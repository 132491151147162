/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { remove } from 'assets/icons';
import { Callout } from 'components/Callout';
import { DateTimePicker } from 'components/DateTimePicker';
import { Input } from 'components/Input';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { StatusPilsner } from 'components/StatusPilsner';

import { DrillsService } from 'services/DrillsService';
import { DrillLogService } from 'services/DrillLogService';
import { Radio, RadioGroup } from '@blueprintjs/core';
import { TextArea } from '../TextArea';
import { CardModal } from './CardModal';
import { safeDateToLuxon, addMonthsToDate } from '../../utilities/dates';

const Wrapper = styled.div`
	overflow-y: auto;
	max-height: 500px;

	.drill-details-header {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.pilsner {
			margin-top: 5px;
		}
	}

	.radio-error {
		color: #db3737;
		font-size: 12px;
		margin-top: 2px;
		line-height: 16px;
		font-style: normal;
		font-weight: normal;
		font-family: Nunito Sans;
		margin-bottom: 0px !important;
	}
`;

export class DrillDetailsModal extends React.Component {
	baseState = {
		loading: true,
		needsToCreateLog: false,
		scheduledDrillDate: null,
		drillLogDate: null,
		fieldsToEdit: null,
		emptyFieldsError: false,
		showDeleteModal: false,
	};

	state = this.baseState;

	postLog = () => {
		const { selectedDrill, setModalToShow, onPostLogSuccess } = this.props;
		const { scheduledDrillDate, drillLogDate, fieldsToEdit } = this.state;

		if (
			!scheduledDrillDate ||
			!drillLogDate ||
			!fieldsToEdit.every(({ value }) => value != null)
		) {
			return this.setState({
				emptyFieldsError: true,
			});
		}

		DrillLogService.postDrillLog(
			selectedDrill?.scheduleId,
			scheduledDrillDate,
			drillLogDate,
			fieldsToEdit?.map(({ value, uuid }) => ({
				value,
				fieldUuid: uuid,
			})),
		)
			.then(data => {
				Swal.fire(
					'Success!',
					'This drill has been logged and completed!',
					'success',
				);
				setModalToShow && setModalToShow(false);
				onPostLogSuccess && onPostLogSuccess(data);
			})
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: "Could not post this drill's log. Please try again.",
				}),
			);
	};

	deleteDrill = () => {
		const { onDeleteDrill, selectedDrill } = this.props;

		const handleDeleteError = () => {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Could not delete this drill. Please try again.',
			});
		};

		DrillsService.deleteScheduledDrill(selectedDrill?.scheduleId)
			.then(successful => {
				if (successful === true) {
					this.setShowDeleteModal(
						false,
						onDeleteDrill && onDeleteDrill(selectedDrill),
					);
				} else {
					handleDeleteError();
				}
			})
			.catch(handleDeleteError);
	};

	createLog = () => {
		const { selectedDrill } = this.props;

		DrillLogService.createDrillLog(selectedDrill?.scheduleId)
			.then(() => {
				Swal.fire(
					'Success!',
					'The new drill log field has been created!',
					'success',
				);
				this.fetchFields();
			})
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Could not create drill log. Please try again.',
				}),
			);
	};

	fetchFields = () => {
		const { selectedDrill, setModalToShow } = this.props;

		const handleFetchError = error => {
			if (error === 'Missing Drill Log.') {
				this.setState({
					...this.baseState,
					loading: false,
					needsToCreateLog: true,
				});
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Couldn\'t edit drill. Please try again.',
				});
				setModalToShow(null);
			}
		};

		DrillsService.getFormFields(selectedDrill?.scheduleId)
			.then(data => {
				if (Array.isArray(data?.fields)) {
					const drillLogDate =
						data?.drill?.log?.drillLoggedDate ||
						data?.drill?.log?.logStartedDate;
					const scheduledDrillDate =
						data?.drill?.scheduledDrillDate || null;
					this.setState({
						...this.baseState,
						loading: false,
						fieldsToEdit: data?.fields,
						scheduledDrillDate: scheduledDrillDate
							? safeDateToLuxon(scheduledDrillDate)
							: null,
						drillLogDate: drillLogDate
							? safeDateToLuxon(drillLogDate)
							: null,
					});
				} else {
					handleFetchError(data);
				}
			})
			.catch(handleFetchError);
	};

	updateField = (uuid, newValue) => {
		this.setState(prevState => ({
			fieldsToEdit: prevState?.fieldsToEdit?.map(item => ({
				...item,
				value: uuid === item.uuid ? newValue : item.value,
			})),
		}));
	};

	setShowDeleteModal = (showDeleteModal = false, callBack) =>
		this.setState({ showDeleteModal }, callBack);

	componentDidMount() {
		this.fetchFields();
	}

	render() {
		const { setModalToShow, selectedDrill } = this.props;
		const {
			loading,
			needsToCreateLog,
			scheduledDrillDate,
			drillLogDate,
			fieldsToEdit,
			emptyFieldsError,
			showDeleteModal,
		} = this.state;

		if (loading) return <LoadingSpinner />;

		return (
			<>
				{/* the log does not exist, so let the user create it */}
				{needsToCreateLog && (
					<CardModal
						isVisible
						setIsVisible={setModalToShow}
						title="Create A Drill Log"
						actionTitle="Create drill log"
						onActionClick={this.createLog}
						secondaryActionIcon={remove}
						secondaryActionTitle="Delete drill"
						onSecondaryActionClick={() =>
							this.setShowDeleteModal(true)
						}
					>
						<p className="modal-text">
							Currently, the {selectedDrill?.drillType?.name} does
							not have a log created. Would you like to create the
							log?
						</p>
					</CardModal>
				)}

				{/* allow the user to log an overdue or upcoming drill, every drill needs a log */}
				{!needsToCreateLog && (
					<CardModal
						isVisible
						setIsVisible={setModalToShow}
						title="Drill details"
						actionTitle="Post log"
						onActionClick={this.postLog}
						secondaryActionIcon={remove}
						secondaryActionTitle="Delete drill"
						onSecondaryActionClick={() =>
							this.setShowDeleteModal(true)
						}
					>
						<Wrapper>
							{emptyFieldsError && (
								<Callout type="warning" className="d-flex mb-2">
									This drill cannot be posted at this time.
									Please check that all fields have been
									completed and try again
								</Callout>
							)}

							<div className="drill-details-header">
								<h2>{selectedDrill.drillType.name}</h2>

								<StatusPilsner status={selectedDrill?.status} />
							</div>

							<DateTimePicker
								label="Drill scheduled date"
								initialValue={new Date(scheduledDrillDate)}
								timePrecision="minute"
								onDateChangeHandler={value =>
									this.setState({
										scheduledDrillDate: value,
									})
								}
								error={emptyFieldsError && !scheduledDrillDate}
								errorMessage="Please enter a value"
								datePickerProps={{
									maxDate : addMonthsToDate()
								}}
							/>

							{Array.isArray(fieldsToEdit) &&
								fieldsToEdit?.map(item => (
									<div className="extra-fields">
										{item.type === 'date' && (
											<DateTimePicker
												label={item.name}
												initialValue={
													item.value
														? safeDateToLuxon(
																item.value,
														  )
														: null
												}
												onDateChangeHandler={value =>
													this.updateField(
														item.uuid,
														value,
													)
												}
												error={
													emptyFieldsError &&
													!item.value
												}
												errorMessage="Please enter a value"
                        datePickerProps={{
                          maxDate : addMonthsToDate()
                        }}
											/>
										)}

										{item.type === 'datetime' && (
											<DateTimePicker
												label={item.name}
												initialValue={
													item.value
														? safeDateToLuxon(
																item.value,
														  )
														: null
												}
												timePrecision="minute"
												onDateChangeHandler={value =>
													this.updateField(
														item.uuid,
														value,
													)
												}
												error={
													emptyFieldsError &&
													!item.value
												}
												errorMessage="Please enter a value"
                        datePickerProps={{
                          maxDate : addMonthsToDate()
                        }}
											/>
										)}

										{[
											'number',
											'text',
											'satisfactory',
										].includes(item.type) && (
											<Input
												label={item.name}
												placeholder={`Enter ${item.name.toLowerCase()}`}
												value={item.value}
												onChange={({ target }) =>
													this.updateField(
														item.uuid,
														target.value,
													)
												}
												error={
													emptyFieldsError &&
													!item.value
												}
												errorMessage="Please enter a value"
											/>
										)}

										{item.type === 'textarea' && (
											<TextArea
												label={item.name}
												width="100%"
												value={item.value}
												onChange={({ target }) =>
													this.updateField(
														item.uuid,
														target.value,
													)
												}
												error={
													emptyFieldsError &&
													!item.value
												}
												errorMessage="Please enter a value"
											/>
										)}

										{item.type === 'yesno' && (
											<>
												<RadioGroup
													label={item.name}
													onChange={({ target }) =>
														this.updateField(
															'item.uuid',
															target.value,
														)
													}
													selectedValue={item.value}
												>
													<Radio
														label="Yes"
														value="yes"
													/>
													<Radio
														label="No"
														value="no"
													/>
												</RadioGroup>

												{emptyFieldsError &&
													!item.value && (
														<p className="radio-error">
															Please select a
															value
														</p>
													)}
											</>
										)}
									</div>
								))}

							<DateTimePicker
								label="Actual drill date and time"
								initialValue={new Date(drillLogDate)}
								timePrecision="minute"
								onDateChangeHandler={value =>
									this.setState({
										drillLogDate: value,
									})
								}
								error={emptyFieldsError && !drillLogDate}
								errorMessage="Please enter a value"
								datePickerProps={{
									maxDate : addMonthsToDate()
								}}
							/>
						</Wrapper>
					</CardModal>
				)}

				{/* allow the user to delete the drill and its log */}
				<CardModal
					isVisible={showDeleteModal}
					setIsVisible={this.setShowDeleteModal}
					title={`Drill ${selectedDrill?.drillType?.name}`}
					actionTitle="Delete drill and log"
					onActionClick={this.deleteDrill}
				>
					<p className="modal-text">
						Are you sure you want to delete this scheduled drill?
					</p>
				</CardModal>
			</>
		);
	}
}

DrillDetailsModal.propTypes = {
	setModalToShow: PropTypes.func,
	selectedDrill: PropTypes.object,
	onDeleteDrill: PropTypes.func,
	onPostLogSuccess: PropTypes.func,
};
