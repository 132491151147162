import { HttpService, buildRoute } from 'networking/HttpService';
import { downloadExportAsCSV } from 'utilities/drills/utils';
import {
	deserializeOverview,
	deserializeDrillsByStatus,
	deserializeYearlyTrends,
} from '../utilities/drills/analytics';

const validateOverviewParams = year => {
	if (!year) {
		throw new Error(`School year needs to be present`);
	}
};

const buildRouteRestWithParams = (route, buildings, drillTypeUUID, status) => {
	let routeToReturn = route;
	let allSchoolsAdded = false;

	const buildingIds = buildings?.map(building => {
		if (building.value === 'all-schools') {
			allSchoolsAdded = true;
		}
		return building.id;
	});

	if (buildings && !allSchoolsAdded) {
		buildingIds.forEach(building => {
			routeToReturn += '&buildingIds[]=:building';
			routeToReturn = buildRoute(routeToReturn, {
				building,
			});
		});
	}

	if (drillTypeUUID) {
		routeToReturn += '&drillTypeUUID=:drillTypeUUID';
		routeToReturn = buildRoute(routeToReturn, {
			drillTypeUUID,
		});
	}
	if (status) {
		routeToReturn += '&status=:status';
		routeToReturn = buildRoute(routeToReturn, {
			status,
		});
	}
	return routeToReturn;
};

export const DrillsAnalyticsService = {
	async getOverview(year, buildings = null, drillTypeUUID = null) {
		validateOverviewParams(year);
		let route = buildRoute('/drill-analytics/overview?year=:year', {
			year,
		});
		if (buildings || drillTypeUUID) {
			route = buildRouteRestWithParams(route, buildings, drillTypeUUID);
		}
		const response = await HttpService({
			method: 'GET',
			route,
		});
		return deserializeOverview(response.data);
	},
	async getDrillsByStatus(
		year,
		status,
		drillTypeUUID = null,
		selectedBuildingIds = null,
	) {
		let route = buildRoute('/drill-analytics/drills-by-status?year=:year', {
			year,
		});
		if (status) {
			route += '&status=:status';
			route = buildRoute(route, {
				status,
			});
		}
		if (drillTypeUUID) {
			route += '&drillTypeUUID=:drillTypeUUID';
			route = buildRoute(route, {
				drillTypeUUID,
			});
		}

		if (selectedBuildingIds) {
			selectedBuildingIds.forEach((item, index) => {
				route += `&buildingIds[${index}]=${item}`;
			});
		}

		const response = await HttpService({
			method: 'GET',
			route,
		});
		return deserializeDrillsByStatus(response.data);
	},
	async getYearlyTrends(year, buildings = null, drillTypeUUID = null) {
		validateOverviewParams(year);
		let route = buildRoute('/drill-analytics/yearly-trends?year=:year', {
			year,
		});
		if (buildings || drillTypeUUID) {
			route = buildRouteRestWithParams(route, buildings, drillTypeUUID);
		}
		const response = await HttpService({
			method: 'GET',
			route,
		});
		return deserializeYearlyTrends(response.data);
	},
	async exportCSV(year, buildings, drillTypeUUID, status) {
		validateOverviewParams(year);
		let route = buildRoute('/drill-analytics/exports?year=:year', {
			year,
		});
		if (buildings || drillTypeUUID) {
			route = buildRouteRestWithParams(
				route,
				buildings,
				drillTypeUUID,
				status,
			);
		}
		const response = await HttpService({
			method: 'POST',
			route,
		});
		downloadExportAsCSV(response);
	},
};
