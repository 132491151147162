import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { BuildingsService, SchoolYearsService } from 'services';
import { Card, Elevation, setHotkeysDialogProps } from '@blueprintjs/core';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Table } from './table';
import FilterModal from './filtermodal';
const animatedComponents = makeAnimated();

const Page = styled.div`
	.dropdown-filters {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.dropdown {
			margin-right: 24px;
			margin-bottom: 16px;
			width: calc(30% - 24px);
		}

		.filters-button {
			margin-top: 25px;
			color: #9f1c3a;
		}
		.filters-icon {
			margin-right: 7px;
		}
	}

	.card-counter {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		flex-direction: row;
		text-align: center;
		margin: 26px 0px 24px 0px;

		.card-width {
			width: 20%;
		}
		.card-title {
			margin: 0;
			color: #000000;
			font-family: Nunito Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
		}

		.card-num {
			margin: 0;
			color: #10161a;
			font-family: Merriweather;
			font-style: normal;
			font-weight: normal;
			font-size: 36px;
			line-height: 40px;
		}
	}

	.subtitle {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		margin: 26px 0px 18px 0px;

		.first-subtitle {
			margin: 0;
			color: #10161a;
			font-size: 18px;
			text-align: left;
			font-weight: bold;
			line-height: 21px;
			font-style: normal;
			letter-spacing: 0.3;
			font-family: Merriweather;
		}

		.second-subtitle {
			margin: 0px 0px 0px 10px;
			color: #738694;
			font-size: 12px;
			text-align: left;
			font-weight: 400;
			line-height: 16px;
			font-style: normal;
			letter-spacing: 0.3;
			font-family: Nunito Sans;
		}
	}

	.table {
		.dropdown {
			min-width: initial;
		}

		.date-time-picker {
			min-width: initial;
		}
	}

	.empty-state {
		color: #5c7080;
		font-family: Merriweather;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 21px;
		text-align: -webkit-center;
		margin-top: 10%;
	}
`;

const WrapMulti = styled.div`
	width: 320px;
	.bp3-popover-target {
		width: 100%;
	}
	input {
		padding: 4px;
		color: #10161a !important;
		::placeholder {
			color: #10161a !important;
			font-size: 16px;
			line-height: 22px;
			font-style: normal;
			font-weight: normal;
			font-family: Nunito Sans;
		}
	}
	.bp3-input {
		border: 1px solid #d8e1e8;
		box-shadow: none;
	}
	margin-right: 14px;
`;

const StyledLabel = styled.div`
	color: #10161a;
	font-size: 16px;
	line-height: 22px;
	font-style: normal;
	margin-bottom: 4px;
	font-weight: normal;
	font-family: Nunito Sans;
`;

const BASE_VIEW = {
	selectedSites: [],
	selectedSchoolYear: null,
	selectedDrillType: null,
	selectedDrill: null,
	scheduledDrills: [],
	updatedScheduledDrills: [],
	unscheduledDrills: [],
	invalidDrillsError: false,
};

const BASE_STATE = {
	...BASE_VIEW,
	loading: true,
	sites: null,
	schoolYears: null,
	drillTypes: null,
	currentData: null,
	showFilterModal: false,
	sectionList: [],
	filterBuildings: null,
	answerTypes: [],
	filterTypes: [],
	filterTypesArray: [],
	filterCompleted: false,
	filterInProgress: false,
	filterThumbsUp: false,
	filterThumbsDown: false,
	filterSections: [],
	isSections: false,
};

class Analytics extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		Promise.all([
			BuildingsService.getAll(true),
			SchoolYearsService.fetchYears(),
		])
			.then(([sites, schoolYears]) => {
				this.setState({
					...BASE_STATE,
					sites,
					schoolYears,
					loading: false,
				});
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Could not load this page, please try refreshing.',
				});
				this.setState({
					...BASE_STATE,
					loading: false,
					error: 'Could not load this page, please try refreshing',
				});
			});
	}

	getData = (selectedTemplate, selectedSites, selectedSchoolYear) => {
		const { sites } = this.state;

		this.setState({ loading: true });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const buildings = [];
		selectedSites.forEach(one => {
			if (one.value === 'all-schools') {
				// empty the buildings
				buildings.length = 0;
				return sites.forEach(site => {
					if (site.id) {
						buildings.push(site.id);
					}
				});
			}

			return buildings.push(one.id);
		});

		fetch(
			`${process.env.API_URL}/templates/${selectedTemplate.id}/analytics`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					buildings,
					year: selectedSchoolYear.value,
				}),
			},
		)
			.then(res => res.json())
			.then(resp => {
				const temp = [];
				const tempTypes = [];
				if (resp.data.sections.length) {
					resp.data.sections.forEach(item =>
						temp.push({ value: item.sectionId, label: item.name }),
					);
				}

				resp.data.answerTypes.forEach(one => {
					const tempAnswer = JSON.parse(one);
					if (Object.keys(tempAnswer).includes('button')) {
						tempTypes.push({
							value: tempAnswer.button,
							label: tempAnswer.button,
							source: tempAnswer,
						});
					} else {
						tempTypes.push({
							value: Object.keys(tempAnswer)[0],
							label: Object.keys(tempAnswer)[0],
							source: tempAnswer,
						});
					}
				});

				this.setState({
					currentData: resp.data,
					loading: false,
					sectionList: temp,
					answerTypes: tempTypes,
					isSections: false,
				});
			}).catch(()=>{
				Swal.fire({
					icon: 'info',
					title: 'There are no Sections for this Template.',
				});
				this.setState({
					loading: false,
					isSections: true,
				})
			})
	};

	closeFilter = () => this.setState({ showFilterModal: false });

	setFilterSections = val => this.setState({ filterSections: val });

	setFilterTypes = val => {
		const { filterTypes, filterTypesArray } = this.state;
		const temp = filterTypesArray.filter(item => item.label === val.label);
		if (temp.length > 0) {
			const tempTypes = filterTypes.filter(item => item !== val.source);
			return this.setState({
				filterTypesArray: temp,
				filterTypes: tempTypes,
			});
		}
		filterTypes.push(val);
		filterTypesArray.push(val.value);
		return this.setState({ filterTypes, filterTypesArray });
	};

	setFilterInProgress = () => {
		const { filterInProgress } = this.state;
		return this.setState({ filterInProgress: !filterInProgress });
	};

	setFilterCompleted = () => {
		const { filterCompleted } = this.state;
		return this.setState({ filterCompleted: !filterCompleted });
	};

	setThumbs = dir => {
		if (dir === 'down') {
			const { filterThumbsDown } = this.state;
			return this.setState({ filterThumbsDown: !filterThumbsDown });
		}
		const { filterThumbsUp } = this.state;
		return this.setState({ filterThumbsUp: !filterThumbsUp });
	};

	applyFilters = () => {
		this.setState({ loading: true, showFilterModal: false });
		const {
			selectedSites,
			selectedSchoolYear,
			filterTypes,
			filterThumbsUp,
			filterThumbsDown,
			filterCompleted,
			filterInProgress,
			filterSections,
			selectedTemplate,
		} = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const buildings = [];
		selectedSites.forEach(one => buildings.push(one.id));
		const sections = [];
		filterSections.forEach(one => sections.push(one.value));
		const answerTypes = [];
		filterTypes.forEach(one => answerTypes.push(one.source));
		fetch(
			`${process.env.API_URL}/templates/${selectedTemplate.id}/analytics`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					buildings,
					year: selectedSchoolYear.value,
					answerTypes,
					completed: filterCompleted,
					inProgress: filterInProgress,
					thumbsUp: filterThumbsUp,
					thumbsDown: filterThumbsDown,
					sectionIds: sections,
				}),
			},
		)
			.then(res => res.json())
			.then(resp =>
				this.setState({
					currentData: resp.data,
					loading: false,
				}),
			);
	};

	changeSchoolYear = selectedSchoolYear => {
		const { updateFields } = this.props;
		updateFields('exportYear', selectedSchoolYear);
		this.setState({
			selectedSchoolYear,
		});
		const { selectedSites, selectedTemplate } = this.state;
		if (selectedSites && selectedTemplate)
			return this.getData(
				selectedTemplate,
				selectedSites,
				selectedSchoolYear,
			);
		return null;
	};

	setTemplate = selectedTemplate => {
		const { updateFields } = this.props;
		updateFields('exportTarget', selectedTemplate);
		this.setState({ selectedTemplate });
		const { selectedSites, selectedSchoolYear } = this.state;
		if (selectedSites && selectedSchoolYear)
			return this.getData(
				selectedTemplate,
				selectedSites,
				selectedSchoolYear,
			);
		return null;
	};

	addBuilding = newValue => {
		const { updateFields } = this.props;
		updateFields('exportBuildings', newValue);
		this.setState({ selectedSites: newValue });
	};

	render() {
		const { templates } = this.props;
		const {
			loading,
			error,
			sites,
			schoolYears,
			selectedSchoolYear,
			selectedTemplate,
			currentData,
			showFilterModal,
			sectionList,
			filterBuildings,
			answerTypes,
			filterTypes,
			filterTypesArray,
			filterInProgress,
			filterCompleted,
			filterThumbsUp,
			filterThumbsDown,
			isSections,
		} = this.state;

		if (error) return error;

		const parsedTemplates = [];
		templates.forEach(item =>
			parsedTemplates.push({ ...item, label: item.name }),
		);

		return (
			<>
				{showFilterModal && (
					<FilterModal
						closeFilter={this.closeFilter}
						sections={sectionList}
						setFilterSections={this.setFilterSections}
						filterBuildings={filterBuildings}
						answerTypes={answerTypes}
						filterTypes={filterTypes}
						setFilterTypes={this.setFilterTypes}
						filterTypesArray={filterTypesArray}
						filterInProgress={filterInProgress}
						filterCompleted={filterCompleted}
						setFilterInProgress={this.setFilterInProgress}
						setFilterCompleted={this.setFilterCompleted}
						setThumbs={this.setThumbs}
						filterThumbsDown={filterThumbsDown}
						filterThumbsUp={filterThumbsUp}
						applyFilters={this.applyFilters}
					/>
				)}
				<Page>
					<div className="dropdown-filters">
						<WrapMulti>
							<StyledLabel>School</StyledLabel>
							<Select
								defaultValue={null}
								className="multi-select"
								closeMenuOnSelect={false}
								components={animatedComponents}
								isMulti
								options={sites}
								onChange={selected =>
									this.addBuilding(selected)
								}
								menuPortalTarget={document.querySelector(
									'body',
								)}
							/>
						</WrapMulti>
						<Dropdown
							label="School year"
							placeholder="Select school year"
							value={selectedSchoolYear}
							options={schoolYears}
							onChange={item => this.changeSchoolYear(item)}
						/>

						<Dropdown
							label="Template"
							placeholder="Select template"
							value={selectedTemplate}
							options={parsedTemplates}
							onChange={this.setTemplate}
						/>
						<Button
							className="filters-button"
							onClick={() => {
								this.setState({ showFilterModal: true });
							}}
							text="More filters"
							intent="tertiary"
							icon="filter"
						/>
					</div>
					{loading && <LoadingSpinner />}
					{!loading && isSections && 
						<div className="empty-state">
							There are no Sections for this Template
						</div>}
					{(currentData && (
						<div>
							<div className="card-counter">
								<Card
									className="card-width"
									interactive={false}
									elevations={Elevation.TWO}
								>
									<h5 className="card-title">
										Total Assessments
									</h5>
									<h1 className="card-num">
										{
											currentData.totals[0]
												.total_assessments
										}
									</h1>
								</Card>
								<Card
									className="card-width"
									interactive={false}
									elevations={Elevation.TWO}
								>
									<h5 className="card-title">
										Total Buildings
									</h5>
									<h1 className="card-num">
										{currentData.totals[0].total_buildings}
									</h1>
								</Card>
								<Card
									className="card-width"
									interactive={false}
									elevations={Elevation.TWO}
								>
									<h5 className="card-title">
										Assessments in Progress
									</h5>
									<h1 className="card-num">
										{currentData.totals[0]
											.started_assessments || 0}
									</h1>
								</Card>
								<Card
									className="card-width"
									interactive={false}
									elevations={Elevation.TWO}
								>
									<h5 className="card-title">
										Assessments Complete
									</h5>
									<h1 className="card-num">
										{currentData.totals[0]
											.completed_assessments || 0}
									</h1>
								</Card>
							</div>
							<div>
								<Table data={currentData} />
							</div>
						</div>
					)) || (
						<div className="empty-state">
							Select school, school year, and template above to
							see your analytics
						</div>
					)}
				</Page>
			</>
		);
	}
}

Analytics.propTypes = {
	templates: PropTypes.any,
	updateFields: PropTypes.func,
};

export { Analytics };
