import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { stringToVarName } from 'utilities';
import { LoadingSpinner } from 'components/LoadingSpinner';

class EmailVariable extends React.Component {
	constructor(props) {
		super(props);
		const {
			editVarContent: { name, helpText },
		} = this.props;
		this.state = {
			value: name,
			helpText,
		};
	}

	updateVar = () => {
		const { editVarContent, editVariable, editVarId } = this.props;
		const { value, helpText } = this.state;

		const temp = {
			name: stringToVarName(value),
			content: {
				helpText,
				name: value,
				typeAnnotation: 'Open text field',
				type: {
					id: 1,
					value: 'Text',
				},
			},
		};
		return editVariable(editVarContent, temp, editVarId);
	};

	render() {
		const { toggleEditDialog, loading } = this.props;
		const { value, helpText } = this.state;
		if (loading) return <LoadingSpinner />;
		return (
			<div className="mt-3">
				<Input
					placeholder="Input Text"
					label="Email Address Variable"
					large
					value={value}
					onChange={e => this.setState({ value: e.target.value })}
					helpText={`Actual variable value: ${stringToVarName(
						value,
					)}`}
				/>
				<div className="mt-2">
					<Input
						placeholder="Help text"
						label="Example help text"
						large
						value={helpText}
						onChange={e =>
							this.setState({ helpText: e.target.value })
						}
						helpText="Help text will appear below the variable input for the end user"
					/>
				</div>
				<div className="d-flex justify-content-end mt-2">
					<Button
						text="Cancel"
						large
						intent="secondary"
						onClick={() => toggleEditDialog(false)}
					/>
					<Button
						className="ml-2"
						text="Save"
						large
						intent="primary"
						onClick={this.updateVar}
					/>
				</div>
			</div>
		);
	}
}

EmailVariable.propTypes = {
	editVariable: PropTypes.func,
	loading: PropTypes.bool,
	editVarContent: PropTypes.object,
	toggleEditDialog: PropTypes.func,
	editVarId: PropTypes.number,
};

export default EmailVariable;
