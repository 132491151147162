export const CHECKIN_STEPS = {
	MANUAL_FORM_STEP: 'manualCheckinFormStep',
	GUARDIANS_LIST_STEP: 'guardiansListStep',
	EXISTING_GUARDIANS_STEP: 'existingGuardiansStep',
	ADD_STUDENTS_STEP: 'AddStudentsStep',
	GUARDIAN_WITH_STUDENTS_STEP: 'guardianWithStudentsStep',
	SELECTED_STUDENTS_STEP: 'SelectedStudentsStep',
	CONFIRM_GUARDIAN_CHECKIN_STEP: 'ConfirmCheckInStep',
};
export const GUARDIAN_RELATIONS = [
	{ value: 'Parent or Legal Guardian', label: 'Parent or Legal Guardian' },
	{ value: 'Family Relative', label: 'Family Relative' },
	{ value: 'Sibling', label: 'Sibling' },
	{ value: 'Friend of Family', label: 'Friend of Family' },
	{ value: 'Other', label: 'Other' },
];
