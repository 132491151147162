import React from 'react';
import PropTypes from 'prop-types';
import './css/ToggleButton.scss';
import { Switch } from '@blueprintjs/core';

export const ToggleButton = ({ checked, label, onChange, disabled = false }) => {
	return (
		<div className="Toggle__container">
			<Switch
				checked={checked}
				onChange={onChange}
				label={label}
				large={true}
				disabled={disabled}
				className="Toggle__container__switch"
			/>
		</div>
	);
};

ToggleButton.propTypes = {
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
};
