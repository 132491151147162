import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { stringToVarName } from 'utilities';
import { LoadingSpinner } from 'components/LoadingSpinner';

const InputWrap = styled.div`
	flex: 1;
`;

class ImageVariable extends React.Component {
	constructor(props) {
		super(props);
		const {
			editVarContent: { name, helpText, width, height },
		} = this.props;
		this.state = {
			value: name,
			helpText,
			width,
			height,
		};
	}

	updateVar = () => {
		const { editVarContent, editVariable, editVarId } = this.props;
		const { value, helpText, width, height } = this.state;

		const temp = {
			name: stringToVarName(value),
			content: {
				helpText,
				name: value,
				typeAnnotation: 'Image',
				type: {
					id: 2,
					value: 'Image',
				},
				width,
				height,
			},
		};
		return editVariable(editVarContent, temp, editVarId);
	};

	render() {
		const { toggleEditDialog, loading } = this.props;
		const { value, helpText, width, height } = this.state;
		if (loading) return <LoadingSpinner />;
		return (
			<div className="mt-3">
				<Input
					placeholder="Input Text"
					label="Name of image variable"
					large
					value={value}
					onChange={e => this.setState({ value: e.target.value })}
					helpText={`Actual variable value: ${stringToVarName(
						value,
					)}`}
				/>
				<div className="mt-2">
					<Input
						placeholder="Help text"
						label="Example help text"
						large
						value={helpText}
						onChange={e =>
							this.setState({ helpText: e.target.value })
						}
						helpText="Help text will appear below the variable input for the end user"
					/>
				</div>
				<div className="mt-2 d-flex flex-row justify-content-between">
					<InputWrap>
						<Input
							placeholder="Width"
							label="Width in pixels"
							large
							type="number"
							value={width}
							onChange={e =>
								this.setState({ width: e.target.value })
							}
						/>
					</InputWrap>
					<InputWrap className="ml-2">
						<Input
							placeholder="Height"
							label="Height in pixels"
							large
							type="number"
							value={height}
							onChange={e =>
								this.setState({ height: e.target.value })
							}
						/>
					</InputWrap>
				</div>
				<div className="d-flex justify-content-end mt-2">
					<Button
						text="Cancel"
						large
						intent="secondary"
						onClick={() => toggleEditDialog()}
					/>
					<Button
						className="ml-2"
						text="Create"
						large
						intent="primary"
						onClick={this.createVar}
					/>
				</div>
			</div>
		);
	}
}

ImageVariable.propTypes = {
	editVariable: PropTypes.func,
	loading: PropTypes.bool,
	editVarContent: PropTypes.object,
	toggleEditDialog: PropTypes.func,
	editVarId: PropTypes.number,
};

export default ImageVariable;
