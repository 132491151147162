import React from 'react';
import { Icon } from '@blueprintjs/core';
import { Button } from 'componentsV2';
import { EMSHome } from 'assets/icons';
import './css/HomeEmptyState.scss';

export const HomeEmptyState = ({ showPastAlarms, setShowPastAlarms, hasViewPermission }) => {
	return (
		<div className="home-empty-state-container">
			<div className="alarm-main-container">
				<div className="alarm-sub-container">
					<img src={EMSHome} alt="homeImage" />
					<h3 className="no-active-alarms-title">No Active Alarms</h3>
					<div className="sub-text-container">
						<p>There are currently no active alarms at any locations.</p>
					</div>

					{hasViewPermission && (
						<Button
							className="alarms-button-container"
							type="ghostPrimary"
							text={
								<>
									{showPastAlarms ? 'Hide' : 'Show'} past alarms
									<Icon
										className="icon-style"
										icon={`chevron-${showPastAlarms ? 'up' : 'down'}`}
									/>
								</>
							}
							onClick={() => setShowPastAlarms(!showPastAlarms)}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
