import React from "react";
import {CardContainer} from "componentsV2";
import {HistoryContent} from "./HistoryContent";
import "../css/PublishHistoryCardContainer.scss"
import {ARCHIVED_STATUS, PUBLISH_STATUS} from "./statuses";

export const PublishHistoryCardContainer = ({
                                                flipchartHistory = [],
                                                loadTabPreviewModal,
                                            }) => {

    return (
        <CardContainer
            title="Publish history"
            subtitle={(flipchartHistory.currentlyPublished && flipchartHistory.oldVersions.length > 0) ? "Click on any version you want see it" : ""}>
            <div className="history-card-container">
                {flipchartHistory.currentlyPublished &&
                    <HistoryContent 
                    status={PUBLISH_STATUS} 
                    historyContent={flipchartHistory.currentlyPublished}
                    loadTabPreviewModal = {loadTabPreviewModal}    
                    />
                }
                {flipchartHistory.oldVersions.length > 0 && (
                    flipchartHistory.oldVersions.map((item, index) => (
                        ([ARCHIVED_STATUS, PUBLISH_STATUS].includes(item.status) && (
                            <HistoryContent
                                key={index} // Add a unique key for each mapped element
                                status={item.status}
                                historyContent={item}
                                loadTabPreviewModal={loadTabPreviewModal}
                            />
                        ))
                    ))
                )}

                {flipchartHistory.oldVersions.length == 0 && !flipchartHistory.currentlyPublished && <>
                    <div className="history-content">
                        <div className="empty-text">
                            There is no publish history yet
                        </div>
                    </div>
                </>
                }
            </div>
        </CardContainer>
    );
}