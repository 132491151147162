import React from 'react';
import PropTypes from 'prop-types';
import { ContentSearch } from './ContentSearch';
import './css/TabPanel.scss';

export const TabPanel = ({
	description,
	onSearchHandler,
	onAddClickHandler,
	showAddBtn,
	hasSearch,
	addBtnTitle,
	children,
	searchValue = '',
	addBtnDisabled = false,
	editPermission,
}) => (
	<div className="tab-panel-container">
		<div className="tab-panel-container-header">
			<p className="tab-panel-container-description">{description}</p>
			{hasSearch && (
				<ContentSearch
					onSearch={onSearchHandler}
					onAddClickHandler={onAddClickHandler}
					showAddBtn={showAddBtn}
					addBtnTitle={addBtnTitle}
					searchValue={searchValue}
					addBtnDisabled={addBtnDisabled}
					editPermission={editPermission}
				/>
			)}
		</div>
		<div className="tab-panel-container-body">{children}</div>
	</div>
);

TabPanel.propTypes = {
	description: PropTypes.string,
	onSearchHandler: PropTypes.func,
	onHideAllHandler: PropTypes.func,
	onAddClickHandler: PropTypes.func,
	editPermission: PropTypes.bool,
	hasSearch: PropTypes.bool,
	showAddBtn: PropTypes.bool,
	addBtnTitle: PropTypes.string,
};
