// todo
export const setAuthToken = token => {};

export const removeTokenAndLogout = () => {
	localStorage.removeItem('user');
	window.location.reload();
};

export const getAuthToken = () => {
	// Check for local storage first
	const token = JSON.parse(localStorage.getItem('user'))?.jwt;

	// Allow the token to be overridden by query param.
	const urlParams = new URLSearchParams(window.location.search);
	const urlToken = urlParams.get('__auth_token');
	if (urlToken) {
		return urlToken;
	}

	return token;
};
export const buildRoute = (url, params = {}) => {
	let newURL = url;

	Object.keys(params).forEach(key => {
		if (!newURL.includes(`:${key}`)) {
			throw new Error(`:${key} was not defined within the route.`);
		}

		newURL = newURL.replace(`:${key}`, params[key]);
	});

	return newURL;

	/* example
		HttpService({
			method: 'GET',
			route: buildRoute(
				Endpoints.url || 'site.com/class/:classId/homework/:homeworkId',
				{
					classId: 5,
					homeworkId: 30
				}
			)
		})
	*/
};

export const isSuccessfulRequest = status => status <= 299 && status >= 200;

export const HttpService = async request => {
	const token = await getAuthToken();
	const apiUrl = process.env.API_URL;

	const config = {
		method: request.method,
		headers: {
			accept: '*/*',
			'content-type': 'application/json',
		},
	};

	// add the token if it is available
	if (token && !request.headers?.Authorization) {
		config.headers = {
			...config.headers,
			authorization: `Bearer ${token}`,
		};
	}

	// allow devs to override headers
	if (request.headers) {
		config.headers = {
			...config.headers,
			...request.headers,
		};
	}

	// addd and build request body
	if (request.body) {
		config.body = JSON.stringify(request.body);
	}

	// return request promise
	try {
		// fire initial request
		const response = await fetch(
			// process.env.API_URL + request.route,
			apiUrl + request.route,
			config,
		);

		if (isSuccessfulRequest(response?.status)) {
			// parse the response into json, successful response to your '.then()' block
			if (response.headers.get('content-type')?.includes('application/json')) {
				return response.json();
			}

			return response.blob();
		}

		// parse the error, to fail the request
		const error = await response.json();
		throw error;
	} catch (err) {
		// throw or send the error to your ".catch()" or "} catch {" blocks
		if (err.statusCode == 401) {
			const localToken = JSON.parse(localStorage.getItem('user'));
			localToken.jwt = '';
			localStorage.setItem('user', JSON.stringify(localToken));
			return (window.location.href = '/login');
		}
		throw err;
	}

	/* example
		HttpService({
			method: 'POST',
			route: Endpoints.route,
			headers: {
				extraHeader: 'yes',
			},
			body: {
				firstName: 'john',
				lastName: 'appleseed',
			}
		})
	*/
};
