import { PlanService } from 'services';
import { Intent } from 'componentsV2';
import { Helper } from 'utilities/helper';

export const RenameSafetyPlanModalFn = (
	modalName,
	setLoading,
	formData,
	dispatchSafetyPlanData,
	closeModal,
	toaster,
	setModalToShow,
	loadData
) => {
	const showRenameModal = safetyPlan => {
		setModalToShow(modalName);
		dispatchSafetyPlanData({
			id: safetyPlan.id,
			title: safetyPlan.title,
			oldTitle: safetyPlan.title
		});
	};

	const handleChangeTitle = e => {
		dispatchSafetyPlanData({ ...formData, title: e.target.value });
	};

	const handleRenameSubmit = () => {
		if (!!formData.title) {
			setLoading(true);
			PlanService.updatePlan(formData.id, { name: formData.title} )
				.then(response => {
					toaster(
						`You have successfully edited "${
							formData.oldTitle
						}".`,
						Intent.SUCCESS,
					);
					closeModal();
					loadData();
				})
				.catch(error => {
					toaster(
						'Error while updating the plan title',
						Intent.DANGER,
					);
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return {
		showRenameModal,
		handleChangeTitle,
		handleRenameSubmit,
	};
};
