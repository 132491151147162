import React,{useContext} from "react";
import PropTypes from 'prop-types';
import { ActionsButton, Button } from "componentsV2";
import { SafetyplansContext } from "../Providers/SafetyplansProvider";

export const ActionsList = ({
    history,
    elementSelector = null,
    template
}) => {

    const {    
        handleUpdateTemplate,
        handleCopyTemplate,
        showArchiveModal,
        showDeleteModal
    } = useContext(SafetyplansContext);

    return (
        <ActionsButton elementSelector={elementSelector}>
                <Button
                    large
                    text="Edit template details"
                    icon="edit"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => handleUpdateTemplate(template.id)}
                />
                <Button
                    large
                    text="Copy"
                    icon="duplicate"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => handleCopyTemplate(template.id)}
                />
                <Button
                    large
                    text={!template.archived ? "Archive" : "Unarchive"}
					icon={!template.archived ? "archive" : "unarchive"}
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={()=> showArchiveModal({
                        id: template.id,
                        title: template.name,
                        archived: template.archived,
                        source: 'details'
                    })}
                />
                <Button
                    large
                    text="Delete"
                    icon="trash"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={()=> showDeleteModal(template)}
                />

        </ActionsButton>
    );
}

ActionsList.propTypes = {
};