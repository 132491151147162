import React, { useEffect, useState } from 'react';
import { FormModal, Checkbox, CheckboxGroup, LoadingModal } from 'componentsV2';
import { RapidAlarmService } from 'services';
import './css/DownloadPastAlarmDetailsModal.scss';

export const DownloadPastAlarmDetailsModal = ({ pastAlarm, pastAlarmHistory, handleCancel }) => {
	const [hasChatHistory, sethasChatHistory] = useState(
		pastAlarmHistory?.chatHistory.some(room => room?.messages?.length > 0),
	);

	const [filteredSubmittedClasses, setFilteredSubmittedClasses] = useState(
		pastAlarmHistory?.submittedClasses.filter(item => item.id !== 0),
	);

	const [hasSubmittedClasses, setHasSubmittedClasses] = useState(
		filteredSubmittedClasses && filteredSubmittedClasses.length > 0,
	);

	const [downloadLoader, setDownloadLoader] = useState();
	const [options, setOptions] = useState([
		{
			id: 1,
			title: 'Roll Call details',
			disabled: pastAlarm?.isRollCallStarted ? false : true,
			value: pastAlarm?.isRollCallStarted ? true : false,
		},
		{
			id: 2,
			title: 'Reunification',
			disabled: pastAlarm?.isReunificationStarted ? false : true,
			value: pastAlarm?.isReunificationStarted ? true : false,
		},
		{
			id: 3,
			title: 'Chat history',
			disabled: !hasChatHistory,
			value: hasChatHistory,
		},
		{
			id: 4,
			title: 'Submitted classes',
			disabled: !hasSubmittedClasses,
			value: hasSubmittedClasses,
		},
	]);

	useEffect(() => {
		if (
			!pastAlarm?.isRollCallStarted &&
			!pastAlarm?.isReunificationStarted &&
			!hasChatHistory &&
			filteredSubmittedClasses.length === 0
		) {
			HandleSave(false);
		}
	}, []);

	const handleCheckBoxChange = id => {
		return () => {
			setOptions(prevOptions => {
				return prevOptions.map(option => {
					if (option.id === id) {
						return { ...option, value: !option.value };
					}
					return option;
				});
			});
		};
	};
	const HandleSave = (withParams = true) => {
		let params = {
			with_rollCall: null,
			with_reunification: null,
			with_chatHistory: null,
			with_submittedClasses: null,
		};
		if (withParams) {
			options.map(option => {
				switch (option.id) {
					case 1:
						params.with_rollCall = option.value ? true : false;
						break;
					case 2:
						params.with_reunification = option.value ? true : false;
						break;
					case 3:
						params.with_chatHistory = option.value ? true : false;
						break;
					case 4:
						params.with_submittedClasses = option.value ? true : false;
						break;
				}
			});
		}

		setDownloadLoader(true);
		RapidAlarmService.downloadPastAlarm(pastAlarm.alarmId, params)
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response]));
				var a = document.createElement('a');
				a.href = url;
				a.download =
					(pastAlarm.alarmType
						? pastAlarm.alarmType.title
						: 'Roll call - ' + pastAlarm.building?.id) + '.xlsx';
				a.click();
				a.remove();
			})
			.catch(err => {
				setDownloadLoader(true);
			})
			.finally(() => {
				handleCancel();
				setDownloadLoader(true);
			});
	};
	return (
		<>
			<FormModal
				isOpen={!downloadLoader}
				onClose={handleCancel}
				showCloseButton
				size="small"
				title="Download past alarm details"
				scrollContent={false}
				confirmBtn={{
					title: 'Download',
					onClick: HandleSave,
					disabled: false,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: () => handleCancel(),
				}}
			>
				<div className="download-past-alarm-container">
					<div className="download-subtitle">Sections to download</div>
					<div className="download-sections">
						<div className="section-box">
							<CheckboxGroup layout={'block'}>
								{options.map(option => (
									<Checkbox
										key={option.id}
										label={option.title}
										checked={option.value}
										disabled={option.disabled}
										onChange={handleCheckBoxChange(option.id)}
									/>
								))}
							</CheckboxGroup>
						</div>
					</div>
				</div>
			</FormModal>
			<LoadingModal
				isOpen={downloadLoader}
				loading={true}
				text={'Downloading...'}
				onClose={''}
			/>
		</>
	);
};
