import { format } from 'date-fns';
import { DateTime } from 'luxon';

export const parsePHPDateTime = (date, timezoneType, timezone) => {
	if (timezoneType === 1) {
		return DateTime.fromSQL(date).setZone(timezone);
	}
	return DateTime.fromSQL(date, {
		zone: timezone,
	});
};

export const safeDateToLuxon = date => {
	const errorMessage =
		'The date is not a Date (js date), DateTime (luxon date), nor a valid date string please check the function call';

	// handle JS dates
	if (date instanceof Date) {
		return DateTime.fromJSDate(date);
	}

	// handle luxon date
	if (date instanceof DateTime) {
		return date;
	}

	// handle strings
	if (typeof date === 'string') {
		try {
			const parsed = new Date(date);
			return DateTime.fromJSDate(parsed);
		} catch (err) {
			throw new Error(errorMessage);
		}
	}

	// handle date objects from the backend
	if (
		date &&
		Object.hasOwn(date, 'date') &&
		Object.hasOwn(date, 'timezone_type') &&
		Object.hasOwn(date, 'timezone')
	) {
		return parsePHPDateTime(date.date, date.timezoneType, date.timezone);
	}

	// else return error
	throw new Error(errorMessage);
};

// This method formats either a JS or a Luxon date to the default expected en-US local format
// The luxon format flag is an optional parameter in case it's needed
export const formatDate = (date, luxonFormatFlag = DateTime.DATETIME_SHORT) => {
	if (!date) return null;
	const safeLuxonDate = safeDateToLuxon(date);
	return (
		safeLuxonDate
			.toLocal()
			// Inspected luxon code, and the flag is just an object following this spec: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
			.toLocaleString({ locale: 'en-US', ...luxonFormatFlag })
	);
};

export const formatDateForService = date => {
	const safeLuxonDate = safeDateToLuxon(date);
	return safeLuxonDate.toUTC();
};

export const MONTH_MAP = {
	Jan: 'January',
	Feb: 'February',
	Mar: 'March',
	Apr: 'April',
	May: 'May',
	Jun: 'June',
	Jul: 'July',
	Aug: 'August',
	Sep: 'September',
	Sept: 'September',
	Oct: 'October',
	Nov: 'November',
	Dec: 'December',
};

/**
 * Adds a specified number of months to a given date and returns the updated date object.
 *
 * @param {number} months - The number of months to add to the date (default: 18).
 * @param {Date} cDate - The date object to be updated (default: current date).
 * @returns {Date} The updated date object.
 */
export const addMonthsToDate = (months = 18, cDate = new Date()) => {
	cDate.setMonth(cDate.getMonth() + months);
	return cDate;
};

export const formatDateForLogs = date => {
	let formattedDate = DateTime.fromSQL(date)
		.setLocale('en-US')
		.toLocaleString({
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
		});
	formattedDate = formattedDate.replace(' PM', 'pm');
	formattedDate = formattedDate.replace(' AM', 'am');
	return formattedDate;
};

/**
 * @param {datetime} datetime
 * @returns date with mm/dd/yyyy format ex: 06/26/2023
 */
export const getFormattedDate = datetime => {
	if (datetime) {
		return format(new Date(datetime), 'MM/dd/yyyy');
	}

	return '';
};

import { SCHOOL_YEAR_CUTOFF_MONTH, SCHOOL_YEAR_CUTOFF_DAY } from 'utilities/constants';

// return school year as array -> [startYear, endYear]
export const getCurrentSchoolYear = () => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const cutoffDate = new Date(currentYear, SCHOOL_YEAR_CUTOFF_MONTH - 1, SCHOOL_YEAR_CUTOFF_DAY);

	if (currentDate < cutoffDate) {
		// If current date is before the cutoff, the school year started last year and ends this year
		return [currentYear - 1, currentYear];
	}

	// If current date is on or after the cutoff, the school year starts this year and ends next year
	return [currentYear, currentYear + 1];
};

/**
 * This function recieves php date object and calculates duration from current time
 * exmple output: 4h 23m 40s
 * @param startDate
 * @returns string
 */
export const getFormattedDurationInHMS = startDate => {
	if (!startDate) return null;
	var end = DateTime.now();

	var dateStringWithoutMicroseconds = startDate.date.replace(/\.\d+$/, ''); // Remove microseconds
	var start = DateTime.fromFormat(dateStringWithoutMicroseconds, 'yyyy-MM-dd HH:mm:ss');

	// Calculate the difference between the end and start dates
	var duration = end.diff(start, ['hours', 'minutes', 'seconds']).toObject();

	// Format the duration into a human-readable string
	return duration.hours + 'h ' + duration.minutes + 'm ' + Math.floor(duration.seconds) + 's';
};
