import React from 'react';
import { Icon } from '@blueprintjs/core';
import { COMPLETED_STATUS } from '../../';
import './css/SectionCard.scss';

export const SectionCard = ({ templateUpdate }) => {
	const getTemplateUpdateType = templateUpdate => {
		const { targetUpdate, sectionType, type } = templateUpdate;
		
		if(type === 'delete') {
			return sectionType+' deleted';
		} else { // add/update
			if(targetUpdate) {
				return sectionType+' updated';
			} else {
				return `New ${sectionType} added`;
			}
		}
	};

	return (
		<div id={`pending-card-${templateUpdate.id}`} className={`pending-section-card ${templateUpdate.status}`}>
			<div className="section-title">
				<Icon
					className="icon"
					icon={
						templateUpdate.status === COMPLETED_STATUS
							? 'tick'
							: 'record'
					}
				/>
				<h6 className="title">
					{templateUpdate.updatedContent?.title}
				</h6>
			</div>
			<div className="section-body">
				{getTemplateUpdateType(templateUpdate)}
			</div>
		</div>
	);
};
