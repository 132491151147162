import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Autosave } from '../Autosave';
import { BackButton } from '../BackButton';
import { Icon } from '@blueprintjs/core';
import MenuContext from 'context/MenuContext';
import { Breadcrumb } from 'componentsV2';
import './index.scss';

/* This component is now depricated. Please make any updates to componentsV2\PageHeader */
const PageHeader = ({
	title = '',
	subtitle = null,
	onBackBtnClick,
	autoSaveValue = '',
	actionTitle = '',
	onActionClick,
	actionDisabled = false,
	customItem = null,
	breadcrumbItems = [],
	breadCrumbCustomItem = null,
	children,
}) => {
	const menu = useContext(MenuContext);

	return (
		<div className="page-header-container legacy">
			{breadcrumbItems.length > 0 && (
				<section className="breadcrumb-section">
					<div className="breadcrumb_Wrapper">
						<Breadcrumb items={breadcrumbItems} />
					</div>
					<div className="breadcrumb_btn_Wrapper">
						{breadCrumbCustomItem && breadCrumbCustomItem()}
					</div>
				</section>
			)}
			{children && children}
			<section className="title-section">
				<div className="header-container">
					{onBackBtnClick && <BackButton goBack={onBackBtnClick} />}
					{autoSaveValue && <Autosave autoSaveValue={autoSaveValue} />}
					<div className="title-container">
						<div className="title-menu">
							<Icon
								icon="menu"
								className="hamburger-menu-icon side-menu"
								onClick={menu}
							/>
							<h3 className="title">{title}</h3>
						</div>
						{subtitle && <span className="sub-title">{subtitle}</span>}
					</div>
				</div>
				<div className="btns-container">
					{customItem && customItem()}
					{onActionClick && (
						<button type="button" disabled={actionDisabled} onClick={onActionClick}>
							{actionTitle}
						</button>
					)}
				</div>
			</section>
		</div>
	);
};

export { PageHeader };

PageHeader.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	onBackBtnClick: PropTypes.func,
	autoSaveValue: PropTypes.oneOf([PropTypes.instanceOf(Date), PropTypes.string]),
	actionTitle: PropTypes.string,
	onActionClick: PropTypes.func,
	actionDisabled: PropTypes.bool,
	customItem: PropTypes.func,
	breadcrumbItems: PropTypes.array,
};
