import React from 'react';
import { DateTimePicker } from './DateTimePicker';

export const DateTimePickerPattern = () => {
	const handleDateSubmit = date => {
		console.log(date);
	};

	return (
		<div style={{ width: '300px' }}>
			<DateTimePicker onSubmit={handleDateSubmit} />
		</div>
	);
};
