import React from 'react';
import { Tooltip, Button } from 'componentsV2';
import { NavLink } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import './OldSafetyPlanTooltip.scss';

export const OldSafetyPlanTooltip = ({
	children,
	message,
	editPermissionOnBinders,
	binderId = null,
	...restProps
}) => (
	<Tooltip
		hoverCloseDelay={300}
		tooltipContent={
			<div className="old-safety-plan-tooltip">
				<p className="old-safety-plan-tooltip-title">{message}</p>
				{editPermissionOnBinders && (
					<NavLink
						exact
						to={binderId ? `/binders/view/${binderId}` : '/binders'}
						className="old-safety-plan-tooltip-navigation"
						target="_blank"
					>
						<Button
							className="old-safety-plan-tooltip-navigation-button"
							type="ghostPrimary"
							size="sm"
							text={
								<div className="old-safety-plan-tooltip-navigation-button-content">
									<p className="old-safety-plan-tooltip-navigation-button-content-title">
										Go to binders
									</p>
									<Icon icon="arrow-right" />
								</div>
							}
						/>
					</NavLink>
				)}
			</div>
		}
		theme="light"
		minimal={true}
		{...restProps}
	>
		{children}
	</Tooltip>
);
