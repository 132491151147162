import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ActionsButton, Button } from 'componentsV2';
import { RollCallAndReunificationContext } from '../../../RollCallAndReunification';
import { ADD_NOTE_MODAL, STUDENT_HISTORY_MODAL } from '../../../Modals/consts';

const ActionListButton = ({ text, icon, onClick }) => (
	<Button
		key={text}
		text={text}
		icon={icon}
		size="md"
		type="ghostDefault"
		wrapperClass="displayBlock"
		className="actionButtonElement"
		onClick={onClick}
	/>
);

export const StaffActionsList = ({ student }) => {
	const { setModalToShow, setSelectedStudent } = useContext(RollCallAndReunificationContext);
	const onClickAddNoteHandler = () => {
		setSelectedStudent(student);
		setModalToShow(ADD_NOTE_MODAL);
	};

	const onClickHistoryHandler = () => {
		setSelectedStudent(student);
		setModalToShow(STUDENT_HISTORY_MODAL);
	};

	return (
		<ActionsButton>
			<ActionListButton text="History" icon="history" onClick={onClickHistoryHandler} />
			<ActionListButton text="Add note" icon="document" onClick={onClickAddNoteHandler} />
		</ActionsButton>
	);
};

ActionListButton.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

StaffActionsList.propTypes = {
	student: PropTypes.object,
};
