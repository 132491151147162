import React,{useContext} from "react";
import { AlertModal, Button ,Intent, FeaturedIcon} from "componentsV2";
import {ToasterContext} from 'pages/App';

export const MarkSectionComplete = ({
    isOpen,
    closeModal,
    dispatch,
    sectionIndex, 
    subsectionIndex,
	template,
	isComplete,
	activeSection,
}) => {

	const toaster = useContext(ToasterContext);

	const markSectionsComplete = () => {

		toaster('You have successfully marked "' +activeSection.title+ '" as ' + ((activeSection.complete) ? 'incomplete' : 'complete'), Intent.SUCCESS,<FeaturedIcon icon={'tick'} type={'Success'} size={'md'} shape={'circleIcon'}/>);
		if (subsectionIndex === null) {
			dispatch({
				type: 'MARK_SECTION_COMPLETE',
				payload: isComplete,
				sectionIndex: sectionIndex,
			});
			
		}else{
			dispatch({
				type: 'MARK_SUBSECTION_COMPLETE',
				payload: isComplete,
				sectionIndex: sectionIndex,
				subsectionIndex,
			});
		}

		closeModal();

		
	};

    return (
        <AlertModal
            isOpen={isOpen}
            onClose={closeModal}
            size='medium'
            title={'Are you sure you would like to mark "' +activeSection.title+ '" as ' + ((activeSection.complete) ? 'incomplete' : 'complete') +'?'}
			showCloseButton={false}
			alertConfirmButton={
				<Button
					text={"Yes, mark as "+ ((activeSection.complete) ? 'incomplete' : 'complete')}
					type="primaryDefault"
					size="md"
					onClick={()=>{markSectionsComplete()}}
                />
			}
            alertCancelButton={
				<Button
					text="No, go back"
					type="tertiary"
					size="md"
					onClick={closeModal}
				/>
			}
        />
    )
}
