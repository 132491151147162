import React from 'react';
import { Modal, ModalFooter } from "../";
import { Button } from "../../";

import PropTypes from 'prop-types';

export const ConfirmationModal = ({
    title, 
    subtitle = null,
    isOpen = false,
    closeModal = () => {},
    btnsSize="lg",
    confirmBtn = {
        title: '',
        type : null,
        onClick: () => {}
    },
    cancelBtn={
        title: '',
        type : null,
        onClick: () => {}
    },
    loading = false,
    icon = {
        icon: null,
        iconColor: null
    },
    ...props
}) => {
    const cancelButton = (
        <Button
            text= {cancelBtn.title}
            type= {cancelBtn.type || 'tertiary'}
            size= {btnsSize}
            onClick={cancelBtn.onClick}
        />
    );

    const confirmButton = (
        <Button
            text= {confirmBtn.title}
            type= {confirmBtn.type || 'primaryDefault'}
            size= {btnsSize}
            onClick={confirmBtn.onClick}
        />
    );

    const customFooter = (
        <>
            {!loading  &&
                <ModalFooter
                    confirmButton={confirmButton}
                    cancelButton={cancelButton}
                />
            } 
        </>
    );

    return (
        <Modal
            isOpen={isOpen}
            subtitle={subtitle}
            onClose={closeModal}
            title={title}
            loading={loading}
            customFooter={customFooter}
            icon={icon.icon}
            iconColor={icon.iconColor}
            {...props}
        />
    )
}

ConfirmationModal.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    isOpen: PropTypes.bool,
    cancelBtn: PropTypes.object,
    confirmBtn: PropTypes.object,
    closeModal: PropTypes.func,
    loading: PropTypes.bool,
    btnsSize:PropTypes.string
};

