import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import './styles.css';

export const RightArrowLink = ({ item = 'Click', toLink = '#' }) => (
	<NavLink exact to={toLink}>
		<text className="link-text-container">
			{item}
			<Icon className="arrow-right-container" icon="arrow-right" />
		</text>
	</NavLink>
);

RightArrowLink.propTypes = {
	item: PropTypes.object,
	toLink: PropTypes.string,
};
