/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { findIndex } from 'lodash';

import { Dropdown } from 'components/Dropdown';
import { DrillTypesService } from 'services/DrillTypesService';
import { CardModal } from './CardModal';

const Wrapper = styled.div``;

export const AddDrillTypeModal = ({
	setModalToShow,
	onActionClick,
	newDrillType = null,
	onChange,
	excludeDrillTypes = null,
}) => {
	const [drillTypes, setDrillTypes] = useState(null);

	useEffect(() => {
		// Calling drill types service again because the parent has enable the "All drill types" option
		DrillTypesService.getAll(false).then(serviceDrillTypes =>
			setDrillTypes(
				serviceDrillTypes.filter(drillType => {
					const found = findIndex(
						excludeDrillTypes,
						exDrillType => drillType.uuid === exDrillType,
					);
					return found === -1;
				}),
			),
		);
	}, []);

	return (
		<CardModal
			isVisible
			setIsVisible={setModalToShow}
			title="Add drill type"
			actionTitle="Add"
			onActionClick={onActionClick}
			actionBtnDisabled={!newDrillType}
		>
			<Wrapper>
				<Dropdown
					label="Drill type"
					value={newDrillType}
					onChange={onChange}
					options={drillTypes}
				/>
			</Wrapper>
		</CardModal>
	);
};

AddDrillTypeModal.propTypes = {
	setModalToShow: PropTypes.func,
	onActionClick: PropTypes.func,
	newDrillType: PropTypes.object,
	onChange: PropTypes.func,
	excludeDrillTypes: PropTypes.array,
};
