import React, {useContext} from "react";
import { ToasterContext , Intent, ToasterMessage } from './';
import { FeaturedIcon, Button } from '../';

export const ToasterPattern = ()=>{
	const toaster = useContext(ToasterContext);

	const onClickHandler = () => {
		const actions =
			<div style={{ display: "flex",gap: "10px", marginTop: "8px"}}>
			<Button
				type="primaryDanger"
				text='cancel action'
				size="sm"
				onClick={() => {}}
			/>
			<Button
				type="primaryDefault"
				text='confirm action'
				size="sm"
				onClick={() => {}}
			/>
			</div>

		const message = <ToasterMessage text={'title'} supportText={'This example contains support text and icon name as string, this toast will disappear in 10 seconds'} actions={actions}/>

		/*Note: for message and icon it can based as a string directly or component to render*/

		//Example 1 "ToasterMessage Component + title + body + blueprint icon + list of actions(that passed to ToasterMessage as a prop because blueprint doesn't support multi action buttons and also with limited features)"
		//If you want a title or actions you have to use Toaster Message Component
		toaster(message, Intent.SUCCESS,'tick',10000)

		//Example 2 "Message that works like body + icon component example"
		toaster('This is an error example with string message & FeaturedIcon component, will disappear in 15 seconds', Intent.DANGER,<FeaturedIcon icon={'error'} type={'Error'} size={'md'} shape={'circleIcon'}/>,15000)

		toaster('This Message with "Default" class & timer of 5 seconds (default timer)!!!', Intent.NONE)
		toaster('his Message with "Primary" class & close button & timer of 20 seconds !!!!', Intent.PRIMARY,<FeaturedIcon icon={'error'} type={'Primary'} size={'md'} shape={'circleIcon'}/>,20000,true)

		toaster(message, Intent.WARNING,<FeaturedIcon icon={'error'} type={'Warning'} size={'md'} shape={'circleIcon'}/>,10000,true)
	}

	return (
		<div className="row">
			<div className="col-md-3">
				<Button
					text="show"
					large
					// intent="secondary"
					onClick={onClickHandler}
				/>
			</div>
		</div>
	)
}