import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, ActionsButton } from 'componentsV2';
import { MapsContext } from '../../../Providers';

export const AreaItemActionsList = ({
	item,
	editPermission,
	isEditMode,
	floorplan,
	handleEditArea,
	handleCopyArea,
	handleViewShapeContent,
	viewPermissionOnSiteId,
	layer,
}) => {
	const MapCTX = useContext(MapsContext);

	const floorplanArchived = !!floorplan?.archivedBy;
	const hasEditPermissionAndEditMode = editPermission && isEditMode;
	return (
		<ActionsButton popoverClassName="map-layers-actions-list">
			{(viewPermissionOnSiteId || floorplan.isVisibleToAll) &&
				!hasEditPermissionAndEditMode && (
					<Button
						text="View"
						icon="eye-open"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => handleViewShapeContent(item)}
					/>
				)}
			{hasEditPermissionAndEditMode && !floorplanArchived && (
				<Button
					text="Edit"
					icon="edit"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => handleEditArea(item.id)}
				/>
			)}
			{hasEditPermissionAndEditMode && !floorplanArchived && (
				<Button
					text="Copy"
					icon="duplicate"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => handleCopyArea(item.id)}
				/>
			)}
			{hasEditPermissionAndEditMode && !floorplanArchived && (
				<Button
					text="Delete"
					icon="trash"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => MapCTX.showAreaDeleteModal(item, layer)}
				/>
			)}
		</ActionsButton>
	);
};

AreaItemActionsList.propTypes = {
	item: PropTypes.object.isRequired,
	editPermission: PropTypes.bool.isRequired,
	floorplan: PropTypes.object.isRequired,
	handleEditArea: PropTypes.func.isRequired,
	handleCopyArea: PropTypes.func.isRequired,
};
