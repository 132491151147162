import React, { useEffect, useState, useReducer, useContext } from 'react';
import {
	Button,
	ContentSwitcher,
	BootstrapedTable,
	sortingStyle,
	Dropdown,
	LoadingModal,
	ToasterContext,
	Intent,
	FeaturedIcon,
	EmptyState,
	noResult,
	ConfirmationModal,
	AlertModal,
} from 'componentsV2';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import './AssessmentsTable.scss';
import { formatDate } from 'utilities/dates';
import { Link } from 'react-router-dom';
import { SearchBar } from 'components/SearchBar';
import { SiteDropdownSingleSelect } from 'components/SiteDropdownSingleSelect';
import { HttpService } from 'networking/HttpService';
import { StartNewAssessmentModal } from './StartNewAssessmentModal';
import { TableStatusCell } from './TableStatusCell';
import { TableActions } from './TableActions';
import { DeleteDrillTypeModal } from '../../../../DrillsV2/components/Settings/StateRequirements/components/DeleteDrillTypeModal';
import { AssessmentService } from 'services';
import { AssessSettings } from '../ListAssessments';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';

export const AssessmentsTable = ({
	assessments,
	years,
	history,
	initAssessments,
	editPermission,
}) => {
	const dispatch = useDispatch();
	const assessmentFilterStateData = useSelector(state => state.route.assessmentFilterStateData);
	const [displayedAssessments, setDisplayedAssessments] = useState(assessments);
	const [modalToShow, setModalToShow] = useState('');
	const [modaLoading, setModalLoading] = useState(false);
	const [modalInputProps, setModalInputProps] = useState({});
	const [permissionOnBuilding, setPermissionOnBuilding] = useState(editPermission);
	const toaster = useContext(ToasterContext);
	const assessSettings = useContext(AssessSettings);
	const permissions = useSelector(state => state.route.permissions);

	const handleCloseModal = () => {
		setModalToShow('');
		setModalInputProps({});
	};

	const checkBuildingPermission = value => {
		const permission = checkPermission('site_assess_edit', permissions, value.id);
		setPermissionOnBuilding(permission);
	};

	const handleShowModal = (modalToShow, modalInputProps) => {
		setModalToShow(modalToShow);
		if (modalInputProps) setModalInputProps(modalInputProps);
	};

	const columns = [
		{
			dataField: 'building',
			hidden: true,
		},
		{
			dataField: 'name',
			text: 'Assessment title',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			sortFunc: (a, b, order) => {
				if (order === 'desc') return a.localeCompare(b);
				else return b.localeCompare(a);
			},
			classes: 'first-column',
			formatter: (cellValue, row) => (
				<>
					<Link
						exact="true"
						to={{
							pathname: `/assessV2/assessment/${row.id}`,
							state: { data: row },
						}}
					>
						{cellValue}
					</Link>
					{row.status === 'Completed' && assessSettings.assessIsLocked && (
						<div className="assessment-title-subtext">Locked</div>
					)}
				</>
			),
		},
		{
			dataField: 'year',
			text: 'School year',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (year, row) => `${year}-${year + 1}`,
		},
		{
			dataField: 'status',
			text: 'Status',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			sortFunc: (a, b, order) => {
				if (order === 'desc') return a.localeCompare(b);
				else return b.localeCompare(a);
			},
			formatter: (cellValue, row) => <TableStatusCell status={cellValue} />,
		},
		{
			dataField: 'createdDate.date',
			text: 'Date created',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			sortFunc: (a, b, order) => {
				if (order === 'desc') return a.localeCompare(b);
				else return b.localeCompare(a);
			},
			formatter: (cellValue, row) => formatDate(cellValue),
		},
		{
			dataField: 'id',
			text: '',
			formatter: (cell, row) => (
				<TableActions
					row={row}
					history={history}
					onShowModal={handleShowModal}
					onDownloadAssessment={onDownloadAssessment}
				/>
			),
		},
	];

	const filterStateReducer = (currentFilters, action) => {
		switch (action.type) {
			case 'status':
				if (action.payload[0] !== 'all' && action.payload.length > 0)
					return { ...currentFilters, status: action.payload[0] };
				else {
					const { status, ...filtersWithoutStatus } = currentFilters;
					return { ...filtersWithoutStatus };
				}
			case 'name':
				if (action.payload) return { ...currentFilters, name: action.payload };
				else {
					const { name, ...filtersWithoutName } = currentFilters;
					return { ...filtersWithoutName };
				}
			case 'site':
				if (action.payload.value !== 'all')
					return { ...currentFilters, site: action.payload };
				else {
					const { site, ...filtersWithoutSite } = currentFilters;
					return { ...filtersWithoutSite };
				}
			case 'year':
				if (action.payload.value !== 'all')
					return { ...currentFilters, year: action.payload };
				else {
					const { year, ...filtersWithoutYear } = currentFilters;
					return { ...filtersWithoutYear };
				}
			default:
				break;
		}
	};

	const [filterState, dispatchFilter] = useReducer(filterStateReducer, assessmentFilterStateData);

	const filterAppliers = {
		status: (assessments, filterValue) => {
			return assessments.filter(assessment => assessment.status === filterValue);
		},
		name: (assessments, filterValue) => {
			return assessments.filter(assessment =>
				assessment.name.toLowerCase().includes(filterValue.toLowerCase()),
			);
		},
		site: (assessments, filterValue) => {
			return assessments.filter(assessment => assessment.building.id === filterValue.value);
		},
		year: (assessments, filterValue) => {
			return assessments.filter(assessment => assessment.year === Number(filterValue.value));
		},
	};

	const applyFilters = (assessments, filterState) => {
		return Object.entries(filterState).reduce(
			(filteredAssessments, [filterName, filterValue]) => {
				const applier = filterAppliers[filterName];
				return applier(filteredAssessments, filterValue);
			},
			assessments,
		);
	};

	const onClickCompleteIncompleteAssesment = () => {
		setModalLoading(true);
		AssessmentService.markCompleteIncomplete(modalInputProps?.id, !modalInputProps?.complete)
			.then(() => {
				initAssessments();
				toaster(
					`You have successfully marked ${modalInputProps?.name} as ${
						modalInputProps.complete ? 'incomplete' : 'complete'
					}.`,
					modalInputProps.complete ? Intent.NONE : Intent.SUCCESS,
					modalInputProps.complete ? (
						<FeaturedIcon icon="ban-circle" size="md" type="Gray" />
					) : (
						''
					),
				);
			})
			.catch(() => {
				toaster(
					`There was an error marking the assessment. Please try again.`,
					Intent.DANGER,
				);
			})
			.finally(() => {
				handleCloseModal();
				setModalLoading(false);
			});
	};

	const onClickDeleteAssessment = () => {
		HttpService({
			method: 'DELETE',
			route: `/assessments/${modalInputProps?.id}`,
		})
			.then(() => {
				toaster(
					`You have successfully deleted ${modalInputProps?.name}.`,
					Intent.NONE,
					<FeaturedIcon icon="trash" size="md" type="Gray" />,
				);
				initAssessments();
			})
			.catch(() => {
				toaster(
					`There was an error deleting the assessment. Please try again.`,
					Intent.DANGER,
				);
			})
			.finally(() => {
				setModalToShow('');
				setModalInputProps({});
			});
	};

	useEffect(() => {
		dispatch({
			type: 'SET_AEESSMENT_FILTER_STATE_DATA',
			payload: {
				...filterState,
				status: filterState.status ? [filterState.status] : filterState.status,
			},
		});
		const filteredAssessments = applyFilters(assessments, filterState);
		setDisplayedAssessments(filteredAssessments);
	}, [filterState]);

	const onDownloadAssessment = async row => {
		setModalToShow('download_assessment');
		try {
			let sectionsIDs = [];
			let assesmentSections = await AssessmentService.getAssessmentSections(row?.id);
			assesmentSections.data.sections
				.filter(section => section.isHidden === false)
				.map(section => {
					sectionsIDs.push(section.id);
				});
			if (sectionsIDs.length > 0) {
				const pdfData = await AssessmentService.downloadAssessment(row?.id, sectionsIDs);
				const filename = `${row?.name} ${format(new Date(), 'hh-mm-ss a')}`;
				const data = new Blob([pdfData], {
					type: 'application/pdf',
				});
				const csvURL = window.URL.createObjectURL(data);
				const fileLink = document.createElement('a');
				fileLink.href = csvURL;
				fileLink.setAttribute('download', `${filename}.pdf`);
				fileLink.click();
			} else {
				toaster(
					`There are no sections to download.`,
					Intent.DANGER,
					<FeaturedIcon icon="error" size="md" type="Error" shape="circleIcon" />,
				);
			}
		} catch (error) {
			toaster(
				`There was an error downloading the assessment. Please try again.`,
				Intent.DANGER,
				<FeaturedIcon icon="error" size="md" type="Error" shape="circleIcon" />,
			);
			console.log('err', error);
		}
		setModalToShow('');
	};

	return (
		<>
			<div className="assessments-table">
				<div className="header">
					<h2 className="title">Assessments</h2>
					{permissionOnBuilding && (
						<Button
							icon="plus"
							text="New assessment"
							type="primaryDefault"
							size="lg"
							onClick={() => setModalToShow('start_new_assessment')}
						/>
					)}
				</div>
				<div className="filters">
					<ContentSwitcher
						onClick={value => dispatchFilter({ type: 'status', payload: value })}
						tabs={[
							{ id: 'all', label: 'View all' },
							{ id: 'In Progress', label: 'Incomplete' },
							{ id: 'Completed', label: 'Complete' },
						]}
						defaultActiveTabs={filterState.status ? filterState.status : ['all']}
					/>
					<SearchBar
						placeholder="Search by assessment title"
						icon="search"
						onSearch={value => dispatchFilter({ type: 'name', payload: value })}
						value={filterState.name || ''}
					/>
					<SiteDropdownSingleSelect
						label={null}
						placeholder="Select site"
						onChange={value => {
							checkBuildingPermission(value);
							dispatchFilter({ type: 'site', payload: value });
						}}
						selectedSite={filterState.site}
						includeAllOption={true}
					/>
					<Dropdown
						label={null}
						placeholder="Select school year"
						onChange={value => dispatchFilter({ type: 'year', payload: value })}
						options={[
							{ label: 'All years', value: 'all' },
							...Object.entries(years).map(([key, value]) => {
								return { label: value, value: key };
							}),
						]}
						value={filterState.year}
					/>
				</div>
				{displayedAssessments.length === 0 ? (
					<EmptyState
						header="No assessments found"
						description="Your search didn't match any assessments. Please try again or add a new assessment."
						icon={noResult}
						className="empty-state-border"
					/>
				) : (
					<BootstrapedTable
						keyField="id"
						data={displayedAssessments}
						columns={columns}
						remote={false}
					/>
				)}
			</div>
			{modalToShow === 'start_new_assessment' && (
				<StartNewAssessmentModal
					history={history}
					onClose={handleCloseModal}
					initAssessments={initAssessments}
				/>
			)}
			{modalToShow === 'delete_assessment' && (
				<DeleteDrillTypeModal
					isOpen
					titleData={modalInputProps?.name}
					onClickDelete={onClickDeleteAssessment}
					onCloseModal={handleCloseModal}
				/>
			)}
			{modalToShow === 'download_assessment' && (
				<LoadingModal isOpen loading={true} text="Downloading" onClose={handleCloseModal} />
			)}
			{modalToShow === 'mark_assessment_complete_incomplete' && (
				<ConfirmationModal
					isOpen
					loading={modaLoading}
					title={`Are you sure you want to mark ${modalInputProps.name} as ${
						modalInputProps.complete ? 'incomplete' : 'complete'
					}?`}
					subtitle={`You can mark it as ${
						modalInputProps.complete ? 'complete' : 'incomplete'
					} at a later time.`}
					showCloseButton={false}
					onClose={handleCloseModal}
					cancelBtn={{
						title: 'No, go back',
						onClick: handleCloseModal,
					}}
					confirmBtn={{
						title: modalInputProps.complete
							? 'Yes, mark as incomplete'
							: 'Yes, mark as complete',
						onClick: onClickCompleteIncompleteAssesment,
						type: 'primaryDefault',
					}}
				/>
			)}
			{modalToShow === 'incomplete_notice' && (
				<AlertModal
					isOpen
					title="This assessment is incomplete."
					subtitle="This assessment will be marked as incomplete until all questions are answered."
					onClose={handleCloseModal}
					icon="error"
					iconColor="warning"
					alertConfirmButton={
						<Button
							text="OK"
							type="primaryDefault"
							size="md"
							onClick={handleCloseModal}
						/>
					}
				/>
			)}
		</>
	);
};
