import React, { useEffect, useState } from 'react';
import { RolesService } from '../../../services/RolesService';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Switch } from '@blueprintjs/core';
import './css/index.scss';
import { Button } from 'components/Button';
import { NoResultFound } from 'components/NoResultFound';
import { NoResultFoundIcon } from '../../../assets/icons';
import { SearchBar } from 'components/SearchBar';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission, filterSitesPerPermissionCode } from 'utilities/permissions';
import 'components/Forms/index.css';
import UserManagement from '..';
import ActionsColumn from './ActionsColumn';
import ActionsAddButton from 'components/ActionsAddButton';
import MoreCard from 'components/MoreCard';
import useSelectedSites from 'hooks/useSelectedSites';
import AddEditRoleModal from './AddEditRoleModal';
import { BootstrapedTable, LoadingSpinner, sortingStyle } from 'componentsV2';

const UserRoles = () => {
	const [roles, setRoles] = useState([]);
	const [modalToShow, setModalToShow] = useState(null);
	const [roleFormType, setRoleFormType] = useState('');
	const [roleFormTitle, setRoleFormTitle] = useState('');
	const [modalRoleId, setModalRoleId] = useState(null);
	const [showDisabled, setshowDisabled] = useState(0);
	const [hasSearch, setHasSearch] = useState(false);
	const [search, setSearch] = useState(null);
	const [selectedSites, setSelectedSites] = useState([]);
	const [loading, setLoading] = useState(true);
	const permissions = useSelector(state => state.route.permissions);
	const [sitesLoaded, setSitesLoaded] = useState({
		data: [],
		loaded: false,
	});
	const defaultSelectedSites = useSelectedSites();
	const dispatch = useDispatch();

	useEffect(() => {
		setSelectedSites([]);
	}, []);

	useEffect(() => {
		fetchRoles();
	}, [showDisabled, search, selectedSites]);

	useEffect(() => {}, [sitesLoaded]);

	const fetchRoles = () => {
		var selectedSitesValues = [];
		selectedSites?.map(selectedSite => {
			if (selectedSite.value != '*') {
				selectedSitesValues.push(selectedSite.value);
			}
		});

		RolesService.search({
			includeDisabled: showDisabled,
			search: search,
			sites: selectedSitesValues,
		})
			.then(response => {
				setRoles(response.data);
				setLoading(false);
			})
			.catch(error => {
				setLoading(false);
			});
	};

	const columns = [
		{
			dataField: 'title',
			text: 'Role Name',
			classes: 'bold-700',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'description',
			text: 'Description',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'createdBy',
			text: 'Created By',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'allSites',
			text: 'Site',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				const formattedSites = !!row.sites ? row.sites.map(site => site.name) : [];
				return (
					<MoreCard
						items={formattedSites}
						showText={value ? 'All sites' : null}
						visibleOptions={1}
						id={row.id}
						borderIncluded={false}
					/>
				);
			},
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'isEnabled',
			text: 'Status',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (value == true ? 'Enabled' : 'Disabled'),
			headerStyle: { width: '16%' },
		},
		{
			dataField: '',
			text: 'Actions',
			formatter: (value, row) => (
				<ActionsColumn
					row={row}
					updateDataCallBack={fetchRoles}
					sitesLoadedData={sitesLoaded.data}
				/>
			),
			headerStyle: { width: '16%' },
		},
	];

	const switchVisibility = event => {
		event.target.checked === true ? setshowDisabled(1) : setshowDisabled(0);
	};

	const showRoleModal = (modal, role = null) => {
		if (role != null) {
			setRoleFormType('edit');
			setRoleFormTitle('Edit');
			setModalRoleId(role.id);
		} else {
			setRoleFormType('add');
			setRoleFormTitle('Add');
			setModalRoleId(null);
		}

		setModalToShow(modal);
	};

	const searchRoleHandler = value => {
		setSearch(value);
	};

	const searchOnFocusHandler = () => {
		setHasSearch(true);
	};

	const searchOnFocusOutHandler = () => {
		setHasSearch(false);
	};

	const onSelectSitesHandler = sites => {
		setSelectedSites(sites);
		dispatch({
			type: 'SET_SELECTED_SITES',
			payload: sites,
		});
	};

	const handleSitesLoadedFromEndPoint = object => {
		const filteredSites = filterSitesPerPermissionCode('user_edit', permissions, object.data);
		const filteredObject = { ...object, data: filteredSites };
		setSitesLoaded(filteredObject);
	};

	return (
		<UserManagement defaultSelectedTab="user-roles">
			<div className="row">
				<div className="col-md-4 ">
					<SitesDropdownMultiSelect
						value={selectedSites}
						onChange={onSelectSitesHandler}
						allowSelectAll={false}
						visibleOptions={1}
						onDataLoad={handleSitesLoadedFromEndPoint}
						labelState={false}
					/>
				</div>
				<div className="col-md-3 " />
				<div className="col-md-5 search-bar-input">
					<SearchBar
						placeholder={'Search Role'}
						onSearch={searchRoleHandler}
						onFocus={searchOnFocusHandler}
						onBlur={searchOnFocusOutHandler}
						hasSearch={hasSearch}
					/>
				</div>
				<div className="col-md-12 d-flex justify-content-between row-break-space">
					<div className="include-disabled-users">
						<Switch
							label="Include disabled roles"
							className="mb-0 inline-switch"
							onChange={switchVisibility}
						/>
					</div>
					{checkPermission('user_edit', permissions) && sitesLoaded.loaded && (
						<div className="">
							<Button
								intent="primary"
								text={<ActionsAddButton text="Create New Role" />}
								onClick={() => showRoleModal('roleForm', null)}
							/>
						</div>
					)}
				</div>
			</div>
			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<>
					{hasSearch && roles.length == 0 ? (
						<NoResultFound header="No Roles Found" icon={NoResultFoundIcon}>
							<p style={{ fontFamily: 'Nunito Sans' }}>
								{' '}
								Try changing the search criteria, <br />
								or click <a style={{ color: '#9F1C3A' }}>Create New Role</a> to add
								a new role
							</p>
						</NoResultFound>
					) : sitesLoaded.loaded ? (
						<BootstrapedTable
							keyField="id"
							className="mt-3"
							data={roles}
							columns={columns}
							bordered={false}
							remote={false}
						/>
					) : null}
				</>
			)}
			{modalToShow === 'roleForm' && (
				<AddEditRoleModal
					formType={roleFormType}
					formTitle={roleFormTitle}
					setModalToShow={setModalToShow}
					closeModal={() => setModalToShow(false)}
					roleId={modalRoleId}
					updateDataCallBack={fetchRoles}
					sitesLoadedData={sitesLoaded.data}
					selectedSites={selectedSites}
				/>
			)}
		</UserManagement>
	);
};

export default UserRoles;
