import React from "react";
import { warning } from "assets/icons";
import NormalModal from "../../../components/Modals/NormalModal";

const ChangesConfirmationModal = ({onConfirmAction, onCancelAction, bodyMessage, backBtnTitle, confirmBtnTitle, headerMessage}) => {
    return (
        <NormalModal
            setModalToShow="ChangesConfirmationModal"
            onConfirmAction={onConfirmAction}
            onCancelAction={onCancelAction}
            header= {headerMessage}
            confirmBtnTitle={confirmBtnTitle}
            cancelBtnTitle={backBtnTitle}
        >
            <div className='text-center'>
                <img src={warning} height='60' width='60' />
            </div>
            <p className='mt-2'>
                {bodyMessage}
            </p>
        </NormalModal>
    );
}

export default ChangesConfirmationModal;