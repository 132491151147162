import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'componentsV2';
import { PendingUpdates,
  SectionsComparison, 
  FooterActions, 
  COMPLETED_STATUS, 
  CURRENT_STATUS, 
  PENDING_STATUS 
} from './';
import { PlanService } from 'services/PlanService';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import './css/ViewUpdatesModal.scss';
import { AlertModal } from 'componentsV2';

const ViewUpdatesModal = ({
  template,
  closeModal,
  reloadTemplate
}) => {
  const [templateUpdates, setTemplateUpdates] = useState([]);
  const [currentUpdate, setCurrentUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [templateUpdated, setTemplateUpdated] = useState(false); 
  const toaster = useContext(ToasterContext);
  const [editorContent, setEditorContent] = useState(null);
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  useEffect(() => {
      sortData()
  }, [])

  const sortData = () => {
      // remove reset type from array
      const allUpdates = template.allUpdates.filter(update => update.type !== 'reset');

      // mapping total updates
      const totalUpdates = allUpdates.map((templateUpdate, index) => {
          const parsedContent = JSON.parse(templateUpdate.updatedContent);
          // get target section/subsectino to update
          let sectionType = 'section';
          let targetUpdate = template.sections.find(item => item.id === parsedContent?.actualSectionId);

          if (parsedContent.actualSubsectionId ) {
              sectionType = 'subsection';
              targetUpdate = targetUpdate?.subsections?.find(subSection => subSection.id === parsedContent.actualSubsectionId);
          }

          return {
              ...templateUpdate,
              targetUpdate,
              sectionType,
              updatedContent: parsedContent,
              status: index !== 0 ? PENDING_STATUS : CURRENT_STATUS
          }
      })
      setTemplateUpdates(totalUpdates);
      const getCurrentUpdate = totalUpdates.find(oneUpdate => oneUpdate.status != COMPLETED_STATUS)

      setCurrentUpdate(getCurrentUpdate);
      setLoading(false);
  };

  const addNewSection = updatedContent => {
      return {
          id: updatedContent.actualSectionId,
          title: updatedContent.title,
          subsections: [],
          content: updatedContent.content,   
      }
  }

  const getBaseFile = (baseFile) => {
      return {
          coreTemplate: baseFile?.coreTemplate ? baseFile.coreTemplate : null,
          name: baseFile?.name ? baseFile.name : "",
          size: baseFile?.size ? baseFile.size : "",
          id: baseFile?.id ? baseFile.id : null,
      }
  }

  const addNewSubSection = updatedContent => {
      const subSection = {}
      subSection.id = updatedContent.actualSubsectionId,
      subSection.title = updatedContent.title;
      subSection.content = updatedContent.content;

      if (updatedContent.is_document) {
          subSection.fileSection = true;
          subSection.reupload = updatedContent.reupload == true ? true : false;
          subSection.baseFile = getBaseFile(updatedContent.baseFile);
          subSection.valueFile = {
              name: '',
              size: '',
              id: null,
          };
      }
      return subSection;
  }

  const updateSectionContent = async (section, updatedContent) => {
      section.title = updatedContent.title;
      section.content = updatedContent.content;

      if (updatedContent.is_document) {
          section.fileSection = true;
          section.reupload = updatedContent.reupload == true ? true : false;
          section.baseFile = getBaseFile(updatedContent.baseFile);
          section.valueFile = {
              name: '',
              size: '',
              id: null,
          };
      }
      return section;
  }

  const updateStatus = (currentUpdate) => {
      const currentUpdateIndex = templateUpdates.findIndex(templateUpdate => templateUpdate.id === currentUpdate.id);

      setTemplateUpdates(oldTemplateUpdates => (
          oldTemplateUpdates.map((oldTemplateUpdate, index) => {
              if( currentUpdateIndex === index) {
                  oldTemplateUpdate.status = COMPLETED_STATUS;
              } else if ( currentUpdateIndex + 1 === index) {
                  oldTemplateUpdate.status = CURRENT_STATUS;
                  setCurrentUpdate(oldTemplateUpdate)
                  scrollToActiveUpdate(oldTemplateUpdate)
              }
              return {
                  ...oldTemplateUpdate,
              }
          })
      ))
      if(currentUpdateIndex === templateUpdates.length-1) {
          reloadTemplate()
          closeModal()
      }
  }

  const findSection = sectionId => {
      return template.sections.find(section => section.id === sectionId);
  }

  const findSectionIndex = sectionId => {
      return template.sections.findIndex(section => section.id === sectionId);
  }

  const findSubsection = (section, subSectionId) => {
      return section.subsections?.find(subSection => subSection.id === subSectionId);
  }

  const findSubsectionIndex = (section, subSectionId) => {
      return section.subsections?.findIndex(subSection => subSection.id === subSectionId);
  }

  const acceptUpdate = async (update) => {
      const { updatedContent, targetUpdate, sectionType, type } = update;

      if( sectionType === 'section' ) { // Section Add/Update/Delete
          const targetSectionIndex = await template.sections.findIndex(section => section.id === targetUpdate?.id);
          
          if(type === 'delete') {
              if(targetSectionIndex > -1) {
                  template.sections.splice(targetSectionIndex, 1);
              }
          } else {
              if( targetUpdate ) {
                  // update existing section
                  const sectionUpdated = await updateSectionContent(targetUpdate, updatedContent);

                  if(updatedContent?.index !== targetSectionIndex) {//reOrder subsections
                      reorderSections(targetSectionIndex, updatedContent.index, sectionUpdated, true);                            
                  } else {
                      template.sections[targetSectionIndex] = sectionUpdated;
                  }

              } else {
                  // new main section will be added
                  const newSection = await addNewSection(updatedContent);
                  
                  if(updatedContent.index && typeof template.sections[updatedContent?.index] === 'undefined') {
                      // index does'nt exist
                      template.sections.push(newSection);
                  } else { // index exists
                      reorderSections(targetSectionIndex, updatedContent.index, newSection);
                  }
              }
          }

      } else { // Sub Section Add/Update/Delete
          const parentSection = findSection(updatedContent.actualSectionId);
          const parentSectionIndex = findSectionIndex(updatedContent.actualSectionId);
          if( parentSection ) {
              const targetSubSection = findSubsection(parentSection, updatedContent.actualSubsectionId);
              const targetSubSectionIndex = findSubsectionIndex(parentSection, updatedContent.actualSubsectionId);
              
              if ( type === 'delete') {
                  // delete Subsection
                  if(targetSubSectionIndex > -1) {
                      parentSection.subsections.splice(targetSubSectionIndex, 1);
                  }
              } else {
                  if ( targetSubSection && targetSubSectionIndex > -1) {
                      // update existing Subsection
                      const updatedSection = await updateSectionContent(targetSubSection, updatedContent);
                      
                      if(updatedContent?.index !== targetSubSectionIndex) {//reOrder subsections
                          reorderSubsections(parentSectionIndex, targetSubSectionIndex, updatedContent.index, updatedSection, true);
                      } else {
                          template.sections[parentSectionIndex].subsections[targetSubSectionIndex] = updatedSection;
                      }
                  } else {
                      // add new Subsection
                      const newSubSection = await addNewSubSection(updatedContent)
                      if(updatedContent.index && typeof template.sections[parentSectionIndex].subsections[updatedContent?.index] === 'undefined') {
                          // index does'nt exist
                          template.sections[parentSectionIndex].subsections.push(newSubSection);
                      } else { // index exists
                          reorderSubsections(parentSectionIndex, targetSubSectionIndex, updatedContent.index, newSubSection);
                      }
                  }
              }
          }
      }
  }
  const reorderSubsections = (sectionIndex, oldIndex , newIndex, updatedSection, isUpdate) => {

      if(isUpdate) {
          template.sections[sectionIndex].subsections.splice(oldIndex, 1)
      }
      template.sections[sectionIndex].subsections.splice(newIndex, 0, updatedSection);
  }


  const reorderSections = (oldIndex, newIndex, updatedSection, isUpdate) => {
      
      if(isUpdate) {
          template.sections.splice(oldIndex, 1)
      }
      template.sections.splice(newIndex, 0, updatedSection);
  }

  const updateTemplateSections = async () => {
      if(editorContent) {
          const cleanContent = cleanContentFromVars(editorContent);
          if(currentUpdate.sectionType === 'section') { // update section content

              const sectionIndex = findSectionIndex(currentUpdate.updatedContent?.actualSectionId);
              if(sectionIndex > -1) {
                  template.sections[sectionIndex].content = cleanContent;
              }
          } else { //update sub section content
              const section = findSection(currentUpdate.updatedContent?.actualSectionId)
              const sectionIndex = findSectionIndex(currentUpdate.updatedContent?.actualSectionId);
              const subSectionIndex = findSubsectionIndex(section, currentUpdate.updatedContent?.actualSubsectionId);
              if(sectionIndex > -1 && subSectionIndex > -1) {
                  template.sections[sectionIndex].subsections[subSectionIndex].content = cleanContent;
              }
          }
      }
  }
  
  const cleanContentFromVars = (content) => {
      content = content.replaceAll(
          `class="diff-remove"`,
          `class=""`,
      );
      content = content.replaceAll(
          `class="diff-add"`,
          `class=""`,
      );
      return content;
  };

  const onEditorChange = value => {
      setEditorContent(value)
  }

  const onApplyAllAction = async () => {
      setLoading(true);
      // accept all updates

      const availableTemplateUpdate = templateUpdates.filter( update => update.status !== 'completed');
  
      for (const templateUpdate of availableTemplateUpdate) {
          await acceptUpdate(templateUpdate);
      }
      
      //save template 
      await updateTemplateContent();

      // call apply all api
      PlanService.applyAllUpdates(template.id)
          .then(response => {
              if (response.statusCode === 201) {
                  toaster('You have successfully applied these updates.', Intent.SUCCESS);
                  setLoading(false)
                  closeModal()
                  reloadTemplate()
              } else {
                  toaster(response.error, Intent.DANGER);
              }
          })
          .catch(err => {
              toaster(err, Intent.DANGER);
          })
  }

  const useUpdated = async () => {
      setShowLoadingModal(true)
      setTemplateUpdated(true);
      //accept api
      await acceptUpdate(currentUpdate);

      // update current update api
      await updateTemplateUpdate(true, currentUpdate).then(async (response) => {
          if(response) {
              //save template 
              await updateTemplateContent().then(res => {
                  if(res) {
                      toaster('You have successfully applied this update.', Intent.SUCCESS, '', 1000);
                      // update status
                      updateStatus(currentUpdate);
                  }
              });
          }
      }).finally(() => {
          setShowLoadingModal(false)
      });
  };

  const keepExisting = async () => {
      setShowLoadingModal(true)
      setTemplateUpdated(true);

      await updateTemplateSections();
      
      // update current update api
      await updateTemplateUpdate(false, currentUpdate).then(async (response) => {
          if(response) {
              //save template 
              await updateTemplateContent().then(res => {
                  if(res) {
                      toaster('You have successfully kept this existing version.', Intent.SUCCESS, '', 1000);
                      // update status
                      updateStatus(currentUpdate);
                  }
              });
          }
      }).finally(() => {
          setShowLoadingModal(false)
      });

      setEditorContent(null);
  };

  const onCloseModal = () => {
      if(templateUpdated === true) {
          reloadTemplate()
      }
      closeModal()
  }

  const updateTemplateUpdate = (accepted, currentUpdate) => {
      const params = {
          accepted: accepted,
          isDistrictLocalUpdate: currentUpdate.isDistrictLocalUpdate
      }

      return PlanService.updateTemplateUpdate(template.id, currentUpdate.id, params).then(response => {
              return true;
          }).catch(err => {
              toaster(err.error?.description, Intent.DANGER);
          });
  };

  const updateTemplateContent = () => {
      const params = {
          headers: JSON.stringify(template.headers),
          content: JSON.stringify(template.sections),
      }
      return PlanService.updatePlan(template.id, params)
          .then(res => {
              return true;
          }).catch(err => {
              toaster(err.error?.description, Intent.DANGER);
          })
  }

  const scrollToActiveUpdate = activeUpdate => {
    const cardId = `pending-card-${activeUpdate.id}`;
    const cardElement = document.getElementById(cardId);
    if (cardElement) {
      cardElement.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  return (
      <Modal
          isOpen={true}
          size={loading ? 'medium' : 'x-large'}
          loading={loading}
          footer={<FooterActions
                  template={template}    
                  closeModal={onCloseModal}
                  onApplyAllAction={onApplyAllAction}
              />
          }
          scrollContent={true}
          onClose={onCloseModal}
          customHeaderClass='view-updates-modal-header'
          showHeader={false}
          customClassName='view-updates-modal-wrapper'
      >
          <div className='view-updates-modal-body'>
              <AlertModal
                  isOpen={showLoadingModal}
                  loading={showLoadingModal}
              />
              <PendingUpdates
                  key='pending-updates'
                  templateUpdates={templateUpdates}
              />
              { currentUpdate && (
                <SectionsComparison 
                    currentUpdate={currentUpdate}
                    keepExisting={keepExisting}
                    useUpdated={useUpdated}
                    template={template}
                    onEditorChange={onEditorChange}
                    onCloseModal={onCloseModal}
                />
              )}
          </div>
      </Modal>
  );
}

ViewUpdatesModal.propTypes = {
  template: PropTypes.object,
  reloadTemplate: PropTypes.func,
  closeModal: PropTypes.func,
};

export { ViewUpdatesModal };
