import React, { Component } from 'react';
import { Toaster, Position, Intent, MenuItem, Icon } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/LoadingSpinner';
import Swal from 'sweetalert2';

const itemRender = (item, { handleClick }) => (
	<MenuItem key={item.key} text={item.value} onClick={handleClick} selected={true} />
);

const WiderButton = styled(Button)`
	min-width: 200px;
	display: flex;
	justify-content: space-between !important;
	background: #fff !important;
	white-space: nowrap;
`;

const Title = styled.h3`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
	margin-left: 10px;
`;

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	color: #394b59;
	align-items: center;
	margin-top: 40px;
`;

const BodyWrapper = styled.div`
  margin-top: 20px;
`;

class Administration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: '',
      outputText: '',
	    escapedContent: '',
      templates: [],
      selectOptions: [],
      selectFiltered: [],
      selectedTemplate: null,
      loading: false,
      eligibleToReset: false
    };
  }

  onFiltersHandler = search => {
		const { selectOptions } = this.state;
		const selectFiltered = selectOptions.filter(template => 
      template.value?.toLowerCase().includes(search.toLowerCase()) 
    );
		this.setState({
			selectFiltered: selectFiltered
		});
	}

  changeFilter = item => {
		const { selectOptions } = this.state;
		let selectedTemplate;
		if (item.key === 0) {
      this.setState({ eligibleToReset: false });
			return;
		} else {
			selectedTemplate = selectOptions.find(one => one.key === item.key);
		}
		let parsedContent = JSON.parse(selectedTemplate.content);
    let hasDuplicateIds = this.checkForDuplicateIds(parsedContent);
		this.setState({ selectedTemplate: selectedTemplate, eligibleToReset: hasDuplicateIds });
    if(!hasDuplicateIds) {
      this.showToaster('No duplicate IDs found', Intent.DANGER);
    } else {
      this.showToaster('Duplicate IDs found', Intent.SUCCESS);
    }
	};

  checkForDuplicateIds = (data) => {
    const parentIdSet = new Set();
    const childIdSet = new Set();
  
    // Check for duplicate IDs among parent objects and children
    for (const parent of data) {
      if (parentIdSet.has(parent.id)) {
        // console.error(`Duplicate parent ID found: ${parent.id}`);
        return true;
      } else {
        parentIdSet.add(parent.id);
      }
  
      if (Array.isArray(parent.subsections)) {
        for (const child of parent.subsections) {
          if (childIdSet.has(child.id)) {
            // console.error(`Duplicate child ID found: ${child.id}`);
            return true;
          } else {
            childIdSet.add(child.id);
          }
        }
      }
    }
    // If you reach this point, there are no duplicates
    return false;
  }

  handleProcessClick = () => {
    Swal.fire({
			title: 'Are you sure you want to regenerate IDs for this template?',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Confirm',
			reverseButtons: true,
		}).then(result => {
      if (result.value) {
        this.processUpdate();
      }
    });
  }

  processUpdate = () => {
    try {
      const token = JSON.parse(localStorage.getItem('user')).jwt;
      const { selectedTemplate } = this.state;
      // prepare the content of the selected template
      let parsedContent = JSON.parse(selectedTemplate.content);
      // remove IDs duplications
      const updatedContent = this.updateIds(parsedContent);
      
      // Send Request to update template content with new content
      fetch(`${process.env.API_URL}/plan-templates/${selectedTemplate.key}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          content: JSON.stringify(updatedContent)
        }),
      }).then(templateRes => templateRes.json()).then( templateResp => {
        if (templateResp.statusCode === 200) {
          this.showToaster('Template content updated successfully.', Intent.SUCCESS);
          // Template content updated, then prepare the update record
          let updateBodyRequest = {
            type: 'reset',
            updatedContent: JSON.stringify({
              content: updatedContent
            })
          };
          // Send Request to create a new Update request of type 'reset'
          this.createUpdateRequest(updateBodyRequest).then(updateRes => updateRes.json()).then(updateResp => {
            if (updateResp.statusCode === 201) {
              this.showToaster('Reset update created successfully.', Intent.SUCCESS);
              selectedTemplate.content = JSON.stringify(updatedContent);
              this.setState({ eligibleToReset: false });
            } else {
              this.showToaster('Failed to create update request!', Intent.DANGER);
            }
          });
        } else {
          this.showToaster('Failed to update template content!', Intent.DANGER);
        }
      });
      // const escapedContent = JSON.stringify(updatedContent, null, 2).replace(/'/g, "''");
    } catch (error) {
      console.error('Error processing data:', error);
    }
  }

  createUpdateRequest = (body) => {
    const { selectedTemplate } = this.state;
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    return fetch(
      `${
        process.env.API_URL
      }/plan-templates/${selectedTemplate.key}/updates`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      },
    );
  }

  updateIds = (data) => {
    let highestId = 0; // Initialize the highest ID

    const idMap = new Map(); // Map to store old and new ID pairs

    // Function to generate a unique ID
    const generateUniqueId = () => {
      highestId++; // Increment the highest ID
      return highestId;
    };

    const updateObjectIds = (obj) => {
      const oldId = obj.id;
      const newId = generateUniqueId();
      idMap.set(oldId, newId); // Store the mapping

      const updatedSubsections = (obj.subsections || []).map((subsection) => ({
        ...subsection,
        id: generateUniqueId(), // Update child IDs
      }));

      return {
        ...obj,
        id: newId, // Update parent ID
        subsections: updatedSubsections,
      };
    };

    const updatedData = data.map(updateObjectIds);

    return updatedData;
  };

  showToaster = (message, intent = 'success') => {
		const toastParams = {
			message,
			intent,
			timeout: 3000,
		};
		this.toaster.show(toastParams);
	};

  loadData = () => {
    this.setState({ loading: true });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates?all=true&includeContent=true`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode !== 200) {
					const localToken = JSON.parse(localStorage.getItem('user'));
					localToken.jwt = '';
					localStorage.setItem('user', JSON.stringify(localToken));
					return window.location.reload();
				}
				const {
					data: { templates },
				} = resp;
        const selectOptions = [];
        templates.forEach(one => {
					const temp = {
						key: one.id,
						value: one.name,
            content: one.content
					};
					selectOptions.push(temp);
				});
				return this.setState({
          loading: false,
					templates: templates,
          selectOptions: selectOptions,
          selectFiltered: selectOptions
				});
			});
	};

  componentDidMount() {
		this.loadData();
	}

  

  render() {
    const {
      selectFiltered,
			selectedTemplate,
      loading,
      eligibleToReset
		} = this.state;
    if (loading) return <LoadingSpinner />;
    return (
      <div>
        <Toaster
          ref={ref => {
            this.toaster = ref;
          }}
          position={Position.TOP_RIGHT}
        />
        <TitleWrap>
          <div className="d-flex">
            <Icon icon="clipboard" />
            <Title>Safety Plan Templates</Title>
          </div>
        </TitleWrap>
        <BodyWrapper>
          <p>Select which template you want to regenerate their linked safety plan IDs.</p>
          <p>This will regenerate the IDs for all safety plans linked to the selected template in all districts and the districts will lose their modifications done on pages.</p>
          <Select
            items={selectFiltered}
            itemRenderer={itemRender}
            onItemSelect={item => this.changeFilter(item)}
            onQueryChange={this.onFiltersHandler}
          >
            <WiderButton
              rightIcon="caret-down"
              text={
                (selectedTemplate && selectedTemplate.value) ||
                'Please select a template'
              }
            />
          </Select>
          <Button
            text="Reset Template IDs"
            intent="primary"
            onClick={this.handleProcessClick}
            disabled={!eligibleToReset}
          />
        </BodyWrapper>
      </div>
    );
  }
}

Administration.propTypes = {
	
};

export default Administration;
