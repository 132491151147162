import React from 'react';

const MultiStepHeaderModal = ({
	bars,
    headerTitle,
}) => {
    return (
    <>
        <span className='bars-header-font'>{headerTitle}</span>
        <div className='bars-container'>
            {
                bars.map((row, index) => (
                    <div className="multi-step-bars" key={index} style={{ background: row}}></div>
                ))
            }
        </div>
    </>
    );
};

export default MultiStepHeaderModal
