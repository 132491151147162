import React , {useState, useEffect} from 'react';

const generateAvatar = (initials) => {
   const canvas  = document.createElement("canvas");
   const context = canvas.getContext("2d");

   canvas.width = 200;
   canvas.height = 200;
   // Draw background
   context.fillStyle = "black";
   context.fillRect(0, 0, canvas.width, canvas.height);
   // Draw text
   context.font = "bold 80px Helvetica"; //Roboto
   context.fillStyle = "white";
   context.textAlign = "center";
   context.textBaseline = "middle";
   context.fillText(initials, canvas.width / 2, canvas.height / 2 );
   
   return canvas.toDataURL("image/png");
}

export default function AvatarInitials (props) 
{
   const [initialsImage,setInitialsImage] = useState(null);
   const [imageStyle,setImageStyle] = useState([]);

   const thumbStyle   = {width: '28px',height: '28px', marginRight: '4px', borderRadius: '50%'};
   const profileStyle = {width: '120px',height: '120px', borderRadius: '50%'};

  
   useEffect(() => { 
      setInitialsImage(props.isImage ? props.initials : generateAvatar(props.initials));
      if (props.imageMode == "profile") {
         setImageStyle(profileStyle);
      } else {
         setImageStyle(thumbStyle);
      }
   },[]);
   
   return(
      <>
         <img src={initialsImage} style={imageStyle} alt="Avatar Initials" />
      </>
   );
}
export {generateAvatar}