import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
	background-color: #ebf1f5;
	border-radius: 10px;
	color: #394b59;
	display: flex;
	font-family: Nunito Sans;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 16px;
	padding: 4px 8px;
	text-transform: capitalize;
	width: fit-content;

	img {
		margin-top: -2px;
		margin-left: 8px;
	}
`;

const FilterChip = ({ icon, text, iconAction }) => (
	<Wrapper className="pilsner">
		<span>{text}</span>
		{icon && (
			<div
				onClick={iconAction}
				onKeyPress={iconAction}
				role="presentation"
			>
				<img src={icon} alt="" />
			</div>
		)}
	</Wrapper>
);

FilterChip.propTypes = {
	icon: PropTypes.any,
	text: PropTypes.string.isRequired,
	iconAction: PropTypes.func,
};

export { FilterChip };
