import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import {
	BootstrapedTable,
	Checkbox,
	Intent,
	LoadingSpinner,
	PageHeader,
	ToasterContext,
} from 'componentsV2';
import { DrillEmailRemindersService } from 'services';
import './ManageFirstResponderNotifications.scss';
import { EditEmailRespondersModal } from './EditEmailRespondersModal';

const ManageFirstResponderNotifications = () => {
	const breadcrumbItems = [
		{ label: 'Drills', url: '/drillsV2' },
		{ label: 'Settings', url: '/drillsV2/settings' },
		{ label: 'First responder' },
	];
	const permissions = useSelector(state => state.route.permissions);
	const canViewSettings = checkPermission('drilllogs_set_reqs', permissions);

	const [emailData, setEmailData] = useState([]);
	const [responderEmailIds, setResponderEmailIds] = useState([]);
	const [responders, setResponders] = useState([]);
	const [selectedBuilding, setSelectedBuilding] = useState([]);
	const [disableSave, setDisableSave] = useState(true);
	const [loading, setLoading] = useState(true);
	const [modalLoading, setModalLoading] = useState(false);
	const [modalToShow, setModalToShow] = useState('');

	const toaster = useContext(ToasterContext);

	useEffect(() => {
		if (!canViewSettings) {
			window.location.href = '/drillsV2';
		}
		return () => {
			setEmailData([]);
			setResponderEmailIds([]);
			setResponders([]);
			setSelectedBuilding([]);
			setDisableSave(true);
			setLoading(false);
			setModalLoading(false);
			setModalToShow('');
		};
	}, [canViewSettings]);

	useEffect(() => {
		DrillEmailRemindersService.fetchEmailSettings()
			.then(res => {
				setEmailData(formatEmailSettings(res));
			})
			.finally(() => setLoading(false));
	}, []);

	const formatEmailSettings = settings => {
		const formattedEmailSettings = settings.map(item => {
			const obj = {
				...item,
				buildingId: item.id,
			};

			const { id: _, ...formattedObj } = obj;

			return formattedObj;
		});

		return formattedEmailSettings;
	};

	const editNotifications = (event, type, row) => {
		setEmailData(prevData =>
			prevData.map(item =>
				item.drillEmailSettings.id === row.drillEmailSettings.id
					? {
							...item,
							drillEmailSettings: {
								...item.drillEmailSettings,
								[type]: event.target.checked,
							},
					  }
					: item,
			),
		);
		setDisableSave(false);
	};

	const editEmailResponders = event => {
		const emailIds = [...responderEmailIds];

		if (event.target.checked) {
			emailIds.push(event.target.value);
		} else {
			const index = emailIds.findIndex(item => item === event.target.value);
			emailIds.splice(index, 1);
		}

		setEmailData(prevData =>
			prevData.map(item =>
				item.buildingId === selectedBuilding
					? {
							...item,
							drillEmailSettings: {
								...item.drillEmailSettings,
								emailRecipients: emailIds,
							},
					  }
					: item,
			),
		);

		setResponderEmailIds(emailIds);
	};

	const fetchResponders = buildingId => {
		setModalLoading(true);
		try {
			DrillEmailRemindersService.fetchResponders([buildingId]).then(res => {
				setResponders(res);
				setSelectedBuilding(buildingId);
				setModalLoading(false);
			});
		} catch (error) {
			console.error(error);
			setModalLoading(false);
		}
	};

	const saveNotifications = () => {
		try {
			DrillEmailRemindersService.updateDrillEmailSettings(emailData);
			toaster('Notifications saved successfully', Intent.SUCCESS);
		} catch (error) {
			console.log(error);
			toaster('Error saving notifications', Intent.DANGER);
		}
	};

	const saveEmailResponders = () => {
		setModalLoading(true);
		try {
			DrillEmailRemindersService.updateDrillEmailSettings(emailData);
			toaster(
				'You have successfully updated the First responder recipients.',
				Intent.SUCCESS,
			);
			setModalLoading(false);
			setModalToShow('');
		} catch (error) {
			console.log(error);
			toaster('Error updating the First responder recipients.', Intent.DANGER);
			setModalLoading(false);
		}
	};

	const onModalOpen = () => {
		setModalToShow('editEmailResponders');
	};

	const disabledRowCondition = row => row.active === false;

	const columns = [
		{
			key: 'id',
			dataField: 'buildingId',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'use-district-list',
			dataField: 'useDistrictRecipientList',
			text: 'Use district list',
			formatter: (value, item) =>
				item.isDistrictLevel ? (
					'District'
				) : (
					<Checkbox
						key={item.drillEmailSettings.id}
						checked={item.drillEmailSettings.useDistrictRecipientList}
						onChange={e => editNotifications(e, 'useDistrictRecipientList', item)}
					/>
				),
		},
		{
			dataField: 'name',
			text: 'Building',
			sort: true,
		},
		{
			key: 're-scheduled',
			dataField: 'rescheduled',
			text: 'Drill re-scheduled',
			formatter: (value, item) => (
				<Checkbox
					key={`rescheduled-${item.drillEmailSettings.id}`}
					checked={item.drillEmailSettings.rescheduled}
					onChange={e => editNotifications(e, 'rescheduled', item)}
					disabled={
						!item.isDistrictLevel && item.drillEmailSettings.useDistrictRecipientList
					}
				/>
			),
		},
		{
			key: 'scheduled',
			dataField: 'scheduled',
			text: 'Drill scheduled',
			formatter: (value, item) => (
				<Checkbox
					key={`scheduled-${item.drillEmailSettings.id}`}
					checked={item.drillEmailSettings.scheduled}
					onChange={e => editNotifications(e, 'scheduled', item)}
					disabled={
						!item.isDistrictLevel && item.drillEmailSettings.useDistrictRecipientList
					}
				/>
			),
		},
		{
			key: 'completed',
			dataField: 'completed',
			text: 'Drill completed',
			formatter: (value, item) => (
				<Checkbox
					key={`completed-${item.drillEmailSettings.id}`}
					checked={item.drillEmailSettings.completed}
					onChange={e => editNotifications(e, 'completed', item)}
					disabled={
						!item.isDistrictLevel && item.drillEmailSettings.useDistrictRecipientList
					}
				/>
			),
		},
		{
			key: 'reminder',
			dataField: 'reminder',
			text: 'Drill reminder',
			formatter: (value, item) => (
				<Checkbox
					key={`reminder-${item.drillEmailSettings.id}`}
					checked={item.drillEmailSettings.reminder}
					onChange={e => editNotifications(e, 'reminder', item)}
					disabled={
						!item.isDistrictLevel && item.drillEmailSettings.useDistrictRecipientList
					}
				/>
			),
		},
		{
			key: 'drill-recipients',
			dataField: 'responders',
			text: 'Drill recipients',
			formatter: (value, item) => (
				<a
					className={`link ${
						!item.isDistrictLevel && item.drillEmailSettings.useDistrictRecipientList
							? 'disabled-link'
							: ''
					}`}
					onClick={() => {
						onModalOpen();
						fetchResponders(item.buildingId);
						setResponderEmailIds(item.drillEmailSettings.emailRecipients || []);
					}}
				>
					Responders
				</a>
			),
		},
	];

	return (
		<>
			<div className="district-requirements-page-container">
				<div className="header-container">
					<PageHeader
						title="First responder email notifications"
						subtitle="If school is not listed, confirm that Drills are enabled."
						breadcrumbItems={breadcrumbItems}
						actionTitle="Save"
						onActionClick={() => saveNotifications()}
						actionDisabled={disableSave}
					/>
				</div>
				<div className="sections-container">
					<div className="table-section-container">
						{loading && emailData.length === 0 && <LoadingSpinner className="mt-3" />}
						{emailData.length > 0 && (
							<>
								<div className="left-side">
									{loading ? (
										<LoadingSpinner className="mt-3" />
									) : (
										<BootstrapedTable
											keyField="buildingId"
											data={emailData}
											columns={columns}
											disabledRowCondition={disabledRowCondition}
											remote={false}
										/>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			{modalToShow === 'editEmailResponders' && (
				<EditEmailRespondersModal
					isOpen
					responderDetails={responders}
					emailIds={responderEmailIds}
					onChange={e => editEmailResponders(e)}
					onModalSubmit={() => saveEmailResponders()}
					onCloseModal={() => setModalToShow('')}
					loading={modalLoading}
				/>
			)}
		</>
	);
};

export default ManageFirstResponderNotifications;
