import React from "react";
import { FormModal, Input, Intent } from "componentsV2";

export const RenameModal = ({
    modalName,
    modalToShow,
    loading,
    documentData,
    closeModal,
    handleChangeTitle,
    handleSubmit,
}) => {

    return (
        <FormModal
            isOpen={modalToShow === modalName}
            onClose={closeModal}
            title={'Rename document'}
            showCloseButton={true}
            confirmBtn={{
                title: 'Update',
                disabled: documentData.title?.trim().length == 0,
                onClick: handleSubmit,
            }}
            cancelBtn={{
                title: "Cancel",
                onClick: closeModal
            }}
            loading={loading}
            scrollContent={false}
        >
            <div className="w-100">
                <Input
                    value={documentData.title}
                    name="title"
                    label="Name"
                    placeholder="Enter your filename here"
                    isRequired={true}
                    onChange={handleChangeTitle}
                />
            </div>
        </FormModal>
    );
}