import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { flags } from 'utilities';
import { LoadingSpinner, Button, EmptyState, assessEmptyState } from 'componentsV2';
import { AssessTemplatesService } from 'services';
import { TemplatesTable } from './components/TemplatesTable';
import { CreateNewTemplateModal } from './components/CreateNewTemplateModal';
import { ImportTemplateModal } from './components/ImportTemplateModal';
import './css/ListTemplates.scss';

export const ListTemplates = ({ history }) => {
	const [templatesData, setTemplatesData] = useState([]);
	const [isCoreSite, setIsCoreSite] = useState(null);
	const [loading, setLoading] = useState(false);
	const [modalToShow, setModalToShow] = useState('');

	useEffect(() => {
		getTemplateList();
	}, []);

	// check if the site is a core site
	const {
		isOn,
		names: { SITE_IS_TEMPLATE_CORE },
	} = flags;

	// get templates data
	const getTemplateList = async () => {
		if (isOn(SITE_IS_TEMPLATE_CORE)) {
			setLoading(true);
			const templateDisplayed = await AssessTemplatesService.getTemplates(true, true);
			setTemplatesData(sortCoreTemplates(templateDisplayed?.data?.templates));
			setIsCoreSite(true);
			setLoading(false);
		} else {
			setLoading(true);
			const templatesList = await AssessTemplatesService.getTemplates();
			setTemplatesData(templatesList?.data?.templates);
			setIsCoreSite(false);
			setLoading(false);
		}
	};

	const sortCoreTemplates = data =>
		data.sort((a, b) => {
			const x = a.name.toUpperCase();
			const y = b.name.toUpperCase();
			if (x < y) {
				return -1;
			}
			if (x > y) {
				return 1;
			}
			return 0;
		});

	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && templatesData?.length > 0 && (
				<TemplatesTable
					{...{ templatesData, isCoreSite, getTemplateList, setModalToShow, history }}
				/>
			)}

			{!loading && templatesData?.length === 0 && (
				<>
					<EmptyState
						header="No templates were added yet"
						description="Click on the + Custom template button to create new template or the Import button to import a template."
						icon={assessEmptyState}
						className="mt-15"
					>
						<Button
							icon="plus"
							text="Custom template"
							type="primaryDefault"
							size="lg"
							onClick={() => {
								setModalToShow('createNewTemplateModal');
							}}
						/>
						<Button
							icon="import"
							text="Import"
							type="secondaryDefault"
							size="lg"
							onClick={() => {
								setModalToShow('importTemplateModal');
							}}
						/>
					</EmptyState>
				</>
			)}

			{modalToShow === 'createNewTemplateModal' && (
				<CreateNewTemplateModal
					onClose={() => setModalToShow('')}
					loadData={() => {
						setLoading(true);
						getTemplateList();
					}}
					isCoreTemplate={isCoreSite}
					history={history}
				/>
			)}

			{modalToShow === 'importTemplateModal' && (
				<ImportTemplateModal
					onClose={() => setModalToShow('')}
					loadData={() => {
						setLoading(true);
						getTemplateList();
					}}
				/>
			)}
		</>
	);
};

ListTemplates.propTypes = {
	history: propTypes.object,
};
