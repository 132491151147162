import React from 'react';
function TableRows({ rowsData, deleteTableRows, handleChange }) {
	return rowsData.map((data, index) => {
		const { fullName, emailAddress, salary } = data;
		return (
			<tr key={index}>
				<td>{data.person.fullName}</td>
				<td>
					{' '}
					{data.login.length > 30 ? `${data.login.substring(0, 30)}...` : data.login}{' '}
				</td>
				<td>
					<a onClick={() => deleteTableRows(index)}>x</a>
				</td>
			</tr>
		);
	});
}
export default TableRows;
