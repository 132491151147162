import { HttpService } from 'networking/HttpService';

const GENERIC_ERROR = new Error('An error occurred, please try again');
export const USER_STATUS = {
	ENABLED: 1,
	DISABLED: 2,
};

export const UserService = {
	fetchMyInfo: async (token, queryParam = '') =>
		await HttpService({
			method: 'GET',
			route: `/users/myself${queryParam}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}).catch(err => {
			throw err;
		}),

	getUserById: async (id, queryParam = '') =>
		await HttpService({
			method: 'GET',
			route: `/users/${id}${queryParam}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		}),

	toggleStatus: userId =>
		HttpService({
			method: 'PUT',
			route: `/users/${userId}/toggle-status`,
		}).then(res => {
			if (res.statusCode === 200) {
				return res.data;
			}

			throw GENERIC_ERROR;
		}),

	deleteUser: userId =>
		HttpService({
			method: 'DELETE',
			route: `/users/${userId}`,
		}).then(res => {
			if (res.statusCode === 200) {
				return res.data;
			}

			throw GENERIC_ERROR;
		}),

	addNewUser: params => {
		// Removes properties that have an empty string
		const data = Object.fromEntries(Object.entries(params).filter(value => value[1]));

		return HttpService({
			method: 'POST',
			route: '/users/add',
			body: data,
		}).then(res => res.data);
	},

	updateUser: (userId, params) =>
		HttpService({
			method: 'PUT',
			route: `/users/${userId}`,
			body: params,
		}).then(res => res.data),

	updatePassword: (userId, params) =>
		HttpService({
			method: 'PUT',
			route: `/users/${userId}/password`,
			body: params,
		}).then(res => res.data),

	sendTestNotification: (userId, params) => {
		try {
			return HttpService({
				method: 'POST',
				route: `/users/${userId}/send-test-notification`,
				body: params,
			});
		} catch (error) {
			throw error;
		}
	},

	getAwsImage: photoName => {
		try {
			return HttpService({
				method: 'GET',
				route: `/awsimages/get/${photoName}`,
			});
		} catch (error) {
			throw error;
		}
	},

	checkEmailExists: email => {
		return HttpService({
			method: 'POST',
			route: `/users/check-email-exist`,
			body: {
				email: email,
			},
		}).then(result => result.data.Exist);
	},
};
