import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button } from 'componentsV2';
import './HeaderButtons.scss';

export const HeaderButtons = ({ showSettings, showAdmin }) => (
	<div className="drills-header-buttons">
		{/* Settings */}
		{showSettings && (
			<NavLink exact to="/drillsV2/settings">
				<Button size="lg" text="Settings" icon="cog" type="tertiary" />
			</NavLink>
		)}
		{/* Admin */}
		{showAdmin && (
			<NavLink exact to="/drillsV2/admin">
				<Button size="lg" text="Admin" type="tertiary" />
			</NavLink>
		)}
	</div>
);

HeaderButtons.propTypes = {
	showSettings: PropTypes.bool,
	showAdmin: PropTypes.bool,
};
