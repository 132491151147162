import { HttpService } from 'networking/HttpService';

export const PartnersService = {
	getAllPartners: async (params = {}) => {
		let query = '';
		if (params.key) {
			query += `&key=${params.key}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/alarms/partners?${query}`,
		}).catch(err => {
			throw err;
		});
	},

	getAllPartnersAlarmTypes: async (params = {}) => {
		let query = '';
		if (params.name) {
			query += `&key=${params.name}`;
		}
		if (params.partnerIds) {
			query += `&key=${params.partnerIds}`;
		}
		if (params.alarmTypesIds) {
			query += `&key=${params.alarmTypesIds}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/alarms/partners/alarm-types?${query}`,
		}).catch(err => {
			throw err;
		});
	},
};
