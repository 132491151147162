import { HttpService } from 'networking/HttpService';

export const FlipchartService = {
	getAll: async (params = {}) => {
		let query = '';
		if (params.title) {
			query += `&title=${params.title}`;
		}
		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}
		if (params.includeArchived) {
			query += `&includeArchived=${params.includeArchived}`;
		}
		if (params.visibleToAllOnly) {
			query += `&visibleToAllOnly=${params.visibleToAllOnly}`;
		}
		if (params.buildingIds && params.buildingIds.length > 0) {
			params.buildingIds.forEach(buildingId => {
				query += `&buildingIds[]=${buildingId}`;
			});
		}
		if (params.statuses && params.statuses.length > 0) {
			params.statuses.forEach(status => {
				query += `&statuses[]=${status}`;
			});
		}

		return await HttpService({
			method: 'GET',
			route: `/flipcharts?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	flipchartTapsDetails: async (flipchartId, params = {}) => {
		let query = '';
		if (params.alarmId) {
			query += `alarmId=${params.alarmId}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/flipcharts/${flipchartId}/tabs?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},
	reorderTabs: async (id, params) => {
		return await HttpService({
			method: 'PUT',
			route: `/flipcharts/${id}/tabs/reorder`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				tabs_ids: params.tabs_ids,
			},
		}).catch(err => {
			throw err;
		});
	},

	getTabsByFlipchartId: async id => {
		return await HttpService({
			method: 'GET',
			route: `/flipcharts/${id}/tabs`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	getFlipchart: async (id = []) => {
		return await HttpService({
			method: 'GET',
			route: `/flipcharts/${id}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	publishFlipChart: async (id, type) => {
		try {
			return await HttpService({
				method: 'POST',
				route: '/flipcharts/' + id + '/publish',
				body: {
					type: type,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	addFlipchart: async params =>
		await HttpService({
			method: 'POST',
			route: '/flipcharts',
			headers: {
				'Content-Type': 'application/json',
			},
			body: params,
		}).catch(err => {
			throw err;
		}),

	updateFlipchart: async (id, params) =>
		await HttpService({
			method: 'PUT',
			route: '/flipcharts/' + id,
			headers: {
				'Content-Type': 'application/json',
			},
			body: params,
		}).catch(err => {
			throw err;
		}),

	archiveFlipChart: async (id, status) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: '/flipcharts/' + id + '/toggle-archive',
				body: {
					archive: status,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	delete: async id => {
		try {
			return await HttpService({
				method: 'DELETE',
				route: '/flipcharts/' + id,
			});
		} catch (error) {
			throw error;
		}
	},

	deleteFlipchartTab: async (flipchartId, tabIds) => {
		try {
			return await HttpService({
				method: 'DELETE',
				route: '/flipcharts/' + flipchartId + '/tabs',
				headers: {
					'Content-Type': 'application/json',
				},
				body: {
					ids: tabIds,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	addFlipchartTab: async (id, params) => {
		try {
			return await HttpService({
				method: 'POST',
				route: `/flipcharts/${id}/tabs`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: params,
			});
		} catch (error) {
			throw error;
		}
	},

	updateFlipchartTab: async (id, tabId, params) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/flipcharts/${id}/tabs/${tabId}`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: params,
			});
		} catch (error) {
			throw error;
		}
	},

	getNewFlipchartTabColors: async id => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/flipcharts/${id}/tabs/new-tab-color`,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			throw error;
		}
	},
};
