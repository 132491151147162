import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Dropdown } from 'components/Dropdown';
import { DrillDetailsModal } from 'components/Modals/DrillDetailsModal';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { MoreActionsButton } from 'components/MoreActionsButton';
import { PageHeader } from 'components/PageHeader';
import { StatusPilsner } from 'components/StatusPilsner';
import { Table } from 'components/Table';

import { DrillsService } from 'services/DrillsService';
import { makeDrillTypes } from '../shared/Modules';

const Page = styled.div`
	padding: 8px 24px;

	.table-title {
		display: flex;
		margin-top: 40px;
		margin-bottom: 16px;
		flex-direction: row;

		h2 {
			color: #10161a;
			font-size: 18px;
			line-height: 21px;
			font-weight: bold;
			font-style: normal;
			margin-bottom: 0px;
			letter-spacing: 0.3px;
			font-family: Merriweather;
		}

		.pilsner {
			margin-left: 8px;
		}
	}
`;

class OverdueDrills extends React.Component {
	state = {
		error: null,
		loading: true,
		data: null,
		drillTypes: null,
		selectedDrillType: null,
		showDetailsModal: false,
		selectedDrill: null,
	};

	componentDidMount() {
		DrillsService.fetchOverdueDrills()
			.then(data => {
				const results = data.filter(
					item => item.daysOverDueDifference < 30,
				);
				const drillTypes = makeDrillTypes(data);
				this.setState({
					data: results,
					drillTypes,
					error: null,
					loading: false,
					selectedDrillType: drillTypes[0],
				});
			})
			.catch(error => {
				this.setState({
					error,
					data: null,
					loading: false,
					drillTypes: null,
					selectedDrillType: null,
				});
			});
	}

	selectDrillType = item => {
		this.setState({
			selectedDrillType: item,
		});
	};

	setShowDetailsModal = showDetailsModal => {
		this.setState({ showDetailsModal });
	};

	removeDrill = drill => {
		const { data } = this.state;

		const newlyFilteredData = data?.filter(
			({ scheduleId }) => scheduleId !== drill?.scheduleId,
		);
		const newlyFilteredDrillTypes = makeDrillTypes(newlyFilteredData);

		this.setState({
			data: newlyFilteredData,
			drillTypes: newlyFilteredDrillTypes,
			showDetailsModal: false,
			selectedDrill: null,
		});
	};

	render() {
		const {
			error,
			loading,
			drillTypes,
			data,
			selectedDrillType,
			showDetailsModal,
			selectedDrill,
		} = this.state;
		const { history } = this.props;

		if (error) {
			return error;
		}

		if (loading) {
			return <LoadingSpinner />;
		}

		const filteredData = data?.filter(
			({ drillType }) =>
				selectedDrillType?.value === 'all' ||
				selectedDrillType?.value === drillType?.uuid,
		);

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Overdue drills"
					onBackBtnClick={history.goBack}
				/>

				{/* drill type dropdown */}
				<Dropdown
					label="Drill type"
					placeholder="Select a drill type"
					value={selectedDrillType}
					options={drillTypes}
					onChange={item => this.selectDrillType(item)}
					disabled={!filteredData?.length}
				/>

				{/* table header */}
				<div className="table-title">
					<h2>Overdue drills</h2>

					<StatusPilsner status="overdue" />
				</div>

				{/* drills table */}
				<Table
					headers={['Drill type', 'Site', 'Date', 'Actions']}
					data={filteredData}
					renderItem={item => (
						<>
							<td>{item.drillType.name}</td>
							<td>{item.buildingName}</td>
							<td>{item.timestamp}</td>
							<td>
								<MoreActionsButton
									actions={[
										{
											id: 'editDrill',
											icon: 'edit',
											title: 'Edit drill',
											handler: () =>
												this.setState({
													selectedDrill: item,
													showDetailsModal: true,
												}),
										},
									]}
								/>
							</td>
						</>
					)}
				/>

				{showDetailsModal && selectedDrill && (
					<DrillDetailsModal
						setModalToShow={this.setShowDetailsModal}
						selectedDrill={selectedDrill}
						onDeleteDrill={this.removeDrill}
						onPostLogSuccess={this.removeDrill}
					/>
				)}
			</Page>
		);
	}
}

OverdueDrills.propTypes = {
	history: PropTypes.object,
};

export default OverdueDrills;
