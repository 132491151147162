import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'components/Dropdown';
import { StatesService } from 'services/StatesService';
import { setLastSelectedState } from '../../routeReducer';

const StatesDropdown = ({ selectedState, onChange }) => {
	const [states, setStates] = useState(null);

	const dispatch = useDispatch();
	const previousStoredSelection = useSelector(
		state => state.route.lastSelectedState,
	);

	useEffect(() => {
		if (previousStoredSelection) {
			onChange(previousStoredSelection);
		}
	}, []);

	useEffect(() => {
		StatesService.getAll().then(backendStates => setStates(backendStates));
	}, []);

	return (
		<Dropdown
			label="State"
			placeholder="Select a state"
			// value={selectedState || previousStoredSelection}
			value={selectedState}
			options={states}
			onChange={selected => {
				dispatch(setLastSelectedState(selected));
				if (onChange) {
					onChange(selected);
				}
			}}
		/>
	);
};

StatesDropdown.propTypes = {
	selectedState: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};

export { StatesDropdown };
