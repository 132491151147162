import React, { useContext, useEffect, useState } from 'react';
import { Badge, LoadingSpinner, EmptyState, emptyFiles } from 'componentsV2';
import { CreateAlarmTypeContext } from '../../../CreateAlarmType/provider';
import { Preview } from '../../../CreateAlarmType/components/CreateAlarmTypeBody/components/CardContent/components';
import { AlarmIsActiveBanner, SaveAlarmAction } from './components';
import { RapidAlarmService } from 'services';
import { ToggleEnableAlarm } from '../../../CreateAlarmType/components/CreateAlarmTypeBody/components/CardContent/components/SharedFields';
import { CANCEL_CHANGES_MODAL } from '../../../CreateAlarmType/consts';
import './css/ViewAlarmTypeBody.scss';

export const ViewAlarmTypeBody = ({ alarmTypeId, editPremission = false }) => {
	const [loading, setLoading] = useState(true);
	const {
		formData,
		setFormData,
		formHasChanged,
		history,
		locationPath,
		setLocationPath,
		openModal,
	} = useContext(CreateAlarmTypeContext);
	const [error, setError] = useState(null);

	useEffect(() => {
		const getAlarmTypeData = () => {
			RapidAlarmService.getAlarmTypeDetails(alarmTypeId)
				.then(response => {
					if (response.statusCode === 200) {
						const alarmTypeData = response.data;
						setFormData(oldValues => ({
							...oldValues,
							id: alarmTypeData.id,
							name: alarmTypeData.title,
							icon: alarmTypeData.icon,
							soundEnabled: alarmTypeData.sound ? true : false,
							sound: alarmTypeData.sound,
							countdown: alarmTypeData.countDown == 1 ? true : false,
							contact911: formData._911Active
								? alarmTypeData.use911Configurations
								: alarmTypeData.contact911,
							alarmTypes911: alarmTypeData.PartnerAlarmType
								? {
										value: alarmTypeData.PartnerAlarmType.id,
										label: alarmTypeData.PartnerAlarmType.name,
								  }
								: null,
							flipchartValue: alarmTypeData.flipchart
								? {
										value: alarmTypeData.flipchart.id,
										label: alarmTypeData.flipchart.title,
								  }
								: {
										value: -1,
										label: 'None',
								  },
							priority: alarmTypeData.setAsPriority,
							generic: alarmTypeData.isGeneric,
							hasRollCall: alarmTypeData.activateRollCall,
							markAllAccounted: alarmTypeData.markAllAsAccounted,
							disabled: alarmTypeData.disabled,
							hasActiveAlarm: alarmTypeData.hasActiveAlarm,
							preset: alarmTypeData.preset,
							isGeneric: alarmTypeData.isGeneric,
						}));
					}
				})
				.catch(err => {
					setError(err?.error.description);
				})
				.finally(() => {
					setLoading(false);
				});
		};

		getAlarmTypeData();
	}, []);

	useEffect(() => {
		const unblock = history.block(location => {
			if (formHasChanged) {
				// should show a save as disabled modal here
				setLocationPath(location.pathname);
				openModal(CANCEL_CHANGES_MODAL);
				return false;
			}
		});

		if (locationPath != '') {
			history.push(locationPath);
		}

		return () => {
			unblock();
		};
	}, [formHasChanged, locationPath, history]);

	if (loading) return <LoadingSpinner className="mt-3" />;
	return (
		<div className="view-alarm-type-container">
			{error && <EmptyState icon={emptyFiles} header={error} />}
			{!error && (
				<>
					{formData.hasActiveAlarm && <AlarmIsActiveBanner />}
					<div className="view-alarm-type-container-body">
						<Preview
							header={
								<>
									Viewing: {formData.name}{' '}
									<Badge
										size="md"
										type="Primary"
										text={formData.disabled ? 'Disabled' : 'Enabled'}
									/>
								</>
							}
							rightActions={
								<>
									{editPremission && <ToggleEnableAlarm formData={formData} />}
									<SaveAlarmAction />
								</>
							}
						/>
					</div>
				</>
			)}
		</div>
	);
};
