import React, { useContext } from "react";
import { Button, ActionsButton, Badge, FeaturedIcon } from 'componentsV2';
import { getFields } from "utilities";
import { DistrictTemplateContext } from '../../Providers';
import './css/SectionItems.scss';

export const SectionItems = ({
    template,
    variables = [],
    history,
    variableValues,
    openModal,
    viewMode,
    openDeleteSectionModal,
    openDeleteSubSectionModal,
    section,
    subSection,
    sectionIndex,
    sectionSubIndex,
    sectionType,
    markFileSubsectionComplete,
    uploadImage,
    openPublishUpdateModal
}) => {
    let sectionsCount = (template.sections) ? template.sections.length : 0;
    const {
        downloadSafetyPlanSingleSelection,
        showInsertVariablesModal
    } = useContext(DistrictTemplateContext)

    const activeSection = subSection === null ? section : subSection;

    const variableText = getFields(activeSection.content, variableValues);

    const showCompleteSectionModal = () => {
        markFileSubsectionComplete(sectionIndex,sectionSubIndex,activeSection.complete? !activeSection.complete: 'true',activeSection);
    }

    const showUploadImageModal = () => {
        uploadImage(activeSection,sectionIndex, sectionSubIndex);
    }

    const exportSingle = () => {
        return downloadSafetyPlanSingleSelection(template, variableValues, sectionIndex, sectionSubIndex);
    }

    const onCreateUpdateHandler = () => {  
        let isSection = sectionType === 'section' ? true : false;

        openPublishUpdateModal(isSection, section, subSection, sectionIndex, sectionSubIndex);
    }

  const insertVariable = () => {
    return showInsertVariablesModal(template, variables, section,
      subSection,
      sectionType,
    );
  }

    const onDeleteHandler = () => {
        if(sectionType === 'section') {
            openDeleteSectionModal(sectionIndex, section)
        } else {
            openDeleteSubSectionModal(sectionIndex, sectionSubIndex, section, subSection)
        }
    }

    return (
        <>
        {!viewMode && (
        <div className="section-items-wrapper">
            <div className="section-actions">
                {template.createdFromDistrict && template.hasEditPermission && !activeSection.fileSection && (
                    <Button
                        size="sm"
                        text="Create an update"
                        intent="default"
                        onClick={onCreateUpdateHandler}
                    />
                )}
                <Button
                    size="sm"
                    text={activeSection.complete ? "Incomplete section" : "Complete section"}
                    type="secondaryDefault"
                    onClick={showCompleteSectionModal}
                />
                {activeSection.fileSection && (
                    <FeaturedIcon icon="trash" size="sm" shape='roundedIcon' type="Default" onClick={onDeleteHandler}/>
                )}
                {!activeSection.fileSection && (
                    <ActionsButton elementSelector={<Button type="tertiary" size="sm" icon="more" />}>
                        <Button
                            text="Add image"
                            icon="media"
                            size="md"
                            type="ghostDefault"
                            intent="default"
                            wrapperClass="displayBlock"
                            className="actionButtonElement"
                            onClick={showUploadImageModal}
                        />
                        {!viewMode && (
                            <>
                                <Button
                                    text="Insert variable"
                                    icon="insert"
                                    size="md"
                                    type="ghostDefault"
                                    intent="default"
                                    wrapperClass="displayBlock"
                                    className="actionButtonElement"
                                    onClick={insertVariable}
                                />
                                <Button
                                    text="Download"
                                    icon="download"
                                    size="md"
                                    type="ghostDefault"
                                    intent="default"
                                    wrapperClass="displayBlock"
                                    className="actionButtonElement"
                                    onClick={exportSingle}
                                />
                                {((sectionType === 'section' && sectionsCount>1) || sectionType==='subsection') && (
                                    <Button
                                        text="Delete"
                                        icon="trash"
                                        size="md"
                                        type="ghostDefault"
                                        intent="default"
                                        wrapperClass="displayBlock"
                                        className="actionButtonElement"
                                        onClick={onDeleteHandler}
                                    />
                                    )
                                }

                            </>
                        )}
                    </ActionsButton>
                )}
            </div>
            <div className="section-tags">
                {variableText && (
                    <Badge
                        key={`variables-${activeSection.id}-${activeSection.title}`}
                        size='md'
                        type='Gray'
                        text={getFields(activeSection.content, variableValues)}
                    />
                )}
                <Badge
                    key={`${activeSection.id}-${activeSection.title}`}
                    size="md"
                    type={activeSection.complete ? 'Success' : 'Warning'}
                    text={activeSection.complete ? 'Complete' : 'Incomplete'}
                />
            </div>
        </div>
        )}
        </>
    );
}
