import React from 'react';
import { CardContainer } from './CardContainer';

export const CardContainerPattern = () => {
	return (
        <>
            <CardContainer title="card 1">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ullamcorper consequat nulla et ultricies. Aliquam sollicitudin sapien consectetur ante rhoncus dictum. Aenean ac consectetur eros. Vivamus gravida dui a turpis pulvinar, in gravida metus accumsan. Fusce enim lectus, pellentesque id nibh eget, vehicula ornare eros. Morbi in diam a magna tincidunt egestas. In elementum at sem quis posuere. Aenean dapibus, mauris ac sagittis semper, dui lacus eleifend augue, at placerat turpis felis vitae quam. Donec ullamcorper mauris nibh, et rhoncus mi malesuada sit amet. Proin consequat lacus in dictum tincidunt. Aenean sit amet blandit eros. Sed lectus justo, molestie vitae justo at, rutrum fringilla odio. Praesent mollis metus sapien, at feugiat diam laoreet ut.</p>
                </CardContainer>
            <div className='row mt-4'>
                <div className='col-6'>
                    <CardContainer title="card 2">
                        <>
                            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ullamcorper consequat nulla et ultricies. Aliquam sollicitudin sapien consectetur ante rhoncus dictum. Aenean ac consectetur eros. Vivamus gravida dui a turpis pulvinar, in gravida metus accumsan. Fusce enim lectus, pellentesque id nibh eget, vehicula ornare eros</p>
                            <p>Morbi in diam a magna tincidunt egestas. In elementum at sem quis posuere. Aenean dapibus, mauris ac sagittis semper, dui lacus eleifend augue, at placerat turpis felis vitae quam. Donec ullamcorper mauris nibh, et rhoncus mi malesuada sit amet. Proin consequat lacus in dictum tincidunt. Aenean sit amet blandit eros. Sed lectus justo, molestie vitae justo at, rutrum fringilla odio. Praesent mollis metus sapien, at feugiat diam laoreet ut.</p>
                        </>
                    </CardContainer>
                </div>

                <div className='col-6'>
                    <CardContainer title="card 3">
                        <ul>
                            <li> item 1</li>
                            <li> item 2</li>
                            <li> item 3</li>
                            <li> item 4</li>
                            <li> item 5</li>
                        </ul>
                    </CardContainer>
                </div>
            </div>
        </>
	);
};