import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog, Icon, MenuItem } from '@blueprintjs/core';
import { Button } from 'components/Button';
import { Select } from '@blueprintjs/select';
import { LabelText } from 'components/Forms/shared.js';
import TextVariable from './textvariable';
import ImageVariable from './imagevariable';
import PhoneVariable from './phonevariable';
import EmailVariable from './emailvariable';
import MultipleChoiceVariable from './multiplechoicevariable';
import UserVariable from './uservariable';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

const DropdownWrap = styled.div`
	padding: 10px;
	width: 100%;
	background: #ffffff;
	border: 1px solid #d8e1e8;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
`;

const SelectWrap = styled(Select)`
	display: block;
	width: 100%;
	.bp3-popover-target {
		display: block;
		width: 100%;
	}
`;

const variableTypes = [
	{
		id: 1,
		value: 'Text',
	},
	{
		id: 2,
		value: 'Image',
	},
	{
		id: 3,
		value: 'Phone Number',
	},
	{
		id: 4,
		value: 'Email Address',
	},
	// {
	// 	id: 5,
	// 	value: 'Multiple Choice',
	// },
	// {
	// 	id: 6,
	// 	value: 'User',
	// },
];

class CreateVariables extends React.Component {
	state = {
		type: null,
		loading: false,
	};

	makeTypes = item => (
		<MenuItem
			key={item.id}
			onClick={() => this.setState({ type: item })}
			text={item.value}
		/>
	);

	toggleLoading = val => this.setState({ loading: val });

	reloadVars = () => {
		const { refreshVariables } = this.props;
		this.setState({ loading: false, type: null });
		return refreshVariables();
	};

	renderCreate = () => {
		const { toggleCreateDialog, templateId } = this.props;
		const { type, loading } = this.state;
		if (type) {
			switch (type.id) {
				case 1:
					return (
						<TextVariable
							toggleCreateDialog={toggleCreateDialog}
							templateId={templateId}
							refreshVariables={this.reloadVars}
							toggleLoading={this.toggleLoading}
							loading={loading}
						/>
					);
				case 2:
					return (
						<ImageVariable
							toggleCreateDialog={toggleCreateDialog}
							templateId={templateId}
							refreshVariables={this.reloadVars}
							toggleLoading={this.toggleLoading}
							loading={loading}
						/>
					);
				case 3:
					return (
						<PhoneVariable
							toggleCreateDialog={toggleCreateDialog}
							templateId={templateId}
							refreshVariables={this.reloadVars}
							toggleLoading={this.toggleLoading}
							loading={loading}
						/>
					);
				case 4:
					return (
						<EmailVariable
							toggleCreateDialog={toggleCreateDialog}
							templateId={templateId}
							refreshVariables={this.reloadVars}
							toggleLoading={this.toggleLoading}
							loading={loading}
						/>
					);
				case 5:
					return (
						<MultipleChoiceVariable
							toggleCreateDialog={toggleCreateDialog}
							templateId={templateId}
							refreshVariables={this.reloadVars}
							toggleLoading={this.toggleLoading}
							loading={loading}
						/>
					);
				case 6:
					return (
						<UserVariable
							toggleCreateDialog={toggleCreateDialog}
							templateId={templateId}
							refreshVariables={this.reloadVars}
							toggleLoading={this.toggleLoading}
							loading={loading}
						/>
					);
				default:
					return null;
			}
		}
		return null;
	};

	render() {
		const { createVarDialog, toggleCreateDialog } = this.props;
		const { type, loading } = this.state;
		return (
			<Dialog
				isOpen={createVarDialog}
				onClose={() => toggleCreateDialog(false)}
			>
				<div>
					<TitleWrap>
						<Title>Create Variable</Title>
						<Button
							text=""
							minimal
							icon="cross"
							onClick={() => {
								toggleCreateDialog(false);
							}}
						/>
					</TitleWrap>
					<hr />
				</div>
				<div className="px-3">
					{!loading && (
						<>
							<LabelText>Variable Type</LabelText>
							<div className="d-flex">
								<SelectWrap
									items={variableTypes}
									itemRenderer={this.makeTypes}
									className="w-100"
									filterable={false}
								>
									<DropdownWrap>
										<div>
											{type ? type.value : 'Select'}
										</div>
										<Icon icon="caret-down" />
									</DropdownWrap>
								</SelectWrap>
							</div>
						</>
					)}

					{this.renderCreate()}
				</div>
			</Dialog>
		);
	}
}

CreateVariables.propTypes = {
	createVarDialog: PropTypes.bool,
	toggleCreateDialog: PropTypes.func,
	templateId: PropTypes.string,
	refreshVariables: PropTypes.func,
};

export default CreateVariables;
