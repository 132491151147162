import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import { CallListModals } from '../Modals';
import { ADD_EDIT_CALL_LIST, DELETE_CALL_LIST } from '../Components/consts';
import { BuildingsService } from 'services';
import { filterSitesPerPermissionCode } from 'utilities/permissions';

export const CallListContext = createContext();

const filterationDataReducer = (filterationData, action) => {
	switch (action.type) {
		case 'selectedSites':
			filterationData.selectedSites = action.payload;
			filterationData.page = 1;
			break;
		case 'title':
			filterationData.title = action.payload;
			filterationData.page = 1;
			break;
		default:
			filterationData[action.type] = action.payload;
			break;
	}
	return {
		page: filterationData.page,
		perPage: filterationData.perPage,
		selectedSites: filterationData.selectedSites,
		title: filterationData.title,
		sortKey: filterationData.sortKey,
		sortDir: filterationData.sortDir,
	};
};

export const CallListProviders = ({ children }) => {
	const [modalToShow, setModalToShow] = useState(false);
	const [sites, setSites] = useState([]);
	const [viewOnlySites, setViewOnlySites] = useState([]);
	const [reloadCallLists, setReloadCallLists] = useState(false);
	const [selectedCallListId, setSelectedCallListId] = useState();
	const [callList, setCallList] = useState();
	const [filterationData, dispatchFilterationData] = useReducer(filterationDataReducer, {
		page: 1,
		perPage: 10,
		selectedSites: [],
		title: '',
		sortKey: 'title',
		sortDir: 'asc',
	});
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('call_list_edit', permissions);

	const addNewCallList = () => {
		setSelectedCallListId(null);
		setModalToShow(ADD_EDIT_CALL_LIST);
	};
	const getSitesList = useCallback(() => {
		BuildingsService.getAll(false).then(serviceBuildings => {
			const filteredSites = filterSitesPerPermissionCode(
				'call_list_edit',
				permissions,
				serviceBuildings,
			);
			const view_permission_sites = filterSitesPerPermissionCode(
				'call_list_view',
				permissions,
				serviceBuildings,
			);
			setSites(filteredSites);
			setViewOnlySites(view_permission_sites);
		});
	}, []);

	useEffect(() => {
		getSitesList();
	}, []);

	const editCallList = callListId => {
		setModalToShow(ADD_EDIT_CALL_LIST);
		setSelectedCallListId(callListId);
	};

	const deleteCallList = callListData => {
		setCallList(callListData);
		setModalToShow(DELETE_CALL_LIST);
	};

	const modalFunctions = {
		modalToShow,
		setModalToShow,
		filterationData,
		dispatchFilterationData,
		editPermission,
		reloadCallLists,
		setReloadCallLists,
		addNewCallList,
		editCallList,
		selectedCallListId,
		setSelectedCallListId,
		sites,
		viewOnlySites,
		deleteCallList,
		callList,
	};

	return <CallListContext.Provider value={modalFunctions}>{children}</CallListContext.Provider>;
};
