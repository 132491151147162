import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as BpButton } from '@blueprintjs/core';

const StyledBP = styled(BpButton)`
	border-radius: 3px;
	height: 40px;
	${({ secondary }) => {
		if (secondary === 'true') {
			return `
				border: 1px solid #B3D8DD !important;
				color: #007E8F !important;
				background: #fff;
			`;
		}
		return null;
	}};
	${({ tertiary }) => {
		if (tertiary === 'true') {
			return `
				border: none !important;
				color: #9F1C3A;
				background: none;
			`;
		}
		return null;
	}};
	${({ primary }) => {
		if (primary === 'true') {
			return `
				border: 1px solid #007E8F !important;
				color: #fff;
				background: #007E8F !important;
				box-shadow: none !important;
			`;
		}
		return null;
	}};
	${({ switchon }) => {
		if (switchon === 'true') {
			return `
				border: 1px solid #3379A4 !important;
				background: #F3F8FB;
				font-weight: bold;
				color: #3379A4 !important;
				margin-right: 1rem;
			`;
		}
		return null;
	}};

	${({ switchoff }) => {
		if (switchoff === 'true') {
			return `
				background-color: #FFF;
				border: 1px solid #5C6A76 !important;
				color: #5C6A76 !important;
				margin-right: 1rem;
			`;
		}
		return null;
	}};

	${({ primaryDanger }) => {
		if (primaryDanger === 'true') {
			return `
				background-color: #E81717;
				border: 1px solid #E81717 !important;
				color: #FFF !important;
			`;
		}
		return null;
	}};

	${({ secondaryDanger }) => {
		if (secondaryDanger === 'true') {
			return `
				background-color: #FFF;
				border: 1px solid #F6A2A2 !important;
				color: #E81717 !important;
			`;
		}
		return null;
	}};

	${({ ghostPrimary }) => {
		if (ghostPrimary === 'true') {
			return `
				color: #007E8F !important;
			`;
		}
		return null;
	}};
`;

const Button = ({
	icon,
	onClick,
	intent,
	text,
	large,
	minimal,
	active,
	disabled,
	rightIcon,
	className,
	type,
	loading,
	...rest
}) => (
	<StyledBP
		secondary={intent === 'secondary' ? 'true' : 'false'}
		tertiary={intent === 'tertiary' ? 'true' : 'false'}
		primary={intent === 'primary' ? 'true' : 'false'}
		switchon={intent === 'switchon' ? 'true' : 'false'}
		switchoff={intent === 'switchoff' ? 'true' : 'false'}
		primaryDanger={intent === 'primaryDanger' ? 'true' : 'false'}
		secondaryDanger={intent === 'secondaryDanger' ? 'true' : 'false'}
		ghostPrimary={intent === 'ghostPrimary' ? 'true' : 'false'}
		type={type}
		className={className}
		icon={icon}
		onClick={onClick}
		intent={intent}
		text={text}
		large={large}
		minimal={minimal}
		active={active}
		disabled={disabled}
		rightIcon={rightIcon}
		loading={loading}
		{...rest}
	/>
);

Button.propTypes = {
	icon: PropTypes.string,
	rightIcon: PropTypes.string,
	intent: PropTypes.string,
	onClick: PropTypes.func,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	large: PropTypes.bool,
	minimal: PropTypes.bool,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	type: PropTypes.string,
	loading: PropTypes.bool,
};

export { Button };
