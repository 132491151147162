import React from 'react';
import { Button } from 'componentsV2';

export const ActionsList = ({ alarm, history }) => {
	return (
		<Button
			large
			text={
				<div style={{ textAlign: 'center', marginRight: 12 }}>
					View past
					<br />
					alarm details
				</div>
			}
			size="md"
			type="ghostPrimary"
			intent="default"
			className="actionButtonElement no-padding"
			onClick={() => history.push(`/past-alarms/${alarm.id}`)}
		/>
	);
};

ActionsList.propTypes = {};
