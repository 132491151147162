import React from 'react';
import PropTypes from 'prop-types';
import { Title } from 'components/Title';
import { PageHeader } from '../../components/PageHeader';
import { EMSHomeComingSoon, CallLists, RapidAlarmComingSoon, Maps } from '../../assets/icons';
import './styles.css';

const PAGES = {
	home: {
		title: 'New EMS is Coming Soon!',
		image: EMSHomeComingSoon,
	},
	maps: {
		title: 'Maps are Coming Soon!',
		subText: 'You’ll be able to create and view maps and floorplans',
		image: Maps,
	},
	callLists: {
		title: 'Call Lists are Coming Soon!',
		subText: 'You’ll be able to manage contact information.',
		image: CallLists,
	},
	rapidAlarm: {
		title: 'Rapid Alarm is Coming Soon!',
		subText:
			'You’ll be able to run drill and training alarms as well as manage an emergency for staff and students',
		image: RapidAlarmComingSoon,
	},
};
export const NewEMSComingSoon = props => {
	const {
		match: {
			params: { selectedPage },
		},
		page,
	} = props;
	return (
		<div>
			<PageHeader title="Emergency Management Solution" />
			<div className="coming-soon-main-container">
				<div className="coming-soon-sub-container">
					<img
						src={page ? PAGES[page]?.image : PAGES[selectedPage]?.image}
						alt="alt-img"
					/>
					<Title className="coming-soon-title-container">
						{page ? PAGES[page]?.title : PAGES[selectedPage]?.title}
					</Title>
					<div className="coming-soon-sub-text-container">
						<p>{page ? PAGES[page]?.subText : PAGES[selectedPage]?.subText}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

NewEMSComingSoon.propTypes = { match: PropTypes.object };
