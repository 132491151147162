export const getStagePointersPositions = (event, stage, map, mapZoom, floorplanDetails) => {
	stage.current.setPointersPositions(event);
	const stagePosition = stage.current.getPointerPosition();
	const scaleFactor = getScaleFactor(map, mapZoom);
	const newLayerPoint = getUpdatedContainerPoints(map, floorplanDetails);

	return {
		x: (stagePosition.x - newLayerPoint.x) / scaleFactor,
		y: (stagePosition.y - newLayerPoint.y) / scaleFactor,
	};
};

export const getScaleFactor = (map, mapZoom) => {
	return Math.pow(2, map.getZoom() - mapZoom);
};

export const convetLatLngToPoints = (map, floorplanDetails) => {
	return map.latLngToLayerPoint([floorplanDetails.building.lat, floorplanDetails.building.long]);
};

export const getUpdatedContainerPoints = (map, floorplanDetails) => {
	const pts = convetLatLngToPoints(map, floorplanDetails);
	return map.layerPointToContainerPoint(pts);
};

export const getMousePosition = (stage, map, mapZoom, floorplanDetails) => {
	const scaleFactor = getScaleFactor(map, mapZoom);
	const mouse = stage.getPointerPosition();
	const initalPoints = getUpdatedContainerPoints(map, floorplanDetails);

	const xPos = (mouse.x - initalPoints.x) / scaleFactor;
	const yPos = (mouse.y - initalPoints.y) / scaleFactor;

	return {
		x: xPos,
		y: yPos,
	};
};
