import { HttpService, buildRoute } from 'networking/HttpService';

const GENERIC_ERROR = new Error('An error occurred, please try again');

export const ContactService = {
	getAll: async (callListId, params) => {
		let query = '';
		if (params.combineSearch) {
			query += `combineSearch=${params.combineSearch}`;
		}
		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/call-lists/${callListId}/contacts?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	addContact: async params =>
		await HttpService({
			method: 'POST',
			route: '/contacts',
			headers: {
				'Content-Type': 'application/json',
			},
			body: params,
		}).catch(err => {
			throw err;
		}),

	async delete(params) {
		return HttpService({
			method: 'DELETE',
			route: `/contacts`,
			body: params,
		}).then(res => {
			if (res.statusCode === 200) {
				return res;
			}
			throw GENERIC_ERROR;
		});
	},


	addExistingContacts: async (contactsList, id) => {
		var usersIds = [];
		for (var key in contactsList){
			usersIds.push({
				id: contactsList[key]['id'],
				accessType: contactsList[key]['accessType'],
			});
		}
		return await HttpService({
			method: 'POST',
			route: '/call-lists/add-contacts/'+ id,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				userIds : usersIds
			},
		}).catch(err => {
			throw err;
		});
	},

	getAllExistingContacts: async (callListId, params) => {
		let query = '';
		if (params.combineSearch) {
			query += `combineSearch=${params.combineSearch}`;
		}
		if (params.page) {
			query += `&page=${params.page}`;
			//query += `&page=2`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/call-lists/${callListId}/users?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},
	getById: async (contactId) => {
		return await HttpService({
			method: 'GET',
			route: '/contacts/'+contactId,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	updateContact: async (contactId, data) => {
		return HttpService({
			method: 'PUT',
			route: '/contacts/'+contactId,
			body: data
		}).then(res => {
			if (res.statusCode === 200) {
				return res.data;
			}

			throw GENERIC_ERROR;
		});
	},
};
