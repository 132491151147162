import React, { useContext, useRef, useState } from 'react';
import NormalModal from '../../../components/Modals/NormalModal';
import { SitesDropdownMultiSelect } from '../../../components/SitesDropdownMultiSelect';
import { RadioGroup, Radio } from '@blueprintjs/core';
import { ToasterContext } from '../../App';
import { Intent } from '@blueprintjs/core';
import { UsersService } from '../../../services/UsersService';
import UnsavedChangesModal from '../../../components/Modals/UnsavedChangesModal';

const AssignToSitesModal = ({ selectedUsers, onCloseModal, onConfirmAction }) => {
	const toaster = useContext(ToasterContext);
	const [confirmBtnTitle, setConfirmBtnTitle] = useState('Add');
	const [selectedType, setSelectedType] = useState('addSite');
	const [selectedSites, setSelectedSites] = useState([]);
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const searchBtnClickHandler = () => {
		if (selectedSites.length == 0) {
			setError('Please select site');
			return;
		} else {
			setError(null);
		}
		var usersIds = [];
		selectedUsers.map(selectedUser => {
			usersIds.push(selectedUser.id);
		});
		var sitesIds = [];
		selectedSites.map(selectedSite => {
			if (selectedSite.value != '*') {
				sitesIds.push(selectedSite.id);
			}
		});

		setLoading(true);
		if (selectedType === 'removeSite') {
			UsersService.removeSites(usersIds, sitesIds)
				.then(resp => {
					if (resp.statusCode === 200) {
						toaster(resp.data, Intent.SUCCESS);
						onConfirmAction();
					} else {
						toaster(resp.data, Intent.DANGER);
					}
					onCloseModal();
				})
				.catch(() => {
					onCloseModal();
				});
		} else {
			//addSite
			UsersService.assignSites(usersIds, sitesIds)
				.then(resp => {
					if (resp.statusCode === 200) {
						toaster(resp.data.message, Intent.SUCCESS);
						onConfirmAction();
					} else {
						toaster(resp.data.message, Intent.DANGER);
					}
					onCloseModal();
				})
				.catch(() => {
					onCloseModal();
				});
		}
	};

	const cancelBtnClickHandler = () => {
		if (selectedSites.length == 0) {
			onCloseModal();
		} else {
			setShowUnsavedChangesModal(true);
		}
	};

	const selectSitesHandler = items => {
		if (items.length == 0) {
			setError('Please select site');
		} else {
			setError(null);
		}
		setSelectedSites(items);
	};

	const addSiteCheckHandler = () => {
		setSelectedType('addSite');
		setConfirmBtnTitle('Add');
	};

	const removeSiteCheckHandler = () => {
		setSelectedType('removeSite');
		setConfirmBtnTitle('Remove');
	};

	return (
		<>
			{showUnsavedChangesModal && (
				<UnsavedChangesModal
					onConfirmAction={onCloseModal}
					onCancelAction={() => setShowUnsavedChangesModal(false)}
				/>
			)}
			<NormalModal
				onConfirmAction={searchBtnClickHandler}
				onCancelAction={cancelBtnClickHandler}
				header="Edit Building Alerts"
				confirmBtnTitle={confirmBtnTitle}
				cancelBtnTitle="Cancel"
				confirmBtnDisabled={false}
				loading={loading}
			>
				<div className="row mb-3">
					<div className="col-md-12">
						<div className="form-group">
							<SitesDropdownMultiSelect
								value={selectedSites}
								onChange={selectSitesHandler}
								visibleOptions={1}
								error={error}
								isRequired={true}
							/>
							{!error && (
								<span
									style={{
										fontSize: '12px',
										lineHeight: '16px',
										color: '#5C7080',
									}}
								>
									Edit sites for alarm notifications
								</span>
							)}
						</div>
					</div>
					<div className="col-md-12">
						<RadioGroup inline selectedValue={selectedType}>
							<Radio
								label="Add site"
								value="addSite"
								onClick={addSiteCheckHandler}
								key={`radioOption-${1}`}
							/>
							<Radio
								label="Remove site"
								value="removeSite"
								onClick={removeSiteCheckHandler}
								key={`radioOption-${2}`}
							/>
						</RadioGroup>
					</div>
				</div>
			</NormalModal>
		</>
	);
};

export default AssignToSitesModal;
