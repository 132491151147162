import { basicInfoValidation } from '../BasicInfo';
import { contact911Validation } from '../Contact911';
import { countdownValidation } from '../Countdown';
import { flipchartValidation } from '../Flipchart';
import { rollCallValidation } from '../RollCall';

export const previewValidation = formData => {
	const basicInfoValidationResponse = basicInfoValidation(formData);
	const contact911ValidationResponse = contact911Validation(formData);
	const countdownValidationResponse = countdownValidation(formData);
	const flipchartValidationResponse = flipchartValidation(formData);
	const rollCallValidationResponse = rollCallValidation(formData);

	const errors = {
		...basicInfoValidationResponse.errors,
		...contact911ValidationResponse.errors,
		...countdownValidationResponse.errors,
		...flipchartValidationResponse.errors,
		...rollCallValidationResponse.errors,
	};

	return {
		errors,
		isValid: Object.keys(errors).length === 0,
	};
};
