import React, { useEffect, useState } from 'react';
import { Badge, Tooltip, Button, Animated } from 'componentsV2';
import './css/FilterBar.scss';

export const FilterBar = ({
	items = [],
	getFilterValue = () => {},
	removeFilter = () => {},
	isShown = false,
}) => {
	const [showFilterBar, setShowFilterBar] = useState(false);

	useEffect(() => {
		setShowFilterBar(isShown);
		if (!isShown) {
			const hasFilterValue = items.some(item => getFilterValue(item.name));
			setShowFilterBar(hasFilterValue);
		}
	}, [items, isShown]);

	const getFilterLabel = item => {
		let itemValue = getFilterValue(item.name);
		let label = itemValue;
		if (item.options) {
			if (item.multipleSelect) {
				label = '';
				if (itemValue.length > 0) {
					let optionLabels = [];
					itemValue.forEach(optionValue => {
						let optionSelected = item.options.filter(
							option => option.value == optionValue,
						);
						optionLabels.push(
							optionSelected && optionSelected.length == 1
								? optionSelected[0].label
								: optionValue,
						);
					});
					optionLabels.sort();
					label = optionLabels.join(', ');
				}
			} else {
				let optionSelected = item.options.filter(option => option.value == itemValue);
				label =
					optionSelected && optionSelected.length == 1
						? optionSelected[0].label
						: itemValue;
			}
		} else if (item.values) {
			label = item.values.join(', ');
		}
		return label;
	};

	const handleResetFilters = () => {
		items.forEach(item => {
			removeFilter(item.name);
		});
	};

	if (!showFilterBar) return null;
	return (
		items.length > 0 && (
			<div className="filter-bar-wrapper">
				<div className="filters-applied">
					{items.map(
						(item, index) =>
							(getFilterValue(item.name) || !!item.values?.length) && (
								<Tooltip key={index} tooltipTitle={item.title} theme="light">
									<Animated>
										<Badge
											type="Gray"
											size="lg"
											text={getFilterLabel(item)}
											hasDotIcon={false}
											hasCloseIcon={true}
											onClick={() => removeFilter(item.name)}
											maxWidth={300}
										/>
									</Animated>
								</Tooltip>
							),
					)}
				</div>
				<div className="filter-right-options">
					<Animated>
						<Button
							className="reset-filters-button"
							type="ghostTertiary"
							size="sm"
							text="Reset filter"
							icon="reset"
							intent="default"
							onClick={handleResetFilters}
						/>
					</Animated>
				</div>
			</div>
		)
	);
};
