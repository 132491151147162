import React from 'react';
import './LightTable.css';
import { BootstrapedTable, EmptyState, emptyDataIcon } from 'componentsV2';
import { Radio } from '@blueprintjs/core';
import { isNotEmpty } from 'utilities/validations';
import { PhoneWithExtension } from 'utilities/PhoneWithExtension';
import { Helper } from 'utilities/helper';

const LightTableComponent = ({ data, onClickUpdatePrimaryContact }) => {
	const tableColumns = [
		{
			key: 'id',
			dataField: 'id',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Name',
			formatter: (cell, row) => {
				return `${row.first_name} ${row.family_name}`;
			},
			editable: false,
		},
		{
			key: 'email',
			dataField: 'email',
			text: 'Email',
			editable: false,
		},
		{
			key: 'work_number',
			dataField: 'work_number',
			text: 'Contact number',
			formatter: (cell, row) =>
				isNotEmpty(row.work_number) ? (
					<PhoneWithExtension
						phone={Helper.formatDigitsToPhone(row?.work_number)}
						extension={row?.work_ext_number}
					/>
				) : (
					'N/A'
				),
			editable: true,
		},
		{
			key: 'isPrimary',
			dataField: 'isPrimary',
			text: 'Primary Contact',
			formatter: (cell, row) => {
				return <Radio checked={row.isPrimary} onChange={() => handleRadioChange(row)} />;
			},
			editable: true,
		},
	];
	const handleRadioChange = row => {
		onClickUpdatePrimaryContact('UpdatePrimaryContactModal', row);
	};
	return (
		<>
			{data.length === 0 ? (
				<div className="empty-table">
					<EmptyState
						header="No contacts were added yet"
						icon={emptyDataIcon}
						showCircles={false}
					/>
				</div>
			) : (
				<BootstrapedTable
					keyField="id"
					data={data.contacts}
					columns={tableColumns}
					showPagination={false}
				/>
			)}
		</>
	);
};

export default LightTableComponent;
