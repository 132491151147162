import React from 'react';
import { Tooltip } from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import './css/SidebarListItem.scss';

export const SidebarListItem = ({
	tooltip = null,
	myClass,
	selected,
	custom = false,
	onClick = () => {},
}) => {
	const getDateDiff = () => {
		let dateDiff = 0;
		if (myClass && new Date(myClass.lastSubmittedDate).toString() !== 'Invalid Date') {
			dateDiff = new Date().getDate() > new Date(myClass.lastSubmittedDate).getDate();
		}
		return dateDiff;
	};

	return (
		<div
			onClick={() => onClick(myClass ? myClass : null)}
			className={`sidebar-list-item ${selected ? 'class-selected' : ''}`}
		>
			<div className="sidebar-list-item-title">
				<p>{custom ? 'Custom list' : myClass.courseName}</p>
				{tooltip && (
					<Tooltip tooltipTitle={tooltip}>
						<Icon icon="info-sign" />
					</Tooltip>
				)}
			</div>
			{!custom && (
				<>
					<div className="sidebar-list-item-description">
						{myClass.grade && <>Grade: {myClass.grade} </>}
						{myClass.period && <>Period: {myClass.period}</>}
						{myClass.user?.person && (
							<>
								<br />
								Teacher: {myClass.user?.person.fullName}
							</>
						)}
					</div>
					<div>
						{myClass.lastSubmittedDate && myClass.hasChangesAfterSubmission && (
							<>
								<span className="dot-icon-wrapper">
									<Icon icon="record" />
								</span>
								<span className="normalText">New Changes</span>
								<p className="lastSubmitText">
									<i>Last submitted at {myClass.lastSubmittedTime}</i>
								</p>
							</>
						)}
						{!myClass.lastSubmittedDate && myClass.hasChangesAfterSubmission && (
							<>
								<span className="dot-icon-wrapper">
									<Icon icon="record" />
								</span>
								<span className="normalText">Pending submission</span>
							</>
						)}
						{myClass.lastSubmittedDate && !myClass.hasChangesAfterSubmission && (
							<>
								<span className="tick-icon-wrapper">
									<Icon icon="tick" />
								</span>
								<span className="primaryText">
									Submitted at{' '}
									{getDateDiff()
										? myClass.lastSubmittedDate
										: myClass.lastSubmittedTime}
								</span>
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
};
