import React, { useContext, useEffect, useState } from 'react';
import NormalModal from 'components/Modals/NormalModal';
import { Input } from 'components/Input';
import './css/ExternalContactModal.scss';
import {
	AllowOnlyNumeric,
	isNotEmpty,
	isValidEmail,
	isValidPhone,
} from '../../../utilities/validations';
import { newUserAvatar } from '../../../assets/icons';
import { Dropdown } from 'componentsV2';
import { SiteDropdownSingleSelect } from '../../../components/SiteDropdownSingleSelect';
import { CallListService } from 'services/CallListService';
import { Icon, Intent } from '@blueprintjs/core';
import { ContactService } from '../../../services/ContactService';
import { ErrorTextWrapper } from 'components/Forms/shared.js';
import { ToasterContext } from 'pages/App';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import { useSelector } from 'react-redux';
import { checkPermission } from '../../../utilities/permissions';
import { Helper } from 'utilities/helper';
import { PHONE_NUMBER_MASK, PHONE_NUMBER_PLACEHOLDER } from 'utilities/constants';
import ImageUploadModal from './ImageUploadModal';
import { PHONE_TYPES, SORTING_DIR } from 'utilities/constants';
import { generateAvatar } from '../../App/AvatarInitials';

const ExternalContactModal = props => {
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);
	const [readOnly, setReadOnly] = useState(false);
	const [avatar, setAvatar] = useState(newUserAvatar);
	const [avatarInitials, setAvatarInitials] = useState(null);
	const [isDefault, setIsDefault] = useState(true);
	const [sites, setSites] = useState([]);
	const [selectedSite, setSelectedSite] = useState({});
	const [siteCallList, setSiteCallList] = useState([]);
	const [selectedLists, setSelectedLists] = useState([]);
	const [defaultCallLists, setDefaultCallLists] = useState([]);
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	const [title, setTitle] = useState('');
	const permissions = useSelector(state => state.route.permissions);
	const [modalToShow, setModalToShow] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);

	const [formData, setFormData] = useState({
		first_name: null,
		last_name: null,
		staff_title: null,
		email: null,
		mobile_phone: null,
		work_phone: null,
		work_phone_ext: null,
		home_phone: null,
		call_list_ids: [],
		image: null,
		image_type: null,
	});

	const [validationErrors, setValidationErrors] = useState({
		first_name: { hasError: false, errorMsg: '' },
		last_name: { hasError: false, errorMsg: '' },
		staff_title: { hasError: false, errorMsg: '' },
		email: { hasError: false, errorMsg: '' },
		mobile_phone: { hasError: false, errorMsg: '' },
		work_phone: { hasError: false, errorMsg: '' },
		work_phone_ext: { hasError: false, errorMsg: '' },
		home_phone: { hasError: false, errorMsg: '' },
		call_list_ids: { hasError: false, errorMsg: '' },
		phones: { hasError: false, errorMsg: '' },
	});

	useEffect(() => {
		if (props.mode == 'add') {
			setTitle('Add Contact');
			const selectedSite = props.selectedSites[0]
				? props.selectedSites[0]
				: {
						id: props.selectedCallList.site_id,
						value: props.selectedCallList.site_id,
						label: props.selectedCallList.site_name,
				  };
			fetchCallLists(selectedSite, props.selectedCallList, 0);
		}

		if (props.mode == 'view') {
			setReadOnly(true);
			setTitle('View Contact');
			fetchContact(props.contactID);
		}

		if (props.mode == 'edit') {
			setTitle('Edit Contact');
			fetchContact(props.contactID);
		}

		if (props.selectedSites) {
			setSelectedSite(props.selectedSites[0]);
		}
	}, []);

	const fetchContact = contactId => {
		setLoading(true);

		ContactService.getById(contactId)
			.then(response => {
				let mobile_phone = null;
				let work_phone = null;
				let work_phone_ext = null;
				let home_phone = null;

				if (response.data.person.phones.length > 0) {
					response.data.person.phones.forEach(function(item) {
						switch (item.type) {
							case PHONE_TYPES.WORK:
								work_phone = item.phoneNumber;
								work_phone_ext = item.extension;
								break;
							case PHONE_TYPES.HOME:
								home_phone = item.phoneNumber;
								break;
							case PHONE_TYPES.MOBILE:
								mobile_phone = item.phoneNumber;
								break;
							default:
								break;
						}
					});
				}

				let siteCallList = [];
				let callListIds = [];

				response.data.contactCallLists.forEach(function(item) {
					let isDisabled = false;

					if (props.mode == 'view') {
						isDisabled = true;
					} else if (props.mode == 'edit') {
						isDisabled = !checkPermission(
							'call_list_edit',
							permissions,
							item.callList.site_id,
						);
					}

					siteCallList.push({
						sites: sites,
						selectedSite: {
							label: item.callList.site,
							value: item.callList.site_id,
							id: item.callList.site_id,
						},
						callLists: [
							{
								label: item.callList.title,
								value: item.callList.id,
								site_id: item.callList.site_id,
							},
						],
						selectedCallList: {
							label: item.callList.title,
							value: item.callList.id,
							site_id: item.callList.site_id,
						},
						disabled: isDisabled,
					});

					callListIds.push(item.callList.id);
				});

				setSiteCallList(siteCallList);

				setFormData({
					...formData,
					first_name: response.data.person.name.givenName,
					last_name: response.data.person.name.familyName,
					staff_title:
						response.data.person.name.title != ''
							? response.data.person.name.title
							: null,
					email: response.data.person.email.emailAddress,
					mobile_phone: mobile_phone,
					home_phone: home_phone,
					work_phone: work_phone,
					work_phone_ext: work_phone_ext,
					call_list_ids: callListIds,
				});

				let contactName =
					response.data.person.name.givenName.charAt(0) +
					response.data.person.name.familyName.charAt(0);
				let generatedAvatar = generateAvatar(contactName);
				setAvatarInitials(generatedAvatar);

				if (response.data.personPhotoUrl) {
					setAvatar(response.data.personPhotoUrl);
					setIsDefault(false);
				} else {
					setAvatar(generatedAvatar);
				}

				setLoading(false);
			})
			.catch(error => {
				toaster('Something went wrong', Intent.DANGER);
				setLoading(false);
				props.onCloseModal();
			});
	};

	const fetchCallLists = (site, selectedCallList, index) => {
		return CallListService.getAll({
			selectedBuilders: [site ? site.id : selectedCallList.site_id],
			sortKey: 'title',
			sortDir: SORTING_DIR.ASC,
			page: 1,
			perPage: 1000,
		}).then(resp => {
			if (resp.statusCode === 200) {
				var lists = [];

				resp.data.map(item => {
					lists.push({
						label: item.title,
						value: item.id,
					});
				});

				if (props.selectedSites.length > 0 && site.id == props.selectedSites[0].id) {
					setDefaultCallLists(lists);
				}

				var newSistesCallList = [...siteCallList];
				newSistesCallList[index] = {
					sites: sites,
					selectedSite: site,
					callLists: lists,
					selectedCallList: selectedCallList,
					disabled: readOnly,
				};
				setSiteCallList(newSistesCallList);
				updateCallLists(newSistesCallList);
			}
		});
	};

	const onConfirmActionHandler = () => {
		var errors = {};

		if (!isNotEmpty(formData.first_name)) {
			errors.first_name = { hasError: true, errorMsg: 'First name is required' };
		}

		if (!isNotEmpty(formData.last_name)) {
			errors.last_name = { hasError: true, errorMsg: 'Last name is required' };
		}

		if (!isNotEmpty(formData.email)) {
			errors.email = { hasError: true, errorMsg: 'Email is required' };
		} else if (!isValidEmail(formData.email)) {
			errors.email = { hasError: true, errorMsg: 'Email is not valid' };
		}

		if (!isValidPhone(formData.mobile_phone)) {
			errors.mobile_phone = { hasError: true, errorMsg: 'Mobile phone is not valid' };
		}

		if (!isValidPhone(formData.work_phone)) {
			errors.work_phone = { hasError: true, errorMsg: 'Work phone is not valid' };
		}

		if (!isValidPhone(formData.home_phone)) {
			errors.home_phone = { hasError: true, errorMsg: 'Home phone is not valid' };
		}

		if (
			formData.work_phone_ext &&
			formData.work_phone_ext.trim().length > 0 &&
			(!formData.work_phone || formData.work_phone.trim().length < 1)
		) {
			errors.work_phone = {
				hasError: true,
				errorMsg: 'Work phone is required when extension is filled.',
			};
		}

		if (!formData.mobile_phone && !formData.home_phone && !formData.work_phone) {
			errors.phones = {
				hasError: true,
				errorMsg: 'At least one phone number should be filled',
			};
		}

		if (formData.call_list_ids.length < 1) {
			errors.call_list_ids = {
				hasError: true,
				errorMsg: 'At least one Call list should be selected',
			};
		}

		setValidationErrors({ ...validationErrors, ...errors });

		if (Object.keys(errors).length == 0) {
			setLoading(true);

			for (var inputKey in formData) {
				if (
					(inputKey == 'mobile_phone' ||
						inputKey == 'work_phone' ||
						inputKey == 'work_phone_ext' ||
						inputKey == 'home_phone') &&
					formData[inputKey] != null &&
					formData[inputKey].trim() == ''
				) {
					formData[inputKey] = null;
				}
			}

			if (props.mode == 'add') {
				ContactService.addContact(formData)
					.then(response => {
						toaster('Successfully created a new contact.', Intent.SUCCESS);
						props.onCloseModal();
						props.updateDataCallBack();
						setLoading(false);
					})
					.catch(error => {
						handleApiErrorResponse(error);
						setLoading(false);
					});
			} else if (props.mode == 'edit') {
				ContactService.updateContact(props.contactID, formData)
					.then(response => {
						toaster('Contact successfully updated.', Intent.SUCCESS);
						props.onCloseModal();
						props.updateDataCallBack();
						setLoading(false);
					})
					.catch(error => {
						handleApiErrorResponse(error);
						setLoading(false);
					});
			}
		}
	};

	const handleApiErrorResponse = error => {
		if (error.statusCode == 400) {
			var errors = validationErrors;

			for (var key in error.error.fields) {
				errors[key] = { hasError: true, errorMsg: error.error.fields[key] };
			}

			setValidationErrors({ ...validationErrors, ...errors });
		}
	};

	const cancelBtnClickHandler = () => {
		if (hasChanges) {
			setShowUnsavedChangesModal(true);
		} else {
			props.onCloseModal();
		}
	};

	const handleInputChange = e => {
		setHasChanges(true);

		let inputValue = e.target.value;

		if (
			e.target.name == 'mobile_phone' ||
			e.target.name == 'home_phone' ||
			e.target.name == 'work_phone'
		) {
			inputValue = Helper.formatPhoneToDigits(e.target.value);
		}

		setFormData({ ...formData, [e.target.name]: inputValue });

		let validations = {};

		let inputValidation = {
			[e.target.name]: { hasError: false, errorMsg: '' },
		};

		validations = { ...inputValidation };

		if (
			e.target.name == 'mobile_phone' ||
			e.target.name == 'home_phone' ||
			e.target.name == 'work_phone'
		) {
			validations = { ...validations, ...{ phones: { hasError: false, errorMsg: '' } } };
		}

		setValidationErrors({ ...validationErrors, ...validations });
	};

	const handleImageSelect = (imageType, imageData) => {
		setHasChanges(true);
		setAvatar(imageData);
		setIsDefault(false);
		setFormData({ ...formData, image_type: imageType, image: imageData });
		closeModal();
	};

	const removePhoto = () => {
		setHasChanges(true);
		setAvatar(avatarInitials);
		setIsDefault(true);
		setFormData({ ...formData, image_type: null, image: null });
	};

	const selectedSiteHandler = (index, site) => {
		setHasChanges(true);
		fetchCallLists(site, {}, index);
	};

	const selectedCallListHandler = (index, calllist) => {
		setHasChanges(true);

		if (selectedLists.indexOf(calllist.value) !== -1) {
			return false;
		}

		let newSistesCallList = [...siteCallList];
		let itemRow = newSistesCallList[index];

		newSistesCallList[index] = {
			sites: sites,
			selectedSite: itemRow.selectedSite,
			callLists: itemRow.callLists,
			selectedCallList: calllist,
			disabled: readOnly,
		};

		setSiteCallList(newSistesCallList);

		updateCallLists(newSistesCallList);

		setValidationErrors({
			...validationErrors,
			call_list_ids: { hasError: false, errorMsg: '' },
		});
	};

	const updateCallLists = items => {
		let callListIds = [];

		items.map(item => {
			if (item.selectedCallList.value != undefined) {
				callListIds.push(item.selectedCallList.value);
			}
		});

		setFormData({ ...formData, call_list_ids: callListIds });
		setSelectedLists(callListIds);
	};

	const removeListRow = index => {
		if (siteCallList.length > 1) {
			var newSistesCallList = [...siteCallList];
			newSistesCallList.splice(index, 1);
			setSiteCallList(newSistesCallList);

			updateCallLists(newSistesCallList);
		}
	};

	const addListRow = () => {
		setSiteCallList([
			...siteCallList,
			{
				sites: sites,
				selectedSite: selectedSite,
				callLists: defaultCallLists,
				disabled: readOnly,
			},
		]);
	};

	const closeModal = () => {
		setModalToShow(false);
	};

	const userAvatarClick = () => {
		if (readOnly) {
			return false;
		} else {
			setModalToShow('ImageUploadModal');
		}
	};
	return (
		<>
			<NormalModal
				onConfirmAction={onConfirmActionHandler}
				onCancelAction={cancelBtnClickHandler}
				header={title}
				confirmBtnTitle="Confirm"
				cancelBtnTitle="Cancel"
				confirmBtnDisabled={false}
				className="larg-modal"
				showConfirmBtn={!readOnly}
				loading={loading}
			>
				<div className="row add-external-contact-wrapper">
					<div className="col-md-3">
						<div
							className={'user-avatar ' + (readOnly ? 'disabled' : '')}
							style={{ backgroundImage: `url(${avatar})` }}
							onClick={userAvatarClick}
						/>
					</div>
					<div className="col-md-9 contact-form">
						<div className="row form">
							<h4 className="h4 col-12">User Details</h4>
							<h5 className="h6 col-12">User Information</h5>
							<div className="col-12">
								<div className="row">
									<div className="col-11">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<Input
														placeholder="Enter first name"
														value={formData.first_name}
														error={validationErrors.first_name.hasError}
														errorMessage={
															validationErrors.first_name.errorMsg
														}
														label="First name"
														isRequired={true}
														onChange={handleInputChange}
														name="first_name"
														maxLength={30}
														readOnly={readOnly}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<Input
														placeholder="Enter last name"
														value={formData.last_name}
														error={validationErrors.last_name.hasError}
														errorMessage={
															validationErrors.last_name.errorMsg
														}
														label="Last name"
														isRequired={true}
														onChange={handleInputChange}
														name="last_name"
														maxLength={30}
														readOnly={readOnly}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-1" />
								</div>
							</div>
							<div className="col-12">
								<div className="row">
									<div className="col-11">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<Input
														placeholder="Enter staff title"
														value={formData.staff_title}
														error={
															validationErrors.staff_title.hasError
														}
														errorMessage={
															validationErrors.staff_title.errorMsg
														}
														label="Staff title"
														isRequired={false}
														onChange={handleInputChange}
														name="staff_title"
														maxLength={30}
														readOnly={readOnly}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 mt-2 mb-4">
								<hr />
							</div>

							<h5 className="h6 col-12">Contact Information</h5>
							<div className="col-12">
								<div className="row">
									<div className="col-11">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<Input
														placeholder="Enter email address"
														value={formData.email}
														error={validationErrors.email.hasError}
														errorMessage={
															validationErrors.email.errorMsg
														}
														label="Email"
														isRequired={true}
														onChange={handleInputChange}
														name="email"
														maxLength={50}
														readOnly={readOnly}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="row">
									<div className="col-11">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<Input
														placeholder={PHONE_NUMBER_PLACEHOLDER}
														mask={PHONE_NUMBER_MASK}
														value={formData.mobile_phone}
														error={
															validationErrors.mobile_phone.hasError
														}
														errorMessage={
															validationErrors.mobile_phone.errorMsg
														}
														label="Mobile phone"
														isRequired={false}
														onChange={handleInputChange}
														name="mobile_phone"
														readOnly={readOnly}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="row">
									<div className="col-11">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<Input
														placeholder={PHONE_NUMBER_PLACEHOLDER}
														mask={PHONE_NUMBER_MASK}
														value={formData.work_phone}
														error={validationErrors.work_phone.hasError}
														errorMessage={
															validationErrors.work_phone.errorMsg
														}
														label="Work phone"
														isRequired={false}
														onChange={handleInputChange}
														name="work_phone"
														readOnly={readOnly}
													/>
												</div>
											</div>
											<div className="col-md-2">
												<div className="form-group">
													<Input
														placeholder="12345"
														value={formData.work_phone_ext}
														error={
															validationErrors.work_phone_ext.hasError
														}
														errorMessage={
															validationErrors.work_phone_ext.errorMsg
														}
														label="Ext."
														isRequired={false}
														onChange={handleInputChange}
														name="work_phone_ext"
														maxLength={5}
														onKeyDown={AllowOnlyNumeric}
														readOnly={readOnly}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="row">
									<div className="col-11">
										<div className="row">
											<div className="col-md-6">
												<div className="form-group">
													<Input
														placeholder={PHONE_NUMBER_PLACEHOLDER}
														mask={PHONE_NUMBER_MASK}
														value={formData.home_phone}
														error={validationErrors.home_phone.hasError}
														errorMessage={
															validationErrors.home_phone.errorMsg
														}
														label="Home phone"
														isRequired={false}
														onChange={handleInputChange}
														name="home_phone"
														readOnly={readOnly}
													/>
												</div>
												{validationErrors.phones.hasError && (
													<ErrorTextWrapper>
														{validationErrors.phones.errorMsg}
													</ErrorTextWrapper>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 mt-2 mb-4">
								<hr />
							</div>

							<h5 className="h6 col-12">Linked to (Call lists)</h5>

							{validationErrors.call_list_ids.hasError && (
								<div className="calllist-error">
									{' '}
									<ErrorTextWrapper>
										{validationErrors.call_list_ids.errorMsg}
									</ErrorTextWrapper>
								</div>
							)}

							{siteCallList.map((element, index) => (
								<div className="col-12" key={index}>
									<div className="row">
										<div className="col-11">
											<div className="row">
												<div className="col-6">
													<div className="form-group">
														<SiteDropdownSingleSelect
															selectedSite={element.selectedSite}
															sitesList={element.sites}
															onChange={e =>
																selectedSiteHandler(index, e)
															}
															disabled={element.disabled}
															permission="call_list_edit"
														/>
													</div>
												</div>
												<div className="col-6">
													<div className="form-group call-list-dropdown">
														<Dropdown
															label="Call List"
															value={element.selectedCallList}
															onChange={e =>
																selectedCallListHandler(index, e)
															}
															options={element.callLists}
															disabledOptions={selectedLists}
															disabled={element.disabled}
															labelState={false}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="col-1">
											{siteCallList.length > 1 && !element.disabled && (
												<Icon
													icon="ban-circle"
													onClick={() => removeListRow(index)}
												/>
											)}
										</div>
									</div>
								</div>
							))}

							<div className="row">
								<div className="col-12">
									{!readOnly && (
										<span className="add-calllist-row" onClick={addListRow}>
											<Icon icon="add" /> Add{' '}
										</span>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</NormalModal>
			{!readOnly && showUnsavedChangesModal && (
				<UnsavedChangesModal
					onConfirmAction={() => props.onCloseModal()}
					onCancelAction={() => setShowUnsavedChangesModal(false)}
				/>
			)}

			{modalToShow === 'ImageUploadModal' && (
				<ImageUploadModal
					closeModal={closeModal}
					handleImageSelect={handleImageSelect}
					removePhoto={removePhoto}
					avatarImage={avatar}
					isDefault={isDefault}
				/>
			)}
		</>
	);
};

export default ExternalContactModal;
