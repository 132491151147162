import React, { useState, useEffect, useContext } from 'react';
import { PageHeader } from 'components/PageHeader';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { BootstrapedTable, Button, Intent, FeaturedIcon } from 'componentsV2';
import { DrillLogService } from 'services';
import { ToasterContext } from 'pages/App';
import { FieldsListAddModal } from './FieldsListAddModal';

import './FieldsList.scss';

export const FieldsList = () => {
	const [loading, setLoading] = useState(true);
	const [totalRows, setTotalRows] = useState(0);
	const [fields, setFields] = useState([]);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const toaster = useContext(ToasterContext);

	const breadcrumbItems = [
		{ label: 'Drills', url: '/drillsV2' },
		{ label: 'Admin', url: '/drillsV2/admin' },
		{ label: 'Drill log fields' },
	];

	// Callback for create drill log field modal.
	const createDrillField = ({ publicViewable, name, type: { value: type } }) => {
		toaster(
			`Creating drill log field "${name}"...`,
			Intent.WARNING,
			<FeaturedIcon icon="upload" type="Warning" size="md" shape="circleIcon" />,
			5000,
			true,
		);
		DrillLogService.createDrillLogField(name, type, publicViewable)
			.then(() => {
				toaster(
					`You have successfully created "${name}" as a new drill log field.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" type="Success" size="md" shape="circleIcon" />,
					10000,
					true,
				);
				getData();
			})
			.catch(() => {
				toaster(
					`Failed to create "${name}" as a new drill log field. Please try again or contact support.`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" size="md" shape="circleIcon" />,
					20000,
					true,
				);
			});
	};

	// Helper to get all field data.
	const getData = () => {
		setLoading(true);
		DrillLogService.fetchDrillLogFields()
			.then(rows => {
				setFields(rows);
				setTotalRows(rows.length);
			})
			.finally(() => setLoading(false));
	};

	// Load table data initial.
	useEffect(getData, []);

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Name',
			sort: true,
			classes: 'first-column',
			// Column width is worst case scenario on this length unrestricted column.
			// This also ensures that the smaller data columns to the right are smaller.
			headerStyle: () => ({ width: '90%' }),
		},
		{
			key: 'type',
			text: 'Data type',
			dataField: 'type',
			sort: true,
			classes: 'title-case',
		},
		{
			key: 'visible',
			text: 'Publicly visible?',
			dataField: 'publicViewable',
			sort: true,
			formatter: (cell, row) => (row.publicViewable ? 'True' : 'False'),
		},
	];

	return (
		<div className="drill-fields">
			<PageHeader {...{ breadcrumbItems }} title="Drill log fields" />
			{modalIsOpen && (
				<FieldsListAddModal
					modalIsOpen={modalIsOpen}
					setModalIsOpen={setModalIsOpen}
					onSubmitFinished={createDrillField}
				/>
			)}
			{loading && <LoadingSpinner />}
			{!loading && (
				<div>
					<div className="Dashboard-Header">
						<h4 className="title">Fields</h4>
						<Button
							size="md"
							text="New field"
							icon="plus"
							intent="default"
							onClick={() => setModalIsOpen(true)}
						/>
					</div>
					<BootstrapedTable
						keyField="id"
						data={fields}
						columns={columns}
						totalRows={totalRows}
						defaultSorted={[{ dataField: 'name', order: 'asc' }]}
						remote={false}
						sizePerPageList={[10, 25, 50]}
					/>
				</div>
			)}
		</div>
	);
};
