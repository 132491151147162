import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Reupload from './Reupload';
import Upload from './Upload';

const Wrapper = styled.div`
	position: absolute;
	z-index: 2;
	width: calc(100% - 130px);
	min-height: calc(100% - 95px);
	background: #fff;
	top: 95px;
`;

const UploadModals = ({
	isOpen,
	reupload,
	baseFile,
	valueFile,
	closeUploadModal,
	sectionIndex,
	subsectionIndex,
	dispatch,
	templateId,
	uploadFile,
}) => {
	if (!isOpen) return null;

	return (
		<Wrapper>
			{reupload && (
				<Reupload
					baseFile={baseFile}
					closeUploadModal={closeUploadModal}
					valueFile={valueFile}
					dispatch={dispatch}
					sectionIndex={sectionIndex}
					subsectionIndex={subsectionIndex}
					templateId={templateId}
					uploadFile={uploadFile}
				/>
			)}
			{!reupload && (
				<Upload
					closeUploadModal={closeUploadModal}
					valueFile={valueFile}
					dispatch={dispatch}
					sectionIndex={sectionIndex}
					subsectionIndex={subsectionIndex}
					templateId={templateId}
					uploadFile={uploadFile}
				/>
			)}
		</Wrapper>
	);
};

UploadModals.propTypes = {
	isOpen: PropTypes.bool,
	reupload: PropTypes.bool,
	baseFile: PropTypes.object,
	valueFile: PropTypes.object,
	closeUploadModal: PropTypes.func,
	sectionIndex: PropTypes.number,
	subsectionIndex: PropTypes.number,
	dispatch: PropTypes.func,
	templateId: PropTypes.string,
	uploadFile: PropTypes.func,
};

export { UploadModals };
