import React from "react";
import '../css/revisionHistoryTable.scss';
import PropTypes from 'prop-types';

export const RevisionTableTitle = ({
    rowHeader,
    rowName,
    rowKey
}) => {

    return (
        <span className ="titleWrapper" key={rowKey}>
            {
                rowKey == 0 ? 
                    <>
                        <div className ="cellTitleHeader">{rowHeader}</div>
                        <div className ="cellTitle">{rowName}</div>
                    </>: 
                <div className ="cellTitle">{rowName}</div>
            }

        </span>    
    )
}

RevisionTableTitle.propTypes = {
    rowHeader: PropTypes.string,
    rowKey: PropTypes.number,
    rowName: PropTypes.string,
};
