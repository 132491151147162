import React from 'react';
import { PageHeader, CustomTabs } from 'componentsV2';
import { Tab } from '@blueprintjs/core';
import { AlarmTypes } from './';
import { useSelector } from 'react-redux';
import { checkPermission, checkPermissionOnDistrict } from 'utilities/permissions';
import './css/AlarmSettings.scss';
import { Integrations } from './Integrations/Integrations';

export const AlarmSettings = ({ history }) => {
	const breadcrumbItems = [{ label: 'Rapid Alarm', url: '/rapid-alarms' }, { label: 'Settings' }];
	const permissions = useSelector(state => state.route.permissions);
	const isSuperAdmin = permissions.isSysAdmin;
	const hasPermissionOnDistrict = checkPermissionOnDistrict('respond_edit');

	const building = JSON.parse(localStorage.getItem('currentDistrict'));
	const districtId = building.parentId || building.id;
	const isDistrictAdmin = checkPermission('respond_edit', permissions, districtId);

	return (
		<div className="alarm-settings">
			<PageHeader
				title="Alarm settings"
				subtitle="Set up and manage different alarm types."
				breadcrumbItems={breadcrumbItems}
			/>

			<CustomTabs renderActiveTabPanelOnly>
				<Tab
					id={0}
					key={0}
					title="Alarm types"
					panel={
						<AlarmTypes
							history={history}
							editPremission={isDistrictAdmin}
							hasPermissionOnDistrict={hasPermissionOnDistrict}
							isSuperAdmin={isSuperAdmin}
						/>
					}
					fill
					className="CustomTabs__Tab"
				/>
				{(isSuperAdmin || isDistrictAdmin) && (
					<Tab
						id={1}
						key={1}
						title="Integrations"
						panel={<Integrations history={history} />}
						fill
						className="CustomTabs__Tab"
					/>
				)}
			</CustomTabs>
		</div>
	);
};
