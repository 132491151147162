import React,{useEffect, useState,useContext} from "react";
import { Modal,Button ,FeaturedIcon,FileUpload,Intent } from "componentsV2";
import { DocumentService } from "services/DocumentService";
import {ToasterContext} from 'pages/App';
import '../css/UploadNewFileModal.scss'
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

export const UploadNewFileModal = ({
    showUploadModal,
    setShowUploadModal,
    binderDetails,
    reloadBinderDetails
}) => {

    const [loading, setLoading] = useState(false);
    const [selectedFile,setSelectedFile] = useState();
    const [disableSave,setDisableSave] = useState(true);
    const toaster = useContext(ToasterContext);
    const footer = (
        <div className="button-div-styles">
                <Button
                  text={'Cancel'} 
                  type="tertiary"
				  intent="default"
                  onClick = {()=>setShowUploadModal(false)}
                />
                <Button
                  text={'Save'} 
				  intent="default"
                  disabled ={disableSave}
                  onClick = {(e)=>{handleUploadFile(e)}}
                />
        </div>
    );
    const [fileError, setFileError] = useState(null);
    
    useEffect(()=>{
        setDisableSave(true); // this to make sure that save button is disable if upload file and dismiss the modal and open it again
    },[showUploadModal])

    const onFileInputChange = (e) => {
        setSelectedFile(e);
        setFileError(null);
    }
    const handleUploadFile = (e) => {
         e.preventDefault();
         let siteId = (binderDetails.building) ? binderDetails.building.id : null;
         let params = {
            buildingId : siteId,
            attachmentId : binderDetails.id,
            attachmentType : 'binder',
         }

         setLoading(true);
        DocumentService.uploadDocument(params,selectedFile)
        .then(response => {
            if (response.statusCode === 200) {
                toaster('You have successfully uploaded a new  file!', Intent.SUCCESS,<FeaturedIcon icon={'tick'} type={'Success'} size={'md'} shape={'circleIcon'}/>,20000,true)
                setShowUploadModal(false) 
                reloadBinderDetails(binderDetails.id);
            } else if (response.statusCode == 400) {
                if (response.error.fields.file) {
                    setFileError(response.error.fields.file);
                }
            }
        })
        .catch(error => {
            console.error('Error uploading the file:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }
    const handleProgressCompleted = (isProgressCompleted) => {
        if(isProgressCompleted){setDisableSave(false)}
        else{setDisableSave(true)}
    }
    return (
    

      <Modal
        isOpen={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        title={' Upload new file'}
        size='small'
        showCloseButton={true}
        loading={loading}
        customFooter = {footer}
        customClassName = {'replace-modal-container'}
      > 
       <div className = "col-md-12">
       <FileUpload
            disabled = {false}
            maxFileSize={MAX_FILE_UPLOADER_SIZE}
            required = {true}
            allowAllTypes = {true}
            allowedFilesLegend = ''
            label = 'File'
            labelState = {false}
            onFileInputChange = {onFileInputChange}
            setProgressCompleted = {handleProgressCompleted}
            error={fileError ? true : false}
            defaultErrorMessage = {fileError}
        />
       </div>

      </Modal>

    );
}