import React from "react";
import './index.css';
import PropTypes from 'prop-types';

export const NoResultFound = ({header, children, icon}) => {
    return (
        <div className="justify-content-center text-center" >
            <div className='empty-result-card'>
                <img src={icon} />
                <h3 className="empty-result-header mt-3">{header}</h3>
                {children}
            </div>
        </div>
    )
}

NoResultFound.propTypes = {
	icon: PropTypes.string.isRequired,
	header: PropTypes.string.isRequired,    
};
