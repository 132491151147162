import Konva from 'konva';
import { geDarkerLighterColor } from 'utilities/colors';
import { layer, editPermission, addShapeActions, floorplanDetails } from '../CanvasDrawFn';
import { GEOMETRY_TYPE_LINE, GEOMETRY_TYPE_POLYGON } from '../consts';
import { getMousePosition } from '../components/Functions';
var eventListeners = [];
export var areaSelectedColor;

export const calculateLabelCenter = (area, labelBorder) => {
	let centerPosition = [];
	let x_max = null;
	let x_min = null;
	let y_max = null;
	let y_min = null;

	area.attrs.points.forEach((point, key) => {
		if (key % 2 == 0) {
			if (x_max == null || point > x_max) x_max = point;
			if (x_min == null || point < x_min) x_min = point;
		} else {
			if (y_max == null || point > y_max) y_max = point;
			if (y_min == null || point < y_min) y_min = point;
		}
	});

	let x_center = (x_max + x_min) / 2 - labelBorder.width() / 2;
	let y_center = (y_max + y_min) / 2 - labelBorder.height() / 2;

	return (centerPosition = [x_center, y_center]);
};

export const drawAreaLabel = (shape, area) => {
	const labelGroup = new Konva.Group({
		id: `labelGroup-${shape.id}`,
		draggable: true,
		listening: false,
		scalable: false,
	});

	const labelBorder = new Konva.Rect({
		id: `labelBorder-${shape.id}`,
		stroke: '#B3D8DD',
		strokeWidth: 1,
		fill: '#E6F2F4',
		height: 20,
		cornerRadius: 32,
	});

	const labelText = new Konva.Text({
		x: labelBorder.x(),
		y: labelBorder.y() - 2,
		text: shape.title,
		fontSize: 12,
		fontFamily: 'Nunito Sans',
		fontStyle: 600,
		fill: '#007E8F',
		padding: 7,
		textAlign: 'center',
		justifyContent: 'center',
		verticalAlign: 'middle',
	});
	labelBorder.width(labelText.width());

	labelGroup.add(labelBorder);
	labelGroup.add(labelText);

	let labelCenter = calculateLabelCenter(area, labelBorder);
	labelGroup.x(labelCenter[0]);
	labelGroup.y(labelCenter[1]);

	return labelGroup;
};

export const drawAreaGroup = shape => {
	const areaGroup = new Konva.Group({
		id: `areaGroup-${shape.id}`,
		shape: shape,
		draggable: false,
		visible: shape.visible,
		geometryType: GEOMETRY_TYPE_POLYGON,
	});

	return areaGroup;
};

export const drawArea = (setHasChange, shape, showModal) => {
	const floorplanArchived = !!floorplanDetails?.archivedBy;
	// Create a group to add the area, and the label inside
	const areaGroup = drawAreaGroup(shape);

	// Area color
	const color = shape.color && shape.color.length > 0 ? shape.color : 'e4e7e7';

	// Create the area
	const area = new Konva.Line({
		id: `${shape.id}`,
		offsetX: shape.shapeOffsetX ? shape.shapeOffsetX : null,
		offsetY: shape.shapeOffsetY ? shape.shapeOffsetY : null,

		points: JSON.parse(shape.positions),
		fill: `#${color}`,
		closed: true,
		opacity: 0.6,

		stroke: `#${geDarkerLighterColor(color, -20)}`,
		strokeWidth: 3,
		draggable: false,
		title: shape.title,
		shape,
		type: GEOMETRY_TYPE_LINE,
	});
	areaGroup.add(area);

	// Draw label
	if (shape.showLabel) {
		const areaLabel = drawAreaLabel(shape, area);
		areaGroup.add(areaLabel);
	}

	if (editPermission) {
		addShapeActions(area, 'area', setHasChange, showModal);
	}

	// Add the group to the layer
	layer.add(areaGroup);
};

export function buildAnchor(x, y, anchorsRef, polygon) {
	var anchor = new Konva.Circle({
		x: x,
		y: y,
		name: 'anchor',
		radius: 5,
		stroke: '#666',
		fill: '#ddd',
		strokeWidth: 2,
		draggable: true,
		type: 'anchor',
	});
	// layer.add(anchor);

	// add hover styling
	anchor.on('mouseover', function() {
		// document.body.style.cursor = 'pointer';
		this.strokeWidth(4);
	});
	anchor.on('mouseout', function() {
		// document.body.style.cursor = 'default';
		this.strokeWidth(2);
	});

	anchor.on('dragmove', function() {
		editPolygon(anchorsRef, polygon);
	});

	anchor.on('contextmenu', function(event) {
		event.evt.preventDefault();
		let numberOfPoints = polygon.points().length / 2;
		if (numberOfPoints > 3) {
			anchorsRef.forEach((singleanchor, index) => {
				if (singleanchor._id === anchor._id) {
					anchorsRef.splice(index, 1);
				}
			});
			anchor.remove();
			anchor.destroy();
			editPolygon(anchorsRef, polygon);
		}
	});

	return anchor;
}

export function editPolygon(anchorsRef, polygon) {
	var polygonPoints = [];
	var count = 0;

	for (var i = 0; i < anchorsRef.length; i++) {
		var x = anchorsRef[i].attrs.x;
		var y = anchorsRef[i].attrs.y;
		polygonPoints[count] = x;
		polygonPoints[(count += 1)] = y;
		count++;
	}

	polygon.points(polygonPoints);
}

export function shortestDistanceOnPath(x1, y1, x2, y2, x3, y3, map) {
	// const map = mapRef.current;
	const zoom = map.getZoom();
	var threshold = zoom < 1.75 ? 5 : 1; //how frequently to iterate distance check between start and end points
	var check_x, check_y, distance;

	x1 = parseFloat(x1);
	x2 = parseFloat(x2);
	y1 = parseFloat(y1);
	y2 = parseFloat(y2);
	x3 = parseFloat(x3);
	y3 = parseFloat(y3);

	var shortest = 9999; //arbitrary large value
	var slope = (y1 - y2) / (x1 - x2);
	var intercept = y1 - slope * x1;
	var x_diff = x1 > x2 ? x1 - x2 : (x1 - x2) * -1;
	var y_diff = y1 > y2 ? y1 - y2 : (y1 - y2) * -1;

	if (x_diff >= y_diff) {
		//horizontal slant

		if (x1 < x2) {
			for (check_x = x1 + threshold; check_x <= x2 - threshold; check_x += threshold) {
				check_y = pointSlopeX(check_x, slope, intercept);
				distance = pointDistance(x3, y3, check_x, check_y);
				if (distance < shortest) {
					shortest = distance;
				}
			}
		} else {
			for (check_x = x1 - threshold; check_x >= x2 + threshold; check_x -= threshold) {
				check_y = pointSlopeX(check_x, slope, intercept);
				distance = pointDistance(x3, y3, check_x, check_y);
				if (distance < shortest) {
					shortest = distance;
				}
			}
		}
	} else {
		//vertical slant

		if (x_diff < 1) {
			//vertical line

			check_x = x1;
			if (y1 <= y2) {
				for (check_y = y1 + threshold; check_y <= y2 - threshold; check_y += threshold) {
					distance = pointDistance(x3, y3, check_x, check_y);
					if (distance < shortest) {
						shortest = distance;
					}
				}
			} else {
				for (check_y = y1 - threshold; check_y >= y2 + threshold; check_y -= threshold) {
					distance = pointDistance(x3, y3, check_x, check_y);
					if (distance < shortest) {
						shortest = distance;
					}
				}
			}
		} else if (y1 < y2) {
			for (check_y = y1 + threshold; check_y <= y2 - threshold; check_y += threshold) {
				check_x = pointSlopeY(x1, y1, check_y, slope);
				distance = pointDistance(x3, y3, check_x, check_y);
				if (distance < shortest) {
					shortest = distance;
				}
			}
		} else {
			for (check_y = y1 - threshold; check_y >= y2 + threshold; check_y -= threshold) {
				check_x = pointSlopeY(x1, y1, check_y, slope);
				distance = pointDistance(x3, y3, check_x, check_y);
				if (distance < shortest) {
					shortest = distance;
				}
			}
		}
	}

	return shortest;
}

function pointSlopeX(x1, m, c) {
	return m * x1 + c;
}

function pointSlopeY(x1, y1, y2, m) {
	var zx = m * -x1;
	zx = y2 - y1 - zx;
	return zx / m;
}

export const addArea = (
	stage,
	map,
	defultColor,
	baseZoomLevel,
	areaSelectedColor,
	setAreaHasChange,
) => {
	let drawMode = true;
	var polyline = new Konva.Line({
		// points: [mapMouse.x, mapMouse.y],
		name: 'poly',
		fill: areaSelectedColor ? areaSelectedColor : '#' + defultColor,
		stroke: '#880ED4',
		strokeWidth: 5,
		draggable: false,
		closed: true,
		hitStrokeWidth: 10,
		opacity: 0.4,
		rotation: 0,
		scalable: false,
	});
	layer.add(polyline);
	document.getElementById('draw-cursor-container').classList.add('draw-cursor');

	function addNewArea(stage, map, baseZoomLevel, polyline, e) {
		if (drawMode) {
			const mapMouse = getMousePosition(stage, map, baseZoomLevel, floorplanDetails);

			const existPolygon = layer.find(node => node.name() === 'polyTemp')[0];
			if (existPolygon) {
				// check if there is area drawed before to be only one
				areaSelectedColor = existPolygon.fill(
					areaSelectedColor ? areaSelectedColor : '#' + defultColor,
				);
				existPolygon.destroy();
			}
			polyline.points([mapMouse.x, mapMouse.y]);
			polyline.fill();
			/////adding points lat and lng
			var polygonPoints = [];
			var points = polyline.points();
			var mapPpppppppp = [];
			var count = 0;
			for (var i = 0; i < points.length; i += 2) {
				var x = points[i];
				var y = points[i + 1];
				var pointXY = L.point(x, y);
				let mapPoint = map.layerPointToLatLng(pointXY);

				mapPpppppppp[count] = [mapPoint.lat, mapPoint.lng];

				count++;
			}
			polygonPoints = mapPpppppppp;
			polyline.attrs.coordLatLong = polygonPoints;

			// layer.add(polyline);
			layer.draw();
			drawMode = false;
			editArea(stage, polyline, map, baseZoomLevel, setAreaHasChange);
		}

		layer.draw();
		stage.batchDraw();
	}

	let handleAddNewArea = function(e) {
		addNewArea(stage, map, baseZoomLevel, polyline, e);
	};

	stage.on('mousedown', handleAddNewArea);
	let event = 'mousedown';
	eventListeners.push({ event, handleAddNewArea, functionName: 'handleAddNewArea' });
};

export const editArea = (stage, polygon, map, baseZoomLevel, setAreaHasChange) => {
	var anchorsRef = [];
	// var polygon = layer.current.findOne(".poly");
	let newPoints = [];
	if (polygon.attrs?.x && polygon.attrs?.y) {
		//  means positon of the area changed
		newPoints = getAreaNewPosition(polygon.attrs.points, polygon.attrs.x, polygon.attrs.y);
	}

	var points = newPoints.length > 0 ? newPoints : polygon.attrs.points;
	var count = 0;
	polygon.points(newPoints.length > 0 ? newPoints : polygon.attrs.points);
	polygon.x(null);
	polygon.y(null);
	for (var i = 0; i < points.length; i += 2) {
		var x = points[i];
		var y = points[i + 1];
		var anchor;
		anchor = buildAnchor(x, y, anchorsRef, polygon);
		anchorsRef[count] = anchor;
		layer.add(anchor);
		count++;
	}
	layer.draw();

	/////////////////////
	let addNewPointInstance = function(e) {
		addNewPoint(stage, map, baseZoomLevel, anchorsRef, polygon, setAreaHasChange, e);
	};
	stage.on('click', addNewPointInstance);
	let event = 'click';
	eventListeners.push({ event, addNewPointInstance, functionName: 'addNewPointInstance' });
};

export const stopEventListener = (event, stage, eventFunctionName) => {
	eventListeners.map(item => {
		if (item.event === event && item[eventFunctionName]) {
			stage.off(event, item[eventFunctionName]);
		}
	});
	let updatedListeners = eventListeners.filter(item => item.functionName !== eventFunctionName);
	eventListeners = updatedListeners;
};

function addNewPoint(stage, map, baseZoomLevel, anchorsRef, polygon, setAreaHasChange, e) {
	if (e.evt.button === 0) {
		setAreaHasChange(true);
		const mapMouse = getMousePosition(stage, map, baseZoomLevel, floorplanDetails);

		anchor = buildAnchor(mapMouse.x, mapMouse.y, anchorsRef, polygon);
		var point_count = anchorsRef.length;
		var add_to = point_count;

		if (point_count >= 3) {
			//find the nearest path to add the new point to within the selected shape
			var z, z2, distance, shortest, anchor;
			shortest = 9999;

			var points = anchorsRef;
			for (z = 0; z < point_count; z++) {
				z2 = z + 1;
				if (z2 == point_count) {
					z2 = 0;
				}
				distance = shortestDistanceOnPath(
					points[z].attrs.x,
					points[z].attrs.y,
					points[z2].attrs.x,
					points[z2].attrs.y,
					mapMouse.x,
					mapMouse.y,
					map,
				);
				if (distance < shortest) {
					shortest = distance;
					add_to = z2;
				}
			}
		}
		if (point_count >= 3 && add_to < point_count) {
			//add points 'dynamically'

			anchorsRef.splice(add_to, 0, anchor);
		} else {
			//add points to end of shape
			anchorsRef.push(anchor);
		}

		// anchorsRef[anchorsRef.length] =  buildAnchor(mapMouse.x,mapMouse.y)
		layer.add(anchor);
		editPolygon(anchorsRef, polygon);
		// e.evt.stopPropagation();
	}
}

function pointDistance(x1, y1, x2, y2) {
	return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

export const cancleDrawArea = (stage, currentArea) => {
	stopEventListener(stage, 'addNewPointInstance');
	const tempLines = layer.find(node => node.name() === 'temp');
	if (tempLines) {
		tempLines.forEach(line => {
			line.destroy();
		});
	}
	if (currentArea) {
		currentArea.destroy();
		let anchors = layer.find(node => node.name() === 'anchor');
		anchors.map(anchor => {
			anchor.destroy();
		});
	}
	// setCancleArea(false);
};

export const changeAreaColor = (currentArea, areaSelectedColor) => {
	if (currentArea) {
		currentArea.attrs.fill = areaSelectedColor;
		layer.draw();
	} else {
		let tempPoly = layer.find(node => node.name() === 'polyTemp')[0];
		if (tempPoly) {
			tempPoly.attrs.fill = areaSelectedColor;
		} else {
			tempPoly = new Konva.Line({ name: 'polyTemp', fill: areaSelectedColor });
			layer.add(tempPoly);
		}
	}
};

function drawcopyedArea(shape) {
	var line = new Konva.Line({
		points: shape.attrs.points,
		name: 'copyPoly',
		fill: shape.attrs.fill,
		stroke: shape.attrs.stroke,
		strokeWidth: 5,
		draggable: false,
		closed: true,
		hitStrokeWidth: 10,
		opacity: 0.4,
		rotation: 0,
		scalable: false,
		// listening: false,
	});
	layer.add(line);
	layer.draw();
}
export const copyArea = (showModal, shape, drawArea = false) => {
	!drawArea ? showModal(shape, 'CopyArea') : drawcopyedArea(shape);
};

export const getAreaNewPosition = (oldposition, x_Differance, y_Differance) => {
	let newPoints = oldposition.map((item, key) => {
		if (key % 2 === 0 || key === 0) {
			//means it x point position
			return x_Differance + item;
		} else if (key % 2) {
			//means its y point
			return y_Differance + item;
		}
	});
	return newPoints;
};

export const handleUnsavedChanges = () => {
	let selectedColor = legendList.filter(item => item.value === oldSelectedArea.attrs.fill)[0];
	let newPoints = [];
	if (oldSelectedArea.attrs?.x && oldSelectedArea.attrs?.y) {
		//  means positon of the area changed
		newPoints = getAreaNewPosition(
			oldSelectedArea.attrs.points,
			oldSelectedArea.attrs.x,
			oldSelectedArea.attrs.y,
		);
	}
	let points = newPoints.length > 0 ? newPoints : oldSelectedArea.attrs.points;
	const areaData = {
		type: 'area',
		title: oldSelectedArea.attrs.title,
		points: points,
		color_id: selectedColor.id,
		show_label: oldSelectedArea.attrs?.showLabel === true ? 1 : 0,
	};

	oldSelectedArea.attrs.shape.title = oldSelectedArea.attrs.title;
	oldSelectedArea.attrs.shape.positions = JSON.stringify(points);
	oldSelectedArea.attrs.shape.colorId = selectedColor.id;
	oldSelectedArea.attrs.shape.showLabel = oldSelectedArea.attrs?.showLabel === true ? 1 : 0;
	updateArea(oldSelectedArea.attrs.id, areaData, true);
	setShowUnsavedModal(false);
	// setAreaHasChange(false);
	areaToEdit.draggable(true);
	stageCleaning();
};

export const disacardUnsavedCahnges = () => {
	setAreaHasChange(false);

	let oldData = floorplan.shapes.filter(shape => {
		return shape.id === parseInt(oldSelectedArea.attrs.id) && shape.geometryType === 'area';
	})[0];
	let selectedColor = legendList.filter(item => item.id === oldData.colorId)[0];
	oldSelectedArea.attrs.title = oldData.title;
	oldSelectedArea.fill(selectedColor.value);
	oldSelectedArea.stroke(selectedColor.value);
	oldSelectedArea.points(JSON.parse(oldData.positions));
	oldSelectedArea.x(null);
	oldSelectedArea.y(null);
	oldSelectedArea.attrs.showLabel = oldData.showLabel === true ? 1 : 0;
	setShowUnsavedModal(false);
	areaToEdit.draggable(true);
	stageCleaning();
};
