import { FloorplanService, DocumentService } from 'services';
import React, { useState, useRef, useContext, useEffect } from 'react';
import '../css/MapContentsList.scss';
import {
	DOCUMENT_TYPE_OPTIONS,
	Image_TYPE_OPTIONS,
	Video_TYPE_OPTIONS,
	CONTENT_TYPE,
} from './consts';
import {
	DocumentIcon,
	AssesmentIcon,
	MapIcon,
	videoWrapIcon,
	FileIcon,
	LinkIcon,
} from 'componentsV2';
import { Intent } from '@blueprintjs/core';
import { ToasterContext } from 'pages/App';

export const MapContentsListFn = (
	setLoading,
	modalData,
	closeModal,
	docIdSelected,
	setDocIdSelected,
) => {
	const [stepModal, setStepModal] = useState(1);
	const [type, setType] = useState('');
	const [contentList, setContentList] = useState([]);
	const [lastResponse, setLastResponse] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchReselt, setSearchReselt] = useState([]);
	const [disabled, setDisabled] = useState(true);
	const [debounceLoading, setDebounceLoading] = useState(false);
	const [typesValues, setTypesValues] = useState([]);
	const [note, setNote] = useState(false);
	const toaster = useContext(ToasterContext);
	const [options, setOptions] = useState([]);
	const [webAdress, setWebAdress] = useState('');
	const [noDataAdded, setNoDataAdded] = useState(false);
	const [showEmptyStatus, setShowEmptyStatus] = useState(false);
	const [validationMapErrors, setErrors] = useState({});
	const [types, setModalTypes] = useState([]);

	const filterationData = useRef({
		page: 1,
		perPage: 10,
		siteId: modalData.siteId,
		title: '',
		types: '',
		extensions: '',
		isFirstRender: true,
		shapeId: modalData.shape?.id,
	});

	useEffect(() => {
		changeStep(1);
		let types = [
			{
				id: 1,
				title: 'Documents / Binders',
				icon: DocumentIcon,
			},
			{
				id: 2,
				title: 'Images',
				icon: MapIcon,
			},
			{
				id: 3,
				title: '360 images',
				icon: MapIcon,
			},
			{
				id: 4,
				title: 'Videos',
				icon: videoWrapIcon,
			},
			{
				id: 5,
				title: 'Note',
				icon: AssesmentIcon,
			},
		];

		if (
			modalData.shape?.geometryType == 'icon' &&
			modalData.shape?.icon.type == 'Camera' &&
			modalData.iconLinks.length == 0
		) {
			types.push({
				id: 6,
				title: 'Add web link',
				icon: LinkIcon,
			});
		}
		setModalTypes(types);
	}, [modalData]);

	const selectDocId = tab => {
		const tabId = tab.id;

		let selectedIds = [];

		if (docIdSelected.find(item => item.id == tabId)) {
			// remove item with un select
			selectedIds = docIdSelected.filter(item => item.id != tabId);
		} else {
			// add item with select
			selectedIds = [...docIdSelected, { id: tab.id, type: tab.type }];
		}

		setDocIdSelected(selectedIds);
		
		if (selectedIds.length > 0) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};

	const changeStep = step => {
		resetStates();
		setStepModal(step);
	};

	const onWebAddressChangeHandler = e => {
		let text = e.target.value;

		if (text.trim() != '') {
			setDisabled(false);
			setWebAdress(text);
		} else {
			setDisabled(true);
		}
	};

	const handleSearch = name => {
		filterationData.current.title = name;
		filterationData.current.page = 1;
		setSearchValue(name);
		setShowEmptyStatus(false);
		setContentList([]);
		getContent();
	};
	const debouncedScrollHandler = _.debounce(async () => {
		let modal = document.getElementsByClassName('modal-content-wrapper');
		if (modal.length > 0) {
			modal = modal[0];
			if (window.innerHeight + modal.scrollTop >= modal.scrollHeight && stepModal === 2) {
				filterationData.current.page += 1;
				filterationData.current.siteId = modalData.siteId;
				if (
					filterationData.current.page == 1 ||
					lastResponse._metadata.currentPage <= lastResponse._metadata.numPages
				) {
					getContent();
				}
			}
		}
	}, 900);

	const handleScroll = () => {
		let modal = document.getElementsByClassName('modal-content-wrapper');
		if (modal.length > 0) {
			modal = modal[0];
			modal.addEventListener('scroll', debouncedScrollHandler());
		}
	};

	const fileIconLabel = tab => {
		let icon = '';

		if (tab.type === 'Video') {
			icon = 'video';
		} else if (tab.type === 'Images' || tab.type === '360image') {
			icon = 'image';
		} else if (tab.type === 'binder') {
			icon = 'pdf';
		} else {
			let docType = tab.extension;
			switch (docType) {
				case 'pdf':
					icon = 'pdf';
					break;
				case 'docx':
				case 'doc':
				case 'docs':
					icon = 'docx';
					break;
				case 'xls':
					icon = 'xls';
					break;
				case 'xlsx':
					icon = 'xls';
					break;
				case 'pptx':
					icon = 'pptx';
					break;
				case 'csv':
					icon = 'csv';
					break;
				case 'txt':
					icon = 'txt';
					break;
				default:
					icon = 'document';
					break;
			}
		}

		return (
			<div className="checkbox-icon">
				<div>
					<span className="docTitle">{tab.title ? tab.title : ''}</span>
					<p className="docSubTitle">
						{tab.justAdded && docIdSelected.find(item => item.id == tab.id) ? (
							`Just added`
						) : (
							<>
								Added by{' '}
								{tab.ownerName
									?.replace(/,/g, ' ')
									.split(' ')
									.reverse()
									.join(' ')}
								, {tab.formattedDateCreated}
							</>
						)}
					</p>
				</div>
				<FileIcon icon={icon} />
			</div>
		);
	};

	const onChangeTypesHandler = types => {
		setShowEmptyStatus(false);
		setTypesValues(types);
		filterationData.current.page = 1;
		filterationData.current.extensions = types;
		getContent();
	};

	const openNextModal = (title, uploadedId = null, preSelectedIds = []) => {
		let paramType = title;
		let type = title.toLowerCase();

		if (title == 'Videos') {
			paramType = 'Video';
		} else if (title == '360 images') {
			paramType = '360image';
		} else if (title == 'Documents / Binders') {
			paramType = ['Documents', 'binder'];
			type = 'documents';
		}

		filterationData.current = {
			page: 1,
			perPage: 10,
			siteId: modalData.siteId,
			title: '',
			types: paramType,
			isFirstRender: true,
			shapeId: modalData.shape?.id,
			floorplanId: modalData.floorplanId,
			uploadedId: uploadedId,
			sortKey: 'createDate',
			sortDir: 'desc',
			includeBinders: 1,
		};
		setType(type);
		setStepModal(2);
		resetStates();
		if (type != CONTENT_TYPE.NOTE && type != CONTENT_TYPE.ADD_WEB_LINK) {
			getContent(preSelectedIds);
		}

		setFilterTypeOptions(type);
	};

	const resetStates = () => {
		setTypesValues([]);
		setContentList([]);
		setDocIdSelected([]);
		setSearchReselt([]);
		setSearchValue('');
		setDisabled(true);
		setErrors([]);
		setShowEmptyStatus(false);
	};

	const setFilterTypeOptions = type => {
		switch (type) {
			case CONTENT_TYPE.DOCUMENTS:
				setOptions(DOCUMENT_TYPE_OPTIONS);
				break;
			case CONTENT_TYPE.IMAGES:
			case CONTENT_TYPE.IMAGE360:
				setOptions(Image_TYPE_OPTIONS);
				break;
			case CONTENT_TYPE.VIDEOS:
				setOptions(Video_TYPE_OPTIONS);
				break;
		}
	};

	const getContent = (preSelectedIds = []) => {
		setDebounceLoading(true);
		DocumentService.getDocumentsBySiteId(filterationData.current)
			.then(response => {
				if (response.statusCode === 200) {
					var isEmpty = response.data.length === 0;
					setNoDataAdded(
						contentList.length == 0 && filterationData.current.isFirstRender && isEmpty,
					);
					setShowEmptyStatus(response._metadata.totalRecords == 0);
					filterationData.current.isFirstRender = false;

					setLastResponse(response);
					if (response._metadata.currentPage === 1) {
						if (filterationData.current.title) {
							setSearchReselt(response.data);
						} else {
							if (filterationData.current.uploadedId) {
								preSelectedIds.push({
									id : filterationData.current.uploadedId,
									type: type,
								});
								setDisabled(false);
								setDocIdSelected(preSelectedIds);
								response.data.forEach(item => {
									if (item.id === filterationData.current.uploadedId) {
										item.justAdded = true;
									}
								});
							}
							setContentList(response.data);
						}
						filterationData.current.page = response._metadata.currentPage;
					} else if (response._metadata.currentPage === 1 && response.data.length === 0) {
						if (filterationData.current.title) {
							setSearchReselt([]);
						} else {
							setContentList([]);
						}
					} else {
						if (filterationData.current.title) {
							setSearchReselt(oldContent => [...oldContent, ...response.data]);
						} else {
							setContentList(oldContent => [...oldContent, ...response.data]);
						}
						filterationData.current.page = response._metadata.currentPage;
					}
				}
			})
			.catch(error => {})
			.finally(() => {
				setLoading(false);
				setDebounceLoading(false);
			});
	};

	const onNoteChangeHandler = e => {
		let text = e.target.value;

		if (text.trim() != '') {
			setDisabled(false);
			setNote(text);
		} else {
			setDisabled(true);
		}
	};

	const saveBtnHandler = () => {
		setLoading(true);

		if (type == CONTENT_TYPE.NOTE) {
			FloorplanService.AddShapeNote({ note: note, shape_id: modalData.shape.id })
				.then(res => {
					if (res.statusCode == 201) {
						toaster(
							'You have successfully added a new note.',
							Intent.SUCCESS,
							null,
							2000,
							false,
							'map-view-page',
						);
						closeModal();
						modalData.loadContentData();
					}
				})
				.catch(error => {
					toaster(
						'Something went wrong, please try again.',
						Intent.DANGER,
						null,
						2000,
						false,
						'map-view-page',
					);
				})
				.finally(() => {
					setLoading(false);
				});
		} else if (type == 'add web link') {
			let contentBody = {
				web_address: webAdress,
			};
			FloorplanService.AttachWeblinkToArea(
				modalData.floorplanId,
				modalData.shape.id,
				contentBody,
			)
				.then(res => {
					if (res.statusCode == 201) {
						setErrors([]);
						closeModal();
						modalData.loadContentData();
						toaster(
							'You have successfully edited "' + modalData.shape.title + '".',
							Intent.SUCCESS,
							null,
							2000,
							false,
							'map-view-page',
						);
					}
				})
				.catch(res => {
					let errors = {};
					if (res.error?.description && res.error.description == 'validations errors.') {
						if (res.error.hasOwnProperty('fields')) {
							if ('web_address' in res.error.fields) {
								errors.web_address = 'Web address must be a URL';
							}
						}
					}
					setErrors(errors);
					setDisabled(true);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			let contentBody = [];
			docIdSelected.forEach(item => {
				contentBody.push({
					id: item.id,
					type: item.type == 'binder' ? item.type : type?.replace(/\s/g, '').slice(0, -1),
				});
			});
			FloorplanService.AttachContentToArea(modalData.siteId, modalData.shape.id, contentBody)
				.then(res => {
					if (res.statusCode == 201) {
						let toastershape =
							modalData.shape?.geometryType == 'icon' ? 'icon' : 'area';
						toaster(
							'You have successfully attached contents to this ' + toastershape,
							Intent.SUCCESS,
							null,
							2000,
							false,
							'map-view-page',
						);
						modalData.loadContentData();
					}
				})
				.catch(error => {
					toaster(
						'Something went wrong, please try again.',
						Intent.DANGER,
						null,
						2000,
						false,
						'map-view-page',
					);
				})
				.finally(() => {
					setLoading(false);
					closeModal();
				});
		}
	};

	return {
		openNextModal,
		stepModal,
		types,
		fileIconLabel,
		type,
		options,
		searchValue,
		searchReselt,
		contentList,
		docIdSelected,
		selectDocId,
		handleSearch,
		handleScroll,
		disabled,
		changeStep,
		debounceLoading,
		onChangeTypesHandler,
		typesValues,
		onNoteChangeHandler,
		saveBtnHandler,
		onWebAddressChangeHandler,
		showEmptyStatus,
		noDataAdded,
		filterationData,
		validationMapErrors,
	};
};
