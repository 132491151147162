import React, { useEffect, useState, useContext } from 'react';
import {
	CardContainer,
	Input,
	Button,
	LoadingSpinner,
	ConfirmationModal,
	ToasterContext,
	Intent,
	FeaturedIcon,
} from 'componentsV2';
import './Alarms.scss';
import { RapidAlarmIntegrationsService } from 'services';
import { Icon } from '@blueprintjs/core';
import { formatDate } from 'utilities/dates';

export const Alarms = ({ partnerId }) => {
	const [alarmData, setAlarmData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [originalDataLength, setOriginalDataLength] = useState(0);
	const [openModal, setOpenModal] = useState('');
	const [alarmType, setAlarmType] = useState({});
	const [alarmTypeIndex, setAlarmTypeIndex] = useState(null);
	const toaster = useContext(ToasterContext);

	const handleCloseModal = () => {
		setOpenModal('');
		setAlarmType({});
	};

	const handleOpenModal = (showModal, alarmType, index) => {
		setOpenModal(showModal);
		if (alarmType) {
			setAlarmType(alarmType);
			setAlarmTypeIndex(index);
		}
	};

	useEffect(() => {
		setLoading(true);
		getAlarmData();
	}, []);

	const getAlarmData = () => {
		RapidAlarmIntegrationsService.getAlarmTypes().then(res => {
			setLoading(false);
			setOriginalDataLength(res?.data?.length);
			setAlarmData(res?.data.map(alarm => ({ ...alarm, nameUpdated: false })));
		});
	};

	const onHandleInputChange = (value, index) => {
		const newAlarmTypeInput = [...alarmData];
		newAlarmTypeInput[index].name = value;
		newAlarmTypeInput[index].nameUpdated = true;
		setAlarmData(newAlarmTypeInput);
	};

	const onAddAlaramType = () => {
		const newAlarmType = alarmData ? [...alarmData] : [];
		newAlarmType.push({
			name: '',
			nameUpdated: true,
		});
		setAlarmData(newAlarmType);
	};

	const onClickSaveAlarmData = () => {
		const updatedAlarms = alarmData.filter(({ nameUpdated }) => nameUpdated);
		const newAlarmData = updatedAlarms.map(alarm => ({
			name: alarm.name,
			id: alarm.id,
		}));

		if (newAlarmData.length) {
			RapidAlarmIntegrationsService.saveAlarmTypes(partnerId, newAlarmData)
				.then(() => {
					getAlarmData();
					toaster(
						`You have successfully updated 911Cellular alarms type.`,
						Intent.SUCCESS,
						<FeaturedIcon icon="tick" type="Success" size="md" shape="circleIcon" />,
					);
				})
				.catch(err => {
					toaster(
						`There was an error saving the alarm type. Please try again.`,
						Intent.DANGER,
					);
					console.log('err', err);
				});
		}
	};

	const onHandleDeleteAlarmType = () => {
		if (alarmTypeIndex >= originalDataLength) {
			let removeAlarmType = [...alarmData];
			removeAlarmType.splice(alarmTypeIndex, 1);
			setAlarmData(removeAlarmType);
			handleCloseModal();
		} else {
			RapidAlarmIntegrationsService.deleteAlarmType(alarmType.id)
				.then(res => {
					toaster(
						`You have successfully deleted ${alarmType.name}.`,
						Intent.NONE,
						<FeaturedIcon icon="trash" size="md" type="Gray" />,
					);
					getAlarmData();
				})
				.catch(err => {
					toaster(
						`There was an error deleting the alarm type. Please try again.`,
						Intent.DANGER,
					);
					console.log('err', err);
				});
			handleCloseModal();
		}
	};
	return (
		<div className="Alarms_Main_Container">
			{loading && <LoadingSpinner />}
			{!loading && (
				<CardContainer>
					<div className="alarms-title-container">
						<span className="manage-alarms-title">Manage alarms</span>
						<span className="manage-alarms-title-text">
							In the 911Cellular system, you need to manage your alarm types as
							follows:
						</span>
						<span className="manage-alarms-instructions-text">
							For every Incident Type you create in the 911Cellular portal, you must
							also create a corresponding Alarm Type in EMS. Ensure the names match
							exactly, both in wording and in case sensitivity.
						</span>
					</div>
					<div>
						<div className="alarm-type-title">
							<span className="alarn-type-title">911Cellular alarm type</span>
							<span className="alarn-type-title">Last updated</span>
							<span />
							{alarmData &&
								alarmData.map((alarm, index) => (
									<>
										<Input
											placeholder="Enter Alarm Type"
											isRequired
											value={alarm.name}
											name="alarm"
											onChange={e =>
												onHandleInputChange(e.target.value, index)
											}
										/>
										<div>
											<span className="last-updated-date-container">
												{alarm?.modifiedAt?.date
													? formatDate(
															alarm?.modifiedAt?.date,
															'MM/dd/yyyy',
													  )
													: ''}
											</span>
										</div>
										<div>
											<Icon
												icon="trash"
												size="18"
												onClick={() => {
													handleOpenModal(
														'delete_alarm_type',
														alarm,
														index,
													);
												}}
											/>
										</div>
									</>
								))}
						</div>
						<div className="add-button-container">
							<Button
								size="md"
								text="Add alarm type"
								icon="add"
								type="ghostPrimary"
								intent="default"
								onClick={() => onAddAlaramType()}
							/>
						</div>
						<div>
							<Button
								size="md"
								text="Save"
								type="primaryDefault"
								intent="default"
								onClick={onClickSaveAlarmData}
							/>
						</div>
					</div>
				</CardContainer>
			)}
			{openModal === 'delete_alarm_type' && (
				<ConfirmationModal
					isOpen
					title={`Are you sure you want to delete ${alarmType.name}?`}
					subtitle="Once deleted, this cannot be recovered."
					icon={{ icon: 'trash', iconColor: 'error' }}
					cancelBtn={{
						title: 'Cancel',
						onClick: handleCloseModal,
					}}
					confirmBtn={{
						title: 'Delete it',
						onClick: () => onHandleDeleteAlarmType(),
						type: 'primaryDanger',
					}}
					onClose={handleCloseModal}
				/>
			)}
		</div>
	);
};
