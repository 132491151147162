import React, { useCallback, useEffect, useState } from 'react';
import { EmptyState, Animated, noResult } from 'componentsV2';
import './css/Layers.scss';
import { MapOptionCard } from '../';
import { MAP_LAYERS, DELETE_MAP_ACTION, UNSAVED_CHANGES_MODAL } from '../../consts';
import { LayerCard } from './LayerCard';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDragItems } from 'hooks/useDragItems';
import PropTypes from 'prop-types';
import { FloorplanService } from 'services/FloorplanService';
import { useContext } from 'react';
import { ToasterContext } from 'componentsV2';
import { Intent } from '@blueprintjs/core';
import { ContentSearch } from '../SidebarComponents';
import { MapsContext } from '../../../Providers';

export const Layers = ({
	siteId,
	toggleMapOption,
	editPermission,
	isEditMode,
	currentFloorplan,
	changeFloorplan,
	mapListUpdated,
	history,
	sourceAction,
	setHasChange,
	hasChange,
}) => {
	const [layersList, setLayersList] = useState([]);
	const [loading, setLoading] = useState(false);
	const toaster = useContext(ToasterContext);
	const MapCTX = useContext(MapsContext);

	useEffect(() => {
		getMapLayers();
	}, [mapListUpdated]);

	const getMapLayers = useCallback(() => {
		setLoading(true);
		const params = {
			includeArchived: 1,
			sortKey: 'order',
			sortDir: 'ASC',
		};
		FloorplanService.getSiteMaps(siteId, params)
			.then(res => {
				if (res.statusCode === 200) {
					setLayersList(res.data);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		if (sourceAction === DELETE_MAP_ACTION && layersList.length > 0) {
			changeFloorplan(layersList[0]);
		}
	}, [sourceAction, layersList]);

	const onSearchHandler = search => {
		setLayersList(
			layersList.filter(floorplan =>
				floorplan.title?.toLowerCase().includes(search.toLowerCase()),
			),
		);
	};

	const onDragEnd = result => {
		const sortedItems = useDragItems(result, layersList);
		setLayersList(sortedItems);

		//call api to reorder floorplans
		const apiBody = {
			mapsIds: sortedItems.map(newItem => newItem.id),
		};
		FloorplanService.reOrder(siteId, apiBody)
			.then(response => {
				if (response.statusCode === 200) {
					toaster(
						'Map successfully reordered',
						Intent.SUCCESS,
						null,
						2000,
						false,
						'map-view-page',
					);
				}
			})
			.catch(err => {
				toaster('Something went wrong!', Intent.DANGER, null, 2000, false, 'map-view-page');
			});
	};

	const floorplanArchived = !!currentFloorplan?.archivedBy;

	return (
		<MapOptionCard
			isOpen={true}
			title="Layers"
			subTitle="Maps set as visible to all are listed below. You will be able to edit or view each map depending on your permissions."
			loading={loading}
			onClick={() => toggleMapOption(MAP_LAYERS)}
			headerItem={
				<ContentSearch
					onSearch={onSearchHandler}
					onAddClickHandler={() => {
						MapCTX.showCreateMapModal(false, null, true);
					}}
					showAddBtn={editPermission && !floorplanArchived && isEditMode}
					addBtnTitle="Add new image"
					editPermission={editPermission}
				/>
			}
		>
			<div className="map-layers-container">
				{layersList.length === 0 && (
					<Animated>
						<div className="mt-3">
							<EmptyState
								header="No layers found"
								description={
									<>
										Your search didn't match any layers. <br />
										Please try again.
									</>
								}
								icon={noResult}
							/>
						</div>
					</Animated>
				)}
				{layersList.length > 0 && (
					<Animated>
						<div className="map-layers-container-maps-label">
							Select a layer to edit
						</div>
						<div id="droppable">
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable">
									{provided => (
										<div
											ref={provided.innerRef}
											className="map-layers-container-maps-content"
										>
											{layersList.map((floorplan, index) => (
												<Draggable
													key={floorplan.id}
													draggableId={floorplan.id?.toString()}
													index={index}
													isDragDisabled={
														!editPermission ||
														floorplanArchived ||
														!isEditMode
													}
												>
													{(p, s) => (
														<div ref={p.innerRef} {...p.draggableProps}>
															<LayerCard
																dragProps={p.dragHandleProps}
																floorplan={floorplan}
																currentFloorplan={currentFloorplan}
																changeFloorplan={changeFloorplan}
																editPermission={editPermission}
																isEditMode={isEditMode}
																setHasChange={setHasChange}
																hasChange={hasChange}
															/>
														</div>
													)}
												</Draggable>
											))}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
					</Animated>
				)}
			</div>
		</MapOptionCard>
	);
};

Layers.PropTypes = {
	siteId: PropTypes.number.isRequired,
	toggleMapOption: PropTypes.func,
	editPermission: PropTypes.bool,
	currentFloorplan: PropTypes.object,
	changeFloorplan: PropTypes.func,
	mapListUpdated: PropTypes.bool,
	sourceAction: PropTypes.string,
};
