import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.table`
	border-radius: 6px;
	table-layout: fixed;
	word-wrap: break-word;
	background-color: white;
	border: 1px solid #ced9e0;

	thead,
	tfoot {
		color: #738694;
		font-size: 14px;
		line-height: 20px;
		font-weight: bold;
		font-style: normal;
		font-family: Nunito Sans;

		th {
			border-bottom: 1px solid #ced9e0;
		}
	}

	tbody tr {
		color: #10161a;
		font-size: 14px;
		transition: 0.2s;
		line-height: 20px;
		font-style: normal;
		font-family: Nunito Sans;
		border-bottom: 1px solid #ced9e0;

		td,
		th {
			border-top: none;
			font-weight: normal !important;

			input {
				border-radius: 3px;
				background: #ffffff;
				border: 1px solid rgba(16, 22, 26, 0.2);
			}
		}

		td {
			vertical-align: middle;
		}
	}

	tfoot {
		height: 51px;
		position: relative;

		.table-pagination {
			right: 1px;
			margin-top: 1px;
			position: absolute;

			button {
				margin: 0 1px;
				border: none;
				color: #738694;
				min-width: 30px;
				font-size: 14px;
				transition: 0.2s;
				min-height: 48px;
				line-height: 20px;
				font-weight: bold;
				font-style: normal;
				border-radius: 1px;
				display: inline-block;
				font-family: Nunito Sans;
				background-color: transparent;

				:hover {
					color: #007bff;
					background-color: #e9ecef;
				}
			}

			.active {
				color: #fff;
				border-color: #007bff;
				background-color: #007bff;
			}

			.disabled {
				color: #6c757d;
				pointer-events: none;
				cursor: auto;
				background-color: #fff;
				border-color: #dee2e6;
			}
		}
	}
`;

const EmptyRow = styled.tr`
	padding: 8px;
	text-align: center;
	align-items: center;
	justify-content: center;
`;

const Table = ({
	id,
	headers = null,
	headersStyles = [],
	data = null,
	renderItem = null,
	numberOfItemsToDisplay = 10,
	body = null,
	hidePagination = false,
}) => {
	// TODO: convert other table instances to use 'renderItem'
	// if (!headers || !renderItem) {
	// 	throw new Error(
	// 		"'headers' or 'renderItem' are undefined. Please define these parameters",
	// 	);
	// }

	const [selectedPage, setSelectedPage] = useState(null);
	const [pagination, setPagination] = useState(null);

	const viewPrevPage = () => {
		if (selectedPage > pagination[0]) {
			setSelectedPage(selectedPage - 1);
		}
	};

	const viewNextPage = () => {
		if (selectedPage < pagination[pagination.length - 1]) {
			setSelectedPage(selectedPage + 1);
		}
	};

	useEffect(() => {
		const dataSize = data?.length || 1;
		const newPagination = Array(
			Math.ceil(dataSize / numberOfItemsToDisplay),
		)
			.fill()
			.map((item, index) => index + 1);

		setPagination(newPagination);

		if (pagination === null || newPagination.length < pagination.length) {
			setSelectedPage(1);
		}
	}, [data]);

	const startIndex =
		numberOfItemsToDisplay * selectedPage - numberOfItemsToDisplay;
	const endIndex = numberOfItemsToDisplay * selectedPage;

	if (!selectedPage && !pagination) return null;

	return (
		<Wrapper className="table" id={id}>
			<thead>
				<tr>
					{headers?.map((item, index) => (
						<th key={item} scope="col" style={headersStyles[index]}>
							{item}
						</th>
					))}
				</tr>
			</thead>

			<tbody>
				{body && body()}
				{!body && !data?.length && <EmptyTable />}
				{Array.isArray(data) &&
					data?.map((item, index) => {
						if (
							hidePagination === true ||
							(startIndex <= index && index < endIndex)
						) {
							return (
								<tr key={item.id || item.uuid}>
									{renderItem(item, index)}
								</tr>
							);
						}
						return null;
					})}
			</tbody>

			{!hidePagination && (
				<tfoot>
					<div className="table-pagination">
						<button type="button" onClick={viewPrevPage}>
							Previous
						</button>

						{pagination.map(item => {
							const isSelected = item === selectedPage;
							return (
								<button
									className={`paginated-btn ${
										isSelected ? 'active' : ''
									}`}
									type="button"
									onClick={() => {
										if (!isSelected) {
											setSelectedPage(item);
										}
									}}
								>
									{item}
								</button>
							);
						})}

						<button type="button" onClick={viewNextPage}>
							Next
						</button>
					</div>
				</tfoot>
			)}
		</Wrapper>
	);
};

const EmptyTable = ({ children = 'No results available' }) => (
	<EmptyRow>
		<td>{children}</td>
	</EmptyRow>
);

Table.propTypes = {
	id: PropTypes.string,
	headers: PropTypes.array.isRequired,
	headersStyles: PropTypes.array,
	data: PropTypes.oneOfType([PropTypes.array]),
	renderItem: PropTypes.func.isRequired,
	numberOfItemsToDisplay: PropTypes.number,
	body: PropTypes.func,
	hidePagination: PropTypes.bool,
};

EmptyTable.propTypes = {
	children: PropTypes.any,
};

export { Table, EmptyTable };
