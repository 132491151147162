import React, { useState } from 'react';
import { useActiveAlarms } from 'hooks';
import { ActiveAlarmsTable } from './';
import { ACTIVE_ALARMS_TYPE } from '../../../consts';
import { Filteration, EmptyStatuses } from '../';

export const ActiveAlarms = ({ filterationInitialState, history }) => {
	const [selectedSites, setSelectedSites] = useState([]);
	const {
		showEmptyStatus,
		noDataAdded,
		filterationData,
		setFilterationData,
		loading,
		activeAlarmList,
	} = useActiveAlarms(filterationInitialState, selectedSites);

	return (
		<>
			<Filteration
				filterationData={filterationData}
				setFilterationData={setFilterationData}
				disabled={noDataAdded}
				setSelectedSites={setSelectedSites}
			/>
			{!showEmptyStatus && (
				<ActiveAlarmsTable loading={loading} list={activeAlarmList} history={history} />
			)}
			{showEmptyStatus && (
				<EmptyStatuses alarmType={ACTIVE_ALARMS_TYPE} noDataAdded={noDataAdded} />
			)}
		</>
	);
};
