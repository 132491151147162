import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from "componentsV2";
import './css/BackToTopButton.scss';

export const BackToTopButton = ({ 
    containerId,        // If the button is inside of an element, you have to specify the container element ID
    topElementId,       // The element ID to point at the top of the page
    elementOnRightId,   // If we have a flex element on the right, and we need to put the button next to this element
    text = 'Top',       // "Top" is the default text
    right = 30,         // 30px by default as a margin right
    show = true,        // Show / hide the component
    onClick = () => {}  // Function aditional to add 
}) => {
    const bottomDefault  = 15;   // 15px by default as a margin bottom
    const heightToHidden = 250; // The button is hidden if we are at 250px of the top of the page

    const [isVisible, setIsVisible] = useState(false);
    const [marginBottom, setMarginBottom] = useState(bottomDefault);
    const [marginRight, setMarginRight] = useState(right);
    const [tempPosition, setTempPosition] = useState({bottom: 0, right: 0});
    const container = document.getElementById(containerId);
    const currentSiteWrapper = document.getElementById('current-site-wrapper');
    const elementOnRight = document.getElementById(elementOnRightId);

    const goToTop = () => {
        let topElement = document.getElementById(topElementId);
        if (topElement) {
            topElement.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    }

    const getMarginBottom = () => {
        let margin = bottomDefault;
        if (currentSiteWrapper) {
            let currentSiteRect = currentSiteWrapper.getBoundingClientRect();
            if (currentSiteRect.top < (window.innerHeight || document.documentElement.clientHeight) 
                && currentSiteRect.bottom > 0) {
                    margin = currentSiteRect.height + margin;
            }
        }
        return margin;
    }

    const getMarginRight = () => {
        let elementOnRight = document.getElementById(elementOnRightId);
        let margin = right;
        if (elementOnRight) {
            let elementRect = elementOnRight.getBoundingClientRect();
            margin = elementRect.width + margin;
        }
        return margin;
    }

    const handleWindowScroll = () => {
        setMarginBottom(getMarginBottom());
        setMarginRight(getMarginRight());
    }

    useEffect(() => {
        if (container) {
            container.onscroll = () => {
                setMarginBottom(getMarginBottom());
                setMarginRight(getMarginRight());
                setIsVisible( container.scrollTop > heightToHidden )
            };
        }
    }, [container]);

    useEffect(() => {
        if (elementOnRight) {
            let margin = marginRight;
            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    const borderBoxSize = entry.borderBoxSize[0];
                    margin = borderBoxSize.inlineSize + right;
                }
                setMarginRight(margin);
            });
            resizeObserver.observe(elementOnRight);
        }
    }, [elementOnRight]);

    useEffect(() => {
        if (show) {
            setMarginBottom(tempPosition.bottom);
            setMarginRight(tempPosition.right);
        } else {
            setTempPosition({
                bottom: marginBottom,
                right: marginRight
            });
        }
    }, [show]);

    useEffect(() => {
        window.addEventListener('scroll', handleWindowScroll);
        return () => {
          window.removeEventListener('scroll', handleWindowScroll)
        }
    }, []);

    return (
        <>
        { show && isVisible && (
            <div className='back-to-top-button-wrapper'>
                <Button 
                    intent="default"
                    size="md"
                    icon="arrow-up"
                    onClick={() => { goToTop(); onClick() }}
                    text={text} 
                />
            </div>
        )}
        </>
    )
}

BackToTopButton.propTypes = {
    containerId: PropTypes.string.isRequired,
    topElementId: PropTypes.string.isRequired,
    elementOnRightId: PropTypes.string,
    text: PropTypes.string,
	right: PropTypes.number,
    show: PropTypes.bool,
    onClick: PropTypes.func,
};