import React, { createContext, useEffect, useState } from 'react';
import { PartnersService, RapidAlarmService } from 'services';

export const CreateAlarmTypeContext = createContext();

export const CreateAlarmProvider = ({
	history,
	children,
	setLoading = () => {},
	loading = false,
	hasPermissionOnDistrict,
}) => {
	const [formData, setFormData] = useState({
		id: null,
		name: null,
		icon: null,
		soundEnabled: false,
		sound: null,
		countdown: null,
		contact911: null,
		alarmTypes911: null,
		_911Active: false,
		flipchartValue: null,
		priority: null,
		generic: null,
		hasRollCall: null,
		markAllAccounted: null,
		anotherAlarmIsGeneric: false,
		disabled: false,
		hasActiveAlarm: false,
		preset: false,
		isGeneric: false,
	});
	const [formErrors, setFormErrors] = useState({});
	const [modalToShow, setModalToShow] = useState(null);
	const [locationPath, setLocationPath] = useState('');
	const [shouldPromptSaveModal, setShouldPromptSaveModal] = useState(false);
	const [formHasChanged, setFormHasChanged] = useState(false);

	useEffect(() => {
		// checking if the 911 cellular integration is active or not
		check911CellularIntegration();

		//checking if there is generic alarm type exists or not
		checkGenericAlarmIsExists();
	}, []);

	const check911CellularIntegration = () => {
		if (hasPermissionOnDistrict) {
			PartnersService.getAllPartners({ key: '911_cellular' }).then(resp => {
				if (resp.statusCode === 200) {
					let isActive = resp.data.length > 0 ? resp.data[0]['isCurrent'] : false;
					onChangeFormData({ type: '_911Active', payload: isActive });
					setLoading(false);
				}
			});
		} else {
			onChangeFormData({ type: '_911Active', payload: true });
			setLoading(false);
		}
	};
	const checkGenericAlarmIsExists = () => {
		const params = {
			page: 1,
			perPage: 1,
			disabled: true,
			isGeneric: true,
		};
		RapidAlarmService.getAlarmTypes(params).then(res => {
			onChangeFormData({
				type: 'anotherAlarmIsGeneric',
				payload: res._metadata.totalRecords > 0,
			});
		});
	};

	useEffect(() => {
		if (formData.name !== null && formData.icon !== null) {
			setShouldPromptSaveModal(true);
		} else {
			setShouldPromptSaveModal(false);
		}
	}, [formData]);

	const onChangeFormData = action => {
		setFormData(oldValues => ({
			...oldValues,
			[action.type]: action.payload,
		}));
		updateFormErrors(action.type, action.payload);
		if (!loading) {
			updateFormChange(true);
		}
	};

	const updateFormChange = value => {
		setFormHasChanged(value);
	};

	const updateFormErrors = (fieldName, value) => {
		switch (fieldName) {
			case 'name':
				if (formErrors['name'] && value && value.trim('').length !== 0) {
					setFormErrors(oldErrors => ({ ...oldErrors, name: null }));
				}
				break;
			case 'soundEnabled':
				if (formErrors['sound'] && value == false) {
					setFormErrors(oldErrors => ({ ...oldErrors, sound: null }));
				}
				break;
			case 'contact911':
				if (value == 'false') {
					setFormErrors(oldErrors => ({
						...oldErrors,
						alarmTypes911: null,
						contact911: null,
					}));
				} else {
					if (formErrors[fieldName] && value !== null) {
						setFormErrors(oldErrors => ({ ...oldErrors, [fieldName]: null }));
					}
				}
				break;
			default:
				if (formErrors[fieldName] && value !== null) {
					setFormErrors(oldErrors => ({ ...oldErrors, [fieldName]: null }));
				}
				break;
		}
	};

	const openModal = modalName => {
		setModalToShow(modalName);
	};

	const closeModal = () => {
		setModalToShow(null);
	};

	const getCreateAlarmParams = () => {
		const params = {
			set_as_priority: formData.priority ? formData.priority : false,
			title: formData.name,
			icon: formData.icon,
			sound: formData.soundEnabled ? formData.sound : null,
			count_down: formData.countdown == true ? 1 : 0,
			flipchart_id:
				formData.flipchartValue && formData.flipchartValue.value > 0
					? formData.flipchartValue.value
					: null,
			disabled: formData.disabled,
			preset: formData.preset,
			is_generic:
				formData._911Active &&
				!formData.anotherAlarmIsGeneric &&
				formData.generic &&
				formData.contact911 == true
					? formData.generic
					: false || (formData.id > 0 && formData.generic),
		};
		if (formData.hasRollCall !== null) {
			params.activate_roll_call = formData.hasRollCall;
		}
		if (formData.markAllAccounted !== null) {
			params.mark_all_as_accounted = formData.markAllAccounted;
		}
		if (formData._911Active) {
			params.use_911_configurations = formData.contact911 == true ? true : false;
			params.partner_alarm_id = formData.alarmTypes911 ? formData.alarmTypes911.value : null;
		} else {
			params.contact_911 = formData.contact911 == true ? true : false;
		}
		return params;
	};

	const allRequiredFieldsAreFilled = () => {
		return (
			formData.name !== null &&
			formData.name !== '' &&
			formData.icon !== null &&
			((formData.soundEnabled === true && formData.sound) ||
				formData.soundEnabled === false) &&
			formData.countdown !== null &&
			formData.flipchartValue !== null &&
			formData.hasRollCall !== null &&
			formData.markAllAccounted !== null &&
			formData.contact911 !== null &&
			((formData._911Active &&
				formData.contact911 == true &&
				formData.alarmTypes911 !== null) ||
				(formData._911Active && formData.contact911 == false) ||
				!formData._911Active)
		);
	};

	const presetFieldsAreFilled = () => {
		return (
			(formData._911Active &&
				formData.contact911 == true &&
				formData.alarmTypes911 !== null) ||
			(formData._911Active && formData.contact911 == false) ||
			!formData._911Active
		);
	};

	const exportedData = {
		formData,
		onChangeFormData,
		formErrors,
		setFormErrors,
		modalToShow,
		openModal,
		closeModal,
		history,
		getCreateAlarmParams,
		allRequiredFieldsAreFilled,
		presetFieldsAreFilled,
		locationPath,
		setLocationPath,
		shouldPromptSaveModal,
		setShouldPromptSaveModal,
		setFormData,
		formHasChanged,
		updateFormChange,
	};

	return (
		<CreateAlarmTypeContext.Provider value={exportedData}>
			{children}
		</CreateAlarmTypeContext.Provider>
	);
};
