import React from "react";
import { FormLabel } from 'components/Forms/shared.js';
import { Input } from 'components/Input';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { Helper } from 'utilities/helper';
import { PHONE_NUMBER_MASK, PHONE_NUMBER_PLACEHOLDER } from 'utilities/constants';
import { Callout } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { isValidEmail } from 'utilities/validations';

const Submit = ({ agencies,
  selectedIndex,
  formData,
  setErrors,
  updateFormData,
  validationErrors,
  onSelectSitesHandler,
  selectedSites,
  sitesFRList,
  responderByEmailExist,
  firstResponderData,
  backToLatest,
  existMailSitesHandler,
  existResponderAccessedSites,
  loading
}) => {

  function handleKeyPress(event) {
    if (event.target.name != 'email') {
      if (event.target.value.length > 25) {
        event.preventDefault();
      }

    }
    const errors = {};
    updateFormData(event.target.name, event.target.value)
    setErrors(errors);
  }


  function checkIfNumber(event) {

    /**
     * Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
     **/

    const regex = new RegExp(/(^[0-9]+$|^$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

    return !event.key.match(regex) && event.preventDefault();
  }

	const validateEmailHandler = e => {
		const errors = {};
		if (!isValidEmail(e.target.value)) {
			errors.email = '*Email is not valid';
			setErrors(errors);
			const firstErrorField = Object.keys(errors)[0];
			const inputElement = document.getElementsByName(firstErrorField)[0];
			if (inputElement) {
				inputElement.focus();
			}
		} else {
			errors.email = '';
			setErrors(errors);
			updateFormData(e.target.name, e.target.value);
		}
	};

  return (
    <>
    {loading && <LoadingSpinner />}
    {! loading && (
      <>
      {!responderByEmailExist ?
        <div className="bootstrap-table-container custom-table custom-scroll first-responder-conratiner">
          <div style={{ width: '450px' }}>
            <SitesDropdownMultiSelect
              value={selectedSites}
              onChange={onSelectSitesHandler}
              visibleOptions={1}
              sitesList={sitesFRList}
              labelState={false}
            />
            <br />
            <div><p className='font-bold'>Agency</p></div>
            <p>{agencies[selectedIndex]['name']}</p>
            <p>{agencies[selectedIndex]['composite_address']}</p>
  
            <hr />
            <div><p className='font-bold'>Primary Contact Information</p></div>
            {(agencies[selectedIndex]['allowed_sites'].length == 0 ? <>
              <p style={{ color: '#9F1C3A' }}>This responder is new to Navigate 360. Provide their contact information and click "save" to notify them of their access to this site.</p>
              <FormLabel>First Name (required)</FormLabel>
              <Input
                placeholder='First name'
                onChange={handleKeyPress}
                onKeyPress={handleKeyPress}
                value={formData.first_name}
                name='first_name'
                error={!!validationErrors.first_name}
                errorMessage={validationErrors.first_name}
  
              />
              <FormLabel>Last Name (required)</FormLabel>
              <Input
                placeholder='Last name'
                onChange={handleKeyPress}
                onKeyPress={handleKeyPress}
                value={formData.last_name}
                name='last_name'
                error={!!validationErrors.last_name}
                errorMessage={validationErrors.last_name}
              />
  
              <FormLabel>Email (required)</FormLabel>
              <Input
                placeholder='email@email.com'
                name='email'
                value={formData.email}
                onChange={handleKeyPress}
                onKeyPress={handleKeyPress}
                error={!!validationErrors.email}
                errorMessage={validationErrors.email}
                onKeyUp={validateEmailHandler}
              />
              <div className='row'>
                <div className="col-md-8">
                  <Input
                    type='text'
                    value={formData.mobile_phone}
                    label="Mobile Phone"
                    error={!!validationErrors.mobilePhone}
                    errorMessage={validationErrors.mobilePhone}
                    placeholder={PHONE_NUMBER_PLACEHOLDER}
                    mask={PHONE_NUMBER_MASK}
                    onChange={(e) => {
                      validationErrors.mobilePhone = ''
                      const input = Helper.formatPhoneToDigits(e.target.value);
                      updateFormData('mobile_phone', input);
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className="col-md-8">
                  <Input
                    type='text'
                    label="Work Phone"
                    error={!!validationErrors.workPhone}
                    errorMessage={validationErrors.workPhone}
                    value={formData.work_phone}
                    placeholder={PHONE_NUMBER_PLACEHOLDER}
                    mask={PHONE_NUMBER_MASK}
                    onChange={(e) => {
                      validationErrors.workPhone = ''
                      const input = Helper.formatPhoneToDigits(e.target.value);
                      updateFormData('work_phone', input);
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type='number'
                    maxLength="5"
                    label="Ext."
                    placeholder="12345"
                    value={formData.extension}
                    onKeyDown={(event) => checkIfNumber(event)}
                    onChange={(e) => {
                      const input = e.target.value;
                      const inputLength = input.length;
                      const maxLength = 5;
                      if (input === '' || (inputLength <= maxLength && /^[0-9]*$/.test(input))) {
                        const parsedInput = parseInt(input);
                        if (parsedInput >= 0 || input === '') {
                          updateFormData('extension', input)
                        }
                      }
                    }}
                  />
                </div>
  
              </div>
              <div className='row'>
                <div className="col-md-8">
                  <Input
                    type='text'
                    label="Home Phone"
                    error={!!validationErrors.homePhone}
                    errorMessage={validationErrors.homePhone}
                    value={formData.home_phone}
                    placeholder={PHONE_NUMBER_PLACEHOLDER}
                    mask={PHONE_NUMBER_MASK}
                    onChange={(e) => {
                      validationErrors.homePhone = ''
                      const input = Helper.formatPhoneToDigits(e.target.value);
                      updateFormData('home_phone', input)
                    }}
                  />
                </div>
              </div>
            </>
              :
              <p style={{ color: '#9F1C3A' }}>This responder is already using Navigate 360. By clicking "save", you are granting them access to your site.</p>
            )}
          </div>
        </div> :
        <>
        <div className="firstRes">
  
        <br/>
          <Callout className="attachment-note" intent="primary">
            The contact entered is already in use by the following <br /> responder, if you would
            like to add this responder instead click "Add".Otherwise <a onClick={backToLatest} className="bulk-actions-anchor">change email</a> or click "Back"
          </Callout>
            <table className="table sticky-table">
              <thead>
                <tr>
                  <td colSpan="2">Organization Information</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{firstResponderData['team_name']}</td>
                </tr>
                <tr> 
                  <td>Address</td>
                  <td>
                      {firstResponderData['team_address']}
                  </td>
                </tr>
                <tr> 
                  <td>City</td>
                  <td>
                      {firstResponderData['team_city']}
                  </td>
  
                </tr>
                <tr> 
                  <td>State</td>
                  <td>
                      {firstResponderData['team_state']}
                  </td>
                </tr>
                <tr>
                  <td> Zip</td>
                  <td>
                      {firstResponderData['team_zip']}
                  </td>
                </tr>
              </tbody>
            </table>
  
            <SitesDropdownMultiSelect
              value={existResponderAccessedSites}
              onChange={existMailSitesHandler}
              visibleOptions={5}
              sitesList={sitesFRList}
            />
            </div>
        </>
      }
      </>
    )}
    
    </>
  );
};
export default Submit;