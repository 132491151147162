import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog } from '@blueprintjs/core';
import { Button } from 'components/Button';
import TextVariable from './textvariable';
import ImageVariable from './imagevariable';
import PhoneVariable from './phonevariable';
import EmailVariable from './emailvariable';
import MultipleChoiceVariable from './multiplechoicevariable';
import UserVariable from './uservariable';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

class EditVariables extends React.Component {
	state = {
		loading: false,
	};

	toggleLoading = val => this.setState({ loading: val });

	reloadVars = () => {
		const { refreshVariables } = this.props;
		this.setState({ loading: false });
		return refreshVariables();
	};

	renderCreate = () => {
		const {
			toggleEditDialog,
			templateId,
			editVarContent,
			editVariable,
			editVarId,
		} = this.props;
		const { loading } = this.state;
		switch (editVarContent.type.id) {
			case 1:
				return (
					<TextVariable
						toggleEditDialog={toggleEditDialog}
						templateId={templateId}
						refreshVariables={this.reloadVars}
						toggleLoading={this.toggleLoading}
						loading={loading}
						editVarContent={editVarContent}
						editVariable={editVariable}
						editVarId={editVarId}
					/>
				);
			case 2:
				return (
					<ImageVariable
						toggleEditDialog={toggleEditDialog}
						templateId={templateId}
						refreshVariables={this.reloadVars}
						toggleLoading={this.toggleLoading}
						loading={loading}
						editVarContent={editVarContent}
						editVariable={editVariable}
						editVarId={editVarId}
					/>
				);
			case 3:
				return (
					<PhoneVariable
						toggleEditDialog={toggleEditDialog}
						templateId={templateId}
						refreshVariables={this.reloadVars}
						toggleLoading={this.toggleLoading}
						loading={loading}
						editVarContent={editVarContent}
						editVariable={editVariable}
						editVarId={editVarId}
					/>
				);
			case 4:
				return (
					<EmailVariable
						toggleEditDialog={toggleEditDialog}
						templateId={templateId}
						refreshVariables={this.reloadVars}
						toggleLoading={this.toggleLoading}
						loading={loading}
						editVarContent={editVarContent}
						editVariable={editVariable}
						editVarId={editVarId}
					/>
				);
			case 5:
				return (
					<MultipleChoiceVariable
						toggleEditDialog={toggleEditDialog}
						templateId={templateId}
						refreshVariables={this.reloadVars}
						toggleLoading={this.toggleLoading}
						loading={loading}
						editVarContent={editVarContent}
						editVariable={editVariable}
						editVarId={editVarId}
					/>
				);
			case 6:
				return (
					<UserVariable
						toggleCreateDialog={toggleEditDialog}
						templateId={templateId}
						refreshVariables={this.reloadVars}
						toggleLoading={this.toggleLoading}
						loading={loading}
						editVarContent={editVarContent}
						editVariable={editVariable}
						editVarId={editVarId}
					/>
				);
			default:
				return null;
		}
	};

	render() {
		const { createVarDialog, toggleEditDialog } = this.props;
		return (
			<Dialog isOpen={createVarDialog} onClose={() => toggleEditDialog()}>
				<div>
					<TitleWrap>
						<Title>Edit Variable</Title>
						<Button
							text=""
							minimal
							icon="cross"
							onClick={() => {
								toggleEditDialog();
							}}
						/>
					</TitleWrap>
					<hr />
				</div>
				<div className="px-3">{this.renderCreate()}</div>
			</Dialog>
		);
	}
}

EditVariables.propTypes = {
	createVarDialog: PropTypes.bool,
	toggleEditDialog: PropTypes.func,
	templateId: PropTypes.string,
	refreshVariables: PropTypes.func,
	editVarContent: PropTypes.object,
	editVariable: PropTypes.func,
	editVarId: PropTypes.number,
};

export default EditVariables;
