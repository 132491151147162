import React, { useState } from 'react';
import { SafetyPlanService } from 'services';
import { format } from 'date-fns-tz';
import {
	exportHeader,
	exportHeaderTables,
	exportCustomStylesForSections,
	getTOC,
	parseContent,
} from './utilities';
import { parseDataModel, isInt } from 'utilities';
import { getVariableType } from 'utilities/SafetyPlan/helpers';

export const DownloadSafetyPlanModal = () => {
	const [exportLoading, setExportLoading] = useState(false);

	const getAndDownloadSafetyPlan = (id, fileName) => {
		setExportLoading(true);
		downloadSafetyPlan(id, fileName)
			.catch(error => {
				// TODO: notify the user it failed and they need to try again.
				console.error(error);
			})
			.finally(() => setExportLoading(false));
	};

	const downloadSafetyPlan = (id, fileName) =>
		SafetyPlanService.getExport(id).then(pdfBlob => {
			const filename = `${fileName} ${format(new Date(), 'hh-mm-ss a')}`;
			const data = new Blob([pdfBlob], {
				type: 'application/pdf',
			});
			const csvURL = window.URL.createObjectURL(data);
			const tempLink = document.createElement('a');
			tempLink.href = csvURL;
			tempLink.setAttribute('download', `${filename}.pdf`);
			tempLink.click();
			return Promise.resolve();
		});

	const downloadSafetyPlanSingleSelection = (
		template,
		variableValues,
		sectionIndex,
		subsectionIndex,
	) => {
		const { id, name } = template;
		const sectionId = template.sections[sectionIndex]?.id;
		const subsectionId = template.sections[sectionIndex]?.subsections[subsectionIndex]?.id;
		const { title } = subsectionId
			? template.sections[sectionIndex].subsections[subsectionIndex]
			: template.sections[sectionIndex];

		setExportLoading(true);
		return SafetyPlanService.getExport(id, sectionId, subsectionId)
			.then(pdfBlob => {
				const filename = `${name} - ${title} ${format(new Date(), 'hh-mm-ss a')}`;
				const data = new Blob([pdfBlob], {
					type: 'application/pdf',
				});
				const csvURL = window.URL.createObjectURL(data);
				const tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', `${filename}.pdf`);
				tempLink.click();
				return Promise.resolve();
			})
			.finally(() => {
				setExportLoading(false);
			});
	};

	return {
		getAndDownloadSafetyPlan,
		downloadSafetyPlan,
		downloadSafetyPlanSingleSelection,
		exportLoading,
	};
};
