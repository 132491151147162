import React from "react";
import { ConfirmationModal } from "componentsV2";

export const DeleteModal = ({
    modalName,
    modalToShow,
    loading,
    documentData,
    closeModal,
    handleDeleteSubmit,
    handleMultipleDeleteSubmit,
}) => {
    
    return (
        <ConfirmationModal
            isOpen={modalToShow === modalName}
            title={documentData.modalTitle}
            subtitle='Once deleted, this cannot be recovered.'
            showCloseButton={false}
            cancelBtn={{
                title: 'No, go back',
                onClick: closeModal
            }}
            confirmBtn={{
                title: documentData.confirmBtnTitle,
                onClick: documentData.id ? handleDeleteSubmit : handleMultipleDeleteSubmit,
                type: 'primaryDanger'
            }}
            loading={loading}
            btnsSize="lg"
            icon={{
                icon: 'trash',
                iconColor: 'error'
            }}
        />
    );
}