import React, { useContext, useState } from 'react';
import { ConfirmationModal, FeaturedIcon } from 'componentsV2';
import { CreateAlarmTypeContext } from '../provider';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { RapidAlarmService } from 'services/RapidAlarmService';

export const SaveAsDisabledModal = () => {
	const { closeModal, formData, getCreateAlarmParams, setShouldPromptSaveModal } = useContext(
		CreateAlarmTypeContext,
	);
	const [loading, setLoading] = useState(false);
	const toaster = useContext(ToasterContext);

	const onConfirmAction = () => {
		setLoading(true);
		const params = { ...getCreateAlarmParams(), disabled: true };
		RapidAlarmService.createAlarmType(params)
			.then(response => {
				if (response.statusCode === 201) {
					setShouldPromptSaveModal(false);
					toaster(
						`You have successfully saved the alarm for "${formData.name}" as disabled.`,
						Intent.NONE,
						<FeaturedIcon icon="disable" type="Gray" />,
					);
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				closeModal();
				setLoading(false);
			});
	};

	const onCancelAction = () => {
		closeModal();
		setShouldPromptSaveModal(false);
	};

	return (
		<ConfirmationModal
			isOpen={true}
			title={`Save "${formData.name}" as disabled?`}
			subtitle="Alarms can be saved as disabled and finalized at a later time. Any unsaved changes will be lost."
			loading={loading}
			confirmBtn={{
				title: 'Save as disabled',
				onClick: onConfirmAction,
			}}
			cancelBtn={{
				title: 'Cancel setup',
				onClick: onCancelAction,
			}}
		/>
	);
};
