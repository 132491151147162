import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './css/ContentSwitcher.scss';

export const ContentSwitcher = ({ 
	tabs = [], // all the tabs to be listed: id, label, isDisabled, skipId (skipId is used to not add the id to the output), leftIcon, rightIcon
	onClick = () => {}, 
	multi = false, // allow multiple selection
	preserveTab = false, // in case of false, allow diselection of tab after selection 
	defaultActiveTabs = [], // array of ids
	useInput = false // always set active to tabs from defaultActiveTabs
}) => {
	const [activeTabs, setActiveTabs] = useState(defaultActiveTabs);

	const handleOnClick = (clickedTabId) => {
		// Check if the clicked tab is not disabled
		const clickedTab = tabs.find((tab) => tab.id === clickedTabId);
		if (clickedTab && !clickedTab.isDisabled) {
			setActiveTabs((prevActiveTabs) => {
				if (prevActiveTabs.includes(clickedTabId) && !preserveTab) {
					return prevActiveTabs.filter((id) => id !== clickedTabId);
				} else if(multi) {
					return [...prevActiveTabs, clickedTabId];
				} else {
					return [clickedTabId];
				}
			});
		}
	};

	useEffect(() => {
		// Call the provided onClick function with the latest activeTabs state
		onClick(activeTabs);
	}, [activeTabs]);

	return (
		<div className="TabButton__wrapper">
		{tabs.map((tab) => (
			<div
				key={tab.id}
				aria-hidden
				onClick={() => handleOnClick(tab.id)}
				className={`tab-btn ${ ( (useInput ? defaultActiveTabs : activeTabs).includes(tab.id) && !tab.skipId) ? 'active' : ''} ${tab.isDisabled ? 'disabled' : ''} `}
			>
				{( tab.leftIcon && (
					<span className='tab-btn-left-icon'>{tab.leftIcon}</span>
				))}
				{tab.label}
				{( tab.rightIcon && (
					<span className='tab-btn-right-icon'>{tab.rightIcon}</span>
				))}
			</div>
		))}
		</div>
	);
};

ContentSwitcher.propTypes = {
	tabs :PropTypes.array.isRequired, 
	onClick : PropTypes.func,
	multi :PropTypes.bool,
	preserveTab :PropTypes.bool,
	defaultActiveTabs :PropTypes.array,
	useInput :PropTypes.bool 
}