import {
	DefaultColors,
	Dropdown,
	Input,
	InputColor,
	ToasterContext,
	FormModal,
	TextArea,
	Intent,
} from 'componentsV2';
import React, { useEffect, useState } from 'react';
import { BuildingsService } from 'services/BuildingsService';
import { filterSitesPerPermissionCode, checkPermissionOnDistrict } from 'utilities/permissions';
import { useSelector } from 'react-redux';
import { PlanService } from 'services/PlanService';
import { isNotEmpty } from 'utilities/validations';
import { FlipchartService } from 'services/FlipchartService';
import { useContext } from 'react';
import { SiteVisibilityError } from 'utilities/helper';

export const AddUpdateFlipchartModal = props => {
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);
	const [sites, setSites] = useState([]);
	const [selectedSite, setSelectedSite] = useState({});
	const permissions = useSelector(state => state.route.permissions);
	const [selectedTemplate, setSelectedTemplate] = useState({
		value: 'blank',
		label: 'Start from scratch',
	});
	const [showSafetyPlans, setShowSafetyPlans] = useState(false);
	const [safetyPlans, setSafetyPlans] = useState([]);
	const [selectedSafetyPlan, setSelectedSafetyPlan] = useState({});
	const [selectedTheme, setSelectedTheme] = useState({ value: 'custom', label: 'Custom' });
	const [showPrimaryOnly, setShowPrimaryOnly] = useState(false);
	const [showPrimayAndSecondary, setShowPrimayAndSecondary] = useState(false);
	const [primaryColor, setPrimaryColor] = useState(null);
	const [secondaryColor, setSecondaryColor] = useState(null);
	const allPresetColors = DefaultColors;
	const somePresetColors = DefaultColors.filter((DefaultColors, index) => index <= 4);
	const [description, setDescription] = useState('');
	const [hasDescriptionError, setHasDescriptionError] = useState(false);
	const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('');
	const [title, setTitle] = useState(null);
	const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
	const hasPermissionOnDistrict = checkPermissionOnDistrict('flipcharts_edit');

	const [validationErrors, setValidationErrors] = useState({
		site_id: false,
	});

	const templates = [
		{ value: 'blank', label: 'Start from scratch' },
		{ value: 'covid_school', label: 'COVID-19 Quick Reference Guide for Schools' },
		{ value: 'covid_business', label: 'COVID-19 Quick Reference Guide for Business' },
		{ value: 'FEMA', label: 'Emergency Flipchart for Schools' },
		{ value: 'safety_plan', label: 'Safety Plan' },
	];

	const themes = [
		{ value: 'custom', label: 'Custom' },
		{ value: 'solid', label: 'Solid' },
		{ value: 'stripes', label: 'Stripes' },
		{ value: 'gradient', label: 'Gradient' },
		{ value: 'rainbow', label: 'Rainbow' },
	];

	useEffect(() => {
		validateInputs();
		getInitialFormData();
	}, []);

	useEffect(() => {
		validateInputs();
	}, [
		title,
		selectedSite,
		selectedTemplate,
		selectedSafetyPlan,
		selectedTheme,
		primaryColor,
		secondaryColor,
	]);

	const getInitialFormData = () => {
		setLoading(true);

		BuildingsService.getAll(false, false)
			.then(serviceBuildings => {
				let sites = filterSitesPerPermissionCode(
					'flipcharts_edit',
					permissions,
					serviceBuildings,
				);

				if (hasPermissionOnDistrict) {
					sites.unshift({ label: 'Visible to all', value: null });
				}

				setSites(sites);
				setDefaultSelectedSite(sites);

				if (props.mode == 'edit') {
					getFlipchartDetails(sites);
				}
			})
			.then(() => {
				setLoading(false);
			});
	};

	const setDefaultSelectedSite = sites => {
		let selectedSite = null;

		if (props.selectedSites && props.selectedSites.length == 1) {
			selectedSite = sites.find(site => site.id === props.selectedSites[0]);
		}

		if (selectedSite) {
			setSelectedSite(selectedSite);
			return;
		}

		let loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
		let primarySite = sites.find(site => site.id === loggedUser.primaryBuilding.id);

		if (primarySite) {
			setSelectedSite(primarySite);
			return;
		}

		setSelectedSite(sites[0]);
	};

	const getFlipchartDetails = sites => {
		let flipchart = props.flipchart;

		setTitle(flipchart.title);
		setDescription(flipchart.description);

		if (flipchart.primaryColor) {
			setPrimaryColor('#' + flipchart.primaryColor);
		}

		if (flipchart.secondaryColor) {
			setSecondaryColor('#' + flipchart.secondaryColor);
		}

		if (flipchart.theme) {
			onThemeChange(themes.find(theme => theme.value === flipchart.theme));
		}

		if (flipchart.buildingId) {
			setSelectedSite(sites.find(site => site.id === flipchart.buildingId));
		} else {
			setSelectedSite(sites[0]);
		}

		if (flipchart.description == null) {
			setDescription('');
		}
	};

	const onSiteChange = selected => {
		setDescriptionError(false);
		setSelectedSite(selected);

		setSaveBtnDisabled(false);

		setValidationErrors({
			site_id: false,
		});

		onTemplateChange(selectedTemplate, selected);
	};

	const onTemplateChange = (selected, site = null) => {
		setDescriptionError(false);
		setSelectedTemplate(selected);
		var stripesTheme = themes.find(theme => theme.value === 'stripes');

		if (selected.value == 'covid_business') {
			setTitle('COVID-19 Quick Reference Guide for Business');
			onThemeChange(stripesTheme);
			setPrimaryColor('#633DB2');
			setSecondaryColor('#FFFFFF');
			setShowSafetyPlans(false);
			setSelectedSafetyPlan({});
		} else if (selected.value == 'covid_school') {
			setTitle('COVID-19 Quick Reference Guide for Schools');
			onThemeChange(stripesTheme);
			setPrimaryColor('#633DB2');
			setSecondaryColor('#FFFFFF');
			setShowSafetyPlans(false);
			setSelectedSafetyPlan({});
		} else if (selected.value == 'FEMA') {
			setTitle('Emergency Flipchart for Schools');
			onThemeChange(stripesTheme);
			setPrimaryColor('#FF0000');
			setSecondaryColor('#FFFFFF');
			setShowSafetyPlans(false);
			setSelectedSafetyPlan({});
		} else if (selected.value == 'safety_plan') {
			setTitle('');
			onThemeChange(stripesTheme);
			setPrimaryColor('#0D0D0D');
			setSecondaryColor('#FFFFFF');
			setShowSafetyPlans(true);

			PlanService.getLightPlans({ buildingId: site ? site.value : selectedSite.value }).then(
				response => {
					if (response.statusCode === 200) {
						setSafetyPlans(response.data);
					}
				},
			);
		} else if (selected.value == 'blank') {
			setTitle('');
			onThemeChange(themes.find(theme => theme.value === 'custom'));
			setShowSafetyPlans(false);
			setSelectedSafetyPlan({});
		}
	};

	const onSafetyPlanChange = selected => {
		setDescriptionError(false);
		setSelectedSafetyPlan(selected);
		setTitle(selected.label);
	};

	const onThemeChange = selected => {
		setDescriptionError(false);
		setSelectedTheme(selected);
		if (selected.value == 'solid' || selected.value == 'gradient') {
			setShowPrimaryOnly(true);
			setShowPrimayAndSecondary(false);
			setSecondaryColor(null);
		} else if (selected.value == 'stripes') {
			setShowPrimayAndSecondary(true);
			setShowPrimaryOnly(false);
		} else {
			setShowPrimaryOnly(false);
			setShowPrimayAndSecondary(false);
			setPrimaryColor(null);
			setSecondaryColor(null);
		}
	};

	const setPrimaryColorValue = value => {
		setDescriptionError(false);
		setPrimaryColor(value);
	};

	const setSecondaryColorValue = value => {
		setDescriptionError(false);
		setSecondaryColor(value);
	};

	const setDescriptionError = error => {
		if (error) {
			setSaveBtnDisabled(true);
			setDescriptionErrorMessage('description must have a length lower than or equal to 255');
			setHasDescriptionError(true);
		} else {
			setDescriptionErrorMessage();
			setHasDescriptionError(false);
		}
	};

	const onDescriptionChange = e => {
		if (e.target.value.length > 255) {
			setDescriptionError(true);
			return;
		}
		setDescriptionError(false);
		setDescription(e.target.value);
	};

	const onTitleChange = e => {
		setDescriptionError(false);
		setTitle(e.target.value);
	};

	const formSubmitHandler = () => {
		validateInputs();

		setLoading(true);

		let formData = {
			title: title,
			site_id: selectedSite.value,
			theme: selectedTheme.value,
			theme_primary_color: primaryColor,
			theme_secondary_color: secondaryColor,
			description: description,
		};

		if (props.mode == 'add') {
			formData.template_type = selectedTemplate.value;

			if (selectedTemplate.value == 'safety_plan') {
				formData.plan_id = selectedSafetyPlan.id;
			}
		}

		if (props.mode == 'edit') {
			FlipchartService.updateFlipchart(props.flipchart.id, formData)
				.then(response => {
					toaster(response.data.message, Intent.SUCCESS);
					props.onCloseModal();
					props.reloadFlipchart(props.flipchart.id);
					setLoading(false);
				})
				.catch(error => {
					if (error.statusCode == 403) {
						toaster(error.error.description, Intent.DANGER);
					} else if (error.statusCode == 400) {
						if (error.error.fields.site_id) {
							setValidationErrors({
								site_id: <SiteVisibilityError errors={error.error.fields} />,
							});
							setSaveBtnDisabled(true);
						} else {
							const allfields = error.error.fields;
							let errorMessage = 'Flipchart cannot be edited';
							if (allfields) {
								errorMessage =
									allfields.description ||
									allfields.title ||
									allfields.theme ||
									allfields.theme_seed ||
									allfields.theme_secondary_color ||
									allfields.theme_primary_color;
							}
							toaster(errorMessage, Intent.DANGER);
						}
					}

					setLoading(false);
				});
		} else {
			FlipchartService.addFlipchart(formData)
				.then(response => {
					toaster(response.data.message, Intent.SUCCESS);
					props.onCloseModal();
					setLoading(false);
					props.history.push('/flipcharts/view/' + response.data.id, { formData });
					return;
				})
				.catch(error => {
					if (error.statusCode == 403) {
						toaster(error.error.description, Intent.DANGER);
					} else if (error.statusCode == 400) {
						const allfields = error.error.fields;
						let errorMessage = 'Flipchart cannot be added';
						if (allfields) {
							errorMessage =
								allfields.description ||
								allfields.title ||
								allfields.theme ||
								allfields.theme_seed ||
								allfields.theme_secondary_color ||
								allfields.theme_primary_color;
						}
						toaster(errorMessage, Intent.DANGER);
					}

					setLoading(false);
				});
		}
	};

	const validateInputs = () => {
		if (
			!isNotEmpty(title) ||
			Object.keys(selectedSite).length < 1 ||
			Object.keys(selectedTemplate).length < 1 ||
			(showSafetyPlans && Object.keys(selectedSafetyPlan).length < 1) ||
			Object.keys(selectedTheme).length < 1 ||
			(showPrimaryOnly && !isNotEmpty(primaryColor)) ||
			(showPrimayAndSecondary && !isNotEmpty(primaryColor)) ||
			(showPrimayAndSecondary && !isNotEmpty(secondaryColor))
		) {
			setSaveBtnDisabled(true);
		} else {
			setSaveBtnDisabled(false);
		}
	};

	return (
		<>
			<FormModal
				isOpen={props.isOpen}
				onClose={props.onCloseModal}
				title={props.mode == 'edit' ? 'Edit flipchart details' : 'Add new flipchart'}
				showCloseButton={true}
				confirmBtn={{
					title: props.mode == 'edit' ? 'Update' : 'Save',
					onClick: formSubmitHandler,
					disabled: saveBtnDisabled,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: props.onCloseModal,
				}}
				loading={loading}
				scrollContent={false}
			>
				{props.mode !== 'edit' && (
					<div className="col-12 mb-3">
						<Dropdown
							label="Choose template"
							value={selectedTemplate}
							options={templates}
							onChange={onTemplateChange}
							isRequired={true}
						/>
					</div>
				)}

				<div className="col-12 mb-3">
					<Dropdown
						label="Site visibility"
						value={selectedSite}
						options={sites}
						onChange={onSiteChange}
						isRequired={true}
						error={validationErrors.site_id}
						errorMessage={validationErrors.site_id}
					/>
				</div>

				{props.mode !== 'edit' && showSafetyPlans && (
					<div className="col-12 mb-3">
						<Dropdown
							label="Choose safety plan"
							hasSearch={true}
							value={selectedSafetyPlan}
							options={safetyPlans}
							onChange={onSafetyPlanChange}
							isRequired={true}
							optionLabel="name"
							optionValue="id"
						/>
					</div>
				)}

				<div className="col-12 mb-3">
					<Input
						value={title}
						onChange={onTitleChange}
						name="title"
						label={'Title'}
						placeholder={'ex. Covid-19 guide for schools'}
						isRequired={true}
					/>
				</div>

				<div className="col-12 mb-3">
					<Dropdown
						label="Theme type"
						value={selectedTheme}
						options={themes}
						onChange={onThemeChange}
						isRequired={true}
					/>
				</div>

				{showPrimaryOnly && (
					<div className="col-12 mb-3">
						<InputColor
							label="Primary color"
							name="theme_primary_color"
							isRequired={true}
							presetColors={allPresetColors}
							value={primaryColor}
							setValue={setPrimaryColorValue}
						/>
					</div>
				)}

				{showPrimayAndSecondary && (
					<>
						<div className="col-12">
							<div className="row">
								<div className="col-6 mb-3">
									<InputColor
										label="Primary color"
										name="theme_primary_color"
										isRequired={true}
										presetColors={somePresetColors}
										value={primaryColor}
										setValue={setPrimaryColorValue}
									/>
								</div>

								<div className="col-6 mb-3">
									<InputColor
										label="Secondary color"
										name="theme_secondary_color"
										isRequired={true}
										presetColors={somePresetColors}
										value={secondaryColor}
										setValue={setSecondaryColorValue}
									/>
								</div>
							</div>
						</div>
					</>
				)}

				<div className="col-12">
					<TextArea
						label="Description"
						placeholder={'Enter flipchart description'}
						isRequired={false}
						onChange={onDescriptionChange}
						value={description}
						error={hasDescriptionError}
						errorMessage={descriptionErrorMessage}
					/>
				</div>
			</FormModal>
		</>
	);
};
