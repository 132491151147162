import React from 'react';
import { RadioButtonGroup } from 'componentsV2';
import { Radio } from '@blueprintjs/core';
import PropTypes from 'prop-types';

export const AlarmCountdown = ({ formData, formErrors, onChangeFormData, disabled = false }) => {
	const onToggleSoundHandler = e => {
		const value = JSON.parse(e.target.value);
		onChangeFormData({ type: 'countdown', payload: value });
	};

	const selectedValue = formData.countdown !== null ? formData.countdown : null;

	return (
		<RadioButtonGroup
			label={null}
			onChange={onToggleSoundHandler}
			selectedValue={selectedValue}
			size="small"
			bordered={false}
			required={true}
			layout="block"
			error={formErrors?.countdown}
			disabled={disabled}
		>
			<Radio label="Yes, countdown with option to override" value={true} />
			<Radio label="No, trigger alarm once alarm type is selected" value={false} />
		</RadioButtonGroup>
	);
};

AlarmCountdown.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
	disabled: PropTypes.bool,
};
