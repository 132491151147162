import React, { useState } from 'react';
import { CreateAlarmProvider } from '../CreateAlarmType/provider';
import { ViewAlarmTypeBody, ViewAlarmTypeHeader } from './components';
import { CreateAlarmTypeModals } from '../CreateAlarmType/modals';
import { LoadingSpinner } from 'componentsV2';
import { checkPermission, checkPermissionOnDistrict } from 'utilities/permissions';
import { useSelector } from 'react-redux';

export const ViewAlarmType = ({ match, history }) => {
	const [loading, setLoading] = useState(true);
	const alarmTypeId = match.params ? parseInt(match.params.id, 10) : null;
	const permissions = useSelector(state => state.route.permissions);
	const hasPermissionOnDistrict = checkPermissionOnDistrict('respond_edit');
	const editPremission = checkPermission('respond_edit', permissions);

	return (
		<CreateAlarmProvider history={history} loading={loading} setLoading={setLoading} hasPermissionOnDistrict={hasPermissionOnDistrict}>
			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<>
					<ViewAlarmTypeHeader />
					<CreateAlarmTypeModals />
					<ViewAlarmTypeBody alarmTypeId={alarmTypeId} editPremission={editPremission} />
				</>
			)}
		</CreateAlarmProvider>
	);
};
