import React, { useContext } from 'react';
import { CreateAlarmTypeContext } from '../../../../../../provider';
import { FieldCard, AlarmName, AlarmIcon, AlarmSound } from '../SharedFields';
import './css/BasicInfo.scss';

export const BasicInfo = () => {
	const { formData, formErrors, onChangeFormData } = useContext(CreateAlarmTypeContext);

	return (
		<div className="create-alarm-basic-info-container">
			<FieldCard
				header="Naming your alarm"
				description={
					<>
						Choose a name that will be displayed when someone activates the alarm.
						<p className="mt-3 mb-0">
							Keep it brief and clear. For example: "Intruder"
						</p>
					</>
				}
			>
				<div className="create-alarm-basic-info-container-alarm-name">
					<AlarmName
						formData={formData}
						formErrors={formErrors}
						onChangeFormData={onChangeFormData}
					/>
				</div>
			</FieldCard>
			<FieldCard
				header={
					<>
						Select an icon for your alarm <span className="text-danger">*</span>
					</>
				}
			>
				<AlarmIcon
					formData={formData}
					formErrors={formErrors}
					onChangeFormData={onChangeFormData}
				/>
			</FieldCard>
			<FieldCard
				header="Sound alarm on device when alarm is activated"
				description={
					<>
						Should users hear a sound on their devices when this alarm is activated?{' '}
						<span className="text-danger">*</span>
					</>
				}
			>
				<AlarmSound
					formData={formData}
					formErrors={formErrors}
					onChangeFormData={onChangeFormData}
				/>
			</FieldCard>
		</div>
	);
};
