import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AssessmentQuestion } from './AssessmentQuestion';

export const AssessmentQuestionSection = ({
	section,
	assessmentId,
	viewOnly,
	questionsFilter,
	updateQuestionsAnsweredCount,
	children,
}) => {
	const { name = '[N/A]', questions = [], sections = [], instructions } = section;
	const sectionClasses = ['section'];

	if (!questions || !questions.length) {
		sectionClasses.push('no-questions');
	}

	return (
		<div className={sectionClasses.join(' ')}>
			{children}
			<div className="section-header">
				<h3>{name}</h3>
				{instructions && (
					<p>
						<b>Directions: </b>
						{instructions}
					</p>
				)}
			</div>
			<div className="questions">
				{questions
					?.filter(question => {
						if (questionsFilter === 'answered') return question.answer !== null;
						if (questionsFilter === 'unanswered') return question.answer === null;
						return true; // 'all' or any other value will include all questions
					})
					.map(question => (
						<AssessmentQuestion
							{...{
								question,
								assessmentId,
								key: question.id,
								viewOnly,
								updateQuestionsAnsweredCount,
							}}
						/>
					))}
			</div>
			{sections?.map(subSection => (
				<AssessmentQuestionSection
					{...{
						section: subSection,
						assessmentId,
						key: subSection.id,
						viewOnly,
						questionsFilter,
						updateQuestionsAnsweredCount,
					}}
				/>
			))}
		</div>
	);
};

AssessmentQuestionSection.defaultProps = {
	viewOnly: false,
};

AssessmentQuestionSection.propTypes = {
	section: PropTypes.object.isRequired,
	assessmentId: PropTypes.number.isRequired,
	viewOnly: PropTypes.bool,
};
