import React, { useState, useEffect } from 'react';
import { Radio } from '@blueprintjs/core';
import { FormModal, Dropdown, Input, RadioButtonGroup, TextArea } from 'componentsV2';
import { BuildingsService } from 'services/BuildingsService';

export const MarkAsUsedModal = ({ closeModal, markAsUsed, loadingLog, scenario }) => {
	// Mark as used values
	const [dateUsed, setDateUsed] = useState('');
	const [scenarioType, setScenarioType] = useState('Drill');
	const [notes, setNotes] = useState('');
	const [isBuildingDisabled, setIsBuildingDisabled] = useState(false);

	// Dropdown state
	const [buildings, setBuildings] = useState([]);
	const [selectedBuilding, setSelectedBuilding] = useState({});

	const [loadingBuildings, setLoadingBuildings] = useState(true);

	useEffect(() => {
		// get buildings list
		const fetchBuildings = async () => {
			const data = await BuildingsService.getAll(false);
			setBuildings(data);
			const getSelectedBuilding = scenario
				? data.find(building => scenario.site_id === building.id)
				: null;
			setSelectedBuilding(getSelectedBuilding ? getSelectedBuilding : buildings[0]);
			setIsBuildingDisabled(getSelectedBuilding ? true : false);
			setLoadingBuildings(false);
		};
		fetchBuildings().catch(console.error);
	}, []);

	const handleConfirmation = () => {
		const data = {
			scenarioId: `${scenario.id}`,
			buildingId: selectedBuilding.value,
			dateUsed,
			scenarioType,
			notes,
		};
		markAsUsed(data);
	};

	return (
		<FormModal
			isOpen={true}
			onClose={closeModal}
			confirmBtn={{
				title: 'Log it',
				onClick: () => handleConfirmation(),
				disabled: !(selectedBuilding && dateUsed),
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: closeModal,
			}}
			title="Scenario log"
			subtitle="Logging a scenario gives each building a way to track what scenarios you've already used with staff and when."
			loading={loadingBuildings || loadingLog}
		>
			<div className="col-12 mb-3">
				<Dropdown
					label="Which building was this scenario used for?"
					value={selectedBuilding}
					optionLabel="name"
					optionValue="value"
					options={buildings}
					isRequired={true}
					onChange={building => {
						setSelectedBuilding(building);
					}}
					disabled={isBuildingDisabled}
				/>
			</div>
			<div className="col-12 mb-3">
				<Input
					type="date"
					label="When did you use this scenario?"
					placeholder="Select date"
					isRequired={true}
					onChange={({ target }) => setDateUsed(target.value)}
				/>
			</div>
			<div className="col-12 mb-3">
				<RadioButtonGroup
					label="How was this scenario used?"
					bordered={true}
					required={true}
					layout="inline"
					size="small"
					onChange={({ target }) => setScenarioType(target.value)}
					selectedValue={scenarioType}
					fullwidth={true}
				>
					<Radio label="Drill" value="Drill" />
					<Radio label="Staff Development" value="Staff Development" />
				</RadioButtonGroup>
			</div>
			<div className="col-12 mb-3">
				<TextArea
					label="Any notes?"
					placeholder="ex. It was a good time to drill this scenario today!"
					onChange={({ target }) => setNotes(target.value)}
				/>
			</div>
		</FormModal>
	);
};
