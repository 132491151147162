import React, { useEffect, useState, useCallback } from 'react';
import { LoadingSpinner } from 'componentsV2';
import {
	Filterations,
	Table,
	EmptyStatuses,
	MapsProvider,
	BulkActions,
	CreateMapBtn,
	OldSafetyPlansCard,
} from './';
import useSelectedSites from 'hooks/useSelectedSites';
import { checkPermission } from 'utilities/permissions';
import { FloorplanService } from 'services/FloorplanService';
import { PageHeader } from 'components/PageHeader';
import { useSelector } from 'react-redux';
import { decodeToken } from 'utilities';
import './css/Maps.scss';

export const Maps = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [tableIsLoading, setTableIsLoading] = useState(false);
	const [MapsList, setMapsList] = useState([]);
	const [selectedMaps, setSelectedMaps] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const defaultSelectedSites = useSelectedSites();
	const [showEmptyStatus, setShowEmptyStatus] = useState(false);
	const [noDataAdded, setNoDataAdded] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [selectedSites, setSelectedSites] = useState([]);
	const [hasOldSp, setHasOldSp] = useState(false);

	const [filterationData, setFilterationData] = useState({
		page: 1,
		perPage: 10,
		sortKey: 'title',
		sortDir: 'asc',
		title: '',
		includeVisibleToAll: true,
		buildingIds: [],
	});
	const permissions = useSelector(state => state.route.permissions);
	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const dist = token ? decodeToken(token) : '';
	const districtId = dist ? dist.dist : null;
	const editPermission = checkPermission('maps_edit', permissions);
	const editPermissionOnBinders = checkPermission('vb_edit', permissions);

	const hasEditPermissionOnBinders = map => {
		return checkPermission('vb_edit', permissions, map.building ? map.building.id : districtId);
	};
	const isSysAdmin = permissions?.isSysAdmin;

	useEffect(() => {
		getMapsList();
		FloorplanService.checkMapContainSp(filterationData.buildingIds).then(response => {
			setHasOldSp(response.data.hasOldSP);
		});
	}, [filterationData]);

	const getMapsList = useCallback(() => {
		setTableIsLoading(true);
		FloorplanService.getAll(filterationData)
			.then(response => {
				if (response.statusCode === 200) {
					const allData = response.data.map(map => {
						const mapEditPermission = checkPermission(
							'maps_edit',
							permissions,
							map.building ? map.building.id : districtId,
						);
						const mapViewPermission = checkPermission(
							'maps_view',
							permissions,
							map.building?.id,
						);
						return {
							...map,
							editPermission: mapEditPermission,
							viewPermission: mapViewPermission,
						};
					});
					setMapsList(allData);
					setTotalRows(response._metadata.totalRecords);
					const isEmpty = allData.length === 0;
					setNoDataAdded(isFirstRender && isEmpty);
					setShowEmptyStatus(isEmpty);
				}
			})
			.catch(error => {
				console.error('Error fetching Maps list:', error);
			})
			.finally(() => {
				setLoading(false);
				setSelectedMaps([]);
				setIsFirstRender(false);
				setTableIsLoading(false);
			});
	}, [filterationData]);

	const toggleBulkActions = () => {
		if (selectedMaps.length === 0) return false;
		return selectedMaps.some(selectedMap => {
			return MapsList.some(map => map.id === selectedMap.id);
		});
	};
	const newMapBtn = editPermission && !noDataAdded && <CreateMapBtn />;

	return (
		<MapsProvider
			reloadList={getMapsList}
			history={history}
			defaultSelectedSites={defaultSelectedSites}
			selectedSites={selectedSites}
			setSelectedSites={setSelectedSites}
		>
			<div className="maps-container">
				<PageHeader
					title="Maps"
					subtitle="Create and view interactive floor plans as layers on top of a site map."
					customItem={() => newMapBtn}
				/>
				{loading && <LoadingSpinner />}
				{!loading && (
					<>
						{hasOldSp && (
							<OldSafetyPlansCard
								history={history}
								editPermissionOnBinders={editPermissionOnBinders}
							/>
						)}
						{!noDataAdded && (
							<Filterations
								defaultSelectedSites={defaultSelectedSites}
								setFilterationData={setFilterationData}
								filterationData={filterationData}
								setLoading={setTableIsLoading}
								selectedSites={selectedSites}
								setSelectedSites={setSelectedSites}
							/>
						)}
						{toggleBulkActions() && (
							<BulkActions
								selectedMaps={selectedMaps}
								editPermission={editPermission}
							/>
						)}
						{showEmptyStatus && (
							<EmptyStatuses
								filterationData={filterationData}
								noDataAdded={noDataAdded}
								editPermission={editPermission}
							/>
						)}
						{!showEmptyStatus && (
							<Table
								list={MapsList}
								filterationData={filterationData}
								setFilterationData={setFilterationData}
								totalRows={totalRows}
								editPermission={editPermission}
								setSelectedRows={setSelectedMaps}
								selectedRows={selectedMaps}
								history={history}
								setLoading={setTableIsLoading}
								loading={tableIsLoading}
								hasEditPermissionOnBinders={hasEditPermissionOnBinders}
								isSysAdmin={isSysAdmin}
							/>
						)}
					</>
				)}
			</div>
		</MapsProvider>
	);
};
