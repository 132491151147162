/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { Dropdown } from 'components/Dropdown';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Input } from 'components/Input';
import { CardModal } from 'components/Modals/CardModal';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { DrillLogService } from 'services';
import { FIELD_OPTIONS } from 'utilities/drills/mockData';
import { Radio, RadioGroup } from '@blueprintjs/core';

const Page = styled.div`
	padding: 8px 24px;

	.modal-card {
		.dropdown {
			margin-bottom: 16px;
		}
	}
`;

const BASE_VIEW = {
	newLogName: null,
	newLogType: null,
	newLogIsPubliclyViewable: null,
	showCreationModal: false,
};

const BASE_STATE = {
	...BASE_VIEW,
	loading: true,
	error: null,
	data: [],
};
class ManageDrillLogFields extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		DrillLogService.fetchDrillLogFields()
			.then(data => {
				this.setState({
					loading: false,
					data: Array.isArray(data) ? data : null,
				});
			})
			.catch(error => {
				this.setState({
					error,
					data: null,
					loading: false,
				});
			});
	}

	showCreationModal = show => {
		this.setState({
			...BASE_VIEW,
			showCreationModal: show,
		});
	};

	createLogField = () => {
		// TODO: use different validations
		const { newLogName, newLogType, newLogIsPubliclyViewable } = this.state;
		const fields = {
			'Drill Log Name': newLogName || null,
			'Drill Log Type': newLogType || null,
			'Publicly Viewable': newLogIsPubliclyViewable,
		};

		// error handling for empty fields
		const emptyFields = Object.keys(fields).filter(
			item => fields[item] === null,
		);
		if (emptyFields.length) {
			const usePluralCase = emptyFields.length > 1;
			return Swal.fire({
				icon: 'error',
				title: `Empty field${usePluralCase ? 's' : ''}`,
				text: usePluralCase
					? `${emptyFields.join(
							', ',
					  )} are empty. Please enter a value for each fields.`
					: `${
							emptyFields[0]
					  } is empty. Please enter a value for this field.`,
			});
		}

		// submit the request
		DrillLogService.createDrillLogField(
			newLogName,
			newLogType?.value,
			newLogIsPubliclyViewable === '1',
		)
			.then(item => {
				if (item?.uuid) {
					Swal.fire(
						'Success!',
						'The new drill log field has been created!',
						'success',
					);

					this.setState(prevState => ({
						showCreationModal: false,
						data: prevState.data.concat(item),
						newLogName: null,
						newLogType: null,
						newLogIsPubliclyViewable: null,
					}));
				}
			})
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Opps...',
					text:
						'Could not create a drill log field at this time. Please try again.',
				}),
			);
	};

	render() {
		const {
			loading,
			error,
			data,
			showCreationModal,
			newLogName,
			newLogType,
			newLogIsPubliclyViewable,
		} = this.state;

		const { history } = this.props;

		if (error) {
			return 'Something went wrong, please refresh';
		}

		if (loading) {
			return <LoadingSpinner />;
		}

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Manage Drill Log Fields"
					onBackBtnClick={history.goBack}
					actionTitle="Create New Drill Log Field"
					onActionClick={() => this.showCreationModal(true)}
				/>

				{/* drills table */}
				<Table
					headers={[
						'Publicly viewable',
						'Field name',
						'Field data type',
						'Field UUID',
					]}
					data={data}
					renderItem={item => (
						<>
							<td>
								<input
									type="checkbox"
									disabled
									checked={item.publicViewable}
								/>
							</td>
							<td>{item.name}</td>
							<td className="text-capitalize">{item.type}</td>
							<td>{item.uuid}</td>
						</>
					)}
				/>

				{/* create drill log field modal */}
				<CardModal
					isVisible={showCreationModal}
					setIsVisible={this.showCreationModal}
					title="Create New Drill Log Field"
					actionTitle="Create"
					onActionClick={this.createLogField}
				>
					{/* name field */}
					<Input
						label="Name"
						placeholder="Enter name..."
						value={newLogName}
						onChange={({ target }) =>
							this.setState({ newLogName: target.value })
						}
					/>

					{/* type dropdown */}
					<Dropdown
						label="Type"
						value={newLogType}
						options={FIELD_OPTIONS}
						onChange={newValue =>
							this.setState({ newLogType: newValue })
						}
					/>

					{/* yes and now check boxes */}
					<RadioGroup
						label="Make viewable to the public"
						onChange={({ target }) => {
							this.setState({
								newLogIsPubliclyViewable: target.value,
							});
						}}
						selectedValue={newLogIsPubliclyViewable}
					>
						<Radio label="Yes" value="1" />
						<Radio label="No" value="0" />
					</RadioGroup>
				</CardModal>
			</Page>
		);
	}
}

ManageDrillLogFields.propTypes = {
	history: PropTypes.object,
};

export default ManageDrillLogFields;
