/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { CardModal } from 'components/Modals/CardModal';
import { Dropdown } from 'components/Dropdown';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { MoreActionsButton } from 'components/MoreActionsButton';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { DrillLogService, SchoolYearsService } from 'services';
import { formatDate } from 'utilities/dates';

const Page = styled.div`
	padding: 8px 32px;
	min-height: 100vh;

	.dropdown {
		max-width: 350px;
	}

	.table-header {
		color: #10161a;
		font-size: 18px;
		margin-top: 24px;
		font-weight: 700;
		line-height: 21px;
		font-style: normal;
		margin-bottom: 16px;
		letter-spacing: 0.3px;
		font-family: Merriweather;
	}

	.modal-card {
		width: 400px;

		.text-header {
			color: #10161a;
			font-size: 14px;
			font-weight: 700;
			line-height: 20px;
			font-style: normal;
			margin-bottom: 2px;
			letter-spacing: 0px;
			font-family: Nunito Sans;
		}

		.text-description {
			color: #10161a;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			font-style: normal;
			letter-spacing: 0px;
			font-family: Nunito Sans;
		}
	}
`;

const BASE_VIEW = {
	selectedSchoolYear: null,
	drills: null,
	tableLoading: false,
	showReportModal: false,
	selectedDrill: null,
};

const BASE_STATE = {
	...BASE_VIEW,
	loading: true,
	error: null,
	selectedDistrictName: null,
	selectedBuildingName: null,
	schoolYears: null,
};

export default class PublicDrillLogs extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		Promise.all([SchoolYearsService.fetchYears(), this.fetchData()])
			.then(([schoolYears, pageData]) => {
				this.setState({
					...BASE_STATE,
					schoolYears,
					loading: false,
					selectedSchoolYear: schoolYears?.find(
						({ value }) => value === new Date().getFullYear(),
					),
					selectedDistrictName: pageData?.districtName,
					selectedBuildingName: pageData?.buildingName,
					drills: pageData?.drills,
				});
			})
			.catch(() => {
				this.setState({
					...BASE_STATE,
					loading: false,
					error:
						'Could not fetch the data, please try refreshing this page',
				});
				Swal.fire({
					title: 'Oops!',
					text: 'Could not fetch the data. Please try again',
					icon: 'error',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
			});
	}

	fetchData = () => {
		const {
			match: {
				params: { districtId, buildingId },
			},
		} = this.props;

		const { selectedSchoolYear } = this.state;

		return DrillLogService.getPublicDrillLogs(
			selectedSchoolYear?.value || new Date().getFullYear(), // for initial request
			districtId,
			buildingId,
		);
	};

	setSchoolYear = selectedSchoolYear => {
		this.setState(
			{
				selectedSchoolYear,
				tableLoading: true,
			},
			() =>
				this.fetchData()
					.then(pageData => {
						this.setState({
							tableLoading: false,
							drills: pageData?.drills,
						});
					})
					.catch(() => {
						Swal.fire({
							title: 'Oops!',
							text: 'Could not fetch the data. Please try again',
							icon: 'error',
							toast: true,
							position: 'top',
							timer: 3000,
							showConfirmButton: false,
						});
					}),
		);
	};

	manageReportModal = (showReportModal = true, selectedDrill = null) =>
		this.setState({ showReportModal, selectedDrill });

	render() {
		const {
			loading,
			error,
			selectedDistrictName,
			selectedBuildingName,
			schoolYears,
			selectedSchoolYear,
			tableLoading,
			drills,
			showReportModal,
			selectedDrill,
		} = this.state;

		if (loading) return <LoadingSpinner />;

		if (error) return error;

		return (
			<Page>
				{/* header and underline */}
				<PageHeader
					title="Public Drill Logs"
					subtitle={selectedDistrictName}
				/>

				{/* school year dropdown */}
				<Dropdown
					label="School year"
					value={selectedSchoolYear}
					options={schoolYears}
					onChange={this.setSchoolYear}
				/>

				{/* loading spinner for the table */}
				{tableLoading && <LoadingSpinner />}

				{/* table header */}
				{drills && selectedBuildingName && (
					<h4 className="table-header">{selectedBuildingName}</h4>
				)}

				{/* list of publicly viewable drill logs */}
				{drills && (
					<Table
						headers={[
							'Scheduled date',
							'Completed date',
							'Type of drill',
							'Actions',
						]}
						data={drills}
						renderItem={item => {
							return (
								<>
									<td>
										{item.scheduledDate
											? formatDate(item.scheduledDate)
											: 'N/A'}
									</td>

									<td>
										{item.completedDate
											? formatDate(item.completedDate)
											: 'N/A'}
									</td>

									<td>{item?.drillTypeName}</td>

									<td>
										<MoreActionsButton
											actions={[
												{
													id: 'view',
													title:
														'View complete report',
													handler: () =>
														this.manageReportModal(
															true,
															item,
														),
												},
											]}
										/>
									</td>
								</>
							);
						}}
					/>
				)}

				{/* email to */}
				<CardModal
					isVisible={showReportModal}
					setIsVisible={this.manageReportModal}
					title="Drill documentation"
					actionTitle="Done"
					onActionClick={() => this.manageReportModal(false)}
				>
					<br />

					{/* site */}
					<p className="text-header">Site</p>
					<p className="text-description">
						{selectedDrill?.buildingName}
					</p>

					{/* drill type */}
					<p className="text-header">Drill type</p>
					<p className="text-description">
						{selectedDrill?.drillTypeName}
					</p>

					{/* drill date and time */}
					<p className="text-header">Drill date &amp; time</p>
					<p className="text-description">
						{selectedDrill?.scheduledDate
							? formatDate(selectedDrill.scheduledDate)
							: 'N/A'}
					</p>

					{/* date publicly posted */}
					<p className="text-header">Date publicly posted</p>
					<p className="text-description">
						{selectedDrill?.completedDate
							? formatDate(selectedDrill.completedDate)
							: 'N/A'}
					</p>

					{/* posted by */}
					<p className="text-header">Posted by</p>
					<p className="text-description">
						{selectedDrill?.postedByFirstName}{' '}
						{selectedDrill?.postedByLasName}
					</p>

					{/* title of person posting */}
					{selectedDrill?.titleOfPersonPosting && (
						<>
							<p className="text-header">
								Title of person posting
							</p>
							<p className="text-description">
								{selectedDrill?.titleOfPersonPosting}
							</p>
						</>
					)}
				</CardModal>
			</Page>
		);
	}
}

PublicDrillLogs.propTypes = {
	match: PropTypes.object,
};
