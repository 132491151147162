/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { Table } from 'components/Table';
import { Dropdown } from 'components/Dropdown';
import { StatusPilsner } from 'components/StatusPilsner';
import { Button } from 'components/Button';
import { MoreActionsButton } from 'components/MoreActionsButton';
import {
	getAvailableActionsForDrillStatus,
	downloadDrillsTableAsCSV,
	getUnscheduledDrillCountsForBuilding
} from 'utilities/drills/utils';
import { DrillTypesService } from 'services/DrillTypesService';
import { DrillsService } from 'services/DrillsService';
import { formatDate } from 'utilities/dates';
import { SitesDropdown } from 'components/SitesDropdown';
import { SchoolYearDropdown } from 'components/SchoolYearDropdown';
import { MoreFiltersModal } from 'components/Modals/MoreFiltersModal';
import { DrillDetailsModal } from 'components/Modals/DrillDetailsModal';
import { ViewDrillDetails } from 'components/Modals/ViewDrillDetails';
import { FilterChip } from 'components/FilterChip';
import { cancel } from 'assets/icons';
import { DrillLogService } from 'services/DrillLogService';
import { checkPermission } from 'utilities/permissions';
import { useFilter } from 'hooks/useFilter';
import { DrillDistrictRequirementsService } from "services";
import { BuildingsService } from '../../../services/BuildingsService';

const Wrapper = styled.div`
	padding: 0px 0px;
	.dropdown-filters {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.dropdown {
			margin-right: 24px;
			margin-bottom: 16px;
			width: calc(20% - 24px);
		}

		.filter-button-container {
			display: flex;
			flex: 1;
			align-items: center;
		}
	}

	.more-actions-btn img {
		cursor: pointer;
		padding: 5px 10px;
	}

	.filters-chips-container {
		align-items: flex-end;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 32px;

		.pilsner {
			margin-right: 10px;
		}
	}

	.date-chips-container {
		display: flex;
		margin-bottom: 10px;
	}

	.status-chips-container {
		display: flex;
		margin-right: 40px;
		margin-bottom: 10px;

		.pilsner {
			margin-right: 10px;
		}
	}

	@media print {
		.drills-table {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: white;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.dropdown-filters {
			display: none !important;
		}
		.actions {
			display: none !important;
		}
		.filters-chips-container {
			display: none;
		}

		#drills-table thead tr th:last-child {
			display: none;
		}

		#drills-table tbody tr td {
			flex-direction: column;
		}

		#drills-table tbody tr td:last-child {
			display: none;
		}
	}
`;

const TableDataStatus = styled.td`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const filterInitialState = {
	actualDrillDateFrom: null,
	actualDrillDateTo: null,
	scheduledDrillDateFrom: null,
	scheduledDrillDateTo: null,
	datePostedFrom: null,
	datePostedTo: null,
	logPosted: 'all',
	statusCompleted: true,
	statusInProgress: true,
	statusScheduled: true,
	statusOverdue: true,
	statusNotScheduled: true
};

const SearchDrills = ({ permissions }) => {
	const [showMoreFiltersModal, setShowMoreFiltersModal] = useState(false);
	const [showDrillDetailsModal, setShowDrillDetailsModal] = useState(false);
	const [showViewDetailsModal, setShowViewDetailsModal] = useState(false);
	const [selectedDrill, setSelectedDrill] = useState(null);

	const [drillTypes, setDrillTypes] = useState(null);
	const [drills, setDrills] = useState(null);

	const [selectedSite, setSelectedSite] = useState(null);
	const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
	const [selectedDrillType, setSelectedDrillType] = useState(null);
	const [filteredData, setFilteredData] = useState(null);
	const [filter, setFilter] = useState(filterInitialState);
	const [userPermissions, setUserPermissions] = useState({});

	const editHandler = drill => () => {
		setShowDrillDetailsModal(true);
		setSelectedDrill(drill);
	};

	const viewHandler = drill => () => {
		setShowViewDetailsModal(true);
		setSelectedDrill(drill);
	};

	const unpostHandler = drill => () => {
		DrillLogService.unpostDrillLog(drill?.scheduleId)
			.then(() => {
				Swal.fire({
					title: 'Success!',
					text: 'Drill log was unposted',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
				getDrills();
			})
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Could not unpost this drill log. Please try again.',
				}),
			);
	};

	// eslint-disable-next-line no-console
	const postHandler = drill => () => console.log('Drill post', drill);

	const printHandler = drill => () => {
		setShowViewDetailsModal(true);
		setSelectedDrill(drill);
		setTimeout(() => {
			window.print();
		}, 2000);
	};

	const removeHandler = drill => () => {
		DrillLogService.removeDrillLog(drill.id)
			.then(() => {
				Swal.fire({
					title: 'Success!',
					text: 'Drill log was removed',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
				getDrills();
			})
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Could not delete this drill. Please try again.',
				}),
			);
	};

	const clearDatesFilterValue = (dateFrom, dateTo) => {
		setFilter(prevState => ({
			...prevState,
			[dateFrom]: null,
			[dateTo]: null,
		}));
	};

	const printListedDrills = () => {
		window.print();
	};

	const getChipText = (text, from, to) => {
		if (from && to) {
			return `${text} ${formatDate(from)} - ${formatDate(to)}`;
		}
		const fromText = from ? formatDate(from) : '';
		const toText = to ? formatDate(to) : '';
		return `${text} ${fromText} ${toText}`.trim();
	};

	const renderItem = item => (
		<>
			<td>{item.id}</td>
			<TableDataStatus>
				{item.title}
				<StatusPilsner status={item.status} />
			</TableDataStatus>
			<td>{item.type}</td>
			<td>{item.buildingName}</td>
			<td>{item.year}</td>
			<td>{item.status === 'not_scheduled' ? 'No date' : formatDate(item.date)}</td>
			<td>
				{item.log?.drillLoggedDate
					? formatDate(item.log?.drillLoggedDate)
					: 'No date'}
			</td>
			<td>
				{item.log?.logCompletedDate
					? formatDate(item.log?.logCompletedDate)
					: 'Not posted'}
			</td>
			<td className="more-actions-btn">
				<MoreActionsButton
					actions={getAvailableActionsForDrillStatus(
						item?.status?.toLowerCase(),
						editHandler(item),
						viewHandler(item),
						unpostHandler(item),
						postHandler(item),
						printHandler(item),
						removeHandler(item),
						userPermissions,
					)}
				/>
			</td>
		</>
	);

	useEffect(() => {
		if (selectedSchoolYear) {
			DrillTypesService.getAll(true, selectedSchoolYear?.value).then(
				serviceDrillTypes => setDrillTypes(serviceDrillTypes),
			);
		}
	}, [selectedSchoolYear]);

	const getDrills = async () => {
		if (selectedSite && selectedSchoolYear) {
			const scheduledDrills = await DrillsService.getWithParams(selectedSite.value === 'all-schools' ? 'all' : selectedSite.value, selectedSchoolYear.value, selectedDrillType ? selectedDrillType.uuid : null)
			const requirements = await DrillDistrictRequirementsService.getAllRequirementsByBuilding(selectedSite.value, selectedSchoolYear.value)
			let unscheduledDrills = []
			// unscheduled drills for one type
			if (selectedDrillType.uuid) {
				// unscheduled drills for each building
				if (selectedSite.value === 'all-schools') {
					const buildings = await BuildingsService.getAll();
					for (const building of buildings) {
						const buildingScheduledDrills = scheduledDrills.filter(drill => drill.buildingName === building.name)
						const unscheduledBuildingDrillCounts = getUnscheduledDrillCountsForBuilding(requirements, buildingScheduledDrills)
						const unscheduledBuildingDrills = createUnscheduledDrillsForBuilding(unscheduledBuildingDrillCounts, building.name, selectedDrillType.uuid)
						unscheduledDrills = [...unscheduledDrills, ...unscheduledBuildingDrills]
					}
				// unscheduled drills for one building
				} else {
					const unscheduledBuildingDrillCounts = getUnscheduledDrillCountsForBuilding(requirements, scheduledDrills)
					const buildingName = (await BuildingsService.findOne(selectedSite.value)).name
					const unscheduledBuildingDrills = createUnscheduledDrillsForBuilding(unscheduledBuildingDrillCounts, buildingName, selectedDrillType.uuid)
					unscheduledDrills = [...unscheduledBuildingDrills]
				}
			// unsheduled drills for all types
			} else {
				// unscheduled drills for each building
				if (selectedSite.value === 'all-schools') {
					const buildings = await BuildingsService.getAll();
					for (const building of buildings) {
						const buildingScheduledDrills = scheduledDrills.filter(drill => drill.buildingName === building.name)
						const unscheduledBuildingDrillCounts = getUnscheduledDrillCountsForBuilding(requirements, buildingScheduledDrills)
						const unscheduledBuildingDrills = createUnscheduledDrillsForBuilding(unscheduledBuildingDrillCounts, building.name)
						unscheduledDrills = [...unscheduledDrills, ...unscheduledBuildingDrills]
					}
				// unscheduled drills for one building
				} else {
					const unscheduledBuildingDrillCounts = getUnscheduledDrillCountsForBuilding(requirements, scheduledDrills)
					const buildingName = (await BuildingsService.findOne(selectedSite.value)).name
					const unscheduledBuildingDrills = createUnscheduledDrillsForBuilding(unscheduledBuildingDrillCounts, buildingName)
					unscheduledDrills = [...unscheduledBuildingDrills]
				}
			}
			setDrills([...scheduledDrills, ...unscheduledDrills])
		}
	};

	const createUnscheduledDrillsForBuilding = (unscheduledDrillCounts, buildingName, drillId = null) => {
		let unscheduledBuildingDrills = []
		// one drill type
		if (drillId) {
			const record = unscheduledDrillCounts.find(e => e.uuid === drillId)
			if (record.count > 0) {
				const unscheduledBuildingDrillsSingleType = Array.from(Array(record.count).keys()).map(drill => {
					return {
						title: record.name,
						type: record.name,
						buildingName: buildingName,
						year: selectedSchoolYear.value,
						date: 'No date',
						status: 'not_scheduled',
						typeId: selectedDrillType.value
					}
				})
				unscheduledBuildingDrills = [...unscheduledBuildingDrills, ...unscheduledBuildingDrillsSingleType]
			}
		// all drill types
		} else {
			for (const record of unscheduledDrillCounts) {
				if (record.count > 0) {
					const unscheduledBuildingDrillsSingleType = Array.from(Array(record.count).keys()).map(drill => {
						return {
							title: record.name,
							type: record.name,
							buildingName: buildingName,
							year: selectedSchoolYear.value,
							date: 'No date',
							status: 'not_scheduled'
						}
					})
					unscheduledBuildingDrills = [...unscheduledBuildingDrills, ...unscheduledBuildingDrillsSingleType]
				}
			}
		}
		return unscheduledBuildingDrills
	}

	useEffect(() => {
		getDrills();
	}, [filter, selectedSite, selectedSchoolYear, selectedDrillType]);

	useFilter(filter, selectedDrillType, setFilteredData, drills);

	const selectSite = site => {
		setSelectedSite(site);

		const availablePermissions = {
			canPrint: 1,
		};

		if (checkPermission('drilllogs_log', permissions, site.id)) {
			availablePermissions.drilllogs_log = 1;
		}

		if (checkPermission('drilllogs_post', permissions, site.id)) {
			availablePermissions.drilllogs_post = 1;
		}

		if (checkPermission('drilllogs_unpost', permissions, site.id)) {
			availablePermissions.drilllogs_unpost = 1;
		}

		if (checkPermission('drilllogs_delete', permissions, site.id)) {
			availablePermissions.drilllogs_delete = 1;
		}

		setUserPermissions(availablePermissions);
	};

	return (
		<Wrapper>
			<MoreFiltersModal
				isVisible={showMoreFiltersModal}
				setIsVisible={setShowMoreFiltersModal}
				actualFilter={filter}
				onApply={updatedFilter => {
					setShowMoreFiltersModal(false);
					setFilter(updatedFilter);
				}}
			/>

			{showDrillDetailsModal && selectedDrill && (
				<DrillDetailsModal
					setModalToShow={setShowDrillDetailsModal}
					selectedDrill={selectedDrill}
					onDeleteDrill={removeHandler(selectedDrill)}
					onPostLogSuccess={() => getDrills()}
				/>
			)}

			{showViewDetailsModal && selectedDrill && (
				<ViewDrillDetails
					setModalToShow={setShowViewDetailsModal}
					selectedDrill={selectedDrill}
					// eslint-disable-next-line no-console
					onPrintDrillSuccess={() => console.log('Print')}
					onUnPostDrillSuccess={() => getDrills()}
				/>
			)}

			{/* site, school year, drill type filters */}
			<div className="dropdown-filters">
				{/* Site */}
				<SitesDropdown
					selectedSite={selectedSite}
					onChange={selectSite}
				/>
				{/* School year */}
				<SchoolYearDropdown
					selectedSchoolYear={selectedSchoolYear}
					onChange={item => setSelectedSchoolYear(item)}
				/>
				{/* Drill type */}
				<Dropdown
					label="Drill type"
					placeholder="Select a Drill type"
					value={selectedDrillType}
					options={drillTypes}
					onChange={item => setSelectedDrillType(item)}
				/>
				<div className="filter-button-container">
					<Button
						onClick={() => {
							setShowMoreFiltersModal(true);
						}}
						text="More filters"
						intent="tertiary"
						icon="filter"
					/>
				</div>
			</div>

			<div className="filters-chips-container">
				{(filter?.statusCompleted ||
					filter?.statusInProgress ||
					filter?.statusScheduled ||
					filter?.statusOverdue) && (
					<div>
						<p>Status filtered by:</p>
						<div className="status-chips-container">
							{filter?.statusCompleted && (
								<FilterChip text="Completed" />
							)}
							{filter?.statusInProgress && (
								<FilterChip text="In Progress" />
							)}
							{filter?.statusScheduled && (
								<FilterChip text="Scheduled" />
							)}
							{filter?.statusOverdue && (
								<FilterChip text="Overdue" />
							)}
							{filter?.statusNotScheduled && (
								<FilterChip text="Not Scheduled" />
							)}
						</div>
					</div>
				)}
				<div className="date-chips-container">
					{(filter?.scheduledDrillDateFrom ||
						filter?.scheduledDrillDateTo) && (
						<FilterChip
							icon={cancel}
							text={getChipText(
								'Scheduled drill date:',
								filter?.scheduledDrillDateFrom,
								filter?.scheduledDrillDateTo,
							)}
							iconAction={() => {
								clearDatesFilterValue(
									'scheduledDrillDateFrom',
									'scheduledDrillDateTo',
								);
							}}
						/>
					)}
					{(filter?.actualDrillDateFrom ||
						filter?.actualDrillDateTo) && (
						<FilterChip
							icon={cancel}
							text={getChipText(
								'Actual drill date:',
								filter?.actualDrillDateFrom,
								filter?.actualDrillDateTo,
							)}
							iconAction={() => {
								clearDatesFilterValue(
									'actualDrillDateFrom',
									'actualDrillDateTo',
								);
							}}
						/>
					)}
					{(filter?.datePostedFrom || filter?.datePostedTo) && (
						<FilterChip
							icon={cancel}
							text={getChipText(
								'Date posted:',
								filter?.datePostedFrom,
								filter?.datePostedTo,
							)}
							iconAction={() => {
								clearDatesFilterValue(
									'datePostedFrom',
									'datePostedTo',
								);
							}}
						/>
					)}
				</div>
			</div>

			<div className="d-flex justify-content-between align-items-center actions">
				{filteredData ? (
					<h4>{`${filteredData.length} drill(s) listed`}</h4>
				) : (
					<h4>Please use the filters to find out drills</h4>
				)}
				<div>
					<Button
						onClick={() => printListedDrills()}
						text="Print listed drills"
						intent="tertiary"
						icon="print"
					/>
					<Button
						onClick={() => downloadDrillsTableAsCSV('drills-table')}
						text="Download Listed Drills"
						intent="tertiary"
						icon="download"
					/>
				</div>
			</div>
			{/* drills table */}
			<Table
				id="drills-table"
				class="drills-table"
				headers={[
					'ID',
					'Status',
					'Drill type',
					'Site',
					'School year',
					'Scheduled date',
					'Actual drill date',
					'Date posted',
					'Actions',
				]}
				headersStyles={[
					{ width: '50px' },
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
				]}
				renderItem={renderItem}
				hidePagination
				data={filteredData}
			/>
		</Wrapper>
	);
};

SearchDrills.propTypes = {
	permissions: PropTypes.object,
};

export { SearchDrills };
