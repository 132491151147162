import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Dialog, Icon, MenuItem } from '@blueprintjs/core';
import { Input } from 'components/Input';
import { LabelText } from 'components/Forms/shared.js';
import { Select } from '@blueprintjs/select';
import AnswerOptions from './answeroptions';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

const DropdownWrap = styled.div`
	padding: 10px;
	width: 100%;
	background: #ffffff;
	border: 1px solid #d8e1e8;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
`;

const SelectWrap = styled(Select)`
	display: block;
	width: 100%;
	.bp3-popover-target {
		display: block;
		width: 100%;
	}
`;

const questionTypes = [
	{
		id: 1,
		type: 'yn',
		value: 'Yes / No',
	},
	{
		id: 2,
		type: 'yn',
		value: 'Yes / No / NA',
	},
	{
		id: 3,
		type: 'yn',
		value: 'Yes / No / NA / NAS',
	},
	{
		id: 4,
		type: 'yn',
		value: 'Yes / No / NA / NS',
	},
	{
		id: 5,
		type: 'area',
		value: 'Text Area',
	},
	{
		id: 6,
		type: 'select',
		value: 'Custom Select (Single Answer)',
	},
	{
		id: 7,
		type: 'multiple',
		value: 'Multiple Select (Multiple Answers)',
	},
];

class EditQuestionModal extends React.Component {
	constructor(props) {
		super(props);
		const { item } = this.props;
		const temp = JSON.parse(item.properties);
		this.state = {
			question: item.title,
			hint: item.hint ? item.hint : '',
			answerType: temp.answerType,
			answerOptions: temp.answerOptions,
			questionId: item.id,
		};
	}

	makeTypes = item => (
		<MenuItem
			key={item.id}
			onClick={() => this.setState({ answerType: item })}
			text={item.value}
		/>
	);

	addOption = () => {
		const { answerOptions, answerType } = this.state;
		if (answerType.id === 6) {
			if (answerOptions.length === 0) {
				answerOptions.push({ id: 1, value: '' });
				return this.setState({ answerOptions });
			}
			return null;
		}

		let lastId;
		if (answerOptions.length > 0) {
			lastId = answerOptions[answerOptions.length - 1].id + 1;
		} else {
			lastId = 1;
		}
		answerOptions.push({ id: lastId, value: '' });
		return this.setState({ answerOptions });
	};

	updateOptionValue = (value, key) => {
		const { answerOptions } = this.state;
		answerOptions[key].value = value;
		return this.setState({ answerOptions });
	};

	removeOption = key => {
		const { answerOptions } = this.state;
		answerOptions.splice(key, 1);
		return this.setState({ answerOptions });
	};

	updateQuesiton = () => {
		const { templateId, getData, sectionId } = this.props;
		const {
			question,
			hint,
			answerType,
			answerOptions,
			questionId,
		} = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const properties = {
			type: null,
		};
		properties.type = answerType.type;
		if (answerType.id < 5) {
			properties.options = answerType.value.split('/');
		}
		if (answerType.id > 5) {
			properties.options = [];
			answerOptions.forEach(item => properties.options.push(item.value));
		}
		fetch(
			`${
				process.env.API_URL
			}/templates/${templateId}/questions/${questionId}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					title: question,
					hint,
					sectionId,
					properties: {
						answerType,
						answerOptions,
					},
				}),
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					return getData();
				}
				return null;
			});
	};

	render() {
		const { isOpen, closeEditQuestion } = this.props;
		const { question, hint, answerType, answerOptions } = this.state;
		return (
			<Dialog isOpen={isOpen} onClose={closeEditQuestion}>
				<div>
					<TitleWrap>
						<Title>Edit Question</Title>
						<Button
							text=""
							minimal
							icon="cross"
							onClick={closeEditQuestion}
						/>
					</TitleWrap>
					<hr />
					<div className="px-2">
						<Input
							label="Question"
							placeholder="Enter your question"
							large
							className="mb-2"
							value={question}
							onChange={e =>
								this.setState({ question: e.target.value })
							}
						/>
						<Input
							label="Hint"
							placeholder="Enter your hint"
							large
							className="mb-2"
							value={hint}
							onChange={e =>
								this.setState({ hint: e.target.value })
							}
						/>

						<LabelText>Answer Type</LabelText>
						<div className="d-flex">
							<SelectWrap
								items={questionTypes}
								itemRenderer={this.makeTypes}
								className="w-100"
								filterable={false}
							>
								<DropdownWrap>
									<div>
										{answerType
											? answerType.value
											: 'Select'}
									</div>
									<Icon icon="caret-down" />
								</DropdownWrap>
							</SelectWrap>
						</div>
						{answerType && answerType.id > 5 && (
							<AnswerOptions
								answerType={answerType}
								answerOptions={answerOptions}
								addOption={this.addOption}
								updateOptionValue={this.updateOptionValue}
								removeOption={this.removeOption}
							/>
						)}
					</div>
					<hr />
					<div className="d-flex justify-content-end mt-2 px-2">
						<Button
							text="Cancel"
							large
							intent="secondary"
							onClick={closeEditQuestion}
						/>
						<Button
							className="ml-2"
							text="Save"
							large
							intent="primary"
							onClick={this.updateQuesiton}
						/>
					</div>
				</div>
			</Dialog>
		);
	}
}

EditQuestionModal.propTypes = {
	isOpen: PropTypes.bool,
	closeEditQuestion: PropTypes.func,
	templateId: PropTypes.any,
	sectionId: PropTypes.any,
	getData: PropTypes.func,
	item: PropTypes.object,
};

export default EditQuestionModal;
