import React from 'react';
import { fileIconsList } from 'componentsV2';
import { ShapeContentItem, NoShapeContentState } from './';
import '../css/ShapeContent.scss';

export const PanoramasContent = ({
	panoramas,
	contentType,
	shapeType,
	shapeId,
	siteId,
	editPermission,
	floorplanArchived,
	isSearchedContent,
	loadContentData,
	isEditMode,
	viewPermissionOnSiteId,
	floorplan,
}) => {
	return (
		<div className="area-content-items-container">
			{panoramas.length > 0 ? (
				panoramas.map(panorama => (
					<ShapeContentItem
						item={panorama}
						id={panorama.id}
						ext={panorama.mimetype}
						thumbnail={panorama.thumbnail}
						contentTitle={panorama.contentTitle}
						createdBy={panorama.createdBy}
						createdDate={panorama.createdDate}
						contentType={contentType}
						siteId={siteId}
						shapeId={shapeId}
						shapeType={shapeType}
						editPermission={editPermission}
						floorplanArchived={floorplanArchived}
						loadContentData={loadContentData}
						isEditMode={isEditMode}
						viewPermissionOnSiteId={viewPermissionOnSiteId}
						floorplan={floorplan}
					/>
				))
			) : (
				<NoShapeContentState
					icon={fileIconsList['map']}
					header={
						isSearchedContent ? 'No content found' : 'No 360 images currently added.'
					}
					description={
						isSearchedContent
							? `Your search didn’t match any 360-images. Please try again.`
							: editPermission && !floorplanArchived && isEditMode
							? 'Click the + button to add a 360 image.'
							: ''
					}
				/>
			)}
		</div>
	);
};
