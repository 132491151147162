import React, { useEffect, useReducer } from 'react';
import { ConfirmationModal } from 'componentsV2';
import { ALARM_MODES } from '../consts';
export const CancelActivationModal = ({ onCancelAction, closeModalAction, mode, setShowModal }) => {
	const initialState = {
		title: ' ',
		subtitle: ' ',
		confirmBtnTitle: ' ',
		cancelBtnTitle: ' ',
	};
	const modalDetailsReducer = (modalDetails, action) => {
		modalDetails = action.payload;
		return {
			title: modalDetails.title,
			subtitle: modalDetails.subtitle,
			confirmBtnTitle: modalDetails.confirmBtnTitle,
			cancelBtnTitle: modalDetails.cancelBtnTitle,
		};
	};
	const [modalDetails, dispatchModalDetails] = useReducer(modalDetailsReducer, initialState);

	useEffect(() => {
		if (mode == ALARM_MODES.alarm) {
			dispatchModalDetails({
				payload: {
					...modalDetails,
					title: 'Cancel alarm activation?',
					subtitle: 'Are you sure you want to cancel activating an alarm?',
					confirmBtnTitle: 'Cancel activation',
					cancelBtnTitle: 'Continue activation',
				},
			});
		} else {
			dispatchModalDetails({
				payload: {
					...modalDetails,
					title: 'Cancel alarm practice?',
					subtitle: 'Are you sure you want to cancel practicing an alarm?',
					confirmBtnTitle: 'Cancel practice',
					cancelBtnTitle: 'Continue practice',
				},
			});
		}
	}, []);

	return (
		<ConfirmationModal
			isOpen={true}
			title={modalDetails.title}
			subtitle={modalDetails.subtitle}
			icon={{ icon: 'warning-sign', iconColor: 'warning' }}
			showCloseButton={false}
			confirmBtn={{
				title: modalDetails.confirmBtnTitle,
				onClick: closeModalAction,
			}}
			cancelBtn={{
				title: modalDetails.cancelBtnTitle,
				onClick: onCancelAction,
			}}
		/>
	);
};
