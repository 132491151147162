import React from 'react';
import './css/ExistingContactsTable.scss';
import { Icon } from '@blueprintjs/core';
import { ExistingContactFilteration } from './';
import {
	BootstrapedTable,
	sortingStyle,
	Callout,
	EmptyState,
	noResultFoundIcon,
	emptyDataIcon,
	FeaturedIcon,
} from 'componentsV2';
import ContactTooltip from './ContactTooltip';

export const ExistingContactsTable = ({
	contactsList,
	selectRow,
	selectedRows,
	setSelectedRows,
	selectedCallList,
	selectedSites,
	deleteTableRows,
	dispatchFilterationData,
	filterationData,
	totalRows,
}) => {
	const disabledRowCondition = row => {
		return row.isLinkedToCallList;
	};

	const columns = [
		{
			key: 'familyName',
			dataField: 'familyName',
			text: 'Name',
			formatter: (value, row) => {
				const rowData =
					(row.familyName ? row.familyName : '') +
					(row.familyName != ' ' &&
					row.familyName != '' &&
					row.givenName != ' ' &&
					row.givenName != ''
						? ', '
						: '') +
					(row.givenName ? row.givenName : '');

				return row.isLinkedToCallList ? <ContactTooltip contact={rowData} /> : rowData;
			},
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'title',
			dataField: 'title',
			headerStyle: { width: '16%' },
			text: 'Title',
			sort: true,
			formatter: (value, row) =>
				row.isLinkedToCallList ? <ContactTooltip contact={row.title} /> : row.title,
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'accessTypeName',
			dataField: 'accessTypeName',
			text: 'Account type',
			sort: true,
			formatter: (value, row) =>
				row.isLinkedToCallList ? (
					<ContactTooltip contact={row.accessTypeName} />
				) : (
					row.accessTypeName
				),
			sortCaret: (order, column) => sortingStyle(order, column),
		},
	];

	const onScrollContactsList = () => {
		let myContactsList = document.getElementById('my-contacts-list');
		myContactsList.addEventListener('scroll', debouncedScrollHandler());
	};
	const unselect = () => {
		setSelectedRows([]);
	};
	const debouncedScrollHandler = _.debounce(async () => {
		let myContactsList = document.getElementById('my-contacts-list');

		if (
			Math.round(myContactsList.offsetHeight) + Math.round(myContactsList.scrollTop) >=
			myContactsList.scrollHeight
		) {
			if (contactsList.length < totalRows) {
				filterationData.page += 1;
				dispatchFilterationData(oldData => ({
					...oldData,
					page: filterationData.page + 1,
				}));
			}
		}
	}, 300);

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = sortField;
			switch (sortField) {
				case 'familyName':
					sortColumn = 'name';
					break;
				case 'title':
					sortColumn = 'staffTitle';
					break;
				case 'accessTypeName':
					sortColumn = 'accessType';
					break;
			}

			dispatchFilterationData({ type: 'sortKey', payload: sortColumn });
			dispatchFilterationData({ type: 'sortDir', payload: sortOrder });
		}
	};
	const icon = <FeaturedIcon icon={'error'} type={'Primary'} size={'md'} shape={'circleIcon'} />;
	return (
		<div className="existingContainer row">
			<div className="col-md-8 tableWrapper">
				<p className="ExistingContactHeaderText">
					To: {selectedCallList.label} in {selectedSites[0]['label']}
				</p>
				<ExistingContactFilteration
					filterationData={filterationData}
					dispatchFilterationData={dispatchFilterationData}
					selectedRows={selectedRows}
					unselect={unselect}
				/>
				<>
					{contactsList.length == 0 ? (
						<div className="noContactsEmptyState empty-contacts-container">
							<EmptyState
								icon={noResultFoundIcon}
								className="mt-3"
								showCircles={false}
								header="No contacts found"
								description="Your search didn't match any contacts."
							/>
						</div>
					) : (
						<div
							onScroll={onScrollContactsList}
							className="existingContacts-table-container"
							id="my-contacts-list"
						>
							<BootstrapedTable
								hasCheckbox={true}
								keyField="id"
								data={contactsList}
								columns={columns}
								bordered={false}
								onTableChange={onTableChange}
								customSelectRow={selectRow}
								currentPage={filterationData.page}
								onSelectRows={setSelectedRows}
								selectedRows={selectedRows}
								disabledRowCondition={disabledRowCondition}
								showPagination={false}
								className={'existingContactTable'}
							/>
						</div>
					)}
				</>
			</div>

			<div className="col-md-4 colRightWrapper">
				{selectedRows.length > 0 && (
					<p className="added-contact-title">
						<strong>Contacts to add</strong>
					</p>
				)}
				{selectedRows.length == 0 ? (
					<div className="buttons-holder">
						<img className="attachment-img" src={emptyDataIcon} alt="" />
						<p className="noContactsAdded_title">No contacts have been added yet</p>
						<p> Selected contacts will appear here.</p>
					</div>
				) : (
					<div className="gridContainer">
						<Callout
							icon={icon}
							type="primary"
							text={`${selectedRows.length +
								(selectedRows.length == 1
									? ' contact '
									: ' contacts ')} to be added`}
							className="Callout_Primary"
						/>
						{selectedRows.map((row, index) => {
							return (
								<div className="rightTable" key={`${index}${row.id}`}>
									<div className="left">
										<div className="text-wrapper">
											<p className="name_text">
												{(row.familyName ? row.familyName : '') +
													(row.familyName != ' ' &&
													row.familyName != '' &&
													row.givenName != ' ' &&
													row.givenName != ''
														? ', '
														: '') +
													(row.givenName ? row.givenName : '')}
											</p>
											<span className="contactSupportText">
												{(row.title ? row.title : '') +
													(row.accessTypeName
														? (row.title && row.title?.trim() !== ''
																? ', '
																: '') + row.accessTypeName
														: '')}
											</span>
										</div>
									</div>
									<div className="right">
										<Icon
											className="custom-icon"
											onClick={() => deleteTableRows(index, row.id)}
											icon="remove"
										/>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};
