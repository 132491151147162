import React, { useState } from 'react';
import { Button, Badge } from 'componentsV2';
import { EditLegendsModal } from './EditLegendsModal';

export const Legends = ({
	getLegends,
	allLegendList,
	legendListWithDescription,
	editPermissionOnDistrict,
  isCapture,
}) => {
	const [showEditLegendsModal, setShowEditLegendsModal] = useState(false);

	const handleEditLegendModal = () => {
		setShowEditLegendsModal(true);
	};

	const LegendHasUpdate = () => {
		getLegends(true);
	};

	return (
		<>
			{allLegendList && (
				<div className="legend">
					{legendListWithDescription.map(legend => (
						<Badge
							key={legend.id}
							dotIconColor={'#' + legend.hex}
							type="Gray"
							size="md"
							text={legend.description}
							hasLeadIcon={true}
							hasCloseIcon={false}
						/>
					))}
					{editPermissionOnDistrict && !isCapture && (
						<Button
							disabled={false}
							small
							icon="annotation"
							size="sm"
							type={'tertiary'}
							intent="default"
							onClick={() => {
								handleEditLegendModal();
							}}
						/>
					)}
					{
						<EditLegendsModal
							showEditLegendsModal={showEditLegendsModal}
							setShowEditLegendsModal={setShowEditLegendsModal}
							legendList={allLegendList}
							LegendHasUpdate={LegendHasUpdate}
						/>
					}
				</div>
			)}
		</>
	);
};
