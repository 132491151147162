import React, { useState, useEffect, useContext } from "react";
import { Button, Tooltip } from "componentsV2";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { checkPermission } from "utilities/permissions";
import { DocumentModalsContext } from "./DocumentModalsProvider";
import { decodeToken } from 'utilities';

export const BulkActions = ({
    selectedDocuments
}) => {
    const permissions = useSelector( state => state.route.permissions);
    const isVisible = checkPermission('document_edit', permissions);
    const [isEnabled, setIsEnabled] = useState(true);
    const [attachDisabled, setAttachDisabled] = useState(false);
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    const dist = token ? decodeToken (token) : '';
    const districtId = dist ? dist.dist : null;

    const {
        showMultipleDownloadModal,
        showMoveModal,
        showAttachToBinderModal,
        showMultipleDeleteModal,
    } = useContext(DocumentModalsContext);

    useEffect(() => {
        setPermissionVars();
        checkAttachDisabled();
    }, [selectedDocuments])

    const setPermissionVars = () => {
        for (const selectedDocument of selectedDocuments) {
            const hasEditPermission = checkPermission('document_edit', permissions, selectedDocument.buildingId? selectedDocument.buildingId : districtId);

            if (! hasEditPermission) {
                setIsEnabled(false);
                return;
            }
        }

        setIsEnabled(true);
    }
    
    const checkAttachDisabled = () => {
        var buildingIds = [];

        for (const selectedDocument of selectedDocuments) {
           if (selectedDocument.type == '360image') {
                setAttachDisabled(true);
                return;
           }

           buildingIds.push(selectedDocument.buildingId);
        }

        if ([...new Set(buildingIds)].length > 1) {
            setAttachDisabled(true);
            return;
        }

        setAttachDisabled(false);
    }

    return (
        <section className="document-actions">
             {isVisible && isEnabled && !attachDisabled && (
                <Button
                    key='attach'
                    text="Attach to binder"
                    type="tertiary"
                    size="sm"
                    icon="document-open"
                    onClick={() => showAttachToBinderModal(selectedDocuments)}
                />
            )}

            {isVisible && (!isEnabled || attachDisabled) && (
                 <Tooltip
                    tooltipTitle={"Selected documents belongs to different sites or to sites you don’t have permission on or 360 image"}
                    theme={'light'}
                >
                    <Button
                        key='attach'
                        text="Attach to binder"
                        type="tertiary"
                        size="sm"
                        icon="document-open"
                        onClick={() => showAttachToBinderModal(selectedDocuments)}
                        disabled={true}
                    />
                </Tooltip>
            )}

            {isVisible && isEnabled && (
                <Button
                    key='move'
                    text="Move"
                    type="tertiary"
                    size="sm"
                    icon="move"
                    onClick={() => showMoveModal(selectedDocuments)}
                />
            )}

            {isVisible && !isEnabled && (
                <Tooltip
                    tooltipTitle={"Selected documents belongs to sites you don’t have permission on"}
                    theme={'light'}
                >
                    <Button
                        key='move'
                        text="Move"
                        type="tertiary"
                        size="sm"
                        icon="move"
                        onClick={() => {}}
                        disabled={true}
                    />
                </Tooltip>
            )}
            
            <Button
                key='download'
                text="Download"
                type="tertiary"
                size="sm"
                icon="cloud-download"
                onClick={() => showMultipleDownloadModal(selectedDocuments)}
            />

            {isVisible && isEnabled && (
                <Button
                    key='delete'
                    text="Delete"
                    type="tertiary"
                    size="sm"
                    icon="trash"
                    onClick={() => showMultipleDeleteModal(selectedDocuments)}
                />
            )}

            {isVisible && !isEnabled && (
                <Tooltip
                    tooltipTitle={"Selected documents belongs to sites you don’t have permission on"}
                    theme={'light'}
                >
                    <Button
                        key='delete'
                        text="Delete"
                        type="tertiary"
                        size="sm"
                        icon="trash"
                        onClick={() => {}}
                        disabled={true}
                    />
                </Tooltip>
            )}
            
        </section>
    );
}

BulkActions.propTypes = {
	selectedDocuments: PropTypes.array.isRequired,
};