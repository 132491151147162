import React, { useContext } from 'react';
import { Button } from 'componentsV2';
import { CallListContext } from '../Providers';

export const CallListCreateBtn = () => {
	const { editPermission, addNewCallList } = useContext(CallListContext);
	if (editPermission) {
		return (
			<Button
				text="New list"
				icon="plus"
				size="lg"
				type="primaryDefault"
				onClick={() => addNewCallList()}
			/>
		);
	}
	return null;
};
