import React from 'react';
import { Input } from 'componentsV2';
import { PHONE_NUMBER_MASK, PHONE_NUMBER_PLACEHOLDER } from 'utilities/constants';
import { Helper } from 'utilities/helper';
import './css/ManualFormStep.scss';
import { isValidEmail, isValidPhone, isNotEmpty } from 'utilities/validations';

export const ManualFormStep = ({
	setFormData,
	formData,
	validationErrors,
	getGuardiansList,
	onChangeModalDetails,
}) => {
	let currentFormData = [];
	return (
		<div className="ManualForm__Wrapper">
			<div className="row">
				<div className="col-md-12 inputCol">
					<Input
						placeholder="Enter full name"
						name="name"
						label={'Full Name'}
						isRequired={true}
						error={!!validationErrors.name}
						errorMessage={validationErrors.name}
						labelState={true}
						value={formData.name}
						onChange={e => {
							let inputVal = e.target.value;
							currentFormData.push({ name: inputVal });
							if (inputVal && inputVal.trim() != '') {
								onChangeModalDetails({
									disabled: false,
									onConfirmHandler: () =>
										getGuardiansList(currentFormData, false),
								});
							} else {
								inputVal = '';
							}
							setFormData({ ...formData, name: inputVal });
						}}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12 inputCol">
					<Input
						label={'Mobile Phone'}
						isRequired={false}
						placeholder={PHONE_NUMBER_PLACEHOLDER}
						name="mobilePhone"
						error={!!validationErrors.mobilePhone}
						errorMessage={validationErrors.mobilePhone}
						labelState={false}
						value={formData.mobilePhone}
						mask={PHONE_NUMBER_MASK}
						onChange={e => {
							const input = Helper.formatPhoneToDigits(e.target.value);
							if (isValidPhone(input)) {
								validationErrors.mobilePhone = '';
							}
							currentFormData.push({ mobilePhone: input });
							setFormData({ ...formData, mobilePhone: input });
							onChangeModalDetails({
								onConfirmHandler: () => getGuardiansList(currentFormData, false),
							});
						}}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12 inputCol">
					<Input
						name="email"
						label="Email"
						value={formData.email}
						error={!!validationErrors.email}
						errorMessage={validationErrors.email}
						isRequired={false}
						labelState={false}
						placeholder="Enter email address"
						onChange={e => {
							currentFormData.push({ email: e.target.value });
							if (isValidEmail(e.target.value)) {
								validationErrors.email = '';
							}
							setFormData({ ...formData, email: e.target.value });
							onChangeModalDetails({
								onConfirmHandler: () => getGuardiansList(formData, false),
							});
						}}
					/>
				</div>
			</div>
		</div>
	);
};
