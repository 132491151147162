import React, { useState, useEffect } from 'react';
import { PageHeader, Button, TableCell, LoadingSpinner, BootstrapedTable } from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import './TestConnection.scss';
import { formatDate } from '../../../../../utilities/dates';
import { RapidAlarmIntegrationsService } from 'services';

export const TestConection = ({ location }) => {
	const partnerId = location?.state?.data;
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [connectionResponse, setConnectionResponse] = useState(null);
	const [tableData, setTableData] = useState('');
	const breadcrumbItems = [
		{ label: 'Rapid Alarm', url: '/rapid-alarms' },
		{ label: 'Settings', url: '/rapid-alarms/settings' },
		{ label: 'Test connection' },
	];

	useEffect(() => {
		setLoading(true);
		getPreviousAlarmData();
	}, []);

	const getPreviousAlarmData = () => {
		RapidAlarmIntegrationsService.getTestConnectionData(partnerId)
			.then(res => {
				setLoading(false);
				setTableData([
					{
						psap: res?.data?.status ? 'Good' : 'Poor',
						date: formatDate(res?.data?.time, 'MM/dd/yyyy'),
						time: new Date(res?.data?.time).toLocaleTimeString([], {
							hour12: true,
							hour: '2-digit',
							minute: '2-digit',
						}),
					},
				]);
			})
			.catch(err => console.log('err-', err));
	};

	const columns = [
		{
			key: 'test-connection',
			text: 'ID',
			hidden: true,
			style: { fontWeight: 'bold' },
		},
		{
			key: 'psapConnection',
			dataField: 'psap',
			text: 'PSAP connection',
			style: { fontWeight: 'bold', fontFamily: 'Nunito Sans' },
			formatter: (value, row) => (
				<TableCell
					text={value}
					leftIcon={
						<Icon
							icon={value === 'Good' ? 'tick-circle' : 'warning-sign'}
							intent={value === 'Good' ? 'success' : 'warning'}
						/>
					}
				/>
			),
		},
		{
			key: 'date',
			dataField: 'date',
			text: 'Date',
			style: { fontFamily: 'Nunito Sans', fontWeight: 'bold' },
		},
		{
			key: 'time',
			dataField: 'time',
			text: 'Time',
		},
	];

	const onHandleTestConnection = () => {
		setIsLoading(true);
		RapidAlarmIntegrationsService.testAlarmConnection(partnerId)
			.then(res => {
				setIsLoading(false);
				setConnectionResponse(`${res?.data?.status}`);
				setTableData([
					{
						psap: res?.data?.status ? 'Good' : 'Poor',
						date: formatDate(res?.data?.time, 'MM/dd/yyyy'),
						time: new Date(res?.data?.time).toLocaleTimeString([], {
							hour12: true,
							hour: '2-digit',
							minute: '2-digit',
						}),
					},
				]);
			})
			.catch(err => console.log('POST err-', err));
	};

	return (
		<div>
			<PageHeader
				title="Test connection"
				subtitle="Test your connection with emergency services."
				breadcrumbItems={breadcrumbItems}
			/>
			<div className="test-connection-main-container">
				<div className="title-cellular-main-container">
					<span className="title-text-container">911Cellular</span>
					<span className="title-sub-container">
						Test your connection with the Public Safety Answering Point (PSAP)
					</span>
					<div>
						<Button
							type="primaryDefault"
							icon="changes"
							text="Test Connection"
							size="md"
							disabled={isLoading}
							onClick={onHandleTestConnection}
						/>
					</div>
				</div>
				<div className="connection-result-container">
					{!isLoading && !connectionResponse && (
						<div className="disabled-container">
							<Icon icon="circle" intent="#E4E7E7" size="50" />
							<span className="connection-result-text">Testing in progress...</span>
						</div>
					)}
					{isLoading && <LoadingSpinner message="Testing in progress..." />}

					{!isLoading && connectionResponse && (
						<>
							<Icon
								icon={
									connectionResponse === 'true' ? 'tick-circle' : 'warning-sign'
								}
								intent={connectionResponse === 'true' ? 'success' : 'warning'}
								size="50"
							/>
							<span className="connection-result-text">
								{connectionResponse === 'true'
									? ' Your connection was successful'
									: 'Connection failed. Please check your authentication token.'}
							</span>
						</>
					)}
				</div>
			</div>
			<div>{loading && <LoadingSpinner />}</div>
			{!loading && (
				<div className="table-main-container">
					<div className="table-title">
						<h3 className="body-md-bold">Last tested connection</h3>
					</div>
					<BootstrapedTable
						keyField="test-connection"
						columns={columns}
						showPagination={false}
						data={tableData}
					/>
				</div>
			)}
		</div>
	);
};
