import React, { useState, useEffect } from "react";
import {
    AssesmentIcon,
    CallListIcon,
    DocumentIcon,
    MapIcon,
    SafetyPlanIcon
} from "./icons";
import { Dropdown } from "components/Dropdown";
import { Modal } from "../Modal";
import { TabPreviewList } from "./TabPreviewList";
import { InputColor, DefaultColors } from "../Colors";
import { Button } from "../Button";

export const TabPreviewListPattern = () => {

    const [showTabPreview, setShowTabPreview] = useState(false);
    const [tabPreviewType, setTabPreviewType] = useState(null);
    const [colors, setColors] = useState({
        primaryColor: null,
        secondaryColor: null
    })
    const [textColors, setTextColors] = useState({
        odd: null,
        even: null
    });
    const [backgroundColors, setBackgroundColors] = useState({
        odd: null,
        even: null
    });
    
    const htmlContent = (
        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, sed. At saepe iusto vel soluta. Aliquid id, commodi quidem odit iusto dolor itaque ipsam in illum dolore iure at voluptatibus.
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto, vitae eveniet excepturi, cupiditate vero ducimus blanditiis ullam aut debitis corporis ex? Rem commodi architecto illo ullam pariatur nisi, repellat fuga.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, provident magni maxime ad sapiente quasi suscipit atque ratione nostrum sunt sed, modi labore consequatur iste cupiditate. Voluptatum non dignissimos ipsum?
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum alias aliquid mollitia quibusdam, distinctio dolorum placeat earum facilis aperiam architecto perferendis. Cum delectus iste harum obcaecati earum saepe maiores odit?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, aspernatur illo velit incidunt dolorem, alias ratione inventore esse similique perferendis harum voluptatum modi quae voluptates! Deserunt amet culpa pariatur ad.
        </p>
    );

    const tabPreviewTypes = [
        {
            label: 'solid',
            value: 'solid'
        },
        {
            label: 'stripe',
            value: 'stripe'
        }
    ];

    useEffect(() => {
        switch (tabPreviewType?.value) {
            case 'solid':
                setTextColors({
                    odd: colors?.primaryColor === null ? null :  'white',
                    even: colors?.primaryColor === null ? null :  'white'
                });
                setBackgroundColors({
                    odd: colors?.primaryColor === null ? null : colors?.primaryColor,
                    even: colors?.primaryColor === null ? null : colors?.primaryColor
                });
            break;
            case 'stripe':
                setTextColors({
                    odd: colors?.primaryColor === null ? null :  'white',
                    even: colors?.secondaryColor === null ? null :  'white'
                });
                setBackgroundColors({
                    odd: colors?.primaryColor === null ? null : colors?.primaryColor,
                    even: colors?.secondaryColor === null ? null : colors?.secondaryColor
                });
            break;
        }
    }, [tabPreviewType?.value, colors]);

    const tabsList = [
        {
            id: 1,
            title: 'Map',
            supportText: 'Support text',
            content: htmlContent,
            icon: MapIcon,
            textColor: textColors.odd,
            backgroundColor: backgroundColors.odd
        },
        {
            id: 2,
            title: 'Document',
            supportText: 'Support text',
            content: htmlContent,
            icon: DocumentIcon,
            textColor: textColors.even,
            backgroundColor: backgroundColors.even
        },
        {
            id: 3,
            title: 'SafetyPlan',
            supportText: 'Support text',
            content: htmlContent,
            icon: SafetyPlanIcon,
            textColor: textColors.odd,
            backgroundColor: backgroundColors.odd
        },
        {
            id: 4,
            title: 'Call List',
            supportText: 'Support text',
            content: htmlContent,
            icon: CallListIcon,
            textColor: textColors.even,
            backgroundColor: backgroundColors.even
        },
        {
            id: 5,
            title: 'Assesment',
            supportText: 'Support text',
            content: htmlContent,
            icon: AssesmentIcon,
            textColor: textColors.odd,
            backgroundColor: backgroundColors.odd
        },
        {
            id: 6,
            title: 'Document',
            supportText: 'Support text',
            content: htmlContent,
            icon: DocumentIcon,
            textColor: textColors.even,
            backgroundColor: backgroundColors.even
        },
        {
            id: 7,
            title: 'Call List',
            supportText: 'Support text',
            content: htmlContent,
            icon: CallListIcon,
            textColor: textColors.odd,
            backgroundColor: backgroundColors.odd
        },
        {
            id: 8,
            title: 'Assesment',
            supportText: 'Support text',
            content: htmlContent,
            icon: AssesmentIcon,
            textColor: textColors.even,
            backgroundColor: backgroundColors.even
        },
    ];

    const setPrimaryColor = value => {
        setColors(oldColors => ({
            ...oldColors,
            'primaryColor': value,   
        }))
    }

    const setSecondaryColor = value => {
        setColors(oldColors => ({
            ...oldColors,
            'secondaryColor': value,   
        }))
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            {/* TODO Replace old components (input, dropdown & checkbox) */}
                            <div className="col-md-12">
                                <Dropdown
                                    className="mt-3"
                                    label="Preview type"
                                    placeholder="Select Preview type"
                                    value={tabPreviewType}
                                    options={tabPreviewTypes}
                                    onChange={value => setTabPreviewType(value)}
                                />
                            </div>
                            {(tabPreviewType?.value === 'solid' || tabPreviewType?.value === 'stripe') && (
                                <div className="col-md-12 mt-1">
                                    <InputColor 
                                        label="Primary color"
                                        name="primary-color"
                                        presetColors={DefaultColors}
                                        isRequired={true}
                                        setValue={setPrimaryColor}
                                    />
                                </div>
                            )}
                            {tabPreviewType?.value === 'stripe' && (
                                <div className="col-md-12 mt-1">
                                    <InputColor 
                                        label="Secondary color"
                                        name="secondary-color"
                                        presetColors={DefaultColors}
                                        isRequired={true}
                                        setValue={setSecondaryColor}
                                    />
                                </div>
                            )}
                            <div className="col-md-6 mt-3">
                                <Button
                                    onClick={() => setShowTabPreview(true)}
                                    type="primaryDefault"
                                    text="Show tab preview"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12"> 
                <Modal
                    isOpen={showTabPreview}
                    onClose={() => setShowTabPreview(false)}
                    title="Tab Preview"
                    size='small'
                    showCloseButton={true}
                >
                    <TabPreviewList 
                        list={tabsList}
                    />
                </Modal>
            </div>
        </div>
    )
}