import React, { useEffect, useState, useRef, useContext } from 'react';
import { AlertModal, Button, FeaturedIcon, ToasterMessage } from 'componentsV2';
import { ReunificationService } from 'services';
import { ToasterContext } from 'pages/App';
import { DEFULT_ADD_ANOTHER_STUDENT_MODAL } from '../../consts';
import { Intent } from '@blueprintjs/core';

export const DeleteStudentGuardianAssociationModal = ({
	setModalToshow,
	currentSelectedStudent,
	alarmId,
	guardianId,
}) => {
	const [modalLoad, setModalLoad] = useState(false);
	const toaster = useContext(ToasterContext);
	const handleDelete = () => {
		let body = {
			removeStudents: [
				{
					id: currentSelectedStudent.id,
				},
			],
		};
		setModalLoad(true);
		ReunificationService.updateGuardianDetails(alarmId, guardianId, body)
			.then(response => {
				if (response.statusCode === 200) {
					const message = (
						<ToasterMessage
							text={'Student deleted'}
							supportText={'The student has successfully been deleted.'}
						/>
					);
					toaster(
						message,
						Intent.NONE,
						<FeaturedIcon
							icon={'trash'}
							type={'Default'}
							size={'md'}
							shape={'circleIcon'}
						/>,
					);
					setModalToshow(DEFULT_ADD_ANOTHER_STUDENT_MODAL);
				}
				setModalLoad(false);
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			});
	};

	return (
		<AlertModal
			isOpen={true}
			loading={modalLoad}
			size="medium"
			title={'Are you sure you want to remove this student'}
			customClassName="map-container-modals"
			subtitle={
				'Once removed the student will be unassociated from the guardian info. This action is irreversible.'
			}
			showCloseButton={false}
			alertConfirmButton={
				<Button
					text={'Delete student'}
					type="primaryDanger"
					size="md"
					onClick={handleDelete}
				/>
			}
			alertCancelButton={
				<Button
					text="Go back"
					type="tertiary"
					size="md"
					onClick={() => {
						setModalToshow(DEFULT_ADD_ANOTHER_STUDENT_MODAL);
					}}
				/>
			}
		/>
	);
};
