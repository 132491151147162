import React, { useState, useRef, useContext } from 'react';
import { Icon, Spinner, Intent } from '@blueprintjs/core';
import { Button } from 'components/Button';
import xslIconImg from 'images/xsl-img.png';
import uploadErrorIcon from 'images/upload-error.png';
import { UsersService } from 'services/UsersService';
import { Callout } from '@blueprintjs/core';
import { ToasterContext } from '../../../App';

const Step1 = ({
	setDisableBtns,
	setChangesOccuredFlag,
	loading,
	setLoading,
	setFormData,
	setUploadText,
	uploadText,
	xslContentClass,
	setxslContentClass,
	setXslContent,
	xslImg,
	setXslImg,
	xslContent,
	setUploadLabel,
	uploadLabel,
}) => {
	const hiddenFileInput = useRef(null);
	const handleClick = event => {
		hiddenFileInput.current.click();
		//hiddenFileInput.current.reset();
	};
	const fileTypes = [
		'xls',
		'xlsx',
		'application/vnd.ms-excel',
		'text/csv',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	];
	const [uploadContainerClass, setUploadContainerClass] = useState('uploading-container-success');
	const context = useContext(ToasterContext);

	const handleFileChange = event => {
		setLoading(true);
		setUploadLabel('Loading...');
		event.preventDefault();
		const file = event.target.files[0];
		setChangesOccuredFlag(1);
		hiddenFileInput.current.value = '';

		//hiddenFileInput.current = null
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = e => {
			const { result } = e.target;
			setTimeout(() => {
				setLoading(false);
				setUploadLabel('Import a file');
				if (result) {
					if (!fileTypes.includes(file.type)) {
						setDisableBtns(true);
						setXslImg(uploadErrorIcon);
						setxslContentClass('font-upload');
						setXslContent('Error uploading file');
						setUploadText('Upload Excel file');
						setUploadContainerClass('uploading-container-error');
					} else {
						setxslContentClass('');
						setXslImg(xslIconImg);
						setXslContent(file.name);
						setUploadText('Replace file');
						setUploadContainerClass('uploading-container-success');
						setFormData(result);
						setDisableBtns(false);
					}
				}
			}, 800);
		};
	};
	const downloadTemplate = () => {
		setLoading(true);
		UsersService.downloadBulkUserTemplate()
			.then(blob => {
				setLoading(false);
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = 'template.xlsx';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				context('File downloaded successfully!', Intent.SUCCESS);
			})
			.catch(error => {
				setLoading(false);
				context('Failed Downloading file!', Intent.DANGER);
			});
	};

	return (
		<>
			<div className="bulk-user-container bootstrap-table-container">
				<Callout className="attachment-note" intent="primary">
					<p style={{ marginBottom: 0 }}>
						<b>
							Uploaded file should match the Excel template provided by Navigate360:{' '}
						</b>
						<a
							onClick={downloadTemplate}
							style={{ textDecoration: 'underline', color: '#0A5E92' }}
						>
							Download Template
						</a>
					</p>
				</Callout>
				<p className="font-bold attachment-import-font">{uploadLabel}</p>
				<div className={uploadContainerClass}>
					<div className="buttons-holder">
						{loading && <Spinner intent="primary" />}
						{!loading && (
							<>
								<img className="attachment-img" src={xslImg} alt="" />
								<p className={xslContentClass}>{xslContent}</p>
								<Button onClick={handleClick}>
									<Icon
										style={{ color: '#007E8F', paddingRight: '5px' }}
										icon="cloud-upload"
									/>
									<span style={{ color: '#007E8F', textAlign: 'center' }}>
										{uploadText}
									</span>
								</Button>
							</>
						)}
					</div>
				</div>
				<input
					type="file"
					accept=".xls,.xlsx"
					ref={hiddenFileInput}
					onChange={handleFileChange}
					style={{ display: 'none' }}
					id="attachInput"
				/>
			</div>
		</>
	);
};
export default Step1;
