import React from 'react';
import './css/InputWrapper.scss';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ElementWrapper = styled.div`
	width: ${props => props.width || '100%'};
`;

export const InputWrapper = ({
	label = null,
	labelState = true,
	isRequired,
	children,
	hasHintText = true,
	hintText = null,
	error,
	errorMessage,
	RightSideItem = null,
	className = '',
}) => {
	return (
		<>
			<div className={`InputWrapperContainer ${className}`}>
				{label && (
					<div className="LabelWrapper">
						<span className="LabelText">{label}</span>
						{RightSideItem && (
							<span className="LabelStateRightSide"> {RightSideItem} </span>
						)}
						{labelState &&
							(isRequired ? (
								<span className="LabelStateIcon">*</span>
							) : (
								<span className="LabelStateText">(Optional)</span>
							))}
					</div>
				)}
				{children}
				{error ? (
					<p className="errorText" dangerouslySetInnerHTML={{ __html: errorMessage }} />
				) : (
					' '
				)}

				{hasHintText ? <div className="HintText"> {hintText} </div> : ''}
			</div>
		</>
	);
};

export default InputWrapper;
export { ElementWrapper };

InputWrapper.propTypes = {
	label: PropTypes.string,
	labelState: PropTypes.bool,
	isRequired: PropTypes.bool,
	hasHintText: PropTypes.bool,
	hintText: PropTypes.string,
	error: PropTypes.bool,
	errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	RightSideItem: PropTypes.any,
	className: PropTypes.string,
};
