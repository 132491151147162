import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Toaster, Position, Intent } from '@blueprintjs/core';
import styled from 'styled-components';
import { SearchBar } from 'components/SearchBar';
import { HttpService } from 'networking/HttpService';
import { Avatar, ToasterMessage, ToasterContext, LoadingSpinner } from 'componentsV2';
const Text = styled.p`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 8px;
	font-size: 16px;
	font-weight: 600;
	font-family: 'Nunito Sans';
`;

export const AssignModal = ({
	siteUsers,
	respondUsers,
	isModalOpen,
	setIsModalOpen,
	fetchSiteKeyPeopleData,
	tableRowData,
	siteLabels,
	buildingId,
}) => {
	const responderKeys = ['dl_school_resp_id', 'site_info_police_id'];
	const [baseData, setBaseData] = useState([]);
	const [filteredAssignData, setFilteredAssignData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const isResponderRow = responderKeys.includes(tableRowData?.roleKey);
		const cBaseData = isResponderRow ? respondUsers : siteUsers;
		cBaseData?.length > 0 ? setLoading(false) : setLoading(true);
		setBaseData(cBaseData);
		// Default visible options without a search query.
		setFilteredAssignData(cBaseData);
	}, [siteUsers, tableRowData]);

	const getUserItem = item => {
		HttpService({
			method: 'PUT',
			route: `/buildings/site-key-people`,

			body: {
				person_id: `${item.personId}`,
				team_id: `${buildingId}`,
				key: `${tableRowData.roleKey}`,
			},
		})
			.then(() => {
				setIsModalOpen(false);
				fetchSiteKeyPeopleData(buildingId);
				const successMessage = (
					<ToasterMessage
						text={`You have successfully assigned ${
							item.fullName
						} as a ${toastLabels()}.`}
					/>
				);
				toaster(successMessage, Intent.SUCCESS, 'tick', 10000);
			})
			.catch(err => {
				console.log('err', err);
			});
	};

	const onHandleAssignSearch = value => {
		const searchData = baseData.filter(
			item =>
				item?.fullName?.toLowerCase()?.includes(value.toLowerCase()) ||
				item?.emailAddress?.toLowerCase()?.includes(value.toLowerCase()),
		);
		setFilteredAssignData(searchData);
	};

	const toastLabels = () => {
		const toastLabel = siteLabels.find(item => item.name === tableRowData.role);
		if (toastLabel) {
			return toastLabel.text;
		}
		return '';
	};

	const toasterRef = React.createRef();
	const toaster = useContext(ToasterContext);
	return (
		<div>
			<Dialog
				onClose={() => setIsModalOpen(false)}
				isCloseButtonShown={false}
				isOpen={isModalOpen}
				title={`Assign a contact for ${toastLabels()}`}
				style={{
					fontSize: '14px',
					fontWeight: 'bolder',
					height: '60%',
					width: '24%',
					minWidth: 'fit-content',
					minHeight: '350px',
					borderRadius: '16px',
				}}
			>
				{loading && <LoadingSpinner />}
				{!loading && (
					<>
						<div
							style={{
								padding: '10px',
							}}
						>
							<SearchBar
								placeholder="Search by name or email..."
								icon="search"
								onSearch={onHandleAssignSearch}
							/>
							{filteredAssignData.length === 0 && <Text>No Users Found...</Text>}
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '90%',
								height: 'auto',
								overflow: 'scroll',
								marginLeft: '12px',
							}}
						>
							{filteredAssignData &&
								filteredAssignData.map(item => (
									<div
										style={{
											display: 'flex',
											padding: '4px',
											cursor: 'pointer',
										}}
										onClick={() => getUserItem(item)}
										key={item.personId}
										tabIndex={0}
										onKeyDown={e => {
											if (e.key === 'Enter') {
												getUserItem(item);
											}
										}}
										role="button"
									>
										<Avatar
											size="md"
											label={item?.fullName || 'Missing Name'}
											supportingText={item.emailAddress}
											name={item?.fullName || 'Missing Name'}
										/>
									</div>
								))}
						</div>
					</>
				)}
			</Dialog>
			<Toaster ref={toasterRef} position={Position.TOP_RIGHT} />
		</div>
	);
};

AssignModal.propTypes = {
	siteUsers: PropTypes.array,
	respondUsers: PropTypes.array,
	isModalOpen: PropTypes.bool,
	setIsModalOpen: PropTypes.bool,
	fetchSiteKeyPeopleData: PropTypes.func,
	tableRowData: PropTypes.any,
	siteLabels: PropTypes.any,
	buildingId: PropTypes.any,
};
