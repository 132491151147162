import React, { useEffect, useState } from 'react';
import { FileUpload, Modal, Button, FeaturedIcon } from 'componentsV2';
import '../css/UploadContent.scss';

export const UploadContent = ({
	loading,
	modalToShow,
	closeModal,
	modalData,
	handleUploadContent,
}) => {
	const [selectedFile, setSelectedFile] = useState();
	const [disableSave, setDisableSave] = useState(true);
	const [fileError, setFileError] = useState(null);

	useEffect(() => {
		setDisableSave(true); // this to make sure that save button is disable if upload file and dismiss the modal and open it again
	}, [modalToShow]);

	const footer = (
		<div className="button-div-styles">
			<Button
				text={'Cancel'}
				type="tertiary"
				intent="default"
				onClick={modalData.onCancelAction}
			/>
			<Button
				text={'Save'}
				intent="default"
				disabled={disableSave}
				onClick={e => {
					handleUploadContent(
						e,
						modalData.siteId,
						selectedFile,
						modalData.contentType,
						setFileError,
					);
				}}
			/>
		</div>
	);

	const onFileInputChange = e => {
		setSelectedFile(e);
		setFileError(null);
	};
	const handleProgressCompleted = isProgressCompleted => {
		if (isProgressCompleted) {
			setDisableSave(false);
		} else {
			setDisableSave(true);
		}
	};
	return (
		<Modal
			isOpen={modalToShow}
			onClose={closeModal}
			title={modalData.modalTitle}
			subtitle={modalData.modalSubTitle}
			showCloseButton={false}
			loading={loading}
			customFooter={footer}
			customClassName={'upload-content-modal-container map-container-modals'}
		>
			<div className="col-md-12">
				<FileUpload
					disabled={false}
					allowedFiles={modalData.uploader.allowedFiles}
					allowedFilesLegend={modalData.uploader.allowedFilesLegend}
					maxFileSize={modalData.uploader.maxFileSize}
					label={modalData.uploader.label}
					required={true}
					labelState={false}
					onFileInputChange={onFileInputChange}
					setProgressCompleted={handleProgressCompleted}
					error={fileError ? true : false}
					defaultErrorMessage={fileError}
				/>
			</div>
		</Modal>
	);
};
