import { states } from '../../../../utilities/constants';
import { formatDate } from '../../../../utilities/dates';

// Map the response data from GET /drills into a more concise format
export const formatDrills = drills => {
	const drillsFormatted = drills.map(drill => ({
		scheduleId: drill.scheduleId,
		type: drill.drillType.name,
		typeUuid: drill.drillType.uuid,
		schedulingInstructions: drill.drillType.schedulingInstructions,
		postingInstructions: drill.drillType.postingInstructions,
		buildingId: drill.buildingId,
		site: drill.buildingName,
		scheduledDate: formatDate(drill.scheduledDrillDateISO),
		year: drill.year,
		status: drill.status,
		dayDifference: calculateDayDifference(drill.scheduledDrillDate.date),
		actualDrillDate:
			drill.log && drill.log.drillLoggedDate && toLocalTime(drill.log.drillLoggedDate.date),
	}));
	return drillsFormatted;
};

// Need to update backend it's missing scheduling and posting instructions
export const formatDrillForTable = (drill, index) => ({
	id: index,
	scheduleId: drill.scheduleId,
	year: drill.year,
	buildingId: drill.buildingId,
	buildingName: drill.buildingName,
	// make sure status is in title case
	status: capitalizeWordsAndReplaceUnderscores(drill.status),
	// convert to local time
	scheduledDrillDate: formatDate(drill.scheduledDrillDateISO),
	typeId: drill.drillType.id,
	typeUuid: drill.drillType.uuid,
	type: drill.drillType.name,
	actualDrillDate: drill.actualDate,
});

export const formatScheduledDate = dateString => {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
	const day = date
		.getDate()
		.toString()
		.padStart(2, '0');
	const hour = date
		.getHours()
		.toString()
		.padStart(2, '0');
	const minute = date
		.getMinutes()
		.toString()
		.padStart(2, '0');

	return `${year}-${month}-${day}T${hour}:${minute}`;
};

export const calculateDayDifference = scheduledDate => {
	const givenDate = new Date(scheduledDate);
	const currentDate = new Date();
	const millisecondsPerDay = 1000 * 60 * 60 * 24;

	// Calculate difference in days
	const differenceInMilliseconds = givenDate - currentDate;
	let differenceInDays = Math.ceil(differenceInMilliseconds / millisecondsPerDay);

	// Check if it's past due or due in future
	if (differenceInDays < 0) {
		differenceInDays = Math.abs(differenceInDays); // Convert to positive
		return differenceInDays === 1
			? `${differenceInDays} day past due`
			: `${differenceInDays} days past due`;
	}
	return differenceInDays === 1
		? `Due in ${differenceInDays} day`
		: `Due in ${differenceInDays} days`;
};

export const capitalizeWordsAndReplaceUnderscores = string =>
	string
		.split('_')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');

export const toLocalTime = dateString => {
	const utcDate = new Date(dateString);
	const offset = utcDate.getTimezoneOffset();
	utcDate.setMinutes(utcDate.getMinutes() - offset);
	return utcDate.toISOString();
};

export const getStateId = () => {
	// Get state name from local storage
	const userDataString = localStorage.getItem('loggedUserData');
	const userDataObject = JSON.parse(userDataString);
	const stateName = userDataObject.primaryBuilding.state;
	// Get state id from constant
	const stateId = states[stateName];
	return stateId;
};
