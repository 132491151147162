import React, { useState } from 'react';
import './css/ExportUsersModal.scss';
import { FormModal, Checkbox } from 'componentsV2';
import { downloadUsersCSV, convertToCSV } from '../../../utilities/drills/utils';

export const ExportUsersModal = ({ selectedRows, onCloseModal }) => {
	const userData = JSON.parse(localStorage.getItem('loggedUserData'));
	const date = new Date().toLocaleDateString();
	const buildingName = userData.primaryBuilding.name.split(' ').join('');
	const fileName = `${buildingName}_${date}_Users`;
	const [loading, setLoading] = useState(false);

	const [headers, setHeaders] = useState({
		name: true,
		staffTitle: true,
		roleType: true,
		site: true,
		loginInfo: true,
		lastLoginDate: true,
	});

	const headersDisplay = {
		name: 'Name',
		staffTitle: 'Staff Title',
		roleType: 'Role Type',
		site: 'Site',
		loginInfo: 'Login Info',
		lastLoginDate: 'Last Login Date',
	};
	const csvColumnNames = {
		name: 'person_fullName',
		staffTitle: 'person_title',
		roleType: 'primaryRole_title',
		site: 'primaryBuilding_name',
		loginInfo: 'login',
		lastLoginDate: 'lastLogin',
	};

	const HandleCheckboxChange = (e, column) => {
		setHeaders({
			...headers,
			[column]: e.target.checked,
		});
	};

	const exportHandler = () => {
		const selectedHeaders = Object.keys(headers).filter(key => headers[key]);
		const selectedCSVHeader = selectedHeaders.map(key => csvColumnNames[key]);
		const selectedHeadersDisplayLabel = selectedHeaders.map(key => headersDisplay[key]);
		const csvData = convertToCSV(selectedRows, selectedCSVHeader, selectedHeadersDisplayLabel);
		if (csvData) {
			downloadUsersCSV(csvData, fileName);
			onCloseModal();
		}
	};

	return (
		<FormModal
			isOpen={true}
			onClose={onCloseModal}
			title="Export users"
			size="medium"
			showCloseButton={true}
			scrollContent={false}
			loading={loading}
			confirmBtn={{
				title: 'Export',
				onClick: exportHandler,
				disabled: false,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: onCloseModal,
			}}
			customClassName="export-users-modal"
		>
			<h3 className="body-md-bold">Information to download</h3>

			<ul class="list-group">
				<li class="list-group-item disabled" aria-disabled="true">
					<Checkbox onChange={() => {}} checked={true} disabled={true} />
					<span className="body-sm-bold">{headersDisplay.name}</span>
				</li>
				<li class="list-group-item">
					<Checkbox
						onChange={e => HandleCheckboxChange(e, 'staffTitle')}
						checked={headers.staffTitle}
					/>
					<span className="body-sm-bold">{headersDisplay.staffTitle}</span>
				</li>
				<li class="list-group-item">
					<Checkbox
						onChange={e => HandleCheckboxChange(e, 'roleType')}
						checked={headers.roleType}
					/>
					<span className="body-sm-bold">{headersDisplay.roleType}</span>
				</li>
				<li class="list-group-item">
					<Checkbox
						onChange={e => HandleCheckboxChange(e, 'site')}
						checked={headers.site}
					/>
					<span className="body-sm-bold">{headersDisplay.site}</span>
				</li>
				<li class="list-group-item">
					<Checkbox
						onChange={e => HandleCheckboxChange(e, 'loginInfo')}
						checked={headers.loginInfo}
					/>
					<span className="body-sm-bold">{headersDisplay.loginInfo}</span>
				</li>
				<li class="list-group-item">
					<Checkbox
						onChange={e => HandleCheckboxChange(e, 'lastLoginDate')}
						checked={headers.lastLoginDate}
					/>
					<span className="body-sm-bold">{headersDisplay.lastLoginDate}</span>
				</li>
			</ul>
		</FormModal>
	);
};

// export default ExportUsersModal;
