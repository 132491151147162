import React from 'react';
import PropTypes from 'prop-types';
import './css/LinkArrow.scss';
import arrowRight from './assets/arrowRight.svg';

export const LinkArrow = ({ onClick, disableLink = false, children }) => {
	return (
		<a 
			onClick={onClick ? onClick : null} 
			className={`link-arrow ${disableLink ? "disable-link" : ""}`}
		>
			<div className="link-content">
				<span>{children}</span>
				{!disableLink && <img src={arrowRight} alt="link" />}
			</div>
		</a>
	);
};

LinkArrow.propTypes = {
	onClick: PropTypes.func,
	disableLink: PropTypes.bool,
	children: PropTypes.string.isRequired,
};
