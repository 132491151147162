import { HttpService, buildRoute } from 'networking/HttpService';

export const UsersService = {
	async search(params = {}) {
		let route = '/users?page=:page&perPage=:perPage';
		const searchParams = {
			page: params.page || 0,
			perPage: params.perPage || 100,
		};

		route = buildRoute(route, searchParams);

		if (params.combineSearch) {
			route += '&combineSearch=:combineSearch';
			route = buildRoute(route, {
				combineSearch: params.combineSearch,
			});
		}

		if (params.includeDisabled && params.includeDisabled === true) {
			route += '&includeDisabled=:includeDisabled';
			route = buildRoute(route, {
				includeDisabled: params.includeDisabled,
			});
		}

		if (params.sortKey && params.sortDir) {
			route += '&sortKey=:sortKey&sortDir=:sortDir';
			route = buildRoute(route, {
				sortKey: params.sortKey,
				sortDir: params.sortDir,
			});
		}

		if (params.sites && params.sites.length > 0) {
			params.sites.forEach(site => {
				route += '&sites[]=:sites';
				route = buildRoute(route, {
					sites: site,
				});
			});
		}

		if (params.roles && params.roles.length > 0) {
			params.roles.forEach(role => {
				route += '&roles[]=:roles';
				route = buildRoute(route, {
					roles: role,
				});
			});
		}

		if (params.email) {
			route += '&email=:email';
			route = buildRoute(route, {
				email: params.email,
			});
		}

		if (params.title) {
			route += '&title=:title';
			route = buildRoute(route, {
				title: params.title,
			});
		}

		if (params.staffTitles && params.staffTitles.length > 0) {
			params.staffTitles.forEach(staffTitle => {
				route += '&titles[]=:staffTitle';
				route = buildRoute(route, {
					staffTitle: staffTitle,
				});
			});
		}

		if (params.includeNeverLoggedUsers && params.includeNeverLoggedUsers === true) {
			route += '&includeNeverLoggedUsers=:includeNeverLoggedUsers';
			route = buildRoute(route, {
				includeNeverLoggedUsers: true,
			});
		}

		const response = await HttpService({
			method: 'GET',
			route,
		});
		return response;
	},

	async getAll() {
		const { data } = await HttpService({ method: 'GET', route: `/users` });
		// Only return user objects with a non-falsy person object.
		return data.filter(({ person }) => !!person);
	},

	async fetchPermissions() {
		try {
			const response = await HttpService({
				method: 'GET',
				route: '/users/myself/permissions',
			});

			if (response?.data) {
				return response?.data;
			}

			throw response;
		} catch (error) {
			if (error.statusCode === 401) {
				const localToken = JSON.parse(localStorage.getItem('user'));
				localToken.jwt = '';
				localStorage.setItem('user', JSON.stringify(localToken));
				return window.location.reload();
			}

			throw error;
		}
	},

	async enableUsers(usersIds) {
		try {
			return await HttpService({
				method: 'POST',
				route: '/users/bulk-enable-users-status',
				body: {
					usersIds: usersIds,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	async disableUsers(usersIds) {
		try {
			return await HttpService({
				method: 'POST',
				route: '/users/bulk-disable-users-status',
				body: {
					usersIds: usersIds,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	async assignSites(usersIds, sitesIds) {
		try {
			return await HttpService({
				method: 'POST',
				route: '/alarms/bulk-add-user-sites',
				body: {
					usersIds: usersIds,
					sitesIds: sitesIds,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	async removeSites(usersIds, sitesIds) {
		try {
			return await HttpService({
				method: 'POST',
				route: '/alarms/bulk-delete-user-sites',
				body: {
					usersIds: usersIds,
					sitesIds: sitesIds,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	async bulkWelcomeEmailUsers(usersIds) {
		try {
			return await HttpService({
				method: 'POST',
				route: '/users/bulk-welcome-email',
				body: {
					usersIds: usersIds,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	async bulkResetPasswordUsers(usersIds) {
		try {
			return await HttpService({
				method: 'POST',
				route: '/users/bulk-reset-password-email',
				body: {
					usersIds: usersIds,
				},
			});
		} catch (error) {
			throw error;
		}
	},
	async validateBulkUsersFile(g) {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		return fetch(`${process.env.API_URL}/users/bulk-validate-users-file`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				file: g,
			}),
		}).then(res => res.json());
	},

	async downloadBulkUserTemplate() {
		try {
			return await HttpService({
				method: 'GET',
				route: '/users/download-template',
				headers: {
					'Content-Type': 'application/vnd.ms-excel',
				},
			});
		} catch (error) {
			throw error;
		}
	},

	async bulkAddUsers(usersList) {
		try {
			return await HttpService({
				method: 'POST',
				route: '/users/add-multiple',
				body: {
					valid_users: usersList,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	async fetchDistrictInfo(dist) {
		try {
			const response = await HttpService({
				method: 'GET',
				route: `/clients/${dist}`,
			});

			if (response?.data) {
				return response?.data;
			}

			throw response;
		} catch (error) {
			if (error.statusCode === 401) {
				const localToken = JSON.parse(localStorage.getItem('user'));
				localToken.jwt = '';
				localStorage.setItem('user', JSON.stringify(localToken));
				return window.location.reload();
			}

			throw error;
		}
	},
	getClients: async () => {
		return await HttpService({
			method: 'GET',
			route: `/clients`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	export: async (userIds = [], headers = []) => {
		let query = '';

		if (userIds.length > 0) {
			userIds.forEach(userId => {
				query += `&userIds[]=${userId}`;
			});
		}

		if (headers.length > 0) {
			headers.forEach(header => {
				query += `&headers[]=${header}`;
			});
		}

		return await HttpService({
			method: 'GET',
			route: `/users/export?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},
};
