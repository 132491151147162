import React, { useState } from "react";
import { Icon } from '@blueprintjs/core';
import CustomModal from "components/Modals/CustomModal";
import '../../css/DeleteSectionModal.scss';
import { Button } from 'components/Button';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fireSwal } from "utilities/fireSwal";

const DeleteSectionModal = ({
    type,
    title,
    description,
    sectionIndex,
    subsectionIndex,
    deletedSection,
    closeDeleteModal,
    onDeleteAndPublishSection,
}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const deleteSection = async (publish = false) => {
        setIsLoading(true);
        if ( type === 'section' ) { //Delete section
            dispatch({
                type: 'REMOVE_SECTION_NEW',
                sectionId: deletedSection.actualSectionId,
            });
        } else { //Delete sub section
            dispatch({
                type: 'REMOVE_SUBSECTION_NEW',
                sectionId: deletedSection.actualSectionId,
                subsectionId: deletedSection.actualSubsectionId,
            });
        }

        if(publish === true) {
            await onDeleteAndPublishSection();
        } else {
            fireSwal('success', 'Successfully deleted!');
        }
        closeDeleteModal();
        setIsLoading(false);
    }

    const footerBtns = (
        <>
            <Button
                className="footer-btn-action"
                intent="secondary"
                text="Cancel"
                onClick={closeDeleteModal}
            />
            <Button
                className="footer-btn-action"
                intent="primary"
                text="Delete"
                onClick={deleteSection}
            />
            <Button
                className="footer-btn-action"
                intent="primary"
                text="Delete & Publish"
                onClick={() => deleteSection(true)}
            />
        </>        
    );

    return (
        <CustomModal
            title={title}
            isLoading={isLoading}
            onCloseModal={closeDeleteModal}
            footerBtns={footerBtns}
        >
            <div className="delete-section-modal">
                <Icon icon='warning-sign' size={60}  intent='warning' />
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
            
        </CustomModal>
    )
}

DeleteSectionModal.propTypes = {
    closeDeleteModal: PropTypes.func,
    type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    sectionIndex: PropTypes?.number,
    subsectionIndex: PropTypes?.number,
    onDeleteAndPublishSection: PropTypes.func.isRequired,
};

export default DeleteSectionModal;