import React, { useEffect, useState } from 'react';
import { DOWNLOAD_MAP } from '../../../consts';
import { FloorplanService } from 'services';

export const DownloadMapFn = (
	modalName,
	setLoading,
	setDownloadLoading,
	closeModal,
	toaster,
	setModalToShow,
	modalData,
	dispatchModalData,
) => {
	useEffect(() => {}, [modalData]);
	const [selectedMap, setSelectedMap] = useState(null);

	const showDownloadMapModal = map => {
		setSelectedMap(map);
		dispatchModalData({
			modalTitle: `Download “${map.title}”`,
		});
		setModalToShow(DOWNLOAD_MAP);
	};

	const handleDownloadMap = type => {
		setDownloadLoading(true);
		FloorplanService.downloadMap(selectedMap.building.id, selectedMap.id, type)
			.then(response => {
				if (response.statusCode === 200) {
					let dataType = 'application/pdf';
					let ext = '.pdf';

					if (type == 'jpeg') {
						ext = '.jpeg';
						dataType = 'image/jpeg';
					}

					const decodedData = atob(response.data[0].screenshotBase64);

					const dataArray = new Uint8Array(decodedData.length);
					for (let i = 0; i < decodedData.length; i++) {
						dataArray[i] = decodedData.charCodeAt(i);
					}

					const dataBlob = new Blob([dataArray], { type: dataType });

					const blobURL = URL.createObjectURL(dataBlob);

					const tempLink = document.createElement('a');
					tempLink.href = blobURL;
					tempLink.setAttribute('download', `${selectedMap.title} - ${selectedMap.building.name}${ext}`);

					tempLink.click();
				}
			})
			.catch(error => {
				console.error('Error:', error);
			})
			.finally(() => {
				closeModal();
				setDownloadLoading(false);
			});
	};

	return {
		showDownloadMapModal,
		handleDownloadMap,
	};
};
