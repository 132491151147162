import { HttpService, buildRoute } from 'networking/HttpService';

export const RolesService = {
	async search(params = {}) {
		let route = '/roles?';

		if (params.search) {
			route += '&search=:search';
			route = buildRoute(route, {
				search: params.search,
			});
		}

		if (Object.keys(params).includes("includeDisabled")) {
			route += '&includeDisabled=:includeDisabled';
			route = buildRoute(route, {
				includeDisabled: params.includeDisabled,
			});
		}

		if (params.sites && params.sites.length > 0) {
			params.sites.forEach(site => {
				route += '&sites[]=:sites';
				route = buildRoute(route, {
					sites: site,
				});
			})
		}
		
		const response = await HttpService({
			method: 'GET',
			route,
		});
		return response;
	},

	toggleStatus: (roleId, newStatus) => {
		return HttpService({
			method: 'POST',
			route: '/update-role-status',
			body: {
				roleId: roleId,
				newStatus: newStatus,
			}
		}).then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
	},

	deleteRole: (roleId) => {
		return HttpService({
			method: 'DELETE',
			route: '/roles/'+roleId
		}).then(res => {
			return res;
		}).catch(err => {
			throw err;
		});
	},
	
};
