import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Button } from '@blueprintjs/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import selectRoute from './selectors';
import Subsections from './subsections';
import { FeaturedIcon, Badge } from 'componentsV2';
import './css/PlanOutline.scss';

const reOrder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	textAlign: 'right',

	// change background colour if dragging
	display: 'flex',
	flexDirection: 'column',

	// styles we need to apply on draggables
	...draggableStyle,
});

class PlanOutline extends React.Component {
	state = {
		expand: true,
	};

	onDragEnd = async (result) => {
		const {
			dispatch,
			route: { template },
			scrollToSection
		} = this.props;
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const temp = template;
		if (result.type === 'SECTIONS') {
			const sections = reOrder(
				temp.sections,
				result.source.index,
				result.destination.index,
			);
			temp.sections = sections;
			await dispatch({
				type: 'REORDER_SECTIONS',
				payload: temp,
			});
			const sectionIndex = result.destination.index;
			const section = temp.sections[sectionIndex];
			scrollToSection(section, null, 'section')
		} else {
			const sectionIndex = parseInt(result.type, 10);
			const subsections = reOrder(
				temp.sections[sectionIndex].subsections,
				result.source.index,
				result.destination.index,
			);
			temp.sections[sectionIndex].subsections = subsections;
			await dispatch({
				type: 'REORDER_SECTIONS',
				payload: temp,
			});
			
			const subSectionIndex = result.destination.index;
			const section = temp.sections[sectionIndex];
			const subSection = temp.sections[sectionIndex].subsections[subSectionIndex];
			scrollToSection(section, subSection, 'subsection')
		}
	};

	scrollTitle = (e, id) => {
		e.preventDefault();
		const el = document.getElementById(`section-${id}`);
		el.scrollIntoView({ behavior: 'smooth' });
	};

	render() {
		const {
			loading,
			route: { template },
			scrollToSection,
			visibleSection,
			visibleSubsection,
			sectionsPerPage,
			setPage,
			sidebarRef,
			viewMode,
			planType
		} = this.props;
		const { expand } = this.state;
		if (loading) return null;
		
		return (
			<div className={`plan-outline-container ${expand ? '' : 'hidden'}`}  ref={sidebarRef}>
				<div className="header-container">
					<FeaturedIcon 
						icon={expand ? 'menu-open' : 'menu-closed'} 
						onClick={() => this.setState({ expand: !expand })}
						shape='roundedIcon'
						className='collapse-menu-icon'
						type="Gray"
						size="sm"
					/>
					<h4 className={`outline-title ${expand ? 'expanded' : ''}`} >Plan Outline</h4>
				</div>
				<div className={`body-container ${expand ? 'expanded' : ''}`}>
					<DragDropContext
						onDragEnd={this.onDragEnd}
					>
						<Droppable droppableId="droppable" type="SECTIONS">
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									className='question-list'
								>
									{template.sections.map((item, index) => {
										var page = Math.trunc(index / sectionsPerPage);
										if ( visibleSection?.id === item.id ) {
											setPage(page);
										}
										return (
											<Draggable
												key={item.id}
												draggableId={item.id?.toString()}
												index={index}
												isDragDisabled={viewMode}
											>
												{(p, s) => (
													<div
														ref={p.innerRef}
														{...p.draggableProps}
														style={getItemStyle(
															s.isDragging,
															p.draggableProps.style,
														)}
													>
														<div id={`planoutline-${item.id}`} className="section-menu-item">
															<div className='dot-icon-wrapper'>
																{visibleSection?.id === item.id && !visibleSubsection?.id  && (
																	<Icon icon="dot" />
																)}
															</div>
															{!viewMode && (
																<span {...p.dragHandleProps}>
																	<Icon icon="drag-handle-vertical" className='draggableIcon' />	
																</span>	
															)}
															<a 
																className='main-section-title'
																onClick={() =>
																	scrollToSection(
																		item,
																		null,
																		'section',
																		page
																	)
																}
															>
																{item.title}
															</a>
															{!viewMode && !item.complete && planType !== "corePlan" && (
																<Badge 
																	type='Warning'
																	size='sm'
																	text='Incomplete' 
																/>
															)}
														</div>
														{item.subsections.length > 0 && (		
															<Subsections
																index={index}
																item={item}
																scrollToSection={scrollToSection}
																visibleSection={visibleSection}
																visibleSubsection={visibleSubsection}
																page={page}
																setPage={setPage}
																planType={planType}
																viewMode={viewMode}
															/>
														)} 
													</div>
												)}
											</Draggable>
										)
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		);
	}
}

PlanOutline.propTypes = {
	loading: PropTypes.bool,
	route: PropTypes.object,
	dispatch: PropTypes.func,
	scrollToSection: PropTypes.func,
	visibleSection: PropTypes.object,
	visibleSubsection: PropTypes.object,
	sectionsPerPage: PropTypes.number,
	setPage: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(PlanOutline);
