import React from 'react';
import { Button, Tooltip, TooltipPosition } from 'componentsV2';
import { AreaItemActionsList } from './AreaItemActionsList';

const getColor = (item, legends) => {
	// Default values
	let color = '#E4E7E7';
	let label = 'Not set';

	// If color field is set
	if (
		(item.color && item.color.length > 0) ||
		(item.colorLegend && item.colorLegend.hex.length > 0)
	) {
		color = `#${item.color ? item.color : item.colorLegend.hex}`;
		// Find the legend associated to the color
		let legendFound = legends.filter(
			legend => `#${legend.hex.toLowerCase()}` == color.toLowerCase(),
		);
		if (legendFound.length == 1) {
			label = legendFound[0].description;
		}
	}

	return {
		color,
		label,
	};
};

export const AreaItem = ({
	item,
	visible = true,
	editPermission,
	viewPermissionOnSiteId,
	legends,
	floorplan,
	handleShowArea,
	handleEditArea,
	handleCopyArea,
	handleViewShapeContent,
	layer,
	setMapListUpdated,
	isEditMode,
}) => {
	const itemColor = getColor(item, legends);
	return (
		<div className={`area-item-container ${visible ? 'area-item-visible' : ''}`}>
			<div className="area-item-left-content">
				<Button
					className="btn-area-item-visible"
					small
					icon={visible ? 'eye-open' : 'eye-off'}
					iconColor="primary"
					size="md"
					type="ghostDefault"
					intent="default"
					onClick={() => handleShowArea(item.id, !visible)}
				/>

				<Tooltip
					tooltipTitle={itemColor.label}
					theme="light"
					position={TooltipPosition.TOP}
				>
					<div
						className="circle-color"
						style={{
							background: itemColor.color,
						}}
					/>
				</Tooltip>

				<span className="area-item-title">{item.title}</span>
			</div>
			<div className="area-item-right-content">
				<AreaItemActionsList
					item={item}
					editPermission={editPermission}
					isEditMode={isEditMode}
					viewPermissionOnSiteId={viewPermissionOnSiteId}
					floorplan={floorplan}
					handleEditArea={handleEditArea}
					handleCopyArea={handleCopyArea}
					handleViewShapeContent={handleViewShapeContent}
					layer={layer}
					setMapListUpdated={setMapListUpdated}
				/>
			</div>
		</div>
	);
};
