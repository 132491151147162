import React from 'react';
import { Button } from 'componentsV2';
import './css/SelectSiteHeader.scss';
import { DISABLED_STATUS, SELECTED_STATUS } from '../../consts';

export const SelectSiteHeader = ({ sitesList, onSelectAll }) => {
	const selectedSites = sitesList.filter(site => site.status === SELECTED_STATUS);
	const allSitesAreSelected =
		sitesList.filter(site => site.status !== DISABLED_STATUS).length === selectedSites.length;
	const selectAllText = allSitesAreSelected ? 'Unselect all sites' : 'Select all sites';
	const allSitesAreDisabled =
		sitesList.filter(site => site.status === DISABLED_STATUS).length === sitesList.length;

	return (
		<div className="select-site-header">
			<p className="select-site-header-left-side">
				{selectedSites.length} of {sitesList.length} selected
			</p>
			<Button
				className="select-site-header-right-side"
				type="ghostPrimary"
				text={selectAllText}
				onClick={() => onSelectAll(!allSitesAreSelected)}
				disabled={allSitesAreDisabled}
			/>
		</div>
	);
};
