import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
`;

const ButtonWrap = styled.div`
	display: inline-flex;
	flex-direction: column;
	margin-left: 3px;
	button {
		margin: 0;
		padding: 0;
		color: #6e84a3;
	}
`;

const ButtonReset = styled.button`
	background: none;
	border: none;
	&:focus {
		outline: none;
	}
`;

const Sorter = ({ onSortClick, children, direction }) => (
	<Wrapper>
		{children}
		<ButtonWrap>
			<ButtonReset onClick={onSortClick}>
				<i className={`fas fa-caret-${direction}`} />
			</ButtonReset>
		</ButtonWrap>
	</Wrapper>
);

Sorter.propTypes = {
	onSortClick: PropTypes.func,
	children: PropTypes.any,
	direction: PropTypes.string,
};

export { Sorter };
