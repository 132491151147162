import React , { useState } from "react";
import { SectionLabel } from "./SectionLabel";
import { CardContainer, Modal, Button, Input } from "../";
import { Checkbox } from "components/Checkbox";
import { TextArea } from "../TextArea/TextArea";
import InputWrapper from "../InputWrapper/InputWrapper";
import './css/SectionLabel.scss';

export const SectionLabelPattern = () => {

    const [content, setContent] = useState("Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio ipsum nemo architecto nobis consequuntur totam dignissimos, accusantium obcaecati, cumque rerum et soluta aliquid tenetur ad nihil quasi iusto exercitationem cum.");
    const [title, setTitle] = useState("Label title.");
    const [showLeftElement, setShowLeftElement] = useState(true);
    const [showRightElement, setShowRightElement] = useState(true);
    const [showFooterElement, setShowFooterElement] = useState(true);
    const [showSectionLabel, setShowSectionLabel] = useState(false);

    const footerBtn = <Button
                        intent='#D9DCDD'
                        style={{
                            backgroundColor: '#D9DCDD',
                            color:'black',
                            borderRadius: 12,
                        }}
                        text='cancel action'
                        onClick={() => {}}
                    />

    const rightBtn = <Button
                        intent="#007E8F"
                        style={{
                            backgroundColor: '#007E8F',
                            color:'white',
                            borderRadius: 12,
                        }}
                        type="primaryDefault"
                        text='confirm action'
                        onClick={() => {}}
                    />

    return (
        <div className="row">
            <div className="col-md-12">
                <CardContainer title="Section label example">
                    <div className="row">
                        <div className="col-md-12">
                            <InputWrapper
                                label='Title'
                                labelState={true}
                                isRequired={true} 
                            >
                                <Input
                                    large
                                    placeholder="Title ..."
                                    value={title}
                                    onChange={e => setTitle(e.target.value)}
                                />
                            </InputWrapper>
                        </div>
                        <div className="col-md-12">
                            <TextArea
                                label="Content"
                                onChange={e => setContent(e.target.value)}
                                value={content}
                            />
                        </div>
                        <div className="col-md-12 mt-2">
                            <Checkbox
                                label="Show left element"
                                onChange={() => setShowLeftElement(oldValue => !oldValue)}
                                checked={showLeftElement}
                            />
                            <Checkbox
                                label="Show Right element"
                                onChange={() => setShowRightElement(oldValue => !oldValue)}
                                checked={showRightElement}
                            />
                            <Checkbox
                                label="Show Footer element"
                                onChange={() => setShowFooterElement(oldValue => !oldValue)}
                                checked={showFooterElement}
                            />
                        </div>
                        <div className="col-md-12 mt-4">
                            <Button
                                onClick={() => setShowSectionLabel(true)}
                                type="primaryDefault"
                                text='Show section label'
                            />                                
                        </div>
                    </div>
                </CardContainer>
            </div>
            <div className="col-md-12">
                <Modal
                    isOpen={showSectionLabel}
                    onClose={() => setShowSectionLabel(false)}
                    size='small'
                    showCloseButton={true}
                >
                    <SectionLabel 
                        title={title}
                        showLeftArrow={showLeftElement}
                        rightElement={showRightElement ? rightBtn  : undefined}
                        footerElements={showFooterElement ? footerBtn  : undefined}
                    >
                        {content}
                    </SectionLabel>
                </Modal>
            </div>
        </div>
    )
}