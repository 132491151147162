import React, { useEffect } from "react";
import { ConfirmationModal } from "componentsV2";

export const DeletePlanModal = ({
    modalName,
    modalToShow,
    loading,
    planData,
    closeModal,
    handleDeleteSubmit,
}) => {
    
    return (
        <ConfirmationModal
            isOpen={modalToShow === modalName}
            title={planData.modalTitle}
            subtitle='Once deleted, this cannot be recovered.'
            showCloseButton={false}
            cancelBtn={{
                title: 'No, go back',
                onClick: closeModal
            }}
            confirmBtn={{
                title: planData.confirmBtnTitle,
                onClick: handleDeleteSubmit,
                type: 'primaryDanger'
            }}
            loading={loading}
            btnsSize="lg"
            icon={{
                icon: 'trash',
                iconColor: 'error'
            }}
        />
    );
}