import React from 'react';
import { CallListProviders, CallList, CallListModals } from './';

export const CallListV2 = ({ history }) => {
	return (
		<CallListProviders history={history}>
			<CallListModals />
			<CallList history={history} />
		</CallListProviders>
	);
};
