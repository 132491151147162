import { GEOMETRY_TYPE_POLYGON } from '../../consts';

export const deleteShapeOnMap = (shapeId, layer, transformer) => {
	const deletedShapeIndex = layer.children.findIndex(
		layerShape =>
			(layerShape.attrs?.shape ? layerShape.attrs.shape.id : layerShape.attrs.id) === shapeId,
	);
	if (deletedShapeIndex > -1) {
		layer.children.splice(deletedShapeIndex, 1);
		layer.batchDraw();
		transformer.detach();
	}
};

export const deleteAllTextsOnMap = (shapeText, layer, transformer) => {
	let shape = '';
	let keys = [];
	for (var key in layer.children) {
		shape = layer.children[key];

		if (shape instanceof Konva.Text) {
			if (shape.attrs.text == shapeText) {
				keys.push(parseInt(key));
			}
		}
	}
	layer.children = layer.children.filter((item, index) => !keys.includes(index));
	layer.batchDraw();
	transformer.detach();
};

export const deleteAreaOnMap = (shapeId, layer, transformer) => {
	let shape = '';
	for (var key in layer.children) {
		shape = layer.children[key];
		if (
			shape.attrs.geometryType === GEOMETRY_TYPE_POLYGON &&
			shape.attrs.id === `areaGroup-` + shapeId
		) {
			layer.children.splice(key, 1);
			layer.batchDraw();
			transformer.detach();
		}
	}
};
