import React from 'react';
import { Input } from 'componentsV2';

// Imports needed for Filter
import { FilterMenuFn, FilterMenu, FilterBar } from 'componentsV2';

export const FilterMenuPattern = () => {
	// Common Filter Functions
	const { filterData, handleChangeFilter, removeFilter, getFilterValue } = FilterMenuFn();

	// Filter Items
	const filterMenuItems = [
		{
			title: 'Site',
			name: 'site',
			onchange: handleChangeFilter,
			multipleSelect: true,
			options: [
				{
					value: 'alb',
					label: 'Alabama',
				},
				{
					value: 'alk',
					label: 'Alaska',
				},
				{
					value: 'arz',
					label: 'Arizona',
				},
				{
					value: 'chi',
					label: 'Chicago',
				},
				{
					value: 'ny',
					label: 'New York',
				},
			],
		},
		{
			title: 'School year',
			name: 'school_year',
			onchange: handleChangeFilter,
			options: [
				{
					value: '2021',
					label: '2021 - 2022',
				},
				{
					value: '2022',
					label: '2022 - 2023',
				},
				{
					value: '2023',
					label: '2023 - 2024',
				},
				{
					value: '2024',
					label: '2024 - 2025',
				},
				{
					value: '2025',
					label: '2025 - 2026',
				},
			],
		},
		{
			title: 'Drill type',
			name: 'drill_type',
			onchange: handleChangeFilter,
			options: [
				{
					value: 'active_shooter',
					label: 'Active shooter',
				},
				{
					value: 'bomb_threat',
					label: 'Bomb threat',
				},
			],
		},
		{
			title: 'Actual drill date',
			name: 'drill_date',
			content: (
				<Input
					placeholder="Select date"
					icon="calendar"
					value={getFilterValue('drill_date')}
					onChange={e =>
						handleChangeFilter('drill_date', e.target.value)
					}
				/>
			),
		},
		{
			title: 'Status',
			name: 'status',
			onchange: handleChangeFilter,
			options: [
				{
					value: 'completed',
					label: 'Completed',
				},
				{
					value: 'in_progress',
					label: 'In progress',
				},
			],
		},
	];

	return (
		<>
			{/* Example Layout */}
			<div className="container-fluid px-0 tab-section">
				<div className="row px-3 my-3">
					<div className="col-md-12">
						<div className="row justify-content-md-end pr-3">
							{/* Component to show the Filter Menu */}
							<FilterMenu
								items={filterMenuItems}
								getFilterValue={getFilterValue}
							/>
						</div>
					</div>
				</div>

				<div className="row px-3 my-3">
					<div className="col-md-12">
						{/* Component to show the bar with all the filters applied */}
						<FilterBar
							items={filterMenuItems}
							getFilterValue={getFilterValue}
							removeFilter={removeFilter}
						/>
					</div>
				</div>
			</div>

			<div className="container-fluid px-0 tab-section mt-3">
				<div className="row px-3 my-3">
					<div className="col-md-12">
						<div className='mb-2'>Filters Applied:</div>
						<pre>
							{JSON.stringify(filterData.filters, null, "\t")}
						</pre>
					</div>
				</div>
			</div>
		</>
	);
};
