import { Radio } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { Modal, LoadingModal, Button, RadioButtonGroup } from 'componentsV2';
import '../css/DownloadMap.scss';

export const DownloadMap = ({
	loading,
	downloadLoading,
	modalToShow,
	closeModal,
	modalData,
	handleDownloadMap,
}) => {
	const [type, setType] = useState('jpeg');

	useEffect(() => {}, [modalToShow]);
	const handleChange = ({ target }) => {
		setType(target.value);
	};
	const footer = (
		<div className="button-div-styles">
			<Button text={'Cancel'} type="tertiary" intent="default" onClick={closeModal} />
			<Button text={'Download'} intent="default" onClick={() => handleDownloadMap(type)} />
		</div>
	);

	return (
		<>
			{ downloadLoading && (
				<LoadingModal
					isOpen={modalToShow}
					loading={downloadLoading}
					onClose={closeModal}
					customClassName={'map-container-modals'}
				/>
			)}
			{ !downloadLoading && (
				<Modal
					isOpen={modalToShow}
					onClose={closeModal}
					title={modalData.modalTitle}
					showCloseButton={false}
					loading={loading}
					size='small'
					customFooter={footer}
					customClassName={'download-map-modal-container map-container-modals'}
				>
					<div className="col-md-12">
						<RadioButtonGroup
							label=""
							onChange={handleChange}
							selectedValue={type}
							size={'small'}
							bordered={false}
							required={true}
							layout={'block'}
						>
							<Radio label="JPEG" value="jpeg" />
							<Radio label="PDF" value="pdf" />
						</RadioButtonGroup>
					</div>
				</Modal>
			)}
		</>
	);
};
