import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { logoNavigateSwitch, logoExpired } from 'assets/icons';
import { Intent } from '@blueprintjs/core';
import { Button, ToasterContext } from 'componentsV2';
import ResetPassword from './ResetPassword';

export default function ResetPasswordExpired(props) {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [showResetSuccess, setShowResetSuccess] = useState(false);

	const toaster = useContext(ToasterContext);

	useEffect(() => {
		setEmail(props.match.params.email);
	}, [props.match.params.email]);

	const resetPasswordHandler = () => {
		setLoading(true);

		const token = JSON.parse(localStorage.getItem('user')).jwt;

		fetch(`${process.env.API_URL}/password-reset-emails`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({ email }),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					setShowResetSuccess(true);
				} else {
					setShowResetSuccess(false);
					setLoading(false);
					toaster('Password reset email failed to send', Intent.DANGER);
				}
			});
	};

	const handleSubmit = event => {
		event.preventDefault();
		resetPasswordHandler();
	};

	return (
		<div className="expired-container">
			<div className="expired-wrapper">
				<div className="expired-box">
					<img alt="Navigate360 Logo" src={logoNavigateSwitch} />
					{!showResetSuccess && (
						<>
							<img alt="Expired link" src={logoExpired} />

							<div className="expired-link-message">
								<h6>Oops!</h6> <p>It seems like the link has expired</p>
							</div>

							<Button
								wrapperClass="w-100"
								text="Request New Link"
								onClick={handleSubmit}
								loading={loading}
								disabled={loading}
							/>
						</>
					)}
					{showResetSuccess && (
						<ResetPassword handleResendLink={handleSubmit} email={email} />
					)}
				</div>
			</div>
		</div>
	);
}

ResetPasswordExpired.propTypes = {
	match: PropTypes.object,
};
