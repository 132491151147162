/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pencil } from 'assets/icons';
import { StatusPilsner } from 'components/StatusPilsner';

const Wrapper = styled.div`
	padding: 16px;
	border-radius: 3px;
	background: #ffffff;
	border: 1px solid rgba(16, 22, 26, 0.15);

	a:hover {
		text-decoration: underline;
	}

	.drill-card-header {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		h2 {
			color: #10161a;
			font-size: 18px;
			line-height: 21px;
			font-weight: bold;
			font-style: normal;
			margin-bottom: 0px;
			letter-spacing: 0.3px;
			font-family: Merriweather;

			span {
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				font-style: normal;
				letter-spacing: 0px;
				font-family: Nunito Sans;
			}
		}

		a {
			color: #9f1c3a;
			font-size: 14px;
			line-height: 20px;
			font-style: normal;
			font-weight: normal;
			font-family: Nunito Sans;
		}
	}

	.drill-row {
		padding: 15px 0;
		border-bottom: 1px solid #ebf1f5;

		:last-child {
			border-bottom: none;
		}

		.dr-header {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;

			.drh-left {
				display: flex;
				align-items: center;
				flex-direction: row;

				p {
					font-size: 14px;
					text-align: left;
					font-weight: 400;
					line-height: 20px;
					font-style: normal;
					margin-bottom: 0px;
					letter-spacing: 0px;
					font-family: Nunito Sans;
				}

				.pilsner {
					margin-left: 5px;
				}
			}

			.drh-right {
				img {
					width: 15px;
					height: 15px;
					margin-top: -3px;
					margin-right: 7px;
				}

				a {
					color: #9f1c3a;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					text-align: center;
					font-style: normal;
					letter-spacing: 0px;
					font-family: Nunito Sans;
				}
			}
		}

		.dr-body {
			color: #5c7080;
			font-size: 14px;
			line-height: 20px;
			font-style: normal;
			font-weight: normal;
			font-family: Nunito Sans;
		}
	}
`;

const DrillsCard = ({
	nameOfCard = '',
	drills = [],
	onViewAllClick,
	onEditClick,
}) => (
	<Wrapper className="drill-card">
		<div className="drill-card-header">
			<h2>
				{nameOfCard} <span>(30 days)</span>
			</h2>

			<a onClick={onViewAllClick}>View all drills</a>
		</div>

		{drills?.map(item => (
			<div key={item?.scheduleId?.toString()} className="drill-row">
				<div className="dr-header">
					<div className="drh-left">
						<p>{item?.drillType?.name}</p>
						{item?.status?.toLowerCase() === 'overdue' && (
							<StatusPilsner type="overdue" />
						)}
					</div>

					<div className="drh-right">
						<div>
							<a onClick={() => onEditClick(item)}>
								<img src={pencil} alt="" />
								Edit
							</a>
						</div>
					</div>
				</div>

				<div className="dr-body">
					<div>{item.buildingName}</div>
					<div>{item.timestamp}</div>
				</div>
			</div>
		))}
	</Wrapper>
);

export { DrillsCard };

DrillsCard.propTypes = {
	nameOfCard: PropTypes.string.isRequired,
	drills: PropTypes.array.isRequired,
	onViewAllClick: PropTypes.func.isRequired,
	onEditClick: PropTypes.func.isRequired,
};
