import React, { useEffect, useState } from 'react';
import { Dropdown } from 'componentsV2/Dropdown';
import { BuildingsService } from 'services/BuildingsService';
import { filterSitesPerPermissionCode, checkPermissionOnDistrict } from 'utilities/permissions';
import { useSelector } from 'react-redux';

const SiteDropdownSingleSelect = ({
	label = 'Site',
	isRequired = false,
	error = '',
	selectedSite = null,
	onChange = site => {},
	disabled = false,
	includeDisabled = false,
	sitesList = [],
	permission = null,
	toAllLabel = null,
	includeAllOption = false,
	labelState= false,
}) => {
	const [sites, setSites] = useState(null);
	const permissions = useSelector(state => state.route.permissions);
	let hasPermissionOnDistrict = false;
	if (permission) {
		hasPermissionOnDistrict = checkPermissionOnDistrict(permission);
	}
	useEffect(() => {
		if (sitesList.length > 0) {
			setSites(sitesList);
		} else {
			BuildingsService.getAll(includeAllOption, includeDisabled).then(serviceBuildings => {
				let sites = [];
				if (permission) {
					sites = filterSitesPerPermissionCode(permission, permissions, serviceBuildings);
				} else {
					sites = serviceBuildings;
				}

				if (!!toAllLabel && (hasPermissionOnDistrict || !permission)) {
					sites.unshift({ label: toAllLabel, value: null });
				}
				setSites(sites);
			});
		}
	}, [includeDisabled]);

	return (
		<>
			<Dropdown
				label={label}
				placeholder="Select a site"
				value={selectedSite}
				options={sites}
				onChange={onChange}
				disabled={disabled}
				isRequired={isRequired}
				error={!!error}
				errorMessage={error}
				labelState={labelState}
			/>
		</>
	);
};

export { SiteDropdownSingleSelect };
