import React, { useEffect, useState } from 'react';
import {
    LoadingSpinner,
    EmptyState,
    messagesEmptyState,
} from 'componentsV2';
import { AlarmChatMessage } from './';
import { Helper } from 'utilities/helper';

export const AlarmChatHistory = ({
    loading,
    pastAlarmHistory,
}) => {

    const [messagesCount, setMessagesCount] = useState(0);
    const [messagesByChatRoom, setMessagesByChatRoom] = useState([]);

    useEffect(() => {
        if (pastAlarmHistory?.chatHistory?.length > 0) {
            // Get the order of the chats (Admins, All, Custom)
            let count = 0;
            let chatRoomsOrder = [];
            let messages = [];
            pastAlarmHistory.chatHistory.map((chatRoom, key) => {
                // Get the first message from the room in a separate array to order
                if (chatRoom?.messages?.length > 0) {
                    count = count + chatRoom.messages.length;
                    chatRoomsOrder.push({
                        key,
                        name: chatRoom.name,
                        firstMessageDate: chatRoom.messages[0].send_date,
                    });

                }
            });
            setMessagesCount(count);

            // Sort the Array by firstMessageDate
            chatRoomsOrder = Helper.sortByField(chatRoomsOrder, 'firstMessageDate');

            chatRoomsOrder.map(room => {
                messages.push(
                    pastAlarmHistory.chatHistory[room.key]
                )
            });
            setMessagesByChatRoom(messages);
        }
    }, [pastAlarmHistory]);

    return (
        <div className="alarm-card-container w-40">
            {loading && <LoadingSpinner />}
            {!loading && (
                <>
                    <div className="alarm-card-header">
                        <div className="alarm-card-title">Chat history ({messagesCount} message{messagesCount !== 1 ? "s" : ""})</div>
                    </div>
                    <div className="alarm-chat-container">
                        {messagesCount === 0 ? (
                            <EmptyState
                                header="No messages"
                                icon={messagesEmptyState}
                                showCircles={false}
                            />
                        ) : (
                            <div className="alarm-chat-messages-container">
                                {messagesByChatRoom.map((chatRoom, chatRoomKey) => (
                                    <div
                                        key={`chatRoom-${chatRoomKey}`}
                                        className="alarm-chat-room-container"
                                    >
                                        {chatRoom.messages.map((message, key) => (
                                            <AlarmChatMessage
                                                key={message.id}
                                                messageKey={key}
                                                message={message}
                                                roomName={chatRoom.user_name ? chatRoom.user_name : chatRoom.name}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div >
    )
}
