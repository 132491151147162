import { HttpService } from 'networking/HttpService';
import { MONTH_MAP } from '../utilities/dates';

const GENERIC_ERROR = new Error('An error occurred, please try again');

export const DrillsStatsService = {
	fetchBuildingStats: (year, drillTypeUUID, month) => {
		// gets stats for every building but filters by month
		let route = `/building-drill-stats?year=${year}`;

		if (drillTypeUUID && drillTypeUUID !== 'All drill types') {
			route += `&drillTypeUUID=${drillTypeUUID}`;
		}

		if (month && month !== 'All months') {
			route += `&month=${month}`;
		}

		return HttpService({
			method: 'GET',
			route,
		}).then(res => {
			if (Object.hasOwn(res.data, 'buildingStats') && Object.hasOwn(res.data, 'overview')) {
				return res.data;
			}

			throw GENERIC_ERROR;
		});
	},
	fetchMonthlyStats: (year, drillTypeUUID, buildingId = null) => {
		// gets stats for every year but filters by building
		let route = `/monthly-drill-stats?year=${year}`;

		if (drillTypeUUID) {
			route += `&drillTypeUUID=${drillTypeUUID}`;
		}

		if (buildingId) {
			route += `&buildingId=${buildingId}`;
		}

		return HttpService({
			method: 'GET',
			route,
		}).then(res => {
			if (Object.hasOwn(res.data, 'monthlyStats') && Object.hasOwn(res.data, 'overview')) {
				const formattedData = {};
				Object.keys(res.data.monthlyStats).forEach(keyYear => {
					const namedMonths = {};
					Object.keys(res.data.monthlyStats[keyYear]).forEach(keyMonth => {
						namedMonths[MONTH_MAP[keyMonth]] = res.data.monthlyStats[keyYear][keyMonth];
					});

					formattedData[keyYear] = namedMonths;
				});

				return {
					...res.data,
					monthlyStats: formattedData,
				};
			}

			throw GENERIC_ERROR;
		});
	},
};
