import { HttpService } from 'networking/HttpService';

const GENERIC_ERROR = new Error('An error occurred, please try again');

export const DrillsSettingsService = {
	fetchPublicViewableSchools: () => {
		return HttpService({
			method: 'GET',
			route: '/public-drill-settings',
		}).then(res => {
			if (Array.isArray(res.data)) {
				return res.data;
			}

			throw GENERIC_ERROR;
		});
	},
	emailLinkToRecipient: (buildingId, recipient, comments) => {
		return HttpService({
			method: 'POST',
			route: '/public-drill-link-emails',
			body: {
				buildingId,
				recipient,
				comments,
			},
		}).then(res => {
			if (res.statusCode === 201) {
				return true;
			}

			throw GENERIC_ERROR;
		});
	},

	enablePublicViewable: (buildingId, isPublic) => {
		return HttpService({
			method: 'POST',
			route: '/public-drill-settings',
			body: {
				settings: [
					{
						buildingId,
						isPublic,
					},
				],
			},
		}).then(res => {
			if (res.statusCode === 201) {
				return res.data;
			}

			throw GENERIC_ERROR;
		});
	},

	getSafetyOfficers: () => {
		return HttpService({
			method: 'GET',
			route: '/drills/safety-officers',
		}).then(res => {
			if (Array.isArray(res.data)) {
				return res.data;
			}

			throw GENERIC_ERROR;
		});
	},
	addSafetyOfficers: (existingUserIds, newPeople) => {
		return HttpService({
			method: 'POST',
			route: '/drills/safety-officers',
			body: {
				userIds: existingUserIds,
				people: newPeople,
			},
		}).then(res => {
			if (Array.isArray(res.data)) {
				return res.data;
			}

			throw GENERIC_ERROR;
		});
	},
	removeSafetyOfficer: userId => {
		return HttpService({
			method: 'DELETE',
			route: `/drills/safety-officers/${userId}`,
		}).then(res => {
			if (res?.data === 'success') {
				return true;
			}

			throw GENERIC_ERROR;
		});
	},
};
