import React, { useEffect, useState, useContext } from 'react';
import { Dropdown, FormModal, Intent, ToasterContext, FeaturedIcon } from 'componentsV2';
import PropTypes from 'prop-types';
import { AssessTemplatesService } from 'services';

export const ImportTemplateModal = ({ onClose, loadData }) => {
	const [templateOptions, setTemplateOptions] = useState(null);
	const [templateSelectedValue, setTemplateSelectedValue] = useState(null);
	const [loading, setLoading] = useState(false);
	const toaster = useContext(ToasterContext);

	useEffect(() => {
		getNavigateTemplates();
	}, []);

	const getNavigateTemplates = async () => {
		await AssessTemplatesService.getTemplates(false, true, true)
			.then(resp => {
				setTemplateOptions(resp.data.templates);
			})
			.catch(() => {
				toaster(
					`There was an error getting the templates. Please try again.`,
					Intent.DANGER,
				);
			});
	};

	const onImport = () => {
		setLoading(true);
		AssessTemplatesService.importTemplate(templateSelectedValue.id)
			.then(() => {
				toaster('You have successfully imported this template.', Intent.SUCCESS);
				setLoading(false);
			})
			.catch(() => {
				toaster(
					`There was an error importing ${templateSelectedValue.name}. Please try again.`,
					Intent.DANGER,
				);
				setLoading(false);
			})
			.finally(() => {
				onClose();
				loadData();
			});
	};

	return (
		<FormModal
			isOpen
			onClose={onClose}
			showCloseButton
			title="Import template"
			confirmBtn={{
				title: 'Import',
				onClick: () => onImport(),
				disabled: !templateSelectedValue,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: () => onClose(),
			}}
			loading={loading}
			loadingMessage="Importing..."
			scrollContent={false}
		>
			<div className="import-assess-template-modal w-100">
				<div className="info-main-container">
					<div className="info-icon-container">
						<FeaturedIcon icon="info-sign" size="md" type="Primary" className="icon" />
					</div>
					<div className="info-text-main-container">
						<span className="info-text-sub-container">
							Please allow 1-2 minutes for the system to finish importing template
						</span>
					</div>
				</div>
				<Dropdown
					label="Navigate360 Templates"
					placeholder="Select template"
					optionLabel="name"
					optionValue="id"
					options={templateOptions}
					value={templateSelectedValue}
					onChange={item => setTemplateSelectedValue(item)}
				/>
			</div>
		</FormModal>
	);
};

ImportTemplateModal.propTypes = {
	onClose: PropTypes.func,
	loadData: PropTypes.func,
};
