import React from 'react';
import { Icon } from '@blueprintjs/core';

export const AlarmContentItem = ({
    icon,
    label,
    value,
}) => {
    return (
        <div className="alarm-content-item">
            {icon && (
                <Icon icon={icon} />
            )}
            <div className="alarm-content-item-info">
                <span className="alarm-item-info-label">
                    {label}
                </span>
                <span className="alarm-item-info-value">
                    {value}
                </span>
            </div>
        </div>
    )
}
