import React, { useState, useEffect } from 'react';
import { BootstrapedTable, TableCell, Badge } from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import { CheckinGuardianStatus } from './Shared';
export const GuardianWithStudentsTable = ({
	selectedStudents,
	setSelectedStudents,
	goToStudentsList,
	setCurrentSelectedStudent,
	currentSelectedStudent,
	manualCheckIn,
}) => {
	const studentsData = selectedStudents;
	const [columns, setColumns] = useState([
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'student',
			dataField: 'student',
			text: "Student's name",
			sort: false,
			headerStyle: () => ({ width: !manualCheckIn ? '70%' : '' }),

			formatter: (value, row) => (
				<>
					<TableCell
						text={`${row.firstName} ${
							row.middleInitial != null ? row.middleInitial.slice(0, 1) + '.' : ''
						} ${row.lastName}`}
						supportText={
							<>
								Grade: {row.grade} <br />
							</>
						}
					/>
					<p className="tableStudentStatus">
						<CheckinGuardianStatus item={row} includeReunify={false} />
					</p>
				</>
			),
		},
		{
			key: 'relation',
			dataField: 'relation',
			text: 'Relationship to student',
			classes: 'tableStudentRelation',

			formatter: (value, row) => (
				<>
					<p className="tableStudentRelation">
						{manualCheckIn
							? row.relation
							: row.parentLabel == 'Parent'
							? 'Parent or Legal Guardian'
							: row.parentLabel}
					</p>
				</>
			),
		},
	]);

	const onRemoveAction = item => {
		setCurrentSelectedStudent(null);
		setSelectedStudents(oldData => {
			const students = oldData.filter(student => student.id != item.id);
			if (students.length == 0) {
				goToStudentsList();
			}
			return students;
		});
	};
	useEffect(() => {
		if (manualCheckIn) {
			columns.push({
				key: 'actions',
				text: 'Actions',
				dataField: 'id',

				formatter: (value, row) => (
					<Icon
						size={16}
						className="trash_icon"
						icon="trash"
						onClick={() => onRemoveAction(row)}
					/>
				),
			});
			setColumns(columns);
		}
	}, []);

	return (
		<div className="GuardianWithStudsentsTable__Wrapper">
			<BootstrapedTable
				keyField="id"
				className="checkin-students-table-list"
				data={studentsData}
				columns={columns}
				borderNone={true}
				showPagination={false}
			/>
		</div>
	);
};
