import React from 'react';
import { PageHeader, CustomTabs } from 'componentsV2';
import { Tab } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import { Credentials } from './Credentials';
import { Alarms } from './Alarms';
import './EditIntegrations.scss';
import { Buildings } from './Buildings';

export const EditIntegrations = ({ location }) => {
	const partnerId = location?.state?.data;
	const breadcrumbItems = [
		{ label: 'Rapid Alarm', url: '/rapid-alarms' },
		{ label: 'Settings', url: '/rapid-alarms/settings' },
		{ label: 'Edit 911Cellular integration' },
	];
	const permissions = useSelector(state => state.route.permissions);
	const isSuperAdmin = permissions.isSysAdmin;
	const editPermission = checkPermission('respond_edit', permissions);

	return (
		<div className="edit-integrations-main-container">
			<PageHeader
				title="Edit 911Cellular integration"
				subtitle="Manage your 911Cellular integration settings."
				breadcrumbItems={breadcrumbItems}
			/>

			<CustomTabs>
				{isSuperAdmin && (
					<Tab
						id={0}
						key={0}
						title="Credentials"
						panel={<Credentials {...{ partnerId }} />}
						fill
						className="CustomTabs__Tab"
					/>
				)}
				{(isSuperAdmin || editPermission) && (
					<Tab
						id={1}
						key={1}
						title="Alarms"
						panel={<Alarms {...{ partnerId }} />}
						fill
						className="CustomTabs__Tab"
					/>
				)}
				{(isSuperAdmin || editPermission) && (
					<Tab
						id={2}
						key={2}
						title="Buildings"
						panel={<Buildings {...{ partnerId }} />}
						fill
						className="CustomTabs__Tab"
					/>
				)}
			</CustomTabs>
		</div>
	);
};
