import React from 'react';
import { NavLink } from 'react-router-dom';
import { BootstrapedTable, sortingStyle, Badge, SORTING_DIR } from 'componentsV2';
import { formatDate } from 'utilities/dates';
import { PlansActionList } from './PlansActionList';
import { DISTRICT_LIST_PATH } from '../../';

export const PlansTable = ({ list, history }) => {
	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Plan title',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (
				<NavLink
					exact
					to={{
						pathname: `${DISTRICT_LIST_PATH}/view/${row.id}`,
					}}
				>
					{value}
				</NavLink>
			),
		},
		{
			key: 'buildingName',
			dataField: 'buildingName',
			text: 'Site',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'status',
			dataField: 'status',
			text: 'Status',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				let type = 'Primary';

				switch (value) {
					case 'incomplete':
						type = 'Primary';
						break;
					case 'archived':
						type = 'Gray';
						break;
					case 'completed':
						type = 'Success';
						break;
					default:
						type = 'Primary';
						break;
				}

				return (
					<>
						<Badge
							type={type}
							size="md"
							text={value.charAt(0).toUpperCase() + value.slice(1)}
						/>
						<span className="gray-400-color">
							{' '}
							{row.totalComplete}/{row.totalSections} pages completed
						</span>
					</>
				);
			},
		},
		{
			key: 'allUpdatesCount',
			dataField: 'allUpdatesCount',
			text: 'Pending updates',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			sortValue: (value, row) => (row.status == 'archived' ? null : value),
			formatter: (value, row) => {
				if (row.status == 'archived') {
					return null;
				}
				if (value > 0) {
					const text = (
						<>
							<span className="font-bold"> {value} </span> pending updates
						</>
					);

					return (
						<NavLink
							exact
							to={{
								pathname: `${DISTRICT_LIST_PATH}/${row.id}`,
								state: { openPendingUpdates: true },
							}}
						>
							<Badge type="Warning" size="md" text={text} />
						</NavLink>
					);
				}

				return null;
			},
		},
		{
			key: 'lastUpdateDate',
			dataField: 'lastUpdateDate',
			text: 'Last modified',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => formatDate(value) || 'N/A',
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => <PlansActionList plan={row} history={history} />,
		},
	];

	return (
		<BootstrapedTable
			remote={false}
			defaultSorted={[
				{
					dataField: 'name',
					order: SORTING_DIR.ASC,
				},
			]}
			keyField="id"
			data={list}
			columns={columns}
		/>
	);
};
