import React from 'react';
import { EmptyState, Animated, noResult } from 'componentsV2';

export const NoTextFoundState = () => (
	<Animated>
		<EmptyState
			icon={noResult}
			header="No text found"
			description="Your search didn’t match any texts. Please try again."
		/>
	</Animated>
);
