import React, { useState } from "react";
import PropTypes from 'prop-types';
import { SitesDropdownMultiSelect } from "components/SitesDropdownMultiSelect";
import { SearchBar } from "components/SearchBar";
import { ContentSwitcher } from "componentsV2";
import './css/Filterations.scss';

export const Filterations = ({
    defaultSelectedSites,
    setFilterationData,
    filterationData,
    setLoading,
    selectedSites,
    setSelectedSites
}) => {

    const mapFilterTabs = [
        {
            id: 'viewAll',
            label: 'View all'
        },
        { 
            id: 'unarchived',
            label: 'Unarchived'
        },
        { 
            id: 'archived',
            label: 'Archived'
        },
    ];

    const onSearchHandler = title => {
        setLoading(true);
        setFilterationData(oldData => ({
            ...oldData,
            title: title,
            page: 1
        }));
    }

    const onChangeSiteHandler = sites => {
        setLoading(true);
        setFilterationData(oldData => ({
            ...oldData,
            buildingIds: sites.map(site => site.value),
            page:1
        }));
        setSelectedSites(sites);
    }

    const onClickHandler = (tab) => { 
        let includeArchived = undefined;
        if (tab && tab[0]) {
            setLoading(true);
            switch (tab[0]) {
                case 'unarchived':
                    includeArchived = 'false';
                break;
                case 'archived':
                    includeArchived = true;
                break;
            }
        }
        setFilterationData(oldData => ({
            ...oldData,
            includeArchived: includeArchived,
            page: 1
        }));
    }

	return (
        <section className="maps-filteraion">
            <div className="left-side">
                <ContentSwitcher
                    onClick={onClickHandler}
                    tabs={mapFilterTabs}
                    multi={false}
                    defaultActiveTabs={['viewAll']}
                    preserveTab={true} 
                />
            </div>
            <div className="right-side">
                <SearchBar
                    placeholder="Search by name..." 
                    icon="search"
                    className="search-bar-text"
                    value={filterationData.title}
                    onSearch={onSearchHandler}
                />
                <SitesDropdownMultiSelect
                    label={null}
                    value={selectedSites}
                    placeholder="Select a Site"
                    onChange={onChangeSiteHandler}
                    visibleOptions={1}
                    labelState={false}
                    className="sites-mddl"
                    allowSelectAll={false}
                />
            </div>
        </section>
	);
}

Filterations.propTypes = {
    defaultSelectedSites: PropTypes.array,
    setFilterationData: PropTypes.func.isRequired,
    filterationData: PropTypes.object,
    setLoading: PropTypes.func
};