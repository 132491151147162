// reunifications lists
export const REUNIFICATION_STUDENTS_LIST = 'studentsList';
export const REUNIFICATION_GUARDIANS_LIST = 'guardiansList';

// reunfication student statuses
export const REUNIFICATION_STUDENT_STATUS_CHECKEDIN = 'checkedIn';
export const REUNIFICATION_STUDENT_STATUS_REUNIFIED = 'reunified';
export const REUNIFICATION_STUDENT_STATUS_NOSTATUS = 'no_status';

// reunfication guardian statuses
export const STATUS_REUNIFICATION_COMPLETE = 'Reunification Complete';
export const STATUS_NO_STUDENTS_REUNIFIED = 'No Status';
export const STATUS_PARTIALLY_REUNIFIED = 'Partially Reunified';
