import React from 'react';
import { Tag } from './';
import './css/TagPattern.scss';

export const TagPattern = () => {

	const tagClick = () => {
		console.log('tag clicked');
	}
	return (
		<div className='TagPattern__Container'>
			<div>
				<Tag
					text={'Text'}
					type={'default'}
					size={'sm'}
					hasCloseIcon={true}
					onClick={tagClick}
				/>
				<Tag
					text={'Text'}
					type={'default'}
					size={'md'}
					hasCloseIcon={true}
					onClick={tagClick}
				/>
				<Tag
					text={'Text'}
					type={'default'}
					size={'lg'}
				/>
				<Tag
					text={'Text'}
					type={'default'}
					size={'xl'}
				/>
				<Tag
					text={'Text'}
					type={'default'}
					size={'xxl'}
					hasCloseIcon={true}
					onClick={tagClick}
				/>
			</div>
			<div>
				<Tag
					text={'Text'}
					type={'disabled'}
					size={'sm'}
					hasCloseIcon={true}
					onClick={tagClick}
				/>
				<Tag
					text={'Text'}
					type={'disabled'}
					size={'md'}
					hasCloseIcon={true}
					onClick={tagClick}
				/>
				<Tag
					text={'Text'}
					type={'disabled'}
					size={'lg'}
				/>
				<Tag
					text={'Text'}
					type={'disabled'}
					size={'xl'}
				/>
				<Tag
					text={'Text'}
					type={'disabled'}
					size={'xxl'}
					hasCloseIcon={true}
					onClick={tagClick}
				/>
			</div>
			<div>
				<Tag
					text={'Text'}
					type={'error'}
					size={'sm'}
					hasCloseIcon={true}
					onClick={tagClick}
				/>
				<Tag
					text={'Text'}
					type={'error'}
					size={'md'}
					hasCloseIcon={true}
				/>
				<Tag
					text={'Text'}
					type={'error'}
					size={'lg'}
					clickable={false}
				/>
				<Tag
					text={'Text'}
					type={'error'}
					size={'xl'}
					clickable={false}
				/>
				<Tag
					text={'Text'}
					type={'error'}
					size={'xxl'}
					hasCloseIcon={true}
					onClick={tagClick}
				/>
			</div>
			<div>
				<Tag
					text={'Text'}
					type={'primary'}
					size={'sm'}
					hasCloseIcon={true}
					onClick={tagClick}
				/>
				<Tag
					text={'Text'}
					type={'primary'}
					size={'md'}
					hasCloseIcon={true}
				/>
				<Tag
					text={'Text'}
					type={'primary'}
					size={'lg'}
					clickable={false}
				/>
				<Tag
					text={'Text'}
					type={'primary'}
					size={'xl'}
					clickable={false}
				/>
				<Tag
					text={'Text'}
					type={'primary'}
					size={'xxl'}
					hasCloseIcon={true}
					onClick={tagClick}
				/>
			</div>
		</div>
	);
};

