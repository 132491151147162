import React from 'react';
import { Button } from 'componentsV2';
import { DELETE_SHAPE_MODAL, GEOMETRY_TYPE_ICON, GEOMETRY_TYPE_ROUTE } from '../../consts';

export const ShapeActionsList = ({ shape, setModalToShow, editPermission, isEditMode }) => {
	const onDeleteAction = () => {
		setModalToShow(DELETE_SHAPE_MODAL);
	};
	return (
		isEditMode && (
			<div className="contextMenu" id="shapeActionsList">
				{shape &&
					(shape.geometryType === GEOMETRY_TYPE_ICON ||
						shape.geometryType === GEOMETRY_TYPE_ROUTE) &&
					editPermission && (
						<Button
							text="Delete"
							icon="trash"
							iconColor="gray-dark"
							type="ghostDefault"
							className="actionButtonElement"
							onClick={onDeleteAction}
						/>
					)}
			</div>
		)
	);
};
