import React from 'react';
import closeIcon from '../assets/closeIcon.svg';
import './css/MapOptionCard.scss';
import { LoadingSpinner } from 'componentsV2';
import { Icon } from '@blueprintjs/core';

export const MapOptionCard = ({
	isOpen,
	title,
	subTitle = null,
	onClick,
	className = '',
	children,
	headerItem = '',
	loading = false,
	titleBorder = true,
	showLeftArrow = false,
	handleArrowClicked,
}) => {
	return (
		<div
			className={`map-option-card-wrapper ${
				isOpen ? 'is-visible' : 'is-hidden'
			} ${className}`}
		>
			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<div className="map-option-card-content">
					<div className="map-option-card-header">
						<div className="map-option-card-header-title">
							<div className="map-option-card-title">
								{showLeftArrow && (
									<Icon
										icon="arrow-left"
										className="icon"
										onClick={handleArrowClicked}
									/>
								)}
								{title}
							</div>
							<div className="close-map-option-card">
								<a onClick={onClick}>
									<img src={closeIcon} alt="Close" />
								</a>
							</div>
						</div>
						{ subTitle && ( <div className="map-option-card-subtitle">{subTitle}</div> )}
						{titleBorder && <div className="title-bottom-border" />}
						{headerItem && (
							<div className="map-option-card-header-additional">{headerItem}</div>
						)}
					</div>
					<div className="details-card-container">{children}</div>
				</div>
			)}
		</div>
	);
};
