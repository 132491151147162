import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';
import { Button } from 'componentsV2/Button';
import './css/TabHeader.scss';

const TabHeader = ({
	title,
	subtitle,
	subsubtitle,
	actionButton,
	loadAddModal,
	editPermission,
	loadHowToUseModal
}) => (
	<div className="tabHeader__wrapper">
		<div className="tab-header-title">
			<div className="d-flex flex-row justify-content-between align-items-center">
				<div className="d-flex flex-row justify-content-between">
					<span className="title">{title}</span>
				</div>

				{actionButton && editPermission && (
					<Button
						text={'Custom scenario'}
						size={'md'}
						icon={'plus'}
						type={'secondaryDefault'}
						onClick={() => loadAddModal()}
					/>
				)}
			</div>
			<div className="row mx-0">
				<span className="subtitle">{subtitle}</span>
			</div>
			<button className="row mx-0 p-0 bg-transparent border-0" onClick={() => loadHowToUseModal()}>
				<span className="subsubtitle">{subsubtitle}</span>
				<Icon className="mx-2" icon="arrow-right" />
			</button>
		</div>
	</div>
);

export { TabHeader };

TabHeader.propTypes = {
	title: PropTypes.string.isRequired,
};
