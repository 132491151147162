import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LoadingSpinner, myClasses, noResult } from 'componentsV2';
import { ReunificationGuardiansEmptyState, ReunificationGuardiansTable } from './components';
import { RollCallDetailsContext } from '../../..';
import { ReunificationService } from 'services/ReunificationService';
import { initializeSocket } from 'utilities/socket';
import { ReunificationContext } from '../Provider';
import { useSelector } from 'react-redux';
import { RapidAlarmsProviders } from '../../../../Providers';

export const ReunificationGuardians = () => {
	const { alarmId } = useContext(RollCallDetailsContext);
	const [loading, setLoading] = useState(true);
	const [guardiansList, setGuardiansList] = useState([]);
	const [totalGuardians, setTotalGuardians] = useState(0);
	const [isEmptyData, setIsEmptyData] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [isSocketConnected, setSocketConnected] = useState(null);
	const toggleState = useSelector(state => state.route.toggleState);
	const [reloadList, setReloadList] = useState(false);

	const {
		guardianFilterationData,
		setGuardianFilterationData,
		setGaurdianFilterationDisabled,
		reloadGuardiansList,
	} = useContext(ReunificationContext);

	useEffect(() => {
		getCheckInGuardians();
	}, [guardianFilterationData, reloadGuardiansList, toggleState]);

	useEffect(() => {
		getCheckInGuardians(false);
	}, [reloadList]);

	useEffect(() => {
		setGaurdianFilterationDisabled(isEmptyData);
	}, [isEmptyData]);

	useEffect(() => {
		if (!isSocketConnected && alarmId) {
			const { initializedSocket } = initializeSocket([
				`alarms/${alarmId}/reunification/guardians`,
				`alarms/${alarmId}/reunification/students`,
			]);
			setSocketConnected(true);

			initializedSocket.on('event', event => {
				handleSocketEvents(event);
			});

			return () => {
				if (initializedSocket.connected) {
					console.log('Disconnecting socket...');
					initializedSocket.disconnect();
				}
			};
		}
	}, []);

	const handleSocketEvents = event => {
		switch (event.name) {
			case 'reunificationGuardians:checkIn':
			case 'reunificationStudents:reunified':
			case 'reunificationGuardians:updated':
			case 'reunificationStudents:checkIn':
			case 'reunificationStudents:checkInManual':
				setReloadList(value => !value);
				break;

			default:
				break;
		}
	};

	const getCheckInGuardians = (showLoading = true) => {
		if (showLoading) {
			setLoading(true);
		}
		ReunificationService.getCheckInGuardians(alarmId, guardianFilterationData)
			.then(response => {
				if (response.statusCode === 200) {
					setGuardiansList(response.data);
					setTotalGuardians(response._metadata.totalRecords);
					if (
						(isFirstRender && response.data.length === 0) ||
						(!isFirstRender &&
							response.data.length === 0 &&
							guardianFilterationData.guardianName == '' &&
							guardianFilterationData.statuses.length == 0)
					) {
						setIsEmptyData(true);
					} else {
						setIsEmptyData(false);
					}
				}
			})
			.catch(error => {
				setGuardiansList([]);
			})
			.finally(() => {
				if (showLoading) {
					setLoading(false);
				}
				setIsFirstRender(false);
			});
	};
	return (
		<>
			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<div>
					{isEmptyData && (
						<ReunificationGuardiansEmptyState
							header="No guardians added to Reunification"
							description={
								<>
									Guardians can be checked-in using the “Check-in guardian” button
									and will appear in this list to be reunified with the students
									related to them.
								</>
							}
							icon={myClasses}
							showCircles={false}
						/>
					)}
					{!isEmptyData && (
						<>
							{totalGuardians === 0 ? (
								<ReunificationGuardiansEmptyState
									header="No results found"
									description={
										<>
											Your search didn’t match any Guardian.
											<br /> Please try again.
										</>
									}
									icon={noResult}
								/>
							) : (
								<RapidAlarmsProviders>
									<ReunificationGuardiansTable
										guardiansList={guardiansList}
										totalGuardians={totalGuardians}
										filterData={guardianFilterationData}
										setFilterData={setGuardianFilterationData}
										alarmId={alarmId}
									/>
								</RapidAlarmsProviders>
							)}
						</>
					)}
				</div>
			)}
		</>
	);
};
