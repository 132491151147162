import React from "react";
import {
  BootstrapedTable,
  Button,
  sortingStyle,
  EmptyState,
  safetyplan,
  AlertModal,
} from 'componentsV2';
import '../css/SafetyPlanSettings.scss';

export const IncompletePlansTableContent = ({
                                              completedPlans,
                                              selectedPlans,
                                              setSelectedPlans,
                                              toBeSubmitted,
                                              messages,
                                              filtrationData,
                                              setFiltrationData,
                                              showConfirmationModal,
                                              closeModal,
                                              preSubmittingConfirmation,
                                              setSelectedPlansAsIncomplete,
                                              editPermission
                                            }) => {

  const columns = [
    {
      key: 'id',
      dataField: 'id',
      text: 'ID',
      hidden: true
    },
    {
      key: 'name',
      dataField: 'name',
      text: 'Template title',
      sort: true,
      classes: 'first-column',
      sortCaret: (order, column) => sortingStyle(order, column),
      formatter: (value, row) => value,
    },
    {
      key: 'LastModified',
      dataField: 'lastUpdateDate',
      text: 'Last modified',
      sort: true,
      sortCaret: (order, column) => sortingStyle(order, column),
      formatter: (value, row) => value,
    },
    {
      key: 'actions',
      dataField: 'id',
      text: '',
      formatter: (value, row) => (
        <div className="settings-single-action-link-container">
          <Button
            text="Set to incomplete"
            type="ghostPrimary"
            size="md"
            onClick={() => preSubmittingConfirmation([row])}
          />
        </div>
      )
    },
  ];

  const onTableChange = (type, {sortField, sortOrder}) => {
    if (type === 'sort') {
      let sortColumn = 'name';
      switch (sortField) {
        case 'lastModified':
          sortColumn = 'editedDate';
          break;
        default:
          sortColumn = sortField;
          break;
      }
      setFiltrationData({...filtrationData, 'sortKey': sortColumn, 'sortDir': sortOrder});
    }
  };

  return (
    <>
      {showConfirmationModal && (
        <AlertModal
          isOpen={showConfirmationModal}
          onClose={closeModal}
          title={`Are you sure you want to set ${messages[0]} to incomplete?`}
          subtitle={`This will change the status of ${messages[1]} in your district to incomplete. This can’t be undone.`}
          icon="error"
          iconColor="error"
          showCloseButton={true}
          alertConfirmButton={
            <Button
              text="Yes, set to incomplete"
              type="primaryDanger"
              size="md"
              onClick={() => setSelectedPlansAsIncomplete(toBeSubmitted)}
            />
          }
          alertCancelButton={
            <Button
              text="No, go back"
              type="tertiary"
              size="md"
              onClick={closeModal}
            />
          }
        />
      )}
      {completedPlans.length > 0 && (
        <BootstrapedTable
          keyField="id"
          data={completedPlans}
          columns={columns}
          onTableChange={onTableChange}
          onSelectRows={setSelectedPlans}
          selectedRows={selectedPlans}
          hasCheckbox={editPermission}
          showPagination={false}
          bordered={false}
        />
      )}
      {
        completedPlans.length == 0 && (
          <div className="settings-empty-state">
            <EmptyState
              header={"No plans to reset"}
              description={"There are no available safety plans to reset."}
              icon={safetyplan}
            >
            </EmptyState>
          </div>
        )
      }
    </>
  );
}
