/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Dropdown } from 'components/Dropdown';
import { PageHeader } from 'components/PageHeader';
import { Wysiwyg } from 'components/Wysiwyg';
import { SCHOOL_YEARS } from 'utilities/drills/mockData';
import { isSuccessfulRequest } from 'networking/HttpService';
import {
	DrillTypeInstructionsService,
	DrillTypesService,
	StatesService,
} from 'services';
import { SchoolYearDropdown } from 'components/SchoolYearDropdown';
import { StatesDropdown } from 'components/StatesDropdown';
import Swal from 'sweetalert2';

const Page = styled.div`
	padding: 8px 24px;

	.dropdown-filters {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.dropdown {
			margin-right: 24px;
			margin-bottom: 16px;
			width: calc(33% - 24px);
		}
	}

	.instructions-to-user {
		color: #5c7080;
		font-size: 18px;
		margin-top: 25%;
		font-weight: bold;
		line-height: 21px;
		text-align: center;
		font-style: normal;
		font-family: Merriweather;
	}

	.drill-instructions-fields {
		display: flex;
		flex-wrap: wrap;
		margin-top: 40px;
		flex-direction: row;
		justify-content: space-between;

		.wysiwyg {
			margin-bottom: 30px;
			width: calc(50% - 17px);
		}

		@media only screen and (max-width: 1445px) {
			.wysiwyg {
				width: 100%;
			}
		}
	}
`;

const getInstructionsForSelectedFilter = (instructions, state, year) => {
	if (!instructions) {
		return '';
	}
	return instructions[state][year] || '';
};

class ManageDrillInstructions extends React.Component {
	state = {
		isLoading: false,

		schoolYears: [],
		states: [],
		drillTypes: [],
		selectedSchoolYear: null,
		selectedState: null,
		selectedDrillType: null,

		autoSaveValue: null,
		schedulingInstructions: null,
		postingInstructions: null,

		visibleSchedulingInstructions: null,
		visiblePostingInstructions: null,
		lastSavedSchedulingInstructions: null,
		lastSavedPostingInstructions: null,
	};

	resetInstuctions = () => {
		this.setState({
			schedulingInstructions: null,
			postingInstructions: null,
			visibleSchedulingInstructions: null,
			visiblePostingInstructions: null,
			lastSavedSchedulingInstructions: null,
			lastSavedPostingInstructions: null,
		});
	};

	fetchInstructions = (state, year, drillTypeUUID) => {
		DrillTypeInstructionsService.getWithParams(
			state,
			year,
			drillTypeUUID,
		).then(res => {
			if (isSuccessfulRequest(res.statusCode)) {
				// Parse instructions
				const schedulingInstructions = res.data
					?.schedulingInstructionsJSON
					? JSON.parse(res.data.schedulingInstructionsJSON)
					: '';

				const postingInstructions = res.data?.postingInstructionsJson
					? JSON.parse(res.data.postingInstructionsJson)
					: '';

				return this.setState({
					schedulingInstructions,
					postingInstructions,
					visibleSchedulingInstructions: getInstructionsForSelectedFilter(
						schedulingInstructions,
						state,
						year,
					),
					visiblePostingInstructions: getInstructionsForSelectedFilter(
						postingInstructions,
						state,
						year,
					),
				});
			}
			return null;
		});
	};

	fetchDrillTypes = () => {
		const { selectedSchoolYear, selectedState } = this.state;
		DrillTypesService.getAll(
			false,
			selectedSchoolYear?.value,
			selectedState?.value,
		).then(drillTypes =>
			this.setState({
				drillTypes,
			}),
		);
	};

	fetchStates = () =>
		StatesService.getAll()
			.then(states =>
				this.setState({
					states,
				}),
			)
			.catch(() => new Error('An error occurred, please try again'));

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(_, prevState) {
		const {
			selectedState,
			selectedSchoolYear,
			selectedDrillType,
		} = this.state;
		// Just fetch instructions if all the fields were filled
		// or any of the fields already filled change
		if (
			selectedState &&
			selectedSchoolYear &&
			selectedDrillType &&
			(selectedState.value !== prevState.selectedState?.value ||
				selectedSchoolYear.value !==
					prevState.selectedSchoolYear?.value ||
				selectedDrillType.value !== prevState.selectedDrillType?.value)
		) {
			this.resetInstuctions();
			this.fetchInstructions(
				selectedState.value,
				selectedSchoolYear.value,
				selectedDrillType.uuid,
			);
		}
	}

	fetchData = () => {
		this.fetchDrillTypes();
		this.fetchStates();
		this.setState({
			schoolYears: SCHOOL_YEARS,
		});
	};

	// manages the dropdown
	selectYear = item => {
		// resets the 'year' and 'drill type' because different 'years' have different 'states' and 'drill types'
		this.setState({
			selectedSchoolYear: item,
			selectedState: null,
			selectedDrillType: null,
		});
	};

	selectState = item => {
		// resets the 'drill type' because different 'states' have different 'drill types'
		this.setState({
			selectedState: item,
			selectedDrillType: null,
		});
	};

	selectDrillType = item => {
		// selected a drill type does not have side effects
		this.setState({
			selectedDrillType: item,
		});
	};

	// handles copying the previous school year's instructions
	copySchedulingInstructions = () => {
		const {
			schedulingInstructions,
			selectedState,
			selectedSchoolYear,
		} = this.state;
		const previousYearInstructions = getInstructionsForSelectedFilter(
			schedulingInstructions,
			selectedState.value,
			selectedSchoolYear.value - 1,
		);
		if (!previousYearInstructions) {
			return Promise.reject(
				new Error('No instructions for previous year'),
			);
		}
		this.setState({
			visibleSchedulingInstructions: previousYearInstructions,
		});
		return Promise.resolve('');
	};

	copyPostingInstructions = () => {
		const {
			postingInstructions,
			selectedState,
			selectedSchoolYear,
		} = this.state;
		const previousYearInstructions = getInstructionsForSelectedFilter(
			postingInstructions,
			selectedState.value,
			selectedSchoolYear.value - 1,
		);
		if (!previousYearInstructions) {
			return Promise.reject(
				new Error('No instructions for previous year'),
			);
		}
		this.setState({
			visiblePostingInstructions: previousYearInstructions,
		});
		return Promise.resolve('');
	};

	// these funcs save the documents in the DB, and inform the user the time it was saved
	saveSchedulingInstructions = schedulingInstructions => {
		// todo: extract data, send to backend
		this.setState({
			autoSaveValue: 'Not Saved',
		});
		const {
			selectedState,
			selectedSchoolYear,
			selectedDrillType,
		} = this.state;
		DrillTypeInstructionsService.save(
			selectedState.value,
			selectedSchoolYear.value,
			selectedDrillType.uuid,
			schedulingInstructions,
			null,
		)
			.then(() => {
				this.setState({
					autoSaveValue: new Date(),
					lastSavedSchedulingInstructions: schedulingInstructions,
				});
				return null;
			})
			.catch();
	};

	savePostingInstructions = postingInstructions => {
		// todo: extract data, send to backend
		this.setState({
			autoSaveValue: 'Not Saved',
		});
		const {
			selectedState,
			selectedSchoolYear,
			selectedDrillType,
		} = this.state;

		DrillTypeInstructionsService.save(
			selectedState.value,
			selectedSchoolYear.value,
			selectedDrillType.uuid,
			null,
			postingInstructions,
		).then(res => {
			if (isSuccessfulRequest(res.statusCode)) {
				this.setState({
					autoSaveValue: new Date(),
					lastSavedPostingInstructions: postingInstructions,
				});
			}
			return null;
		});
	};

	saveBothInstructions = () => {
		// todo: extract data, send to backend
		this.setState({
			autoSaveValue: 'Not Saved',
		});
		const {
			lastSavedSchedulingInstructions,
			lastSavedPostingInstructions,
			selectedState,
			selectedSchoolYear,
			selectedDrillType,
		} = this.state;

		DrillTypeInstructionsService.save(
			selectedState.value,
			selectedSchoolYear.value,
			selectedDrillType.uuid,
			lastSavedSchedulingInstructions,
			lastSavedPostingInstructions,
		).then(res => {
			if (isSuccessfulRequest(res.statusCode)) {
				this.setState({
					autoSaveValue: new Date(),
					lastSavedSchedulingInstructions,
					lastSavedPostingInstructions,
				});
				Swal.fire({
					title: 'Success!',
					text: 'Instructions were saved',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
			}
			return null;
		});
	};

	render() {
		const {
			isLoading,
			drillTypes,
			selectedSchoolYear,
			selectedState,
			selectedDrillType,
			autoSaveValue,
			visiblePostingInstructions,
			visibleSchedulingInstructions,
		} = this.state;

		const { history } = this.props;

		if (isLoading) {
			return null;
		}

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Manage Drill Instructions"
					onBackBtnClick={history.goBack}
					autoSaveValue={autoSaveValue}
					actionTitle="Done"
					onActionClick={this.saveBothInstructions}
				/>

				{/* school, state, and drill type selectors */}
				<div className="dropdown-filters">
					{/* School year */}
					<SchoolYearDropdown
						selectedSchoolYear={selectedSchoolYear}
						onChange={item => this.selectYear(item)}
					/>

					{selectedSchoolYear && (
						<StatesDropdown
							selectedState={selectedState}
							onChange={item => this.selectState(item)}
						/>
					)}

					{selectedSchoolYear && selectedState && (
						<Dropdown
							label="Drill type"
							placeholder="Select a drill type"
							value={selectedDrillType}
							options={drillTypes}
							onChange={item => this.selectDrillType(item)}
						/>
					)}
				</div>

				{selectedSchoolYear && selectedState && selectedDrillType ? (
					/* edit scheduling and posting instructions */
					<div className="drill-instructions-fields">
						<Wysiwyg
							label="Scheduling instructions"
							actionHandler={{
								actionTitle: 'Copy from last year',
								onClick: this.copySchedulingInstructions,
								modalTitle: 'Copy instructions from last year',
								modalBody:
									'You are about to overwrite the instructions you have in your current year with the instructions from last year. Do you still wish to proceed?',
								successLabel: 'Copied from last year',
								errorLabel:
									'There are no instructions from last year',
							}}
							// the editor manages it's own state, no need to cache it twice
							initialValue={visibleSchedulingInstructions}
							onDebounce={this.saveSchedulingInstructions}
						/>

						<Wysiwyg
							label="Posting instructions"
							actionHandler={{
								actionTitle: 'Copy from last year',
								onClick: this.copyPostingInstructions,
								modalTitle: 'Copy instructions from last year',
								modalBody:
									'You are about to overwrite the instructions you have in your current year with the instructions from last year. Do you still wish to proceed?',
								successLabel: 'Copied from last year',
								errorLabel:
									'There are no instructions from last year',
							}}
							// the editor manages it's own state, no need to cache it twice
							initialValue={visiblePostingInstructions}
							onDebounce={this.savePostingInstructions}
						/>
					</div>
				) : (
					/* inform the user to pick from the drop down */
					<div className="instructions-to-user">
						{!selectedSchoolYear
							? 'Please select a School Year'
							: !selectedState
							? 'Please select a State to continue'
							: !selectedDrillType &&
							  'Please select a Drill Type'}
					</div>
				)}
			</Page>
		);
	}
}

ManageDrillInstructions.propTypes = {
	history: PropTypes.object,
};

export default ManageDrillInstructions;
