export const basicInfoValidation = formData => {
	let isValid = true;
	const errors = {};

	if (!formData.name || (formData.name && formData.name.trim('').length === 0)) {
		isValid = false;
		errors.name = 'Name is required';
	}
	if (!formData.icon) {
		isValid = false;
		errors.icon = 'Icon is required';
	}
	if (formData.soundEnabled == true && formData.sound === null) {
		isValid = false;
		errors.sound = 'Sound is required';
	}

	return {
		isValid,
		errors,
	};
};
