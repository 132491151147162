import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'components/Button';
import { Checkbox, Intent, InputGroup, InputRightElement } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ToasterContext } from 'pages/App';
import { decodeToken } from 'utilities';
import './AccountManger.css'; // import your CSS file
import { useSelector } from 'react-redux';
import AddRespondBuildingModal from './RespondBuilding/AddRespondBuildingModal';
import DeleteRespondBuildingModal from './RespondBuilding/DeleteRespondBuildingModal';
import UnsavedChangesModal from '../../../../components/Modals/UnsavedChangesModal';
import BuildingAlert from './RespondBuilding/BuildingAlert';
import SendTestNotificationModal from './RespondBuilding/SendTestNotificationModal';
import './RespondSettings.css';
import { AllowOnlyNumeric } from '../../../../utilities/validations';
import Password from '../../../../components/Password';

export default function ModuleRespondSettings({
	user,
	setTabHasChange,
	setUserupdated,
	isLoading,
	setIsLoading,
	isSuperAdmin,
	hasUserEditPermission,
	isMyAccount,
}) {
	const [buildingAlerts, setBuildingAlerts] = useState([]);
	const [modalToShow, setModalToShow] = useState(false);
	const url = process.env.API_URL;
	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const permissions = useSelector(state => state.route.permissions);
	const toaster = useContext(ToasterContext);
	const [btnTeacherSwitch, setBtnTeacherSwitch] = useState('switchon');
	const [validationErrors, setErrors] = useState({});
	const [btnAdminSwitch, setBtnAdminSwitch] = useState('switchon');
	const [disableTeacherBtn, setDisableTeacherBtn] = useState(false);
	const [disableAdminBtn, setDisableAdminBtn] = useState(false);
	const [disableSubmit, setDisableSubmit] = useState(true);
	const [showPassword, setShowPassword] = useState(false);
	const [formData, setFormData] = useState({
		accountType: user.accountType,
		alarmNotificationEmail: user.alarmNotificationEmail,
		alarmNotificationText: user.alarmNotificationText,
		buildingAlerts: buildingAlerts,
		respondAlarmPin: user.respondAlarmPin,
	});
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	const [deletedSite, setDeletedSite] = useState(null);
	const [isReadOnlyForm, setIsReadOnlyForm] = useState(false);
	const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
	const isRespondTypeAdmin = user.accountType == 'administrator';

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const getMappedSites = () => {
		let currentBuildings = [];

		user.buildingAlerts.forEach(buildingDetails => {
			currentBuildings.push(buildingDetails.building);
		});
		setBuildingAlerts(currentBuildings);
		setFormData({
			...formData,
			buildingAlerts: currentBuildings,
		});
	};

	useEffect(() => {
		if (!isRespondTypeAdmin) {
			setBtnTeacherSwitch('switchon');
			setBtnAdminSwitch('switchoff');
			setDisableTeacherBtn(true);
		} else {
			setBtnTeacherSwitch('switchoff');
			setBtnAdminSwitch('switchon');
			setDisableAdminBtn(true);
		}

		if (!hasUserEditPermission && !isSuperAdmin) {
			setDisableTeacherBtn(true);
			setDisableAdminBtn(true);
			setIsReadOnlyForm(true);
		}

		if (isMyAccount) {
			setDisableTeacherBtn(true);
			setDisableAdminBtn(true);
			setIsReadOnlyForm(false);
		}
		getMappedSites();
	}, [user]);

	const validate = async () => {
		const errors = {};

		if (
			formData.respondAlarmPin &&
			formData.respondAlarmPin.length > 0 &&
			formData.respondAlarmPin.length < 4
		) {
			errors.respondAlarmPin = 'Must contain 4 numbers';
		}
		return errors;
	};

	const handleSubmit = event => {
		event.preventDefault();
		validate().then(errors => {
			if (Object.keys(errors).length === 0) {
				submitUserUpdate();
				setTabHasChange(false);
			} else {
				setErrors(errors);
				const firstErrorField = Object.keys(errors)[0];
				const inputElement = document.getElementsByName(firstErrorField)[0];
				if (inputElement) {
					inputElement.focus();
				}
			}
		});
	};

	async function submitUserUpdate() {
		const updateUrl =
			decodeToken(token).id == user.id ? `${url}/users/myself` : `${url}/users/${user.id}`;
		setUserupdated(false);
		return fetch(updateUrl, {
			method: 'PUT',
			headers: {
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(formData),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					setUserupdated(true);
					toaster('Saved changes.', Intent.SUCCESS);
					setDisableSubmit(true);
				} else {
					toaster(resp.error.description, Intent.DANGER);
				}
			});
	}

	const onAddRespondBuildingHandler = sites => {
		var newBuildings = sites.filter(site => {
			if (
				site.value != '*' &&
				!buildingAlerts.some(buildingAlert => {
					return buildingAlert.id === site.id;
				})
			) {
				return site;
			}
			return null;
		});

		setBuildingAlerts([...buildingAlerts, ...newBuildings]);
		setFormData({
			...formData,
			buildingAlerts: [...buildingAlerts, ...newBuildings],
		});
		setTabHasChange(true);
		setModalToShow(false);
		setDisableSubmit(false);
	};

	const onDeleteBuildingAlert = site => {
		setDeletedSite(site);
		setModalToShow('DeleteRespondBuildingModal');
	};

	const onDeleteRespondBuildingHandler = site => {
		const newBuildings = buildingAlerts.filter(buildingAlert => {
			return buildingAlert.id != site.id;
		});
		setBuildingAlerts(newBuildings);
		setFormData({
			...formData,
			buildingAlerts: newBuildings,
		});
		setTabHasChange(true);
		setModalToShow(false);
		setDisableSubmit(false);
	};

	return (
		<>
			{showUnsavedChangesModal && (
				<UnsavedChangesModal
					onConfirmAction={() => {
						setModalToShow(false);
						setShowUnsavedChangesModal(false);
					}}
					onCancelAction={() => setShowUnsavedChangesModal(false)}
				/>
			)}
			{modalToShow === 'AddRespondBuildingModal' && (
				<AddRespondBuildingModal
					setModalToShow={setModalToShow}
					setShowUnsavedChangesModal={setShowUnsavedChangesModal}
					isLoading={isLoading}
					onConfirmAction={onAddRespondBuildingHandler}
					values={buildingAlerts}
					userId={user.id}
				/>
			)}
			{modalToShow === 'DeleteRespondBuildingModal' && (
				<DeleteRespondBuildingModal
					setModalToShow={setModalToShow}
					setShowUnsavedChangesModal={setShowUnsavedChangesModal}
					site={deletedSite}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					onConfirmAction={onDeleteRespondBuildingHandler}
				/>
			)}
			{modalToShow === 'SendTestNotificationModal' && (
				<SendTestNotificationModal
					setModalToShow={setModalToShow}
					setShowUnsavedChangesModal={setShowUnsavedChangesModal}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					onConfirmAction={onDeleteRespondBuildingHandler}
					user={user}
				/>
			)}
			<h4>Rapid Alarm Settings</h4>
			<br />
			<h6>
				<strong>Roll Call & Reunification User Type</strong>
			</h6>

			<form onSubmit={handleSubmit} method="PUT">
				<Button
					type="button"
					text="Teacher/Staff"
					intent={btnTeacherSwitch}
					disabled={disableTeacherBtn}
					onClick={e => {
						setFormData({
							...formData,
							accountType: 'teacher',
						});
						setDisableSubmit(false);
						setTabHasChange(true);

						setBtnTeacherSwitch('switchon');
						setBtnAdminSwitch('switchoff');
						setDisableTeacherBtn(true);
						setDisableAdminBtn(false);
					}}
				/>
				&nbsp;
				<Button
					type="button"
					text="Administrator"
					intent={btnAdminSwitch}
					disabled={disableAdminBtn}
					onClick={e => {
						setFormData({
							...formData,
							accountType: 'administrator',
						});
						setDisableSubmit(false);
						setTabHasChange(true);
						setBtnTeacherSwitch('switchoff');
						setBtnAdminSwitch('switchon');
						setDisableTeacherBtn(false);
						setDisableAdminBtn(true);
					}}
				/>
				<br />
				<br />
				<p>
					{btnAdminSwitch === 'switchon' ? (
						<>
							This user can end an alarm or view the alarm counts for Roll Call and/or
							Reunification from the alarm details page.
						</>
					) : (
						<>
							This user cannot end an alarm or view the alarm counts for Roll Call
							and/or Reunification from the alarm details page.
						</>
					)}
				</p>
				<br />
				<div className="row">
					<div className="col-md-4">
						<h6>Rapid Alarm PIN #</h6>
						<Password
							name="respondAlarmPin"
							value={formData.respondAlarmPin}
							placeholder="1234"
							error={!!validationErrors.respondAlarmPin}
							onKeyDown={AllowOnlyNumeric}
							withoutLabel={true}
							onChangeHandler={e => {
								validationErrors.respondAlarmPin = '';
								const input = e.target.value;
								const inputLength = input.length;
								const maxLength = 4;
								if (
									input === '' ||
									(inputLength <= maxLength && /^[0-9]*$/.test(input))
								) {
									const parsedInput = parseInt(input);
									if (parsedInput >= 0 || input === '') {
										setFormData({ ...formData, respondAlarmPin: input });
										setDisableSubmit(false);
										setTabHasChange(true);
									}
								}
							}}
							rightelement={
								<Button
									type="button"
									minimal={true}
									icon={showPassword ? 'eye-on' : 'eye-off'}
									onClick={handleShowPassword}
									style={{
										backgroundColor: 'transparent',
										border: 'none',
										cursor: 'pointer',
									}}
									className="no-hover-backgound"
									disabled={isReadOnlyForm}
								/>
							}
							readOnly={isReadOnlyForm}
						/>
					</div>
				</div>
				<p
					style={
						!!validationErrors.respondAlarmPin
							? { color: '#db3737' }
							: { color: 'gray' }
					}
				>
					Must contain 4 numbers
				</p>
				<br />
				<h6>Rapid Alarm Alerts</h6>
				<p>Choose how the user will receive rapid alarm alerts</p>
				<Checkbox checked={true} onChange={''} disabled={true}>
					<span>Push alert to app</span>
				</Checkbox>
				<Checkbox
					checked={formData.alarmNotificationText}
					onChange={e => {
						setFormData({
							...formData,
							alarmNotificationText: e.target.checked,
						});
						setDisableSubmit(false);
						setTabHasChange(true);
					}}
					value="alarmNotificationText"
					name="alarmNotificationText"
					disabled={isReadOnlyForm}
				>
					<span>Text</span>
				</Checkbox>
				<Checkbox
					checked={formData.alarmNotificationEmail}
					onChange={e => {
						setFormData({
							...formData,
							alarmNotificationEmail: e.target.checked,
						});
						setDisableSubmit(false);
						setTabHasChange(true);
					}}
					value="alarmNotificationEmail"
					name="alarmNotificationEmail"
					disabled={isReadOnlyForm}
				>
					<span>Email</span>
				</Checkbox>
				<br />
				<h6>Building Alerts</h6>
				<p>Receive notifications and join staff count for the locations listed below.</p>
				<p>
					If added, the user <strong>must</strong> account for themselves for all Rapid
					Alarm events.
				</p>
				<div className="row">
					{buildingAlerts.map(building => (
						<BuildingAlert
							building={building}
							onDeleteAction={() => onDeleteBuildingAlert(building)}
							isReadOnly={isReadOnlyForm}
						/>
					))}
					<div className="col-md-12">
						<Button
							type="button"
							icon="add"
							text="Add building"
							minimal={true}
							onClick={() => setModalToShow('AddRespondBuildingModal')}
							className="no-hover-backgound"
							intent="ghostPrimary"
							style={{ marginLeft: '-10px' }}
							disabled={isReadOnlyForm}
						/>
					</div>
					{!isReadOnlyForm && (
						<div className="col-md-12 mt-3">
							<Button
								type="submit"
								intent="primary"
								text="Save"
								large
								disabled={disableSubmit}
							/>
						</div>
					)}
				</div>
			</form>
			<hr className="page-hr mt-3" />
			{loggedUserData.accessType == 3 && (
				<div className="mt-3">
					<h6>Test Alerts</h6>
					<p>Send a test alert to this user</p>
					<Button
						style={{ height: 'auto' }}
						icon={IconNames.SEND_MESSAGE}
						text="Send Rapid Alarm Test Alert"
						intent="DANGER"
						className="send-alert"
						onClick={() => setModalToShow('SendTestNotificationModal')}
						disabled={isReadOnlyForm}
					/>
				</div>
			)}
		</>
	);
}
