import React, { useState, useReducer, useContext } from 'react';
import {
	BootstrapedTable,
	sortingStyle,
	Badge,
	SORTING_DIR,
	ConfirmationModal,
	Intent,
	FeaturedIcon,
	Tooltip,
	TableCell,
} from 'componentsV2';
import { ActionsList } from './ActionsList';
import { formatDate } from 'utilities/dates';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FlipchartService } from 'services/FlipchartService';
import { ToasterContext } from 'pages/App';
import { ARCHIVED_STATUS, MODIFIED_STATUS, DRAFT_STATUS, PUBLISH_STATUS } from './statuses';
import { TabPreviewModal } from './TabPreviewModal';
import { HAS_OLD_SP_TP_CONTENT_EDIT_USER, HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER } from '../consts.js';
import { Icon } from '@blueprintjs/core';

export const FlipchartsTable = ({ list, history, editPermission, getFlipchartList }) => {
	const [modalToShow, setModalToShow] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showTabPreviewModal, setShowTabPreviewModal] = useState(false);
	const [flipchartId, setFlipchartId] = useState();
	const toaster = useContext(ToasterContext);
	const COPY_MODAL = 'copyModal';
	const DELETE_MODAL = 'deleteModal';
	const ARCHIVE_MODAL = 'archiveModal';
	const PUBLISH_MODAL = 'publishModal';

	const flipchartDataReducer = (filpchartData, action) => {
		return {
			id: action.id,
			title: action.title,
			isArchived: action.isArchived,
			tabCount: action.tabCount ? action.tabCount : 0,
		};
	};

	const [filpchartData, dispatchFlipchartnData] = useReducer(flipchartDataReducer, {
		id: null,
		title: '',
		isArchived: false,
		tabCount: 0,
	});

	const handlConfirmCopyAction = async () => {
		try {
			setLoading(true);
			const response = await FlipchartService.publishFlipChart(filpchartData.id, 'copy');
			setLoading(false);
			closeModal();
			history.push('/flipcharts/' + response.data.id);
		} catch (err) {
			setLoading(false);
			closeModal();
			toaster(err.error?.description, Intent.DANGER, '', 3000);
		}
	};

	const handleArchiveAction = async () => {
		try {
			setLoading(true);
			const archiveFlipchart = filpchartData.isArchived ? false : true; //toggle status
			await FlipchartService.archiveFlipChart(filpchartData.id, archiveFlipchart);
			setLoading(false);
			closeModal();
			getFlipchartList();
			toaster(
				'You have successfully ' +
					(filpchartData.isArchived ? 'unarchived ' : 'archived ') +
					`"` +
					filpchartData.title +
					'"!',
				Intent.NONE,
				<FeaturedIcon icon={'database'} type={'Gray'} size={'md'} shape={'circleIcon'} />,
				2000,
			);
		} catch (err) {
			setLoading(false);
			closeModal();
			toaster(err.error?.description, Intent.DANGER, '', 3000);
		}
	};

	const handleDeleteAction = async () => {
		try {
			setLoading(true);
			await FlipchartService.delete(filpchartData.id);
			setLoading(false);
			closeModal();
			getFlipchartList();
			toaster(
				'You have successfully deleted "' + filpchartData.title + '"!',
				Intent.NONE,
				<FeaturedIcon icon="trash" type="Gray" />,
			);
		} catch (error) {
			toaster(error.error?.description, Intent.DANGER);
			closeModal();
			setLoading(false);
		}
	};

	const handleConfirmPublishAction = async () => {
		try {
			// validate if flipchart has tabs
			if (filpchartData.tabCount === 0) {
				toaster(
					`You must create a tab before you can publish the flipchart "${
						filpchartData.title
					}"`,
					Intent.DANGER,
				);
				closeModal();
				return;
			}
			setLoading(true);
			await FlipchartService.publishFlipChart(filpchartData.id, 'publish');
			await getFlipchartList();
			closeModal();

			toaster(`You have successfully published "${filpchartData.title}"`, Intent.SUCCESS);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			closeModal();
			toaster(error?.error?.description, Intent.DANGER);
		}
	};

	const showCopyModal = row => {
		dispatchFlipchartnData({
			id: row.id,
			title: row.title,
		});
		setModalToShow(COPY_MODAL);
	};

	const showArchiveModal = row => {
		dispatchFlipchartnData({
			id: row.id,
			title: row.title,
			isArchived: row.archivedBy ? true : false,
		});
		setModalToShow(ARCHIVE_MODAL);
	};

	const showDeleteModal = row => {
		dispatchFlipchartnData({
			id: row.id,
			title: row.title,
		});
		setModalToShow(DELETE_MODAL);
	};

	const showPublishModal = flipchart => {
		dispatchFlipchartnData(flipchart);
		setModalToShow(PUBLISH_MODAL);
	};

	const closeModal = () => {
		setModalToShow(null);
	};

	const loadTabPreviewModal = id => {
		setFlipchartId(id);
		setShowTabPreviewModal(true);
	};

	const getFlipchartRedirection = flipchart => {
		let titleWrappComponent = flipchart.title;

		if (flipchart.editPermission == true) {
			let redirectionPath = '';
			if (flipchart.status !== ARCHIVED_STATUS) {
				redirectionPath = `/flipcharts/${flipchart.id}`;
			} else {
				redirectionPath = `/flipcharts/view/${flipchart.id}`;
			}
			titleWrappComponent = (
				<NavLink
					exact
					to={{
						pathname: redirectionPath,
					}}
				>
					{flipchart.title}
				</NavLink>
			);
		} else if (flipchart.tabCount > 0) {
			titleWrappComponent = (
				<NavLink to="#" onClick={() => loadTabPreviewModal(flipchart.id)}>
					{flipchart.title}
				</NavLink>
			);
		}

		return (
			<TableCell
				text={titleWrappComponent}
				rightIcon={
					flipchart.hasOldSafetyPlan && (
						<Tooltip
							tooltipContent={
								<p className="sp_tp_text">
									{flipchart.canEdit
										? HAS_OLD_SP_TP_CONTENT_EDIT_USER
										: HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER}
								</p>
							}
							theme="light"
						>
							<Icon className="light-warning" icon="warning-sign" />
						</Tooltip>
					)
				}
			/>
		);
	};

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'title',
			dataField: 'title',
			text: 'Name',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => getFlipchartRedirection(row),
		},
		{
			key: 'building',
			dataField: 'building',
			text: 'Site Visibility',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'datePublished',
			dataField: 'datePublished',
			text: 'Last Published',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (value?.date ? formatDate(value.date) : 'N/A'),
			sortFunc: (a, b, order) => {
				const dateA = a?.date ? Date.parse(a.date) : 0;
				const dateB = b?.date ? Date.parse(b.date) : 0;
				if (order === SORTING_DIR.ASC) {
					return dateA - dateB;
				}
				return dateB - dateA;
			},
		},
		{
			key: 'status',
			dataField: 'status',
			text: 'Status',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				let type = 'Primary';
				switch (value) {
					case DRAFT_STATUS:
						type = 'Primary';
						break;
					case MODIFIED_STATUS:
						type = 'Warning';
						break;
					case PUBLISH_STATUS:
						type = 'Success';
						break;
					case ARCHIVED_STATUS:
						type = 'Gray';
						break;
					default:
						type = 'Primary';
						break;
				}
				const status = value.replace(/_/g, ' ');

				return (
					<Badge
						type={type}
						size="md"
						text={status.charAt(0).toUpperCase() + status.slice(1)}
					/>
				);
			},
			hidden: !editPermission,
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<ActionsList
					flipchart={row}
					history={history}
					loadTabPreviewModal={loadTabPreviewModal}
					showCopyModal={showCopyModal}
					showArchiveModal={showArchiveModal}
					showDeleteModal={showDeleteModal}
					showPublishModal={showPublishModal}
				/>
			),
		},
	];

	const cancelBtn = { title: 'No, go back', onClick: closeModal };
	const confirmCopyBtn = { title: 'Yes, copy it', onClick: handlConfirmCopyAction };
	const confirmArchiveBtn = {
		title: 'Yes, ' + (filpchartData.isArchived ? 'unarchive' : 'archive') + ' it',
		onClick: handleArchiveAction,
	};
	const confirmDeleteBtn = {
		title: 'Yes, delete it',
		onClick: handleDeleteAction,
		type: 'primaryDanger',
	};
	const confirmPublishBtn = {
		title: 'Yes, publish it',
		onClick: handleConfirmPublishAction,
	};
	const deleteIcon = { icon: 'trash', iconColor: 'error' };

	return (
		<>
			<ConfirmationModal
				isOpen={modalToShow === COPY_MODAL}
				title={
					`Are you sure you would like to make a copy of "` + filpchartData.title + `"?`
				}
				subtitle=""
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmCopyBtn}
				loading={loading}
				btnsSize="lg"
			/>
			<ConfirmationModal
				isOpen={modalToShow === ARCHIVE_MODAL}
				title={
					`Are you sure you want to ` +
					(filpchartData.isArchived ? 'unarchive ' : 'archive ') +
					`"` +
					filpchartData.title +
					`"?`
				}
				showCloseButton={false}
				cancelBtn={cancelBtn}
				subtitle={
					`You can ` +
					(filpchartData.isArchived ? 'archive ' : 'unarchive ') +
					`it at a later time.`
				}
				confirmBtn={confirmArchiveBtn}
				loading={loading}
			/>
			<ConfirmationModal
				isOpen={modalToShow === DELETE_MODAL}
				title={`Are you sure you want to delete "${filpchartData.title}"?`}
				subtitle={`Once deleted, this cannot be recovered.`}
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmDeleteBtn}
				loading={loading}
				icon={deleteIcon}
			/>
			<ConfirmationModal
				isOpen={modalToShow === PUBLISH_MODAL}
				title={`Are you sure you would like to publish "${filpchartData.title}"?`}
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmPublishBtn}
				loading={loading}
			/>

			{showTabPreviewModal && (
				<TabPreviewModal
					showTabPreviewModal={showTabPreviewModal}
					setShowTabPreviewModal={setShowTabPreviewModal}
					id={flipchartId}
				/>
			)}

			<BootstrapedTable keyField="id" data={list} columns={columns} remote={false} />
		</>
	);
};

FlipchartsTable.propTypes = {
	list: PropTypes.array.isRequired,
	editPermission: PropTypes.bool.isRequired,
};
