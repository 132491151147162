import React from 'react';
import { SearchBar, Button, Tooltip } from 'componentsV2';
import PropTypes from 'prop-types';
import './css/ContentSearch.scss';

export const ContentSearch = ({
	onSearch,
	onAddClickHandler,
	showAddBtn,
	addBtnTitle = '',
	searchValue = '',
	addBtnDisabled,
}) => (
	<div className="map-search-container">
		<SearchBar
			placeholder="Search by name..."
			icon="search"
			onSearch={onSearch}
			value={searchValue}
		/>
		{showAddBtn && (
			<Tooltip tooltipTitle={addBtnTitle} theme="light">
				<Button
					icon="plus"
					iconColor="primary"
					size="md"
					type="tertiary"
					intent="default"
					onClick={onAddClickHandler}
					disabled={addBtnDisabled}
					wrapperClass="map-add-btn"
				/>
			</Tooltip>
		)}
	</div>
);

ContentSearch.propTypes = {
	onSearch: PropTypes.func,
	onAddClickHandler: PropTypes.func,
	showAddBtn: PropTypes.bool,
	addBtnTitle: PropTypes.string,
};
