
import React from 'react';
import './css/SectionSubSectionContainer.scss';
import { SingleEditor } from './singleeditor';
import { FileSection } from '../FileSection';
import { Input } from "componentsV2";

export const SectionSubSectionContainer = ({
    history,
    item,
    type,
    id,
    viewMode,
    onTitleChange,
    sectionCustomItem,
    editVariable,
    sectionIndex,
    subsectionIndex,
    updateNodes,
    content,
    openVariables,
    commonVariables,
    templateVariables,
    templateId,
    reloadVariables,
    deleteVariable,
    saveAndReload,
    actualSectionId,
    template,
    subsection,
    actualSubsectionId,
    dispatch,
    page,
    saveTemplate,
    contentType,
    openUploadModal,
    variableValues,
    updateAutoSave,
    modalToShow,
    indicator,
    setActiveEditor,
    linkEditorsToSections = () => {},
    updateEditor = true,
}) => {

    const showSingleEditor = (() => {

        if (type == 'subsection' && item.fileSection) {
            return false;
        }
        return true;
    })
    return (
        
        <div className='sectionContainer' id={id}>
            <div className="teaxtArea_Header">
                <div className="title_Wrapper">
                    <Input
                        value={item.title}
                        type={type}
                        readOnly={viewMode}
                        onChange={onTitleChange}
                        className="inputTitle"
                    />

                </div>
                <div className="actions_Wrapper">
                    {sectionCustomItem ? sectionCustomItem : null}
                </div>
            </div>
       
    
            {showSingleEditor() && <SingleEditor
                viewMode={viewMode}
                sectionIndex={sectionIndex}
                subsectionIndex={subsectionIndex}
                updateNodes={updateNodes}
                content={content}
                modalToShow={modalToShow}
                saveAndReload={saveAndReload}
                type={type}
                variableValues={variableValues}
                actualSectionId={actualSectionId}
                template={template}
                subsection={subsection}
                actualSubsectionId={actualSubsectionId}
                contentType={contentType}
                updateAutoSave={updateAutoSave}
                saveTemplate={saveTemplate}
                dispatch={dispatch}
                indicator={indicator}
                setActiveEditor={setActiveEditor}
                linkEditorsToSections={linkEditorsToSections}
                updateEditor={updateEditor}
            />}
            
            {!showSingleEditor() && (
                <FileSection
                    viewMode={viewMode}
                    sectionIndex={sectionIndex}
                    subsectionIndex={subsectionIndex}
                    updateNodes={updateNodes}
                    subsection={item}
                    dispatch={dispatch}
                    content={content}
                    templateId={templateId}
                    actualSubsectionId={actualSectionId}
                    actualSectionId={actualSubsectionId}
                    openUploadModal={openUploadModal}
                    saveTemplate={saveTemplate}
                    page={page}
                    type={contentType}
                    template={template}
                    saveAndReload={saveAndReload}
                    updateAutoSave={updateAutoSave}
                />
            )}
        </div>
    )

}