import React, { useEffect, useState, useContext, useRef } from 'react';
import {
	Modal,
	TabPreview,
	Button,
	SectionLabel,
	Checkbox,
	CheckboxGroup,
	FileIcon,
	Intent,
	FeaturedIcon,
} from 'componentsV2';
import {
	BuildingsService,
	FloorplanService,
	DocumentService,
	CallListService,
	AssessmentService,
	PlanService,
	BinderService,
} from 'services';
import { ToasterContext } from 'pages/App';
import { SearchBar } from 'components/SearchBar';

import '../css/AttachContentModal.scss';
import { AssesmentIcon, CallListIcon, DocumentIcon, MapIcon, SafetyPlanIcon } from 'componentsV2';

export const AttachContentModal = ({
	Binder,
	showTabPreview,
	setShowTabPreview,
	reloadBinderDetails,
}) => {
	const [loading, setLoading] = useState(true);
	const [siteList, setSiteList] = useState([]);
	const [contentList, setContentList] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchReselt, setSearchReselt] = useState([]);
	const [tabsTitle, setTabsTitle] = useState('Select site');
	const [openModal, setOpenModal] = useState(1);
	const [selectedSite, setSelectedSite] = useState();
	const [docIdSelected, setDocIdSelected] = useState([]);
	const [isDisabled, setIsDisabled] = useState(true);
	const toaster = useContext(ToasterContext);
	const SELECT_MAPS = 'Select maps';
	const SELECT_CALL_LISTS = 'Select call lists';
	const SELECT_SAFTY_PLANS = 'Select safety plans';
	const SELECT_ASSESSMENTS = 'Select assessments';
	const SELECTDOCUMENTS = 'Select documents';

	const filterationData = useRef({
		page: 1,
		perPage: 10,
		siteId: '',
		title: '',
		binderId: Binder.id,
	});
	const footer = (
		<div className="button-div-styles">
			<Button
				text={'Cancel'}
				type="tertiary"
				intent="default"
				onClick={() => setShowTabPreview(false)}
			/>
			<Button
				text={'Attach'}
				intent="default"
				disabled={isDisabled}
				onClick={e => handleAttachContent(e)}
			/>
		</div>
	);

	const types = [
		{
			id: 1,
			title: 'Documents',
			icon: DocumentIcon,
		},
		{
			id: 2,
			title: 'Maps',
			icon: MapIcon,
		},
		{
			id: 3,
			title: 'Call Lists',
			icon: CallListIcon,
		},
		{
			id: 4,
			title: 'Safety Plans',
			icon: SafetyPlanIcon,
		},
		{
			id: 5,
			title: 'Assessments',
			icon: AssesmentIcon,
		},
	];

	const getContent = serviceFunction => {
		serviceFunction(filterationData.current)
			.then(response => {
				if (response.statusCode === 200 && response.data) {
					if (response._metadata.currentPage === 1 && response.data.length !== 0) {
						if (filterationData.current.title) {
							setSearchReselt(response.data);
						} else {
							setContentList(response.data);
						}
						filterationData.current.page = response._metadata.currentPage;
					} else if (response._metadata.currentPage === 1 && response.data.length === 0) {
						if (filterationData.current.title) {
							setSearchReselt([]);
						} else {
							setContentList([]);
						}
					} else {
						if (filterationData.current.title) {
							setSearchReselt(oldContent => [...oldContent, ...response.data]);
						} else {
							setContentList(oldContent => [...oldContent, ...response.data]);
						}
						filterationData.current.page = response._metadata.currentPage;
					}
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const selectDocId = tab => {
		const tabId = parseInt(tab.target.value);
		if (docIdSelected.includes(tabId)) {
			// If it exists, remove it by filtering it out
			setDocIdSelected(docIdSelected.filter(id => id !== tabId));
		} else {
			// If it doesn't exist, add it by pushing it to the array
			setDocIdSelected([...docIdSelected, tabId]);
			setIsDisabled(false);
		}
	};

	const selectAndGetContentType = () => {
		switch (tabsTitle) {
			case SELECT_MAPS:
				getContent(FloorplanService.getFloorplansBySiteId);
				break;
			case SELECT_CALL_LISTS:
				getContent(CallListService.getCallListsLegacy);
				break;
			case SELECT_SAFTY_PLANS:
				getContent(PlanService.getLightPlans);
				break;
			case SELECT_ASSESSMENTS:
				getContent(AssessmentService.getAssessmentsBySiteId);
				break;
			case SELECTDOCUMENTS:
				getContent(DocumentService.getDocumentsBySiteId);
				break;
		}
	};
	const handleSearch = name => {
		filterationData.current.title = name;
		filterationData.current.page = 1;
		setSearchValue(name);
		selectAndGetContentType();
	};

	const handleAttachContent = e => {
		e.preventDefault();

		let contentType;

		switch (tabsTitle) {
			case SELECT_MAPS:
				contentType = 'floor_plan';
				break;
			case SELECT_CALL_LISTS:
				contentType = 'call_list';
				break;
			case SELECT_SAFTY_PLANS:
				contentType = 'safety_plan';
				break;
			case SELECT_ASSESSMENTS:
				contentType = 'assessment';
				break;
			case SELECTDOCUMENTS:
				contentType = 'document';
				break;
		}

		let contents = docIdSelected.map(item => {
			return {
				content_type: contentType,
				content_id: item,
			};
		});
		setLoading(true);
		BinderService.attachContentToBinder(contents, Binder.id)
			.then(response => {
				if (response.statusCode === 201) {
					toaster(
						'You have successfully attached ' + contents.length + ' files!',
						Intent.SUCCESS,
						<FeaturedIcon
							icon={'tick'}
							type={'Success'}
							size={'md'}
							shape={'circleIcon'}
						/>,
					);
					setShowTabPreview(false);
					reloadBinderDetails(Binder.id);
				}
			})
			.catch(error => {
				console.error('Error attaching binder to contents:', error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const debouncedScrollHandler = _.debounce(async () => {
		let modal = document.getElementsByClassName('modal-content-wrapper');
		if (modal.length > 0) {
			// Check if any element was found with the specified class
			modal = modal[0];
			if (window.innerHeight + modal.scrollTop >= modal.scrollHeight && openModal === 3) {
				filterationData.current.page += 1;
				filterationData.current.siteId = selectedSite.id;
				selectAndGetContentType();
			}
		}
	}, 900); // Set the debounce delay to 500 milliseconds

	const handleScroll = () => {
		let modal = document.getElementsByClassName('modal-content-wrapper');
		if (modal.length > 0) {
			// Check if any element was found with the specified class
			modal = modal[0];

			modal.addEventListener('scroll', debouncedScrollHandler());
		}
	};

	useEffect(() => {
		if (docIdSelected.length === 0) {
			setIsDisabled(true);
		}
	}, [docIdSelected]);

	useEffect(() => {
		if (Binder.building === null && openModal < 3) {
			BuildingsService.getAllowed()
				.then(response => {
					if (response) {
						setSiteList(response);
					}
				})
				.catch(error => {
					console.error('Error fetching site list:', error);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setOpenModal(2);
			setTabsTitle('Select type');
			setSelectedSite(Binder.building);
			setLoading(false);
		}
	}, []);

	const fileIconLabel = tab => {
		let icon;

		switch (tabsTitle) {
			case SELECT_MAPS:
				icon = 'map';
				break;
			case SELECT_CALL_LISTS:
				icon = 'call_list';
				break;
			case SELECT_SAFTY_PLANS:
				icon = 'safety_plan';
				break;
			case SELECT_ASSESSMENTS:
				icon = 'assessment';
				break;
			case SELECTDOCUMENTS:
				if (tab.type === 'Audio') {
					icon = 'document';
				} else if (tab.type === 'Video') {
					icon = 'video';
				} else if (tab.type === 'Images' || tab.type === '360image') {
					icon = 'image';
				} else {
					let docType = tab.extension;
					switch (docType) {
						case 'pdf':
							icon = 'pdf';
							break;
						case 'docx':
						case 'doc':
							icon = 'docx';
							break;
						case 'xls':
							icon = 'xls';
							break;
						case 'pptx':
							icon = 'pptx';
							break;
						case 'csv':
							icon = 'csv';
							break;
						case 'txt':
							icon = 'txt';
							break;
						default:
							icon = 'document';
							break;
					}
				}
				break;
		}

		return (
			<div className="checkbox-icon">
				<div>{tab.title ? tab.title : tab.name}</div>
				<FileIcon icon={icon} />
			</div>
		);
	};
	const goToModal = value => {
		const nextModal = openModal + 1;
		setOpenModal(nextModal);
		if (nextModal === 2 && Binder.building === null) {
			const site = siteList.filter(site => site.id === value)[0];
			setSelectedSite(site);
			setTabsTitle('Select type');
		} else if (nextModal === 3) {
			const selectedType = types.filter(type => type.id === value)[0].title;
			setLoading(true);
			setContentList([]);
			setDocIdSelected([]);
			setSearchReselt([]);
			setSearchValue('');
			filterationData.current = {
				page: 1,
				perPage: 10,
				siteId: selectedSite.id,
				title: '',
				binderId: Binder.id,
			};
			switch (selectedType) {
				case 'Maps':
					setTabsTitle(SELECT_MAPS);
					getContent(FloorplanService.getFloorplansBySiteId);
					break;

				case 'Documents':
					setTabsTitle(SELECTDOCUMENTS);
					getContent(DocumentService.getDocumentsBySiteId);
					break;

				case 'Call Lists':
					setTabsTitle(SELECT_CALL_LISTS);
					getContent(CallListService.getCallListsLegacy);

					break;

				case 'Safety Plans':
					setTabsTitle(SELECT_SAFTY_PLANS);
					getContent(PlanService.getLightPlans);
					break;

				case 'Assessments':
					setTabsTitle('Select assessments');
					getContent(AssessmentService.getAssessmentsBySiteId);
					break;
			}
		}
	};

	const goBackToModal = () => {
		filterationData.current.page = 1;
		const nextModal = openModal - 1;
		setOpenModal(nextModal);
		if (nextModal === 2) {
			setTabsTitle('Select type');
		}
		if (nextModal === 1) {
			setTabsTitle('Select site');
		}
	};
	return (
		<Modal
			isOpen={showTabPreview}
			onClose={() => setShowTabPreview(false)}
			title={' Attach content to "' + Binder.name + '"'}
			size="small"
			customClassName={'attach-content-container'}
			showCloseButton={true}
			loading={loading}
			subtitle={openModal > 1 && selectedSite ? 'Site: ' + selectedSite.name : ''}
			customFooter={openModal > 2 ? footer : ''}
			onScrollHandller={handleScroll}
		>
			<div className="sticky">
				<SectionLabel
					title={tabsTitle}
					showLeftArrow={
						openModal === 1 || (openModal == 2 && Binder.building) ? false : true
					}
					textColor={'#697277'}
					onClickBackBtn={() => goBackToModal()}
					customClassName={'attachment-title'}
				/>
			</div>
			{openModal > 2 && (
				<div className="search-input">
					<SearchBar
						placeholder="Search by name..."
						icon="search"
						className="search-bar-text"
						value={searchValue}
						onSearch={e => handleSearch(e)}
					/>
				</div>
			)}

			<div className="tab-preview-list-container">
				{
					<>
						{openModal === 1 &&
							Binder.building === null &&
							siteList.map((item, index) => (
								<TabPreview //first  modal tabs
									key={index}
									id={item.id}
									title={item.name}
									isOpen={false}
									setIsOpen={goToModal}
								/>
							))}
						{openModal === 2 &&
							types.map((item, index) => (
								<TabPreview // second modal tabs
									key={index}
									id={item.id}
									title={item.title}
									icon={item?.icon}
									isOpen={false}
									setIsOpen={goToModal}
								/>
							))}
						{openModal === 3 && (
							<CheckboxGroup
								size={'medium'} // small | medium
								layout={'block'} // block | inline
								required={false}
							>
								{searchValue.length > 0
									? searchReselt.length > 0 &&
									  searchReselt.map(item => (
											<Checkbox
												label={fileIconLabel(item)}
												onChange={selectDocId}
												checked={docIdSelected.includes(item.id)}
												key={`checkbox-${item.id}`}
												size={'small'} // small | medium
												bordered={true}
												value={item.id}
											/>
									  ))
									: contentList.map(item => (
											<Checkbox
												label={fileIconLabel(item)}
												onChange={selectDocId}
												checked={docIdSelected.includes(item.id)}
												key={`checkbox-${item.id}`}
												size={'small'} // small | medium
												bordered={true}
												value={item.id}
											/>
									  ))}
							</CheckboxGroup>
						)}
					</>
				}
			</div>
		</Modal>
	);
};
