import React from "react";
import './css/ToasterNotification.scss';

export const ToasterMessage = props => {
    const { text, supportText, actions } = props;
    return (
        <div>
            <div className={'toaster-text'}>
                {text}
            </div>
            <div className={'toaster-support-text'}>
                {supportText}
            </div>
            <div>
                {actions}
            </div>
        </div>
    )
}