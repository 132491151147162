import React, { useState, createContext, useContext, useReducer } from 'react';
import { ToasterContext } from 'pages/App';
import { LoadingModal } from 'componentsV2';
import {
	CreateModal,
	CopySafetyPlanModal,
	CopySafetyPlanModalFn,
	RenameSafetyPlanModal,
	RenameSafetyPlanModalFn,
	DownloadSafetyPlanModal,
	ToogleArchivePlanModal,
	ToogleArchivePlanModalFn,
	DeletePlanModal,
	DeletePlanModalFn,
	InsertVariablesModalFn,
	InsertVariablesModal,
} from '../';

export const DistrictTemplateContext = createContext();

export const DistrictTemplateProvider = ({ children, history, loadData }) => {
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [modalToShow, setModalToShow] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedSites, setSelectedSites] = useState([]);
	const toaster = useContext(ToasterContext);
	const RENAME_MODAL = 'RenameModal';
	const COPY_MODAL = 'CopyModal';
	const ARCHIVE_MODAL = 'ArchiveModal';
	const DELETE_MODAL = 'DeleteModal';
	const INSERT_VARIABLES_MODAL = 'InsertVariableModal';

	const safetyPlanDataReducer = (safetyPlanData, action) => {
		return {
			id: action.id,
			title: action.title,
			oldTitle: action.oldTitle,
			site: action.site,
			sites: action.sites,
			isDisabled: action.isDisabled,
			archived: action.archived,
			modalTitle: action.modalTitle,
			confirmBtnTitle: action.confirmBtnTitle,
			variables: action.variables,
			source: action.source,
			linkedObjects: action.linkedObjects,
		};
	};

	const [safetyPlanData, dispatchSafetyPlanData] = useReducer(safetyPlanDataReducer, {
		id: null,
		title: '',
		oldTitle: '',
		site: null,
		sites: [],
		isDisabled: true,
		archived: null,
		modalTitle: '',
		confirmBtnTitle: '',
		source: '',
		variables: [],
		linkedObjects: {},
		onConfirmAction: () => {},
	});

	const handleCreateNewSafetyPlan = () => {
		setShowCreateModal(true);
	};

	const closeModal = () => {
		setModalToShow(null);
	};

	const { showRenameModal, handleChangeTitle, handleRenameSubmit } = RenameSafetyPlanModalFn(
		RENAME_MODAL,
		setLoading,
		safetyPlanData,
		dispatchSafetyPlanData,
		closeModal,
		toaster,
		setModalToShow,
		loadData,
	);

	const { showArchiveModal, handleArchiveSubmit } = ToogleArchivePlanModalFn(
		ARCHIVE_MODAL,
		setLoading,
		safetyPlanData,
		dispatchSafetyPlanData,
		closeModal,
		toaster,
		setModalToShow,
		loadData,
		history,
	);

	const {
		showCopyModal,
		handleChangeTitleCopy,
		handleChangeSiteCopy,
		handleSubmitCopy,
	} = CopySafetyPlanModalFn(
		COPY_MODAL,
		setLoading,
		safetyPlanData,
		dispatchSafetyPlanData,
		closeModal,
		toaster,
		setModalToShow,
		loadData,
		selectedSites,
		history,
	);

	const {
		exportLoading,
		getAndDownloadSafetyPlan,
		downloadSafetyPlan,
		downloadSafetyPlanSingleSelection,
	} = DownloadSafetyPlanModal();

	const { showDeleteModal, handleDeleteSubmit } = DeletePlanModalFn(
		DELETE_MODAL,
		setLoading,
		safetyPlanData,
		dispatchSafetyPlanData,
		closeModal,
		toaster,
		setModalToShow,
		history,
		loadData,
	);

	const { showInsertVariablesModal } = InsertVariablesModalFn(
		INSERT_VARIABLES_MODAL,
		setLoading,
		safetyPlanData,
		dispatchSafetyPlanData,
		closeModal,
		setModalToShow,
	);
	const exportedFunctions = {
		history,
		setSelectedSites,
		handleCreateNewSafetyPlan,
		showRenameModal,
		showCopyModal,
		getAndDownloadSafetyPlan,
		downloadSafetyPlan,
		downloadSafetyPlanSingleSelection,
		showArchiveModal,
		showDeleteModal,
		showInsertVariablesModal,
	};

	return (
		<DistrictTemplateContext.Provider value={exportedFunctions}>
			{showCreateModal && (
				<CreateModal
					showModal={showCreateModal}
					setShowModal={setShowCreateModal}
					selectedSites={selectedSites}
					history={history}
				/>
			)}

			<LoadingModal isOpen={exportLoading} text="Generating PDF for plan..." loading />

			<CopySafetyPlanModal
				showModal={modalToShow === COPY_MODAL}
				closeModal={closeModal}
				loading={loading}
				formData={safetyPlanData}
				handleChangeTitle={handleChangeTitleCopy}
				handleChangeSite={handleChangeSiteCopy}
				handleSubmit={handleSubmitCopy}
			/>

			<RenameSafetyPlanModal
				modalName={RENAME_MODAL}
				modalToShow={modalToShow}
				loading={loading}
				formData={safetyPlanData}
				closeModal={closeModal}
				handleChangeTitle={handleChangeTitle}
				handleSubmit={handleRenameSubmit}
			/>

			<ToogleArchivePlanModal
				modalName={ARCHIVE_MODAL}
				modalToShow={modalToShow}
				loading={loading}
				formData={safetyPlanData}
				closeModal={closeModal}
				handleSubmit={handleArchiveSubmit}
				safetyPlanData={safetyPlanData}
				history={history}
			/>

			<DeletePlanModal
				modalName={DELETE_MODAL}
				modalToShow={modalToShow}
				loading={loading}
				planData={safetyPlanData}
				closeModal={closeModal}
				handleDeleteSubmit={handleDeleteSubmit}
			/>

			<InsertVariablesModal
				modalName={INSERT_VARIABLES_MODAL}
				modalToShow={modalToShow}
				loading={loading}
				setLoading={setLoading}
				variables={safetyPlanData.variables}
				templateId={safetyPlanData.id}
				closeModal={closeModal}
				loadData={loadData}
			/>
			{children}
		</DistrictTemplateContext.Provider>
	);
};
