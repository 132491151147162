import React, { useRef, useState } from 'react';
import './index.css';
import PropTypes from 'prop-types';

const InputTag = ({
    key,
    label,
    onClick,
    onDelete,
    textColor,
    ...restProps
}) => (
    <div key={key} 
        className="input-tag-value-tag" 
        onClick={onClick}
        {...restProps}
    >
        <div className="input-tag-value-label">
            <span style={{ color: textColor }}>{label}</span>
        </div>
        {onDelete && <div role="button" className="input-tag-value-remove" aria-label={`Remove `+key} onClick={onDelete}>
            <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg">
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
        </div>}
    </div>
);

const InputTagContainer = ({
    children,
    visibleOptions
}) => {
    
	const [showHiddenTags, setShowHiddenTags] = useState(false);

    const onBlurHandler = () => {
		setShowHiddenTags(false);
	}

    if ( visibleOptions > 0 && children.length > visibleOptions  ) { // show more / less button should displayed
        if(!showHiddenTags) { //show more button should displayed
            children = [
                children.slice(0, visibleOptions),
                <InputTag 
                    key="more"
                    label={`+${children.length - visibleOptions} more`}
                    onClick={() => setShowHiddenTags(currentValue => !currentValue)}
                    style={{ cursor: 'pointer', backgroundColor:"#577082" }}
                    textColor="white"
                />
            ];
        } else { // show less button should displayed at the end of list
            children = [
                ...children,
                <InputTag 
                    key="more"
                    label="Show less"
                    onClick={() => setShowHiddenTags(currentValue => !currentValue)}
                    style={{ cursor: 'pointer', backgroundColor:"#577082" }}
                    textColor="white"
                />
            ];
        }
    }

    return (       
        <div className='input-tag-value-container' onBlur={onBlurHandler}>
            {children}
        </div>
    );
}

InputTag.propTypes = {
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
}

InputTagContainer.propTypes = {
    visibleOptions: PropTypes.number
};

export { InputTagContainer };
export { InputTag };