import React from 'react';
import { Input } from 'componentsV2';
import PropTypes from 'prop-types';

export const AlarmName = ({ formData, formErrors, onChangeFormData, disabled = false }) => (
	<Input
		label="Alarm name"
		placeholder="Alarm name"
		isRequired
		onChange={e => onChangeFormData({ type: 'name', payload: e.target.value })}
		value={formData.name}
		error={formErrors?.name ? true : false}
		errorMessage={formErrors?.name}
		disabled={disabled}
	/>
);

AlarmName.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
	disabled: PropTypes.bool,
};
