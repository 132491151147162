import React, { useState } from "react";
import PropTypes from 'prop-types';
import { ActionsButton, Button } from "componentsV2";


export const SectionActionsList = ({
    history,
    elementSelector = null,
    deleteAction,
    createVariableAction,
    addImageAction,
    viewMode,
    sectionType,
    template
}) => {
    let sectionsCount = (template.sections) ? template.sections.length : 0;
    return (
        <ActionsButton elementSelector={elementSelector}>
                <Button
                    large
                    text="Insert variable"
                    icon="insert"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={createVariableAction}
                />
                <Button
                    text="Add image"
                    icon="media"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={addImageAction}
                />
            {!viewMode && (
                <>
                {((sectionType === 'section' && sectionsCount>1) || sectionType==='subsection') && (
                <Button
                    large
                    text="Delete"
                    icon="trash"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={deleteAction}
                />
                )}
                </>
            )}
        </ActionsButton>
    );
}

SectionActionsList.propTypes = {
};