import React from 'react';
// permissions
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
// tabs
import { CustomTabs, PageHeader } from 'componentsV2';
import { Tab } from '@blueprintjs/core';
import { Dashboard, Scenarios, Statistics } from './components';
// header
import { HeaderButtons } from './components';

export const DrillsV2 = ({ history, tabId }) => {
	const permissions = useSelector(state => state.route.permissions);
	const isSuperAdmin = permissions.isSysAdmin;
	const canViewSettings = checkPermission('drilllogs_set_reqs', permissions);
	const canViewScenarios = checkPermission('drill_scenario_edit', permissions);

	const tabs = [
		{ id: 'dashboard', title: 'Dashboard', panel: <Dashboard history={history} /> },
		{ id: 'scenarios', title: 'Scenarios', panel: <Scenarios history={history} /> },
		{ id: 'statistics', title: 'Statistics', panel: <Statistics /> },
	];

	const routeTab = tabId => {
		if (tabId === 'dashboard') history.push('/drillsV2');
		else history.push(`/drillsV2/${tabId}`);
	};

	return (
		<>
			<PageHeader
				title="Drills"
				subtitle="Schedule, facilitate, and log safety drills"
				customItem={() => (
					<HeaderButtons
						showSettings={canViewSettings || isSuperAdmin}
						showAdmin={isSuperAdmin}
						history={history}
					/>
				)}
			/>
			<CustomTabs id="drillsTabs" onChangeHandler={routeTab} selectedTabId={tabId}>
				{tabs.map(tab => {
					// Scenarios are only viewable to super admins and users with edit permissions
					if (tab.id === 'scenarios') {
						return (
							(canViewScenarios || isSuperAdmin) && (
								<Tab id={tab.id} title={tab.title} panel={tab.panel} />
							)
						);
					}

					return <Tab id={tab.id} title={tab.title} panel={tab.panel} />;
				})}
			</CustomTabs>
		</>
	);
};
