import React from 'react';
import { ProgressBar as PropgressBarBP } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import './css/ProgressBar.scss';

export const ProgressBar = ({
	label = '',
	value = 0,
	total = 100,
	displayValueInPercentage = true,
	disabled = false,
}) => {
	const calculatedValue = +total !== 0 ? value / total : value / 100;
	const percentageValue = displayValueInPercentage
		? `${(calculatedValue * 100).toFixed(0)} %`
		: `${value} / ${total}`;

	return (
		<div className={`progress-bar-container ${disabled ? "progress-bar-disabled" : ""}`}>
			{ label && <div className="progress-bar-container-title">{label}</div> }
			<div className={`progress-bar-container-progress ${calculatedValue == 0 ? "progress-bar-progress-0" : ""}`}>
				<PropgressBarBP
					value={calculatedValue}
					stripes={false}
					animate={true}
				/>
				<p className="progress-bar-container-progress-value">{percentageValue}</p>
			</div>
		</div>
	);
};

ProgressBar.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number.isRequired,
	total: PropTypes.number,
	displayValueInPercentage: PropTypes.bool,
};
