import React from 'react';
import {
	LoadingSpinner,
	BootstrapedTable,
	sortingStyle,
	TableCell,
	SORTING_DIR,
	sortDatesObject,
	sortStringInObject,
	formatDate,
} from 'componentsV2';
import { ActionsList } from './ActionsList';
import { RAPID_ICONS } from 'assets/respond';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

export const ActiveAlarmsTable = ({ loading, list, history }) => {
	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'disabled',
			dataField: 'disabled',
			hidden: true,
		},
		{
			key: 'alarmType',
			dataField: 'alarmType',
			text: 'Alarm Type',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (
				<TableCell
					text={value.title}
					supportText={
						row.mode
							? row.mode.charAt(0).toUpperCase() + row.mode?.slice(1) + ' mode'
							: ''
					}
					leftIcon={<img src={RAPID_ICONS[value.icon]} />}
				/>
			),
			sortFunc: (a, b, order) => sortStringInObject(a, b, order, 'title'),
		},
		{
			key: 'building',
			dataField: 'building',
			text: 'Site',
			sort: true,
			classes: 'wrapText',
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => value.name,
			sortFunc: (a, b, order) => sortStringInObject(a, b, order, 'name'),
		},
		{
			key: 'dateStarted',
			dataField: 'dateStarted',
			text: 'Time',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				if (value.date) {
					return (
						<TableCell
							text={formatDate(value.date, DateTime.TIME_SIMPLE)}
							supportText={formatDate(value.date, DateTime.DATE_HUGE)}
						/>
					);
				}
				return null;
			},
			sortFunc: (a, b, order) => sortDatesObject(a, b, order, 'date'),
		},
		{
			key: 'activatedBy',
			dataField: 'activatedBy',
			text: 'Activated by',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'initiatedBy',
			dataField: 'initiatedBy',
			text: 'Via',
			sortCaret: (order, column) => sortingStyle(order, column),
			sort: true,
		},
		{
			key: 'actions',
			dataField: 'id',
			text: '',
			formatter: (value, row) => <ActionsList alarm={row} history={history} />,
		},
	];

	if (loading) return <LoadingSpinner className="mt-3" />;
	return (
		<BootstrapedTable
			keyField="id"
			defaultSorted={[
				{
					dataField: 'dateStarted',
					order: SORTING_DIR.DESC,
				},
			]}
			data={list}
			columns={columns}
			remote={false}
			showPagination={false}
		/>
	);
};

ActiveAlarmsTable.propTypes = {
	list: PropTypes.array.isRequired,
};
