import React from 'react';
import DropdownButton from 'components/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Icon } from '@blueprintjs/core';

const AddContactButton = ({ setModalToShow }) => {
	return (
		<DropdownButton
			variant="primary"
			text="Add contacts"
			lefticon="plus"
			style={{
				backgroundColor: '#007E8F',
			}}
		>
			<>
				<Dropdown.Item
					className="mt-2 w-100 justify-content-start"
					onClick={() => setModalToShow('ExternalContactModal')}
				>
					<Icon icon="person" className="dropdown-item-icon" /> New Contacts
				</Dropdown.Item>
				<Dropdown.Item
					className="mt-2 w-100 justify-content-start"
					onClick={() => setModalToShow('CallListBulkContactsModal')}
				>
					<Icon icon="people" className="dropdown-item-icon" /> Multiple contacts
				</Dropdown.Item>
				<Dropdown.Item
					className="mt-2 w-100 justify-content-start"
					onClick={() => setModalToShow('CallListExistingContactsModal')}
				>
					<Icon icon="pivot" className="dropdown-item-icon" /> Existing Contact
				</Dropdown.Item>
			</>
		</DropdownButton>
	);
};

export default AddContactButton;
