import React, { useContext } from 'react';
import { ActionsButton, Button } from 'componentsV2';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import { AssessSettings } from '../ListAssessments';

export const TableActions = ({ row, history, onShowModal, onDownloadAssessment }) => {
	const { assessIsLocked, assessForceCompletion } = useContext(AssessSettings);
	const permissions = useSelector(state => state.route.permissions);
	const districtId = useSelector(state => state.route.districtId);
	const viewPermission = checkPermission('site_assess_view', permissions, row.building.id);
	const editPermissionBuilding = checkPermission(
		'site_assess_edit',
		permissions,
		row.building.id,
	);
	const editPermissionDistrict = checkPermission('site_assess_edit', permissions, districtId);
	const canEdit = assessIsLocked ? !!editPermissionDistrict : !!editPermissionBuilding;
	const isComplete = row.status === 'Completed';

	const go = (route, row) => {
		history.push({ pathname: route, state: { data: row } });
	};

	const handleCompleteIncomplete = () => {
		if (!isComplete && assessForceCompletion && row.answerCount !== row.questionCount) {
			onShowModal('incomplete_notice');
		} else {
			onShowModal('mark_assessment_complete_incomplete', {
				id: row.id,
				name: row.name,
				complete: isComplete,
			});
		}
	};

	return (
		<ActionsButton>
			{viewPermission && (
				<Button
					text={canEdit ? 'View or edit' : 'View'}
					icon="eye-open"
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => go(`/assessV2/assessment/${row.id}`, row)}
					iconColor="gray-dark"
				/>
			)}
			{canEdit && (
				<Button
					text={isComplete ? 'Mark as incomplete' : 'Mark as complete'}
					icon={isComplete ? 'ban-circle' : 'tick'}
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={handleCompleteIncomplete}
					iconColor="gray-dark"
				/>
			)}
			{viewPermission && (
				<Button
					text="Download"
					icon="cloud-download"
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => onDownloadAssessment(row)}
					iconColor="gray-dark"
				/>
			)}
			{canEdit && (
				<Button
					text="Delete"
					icon="trash"
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() =>
						onShowModal('delete_assessment', {
							id: row.id,
							name: row.name,
						})
					}
					iconColor="gray-dark"
				/>
			)}
		</ActionsButton>
	);
};
