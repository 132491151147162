import React from 'react';
import { BootstrapedTable, sortingStyle } from 'componentsV2';
import { formatDate } from 'utilities/dates';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TableCell, Color, Tooltip } from 'componentsV2';
import { BINDER_EDIT_PATH, BINDER_VIEW_PATH } from './';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from 'react-redux';
import { ActionsList } from './ActionsList';
import { Icon } from '@blueprintjs/core';
import { HAS_OLD_SP_TP_CONTENT_EDIT_USER, HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER } from './consts.js';

export const BindersTable = ({
	list,
	totalRows,
	filterationData,
	setFilterationData,
	editPermission,
	setSelectedRows,
	selectedRows,
	history,
	setLoading,
	districtId,
}) => {
	const permissions = useSelector(state => state.route.permissions);

	const getRedirectionPath = binder => {
		const binderEditPermission = checkPermission(
			'vb_edit',
			permissions,
			binder.building ? binder.building?.id : null,
		);
		if (binderEditPermission && !binder.archivedByUser) {
			return `/${BINDER_EDIT_PATH}/${binder.id}`;
		}
		return `/${BINDER_VIEW_PATH}/${binder.id}`;
	};

	const hasSiteEditPermission = binder => {
		return checkPermission(
			'vb_edit',
			permissions,
			binder.building ? binder.building.id : districtId,
		);
	};

	const getBinderSupportText = binder => {
		let contentCount = binder.contentCount;
		contentCount += contentCount > 1 ? ' files' : ' file';
		if (binder.archivedByUser) {
			contentCount += ', Archived';
		}
		return contentCount;
	};

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'disabled',
			dataField: 'disabled',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Title',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (
				<TableCell
					text={
						<>
							<NavLink
								exact
								to={{
									pathname: getRedirectionPath(row),
								}}
							>
								{row.name}
							</NavLink>
						</>
					}
					supportText={getBinderSupportText(row)}
					leftIcon={
						<Color
							size="xs"
							customStyle={{
								backgroundColor: '#' + row.color,
								border: '1px solid #D9DCDD',
							}}
						/>
					}
					rightIcon={
						row.hasOldSP && (
							<Tooltip
								tooltipContent={
									<p className="sp_tp_text">
										{hasSiteEditPermission(row)
											? HAS_OLD_SP_TP_CONTENT_EDIT_USER
											: HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER}
									</p>
								}
								theme="light"
							>
								<Icon className="light-warning" icon="warning-sign" />
							</Tooltip>
						)
					}
				/>
			),
		},
		{
			key: 'building',
			dataField: 'building',
			text: 'Site Visibility',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (value == null ? 'Visible to all' : value?.name),
		},
		{
			key: 'lastRevised',
			dataField: 'lastRevised',
			text: 'Last Modified',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (value?.date ? formatDate(value.date) : 'N/A'),
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<ActionsList
					binder={row}
					history={history}
					setLoading={setLoading}
					hasSiteEditPermission={hasSiteEditPermission}
				/>
			),
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'name';
			switch (sortField) {
				case 'building':
					sortColumn = 'site';
					break;
				case 'lastRevised':
					sortColumn = 'lastRevised';
					break;
				default:
					sortColumn = sortField;
					break;
			}
			setFilterationData({ ...filterationData, sortKey: sortColumn, sortDir: sortOrder });
		}
	};

	const setRowsPerPage = value => {
		setFilterationData(oldData => ({ ...oldData, perPage: value }));
	};

	const setCurrentPage = value => {
		setFilterationData(oldData => ({ ...oldData, page: value }));
	};

	return (
		<BootstrapedTable
			keyField="id"
			data={list}
			columns={columns}
			totalRows={totalRows}
			onTableChange={onTableChange}
			currentPage={filterationData.page}
			rowsPerPage={filterationData.perPage}
			setCurrentPage={setCurrentPage}
			setRowsPerPage={setRowsPerPage}
			onSelectRows={setSelectedRows}
			selectedRows={selectedRows}
			hasCheckbox={editPermission}
			showTooltipOnDisabledCheckboxes
		/>
	);
};

BindersTable.propTypes = {
	list: PropTypes.array.isRequired,
	editPermission: PropTypes.bool.isRequired,
};
