import React, { useEffect, useState } from 'react';
import { CustomTabs, Animated, LoadingSpinner } from 'componentsV2';
import { MapOptionCard } from '../';
import { MAP_TEXT_LIST } from '../../';
import { AvailableToUsePanel, InUsePanel } from './';
import PropTypes from 'prop-types';
import { Tab } from '@blueprintjs/core';
import './css/TextList.scss';
import { FloorplanService } from 'services/FloorplanService';

export const TextList = ({
	siteId,
	floorplan,
	toggleMapOption,
	editPermission,
	addNewTextHandler,
	addBtnDisabled = false,
	refreshTextList = false,
	triggerShowHide,
	optionToShow,
	layer,
	floorplanShapes,
	setFloorplanShapes,
	isEditMode,
}) => {
	const [loading, setLoading] = useState(true);
	const [textList, setTextList] = useState([]);
	const [selectedTab, setSelectedTab] = useState('InUse');
	const floorplanArchived = !!floorplan?.archivedBy;
	useEffect(() => {
		getTextList();
	}, [floorplan, refreshTextList]);

	const getTextList = () => {
		setLoading(true);
		FloorplanService.getShapes({
			siteId,
			geometryType: '1',
		})
			.then(response => {
				if (response.statusCode === 200) {
					setTextList(response.data);
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const tabs = [
		{
			id: 'InUse',
			title: 'In use',
		},
		{
			id: 'AvailableToUse',
			title: 'Available to use',
		},
	];

	const onChangeTabHandler = tab => {
		setSelectedTab(tab);
	};

	return (
		<MapOptionCard
			isOpen={optionToShow === MAP_TEXT_LIST}
			title="Text"
			onClick={() => toggleMapOption(MAP_TEXT_LIST)}
			titleBorder={false}
			headerItem={
				<CustomTabs
					onChangeHandler={onChangeTabHandler}
					renderActiveTabPanelOnly={true}
					selectedTabId={selectedTab}
				>
					{tabs.map((tab, index) => (
						<Tab
							id={tab.id}
							key={index}
							panel={<></>}
							title={tab.title}
							className="CustomTabs__Tab"
						/>
					))}
				</CustomTabs>
			}
		>
			<Animated>
				{loading && <LoadingSpinner />}
				{!loading && (
					<div className="map-text-list-container">
						{selectedTab === 'InUse' && (
							<InUsePanel
								floorplanId={floorplan?.id}
								list={textList}
								addNewTextHandler={addNewTextHandler}
								addBtnDisabled={addBtnDisabled}
								triggerShowHide={triggerShowHide}
								siteId={siteId}
								layer={layer}
								editPermission={editPermission}
								floorplanArchived={floorplanArchived}
								floorplanShapes={floorplanShapes}
								setFloorplanShapes={setFloorplanShapes}
								refreshTextList={refreshTextList}
								isEditMode={isEditMode}
							/>
						)}
						{selectedTab === 'AvailableToUse' && (
							<AvailableToUsePanel
								floorplanId={floorplan?.id}
								list={textList}
								addNewTextHandler={addNewTextHandler}
								addBtnDisabled={addBtnDisabled}
								siteId={siteId}
								layer={layer}
								editPermission={editPermission}
								floorplanArchived={floorplanArchived}
								isEditMode={isEditMode}
							/>
						)}
					</div>
				)}
			</Animated>
		</MapOptionCard>
	);
};

TextList.propTypes = {
	floorplan: PropTypes.object,
	editPermission: PropTypes.bool,
	toggleMapOption: PropTypes.func,
};
