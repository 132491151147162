import React, { useEffect, useState, useRef, useContext } from 'react';
import {
	FormModal,
	SearchBar,
	Badge,
	noResult,
	NavCard,
	Dropdown,
	LoadingSpinner,
	ToasterMessage,
} from 'componentsV2';
import { ReunificationService } from 'services';
import { ReunificationStudentsEmptyState } from '../../RollCallDetails/components/Reunification/Students/components/ReunificationStudentsEmptyState';
import { ToasterContext } from 'pages/App';
import { AddStudentHeader } from '../../RollCallDetails/components/Reunification/Guardians/GuardiansCheckin/CheckinSteps/Shared/AddStudentHeader';
import './css/AddAnotherStudentModal.scss';
import { GUARDIAN_RELATIONS } from '../../RollCallDetails/components/Reunification/Guardians/GuardiansCheckin/consts';
import { Icon } from '@blueprintjs/core';
import { DEFULT_ADD_ANOTHER_STUDENT_MODAL } from '../../consts';
import { Intent } from '@blueprintjs/core';

export const AddAnotherStudentModal = ({
	handleCancel,
	alarmId,
	guardianDetails,
	setModalToshow,
}) => {
	const filterationData = useRef({
		page: 1,
		perPage: 10,
		studentName: '',
		sortKey: 'studentName',
		sortDir: 'asc',
	});

	const [studentsList, setStudentsList] = useState([]);
	const [totalStudents, setTotalStudents] = useState();
	const [currentSelectedStudent, setCurrentSelectedStudent] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [selectedRelation, setSelectedRelation] = useState();
	const [submitDisabled, setsubmitDisabled] = useState(true);
	const [loading, setLoading] = useState(false);
	const [modalLoad, setModalLoad] = useState(false);
	const toaster = useContext(ToasterContext);

	const handleChangeSelection = target => {
		let currentSelected = studentsList.find(item => item.id == target.id);
		setCurrentSelectedStudent(currentSelected);
	};

	const getStudentsList = (appendData = false) => {
		!appendData ? setLoading(true) : setLoading(false);
		ReunificationService.getStudentsList(alarmId, filterationData.current)
			.then(response => {
				if (response.statusCode === 200) {
					if (appendData) {
						setStudentsList(oldContent => [...oldContent, ...response.data]);
					} else {
						if (response.data.length > 0) {
							setStudentsList(response.data);
						}
					}
					setTotalStudents(response._metadata.totalRecords);
				}
				setLoading(false);
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			});
	};

	const HandleSave = () => {
		let body = {
			addStudents: [
				{
					id: currentSelectedStudent.id,
					relation: currentSelectedStudent.relation,
					note: null,
				},
			],
		};
		setModalLoad(true);
		ReunificationService.updateGuardianDetails(alarmId, guardianDetails.id, body)
			.then(response => {
				if (response.statusCode === 200) {
					const message = (
						<ToasterMessage
							text={'Student added'}
							supportText={'The student has successfully been added.'}
						/>
					);
					toaster(message, Intent.SUCCESS);
					setModalToshow(DEFULT_ADD_ANOTHER_STUDENT_MODAL);
					setModalLoad(false);
				}
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			});
	};

	useEffect(() => {
		getStudentsList();
	}, []);

	const rightContent = (item, hasRemoveIcon = false) => {
		let type = '';
		let text = '';
		if (item.reunified) {
			type = 'Primary';
			text = 'Reunified';
		} else {
			if (item.checkIn) {
				switch (item.checkIn.status) {
					case 'checkedIn':
						type = 'Success';
						text = 'Checked-in';
						break;
					case 'absent':
						type = 'Gray';
						text = 'Absent';
						break;
					case 'transferred':
						type = 'Gray';
						text = 'Transferred to reunification';
						break;
					default:
						type = 'Primary';
						text = item.checkIn.status;
				}
			} else {
				type = 'Warning';
				text = 'Not Checked-in';
			}
		}

		return (
			<span className="badgesWrapper">
				<Badge type={type} size="md" text={text} />
				{item.guardianCheckIn.length > 0 && (
					<span className="AssociatedWrapper">
						<Badge
							type="Gray"
							size="md"
							text={'Associated with ' + item.guardianCheckIn[0]['name']}
						/>
					</span>
				)}
				{hasRemoveIcon && (
					<Icon
						size={16}
						className="cross-icon"
						icon="cross"
						onClick={changeSelectedStudent}
					/>
				)}
			</span>
		);
	};

	const changeSelectedStudent = () => {
		setCurrentSelectedStudent(null);
		setsubmitDisabled(true);
		setSelectedRelation(null);
	};
	const backToEditGuardian = () => {
		currentSelectedStudent
			? setCurrentSelectedStudent(null)
			: setModalToshow(DEFULT_ADD_ANOTHER_STUDENT_MODAL);
		setsubmitDisabled(true);
		setSelectedRelation(null);
	};

	const debouncedScrollHandler = _.debounce(async () => {
		let modal = document.getElementsByClassName('modal-content-wrapper');
		if (modal.length > 0) {
			// Check if any element was found with the specified class
			modal = modal[0];
			if (window.innerHeight + modal.scrollTop >= modal.scrollHeight) {
				filterationData.current.page += 1;
				getStudentsList(true);
			}
		}
	}, 900); // Set the debounce delay to 500 milliseconds

	const handleScroll = () => {
		let modal = document.getElementsByClassName('modal-content-wrapper');
		if (modal.length > 0) {
			// Check if any element was found with the specified class
			modal = modal[0];

			modal.addEventListener('scroll', debouncedScrollHandler());
		}
	};

	const handleSearch = name => {
		filterationData.current.studentName = name;
		filterationData.current.page = 1;
		setSearchValue(name);
		getStudentsList();
	};

	const onChangeRelation = selectedValue => {
		let currentStudent = currentSelectedStudent;
		currentStudent.relation = selectedValue.value;
		setCurrentSelectedStudent(currentStudent);
		setSelectedRelation(selectedValue);
		setsubmitDisabled(false);
	};
	return (
		<>
			<FormModal
				isOpen={true}
				onClose={handleCancel}
				showCloseButton
				customClassName={'add-student-manual-modal'}
				size="medium"
				title="Edit guardian details"
				scrollContent={true}
				showBackArrow={true}
				backArrowAction={backToEditGuardian}
				confirmBtn={{
					title: 'Add student',
					onClick: HandleSave,
					disabled: submitDisabled,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: () => handleCancel(),
				}}
				onScrollHandller={handleScroll}
				loading={modalLoad}
			>
				{
					<div className="add-another-student-to-reunification-container">
						<AddStudentHeader
							title="Add another student"
							subtitle={
								'Search and select a student that is associated with ' +
								guardianDetails.name
							}
						/>
						{currentSelectedStudent && (
							<div className="selected-student">
								<div className="NavWrapper">
									<NavCard
										{...{
											name:
												currentSelectedStudent.firstName +
												' ' +
												currentSelectedStudent.middleInitial?.substring(
													0,
													1,
												) +
												'. ' +
												currentSelectedStudent.lastName,
											key: currentSelectedStudent.id,
											subtext: `Grade:  ${currentSelectedStudent.grade}`,
											selected: true,
											rightElement: rightContent(
												currentSelectedStudent,
												true,
											),
											showIcon: false,
											disabled: false,
										}}
									/>
								</div>
								<Dropdown
									label={
										`Select the relation of the guardian to ` +
										currentSelectedStudent.firstName +
										' ' +
										currentSelectedStudent.middleInitial?.substring(0, 1) +
										'. ' +
										currentSelectedStudent.lastName
									}
									value={selectedRelation}
									options={GUARDIAN_RELATIONS}
									onChange={onChangeRelation}
									isRequired={true}
									placeholder="Select relation"
									className="GuardianRelation_dropDown"
								/>
							</div>
						)}
						{!currentSelectedStudent && (
							<>
								<div className="add-another-students-search">
									<SearchBar
										placeholder={'Search by student’s name'}
										icon="search"
										className={'input-search'}
										value={searchValue}
										onSearch={e => handleSearch(e)}
									/>
								</div>
								{loading && <LoadingSpinner className="mt-3" />}
								{!loading && (
									<>
										{totalStudents === 0 ? (
											<ReunificationStudentsEmptyState
												header="No result found"
												description={
													<>
														Your search didn’t match any student.
														<br /> Please try again.
													</>
												}
												icon={noResult}
												showCircles={false}
											/>
										) : (
											<>
												{studentsList.map(item => {
													return (
														<div className="NavWrapper">
															<NavCard
																name={
																	item.firstName +
																	' ' +
																	item.middleInitial?.substring(
																		0,
																		1,
																	) +
																	'. ' +
																	item.lastName
																}
																key={item.id}
																subtext={`Grade:  ${item.grade}`}
																selected={
																	item.id ===
																	currentSelectedStudent?.id
																}
																onClick={() =>
																	handleChangeSelection(item)
																}
																rightElement={rightContent(item)}
																showIcon={false}
																disabled={
																	item.reunified ||
																	item.guardianCheckIn.length > 0
																}
															/>
														</div>
													);
												})}
											</>
										)}
									</>
								)}
							</>
						)}
					</div>
				}
			</FormModal>
		</>
	);
};
