import React, {useContext, useReducer, useState} from "react";
import PropTypes from "prop-types";
import {FormModal, Button, Modal, ModalFooter, ToasterContext, Intent} from "componentsV2";
import {PlanService} from "services";
import {VariablesFieldsForm} from "../../components/VariablesFields";
import './css/InsertVariablesModal.scss';

export const InsertVariablesModal = ({
                                       modalName,
                                       modalToShow,
                                       loading,
                                       setLoading,
                                       variables = [],
                                       templateId,
                                       closeModal,
                                       loadData
                                     }) => {
  const [disableForm, setDisableForm] = useState(true);
  const variablesReducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_VARIABLES':
        const {name, value, type, file, fileObj} = action.payload;
        const existingVariableIndex = state.variables.findIndex(variable => variable.name === name);
        if (existingVariableIndex !== -1) {
          const updatedVariables = [...state.variables];
          updatedVariables[existingVariableIndex] = {
            name: name,
            value: value,
            type: type,
            file: file,
            fileObj: fileObj
          };
          return {
            ...state,
            variables: updatedVariables,
          };
        } else {
          return {
            ...state,
            variables: [...state.variables, {
              name: name,
              value: value,
              type: type,
              file: file,
              fileObj: fileObj
            }],
          };
        }
      case 'DELETE_VARIABLES':
        const {name: variableToDelete} = action.payload;
        const filteredVariables = state.variables.filter(
          (variable) => variable.name !== variableToDelete
        );
        return {
          ...state,
          variables: filteredVariables,
        };
      default:
        return state;
    }
  };
  const [variableFormData, dispatchVariableFormData] = useReducer(variablesReducer, {
    variables: [],
    sectionForm: true
  });
  const toaster = useContext(ToasterContext);
  const handleCloseModal = () => {
    closeModal()
    loadData()
  }
  const handleInsertVariablesSubmit = () => {
    setLoading(true);
    PlanService.saveAllVariables(templateId, variableFormData)
      .then(resp => {
        if (resp.statusCode === 201) {
          toaster("You have successfully added the variable fields.",
            Intent.SUCCESS
          );
          handleCloseModal()
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (<>
      {variables.length == 0 &&
        <Modal
          isOpen={modalToShow === modalName}
          onClose={closeModal}
          title="Add values"
          loading={loading}
          showCloseButton={false}
          customFooter={
            <ModalFooter
              confirmButton={
                <Button
                  text="Close"
                  type="primaryDefault"
                  intent="default"
                  onClick={() => closeModal(false)}
                />
              }
            />
          }
        >
          <div className="no-variables-text">No variables in this section</div>
        </Modal>
      }
      {variables.length > 0 &&
        <FormModal
          isOpen={modalToShow === modalName}
          onClose={closeModal}
          title={'Add values'}
          showCloseButton={true}
          confirmBtn={{
            title: "Save",
            onClick: handleInsertVariablesSubmit,
            disabled: !!disableForm
          }}
          cancelBtn={{
            title: "Cancel",
            onClick: closeModal
          }}
          loading={loading}
          scrollContent={false}
        >
          <div className="w-100">
            <VariablesFieldsForm
              templateId={templateId}
              variables={variables}
              setLoading={setLoading}
              variableFormData={variableFormData}
              dispatchVariableFormData={dispatchVariableFormData}
              setDisableForm={setDisableForm}
              loadData={loadData}
            />
          </div>
        </FormModal>
      }
    </>
  );
}
InsertVariablesModal.propTypes = {
  modalName: PropTypes.string,
  modalToShow: PropTypes.string,
  loading: PropTypes.bool,
  variables: PropTypes.array,
  closeModal: PropTypes.func,
  handleSubmit: PropTypes.func,
}
