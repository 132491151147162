import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BuildingsService } from 'services/BuildingsService';
import { DropdownMultiSelect } from 'componentsV2';
import { ErrorTextWrapper } from 'components/Forms/shared.js';

export const SitesDropdownMultiSelect = ({
	value = [],
	labelState = true,
	onChange,
	visibleOptions = null,
	includeDisabled = false,
	isRequired = false,
	disabled = false,
	error = null,
	label = 'Site',
	allowSelectAll = true,
	selectAlllabel = 'All Sites',
	showRequiredText = false,
	sitesList = [],
	isClearable = true,
	className = '',
	onDataLoad = () => {}, // output for list of sites in case they are loaded from endpoint
	enabledModule = null,
}) => {
	const [sites, setSites] = useState([]);

	useEffect(() => {
		if (sitesList.length > 0) {
			setSites(sitesList);
		} else {
			BuildingsService.getAll(false, includeDisabled, enabledModule).then(
				serviceBuildings => {
					setSites(serviceBuildings);
					onDataLoad({
						data: serviceBuildings,
						loaded: true,
					});
				},
			);
		}
	}, [includeDisabled]);

	return (
		<>
			<DropdownMultiSelect
				label={label}
				labelState={labelState}
				disabled={disabled}
				placeholder={sites.length == 0 ? 'Loading Sites...' : 'Select a Site'}
				isRequired={isRequired}
				values={value}
				options={sites}
				className={className}
				allowSelectAll={allowSelectAll}
				selectAllLabel={selectAlllabel}
				onChange={onChange}
				error={error}
				visibleOptions={visibleOptions}
				showRequiredText={showRequiredText}
				isClearable={isClearable}
			/>
			{error && <ErrorTextWrapper>{error}</ErrorTextWrapper>}
		</>
	);
};

SitesDropdownMultiSelect.propTypes = {
	selectedSite: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	labelState: PropTypes.bool,
};
