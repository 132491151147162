export const shapeContextMenu = konvaShape => {
	konvaShape.on('contextmenu', event => {
		// Show the context menu
		const offset = 35;
		event.evt.preventDefault();
		const contextMenu = document.getElementById('shapeActionsList');
		contextMenu.style.display = 'block';
		contextMenu.style.left = `${event.evt.clientX - offset}px`;
		contextMenu.style.top = `${event.evt.clientY - offset}px`;

		// Hide the context menu on click outside
		document.addEventListener('click', function hideContextMenu() {
			contextMenu.style.display = 'none';
			document.removeEventListener('click', hideContextMenu);
		});
	});
};
