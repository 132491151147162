import React, { useState, useEffect } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Icon } from '@blueprintjs/core';
import { EventLogService } from 'services/EventLogService';
import BootstrapedTable from "components/BootstrapedTable";
import { SORTING_DIR } from 'utilities/constants';

export default function ActivityModule({ user }) {
	const [userLogs, setUserLogs] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);

	const fetchUserLogs = (
		page,
		size = perPage,
		sort_key = 'id',
		sort_dir = SORTING_DIR.DESC,
	) => {
		EventLogService.getUserLogs({
			userId: user.id,
			page,
			perPage: size,
			sortKey: sort_key,
			sortDir: sort_dir,
		})
			.then(response => {
				setUserLogs(response.data);
				setTotalRows(response._metadata.totalRecords);
				setCurrentPage(page);
				setPerPage(size);
			})
			.catch(error => {
				console.log(error);
			});
	};

	useEffect(() => {
		fetchUserLogs(currentPage, perPage);
	}, [currentPage, perPage]);

	const sortCaret = (order, column) => {
		if (order === SORTING_DIR.DESC) {
			return <Icon icon="caret-down" />;
		}

		return <Icon icon="caret-up" />;
	};

	const columns = [
		{ dataField: 'id', text: 'ID', sort: true, hidden: true },
		{
			dataField: 'description',
			text: 'Activity',
			sort: true,
			sortCaret,
		},
		{
			dataField: 'date',
			text: 'Date',
			sort: true,
			sortCaret,
		},
		{
			dataField: 'time',
			text: 'Time',
			sort: true,
			sortCaret,
		},
		{
			dataField: 'device',
			text: 'Device',
			sort: true,
			sortCaret,
		},
		{
			dataField: 'user',
			text: 'User',
			sort: true,
			sortCaret,
		},
	];

	const defaultSorted = [
		{
			dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
			order: SORTING_DIR.DESC, // desc or asc
		}
	];

	// this function has to be identified
	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			fetchUserLogs(currentPage, perPage, sortField, sortOrder);
		}
	};

	return (
		<div className='user-activity-log'>
			<div className='row'>
				<div className='col-12'>
					<h4>Recent Activity</h4>
				</div>
				<div className='col-12'>
					<BootstrapedTable
						keyField="id"
						data={userLogs}
						columns={columns}
						bordered={false}
						defaultSorted={defaultSorted}
						onTableChange={onTableChange}
						setCurrentPage={setCurrentPage}
						setRowsPerPage={setPerPage}
						currentPage={currentPage}
						rowsPerPage={perPage}
						totalRows={totalRows}
						sizePerPageList={[10, 25, 50, 100, 200]}
					/>
				</div>
			</div>
		</div>
	);
}
