import React from 'react';
import PropTypes from 'prop-types';
import {Title} from 'components/Title';
import {Button} from 'components/Button';
import {Input} from 'components/Input';
import { LabelText } from 'components/Forms/shared.js';
import {Select, MultiSelect} from '@blueprintjs/select';
import {MenuItem, Icon, Checkbox} from '@blueprintjs/core';
import Swal from 'sweetalert2';
import './css/templatedetails.scss';
import styled from 'styled-components';
import {allStates} from './statelist';
import '../CloneTemplate/index.scss';

const DropdownWrap = styled.div`
  padding: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d8e1e8;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
`;

const SelectWrap = styled(Select)`
  display: block;
  width: 100%;

  .dropdown-wrapper {
    padding: 10px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d8e1e8;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
  }

  .bp3-popover-target {
    display: block;
    width: 100%;
  }
`;

class TemplateDetails extends React.Component {
  state = {
    districts: this.props.districts,
    data: [],   //not used
  };

  makeTypes = item => {
    const {updateFields} = this.props;
    return (
      <MenuItem
        key={item.id}
        onClick={() => updateFields('type', item.value)}
        text={item.value}
      />
    );
  };

  makeStates = item => {
    const {updateFields} = this.props;
    return (
      <MenuItem
        key={item}
        onClick={() => updateFields('state', item)}
        text={item}
      />
    );
  };

  makeClients = item => {
    const {setClient} = this.props;
    return (
      <MenuItem
        key={item.id}
        onClick={() => setClient(item)}
        text={`${item.id} ${item.name}`}
      />
    );
  };

  addDistrict = item => {
    const {districts} = this.state;
    const districtsIds = [];
    districts.forEach(item => districtsIds.push(item.id));
    if (!districtsIds.includes(item.id)) {
      districts.push(item);
    }
    return this.setState({districts});
  };

  makeDistricts = (item, {handleClick}) => (
    <MenuItem
      key={item.id}
      onClick={handleClick}
      text={`${item.id} ${item.name}`}
    />
  );

  handleItemSelect = (item, event) => {
    this.addDistrict(item);
  };

  renderTag = item => <p>{item.name}</p>;

  updateFields = (key, value) => {
    const {template} = this.state;
    template[key] = value;
    return this.setState({template});
  };

  clonePlan = () => {
    Swal.showLoading();
    const {
      template,
      template: {name, state, isProprietary, districts, data},
      building,
      type
    } = this.props;
    if ((building?.key && type === 'plan') || type === 'core') {
      template.content.forEach(item => {
        item.subsections.forEach(one => {
          if (one.fileSection) {
            one.valueFile = {
              name: '',
              size: '',
              id: null,
            };
          }
        });
      });
      const bodyValues = {
        newName: name,
        state,
        isProprietary,
        buildingId: (building?.key && type === 'plan') ? building.key : null, //check backend
        districts: isProprietary ? districts : [],
        newContent: JSON.stringify(template.content),
      };
      const templateUrl =
        type == 'core' ? 'plan-templates' : 'district-templates';
      if (isProprietary) {
        if (districts == []) {
          Swal.fire({
            text:
              'For proprietary templates, a client must be selected',
            icon: 'error',
            confirmButtonText: 'Close',
          });
        } else {
          bodyValues.districts = districts;
        }
      }
      const token = JSON.parse(localStorage.getItem('user')).jwt;
      const url = `${process.env.API_URL}/${templateUrl}/${data?.id}/clone`;
      fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyValues),
      })
        .then(res => res.json())
        .then(resp => {
          Swal.close();
          const historyUrl =
            type == 'plan' ? 'edit' : 'editplan';
          const {history} = this.props;
          history.push(`/${historyUrl}/${resp.data.id}`);
        });

    } else {
      Swal.fire({
        text: 'You have to select a building',
        icon: 'error',
        confirmButtonText: 'Close',
      });
    }
  };

  submit = () => {
    const {
      template: {name, state, isProprietary, data},
      action,
    } = this.props;
    if (action == 'clone') {
      this.clonePlan();
      return;
    }
    const {districts} = this.state;
    if (!name) {
      return Swal.fire({
        text: 'Template name is required',
        icon: 'error',
        confirmButtonText: 'Close',
      });
    }
    if (!state) {
      return Swal.fire({
        text: 'State is required',
        icon: 'error',
        confirmButtonText: 'Close',
      });
    }
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    const bodyValues = {name, state, isProprietary};
    if (isProprietary) {
      if (districts.length === 0) {
        return Swal.fire({
          text:
            'For proprietary templates, a client must be selected',
          icon: 'error',
          confirmButtonText: 'Close',
        });
      }
      const distArray = [];
      districts.forEach(item => distArray.push(item.id));
      bodyValues.districts = distArray;
    }
    if (data?.id) {
      // Edit Details
      return fetch(
        `${process.env.API_URL}/plan-templates/${parseInt(
          data.id,
          10,
        )}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ...bodyValues,
          }),
        },
      )
        .then(res => res.json())
        .then(resp => {
          if (resp.statusCode === 200) {
            Swal.fire({
              title: 'Done!',
              icon: 'success',
              timer: 1000,
              showConfirmButton: false,
            }).then(() => {
              const {history} = this.props;
              Swal.close();
              return history.push('/');
            });
          }
        });
    } // Create new Template
    const content = [
      {
        id: 1,
        title: name,
        content: 'Section 1 content',
        subsections: [
          {
            id: 1,
            title: 'Subsection 1 title',
            content:
              '<p>This page intentionally left blank</p>',
          },
        ],
      },
    ];
    bodyValues.content = JSON.stringify(content);
    return fetch(`${process.env.API_URL}/plan-templates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...bodyValues,
      }),
    })
      .then(res => res.json())
      .then(resp => {
        if (resp.data) {
          const {history} = this.props;
          const {
            data: {id},
          } = resp;
          return history.push(`/addplan/${id}`);
        }
        return null;
      });
  };

  handleChange = () => {
    const {
      updateFields,
      template: {isProprietary},
    } = this.props;
    return updateFields('isProprietary', !isProprietary);
  };

  filterClients = (query, item) => {
    if (!query) return true;
    return item.name && item.searchString.includes(query.toLowerCase());
  };

  handleTagRemove = item => {
    const {districts} = this.state;
    const temp = districts.filter(one => one.name !== item.props.children);
    return this.setState({districts: temp});
  };

  render() {
    const {
      template: {name, state, isProprietary},
      clients,
      updateFields,
      title,
      submitText,
      action,
      building,
      selectedOptions,
      makeBuildings,
      type
    } = this.props;
    const {districts} = this.state;
    return (
      <div className="container-fluid">
        <div className="template-details-wrapper">
          <Title className="mt-2">{title}</Title>
          <hr/>
          <div className="row justify-content-around">
            <div className="col-6">
              <Input
                placeholder="Plan Name"
                label="Template Name (Required)"
                large
                value={name}
                onChange={e =>
                  updateFields('name', e.target.value)
                }
              />
              {type === 'plan' && (
                <>
                  <LabelText className="mt-2">
                    Building
                  </LabelText>
                  <Select
                    items={selectedOptions}
                    itemRenderer={makeBuildings}
                    filterable={false}
                    popoverProps={{
                      usePortal: false,
                    }}
                  >
                    <div className="dropdown-wrapper">
                      <div>
                        {building.value || 'Select'}
                      </div>
                      <Icon icon="caret-down"/>
                    </div>
                  </Select>
                </>
              )}
              {type === 'core' && (
                <>
                  <LabelText className="mt-2">State</LabelText>
                  <Select
                    items={allStates}
                    itemRenderer={this.makeStates}
                    className="select-wrapper"
                    filterable={false}
                    popoverProps={{
                      usePortal: false,
                    }}
                  >
                    <div className="dropdown-wrapper">
                      <div>{state || 'Select'}</div>
                      <Icon icon="caret-down"/>
                    </div>
                  </Select>
                  <Checkbox
                    checked={isProprietary}
                    label="Proprietary"
                    className="mt-2"
                    onChange={this.handleChange}
                  />
                  {type === 'core' && isProprietary && (
                    <MultiSelect
                      items={clients}
                      itemRenderer={this.makeDistricts}
                      itemPredicate={this.filterClients}
                      className="multi-select-wrapper"
                      selectedItems={districts}
                      resetOnSelect
                      tagRenderer={this.renderTag}
                      onItemSelect={this.handleItemSelect}
                      noResults={
                        <MenuItem disabled text="No results."/>
                      }
                      popoverProps={{
                        usePortal: false,
                      }}
                      tagInputProps={{
                        onRemove: this.handleTagRemove,
                      }}
                    />
                  )}
                </>
              )}
              <Button
                text={submitText}
                intent="primary"
                large
                className="mt-3"
                onClick={this.submit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TemplateDetails.propTypes = {
  template: PropTypes.object,
  history: PropTypes.object,
  updateFields: PropTypes.func,
  clients: PropTypes.array,
  setClient: PropTypes.func,
  districts: PropTypes.array,
  title: PropTypes.string,
  submitText: PropTypes.string,
  building: PropTypes.object,
  selectedOptions: PropTypes.array,
  makeBuildings: PropTypes.func,
  type: PropTypes.string
};

export default TemplateDetails;
