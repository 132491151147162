import React from 'react';
import { EmptyState, noResultFoundIcon, emptyDataIcon } from 'componentsV2';
import './css/CallListEmptyState.scss';

export const CallListEmptyState = ({ header, description, emptyDataState = false, children }) => (
	<div className={`call-list-empty-state-wrapper ${!emptyDataState ? 'show-border' : ''}`}>
		<EmptyState
			icon={emptyDataState ? emptyDataIcon : noResultFoundIcon}
			showCircles={false}
			header={header}
			description={description}
		>
			{children}
		</EmptyState>
	</div>
);
