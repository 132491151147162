import React, { useContext } from 'react';
import { Tooltip, ToggleButton } from 'componentsV2';
import PropTypes from 'prop-types';
import { CreateAlarmTypeContext } from '../../../../../../provider';
import { ENABLE_DISABLE_ALARM_MODAL } from '../../../../../../consts';

export const ToggleEnableAlarm = ({ formData }) => {
	const { allRequiredFieldsAreFilled, openModal } = useContext(CreateAlarmTypeContext);

	const isToggleAlarmEnabled = allRequiredFieldsAreFilled() && !formData.hasActiveAlarm;
	const disabledTooltip = formData.hasActiveAlarm
		? 'This alarm is currently active and cannot be edited. Please wait until the alarm concludes or deactivate it before making changes.'
		: !allRequiredFieldsAreFilled()
		? `This alarm can't be ${
				formData.disabled ? 'enabled' : 'disabled'
		  } until all required fields are complete.`
		: null;

	const onChangeHandler = () => {
		openModal(ENABLE_DISABLE_ALARM_MODAL);
	};

	const ToggleActionBtn = () => (
		<ToggleButton
			checked={!formData.disabled}
			label="Enable alarm type"
			onChange={onChangeHandler}
			disabled={!isToggleAlarmEnabled}
		/>
	);

	if (disabledTooltip !== null) {
		return (
			<Tooltip tooltipTitle={disabledTooltip}>
				<ToggleActionBtn />
			</Tooltip>
		);
	}
	return <ToggleActionBtn />;
};

ToggleEnableAlarm.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
};
