import React, { useContext } from 'react';
import { SearchBar, DropdownMultiSelect } from 'componentsV2';
import { CallListContext } from '../Providers';
import './css/CallListFilteration.scss';

export const CallListFilteration = () => {
	const { filterationData, sites, dispatchFilterationData, viewOnlySites } = useContext(
		CallListContext,
	);
	const handleChangeSite = sites => {
		dispatchFilterationData({
			type: 'selectedSites',
			payload: sites,
		});
	};

	return (
		<section className="call-list-filteration-container">
			<div className="call-list-filteration-container-left-side">
				<SearchBar
					placeholder="Search by name..."
					onSearch={title => dispatchFilterationData({ type: 'title', payload: title })}
					value={filterationData.title}
				/>
			</div>
			<div className="call-list-filteration-container-right-side">
				<DropdownMultiSelect
					label={null}
					labelState={false}
					values={filterationData.selectedSites}
					options={viewOnlySites}
					placeholder="Select a Site"
					onChange={handleChangeSite}
					visibleOptions={1}
					allowSelectAll={false}
				/>
			</div>
		</section>
	);
};
