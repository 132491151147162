import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder, Headline, Text } from "components/placeholder";
import { EventLogItemWrapper } from './EventLogItem';

export const EventLogPlaceholder = ({items}) => {

    let elements = [];
    for (let i = 0; i < items; i++) {
        elements.push(
            <EventLogItemWrapper key={i}>
                <Placeholder>
                    <Headline size="large" />
                    <Text size="medium" />
                </Placeholder>
            </EventLogItemWrapper>
        );
    }
    return elements;
}

EventLogPlaceholder.propTypes = {
  items: PropTypes.number,
};

EventLogPlaceholder.defaultProps = {
  items: 2
};