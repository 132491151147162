import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Toaster, Position, Intent } from '@blueprintjs/core';
import { BuildingsService, FirstRespondersService } from 'services';
import { HttpService } from 'networking/HttpService';
import {
	CardContainer,
	Input,
	Checkbox,
	BootstrapedTable,
	Dropdown,
	Avatar,
	TableCell,
	ToasterMessage,
	ToasterContext,
	LoadingSpinner,
} from 'componentsV2';
import './styles.css';
import './MainInfo.scss';
import { checkPermission } from 'utilities/permissions';
import { stateOptions } from './States.js';
import { isNotEmpty, isValidPhone } from '../../../../utilities/validations.js';
import { AssignModal } from './AssignModal';
import SitesDropDown from '../../../App/Users/AccountManager/SitePermissions/SitesDropdown';

const buildSitesTree = (sitesHierarchyList, sitesDetails) => {
	const map = {};
	const roots = [];

	const sitesMap = {};
	sitesDetails.forEach(({ id, name }) => {
		sitesMap[id] = name;
	});

	sitesHierarchyList.forEach(item => {
		map[item.id] = {
			...item,
			name: sitesMap[item.id],
			children: [],
		};
	});

	// Add children to parents from the sitesHierarchyList.
	sitesHierarchyList.forEach(item => {
		if (item.parent !== null) {
			map[item.parent]?.children
				? map[item.parent]?.children?.push(map[item.id])
				: roots.push(map[item.id]);
		} else {
			roots.push(map[item.id]);
		}
	});

	return roots;
};

const assignIndentation = (node, indent, result) => {
	result.push({ id: node.id, name: node.name, indent, label: node.name, value: node.id });
	node.children.forEach(child => assignIndentation(child, indent + 1, result));
};

// This function returns a list of items that can be passed into the sites dropdown list.
// It assigns an indentation value based on the sites hierarchy for the current district.
const sitesListWithIndent = (sitesHierarchy, sitesDetails) => {
	const sitesHierarchyList = Object.values(sitesHierarchy);
	const sitesTree = buildSitesTree(sitesHierarchyList, sitesDetails);
	const result = [];
	sitesTree.forEach(root => assignIndentation(root, 0, result));

	return result;
};

export const MainInfo = () => {
	const [loading, setLoading] = useState(true);
	const [tableRowData, setTableRowData] = useState(null);
	const [sites, setSites] = useState([]);
	const [disabledSites, setDisabledSites] = useState([]);
	const [currentBuilding, setCurrentBuilding] = useState(null);
	const permissions = useSelector(state => state.route.permissions);
	const districtId = useSelector(state => state.route.districtId);

	const getSites = async () => {
		BuildingsService.getAll(false).then(resp => {
			const sitesWithIndent = sitesListWithIndent(permissions.buildingHierarchy, resp);
			setSites(sitesWithIndent);

			const disabled = resp.filter(site => site.isLevelDisable === true).map(site => site.id);
			setDisabledSites(disabled);

			const userData = JSON.parse(localStorage.getItem('loggedUserData'));
			let building;

			if (userData.primaryBuildingId) {
				building = resp.find(
					site => site.id === userData.primaryBuildingId && site.isLevelDisable === false,
				);
			} else if (districtId) {
				building = resp.find(
					site => site.id === districtId && site.isLevelDisable === false,
				);
			}

			setCurrentBuilding(building || resp[0]);
		});
	};

	useEffect(() => {
		getSites();
	}, []);

	const emptySiteKeyPeople = [
		{
			role: 'districtAdmin',
			name: 'N/A',
			pa_id: null,
			roleKey: 'site_info_district_admin_id',
		},
		{
			role: 'drillLogAdmin',
			name: 'N/A',
			pa_id: null,
			roleKey: 'site_info_drill_log_admin_id',
		},
		{
			role: 'primaryFireDepartment',
			name: 'N/A',
			pa_id: null,
			roleKey: 'dl_school_resp_id',
		},
		{
			role: 'primaryPoliceDepartment',
			name: 'N/A',
			pa_id: null,
			roleKey: 'site_info_police_id',
		},
		{
			role: 'principal',
			name: 'N/A',
			pa_id: null,
			roleKey: 'dl_school_principal_id',
		},
		{
			role: 'safetyPlanAdmin',
			name: 'N/A',
			pa_id: null,
			roleKey: 'site_info_safety_plan_admin_id',
		},
	];
	const [siteKeyPeopleData, setSiteKeyPeopleData] = useState(emptySiteKeyPeople);

	const [isSubmitted, setIsSubmitted] = useState(false);
	const [stateValue, setStateValue] = useState({ value: '', label: '' });
	const [siteValue, setSiteValue] = useState({ value: '', label: '' });
	const [buildingId, setBuildingId] = useState();
	const [timezoneDropdown, setTimezoneDropdown] = useState({
		value: '',
		label: '',
	});

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [validationErrors, setValidationErrors] = useState({
		name: { hasError: false, errorMsg: '' },
		site: { hasError: false, errorMsg: '' },
		address: { hasError: false, errorMsg: '' },
		city: { hasError: false, errorMsg: '' },
		state: { hasError: false, errorMsg: '' },
		zip: { hasError: false, errorMsg: '' },
		officePhone: { hasError: false, errorMsg: '' },
		emergencyPhone: { hasError: false, errorMsg: '' },
		timeZone: { hasError: false, errorMsg: '' },
	});

	const [formDetails, setFormDetails] = useState({});

	const [siteUsers, setSiteUsers] = useState([]);
	const [respondUsers, setRespondUsers] = useState([]);

	const siteLabels = [
		{
			name: 'principal',
			text: 'Principal',
		},
		{
			name: 'districtAdmin',
			text: 'District Admin',
		},
		{
			name: 'drillLogAdmin',
			text: 'Drill Log Admin',
		},
		{
			name: 'safetyPlanAdmin',
			text: 'Safety Plan Admin',
		},
		{
			name: 'primaryFireDepartment',
			text: 'Primary Fire Department',
		},
		{
			name: 'primaryPoliceDepartment',
			text: 'Primary Police Department',
		},
	];

	useEffect(() => {
		if (currentBuilding) {
			getFormData(currentBuilding.id);
			getSiteKeyPeopleData(currentBuilding.id);
			getTimeZoneDropdownOptions();
			getClientSiteData();
			getUsers();
			getRespondUsers();
		}
	}, [currentBuilding?.id]);

	const handleOnClickModal = row => {
		setTableRowData(row);
		setIsModalOpen(true);
	};
	const canEditSiteInfo =
		currentBuilding && checkPermission('site_info_edit', permissions, currentBuilding.id);

	const columns = [
		{
			key: 'mainInfoId',
			text: 'ID',
			hidden: true,
			style: { fontWeight: 'bold' },
		},
		{
			key: 'attribute-name',
			dataField: 'role',
			text: 'Role',
			style: { fontWeight: 'bold', fontFamily: 'Nunito Sans' },
			formatter: value =>
				siteLabels.map(
					item => item.name === value && <TableCell key={item.id} text={item.text} />,
				),
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Assignee',
			style: { fontFamily: 'Nunito Sans', fontWeight: 'bold' },
			formatter: value =>
				value === 'N/A' ? (
					<div style={{ display: 'flex', padding: '10px' }}>
						<div style={{ padding: '8px' }} />
						<TableCell text={value} />
					</div>
				) : (
					<div style={{ display: 'flex' }}>
						<div style={{ padding: '8px' }}>
							<Avatar size="md" name={value} />
						</div>
						<TableCell text={value} />
					</div>
				),
		},
		{
			key: 'pa_id',
			dataField: 'pa_id',
			text: `${canEditSiteInfo ? 'Assign to' : ''}`,
			formatter: (item, row) => {
				if (row.name !== 'N/A' && canEditSiteInfo) {
					return (
						<NavLink
							to="#"
							exact
							style={{
								color: '#697277',
								fontWeight: 'bold',
								textDecoration: 'none',
							}}
							onClick={() => handleOnClickModal(row)}
						>
							Re-assign
						</NavLink>
					);
				}

				if (canEditSiteInfo) {
					return (
						<NavLink
							to="#"
							exact
							style={{
								color: '#007E8F',
								fontWeight: 'bold',
								textDecoration: 'none',
							}}
							onClick={() => handleOnClickModal(row)}
						>
							Assign
						</NavLink>
					);
				}

				return '';
			},
		},
	];

	// Site Dropdown Data
	const getClientSiteData = async () => {
		const buildingsData = await BuildingsService.getActiveBuildings();
		const initSite = buildingsData.find(
			element => element.id === parseInt(currentBuilding?.id),
		);
		const selectedSite = initSite || buildingsData[0];

		setBuildingId(selectedSite.id);

		setSiteValue({
			value: selectedSite.id,
			label: selectedSite.name,
		});
	};

	// Main Info Form Data
	const getFormData = async siteId => {
		setLoading(true);

		const data = await BuildingsService.getSiteInfo(siteId);
		await getSiteKeyPeopleData(siteId);

		// Set Form Data
		setFormDetails({
			name: data.name,
			address: data.address,
			address2: data.address2,
			city: data.city,
			zip: data.zip,
			county: data.county,
			identificationNumber: data.identificationNumber,
			webAddress: data.webAddress,
			officePhone: data.officePhone,
			emergencyPhone: data.emergencyPhone,
			fax: data.fax,
			isSafetyPlansEnabled: data.isSafetyPlansEnabled,
			isDrillLogsEnabled: data.isDrillLogsEnabled,
		});
		setSiteValue({
			value: data.name,
			label: data.name,
		});
		setStateValue({
			value: data.state,
			label: data.state,
		});
		setTimezoneDropdown({
			value: data.timeZone,
			label: data.timeZone,
		});
		setLoading(false);
	};

	// Site-Key-People Table Data
	const getSiteKeyPeopleData = async (siteId = null) => {
		siteId = siteId || currentBuilding.id;
		const data = await BuildingsService.getSiteKeyPeople(siteId);

		const siteTableResponse = data.sitePeople;
		const newSiteKeyPeopleData = emptySiteKeyPeople;
		for (let i = 0; i < newSiteKeyPeopleData.length; i += 1) {
			if (newSiteKeyPeopleData[i].role in siteTableResponse) {
				newSiteKeyPeopleData[i].name = siteTableResponse[newSiteKeyPeopleData[i].role].name
					? siteTableResponse[newSiteKeyPeopleData[i].role].name
					: 'N/A';
				newSiteKeyPeopleData[i].pa_id =
					siteTableResponse[newSiteKeyPeopleData[i].role].pa_id;
			}
		}
		setSiteKeyPeopleData(newSiteKeyPeopleData);
	};

	const validationErrorsHandler = () => {
		const errors = {};

		if (!isNotEmpty(formDetails.name)) {
			errors.name = {
				hasError: true,
				errorMsg: 'Organization name is required',
			};
		}

		if (!isNotEmpty(formDetails.address)) {
			errors.address = {
				hasError: true,
				errorMsg: 'Address is required',
			};
		}

		if (!isNotEmpty(formDetails.city)) {
			errors.city = { hasError: true, errorMsg: 'City is required' };
		}
		if (!isNotEmpty(formDetails.officePhone)) {
			errors.officePhone = {
				hasError: true,
				errorMsg: 'Office Phone is required',
			};
		} else if (!isValidPhone(formDetails.officePhone)) {
			errors.officePhone = {
				hasError: true,
				errorMsg: 'Office Phone is not valid',
			};
		}

		if (!isNotEmpty(formDetails.emergencyPhone)) {
			errors.emergencyPhone = {
				hasError: true,
				errorMsg: 'Emergency Phone is required',
			};
		} else if (!isValidPhone(formDetails.emergencyPhone)) {
			errors.emergencyPhone = {
				hasError: true,
				errorMsg: 'Emergency phone is not valid',
			};
		}

		if (!isNotEmpty(timezoneDropdown.label) || timezoneDropdown.label === 'Select...') {
			errors.timeZone = {
				hasError: true,
				errorMsg: 'Timezone is required',
			};
		}

		if (!isNotEmpty(stateValue.label)) {
			errors.state = {
				hasError: true,
				errorMsg: 'State is required',
			};
		}
		if (!isNotEmpty(formDetails.zip)) {
			errors.zip = {
				hasError: true,
				errorMsg: 'Zip Code is required',
			};
		}

		if (!isNotEmpty(siteValue.label)) {
			errors.site = {
				hasError: true,
				errorMsg: 'State is required',
			};
		}

		setValidationErrors({ ...validationErrors, ...errors });
		return errors;
	};

	// Handle form input changes.
	const handleChange = e => {
		const { name } = e.target;
		const { value } = e.target;
		setFormDetails({
			...formDetails,
			[name]: value,
		});
		let validations = {};

		const inputValidation = {
			[e.target.name]: { hasError: false, errorMsg: '' },
		};

		validations = { ...inputValidation };

		setValidationErrors({ ...validationErrors, ...validations });
	};

	const handleSubmit = e => {
		e.preventDefault();
		const errors = validationErrorsHandler();
		if (Object.keys(errors).length === 0) {
			submitUserForm(); // submit the form
		} else {
			console.log('Validation Errors:', validationErrors);
		}
	};

	const toasterRef = React.createRef();
	const toaster = useContext(ToasterContext);
	const successMessage = (
		<ToasterMessage text="You have successfully saved site information changes." />
	);

	const submitUserForm = async () => {
		const data = {
			team_id: currentBuilding?.id,
			team_organization_name: formDetails.name,
			team_address_1: formDetails.address,
			team_address1: formDetails.address,
			team_address2: formDetails.address2,
			team_city: formDetails.city,
			team_state: stateValue.value,
			team_zip: formDetails.zip,
			team_country: formDetails.county,
			team_identifier: formDetails.identificationNumber,
			team_phone: formDetails.officePhone,
			team_phone_emergency: formDetails.emergencyPhone,
			team_phone_ext: null,
			team_fax: formDetails.fax,
			team_web_address: formDetails.webAddress,
			timezone: timezoneDropdown.value,
			team_safety_plans_enabled: formDetails.isSafetyPlansEnabled === true ? 't' : 'f',
			team_drill_logs_enabled: formDetails.isDrillLogsEnabled === true ? 't' : 'f',
		};

		await BuildingsService.setSiteInfo(data);
		toaster(successMessage, Intent.SUCCESS, 'tick', 10000);
		getSites();
	};

	const getTimeZoneDropdownOptions = () => {
		const timeZones = Intl.supportedValuesOf('timeZone');
		const americaTimezones = timeZones.filter(item => item.includes('America'));

		const timezoneData = [];
		for (let i = 0; i < americaTimezones.length; i += 1) {
			const dataObject = {
				value: americaTimezones[i],
				label: americaTimezones[i],
			};
			timezoneData.push(dataObject);
		}

		return timezoneData;
	};
	const timeZoneDropData = getTimeZoneDropdownOptions();
	const getSiteInformationData = item => {
		setSiteValue(item);
		getFormData(item.id);
	};

	const onBuildingSiteChange = item => {
		setBuildingId(item.id);

		if (item.isDistrictLevel) {
			setIsSubmitted(false);
		} else {
			setIsSubmitted(true);
		}
		getSiteInformationData(item);
		resetValidationErrors();
		setCurrentBuilding(item);
		setSiteValue(item);
		getFormData(item.id);
	};

	const getUsers = async () => {
		await HttpService({
			method: 'GET',
			route: `/users`,
		})
			.then(res => {
				if (res.statusCode === 200) {
					const siteUsersData = [];
					res.data.map(user => siteUsersData.push(user?.person));
					setSiteUsers(siteUsersData);
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	const resetValidationErrors = () => {
		setValidationErrors({
			name: { hasError: false, errorMsg: '' },
			site: { hasError: false, errorMsg: '' },
			address: { hasError: false, errorMsg: '' },
			city: { hasError: false, errorMsg: '' },
			state: { hasError: false, errorMsg: '' },
			zip: { hasError: false, errorMsg: '' },
			officePhone: { hasError: false, errorMsg: '' },
			emergencyPhone: { hasError: false, errorMsg: '' },
			timeZone: { hasError: false, errorMsg: '' },
		});
	};

	const getRespondUsers = async () => {
		const res = await FirstRespondersService.getAll();

		const respondersData = [];
		res.data.map(item =>
			respondersData.push({
				emailAddress: item.primary_contact?.email,
				firstName: item.primary_contact?.first_name,
				lastName: item.primary_contact?.family_name,
				fullName: item.name,
				personId: item.id,
				name: item.name,
			}),
		);
		setRespondUsers(respondersData);
	};

	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && (
				<div style={{ height: '40px' }} className="Main-info-container">
					{isModalOpen && (
						<AssignModal
							isModalOpen={isModalOpen}
							setIsModalOpen={setIsModalOpen}
							siteUsers={
								tableRowData.roleKey === 'dl_school_resp_id' ||
								tableRowData.roleKey === 'site_info_police_id'
									? respondUsers
									: siteUsers
							}
							respondUsers={respondUsers}
							fetchSiteKeyPeopleData={getSiteKeyPeopleData}
							tableRowData={tableRowData}
							siteLabels={siteLabels}
							buildingId={buildingId}
						/>
					)}
					<div className="site-container">
						<div>
							<Dropdown
								className="site-dropdown-container"
								label="Site"
								placeholder="Select a site"
								value={siteValue}
								options={sites}
								disabledOptions={disabledSites}
								onChange={item => {
									onBuildingSiteChange(item);
								}}
								error={validationErrors.site.hasError}
								errorMessage={validationErrors.site.errorMsg}
								isRequired
							/>
						</div>
					</div>
					<div className="form-title">
						<b className="form-title-text">Information</b>
					</div>
					<CardContainer>
						<form onSubmit={handleSubmit}>
							<Input
								label="Organization name"
								error={validationErrors.name.hasError}
								errorMessage={validationErrors.name.errorMsg}
								value={formDetails.name || ''}
								name="name"
								isRequired
								onChange={handleChange}
								disabled={!canEditSiteInfo}
							/>
							<div className="form-container">
								<Input
									label="Address Line 1"
									error={validationErrors.address.hasError}
									errorMessage={validationErrors.address.errorMsg}
									value={formDetails.address || ''}
									name="address"
									isRequired
									onChange={handleChange}
									disabled={!canEditSiteInfo}
								/>
								<div>
									<Input
										label="Address Line 2"
										value={formDetails.address2 || ''}
										name="address2"
										onChange={handleChange}
										disabled={!canEditSiteInfo}
									/>
								</div>
								<div>
									<Input
										label="City"
										error={validationErrors.city.hasError}
										errorMessage={validationErrors.city.errorMsg}
										value={formDetails.city || ''}
										name="city"
										isRequired
										onChange={handleChange}
										disabled={!canEditSiteInfo}
									/>
								</div>
								<div>
									<Dropdown
										label="State"
										isRequired
										disabled={!canEditSiteInfo}
										value={stateValue}
										options={stateOptions}
										onChange={item => {
											setStateValue(item);
											setValidationErrors({
												...validationErrors,
												state: { hasError: false, errorMsg: '' },
											});
										}}
										error={validationErrors.state.hasError}
										errorMessage={validationErrors.state.errorMsg}
									/>
								</div>
								<div>
									<Input
										label="Zip Code"
										error={validationErrors.zip.hasError}
										errorMessage={validationErrors.zip.errorMsg}
										value={formDetails.zip || ''}
										name="zip"
										isRequired
										onChange={handleChange}
										disabled={!canEditSiteInfo}
									/>
								</div>
								<div>
									<Input
										label="County"
										value={formDetails.county || ''}
										name="county"
										onChange={handleChange}
										disabled={!canEditSiteInfo}
									/>
								</div>
								<div>
									<Input
										label="Identification number"
										value={formDetails.identificationNumber || ''}
										name="identificationNumber"
										onChange={handleChange}
										disabled={!canEditSiteInfo}
									/>
								</div>

								<div>
									<Input
										label="Web Address"
										value={formDetails.webAddress || ''}
										name="webAddress"
										onChange={handleChange}
										disabled={!canEditSiteInfo}
									/>
								</div>

								<div>
									<Input
										label="Office phone"
										error={validationErrors.officePhone.hasError}
										errorMessage={validationErrors.officePhone.errorMsg}
										value={formDetails.officePhone || ''}
										name="officePhone"
										isRequired
										onChange={handleChange}
										disabled={!canEditSiteInfo}
									/>
								</div>

								<div>
									<Input
										label="Emergency phone"
										error={validationErrors.emergencyPhone.hasError}
										errorMessage={validationErrors.emergencyPhone.errorMsg}
										value={formDetails.emergencyPhone || ''}
										name="emergencyPhone"
										isRequired
										onChange={handleChange}
										disabled={!canEditSiteInfo}
									/>
								</div>

								<div>
									<Input
										label="Fax"
										value={formDetails.fax || ''}
										name="fax"
										onChange={handleChange}
										disabled={!canEditSiteInfo}
									/>
								</div>

								<div>
									<Dropdown
										label="Timezone"
										value={timezoneDropdown}
										isRequired
										options={timeZoneDropData}
										disabled={!canEditSiteInfo}
										onChange={item => {
											setTimezoneDropdown(item);
											setValidationErrors({
												...validationErrors,
												timeZone: { hasError: false, errorMsg: '' },
											});
										}}
										error={validationErrors.timeZone.hasError}
										errorMessage={validationErrors.timeZone.errorMsg}
									/>
								</div>
							</div>
							<div style={{ marginTop: '16px' }}>
								<Checkbox
									label={
										<div>
											Enable safety plans
											<p>
												Whether or not to display this site in the safety
												plans section.
											</p>
										</div>
									}
									checked={formDetails.isSafetyPlansEnabled}
									onChange={e => {
										setFormDetails({
											...formDetails,
											isSafetyPlansEnabled: e.target.checked,
										});
									}}
									value
									name="isSafetyPlansEnabled"
									disabled={!canEditSiteInfo}
								/>
								<Checkbox
									label={
										<div>
											Enable drill logs
											<p>
												Whether or not to display this site on drill
												reports.
											</p>
										</div>
									}
									checked={formDetails.isDrillLogsEnabled}
									onChange={e => {
										setFormDetails({
											...formDetails,
											isDrillLogsEnabled: e.target.checked,
										});
									}}
									value
									name="isDrillLogsEnabled"
									disabled={!canEditSiteInfo}
								/>
							</div>
							<hr />
							<Toaster ref={toasterRef} position={Position.TOP_RIGHT} />
							{/* Save Button */}
							{canEditSiteInfo && (
								<div className="button-container">
									<button
										className="save-changes-button-container"
										size="lg"
										type="submit"
										intent="primaryDefault"
									>
										Save Changes{' '}
									</button>
								</div>
							)}
						</form>
					</CardContainer>
					{/* Site Key Table and Heading */}
					<div className="siteKey-table">
						<div className="site-key-title-container">Site Key People</div>

						<div className="site-key-table-container">
							<BootstrapedTable
								keyField="mainInfoId"
								data={siteKeyPeopleData}
								columns={columns}
								showPagination={false}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
