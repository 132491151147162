import React from 'react';
import { EmptyState, heartbroken, Animated } from 'componentsV2';

export const NoIconAddedState = ({ editPermission }) => (
	<Animated>
		<EmptyState
			className="mt-3"
			icon={heartbroken}
			header="No icons added yet."
			description={editPermission ? "Use the Available to Use tab to add icons to this map layer." : ""}
		/>
	</Animated>
);
