import { HttpService, buildRoute } from 'networking/HttpService';

const GENERIC_ERROR = new Error('An error occurred, please try again');

export const EventLogService = {
	getUserLogs: (params = {}) => {
		let route = `/users/${
			params.userId
		}/event-log?page=:page&perPage=:perPage`;

		const paginateParams = {
			page: params.page || 1,
			perPage: params.perPage || 100,
		};

		route = buildRoute(route, paginateParams);

		if (params.sortKey && params.sortDir) {
			route += '&sortKey=:sortKey&sortDir=:sortDir';
			route = buildRoute(route, {
				sortKey: params.sortKey,
				sortDir: params.sortDir,
			});
		}

		return HttpService({
			method: 'GET',
			route,
		}).then(res => {
			if (res.statusCode === 200) {
				return res;
			}

			throw GENERIC_ERROR;
		});
	},
};
