import React from 'react';
import { Checkbox } from 'componentsV2';
import PropTypes from 'prop-types';

export const AlarmGeneric = ({ formData, formErrors, onChangeFormData, disabled }) => (
	<Checkbox
		label="Yes"
		onChange={e => onChangeFormData({ type: 'generic', payload: e.target.checked })}
		checked={formData.generic}
		error={formErrors?.generic ? true : false}
		errorMessage={formErrors?.generic}
		disabled={disabled}
	/>
);

AlarmGeneric.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
};
