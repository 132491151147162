import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dialog, Classes, Icon, MenuItem } from '@blueprintjs/core';
import { LabelText } from 'components/Forms/shared.js';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Select } from '@blueprintjs/select';
import Swal from 'sweetalert2';

const allYears = [
	{ id: 1, value: '2021-2022' },
	{ id: 2, value: '2022-2023' },
	{ id: 3, value: '2023-2024' },
	{ id: 4, value: '2024-2025' },
	{ id: 5, value: '2025-2026' },
];

const SelectWrap = styled(Select)`
	display: block;
	width: 100%;
	.bp3-popover-target {
		display: block;
		width: 100%;
	}
`;

const HrWrapper = styled.hr`
	widht: 90%;
`;

const CreateDialog = styled(Dialog)`
	width: 300px;
`;

const DropdownWrap = styled.div`
	padding: 10px;
	width: 100%;
	background: #ffffff;
	border: 1px solid #d8e1e8;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
`;

const DialogFooterWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 10px;
`;

class NewAssess extends React.Component {
	state = {
		loading: false,
		templates: [],
		selectedTemplate: null,
		schoolYear: null,
	};

	getData = () => {
		this.setState({ loading: true });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		Promise.all([
			fetch(
				`${process.env.API_URL}/templates?includeUnpublished=${false}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			),
		])
			.then(([res1]) => Promise.all([res1.json()]))
			.then(([resp1]) => {
				const {
					data: { templates },
				} = resp1;

				this.setState({
					loading: false,
					templates,
				});
			});
	};

	startNewAssess = () => {
		this.setState({ loading: true });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { selectedBuilding, history } = this.props;
		const { selectedTemplate, schoolYear } = this.state;
		return fetch(`${process.env.API_URL}/assessments`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				buildingId: selectedBuilding.id,
				templateId: selectedTemplate.id,
				year: schoolYear.value,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() =>
						// Take me into the assessment I created
						history.push(`/assessment/${resp.data.id}`),
					);
				}
			});
	};

	makeTemplates = item => (
		<MenuItem
			key={item.id}
			text={item.name}
			onClick={() => this.setState({ selectedTemplate: item })}
		/>
	);

	makeYears = item => (
		<MenuItem
			key={item.id}
			text={item.value}
			onClick={() => this.setState({ schoolYear: item })}
		/>
	);

	componentDidMount() {
		return this.getData();
	}

	render() {
		const { closeNewAssess } = this.props;
		const { selectedTemplate, schoolYear, loading, templates } = this.state;
		return (
			<CreateDialog
				isOpen
				onClose={closeNewAssess}
				title="Start an assessment"
				{...this.state}
			>
				<div className={Classes.DIALOG_BODY}>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<LabelText className="mt-2">Template</LabelText>
							<SelectWrap
								items={templates}
								itemRenderer={this.makeTemplates}
								className="w-100"
								filterable={false}
							>
								<DropdownWrap>
									<div>
										{selectedTemplate
											? selectedTemplate.name
											: 'Select'}
									</div>
									<Icon icon="caret-down" />
								</DropdownWrap>
							</SelectWrap>
							<LabelText className="mt-2">Year</LabelText>
							<SelectWrap
								items={allYears}
								itemRenderer={this.makeYears}
								className="w-100"
								filterable={false}
							>
								<DropdownWrap>
									<div>
										{schoolYear
											? schoolYear.value
											: 'Select'}
									</div>
									<Icon icon="caret-down" />
								</DropdownWrap>
							</SelectWrap>

							<HrWrapper />
						</>
					)}
				</div>
				<DialogFooterWrapper>
					<Button
						style={{
							marginRight: 16,
							height: 40,
							width: 68,
						}}
						onClick={closeNewAssess}
						text="Cancel"
						intent="none"
					/>
					<Button
						style={{
							height: 40,
							width: 68,
							background: '#9F1C3A',
						}}
						onClick={this.startNewAssess}
						text="Start"
						intent="danger"
					/>
				</DialogFooterWrapper>
			</CreateDialog>
		);
	}
}

NewAssess.propTypes = {
	templates: PropTypes.array,
	selectedBuilding: PropTypes.object,
	closeNewAssess: PropTypes.func,
	history: PropTypes.object,
};

export default NewAssess;
