import { HttpService } from 'networking/HttpService';
import { deserializeStates } from 'utilities/drills/deserializers';

export const StatesService = {
	async getAll(showAll = false) {
		try {
			const response = await HttpService({
				method: 'GET',
				route: `/drills/states?showAll=${showAll}`,
			});
			return deserializeStates(response.data);
		} catch (error) {
			throw error;
		}
	},
};
