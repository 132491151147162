import React, { useContext, useEffect, useState } from 'react';
import { TemplateService } from 'services';
import { Animated, Modal, ModalFooter, AlertModal, Input, Button, Intent, FeaturedIcon } from 'componentsV2';
import { ToasterContext } from 'pages/App';
import { AddUpdateCustomVariable, VariableItem } from './components';
import './css/CreateVariableModal.scss';

export const CreateVariableModal = ({
    isOpen,
    onClose,
    template,
    activeSection,
    sectionIndex,
    subsectionIndex,
    updateNodes,
    reloadData,
    activeEditor
}) => {

    const [loading, setLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [commonVariables, setCommonVariables] = useState([]);
    const [filteredCommonVariables, setFilteredCommonVariables] = useState([]);
    const [customVariables, setCustomVariables] = useState([]);
    const [filteredCustomVariables, setFilteredCustomVariables] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const toaster = useContext(ToasterContext);

    useEffect(() => {
        if (isOpen) {
            getCommonVariables();
            getCustomVariables();
        }
    }, [isOpen]);

    const getCommonVariables = () => {
        setLoading(true);
        TemplateService.getCommonVariables()
            .then((response) => {
                if (response.statusCode === 200) {
                    setCommonVariables(response.data);
                    setFilteredCommonVariables(response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching Common Variables:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getCustomVariables = () => {
        setLoading(true);
        TemplateService.getPlanTemplateById(template.id)
            .then((response) => {
                if (response.statusCode === 200) {
                    setCustomVariables(response.data.variables);
                    setFilteredCustomVariables(response.data.variables);
                }
            })
            .catch(error => {
                console.error('Error fetching Custom Variables:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        searchVariables(searchValue);
    }, [searchValue]);

    const searchVariables = (value) => {
        if (value != "") {
            const filteredCommonVars = commonVariables.filter(function (commonVar) {
                return JSON.parse(commonVar.content).name.toLowerCase().includes(value.toLowerCase())
            });
            setFilteredCommonVariables(filteredCommonVars);

            const filteredCustomVars = customVariables.filter(function (customVar) {
                return JSON.parse(customVar.content).name.toLowerCase().includes(value.toLowerCase())
            });
            setFilteredCustomVariables(filteredCustomVars);
        } else {
            setFilteredCommonVariables(commonVariables);
            setFilteredCustomVariables(customVariables);
        }
    }

    const handleAddCustomVariable = () => {
        setItemToEdit(null);
        setShowAddModal(true);
    }

    const handleInsertVariable = (item) => {
        let variableContent = `<span class="variable-highlight">${item.name}</span>`;
        if(activeEditor?.current) {
            // Insert the variable in the current cursor position
            activeEditor.current.insertContent(variableContent);
        } else {
            // Insert the variable at the end
            let newContent = activeSection.content;
            newContent += variableContent;
            updateNodes(sectionIndex, subsectionIndex, newContent);
        }
        onClose(false);
    }

    const handleEditVariable = (item) => {
        setItemToEdit(item);
        setShowAddModal(true);
    }

    const confirmDeleteVariable = (item) => {
        let itemSelected = item;
        itemSelected.info = JSON.parse(item.content);
        setItemToDelete(itemSelected);
        setShowDeleteModal(true);
    }

    const handleDeleteVariable = (oldVar) => {
        setShowDeleteModal(false);
        setLoading(true);

        template.sections.forEach(item => {
            if (item.content) {
                if (item.content.includes(oldVar.name)) {
                    item.content = item.content.replaceAll(
                        `<span class=\"variable-highlight\">${oldVar.name}</span>`,
                        '',
                    );
                }
                item.subsections.forEach(one => {
                    if (one.content.includes(oldVar.name)) {
                        one.content = one.content.replaceAll(
                            `<span class=\"variable-highlight\">${oldVar.name}</span>`,
                            '',
                        );
                    }
                });
            }
        });

        TemplateService.updatePlanTemplate({ content: JSON.stringify(template.sections) }, template.id)
            .then(resp => {
                if (resp.statusCode === 200) {
                    TemplateService.deleteCustomVariable(template.id, oldVar.id)
                        .then((response) => {
                            if (response.statusCode === 200) {
                                toaster(`You have successfully deleted the variable "${oldVar.info.name}"`,
                                    Intent.SUCCESS,
                                    <FeaturedIcon icon={'tick'} type={'Success'} size={'md'} shape={'circleIcon'} />
                                );
                                reloadData(true);
                            }
                        })
                        .catch(error => {
                            console.error('Error removing custom variable:', error);
                        })
                        .finally(() => {
                            onClose(false);
                        });
                }
            })
            .catch(error => {
                console.error('Error removing variable from template content:', error);
            })
    };

    const clearSearchButton =
        <Animated show={searchValue.length > 0}>
            <Button
                large
                icon="delete"
                size="sm"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="clear-search-button"
                onClick={() => setSearchValue("")}
            />
        </Animated>;

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title="Insert variable"
                loading={loading}
                showCloseButton={false}
                customBodyClass="body-no-padding insert-variable-modal"
                customFooter={
                    <ModalFooter
                        confirmButton={
                            <Button
                                text="Done"
                                type="primaryDefault"
                                intent="default"
                                onClick={() => onClose(false)}
                            />
                        }
                    />
                }
            >
                <>
                    <div className="search-wrapper">
                        <div className="w-100">
                            <Input
                                placeholder="Search variables"
                                icon="search"
                                width="100%"
                                rightElement={clearSearchButton}
                                onChange={({ target }) => setSearchValue(target.value)}
                                value={searchValue}
                            />
                        </div>
                    </div>


                    <div className="variables-wrapper">

                        <div className="variable-title-wrapper">
                            <span className='variables-title'>
                                Common variables
                            </span>
                        </div>

                        {filteredCommonVariables.length > 0 && (
                            <>
                                {filteredCommonVariables.map((commonVar, index) => (
                                    <Animated key={`animated-common-${index}`}>
                                        <VariableItem
                                            key={`common-${index}`}
                                            item={commonVar}
                                            type="common"
                                            handleInsertVariable={handleInsertVariable}
                                        />
                                    </Animated>
                                ))}
                            </>
                        )}

                        {filteredCommonVariables.length == 0 && (
                            <Animated>
                                <div className="variables-empty-state">
                                    <div className="empty-state-content">
                                        <div className="empty-state-title">
                                            No common variables.
                                        </div>
                                        <div className="empty-state-description">
                                            {commonVariables.length == 0
                                                ? <>No common variables have been added.</>
                                                : <>Your search didn't match any common variables. Please try again or add a new custom variable.</>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Animated>
                        )}
                    </div>

                    <div className="variables-wrapper">

                        <div className="variable-title-wrapper">
                            <span className='variables-title'>
                                Custom variables
                            </span>

                            <Button
                                text="Add custom variable"
                                type="primaryDefault"
                                intent="default"
                                size="sm"
                                onClick={handleAddCustomVariable}
                            />
                        </div>

                        {filteredCustomVariables.length > 0 && (
                            <>
                                {filteredCustomVariables.map((customVar, index) => (
                                    <Animated key={`animated-custom-${index}`}>
                                        <VariableItem
                                            key={`custom-${index}`}
                                            item={customVar}
                                            type="custom"
                                            handleInsertVariable={handleInsertVariable}
                                            handleEditVariable={handleEditVariable}
                                            confirmDeleteVariable={confirmDeleteVariable}
                                        />
                                    </Animated>
                                ))}
                            </>
                        )}

                        {filteredCustomVariables.length == 0 && (
                            <Animated>
                                <div className="variables-empty-state">
                                    <div className="empty-state-content">
                                        <div className="empty-state-title">
                                            No custom variables.
                                        </div>
                                        <div className="empty-state-description">
                                            {customVariables.length == 0
                                                ? <>No custom variables have been added.</>
                                                : <>Your search didn't match any custom variables. Please try again or add a new custom variable.</>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Animated>
                        )}

                    </div>
                </>
            </Modal>

            <AlertModal
                isOpen={showDeleteModal}
                onClose={setShowDeleteModal}
                title={`Are you sure you want to delete "${itemToDelete?.info?.name}"?`}
                subtitle="Once deleted, this cannot be recovered"
                icon="trash"
                iconColor="error"
                showCloseButton={true}
                alertConfirmButton={
                    <Button
                        text="Yes, delete it"
                        type="primaryDanger"
                        size="md"
                        onClick={() => handleDeleteVariable(itemToDelete)}
                    />
                }
                alertCancelButton={
                    <Button
                        text="No, go back"
                        type="tertiary"
                        size="md"
                        onClick={() => setShowDeleteModal(false)}
                    />
                }
            />

            <AddUpdateCustomVariable
                showAddModal={showAddModal}
                setShowAddModal={setShowAddModal}
                template={template}
                reloadVariables={getCustomVariables}
                item={itemToEdit}
                reloadData={reloadData}
                onCloseMainModal={onClose}
            />
        </>
    )
}
