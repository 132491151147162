import React, { useEffect, useState, useContext } from 'react';
import {
	FormModal,
	Intent,
	FeaturedIcon,
	Dropdown,
	Input,
	RadioButtonGroup,
	DropdownMultiSelect,
} from 'componentsV2';
import { TemplateService, UsersService } from 'services';
import { Radio } from '@blueprintjs/core';
import './css/AddUpdateCopyTemplateModal.scss';
import { ToasterContext } from 'pages/App';
import { allStates } from '../../../AddTemplate/statelist';
export const AddUpdateCopyTemplateModal = ({
	showCreateUpdateCopyTemplateModal,
	setShowCreateUpdateCopyTemplateModal,
	editTemplateId,
	reloadData,
	isCopy,
	history,
}) => {
	const toaster = useContext(ToasterContext);
	const [isEdit, setIsEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [sites, setSites] = useState();
	const [oldTitle, setOldTitle] = useState();
	const [formData, setFormData] = useState({
		id: null,
		title: '',
		state: '',
		proprietary: '2',
		site: '',
		content: '',
	});
	const statesArray = allStates.map(state => {
		return { value: state, label: state };
	});

	const createTemplate = createData => {
		setLoading(true);
		TemplateService.savePlanTemplate(createData)
			.then(resp => {
				if (resp.statusCode === 201) {
					toaster(
						'You have successfully created "' + formData.title + '"',
						Intent.SUCCESS,
						<FeaturedIcon
							icon={'tick'}
							type={'Success'}
							size={'md'}
							shape={'circleIcon'}
						/>,
					);
					history.push('/core/templates/' + resp.data.id);
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateTemplate = updatedata => {
		setLoading(true);
		TemplateService.updatePlanTemplate(updatedata, formData.id)
			.then(resp => {
				if (resp.statusCode === 200) {
					toaster(
						'You have successfully edited "' + formData.title + '"',
						Intent.SUCCESS,
						<FeaturedIcon
							icon={'tick'}
							type={'Success'}
							size={'md'}
							shape={'circleIcon'}
						/>,
					);
					setShowCreateUpdateCopyTemplateModal(false);
					setIsEdit(false);
					reloadData();
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const copyTemplate = () => {
		const districts =
			formData.proprietary === '1'
				? formData.site.map(site => {
						return { id: site.value, name: site.label };
				  })
				: [];
		const templateData = {
			state: formData.state.value,
			newName: formData.title,
			isProprietary: formData.proprietary === '1' ? true : false,
			districts: districts,
			newContent: formData.content,
		};
		setLoading(true);
		TemplateService.copyPlanTemplate(templateData, formData.id)
			.then(resp => {
				if (resp.statusCode === 201) {
					toaster(
						'You have successfully copied "' + oldTitle + '"',
						Intent.SUCCESS,
						<FeaturedIcon
							icon={'tick'}
							type={'Success'}
							size={'md'}
							shape={'circleIcon'}
						/>,
					);
					history.push('/core/templates/' + resp.data.id);
					reloadData();
					handleCloseModal();
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSave = e => {
		e.preventDefault();

		const districts =
			formData.proprietary === '1'
				? formData.site.map(site => {
						return site.value;
				  })
				: [];
		const templateData = {
			state: formData.state.value,
			name: formData.title,
			isProprietary: formData.proprietary === '1' ? true : false,
			districts: districts,
		};
		let data = templateData;
		isCopy ? copyTemplate() : isEdit ? updateTemplate(data) : createTemplate(data);
	};

	const handleCloseModal = () => {
		setShowCreateUpdateCopyTemplateModal(false);
		setIsEdit(false);
		editTemplateId = null;
		isCopy = false;
		setFormData({
			id: null,
			title: '',
			state: '',
			proprietary: '2',
			site: '',
		});
		setIsDisabled(true);
	};

	const getSites = () => {
		setLoading(true);
		UsersService.getClients()
			.then(resp => {
				if (resp.statusCode === 200) {
					const sites = resp.data.map(site => {
						return { value: site.id, label: site.id + ' ' + site.name };
					});
					setSites(sites);
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const loadTemplateInfo = () => {
		!isCopy ? setIsEdit(true) : isCopy ? setIsDisabled(false) : '';
		setLoading(true);
		TemplateService.getPlanTemplateById(editTemplateId)
			.then(resp => {
				if (resp.statusCode === 200) {
					const template = resp.data.template;

					const districts =
						template.districts && template.isProprietary === true
							? template.districts.map(site => {
									return {
										value: site.districtId,
										label: site.districtId + ' ' + site.name,
									};
							  })
							: [];
					setOldTitle(template.name);
					setFormData(formData => ({
						...formData,
						id: template.id,
						title: template.name + (isCopy ? '- COPY ' : ''),
						state: { value: template.state, label: template.state },
						proprietary: template.isProprietary === true ? '1' : '2',
						site: districts,
						content: template.content,
					}));
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getSites();
	}, []);

	useEffect(() => {
		if (editTemplateId) {
			setIsDisabled(true);
			loadTemplateInfo();
		}
	}, [showCreateUpdateCopyTemplateModal]);

	return (
		<FormModal
			isOpen={showCreateUpdateCopyTemplateModal}
			onClose={() => handleCloseModal()}
			title={
				isCopy
					? 'Copy safety plan template'
					: isEdit
					? 'Edit template details'
					: 'Create a new template'
			}
			size="small"
			loading={loading}
			confirmBtn={{
				title: isCopy ? 'Copy template' : isEdit ? 'Save changes' : 'Create template',
				onClick: handleSave,
				disabled: isDisabled === true ? true : false,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: handleCloseModal,
			}}
			customClassName={'edit-legend-container'}
			scrollContent={false}
			subtitle={isCopy ? oldTitle + ' being copied' : null}
		>
			<div className="w-100">
				<Input
					label="Template title"
					name="title"
					isRequired={true}
					width="100%"
					value={formData?.title}
					onChange={e => {
						setFormData({ ...formData, title: e.target.value });
						e.target.value &&
						formData.state &&
						((formData.proprietary === '1' && Object.keys(formData.site).length > 0
							? true
							: false) || formData.proprietary === '2'
							? true
							: false)
							? setIsDisabled(false)
							: setIsDisabled(true);
					}}
					placeholder="Template title"
				/>
			</div>

			<div className="w-100 mt-3">
				<Dropdown
					label="State"
					placeholder="Select state"
					value={formData?.state}
					options={statesArray}
					onChange={e => {
						setFormData({ ...formData, state: e });
						e &&
						formData.title &&
						((formData.proprietary === '1' && Object.keys(formData.site).length > 0
							? true
							: false) || formData.proprietary === '2'
							? true
							: false)
							? setIsDisabled(false)
							: setIsDisabled(true);
					}}
					isRequired={true}
				/>
			</div>

			<div className="w-100 properitary-container mt-3">
				<RadioButtonGroup
					label={'Proprietary template'}
					onChange={e => {
						setFormData({ ...formData, proprietary: e.target.value });
						formData.title &&
						formData.state &&
						((e.target.value === '1' && Object.keys(formData.site).length > 0
							? true
							: false) || e.target.value === '2'
							? true
							: false)
							? setIsDisabled(false)
							: setIsDisabled(true);
					}}
					selectedValue={formData ? formData?.proprietary : '2'}
					size={'small'}
					bordered={true}
					required={true}
					layout={'inline'}
				>
					<Radio label={'Yes'} value="1" className="radio-button" />
					<Radio label={'No'} value="2" className="radio-button" />
				</RadioButtonGroup>
			</div>
			<div className="w-100 mt-3">
				<DropdownMultiSelect
					placeholder="Select site"
					values={formData?.proprietary === '2' ? [] : formData?.site}
					options={sites}
					onChange={e => {
						setFormData({ ...formData, site: e });
						formData.state &&
						formData.title &&
						((formData.proprietary === '1' && Object.keys(e).length > 0
							? true
							: false) || formData.proprietary === '2'
							? true
							: false)
							? setIsDisabled(false)
							: setIsDisabled(true);
					}}
					visibleOptions={1}
					isRequired={formData?.proprietary === '2' ? false : true}
					disabled={formData?.proprietary === '2' ? true : false}
					error={false}
					label={'Site visibility'}
					allowSelectAll={false}
					showRequiredText={false}
					isClearable={true}
				/>
			</div>
		</FormModal>
	);
};
