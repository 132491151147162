import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'componentsV2';
import { RapidAlarmService } from 'services/RapidAlarmService';
import { ToasterContext } from 'pages/App';
import { AlarmTypesTable } from './AlarmTypesTable';
import { NavLink } from 'react-router-dom';

export const AlarmTypes = ({ editPremission, isSuperAdmin, hasPermissionOnDistrict, history }) => {
	const [alarmTypesList, setAlarmTypesList] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [loading, setLoading] = useState(true);
	const toaster = useContext(ToasterContext);

	const [filterationData, setFilterationData] = useState({
		page: 1,
		perPage: 10,
	});

	useEffect(() => {
		getAlarmTypesList();
	}, [filterationData]);

	const getAlarmTypesList = () => {
		setLoading(true);

		RapidAlarmService.getAlarmTypes(filterationData)
			.then(response => {
				setAlarmTypesList(response.data);
				setTotalRows(response._metadata.totalRecords);
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<div className="alarmtype-container">
				<section className="header">
					<div className="row">
						<div className="col-md-8">
							<h4 className="body-lg-bold">Alarm types</h4>
							<p>
								Setting an alarm as priority will ensure it appears at the top of
								the alarm list.
							</p>
							<p>
								*Setting an alarm as generic will designate this specific alarm as
								the default option in case no specific alarm type is chosen.
							</p>
						</div>
						{(hasPermissionOnDistrict || isSuperAdmin) && (
							<div className="col-md-4 text-right">
								<NavLink
									exact
									to={{
										pathname: '/rapid-alarms/settings/create-alarm-type',
									}}
								>
									<Button
										size="md"
										text="Create new alarm type"
										icon="plus"
										type="secondaryDefault"
									/>
								</NavLink>
							</div>
						)}
					</div>
				</section>

				<AlarmTypesTable
					hasPermissionOnDistrict={hasPermissionOnDistrict}
					isSuperAdmin={isSuperAdmin}
					loading={loading}
					list={alarmTypesList}
					filterationData={filterationData}
					setFilterationData={setFilterationData}
					totalRows={totalRows}
					reloadList={getAlarmTypesList}
					history={history}
				/>
			</div>
		</>
	);
};
