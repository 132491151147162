import React, { useContext } from 'react';
import {
	AddNoteModal,
	ReunifyStudentModal,
	StudentHistoryModal,
	ReunificationStudentGuardiansModal,
	GuardianUndoCheckInModal,
} from '.';
import { RollCallAndReunificationContext } from '../RollCallAndReunification';
import {
	ADD_NOTE_MODAL,
	STUDENT_HISTORY_MODAL,
	REUNIFY_STUDENT_MODAL,
	STUDENT_GUARDIANS_LIST,
	GUARDIAN_UNDO_CHECKIN_MODAL,
} from './consts';

export const RollCallReunificationModals = ({ contentToShow }) => {
	const { modalToShow, selectedStudent } = useContext(RollCallAndReunificationContext);

	return (
		<>
			{modalToShow === ADD_NOTE_MODAL && <AddNoteModal contentToShow={contentToShow} />}
			{modalToShow === STUDENT_HISTORY_MODAL && (
				<StudentHistoryModal contentToShow={contentToShow} />
			)}
			{modalToShow === REUNIFY_STUDENT_MODAL && <ReunifyStudentModal />}
			{modalToShow === STUDENT_GUARDIANS_LIST && (
				<ReunificationStudentGuardiansModal selectedStudent={selectedStudent} />
			)}
			{modalToShow === GUARDIAN_UNDO_CHECKIN_MODAL && <GuardianUndoCheckInModal />}
		</>
	);
};
