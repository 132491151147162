import React from 'react';
import { Helper } from 'utilities/helper';
import './css/ExistingGuardians.scss';
import { useStudentStatus } from '../../../Students/hooks';
import { CHECKIN_STEPS } from '../consts';

export const ExistingGuardiansStep = ({
	guardiansResponse,
	onChangeModalDetails,
	setSelectedGuardian,
	selectedGuardian,
	setAddAsNew,
	setManualCheckIn,
	changeStep,
	setGuardianStudents,
	setIsUseSelected,
}) => {
	const handleChange = target => {
		let currentSelected = guardiansResponse.find(item =>
			target.id ? item.id == target.id : item.personId == target.personId,
		);

		setAddAsNew(false);
		setSelectedGuardian(currentSelected);
		onChangeModalDetails({
			disabled: false,
			onConfirmHandler: () => {
				setAddAsNew(false);
				setIsUseSelected(true);
				setManualCheckIn(false);
				setGuardianStudents(currentSelected);
				changeStep(CHECKIN_STEPS.GUARDIAN_WITH_STUDENTS_STEP);
			},
		});
	};
	return (
		<div className="existingGuardians_Wrapper">
			<div className="row">
				<div className="col-md-12">
					{guardiansResponse.map((item, index) => {
						if (item.checkedIn) {
							item.checkIn = { status: 'checkedIn' };
						}

						return useStudentStatus(
							item.name,
							Helper.formatDigitsToPhone(item.phone),
							item,
							() => handleChange(guardiansResponse[index]),
							selectedGuardian,
							false,
							() => {},
							[],
							item.checkedIn,
						);
					})}
				</div>
			</div>
		</div>
	);
};
