import React, { useEffect, useState } from 'react';
import { ResetSuccess, logoNavigateSwitch } from 'assets/icons';
import { PropTypes } from 'prop-types';
import EmailTimer from './EmailTimer';

export default function ResetPassword({ email, handleResendLink }) {
	const [timerEnd, setTimerEnd] = useState(false);
	const [datetime, setDateTime] = useState(new Date());
	const [time, setTime] = useState();

	const dateoptions = {
		weekday: 'long',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	};

	const timeOptions = {
		hour12: true,
		hour: '2-digit',
		minute: '2-digit',
	};

	useEffect(() => {
		const newDateTime = new Date(datetime.getTime() + 24 * 60 * 60 * 1000);

		const formattedDate = newDateTime.toLocaleDateString([], dateoptions).replace(',', '');
		const formattedTime = newDateTime.toLocaleTimeString([], timeOptions);

		setDateTime(formattedDate);
		setTime(formattedTime);
	}, []);

	const handleTimerEnd = () => {
		setTimerEnd(true);
	};
	return (
		<div className="expired-container">
			<div className="expired-wrapper">
				<img alt="Navigate360 Logo" src={logoNavigateSwitch} />
				{/* <LogoSuccess src={ResetSuccess} /> */}
				<img alt="Logo success" src={ResetSuccess} />

				<div className="expired-box">
					<p>
						<span className="expired-done">Done!</span> <br />
						New reset link has been sent to the email address <strong>
							{email}
						</strong>{' '}
						<br /> The reset link will be deactivated within <strong>24 hours</strong>{' '}
						on{' '}
						<strong>
							{datetime.toString()} {time}
						</strong>
						<br /> <br />
						{!timerEnd && (
							<p>
								<EmailTimer
									seconds={60}
									message="If you didn't receive an email, please check your junk folder or wait a moment"
									handleTimerEnd={handleTimerEnd}
								/>
							</p>
						)}
						{timerEnd && (
							<p>
								If you didn't receive an email, please check your junk folder or{' '}
								<a href="$" onClick={handleResendLink}>
									resend link
								</a>
							</p>
						)}
					</p>
				</div>
			</div>
		</div>
	);
}

ResetPassword.propTypes = {
	email: PropTypes.string,
	handleResendLink: PropTypes.func,
};
