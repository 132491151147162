/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { downArrow, upArrow } from 'assets/icons';

const Wrapper = styled.div`
	width: 100%;
	padding: 24px;
	transition: 0.2s;
	margin-bottom: 40px;
	background-color: white;

	.title-row {
		display: flex;
		cursor: pointer;
		flex-directin: row;

		h4 {
			margin: 0;
			color: #5c7080;
			font-size: 18px;
			text-align: left;
			font-weight: 700;
			line-height: 21px;
			font-style: normal;
			letter-spacing: 0.3;
			font-family: Merriweather;
		}

		img {
			user-select: none;
			margin-left: 10px;
		}
	}

	.content-rows {
		margin-top: 24px;
	}

	p {
		color: #5c7080;
		font-size: 16px;
		text-align: left;
		font-weight: 400;
		line-height: 22px;
		font-style: normal;
		letter-spacing: 0px;
		font-family: Nunito Sans;

		span {
			color: #9f1c3a;
		}
	}
`;

export const InstructionsBlock = ({ title = 'Instructions', contentRows }) => {
	const [isVisible, setIsVisible] = useState(false);

	return (
		<Wrapper>
			<div className="title-row" onClick={() => setIsVisible(!isVisible)}>
				<h4>{title}</h4>
				<img alt="" src={isVisible ? upArrow : downArrow} />
			</div>

			{isVisible && (
				<div className="content-rows">
					{contentRows?.map(item => {
						if (typeof item === 'string') {
							return <p>{item}</p>;
						}

						return item;
					})}
				</div>
			)}
		</Wrapper>
	);
};

InstructionsBlock.propTypes = {
	title: PropTypes.string,
	contentRows: PropTypes.array.isRequired,
};
