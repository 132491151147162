import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
 
	body {
		font-family: 'Open Sans', sans-serif;
		font-style: normal;
		font-weight: 400;
		height: 100%;
		min-height: 100vh;
		width: 100%;
		background: #F5F8FA;
	}
	button{
		&:focus{
			outline: none !important;
		}
	}
	.bp3-dialog{
		background: #fff !important;
	}
	.variable-highlight{
		background: rgba(19, 124, 189, 0.2) !important;
	}
	.variable-value-highlight{
		background: #D9822B;
		border: 0 !important;
		padding: 0 !important;
	}
	.tox-tinymce{
		border: 1px solid #CED9E0 !important;
	}
	.tox-toolbar__primary{
		background: #EBF1F5 !important;
	}
	.bp3-tab-indicator{
		background-color: #9F1C3A;
	}
	.bp3-tab:hover {
		color: #9F1C3A;
		font-weight: normal;
	}
	.bp3-tab[aria-selected="true"] {
		color: #9F1C3A;
	}
	.tox-toolbar__primary{
		background: #fff !important;
		border-bottom: 1px solid #CED9E0 !important;
	}
	.tox-editor-container .tox-toolbar__group{
		flex: 1;
		justify-content:space-between;
	}
	.tox-toolbar__group svg{
		fill: #30404D !important;
	}
	.tox .tox-tbtn:hover{
		background: rgba(222,224,226,0.38) !important;
	}
	.tox .tox-collection--toolbar .tox-collection__item--active{
		background: rgba(222,224,226,0.38) !important;
	}
	.tox .tox-tbtn:active{
		background: rgba(222,224,226,0.38) !important;	
	}
	.mce-content-body [contentEditable=false][data-mce-selected]{
		outline: none !important;
	}
	.ems-primary-button{
		border: 1px solid rgba(16, 22, 26, 0.2) !important;
		color: #fff !important;
		background-color: #9F1C3A !important;
		box-shadow: none !important;
		background-image: none !important;
	}
	.ems-primary-button:hover{
		border: 1px solid rgba(16, 22, 26, 0.2) !important;
		color: #fff !important;
		background-color: #9F1C3A !important;
		box-shadow: none !important;
		background-image: none !important;
	}
	.bp3-control.bp3-checkbox input:checked~.bp3-control-indicator:before {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E")
	}	
`;
export default GlobalStyle;
