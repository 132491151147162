import React, { useEffect } from 'react';
import { CardContainer, Button, FeaturedIcon } from 'componentsV2';
import './css/PracticeAlarm.scss';
import { ALARM_MODES, STEP_ONE } from '../../consts';
export const PracticeAlarm = ({
	setCalloutData,
	onChangeHandler,
	onChangeModalData,
	onCancelModalHandler,
}) => {
	useEffect(() => {
		onChangeModalData({
			showCancelAction: false,
			secondBtnTitle: 'Cancel',
			onGoBackHandler: onCancelModalHandler,
		});
	}, []);

	const showActivateModalHandler = mode => {
		const icon = (
			<FeaturedIcon icon={'error'} type={'Primary'} size={'md'} shape={'circleIcon'} />
		);
		onChangeHandler({
			hasCallout: true,
		});
		setCalloutData({
			text: ` This is a ${mode} alarm.`,
			icon: icon,
			type: 'primary',
		});
		onChangeModalData({
			activeStep: STEP_ONE,
			mode: mode,
			modalSubTitle: '',
		});
	};

	return (
		<div className="Practive_Alarm_CardContainer">
			<CardContainer
				key={1}
				title="Activate drill alarm"
				subtitle="Will send notifications to staff, but with a note that this is a drill."
			>
				<Button
					text="Activate"
					type="ghostPrimary"
					onClick={() => {
						showActivateModalHandler(ALARM_MODES.drill);
					}}
					rightIcon="arrow-right"
					icon={null}
					className="Activate_Button"
				/>
			</CardContainer>
			<br />
			<CardContainer
				key={2}
				title="Activate training alarm"
				subtitle="Will send no notifications to staff."
			>
				<Button
					text="Activate"
					type="ghostPrimary"
					onClick={() => {
						showActivateModalHandler(ALARM_MODES.training);
					}}
					rightIcon="arrow-right"
					icon={null}
					className="Activate_Button"
				/>
			</CardContainer>
		</div>
	);
};
