import React from 'react';
import { fileIconsList } from 'componentsV2';
import { ShapeContentItem, NoShapeContentState } from './';
import '../css/ShapeContent.scss';

export const NotesContent = ({
	notes,
	contentType,
	shapeType,
	shapeId,
	siteId,
	editPermission,
	floorplanArchived,
	isSearchedContent,
	selectedShape,
	loadContentData,
	isEditMode,
	viewPermissionOnSiteId,
	floorplan,
}) => {
	return (
		<div className="area-content-items-container">
			{notes.length > 0 ? (
				notes.map(note => (
					<ShapeContentItem
						item={note}
						id={note.id}
						ext={'note'}
						thumbnail={null}
						contentTitle={note.contentTitle}
						createdBy={note.createdBy}
						editPermission={editPermission}
						floorplanArchived={floorplanArchived}
						createdDate={note.createdDate}
						contentType={contentType}
						selectedShape={selectedShape}
						siteId={siteId}
						shapeId={shapeId}
						shapeType={shapeType}
						loadContentData={loadContentData}
						isEditMode={isEditMode}
						viewPermissionOnSiteId={viewPermissionOnSiteId}
						floorplan={floorplan}
					/>
				))
			) : (
				<NoShapeContentState
					icon={fileIconsList['note']}
					header={isSearchedContent ? 'No content found' : 'No notes currently added.'}
					description={
						isSearchedContent
							? `Your search didn’t match any ${contentType}. Please try again.`
							: editPermission && !floorplanArchived && isEditMode
							? 'Click the + button to add a note.'
							: ''
					}
				/>
			)}
		</div>
	);
};
