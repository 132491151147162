import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DatePicker } from '@blueprintjs/datetime';
import { Icon } from '@blueprintjs/core';

import { formatDate } from 'utilities/dates';
import { Input } from '../Input';
import { Button } from '../Button';

const Wrapper = styled.div`
	min-width: 340px;
	input {
		height: 42px;
	}

	p {
		color: #10161a;
		font-size: 16px;
		line-height: 22px;
		font-style: normal;
		margin-bottom: 4px;
		font-weight: normal;
		font-family: Nunito Sans;
	}

	.picker-wrapper {
		z-index: 100;
		position: absolute;

		.bp3-timepicker {
			margin-left: -55px;
		}

		.done-button {
			right: 10px;
			bottom: 31px;
			position: absolute;
		}
	}
`;

const DatePickerStyled = styled(DatePicker)`
	&&& {
		font-family: Nunito Sans;

		.DayPicker-Day--selected {
			background-color: #9f1c3a;
			color: white;
		}
		.DayPicker-Day:hover {
			background-color: #faf2f3;
		}
	}
`;

// This height needs to be equals to input height value
const IconContainer = styled.div`
	height: 42px;
`;

// Rest of the date picker props at: https://blueprintjs.com/docs/#datetime/datepicker
// Timer picker props at: https://blueprintjs.com/docs/#datetime/timepicker
// @blueprint consolidated the DatePicker and TimePicker components into one component
// codebase: https://github.com/palantir/blueprint/blob/develop/packages/datetime/src/datePicker.tsx
const DateTimePicker = ({
	title,
	isRequiredAsterisk = null,
	initialValue = null,
	label = null,
	timePrecision = null,
	placeholder = timePrecision
		? 'Select a date and a time...'
		: 'Select a date...',
	onDateChangeHandler = null,
	disabled = false,
	highlightCurrentDay = true,
	datePickerProps = {},
	timePickerProps = {
		useAmPm: true,
		showArrowButtons: true,
	},
	// Set this to true if you are using the picker within a table row
	inputNoMarginBottom,
	error,
	errorMessage,
}) => {
	if (typeof onDateChangeHandler !== 'function') {
		throw Error(
			"The 'onDateChangeHandler' prop/handler needs to be defined as a function",
		);
	}

	const [showPicker, setShowPicker] = useState(false);
	const [selectedDate, setSelectedDate] = useState(initialValue);
	const containerRef = useRef();
	
	// Effect to hide calendar if clicked outside
	useEffect(() => {
		function handleClickOutside(event) {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setShowPicker(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [containerRef]);

	const submitDate = date => {
		setShowPicker(false);
		onDateChangeHandler(date);
	};

	const updateDate = (date, isUserChange) => {
		if (isUserChange) {
			setSelectedDate(date);
			if (!timePrecision) {
				submitDate(date);
			}
		}
	};

	const togglePickerVisibility = () => setShowPicker(!showPicker);

	return (
		<Wrapper className="date-time-picker">
			<Input
				isRequiredAsterisk={isRequiredAsterisk}
				noMarginBottom={inputNoMarginBottom}
				title={title}
				readOnly
				label={label}
				placeholder={placeholder}
				disabled={showPicker || disabled}
				value={selectedDate ? formatDate(selectedDate) : null}
				onFocus={() => setShowPicker(true)}
				error={error}
				errorMessage={errorMessage}
				rightelement={
					<IconContainer
						className="d-flex justify-content-center align-items-center mr-2"
						onClick={togglePickerVisibility}
					>
						<Icon icon="calendar" />
					</IconContainer>
				}
			/>
			{showPicker && (
				<div className="picker-wrapper" ref={containerRef}>
					<DatePickerStyled
						value={selectedDate}
						className="picker"
						disabled
						onChange={(date, isUserChange) => {
							updateDate(date, isUserChange);
						}}
						timePrecision={timePrecision}
						highlightCurrentDay={highlightCurrentDay}
						{...datePickerProps}
						timePickerProps={
							timePrecision ? timePickerProps : undefined
						}
					/>
					{timePrecision && (
						<Button
							className="done-button"
							intent="primary"
							text="Done"
							onClick={() => submitDate(selectedDate)}
						/>
					)}
				</div>
			)}
		</Wrapper>
	);
};

DateTimePicker.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	initialValue: PropTypes.any,
	placeholder: PropTypes.string,
	onDateChangeHandler: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	timePrecision: PropTypes.oneOf([
		undefined,
		'minute',
		'second',
		'millisecond',
	]),
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	highlightCurrentDay: PropTypes.bool,
	showArrowButtons: PropTypes.bool,
	datePickerProps: PropTypes.object,
	timePickerProps: PropTypes.object,
	inputNoMarginBottom: PropTypes.bool,
	isRequiredAsterisk: PropTypes.bool,
};

export { DateTimePicker };
