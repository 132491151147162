import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Button';
import { DistrictsService } from 'services/DistrictsService';
import { Menu, MenuItem } from '@blueprintjs/core';
import { SearchBar } from 'components/SearchBar';
import './index.css';
import { logoNavigate } from 'assets/icons';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { createBrowserHistory } from 'history';
import { UserService } from 'services/UserService';

const DistrictSwitcher = ({ props }) => {
	const [districtsList, setDistrictsList] = useState([]);
	const [filteredDistrictList, setFilteredDistrictList] = useState([]);
	const [selectedDistrict, setSelectedDistrict] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const districtToken = localStorage.getItem('districtToken');
	const dispatch = useDispatch();
	const history = createBrowserHistory();
	const events = ['click', 'select', 'scroll', 'keyPress'];
	const startTimerInterval = useRef();

	const resetTimer = () => {
		clearTimeout(startTimerInterval.current);
		timeChecker();
	};

	const timeChecker = () => {
		// setting a 30 min timer
		startTimerInterval.current = setTimeout(() => {
			dispatch({
				type: 'SET_DISTRICT_TOKEN',
				payload: '',
			});
		}, 30 * 60 * 1000);
	};

	useEffect(() => {
		DistrictsService.getAll(districtToken)
			.then(data => {
				setDistrictsList(data);
				setFilteredDistrictList(data);
				setLoading(false);
			})
			.catch(err => {
				setLoading(false);
				setError(err.error.description);
			});
		events.forEach(event => {
			window.addEventListener(event, resetTimer);
		});
		timeChecker();
		return () => {
			events.forEach(event => {
				window.removeEventListener(event, resetTimer);
			});
		};
	}, []);

	const onSearchDistrictHandler = search => {
		setFilteredDistrictList(
			districtsList.filter(
				district =>
					district.name
						?.toLowerCase()
						.includes(search.toLowerCase()) ||
					district.id?.toLowerCase().includes(search.toLowerCase()),
			),
		);
	};

	const submitDistrict = () => {
		setLoading(true);
		DistrictsService.selectDistrict(selectedDistrict.id, districtToken)
			.then(resp => {
				if (
					(resp.statusCode && resp.statusCode === 200) ||
					(resp.statusCode && resp.statusCode === 300)
				) {
					if (resp.data.accessToken) {
						const { accessToken } = resp.data;
						UserService.fetchMyInfo(accessToken).then(resp => {
							if (resp.statusCode === 200) {
								dispatch({
									type: 'SET_USER',
									payload: accessToken,
								});
								dispatch({
									type: 'SET_CURRENT_DISTRICT',
									payload: selectedDistrict,
								});
								dispatch({
									type: 'SET_USER_DATA',
									payload: resp.data,
								});
								window.location.href = '/';
								return history.push('/');
							}
						});
					}
				} else {
					setLoading(false);
					setError(resp.error.description);
				}
			})
			.catch(err => {
				setLoading(false);
				setError(err.error.description);
			});
	};

	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && (
				<div className="container-sm district-switcher-wrapper">
					<div className="row text-center">
						<div className="col-md-12">
							<img
								src={logoNavigate}
								alt="logo navigate"
								className="logo-navigate"
							/>
							{error && <h5 className="text-danger">{error}</h5>}
							<div className="district-switcher-box">
								<div className="district-switcher-search-bar">
									{districtsList.length > 0 && (
										<SearchBar
											placeholder="Search district..."
											onSearch={onSearchDistrictHandler}
										/>
									)}
									<Menu
										style={{
											marginTop: 10,
											marginBottom: 5,
										}}
										large
										className="district-menu-items custom-scroll"
									>
										{filteredDistrictList.map(item => (
											<MenuItem
												key={item.id}
												text={`${item.id} - ${
													item.name
												}`}
												onClick={() =>
													setSelectedDistrict(item)
												}
												active={
													selectedDistrict === item
												}
											/>
										))}
									</Menu>
								</div>
								<Button
									text="Proceed"
									large
									className="w-100"
									intent="primary"
									disabled={selectedDistrict === null}
									onClick={submitDistrict}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

DistrictSwitcher.propTypes = {
	error: PropTypes.string,
};

export default DistrictSwitcher;
