import React, { useContext, useEffect, useState } from 'react';
import { FormModal, Input, ToasterContext, Intent, Dropdown, Button } from 'componentsV2';
import './css/ExternalContactModal.scss';
import { AllowOnlyNumeric, isNotEmpty, isValidEmail, isValidPhone } from 'utilities/validations';
import { userAvatarMay24PNG } from 'assets/icons';
import { SiteDropdownSingleSelect } from 'components/SiteDropdownSingleSelect';
import { CallListService } from 'services/CallListService';
import { ContactService } from 'services/ContactService';
import { ErrorTextWrapper } from 'components/Forms/shared.js';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import { Helper } from 'utilities/helper';
import {
	PHONE_NUMBER_MASK,
	PHONE_NUMBER_PLACEHOLDER,
	PHONE_TYPES,
	SORTING_DIR,
} from 'utilities/constants';
import ImageUploadModal from '../../Components/ImageUploadModal';
import { generateAvatar } from 'pages/App/AvatarInitials';
import { ContactsContext } from '../../Providers';

export const ExternalContactModal = props => {
	const { setReloadContacts } = useContext(ContactsContext);
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);
	const [readOnly, setReadOnly] = useState(false);
	const [avatar, setAvatar] = useState(userAvatarMay24PNG);
	const [avatarInitials, setAvatarInitials] = useState(null);
	const [isDefault, setIsDefault] = useState(true);
	const [sites, setSites] = useState([]);
	const [selectedSite, setSelectedSite] = useState({});
	const [siteCallList, setSiteCallList] = useState([]);
	const [selectedLists, setSelectedLists] = useState([]);
	const [defaultCallLists, setDefaultCallLists] = useState([]);
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	const [title, setTitle] = useState('');
	const permissions = useSelector(state => state.route.permissions);
	const [modalToShow, setModalToShow] = useState(false);
	const [confirmBtnTitle, setConfirmBtnTitle] = useState('Save');

	const [formData, setFormData] = useState({
		first_name: null,
		last_name: null,
		staff_title: null,
		email: null,
		mobile_phone: null,
		work_phone: null,
		work_phone_ext: null,
		home_phone: null,
		call_list_ids: [],
		image: null,
		image_type: null,
	});

	const [validationErrors, setValidationErrors] = useState({
		first_name: { hasError: false, errorMsg: '' },
		last_name: { hasError: false, errorMsg: '' },
		staff_title: { hasError: false, errorMsg: '' },
		email: { hasError: false, errorMsg: '' },
		mobile_phone: { hasError: false, errorMsg: '' },
		work_phone: { hasError: false, errorMsg: '' },
		work_phone_ext: { hasError: false, errorMsg: '' },
		home_phone: { hasError: false, errorMsg: '' },
		call_list_ids: { hasError: false, errorMsg: '' },
		phones: { hasError: false, errorMsg: '' },
	});

	const confirmBtnDisabled =
		Object.keys(validationErrors).length != 0 ||
		!isNotEmpty(formData.first_name) ||
		!isNotEmpty(formData.last_name) ||
		!isNotEmpty(formData.email);

	useEffect(() => {
		if (props.mode == 'add') {
			setTitle('Add external contact');
			fetchCallLists(props.selectedSites[0], props.selectedCallList, 0);
			setConfirmBtnTitle('Create');
		}

		if (props.mode == 'view') {
			setReadOnly(true);
			setTitle('View external contact');
			fetchContact(props.contactID);
		}

		if (props.mode == 'edit') {
			setTitle('Edit external contact');
			fetchContact(props.contactID);
		}

		if (props.selectedSites) {
			setSelectedSite(props.selectedSites[0]);
		}
	}, [props]);

	useEffect(() => {
		validateInputs();
	}, [formData]);

	const fetchContact = contactId => {
		setLoading(true);

		ContactService.getById(contactId)
			.then(response => {
				let mobile_phone = null;
				let work_phone = null;
				let work_phone_ext = null;
				let home_phone = null;

				if (response.data.person.phones.length > 0) {
					response.data.person.phones.forEach(function(item) {
						switch (item.type) {
							case PHONE_TYPES.WORK:
								work_phone = item.phoneNumber;
								work_phone_ext = item.extension;
								break;
							case PHONE_TYPES.HOME:
								home_phone = item.phoneNumber;
								break;
							case PHONE_TYPES.MOBILE:
								mobile_phone = item.phoneNumber;
								break;
							default:
								break;
						}
					});
				}

				let siteCallList = [];
				let callListIds = [];

				response.data.contactCallLists.forEach(function(item) {
					let isDisabled = false;

					if (props.mode == 'view') {
						isDisabled = true;
					} else if (props.mode == 'edit') {
						isDisabled = !checkPermission(
							'call_list_edit',
							permissions,
							item.callList.site_id,
						);
					}

					siteCallList.push({
						sites: sites,
						selectedSite: {
							label: item.callList.site,
							value: item.callList.site_id,
							id: item.callList.site_id,
						},
						callLists: [
							{
								label: item.callList.title,
								value: item.callList.id,
								site_id: item.callList.site_id,
							},
						],
						selectedCallList: {
							label: item.callList.title,
							value: item.callList.id,
							site_id: item.callList.site_id,
						},
						disabled: isDisabled,
					});

					callListIds.push(item.callList.id);
				});

				setSiteCallList(siteCallList);

				setFormData({
					...formData,
					first_name: response.data.person.name.givenName,
					last_name: response.data.person.name.familyName,
					staff_title:
						response.data.person.name.title != ''
							? response.data.person.name.title
							: null,
					email: response.data.person.email.emailAddress,
					mobile_phone: mobile_phone,
					home_phone: home_phone,
					work_phone: work_phone,
					work_phone_ext: work_phone_ext,
					call_list_ids: callListIds,
				});

				let contactName =
					response.data.person.name.givenName.charAt(0) +
					response.data.person.name.familyName.charAt(0);
				let generatedAvatar = generateAvatar(contactName);
				setAvatarInitials(generatedAvatar);

				if (response.data.personPhotoUrl) {
					setAvatar(response.data.personPhotoUrl);
					setIsDefault(false);
				} else {
					setAvatar(generatedAvatar);
				}

				setLoading(false);
			})
			.catch(error => {
				toaster(`Something went wrong`, Intent.DANGER);
				setLoading(false);
				props.onCloseModal();
			});
	};

	const fetchCallLists = (site, selectedCallList, index) => {
		return CallListService.getAll({
			selectedBuilders: [site.id],
			sortKey: 'title',
			sortDir: SORTING_DIR.ASC,
			page: 1,
			perPage: 1000,
		}).then(resp => {
			if (resp.statusCode === 200) {
				var lists = [];

				resp.data.map(item => {
					lists.push({
						label: item.title,
						value: item.id,
					});
				});

				if (site.id == props.selectedSites[0].id) {
					setDefaultCallLists(lists);
				}

				var newSistesCallList = [...siteCallList];
				newSistesCallList[index] = {
					sites: sites,
					selectedSite: site,
					callLists: lists,
					selectedCallList: selectedCallList,
					disabled: readOnly,
				};
				setSiteCallList(newSistesCallList);
				updateCallLists(newSistesCallList);
			}
		});
	};

	const onConfirmActionHandler = () => {
		validateInputs();

		setLoading(true);

		for (var inputKey in formData) {
			if (
				(inputKey == 'mobile_phone' ||
					inputKey == 'work_phone' ||
					inputKey == 'work_phone_ext' ||
					inputKey == 'home_phone') &&
				formData[inputKey] != null &&
				formData[inputKey].trim() == ''
			) {
				formData[inputKey] = null;
			}
		}

		if (props.mode == 'add') {
			ContactService.addContact(formData)
				.then(response => {
					toaster('You have successfully added a new contact.', Intent.SUCCESS);
					props.onCloseModal();
					setReloadContacts(value => !value);
					setLoading(false);
				})
				.catch(error => {
					handleApiErrorResponse(error);
					setLoading(false);
				});
		} else if (props.mode == 'edit') {
			ContactService.updateContact(props.contactID, formData)
				.then(response => {
					toaster('Contact successfully updated.', Intent.SUCCESS);
					props.onCloseModal();
					setReloadContacts(value => !value);
					setLoading(false);
				})
				.catch(error => {
					handleApiErrorResponse(error);
					setLoading(false);
				});
		}
	};

	const validateInputs = () => {
		var errors = {};

		if (formData?.email?.length > 0 && !isValidEmail(formData.email)) {
			errors.email = { hasError: true, errorMsg: 'Email is not valid' };
		}

		if (!isValidPhone(formData.mobile_phone)) {
			errors.mobile_phone = { hasError: true, errorMsg: 'Mobile phone is not valid' };
		}

		if (!isValidPhone(formData.work_phone)) {
			errors.work_phone = { hasError: true, errorMsg: 'Work phone is not valid' };
		}

		if (!isValidPhone(formData.home_phone)) {
			errors.home_phone = { hasError: true, errorMsg: 'Home phone is not valid' };
		}

		if (
			formData.work_phone_ext &&
			formData.work_phone_ext.trim().length > 0 &&
			(!formData.work_phone || formData.work_phone.trim().length < 1)
		) {
			errors.work_phone = {
				hasError: true,
				errorMsg: 'Work phone is required when extension is filled.',
			};
		}

		if (!formData.mobile_phone && !formData.home_phone && !formData.work_phone) {
			errors.phones = {
				hasError: true,
				errorMsg: 'At least one phone number should be filled',
			};
		}

		if (formData.call_list_ids.length < 1) {
			errors.call_list_ids = {
				hasError: true,
				errorMsg: 'At least one Call list should be selected',
			};
		}

		setValidationErrors(errors);
	};

	const handleApiErrorResponse = error => {
		if (error.statusCode == 400) {
			var errors = validationErrors;

			for (var key in error.error.fields) {
				errors[key] = { hasError: true, errorMsg: error.error.fields[key] };
			}

			setValidationErrors({ ...validationErrors, ...errors });
		}
	};

	const handleInputChange = e => {
		let inputValue = e.target.value;

		if (
			e.target.name == 'mobile_phone' ||
			e.target.name == 'home_phone' ||
			e.target.name == 'work_phone'
		) {
			inputValue = Helper.formatPhoneToDigits(e.target.value);
		}

		setFormData({ ...formData, [e.target.name]: inputValue });

		let validations = {};

		let inputValidation = {
			[e.target.name]: { hasError: false, errorMsg: '' },
		};

		validations = { ...inputValidation };

		if (
			e.target.name == 'mobile_phone' ||
			e.target.name == 'home_phone' ||
			e.target.name == 'work_phone'
		) {
			validations = { ...validations, ...{ phones: { hasError: false, errorMsg: '' } } };
		}

		setValidationErrors({ ...validationErrors, ...validations });
	};

	const handleImageSelect = (imageType, imageData) => {
		setAvatar(imageData);
		setIsDefault(false);
		setFormData({ ...formData, image_type: imageType, image: imageData });
		closeModal();
	};

	const removePhoto = () => {
		setAvatar(avatarInitials);
		setIsDefault(true);
		setFormData({ ...formData, image_type: null, image: null });
	};

	const selectedSiteHandler = (index, site) => {
		fetchCallLists(site, {}, index);
	};

	const selectedCallListHandler = (index, calllist) => {
		if (selectedLists.indexOf(calllist.value) !== -1) {
			return false;
		}

		let newSistesCallList = [...siteCallList];
		let itemRow = newSistesCallList[index];

		newSistesCallList[index] = {
			sites: sites,
			selectedSite: itemRow.selectedSite,
			callLists: itemRow.callLists,
			selectedCallList: calllist,
			disabled: readOnly,
		};

		setSiteCallList(newSistesCallList);

		updateCallLists(newSistesCallList);

		setValidationErrors({
			...validationErrors,
			call_list_ids: { hasError: false, errorMsg: '' },
		});
	};

	const updateCallLists = items => {
		let callListIds = [];

		items.map(item => {
			if (item.selectedCallList.value != undefined) {
				callListIds.push(item.selectedCallList.value);
			}
		});

		setFormData({ ...formData, call_list_ids: callListIds });
		setSelectedLists(callListIds);
	};

	const removeListRow = index => {
		if (siteCallList.length > 1) {
			var newSistesCallList = [...siteCallList];
			newSistesCallList.splice(index, 1);
			setSiteCallList(newSistesCallList);

			updateCallLists(newSistesCallList);
		}
	};

	const addListRow = () => {
		setSiteCallList([
			...siteCallList,
			{
				sites: sites,
				selectedSite: selectedSite,
				callLists: defaultCallLists,
				disabled: readOnly,
			},
		]);
	};

	const closeModal = () => {
		setModalToShow(false);
	};

	const userAvatarClick = () => {
		if (readOnly) {
			return false;
		} else {
			setModalToShow('ImageUploadModal');
		}
	};

	return (
		<>
			<FormModal
				isOpen={true}
				confirmBtn={{
					title: confirmBtnTitle,
					onClick: onConfirmActionHandler,
					disabled: confirmBtnDisabled || readOnly,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: () => props.onCloseModal(),
				}}
				title={title}
				loading={loading}
				size={'large'}
				customClassName="external-contact-modal"
			>
				<div className="external-content-form-container">
					<div className="user-avatar-container">
						<div
							className={'user-avatar ' + (readOnly ? 'disabled' : '')}
							style={{ backgroundImage: `url(${avatar})` }}
							onClick={userAvatarClick}
						/>
					</div>
					<div className="content-form-container">
						<div className="header-content">
							<p className="title">User Information</p>
						</div>
						<div className="user-info-container">
							<div className="user-info-group1">
								<Input
									placeholder="Enter first name"
									value={formData.first_name}
									error={validationErrors?.first_name?.hasError}
									errorMessage={validationErrors?.first_name?.errorMsg}
									label="First name"
									isRequired={true}
									labelState={true}
									onChange={handleInputChange}
									name="first_name"
									maxLength={30}
									readOnly={readOnly}
								/>
								<Input
									placeholder="Enter last name"
									value={formData.last_name}
									error={validationErrors?.last_name?.hasError}
									errorMessage={validationErrors?.last_name?.errorMsg}
									label="Last name"
									isRequired={true}
									labelState={true}
									onChange={handleInputChange}
									name="last_name"
									maxLength={30}
									readOnly={readOnly}
								/>
							</div>
							<div className="user-info-group2">
								<Input
									placeholder="Enter staff title"
									value={formData.staff_title}
									error={validationErrors?.staff_title?.hasError}
									errorMessage={validationErrors?.staff_title?.errorMsg}
									label="Staff title"
									isRequired={false}
									labelState={false}
									onChange={handleInputChange}
									name="staff_title"
									maxLength={30}
									readOnly={readOnly}
								/>
							</div>
						</div>

						<div className="br-section">
							<hr />
						</div>

						<div className="header-content">
							<p className="title">Contact Information</p>
						</div>
						<div className="contact-info-container">
							<div className="contact-info-group1">
								<Input
									placeholder="Enter email address"
									value={formData.email}
									error={validationErrors?.email?.hasError}
									errorMessage={validationErrors?.email?.errorMsg}
									label="Email"
									isRequired={true}
									labelState={true}
									onChange={handleInputChange}
									name="email"
									maxLength={50}
									readOnly={readOnly}
								/>
							</div>
							<div className="contact-info-group2">
								<Input
									placeholder={PHONE_NUMBER_PLACEHOLDER}
									mask={PHONE_NUMBER_MASK}
									value={formData.mobile_phone}
									error={validationErrors?.mobile_phone?.hasError}
									errorMessage={validationErrors?.mobile_phone?.errorMsg}
									label="Mobile phone"
									isRequired={false}
									labelState={false}
									onChange={handleInputChange}
									name="mobile_phone"
									readOnly={readOnly}
								/>
							</div>
							<div className="contact-info-group3">
								<Input
									placeholder={PHONE_NUMBER_PLACEHOLDER}
									mask={PHONE_NUMBER_MASK}
									value={formData.work_phone}
									error={validationErrors?.work_phone?.hasError}
									errorMessage={validationErrors?.work_phone?.errorMsg}
									label="Work phone"
									isRequired={false}
									labelState={false}
									onChange={handleInputChange}
									name="work_phone"
									readOnly={readOnly}
								/>
								<Input
									placeholder="12345"
									value={formData.work_phone_ext}
									error={validationErrors?.work_phone_ext?.hasError}
									errorMessage={validationErrors?.work_phone_ext?.errorMsg}
									label="Ext."
									isRequired={false}
									labelState={false}
									onChange={handleInputChange}
									name="work_phone_ext"
									maxLength={5}
									onKeyDown={AllowOnlyNumeric}
									readOnly={readOnly}
								/>
							</div>
							<div className="contact-info-group4">
								<Input
									placeholder={PHONE_NUMBER_PLACEHOLDER}
									mask={PHONE_NUMBER_MASK}
									value={formData.home_phone}
									error={validationErrors?.home_phone?.hasError}
									errorMessage={validationErrors?.home_phone?.errorMsg}
									label="Home phone"
									isRequired={false}
									labelState={false}
									onChange={handleInputChange}
									name="home_phone"
									readOnly={readOnly}
								/>
							</div>
							{validationErrors?.phones?.hasError && (
								<ErrorTextWrapper>
									{validationErrors.phones.errorMsg}
								</ErrorTextWrapper>
							)}
						</div>

						<div className="br-section">
							<hr />
						</div>
						<div className="header-content">
							<p className="title">Linked to (Call lists)</p>
						</div>
						{validationErrors?.call_list_ids?.hasError && (
							<div className="calllist-error">
								{' '}
								<ErrorTextWrapper>
									{validationErrors.call_list_ids.errorMsg}
								</ErrorTextWrapper>
							</div>
						)}
						<div className="linkedto-info-container">
							{siteCallList.map((element, index) => (
								<div className="row linkedto-row" key={index}>
									<div className="col-md-5">
										<SiteDropdownSingleSelect
											selectedSite={element.selectedSite}
											sitesList={element.sites}
											onChange={e => selectedSiteHandler(index, e)}
											disabled={element.disabled}
											permission="call_list_edit"
											labelState={false}
										/>
									</div>
									<div className="col-md-5">
										<Dropdown
											label="Call List"
											value={element.selectedCallList}
											onChange={e => selectedCallListHandler(index, e)}
											options={element.callLists}
											disabledOptions={selectedLists}
											disabled={element.disabled || element.callLists.length == 0}
											labelState={false}
										/>
									</div>
									<div className="col-md-2 linkedto-row-remove">
										{siteCallList.length > 1 && !element.disabled && (
											<Button
												minimal
												icon="remove"
												type="ghostDefault"
												size="sm"
												onClick={() => removeListRow(index)}
											/>
										)}
									</div>
								</div>
							))}
						</div>
						<div className="linkedto-rows-add">
							{!readOnly && (
								<Button
									icon="add"
									text="Add"
									type="ghostPrimary"
									intent="secondary"
									onClick={addListRow}
								/>
							)}
						</div>
					</div>
				</div>
			</FormModal>
			{!readOnly && showUnsavedChangesModal && (
				<UnsavedChangesModal
					onConfirmAction={() => onCloseModal()}
					onCancelAction={() => setShowUnsavedChangesModal(false)}
				/>
			)}

			{modalToShow === 'ImageUploadModal' && (
				<ImageUploadModal
					closeModal={closeModal}
					handleImageSelect={handleImageSelect}
					removePhoto={removePhoto}
					avatarImage={avatar}
					isDefault={isDefault}
				/>
			)}
		</>
	);
};
