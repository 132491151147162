import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SearchBar, ContentSwitcher, DropdownMultiSelect } from 'componentsV2';
import {
	ROLL_CALL_TAB_MY_CLASSES,
	ROLL_CALL_TAB_STUDENTS,
	ROLL_CALL_TAB_STAFF,
	ROLL_CALL_TAB_VISITORS,
	ALARM_STUDENT_STATUS_OPTIONS,
	ALARM_STAFF_STATUS_OPTIONS,
	ALARM_VISITORS_STATUS_OPTIONS,
} from '../../../consts';
import { StatusFilterBar, StatusFilterMenu } from './Students/components';
import { RapidAlarmService } from 'services';

export const TableFilter = ({
	filterData,
	setFilterData,
	initialFiltrationData,
	contentToShow,
	setContentToShow,
	disableFilter,
	setDisableFilter,
	alarm,
}) => {
	const [rollCallFilterTabs, setRollCallFilterTabs] = useState([]);
	const [statusOptions, setStatusOptions] = useState([]);
	const [gradesList, setGradesList] = useState([]);
	const [studentsTabActive, setStudentsTabActive] = useState(false);

	useEffect(() => {
		if (studentsTabActive) {
			//get grades list if i'm on students list tab
			RapidAlarmService.getStudentsGrades()
				.then(response => {
					if (response.statusCode === 200) {
						const gradesListValues = response.data.map(gradeItem => ({
							label: gradeItem.grade,
							value: gradeItem.grade,
						}));
						setGradesList(gradesListValues);
					}
				})
				.catch(err => {
					//
				});
		}
	}, [studentsTabActive]);

	useEffect(() => {
		setDisableFilter(true);
		setStudentsTabActive(contentToShow === ROLL_CALL_TAB_STUDENTS);
		setStatusOptionsByTab();
	}, [contentToShow]);

	useEffect(() => {
		let tabs = [];
		if (alarm) {
			if (alarm.hasEnabledSIS) {
				tabs.push(
					{
						id: ROLL_CALL_TAB_MY_CLASSES,
						label: 'My classes',
					},
					{
						id: ROLL_CALL_TAB_STUDENTS,
						label: 'Students',
					},
				);
			}
			tabs.push({
				id: ROLL_CALL_TAB_STAFF,
				label: 'Staff',
			});
			if (alarm.vmAccess) {
				tabs.push({
					id: ROLL_CALL_TAB_VISITORS,
					label: 'Visitors',
				});
			}
			setRollCallFilterTabs(tabs);
		}
	}, []);

	const setStatusOptionsByTab = () => {
		const options =
			contentToShow === ROLL_CALL_TAB_STAFF
				? ALARM_STAFF_STATUS_OPTIONS
				: contentToShow === ROLL_CALL_TAB_VISITORS
				? ALARM_VISITORS_STATUS_OPTIONS
				: ALARM_STUDENT_STATUS_OPTIONS;
		const statusKeys = Object.keys(options);
		let statusList = [];
		for (let i = 0; i < statusKeys.length; i++) {
			statusList.push({
				label: options[statusKeys[i]],
				value: statusKeys[i],
			});
		}
		setStatusOptions(statusList);
	};

	const onSearchHandler = name => {
		setFilterData(oldData => ({
			...oldData,
			name,
			page: 1,
		}));
	};

	const onChangeStatusHandler = statusList => {
		setFilterData(oldData => ({
			...oldData,
			statusSelected: statusList,
			page: 1,
		}));
	};

	const switchContentHandler = tab => {
		if (contentToShow === tab[0]) {
			return false;
		}
		setContentToShow(tab[0]);
		// reset filteration data when navigating between tabs
		let sortKey =
			tab[0] == ROLL_CALL_TAB_STAFF
				? 'staffName'
				: tab[0] == ROLL_CALL_TAB_VISITORS
				? 'visitorName'
				: 'studentName';
		setFilterData({
			...initialFiltrationData,
			sortKey,
		});
	};

	return (
		<section className="roll-call-table-filter">
			<div className="left-side">
				<ContentSwitcher
					onClick={switchContentHandler}
					tabs={rollCallFilterTabs}
					multi={false}
					defaultActiveTabs={
						!alarm?.hasEnabledSIS ? [ROLL_CALL_TAB_STAFF] : [ROLL_CALL_TAB_MY_CLASSES]
					}
					preserveTab={true}
				/>
			</div>
			<div className="right-side">
				<SearchBar
					placeholder={
						contentToShow === ROLL_CALL_TAB_STAFF
							? 'Search by staff name...'
							: contentToShow === ROLL_CALL_TAB_VISITORS
							? 'Search by visitor name...'
							: 'Search by student name...'
					}
					icon="search"
					className="search-bar-text"
					value={filterData.name}
					onSearch={onSearchHandler}
					style={{
						width: 400,
					}}
					disabled={disableFilter}
				/>

				{!studentsTabActive && statusOptions.length > 0 && (
					<DropdownMultiSelect
						label={null}
						values={filterData.statusSelected}
						options={statusOptions}
						allowSelectAll={false}
						placeholder="Filter by status"
						className="status-mddl"
						visibleOptions={2}
						onChange={onChangeStatusHandler}
						width="400px"
						disabled={disableFilter}
					/>
				)}
				{studentsTabActive && (
					<StatusFilterMenu
						filterationData={filterData}
						setFilterationData={setFilterData}
						statusOptions={statusOptions}
						gradesList={gradesList}
					/>
				)}
			</div>
			<div className="w-100">
				{studentsTabActive && (
					<StatusFilterBar
						filterationData={filterData}
						setFilterationData={setFilterData}
						statusOptions={statusOptions}
					/>
				)}
			</div>
		</section>
	);
};

TableFilter.propTypes = {
	filterData: PropTypes.object,
	setFilterData: PropTypes.func.isRequired,
};
