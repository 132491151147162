import React from 'react';
import { PageHeader } from 'components/PageHeader';
import { Tab } from '@blueprintjs/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CustomTabs } from 'componentsV2';
import { checkPermission } from 'utilities/permissions';
import { MainInfo } from './AdminTabs/MainInfo/MainInfo';
import { Statistics } from './AdminTabs/Statistics/Statistics';
import selectRoute from 'pages/App/selectors';

const TABS = [
	{
		title: 'Main info',
		panel: <MainInfo />,
		index: 0,
	},
	{
		title: 'Statistics',
		panel: <Statistics />,
		index: 1,
	},
];
class Administration extends React.Component {
	state = {};

	constructor() {
		super();
	}

	componentDidMount(nextProps, nextState) {
		const {
			route: {  permissions },
			history
		} = this.props;
		const canViewSiteInfo = checkPermission('site_info_view', permissions);
		if(!canViewSiteInfo) {
			history.push('/');
		}
	}

	render() {
		return (
			<div className="container-fluid">
				<PageHeader title="Site Information" />
				<CustomTabs>
					{TABS.map(tab => (
						<Tab
							style={{
								fontFamily: 'Nunito Sans',
								fontWeight: 'bold',
							}}
							id={tab.index}
							key={tab.index}
							title={tab.title}
							disabled={tab.disabled}
							panel={tab.panel}
						/>
					))}
				</CustomTabs>
			</div>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(Administration);
