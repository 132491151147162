import React, { useState } from 'react';
import { Dropdown } from "components/Dropdown";
import { Radio, Icon } from '@blueprintjs/core';
import { RadioButtonGroup } from './';

export const RadioButtonPattern = () => {

  const [selectedValue, setSelectedValue] = useState("2");

  // State of the options
  const [optionSize, setOptionSize] = useState(null);
  const [optionBorder, setOptionBorder] = useState(null);
  const [optionLayout, setOptionLayout] = useState(null);
  const [optionShowLabel, setOptionShowLabel] = useState(null);
  const [optionIcon, setOptionIcon] = useState(null);
  const [optionRequired, setOptionRequired] = useState(null);

  /** Handle Options */

  const handleChangeSize = item => {
    setOptionSize(item);
  }
  const handleChangeBorder = item => {
    setOptionBorder(item);
  }
  const handleChangeLayout = item => {
    setOptionLayout(item);
  }
  const handleChangeShowLabel = item => {
    setOptionShowLabel(item);
  }
  const handleChangeIcon = item => {
    setOptionIcon(item);
  }
  const handleChangeRequired = item => {
    setOptionRequired(item);
  }

  const customLabel = (
    <div className="checkbox-icon">
      <div>
        Radio Button option 1
        <p>This is a supporting text</p>
      </div>
      {optionIcon && optionIcon.value.length > 0 && (
        <Icon icon={optionIcon.value} className="check-icon" />
      )}
    </div>
  );

  const customOptionWithIcon = (
    <div className="checkbox-icon">
      <div>
        Radio Button with fixed icon
      </div>
      <Icon icon="home" className="check-icon" />
    </div>
  );

  const handleChange = ({ target }) => {
    setSelectedValue(target.value);
    console.log(target.value);
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-3">
          <div className="card">
            <div className="card-body">
              <h4 className='mb-2'>Radio button Options</h4>

              <div className="row">
                <div className="col-md-4">

                  <Dropdown
                    className="mt-3"
                    label="Size (all)"
                    placeholder="Select size"
                    value={optionSize}
                    options={[
                      {
                        value: "small",
                        label: "Small"
                      },
                      {
                        value: "medium",
                        label: "Medium"
                      }
                    ]}
                    onChange={handleChangeSize}
                  />

                  <div className="mt-3"></div>
                  <Dropdown
                    label="Show Border (all)"
                    placeholder="Select an option"
                    value={optionBorder}
                    options={[
                      {
                        value: true,
                        label: "Yes"
                      },
                      {
                        value: false,
                        label: "No"
                      }
                    ]}
                    onChange={handleChangeBorder}
                  />
                </div>

                <div className="col-md-4">
                  <Dropdown
                    label="Layout (all)"
                    placeholder="Select an option"
                    value={optionLayout}
                    options={[
                      {
                        value: "block",
                        label: "Block"
                      },
                      {
                        value: "inline",
                        label: "Inline"
                      }
                    ]}
                    onChange={handleChangeLayout}
                  />

                  <div className="mt-3"></div>
                  <Dropdown
                    label="Show Label (all)"
                    placeholder="Select an option"
                    value={optionShowLabel}
                    options={[
                      {
                        value: true,
                        label: "Yes"
                      },
                      {
                        value: false,
                        label: "No"
                      }
                    ]}
                    onChange={handleChangeShowLabel}
                  />

                </div>

                <div className="col-md-4">
                  <Dropdown
                    label="Required (all)"
                    placeholder="Select an option"
                    value={optionRequired}
                    options={[
                      {
                        value: true,
                        label: "Yes"
                      },
                      {
                        value: false,
                        label: "No"
                      }
                    ]}
                    onChange={handleChangeRequired}
                  />

                  <div className="mt-3"></div>
                  <Dropdown
                    label="Icon (only option 1)"
                    placeholder="Select an option"
                    value={optionIcon}
                    options={[
                      {
                        value: "",
                        label: "Hide Icon"
                      },
                      {
                        value: "circle",
                        label: "Circle"
                      },
                      {
                        value: "warning-sign",
                        label: "warning-sign"
                      },
                      {
                        value: "help",
                        label: "help"
                      },
                      {
                        value: "tick-circle",
                        label: "tick-circle"
                      },
                      {
                        value: "lightbulb",
                        label: "lightbulb"
                      }
                    ]}
                    onChange={handleChangeIcon}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">

          <div className="card">
            <div className="card-body">

              <RadioButtonGroup
                label={optionShowLabel && optionShowLabel.value ? "Radio button group title" : ""}
                onChange={handleChange}
                selectedValue={selectedValue}
                size={optionSize && optionSize.value} // small | medium
                bordered={optionBorder && optionBorder.value}
                required={optionRequired && optionRequired.value}
                layout={optionLayout && optionLayout.value} // block | inline
              >
                <Radio label={customLabel} value="1" />
                <Radio label="Radio Button Disabled" disabled value="2" />
                <Radio label={customOptionWithIcon} value="3" />
                <Radio value="4" className='no-content' />
              </RadioButtonGroup>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}