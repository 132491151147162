import React, { useEffect } from 'react';
import { usePastAlarms } from 'hooks';
import { PAST_ALARMS_TO_SHOW_IN_HOME, PastAlarmsTable } from '../../RapidAlarms';

export const PastAlarmList = ({ pastAlarmListLoaded, setPastAlarmListLoaded, history }) => {
	const { filterationData, loading, pastAlarmList } = usePastAlarms(
		PAST_ALARMS_TO_SHOW_IN_HOME,
		!!pastAlarmListLoaded,
	);

	useEffect(() => {
		if (pastAlarmList.length > 0) {
			setPastAlarmListLoaded(pastAlarmList);
		}
	}, [pastAlarmList]);

	return (
		<div className="past-alams-table">
			<PastAlarmsTable
				list={pastAlarmListLoaded ? pastAlarmListLoaded : pastAlarmList}
				loading={pastAlarmListLoaded ? false : loading}
				totalRows={PAST_ALARMS_TO_SHOW_IN_HOME}
				filterationData={filterationData}
				history={history}
				showPagination={false}
				enableSorting={false}
				showHeader={true}
			/>
		</div>
	);
};
