import React from 'react';
import './css/AddStudentsStep.scss';
import { StudentsFilters } from './';
import { myClasses, noResult } from 'componentsV2';
import { GuardianDetails, AddStudentHeader } from './Shared';
import { ReunificationStudentsEmptyState } from '../../../Students/components/ReunificationStudentsEmptyState';
import { useStudentStatus } from '../../../Students/hooks';

export const AddStudentsStep = ({
	formData,
	studentsResponse,
	onChangeModalDetails,
	filterationData,
	setFilterationData,
	totalStudents,
	selectedStudents,
	setCurrentSelectedStudent,
	currentSelectedStudent,
	isEmptyData,
	selectedGuardian,
	addAsNew,
}) => {
	const handleChange = target => {
		onChangeModalDetails({
			disabled: false,
		});
		let currentSelected = studentsResponse.find(item => item.id == target.id);
		setCurrentSelectedStudent(currentSelected);
	};

	const onScrollStudentsList = () => {
		let myStudentsList = document.getElementById('my-students-list');
		myStudentsList.addEventListener('scroll', debouncedScrollHandler());
	};

	const debouncedScrollHandler = _.debounce(async () => {
		let myStudentsList = document.getElementById('my-students-list');

		if (
			Math.round(myStudentsList.offsetHeight) + Math.round(myStudentsList.scrollTop) + 2 >=
			myStudentsList.scrollHeight
		) {
			if (studentsResponse.length < totalStudents) {
				const newData = {
					...filterationData,
					page: filterationData.page + 1,
				};
				setFilterationData(newData);
			}
		}
	}, 300);

	return (
		<div className="AddStudsents__Wrapper">
			<GuardianDetails
				formData={formData}
				selectedGuardian={selectedGuardian}
				addAsNew={addAsNew}
			/>
			{isEmptyData && (
				<ReunificationStudentsEmptyState
					header="No students added to Reunification"
					description={
						<>
							Once Reunification is activated, students can be transferred to this
							list from Roll Call or by using the "Add student to Reunification"
							button.
						</>
					}
					icon={myClasses}
					showCircles={false}
				/>
			)}
			{!isEmptyData && (
				<div className="AddStudents_Content">
					<AddStudentHeader
						title={`Add ${selectedStudents.length > 0 ? ' another' : ' a'} student`}
						subtitle="Search and select a student that is associated with this guardian."
					/>
					<StudentsFilters
						filterationData={filterationData}
						setFilterationData={setFilterationData}
					/>
					<br />
					{totalStudents === 0 ? (
						<ReunificationStudentsEmptyState
							header="No result found"
							description={
								<>
									Your search didn’t match any student.
									<br /> Please try again.
								</>
							}
							icon={noResult}
							showCircles={false}
						/>
					) : (
						<>
							<div className="AddStudsents__TableWrapper">
								<div
									className="AddStudentsContent_Wrapper nonRadioGroup"
									id="my-students-list"
									onScroll={onScrollStudentsList}
								>
									<div className="row">
										<div className="col-md-12">
											{studentsResponse.map((item, index) =>
												useStudentStatus(
													item.firstName +
														' ' +
														(!item.middleInitial ||
														item.middleInitial == null
															? ''
															: item.middleInitial?.substring(0, 1) +
															  '. ') +
														item.lastName,
													`Grade:  ${item.grade}`,
													item,
													() => handleChange(studentsResponse[index]),
													currentSelectedStudent,
													false,
													() => {},
													selectedStudents,
												),
											)}
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};
