import { HttpService } from 'networking/HttpService';

export const ReunificationService = {
	getStudentsList: async (alarmId, params = {}) => {
		let query = '';
		if (params.studentName) {
			query += `&studentName=${params.studentName}`;
		}
		if (params.statusSelected && params.statusSelected.length > 0) {
			params.statusSelected.forEach(status => {
				query += `&statuses[]=${status}`;
			});
		}
		if (params.grades && params.grades.length > 0) {
			params.grades.forEach(grade => {
				query += `&grades[]=${grade}`;
			});
		}
		if (params.checkedInOnly) {
			query += `&checkedInOnly=${params.checkedInOnly}`;
		}
		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/alarms/${alarmId}/reunification/students?${query}`,
		}).catch(err => {
			throw err;
		});
	},

	updateStudentStatus: async (alarmId, students) =>
		await HttpService({
			method: 'POST',
			route: `/alarms/${alarmId}/reunification/student-checkins`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				students,
			},
		}).catch(err => {
			throw err;
		}),

	reunifyStudentStatus: async (alarmId, body) =>
		await HttpService({
			method: 'PUT',
			route: `/alarms/reunification/${alarmId}/students/mark-as-reunified`,
			headers: {
				'Content-Type': 'application/json',
			},
			body,
		}).catch(err => {
			throw err;
		}),

	updateStudentComment: async (alarmId, body) =>
		await HttpService({
			method: 'PUT',
			route: `/alarms/reunification/${alarmId}/students/comment`,
			headers: {
				'Content-Type': 'application/json',
			},
			body,
		}).catch(err => {
			throw err;
		}),

	getGuardiansList: async (alarmId, params = {}) => {
		let query = '';
		if (params.includeExistingContacts) {
			query += `&includeExistingContacts=${params.includeExistingContacts}`;
		}

		if (params.guardianName) {
			query += `&searchKey=${params.guardianName}`;
		}
		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/alarms/${alarmId}/reunification/all-guardians?${query}`,
		}).catch(err => {
			throw err;
		});
	},
	getGuardianDetails: async (alarmId, guardianId) => {
		return await HttpService({
			method: 'GET',
			route: `/alarms/${alarmId}/reunification/guardians/${guardianId}`,
		}).catch(err => {
			throw err;
		});
	},

	getCheckInGuardians: async (alarmId, params = {}) => {
		let query = '';
		if (params.includeExistingContacts) {
			query += `&includeExistingContacts=${params.includeExistingContacts}`;
		}

		if (params.guardianName) {
			query += `&guardianName=${params.guardianName}`;
		}

		if (params.statuses && params.statuses.length > 0) {
			const statuses = params.statuses.map(status => status.value);

			statuses.forEach(status => {
				query += `&statuses[]=${status}`;
			});
		}

		if (params.page) {
			query += `&page=${params.page}`;
		}

		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}

		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}

		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/alarms/${alarmId}/reunification/guardians?${query}`,
		}).catch(err => {
			throw err;
		});
	},
	checkStudentIn: async (alarmId, body) =>
		await HttpService({
			method: 'POST',
			route: `/alarms/${alarmId}/reunification/student-checkins`,
			headers: {
				'Content-Type': 'application/json',
			},
			body,
		}).catch(err => {
			throw err;
		}),

	submitGuardianCheckin: async (alarmId, body) =>
		await HttpService({
			method: 'POST',
			route: `/alarms/${alarmId}/reunification/guardian-checkins`,
			headers: {
				'Content-Type': 'application/json',
			},
			body,
		}).catch(err => {
			throw err;
		}),

	updateGuardianDetails: async (alarmId, guardianId, body) => {
		return await HttpService({
			method: 'PUT',
			route: `/alarms/${alarmId}/reunification/guardian-checkins/${guardianId}`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},
	getStudentGuardians: async (alarmId, studentId) => {
		return await HttpService({
			method: 'GET',
			route: `/alarms/${alarmId}/reunification/students/${studentId}/guardians`,
		}).catch(err => {
			throw err;
		});
	},

	undoCheckinGuardian: async (alarmId, guardianId) => {
		return await HttpService({
			method: 'PUT',
			route: `/alarms/${alarmId}/reunification/guardians/${guardianId}/undo-checkin`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},
};
