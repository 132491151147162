import { useEffect, useState, useReducer } from 'react';
import { Intent } from 'componentsV2';
import { useSelector } from 'react-redux';
import { BuildingsService, FloorplanService } from 'services';
import { filterSitesPerPermissionCode } from 'utilities/permissions';
import { MAP_LAYERS } from '../../../consts';
import { drawFloorPlanImage } from '../../../Details/CanvasDrawFn.js';

export const CreateUpdateMapFn = (
	closeModal,
	toaster,
	setLoading,
	setModalToShow,
	modalToShow,
	isEdit,
	history,
	reloadList,
	loadMapDetails,
	floorpanDetails,
	defaultSelectedSites,
	selectedSites,
	setSelectedSites,
	toggleMapOption,
	AddedFromView,
	setFloorPlanDetails,
	layer,
	editPermissionOnSiteId,
	currentFloorplan,
) => {
	const [isDisabled, setIsDisabled] = useState(true);
	const [showProgressBar, setShowProgressBar] = useState(false);
	const [isVisibleToAll, setVisibleToall] = useState(false);
	const [selectedSite, setSelectedSite] = useState(
		defaultSelectedSites ? defaultSelectedSites[0] : null,
	);
	const [hasFileError, setHasError] = useState(false);
	const [validationErrors, setErrors] = useState({});

	const initialFormData = {
		id: null,
		title: null,
		selectedSite: defaultSelectedSites ? defaultSelectedSites[0] : null,
		map_image: '',
		visible_to_all: false,
		oldImage: '',
	};

	const formDataReducer = (formDetails, action) => {
		formDetails = action.payload;

		return {
			id: formDetails.id,
			title: formDetails.title,
			selectedSite: formDetails.selectedSite,
			map_image: formDetails.map_image,
			visible_to_all: formDetails.visible_to_all,
			oldImage: formDetails.oldImage,
		};
	};

	const [formData, dispatchFormData] = useReducer(formDataReducer, initialFormData);
	const permissions = useSelector(state => state.route.permissions);

	const closeAddUpdateModal = () => {
		dispatchFormData({ payload: initialFormData });
		setFloorPlanDetails(null);
		closeModal();
	};

	const createMap = (addFromView = false) => {
		setLoading(true);
		validate(formData);
		const params = {
			id: formData.id,
			title: formData.title,
			map_image: formData.map_image,
			visible_to_all: formData.visible_to_all,
			oldImage: formData.oldImage,
			site_id: selectedSite.value,
			lat: selectedSite?.lat,
			long: selectedSite?.long,
		};

		FloorplanService.createMap(params)
			.then(res => {
				if (AddedFromView) {
					loadMapDetails(res.data.id);
				} else {
					reloadList();
				}
				closeAddUpdateModal();
				if (res.statusCode === 201) {
					toaster(
						'You have successfully added the "' + formData.title + '" as a new map',
						Intent.SUCCESS,
						null,
						2000,
						false,
						'map-view-page',
					);
					if (!addFromView) {
						window.open(
							'/maps/view/' + selectedSite.value + '/' + res.data.id,
							'_blank',
						);
					}
				} else {
					toaster(
						res.error?.description,
						Intent.DANGER,
						null,
						2000,
						false,
						'map-view-page',
					);
				}
				setErrors([]);
				setIsDisabled(true);
			})
			.catch(res => {
				let errors = {};
				if (
					res.error?.description &&
					res.error.description == 'File type is not supported.'
				) {
					errors.map_image = res.error.description;
				} else {
					errors = res.error?.fields;
				}
				setErrors(errors);
				setIsDisabled(true);
				toaster(res.error?.description, Intent.DANGER, null, 2000, false, 'map-view-page');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMap = async () => {
		let errors = '';
		setLoading(true);
		validate(formData);

		const updateMap = {
			map_image: formData.map_image,
			title: formData.title,
			visible_to_all: formData.visible_to_all,
		};

		await FloorplanService.updateMap(floorpanDetails.id, updateMap)
			.then(async res => {
				if (res.statusCode === 200) {
					toaster(
						'Map successfully updated',
						Intent.SUCCESS,
						null,
						2000,
						false,
						'map-view-page',
					);
				} else {
					toaster(
						res.error?.description,
						Intent.DANGER,
						null,
						2000,
						false,
						'map-view-page',
					);
				}
				await loadMapDetails(floorpanDetails.id);

				if (currentFloorplan.id == floorpanDetails.id) {
					let FloorplanImg;
					let image = res.data.link;
					FloorplanImg = await drawFloorPlanImage(
						layer,
						image,
						floorpanDetails,
						editPermissionOnSiteId,
					);

					layer.add(FloorplanImg);
					layer.draw();
				}

				setErrors([]);
			})
			.catch(res => {
				if (
					res.error?.description &&
					res.error.description == 'File type is not supported.'
				) {
					errors.map_image = res.error.description;
				} else {
					errors = res.error?.fields;
				}
				setErrors(errors);
				setIsDisabled(true);
				toaster(res.error?.description, Intent.DANGER, null, 2000, false, 'map-view-page');
			})
			.finally(() => {
				if (!errors) {
					toggleMapOption(MAP_LAYERS);
					closeAddUpdateModal();
				}
				setLoading(false);
			});
	};

	const validate = updatedForm => {
		if (updatedForm.title && updatedForm.map_image && (updatedForm.selectedSite || isEdit)) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	};

	const deleteFile = deletedFile => {
		let updatedForm = { ...formData, map_image: '' };
		dispatchFormData({ payload: updatedForm });
		setIsDisabled(true);
	};

	const replaceAction = () => {
		let updatedForm = { ...formData, map_image: '' };
		dispatchFormData({ payload: updatedForm });
		setIsDisabled(true);
	};

	const updateFormData = (val, FieldType) => {
		let updatedForm = null;

		switch (FieldType) {
			case 'title':
				updatedForm = { ...formData, title: val.target.value };
				dispatchFormData({ payload: updatedForm });
				validate(updatedForm);
				break;

			case 'site':
				updatedForm = { ...formData, selectedSite: val };
				dispatchFormData({ payload: updatedForm });
				validate(updatedForm);
				setSelectedSite(val);
				break;

			case 'img':
				let result = '';
				setShowProgressBar(true);
				if (val.name) {
					const reader = new FileReader();
					reader.readAsDataURL(val);
					setTimeout(() => {
						setShowProgressBar(false);
					}, 2000);
					reader.onload = async f => {
						result = f.target;
						updatedForm = { ...formData, map_image: result?.result };
						dispatchFormData({ payload: updatedForm });
						validate(updatedForm);
					};
				} else {
					setIsDisabled(true);
				}
				break;

			case 'checkbox':
				updatedForm = { ...formData, visible_to_all: !isVisibleToAll };
				dispatchFormData({ payload: updatedForm });
				setVisibleToall(val);
				break;
		}
	};

	useEffect(() => {
		setErrors([]);
		let file = null;
		let formDetails = null;
		if (floorpanDetails) {
			setLoading(true);
			FloorplanService.getMapInfo(floorpanDetails.id).then(res => {
				file = {
					id: floorpanDetails.id,
					name: floorpanDetails.filename,
					size: res.data.base64?.length * (3 / 4) - 2,
					type: 'image/' + floorpanDetails.mimitype,
				};
				formDetails = {
					id: floorpanDetails.id,
					title: floorpanDetails.title,
					site_id: floorpanDetails.building?.id,
					oldImage: file,
					visible_to_all: floorpanDetails.isVisibleToAll,
					map_image: 'data:image/png;base64,' + res.data.base64,
				};
				setVisibleToall(res.data.floorplan.isVisibleToAll);
				dispatchFormData({ payload: formDetails });
				setIsDisabled(false);
				setLoading(false);
			});
		}

		if (!isEdit) {
			setFloorPlanDetails(null);
			dispatchFormData({ payload: initialFormData });
			getSites();
		}
	}, [selectedSites, modalToShow]);

	useEffect(() => {
		if (hasFileError) {
			setIsDisabled(true);
		}
	}, [hasFileError]);

	const getSites = () => {
		setLoading(true);
		BuildingsService.getAll(false, false)
			.then(serviceBuildings => {
				let sites = filterSitesPerPermissionCode(
					'maps_edit',
					permissions,
					serviceBuildings,
				);
				setDefaultSelectedSite(sites);
			})
			.then(() => {
				setLoading(false);
			});
	};

	const setDefaultSelectedSite = sites => {
		if (selectedSites && selectedSites.length == 1) {
			let site = sites.find(site => site.id === selectedSites[0]['value']);
			if (site) {
				updateFormData(site, 'site');
				return;
			}
		}

		if (selectedSites && selectedSites.length > 1) {
			let loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
			let primarySite = sites.find(site => site.id === loggedUser.primaryBuilding.id);
			if (primarySite) {
				updateFormData(primarySite, 'site');
				return;
			}
		}
	};

	return {
		updateFormData,
		createMap,
		updateMap,
		replaceAction,
		deleteFile,
		isDisabled,
		showProgressBar,
		selectedSite,
		validationErrors,
		formData,
		setHasError,
		isVisibleToAll,
		closeAddUpdateModal,
	};
};
