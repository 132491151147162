import React from 'react';
import { Icon } from '@blueprintjs/core';
import './css/MapElementCard.scss';
import PropTypes from 'prop-types';

export const MapElementCard = ({
	title,
	subTitle,
	icon,
	isSelected,
	isHidden = false,
	showEyeIcon = true,
	onClick = () => {},
	onDrag = () => {},
	rightElement,
	onShowHideClick = () => {},
	editPermission,
	floorplanArchived,
}) => (
	<div
		onClick={onClick}
		onDragStart={editPermission && !floorplanArchived ? onDrag : () => {}}
		className={`map-sidebar-card ${isSelected ? 'selected' : ''}`}
	>
		<div className="map-sidebar-card-left-side" onClick={onShowHideClick}>
			{showEyeIcon && (
				<Icon
					className="map-sidebar-card-left-side-icon"
					icon={isHidden ? 'eye-off' : 'eye-open'}
				/>
			)}
			{icon}
			<div className="map-sidebar-card-left-side-title-container" draggable={editPermission && !floorplanArchived ? "true" : "false"}>
				<p title={title} className="map-sidebar-card-left-side-title-container-title">
					{title}
				</p>
				{subTitle && (
					<span className="map-sidebar-card-left-side-title-container-subtitle">
						{subTitle}
					</span>
				)}
			</div>
		</div>
		{rightElement && <div className="map-sidebar-card-actions-container">{rightElement}</div>}
	</div>
);

MapElementCard.propTypes = {
	title: PropTypes.string,
	subTitle: PropTypes.string,
	icon: PropTypes.element,
	isSelected: PropTypes.bool,
	isHidden: PropTypes.bool,
	rightElement: PropTypes.element,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
};
