import React, { useEffect, useRef, useState } from 'react';
import './css/MediaPlayer.scss';
import { Icon, IconSize } from '@blueprintjs/core';

export const MediaPlayer = ({ mediaUrl, showAudioPlayer = false, showVideoPlayer = false }) => {
	const isVideo =
		(mediaUrl &&
			(mediaUrl.endsWith('.mp4') ||
				mediaUrl.endsWith('.webm') ||
				mediaUrl.endsWith('.avi'))) ||
		showVideoPlayer;
	const isAudio =
		(mediaUrl &&
			(mediaUrl.endsWith('.mp3') ||
				mediaUrl.endsWith('.wav') ||
				mediaUrl.endsWith('.ogg'))) ||
		showAudioPlayer;

	const mediaRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		// Update the src attribute of the media element when mediaUrl changes
		if (mediaRef.current) {
			mediaRef.current.src = mediaUrl;
			// Pause the media when changing the source
			setIsPlaying(false);
		}
	}, [mediaUrl]);

	const handlePlayPause = () => {
		if (isPlaying) {
			mediaRef.current.pause();
		} else {
			mediaRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	const handleVideoPlay = () => {
		setIsPlaying(true);
	};

	const handleVideoPause = () => {
		setIsPlaying(false);
	};

	const handleSeeked = () => {
		handlePlayPause();
	};

	return (
		<div>
			{isVideo && (
				<div className="video-container">
					<video
						ref={mediaRef}
						src={mediaUrl}
						controls={isPlaying}
						onPlay={handleVideoPlay}
						onPause={handleVideoPause}
						width="100%"
						height="100%"
						onSeeked={handleSeeked}
					>
						Your browser does not support the video tag.
					</video>
					{!isPlaying && (
						<div className="play-button-overlay">
							<button className="play-button" onClick={handlePlayPause}>
								<Icon icon="play" size={IconSize.LARGE} />
							</button>
						</div>
					)}
				</div>
			)}

			{isAudio && (
				<audio ref={mediaRef} controls controlsList={!mediaUrl ? 'noplaybackrate' : ''}>
					<source src={mediaUrl} type="audio/mp3" />
					Your browser does not support the audio tag.
				</audio>
			)}
		</div>
	);
};
