import React from 'react';
import { useEventLogs } from 'hooks';
import { Button, Badge } from 'componentsV2';
import { EventLogItemAction } from './EventLogItemAction';
import './css/EventLogItem.scss';

export const EventLogItem = ({
  item,
  handleExpandDetails,
}) => {

  const {
    userName,
    itemName,
    description,
    dateTime,
    value,
    value_diff,
    title_diff,
    layout,
  } = useEventLogs(item);

  if (description.length === 0) return <></>;

  return (
    <div className='event-log-item-wrapper'>
      <div className="title-with-button">
        <div className="title-content">
          <span className='item-title'>
            <strong>{userName}</strong>
            {` ${description} `}
            <strong>{itemName}</strong>
          </span>
          <Badge
            type="Gray"
            size="sm"
            text={dateTime}
            hasLeadIcon={false}
            hasCloseIcon={false}
          />
        </div>

        {layout == 'diff_content' && (
          <Button
            disabled={false}
            minimal
            text="Expand details"
            type="ghostPrimary"
            intent="default"
            size="sm"
            onClick={() => handleExpandDetails(item)}
          />
        )}
      </div>

      <EventLogItemAction
        layout={layout}
        value={value}
        value_diff={value_diff}
        title_diff={title_diff}
      />

    </div>
  )
}
