import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Icon, InputGroup } from '@blueprintjs/core';

const RedIcon = styled(Icon)`
	color: #db3737;
`;

const Wrapper = styled.div`
	padding: 0 8px;
	width: 100%;
	&&& {
		input {
			border: 1px solid #d8e1e8;
			font-size: 16px;
			min-height: 40px;
			border-radius: 3px;
			overflow: hidden;
			box-shadow: none;
			text-overflow: ellipsis;
			white-space: nowrap;
			box-sizing: border-box;
		}

		.error input::placeholder {
			color: #db3737;
		}

		.bp3-intent-danger > input {
			box-shadow: 0 0 0 0 rgba(219, 55, 55, 0),
				0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737,
				inset 0 0 0 1px rgba(16, 22, 26, 0.15),
				inset 0 1px 1px rgba(16, 22, 26, 0.2) !important;
		}
	}
`;

const StyledInput = styled(InputGroup)`
	&&& {
		input::-ms-reveal,
		input::-ms-clear {
			display: none;
		}
		margin-left: 0px;
	}
`;

const InputWrap = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
`;

class AnswerOptions extends React.Component {
	state = {};

	render() {
		const {
			answerOptions,
			addOption,
			updateOptionValue,
			removeOption,
		} = this.props;

		return (
			<div>
				{answerOptions &&
					answerOptions.map((item, index) => (
						<div
							key={item.id}
							className="mt-2 d-flex justify-content-between align-items-center"
						>
							<InputWrap>
								<Icon icon="drag-handle-vertical" />
								<Wrapper>
									<StyledInput
										placeholder="Enter your text here..."
										large
										value={item.value}
										onChange={e =>
											updateOptionValue(
												e.target.value,
												index,
											)
										}
									/>
								</Wrapper>
							</InputWrap>
							<RedIcon
								icon="ban-circle"
								onClick={() => removeOption(index)}
							/>
						</div>
					))}
				<Button
					icon="plus"
					text="Add an option"
					minimal
					intent="danger"
					className="mt-3"
					onClick={addOption}
				/>
			</div>
		);
	}
}

AnswerOptions.propTypes = {
	answerOptions: PropTypes.array,
	addOption: PropTypes.func,
	updateOptionValue: PropTypes.func,
	removeOption: PropTypes.func,
};

export default AnswerOptions;
