import React, { useState, useContext, useEffect } from 'react';
import './Section.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, FeaturedIcon, ToasterContext, Intent } from 'componentsV2';
import { SectionActions } from './SectionActions';
import { AssessTemplatesService } from 'services';
import { Question } from './Question';

const getHeading = (depth, name) => {
	switch (depth) {
		case 0:
			return <h2 className="title">{name}</h2>;
		case 1:
			return <h3 className="title">{name}</h3>;
		case 2:
			return <h4 className="title">{name}</h4>;
		case 3:
			return <h5 className="title">{name}</h5>;
		default:
			// please no one make this many subsections
			return <h6 className="title">{name}</h6>;
	}
};

export const Section = ({ section, depth, onOpenModal, templateId, isViewOnly }) => {
	const { name, instructions, questions, sections } = section;
	const [sectionQuestions, setSectionQuestions] = useState(questions);
	const toaster = useContext(ToasterContext);

	useEffect(() => {
		setSectionQuestions(questions);
	}, [questions]);

	const handleOnDragEnd = async result => {
		if (!result.destination) {
			return;
		}
		const questionItems = Array.from(sectionQuestions);
		const [reorderedQuestion] = questionItems.splice(result.source.index, 1);
		questionItems.splice(result.destination.index, 0, reorderedQuestion);
		setSectionQuestions(questionItems);
		await AssessTemplatesService.updateQuestionOrder(
			templateId,
			section.id,
			questionItems.map(ques => ques.id),
		).catch(err => {
			toaster(
				`There was an error updating the question order. Please try again.`,
				Intent.DANGER,
				<FeaturedIcon icon="error" type="Error" />,
			);
			setSectionQuestions(sectionQuestions);
			console.log('err', err);
		});
	};

	return (
		<div className="view-edit-template-section" style={{ paddingLeft: `${depth * 24}px` }}>
			<div className={`header ${questions.length === 0 ? 'no-questions' : ''}`}>
				<div className="title-actions">
					{getHeading(depth, name)}
					{!isViewOnly && (
						<SectionActions onOpenModal={onOpenModal} section={section} depth={depth} />
					)}
				</div>
				<div className="directions">
					<span>Directions: </span>
					<span>{instructions}</span>
				</div>
			</div>
			<DragDropContext onDragEnd={handleOnDragEnd}>
				<Droppable droppableId="questions" type="QUESTIONS">
					{(provided, snapshot) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{sectionQuestions.map((question, index) => (
								<Draggable
									key={question.id}
									index={index}
									draggableId={question.id?.toString()}
									isDragDisabled={isViewOnly}
								>
									{(p, s) => (
										<div
											ref={p.innerRef}
											{...p.dragHandleProps}
											{...p.draggableProps}
										>
											<Question
												key={question.id}
												sectionId={section.id}
												question={question}
												questionNumber={index + 1}
												isViewOnly={isViewOnly}
												onOpenModal={onOpenModal}
											/>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{!isViewOnly && (
				<div className="add-buttons">
					<Button
						type="ghostDefault"
						icon="add"
						text="Add a question"
						size="sm"
						onClick={() => onOpenModal('add_question', { sectionId: section.id })}
					/>
					<Button
						type="ghostDefault"
						icon="add"
						text="Add a subsection"
						size="sm"
						onClick={() => {
							onOpenModal('add_subsection', { id: section.id });
						}}
					/>
				</div>
			)}
			{/* Sub sections */}
			{sections.map(subSection => (
				<Section
					key={subSection.id}
					section={subSection}
					depth={depth + 1}
					onOpenModal={onOpenModal}
					templateId={templateId}
					isViewOnly={isViewOnly}
				/>
			))}
		</div>
	);
};
