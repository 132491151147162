import React, { useContext } from 'react';
import { NormalModal } from 'componentsV2';
import { CreateAlarmTypeContext } from '../provider';
import { AlarmIcon } from '../components/CreateAlarmTypeBody/components/CardContent/components/SharedFields';

export const BrowseIconsModal = () => {
	const { formData, formErrors, onChangeFormData, closeModal } = useContext(
		CreateAlarmTypeContext,
	);

	return (
		<NormalModal
			isOpen={true}
			title="Alarm icon"
			showCloseButton={false}
			showConfirmBtn={true}
			confirmBtnTitle="Done"
			confirmBtnType="primaryDefault"
			onConfirmAction={closeModal}
		>
			<h3 className="body-lg-bold">
				Select an icon for your alarm <span className="text-danger">*</span>
			</h3>
			<AlarmIcon
				formData={formData}
				formErrors={formErrors}
				onChangeFormData={onChangeFormData}
			/>
		</NormalModal>
	);
};
