import { HttpService } from 'networking/HttpService';

function daysIntoYear(date = new Date()) {
	return (
		(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
			Date.UTC(date.getFullYear(), 0, 0)) /
		24 /
		60 /
		60 /
		1000
	);
}

export const SchoolYearsService = {
	getCurrentYear: () => {
		// US National school year threshold Aug 31.
		const cutoff = 243;

		if (daysIntoYear() > cutoff) {
			// Current Year
			return new Date().getFullYear();
		}

		// Previous Year
		return new Date().getFullYear() - 1;
	},

	fetchYears: () => {
		// if the request fails, cache an array of five years: past two years, current year, next two years
		const year = new Date().getFullYear();
		const failSafe = [];
		for (let i = -2; i <= 2; i += 1) {
			failSafe.push({
				label: `${year + i} - ${year + i + 1}`,
				value: parseInt(year + i, 10),
			});
		}

		return HttpService({
			method: 'GET',
			route: '/drills-init',
		})
			.then(res => {
				if (typeof res?.data?.years === 'object') {
					return Object.keys(res?.data?.years).map(key => ({
						label: res?.data?.years[key],
						value: parseInt(key, 10),
					}));
				}

				return failSafe;
			})
			.catch(() => failSafe);
	},
	async getMonths(useForDropdown = true, includeAllMonths) {
		const months = [
			includeAllMonths && 'All months',
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		if (useForDropdown) {
			return months.map(item => ({
				label: item,
				value: item,
			}));
		}

		return months;
	},
};
