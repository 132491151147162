import React from 'react';
import { FormModal, Input } from 'componentsV2';

export const RenameMapModal = ({ 
	textToRename,
	handleChangeText,
	disableRenameForm,
	loading, 
	closeModal, 
	modalData 
}) => {
	return (
		<FormModal
			isOpen={true}
			title={modalData.modalTitle}
			subtitle={modalData.modalSubTitle}
			showCloseButton={false}
			confirmBtn={{
				title: "Update",
				disabled: disableRenameForm,
				onClick: modalData.onConfirmAction,
			}}
			cancelBtn={{
				title: "Cancel",
				onClick: closeModal
			}}
			loading={loading}
			scrollContent={false}
			customClassName={'map-container-modals'}
			onClose={closeModal}
		>
			<div className="col-12 mb-3">
				<Input 
					value={textToRename} 
					name="textToRename" 
					label="Name"
					isRequired={true}
					onChange={handleChangeText}
				/>
			</div>
		</FormModal>
	);
};
