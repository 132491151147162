import React from "react";
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Dialog , Icon } from '@blueprintjs/core';	
import { useState } from "react";
import './css/CustomModal.scss';
import PropTypes from 'prop-types';

const CustomModal = ({
    onCloseModal,
    isLoading,
    title,
    footerBtns,
    children,
}) => {
    
    const [isOpen, setIsOpen] = useState(true);
    const closeModal = () => {
        setIsOpen(false)
        onCloseModal()
    }

    return (
        <Dialog isOpen={isOpen} onClose={closeModal}>
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div className="custom-modal-wrapper">
                    <div className="custom-modal-header">
                        <h3 className="custom-modal-title">{title}</h3>
                        <Icon icon='cross' className='cross-icon' onClick={closeModal}/>
                    </div>
                    <div className="custom-modal-body">
                        {children}
                    </div>
                    <div className="custom-modal-footer">
                        {footerBtns}
                    </div>
                </div>
            )}
        </Dialog>
    )
}

CustomModal.propTypes = {
    isLoading: PropTypes.boolean,
    onCloseModal: PropTypes.func,
    title: PropTypes.string,
    footerBtns: PropTypes.any,
};

export default CustomModal;