import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
	Tabs,
	Tab,
	Menu,
	MenuItem,
	Popover,
	Position,
} from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { backArrow } from 'assets/icons';
import { Title } from 'components/Title';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import selectRoute from './selectors';
import SectionsTable from './sectionstable';
import Tasks from './Tasks/';
import AddSummaryModal from './summarymodal';
import { checkPermission } from '../../utilities/permissions';
import ExportSectionModal from './exportSectionModal';

const BackBtn = styled.div`
	display: flex;
	color: #5c7080;
	cursor: pointer;
	font-size: 14px;
	transition: 0.1s;
	line-height: 20px;
	font-style: normal;
	border-radius: 8px;
	font-weight: normal;
	align-items: center;
	flex-direction: row;
	font-family: Nunito Sans;

	:hover {
		background-color: white;
	}

	img {
		margin-right: 9px;
	}
`;

const Subtitle = styled.div`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
`;

const Wrapper = styled.div`
	flex-direction: row;
	display: flex;
`;

class SingleAssessment extends React.Component {
	state = {
		loading: true,
		name: null,
		state: null,
		year: null,
		sections: [],
		tasks: [],
		panel: 'sections',
		showSummaryModal: false,
		showExportSelectionModal: false,
		assessmentSummary: null,
	};

	getData = () => {
		this.setState({
			loading: true,
			showSummaryModal: false,
			showExportSelectionModal: false,
		});
		const {
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		Promise.all([
			fetch(
				`${
					process.env.API_URL
				}/assessments/${id}?includeHiddenSections=true`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			),
			fetch(`${process.env.API_URL}/assessments/${id}/tasks`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			}),
		])
			.then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
			.then(([resp1, resp2]) =>
				this.setState({
					loading: false,
					name: resp1.data.name,
					state: resp1.data.building.state,
					year: resp1.data.year,
					sections: resp1.data.sections,
					status: resp1.data.status,
					tasks: resp2.data,
					assessmentSummary: resp1.data.assessmentSummary,
				}),
			);
	};

	toggleCompleted = () => {
		const { status } = this.state;
		const {
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		// Swal.fire('Saving');
		// Swal.showLoading();
		Swal.fire({
			title: `Mark assessment as completed`,
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: `Mark as completed`,
			reverseButtons: true,
		}).then(result => {
			if (result.dismiss === Swal.DismissReason.cancel) {
				return Swal.close();
			}
			fetch(`${process.env.API_URL}/assessments/${id}`, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					isCompleted: status === 'In Progress',
				}),
			})
				.then(res => res.json())
				.then(resp => {
					if (resp.statusCode === 200) {
						Swal.fire({
							title: 'Done!',
							icon: 'success',
							timer: 1000,
							showConfirmButton: false,
						}).then(() => this.getData());
					}
				});
			return Swal.close();
		});
	};

	toggleNotCompleted = () => {
		const { status } = this.state;
		const {
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		Swal.fire({
			title: `Mark assessment as not completed`,
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: `Mark as not completed`,
			reverseButtons: true,
		}).then(result => {
			if (result.dismiss === Swal.DismissReason.cancel) {
				return Swal.close();
			}
			fetch(`${process.env.API_URL}/assessments/${id}`, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					isCompleted: status === 'In Progress',
				}),
			})
				.then(res => res.json())
				.then(resp => {
					if (resp.statusCode === 200) {
						Swal.fire({
							title: 'Done!',
							icon: 'success',
							timer: 1000,
							showConfirmButton: false,
						}).then(() => this.getData());
					}
				});
			return Swal.close();
		});
	};

	closeAddSummary = () => this.setState({ showSummaryModal: false });

	closeExportSection = () =>
		this.setState({ showExportSelectionModal: false });

	exportSections = () => {
		Swal.fire('Exporting');
		Swal.showLoading();
		const {
			match: {
				params: { id: assessmentId },
			},
		} = this.props;
		const { name } = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/assessments/${assessmentId}/export`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({ something: 'hello' }), // This is a weird bug fix
		})
			.then(res => res.blob())
			.then(resp => {
				Swal.close();
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				}).then(() => {
					const filename = `${name} ${format(
						new Date(),
						'hh-mm-ss a',
					)}`;
					const data = new Blob([resp], {
						type: 'application/pdf',
					});
					const csvURL = window.URL.createObjectURL(data);
					const tempLink = document.createElement('a');
					tempLink.href = csvURL;
					tempLink.setAttribute('download', `${filename}.pdf`);
					tempLink.click();
				});

				// this.setState({ exportLoading: false });
			});
	};

	exportWord = () => {
		Swal.fire('Exporting');
		Swal.showLoading();
		const {
			match: {
				params: { id: assessmentId },
			},
		} = this.props;
		const { name } = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${process.env.API_URL}/assessments/${assessmentId}/export-word`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		)
			.then(res => res.blob())
			.then(resp => {
				Swal.close();
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				}).then(() => {
					const filename = `${name} ${format(
						new Date(),
						'hh-mm-ss a',
					)}`;
					const data = new Blob([resp], {
						type: 'application/word',
					});
					const csvURL = window.URL.createObjectURL(data);
					const tempLink = document.createElement('a');
					tempLink.href = csvURL;
					tempLink.setAttribute('download', `${filename}.docx`);
					tempLink.click();
				});

				// this.setState({ exportLoading: false });
			});
	};

	deleteAssessment = () => {
		Swal.fire({
			title: `Are you sure you want to delete this assessment?`,
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: `Delete`,
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				const {
					match: {
						params: { id: assessmentId },
					},
					history,
				} = this.props;
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				return fetch(
					`${process.env.API_URL}/assessments/${assessmentId}`,
					{
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
					.then(res => res.json())
					.then(resp => {
						if (resp.statusCode === 200) {
							Swal.close();
							Swal.fire({
								title: 'Done!',
								icon: 'success',
								timer: 1000,
								showConfirmButton: false,
							}).then(() => history.push('/assess'));
						}
						return null;
					});
			}
			return Swal.close();
		});
	};

	componentDidMount() {
		return this.getData();
	}

	render() {
		const {
			match: {
				params: { id: assessmentId },
			},
			history,
			route: { permissions },
		} = this.props;

		const {
			name,
			state,
			year,
			sections,
			panel,
			loading,
			status,
			tasks,
			showSummaryModal,
			showExportSelectionModal,
			assessmentSummary,
		} = this.state;

		if (loading) return <LoadingSpinner />;

		return (
			<Wrapper>
				{showSummaryModal && (
					<AddSummaryModal
						year={year}
						name={name}
						isOpen
						value={assessmentSummary}
						getData={this.getData}
						closeAddSummary={this.closeAddSummary}
						assessmentId={assessmentId}
					/>
				)}
				{showExportSelectionModal && (
					<ExportSectionModal
						isOpen
						value={assessmentSummary}
						name={name}
						getData={this.getData}
						sections={sections}
						closeExportSection={this.closeExportSection}
						assessmentId={assessmentId}
					/>
				)}
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div>
								<div className="d-flex align-items-center justify-content-between">
									<div>
										<div className="d-flex align-items-center mt-3">
											<BackBtn
												onClick={() =>
													history.push('/assess')
												}
											>
												<img
													src={backArrow}
													alt="back arrow"
												/>
												Back to Dashboard
											</BackBtn>
										</div>
										<div className="d-flex justify-content-between">
											<div>
												<div className="mt-2 d-flex align-items-center">
													<Title className="mb-0">
														{year}-{year + 1}:{' '}
														{name}
													</Title>
												</div>
												<Subtitle>{state}</Subtitle>
											</div>
										</div>
									</div>
									<div>
										<Popover position={Position.BOTTOM}>
											<Button
												intent="tertiary"
												icon="cog"
												minimal
												style={{}}
												text="Options"
											/>
											<div>
												<Menu>
													{checkPermission(
														'site_assess_edit',
														permissions,
													) && (
														<MenuItem
															text="Add Summary"
															onClick={() =>
																this.setState({
																	showSummaryModal: true,
																})
															}
														/>
													)}
													<MenuItem
														text="Export sections to PDF"
														onClick={() =>
															this.setState({
																showExportSelectionModal: true,
															})
														}
													/>
													<MenuItem
														text="Export all to PDF"
														onClick={
															this.exportSections
														}
													/>
													<MenuItem
														text="Export all to Word"
														onClick={
															this.exportWord
														}
													/>
													{checkPermission(
														'site_assess_edit',
														permissions,
													) && (
														<MenuItem
															style={{
																color:
																	'#DB3737',
															}}
															text="Delete assessment"
															onClick={
																this
																	.deleteAssessment
															}
														/>
													)}
												</Menu>
											</div>
										</Popover>
										{checkPermission(
											'site_assess_edit',
											permissions,
										) && (
											<Button
												intent="secondary"
												text={
													status === 'In Progress'
														? 'Mark as complete'
														: 'Mark as not completed'
												}
												className="ml-2"
												onClick={
													status === 'In Progress'
														? this.toggleCompleted
														: this
																.toggleNotCompleted
												}
											/>
										)}
									</div>
								</div>

								<Tabs
									id="assessTabs"
									defaultSelectedTabId={panel}
									onChange={val =>
										this.setState({ panel: val })
									}
									className="mt-2"
								>
									<Tab
										id="sections"
										title="Sections"
										panel={
											<SectionsTable
												year={year}
												name={name}
												sections={sections}
												assessmentId={assessmentId}
												history={history}
												editAssess={checkPermission(
													'site_assess_edit',
													permissions,
												)}
												getData={this.getData}
												assessmentSummary={
													assessmentSummary
												}
											/>
										}
									/>
									<Tab
										id="tasks"
										title="Tasks"
										panel={
											<Tasks
												tasks={tasks}
												assessmentId={assessmentId}
												history={history}
												getData={this.getData}
											/>
										}
									/>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
		);
	}
}

SingleAssessment.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
	route: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(SingleAssessment);
