import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Select } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import './css/EditTemplateDetails.scss';
import TemplateDetails from '../AddTemplate/templatedetails';

class EditTemplateDetails extends React.Component {
	state = {
		template: {
			name: '',
			type: null,
			state: null,
			site: null,
			isProprietary: false,
		},
		clients: [],
		loading: true,
	};

	updateFields = (key, value) => {
		const { template } = this.state;
		template[key] = value;
		return this.setState({ template });
	};
	getData = () => {
		const {
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;

		Promise.all([
			fetch(`${process.env.API_URL}/plan-templates/${id}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
			fetch(`${process.env.API_URL}/clients`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			}),
		])
			.then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
			.then(([resp1, resp2]) => {
				const temp = resp2.data;
				temp.map(item => {
					if (!item.name) return null;
					const tmp = item;
					tmp.searchString = `${item.id} ${item.name.toLowerCase()}`;
					return tmp;
				});
				let districts = resp1.data.template.districts || [];
				let formattedDistricts = [];
				if (districts.length > 0) {
					formattedDistricts = districts.map(district => ({
						id: district.districtId,
						name: district.name,
					}));
				}
				const template = {
					name: resp1.data.template.name,
					state: resp1.data.template.state,
					isProprietary: resp1.data.template.isProprietary,
					districts: formattedDistricts,
					data: { id },
				};
				// let districts = [];
				if (template.isProprietary) {
					districts = template.districts;
				}
				return this.setState({
					template,
					clients: temp,
					districts: template.isProprietary ? template.districts: [],
					loading: false,
				});
			});
	};

	setClient = val => this.setState({ districts: val });

	componentDidMount() {
		this.getData();
	}

	render() {
    const {template, clients, loading, districts} = this.state;
		const { history } = this.props;
		if (loading) return <LoadingSpinner />;
		return (
			<div className="add-template-wrapper">
				<TemplateDetails
					template={template}
					updateFields={this.updateFields}
					history={history}
					clients={clients}
					districts={districts}
					setClient={this.setClient}
					title="Edit Template Details"
					submitText="Save Changes"
					type="core"
				/>
			</div>
		);
	}
}

EditTemplateDetails.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
};

export default EditTemplateDetails;
