import { parsePHPDateTime } from '../dates';
import { translateStatus } from './utils';

export const deserializeDrillTypes = backendDrillTypes =>
	backendDrillTypes.map(drillType => ({
		...drillType,
		value: drillType.id,
		label: drillType.name,
		uuid: drillType.uuid,
	}));

export const deserializeStates = backendStates =>
	backendStates.map(state => ({
		...state,
		id: state.id,
		value: state.name.toLowerCase(),
		label: state.name,
	}));

export const deserializeSites = backendSites =>
	backendSites.map(state => ({
		...state,
		value: state.id,
		label: state.name,
	}));

export const deserializeDrills = backendDrills =>
	backendDrills.map((drill, index) => ({
		...drill,
		id: drill.scheduleId,
		title: `#${index + 1} - ${drill.drillType.name}`,
		type: drill.drillType.name,
		typeId: drill.drillType.id,
		date: parsePHPDateTime(
			drill.scheduledDrillDate.date,
			drill.scheduledDrillDate.timezone_type,
			drill.scheduledDrillDate.timezone,
		),
		actualDate: drill.log?.drillLoggedDate?.date
			? parsePHPDateTime(
					drill.log?.drillLoggedDate.date,
					drill.log?.drillLoggedDate.timezone_type,
					drill.log?.drillLoggedDate.timezone,
			  )
			: null,
		datePosted: drill.log?.logCompletedDate?.date
			? parsePHPDateTime(
					drill.log?.logCompletedDate.date,
					drill.log?.logCompletedDate.timezone_type,
					drill.log?.logCompletedDate.timezone,
			  )
			: null,
		status: translateStatus(drill.status),
	}));

export const deserializeCompleteRequirements = backendRequirements =>
	backendRequirements.map(requirement => ({
		id: requirement.id,
		type: requirement.name,
		uuid: requirement.uuid,
		districtRequirements: requirement.districtRequirement?.requirements,
		stateRequirements: requirement.stateRequirement?.requirements,
		active: requirement.districtRequirement
			? requirement.districtRequirement.active
			: false,
		...requirement,
	}));

export const deserializeSafetyPlans = backendPlans =>
	backendPlans.map(state => ({
		...state,
		value: state.id,
		label: state.name,
	}));
