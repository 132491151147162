/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { Button } from 'components/Button';
import { Callout } from 'components/Callout';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Input } from 'components/Input';
import { AddUsersModal } from 'components/Modals/AddUsersModal';
import { CardModal } from 'components/Modals/CardModal';
import { MoreActionsButton } from 'components/MoreActionsButton';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { DrillsSettingsService } from 'services';

const Page = styled.div`
	padding: 8px 24px;

	.recipient-header-buttons {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: flex-end;
	}

	.empty-message {
		height: 250px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		h4 {
			color: #5c7080;
			font-size: 18px;
			font-weight: 700;
			line-height: 21px;
			font-style: normal;
			letter-spacing: 0.3px;
			font-family: Merriweather;

			.red {
				color: #9f1c3a;
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	table {
		margin-top: 16px;
	}

	.modal-card {
		width: 400px;
	}

	.add-users-modal {
		width: initial;
	}
`;

const BASE_VIEW = {
	modalToShow: null,
	recipientEmail: null,
	isRecipientEmailValid: false,
	recipientName: null,

	selectedRecipient: null,
};

const BASE_STATE = {
	...BASE_VIEW,
	loading: true,
	error: null,
	data: [],
};

export default class OfficeEmailRecipients extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		DrillsSettingsService.getSafetyOfficers()
			.then(officers => {
				this.setState({
					...BASE_STATE,
					loading: false,
					data: officers,
				});
			})
			.catch(() => {
				this.setState({
					...BASE_STATE,
					loading: false,
					error: 'Could not load this page. Please try refreshing',
				});

				Swal.fire({
					title: 'Oops!',
					text: 'Could not load this page. Please try refreshing',
					icon: 'error',
					toast: true,
					position: 'top',
					showConfirmButton: false,
				});
			});
	}

	setModalToShow = (modalToShow = null, selectedRecipient = null) => {
		this.setState({
			...BASE_VIEW,
			modalToShow,
			selectedRecipient,
		});
	};

	addEmailRecipient = () => {
		const { recipientEmail, recipientName, data } = this.state;
		DrillsSettingsService.addSafetyOfficers(null, [
			{
				email: recipientEmail,
				name: recipientName,
			},
		])
			.then(recipients => {
				this.setState({
					...BASE_VIEW,
					data: data.concat(recipients),
				});
				Swal.fire({
					title: 'Success!',
					text: 'This user has been added!',
					icon: 'success',
				});
			})
			.catch(() => {
				Swal.fire({
					title: 'Oops!',
					text: 'Could not add this user. Please try again.',
					icon: 'error',
				});
			});
	};

	addExistingUsers = users => {
		const { data } = this.state;
		DrillsSettingsService.addSafetyOfficers(users)
			.then(recipients => {
				this.setState({
					...BASE_VIEW,
					data: data.concat(recipients),
				});
				Swal.fire({
					title: 'Success!',
					text: 'This user has been added!',
					icon: 'success',
				});
			})
			.catch(() => {
				Swal.fire({
					title: 'Oops!',
					text: 'Could not add this user. Please try again.',
					icon: 'error',
				});
			});
	};

	removeRecipient = () => {
		const { selectedRecipient, data } = this.state;
		DrillsSettingsService.removeSafetyOfficer(selectedRecipient.id)
			.then(() => {
				this.setState({
					modalToShow: null,
					selectedRecipient: null,
					data: data.filter(({ id }) => id !== selectedRecipient.id),
				});
				Swal.fire({
					title: 'Success!',
					text: 'This user has been removed!',
					icon: 'success',
				});
			})
			.catch(() => {
				Swal.fire({
					title: 'Oops!',
					text: 'Could not remove this user. Please try again.',
					icon: 'error',
				});
			});
	};

	checkValidity = (target) => {
		if (target.validity.valid)
			this.setState({ isRecipientEmailValid: true })
		else
			this.setState({ isRecipientEmailValid: false })
	}

	render() {
		const {
			loading,
			error,
			data,
			modalToShow,
			recipientEmail,
			isRecipientEmailValid,
			recipientName,
		} = this.state;

		const { history } = this.props;

		if (error) {
			return 'Something went wrong, please refresh';
		}

		if (loading) {
			return <LoadingSpinner />;
		}

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Emergency Management Office Email Recipients"
					onBackBtnClick={history.goBack}
					customItem={() => (
						<div className="recipient-header-buttons">
							<Button
								minimal
								text="Add email recipient"
								intent="tertiary"
								icon="plus"
								onClick={() =>
									this.setModalToShow('addEmailRecipient')
								}
							/>

							<Button
								minimal
								text="Add existing users as recipients"
								intent="tertiary"
								icon="plus"
								onClick={() =>
									this.setModalToShow('addExistingUsers')
								}
							/>
						</div>
					)}
				/>

				{/* empty message */}
				{!data.length && (
					<div className="empty-message">
						<h4>
							<span
								className="red"
								onClick={() =>
									this.setModalToShow('addEmailRecipient')
								}
							>
								Add an email recipient
							</span>{' '}
							or{' '}
							<span
								className="red"
								onClick={() =>
									this.setModalToShow('addExistingUsers')
								}
							>
								Add existing users as recipients
							</span>{' '}
							to begin
						</h4>
					</div>
				)}

				{/* instructions callout */}
				{!!data.length && (
					<Callout type="info">
						Anyone in the below list will receive messages via email
						from anyone who submits a question when filling out a
						drill report
					</Callout>
				)}

				{/* drills table */}
				{!!data.length && (
					<Table
						headers={['User ID', 'Name', 'Email', 'Actions']}
						data={data}
						renderItem={item => (
							<>
								<td>{item.id}</td>

								<td>{item.name}</td>

								<td>{item.email}</td>

								<td>
									<MoreActionsButton
										actions={[
											{
												id: 'remove',
												title: 'Remove recipient',
												isDestructive: true,
												handler: () =>
													this.setModalToShow(
														'removeRecipient',
														item,
													),
											},
										]}
									/>
								</td>
							</>
						)}
					/>
				)}

				{/* add a new recipient */}
				<CardModal
					isVisible={modalToShow === 'addEmailRecipient'}
					setIsVisible={this.setModalToShow}
					title="Add email recipient"
					actionTitle="Add"
					onActionClick={this.addEmailRecipient}
					actionBtnDisabled={!recipientEmail || !recipientName || !isRecipientEmailValid}
				>
					{/* email field */}
					<Input
						type="email"
						label="Email address"
						placeholder="Enter email address"
						value={recipientEmail}
						onChange={({ target }) => {
								this.checkValidity(target)
								this.setState({ recipientEmail: target.value })
							}
						}
						
					/>

					{/* name field */}
					<Input
						label="Name"
						placeholder="Enter name"
						value={recipientName}
						onChange={({ target }) =>
							this.setState({ recipientName: target.value })
						}
					/>
				</CardModal>

				{/* add an existing user */}
				{modalToShow === 'addExistingUsers' && (
					<AddUsersModal
						setModalToShow={this.setModalToShow}
						onUsersAdd={this.addExistingUsers}
					/>
				)}

				{/* add an existing user */}
				<CardModal
					isVisible={modalToShow === 'removeRecipient'}
					setIsVisible={this.setModalToShow}
					title="Remove recipient"
					actionTitle="Remove"
					onActionClick={this.removeRecipient}
				>
					<p className="modal-text">
						Are you sure you want to remove this recipient?
					</p>
				</CardModal>
			</Page>
		);
	}
}

OfficeEmailRecipients.propTypes = {
	history: PropTypes.object,
};