import React from 'react';
import './css/FeaturedIcon.scss';
import { Icon } from '@blueprintjs/core';

export const FeaturedIcon = ({
    icon, type, size = 'md', shape = 'circleIcon', onClick = () => {}
}) => {

	return (
	<div className="FeaturedIcon__Wrapper" onClick={onClick}>
		<div className={`FeaturedIcon__Circle ${type} ${size} ${shape}`}>
            <Icon icon={icon} />
        </div>
	</div>
	);
};