import React, { useState, useRef } from 'react';
import { Icon, Popover, Position } from '@blueprintjs/core';
import './css/ActionsButton.scss';

export const ActionsButton = ({
	disabled = false,
	isOpened = false,
	children,
	customClass,
	elementSelector = null,
	closeOnClick = true,
	popoverClassName = '',
	position = Position.BOTTOM_RIGHT,
}) => {
	const [isOpen, setIsOpen] = useState(isOpened);
	const actionsButtonRef = useRef();

	if (closeOnClick) {
		document.addEventListener('click', e => {
			if (!actionsButtonRef.current?.contains(e.target)) {
				setIsOpen(false);
			}
		});
	}

	return (
		<div className={`ActionsButton__Container ${ disabled ? 'ActionsButton__Disabled' : ''}`} ref={actionsButtonRef}>
			<Popover
				popoverClassName={`ActionsButton__popover ${popoverClassName}`}
				className="actions-btn-popover-overlay"
				disabled={disabled}
				enforceFocus={false}
				content={
					<div className={'popover-actions-container actions-btn-popover ' + customClass}>
						<span className={'list-items'}>{children}</span>
					</div>
				}
				visible={true}
				minimal={false}
				usePortal={true}
				isOpen={isOpen}
				position={position}
				onClose={() => setIsOpen(false)}
			>
				{elementSelector ? (
					React.cloneElement(elementSelector, {
						onClick: () => setIsOpen(true),
					})
				) : (
					<Icon
						icon="more"
						className={`action-icon ${disabled ? 'disabled-action' : ''}`}
						onClick={() => setIsOpen(true)}
					/>
				)}
			</Popover>
		</div>
	);
};
