import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'componentsV2';
import { TabPreviewModal } from 'pages/Flipchart/components';
export const FlipchartPreview = ({ formData }) => {
	const [showTabPreviewModal, setShowTabPreviewModal] = useState(false);

	const showFlipchartPreviewModal = () => {
		setShowTabPreviewModal(true);
	};

	return (
		<div className="AlarmtypeFlipchart-Button_Wrapper">
			<Button
				icon="eye-open"
				text="Preview"
				type="secondaryDefault"
				intent="default"
				size="sm"
				disabled={
					formData.flipchartValue == null || formData.flipchartValue?.value == -1
						? true
						: false
				}
				onClick={() => showFlipchartPreviewModal()}
			/>
			{showTabPreviewModal && (
				<TabPreviewModal
					showTabPreviewModal={showTabPreviewModal}
					setShowTabPreviewModal={setShowTabPreviewModal}
					id={formData.flipchartValue?.value}
				/>
			)}
		</div>
	);
};

FlipchartPreview.propTypes = {
	formData: PropTypes.object,
};
