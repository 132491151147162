import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BuildingsService, PlanService } from 'services';
import { Intent, FeaturedIcon } from 'componentsV2';
import { filterSitesPerPermissionCode } from "utilities/permissions";
import { DISTRICT_LIST_PATH } from '../../../consts';

export const CopySafetyPlanModalFn = (
	modalName,
	setLoading,
	formData,
	dispatchSafetyPlanData,
	closeModal,
	toaster,
	setModalToShow,
	loadData,
	selectedSites,
	history,
) => {

	const permissions = useSelector(state => state.route.permissions);

	useEffect(() => {
		let disabled = true;
		if (formData.site &&
			formData.title &&
			formData.title.trim().length > 0) {
			disabled = false;
		}
		dispatchSafetyPlanData({
			...formData,
			isDisabled: disabled,
		});
	}, [formData.title, formData.site,]);

	const showCopyModal = (safetyPlan) => {
		setModalToShow(modalName);
		setInitialData(safetyPlan);
	};

	const setInitialData = (safetyPlan) => {
		setLoading(true);
		BuildingsService.getAll(false, false).then(serviceBuildings => {
			let sites = filterSitesPerPermissionCode('safety_plans_edit', permissions, serviceBuildings);
			let selectedSite = null;
			if (safetyPlan.siteId) {
				selectedSite = sites.find(site => site.id === safetyPlan.siteId);
			} else {
				if (selectedSites && selectedSites.length == 1) {
					selectedSite = sites.find(site => site.id === selectedSites[0]);
				}

				if (!selectedSite) {
					let loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
					selectedSite = sites.find(site => site.id === loggedUser.primaryBuilding.id);
				}
			}

			dispatchSafetyPlanData({
				id: safetyPlan.id,
				title: `${safetyPlan.title} - COPY`,
				oldTitle: safetyPlan.title,
				site: selectedSite,
				sites,
			});

		}).then(() => {
			setLoading(false);
		})
	}

	const handleChangeTitleCopy = e => {
		dispatchSafetyPlanData({ ...formData, title: e.target.value });
	};

	const handleChangeSiteCopy = option => {
		dispatchSafetyPlanData({ ...formData, site: option });
	};

	const handleSubmitCopy = () => {
		setLoading(true);
		PlanService.copy({
			id: formData.id,
			buildingId: formData.site.id,
			newName: formData.title,
		})
			.then(resp => {
				if (resp.statusCode === 201) {
					toaster(`You have successfully copied "${formData.oldTitle}"`, Intent.SUCCESS, <FeaturedIcon icon={'tick'} type={'Success'} size={'md'} shape={'circleIcon'} />);
					closeModal();
					history.push(`${DISTRICT_LIST_PATH}/${resp.data.id}`);
					loadData();
				}
			})
			.catch(error => {
				toaster(
					'Error copying the safety plan',
					Intent.DANGER,
				);
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		showCopyModal,
		handleChangeTitleCopy,
		handleChangeSiteCopy,
		handleSubmitCopy,
	};
};
