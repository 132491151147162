import React, { useContext, useState } from 'react';
import { AlertModal, Button } from 'componentsV2';
import { RapidAlarmService } from 'services';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { ACTIVATE_ROLL_CALL, END_ROLL_CALL } from 'pages/RapidAlarms/consts';

export const ActiveEndRollCall = ({ activeAlarm, requstType, handleCancel }) => {
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);
	const isVmsActive = activeAlarm.vmAccess;
	console.log(isVmsActive);
	const handleRollCall = () => {
		if (requstType === ACTIVATE_ROLL_CALL) {
			RapidAlarmService.activateRollCall(activeAlarm.id)
				.then(resp => {
					if (resp.statusCode === 200) {
						toaster('You have successfully activated Roll Call.', Intent.SUCCESS);
					}
				})
				.catch(err => {
					toaster(err?.error?.fields.id, Intent.DANGER);
				})
				.finally(() => {
					setLoading(false);
					handleCancel();
				});
		} else if (requstType === END_ROLL_CALL) {
			setLoading(true);
			RapidAlarmService.endRollCall(activeAlarm.id)
				.then(resp => {
					if (resp.statusCode === 200) {
						toaster('You have successfully ended Roll Call.', Intent.SUCCESS);
					}
				})
				.catch(err => {
					toaster(err?.error?.fields.id, Intent.DANGER);
				})
				.finally(() => {
					setLoading(false);
					handleCancel();
				});
		}
	};

	return (
		<AlertModal
			isOpen={true}
			loading={loading}
			size="medium"
			title={requstType === 'Active' ? 'Activate Roll Call?' : 'End Roll Call?'}
			subtitle={
				requstType === 'Active'
					? isVmsActive
						? 'Student, staff, and visitors can be accounted for.'
						: 'Student and staff can be accounted for.'
					: 'Please note that ending Roll Call will not end the alarm, just Roll Call. This action is irreversible.'
			}
			showCloseButton={false}
			alertConfirmButton={
				<Button
					text={requstType === 'Active' ? 'Activate Roll Call' : 'End Roll Call'}
					type={requstType === 'Active' ? 'primaryDefault' : 'primaryDanger'}
					size="md"
					onClick={handleRollCall}
				/>
			}
			alertCancelButton={
				<Button text="Cancel" type="tertiary" size="md" onClick={handleCancel} />
			}
			icon="warning-sign"
			iconColor="warning"
		/>
	);
};
