import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Dialog } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	${'' /* padding: 5px 5px 0 5px; */}
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

const DialogWrap = styled.div`
	padding-left: 10px;
	padding-right: 10px;
`;

const ConfirmationModal = ({
	title,
	text,
	confirmBtnTxt,
	submitModel,
	closeModal,
	loading,
	danger,
}) => {
	return (
		<Dialog isOpen={true} onClose={closeModal}>
			{loading && <LoadingSpinner />}

			{!loading && (
				<DialogWrap>
					<TitleWrap>
						<Title>{title}</Title>
						<Button text="" minimal icon="cross" onClick={closeModal} />
					</TitleWrap>
					<hr />
					{
						<>
							{text}
							<hr className="modal-footer-actions" />
							<div className="d-flex justify-content-end mt-2 px-2">
								<Button
									text="Cancel"
									large
									intent={danger ? 'secondaryDanger' : 'secondary'}
									onClick={closeModal}
								/>
								<Button
									className="ml-2"
									text={confirmBtnTxt}
									large
									intent={danger ? 'primaryDanger' : 'primary'}
									onClick={submitModel}
								/>
							</div>
						</>
					}
				</DialogWrap>
			)}
		</Dialog>
	);
};

export default ConfirmationModal;
