import { HttpService } from 'networking/HttpService';

export const PanoramaService = {
	upload: async (params, selectedfile) => {
        let query = '';

        if (params.buildingId) {
			query += `&buildingId=${params.buildingId}`;
		}

        if(params.panoramaId){
            query += `&panoramaId=${params.panoramaId}`;
        }

        const formData = new FormData();
        formData.append("file", selectedfile);
        const url = process.env.API_URL;
        const token = JSON.parse(localStorage.getItem('user')).jwt;

        const response = await fetch(`${url}/floorplans/panoramas?${query}`, {
            method: 'POST',
            headers: {
                    'Authorization': `Bearer ${token}`,
                },
            body: formData,
        });

        return response.json();
    }
};
