import { HttpService } from 'networking/HttpService';

export const RapidAlarmIntegrationsService = {
	async getAlarmPartners() {
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/partners`,
			});
		} catch (error) {
			throw error;
		}
	},
	async cellularConnection(partnerId, active) {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/alarms/partners/${partnerId}`,
				body: { active },
			});
		} catch (error) {
			throw error;
		}
	},
	async getCredentials(id) {
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/partners/${id}`,
			});
		} catch (error) {
			throw error;
		}
	},
	async submitCredentials(id, credentials) {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/alarms/partners/${id}`,
				body: credentials,
			});
		} catch (error) {
			throw error;
		}
	},
	getAlarmTypes: () =>
		HttpService({
			method: 'GET',
			route: `/alarms/partners/alarm-types`,
		}).catch(err => {
			throw err;
		}),
	deleteAlarmType: alarmTypeId =>
		HttpService({
			method: 'DELETE',
			route: `/alarms/partners/alarm-types/${alarmTypeId}`,
		}).catch(err => {
			throw err;
		}),
	saveAlarmTypes: (partnerId, alarmTypeData) =>
		HttpService({
			method: 'POST',
			route: `/alarms/partners/alarm-types`,
			body: { partnerId, partnerAlarmTypes: alarmTypeData },
		}),
	getBuildingMappings: id =>
		HttpService({
			method: 'GET',
			route: `/alarms/partners/${id}/building-mappings`,
		}),
	updateBuildingMappings: partnerBuildingMappings =>
		HttpService({
			method: 'POST',
			route: `/alarms/partners/building-mappings`,
			body: {
				partnerId: 1, // Only 911 cellular for now.
				partnerBuildingMappings,
			},
		}),
	getAlarmBuildingsZones: id =>
		HttpService({
			method: 'GET',
			route: `/alarms/partners/${id}/zones`,
		}),
	getTestConnectionData: id =>
		HttpService({
			method: 'GET',
			route: `/alarms/partners/${id}/test`,
		}),
	testAlarmConnection: id =>
		HttpService({
			method: 'POST',
			route: `/alarms/partners/${id}/test`,
		}),
};
