import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import {
	LoadingSpinner,
	SortableTable,
	TableCell,
	Checkbox,
	EmptyState,
	noResult,
	FileIcon,
	Button,
	Tooltip,
} from 'componentsV2';
import { DOCUMENT_TYPE, TYPES_DATA } from './types';
import { IMAGES_TYPES } from '../../';
import { Intent, Icon } from '@blueprintjs/core';
import { ToasterContext } from 'pages/App';
import { BinderService } from 'services';
import { BinderContentActionsList } from './BinderContentActionsList';
import { BinderModalsProvider, BinderModalsContext } from '../../';
import {
	HAS_OLD_SP_TP_CONTENT_EDIT_USER,
	HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER,
} from '../../consts.js';
import '../css/BinderDetails.scss';
import '../css/BinderContentTable.scss';

export const BinderContentTable = ({
	history,
	filteredContent,
	binderId,
	hasEditAccess,
	binderContent = [],
	viewMode,
	archiver,
	reloadBinderDetails,
}) => {
	const [selectedContentIds, setSelectedContentIds] = useState([]);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const toaster = useContext(ToasterContext);
	const { showDownloadModal } = useContext(BinderModalsContext);
	const permissions = useSelector(state => state.route.permissions);

	const selectContentId = content => {
		const id = parseInt(content.target.value);
		setSelectedContentIds(prevSelectedContentIds => {
			const isIdIncluded = prevSelectedContentIds.includes(id);
			const updatedIds = isIdIncluded
				? prevSelectedContentIds.filter(selectedId => selectedId !== id)
				: [...prevSelectedContentIds, id];
			setIsAllChecked(updatedIds.length === binderContent.length);
			return updatedIds;
		});
	};
	const handleCheckAll = () => {
		//VBContentId here is the PK of each row - contentId is not unique in this case
		const allIds = binderContent.map(item => item.VBContentId);
		setSelectedContentIds(isAllChecked ? [] : allIds);
		setIsAllChecked(!isAllChecked);
	};

	const getRedirectionPathToContent = content => {
		if (content.type !== DOCUMENT_TYPE) {
			const contentPath = TYPES_DATA.find(typeObj => typeObj.value === content.originalType)
				.path;
			return contentPath.includes('maps')
				? `/${contentPath}/${content.buildingId}/${content.contentId}`
				: `/${contentPath}/${content.contentId}`;
		}
	};

	const headers = archiver || viewMode ? ['Title', 'Type', ''] : ['Title', 'Type', 'Actions'];
	const hiddenIndex = [3, 4, 5, 6, 7, 8, 9, 10, 11];
	const binderContentRows = binderContent.map(row => {
		return {
			title: row.fileName,
			type: TYPES_DATA.find(typeObj => typeObj.value === row.type).label,
			actions: '',
			contentId: parseInt(row.contentId),
			id: parseInt(row.VBContentId),
			size: row.size,
			ext: row.ext,
			orderNumber: row.orderNumber,
			thumbnail: row.thumbnail,
			buildingId: row.building?.id,
			originalType: row.type,
			isOldSP: row.isOldSP,
		};
	});
	const headerRenders = {
		Title: headerTitle => (
			<div className="content-title-checkbox">
				{!archiver && !viewMode && (
					<Checkbox size="small" checked={isAllChecked} onChange={handleCheckAll} />
				)}
				<div>{headerTitle}</div>
			</div>
		),
	};

	const ContentTitle = ({
		obj,
		str,
		archiver,
		viewMode,
		selectedContentIds,
		selectContentId,
		showDownloadModal,
	}) => {
		const isImage = IMAGES_TYPES.includes(obj.ext.toLowerCase());

		const islinkEnabled = (type, buildingId) => {
			let permissionKey = null;
			switch (type) {
				case 'Map':
					permissionKey = 'maps_view';
					break;
				case 'Assessment':
					permissionKey = 'site_assess_view';
					break;
				case 'Call list':
					permissionKey = 'call_list_view';
					break;
				case 'Safety plan':
					permissionKey = 'safety_plans_view';
					break;
			}
			if (!permissionKey) {
				return true;
			}
			return checkPermission(permissionKey, permissions, buildingId);
		};

		const linkProps = islinkEnabled(obj.type, obj.buildingId)
			? {
					to:
						obj.type === DOCUMENT_TYPE
							? { pathname: '' }
							: { pathname: getRedirectionPathToContent(obj) },
					onClick:
						obj.type === DOCUMENT_TYPE
							? () =>
									showDownloadModal({
										...obj,
										id: obj.contentId,
										extension: obj.ext,
									})
							: null,
					target: obj.type === DOCUMENT_TYPE ? null : '_blank',
			  }
			: null;

		const titleText =
			obj.type === DOCUMENT_TYPE && obj.ext.toLowerCase() !== 'document'
				? `${str}.${obj.ext}`
				: str;

		return (
			<div className="content-title-checkbox">
				{!archiver && !viewMode && (
					<Checkbox
						size="small"
						checked={selectedContentIds.includes(obj.id)}
						key={`checkbox-${obj.id}`}
						value={obj.id}
						onChange={selectContentId}
					/>
				)}
				{isImage ? (
					<FileIcon sourceURL={obj.thumbnail} icon={'empty'} />
				) : (
					<FileIcon icon={obj.ext.toLowerCase()} />
				)}
				<TableCell
					text={
						linkProps && !obj.isOldSP ? (
							<NavLink exact {...linkProps}>
								<p className={`${obj.isOldSP ? ' binder-content-title' : ''}`}>
									{titleText}
								</p>
							</NavLink>
						) : obj.isOldSP ? (
							<Tooltip
								tooltipTitle="Viewing is disabled on outdated safety plans"
								theme="light"
							>
								<p className={`${obj.isOldSP ? ' binder-content-title' : ''}`}>
									{titleText}
								</p>
							</Tooltip>
						) : (
							<Tooltip
								tooltipContent={`This ${obj.type.toLowerCase()} is linked to a site you don't have permission on.`}
								theme="light"
							>
								<a
									className="link-disable"
									onClick={e => {
										e.target.click();
									}}
								>
									<p className={`${obj.isOldSP ? ' binder-content-title' : ''}`}>
										{titleText}
									</p>
								</a>
							</Tooltip>
						)
					}
					supportText={obj.size || ' '}
					firstRow={true}
					rightIcon={
						obj.isOldSP && (
							<Tooltip
								tooltipContent={
									<p className="sp_tp_text">
										{checkPermission('vb_edit', permissions, obj.buildingId)
											? HAS_OLD_SP_TP_CONTENT_EDIT_USER
											: HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER}
									</p>
								}
								theme="light"
							>
								<Icon className="light-warning" icon="warning-sign" />
							</Tooltip>
						)
					}
				/>
			</div>
		);
	};

	const renders = {
		title: (str, obj) => (
			<ContentTitle
				obj={obj}
				str={str}
				archiver={archiver}
				viewMode={viewMode}
				selectedContentIds={selectedContentIds}
				selectContentId={selectContentId}
				showDownloadModal={showDownloadModal}
			/>
		),
		actions: (str, obj) => {
			return (
				!archiver &&
				!viewMode && (
					<BinderContentActionsList
						binderContent={obj}
						history={history}
						binderId={binderId}
					/>
				)
			);
		},
	};
	const { showMultipleDeleteContentModal } = useContext(BinderModalsContext);

	const handleDragEnd = (newRows, draggedObj) => {
		const rowIds = newRows.map(row => row.id);
		binderContent.sort((a, b) => {
			const indexA = rowIds.indexOf(a.id);
			const indexB = rowIds.indexOf(b.id);
			return indexA - indexB;
		});

		const params = {
			ids: rowIds,
		};
		setIsLoading(true);
		BinderService.reorderContent(binderId, params)
			.then(response => {
				if (response.statusCode == 200) {
					const sortedTabTitle = draggedObj.title;
					toaster(
						'You have successfully changed the order of "' + sortedTabTitle + '"!',
						Intent.SUCCESS,
					);
					reloadBinderDetails(binderId);
				}
			})
			.catch(err => {
				const msg = err.error ? err.error.fields.ids : 'ERROR!';
				toaster(msg, Intent.DANGER);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return isLoading ? (
		<LoadingSpinner />
	) : (
		<>
			{filteredContent && !binderContent.length && (
				<div className="binder-content-search-empty-state">
					<EmptyState
						icon={noResult}
						header={'No content found.'}
						description={
							'Your search didn’t match any content. Please try again or upload new content.'
						}
					/>
				</div>
			)}
			{binderContent.length > 0 && (
				<>
					{selectedContentIds.length > 0 && (
						<>
							<BinderModalsProvider>
								<div className="bulk-action-container">
									<div className="left-side">
										<Button
											text={
												'Delete ' +
												selectedContentIds.length +
												(selectedContentIds.length > 1
													? ' contents'
													: ' content')
											}
											icon="trash"
											size="md"
											type="tertiary"
											intent="default"
											className="actionButtonElement"
											onClick={() =>
												showMultipleDeleteContentModal(
													selectedContentIds,
													binderId,
													binderContent,
												)
											}
										/>
									</div>
								</div>
							</BinderModalsProvider>
						</>
					)}
					<SortableTable
						customClass="binder-content-table"
						hiddenIndex={hiddenIndex}
						headers={headers}
						headerRenders={headerRenders}
						loadedData={binderContentRows}
						renders={renders}
						allowDrag={
							filteredContent || binderContent.length == 1 || archiver || viewMode
								? false
								: true
						}
						handleDragEnd={handleDragEnd}
						sortableField="orderNumber"
					/>
				</>
			)}
		</>
	);
};
