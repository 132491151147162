export const flipchartValidation = formData => {
	let isValid = true;
	const errors = {};

	if (formData.flipchartValue === null) {
		isValid = false;
		errors.flipchartValue = 'Flipchart is required';
	}

	return {
		isValid,
		errors,
	};
};
