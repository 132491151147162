import React from 'react';
import PropTypes from 'prop-types';
import { logoNavigate360 } from 'assets/icons';

/**
 * Nav360 EMS Maintenance Mode
 */

export const MaintenanceMode = ({ children }) => (
	<div className="maintenance-mode">
		<img src={logoNavigate360} alt="Navigate360 Logo" className="navigate-logo" width="300" />
		{children}
	</div>
);

MaintenanceMode.propTypes = {
	children: PropTypes.any,
};
