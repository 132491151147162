import React from "react";
import { Badge } from "./";
import './css/BadgePattern.scss';

const sizes = ['sm', 'md', 'lg', 'xl'];
const types = [
  'Gray', 'Primary', 'Error', 'Warning', 'Success',
  'BlueDark', 'Indigo', 'Purple', 'Fuchsia', 'Pink', 'Orange', 'OrangeDark'
];

export const BadgePattern = () => {
    return (
        <div className="BadgePattern__Container">
          {types.map((type) => (
            <div className="BadgePattern__Row" key={type}>
              {sizes.map((size) => (
                <div className="BadgePattern__Grid">
                    <div>{size} / {type}</div>
                    <Badge
                        key={`${size}-${type}`}
                        type={type}
                        size={size}
                        text="Badge Text"
                        hasLeadIcon={true}
                        hasCloseIcon={true}
                    />
                    
                </div>
              ))}
            </div>
          ))}
        </div>
      );
}
