import React, { useState } from "react";
import { FeaturedIcon } from "./";
import './css/FeaturedIconPattern.scss';
import { Dropdown } from "components/Dropdown";

const sizes = ['xsm', 'sm', 'md', 'lg', 'xl'];
const types = ['Default', 'Gray', 'Primary', 'Error', 'Warning', 'Success', 'OutlinePrimary', 'OutlineGray'];

const icons = [
    {value: 'circle', label: 'circle'},
    {value: 'error', label: 'error'},
    {value: 'tick', label: 'tick'},
    {value: 'add', label: 'add'},
    {value: 'help', label: 'help'},
    {value: 'cross', label: 'cross'},
    {value: 'full-circle', label: 'full circle'},
];

const shapes = [
    { value: 'circleIcon' , label: 'Circle'},
    { value: 'roundedIcon' , label: 'Rounded'}
];

export const FeaturedIconPattern = () => {

    const [optionIcon, setOptioIcon] = useState(icons[0]);
    const [optionShape, setOptionShape] = useState(shapes[0]);

    const handleChangeIcon = item => {
        setOptioIcon(item);
    }

    const handleChangeShape = item => {
        setOptionShape(item);
    }

    return (
        <div className="FeaturedIconPattern__Container">


            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h4 className='mb-2'>Featured Icon Options</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <Dropdown
                                        className="mt-3"
                                        label="Icon"
                                        placeholder="Select icon"
                                        value={optionIcon}
                                        options={icons}
                                        onChange={handleChangeIcon}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Dropdown
                                        className="mt-3"
                                        label="Shape"
                                        placeholder="Select Shape"
                                        value={optionShape}
                                        options={shapes}
                                        onChange={handleChangeShape}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="FeaturedIconPattern__Row" key='header'>
                <div className="FeaturedIconPattern__Grid" style={ {width: '100px'} }></div>
                {sizes.map((size) => (
                    <div className="FeaturedIconPattern__Grid">
                        <div>{size}</div>
                    </div>
                ))}
            </div>
            
            {types.map((type) => (
            <div className="FeaturedIconPattern__Row" key={type}>
                <div className="FeaturedIconPattern__Grid">{type}</div>
                {sizes.map((size) => (
                    <div className="FeaturedIconPattern__Grid">
                        <FeaturedIcon icon={optionIcon.value} type={type} size={size} shape={optionShape.value}></FeaturedIcon>
                    </div>
                ))}
            </div>
            ))}
        </div>
      );
}
