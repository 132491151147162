import React, { useEffect, useReducer, useState } from 'react';
import { NoResultFound } from 'components/NoResultFound';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { SearchBar } from 'components/SearchBar';
import { Button } from 'components/Button';
import { NoResultFoundIcon, addUser } from 'assets/icons';
import { FirstRespondersService } from 'services/FirstRespondersService';
import FirstRespondersTable from './Components/FirstRespondersTable';
import AddFirstResponderModal from './Components/AddFirstResponderModal';
import UserManagement from '..';
import { BuildingsService } from 'services/BuildingsService';
import { checkPermission, filterSitesPerPermissionCode } from 'utilities/permissions';
import ActionsAddButton from 'components/ActionsAddButton';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingSpinner } from 'componentsV2';

const filterationDataReducer = (filterationData, action) => {
	const dispatch = useDispatch();

	switch (action.type) {
		case 'buildingId':
			var sites = [];
			action.payload.map(site => {
				sites.push(site.value);
			});
			filterationData.buildingId = sites;
			filterationData.selectedSites = action.payload;
			dispatch({
				type: 'SET_SELECTED_SITES',
				payload: action.payload,
			});
			filterationData.page = 1;
			break;
		case 'name':
			filterationData.name = action.payload;
			filterationData.page = 1;
			break;
		default:
			filterationData[action.type] = action.payload;
			break;
	}
	return {
		page: filterationData.page,
		perPage: filterationData.perPage,
		buildingId: filterationData.buildingId,
		selectedSites: filterationData.selectedSites,
		name: filterationData.name,
		sortKey: filterationData.sortKey,
		sortDir: filterationData.sortDir,
	};
};

const FirstResponders = () => {
	const [loading, setLoading] = useState(true);
	const [modalToShow, setModalToShow] = useState(false);
	const [FirstRespondersList, setFirstRespondersList] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [sitesList, setSitesList] = useState([]);
	const [emptyFirstResponder, setEmptyFirstResponder] = useState(false);
	const loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
	const permissions = useSelector(state => state.route.permissions);
	const userEditPermission = checkPermission('user_edit', permissions);
	const [primarySite, setPrimarySite] = useState([
		{
			label: loggedUser.primaryBuilding.name,
			value: loggedUser.primaryBuilding.id,
			id: loggedUser.primaryBuilding.id,
		},
	]);

	const [filterationData, dispatchFilterationData] = useReducer(filterationDataReducer, {
		page: 1,
		perPage: 10,
		buildingId: [],
		selectedSites: [],
		name: '',
		sortKey: 'name',
		sortDir: 'asc',
	});

	const [filteredSitesList, setFilteredSitesList] = useState({
		data: [],
		loaded: false,
	});

	useEffect(() => {
		dispatchFilterationData({ type: 'buildingId', payload: [] });
	}, []);

	const getSites = () => {
		BuildingsService.getAll(false, true).then(serviceBuildings => {
			setSitesList(serviceBuildings);
			const filteredSites = filterSitesPerPermissionCode(
				'user_edit',
				permissions,
				serviceBuildings,
			);
			const filteredObject = { data: filteredSites, loaded: true };
			setFilteredSitesList(filteredObject);
		});
	};

	useEffect(() => {
		getSites();
		BuildingsService.getAll(false, true).then(sites => {
			setSitesList(sites);
		});
	}, []);

	const fetchFirstResponders = () => {
		FirstRespondersService.getAll(filterationData)
			.then(resp => {
				if (resp.statusCode == 200) {
					setFirstRespondersList(resp.data);
					setTotalRows(resp._metadata.totalRecords);
				}
				setLoading(false);
			})
			.catch(() => setLoading(false));
	};

	useEffect(() => {
		FirstRespondersService.getAll({
			buildingId: filterationData.buildingId,
		})
			.then(res => {
				if (res._metadata.totalRecords == 0) {
					setEmptyFirstResponder(true);
				} else {
					setEmptyFirstResponder(false);
				}
				setLoading(false);
			})
			.catch(() => setLoading(false));
	}, [filterationData.buildingId]);

	useEffect(() => {
		setLoading(true);
		fetchFirstResponders();
	}, [
		filterationData.page,
		filterationData.perPage,
		filterationData.selectedSites,
		filterationData.name,
	]);

	useEffect(() => {
		fetchFirstResponders();
	}, [filterationData.sortKey, filterationData.sortDir]);

	const onCloseModal = () => {
		setLoading(false);
		setModalToShow(false);
	};

	return (
		<UserManagement defaultSelectedTab="first-responders">
			{modalToShow === 'AddFirstResponderModal' && (
				<AddFirstResponderModal
					isOpen
					onCloseModal={onCloseModal}
					loggedUser={loggedUser}
					sitesFRList={filteredSitesList.data}
					selectedUserSites={
						filterationData.selectedSites.length != 0
							? filterationData.selectedSites
							: primarySite
					}
					fetchFirstResponders={fetchFirstResponders}
				/>
			)}
			<div className="row">
				<div className="col-md-5">
					<SitesDropdownMultiSelect
						value={filterationData.selectedSites}
						onChange={sites =>
							dispatchFilterationData({ type: 'buildingId', payload: sites })
						}
						allowSelectAll={false}
						visibleOptions={1}
						sitesList={sitesList}
						labelState={false}
					/>
				</div>
				<div className="col-md-2" />
				<div className="col-md-5 search-bar-input">
					<SearchBar
						placeholder="Search first responder"
						onSearch={name => dispatchFilterationData({ type: 'name', payload: name })}
						value={filterationData.name}
					/>
				</div>
				<div className="col-md-12 mt-3">
					<div className="float-right">
						{userEditPermission && !emptyFirstResponder && (
							<Button
								text={<ActionsAddButton text="Add First Responder" />}
								intent="primary"
								onClick={() => setModalToShow('AddFirstResponderModal')}
							/>
						)}
					</div>
				</div>
				<div className="col-md-12">
					{loading && <LoadingSpinner className="mt-3" />}
					{!loading && (
						<>
							{emptyFirstResponder && (
								<NoResultFound
									header="No first responders were added yet"
									icon={addUser}
								>
									{userEditPermission && (
										<Button
											text={<ActionsAddButton text="Add First Responder" />}
											intent="primary"
											onClick={() => setModalToShow('AddFirstResponderModal')}
										/>
									)}
								</NoResultFound>
							)}
							{!emptyFirstResponder && (
								<>
									{FirstRespondersList.length == 0 ? (
										<NoResultFound
											header="No first responders were found"
											icon={NoResultFoundIcon}
										/>
									) : (
										<FirstRespondersTable
											list={FirstRespondersList}
											filterationData={filterationData}
											dispatchFilterationData={dispatchFilterationData}
											totalRows={totalRows}
										/>
									)}
								</>
							)}
						</>
					)}
				</div>
			</div>
		</UserManagement>
	);
};

export default FirstResponders;
