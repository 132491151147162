import React, { useEffect, useState } from 'react';
import { PageHeader } from 'components/PageHeader';
import { LoadingSpinner } from 'componentsV2';
import { FlipchartService } from 'services/FlipchartService';
import { AddUpdateFlipchartModal } from './components/AddUpdateFlipchartModal';
import { Filteration, FlipchartsTable, TabPreviewModal } from './components';
import { checkPermission } from 'utilities/permissions';
import useSelectedSites from 'hooks/useSelectedSites';
import { useSelector } from 'react-redux';
import { EmptyStatuses } from './components/EmptyStatuses';
import { CreateBtn } from './components/CreateBtn';
import { decodeToken } from 'utilities';
import './css/Flipchart.scss';

export const Flipchart = props => {
	const [loading, setLoading] = useState(true);

	const [flipchartsList, setFlipchartsList] = useState([]);
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('flipcharts_edit', permissions);
	const defaultSelectedSites = useSelectedSites();
	const [filterationData, setFilterationData] = useState({
		includeArchived: 1,
		title: '',
		buildingIds: [],
		statuses: [],
	});
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEmptyStatus, setShowEmptyStatus] = useState(false);
	const [noDataAdded, setNoDataAdded] = useState(false);
	const [modalToShow, setModalToShow] = useState('');
	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const dist = token ? decodeToken(token) : '';
	const districtId = dist ? dist.dist : null;
	const [tableIsLoading, setTableIsLoading] = useState(true);

	useEffect(() => {
		setTableIsLoading(true);
		getFlipchartList();
	}, [filterationData]);

	const getFlipchartList = async () => {
		console.log(filterationData);
		FlipchartService.getAll(filterationData)
			.then(async response => {
				if (response.statusCode === 200) {
					const data = response.data;
					const isEmpty = data.length === 0;
					setNoDataAdded(
						isEmpty &&
							filterationData.statuses.length === 0 &&
							filterationData.buildingIds.length === 0 &&
							(filterationData.title === null || filterationData.title === ''),
					);
					setShowEmptyStatus(isEmpty);
					const list = await data.map(flipchart => {
						const flipchartEditPermission = checkPermission(
							'flipcharts_edit',
							permissions,
							flipchart.buildingId ? flipchart.buildingId : districtId,
						);
						const flipchartViewPermission = checkPermission(
							'flipcharts_view',
							permissions,
							flipchart?.buildingId,
						);
						return {
							...flipchart,
							editPermission: flipchartEditPermission,
							viewPermission: flipchartViewPermission,
						};
					});
					setFlipchartsList(list);
				}
			})
			.catch(error => {
				console.error('Error fetching flipchart list:', error);
			})
			.finally(() => {
				setLoading(false);
				setTableIsLoading(false);
			});
	};

	const createBtn = editPermission && (!showEmptyStatus || (showEmptyStatus && !noDataAdded)) && (
		<CreateBtn onClick={() => showAddFlipchartModal()} />
	);

	const showAddFlipchartModal = () => {
		setShowAddModal(true);
		setModalToShow('AddUpdateFlipchartModal');
	};

	const onCloseModal = () => {
		setShowAddModal(false);
		setModalToShow('');
	};

	return (
		<>
			<div className="flipchart-container">
				<PageHeader
					title="Flipcharts"
					subtitle="Create and access emergency procedures."
					customItem={() => createBtn}
				/>
				{loading && <LoadingSpinner />}
				{!loading && (
					<>
						{!noDataAdded && (
							<Filteration
								defaultSelectedSites={defaultSelectedSites}
								filterationData={filterationData}
								setFilterationData={setFilterationData}
								editPermission={editPermission}
								getFlipchartList={getFlipchartList}
							/>
						)}
						{showEmptyStatus && (
							<EmptyStatuses
								filterationData={filterationData}
								noDataAdded={noDataAdded}
								editPermission={editPermission}
								addFlipchartAction={showAddFlipchartModal}
							/>
						)}
						{!showEmptyStatus && (
							<>
								{tableIsLoading && <LoadingSpinner className="mt-3" />}
								{!tableIsLoading && (
									<FlipchartsTable
										list={flipchartsList}
										history={props.history}
										editPermission={editPermission}
										getFlipchartList={getFlipchartList}
									/>
								)}
							</>
						)}
					</>
				)}
			</div>

			{modalToShow == 'AddUpdateFlipchartModal' && (
				<AddUpdateFlipchartModal
					isOpen={showAddModal}
					onCloseModal={onCloseModal}
					selectedSites={filterationData.buildingIds}
					mode="add"
					history={props.history}
				/>
			)}
		</>
	);
};
