import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputWrapper from '../InputWrapper/InputWrapper.jsx';
import './css/TinymceEditor.scss';
import { Editor } from '@tinymce/tinymce-react';
import { LoadingSpinner } from 'componentsV2';

export const TinymceEditor = ({
	label = null,
	placeholder = 'Enter a value',
	value = null,
	onChange = null,
	disabled = false,
	width = null,
	error = false,
	errorMessage = null,
	hasHintText = false,
	hintText = null,
	isRequired,
	labelState,
	setupProps,
	editorWidth,
	editorHeight,
	devTool = '',
	...props
}) => {
	const [loading, setLoading] = useState(true);
	const setupFunc = editor => {
		!error &&
			editor.on('focus', e => {
				e.target.editorContainer.classList.add('tox--focus');
			});

		editor.on('blur', e => {
			e.target.editorContainer.classList.remove('tox--focus');
		});

		error &&
			editor.on('focus', e => {
				e.target.editorContainer.classList.add('tox--focus-error');
			});

		editor.on('blur', e => {
			e.target.editorContainer.classList.remove('tox--focus-error');
		});

		editor.on('init', function(e) {
			error && e.target.editorContainer.classList.add('tox--error');
			disabled && e.target.editorContainer.classList.add('tox--disabled');
			disabled ? (editor.getBody().style.backgroundColor = '#F4F5F5') : '';
		});

		setupProps(editor);
	};

	if (typeof onChange !== 'function')
		throw new Error("'onChange' is not defined for this text area");

	return (
		<>
			{loading && <LoadingSpinner />}
			<InputWrapper
				label={label}
				placeholder={placeholder}
				isRequired={isRequired}
				error={error}
				errorMessage={errorMessage}
				hasHintText={hasHintText}
				hintText={hintText}
				labelState={labelState}
			>
				<div className="Editor__Wrapper">
					<Editor
						disabled={disabled}
						{...props}
						value={value}
						init={{
							init_instance_callback: function(editor) {
								setLoading(false);
							},
							content_style:
								'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }' +
								'.variable-highlight{background: rgba(19, 124, 189, 0.2)}' +
								'.diff-add{background: #CCE5E9}' +
								'.diff-remove{background: #FDE8E8}' +
								'.variable-value-highlight{background: rgbA(217, 130, 43, 0.4)}',
							height: editorHeight,
							width: editorWidth,
							menubar: false,
							placeholder: placeholder,
							toolbar: false,
							statusbar: false,
							branding: false,
							resize: false,
							nonbreaking_force_tab: true,
							plugins: [
								'advlist autolink lists link image charmap print preview anchor',
								'searchreplace visualblocks code fullscreen',
								'insertdatetime media table paste code table ',
								'nonbreaking',
							],
							fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
							toolbar: !disabled
								? 'undo redo formatselect bold italic underline fontselect fontsizeselect forecolor backcolor cut copy ' +
								  'alignleft aligncenter alignright alignjustify bullist numlist table  ' +
								  devTool +
								  ' imageModal'
								: false,
							setup: setupFunc,
						}}
					/>
				</div>
			</InputWrapper>
		</>
	);
};

TinymceEditor.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.bool,
	errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	hasHintText: PropTypes.bool,
	hintText: PropTypes.string,
	isRequired: PropTypes.bool,
	labelState: PropTypes.bool,
	editorWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	editorHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
