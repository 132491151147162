
import React, { useState, useContext, useReducer, useEffect } from 'react';
import NormalModal from "components/Modals/NormalModal";
import UnsavedChangesModal from "components/Modals/UnsavedChangesModal";
import { ToasterContext } from "pages/App";
import { Intent } from '@blueprintjs/core';
import ExistingContactsTable from "./ExistingContactsTable";
import { ContactService } from 'services/ContactService';
import { SORTING_DIR } from 'utilities/constants';

const filterationDataReducer = (filterationData, action) => {
	switch (action.type) {
		default:
			filterationData[action.type] = action.payload;
			break;
	}
	return {
		page: filterationData.page,
		perPage: filterationData.perPage,
		selectedSites: filterationData.selectedSites,
		combineSearch: filterationData.combineSearch,
		sortKey: filterationData.sortKey,
		sortDir: filterationData.sortDir,
	};
};

const ExistingContactsModal = (props) => {
	const {
		onCloseModal, 
		selectedCallList,
		selectedSites,
		fetchMainContacts
	} = props;
	const [disableBtns, setDisableBtns] = useState(true);
	const [loading, setLoading] = useState(false);
	const [changesOccuredFlag, setChangesOccuredFlag] = useState(0)
	const [showConfirmPopModal, setShowConfirmPopModal] = useState(false)
	const context = useContext(ToasterContext);
	const [selectedRows, setSelectedRows] = useState([]);
	const [backBtn, setBackBtn] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [newSelectedIds, setNewSelectedIds] = useState([]);
	const [allContactsList, setAllContactsList] = useState([])
	const callListId = selectedCallList.value;
	const [preAssignedContacts, setPreAssignedContacts] = useState([]);

	const back = () => {
		setBackBtn(false)
	
	};
	const closeConfirmPopUpModal = () => {
		setShowConfirmPopModal(false)
	} 

	const [filterationData, dispatchFilterationData] = useReducer(
		filterationDataReducer,
		{
			page: 1,
			perPage: 10,
			selectedSites: [],
			combineSearch: '',
			sortKey: 'name',
			sortDir: SORTING_DIR.ASC,
		},
	);

	const fetchContacts = () => {
		ContactService.getAllExistingContacts(callListId, filterationData).then(resp => {
			if (resp.statusCode === 200) {
				const distArray = [];
				resp.data.forEach(item => (item.isLinkedToCallList) ? distArray.push(item.id) : null);
				setPreAssignedContacts(distArray);
				setTotalRows(resp._metadata.totalRecords)
				setAllContactsList(resp.data);
			}
		}).catch(() => setLoading(false))
	};


	const deleteTableRows = (index)=>{
		const rows = [...selectedRows];
		rows.splice(index, 1);
		setSelectedRows(rows);
		if (rows.length == 0) {
			setDisableBtns(true)
		} else {
			setDisableBtns(false)
		}
		const distArray = [...preAssignedContacts];
		rows.forEach(item => distArray.push(item.id));
		setNewSelectedIds(distArray);
		setChangesOccuredFlag(1);
}

	const handleSubmit = (event) => {
		setLoading(true);
		ContactService.addExistingContacts(selectedRows, selectedCallList.value).then(response => {
			setLoading(false);
			context("Successfully created new contact(s).", Intent.SUCCESS);
			fetchMainContacts()
			onCloseModal();
		}).catch(error => {
			setLoading(false);
			if (error.statusCode == 400) {
				context("Failed to create some contact(s).", Intent.DANGER);
			}
		})
	};
	
	useEffect(() => {
		//check if selected sites has Contacts
		if (selectedRows.length == 0) {
			setDisableBtns(true)
		} else {
			setDisableBtns(false)
		}
		setLoading(true);
		fetchContacts()
		setLoading(false);
	}, [filterationData, callListId])

	const cancelBtnClickHandler = () => {
		if (changesOccuredFlag == 1) {
			setShowConfirmPopModal(true)
		} else {
			onCloseModal();
		}
	}

	const selectRow = {
		mode: 'checkbox',
		clickToSelect: true,
		onSelect: (row, isSelect) => {
			setChangesOccuredFlag(1);
			if (selectedRows.length == 0) {
				setDisableBtns(false);
			}
			if (isSelect) {
				setSelectedRows([...selectedRows, row]);
				const distArray = [];
				[...selectedRows, row].forEach(item => distArray.push(item.id));
				setNewSelectedIds(distArray);

			} else {
				setSelectedRows(selectedRows.filter(selectedRow => {
					return selectedRow.id != row.id
				}))

				setNewSelectedIds(newSelectedIds.filter(selectedRow => {
					return selectedRow != row.id
				}))	
			}
	
		},

		onSelectAll: (isSelect, rows, e) => {
			setChangesOccuredFlag(1);
			if (isSelect) {
				const distArray = [];
				rows.forEach(row => {
					[...selectedRows, row].forEach(item => distArray.push(item.id));
					selectedRows.push(row);

				});
				setSelectedRows([...selectedRows]);
				setNewSelectedIds(distArray);				
			} else {
				setSelectedRows([])
				setNewSelectedIds([...preAssignedContacts])
			}
		},
		nonSelectable: preAssignedContacts,
		selected: [...preAssignedContacts, ...newSelectedIds]

	};

	return (
	<>
		<NormalModal
			setModalToShow="CallListBulkContactsModal"
			onConfirmAction={handleSubmit}
			onCancelAction={cancelBtnClickHandler}
			header="Add Contacts from EMS"
			confirmBtnTitle='Add'
			cancelBtnTitle="Cancel"
			confirmBtnDisabled={disableBtns}
			showBackArrow={backBtn}
			backArrowAction={back}
			className='existing-contacts-modal'
			onCloseAction={cancelBtnClickHandler}>

			<ExistingContactsTable
				contactsList={allContactsList}
				totalRows={totalRows}
				selectRow={selectRow}
				selectedRows={selectedRows}
				selectedCallList={selectedCallList}
				selectedSites={selectedSites}
				deleteTableRows={deleteTableRows}
				newSelectedIds={newSelectedIds}
				dispatchFilterationData={dispatchFilterationData}
				filterationData={filterationData}
				loading={loading}
			>
			</ExistingContactsTable>
			
		</NormalModal>
		{showConfirmPopModal && ( 
			<UnsavedChangesModal
				onConfirmAction={onCloseModal}
				onCancelAction = {closeConfirmPopUpModal}
				confirmBtnTitle = 'Continue'
				backBtnTitle = 'Cancel'
				header = 'Unsaved changes'
				message = 'The changes you have made have not been saved. Do you want to continue?'
			/>
		)}
	</>)
}

export default ExistingContactsModal ;