import React, { useEffect, useState, useContext } from 'react';
import {
	CardContainer,
	Button,
	Breadcrumb,
	PageHeader,
	ActionsButton,
	FeaturedIcon,
	ToasterContext,
	Intent,
	Badge,
	Callout,
	ConfirmationModal,
	EmptyState,
	ScrollTopButton,
} from 'componentsV2';
import { emptyFiles } from 'componentsV2/EmptyState/icons';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from 'react-redux';
import InputWrapper from 'componentsV2/InputWrapper/InputWrapper';
import '../styles.scss';
import { formatDate } from 'utilities/dates';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { AssessTemplatesService } from 'services';
import { flags } from 'utilities';
import { EditTemplateDetailsModal } from './EditTemplateDetailsModal';
import { Template } from './Template';
import { AddSummaryModal } from '../../ViewAssessment/components/AddSummaryModal';
import { TemplateSectionModal } from './TemplateSectionModal';

const EditTemplateDetails = ({ title, value, description }) => (
	<div className="template-details-container">
		<InputWrapper label={title} labelState={false}>
			{title === 'Status' &&
				(value === 'Published' ? (
					<Badge type="Success" text="Published" size="md" />
				) : (
					<Badge type="Gray" text="Not Published" size="md" />
				))}
			{title !== 'Status' && <div className="value-container">{value}</div>}
			{description && (
				<div
					dangerouslySetInnerHTML={{
						__html: description,
					}}
					className="value-container"
				/>
			)}
		</InputWrapper>
	</div>
);

const TemplateActions = ({ templateActions, setOpenModal }) => (
	<ActionsButton elementSelector={<Button type="tertiary" size="md" icon="more" />}>
		{templateActions.map((action, index) => (
			<Button
				large
				text={action.label}
				icon={action.icon}
				size="md"
				key={index}
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => {
					setOpenModal(action.onModalOpen);
				}}
			/>
		))}
	</ActionsButton>
);

export const TemplateDetails = ({ history, location }) => {
	const templateData = location?.state?.data;
	const isCoreSite = location?.state?.isCoreSite;
	const [loading, setLoading] = useState(true);
	const [editTemplateDetails, setEditTemplateDetails] = useState(null);
	const [openModal, setOpenModal] = useState(null);
	const [modalLoading, setModalLoading] = useState(false);
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('site_assess_edit', permissions);
	const isSysAdmin = permissions?.isSysAdmin;
	const toaster = useContext(ToasterContext);

	// Template is view-only if not on core site and templateData.isCoreTemplate
	const {
		isOn,
		names: { SITE_IS_TEMPLATE_CORE },
	} = flags;
	const isViewOnly = !isOn(SITE_IS_TEMPLATE_CORE) && templateData.isCoreTemplate;

	const handleCloseModal = () => {
		setOpenModal(null);
	};
	const breadCrumbs = [
		{ label: 'Templates', url: '/assessV2/templates' },
		{
			label: 'Assess template editor',
		},
	];
	const templateActions = [
		{
			label: 'Add main section',
			icon: 'add',
			onModalOpen: 'add_main_section',
		},
		{ label: 'Edit summary', icon: 'edit', onModalOpen: 'edit_summary' },
		{
			label: `${editTemplateDetails?.published ? 'Unpublish' : 'Publish'}`,
			icon: `${editTemplateDetails?.published ? 'th-disconnect' : 'book'}`,
			onModalOpen: 'publish_unpublish',
		},
	];

	useEffect(() => {
		getTemplatesData();
	}, []);

	const onSubmitMainSection = data => {
		AssessTemplatesService.addMainSection(templateData?.id, data?.title, data?.instructions)
			.then(() => {
				getTemplatesData();
				toaster(
					`You have successfully added this section.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
				);
			})
			.catch(err => {
				console.log('err', err);
				toaster(
					`There was an error adding this section. Please try again!`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				handleCloseModal();
			});
	};
	const getTemplatesData = () => {
		setLoading(true);
		AssessTemplatesService.getTemplateData(templateData?.id)
			.then(res => {
				setEditTemplateDetails(res?.data);
			})
			.catch(err => console.log(err))
			.finally(() => {
				setLoading(false);
			});
	};

	const submitTemplateSummary = summary => {
		AssessTemplatesService.submitTemplateSummary(templateData?.id, summary)
			.then(() => {
				getTemplatesData();
				toaster(
					`You have successfully edited this summary.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
				);
			})
			.catch(err => {
				console.log('err', err);
				toaster(
					`There was an error adding summary. Please try again!`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				handleCloseModal();
			});
	};

	const EditModalButton = (
		<Button
			text="Edit"
			type="ghostPrimary"
			onClick={() => {
				setOpenModal('edit_template_details');
			}}
		/>
	);

	const checkUserPermissionOptions = (value1, value2) => {
		// View-only site
		if (isViewOnly) {
			return value2;
		}
		// If not view-only site, check user perms
		else if (isSysAdmin || editPermission) {
			return value1;
		}
		// View-only user
		else {
			return value2;
		}
	};

	const onClickPublishUnpublishTemplate = () => {
		setModalLoading(true);
		AssessTemplatesService.publishUnpublishTemplate(
			editTemplateDetails?.id,
			!editTemplateDetails?.published,
		)
			.then(() => {
				history.push('/assessV2/templates');
				toaster(
					`You have successfully ${
						editTemplateDetails.published ? 'unpublished' : 'published'
					} ${editTemplateDetails?.name}.`,
					editTemplateDetails.published ? Intent.NONE : Intent.SUCCESS,
					<FeaturedIcon
						icon={editTemplateDetails?.published ? 'info-sign' : 'tick'}
						size="md"
						type={editTemplateDetails?.published ? 'Gray' : 'Success'}
					/>,
				);
			})
			.catch(error => {
				console.log(error);
				toaster(
					`There was an error ${
						editTemplateDetails.published ? 'unpublishing' : 'publishing'
					} the assessment. Please try again.`,
					Intent.DANGER,
				);
			})
			.finally(() => {
				handleCloseModal();
				setModalLoading(false);
			});
	};

	return (
		<>
			<ScrollTopButton pageLevel />
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="Assessment_Template__Details_Container">
					<div className="top-nav">
						<Breadcrumb items={breadCrumbs} />
						<Button
							text={checkUserPermissionOptions('Save and exit', 'Exit')}
							onClick={() => {
								history.push(`/assessV2/templates`);
							}}
						/>
					</div>
					{editTemplateDetails.isCoreTemplate === true && !isViewOnly && (
						<div className="callout">
							<Callout
								icon={
									<FeaturedIcon
										icon="error"
										type="Primary"
										size="md"
										shape="circleIcon"
									/>
								}
								className="Callout_Primary"
								text="Note that these are core templates. Edited templates will not pass down edits to any assessments in progress or completed."
							/>
						</div>
					)}
					<PageHeader
						title={editTemplateDetails?.name}
						subtitle={editTemplateDetails?.state}
						customItem={() =>
							checkUserPermissionOptions(
								<TemplateActions
									setOpenModal={setOpenModal}
									templateActions={templateActions}
								/>,
								null,
							)
						}
					/>
					{editTemplateDetails?.summary && (
						<div className="template-summary-container">
							<span className="summary-title">Summary</span>
							<div
								className="summary-text"
								dangerouslySetInnerHTML={{
									__html: editTemplateDetails?.summary,
								}}
							/>
						</div>
					)}
					<div className="edit-details-main-container">
						{editTemplateDetails.templateSections.length === 0 ? (
							<EmptyState
								header="Add a main section to begin creating your template"
								icon={emptyFiles}
								type="primaryDefault"
								showCircles={false}
							>
								<Button
									text="Add main section"
									size="sm"
									onClick={() => {
										setOpenModal('add_main_section');
									}}
									icon="plus"
								/>
							</EmptyState>
						) : (
							<Template
								templateData={editTemplateDetails}
								getTemplatesData={getTemplatesData}
								isViewOnly={isViewOnly || !(isSysAdmin || editPermission)}
							/>
						)}
						<div className="edit-details-sub-container">
							<CardContainer
								title="Details"
								rightAction={checkUserPermissionOptions(EditModalButton, null)}
							>
								<div className="details-grid-container">
									<EditTemplateDetails
										key="State"
										title="State"
										value={editTemplateDetails?.state}
									/>

									<EditTemplateDetails
										key="School Year"
										title="School Year"
										value={editTemplateDetails?.year}
									/>

									<EditTemplateDetails
										key="Status"
										title="Status"
										value={
											editTemplateDetails?.published
												? 'Published'
												: 'Not Published'
										}
									/>

									<EditTemplateDetails
										key="Date Created"
										title="Date Created"
										value={formatDate(
											editTemplateDetails?.createdDate,
											'MM/dd/yyyy',
										)}
									/>
									<EditTemplateDetails
										key="Proprietary"
										title="Proprietary"
										value={editTemplateDetails?.isProprietary ? 'Yes' : 'No'}
									/>
								</div>
								<div className="details-sub-container">
									<EditTemplateDetails
										key="recipients"
										title="Recipients for notification upon assessment completion"
										value={editTemplateDetails?.emails || 'N/A'}
									/>
									<EditTemplateDetails
										key="Description"
										title="Description"
										description={editTemplateDetails?.notes || 'N/A'}
									/>
								</div>
							</CardContainer>
						</div>
					</div>
					{openModal === 'edit_template_details' && (
						<EditTemplateDetailsModal
							onClose={handleCloseModal}
							templateData={editTemplateDetails}
							refreshTemplatesData={getTemplatesData}
							isCoreTemplate={isOn(SITE_IS_TEMPLATE_CORE)}
						/>
					)}
					{openModal === 'edit_summary' && (
						<AddSummaryModal
							isOpen
							submitSummary={submitTemplateSummary}
							handleCloseModal={handleCloseModal}
							summaryData={editTemplateDetails?.summary}
							modalTitle="Edit Summary"
							confirmBtnText="Update"
							modalSubTitle={editTemplateDetails?.name}
						/>
					)}
					{openModal === 'add_main_section' && (
						<TemplateSectionModal
							isOpen
							inputLabelText="Section Title"
							inputLabelPlaceholderText="Add section title"
							textAreaLabel="Directions"
							textAreaPlaceholderText="Enter directions"
							sectionModalTitle="Add a Section"
							confirmBtnText="Add"
							sectionSubTitle={editTemplateDetails?.name}
							submitSection={onSubmitMainSection}
							handleCloseModal={handleCloseModal}
						/>
					)}
					{openModal === 'publish_unpublish' && (
						<ConfirmationModal
							isOpen
							loading={modalLoading}
							title={`Are you sure you want to ${
								editTemplateDetails.published ? 'unpublish' : 'publish'
							} ${editTemplateDetails.name}?`}
							subtitle={`You can ${
								editTemplateDetails.published ? 'publish' : 'unpublish'
							} it at a later time.`}
							showCloseButton={false}
							onClose={handleCloseModal}
							cancelBtn={{
								title: 'No, go back',
								onClick: handleCloseModal,
							}}
							confirmBtn={{
								title: editTemplateDetails.published
									? 'Yes, unpublish it'
									: 'Yes, publish it',
								onClick: onClickPublishUnpublishTemplate,
								type: 'primaryDefault',
							}}
						/>
					)}
				</div>
			)}
		</>
	);
};
