import React from 'react';
import { useRollCallChat } from './useRollCallChat';
import {
	ChatContainer,
	ChatForm,
	ChatMessage,
	ChatMessageDate,
	ChatMessagesContainer,
	ChatRoom,
	ChatRoomItem,
	EmptyState,
	messagesEmptyState,
	LoadingSpinner,
} from 'componentsV2';

export const RollCallChat = ({ alarm }) => {
	const {
		chatData,
		isToday,
		loggedUser,
		isRespondTypeAdmin,
		limitMessageLength,

		// Everyone
		everyoneOpenChatRoom,
		everyoneSetShowChatRoom,
		everyoneSetExpandChatRoom,
		everyoneChangeMessage,
		everyoneSendMessage,
		everyoneOnScrollChatRoom,

		// Admin
		adminOpenChatRoom,
		adminSetShowChatRoom,
		adminSetExpandChatRoom,
		adminChangeMessage,
		adminSendMessage,
		adminOnScrollChatRoom,
	} = useRollCallChat(alarm);

	return chatData.loading ? (
		<ChatContainer>
			<ChatRoom
				title="Loading Chat..."
				notifications={0}
				optionToClose={false}
				startMinimized={true}
			/>
		</ChatContainer>
	) : (
		<ChatContainer>
			{isRespondTypeAdmin && (
				<ChatRoom
					key="ChatRoomMain"
					title="Chat"
					notifications={chatData.adminNotifications + chatData.everyoneNotifications}
					optionToClose={false}
					startMinimized={true}
				>
					<ChatRoomItem
						key="ChatRoomItemEveryone"
						onClick={everyoneOpenChatRoom}
						title="Everyone"
						description={
							chatData.everyoneLastMessage
								? `${chatData.everyoneLastMessage.senderName}: ${limitMessageLength(
										chatData.everyoneLastMessage.message,
								  )}`
								: ''
						}
						notifications={chatData.everyoneNotifications}
					/>
					<ChatRoomItem
						key="ChatRoomItemAdmins"
						onClick={adminOpenChatRoom}
						title="Admins"
						description={
							chatData.adminLastMessage
								? `${chatData.adminLastMessage.senderName}: ${limitMessageLength(
										chatData.adminLastMessage.message,
								  )}`
								: ''
						}
						notifications={chatData.adminNotifications}
					/>
				</ChatRoom>
			)}

			<ChatRoom
				key="ChatRoomEveryone"
				title="Everyone"
				notifications={chatData.everyoneNotifications}
				showChatRoom={isRespondTypeAdmin ? chatData.everyoneShowChatRoom : true}
				setShowChatRoom={isRespondTypeAdmin ? everyoneSetShowChatRoom : () => {}}
				optionToClose={isRespondTypeAdmin}
				expand={chatData.everyoneExpandChatRoom}
				setExpand={everyoneSetExpandChatRoom}
				startMinimized={!isRespondTypeAdmin}
			>
				<ChatMessagesContainer id="everyoneMessages" onScroll={everyoneOnScrollChatRoom}>
					{chatData.everyoneLoadingMessages && <LoadingSpinner size="small" />}
					{chatData.everyoneGroupedMessages.length > 0 ? (
						chatData.everyoneGroupedMessages.map(messagesPerDay => (
							<div
								className="messages-per-day"
								key={`everyone-${messagesPerDay.date}`}
							>
								<ChatMessageDate
									key={messagesPerDay.date}
									date={
										isToday(messagesPerDay.date) ? 'Today' : messagesPerDay.date
									}
								/>
								{messagesPerDay.messages.map((message, key) => (
									<ChatMessage
										key={message.key}
										showAuthor={
											messagesPerDay.messages[key - 1]?.senderId !==
											message.senderId
										}
										author={
											loggedUser.person.personId === message.senderId
												? 'me'
												: message.senderName
										}
										text={message.message}
										time={message.time}
									/>
								))}
							</div>
						))
					) : (
						<EmptyState
							header="No messages yet"
							icon={messagesEmptyState}
							showCircles={false}
						/>
					)}
				</ChatMessagesContainer>
				<ChatForm
					message={chatData.everyoneMessageForm}
					handleChangeMessage={everyoneChangeMessage}
					handleSendMessage={everyoneSendMessage}
					disableSubmit={chatData.everyoneDisableSubmit}
				/>
			</ChatRoom>

			{isRespondTypeAdmin && (
				<ChatRoom
					key="ChatRoomAdmin"
					title="Admins"
					notifications={chatData.adminNotifications}
					showChatRoom={chatData.adminShowChatRoom}
					setShowChatRoom={adminSetShowChatRoom}
					expand={chatData.adminExpandChatRoom}
					setExpand={adminSetExpandChatRoom}
				>
					<ChatMessagesContainer id="adminMessages" onScroll={adminOnScrollChatRoom}>
						{chatData.adminLoadingMessages && <LoadingSpinner size="small" />}
						{chatData.adminGroupedMessages.length > 0 ? (
							chatData.adminGroupedMessages.map(messagesPerDay => (
								<div
									className="messages-per-day"
									key={`admin-${messagesPerDay.date}`}
								>
									<ChatMessageDate
										key={messagesPerDay.date}
										date={
											isToday(messagesPerDay.date)
												? 'Today'
												: messagesPerDay.date
										}
									/>
									{messagesPerDay.messages.map((message, key) => (
										<ChatMessage
											key={message.key}
											showAuthor={
												messagesPerDay.messages[key - 1]?.senderId !==
												message.senderId
											}
											author={
												loggedUser.person.personId === message.senderId
													? 'me'
													: message.senderName
											}
											text={message.message}
											time={message.time}
										/>
									))}
								</div>
							))
						) : (
							<EmptyState
								header="No messages yet"
								icon={messagesEmptyState}
								showCircles={false}
							/>
						)}
					</ChatMessagesContainer>
					<ChatForm
						message={chatData.adminMessageForm}
						handleChangeMessage={adminChangeMessage}
						handleSendMessage={adminSendMessage}
						disableSubmit={chatData.adminDisableSubmit}
					/>
				</ChatRoom>
			)}
		</ChatContainer>
	);
};
