import React, { useState, useEffect, useContext } from 'react';
import { EmptyState, Button, emptyDataIcon, noResultFoundIcon } from 'componentsV2';
import { DistrictTemplateContext } from '../../Providers';
import './css/EmptyStatuses.scss';

export const EmptyStatuses = ({ filterationData, noDataAdded }) => {
	const [emptyStatusContent, setEmptyStatusContent] = useState({
		title: '',
		description: '',
		icon: '',
	});

	const { handleCreateNewSafetyPlan } = useContext(DistrictTemplateContext);

	useEffect(() => {
		if (noDataAdded === true) {
			setEmptyStatusContent({
				title: 'No safety plans were added yet',
				description: 'Add a safety plan to your Emergency Management Suite',
				icon: emptyDataIcon,
			});
		} else {
			setEmptyStatusContent({
				title: 'No safety plans found',
				description:
					'Your search didn’t match any safety plans. Please try again or add a new safety plan',
				icon: noResultFoundIcon,
			});
		}
	}, [filterationData]);

	return (
		<EmptyState
			header={emptyStatusContent.title}
			description={emptyStatusContent.description}
			icon={emptyStatusContent.icon}
			showCircles={false}
			className={noDataAdded ? '' : 'empty-state-border'}
			bodyClass={'empty-state-body'}
		>
			{noDataAdded && (
				<Button
					icon="plus"
					text="New safety plan"
					className="actionButtonElement"
					onClick={() => handleCreateNewSafetyPlan()}
				/>
			)}
		</EmptyState>
	);
};
