import { HttpService } from 'networking/HttpService';
import { deserializeSafetyPlans } from '../utilities/drills/deserializers';

export const PlanService = {
	async getTemplate(templateId) {
		try {
			return await HttpService({
				method: 'GET',
				route: `/district-templates/${templateId}`,
			});
		} catch (error) {
			throw error;
		}
	},
	publishUpdates: async (templateId, Updatedbody) => {
		return await HttpService({
			method: 'POST',
			headers: {
				Accept: 'application/json, text/plain, */*',
			},
			route: `/district-templates/${templateId}/updates`,
			body: Updatedbody,
		}).catch(err => {
			throw err;
		});
	},

	async uploadFile(file, templateId) {
		try {
			const formData = new FormData();
			formData.append('file', file);
			const url = process.env.API_URL;
			const token = JSON.parse(localStorage.getItem('user')).jwt;
			const response = await fetch(`${url}/district-templates/${templateId}/upload`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});
			return response.json();
		} catch (error) {
			throw error;
		}
	},

	async deleteFile(fileId, templateId) {
		try {
			return await HttpService({
				method: 'DELETE',
				route: `/district-templates/${templateId}/files/${fileId}`,
			});
		} catch (error) {
			throw error;
		}
	},

	async getAll(params = {}) {
		try {
			let query = '';

			if (params.sortKey) {
				query += `&sortKey=${params.sortKey}`;
			}
			if (params.sortDir) {
				query += `&sortDir=${params.sortDir}`;
			}
			if (params.includeContent) {
				query += `&includeContent=${params.includeContent}`;
			}
			if (params.name) {
				query += `&name=${params.name}`;
			}

			if (params.page) {
				query += `&page=${params.page}`;
			}

			if (params.perPage) {
				query += `&perPage=${params.perPage}`;
			}

			if (params.isCompleted) {
				query += `&isCompleted=${params.isCompleted}`;
			}

			if (params.inCompleted) {
				query += `&inCompleted=${params.inCompleted}`;
			}

			if (params.archived) {
				query += `&archived=${params.archived}`;
			}

			if (params.buildingIds && params.buildingIds.length > 0) {
				params.buildingIds.forEach(buildingId => {
					query += `&buildingIds[]=${buildingId}`;
				});
			}

			if (params.binderId) {
				query += `&binderId=${params.binderId}`;
			}

			const response = await HttpService({
				method: 'GET',
				route: `/district-templates?${query}`,
			});

			if (params.isNotDropdown) {
				return response;
			}

			return deserializeSafetyPlans(response.data);
		} catch (error) {
			throw error;
		}
	},
	async getVariableValues(id) {
		try {
			return await HttpService({
				method: 'GET',
				route: `/district-templates/${id}/variablevalues`,
			});
		} catch (error) {
			throw error;
		}
	},

	async updatePlan(id, params) {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/district-templates/${id}`,
				body: params,
			});
		} catch (error) {
			throw error;
		}
	},

	async add(params) {
		try {
			return await HttpService({
				method: 'POST',
				route: `/district-templates`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: params,
			});
		} catch (error) {
			throw error;
		}
	},

	async copy(params) {
		try {
			return await HttpService({
				method: 'POST',
				route: `/district-templates/${params.id}/clone`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: params,
			});
		} catch (error) {
			throw error;
		}
	},

	async getLightPlans(params = {}) {
		try {
			let query = '';

			if (params.page) {
				query += `page=${params.page}`;
			}
			if (params.perPage) {
				query += `&perPage=${params.perPage}`;
			}
			if (params.buildingId) {
				query += `&buildingId=${params.buildingId}`;
			}
			if (params.siteId) {
				query += `&buildingId=${params.siteId}`;
			}
			if (params.binderId) {
				query += `&binderId=${params.binderId}`;
			}
			if (params.title) {
				query += `&name=${params.title}`;
			}
			const response = await HttpService({
				method: 'GET',
				route: `/district-templates/light?${query}`,
			});
			response._metadata = {
				currentPage: 1,
				numPages: 1,
				totalRecords: response.data.length,
			};
			return response;
		} catch (error) {
			throw error;
		}
	},
	async getPlanLegacy(id) {
		try {
			return await HttpService({
				method: 'GET',
				route: `/district-templates/legacy/${id}`,
			});
		} catch (error) {
			throw error;
		}
	},

	async toogleArchive(id) {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/district-templates/${id}/toggle-archive`,
			});
		} catch (error) {
			throw error;
		}
	},

	async applyAllUpdates(id) {
		try {
			return await HttpService({
				method: 'POST',
				route: `/district-templates/${id}/bulk-updates`,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			throw error;
		}
	},

	async deletePlan(planId) {
		try {
			return await HttpService({
				method: 'DELETE',
				route: `/district-templates/${planId}`,
			});
		} catch (error) {
			throw error;
		}
	},
	async saveAllVariables(id, formData) {
		try {
			return await HttpService({
				method: 'POST',
				route: `/district-templates/${id}/bulkvariablesvalues`,
				body: formData,
			});
		} catch (error) {
			throw error;
		}
	},

	async updateTemplateUpdate(id, updateId, params) {
		return await HttpService({
			method: 'POST',
			route: `/district-templates/${id}/updates/${updateId}`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: params,
		});
	},
	async bulkToggleComplete(params) {
		return await HttpService({
			method: 'PUT',
			route: `/district-templates/bulk-toggle-complete`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: params,
		});
	},

	markSafetyplanAsCompleted: async (templateId, isCompleted) => {
		return await HttpService({
			method: 'PUT',
			route: `/district-templates/${templateId}`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				isCompleted: isCompleted,
			},
		}).catch(err => {
			throw err;
		});
	},

	checkPublishValidation: async (templateId, sectionId) => {
		return await HttpService({
			method: 'GET',
			route: `/district-templates/${parseInt(
				templateId,
				10,
			)}/check-published-section?sectionId=${sectionId}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},
};
