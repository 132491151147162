import React from 'react';
import PropTypes from 'prop-types';
import { Button as BpButton } from '@blueprintjs/core';
import './css/Button.scss';

export const Button = ({
	icon,
	iconColor = '',
	onClick,
	intent = 'default',
	text = '',
	large,
	minimal,
	active,
	disabled,
	rightIcon,
	className,
	type = 'primaryDefault',
	loading,
	size = 'md',
	wrapperClass = '',
	style,
	...rest
}) => (
	<span
		style={style}
		className={`customButtonWrapprer ${wrapperClass} ${type}Custom ${size} ${
			text == '' ? 'iconOnly' : ''
		} ${disabled ? 'disabled' : ''} ${iconColor != '' ? `${iconColor}-IconColor` : ''}  `}
	>
		<BpButton
			secondary={intent === 'secondary' ? 'true' : 'false'}
			tertiary={intent === 'tertiary' ? 'true' : 'false'}
			primary={intent === 'primary' ? 'true' : 'false'}
			switchon={intent === 'switchon' ? 'true' : 'false'}
			switchoff={intent === 'switchoff' ? 'true' : 'false'}
			icon={icon}
			className={className}
			onClick={onClick}
			intent={intent}
			text={text}
			large={large}
			minimal={minimal}
			active={active}
			disabled={disabled}
			rightIcon={rightIcon}
			loading={loading}
			{...rest}
		/>
	</span>
);

Button.propTypes = {
	icon: PropTypes.string,
	iconColor: PropTypes.string,
	rightIcon: PropTypes.string,
	intent: PropTypes.string,
	onClick: PropTypes.func,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	large: PropTypes.bool,
	minimal: PropTypes.bool,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	type: PropTypes.string,
	loading: PropTypes.bool,
	size: PropTypes.string,
};
