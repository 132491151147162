/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { VariableValue } from 'components/VariableValue';
import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Tooltip, Position } from '@blueprintjs/core';
import { parseDataModel } from '../../utilities';
import { countOfSpecialTextInString } from 'utilities/searchInString';

const Wrapper = styled.div`
	height: 100vh;
`;

class VariableOnly extends React.Component {
	state = {
		templateVariables: [],
		commonVariables: [],
		loading: true,
	};

	createFields = () => {
		const { templateId, variableValues } = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		Promise.all([
			fetch(`${process.env.API_URL}/district-templates/${templateId}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
		])
			.then(([res1]) => Promise.all([res1.json()]))
			.then(([resp1]) => {
				let template = {
					id: templateId,
					name: resp1.data.name,
					lastUpdated: resp1.data.createdDate.date,
					sections: [
						{
							id: 1,
							title: 'Section 1 title',
							subsections: [
								{
									id: 11,
									title: 'Subsection 1 title',
									content: '<p>Subsection 1 content</p>',
								},
							],
						},
					],
				};
				
				if (resp1.data.content) {
					template.headers = JSON.parse(resp1.data.headers);
				}
				if (resp1.data.content) {
					template.sections = JSON.parse(resp1.data.content);
					template = parseDataModel(template);
				}
				const tempCommon = resp1.data.commonVariables;
				tempCommon.forEach(item => {
					item.variable = {
						content: {
							type: {
								id: 1,
								name: 'Text',
							},
							name: JSON.parse(item.content).name,
						},
						name: item.name,
					};
					const variableCountAndSeen = this.getVariableCountAndSeen(template.sections, item.name);
					item.total = variableCountAndSeen.total;
					item.seenAt = variableCountAndSeen.seenAt;
					
					const variableValue = variableValues.find(variableValue => variableValue.variableName === item.name)
					if(variableValue){
						item.value = variableValue.variableValue;
						item.id = variableValue.id;
						item.imageInfo = variableValue?.imageInfo ? variableValue.imageInfo : null;
					}
					item.content = JSON.parse(item.content);
				});

				const tempTemplate = resp1.data.templateVariables;
				tempTemplate.forEach(item => {
					const variableCountAndSeen = this.getVariableCountAndSeen(template.sections, item.name);
					item.total = variableCountAndSeen.total;
					item.seenAt = variableCountAndSeen.seenAt;
					item.id = null;
					
					const variableValue = variableValues.find(variableValue => variableValue.variableName === item.name)
					
					if(variableValue){
						item.value = variableValue.variableValue;
						item.id = variableValue.id;
						item.imageInfo = variableValue?.imageInfo ? variableValue.imageInfo : null;
					}
					item.variable = {
						name: item.name,
						content: JSON.parse(item.content),
					};
				});

				this.setState({
					commonVariables: tempCommon,
					templateVariables: tempTemplate,
					loading: false,
				});
			});
	};

	componentDidMount() {
		this.createFields();
	}

	getVariableCountAndSeen(TemplateSections, text) {
		let total = 0;
		const seenAt = [];

		TemplateSections.forEach(section => {
			if (section.content.includes(text)) {
				total += countOfSpecialTextInString(section.content, text);
				seenAt.push(section.title);
			}
			section.subsections.forEach(subSection => {
				if (subSection.content.includes(text)) {
					total += countOfSpecialTextInString(subSection.content, text);
					seenAt.push(subSection.title);
				}
			});
		});
		return {
			'total': total,
			'seenAt': seenAt
		};
	}
	
	setIsUpdated = val => {
		this.createFields()
	}

	render() {
		const { templateId, loadData } = this.props;
		const { commonVariables, templateVariables, loading } = this.state;
		if (loading) return <LoadingSpinner />;
		return (
			<Wrapper>
				{commonVariables.map(item => {
					if (item.total > 0) {
						return (
							<React.Fragment key={`Temp variables-${item.id}`}>
								<VariableValue
									item={item}
									templateId={templateId}
									setIsUpdated={this.setIsUpdated}
								/>
								<div className="mx-3 mt-2 d-flex justify-content-end">
									<Tooltip
										content={
											<div>
												{item.seenAt.map(
													(itm, index) => (
														<p key={index}>{itm}</p>
													),
												)}
											</div>
										}
									>
										<a
											href="#"
											onClick={e => e.preventDefault()}
										>
											View references ({item.seenAt.length})
										</a>
									</Tooltip>
								</div>
							</React.Fragment>
						);
					}
					return null;
				})}
				{templateVariables.map(item => {
					if (item.total > 0) {
						return (
							<React.Fragment key={`Temp variables-${item.id}`}>
								<VariableValue
									item={item}
									templateId={templateId}
									setIsUpdated={this.setIsUpdated}
								/>
								<div className="mx-3 mt-2 d-flex justify-content-end">
									<Tooltip
										content={
											<div>
												{item.seenAt.map(
													(itm, index) => (
														<p key={index}>{itm}</p>
													),
												)}
											</div>
										}
									>
										<a
											href="#"
											onClick={e => e.preventDefault()}
										>
											View references ({item.seenAt.length})
										</a>
									</Tooltip>
								</div>
							</React.Fragment>
						);
					}
					return null;
				})}
				<div className="mx-3 mt-3 d-flex justify-content-end">
					<Button
						onClick={loadData}
						text="Save"
						large
						intent="primary"
					/>
				</div>
			</Wrapper>
		);
	}
}

VariableOnly.propTypes = {
	variableValues: PropTypes.array,
	templateId: PropTypes.any,
};

export default VariableOnly;
