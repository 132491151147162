export const DefaultColors = [
	{
		class: 'color-gray',
		value: '#29353D'
	},
	{
		class: 'color-primary',
		value: '#007E8F'
	},
	{
		class: 'color-error',
		value: '#E81717'
	},
	{
		class: 'color-warning',
		value: '#FF8310'
	},
	{
		class: 'color-success',
		value: '#5DD400'
	},
	{
		class: 'color-blue-dark',
		value: '#2970FF'
	},
	{
		class: 'color-indigo',
		value: '#6172F3'
	},
	{
		class: 'color-purple',
		value: '#7A5AF8'
	},
	{
		class: 'color-fuchsia',
		value: '#D444F1'
	},
	{
		class: 'color-pink',
		value: '#EE46BC'
	},
	{
		class: 'color-orange-dark',
		value: '#EF6820'
	},
];