import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { search, clear } from 'assets/icons';
import { advancedFilter } from 'assets/icons';
import './index.css';
import { debounce } from 'lodash';
import { LabelText } from 'components/Forms/shared.js';
import { InputTagContainer, InputTag } from '../InputTag';

const SearchBarWithTags = ({
	placeholder,
	onSearch,
	style,
	label,
	onFocus,
	showTagInput,
	advancedSearchbtn,
	advancedSearchbtnAction,
	onDelete,
	onClearSearchBar,
	value = '',
	defaultValue = '',
}) => {
	const inputRef = useRef();
	const [hasSearch, setHasSearch] = useState(false);

	useEffect(() => {
		// Set the value directly to the input element
		if (inputRef.current && defaultValue !== '') {
			inputRef.current.value = defaultValue;
		}
	}, [defaultValue]);

	const clearSearch = () => {
		setHasSearch(false);
		onClearSearchBar();
		if (inputRef && inputRef.current) {
			inputRef.current.value = '';
		}
	};

	const onSearchHandler = e => {
		setHasSearch(true);
		onSearch(e.target.value);
	};

	const debouncedOnChange = debounce(onSearchHandler, 500);

	return (
		<div
			className="input-search-tags-wrapper input-wrapper search-bar d-contents"
			style={style}
		>
			<LabelText>{label && label} </LabelText>
			<img alt="" src={search} />
			{showTagInput && (
				<InputTagContainer visibleOptions={2}>
					{value.map(item => {
						return (
							<InputTag
								key={item.key}
								label={item.label}
								onDelete={() => onDelete(item)}
							/>
						);
					})}
				</InputTagContainer>
			)}
			{!showTagInput && (
				<input
					id="search-input"
					className="search-input-tag"
					placeholder={placeholder || 'Search'}
					onFocus={onFocus}
					ref={inputRef}
					onChange={debouncedOnChange}
				/>
			)}
			{hasSearch ||
				(showTagInput && (
					<img alt="" src={clear} className="img-btn-icon" onClick={clearSearch} />
				))}
			{advancedSearchbtn && (
				<img
					alt=""
					src={advancedFilter}
					className="advanced-search-btn"
					onClick={advancedSearchbtnAction}
				/>
			)}
		</div>
	);
};

export { SearchBarWithTags };

SearchBarWithTags.propTypes = {
	placeholder: PropTypes.string,
	onSearch: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	style: PropTypes.object,
};
