import React from 'react';
import BootstrapedTable from 'components/BootstrapedTable';
import { Icon } from '@blueprintjs/core';
import ContactCard from './ContactCard';
import RolesColumn from './RolesColumn';
import { Helper } from 'utilities/helper';
import { PhoneWithExtension } from 'utilities/PhoneWithExtension';
import ContactsListActionsButtons from './ContactsListActionsButtons';
import { PHONE_TYPES, SORTING_DIR } from 'utilities/constants';

const Table = ({
	contactsList,
	filterationData,
	userEditPermission,
	userViewPermission,
	callListEditPermission,
	dispatchFilterationData,
	totalRows,
	callListId,
	selectedCallList,
	updateDataCallBack,
}) => {
	
	
	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Name',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => <ContactCard contact={row} />,
		},
		{
			key: 'staffTitle',
			dataField: 'person.name.title',
			text: 'Staff Title',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'roles',
			dataField: 'roles',
			text: 'Role Type',
			classes: 'bold-500',
			sort: true,
			hidden: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => <RolesColumn 
											row={row}
											callList={selectedCallList} 
										/>
		},
		{
			key: 'workNumber',
			dataField: 'workNumber',
			text: 'Work Phone',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				const mobileNumber = row.person.phones.find(
					phone => phone.type == PHONE_TYPES.WORK,
				);
				return <PhoneWithExtension 
					phone={Helper.formatDigitsToPhone(mobileNumber?.phoneNumber)} 
					extension={mobileNumber?.extension} 
				/>
			},
		},
		{
			key: 'mobileNumber',
			dataField: 'mobileNumber',
			text: 'Mobile Phone',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				const mobileNumber = row.person.phones.find(
					phone => phone.type == PHONE_TYPES.MOBILE,
				);
				return Helper.formatDigitsToPhone(mobileNumber?.phoneNumber);
			},
		},
		{
			key: 'homeNumber',
			dataField: 'homeNumber',
			text: 'Home Phone',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				const mobileNumber = row.person.phones.find(
					phone => phone.type == PHONE_TYPES.HOME,
				);
				return Helper.formatDigitsToPhone(mobileNumber?.phoneNumber);
			},
		},
		{
			key: 'email',
			dataField: 'person.email.emailAddress',
			text: 'Email',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => {
				return (
					<ContactsListActionsButtons
						row={row}
						callListId={callListId}
						updateDataCallBack={updateDataCallBack}
						userEditPermission={userEditPermission}
						userViewPermission={userViewPermission}
						callListEditPermission={callListEditPermission}
						filterationData={filterationData}
					/>

				)
			}
		},
	];

	const sortingStyle = (order, column) => {
		if (order === SORTING_DIR.DESC) {
			return <Icon icon="caret-down" />;
		}
		return <Icon icon="caret-up" />;
	};

	// change row color with disabled contactsList
	const rowStyle = (row, rowIndex) => {
		const style = {};

		style.color = '#777';

		return style;
	};

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = sortField;
			switch (sortField) {
				case 'person.email.emailAddress':
					sortColumn = 'email';
					break;
				case 'person.name.title':
					sortColumn = 'staffTitle';
					break;
			}
			dispatchFilterationData({ type: 'sortKey', payload: sortColumn });
			dispatchFilterationData({ type: 'sortDir', payload: sortOrder });
		}
	};

	return (
		<>
		

			<BootstrapedTable
				keyField="id"
				data={contactsList}
				columns={columns}
				bordered={false}
				defaultSorted={[
					{
						dataField: 'title',
						order: SORTING_DIR.ASC,
					},
				]}
				rowStyle={rowStyle}
				onTableChange={onTableChange}
				currentPage={filterationData.page}
				rowsPerPage={filterationData.perPage}
				setCurrentPage={value =>
					dispatchFilterationData({ type: 'page', payload: value })
				}
				setRowsPerPage={value =>
					dispatchFilterationData({ type: 'perPage', payload: value })
				}
				totalRows={totalRows}
				sizePerPageList={[10, 25, 50, 100, 200]}
			/>
		</>
	);
};

export default Table;
