import React, { useReducer } from "react";
import { CardContainer, Checkbox } from "componentsV2";
import { BinderService } from "services/BinderService";

const printOptionsReducer = (data, action) => {
    data[action.type] = !data[action.type];
    return {
        ...data
    }
}

export const PrintSection = ({
    binder,
    viewMode
}) => {
    const [printOptions, setPrintOptions] = useReducer(printOptionsReducer, {
        header_style: binder.headerStyle,
        footer_style: binder.footerStyle,
        include_toc: binder.includeToc,
        include_index: binder.includeIndex,
    });

    const updateBinderOptions = async () => {
        await BinderService.updatePrintOptions(binder.id, printOptions).then(response => {
            // 
        }).catch(err => {
            // 
        })
    }

    const onChangeOptionHandler = async option => {
        setPrintOptions({ 'type': option });
        await updateBinderOptions();
    }

    const PrintItem = ({
        title,
        isChecked,
        onChange
    }) => (
        <Checkbox
            label={title}
            onChange={onChange}
            checked={isChecked}
            disabled={viewMode}
            size='md' 
        />
    )
   
    return (
        <CardContainer
            title="Print options"
            className='binder-details-print-section'
        >
            <PrintItem
                title='Show page headers'
                isChecked={printOptions.header_style}
                onChange={() => onChangeOptionHandler('header_style')}
            />
            <PrintItem
                title='Show page footers'
                isChecked={printOptions.footer_style}
                onChange={() => onChangeOptionHandler('footer_style')}
            />
            <PrintItem
                title='Include table of contents'
                isChecked={printOptions.include_toc}
                onChange={() => onChangeOptionHandler('include_toc')}
            />
            <PrintItem
                title='Include index'
                isChecked={printOptions.include_index}
                onChange={() => onChangeOptionHandler('include_index')}
            />
        </CardContainer>
    );
}