import React, { useEffect, useReducer } from 'react';
import { AlertModal, Button } from 'componentsV2';
import './css/ToogleArchivePlanModal.scss';

export const ToogleArchivePlanModal = ({
	modalName,
	modalToShow,
	loading,
	formData,
	closeModal,
	handleSubmit,
	safetyPlanData,
	history,
}) => {
	const archived = formData?.archived;

	const modalDetailsReducer = (modalDetails, action) => {
		modalDetails = action.payload;
		return {
			title: modalDetails.title,
			subtitle: modalDetails.subtitle,
			icon: modalDetails.icon,
			confirmBtnElement: modalDetails.confirmBtnElement,
			cancelBtnElement: modalDetails.cancelBtnElement,
		};
	};

	const initialState = {
		title: ' ',
		subtitle: ' ',
		icon: {
			icon: '',
			iconColor: '',
		},
		confirmBtnElement: null,
		cancelBtnElement: null,
	};
	const [modalDetails, dispatchModalDetails] = useReducer(modalDetailsReducer, initialState);

	useEffect(() => {
		let modalTitle = '';
		let modalSubtitle = '';
		let modalIcon = null;
		let confirmBtn = null;
		let cancelBtn = null;

		const contents = safetyPlanData.linkedObjects;
		let titleData = null;
		if (contents) {
			if (contents.binders?.length > 0 && contents.flipcharts?.length > 0) {
				titleData = 'binders and flipcharts have';
			} else if (contents.binders?.length > 0) {
				titleData = contents.binders?.length == 1 ? 'binder has' : 'binders have';
			} else if (contents.flipcharts?.length > 0) {
				titleData = contents.flipcharts?.length == 1 ? 'flipchart has' : 'flipcharts have';
			}
		}
		
		if (titleData) {
			modalTitle = `Update Needed Before Archiving "${safetyPlanData?.title}"`;
			modalSubtitle = (
				<>
					<span className="disableArchiveSubtitle">
						The following {titleData} this safety plan and need to be updated with a
						different safety plan before this plan can be archived. Click on the link(s)
						below to be taken to a new tab.
					</span>
					<div className="customSpBinderArchiveLink">
						{contents.binders.map(item => {
							return (
								<Button
									text={item.name}
									type="ghostPrimary"
									size="md"
									onClick={() => window.open(`/binders/edit/${item.id}`, '_blank')}
									icon="dot"
								/>
							);
						})}
					</div>
					<div className="customSpFlipchartLink">
						{contents.flipcharts.map(item => {
							return (
								<Button
									text={item.name}
									type="ghostPrimary"
									size="md"
									onClick={() => window.open(`/flipcharts/${item.id}`, '_blank')}
									icon="dot"
								/>
							);
						})}
					</div>
				</>
			);

			modalIcon = {
				icon: 'hand',
				iconColor: 'error',
			};
			confirmBtn = <Button text="OK" type="primaryDefault" size="md" onClick={closeModal} />;
		} else {
			modalTitle = `Are you sure you want to ${archived ? 'unarchive' : 'archive'} "${
				safetyPlanData?.title
			}"?`;
			modalSubtitle = archived
				? 'You can archive it at a later time.'
				: "Archived plans can't be edited, or attached to binders or flipcharts. Archived plans do not have their fields updated from other places in the system, Are you sure you want to archive this plan?";
			modalIcon = {
				icon: 'warning-sign',
				iconColor: 'warning',
			};

			confirmBtn = (
				<Button
					text={`Yes, ${archived ? 'unarchive' : 'archive'} it`}
					type="primaryDefault"
					size="md"
					onClick={handleSubmit}
				/>
			);
			cancelBtn = (
				<Button text="No, go back" type="tertiary" size="md" onClick={closeModal} />
			);
		}

		dispatchModalDetails({
			payload: {
				...modalDetails,
				title: modalTitle,
				subtitle: modalSubtitle,
				icon: modalIcon,
				confirmBtnElement: confirmBtn,
				cancelBtnElement: cancelBtn,
			},
		});
	}, [safetyPlanData]);

	return (
		<AlertModal
			isOpen={modalToShow === modalName}
			onClose={closeModal}
			loading={loading}
			size="medium"
			customClassName="archiveModalWrapper"
			title={modalDetails.title}
			subtitle={modalDetails.subtitle}
			showCloseButton={false}
			alertConfirmButton={modalDetails.confirmBtnElement}
			alertCancelButton={modalDetails.cancelBtnElement}
			icon={archived ? null : 'warning-sign'}
			iconColor={archived ? null : 'warning'}
		/>
	);
};
