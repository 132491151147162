import { DocumentService } from "services/DocumentService";
import { Intent } from '@blueprintjs/core';

    export const DocumentPdfFn = (
        setTbLoading,
        toaster
    ) => {

    const OpenDocumentAsPdf = async (id) => {
        setTbLoading(true)
        DocumentService.download([id], [], true).then(blob => {
            setTbLoading(false)
            let newWindow = window.open('/')
            newWindow.location = URL.createObjectURL(blob);

        }).catch(err => {
            toaster(err.error?.description, Intent.DANGER)
        })
        .finally(() => {
            setTbLoading(false);
        });
    }

    return {
        OpenDocumentAsPdf
    }
}
