import { HttpService, buildRoute } from 'networking/HttpService';

const GENERIC_ERROR = new Error('An error occurred, please try again');

export const CallListService = {
	getAll: async params =>
		await HttpService({
			method: 'POST',
			route: '/call-lists/search',
			headers: {
				'Content-Type': 'application/json',
			},
			body: params,
		}).catch(err => {
			throw err;
		}),

	save: async params => {
		if (params.id) {
			const editParams = {
				name: params.name,
			};
			return await HttpService({
				method: 'PUT',
				route: `/call-lists/${params.id}`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: editParams,
			}).catch(err => {
				throw err;
			});
		}
		return await HttpService({
			method: 'POST',
			route: '/call-lists',
			headers: {
				'Content-Type': 'application/json',
			},
			body: params,
		}).catch(err => {
			throw err;
		});
	},

	getById: async params =>
		await HttpService({
			method: 'GET',
			route: `/call-lists/${params.id}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		}),

	async delete(id) {
		return HttpService({
			method: 'DELETE',
			route: `/call-lists/${id}`,
		}).then(res => {
			if (res.statusCode === 200) {
				return res;
			}
			throw GENERIC_ERROR;
		});
	},

	async validateBulkContactsFile(g) {
		try {
			return await HttpService({
				method: 'POST',
				route: '/call-lists/bulk-validate-contacts-file',
				body: {
					file: g,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	async bulkAddContacts(contactsList, callListId) {
		try {
			return await HttpService({
				method: 'POST',
				route: `/call-lists/${callListId}/contacts`,
				body: {
					contacts: contactsList,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	getCalllistBySiteId : async (params) => {
		let query = '';
        if (params.page) {
			query += `page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}		
		if(params.title){
            query += `&title=${params.title}`;
        }
		if(params.binderId){
			query += `&binderId=${params.binderId}`;
		}


		return await HttpService({
			method:'POST',
			route:`/call-lists/search?${query}`,
			headers:{
				'Content-Type': 'application/json',
			},
			body: {
				selectedBuilders: [params.siteId],
			},
		})
		.catch(err => {
            throw err;
		})
	},
	getCallListsLegacy: async (params) => {
		let query = '';
		if (params.selectedBuilders && params.selectedBuilders.length > 0) {
			params.selectedBuilders.forEach(selectedBuilder => {
				query += `&selectedBuilders[]=${selectedBuilder}`;
			})
		}
		if (!!params?.title) {
			query += `&title=${params.title}`;
		}
		if (!!params?.siteId) {
			query += `&selectedBuilders[]=${params.siteId}`;
		}
		if (!!params.binderId) {
			query += `&binderId=${params.binderId}`;
		}
		if (!!params.page) {
			query += `&page=${params.page}`;
		}
		if (!!params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
	
		return await HttpService({
			method: 'GET',
			route: `/call-lists/legacy?${query}`,
			headers: {
				'Content-Type': 'application/json',
			}
		}).catch(err => {
			throw err;
		});
	}
};
