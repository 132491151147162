import React, {useContext} from "react";
import PropTypes from 'prop-types';
import { ActionsButton, Button } from "componentsV2";
import { useSelector } from "react-redux";
import { checkPermission } from 'utilities/permissions';
import { decodeToken } from 'utilities';
import { DocumentModalsContext } from "./DocumentModalsProvider";

export const ActionsList = ({
    document,
    history
}) => {
    const permissions = useSelector( state => state.route.permissions );
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    const dist = token ? decodeToken(token) : '';
    const districtId = dist ? dist.dist : null;
    const editPermission = checkPermission('document_edit', permissions, document.buildingId ? document.buildingId : districtId);
    const viewPermission = checkPermission('document_view', permissions, document.buildingId);

    const {
        showDownloadModal,
        showRenameModal,
        showWhereItsUsedmodal,
        showRevisionHistoryModal,
        OpenDocumentAsPdf,
        showAttachToBinderModal,
        showDeleteModal,
        showUploadModal,
        showMoveModal
    } = useContext(DocumentModalsContext);
    return (
        viewPermission && <ActionsButton>
            <Button
                large
                text="Download"
                icon="cloud-download"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
                onClick={() => showDownloadModal(document)}
            />

            {/*USE OpenDocumentAsPdf(document.id) in case of converting the Document source to PDF*/}
            
            {editPermission && (document.type != "360image") && (
                <Button
                    large
                    text="Attach to binder"
                    icon="document-open"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => showAttachToBinderModal([document])}
                />
            )}

            {editPermission && (
                <Button
                    large
                    text="Move"
                    icon="move"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => showMoveModal([document])}
                />
            )}

            <Button
                large
                text="Where it’s used"
                icon="search-template"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
                onClick={() => showWhereItsUsedmodal(document)}
            />

            {(document.type != "360image") && <Button
                large
                text="Revision history"
                icon="history"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
                onClick={() => showRevisionHistoryModal(document)}
            />}

            {editPermission && (
                <Button
                    large
                    text="Rename"
                    icon="annotation"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => showRenameModal(document)}
                />
            )}

            {editPermission && (
                <Button
                    large
                    text="Replace file"
                    icon="repeat"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => showUploadModal(document)}
                />
            )}

            {editPermission && (
                <Button
                    large
                    text="Delete"
                    icon="trash"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => showDeleteModal(document)}
                />
            )}
            
        </ActionsButton>
    );
}

ActionsList.propTypes = {
    document: PropTypes.object,
};