import React, { useContext, useState, useEffect } from 'react';
import { TabsActionsList } from './TabsActionsList';
import { FlipchartService } from 'services/FlipchartService';
import { ToasterContext } from 'pages/App';
import { Intent, LoadingSpinner, SortableTable, TableCell, Checkbox, Tooltip } from 'componentsV2';
import { HAS_OLD_SP_TP_CONTENT_EDIT_USER, HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER } from '../consts.js';
import { Icon } from '@blueprintjs/core';

export const Tabs = ({
	list,
	history,
	getTabsSelected,
	setDeleteTabModalOpen,
	setSingleDeleteTabFlag,
	reloadFlipchartDetails,
	flipchartId,
	isArchived = false,
	buildingId,
	flipchart,
}) => {
	const toaster = useContext(ToasterContext);
	const [isLoading, setIsLoading] = useState(false);
	const [tabsIdSelected, setTabsIdSelected] = useState([]);
	const [isAllChecked, setIsAllChecked] = useState(false);

	const selectTabId = tab => {
		const tabId = parseInt(tab.target.value);
		if (tabsIdSelected.includes(tabId)) {
			// If it exists, remove it by filtering it out
			setTabsIdSelected(tabsIdSelected.filter(id => id !== tabId));
		} else {
			// If it doesn't exist, add it by pushing it to the array
			setTabsIdSelected([...tabsIdSelected, tabId]);
		}
	};

	const handleCheckAll = () => {
		if (!isAllChecked) {
			// If "Check All" is clicked, select all checkboxes
			const allIds = list.map(item => item.id);
			setTabsIdSelected(allIds);
			getTabsSelected(allIds);
		} else {
			// If "Uncheck All" is clicked, deselect all checkboxes
			setTabsIdSelected([]);
		}

		// Toggle the state of the "Check All" checkbox
		setIsAllChecked(!isAllChecked);
	};

	const handleSingleTabDelete = tabId => {
		setDeleteTabModalOpen(true);
		setSingleDeleteTabFlag(true);
		getTabsSelected([tabId]);
	};

	useEffect(() => {
		if (tabsIdSelected.length > 0) {
			if (tabsIdSelected.length == list.length) {
				setIsAllChecked(true);
			} else {
				setIsAllChecked(false);
			}
			getTabsSelected(tabsIdSelected);
		} else {
			getTabsSelected(0);
		}
	}, [tabsIdSelected, list]);

	//////
	const headers = isArchived ? ['Name', ''] : ['Name', 'Actions'];
	const hiddenIndex = [2, 3, 4, 5];
	const flipchartTabs = list.map(tab => {
		return {
			title: tab.title,
			actions: '',
			id: parseInt(tab.id),
			orderNumber: tab.orderNumber,
			contentType: tab.contentType,
			hasOldSafetyPlan: tab.hasOldSafetyPlan,
		};
	});

	const headerRenders = {
		Name: headerTitle => (
			<>
				<div className="tab-title-checkbox">
					{!isArchived && (
						<Checkbox size="small" checked={isAllChecked} onChange={handleCheckAll} />
					)}
					<div>{headerTitle}</div>
				</div>
			</>
		),
	};
	const renders = {
		title: (str, obj) => (
			<>
				<div className="tab-title-checkbox">
					{!isArchived && (
						<Checkbox
							size="small"
							checked={tabsIdSelected.includes(obj.id)}
							key={`checkbox-${obj.id}`}
							value={obj.id}
							onChange={selectTabId}
						/>
					)}
					<TableCell
						text={str}
						supportText={obj.contentType}
						firstRow={true}
						rightIcon={
							obj.hasOldSafetyPlan && (
								<Tooltip
									tooltipContent={
										<p className="sp_tp_text">
											{flipchart.editPermission
												? HAS_OLD_SP_TP_CONTENT_EDIT_USER
												: HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER}
										</p>
									}
									theme="light"
								>
									<Icon className="light-warning" icon="warning-sign" />
								</Tooltip>
							)
						}
					/>
				</div>
			</>
		),
		actions: (str, obj) => {
			return isArchived ? null : (
				<TabsActionsList
					flipchart={obj}
					history={history}
					handleSingleTabDelete={handleSingleTabDelete}
					tabFullData={list.find(tab => tab.id == obj.id)}
					flipchartId={flipchartId}
					buildingId={buildingId}
					onSubmitFinished={reloadFlipchartDetails}
				/>
			);
		},
	};

	const handleDragEnd = (newFlipchartTabs, draggedObj) => {
		const tabs_ids = newFlipchartTabs.map(tab => tab.id);
		list.sort((a, b) => {
			const indexA = tabs_ids.indexOf(a.id);
			const indexB = tabs_ids.indexOf(b.id);
			return indexA - indexB;
		});

		const params = {
			tabs_ids: tabs_ids,
		};
		setIsLoading(true);
		FlipchartService.reorderTabs(flipchartId, params)
			.then(response => {
				if (response.statusCode == 200) {
					const sortedTabTitle = draggedObj.title;
					toaster(
						'You have successfully changed the order of "' + sortedTabTitle + '".',
						Intent.SUCCESS,
					);
				}
				setIsLoading(false);
				reloadFlipchartDetails();
			})
			.catch(err => {
				toaster(err.error.fields.tabs_ids || err.error.fields.id, Intent.DANGER);
				setIsLoading(false);
			});
	};

	if (isLoading) return <LoadingSpinner className="mt-3" />;
	return (
		<SortableTable
			hiddenIndex={hiddenIndex}
			headers={headers}
			headerRenders={headerRenders}
			loadedData={flipchartTabs}
			renders={renders}
			allowDrag={!isArchived}
			handleDragEnd={handleDragEnd}
			sortableField="orderNumber"
		/>
	);
};
