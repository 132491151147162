export const fieldTypeIs = (field, type) => field === type;

export const translateStatus = status => {
	if (status === 'In Progress') {
		return 'in_progress';
	}
	if (status === 'Completed') {
		return 'completed';
	}
	if (status === 'Overdue') {
		return 'overdue';
	}
	return 'scheduled';
};

const getActions = (
	editHandler,
	viewHandler,
	unpostHandler,
	postHandler,
	printHandler,
	removeHandler,
) => [
	{
		id: 'edit',
		title: 'Edit log',
		icon: 'edit',
		requiredPermission: 'canEdit',
		requiredUserPermission: 'drilllogs_log',
		handler: editHandler,
	},
	{
		id: 'view',
		title: 'View drill log',
		icon: 'eye-open',
		requiredPermission: 'canView',
		handler: viewHandler,
	},
	{
		id: 'unpost',
		title: 'Unpost drill log',
		icon: 'undo',
		requiredPermission: 'canUnpost',
		requiredUserPermission: 'drilllogs_unpost',
		handler: unpostHandler,
	},
	{
		id: 'post',
		title: 'Post drill log',
		icon: 'upload',
		requiredPermission: 'canPost',
		requiredUserPermission: 'drilllogs_post',
		handler: postHandler,
	},
	{
		id: 'print',
		title: 'Print log',
		icon: 'print',
		requiredPermission: 'canPrint',
		handler: printHandler,
	},
	{
		id: 'remove',
		title: 'Remove from list',
		icon: 'cross',
		requiredPermission: 'canRemove',
		requiredUserPermission: 'drilllogs_delete',
		handler: removeHandler,
		isDestructive: true,
	},
];

// what's allowed for each drill status
const permissions = {
	scheduled: {
		canEdit: true,
		canView: false,
		canUnpost: false,
		canPost: false,
		canRemove: false,
		canPrint: false,
	},
	overdue: {
		canEdit: true,
		canView: false,
		canUnpost: false,
		canPost: false,
		canRemove: false,
		canPrint: false,
	},
	in_progress: {
		canEdit: true,
		canView: false,
		canUnpost: false,
		canPost: true,
		canRemove: true,
		canPrint: false,
	},
	completed: {
		canEdit: false,
		canView: true,
		canUnpost: true,
		canPost: false,
		canRemove: false,
		canPrint: true,
	},
	not_scheduled: {
		canEdit: false,
		canView: false,
		canUnpost: false,
		canPost: false,
		canRemove: false,
		canPrint: false,
	},
};

const validateHandlersDefinition = (
	requiredPermission,
	editHandler,
	viewHandler,
	unpostHandler,
	postHandler,
	printHandler,
	removeHandler,
) => {
	if (requiredPermission === 'canEdit' && !editHandler) {
		throw new Error('editHandler needs to be defined');
	}
	if (requiredPermission === 'canView' && !viewHandler) {
		throw new Error('viewHandler needs to be defined');
	}
	if (requiredPermission === 'canUnpost' && !unpostHandler) {
		throw new Error('unpostHandler needs to be defined');
	}
	if (requiredPermission === 'canPost' && !postHandler) {
		throw new Error('postHandler needs to be defined');
	}
	if (requiredPermission === 'canRemove' && !removeHandler) {
		throw new Error('removeHandler needs to be defined');
	}
	if (requiredPermission === 'canPrint' && !printHandler) {
		throw new Error('printHandler needs to be defined');
	}
};

const isActionPermitted = (
	status,
	requiredPermission,
	requiredUserPermission,
	editHandler,
	viewHandler,
	unpostHandler,
	postHandler,
	printHandler,
	removeHandler,
	userPermissions,
) => {
	let hasPermission = permissions[status][requiredPermission] === true;

	// make sure user has permission for this action
	if (hasPermission && requiredUserPermission && !userPermissions[requiredUserPermission]) {
		hasPermission = false;
	}

	if (hasPermission) {
		validateHandlersDefinition(
			requiredPermission,
			editHandler,
			viewHandler,
			unpostHandler,
			postHandler,
			printHandler,
			removeHandler,
		);
	}
	return hasPermission;
};

export function getAvailableActionsForDrillStatus(
	drillStatus,
	editHandler,
	viewHandler,
	unpostHandler,
	postHandler,
	printHandler,
	removeHandler,
	userPermissions = {},
) {
	return getActions(
		editHandler,
		viewHandler,
		unpostHandler,
		postHandler,
		printHandler,
		removeHandler,
	).filter(action =>
		isActionPermitted(
			drillStatus,
			action.requiredPermission,
			action.requiredUserPermission,
			editHandler,
			viewHandler,
			unpostHandler,
			postHandler,
			printHandler,
			removeHandler,
			userPermissions,
		),
	);
}

export const getManageDistrictRequirementsActions = (
	editHandler,
	editSchedulingInstructionsHandler,
	editPostingInstructionsHandler,
	removeHandler,
	setRequirementHandler,
) => [
	{
		id: 'edit',
		title: 'Edit required fields',
		icon: 'edit',
		handler: editHandler,
	},
	{
		id: 'setRequirement',
		title: 'Edit requirements per building',
		icon: 'edit',
		handler: setRequirementHandler,
	},
	{
		id: 'edit',
		title: 'Edit scheduling instructions',
		icon: 'edit',
		handler: editSchedulingInstructionsHandler,
	},
	{
		id: 'edit',
		title: 'Edit posting instructions',
		icon: 'edit',
		handler: editPostingInstructionsHandler,
	},
	{
		id: 'remove',
		title: 'Remove from list',
		icon: 'cross',
		handler: removeHandler,
		isDestructive: true,
	},
];

const tableAsCSV = tableClass => {
	// Variable to store the final csv data
	const csvData = [];

	// Get each row data
	const rows = document.getElementById(tableClass).getElementsByTagName('tr');
	for (let i = 0; i < rows.length; i += 1) {
		// Get each column data
		const cols = rows[i].querySelectorAll('td,th');
		// Stores each csv row data
		const csvrow = [];
		for (let j = 0; j < cols.length; j += 1) {
			// Get the text data of each cell of
			// a row and push it to csvrow
			const text = cols[j].innerText.replace(/\n/g, ' ');
			csvrow.push(`"${text}"`);
		}

		// Combine each column value with comma
		csvData.push(csvrow.join(','));
	}
	// combine each row data with new line character
	return csvData.join('\n');

	/* We will use this function later to download
		the data in a csv file downloadCSVFile(csv_data);
		*/
};

export const downloadDrillsTableAsCSV = tableClass => {
	// Create CSV file object and feed our
	// csv_data into it
	const CSVFile = new Blob([tableAsCSV(tableClass)], { type: 'text/csv' });

	// Create to temporary link to initiate
	// download process
	const tempLink = document.createElement('a');

	// Download csv file
	tempLink.download = 'drills.csv';
	const url = window.URL.createObjectURL(CSVFile);
	tempLink.href = url;

	// This link should not be displayed
	tempLink.style.display = 'none';
	document.body.appendChild(tempLink);

	// Automatically click the link to trigger download
	tempLink.click();
	document.body.removeChild(tempLink);
};

export const downloadExportAsCSV = data => {
	// Create CSV file object and feed our
	// csv_data into it
	const CSVFile = new Blob([data], { type: 'text/csv' });

	// Create to temporary link to initiate
	// download process
	const tempLink = document.createElement('a');

	// Download csv file
	tempLink.download = 'drills-analytics.csv';
	const url = window.URL.createObjectURL(CSVFile);
	tempLink.href = url;

	// This link should not be displayed
	tempLink.style.display = 'none';
	document.body.appendChild(tempLink);

	// Automatically click the link to trigger download
	tempLink.click();
	document.body.removeChild(tempLink);
};

export const getUnscheduledDrillCountsForBuilding = (buildingRequirements, scheduledDrills) => {
	let unscheduledDrillCounts = [];
	// make a count of drill type requirements that are already scheduled
	const scheduledRequirements = {};
	for (const drill of scheduledDrills) {
		const { uuid } = drill.drillType;
		if (!scheduledRequirements[uuid]) {
			scheduledRequirements[uuid] = 0;
		}
		scheduledRequirements[uuid] += 1;
	}
	// find count differences
	for (const req of buildingRequirements) {
		if (req.uuid in scheduledRequirements) {
			const difference = req.requirement - scheduledRequirements[req.uuid];
			const { uuid, name } = req;
			unscheduledDrillCounts.push({ uuid, name, count: difference });
		} else {
			const { uuid, name, requirement } = req;
			unscheduledDrillCounts.push({ uuid, name, count: requirement });
		}
	}
	return unscheduledDrillCounts;
};

//  Data objects are nested, flattening these objects will ensure that each nested value is accessible as a
// single key value pair.
export const flattenData = (item, parent = '', res = {}) => {
	for (let key in item) {
		// create a new key name by combining the parent and current key that we want to access.
		let keyName = parent ? `${parent}_${key}` : key;
		// if the value is an object and not null, recurse into it.
		if (typeof item[key] === 'object' && item[key] !== null) {
			flattenData(item[key], keyName, res);
		} else {
			// if its not an object, add the property to the result.
			res[keyName] = item[key];
		}
	}
	return res;
};

// This function transforms an array of objects into CSV format string.
export const convertToCSV = (data, keys, columnLabels) => {
	// the array will have nested structures. The flattenData() is used to convert these nested objects into flat structure
	// to access the data as a single key value.
	const flattenedDataArray = data.map(item => flattenData(item));
	const columnHeaders = columnLabels.join(',') + '\n';

	// Generate the CSV data rows by extracting the values corresponding to the specified keys from each flattened object.
	const rows = flattenedDataArray
		.map(obj => {
			return keys
				.map(key => {
					let value = obj[key] !== undefined ? obj[key] : '';
					if (typeof value === 'string' && value.includes(',')) {
						value = `"${value}"`;
					}
					return value;
				})
				.join(',');
		})
		.join('\n');

	return columnHeaders + rows;
};

export const downloadUsersCSV = (csvData, fileName) => {
	const blob = new Blob([csvData], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);
	var link = document.createElement('a');
	link.setAttribute('hidden', '');
	link.setAttribute('href', url);
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
