import React, {useContext} from "react";
import {TableCell } from "componentsV2";
import { NavLink } from 'react-router-dom';
import { getIconFromMimeType } from "utilities/helper";
import { DocumentModalsContext } from "./DocumentModalsProvider";

export const DocumentTitleWithIcon = ({
    row
}) => {
    const {
        showDownloadModal,
    } = useContext(DocumentModalsContext);
    return (
        <TableCell
            text={
                <NavLink 
                    exact
                    to='#'
                    onClick={()=>showDownloadModal(row)}
                >
                    {row.title}
                </NavLink>
            }
            supportText={row.size}
            leftIcon = {
                getIconFromMimeType(row.mimeType, row.thumbUrl)
            }
        />
        
    )
}