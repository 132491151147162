import React, { useState ,useContext} from "react";
import {Badge, EmptyState, Button} from 'componentsV2';
import {emptyFiles} from "componentsV2/EmptyState/icons";
import '../css/BinderContent.scss';

import {BinderContentFilteration} from "./BinderContentFilteration";
import {BinderContentTable} from "./BinderContentTable";
import { BinderModalsContext } from "../../BinderModalsProvider";
export const BinderContent = ({
                                  binderDetails,
                                  filteredContent,
                                  filterationData,
                                  setFilterationData,
                                  viewMode,
                                  reloadBinderDetails
                              }) => {
        const {    
            handleAttachContentModal,
            handleUploadNewFile
        } = useContext(BinderModalsContext);




    const BinderContentButtons = <>
        <Button
            size="md"
            text={'Upload content'}
            icon="cloud-upload"
            type="primaryDefault"
            intent="default"
            onClick = {() => handleUploadNewFile(binderDetails)}
        />
        <Button
            size="md"
            text={'Attach EMS content'}
            type="tertiary"
            intent="default"
            onClick = {()=> handleAttachContentModal(binderDetails)}
        />
    </>

    return (
        <>
            {!filteredContent && binderDetails.contentCount === 0 ? (
                <EmptyState
                    header={"No content is uploaded yet."}
                    icon={emptyFiles}
                >
                    {!viewMode && !binderDetails.archivedByUser && (
                        <div className="binder-content-empty-state-footer">{BinderContentButtons}</div>
                    )}
                </EmptyState>
            ) : (
                <>
                    <section className="content-section-header-container">
                        <div className="left-side">
                            <>
                                <span className="text">Binder content</span>
                                <Badge
                                    key="sm-Gray"
                                    type="Gray"
                                    size="sm"
                                    text={binderDetails.contentCount}
                                />
                            </>
                        </div>
                        {!viewMode && !binderDetails.archivedByUser && 
                        <div className="right-side">
                            {BinderContentButtons}
                        </div>}
                    </section>
                    <section className="content-section-filter-container">
                        <BinderContentFilteration filterationData={filterationData}
                                                  setFilterationData={setFilterationData}
                        />
                    </section>
                    <BinderContentTable filteredContent={filteredContent}
                                        binderId={binderDetails.id}
                                        hasEditAccess={binderDetails.hasEditAccess}
                                        binderContent={binderDetails.binderContents}
                                        viewMode={viewMode}
                                        archiver={binderDetails.archivedByUser}
                                        reloadBinderDetails={reloadBinderDetails}/>
                </>
            )}
        </>
    );
}