import React, { useEffect, useState } from 'react';
import NormalModal from 'components/Modals/NormalModal';
import { Input } from 'components/Input';
import { StaffTypesService } from 'services/StaffTypesService';
import { RolesDropdown } from 'components/RolesDropdown';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { StaffTypesDropdown } from 'components/StaffTypesDropdown';

const AdvancedSearchModal = ({
	advancedSearchHandler,
	advancedSearchCancelBtnHandler,
	defaultSelectedSites,
	defaultSelectedRoles,
	defaultIncludeDisabledUsers,
	defaultIncludeDisabledSites,
	defaultIncludeNeverLoggedUsers,
	defaultUserName = '',
	defaultEmailLogin = '',
	defaultStaffTitle = null,
	rolesList,
	siteList,
}) => {
	const [includeDisabledSites, setIncludeDisabledSites] = useState(
		defaultIncludeDisabledSites,
	);
	const [includeDisabledUsers, setIncludeDisabledUsers] = useState(
		defaultIncludeDisabledUsers,
	);
	const [includeNeverLoggedUsers, setIncludeNeverLoggedUsers] = useState(
		defaultIncludeNeverLoggedUsers,
	);
	const [userName, setUserName] = useState(defaultUserName);
	const [emailLogin, setEmailLogin] = useState(defaultEmailLogin);
	const [sites, setSites] = useState(defaultSelectedSites);
	const [roles, setRoles] = useState(defaultSelectedRoles);
	const [staffTitle, setStaffTitle] = useState(defaultStaffTitle);
	const [staffTypes, setStaffTypes] = useState([]);

	useEffect(() => {
		StaffTypesService.getAll().then(data => {
			var staffTypes = [];
			data.forEach(element => {
				staffTypes.push({
					'value' : element.title,
					'label' : element.title
				})
			});
			setStaffTypes(staffTypes);
		});
	}, []);

	const resetValues = () => {
		
		setIncludeDisabledSites(false);
		setIncludeDisabledUsers(false);
		setIncludeNeverLoggedUsers(false);
		setUserName('');
		setEmailLogin('');
		setRoles([]);
		setSites([]);
		setStaffTitle([]);
	}

	const searchBtnClickHandler = () => {
		const formData = {
			includeDisabledSites,
			includeDisabledUsers,
			includeNeverLoggedUsers,
			userName,
			emailLogin,
			sites,
			roles,
			staffTitle,
		};
		advancedSearchHandler(formData);
	};


	return (
			<NormalModal
				onConfirmAction={searchBtnClickHandler}
				onCancelAction={advancedSearchCancelBtnHandler}
				header="Advanced search"
				confirmBtnTitle="Search"
				cancelBtnTitle="Cancel"
				confirmBtnDisabled={false}
				thirdButtonTitle="Reset"
				thirdButtonIcon="reset"
				thirdButtonAction={resetValues}
			>
				<div className="row mb-3">
					<div className="col-md-12">
						<div className="form-group mt-2 mb-3">
							<strong style={{ fontFamily: 'Merriweather' }}>
								Search Filters
							</strong>
						</div>
						<div className="form-group">
							<label className="advanced-search-checkbox">
								<input
									type="checkbox"
									onChange={val =>
										setIncludeDisabledSites(
											val.target.checked,
										)
									}
									checked={includeDisabledSites}
									className="mr-1"
								/>
								Show disabled sites
							</label>
							<SitesDropdownMultiSelect
								label="Sites"
								value={sites}
								placeholder="Select a Site"
								onChange={setSites}
								visibleOptions={1}
								allowSelectAll={false}
								sitesList={siteList}
								includeDisabled={includeDisabledSites}
								labelState={false}
							/>
						</div>
						<div className="form-group">
							<RolesDropdown
								label="Role Type"
								placeholder="Select a Role"
								value={roles}
								onChange={setRoles}
								options={rolesList}
								visibleOptions={1}
								allowSelectAll={false}
							/>
						</div>
						<div className="form-group">
							<label className="advanced-search-checkbox">
								<input
									type="checkbox"
									className="mr-1"
									checked={includeDisabledUsers}
									onChange={val =>
										setIncludeDisabledUsers(
											val.target.checked,
										)
									}
								/>
								Show disabled users
							</label>
							<Input
								label="Name"
								placeholder="Search by name"
								value={userName}
								onChange={e => setUserName(e.target.value)}
							/>
						</div>
						<div className="form-group">
							<StaffTypesDropdown
								label="Staff title"
								placeholder="Select a position"
								value={staffTitle}
								options={staffTypes}
								onChange={setStaffTitle}
								visibleOptions={2}
							/>
						</div>
						<div className="form-group">
							<label className="advanced-search-checkbox">
								<input
									type="checkbox"
									className="mr-1"
									checked={includeNeverLoggedUsers}
									onChange={val =>
										setIncludeNeverLoggedUsers(
											val.target.checked,
										)
									}
								/>
								Only show never logged in
							</label>
							<Input
								label="Email Login"
								placeholder="Search by email login"
								type="email"
								value={emailLogin}
								onChange={e => setEmailLogin(e.target.value)}
							/>
						</div>
					</div>
				</div>
			</NormalModal>
	);
};

export default AdvancedSearchModal;
