import React, { useState, useEffect} from 'react';
import './css/SortableTable.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@blueprintjs/core';

export const SortableTable = ({
    headers,
    loadedData,
    renders = [],
    headerRenders = [],
    hiddenIndex = [],
    allowDrag = true,
    handleDragEnd = () => {},
    sortableField = "index",
    customClass = "",
}) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(loadedData);
    }, [loadedData]);

    const onDragEnd = (result) => {
        //validate that the dragged item is not in the same index
        if(result.source.index === result.destination.index) {
            return null;
        }

        const draggedObj = data[result.source.index];
        const newData = reOrder(
            data,
            result.source.index,
            result.destination.index,
        );
        newData.forEach((item, index) => {
            item[sortableField] = index; 
        });
        setData(newData);
        handleDragEnd(newData, draggedObj);
    }

    const reOrder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    return (
        <div className={`tableContainerWrapperV2 ${customClass}`}>
            <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div className='react-bootstrap-table sortable-table-container' ref={provided.innerRef}>
                                <table className='table bootstrap-custom-table'>
                                <thead>
                                    <tr>
                                        {( allowDrag && <th width="50px" key="sortable"></th> )}
                                        {headers.map((header, index) => (
                                            <th key={index}>{ headerRenders[header] ? headerRenders[header](header) : header }</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item, rowIndex) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id.toString()}
                                            index={rowIndex}
                                            isDragDisabled={!allowDrag}
                                        >
                                            {(p, s) => (
                                                <tr key={rowIndex} ref={p.innerRef} {...p.draggableProps}>
                                                    {( allowDrag && <td key={`icon-${rowIndex}`}><Icon icon="drag-handle-vertical" {...p.dragHandleProps} /></td> )}
                                                    { Object.entries(item).map(([key, value], cellIndex) => {
                                                        return !hiddenIndex.includes(cellIndex) ?
                                                        (
                                                            <td key={cellIndex}>
                                                                { renders[key] ? renders[key](value, item) : value }
                                                            </td>
                                                        ) : null
                                                    })}
                                                </tr>
                                            )}
                                        </Draggable>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                        )}
                    </Droppable>
            </DragDropContext>
        </div>
    );
};
