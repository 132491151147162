import React, {useState, useEffect} from 'react'; 
//import './account-manager.scss';

export default function UploadBasic ()
{
    const [selectedFile, setSelectedFile] = useState();
    const url = process.env.API_URL;
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    
    const onSubmit = (data) => {
        console.log('new data file');
        console.log(data);

    }

    //useEffect(() => {});

    // state = {
  
    //     // Initially, no file is selected
    //     selectedFile: null
    //   };
       
    // On file select (from the pop up)
    function onFileChange(event) 
    {
       
        // Update the state
        //this.setState({ selectedFile: event.target.files[0] });

        setSelectedFile(event.target.files[0]);

    };
       
    // On file upload (click the upload button)
    function onFileUpload () 
    {
        console.log(selectedFile);

        const reader = new FileReader();
        reader.readAsDataURL(selectedFile); //event.target.files[0]

        reader.onload = (e) => {
            
            fetch(`${url}/users/uploadimage`, {
                method : 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userId : 55,
                    file : e.target.result 
                })
                //body: JSON.stringify(formData)
                //body: formData
            })
            .then(res => res.json())
            .then(resp => {
                console.log(resp);
            });
        
        };
        
        
        // Create an object of formData
        const formData = new FormData();
         
        // // Update the formData object
        // formData.append(
        //     "myFile",
        //     selectedFile, //this.state.selectedFile,
        //     selectedFile.name //this.state.selectedFile.name
        // );
       
        //console.log(selectedFile);
        //console.log(formData.get('myFile'));
        // Details of the uploaded file
        //const bodyForm = JSON.stringify({'file': selectedFile}); 
        
        //console.log(bodyForm);

       
        // Request made to the backend api
        // Send formData object
        //axios.post("api/uploadfile", formData);

        // fetch(`${url}/users/uploadimage`, {
        //     method : 'POST',
        //     headers: {
        //         'Authorization': `Bearer ${token}`,
        //     },
        //     body: JSON.stringify({'file' : selectedFile})
        //     //body: JSON.stringify(formData)
        //     //body: formData
        // })
        // .then(res => res.json())
        // .then(resp => {
        //     console.log(resp);
        // });
      };

    function fileData ()
    {
     
        if (selectedFile) {
            
            return (
                <div>
                <h2>File Details:</h2>
                <p>File Name: {selectedFile.name}</p>
        
                <p>File Type: {selectedFile.type}</p>
        
                <p>
                    Last Modified:{" "}
                    {selectedFile.lastModifiedDate.toDateString()}
                </p>
        
                </div>
            );
        } else {
            return (
                <div>
                <br />
                <h4>Choose before Pressing the Upload button</h4>
                </div>
            );
        }
    };

    return (
        <>
            <div>
                <h1>
                GeeksforGeeks
                </h1>
                <h3>
                File Upload using React!
                </h3>

                <div>
                    <input type="file" onChange={(e) => onFileChange(e)} />
                    <button onClick={onFileUpload}>
                    Upload!
                    </button>
                </div>                
                {
                    //this.fileData()
                    fileData()
                }
            </div>
        </>
    );
}
