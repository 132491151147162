import React from 'react';
import { BootstrapedTable, sortingStyle, TableCell } from 'componentsV2';
import { RolesColumn, ContactCard } from './';
import { Helper } from 'utilities/helper';
import { PhoneWithExtension } from 'utilities/PhoneWithExtension';
import ContactsListActionsButtons from './ContactsListActionsButtons';
import { PHONE_TYPES } from 'utilities/constants';

export const ContactsTable = ({
	contactsList,
	filterationData,
	userEditPermission,
	userViewPermission,
	callListEditPermission,
	dispatchFilterationData,
	totalRows,
	callListId,
	selectedCallList,
	updateDataCallBack,
}) => {
	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Contact',
			sort: true,
			headerStyle: { width: '16%' },
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => <ContactCard contact={row} />,
		},
		{
			key: 'email',
			dataField: 'person.email.emailAddress',
			text: 'Email',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
		},

		{
			key: 'roles',
			dataField: 'roles',
			text: 'Type',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => <RolesColumn row={row} callList={selectedCallList} />,
		},
		{
			key: 'homeNumber',
			dataField: 'homeNumber',
			text: 'Home phone',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				const mobileNumber = row.person.phones.find(
					phone => phone.type == PHONE_TYPES.HOME,
				);
				return Helper.formatDigitsToPhone(mobileNumber?.phoneNumber);
			},
		},

		{
			key: 'workNumber',
			dataField: 'workNumber',
			text: 'Work phone',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				const mobileNumber = row.person.phones.find(
					phone => phone.type == PHONE_TYPES.WORK,
				);
				return (
					<>
						{Helper.formatDigitsToPhone(mobileNumber?.phoneNumber)} <br />
						<p className="contactSupportText">
							{mobileNumber?.extension ? 'ext. ' + mobileNumber.extension : ''}
						</p>
					</>
				);
			},
		},
		{
			key: 'mobileNumber',
			dataField: 'mobileNumber',
			text: 'Mobile phone',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				const mobileNumber = row.person.phones.find(
					phone => phone.type == PHONE_TYPES.MOBILE,
				);
				return Helper.formatDigitsToPhone(mobileNumber?.phoneNumber);
			},
		},

		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => {
				return (
					<ContactsListActionsButtons
						row={row}
						callListId={callListId}
						userEditPermission={userEditPermission}
						userViewPermission={userViewPermission}
						callListEditPermission={callListEditPermission}
					/>
				);
			},
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = sortField;
			switch (sortField) {
				case 'person.email.emailAddress':
					sortColumn = 'email';
					break;
				case 'person.name.title':
					sortColumn = 'staffTitle';
					break;
			}
			dispatchFilterationData({ type: 'sortKey', payload: sortColumn });
			dispatchFilterationData({ type: 'sortDir', payload: sortOrder });
		}
	};

	return (
		<div className="callList-Table">
			<BootstrapedTable
				keyField="id"
				data={contactsList}
				columns={columns}
				totalRows={totalRows}
				onTableChange={onTableChange}
				currentPage={filterationData.page}
				rowsPerPage={filterationData.perPage}
				setCurrentPage={value => dispatchFilterationData({ type: 'page', payload: value })}
				setRowsPerPage={value =>
					dispatchFilterationData({ type: 'perPage', payload: value })
				}
			/>
		</div>
	);
};
