import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Dialog } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import Swal from 'sweetalert2';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

const AttachWrap = styled.div`
	height: 150px;
	width: 100%;
	border: 1px solid #d8e1e8;
	border-radius: 3px;
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

const InputLabel = styled.label`
	border: 1px solid rgba(16, 22, 26, 0.2) !important;
	color: #9f1c3a !important;
	background: #fff;
	padding: 10px 0;
	text-align: center;
`;

const FileWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

class AttachmentModal extends React.Component {
	constructor() {
		super();
		this.state = { loading: true };
		this.fileInput = React.createRef();
	}

	getData = () => {
		this.setState({ loading: true });
		const { templateId, questionId } = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${process.env.API_URL}/assessments/${templateId}/files?questionId=${questionId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${token}`,
				},
			},
		)
			.then(res => res.json())
			.then(resp => {
				this.setState({ loading: false, files: resp.data });
			});
	};

	deleteFile = id => {
		Swal.fire('Removing');
		Swal.showLoading();
		const { templateId } = this.props;

		const token = JSON.parse(localStorage.getItem('user')).jwt;

		fetch(`${process.env.API_URL}/assessments/${templateId}/files/${id}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => this.getData());
				}
			});
	};

	fileChange = e => {
		Swal.fire('Uploading');
		Swal.showLoading();
		const { templateId, questionId, closeAttachModal } = this.props;

		const token = JSON.parse(localStorage.getItem('user')).jwt;

		const fd = new FormData();
		fd.append('file', e.target.files[0]);
		fd.append('questionId', questionId);

		fetch(
			`${process.env.API_URL}/assessments/${templateId}/files?questionId=${questionId}`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: fd,
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => this.getData());
				} else if (resp.statusCode === 500) {
					closeAttachModal();
					Swal.fire({
						title: 'Oops!',
						icon: 'error',
						text: 'File size exceeds the limit of 8MB',
						showConfirmButton: true
					});
				}
			});
	};

	componentDidMount() {
		this.getData();
	}

	render() {
		const { isOpen, closeAttachModal } = this.props;
		const { loading, files } = this.state;
		return (
			<Dialog isOpen={isOpen} onClose={closeAttachModal}>
				<div>
					<TitleWrap>
						<Title>Attachments</Title>
						<Button
							text=""
							minimal
							icon="cross"
							onClick={closeAttachModal}
						/>
					</TitleWrap>
					<hr />
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<div className="px-2">
								{files.length === 0 && (
									<AttachWrap>
										<p className="mb-0">
											There are no attachments for this
											question
										</p>
									</AttachWrap>
								)}
								{files.length > 0 &&
									files.map(item => (
										<FileWrap>
											<p className="mb-0">
												{item.fileName}
											</p>
											<Button
												minimal
												icon="cross"
												onClick={() =>
													this.deleteFile(item.id)
												}
											/>
										</FileWrap>
									))}
								<InputLabel
									htmlFor="file"
									className="mt-2 w-100"
								>
									Upload image or file
								</InputLabel>
								<input
									id="file"
									style={{ display: 'none' }}
									type="file"
									onChange={this.fileChange}
								/>
							</div>
							<hr />
							<div className="d-flex justify-content-end mt-2 px-2">
								<Button
									text="Cancel"
									large
									intent="secondary"
									onClick={closeAttachModal}
								/>
								<Button
									className="ml-2"
									text="Save"
									large
									intent="primary"
									onClick={closeAttachModal}
								/>
							</div>
						</>
					)}
				</div>
			</Dialog>
		);
	}
}

AttachmentModal.propTypes = {
	isOpen: PropTypes.bool,
	templateId: PropTypes.number,
	questionId: PropTypes.number,
	closeAttachModal: PropTypes.func,
};

export default AttachmentModal;
