
export const useDragItems = (
    sortedItem,
    data
) => {

    const reOrder = ( startIndex, endIndex) => {
        const result = Array.from(data);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    //validate that the dragged item is not in the same index
    if(sortedItem.source.index === sortedItem.destination.index) {
        return data;
    }

    return reOrder(sortedItem.source.index, sortedItem.destination.index);
}