import React, { useState, useEffect, useContext } from "react";
import { Button } from "componentsV2";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { checkPermission } from "utilities/permissions";
import { BinderModalsContext } from "..";
import '../css/BinderBulkActions.scss';

export const BinderBulkActions = ({
    selectedBinders
}) => {
    const [hasEditPermission, setHasEditPermission] = useState(false);
    const [hasArchivedBinders, setHasArchivedBinders] = useState(false);
    const [hasNotArchivedBinders, setHasNotArchivedBinders] = useState(false);

    const permissions = useSelector( state => state.route.permissions);
    useEffect(async () => {
        await selectedBinders.map(selectedBinder => {
            //check edit permission
            const canEdit = checkPermission('vb_edit', permissions, selectedBinder.building?.id);
            if(canEdit) {
                setHasEditPermission(true);
            }
        });
        //check archived/unarchived binders
        const hasArchived = selectedBinders.some(selectedBinder => selectedBinder.archivedByUser)
        const hasNotArchived = selectedBinders.some(selectedBinder => !selectedBinder.archivedByUser)
        setHasArchivedBinders(hasArchived);
        setHasNotArchivedBinders(hasNotArchived);
    }, [selectedBinders])

    const { 
        showArchiveMultipleModal,
        showDeleteMultipleModal, 
    } = useContext(BinderModalsContext);

    if(!hasEditPermission) return null;

    return (
        <section className="binder-actions">
            {hasArchivedBinders && (
                <Button
                    key='Unarchive'
                    text="Unarchive"
                    type="tertiary"
                    size="sm"
                    icon="unarchive"
                    onClick={() => showArchiveMultipleModal(selectedBinders, false)}
                />
            )}
            {hasNotArchivedBinders && (
                <Button
                    key='Archive'
                    text="Archive"
                    type="tertiary"
                    size="sm"
                    icon="archive"
                    onClick={() => showArchiveMultipleModal(selectedBinders, true)}
                />
            )}
            {!hasArchivedBinders && hasNotArchivedBinders && (
                <Button
                    key='Delete'
                    text="Delete"
                    type="tertiary"
                    size="sm"
                    icon="trash"
                    onClick={() => showDeleteMultipleModal(selectedBinders)}
                />
            )}
        </section>
    );
}

BinderBulkActions.propTypes = {
	selectedBinders: PropTypes.array.isRequired,
};