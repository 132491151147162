import React, { useState, useContext, useEffect } from 'react';

import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { CallListService } from 'services/CallListService';
import { Modal, Button } from 'componentsV2';
import './css/BulkContactsModal.scss';
import Step1 from './Step1';
import Step2 from './Step2';

export const BulkContactsModal = ({ onCloseModal, selectedCallList, setReloadContacts }) => {
	const [confirmBtnTitle, setConfirmBtnTitle] = useState('Execute');
	const [disableBtns, setDisableBtns] = useState(true);
	const [currentStep, setCurrentStep] = useState(1);
	const [loading, setLoading] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [formData, setFormData] = useState();
	const [validationResData, setValidationRes] = useState();
	const [validContacts, setValidContacts] = useState([]);
	const context = useContext(ToasterContext);

	const next = () => {
		setLoading(true);
		CallListService.validateBulkContactsFile(formData)
			.then(resp => {
				validate(resp);
			})
			.catch(err => {
				if (err.statusCode == 400) {
					validate(err);
				} else {
					context('Something went wrong!', Intent.DANGER);
					setLoading(false);
					setCurrentStep(1);
				}
			});
	};

	const handleSubmit = event => {
		setModalLoading(true);
		const callListId = selectedCallList.value;

		CallListService.bulkAddContacts(validContacts, callListId)
			.then(resp => {
				if (resp.statusCode == 201) {
					context('Contacts successfully added', Intent.SUCCESS);
				} else {
					context('Contacts cannot be added', Intent.DANGER);
				}

				onCloseModal();
				setReloadContacts(oldValue => !oldValue);
				setModalLoading(false);
			})
			.catch(() => {
				context('Something went wrong!', Intent.DANGER);
				setModalLoading(false);
				onCloseModal();
			});
	};

	useEffect(() => {
		switch (currentStep) {
			case 1:
				setConfirmBtnTitle('Execute');
				break;
			case 2:
				setConfirmBtnTitle('Import');
				break;
		}
	}, [currentStep]);

	const confirmAction = () => {
		switch (currentStep) {
			case 1:
				return next();
			case 2:
				return handleSubmit();
		}
	};

	function validate(resp) {
		if (
			resp.data.invalid_headers == undefined &&
			resp.data.invalid_contacts_count == 0 &&
			resp.data.valid_contacts_count == 0
		) {
			context('Excel file is empty!', Intent.DANGER);
			setCurrentStep(1);
		} else {
			setValidationRes(resp.data);
			if (resp.data.valid_contacts_count != undefined && resp.data.valid_contacts_count > 0) {
				setValidContacts(resp.data.valid_contacts);
				setDisableBtns(false);
			} else {
				setDisableBtns(true);
			}
			setCurrentStep(currentStep + 1);
		}
		setLoading(false);
	}

	const cancelBtnClickHandler = () => {
		onCloseModal();
	};

	const footer = (
		<div className="button-div-styles">
			<Button
				text={'Cancel'}
				type="tertiary"
				intent="default"
				onClick={cancelBtnClickHandler}
			/>
			<Button
				text={confirmBtnTitle}
				intent="default"
				disabled={disableBtns}
				onClick={confirmAction}
			/>
		</div>
	);

	const resetInitialState = () => {
		setCurrentStep(1);
		setDisableBtns(true);
		setValidationRes(null);
	};

	return (
		<Modal
			isOpen={true}
			title="Import multiple contacts"
			onClose={cancelBtnClickHandler}
			loading={modalLoading}
			customClassName={'bulk-contacts-modal'}
			footer={footer}
			showCloseButton={true}
		>
			<Step1
				setDisableBtns={setDisableBtns}
				setFormData={setFormData}
				setCurrentStep={setCurrentStep}
				resetInitialState={resetInitialState}
			/>
			<Step2 loading={loading} validationResData={validationResData} />
		</Modal>
	);
};
