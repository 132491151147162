import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { Callout } from 'components/Callout';
import Swal from 'sweetalert2';
import { MoreActionsButton } from 'components/MoreActionsButton';
import { AddUsersModal } from 'components/Modals/AddUsersModal';
import { DrillEmailRemindersService } from 'services/DrillEmailRemindersService';

const Page = styled.div`
	padding: 8px 24px;

	.callout {
		margin: 24px 0px 24px 0px;
	}
`;

class ManageEmailReminders extends React.Component {
	state = {
		loading: true,
		error: null,
		modalToShow: null,
		data: [],
		buildingName: null,
	};

	componentDidMount() {
		const {
			location: {
				state: { buildingName },
			},
		} = this.props;

		this.setState({ buildingName });
		this.fetchData();
	}

	fetchData = () => {
		const {
			match: {
				params: { id },
			},
		} = this.props;

		DrillEmailRemindersService.fetch(id)
			.then(emailReminders => {
				this.setState({ data: emailReminders, loading: false });
			})
			.catch(e => {
				this.setState({ loading: false });
				console.error('error', e.message);
			});
	};

	setModalToShow = modal => {
		this.setState({
			modalToShow: modal,
		});
	};

	addUsers = async userIds => {
		const {
			match: {
				params: { id },
			},
		} = this.props;

		const { data } = this.state;

		this.setModalToShow(null);

		DrillEmailRemindersService.add(id, userIds)
			.then(addedReminders => {
				Swal.fire({
					title: 'Success!',
					text: 'User has been added to email reminders',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
				this.setState({
					data: data?.concat(addedReminders),
				});
			})
			.catch(() =>
				Swal.fire({
					title: 'Error',
					text:
						'An issue has prevented the user from being added to reminders.',
					icon: 'error',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				}),
			);
	};

	removeReminder = reminder => {
		const { data } = this.state;
		DrillEmailRemindersService.remove(reminder.id)
			.then(() => {
				this.setState({
					data: data.filter(item => item.id !== reminder.id),
				});
				Swal.fire({
					title: 'User Removed',
					text: 'User has been removed from reminders',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
			})
			.catch(() =>
				Swal.fire({
					title: 'Error',
					text: 'Could not remove user from reminders.',
					icon: 'error',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				}),
			);
	};

	render() {
		const { loading, error, data, buildingName, modalToShow } = this.state;
		const { history } = this.props;

		const hidePagination = data.length < 10;

		if (error) {
			return 'Something went wrong, please refresh';
		}

		if (loading) {
			return <LoadingSpinner />;
		}

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Manage Email Reminders"
					subtitle={buildingName}
					onBackBtnClick={history.goBack}
					actionTitle="Add Users"
					onActionClick={() => this.setModalToShow('addUsers')}
				/>

				<Callout type="info">
					Email reminders are sent three days before and one day after
				</Callout>

				<h4>Users receiving reminders</h4>
				<Table
					headers={['Name', 'Title', '', '', 'Actions']}
					numberOfItemsToDisplay={20}
					data={data}
					hidePagination={hidePagination}
					renderItem={item => (
						<>
							<td>
								{item?.user?.person?.firstName}{' '}
								{item?.user?.person?.lastName}
							</td>
							<td>{item?.user?.person?.title}</td>
							<td colSpan="2" />
							<td>
								<MoreActionsButton
									actions={[
										{
											title: 'Remove user',
											id: 'remove',
											handler: () =>
												this.removeReminder(item),
											isDestructive: true,
										},
									]}
								/>
							</td>
						</>
					)}
				/>

				{modalToShow === 'addUsers' && (
					<AddUsersModal
						setModalToShow={this.setModalToShow}
						onUsersAdd={this.addUsers}
						subtitle={buildingName}
						tip="You will only see users that are not already receiving reminders for NaviGate Elementary."
					/>
				)}
			</Page>
		);
	}
}

ManageEmailReminders.propTypes = {
	match: PropTypes.object,
	location: PropTypes.object,
	history: PropTypes.object,
};

export default ManageEmailReminders;
