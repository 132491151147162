export const removeDuplicatesWithCount = (array, key) => {
	var counts = {};
	array.forEach(element => {
		var value = element[key];
		counts[value] = counts[value] ? ++counts[value] : 1;
	});

	return array
		.filter((obj, index) => array.findIndex(item => item[key] === obj[key]) === index)
		.map(obj => ({
			...obj,
			counter: counts[obj[key]],
		}));
};
