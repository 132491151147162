import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Dialog, MenuItem } from '@blueprintjs/core';
import { differenceInCalendarDays } from 'date-fns';
import Swal from 'sweetalert2';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

/* 

       Complete
        Non complete

*/

const ValueTitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: #10161a;
	margin-bottom: 0px;
`;

const StyledTag = styled.div`
	background: #fcebeb;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #db3737;
	border-radius: 10px;
	padding: 2px 8px;
`;

const StatusText = styled.p`
	font-family: Nunito Sans;
	font-style: italic;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #738694;
	margin-bottom: 0px;
	margin-left: 6px;
`;

const CompleteTag = styled(StyledTag)`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	border-radius: 10px;
	padding: 2px 8px;
	margin-left: 6px;
	background: ${props =>
		props.disposition === 'Complete' ? '#E8F4EF' : '#EBF1F5'} !important;
	color: ${props =>
		props.disposition === 'Complete' ? '#128C5A' : '#394B59'} !important;
`;

class ViewTask extends React.Component {
	state = {
		taskDisposition: null,
		closeDisposition: '',
	};

	makeDispositions = item => (
		<MenuItem
			key={item.id}
			onClick={() => this.setState({ taskDisposition: item })}
			text={item.label}
		/>
	);

	updateQuesiton = () => {
		const { templateId, getData, sectionId } = this.props;
		const {
			question,
			hint,
			answerType,
			answerOptions,
			questionId,
		} = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${
				process.env.API_URL
			}/templates/${templateId}/questions/${questionId}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					title: question,
					hint,
					sectionId,
					properties: {
						answerType,
						answerOptions,
					},
				}),
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					return Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => getData());
				}
				return null;
			});
	};

	getStatus = () => {
		const { item } = this.props;
		const diffInDays = differenceInCalendarDays(
			new Date(item.due_date),
			new Date(),
		);

		if (item.closed_date !== null) {
			return (
				<div className="d-flex align-items-center">
					<CompleteTag disposition={item.disposition}>
						Closed: {item.disposition}
					</CompleteTag>
				</div>
			);
		}
		if (diffInDays < 0) {
			return (
				<div className="d-flex align-items-center ml-2">
					<StyledTag>Overdue</StyledTag>
					<StatusText>
						{Math.abs(diffInDays)} days past due
					</StatusText>
				</div>
			);
		}
		return null;
	};

	submitCloseTask = () => {
		Swal.fire('Updating');
		Swal.showLoading();
		const { item, getData } = this.props;
		const { taskDisposition, closeDisposition } = this.state;

		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/tasks/${item.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				disposition: taskDisposition.value,
				closedNotes: closeDisposition,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					return Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => getData());
				}
				return null;
			});
	};

	render() {
		const { isOpen, closeViewTask, item } = this.props;

		const dueDate = item.due_date;
		const createdDate = item.created_date;
		const closedDate = item.closed_date;
		const formatDate = () => {
			const options = {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			};
			return (
				new Date(dueDate).toLocaleDateString(undefined, options),
				new Date(createdDate).toLocaleDateString(undefined, options),
				new Date(closedDate).toLocaleDateString(undefined, options)
			);
		};

		return (
			<Dialog
				style={{ width: 450 }}
				isOpen={isOpen}
				onClose={closeViewTask}
			>
				<div>
					<TitleWrap>
						<Title>View Task</Title>
						<Button
							text=""
							minimal
							icon="cross"
							onClick={closeViewTask}
						/>
					</TitleWrap>
					<hr />
					<div className="px-2">
						<ValueTitle>Template</ValueTitle>
						<p>{item.assessment_name}</p>
						<ValueTitle>Section</ValueTitle>
						<p>{item.section_name}</p>
						<ValueTitle>Question</ValueTitle>
						<p>{item.question_text}</p>
						<ValueTitle>Status</ValueTitle>
						<p className="d-flex">{this.getStatus()}</p>
					</div>

					<hr />
					<div className="px-2">
						<ValueTitle>Site</ValueTitle>
						<p>{item.building_name}</p>
						<ValueTitle>Title</ValueTitle>
						<p>{item.title}</p>
						<ValueTitle>Description</ValueTitle>
						<p>{item.closed_notes}</p>
						<ValueTitle>Assigned To: </ValueTitle>
						<p>{item.assigned_to_name}</p>
						<ValueTitle>Due Date: </ValueTitle>
						<p>{formatDate(dueDate)}</p>
						{item.created_by_name.trim().length > 0 ? (
							<>
								<ValueTitle>Created By:</ValueTitle>
								<p>
									{item.created_by_name} |{' '}
									{formatDate(createdDate)}
								</p>
							</>
						) : (
							<>
								<ValueTitle>Created Date:</ValueTitle>
								<p>{formatDate(createdDate)}</p>
							</>
						)}
						{item.closed_by_name.trim().length > 0 ? (
							<>
								<ValueTitle>Closed By:</ValueTitle>
								<p>{item.closed_by_name}</p>
							</>
						) : null}
					</div>
					<hr />
					<div className="d-flex justify-content-end mt-2 px-2">
						<Button
							text="Done"
							large
							intent="secondary"
							onClick={closeViewTask}
						/>
					</div>
				</div>
			</Dialog>
		);
	}
}

ViewTask.propTypes = {
	isOpen: PropTypes.bool,
	templateId: PropTypes.any,
	sectionId: PropTypes.any,
	getData: PropTypes.func,
	closeViewTask: PropTypes.func,
	item: PropTypes.object,
};

export default ViewTask;
