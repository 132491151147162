import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ActionsButton, Button, TooltipPosition } from 'componentsV2';
import { BinderModalsContext } from 'pages/Binders';
import { DocumentModalsContext } from 'pages/Documents';
import { MapsContext, OldSafetyPlanTooltip } from '../../../../Providers';
import { ShapeContentContext } from '../Providers';
import { REMOVE_MODAL } from './ContentConstansts';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from 'react-redux';

const ActionListButton = ({ text, icon, onClick, disabled = false }) => {
	return (
		<Button
			key={text}
			text={text}
			icon={icon}
			size="md"
			type="ghostDefault"
			wrapperClass="displayBlock"
			className="actionButtonElement"
			onClick={onClick}
			disabled={disabled}
		/>
	);
};

export const ShapeContentActionsList = ({
	item,
	itemType,
	elementSelector = null,
	editPermission,
	floorplanArchived,
	selectedShape,
	isEditMode,
	viewPermissionOnSiteId,
	floorplan,
}) => {
	const MapCTX = useContext(MapsContext);
	const shapeContentContext = useContext(ShapeContentContext);
	const DocumentContext = useContext(DocumentModalsContext);
	const BinderContext = useContext(BinderModalsContext);
	const hasEditPermissionAndEditMode = editPermission && isEditMode;
	const permissions = useSelector(state => state.route.permissions);

	const hasEditPermissionOnBinders = binder => {
		return checkPermission('vb_edit', permissions, binder.building ? binder.building.id : null);
	};

	const DownloadDocumentActionWrapper = ({ item, children }) => {
		if (item.hasOldSP) {
			const hasEditPermissionOnBinder = hasEditPermissionOnBinders(item);
			const message = hasEditPermissionOnBinder
				? 'This binder has an outdated safety plan. Please go to Binders and update the outdated plans in order to download binders from maps.'
				: 'This binder has an outdated safety plan. Please contact your administrator to update these plans in the Binders section before downloading binders from the maps.';

			return (
				<OldSafetyPlanTooltip
					message={message}
					editPermissionOnBinders={hasEditPermissionOnBinder}
					position={TooltipPosition.LEFT}
					binderId={item.id}
				>
					{children}
				</OldSafetyPlanTooltip>
			);
		}
		return <>{children}</>;
	};

	return (
		<ActionsButton
			popoverClassName="map-layers-actions-list"
			elementSelector={elementSelector}
			customClass="shape-content-actionlist"
		>
			{['360image', 'image'].includes(itemType) && (
				<ActionListButton
					large
					text="Open"
					icon="eye-open"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => DocumentContext.showViewImageModal(item)}
				/>
			)}
			{['document', 'video', 'image', '360image', 'binder'].includes(itemType) && (
				<>
					<DownloadDocumentActionWrapper item={item}>
						<ActionListButton
							large
							text="Download"
							icon="cloud-download"
							size="md"
							type="ghostDefault"
							intent="default"
							wrapperClass="displayBlock"
							className="actionButtonElement"
							onClick={() =>
								itemType == 'binder'
									? BinderContext.downloadBinder(item, floorplan.id)
									: DocumentContext.showDownloadModal(
											item,
											true,
											null,
											floorplan.id,
									  )
							}
							disabled={item.hasOldSP}
						/>
					</DownloadDocumentActionWrapper>
					{editPermission && !floorplanArchived && isEditMode && (
						<ActionListButton
							large
							text="Remove"
							icon="remove"
							size="md"
							type="ghostDefault"
							intent="default"
							wrapperClass="displayBlock"
							className="actionButtonElement"
							onClick={() => shapeContentContext.showRemoveContentModal(REMOVE_MODAL)}
						/>
					)}
				</>
			)}
			{itemType == 'notes' && (
				<>
					{(viewPermissionOnSiteId || floorplan.isVisibleToAll) &&
						!hasEditPermissionAndEditMode && (
							<ActionListButton
								large
								text="View"
								icon="eye-open"
								size="md"
								type="ghostDefault"
								intent="default"
								wrapperClass="displayBlock"
								className="actionButtonElement"
								onClick={() =>
									MapCTX.showViewUpdateNoteModal(item, selectedShape, 'view')
								}
							/>
						)}
					{editPermission && !floorplanArchived && isEditMode && (
						<>
							<ActionListButton
								large
								text="Edit"
								icon="edit"
								size="md"
								type="ghostDefault"
								intent="default"
								wrapperClass="displayBlock"
								className="actionButtonElement"
								onClick={() =>
									MapCTX.showViewUpdateNoteModal(item, selectedShape, 'update')
								}
							/>
							<ActionListButton
								large
								text="Delete"
								icon="trash"
								size="md"
								type="ghostDefault"
								intent="default"
								wrapperClass="displayBlock"
								className="actionButtonElement"
								onClick={() => MapCTX.showDeleteNoteModal(item, selectedShape)}
							/>
						</>
					)}
				</>
			)}

			{itemType == 'links' && (
				<>
					{(viewPermissionOnSiteId || floorplan.isVisibleToAll) &&
						!hasEditPermissionAndEditMode && (
							<ActionListButton
								large
								text="View"
								icon="eye-open"
								size="md"
								type="ghostDefault"
								intent="default"
								wrapperClass="displayBlock"
								className="actionButtonElement"
								onClick={() =>
									MapCTX.showViewUpdateCameraLinkModal(
										item,
										selectedShape,
										'view',
									)
								}
							/>
						)}
					{editPermission && !floorplanArchived && isEditMode && (
						<>
							<ActionListButton
								large
								text="Edit"
								icon="edit"
								size="md"
								type="ghostDefault"
								intent="default"
								wrapperClass="displayBlock"
								className="actionButtonElement"
								onClick={() =>
									MapCTX.showViewUpdateCameraLinkModal(
										item,
										selectedShape,
										'update',
									)
								}
							/>
							<ActionListButton
								large
								text="Delete"
								icon="trash"
								size="md"
								type="ghostDefault"
								intent="default"
								wrapperClass="displayBlock"
								className="actionButtonElement"
								onClick={() =>
									MapCTX.showDeleteCameraLinkModal(item, selectedShape)
								}
							/>
						</>
					)}
				</>
			)}
		</ActionsButton>
	);
};
ActionListButton.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

ShapeContentActionsList.propTypes = {
	item: PropTypes.object,
	itemType: PropTypes.string,
	sourcePage: PropTypes.element,
	elementSelector: PropTypes.element,
	history: PropTypes.object,
};
