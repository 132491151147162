import React from 'react';
import styled from 'styled-components';
import { Switch } from "@blueprintjs/core";

const Wrapper = styled.div`
	width: 420px;
	height: 190px;
	display: flex;
	padding: 16px;
	margin-right: 40px;
	border-radius: 3px;
	margin-bottom: 40px;
	background: #ffffff;
	flex-direction: column;
	box-shadow: 0px 0px 0px 1px rgba(16, 22, 26, 0.15);

	h4 {
		color: #10161a;
		font-size: 18px;
		line-height: 21px;
		font-weight: bold;
		font-style: normal;
		letter-spacing: 0.3px;
		font-family: Merriweather;
	}

	p {
		height: 60px;
		color: #10161a;
		font-size: 14px;
		margin-top: 10px;
		line-height: 20px;
		font-style: normal;
		font-weight: normal;
		font-family: Nunito Sans;
	}

	.line {
		width: 100%;
		height: 1px;
		background: #ebf1f5;
	}

	button {
		width: 60px;
		color: white;
		display: flex;
		padding: 6px 0;
		cursor: pointer;
		margin-top: 15px;
		border-radius: 3px;
		align-items: center;
		box-sizing: border-box;
		justify-content: center;
		background-color: #9f1c3a;
		border: 1px solid rgba(16, 22, 26, 0.2);
	}

    .switch {
        margin-top: 15px;
    }
`;

const SettingsCard = ({ title, description, onChange }) => {
	return (
		<Wrapper>
			<h4>{title}</h4>
			<p>{description}</p>
			<div className="line" />
            <Switch
                onChange={onChange}
                className="switch"
                large={true}
                innerLabel="off"
                innerLabelChecked="on"
            />
		</Wrapper>
	);
};

export { SettingsCard };