import React, { useContext } from 'react';
import { getFormattedDate } from 'utilities/dates';
import { TableCell, FileIcon, Tooltip, TooltipPosition } from 'componentsV2';
import { BinderModalsProvider } from 'pages/Binders';
import { DocumentModalsProvider } from 'pages/Documents';
import { ShapeContentActionsList } from './ShapeContentActionsList';
import { getIconFromMimeType } from 'utilities/helper';
import { Icon } from '@blueprintjs/core';
import { ShapeContentProvider } from '../Providers';
import { ShapeContentImageLink } from './ShapeContentImageLink';
import '../css/ShapeContent.scss';

export const ShapeContentItem = ({
	item,
	id,
	link,
	contentTitle,
	contentType,
	shapeType,
	shapeId,
	siteId,
	ext = null,
	mimetype = null,
	thumbnail = null,
	createdDate = null,
	createdBy = null,
	editPermission,
	isEditMode,
	floorplanArchived,
	selectedShape,
	loadContentData,
	viewPermissionOnSiteId,
	floorplan,
}) => {
	return (
		<ShapeContentProvider
			history={history}
			item={item}
			itemType={contentType}
			shapeType={shapeType}
			shapeId={shapeId}
			siteId={siteId}
			loadContentData={loadContentData}
		>
			<DocumentModalsProvider
				history={history}
				item={item}
				itemType={contentType}
				shapeType={shapeType}
				shapeId={shapeId}
				siteId={siteId}
			>
				<BinderModalsProvider history={history}>
					<div className="area-content-item" key={id}>
						<div className="area-content-left-side">
							{contentType == 'links' && (
								<Icon icon="link" className="non-border camera-link-icon" />
							)}
							{['notes', 'video', 'binder'].includes(contentType) && (
								<FileIcon sourceURL={null} icon={ext} />
							)}
							{contentType == 'document' && getIconFromMimeType(mimetype, thumbnail)}
							{['image', '360image'].includes(contentType) && (
								<FileIcon
									sourceURL={thumbnail ? thumbnail : null}
									icon={thumbnail ? null : ext}
								/>
							)}
							<TableCell
								className={contentType == 'links' ? 'camera-link-content' : ''}
								text={
									contentType == 'links' ? (
										' '
									) : (
										<Tooltip
											tooltipContent={contentTitle}
											theme={'dark'}
											position={TooltipPosition.TOP}
										>
											<div className={'content-tooltip-text'}>
												{['360image', 'image'].includes(contentType) ? (
													<ShapeContentImageLink item={item} />
												) : (
													contentTitle
												)}
											</div>
										</Tooltip>
									)
								}
								supportText={
									contentType == 'links' ? (
										<Tooltip
											tooltipContent={link}
											theme={'dark'}
											position={TooltipPosition.TOP}
										>
											<div className={'camera-link-content'}>{link}</div>
										</Tooltip>
									) : (
										`Added by ${createdBy?.first_name} ${
											createdBy.last_name
										}, ${getFormattedDate(createdDate.date)}`
									)
								}
							/>
						</div>
						<div className="area-content-right-side">
							<ShapeContentActionsList
								item={item}
								itemType={contentType}
								editPermission={editPermission}
								isEditMode={isEditMode}
								floorplanArchived={floorplanArchived}
								selectedShape={selectedShape}
								viewPermissionOnSiteId={viewPermissionOnSiteId}
								floorplan={floorplan}
							/>
						</div>
					</div>
				</BinderModalsProvider>
			</DocumentModalsProvider>
		</ShapeContentProvider>
	);
};
