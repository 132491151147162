import React, { useEffect, useState } from 'react'
import { PrintMapFooterItem } from './PrintMapFooterItem'
import { 
  GEOMETRY_TYPE_POLYGON, 
  GEOMETRY_TYPE_ICON, 
  MAP_LEGEND 
} from '../../consts';

export const PrintMapFooter = ({
  floorplan,
  legends,
  floorplanShapes,
}) => {

  const [iconsInUse, setIconsInUse] = useState([]);
  const [legendsInUse, setLegendsInUse] = useState([]);

  useEffect(() => {
    if (floorplan) {
      let iconsArray = [];
      let iconFound = null;
      let colorsArray = [];
      let legendFound = null;
      let legendsArray = [];
			floorplanShapes.filter(floorplanShape => floorplanShape.visible === true)
      	.forEach(shape => {
        switch (shape.geometryType) {
          case GEOMETRY_TYPE_POLYGON:
            if (!colorsArray.includes(shape.color)) {
              colorsArray.push(shape.color);
              legendFound = legends.find(legend => legend.hex === shape.color);
              if (legendFound) {
                legendsArray.push(legendFound);
              }
            }
            break;
          case GEOMETRY_TYPE_ICON:
            if (shape?.icon?.id) {
              iconFound = iconsArray.find(icon => icon.id === shape.icon.id);
              if (!iconFound) {
                iconsArray.push({
                  id: shape.icon.id,
                  iconURL: shape.icon.iconURL,
                  title: shape.icon.title,
                });
              }
            }
            break;
        }
      });
      setIconsInUse(iconsArray);
      setLegendsInUse(legendsArray);
    }
  }, [floorplan]);

  return (iconsInUse.length > 0 || legendsInUse.length > 0) && (
    <div className="map-footer-container">

      {iconsInUse.length > 0 && (
        <div className="map-footer-section-container">
          {iconsInUse.map(icon => 
            <PrintMapFooterItem 
              key={`${GEOMETRY_TYPE_ICON}-${icon.id}`}
              type={GEOMETRY_TYPE_ICON}
              item={icon}
            />
          )}
        </div>
      )}

      {legendsInUse.length > 0 && (
        <div className="map-footer-section-container">
          {legendsInUse.map(legend => 
            <PrintMapFooterItem 
              key={`${MAP_LEGEND}-${legend.id}`}
              type={MAP_LEGEND}
              item={legend}
            />
          )}
        </div>
      )}
    </div>
    )
}
