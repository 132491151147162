import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, ActionsButton } from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import './css/DistrictTemplateHeader.scss';
import { BreadCrumbHeader, PlanUpdates } from '../../../Shared';
import { DISTRICT_LIST_PATH } from '../../consts';
import { DistrictTemplateContext } from '../../Providers';
import { getFormattedDate } from 'utilities/dates';

export const DistrictTemplateHeader = ({
	template,
	saveTemplate,
	viewMode,
	templateUpdates,
	openModal,
	variableImagesIDs,
	variableValues,
	indicator,
	history,
}) => {
	const {
		showRenameModal,
		showCopyModal,
		getAndDownloadSafetyPlan,
		showArchiveModal,
		showDeleteModal,
	} = useContext(DistrictTemplateContext);

	const breadcrumbItems = [
		{
			label: 'Safety plans',
			url: DISTRICT_LIST_PATH,
		},
		{
			label: viewMode ? 'Safety plan viewer' : 'Safety plan editor',
		},
	];

	const ListButton = ({ text, icon, onClick }) => (
		<Button
			text={text}
			icon={icon}
			size="md"
			type="ghostDefault"
			intent="default"
			wrapperClass="displayBlock"
			className="actionButtonElement"
			onClick={onClick}
		/>
	);

	const headerCustomItem = () => (
		<>
			{viewMode && (
				<>
					{!template.archivedByUser && (
						<Button
							size="lg"
							text="Download"
							icon="cloud-download"
							onClick={() => getAndDownloadSafetyPlan(template.id, template.name)}
						/>
					)}

					{!template.archivedByUser && template.hasEditPermission && (
						<Button
							size="lg"
							text="Edit"
							type="tertiary"
							icon="edit"
							onClick={() => {
								history.push(`${DISTRICT_LIST_PATH}/${template.id}`);
							}}
						/>
					)}

					{template.archivedByUser && template.hasEditPermission && (
						<Button
							size="lg"
							text="Unarchive"
							type="tertiary"
							icon="unarchive"
							onClick={() =>
								showArchiveModal({
									id: template.id,
									title: template.name,
									archived: template.archivedByUser,
									source: 'details',
								})
							}
						/>
					)}
				</>
			)}
			{!viewMode && (
				<>
					{!template.archivedByUser && template.hasEditPermission && (
						<Button
							size="lg"
							text={!template.isCompleted ? 'Mark as complete' : 'Mark as incomplete'}
							intent="default"
							onClick={() => openModal('completeModal')}
						/>
					)}
					<Button
						size="lg"
						text="View"
						type="secondaryDefault"
						icon="eye-open"
						onClick={() => {
							history.push('/safety-plans/view/' + template.id);
						}}
					/>
					<ActionsButton
						elementSelector={<Button type="tertiary" size="lg" icon="more" />}
					>
						{template.hasEditPermission && (
							<>
								<ListButton
									text="Edit plan title"
									icon="edit"
									onClick={() =>
										showRenameModal({
											id: template.id,
											title: template.name,
											oldTitle: template.name,
										})
									}
								/>
								<ListButton
									text="Attach files"
									icon="paperclip"
									onClick={() => openModal('bonusFilesModal')}
								/>
							</>
						)}

						<ListButton
							text="Download"
							icon="cloud-download"
							onClick={() => getAndDownloadSafetyPlan(template.id, template.name)}
						/>

						{template.hasEditPermission && (
							<>
								<ListButton
									text="Copy"
									icon="duplicate"
									onClick={() =>
										showCopyModal({
											id: template.id,
											title: template.name,
											siteId: template.buildingId,
										})
									}
								/>
								<ListButton
									text={!template.archivedByUser ? 'Archive' : 'Unarchive'}
									icon={!template.archivedByUser ? 'archive' : 'unarchive'}
									onClick={() =>
										showArchiveModal({
											id: template.id,
											title: template.name,
											archived: template.archivedByUser,
											source: 'details',
										})
									}
								/>
								<ListButton
									text="Delete"
									icon="trash"
									onClick={() => {
										showDeleteModal(template);
									}}
								/>
							</>
						)}
					</ActionsButton>
				</>
			)}
		</>
	);

	const getAutosave = template => {
		if (template.lastUpdatedFormatted) {
			return template.lastUpdatedFormatted;
		}

		return 'N/A';
	};

	const breadCrumbCustomItem = () => {
		const saveText = viewMode ? (
			template.archivedByUser ? (
				<>
					<Icon icon="archive" /> {'Archived by: '}
					{template.archivedByUser ? template.archivedByUser.person.fullName : ''}
					{' on '}
					{template.archivedDate ? getFormattedDate(template.archivedDate.date) : ''}
				</>
			) : (
				'Last saved: ' + getFormattedDate(template.lastUpdated)
			)
		) : indicator ? (
			<>
				<Icon icon="small-tick" /> Auto Saved: {getAutosave(template)}
			</>
		) : (
			'Not saved'
		);

		return (
			<>
				<Button
					size="sm"
					text={viewMode ? 'Exit' : 'Save and exit'}
					intent="default"
					onClick={() =>
						viewMode ? history.push(DISTRICT_LIST_PATH) : saveTemplate(true)
					}
					className="CrumbButtonActions"
				/>

				<Badge
					hasLeadIcon={false}
					leadIcon="tick"
					key="lg-Gray"
					type="Gray"
					size="lg"
					text={saveText}
				/>
			</>
		);
	};

	const UpdateTitle = () => (
		<>
			There{' '}
			{templateUpdates.length === 1
				? 'is 1 pending update to this plan'
				: `are ${templateUpdates.length} pending updates to this plan`}
		</>
	);

	return (
		<div className="plan-header-container">
			<BreadCrumbHeader
				title={template.name}
				breadcrumbItems={breadcrumbItems}
				customItem={() => headerCustomItem()}
				breadCrumbCustomItem={() => breadCrumbCustomItem()}
			>
				{!viewMode && templateUpdates.length !== 0 && (
					<PlanUpdates
						title={<UpdateTitle />}
						onClick={() => {
							openModal('ViewUpdatesModal');
						}}
					/>
				)}
			</BreadCrumbHeader>
		</div>
	);
};

DistrictTemplateHeader.propTypes = {
	template: PropTypes.object,
	saveTemplate: PropTypes.func,
	viewMode: PropTypes.bool,
	templateUpdates: PropTypes.array,
	openModal: PropTypes.func,
	exportPdf: PropTypes.func,
};
