/* eslint-disable */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog, Tab, Tabs, Icon, Tag } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { parseISO } from 'date-fns';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import VisibilitySensor from 'react-visibility-sensor';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import selectRoute from './selectors';
import { parseDataModel, getFields } from 'utilities';
import {validateTemplateSections} from 'utilities/'
import VariableOnly from './variableonly';
import SideBar from './sidebar';
import { SingleEditor } from './singleeditor';
import {
	exportHeader,
	exportHeaderTables,
	exportCustomStylesForSections,
} from './exportheader';
import FileSection from './filesection';
import { UploadModals } from './UploadModals/';
import UpdateModal from './UpdateModal/';
import BonusFilesModal from './bonusfilesmodal';
import CompletePlanModal from './completeplanmodal';
import { BackToTopButton } from 'components/BackToTopButton';
import { decodeToken } from 'utilities';
import DeleteSectionModal from '../SafetyPlan/Modals/DeleteSectionModal';
import { PlanService } from 'services/PlanService';
import { fireSwal } from 'utilities/fireSwal';
import { scrollToSidebarSection } from 'utilities/SafetyPlan/helpers';


// Important: To maintain the 1-1 relationship between pages and sections. Keep this value at 1
const sectionsPerPage = 1;

const StyledTag = styled(Tag)`
	background: ${props =>
		props.green === 'true' ? '#E8F4EF' : '#FCF3EA'} !important;
	color: ${props =>
		props.green === 'true' ? '#128C5A' : '#D9822B'} !important;
	margin-left: 8px;
`;

const Wrapper = styled.div`
	display: flex;
	flex: 1;
`;

const TextAreaContainer = styled.div`
	padding: 0 15px;
	background: #fff;
`;

const ColoredIcon = styled(Icon)`
	color: ${props => (props.indicator === 'true' ? 'green' : 'red')};
`;

const TemplateName = styled.h1`
	font-family: Merriweather;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 32px;
	color: #394b59;
`;

const BackButtonWrap = styled.button`
	color: #5c7080;
	background: none;
	border: none;
	margin-right: 6px;
`;

const BackText = styled.span`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #5c7080;
	margin-left: 10px;
`;

const SmallIcon = styled(Icon)`
	transform: scale(0.7);
	margin-top: 1px;
	margin-left: -3px;
`;

const AutoSaveWrap = styled.div`
	display: flex;
	align-items: center;
	color: #5c7080;
`;

const BlankInput = styled.input`
	font-family: Merriweather;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 32px;
	color: #293742;
	border: none;
	flex: 1;
	padding: 0;
	&:focus {
		outline: none;
	}
`;

const TemplateNameInput = styled(BlankInput)`
	font-size: 32px;
`;

const VariableNumber = styled.span`
	white-space: nowrap;
`;

const HeaderWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #f5f8fa;
	margin-left: -16px;
	margin-right: -16px;
	margin-top: -16px;
	padding: 16px 16px 0 16px;
	margin-bottom: 16px;
	border-bottom: 1px solid #d0d9e0;
`;

const DeleteButton = styled(Button)`
	font-size: 10px !important;
	margin-right: 6px;
	font-weight: bold;
`;

const UpdateWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #faf2f3;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: -16px;
	padding: 8px 16px;
	margin-bottom: 16px;
	p {
		margin-bottom: 0;
	}
`;

const UpdateLocalWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #F5F5DC;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: -16px;
	padding: 8px 16px;
	margin-bottom: 16px;
	p {
		margin-bottom: 0;
	}
`;

class EditTemplate extends React.Component {
	state = {
		nodes: [],
		content: '',
		loading: true,
		commonVariables: [],
		panel: 'contextualeditor',
		exportLoading: false,
		uploadModal: false,
		uploadSection: null,
		uploadSubsection: null,
		uploadBaseFile: null,
		uploadValueFile: null,
		bonusFilesModal: false,
		updateModal: false,
		districtUpdateModal: false,
		completeModal: false,
		activeSection: null,
		hasResetUpdate: false,
		currentPage: 0,
		yPosition: 0,
		page: 0,
		variableValues: [],
		showBackToTopButton: true,
		removeSubsectionReference: true,
		updateMode: false,
		updateTarget: null,
		updateType: null,
		hasPermissions: false,
		modalToShow: false,
		isSectionScrollActive: true,
		startScrolling: false,
		viewMode: false,
		templateOldName: '',
		templateOldContent: {},
		templateSavedBeforeUnmount: false,
		variableImagesIDs: []
	};
	constructor() {
		super();
		this.sectionRef = React.createRef();
		this.sidebarRef = React.createRef();
		this.mainWrapperRef = React.createRef();
	}
	loadData = async (firstLoad) => {
		this.setState({
			loading: true,
			bonusFilesModal: false,
			panel: 'contextualeditor',
			updateModal: false,
			districtUpdateModal: false
		});

		const {
			match: {
				params: { id },
			},
			location,
			dispatch,
			history,
			viewMode,
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;

		dispatch({
			type: 'CLEAR_TEMPLATE_DATA',
		});

		Promise.all([
			fetch(`${process.env.API_URL}/district-templates/${id}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
			fetch(`${process.env.API_URL}/district-templates/${id}/variablevalues`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			),
		])
			.then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
			.then(async ([resp1, resp2]) => {
				// if (resp1.statusCode !== 200 || resp2.statusCode !== 200) {
				// 	const localToken = JSON.parse(localStorage.getItem('user'));
				// 	localToken.jwt = '';
				// 	localStorage.setItem('user', JSON.stringify(localToken));
				// 	return window.location.reload();
				// }
				if(!resp1.data.hasEditPermission && !viewMode) {
					return history.push(`/view/${id}`);
				}

				const templateUpdates = resp1.data.updates;
				const hasResetUpdate = templateUpdates.some(templateUpdate =>  templateUpdate.type === 'reset');
				let template = {
					id,
          			is_template: false,
					name: resp1.data.name,
					lastUpdated: resp1.data.createdDate,
					lastUpdatedFormatted: resp1.data.lastUpdateTime,
					buildingId: resp1.data.buildingId,
					createdFromDistrict: resp1.data.createdFromDistrict,
					hasEditPermission: resp1.data.hasEditPermission,
					sections: [
						{
							id: 1,
							title: 'Section 1 title',
							subsections: [
								{
									id: 11,
									title: 'Subsection 1 title',
									content: '<p>Subsection 1 content</p>',
								},
							],
						},
					],
					variableValues: resp2.data,
					bonusFiles: resp1.data.bonusFiles
						? JSON.parse(resp1.data.bonusFiles)
						: [],
					updates: templateUpdates,
					localUpdates: resp1.data.localUpdates
				};
				if (resp1.data.headers) {
					template.headers = JSON.parse(resp1.data.headers);
				}
				if (resp1.data.content) {
					if (firstLoad) {
						// Only on the First Load, set the Initial Information
						// of the template in the state, needed to log changes
						this.setState({
							templateOldName: resp1.data.name,
							templateOldContent: resp1.data.content
						});
					}
					template.sections = JSON.parse(resp1.data.content)?.sections || JSON.parse(resp1.data.content);
					template = parseDataModel(template);
				}
				const firstSection = template?.sections[0];
				let activeSection = this.state.activeSection;
				if(firstSection && activeSection === null ) {
					activeSection = {
						sectionId: firstSection.id,
						subSectionId: null
					}
				}
				const isUpdateAction = location?.state;
				if (isUpdateAction !== undefined &&	isUpdateAction.isUpdate && templateUpdates.length != 0 ) {
					this.openUpdateModal();
				}

				const tempCommon = resp1.data.commonVariables;
				tempCommon.forEach(item => {
					let total = 0;
					template.sections.forEach(one => {
						if (one.content.includes(item.name)) {
							total += 1;
						}
						one.subsections.forEach(single => {
							if (single.content.includes(item.name)) {
								total += 1;
							}
						});
					});
					item.total = total;
				});

				const tempTemplate = resp1.data.templateVariables;
				tempTemplate.forEach(item => {
					let total = 0;
					template.sections.forEach(one => {
						if (one.content.includes(item.name)) {
							total += 1;
						}
						one.subsections.forEach(single => {
							if (single.content.includes(item.name)) {
								total += 1;
							}
						});
					});
					item.total = total;
				});
				this.setState({
					commonVariables: resp1.data.commonVariables,
					templateVariables: resp1.data.templateVariables
				});
				const variableValues = [];
				const variableImagesIDs = []
				await resp2.data.forEach( async variableValue => {
					const item = variableValue;
					const variableType = this.getVariableType(variableValue);
					item.type = variableType;

					if(variableType === 'image') {
						if ( this.isInt(item.variableValue) ) {
							variableImagesIDs.push(parseInt(item.variableValue));
						}
					}
					variableValues.push(item);
				});
				this.setState({
					variableImagesIDs: variableImagesIDs
				});
				let imagesValues = []
				await this.fetchImages(variableImagesIDs).then((res) => {
					imagesValues = res;
				})
				for (const imagesValue of imagesValues) {
					variableValues.forEach(variableValue => {
						if(variableValue.variableValue == imagesValue.id) {
							variableValue.imageInfo = imagesValue
						}
					})
				}
				this.setState({ variableValues: variableValues});

				if (
					resp2.data.length > 0 &&
					(template.legacyParseDone === true ||
						template.legacyParseDone)
				) {
					Swal.fire({
						icon: 'warning',
						title: 'Optimizing your template',
						text: `We are optimizing your template for loading times. We will only do this once, please don't reload the page before this process finishes.`,
					});
					Swal.showLoading();
					template.sections.forEach(section => {
						resp2.data.forEach(item => {
							if (this.isStringUrl(item.variableValue)) {
								let varInfo = {};
								resp1.data.templateVariables.forEach(one => {
									if (one.name === item.variableName) {
										varInfo = JSON.parse(one.content);
									}
								});
								section.content = section.content.replaceAll(
									`<div><img src="${item.variableValue}" style="width: auto; height: 300px"/></div>`,
									`<span class="variable-highlight">${item.variableName}</span>`,
								);
							} else {
								section.content = section.content.replaceAll(
									`<span class="variable-value-highlight" contenteditable="false">${item.variableValue}</span>`,
									`<span class="variable-highlight">${item.variableName}</span>`,
								);
							}
						});
						section.subsections.forEach(subsection => {
							resp2.data.forEach(item => {
								if (this.isStringUrl(item.variableValue)) {
									let varInfo = {};
									resp1.data.templateVariables.forEach(
										one => {
											if (
												one.name === item.variableName
											) {
												varInfo = JSON.parse(
													one.content,
												);
											}
										},
									);
									subsection.content = subsection.content.replaceAll(
										`<div><img src="${item.variableValue}" style="width: auto; height: 300px"/></div>`,
										`<span class="variable-highlight">${item.variableName}</span>`,
									);
								} else {
									subsection.content = subsection.content.replaceAll(
										`<span class="variable-value-highlight" contenteditable="false">${item.variableValue}</span>`,
										`<span class="variable-highlight">${item.variableName}</span>`,
									);
								}
							});
						});
					});
					template.legacyParseDone = false;
					Swal.close();
				} else {
					template.legacyParseDone = false;
				}
				const filteredSections = JSON.parse(JSON.stringify(template));
				if(viewMode) {
					filteredSections.sections.length = (template.sections.length > sectionsPerPage) ? parseInt(sectionsPerPage+10) : template.sections.length;
				} else {
					filteredSections.sections.length = (template.sections.length > sectionsPerPage) ? sectionsPerPage : template.sections.length;
				}

				//set updateModal to true if the updates has reset update
				if(hasResetUpdate === true && !viewMode) this.openUpdateModal();

				this.setState({
					activeSection,
					hasResetUpdate: hasResetUpdate,
					loading: false
				})
				return dispatch({
					type: 'LOAD_TEMPLATE',
					payload: template,
					filtered: filteredSections
				});
			});
	};
	cancelUpdateMode = () => {
		this.setState({
			updateMode: false,
			updateTarget: null,
			updateType: null,
			loading: true,
		});
		return this.loadData();
	};

	toggleUpdateFileMode = (
		val,
		updateTarget,
		updateType,
		actualSectionId,
		actualSubsectionId,
	) => {
		const {
			dispatch,
			route: { template },
		} = this.props;
		this.setState({
			updateMode: val,
			updateTarget,
			updateType,
		});
		let target = actualSectionId + '-' + actualSubsectionId;
		return dispatch({
			type: 'SET_SCROLL_MODE',
			scrollTarget: target,
			scrollType: updateType,
		});
	};

	isInt(value) {
		return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
	}

	reloadVariables = () => {
		const {
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates/${id}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.data.variables) {
					this.setState({ templateVariables: resp.data.variables });
				}
			});
	};

	saveAndReload = () =>
	{
		const {
			route: {
				template: { sections, headers },
			},
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { nodes } = this.state;
		fetch(`${process.env.API_URL}/district-templates/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				headers: JSON.stringify(headers),
				content: JSON.stringify(sections),
			}),
		})
		.then(res => res.json())
		.then(resp => {
			Swal.close();
			this.loadData();
		});
	};

	saveWithoutReload = () =>
	{
		const {
			route: {
				template: { sections, headers },
			},
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { nodes } = this.state;
		fetch(`${process.env.API_URL}/district-templates/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				headers: JSON.stringify(headers),
				content: JSON.stringify(sections),
			}),
		})
		.then(res => res.json())
		.then(resp => {
			Swal.close();
		});
	};

	saveTemplate = (enableValidation) => {
		const { viewMode, history } = this.props;
		if(viewMode) {
			return history.push('/safety-plansV1');
		}
		const {uploadModal} = this.state;
		if (uploadModal) {
			return this.closeUploadModal();
		}
		const {
			route: {
				template: {sections, is_template, name, headers},
			},
			match: {
				params: {id},
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		let errorObj = validateTemplateSections(sections, is_template, null, name);
		if (errorObj.issue && enableValidation) {
			Swal.fire({
				icon: 'question',
				html: errorObj.messageHTML + '<br> Are you sure you want to close and lose your changes?',
				showCancelButton: true,
				customClass: {
				confirmButton: 'bp3-button ems-primary-button',
				cancelButton: 'bp3-button',
				},
				confirmButtonText: 'Continue',
				reverseButtons: true,
				returnFocus: false,
				onAfterClose: () => {
				const element = document.getElementById(errorObj.elementId);
				if (!!element){
					element.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
					});
				}
				}
			}).then(result => {
				if (result.value) {
				const {history} = this.props;
				return history.push('/safety-plansV1');
				}
			});
		} else {
			fetch(`${process.env.API_URL}/district-templates/${id}`, {
				method: 'PUT',
				headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					headers: JSON.stringify(headers),
					content: JSON.stringify(sections),
					name: name,
					oldName: this.state.templateOldName,
					oldContent: this.state.templateOldContent,
					log: 1,
				}),
			})
			.then(res => res.json())
			.then(resp => {
				if (enableValidation) {
					this.setState({templateSavedBeforeUnmount: true});
					const {history} = this.props;
					return history.push('/safety-plansV1');
				}
			});
		}
	};

	handleNodeClick = e => {
		window.location.hash = e.id;
	};

	markSectionsComplete = (sectionIndex, subsectionIndex, payload) => {
		const page = this.state.page;
		const { dispatch } = this.props;
		if (subsectionIndex === null) {
			return dispatch({
				type: 'MARK_SECTION_COMPLETE',
				payload,
				sectionIndex: page,
			});
		}
		return dispatch({
			type: 'MARK_SUBSECTION_COMPLETE',
			payload,
			sectionIndex: page,
			subsectionIndex,
		});
	};

	updateNodes = (sectionIndex, subsectionIndex, content) => {
		const page = this.state.page;
		const { dispatch } = this.props;
		if (subsectionIndex === null) {
			return dispatch({
				type: 'UPDATE_SECTION_CONTENT',
				payload: content,
				sectionIndex: page,
			});
		}
		return dispatch({
			type: 'UPDATE_SUBSECTION',
			payload: content,
			sectionIndex: page,
			subsectionIndex,
		});
	};

	updateSectionTitle = (e, sectionIndex) => {
		const page = this.state.page;
		if (e.target.value.length === 0) {
			e.target.parentElement.style.background = '#faf2f3';
			e.target.style.background = '#faf2f3';
		} else {
			e.target.parentElement.style.background = '';
			e.target.style.background = '';
		}
		const { dispatch } = this.props;
		return dispatch({
			type: 'UPDATE_SECTION_TITLE',
			payload: e.target.value,
			sectionIndex: page,
		});
	};

	updateSubsectionTitle = (e, sectionIndex, subsectionIndex) => {
		const page = this.state.page;
		if (e.target.value.length === 0) {
			e.target.parentElement.style.background = '#faf2f3';
			e.target.style.background = '#faf2f3';
		} else {
			e.target.parentElement.style.background = '';
			e.target.style.background = '';
		}
		const { dispatch } = this.props;
		return dispatch({
			type: 'UPDATE_SUBSECTION_TITLE',
			payload: e.target.value,
			sectionIndex: page,
			subsectionIndex,
		});
	};

	updateTemplateName = e => {
    if (e.target.value.length === 0) {
      e.target.style.background = '#faf2f3';
    } else {
      e.target.style.background = '';
    }
		const { dispatch } = this.props;
		return dispatch({
			type: 'UPDATE_TEMPLATE_NAME',
			payload: e.target.value,
		});
	};

	makeIndicatorRed = () => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'INDICATOR_RED',
		});
	};

	addSubsection = (sectionIndex, subsectionIndex, subsectionId, source = '') => {
		const page = (this.state.activeSection.subSectionId || !subsectionId) ? this.state.page : this.state.page -1;
		const { dispatch } = this.props;
		return dispatch({
			type: 'ADD_SUBSECTION',
			sectionIndex: page,
			subsectionIndex,
			subsectionId,
			source,
			removeReference: this.state.removeSubsectionReference,
		});
	};

	addMainSection = (sectionIndex, source) => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'ADD_SECTION',
			sectionIndex,
			source
		});
	};

	isStringUrl = str => {
		const pattern = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i',
		); // fragment locator
		return !!pattern.test(str);
	};

	getVariableType = item => {
		const { templateVariables, commonVariables } = this.state;

		//search in template variables
		const getTemplateVariable = templateVariables.find(templateVariable => templateVariable.name === item.variableName);
		if(getTemplateVariable) {
			if( getTemplateVariable.content && JSON.parse(getTemplateVariable.content).typeAnnotation == 'Image') {
				return 'image';
			}
			return 'text';
		}

		//search in common variables
		const getCommonVariable = commonVariables.find(commonVariable => commonVariable.name === item.variableName);

		if(getCommonVariable) {
			if( getCommonVariable.content && JSON.parse(getCommonVariable.content).typeAnnotation == 'Image') {
				return 'image';
			}
			return 'text';
		}
	}

	fetchImages = async (fileIds) => {
		const dataArray = []
		const {
			match: {
				params: { id: templateId },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		for (const fileId of fileIds) {

			await fetch(`${process.env.API_URL}/district-templates/${templateId}/files/${fileId}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				}
			}).then(res => res.json())
			.then(response => {
				if(response.statusCode === 200) {
					dataArray.push(response.data);
				}
			}).catch(err => {
				//
			})
		}
		return dataArray;
	}

	parseContent = (content, toExportPDF = false ) => {
		const { variableValues } = this.state;
		let value = "";
		if (variableValues.length > 0 && content) {
			variableValues.forEach(variableValue => {
				if(variableValue.type == 'image') { //variable is image
					if(toExportPDF === true) {
						value = `[[FILE_${variableValue.variableValue}]]`;
					} else if(variableValue.imageInfo?.url ) {
						value = `<div><img src="${variableValue.imageInfo.url}" style="width: auto; height: 300px" /></div>`;
					}
				} else {// variable is free text
					value = `<span class="variable-value-highlight" contenteditable="false">${variableValue.variableValue}</span>`;
				}
				content = content.replaceAll(
					`<span class="variable-highlight">${variableValue.variableName}</span>`,
					value
				);
			});
		}
		return content;
	};

	getSubs = item => {
		let retSubs = '';
		item.subsections.forEach(one => {
			const temp = `<li class="subflex-li"><div class="toc-subtitle">${
				one.title
			}</div><div class="digit">555</div></li>`;
			retSubs += temp;
		});
		return retSubs;
	};

	getTOC = () => {
		const {
			route: { template },
		} = this.props;
		let retArr = '';
		template.sections.forEach(item => {
			let isCoverPage = !!item.title.toLowerCase().includes('cover page');
			const temp = `<li class="flex-li"><div class="toc-title">${
				isCoverPage ? '' : item.title
			}</div><div class="digit">555</div>${this.getSubs(item)}</li>`;
			retArr += temp;
		});
		return retArr;
	};

	exportPdf = () => {
		const {
			route: { template },
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		this.setState({ exportLoading: true });

		const first = template.sections[0];

		let coverPage = `<div class="page-after title-div">
			<h1>${template.name}</h1>
		</div>`;
		if (first) {
			if (first.title.toLowerCase().includes('cover page')) {

				coverPage = `<div class="page-after title-div">${
					this.parseContent(first.content, true)
				}</div>`;
			}
		}
		const headerString = `
		<html>
			<head>
			<style>
				${exportHeader}
				${exportHeaderTables}
				${exportCustomStylesForSections}
			</style>
			</head>
			<body>
      		<div id="footer">
    			<p class="page">Page </p>
			</div>
			${coverPage}`;

		const fileIds = [];
		template.sections.forEach(templ => {
			templ.subsections.forEach(single => {
				if (single.fileSection) {
					if (single.valueFile.id) {
						fileIds.push(single.valueFile.id);
					}
				}
			});
		});

		let htmlString = '';
		let markedHtml = '';
		const tableOfContents = `<div class="page-after"><ul class="unstyled-list">${this.getTOC()}</ul></div>`;

		const tocStructure = [];
		template.sections.forEach(item => {
			let isCoverPage = !!item.title.toLowerCase().includes('cover page');
			const temp = {
				id: item.id,
				title: item.title,
				page: null,
				subsections: [],
				isCoverPage: isCoverPage
			};

			markedHtml += `<div class="page-before"><h4>[!]${item.id}${item.title}[/!]</h4></div>`;
			markedHtml += this.parseContent(item.content, true);

			if(!isCoverPage) {
				htmlString += `<div class="page-before"><h4>${item.title}</h4></div>`;
				htmlString += this.parseContent(item.content, true);
			}

			item.subsections.forEach(one => {
				if (one.fileSection) {
					htmlString += `[[FILE_${one.valueFile.id}]]`;
					markedHtml += `[[FILE_${one.valueFile.id}]]`;
				} else {
					temp.subsections.push({
						id: one.id,
						title: one.title,
						page: null,
					});
					htmlString += `<div class="page-before"><h6>${one.title}</h6></div>`;
					htmlString += this.parseContent(one.content, true);
					markedHtml += `<div class="page-before"><h6>[!]${one.id}${one.title}[/!]</h6></div>`;
					markedHtml += this.parseContent(one.content, true);
				}
			});
			tocStructure.push(temp);
			// }
		});
		if (template.bonusFiles.length > 0) {
			template.bonusFiles.forEach(bFile => {
				if (bFile) {
					htmlString += `[[FILE_${bFile.id}]]`;
					markedHtml += `[[FILE_${bFile.id}]]`;
					fileIds.push(bFile.id);
				}
			});
		}

		htmlString += `</body></html>`;
		markedHtml += `</body></html>`;

		// add image variable ids to file ids array
		const { variableImagesIDs } = this.state;
		variableImagesIDs.forEach(variableImagesID => {
			fileIds.push(variableImagesID);
		})

		fetch(
			`${process.env.API_URL}/district-templates/${template.id}/export`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					headerString,
					html: htmlString,
					markedHtml,
					tocStructure,
					tableOfContents,
					singleSection: false,
					fileIds,
				}),
			},
		)
			.then(res => res.blob())
			.then(resp => {
				const filename = `${template.name} ${format(
					new Date(),
					'hh-mm-ss a',
				)}`;
				const data = new Blob([resp], {
					type: 'application/pdf',
				});
				const csvURL = window.URL.createObjectURL(data);
				const tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', `${filename}.pdf`);
				tempLink.click();
				this.setState({ exportLoading: false });
			});
	};

	openDeleteSectionModal = (sectionIndex, sectionDetails) =>{
		this.setState({
			modalToShow: 'DeleteSectionModal',
			deletedSectionIndex: sectionIndex,
			deletedSubsectionIndex: null,
			deletedSection: {
				actualSectionId: sectionDetails.id,
				...sectionDetails,
			},
		})
	}

	openDeleteSubSectionModal = (sectionIndex, subsectionIndex, sectionDetails, subSectionDetails) => {
		this.setState({
			modalToShow: 'DeleteSubSectionModal',
			deletedSectionIndex: sectionIndex,
			deletedSubsectionIndex: subsectionIndex,
			deletedSection: {
				actualSectionId: sectionDetails.id,
				actualSubsectionId: subSectionDetails.id,
				...subSectionDetails,
			},
		})
	}

	closeModal = () => {
		this.setState({
			modalToShow: false
		})

		// this.saveAndReload();
	}

	openUploadModal = (
		reupload,
		baseFile,
		valueFile,
		sectionIndex,
		subsectionIndex,
	) => {
		const page = this.state.page;
		return this.setState({
			uploadModal: true,
			uploadSection: page,
			uploadSubsection: subsectionIndex,
			uploadReupload: reupload,
			uploadBaseFile: baseFile,
			uploadValueFile: valueFile,
			showBackToTopButton: false,
		});
	};

	uploadFile = (files, sectionIndex, subsectionIndex) => {
		const page = this.state.page;
		Swal.fire('Uploading');
		Swal.showLoading();
		const {
			match: {
				params: { id: templateId },
			},
			dispatch,
		} = this.props;

		const token = JSON.parse(localStorage.getItem('user')).jwt;

		const fd = new FormData();
		fd.append('file', files[0]);

		fetch(
			`${process.env.API_URL}/district-templates/${templateId}/upload`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: fd,
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					const valueFile = {
						size: resp.data.size,
						id: resp.data.id,
						name: resp.data.fileName,
					};
					this.setState({
						uploadValueFile: valueFile,
					});
					Swal.close();
					return dispatch({
						type: 'ADD_VALUE_FILE',
						valueFile,
						sectionIndex: page,
						subsectionIndex,
					});
				} else if (resp.statusCode == 400) {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: resp.data,
					});
				}
				return null;
			});
	};

	shouldComponentUpdate(nextProps, nextState) {
		const {
			route: {  permissions },

		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { dist } = token ? decodeToken(token) : '';
		const  hasPermission = permissions.buildingPermissions?.[dist]?.['safety_plans_edit']
		if (hasPermission !== undefined && this.state.hasPermissions != hasPermission) {
			this.setState({
				hasPermissions: hasPermission,

			});

		}

		return true;
	}

	closeUploadModal = () =>
		this.setState({
			uploadModal: false,
			uploadSection: null,
			uploadSubsection: null,
			uploadBaseFile: null,
			uploadValueFile: null,
			showBackToTopButton: true,
		});

	markFileSubsectionComplete = (sectionIndex, subsectionIndex, payload) => {
		const page = this.state.page;
		const { dispatch } = this.props;
		Swal.fire({
			title: `Mark subsection as ${payload ? 'complete' : 'incomplete'}?`,
			icon: 'question',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button ems-primary-button',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Confirm',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				dispatch({
					type: 'MARK_SUBSECTION_COMPLETE',
					payload,
					sectionIndex: page,
					subsectionIndex,
				});
			}
			return Swal.close();
		});
	};

	exportSingleSection = (sectionIndex, subsectionIndex) => {
		sectionIndex = this.state.page;
		Swal.fire('This may take a minute');
		Swal.showLoading();
		const {
			route: { template },
		} = this.props;
		let item;
		if (subsectionIndex === null) {
			item = template.sections[sectionIndex];
		} else {
			item = template.sections[sectionIndex].subsections[subsectionIndex];
		}

		const headerString = `
		<html>
			<head>
			<style>
				${exportHeader}
				${exportHeaderTables}
				${exportCustomStylesForSections}
			</style>
			</head>
			<body>`;

		let htmlString = '';
		htmlString += `<h4>${item.title}</h4>`;
		htmlString += this.parseContent(item.content, true);
		htmlString += `</body></html>`;
		const token = JSON.parse(localStorage.getItem('user')).jwt;

		// get image variables ids in this item
		const fileIds = []
		const { variableValues } = this.state;
		variableValues.forEach(variableValue => {
			if(variableValue.type == 'image' && item.content.includes(variableValue.variableName)) {
				fileIds.push(parseInt(variableValue.variableValue))
			}
		});

		fetch(
			`${process.env.API_URL}/district-templates/${template.id}/export`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					headerString,
					html: htmlString,
					singleSection: true,
					fileIds
				}),
			},
		)
			.then(res => res.blob())
			.then(resp => {
				const filename = `${template.name} ${format(
					new Date(),
					'hh-mm-ss a',
				)}`;
				const data = new Blob([resp], { type: 'application/pdf' });
				const csvURL = window.URL.createObjectURL(data);
				const tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', `${filename}.pdf`);
				tempLink.click();
				Swal.close();
			});
	};

	toggleUpdateMode = (
		val,
		updateTarget,
		updateType,
		actualSectionId,
		actualSubsectionId,
	) => {
		const {
			dispatch,
			route: { template },
		} = this.props;
		this.setState({
			updateMode: val,
			updateTarget,
			updateType,
		});
		let target =
			updateType === 'section' ? actualSectionId : actualSectionId + '-' + actualSubsectionId;
		return dispatch({
			type: 'SET_SCROLL_MODE',
			scrollTarget: target,
			scrollType: updateType,
		});
	};

	toggleUpdateModal = val => {
		if (!val) {
			return window.location.reload();
		}
		return this.setState({ updateModal: val });
	};
	toggleDistrictUpdateModal = val => {
		if (!val) {
			return window.location.reload();
		}
		return this.setState({ districtUpdateModal: val });
	};


	closeUpdateModal = () => this.setState({ updateModal: false });

	closeDistrictUpdateModal = () => this.setState({ districtUpdateModal: false });

	openUpdateModal = () => this.setState({ updateModal: true });

	openCompleteModal = () => this.setState({ completeModal: true });

	closeCompleteModal = () => this.setState({ completeModal: false });

	scrollToSection = (section, subSection, type, page) => {
		this.setPage(page);
		let sectionId = `${type}-${section.id}`;
		if(subSection !== null) {
			sectionId += `-${subSection.id}`;
		}
		this.setState({
			isSectionScrollActive: false,
			activeSection: {
				sectionId: section.id,
				subSectionId: subSection?.id ? subSection.id : null
			},
		})
		let element = document.getElementById(sectionId);
		if (element) {
			this.scrollToElement(element);
		} else {
			// The element is not loaded yet, so, load the page that includes the element
			this.showPage(page).then((pageLoaded) => {
				if (pageLoaded) {
					let newElement = document.getElementById(sectionId);
					if (newElement) {
						this.scrollToElement(newElement);
					}
				}
			});
		}
	};

	scrollToElement = (element) => {
		element.scrollIntoView()
	}

	getAutosave = template => {
		if (template.lastUpdatedFormatted) {
			return template.lastUpdatedFormatted
		}

		return 'N/A';
	};

	onVisibilityChange = (sectionId, subSectionId = null, subIndex = null) => {
		if( this.state.isSectionScrollActive === true && this.state.startScrolling === true ) { // iam scrolling
			this.setState({
				activeSection: {
					sectionId,
					subSectionId
				}
			})
			scrollToSidebarSection(subIndex, this.sidebarRef, this.sectionRef)
		}
	};

	currentValues = {};

	getCurrentValuesFromState = () => {
		this.currentValues.activeSection = this.state.activeSection;
		this.currentValues.page = this.state.page;
	}

	setCurrentValuesToState = () => {
		this.setState({ activeSection: this.currentValues.activeSection });
		this.setState({ page: this.currentValues.page });
	}

	nextPage = () => {
		this.getCurrentValuesFromState();
		new Promise((resolve) => {
			const {
				route: { template, filtered },
				dispatch,
			} = this.props;

			var lastSection = null;
			if (filtered.sections.length > 0) {
				lastSection = filtered.sections[filtered.sections.length -1];
			}

			var nextSectionIndexToLoad = 0;
			if (lastSection) {
				template.sections.map((section, index) => {
					if (section.id === lastSection.id) {
						if (template.sections[index + 1] != undefined) {
							nextSectionIndexToLoad = index + 1;
						}
					}
				})
			}

			if (nextSectionIndexToLoad > 0) {
				const temp = template.sections[nextSectionIndexToLoad];
				filtered.sections.push(temp);
			}

			resolve( dispatch({
				type: 'NEXT_SECTION',
				payload: filtered,
			}));
		}).then((dispatchResolve) => {
			this.setCurrentValuesToState();
			return dispatchResolve;
		});
	};

	isLastSection = (sectionId) => {
		const {
			route: { template }
		} = this.props;

		const lastSection = template.sections[template.sections.length -1];
		return lastSection.id === sectionId;
	}

	previousPage = () => {
		this.getCurrentValuesFromState();
		new Promise((resolve) => {
			const {
				route: { template, filtered },
				dispatch,
			} = this.props;

			var firstSection = null;
			if (filtered.sections.length > 0) {
				firstSection = filtered.sections[0];
			}

			var previousSectionIndexToLoad = -1;
			if (firstSection) {
				template.sections.map((section, index) => {
					if (section.id === firstSection.id) {
						if (template.sections[index - 1] != undefined) {
							previousSectionIndexToLoad = index - 1;
						}
					}
				})
			}

			if (previousSectionIndexToLoad >= 0) {
				const temp = template.sections[previousSectionIndexToLoad];
				filtered.sections = [
					temp,
					...filtered.sections,
				];
			}

			return resolve( dispatch({
				type: 'NEXT_SECTION',
				payload: filtered,
			}));

		}).then((dispatchResolve) => {
			this.setCurrentValuesToState();
			return dispatchResolve;
		});
	}

	showPage = (page) => {
		return new Promise((resolvePage) => {
			new Promise((resolve) => {
				const {
					route: { template, filtered },
					dispatch,
				} = this.props;

				// push unloaded sections to filtered.sections array
				const lastCurrentIndex = this.state.page;
				const newSections = template.sections.slice(lastCurrentIndex+1, page+1);
				filtered.sections.push(...newSections);

				this.setState({removeSubsectionReference: false});
				this.setPage(page);

				resolve( dispatch({
					type: 'NEXT_SECTION',
					payload: filtered,
				}));
			}).then(() => {
				resolvePage(true);
			});
		})
	}

	setPage = (page) => {
		this.setState({ page: page });
	}

	handleMainScroll = (e) => {
		if (this.state.yPosition > e.target.scrollTop ) {
			if (e.target.scrollTop < 300) {
				this.previousPage();
			}
		}
		this.setState({
			yPosition: e.target.scrollTop
		});

		if( this.state.startScrolling === false ){
			this.setState({
				startScrolling: true
			});
		}
	};

	handleBackToTop = () => {
		// Get the first section
		const {
			route: { template },
		} = this.props;
		const firstSection = template.sections[0];
		this.setState({
			isSectionScrollActive: false,
			activeSection: {
				sectionId: firstSection?.id,
				subSectionId: null
			},
		})
		// Wait 300ms to load the content, then scroll to the top of the page
		// setTimeout(() => {
			this.mainWrapperRef.current.scrollTo({
				top: 0
			})
			this.sidebarRef.current.scrollTo({
				top: 0
			})
			window.scrollTo(0, 0);
		// }, 300);
	}

	onWheelHandler = () => {
		if(this.state.isSectionScrollActive === false) {
			this.setState({
				isSectionScrollActive: true
			})
		}
	}

	componentDidMount = async () => {
		const {  viewMode } = this.props;
		if(viewMode === true) {
			this.setState({
				viewMode: true
			});
		}

		return await this.loadData(true);
	}

	componentWillUnmount() {
		const {
			route: { templateLoading },
		} = this.props;
		const templateSaved = this.state.templateSavedBeforeUnmount;
		const loading = this.state.loading;
		if (!templateSaved && !loading && !templateLoading) {
			this.saveTemplate(false);
		}
	}

	onDeleteAndPublishSection = async () => {
		const { deletedSection } = this.state;
		const {
			match: {
				params: { id: templateId },
			},
		} = this.props;
    const updateBody = {
      type: 'delete',
      updatedContent: JSON.stringify(deletedSection),
      actualSectionId : deletedSection.item.id,
      actualSubsectionId: deletedSection.actualSubsectionId,
    }
		await PlanService.publishUpdates(templateId, updateBody)
                        .then(response => {
                            if(response.statusCode === 201) {
                                fireSwal('success', 'Successfully deleted!');
                            } else {
                                fireSwal('error', response.error.description)
                            }
                        })
	}

	updatePosition = (sectionId, subSectionId) => {
		this.setState({
			activeSection: {
				sectionId,
				subSectionId: !subSectionId ? null : subSectionId,
			},
		});
	};

	render() {
		const {
			commonVariables,
			templateVariables,
			loading,
			variableValues,
			panel,
			exportLoading,
			uploadModal,
			uploadBaseFile,
			uploadValueFile,
			uploadSection,
			uploadSubsection,
			uploadReupload,
			bonusFilesModal,
			updateModal,
			districtUpdateModal,
			completeModal,
			activeSection,
			updateMode,
			updateTarget,
			updateType,
			viewMode,
			hasResetUpdate
		} = this.state;

		const { nodes, modalToShow, deletedSectionIndex, deletedSubsectionIndex, deletedSection } = this.state;
		const {
			route: { template, filtered, indicator, templateLoading },
			match: {
				params: { id: templateId },
			},
			history: {
				location: { pathname },
			},
			dispatch,
		} = this.props;


		if (templateLoading || loading) return <LoadingSpinner />;

		if (panel === 'contextualeditor') {
			return (
				<>
					{modalToShow  === 'DeleteSectionModal' && (
						<DeleteSectionModal
							type='section'
							title='Delete section?'
							description='This will remove the section title and all of the subsections within this section'
							sectionIndex={deletedSectionIndex}
							subsectionIndex={deletedSubsectionIndex}
							deletedSection={deletedSection}
							closeDeleteModal={this.closeModal}
							onDeleteAndPublishSection={this.onDeleteAndPublishSection}
						/>
					)}
					{modalToShow === 'DeleteSubSectionModal' && (
						<DeleteSectionModal
							type='sub-section'
							title='Delete subsection?'
							description='This will remove the sub section title and all of the content within this sub section'
							sectionIndex={deletedSectionIndex}
							subsectionIndex={deletedSubsectionIndex}
							deletedSection={deletedSection}
							closeDeleteModal={this.closeModal}
							onDeleteAndPublishSection={this.onDeleteAndPublishSection}
						/>
					)}
					<Dialog isOpen={exportLoading}>
						<div className="d-flex justify-content-around mt-3">
							<h4>Exporting</h4>
						</div>
						<div className="d-flex justify-content-around mt-2">
							<p className="mb-0">
								Exports may take up to several minutes based on
								plan size
							</p>
						</div>

						<LoadingSpinner />
					</Dialog>

					<BonusFilesModal
						isOpen={bonusFilesModal}
						template={template}
						onClose={() => this.loadData()}
						dispatch={dispatch}
						loadData={this.loadData}
					/>

					{completeModal && (
						<CompletePlanModal
							isOpen={completeModal}
							template={template}
							closeCompleteModal={this.closeCompleteModal}
						/>
					)}

					<Wrapper>
						<div
							style={{
								width: '100%',
								height: '100vh',
								overflow: 'auto',
							}}
							id="main"
							onScroll={this.handleMainScroll}
							onWheelCapture={this.onWheelHandler}
							ref={this.mainWrapperRef}
						>
							<div id="top-element"></div>
							<BackToTopButton
								onClick={ this.handleBackToTop }
								containerId="main"
								topElementId="top-element"
								elementOnRightId="sidebar-wrapper"
								show={this.state.showBackToTopButton}
							/>
							<InfiniteScroll
								dataLength={filtered.sections.length}
								next={this.nextPage}
								hasMore={
									filtered.sections.length <
									template.sections.length
								}
								scrollableTarget="main"
							>
								<UploadModals
									isOpen={uploadModal}
									closeUploadModal={this.closeUploadModal}
									baseFile={uploadBaseFile}
									valueFile={uploadValueFile}
									sectionIndex={uploadSection}
									subsectionIndex={uploadSubsection}
									reupload={uploadReupload}
									dispatch={dispatch}
									templateId={templateId}
									uploadFile={this.uploadFile}
								/>
								<UpdateModal
									template={template}
									isOpen={updateModal}
									reloadTemplate={this.loadData}
									closeUpdateModal={this.closeUpdateModal}
								/>

								<UpdateModal
									template={template}
									isOpen={districtUpdateModal}
									reloadTemplate={this.loadData}
									closeUpdateModal={this.closeDistrictUpdateModal}
									isDistrictUpdate={true}
								/>
								<TextAreaContainer className="pt-3">
									<HeaderWrap>
										<div>
											<div className="d-flex align-itesm-center">
												<BackButtonWrap
													onClick={() => this.saveTemplate(true)}
												>
													<Icon icon="arrow-left" />{' '}
													<BackText>{viewMode ? "Exit" : "Save and Exit"}</BackText>
												</BackButtonWrap>
												{updateMode && (
											<Tag intent="danger" round>
												Autosave disabled during update
												creation
											</Tag>
												)}
												{!updateMode && (
													<Tag minimal round>
														{indicator ? (
															<AutoSaveWrap>
																<SmallIcon icon="tick" />
																Auto Saved
																<SmallIcon icon="dot" />
																{this.getAutosave(
																	template,
																)}
															</AutoSaveWrap>
														) : (
															'Not Saved'
														)}

													</Tag>
												)}
											</div>
											<TemplateName className="mb-3 mt-2">
												{pathname.includes('edit')
													? 'Edit Template'
													: 'Create New Template'}
											</TemplateName>
										</div>
										{!uploadModal && !updateModal && !districtUpdateModal && (
											<div className="d-flex align-items-start">
												<Tabs
													id="TabsExample"
													defaultSelectedTabId={panel}
													onChange={val =>
														this.setState({
															panel: val,
														})
													}
												>
													<Tab
														id="contextualeditor"
														title="Contextual Editor"
														panel={null}
													/>
													{!viewMode && (
													<Tab
														id="variableonly"
														title="Variable Fields Only"
														panel={null}
													/>
													)}
												</Tabs>
												{!viewMode && (
												<Button
													intent="secondary"
													text="Mark as Complete"
													className="ml-2"
													onClick={
														this.openCompleteModal
													}
												/>
												)}
											</div>
										)}
									</HeaderWrap>
									{/* template updates */}
									{!viewMode && template.updates.length > 0 && (
										<UpdateWrap>
											<p>
												{`There ${template.updates.length === 1
													? `is 1 update`
													: `are ${template.updates.length} updates`}
												 to sections in this plan`}
											</p>
											<Button
												rightIcon="arrow-top-right"
												text="View Updates"
												intent="danger"
												minimal
												onClick={this.openUpdateModal}
											/>
										</UpdateWrap>
									)}
									{/* local updates hide them if hasResetUpdate & viewMode === true */}
									{!viewMode && !hasResetUpdate && template.localUpdates.length > 0 && (
										<UpdateLocalWrap>
											<p>
												There{' '}
												{template.localUpdates.length === 1
													? 'is 1 district update'
													: `are ${
															template.localUpdates
																.length
													  } district updates`}{' '}
												to sections in this plan
											</p>
											<Button
												rightIcon="arrow-top-right"
												text="View Updates"
												intent="danger"
												minimal
												onClick={() =>
													this.toggleDistrictUpdateModal(true)
												}
											/>
										</UpdateLocalWrap>
									)}
									<div className="d-flex align-items-center justify-content-between">
										<TemplateNameInput
											value={template.name}
											onChange={e =>
												this.updateTemplateName(e)
											}
										/>
										<div
											className="d-flex"
											style={{ minWidth: '170px' }}
										>
											{!viewMode && (
											<Button
												onClick={() =>
													this.setState({
														bonusFilesModal: true,
													})
												}
												text="Attach Files"
											/>
											)}
											<Button
												className="ml-2"
												onClick={this.exportPdf}
												text="Export"
											/>
										</div>
									</div>
									{filtered.sections.map((item, index) => (
										<VisibilitySensor
											onChange={() => this.onVisibilityChange(item.id)}
											key={`section-${item.id}-${index}`}
											scrollCheck
											partialVisibility="top"
											offset={{top:0}}
										>
											<React.Fragment>

													<React.Fragment>
													<div
														className="d-flex align-items-center mb-2"
														id={`section-${item.id}`}
													>
														{template.sections.length > 1 && !viewMode && (
															<DeleteButton
																text="DELETE"
																intent="danger"
																minimal
																onClick={() => this.openDeleteSectionModal(index, item)}
															/>
														)}

														<BlankInput
															value={item.title}
															readOnly={viewMode}
															id={`section-${item.id}`}
															onChange={e =>
																this.updateSectionTitle(
																	e,
																	index,
																)
															}
														/>
														{!viewMode && (
														<>
															<VariableNumber>
																{getFields(
																	item.content,
																	template.variableValues,
																)}
															</VariableNumber>
														<StyledTag
															round
															green={
																item.complete
																	? item.complete.toString()
																	: 'false'
															}
														>
															{item.complete
																? 'Completed'
																: 'Not Completed'}
														</StyledTag>
														</>
														)}
													</div>
													<SingleEditor
														viewMode={viewMode}
														makeIndicatorRed={this.makeIndicatorRed}
														indicator={indicator}
														sectionIndex={index}
														subsectionIndex={null}
														updateNodes={this.updateNodes}
														content={item.content}
														openVariables={this.openVariables}
														commonVariables={commonVariables}
														templateVariables={templateVariables}
														templateId={templateId}
														reloadVariables={this.reloadVariables}
														loadData={this.loadData}
														variableValues={variableValues}
														parsedContent={this.parseContent(item.content)}
														isCompleted={item.complete}

														markSectionsComplete={this.markSectionsComplete}
														exportSingleSection={this.exportSingleSection}
                            	                        type="section"
                                                        subsection={item}
														updateMode={updateMode}
														updateType={updateType}
														updateTarget={updateTarget}
														toggleUpdateMode={this.toggleUpdateMode}
														actualSectionId={item.id}
														template={template}
														cancelUpdateMode={this.cancelUpdateMode}
														hasPermission={this.state.hasPermissions}
														updatePosition={this.updatePosition}
													/>
													</React.Fragment>

												{item.subsections.map(
													(single, subindex) => (
														<VisibilitySensor
															onChange={() => this.onVisibilityChange(item.id, single.id, subindex)}
															key={`subsection-${item.id}-${single.id}-${subindex}`}
															scrollCheck
															partialVisibility="top"
															offset={{top:0}}
														>
															<React.Fragment>
																<div
																	className={"d-flex align-items-center mb-2" + (single.fileSection?' mt-3':'')}
																	id={`subsection-${item.id}-${single.id}`}
																>
																	{!viewMode && (
																	<DeleteButton
																		text="DELETE"
																		intent="danger"
																		minimal
																		onClick={() => this.openDeleteSubSectionModal(index, subindex, item, single)}
																	/>
																	)}
																	<BlankInput
																		value={
																			single.title
																		}
																		readOnly={viewMode}
																		id={`subsection-${item.id}-${single.id}`}
																		onChange={e =>
																			this.updateSubsectionTitle(
																				e,
																				index,
																				subindex,
																			)
																		}
																	/>
																	{!viewMode && (
																	<>
																		<VariableNumber>
																			{getFields(
																				single.content,
																				template.variableValues,
																			)}
																		</VariableNumber>
																	<StyledTag
																		round
																		green={
																			single.complete
																				? single.complete.toString()
																				: 'false'
																		}
																	>
																		{single.complete
																			? 'Completed'
																			: 'Not Completed'}
																	</StyledTag>
																	</>
																	)}
																	{single.fileSection && !viewMode && (
																		<Button
																			intent="secondary"
																			minimal
																			className="ml-2"
																			text="Complete Section"
																			onClick={() =>
																				this.markFileSubsectionComplete(
																					index,
																					subindex,
																					single.complete
																						? !single.complete
																						: 'true',
																				)
																			}
																		/>
																	)}
																</div>
																{!single.fileSection && (
																	<SingleEditor
																		viewMode={viewMode}
																		makeIndicatorRed={this.makeIndicatorRed}
																		indicator={indicator}
																		sectionIndex={index}
																		subsectionIndex={subindex}
																		updateNodes={this.updateNodes}
																		content={single.content}
																		openVariables={this.openVariables}
																		commonVariables={commonVariables}
																		templateVariables={templateVariables}
																		templateId={templateId}
																		reloadVariables={this.reloadVariables}
																		loadData={this.loadData}
																		variableValues={variableValues}
																		parsedContent={this.parseContent(single.content)}
																		isCompleted={single.complete}
																		markSectionsComplete={this.markSectionsComplete}
																		exportSingleSection={this.exportSingleSection}
                                                                        subsection={single}
                                                                        type='subsection'
																		toggleUpdateMode={this.toggleUpdateMode}
																		actualSubsectionId={
																			single.id
																		}
																		actualSectionId={
																			item.id
																		}
																		template={template}
																		updateMode={updateMode}
																		updateType={updateType}
																		updateTarget={updateTarget}
																		cancelUpdateMode={this.cancelUpdateMode}
																		hasPermission={this.state.hasPermissions}
																		updatePosition={this.updatePosition}
																	/>
																)}

																{single.fileSection && (
																	<FileSection
																		viewMode={viewMode}
																		sectionIndex={this.state.page}
																		subsectionIndex={subindex}
																		subsection={single}
																		templateId={templateId}
																		openUploadModal={this.openUploadModal}
																		saveAndReload={this.saveAndReload}
																		saveWithoutReload={this.saveWithoutReload}
																		dispatch={dispatch}
																		actualSubsectionId={
																			single.id
																		}
																		actualSectionId={
																			item.id
																		}
																		template={template}
																		updateMode={updateMode}
																		updateType={updateType}
																		updateTarget={updateTarget}
																		cancelUpdateMode={this.cancelUpdateMode}
																		toggleUpdateFileMode={this.toggleUpdateFileMode}
																		hasPermission={this.state.hasPermissions}
																	/>
																)}
															</React.Fragment>
														</VisibilitySensor>
													),
												)}
												{!viewMode && (
												<>
												<Button
													className="pb-2"
													icon="add"
													text="Add subsection"
													tertiray
													minimal
													onClick={() =>
														this.addSubsection(
															index,
															(item.subsections?.length - 1) || 0,
															(item.subsections?.length > 0) ? item.subsections[item.subsections.length - 1].id : null,
															this.state.hasPermissions ? 'plan' : 'site'
														)
													}
												/>
												{this.isLastSection(item.id) && (
													<div>
														<Button
															className="pb-2"
															icon="add"
															text="Add main section"
															minimal
															onClick={() =>
																this.addMainSection(
																	index,
																	this.state.hasPermissions ? 'plan' : 'site'
																)
															}
														/>
													</div>
												)}
												</>
												)}
											</React.Fragment>
										</VisibilitySensor>
									))}
								</TextAreaContainer>
							</InfiniteScroll>
						</div>

						{!uploadModal && !updateModal && !districtUpdateModal && (
							<SideBar
								nodes={nodes}
								updateNodes={this.updateNodes}
								loading={templateLoading}
								variableValues={variableValues}
								loadData={this.loadData}
								scrollToSection={this.scrollToSection}
								activeSection={activeSection}
								sectionsPerPage={sectionsPerPage}
								setPage={this.setPage}
								sectionRef={this.sectionRef}
								sidebarRef={this.sidebarRef}
								viewMode={viewMode}
							/>
						)}
					</Wrapper>
				</>
			);
		}
		return (
			<>
				<Wrapper>
					<div
						style={{
							width: '100%',
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<TextAreaContainer className="pt-3">
							<div className="d-flex align-items-center justify-content-between">
								<div>
									<div className="d-flex align-itesm-center">
										<BackButtonWrap
											onClick={() => this.saveTemplate(true)}
										>
											<Icon icon="arrow-left" />{' '}
											<BackText>{viewMode ? "Exit" : "Save and Exit"}</BackText>
										</BackButtonWrap>
										{updateMode && (
											<Tag intent="danger" round>
												Autosave disabled during update
												creation
											</Tag>
										)}
										{!updateMode && (
											<Tag minimal round>
												{indicator ? (
													<AutoSaveWrap>
														<SmallIcon icon="tick" />
														Auto Saved
														<SmallIcon icon="dot" />
														{this.getAutosave(template)}
													</AutoSaveWrap>
												) : (
													'Not Saved'
												)}
											</Tag>
										)}
									</div>
									<TemplateName className="mb-3 mt-2">
										{pathname.includes('edit')
											? 'Edit Template'
											: 'Create New Template'}
									</TemplateName>
								</div>

								{!uploadModal && !updateModal && !districtUpdateModal && (
									<Tabs
										id="TabsExample"
										defaultSelectedTabId={panel}
										onChange={val => {
											this.setState({ panel: val });
											if (val === 'contextualeditor') {
												this.loadData();
											}
										}}
									>
										<Tab
											id="contextualeditor"
											title="Contextual Editor"
											panel={null}
										/>
										{!viewMode && (
										<Tab
											id="variableonly"
											title="Variable Fields Only"
											panel={null}
										/>
										)}
									</Tabs>
								)}
							</div>
							<VariableOnly
								templateId={templateId}
								variableValues={variableValues}
								loadData={this.loadData}
							/>
						</TextAreaContainer>
					</div>
					{!uploadModal && (
						<SideBar
							nodes={nodes}
							updateNodes={this.updateNodes}
							loading={templateLoading}
							variableValues={variableValues}
							loadData={this.loadData}
						/>
					)}
				</Wrapper>
			</>
		);
	}
}

EditTemplate.propTypes = {
	match: PropTypes.object,
	route: PropTypes.object,
	history: PropTypes.object,
	dispatch: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(EditTemplate);
