import React from 'react';
import './styles.css';
import { Icon } from '@blueprintjs/core';
import { CardContainer, PageHeader } from 'componentsV2';
import { drillsSettingsCards, breadcrumbItems } from './utilities';
import { RightArrowLink } from '../../components/RightArrowLink/RightArrowLink';

const DrillsSettings = () => (
	<div>
		<PageHeader breadcrumbItems={breadcrumbItems} title="Drills Settings" />
		<div className="main-container">
			{drillsSettingsCards.map(item => (
				<CardContainer>
					<div className="card-container-style">
						<div className="icon-container">
							<Icon icon={item?.icon} />
						</div>
						<div className="main-title-container">
							<p className="text-container">{item?.title}</p>
							<p>{item?.subTitle}</p>

							<div className="link-container">
								<RightArrowLink item={item?.linkText} toLink={item?.linkUri} />
							</div>
						</div>
					</div>
				</CardContainer>
			))}
		</div>
	</div>
);
export default DrillsSettings;
