import { HttpService } from 'networking/HttpService';
import { GRADE_LEVELS_VALUES } from '../utilities/drills/mockData';

const GENERIC_ERROR = new Error('An error occurred, please try again');

export const ReportService = {
	fetchReportsForDistrict: () =>
		HttpService({
			method: 'GET',
			route: `/drills/reports/templates`,
		}).then(res => {
			if (Array.isArray(res?.data?.templates)) {
				return res?.data?.templates?.map(item => {
					if (item?.parameters) {
						const parameters = {};

						Object.keys(item?.parameters)
							.filter(key => item.parameters[key] !== null)
							.forEach(key => {
								if (key === 'gradeLevels') {
									parameters.gradeLevels = GRADE_LEVELS_VALUES;
								} else {
									parameters[key] = null;
								}
							});

						return {
							...item,
							parameters,
						};
					}

					return item;
				});
			}

			throw GENERIC_ERROR;
		}),

	buildReport: parameters =>
		HttpService({
			method: 'POST',
			route: '/drills/reports',
			body: {
				parameters,
			},
		}).then(res => {
			if (res?.statusCode === 200) {
				return res?.data;
			}

			throw GENERIC_ERROR;
		}),

	getReportStatus: reportId =>
		HttpService({
			method: 'GET',
			route: `/drills/reports/${reportId}`,
		}).then(res => {
			if (res?.statusCode === 200) {
				return res?.data?.status;
			}

			return 'err';
		}),
};
