import React, { useState } from 'react';
import { Modal, AlertModal, NormalModal, Button, Dropdown, Input, LoadingModal } from '../';

export const ModalPattern = () => {
    // Needed for Open / Close the modal
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
    const [normalModalIsOpen, setNormalModalIsOpen] = useState(false);
    const [loadingModalIsOpen, setOpenLoadingModal] = useState(false);

    // State of the options
    const [optionModalSize, setOptionModalSize] = useState(null);
    const [optionModalIcon, setOptionModalIcon] = useState(null);
    const [optionModalIconColor, setOptionModalIconColor] = useState(null);
    const [optionModalTitle, setOptionModalTitle] = useState(null);
    const [optionModalSubtitle, setOptionModalSubtitle] = useState(null);
    const [optionModalCloseButton, setOptionModalCloseButton] = useState(null);
    const [optionModalShowFooter, setOptionModalShowFooter] = useState(null);
    const [optionModalLoading, setOptionModalLoading] = useState(null);

    const [optionAlertModalFooterLeftContent, setOptionAlertModalFooterLeftContent] = useState(null);
    const [optionAlertModalShowCancelButton, setOptionAlertModalShowCancelButton] = useState(null);
    const [optionAlertModalShowConfirmButton, setOptionAlertModalConfirmButton] = useState(null);

    const [loadingModalText, setLoadingModalText] = useState('Downloading...');


    /** Handle Open / Close the modal*/
    const handleShowModal = (show) => {
        setModalIsOpen(show);
    }

    const handleShowAlertModal = (show) => {
        setAlertModalIsOpen(show);
    }

    const handleOpenNormalModal = () => {
        setNormalModalIsOpen(true);
    }

    const handleOpenLoadingModal = () => {
        setOpenLoadingModal(true);
    }

    const handleCloseNormalModal = () => {
        setNormalModalIsOpen(false);
    }

    const handleCloseLoadingModal = () => {
        setOpenLoadingModal(false);
    }

    const footerContentElement = (
        <>
            <span>Footer Content here...</span>
        </>
    );

    const alertFooterLeftContentElement = (
        <>
            Alert Footer Left Content here...
        </>
    );

    const alertConfirmButtonElement = (
        <Button
            text="Open Modal"
            icon="console"
            type="primaryDefault"
            size="md"
            onClick={() => {
                setAlertModalIsOpen(false);
                setModalIsOpen(true);

            }}
        />
    );

    const alertCancelButtonElement = (
        <Button
            text="Cancel"
            type="tertiary"
            size="md"
            onClick={() => {
                setAlertModalIsOpen(false);
            }}
        />
    );

    /** Handle Options */

    const handleChangeSize = item => {
        setOptionModalSize(item);
    }
    const handleChangeIcon = item => {
        setOptionModalIcon(item);
    }
    const handleChangeIconColor = item => {
        setOptionModalIconColor(item);
    }
    const handleChangeTitle = item => {
        setOptionModalTitle(item);
    }
    const handleChangeSubtitle = item => {
        setOptionModalSubtitle(item);
    }
    const handleChangeCloseButton = item => {
        setOptionModalCloseButton(item);
    }
    const handleChangeShowFooter = item => {
        setOptionModalShowFooter(item);
    }
    const handleChangeLoading = item => {
        setOptionModalLoading(item);
    }
    const handleChangeFooterLeftContent = item => {
        setOptionAlertModalFooterLeftContent(item);
    }
    const handleChangeShowCancelButton = item => {
        setOptionAlertModalShowCancelButton(item);
    }
    const handleChangeShowConfirmButton = item => {
        setOptionAlertModalConfirmButton(item);
    }

    const handleLoadingTextInput = (e) => {
        setLoadingModalText(e.target.value);
    }

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <div className="card">
                        <div className="card-body">
                            <h4 className='mb-0'>Modal Options</h4>
                            <span className='d-block text-secondary mb-3 font-italic'>Basic options for all modals</span>

                            <div className="row">
                                <div className="col-md-6">

                                    <Dropdown
                                        className="mt-3"
                                        label="Size"
                                        placeholder="Select size"
                                        value={optionModalSize}
                                        options={[
                                            {
                                                value: "small",
                                                label: "Small"
                                            },
                                            {
                                                value: "medium",
                                                label: "Medium"
                                            },
                                            {
                                                value: "large",
                                                label: "Large"
                                            }
                                        ]}
                                        onChange={handleChangeSize}
                                    />

                                    <div className="mt-3"></div>
                                    <Dropdown
                                        label="Icon"
                                        placeholder="Select an option"
                                        value={optionModalIcon}
                                        options={[
                                            {
                                                value: "",
                                                label: "Hide Icon"
                                            },
                                            {
                                                value: "circle",
                                                label: "Circle"
                                            },
                                            {
                                                value: "warning-sign",
                                                label: "warning-sign"
                                            },
                                            {
                                                value: "help",
                                                label: "help"
                                            },
                                            {
                                                value: "tick-circle",
                                                label: "tick-circle"
                                            },
                                            {
                                                value: "lightbulb",
                                                label: "lightbulb"
                                            }
                                        ]}
                                        onChange={handleChangeIcon}
                                    />

                                    <div className="mt-3"></div>
                                    <Dropdown
                                        label="Icon Color"
                                        placeholder="Icon Color"
                                        value={optionModalIconColor}
                                        options={[
                                            {
                                                value: "primary",
                                                label: "Primary"
                                            },
                                            {
                                                value: "error",
                                                label: "Error"
                                            },
                                            {
                                                value: "warning",
                                                label: "Warning"
                                            },
                                            {
                                                value: "success",
                                                label: "Success"
                                            }
                                        ]}
                                        onChange={handleChangeIconColor}
                                    />

                                    <div className="mt-3"></div>
                                    <Dropdown
                                        label="Show Close button"
                                        placeholder="Select an option"
                                        value={optionModalCloseButton}
                                        options={[
                                            {
                                                value: true,
                                                label: "Yes"
                                            },
                                            {
                                                value: false,
                                                label: "No"
                                            }
                                        ]}
                                        onChange={handleChangeCloseButton}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Dropdown
                                        label="Show Title"
                                        placeholder="Select an option"
                                        value={optionModalTitle}
                                        options={[
                                            {
                                                value: "Modal title",
                                                label: "Yes"
                                            },
                                            {
                                                value: "",
                                                label: "No"
                                            }
                                        ]}
                                        onChange={handleChangeTitle}
                                    />

                                    <div className="mt-3"></div>
                                    <Dropdown
                                        label="Show Subtitle"
                                        placeholder="Select an option"
                                        value={optionModalSubtitle}
                                        options={[
                                            {
                                                value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
                                                label: "Yes"
                                            },
                                            {
                                                value: "",
                                                label: "No"
                                            }
                                        ]}
                                        onChange={handleChangeSubtitle}
                                    />

                                    <div className="mt-3"></div>
                                    <Dropdown
                                        label="Show Footer"
                                        placeholder="Select an option"
                                        value={optionModalShowFooter}
                                        options={[
                                            {
                                                value: true,
                                                label: "Yes"
                                            },
                                            {
                                                value: false,
                                                label: "No"
                                            }
                                        ]}
                                        onChange={handleChangeShowFooter}
                                    />

                                    <div className="mt-3"></div>
                                    <Dropdown
                                        label="Show Loading"
                                        placeholder="Select an option"
                                        value={optionModalLoading}
                                        options={[
                                            {
                                                value: true,
                                                label: "Yes"
                                            },
                                            {
                                                value: false,
                                                label: "No"
                                            }
                                        ]}
                                        onChange={handleChangeLoading}
                                    />

                                    <div className="mt-4"></div>
                                    <Button
                                        text="Open Modal"
                                        icon="application"
                                        size="md"
                                        onClick={() => handleShowModal(true)}
                                    />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card mb-3">
                        <div className="card-body">
                            <h4 className='mb-0'>Alert Modal Options</h4>
                            <span className='d-block text-secondary mb-3 font-italic'>Basic options + additional options for alert modals</span>

                            <div className="mt-3"></div>
                            <Dropdown
                                label="Show Confirm button"
                                placeholder="Select an option"
                                value={optionAlertModalShowConfirmButton}
                                options={[
                                    {
                                        value: true,
                                        label: "Yes"
                                    },
                                    {
                                        value: false,
                                        label: "No"
                                    }
                                ]}
                                onChange={handleChangeShowConfirmButton}
                            />

                            <div className="mt-3"></div>
                            <Dropdown
                                label="Show Cancel button"
                                placeholder="Select an option"
                                value={optionAlertModalShowCancelButton}
                                options={[
                                    {
                                        value: true,
                                        label: "Yes"
                                    },
                                    {
                                        value: false,
                                        label: "No"
                                    }
                                ]}
                                onChange={handleChangeShowCancelButton}
                            />

                            <div className="mt-3"></div>
                            <Dropdown
                                label="Show Left Footer Content"
                                placeholder="Select an option"
                                value={optionAlertModalFooterLeftContent}
                                options={[
                                    {
                                        value: true,
                                        label: "Yes"
                                    },
                                    {
                                        value: false,
                                        label: "No"
                                    }
                                ]}
                                onChange={handleChangeFooterLeftContent}
                            />

                            <div className="mt-4"></div>
                            <Button
                                text="Open Alert Modal"
                                icon="console"
                                type="ghostPrimary"
                                size="md"
                                onClick={() => handleShowAlertModal(true)}
                            />

                            <div className="mt-3"></div>
                            <Button
                                text="Open Normal Modal"
                                icon="console"
                                type="ghostPrimary"
                                size="md"
                                onClick={handleOpenNormalModal}
                            />
                            <div className="mt-3"></div>
                            <Input 
                                label="Loading Modal text"
                                value={loadingModalText}
                                onChange={handleLoadingTextInput}
                            />
                            <div className="mt-3"></div>
                            <Button
                                text="Open Loading Modal"
                                icon="circle"
                                type="ghostPrimary"
                                size="md"
                                onClick={handleOpenLoadingModal}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onClose={handleShowModal}
                loading={optionModalLoading && optionModalLoading.value}
                size={optionModalSize && optionModalSize.value}
                title={optionModalTitle && optionModalTitle.value}
                subtitle={optionModalSubtitle && optionModalSubtitle.value}
                showCloseButton={optionModalCloseButton && optionModalCloseButton.value}
                icon={optionModalIcon && optionModalIcon.value}
                iconColor={optionModalIconColor && optionModalIconColor.value}
                footer={optionModalShowFooter && optionModalShowFooter.value && footerContentElement}
            >
                <span>Content here...</span>
            </Modal>

            <AlertModal
                isOpen={alertModalIsOpen}
                onClose={handleShowAlertModal}
                loading={optionModalLoading && optionModalLoading.value}
                size={optionModalSize && optionModalSize.value}
                title={optionModalTitle && optionModalTitle.value}
                subtitle={optionModalSubtitle && optionModalSubtitle.value}
                showCloseButton={optionModalCloseButton && optionModalCloseButton.value}
                icon={optionModalIcon && optionModalIcon.value}
                iconColor={optionModalIconColor && optionModalIconColor.value}
                footer={optionModalShowFooter && optionModalShowFooter.value && footerContentElement}

                alertConfirmButton={optionAlertModalShowConfirmButton && optionAlertModalShowConfirmButton.value && alertConfirmButtonElement}
                alertCancelButton={optionAlertModalShowCancelButton && optionAlertModalShowCancelButton.value && alertCancelButtonElement}
                alertLeftContent={optionAlertModalFooterLeftContent && optionAlertModalFooterLeftContent.value && alertFooterLeftContentElement}
            />

            <NormalModal
                isOpen={normalModalIsOpen}
                onCloseAction={handleCloseNormalModal}
                loading={optionModalLoading && optionModalLoading.value}
                size={optionModalSize && optionModalSize.value}
                header="Normal Modal Title"
                subtitle="Normal Modal Subtitle (Optional)"
                showCloseButton={optionModalCloseButton && optionModalCloseButton.value}
                icon={optionModalIcon && optionModalIcon.value}
                iconColor={optionModalIconColor && optionModalIconColor.value}

                showBackArrow={true}
                backArrowAction={() => { alert("Back arrow function triggered") }}

                showConfirmBtn
                onConfirmAction={() => { alert("Normal Modal Confirmed") }}
                confirmBtnTitle="Accept"
                confirmBtnDisabled={false}
                confirmBtnType="primaryDanger"

                onCancelAction={handleCloseNormalModal}
                cancelBtnTitle="Cancel"
                cancelBtnType="secondaryDefault"

                thirdButtonTitle="Open Alert Modal"
                thirdButtonAction={() => {
                    handleCloseNormalModal();
                    handleShowAlertModal(true);
                }}
                thirdBtnDisabled={false}

                footer={optionModalShowFooter && optionModalShowFooter.value && footerContentElement}
                alertShowCancelButton={optionAlertModalShowCancelButton && optionAlertModalShowCancelButton.value}
                alertFooterLeftContent={optionAlertModalFooterLeftContent && optionAlertModalFooterLeftContent.value && alertFooterLeftContentElement}
                alertConfirmButton={optionAlertModalShowConfirmButton && optionAlertModalShowConfirmButton.value && alertConfirmButtonElement}
            >
                <span>Content for Normal Modal here...</span>
            </NormalModal>

            <LoadingModal
                isOpen={loadingModalIsOpen}
                loading={true}
                text={loadingModalText}
                onClose={handleCloseLoadingModal}
            />
        </>
    )
}