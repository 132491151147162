import React, { useState } from 'react';
import TinymceEditorPattern from './TinymceEditor/TinymceEditorPattern.jsx';
import { DropdownMultiSelectPattern } from './DropdownMultiSelect/DropdownMultiSelectPattern';
import {
	BootstrapedTablePattern,
	EmptyStatePattern,
	InputColorPattern,
	ModalPattern,
	RadioButtonPattern,
	CheckboxPattern,
	SectionLabelPattern,
	TabPreviewListPattern,
	ActionsButtonPattern,
	ToasterPattern,
	ContentSwitcherPattern,
	BreadcrumbPattern,
	BadgePattern,
	TagPattern,
	FeaturedIconPattern,
	FileUploadPattern,
	AvatarPattern,
	ButtonPattern,
	DropdownPattern,
	Dropdown,
	CardContainerPattern,
	InputPattern,
	CustomTabsPattern,
	TextAreaPattern,
	FileIconPattern,
	MediaPlayerPattern,
	FilterMenuPattern,
	TooltipPattern,
	DateTimePickerPattern,
	CarouselPattern,
	ToggleButtonPattern,
	ProgressBarPattern,
	AccordionPattern,
	NavCardPattern,
	StepsBarPattern,
	OptionGroupPattern,
	ChatPattern,
} from './';

const ComponentsV2 = () => {
	const [selectedComponent, setSelectedComponent] = useState(null);

	const components = [
		{
			value: <BootstrapedTablePattern />,
			label: 'Table & Pagination',
		},
		{
			value: <ActionsButtonPattern />,
			label: 'Table Actions',
		},
		{
			value: <ContentSwitcherPattern />,
			label: 'Content Switcher',
		},
		{
			value: <BadgePattern />,
			label: 'Badge',
		},
		{
			value: <TextAreaPattern />,
			label: 'Text Area',
		},
		{
			value: <BreadcrumbPattern />,
			label: 'Breadcrumb',
		},
		{
			value: <ModalPattern />,
			label: 'Modal',
		},
		{
			label: 'Colors',
			value: <InputColorPattern />,
		},
		{
			value: <DropdownMultiSelectPattern />,
			label: 'DropdownMultiSelect',
		},
		{
			value: <DropdownPattern />,
			label: 'Dropdown',
		},
		{
			value: <CustomTabsPattern />,
			label: 'Tabs',
		},
		{
			value: <TagPattern />,
			label: 'Tag',
		},
		{
			label: 'Card',
			value: <CardContainerPattern />,
		},
		{
			value: <TinymceEditorPattern />,
			label: 'Editor',
		},
		{
			value: <EmptyStatePattern />,
			label: 'Empty state',
		},
		{
			value: <ButtonPattern />,
			label: 'Buttons',
		},
		{
			value: <AvatarPattern />,
			label: 'Avatar',
		},
		{
			label: 'Radio Button',
			value: <RadioButtonPattern />,
		},
		{
			label: 'CheckBox',
			value: <CheckboxPattern />,
		},
		{
			label: 'Featured Icon',
			value: <FeaturedIconPattern />,
		},
		{
			label: 'Section Label',
			value: <SectionLabelPattern />,
		},
		{
			label: 'Tab Preview',
			value: <TabPreviewListPattern />,
		},
		{
			value: <InputPattern />,
			label: 'Input',
		},
		{
			value: <ToasterPattern />,
			label: 'Toaster',
		},
		{
			value: <FileUploadPattern />,
			label: 'File Uploader',
		},
		{
			value: <ToggleButtonPattern />,
			label: 'Toggle Button',
		},
		{
			value: <MediaPlayerPattern />,
			label: 'Media Player',
		},
		{
			value: <FileIconPattern />,
			label: 'File Icon',
		},
		{
			value: <FilterMenuPattern />,
			label: 'Filter Menu & Filter Bar',
		},
		{
			value: <TooltipPattern />,
			label: 'Tooltip',
		},
		{
			value: <DateTimePickerPattern />,
			label: 'DateTime Picker',
		},
		{
			value: <CarouselPattern />,
			label: 'Carousel',
		},
		{
			value: <ProgressBarPattern />,
			label: 'Progress Bar',
		},
		{
			value: <AccordionPattern />,
			label: 'Accordion',
		},
		{
			value: <NavCardPattern />,
			label: 'NavCard',
		},
		{
			value: <StepsBarPattern />,
			label: 'StepsBar',
		},
		{
			value: <OptionGroupPattern />,
			label: 'OptionGroup',
		},
		{
			value: <ChatPattern />,
			label: 'Chat',
		},
	];

	const onChangeHandler = item => {
		setSelectedComponent(item);
	};

	return (
		<div className="container-fluid mt-3">
			<div className="row">
				<div className="col-md-6">
					<Dropdown
						label="Component to render"
						placeholder="Select component"
						value={selectedComponent}
						options={components.sort((a, b) => a.label.localeCompare(b.label))}
						onChange={onChangeHandler}
						hasSearch={true}
					/>
				</div>
				<div className="col-md-12 mt-3">{selectedComponent && selectedComponent.value}</div>
			</div>
		</div>
	);
};

export default ComponentsV2;
