import React from 'react';
import PropTypes from 'prop-types';
import './css/CheckboxGroup.scss';

export const CheckboxGroup = ({
	label,
	size, // small | medium
	layout, // block | inline
	fullwidth = false,
	required,
	children,
	className,
}) => {
	return (
		<div className={`checkbox-group-wrapper ${className}`}>
			{label && label.length > 0 && (
				<label className={`checkbox-group-label size-${size ? size : 'small'}`}>
					{label}
					{required ? (
						<span className="label-required ml-1">*</span>
					) : (
						<span className="label-optional ml-1">(Optional)</span>
					)}
				</label>
			)}
			<div
				className={`checkbox-group layout-${layout ? layout : 'block'} ${
					fullwidth ? 'fullwidth' : ''
				}`}
			>
				{children}
			</div>
		</div>
	);
};

CheckboxGroup.propTypes = {
	label: PropTypes.string,
	size: PropTypes.string,
	layout: PropTypes.string,
	required: PropTypes.bool,
	fullwidth: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};
