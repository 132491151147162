import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Dialog } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import Swal from 'sweetalert2';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	${'' /* padding: 5px 5px 0 5px; */}
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;





const DialogWrap = styled.div`

    padding-left: 10px;
    padding-right: 10px;

`;



const DisableModal = ({ isOpen, closeModal, siteName,submitModel,loading }) => {

	
	return (
		<Dialog isOpen={isOpen} onClose={closeModal}>
		 {loading && <LoadingSpinner />}

		 {!loading && (
			<DialogWrap>
				<TitleWrap>
					<Title>Disable User Role?</Title>
					<Button
						text=""
						minimal
						icon="cross"
						onClick={closeModal}
					/>
				</TitleWrap>
				<hr />
				{(
					<>

						<p>Are you sure you want to disable this role for {siteName}</p>
						<hr />
						<div className="d-flex justify-content-end mt-2 px-2">
							<Button
								text="Cancel"
								large
								intent="secondary"
								onClick={closeModal}
							/>
							<Button
								className="ml-2"
								text="Disable"
								large
								intent="primary"
								onClick={submitModel}
							/>
						</div>
					</>
				)}

			</DialogWrap>
		 )}

		</Dialog>
	);

}



export default DisableModal;
