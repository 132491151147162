import { HttpService } from 'networking/HttpService';
import { deserializeDrillTypes } from 'utilities/drills/deserializers';

export const DrillTypesService = {
	async getAll(includeAllDrillTypesOption, year = null, stateId = null, isDistinctDrillTypes) {
		let route = '/drill-types';
		if (year) {
			route += `?year=${year}&`;
		}
		if (isDistinctDrillTypes) {
			route += `?isDistinctDrillTypes=${isDistinctDrillTypes}`;
		}
		if (stateId && year) {
			route += `stateId=${stateId}`;
		} else if (stateId) {
			route += `?stateId=${stateId}`;
		}
		const response = await HttpService({
			method: 'GET',
			route,
		});
		const drills = deserializeDrillTypes(response.data);
		if (includeAllDrillTypesOption) {
			return [{ value: 'All drill types', label: 'All drill types' }, ...drills];
		}
		return drills;
	},
	async createDrillType(name) {
		return HttpService({
			method: 'POST',
			route: `/drill-types`,
			body: {
				name,
			},
		});
	},
};
