import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup as BPInput, Intent } from '@blueprintjs/core';
import MaskedInput from 'react-text-mask';
import InputWrapper , { ElementWrapper}  from 'componentsV2/InputWrapper/InputWrapper.jsx';
import './css/Input.scss';

const Input = ({
  label = null,
  RightSideItem = null,
  placeholder = 'Enter a value',
  value = null,
  onChange = null,
  disabled = false,
  width = null,
  error = false,
  errorMessage = null,
  hasHintText=false,
  hintText=null,
  isRequired,
  labelState,
  large,
  icon,
  small,
  intent,
  className = '',
  type = 'text',
  rightElement,
  readOnly,
  inputRef,
  maxLength,
  helpText,
  onBlur,
  defaultValue,
  noMarginBottom = false,
  useEllipsisWrapper,
  mask,
  ...props
}) => {
	const ElementInput = (!!mask) ? MaskedInput : BPInput;
	return (
    <InputWrapper
      label={label}
      placeholder={placeholder}
      isRequired={isRequired}
      error={error}
      errorMessage={errorMessage}
      hasHintText={hasHintText}
      hintText = {hintText}
      labelState ={labelState}
      RightSideItem={RightSideItem}
    >
      <ElementWrapper className="Input__Wrapper" width={width}>
        <ElementInput
          type={type}
          className={`TextInput__Area ${className} bp3-input-${intent || 'primary'} input ${
           mask ? 'maskInput' : '' 
          } ${
            error ? 'error' : '' 
          }`}
          disabled={disabled}
          large={large}
          leftIcon={icon}
          onChange={onChange}
          placeholder={placeholder}
          small={small}
          value={value}
          intent={error ? Intent.DANGER : intent}
          rightElement={rightElement}
          readOnly={readOnly}
          maxLength={maxLength}
          inputRef={inputRef}
          onBlur={onBlur}
          defaultValue={defaultValue}
          mask={mask}
          {...props}
        />
      </ElementWrapper>
    </InputWrapper>
	)};

Input.propTypes = {
	disabled: PropTypes.bool,
	large: PropTypes.bool,
	icon: PropTypes.string,
	intent: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	small: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string,
	type: PropTypes.string,
	rightElement: PropTypes.any,
	readOnly: PropTypes.bool,
	maxLength: PropTypes.number,
	inputRef: PropTypes.any,
	label: PropTypes.string,
    RightSideItem:PropTypes.any,
	helpText: PropTypes.string,
	onBlur: PropTypes.func,
	defaultValue: PropTypes.any,
	error: PropTypes.bool,
	useEllipsisWrapper: PropTypes.bool,
	errorMessage: PropTypes.string,
	noMarginBottom: PropTypes.bool,
	mask: PropTypes.array
};

export { Input };
