import React, { useState, useEffect } from "react";
import { Avatar, Badge, TableCell } from "componentsV2";
import { PUBLISH_STATUS, ARCHIVED_STATUS, STATUS_LABELS } from "./statuses";
import { NavLink } from 'react-router-dom';
import "../css/PublishHistoryCardContainer.scss"

export const HistoryContent = ({
    historyContent = [],
    status = PUBLISH_STATUS,
    loadTabPreviewModal
}) => {
    const [user, setUser] = useState(null);

    useEffect(async () => {
        await setUserData();
    }, [])

    const setUserData = async () => {
        //get the user based on status
        switch (status) {
            case PUBLISH_STATUS:
                setUser(historyContent.publisher);
            break;
            case ARCHIVED_STATUS:
                setUser(historyContent.archiver);
            break;
        }
    }

    return (
        <div className="history-content">
            <div className="history-title" >
                <NavLink 
                    className="history-link"
                    exact
                    to='#'
                    onClick={()=>loadTabPreviewModal(historyContent.id)}
                    >
                    {historyContent.title}
                </NavLink>
            </div>
            <div className="history-details">
                <div className="left-side">
                {user && (
                    <>
                    <Avatar
                        size='md'
                        image={user.photo}
                        name={user.name}
                    />
                    <TableCell
                        text={user.name}
                        supportText={status == PUBLISH_STATUS ? historyContent.datePublishedFormatted : historyContent.dateArchivedFormatted}
                    />
                    </>
                )}
                </div>
                <div className="right-side">
                    <Badge
                        key="status"
                        type="md"
                        size={status == PUBLISH_STATUS ? "Success" : "Gray"}
                        text={STATUS_LABELS.find((statusObj) => statusObj.value === status).label}
                    />
                </div>
            </div>
        </div>
    );
}