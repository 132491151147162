/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs} from '@blueprintjs/core';
import styled from 'styled-components';
import {Title} from 'components/Title';
import {Button} from 'components/Button';
import {LoadingSpinner} from 'components/LoadingSpinner';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import selectRoute from '../Login/selectors';
import { decodeToken } from 'utilities';
import {checkPermission} from '../../utilities/permissions';
import { PageHeader } from '../../components/PageHeader';
// import { AdminDashboard, Dashboard, Overview, Settings } from './screens';
import {AdminDashboard, Dashboard, Settings} from './screens';
import Administration from './screens/Administration';

const DashbordWrap = styled.div`
    .bp3-tabs {
        min-height: 80vh;
    }
`;


class DashboardTabs extends React.Component {
    state = {
        primaryBuildingId: null,
        loading: true,
        tabId: '',
    };

    checkUser = () => {
        const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
        if(!loggedUserData){
            localStorage.removeItem('user');
            window.location.href = "/login";
        }
        return this.setState({
            primaryBuildingId: loggedUserData.primaryBuildingId,
            loading: false,
        });
    };

    clearStore = () => {
        const {dispatch} = this.props;
        return dispatch({
            type: 'CLEAR_TEMPLATE_DATA',
        });
    };
    changeTab = (newTabId) => {
        this.setState({tabId: newTabId});
    }

    componentDidMount = () => {
        this.checkUser();
        this.clearStore();
    };

    componentDidUpdate = () => {
        const {
            history,
            route: {permissions, permissionsFetched},
        } = this.props;

        if (permissionsFetched) {
            const permissionCheck = checkPermission(
                'safety_plans_view',
                permissions,
            );

            if (!permissionCheck && permissionsFetched) {
                return history.push(`/comingsoon/flipcharts`); //Just send the user to flipcharts
            }
        }
    };

	render() {
		const { 
			history,
			route,
			route: { permissions, permissionsFetched }
		} = this.props;
		const { primaryBuildingId, loading, tabId } = this.state;
        let hasEditPermissionOnDistrict;

        const localToken = JSON.parse(localStorage.getItem('user'));
        if(!!localToken?.jwt) {
            const { dist } = decodeToken(localToken.jwt);
            hasEditPermissionOnDistrict = checkPermission('safety_plans_edit', permissions, dist);
        }


		if (loading || !permissionsFetched) return <LoadingSpinner />;
		return (
			<div className="container-fluid">
				<DashbordWrap>
					<div className="d-flex justify-content-between">
                        <PageHeader 
							title="Safety Plans"
							customItem={() => primaryBuildingId === 6880 && tabId !== 'Settings' && (
								<Button
									text="Create Template"
									intent="secondary"
									onClick={() => history.push('/addtemplate')}
								/>
							)}
						/>
					</div>
					<hr />
					<Tabs
						id="TabsExample"
                        renderActiveTabPanelOnly={true}
						defaultSelectedTabId={
							primaryBuildingId === 6880
								? 'AdminDashboard'
								: 'Dashboard'
						} onChange={(newTabId) => this.changeTab(newTabId)}
					>
						<Tab
							id="Dashboard"
							title="Dashboard"
							panel={<Dashboard history={history} route={route}/>}
						/>

                        {hasEditPermissionOnDistrict && (
                            <Tab
                                id="Settings"
                                title="Settings"
                                panel={<Settings history={history}/>}
                            />
                        )}
                        {primaryBuildingId === 6880 && (
                            <Tab
                                id="AdminDashboard"
                                title="Admin Dashboard"
                                panel={<AdminDashboard history={history}/>}
                            />
                        )}
                        {primaryBuildingId === 6880 && (
                            <Tab
                                id="Administration"
                                title="Generate new template IDs"
                                panel={<Administration />}
                            />
                        )}
                    </Tabs>
                </DashbordWrap>
            </div>
        );
    }
}

DashboardTabs.propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(DashboardTabs);
