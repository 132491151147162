import React, { useContext } from 'react';
import { FieldCard, AlarmContact911, AlarmType911List } from '../SharedFields';
import { CreateAlarmTypeContext } from '../../../../../../provider';
import '../SharedFields/css/FieldCard.scss';

export const Contact911 = () => {
	const { formData, formErrors, onChangeFormData, loading } = useContext(CreateAlarmTypeContext);

	return (
		<FieldCard
			header={`${
				formData._911Active
					? 'Utilize 911Cellular integration'
					: 'Enable 911 prompt on alarm activation'
			} `}
			description={
				!formData._911Active ? (
					<>
						Would you like a prompt to call 911 when this alarm goes off?
						<span className="text-danger">*</span>
						<br />
						<br />
						This serves as a reminder for the individual activating the alarm to reach
						out to emergency services if they haven't done so.
					</>
				) : (
					<>
						Do you want to use this alarm with your 911Cellular configuration?
						<span className="text-danger"> *</span>
					</>
				)
			}
			loading={loading}
			style={{
				height: '100%',
			}}
		>
			<AlarmContact911
				formData={formData}
				formErrors={formErrors}
				onChangeFormData={onChangeFormData}
			/>
			{formData._911Active && (
				<>
					<h3 className="field-card-container-header-container-title-container-title">
						Choose 911Cellular alarm
					</h3>

					<p className="field-card-container-header-container-description">
						Choose the alarm that displays at the 911 Public Safety Answering Point
						(PSAP).
						<br />
						<br />
						This alarm name will be what dispatchers see at the 911 call center when
						this alarm is activated.
					</p>

					<AlarmType911List
						formData={formData}
						formErrors={formErrors}
						onChangeFormData={onChangeFormData}
					/>
				</>
			)}
		</FieldCard>
	);
};
