import React, { useContext, useState } from 'react';
import {
	Button,
	Input,
	LoadingSpinner,
	ToasterContext,
	ToasterMessage,
	Intent,
} from 'componentsV2';
import { logoNavigateSwitch, logoNavigateDark } from 'assets/icons';
import './css/PasswordRecovery.scss';

export const PasswordRecovery = props => {
	const toaster = useContext(ToasterContext);
	const { senderEmail } = props.location.state;
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState(senderEmail);

	const submitForget = e => {
		e.preventDefault();
		// eslint-disable-next-line no-useless-escape
		const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		if (filter.test(email)) {
			setLoading(true);
			return fetch(`${process.env.API_URL}/password-reset-emails`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: email,
				}),
			})
				.then(res => res.json())
				.then(resp => {
					if (resp.statusCode === 200 || resp.statusCode === 201) {
						toaster(
							`A reset email has been sent successfully to: ${email}`,
							Intent.SUCCESS,
						);
						setSubmitDisabled(true);
					} else {
						toaster(resp.error.description, Intent.DANGER);
					}
					setLoading(false);
				});
		}
		toaster('Email is not valid!', Intent.DANGER);
	};

	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && (
				<div className="new-login-container">
					<div className="new-login-wrapper">
						<img src={logoNavigateSwitch} />
						<div className="new-login-box">
							<div className="new-login-title">Forgot Password</div>
							<div className="new-login-controls">
								<div className="w-100">
									<Input
										label="Email"
										isRequired={true}
										disabled={submitDisabled}
										placeholder="Enter your email address"
										large
										value={email}
										onChange={e => setEmail(e.target.value)}
									/>
								</div>
								<div className="new-login-actions">
									<Button
										large={true}
										wrapperClass="w-100"
										text="Send Reset Link"
										type="primaryDefault"
										intent="default"
										disabled={submitDisabled}
										onClick={e => submitForget(e)}
									/>
									{props.history && (
										<p>
											<a
												onClick={() => {
													props.history.goBack();
												}}
											>
												Go back to Login
											</a>
										</p>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="new-login-footer">
						<img src={logoNavigateDark} />
					</div>
				</div>
			)}
		</>
	);
};
