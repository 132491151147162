import { useReducer } from 'react';

export const FilterMenuFn = () => {
	const filterMenuReducer = (data, action) => {
		data[action.type] = action.payload;
		return {
			...data,
		};
	};

	const [filterData, dispatchFilterData] = useReducer(filterMenuReducer, {
		filters: null,
	});

	const initialFilterDrillData = data => {
		dispatchFilterData({
			type: 'filters',
			payload: {
				...data,
			},
		});
	};

	const handleChangeFilter = (filterName, value, filterType) => {
		if (filterType && filterType == 'multiple') {
			let values = getFilterValue(filterName);
			if (!values) {
				values = [];
			}

			if (values.includes(value)) {
				values = values.filter(valueChecked => valueChecked != value);
			} else {
				values.push(value);
			}

			if (values.length > 0) {
				dispatchFilterData({
					type: 'filters',
					payload: {
						...filterData.filters,
						[filterName]: values,
					},
				});
			} else {
				removeFilter(filterName);
			}
		} else {
			if ((typeof value === 'string' || value instanceof String) && value.length == 0) {
				removeFilter(filterName);
			} else {
				dispatchFilterData({
					type: 'filters',
					payload: {
						...filterData.filters,
						[filterName]: value,
					},
				});
			}
		}
	};

	const removeFilter = filterName => {
		let filters = filterData.filters;
		if (filters && filters[filterName] !== undefined) {
			delete filters[filterName];
		}
		dispatchFilterData({
			type: 'filters',
			payload: filters,
		});
	};

	const getFilterValue = filterName => {
		let value = '';
		if (filterData.filters && filterData.filters[filterName]) {
			value = filterData.filters[filterName];
		}
		return value;
	};

	return {
		filterData,
		handleChangeFilter,
		removeFilter,
		getFilterValue,
		initialFilterDrillData,
	};
};
