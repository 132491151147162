import React from 'react';
import { FileIcon } from 'componentsV2';
import {
	AUDIO_MIME_TYPES,
	CSV_MIME_TYPE,
	DOCX_MIME_TYPE,
	DOC_MIME_TYPE,
	IMAGE_MIME_TYPES,
	PDF_MIME_TYPE,
	PPTX_MIME_TYPE,
	PPT_MIME_TYPE,
	TXT_MIME_TYPE,
	VIDEO_MIME_TYPES,
	XLSX_MIME_TYPE,
	XLS_MIME_TYPE,
	ODT_MIME_TYPE,
} from './constants';
import { checkPermission } from './permissions';

export const Helper = {
	getSelectedSiteOnLoad: (selectedSites, allSites) => {
		let selectedSiteOnLoad;
		const firstSelectedSite = selectedSites.length > 0 ? selectedSites[0] : false;
		if (!firstSelectedSite) {
			selectedSiteOnLoad = allSites[0];
		} else {
			// check if the first selected sites is included in the list of sites has edit permission
			const firstSelectedSiteExists = allSites.some(item => item.id === firstSelectedSite.id);
			if (firstSelectedSiteExists) {
				selectedSiteOnLoad = firstSelectedSite;
			} else {
				selectedSiteOnLoad = allSites[0];
			}
		}
		return selectedSiteOnLoad;
	},

	// change phone number format from (123) 456-7899 to 1234567899
	formatPhoneToDigits: phoneNumber => (phoneNumber ? phoneNumber.replace(/\D/g, '') : ''),

	// change phone number format from 1234567899 to (123) 456-7899
	formatDigitsToPhone: phoneNumber =>
		phoneNumber ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '',

	capitalizeFirstLetter: string => string.charAt(0).toUpperCase() + string.slice(1),

	// Sort array of objects by a specified field
	sortByField: (array, field) =>
		array.sort((a, b) => {
			const keyA = a[field];
			const keyB = b[field];
			// Compare the values
			if (keyA < keyB) return -1;
			if (keyA > keyB) return 1;
			return 0;
		}),

	getMultipleSelectedSitesOnLoad: (sites, permissions) => {
		const selectedSites = [];

		sites.forEach(site => {
			const siteHasPermission = checkPermission('user_edit', permissions, site.value);

			if (siteHasPermission) {
				selectedSites.push(site);
			}
		});

		if (selectedSites.length < 1) {
			const loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));

			const primaryHasPermission = checkPermission(
				'user_edit',
				permissions,
				loggedUser.primaryBuilding.id,
			);

			if (primaryHasPermission) {
				selectedSites.push({
					label: loggedUser.primaryBuilding.name,
					value: loggedUser.primaryBuilding.id,
				});
			}
		}

		return selectedSites;
	},
};

/**
 * @param mimeType
 * @param sourceURL This param is passed if you want to display an actual image
 *
 * @return <FileIcon>
 */
export const getIconFromMimeType = (mimeType, sourceURL = null) => {
	if (IMAGE_MIME_TYPES.includes(mimeType) && sourceURL) {
		return <FileIcon sourceURL={sourceURL} />;
	}
	if (IMAGE_MIME_TYPES.includes(mimeType)) {
		return <FileIcon icon="empty" />;
	}
	if (mimeType == PDF_MIME_TYPE) {
		return <FileIcon icon="pdf" />;
	}
	if (mimeType == PPT_MIME_TYPE) {
		return <FileIcon icon="ppt" />;
	}
	if (mimeType == PPTX_MIME_TYPE) {
		return <FileIcon icon="pptx" />;
	}
	if (mimeType == XLS_MIME_TYPE) {
		return <FileIcon icon="xls" />;
	}
	if (mimeType == XLSX_MIME_TYPE) {
		return <FileIcon icon="xlsx" />;
	}
	if (mimeType == CSV_MIME_TYPE) {
		return <FileIcon icon="csv" />;
	}
	if (mimeType == DOC_MIME_TYPE || mimeType == ODT_MIME_TYPE) {
		return <FileIcon icon="doc" />;
	}
	if (mimeType == DOCX_MIME_TYPE) {
		return <FileIcon icon="docx" />;
	}
	if (mimeType == TXT_MIME_TYPE) {
		return <FileIcon icon="txt" />;
	} else if (VIDEO_MIME_TYPES.includes(mimeType)) {
		return <FileIcon icon="video" />;
	} else if (AUDIO_MIME_TYPES.includes(mimeType)) {
		return <FileIcon icon="mp3" />;
	}

	return <FileIcon icon="empty" />;
};

export const SiteVisibilityError = ({ errors }) => (
	<>
		<div>
			<strong>{errors.site_id}</strong>
		</div>
		{errors.plans_sites && errors.plans_sites.length > 0 && (
			<>
				<strong>Safety Plans:</strong>
				{errors.plans_sites.map((planSite, index) => (
					<ul className="m-0" key={index}>
						<li>
							{planSite.name} - {planSite.site}
						</li>
					</ul>
				))}
			</>
		)}
		{errors.call_list_sites && errors.call_list_sites.length > 0 && (
			<>
				<strong>Call Lists:</strong>
				{errors.call_list_sites.map((callList, index) => (
					<ul className="m-0" key={index}>
						<li>
							{callList.name} - {callList.site}
						</li>
					</ul>
				))}
			</>
		)}
		{errors.assessments_sites && errors.assessments_sites.length > 0 && (
			<>
				<strong>Assessments:</strong>
				{errors.assessments_sites.map((assessment, index) => (
					<ul className="m-0" key={index}>
						<li>
							{assessment.name} - {assessment.site}
						</li>
					</ul>
				))}
			</>
		)}
		{errors.documents_sites && errors.documents_sites.length > 0 && (
			<>
				<strong>Documents:</strong>
				{errors.documents_sites.map((document, index) => (
					<ul className="m-0" key={index}>
						<li>
							{document.name} - {document.site}
						</li>
					</ul>
				))}
			</>
		)}
		{errors.maps_sites && errors.maps_sites.length > 0 && (
			<>
				<strong>Maps:</strong>
				{errors.maps_sites.map((map, index) => (
					<ul className="m-0" key={index}>
						<li>
							{map.name} - {map.site}
						</li>
					</ul>
				))}
			</>
		)}
	</>
);

/**
 * Takes two objects and returns the key/value pairs that have changed values.
 *
 * Note: Both objects must have the same keys, and will return '{}' if there are no changes.
 *
 * @param {object} originalData
 * @param {object} currentData
 * @return {object}
 */
export const changedData = (originalData, currentData) =>
	Object.fromEntries(
		Object.entries(currentData).filter(value => {
			if (currentData[value[0]] !== originalData[value[0]]) {
				if (value[1]?.length === 0) {
					return value[0];
				}
				return value[1];
			}
		}),
	);

/**
 * Convert a 2D array into a string
 */
export const arrayToString = data => {
	return data
		.map(
			row =>
				row
					.map(String) // convert every value to String
					.join(','), // comma-separated
		)
		.join('\r\n'); // rows starting on new lines
};

/**
 *
 * @param {*} content data as string each row separated by new line
 * example:
 * header1,header2,header3
 * data1,data2,data3
 *
 * You can use arrayToString to prepare data
 * @param {*} filename exported file name example: example.csv
 * @param {*} contentType file type example: text/csv;charset=utf-8;
 *
 */
export const downloadBlob = (content, filename, contentType) => {
	// Create a blob
	var blob = new Blob([content], { type: contentType });
	var url = URL.createObjectURL(blob);

	// Create a link to download it
	var pom = document.createElement('a');
	pom.href = url;
	pom.setAttribute('download', filename);
	pom.click();
};
