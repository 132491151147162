import React, { createContext, useState } from 'react';

export const ReunificationContext = createContext();

export const ReunificationProvider = ({ children }) => {
	const initialFilterationData = {
		studentName: '',
		grades: [],
		statusSelected: [],
		checkedInOnly: true,
		page: 1,
		perPage: 10,
		sortKey: 'studentName',
		sortDir: 'asc',
	};
	const [filterationData, setFilterationData] = useState(initialFilterationData);
	const [filterationDisabled, setFilterationDisabled] = useState(false);
	const [reloadGuardiansList, setReloadGuardiansList] = useState(false);
	const initialGuardianFilterationData = {
		guardianName: '',
		statuses: [],
		includeExistingContacts: false,
		page: 1,
		perPage: 10,
		sortKey: 'guardianName',
		sortDir: 'asc',
	};
	const [guardianFilterationData, setGuardianFilterationData] = useState(
		initialGuardianFilterationData,
	);
	const [gaurdianFilterationDisabled, setGaurdianFilterationDisabled] = useState(false);

	const sharedData = {
		initialFilterationData,
		filterationData,
		setFilterationData,
		filterationDisabled,
		setFilterationDisabled,
		initialGuardianFilterationData,
		guardianFilterationData,
		setGuardianFilterationData,
		gaurdianFilterationDisabled,
		setGaurdianFilterationDisabled,
		reloadGuardiansList,
		setReloadGuardiansList,
	};

	return (
		<ReunificationContext.Provider value={sharedData}>{children}</ReunificationContext.Provider>
	);
};
