import React, { useContext } from 'react';
import { Button, ActionsButton } from 'componentsV2';
import PropTypes from 'prop-types';
import { MapsContext } from '../../../Providers';

export const TextListActionsList = ({
	textShape,
	editPermission,
	floorplanArchived,
	tabType,
	siteId,
	layer,
	isEditMode,
}) => {
	if (!editPermission || floorplanArchived || !isEditMode) return null;
	const MapCTX = useContext(MapsContext);

	return (
		<ActionsButton popoverClassName="map-layers-actions-list">
			<Button
				text="Rename"
				icon="edit"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => MapCTX.showTextRenameModal(textShape, tabType, siteId, layer)}
			/>
			<Button
				text="Delete"
				icon="trash"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => MapCTX.showTextDeleteModal(textShape, tabType, siteId, layer)}
			/>
		</ActionsButton>
	);
};

TextListActionsList.propTypes = {
	textShape: PropTypes.object,
	editPermission: PropTypes.bool,
	floorplanArchived: PropTypes.bool,
};
