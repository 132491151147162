import React, { useState, useEffect, useReducer } from 'react';
import { Modal, FeaturedIcon, RadioButtonGroup, ModalFooter, Button, TextArea } from 'componentsV2';
import './css/AlarmFormModal.scss';
import { Radio } from '@blueprintjs/core';
import { AFTER_ACTIVATION_STATUSES } from '../ActivateAlarmModal/consts';

export const AlarmFormModal = ({
	hasCallout = true,
	preSelectedValue = 0,
	toasterMsg = '',
	loading = false,
	childern,
	setFormData,
	formData,
	modalData,
	onChangeModalData,
	onClose,
	response,
	confirmSafeAction,
	showCancelBtn = true,
	...props
}) => {
	const [selectedValue, setSelectedValue] = useState(preSelectedValue);
	const [disabled, setDisabled] = useState(true);
	const [calloutData, setCalloutData] = useState();

	const modalDetailsReducer = (modalDetails, action) => {
		modalDetails = action.payload;
		return {
			title: modalDetails.title,
			subtitle: modalDetails.subtitle,
			icon: modalDetails.icon,
			onConfirmHandler: modalData.onConfirmHandler,
		};
	};

	const handleChange = ({ target }) => {
		setSelectedValue(target.value);
		setDisabled(false);
		setFormData({ ...formData, selectedOption: target.value });
	};

	const onNoteChangeHandler = e => {
		let text = e.target.value;

		if (text.trim() != '') {
			setDisabled(false);
			setFormData({ ...formData, value: text });
		} else {
			setDisabled(true);
		}
	};

	const initialState = {
		title: ' ',
		subtitle: ' ',
		icon: {
			icon: '',
			iconColor: '',
		},
		onConfirmHandler: modalData.onConfirmHandler,
	};
	const [modalDetails, dispatchModalDetails] = useReducer(modalDetailsReducer, initialState);

	useEffect(() => {
		let modalTitle = '';
		let modalSubtitle = '';
		let modalIcon = null;
		const icon = (
			<FeaturedIcon icon={'tick'} type={'Success'} size={'md'} shape={'circleIcon'} />
		);
		setCalloutData({
			intent: 'success',
			text: modalData.alertMsg,
			icon: icon,
		});

		if (modalData.showAreYouSafe) {
			modalTitle = 'Are you safe?';
			modalSubtitle =
				'Before you start Roll Call, please mark your status so we can notify administration. You can update your status within Roll Call.';
			modalIcon = {
				icon: 'hand',
				iconColor: 'error',
			};
		} else if (modalData.ShowNeedAssistant) {
			modalTitle = 'Needs assistance';
			modalSubtitle =
				'This individual requires assistance. Please include a note with additional details.';
			modalIcon = {
				icon: 'warning-sign',
				iconColor: 'warning',
			};
		}
		if (preSelectedValue != 0 && preSelectedValue) {
			setDisabled(false);
			setFormData({ ...formData, selectedOption: preSelectedValue });
		}

		dispatchModalDetails({
			payload: {
				...modalDetails,
				title: modalTitle,
				subtitle: modalSubtitle,
				icon: modalIcon,
				onConfirmHandler: modalDetails.onConfirmHandler,
			},
		});
		getFooter();
	}, [modalData.showAreYouSafe, response]);

	const getFooter = () => {
		const thirdButtonElement = !loading && modalData.ShowNeedAssistant && (
			<Button
				text="Back to Previous Step"
				minimal={true}
				fill={false}
				type="ghostPrimary"
				onClick={() =>
					onChangeModalData({
						ShowNeedAssistant: false,
						showAreYouSafe: true,
					})
				}
			/>
		);
		const confirmButtonElement = !loading && (
			<Button
				text={modalData.ShowNeedAssistant ? 'Save' : 'Confirm'}
				intent="default"
				disabled={disabled}
				onClick={() => confirmSafeAction(response, formData)}
			/>
		);
		const cancelButtonElement = (
			<Button
				text="Cancel"
				type="tertiary"
				intent="default"
				onClick={() => onClose(response)}
			/>
		);

		return (
			<ModalFooter
				confirmButton={confirmButtonElement}
				cancelButton={showCancelBtn && cancelButtonElement}
				leftContent={thirdButtonElement}
			/>
		);
	};
	return (
		<Modal
			isOpen={true}
			onClose={() => {}}
			title={modalDetails.title}
			subtitle={modalDetails.subtitle}
			size="medium"
			showCloseButton={false}
			customClassName="alarm-form-modal-container"
			loading={loading}
			showHeader={true}
			icon={modalDetails.icon.icon}
			iconColor={modalDetails.icon.iconColor}
			customFooter={getFooter()}
			hasCallout={hasCallout}
			calloutData={calloutData}
			{...props}
		>
			{modalData.showAreYouSafe && (
				<RadioButtonGroup
					label={''}
					onChange={handleChange}
					selectedValue={selectedValue}
					size="medium" // small | medium
					bordered={true}
					required={true}
					layout="block" // block | inline
					fullwidth={true}
				>
					<Radio label="I am safe" value={AFTER_ACTIVATION_STATUSES.OKAY} />
					<Radio label="I need assistance" value={AFTER_ACTIVATION_STATUSES.NOT_OKAY} />
					<Radio label="I am not on campus" value={AFTER_ACTIVATION_STATUSES.NOT_HERE} />
				</RadioButtonGroup>
			)}

			{modalData.ShowNeedAssistant && (
				<TextArea
					label="Note"
					placeholder={'Enter you note...'}
					isRequired={false}
					width={'575px'}
					onChange={onNoteChangeHandler}
				/>
			)}
			{childern}
		</Modal>
	);
};
