import React, {useContext, useEffect, useState} from 'react';
import {LoadingSpinner, Button, Intent, ToasterContext, FeaturedIcon} from 'componentsV2';
import {IncompletePlansTableContent} from "./components/IncompletePlansTableContent";
import {PlanService} from "services/PlanService";
import './css/SafetyPlanSettings.scss';

export const SafetyPlanSettings = ({
                                     editPermission
                                   }) => {

  const [loading, setLoading] = useState(true);
  const [completedPlans, setCompletedPlans] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [toBeSubmitted, setToBeSubmitted] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const toaster = useContext(ToasterContext);

  const closeModal = () => {
    setShowConfirmationModal(false)
  }

  const [filtrationData, setFiltrationData] = useState({
    'sortKey': 'name',
    'sortDir': 'asc'
  });

  const fetchCompletedPlans = async (filtrationData) => {
    try {
      const params = {
        includeContent: "false",
        isCompleted: true,
        isNotDropdown: true,
        sortKey: filtrationData?.sortKey,
        sortDir: filtrationData?.sortDir
      };
      const response = await PlanService.getAll(params);
      if (response.statusCode === 200) {
        setCompletedPlans(response.data);
      } else {
        console.error('Error fetching Templates list:', response.error);
      }
    } catch (error) {
      console.error('Error fetching Templates list:', error);
    } finally {
      setSelectedPlans([]);
      setLoading(false);
    }
  };

  const preSubmittingConfirmation = (validatePlans) => {
    let planPartConfirmationMessage = "all plans";
    let planPartDescription = "all plans";
    setToBeSubmitted(validatePlans)
    if (validatePlans.length === 1) {
      planPartConfirmationMessage = `“${validatePlans[0].name}”`;
      planPartDescription = "these school plans";
    }
    if (validatePlans.length > 1 && validatePlans.length < completedPlans.length) {
      planPartConfirmationMessage = `${validatePlans.length} templates`;
      planPartDescription = "those school plans";
    }
    setMessages([planPartConfirmationMessage, planPartDescription])
    setShowConfirmationModal(true);
  }

  const setSelectedPlansAsIncomplete = (selectedPlans) => {
    setShowConfirmationModal(false);
    setLoading(true);
    const params = {
      isCompleted: false,
      plans: selectedPlans.map(plan => plan.id)
    }
    PlanService.bulkToggleComplete(params)
      .then(async response => {
        if (response.statusCode === 200) {
          toaster(`You have successfully set ${messages[0]} as incomplete.`, Intent.NONE, <FeaturedIcon
            icon={'delete'} type='Gray' size={'md'} shape={'circleIcon'}/>, 20000, true)
        }
      })
      .catch(error => {
        console.error('Error fetching Templates list:', error);
      })
      .finally(() => {
        fetchCompletedPlans(filtrationData);
      });
  }

  useEffect(() => {
    fetchCompletedPlans(filtrationData);
  }, [filtrationData]);

  return (
    <>
      {loading && <LoadingSpinner/>}
      {!loading && (
        <div className="safety-plan-settings-container">
          <section className="settings-header-container">
            <div className="content">
              <span className="text">
                Available safety plan templates
              </span>
              <span className="sub-text">
                Reset the status of one or more safety plans to reinforce site review.
              </span>
            </div>
          </section>
          <div className="settings-action-bar">
            <Button
              type="tertiary"
              intent="default"
              size="md"
              text="Set to incomplete"
              className="actionButtonElement"
              disabled={selectedPlans.length === 0}
              onClick={() => preSubmittingConfirmation(selectedPlans)}
            />
          </div>
          <div className="settings-body-container">
            <IncompletePlansTableContent completedPlans={completedPlans} toBeSubmitted={toBeSubmitted}
                                         selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans}
                                         filtrationData={filtrationData} setFiltrationData={setFiltrationData}
                                         messages={messages}
                                         showConfirmationModal={showConfirmationModal} closeModal={closeModal}
                                         preSubmittingConfirmation={preSubmittingConfirmation}
                                         setSelectedPlansAsIncomplete={setSelectedPlansAsIncomplete}
                                         editPermission={editPermission}

            />
          </div>
        </div>
      )}
    </>
  )
}
