import React, { useState, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { AssessmentService } from 'services';
import { debounce } from 'debounce';
import {
	Badge,
	Button,
	ToasterContext,
	Intent,
	InputWrapper,
	FeaturedIcon,
	TextArea,
	OptionGroup,
} from 'componentsV2';
import { AddAttachmentModal } from './AddAttachmentModal';
import { DeleteDrillTypeModal } from '../../../../DrillsV2/components/Settings/StateRequirements/components/DeleteDrillTypeModal';

const UPDATE_DEBOUNCE_TIME = 1750;

export const AssessmentQuestion = ({
	assessmentId,
	question,
	viewOnly,
	updateQuestionsAnsweredCount,
}) => {
	const { id, title, hint, orderNumber, answer: initialAnswer } = question;
	const toaster = useContext(ToasterContext);

	// Extract response from answer object.
	// TODO: Handle bad parse.
	const getResponseValue = response => (response ? Object.values(JSON.parse(response))[0] : '');
	const initialAnswerValue = getResponseValue(initialAnswer?.response);
	const [answerComment, setAnswerComment] = useState(initialAnswer?.comment || '');
	const [openModal, setOpenModal] = useState(null);
	const [questionAttachments, setQuestionAttachments] = useState([]);

	// TODO: Handle bad parse.
	const rawProperties = JSON.parse(question.properties);
	const { type, options } = rawProperties.answerType || rawProperties;
	const [textResponse, setTextResponse] = useState(initialAnswerValue);
	const [answer, setAnswer] = useState(initialAnswer);

	// Setup initial selection as a lowercase set of keys to match with optionsObject.
	let initialSelection = [];

	// With an initial answer, set initial option selection based on either string or array (multi only);
	if (initialAnswer) {
		if (Array.isArray(initialAnswerValue)) {
			initialSelection = initialAnswerValue.map(key => key.toLowerCase());
		} else {
			initialSelection = [initialAnswerValue.toLowerCase()];
		}
	}

	// Actually try to send response data to save answer.
	const saveResponse = value => {
		const response = {
			[type]: value,
		};

		AssessmentService.saveQuestionAnswer(assessmentId, id, response)
			.then(res => {
				setAnswer(res.data);
				updateQuestionsAnsweredCount();
			})
			.catch(err => {
				console.error(err);
				toaster(
					`There was an error saving your response. Please try again, or refresh your session.`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			});
	};

	// Actually try to save comment.
	const saveComment = commentText => {
		AssessmentService.saveAnswerComment(assessmentId, id, commentText)
			.then(res => {
				setAnswer(res.data);
			})
			.catch(err => {
				console.error(err);
				toaster(
					`There was an error saving your comment. Please try again, or refresh your session.`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			});
	};

	// Debounced versions of the save functions.
	const waitSaveResponse = useCallback(debounce(saveResponse, UPDATE_DEBOUNCE_TIME), []);
	const waitSaveComment = useCallback(debounce(saveComment, UPDATE_DEBOUNCE_TIME), []);
	useEffect(() => {
		if(question.questionHasAttachment){
			setQuestionAttachments(question.answer.attachments);
		}
	}, [question]);

	const handleCloseModal = () => {
		setOpenModal(null);
	};

	const getAllQuestionAttachmentsData = () => {
		AssessmentService.getQuestionAttachments(assessmentId, question?.id).then(res => {
			setQuestionAttachments(res?.data);
		});
	};

	const uploadQuestionAttachment = fileSelected => {
		AssessmentService.uploadQuestionAttachment(assessmentId, question?.id, fileSelected)
			.then(() => {
				getAllQuestionAttachmentsData();
				toaster(
					`You have successfully added this attachment.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
				);
			})
			.catch(err => {
				console.error(err);
				toaster(
					`There was an error adding this attachment. Please try again!`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				handleCloseModal();
			});
	};

	const deleteQuestionAttachment = () => {
		AssessmentService.deleteAttachment(assessmentId, openModal?.questionAttachmentData)
			.then(() => {
				getAllQuestionAttachmentsData();
				toaster(
					`You have successfully deleted this attachment.`,
					Intent.NONE,
					<FeaturedIcon icon="trash" size="md" type="Gray" />,
				);
			})
			.catch(err => {
				console.error(err);
				toaster(
					`There was an error deleting this attachment. Please try again!`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				handleCloseModal();
			});
	};

	// Move options arrays over to objects with key => value.
	let optionsObject = options;
	if (Array.isArray(options)) {
		optionsObject = {};
		options.forEach(option => {
			optionsObject[option.toLowerCase()] = option;
		});
	}

	// Handle input from textarea, option input, and comment.
	const handleInputChange = key => e => {
		const rawString = e?.target?.value;
		if (key === 'textarea') {
			setTextResponse(rawString);
			waitSaveResponse(rawString);
		}

		if (key === 'option') {
			const optVal = type === 'multiple' ? e : e[0];
			waitSaveResponse(optVal);
		}

		if (key === 'comment') {
			setAnswerComment(rawString);
			waitSaveComment(rawString);
		}
	};

	const typeMap = {
		area: 'textarea',
		textarea: 'textarea',
		yn: 'select',
		select: 'select',
		multiple: 'select',
	};

	let input = <p>NOT RENDERABLE</p>;

	// Only render input if type in typeMap.
	if (typeMap[type]) {
		switch (typeMap[type]) {
			case 'textarea':
				input = (
					<TextArea
						disabled={viewOnly}
						placeholder="Add your answer here"
						value={textResponse}
						onChange={handleInputChange('textarea')}
						className="answer"
					/>
				);
				break;

			case 'select':
				input = (
					<OptionGroup
						{...{
							optionsObject,
							initialSelection,
							disabled: viewOnly,
							onSelect: handleInputChange('option'),
							multiple: type === 'multiple',
							caseInsensitive: true,
						}}
					/>
				);
				break;
			default:
				break;
		}
	}

	let answerTime = '';
	if (answer && answer.editedDate) {
		const d = new Date(`${answer.editedDate.date}${answer.editedDate.timezone}`);
		answerTime = `| ${d.toLocaleTimeString()} | ${d.toLocaleDateString()}`;
	}

	return (
		<div className="question">
			<header>
				<div className="badges">
					<Badge text={`Question ${orderNumber}`} type="Primary" size="md" />
					{answer && (
						<Badge
							text={`Saved - ${answer.editedBy} ${answerTime}`}
							type="Success"
							leadIcon="tick"
							hasLeadIcon
							size="md"
						/>
					)}
				</div>
				<div className="titles">
					<h4>{title}</h4>
					{hint && <h5>{hint}</h5>}
				</div>
				<div className="buttons">
					<Button
						text="Attachments"
						type="secondaryDefault"
						intent="default"
						disabled={!answer || viewOnly}
						onClick={() => {
							setOpenModal('add_attachment');
						}}
					/>
				</div>
			</header>
			<div className="body">
				{input}

				<TextArea
					label="Comments"
					className="comments"
					placeholder="Add comments"
					disabled={!answer || viewOnly}
					id={`comments-${id}`}
					labelState={false}
					value={answerComment}
					onChange={handleInputChange('comment')}
				/>

				{questionAttachments?.length > 0 && (
					<InputWrapper label="Attachments" className="attachments" labelState={false}>
						{questionAttachments?.map(attachment => (
							<Badge
								key={attachment?.id}
								type="Gray"
								size="lg"
								text={attachment?.fileName}
								hasCloseIcon
								onClick={() => {
									setOpenModal({
										modalToOpen: 'delete_attachment',
										questionAttachmentData: attachment?.id,
									});
								}}
							/>
						))}
					</InputWrapper>
				)}
			</div>
			{openModal === 'add_attachment' && (
				<AddAttachmentModal
					isOpen
					handleCloseModal={handleCloseModal}
					handleUploadAttachment={uploadQuestionAttachment}
					attachmentModalTitle="Attach image or file"
					attachmentModalLabel="Image or file"
				/>
			)}
			{openModal?.modalToOpen === 'delete_attachment' && (
				<DeleteDrillTypeModal
					isOpen
					onCloseModal={handleCloseModal}
					titleText="Are you sure you want to delete this attachment?"
					buttonText="Delete it"
					onClickDelete={deleteQuestionAttachment}
				/>
			)}
		</div>
	);
};

AssessmentQuestion.propTypes = {
	assessmentId: PropTypes.number.isRequired,
	question: PropTypes.object.isRequired,
	answer: PropTypes.object,
	viewOnly: PropTypes.bool,
};
