import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import _ from 'lodash';

import { FormLabel, RequiredWraper } from 'components/Forms/shared.js';

const animatedComponents = makeAnimated();


const Option = ({
	getStyles,
	Icon,
	isDisabled,
	isFocused,
	isSelected,
	children,
	innerProps,
	...rest
}) => {
	const [isActive, setIsActive] = useState(false);
	const onMouseDown = () => setIsActive(true);
	const onMouseUp = () => setIsActive(false);
	const onMouseLeave = () => setIsActive(false);

	// styles
	let bg = 'transparent';
	let color = 'inherit';
	let cursor = 'default';

	if (isFocused) bg = '#eee';
	if (isActive) bg = '#B2D4FF';
	if (isDisabled) {
		color = '#BEBEBE';
		cursor = 'not-allowed';
	}
	const style = {
		alignItems: 'center',
		backgroundColor: bg,
		color,
		display: 'flex',
		cursor,
	};

	let disabledStyle = {};
	if (isDisabled) {
		disabledStyle = {
			cursor: 'not-allowed',
		};
	}

	// prop assignment
	const props = {
		...innerProps,
		onMouseDown,
		onMouseUp,
		onMouseLeave,
		style,
	};

	return (
		<components.Option
			{...rest}
			isDisabled={isDisabled}
			isFocused={isFocused}
			isSelected={isSelected}
			getStyles={getStyles}
			innerProps={props}
		>
			<div
				style={disabledStyle}
				className="ddl-multiselect-custom d-flex justify-content-start"
			>
				<input
					type="checkbox"
					checked={isSelected}
					style={disabledStyle}
					onChange={() => {}}
				/>
				<p style={disabledStyle}>{rest.label}</p>
			</div>
		</components.Option>
	);
};

const CustomValueContainer = ({ children, ...props }) => {
	const selectedOptions = props.getValue();
	const numSelected = selectedOptions.length;
	const { visibleOptions } = props.selectProps;
	const { showHiddenOptions } = props.selectProps;
	const { setShowHiddenOptions } = props.selectProps;

	const handleClick = () => {
		setShowHiddenOptions(currentValue => !currentValue);
	};

	if (selectedOptions.some(val => val.value === '*')) {
		children = [[children[0][0]], children[1]];
	} else if (visibleOptions && numSelected > visibleOptions) {
		if (!showHiddenOptions) {
			children = [
				children[0].slice(0, visibleOptions),
				<div
					key="more"
					className="x-more-hidden-options"
					onClick={handleClick}
				>
					<div className="x-more-hidden-options-count">
						+{numSelected - visibleOptions} more
					</div>
				</div>,
				children[children.length - 1],
			];
		} else {
			children = [
				...children[0],
				<div
					key="more"
					className="x-more-hidden-options"
					onClick={handleClick}
				>
					<div className="x-more-hidden-options-count">Show less</div>
				</div>,
			];
		}
	}
	return (
		<components.ValueContainer
			{...props}
			style={{overFlowY: 'auto'}}
			className="ddl-multiselect-value-container"
		>
			{children}
		</components.ValueContainer>
	);
};

const MultiValue = props => (
	<components.MultiValue {...props}
		className="multi-value-selector"
	>
		<span>{props.data.label}</span>
	</components.MultiValue>
);

const DropdownMultiSelect = ({
	label = '',
	placeholder = 'Select an option...',
	isRequired,
	values,
	options = [],
	onChange,
	visibleOptions = null,
	disabled = false,
	allowSelectAll = false,
	selectAllLabel = 'Select all',
	selectAllChecked = false,
	defaultValue,
	error = null,
	showRequiredText = false,
	isClearable = true,
}) => {
	const [showHiddenOptions, setShowHiddenOptions] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const selectRef = useRef();

	const selectAllOption = {
		value: '*',
		label: selectAllLabel,
	};

	const onBlurHandler = () => {
		setShowHiddenOptions(false);
	};

	const handleKeyDown = e => {
		const inputValue = e.target.value;
		if (e.key === 'Backspace' && !isClearable && !inputValue) {
			e.preventDefault();
		}
	};

	const customStyles = {
		menuPortal: base => ({
			...base,
			zIndex: 9999,
		}),
		placeholder: (provided, state) => ({
			...provided,
			fontSize: '16px',
		}),
	};

	if (!isClearable) {
		customStyles.multiValueRemove = (styles, { data }) => ({
			...styles,
			display: 'none',
		});
	}

	const onChangeHandler = (selected, event) => {
		if (selected !== null && selected.length > 0) {
			setShowHiddenOptions(false);
			if (selected[selected.length - 1].value === selectAllOption.value && event.action == 'select-option') {
				setSelectedOptions([selectAllOption, ...options]);
				return onChange([selectAllOption, ...options]);
			}
			let result = [];
			if (selected.length === options.length) {
				if (
					selected.filter(
						selectItem =>
							selectItem.value === selectAllOption.value,
					).length > 0
				) {
					result = selected.filter(
						option => option.value !== selectAllOption.value,
					);
				} else if (event.action === 'select-option') {
					result = [selectAllOption, ...options];
				}
				setSelectedOptions(result);
				return onChange(result);
			}
			setSelectedOptions(selected);
			return onChange(selected);
		}
		setSelectedOptions([]);
		return onChange([]);
	};

	useEffect(() => {
		if (menuIsOpen) {
			const handleClickOutside = e => {
				if (
					selectRef.current &&
					!selectRef.current.contains(e.target)
				) {
					setMenuIsOpen(false);
				}
			};

			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}
	}, [menuIsOpen]);

	return (
		<div className='ddl-multiselect-wrapper' ref={selectRef}>
			{label && (
				<FormLabel>
					{label}
					{isRequired && <RequiredWraper>Required</RequiredWraper>}
				</FormLabel>
			)}
			<Select
				defaultValue={defaultValue}
				value={values}
				className={`multi-select ${error ? ' is-error' : ''}`}
				placeholder={placeholder}
				options={
					allowSelectAll ? [selectAllOption, ...options] : options
				}
				isClearable={isClearable}
				isMulti
				menuIsOpen={menuIsOpen}
				onMenuOpen={() => setMenuIsOpen(true)}
				onMenuClose={() => setMenuIsOpen(false)}
				visibleOptions={visibleOptions}
				showHiddenOptions={showHiddenOptions}
				setShowHiddenOptions={setShowHiddenOptions}
				isDisabled={disabled}
				selectAllLabel={selectAllLabel}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				components={{
					Option,
					MultiValue,
					ValueContainer: CustomValueContainer,
					animatedComponents,
				}}
				onChange={allowSelectAll ? onChangeHandler : onChange}
				onBlur={onBlurHandler}
				menuPortalTarget={document.body}
				styles={customStyles}
				selectAllChecked={selectAllChecked}
				onKeyDown={e => handleKeyDown(e)}
			/>

			{showRequiredText && (
				<p className="text-danger">*Site is required</p>
			)}
		</div>
	);
};

export { DropdownMultiSelect };
