import React from 'react';
import { Button } from 'componentsV2';
import { DELETE_SHAPE_MODAL } from '../consts';
const renameOption = type => (
	<div className={`renameOption-${type} contextMenuOption`}>
		<Button
			text="Rename"
			icon="style"
			iconColor="gray-dark"
			type="ghostDefault"
			className="actionButtonElement"
			onClick={e => {}}
		/>
	</div>
);

const deleteOption = (type, setModalToShow) => (
	<div className={`deleteOption-${type} contextMenuOption`}>
		<Button
			text="Delete"
			icon="trash"
			iconColor="gray-dark"
			type="ghostDefault"
			className="actionButtonElement"
			onClick={() => {
				setModalToShow(DELETE_SHAPE_MODAL);
			}}
		/>
	</div>
);

const copyOption = type => (
	<div className={`copyOption-${type} contextMenuOption`}>
		<Button
			text="Copy"
			icon="duplicate"
			iconColor="gray-dark"
			type="ghostDefault"
			className="actionButtonElement"
			onClick={e => {}}
		/>
	</div>
);

export const RightClickMenu = ({ type, setModalToShow }) => {
	return (
		<>
			<div id={`context-menu-${type}`} className="contextMenu">
				{type == 'text' && (
					<>
						{renameOption(type)}
						{deleteOption(type, setModalToShow)}
					</>
				)}

				{type == 'area' && (
					<>
						{copyOption(type)}
						{deleteOption(type, setModalToShow)}
					</>
				)}
			</div>
		</>
	);
};
