import React, { useCallback, useEffect, useState } from 'react';
import { LoadingSpinner } from 'componentsV2';
import { MapElementCard, ShowHideAllIcon, TabPanel } from '../SidebarComponents';
import { NoIconAddedState, NoIconFoundState } from './';

export const InUsePanel = ({
	loading,
	editPermission,
	floorplanArchived,
	iconDetails,
	dispatchIconDetails,
	onToggleShape,
	onDragItem,
	onShowHideAllHandler,
}) => {
	const [inUseList, setInUseList] = useState(iconDetails.inUseIconsList);

	useEffect(() => {
		onSearchHandler(iconDetails.inUseSearchKey);
	}, [iconDetails.inUseIconsList]);

	const onSearchHandler = search => {
		const filteredIcons = iconDetails.inUseIconsList.filter(iconItem =>
			iconItem.title?.toLowerCase().includes(search.toLowerCase()),
		);
		setInUseList(filteredIcons);
		dispatchIconDetails({
			payload: {
				...iconDetails,
				inUseSearchKey: search,
			},
		});
	};

	if (loading) return <LoadingSpinner />;
	return (
		<>
			{iconDetails.noDataAdded && <NoIconAddedState editPermission={editPermission} />}
			{!iconDetails.noDataAdded && (
				<TabPanel
					description="The icons listed below are icons already in use for this map layer.  Drag an icon from the list to add to the map layer. Right click to edit the icon."
					onSearchHandler={onSearchHandler}
					onAddClickHandler={() => {}}
					addBtnTitle=""
					hasSearch={true}
					showAddBtn={false}
					searchValue={iconDetails.inUseSearchKey}
				>
					{inUseList.length === 0 && <NoIconFoundState />}
					{!!inUseList.length && (
						<>
							<ShowHideAllIcon
								showAll={iconDetails.hideAll}
								onClick={() => onShowHideAllHandler(!iconDetails.hideAll)}
							/>
							{inUseList.map(iconShape => (
								<MapElementCard
									onShowHideClick={() =>
										onToggleShape(iconShape, !iconShape.visible)
									}
									key={iconShape.id}
									title={`${iconShape.title} [${iconShape.tagsCounter}]`}
									isSelected={iconShape.visible}
									isHidden={!iconShape.visible}
									icon={<img className="inUseImg" src={iconShape.iconURL} />}
									onDrag={e => onDragItem(e, iconShape)}
									editPermission={editPermission}
									floorplanArchived={floorplanArchived}
								/>
							))}
						</>
					)}
				</TabPanel>
			)}
		</>
	);
};
