import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { formatDate } from 'utilities/dates';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import {
	Button,
	BootstrapedTable,
	sortingStyle,
	TableCell,
	Badge,
	ContentSwitcher,
	FeaturedIcon,
	ToasterContext,
	Intent,
	ConfirmationModal,
} from 'componentsV2';
import './TemplatesTable.scss';
import { AssessTemplatesService } from 'services';
import { TemplatesTableActions } from './TemplatesTableActions';
import { DeleteDrillTypeModal } from '../../../../DrillsV2/components/Settings/StateRequirements/components/DeleteDrillTypeModal';
import { CopyTemplateModal } from './CopyTemplateModal';

export const TemplatesTable = ({
	templatesData,
	isCoreSite,
	getTemplateList,
	setModalToShow,
	history,
}) => {
	const [filteredData, setFilteredData] = useState(templatesData);
	const [templatesModalOpen, setTemplatesModalOpen] = useState('');
	const [modalInputValues, setModalInputValues] = useState({});
	const toaster = useContext(ToasterContext);
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('site_assess_edit', permissions);
	const isSysAdmin = permissions?.isSysAdmin;
	const [modalLoading, setModalLoading] = useState(false);

	const TemplatesTabs = [
		{ id: 'all', label: 'View all' },
		{ id: 'published', label: 'Published' },
		{ id: 'not_published', label: 'Not published' },
	];

	const handleCloseModal = () => {
		setTemplatesModalOpen('');
		setModalInputValues({});
	};

	const handleShowModal = (templatesModalOpen, modalInputValues) => {
		setTemplatesModalOpen(templatesModalOpen);
		if (modalInputValues) setModalInputValues(modalInputValues);
	};

	const columns = [
		{
			dataField: 'id',
			hidden: true,
		},
		{
			dataField: 'name',
			text: 'Template title',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column),
			sortFunc: (a, b, order) => {
				if (order === 'desc') return a.localeCompare(b);
				return b.localeCompare(a);
			},
			formatter: (value, row) =>
				isCoreSite ? (
					<TableCell
						supportText={row?.isProprietary ? 'Navigate360' : 'Custom'}
						text={value}
					/>
				) : (
					<TableCell
						supportText={row?.isCoreTemplate ? 'Navigate360' : 'Custom'}
						text={value}
					/>
				),
		},
		{
			dataField: 'state',
			text: 'State',
			hidden: !isCoreSite,
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			sortFunc: (a, b, order) => {
				if (order === 'desc') return a.localeCompare(b);
				return b.localeCompare(a);
			},
			formatter: (value, row) => <TableCell text={value} />,
		},
		{
			dataField: 'published',
			text: 'Status',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (published, row) => (
				<div>
					<Badge
						key="sm-Gray"
						type={published ? 'Success' : 'Gray'}
						size="md"
						text={published ? 'Published' : 'Not Published'}
					/>
				</div>
			),
		},
		{
			dataField: 'createdDate',
			text: 'Date created',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			sortFunc: (a, b, order) => {
				if (order === 'desc') return a.localeCompare(b);
				return b.localeCompare(a);
			},
			formatter: (value, row) => formatDate(value),
		},
		{
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<TemplatesTableActions
					onShowModal={handleShowModal}
					history={history}
					row={row}
					isCoreSite={isCoreSite}
					editPermission={editPermission}
					isSysAdmin={isSysAdmin}
				/>
			),
		},
	];

	const onClickDeleteTemplate = () => {
		AssessTemplatesService.deleteTemplate(modalInputValues?.id)
			.then(() => {
				getTemplateList();
				toaster(
					`You have successfully ${
						modalInputValues?.isCoreTemplate ? 'removed' : 'deleted'
					} "${modalInputValues?.name}".`,
					Intent.NONE,
					<FeaturedIcon icon={modalInputValues?.isCoreTemplate ? 'info-sign':'trash'} size="md" type="Gray" />,
				);
			})
			.catch(() => {
				toaster(
					`There was an error ${
						modalInputValues?.isCoreTemplate ? 'removing' : 'deleting'
					} the assessment. Please try again.`,
					Intent.DANGER,
				);
			})
			.finally(() => {
				handleCloseModal();
			});
	};

	const onClickPublishUnpublishTemplate = () => {
		setModalLoading(true);
		AssessTemplatesService.publishUnpublishTemplate(
			modalInputValues?.id,
			!modalInputValues?.published,
		)
			.then(() => {
				getTemplateList();
				toaster(
					`You have successfully ${
						modalInputValues.published ? 'unpublished' : 'published'
					} ${modalInputValues?.name}.`,
					modalInputValues.published ? Intent.NONE : Intent.SUCCESS,
					<FeaturedIcon
						icon={modalInputValues?.published ? 'info-sign' : 'tick'}
						size="md"
						type={modalInputValues?.published ? 'Gray' : 'Success'}
					/>,
				);
			})
			.catch(() => {
				toaster(
					`There was an error ${
						modalInputValues.published ? 'unpublishing' : 'publishing'
					} the assessment. Please try again.`,
					Intent.DANGER,
				);
			})
			.finally(() => {
				handleCloseModal();
				setModalLoading(false);
			});
	};

	const onFilterTemplates = value => {
		if (value.length > 0 && value[0] !== 'all') {
			const filteredTemplates = templatesData.filter(template =>
				value[0] === 'published' ? template?.published : !template?.published,
			);
			setFilteredData(filteredTemplates);
		} else {
			setFilteredData(templatesData);
		}
	};
	return (
		<>
			<div className="templates-table">
				<div className="table-container">
					<div className="title-container">
						<span className="title-sub-container">
							{isCoreSite ? 'Core templates' : 'Templates'}
						</span>
						<span className="sub-text-container">
							{isCoreSite &&
								'Note that these are core templates. Please be careful when editing or deleting.'}
						</span>
					</div>
					{isCoreSite && isSysAdmin ? (
						<div>
							<Button
								icon="plus"
								text="Core template"
								type="primaryDefault"
								size="lg"
								onClick={() => {
									setModalToShow('createNewTemplateModal');
								}}
							/>
						</div>
					) : (
						<>
							{editPermission && (
								<div className="button-container">
									<Button
										icon="plus"
										text="Custom template"
										type="primaryDefault"
										size="lg"
										onClick={() => {
											setModalToShow('createNewTemplateModal');
										}}
									/>

									<Button
										icon="import"
										text="Import"
										type="secondaryDefault"
										size="lg"
										onClick={() => {
											setModalToShow('importTemplateModal');
										}}
									/>
								</div>
							)}
						</>
					)}
				</div>
				<div className="filter-container">
					<ContentSwitcher
						onClick={value => {
							onFilterTemplates(value);
						}}
						multi={false}
						tabs={TemplatesTabs}
						defaultActiveTabs={['all']}
					/>
				</div>
				<BootstrapedTable
					keyField="id"
					data={filteredData}
					columns={columns}
					remote={false}
					showPagination={isCoreSite}
				/>
			</div>
			{templatesModalOpen === 'delete_template' && (
				<DeleteDrillTypeModal
					isOpen
					titleData={modalInputValues?.name}
					onClickDelete={onClickDeleteTemplate}
					onCloseModal={handleCloseModal}
				/>
			)}
			{templatesModalOpen === 'copy_template' && (
				<CopyTemplateModal
					isOpen
					templateData={modalInputValues}
					onClickCopy={getTemplateList}
					onCloseModal={handleCloseModal}
				/>
			)}
			{templatesModalOpen === 'publish_unpublish_template' && (
				<ConfirmationModal
					isOpen
					loading={modalLoading}
					title={`Are you sure you want to ${
						modalInputValues.published ? 'unpublish' : 'publish'
					} ${modalInputValues.name}?`}
					subtitle={`You can ${
						modalInputValues.published ? 'publish' : 'unpublish'
					} it at a later time.`}
					showCloseButton={false}
					onClose={handleCloseModal}
					cancelBtn={{
						title: 'No, go back',
						onClick: handleCloseModal,
					}}
					confirmBtn={{
						title: modalInputValues.published ? 'Yes, unpublish it' : 'Yes, publish it',
						onClick: onClickPublishUnpublishTemplate,
						type: 'primaryDefault',
					}}
				/>
			)}
			{templatesModalOpen === 'remove_template' && (
				<DeleteDrillTypeModal
					isOpen
					titleText={`Are you sure you want to remove "${modalInputValues?.name}"?`}
					subtitleText="Once removed, this cannot be recovered."
					buttonText="Yes, remove it"
					onClickDelete={onClickDeleteTemplate}
					onCloseModal={handleCloseModal}
				/>
			)}
		</>
	);
};

TemplatesTable.propTypes = {
	templatesData: PropTypes.array,
	isCoreSite: PropTypes.bool,
	getTemplateList: PropTypes.func,
	setModalToShow: PropTypes.func,
};
