import React from 'react';
import PropTypes from 'prop-types';
import { flags } from 'utilities';
import { Tabs, Tab } from '@blueprintjs/core';
import { NavLink } from 'react-router-dom';
import { LoadingSpinner } from 'componentsV2';
import './css/index.scss';
import { PageHeader } from 'components/PageHeader';

const UserManagement = ({ defaultSelectedTab, loading, children }) => {
	const {
		isOn,
		names: { SITE_IS_FIRST_RESPONDER, SITE_IS_INTERNAL },
	} = flags;

	return (
		<div className="users-wrapper container-fluid">
			<div className="row">
				<div className="col-12">
					{isOn(SITE_IS_FIRST_RESPONDER) ? (
						<PageHeader
							title="User Management"
							subtitle="First responder agencies can add and manage users that will have access to school sites."
						/>
					) : (
						<>
							<PageHeader title="User Management" />
							<Tabs className="page-tabs" defaultSelectedTabId={defaultSelectedTab}>
								<Tab id="users">
									<NavLink exact to="/user-management">
										Users
									</NavLink>
								</Tab>
								{isOn(SITE_IS_INTERNAL) && (
									<Tab id="user-roles">
										<NavLink exact to="/user-roles">
											User Roles
										</NavLink>
									</Tab>
								)}
								<Tab id="first-responders">
									<NavLink exact to="/first-responders">
										First Responder
									</NavLink>
								</Tab>
							</Tabs>
						</>
					)}
				</div>
				<div className="col-12">
					{loading && <LoadingSpinner />}
					{!loading && children}
				</div>
			</div>
		</div>
	);
};

UserManagement.propTypes = {
	defaultSelectedTab: PropTypes.string,
	loading: PropTypes.bool,
	children: PropTypes.node,
};

export default UserManagement;
