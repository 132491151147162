import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
	Button,
	Dialog,
	Classes,
	TextArea,
	Intent,
	Callout,
} from '@blueprintjs/core';
import { Input } from 'components/Input';
import { LabelText } from 'components/Forms/shared.js';
import { LoadingSpinner } from 'components/LoadingSpinner';

const HrWrapper = styled.hr`
	widht: 90%;
`;

const CreateDialog = styled(Dialog)`
	width: 300px;
`;

const DialogFooterWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 10px;
`;

class EditSectionModal extends React.Component {
	constructor(props) {
		super(props);
		const { item } = this.props;
		this.state = {
			id: item.id,
			name: item.name,
			instructions: item.instructions,
			loading: false,
		};
	}

	updateFields = (key, val) => this.setState({ [key]: val });

	updateSection = () => {
		const { templateId, getData } = this.props;
		const { name, instructions, id } = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/templates/${templateId}/sections/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				name,
				instructions,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					return getData();
				}
				return null;
			});
	};

	render() {
		const { closeEdit } = this.props;
		const { name, instructions, loading } = this.state;
		return (
			<CreateDialog
				isOpen
				onClose={closeEdit}
				title="Edit Section"
				{...this.state}
			>
				<div className={Classes.DIALOG_BODY}>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<Callout intent="primary" className="mb-2">
								Directions will help users understand more
								information on how to answer this section. For
								example, “Directions: Please assess the
								following areas and answer each question by
								selecting: YES, NO, N/A (Not Applicable), or
								N/AS (Not Assessed) from the drop-down box.”
							</Callout>
							<Input
								placeholder="Main Section Title"
								label="Section"
								large
								onChange={e =>
									this.updateFields('name', e.target.value)
								}
								value={name}
							/>
							<LabelText className="mt-2">Directions</LabelText>
							<TextArea
								className="bp3-fill .modifier"
								growVertically={false}
								intent={Intent.NONE}
								placeholder="Enter directions"
								onChange={e =>
									this.updateFields(
										'instructions',
										e.target.value,
									)
								}
								value={instructions}
							/>
							<HrWrapper />
						</>
					)}
				</div>
				<DialogFooterWrapper>
					<Button
						style={{
							marginRight: 16,
							height: 40,
							width: 68,
						}}
						onClick={closeEdit}
						text="Cancel"
						intent="none"
					/>
					<Button
						style={{
							height: 40,
							width: 68,
							background: '#9F1C3A',
						}}
						onClick={this.updateSection}
						text="Save"
						intent="danger"
					/>
				</DialogFooterWrapper>
			</CreateDialog>
		);
	}
}

EditSectionModal.propTypes = {
	editTemplate: PropTypes.object,
	updateTemplatesArray: PropTypes.func,
	closeEdit: PropTypes.func,
	templateId: PropTypes.any,
	getData: PropTypes.func,
	item: PropTypes.object,
};

export default EditSectionModal;
