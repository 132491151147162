import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { Icon, Dialog } from '@blueprintjs/core';
import { Button } from 'components/Button';
import DownloadBaseFile from './downloadbasefile';

const Progress = styled.div`
    top:0;
    background: #CED9E0;
    height: 4px;
`;

const Bar = styled.div`
	height: 100%;
	width: ${props => props.width};
	background: #128c5a;
	margin-top: -1px;
`;

const BigIcon = styled.div`
	color: #5c7080;
	text-align: center;
	padding: 30px 0;
	svg {
		width: 48px;
		height: auto;
		margin-bottom: 10px;
	}
	p {
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		color: #394b59;
	}
`;

const LightIcon = styled(BigIcon)`
	color: #d8e1e8;
`;

const FileName = styled.p`
	font-family: Merriweather;
	font-weight: bold !important;
	font-size: 18px !important;
	line-height: 21px;
	color: #394b59 !important;
`;

const FileSize = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #394b59;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	color: #394b59;
	margin-top: 36px;
`;

const Subtitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #394b59;
	margin-bottom: 25px;
`;

const Section = styled.section`
	border: 1px dashed #d8e1e8;
`;

const SmallTitle = styled.p`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
`;

class Reupload extends React.Component {
	state = {
		step: 1,
	};

	getWidth = () => {
		const {valueFile} = this.props;
		return valueFile?.name ? `100%` : `50%`;
	};

	changeStep = () => this.setState({ step: 2 });

	render() {
		const {
			valueFile,
			closeUploadModal,
			uploadFile,
			sectionIndex,
			subsectionIndex,
			baseFile,
		} = this.props;
		const { step } = this.state;
		if (step === 1)
			return (
				<DownloadBaseFile
					baseFile={baseFile}
					changeStep={this.changeStep}
				/>
			);
		return (
			<div>
        <Dialog isOpen={true} style={{width:"100%", height:"70%"}}>
				<Progress>
					<Bar width={this.getWidth()} />
				</Progress>
				<div className="container">
					<div className="row justify-content-around mt-3">
						<div className="col-6">
							<Title>Upload your document</Title>
							<Subtitle>
								Upload your desired document in order to
								appear in your School Emergency Operations Plan.
							</Subtitle>
							<SmallTitle>Upload your document</SmallTitle>
							<Dropzone
								onDrop={file =>
									uploadFile(
										file,
										sectionIndex,
										subsectionIndex,
									)
								}
							>
								{({ getRootProps, getInputProps }) => (
									<Section className="container">
										<div
											{...getRootProps({
												className: 'dropzone',
											})}
										>
											<input {...getInputProps()} />
											{!valueFile?.name && (
												<LightIcon>
													<Icon icon="document" />
													<p>
														Drag and drop a file
														here, or{' '}
														<a
															href="#"
															onClick={e =>
																e.preventDefault()
															}
														>
															browse
														</a>
													</p>
												</LightIcon>
											)}
											{valueFile?.name && (
												<BigIcon>
													<Icon icon="document" />
													<FileName>
														{valueFile.name}
													</FileName>
													<FileSize>
														{(
															valueFile.size /
															1000000
														).toFixed(2)}{' '}
														MB
													</FileSize>
												</BigIcon>
											)}
										</div>
									</Section>
								)}
							</Dropzone>
							<div className="d-flex justify-content-end mt-3">
								<Button
									text={
										valueFile?.name
											? 'Save and Continue'
											: 'Close'
									}
									intent="primary"
									onClick={closeUploadModal}
								/>
							</div>
						</div>
					</div>
				</div>
        </Dialog>
			</div>
		);
	}
}

Reupload.propTypes = {
	valueFile: PropTypes.object,
	baseFile: PropTypes.object,
	closeUploadModal: PropTypes.func,
	templateId: PropTypes.string,
	dispatch: PropTypes.func,
	sectionIndex: PropTypes.number,
	subsectionIndex: PropTypes.number,
	uploadFile: PropTypes.func,
};

export default Reupload;
