import React from 'react';
import { ConfirmationModal } from 'componentsV2';

export const DeleteCameraLinkModal = ({ loading, closeModal, deleteCameraLinkHandler }) => {
	return (
		<ConfirmationModal
			isOpen={true}
			title=" Are you sure you want to delete this camera address link"
			subtitle="Once deleted, this cannot be recovered."
			showCloseButton={false}
			customClassName={'map-container-modals'}
			cancelBtn={{
				title: 'No, go back',
				onClick: closeModal,
			}}
			confirmBtn={{
				title: 'Yes, delete it',
				onClick: deleteCameraLinkHandler,
				type: 'primaryDanger',
			}}
			loading={loading}
			btnsSize="lg"
			icon={{
				icon: 'trash',
				iconColor: 'error',
			}}
		/>
	);
};
