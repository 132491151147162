export const countdownValidation = formData => {
	let isValid = true;
	const errors = {};

	if (formData.countdown === null) {
		isValid = false;
		errors.countdown = 'Countdown is required';
	}

	return {
		isValid,
		errors,
	};
};
