import { useState, useEffect } from "react";
import { DocumentService } from "services";
import { Intent } from "componentsV2";
import { Helper } from 'utilities/helper';
import { TYPE_360IMAGE, TYPE_DOCUMENT } from "pages/Documents";

export const DeleteModalFn = (
    modalName,
    setLoading,
    documentData,
    dispatchDocumentData,
    setModalToShow,
    closeModal,
    getDocumentsList,
    toaster,
) => {

    const [multiDocumentsFinished, setMultiDocumentsFinished] = useState(false);
    const [multi360ImagesFinished, setMulti360ImagesFinished] = useState(false);
    const [multiDeleteError, setMultiDeleteError] = useState(false);

    useEffect(() => {
        if (multiDocumentsFinished && multi360ImagesFinished) {
            if (multiDeleteError) {
                closeModal();
                getDocumentsList();
            } else {
                response_ok();
            }
        }
    }, [multiDocumentsFinished, multi360ImagesFinished]);

    const showDeleteModal = (document) => {
        setLoading(true);
        setModalToShow(modalName);
        dispatchDocumentData({
            ...documentData,
            id: document.id,
            type: document.type == TYPE_360IMAGE ? TYPE_360IMAGE : TYPE_DOCUMENT,
            title: `“${document.title}”`,
            modalTitle: `Are you sure you want to delete “${document.title}”?`,
            confirmBtnTitle: `Yes, delete it`,
        });
        setLoading(false);
    }

    const showMultipleDeleteModal = (selectedDocuments) => {
        setModalToShow(modalName);
        setLoading(true);

        let itemsSelected = selectedDocuments.length == 1
            ? `“${selectedDocuments[0].title}”`
            : `${selectedDocuments.length} documents`;

        let ids_360image = [];
        let ids_document = [];

        selectedDocuments.forEach(document => {
            if (document.type == TYPE_360IMAGE) {
                ids_360image.push(document.id);
            } else {
                ids_document.push(document.id);
            }
        });

        dispatchDocumentData({
            ...documentData,
            id: null,
            title: itemsSelected,
            modalTitle: `Are you sure you want to delete ${itemsSelected}?`,
            confirmBtnTitle: `Yes, delete ${selectedDocuments.length == 1 ? "it" : "them"}`,

            bulkIds: {
                ids_360image,
                ids_document
            }
        });
        setLoading(false);
    }

    const response_ok = () => {
        toaster(`You have successfully deleted ${documentData.title}`, Intent.SUCCESS);
        closeModal();
        getDocumentsList();
    }

    const response_error = (type, error) => {
        let errorMessage = error.error.description ? error.error.description : "";
        if (type == TYPE_DOCUMENT) {
            errorMessage = error.error.fields?.documents_ids ? error.error.fields.documents_ids : errorMessage;
        }
        if (type == TYPE_360IMAGE) {
            errorMessage = error.error.fields?.ids ? error.error.fields.ids : errorMessage;
        }
        toaster(Helper.capitalizeFirstLetter(errorMessage), Intent.DANGER);
        closeModal();
    }

    const handleDeleteSubmit = () => {
        setLoading(true);
        if (documentData.type == TYPE_DOCUMENT) {
            DocumentService.delete([documentData.id])
                .then(response_ok)
                .catch((error) => { response_error(documentData.type, error) })
                .finally(() => { setLoading(false); });
        }

        if (documentData.type == TYPE_360IMAGE) {
            DocumentService.delete360Image([documentData.id])
                .then(response_ok)
                .catch((error) => { response_error(documentData.type, error) })
                .finally(() => { setLoading(false); });
        }
    }

    const handleMultipleDeleteSubmit = () => {
        setLoading(true);

        setMultiDocumentsFinished(false);
        setMulti360ImagesFinished(false);
        setMultiDeleteError(false);

        // Delete documents
        if (documentData.bulkIds?.ids_document?.length > 0) {
            DocumentService.delete(documentData.bulkIds.ids_document)
                .then(() => {
                    setMultiDocumentsFinished(true);
                })
                .catch((error) => { 
                    response_error(TYPE_DOCUMENT, error) ;
                    setMultiDeleteError(true);
                    setMultiDocumentsFinished(true);
                })
                .finally(() => { setLoading(false); });
        } else {
            setMultiDocumentsFinished(true);
        }

        // Delete 360 images
        if (documentData.bulkIds?.ids_360image?.length > 0) {
            DocumentService.delete360Image(documentData.bulkIds.ids_360image)
                .then(() => {
                    setMulti360ImagesFinished(true);
                })
                .catch((error) => { 
                    response_error(TYPE_360IMAGE, error) ;
                    setMultiDeleteError(true);
                    setMulti360ImagesFinished(true);
                })
                .finally(() => { setLoading(false); });
        } else {
            setMulti360ImagesFinished(true);
        }
    }

    return {
        showDeleteModal,
        showMultipleDeleteModal,
        handleDeleteSubmit,
        handleMultipleDeleteSubmit,
    }
}    