import React from 'react';
import { flags } from 'utilities';
import { Home } from './Home';
import { FirstResponder } from '../FirstResponder/FirstResponder';

export const HomeCheckSiteInternal = (props) => {
	const {
		isOn,
		names: { SITE_IS_INTERNAL, SITE_IS_FIRST_RESPONDER },
	} = flags;

	if (isOn(SITE_IS_INTERNAL)) {
		return <Home {...props} />;
	}

	if (isOn(SITE_IS_FIRST_RESPONDER)) {
		return <FirstResponder {...props} />;
	}

	const isLoggedIn = JSON.parse(localStorage.getItem('user'));

	if (isLoggedIn) {
		return <Home {...props} />;
	}
	return <></>;
};
