import React from 'react';
import { FeaturedIcon } from 'componentsV2';

export const StepsBarItem = ({
    item,
    itemWidth = 100,
}) => {
    return (
        <div style={{
            width: `${itemWidth}%`
        }} className={`steps-bar-item-container item-${item.passed ? "passed" : "not-passed"} item-${item.active ? "active" : "inactive"}`}>
            <div className="steps-bar-item-indicator">
                <div className="steps-bar-item-line"></div>
                <FeaturedIcon
                    icon={item.icon ? item.icon : "symbol-circle"}
                    type={item.passed ? "OutlinePrimary" : "OutlineGray"}
                    size="xsm"
                    shape="circleIcon"
                />
                <div className="steps-bar-item-line"></div>
            </div>
            <div className="steps-bar-item-text-container">
                <div className="steps-bar-item-title">
                    {item.title}
                </div>
                {item.subtitle && (
                    <div className="steps-bar-item-subtitle">
                        {item.subtitle}
                    </div>
                )}
            </div>
        </div>
    )
}
