import React from "react";
import {TextArea}  from 'componentsV2/TextArea/TextArea.jsx';

export const TextAreaPattern = ({
	label = 'Test Label',
	placeholder = 'Enter a value',
	value = null,
	disabled = false,
	error = false,
	errorMessage = null,
	hasHintText=false,
	hintText=null,
	isRequired = false,
    onChange = null,
    labelState = true
}) => {
    return (
		<>
		<TextArea 
			label="Default Optional"
			placeholder={placeholder}
			isRequired={isRequired}
			error={error}
			errorMessage={errorMessage}
			hasHintText={hasHintText}
    		hintText = {hintText}
            disabled = {disabled}
            value={value}
			width={"50%"}
            onChange={() => {}}
            labelState={labelState}
		/>
        <br/>
        <TextArea 
			label="Default Required label"
			placeholder={placeholder}
			isRequired={true}
			error={error}
			errorMessage={errorMessage}
			hasHintText={hasHintText}
    		hintText = {hintText}
            disabled = {disabled}
            value={value}
			width={"350px"}
            onChange={() => {}}
            labelState={labelState}
		/>
        <br/>

        <TextArea 
			label="Error Example"
			placeholder={placeholder}
			isRequired={true}
			error={true}
			errorMessage="This is test error msg"
			hasHintText={hasHintText}
    		hintText = {hintText}
            disabled = {disabled}
            value={value}
			width={"100%"}
            onChange={() => {}}
            labelState={labelState}
		/>
        <br/>

        <TextArea 
			label="Disabled"
			placeholder={placeholder}
			isRequired={isRequired}
			error={false}
			errorMessage={errorMessage}
            hasHintText={true}
    		hintText = "Help User Message Hint"
            disabled = {true}
            value={value}
            onChange={() => {}}
            labelState={false}
		/>
         <br/>

					
		</>
	);

}