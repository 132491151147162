import React, { useContext } from 'react';
import { Button } from 'componentsV2';
import { RapidAlarmService } from 'services';
import { CreateAlarmTypeContext } from '../../../../CreateAlarmType/provider';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { LOADING_MODAL } from '../../../../CreateAlarmType/consts';

export const SaveAlarmAction = () => {
	const {
		formData,
		getCreateAlarmParams,
		allRequiredFieldsAreFilled,
		presetFieldsAreFilled,
		openModal,
		closeModal,
		formHasChanged,
		updateFormChange,
	} = useContext(CreateAlarmTypeContext);
	const toaster = useContext(ToasterContext);

	const onClickHandler = () => {
		openModal(LOADING_MODAL);
		const params = getCreateAlarmParams();
		RapidAlarmService.updateAlarmType(formData.id, params)
			.then(response => {
				if (response.statusCode === 200) {
					toaster(`You have successfully updated "${formData.name}".`, Intent.SUCCESS);
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				closeModal();
				updateFormChange(false);
			});
	};

	const isEnabled =
		formData.preset == true
			? presetFieldsAreFilled() && formHasChanged
			: allRequiredFieldsAreFilled() && formHasChanged;

	return <Button text="Save" disabled={!isEnabled} onClick={onClickHandler} />;
};
