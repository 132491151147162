import { HttpService } from 'networking/HttpService';
import { deserializeSites } from 'utilities/drills/deserializers';

export const BuildingsService = {
	async getAll(
		includeAllBuildingsOption,
		includeDisabledSites = false,
		enabledSitesModule = null,
	) {
		try {
			// In the backend 'active' will always be true unless it's explicitly stated to be false, so I don't understand the point of this if statement. See: ListBuildingsAction.php
			if (includeDisabledSites) {
				var params = `includeDistrict=true&active=true`;
			} else {
				var params = `includeDistrict=true`;
			}
			if (enabledSitesModule) {
				params += `&enabled_module=${enabledSitesModule}`;
			}
			const response = await HttpService({
				method: 'GET',
				route: `/buildings?${params}`,
			});
			const buildings = deserializeSites(response.data);
			if (includeAllBuildingsOption) {
				return [{ value: 'all', label: 'All Sites' }, ...buildings];
			}
			return buildings;
		} catch (error) {
			throw error;
		}
	},

	/** This doesn't take into account any permissions. It simply returns all active buildings. Which is what we want for site info
	 */
	getActiveBuildings: async () => {
		const response = await HttpService({
			method: 'GET',
			route: `/buildings-active`,
		});

		return deserializeSites(response.data);
	},

	async findOne(buildingId) {
		try {
			const response = await HttpService({
				method: 'GET',
				route: `/buildings/${buildingId}`,
			});
			const building = response.data;
			if (building) {
				return building;
			}
			return null;
		} catch (error) {
			throw error;
		}
	},

	async getAllowed() {
		try {
			const response = await HttpService({
				method: 'GET',
				route: `/buildings-allowed`,
			});
			const buildings = deserializeSites(response.data);
			return buildings;
		} catch (error) {
			throw error;
		}
	},

	async getUserAssignedBuildings(userId) {
		try {
			const response = await HttpService({
				method: 'GET',
				route: `/users/${userId}/buildings`,
			});
			// response.data is an array but it doesn't always start with index 0,
			// so i need to convert to a standard array
			const tempArray = Object.values(response.data);
			const buildings = deserializeSites(tempArray);
			return buildings;
		} catch (error) {
			throw error;
		}
	},

	async getSiteInfo(buildingId) {
		try {
			const response = await HttpService({
				method: 'GET',
				route: `/buildings/${buildingId}/site-info`,
			});

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	async getSiteKeyPeople(buildingId) {
		try {
			const response = await HttpService({
				method: 'GET',
				route: `/buildings/${buildingId}/site-key-people`,
			});

			return response.data;
		} catch (error) {
			throw error;
		}
	},

	async setSiteInfo(body) {
		return await HttpService({
			method: 'PUT',
			route: `/buildings/site-info`,
			body,
		});
	},
};
