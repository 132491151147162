import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { TextArea } from 'components/TextArea';
import { Dialog, Icon, MenuItem } from '@blueprintjs/core';
import { LabelText } from 'components/Forms/shared.js';
import { Select } from '@blueprintjs/select';
import { differenceInCalendarDays } from 'date-fns';
import Swal from 'sweetalert2';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

const DropdownWrap = styled.div`
	padding: 10px;
	width: 100%;
	background: #ffffff;
	border: 1px solid #d8e1e8;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
`;

const SelectWrap = styled(Select)`
	display: block;
	width: 100%;
	.bp3-popover-target {
		display: block;
		width: 100%;
	}
`;

/* 

       Complete
        Non complete

*/

const ValueTitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: #10161a;
	margin-bottom: 0px;
`;

const StyledTag = styled.div`
	background: #fcebeb;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #db3737;
	border-radius: 10px;
	padding: 2px 8px;
`;

const StatusText = styled.p`
	font-family: Nunito Sans;
	font-style: italic;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #738694;
	margin-bottom: 0px;
	margin-left: 6px;
`;

const dispositions = [
	{
		id: 1,
		value: 'Complete',
		label: 'Complete',
	},
	{
		id: 2,
		value: 'Non-Complete',
		label: 'Non Complete',
	},
];

class CloseTask extends React.Component {
	state = {
		taskDisposition: null,
		closeDisposition: '',
	};

	makeDispositions = item => (
		<MenuItem
			key={item.id}
			onClick={() => this.setState({ taskDisposition: item })}
			text={item.label}
		/>
	);

	updateQuesiton = () => {
		const { templateId, getData, sectionId } = this.props;
		const {
			question,
			hint,
			answerType,
			answerOptions,
			questionId,
		} = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${
				process.env.API_URL
			}/templates/${templateId}/questions/${questionId}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					title: question,
					hint,
					sectionId,
					properties: {
						answerType,
						answerOptions,
					},
				}),
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					return Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => getData());
				}
				return null;
			});
	};

	getStatus = () => {
		const { item } = this.props;
		const diffInDays = differenceInCalendarDays(
			new Date(item.due_date),
			new Date(),
		);

		if (diffInDays < 0) {
			return (
				<div className="d-flex align-items-center">
					<StyledTag>Overdue</StyledTag>
					<StatusText>
						{Math.abs(diffInDays)} days past due
					</StatusText>
				</div>
			);
		}
		return null;
	};

	submitCloseTask = () => {
		Swal.fire('Updating');
		Swal.showLoading();
		const { item, getData } = this.props;
		const { taskDisposition, closeDisposition } = this.state;

		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/tasks/${item.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				disposition: taskDisposition.value,
				closedNotes: closeDisposition,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					return Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => getData());
				}
				return null;
			});
	};

	render() {
		const { isOpen, closeCloseTask, item } = this.props;
		const { taskDisposition, closeDisposition } = this.state;

		return (
			<Dialog isOpen={isOpen} onClose={closeCloseTask}>
				<div>
					<TitleWrap>
						<Title>Close Task</Title>
						<Button
							text=""
							minimal
							icon="cross"
							onClick={closeCloseTask}
						/>
					</TitleWrap>
					<hr />
					<div className="px-2">
						<ValueTitle>Template</ValueTitle>
						<p>{item.assessment_name}</p>
						<ValueTitle>Section</ValueTitle>
						<p>{item.section_name}</p>
						<ValueTitle>Question</ValueTitle>
						<p>{item.question_text}</p>
						<ValueTitle>Task title</ValueTitle>
						<p>{item.title}</p>
						<ValueTitle>Status</ValueTitle>
						<p className="d-flex">{this.getStatus()}</p>
					</div>
					<div className="d-flex flex-column px-2">
						<LabelText>Close Description</LabelText>
						<SelectWrap
							items={dispositions}
							itemRenderer={this.makeDispositions}
							className="w-100"
							filterable={false}
						>
							<DropdownWrap>
								<div>
									{taskDisposition
										? taskDisposition.label
										: 'Select'}
								</div>
								<Icon icon="caret-down" />
							</DropdownWrap>
						</SelectWrap>
					</div>
					<div className="px-2">
						<TextArea
							label="Close Description"
							placeholder="Enter description"
							large
							width="100%"
							className="mb-2"
							value={closeDisposition}
							onChange={e =>
								this.setState({
									closeDisposition: e.target.value,
								})
							}
						/>
					</div>
					<hr />
					<div className="d-flex justify-content-end mt-2 px-2">
						<Button
							text="Cancel"
							large
							intent="secondary"
							onClick={closeCloseTask}
						/>
						<Button
							className="ml-2"
							text="Submit"
							large
							intent="primary"
							onClick={this.submitCloseTask}
						/>
					</div>
				</div>
			</Dialog>
		);
	}
}

CloseTask.propTypes = {
	isOpen: PropTypes.bool,
	closeCloseTask: PropTypes.func,
	templateId: PropTypes.any,
	sectionId: PropTypes.any,
	getData: PropTypes.func,
	item: PropTypes.object,
};

export default CloseTask;
