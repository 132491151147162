import { findIndex, cloneDeep } from 'lodash';
import { parsePHPDateTime } from '../dates';

const deserializeDrillLogsResumeByYear = buildingStatsBackend => {
	const typesAdded = [];
	const buildingStatsCompleted = [];
	const buildingStatsOverdue = [];
	const buildingStatsNotScheduled = [];
	buildingStatsBackend.forEach(buildingStat => {
		const objectToReturnCompleted = {
			name: buildingStat.name,
		};
		const objectToReturnOverdue = {
			name: buildingStat.name,
		};
		const objectToReturnNotScheduled = {
			name: buildingStat.name,
		};
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, value] of Object.entries(buildingStat.typeStats)) {
			objectToReturnCompleted[`${value.name}`] = value.completed;
			objectToReturnOverdue[`${value.name}`] = value.overdue;
			objectToReturnNotScheduled[`${value.name}`] = value.notScheduled;
			const found = findIndex(typesAdded, type => type.uuid === key);
			// If not found
			if (found === -1) {
				typesAdded.push(value);
			}
		}
		buildingStatsCompleted.push(objectToReturnCompleted);
		buildingStatsOverdue.push(objectToReturnOverdue);
		buildingStatsNotScheduled.push(objectToReturnNotScheduled);
	});

	return {
		completed: buildingStatsCompleted,
		overdue: buildingStatsOverdue,
		notScheduled: buildingStatsNotScheduled,
		typesAdded,
	};
};

const multiSeriesChartArray = [
	{
		name: 'July',
	},
	{
		name: 'August',
	},
	{
		name: 'September',
	},
	{
		name: 'October',
	},
	{
		name: 'November',
	},
	{
		name: 'December',
	},
	{
		name: 'January',
	},
	{
		name: 'February',
	},
	{
		name: 'March',
	},
	{
		name: 'April',
	},
	{
		name: 'May',
	},
	{
		name: 'June',
	},
];

const getYearValue = year => `${year} - ${year + 1}`;

const getMonthNameForIndex = index => {
	switch (index) {
		case 0:
			return 'July';
		case 1:
			return 'August';
		case 2:
			return 'September';
		case 3:
			return 'October';
		case 4:
			return 'November';
		case 5:
			return 'December';
		case 6:
			return 'January';
		case 7:
			return 'February';
		case 8:
			return 'March';
		case 9:
			return 'April';
		case 10:
			return 'May';
		case 11:
			return 'June';
		default:
			return '';
	}
};

export const deserializeYearlyTrends = backendData => {
	const years = [];
	const response = {
		completed: [],
		late: [],
	};
	for (
		let monthIndex = 0;
		monthIndex < multiSeriesChartArray.length;
		monthIndex += 1
	) {
		const objectCompleted = cloneDeep(multiSeriesChartArray[monthIndex]);
		const objectLate = cloneDeep(multiSeriesChartArray[monthIndex]);

		for (let index = 0; index < backendData.length; index += 1) {
			const { months, year } = backendData[index];
			// Initialization as an empty object
			objectCompleted[`'${getYearValue(year)}'`] =
				months[getMonthNameForIndex(monthIndex)].completedOnTime;
			objectLate[`'${getYearValue(year)}'`] =
				months[getMonthNameForIndex(monthIndex)].lateOrMissed;

			if (years.length < 3) {
				years.push(`'${getYearValue(year)}'`);
			}
		}

		response.completed.push(objectCompleted);
		response.late.push(objectLate);
	}
	return {
		completed: response.completed,
		late: response.late,
		years,
	};
};

export const deserializeRequiredDrillsByType = typeStats =>
	typeStats.map(drillTypeEntry => ({
		name: `${drillTypeEntry.name} ${drillTypeEntry.required} required`,
		Overdue: drillTypeEntry.overdue,
		Completed: drillTypeEntry.completed,
		'Not scheduled yet': drillTypeEntry.notScheduled,
	}));

export const deserializeOverview = backendData => {
	const { overview } = backendData;
	const { completed, total, notScheduled } = overview;
	const totalPlusNotScheduled = total + notScheduled;
	return {
		totalRequired: total,
		totalOverdue: overview.overdue,
		totalNotScheduled: notScheduled,
		totalCompleted: completed,
		totalCompletedPercentage:
			totalPlusNotScheduled > 0
				? Math.round((completed / totalPlusNotScheduled) * 100)
				: 0,
		mostOverdueType: overview.mostOverdueType,
		mostNotScheduledType: overview.mostNotScheduledType,
		mostCompletedType: overview.mostCompletedType,
		mostOverdueBuilding: overview.mostOverdueBuilding,
		mostNotScheduledBuilding: overview.mostNotScheduledBuilding,
		mostCompletedBuilding: overview.mostCompletedBuilding,
		byYearStats: deserializeDrillLogsResumeByYear(
			backendData.buildingStats,
		),
		byTypeStats: deserializeRequiredDrillsByType(backendData.typeStats),
	};
};

export const deserializeDrillsByStatus = backendData => {
	const typesAdded = [];
	const buildingStats = backendData.buildingStats.map(buildingStat => {
		const objectToReturn = {
			name: buildingStat.name,
		};
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, value] of Object.entries(buildingStat.typeStats)) {
			objectToReturn[`${value.name}`] = value.count;
			const found = findIndex(typesAdded, type => type.uuid === key);
			// If not found
			if (found === -1) {
				typesAdded.push(value);
			}
		}
		return objectToReturn;
	});

	let buildingDrills = [];
	if (backendData.buildingDrills) {
		buildingDrills = backendData.buildingDrills.map(buildingDrill => ({
			id: buildingDrill?.id,
			site: buildingDrill?.name,
			'drill type': '',
			'scheduled date': '',
			count: buildingDrill?.drills?.length || 0,
			drills: buildingDrill?.drills?.map(drill => ({
				id: drill.uuid,
				'drill type': drill.name,
				'scheduled date': parsePHPDateTime(
					drill.scheduledDate.date,
					drill.scheduledDate.timezone_type,
					drill.scheduledDate.timezone,
				),
				'actual date': parsePHPDateTime(
					drill.actualDate?.date,
					// eslint-disable-next-line camelcase
					drill.actualDate?.timezone_type,
					drill.actualDate?.timezone,
				),
			})),
		}));
	}

	let typeStats = [];
	if (backendData.typeStats) {
		typeStats = Object.values(backendData.typeStats).map(
			drillTypeEntry => ({
				name: drillTypeEntry.name,
				count: drillTypeEntry.count,
			}),
		);
	}

	return {
		types: typesAdded,
		buildingStats,
		buildingDrills,
		typeStats,
	};
};

export const randomColor = () => {
	let color = '#';
	for (let i = 0; i < 6; i += 1) {
		const random = Math.random();
		// eslint-disable-next-line no-bitwise
		const bit = (random * 16) | 0;
		color += bit.toString(16);
	}
	return color;
};
