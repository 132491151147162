import React from 'react';
import PropTypes from 'prop-types';
import '../css/TableCell.scss';

export const TableCell = ({
	text,
	supportText,
	leftIcon = null,
	rightIcon = null,
	fontSize = '',
	firstRow = false,
	className = '',
}) => (
	<div className="table-cell">
		<div className="cell-wrapper">
			{leftIcon && <div className="left-icon">{leftIcon}</div>}
			<div className="text-wrapper">
				<p className={'cell-text ' + fontSize + ' ' + (firstRow ? 'first-column' : '')}>
					{text}
				</p>
				<span className={className}>{supportText}</span>
			</div>
			{rightIcon && <div className="right-icon">{rightIcon}</div>}
		</div>
	</div>
);

TableCell.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	supportText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	leftIcon: PropTypes.element,
	rightIcon: PropTypes.element,
	firstRow: PropTypes.bool,
};
