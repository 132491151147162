import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import NormalModal from 'components/Modals/NormalModal';
import { ToasterContext } from 'pages/App';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { FormGroup, InputGroup, ControlGroup, Switch, Intent } from '@blueprintjs/core';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import { FormLabel, RequiredWraper } from 'components/Forms/shared.js';
import './css/AddEditRole.scss';
import { useSelector } from 'react-redux';
import { checkPermission } from '../../../utilities/permissions';
import { Helper } from '../../../utilities/helper';

const TableWrapper = styled.div``;
const TableContent = styled.table``;

export default function AddEditRoleModal(props) {
	const [name, setName] = useState('');
	const [sites, setSites] = useState([]);
	const [unsavedName, setUnsavedName] = useState('');
	const [unsavedSites, setUnsavedSites] = useState([]);
	const [permissionModulesViewChecked, setPermissionModulesViewChecked] = useState([]);
	const [permissionModulesEditChecked, setPermissionModulesEditChecked] = useState([]);
	const [unsavedPmodsViewChecked, setUnsavedPmodsViewChecked] = useState([]);
	const [unsavedPmodsEditChecked, setUnsavedPmodsEditChecked] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isAllViewChecked, setIsAllViewChecked] = useState(false);
	const [isAllEditChecked, setIsAllEditChecked] = useState(false);
	const [formTitle, setFormTitle] = useState('Save');
	const [modalTitle, setModalTitle] = useState('Create Role');
	const [roleId, setRoleId] = useState(props.roleId);
	const [roleAllSites, setRoleAllSites] = useState(false);
	const [roleEditData, setRoleEditData] = useState([]);
	const [resetButton, setResetButton] = useState(false);
	const [requiredSites, setRequiredSites] = useState(false);
	const [requiredName, setRequiredName] = useState(false);
	const [requiredModules, setRequiredModules] = useState(false);
	const [roleNameExists, setRoleNameExists] = useState('');
	const toaster = useContext(ToasterContext);
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	const [hasDisabledSites, setHasDisabledSites] = useState(false);
	const [allSitesList, setAllSitesList] = useState(props.sitesLoadedData);
	const [hasChanges, setHasChanges] = useState(false);
	const permissions = useSelector(state => state.route.permissions);

	const modulesIdsShow = [
		{ id: 16, title: 'Assess' },
		{ id: 4, title: 'Binders & Docs' },
		{ id: 2, title: 'Call List' },
		{ id: 6, title: 'Drill Logs' },
		{ id: 5, title: 'Flipcharts' },
		{ id: 3, title: 'Maps & Floorplans' },
		{ id: 8, title: 'Rapid Alarm' },
		{ id: 15, title: 'Reunification' },
		{ id: 7, title: 'Safety Plans' },
		{ id: 12, title: 'Site Information' },
		{ id: 9, title: 'User Management' },
	];

	const url = process.env.API_URL; //const url = "http://localhost:8086";
	const token = JSON.parse(localStorage.getItem('user')).jwt;

	useEffect(() => {
		setLoading(true);

		if (props.formType == 'edit') {
			getRole();
			setFormTitle('Update');
			setModalTitle('Edit Custom Role');
		} else {
			setDefaultSelectedSites();
			getPermissionModules();
		}
	}, []);

	function setDefaultSelectedSites() {
		if (allSitesList.length < 1) {
			return;
		}

		var selectedSites = Helper.getMultipleSelectedSitesOnLoad(props.selectedSites, permissions);

		if (selectedSites.length === 0 && allSitesList.length === 1) {
			selectedSites = _.clone(allSitesList);
		}

		if (selectedSites.length == allSitesList.length) {
			selectedSites.unshift({
				value: '*',
				label: 'All Sites',
			});

			setRoleAllSites(true);
		}

		setSites(selectedSites);
	}

	function getPermissionModules() {
		setIsAllViewChecked(false);
		setIsAllEditChecked(false);

		let viewChecked = [];
		let editChecked = [];
		modulesIdsShow.map(() => {
			viewChecked.push(false);
			editChecked.push(false);
		});

		setPermissionModulesViewChecked(viewChecked);
		setPermissionModulesEditChecked(editChecked);
		setUnsavedPmodsViewChecked(viewChecked);
		setUnsavedPmodsEditChecked(editChecked);
		setLoading(false);
	}

	function getRole() {
		fetch(`${url}/roles/details/${roleId}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				setRoleEditData(resp.data);
				setRoleFormEditData(resp.data);
			});
	}

	function setRoleFormEditData(roleData) {
		const permissionsViewChecked = [];
		const permissionsEditChecked = [];

		modulesIdsShow.map(function(item, inx) {
			if (roleData.permissions_saved[item.id] != undefined) {
				permissionsViewChecked[inx] = roleData.permissions_saved[item.id]['canView'];
				permissionsEditChecked[inx] = roleData.permissions_saved[item.id]['canEdit'];
			} else {
				permissionsViewChecked[inx] = false;
				permissionsEditChecked[inx] = false;
			}
		});

		setName(roleData.role.title);
		setUnsavedName(roleData.role.title);
		setRoleAllSites(roleData.role.all_sites);

		let disabledSites = [];
		roleData.assigned_users.map(assigned => {
			disabledSites.push(assigned.buildingId);
		});
		if (disabledSites.length > 0) {
			setHasDisabledSites(true);
		}

		let selectedSites = [];
		let allSites = [];
		const serviceBuildings = props.sitesLoadedData.map(site => ({ ...site }));
		// BuildingsService.getAll(false, false).then(serviceBuildings => {
		if (roleData.role.all_sites) {
			selectedSites.push({
				value: '*',
				label: 'All Sites',
			});
			serviceBuildings.map(serviceBuilding => {
				serviceBuilding.isDisabled = disabledSites.indexOf(serviceBuilding.value) != -1;
				selectedSites.push(serviceBuilding);
				allSites.push(serviceBuilding);
			});
			setAllSitesList(allSites);
		} else {
			serviceBuildings.map(serviceBuilding => {
				serviceBuilding.isDisabled = disabledSites.indexOf(serviceBuilding.value) != -1;
				allSites.push(serviceBuilding);
			});
			setAllSitesList(allSites);

			if (serviceBuildings.length == roleData.role_sites.length) {
				selectedSites.push({
					value: '*',
					label: 'All Sites',
				});
			}

			roleData.role_sites.map(roleSite => {
				selectedSites.push({
					value: roleSite.building_id,
					label: roleSite.building_name,
				});
			});
		}
		setSites(selectedSites);
		setLoading(false);
		// });

		setUnsavedSites(selectedSites);
		setPermissionModulesViewChecked(permissionsViewChecked);
		setPermissionModulesEditChecked(permissionsEditChecked);
		setUnsavedPmodsViewChecked(permissionsViewChecked);
		setUnsavedPmodsEditChecked(permissionsEditChecked);
		setIsAllViewChecked(getCheckedCount(permissionsViewChecked) == modulesIdsShow.length);
		setIsAllEditChecked(getCheckedCount(permissionsEditChecked) == modulesIdsShow.length);
	}

	function getCheckedCount(items) {
		return items.filter(chkValue => chkValue == true).length;
	}

	function saveFormRole() {
		setRequiredName(false);
		setRequiredSites(false);
		setRequiredModules(false);
		setRoleNameExists('');

		let validForm = true;
		if (name == '') {
			setRequiredName(true);
			validForm = false;
		}

		if (sites.length == 0) {
			setRequiredSites(true);
			validForm = false;
		}

		if (getCheckedCount(permissionModulesViewChecked) == 0) {
			setRequiredModules(true);
			validForm = false;
		}

		if (!validForm) {
			return false;
		}

		setLoading(true);

		fetch(`${url}/roles/existsname`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({ name: name, id: roleId }),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.data.exists) {
					setRoleNameExists(resp.data.message);
					setLoading(false);
				} else {
					if (props.formType == 'edit') {
						editRole();
					} else {
						addRole();
					}
				}
			});
	}

	function addRole() {
		var selectedSitesIds = [];
		selectedSitesIds = sites?.filter(site => {
			return site.value != '*';
		});
		const formData = {
			name: name,
			all_sites: roleAllSites,
			sites: selectedSitesIds,
			modules: modulesIdsShow,
			canView: permissionModulesViewChecked,
			canEdit: permissionModulesEditChecked,
		};

		fetch(`${url}/roles/add`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(formData),
		})
			.then(res => res.json())
			.then(resp => {
				toaster('Role successfully added.', Intent.SUCCESS);
				setLoading(false);
				props.updateDataCallBack();
				props.setModalToShow(false);
			});
	}

	function editRole() {
		var selectedSitesIds = [];
		selectedSitesIds = sites?.filter(site => {
			return site.value != '*';
		});

		const formData = {
			id: roleId,
			name: name,
			all_sites: roleAllSites,
			sites: selectedSitesIds,
			modules: modulesIdsShow,
			canView: permissionModulesViewChecked,
			canEdit: permissionModulesEditChecked,
		};

		fetch(`${url}/roles/edit`, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(formData),
		})
			.then(res => res.json())
			.then(resp => {
				toaster('Role successfully edited.', Intent.SUCCESS);
				props.updateDataCallBack();
				props.setModalToShow(false);
			});
	}

	const selectSitesHandler = sitesSelected => {
		setHasChanges(true);

		setRequiredSites(false);

		if (sitesSelected.length > 0) {
			setSites(sitesSelected);

			let allSiteChecked = false;
			for (const item of sitesSelected) {
				if (item.value === '*') {
					allSiteChecked = true;
				}
			}
			setRoleAllSites(allSiteChecked);
		} else {
			let sitesStillSelected = [];
			sites.map(siteNow => {
				if (siteNow.isDisabled) {
					sitesStillSelected.push(siteNow);
				}
			});
			setSites(sitesStillSelected);
			setRoleAllSites(false);
		}
	};

	function checkAllView(checkValue) {
		setHasChanges(true);

		setRequiredModules(false);
		setIsAllViewChecked(checkValue);
		//set all EDIT to FALSE if case
		if (checkValue == false && isAllEditChecked == true) {
			checkAllEdit(checkValue);
		}
		// check all children
		const newAllPerms = [];
		modulesIdsShow.map((item, inx) => {
			newAllPerms.push(checkValue);
		});

		setPermissionModulesViewChecked(newAllPerms);
	}

	function checkAllEdit(checkValue) {
		setHasChanges(true);

		setRequiredModules(false);
		setIsAllEditChecked(checkValue);
		//set all VIEW to TRUE if case
		if (checkValue == true && isAllViewChecked == false) {
			checkAllView(checkValue);
		}
		//check all children
		const newAllPerms = [];
		modulesIdsShow.map((item, inx) => {
			newAllPerms.push(checkValue);
		});
		setPermissionModulesEditChecked(newAllPerms);
	}

	function handleModuleViewCheck(elemVal, indexCheck) {
		setHasChanges(true);

		setRequiredModules(false);
		let items = [...permissionModulesViewChecked];
		items[indexCheck] = elemVal;

		setPermissionModulesViewChecked(items);

		if (elemVal == false && permissionModulesEditChecked[indexCheck] == true) {
			handleModuleEditCheck(elemVal, indexCheck);
		}

		if (getCheckedCount(items) == modulesIdsShow.length && !isAllViewChecked) {
			setIsAllViewChecked(true);
		}

		if (getCheckedCount(items) != modulesIdsShow.length && isAllViewChecked) {
			setIsAllViewChecked(false);
			setIsAllEditChecked(false);
		}
	}

	function handleModuleEditCheck(elemVal, indexCheck) {
		setHasChanges(true);

		setRequiredModules(false);
		let items = [...permissionModulesEditChecked];
		items[indexCheck] = elemVal;

		setPermissionModulesEditChecked(items);

		if (elemVal == true && permissionModulesViewChecked[indexCheck] == false) {
			handleModuleViewCheck(elemVal, indexCheck);
		}

		if (getCheckedCount(items) == modulesIdsShow.length && !isAllEditChecked) {
			setIsAllEditChecked(true);
			setIsAllEditChecked(true);
		}

		if (getCheckedCount(items) != modulesIdsShow.length && isAllEditChecked) {
			setIsAllEditChecked(false);
		}
	}

	function closeModalForm() {
		if (hasChanges) {
			setShowUnsavedChangesModal(true);
			return false;
		}

		props.setModalToShow(null);
	}

	const handleConfirmAction = () => {
		if (resetButton) {
			if (props.formType == 'edit') {
				setRoleFormEditData(roleEditData);
			} else {
				getPermissionModules();
				setName('');
				setSites([]);
			}
			setResetButton(false);
		} else {
			props.setModalToShow(null);
		}
		setShowUnsavedChangesModal(false);
	};

	const resetFormHandler = () => {
		setResetButton(true);

		if (hasChanges) {
			setShowUnsavedChangesModal(true);
			setHasChanges(false);
			return false;
		}
	};

	const handleNameChange = event => {
		setHasChanges(true);
		setName(event.target.value);
		setRequiredName(false);
		setRoleNameExists('');
	};

	return (
		<NormalModal
			setModalToShow="roleForm"
			onConfirmAction={saveFormRole}
			onCancelAction={closeModalForm}
			header={modalTitle}
			confirmBtnTitle={formTitle}
			cancelBtnTitle="Cancel"
			loading={loading}
			className="role-card-modal"
			thirdButtonTitle="Reset"
			thirdButtonIcon="reset"
			thirdButtonAction={resetFormHandler}
			confirmBtnDisabled={
				name.trim() === '' ||
				sites.length === 0 ||
				getCheckedCount(permissionModulesViewChecked) == 0
			}
		>
			<ControlGroup fill={true} vertical={false} className="custom-controlgroup-class">
				<FormGroup className="form-group-title">
					<FormLabel>
						Role name <RequiredWraper>Required</RequiredWraper>
					</FormLabel>
					<InputGroup
						id="text-input"
						placeholder="Role name"
						value={name}
						onChange={handleNameChange}
					/>
					{requiredName && <p className="text-danger">* Role name is required</p>}
					{roleNameExists != '' && <p className="text-danger">{roleNameExists}</p>}
				</FormGroup>
				{!loading && (
					<>
						<FormGroup className="form-group-title">
							<FormLabel>
								Site <RequiredWraper>Required</RequiredWraper>
							</FormLabel>
							<SitesDropdownMultiSelect
								value={sites}
								label={null}
								isRequired={true}
								onChange={selectSitesHandler}
								visibleOptions={1}
								isClearable={!hasDisabledSites}
								sitesList={allSitesList}
								allowSelectAll={true}
							/>
							{requiredSites && <p className="text-danger">* Please select site</p>}
						</FormGroup>
					</>
				)}
			</ControlGroup>

			<>
				<h4>Choose Module Settings</h4>
			</>

			<TableWrapper className="table-wrapper-modules">
				<TableContent className="table role-permissions-table">
					<thead>
						<tr>
							<th className="title-column">
								<span>All Modules</span>
								&nbsp;
								{requiredModules && (
									<span className="text-danger">
										Check at least one permission
									</span>
								)}
							</th>
							<th>
								<Switch
									checked={isAllViewChecked}
									label="Can View"
									onChange={e => checkAllView(e.target.checked)}
									className="roles-form-switch-bp"
								/>
							</th>
							<th>
								<Switch
									checked={isAllEditChecked}
									label="Can Edit"
									onChange={e => checkAllEdit(e.target.checked)}
									className="roles-form-switch-bp"
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						{!loading &&
							modulesIdsShow.map((item, index) => (
								<tr key={index}>
									<td className="title-column">{item.title} </td>
									<td>
										<Switch
											checked={permissionModulesViewChecked[index]}
											onChange={e =>
												handleModuleViewCheck(e.target.checked, index)
											}
											className="roles-form-switch-bp"
											key={`${index}-v`}
										/>
									</td>
									<td>
										<Switch
											checked={permissionModulesEditChecked[index]}
											onChange={e =>
												handleModuleEditCheck(e.target.checked, index)
											}
											className="roles-form-switch-bp"
											key={`${index}-e`}
										/>
									</td>
								</tr>
							))}
					</tbody>
				</TableContent>
			</TableWrapper>

			{showUnsavedChangesModal && (
				<UnsavedChangesModal
					onConfirmAction={() => handleConfirmAction()}
					onCancelAction={() => {
						setShowUnsavedChangesModal(false);
					}}
				/>
			)}
		</NormalModal>
	);
}
