import React, { useState, useContext } from 'react';
import { Button, AlertModal, FeaturedIcon, ToasterContext, Intent } from 'componentsV2';
import { CallListService } from 'services/CallListService';

export const DeleteCallListModal = ({ handleCancel, callList, setReloadCallLists }) => {
	const [loading, setLoading] = useState(false);
	const toaster = useContext(ToasterContext);
	const handleDelete = () => {
		setLoading(true);
		CallListService.delete(callList.id).then(resp => {
			if (resp.statusCode === 200) {
				toaster(
					'You have successfully deleted this call list.',
					Intent.NONE,
					<FeaturedIcon icon="trash" size="md" type="Gray" />,
				);
				handleCancel();
				setReloadCallLists(oldValue => !oldValue);
			}
			setLoading(false);
		});
	};
	return (
		<AlertModal
			isOpen={true}
			onClose={handleCancel}
			loading={loading}
			size="medium"
			title={'Are you sure you want to delete “' + callList.title + '”?'}
			customClassName="map-container-modals"
			subtitle={
				'Deleting this call list will remove all related contacts. This action cannot be undone.'
			}
			showCloseButton={false}
			icon={'trash'}
			iconColor="error"
			alertConfirmButton={
				<Button text="Delete" type="primaryDanger" size="md" onClick={handleDelete} />
			}
			alertCancelButton={
				<Button text="Cancel" type="tertiary" size="md" onClick={handleCancel} />
			}
		/>
	);
};
