import { HttpService, buildRoute } from 'networking/HttpService';
import { deserializeCompleteRequirements } from '../utilities/drills/deserializers';

const GENERIC_ERROR = new Error('An error occurred, please try again');

const validateRequirementId = requirementId => {
	if (!Number.isInteger(requirementId) || requirementId < 0) {
		throw new Error(
			`'requirementId' needs to be a positive integer/number, not a ${typeof requirementId}`,
		);
	}
};

export const DrillDistrictRequirementsService = {
	async getAllRequirements(year, stateId) {
		const response = await HttpService({
			method: 'GET',
			route: `/drills/all-requirements?year=${year}&stateId=${stateId}`,
		});
		return deserializeCompleteRequirements(response.data);
	},
	async deleteRequirement(selectedItem) {
		return HttpService({
			method: 'DELETE',
			route: buildRoute('/drills/district-requirements/:selectedItem', {
				selectedItem,
			}),
		}).then(res => {
			if (res) {
				return res;
			}
			throw GENERIC_ERROR;
		});
	},
	async createRequirement(
		drillTypeUUID,
		year,
		requirement = null,
		fieldsUuids = null,
	) {
		return HttpService({
			method: 'POST',
			route: `/drills/district-requirements`,
			body: {
				drillTypeUUID,
				year,
				...(requirement && {
					requirement: requirement || 0,
				}),
				...(fieldsUuids && { fieldsUuids }),
			},
		});
	},
	async updateRequirements(
		requirementId,
		numberOfRequirements = null,
		updatedFields = null,
		active = null,
	) {
		validateRequirementId(requirementId);
		if (!numberOfRequirements && !updatedFields && active === null) {
			throw new Error(
				`At least one parameter numberOfRequirements, updatedFields or active needs to be present`,
			);
		}
		return HttpService({
			method: 'PATCH',
			route: buildRoute('/drills/district-requirements/:requirementId', {
				requirementId,
			}),
			body: {
				...(numberOfRequirements !== null && {
					requirement: numberOfRequirements,
				}),
				...(updatedFields !== null && { fieldUuids: updatedFields }),
				...(active !== null && { active }),
			},
		});
	},
	async fetchRequiredFields(requirementId) {
		validateRequirementId(requirementId);
		return HttpService({
			method: 'GET',
			route: buildRoute(
				'/drills/district-requirements/:requirementId/fields',
				{
					requirementId,
				},
			),
		}).then(res => {
			if (Array.isArray(res?.data)) {
				return res?.data;
			}
			throw GENERIC_ERROR;
		});
	},
	// buildingRequirements is an array containing object like this:
	// [{ buildingId: , requirement: 1}, ...]
	async updateBuildingRequirements(requirementId, buildingRequirements) {
		validateRequirementId(requirementId);
		return HttpService({
			method: 'PUT',
			route: buildRoute(
				'/drills/district-requirements/:requirementId/building-requirements',
				{
					requirementId,
				},
			),
			body: {
				buildingRequirements: buildingRequirements.map(buildingReq => ({
					// This could be an existing district requirement or a building
					// Existing requirements have a building prop, buildings have direct access to the id
					buildingId: buildingReq.building
						? buildingReq.building.id
						: buildingReq.id,
					requirement: buildingReq.requirement,
				})),
			},
		});
	},

	async getAllRequirementsByBuilding(buildingId, year) {
		const response = await HttpService({
			method: 'GET',
			route: `/buildings/${buildingId}/requirements?year=${year}`,
		});
		return response.data;
	},
};
