import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { formatDate } from 'utilities/dates';
import { validateTemplateSections } from './parsedatamodel';

const selectRouteDomain = state => state.route;

export default actions => store => {
	const ACTIONS_WITH_DELAY = [
		'UPDATE_SUBSECTION',
		'UPDATE_SECTION_TITLE',
		'UPDATE_SUBSECTION_TITLE',
		'UPDATE_SECTION_CONTENT',
	];
	const autoSave = () => {
		const token = selectRouteDomain(store.getState()).user;
		const data = selectRouteDomain(store.getState());
		// validate empty sections/subsections title
		let errorObj = { issue: null };
		if (
			typeof data !== 'undefined' &&
			typeof data.template !== 'undefined' &&
			typeof data.template.sections !== 'undefined'
		) {
			errorObj = validateTemplateSections(
				data.template.sections,
				data.template.is_template,
				null,
				data.template.name,
			);
		}
		if (!errorObj.issue) {
			store.dispatch({
				type: 'AUTO_SAVE_INIT',
				payload: true,
			});

			const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
			const tempBody = {
				name: data.template.name,
				headers: JSON.stringify(data.template.headers),
				content: JSON.stringify(data.template.sections),
				started: true,
				bonusFiles: JSON.stringify(data.template.bonusFiles) || [],
			};
			let url = `${process.env.API_URL}/district-templates/${parseInt(data.template.id, 10)}`;
			if (loggedUserData.primaryBuildingId === 6880 && data.template.is_template) {
				url = `${process.env.API_URL}/plan-templates/${parseInt(data.template.id, 10)}`;
				tempBody.state = data.template.state;
			}
			if (!!data.template.id) {
				fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'app/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						...tempBody,
					}),
				})
					.then(res => res.json())
					.then(rsp => {
						store.dispatch({
							type: 'AUTO_SAVE_TIME',
							payload: formatDate(
								rsp.data?.editedDate || rsp.data?.createdDate,
								DateTime.TIME_WITH_SHORT_OFFSET,
							),
							dateWithTimezone: rsp.data?.dateWithTimezone,
						});
					});
			}
		}
	};

	const autoSaveDebounced0 = debounce(autoSave, 0);
	const autoSaveDebounced2000 = debounce(autoSave, 2000);

	return next => action => {
		if (actions.includes(action.type)) {
			store.dispatch({
				type: 'AUTO_SAVE_INIT',
				payload: false,
			});
			if (ACTIONS_WITH_DELAY.includes(action.type)) {
				autoSaveDebounced2000();
			} else {
				autoSaveDebounced0();
			}
		}
		next(action);
	};
};
