import React from 'react';
import { Callout, FeaturedIcon, LoadingSpinner } from 'componentsV2';

const Step2 = ({ validationResData, loading }) => {
	const successIcon = (
		<FeaturedIcon icon={'person'} type={'Success'} size={'md'} shape={'circleIcon'} />
	);
	const errorIcon = (
		<FeaturedIcon icon={'error'} type={'Error'} size={'md'} shape={'circleIcon'} />
	);
	const warningIcon = (
		<FeaturedIcon icon={'error'} type={'Warning'} size={'md'} shape={'circleIcon'} />
	);
	return (
		<>
			{loading && <LoadingSpinner />}

			{!loading && validationResData && (
				<div className="step-2">
					{!loading && (
						<>
							{validationResData != undefined &&
							validationResData.invalid_headers != undefined ? (
								<Callout
									intent="danger"
									icon={errorIcon}
									text={
										<>
											{
												<strong>
													{' '}
													{validationResData['invalid_headers']}{' '}
												</strong>
											}
											{validationResData.msg}
										</>
									}
								/>
							) : (
								''
							)}
							{validationResData != undefined &&
							validationResData.valid_contacts != undefined &&
							validationResData.valid_contacts != 0 ? (
								<Callout
									intent="success"
									icon={successIcon}
									text={
										<div>
											{validationResData.valid_contacts_count} contacts can be
											uploaded successfully
										</div>
									}
								/>
							) : (
								''
							)}

							{validationResData != undefined &&
							validationResData['invalid_contacts_count'] != undefined &&
							validationResData['invalid_contacts_count'] != 0 ? (
								<Callout
									intent="warning"
									icon={warningIcon}
									text={
										<>
											<div>
												<p className="title">
													{validationResData['invalid_contacts_count']}{' '}
													contacts cannot be uploaded due to the below
													reasons
												</p>
												<ul>
													{validationResData['validation_errors'].map(
														row => (
															<li>
																<strong> {row.key} </strong>{' '}
																{row.msg}{' '}
																<strong>
																	<span className="">
																		{'{'}
																		{row.rows}
																		{'}'}
																	</span>
																</strong>
															</li>
														),
													)}
												</ul>
											</div>
										</>
									}
								/>
							) : (
								''
							)}
						</>
					)}
				</div>
			)}
		</>
	);
};
export default Step2;
