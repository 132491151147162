import React, { useContext } from 'react';
import { Button } from 'componentsV2';
import './css/ActionsList.scss';
import { RapidAlarmsContext } from '../../../Providers';

export const ActionsList = ({ alarm, history }) => {
	const { openedFromHomePage, onEndAlarmClick } = useContext(RapidAlarmsContext);

	const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
	const isRespondTypeAdmin = loggedUserData.accountType == 'administrator';
	const redirectToAlarmHandler = () => {
		history.push({
			pathname: `/active-alarms/${alarm.id}`,
			state: { openedFromHomePage },
		});
	};

	return (
		<div className="active-alarm-actions-list">
			<Button
				large
				text="Go to alarm"
				size="sm"
				type="secondaryDefault"
				intent="default"
				className="actionButtonElement"
				rightIcon="arrow-top-right"
				onClick={redirectToAlarmHandler}
			/>
			{isRespondTypeAdmin && (
				<Button
					large
					text="End alarm"
					size="sm"
					type="primaryDanger"
					intent="default"
					className="actionButtonElement"
					onClick={() => {
						onEndAlarmClick(alarm);
					}}
				/>
			)}
		</div>
	);
};

ActionsList.propTypes = {};
