/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import { HttpService } from 'networking/HttpService';

const getDistrictId = () => JSON.parse(localStorage.getItem('currentDistrict')).id

export const ScenariosService = {
	async getAll(filterationData) {

		const objString = `?${new URLSearchParams({ ...filterationData, category: filterationData.category.value }).toString()}`;

		const route = `/drills/scenario${objString}`
		const response = await HttpService({
			method: 'GET',
			route,
		});

		return (response);
	},

	enableAndDisableScenario: async (id, status) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: '/drills/scenario/enable-disable/' + id,
				body: {
					"type": status
				}
			});
		} catch (error) {
			throw error;
		}
	},

	async getScenario(id) {
		try {
			const res = await HttpService({
				method: 'GET',
				route: `/drills/scenario/${id}`
			})
			return res.data
		} catch (error) {
			throw error
		}
	},

	async getUsageHistory(id) {
		try {
			const res = await HttpService({
				method: 'GET',
				route: `/drills/scenario/usagehistory/${id}`
			})
			return res.data
		} catch (error) {
			throw error
		}
	},

	// jsdocs for data props
	async markAsUsed(data) {
		try {
			const res = await HttpService({
				method: 'POST',
				route: '/drills/scenario/mark-as-used',
				body: data
			})
			return res.data
		} catch (error) {
			throw error
		}
	}
};
