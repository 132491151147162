import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import selectRoute from '../Login/selectors';
import { checkPermission } from 'utilities/permissions';
import { PageHeader } from 'components/PageHeader';
import { SafetyPlanTemplates, SafetyplansProvider, SafetyPlanSettings } from './Core';
import { CustomTabs } from 'componentsV2';
import { Tab } from '@blueprintjs/core';
import { DistrictPlans, DistrictTemplateProvider } from './Districts';
import SafetyPlanAdministration from './Core/SafetyPlanAdministration';

export const SafetyPlanTemplatesDashboard = ({ history, route }) => {
	const { permissions, permissionsFetched } = route;
	const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
	const primaryBuildingId = loggedUserData?.primaryBuildingId;
	const [tabId, setTabId] = useState(primaryBuildingId == 6880 ? 'Templates' : 'SafetyPlans');

	useEffect(() => {
		if (permissionsFetched) {
			const permissionCheck = checkPermission('safety_plans_view', permissions);

			if (!permissionCheck) {
				history.push(`/comingsoon/flipcharts`);
			}
		}
	}, [permissionsFetched, permissions, history]);

	const tabs = [
		{
			title: 'Safety plans',
			panel: (
				<DistrictTemplateProvider history={history}>
					<DistrictPlans />
				</DistrictTemplateProvider>
			),
			index: 0,
			disabled: false,
			id: 'SafetyPlans',
		},
		{
			title: 'Settings',
			panel: <SafetyPlanSettings editPermission={true} />,
			index: 1,
			disabled: false,
			id: 'Settings',
		},
	];
	if (primaryBuildingId == 6880) {
		tabs.push({
			title: 'Templates',
			panel: <SafetyPlanTemplates history={history} />,
			index: 2,
			disabled: false,
			id: 'Templates',
		});
		tabs.push({
			title: 'Generate new template IDs',
			panel: <SafetyPlanAdministration />,
			index: 3,
			disabled: false,
			id: 'administration',
		});
	}
	const panelLoader = index => {
		return tabs[index].panel || null; // Return null if no panel content
	};

	const changeTab = newTabId => {
		setTabId(newTabId);
	};

	return (
		<SafetyplansProvider history={history}>
			<div className="SafetyPlanDashboard__Container">
				<div className="d-flex justify-content-between">
					<PageHeader
						title="Safety plan dashboard"
						subtitle="Create and access safety plans"
					/>
				</div>
				<CustomTabs
					id="customTabsPattern"
					onChangeHandler={changeTab}
					renderActiveTabPanelOnly={true}
					selectedTabId={tabId}
				>
					{tabs.map(tab => (
						<Tab
							id={tab.id}
							key={tab.index}
							panel={panelLoader(tab.index)}
							title={tab.title}
							disabled={tab.disabled}
							className="CustomTabs__Tab"
						/>
					))}
				</CustomTabs>
			</div>
		</SafetyplansProvider>
	);
};

SafetyPlanTemplatesDashboard.propTypes = {
	history: PropTypes.object,
	route: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(SafetyPlanTemplatesDashboard);
