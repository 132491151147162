import React from 'react';
import { StepsBarItem } from './components';
import './css/StepsBar.scss';

export const StepsBar = ({
  steps,
  orientation,
  stepBarType,
  iconCircle = true,
}) => {

  const itemWidth = orientation == "horizontal" ? Math.round(100 / steps.length) + 1 : 100;

  return (
    <div className={`steps-bar-container steps-bar-${orientation} ${!iconCircle ? "steps-bar-icon-hide-circle" : ""} ${stepBarType ? `steps-bar-${stepBarType}` : ""}`}>
      {steps.map((item, key) => (
        <StepsBarItem
          key={key}
          item={item}
          itemWidth={itemWidth}
        />
      ))}
    </div>
  );
};

