import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Checkbox } from '@blueprintjs/core';
import Dropzone from 'react-dropzone';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';
import { debounce } from 'debounce';
import {validateTemplateSubsection} from "utilities";
import { TemplateService } from 'services/TemplateService';
import { fireSwal } from 'utilities/fireSwal';

const Wrapper = styled.div`
	border: 1px solid #ced9e0;
`;
const ButtonWrap = styled.div`
	display: flex;
	border: 0.2px solid #ced9e0;
	border-bottom: 0px;
`;
const span = styled.span`
	padding-left: 500px;
`;

const BigIcon = styled.div`
	color: #5c7080;
	text-align: center;
	padding: 30px 0;
	svg {
		width: 48px;
		height: auto;
		margin-bottom: 10px;
	}
	p {
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		color: #394b59;
	}
`;

const LightIcon = styled(BigIcon)`
	color: #d8e1e8;
`;

const CheckboxWrap = styled.div`
	margin-top: 32px;
	margin-left: 56px;
	margin-right: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const FileName = styled.p`
	font-family: Merriweather;
	font-weight: bold !important;
	font-size: 18px !important;
	line-height: 21px;
	color: #394b59 !important;
`;

const FileSize = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #394b59;
`;

const DropzoneWrap = styled.div``;

class FileSection extends React.Component {
	state = {
		loading: false,
		showButtons: false,
	};

	constructor() {
		super();
		this.state = {
			isOpen: false,
		};
		this.editorRef = React.createRef();
		this.debounceUpdate = debounce(this.updateSubsection, 400);

	}
	onDrop = files => {
		this.setState({ loading: true });
		const {
			templateId,
			dispatch,
			sectionIndex,
			subsectionIndex,
		} = this.props;

		const token = JSON.parse(localStorage.getItem('user')).jwt;

		const fd = new FormData();
		fd.append('file', files[0]);

		fetch(`${process.env.API_URL}/plan-templates/${templateId}/upload`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: fd,
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					this.setState({ loading: false });
					return dispatch({
						type: 'ADD_FILE',
						baseFile: {
							coreTemplate: templateId,
							size: resp.data.size,
							id: resp.data.id,
							name: resp.data.fileName,
						},
						sectionIndex,
						subsectionIndex,
					});
				} else if (resp.statusCode == 400) {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: resp.data,
					});
				}
				this.setState({ loading: false });
				return null;
			});
	};

	updateSubsection = () => {
		const { updateMode } = this.props;
		if (updateMode) return null;
		const { updateNodes, sectionIndex, subsectionIndex } = this.props;
		const tempcontent = '';
		return updateNodes(sectionIndex, subsectionIndex, tempcontent);
	};

	flipReupload = () => {
		const { dispatch, sectionIndex, subsectionIndex } = this.props;
		return dispatch({
			type: 'FLIP_REUPLOAD',
			sectionIndex,
			subsectionIndex,
		});
	};

	deleteFile = () => {
		Swal.fire({
			title: 'Delete the file?',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Delete',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				const {
					subsection: { baseFile },
					templateId,
					dispatch,
					sectionIndex,
					subsectionIndex,
				} = this.props;

				TemplateService.deleteFile(baseFile.id, templateId).then(async (response) => {
					if (response.statusCode === 200) {
						await dispatch({
							type: 'DELETE_FILE',
							sectionIndex,
							subsectionIndex,
						});
						const { saveAndReload } = this.props;
						await saveAndReload();
						fireSwal('success', 'File Deleted Successfully');
					} else {
						fireSwal('error', 'Something went wrong!');
					}
				}).catch(err => {
					fireSwal('error', err.error.description);
				})
			}
		});
	};
	publishUpdate = () => {
		const {
			templateId,
			sectionIndex,
			subsectionIndex,
			actualSectionId,
			actualSubsectionId,
			template,
			subsection,
			subsection: { reupload, baseFile },
		} = this.props;
		// should always be true -- publish is for templates only
		const errorObj = validateTemplateSubsection(
			subsection,
			actualSectionId,
			true,
      null,
			template.name,
		);
		if (
			!errorObj.issue ||
			(!reupload && errorObj.issue === 'missingUpload')
		) {
			Swal.fire({
				title: 'Publish update?',
				text: 'You are about to publish an update for this section',
				icon: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'bp3-button bp3-intent-danger',
					cancelButton: 'bp3-button',
				},
				confirmButtonText: 'Publish',
				reverseButtons: true,
			}).then(result => {
				if (result.value) {
					Swal.fire('Publishing');
					Swal.showLoading();
					const token = JSON.parse(localStorage.getItem('user')).jwt;
					const tempcontent = '';
					let subsectionTitle;
					if (actualSubsectionId) {
						template.sections[sectionIndex].subsections[
							subsectionIndex
						].content = tempcontent;
						subsectionTitle =
							template.sections[sectionIndex].subsections[
								subsectionIndex
							].title;
					} else {
						template.sections[sectionIndex].content = tempcontent;
						subsectionTitle = template.sections[sectionIndex].title;
					}
					let updatedContent;
					if (reupload) {
						updatedContent = {
							actualSectionId,
							actualSubsectionId,
							content: '',
							is_document: true,
							reupload: true,
						};
            if (!!baseFile?.name) {
              updatedContent['baseFile'] = baseFile;
            }
          } else {
            updatedContent = {
              actualSectionId,
              actualSubsectionId,
              content: '',
              is_document: true
            };
          }
		  updatedContent.index = actualSubsectionId ? subsectionIndex : sectionIndex;
		  updatedContent.title = subsectionTitle;


          if (actualSubsectionId) {
            template.sections[sectionIndex].subsections[
              subsectionIndex
              ].content = tempcontent;
          } else {
            template.sections[sectionIndex].content = tempcontent;
          }
          return fetch(
            `${
              process.env.API_URL
            }/plan-templates/${templateId}/updates`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                type: 'update',
                updatedContent: JSON.stringify(updatedContent),
                actualSectionId,
                actualSubsectionId,
              }),
            },
          )
            .then(res => res.json())
            .then(resp => {
              if (resp.statusCode === 201) {
                fetch(
                  `${
                    process.env.API_URL
                  }/plan-templates/${parseInt(template.id, 10)}`,
                  {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'app/json',
                      Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
						headers: JSON.stringify(template.headers),
						content: JSON.stringify(
							template.sections,
						),
						name: template.name,
						state: template.state,
                    }),
                  },
                )
                  .then(res => res.json())
                  .then(rsp => {
                    if (rsp.statusCode === 200) {
                      Swal.close();
                      Swal.fire({
                        title: 'Done!',
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false,
                      }).then(() => window.location.reload());
                    }
                  });
				} else if(resp.statusCode == 400) {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: resp.error?.description || 'Could not publish update. Please try again.',
					});
				}
              return null;
            });
        }
        return Swal.close();
      });
    } else {
      Swal.fire({
        html: errorObj.messageHTML,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'ok',
        reverseButtons: true,
        returnFocus: false,
        onAfterClose() {
          const element = document.getElementById('subsection-' + actualSectionId + '-' + actualSubsectionId);
          if (!!element) {
            element.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }
        }
      }).then(result => {
        if (result.value) {
          return Swal.close();
        }
      });
    }
	};


	toggleMode = () => {
		const {
			type,
			sectionIndex,
			subsectionIndex,
			toggleUpdateFileMode,
			actualSectionId,
			actualSubsectionId,
		} = this.props;
		let indexVal = sectionIndex + '-' + subsectionIndex;
		this.setState({ showButtons: true });
		return toggleUpdateFileMode(
			true,
			indexVal,
			type,
			actualSectionId,
			actualSubsectionId,
		);

	};


	render() {
		const {
			subsectionIndex,
			cancelUpdateMode,
			subsection: { reupload, baseFile },
			viewMode,
		} = this.props;
		const { loading , showButtons } = this.state;
		return (
			<>
				<ButtonWrap className="">
					{!viewMode && (
					<Button
						text= "Create an Update"
						minimal
						onClick={this.toggleMode}
					/>
					)}

				{showButtons && (<><span></span>
					<Button
						text="Cancel"
						onClick={cancelUpdateMode}
					/>
					<Button
						text="Publish"
						className="ml-2"
						onClick={this.publishUpdate}
					/></>
				)}
				</ButtonWrap>
				<Wrapper
					id ="ss0"
					key={subsectionIndex}
					ref={this.editorRef}
					onInit={(evt, editor) => {
						this.editorRef.current = editor;
						return null;
					}}
				>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<CheckboxWrap>
								<Checkbox
									disabled={viewMode}
									checked={reupload}
									onChange={this.flipReupload}
								>
									Allow document download & re-upload
								</Checkbox>
								{reupload && baseFile?.name && !viewMode  && (
									<Button
										icon="cross"
										minimal
										onClick={this.deleteFile}
									/>
								)}
							</CheckboxWrap>
							{!reupload && (
								<BigIcon>
									<Icon icon="saved" />
									<p>Users will upload their document here</p>
								</BigIcon>
							)}
							{reupload && !baseFile?.name && (
								<DropzoneWrap>
									<Dropzone onDrop={this.onDrop} disabled={viewMode}>
										{({ getRootProps, getInputProps }) => (
											<section className="container">
												<div
													{...getRootProps({
														className: 'dropzone',
													})}
												>
													<input {...getInputProps()} />
													<LightIcon>
														<Icon icon="document" />
														<p>
															Drag and drop a file
															here, or{' '}
															<a
																href="#"
																onClick={e =>
																	e.preventDefault()
																}
															>
																browse
															</a>
														</p>
													</LightIcon>
												</div>
											</section>
										)}
									</Dropzone>
								</DropzoneWrap>
							)}
							{reupload && baseFile?.name && (
								<DropzoneWrap>
									<Dropzone onDrop={this.onDrop} disabled={viewMode}>
										{({ getRootProps, getInputProps }) => (
											<section className="container">
												<div
													{...getRootProps({
														className: 'dropzone',
													})}
												>
													<input {...getInputProps()} />
													<BigIcon>
														<Icon icon="document" />
														<FileName>
															{baseFile?.name}
														</FileName>
														<FileSize>
															{(baseFile.size /1000000).toFixed(2)} MB
														</FileSize>
													</BigIcon>
												</div>
											</section>
										)}
									</Dropzone>
								</DropzoneWrap>
							)}
						</>
					)}
				</Wrapper>
			</>
		);
	}
}

FileSection.propTypes = {
	subsection: PropTypes.object,
	dispatch: PropTypes.func,
	sectionIndex: PropTypes.number,
	subsectionIndex: PropTypes.number,
	templateId: PropTypes.string,
};

export default FileSection;
