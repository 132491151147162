import React from 'react';
import { BootstrapedTable, sortingStyle, TableCell, Badge } from 'componentsV2';
import './css/ReunificationGuardiansTable.scss';
import {
	STATUS_NO_STUDENTS_REUNIFIED,
	STATUS_PARTIALLY_REUNIFIED,
	STATUS_REUNIFICATION_COMPLETE,
} from '../../consts';
import { Icon } from '@blueprintjs/core';
import { ReunificationGuardianActionsList } from './ReunificationGuardianActionsList';

export const ReunificationGuardiansTable = ({
	guardiansList,
	totalGuardians,
	filterData,
	setFilterData,
	alarmId,
}) => {
	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Checked-in guardian',
			sort: true,
			classes: 'border-right',
			sortCaret: (order, column) => sortingStyle(filterData.sortDir, column),
		},
		{
			key: 'status',
			dataField: 'status',
			text: 'Status',
			classes: 'border-right width-100',
			formatter: (value, row) => {
				let type = 'Success';

				switch (value) {
					case STATUS_REUNIFICATION_COMPLETE:
						type = 'Success';
						break;
					case STATUS_PARTIALLY_REUNIFIED:
						type = 'Warning';
						break;
					case STATUS_NO_STUDENTS_REUNIFIED:
						return <></>;
				}

				return <Badge type={type} size="md" text={value} />;
			},
		},
		{
			key: 'students',
			dataField: 'students',
			text: 'Students to pick-up',
			classes: 'no-padding border-right',
			formatter: (value, row) => {
				return (
					<>
						{value.map((student, key) => (
							<TableCell
								key={key}
								text={
									student.student.firstName +
									' ' +
									(student.student.middleInitial
										? student.student.middleInitial.charAt(0) + '. '
										: ' ') +
									student.student.lastName
								}
								supportText={<>Grade: {student.student.grade}</>}
							/>
						))}
					</>
				);
			},
		},
		{
			key: 'ready-to-reunify',
			dataField: 'checkedIn', // it could be students but we put any other key to avoid key duplicates
			text: 'Ready to reunify',
			headerStyle: {
				textAlign: 'center',
			},
			classes: 'no-padding width-150',
			formatter: (value, row) => {
				return (
					<>
						{row.students.map((student, key) => (
							<div className="table-cell" key={key}>
								{student.student.checkIn?.status == 'checkedIn' && (
									<Icon icon="tick" />
								)}
							</div>
						))}
					</>
				);
			},
		},
		{
			key: 'reunified',
			dataField: 'personId', // it could be students but we put any other key to avoid key duplicates
			text: 'Reunified',
			headerStyle: {
				textAlign: 'center',
			},
			classes: 'no-padding border-right width-150',
			formatter: (value, row) => {
				return (
					<>
						{row.students.map((student, key) => (
							<div className="table-cell" key={key}>
								{student.reunified && <Icon icon="tick" />}
							</div>
						))}
					</>
				);
			},
		},
		{
			key: 'actions',
			dataField: 'email',
			text: 'Actions',
			classes: 'width-100',
			formatter: (value, row) => (
				<ReunificationGuardianActionsList row={row} alarmId={alarmId} />
			),
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			if (sortField == 'name') {
				setFilterData(oldData => ({
					...oldData,
					sortKey: 'guardianName',
					sortDir: oldData.sortDir == 'desc' ? 'asc' : 'desc',
				}));
			}
		}
	};

	const setCurrentPage = value => {
		setFilterData(oldData => ({ ...oldData, page: value }));
	};

	const setRowsPerPage = value => {
		setFilterData(oldData => ({ ...oldData, perPage: value }));
	};

	return (
		<BootstrapedTable
			keyField="id"
			key="reunificationGuardiansTable"
			className="reunification-guardians-table"
			data={guardiansList}
			columns={columns}
			totalRows={totalGuardians}
			currentPage={filterData.page}
			rowsPerPage={filterData.perPage}
			setCurrentPage={setCurrentPage}
			setRowsPerPage={setRowsPerPage}
			onTableChange={onTableChange}
		/>
	);
};
