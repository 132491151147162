import React from 'react';
import PropTypes from 'prop-types';
import InputWrapper, { ElementWrapper } from 'componentsV2/InputWrapper/InputWrapper.jsx';
import './css/TextArea.scss';

export const TextArea = ({
	label = null,
	placeholder = 'Enter a value',
	value = null,
	onChange = null,
	disabled = false,
	width = null,
	error = false,
	errorMessage = null,
	hasHintText = false,
	hintText = null,
	isRequired,
	labelState = true,
	className = '',
	name = null,
	textAreaRef = null,
	onKeyDown = () => {},
}) => {
	if (typeof onChange !== 'function')
		throw new Error("'onChange' is not defined for this text area");

	return (
		<>
			<InputWrapper
				label={label}
				placeholder={placeholder}
				isRequired={isRequired}
				error={error}
				errorMessage={errorMessage}
				hasHintText={hasHintText}
				hintText={hintText}
				labelState={labelState}
				className={className}
			>
				<ElementWrapper className="TextArea__Wrapper" width={width}>
					<textarea
						ref={textAreaRef}
						className={`TextInput__Area ${error ? 'errorContainer' : ''}`}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						disabled={disabled}
						name={name}
						onKeyDown={onKeyDown}
					/>
				</ElementWrapper>
			</InputWrapper>
		</>
	);
};

TextArea.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.bool,
	errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
};
