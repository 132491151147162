import React from 'react';
import PropTypes from 'prop-types';
import TextVariable from './textvariable';
import MultipleVariable from './multiplevariable';
import ImagesVariable from './imagesvariable';

class VariableValue extends React.Component {
	state = {};

	getValueComponent = () => {
		const { item, templateId, setCanClose, setIsUpdated } = this.props;
		switch (item.variable.content.type.id) {
			case 1:
				return (
					<TextVariable
						item={item}
						templateId={templateId}
						setCanClose={setCanClose}
						setIsUpdated={setIsUpdated}
					/>
				);
			case 2: // image type
				return (
					<ImagesVariable
						item={item}
						templateId={templateId}
						setCanClose={setCanClose}
						setIsUpdated={setIsUpdated}
					/>
				)
			case 3:
				return (
					<TextVariable
						item={item}
						templateId={templateId}
						setCanClose={setCanClose}
					/>
				);
			case 4:
				return (
					<TextVariable
						item={item}
						templateId={templateId}
						setCanClose={setCanClose}
					/>
				);
			case 5:
				return (
					<MultipleVariable
						item={item}
						templateId={templateId}
						setCanClose={setCanClose}
					/>
				);
			default:
				return <div />;
		}
	};

	render() {
		return <div className="px-3">{this.getValueComponent()}</div>;
	}
}

VariableValue.propTypes = {
	item: PropTypes.object,
	templateId: PropTypes.string,
	setCanClose: PropTypes.func,
};

export { VariableValue };
