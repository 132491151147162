import React, { useState } from 'react';
import { Popover , Position } from '@blueprintjs/core';
import { Button } from '@blueprintjs/core';
import './index.scss';
import PropTypes from 'prop-types';


const ActionsDropdown = ({
    actionsList
}) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Popover
            className='actions-dropdown-wrapper'
            enforceFocus={false}
            isOpen={isOpen}
            position={Position.BOTTOM}
            onClose={() => setIsOpen(false)}
        >
            <Button
                icon="more"
                text=""
                onClick={() => setIsOpen(true)}
                minimal
            />
            <div className='actions-dropdown-list-wrapper'>
                {actionsList}
            </div>
        </Popover>
    )
}

ActionsDropdown.propTypes = {
    actionsList: PropTypes.any.isRequired
}

export default ActionsDropdown;