import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

class SelectQuestion extends React.Component {
	constructor(props) {
		super(props);
		const { item } = this.props;
		let sValue = null;
		if (item.answer && item.answer.response) {
			const parsed = JSON.parse(item.answer.response)[
				Object.keys(JSON.parse(item.answer.response))[0]
			];
			sValue = {
				label: parsed,
				value: parsed,
			};
		}

		const stateArr = [];
		const tempArr = Object.values(JSON.parse(item.properties).options);
		tempArr.forEach(one => stateArr.push({ label: one, value: one }));
		this.state = {
			options: stateArr,
			selectedValue: sValue,
		};
	}

	handleChange = item => {
		this.setState({ selectedValue: item });
		Swal.fire('Saving');
		Swal.showLoading();
		const { templateId, questionId } = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${
				process.env.API_URL
			}/assessments/${templateId}/questions/${questionId}/answers`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					response: {
						select: item.value,
					},
				}),
			},
		)
			.then(res => res.json())
			.then(() => {
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				});
			});
	};

	render() {
		const { selectedValue, options } = this.state;
		return (
			<Select
				defaultValue={selectedValue}
				className="multi-select"
				closeMenuOnSelect={false}
				components={animatedComponents}
				options={options}
				onChange={selected => this.handleChange(selected)}
			/>
		);
	}
}

SelectQuestion.propTypes = {
	templateId: PropTypes.any,
	questionId: PropTypes.any,
	item: PropTypes.object,
};

export default SelectQuestion;
