import React, { useEffect, useState, useContext } from 'react';
import {
	RadioButtonGroup,
	Input,
	TextArea,
	Dropdown,
	FormModal,
	Intent,
	ToasterContext,
	FeaturedIcon,
} from 'componentsV2';
import PropTypes from 'prop-types';
import { SchoolYearsService, AssessTemplatesService, StatesService } from 'services';
import { Radio } from '@blueprintjs/core';

export const CreateNewTemplateModal = ({ onClose, isCoreTemplate, history }) => {
	const [yearsOptions, setYearsOptions] = useState(null);
	const [stateOptions, setStateOptions] = useState([]);
	const [yearSelectedValue, setYearSelectedValue] = useState(null);
	const [stateSelectedValue, setStateSelectedValue] = useState(null);
	const [titleValue, setTitleValue] = useState('');
	const [proprietarySelectedValue, setProprietarySelectedValue] = useState(null);
	const [emailValue, setEmailValue] = useState('');
	const [descriptionValue, setDescriptionValue] = useState('');
	const toaster = useContext(ToasterContext);

	useEffect(() => {
		getAssessmentYears();
		getStates();

		if (!isCoreTemplate) {
			setProprietarySelectedValue('false');
		}
	}, []);

	const getAssessmentYears = () => {
		SchoolYearsService.fetchYears().then(siteYears => setYearsOptions(siteYears));
	};
	const loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));

	const getStates = () => {
		if (isCoreTemplate) {
			StatesService.getAll(true).then(states => {
				const newArray = states.slice();
				newArray.unshift({
					id: 0,
					abbreviation: 'All',
					label: 'All States',
					name: 'All States',
					value: 'all states',
				});

				setStateOptions(newArray);
			});
		} else {
			StatesService.getAll().then(states => {
				setStateOptions(states);
				setStateSelectedValue(states[states.findIndex(state => state.name === loggedUser.primaryBuilding.state)]);
			});
		}
	};

	const onSave = () => {
		AssessTemplatesService.saveTemplate({
			name: titleValue,
			state: stateSelectedValue.abbreviation,
			year: yearSelectedValue.value,
			proprietary: proprietarySelectedValue,
			emails: emailValue || null,
			notes: descriptionValue || null,
		})
			.then(resp => {
				toaster(
					`You have successfully created ${titleValue}.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" type="Success" size="md" shape="circleIcon" />,
				);
				history.push({
					pathname: `/assessV2/assess/template/${resp?.data?.id}`,
					state: { data: resp.data, isCoreTemplate },
				});
			})
			.catch(() => {
				toaster(
					`There was an error creating ${titleValue}. Please try again.`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" size="md" shape="circleIcon" />,
				);
			})
			.finally(() => {
				onClose();
			});
	};

	return (
		<>
			<FormModal
				isOpen
				onClose={onClose}
				showCloseButton
				title={`Add ${isCoreTemplate ? 'core' : 'custom'} template`}
				confirmBtn={{
					title: 'Create',
					onClick: () => onSave(),
					disabled: !(
						titleValue &&
						stateSelectedValue &&
						yearSelectedValue &&
						proprietarySelectedValue
					),
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: () => onClose(),
				}}
			>
				<div className="create-assess-template-modal w-100">
					<Input
						label="Title"
						isRequired
						placeholder="Enter title"
						hasHintText
						hintText="ex. Playground Safety Assessment"
						value={titleValue}
						onChange={e => setTitleValue(e.target.value)}
						width="100%"
					/>
					<div className="side-by-side">
						<div className="w-50">
							<Dropdown
								label="State"
								placeholder="Select state"
								optionLabel="abbreviation"
								optionValue="name"
								options={stateOptions}
								isRequired
								disabled={!isCoreTemplate}
								value={stateSelectedValue}
								onChange={item => setStateSelectedValue(item)}
							/>
						</div>
						<div className="w-50">
							<Dropdown
								label="School year"
								placeholder="Select school year"
								options={yearsOptions}
								isRequired
								value={yearSelectedValue}
								onChange={item => setYearSelectedValue(item)}
							/>
						</div>
					</div>
					{isCoreTemplate && (
						<RadioButtonGroup
							label="Proprietary template"
							size="medium"
							layout="inline"
							bordered
							required
							fullwidth
							disabled={!isCoreTemplate}
							onChange={e => setProprietarySelectedValue(e.target.value)}
							selectedValue={proprietarySelectedValue}
						>
							<Radio className="w-50" label="Yes" value="true" />
							<Radio className="w-50" label="No" value="false" />
						</RadioButtonGroup>
					)}
					<Input
						label="Add recipients for notification upon assessment completion"
						placeholder="Enter email"
						hasHintText
						hintText="Enter as a comma separated list."
						onChange={e => setEmailValue(e.target.value)}
						value={emailValue}
						width="100%"
					/>
					<TextArea
						label="Description"
						placeholder="Enter template description"
						onChange={e => setDescriptionValue(e.target.value)}
						value={descriptionValue}
						width="100%"
					/>
				</div>
			</FormModal>
		</>
	);
};

CreateNewTemplateModal.propTypes = {
	onClose: PropTypes.func,
	isCoreTemplate: PropTypes.bool,
	history: PropTypes.object,
};
