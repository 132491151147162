import React, { useContext, useEffect, useReducer, useState } from 'react';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { NoResultFound } from 'components/NoResultFound';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { SearchBar } from 'components/SearchBar';
import { NoResultFoundIcon, noCallListAdded } from 'assets/icons';
import { CallListService } from 'services/CallListService';
import './css/index.scss';
import Table from './Components/Table';
import { Button } from 'components/Button';
import NewCallListModal from './Components/NewCallListModal';
import { Icon, Intent } from '@blueprintjs/core';
import { checkPermission, filterSitesPerPermissionCode } from 'utilities/permissions';
import GridTable from './Components/GridTable';
import { useDispatch, useSelector } from 'react-redux';
import { ToasterContext } from 'pages/App';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import SortByDropdown from 'components/SortByDropdown';
import ActionsAddButton from 'components/ActionsAddButton';
import { PageHeader } from 'components/PageHeader';
import { SORTING_DIR } from 'utilities/constants';

const CallList = props => {
	const [loading, setLoading] = useState(true);
	const [modalToShow, setModalToShow] = useState(false);
	const [callListID, setCallListID] = useState(null);
	const [callList, setCallList] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [emptyCallList, setEmptyCallList] = useState(false);
	const [noCallListFound, setNoCallListFound] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [refetchGridData, setRefetchGridData] = useState(false);
	const toaster = useContext(ToasterContext);
	const [sitesLoaded, setSitesLoaded] = useState({
		data: [],
		loaded: false,
	});
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('call_list_edit', permissions);
	const dispatch = useDispatch();
	const filterationDataReducer = (filterationData, action) => {
		switch (action.type) {
			case 'selectedBuilders':
				var sites = [];
				action.payload.map(site => {
					sites.push(site.value);
				});
				filterationData.selectedBuilders = sites;
				filterationData.selectedSites = action.payload;
				filterationData.page = 1;
				dispatch({ type: 'SET_CALL_LIST_SITES', payload: action.payload });
				break;
			case 'title':
				filterationData.title = action.payload;
				filterationData.page = 1;
				break;
			default:
				filterationData[action.type] = action.payload;
				break;
		}
		return {
			page: filterationData.page,
			perPage: filterationData.perPage,
			selectedBuilders: filterationData.selectedBuilders,
			selectedSites: filterationData.selectedSites,
			title: filterationData.title,
			sortKey: filterationData.sortKey,
			sortDir: filterationData.sortDir,
		};
	};

	const prevoulscallListViewType = useSelector(state => state.route.callListViewType);
	const [filterationData, dispatchFilterationData] = useReducer(filterationDataReducer, {
		page: 1,
		perPage: prevoulscallListViewType === 'grid' ? 30 : 10,
		selectedBuilders: [],
		selectedSites: [],
		title: '',
		sortKey: 'title',
		sortDir: 'asc',
	});

	const loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
	const previousSitesSelection = useSelector(state => state.route.callListSites);

	const [listView, setListView] = useState(prevoulscallListViewType === 'grid' ? false : true);

	useEffect(() => {
		if (previousSitesSelection !== undefined) {
			dispatchFilterationData({ type: 'selectedBuilders', payload: [] });
		} else {
			dispatchFilterationData({
				type: 'selectedBuilders',
				payload: [],
			});
		}
	}, []);

	const handleSitesLoadedFromEndPoint = object => {
		const filteredSites = filterSitesPerPermissionCode(
			'call_list_edit',
			permissions,
			object.data,
		);
		const filteredObject = { ...object, data: filteredSites };
		setSitesLoaded(filteredObject);
	};

	const fetchCallList = () => {
		CallListService.getAll(filterationData)
			.then(resp => {
				if (resp.statusCode === 200) {
					setTotalRows(resp._metadata.totalRecords);
					setCallList(resp.data);
					if (resp._metadata.totalRecords === 0) {
						setNoCallListFound(true);
					} else {
						setNoCallListFound(false);
					}
				}
				setLoading(false);
			})
			.catch(() => setLoading(false));
	};

	useEffect(() => {
		//check if selected sites has call lists
		CallListService.getAll({
			page: 1,
			perPage: 1,
			selectedBuilders: filterationData.selectedBuilders,
		})
			.then(resp => {
				if (resp._metadata.totalRecords === 0) {
					setEmptyCallList(true);
				} else {
					setEmptyCallList(false);
				}
			})
			.catch(() => setLoading(false));
	}, [filterationData.selectedBuilders]);

	useEffect(() => {
		fetchCallList();
	}, [filterationData]);

	const onCloseModal = () => {
		setCallListID(null);
		setLoading(false);
		setModalToShow(false);
	};

	const loadAddModal = () => {
		setCallListID(null);
		setModalToShow('AddCallListModal');
	};

	const loadEditModal = id => {
		setCallListID(id);
		setModalToShow('AddCallListModal');
	};

	const handlePerPageChange = perPage => {
		dispatchFilterationData({
			type: 'perPage',
			payload: perPage,
		});
	};

	const deleteCallList = () => {
		setModalLoading(true);
		CallListService.delete(callListID)
			.then(res => {
				if (res.statusCode === 200) {
					toaster('Call list successfully deleted', Intent.SUCCESS);
					setModalLoading(false);
					setModalToShow(false);
					if (listView) {
						fetchCallList();
					} else {
						setRefetchGridData(oldValue => !oldValue);
					}
				} else {
					toaster('Something went wrong', Intent.DANGER);
					setModalLoading(false);
				}
			})
			.catch(err => {
				setModalLoading(false);
				toaster(err.error.description, Intent.DANGER);
			});
	};

	const onListViewClickHandler = () => {
		setListView(true);
		dispatch({ type: 'SET_CALL_LIST_VIEW_TYPE', payload: 'list' });
		handlePerPageChange(10);
		if (listView === false) {
			dispatchFilterationData({
				type: 'page',
				payload: 1,
			});
		}
	};

	const onGridViewClickHandler = () => {
		setListView(false);
		dispatch({ type: 'SET_CALL_LIST_VIEW_TYPE', payload: 'grid' });
		handlePerPageChange(30);
	};

	const sortList = [
		{
			label: 'Ascending',
			value: SORTING_DIR.ASC,
			type: 'sortDir',
			icon: 'arrow-down',
		},
		{
			label: 'Descending',
			value: SORTING_DIR.DESC,
			type: 'sortDir',
			icon: 'arrow-up',
			addBreakLine: true,
		},
		{
			label: 'Name',
			value: 'title',
			type: 'sortKey',
			icon: 'tick',
		},
		{
			label: 'Site',
			value: 'site',
			type: 'sortKey',
			icon: 'tick',
		},
		{
			label: 'Last Modified',
			value: 'lastModified',
			type: 'sortKey',
			icon: 'tick',
		},
		{
			label: 'Number of Contacts',
			value: 'contracts',
			type: 'sortKey',
			icon: 'tick',
		},
	];

	const onSortItemHandler = item => {
		switch (item.type) {
			case 'sortKey':
				dispatchFilterationData({ type: 'sortKey', payload: item.value });
				break;
			case 'sortDir':
				dispatchFilterationData({ type: 'sortDir', payload: item.value });
				break;
		}
		dispatchFilterationData({ type: 'page', payload: 1 });
	};

	return (
		<>
			{modalToShow === 'deleteCallList' && (
				<ConfirmationModal
					title="Delete Call List"
					text={[
						<>
							<p>
								Deleting this call list will remove all related contacts. This
								action can not be undone.
							</p>
						</>,
					]}
					confirmBtnTxt="Delete"
					closeModal={() => setModalToShow(false)}
					submitModel={deleteCallList}
					loading={modalLoading}
					danger
				/>
			)}
			{modalToShow === 'AddCallListModal' && (
				<NewCallListModal
					onCloseModal={onCloseModal}
					setModalToShow={setModalToShow}
					updateDataCallBack={fetchCallList}
					sites={sitesLoaded.data}
					selectedSites={filterationData.selectedSites}
					id={callListID}
					history={props.history}
				/>
			)}
			<div className="container-fluid call-list-wrapper">
				<div className="row">
					<div className="col-md-12">
						<PageHeader title="Call Lists" />
					</div>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<div className="col-md-5">
								<SitesDropdownMultiSelect
									labelState={false}
									value={filterationData.selectedSites}
									onChange={sites =>
										dispatchFilterationData({
											type: 'selectedBuilders',
											payload: sites,
										})
									}
									allowSelectAll={false}
									visibleOptions={1}
									onDataLoad={handleSitesLoadedFromEndPoint}
								/>
							</div>
							<div className="col-md-2" />
							<div className="col-md-5 search-bar-input row-break-space">
								<SearchBar
									placeholder="Search list name..."
									onSearch={title =>
										dispatchFilterationData({ type: 'title', payload: title })
									}
									value={filterationData.title}
								/>
							</div>
							<div
								className={
									'col-md-12 d-flex row-break-space ' +
									(listView ? 'justify-content-end' : 'justify-content-between')
								}
							>
								{!listView && (
									<SortByDropdown
										sortList={sortList}
										sortKey={filterationData.sortKey}
										sortDir={filterationData.sortDir}
										onSelectItemHandler={onSortItemHandler}
									/>
								)}
								<div className="d-flex">
									{editPermission && !emptyCallList && sitesLoaded.loaded && (
										<Button
											text={<ActionsAddButton text="Create call list" />}
											intent="primary"
											onClick={() => setModalToShow('AddCallListModal')}
											className="create-btn"
										/>
									)}
								</div>
							</div>
							<div className="col-md-12 row-break-space">
								<hr className="page-hr" />
							</div>
							{emptyCallList && (
								<div className="col-md-12 ">
									<NoResultFound
										header="No call list were added yet"
										icon={noCallListAdded}
									>
										{editPermission && sitesLoaded.loaded && (
											<Button
												text={<ActionsAddButton text="Create call list" />}
												intent="primary"
												onClick={loadAddModal}
											/>
										)}
									</NoResultFound>
								</div>
							)}
							{!emptyCallList && (
								<>
									{noCallListFound ? (
										<div className="col-md-12">
											<NoResultFound
												header="No call list were found"
												icon={NoResultFoundIcon}
											/>
										</div>
									) : (
										<>
											{listView === true ? (
												<Table
													callList={callList}
													filterationData={filterationData}
													dispatchFilterationData={
														dispatchFilterationData
													}
													totalRows={totalRows}
													setCallListID={setCallListID}
													loadEditModal={loadEditModal}
													setModalToShow={setModalToShow}
												/>
											) : (
												<GridTable
													filterationData={filterationData}
													dispatchFilterationData={
														dispatchFilterationData
													}
													listView={listView}
													setCallListID={setCallListID}
													loadEditModal={loadEditModal}
													setModalToShow={setModalToShow}
													refetchData={refetchGridData}
													history={props.history}
												/>
											)}
										</>
									)}
								</>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default CallList;
