import { HttpService } from 'networking/HttpService';

export const DocumentService = {
	getAll: async (params = {}) => {
		let query = '';

		if (params.page) {
			query += `page=${params.page}`;
		}

		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}

		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}

		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}

		if (params.title) {
			query += `&title=${params.title}`;
		}

		if (params.types && params.types.length > 0) {
			params.types.forEach(type => {
				query += `&types[]=${type}`;
			});
		}

		if (params.buildingIds && params.buildingIds.length > 0) {
			params.buildingIds.forEach(buildingId => {
				query += `&buildingIds[]=${buildingId}`;
			});
		}

		return await HttpService({
			method: 'GET',
			route: `/documents?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},
	getDocumentsBySiteId: async params => {
		let query = '';
		if (params.page) {
			query += `page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.siteId) {
			query += `&buildingIds[]=${params.siteId}`;
		}
		if (params.title) {
			query += `&title=${params.title}`;
		}
		if (params.binderId) {
			query += `&binderId=${params.binderId}`;
		}

		if (params.types) {
			if (Array.isArray(params.types)) {
				params.types.forEach(type => {
					query += `&types[]=${type}`;
				});
			} else {
				query += `&types[]=${params.types}`;
			}
		}

		if (params.extensions && params.extensions.length > 0) {
			params.extensions.forEach(extensions => {
				query += `&extensions[]=${extensions.value}`;
			});
		}

		if (params.shapeId) {
			query += `&shapeId=${params.shapeId}`;
		}

		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}

		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}

		if (params.includeBinders) {
			query += `&includeBinders=${params.includeBinders}`;
		}
		if (params.floorplanId) {
			query += `&map_id=${params.floorplanId}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/documents?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},
	uploadDocument: async (params, selectedfile) => {
		let query = '';

		if (params.buildingId) {
			query += `&buildingId=${params.buildingId}`;
		}

		if (params.documentId) {
			query += `&documentId=${params.documentId}`;
		}

		if (params.attachmentId) {
			query += `&attachmentId=${params.attachmentId}`;
		}

		if (params.attachmentType) {
			query += `&attachmentType=${params.attachmentType}`;
		}

		const formData = new FormData();

		formData.append('file', selectedfile);
		const url = process.env.API_URL;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const response = await fetch(`${url}/documents?${query}`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: formData,
		});

		return response.json();
	},

	download: async (ids, _360Ids, asPdf, revisionId, floorplanId = null) => {
		let i = 0;
		let j = 0;
		const contentType = ids.length > 1 ? 'application/zip' : 'application/pdf';
		let route = '/documents/download?';

		if (ids.length != 0) {
			route += 'ids[]=';
			ids.forEach(id => {
				route += i == 0 ? id : '&ids[]=' + id;
				i++;
			});
		}

		if (_360Ids.length != 0) {
			if (ids.length == 0) {
				route += 'panorama_ids[]=';
			}
			_360Ids.forEach(id => {
				route += j == 0 && ids.length == 0 ? id : '&panorama_ids[]=' + id;
				j++;
			});
		}

		if (revisionId) {
			route += '&revision_id=' + revisionId;
		}
		if (floorplanId) {
			route += '&map_id=' + floorplanId;
		}

		route += asPdf ? '&as_pdf=true' : '';

		return await HttpService({
			method: 'GET',
			route: route,
			headers: {
				'Content-Type': contentType,
			},
		}).catch(err => {
			throw err;
		});
	},
	getRevisionHistory: async id => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/documents/revisions/${id}`,
			});
		} catch (error) {
			throw error;
		}
	},

	renameDocumentTitle: async params => {
		return await HttpService({
			method: 'PUT',
			route: `/documents/${params.id}/rename`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				type: params.type,
				title: params.title,
			},
		}).catch(err => {
			throw err;
		});
	},

	getDocumentLocations: async params => {
		let baseUrl = params.type == 'document' ? '/documents' : '/floorplans/panoramas';
		return await HttpService({
			method: 'GET',
			route: `${baseUrl}/${params.id}/locations`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	delete: async documentsIds => {
		try {
			return await HttpService({
				method: 'DELETE',
				route: `/documents`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: {
					documents_ids: documentsIds,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	delete360Image: async ids => {
		try {
			return await HttpService({
				method: 'DELETE',
				route: `/floorplans/panoramas`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: {
					ids,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	moveDocument: async params => {
		return await HttpService({
			method: 'PUT',
			route: `/documents/move`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				site_id: params.siteId,
				documents_ids: params.ids,
				panoramas_ids: params.panoramasIds,
			},
		}).catch(err => {
			throw err;
		});
	},
};
