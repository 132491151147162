import React, { useState, useEffect, useMemo } from 'react';
import { logoNavigate360 } from 'assets/icons';
import { checkPermission } from 'utilities/permissions';
import { flags, decodeToken } from 'utilities';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { NavLink } from 'react-router-dom';
import { Avatar, Button, Animated } from 'componentsV2';
import { useSelector, useDispatch } from 'react-redux';
import { resetState } from '../../../routeReducer';
import './Sidebar.scss';
import { AlarmButton } from './AlarmButton';
import { CALLLIST_ROUTES } from 'utilities/constants';
import { routes } from '../routes';

const SidebarNavLink = ({ text, icon, path, isSubItem = false, onClick, isSelected = false }) => (
	<div onClick={onClick}>
		<NavLink
			className={`sidebar-navlink ${isSubItem ? 'sub-item-padding' : ''} ${
				isSelected ? 'active' : ''
			}`}
			exact
			to={path}
		>
			{icon && <Icon icon={icon} />}
			<span className="text">{text}</span>
		</NavLink>
	</div>
);

const SidebarNavLinkCollapse = ({ text, icon, children, isOpen, onCollapse }) => (
	<div className="sidebar-navlink-collapse">
		<button className="sidebar-navlink-collapse-button" onClick={onCollapse}>
			<div className="icon-text">
				<Icon icon={icon} />
				<span className="text">{text}</span>
			</div>
			<Icon className="chevron" icon={isOpen ? 'chevron-up' : 'chevron-down'} />
		</button>
		<section className={`sidebar-navlink-collapse-items ${isOpen ? 'opened' : ''}`}>
			<Animated>{children}</Animated>
		</section>
	</div>
);

const UserMenuButton = ({ isMenuFloated, history, permissions, handleCollapse }) => {
	const [isOpen, setIsOpen] = useState(false);

	const dispatch = useDispatch();
	const handleLogOut = async () => {
		await dispatch(resetState());
		const user = JSON.parse(localStorage.getItem('user'));
		if (user === null) {
			window.location.href = '/login';
		}
	};

	const legacyEMSSwitch = feature => () => {
		handleCollapse();
		const localToken = JSON.parse(localStorage.getItem('user'));
		const { dist, gid } = decodeToken(localToken.jwt);

		return window.open(
			`${
				process.env.LEGACY_URL
			}/new_ems_landing.php?globalUserId=${gid}&districtId=${dist}&feature=${feature}`,
			'_blank',
		);
	};

	const go = path => () => {
		handleCollapse();
		history.push(path);
		setIsOpen(false);
	};

	const {
		isOn,
		names: { SITE_IS_FIRST_RESPONDER, SITE_IS_INTERNAL },
	} = flags;

	if (isOn(SITE_IS_FIRST_RESPONDER)) {
		return (
			<Popover
				popoverClassName={`user-menu-popover ${isMenuFloated && 'menu-floating'}`}
				isOpen={isOpen}
				position={Position.RIGHT_BOTTOM}
				onClose={() => setIsOpen(false)}
				modifiers={{
					arrow: { enabled: false },
					offset: { enabled: true, offset: '[0, 7]' },
				}}
				content={
					<>
						{checkPermission('user_view', permissions) && (
							<button
								className="user-menu-popover-item"
								onClick={go('/user-management')}
							>
								<Icon className="icon" size={16} icon="people" />
								<span className="text">User Management</span>
							</button>
						)}
						<button className="user-menu-popover-item logout" onClick={handleLogOut}>
							<Icon className="icon" size={16} icon="log-out" />
							<span className="text">Log Out</span>
						</button>
					</>
				}
			>
				<Button
					icon="more"
					type="tertiary"
					size="sm"
					className="user-menu-button"
					onClick={() => setIsOpen(true)}
				/>
			</Popover>
		);
	}

	return (
		<Popover
			popoverClassName={`user-menu-popover ${isMenuFloated && 'menu-floating'}`}
			isOpen={isOpen}
			position={Position.RIGHT_BOTTOM}
			onClose={() => setIsOpen(false)}
			modifiers={{ arrow: { enabled: false }, offset: { enabled: true, offset: '[0, 7]' } }}
			content={
				<>
					{checkPermission('respond_edit', permissions) && (
						<button
							className="user-menu-popover-item"
							onClick={legacyEMSSwitch('sync')}
						>
							<Icon className="icon" size={16} icon="refresh" />
							<span className="text">Manage Sync</span>
						</button>
					)}
					{checkPermission('user_view', permissions) && (
						<button className="user-menu-popover-item" onClick={go('/user-management')}>
							<Icon className="icon" size={16} icon="people" />
							<span className="text">User Management</span>
						</button>
					)}
					{checkPermission('site_info_view', permissions) && (
						<button className="user-menu-popover-item" onClick={go('/administration')}>
							<Icon className="icon" size={16} icon="locate" />
							<span className="text">Site Information</span>
						</button>
					)}
					{localStorage.getItem('hasMultiDistricts') == 1 && (
						<button className="user-menu-popover-item" onClick={go('/switch-district')}>
							<Icon className="icon" size={16} icon="swap-horizontal" />
							<span className="text">Switch District</span>
						</button>
					)}
					{permissions.isSysAdmin === true && (
						<button
							className="user-menu-popover-item"
							onClick={legacyEMSSwitch('admin')}
						>
							<Icon className="icon" size={16} icon="cog" />
							<span className="text">Administration</span>
						</button>
					)}
					<button className="user-menu-popover-item logout" onClick={handleLogOut}>
						<Icon className="icon" size={16} icon="log-out" />
						<span className="text">Log Out</span>
					</button>
				</>
			}
		>
			<Button
				icon="more"
				type="tertiary"
				size="sm"
				className="user-menu-button"
				onClick={() => setIsOpen(true)}
			/>
		</Popover>
	);
};

export const Sidebar = ({
	permissions,
	auth,
	history,
	isMenuFloated,
	setMenuFloat,
	setModalToShow,
	className = '',
}) => {
	const reloadUserPhotos = useSelector(state => state.route.updateLocalStorageKey);
	const loggedUserData = localStorage.getItem('loggedUserData');
	let hasBuildingAlerts = JSON.parse(loggedUserData)?.buildingAlerts?.length > 0;
	const imageUrl = JSON.parse(loggedUserData)?.personPhotoUrl;

	useEffect(() => {
		setImageLink(imageUrl);
	}, [reloadUserPhotos, imageUrl]);

	const [imageLink, setImageLink] = useState(null);

	const closeMenuIfFloating = () => {
		if (isMenuFloated) setMenuFloat(false);
	};

	const {
		isOn,
		names: { SITE_IS_INTERNAL, SITE_IS_TN, SITE_IS_FIRST_RESPONDER, ENV_DEV_ONLY },
	} = flags;

	const getUserFullName = () => {
		const loggedUserData = localStorage.getItem('loggedUserData');
		const personName = JSON.parse(loggedUserData).person.name;

		return `${personName.givenName} ${personName.familyName}`;
	};

	const dispatch = useDispatch();
	const isPrepareOpen = useSelector(state => state.route.isPrepareOpen);
	const isDocumentationOpen = useSelector(state => state.route.isDocumentationOpen);
	const handleCollapse = (text = null) => {
		if (text === 'Prepare') {
			dispatch({ type: 'IS_PREPARE_OPEN', payload: !isPrepareOpen });
			dispatch({
				type: 'IS_DOCUMENTATION_OPEN',
				payload: false,
			});
			return;
		}
		if (text === 'Documentation') {
			dispatch({
				type: 'IS_DOCUMENTATION_OPEN',
				payload: !isDocumentationOpen,
			});
			dispatch({ type: 'IS_PREPARE_OPEN', payload: false });
			return;
		}
		dispatch({ type: 'IS_PREPARE_OPEN', payload: false });
		dispatch({ type: 'IS_DOCUMENTATION_OPEN', payload: false });
	};

	const selectedMenuItem = useMemo(() => {
		const currentPathName = window.location.pathname;
		const secondSlashIndex = currentPathName.indexOf('/', 1); // Start searching after the first character
		const currentPathModified =
			secondSlashIndex > -1
				? currentPathName.substring(0, secondSlashIndex + 1)
				: currentPathName;
		const matchedRoute = routes.find(route => route.link.startsWith(currentPathModified));

		return matchedRoute && matchedRoute.parentPath
			? matchedRoute.parentPath
			: matchedRoute.link;
	}, [window.location.pathname]);

	const isSidebarMenuSelected = pathName => pathName === selectedMenuItem;

	// EIT-4104: Lockout TN sites from alarms.
	if (isOn(SITE_IS_TN)) {
		hasBuildingAlerts = false;
	}

	if (!auth) return null;
	return (
		<div
			className={[
				'app-sidebar-container',
				isMenuFloated ? 'floating' : 'header-padding',
				className || '',
			].join(' ')}
		>
			<div className="navigation">
				<img
					className="sidebar-logo"
					alt="Navigate360 Emergency Management"
					src={logoNavigate360}
				/>
				{(checkPermission('respond_view', permissions) || hasBuildingAlerts) && (
					<AlarmButton
						permissions={permissions}
						setModalToShow={() => setModalToShow('activateAlarmModal')}
					/>
				)}
				<div className="navigation-items">
					<SidebarNavLink
						text="Home"
						icon="home"
						path="/"
						onClick={closeMenuIfFloating}
						isSelected={isSidebarMenuSelected('/')}
					/>
					{(checkPermission('drilllogs_view', permissions) ||
						checkPermission('safety_plans_view', permissions) ||
						checkPermission('site_assess_view', permissions)) &&
						!isOn(SITE_IS_FIRST_RESPONDER) && (
							<SidebarNavLinkCollapse
								text="Prepare"
								icon="lifesaver"
								isOpen={isPrepareOpen}
								onCollapse={() => handleCollapse('Prepare')}
							>
								{checkPermission('drilllogs_view', permissions) && (
									<SidebarNavLink
										text="Drills"
										path="/drillsV2"
										isSubItem
										onClick={closeMenuIfFloating}
										isSelected={isSidebarMenuSelected('/drillsV2')}
									/>
								)}
								{checkPermission('safety_plans_view', permissions) && (
									<SidebarNavLink
										text="Safety Plans"
										path="/safety-plans"
										isSubItem
										onClick={closeMenuIfFloating}
										isSelected={isSidebarMenuSelected('/safety-plans')}
									/>
								)}
								{checkPermission('site_assess_view', permissions) && (
									<SidebarNavLink
										text="Assess"
										path="/assessV2"
										isSubItem
										onClick={closeMenuIfFloating}
										isSelected={isSidebarMenuSelected('/assessV2')}
									/>
								)}
							</SidebarNavLinkCollapse>
						)}
					{(checkPermission('document_view', permissions) ||
						checkPermission('call_list_view', permissions) ||
						checkPermission('flipcharts_view', permissions)) &&
						!isOn(SITE_IS_FIRST_RESPONDER) && (
							<SidebarNavLinkCollapse
								text="Documentation"
								icon="folder-open"
								isOpen={isDocumentationOpen}
								onCollapse={() => handleCollapse('Documentation')}
							>
								{checkPermission('document_view', permissions) && (
									<SidebarNavLink
										text="Binders and Documents"
										path="/binders"
										isSubItem
										onClick={closeMenuIfFloating}
										isSelected={isSidebarMenuSelected('/binders')}
									/>
								)}
								{checkPermission('call_list_view', permissions) && (
									<SidebarNavLink
										text="Call Lists"
										path={CALLLIST_ROUTES.LISTINGV2}
										isSubItem
										onClick={closeMenuIfFloating}
										isSelected={isSidebarMenuSelected(
											CALLLIST_ROUTES.LISTINGV2,
										)}
									/>
								)}
								{checkPermission('flipcharts_view', permissions) && (
									<SidebarNavLink
										text="Flipcharts"
										path="/flipcharts"
										isSubItem
										onClick={closeMenuIfFloating}
										isSelected={isSidebarMenuSelected('/flipcharts')}
									/>
								)}
							</SidebarNavLinkCollapse>
						)}
					{checkPermission('maps_view', permissions) && !isOn(SITE_IS_FIRST_RESPONDER) && (
						<SidebarNavLink
							text="Maps"
							icon="map"
							path="/maps"
							onClick={() => {
								closeMenuIfFloating();
								handleCollapse();
							}}
							isSelected={isSidebarMenuSelected('/maps')}
						/>
					)}
					{(checkPermission('respond_view', permissions) ||
						checkPermission('reunification_view', permissions) ||
						hasBuildingAlerts) &&
						!isOn(SITE_IS_FIRST_RESPONDER) && (
							<SidebarNavLink
								text="Rapid Alarm"
								icon="notifications"
								path="/rapid-alarms"
								onClick={() => {
									closeMenuIfFloating();
									handleCollapse();
								}}
								isSelected={isSidebarMenuSelected('rapid-alarms')}
							/>
						)}
					{isOn(SITE_IS_INTERNAL) &&
						isOn(ENV_DEV_ONLY) &&
						permissions.isSysAdmin === true && (
							<SidebarNavLink
								text="Components"
								path="/components-v2"
								onClick={() => {
									closeMenuIfFloating();
									handleCollapse();
								}}
								isSelected={isSidebarMenuSelected('/components-v2')}
							/>
						)}
				</div>
			</div>
			<div className="footer">
				<div
					className="user-signature"
					onClick={() => {
						history.push('/users/myprofile');
					}}
				>
					<Avatar name={getUserFullName()} image={imageLink} size="lg" />
					<span title={getUserFullName()}>{getUserFullName()}</span>
				</div>
				<UserMenuButton
					isMenuFloated={isMenuFloated}
					history={history}
					permissions={permissions}
					handleCollapse={handleCollapse}
				/>
			</div>
		</div>
	);
};
