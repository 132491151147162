import React, {useState, useEffect} from 'react';

export default function PermissionCheckRoutes({match})
{
    const userId = match.params.userId;
    const districtId = match.params.districtId;
    const [checkedPermission, setCheckedPermission] = useState('please click from list');
    const userModules = [
        {
            'link' : `user_view`, 
            'title' : 'User View'
        }
        ,
        {
            'link' : `user_edit`, 
            'title' : 'User Edit'
        }
        ,
        {
            'link' : `call_list_view`, 
            'title' : 'Call lists View'
        }
        ,
        {
            'link' : `call_list_edit`, 
            'title' : 'Call lists Edit'
        }
        ,
        {
            'link' : `respond_view`, 
            'title' : 'Respond View'
        }
        ,
        {
            'link' : `respond_edit`, 
            'title' : 'Respond Edit'
        }
        ,
        {
            'link' : `flipcharts_view`, 
            'title' : 'Flipcharts View'
        }
        ,
        {
            'link' : `flipcharts_edit`, 
            'title' : 'Flipcharts Edit'
        }
        ,
        {
            'link' : `reunification_view`, 
            'title' : 'Reunification View'
        }
        ,
        {
            'link' : `reunification_edit`, 
            'title' : 'Reunification Edit'
        }
    ];

    /* 
    Safety plan   
    Drill Logs
    Assess
    
    Call List //sidebarCallList
    Documents // sidebarDocuments
    Maps & Floorplans //sidebarMaps
    Virtual Binders // sidebarBinders
    Flipcharts // sidebarFlipcharts
    Scenarios // sidebarScenarios
    Respond // sidebarRespond
    Reunification // sidebarReunification   
    */ 
    
    
    const url = process.env.API_URL;
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    
    const loadUrl = (elem, listIndex) => 
    {
        elem.preventDefault();
        
        setCheckedPermission('checking...');

        fetch(`${url}/users/testpermissions/${userId}/${districtId}/${userModules[listIndex].link}`, {
            method : 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(resp => {
            let textPermission = "" 
            const permissionDetails = resp.data.data_url.permission; // + ' to ' +resp.data.data_url.user_id + ' in ' + resp.data.data_url.building_id
            if (resp.statusCode == 202) {
                textPermission = "ALLOWED for " + permissionDetails; 
            } else {
                // setCheckedPermission('DENIED Permission for XX on YY');
                textPermission = "DENIED for " + permissionDetails;
            }
            setCheckedPermission(textPermission);
        });

    };

    return (
        <>
            <h2>Permission Check Real Routes</h2>

            <h4>
                User ID <code>{userId}</code> ON District ID <code>{districtId}</code>
            </h4>

            <h3>
            Test Direct access to the Modules
            </h3>

            <div style={{'display':'flex'}}>
                
                <div style={{'backgroundColor':'#EEE','padding':'0.5rem', 'min-width':'35%'}}>
                    <ul>
                        {
                        userModules.map((item, index) => {
                            return <>
                                <li>
                                    <a onClick={(e) => loadUrl(e,index) } href="#">
                                        {item.title}
                                    </a>
                                </li>
                            </>
                        })
                        }
                    </ul>

                </div>

                <div style={{'backgroundColor':'#DDD','padding':'0.5rem 1rem'}}>
                    <h4>
                        {checkedPermission}
                    </h4>
                </div>

            </div>

            
        </>
    )
}
    