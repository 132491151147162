import React from 'react';
import {Animated} from 'componentsV2';
import '../css/ShapeContent.scss'

export const NoShapeContentState = ({icon, header, description}) => (
  <Animated>
      <div className='empty-state-body'>
        <div className="header-icon">
          <img src={icon} className="icon"/>
        </div>
        <div className="body-container">
          <h3 className="title">{header}</h3>
          <span className="description">{description}</span>
        </div>
    </div>
  </Animated>
);
