import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dialog, Classes, Icon } from '@blueprintjs/core';
import { Input } from 'components/Input';
import { LoadingSpinner } from 'components/LoadingSpinner';

const Callout = styled.div`
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    background: #e8f2f9;
    margin-bottom: 16px;
`;

const HrWrapper = styled.hr`
    widht: 90%;
`;

const CreateDialog = styled(Dialog)`
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    width: 300px;
`;

const DialogFooterWrapper = styled.div`
    display: flex;
    justify-content: end;
    margin-right: 10px;
    margin-top: -15px;
`;

class CopyAssessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCopy: '',
        };
    }

    updateFields = (key, val) => {
        this.setState({ [key]: val });
    };

    copyTemplate = () => {
        const { nameCopy } = this.state;
        const {
            copyTemplate: { state, year, notes },
        } = this.props;
        const {
            primaryBuildingId,
            closeEdit,
            getData,
            proprietary,
        } = this.props;
        this.setState({ loading: true });
        const token = JSON.parse(localStorage.getItem('user')).jwt;
        fetch(`${process.env.API_URL}/templates/${this.props.copyTemplate.id}/copy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'app/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                name: nameCopy,
                year,
                notes,
                state,
                proprietary,
                buildingId: primaryBuildingId,
            }),
        })
            .then(res => res.json())
            .then(resp => {
                if (resp.statusCode === 201) {
                    this.setState({ loading: false });
                    closeEdit();
                    return getData();
                }
                return null;
            });
    };

    render() {
        const { closeEdit } = this.props;
        // eslint-disable-next-line react/destructuring-assignment
        const { name } = this.props.copyTemplate;
        const { loading } = this.state;
        return (
            <CreateDialog
                isOpen
                onClose={closeEdit}
                title="Copy Template"
                {...this.state}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div
                        style={{
                            marginBottom: 15,
                            fontSize: 16,
                        }}
                    >
                        {name}
                    </div>
                    <Callout
                        style={{ height: '60px', width: '100%' }}
                        className=".bg-primary"
                    >
                        <Icon
                            style={{
                                marginRight: 10,
                                marginLeft: 10,
                                color: '#137CBD',
                            }}
                            icon="info-sign"
                        />
                        Give your copied template a new name. Please refrain
                        from using a duplicate template name.
                    </Callout>
                    {loading && <LoadingSpinner />}
                    {!loading && (
                        <>
                            <Input
                                style={{ fontFamily: 'Nunito Sans' }}
                                placeholder="Enter new template name"
                                label="Template"
                                large
                                onChange={e =>
                                    this.updateFields(
                                        'nameCopy',
                                        e.target.value,
                                    )
                                }
                            />
                            <HrWrapper />
                        </>
                    )}
                </div>
                <DialogFooterWrapper>
                    <Button
                        style={{
                            marginRight: 16,
                            height: 40,
                            width: 68,
                            fontFamily: 'Nunito Sans',
                        }}
                        onClick={closeEdit}
                        text="Cancel"
                        intent="none"
                    />
                    <Button
                        style={{
                            height: 40,
                            width: 68,
                            background: '#9F1C3A',
                            fontFamily: 'Nunito Sans',
                        }}
                        onClick={this.copyTemplate}
                        text="Copy"
                        intent="danger"
                    />
                </DialogFooterWrapper>
            </CreateDialog>
        );
    }
}

CopyAssessment.propTypes = {
    copyTemplate: PropTypes.object,
    closeEdit: PropTypes.func,
    name: PropTypes.any,
    primaryBuildingId: PropTypes.any,
    getData: PropTypes.func,
    proprietary: PropTypes.any,
};

export default CopyAssessment;
