import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { Callout } from '../Callout';
import { CardModal } from './CardModal';
import { Dropdown } from 'components/Dropdown';
import { BuildingsService, DrillsService } from 'services';

const Wrapper = styled.div`
	width: 400px;

	h5 {
		font-size: 15px;
		margin-top: 16px;
		font-weight: 700;
		line-height: 22px;
		font-style: normal;
		letter-spacing: 0.4px;
		font-family: Merriweather;
	}

	.callout {
		margin: 16px 0;
	}
`;

const BASE_STATE = {
	loading: true,
	showConfirmationModal: true,
	schools: null,
	schoolToCopyInto: null,
};

export class CopyDrillScheduleModal extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		BuildingsService.getAll()
			.then(schools => {
				this.setState({
					...BASE_STATE,
					schools,
					loading: false,
				});
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Could not complete this request. Please try again.',
				});
			});
	}

	copyDrills = () => {
		const {
			setModalToShow,
			onCopySuccess,
			selectedSite,
			selectedSchoolYear,
		} = this.props;
		const { schoolToCopyInto } = this.state;

		DrillsService.copyDrills(
			selectedSchoolYear?.value,
			selectedSite?.id,
			schoolToCopyInto?.id,
		)
			.then(data => {
				setModalToShow(null);
				onCopySuccess && onCopySuccess(data);
				Swal.fire({
					icon: 'success',
					title: 'Success!',
					text: 'Your drills have been successfully copied.',
				});
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text:
						'Could not copy drills at this time. Please try again.',
				});
			});
	};

	render() {
		const {
			loading,
			showConfirmationModal,
			schools,
			schoolToCopyInto,
		} = this.state;
		const { setModalToShow, selectedSite, selectedSchoolYear } = this.props;

		if (loading) return null;

		if (showConfirmationModal) {
			return (
				<CardModal
					isVisible
					setIsVisible={setModalToShow}
					title="Copy drill schedule"
					actionTitle="Confirm"
					onActionClick={() =>
						this.setState({ showConfirmationModal: false })
					}
				>
					<p className="modal-text">
						You are about to copy scheduled drills over to a
						different site. This will not replace or delete any
						currently existing scheduled drills in that site.
					</p>
				</CardModal>
			);
		}

		return (
			<CardModal
				isVisible
				setIsVisible={setModalToShow}
				title="Copy drill schedule"
				actionTitle="Copy drills"
				onActionClick={this.copyDrills}
				actionBtnDisabled={!schoolToCopyInto}
			>
				<Wrapper>
					<h5>
						{selectedSchoolYear?.label}: {selectedSite?.name}
					</h5>

					<Callout type="info">
						Copying scheduled drills over to a different site will
						not replace or delete any currently existing scheduled
						drills in that site. If you wish for the two sites to
						have the same drill schedule, please make sure that no
						other drills have been scheduled in the other site.
					</Callout>

					<Dropdown
						label="Copy scheduled drills into"
						placeholder="Select school"
						value={schoolToCopyInto}
						options={schools}
						onChange={item =>
							this.setState({ schoolToCopyInto: item })
						}
					/>
				</Wrapper>
			</CardModal>
		);
	}
}

CopyDrillScheduleModal.propTypes = {
	setModalToShow: PropTypes.func,
	onCopySuccess: PropTypes.func,
	selectedSite: PropTypes.object,
	selectedSchoolYear: PropTypes.object,
};
