import React from "react";
import { Icon } from "@blueprintjs/core";
import PropTypes from 'prop-types';
import './css/SectionLabel.scss';

export const SectionLabel = ({
    title,
    showLeftArrow,
    onClickBackBtn,
    rightElement,
    children,
    footerElements,
    textColor
}) => (
    <div className="section-label-container">
        <section className="header">
            <div className="left-side"
                style={{
                    color: (textColor ? textColor: undefined) 
                }}
            >
                {showLeftArrow && 
                    <Icon 
                        icon='arrow-left' 
                        className="icon" 
                        style={{
                            color: (textColor ? textColor: undefined)
                        }}
                        onClick={onClickBackBtn} 
                    />
                }
                <p className="title"
                    style={{
                        color: (textColor ? textColor: undefined)
                    }}
                >
                    {title}
                </p>
            </div>
            <div className="right-side">
                {rightElement && rightElement}
            </div>
        </section>
        <section className="body">
            {children}
        </section>
        <section className="footer">
            {footerElements}
        </section>
    </div>
)

SectionLabel.propTypes = {
    title: PropTypes.string.isRequired,
    showLeftArrow: PropTypes.bool,
    onClickBackBtn: PropTypes.func.isRequired,
    rightElement: PropTypes.element,
    footerElements: PropTypes.element,
    textColor: PropTypes.string
}