import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { downArrow, upArrow } from 'assets/icons';
import { noResult } from '../../componentsV2/EmptyState/icons';
import InputWrapper, { ElementWrapper } from '../InputWrapper/InputWrapper';
import './css/Dropdown.scss';
import { EmptyState, Input } from '../';

export const Dropdown = ({
	label = '',
	placeholder = 'Select an option...',
	isRequired,
	value,
	options = null,
	onChange,
	disabled = false,
	optionLabel = 'label',
	optionValue = 'value',
	error = false,
	errorMessage = null,
	onToggleOptions = null,
	width = null,
	labelState,
	disabledOptions = {},
	loading = false,
	hasSearch = false,
	hasEmptyState = false,
	searchMessageTitle = 'No results',
	searchMessageSubtitle = '',
	className = '',
}) => {
	const dropDownRef = useRef();
	const [showOptions, setShowOptions] = useState(false);
	const [itemsList, setItemsList] = useState([]);

	useEffect(() => {
		setItemsList(options);
	}, [options]);

	const selectItem = item => {
		if (disabledOptions.length > 0 && disabledOptions.indexOf(item[optionValue]) > -1) {
			return false;
		}
		if (value != null && item[optionValue] == value[optionValue]) {
			return false;
		}

		if (!onChange) {
			throw Error("the 'onChange' prop/event has not been defined.");
		}

		onChange(item);
		setShowOptions(false);
	};

	const closeOnOutsideClick = useCallback(event => {
		if (!dropDownRef.current?.contains(event.target)) {
			setShowOptions(false);
		}
	}, []);

	const getItemClass = itmVal => {
		const itemClass = 'dropdown-items';
		if (disabledOptions.length > 0 && disabledOptions.indexOf(itmVal[optionValue]) > -1) {
			return `${itemClass} disabled-option`;
		}
		if (value != null && itmVal[optionValue] === value[optionValue]) {
			return `${itemClass} active`;
		}
		return itemClass;
	};

	useEffect(() => {
		if (dropDownRef) {
			window.addEventListener('click', closeOnOutsideClick);
		}
		return () => window.removeEventListener('click', closeOnOutsideClick);
	}, []);

	useEffect(() => {
		if (onToggleOptions !== null) {
			onToggleOptions(showOptions);
		}
	}, [showOptions]);

	const hasValue = !(!value || Object.keys(value).length === 0);
	const dropDownValue =
		!value || Object.keys(value).length === 0 ? placeholder : value[optionLabel];

	const handleSearch = e => {
		const search = e.target.value;

		const selectFiltered = options.filter(item =>
			item[optionLabel]?.toLowerCase().includes(search.toLowerCase()),
		);

		setItemsList(selectFiltered);
	};

	return (
		<>
			<div className={`dropdown__wrapper ${className}`} ref={dropDownRef}>
				<InputWrapper
					label={label}
					isRequired={isRequired}
					labelState={labelState}
					error={error}
				>
					{loading && 'Loading...'}
					{!loading && (
						<ElementWrapper width={width}>
							<div
								className={`dropdown-trigger-common dropdown-trigger ${
									disabled ? 'disabled' : ''
								} ${showOptions ? 'expanded' : ''} ${error ? 'error' : ''}`}
								role="button"
								onClick={() => !disabled && setShowOptions(!showOptions)}
							>
								<div className={`dropdown-value ${!hasValue ? 'placeholder' : ''}`}>
									{dropDownValue}
								</div>

								<img alt="" src={showOptions ? upArrow : downArrow} />
							</div>

							{itemsList && (
								<ul
									className={`dropdown-menu ${
										itemsList < 1 && hasEmptyState ? 'dropdown-empty-state' : ''
									}`}
									style={{ display: showOptions ? 'block' : 'none' }}
								>
									{hasSearch && (
										<li>
											<Input
												placeholder="Search..."
												onChange={handleSearch}
												className="input-search"
												icon="search"
											/>
										</li>
									)}

									{hasEmptyState && itemsList.length === 0 ? (
										<li>
											<EmptyState
												icon={noResult}
												header={searchMessageTitle}
												description={searchMessageSubtitle}
												showCircles={false}
											/>
										</li>
									) : (
										itemsList?.map((item, index) => (
											<li
												key={`${item[optionValue]}-${index}`}
												onClick={() => selectItem(item)}
												{...(item?.indent
													? {
															className: `indent-${item.indent}`,
													  }
													: {})}
											>
												<a className={getItemClass(item)}>
													{item[optionLabel]}
												</a>
											</li>
										))
									)}
								</ul>
							)}
						</ElementWrapper>
					)}
				</InputWrapper>
			</div>

			{errorMessage && <div className="dropdown-error-message">{errorMessage}</div>}
		</>
	);
};

Dropdown.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	isRequired: PropTypes.bool,
	value: PropTypes.object,
	options: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	optionLabel: PropTypes.string,
	optionValue: PropTypes.string,
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	onToggleOptions: PropTypes.func,
	width: PropTypes.string,
	labelState: PropTypes.string,
	disabledOptions: PropTypes.array,
	loading: PropTypes.bool,
	hasSearch: PropTypes.bool,
	hasEmptyState: PropTypes.bool,
	searchMessageTitle: PropTypes.string,
	searchMessageSubtitle: PropTypes.string,
	className: PropTypes.string,
};
