/* eslint-disable import/no-unresolved */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

import { CardModal } from 'components/Modals/CardModal';
import { circleCheckMark, cirlceWarning } from 'assets/icons';

const Wrapper = styled.div`
	.loading-wrapper {
		align-items: center;
		display: flex;
		flex: 1;
		justify-content: center;
	}
	.editor-header {
		display: flex;
		margin-bottom: 12px;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.bold-header {
			margin: 0px;
			font-family: Merriweather;
			font-style: normal;
			font-weight: bold;
			font-size: 15px;
			color: #10161a;
		}

		.regular-header {
			color: #000000;
			font-size: 16px;
			line-height: 22px;
			font-style: normal;
			font-weight: normal;
			font-family: Nunito Sans;
		}

		.action-and-toastr-cont {
			display: flex;
			align-items: center;

			.action-toastr {
				margin-right: 8px;

				img {
					width: 10px;
					height: 10px;
					margin-top: -1px;
					margin-right: 4px;
				}
			}

			.error-toastr {
				color: #db3737;
				padding: 0 5px;
				border-radius: 10px;
				background-color: #fcebeb;
			}

			button {
				color: white;
				display: flex;
				padding: 6px 0;
				cursor: pointer;
				border-radius: 3px;
				align-items: center;
				box-sizing: border-box;
				justify-content: center;
				background-color: #9f1c3a;
				border: 1px solid rgba(16, 22, 26, 0.2);
				height: 40px;
				padding: 10px;
			}
		}
	}
`;

class Wysiwyg extends React.Component {
	editorRef = React.createRef();

	timeout = null;

	state = {
		showActionModal: false,
		errorToastrMessage: null,
		successToastrMessage: null,
	};

	handleDebounce = () => {
		const { onDebounce = () => {}, debounceWait = 300 } = this.props;

		// check if existing timer exists
		if (this.timeout) {
			// clearing the timer because we don't need to exec the previous run
			clearTimeout(this.timeout);
		}

		// preparing to call the new run
		this.timeout = setTimeout(
			() => onDebounce(this.editorRef.current.getContent()),
			debounceWait,
		);
	};

	setActionModal = show => {
		this.setState({ showActionModal: show });
	};

	handleActionClick = () => {
		const { actionHandler } = this.props;
		if (actionHandler?.modalTitle && actionHandler?.modalBody) {
			// show the modal, then run the action
			this.setActionModal(true);
		} else {
			// just run the action
			this.execDeveloperOnClick();
		}
	};

	execDeveloperOnClick = () => {
		const { showActionModal } = this.state;
		const { actionHandler = {} } = this.props;

		if (showActionModal) {
			this.setActionModal(false);
		}

		actionHandler
			.onClick()
			.then(() => {
				this.setState(
					{
						successToastrMessage: actionHandler?.successLabel,
					},
					() => {
						setTimeout(() => {
							this.setState({ successToastrMessage: null });
						}, 3000);
					},
				);
			})
			.catch(() => {
				this.setState(
					{
						errorToastrMessage: actionHandler?.errorLabel,
					},
					() => {
						setTimeout(() => {
							this.setState({ errorToastrMessage: null });
						}, 3000);
					},
				);
			});
	};

	render() {
		const { label, header, initialValue, actionHandler = {} } = this.props;

		const { actionTitle, modalTitle, modalBody } = actionHandler;

		const {
			showActionModal,
			errorToastrMessage,
			successToastrMessage,
		} = this.state;

		return (
			<Wrapper className="wysiwyg">
				<div className="editor-header">
					{label && (
						/* editor label */
						<div className="regular-header">{label}</div>
					)}

					{header && (
						/* editor label */
						<h2 className="bold-header">{header}</h2>
					)}

					<div className="action-and-toastr-cont">
						{(successToastrMessage || errorToastrMessage) && (
							/* show toastr message after the button has been clicked */
							<div
								className={`action-toastr ${
									successToastrMessage ? 'success' : 'error'
								}-toastr`}
							>
								{successToastrMessage ? (
									<img alt="" src={circleCheckMark} />
								) : (
									<img alt="" src={cirlceWarning} />
								)}
								{successToastrMessage || errorToastrMessage}
							</div>
						)}

						{actionTitle && (
							<button
								type="button"
								onClick={this.handleActionClick}
							>
								{actionTitle}
							</button>
						)}
					</div>
				</div>

				<Editor
					onInit={(event, editor) => {
						this.editorRef.current = editor;
					}}
					init={{
						height: 315,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code table ',
						],
						toolbar:
							'undo redo formatselect bold italic alignleft aligncenter alignright justify bullist numlist ' +
							'table variableMenu exportSection completeSection',
						setup: editor => {
							editor.on(
								'Paste Change input Undo Redo',
								this.handleDebounce,
							);
						},
					}}
					initialValue={initialValue}
				/>
				<CardModal
					isVisible={showActionModal}
					setIsVisible={this.setActionModal}
					title={modalTitle}
					actionTitle="Continue"
					onActionClick={this.execDeveloperOnClick}
				>
					<p className="modal-text">{modalBody}</p>
				</CardModal>
			</Wrapper>
		);
	}
}

Wysiwyg.propTypes = {
	header: PropTypes.string,
	label: PropTypes.string,
	initialValue: PropTypes.any,
	actionHandler: PropTypes.object,
	onDebounce: PropTypes.func,
	debounceWait: PropTypes.number,
};

export { Wysiwyg };
