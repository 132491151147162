import React from 'react';
import { Carousel } from 'componentsV2';
import {
	carouselImageFlipcharts as img1,
	carouselImageMaps as img2,
	carouselImageDrills as img3,
} from 'assets/images';

export const CarouselPattern = () => {
	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: 'repeat(2, 1fr)',
				backgroundColor: 'cadetblue',
				padding: '1em',
			}}
		>
			<Carousel>
				<img alt="a" src={img1} />
				<img alt="b" src={img2} />
				<img alt="c" src={img3} />
			</Carousel>
			<Carousel interval="10000" onSlid={console.log}>
				<img alt="a" src={img1} />
				<img alt="b" src={img2} />
				<img alt="c" src={img3} />
			</Carousel>
		</div>
	);
};
