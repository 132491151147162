import React from 'react';
import { Modal, Avatar } from 'componentsV2';
import '../css/MapInfo.scss';
import PropTypes from 'prop-types';

const MapItem = ({ title, value }) => (
	<div className="row rowItem">
		<div className="col-md-12">
			<span className="label"> {title} </span>
			<span className="infoValue"> {value} </span>
		</div>
	</div>
);

export const MapInfo = ({ mapDetails, loading, closeModal }) => {
	const createdBy = (
		<Avatar
			size="md"
			name={
				mapDetails?.createdBy?.photo
					? null
					: `${mapDetails?.createdBy?.first_name} ${mapDetails?.createdBy?.last_name}`
			}
			label={`${mapDetails?.createdBy?.first_name} ${mapDetails?.createdBy?.last_name}`}
			supportingText={mapDetails?.createdBy?.title}
			image={mapDetails?.createdBy?.photo ? mapDetails?.createdBy.photo : null}
		/>
	);

	const modifiedBy = (
		<Avatar
			size="md"
			name={
				mapDetails?.modifiedBy?.photo
					? null
					: `${mapDetails?.modifiedBy?.first_name} ${mapDetails?.modifiedBy?.last_name}`
			}
			label={`${mapDetails?.modifiedBy?.first_name} ${mapDetails?.modifiedBy?.last_name}`}
			supportingText={mapDetails?.modifiedBy?.title}
			image={mapDetails?.modifiedBy?.photo ? mapDetails?.modifiedBy?.photo : null}
		/>
	);

	const coordinates = (
		<>
			Latitude {mapDetails?.building?.lat} <br /> Longitude {mapDetails?.building?.long}
		</>
	);

	return (
		<>
			<Modal
				isOpen={true}
				onClose={() => closeModal()}
				title="Map info"
				size="small"
				loading={loading || !mapDetails}
				confirmBtn=""
				cancelBtn=""
				scrollContent={false}
				showCloseButton={true}
				customClassName={'edit-legend-container map-container-modals'}
			>
				<div className="MapInfo__wrapper">
					<MapItem title="Site name" value={mapDetails?.building?.name} />

					<MapItem title="Location" value={mapDetails?.building?.address} />

					<MapItem title="Location coordinates" value={coordinates} />

					<MapItem title="Created by" value={createdBy} />

					<MapItem title="Date created" value={mapDetails?.formattedDateCreated} />

					<MapItem
						title="Last modified by"
						value={mapDetails?.formattedDateModified ? modifiedBy : 'N/A'}
					/>

					<MapItem
						title="Date last modified"
						value={
							mapDetails?.formattedDateModified
								? mapDetails.formattedDateModified
								: 'N/A'
						}
					/>
				</div>
			</Modal>
		</>
	);
};
MapInfo.propTypes = {
	mapDetails: PropTypes.any,
};
