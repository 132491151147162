import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dialog, Classes, Icon, MenuItem } from '@blueprintjs/core';
import { LabelText } from 'components/Forms/shared.js';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Select } from '@blueprintjs/select';
import Swal from 'sweetalert2';

const SelectWrap = styled(Select)`
	display: block;
	width: 100%;
	.bp3-popover-target {
		display: block;
		width: 100%;
	}
`;

const HrWrapper = styled.hr`
	widht: 90%;
`;

const CreateDialog = styled(Dialog)`
	width: 300px;
`;

const DropdownWrap = styled.div`
	padding: 10px;
	width: 100%;
	background: #ffffff;
	border: 1px solid #d8e1e8;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
`;

const DialogFooterWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 10px;
`;

class ImportAssess extends React.Component {
	state = {
		loading: false,
		templates: [],
		selectedTemplate: null,
	};

	getData = () => {
		this.setState({ loading: true });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		Promise.all([
			fetch(
				`${
					process.env.API_URL
				}/templates?includeUnpublished=false&getCore=true`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			),
		])
			.then(([res1]) => Promise.all([res1.json()]))
			.then(([resp1]) => {
				const {
					data: { templates },
				} = resp1;
				this.setState({
					loading: false,
					templates,
				});
			});
	};

	importNewTemplate = () => {
		this.setState({ loading: true });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { selectedTemplate } = this.state;
		const { id } = selectedTemplate;
		return fetch(`${process.env.API_URL}/templates/${id}/import`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				id: selectedTemplate.id,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					});
				}
			});
	};

	importTemplates = item => (
		<MenuItem
			key={item.id}
			text={item.name}
			onClick={() => this.setState({ selectedTemplate: item })}
		/>
	);

	handleClick = () => {
		const { closeImportAssess, importTemplateCallback } = this.props;
		this.importNewTemplate()
			.then(() => {
				closeImportAssess();
			})
			.then(() => {
				importTemplateCallback();
			});
	};

	componentDidMount() {
		return this.getData();
	}

	render() {
		const { closeImportAssess } = this.props;
		const { loading, templates, selectedTemplate } = this.state;
		return (
			<CreateDialog
				isOpen
				onClose={closeImportAssess}
				title="Import template"
				{...this.state}
			>
				<div className={Classes.DIALOG_BODY}>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<div
								style={{
									backgroundColor: '#E8F2F9',
									borderRadius: 3,
									padding: 5,
								}}
								className="d-flex"
							>
								<Icon
									style={{
										color: '#137CBD',
										marginRight: 8,
										marginLeft: 4,
										marginTop: 8,
									}}
									icon="info-sign"
								/>
								<p
									className="col2"
									style={{
										height: 25,
										width: 440,
										wordSpacing: 2,
										fontWeight: 400,
										fontFamily: 'Nunito Sans',
										fontSize: 15,
									}}
								>
									Please allow 1-2 minutes for the system to
									finish importing template
								</p>
							</div>
							<LabelText className="mt-2">
								Navigate360 Templates
							</LabelText>
							<SelectWrap
								items={templates}
								itemRenderer={this.importTemplates}
								filterable={false}
								className="w-100"
							>
								<DropdownWrap>
									<div>
										{selectedTemplate
											? selectedTemplate.name
											: 'Select'}
									</div>
									<Icon icon="caret-down" />
								</DropdownWrap>
							</SelectWrap>
							<HrWrapper />
						</>
					)}
				</div>
				<DialogFooterWrapper>
					<Button
						style={{
							marginRight: 16,
							height: 40,
							width: 68,
						}}
						onClick={closeImportAssess}
						text="Cancel"
						intent="none"
					/>
					<Button
						style={{
							height: 40,
							width: 68,
							background: '#9F1C3A',
						}}
						onClick={this.handleClick}
						text="Import"
						intent="danger"
					/>
				</DialogFooterWrapper>
			</CreateDialog>
		);
	}
}

ImportAssess.propTypes = {
	templates: PropTypes.array,
	importNewTemplate: PropTypes.func,
	closeImportAssess: PropTypes.func,
	importTemplateCallback: PropTypes.func,
	history: PropTypes.object,
};

export default ImportAssess;
