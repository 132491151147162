/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import reduxCatch from 'redux-catch';

import createSagaMiddleware from 'redux-saga';
import autoSaveMiddleware from 'utilities/autosavemiddleware';
import rootSaga from './rootSaga';
import createReducer from './rootReducers';

const reduxErrorHandler = (error, getState, lastAction) => {
	if (process.env.NODE_ENV === 'production') {
		const state = getState();
		const { projectId } = state.builder;
		return fetch(`${process.env.API_URL}/errorpipe`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				error: error.stack.toString(),
				info: '',
				lastAction: JSON.stringify(lastAction) || '',
				state: `Project ID: ${projectId}`,
			}),
		});
	}
	console.warn(lastAction);
	return console.error(error);
};

export default function configureStore(initialState = {}) {
	const sagaMiddleware = createSagaMiddleware();
	const middlewares = [
		reduxCatch(reduxErrorHandler),
		sagaMiddleware,
		autoSaveMiddleware([
			'UPDATE_SUBSECTION',
			'REORDER_SECTIONS',
			'UPDATE_SECTION_TITLE',
			'UPDATE_SUBSECTION_TITLE',
			'UPDATE_TEMPLATE_NAME',
			'UPDATE_SECTION_CONTENT',
			'ADD_SUBSECTION',
			'ADD_SECTION',
			'REMOVE_SECTION',
			'REMOVE_SUBSECTION',
			'REMOVE_SUBSECTION_NEW',
			'REMOVE_SECTION_NEW',
			'ADD_DOCUMENT_UPLOAD',
			'FLIP_REUPLOAD',
			'ADD_FILE',
			'ADD_VALUE_FILE',
			'DELETE_FILE',
			'DELETE_VALUE_FILE',
			'ADD_BONUS_FILES',
			'MARK_SECTION_COMPLETE',
			'MARK_SUBSECTION_COMPLETE',
			'REMOVE_BONUS_FILE',
		]),
	];

	const enhancers = [applyMiddleware(...middlewares)];

	/* eslint-disable no-underscore-dangle, indent */
	const composeEnhancers =
		process.env.NODE_ENV !== 'production' &&
		typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
					shouldHotReload: false,
			  })
			: compose;

	/* eslint-enable */

	const store = createStore(
		createReducer(),
		initialState,
		composeEnhancers(...enhancers),
	);

	store.injectedReducers = {};
	sagaMiddleware.run(rootSaga);
	/* istanbul ignore next */
	if (module.hot) {
		module.hot.accept('./rootReducers', () => {
			store.replaceReducer(createReducer(store.injectedReducers));
		});
	}

	return store;
}
