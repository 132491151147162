import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { flags } from 'utilities';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { logoNavigate360, logoNavigateDark, classLinkLogo, cleverLogo } from 'assets/icons';
import { Toaster, Position, Intent } from '@blueprintjs/core';
import { Carousel, Button, Input } from 'componentsV2';
import { carouselImageFlipcharts, carouselImageMaps, carouselImageDrills } from 'assets/images';
import selectRoute from './selectors';
import EmailTimer from './EmailTimer';
import { UserService } from '../../services/UserService';
import './css/index.scss';
import { MaintenanceMode } from './MaintenanceMode';

class Login extends React.Component {
	state = {
		email: '',
		password: '',
		emailForget: '',
		validationErrors: '',
		loading: false,
		forgotPassword: false,
		selected: null,
		error: null,
		submitDisabled: false,
	};

	toasterRef = React.createRef();

	showToast = () => {
		this.toasterRef.current.show({
			message: (
				<>
					{' '}
					A reset link has been sent to your email address.{' '}
					<strong>Check your email now!</strong>
				</>
			),
			intent: Intent.SUCCESS,
		});
	};

	setField = (e, target) => this.setState({ [target]: e.target.value });

	checkUser = () => {
		const {
			route: { user },
			history,
		} = this.props;
		if (user) {
			return history.push('/');
		}
		return null;
	};

	submitLogin = e => {
		e.preventDefault();
		this.setState({ loading: true });
		const { email, password } = this.state;
		const apiUrl = process.env.API_URL;
		const { dispatch, history } = this.props;

		return fetch(`${apiUrl}/sign-in`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email,
				password,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (
					(resp.statusCode && resp.statusCode === 200) ||
					(resp.statusCode && resp.statusCode === 300)
				) {
					localStorage.removeItem('masqueradingUserToken');
					localStorage.removeItem('masqueradingUserData');
					if (resp.data.districtSelectToken) {
						dispatch({
							type: 'SET_DISTRICT_TOKEN',
							payload: resp.data.districtSelectToken,
						});
						localStorage.setItem('hasMultiDistricts', 1);
						return history.push('/switch-district');
					}

					localStorage.setItem('hasMultiDistricts', 0);
					if (resp.data.accessToken) {
						const { accessToken } = resp.data;
						return UserService.fetchMyInfo(accessToken)
							.then(({ data }) => {
								dispatch({ type: 'SET_USER_DATA', payload: data });
								dispatch({ type: 'SET_USER', payload: accessToken });
								dispatch({
									type: 'SET_CURRENT_DISTRICT',
									payload: data.primaryBuilding,
								});
								return history.push('/');
							})
							.catch(err =>
								this.setState({
									loading: false,
									error: err.error.description,
								}),
							);
					}
					this.setState({
						loading: false,
					});
				} else {
					switch (resp?.statusCode || 500) {
						case 400:
							if (resp.error.fields && resp.error.fields.length > 1) {
								// Password and email are missing
								return this.setState({
									loading: false,
									error: 'Please enter your email address and password.',
								});
							} else if (resp.error.fields && resp.error.fields.length === 1) {
								// Password or email is missing
								return this.setState({
									loading: false,
									error:
										resp.error.fields[0].field === 'login'
											? 'Please enter your email address.'
											: 'Please enter your password.',
								});
							} else {
								// Some weird case in which the response has no fields array?
								return this.setState({
									loading: false,
									error: 'An unkown error occurred. Please try again.',
								});
							}
						case 404:
						case 401:
							// Wrong email and/or password
							return this.setState({
								loading: false,
								error: 'Please check your credentials and try again.',
							});

						default:
							// Something weird happened so we show a more generic error message
							return this.setState({
								loading: false,
								error: 'An unkown error occurred. Please try again.',
							});
					}
				}
			});
	};

	submitForget = e => {
		e.preventDefault();
		const { emailForget } = this.state;
		// eslint-disable-next-line no-useless-escape
		const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		if (filter.test(emailForget)) {
			this.setState({ loading: true });
			return fetch(`${process.env.API_URL}/password-reset-emails`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: emailForget,
				}),
			})
				.then(res => res.json())
				.then(resp => {
					this.setState({ loading: false });
					if (resp.statusCode === 200 || resp.statusCode === 201) {
						this.showToast();
						this.setState({ submitDisabled: true });
						return;
						// return Swal.fire({
						// 	text: 'Reset Password Mail Sent Successfully.',
						// 	icon: 'success',
						// 	showConfirmButton: false,
						// });
					}
					return Swal.fire({
						text: resp.error.description
							? resp.error.description
							: 'Something went wrong',
						icon: 'error',
						confirmButtonText: 'Try again ',
					});
				});
		}
		this.setState({ validationErrors: '* Email is not valid.' });
	};

	componentDidMount() {
		this.checkUser();
	}

	componentDidUpdate() {
		this.checkUser();
	}

	render() {
		const {
			email,
			password,
			emailForget,
			loading,
			forgotPassword,
			selected,
			error,
			validationErrors,
			submitDisabled,
		} = this.state;
		const showForm = !loading && !forgotPassword;

		const {
			isOn,
			names: { SITE_IS_IN_MAINTENANCE },
		} = flags;

		const handleSubmitEmail = () => {
			this.setState({ submitDisabled: false });
		};

		const handleTimerEnd = () => {
			this.setState({ submitDisabled: false });
		};

		const message = "Didn't get an email? <strong>Wait a moment</strong>";

		return (
			<>
				{isOn(SITE_IS_IN_MAINTENANCE) ? (
					<MaintenanceMode>
						Emergency Management is undergoing a scheduled maintenance window starting
						on Thursday, July 11th and ending by close of business day on Friday, July
						12th.
					</MaintenanceMode>
				) : (
					<div className="login-wrapper">
						<div className="login">
							<div className="col-md-12">
								{loading && <LoadingSpinner />}
								{!loading && (
									<>
										<div className="text-center img-container">
											<img
												src={logoNavigate360}
												alt="Navigate360 Logo"
												className="navigate-logo"
												width="300"
											/>
										</div>
										<h5 className="sub-title">
											{!forgotPassword
												? 'Sign in to your account'
												: 'Password Recovery'}
										</h5>
										{showForm && (
											<form onSubmit={e => this.submitLogin(e)}>
												<Input
													label="Email"
													isRequired
													placeholder="Enter your email"
													large
													className="mb-3"
													value={email}
													onChange={e => this.setField(e, 'email')}
												/>
												<Input
													label="Password"
													isRequired
													placeholder="Enter your password"
													value={password}
													type="password"
													onChange={e => this.setField(e, 'password')}
												/>
												{error && (
													<p className="text-danger mb-2">{error}</p>
												)}
												<br />
												<Button
													text="Log In"
													large
													className="w-100"
													wrapperClass="login-btn"
													onClick={this.submitLogin}
												/>
												<a
													className="link-button d-flex justify-content-center mt-3"
													onClick={() => {
														const { history } = this.props;
														return history.push({
															pathname: '/password-recovery',
															state: { senderEmail: email },
														});
													}}
												>
													Forgot Password?
												</a>
												<div className="sso-options">
													<span className="sso-label">
														Or sign in with
													</span>
													<div className="sso-btns">
														<a href="https://launchpad.classlink.com/">
															<img
																src={classLinkLogo}
																alt="ClassLink Logo"
															/>
														</a>
														<a href="https://clever.com/">
															<img
																src={cleverLogo}
																alt="Clever Logo"
															/>
														</a>
													</div>
													<img
														src={logoNavigateDark}
														alt="Navigate360 Logo"
														className="navigate-logo"
													/>
												</div>
											</form>
										)}
										{forgotPassword && (
											<form onSubmit={e => this.submitLogin(e)}>
												<Input
													label="Email"
													disabled={submitDisabled}
													placeholder="email@email.com"
													large
													className="mb-3"
													value={emailForget}
													error={!!validationErrors}
													errorMessage={validationErrors}
													onChange={e => {
														this.setField(e, 'emailForget');
														this.setState({
															e,
															validationErrors: '',
														});
													}}
													rightelement={
														submitDisabled === true && (
															<Button
																type="button"
																minimal
																icon="annotation"
																onClick={handleSubmitEmail}
																style={{
																	backgroundColor: 'transparent',
																	border: 'none',
																	cursor: 'pointer',
																}}
																className="no-hover-backgound"
															/>
														)
													}
												/>
												<br />
												<Toaster
													ref={this.toasterRef}
													position={Position.TOP_RIGHT}
												/>
												<Button
													disabled={submitDisabled}
													text="Send Password Reset"
													intent="primary"
													large
													className="w-100 mt-1"
													onClick={e => this.submitForget(e)}
												/>
												{submitDisabled === true && (
													<span
														className="d-flex justify-content-center "
														style={{
															fontFamily: "'Roboto', sans-serif",
														}}
													>
														<EmailTimer
															seconds={60}
															message={message}
															handleTimerEnd={handleTimerEnd}
														/>
													</span>
												)}
												<span
													className="d-flex justify-content-center mt-3"
													style={{
														fontFamily: "'Roboto', sans-serif",
													}}
												>
													Back to Login?&nbsp;
													<a
														onClick={() =>
															this.setState({
																forgotPassword: false,
															})
														}
														style={{ color: '#9A2C26' }}
													>
														{' '}
														Click here
													</a>
												</span>
											</form>
										)}
									</>
								)}
							</div>
						</div>
						<Carousel>
							<img alt="Navigate360: EMS Flipcharts" src={carouselImageFlipcharts} />
							<img
								alt="Navigate360: EMS Maps and building management"
								src={carouselImageMaps}
							/>
							<img
								alt="Navigate360: EMS Drills management"
								src={carouselImageDrills}
							/>
						</Carousel>
					</div>
				)}
			</>
		);
	}
}

Login.propTypes = {
	dispatch: PropTypes.func,
	route: PropTypes.object,
	history: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(Login);
