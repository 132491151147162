import React, {useState, useContext, useRef, useEffect} from 'react'; 
import NormalModal from 'components/Modals/NormalModal';
import { ToasterContext } from "pages/App";
import { Intent } from '@blueprintjs/core';

export default function ImageUploadModal (props)
{
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [loading, setLoading] = useState(false);
    const [removePhoto, setRemovePhoto] = useState(false);
    const inputUploadFile = useRef(null);
    const imageMimeType = /image\/(png|jpg|jpeg)/i;
    const toaster = useContext(ToasterContext);
    const [cancelBtnTitle, setCancelBtnTitle] = useState("Cancel");

    useEffect(() => {
        if (! props.isDefault) {
            setCancelBtnTitle('Remove Photo');
        }
    })
    
    function onFileChange(event) 
    {
        const file = event.target.files[0];
        
        if (!file.type.match(imageMimeType)) {
            toaster("Image mime type is not valid.", Intent.DANGER);
            return;
        } else {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                const { result } = e.target;
                if (result) {
                    setFileDataURL(result);
                }
            }

        }
    };

    function confirmClickTitle()
    {
        if (selectedFile) {
            return "Save Changes";
        } else if (! props.isDefault) {
            return "Upload new photo";
        } else {
            return "Upload photo";           
        }
    }

    function confirmClickAction()
    {
        if (selectedFile) {
            props.handleImageSelect(selectedFile.type, fileDataURL);
        } else if (removePhoto) {
            setRemovePhoto(false);
        } else {
            inputUploadFile.current.click();
        }
    }

    function cancelClickAction(e)
    {     
        if (! props.isDefault) {
            props.removePhoto()
        }

        props.closeModal()
    }

    return (
        <>
            <NormalModal
                onConfirmAction={confirmClickAction}
                onCancelAction={(e) => cancelClickAction(e)}
                confirmBtnTitle={confirmClickTitle()}
                cancelBtnTitle={cancelBtnTitle}
                header="User photo"
                loading={loading}
                className="upload-image-modal"
            >
                <div className='image-upload-wrap'>
                    {(selectedFile) ? (
                        <img src={fileDataURL} alt="preview"/>
                    ): (
                        <img src={props.avatarImage} />
                    )}
                    <div className='image-upload-wrap_Note'>*Professional photos only</div>
               </div>
               <input 
                    id="profile-file" 
                    type="file" 
                    accept='.png, .jpg, .jpeg'
                    ref={inputUploadFile} 
                    style={{display: 'none'}}
                    onChange={(e) => onFileChange(e)} 
                />
                
            </NormalModal>
        </>
    );
}