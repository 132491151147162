import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, FormModal, Input, Dropdown } from 'componentsV2';
import { Icon } from '@blueprintjs/core';

export const TemplateQuestionModal = ({ type, sectionId, question, onEdit, onClose, onAdd }) => {
	let typeData;
	let optionsData;
	if (question) {
		const propertiesData = JSON.parse(question.properties);
		const value = propertiesData.answerType || propertiesData;
		typeData = value.type;
		optionsData = value.options;
	}

	const [title, setTitle] = useState(question ? question.title : '');
	const [hint, setHint] = useState(question ? question.hint : '');
	const [answerTypeDropdownValue, setAnswerTypeDropdownValue] = useState({ value: { type: '' } });
	const [customOptions, setCustomOptions] = useState([]);

	const dropdownOptions = [
		{ label: 'Yes/No', value: { type: 'yn', options: ['Yes', 'No'] } },
		{ label: 'Yes/No/NA', value: { type: 'yn', options: ['Yes', 'No', 'N/A'] } },
		{ label: 'Yes/No/NA/NAS', value: { type: 'yn', options: ['Yes', 'No', 'N/A', 'N/AS'] } },
		{ label: 'Yes/No/NA/NS', value: { type: 'yn', options: ['Yes', 'No', 'N/A', 'N/S'] } },
		{ label: 'Text area', value: { type: 'area' } },
		{
			label: 'Custom select (single answer)',
			value: { type: 'select', options: [] },
		},
		{
			label: 'Multiple select (multiple answers)',
			value: { type: 'multiple', options: [] },
		},
	];

	const getDropdownValueFromData = (typeData, optionsData) => {
		switch (typeData) {
			case 'yn':
				if (optionsData) {
					// "yn" type options should match one of the dropdown options
					// try matching strings
					const optionsDataString = JSON.stringify(optionsData).toLowerCase();
					const dropdownOptionsMatch = dropdownOptions.find(
						option =>
							JSON.stringify(option.value.options).toLowerCase() ===
							optionsDataString,
					);
					setAnswerTypeDropdownValue(dropdownOptionsMatch);
				}
				break;
			case 'select' || 'multiple':
				setAnswerTypeDropdownValue(
					dropdownOptions.find(option => option.value.type === typeData),
				);
				setCustomOptions(optionsData);
				break;
			case 'multiple':
				setAnswerTypeDropdownValue(
					dropdownOptions.find(option => option.value.type === typeData),
				);
				setCustomOptions(optionsData);
				break;
			case 'area':
				setAnswerTypeDropdownValue(
					dropdownOptions.find(option => option.value.type === typeData),
				);
			default:
				console.log('getDropdownValueFromData default case');
				break;
		}
	};

	useEffect(() => {
		if (type === 'edit') getDropdownValueFromData(typeData, optionsData);
	}, []);

	const makeQuestionBody = () => {
		const properties =
			answerTypeDropdownValue.value.type === 'select' ||
			answerTypeDropdownValue.value.type === 'multiple'
				? {
						type: answerTypeDropdownValue.value.type,
						options: customOptions,
				  }
				: {
						...answerTypeDropdownValue.value,
				  };
		return {
			sectionId,
			title,
			hint,
			properties,
		};
	};

	const handleEditQuestion = () => {
		onEdit(sectionId, question.id, makeQuestionBody());
		onClose();
	};

	const handleAddQuestion = () => {
		onAdd(sectionId, makeQuestionBody());
		onClose();
	};

	// CRUD for select/multuple custom options
	const updateCustomOptions = (updatedOption, index) => {
		const updatedOptions = customOptions.map((option, i) => {
			return i === index ? updatedOption : option;
		});
		setCustomOptions(updatedOptions);
	};

	const addNewCustomOption = () => {
		const updatedOptions = [...customOptions, ''];
		setCustomOptions(updatedOptions);
	};

	const removeCustomOption = index => {
		const updatedOptions = customOptions.filter((option, i) => i !== index);
		setCustomOptions(updatedOptions);
	};

	const reorderCustomOptions = e => {
		const updatedOptions = [...customOptions];
		// Remove option from current position
		const [option] = updatedOptions.splice(e.source.index, 1);
		// Insert option into new position
		updatedOptions.splice(e.destination.index, 0, option);

		setCustomOptions(updatedOptions);
	};

	// Styles for dnd components
	const getListStyle = isDraggingOver => ({
		display: 'flex',
		flexDirection: 'column',
		gap: '14px',
	});

	const getItemStyle = (isDragging, draggableStyle) => ({
		display: 'flex',
		gap: '8px',
		alignItems: 'center',
		// styles we need to apply on draggables
		...draggableStyle,
	});

	return (
		<FormModal
			isOpen
			title={type === 'edit' ? 'Edit question' : 'Add question'}
			confirmBtn={{
				title: type === 'edit' ? 'Update' : 'Add',
				onClick: type === 'edit' ? handleEditQuestion : handleAddQuestion,
				disabled: title === '' || answerTypeDropdownValue.value.type === '',
			}}
			cancelBtn={{ title: 'Cancel', onClick: onClose }}
			onClose={onClose}
			scrollContent={false}
		>
			<div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
				<Input
					label="Question"
					isRequired
					value={title}
					onChange={e => setTitle(e.target.value)}
				/>
				<Input
					label="Hint"
					isRequired={false}
					value={hint}
					onChange={e => setHint(e.target.value)}
				/>
				<Dropdown
					label="Answer type"
					isRequired
					value={answerTypeDropdownValue}
					options={dropdownOptions}
					onChange={e => setAnswerTypeDropdownValue(e)}
				/>
				{answerTypeDropdownValue.value.type === 'select' && (
					<div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
						{customOptions.map((option, index) => (
							<div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
								<div style={{ width: '100%' }}>
									<Input
										isRequired={false}
										value={option}
										onChange={e => updateCustomOptions(e.target.value, index)}
									/>
								</div>
								<Button
									type="ghostDefault"
									icon="remove"
									size="md"
									onClick={() => removeCustomOption(index)}
								/>
							</div>
						))}
						<div style={{ alignSelf: 'flex-start' }}>
							<Button
								type="ghostDefault"
								icon="add"
								text="Add option"
								size="sm"
								onClick={addNewCustomOption}
							/>
						</div>
					</div>
				)}
				{answerTypeDropdownValue.value.type === 'multiple' && (
					<div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
						<DragDropContext onDragEnd={reorderCustomOptions}>
							<Droppable droppableId="droppable">
								{(droppableProvided, droppableSnapshot) => (
									<div
										ref={droppableProvided.innerRef}
										style={getListStyle(droppableSnapshot.isDraggingOver)}
									>
										{customOptions.map((option, index) => (
											<Draggable
												key={`draggable-question-key-${sectionId}${
													question ? question.id : 'new-question'
												}${index}`}
												draggableId={`draggable-question-id-${sectionId}${
													question ? question.id : 'new-question'
												}${index}`}
												index={index}
											>
												{(draggableProvided, draggableSnapshot) => (
													<div
														ref={draggableProvided.innerRef}
														{...draggableProvided.draggableProps}
														{...draggableProvided.dragHandleProps}
														style={getItemStyle(
															draggableSnapshot.isDragging,
															draggableProvided.draggableProps.style,
														)}
													>
														<Icon icon="drag-handle-vertical" />
														<div style={{ width: '100%' }}>
															<Input
																isRequired={false}
																value={option}
																onChange={e =>
																	updateCustomOptions(
																		e.target.value,
																		index,
																	)
																}
															/>
														</div>
														<Button
															type="ghostDefault"
															icon="remove"
															size="md"
															onClick={() =>
																removeCustomOption(index)
															}
														/>
													</div>
												)}
											</Draggable>
										))}
										{droppableProvided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
						<div style={{ alignSelf: 'flex-start' }}>
							<Button
								type="ghostDefault"
								icon="add"
								text="Add option"
								size="sm"
								onClick={addNewCustomOption}
							/>
						</div>
					</div>
				)}
			</div>
		</FormModal>
	);
};
