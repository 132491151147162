import React, { useState } from 'react';
import { throttle } from 'lodash';
import { UsersService } from 'services/UsersService';
import {
  Checkbox,
  Input,
  FormModal,
  LoadingSpinner,
  EmptyState,
} from 'componentsV2';
import { noResult } from 'componentsV2/EmptyState/icons';
import '../css/AddUsersModal.scss';

export const AddUsersModal = (props) => {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [searchStarted, setSearchStarted] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const onChange = ({ target }) => {
    if (!target.value || target.value.trim() === '') {
      setSearchValue('');
      setUsers([]);
      return;
    }

    setSearchValue(target.value);
    if (target.value.length > 2) {
      throttledUserSearch(target.value);
    }
  };

  const searchUsers = async (query) => {
    return new Promise(async (resolve, reject) => {
      setSearchStarted(true);
      setQuery(query);

      try {
        const users = await UsersService.search({
          combineSearch: query,
          page: 0,
          perPage: 10,
        });
        setUsers(users?.data);
        setDataFetched(true);
        resolve(users);
      } catch (error) {
        reject(error);
      }
    });
  };

  const throttledUserSearch = throttle(searchUsers, 800);

  const toggleUserSelect = (user) => {
    if (selectedUsers.includes(user.id)) {
      setSelectedUsers(selectedUsers.filter((elem) => elem !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user.id]);
    }
  };

  const isSelectedUser = (id) => selectedUsers.includes(id);

  const returnUsers = () => {
    props.onUsersAdd(selectedUsers);
  };

  return (
    <>
      <FormModal
        size="medium"
        isOpen={props.isOpen}
        onClose={props.onCloseModal}
        title={props.title}
        subtitle={props.subtitle}
        showCloseButton={false}
        confirmBtn={{
          title: 'Add',
          onClick: returnUsers,
          disabled: selectedUsers.length == 0,
        }}
        cancelBtn={{
          title: 'Cancel',
          onClick: props.onCloseModal,
        }}
        scrollContent={false}
        customBodyClass="add-users-content"
      >
        <div className="d-flex w-100">
          <div className="w-100">
            <Input
              value={searchValue}
              icon={'search'}
              large={true}
              onChange={onChange}
              name="title"
              placeholder={'Search by name or email...'}
              isRequired={true}
              width="100%"
            />
          </div>
        </div>
        {query && (
          <div className="users-list">
            {searchStarted && !dataFetched && <LoadingSpinner />}

            {dataFetched && users.length < 1 && (
              <EmptyState
                header={'No users found.'}
                icon={noResult}
              ></EmptyState>
            )}

            {users &&
              query &&
              users.map((user) => (
                <div key={user.id} className="user">
                  <Checkbox
                    key={user.id}
                    label={
                      <div className="checkbox-icon">
                        <div>
                          {user?.person?.fullName}
                          <p>{user?.person?.emailAddress}</p>
                        </div>
                      </div>
                    }
                    onChange={() => toggleUserSelect(user)}
                    checked={isSelectedUser(user.id)}
                    size="small"
                    bordered={true}
                  />
                </div>
              ))}
          </div>
        )}
      </FormModal>
    </>
  );
};
