import React, { useState, useContext, useEffect } from 'react';
import { Modal, LoadingModal } from 'componentsV2';
import { ToasterContext } from 'pages/App';
import {
	CancelActivationModal,
	FooterActions,
	StepOneAlarmType,
	Countdown,
	StepTwoSelectSite,
	PracticeAlarm,
} from './components';
import { AlarmFormModal } from '../AlarmFormModal';
import { RapidAlarmService } from 'services';
import {
	STEP_ONE,
	STEP_TWO,
	STEP_THREE,
	STEP_FOUR,
	ALARM_SAFE_MODAL,
	AFTER_ACTIVATION_STATUSES,
	ALARM_MODES,
	ALARM_PRACTICE_MODAL,
} from './consts';
import './css/ActivateAlarmModal.scss';
import { Intent } from '@blueprintjs/core';
import { useSelector, useDispatch } from 'react-redux';
import { MODAL_TO_SHOW } from 'utilities/constants';

export const ActivateAlarmModal = ({
	history,
	activeStep = STEP_ONE,
	showPracticeModal = () => {},
}) => {
	const toaster = useContext(ToasterContext);
	const [calloutData, setCalloutData] = useState();
	const dispatch = useDispatch();
	const toggleState = useSelector(state => state.route.toggleState);

	const [modalData, setModalData] = useState({
		activeStep: activeStep,
		modalTitle: '',
		modalSubTitle: '',
		showCancelAction: false,
		showActivateAlarmAction: false,
		secondBtnTitle: 'Cancel',
		showHeader: true,
		activateAlarmDisabled: true,
		onGoBackHandler: () => {},
		onActivateAlarmHandler: () => {},
		onCancelHandler: () => {},
		loading: false,
		onConfirmHandler: () => {},
		alertMsg: '',
		showAreYouSafe: false,
		ShowNeedAssistant: true,
		mode: ALARM_MODES.alarm,
	});

	useEffect(() => {
		let title = '';
		let subtitle = '';
		let mode = '';

		if (activeStep == ALARM_PRACTICE_MODAL) {
			title = 'Practice';
			subtitle = 'Practice drills and training alarms.';
			mode = ALARM_MODES.drill; //By Default
		} else {
			title = 'Select alarm type';
			mode = ALARM_MODES.alarm; //By Default
		}
		onChangeModalData({
			modalTitle: title,
			modalSubTitle: subtitle,
			activeStep: activeStep,
			mode: mode,
		});
	}, []);

	const [formData, setFormData] = useState({
		alarmType: null,
		selectedSites: [],
		selectSitesFirstLoad: true,
		value: '',
		selectedOption: null,
		hasCallout: false,
	});

	const [openCancelActivationModal, setOpenCancelActivationModal] = useState(false);
	const [response, setResponse] = useState(null);
	const onChangeHandler = value => {
		setFormData(oldFormData => ({
			...oldFormData,
			...value,
		}));
	};

	const onChangeModalData = value => {
		setModalData(oldModalData => ({
			...oldModalData,
			...value,
		}));
	};

	const onConfirmSafeModalHandler = (res, val) => {
		let alarmsIds = [];
		res.already_activated.forEach(site => {
			alarmsIds.push(site?.alarmId);
		});

		res.newly_activated.forEach(site => {
			alarmsIds.push(site?.alarmId);
		});
		RapidAlarmService.updateStaffStatus(formData.selectedOption, alarmsIds)
			.then(response => {
				if (response.statusCode === 200) {
					if (
						modalData.showAreYouSafe &&
						formData.selectedOption == AFTER_ACTIVATION_STATUSES.NOT_OKAY
					) {
						onChangeModalData({
							ShowNeedAssistant: true,
							showAreYouSafe: false,
						});
					} else if (modalData.ShowNeedAssistant) {
						onChangeModalData({
							loading: true,
						});

						createPersonAlarmsNote(res, alarmsIds);
					} else {
						redirectUser(res);
						toaster('You have successfully activated this alarm.', Intent.SUCCESS);
						closeModalAction();
					}
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {});
	};

	const onCancelAreYouSafe = response => {
		closeModalAction();
		redirectUser(response);
		const msg = getAlertMessageDetails(response, formData, formData.alarmType);
		toaster(msg, Intent.SUCCESS);
	};

	const onCancelModalHandler = () => {
		modalData.onCancelHandler();
		setOpenCancelActivationModal(true);
	};

	const redirectUser = res => {
		if (formData.selectedSites.length > 1) {
			dispatch({
				type: 'RELOAD_FETCHED_LIST',
				payload: !toggleState,
			});
			history.push('/rapid-alarms');
		} else {
			history.push(
				'/active-alarms/' +
					(res.newly_activated.length > 0
						? res.newly_activated?.[0]['alarmId']
						: res.already_activated?.[0]['alarmId']),
			);
		}
	};

	const createPersonAlarmsNote = (res, alarmsIds) => {
		RapidAlarmService.createStaffNote(formData.value, alarmsIds)
			.then(response => {
				if (response.statusCode === 201) {
					redirectUser(res);
					toaster(
						'Your status has been saved and updated in Roll Call. If you provided a note, it will be saved to your Roll Call history.',
						Intent.WARNING,
					);
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				onChangeModalData({
					loading: false,
				});
				closeModalAction();
			});
	};

	const onCloseCancelActivationModal = () => {
		modalData.onCancelHandler();
		setOpenCancelActivationModal(false);
	};

	const closeModalAction = () => {
		setOpenCancelActivationModal(false);
		showPracticeModal(false);
		dispatch({
			type: MODAL_TO_SHOW,
			payload: null,
		});
	};

	const activetAlarm = (selectedAlarm, val) => {
		const selectedSitesIds = [];

		formData.selectedSites.forEach(site => {
			selectedSitesIds.push(site?.id);
		});

		const activateAlarmRequest = {
			buildingsIds: selectedSitesIds,
			alarmTypeId: selectedAlarm.id,
			mode: modalData.mode.toLowerCase(),
		};
		onChangeModalData({
			activeStep: STEP_FOUR,
		});

		RapidAlarmService.activateAlarm(activateAlarmRequest)
			.then(response => {
				if (response.statusCode === 201) {
					const res = response.data;
					setResponse(res);
					const msg = getAlertMessageDetails(res, formData, selectedAlarm);

					//If the alarm is already active for all the selected sites

					if (selectedAlarm.activateRollCall) {
						onChangeModalData({
							activeStep: ALARM_SAFE_MODAL,
							showAreYouSafe: true,
							ShowNeedAssistant: false,
							alertMsg: msg,
							onConfirmHandler: () => onConfirmSafeModalHandler(res),
						});
					} else {
						closeModalAction();
						if (formData.selectedSites.length > 1) {
							dispatch({
								type: 'RELOAD_FETCHED_LIST',
								payload: !toggleState,
							});
							history.push('/rapid-alarms');
						} else {
							history.push(
								'/active-alarms/' +
									(res.newly_activated.length > 0
										? res.newly_activated?.[0]['alarmId']
										: res.already_activated?.[0]['alarmId']),
							);
						}
						toaster(msg, Intent.SUCCESS);
					}
				}
			})
			.catch(err => {
				closeModalAction();
				toaster(err?.error?.description, Intent.DANGER);
			});
	};

	const getAlertMessageDetails = (res, formData, selectedAlarm) => {
		let toasterMsg = '';
		//If the alarm was activated in all sites successfully
		if (res.newly_activated.length == formData.selectedSites.length) {
			toasterMsg = selectedAlarm.title + ' alarm activated.';
			if (selectedAlarm.contact911) {
				toasterMsg += ' Please call 911 ASAP.';
			}

			//If the the alarm was already activated in any of the selected sites by another user
		} else {
			let preActivatedSites = [];
			let newActivatedSites = [];

			res.already_activated.forEach(site => {
				preActivatedSites.push(site?.buildingName);
			});

			res.newly_activated.forEach(site => {
				newActivatedSites.push(site?.buildingName);
			});

			let ActiveAlarms = newActivatedSites.join(', ');
			let preAlarms = preActivatedSites.join(', ');

			toasterMsg = (
				<>
					{
						<span>
							{res.newly_activated.length > 0 &&
								selectedAlarm.title +
									' alarm activated in ' +
									(res.newly_activated.length == 1 ? 'site ' : 'sites ') +
									ActiveAlarms +
									'.'}

							{res.newly_activated.length > 0 && res.already_activated.length > 0 && (
								<br />
							)}
							{res.already_activated.length > 0 &&
								preAlarms +
									' already ' +
									(res.already_activated.length == 1 ? 'has' : 'have') +
									' an active alarm.'}
							{selectedAlarm.contact911 ? ' Please call 911 ASAP.' : ''}
						</span>
					}
				</>
			);
		}
		return toasterMsg;
	};

	return (
		<div className="ActivateAlarm__Wrapper">
			{modalData.activeStep != STEP_FOUR && (
				<Modal
					isOpen={true}
					onClose={onCancelModalHandler}
					title={modalData.modalTitle}
					subtitle={modalData.modalSubTitle}
					size="medium"
					showCloseButton={true}
					scrollContent={true}
					customClassName="activate-alarm-modal-container"
					loading={false}
					hasCallout={formData.hasCallout}
					calloutData={formData.hasCallout ? calloutData : null}
					footer={
						<FooterActions
							onCancelAction={onCancelModalHandler}
							showCancelAction={modalData.showCancelAction}
							onGoBackAction={modalData.onGoBackHandler}
							secondBtnTitle={modalData.secondBtnTitle}
							showActivateAlarmAction={modalData.showActivateAlarmAction}
							onActivateAlarmHandler={modalData.onActivateAlarmHandler}
							activateAlarmDisabled={modalData.activateAlarmDisabled}
						/>
					}
					showHeader={modalData.showHeader}
				>
					{modalData.activeStep == ALARM_PRACTICE_MODAL && (
						<PracticeAlarm
							setCalloutData={setCalloutData}
							onChangeHandler={onChangeHandler}
							onChangeModalData={onChangeModalData}
							onCancelModalHandler={onCancelModalHandler}
						/>
					)}
					{modalData.activeStep === STEP_ONE && (
						<StepOneAlarmType
							selectedAlarm={formData.alarmType}
							onChangeModalData={onChangeModalData}
							onChangeHandler={onChangeHandler}
							onCancelModalHandler={onCancelModalHandler}
							hasCallout={formData.hasCallout}
						/>
					)}
					{modalData.activeStep === STEP_TWO && (
						<StepTwoSelectSite
							formData={formData}
							onChangeModalData={onChangeModalData}
							onChangeHandler={onChangeHandler}
							activetAlarm={activetAlarm}
							hasCallout={formData.hasCallout}
							mode={modalData.mode}
						/>
					)}
					{modalData.activeStep === STEP_THREE && (
						<Countdown
							formData={formData}
							onChangeModalData={onChangeModalData}
							activetAlarm={activetAlarm}
						/>
					)}

					{modalData.activeStep === ALARM_SAFE_MODAL && (
						<AlarmFormModal
							isOpen={true}
							loading={modalData.loading}
							onClose={onCancelAreYouSafe}
							setFormData={setFormData}
							formData={formData}
							modalData={modalData}
							onChangeModalData={onChangeModalData}
							response={response}
							confirmSafeAction={onConfirmSafeModalHandler}
						/>
					)}
					{openCancelActivationModal && (
						<CancelActivationModal
							onCancelAction={onCloseCancelActivationModal}
							closeModalAction={closeModalAction}
							mode={modalData.mode}
						/>
					)}
				</Modal>
			)}
			{modalData.activeStep === STEP_FOUR && (
				<LoadingModal
					isOpen={true}
					loading={true}
					onClose={onCancelModalHandler}
					text="Activating..."
					size="medium"
					customClassName="AlarmLoading_Modal"
				/>
			)}
		</div>
	);
};
