import React, { useEffect, useState } from 'react';
import './css/DurationAlarm.scss';

export const DurationAlarm = ({ row, value, twoLines = false }) => {
	const [durationTitle, setDurationTitle] = useState('');

	useEffect(() => {
		let duration = '';
		let periodCount = 0;
		if (value) {
			if (value.indexOf('day') > -1) {
				periodCount = value.substring(0, value.indexOf('day'));
				duration += periodCount + (periodCount > 1 ? ' days  ' : ' day  ');
				value = value.replace(periodCount + 'day', '');
			}

			if (value.indexOf('hr') > -1) {
				periodCount = value.substring(0, value.indexOf('hr'));
				duration += periodCount + (periodCount > 1 ? ' hours  ' : ' hour  ');
				duration += twoLines ? '\n' : '';
				value = value.replace(periodCount + 'hr', '');
			}

			if (value.indexOf('min') > -1) {
				periodCount = value.substring(0, value.indexOf('min'));
				duration += periodCount + (periodCount > 1 ? ' minutes  ' : ' minute  ');
				value = value.replace(periodCount + 'min', '');
			}

			if (value.indexOf('sec') > -1) {
				periodCount = value.substring(0, value.indexOf('sec'));
				duration += periodCount + (periodCount > 1 ? ' seconds  ' : ' second  ');
				value = value.replace(periodCount + 'sec', '');
			}

			setDurationTitle(
				duration
					.split('  ')
					.join(',')
					.replace(/,\s*$/, ''),
			);
		}
	}, []);

	return <p className="durationTitle"> {durationTitle} </p>;
};

DurationAlarm.propTypes = {};
