import React from 'react';
import { PageHeader } from 'componentsV2';
import PropTypes from 'prop-types';
import { CALLLIST_ROUTES } from 'utilities/constants';

export const Header = ({ title, customItem }) => {
	let breadcrumbItems = [
		{
			label: 'Call Lists',
			url: CALLLIST_ROUTES.LISTINGV2,
		},
		{
			label: 'List details',
		},
	];

	return <PageHeader title={title} breadcrumbItems={breadcrumbItems} customItem={customItem} />;
};

Header.propTypes = {
	openedFromHomePage: PropTypes.bool,
};
