import React from 'react';
import { Tooltip } from 'componentsV2';

const ContactTooltip = ({ contact }) => {
	return (
		<Tooltip tooltipContent={'User already in this call list.'} position="right">
			<span>{contact}</span>
		</Tooltip>
	);
};

export default ContactTooltip;
