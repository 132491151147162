import React, { useContext, useState } from 'react';
import { ConfirmationModal, ToasterContext, FeaturedIcon } from 'componentsV2';
import { RollCallAndReunificationContext } from '../RollCallAndReunification';
import { Intent } from '@blueprintjs/core';
import { RollCallDetailsContext } from '../../RollCallDetails';
import { ReunificationService } from 'services';
import { ReunificationContext } from '../Reunification';

export const GuardianUndoCheckInModal = () => {
	const { alarmId } = useContext(RollCallDetailsContext);
	const { closeModal, selectedStudent } = useContext(RollCallAndReunificationContext);
	const {
		setReloadGuardiansList,
		initialGuardianFilterationData,
		setGuardianFilterationData,
	} = useContext(ReunificationContext);
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);

	const guardianName = selectedStudent.name;
	const title = `Are you sure you want to undo check-in for "${guardianName}"?`;
	const subTitle = 'The guardian will be removed from this list. This action is irreversible.';

	const undoCheckInHandler = () => {
		setLoading(true);
		ReunificationService.undoCheckinGuardian(alarmId, selectedStudent.id)
			.then(res => {
				if (res.statusCode === 201) {
					setReloadGuardiansList(oldVal => !oldVal);
					const successMsg = `You have successfully removed "${guardianName}" from the guardians list.`;
					toaster(successMsg, Intent.NONE,<FeaturedIcon icon={'undo'} type={'Gray'} size={'md'} shape={'circleIcon'} />);
				}
			})
			.catch(err => {
				toaster(err?.error.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				closeModal();
				setGuardianFilterationData(initialGuardianFilterationData);
			});
	};

	return (
		<ConfirmationModal
			isOpen={true}
			title={title}
			subtitle={subTitle}
			showCloseButton={false}
			cancelBtn={{
				title: 'Cancel',
				onClick: closeModal,
			}}
			confirmBtn={{
				title: 'Undo check-in',
				onClick: undoCheckInHandler,
				type: 'primaryDanger',
			}}
			icon={{ icon: 'undo', iconColor: 'error' }}
			loading={loading}
		/>
	);
};
