import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { debounce } from 'debounce';
import { removeVariables ,validateTemplateSections, validateTemplateSubsection} from 'utilities';
import Swal from 'sweetalert2';
import VariableDialog from './variabledialog';
import ImageDialog from './imagedialog';
import styled from 'styled-components';
import { Button } from 'components/Button';

const EditorWrap = styled.div`
	position: relative;
`;

const ButtonWrap = styled.div`
	position: absolute;
	right: 320px;
	top: -45px;
`;

class SingleEditor extends React.Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
			isImagesOpen: false
		};
		this.editorRef = React.createRef();
		this.debounceUpdate = debounce(this.updateSubsection, 0);
	}

	insertVariable = val => {
		const selection = this.editorRef.current.selection.getContent({
			format: 'text',
		});
		if (selection) {
			this.editorRef.current.selection.setContent(
				`<span class="variable-highlight">${val.name}</span> `,
			);
		}
		this.updateSubsection();
		return this.setState({ isOpen: false });
	};

	openMenu = () => this.setState({ isOpen: true });

	openImages = () => this.setState({ isImagesOpen: true });

	updateSubsection = () => {
		const { updateMode } = this.props;
		if (updateMode) return null;
		const {
			updateNodes,
			sectionIndex,
			subsectionIndex,
			variableValues,
		} = this.props;
		const tempcontent = this.editorRef.current.getContent();
		const cleancontent = removeVariables(tempcontent, variableValues);
		return updateNodes(sectionIndex, subsectionIndex, cleancontent);
	};

	insertImage = val => {
		let tempcontent = this.editorRef.current.getContent();

		tempcontent += val;
		this.editorRef.current.setContent(tempcontent);
		return Swal.fire({
			title: 'Done!',
			icon: 'success',
			timer: 1000,
			showConfirmButton: false,
		}).then(() => {
			this.setState({ isImagesOpen: false });
			return this.updateSubsection();
		});
	};

	shouldComponentUpdate(nextProps, nextState, props) {
		const { isOpen, isImagesOpen } = this.state;

		const { updateMode, sectionIndex, subsectionIndex , hasPermission} = this.props;

		if (this.props.hasPermission !== hasPermission) {
			this.setState({ hasPermission: this.props.hasPermission });
			return true;
		}
		if (updateMode !== nextProps.updateMode) {
			return true;
		}

		if (nextState.isOpen !== isOpen) {
			return true;
		}
		if (nextState.isImagesOpen !== isImagesOpen) {
			return true;
		}
		if (
			nextProps.sectionIndex === sectionIndex &&
			nextProps.subsectionIndex === subsectionIndex
		) {
			return false;
		}

		return true;
	}

	closeDialog = () => {
		const { loadData } = this.props;
		this.setState({ isOpen: false });
		return loadData();
	};

	closeWithoutReload = () => {
		this.setState({ isOpen: false });
	};

	completeSection = () => {
		const {
			isCompleted,
			markSectionsComplete,
			sectionIndex,
			subsectionIndex,
		} = this.props;
		Swal.fire({
			title: `Mark ${
				subsectionIndex >= 0 ? 'subsection' : 'section'
			} as ${isCompleted ? 'incomplete' : 'complete'}?`,
			icon: 'question',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button ems-primary-button',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Confirm',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				markSectionsComplete(
					sectionIndex,
					subsectionIndex,
					isCompleted ? !isCompleted : true,
				);
			}
			return Swal.close();
		});
	};

	closeImages = () => this.setState({ isImagesOpen: false });

	getIndex = () => {
		const { type, sectionIndex, subsectionIndex } = this.props;
		let indexVal = sectionIndex;
		if (type === 'subsection') {
			indexVal = sectionIndex + '-' + subsectionIndex;
		}
		return indexVal;
	};

	exportSingle = () => {
		const {
			sectionIndex,
			subsectionIndex,
			exportSingleSection,
		} = this.props;
		return exportSingleSection(sectionIndex, subsectionIndex);
	};

	publishUpdate = () => {
		const {
			templateId,
			sectionIndex,
			subsectionIndex,
			actualSectionId,
			actualSubsectionId,
			template,
			subsection,
			type,
		} = this.props;
		let errorObj = null;
		if(type==="section"){
			errorObj = validateTemplateSections([subsection], true, 'publish', template.name);
		}else {
			errorObj = validateTemplateSubsection(subsection, actualSectionId, true, null,template.name);
		}
		if (errorObj.issue) {
			Swal.fire({
				html: errorObj.messageHTML,
				icon: 'warning',
				showCancelButton: false,
				confirmButtonText: 'ok',
				reverseButtons: true,
				returnFocus: false,
				onAfterClose() {
					const element = document.getElementById(`subsection-${  actualSectionId  }-${  actualSubsectionId}`);
					if (element) {
						element.scrollIntoView({
							block: 'start',
							behavior: 'smooth',
						});
					}
				},
			}).then(result => {
				if (result.value) {
					return Swal.close();
				}
			});
			return;
		}
		Swal.fire({
			title: 'Publish update?',
			text: 'You are about to publish an update for this section',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Publish',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				Swal.fire('Publishing');
				Swal.showLoading();
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				const tempcontent = this.editorRef.current.getContent();
				let updatedTitle = "";
				let isClient = false

				if (actualSubsectionId) {
					template.sections[sectionIndex].subsections[subsectionIndex].content = tempcontent;
					updatedTitle = template.sections[sectionIndex].subsections[subsectionIndex].title;
					isClient = template.sections[sectionIndex].subsections[subsectionIndex].isClient ? template.sections[sectionIndex].subsections[subsectionIndex].isClient : false

				} else {
					template.sections[sectionIndex].content = tempcontent;
					updatedTitle = template.sections[sectionIndex].title;
					isClient = template.sections[sectionIndex].isClient ? template.sections[sectionIndex].isClient : false;
				}
				const updatedContent = {
					actualSectionId,
					actualSubsectionId,
					content: tempcontent,
					title: updatedTitle,
					index: actualSubsectionId? subsectionIndex : sectionIndex,
					isClient:isClient
				};
				return fetch(
					`${
						process.env.API_URL
					}/district-templates/${templateId}/updates`,
					{
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({
							type: 'update',
							updatedContent: JSON.stringify(updatedContent),
							actualSectionId,
							actualSubsectionId,
						}),
					},
				)
					.then(res => res.json())
					.then(resp => {
						if (resp.statusCode === 201) {
							fetch(
								`${
									process.env.API_URL
								}/district-templates/${parseInt(template.id, 10)}`,
								{
									method: 'PUT',
									headers: {
										'Content-Type': 'app/json',
										Authorization: `Bearer ${token}`,
									},
									body: JSON.stringify({
										headers: JSON.stringify(template.headers),
										content: JSON.stringify(
											template.sections,
										),
										name: template.name,
										state: template.state,
									}),
								},
							)
								.then(res => res.json())
								.then(rsp => {
									if (rsp.statusCode === 200) {
										Swal.close();
										Swal.fire({
											title: 'Done!',
											icon: 'success',
											timer: 1000,
											showConfirmButton: false,
										}).then(() => window.location.reload()
										);
									}
								});
						} else if(resp.statusCode == 400) {
							Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: resp.error?.description || 'Could not publish update. Please try again.',
							});
						}
						return null;
					});
			}
			return Swal.close();
		});
	};

	toggleMode = () => {
		const {
			type,
			sectionIndex,
			subsectionIndex,
			toggleUpdateMode,
			actualSectionId,
			actualSubsectionId,
		} = this.props;
		let indexVal = sectionIndex;

		if (type === 'subsection') {
			indexVal = sectionIndex + '-' + subsectionIndex;
		}

		return toggleUpdateMode(
			true,
			indexVal,
			type,
			actualSectionId,
			actualSubsectionId,
		);
	};

	updateEditorPosition = () => {
		const { updatePosition, actualSectionId, actualSubsectionId } = this.props;
		updatePosition(actualSectionId, actualSubsectionId);
	};

	render() {
		const {
			content,
			subsectionIndex,
			makeIndicatorRed,
			indicator,
			commonVariables,
			templateVariables,
			templateId,
			reloadVariables,
			loadData,
			variableValues,
			parsedContent,
			updateMode,
			updateType,
			updateTarget,
			type,
			cancelUpdateMode,
			viewMode = false
		} = this.props;

		const { isOpen, isImagesOpen } = this.state;
		const showEditor =
		(updateMode &&
			updateType === type &&
			updateTarget === this.getIndex()) ||
		!updateMode;
		const showButtons =
			updateMode &&
			updateType === type &&
			updateTarget === this.getIndex();

		return (
			<>
				<VariableDialog
					isOpen={isOpen}
					toggleDialog={() => this.setState({ isOpen: false })}
					insertVariable={this.insertVariable}
					commonVariables={commonVariables}
					templateVariables={templateVariables}
					templateId={templateId}
					reloadVariables={reloadVariables}
					reopenDialog={() => this.setState({ isOpen: true })}
					content={content}
					loadData={loadData}
					variableValues={variableValues}
					closeDialog={this.closeDialog}
					closeWithoutReload={this.closeWithoutReload}
				/>
				<ImageDialog
					isOpen={isImagesOpen}
					insertImage={this.insertImage}
					closeImages={this.closeImages}
				/>
				<div className="mb-3">
					<EditorWrap className="mb-3">
						{showEditor && !viewMode ? (
							<>
								{showButtons && (
									<ButtonWrap>
										<Button
											text="Cancel"
											onClick={cancelUpdateMode}
										/>
										<Button
											text="Publish"
											className="ml-2"
											onClick={this.publishUpdate}
									/>
									</ButtonWrap>
								)}

								<Editor
									key={subsectionIndex}
									ref={this.editorRef}
									onInit={(evt, editor) => {
										this.editorRef.current = editor;
										return null;
									}}
									onEditorChange={() => {
										if (indicator) {
											return makeIndicatorRed();
										}
										return null;
									}}
									onFocus={this.updateEditorPosition}
									initialValue={parsedContent}
									init={{
										height: '90vh',
										menubar: false,
										statusbar: false,
										branding: false,
										resize: false,
										nonbreaking_force_tab: true,
										plugins: [
											'advlist autolink lists link image charmap print preview anchor',
											'searchreplace visualblocks code fullscreen',
											'insertdatetime media table paste code table ',
											'nonbreaking'
										],
										toolbar:
											'undo redo formatselect bold italic underline fontselect fontsizeselect forecolor backcolor cut copy ' +
											'alignleft aligncenter alignright alignjustify bullist numlist ' +
											'table imageModal variableMenu exportSection completeSection updateMode',
										content_style:
											'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }' +
											'.variable-highlight{background: rgba(19, 124, 189, 0.2)}' +
											'.variable-value-highlight{background: rgbA(217, 130, 43, 0.4)}',
										setup: editor => {
											editor.ui.registry.addButton('variableMenu', {
												text: 'Insert Variable',
												onAction: this.openMenu,
											});
											editor.ui.registry.addButton('imageModal', {
												text: 'Add Image',
												onAction: this.openImages,
											});
											editor.ui.registry.addButton(
												'completeSection',
												{
													text: 'Complete Section',
													onAction: this.completeSection,
												},
											);
											editor.ui.registry.addButton('exportSection', {
												text: 'Export',
												onAction: this.exportSingle,
											});

											this.props.template.createdFromDistrict && this.props.template.hasEditPermission && editor.ui.registry.addButton(
												'updateMode',
												{
													text: 'Create an Update',
													onAction: this.toggleMode,
												},
											);

											editor.on(
												'Paste Change input Undo Redo',
												this.debounceUpdate,
											);
										},
									}}
								/>
							</>)

							: (
								<div
									dangerouslySetInnerHTML={{
										__html: parsedContent,
									}}
								/>)
							}
					</EditorWrap>
				</div>
			</>
		);
	}
}

SingleEditor.propTypes = {
	content: PropTypes.any,
	parsedContent: PropTypes.any,
	updateNodes: PropTypes.func,
	sectionIndex: PropTypes.number,
	subsectionIndex: PropTypes.number,
	makeIndicatorRed: PropTypes.func,
	indicator: PropTypes.bool,
	commonVariables: PropTypes.array,
	templateVariables: PropTypes.array,
	templateId: PropTypes.string,
	reloadVariables: PropTypes.func,
	loadData: PropTypes.func,
	markSectionsComplete: PropTypes.func,
	variableValues: PropTypes.any,
	isCompleted: PropTypes.bool,
	exportSingleSection: PropTypes.func,
	type: PropTypes.string,
	updateMode: PropTypes.bool,
	updateType: PropTypes.string,
};

export { SingleEditor };
