import React, { useEffect, useState, useContext } from 'react';
import { dragSelector, removeNew } from 'assets/icons';
import { DragAndDrop } from 'components/DragAndDrop';
import {
	Dropdown,
	FormModal,
	LoadingSpinner,
	EmptyState,
	ToasterContext,
	Intent,
} from 'componentsV2';
import { noResult } from 'componentsV2/EmptyState/icons';
import {
	DrillDistrictRequirementsService,
	DrillStateRequirementsService,
	DrillLogService,
} from 'services';
import '../css/EditRequiredFieldsModal.scss';

export const EditRequiredFieldsModal = ({
	isOpen,
	subtitle,
	onCloseModal,
	onActionClick,
	selectedRequirement,
}) => {
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(true);
	const [fields, setFields] = useState([]);
	const [fieldOptions, setFieldOptions] = useState([]);

	const cacheItems = newlyOrderedItems => {
		setFields(newlyOrderedItems);
	};

	const handleActionBtn = () => {
		if (fields.length) {
			setLoading(true);
			onActionClick(fields);
		} else {
			onCloseModal();
		}
	};

	const filterOutMatchingElements = (mainArray, elementsToFilterOut) => {
		const uuidsToRemove = elementsToFilterOut.map(obj => obj.uuid);
		return mainArray.filter(item => !uuidsToRemove.includes(item.uuid));
	};

	// remove field from options if added
	useEffect(() => {
		const opts = filterOutMatchingElements(fieldOptions, fields);
		setFieldOptions(opts);
	}, [fields]);

	const getRequirements = async () => {
		let districtRequirements = [];
		let stateRequirements = [];

		if (
			selectedRequirement?.districtRequirement?.fieldUuids.length > 0 &&
			selectedRequirement?.stateRequirement?.fieldUuids.length > 0
		) {
			districtRequirements = await DrillDistrictRequirementsService.fetchRequiredFields(
				selectedRequirement.districtRequirement?.id,
			);
			stateRequirements = await DrillStateRequirementsService.fetchRequiredFields(
				selectedRequirement?.stateRequirement?.id,
			);
			districtRequirements = districtRequirements.map(obj => ({ ...obj, isState: false }));
			stateRequirements = stateRequirements.map(obj => ({ ...obj, isState: true }));

			const ids = new Set(stateRequirements.map(d => d.uuid));

			return [...stateRequirements, ...districtRequirements.filter(d => !ids.has(d.uuid))];
		}

		if (selectedRequirement?.districtRequirement?.fieldUuids.length > 0) {
			districtRequirements = await DrillDistrictRequirementsService.fetchRequiredFields(
				selectedRequirement.districtRequirement?.id,
			);
			return districtRequirements.map(obj => ({ ...obj, isState: false }));
		}

		if (selectedRequirement?.stateRequirement?.id) {
			stateRequirements = await DrillStateRequirementsService.fetchRequiredFields(
				selectedRequirement?.stateRequirement?.id,
			);
			return stateRequirements.map(obj => ({ ...obj, isState: true }));
		}

		return DrillStateRequirementsService.fetchRequiredFields(selectedRequirement?.id);
	};

	useEffect(async () => {
		const drillLogFields = await DrillLogService.fetchDrillLogFields(); // gets all fields

		getRequirements()
			.then(backendFields => {
				const opts = filterOutMatchingElements(drillLogFields, backendFields);
				setFieldOptions(opts);
				setFields(backendFields);
			})
			.catch(() => {
				onCloseModal();
				toaster('Could not get fields at this time. Please try again.', Intent.DANGER);
			})
			.finally(() => setLoading(false));
	}, []);

	const displayFieldsList = () => {
		if (loading) {
			return <LoadingSpinner className="mt-3" />;
		}

		if (fields.length === 0) {
			return <EmptyState header="There are no fields to edit" icon={noResult} />;
		}

		return (
			<DragAndDrop
				cacheItems={cacheItems}
				items={fields}
				component={(item, removeItem) => (
					<div className="required-fields">
						<div className="required-field-content">
							<img alt="" className="drag-selector" src={dragSelector} />
							<div className="field-text">{item.name}</div>
						</div>
						<img
							className={`delete-requirement ${item?.isState ? 'disabled' : ''}`}
							alt="Delete field"
							onClick={() => {
								if (item?.isState) return;
								removeItem(item.id);
							}}
							src={removeNew}
						/>
					</div>
				)}
			/>
		);
	};

	return (
		<>
			<FormModal
				size="medium"
				title={`Edit required log fields for "${selectedRequirement?.name ||
					selectedRequirement.drillType.name}"`}
				subtitle={subtitle}
				isOpen={isOpen}
				onClose={onCloseModal}
				showCloseButton={false}
				confirmBtn={{
					title: 'Update',
					onClick: handleActionBtn,
					disabled: fields.length === 0,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: onCloseModal,
				}}
				scrollContent={false}
			>
				<div className="district-requirements-fields-list w-100">{displayFieldsList()}</div>
				<div className="w-100 add-another-field">
					<Dropdown
						placeholder="Add another field"
						value={null}
						optionLabel="name"
						optionValue="id"
						options={fieldOptions}
						onChange={option => {
							setFields(
								fields.concat({
									...option,
									uuid: option.uuid,
									id: option.id,
								}),
							);
						}}
					/>
				</div>
			</FormModal>
		</>
	);
};
