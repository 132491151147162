export const DOCUMENT_TYPE_OPTIONS = [
	{ value: 'PDF', label: 'PDF' },
	{ value: 'DOCX', label: 'DOCX' },
	{ value: 'XLSX', label: 'XLSX' },
	{ value: 'TXT', label: 'TXT' },
	{ value: 'CSV', label: 'CSV' },
	{ value: 'PPTX', label: 'PPTX' },
	{ value: 'Binder', label: 'Binder' },
];

export const Image_TYPE_OPTIONS = [
	{ value: 'JPEG', label: 'JPEG' },
	{ value: 'JPG', label: 'JPG' },
	{ value: 'PNG', label: 'PNG' },
	{ value: 'GIF', label: 'GIF' },
];

export const Video_TYPE_OPTIONS = [{ value: 'AVI', label: 'AVI' }, { value: 'MP4', label: 'MP4' }];

export const CONTENT_TYPE = {
	DOCUMENTS: 'documents',
	IMAGES: 'images',
	IMAGE360: '360 images',
	VIDEOS: 'videos',
	ADD_WEB_LINK: 'add web link',
	NOTE: 'note',
};
