import React, { useRef, useEffect } from 'react';
import { TextArea, Button } from 'componentsV2';

export const ChatForm = ({
	message,
	handleChangeMessage,
	handleSendMessage,
	disableSubmit = false,
}) => {
	const textAreaRef = useRef(null);

	useEffect(() => {
		if (textAreaRef) {
			textAreaRef.current.style.minHeight = '0px';
			const scrollHeight = textAreaRef.current.scrollHeight;
			textAreaRef.current.style.minHeight = `${scrollHeight <= 120 ? scrollHeight : 120}px`;
		}
	}, [textAreaRef, message]);

	const handleKeyDown = e => {
		if (e.keyCode === 13 && !e.shiftKey) {
			e.preventDefault();
			if (message.trim().length > 0) {
				handleSendMessage();
			}
		}
	};

	useEffect(() => {
		if (textAreaRef && !disableSubmit) {
			textAreaRef.current.focus();
		}
	}, [disableSubmit]);

	return (
		<div className="chat-form">
			<TextArea
				textAreaRef={textAreaRef}
				onChange={handleChangeMessage}
				value={message}
				placeholder="Write a message..."
				onKeyDown={handleKeyDown}
				disabled={disableSubmit}
			/>
			<Button
				text="Send"
				type="primaryDefault"
				intent="default"
				size="sm"
				onClick={handleSendMessage}
				disabled={message.trim().length === 0 || disableSubmit}
			/>
		</div>
	);
};
