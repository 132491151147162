import React, { useContext, useState } from "react";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { Checkbox, Intent, Tooltip } from '@blueprintjs/core';
import { ToasterContext } from "pages/App";
import { examalationIcon } from "assets/icons";
import { decodeToken } from 'utilities';
import './index.css';

const SystemSync = ({
    user, 
    hasUserEditPermission, 
    isSuperAdmin, 
    isMyAccount,
    setTabHasChange,
    setUserupdated
}) => 
{
    const [isChecked, setIsChecked] = useState(user ? user.lockUserSyncing : false);
    const toaster = useContext(ToasterContext);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const url = process.env.API_URL;
	const token = JSON.parse(localStorage.getItem('user')).jwt;

    const toogleCheckHandler = async () =>{
        setIsChecked(!isChecked)
        setTabHasChange(true);
        
        if(!isChecked === (user ? user.lockUserSyncing : false)) {
            setSaveBtnDisabled(true)
        } else {
            setSaveBtnDisabled(false)
        }
    }

    const syncHandler = () => {
        const updateUrl = decodeToken(token).id == user.id ? `myself` : `${user.id}`;
        fetch(`${url}/users/${updateUrl}/lock-syncing`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
            body: JSON.stringify({
                'lockSyncing': isChecked ? true: false
            })
		}).then(res => res.json())
		.then(resp => {
            if(resp.statusCode === 201) {
                toaster(resp.data, Intent.SUCCESS);
                setSaveBtnDisabled(true);
                setTabHasChange(false);
                setUserupdated(true)
            } else {
                toaster(resp.error.description, Intent.DANGER);
            }
        });
    }

    return (
        <>
            <h6 className="mb-5">System Sync</h6>
            <div style={{width:"280px"}}>
                {isSuperAdmin && (
                    <>
                        <Input 
                            label="Import ID"
                            placeholder="Import ID"
                            value={user ? user.importId : ""}
                            readOnly={true}
                        />
                        <Tooltip 
                            style={{width: '100%'}}
                            className="input-tooltip"
                            content={
                                <>
                                    This field exists so a district using Clever can sync. <br/>
                                    Clever users can have two separate accounts, one for being <br/>
                                    a teacher, and one for being an admin. If your district <br/> 
                                    does not  sync using Clever, this field should be ignored.
                                </> 
                            }
                            position='right'
                        >
                            <Input 
                                    label="Secondary Import ID"
                                    placeholder="Secondary Import ID"
                                    value={user ? user.secondImportId : ""}
                                    readOnly={true}
                                />
                        </Tooltip>
                    </>
                )}
                

                <Checkbox 
                    checked={isChecked}
                    onClick={toogleCheckHandler}
                    disabled={(!hasUserEditPermission && !isMyAccount)}
                >
                    <strong>Lock syncing for this user </strong>
                    <Tooltip 
                        content={
                            <>
                                This user’s account will remain unaffected during <br/>
                                scheduled syncs. Updates to this user’s account <br/>
                                and access permissions need to be changed <br/>
                                manually moving forward. <br/>
                            </> 
                            }
                            position='right'
                        >
                            <img src={examalationIcon} />
                    </Tooltip>
                </Checkbox>
                {( hasUserEditPermission || isMyAccount ) && (
                    <Button
                        text="Save"
                        intent="primary"
                        className="mt-4"
                        onClick={syncHandler}
                        disabled={saveBtnDisabled}
                    />
                )}
            </div>
        </>
    );
}

export default SystemSync;
