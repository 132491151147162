import React, { useContext, useEffect, useState } from 'react';
import { SetupGuide, CardContent } from './components';
import {
	BASIC_INFO_SECTION,
	COUNTDOWN_SECTION,
	CONTACT_911_SECTION,
	FLIPCHART_SECTION,
	PREVIEW_SECTION,
	ROLLCALL_SECTION,
	MISSING_REQUIRED_FIELDS_MODAL,
	FINALIZE_ALARM_MODAL,
	SAVE_AS_DISABLED_MODAL,
} from '../../consts';
import './css/CreateAlarmTypeBody.scss';
import {
	basicInfoValidation,
	contact911Validation,
	countdownValidation,
	flipchartValidation,
	previewValidation,
	rollCallValidation,
} from './components/CardContent/components';
import { CreateAlarmTypeContext } from '../../provider';

export const CreateAlarmTypeBody = () => {
	const {
		formData,
		openModal,
		setFormErrors,
		locationPath,
		setLocationPath,
		shouldPromptSaveModal,
		history,
	} = useContext(CreateAlarmTypeContext);
	const [sectionsList, setSectionsList] = useState([
		{
			name: BASIC_INFO_SECTION,
			next: COUNTDOWN_SECTION,
			previous: null,
			completed: false,
		},
		{
			name: COUNTDOWN_SECTION,
			next: CONTACT_911_SECTION,
			previous: BASIC_INFO_SECTION,
			completed: false,
		},
		{
			name: CONTACT_911_SECTION,
			next: FLIPCHART_SECTION,
			previous: COUNTDOWN_SECTION,
			completed: false,
		},
		{
			name: FLIPCHART_SECTION,
			next: ROLLCALL_SECTION,
			previous: CONTACT_911_SECTION,
			completed: false,
		},
		{
			name: ROLLCALL_SECTION,
			next: PREVIEW_SECTION,
			previous: FLIPCHART_SECTION,
			completed: false,
		},
		{
			name: PREVIEW_SECTION,
			next: null,
			previous: ROLLCALL_SECTION,
			completed: false,
		},
	]);
	const [activeSection, setActiveSection] = useState(BASIC_INFO_SECTION);
	const selectedSection = sectionsList.find(cardItem => cardItem.name === activeSection);

	useEffect(() => {
		const unblock = history.block(location => {
			if (shouldPromptSaveModal) {
				// should show a save as disabled modal here
				setLocationPath(location.pathname);
				openModal(SAVE_AS_DISABLED_MODAL);
				return false;
			}
		});

		if (locationPath != '') {
			history.push(locationPath);
		}

		return () => {
			unblock();
		};
	}, [shouldPromptSaveModal, locationPath, history]);

	const onChangeSectionHandler = newSectionName => {
		const isValid = formDataValidationHandler(activeSection);
		// if active section is validated then set this section to completed and set the active section to new section name param
		if (isValid) {
			updateCompletedSections(activeSection, true);
			setActiveSection(newSectionName);
		} else {
			// if active section is not validated then set this section on incompleted and show error modal
			updateCompletedSections(activeSection, false);
			openModal(MISSING_REQUIRED_FIELDS_MODAL);
		}
	};

	const updateCompletedSections = (sectionName, isCompleted) => {
		setSectionsList(oldSections =>
			oldSections.map(oldSection => {
				if (oldSection.name === sectionName) {
					return {
						...oldSection,
						completed: isCompleted,
					};
				}
				return oldSection;
			}),
		);
	};

	const formDataValidationHandler = validationType => {
		let validationResponse = {
			isValid: true,
			errors: {},
		};
		switch (validationType) {
			case BASIC_INFO_SECTION:
				validationResponse = basicInfoValidation(formData);
				break;
			case COUNTDOWN_SECTION:
				validationResponse = countdownValidation(formData);
				break;
			case CONTACT_911_SECTION:
				validationResponse = contact911Validation(formData);
				break;
			case FLIPCHART_SECTION:
				validationResponse = flipchartValidation(formData);
				break;
			case ROLLCALL_SECTION:
				validationResponse = rollCallValidation(formData);
				break;
			case PREVIEW_SECTION:
				validationResponse = previewValidation(formData);
				break;
		}
		// if validation has some errors set them to formErrors state
		setFormErrors(oldErros => ({ ...oldErros, ...validationResponse.errors }));

		return validationResponse.isValid;
	};

	const onSubmitFormHandler = () => {
		openModal(FINALIZE_ALARM_MODAL);
	};

	return (
		<div className="create-alarm-type-body">
			<SetupGuide
				sectionsList={sectionsList}
				activeSection={activeSection}
				onChange={onChangeSectionHandler}
			/>
			<CardContent
				selectedSection={selectedSection}
				onChange={onChangeSectionHandler}
				onSubmit={onSubmitFormHandler}
			/>
		</div>
	);
};
