import React, { useState } from 'react';
import { FileUpload, Modal, Button, AlertModal } from 'componentsV2';
import './css/AttachFilesModal.scss';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

export const AttachFilesModal = ({ isOpen, onClose, template, dispatch }) => {
	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const [loading, Setloading] = useState(false);
	const [fileError, setFileError] = useState(null);
	const [showAlertModal, setShowAlertModal] = useState(false);
	const [deledteFile, setDeletedFile] = useState();
	const [alertLoading, setAlertLoading] = useState(false);
	const [showFileUpload, setShowFileUpload] = useState(true);

	const footer = (
		<div className="button-div-styles">
			<Button
				text={'Done'}
				intent="default"
				onClick={e => {
					onClose();
				}}
			/>
		</div>
	);

	const onFileInputChange = e => {
		setFileError(null);
		uploadFile(e);
	};

	const uploadFile = file => {
		//  Setloading(true);
		const fd = new FormData();
		fd.append('file', file);

		fetch(`${process.env.API_URL}/district-templates/${template.id}/upload`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: fd,
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					const valueFile = {
						size: resp.data.size,
						id: resp.data.id,
						name: resp.data.fileName,
					};
					dispatch({
						type: 'ADD_BONUS_FILES',
						valueFile,
					});
					setShowFileUpload(false);
					saveAndEnableClose();
					setShowFileUpload(true);
				} else if (resp.statusCode == 400) {
				}
				//  Setloading(false);
				return null;
			});
	};

	const saveAndEnableClose = () => {
		fetch(`${process.env.API_URL}/district-templates/${template.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				bonusFiles: JSON.stringify(template.bonusFiles),
			}),
		})
			.then(res => res.json())
			.then(() => {
				Setloading(false);
			});
	};

	const deleteFile = () => {
		setAlertLoading(true);
		fetch(`${process.env.API_URL}/district-templates/${template.id}/files/${deledteFile.id}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(() => {
				dispatch({
					type: 'REMOVE_BONUS_FILE',
					payload: deledteFile,
				});
				setAlertLoading(false);
				closeAlertModal();
				saveAndEnableClose();
			});
	};

	const handleDelete = file => {
		setShowAlertModal(true);
		setDeletedFile({
			id: file.id,
			name: file.name,
			size: file.size / 1024,
		});
	};

	const handleConfirmDelete = () => {
		deleteFile();
	};

	const closeAlertModal = () => {
		setShowAlertModal(false);
	};

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={() => {
					onClose();
				}}
				title={'Attach file'}
				size="small"
				showCloseButton={false}
				loading={loading}
				footer={footer}
				customClassName={'Attach-modal-container'}
			>
				<div className="col-md-12">
					{showFileUpload && (
						<FileUpload
							disabled={false}
							required={true}
							allowedFiles={[
								'png',
								'jpeg',
								'gif',
								'jpg',
								'gif',
								'pdf',
								'txt',
								'docx',
								'xlsx',
								'xls',
							]}
							allowedFilesLegend={`PNG, JPEG, GIF, PDF, TXT, DOCX, XLSX, XLS (max. ${MAX_FILE_UPLOADER_SIZE}GB)`}
							label="File"
							labelState={false}
							onFileInputChange={onFileInputChange}
							maxFileSize={MAX_FILE_UPLOADER_SIZE}
							error={fileError ? true : false}
							defaultErrorMessage={fileError}
						/>
					)}

					{template.bonusFiles.map((item, index) => {
						const nameArray = item.name.split('.');
						const fileInfo = {
							name: item.name,
							size: parseFloat(item.size) * 1024,
							id: item.id,
							ext: nameArray[1],
							type:
								nameArray[1] === 'png' ||
								nameArray[1] === 'jpeg' ||
								nameArray[1] === 'gif' ||
								nameArray[1] === 'jpg'
									? 'image'
									: '',
						};

						return (
							<div className="single-file">
								<FileUpload
									key={`file-${item.id}`}
									disabled={false}
									required={true}
									allowedFiles={[
										'png',
										'jpeg',
										'gif',
										'jpg',
										'pdf',
										'txt',
										'docx',
										'xlsx',
										'xls',
									]}
									allowedFilesLegend={`PNG, JPEG, GIF, JPG, PDF, TXT, DOCX, XLSX, XLS (max. ${MAX_FILE_UPLOADER_SIZE}mb)`}
									labelState={false}
									onFileInputChange={() => {}}
									maxFileSize={MAX_FILE_UPLOADER_SIZE}
									file={fileInfo}
									fileForEditing={true}
									error={fileError ? true : false}
									defaultErrorMessage={fileError}
									onDeleteFile={handleDelete}
									DeleteOnConfirm={true}
									showReplaceAction={false}
									alloweFileDrop={false}
								/>
							</div>
						);
					})}
				</div>
			</Modal>
			<AlertModal
				isOpen={showAlertModal}
				loading={alertLoading}
				onClose={closeAlertModal}
				icon={'trash'}
				iconColor={'error'}
				size="medium"
				title={'Are you sure you want to delete this file?'}
				subtitle={'Once deleted, this cannot be recovered.'}
				alertConfirmButton={
					<Button
						text={'Yes, delete it'}
						type="primaryDanger"
						size="md"
						onClick={() => {
							handleConfirmDelete();
						}}
					/>
				}
				alertCancelButton={
					<Button
						text="No, go back"
						type="tertiary"
						size="md"
						onClick={closeAlertModal}
					/>
				}
			/>
		</>
	);
};
