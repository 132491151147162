import React, { useEffect, useState } from 'react';
import { useActiveAlarms } from 'hooks';
import { PageHeader, Tag, Animated } from 'componentsV2';
import { HomeEmptyState, PastAlarmList } from './components';
import { ALARM_MODE, ActiveAlarmsTable } from '../RapidAlarms';
import { RapidAlarmsProviders } from 'pages/RapidAlarms';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from 'react-redux';
import './css/Home.scss';

export const Home = ({ history }) => {
	const [emergencyMode, setEmergencyMode] = useState(false);
	const { showEmptyStatus, loading, activeAlarmList } = useActiveAlarms();
	const [showPastAlarms, setShowPastAlarms] = useState(false);
	const [pastAlarmListLoaded, setPastAlarmListLoaded] = useState(null);
	const permissions = useSelector(state => state.route.permissions);
	const hasRespondViewPermission = checkPermission('respond_view', permissions);
	const hasReunificationViewPermission = checkPermission('reunification_view', permissions);
	const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));

	useEffect(() => {
		if (activeAlarmList.length > 0) {
			setEmergencyMode(activeAlarmList.some(alarm => alarm.mode == ALARM_MODE));
		} else {
			setEmergencyMode(false);
		}
	}, [activeAlarmList]);

	return (
		<div className="home-page-container">
			<PageHeader
				title="Emergency Management Solution"
				customItem={() =>
					emergencyMode && (
						<Tag
							text="You're in emergency mode"
							type="error"
							size="xl"
							clickable={false}
						/>
					)
				}
			/>
			{!showEmptyStatus && (
				<div className="active-alams-table">
					<RapidAlarmsProviders history={history} openedFromHomePage={true}>
						<ActiveAlarmsTable
							loading={loading}
							list={activeAlarmList}
							history={history}
						/>
					</RapidAlarmsProviders>
				</div>
			)}

			{showEmptyStatus && (
				<>
					<HomeEmptyState
						showPastAlarms={showPastAlarms}
						setShowPastAlarms={setShowPastAlarms}
						hasViewPermission={
							(hasRespondViewPermission || hasReunificationViewPermission) && loggedUserData.accountType == 'administrator'
						}
					/>

					{showPastAlarms && (
						<Animated>
							<PastAlarmList
								pastAlarmListLoaded={pastAlarmListLoaded}
								setPastAlarmListLoaded={setPastAlarmListLoaded}
								history={history}
							/>
						</Animated>
					)}
				</>
			)}
		</div>
	);
};
