import React, { useState, useEffect, useContext } from 'react';
import { Input } from '../../../../components/Input';
import { FormLabel, RequiredWraper } from 'components/Forms/shared.js';
import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { Helper } from 'utilities/helper';
import { UserService } from 'services';
import { PHONE_NUMBER_MASK, PHONE_NUMBER_PLACEHOLDER, PHONE_TYPES } from 'utilities/constants';
import './AccountManger.css';
import { isNotEmpty, isValidEmail, isValidPhone } from '../../../../utilities/validations';

export default function ModuleDetails({
	userId,
	setUserupdated,
	setTabHasChange,
	user,
	isMyAccount,
	hasUserEditPermission,
	isSuperAdmin,
}) {
	const url = process.env.API_URL;
	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const [disableSubmit, setDisableSubmit] = useState(true);
	const [validationErrors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const toaster = useContext(ToasterContext);
	const [isReadOnlyForm, setIsReadOnlyForm] = useState(false);
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		title: '',
		mobilePhone: '',
		workPhone: '',
		workPhoneExtension: '',
		homePhone: '',
	});

	useEffect(() => {
		fetchUserData();

		if (!isMyAccount && !hasUserEditPermission && !isSuperAdmin) {
			setIsReadOnlyForm(true);
		}
	}, [user]);

	async function fetchUserData() {
		setLoading(true);

		return fetch(`${url}/users/${userId}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json, text/plain, */*',
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				setLoading(false);
				let workPhone,
					mobilePhone,
					homePhone,
					ext = '';
				const userData = resp.data.person;
				const phones = userData.phones;

				for (let i = 0; i < phones.length; i++) {
					const phone = phones[i];

					if (phone.type === PHONE_TYPES.WORK) {
						workPhone = phone.phoneNumber;
						ext = phone.extension;
					} else if (phone.type === PHONE_TYPES.MOBILE) {
						mobilePhone = phone.phoneNumber;
					} else if (phone.type === PHONE_TYPES.HOME) {
						homePhone = phone.phoneNumber;
					}
				}
				const updatedFormData = {
					...formData,
					firstName: userData.name.givenName,
					lastName: userData.name.familyName,
					email: user.person.email.emailAddress,
					title: userData.name.title,
					mobilePhone: mobilePhone,
					workPhone: workPhone,
					homePhone: homePhone,
					workPhoneExtension: ext,
				};
				setFormData(updatedFormData);
			});
	}

	const checkEmailExist = async (email) => {
		setLoading(true);
		const emailExists = await UserService.checkEmailExists(email);
		setLoading(false);
		return emailExists;
	};

	const validate = async () => {
		const errors = {};

		if (!isNotEmpty(formData.firstName)) {
			errors.firstName = '*First name is required.';
		}

		if (!isNotEmpty(formData.lastName)) {
			errors.lastName = '*Last name is required.';
		}

		if (!isValidPhone(formData.mobilePhone)) {
			errors.mobilePhone = '*Mobile phone is not valid.';
		}

		if (!isValidPhone(formData.workPhone)) {
			errors.workPhone = '*Work phone is not valid.';
		}

		if (
			(!formData.workPhone && formData.workPhoneExtension) ||
			(formData.workPhone &&
				formData.workPhone.trim().length <= 0 &&
				formData.workPhoneExtension &&
				formData.workPhoneExtension.length > 0)
		) {
			errors.workPhone = '*Work phone number is required when extension is filled.';
		}

		if (!isValidPhone(formData.homePhone)) {
			errors.homePhone = '*Home phone is not valid.';
		}

		if (!isNotEmpty(formData.email)) {
			errors.email = '*Email is required.';
		} else {
			const emailExists = await checkEmailExist(formData.email);

			if (emailExists && user.person.email.emailAddress != formData.email) {
				errors.email =
					'*Email already exists. Verify user is not in the system or enter a different email.';
			}
		}

		return errors;
	};

	const handleSubmit = event => {
		event.preventDefault();
		validate().then(errors => {
			if (Object.keys(errors).length === 0) {
				submitUserUpdate();
				setTabHasChange(false);
			} else {
				setErrors(errors);
				const firstErrorField = Object.keys(errors)[0];
				const inputElement = document.getElementsByName(firstErrorField)[0];
				if (inputElement) {
					inputElement.focus();
				}
			}
		});
	};

	async function submitUserUpdate() {
		setLoading(true);

		// update phone fields to be null in case they are empty strings
		for (var inputKey in formData) {
			if (
				(inputKey == 'mobilePhone' ||
					inputKey == 'workPhone' ||
					inputKey == 'workPhoneExtension' ||
					inputKey == 'homePhone') &&
				formData[inputKey] != null &&
				formData[inputKey].trim() == ''
			) {
				formData[inputKey] = null;
			}
		}

		setUserupdated(false);
		const endPoint = isMyAccount ? 'myself' : userId;
		return fetch(`${url}/users/${endPoint}`, {
			method: 'PUT',
			headers: {
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(formData),
		})
			.then(res => res.json())
			.then(resp => {
				setLoading(false);
				if (resp.statusCode === 200) {
					setUserupdated(true);
					toaster('User details has been updated.', Intent.SUCCESS);
					setDisableSubmit(true);
				} else if (resp.statusCode === 400) {
					var error = '';

					for (var key in resp.error.fields) {
						error = resp.error.fields[key];
					}
					let errorMsg = error ? error : resp.error.description;
					toaster(errorMsg, Intent.DANGER);
				}
			});
	}

	function checkIfNumber(event) {
		/**
		 * Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
		 **/

		const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

		return !event.key.match(regex) && event.preventDefault();
	}

	return (
		<>
			{loading && (
				<div className="d-flex justify-content-center">
					<LoadingSpinner />
				</div>
			)}

			{!loading && (
				<form onSubmit={handleSubmit} method="PUT">
					<h4 className="user-details-header">User Details</h4>

					<h6 className="user-details-content-header">User Information</h6>

					<div className="row">
						<div className="col-md-4">
							<FormLabel>
								First Name <RequiredWraper>Required</RequiredWraper>
							</FormLabel>
							<Input
								placeholder="First Name"
								name="firstName"
								value={formData.firstName}
								error={!!validationErrors.firstName}
								errorMessage={validationErrors.firstName}
								onChange={e => {
									validationErrors.firstName = '';
									setFormData({ ...formData, firstName: e.target.value });
									setDisableSubmit(false);
									setTabHasChange(true);
								}}
								readOnly={isReadOnlyForm}
							/>
						</div>
						<div className="col-md-4">
							<FormLabel>
								Last Name <RequiredWraper>Required</RequiredWraper>
							</FormLabel>
							<Input
								placeholder="Last Name"
								name="lastName"
								value={formData.lastName}
								error={!!validationErrors.lastName}
								errorMessage={validationErrors.lastName}
								onChange={e => {
									validationErrors.lastName = '';
									setFormData({ ...formData, lastName: e.target.value });
									setDisableSubmit(false);
									setTabHasChange(true);
								}}
								readOnly={isReadOnlyForm}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<Input
								label="Staff Title"
								placeholder="Enter Staff Title"
								name="title"
								value={formData.title}
								onChange={e => {
									setFormData({ ...formData, title: e.target.value });
									setDisableSubmit(false);
									setTabHasChange(true);
								}}
								readOnly={isReadOnlyForm}
							/>
						</div>
					</div>

					<hr />

					<h5 className="user-details-content-header">Contact Information</h5>
					<div className="row">
						<div className="col-md-4">
							<FormLabel>
								Email <RequiredWraper>Required</RequiredWraper>
							</FormLabel>
							<Input
                type="email"
								name="email"
								value={formData.email}
								error={!!validationErrors.email}
								errorMessage={validationErrors.email}
								placeholder="Enter Email"
								onChange={e => {
									validationErrors.email = '';
									setFormData({ ...formData, email: e.target.value });
									setDisableSubmit(false);
									setTabHasChange(true);
								}}
								readOnly={isReadOnlyForm}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<Input
								type="text"
								label="Mobile Phone"
								name="mobilePhone"
								value={formData.mobilePhone}
								error={!!validationErrors.mobilePhone}
								errorMessage={validationErrors.mobilePhone}
								placeholder={PHONE_NUMBER_PLACEHOLDER}
								mask={PHONE_NUMBER_MASK}
								onChange={e => {
									validationErrors.mobilePhone = '';
									const input = Helper.formatPhoneToDigits(e.target.value);
									setFormData({ ...formData, mobilePhone: input });
									setDisableSubmit(false);
									setTabHasChange(true);
								}}
								readOnly={isReadOnlyForm}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<Input
								type="text"
								label="Work Phone"
								name="workPhone"
								value={formData.workPhone}
								error={!!validationErrors.workPhone}
								errorMessage={validationErrors.workPhone}
								placeholder={PHONE_NUMBER_PLACEHOLDER}
								mask={PHONE_NUMBER_MASK}
								onChange={e => {
									validationErrors.workPhone = '';
									const input = Helper.formatPhoneToDigits(e.target.value);
									setFormData({ ...formData, workPhone: input });
									setDisableSubmit(false);
									setTabHasChange(true);
								}}
								readOnly={isReadOnlyForm}
							/>
						</div>
						<div className="col-md-2">
							<Input
								type="number"
								maxLength={5}
								label="Ext."
								name="workPhoneExtension"
								value={formData.workPhoneExtension}
								placeholder="12345"
								onKeyDown={event => checkIfNumber(event)}
								onChange={e => {
									const input = e.target.value;
									const inputLength = input.length;
									const maxLength = 5;
									if (
										input === '' ||
										(inputLength <= maxLength && /^[0-9]*$/.test(input))
									) {
										const parsedInput = parseInt(input);
										if (parsedInput >= 0 || input === '') {
											setFormData({ ...formData, workPhoneExtension: input });
											setDisableSubmit(false);
											setTabHasChange(true);
										}
									}
								}}
								readOnly={isReadOnlyForm}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<Input
								type="text"
								label="Home Phone"
								name="homePhone"
								value={formData.homePhone}
								error={!!validationErrors.homePhone}
								errorMessage={validationErrors.homePhone}
								placeholder={PHONE_NUMBER_PLACEHOLDER}
								mask={PHONE_NUMBER_MASK}
								onChange={e => {
									validationErrors.homePhone = '';
									const input = Helper.formatPhoneToDigits(e.target.value);
									setFormData({ ...formData, homePhone: input });
									setDisableSubmit(false);
									setTabHasChange(true);
								}}
								readOnly={isReadOnlyForm}
							/>
						</div>
					</div>

					{!isReadOnlyForm && (
						<div className="row">
							<div className="col-md-4">
								<Button
									type="submit"
									intent="primary"
									text="Save"
									large
									disabled={disableSubmit}
								/>
							</div>
						</div>
					)}
				</form>
			)}
		</>
	);
}
