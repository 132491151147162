import React, { useContext, useEffect } from 'react';
import { LoadingSpinner, myClasses, noResult, ToasterContext } from 'componentsV2';
import { RollCallDetailsContext } from '../../../RollCallDetails';
import { ReunificationStudentsEmptyState, ReunificationStudentsTable } from './components';
import { ReunificationContext } from '../Provider';
import { useReunficationStudents } from './hooks';
import { Intent } from '@blueprintjs/core';

export const ReunificationStudents = () => {
	const { filterationData, setFilterationData, setFilterationDisabled } = useContext(
		ReunificationContext,
	);

	const { alarmId, studentUpdateEvent } = useContext(RollCallDetailsContext);
	const {
		loading,
		setStudentsList,
		studentsList,
		totalStudents,
		isEmptyData,
		error,
		getStudentsList,
	} = useReunficationStudents(alarmId, filterationData);

	const toaster = useContext(ToasterContext);

	useEffect(() => {
		setFilterationDisabled(isEmptyData);
	}, [isEmptyData]);

	useEffect(() => {
		//student status updated
		if (
			studentUpdateEvent &&
			[
				'alarmStudents:reunificationUpdated',
				'reunificationGuardians:checkIn',
				'reunificationGuardians:undoCheckIn',
			].includes(studentUpdateEvent.name)
		) {
			getStudentsList(false);
		}
	}, [studentUpdateEvent]);

	useEffect(() => {
		if (error) {
			toaster(error, Intent.DANGER);
		}
	}, [error]);

	return (
		<>
			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<div className="reunfication-students-container">
					{isEmptyData && (
						<ReunificationStudentsEmptyState
							header="No students added to Reunification"
							description={
								<>
									Once Reunification is activated, students can be transferred to
									this list from Roll Call or by using the "Add student to
									Reunification" button.
								</>
							}
							icon={myClasses}
							showCircles={false}
						/>
					)}
					{!isEmptyData && (
						<>
							{totalStudents === 0 ? (
								<ReunificationStudentsEmptyState
									header="No results found"
									description={
										<>
											Your search didn’t match any student.
											<br /> Please try again.
										</>
									}
									icon={noResult}
								/>
							) : (
								<ReunificationStudentsTable
									studentsList={studentsList}
									setStudentsList={setStudentsList}
									totalStudents={totalStudents}
									filterData={filterationData}
									setFilterData={setFilterationData}
								/>
							)}
						</>
					)}
				</div>
			)}
		</>
	);
};
