import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FormModal, Button, FileUpload, LoadingModal} from "componentsV2";
import {TemplateService} from 'services/TemplateService';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

export const DownloadBaseFile = ({
	baseFile,
	changeStep,
	closeUploadModal
}) => {
	const [downloading, setDownloading] = useState(false);

	
	const downloadFile = () => {
		setDownloading(true)

		TemplateService.downloadFile(baseFile.coreTemplate, baseFile.id)
        .then(resp => {
			if (resp.statusCode === 200){
				const tempLink = document.createElement('a');
				tempLink.href = resp.data.url;
				tempLink.setAttribute('download', `${baseFile?.name}`);
				tempLink.click();
			}
        })
        .catch(error => {
        })
        .finally(() => {
			setDownloading(false)
        });
	}

	return (<>
		{downloading && <LoadingModal
			isOpen={true}
			loading={true}
		/>}
		{!downloading &&
			<FormModal
				isOpen={true}
				onClose={() => {}}
				title='Step 1 of 2: Approval and implementation signature'
				showCloseButton={false}
				footer={false}
				loading= {false}
				scrollContent={false}
				size='medium'
				subtitle="Download and Sign the Approval and Implementation Form."
				confirmBtn={{
					title: `Next`,
					onClick:changeStep,
					type:"primaryDefault",
					size:"md"
				}}
				cancelBtn={{
					title:'Cancel',
					type:"tertiary",
					size:"md",
					onClick:closeUploadModal,
				}}
			>       
				<div className='col-md-12'>                         
				<FileUpload
					identifier={`fileKey`}
					labelState = {false}
					label="File"
					allowedFiles = {['pdf', 'png', 'jpeg', 'jpg', 'txt', 'gif', 'doc', 'docx', 'xlsx', 'xls']}
					allowedFilesLegend = {`PDF, PNG, JPEG, GIF, TXT, DOCS, XLSX or XLS (max. ${MAX_FILE_UPLOADER_SIZE}mb)`}
					defaultErrorMessage = 'Upload failed, please try again'
					maxFileSize={MAX_FILE_UPLOADER_SIZE}
					file={baseFile}
					fileForEditing={true}
					showProgressBar={false}
					onFileInputChange = {() => {}}
					onDeleteFile={() => {}}
					showDeleteBtn={false}
					downloadText = 'Download'
					showDownloadAction={true}
					showReplaceAction={false}
					onDownloadClickHandler={downloadFile}
					disabled={false}
					showLegend={true}

				/>
				</div>
			</FormModal>
		}
	</>
	);
	
}

DownloadBaseFile.propTypes = {
	baseFile: PropTypes.object,
	changeStep: PropTypes.func,
};

export default DownloadBaseFile;
