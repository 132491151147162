/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Button } from '@blueprintjs/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import selectRoute from './selectors';
import Subsections from './subsections';

const Wrapper = styled.div`
	height: 100vh;
	overflow-y: scroll;
	box-shadow: inset 1px 0px 0px #d0d9e0;
	width: 400px;
	width: ${props => (props.expand ? '400px' : '70px')};
	position: relative;
	transition: all 0.3s ease;
	padding: 20px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	margin-bottom: 0;
	color: #000000;
	opacity: ${props => (props.expand ? '1' : '0')};
	transition: all 0.3s ease;
`;

const ToggleButton = styled(Button)`
	background: #ebf1f5 !important;
	border-radius: 8px !important;
	box-shadow: none !important;
	@media (max-width: 991px) {
		display: none !important;
	}
`;

const NodeWrapper = styled.div`
	opacity: ${props => (props.expand ? '1' : '0')};
	transition: all 0.3s ease;
`;

const ItemTitle = styled.span`
	display: block;
	text-align: left;
	cursor: pointer;
`;

const IconWrap = styled.div`
	position: absolute;
	left: 10px;
`;

const getColor = filledOut => {
	if (filledOut === 'true') {
		return '#128C5A';
	}
	if (filledOut === 'false') {
		return '#D9822B';
	}
	return '#000000';
};

const ColoredTitle = styled.span`
	color: ${props => getColor(props.filledOut)};
`;

const reOrder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	textAlign: 'right',

	// change background colour if dragging
	display: 'flex',
	flexDirection: 'column',

	// styles we need to apply on draggables
	...draggableStyle,
});

// const getQuestionListStyle = isDraggingOver => ({
// 	padding: 8,
// 	width: 350,
// });

const getQuestionListStyle = () => ({
	padding: 8,
	width: 350,
});

class SideBar extends React.Component {
	state = {
		expand: true,
	};

	onDragEnd = result => {
		const {
			dispatch,
			route: { template },
		} = this.props;
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const temp = template;
		if (result.type === 'SECTIONS') {
			const sections = reOrder(
				temp.sections,
				result.source.index,
				result.destination.index,
			);
			temp.sections = sections;
			dispatch({
				type: 'REORDER_SECTIONS',
				payload: temp,
			});
			let timerInterval;
			Swal.fire({
				title: 'Reordering',
				timer: 2500,
				showConfirmButton: false,
				timerProgressBar: true,
				willClose: () => {
					clearInterval(timerInterval);
				},
			});
		} else {
			const subsections = reOrder(
				temp.sections[parseInt(result.type, 10)].subsections,
				result.source.index,
				result.destination.index,
			);
			temp.sections[parseInt(result.type, 10)].subsections = subsections;
			dispatch({
				type: 'REORDER_SECTIONS',
				payload: temp,
			});
			let timerInterval;
			Swal.fire({
				title: 'Reordering',
				timer: 2500,
				showConfirmButton: false,
				timerProgressBar: true,
				willClose: () => {
					clearInterval(timerInterval);
				},
			});
		}
	};

	getTitle = item => (
		<ColoredTitle
			filledOut={item.complete ? item.complete.toString() : 'false'}
		>
			{item.title}
		</ColoredTitle>
	);

	render() {
		const {
			loading,
			route: { template },
			variableValues,
			scrollToSection,
			activeSection,
			sectionsPerPage,
			setPage,
			sectionRef,
			sidebarRef,
			viewMode
		} = this.props;
		const { expand } = this.state;
		if (loading) return null;
		return (
			<Wrapper id='sidebar-wrapper' expand={expand} ref={sidebarRef}>
				<div className="d-flex align-items-center mb-3">
					<ToggleButton
						onClick={() => this.setState({ expand: !expand })}
						icon={expand ? 'menu-open' : 'menu-closed'}
						secondary="true"
						className="mr-2"
					/>
					<Title expand={expand}>Plan Overview</Title>
				</div>
				<NodeWrapper expand={expand}>
					<DragDropContext
						onDragEnd={this.onDragEnd}
						onDragUpdate={this.onDragUpdate}
					>
						<Droppable droppableId="droppable" type="SECTIONS">
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									style={getQuestionListStyle(
										snapshot.isDraggingOver,
									)}
								>
									{template.sections.map((item, index) => {
										var page = Math.trunc(index / sectionsPerPage);
										if ( activeSection?.sectionId === item.id && activeSection.subSectionId === null ) {
											setPage(page);
										}
										return (
										<Draggable
											key={`${item.id}-${index}`}
											draggableId={item.id.toString()}
											index={index}
											isDragDisabled={viewMode}
										>
											{(p, s) => (
												<div
													ref={p.innerRef}
													{...p.draggableProps}
													style={getItemStyle(
														s.isDragging,
														p.draggableProps.style,
													)}
												>
													<div className="d-flex align-items-center mb-2">
														<span
															{...p.dragHandleProps}
														>
															<Icon
																icon="drag-handle-vertical"
																style={{
																	float:
																		'left',
																}}
															/>
														</span>
														<ItemTitle
															ref={activeSection?.sectionId === item.id ? sectionRef : undefined} 
															onClick={() => scrollToSection(item, null, 'section', page)}
														>
															{this.getTitle(
																item,
															)}
														</ItemTitle>
														{activeSection?.sectionId === item.id && activeSection?.subSectionId === null && (
															<IconWrap>
																<Icon
																	icon="dot"
																	color="#D9822B"
																/>
															</IconWrap>
														)}
													</div>

													<Subsections
														index={index}
														item={item}
														variableValues={
															variableValues
														}
														scrollToSection={
															scrollToSection
														}
														activeSection={
															activeSection
														}
														page={page}
														setPage={setPage}
														viewMode={viewMode}
													/>
												</div>
											)}
										</Draggable>
										)
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</NodeWrapper>
			</Wrapper>
		);
	}
}

SideBar.propTypes = {
	updateNodes: PropTypes.func,
	loading: PropTypes.bool,
	route: PropTypes.object,
	dispatch: PropTypes.func,
	variableValues: PropTypes.array,
	scrollToSection: PropTypes.func,
	activeSection: PropTypes.object,
	sectionsPerPage: PropTypes.number,
	setPage: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(SideBar);
