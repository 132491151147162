import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import '../css/Filteration.scss';
import {
	DropdownMultiSelect,
	FilterBar,
	ContentSwitcher,
	SearchBar,
	Button,
	formatDate,
} from 'componentsV2';
import { ACTIVE_ALARMS_TYPE, ADVANCED_FILTER_MODAL, PAST_ALARMS_TYPE } from '../../consts';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { RapidAlarmsContext } from '../../Providers';
import { AdvancedFilterModal } from './AdvancedFilterModal';
import { AlarmsListContext } from '..';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { filterSitesPerPermissionCode } from 'utilities/permissions';

export const Filteration = ({
	setFilterationData,
	filterationData,
	onChange = () => {},
	disabled = false,
	setSelectedSites,
}) => {
	const {
		editPermissionOnRapidAlarm,
		editPermissionOnReunification,
		setModalToShow,
		modalToShow,
	} = useContext(RapidAlarmsContext);
	const {
		filterationInitialState: initialStates,
		setAlarmType,
		alarmType,
		alarmSites,
	} = useContext(AlarmsListContext);

	const permissions = useSelector(state => state.route.permissions);
	const pastAlarmSites = filterSitesPerPermissionCode('respond_edit', permissions, alarmSites);

	const RapidAlaramTabs = [
		{ id: ACTIVE_ALARMS_TYPE, label: 'Active' },
		{ id: PAST_ALARMS_TYPE, label: 'Past' },
	];

	const onSwitchTabHandler = tab => {
		if (tab && tab[0]) {
			setAlarmType(tab[0]);
		}
	};

	const onSearchHandler = search => {
		setFilterationData(oldData => ({
			...oldData,
			search,
			page: 1,
		}));
		onChange();
	};

	const onChangeSiteHandler = sites => {
		setFilterationData(oldData => ({
			...oldData,
			sites,
			page: 1,
		}));
		setSelectedSites(sites);
		onChange();
	};

	const isFilterBarShown = () => {
		return (
			filterationData.startDate ||
			filterationData.endDate ||
			(filterationData.alarmModes && filterationData.alarmModes.length > 0) ||
			(filterationData.alarmTypes && filterationData.alarmTypes.length > 0) ||
			filterationData.triggeredCellular
		);
	};

	const onRemoveFilterBar = filterName => {
		setFilterationData(oldData => ({
			...oldData,
			[filterName]: initialStates[filterName],
		}));
		onChange();
	};

	const filterMenuItems = [
		{
			title: 'Start date',
			name: 'startDate',
			values: filterationData.startDate
				? [formatDate(filterationData.startDate, DateTime.DATE_SHORT)]
				: [],
		},
		{
			title: 'End date',
			name: 'endDate',
			values: filterationData.endDate
				? [formatDate(filterationData.endDate, DateTime.DATE_SHORT)]
				: [],
		},
		{
			title: 'Alarm modes',
			name: 'alarmModes',
			values: filterationData.alarmModes
				? filterationData.alarmModes.map(alarmMode => alarmMode.label)
				: [],
		},
		{
			title: 'Alarm types',
			name: 'alarmTypes',
			values: filterationData.alarmTypes
				? filterationData.alarmTypes.map(alarmType => alarmType.label)
				: [],
		},
		{
			title: 'Triggered by 911',
			name: 'triggeredCellular',
			values: filterationData.triggeredCellular ? ['Triggered by 911'] : [],
		},
	];

	return (
		<section className="rapidAlarm-filteraion">
			<div className="rapidAlarm-filteraion-inputs-container">
				<div className="rapidAlarm-filteraion-inputs-container-left-side">
					{editPermissionOnReunification || editPermissionOnRapidAlarm ? (
						<ContentSwitcher
							onClick={onSwitchTabHandler}
							tabs={RapidAlaramTabs}
							multi={false}
							defaultActiveTabs={[alarmType]}
							preserveTab={true}
							useInput={true}
						/>
					) : (
						<h3 className="rapidAlarm-title-header">Active alarms</h3>
					)}
				</div>
				<div className="rapidAlarm-filteraion-inputs-container-right-side">
					<SearchBar
						placeholder="Search alarm types..."
						icon="search"
						className="rapid-alarms-saarch-bar"
						value={filterationData.search}
						onSearch={value => onSearchHandler(value)}
						disabled={disabled}
					/>
					{alarmType === ACTIVE_ALARMS_TYPE && (
						<DropdownMultiSelect
							label={null}
							values={filterationData.sites}
							placeholder="Select a Site"
							onChange={value => onChangeSiteHandler(value)}
							visibleOptions={1}
							labelState={false}
							allowSelectAll={false}
							disabled={disabled}
							options={alarmSites}
							className="rapid-alarms-sites-ddl"
						/>
					)}
					{alarmType === PAST_ALARMS_TYPE && (
						<SitesDropdownMultiSelect
							label={null}
							value={filterationData.sites}
							placeholder="Select a Site"
							onChange={value => onChangeSiteHandler(value)}
							visibleOptions={1}
							labelState={false}
							allowSelectAll={false}
							disabled={disabled}
							sitesList={pastAlarmSites}
							className="rapid-alarms-sites-ddl"
						/>
					)}
					<Button
						type="tertiary"
						size="md"
						text="Filters"
						icon="horizontal-bar-chart-desc"
						intent="default"
						disabled={disabled}
						onClick={() => setModalToShow(ADVANCED_FILTER_MODAL)}
					/>
					{modalToShow === ADVANCED_FILTER_MODAL && (
						<AdvancedFilterModal
							setFilterationData={setFilterationData}
							filterationData={filterationData}
							initialStates={initialStates}
							onChange={onChange}
						/>
					)}
				</div>
			</div>
			{isFilterBarShown() && (
				<FilterBar
					items={filterMenuItems}
					removeFilter={onRemoveFilterBar}
					isShown={true}
				/>
			)}
		</section>
	);
};

Filteration.propTypes = {
	setFilterationData: PropTypes.func.isRequired,
	filterationData: PropTypes.object,
};
