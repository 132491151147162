import React from 'react';
import { EmptyState, noResult } from 'componentsV2';
import { AlarmSubmittedClassRow } from './AlarmSubmittedClassRow';
import './css/SubmittedClasses.scss';

export const AlarmSubmittedClasses = ({ alarmId, submittedClasses = [], history }) => {
	return (
		<div className="alarm-card-container">
			<div className="alarm-card-header">
				<div className="alarm-card-title">Submitted classes</div>
			</div>
			<div
				className={`alarm-submitted-classes-container ${
					submittedClasses.length === 0 ? 'alarm-submitted-classes-empty' : ''
				} `}
			>
				{submittedClasses.length === 0 ? (
					<EmptyState
						header="No classes to show right now"
						icon={noResult}
						showCircles={false}
					/>
				) : (
					<>
						<div className="alarm-submitted-classes-header">
							<div className="alarm-submitted-classes-column1">Class</div>
							<div className="alarm-submitted-classes-column2">Submitted by</div>
							<div className="alarm-submitted-classes-column3" />
						</div>
						<div
							className={`alarm-submitted-classes-body ${
								submittedClasses.length == 1 ? 'one-item' : ''
							}`}
						>
							{submittedClasses.map((submittedClass, key) => (
								<AlarmSubmittedClassRow
									key={`submittedClass-${key}`}
									submittedClass={submittedClass}
									history={history}
									alarmId={alarmId}
								/>
							))}
						</div>
					</>
				)}
			</div>
		</div>
	);
};
