import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import routeReducer from './routeReducer';

const rootReducer = {
	route: routeReducer,
	routing: routerReducer,
};

export default function createReducer(injectedReducers) {
	return combineReducers({
		...rootReducer,
		...injectedReducers,
	});
}
