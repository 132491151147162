import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Select } from '@blueprintjs/select';
import { Icon, MenuItem } from '@blueprintjs/core';
import Card from './card';

const DropdownHeaderWrap = styled.div`
	p {
		color: #10161a;
		font-size: 16px;
		line-height: 22px;
		font-style: normal;
		margin-bottom: 4px;
		font-weight: normal;
		font-family: Nunito Sans;
	}
`;

const DropdownWrap = styled.div`
	padding: 10px;
	background: #ffffff;
	border: 1px solid #d8e1e8;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SelectWrap = styled(Select)`
	svg {
		margin-left: 10px;
	}
`;

class Dashboard extends React.Component {
	state = {};

	makeBuildings = item => {
		const { changeBuilding } = this.props;
		return (
			<MenuItem
				key={item.id}
				onClick={() => changeBuilding(item)}
				text={item.name}
			/>
		);
	};

	render() {
		const {
			buildings,
			selectedBuilding,
			assessments,
			history,
		} = this.props;
		return (
			<div>
				<SelectWrap
					items={buildings}
					itemRenderer={this.makeBuildings}
					className="w-100"
					filterable={false}
				>
					<DropdownHeaderWrap>
						<p>Select Site</p>
					</DropdownHeaderWrap>
					<DropdownWrap>
						<div>
							{selectedBuilding
								? selectedBuilding.name
								: 'Select'}
						</div>
						<Icon icon="caret-down" />
					</DropdownWrap>
				</SelectWrap>
				<div className="row mt-3">
					{assessments.map(item => (
						<Card
							item={item}
							history={history}
							key={`asssements-${item.id}`}
						/>
					))}
				</div>
			</div>
		);
	}
}

Dashboard.propTypes = {
	changeBuilding: PropTypes.func,
	buildings: PropTypes.any,
	selectedBuilding: PropTypes.any,
	assessments: PropTypes.any,
	history: PropTypes.object,
};

export default Dashboard;
