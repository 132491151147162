import React, { useContext, useEffect, useState } from 'react';
import { RapidAlarmService } from 'services';
import { BootstrapedTable, sortingStyle, Checkbox, TableCell, Tooltip } from 'componentsV2';
import { StudentActionsList } from './';
import {
	ALARM_STUDENT_STATUS_ABSENT,
	ALARM_STUDENT_STATUS_ACCOUNTED,
	ALARM_STUDENT_STATUS_EXCEPTION,
	ALARM_STUDENT_STATUS_NEED_ASSISTANCE,
} from '../../../../consts';
import { RollCallDetailsContext } from '../../../RollCallDetails';
import { RollCallAndReunificationContext } from '../../RollCallAndReunification';
import './css/StudentsTable.scss';
import { STUDENTS_LIST } from '../consts';
import { ADD_NOTE_MODAL } from '../../Modals/consts';

export const StudentsTable = ({
	studentsList,
	setStudentsList,
	totalStudents,
	filterData,
	setFilterData,
	listType,
	borderNone = false,
	rosterId = null,
}) => {
	const { alarmId, studentUpdateEvent } = useContext(RollCallDetailsContext);
	const [studentChecked, setStudentChecked] = useState(null);
	const [tableKey, setTableKey] = useState(0);

	const {
		setModalToShow,
		setSelectedStudent,
		reunificationNotStarted,
		reunificationEnded,
		requestId,
	} = useContext(RollCallAndReunificationContext);
	const reunificationNotActivated = reunificationNotStarted || reunificationEnded;

	const updateStudentReunificationStatus = eventData => {
		setStudentsList(studentsList =>
			studentsList.map(studentItem => {
				if (studentItem.id === eventData.id) {
					return {
						...studentItem,
						reunificationStatus: eventData.tranferStatus ? 'T' : false,
						isTransferToReunification: eventData.isTransferToReunification
							? eventData.isTransferToReunification
							: false,
					};
				}
				return studentItem;
			}),
		);
	};

	useEffect(() => {
		//student reunification status updated
		if (
			studentUpdateEvent &&
			studentUpdateEvent.name === 'alarmStudents:reunificationUpdated'
		) {
			updateStudentReunificationStatus(studentUpdateEvent.data);
		}
	}, [studentUpdateEvent]);

	useEffect(() => {
		forceRerender();
	}, [studentChecked, reunificationNotActivated]);

	// Force rerender the component to use the latest values for checkboxes
	const forceRerender = () => {
		setTableKey(prevKey => prevKey + 1);
	};

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'student',
			dataField: 'student',
			text: "Student's name",
			sort: true,
			sortCaret: (order, column) => sortingStyle(filterData.sortDir, column),
			formatter: (value, row) => (
				<TableCell
					text={`${row.firstName} ${
						row.middleName?.length > 0 ? row.middleName.slice(0, 1) + '.' : ''
					} ${row.lastName}`}
					supportText={listType === STUDENTS_LIST ? <>Grade: {row.grade}</> : null}
				/>
			),
		},
		{
			key: 'accountedFor',
			dataField: 'accountedFor',
			text: 'Accounted for',
			style: {
				width: '125px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() => changeStudenStatus(row, ALARM_STUDENT_STATUS_ACCOUNTED)}
						checked={row.respondStatus === ALARM_STUDENT_STATUS_ACCOUNTED}
						size="large"
						color="success"
					/>
				</div>
			),
		},
		{
			key: 'needsAssistance',
			dataField: 'needsAssistance',
			text: 'Needs assistance',
			style: {
				width: '100px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() =>
							changeStudenStatus(row, ALARM_STUDENT_STATUS_NEED_ASSISTANCE)
						}
						checked={row.respondStatus === ALARM_STUDENT_STATUS_NEED_ASSISTANCE}
						size="large"
						color="error"
					/>
				</div>
			),
		},
		{
			key: 'notOnCampus',
			dataField: 'notOnCampus',
			text: 'Not on campus',
			style: {
				width: '100px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() => changeStudenStatus(row, ALARM_STUDENT_STATUS_ABSENT)}
						checked={row.respondStatus === ALARM_STUDENT_STATUS_ABSENT}
						size="large"
						color="gray"
					/>
				</div>
			),
		},
		{
			key: 'exception',
			dataField: 'exception',
			text: 'Exception',
			style: {
				width: '100px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			formatter: (value, row) => (
				<div className="cell-center">
					<Checkbox
						onChange={() => changeStudenStatus(row, ALARM_STUDENT_STATUS_EXCEPTION)}
						checked={row.respondStatus === ALARM_STUDENT_STATUS_EXCEPTION}
						size="large"
						color="primary"
					/>
				</div>
			),
		},
		{
			key: 'transferToReunify',
			dataField: 'transferToReunify',
			text: 'Transfer to reunify',
			style: {
				width: '100px',
			},
			headerStyle: {
				textAlign: 'center',
			},
			classes: `transfer-to-reunify ${reunificationNotActivated ? 'disabled' : ''}`,
			formatter: (value, row) => {
				const isDisabled =
					reunificationNotActivated ||
					(!!row.reunificationStatus && row.reunificationStatus !== 'T');
				const tooltipTitle = reunificationNotActivated
					? 'Reunification is not activated'
					: 'Student has been checked-in to reunification';
				const WrapperComponent = ({ children }) =>
					isDisabled ? (
						<Tooltip tooltipTitle={tooltipTitle}>{children}</Tooltip>
					) : (
						<>{children}</>
					);

				return (
					<WrapperComponent>
						<div className="cell-center">
							<Checkbox
								onChange={() => transferToReunifyStudent(row)}
								checked={row.isTransferToReunification}
								size="large"
								color="orange"
								disabled={isDisabled}
							/>
						</div>
					</WrapperComponent>
				);
			},
		},
		{
			key: 'actions',
			text: 'Actions',
			dataField: 'id',
			style: {
				width: '100px',
			},
			formatter: (value, row) => <StudentActionsList student={row} />,
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			if (sortField == 'student') {
				setFilterData(oldData => ({
					...oldData,
					sortKey: 'studentName',
					sortDir: oldData.sortDir == 'desc' ? 'asc' : 'desc',
				}));
			}
		}
	};

	const setCurrentPage = value => {
		setFilterData(oldData => ({ ...oldData, page: value }));
	};

	const setRowsPerPage = value => {
		setFilterData(oldData => ({ ...oldData, perPage: value }));
	};

	const changeStudenStatus = (student, status) => {
		const respondStatus = student.respondStatus === status ? null : status;

		// display add note modal if the selected status is exception
		if (respondStatus === ALARM_STUDENT_STATUS_EXCEPTION) {
			setSelectedStudent(student);
			setModalToShow(ADD_NOTE_MODAL);
		}

		setStudentChecked({
			studentId: student.id,
		});

		setStudentsList(studentsList =>
			studentsList.map(studentItem => {
				if (studentItem.id === student.id) {
					return { ...studentItem, respondStatus };
				}
				return studentItem;
			}),
		);

		const params = {
			students: [
				{
					studentId: student.id,
					status: respondStatus,
					rosterId: listType === STUDENTS_LIST ? student.rosterId : rosterId,
				},
			],
			requestId,
		};
		RapidAlarmService.updateStudentsStatus(alarmId, params)
			.then()
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				forceRerender();
				setStudentChecked(null);
			});
	};

	const transferToReunifyStudent = student => {
		const transferStatus = student.reunificationStatus !== 'T';

		setStudentsList(studentsList =>
			studentsList.map(studentItem => {
				if (studentItem.id === student.id) {
					return {
						...studentItem,
						reunificationStatus: student.reunificationStatus !== 'T' ? 'T' : null,
					};
				}
				return studentItem;
			}),
		);

		RapidAlarmService.transferToReunifyStudent(alarmId, student.id, transferStatus)
			.then()
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				forceRerender();
			});
	};

	return (
		<BootstrapedTable
			keyField="id"
			key={tableKey}
			className="rollcall-students-table-list-container"
			data={studentsList}
			columns={columns}
			totalRows={totalStudents}
			currentPage={filterData.page}
			rowsPerPage={filterData.perPage}
			setCurrentPage={setCurrentPage}
			setRowsPerPage={setRowsPerPage}
			onTableChange={onTableChange}
			borderNone={borderNone}
		/>
	);
};
