import React, { useEffect, useState } from 'react';
import { generateAvatar } from 'pages/App/AvatarInitials';
import { Position, Popover } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { Helper } from 'utilities/helper';
import './css/ContactsCard.scss';
import { PHONE_TYPES } from 'utilities/constants';
import { TableCell } from 'componentsV2';

const CardItemRow = ({ title, value }) => (
	<div className="contact-card-label">
		<label className="title"> {title} </label>
		<p className="value"> {value ? value : 'N/A'} </p>
	</div>
);
export const ContactCard = ({ contact }) => {
	const [isOpen, setIsOpen] = useState(false);
	const mobileNumber = contact.person.phones.find(phone => phone.type == PHONE_TYPES.MOBILE);
	const homeNumber = contact.person.phones.find(phone => phone.type == PHONE_TYPES.HOME);
	const workNumber = contact.person.phones.find(phone => phone.type == PHONE_TYPES.WORK);
	const [avatarImg, setAvatarImg] = useState('');

	const name = contact.person.name.givenName.charAt(0) + contact.person.name.familyName.charAt(0);
	const avatarInitials = generateAvatar(name);

	useEffect(() => {
		if (contact.img_link) {
			setAvatarImg(
				<img
					src={contact.img_link}
					className="contact-profile-img"
					alt={contact.person.name.givenName}
				/>,
			);
		} else {
			setAvatarImg(
				<img
					src={avatarInitials}
					className="contact-profile-img"
					alt={contact.person.name.givenName}
				/>,
			);
		}
	}, [contact]);

	return (
		<div className="contacts-card-parent">
			<TableCell
				text={
					<p onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
						{contact.person.name.givenName} {contact.person.name.familyName}
					</p>
				}
				supportText={contact.person.name.title}
			/>
			{isOpen && (
				<Popover
					isOpen={isOpen}
					position={Position.RIGHT}
					enforceFocus={false}
					className="contacts-card-popover"
					modifiers={{
						arrow: {
							enabled: false,
						},
					}}
				>
					<i className="card-left-arrow" />
					<div className="cardContent_Wrapper">
						<div className="row">
							<div className="col-md-4">{avatarImg}</div>
							<div className="col-md-8 contactInfo_wrapper">
								<h3 className="contact-card-title">
									{contact.person.name.givenName} {contact.person.name.familyName}
								</h3>
								<CardItemRow title="Title" value={contact.person.name.title} />
								<CardItemRow
									title="Mobile"
									value={Helper.formatDigitsToPhone(mobileNumber?.phoneNumber)}
								/>
								<CardItemRow
									title="Work phone"
									value={
										Helper.formatDigitsToPhone(workNumber?.phoneNumber) +
										(workNumber?.extension && workNumber?.extension != ''
											? ' ext.' + workNumber.extension
											: '')
									}
								/>
								<CardItemRow
									title="Home phone"
									value={Helper.formatDigitsToPhone(homeNumber?.phoneNumber)}
								/>
								<CardItemRow
									title="Email"
									value={contact.person.email.emailAddress}
								/>
							</div>
						</div>
					</div>
				</Popover>
			)}
		</div>
	);
};

ContactCard.propTypes = {
	contact: PropTypes.object.isRequired,
};
