import React, { useEffect, useState } from 'react';
import { Badge } from 'componentsV2';

export const CheckinGuardianStatus = ({ item, includeReunify = true, disabled = false }) => {
	const [type, setType] = useState('');
	const [text, setText] = useState('');
	const setStatuses = () => {
		let type = '';
		let text = '';
		if (includeReunify && item.reunified) {
			type = 'Gray';
			text = 'Reunified';
		} else if (item.checkIn) {
			switch (item.checkIn.status) {
				case 'checkedIn':
					type = 'Success';
					text = 'Checked-in';
					break;
				case 'absent':
				case 'transferred':
					type = 'Warning';
					text = 'Not Checked-in';
					break;
				default:
					type = 'Primary';
					text = item.checkIn.status;
			}
		} else {
			if (!disabled) {
				type = 'Warning';
				text = 'Not Checked-in';
			}
		}
		setType(type);
		setText(text);
	};
	useEffect(() => {
		setStatuses();
	}, []);

	return <Badge type={type} size="md" text={text} />;
};
