import React from 'react';
import PropTypes from 'prop-types';
import { SearchBar, Badge } from 'componentsV2';
import './css/ExistingContactFilteration.scss';
export const ExistingContactFilteration = ({
	dispatchFilterationData,
	filterationData,
	selectedRows,
	unselect,
}) => {
	return (
		<section className="ExistingContact-filteraion">
			<div className="col-md-8 searchWrapper">
				<SearchBar
					placeholder="Search by name, or email..."
					onSearch={combineSearch =>
						dispatchFilterationData({
							type: 'combineSearch',
							payload: combineSearch,
						})
					}
					value={filterationData.combineSearch}
				/>
			</div>
			<div>
				{selectedRows.length > 0 && (
					<Badge
						key="status"
						type="md"
						size={'Gray'}
						text={selectedRows.length + ' selected'}
						hasCloseIcon={true}
						onClick={unselect}
					/>
				)}
			</div>
		</section>
	);
};

ExistingContactFilteration.propTypes = {
	filterationData: PropTypes.object,
	dispatchFilterationData: PropTypes.func.isRequired,
	selectedRows: PropTypes.array,
};
