import React from 'react';
import { Checkbox } from 'componentsV2';
import PropTypes from 'prop-types';

export const AlarmPriority = ({ formData, formErrors, onChangeFormData, disabled = false }) => (
	<Checkbox
		label="Yes"
		onChange={e => onChangeFormData({ type: 'priority', payload: e.target.checked })}
		checked={formData.priority}
		error={formErrors?.priority ? true : false}
		errorMessage={formErrors?.priority}
		disabled={disabled}
	/>
);

AlarmPriority.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
	disabled: PropTypes.bool,
};
