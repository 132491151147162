import React from 'react';
import "./css/FileIcon.scss"

import empty from "./assets/empty.svg";
import empty_gray from "./assets/empty_gray.svg";
import empty_solid from "./assets/empty_solid.svg";

import folder from "./assets/folder.svg";
import folder_gray from "./assets/folder_gray.svg";
import folder_solid from "./assets/folder_solid.svg";

import image from "./assets/image.svg";
import image_gray from "./assets/image_gray.svg";
import image_solid from "./assets/image_solid.svg";

import video from "./assets/video.svg";
import video_gray from "./assets/video_gray.svg";
import video_solid from "./assets/video_solid.svg";

import play from "./assets/play.svg";
import play_gray from "./assets/play_gray.svg";
import play_solid from "./assets/play_solid.svg";

import music from "./assets/music.svg";
import music_gray from "./assets/music_gray.svg";
import music_solid from "./assets/music_solid.svg";

import pdf from "./assets/pdf.svg";
import pdf_gray from "./assets/pdf_gray.svg";
import pdf_solid from "./assets/pdf_solid.svg";

import doc from "./assets/doc.svg";
import doc_gray from "./assets/doc_gray.svg";
import doc_solid from "./assets/doc_solid.svg";

import docx from "./assets/docx.svg";
import docx_gray from "./assets/docx_gray.svg";
import docx_solid from "./assets/docx_solid.svg";

import txt from "./assets/txt.svg";
import txt_gray from "./assets/txt_gray.svg";
import txt_solid from "./assets/txt_solid.svg";

import csv from "./assets/csv.svg";
import csv_gray from "./assets/csv_gray.svg";
import csv_solid from "./assets/csv_solid.svg";

import xls from "./assets/xls.svg";
import xls_gray from "./assets/xls_gray.svg";
import xls_solid from "./assets/xls_solid.svg";

import xlsx from "./assets/xlsx.svg";
import xlsx_gray from "./assets/xlsx_gray.svg";
import xlsx_solid from "./assets/xlsx_solid.svg";

import ppt from "./assets/ppt.svg";
import ppt_gray from "./assets/ppt_gray.svg";
import ppt_solid from "./assets/ppt_solid.svg";

import pptx from "./assets/pptx.svg";
import pptx_gray from "./assets/pptx_gray.svg";
import pptx_solid from "./assets/pptx_solid.svg";

import document from "./assets/document.svg";
import document_gray from "./assets/document_gray.svg";
import document_solid from "./assets/document_solid.svg";

import safety_plan from "./assets/folder.svg";
import safety_plan_gray from "./assets/folder_gray.svg";
import safety_plan_solid from "./assets/folder_solid.svg";

import call_list from "./assets/call_list.svg";
import call_list_gray from "./assets/call_list_gray.svg";
import call_list_solid from "./assets/call_list_solid.svg";

import map from "./assets/image.svg";
import map_gray from "./assets/image_gray.svg";
import map_solid from "./assets/image_solid.svg";

import assessment from "./assets/assessment.svg";
import assessment_gray from "./assets/assessment_gray.svg";
import assessment_solid from "./assets/assessment_solid.svg";

import mp3 from "./assets/mp3.svg";
import mp3_gray from "./assets/mp3_gray.svg";
import mp3_solid from "./assets/mp3_solid.svg";

import wav from "./assets/wav.svg";
import wav_gray from "./assets/wav_gray.svg";
import wav_solid from "./assets/wav_solid.svg";

import mp4 from "./assets/mp4.svg";
import mp4_gray from "./assets/mp4_gray.svg";
import mp4_solid from "./assets/mp4_solid.svg";

import mpeg from "./assets/mpeg.svg";
import mpeg_gray from "./assets/mpeg_gray.svg";
import mpeg_solid from "./assets/mpeg_solid.svg";

import avi from "./assets/avi.svg";
import avi_gray from "./assets/avi_gray.svg";
import avi_solid from "./assets/avi_solid.svg";

import mkv from "./assets/mkv.svg";
import mkv_gray from "./assets/mkv_gray.svg";
import mkv_solid from "./assets/mkv_solid.svg";

import zip from "./assets/zip.svg";
import zip_gray from "./assets/zip_gray.svg";
import zip_solid from "./assets/zip_solid.svg";

import rar from "./assets/rar.svg";
import rar_gray from "./assets/rar_gray.svg";
import rar_solid from "./assets/rar_solid.svg";

import note from "./assets/note.svg";

export const fileIconsList = {
    empty: empty,
    empty_gray: empty_gray,
    empty_solid: empty_solid,

    folder: folder,
    folder_gray: folder_gray,
    folder_solid: folder_solid,

    image: image,
    image_gray: image_gray,
    image_solid: image_solid,

    video: video,
    video_gray: video_gray,
    video_solid: video_solid,

    play: play,
    play_gray: play_gray,
    play_solid: play_solid,

    music: music,
    music_gray: music_gray,
    music_solid: music_solid,

    pdf: pdf,
    pdf_gray: pdf_gray,
    pdf_solid: pdf_solid,

    doc: doc,
    doc_gray: doc_gray,
    doc_solid: doc_solid,

    docx: docx,
    docx_gray: docx_gray,
    docx_solid: docx_solid,

    txt: txt,
    txt_gray: txt_gray,
    txt_solid: txt_solid,

    csv: csv,
    csv_gray: csv_gray,
    csv_solid: csv_solid,

    xls: xls,
    xls_gray: xls_gray,
    xls_solid: xls_solid,

    xlsx: xlsx,
    xlsx_gray: xlsx_gray,
    xlsx_solid: xlsx_solid,

    ppt: ppt,
    ppt_gray: ppt_gray,
    ppt_solid: ppt_solid,

    pptx: pptx,
    pptx_gray: pptx_gray,
    pptx_solid: pptx_solid,

    document: document,
    document_gray: document_gray,
    document_solid: document_solid,

    safety_plan: safety_plan,
    safety_plan_gray: safety_plan_gray,
    safety_plan_solid: safety_plan_solid,

    call_list: call_list,
    call_list_gray: call_list_gray,
    call_list_solid: call_list_solid,

    map: map,
    map_gray: map_gray,
    map_solid: map_solid,

    assessment: assessment,
    assessment_gray: assessment_gray,
    assessment_solid: assessment_solid,

    mp3: mp3,
    mp3_gray: mp3_gray,
    mp3_solid: mp3_solid,

    wav: wav,
    wav_gray: wav_gray,
    wav_solid: wav_solid,

    mp4: mp4,
    mp4_gray: mp4_gray,
    mp4_solid: mp4_solid,

    mpeg: mpeg,
    mpeg_gray: mpeg_gray,
    mpeg_solid: mpeg_solid,

    avi: avi,
    avi_gray: avi_gray,
    avi_solid: avi_solid,

    mkv: mkv,
    mkv_gray: mkv_gray,
    mkv_solid: mkv_solid,

    zip: zip,
    zip_gray: zip_gray,
    zip_solid: zip_solid,

    rar: rar,
    rar_gray: rar_gray,
    rar_solid: rar_solid,

    note: note,
}

export const FileIcon = ({
    icon = null,
    sourceURL = ''
}) => {

    let iconToShow = icon ? fileIconsList[icon] ? icon : "empty" : "empty";

    return (
        <>
            {icon && !sourceURL ? (
                <img src={fileIconsList[iconToShow]} alt={icon} />
            ) : (
                <img src={sourceURL}  className="thumbnail-file-icon"/>
            )}
        </>
    )
}
