import React from 'react';
import { Button, Modal } from 'componentsV2';
import './css/EmptyTextModal.scss';

export const EmptyTextModal = ({
	isOpen,
	handleClose,
	selectedShape,
	floorplanDetails,
	setReloadMap,
}) => {
	const footer = (
		<div className="button-div-styles">
			<Button
				text={'No, go back'}
				type="tertiary"
				intent="default"
				onClick={() => {
					const textarea = document.getElementsByTagName('textarea')[0];
					textarea.focus();
					handleClose();
				}}
			/>
			<Button
				text={'Yes, continue'}
				intent="default"
				onClick={() => {
					handleOk();
				}}
			/>
		</div>
	);

	const handleOk = () => {
		if (selectedShape) {
			setReloadMap(oldValue => !oldValue);

			let oldText = floorplanDetails.shapes.filter(
				item => item.id === parseInt(selectedShape.attrs.id),
			)[0];

			const textarea = document.getElementsByTagName('textarea')[0];

			selectedShape.text(oldText.title);
			selectedShape.show();
			textarea.parentNode.removeChild(textarea);

			handleClose();
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			title={'Text cannot be empty. Are you sure you want to lose your changes?'}
			customClassName="map-container-modals"
			icon={'warning-sign'}
			iconColor={'warning'}
			footer={footer}
		/>
	);
};
