import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormModal, Button, Intent, FeaturedIcon, AlertModal } from 'componentsV2';
import { ToasterContext } from 'pages/App';

const DeleteSectionModal = ({
	isOpen,
	type,
	sectionIndex,
	subsectionIndex,
	deletedSection,
	closeDeleteModal,
	onDeleteAndPublishSection,
	scrollToSection,
	template,
}) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const toaster = useContext(ToasterContext);
	const [openAlert, setOpenAlert] = useState(false);

	const deleteSection = async () => {
		// Delete section
		await dispatch({
			type: 'REMOVE_SECTION_NEW',
			sectionId: deletedSection.actualSectionId,
		});
		// scroll to next section
		let nextSection = template.sections.find(
			(templateSection, index) => index === sectionIndex + 1,
		);
		if (!nextSection) {
			nextSection = template.sections.find(
				(templateSection, index) => index === sectionIndex - 1,
			);
		}
		if (nextSection !== undefined && nextSection !== null) {
			scrollToSection(nextSection, null, 'section');
		}
	};

	const deleteSubseciton = async () => {
		// Delete sub section
		await dispatch({
			type: 'REMOVE_SUBSECTION_NEW',
			sectionId: deletedSection.actualSectionId,
			subsectionId: deletedSection.actualSubsectionId,
		});
		// scroll to next sub section
		const currentSection = template.sections.find(
			(templateSection, index) => index === sectionIndex,
		);
		if (currentSection) {
			let nextSubSection = currentSection.subsections.find(
				(templateSection, index) => index === subsectionIndex,
			);
			if (!nextSubSection) {
				nextSubSection = currentSection.subsections.find(
					(templateSection, index) => index === subsectionIndex - 1,
				);
			}
			if (nextSubSection !== undefined && nextSubSection !== null) {
				scrollToSection(currentSection, nextSubSection, 'subsection');
			} else {
				scrollToSection(currentSection, null, 'section');
			}
		}
	};

	const deleteSectionSubsection = async (publish = false) => {
		setIsLoading(true);

		if (publish === true) {
			try {
				await onDeleteAndPublishSection();

				if (type === 'section') {
					deleteSection();
				} else {
					deleteSubseciton();
				}
				closeDeleteModal();
				setIsLoading(false);
				toaster(
					`You have successfully deleted and published "${deletedSection.title}".`,
					Intent.NONE,
					<FeaturedIcon icon="trash" type="Gray" size="md" shape="circleIcon" />,
				);
			} catch (error) {
				setOpenAlert(true);
			}
		} else {
			if (type === 'section') {
				deleteSection();
			} else {
				deleteSubseciton();
			}

			closeDeleteModal();
			setIsLoading(false);
			toaster(
				`You have successfully deleted "${deletedSection.title}".`,
				Intent.NONE,
				<FeaturedIcon icon="trash" type="Gray" size="md" shape="circleIcon" />,
			);
		}
	};

	const leftFooter = (
		<Button
			text="No, go back"
			type="ghostDefault"
			size="md"
			onClick={closeDeleteModal}
			className="left-button"
		/>
	);

	const title = (
		<>
			<p>Once deleted, this cannot be recovered.</p>
			{template.createdFromDistrict === true ? (
				<>
					<br />
					<p>
						Selecting “Delete” will update the template. “Delete & Publish” will update
						the template and push out an update.
					</p>
				</>
			) : null}
		</>
	);

	const handleConfirmButton = () => {
		template.createdFromDistrict === true
			? deleteSectionSubsection(true)
			: deleteSectionSubsection();
	};

	const handleCancelButton = () => {
		template.createdFromDistrict === true ? deleteSectionSubsection() : closeDeleteModal();
	};

	const handleAlertClose = () => {
		closeDeleteModal();
		setOpenAlert(false);
	};
	return (
		<>
			<FormModal
				isOpen={isOpen}
				title={`Are you sure you want to delete "${deletedSection?.title}"?`}
				subtitle={title}
				loading={isLoading}
				onClose={closeDeleteModal}
				icon="trash"
				iconColor="error"
				customClassName="delete-modal-wraper"
				leftFooter={template.createdFromDistrict === true ? leftFooter : null}
				confirmBtn={{
					title:
						template.createdFromDistrict === true
							? 'Yes, delete and publish it'
							: 'Yes, delete it',
					onClick: handleConfirmButton,
					type:
						template.createdFromDistrict === true ? 'primaryDanger' : 'secondaryDanger',
				}}
				cancelBtn={{
					title: template.createdFromDistrict === true ? 'Yes, delete it' : 'No, go back',
					onClick: handleCancelButton,
					type: template.createdFromDistrict === true ? 'secondaryDanger' : 'tertiary',
				}}
			/>

			<AlertModal
				isOpen={openAlert}
				onClose={handleAlertClose}
				title="This subsection cannot be published until its main section has been published."
				subtitle="Publish the main section in order to publish the subsection."
				icon="warning-sign"
				iconColor="warning"
				alertConfirmButton={
					<Button text="Ok" type="primaryDefault" size="md" onClick={handleAlertClose} />
				}
			/>
		</>
	);
};

DeleteSectionModal.propTypes = {
	closeDeleteModal: PropTypes.func,
	type: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	sectionIndex: PropTypes?.number,
	subsectionIndex: PropTypes?.number,
	onDeleteAndPublishSection: PropTypes.func.isRequired,
};

export { DeleteSectionModal };
