import React, { useContext } from 'react';
import { ProgressBar, Button } from 'componentsV2';
import { RapidAlarmsContext } from '../../Providers';
import { REUNIFICATION_TAB } from '../../RollCallDetails/components';

export const ReunificationDetailsCard = ({
	alarm,
	loggedUser,
	isReunificationStarted,
	isRollCallStarted,
	reunificationLocation,
	history,
	reunificationStats,
	userStatus,
	setForceAreYouSafeOpen,
	isRollCallEnded,
	setGoButtonClicked,
}) => {
	const { onActivateReunification, onEndAlarmClick, openedFromHomePage } = useContext(
		RapidAlarmsContext,
	);
	const redirectReunificationHandler = () => {
		if (!userStatus && isRollCallStarted && !isRollCallEnded) {
			setForceAreYouSafeOpen(true);
			setGoButtonClicked(REUNIFICATION_TAB);
		} else {
			history.push({
				pathname: `/active-alarms/roll-call-reunification/${alarm.id}`,
				state: { activeTab: REUNIFICATION_TAB, openedFromHomePage },
			});
		}
	};

	return (
		<div className="alarm-card-container">
			<div className="alarm-card-info">
				{loggedUser.accountType == 'administrator' ? (
					<>
						<div className="row">
							<div className="col-md-6">
								<h3 className="alarm-card-title">Reunification details</h3>
								{reunificationLocation && (
									<p>The Reunification site is at: {reunificationLocation}</p>
								)}
							</div>
							<div className="col-md-6 text-right">
								<div className="alarm-card-buttons">
									{isReunificationStarted ? (
										<span className="mr-3">
											<Button
												large
												text="End Reunification"
												type="secondaryDanger"
												intent="default"
												onClick={() => {
													onEndAlarmClick(alarm, true);
												}}
											/>
										</span>
									) : (
										<span className="mr-3">
											<Button
												large
												text="Activate Reunification"
												onClick={() => {
													onActivateReunification(
														alarm,
														isRollCallStarted,
													);
												}}
											/>
										</span>
									)}

									<Button
										large
										text="See Reunification details"
										type="secondaryDefault"
										intent="default"
										rightIcon="arrow-top-right"
										disabled={!isReunificationStarted}
										onClick={redirectReunificationHandler}
									/>
								</div>
							</div>
						</div>
						<div className="alarm-progress-bars-container">
							<ProgressBar
								label="Students reunified out of total students checked in"
								value={
									isReunificationStarted
										? reunificationStats.reunifiedStudentsCount
										: 0
								}
								total={
									isReunificationStarted
										? reunificationStats.totalStudentsCound
										: 0
								}
								displayValueInPercentage={false}
								disabled={!isReunificationStarted}
							/>

							<ProgressBar
								label="Guardians reunified out of total guardians checked in"
								value={
									isReunificationStarted
										? reunificationStats.reunifiedGuardiansCount
										: 0
								}
								total={
									isReunificationStarted
										? reunificationStats.checkedInGuardiansCount
										: 0
								}
								displayValueInPercentage={false}
								disabled={!isReunificationStarted}
							/>
						</div>
					</>
				) : (
					<div className="row">
						<div className="col-md-6">
							<h3 className="alarm-card-title">Reunification</h3>
							{isReunificationStarted && (
								<p>
									Reunification has been activated.
									{reunificationLocation && (
										<> The Reunification site is at: {reunificationLocation}</>
									)}
								</p>
							)}
							{!isReunificationStarted && (
								<p>
									Reunification has not been activated. Wait for further
									instruction.
								</p>
							)}
						</div>
						<div className="col-md-6 text-right">
							<div className="alarm-card-buttons">
								<Button
									large
									text="Go to Reunification"
									type="secondaryDefault"
									intent="default"
									rightIcon="arrow-top-right"
									disabled={!isReunificationStarted}
									onClick={redirectReunificationHandler}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
