import React, { createContext, useReducer, useState } from 'react';
import { SORTING_DIR } from 'utilities/constants';

export const ContactsContext = createContext();

const filterationDataReducer = (filterationData, action) => {
	switch (action.type) {
		case 'selectedSites':
			filterationData.selectedSites = action.payload;
			filterationData.page = 1;
			break;
		case 'combineSearch':
			filterationData.combineSearch = action.payload;
			filterationData.page = 1;
			filterationData.perPage = filterationData.perPage;
			break;
		default:
			filterationData[action.type] = action.payload;
			break;
	}
	return {
		page: filterationData.page,
		perPage: filterationData.perPage,
		selectedSites: filterationData.selectedSites,
		combineSearch: filterationData.combineSearch,
		sortKey: filterationData.sortKey,
		sortDir: filterationData.sortDir,
	};
};
export const ContactsProviders = ({ children }) => {
	const [reloadContacts, setReloadContacts] = useState(false);
	const [filterationData, dispatchFilterationData] = useReducer(filterationDataReducer, {
		page: 1,
		perPage: 10,
		selectedSites: [],
		combineSearch: '',
		sortKey: 'name',
		sortDir: SORTING_DIR.ASC,
	});
	const [modalToShow, setModalToShow] = useState(false);
	const [selectedContact, setSelectedContact] = useState(null);
	const [contactsList, setContactsList] = useState([]);

	const onCloseModal = () => {
		setModalToShow(false);
		setMode('add');
	};
	const [selectedContactCallList, setSelectedContactCallList] = useState(null);

	const [mode, setMode] = useState('add');
	const [contactID, setContactID] = useState(null);

	const handleViewEditClick = (mode, contactID) => {
		setContactID(contactID);
		setMode(mode);
		setModalToShow('ExternalContactModal');
	};
	const modalFunctions = {
		filterationData,
		dispatchFilterationData,
		reloadContacts,
		setReloadContacts,
		modalToShow,
		setModalToShow,
		onCloseModal,
		selectedContactCallList,
		setSelectedContactCallList,
		setSelectedContact,
		selectedContact,
		setContactsList,
		contactsList,
		mode,
		contactID,
		setContactID,
		handleViewEditClick,
	};

	return <ContactsContext.Provider value={modalFunctions}>{children}</ContactsContext.Provider>;
};
