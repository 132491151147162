import React from 'react';
import { Button } from 'componentsV2';

export const FooterActions = ({
	showCancelAction = true,
	showActivateAlarmAction = true,
	onCancelAction,
	onGoBackAction,
	secondBtnTitle = 'Go back',
	activateAlarmDisabled,
	onActivateAlarmHandler,
}) => (
	<div className="activate-alarm-modal-footer">
		<div className="activate-alarm-modal-footer-left-side">
			{showCancelAction && (
				<Button text="Cancel alarm" type="ghostDefault" onClick={onCancelAction} />
			)}
		</div>
		<div className="activate-alarm-modal-footer-right-side">
			<Button text={secondBtnTitle} type="tertiary" onClick={onGoBackAction} />
			{showActivateAlarmAction && (
				<Button
					text="Activate alarm"
					type="primaryDefault"
					disabled={activateAlarmDisabled}
					onClick={onActivateAlarmHandler}
				/>
			)}
		</div>
	</div>
);
