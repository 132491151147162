import React from 'react';
import './css/Badge.scss';
import { Icon } from '@blueprintjs/core';

export const Badge = ({
	type = 'Gray',
	text,
	size = 'sm',
	hasLeadIcon = false,
	leadIcon = 'full-circle',
	hasCloseIcon = false,
	onClick = null,
	dotIconColor = null,
	maxWidth = null,
}) => {
	return (
		<div className="Badge__wrapper">
			<span className={`badge ${type} ${size}`}>
				{hasLeadIcon && (
					<Icon className="tab-icon badge-dot" icon={leadIcon} color={dotIconColor} />
				)}
				<span className={maxWidth && 'overflow-ellipsis'} style={maxWidth && { maxWidth }}>
					{text}
				</span>
				{hasCloseIcon && (
					<Icon className="tab-icon badge-close" icon="cross" onClick={onClick} />
				)}
			</span>
		</div>
	);
};
