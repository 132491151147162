import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LoadingSpinner } from 'components/LoadingSpinner';
import { DrillDetailsModal } from 'components/Modals/DrillDetailsModal';
import { DrillsService } from 'services/DrillsService';
import { DrillsCard } from '../shared/DrillsCard';

const Page = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;

	.drill-card {
		height: 0%;
		margin-bottom: 16px;
		width: calc(50% - 8px);

		:first-child {
			margin-right: 16px;
		}
	}
`;

class Dashboard extends React.Component {
	state = {
		error: null,
		loading: true,
		upcomingDrills: null,
		overdueDrills: null,
		modalToShow: null,
		selectedDrill: null,
	};

	componentDidMount() {
		Promise.all([
			DrillsService.fetchUpcomingDrills(3),
			DrillsService.fetchOverdueDrills(100, 30),
		])
			.then(([upcomingDrills, overdueDrills]) => {
				this.setState({
					upcomingDrills,
					overdueDrills,
					error: null,
					loading: false,
				});
			})
			.catch(error => {
				this.setState({
					error,
					loading: false,
					upcomingDrills: null,
					overdueDrills: null,
				});
			});
	}

	setModalToShow = (displayValue, selectedDrill = null) => {
		this.setState({
			selectedDrill,
			modalToShow: displayValue,
		});
	};

	removeDrill = drill => {
		const path =
			drill?.status === 'scheduled' ? 'upcomingDrills' : 'overdueDrills';

		this.setState(prevState => ({
			modalToShow: null,
			selectedDrill: null,
			[path]: prevState[path]?.filter(
				({ scheduleId }) => scheduleId !== drill?.scheduleId,
			),
		}));
	};

	render() {
		const {
			error,
			loading,
			upcomingDrills,
			overdueDrills,
			modalToShow,
			selectedDrill,
		} = this.state;
		const { history } = this.props;

		if (error) {
			return error;
		}

		if (loading) {
			return <LoadingSpinner />;
		}

		return (
			<Page>
				{Array.isArray(upcomingDrills) && (
					<DrillsCard
						nameOfCard="Upcoming drills"
						drills={upcomingDrills}
						onViewAllClick={() => history.push('/upcomingDrills')}
						onEditClick={drill => this.setModalToShow(true, drill)}
					/>
				)}

				{Array.isArray(overdueDrills) && (
					<DrillsCard
						nameOfCard="Overdue drills"
						drills={overdueDrills}
						onViewAllClick={() => history.push('/overdueDrills')}
						onEditClick={drill => this.setModalToShow(true, drill)}
					/>
				)}

				{!upcomingDrills && !overdueDrills && (
					<div>
						You do not have any upcoming nor any overdue drills!
					</div>
				)}

				{modalToShow && selectedDrill && (
					<DrillDetailsModal
						setModalToShow={this.setModalToShow}
						selectedDrill={selectedDrill}
						onDeleteDrill={this.removeDrill}
						onPostLogSuccess={this.removeDrill}
					/>
				)}
			</Page>
		);
	}
}

export { Dashboard };

Dashboard.propTypes = {
	history: PropTypes.object,
};