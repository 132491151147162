import React from "react";
import '../css/TemplateSection.scss';
import { Button } from 'components/Button';

const TemplateSection = ({
    name,
    templateTitle,
    isPrimary,
    btnTitle,
    onClickHandler,
    children,
    isButtonDisabled = false,
    isDeleteUpdate
}) => {
    return (
        <section className={`template-section-wrapper  ${isDeleteUpdate && 'delete-update'}`}>
            <div className="main-header">
                <div className={isPrimary ? 'dot-icon blue' : 'dot-icon'} />
                <p>{name}</p>
            </div>
            <div className="title-container">
                <div className="title" >
                    {templateTitle}
                </div>
                <Button
                    className="title-button"
                    intent="primary"
                    text={btnTitle}
                    onClick={onClickHandler}
                    disabled={isButtonDisabled}
                />
            </div>
            <div className='body-content-wrapper'>
                {children}
            </div>
        </section>
    );
}

export default TemplateSection;
