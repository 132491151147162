import React from "react";
import { CreateBinder } from "./";
import '../css/BinderHeader.scss';

export const BinderHeader = ({
    editPermission,
    noDataAdded,
    onAddBinderSubmit,
    addBinderSelectedSites,
}) => {
    const newBinderBtn = editPermission && <CreateBinder onSubmitFinished={onAddBinderSubmit} selectedSites={addBinderSelectedSites} />

    return (
        !noDataAdded && 
        <section className="binder-headers">
            <h4>Binders</h4>
            <div className="right-side">
                {newBinderBtn}
            </div>
        </section>
    );
}