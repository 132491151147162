import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/Input';
import InputWrapper from '../InputWrapper/InputWrapper';
import { Color, ColorPicker } from './components';
import './css/InputColor.scss';

export const InputColor = ({
	label,
	name,
	isRequired = false,
	value,
	setValue,
	presetColors = [],
	error = false,
	errorMessage = null,
	showColorPicker = true,
}) => {
	const [selectedColor, setSelectedColor] = useState(value || null);
	const [isPresetColor, setIsPresetColor] = useState(false);
	useEffect(() => {
		if (value) {
			onChangeColor(value);
		}
	}, [value]);

	const onChangeColor = value => {
		setSelectedColor(value);
		setValue(value);
		const findPresetColor = presetColors.find(presetColor => presetColor.value === value);
		setIsPresetColor(findPresetColor);
	};

	return (
		<div className="input-color-wrapper">
			<InputWrapper
				label={label}
				isRequired={isRequired}
				labelState={true}
				error={error}
				errorMessage={errorMessage}
			>
				<div className="colors-group">
					{presetColors.map((presetColor, index) => (
						<Color
							key={index}
							colorClass={presetColor.class}
							isSelected={presetColor.value === selectedColor}
							onClick={() => onChangeColor(presetColor.value)}
							customStyle={{
								backgroundColor: presetColor.value,
							}}
							showToolTipe={presetColor?.showToolTip ? true : false}
							tooltipTitle={presetColor?.tooltipTitle ? presetColor.tooltipTitle : ''}
						/>
					))}
					{showColorPicker && (
						<ColorPicker
							selectedColor={selectedColor}
							setSelectedColor={onChangeColor}
							isSelected={!isPresetColor}
							singleColor={presetColors.length === 0}
						/>
					)}
					<Input type="hidden" name={name} value={selectedColor} />
				</div>
			</InputWrapper>
		</div>
	);
};

InputColor.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	setValue: PropTypes.func,
	isRequired: PropTypes.bool,
	showColorPicker: PropTypes.bool,
	presetColors: PropTypes.arrayOf(
		PropTypes.shape({
			class: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}),
	).isRequired,
};
