import React from 'react';
import { PropTypes } from 'prop-types';
import { BootstrapedTable, TableCell, SORTING_DIR } from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import { formatDate } from 'utilities/dates';
import { DateTime } from 'luxon';
import { TableStatusCell } from '../DrillsV2/components/Dashboard/TableStatusCell';

export const DrillsTable = ({ drills }) => {
	const sortCaret = (order, column) => {
		const icon = `arrow-${order === 'desc' ? 'up' : 'down'}`;
		return <Icon className="carret-icon" icon={icon} />;
	};

	const checkDates = row => {
		const { sch_date } = row;
		if (sch_date) {
			const schDate = DateTime.fromISO(sch_date);
			const today = DateTime.now();

			if (schDate < today) {
				const days = Math.round((today - schDate) / (1000 * 3600 * 24));
				return `${days} day${days.length === 1 ? '' : 's'} past due`;
			}

			if (schDate > today) {
				const days = Math.round((schDate - today) / (1000 * 3600 * 24));
				return `Due in ${days} day${days.length === 1 ? '' : 's'}`;
			}
		}

		return null;
	};

	const checkStatus = row => {
		const { dl_id, log_completed, sch_date } = row;

		if (sch_date) {
			if (dl_id && log_completed !== true) {
				return 'In Progress';
			}

			if (log_completed === true) {
				return 'Completed';
			}

			return 'Scheduled';
		}

		return '';
	};

	const tableColumns = [
		{
			dataField: 'dl_id',
			hidden: true,
		},
		{
			dataField: 'drill_date',
			hidden: true,
		},
		{
			dataField: 'drill_type_name',
			text: 'Type',
			sort: true,
			sortCaret,
			sortFunc: (a, b, order) => (order === 'desc' ? a.localeCompare(b) : b.localeCompare(a)),
			formatter: (cell, row) => {
				const supportText = checkDates(row);
				return <TableCell text={row.drill_type_name} supportText={supportText} />;
			},
			editable: false,
		},
		{
			dataField: 'team_name',
			text: 'Site',
			sort: true,
			sortCaret,
			sortFunc: (a, b, order) => (order === 'desc' ? a.localeCompare(b) : b.localeCompare(a)),
			editable: false,
		},
		{
			dataField: 'sch_date',
			text: 'Scheduled date',
			sort: true,
			sortCaret,
			sortFunc: (a, b, order) => {
				const dateA = a ? Date.parse(a) : 0;
				const dateB = b ? Date.parse(b) : 0;
				if (order === SORTING_DIR.ASC) {
					return dateB - dateA;
				}
				return dateA - dateB;
			},
			formatter: (cell, row) =>
				cell ? formatDate(cell, DateTime.DATE_SHORT) : 'Schedule drill',
			editable: false,
		},
		{
			dataField: 'log_completed',
			text: 'Status',
			sort: true,
			sortCaret,
			formatter: (cell, row) => {
				const status = checkStatus(row);
				return <TableStatusCell status={status} />;
			},
			editable: false,
		},
	];

	return (
		<div className="d-flex flex-column">
			<BootstrapedTable
				keyField="sd_id"
				data={drills}
				columns={tableColumns}
				remote={false}
			/>
		</div>
	);
};

DrillsTable.propTypes = { drills: PropTypes.array };
