import React from 'react'
import { ProgressBar } from 'componentsV2';

export const RollCallDetailsCard = ({
    rollCall,
}) => {
    return (
        <div className="alarm-card-container">
            <div className="alarm-card-info">
                <div className="alarm-card-title">
                    Final Roll Call details
                </div>

                <div className="alarm-progress-bars-container">
                    <ProgressBar
                        label="Students with a Roll Call status"
                        value={rollCall.totalStudentAccounted}
                        total={rollCall.totalStudentCount}
                        displayValueInPercentage={false}
                        disabled={rollCall.totalStudentCount == 0}
                    />

                    <ProgressBar
                        label="Staff with a Roll Call status"
                        value={rollCall.totalStaffAccounted}
                        total={rollCall.totalStaffCount}
                        displayValueInPercentage={false}
                        disabled={rollCall.totalStaffCount == 0}
                    />

                    {rollCall.totalVisitorsCount > 0 && (
                        <ProgressBar
                            label="Visitors with a Roll Call status"
                            value={rollCall.totalVisitorsAccounted}
                            total={rollCall.totalVisitorsCount}
                            displayValueInPercentage={false}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
