import { HttpService, buildRoute } from 'networking/HttpService';

const GENERIC_ERROR = new Error('An error occurred, please try again');

export const DrillStateRequirementsService = {
	fetchRequirements: (year, stateId) =>
		HttpService({
			method: 'GET',
			route: buildRoute('/drills/state-requirements?year=:year&stateId=:stateId', {
				year,
				stateId,
			}),
		})
			.then(res => {
				if (Array(res?.data)) {
					return res?.data?.map(item => ({
						...item,
						status: 'Published',
					}));
				}

				return null;
			})
			.catch(() => GENERIC_ERROR),

	fetchRequiredFields: requirementId =>
		HttpService({
			method: 'GET',
			route: buildRoute('/drills/state-requirements/:requirementId/fields', {
				requirementId,
			}),
		}).then(res => {
			if (Array.isArray(res?.data)) {
				return res?.data;
			}

			throw GENERIC_ERROR;
		}),

	deleteRequirement: requirementId =>
		HttpService({
			method: 'DELETE',
			route: buildRoute('/drills/state-requirements/:requirementId', {
				requirementId,
			}),
		}).then(res => {
			if (res?.statusCode === 200) {
				return true;
			}

			return false;
		}),

	updateNumberOfRequirements: (requirementId, numberOfRequirements) =>
		HttpService({
			method: 'PATCH',
			route: buildRoute('/drills/state-requirements/:requirementId', {
				requirementId,
			}),
			body: {
				requirement: numberOfRequirements,
			},
		})
			.then(res => res)
			.catch(() => {
				throw GENERIC_ERROR;
			}),

	updateRequiredFieldsOrder: (requirementId, updatedFields) =>
		HttpService({
			method: 'PATCH',
			route: buildRoute('/drills/state-requirements/:requirementId', {
				requirementId,
			}),
			body: {
				fieldUuids: updatedFields,
			},
		}).then(res => {
			if (res?.statusCode === 200) {
				return true;
			}

			throw GENERIC_ERROR;
		}),

	addStateRequirement: (requirementCount = 0, drillTypeUUID, stateId, year) => {
		return HttpService({
			method: 'POST',
			route: '/drills/state-requirements',
			body: {
				requirement: requirementCount,
				drillTypeId: drillTypeUUID,
				stateId,
				year,
			},
		}).then(res => {
			if (Number.isInteger(res?.data?.id)) {
				return res.data;
			}

			throw GENERIC_ERROR;
		});
	},
};
