import { ToasterContext } from 'componentsV2';
import { useContext, useState } from 'react';
import { DELETE_CAMERA_LINK_MODAL } from '../../../consts';
import { Intent } from '@blueprintjs/core';
import { FloorplanService } from 'services/FloorplanService';
import { floorplanDetails } from '../../../Details/CanvasDrawFn';

export const DeleteCameraLinkModalFn = (setLoading, closeModal, setModalToShow, reloadContent) => {
	const toaster = useContext(ToasterContext);
	const [cameraLink, setCameraLink] = useState(null);
	const [selectedShape, setSelectedShape] = useState({});

	const showDeleteCameraLinkModal = (cameraLink, selectedShape) => {
		setCameraLink(cameraLink);
		setSelectedShape(selectedShape);
		setModalToShow(DELETE_CAMERA_LINK_MODAL);
	};

	const deleteCameraLinkHandler = () => {
		setLoading(true);

		FloorplanService.DeleteShapeCameraLink(floorplanDetails.id, selectedShape.id, cameraLink.id)
			.then(() => {
				toaster(
					`You have successfully deleted the camera address link`,
					Intent.SUCCESS,
					null,
					2000,
					false,
					'map-view-page',
				);
				closeModal();
				reloadContent();
			})
			.catch(error => {
				console.log(error);
				toaster(
					`Something went wrong, please try again later`,
					Intent.DANGER,
					null,
					2000,
					false,
					'map-view-page',
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		showDeleteCameraLinkModal,
		deleteCameraLinkHandler,
	};
};
