import React from "react";
import { SafetyPlanTab, CallListTab, CustomTab, TabHeader } from "./";
import { LoadingSpinner } from 'componentsV2';
import { FLIPCHART_CONTENT_TYPE } from 'utilities/constants';

export const Tabs = ({
    flipchartData,
    isLoading,
    history,
    handlePrintClick
}) => {
    return (
        <>
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div className="printWrapper">
                    {
                        flipchartData.length!=0 ? flipchartData.map((row, index) => (
                            <div key={index} className="card">
                                <TabHeader handlePrintClick={handlePrintClick} history={history} row={row} index={index}/>
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            row.contentTypeCode == FLIPCHART_CONTENT_TYPE.SAFETYPLAN ? <SafetyPlanTab row={row} index={index}/> : "" +
                                            row.contentTypeCode == FLIPCHART_CONTENT_TYPE.CUSTOM ? <CustomTab row={row} index={index}/> : "" +
                                            row.contentTypeCode == FLIPCHART_CONTENT_TYPE.CALLLIST ? <CallListTab row={row} index={index}/> : "" 
                                        }
                                    </div>
                                </div>
                            </div>
                        )) : <strong>There is currently no content in this flipchart.</strong>
                    }
                </div>
            )}
        </>
    )
}