import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'componentsV2';

/**
 * ScrollTopButton Component.
 *
 * Renders a scroll-to-top button component that manages the scroll of its parent element.
 *
 * @param {Object} props
 * @param {string} [props.label='Top']
 *   Optional override for label text on the button.
 * @param {string} [props.icon='arrow-up']
 *   Optional override icon name for the button.
 * @param {function} [props.onClick=()=>{}]
 *   Optional additional click event handler for the button (does not effect scrolling).
 * @param {DOMElement} [props.initialParent]
 *   Optional override for the scrollable element to control.
 *     Add "position: relative;" to parent scrollable element style for positioning.
 * @param {bool} [props.pageLevel]
 *   Pass true to control document level scrolling.
 *
 * @returns {JSX.Element} The scroll-to-top button component.
 */
export const ScrollTopButton = ({
	label = 'Top',
	icon = 'arrow-up',
	onClick = () => {},
	initialParent = null,
	pageLevel = false,
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const [parent, setParent] = useState(pageLevel ? document : initialParent);
	const baseRef = useRef(null);

	// Using a native DOM element reference, we can use the DOM tree to grab the parent
	// element directly (or use the passed element), and then use the native DOM interface
	// for attaching an event handler for the scroll event.
	useEffect(() => {
		if (baseRef.current) {
			const myParent = parent || baseRef.current.parentNode;
			if (!parent) setParent(myParent);
			myParent.addEventListener('scroll', ({ target }) => {
				const { scrollTop, clientHeight } = pageLevel ? target.documentElement : target;

				// Show button when scrolled more than 50% of visible height.
				setIsVisible(scrollTop > clientHeight / 2);
			});
		}
	}, [baseRef]);

	const scrollTop = () => {
		const scrollParent = pageLevel ? parent.documentElement : parent;
		if (scrollParent) {
			scrollParent.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const pageLevelButtonStyle = {
		position: 'fixed',
		bottom: '2em',
		right: '2em',
	};

	const buttonStyle = {
		position: 'sticky',
		left: '100%',
		top: 'calc(100% - 3em)',
	};

	return (
		<>
			<span ref={baseRef} />
			{isVisible && (
				<Button
					intent="default"
					size="md"
					icon={icon}
					title="Testing"
					style={pageLevel ? pageLevelButtonStyle : buttonStyle}
					onClick={() => {
						scrollTop();
						onClick();
					}}
					text={label}
				/>
			)}
		</>
	);
};

ScrollTopButton.propTypes = {
	label: PropTypes.string,
	icon: PropTypes.string,
	onClick: PropTypes.func,
	pageLevel: PropTypes.bool,
	initialParent: PropTypes.element,
};
