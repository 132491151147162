/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, MenuItem } from '@blueprintjs/core';
import { Title } from 'components/Title';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { backArrow } from 'assets/icons';
import Swal from 'sweetalert2';
import { Button as LocalBtn } from 'components/Button';
import { Select } from '@blueprintjs/select';
import Sidebar from './sidebar';
import Section from './Section';
import FilterModal from '././Section/filtermodal';

const PageBody = styled.div`
	flex-wrap: wrap;
`;

const BackBtn = styled.div`
	display: flex;
	color: #5c7080;
	cursor: pointer;
	font-size: 14px;
	transition: 0.1s;
	margin-left: 11px;
	line-height: 20px;
	font-style: normal;
	border-radius: 8px;
	font-weight: normal;
	align-items: center;
	flex-direction: row;
	font-family: Nunito Sans;

	:hover {
		background-color: white;
	}

	img {
		margin-right: 9px;
	}
`;

const Subtitle = styled.div`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
`;

const Wrapper = styled.div`
	flex-direction: row;
	display: flex;
	padding-right: ${props => (props.expand ? '315px' : '70px')};
	transition: all 0.3s ease;
	padding-left: 15px;
`;

const Progress = styled.div`
    position: absolute:
    top:0;
    background: #CED9E0;
    height: 4px;
	margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -25px;

`;

const Bar = styled.div`
	height: 100%;
	width: ${props => props.width}%;
	background: #128c5a;
	margin-top: -1px;
`;

const DropdownWrap = styled.div`
	padding: 10px 10px 10px 0px;
	width: 100%;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	h4 {
		margin-bottom: 0 !important;
	}
	span {
		margin-left: 8px !important;
	}
`;

const SelectWrap = styled(Select)`
	display: block;
	width: 100%;
	.bp3-popover-target {
		display: block;
		width: 100%;
	}
`;

class SectionView extends React.Component {
	state = {
		primaryBuildingId: null,
		loading: true,
		name: '',
		sections: [],
		addSectionModal: false,
		copySectionModal: false,
		copySectionId: null,
		editSectionModal: false,
		editSectionTarget: null,
		selectedSection: {},
		expand: true,
		showFilterModal: false,
		filteredSection: {},
		appliedFilters: {}
	};

	getData = (newSection = null) => {
		const {
			match: {
				params: { id, sectionId },
			},
		} = this.props;
		this.setState({
			loading: true,
			addSectionModal: false,
			copySectionModal: false,
			copySectionId: null,
			editSectionModal: false,
			editSectionTarget: null,
		});
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/assessments/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					const {
						data: { state, name, published },
					} = resp;
					let currentSection = sectionId;
					if (newSection) {
						currentSection = newSection.id;
					}
					return fetch(
						`${
							process.env.API_URL
						}/assessments/${id}/sections/${currentSection}`,
						{
							method: 'GET',
							headers: {
								'Content-Type': 'app/json',
								Authorization: `Bearer ${token}`,
							},
						},
					)
						.then(rs => rs.json())
						.then(rsp =>
							this.setState({
								name,
								state,
								sections: resp.data.sections,
								templateId: id,
								loading: false,
								published,
								selectedSection: rsp.data[0],
								filteredSection: rsp.data[0]
							}),
						);
				}
				return null;
			});
	};

	componentDidMount = () => {
		this.getData();
	};

	handleRadioChange = () =>
		this.setState({
			// eslint-disable-next-line no-constant-condition
			// eslint-disable-next-line no-nested-ternary
			radioChange: 'one' ? 'two' : false && 'two' ? 'one' : false,
		});

	goBack = () => {
		const {
			history,
			match: {
				params: { id },
			},
		} = this.props;
		return history.push(`/assessment/${id}`);
	};

	addSections = () => {
		const { sections } = this.state;
		let lastId;
		if (sections.length > 0) {
			lastId = sections[sections.length - 1].id + 1;
		} else {
			lastId = 1;
		}
		sections.push({
			id: lastId,
			title: 'Section title',
			directions: 'Lorem ipsum dolor sit amet,',
			questions: [],
		});
		return this.setState({ sections });
	};

	addQuestionToSection = (question, index) => {
		const { sections } = this.state;
		sections[index].questions.push(question);
		return this.setState({ sections });
	};

	updateSectionQuestion = (question, index, questionIndex) => {
		const { sections } = this.state;
		sections[index].questions[questionIndex] = question;
		return this.setState({ sections });
	};

	deleteQuestion = (sectionIndex, index) => {
		const { sections } = this.state;
		sections[sectionIndex].questions.splice(index, 1);
		return this.setState({ sections });
	};

	closeAdd = () => this.setState({ addSectionModal: false });

	openAdd = () => this.setState({ addSectionModal: true });

	openCopy = id =>
		this.setState({ copySectionModal: true, copySectionId: id });

	closeCopy = () =>
		this.setState({ copySectionModal: false, copySectionId: null });

	openEdit = item =>
		this.setState({ editSectionModal: true, editSectionTarget: item });

	closeEdit = () =>
		this.setState({ editSectionModal: false, editSectionTarget: null });

	closeAttachModal = () => this.setState({ showFilterModal: false });

	publishTemplate = () => {
		const { published } = this.state;
		Swal.fire({
			title: `Are you sure you want to ${
				published ? 'Unpulsih' : 'Publish'
			} this template?`,
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: `${published ? 'Unpublish' : 'Publish'}`,
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				const { templateId } = this.state;
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				return fetch(`${process.env.API_URL}/templates/${templateId}`, {
					method: 'PUT',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						publish: !published,
					}),
				})
					.then(res => res.json())
					.then(resp => {
						if (resp.statusCode === 200) {
							return this.getData();
						}
						return null;
					});
			}
			return Swal.close();
		});
	};

	makeSections = item => {
		const {
			match: {
				params: { id },
			},
		} = this.props;
		const { history } = this.props;
		return (
			<MenuItem
				key={item.name}
				onClick={() => {
					history.replace(`/assessment/${id}/sections/${item.id}`);
					return this.getData(item);
				}}
				text={item.name}
			/>
		);
	};

	getPercentage = (partialValue, totalValue) =>
		(100 * partialValue) / totalValue;

	setExpand = val => this.setState({ expand: val });

	filterValues = (filters) => {
		const questionsCopy = [...this.state.selectedSection.questions]
		let filteredQuestions = []
		switch (filters.answer) {
			case 'answeredQuestions':
				filteredQuestions = questionsCopy.filter(q => q.answer !== null)
				break;
			case 'unAnsweredQuestions':
				filteredQuestions = questionsCopy.filter(q => q.answer === null)
				break;
			default:
				filteredQuestions = questionsCopy
				break;
		}
		switch (filters.filesCount) {
			case 'attachmentsIncluded':
				filteredQuestions = filteredQuestions.filter(q => q.answer !== null && q.answer.filesCount > 0)
				break;
			case 'commentsIncluded':
				filteredQuestions = filteredQuestions.filter(q => q.answer !== null && q.answer.comment !== null)
				break;
			default:
				break;
		}
		switch (filters.rating) {
			case 'likedQuestions':
				filteredQuestions = filteredQuestions.filter(q => q.answer !== null && q.answer.rating === 'up')
				break;
			case 'unlikedQuestions':
				filteredQuestions = filteredQuestions.filter(q => q.answer !== null && q.answer.rating === 'down')
				break;
			default:
				break;
		}
		this.setState({
			filteredSection: {
				...this.state.filteredSection,
				questions: filteredQuestions
			},
			appliedFilters: filters
		})
	};	

	render() {
		const {
			loading,
			sections,
			templateId,
			state,
			selectedSection,
			expand,
			showFilterModal,
			filteredSection,
			appliedFilters
		} = this.state;

		if (loading) return <LoadingSpinner />;
		return (
			<>
			{showFilterModal && (
					<FilterModal
						isOpen
						closeAttachModal={this.closeAttachModal}
						templateId={templateId}
						handleFilterValues={this.filterValues}
						filters={appliedFilters}
					/>
				)}
				<Wrapper expand={expand}>
					<div className="w-100">
						<div>
							<div className="d-flex align-items-center mt-3">
								<BackBtn onClick={this.goBack}>
									<img src={backArrow} alt="back arrow" />
									Back to overview
								</BackBtn>
							</div>
							<div className="d-flex justify-content-between">
								<div>
									<div className="mt-2 d-flex align-items-center">
										<SelectWrap
											items={sections}
											itemRenderer={this.makeSections}
											className="w-100"
											filterable={false}
										>
											<DropdownWrap>
												<Title>
													{selectedSection.name ||
														'Select'}
												</Title>
												<Icon icon="caret-down" />
											</DropdownWrap>
										</SelectWrap>
									</div>
									<Subtitle className="mt-2">
										{state}
									</Subtitle>
								</div>
								<div style={{marginTop: '10px' }}>
									<LocalBtn
										icon="filter"
										text="Filter"
										intent="tertiary"
										onClick={() =>
											this.setState({
												showFilterModal: true,
											})
										}
										child
									/>
								</div>
							</div>
						</div>
						<Progress className="mt-2">
							<Bar
								width={this.getPercentage(
									selectedSection.answerCount,
									selectedSection.questionCount,
								)}
							/>
						</Progress>
						<PageBody>
							<Section
								item={filteredSection}
								templateId={templateId}
								getData={this.getData}
								openCopy={this.openCopy}
								openEdit={this.openEdit}
							/>
						</PageBody>
					</div>
				</Wrapper>
				<Sidebar
					selectedSection={selectedSection}
					setExpand={this.setExpand}
					expand={expand}
				/>
			</>
		);
	}
}

SectionView.propTypes = {
	history: PropTypes.object,
	match: PropTypes.object,
};

export default SectionView;
