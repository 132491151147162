import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { Input } from 'components/Input';
import { Button as LocalBtn } from 'components/Button';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import {
	likeFill,
	likeOutline,
	dislikeFill,
	dislikeOutline,
} from 'assets/icons';
import TextAreaQuestion from './textareaquestion';
import RadioQuestion from './radioquestion';
import SelectQuestion from './selectquestion';
import AttachmentModal from '../attachmentmodal';
import TasksModal from '../Tasks';

const QuestionWrap = styled.div`
	border: 1px solid #d8e1e8;
	padding: 24px;
	margin-bottom: 12px;
`;

const MetaData = styled.span`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 16px;
	color: #5c7080;
`;

const QuestionTitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: #10161a;
`;

const BlueButton = styled(LocalBtn)`
	color: #137cbd;
`;

const VerticalLine = styled.div`
	height: 70%;
	width: 2px;
	background: #ced9e0;
	margin: 0 10px;
`;

const ThumbsButtonWrap = styled.button`
	background: none;
	border: none;
`;

class SingleQuestion extends React.Component {
	constructor(props) {
		super(props);
		const { item } = this.props;
		this.state = {
			showAttachModal: false,
			showTasksModal: false,
			rating: item.answer ? item.answer.rating : null,
			disabled: item.answer !== null ? false : true
		};
		this.debounceComment = debounce(this.addComment, 1000);
	}

	addComment = e => {
		const { templateId, item } = this.props;
		if (item.answer !== null && item.answer.hasOwnProperty('response') && item.answer.response !== null) {
			Swal.fire('Saving');
			Swal.showLoading();
			const token = JSON.parse(localStorage.getItem('user')).jwt;
			fetch(
				`${process.env.API_URL}/assessments/${templateId}/questions/${item.id}/answers`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						comment: e.target.value,
					}),
				},
			)
				.then(res => res.json())
				.then((resp) => {
					if (resp.statusCode === 500) {
						Swal.fire({
							icon: 'error',
							title: 'Oops!',
							text: 'Please answer the question before adding a comment.'
						});
					} else {
						Swal.fire({
							title: 'Done!',
							icon: 'success',
							timer: 1000,
							showConfirmButton: false,
						})
					}
				});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops!',
				text: 'Please answer the question before adding a comment.',
			});
		}
	};

	rateQuestion = val => {
		const { templateId, item } = this.props;
		if (item.answer !== null && item.answer.hasOwnProperty('response') && item.answer.response !== null) {
			const token = JSON.parse(localStorage.getItem('user')).jwt;
			Swal.fire('Saving');
			Swal.showLoading();
			fetch(
				`${process.env.API_URL}/assessments/${templateId}/questions/${item.id}/answers`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						rating: val,
					}),
				},
			)
				.then(res => res.json())
				.then(resp => {
					if (resp.statusCode === 201) {
						this.setState({ rating: val });
						Swal.fire({
							title: 'Done!',
							icon: 'success',
							timer: 1000,
							showConfirmButton: false,
						});
					} else if (resp.statusCode === 500) {
						Swal.fire({
							icon: 'error',
							title: 'Oops!',
							text: 'Please answer the question before rating this question.'
						});
					}
				});
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops!',
				text: 'Please answer the question before rating this question.'
			});
		}
	};

	closeAttachModal = () => this.setState({ showAttachModal: false });

	closeTasksModal = () => this.setState({ showTasksModal: false });

	render() {
		const { item, templateId, index } = this.props;
		const { showAttachModal, showTasksModal, rating } = this.state;
		const { type, options } = JSON.parse(item.properties);
		return (
			<QuestionWrap key={`question-${item.id}`}>
				{showAttachModal && (
					<AttachmentModal
						isOpen
						closeAttachModal={this.closeAttachModal}
						templateId={templateId}
						questionId={item.id}
					/>
				)}
				{showTasksModal && (
					<TasksModal
						isOpen
						closeTasksModal={this.closeTasksModal}
						templateId={templateId}
						questionId={item.id}
						item={item}
					/>
				)}
				<div>
					<p>{item.title}</p>
					<MetaData>Question {index + 1}</MetaData>
					{item.answer && (
						<MetaData className="ml-2">
							Saved - {item.answer.editedBy} |{' '}
							{format(
								new Date(item.answer.editedDate.date),
								'hh:mm a',
							)}{' '}
							|{' '}
							{format(
								new Date(item.answer.editedDate.date),
								'MM/dd/yyyy',
							)}{' '}
						</MetaData>
					)}

					<QuestionTitle>{item.text}</QuestionTitle>
					{type === 'area' && (
						<TextAreaQuestion
							templateId={templateId}
							questionId={item.id}
							item={item}
						/>
					)}
					{type === 'yn' && (
						<RadioQuestion
							options={options}
							item={item}
							templateId={templateId}
							questionId={item.id}
							type={type}
						/>
					)}

					{type === 'select' && (
						<SelectQuestion
							options={options}
							item={item}
							templateId={templateId}
							questionId={item.id}
							type={type}
						/>
					)}

					{type !== 'area' && type !== 'yn' && console.log(item)}

					<QuestionTitle className="mt-3">Comments</QuestionTitle>
					<Input
						className="w-100"
						large
						onChange={this.debounceComment}
						defaultValue={
							item.answer &&
							item.answer.comment &&
							item.answer.comment
						}
						disabled={this.state.disabled}
					/>
					<div className="d-flex justify-content-between mt-3">
						<div style={this.state.disabled ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
							<BlueButton
								icon="paperclip"
								text="Attachments"
								intent="tertiary"
								onClick={() =>
									this.setState({
										showAttachModal: true,
									})
								}
							/>
							<BlueButton
								icon="properties"
								text="Tasks"
								intent="tertiary"
								onClick={() =>
									this.setState({
										showTasksModal: true,
									})
								}
							/>
						</div>
						<div className="d-flex align-items-center" style={this.state.disabled ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
							<ThumbsButtonWrap
								type="button"
								onClick={() => this.rateQuestion('down')}
							>
								<img
									alt="thumbs down icon"
									src={
										rating === 'down'
											? dislikeFill
											: dislikeOutline
									}
								/>
							</ThumbsButtonWrap>

							<VerticalLine />

							<ThumbsButtonWrap
								type="button"
								onClick={() => this.rateQuestion('up')}
							>
								<img
									alt="thumbs down icon"
									src={
										rating === 'up'
											? likeFill
											: likeOutline
									}
								/>
							</ThumbsButtonWrap>
						</div>
					</div>
				</div>
			</QuestionWrap>
		);
	}
}

SingleQuestion.propTypes = {
	item: PropTypes.object,
	templateId: PropTypes.any,
	index: PropTypes.number,
};

export default SingleQuestion;
