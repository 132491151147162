import React, { createContext, useEffect, useState } from 'react';
import {
	Header,
	REUNIFICATION_TAB,
	ROLLCALL_TAB,
	RollCallAndReunification,
	RollCallChat,
} from './components';
import { ActiveAlarmDetails } from '../ActiveAlarmDetails';
import { GuardianCheckin } from './components/Reunification/Guardians/GuardiansCheckin';
import { CHECKIN_STEPS } from './components/Reunification/Guardians/GuardiansCheckin/consts';
import { REUNIFICATION_STUDENTS_LIST } from './components/Reunification/consts';
import { RapidAlarmsProviders } from '../Providers';

export const RollCallDetailsContext = createContext();

export const RollCallDetails = ({ match, history, location }) => {
	const alarmId = match.params.id ? parseInt(match.params.id) : null;
	// check whether it opened from home page or rapid alarms list page
	const openedFromHomePage = location?.state?.openedFromHomePage;
	const [loading, setLoading] = useState(true);
	const [alarm, setAlarmDetails] = useState(null);
	const [socketEventsListeners, setSocketEventsListeners] = useState({
		isRollCallStarted: false,
		isRollCallEnded: false,
		isReunificationStarted: false,
		isReunificationEnded: false,
		userStatusUpdate: null,
	});
	const [studentUpdateEvent, setStudentUpdateEvent] = useState(null);
	const [rosterUpdateEvent, setRosterUpdateEvent] = useState(null);
	const [staffUpdateEvent, setStaffUpdateEvent] = useState(null);
	const [visitorUpdateEvent, setVisitorUpdateEvent] = useState(null);
	const [modalToShow, setModalToShow] = useState(null);
	const [activeTabId, setActiveTabId] = useState(ROLLCALL_TAB);
	const [reunificationActiveTab, setReunificationActiveTab] = useState(
		REUNIFICATION_STUDENTS_LIST,
	);
	const rollCallNotStarted = !socketEventsListeners.isRollCallStarted;
	const rollCallEnded =
		socketEventsListeners.isRollCallStarted && socketEventsListeners.isRollCallEnded;
	const [selectedRoster, setSelectedRoster] = useState(null);

	const forceAreYouSafeOpened =
		socketEventsListeners.isRollCallStarted &&
		!socketEventsListeners.userStatusUpdate &&
		!socketEventsListeners.isRollCallEnded;

	useEffect(() => {
		if (location?.state?.selectedRoster) {
			setSelectedRoster(location.state.selectedRoster);
		}
	}, []);

	useEffect(() => {
		if (location?.state?.activeTab) {
			setActiveTabId(location.state.activeTab);
		} else {
			setActiveTabId(
				rollCallNotStarted || rollCallEnded
					? socketEventsListeners.isReunificationStarted
						? REUNIFICATION_TAB
						: null
					: ROLLCALL_TAB,
			);
		}
	}, [socketEventsListeners]);

	const resetStudentUpdateEvent = () => {
		setStudentUpdateEvent(null);
	};

	const exportedData = {
		alarmId,
		studentUpdateEvent,
		staffUpdateEvent,
		visitorUpdateEvent,
		reunificationActiveTab,
		setReunificationActiveTab,
		resetStudentUpdateEvent,
	};

	const onChangeModalToShow = value => {
		setModalToShow(value);
	};

	return (
		<RapidAlarmsProviders openedFromHomePage={openedFromHomePage}>
			<RollCallDetailsContext.Provider value={exportedData}>
				<div className="roll-call-details-container">
					<Header
						alarmId={alarmId}
						onChangeModalToShow={onChangeModalToShow}
						socketEventsListeners={socketEventsListeners}
						reunificationActiveTab={reunificationActiveTab}
						activeTabId={activeTabId}
					/>
					<ActiveAlarmDetails
						history={history}
						match={match}
						isRollCallDetails={true}
						setAlarmDetails={setAlarmDetails}
						socketEventsListeners={socketEventsListeners}
						setSocketEventsListeners={setSocketEventsListeners}
						setStudentUpdateEvent={setStudentUpdateEvent}
						setStaffUpdateEvent={setStaffUpdateEvent}
						setVisitorUpdateEvent={setVisitorUpdateEvent}
						setRollCallLoading={setLoading}
						setRosterUpdateEvent={setRosterUpdateEvent}
						rollCallDetailsPage={true}
						forceAreYouSafeOpened={forceAreYouSafeOpened}
					/>
					{!loading && alarm && (
						<RollCallAndReunification
							alarm={alarm}
							socketEventsListeners={socketEventsListeners}
							history={history}
							rosterUpdateEvent={rosterUpdateEvent}
							activeTabId={activeTabId}
							setActiveTabId={setActiveTabId}
							selectedRoster={selectedRoster}
						/>
					)}
					{modalToShow == CHECKIN_STEPS.GUARDIANS_LIST_STEP && (
						<GuardianCheckin
							alarmId={alarm?.id}
							activeStep={CHECKIN_STEPS.GUARDIANS_LIST_STEP}
							onChangeModalToShow={onChangeModalToShow}
						/>
					)}

					<RollCallChat alarm={alarm} />
				</div>
			</RollCallDetailsContext.Provider>
		</RapidAlarmsProviders>
	);
};
