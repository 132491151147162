import React, { useState, useEffect, useContext } from "react";
import { ScenariosService } from "../../../services/ScenariosService";
import { 
    Breadcrumb,
    Button,
    ActionsButton,
    MediaPlayer,
    CardContainer,
    Badge,
    LoadingSpinner,
    ToasterContext,
    Intent,
} from "../../../componentsV2";
import { CATEGORIES_BADGE_MAP } from '../../../utilities/constants';
import { formatDate } from "../../../utilities/dates";
import styled from "styled-components";
import { MarkAsUsedModal, UsageHistory } from '../panels/components'
import Swal from 'sweetalert2';

const Page = styled.div`
    .header {
        margin: 32px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .header-content {
        display: flex;
        justify-content: space-between;
        align-itens: start;
    }
    .actions {
        display: flex;
        gap: 12px;
    }
    .actions-btn-container {
        padding: 14px 10px;
        border: 1px solid rgba(217,220,221,1);
        height: 44px;
        border-radius: 12px;
        display: flex;
        align-items: center;
    }
    .actions-btn-container:hover {
        background: #F4F5F5;
    }
    .content {
        display: flex;
        gap: 32px;
    }
    .left {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    .sub-title {
        font-size: 16px;
        font-weight: 700;
        color: rgba(41, 53, 61, 1);
        line-height: 24px;
    }
    .description {
        font-family: Nunito Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(105, 114, 119, 1);
    }
    .details-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 16px;
    }
    .detail-name {
        font-weight: 600;
        font-size: 14px;
        color: rgba(105, 114, 119, 1);
    }
    .detail-value {
        font-weight: 600;
        font-size: 16px;
        color: rgba(41, 53, 61, 1);
    }
    .no-usage {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(105, 114, 119, 1);
    }
    .title > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(41, 53, 61, 1);
    }
`

const breadCrumbs = [
    { label: 'Drills', url: '/drills' },
    { label: 'Scenarios', url: '/drills' },
    { label: 'Scenario details' },
];

const ScenarioDetails = ({ match }) => {
    const [scenario, setScenario] = useState({})
    const [mediaUrl, setMediaUrl] = useState('')
    const [usageHistory, setUsageHistory] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState('')
    const [loadingLog, setLoadingLog] = useState(false)

    useEffect(async () => {
        const scenarioData = await ScenariosService.getScenario(match.params.id)
        const usageData = await ScenariosService.getUsageHistory(match.params.id)
        setScenario(scenarioData)
        setUsageHistory(usageData)
        if (scenarioData['audioFile']) {
            setMediaUrl(scenarioData.audioFile)
        } else if (scenarioData['videoFile']) {
            setMediaUrl(scenarioData.videoFile)
        }
        setIsLoading(false)
    }, [])

    const closeModal = () => setShowModal('')

    const toaster = useContext(ToasterContext)

    const markAsUsed = async (data) => {
        if (data.buildingId && data.dateUsed && data.scenarioType) {
            try {
                setLoadingLog(true)
                const res = await ScenariosService.markAsUsed(data)
                setLoadingLog(false)
                closeModal()
                toaster(`You have successfully marked "${scenario.title}" as used.`, Intent.SUCCESS)
                // reload history
                const usageData = await ScenariosService.getUsageHistory(match.params.id)
                setUsageHistory(usageData)
            } catch (error) {
                closeModal()
                Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Error logging scenario usage'
				});
            }
        }
    }

    if (isLoading) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <>
            {showModal === 'markAsUsed' && (
                <MarkAsUsedModal
                    closeModal={closeModal}
                    markAsUsed={markAsUsed}
                    scenarioId={match.params.id}
                    loadingLog={loadingLog}
                />
            )}
            <Page>
                <div className="header">
                    <Breadcrumb items={breadCrumbs} />
                    <div className="header-content">
                        <div className="title">
                            <h2>{scenario.title}</h2>
                            <p>Custom Scenario</p>
                        </div>
                        <div className="actions">
                            <Button
                                type="tertiary"
                                size="lg"
                                text="Edit"
                                icon="edit"
                                onClick={() => setShowModal('edit')}
                            />
                            <div className="actions-btn-container">
                                <ActionsButton>
                                    <Button
                                        type="ghostDefault"
                                        size="lg"
                                        text="Mark as used"
                                        icon="tick"
                                        onClick={() => setShowModal('markAsUsed')}
                                        intent="default"
                                        wrapperClass="displayBlock"
                                        className="actionButtonElement"
                                    />
                                    <Button
                                        type="ghostDefault"
                                        size="lg"
                                        text="Disable"
                                        icon="disable"
                                        onClick={() => setShowModal('disable')}
                                        intent="default"
                                        wrapperClass="displayBlock"
                                        className="actionButtonElement"
                                    />
                                    <Button
                                        type="ghostDefault"
                                        size="lg"
                                        text="Delete"
                                        icon="trash"
                                        onClick={() => setShowModal('delete')}
                                        intent="default"
                                        wrapperClass="displayBlock"
                                        className="actionButtonElement"
                                    />
                                </ActionsButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="left">
                        {mediaUrl && <MediaPlayer mediaUrl={mediaUrl} />}
                        {scenario.content && (
                            <div>
                                <h3 className="sub-title">Scenario description</h3>
                                <p className="description">{scenario.content}</p>
                            </div>
                        )}
                    </div>
                    <div className="right">
                        <CardContainer title="Details">
                            <div className="details-grid">
                                <div className="details-grid-item">
                                    <p className="detail-name">Date created</p>
                                    <p className="detail-value">{scenario.dateEntered ? formatDate(scenario.dateEntered) : ''}</p>
                                </div>
                                <div className="details-grid-item">
                                    <p className="detail-name">Created by</p>
                                    <p className="detail-value">{scenario.enteredBy}</p>
                                </div>
                                <div className="details-grid-item">
                                    <p className="detail-name">Last modified</p>
                                    <p className="detail-value">{scenario.lastModified ? formatDate(scenario.lastModified) : ''}</p>
                                </div>
                                <div className="details-grid-item">
                                    <p className="detail-name">Category</p>
                                    {scenario.category ?
                                        <Badge
                                            text={scenario.category.title}
                                            type={CATEGORIES_BADGE_MAP[scenario.category.title]}
                                            size="lg"
                                        />
                                        : ''}
                                </div>
                            </div>
                        </CardContainer>
                        <CardContainer title="Usage history">
                            {usageHistory.length > 0 ? (
                                <UsageHistory usageHistory={usageHistory} />
                            ) : (
                                <p className="no-usage">There is no usage history yet</p>
                            )}
                        </CardContainer>
                    </div>
                </div>
            </Page>
        </>
    )
}

export default ScenarioDetails