/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { downArrow, upArrow } from 'assets/icons';

const indentStyles = Array.from({ length: 10 }, (_, i) => i + 1).map(
	(i) => css`
		.indent-${i} {
			text-indent: calc(16px * ${i});
		}
	`,
);

const Wrapper = styled.div`
	p {
		color: #10161a;
		font-size: 16px;
		line-height: 22px;
		font-style: normal;
		margin-bottom: 4px;
		font-weight: normal;
		font-family: Nunito Sans;
	}

	.dropdown-trigger {
		width: 100%;
		height: 40px;
		display: flex;
		color: #10161a;
		font-size: 16px;
		line-height: 22px;
		font-style: normal;
		border-radius: 3px;
		font-weight: normal;
		align-items: center;
		flex-direction: row;
		background-color: white;
		padding: 4px 10px 0 10px;
		font-family: Nunito Sans;
		border: 1px solid #d8e1e8;
		justify-content: space-between;

		.dropdown-value {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.error {
		border: 1px solid #db3737;
	}

	.dropdown-menu {
		width: 100%;
		overflow-y: auto;
		max-height: 332px;

		a {
			white-space: initial;
		}
	}

	.disabled {
		cursor: not-allowed;
	}

	${indentStyles}
`;

const Dropdown = ({
	label = '',
	placeholder = 'Select an option...',
	value,
	options = null,
	onChange,
	disabled = false,
	optionLabel = 'label',
	optionValue = 'value',
	error = false,
	onToggleOptions = null,
	disabledOptions = {},
}) => {
	const [dropdownId] = useState(() => (Math.random() + 1).toString(36).substring(2));
	const [showOptions, setShowOptions] = useState(false);

	const selectItem = (item) => {
		if (value != null && item.value == value.value) {
			return false;
		}

		if (!onChange) {
			throw Error("the 'onChange' prop/event has not been defined.");
		}

		onChange(item);
		setShowOptions(false);
	};

	const closeOnOutsideClick = useCallback((event) => {
		if (
			document.getElementById(dropdownId) &&
			!document.getElementById(dropdownId).contains(event.target)
		) {
			setShowOptions(false);
		}
	}, []);

	const getItemClass = (itmVal) => {
		let itemClass = 'dropdown-item';
		if (itmVal?.indent) {
			itemClass += ` indent-${itmVal.indent}`;
		}
		if (disabledOptions.length > 0 && disabledOptions.indexOf(itmVal.value) > -1) {
			return itemClass + ' active';
		}
		if (value != null && itmVal.value == value.value) {
			return itemClass + ' active';
		} else {
			return itemClass;
		}
	};

	useEffect(() => {
		if (dropdownId) {
			window.addEventListener('click', closeOnOutsideClick);
		}

		return () => window.removeEventListener('click', closeOnOutsideClick);
	}, []);

	useEffect(() => {
		if (onToggleOptions !== null) {
			onToggleOptions(showOptions);
		}
	}, [showOptions]);

	const dropDownValue = !value ? placeholder : value[optionLabel];

	return (
		<Wrapper className="dropdown" id={dropdownId}>
			{label && <p>{label}</p>}

			<div
				className={`dropdown-trigger ${disabled ? 'disabled' : ''} ${error ? 'error' : ''}`}
				role="button"
				onClick={() => !disabled && setShowOptions(!showOptions)}
			>
				<div className="dropdown-value" style={{ color: !value ? '#738694' : '#182026' }}>
					{dropDownValue}
				</div>

				{!disabled && <img alt="" src={showOptions ? upArrow : downArrow} />}
			</div>

			{options && (
				<ul className="dropdown-menu" style={{ display: showOptions ? 'block' : 'none' }}>
					{options?.map((item, index) => (
						<li key={`${item[optionValue]}-${index}`} onClick={() => selectItem(item)}>
							<a className={getItemClass(item)}>{item[optionLabel]}</a>
						</li>
					))}
				</ul>
			)}
		</Wrapper>
	);
};

export { Dropdown };

Dropdown.propTypes = {
	label: PropTypes.string,
	disabled: PropTypes.bool,
	placeholder: PropTypes.string,
	optionLabel: PropTypes.string,
	optionValue: PropTypes.string,
	value: PropTypes.oneOf([PropTypes.object, null]),
	options: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
};
