import React, { useState } from 'react';
import './NavCardPattern.scss';
import { NavCard } from './NavCard';
import { Badge } from 'componentsV2';

export const NavCardPattern = () => {
	const taskTabsInit = [
		{
			name: 'Section 1',
			subtext: '20/20 questions answered',
			completed: true,
			selected: true,
		},
		{
			name: 'Section 2',
			subtext: '20/20 questions answered',
			completed: true,
			selected: false,
		},
		{
			name: 'Section 3',
			subtext: '0/20 questions answered',
			completed: false,
			selected: false,
		},
		{
			name: 'Section 4',
			subtext: '0/20 questions answered',
			completed: false,
			selected: false,
			rightElement: <Badge text="Hidden" size="md" />,
		},
		{
			name: 'Section 5',
			subtext: '0/20 questions answered',
			completed: false,
			selected: false,
		},
	];

	const [taskTabs, setTaskTabs] = useState(taskTabsInit);

	const handleTaskTabClick = name => {
		const newTaskTabsState = taskTabs.map(taskTab => {
			return taskTab.name === name
				? { ...taskTab, selected: true }
				: { ...taskTab, selected: false };
		});
		setTaskTabs(newTaskTabsState);
	};

	return (
		<>
			<div className="nav-card-pattern">
				<div style={{ width: '260px' }}>
					<h2>Default props</h2>
					<NavCard name="Section name" />
				</div>
				<div style={{ width: '260px' }}>
					<h2>Selected = true</h2>
					<NavCard name="Section name" selected={true} />
				</div>
				<div style={{ width: '260px' }}>
					<h2>Completed = true</h2>
					<NavCard name="Section name" completed={true} />
				</div>
				<div style={{ width: '260px' }}>
					<h2>Selected = true, and completed = true</h2>
					<NavCard name="Section name" selected={true} completed={true} />
				</div>
				<div style={{ width: '260px' }}>
					<h2>With subtext</h2>
					<NavCard name="Section name" subtext="4/20 questions answered" />
				</div>
				<div style={{ width: '260px' }}>
					<h2>With badge</h2>
					<NavCard
						name="Section name"
						subtext="4/20 questions answered"
						rightElement={<Badge text="Hidden" size="md" />}
					/>
				</div>
			</div>
			<div className="nav-card-pattern-example">
				<h2>Example</h2>
				{taskTabs.map(taskTab => {
					return <NavCard {...taskTab} onClick={handleTaskTabClick} />;
				})}
			</div>
		</>
	);
};
