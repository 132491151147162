import React,{useEffect, useState,useContext,useRef} from "react";
import { Modal,Button ,FeaturedIcon,FileUpload,Intent } from "componentsV2";
import { DocumentService } from "services/DocumentService";
import {ToasterContext} from 'pages/App';
 import '../css/ReplaceContentModal.scss';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';


export const ReplaceContentModal = ({
    selectedContentToReplace ,
    showReplaceModal ,
    setShowReplaceModal ,
    binderId,
    reloadBinderDetails
}) => {
     const [loading, setLoading] = useState(false);
     const [selectedFile,setSelectedFile] = useState();
     const [disableSave,setDisableSave] = useState(true);
     const toaster = useContext(ToasterContext);
     const [oldFileData,setOldFileData] = useState()
     const [showUpload,setShowUpload] = useState(false);
     const footer = (
        <div className="button-div-styles">
                <Button
                  text={'Cancel'} 
                  type="tertiary"
				  intent="default"
                  onClick = {()=>handleCloseModal()}
                />
                <Button
                  text={'Save'} 
				  intent="default"
                  disabled ={disableSave}
                  onClick = {(e)=>{handleUploadFile(e)}}
                />
        </div>
    );

    useEffect(()=>{
        setDisableSave(true); // this to make sure that save button is disable if upload file and dismiss the modal and open it again
    },[showReplaceModal])

    useEffect(()=>{
        if(selectedContentToReplace)
        {
            setOldFileData({
                type:selectedContentToReplace.type,
                name:(selectedContentToReplace.title + '.' +  selectedContentToReplace.ext),
                size:(parseFloat(selectedContentToReplace.size)*1024),
                id:selectedContentToReplace.id,
                ext: selectedContentToReplace.ext
             })
             setShowUpload(true);
        }
    },[selectedContentToReplace])


    const onFileInputChange = (e) => {
        setSelectedFile(e);
    }


    const handleUploadFile = (e) => {
        e.preventDefault();
        let params = {
            buildingId:selectedContentToReplace.buildingId,
            documentId: selectedContentToReplace.contentId,
           attachmentId : binderId,
           attachmentType : 'binder',
        }

        setLoading(true);
       DocumentService.uploadDocument(params,selectedFile)
       .then(response => {
       if (response.statusCode === 200) {
           toaster('You have successfully replaced the file!', Intent.SUCCESS,<FeaturedIcon icon={'tick'} type={'Success'} size={'md'} shape={'circleIcon'}/>,20000,true)
           setShowReplaceModal(false) 
           reloadBinderDetails(binderId);
       }
       })
       .catch(error => {
           console.error('Error uploading the file:', error);
       })
       .finally(() => {
           setLoading(false);
           setShowUpload(false);
       });
   }

   const handleProgressCompleted = (isProgressCompleted) => {
    if(isProgressCompleted){setDisableSave(false)}
    else{setDisableSave(true)}
    } 

    const handleCloseModal =() => {
        setShowReplaceModal(false);
        setDisableSave(true);
        setShowUpload(false)
    }

    return (
        <Modal
        isOpen={showReplaceModal}
        onClose={() => handleCloseModal()}
        title={'Replace file'}
        size='small'
        showCloseButton={true}
        loading={loading}
        customFooter = {footer}
        customClassName = {'replace-modal-container'}
      > 
       
       {showUpload && (
        <div className = "col-md-12">
            <FileUpload
                disabled = {false}
                required = {true}
                allowAllTypes = {true}
                allowedFilesLegend = ''
                defaultErrorMessage = 'Upload failed, please try again'
                label = 'File'
                labelState = {false}
                onFileInputChange = {onFileInputChange}
                setProgressCompleted = {handleProgressCompleted}
                file = {oldFileData}
                fileForEditing = {true}
                maxFileSize={MAX_FILE_UPLOADER_SIZE}
            />
       </div>
       )

       }


      </Modal>
    );
}