import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
	width: 71px;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	padding: 2.5px 5px;
	font-style: normal;
	border-radius: 10px;
	letter-spacing: 0px;
	font-family: Nunito Sans;
	text-transform: capitalize;
	color: ${props => props?.textColor};
	background-color: ${props => props?.backgroundColor};

	img {
		margin-top: -2px;
		margin-right: 4px;
	}
`;

const Pilsner = ({
	icon,
	text,
	textColor,
	backgroundColor,
	wrapperStyles = {},
	textStyles = {},
}) => (
	<Wrapper
		className="pilsner"
		textColor={textColor}
		backgroundColor={backgroundColor}
		style={wrapperStyles}
	>
		<img src={icon} alt="" />
		<p style={textStyles}>{text}</p>
	</Wrapper>
);

Pilsner.propTypes = {
	icon: PropTypes.any.isRequired,
	text: PropTypes.string.isRequired,
	textColor: PropTypes.string.isRequired,
	backgroundColor: PropTypes.string.isRequired,
	wrapperStyles: PropTypes.object,
	textStyles: PropTypes.object,
};

export { Pilsner };
