import React, { useContext } from 'react';
import { BootstrapedTable, sortingStyle } from 'componentsV2';
import { formatDate } from 'utilities/dates';
import { CallListsActionsButton } from './CallListsActionsButton';
import { NavLink } from 'react-router-dom';
import { CallListContext } from '../Providers';
import { CALLLIST_ROUTES } from 'utilities/constants';

export const CallListTable = ({ callList, totalRows, history }) => {
	const { filterationData, dispatchFilterationData } = useContext(CallListContext);
	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'title',
			dataField: 'title',
			text: 'Name',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				return (
					<NavLink
						exact
						className="active"
						to={{
							pathname: `${CALLLIST_ROUTES.CONTACTSV2 + row.id}`,
							state: { contact: row.id },
						}}
					>
						{value}
					</NavLink>
				);
			},
		},
		{
			key: 'site',
			dataField: 'site',
			text: 'Site',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'date',
			dataField: 'dateModified.date',
			text: 'Last modified',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => {
				return value ? formatDate(value) : 'N/A';
			},
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => <CallListsActionsButton row={row} history={history} />,
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'title';
			switch (sortField) {
				case 'buildings[0].name':
					sortColumn = 'buildingName';
					break;
				case 'dateModified.date':
					sortColumn = 'lastModified';
					break;
				case 'numberOfContracts':
					sortColumn = 'contracts';
					break;
				default:
					sortColumn = sortField;
					break;
			}
			dispatchFilterationData({ type: 'sortKey', payload: sortColumn });
			dispatchFilterationData({ type: 'sortDir', payload: sortOrder });
		}
	};

	const setRowsPerPage = value => {
		dispatchFilterationData({ type: 'perPage', payload: value });
	};

	const setCurrentPage = value => {
		dispatchFilterationData({ type: 'page', payload: value });
	};
	return (
		<BootstrapedTable
			keyField="id"
			data={callList}
			columns={columns}
			bordered={false}
			onTableChange={onTableChange}
			currentPage={filterationData.page}
			rowsPerPage={filterationData.perPage}
			setCurrentPage={setCurrentPage}
			setRowsPerPage={setRowsPerPage}
			totalRows={totalRows}
		/>
	);
};
