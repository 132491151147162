import React, { useState } from "react";
import { Tag } from '@blueprintjs/core';

const SiteColumn = ({
    value,
    row
}) => {
    const [showSitesCard, setShowSitesCard] = useState(false);

    document.addEventListener('click', function(e){
        if ( !document.getElementById('tag-'+row.id)?.contains(e.target)){
            // Clicked outside the box
            setShowSitesCard(false)
        }
    })

    return (
        <div className="d-flex SiteColumn__Container">
            <div>
                {value}
            </div>
            {row.roles.length > 0 && row.primaryBuilding && (
            <div className="position-relative">
                <Tag 
                    key={row.id}
                    className="tags-extra"
                    onClick={() => setShowSitesCard(!showSitesCard)}
                    id={'tag-'+row.id}
                    style={{
                        marginLeft:5
                    }}
                    > +{row.roles.length} more 
                </Tag>
                {showSitesCard && 
                    <div className="roles-type-card">
                        {row.roles.map((role, key) => {
                            return (
                                <div className="roles-type-card-item" key={key}>
                                    <p>{ role.building.name}</p>
                                    <Tag key={key} className="tags-extra"> 
                                        {role.role.title}
                                    </Tag>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
            )}
        </div>
    );
}

export default SiteColumn;