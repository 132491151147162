import React from 'react';
import { RAPID_ICONS } from 'assets/respond';
import { getFormattedDate } from 'utilities/dates';
import { Badge } from 'componentsV2';
import { AlarmContentItem } from './AlarmContentItem';
import { formatDate } from '../../../../utilities/dates';
import { DateTime } from 'luxon';

export const AlarmDetailsCard = ({ pastAlarm }) => {
	return (
		<div className="alarm-card-container">
			<div className="alarm-title-container">
				<img
					src={RAPID_ICONS[(pastAlarm.alarmType?.icon)]}
					alt={pastAlarm.alarmType?.icon}
				/>

				<div className="alarm-title-and-subtitle">
					<div className="alarm-title-and-type">
						<h2>
							{pastAlarm.alarmType?.title} - #{pastAlarm.alarmId}
						</h2>
						<Badge
							key="past-alarm-type"
							type="Primary"
							size="md"
							text={`${pastAlarm.mode.charAt(0).toUpperCase() +
								pastAlarm.mode.slice(1)} mode`}
						/>
						{pastAlarm.alarmType?.disabled && (
							<Badge
								key="past-alarm-type-disabled"
								type="Gray"
								size="md"
								text="Disabled alarm type"
							/>
						)}
					</div>
					<span className="alarm-subtitle">{pastAlarm.building.name}</span>
				</div>
			</div>

			<div className="alarm-details-contents">
				<AlarmContentItem icon="map-marker" label="Address" value={pastAlarm.address} />

				<AlarmContentItem
					icon="time"
					label="Alarm duration"
					value={pastAlarm.duration ? pastAlarm.duration : 'N/A'}
				/>

				<AlarmContentItem
					icon="calendar"
					label="Start date and time"
					value={`${
						pastAlarm.dateStarted
							? getFormattedDate(pastAlarm.dateStarted.date) + ','
							: 'N/A'
					} ${
						pastAlarm.dateStarted
							? formatDate(pastAlarm.dateStarted.date, DateTime.TIME_SIMPLE)
							: ''
					}`}
				/>

				<AlarmContentItem
					icon="calendar"
					label="End date and time "
					value={`${
						pastAlarm.dateEnded
							? getFormattedDate(pastAlarm.dateEnded.date) + ','
							: 'N/A'
					} ${
						pastAlarm.dateEnded
							? formatDate(pastAlarm.dateEnded.date, DateTime.TIME_SIMPLE)
							: ''
					}`}
				/>

				<AlarmContentItem
					icon="person"
					label="Initiated by and via"
					value={`${
						pastAlarm.triggeredVia == '911_cellular' ? 'Unknown' : pastAlarm.initiatedBy
					} via ${
						pastAlarm.triggeredVia == '911_cellular'
							? pastAlarm.initiatedBy
							: pastAlarm.triggeredVia
					}`}
				/>
			</div>
		</div>
	);
};
