import { useEffect, useReducer, useContext } from 'react';
import { CallListService, PlanService, FlipchartService } from 'services';
import { ToasterContext, Intent } from 'componentsV2';

const flipchartTabReducer = (data, action) => {
	data[action.type] = action.payload;
	return {
		...data,
	};
};

export const useFlipchartTabForm = (
	flipchart,
	tab,
	tabFlipchartId,
	tabBuildingId,
	setModalIsOpen,
	onSubmitFinished,
) => {
	const toaster = useContext(ToasterContext);

	const textColor = tab && tab.textColor?.length > 0 ? tab.textColor : null;
	const backgroundColor = tab && tab.backgroundColor?.length > 0 ? tab.backgroundColor : null;
	const buildingId = tab ? tabBuildingId : flipchart ? flipchart.buildingId : null;

	// Initialize the reducer and set Initial values
	const [formData, dispatchFormData] = useReducer(flipchartTabReducer, {
		title: tab ? tab.title : '',
		subtitle: tab && tab.subtitle?.length > 0 ? tab.subtitle : '',
		textColor: textColor ? `#${textColor}` : '',
		backgroundColor: backgroundColor ? `#${backgroundColor}` : '',
		contentType: tab ? tab.contentTypeCode : 'custom',
		customContentValue: tab && tab.contentTypeCode == 'custom' ? tab.content : '',
		initialCustomContent: tab && tab.contentTypeCode == 'custom' ? tab.content : '',

		callListLoading: true,
		callListOptions: null,
		callListSelected: null,
		callListInitId: null,

		safetyPlanLoading: true,
		safetyPlanOptions: null,
		safetyPlanSelected: null,
		safetyPlanInitId: null,

		safetyPlanSectionLoading: true,
		safetyPlanSectionOptions: null,
		safetyPlanSectionSelected: null,
		safetyPlanSectionLoaded: false,

		safetyPlanSectionsData: [],
		safetyPlanSubsectionOptions: null,
		safetyPlanSubsectionSelected: null,
		safetyPlanSubsectionLoaded: false,

		disableSubmit: true,
		modalLoading: false,

		fieldsErrors: [],
	});

	useEffect(() => {
		if (tab && tab.contentTypeCode == 'call_list') {
			getCallListData();
		}
		if (tab && tab.contentTypeCode == 'safety_plan') {
			getSafetyPlansData();
		}
		if (!tab) {
			dispatchFormData({ type: 'modalLoading', payload: true });
			getNewTabColors();
		}
	}, []);

	useEffect(() => {
		if (
			tab &&
			tab.callListId > 0 &&
			tab.contentTypeCode == 'call_list' &&
			formData.callListOptions
		) {
			let option = formData.callListOptions.find(item => item.value === tab.callListId);
			dispatchFormData({ type: 'callListInitId', payload: tab.callListId });
			dispatchFormData({ type: 'callListSelected', payload: option });
			dispatchFormData({ type: 'callListLoading', payload: false });
		}
	}, [formData.callListOptions]);

	useEffect(() => {
		if (
			tab &&
			tab.safetyPlansIdNew > 0 &&
			tab.contentTypeCode == 'safety_plan' &&
			formData.safetyPlanOptions
		) {
			let option = formData.safetyPlanOptions.find(item => item.id === tab.safetyPlansIdNew);
			dispatchFormData({ type: 'safetyPlanInitId', payload: tab.safetyPlansIdNew });
			dispatchFormData({ type: 'safetyPlanSelected', payload: option });
			dispatchFormData({ type: 'safetyPlanLoading', payload: false });
			getSafetyPlanSectionsData(tab.safetyPlansIdNew);
		}
	}, [formData.safetyPlanOptions]);

	useEffect(() => {
		if (
			tab &&
			tab.safetyPlansSectionId > 0 &&
			tab.contentTypeCode == 'safety_plan' &&
			formData.safetyPlanSectionOptions &&
			!formData.safetyPlanSectionLoaded
		) {
			let option = formData.safetyPlanSectionOptions.find(
				item => item.value === tab.safetyPlansSectionId,
			);
			handleChangeSafetyPlanSection(option);
			dispatchFormData({ type: 'safetyPlanSectionLoaded', payload: true });
		}
	}, [formData.safetyPlanSectionOptions]);

	useEffect(() => {
		if (
			tab &&
			tab.safetyPlansSubsectionId > 0 &&
			tab.contentTypeCode == 'safety_plan' &&
			formData.safetyPlanSubsectionOptions &&
			!formData.safetyPlanSubsectionLoaded
		) {
			let option = formData.safetyPlanSubsectionOptions.find(
				item => item.value === tab.safetyPlansSubsectionId,
			);
			dispatchFormData({ type: 'safetyPlanSubsectionSelected', payload: option });
			dispatchFormData({ type: 'safetyPlanSubsectionLoaded', payload: true });
		}
	}, [formData.safetyPlanSubsectionOptions]);

	// Validate the form
	// Enable / Disable the Submit button
	useEffect(() => {
		let titleValid = formData.title.length > 0;
		let customContentValid =
			formData.contentType == 'custom' && formData.customContentValue.length > 0;
		let calllistValid =
			formData.contentType == 'call_list' && formData.callListSelected != null;
		let safetyPlanValid =
			formData.contentType == 'safety_plan' &&
			formData.safetyPlanSelected != null &&
			formData.safetyPlanSectionSelected != null;

		let disableSubmitResult =
			!titleValid || !(customContentValid || calllistValid || safetyPlanValid);
		dispatchFormData({ type: 'disableSubmit', payload: disableSubmitResult });
	}, [
		formData.title,
		formData.contentType,
		formData.customContentValue,
		formData.callListSelected,
		formData.safetyPlanSelected,
		formData.safetyPlanSectionSelected,
	]);

	const handleChangeTitle = ({ target }) => {
		dispatchFormData({ type: 'title', payload: target.value });
	};

	const handleChangeSubtitle = ({ target }) => {
		dispatchFormData({ type: 'subtitle', payload: target.value });
	};

	const handleChangeContentType = ({ target }) => {
		dispatchFormData({ type: 'contentType', payload: target.value });
		switch (target.value) {
			case 'call_list':
				if (!formData.callListOptions) {
					getCallListData();
				}
				break;
			case 'safety_plan':
				if (!formData.safetyPlanOptions) {
					getSafetyPlansData();
				}
				break;
		}
	};

	const handleChangeSafetyPlan = item => {
		dispatchFormData({ type: 'safetyPlanSelected', payload: item });
		dispatchFormData({ type: 'safetyPlanSectionSelected', payload: null });
		getSafetyPlanSectionsData(item.id);
	};

	const handleChangeSafetyPlanSection = item => {
		dispatchFormData({ type: 'safetyPlanSectionSelected', payload: item });
		dispatchFormData({ type: 'safetyPlanSubsectionOptions', payload: null });
		dispatchFormData({ type: 'safetyPlanSubsectionSelected', payload: null });

		if (formData.safetyPlanSectionsData.length > 0 && item.isNewSP === true) {
			formData.safetyPlanSectionsData.map(section => {
				if (section.id == item.value) {
					let formattedOptions = section.subsections.map(subsection => {
						return {
							value: subsection.id,
							label: subsection.title,
						};
					});
					dispatchFormData({
						type: 'safetyPlanSubsectionOptions',
						payload: formattedOptions,
					});
				}
			});
		}
	};

	const handleSubmit = async () => {
		try {
			// Disable the button to avoid multiple request
			// And disable all the form controls
			dispatchFormData({ type: 'disableSubmit', payload: true });
			dispatchFormData({ type: 'modalLoading', payload: true });

			let body = {
				content_type: formData.contentType,
				content: formData.customContentValue,
				title: formData.title,
				sub_title: formData.subtitle,
				calllist_id: formData.callListSelected ? formData.callListSelected.value : null,
				plan_id: formData.safetyPlanSelected ? formData.safetyPlanSelected.id : null,
				section_id: formData.safetyPlanSectionSelected
					? formData.safetyPlanSectionSelected.value
					: null,
			};
			if (formData.safetyPlanSubsectionSelected) {
				body.subsection_id = formData.safetyPlanSubsectionSelected.value;
			}
			if (formData.textColor) {
				body.text_color = formData.textColor.replace('#', '');
			}
			if (formData.backgroundColor) {
				body.background_color = formData.backgroundColor.replace('#', '');
			}

			if (tab) {
				// Update Tab
				const response = await FlipchartService.updateFlipchartTab(
					tabFlipchartId,
					tab.id,
					body,
				);
				if (response.statusCode == 200) {
					toaster(
						`You have successfully updated "${formData.title}" tab.`,
						Intent.SUCCESS,
					);
					setModalIsOpen(false);
					onSubmitFinished(tabFlipchartId);
				}
			} else {
				// Add new tab
				const response = await FlipchartService.addFlipchartTab(flipchart.id, body);
				if (response.statusCode == 201) {
					toaster(
						`You have successfully added "${formData.title}" as a new tab.`,
						Intent.SUCCESS,
					);
					setModalIsOpen(false);
					onSubmitFinished(flipchart.id);
				}
			}
		} catch (error) {
			dispatchFormData({ type: 'modalLoading', payload: false });
			if (error.error.fields) {
				dispatchFormData({ type: 'fieldsErrors', payload: error.error.fields });
			} else {
				setModalIsOpen(false);
				toaster(
					'An error occurred while adding a tab to the flipchart. Please contact the administrator.',
					Intent.DANGER,
				);
			}
		}
	};

	const getCallListData = () => {
		CallListService.getCallListsLegacy({
			selectedBuilders: buildingId ? [buildingId] : [],
			sortKey: 'title',
			sortDir: 'asc',
		})
			.then(resp => {
				if (resp.statusCode === 200) {
					let formattedOptions = resp.data.map(calllist => {
						return {
							value: calllist.id,
							label: calllist.title,
						};
					});
					dispatchFormData({ type: 'callListOptions', payload: formattedOptions });
					dispatchFormData({ type: 'callListLoading', payload: false });
				}
			})
			.catch(() => dispatchFormData({ type: 'callListLoading', payload: false }));
	};

	const getSafetyPlansData = () => {
		PlanService.getLightPlans({ buildingId })
			.then(response => {
				if (response.statusCode === 200) {
					dispatchFormData({ type: 'safetyPlanOptions', payload: response.data });
					dispatchFormData({ type: 'safetyPlanLoading', payload: false });
				}
			})
			.catch(() => dispatchFormData({ type: 'safetyPlanLoading', payload: false }));
	};

	const getSafetyPlanSectionsData = safetyPlanId => {
		dispatchFormData({ type: 'safetyPlanSectionLoading', payload: true });
		dispatchFormData({ type: 'safetyPlanSectionSelected', payload: null });

		PlanService.getPlanLegacy(safetyPlanId)
			.then(response => {
				if (response.statusCode === 200) {
					const safetyPlanItem = response.data;
					if (safetyPlanItem.isNewSP) {
						// get content
						let sections = JSON.parse(safetyPlanItem.content);
						dispatchFormData({ type: 'safetyPlanSectionsData', payload: sections });
						let formattedOptions = sections.map(section => {
							return {
								value: section.id,
								label: section.title,
								isNewSP: true,
							};
						});
						dispatchFormData({
							type: 'safetyPlanSectionOptions',
							payload: formattedOptions,
						});
						dispatchFormData({ type: 'safetyPlanSectionLoading', payload: false });
					} else {
						// get annexes
						let annexes = safetyPlanItem.annexes;
						dispatchFormData({ type: 'safetyPlanSectionsData', payload: annexes });
						let formattedOptions = annexes.map(annex => {
							return {
								value: annex.questions_id,
								label: annex.title,
								isNewSP: false,
							};
						});
						dispatchFormData({
							type: 'safetyPlanSectionOptions',
							payload: formattedOptions,
						});
						dispatchFormData({ type: 'safetyPlanSectionLoading', payload: false });
					}
				}
			})
			.catch(() => dispatchFormData({ type: 'safetyPlanSectionLoading', payload: false }));
	};

	const getNewTabColors = async () => {
		try {
			const response = await FlipchartService.getNewFlipchartTabColors(flipchart.id);
			if (response.statusCode == 200) {
				let backgroundColor = null;
				let textColor = null;
				let tabColors = response.data;
				if (tabColors && tabColors.primaryTextColor && tabColors.secondaryTextColor) {
					backgroundColor = `#${tabColors.primaryTextColor}`;
					textColor = `#${tabColors.secondaryTextColor}`;
				}
				dispatchFormData({ type: 'backgroundColor', payload: backgroundColor });
				dispatchFormData({ type: 'textColor', payload: textColor });
				dispatchFormData({ type: 'modalLoading', payload: false });
			}
		} catch (error) {
			setModalIsOpen(false);
			toaster(
				'An error occurred while loading a new tab. Please contact the administrator.',
				Intent.DANGER,
			);
		}
	};

	return {
		formData,
		dispatchFormData,
		handleChangeTitle,
		handleChangeSubtitle,
		handleChangeContentType,
		handleChangeSafetyPlan,
		handleChangeSafetyPlanSection,
		handleSubmit,
	};
};
