/**
 * Function to get darker or lighter color based on a
 * specific color and percentage to add light or dark
 * @param {*} color
 * @param {*} percent // can be positive to light and negative to dark
 * @returns string like "f1f1f1"
 */
export const geDarkerLighterColor = (color, percent) => {
	const num = parseInt(color, 16);
	const amt = Math.round(2.55 * percent);
	const R = (num >> 16) + amt;
	const B = ((num >> 8) & 0x00ff) + amt;
	const G = (num & 0x0000ff) + amt;
	return (
		0x1000000 +
		(R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
		(B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
		(G < 255 ? (G < 1 ? 0 : G) : 255)
	)
		.toString(16)
		.slice(1);
};
