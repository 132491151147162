import React from "react";
import { Icon } from "@blueprintjs/core";
import { Avatar } from "componentsV2";
import styled from "styled-components";

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    .item {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .item:not(:last-child) {
        border-bottom: 1px solid rgba(228, 231, 231, 1);
        padding-bottom: 16px;
    }

    .info {
        color: rgba(105, 114, 119, 1);
        display: flex;
        justify-content: space-between;
        gap: 32px;
    }

    .info-item {
        display: flex;
        gap: 4px;
    }
`

const formatDateUsed = (dateUsed) => {
    let dateObj = new Date(dateUsed.split('/').reverse().join('-'));
    let options = { day: '2-digit', month: 'short', year: 'numeric', timeZone: 'UTC' };
    return dateObj.toLocaleDateString('en-US', options);
}

export const UsageHistory = ({ usageHistory }) => {
    return (
        <Styled>
            {/* flatten because /usagehistory returns a nested array for scenarios with limited visibility */}
            {usageHistory.flat(Infinity).map((item) => (
                <div className="item">
                    <Avatar
                        name={item.user}
                        image={item.personPhotoUrl}
                        size="md"
                        label={item.user}
                        supportingText={formatDateUsed(item.dateUsed)}
                    />
                    {item.notes && (
                        <p>{item.notes}</p>
                    )}
                    <div className="info">
                        <div className="info-item">
                            <Icon icon="offline" size={12} style={{ paddingTop: '2px' }} />
                            <span>{item.type}</span>
                        </div>
                        <div className="info-item">
                            <Icon icon="map-marker" size={14} style={{ paddingTop: '2px' }} />
                            <span>{item.site}</span>
                        </div>
                    </div>
                </div>
            ))}
        </Styled>
    );
}
