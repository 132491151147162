import React from 'react';
import PropTypes from 'prop-types';
import './css/Animated.scss';

export const Animated = ({ show = true, children }) => {

    return (
        <div className={`animated-container ${show ? "mounted" : "unmounted"} `}>
            {children}
        </div>
    )
}

Animated.propTypes = {
	show: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};
