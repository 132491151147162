import { Intent } from "componentsV2";
import { PlanService } from "../../../../../../services/PlanService";
import { useState } from "react";

export const DeletePlanModalFn = (
    modalName,
	setLoading,
	safetyPlanData,
    dispatchSafetyPlanData,
	closeModal,
	toaster,
	setModalToShow,
	history,
    loadData
) => {

    const [source, setSource] = useState(null);

    const showDeleteModal = (plan, source = null) => {
        setModalToShow(modalName);
        dispatchSafetyPlanData({
            ...safetyPlanData,
            id: plan.id,
            title: `“${plan.name}”`,
            modalTitle: `Are you sure you want to delete “${plan.name}”?`,
            confirmBtnTitle: `Yes, delete it`,
        });

        setSource(source);
    }

    const handleDeleteSubmit = () => {
        setLoading(true);
        PlanService.deletePlan(safetyPlanData.id)
            .then(() => {
                toaster(`You have successfully deleted ${safetyPlanData.title}`, Intent.SUCCESS);
                closeModal();
                if (source == 'list') {
                    loadData();
                } else {
                    history.push('/safety-plans');
                }
            })
            .catch((error) => { 
                toaster('Something went wrong, please try again later', Intent.DANGER);
             })
            .finally(() => { setLoading(false); });
    }

    return {
        showDeleteModal,
        handleDeleteSubmit
    }
}    