import React from 'react';
import { NoShapeContentState, ShapeContentItem } from './index';
import { emptyDocument } from 'componentsV2';

import '../css/ShapeContent.scss';

export const DocumentsBindersContent = ({
	documentsBinders,
	contentType,
	shapeType,
	shapeId,
	siteId,
	editPermission,
	floorplanArchived,
	isSearchedContent,
	loadContentData,
	isEditMode,
	viewPermissionOnSiteId,
	floorplan,
}) => {
	return (
		<div className="area-content-items-container">
			{documentsBinders.length > 0 ? (
				documentsBinders.map(item => (
					<ShapeContentItem
						item={item}
						id={item.uniqId}
						ext={item.type == 'binder' ? 'pdf' : item.ext}
						mimetype={item.type == 'binder' ? 'application/pdf' : item.mimeType}
						thumbnail={item.thumbnail}
						contentTitle={item.contentTitle}
						createdBy={item.createdBy}
						createdDate={item.createdDate || item.dateEntered}
						editPermission={editPermission}
						isEditMode={isEditMode}
						floorplanArchived={floorplanArchived}
						siteId={siteId}
						shapeId={shapeId}
						shapeType={shapeType}
						contentType={item.type == 'binder' ? 'binder' : 'document'}
						loadContentData={loadContentData}
						viewPermissionOnSiteId={viewPermissionOnSiteId}
						floorplan={floorplan}
					/>
				))
			) : (
				<NoShapeContentState
					icon={emptyDocument}
					header={
						isSearchedContent ? 'No content found' : 'No documents currently added.'
					}
					description={
						isSearchedContent
							? `Your search didn’t match any ${contentType}s. Please try again.`
							: editPermission && !floorplanArchived && isEditMode
							? 'Click the + button to add a document.'
							: ''
					}
				/>
			)}
		</div>
	);
};
