import React from "react";
import { CardContainer, Color, FeaturedIcon } from "componentsV2";
import InputWrapper from "componentsV2/InputWrapper/InputWrapper";
import { formatDate } from "utilities/dates";
import { EditBinder } from "./EditBinder";
import '../css/DetailsSection.scss';

const BinderDetailsItem = ({
    title,
    value
}) => (
    <div className="binder-details-item">
        <InputWrapper
            label={title}
            labelState={false}
        >
            <div className="item-value">
                {value}
            </div>
        </InputWrapper>
    </div>
)

export const DetailsSection = ({
    viewMode,
    binder,
    onEditSubmit,
}) => {

    const DetailsCardSideItems = !viewMode && <EditBinder binder={binder} onSubmitFinished={onEditSubmit} />
    if(!binder) return null;
    return (
        <CardContainer
            title="Details"
            rightAction={DetailsCardSideItems}
            bodyCustomClass='binder-details-section-container'
        >
            {binder.archivedByUser && (
                <CardContainer>
                    <FeaturedIcon icon='archive' size='md' type='Gray' />
                    Archived by {binder.archivedByUser.person?.fullName} on {(binder.dateArchived?.date ? formatDate(binder.dateArchived?.date) : "")}.
                </CardContainer>
            )}
            <section className="binder-details-items">
            <BinderDetailsItem
                key="Site"
                title="Site"
                value={binder.building ? binder.building.name : "Visible to all"}
            />
            <BinderDetailsItem
                key="Date created"
                title="Date created"
                value={binder.dateEntered?.date ? formatDate(binder.dateEntered?.date) : ""}
            />
            <BinderDetailsItem
                key="Created by"
                title="Created by"
                value={binder.enteredByUser?.person?.fullName}
            />
            <BinderDetailsItem
                key="Last revised"
                title="Last revised"
                value={binder.lastRevised?.date ? formatDate(binder.lastRevised?.date) : ""}
            />
            <BinderDetailsItem
                key="Color"
                title="Color"
                value={<Color 
                        size="sm" 
                        customStyle={{
                            backgroundColor: '#'+ binder.color,
                            border: '1px solid #D9DCDD'
                        }}
                    />
                }
            />           
            <BinderDetailsItem
                key="Description"
                title="Description"
                value={binder.description ? binder.description : 'N/A'}
            />
            </section>
        </CardContainer>
    );
}