import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '@blueprintjs/core';
import { COMPLETED_STATUS } from '../../';
import { SectionCard } from './SectionCard';
import './css/PendingUpdates.scss';

export const PendingUpdates = ({ templateUpdates }) => {
	const [completedUpdates, setCompletedUpdates] = useState([]);

	useEffect(() => {
		// get completed updates
		const getCompletedUpdates = templateUpdates.filter(
			templateUpdate => templateUpdate.status === COMPLETED_STATUS,
		);

		setCompletedUpdates(getCompletedUpdates);
	}, [templateUpdates]);

	const completedPercentage = ((completedUpdates.length / templateUpdates.length) * 100).toFixed(0);

	return (
		<section className="pending-updates-wrapper">
			<section className="pending-updates-header">
				<div className="pending-updates-title">
					Pending Updates
					<br />({completedUpdates.length} out of{' '}
					{templateUpdates.length} applied)
				</div>
				<div className="pending-updates-bar">
					<ProgressBar
						value={completedPercentage / 100}
						stripes={false}
						className="progress-bar-element"
						animate={false}
					/>
					<p className="percentage">{completedPercentage} %</p>
				</div>
			</section>
			<section className="pending-updates-sections">
				{templateUpdates.map(templateUpdate => (
					<SectionCard
						key={templateUpdate.id}
						templateUpdate={templateUpdate}
					/>
				))}
			</section>
		</section>
	);
};

PendingUpdates.propTypes = {
	templateUpdates: PropTypes.array.isRequired,
};
