import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';
import { allowedExtensions, validateTemplateSubsection } from 'utilities';

const Wrapper = styled.div``;

const BigIcon = styled.div`
	color: #5c7080;
	text-align: center;
	padding: 30px 0;
	.big-icon {
		svg {
			width: 48px;
			height: auto;
			margin-bottom: 10px;
		}
	}
	p {
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		color: #394b59;
	}
`;

const LightIcon = styled(BigIcon)`
	color: #d8e1e8;
`;

const FileName = styled.p`
	font-family: Merriweather;
	font-weight: bold !important;
	font-size: 18px !important;
	line-height: 21px;
	color: #394b59 !important;
`;

const FileSize = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #394b59;
`;

const ButtonWrap = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: 56px;
`;

const ButtonFileWrap = styled.div`
	display: flex;
	justify-content: flex-end;
`;

class FileSection extends React.Component {
	state = {
		showButtons:false
	};

	toggleMode = () => {
		const {
			type,
			sectionIndex,
			subsectionIndex,
			toggleUpdateFileMode,
			actualSectionId,
			actualSubsectionId,
		} = this.props;
		let indexVal = sectionIndex + '-' + subsectionIndex;
		this.setState({ showButtons: true });
		return toggleUpdateFileMode(
			true,
			indexVal,
			type,
			actualSectionId,
			actualSubsectionId,
		);

	};

	deleteFile = () => {
		Swal.fire({
			title: 'Delete the file?',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Delete',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				const {
					subsection: { valueFile },
					templateId,
					dispatch,
					sectionIndex,
					subsectionIndex,
					saveAndReload,
					saveWithoutReload
				} = this.props;

				const token = JSON.parse(localStorage.getItem('user')).jwt;

				Swal.fire('Removing Attachment...');
				Swal.showLoading();

				fetch(`${process.env.API_URL}/district-templates/${templateId}/files/${valueFile.id}`, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
					}
				})
				.then(res => res.json())
				.then(resp => {
					dispatch({
						type: 'DELETE_VALUE_FILE',
						sectionIndex,
						subsectionIndex,
					});
					saveWithoutReload();
				});
			}

		});
	};

	publishUpdate = () => {
    const {
      templateId,
      sectionIndex,
      subsectionIndex,
      actualSectionId,
      actualSubsectionId,
      template,
      subsection,
      subsection: { reupload, baseFile },
    } = this.props;
    // should always be true -- publish is for templates only
    const errorObj = validateTemplateSubsection(
      subsection,
      actualSectionId,
      true,
      null,
      template.name,
    );
    if (
      !errorObj.issue ||
      (!reupload && errorObj.issue === 'missingUpload')
    ) {

		Swal.fire({
			title: 'Publish update?',
			text: 'You are about to publish an update for this section',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Publish',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				Swal.fire('Publishing');
				Swal.showLoading();
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				const tempcontent = '';
				let subsectionTitle;
				if (actualSubsectionId) {
				  template.sections[sectionIndex].subsections[subsectionIndex].content = tempcontent;
				  subsectionTitle = template.sections[sectionIndex].subsections[subsectionIndex].title;
				} else {
				  template.sections[sectionIndex].content = tempcontent;
				  subsectionTitle = template.sections[sectionIndex].title;
				}
				let updatedContent;
				if(reupload) {
					updatedContent = {
						actualSectionId,
						actualSubsectionId,
						content: '',
						is_document:true,
						reupload: true
					};
					if(!!baseFile?.name) {
						updatedContent['baseFile'] = baseFile;
					}
				} else {
					updatedContent = {
						actualSectionId,
						actualSubsectionId,
						content: '',
						is_document:true,
						isClient:1
					};
				}
				updatedContent.index = actualSubsectionId ? subsectionIndex : sectionIndex;
		  		updatedContent.title = subsectionTitle;
				return fetch(
					`${
						process.env.API_URL
					}/district-templates/${templateId}/updates`,
					{
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({
							type: 'update',
							updatedContent: JSON.stringify(updatedContent),
							actualSectionId,
							actualSubsectionId,
						}),
					},
				)
					.then(res => res.json())
					.then(resp => {
						if (resp.statusCode === 201) {
							fetch(
								`${
									process.env.API_URL
								}/district-templates/${parseInt(template.id, 10)}`,
								{
									method: 'PUT',
									headers: {
										'Content-Type': 'app/json',
										Authorization: `Bearer ${token}`,
									},
									body: JSON.stringify({
										content: JSON.stringify(
											template.sections,
										),
										name: template.name,
										state: template.state,
									}),
								},
							)
								.then(res => res.json())
								.then(rsp => {
									if (rsp.statusCode === 200) {
										Swal.close();
										Swal.fire({
											title: 'Done!',
											icon: 'success',
											timer: 1000,
											showConfirmButton: false,
										}).then(() => window.location.reload());
									}
								});
						} else if(resp.statusCode == 400) {
							Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: resp.error?.description || 'Could not publish update. Please try again.',
							});
						}
						return null;
					});
			}
			return Swal.close();
		});
    } else {
      Swal.fire({
        html: errorObj.messageHTML,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'ok',
        reverseButtons: true,
        returnFocus: false,
        onAfterClose() {
          const element = document.getElementById('subsection-' + actualSectionId + '-' + actualSubsectionId);
          if (!!element) {
            element.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }
        }
      }).then(result => {
        if (result.value) {
          return Swal.close();
        }
      });
    }
	};
	render() {
		const {
			subsection: { reupload, baseFile, valueFile },
			openUploadModal,
			sectionIndex,
			subsectionIndex,
			cancelUpdateMode,
			viewMode,
		} = this.props;
		const { showButtons } = this.state;
		return (
			<>
			{this.props.hasPermission &&
			<ButtonFileWrap className="">
				{!viewMode && (
					<Button
						text= "Create an Update"
						minimal
						onClick={this.toggleMode}
					/>
				)}

				{showButtons && (<><span></span>
					<Button
						text="Cancel"
						onClick={cancelUpdateMode}
					/>
					<Button
						text="Publish"
						className="ml-2"
						onClick={this.publishUpdate}
					/></>
				)}
			</ButtonFileWrap>
			}
			<Wrapper  className='file-section-wrapper'>
				{!reupload && (
					<>
						{valueFile.name && !viewMode && (
							<ButtonWrap className="mt-3">
								<Button
									icon="cross"
									minimal
									onClick={this.deleteFile}
								/>
							</ButtonWrap>
						)}

						<LightIcon>
							<Icon icon="document" className="big-icon" />
							{valueFile.name && (
								<>
									<FileName>{valueFile.name}</FileName>
									<FileSize>
										{(valueFile.size / 1000000).toFixed(2)}{' '}
										MB
									</FileSize>
								</>
							)}
							{!valueFile.name && !viewMode && (
								<>
									<p>Upload your document</p>
									<p>{allowedExtensions}</p>
									<Button
										intent="primary"
										rightIcon="arrow-top-right"
										text="Upload Document"
										onClick={() =>
											openUploadModal(
												false,
												null,
												valueFile,
												sectionIndex,
												subsectionIndex,
											)
										}
									/>
								</>
							)}
						</LightIcon>
					</>
				)}
				{reupload && !viewMode && (
					<LightIcon>
						{valueFile.name && (
							<ButtonWrap>
								<Button
									icon="cross"
									minimal
									onClick={this.deleteFile}
								/>
							</ButtonWrap>
						)}
						<BigIcon>
							<Icon icon="document" className="big-icon" />
							<FileName>
								{valueFile.name
									? valueFile.name
									: baseFile?.name}
							</FileName>
							{valueFile.size && (
								<FileSize>
									{(valueFile.size / 1000000).toFixed(2)} MB
								</FileSize>
							)}
						</BigIcon>
						{!valueFile.name && !viewMode && (
							<Button
								intent="primary"
								rightIcon="arrow-top-right"
								text="Download and re-upload signed document"
								onClick={() =>
									openUploadModal(
										true,
										baseFile,
										valueFile,
										sectionIndex,
										subsectionIndex,
									)
								}
							/>
						)}
					</LightIcon>
				)}
			</Wrapper>
			</>
		);
	}
}

FileSection.propTypes = {
	subsection: PropTypes.object,
	openUploadModal: PropTypes.func,
	sectionIndex: PropTypes.number,
	subsectionIndex: PropTypes.number,
	templateId: PropTypes.string,
	dispatch: PropTypes.func,
};

export default FileSection;
