import React from 'react';
import '../css/Chat.scss';

export const ChatContainer = ({ children }) => {
	return (
		<div className="chat-container">
			<div className="chatroom-group">{children}</div>
		</div>
	);
};
