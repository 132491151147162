import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, FormModal, Intent, FeaturedIcon, ToasterContext } from 'componentsV2';
import PropTypes from 'prop-types';
import { BuildingsService, SchoolYearsService } from 'services';
import { HttpService } from 'networking/HttpService';
import { getBuildingsByPermission } from 'utilities/permissions';

export const StartNewAssessmentModal = ({ onClose, initAssessments, history }) => {
	const [siteOptions, setSiteOptions] = useState(null);
	const [yearsOptions, setYearsOptions] = useState(null);
	const [templatesOptions, setTemplateOptions] = useState(null);
	const [siteSelectedValue, setSiteSelectedValue] = useState(null);
	const [templateSelectedValue, setTemplateSelectedValue] = useState(null);
	const [yearSelectedValue, setYearSelectedValue] = useState(null);
	const toaster = useContext(ToasterContext);
	const permissions = useSelector(state => state.route.permissions);
	const sitesByPermission = getBuildingsByPermission('site_assess_edit', permissions);

	useEffect(() => {
		getAssessmentSites();
		getAssessmentYears();
		getAssessmentTemplates();
	}, []);

	const getAssessmentSites = () => {
		BuildingsService.getAll().then(siteDrillTypes => {
			const filteredSitesByPermission = siteDrillTypes.filter(site =>
				sitesByPermission.find(building => building.id === site.id),
			);
			setSiteOptions(filteredSitesByPermission);
		});
	};

	const getAssessmentYears = () => {
		SchoolYearsService.fetchYears().then(siteYears => setYearsOptions(siteYears));
	};

	const getAssessmentTemplates = () => {
		HttpService({
			method: 'GET',
			route: `/templates?includeUnpublished=${false}`,
		})
			.then(res => {
				if (res.statusCode === 200) {
					setTemplateOptions(res?.data?.templates);
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	const onSave = () => {
		HttpService({
			method: 'POST',
			route: '/assessments',
			body: {
				buildingId: siteSelectedValue?.id,
				templateId: templateSelectedValue?.id,
				year: yearSelectedValue?.value,
			},
		})
			.then(res => {
				history.push({
					pathname: `/assessV2/assessment/${res?.data?.id}`,
					state: { data: res?.data },
				});
			})
			.catch(err => {
				toaster(
					`There was an error starting the assessment. Please try again.`,
					Intent.DANGER,
					<FeaturedIcon icon="error" size="md" type="Error" shape="circleIcon" />,
				);
				console.log(err);
			})
			.finally(() => {
				onClose();
			});
	};
	return (
		<>
			<FormModal
				isOpen
				onClose={onClose}
				showCloseButton
				title="Start New Assessment"
				scrollContent={false}
				confirmBtn={{
					title: 'Start',
					onClick: () => onSave(),
					disabled: !(siteSelectedValue && yearSelectedValue && templateSelectedValue),
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: () => onClose(),
				}}
			>
				<div className="w-100">
					<Dropdown
						label="Select site"
						placeholder="Select site"
						options={siteOptions}
						isRequired
						value={siteSelectedValue}
						onChange={item => {
							setSiteSelectedValue(item);
						}}
					/>
				</div>
				<div className="w-100">
					<Dropdown
						label="Select template"
						placeholder="Select template"
						optionLabel="name"
						optionValue="id"
						options={templatesOptions}
						isRequired
						value={templateSelectedValue}
						onChange={item => {
							setTemplateSelectedValue(item);
						}}
					/>
				</div>
				<div className="w-100">
					<Dropdown
						label="Select school year"
						placeholder="Select school year"
						options={yearsOptions}
						isRequired
						value={yearSelectedValue}
						onChange={item => {
							setYearSelectedValue(item);
						}}
					/>
				</div>
			</FormModal>
		</>
	);
};
StartNewAssessmentModal.propTypes = {
	onClose: PropTypes.any,
};
