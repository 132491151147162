import React, {useState, useEffect} from 'react';
import { decodeToken } from 'utilities';

export default function AwsImages () 
{
    const getSite = () => decodeToken(JSON.parse(localStorage.getItem('user')).jwt).dist;
    const [awsImages,setAwsImages] = useState([]); 
    const siteId = getSite();
    const filterType ='userimages';
    const url = process.env.API_URL;
    const token = JSON.parse(localStorage.getItem('user')).jwt;
   
    useEffect(() => {
        // AWS IMAGES TEST FETCH  
        fetch(`${url}/awsimages/explore/${siteId}/${filterType}`, {
            method : 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(resp => {
            setAwsImages(resp.data.images_urls)
        });
    },[]);

    const deleteImage = (item) => {
        let itemName = item.split('/users/images/')[1];
        let fileName = itemName.split('?X-Amz')[0];
        let userId = fileName.split('.')[0];

        fetch(`${url}/awsimages/remove/${fileName}/${userId}`, {
            method : 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(resp => {
            window.location.reload();
        });
    };

    const listImages = (item, index) => {

        let itemName = item.split('/users/images/')[1];
        itemName = itemName.split('?X-Amz')[0];

        if (index < 4) {
            return (
                <>
                    <p key={index}>
                        * <img src={item} style={{'maxWidth':'80px'}} />
                        <br/>
                        <a href={item} target="_blank" >
                            { itemName }
                        </a>
                         - 
                        <a href="#" onClick={(e) => deleteImage(item)}>
                            Delete
                        </a>
                    </p>
                </>
            );
        }
        
    };

    return (
        <>
            <h2>Images on FOLDER</h2>
            
            {awsImages.map((item, index) => listImages(item, index))}
        </>
    );
    
}

