import React, { useState, useEffect, useRef } from 'react';
import { DatePicker, TimePrecision } from '@blueprintjs/datetime';
import { Icon } from '@blueprintjs/core';
import { Button } from 'componentsV2';
import './DateCellEdit.scss';
import { formatDate } from 'utilities/dates';

export const DateCellEdit = ({ editorProps, date, row, onSubmit }) => {
	const [selectedDate, setSelectedDate] = useState(null);
	const [isExpanded, setIsExpanded] = useState(false);
	const editorRef = useRef(null);

	const maxDateValue = new Date();
	maxDateValue.setFullYear(maxDateValue.getFullYear() + 2);

	const handleOnChange = value => {
		setSelectedDate(value);
	};

	const submitDate = () => {
		onSubmit(row, selectedDate);
		editorProps.onUpdate(selectedDate);
	};

	useEffect(() => {
		setIsExpanded(true);
		if (date) {
			setSelectedDate(date);
		}
	}, []);

	useEffect(() => {
		// Function to check if clicked outside of editor
		function handleClickOutside(event) {
			if (editorRef.current && !editorRef.current.contains(event.target)) {
				submitDate();
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [editorRef, selectedDate]);

	return (
		<div className="date-cell-edit-container" ref={editorRef}>
			{/* Fake date Input div */}
			<div className="drills-table-schedule-date-cell">
				<Icon icon="calendar" />
				<span>{formatDate(selectedDate ? selectedDate : new Date().toLocaleString())}</span>
			</div>
			<div className={`date-cell-edit-datepicker ${isExpanded ? 'expanded' : ''}`}>
				<DatePicker
					maxDate={maxDateValue}
					defaultValue={date ? new Date(date) : new Date()}
					onChange={handleOnChange}
					timePrecision={TimePrecision.MINUTE}
					timePickerProps={{ useAmPm: true, showArrowButtons: true }}
				/>
				<Button
					wrapperClass="date-cell-edit-button"
					type="tertiary"
					text="Save"
					onClick={submitDate}
				/>
			</div>
		</div>
	);
};
