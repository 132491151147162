const scrollToSidebarSection = (sectionId, subSectionId, sideBarRef) => {
	let planoutLineId = `planoutline-${sectionId}`;
	if (subSectionId) {
		planoutLineId += `-${subSectionId}`;
	}
	const planoutLineElement = document.getElementById(planoutLineId);
	if (planoutLineElement && sideBarRef?.current) {
		sideBarRef.current.scrollTo({
			behavior: 'smooth',
			block: 'start',
			top: planoutLineElement.offsetTop - 150,
		});
	}
};

export { scrollToSidebarSection };
