/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { downArrow, upArrow, dislikeFill, likeFill } from 'assets/icons';
import { TableRow } from './tablerow';

const Wrapper = styled.div`
	width: 100%;
	padding: 24px;
	transition: 0.2s;
	margin-bottom: 40px;
	margin-top: 0;
	background-color: white;

	.title-row {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		flex-direction: row;

		h4 {
			margin: 0;
			color: #5c7080;
			font-size: 14px;
			text-align: left;
			font-weight: 700;
			line-height: 20px;
			font-style: normal;
			letter-spacing: 0.3;
			font-family: Nunito Sans;
		}

		img {
			user-select: none;
			width: 14px;
			height: 14px;
			margin-left: 10px;
		}
	}

	.content-rows {
		margin-top: 24px;
	}

	p {
		color: #5c7080;
		font-size: 16px;
		text-align: left;
		font-weight: 400;
		line-height: 22px;
		font-style: normal;
		letter-spacing: 0px;
		font-family: Nunito Sans;

		span {
			color: #9f1c3a;
		}
	}
`;

export const Table = ({ data }) => {
	return data.template.templateSections.map(item => (
		<React.Fragment key={item.id}>
			<div className="subtitle">
				<h5 className="first-subtitle">{item.name}</h5>
				{item.questions.length > 0 && (
					<h5 className="second-subtitle">
						Showing all sections, all answer types
					</h5>
				)}
			</div>
			{item.questions.length > 0 && (
				<Wrapper>
					<table className="table">
						<thead>
							<tr>
								<th>Question</th>
								<th>Answer</th>
								<th className="title-row">
									<div className="d-flex w-100 justify-content-end">
										<img alt="" src={likeFill} />
										<h4>/</h4>
										<img alt="" src={dislikeFill} />
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{item.questions.map(one => {
								return (
									<TableRow key={one.questionid} item={one} />
								);
							})}
						</tbody>
					</table>
				</Wrapper>
			)}
			{item.sections.length > 0 &&
				item.sections.map(sub => {
					return (
						<>
							<div className="subtitle">
								<h5 className="first-subtitle">{sub.name}</h5>
								{sub.questions.length > 0 && (
									<h5 className="second-subtitle">
										Showing all sections, all answer types
									</h5>
								)}
							</div>
							{sub.questions.length > 0 && (
								<Wrapper>
									<table className="table">
										<thead>
											<tr>
												<th>Question</th>
												<th>Answer</th>
												<th className="title-row">
													<div className="d-flex w-100 justify-content-end">
														<img
															alt=""
															src={likeFill}
														/>
														<h4>/</h4>
														<img
															alt=""
															src={dislikeFill}
														/>
													</div>
												</th>
											</tr>
										</thead>
										<tbody>
											{sub.questions.map(question => {
												return (
													<TableRow
														key={
															question.questionid
														}
														item={question}
													/>
												);
											})}
										</tbody>
									</table>
								</Wrapper>
							)}

							{sub.sections.length > 0 &&
								sub.sections.map(subSub => {
									return (
										<>
											<div className="subtitle">
												<h5 className="first-subtitle">
													{subSub.name}
												</h5>
												{subSub.questions.length >
													0 && (
													<h5 className="second-subtitle">
														Showing all sections,
														all answer types
													</h5>
												)}
											</div>
											{subSub.questions.length > 0 && (
												<Wrapper>
													<table className="table">
														<thead>
															<tr>
																<th>
																	Question
																</th>
																<th>Answer</th>
																<th className="title-row">
																	<div className="d-flex w-100 justify-content-end">
																		<img
																			alt=""
																			src={
																				likeFill
																			}
																		/>
																		<h4>
																			/
																		</h4>
																		<img
																			alt=""
																			src={
																				dislikeFill
																			}
																		/>
																	</div>
																</th>
															</tr>
														</thead>
														<tbody>
															{subSub.questions.map(
																question => {
																	return (
																		<TableRow
																			key={
																				question.questionid
																			}
																			item={
																				question
																			}
																		/>
																	);
																},
															)}
														</tbody>
													</table>
												</Wrapper>
											)}
										</>
									);
								})}
						</>
					);
				})}
		</React.Fragment>
	));
};

Table.propTypes = {
	data: PropTypes.object,
	subTitle: PropTypes.string,
};
