import React, { useEffect, useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import { AssessmentService } from 'services';
import { LoadingSpinner, EmptyState, assessEmptyState, Button } from 'componentsV2';
import { AssessmentsTable } from './components/AssessmentsTable';
import { checkPermission } from 'utilities/permissions';
import { StartNewAssessmentModal } from './components/StartNewAssessmentModal';

export const AssessSettings = createContext();

export const ListAssessments = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [assessments, setAssessments] = useState([]);
	const [years, setYears] = useState([]);
	const [showModal, setShowModal] = useState('');
	const [assessSettings, setAssessSettings] = useState(undefined);
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('site_assess_edit', permissions);

	const districtId = useSelector(state => state.route.districtId);
	const initAssessments = async () => {
		setLoading(true);

		// get initial table data
		const initAssessmentsData = await AssessmentService.initAssessments();
		setAssessments(initAssessmentsData.data.assessments);
		setYears(initAssessmentsData.data.years);

		// get assess settings
		const {
			data: { assessIsLocked, assessForceCompletion },
		} = await AssessmentService.getSettings(districtId);
		setAssessSettings({ assessIsLocked, assessForceCompletion });

		setLoading(false);
	};

	useEffect(() => {
		initAssessments();
	}, []);

	const handleCloseModal = () => setShowModal('');

	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && assessments.length > 0 && (
				<AssessSettings.Provider value={assessSettings}>
					<AssessmentsTable
						{...{
							assessments,
							years,
							history,
							initAssessments,
							editPermission,
						}}
					/>
				</AssessSettings.Provider>
			)}
			{!loading && assessments.length === 0 && (
				<>
					<EmptyState
						header="No assessments were added yet"
						description="Click on the new assessment button to add an assessment."
						icon={assessEmptyState}
					>
						{editPermission && (
							<Button
								icon="plus"
								text="New assessment"
								type="primaryDefault"
								size="lg"
								onClick={() => setShowModal('start_new_assessment')}
							/>
						)}
					</EmptyState>
					{showModal === 'start_new_assessment' && (
						<StartNewAssessmentModal history={history} onClose={handleCloseModal} />
					)}
				</>
			)}
		</>
	);
};
