import React from 'react';
import { EmptyState, Animated, noResult } from 'componentsV2';

export const NoIconFoundState = () => (
	<Animated>
		<EmptyState
			icon={noResult}
			header="No icons found"
			description="Your search didn’t match any icons. Please try again."
		/>
	</Animated>
);
