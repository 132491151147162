import React, { useContext, useEffect, useState } from 'react';
import { ActionsButton, Button, Tooltip } from 'componentsV2';
import { STATUS_PARTIALLY_REUNIFIED, STATUS_REUNIFICATION_COMPLETE } from '../../consts';
import { RapidAlarmsContext } from '../../../../../Providers';
import { RollCallAndReunificationContext } from '../../..';
import { GUARDIAN_UNDO_CHECKIN_MODAL } from '../../../Modals/consts';

const ActionListButton = ({ text, icon, onClick }) => (
	<Button
		key={text}
		text={text}
		icon={icon}
		size="md"
		type="ghostDefault"
		wrapperClass="displayBlock"
		className="actionButtonElement"
		onClick={onClick}
	/>
);

export const ReunificationGuardianActionsList = ({ row, alarmId }) => {
	const { onEditGuardianDetails } = useContext(RapidAlarmsContext);
	const editEnabled = row.personId == null && row.status != STATUS_REUNIFICATION_COMPLETE;
	const deleteEnabled =
		row.status != STATUS_REUNIFICATION_COMPLETE && row.status != STATUS_PARTIALLY_REUNIFIED;

	const { setModalToShow, setSelectedStudent } = useContext(RollCallAndReunificationContext);
	const undoCheckInHandler = guardian => {
		setSelectedStudent(guardian);
		setModalToShow(GUARDIAN_UNDO_CHECKIN_MODAL);
	};

	return (
		<>
			{editEnabled || deleteEnabled ? (
				<ActionsButton>
					{editEnabled && (
						<ActionListButton
							text="Edit"
							icon="edit"
							onClick={() => {
								onEditGuardianDetails(row, alarmId);
							}}
						/>
					)}
					{deleteEnabled && (
						<ActionListButton
							text="Undo check-in"
							icon="undo"
							onClick={() => undoCheckInHandler(row)}
						/>
					)}
				</ActionsButton>
			) : (
				<Tooltip
					position="left"
					tooltipContent="Guardians with status reunified can't be edited or deleted."
					theme="dark"
				>
					<ActionsButton disabled={true} />
				</Tooltip>
			)}
		</>
	);
};
