export const BASIC_INFO_SECTION = 'Name, icon & sound';
export const COUNTDOWN_SECTION = 'Countdown';
export const CONTACT_911_SECTION = 'Contact 911';
export const FLIPCHART_SECTION = 'Flipchart';
export const ROLLCALL_SECTION = 'Roll call';
export const PREVIEW_SECTION = 'Preview & finalize';
export const CANCEL_CREATION_MODAL = 'CancelCreateModal';
export const MISSING_REQUIRED_FIELDS_MODAL = 'MissingRequiredFieldsModal';
export const BROWSE_ICONS_MODAL = 'browseIconsModal';
export const FINALIZE_ALARM_MODAL = 'finalizeAlarmModal';
export const SAVE_AS_DISABLED_MODAL = 'saveAsDisabledModal';
export const LOADING_MODAL = 'loadingModal';
export const CANCEL_CHANGES_MODAL = 'cancelChangesModal';
export const ENABLE_DISABLE_ALARM_MODAL = 'enableDisableAlarmModal';
