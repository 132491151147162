import React , { useState, useRef, useCallback, useReducer, useEffect } from "react";
import CardView from "components/CardView";
import { ContactService } from 'services/ContactService';
import { generateAvatar } from "../../App/AvatarInitials";
import { LoadingSpinner } from 'components/LoadingSpinner';
import './css/GridExistingContactTable.scss';
import { Helper } from 'utilities/helper';
import RolesColumn from './RolesColumn';
import ContactsListActionsButtons from './ContactsListActionsButtons';

const GridExistingContactTable = ({
    listView,
    callListId,
    filterationContactData,
    dispatchFilterationContactData,
    selectedCallList,
    userEditPermission,
    userViewPermission,
    callListEditPermission,
    updateDataCallBack,
    lodinggrid,
    setLoadingGrid,
    contactGridList,
    pageNumber,
    endOfList
}) => {
  

    const handleScroll = useCallback(
      _.debounce(async () => {
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
  
        if (scrollTop + windowHeight >= documentHeight) {
          // User has reached the bottom of the page, load more results
          if(!endOfList.current)
          {
            dispatchFilterationContactData({ type: "page", payload: pageNumber.current });
            pageNumber.current += 1;
          }

        }
      }, 500), // Set the debounce delay to 500 milliseconds
      []
    );

    useEffect(() => {
      dispatchFilterationContactData({ type: "page", payload: pageNumber.current });
  }, []);


    useEffect(() => {
        if (!listView) {
          window.addEventListener("scroll", handleScroll);
          return () => {
            window.removeEventListener("scroll", handleScroll);
          };
        }
      }, [listView, handleScroll]);

    useEffect(async() => {  
      try {
        await updateDataCallBack();
      } catch (error) {
        setLoadingGrid(false);
      } finally {
        setLoadingGrid(false);
      }

    }
    , [filterationContactData]);


    return (
      <>
        {
          contactGridList.map((item) =>
            <>
              <div className="col-md-6 col-lg-4">
                <a>
                  <CardView 
                    item={item}
                    itemId = {item.id}
                    thumbnail = {item.img_link == "" ? generateAvatar(item.person.name.givenName.charAt(0) + item.person.name.familyName.charAt(0)):item.img_link}
                    imgClass="contact-card-view"
                    actionButton={
                      <ContactsListActionsButtons
                          row={item}
                          callListId={callListId}
                          updateDataCallBack={updateDataCallBack}
                          userEditPermission={userEditPermission}
                          userViewPermission={userViewPermission}
                          callListEditPermission={callListEditPermission}
                          filterationData={filterationContactData}
                      />

                    }
                  >
                    <div className="GridTableWrapper">
                      <p>
                        <span className="gridTitle">{(item.person.name.familyName ? item.person.name.familyName  : '')  + (item.person.name.familyName != " " && item.person.name.familyName != "" && item.person.name.givenName !=" " && item.person.name.givenName !=""   ? ', ' : '')
                        +(item.person.name.givenName ? item.person.name.givenName : '')} </span><span className="gridTitleLabel">{(item.accessType == 5) ? '(External)' : '(Internal)'}</span>
                      </p>
                      <div className="gridSubTitle">
                        <>
                        {
                        (item.person.name.title != null) ? 
                          ((item.person.name.title.trim()) ?  
                            <> <span className="inline">{item.person.name.title}  {(item.roles.length != 0) ? <> - <RolesColumn callList={selectedCallList}  row={item} /> </>: ''}</span></>
                          : 
                            (item.roles.length != 0) ? <> <RolesColumn callList={selectedCallList}  row={item} /> </>: <br/>
                          ) : ''
                        }
                        {
                          (item.person.name.title == null) ? item.roles.length != 0 ? <> <RolesColumn callList={selectedCallList}  row={item} /></>: <br/> : ''
                        }
                        </>
                      </div>
                      <p>
                        <span className="gridSubTitle">Mobile: </span><span className="gridMobile">{(item.person.phones[0]) ? Helper.formatDigitsToPhone(item.person.phones[0].phoneNumber): ''}</span>
                      </p>
                    </div>
                  </CardView>
                </a>
              </div>
            </>
          )
          
        }
      <div> {lodinggrid && <LoadingSpinner />}</div>
      </>
    );

  }
export default GridExistingContactTable;