import React from 'react';
import { EmptyState, Button, fonts, Animated } from 'componentsV2';

export const NoTextAddedState = ({
	editPermission,
	floorplanArchived,
	addNewTextHandler,
	addBtnDisabled,
	isEditMode,
}) => (
	<Animated>
		<EmptyState
			className="mt-3"
			icon={fonts}
			header="No text has been added yet."
			description={
				editPermission && !floorplanArchived ? 'Start adding text over the map' : ''
			}
		>
			{editPermission && !floorplanArchived && isEditMode && (
				<Button
					text="Add text"
					size="md"
					icon="plus"
					type="primaryDefault"
					intent="default"
					onClick={addNewTextHandler}
					disabled={addBtnDisabled}
					wrapperClass="map-add-btn"
				/>
			)}
		</EmptyState>
	</Animated>
);
