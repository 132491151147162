/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { CardModal } from './CardModal';
import { Wysiwyg } from 'components/Wysiwyg';

const Wrapper = styled.div`
	width: ${props => props.width || 'initial'};

	.wysiwyg {
		margin: 16px 0;
		min-width: 600px;
	}
`;

export const EditSchedulingInstructionsModal = ({
	setModalToShow,
	onActionClick,
	header,
}) => {
	const [instructions, setInstructions] = useState(null);

	const copyScheduleInstructions = () => Promise.resolve(5);

	const saveSchedulingInstructions = () => {
		// todo: handle the parameters to the function
		Promise.resolve(10);
	};

	useEffect(() => {
		setInstructions('<p>Hello, World!</p>');
	}, []);

	return (
		<CardModal
			isVisible
			setIsVisible={setModalToShow}
			title="Edit scheduling instructions"
			actionTitle="Save"
			onActionClick={() =>
				saveSchedulingInstructions().then(onActionClick)
			}
		>
			<Wrapper>
				<Wysiwyg
					header={header}
					actionHandler={{
						actionTitle: 'Copy from last year',
						onClick: copyScheduleInstructions,
						modalTitle: 'Copy instructions from last year',
						modalBody:
							'You are about to overwrite the instructions you have in your current year with the instructions from last year. Do you still wish to proceed?',
						successLabel: 'Copied from last year',
						errorLabel: 'There are no instructions from last year',
					}}
					initialValue={instructions}
					onDebounce={saveSchedulingInstructions}
				/>
			</Wrapper>
		</CardModal>
	);
};

export const EditPostingInstructionsModal = ({
	setModalToShow,
	onActionClick,
	header,
}) => {
	const [instructions, setInstructions] = useState(null);

	const copyPostingInstructions = () => Promise.reject(new Error(5));

	const savePostingInstructions = () => {
		// todo: handle the parameters to the function
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: 'Could not save at this time. Please try again.',
		});
		return Promise.reject(new Error(10));
	};

	useEffect(() => {
		setInstructions('<p>Hello, World 2!</p>');
	}, []);

	return (
		<CardModal
			isVisible
			setIsVisible={setModalToShow}
			title="Edit posting instructions"
			actionTitle="Save"
			onActionClick={() => savePostingInstructions().then(onActionClick)}
		>
			<Wrapper>
				<Wysiwyg
					header={header}
					actionHandler={{
						actionTitle: 'Copy from last year',
						onClick: copyPostingInstructions,
						modalTitle: 'Copy instructions from last year',
						modalBody:
							'You are about to overwrite the instructions you have in your current year with the instructions from last year. Do you still wish to proceed?',
						successLabel: 'Copied from last year',
						errorLabel: 'There are no instructions from last year',
					}}
					initialValue={instructions}
					onDebounce={savePostingInstructions}
				/>
			</Wrapper>
		</CardModal>
	);
};

EditSchedulingInstructionsModal.propTypes = {
	setModalToShow: PropTypes.func,
	onActionClick: PropTypes.func,
	header: PropTypes.string,
};

EditPostingInstructionsModal.propTypes = {
	setModalToShow: PropTypes.func,
	onActionClick: PropTypes.func,
	header: PropTypes.string,
};
