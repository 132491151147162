import { Icon } from '@blueprintjs/core';
import React from 'react';
import { GEOMETRY_TYPE_ICON, MAP_LEGEND } from '../../consts';

export const PrintMapFooterItem = ({
    type,
    item,
}) => {
  return (
    <div className="map-footer-section-item">

        {type === GEOMETRY_TYPE_ICON && (
          <img className="iconImage" src={item.iconURL} alt={item.title} />
        )}

        {type === MAP_LEGEND && (
          <Icon
            icon="full-circle"
            className="icon"
            color={`#${item.hex}`}
            size={18}
          />
        )}
        
        <div className="map-footer-item-label">
            {type === GEOMETRY_TYPE_ICON && <>{item.title}</>}
            {type === MAP_LEGEND && <>{item.description}</>}
        </div>
    </div>
  )
}
