import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { FormLabel } from 'components/Forms/shared.js';
import { DateTimePicker } from 'components/DateTimePicker';
import { Dropdown } from 'components/Dropdown';
import { DrillsService } from 'services/DrillsService';
import { DrillTypesService } from 'services/DrillTypesService';
import { CardModal } from 'components/Modals/CardModal';
import { SitesDropdown } from 'components/SitesDropdown';
import { SchoolYearDropdown } from 'components/SchoolYearDropdown';
import { addMonthsToDate } from 'utilities/dates';

const CardModalStyled = styled(CardModal)`
	.modal-card {
		.modal-input-label {
			color: #10161a;
			font-size: 16px;
			margin-top: 16px;
			line-height: 22px;
			font-style: normal;
			font-weight: normal;
			font-family: Nunito Sans;
		}

		.modal-input {
			width: 350px;
			height: 42px;
			font-size: 16px;
			line-height: 22px;
			font-style: normal;
			padding-left: 10px;
			border-radius: 3px;
			font-weight: normal;
			margin-bottom: 16px;
			font-family: Nunito Sans;
			border: 1px solid #d8e1e8;
		}

		.modal-check-box-wrapper {
			margin-bottom: 15px;
			div {
				margin-right: 40px;

				label {
					text-transform: capitalize;
					margin: 0 0 3.5px 5px;
				}
			}
		}
	}
`;

const ScheduleAndLogDrillModal = ({
	isVisible,
	setIsVisible,
	sites,
	onSuccessHandler,
	onErrorHandler,
	position
}) => {
	const [selectedDrillType, setSelectedDrillType] = useState(null);
	const [selectedSite, setSelectedSite] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [drillTypes, setDrillTypes] = useState(null);

	useEffect(() => {
		if (selectedYear) {
			// Calling drill types service again because the parent has enable the "All drill types" option
			DrillTypesService.getAll(false, selectedYear.value).then(
				serviceDrillTypes => setDrillTypes(serviceDrillTypes),
			);
		}
	}, [selectedYear]);

	return (
		<CardModalStyled
			isVisible={isVisible}
			setIsVisible={setIsVisible}
			title="Schedule and log a drill"
			actionTitle="Schedule and log"
			onActionClick={() => {
				if (
					selectedSite &&
					selectedYear &&
					selectedDrillType &&
					selectedDate
				) {
					DrillsService.scheduleAndLogADrill(
						selectedSite.value,
						selectedYear.value,
						selectedDrillType.uuid,
						selectedDate,
					)
						.then(() => {
							Swal.fire({
								title: 'Success!',
								text: 'A new drill was scheduled and logged',
								icon: 'success',
								toast: true,
								position: 'top',
								timer: 3000,
								showConfirmButton: false,
							});
							setIsVisible(false);
							if (onSuccessHandler) onSuccessHandler();
						})
						.catch(error => {
							if (onErrorHandler) onErrorHandler(error);
						});
				} else {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Please select all the fields and try again.',
					});
				}
			}}
			style={{
				top: position.top,
				position: 'inherit',
			}}
		>
			{/* building/site */}
			<SitesDropdown
				selectedSite={selectedSite}
				options={sites || []}
				onChange={site => setSelectedSite(site)}
			/>
			{/* name field */}
			<SchoolYearDropdown
				selectedSchoolYear={selectedYear}
				onChange={year => setSelectedYear(year)}
			/>
			{/* name field */}
			<FormLabel>Drill Type</FormLabel>
			<Dropdown
				value={selectedDrillType}
				options={drillTypes || []}
				onChange={newValue => setSelectedDrillType(newValue)}
			/>

			{/* type dropdown */}
			<FormLabel>
				What date and time was this drill scheduled for?
			</FormLabel>
			<DateTimePicker
				initialValue={null}
				onDateChangeHandler={date => setSelectedDate(date)}
				timePrecision="minute"
				datePickerProps={{
					maxDate: addMonthsToDate(),
				}}
			/>
		</CardModalStyled>
	);
};

ScheduleAndLogDrillModal.propTypes = {
	isVisible: PropTypes.bool,
	setIsVisible: PropTypes.func.isRequired,
	sites: PropTypes.array.isRequired,
	onSuccessHandler: PropTypes.func,
	onErrorHandler: PropTypes.func,
};

export { ScheduleAndLogDrillModal };
