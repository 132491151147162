import React from "react";
import { Callout, Spinner } from '@blueprintjs/core';

const Step2 = ({validationResData, loading}) => {
    return (
        <div style ={{height:"340px"}} className="bulk-user-container bootstrap-table-container">
        {loading && <Spinner intent ='primary'/>}
            {!loading && (
            <>
                <div >
                    {(
                        (validationResData != undefined && validationResData.invalid_headers != undefined?  
                            <Callout className="attachment-note" intent="danger">
                                { <strong> {validationResData['invalid_headers']} </strong>}
                                { validationResData.msg }
                            </Callout> 
                        : '') 
                    )}
                    {(
                        (validationResData != undefined && validationResData.valid_contacts != undefined && validationResData.valid_contacts != 0 ?  
                            <Callout className="attachment-note" intent="success">
                                { validationResData.valid_contacts_count} contacts can be uploaded successfully 
                            </Callout> 
                        : '') 
                    )}
                </div>
                <br/>
                <div className = "bulk-warning-container custom-table custom-scroll">
                {(
                    (validationResData != undefined && validationResData['invalid_contacts_count'] != undefined && validationResData['invalid_contacts_count'] != 0) ? 
                        <Callout intent="warning">
                            <div >
                                <p><b>{validationResData['invalid_contacts_count']}</b> contacts cannot be uploaded due to the below reasons</p>
                                <ul>
                                    {
                                        validationResData['validation_errors'].map((row) => (
                                            <li><strong> {row.key} </strong> {row.msg} <strong><span className ="wrap-content">{"{"}{row.rows}{"}"}</span></strong></li>
                                        ))
                                    } 
                                
                                </ul>
                            </div>
                        </Callout> : ''
                    )}
                </div>
            </>
            )}
        </div>
    );
};
export default Step2;