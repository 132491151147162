import { useEffect, useState } from 'react';
import { RapidAlarmService } from 'services';

export const useStudentsGrades = () => {
	const [loading, setLoading] = useState(true);
	const [gradesList, setGradesList] = useState([]);

	useEffect(() => {
		const getGradesList = () => {
			setLoading(true);
			RapidAlarmService.getStudentsGrades()
				.then(response => {
					if (response.statusCode === 200) {
						const gradesListValues = response.data.map(gradeItem => ({
							label: gradeItem.grade,
							value: gradeItem.grade,
						}));
						setGradesList(gradesListValues);
					}
				})
				.catch(err => {
					//
				})
				.finally(() => {
					setLoading(false);
				});
		};

		getGradesList();
	}, []);

	return {
		loading,
		gradesList,
	};
};
