import React, { useState } from 'react';
import { Tag } from '@blueprintjs/core';
import { Badge } from '../../../../componentsV2';
import '../../../App/Users/AccountManager/account-manager.scss';

export const MoreSites = ({ id, visibleOptions, items, showText = null }) => {
	const [showSitesCard, setShowSitesCard] = useState(false);

	document.addEventListener('click', function(e) {
		if (!document.getElementById('tag-' + id)?.contains(e.target)) {
			setShowSitesCard(false);
		}
	});

	const itemsToShow = (visibleOptions ? items?.slice(0, visibleOptions) : items) || [];
	const remainingCount = items?.length - visibleOptions;
	const remainingItems = items?.slice(visibleOptions) || [];

	return (
		<div className="d-flex MoreCard__Container">
			{!!showText ? (
				showText
			) : (
				<>
					<div className="more-sites-badge">
						{itemsToShow.map(item => (
							<Badge
								type="Gray"
								size="lg"
								text={item}
								hasDotIcon={false}
								hasCloseIcon={false}
							/>
						))}
					</div>
					{remainingCount > 0 && (
						<div className="position-relative">
							<Tag
								key={id}
								minimal={true}
								onClick={() => setShowSitesCard(!showSitesCard)}
								id={'tag-' + id}
								style={{
									border: '1px solid #D9DCDD',
								}}
								intent="None"
								className="bp3-tag custom-class"
								round={true}
							>
								{' '}
								+{remainingCount}
							</Tag>
							{showSitesCard && (
								<div className="more-sites-role-card">
									{remainingItems.map(site => {
										return (
											<Badge
												type="Gray"
												size="lg"
												text={site}
												hasDotIcon={false}
												hasCloseIcon={false}
											/>
										);
									})}
								</div>
							)}
						</div>
					)}
				</>
			)}
		</div>
	);
};
