/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { Table } from 'components/Table';
import { Dropdown } from 'components/Dropdown';
import { StatusPilsner } from 'components/StatusPilsner';
import { Button } from 'components/Button';
import { MoreActionsButton } from 'components/MoreActionsButton';
import { SCHOOL_YEARS } from 'utilities/drills/mockData';
import { getAvailableActionsForDrillStatus } from 'utilities/drills/utils';
import { DrillTypesService } from 'services/DrillTypesService';
import { BuildingsService } from 'services/BuildingsService';
import { DrillsService } from 'services/DrillsService';
import { formatDate } from 'utilities/dates';
import { ScheduleAndLogDrillModal } from 'components/Modals/ScheduleAndLogDrillModal';
import { SitesDropdown } from 'components/SitesDropdown';
import { SchoolYearDropdown } from 'components/SchoolYearDropdown';
import { DrillDetailsModal } from 'components/Modals/DrillDetailsModal';
import { ViewDrillDetails } from 'components/Modals/ViewDrillDetails';
import { DrillLogService } from 'services/DrillLogService';
import { checkPermission } from 'utilities/permissions';

const Wrapper = styled.div`
	padding: 0px 0px;
	.dropdown-filters {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.dropdown {
			margin-right: 24px;
			margin-bottom: 16px;
			width: calc(33% - 24px);
		}
	}

	.more-actions-btn img {
		cursor: pointer;
		padding: 5px 10px;
	}
`;

const TableDataStatus = styled.td`
	display: flex;
	flex-direction: column;
`;

const LogDrills = ({ permissions }) => {
	const [showScheduleAndLogModal, setShowScheduleAndLogModal] = useState(
		false,
	);
	const [modalPosition, setModalPosition] = useState({ top: '50%' });

	const handleButtonClick = (e) => {
		const { clientY } = e;
		setModalPosition({ top: clientY });
		setShowScheduleAndLogModal(true);
	};

	const [showDrillDetailsModal, setShowDrillDetailsModal] = useState(false);
	const [showViewDetailsModal, setShowViewDetailsModal] = useState(false);
	const [drillTypes, setDrillTypes] = useState(null);
	const [sites, setSites] = useState(null);
	const [drills, setDrills] = useState(null);

	const [selectedSite, setSelectedSite] = useState(null);
	const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
	const [selectedDrillType, setSelectedDrillType] = useState(null);
	const [filteredData, setFilteredData] = useState(null);
	const [selectedDrill, setSelectedDrill] = useState(null);
	const [userPermissions, setUserPermissions] = useState({});

	const editHandler = drill => () => {
		setShowDrillDetailsModal(true);
		setSelectedDrill(drill);
	};

	const viewHandler = drill => () => {
		setShowViewDetailsModal(true);
		setSelectedDrill(drill);
	};

	const unpostHandler = drill => () => {
		DrillLogService.unpostDrillLog(drill?.scheduleId)
			.then(() => {
				Swal.fire({
					title: 'Success!',
					text: 'Drill log was unposted',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
				getDrills();
			})
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Could not unpost this drill log. Please try again.',
				}),
			);
	};

	const postHandler = drill => () => {
		const scheduledDrillDate = drill?.scheduledDrillDate?.date || null;
		const drillLogDate = drill?.log?.drillLoggedDate?.date || drill?.log?.logStartedDate?.date;
		DrillLogService.postDrillLog(drill?.scheduleId, scheduledDrillDate, drillLogDate, null)
			.then(() => {
				Swal.fire({
					title: 'Success!',
					text: 'Drill log was posted',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
				getDrills();
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Could not post this drill log. Please try again.',
				})
			});
	}

	const printHandler = drill => () => {
		setShowViewDetailsModal(true);
		setSelectedDrill(drill);
		setTimeout(() => {
			window.print();
		}, 2000);
	};

	const removeHandler = drill => () => {
		DrillLogService.removeDrillLog(drill.id)
			.then(() => {
				Swal.fire({
					title: 'Success!',
					text: 'Drill log was removed',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
				getDrills();
			})
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Could not delete this drill. Please try again.',
				}),
			);
	};

	const renderItem = item => (
		<>
			<TableDataStatus>
				{item.title}
				<StatusPilsner status={item.status} />
			</TableDataStatus>
			<td>{item.type}</td>
			<td>{formatDate(item.date)}</td>
			<td className="more-actions-btn">
				<MoreActionsButton
					actions={getAvailableActionsForDrillStatus(
						item?.status?.toLowerCase(),
						editHandler(item),
						viewHandler(item),
						unpostHandler(item),
						postHandler(item),
						printHandler(item),
						removeHandler(item),
						userPermissions,
					)}
				/>
			</td>
		</>
	);

	useEffect(() => {
		if (selectedDrillType !== null) {
			setFilteredData(
				drills && drills.length
					? drills.filter(
							drill => drill.typeId === selectedDrillType.value,
					  )
					: null,
			);
		}
		if (
			!selectedDrillType ||
			selectedDrillType?.value === 'All drill types'
		) {
			setFilteredData(drills);
		}
	}, [selectedDrillType, drills]);

	useEffect(() => {
		BuildingsService.getAll().then(serviceBuildings =>
			setSites(serviceBuildings),
		);
	}, []);

	useEffect(() => {
		if (selectedSchoolYear) {
			DrillTypesService.getAll(true, selectedSchoolYear.value).then(
				serviceDrillTypes => setDrillTypes(serviceDrillTypes),
			);
		}
	}, [selectedSchoolYear]);

	const getDrills = () => {
		if (selectedSite && selectedSchoolYear) {
			DrillsService.getWithParams(
				selectedSite.value,
				selectedSchoolYear.value,
				selectedDrillType ? selectedDrillType.uuid : null,
			).then(serviceDrills => {
				setDrills(serviceDrills);
			});
		}
	};

	useEffect(() => {
		getDrills();
	}, [selectedSite, selectedSchoolYear, selectedDrillType]);

	const selectSite = site => {
		setSelectedSite(site);

		const availablePermissions = {
			canPrint: 1,
		};

		if (checkPermission('drilllogs_log', permissions, site.id)) {
			availablePermissions.drilllogs_log = 1;
		}

		if (checkPermission('drilllogs_post', permissions, site.id)) {
			availablePermissions.drilllogs_post = 1;
		}

		if (checkPermission('drilllogs_unpost', permissions, site.id)) {
			availablePermissions.drilllogs_unpost = 1;
		}

		if (checkPermission('drilllogs_delete', permissions, site.id)) {
			availablePermissions.drilllogs_delete = 1;
		}

		setUserPermissions(availablePermissions);
	};

	return (
		<Wrapper>
			<ScheduleAndLogDrillModal
				isVisible={showScheduleAndLogModal}
				setIsVisible={setShowScheduleAndLogModal}
				sites={sites || []}
				years={SCHOOL_YEARS}
				onSuccessHandler={() => getDrills()}
				position={modalPosition}
			/>
			{showDrillDetailsModal && selectedDrill && (
				<DrillDetailsModal
					setModalToShow={setShowDrillDetailsModal}
					selectedDrill={selectedDrill}
					onDeleteDrill={removeHandler(selectedDrill)}
					onPostLogSuccess={() => getDrills()}
				/>
			)}

			{showViewDetailsModal && selectedDrill && (
				<ViewDrillDetails
					setModalToShow={setShowViewDetailsModal}
					selectedDrill={selectedDrill}
					// eslint-disable-next-line no-console
					onPrintDrillSuccess={() => console.log('Print')}
					onUnPostDrillSuccess={() => getDrills()}
				/>
			)}

			{/* site, school year, drill type filters */}
			<div className="dropdown-filters">
				{/* Site */}
				<SitesDropdown
					selectedSite={selectedSite}
					onChange={selectSite}
				/>
				{/* School year */}
				<SchoolYearDropdown
					selectedSchoolYear={selectedSchoolYear}
					onChange={item => setSelectedSchoolYear(item)}
				/>
				{/* Drill type */}
				<Dropdown
					label="Drill type"
					placeholder="Select a Drill type"
					value={selectedDrillType}
					options={drillTypes}
					onChange={item => setSelectedDrillType(item)}
				/>
			</div>

			{checkPermission(
				'drilllogs_schedule',
				permissions,
				selectedSite?.id,
			) &&
				checkPermission(
					'drilllogs_log',
					permissions,
					selectedSite?.id,
				) && (
					<div className="d-flex justify-content-between align-items-center">
						<h4>Log a drill</h4>
						<Button
							onClick={handleButtonClick}
							text="Schedule and log one drill"
							intent="tertiary"
							icon="calendar"
						/>
					</div>
				)}

			{/* drills table */}
			<Table
				headers={['Drill', 'Type', 'Date', 'Actions']}
				renderItem={renderItem}
				hidePagination
				data={filteredData}
			/>
		</Wrapper>
	);
};

LogDrills.propTypes = {
	permissions: PropTypes.object,
};

export { LogDrills };
