import React, { useState, useEffect, useContext, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import { ToasterContext } from 'pages/App';
import { UsersService, UserService } from 'services';
import { SORTING_DIR } from 'utilities/constants';
import { checkPermission } from 'utilities/permissions';
import { ConfirmationModal, FeaturedIcon, LoadingModal } from 'componentsV2';
import { NewUserResponderModal } from './components/NewUserResponderModal';
import UserManagement from '..';
import { FirstResponderUsersTable } from './components/FirstResponderUsersTable';
import './index.scss';

/** Gets and displays users for when a first responder is logged into 5304 */
const FirstResponderUsers = () => {
	const [users, setUsers] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [modalToShow, setModalToShow] = useState(false);
	const [modalInputProps, setModalInputProps] = useState({});
	const [loading, setLoading] = useState(true);
	const [sortDirection, setSortDirection] = useState(SORTING_DIR.ASC);
	const toaster = useContext(ToasterContext);
	const permissions = useSelector(state => state.route.permissions);
	const userEditPermission = checkPermission('user_edit', permissions);
	const buildingId = JSON.parse(localStorage.getItem('currentDistrict')).id;

	const submitNewUser = userData => {
		UserService.addNewUser(userData)
			.then(() => {
				toaster(
					`You have successfully added "${userData.first_name} ${userData.last_name}"`,
					Intent.SUCCESS,
				);
				setModalToShow(false);
				fetchUsers();
			})
			.catch(() => {
				toaster('Something went wrong, user has not been created.', Intent.DANGER);
				setModalToShow(false);
			});
	};

	const editUser = (userId, userData, name, isUpdatePassword = false) => {
		UserService.updateUser(userId, userData)
			.then(() => {
				toaster(
					`You have successfully updated ${name.firstName} ${name.lastName}.`,
					Intent.SUCCESS,
				);

				if (isUpdatePassword) {
					editPassword(userId, {
						password: userData.password,
						passwordConfirmation: userData.confirmPassword,
					});
				}

				setModalToShow(false);
				fetchUsers();
			})
			.catch(() => {
				toaster('Something went wrong, user has not been updated.', Intent.DANGER);
				setModalToShow(false);
			});
	};

	const editPassword = (userId, passwordData, name = {}, isOnlyUpdate = false) => {
		UserService.updatePassword(userId, passwordData)
			.then(() => {
				if (isOnlyUpdate) {
					toaster(
						`You have successfully updated ${name.firstName} ${name.lastName}.`,
						Intent.SUCCESS,
					);
				} else {
					console.log('Update successful.');
				}
				setModalToShow(false);
				fetchUsers();
			})
			.catch(() => {
				if (isOnlyUpdate) {
					toaster('Something went wrong, user has not been updated.', Intent.DANGER);
				} else {
					console.log('Update failed.');
				}
				setModalToShow(false);
			});
	};

	const fetchUsers = async () => {
		await UsersService.search({
			combineSearch: filterationData.name,
			sites: [buildingId],
			page: currentPage,
			perPage: rowsPerPage,
			sortKey: 'name',
			sortDir: sortDirection,
			includeDisabled: true,
		})
			.then(response => {
				setUsers(response.data);
				setTotalRows(response._metadata.totalRecords);
			})
			.catch(() => console.log('error'));
	};

	const handleShowModal = (modalToShow, modalInputProps = null) => {
		setModalToShow(modalToShow);
		if (modalInputProps) {
			setModalInputProps(modalInputProps);
		}
	};

	const onCloseModal = () => {
		setLoading(false);
		setModalToShow(false);
	};

	const toggleUserStatus = () => {
		UserService.toggleStatus(modalInputProps.userId).then(() => {
			if (modalInputProps.icon === 'disable') {
				toaster(
					'You have successfully disabled this user.',
					Intent.NONE,
					<FeaturedIcon icon="disable" type="Gray" />,
				);
			} else {
				toaster('You have successfully enabled this user.', Intent.SUCCESS);
			}
			setModalToShow(false);
			setLoading(true);
			fetchUsers().finally(() => setLoading(false));
		});
	};

	const filterStateReducer = (filterationData, action) => ({
		...filterationData,
		name: action.payload,
	});

	const [filterationData, dispatchFilter] = useReducer(filterStateReducer, {});

	const filterAppliers = {
		name: (users, filterValue) =>
			users.filter(user =>
				user.person.fullName.toLowerCase().includes(filterValue.toLowerCase()),
			),
	};

	const applyFilters = (users, filterationData) =>
		Object.entries(filterationData).reduce((filteredUsers, [filterName, filterValue]) => {
			const applier = filterAppliers[filterName];
			return applier(filteredUsers, filterValue);
		}, users);

	useEffect(() => {
		setLoading(true);
		fetchUsers().finally(() => setLoading(false));
	}, [currentPage, rowsPerPage, filterationData, sortDirection]);

	useEffect(() => {
		const filteredUsers = applyFilters(users, filterationData);
		setUsers(filteredUsers);
	}, [filterationData]);

	return (
		<UserManagement>
			{modalToShow === 'NewUserResponderModal' && (
				<NewUserResponderModal
					buildingId={buildingId}
					isOpen
					closeModal={onCloseModal}
					addUser={submitNewUser}
					isEdit={modalInputProps.isEdit}
					editUser={editUser}
					editPassword={editPassword}
					user={modalInputProps.user}
				/>
			)}
			{modalToShow === 'EnableDisableUser' && (
				<ConfirmationModal
					title={modalInputProps.title}
					subtitle={modalInputProps.subtitle}
					isOpen
					confirmBtn={{
						title: modalInputProps.confirmTitle,
						onClick: toggleUserStatus,
					}}
					cancelBtn={{
						title: 'No, go back',
						onClick: onCloseModal,
					}}
					closeModal={onCloseModal}
					icon={{
						icon: modalInputProps.icon,
						iconColor: modalInputProps.iconColor,
					}}
				/>
			)}
			<FirstResponderUsersTable
				users={users}
				dispatchFilter={dispatchFilter}
				userEditPermission={userEditPermission}
				openModal={handleShowModal}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
				totalRows={totalRows}
				setSortDirection={setSortDirection}
				loading={loading}
			/>
		</UserManagement>
	);
};

export default FirstResponderUsers;
