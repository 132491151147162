import React, { useState, useEffect, Suspense } from 'react';
import { TemplateService } from 'services';
import { LoadingSpinner } from 'componentsV2';
import { EventLogEmptyState, EventLogItem } from './';
import { EventLogDiffModal } from './EventLogDiffModal';
import './css/EventLog.scss';

export const EventLog = ({
    templateId
}) => {

    const LOGS_PER_PAGE = 10;
    const [loading, setLoading] = useState(true);
    const [loadingMoreContent, setLoadingMoreContent] = useState(false);
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(1);
    const [totalLogs, setTotalLogs] = useState(0);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [itemDetails, setItemDetails] = useState(null);

    const getEventLogs = (first_load) => {
        if (first_load) {
            setLoading(true);
        } else {
            setLoadingMoreContent(true);
        }
        TemplateService.getEventLogs({
            templateId,
            page,
            perPage: LOGS_PER_PAGE,
        })
            .then((response) => {
                if (response.statusCode === 200) {
                    if (response.data) {
                        setPage(response.data.metadata.nextPage);
                        setTotalLogs(response.data.metadata.totalRecords);
                        if (response.data.records.length > 0) {
                            response.data.records.forEach(log => {
                                setLogs(logs => [...logs, log]);
                            });
                        }
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching Event logs:', error);
            })
            .finally(() => {
                if (first_load) {
                    setLoading(false);
                } else {
                    setLoadingMoreContent(false);
                }
            });
    }

    useEffect(() => {
        getEventLogs(true);
    }, []);

    const handleScroll = (e) => {
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = e.target;

        if (scrollTop + clientHeight + 20 > scrollHeight && !loadingMoreContent && logs.length < totalLogs) {
            getEventLogs();
            setLoadingMoreContent(true);
        }
    }

    const handleExpandDetails = (item) => {
        setItemDetails(item);
        setShowDetailsModal(true);
    }

    return (
        <>
            {logs.length > 0 && !loading && (
                <>
                    <div className='event-log-wrapper'>
                        <div id='event-log-scroll'
                            className='event-log-inner'
                            onScroll={handleScroll}
                        >
                            <div className="section-header">
                                <div className="header-title">
                                    Update history
                                </div>
                            </div>
                            <div className="section-content">
                                {logs.map(item => (
                                    <Suspense key={item.id} fallback={<></>}>
                                        <EventLogItem
                                            key={`item-${item.id}`}
                                            item={item}
                                            handleExpandDetails={handleExpandDetails}
                                        />
                                    </Suspense>
                                ))}
                                {loadingMoreContent && <LoadingSpinner size="small" />}
                            </div>
                        </div>
                    </div>

                    <EventLogDiffModal
                        isOpen={showDetailsModal}
                        onClose={setShowDetailsModal}
                        item={itemDetails}
                    />
                </>
            )}
            {loading && <LoadingSpinner />}
            {logs.length === 0 && !loading && <EventLogEmptyState />}
        </>
    )
}
