import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionsButton, Button } from 'componentsV2';
import { AddUpdateTabModal } from './AddUpdateTabModal';

export const TabsActionsList = ({
	flipchart,
	history,
	showCopyModal,
	handleSingleTabDelete,
	tabFullData,
	flipchartId,
	buildingId,
	onSubmitFinished,
}) => {
	const [modalUpdateTabIsOpen, setModalUpdateTabIsOpen] = useState(false);

	return (
		<>
			<ActionsButton>
				<Button
					large
					text="Edit"
					icon="edit"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => {
						setModalUpdateTabIsOpen(true);
					}}
				/>
				<Button
					large
					text="Delete"
					icon="trash"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => handleSingleTabDelete(flipchart.id)}
				/>
			</ActionsButton>

			{modalUpdateTabIsOpen && (
				<AddUpdateTabModal
					tab={tabFullData}
					tabFlipchartId={flipchartId}
					tabBuildingId={buildingId}
					onSubmitFinished={onSubmitFinished}
					modalIsOpen={modalUpdateTabIsOpen}
					setModalIsOpen={setModalUpdateTabIsOpen}
				/>
			)}
		</>
	);
};

TabsActionsList.propTypes = {
	flipchart: PropTypes.object,
	history: PropTypes.object,
	showCopyModal: PropTypes.func,
	handleSingleTabDelete: PropTypes.func,
	tabFullData: PropTypes.object,
	flipchartId: PropTypes.number,
	buildingId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
	onSubmitFinished: PropTypes.func,
};
