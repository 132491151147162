import React from 'react';
import { AlertModal, Button } from 'componentsV2';

export const ArchiveMapModal = ({
	loading,
	title,
	subtitle,
	confirmBtnTitle,
	onConfirmAction,
	closeModal,
}) => (
	<AlertModal
		isOpen={true}
		loading={loading}
		size="medium"
		title={title}
		subtitle={subtitle}
		customClassName="map-container-modals"
		showCloseButton={false}
		alertConfirmButton={
			<Button
				text={confirmBtnTitle}
				type="primaryDefault"
				size="md"
				onClick={onConfirmAction}
			/>
		}
		alertCancelButton={
			<Button text="No, go back" type="tertiary" size="md" onClick={closeModal} />
		}
	/>
);
