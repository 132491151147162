import React from 'react';
import { Helper } from 'utilities/helper';
import { PhoneWithExtension } from 'utilities/PhoneWithExtension';

export const CallListTab = ({ row, index }) => {
	return (
		<div key={index} className="row callListContainer">
			<div className="col-md-12">
				{row.callList?.contacts?.length == 0 ? (
					<p>No contacts have been added yet</p>
				) : (
					row.callList?.contacts?.map((rowData, key) => (
						<>
							<p key={key}>
								<span className="callListTitle">
									{(rowData.person.name.familyName
										? rowData.person.name.familyName
										: '') +
										(rowData.person.name.familyName !=
											' ' &&
										rowData.person.name.familyName != '' &&
										rowData.person.name.givenName != ' ' &&
										rowData.person.name.givenName != ''
											? ', '
											: '') +
										(rowData.person.name.givenName
											? rowData.person.name.givenName
											: '')}{' '}
								</span>
								{rowData.person.name.title != '' &&
								rowData.person.name.title != ' ' ? (
									<>
										{' '}
										({' '}
										<span className="callListSubTitle">
											{' '}
											{rowData.person.name.title}{' '}
										</span>{' '}
										)
									</>
								) : (
									''
								)}{' '}
								<br />
								<span>
									{rowData.person.phones.map(
										(phone, phoneKey) => (
											<>
												<div
													key={phoneKey}
													className="phoneWapper"
												>
													<div className="phoneLabel callListLabel">
														{phone.type == 'mobile'
															? 'MOBILE: '
															: phone.type ==
															  'work'
															? 'WORK: '
															: phone.type ==
															  'home'
															? 'HOME: '
															: ''}
													</div>
													<div className="phoneNo">
														<a
															href={
																'tel:' +
																phone.phoneNumber
															}
														>
															<PhoneWithExtension
																phone={Helper.formatDigitsToPhone(
																	phone.phoneNumber
																		? phone.phoneNumber
																		: '',
																)}
																extension={
																	phone.extension
																		? phone.extension
																		: ''
																}
															/>
														</a>
													</div>
												</div>
												<br />
											</>
										),
									)}
								</span>
								{rowData.person.email ? (
									<>
										<span className="callListLabel">
											{' '}
											EMAIL:{' '}
										</span>
										<a
											href={
												'mailto:' +
												rowData.person.email
													.emailAddress
											}
										>
											{' '}
											{rowData.person.email.emailAddress
												? rowData.person.email
														.emailAddress
												: ''}{' '}
										</a>{' '}
									</>
								) : (
									''
								)}
							</p>
							<br />
						</>
					))
				)}
			</div>
		</div>
	);
};
