export const makeDrillTypes = (items, includeAll = true) => {
	const seen = {};
	const newItems = [];
	for (let i = 0; i < items.length; i += 1) {
		const { drillType } = items[i];

		if (!seen[drillType.uuid]) {
			seen[(drillType?.uuid)] = true;
			newItems.push({
				label: drillType.name,
				value: drillType.uuid,
			});
		}
	}

	return [
		...(includeAll && [
			{
				label: 'All drill types',
				value: 'all',
			},
		]),
		...newItems.sort((a, b) => {
			if (a.label < b.label) {
				return -1;
			}

			if (a.label > b.label) {
				return 1;
			}

			return 0;
		}),
	];
};
