import React, { useEffect, useState } from 'react';
import { HttpService } from 'networking/HttpService';
import { BootstrapedTable, LoadingSpinner } from 'componentsV2';
import './styles.scss';
import { SearchBar } from 'components/SearchBar';

import { columns } from './utilities';

export const Statistics = () => {
	const [statsData, setStatsData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [filteredStatsData, setFilteredStatsData] = useState([]);

	useEffect(() => {
		getBuildingStats();
	}, []);

	// Get Building Statistics Data
	const getBuildingStats = async () => {
		await HttpService({
			method: 'GET',
			route: `/buildings/dist/stats`,
		})
			.then(res => {
				if (res.statusCode === 200) {
					setStatsData(res.data);
					setFilteredStatsData(res.data);
					setLoading(false);
				}
			})
			.catch(err => {
				console.log(err);
				setLoading(false);
			});
	};

	const onHandleStatsSearch = value => {
		const searchData = statsData.filter(item =>
			item.team_name
				.toString()
				.toLowerCase()
				.includes(value.toString().toLowerCase()),
		);
		setFilteredStatsData(searchData);
	};
	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && (
				<div className='StatisticsTable__Container'>
					<div className="site-container-search">
						<SearchBar
							placeholder="Search by site name..."
							icon="search"
							className="search-bar-text"
							onSearch={onHandleStatsSearch}
						/>
					</div>
					<div className="main-table-container">
						<BootstrapedTable
							keyField="adminStatisticsTableId"
							data={filteredStatsData}
							columns={columns}
							showPagination={false}
						/>
					</div>
				</div>
			)}
		</>
	);
};
