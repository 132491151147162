import React, { useState, useEffect, useContext } from 'react';
import { BootstrapedTable, TableCell, Modal, myClasses, EmptyState } from 'componentsV2';
import { ReunificationService } from 'services';
import { RollCallAndReunificationContext } from '../RollCallAndReunification';
import { CheckinGuardianStatus } from '../Reunification/Guardians/GuardiansCheckin/CheckinSteps/Shared';
import './css/ReunificationStudentGuardiansModal.scss';
import { Helper } from 'utilities/helper';

const StudentItem = ({ title, value }) => (
	<div className="AddStudsents__Item">
		<label className="StudentLabel">{title}</label>
		<p className="StudentValue">{value ? value : 'N/A'}</p>
	</div>
);

export const ReunificationStudentGuardiansModal = () => {
	const [studentGuardians, setStudentGuardians] = useState([]);
	const [loading, setLoading] = useState(true);
	const { alarm, selectedStudent, setModalToShow } = useContext(RollCallAndReunificationContext);

	useEffect(() => {
		ReunificationService.getStudentGuardians(alarm.id, selectedStudent.id)
			.then(response => {
				if (response.statusCode === 200) {
					setStudentGuardians(response.data);
				}
			})
			.catch(error => {})
			.finally(() => {
				setLoading(false);
			});
	}, []);
	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'student',
			dataField: 'student',
			text: 'Guardian’s name',
			sort: false,
			headerStyle: () => ({ width: '40%' }),

			formatter: (value, row) => (
				<TableCell
					text={`${row.name}`}
					supportText={Helper.formatDigitsToPhone(
						row.mobilePhone ? row.mobilePhone.phoneNumber : '',
					)}
				/>
			),
		},
		{
			key: 'relation',
			dataField: 'relation',
			text: 'Relationship to student',
			formatter: (value, row) => (row.relation ? row.relation : 'N/A'),
		},

		{
			key: 'status',
			text: 'Status',
			dataField: 'status',

			formatter: (value, row) => {
				if (row.status == 'Checked-in') {
					row.checkIn = { status: 'checkedIn' };
				}
				return <CheckinGuardianStatus item={row} includeReunify={false} />;
			},
		},
	];

	const closeModalAction = () => {
		setModalToShow(null);
	};

	return (
		<div className="GuardianWithStudsentsTable__Wrapper">
			<Modal
				isOpen={true}
				onClose={closeModalAction}
				title="Guardians"
				size="medium"
				showCloseButton={true}
				loading={loading}
				customBodyClass="studentGuardian_customModal"
				customClassName="studentGuardian-checkin-modal-container"
			>
				<div className="studentGuardian_topSection">
					<div className="studentGuardian__contents">
						<StudentItem
							title="Student"
							value={
								selectedStudent.firstName +
								' ' +
								(!selectedStudent.middleInitial ||
								selectedStudent.middleInitial == null
									? ''
									: selectedStudent.middleInitial?.substring(0, 1) + '. ') +
								selectedStudent.lastName
							}
						/>
						<StudentItem title="Grade" value={selectedStudent.grade} />
					</div>
				</div>

				<div className="StudentGuardians__Wrapper">
					{studentGuardians.guardians?.length == 0 && (
						<EmptyState
							header="No guardians found for this student"
							description=""
							className="StudentGuardianList_emptyState"
							icon={myClasses}
							showCircles={false}
						/>
					)}
					{studentGuardians.guardians?.length != 0 && (
						<BootstrapedTable
							keyField="id"
							className="students-guardians-table-list"
							data={studentGuardians.guardians}
							columns={columns}
							showPagination={false}
						/>
					)}
				</div>
			</Modal>
		</div>
	);
};
