import React from 'react';
import { Dialog } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { ModalHeader } from './';
import { LoadingSpinner } from '../';
import './css/Modal.scss';

export const Modal = ({
	isOpen,
	onClose,
	loading,
	loadingMessage = '',
	size,
	title,
	subtitle,
	respectSubtitleHtml,
	showCloseButton,
	icon,
	iconColor,
	footer,
	customFooter,
	children,
	customClassName = '',
	scrollContent = true,
	onScrollHandller,
	customHeaderClass = '',
	customBodyClass = '',
	showHeader = true,
	showBackArrow = false,
	backArrowAction,
	hasCallout = false,
	calloutData = null,
}) => {
	return (
		<Dialog
			className={`modal-wrapper modal-rounded modal-no-padding size-${size ||
				'medium'} ${customClassName}`}
			isOpen={isOpen}
			onClose={() => onClose(false)}
		>
			{loading ? (
				<div className="modal-loading-wrapper">
					<LoadingSpinner message={loadingMessage} />
				</div>
			) : (
				<>
					{showHeader && (
						<ModalHeader
							onClose={onClose}
							title={title}
							subtitle={subtitle}
							respectSubtitleHtml={respectSubtitleHtml}
							showCloseButton={showCloseButton}
							icon={icon}
							iconColor={iconColor}
							customHeaderClass={customHeaderClass}
							showBackArrow={showBackArrow}
							backArrowAction={backArrowAction}
							hasCallout={hasCallout}
							calloutData={calloutData}
						/>
					)}

					{children && (
						<div
							className={`modal-content-wrapper ${customBodyClass} ${
								footer || customFooter ? 'with-border' : ''
							}`}
						>
							<div
								className={`content-body ${scrollContent ? 'scroll-content' : ''}`}
								onScroll={onScrollHandller}
							>
								{children}
							</div>
						</div>
					)}

					{footer && !customFooter && (
						<div className="modal-footer-wrapper">{footer}</div>
					)}
					{customFooter}
				</>
			)}
		</Dialog>
	);
};

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	loadingMessage: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
	size: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	respectSubtitleHtml: PropTypes.bool,
	showCloseButton: PropTypes.bool,
	icon: PropTypes.string,
	iconColor: PropTypes.string,
	footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	customFooter: PropTypes.element,
	scrollContent: PropTypes.bool,
	customBodyClass: PropTypes.string,
	showHeader: PropTypes.bool,
};
