import React from 'react';
import PropTypes from 'prop-types';
import {
	Badge,
	BootstrapedTable,
	Button,
	EmptyState,
	LoadingSpinner,
	noResult,
	SearchBar,
	sortingStyle,
} from 'componentsV2';
import { formatDate } from 'utilities/dates';
import { SORTING_DIR } from 'utilities/constants';
import { TableActions } from './TableActions';
import { Helper } from 'utilities/helper';
import './FirstResponderUsersTable';

export const FirstResponderUsersTable = ({
	users,
	dispatchFilter,
	userEditPermission,
	openModal,
	currentPage,
	setCurrentPage,
	rowsPerPage,
	setRowsPerPage,
	totalRows,
	setSortDirection,
	loading,
}) => {
	const columns = [
		{ dataField: 'uniqid', text: 'ID', hidden: true },
		{
			dataField: 'person.fullName',
			text: 'Name',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'person.title',
			text: 'Title',
			sort: false,
			formatter: (cell, row) => {
				if (row.person.title === null || row.person.title.trim().length === 0) {
					return '';
				}

				return row.person.title;
			},
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'person.emailAddress',
			text: 'Email',
			sort: false,
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'person.phoneNumber',
			text: 'Phone Number',
			sort: false,
			formatter: (cell, row) => Helper.formatDigitsToPhone(row.person.phoneNumber),
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'lastLogin',
			text: 'Last Login',
			sort: false,
			headerStyle: { width: '16%' },
			formatter: (cell, row) => {
				if (cell !== 'N/A') {
					return formatDate(cell);
				}

				return cell;
			},
		},
		{
			dataField: 'person.status',
			text: 'Status',
			sort: false,
			formatter: (cell, row) => {
				if (isDisabled(row)) {
					return <Badge text="Disabled" />;
				}

				return <Badge text="Enabled" type="Primary" />;
			},
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'id',
			text: '',
			formatter: (cell, row) => <TableActions user={row} openModal={openModal} />,
			editable: false,
		},
	];

	const isDisabled = user => {
		if (user.person.status === 1) {
			return false;
		}

		return true;
	};

	const defaultSorted = [
		{
			dataField: 'person.fullName', // if dataField is not match to any column you defined, it will be ignored.
			order: SORTING_DIR.ASC, // desc or asc
		},
	];

	const disabledRowCondition = row => isDisabled(row);

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort' && sortField === 'person.fullName') {
			setSortDirection(sortOrder);
		}
	};

	return (
		<div className="first-responder-users-table">
			<div className="header">
				<h2 className="title">Users</h2>
				{userEditPermission && (
					<Button
						icon="plus"
						text="Add new user"
						type="primaryDefault"
						size="lg"
						onClick={() => openModal('NewUserResponderModal', { isEdit: false })}
					/>
				)}
			</div>
			<div className="filters">
				<SearchBar
					placeholder="Search..."
					className="search-bar"
					onSearch={value => dispatchFilter({ type: 'name', payload: value })}
				/>
			</div>
			{loading && <LoadingSpinner className="no-results" />}
			{!loading && users.length > 0 && (
				<BootstrapedTable
					keyField="uniqid"
					data={users}
					columns={columns}
					defaultSorted={defaultSorted}
					disabledRowCondition={disabledRowCondition}
					currentPage={currentPage}
					setCurrentPage={value => setCurrentPage(value)}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={value => setRowsPerPage(value)}
					totalRows={totalRows}
					onTableChange={onTableChange}
				/>
			)}
			{!loading && users.length === 0 && (
				<EmptyState
					header="No users were found"
					icon={noResult}
					description="Try changing the search criteria or add a new user."
					showCircles
					className="no-results"
				/>
			)}
		</div>
	);
};

FirstResponderUsersTable.propTypes = {
	users: PropTypes.array,
	dispatchFilter: PropTypes.func,
	userEditPermission: PropTypes.bool,
	openModal: PropTypes.func,
	currentPage: PropTypes.number,
	setCurrentPage: PropTypes.func,
	rowsPerPage: PropTypes.number,
	setRowsPerPage: PropTypes.func,
	totalRows: PropTypes.number,
	setSortDirection: PropTypes.func,
	loading: PropTypes.bool,
};
