import React, { useState } from 'react';
import { Button, Tooltip, AlertModal } from 'componentsV2';

export const FooterActions = ({ template, closeModal, onApplyAllAction }) => {
	const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);

	const onConfirmActionHandler = () => {
		setAlertModalIsOpen(false);
		onApplyAllAction();
	};

	const ApplyAllChangesAlert = () => {
		return (
			<AlertModal
				isOpen={alertModalIsOpen}
				onClose={() => setAlertModalIsOpen(false)}
				size="medium"
				title="Are you sure you want to apply all updates?"
				subtitle="Once all updates are applied this cannot be undone."
				showCloseButton={false}
				icon="warning-sign"
				iconColor="warning"
				alertConfirmButton={
					<Button
						text="Yes, apply all updates"
						type="primaryDefault"
						size="md"
						onClick={onConfirmActionHandler}
					/>
				}
				alertCancelButton={
					<Button
						text="No, go back"
						type="tertiary"
						size="md"
						onClick={() => setAlertModalIsOpen(false)}
					/>
				}
			/>
		);
	};

	return (
		<>
			<Button
				text="Close without updating"
				type="tertiary"
				intent="default"
				onClick={closeModal}
			/>
			{!template.acceptingBulkUpdate.success && (
				<Tooltip
					tooltipTitle="Cannot apply all updates since some updates cannot be pulled. You have to pull the updates one by one."
					theme="dark"
				>
					<Button
						text="Apply all updates"
						intent="default"
						disabled={true}
					/>
				</Tooltip>
			)}
			{template.acceptingBulkUpdate.success && (
				<>
					<ApplyAllChangesAlert />
					<Button
						text="Apply all updates"
						intent="default"
						onClick={() => setAlertModalIsOpen(true)}
					/>
				</>
			)}
		</>
	);
};
