import React, { useState } from "react";
import { Tag } from '@blueprintjs/core';
import { USER_ACCESS_TYPES } from "utilities/constants";

const RolesColumn = ({
    row,
    callList
}) => {
    const [showRolesCard, setShowRolesCard] = useState(false);

    document.addEventListener('click', function(e){
        if ( !document.getElementById('tag-'+row.id)?.contains(e.target)){
            // Clicked outside the box
            setShowRolesCard(false)
        }
    })

    if(row.accessType === USER_ACCESS_TYPES.OUTSIDE_USER) {

        return "External Contact";
    }

    var mainRole = null;
    var remaingRoles = [];

    row.roles?.map(role => {
        if( role.building.id === callList.site_id ) {
            mainRole = role
        } else {
            remaingRoles.push(role);
        }
    })

    return (
        <div className="d-flex">
            <div>
                {mainRole?.role.title}
            </div>
            {remaingRoles.length > 0 && (
            <div className="position-relative">
                <Tag 
                    key={row.id}
                    className="tags-extra"
                    onClick={() => setShowRolesCard(!showRolesCard)}
                    id={'tag-'+row.id}
                    style={{
                        marginLeft:5
                    }}
                    > +{remaingRoles.length} more 
                </Tag>
                {showRolesCard && 
                    <div className="roles-type-card">
                        {remaingRoles.map((role, key) => {
                            return (
                                <div className="roles-type-card-item" key={key}>
                                    <p>{ role.building.name}</p>
                                    <Tag key={key} className="tags-extra"> 
                                        {role.role.title}
                                    </Tag>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
            )}
        </div>
    );
}

export default RolesColumn;