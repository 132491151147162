import React from 'react';
import PropTypes from 'prop-types';
import { ActionsButton, Button, Tooltip, TooltipPosition } from 'componentsV2';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import { decodeToken } from 'utilities';
import { BINDER_EDIT_PATH, BINDER_VIEW_PATH } from '..';
import { useContext } from 'react';
import { BinderModalsContext } from './BinderModalsProvider';
import {
	HAS_OLD_SP_TP_CONTENT_EDIT_USER,
	HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER,
	HAS_OLD_SP_TP_COPY_CONTENT_EDIT_USER,
} from './consts.js';

export const ActionsList = ({
	binder,
	history,
	sourcePage = null,
	elementSelector = null,
	hasSiteEditPermission,
}) => {
	const permissions = useSelector(state => state.route.permissions);
	const isArchived = binder.archivedByUser ? true : false;
	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const dist = token ? decodeToken(token) : '';
	const districtId = dist ? dist.dist : null;
	const editPermission = checkPermission(
		'vb_edit',
		permissions,
		binder.building ? binder.building.id : districtId,
	);
	const viewPermission = checkPermission('vb_view', permissions, binder.building?.id);

	const { downloadBinder, showCopyModal, showArchiveModal, showDeleteModal } = useContext(
		BinderModalsContext,
	);

	const downloadBtn = (
		<Button
			large
			text="Download"
			icon="download"
			size="md"
			type="ghostDefault"
			wrapperClass="displayBlock"
			className={`actionButtonElement ${binder.hasOldSP ? ' customDownloadDisabled' : ''}`}
			disabled={binder.hasOldSP}
			onClick={() => downloadBinder(binder)}
		/>
	);

	const copyBtn = (
		<Button
			large
			text="Copy"
			icon="duplicate"
			size="md"
			type="ghostDefault"
			intent="default"
			wrapperClass="displayBlock"
			className={`actionButtonElement ${binder.hasOldSP ? ' customDownloadDisabled' : ''}`}
			disabled={binder.hasOldSP}
			onClick={() => {
				showCopyModal(binder);
			}}
		/>
	);

	return (
		viewPermission && (
			<ActionsButton elementSelector={elementSelector} customClass="binder_ActionsList">
				{sourcePage !== 'details' &&
					viewPermission &&
					(!isArchived ? !editPermission : true) && (
						<Button
							large
							text="View"
							icon="eye-open"
							size="md"
							type="ghostDefault"
							intent="default"
							wrapperClass="displayBlock"
							className="actionButtonElement"
							onClick={() => history.push(BINDER_VIEW_PATH + '/' + binder.id)}
						/>
					)}
				{editPermission && sourcePage !== 'details' && !isArchived && (
					<Button
						large
						text="Edit"
						icon="edit"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => history.push(BINDER_EDIT_PATH + '/' + binder.id)}
					/>
				)}
				{editPermission && (
					<Button
						large
						text={isArchived ? 'Unarchive' : 'Archive'}
						icon="archive"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => showArchiveModal(binder)}
					/>
				)}

				{sourcePage !== 'details' &&
					binder.contentCount != 0 &&
					viewPermission &&
					!isArchived &&
					(binder.hasOldSP ? (
						<Tooltip
							className="binderPopover_wrapper"
							tooltipContent={
								<p className="tp_text">
									{hasSiteEditPermission(binder)
										? HAS_OLD_SP_TP_CONTENT_EDIT_USER
										: HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER}
								</p>
							}
							theme="light"
							position={TooltipPosition.LEFT}
						>
							{downloadBtn}
						</Tooltip>
					) : (
						downloadBtn
					))}

				{editPermission &&
					!isArchived &&
					(binder.hasOldSP ? (
						<Tooltip
							className="binderPopover_wrapper"
							tooltipContent={
								<p className="tp_text">{HAS_OLD_SP_TP_COPY_CONTENT_EDIT_USER}</p>
							}
							theme="light"
							position={TooltipPosition.LEFT}
						>
							{copyBtn}
						</Tooltip>
					) : (
						copyBtn
					))}

				{editPermission && !isArchived && (
					<Button
						large
						text="Delete"
						icon="trash"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => showDeleteModal(binder)}
					/>
				)}
			</ActionsButton>
		)
	);
};

ActionsList.propTypes = {
	binder: PropTypes.object,
};
