import React from 'react';
import { PropTypes } from 'prop-types';
import { Checkbox, FormModal } from 'componentsV2';

export const EditEmailRespondersModal = ({
	isOpen,
	onModalSubmit,
	onCloseModal,
	responderDetails = [],
	emailIds = [],
	onChange,
	loading = false,
}) => (
	<FormModal
		title="Responders"
		size="small"
		isOpen={isOpen}
		onClose={onCloseModal}
		showCloseButton={false}
		confirmBtn={{
			title: 'Update',
			onClick: onModalSubmit,
		}}
		cancelBtn={{
			title: 'Cancel',
			onClick: onCloseModal,
		}}
		loading={loading}
	>
		<div className="w-100 checkbox-container">
			{responderDetails?.length === 0
				? 'No responders found.'
				: responderDetails.map(item => (
						<Checkbox
							label={item.name}
							onChange={e => onChange(e)}
							checked={emailIds?.includes(item.id.toString())}
							key={`checkbox-${item.id}`}
							size="small" // small | medium
							value={item.id}
						/>
				  ))}
		</div>
	</FormModal>
);

EditEmailRespondersModal.propTypes = {
	isOpen: PropTypes.bool,
	onModalSubmit: PropTypes.func,
	onCloseModal: PropTypes.func,
	responderDetails: PropTypes.array.isRequired,
	emailIds: PropTypes.array.isRequired,
	onChange: PropTypes.func,
	loading: PropTypes.bool,
};
