import React, { useState, useContext } from 'react';
import { Input, FormModal, Intent, ToasterContext, FeaturedIcon } from 'componentsV2';
import PropTypes from 'prop-types';
import { AssessTemplatesService } from 'services';

export const CopyTemplateModal = ({ onCloseModal, templateData, onClickCopy }) => {
	const [titleValue, setTitleValue] = useState('');
	const [loading, setLoading] = useState(false);
	const toaster = useContext(ToasterContext);

	const onSave = () => {
		setLoading(true);
		AssessTemplatesService.copyTemplate(templateData.id, titleValue)
			.then(() => {
				toaster(
					`You have successfully copied "${templateData.name}".`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" type="Success" size="md" shape="circleIcon" />,
				);
			})
			.catch(() => {
				toaster(
					`There was an error copying ${templateData.name}. Please try again.`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" size="md" shape="circleIcon" />,
				);
			})
			.finally(() => {
				setLoading(false);
				onCloseModal();
				onClickCopy();
			});
	};

	return (
		<FormModal
			isOpen
			onClose={onCloseModal}
			showCloseButton
			title="Copy template"
			subtitle={templateData.name}
			loading={loading}
			loadingMessage="Copying..."
			confirmBtn={{
				title: 'Create',
				onClick: () => onSave(),
				disabled: !titleValue,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: () => onCloseModal(),
			}}
		>
			<Input
				label="Template title"
				placeholder="Template title"
				isRequired
				hasHintText
				hintText="Give your copied template a new title. Please refrain from using a duplicate template title."
				onChange={e => setTitleValue(e.target.value)}
				value={titleValue}
				width="100%"
			/>
		</FormModal>
	);
};

CopyTemplateModal.propTypes = {
	onCloseModal: PropTypes.func,
	onClickCopy: PropTypes.func,
	templateData: PropTypes.object,
};
