import React from 'react';
import './css/AddStudentHeader.scss';

export const AddStudentHeader = ({ title, subtitle }) => {
	return (
		<div className="headerContent">
			<p className="title">{title}</p>
			<p className="supportingText">{subtitle}</p>
		</div>
	);
};
