import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserService } from 'services/UserService';
import './css/Avatar.scss';

export const Avatar = ({ name, image = '', size = 'lg', label = '', supportingText = '' }) => {
	const dispatch = useDispatch();
	const updateLocalStorageKey = useSelector(state => state.route.updateLocalStorageKey);

	const [imageLink, setImageLink] = useState(image);

	useEffect(() => {
		setImageLink(image);
	}, [image, name]);

	const resolveInitials = name => {
		const wordSplit = name.match(/\b\w+\b/g);
		const lastWord = wordSplit ? wordSplit[wordSplit.length - 1] : '';
		const firstWord = wordSplit ? wordSplit[0] : '';
		const firstCharacterOfFirstWord = firstWord ? firstWord[0] : '';
		const firstCharacterOfLastWord = lastWord ? lastWord[0] : '';
		return firstCharacterOfFirstWord + firstCharacterOfLastWord;
	};

	// returns [labelSize, supportingTextSize]
	const resolveLabelGroupTypo = size => {
		switch (size) {
			case 'xs':
				return ['xs', 'xs'];
				break;
			case 'sm':
				return ['sm', 'xs'];
				break;
			case 'md':
				return ['sm', 'sm'];
				break;
			case 'lg':
				return ['md', 'md'];
				break;
			case 'xl':
				return ['lg', 'md'];
				break;
			case '2xl':
				return ['xl', 'lg'];
				break;
		}
	};
	const onError = e => {
		if (imageLink) {
			let loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
			UserService.getUserById(loggedUserData.id, '?includeUserBuildings=true').then(resp => {
				loggedUserData.personPhoto = resp.data.personPhoto;
				loggedUserData.personPhotoUrl = resp.data.personPhotoUrl;
				localStorage.setItem('loggedUserData', JSON.stringify(loggedUserData));

				dispatch({
					type: 'UPDATE_LOCAL_STORAGE',
					payload: !updateLocalStorageKey,
				});
			});
		}
	};

	const labelGroupSize = resolveLabelGroupTypo(size);

	return (
		<div className={`Avatar__wrapper avatar-${size}`} tabIndex={0}>
			{imageLink ? (
				<img src={imageLink} className={'avatar'} onError={onError} />
			) : (
				<div className={`avatar border-gray-50 body-${size}-bold`}>
					{resolveInitials(name)}
				</div>
			)}
			{(label || supportingText) && (
				<div className={'label-group'}>
					<div className={`label body-${labelGroupSize[0]}-bold`}>{label}</div>
					<div className={`supporting-text body-${labelGroupSize[1]}-regular`}>
						{supportingText}
					</div>
				</div>
			)}
		</div>
	);
};
