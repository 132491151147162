import React, {useContext, useEffect} from "react";
import { ToasterContext } from 'pages/App';
import { Intent} from "componentsV2";

const ToasterModal = ({
   msg,
   intent
}) => {
    const toaster = useContext(ToasterContext);
    useEffect(() => {
        toaster(msg, intent == "success" ? Intent.SUCCESS : Intent.DANGER);
    }, []);

    return null;
}

export default ToasterModal;
