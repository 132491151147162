import React from 'react';
import { ConfirmationModal } from 'componentsV2';
import { GEOMETRY_TYPE_ICON } from '../../../Details/consts';

export const DeleteNoteModal = ({ loading, closeModal, deleteNodeHandler, selectedShape }) => {
	return (
		<ConfirmationModal
			isOpen={true}
			title="Are you sure you want to delete this note?"
			subtitle={
				'This will remove this note from this map ' +
				(selectedShape.geometryType == GEOMETRY_TYPE_ICON ? 'icon.' : 'area.') +
				' Once removed, the information cannot be recovered.'
			}
			showCloseButton={false}
			customClassName={'map-container-modals'}
			cancelBtn={{
				title: 'No, go back',
				onClick: closeModal,
			}}
			confirmBtn={{
				title: 'Yes, delete it',
				onClick: deleteNodeHandler,
				type: 'primaryDanger',
			}}
			loading={loading}
			btnsSize="lg"
			icon={{
				icon: 'trash',
				iconColor: 'error',
			}}
		/>
	);
};
