import React, { useState, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import './ScenariosTable.scss';
import { Icon } from '@blueprintjs/core';
import { SORTING_DIR } from 'utilities/constants';
import { Badge } from 'componentsV2';
import { ScenariosService } from "services/ScenariosService";
import { CATEGORIES_BADGE_MAP } from '../../../../../utilities/constants';
import { BootstrapedTable, TableCell, sortingStyle, ConfirmationModal, Intent, FeaturedIcon } from 'componentsV2';
import { ActionsList } from "../ActionsList";
import { ToasterContext } from 'pages/App';
import { MarkAsUsedModal } from '../MarkAsUsedModal/MarkAsUsedModal';
import Swal from 'sweetalert2';

const ScenariosTable = ({
	scenarios,
	filterationData,
	dispatchFilterationData,
	totalRows,
	fetchScenarios,
	loadEditModal,
	loadDeleteModal,
	history
}) => {
	const [showModal, setShowModal] = useState('');
	const [loading, setLoading] = useState(false);
	const toaster = useContext(ToasterContext);

	const closeModal = () => setShowModal('')

	const drillScenarioDataReducer = (scenarioData, action) => {
		return {
			id: action.id,
			title: action.title,
			is_enabled: action.is_enabled,
		}
	}

	const [scenarioData, dispatchScenarioData] = useReducer(drillScenarioDataReducer, {
		id: null,
		title: '',
		is_enabled: true
	});

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: false,
		},
		{
			key: 'title',
			dataField: 'title',
			text: 'Name',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%' },
			formatter: (value, row) => {
				const leftIcon = row.static_id ? <Icon icon="lock" /> : null;
				const supportText = row.static_id
					? 'Preset'
					: 'Custom Scenario';
				return (
					<TableCell
						text={value}
						supportText={supportText}
						leftIcon={leftIcon}
						rightIcon={null}
						firstRow={true}
					/>
				);
			},
		},
		{
			key: '',
			dataField: '',
			text: '',
			classes: 'bold-500',
			sort: true,
			sortCaret: () => '',
			headerStyle: { width: '20%' },
			formatter: (value, row) => {
				const leftIcon = row.audioFile ? (
					<Icon icon="volume-up" />
				) : row.videoFile ? (
					<Icon icon="mobile-video" />
				) : null;

				return (
					<TableCell
						text={value}
						supportText={null}
						leftIcon={leftIcon}
						rightIcon={null}
						firstRow={false}
					/>
				);
			},
		},
		{
			key: 'category_name',
			dataField: 'category_name',
			text: 'Category',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%' },

			formatter: (value, row) => (
				<TableCell
					text={null}
					supportText={null}
					leftIcon={
						<Badge
							text={value}
							type={CATEGORIES_BADGE_MAP[value]}
							size="lg"
						/>
					}
					rightIcon={null}
					firstRow={false}
				/>
			),
		},
		{
			key: 'site',
			dataField: 'site',
			text: 'Site visibility',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%' },
			formatter: (value, row) => (
				<TableCell
					text={row.enabled ? value : 'Disabled'}
					supportText={null}
					leftIcon={null}
					rightIcon={null}
					firstRow={false}
				/>
			),
		},
		{
			key: 'lastUsed',
			dataField: 'lastUsed',
			text: 'Last used',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%' },
			formatter: (value, row) => (
				<TableCell
					text={value?.date || 'N/A'}
					supportText={null}
					leftIcon={null}
					rightIcon={null}
					firstRow={false}
				/>
			),
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<ActionsList
					drillScenario={row}
					showEnableModal={showEnableModal}
					loadEditModal={loadEditModal}
					loadDeleteModal={loadDeleteModal}
					history={history}
					showMarkAsUsedModal={showMarkAsUsedModal}
				/>
			)
		},
	];

	const sortingStyle = (order, column) => {
		if (order === SORTING_DIR.DESC) {
			return <Icon className="carretIcon" icon="arrow-down" />;
		}
		return <Icon className="carretIcon" icon="arrow-up" />;
	};

	const showEnableModal = (row) => {

		dispatchScenarioData({
			id: row.id,
			title: row.title,
			is_enabled: row.enabled
		});

		setShowModal('disable')
	}

	const showMarkAsUsedModal = (row) => {
		dispatchScenarioData({
			id: row.id,
			title: row.title,
			is_enabled: row.enabled
		});
		setShowModal('markAsUsed')
	}

	const rowStyle = (row, rowIndex) => {
		const style = {};

		style.color = '#777';

		return style;
	};

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'title';
			switch (sortField) {
				case 'buildings[0].name':
					sortColumn = 'buildingName';
					break;
				case 'dateModified.date':
					sortColumn = 'lastModified';
					break;
				case 'numberOfContracts':
					sortColumn = 'contracts';
					break;
				default:
					sortColumn = sortField;
					break;
			}
			dispatchFilterationData({ type: 'sortKey', payload: sortColumn });
			dispatchFilterationData({ type: 'sortDir', payload: sortOrder });
		}
	};

	const handlEnableAction = async () => {
		const status = scenarioData.is_enabled ? 0 : 1;

		try {
			setLoading(true)
			await ScenariosService.enableAndDisableScenario(scenarioData.id, status);
			setLoading(false)
			closeModal()
			if (status == 0) {
				toaster('You have successfully disabled ' + '"' + scenarioData.title + '"!', Intent.NONE, <FeaturedIcon icon={'disable'} type={'Gray'} size={'md'} shape={'circleIcon'} />, 2000)
			} else {
				toaster('You have successfully enabled ' + '"' + scenarioData.title + '"!', Intent.SUCCESS, "", 2000)
			}

			fetchScenarios()

		} catch (error) {
			setLoading(false)
			toaster('Failed to  ' + (status == 0 ? 'disable!' : 'enable!'), 'danger')

		}
	}

	const disabledRowCondition = row => {
		return row.enabled === false;
	};

	const cancelBtn = { title: 'No, Go back', onClick: closeModal };
	const confirmEnableBtn = { title: 'Yes, ' + (scenarioData.is_enabled ? 'Disable ' : 'Enable ') + 'It', onClick: handlEnableAction };

	const [loadingLog, setLoadingLog] = useState(false)
	const markAsUsed = async (data) => {
		if (data.buildingId && data.dateUsed && data.scenarioType) {
			try {
				setLoadingLog(true)
				const res = await ScenariosService.markAsUsed(data)
				setLoadingLog(false)
				closeModal()
				toaster(`You have successfully marked "${scenarioData.title}" as used.`, Intent.SUCCESS)
			} catch (error) {
				closeModal()
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Error logging scenario usage'
				});
			}
		}
	}

	return (
		<div className="col-md-12">
			<ConfirmationModal
				isOpen={showModal === 'disable'}
				title={'Are you sure you want to ' + (scenarioData.is_enabled ? 'disable ' : 'enable ') + `"` + scenarioData.title + `"?`}
				subtitle={'You can ' + (scenarioData.is_enabled ? `enable` : `disable`) + ' it at a later time.'}
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmEnableBtn}
				loading={loading}
				btnsSize="lg"
			/>
			{showModal === 'markAsUsed' && (
				<MarkAsUsedModal
					closeModal={closeModal}
					markAsUsed={markAsUsed}
					scenarioId={`${scenarioData.id}`}
					loadingLog={loadingLog}
				/>
			)}
			<BootstrapedTable
				keyField="id"
				data={scenarios}
				columns={columns}
				bordered={false}
				defaultSorted={[
					{
						dataField: 'title',
						order: SORTING_DIR.ASC,
					},
				]}
				rowStyle={rowStyle}
				onTableChange={onTableChange}
				currentPage={filterationData.page}
				rowsPerPage={filterationData.perPage}
				setCurrentPage={value =>
					dispatchFilterationData({
						type: 'page',
						payload: value,
					})
				}
				setRowsPerPage={value =>
					dispatchFilterationData({
						type: 'perPage',
						payload: value,
					})
				}
				totalRows={totalRows}
				sizePerPageList={[10, 25, 50, 100, 200]}
				disabledRowCondition={disabledRowCondition}
			/>
		</div>
	);
};

ScenariosTable.propTypes = {
	scenarios: PropTypes.array,
	filterationData: PropTypes.array,
	dispatchFilterationData: PropTypes.func,
	totalRows: PropTypes.number,
};
export { ScenariosTable };
