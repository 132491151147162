import React from 'react';
import { ActiveEndRollCall } from './RollCallModals/ActiveEndRollCall';
import { ActivateReunificationModal } from './ActivateReunificationModal';
import {
	ACTIVATE_REUNIFICATION_MODAL,
	END_AlARM_MODAL,
	ACTIVATE_END_ROLLCALL_MODAL,
	DOWNLOAD_PAST_AlARM_MODAL,
	ADD_STUDENT_TO_REUNIFICATION_MODAL,
	EDIT_GUARDIAN_DETAILS_MODAL,
} from '../consts';
import { EndAlarmModal } from './EndAlarmModal';
import { DownloadPastAlarmDetailsModal } from './DownloadPastAlarmDetailsModal';
import { AddStudentToReunificationModal } from './AddStudentToReunificationModal';
import { EditGuardianDetailsModal } from './EditGuardianModals';

export const RapidAlarmModals = ({
	modalToShow,
	closeModal,
	activeAlarm,
	pastAlarm,
	pastAlarmHistory,
	history,
	requestType,
	isRollCallStarted,
	reunificationLocation,
	setReunificationLocation,
	isReunificaton,
	alarmId,
	guardianDetails,
}) => {
	return (
		<>
			{modalToShow === END_AlARM_MODAL && (
				<EndAlarmModal
					activeAlarmId={activeAlarm.id}
					handleCancel={closeModal}
					history={history}
					isReunificaton={isReunificaton}
				/>
			)}
			{modalToShow === ACTIVATE_END_ROLLCALL_MODAL && (
				<ActiveEndRollCall
					activeAlarm={activeAlarm}
					handleCancel={closeModal}
					requstType={requestType}
					history={history}
				/>
			)}
			{modalToShow === ACTIVATE_REUNIFICATION_MODAL && (
				<ActivateReunificationModal
					activeAlarmId={activeAlarm.id}
					handleCancel={closeModal}
					isRollCallStarted={isRollCallStarted}
					reunificationLocation={reunificationLocation}
					setReunificationLocation={setReunificationLocation}
				/>
			)}
			{modalToShow === DOWNLOAD_PAST_AlARM_MODAL && (
				<DownloadPastAlarmDetailsModal
					handleCancel={closeModal}
					pastAlarm={pastAlarm}
					pastAlarmHistory={pastAlarmHistory}
				/>
			)}
			{modalToShow === ADD_STUDENT_TO_REUNIFICATION_MODAL && (
				<AddStudentToReunificationModal handleCancel={closeModal} alarmId={alarmId} />
			)}
			{modalToShow === EDIT_GUARDIAN_DETAILS_MODAL && (
				<EditGuardianDetailsModal
					handleCancel={closeModal}
					guardianDetails={guardianDetails}
					alarmId={alarmId}
				/>
			)}
		</>
	);
};
