import React from 'react';
import { PlanService } from 'services';
import { Intent, FeaturedIcon } from 'componentsV2';

export const ToogleArchivePlanModalFn = (
	modalName,
	setLoading,
	formData,
	dispatchSafetyPlanData,
	closeModal,
	toaster,
	setModalToShow,
	loadData,
    history
) => {
	const showArchiveModal = safetyPlan => {
		setModalToShow(modalName);
		dispatchSafetyPlanData({
			id: safetyPlan.id,
			title: safetyPlan.title,
			archived: safetyPlan.archived,
			source: safetyPlan.source,
			linkedObjects: {},
		});
	};

	const handleArchiveSubmit = () => {
		setLoading(true);
		PlanService.toogleArchive(formData.id)
			.then(response => {
                if(formData.archived) {
					toaster(
                        `You have successfully unarchived "${
                            formData.title
                        }".`,
                        Intent.SUCCESS
					);
				} else {
					toaster(
                        `You have successfully archived "${
                            formData.title
                        }".`,
						Intent.NONE,
                        <FeaturedIcon icon={'archive'} type={'Gray'} />
					);
				}

                if(formData.source == 'list') {
					loadData();
				} else {
                    if(formData.archived) {
						history.push(`/safety-plans/${formData.id}`);
					} else {
						history.push('/safety-plans/view/' + formData.id);
					}
				}
				closeModal();
			})
			.catch(error => {
				if (error.error?.fields?.linkedObjects) {
					handleSpBinderFlipchartContents(error.error.fields.linkedObjects);
				} else {
					toaster('Error while archiving the safety plan', Intent.DANGER);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSpBinderFlipchartContents = linkedObjects => {
		dispatchSafetyPlanData({
			id: formData.id,
			title: formData.title,
			linkedObjects: linkedObjects,
		});
	};

	return {
		showArchiveModal,
		handleArchiveSubmit,
	};
};
