import React, { useContext, useReducer, useState } from "react";
import { Checkbox } from "components/Checkbox";
import { Input } from "components/Input";
import NormalModal from "components/Modals/NormalModal";
import { TextArea } from "components/TextArea";
import { UserService } from "services/UserService";
import { ToasterContext } from "pages/App";
import { Callout, Intent } from "@blueprintjs/core";
import { PHONE_TYPES } from 'utilities/constants';

const formDataReducer = (formData, action) => {
    formData[action.type] = action.payload;
    return {
        title: formData.title,
        message: formData.message,
        pushAlerts: formData.pushAlerts,
        textAlerts: formData.textAlerts,
        emailAlerts: formData.emailAlerts,
    }
}

const formDataErrorsReducer = (formDataErrors, action) => {
    formDataErrors[action.type] = action.payload;
    return {
        message: formDataErrors.message,
        notificationType: formDataErrors.notificationType,
    }
}

const SendTestNotificationModal = ({
    setModalToShow,
    setShowUnsavedChangesModal,
    user,
}) => {

    const [loading, setLoading] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [formData, dispatchFormData] = useReducer(formDataReducer, {
        message: "This is a test notification. Please do not be alarmed",
        title: "Test Notification",
        pushAlerts: false,
        textAlerts: false,
        emailAlerts: false,
    });
    const [errors, setErrors] = useState([])
    const [formDataErrors, dispatchFormDataErrors] = useReducer(formDataErrorsReducer, {
        message: false,
        title: false,
        notificationType: true,
    });
    const toaster = useContext(ToasterContext);

    const onConfirmActionHandler = () => {
        validateFormData().then(() => {
            if(!formDataErrors.message && !formDataErrors.notificationType && errors.length === 0) {

                setLoading(true);
                const params = {
                    "title": formData.title,
                    "message": formData.message,
                    "send_email": formData.emailAlerts ? 1 : 0,
                    "send_sms": formData.textAlerts ? 1 : 0,
                    "send_push": formData.pushAlerts ? 1 : 0,
                };
                UserService.sendTestNotification(user.id, params).then(res => {
                    if(res.statusCode === 200) {
                        toaster(res.data, Intent.SUCCESS)
                        setModalToShow(false);
                    } else {
                        toaster(res.error.description, Intent.DANGER)
                    }
                    setLoading(false);
                }).catch(res => {
                    toaster(res.error.description, Intent.DANGER);
                    setLoading(false);
                })
            }
        })
    }

    const validateFormData = async () => {

        if( !formData.message ) {
            dispatchFormDataErrors({type: 'message', payload: '* Message is required.'})
        } else {
            dispatchFormDataErrors({type: 'message', payload: false})
        }

        if( !formData.pushAlerts && !formData.textAlerts && !formData.emailAlerts ) {
            dispatchFormDataErrors({type: 'notificationType', payload: '* You must choose at least one type.'})
        } else {
            dispatchFormDataErrors({type: 'notificationType', payload: false})
        }

        //validate user has mobile number
        if( formData.textAlerts ) {
            const mobileNumber = user.person.phones.find(phone => phone.type == PHONE_TYPES.MOBILE)
            if(!mobileNumber || (mobileNumber && mobileNumber.phoneNumber.trim() == '')) {
                setErrors(['No mobile number defined for this user'])
            } else {
                setErrors([])
            }
        } else {
            setErrors([])
        }
    }

    const onCancelActionHandler = () => {

        if(!formData.title 
            && !formData.message
            && !formData.pushAlerts
            && !formData.emailAlerts
            && !formData.textAlerts) {
                setModalToShow(false)
        } else {
            if(isTouched) {
                setShowUnsavedChangesModal(true);
            } else {
                setModalToShow(false)
            }
        } 
    }

    return (
        <NormalModal
            onConfirmAction={onConfirmActionHandler}
            onCancelAction={onCancelActionHandler}
            header={'Send Respond Test Alert'}
            className="send-test-modal"
            confirmBtnTitle="Send"
            cancelBtnTitle="Cancel"
            confirmBtnDisabled={!formData.title 
                                && !formData.message
                                && !formData.pushAlerts
                                && !formData.emailAlerts
                                && !formData.textAlerts ? true : false}
            loading={loading}
        >
            <div className="row mb-3">
                {errors.length !== 0 && (
                    <div className="col-md-12 mb-2 mt-1">
                        <Callout 
                            intent='danger'
                            showHeader={false}
                        >
                           {errors.map(error => {
                                return <li className="error-text">{error}</li>
                           })}
                        </Callout>
                    </div>
                )}
                <div className="col-md-12 mt-1">
                    <div className="form-group">
                        <p className='title-details-header'>
                            <span className='title-details-header-text'>To </span>
                            <strong>{user.person.name.givenName} {user.person.name.familyName}</strong>
                        </p>
                        <label >Type</label>
                        <div className="row d-flex mt-1">
                            <div className="col-md-4">
                                <Checkbox 
                                    label="Push Alert"
                                    onChange={checked => {
                                        dispatchFormData({type: 'pushAlerts', payload: checked})
                                        setIsTouched(true);
                                        if(checked) {
                                            dispatchFormDataErrors({type: 'notificationType', payload: false})
                                        } else if( !formData.textAlerts && !formData.emailAlerts ) {
                                            dispatchFormDataErrors({type: 'notificationType', payload: '* You must choose at least one type.'})
                                        }
                                    }}
                                    checked={formData.pushAlerts}
                                />
                            </div>
                            <div className="col-md-4">
                                <Checkbox 
                                    label="Text Alerts"
                                    onChange={checked => {
                                        dispatchFormData({type: 'textAlerts', payload: checked})
                                        setIsTouched(true);
                                        if(checked) {
                                            dispatchFormDataErrors({type: 'notificationType', payload: false})
                                        } else if( !formData.pushAlerts && !formData.emailAlerts ) {
                                            dispatchFormDataErrors({type: 'notificationType', payload: '* You must choose at least one type.'})
                                        }
                                    }}
                                    checked={formData.textAlerts}
                                />
                            </div>
                            <div className="col-md-4">
                                <Checkbox 
                                    label="Email Alerts"
                                    onChange={checked => {
                                        dispatchFormData({type: 'emailAlerts', payload: checked})
                                        setIsTouched(true);
                                        if(checked) {
                                            dispatchFormDataErrors({type: 'notificationType', payload: false})
                                        } else if( !formData.textAlerts && !formData.pushAlerts ) {
                                            dispatchFormDataErrors({type: 'notificationType', payload: '* You must choose at least one type.'})
                                        }
                                    }}
                                    checked={formData.emailAlerts}
                                />
                            </div>
                            <div className="col-md-12">
                                <p className="error-text">{formDataErrors.notificationType}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <Input 
                            label="Title"
                            placeholder="Write a title"
                            value={formData.title}
                            onChange={e => {
                                dispatchFormData({type: 'title', payload: e.target.value})
                                setIsTouched(true);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <TextArea 
                            label="Message"
                            placeholder="Message here..."
                            isRequired={true}
                            width='100%'
                            type='textarea'
                            value={formData.message}
                            onChange={e => {
                                dispatchFormData({type: 'message', payload: e.target.value})
                                setIsTouched(true);
                                if(e.target.value) {
                                    dispatchFormDataErrors({type: 'message', payload: false})
                                } else {
                                    dispatchFormDataErrors({type: 'message', payload: '* Message is required.'})
                                }
                            }}
                            error={formDataErrors.message}
                            errorMessage={formDataErrors.message}
                        />
                    </div>
                </div>
            </div>
        </NormalModal>
    );
}

export default SendTestNotificationModal;