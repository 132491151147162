import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card as BpCard, Tag, Popover, Position } from '@blueprintjs/core';
import { Button } from 'components/Button';
import { format } from 'date-fns';
import Swal from 'sweetalert2';

const CardTitle = styled.h5`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	color: ${props => (props.color === 'red' ? '#9F1C3A	' : '#10161a')};
	margin-bottom: 0;
`;

const CardDetailsWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
`;

const CardTimeStamp = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #30404d;
	margin-bottom: 0;
`;
const CardSubtitle = styled.p`
	margin-bottom: 0;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #30404d;
`;

const StyledTag = styled(Tag)`
	margin-top: 6px;
	background-color: ${props => props.background} !important;
	font-family: Nunito Sans !important;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: ${props => props.text} !important;
	border-radius: 10px;
`;

class Card extends React.Component {
	state = {
		showMenu: false,
	};

	exportAssess = item => {
		Swal.fire('Exporting');
		Swal.showLoading();
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/assessments/${item.id}/export`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({}), // This is a weird bug fix ;  Removed the body.(Was not required)
		})
			.then(res => res.blob())
			.then(resp => {
				Swal.close();
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				}).then(() => {
					const filename = `${item.name} ${format(
						new Date(),
						'hh-mm-ss a',
					)}`;
					const data = new Blob([resp], {
						type: 'application/pdf',
					});
					const csvURL = window.URL.createObjectURL(data);
					const tempLink = document.createElement('a');
					tempLink.href = csvURL;
					tempLink.setAttribute('download', `${filename}.pdf`);
					tempLink.click();
				});
			});
	};

	getTag = status => {
		let background = '#EBF1F5';
		let text = '#394B59';
		if (status === 'In Progress') {
			background = '#E8F2F9';
			text = '#137CBD';
		}
		if (status === 'Completed') {
			background = '#E8F4EF';
			text = '#128C5A';
		}
		return (
			<StyledTag round text={text} background={background}>
				{status}
			</StyledTag>
		);
	};

	render() {
		const { item, history } = this.props;
		const { showMenu } = this.state;
		return (
			<div className="col-4 mb-3" key={`core-${item.id}`}>
				<BpCard>
					<div className="d-flex align-items-center justify-content-between mb-2">
						<CardTitle>{item.name}</CardTitle>
					</div>
					<CardSubtitle>
						{item.year}-{item.year + 1}
					</CardSubtitle>
					{this.getTag(item.status)}
					<CardDetailsWrap className="mt-3">
						<CardTimeStamp>
							Created on:{' '}
							{format(
								new Date(item.createdDate.date),
								'MM/dd/yyyy',
							)}
						</CardTimeStamp>
					</CardDetailsWrap>
					<hr />
					<div className="d-flex justify-content-between w-100">
						<Button
							intent="tertiary"
							onClick={() =>
								history.push(`/assessment/${item.id}`)
							}
							text="Continue"
							large
						/>
						<Popover
							enforceFocus={false}
							isOpen={showMenu}
							position={Position.BOTTOM}
							onClose={() => this.setState({ showMenu: false })}
						>
							<Button
								icon="more"
								text=""
								onClick={() =>
									this.setState({ showMenu: true })
								}
								minimal
							/>
							<div style={{ width: '234px' }}>
								<div className="pt-2 pb-2">
									<Button
										minimal
										text="Print assessment"
										className="w-100 justify-content-start"
										onClick={() => this.exportAssess(item)}
									/>
								</div>
							</div>
						</Popover>
					</div>
				</BpCard>
			</div>
		);
	}
}

Card.propTypes = {
	item: PropTypes.object,
	history: PropTypes.object,
};

export default Card;
