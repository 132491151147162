import React, { useContext, useEffect, useState } from "react";
import NormalModal from "../../../components/Modals/NormalModal";
import { ToasterContext } from "../../App";
import { Intent } from '@blueprintjs/core';
import { UsersService } from 'services/UsersService';

const DisableUsersModal = ({
    onCloseModal,
    selectedRows,
    fetchUsers,
    setSelectedRows
}) => {

    const context = useContext(ToasterContext);
	const [message, setMessage] = useState();
    const [headerMsg, setHeaderMsg] = useState();
    const [loading, setLoading] = useState(false);


    const searchBtnClickHandler = () => {
        setLoading(true);
        const usersIds = [];
        selectedRows.map(selectedRow => {
            usersIds.push(
                selectedRow.id
			);
        });
       
        UsersService.disableUsers(usersIds).then(resp => {
            if(resp.statusCode == 200) {
                context(resp.data, Intent.SUCCESS)
                fetchUsers()
                setSelectedRows([])
            } else {
                context(resp.data, Intent.DANGER)
            }
            onCloseModal();
        }).catch(() => {
            onCloseModal();
        });
    }

    const cancelBtnClickHandler = () => {
        onCloseModal();
    }

    useEffect(() => {
		if (selectedRows.length == 1) {
            setMessage('Are you sure you want to disable this user?');
            setHeaderMsg('Disable User?');
		} else {
            setMessage('Are you sure you want to disable these users?');
            setHeaderMsg('Disable Users?');

        }
	}, []);

    return (
		<>
            <NormalModal
            	setModalToShow="DisableUsersModal"
                onConfirmAction={searchBtnClickHandler}
                onCancelAction={cancelBtnClickHandler}
                header={headerMsg}
                confirmBtnTitle="Disable"
                cancelBtnTitle="Cancel"
                confirmBtnDisabled={false}
                loading={loading}
            >
                <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="form-group">
                           <p>{message}</p>
                        </div>
                    </div>
                   
                </div>
            </NormalModal>
        </>
    );
}

export default DisableUsersModal;