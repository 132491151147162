import React, { useContext } from 'react';
import { AddUpdateCallListModal, DeleteCallListModal } from './';
import { ADD_EDIT_CALL_LIST, DELETE_CALL_LIST } from '../Components/consts';
import { CallListContext } from '../Providers';

export const CallListModals = () => {
	const {
		setReloadCallLists,
		selectedCallListId,
		setSelectedCallListId,
		modalToShow,
		setModalToShow,
		sites,
		callList,
	} = useContext(CallListContext);

	const handleCancel = () => {
		setModalToShow(null);
	};
	return (
		<>
			{modalToShow === ADD_EDIT_CALL_LIST && (
				<AddUpdateCallListModal
					handleCancel={handleCancel}
					sites={sites}
					history={history}
					setReloadCallLists={setReloadCallLists}
					selectedCallListId={selectedCallListId}
					setSelectedCallListId={setSelectedCallListId}
				/>
			)}
			{modalToShow === DELETE_CALL_LIST && (
				<DeleteCallListModal
					callList={callList}
					handleCancel={handleCancel}
					setReloadCallLists={setReloadCallLists}
				/>
			)}
		</>
	);
};
