const parseDataModel = template => {
	const temp = { ...template };
	temp.sections.forEach(item => {
		const one = item;
		if (!one.content) {
			one.content = `Section ${item.id} content`;
		}
	});
	if (!temp.bonusFiles) {
		temp.bonusFiles = [];
	}
	return temp;
};

/**
 * @type {string}
 * Error Messages in form of HTML compatible with SWAL
 */
const TemplateNameValidationErrorMessage =
	'<b>This Template has empty name.</b>';
const PlanNameValidationErrorMessage = '<b>This Plan has empty name.</b>';
const emptyTitleHTMLErrorMessageForPublish =
	'<b>This Sections/Subsections has empty title</b>';
const emptyTitleHTMLErrorMessage =
	'<b>Exist Sections/Subsections with empty title</b>';

/**
 * Validates the sections of a template, ensuring that each section and subsection
 * has a title and that all required files have been uploaded.
 *
 * @param {Array} sections - An array of objects representing the sections of the template.
 * @param {bool} [is_template=false] - An optional boolean indicating whether the sections being referred to are part of a template or a safety plan.
 * @param {string} action - string to determine the action calling the validation, to make decisions based on it
 *        publish action here is used to validate publishing the main section (Template).
 * @param {string} name - string to determine the Template/Plan name
 * @returns {Object} - An object representing any issues found during validation. If no issues were found, the object will have a null 'issue' property.
 */

const validateTemplateSections = (
	sections,
	is_template = false,
	action = null,
	name,
) => {
	if (!sections) {
		return {
			issue: 'noSections',
			messageHTML: 'There is no Sections!',
		};
	}
	for (const section of sections) {
		if (!section.title) {
			return {
				issue: 'emptyTitles',
				elementId: `section-${section.id}`,
				messageHTML:
					action === 'publish'
						? emptyTitleHTMLErrorMessageForPublish
						: emptyTitleHTMLErrorMessage,
			};
		}
		if (section.subsections) {
			for (const subsection of section.subsections) {
				if (!subsection.title) {
					return {
						issue: 'emptyTitles',
						elementId: `subsection-${section.id}-${subsection.id}`,
						messageHTML:
							action === 'publish'
								? emptyTitleHTMLErrorMessageForPublish
								: emptyTitleHTMLErrorMessage,
					};
				}
				// only in templates -->  Check if the subsection is a 'fileSection' and a 'reupload' property set to true , but no 'baseFile.name' property
				if (
					is_template &&
					subsection.fileSection &&
					subsection.reupload &&
					!subsection.baseFile.name
				) {
					return {
						issue: 'missingUpload',
						elementId:
							subsection.id !== undefined
								? `subsection-${section.id}-${subsection.id}`
								: null,
						messageHTML: `<b>${
							subsection.title
						} is missing a file upload. </b>`,
					};
				}
			}
		}
	}
	if (name.trim().length === 0) {
		return {
			issue: 'emptyName',
			messageHTML: is_template
				? TemplateNameValidationErrorMessage
				: PlanNameValidationErrorMessage,
		};
	}
	return { issue: null };
};

/**
 * used in publish updates
 * Validates a subsections in a template, ensuring that this subsection
 * has a title and that all required files have been uploaded.
 *
 * @param subsection - An objects representing a subsection in the template.
 * @param sectionId - the parent Section id of the passed subsection.
 * @param {bool} [is_template=false] - An optional boolean indicating whether the sections being referred to are part of a template or a safety plan.
 * @param {string} type - string representing the type of this subsection, to make decisions based on it in future use
 * @returns {Object} - An object representing any issues found during validation. If no issues were found, the object will have a null 'issue' property.
 */
const validateTemplateSubsection = (
	subsection,
	sectionId,
	is_template = false,
	type = null,
	name,
) => {
	if (!subsection) {
		return {
			issue: 'noSubsection',
			messageHTML: 'There is no Subsections!',
		};
	}
	if (!sectionId) {
		return {
			issue: 'noSectionId',
			messageHTML: 'There is no sectionId!',
		};
	}
	if (!subsection.title) {
		return {
			issue: 'emptyTitles',
			elementId: `subsection-${sectionId}-${subsection.id}`,
			messageHTML: emptyTitleHTMLErrorMessageForPublish,
		};
	}
	// only in templates -->  Check if the subsection is a 'fileSection' and a 'reupload' property set to true , but no 'baseFile.name' property
	if (
		is_template &&
		subsection.fileSection &&
		subsection.reupload &&
		!subsection.baseFile.name
	) {
		return {
			issue: 'missingUpload',
			elementId:
				subsection.id !== undefined
					? `subsection-${sectionId}-${subsection.id}`
					: null,
			messageHTML: `<b>${
				subsection.title
			} is missing a file upload. </b>`,
		};
	}
	if (name.trim().length === 0) {
		return {
			issue: 'emptyName',
			messageHTML: is_template
				? TemplateNameValidationErrorMessage
				: PlanNameValidationErrorMessage,
		};
	}
	return { issue: null };
};

export { parseDataModel, validateTemplateSections, validateTemplateSubsection };
