import React from 'react';
import { Icon } from '@blueprintjs/core';
import { ActionsList } from './ActionsList';
import './css/LayerCard.scss';

export const LayerCard = ({
	floorplan,
	currentFloorplan,
	changeFloorplan,
	dragProps,
	editPermission,
	isEditMode,
	setHasChange,
	hasChange,
}) => {
	const onClickHandler = floorplan => {
		if (currentFloorplan.id === floorplan.id) {
			return;
		}
		changeFloorplan(floorplan);
	};

	const floorplanArchived = !!currentFloorplan?.archivedBy;

	return (
		<>
			<div
				className={`map-layers-card ${
					currentFloorplan.id === floorplan.id ? 'selected' : ''
				}`}
			>
				<div className="card-left-side">
					{editPermission && !floorplanArchived && isEditMode && (
						<Icon
							icon="drag-handle-vertical"
							{...dragProps}
							className="draggableIcon"
						/>
					)}
					<div
						className="card-left-side-map-info-container"
						onClick={() => onClickHandler(floorplan)}
					>
						<img src={floorplan.link} className="map-img" />
						<div className="map-card-title-container">
							<p title={floorplan.title} className="map-card-title">
								{floorplan.title}
							</p>
							{floorplan.dateArchived && (
								<span className="map-card-subtitle">Archived</span>
							)}
						</div>
					</div>
				</div>
				<div className="card-right-side">
					<ActionsList
						floorplan={floorplan}
						editPermission={editPermission}
						isEditMode={isEditMode}
						setHasChange={setHasChange}
						hasChange={hasChange}
					/>
				</div>
			</div>
		</>
	);
};
