import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormModal, DateTimePicker, DropdownMultiSelect, Button } from 'componentsV2';
import InputWrapper from 'componentsV2/InputWrapper/InputWrapper';
import { ToasterContext } from 'pages/App';
import { DateTime } from 'luxon';
import { RapidAlarmService } from 'services';
import { RapidAlarmsContext } from '../../Providers';
import { ALARM_MODE, DRILL_MODE, TRAINING_MODE } from '../../consts';
import { Intent } from '@blueprintjs/core';
import '../css/AdvancedFilterModal.scss';

export const AdvancedFilterModal = ({
	filterationData,
	setFilterationData,
	initialStates,
	onChange,
}) => {
	const [loading, setLoading] = useState(true);
	const [alarmTypes, setAlarmTypes] = useState([]);
	const [alarmModes, setAlarmModes] = useState([]);
	const [formData, setFormData] = useState({
		alarmTypes: filterationData.alarmTypes,
		alarmModes: filterationData.alarmModes,
		startDate: filterationData.startDate,
		endDate: filterationData.endDate,
		triggeredCellular: filterationData.triggeredCellular,
	});
	const formErrorsInitial = {
		endDate: '',
	};
	const [formErrors, setFormErrors] = useState(formErrorsInitial);
	const toaster = useContext(ToasterContext);
	const { closeModal } = useContext(RapidAlarmsContext);

	useEffect(() => {
		setLoading(true);
		RapidAlarmService.getAlarmTypes()
			.then(response => {
				if (response.statusCode === 200) {
					const alarmTypesList = response.data.map(item => ({
						label: item.title,
						value: item.id,
					}));
					setAlarmTypes(alarmTypesList);
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
			});

		const alarmModesList = [
			{
				label: 'Emergency mode',
				value: ALARM_MODE,
			},
			{
				label: 'Drill mode',
				value: DRILL_MODE,
			},
			{
				label: 'Training',
				value: TRAINING_MODE,
			},
		];
		setAlarmModes(alarmModesList);
	}, []);

	const onChangeHandler = val => {
		setFormData(oldValues => ({
			...oldValues,
			...val,
		}));
	};

	const formValidations = () => {
		if (formData.startDate && formData.endDate) {
			const isStartDateAfterEndDate = formData.startDate > formData.endDate;
			if (isStartDateAfterEndDate) {
				setFormErrors({
					endDate: 'Start date should be less than end date.',
				});
				return false;
			}
			setFormErrors({
				endDate: '',
			});
		}
		return true;
	};

	const onConfirmSearchHandler = () => {
		const isValid = formValidations();
		if (isValid) {
			setFilterationData(oldValues => ({
				...oldValues,
				...formData,
				page: 1,
			}));
			onChange();
			closeModal();
		}
	};

	const onResetHandler = () => {
		setFormData(oldValues => ({
			...oldValues,
			...initialStates,
		}));
		setFormErrors(formErrorsInitial);
	};

	return (
		<FormModal
			isOpen={true}
			onClose={closeModal}
			title="Advanced filter"
			size="medium"
			showCloseButton={true}
			scrollContent={false}
			loading={loading}
			confirmBtn={{
				title: 'Search',
				onClick: onConfirmSearchHandler,
				disabled: false,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: closeModal,
			}}
			leftFooter={
				<Button text="Reset" icon="reset" type="ghostPrimary" onClick={onResetHandler} />
			}
		>
			<div className="rapid-alarm-advanced-filter">
				<InputWrapper label="Alarms between" labelState={false}>
					<div className="rapid-alarm-advanced-filter-dates">
						<DateTimePicker
							placeholder="Start date"
							showTime={false}
							shortcuts={true}
							onChange={val => onChangeHandler({ startDate: val })}
							initialValue={formData.startDate}
							luxonDateFormat={DateTime.DATE_SHORT}
							errorMessage={formErrors.endDate}
						/>
						<DateTimePicker
							placeholder="End date"
							showTime={false}
							shortcuts={true}
							onChange={val => onChangeHandler({ endDate: val })}
							initialValue={formData.endDate}
							luxonDateFormat={DateTime.DATE_SHORT}
							errorMessage={formErrors.endDate}
						/>
					</div>
				</InputWrapper>
				<DropdownMultiSelect
					label="Alarm type"
					labelState={false}
					placeholder="Select alarm type"
					options={alarmTypes}
					values={formData.alarmTypes}
					onChange={val => onChangeHandler({ alarmTypes: val })}
					allowSelectAll={false}
					visibleOptions={2}
				/>
				<DropdownMultiSelect
					label="Alarm mode"
					labelState={false}
					placeholder="Select alarm mode"
					options={alarmModes}
					values={formData.alarmModes}
					onChange={val => onChangeHandler({ alarmModes: val })}
					allowSelectAll={false}
					visibleOptions={2}
				/>
				<Checkbox
					label="Triggered by 911 cellular"
					checked={formData.triggeredCellular}
					onChange={e => onChangeHandler({ triggeredCellular: e.target.checked })}
				/>
			</div>
		</FormModal>
	);
};
