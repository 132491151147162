import React from 'react';
import { Icon } from '@blueprintjs/core';
import { Tooltip, Button } from 'componentsV2';

export const AlarmSubmittedClassRow = ({ alarmId, submittedClass, history }) => {
	return (
		<div className="alarm-submitted-classes-row">
			<div className="alarm-submitted-classes-column1">
				<div className="alarm-class-info-container">
					<div className="alarm-class-icon">
						{submittedClass.found < submittedClass.totalcount ? (
							<Tooltip
								tooltipTitle={
									<>
										Some students in this class
										<br />
										do not have a status submitted
									</>
								}
							>
								<Icon icon="error" className="icon" color="#E81717" size={16} />
							</Tooltip>
						) : (
							<Icon icon="tick-circle" className="icon" color="#5DD400" size={16} />
						)}
					</div>
					<div className="alarm-class-title-container">
						<span className="alarm-class-title">{submittedClass.rostername}</span>
						<span className="alarm-class-grade">
							{submittedClass.grade && <>Grade: {submittedClass.grade}</>}
						</span>
					</div>
				</div>
			</div>
			<div className="alarm-submitted-classes-column2">
				{submittedClass.teachername && (
					<span className="alarm-class-submitted-by">{submittedClass.teachername}</span>
				)}
			</div>
			<div className="alarm-submitted-classes-column3">
				<span className="alarm-class-submitted-by">
					<Button
						className="view-class-btn"
						onClick={() =>
							history.push({
								pathname: `/active-alarms/roll-call-reunification/${alarmId}`,
								state: { selectedRoster: submittedClass },
							})
						}
					>
						View class
					</Button>
				</span>
			</div>
		</div>
	);
};
