import React from 'react';
import { ProgressBar } from 'componentsV2';

export const ReunificationDetailsCard = ({
    reunification,
}) => {
    return (
        <div className="alarm-card-container">
            <div className="alarm-card-info">
                <div className="alarm-card-title">
                    Final Reunification details
                </div>

                <div className="alarm-progress-bars-container">
                    <ProgressBar
                        label="Students reunified out of total students checked in"
                        value={reunification.totalReunifiedStudents}
                        total={reunification.totalReunifiedCheckedInStudents}
                        displayValueInPercentage={false}
                        disabled={reunification.totalReunifiedCheckedInStudents == 0}
                    />

                    <ProgressBar
                        label="Guardians reunified out of total guardians checked in"
                        value={reunification.totalReunifiedGuardians}
                        total={reunification.totalCheckedInGuardians}
                        displayValueInPercentage={false}
                        disabled={reunification.totalCheckedInGuardians == 0}
                    />
                </div>
            </div>
        </div>
    )
}
