import React from 'react';
import { formatDate } from 'utilities/dates';

const rooms = {
	All: 'Everyone',
	Admins: 'Admins',
};

export const AlarmChatMessage = ({ messageKey, message, roomName }) => {
	// formatting the message.send_date string to indicate its a UTC time.
	const formatMessageSendDate = new Date(message.send_date + 'Z');

	const senderName = name => {
		let nameArray = String(name).split(', ');
		if (nameArray.length == 2) {
			name = `${nameArray[1]} ${nameArray[0]}`;
		}
		return name;
	};

	return (
		<div className={`alarm-chat-message ${messageKey !== 0 ? 'reply-message' : ''} `}>
			<div className="chat-message-title">
				<strong>
					{String(message.sender_name).includes(',')
						? senderName(message.sender_name)
						: message.sender_name}{' '}
					{message.sender_type == 'administrator' ? '(Admin)' : ''}
				</strong>{' '}
				<span>({formatDate(formatMessageSendDate)})</span>
			</div>
			<span className="chat-message-text">{message.message}</span>
			{messageKey === 0 && (
				<span className="chat-message-sent-to">
					This message sent to{' '}
					{rooms.hasOwnProperty(roomName) ? rooms[roomName] : senderName(roomName)}{' '}
				</span>
			)}
		</div>
	);
};
