import React from 'react';
import { ActionsButton, Button } from 'componentsV2';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';

export const TableActions = ({
	drill,
	loadDeleteModal,
	loadInstructionsModal,
	addDrillToCalendar,
	history,
}) => {
	const permissions = useSelector(state => state.route.permissions);
	const deletePermission = checkPermission('drilllogs_delete', permissions, drill.site_id);
	const hasDrillLogPermission = checkPermission('drilllogs_log', permissions, drill.site_id);
	const hasViewLogPermission = checkPermission('drilllogs_view', permissions, drill.site_id);
	const isSysAdmin = permissions?.isSysAdmin;

	const isScheduledDrill = drill.status !== 'N/A';
	const isViewDrillLog =
		!isSysAdmin &&
		hasViewLogPermission &&
		!hasDrillLogPermission &&
		drill?.status === 'Completed';

	const handleLogDrill = ({ year, scheduleId }) => {
		history.push(`/drillsV2/log/${year}/${scheduleId}`);
	};

	return (
		<ActionsButton>
			{hasDrillLogPermission && isScheduledDrill && (
				<Button
					text={drill?.status === 'Completed' ? 'View Log' : 'Log drill'}
					icon="time"
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => handleLogDrill(drill)}
				/>
			)}
			{isViewDrillLog && (
				<Button
					text="View Log"
					icon="time"
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => handleLogDrill(drill)}
				/>
			)}
			{isScheduledDrill && (
				<Button
					text="Add to calendar"
					icon="calendar"
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => {
						addDrillToCalendar(drill);
					}}
				/>
			)}
			<Button
				text="Instructions"
				icon="document"
				type="ghostDefault"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => {
					loadInstructionsModal(drill);
				}}
			/>
			{deletePermission && isScheduledDrill && (
				<Button
					text="Delete"
					icon="trash"
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => {
						loadDeleteModal(drill);
					}}
				/>
			)}
		</ActionsButton>
	);
};
