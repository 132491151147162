import React, { useContext, useState } from 'react';
import { ConfirmationModal, ToasterContext } from 'componentsV2';
import { RollCallAndReunificationContext } from '../RollCallAndReunification';
import { Intent } from '@blueprintjs/core';
import { RollCallDetailsContext } from '../../RollCallDetails';
import { ReunificationService } from 'services';

export const ReunifyStudentModal = () => {
	const { alarmId } = useContext(RollCallDetailsContext);
	const { closeModal, selectedStudent } = useContext(RollCallAndReunificationContext);
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);

	const reunifyStudent = selectedStudent.reunified ? false : true;

	const title = reunifyStudent
		? 'Are you sure you want to reunify this student with their guardian?'
		: 'Are you sure you want to cancel the reunification of this student with their guardian?';
	const subTitle = reunifyStudent
		? 'Once reunified, you can still cancel the reunification at anytime.'
		: 'Once reunification is canceled, you can still reunify them at anytime.';
	const confirmBtnTitle = reunifyStudent ? 'Reunify' : 'Cancel Reunification';

	const reunifyStudentHandler = () => {
		setLoading(true);
		const body = {
			studentId: selectedStudent.id,
			guardianCheckInId: selectedStudent.guardianCheckIn[0]
				? selectedStudent.guardianCheckIn[0].id
				: null,
			reunify: reunifyStudent,
		};
		ReunificationService.reunifyStudentStatus(alarmId, body)
			.then(res => {
				if (res.statusCode === 200) {
					const studentName = `${selectedStudent.firstName} ${
						selectedStudent.middleInitial
					} ${selectedStudent.lastName}`;
					const guardianName = selectedStudent.guardianCheckIn[0]
						? selectedStudent.guardianCheckIn[0].name
						: '';
					const successMsg = reunifyStudent
						? `You have successfully reunified the student ${studentName} with their guardian ${guardianName}`
						: `You have successfully canceled the reunification of the student ${studentName} with their guardian ${guardianName}`;
					toaster(successMsg, Intent.SUCCESS);
				}
			})
			.catch(err => {
				toaster(err?.error.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	return (
		<ConfirmationModal
			isOpen={true}
			title={title}
			subtitle={subTitle}
			showCloseButton={false}
			cancelBtn={{
				title: 'Go back',
				onClick: closeModal,
			}}
			confirmBtn={{
				title: confirmBtnTitle,
				onClick: reunifyStudentHandler,
			}}
			loading={loading}
		/>
	);
};
