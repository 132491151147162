import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Input, FileUpload, Badge, Tooltip} from 'componentsV2';
import './css/VariablesFieldsForm.scss';
import {Position} from '@blueprintjs/core';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

export const VariablesFieldsForm = ({
                                      variables,
                                      templateId,
                                      loadData,
                                      setLoading,
                                      setDisableForm,
                                      variableFormData,
                                      dispatchVariableFormData
                                    }) => {

  useEffect(() => {
    setLoading(true);
    variables
      .filter((variable) => variable.value)
      .map((variable, index) => {
        if (variable.parsedContent.typeAnnotation === "Image") {
          dispatchVariableFormData({
            type: 'UPDATE_VARIABLES',
            payload: {
              name: variable.name,
              value: variable.value.id,
              type: 'image',
              file: variable.value,
              fileObj: variable.value
            },
          });
        } else {
          dispatchVariableFormData({
            type: 'UPDATE_VARIABLES',
            payload: {name: variable.name, value: variable.value, type: 'text', file: null},
          });
        }
        return null;
      });
    setLoading(false);
  }, [variables]);

  const handleFileDelete = (name, e) => {
    dispatchVariableFormData({
      type: 'DELETE_VARIABLES',
      payload: {name: name},
    });
    setDisableForm(false);
  }
  const handleFileChange = async (name, e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onload = (f) => {
      const {result} = f.target;
      if (result) {
        const file = {fileName: e.name, fileSize: e.size, fileType: e.type}
        dispatchVariableFormData({
          type: 'UPDATE_VARIABLES',
          payload: {
            name: name,
            value: result,
            file: file,
            type: 'image',
            fileObj: {id: e.name, fileName: e.name, size: e.size, contentType: e.type}
          },
        });
      }
    }
    setDisableForm(false);
  };

  const handleInputChange = (name, value) => {
    dispatchVariableFormData({
      type: 'UPDATE_VARIABLES',
      payload: {name: name, value: value, file: null, type: 'text'},
    });
    setDisableForm(false);
  };

  const getValueByName = (name) => {
    if (variableFormData.variables.length > 0) {
      const variable = variableFormData.variables.find((variable) => variable.name == name);
      return variable ? (variable.type == 'text' ? variable.value : variable.fileObj) : null;
    }
  };

  const parseFileData = (file) => {
    if (file?.id) {
      return {
        id: file.id,
        name: file.fileName,
        size: file.size,
        type: file.contentType
      }
    }
  }
  const getUsageBadge = (places, references) => {
    if (references.length > 0) {
      return (
        <Tooltip
          tooltipTitle="References"
          position={Position.RIGHT}
          tooltipContent={
            <>
              <div className="usage-badge-content">
                This is repeated in the following areas:
              </div>
              {references.map((reference, index) => (
                <div className="usage-badge-content" key={index}>
                  {reference}
                </div>
              ))}
            </>
          }
          theme="light"
        >
          <Badge
            key="sm-Primary"
            type="Primary"
            size="sm"
            text={`Used ${references.length} times in ${places} place(s)`}
            hasLeadIcon={false}
            hasCloseIcon={false}
          />
        </Tooltip>
      );
    } else {
      // Return Badge without Tooltip if there are no references
      return (
        <Badge
          key="sm-Primary"
          type="Primary"
          size="sm"
          text={`Used in ${places} place(s)`}
          hasLeadIcon={false}
          hasCloseIcon={false}
        />
      );
    }
  };

  return (<>
      <div className="single-variable">
        {variables.map((variable, index) => (
          variable.references.length > 0 && (
            <div key={index} className="col-md-12">
              {variable.parsedContent.typeAnnotation === "Image" ? (
                <FileUpload
                  identifier={`${variable.name} - ${index}`}
                  disabled={false}
                  labelState={false}
                  allowedFiles={['png', 'jpeg', 'jpg', 'svg', 'gif', 'GIF', 'SVG', 'JPG', 'JPEG', 'PNG']}
                  maxFileSize={MAX_FILE_UPLOADER_SIZE}
                  label={variable.parsedContent.name}
                  RightSideItem={getUsageBadge(variable.places, variable.references)}
                  hintText={variable.parsedContent.helpText ? `Add the ${variable.parsedContent.helpText}` : undefined}
                  allowedFilesLegend={`SVG, PNG, JPG or GIF (max. ${MAX_FILE_UPLOADER_SIZE}mb)`}
                  showProgressBar={true}
                  showReplaceAction={true}
                  fileForEditing={true}
                  file={variable.value ? parseFileData(getValueByName(variable.name)) : null}
                  onFileInputChange={(e) => handleFileChange(variable.name, e)}
                  showDeleteBtn={true}
                  onDeleteFile={(e) => handleFileDelete(variable.name, e)}
                />
              ) : (
                <Input
                  label={variable.parsedContent.name}
                  RightSideItem={getUsageBadge(variable.places, variable.references)}
                  placeholder={variable.parsedContent.name}
                  labelState={false}
                  isRequired={false}
                  hasHintText={!!variable.parsedContent.helpText}
                  hintText={variable.parsedContent.helpText ? `Add the ${variable.parsedContent.helpText}` : undefined}
                  value={getValueByName(variable.name)}
                  onChange={e => handleInputChange(variable.name, e.target.value)}
                />
              )}
            </div>
          )
        ))}
      </div>
    </>
  );
};

VariablesFieldsForm.propTypes = {
  variables: PropTypes.array.isRequired,
  template: PropTypes.object.isRequired,
};
