import React from 'react';
import Step1 from "./FirstRespondersMultiStepForm/MultiStep1";
import Step2 from "./FirstRespondersMultiStepForm/MultiStep2";
import Submit from "./FirstRespondersMultiStepForm/MultiStepSubmit";

const FirstResponderStepsHandler = ({
	currentStep,
    formData,
    next,
    updateFormData,
    selectedMiles,
    validationErrors,
    setSelectedMiles,
    setErrors,
    setDisableBtns,
    setresetDisableHandler,
    highlightedIndex,
    selectedIndex,
    agencies,
    back,
    loading,
    allowedSites,
    onSelectSitesHandler,
    selectedSites,
    sitesFRList,
    selectedUserSites,
    responderByEmailExist,
    firstResponderData,
    backToLatest,
    existMailSitesHandler,
    existResponderAccessedSites
}) => {
    switch (currentStep) {
		  case 1:
         return <Step1 
            data={formData} 
            next={next} 
            updateFormData={updateFormData}
            selectedMiles={selectedMiles}
            validationErrors={validationErrors}
            setSelectedMiles={setSelectedMiles}
            formData={formData}
            setErrors={setErrors}
            setDisableBtns={setDisableBtns}
            setresetDisableHandler={setresetDisableHandler}
            loading={loading}
        />
      case 2 :
        return <Step2
        data={formData}
        next={next}
        back={back}
        updateFormData={updateFormData}
        highlightedIndex={highlightedIndex}
        selectedIndex={selectedIndex}
        agencies={agencies}
        formData={formData}
        setErrors={setErrors}
        loading={loading}
      />
      case 3:
        return <Submit
						data={formData}
						next={next}
						back={back}
						updateFormData={updateFormData}
						highlightedIndex={highlightedIndex}
						agencies={agencies}
						selectedIndex={selectedIndex}
						formData={formData}
						setErrors={setErrors}
						validationErrors={validationErrors}
            allowedSites={allowedSites}
            selectedSites={selectedSites}
            onSelectSitesHandler={onSelectSitesHandler}
            sitesFRList={sitesFRList}
            selectedUserSites={selectedUserSites}
            responderByEmailExist={responderByEmailExist}
            firstResponderData={firstResponderData}
            backToLatest={backToLatest}
            existMailSitesHandler={existMailSitesHandler}
            existResponderAccessedSites={existResponderAccessedSites}
            loading={loading}
					/>
        }
};

export default FirstResponderStepsHandler
