import React, { useEffect, useContext, createContext, useState } from 'react';
import { Tab, Intent } from '@blueprintjs/core';
import { Tooltip, TooltipPosition, ToasterContext, CustomTabs } from 'componentsV2';
import './css/RollCallAndReunification.scss';
import { REUNIFICATION_TAB, ROLLCALL_TAB, RollCallTabContent } from './';
import { Reunification } from './Reunification';

export const RollCallAndReunificationContext = createContext();

export const RollCallAndReunification = ({
	alarm,
	socketEventsListeners,
	history,
	rosterUpdateEvent,
	activeTabId,
	setActiveTabId,
	selectedRoster,
}) => {
	const [requestId, setRequestId] = useState(Math.floor(Math.random() * 100001));
	const toaster = useContext(ToasterContext);
	const [modalToShow, setModalToShow] = useState(null);
	const [selectedStudent, setSelectedStudent] = useState(null);
	const rollCallNotStarted = !socketEventsListeners.isRollCallStarted;
	const rollCallEnded =
		socketEventsListeners.isRollCallStarted && socketEventsListeners.isRollCallEnded;
	const reunificationNotStarted = !socketEventsListeners.isReunificationStarted;
	const reunificationEnded =
		socketEventsListeners.isReunificationStarted && socketEventsListeners.isReunificationEnded;

	const closeModal = () => {
		setModalToShow(null);
	};

	useEffect(() => {
		if (
			alarm &&
			(rollCallNotStarted || rollCallEnded) &&
			(reunificationNotStarted || reunificationEnded)
		) {
			history.push(`/active-alarms/${alarm.id}`);
			toaster('Roll Call and Reunification are not activated for this alarm.', Intent.DANGER);
		}
	}, [alarm, socketEventsListeners]);

	const RollCallTooltipTitle = rollCallEnded
		? 'Roll Call ended.'
		: rollCallNotStarted
		? 'Roll Call is not activated for this alarm.'
		: null;
	const ReunificationTooltipTitle = reunificationEnded
		? 'Reunification ended.'
		: reunificationNotStarted
		? 'Reunification is not activated.'
		: null;

	const tabs = [
		{
			id: ROLLCALL_TAB,
			title: 'Roll Call',
			panel: (
				<RollCallTabContent
					alarm={alarm}
					rosterUpdateEvent={rosterUpdateEvent}
					selectedRoster={selectedRoster}
				/>
			),
			index: 0,
			disabled: RollCallTooltipTitle ? true : false,
			tooltipProps:
				RollCallTooltipTitle !== null
					? {
							tooltipTitle: RollCallTooltipTitle,
							theme: 'dark',
							position: TooltipPosition.BOTTOM,
					  }
					: null,
		},
		{
			id: REUNIFICATION_TAB,
			title: 'Reunification',
			panel: <Reunification />,
			index: 1,
			disabled: ReunificationTooltipTitle ? true : false,
			tooltipProps: ReunificationTooltipTitle
				? {
						tooltipTitle: ReunificationTooltipTitle,
						theme: 'dark',
						position: TooltipPosition.BOTTOM,
				  }
				: null,
		},
	];

	const panelLoader = index => {
		return tabs[index].panel || null; // Return null if no panel content
	};
	const exportedData = {
		reunificationNotStarted,
		reunificationEnded,
		modalToShow,
		setModalToShow,
		closeModal,
		selectedStudent,
		setSelectedStudent,
		alarm,
		requestId,
	};

	return (
		<RollCallAndReunificationContext.Provider value={exportedData}>
			<div className="roll-call-and-reunification-container">
				<CustomTabs
					id="roll-call-and-reunification-tabs"
					onChangeHandler={setActiveTabId}
					renderActiveTabPanelOnly={true}
					selectedTabId={activeTabId}
				>
					{tabs.map(tab => (
						<Tab
							id={tab.id}
							key={tab.index}
							panel={panelLoader(tab.index)}
							disabled={tab.disabled}
							className="CustomTabs__Tab"
						>
							{tab.tooltipProps ? (
								<Tooltip {...tab.tooltipProps}>{tab.title}</Tooltip>
							) : (
								tab.title
							)}
						</Tab>
					))}
				</CustomTabs>
			</div>
		</RollCallAndReunificationContext.Provider>
	);
};
