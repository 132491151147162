import React, { useState } from "react";
import { Icon } from '@blueprintjs/core';
import './index.css';

const ActionsAddButton = ({ 
    text
}) => {

    return (
        <><Icon icon='plus'> </Icon> <span className="btn-text" style={{fontWeight:"var(--font-weight)"}}>  {text}</span></>
    )
}

export default ActionsAddButton;