import React, { useContext } from 'react';
import { FieldCard, FlipchartDropDown, FlipchartPreview } from '../SharedFields';
import { CreateAlarmTypeContext } from '../../../../../../provider';
import './css/Flipchart.scss';

export const Flipchart = () => {
	const { formData, formErrors, onChangeFormData } = useContext(CreateAlarmTypeContext);

	return (
		<FieldCard
			header="Flipcharts"
			description={
				<>
					Choose the Flipchart that staff and administrators should reference during this
					type of emergency.
					<br />
					<br />
					Only published Flipcharts set as “Visible to All” will appear in this list.
				</>
			}
			loading={false}
			style={{
				height: '100%',
			}}
		>
			<div className="row AlarmType_Flipchart_Wrapper">
				<div className="col-md-5 flipChart_DropDown">
					<FlipchartDropDown
						formData={formData}
						formErrors={formErrors}
						onChangeFormData={onChangeFormData}
					/>
				</div>
				<div className="col-md-3 AlarmType_Button_Wrapper">
					<FlipchartPreview formData={formData} />
				</div>
			</div>
		</FieldCard>
	);
};
