import React from 'react';
import { useEffect, useReducer, useContext } from 'react';
import { useSelector } from "react-redux";
import { BuildingsService, BinderService } from "services";
import { ToasterContext, Intent } from 'componentsV2';
import { filterSitesPerPermissionCode, checkPermissionOnDistrict } from "utilities/permissions";
import useSelectedSites from './useSelectedSites';
import { SiteVisibilityError } from 'utilities/helper';

const binderReducer = (data, action) => {
    data[action.type] = action.payload;
    return {
        ...data
    }
}

export const useBinderForm = (
    binder,
    setModalIsOpen,
    selectedSites,
    onSubmitFinished
) => {
    const toaster = useContext(ToasterContext);
    const permissions = useSelector(state => state.route.permissions);
    const selectedSitesArray = useSelectedSites();
    const hasPermissionOnDistrict = checkPermissionOnDistrict('flipcharts_edit');

    // Initialize the reducer and set Initial values
    const [formData, dispatchFormData] = useReducer(binderReducer, {
        name: binder ? binder.name : "",
        siteOptions: null,
        siteSelected: null,
        siteValidationError: null,
        color: binder ? `#${binder.color}` : "",
        description: binder ? binder.description : "",

        disableSubmit: true,
        modalLoading: false,
    });

    useEffect(() => {
        getInitialFormData();
    }, []);

    const getInitialFormData = () => {
        dispatchFormData({ 'type': 'modalLoading', 'payload': true });
        BuildingsService.getAll(false, false).then(serviceBuildings => {
            let sites = filterSitesPerPermissionCode('vb_edit', permissions, serviceBuildings);
            if (hasPermissionOnDistrict) {
                sites.unshift({ label: 'Visible to all', value: null });
            }
            dispatchFormData({ 'type': 'siteOptions', 'payload': sites });
            setDefaultSelectedSite(sites);
        }).then(() => {
            dispatchFormData({ 'type': 'modalLoading', 'payload': false });
        })
    }

    const setDefaultSelectedSite = (sites) => {
        let selectedSite = null;

        if (binder) {
            selectedSite = {
                value: binder.building ? binder.building.id : null,
                label: binder.building ? binder.building.name : 'Visible to all',
            }
        } else {
            if (selectedSites && selectedSites.length == 1) {
                selectedSite = sites.find(site => site.id === selectedSites[0]);
            }
        }

        if (selectedSite) {
            dispatchFormData({ 'type': 'siteSelected', 'payload': selectedSite });
            return;
        }

        dispatchFormData({ 'type': 'siteSelected', 'payload': sites[0] });
    }

    // Validate the form
    // Enable / Disable the Submit button
    useEffect(() => {
        let validName = formData.name.trim().length > 0;
        let validColor = formData.color && formData.color.length >= 6;
        let disableSubmitResult = !validName || !validColor || formData.siteValidationError;
        dispatchFormData({ 'type': 'disableSubmit', 'payload': disableSubmitResult });
    }, [
        formData.name,
        formData.siteSelected,
        formData.color,
    ]);

    const handleChangeName = ({ target }) => {
        dispatchFormData({ 'type': 'name', 'payload': target.value });
    }

    const handleChangeSite = (option) => {
        dispatchFormData({ 'type': 'siteSelected', 'payload': option });
        dispatchFormData({ 'type': 'siteValidationError', 'payload': null });
    }


    const handleChangeDescription = ({ target }) => {
        dispatchFormData({ 'type': 'description', 'payload': target.value });
    }

    const handleSubmit = async () => {
        try {
            // Disable the button to avoid multiple request
            // And disable all the form controls
            dispatchFormData({ 'type': 'disableSubmit', 'payload': true });
            dispatchFormData({ 'type': 'modalLoading', 'payload': true });

            let body = {
                name: formData.name.trim(),
                site_id: formData.siteSelected ? formData.siteSelected.value : null,
                color: formData.color.replace('#', ''),
                description: formData.description?.trim(),
            };

            if (binder) { // Update Binder
                const response = await BinderService.update(binder.id, body);
                if (response.statusCode == 200) {
                    toaster(
                        `You have successfully modified “${formData.name}” details!`,
                        Intent.SUCCESS
                    );
                    setModalIsOpen(false);
                    onSubmitFinished(binder.id);
                }
            } else { // Add new Binder
                const response = await BinderService.add(body);
                if (response.statusCode == 201) {
                    toaster(
                        `You have successfully added the “${formData.name}” as a new binder!`,
                        Intent.SUCCESS
                    );
                    setModalIsOpen(false);
                    onSubmitFinished(response.data.id);
                }
            }
        } catch ({ error }) {
            if (error.type == "BAD_REQUEST" && error.fields && error.fields.site_id) {
                dispatchFormData({ 'type': 'modalLoading', 'payload': false });
                dispatchFormData({ 'type': 'siteValidationError', 'payload': <SiteVisibilityError errors={error.fields} /> });
            } else {
                setModalIsOpen(false);
                let message = error && error.description
                    ? error.description
                    : 'An error occurred while adding a tab to the flipchart. Please contact the administrator.';
                toaster(
                    message,
                    Intent.DANGER
                );
            }
        }
    }

    return {
        formData,
        dispatchFormData,
        handleChangeName,
        handleChangeSite,
        handleChangeDescription,
        handleSubmit,
    }
}