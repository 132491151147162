/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { Dropdown } from 'components/Dropdown';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import {
	SchoolYearsService,
	DrillTypesService,
	DrillsStatsService,
} from 'services';

const Page = styled.div`
	min-height: 100vh;

	.dropdown-filters {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.dropdown {
			margin-right: 24px;
			margin-bottom: 16px;
			width: calc(33% - 24px);
		}
	}

	table {
		.red {
			color: #9f1c3a;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			font-style: normal;
			margin-right: 10px;
			letter-spacing: 0px;
			font-family: Nunito Sans;
		}

		.grey {
			color: #738694;
			font-size: 12px;
			font-weight: 400;
			line-height: 15px;
			font-style: italic;
			letter-spacing: 0px;
			font-family: Nunito Sans;
		}

		.drill-statuses {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.pilsner {
				max-width: initial;
				margin-left: 0;
				margin-right: 8px;
				margin-bottom: 8px;
			}
		}
	}

	.table-description {
		margin: 0;
		color: #10161a;
		font-size: 18px;
		font-weight: 700;
		line-height: 21px;
		font-style: normal;
		margin-right: 24px;
		letter-spacing: 0.3px;
		font-family: Merriweather;
	}
`;

const BASE_VIEW = {
	selectedSchoolYear: null,
	selectedDrillType: null,

	tableLoading: false,
	stats: null,
	overviewStats: null,
};

const BASE_STATE = {
	...BASE_VIEW,
	loading: true,
	schoolYears: null,
	drillTypes: null,
	buildingName: null,
};

export default class ViewSchoolStats extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		const {
			location: {
				state: { buildingName },
			},
		} = this.props;

		Promise.all([
			SchoolYearsService.fetchYears(),
			DrillTypesService.getAll(true),
		])
			.then(([schoolYears, drillTypes]) => {
				this.setState({
					...BASE_STATE,
					schoolYears,
					drillTypes,
					buildingName,
					loading: false,
				});
			})
			.catch(() => {
				this.setState({
					...BASE_STATE,
					loading: false,
					error: 'Could not load this page, please try refreshing',
				});
				Swal.fire({
					title: 'Oops!',
					text: 'Could not load this page. Please try refreshing',
					icon: 'error',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
			});
	}

	setDrillType = selectedDrillType => {
		const { selectedSchoolYear } = this.state;
		const {
			location: {
				state: { buildingId },
			},
		} = this.props;

		this.setState(
			{
				selectedDrillType,
				overviewStats: null,
				stats: null,
				tableLoading: true,
			},
			() => {
				DrillsStatsService.fetchMonthlyStats(
					selectedSchoolYear.value,
					selectedDrillType.uuid,
					buildingId,
				)
					.then(data => {
						this.setState({
							tableLoading: false,
							stats: data.monthlyStats,
							overviewStats: data.overview,
						});
					})
					.catch(() => {
						this.setState({
							tableLoading: false,
						});
						Swal.fire({
							title: 'Oops!',
							text:
								'Could not get any statistics. Please try again',
							icon: 'error',
							toast: true,
							position: 'top',
							timer: 3000,
							showConfirmButton: false,
						});
					});
			},
		);
	};

	render() {
		const {
			loading,
			error,
			schoolYears,
			drillTypes,
			buildingName,
			selectedSchoolYear,
			selectedDrillType,
			overviewStats,
			stats,
			tableLoading,
		} = this.state;

		const { history } = this.props;

		if (loading) return <LoadingSpinner />;

		if (error) return error;

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title={buildingName}
					onBackBtnClick={history.goBack}
				/>

				{/* drop downs */}
				<div className="dropdown-filters">
					{/* School year */}
					<Dropdown
						label="School year"
						placeholder="Select a school year"
						value={selectedSchoolYear}
						options={schoolYears}
						onChange={item =>
							this.setState({
								selectedSchoolYear: item,
								selectedDrillType: null,
								stats: null,
								overviewStats: null,
							})
						}
					/>

					{selectedSchoolYear && (
						/* Drill type */
						<Dropdown
							label="Drill type"
							placeholder="Select a Drill type"
							value={selectedDrillType}
							options={drillTypes}
							onChange={this.setDrillType}
						/>
					)}
				</div>

				{stats && (
					/* table description */
					<div className="table-description">
						<h4>Drill statistics</h4>
					</div>
				)}

				{tableLoading && (
					/* loading spinner while re-fetching data */
					<div style={{ marginTop: 20 }}>
						<LoadingSpinner />
					</div>
				)}

				{overviewStats && (
					/* overview table */
					<Table
						headers={['Overall', selectedSchoolYear.label]}
						data={[
							{ key: 'completed', label: 'Total completed' },
							{ key: 'scheduled', label: 'Total scheduled' },
							{ key: 'required', label: 'Required' },
						]}
						renderItem={(item, index) => {
							return (
								<>
									<td>{item.label}</td>

									<td>
										<strong>
											{overviewStats[item.key]}
										</strong>
									</td>
								</>
							);
						}}
					/>
				)}

				{stats &&
					/* tables by year */
					Object.keys(stats).map(item => (
						<Table
							headers={[
								`Months in ${item}`,
								'Completed',
								// 'Drill types completed',
							]}
							data={Object.keys(stats[item])}
							renderItem={subItem => {
								return (
									<>
										<td>{subItem}</td>

										<td>
											{stats[item][subItem]['completed']}{' '}
											of {stats[item][subItem]['total']}
										</td>
									</>
								);
							}}
						/>
					))}
			</Page>
		);
	}
}

ViewSchoolStats.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
};
