import React from 'react';
import { ActionsButton, Button } from 'componentsV2';

const AddContactButton = ({ setModalToShow }) => {
	return (
		<ActionsButton
			elementSelector={<Button text="Add contacts" size="lg" rightIcon="chevron-down" />}
		>
			<Button
				medium
				text="Attach EMS contacts"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => setModalToShow('CallListExistingContactsModal')}
			/>
			<Button
				medium
				text="Import multiple contacts"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => setModalToShow('bulkExternalContacts')}
			/>
			<Button
				medium
				text="Add external contact"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => setModalToShow('ExternalContactModal')}
			/>
		</ActionsButton>
	);
};

export default AddContactButton;
