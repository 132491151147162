import React , { useState, useRef,useCallback,useEffect } from "react";
import CardView from "components/CardView";
import { phone } from 'assets/icons';
import { CallListService } from "services/CallListService";
import CallListsActionsButton from "./CallListsActionsButton";


const GridTable = ({
    filterationData,
    dispatchFilterationData,
    listView,
    loadEditModal,
    setCallListID,
    setModalToShow,
    refetchData,
    history
}) => {
    const [gridList, setGridList] = useState([]);
    const endOfList = useRef(false);
    const pageNumber = useRef(1)
    const fetchGridList = async () => {
        CallListService.getAll(filterationData).then(resp => {
                if (resp.statusCode === 200) {
                    if (resp.data.length === 0) {endOfList.current = true;}else{endOfList.current = false}
                    if(resp._metadata.currentPage === 1 && resp.data.length !== 0) {
                        setGridList(resp.data);
                        pageNumber.current = 2;
                        
                    } else {
                        setGridList(oldGrid => [
                            ...oldGrid, ...resp.data
                        ])
                    }
                }
            });
      };


    const handleScroll = useCallback(
        _.debounce(async () => {
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;
          const documentHeight = document.documentElement.scrollHeight;
          const scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop;
    
          if (scrollTop + windowHeight >= documentHeight) {
            // User has reached the bottom of the page, load more results
            if(!endOfList.current)
            {
                dispatchFilterationData({ type: "page", payload: pageNumber.current });
                pageNumber.current += 1;
            }

          }
        }, 500), // Set the debounce delay to 500 milliseconds
        []
      );


    useEffect(() => {
        if (!listView) {
          window.addEventListener("scroll", handleScroll);
          return () => {
            window.removeEventListener("scroll", handleScroll);
          };
        }
      }, [listView, handleScroll]);


      useEffect(() => {
        fetchGridList();
  }, [filterationData, refetchData]);

  useEffect(() => {
    dispatchFilterationData({ type: "page", payload: pageNumber.current });
}, []);

  const handleActionButton = (event,clickEvent, item ) => {
    if (clickEvent == 'clicked') {
      history.push("/contacts/"+ item.id);
    } else {
      event.stopPropagation(); 
    }
  }



  return (
        gridList.map((item,index) =>(
          <div className="col-md-6 col-lg-4">
            <a className="gridListAnchor" href="#" onClick={ (e)=> handleActionButton(e,'clicked', item)}
            >
              <CardView 
              key={item.id}
              thumbnail={phone}
              item = {item}
              isLastElement={index + 1 === gridList.length }
              actionButton={
                 <span
                 onClick={(e) => {handleActionButton(e, 'unClicked', item)}} // Pass the click handler to the button 
                 >
                  <CallListsActionsButton 
                    row={item}
                    loadEditModal={loadEditModal}
                    setCallListID={setCallListID}
                    setModalToShow={setModalToShow}
                    
                  />
                 </span>

              }
              > 
                  <strong>{item.title}</strong>
                  <p>{(item.site) ? (item.site) : ' '}</p>
                  <p>Number of contacts: <strong>{item.numberOfContracts}</strong></p>
              </CardView>
            </a> 
            </div> 
          )
      )

  );

}
export default GridTable;