import React from 'react';
import { RadioButtonGroup } from 'componentsV2';
import { Radio } from '@blueprintjs/core';
import PropTypes from 'prop-types';

export const RollcallRadioGroup = ({
	formData,
	formErrors,
	onChangeFormData,
	disabled = false,
}) => {
	const onToggleRollcallHandler = e => {
		const value = JSON.parse(e.target.value);
		onChangeFormData({ type: 'hasRollCall', payload: value });
	};
	const selectedValue = formData.hasRollCall !== null ? formData.hasRollCall : null;

	return (
		<RadioButtonGroup
			label={null}
			onChange={onToggleRollcallHandler}
			selectedValue={selectedValue}
			size="small"
			bordered={false}
			required={true}
			layout="inline"
			error={formErrors?.hasRollCall}
			disabled={disabled}
		>
			<Radio label="Yes" value={true} />
			<Radio label="No" value={false} />
		</RadioButtonGroup>
	);
};

RollcallRadioGroup.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
};
