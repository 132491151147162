import { HttpService, buildRoute } from 'networking/HttpService';

export const DrillEmailRemindersService = {
	fetch: (buildingId = null) => {
		let route = '/drills/reminders';
		if (buildingId) {
			route += `?buildingId=${buildingId}`;
		}
		return HttpService({
			method: 'GET',
			route,
		}).then(res => {
			if (Array.isArray(res?.data)) {
				return res.data;
			}
			throw new Error('failed request');
		});
	},

	add: (buildingId, userIds) =>
		HttpService({
			method: 'POST',
			route: '/drills/reminders',
			body: {
				buildingId,
				userIds,
			},
		}).then(res => {
			if (Array.isArray(res?.data)) {
				return res.data;
			}
			throw new Error('failed request');
		}),

	remove: reminderId => {
		const route = buildRoute('/drills/reminders/:reminderId', {
			reminderId,
		});
		return HttpService({
			method: 'DELETE',
			route,
		}).then(res => {
			if (res?.data === 'success') {
				return true;
			}
			throw new Error('failed to delete');
		});
	},

	fetchEmailSettings: () =>
		HttpService({
			method: 'GET',
			route: '/drill-email-settings',
		}).then(res => {
			if (Array.isArray(res?.data)) {
				return res.data;
			}

			throw new Error('failed to fetch');
		}),

	fetchResponders: (buildingIds = []) => {
		let route = '/responders';
		if (buildingIds) {
			const buildings = buildingIds.map(buildingId => `buildingId[]=${buildingId}`).join('&');
			route += `?${buildings}`;
		}
		return HttpService({
			method: 'GET',
			route,
		}).then(res => {
			if (Array.isArray(res?.data)) {
				return res.data;
			}

			throw new Error('failed to fetch');
		});
	},

	updateDrillEmailSettings: buildings =>
		HttpService({
			method: 'POST',
			route: '/drill-email-settings',
			body: {
				buildings,
			},
		}).then(res => {
			if (Array.isArray(res?.data)) {
				return res.data;
			}

			throw new Error('failed to fetch');
		}),
};
