import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RequiredWraper } from 'components/Forms/shared.js';
import { LabelText } from 'components/Forms/shared.js';

const Wrapper = styled.div`
	margin-bottom: 16px;
	width: ${props => props.width || '350px'};

	textarea {
		margin 0px;
		width: 100%;
		color: #182026;
		font-size: 16px;
		min-height: 70px;
		font-weight: 400;
		padding: 10px 10px;
		border-radius: 3px;
		box-shadow: none !important;
		border: 1px solid #d8e1e8 !important;
		transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
	}

	.error {
		border: 1px solid var(--error-color) !important;
	}
	textarea:focus {
		outline: none;
	}
`;

export const TextArea = ({
	label = null,
	placeholder = 'Enter a value',
	value = null,
	onChange = null,
	disabled = false,
	width = null,
	error = false,
	errorMessage = null,
	isRequired,
}) => {
	if (typeof onChange !== 'function')
		throw new Error("'onChange' is not defined for this text area");

	return (
		<Wrapper className="text-area" width={width}>
			{label && 
			<LabelText>
				{label}
				{isRequired && <RequiredWraper>Required</RequiredWraper>}
			</LabelText>}

			<textarea
				className={`textarea ${error ? 'error' : ''}`}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				disabled={disabled}
			/>

			{error && <p className="error-text">{errorMessage}</p>}
		</Wrapper>
	);
};

TextArea.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.bool,
	errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
