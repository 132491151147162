import React from 'react';
import PropTypes from 'prop-types';
import { Card, Elevation } from '@blueprintjs/core';
import styled from 'styled-components';
import { decodeToken } from 'utilities';
import { Button } from '../../components/Button';

const CardWrapper = styled(Card)`
	width: 85%;
	align-self: center;
	margin-top: 15%;
`;

const CardTitle = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 32px;

	text-align: center;
	letter-spacing: 0.3px;

	padding-top: 30px;
`;
const CardSubTitle = styled.h4`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
    padding-top: 20px;
	text-align: center;
`;

const ButtonWrapper = styled.div`
    padding-top: 30px;
    padding-bottom: 20px;
	text-align: center;
`;

const FEATURES = {
	calllist: {
		buttonText: 'Switch to Call List within Classic EMS',
		moduleText:
			"Soon you will be able to manage contact information within the new EMS. We'll let you know as soon as its ready!",
	},
	documents: {
		buttonText: 'Switch to Documents  within Classic EMS',
		moduleText:
			"Soon you will be able to store files securely in the cloud within the new EMS. We'll let you know as soon as its ready!",
	},
	maps: {
		buttonText: 'Switch to Maps & Floorplans within Classic EMS',
		moduleText:
			"Soon you will be able to create and view interactive floor plans  within the new EMS. We'll let you know as soon as its ready!",
	},
	binders: {
		buttonText: 'Switch to Virtual Binders within Classic EMS',
		moduleText:
			"Soon you will be able to easily build and organize safety information within the new EMS. We'll let you know as soon as its ready!",
	},
	flipcharts: {
		buttonText: 'Switch to Flipcharts within Classic EMS',
		moduleText:
			"Soon you will be able to create and access emergency procedures within the new EMS. We'll let you know as soon as its ready!",
	},
	scenario: {
		buttonText: 'Switch to Scenarios within Classic EMS',
		moduleText:
			"Soon you will be able to manage emergency scenarios within the new EMS. We'll let you know as soon as its ready!",
	},
	respond: {
		buttonText: 'Switch to Respond within Classic EMS',
		moduleText:
			"Soon you will be able to account for students during an alarm within the new EMS. We'll let you know as soon as its ready!",
	},
	reunification: {
		buttonText: 'Switch to Reunification within Classic EMS',
		moduleText:
			"Soon you will be able to reunite students with their parents within the new EMS. We'll let you know as soon as its ready!",
	},
};

class ComingSoon extends React.Component {
	state = {};

	legacyEMSSwitch = () => {
		const {
			match: {
				params: { feature },
			},
		} = this.props;

		const localToken = JSON.parse(localStorage.getItem('user'));
		const { dist, gid } = decodeToken(localToken.jwt);

		return window.location.replace(
			`${
				process.env.LEGACY_URL
			}/new_ems_landing.php?globalUserId=${gid}&districtId=${dist}&feature=${feature}`,
		);
	};

	render() {
		const {
			match: {
				params: { feature },
			},
		} = this.props;

		return (
			<CardWrapper
				style={{ borderRadius: '12px' }}
				elevations={Elevation.FOUR}
			>
				<div>
					<CardTitle className="card-title">Coming soon!</CardTitle>
					<CardSubTitle className="card-subtitle">
						{FEATURES[feature].moduleText}
					</CardSubTitle>
				</div>
				<ButtonWrapper>
					<Button
						text={FEATURES[feature].buttonText}
						intent="primary"
						icon="swap-horizontal"
						onClick={this.legacyEMSSwitch}
					/>
				</ButtonWrapper>
			</CardWrapper>
		);
	}
}

ComingSoon.propTypes = { match: PropTypes.object };
export default ComingSoon;
