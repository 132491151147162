import React from "react";
import { ContentSwitcher } from "./";
import './css/ContentSwitcherPattern.scss';

export const ContentSwitcherPattern = () => {
    const tabs1 = [
        { id: '1', label: 'Text 1' },
        { id: '2', label: 'Text 2'},
        { id: '3', label: 'Text 3', isDisabled: true },
        { id: '4', label: 'Text 4'},
        { id: '5', label: 'Text 5'},
        { id: '6', label: 'Text 6'},
        { id: '7', label: 'Text 7'},
    ];

    const tabs2 = [
        { id: '1', label: 'Text 1' },
        { id: '2', label: 'Text 2'}
    ];

    const tabs3 = [
        { id: '1', label: 'Text 1' }
    ];

    return (
        <div className="ContentSwitcherPattern__Container">
            <p>Multi selection with 1 option disabled</p>
            <ContentSwitcher tabs={tabs1} multi={true} />

            <p>Single selection</p>
            <ContentSwitcher tabs={tabs2} multi={false} />

            <p>Single selection</p>
            <ContentSwitcher tabs={tabs3} multi={false} />

            <p>Single selection with 1 option disabled and 1 option selected</p>
            <ContentSwitcher tabs={tabs1} multi={false} defaultActiveTabs={['6']} preserveTab={true} />
        </div>
      );
}