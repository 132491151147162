import React from 'react';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	height: 100vh;
	box-shadow: inset 1px 0px 0px #d0d9e0;
	min-width: ${props => (props.expand ? '300px' : '20px')};
	position: relative;
	transition: all 0.3s ease;
	margin-top: -20px;
	padding: 15px 0 0 20px;
	background-color: #f5f8fa;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	margin-bottom: 0;
	color: #000;
	opacity: ${props => (props.expand ? '1' : '0')};
	transition: all 0.3s ease;
`;

// const ListHeading = styled.p`
// 	position: static;
// 	width: 244px;
// 	height: 20px;
// 	left: 0px;
// 	top: 0px;
// 	font-family: Nunito Sans;
// 	font-style: normal;
// 	font-weight: bold;
// 	font-size: 14px;
// 	line-height: 20px;
// 	/* identical to box height, or 143% */
// 	display: flex;
// 	align-items: center;
// `;

const ToggleButton = styled.span`
	font-family: Open Sans;
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 25px;
	color: #5c7080;
`;

class Sidebar extends React.Component {
	state = {
		expand: true,
	};

	scrollTitle = (e, id) => {
		e.preventDefault();
		const el = document.getElementById(`section-${id}`);
		el.scrollIntoView({ behavior: 'smooth' });
	};

	render() {
		const { expand } = this.state;
		const { sections } = this.props;
		return (
			<Wrapper expand={expand}>
				<div className="d-col align-items-start mt-2">
					<div className="d-flex">
						<ToggleButton
							onClick={() => this.setState({ expand: !expand })}
							secondary="true"
							className="mr-2"
						>
							{expand ? (
								<Icon
									style={{
										color: '#5c7080',
									}}
									icon="menu-open"
								/>
							) : (
								<Icon
									style={{
										color: '#5c7080',
									}}
									icon="menu-closed"
								/>
							)}
						</ToggleButton>
						<Title style={{ marginTop: 5 }} expand={expand}>
							Template outline
						</Title>
					</div>
					<div className="d-col" style={{ marginTop: 20 }}>
						<ol
							style={{
								paddingLeft: '1rem',
							}}
						>
							{sections.map(item => (
								<div
									className="d-flex"
									style={{
										marginBottom: 10,
										fontFamily: 'Nunito Sans',
									}}
								>
									<li style={{ fontWeight: 600 }}>
										{item.name}
										{item.sections.map(itemSection => (
											<div
												style={{
													marginTop: 10,
													fontWeight: 100,
												}}
											>
												{itemSection.name}
											</div>
										))}
									</li>
								</div>
							))}
						</ol>
					</div>
				</div>
			</Wrapper>
		);
	}
}

Sidebar.propTypes = {
	sections: PropTypes.array,
};

export default Sidebar;
