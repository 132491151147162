import React from 'react';
import { PageHeader } from 'components/PageHeader';
import PropTypes from 'prop-types';
import './css/BreadCrumbHeader.scss';

export const BreadCrumbHeader = ({
	title,
	breadcrumbItems,
	customItem,
	breadCrumbCustomItem,
	children,
}) => {
	return (
		<PageHeader
			title={title}
			breadcrumbItems={breadcrumbItems}
			customItem={customItem}
			breadCrumbCustomItem={breadCrumbCustomItem}
		>
			{children}
		</PageHeader>
	);
};

BreadCrumbHeader.propTypes = {
	title: PropTypes.any.isRequired,
	breadcrumbItems: PropTypes.array.isRequired,
};
