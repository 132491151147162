import React from 'react';
import { Button } from 'componentsV2';

export const StudentsFooterOptions = ({ isMarkAllAsAccountedAllowed, submitClassHandler }) => {
	return (
		<div className="footer-options-container">
			{isMarkAllAsAccountedAllowed && (
				<Button
					text="Mark all accounted for"
					type="secondaryDefault"
					intent="default"
					size="sm"
					onClick={() => submitClassHandler(true)}
				/>
			)}

			<Button
				text="Submit class"
				type="primaryDefault"
				intent="default"
				size="sm"
				onClick={() => submitClassHandler(false)}
			/>
		</div>
	);
};
