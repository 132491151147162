import { HttpService } from 'networking/HttpService';

export const MasqueradeService = {

	startMasqueradingSession: async (params) => {
		return await HttpService({
			method: 'POST',
			route: '/users/masquerade',
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
                target_user_id: params.target_user_id,
				action_type: 'masquerade'
            }
		}).catch(err => {
			throw err;
		});
	},

	isMasquerading: () => {
		return JSON.parse(localStorage.getItem('masqueradingUserToken'))?.jwt ? true : false;
	},

	stopMasqueradingSession: async () => {
		return await HttpService({
			method: 'POST',
			route: '/users/masquerade',
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				action_type: 'stop_masquerade'
            }
		}).catch(err => {
			throw err;
		});
	}
};
