import React, { useState, useContext, useEffect } from 'react';
import { RollCallReunificationModals } from '../Modals';
import { TableFilter, MyClasses, Students, Staff, Visitors } from './';
import {
	ROLL_CALL_TAB_MY_CLASSES,
	ROLL_CALL_TAB_STUDENTS,
	ROLL_CALL_TAB_STAFF,
	ROLL_CALL_TAB_VISITORS,
} from '../../../consts';
import { RollCallDetailsContext } from '../..';
import { REUNIFICATION_STUDENTS_LIST, REUNIFICATION_GUARDIANS_LIST } from '../Reunification/consts';
export const RollCallTabContent = ({ alarm, rosterUpdateEvent, selectedRoster }) => {
	const { reunificationActiveTab, setReunificationActiveTab } = useContext(
		RollCallDetailsContext,
	);

	useEffect(() => {
		//reset reunification tab to student tab when return roll call tab
		if (reunificationActiveTab === REUNIFICATION_GUARDIANS_LIST) {
			setReunificationActiveTab(REUNIFICATION_STUDENTS_LIST);
		}
	}, []);
	const [contentToShow, setContentToShow] = useState(null);
	const [disableFilter, setDisableFilter] = useState(true);

	const initialData = {
		alarmId: alarm.id,
		page: 1,
		perPage: 10,
		sortKey: 'studentName',
		sortDir: 'asc',
		name: '',
		statusSelected: [],
		grades: [],
	};
	const [filterData, setFilterData] = useState(initialData);

	return (
		<>
			<RollCallReunificationModals contentToShow={contentToShow} />
			<TableFilter
				filterData={filterData}
				setFilterData={setFilterData}
				initialFiltrationData={initialData}
				contentToShow={contentToShow}
				setContentToShow={setContentToShow}
				disableFilter={disableFilter}
				setDisableFilter={setDisableFilter}
				alarm={alarm}
			/>

			{contentToShow === ROLL_CALL_TAB_MY_CLASSES && (
				<MyClasses
					alarm={alarm}
					initialData={initialData}
					filterData={filterData}
					setFilterData={setFilterData}
					setDisableFilter={setDisableFilter}
					rosterUpdateEvent={rosterUpdateEvent}
					selectedRoster={selectedRoster}
				/>
			)}

			{contentToShow === ROLL_CALL_TAB_STUDENTS && (
				<Students
					filterData={filterData}
					setFilterData={setFilterData}
					setDisableFilter={setDisableFilter}
				/>
			)}

			{contentToShow === ROLL_CALL_TAB_STAFF && (
				<Staff
					filterData={filterData}
					setFilterData={setFilterData}
					setDisableFilter={setDisableFilter}
				/>
			)}

			{contentToShow === ROLL_CALL_TAB_VISITORS && (
				<Visitors
					filterData={filterData}
					setFilterData={setFilterData}
					setDisableFilter={setDisableFilter}
				/>
			)}
		</>
	);
};
