import React, {useContext} from "react";
import { Button } from "componentsV2";
import { DocumentModalsContext } from "./DocumentModalsProvider";

export const DocumentHeader = ({
    editPermission,
    noDataAdded
}) => {    
    const {
        showUploadModal
    } = useContext(DocumentModalsContext);

    return (
        !noDataAdded && 
        <section className="document-header">
            <h4>File Manager</h4>
            <div className="right-side">
            {editPermission && (
                <Button
                    size="lg"
                    text="Upload file"
                    icon="cloud-upload"
                    intent="default"
                    onClick={() => showUploadModal()}
                />
            )}
            </div>
        </section>
    );
}