export const DOCUMENT_TYPE = 'Document';
export const MAPS_TYPE = 'Floor Plan';
export const CALL_LIST_TYPE = 'Call List';
export const SAFETY_PLAN_TYPE = 'Safety Plan';
export const ASSESSMENTS_TYPE = 'Assessment';

export const TYPES_DATA = [
	{
		label: 'Document',
		value: DOCUMENT_TYPE,
		path: ''
	},
	{
		label: 'Map',
		value: MAPS_TYPE,
		path: 'maps/view'

	},
	{
		label: 'Call list',
		value: CALL_LIST_TYPE,
		path: 'contacts'
	},
	{
		label: 'Safety plan',
		value: SAFETY_PLAN_TYPE,
		path: 'safety-plans/view'

	},
	{
		label: 'Assessment',
		value: ASSESSMENTS_TYPE,
		path: 'assessV2/assessment'

	},
];
