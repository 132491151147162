import React from 'react';
import { CardContainer, FeaturedIcon } from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import './OldSafetyPlansCard.scss';

export const OldSafetyPlansCard = ({ history, editPermissionOnBinders }) => (
	<CardContainer
		cardCustomClass="old-safetyplans-card-container"
		bodyCustomClass="old-safetyplans-card-container-body"
	>
		<FeaturedIcon icon="warning-sign" size="lg" type="Warning" className="warning-sign" />
		<div className="old-safetyplans-card-container-body-content">
			<p className="old-safetyplans-card-container-body-content-title">
				{editPermissionOnBinders
					? `Map titles with the orange warning icon indicate areas or icons linked to binders with outdated safety plans. Please update these plans in the Binders section before downloading binders from the maps.`
					: `Map titles with the orange warning icon indicate areas or icons linked to binders with outdated safety plans. Please contact your administrator to update these plans in the Binders section before downloading binders from the maps.`}
			</p>
			{editPermissionOnBinders && (
				<p
					className="old-safetyplans-card-container-body-content-link"
					onClick={() => history.push('/binders')}
				>
					Go to Binders <Icon icon="arrow-right" size={12} />
				</p>
			)}
		</div>
	</CardContainer>
);
