import { FloorplanService } from 'services/FloorplanService';
import { GEOMETRY_TYPE_ICON, GEOMETRY_TYPE_ROUTE } from '../../consts';
import { deleteShapeOnMap } from './deleteShapeOnMap';
import { drawIcon, drawRoute } from '../../Draw';
import { Intent } from '@blueprintjs/core';

export const updateIcon = (selectedIcon, floorplanId, layer, transformer, mapToaster) => {
	let sIcon = layer.find(node => node.id() === `iconBackground-${selectedIcon.id}`)[0];
	let iconData = {
		id: selectedIcon.id,
		title: selectedIcon.Newtitle ? selectedIcon.Newtitle : selectedIcon.title,
		showLabel:
			selectedIcon.NewshowLabel !== undefined
				? selectedIcon.NewshowLabel
				: selectedIcon.showLabel,
		visible: true,
		icon: selectedIcon.icon,
		geometryType: selectedIcon.geometryType,
	};

	const params = {
		title: iconData.title,
		show_label: iconData.showLabel,
	};
	FloorplanService.updateShape(floorplanId, iconData.id, params).then(response => {
		if (response.statusCode === 200) {
			updateShapeOnMap(iconData, layer, transformer);
			mapToaster(`You have successfully edited "${iconData.title}"`, Intent.SUCCESS);

			sIcon.attrs.title = iconData.title;
			sIcon.attrs.showLabel = iconData.showLabel;
		}
	});
};

const updateShapeOnMap = (iconData, layer, transformer) => {
	// update shape position x & y & rotaion if exists
	const currentIcon = layer.children.find(
		item =>
			item.attrs.shape?.id === iconData.id &&
			(item.attrs.shape?.geometryType === GEOMETRY_TYPE_ICON ||
				item.attrs.shape?.geometryType === GEOMETRY_TYPE_ROUTE),
	);
	if (currentIcon) {
		iconData.shape_position_x = currentIcon.attrs.x;
		iconData.shape_position_y = currentIcon.attrs.y;
		const iconImage = currentIcon.children.find(
			child =>
				child.attrs.type === GEOMETRY_TYPE_ICON || child.attrs.type === GEOMETRY_TYPE_ROUTE,
		);
		if (iconImage) {
			iconData.angle = iconImage.attrs.rotation;
		}
	}

	deleteShapeOnMap(iconData.id, layer, transformer.current);
	if (iconData.geometryType === GEOMETRY_TYPE_ICON) {
		drawIcon(iconData);
	} else if (iconData.geometryType === GEOMETRY_TYPE_ROUTE) {
		drawRoute(iconData);
	}
};
