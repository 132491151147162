import React, { useEffect, useState } from 'react';
import { DocumentService, PanoramaService } from 'services';
import { MAP_CONTENTS_MODAL, UPLOAD_CONTENT } from '../../../consts';
import { CONTENT_TYPE, DOCUMENT_TYPE_OPTIONS, Image_TYPE_OPTIONS } from '../MapContentsList/consts';
import { Intent } from 'componentsV2';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

export const UploadContentFn = (
	modalName,
	setLoading,
	closeModal,
	toaster,
	setModalToShow,
	modalData,
	dispatchModalData,
	reloadContent,
	openNextModal,
) => {
	useEffect(() => {}, [modalData]);
	const [docIdSelected, setDocIdSelected] = useState([]);
	const cancelUploadModal = tapType => {
		setModalToShow(MAP_CONTENTS_MODAL);
		openNextModal(tapType, null, docIdSelected);
	};
	const showUploadContentModal = (type, docIdSelected) => {
		setDocIdSelected(docIdSelected);
		switch (type) {
			case CONTENT_TYPE.DOCUMENTS:
				dispatchModalData({
					iconLinks: modalData.iconLinks,
					loadContentData: modalData.loadContentData,
					shape: modalData.shape,
					siteId: modalData.siteId,
					floorplanId: modalData.floorplanId,
					contentType: 'document',
					modalTitle: 'Upload document',
					modalSubTitle: `Documents will be added in the Documents/Binders section under Content`,
					uploader: {
						allowedFiles: DOCUMENT_TYPE_OPTIONS.map(item => item.value.toLowerCase()),
						allowedFilesLegend: `PDF, DOCX, XLSX, TXT, CSV, PPTX (max. ${MAX_FILE_UPLOADER_SIZE}mb)`,
						maxFileSize: MAX_FILE_UPLOADER_SIZE,
						label: 'Upload document',
					},
					onCancelAction: () => cancelUploadModal('Documents'),
				});
				break;
			case CONTENT_TYPE.IMAGES:
				dispatchModalData({
					iconLinks: modalData.iconLinks,
					loadContentData: modalData.loadContentData,
					shape: modalData.shape,
					siteId: modalData.siteId,
					floorplanId: modalData.floorplanId,
					contentType: 'image',
					modalTitle: 'Upload image',
					modalSubTitle: `Images will be added in the Images section under Content`,
					uploader: {
						allowedFiles: Image_TYPE_OPTIONS.map(item => item.value.toLowerCase()),
						allowedFilesLegend: `JPEG, JPG, PNG, GIF (max. ${MAX_FILE_UPLOADER_SIZE}mb)`,
						maxFileSize: MAX_FILE_UPLOADER_SIZE,
						label: 'Upload image',
					},
					onCancelAction: () => cancelUploadModal('Images'),
				});
				break;
			case CONTENT_TYPE.IMAGE360:
				dispatchModalData({
					iconLinks: modalData.iconLinks,
					loadContentData: modalData.loadContentData,
					shape: modalData.shape,
					siteId: modalData.siteId,
					floorplanId: modalData.floorplanId,
					contentType: '360image',
					modalTitle: 'Upload 360 image',
					modalSubTitle: `Images will be added in the 360 images section under Content`,
					uploader: {
						allowedFiles: Image_TYPE_OPTIONS.map(item => item.value.toLowerCase()),
						allowedFilesLegend: `JPEG, JPG, PNG, GIF (max. ${MAX_FILE_UPLOADER_SIZE}mb)`,
						maxFileSize: MAX_FILE_UPLOADER_SIZE,
						label: 'Upload image',
					},
					onCancelAction: () => cancelUploadModal('360 images'),
				});
				break;
			case CONTENT_TYPE.VIDEOS:
				dispatchModalData({
					iconLinks: modalData.iconLinks,
					loadContentData: modalData.loadContentData,
					shape: modalData.shape,
					siteId: modalData.siteId,
					floorplanId: modalData.floorplanId,
					contentType: 'video',
					modalTitle: 'Upload video',
					modalSubTitle: `Videos will be added in the Videos section under Content`,
					uploader: {
						allowedFiles: ['avi', 'wav', 'mp4'],
						allowedFilesLegend: `MP4, WAV and AVI (max. ${MAX_FILE_UPLOADER_SIZE}mb)`,
						maxFileSize: MAX_FILE_UPLOADER_SIZE,
						label: 'Upload video',
					},
					onCancelAction: () => cancelUploadModal('Videos'),
				});
				break;
			default:
			// Handle default case if necessary
		}
		setModalToShow(UPLOAD_CONTENT);
	};
	const UploadPanorama = (siteId, selectedFile, setFileError, tapType) => {
		setLoading(true);
		let params = {
			buildingId: siteId,
		};
		PanoramaService.upload(params, selectedFile)
			.then(response => {
				if (response.statusCode == 201) {
					closeModal();
					setModalToShow(MAP_CONTENTS_MODAL);
					openNextModal(tapType, response.data, docIdSelected);
					toaster(
						`You have successfully uploaded a new content`,
						Intent.SUCCESS,
						null,
						3000,
						false,
						'map-view-page',
					);
				} else if (response.statusCode == 400) {
					if (response.error.fields.file) {
						setFileError(response.error.fields.file);
					}
				}
			})
			.catch(error => {
				toaster(
					`Error uploading the file: ${error}`,
					Intent.DANGER,
					null,
					3000,
					false,
					'map-view-page',
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const UploadDocument = (siteId, selectedFile, setFileError, tapType) => {
		setLoading(true);
		let params = {
			buildingId: siteId,
		};
		DocumentService.uploadDocument(params, selectedFile)
			.then(response => {
				if (response.statusCode === 200) {
					closeModal();
					setModalToShow(MAP_CONTENTS_MODAL);
					openNextModal(tapType, response.data, docIdSelected);
					toaster(
						`You have successfully uploaded a new content`,
						Intent.SUCCESS,
						null,
						3000,
						false,
						'map-view-page',
					);
				} else if (response.statusCode == 400) {
					if (response.error.fields.file) {
						setFileError(response.error.fields.file);
					}
				}
			})
			.catch(error => {
				toaster(
					`Error uploading the file: ${error}`,
					Intent.DANGER,
					null,
					3000,
					false,
					'map-view-page',
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const handleUploadContent = (e, siteId, selectedFile, contentType, setFileError) => {
		e.preventDefault();
		switch (contentType) {
			case 'document':
				UploadDocument(siteId, selectedFile, setFileError, 'Documents');
				break;
			case 'image':
				UploadDocument(siteId, selectedFile, setFileError, 'Images');
				break;
			case '360image':
				UploadPanorama(siteId, selectedFile, setFileError, '360 images');
				break;
			case 'video':
				UploadDocument(siteId, selectedFile, setFileError, 'Videos');
				break;
			default:
		}
	};

	return {
		showUploadContentModal,
		handleUploadContent,
	};
};
