/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { MenuItem, Card, Tag, Icon, Toaster, Position } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { DateTime } from 'luxon';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import selectRoute from '../../App/selectors';
import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { formatDate } from 'utilities/dates';
import { checkPermission } from 'utilities/permissions';
import PlanActionButton from '../components/PlanActionButton';
import ArchivedTemplatesTable from '../components/ArchivedTemplatesTable';


const itemRender = (item, { handleClick }) => (
	<MenuItem key={item.key} text={item.value} onClick={handleClick} selected={true} />
);

const WiderButton = styled(Button)`
	min-width: 200px;
	display: flex;
	justify-content: space-between !important;
	background: #fff !important;
	white-space: nowrap;
`;

const CardTitle = styled.h5`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	color: ${props => (props.color === 'red' ? '#9F1C3A	' : '#10161a')};
	margin-bottom: 0;
`;

const CardDetailsWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
`;

const CardTimeStamp = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #30404d;
	margin-bottom: 0;
`;

const TitleWrap = styled.div`
	display: flex;
	color: #394b59;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 5px;
	h5 {
		margin-left: 10px;
		margin-bottom: 0;
		font-family: 'Merriweather';
		font-weight: 700;
		font-size: 15px;
		line-height:22px;
		letter: 0.4px;
	}
`;

const Dot = styled.div`
	height: 10px;
	width: 10px;
	border-radius: 10px;
	margin-right: 6px;
	background: #9f1c3a;
`;

class Dashboard extends React.Component {
	constructor() {
		super();
		this.inputRef = React.createRef(); // Create a ref for the form element
	}
	state = {
		selected: null,
		selectOptions: [],
		filteredSites: [],
		loading: false,
		hasEditPermissionOnPlans: false
	};

	loadData = () => {
		const {
			route: { permissions, permissionsFetched },
		} = this.props;
		let hasEditPermissionOnPlans = checkPermission('safety_plans_edit', permissions);
		this.setState({ loading: true, selected: null, hasEditPermissionOnPlans: hasEditPermissionOnPlans });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const apiUrl = process.env.API_URL
		
		Promise.all([
			fetch(
				`${apiUrl}/plan-templates?includeContent=false`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			),
			fetch(
				`${apiUrl}/district-templates?includeContent=false&isCompleted=true&inCompleted=true`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			),
			fetch(
				`${apiUrl}/users/myself?includeUserBuildings=true`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			),
		])
			.then(([res1, res2, res3]) =>
				Promise.all([res1.json(), res2.json(), res3.json()]),
			)
			.then(([resp1, resp2, resp3]) => {
				if (resp1.statusCode !== 200) {
					const localToken = JSON.parse(localStorage.getItem('user'));
					localToken.jwt = '';
					localStorage.setItem('user', JSON.stringify(localToken));
					return window.location.reload();
				}

				const selectOptions = [];
				if (resp3.data.districtUser) {
					const temp = {
						key: 0,
						value: 'All',
					};
					selectOptions.push(temp);
				}
				resp3.data.buildings.forEach(one => {
					const temp = {
						key: one.id,
						value: one.name,
					};
					selectOptions.push(temp);
				});
				const { data } = resp1;
				resp2.data.forEach(item => {
					let hasEditPermissionOnPlanPerSite = checkPermission('safety_plans_edit', permissions, item.buildingId);
					let hasViewPermissionOnPlanPerSite = checkPermission('safety_plans_view', permissions, item.buildingId);
					item['hasViewPermissionOnPlanPerSite'] = hasViewPermissionOnPlanPerSite;
					item['hasEditPermissionOnPlanPerSite'] = hasEditPermissionOnPlanPerSite;
					return data.templates.push(item)
				});
				this.setState({
					...data,
					filtered: [],
					loading: false,
					selectOptions,
					filteredSites: selectOptions
				});
			}).then(()=> {
				const { route } = this.props;
				const districtPlanSite = route.districtPlanSite;
				if(districtPlanSite) {
					this.changeFilter(districtPlanSite)
				}
			});
	};

	viewTemplate = (id) => {
		const { history } = this.props;
		history.push(`/view/${id}`)
	}

	editButton = (item, isUpdate = false) => {
		const { history } = this.props;
		const { selected } = this.state;
		// core template
		if (item.type === 'core') {
			if (!selected) {
				return Swal.fire({
					text: 'Please select a site first',
					icon: 'error',
					confirmButtonText: 'Close',
				});
			}
			if (selected.key === 0) {
				Swal.fire({
					title: 'Select a Site',
					text: 'Select a site to proceed with safety plan creation',
					icon: 'warning',
					confirmButtonText: 'Close',
				});
				return;
				// focus on select
			}
			const token = JSON.parse(localStorage.getItem('user')).jwt;
			fetch(
				`${process.env.API_URL}/district-templates?coreId=${item.id}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						coreId: item.id,
						buildingId: selected.key,
					}),
				},
			)
				.then(res => res.json())
				.then(resp => {
					if (resp.statusCode === 201) {
						return history.push(`/edit/${resp.data.id}`, {isUpdate});
					}
					return null;
				});
		} else {
			history.push(`/edit/${item.id}`, {isUpdate});
		}
	};

	changeFilter = item => {
		const { templates } = this.state;
		let newTemplates;
		if (item.key === 0) {
			newTemplates = templates;
		} else {
			newTemplates = templates.filter(one => one.buildingId === item.key);
		}
		const { dispatch } = this.props;
		dispatch({
			type: 'DISTRICT_PLANS_SITE',
			payload: item
		});
		this.setState({ selected: item, filtered: newTemplates });
	};

	cloneTemplate = item => {
		const { history } = this.props;
		return history.push(`/clone/${item.id}/plan`);
	};

	componentDidMount() {
		this.loadData();
		// this.setState({loading: false});
	}

	onFilterSitesHandler = search => {
		const { selectOptions } = this.state;
		const filteredSites = selectOptions.filter(district => 
								district.value?.toLowerCase().includes(search.toLowerCase()) 
							);
		this.setState({
			filteredSites
		});
	}
	
	showToaster = (message, intent = 'success') => {
		const toastParams = {
			message,
			intent,
			timeout: 3000,
		};
		this.toaster.show(toastParams);
	};


	render() {
		const {
			selected,
			loading,
			templates,
			filtered,
			filteredSites,
		} = this.state;
		const { history } = this.props;
		const { route : { districtPlanSite } } = this.props;
		if (loading) return <LoadingSpinner />;
		return (
			<>
				<Toaster
					ref={ref => {
						this.toaster = ref;
					}}
					position={Position.TOP_RIGHT}
				/>
				<div className="mb-3">
					<p className="mb-0">Site</p>
					<Select
						className='siteSelectorPlans'
						items={filteredSites}
						ref={this.inputRef}
						itemRenderer={itemRender}
						onItemSelect={item => this.changeFilter(item)}
						onQueryChange={this.onFilterSitesHandler}
					>
						<WiderButton
							rightIcon="caret-down"
							text={
								(selected && selected.value) ||
								'Please select a site'
							}
						/>
					</Select>
				</div>
				{ this.state.hasEditPermissionOnPlans && (
				<>
				<TitleWrap>
					<Icon icon="clipboard" />
					<h5>Safety Plan Templates</h5>
				</TitleWrap>
				<div className="row">
					{templates && templates.map(item => {
						if (item.type === 'core') {
							return (
								<div
									className="col-lg-4 col-md-6 mb-3"
									key={`core-${item.id}`}
								>
									<Card>
										<div className="d-flex align-items-center justify-content-between mb-2">
											<CardTitle>{item.name}</CardTitle>
										</div>
										<CardDetailsWrap>
											<CardTimeStamp>
												Last updated:{' '}
												{item.editedDate
													? formatDate(
															item.editedDate
																.date,
															DateTime.DATE_SHORT,
													  )
													: 'N/A'}
											</CardTimeStamp>
										</CardDetailsWrap>
										<hr />
										<Button
											intent="primary"
											onClick={() =>
												this.editButton(item)
											}
											text="Create Plan"
											large
										/>
									</Card>
								</div>
							);
						}
						return null;
					})}
				</div>
				</>
				)}
				<TitleWrap>
					<Icon icon="clipboard" />
					<h5>District Plan Templates</h5>
				</TitleWrap>
				<div className="row">
					{filtered && filtered.map(item => {
						if (item.type !== 'core') {
							let hasEditPermissionOnPlanPerSite = item.hasEditPermissionOnPlanPerSite;
							let hasViewPermissionOnPlanPerSite = item.hasViewPermissionOnPlanPerSite;
							return (
								<div
									className="col-lg-4 col-md-6 mb-3"
									key={`district-${item.id}`}
								>
									<Card>
										<div className="d-flex align-items-center justify-content-between mb-2">
											<div className="d-flex align-items-center">
												{item.totalUpdates > 0 || item.totalLocalUpdates > 0 && (
													<Dot />
												)}
												<CardTitle
													color={
														(item.totalUpdates > 0 || item.totalLocalUpdates > 0) &&
														'red'
													}
												>
													{item.name}
												</CardTitle>
											</div>
											<small>
												{item.type === 'core' && 'Core'}
											</small>
										</div>
										<CardTimeStamp className="mb-2">
											{item.buildingName &&
												item.buildingName}
										</CardTimeStamp>
										<div className="d-flex flex-column">
											<div className="d-flex align-items-center">
												<Tag
													intent={
														item.isCompleted
															? 'success'
															: 'primary'
													}
													minimal
													round
												>
													{item.isCompleted
														? 'Completed'
														: 'In Progress'}
												</Tag>
												<CardTimeStamp className="ml-2">
													{item.totalComplete} out of{' '}
													{item.totalSections} pages
													completed
												</CardTimeStamp>
											</div>
											<div>
												<CardTimeStamp className="mt-2">
													Template last updated:{' '}
													{item.editedDate
														? formatDate(
																item.editedDate,
																DateTime.DATE_SHORT,
														  )
														: 'N/A'}
												</CardTimeStamp>
											</div>
										</div>
										<hr />
										<div className="d-flex justify-content-between">
											<div>
												{(item.totalUpdates > 0 || item.totalLocalUpdates) > 0 && hasEditPermissionOnPlanPerSite && (
													<Button
														minimal
														rightIcon="arrow-top-right"
														intent="danger"
														onClick={() =>
															this.editButton(
																item,true
															)
														}
														text={`${
															item.totalUpdates + (item.totalLocalUpdates ? parseInt(item.totalLocalUpdates) : 0)
														} new updates`}
													/>
												)}
											</div>

											<PlanActionButton
												item={item}
												viewPlanAction={this.viewTempalte}
												loadDataCallback={this.loadData}
												editPlanCallback={this.editButton}
												clonePlanCallback={this.cloneTemplate}
												viewTemplate={this.viewTemplate}
											></PlanActionButton>
										</div>
									</Card>
								</div>
							);
						}
						return null;
					})}

					{ this.state.hasEditPermissionOnPlans && (
						<>
						<div className='col-md-12'>						
							<TitleWrap>
								<Icon icon="archive" size={16} />
								<h5>Archived</h5>
							</TitleWrap>
						</div>
						<div className='col-md-12 archived-template-wrapper'>
							<ArchivedTemplatesTable
								showToaster={this.showToaster}
								reloadPlans={this.loadData}
								history={history}
								selectedSite={districtPlanSite ? districtPlanSite?.key : null}
							/>
						</div>
						</>
					)}
				</div>
			</>
		);
	}
}

Dashboard.propTypes = {
	history: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});


function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(withRouter(Dashboard));
