import React from "react";
import './css/TemplateSection.scss';
import { Button } from 'componentsV2';

export const TemplateSection = ({
    title,
    subTitle,
    btnType,
    btnTitle,
    onClickHandler,
    children,
    isButtonDisabled = false,
}) => {
    return (
        <section className='template-section-container'>
            <div className="title-container">
                <div className="title">
                    {title}
                    <span className="subtitle">{subTitle}</span>
                </div>
                <Button
                    text={btnTitle}
                    size="sm"
                    type={btnType}
                    onClick={onClickHandler}
                    disabled={isButtonDisabled}
                />
            </div>
            <div className='body-content-wrapper'>
                {children}
            </div>
        </section>
    );
}

