import React from "react";
import BootstrapedTable from "components/BootstrapedTable";
import { Icon } from '@blueprintjs/core';
import '../css/index.scss';
import { formatDate } from "utilities/dates";
import { NavLink } from 'react-router-dom';
import CallListsActionsButton from "./CallListsActionsButton";
import { SORTING_DIR } from 'utilities/constants';

const Table = ({
	callList,
	filterationData,
	dispatchFilterationData,
	totalRows,
	loadEditModal,
	setCallListID,
	setModalToShow
}) => {

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID', 
			hidden: true 
		},
		{	
			key: 'title',
			dataField: 'title',
			text: 'Name',
			classes:'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%'},
			formatter: (value, row) => {
				return <NavLink 
							exact
							className='active'
							to={{
								pathname:`/contacts/${row.id}`,
								state: {'contact': row.id}  
							}}
						>
						{value}
					</NavLink>
			}
		},
		{
			key: 'site',
			dataField: 'site',
			text: 'Site',
			classes:'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%'},
		},
		{
			key: 'date',
			dataField: 'dateModified.date',
			text: 'Last Modified',
			classes:'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%'},
			formatter: (value, row) => {
				return value ? formatDate(value) : null
			}
		},
		{
			key: 'numberOfContracts',
			dataField: 'numberOfContracts',
			text: 'Number Of Contacts',
			classes:'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%'},
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<CallListsActionsButton 
					row={row}
					loadEditModal={loadEditModal}
					setCallListID={setCallListID}
					setModalToShow={setModalToShow}
				/>
			)
		},
	];

	const sortingStyle = (order, column) => {
		if (order === SORTING_DIR.DESC) {
			return <Icon icon="caret-down" />
		}
		return <Icon icon="caret-up" />
	};

	// change row color with disabled callList
	const rowStyle = (row, rowIndex) => {
		const style = {};

		style.color = '#777';

		return style;
	};
	
	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'title';
			switch (sortField) {
				case 'buildings[0].name':
					sortColumn = 'buildingName';
				break;
				case 'dateModified.date':
					sortColumn = 'lastModified';
				break;
				case 'numberOfContracts':
					sortColumn = 'contracts';
				break;
				default:
					sortColumn = sortField;
				break;
			}
			dispatchFilterationData({'type' : 'sortKey', 'payload': sortColumn})
			dispatchFilterationData({'type' : 'sortDir', 'payload': sortOrder})
		}
	};

	return (
		<div className="col-md-12">
		<BootstrapedTable
			keyField="id"
			data={callList}
			columns={columns}
			bordered={false}
			defaultSorted={[{
				dataField: 'title',
				order: SORTING_DIR.ASC, 
			}]}
			rowStyle={rowStyle}
			onTableChange={onTableChange}
			currentPage={filterationData.page}
			rowsPerPage={filterationData.perPage}
			setCurrentPage={value => dispatchFilterationData({'type' : 'page', 'payload': value})}
			setRowsPerPage={value => dispatchFilterationData({'type' : 'perPage', 'payload': value})}
			totalRows={totalRows}
			sizePerPageList={[10, 25, 50, 100, 200]}
		/>
		</div>

	);

}

export default Table;