import React, { useContext } from 'react';
import { ActionsButton, Button } from 'componentsV2';
import { DISTRICT_LIST_PATH, DistrictTemplateContext } from '../../';
import { useSelector } from 'react-redux';
import { decodeToken } from 'utilities';
import { checkPermission } from 'utilities/permissions';

export const PlansActionList = ({ plan, history }) => {
	const {
		showCopyModal,
		getAndDownloadSafetyPlan,
		showArchiveModal,
		showDeleteModal,
	} = useContext(DistrictTemplateContext);

	const permissions = useSelector(state => state.route.permissions);
	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const dist = token ? decodeToken(token) : '';
	const districtId = dist ? dist.dist : null;
	const editPermission = checkPermission(
		'safety_plans_edit',
		permissions,
		plan.buildingId ? plan.buildingId : districtId,
	);

	const handleDownloadSafetyPlan = () => {
		return getAndDownloadSafetyPlan(plan.id, plan.name);
	};

	return (
		<ActionsButton>
			<Button
				large
				text="View"
				icon="eye-open"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => history.push(`${DISTRICT_LIST_PATH}/view/${plan.id}`)}
			/>
			{editPermission && plan.status != 'archived' && (
				<>
					<Button
						large
						text="Edit"
						icon="edit"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => history.push(`${DISTRICT_LIST_PATH}/${plan.id}`)}
					/>

					<Button
						large
						text="Copy"
						icon="duplicate"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() =>
							showCopyModal({
								id: plan.id,
								title: plan.name,
								siteId: plan.buildingId,
							})
						}
					/>
				</>
			)}

			{plan.status != 'archived' && (
				<Button
					large
					text="Download"
					icon="cloud-download"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={handleDownloadSafetyPlan}
				/>
			)}

			{editPermission && (
				<Button
					large
					text={plan.status != 'archived' ? 'Archive' : 'Unarchive'}
					icon={plan.status != 'archived' ? 'archive' : 'unarchive'}
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() =>
						showArchiveModal({
							id: plan.id,
							title: plan.name,
							archived: plan.status == 'archived',
							source: 'list',
						})
					}
				/>
			)}

			{editPermission && plan.status != 'archived' && (
				<Button
					large
					text="Delete"
					icon="trash"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => {
						showDeleteModal(plan, 'list');
					}}
				/>
			)}
		</ActionsButton>
	);
};
