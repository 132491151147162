import React, { useState } from 'react';
import { ProgressBar } from './ProgressBar';
import { Input, Checkbox } from 'componentsV2';

export const ProgressBarPattern = () => {
	// Example 1 Simple usage states & functions
	const [progressBarDataExOne, setProgressBarDataExOne] = useState({
		label: 'Simple usage',
		value: 50,
	});

	const onChangeHandlerExOne = value => {
		setProgressBarDataExOne(oldData => ({
			...oldData,
			...value,
		}));
	};

	// Example 2 advanced usage states & functions
	const [progressBarDataExTwo, setProgressBarDataExTwo] = useState({
		label: 'Advanced usage',
		value: 50,
		total: 100,
		displayValueInPercentage: true,
		disabled: false,
	});

	const onChangeHandlerExTwo = value => {
		setProgressBarDataExTwo(oldData => ({
			...oldData,
			...value,
		}));
	};

	return (
		<div className="d-flex flex-column">
			<section className="gap-3">
				<ProgressBar
					label={progressBarDataExOne.label}
					value={progressBarDataExOne.value}
				/>
				<div className="d-flex flex-column gap-3 mt-3">
					<Input
						label="Label"
						labelState={false}
						type="text"
						onChange={e => onChangeHandlerExOne({ label: e.target.value })}
						value={progressBarDataExOne.label}
					/>
					<Input
						label="Value"
						labelState={false}
						type="number"
						maxLength={3}
						onChange={e => onChangeHandlerExOne({ value: e.target.value })}
						value={progressBarDataExOne.value}
					/>
				</div>
			</section>
			<section className="my-5 gap-3">
				<ProgressBar
					label={progressBarDataExTwo.label}
					value={progressBarDataExTwo.value}
					total={progressBarDataExTwo.total}
					displayValueInPercentage={progressBarDataExTwo.displayValueInPercentage}
					disabled={progressBarDataExTwo.disabled}
				/>
				<div className="mt-3">
					<Input
						label="Label"
						labelState={false}
						type="text"
						onChange={e => onChangeHandlerExTwo({ label: e.target.value })}
						value={progressBarDataExTwo.label}
					/>
					<Input
						label="Value"
						labelState={false}
						type="number"
						onChange={e => onChangeHandlerExTwo({ value: e.target.value })}
						value={progressBarDataExTwo.value}
						disabled={progressBarDataExTwo.disabled}
					/>
					<Input
						label="Total"
						labelState={false}
						type="number"
						onChange={e => onChangeHandlerExTwo({ total: e.target.value })}
						value={progressBarDataExTwo.total}
						disabled={progressBarDataExTwo.disabled}
					/>
					<br />
					<Checkbox
						label="display value in percentage"
						onChange={e =>
							onChangeHandlerExTwo({ displayValueInPercentage: e.target.checked })
						}
						checked={progressBarDataExTwo.displayValueInPercentage}
					/>
					<br />
					<Checkbox
						label="Disabled"
						onChange={e =>
							onChangeHandlerExTwo({ disabled: e.target.checked })
						}
						checked={progressBarDataExTwo.disabled}
					/>
				</div>
			</section>
		</div>
	);
};
