import styled from 'styled-components';

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 32px;
	color: #394b59;
`;

export { Title };
