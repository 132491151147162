import React from 'react'
import rightArrow from "../assets/rightArrow.svg";

export const FilterMenuItem = ({
    title,
    subtitle = "",
    hasChildren = false,
    content = null,
}) => {
    return (
        <div className='filter-menu-item'>
            <div className='menu-item-content'>
                <div className='menu-item-title'>{title}</div>
                {subtitle.length > 0 && (
                    <div className='menu-item-subtitle'>{subtitle}</div>
                )}
                {content && (
                    <div className='menu-item-content'>{content}</div>
                )}
            </div>
            {hasChildren && (
                <img src={rightArrow} alt="Expand" />
            )}
        </div>
    )
}
