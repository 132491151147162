import React from 'react';
import { NavLink } from 'react-router-dom';
import { BootstrapedTable, sortingStyle, TableCell } from 'componentsV2';
import { formatDate } from 'utilities/dates';
import { TemplatesActionList } from './TemplatesActionList';

export const TemplatesTable = ({
	list,
	totalRows,
	filtrationData,
	setFiltrationData,
	history,
	setLoading,
}) => {
	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'disabled',
			dataField: 'disabled',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Template title',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (
				<TableCell
					text={
						<NavLink
							exact
							to={{
								pathname: row.archivedBy
									? '/core/view/' + row.id
									: '/core/templates/' + row.id,
							}}
						>
							{value}
						</NavLink>
					}
					supportText={row.archivedBy ? 'Archived' : ''}
				/>
			),
		},
		{
			key: 'state',
			dataField: 'state',
			text: 'State',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'isProprietary',
			dataField: 'isProprietary',
			text: 'Proprietary',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (value ? 'Yes' : 'No'),
		},
		{
			key: 'editedDate',
			dataField: 'editedDate',
			text: 'Last modified',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (formatDate(value?.date) ? formatDate(value?.date) : 'N/A'),
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<TemplatesActionList template={row} history={history} setLoading={setLoading} />
			),
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'name';
			switch (sortField) {
				case 'state':
					sortColumn = 'state';
					break;
				case 'editedDate':
					sortColumn = 'lastModified';
					break;
				case 'isProprietary':
					sortColumn = 'proprietary';
					break;
				default:
					sortColumn = sortColumn;
					break;
			}
			setFiltrationData({ ...filtrationData, sortKey: sortColumn, sortDir: sortOrder });
		}
	};

	const setRowsPerPage = value => {
		setFiltrationData(oldData => ({ ...oldData, perPage: value }));
	};

	const setCurrentPage = value => {
		setFiltrationData(oldData => ({ ...oldData, page: value }));
	};

	return (
		<BootstrapedTable
			keyField="id"
			data={list}
			columns={columns}
			totalRows={totalRows}
			onTableChange={onTableChange}
			currentPage={filtrationData.page}
			rowsPerPage={filtrationData.perPage}
			setCurrentPage={setCurrentPage}
			setRowsPerPage={setRowsPerPage}
		/>
	);
};
