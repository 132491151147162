import React from "react";
import PropTypes from 'prop-types';
import {FormModal, Input} from "componentsV2";
import {SiteDropdownSingleSelect} from "components/SiteDropdownSingleSelect";

export const MoveModal = ({
                              modalName,
                              modalToShow,
                              loading,
                              documentData,
                              closeModal,
                              handleChangeSite,
                              handleSubmit,
                              selectedSite,
                              documents,
                              inputErrors
                          }) => {

    return (
        <FormModal
            isOpen={modalToShow === modalName}
            onClose={closeModal}
            title={'Move file'}
            showCloseButton={true}
            confirmBtn={{
                title: `Move ${ documents.length > 1 ? 'them' : 'it'}`,
                onClick: handleSubmit,
                disabled: ! selectedSite
            }}
            cancelBtn={{
                title: "Cancel",
                onClick: closeModal
            }}
            loading={loading}
            scrollContent={false}
        >
            <div className="w-100">
                <div className="col-12 mb-3">
                    <Input
                        value={documentData.displayTitle}
                        label="File to move"
                        labelState={false}
                        disabled={true}
                        isRequired={false}
                    />
                </div>
                <div className="col-12 mb-3">
                    <SiteDropdownSingleSelect
                        label={`Site to put ${ documents.length > 1 ? 'them' : 'it'} in`}
                        toAllLabel="Visible to All Sites"
                        selectedSite={selectedSite}
                        onChange={handleChangeSite}
                        permission={'document_edit'}
                        isRequired={true}
                        error={inputErrors?.errorMessage}
                    />
                </div>
            </div>
        </FormModal>
    );
}

MoveModal.propTypes = {
    modalName: PropTypes.string,
    modalToShow: PropTypes.func,
    loading: PropTypes.bool,
    documentData: PropTypes.array,
    closeModal: PropTypes.func,
    handleChangeSite: PropTypes.func,
    handleSubmit: PropTypes.func,
    inputErrors: PropTypes.any,
}