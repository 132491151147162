import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tab, Tabs, Icon, Tag } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { Button } from 'components/Button';
import { LinkButton } from 'components/LinkButton';
import Swal from 'sweetalert2';
import FileSection from './filesection';
import selectRoute from './selectors';
import {parseDataModel, stringToVarName, validateTemplateSections} from 'utilities';
import SideBar from './sidebar';
import { SingleEditor } from './singleeditor';
import { EventLogs } from 'components/EventLogs';
import { BackToTopButton } from 'components/BackToTopButton';
import VisibilitySensor from 'react-visibility-sensor';
import DeleteSectionModal from '../../SafetyPlan/Modals/DeleteSectionModal';
import { TemplateService } from 'services';
import { fireSwal } from 'utilities/fireSwal';
import { scrollToSidebarSection } from 'utilities/SafetyPlan/helpers';

const Wrapper = styled.div`
	display: flex;
	flex: 1;
`;

const TextAreaContainer = styled.div`
	padding: 0 15px;
	background: ${props => props.panel === 'contextualeditor' ? '#fff' : 'inherit' };
`;

const TemplateName = styled.h1`
	font-family: Merriweather;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 32px;
	color: #394b59;
`;

const BackButtonWrap = styled.button`
	color: #5c7080;
	background: none;
	border: none;
	margin-right: 6px;
`;

const BackText = styled.span`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #5c7080;
	margin-left: 10px;
`;

const SmallIcon = styled(Icon)`
	transform: scale(0.7);
	margin-top: 1px;
	margin-left: -3px;
`;

const AutoSaveWrap = styled.div`
	display: flex;
	align-items: center;
	color: #5c7080;
`;

const getSize = type => {
	if (type === 'section') {
		return '22px';
	}
	if (type === 'subsection') {
		return '18px';
	}
	return '15px';
};

const BlankInput = styled.input`
	font-family: Merriweather;
	font-style: normal;
	font-weight: normal;
	font-size: ${props => getSize(props.type)} !important;
	text-transform: ${props => (props.type ? 'none' : 'uppercase')};
	margin-bottom: ${props => (props.type ? '0' : '16px')};
	margin-top: ${props => (props.type ? '0' : '-16px')};
	line-height: 32px;
	color: #293742;
	border: none;
	width: 100%;
	padding: 0;
	&:focus {
		outline: none;
	}
`;

const HeaderWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #f5f8fa;
	margin-left: -16px;
	margin-right: -16px;
	margin-top: -16px;
	padding: 16px 16px 0 16px;
	margin-bottom: 16px;
	border-bottom: 1px solid #d0d9e0;
`;

const DeleteButton = styled(Button)`
	font-size: 10px !important;
	margin-right: 6px;
	font-weight: bold;
`;

const Split = styled.hr``;

const sectionsPerPage = 1;
class AddPlan extends React.Component {
	state = {
		nodes: [],
		loading: true,
		commonVariables: [],
		updateMode: false,
		updateTarget: null,
		updateType: null,
		panel: 'contextualeditor',
		visibleSection: null,
		currentPage: 0,
		yPosition: 0,
		page: 0,
		modalToShow: false,
		viewMode: false,
		templateOldName: '',
		templateOldContent: {},
		templateSavedBeforeUnmount: false,
		isSectionScrollActive: true,
		startScrolling: false,
	};

	constructor() {
		super();
		this.sectionRef = React.createRef();
		this.sidebarRef = React.createRef();
		this.mainWrapperRef = React.createRef();
	}

	loadData = (firstLoad) => {
		const {
			match: {
				params: { id },
			},
			dispatch,
			route: { districtId },
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;

		Promise.all([
			fetch(`${process.env.API_URL}/plan-templates/${id}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
			fetch(`${process.env.API_URL}/common-variables`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			}),
		])
		.then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
		.then(([resp1, resp2]) => {
			let template = {
				id,
				is_template: true,
				name: resp1.data.template.name,
				lastUpdated: resp1.data.template.editedDate.date,
				state: resp1.data.template.state,
				sections: [
					{
						id: 1,
						title: 'Section 1 title',
						subsections: [
							{
								id: 1,
								title: 'Subsection 1 title',
								content: '<p>Subsection 1 content</p>',
							},
						],
					},
				],
			};
			if (resp1.data.template.headers) {
				template.headers = JSON.parse(resp1.data.template.headers);
			}
			if (resp1.data.template.content) {
				if (firstLoad) {
					// Only on the First Load, set the Initial Information
					// of the template in the state, needed to log changes
					this.setState({
						templateOldName: resp1.data.template.name,
						templateOldContent: resp1.data.template.content
					});
				}
				template.sections = JSON.parse(resp1.data.template.content);
				if(template.sections[0]) {
					this.setState({
						visibleSection: template.sections[0]
					})
				}
				template = parseDataModel(template);
			}
			if (resp1.data.variables) {
				this.setState({ templateVariables: resp1.data.variables });
			}
			const singleSection = JSON.parse(JSON.stringify(template));
			singleSection.sections.length = 1;
			this.setState({ commonVariables: resp2.data, loading: false });
			return dispatch({
				type: 'LOAD_TEMPLATE',
				payload: template,
				filtered: singleSection,
			});
		});
	};

	reloadVariables = () => {
		const {
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates/${id}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then(res => res.json())
		.then(resp => {
			if (resp.data.variables) {
				this.setState({ templateVariables: resp.data.variables });
			}
		});
	};

	saveTemplate = (enableValidation) => {
    const {
      route: {
        template: {sections, is_template, name, headers},
      },
      match: {
        params: {id},
      },
    } = this.props;
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    let errorObj = validateTemplateSections(sections, true, null, name);
    if (errorObj.issue && enableValidation) {
      Swal.fire({
        icon: 'question',
        html: errorObj.messageHTML + '<br> Are you sure you want to close and lose your changes?',
        showCancelButton: true,
        customClass: {
          confirmButton: 'bp3-button ems-primary-button',
          cancelButton: 'bp3-button',
        },
        confirmButtonText: 'Continue',
        reverseButtons: true,
        returnFocus: false,
        onAfterClose: () => {
          const element = document.getElementById(errorObj.elementId);
          if (element) {
            element.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }
        }
      }).then(result => {
        if (result.value) {
          const {history} = this.props;
          return history.push('/safety-plansV1');
        }
        return Swal.close();
      });
    } else {
      fetch(`${process.env.API_URL}/plan-templates/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'app/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
			headers: JSON.stringify(headers),
			content: JSON.stringify(sections),
			name: name,
			oldName: this.state.templateOldName,
			oldContent: this.state.templateOldContent,
			log: 1,
        }),
      })
        .then(res => res.json())
        .then(() => {
			if (enableValidation) {
				this.setState({templateSavedBeforeUnmount: true});
				const {history} = this.props;
				return history.push('/safety-plansV1');
			}
        });
    }
  };

	saveAndReload = () =>
	{
		const {
			route: {
				template: { sections, headers },
			},
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				headers: JSON.stringify(headers),
				content: JSON.stringify(sections),
			}),
		})
		.then(res => res.json())
		.then(() => {
			this.loadData();
		});
	};

	handleNodeClick = e => {
		window.location.hash = e.id;
	};

	// updateNodes = (newNodes, index) => {
	// 	const { nodes } = this.state;
	// 	const temp = JSON.parse(JSON.stringify(nodes));
	// 	temp[0].sections[index] = newNodes;
	// 	return this.setState({ nodes: temp, indicator: false });
	// };

	updateNodes = (
		sectionIndex,
		subsectionIndex,
		content,
		cancelUpdateMode = false,
	) => {
		const { dispatch } = this.props;
		if (subsectionIndex === null) {
			dispatch({
				type: 'UPDATE_SECTION_CONTENT',
				payload: content,
				sectionIndex,
			});
			if (cancelUpdateMode) {
				return this.cancelUpdateMode();
			}
			return null;
		}
		dispatch({
			type: 'UPDATE_SUBSECTION',
			payload: content,
			sectionIndex,
			subsectionIndex,
		});
		if (cancelUpdateMode) {
			return this.cancelUpdateMode();
		}
		return null;
	};

	// getContent = nodes => {
	// 	const retArr = [];
	// 	const regex = /\[\[.*?\]\]/;
	// 	nodes.forEach(item =>
	// 		item.sections.forEach((single, index) => {
	// 			let content = '';
	// 			content += single.title;
	// 			single.subsections.forEach(one => {
	// 				content += one.title;
	// 				content += one.content;
	// 				return null;
	// 			});
	// 			retArr.push(
	// 				<>
	// 					<SingleEditor
	// 						viewMode={viewMode}
	// 						index={index}
	// 						nodeId={single.id}
	// 						updateNodes={this.updateNodes}
	// 						content={content}
	// 						openVariables={this.openVariables}
	// 						toggleDialog={this.toggleDialog}
	// 					/>
	// 					<Button text="add section" className="w-100 mb-3" />
	// 				</>,
	// 			);
	// 		}),
	// 	);
	// 	return retArr;
	// };

	updateSectionTitle = (e, sectionIndex) => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'UPDATE_SECTION_TITLE',
			payload: e.target.value,
			sectionIndex,
		});
	};

	updateSubsectionTitle = (e, sectionIndex, subsectionIndex) => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'UPDATE_SUBSECTION_TITLE',
			payload: e.target.value,
			sectionIndex,
			subsectionIndex,
		});
	};

  updateTemplateName = e => {
    if (e.target.value.length === 0) {
      e.target.style.background = '#faf2f3';
    } else {
      e.target.style.background = '';
    }
    const {dispatch} = this.props;
    return dispatch({
      type: 'UPDATE_TEMPLATE_NAME',
      payload: e.target.value,
    });
  };

	makeIndicatorRed = () => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'INDICATOR_RED',
		});
	};

	addSubsection = (sectionIndex, subsectionIndex, subsectionId) => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'ADD_SUBSECTION',
			sectionIndex,
			subsectionIndex,
			removeReference: true,
		});
	};

	addDocumentUpload = (sectionIndex, subsectionIndex) => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'ADD_DOCUMENT_UPLOAD',
			sectionIndex,
			subsectionIndex,
		});
	};

	addMainSection = sectionIndex => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'ADD_SECTION',
			sectionIndex,
		});
	};

	closeModal = () => {
		this.setState({
			modalToShow: false
		})
	}

	openDeleteSectionModal = (sectionIndex, sectionDetails) =>{
		this.setState({
			modalToShow: 'DeleteSectionModal',
			deletedSectionIndex: sectionIndex,
			deletedSubsectionIndex: null,
			deletedSection: {
				actualSectionId: sectionDetails.id,
				...sectionDetails,
			},
		})
	}

	openDeleteSubSectionModal = (sectionIndex, subsectionIndex, sectionDetails, subSectionDetails) => {
		this.setState({
			modalToShow: 'DeleteSubSectionModal',
			deletedSectionIndex: sectionIndex,
			deletedSubsectionIndex: subsectionIndex,
			deletedSection: {
				actualSectionId: sectionDetails.id,
				actualSubsectionId: subSectionDetails.id,
				...subSectionDetails,
			},
		})
	}

	editVariable = (oldVar, newVar, editVarId) => {
		Swal.fire('Updating');
		Swal.showLoading();
		const {
			route: { template },
			match: {
				params: { id: templateId },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		/* eslint-disable */
		if (oldVar.name !== newVar.content.name) {
			// propagate changes

			template.sections.forEach(item => {
				if (item.content) {
					if (item.content.includes(stringToVarName(oldVar.name))) {
						item.content = item.content.replaceAll(
							stringToVarName(oldVar.name),
							stringToVarName(newVar.content.name),
						);
					}
					item.subsections.forEach(one => {
						if (
							one.content.includes(stringToVarName(oldVar.name))
						) {
							one.content = one.content.replaceAll(
								stringToVarName(oldVar.name),
								stringToVarName(newVar.content.name),
							);
						}
					});
				}
			});
			fetch(`${process.env.API_URL}/plan-templates/${templateId}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					name: template.name,
					headers: JSON.stringify(template.headers),
					content: JSON.stringify(template.sections),
				}),
			})
				.then(res => res.json())
				.then(rsp => {
					if (rsp.statusCode === 200) {
						fetch(
							`${
								process.env.API_URL
							}/plan-templates/${templateId}/variables/${editVarId}`,
							{
								method: 'PUT',
								headers: {
									Authorization: `Bearer ${token}`,
								},
								body: JSON.stringify({
									name: newVar.name,
									content: JSON.stringify({
										helpText: newVar.content.helpText,
										name: newVar.content.name,
										typeAnnotation:
											newVar.content.typeAnnotation,
										type: {
											id: newVar.content.type.id,
											value: newVar.content.type.value,
										},
									}),
								}),
							},
						)
							.then(res => res.json())
							.then(() => {
								Swal.close();
								window.location.reload();
							});
					}
					return null;
				});
		} else {
			fetch(
				`${
					process.env.API_URL
				}/plan-templates/${templateId}/variables/${editVarId}`,
				{
					method: 'PUT',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						name: newVar.name,
						content: JSON.stringify({
							helpText: newVar.content.helpText,
							name: newVar.content.name,
							typeAnnotation: newVar.content.typeAnnotation,
							type: {
								id: newVar.content.type.id,
								value: newVar.content.type.value,
							},
						}),
					}),
				},
			)
				.then(res => res.json())
				.then(() => {
					Swal.close();
					window.location.reload();
				});

			// fetch variable update
		}
	};

	deleteVariable = oldVar => {
		Swal.fire('Updating');
		Swal.showLoading();
		// propagate changes
		const {
			route: { template },
			match: {
				params: { id: templateId },
			},
		} = this.props;

		template.sections.forEach(item => {
			if (item.content) {
				if (item.content.includes(oldVar.name)) {
					item.content = item.content.replaceAll(
						`<span class=\"variable-highlight\">${
							oldVar.name
						}</span>`,
						'',
					);
				}
				item.subsections.forEach(one => {
					if (one.content.includes(oldVar.name)) {
						one.content = one.content.replaceAll(
							`<span class=\"variable-highlight\">${
								oldVar.name
							}</span>`,
							'',
						);
					}
				});
			}
		});
		/* eslint-disable */
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates/${templateId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				name: template.name,
				content: JSON.stringify(template.sections),
			}),
		})
			.then(res => res.json())
			.then(rsp => {
				if (rsp.statusCode === 200) {
					fetch(
						`${
							process.env.API_URL
						}/plan-templates/${templateId}/variables/${oldVar.id}`,
						{
							method: 'PUT',
							headers: {
								Authorization: `Bearer ${token}`,
							},
							body: JSON.stringify({
								name: oldVar.name,
								content: oldVar.content,
								active: false,
							}),
						},
					)
						.then(res => res.json())
						.then(resp => {
							Swal.close();
							window.location.reload();
						});
					return this.loadData();
				}
				return null;
			});
	};

	toggleUpdateMode = (
		val,
		updateTarget,
		updateType,
		actualSectionId,
		actualSubsectionId,
	) => {
		const {
			dispatch,
			route: { template },
		} = this.props;
		this.setState({
			updateMode: val,
			updateTarget,
			updateType,
		});
		let target =
			updateType === 'section' ? actualSectionId : actualSectionId + '-' + actualSubsectionId;
		return dispatch({
			type: 'SET_SCROLL_MODE',
			scrollTarget: target,
			scrollType: updateType,
		});
	};

	toggleUpdateFileMode = (
		val,
		updateTarget,
		updateType,
		actualSectionId,
		actualSubsectionId,
	) => {
		const {
			dispatch,
			route: { template },
		} = this.props;
		this.setState({
			updateMode: val,
			updateTarget,
			updateType,
		});
		let target = actualSectionId + '-' + actualSubsectionId;
		return dispatch({
			type: 'SET_SCROLL_MODE',
			scrollTarget: target,
			scrollType: updateType,
		});
	};


	cancelUpdateMode = () => {
		this.setState({
			updateMode: false,
			updateTarget: null,
			updateType: null,
			loading: true,
		});
		return this.loadData();
	};

	checkScroll = () => {
		const {
			route: { scrollTarget, scrollType, shouldScroll, template },
			dispatch,
		} = this.props;
		if (!shouldScroll) return null;
		document
			.getElementById(`${scrollType}-${scrollTarget}`)
			.scrollIntoView({ behavior: 'smooth' });
		return dispatch({ type: 'UNSET_SCROLL_MODE' });
	};

	onSectionChange = (item) => {
		this.setState({ visibleSubsection: null, visibleSection: item });
	};

	onSubSectionChange = (item) => {
		this.setState({ visibleSection: null, visibleSubsection: item });
	};

	onVisibilityChange = (section, subSection = null, subSectionIndex = null) => {
		if(this.state.startScrolling === true && this.state.isSectionScrollActive === true ) {
			if(subSection === null) {
				this.onSectionChange(section)
			} else {
				this.onSubSectionChange(subSection)
			}
			scrollToSidebarSection(subSectionIndex, this.sidebarRef, this.sectionRef)
		}
	}

	currentValues = {};

	getCurrentValuesFromState = () => {
		this.currentValues.visibleSection = this.state.visibleSection;
		this.currentValues.visibleSubsection = this.state.visibleSubsection;
		this.currentValues.page = this.state.page;
	}

	setCurrentValuesToState = () => {
		this.setState({
			visibleSection: this.currentValues.visibleSection,
			visibleSubsection: this.currentValues.visibleSubsection,
			page: this.currentValues.page
		});
	}

	nextPage = () => {
		this.getCurrentValuesFromState();
		new Promise((resolve) => {
			const {
				route: { template, filtered },
				dispatch,
			} = this.props;

			var lastSection = null;
			if (filtered.sections.length > 0) {
				lastSection = filtered.sections[filtered.sections.length -1];
			}

			var nextSectionIndexToLoad = 0;
			if (lastSection) {
				template.sections.map((section, index) => {
					if (section.id === lastSection.id) {
						if (template.sections[index + 1] != undefined) {
							nextSectionIndexToLoad = index + 1;
						}
					}
				})
			}

			if (nextSectionIndexToLoad > 0) {
				const temp = template.sections[nextSectionIndexToLoad];
				filtered.sections.push(temp);
			}

			resolve( dispatch({
				type: 'NEXT_SECTION',
				payload: filtered,
			}));
		}).then((dispatchResolve) => {
			this.setCurrentValuesToState();
			return dispatchResolve;
		});
	};

	isLastSection = (sectionId) => {
		const {
			route: { template }
		} = this.props;

		const lastSection = template.sections[template.sections.length -1];
		return lastSection.id === sectionId;
	}

	previousPage = () => {
		this.getCurrentValuesFromState();
		new Promise((resolve) => {
			const {
				route: { template, filtered },
				dispatch,
			} = this.props;

			var firstSection = null;
			if (filtered.sections.length > 0) {
				firstSection = filtered.sections[0];
			}

			var previousSectionIndexToLoad = -1;
			if (firstSection) {
				template.sections.map((section, index) => {
					if (section.id === firstSection.id) {
						if (template.sections[index - 1] != undefined) {
							previousSectionIndexToLoad = index - 1;
						}
					}
				})
			}

			if (previousSectionIndexToLoad >= 0) {
				const temp = template.sections[previousSectionIndexToLoad];
				filtered.sections = [
					temp,
					...filtered.sections,
				];
			}

			return resolve( dispatch({
				type: 'NEXT_SECTION',
				payload: filtered,
			}));

		}).then((dispatchResolve) => {
			this.setCurrentValuesToState();
			return dispatchResolve;
		});
	}

	showPage = (page) => {
		return new Promise((resolvePage) => {
			new Promise((resolve) => {
				const {
					route: { template, filtered },
					dispatch,
				} = this.props;

				filtered.sections = template.sections.filter(function (section, index) {
					return (index >= (page * sectionsPerPage - 1) && index < page * sectionsPerPage + sectionsPerPage)
						? section
						: false;
				});

				this.setPage(page);

				resolve( dispatch({
					type: 'NEXT_SECTION',
					payload: filtered,
				}));
			}).then(() => {
				resolvePage(true);
			});
		})
	}

	setPage = (page) => {
		this.setState({ page: page });
	}

	handleMainScroll = (e) => {
		if (this.state.yPosition > e.target.scrollTop ) {
			if (e.target.scrollTop < 300) {
				this.previousPage();
			}
		}
		this.setState({ yPosition: e.target.scrollTop });
		if( this.state.startScrolling === false ){
			this.setState({
				startScrolling: true
			});
		}
	};

	handleBackToTop = () => {
		// Get the first section
		const {
			route: { template },
		} = this.props;
		const firstSection = template.sections[0];
		this.onSectionChange(firstSection)
		this.setState({
			isSectionScrollActive: false,
		})

		this.mainWrapperRef.current.scrollTo({
			top: 0
		})
		this.sidebarRef.current.scrollTo({
			top: 0
		})
		window.scrollTo(0, 0);
	}

	scrollToSection = (section, subSection, type, page) => {
		this.setPage(page);
		let sectionId = `${type}-${section.id}`;
		if(subSection !== null) {
			sectionId += `-${subSection.id}`;
		}
		let element = document.getElementById(sectionId);
		this.setState({
			isSectionScrollActive: false
		})
		if (element) {
			this.scrollToElement(element);
			if(type === 'subsection') {
				this.onSubSectionChange(subSection)
			} else {
				this.onSectionChange(section)
			}
		} else {
			// The element is not loaded yet, so, load the page that includes the element
			this.showPage(page).then((pageLoaded) => {
				if (pageLoaded) {
					let newElement = document.getElementById(sectionId);
					if (newElement) {
						this.scrollToElement(newElement);
						if(type === 'subsection') {
							this.onSubSectionChange(subSection)
						} else {
							this.onSectionChange(section)
						}
					}
				}
			});
		}
	};

	scrollToElement = (element) => {
		element.scrollIntoView({
			block: 'start',
			behavior: 'smooth',
		});
	}

	componentDidUpdate() {
		this.checkScroll();
	}

	componentDidMount() {
		const {  viewMode } = this.props;
		if(viewMode === true) {
			this.setState({
				viewMode: true
			});
		}

		return this.loadData(true);
	}

	toggleSectionScroll = value => {
		this.setState({
			isSectionScrollActive: value
		})
	}

	onDeleteAndPublishSection = async () => {
		const { deletedSection } = this.state;
		const {
			match: {
				params: { id: templateId },
			},
		} = this.props;
    const updateBody = {
      type: 'delete',
      updatedContent: JSON.stringify(deletedSection),
      actualSectionId : deletedSection.item.id,
      actualSubsectionId: deletedSection.actualSubsectionId,
    }
		await TemplateService.publishUpdates(templateId, updateBody)
			.then(response => {
				if(response.statusCode === 201) {
					fireSwal('success', 'Successfully deleted!');
				} else {
					fireSwal('error', response.error.description)
				}
			})
	}

	onWheelHandler = () => {
		if (this.state.isSectionScrollActive === false) {
			this.toggleSectionScroll(true)
		}
	}

	componentWillUnmount() {
		const {
			route: { templateLoading },
		} = this.props;
		const templateSaved = this.state.templateSavedBeforeUnmount;
		const loading = this.state.loading;
		if (!templateSaved && !loading && !templateLoading) {
			this.saveTemplate(false);
		}
	}

	render() {
		const {
			commonVariables,
			templateVariables,
			loading,
			updateMode,
			updateTarget,
			updateType,
			panel,
			visibleSection,
			visibleSubsection
		} = this.state;
		const { nodes, modalToShow, deletedSectionIndex, deletedSubsectionIndex, viewMode, deletedSection } = this.state;
		const {
			route: { template, indicator, templateLoading },
			match: {
				params: { id: templateId },
			},
			history: {
				location: { pathname },
			},
			dispatch,
		} = this.props;
		if (templateLoading || loading) return <LoadingSpinner />;

		return (
			<>
				{modalToShow  === 'DeleteSectionModal' && (
					<DeleteSectionModal
						type='section'
						title='Delete section?'
						description='This will remove the section title and all of the subsections within this section'
						sectionIndex={deletedSectionIndex}
						subsectionIndex={deletedSubsectionIndex}
						deletedSection={deletedSection}
						closeDeleteModal={this.closeModal}
						onDeleteAndPublishSection={this.onDeleteAndPublishSection}
					/>
				)}
				{modalToShow === 'DeleteSubSectionModal' && (
					<DeleteSectionModal
						type='sub-section'
						title='Delete subsection?'
						description='This will remove the sub section title and all of the content within this sub section'
						sectionIndex={deletedSectionIndex}
						subsectionIndex={deletedSubsectionIndex}
						deletedSection={deletedSection}
						closeDeleteModal={this.closeModal}
						onDeleteAndPublishSection={this.onDeleteAndPublishSection}
					/>
				)}
				<Wrapper>
					<div
						id='main'
						style={{
							width: '100%',
							height: '100vh',
							overflow: 'auto',
						}}
						ref={this.mainWrapperRef}
						onScroll={this.handleMainScroll}
						onWheelCapture={this.onWheelHandler}
					>
						<div id="top-element"></div>
						<BackToTopButton
							onClick={this.handleBackToTop}
							containerId="main"
							topElementId="top-element"
							elementOnRightId="sidebar-wrapper"
							show={this.state.showBackToTopButton}
						/>
						<TextAreaContainer panel={panel} className="pt-3">
							<HeaderWrap className="d-flex align-items-center justify-content-between">
								<div>
									<div className="d-flex align-items-center">
										<BackButtonWrap
											onClick={() => this.saveTemplate(true)}
										>
											<Icon icon="arrow-left" />{' '}
											<BackText>Save and Exit</BackText>
										</BackButtonWrap>
										{updateMode && (
											<Tag intent="danger" round>
												Autosave disabled during update
												creation
											</Tag>
										)}
										{!updateMode && (
											<Tag minimal round>
												{indicator ? (
													<AutoSaveWrap>
														<SmallIcon icon="tick" />
														Auto Saved
														<SmallIcon icon="dot" />
														{template.lastUpdated
															? format(
																	new Date(
																		template.lastUpdated,
																	),
																	'HH:mm a',
															  )
															: null}
													</AutoSaveWrap>
												) : (
													'Not Saved'
												)}
											</Tag>
										)}
									</div>
									<TemplateName className="mb-3 mt-2">
										{pathname.includes('edit')
											? 'Edit Template'
											: 'Create New Template'}
									</TemplateName>
								</div>

								<Tabs
									id="addPlanTabs"
									defaultSelectedTabId={panel}
									onChange={val =>
										this.setState({ panel: val })
									}
								>
									<Tab
										id="contextualeditor"
										title="Contextual Editor"
										panel={null}
									/>
									<Tab
										id="EventLogs"
										title="Event log"
										panel={null}
									/>
								</Tabs>
							</HeaderWrap>

							{panel === 'contextualeditor' &&
								<>
									<BlankInput
										value={template.name}
										readOnly={viewMode}
										onChange={e => this.updateTemplateName(e)}
									/>
									{template.sections.map((item, index) => (
										<VisibilitySensor
										onChange={() =>
											this.onVisibilityChange(item)
										}
										key={`section-${item.id}`}
										scrollCheck
										partialVisibility="top"
										offset={{top:0}}
									>
										<React.Fragment key={item.id}>
											<div className="d-flex align-items-center justify-content-between w-100 mb-2">
												{template.sections.length > 1 && !viewMode &&  (
													<DeleteButton
														text="DELETE"
														intent="danger"
														minimal
														onClick={() => this.openDeleteSectionModal(index, item)}
													/>
												)}
												<div className="d-flex justify-content-between w-100">
													<BlankInput
														value={item.title}
														type="section"
														id={`section-${item.id}`}
														readOnly={viewMode}
														onChange={e =>
															this.updateSectionTitle(
																e,
																index,
															)
														}
													/>
												</div>
											</div>

											<SingleEditor
												viewMode={viewMode}
												editVariable={this.editVariable}
												makeIndicatorRed={this.makeIndicatorRed}
												indicator={indicator}
												sectionIndex={index}
												subsectionIndex={null}
												updateNodes={this.updateNodes}
												content={item.content}
												openVariables={this.openVariables}
												commonVariables={commonVariables}
												templateVariables={templateVariables}
												templateId={templateId}
												reloadVariables={this.reloadVariables}
												deleteVariable={this.deleteVariable}
												updateMode={updateMode}
												updateType={updateType}
												updateTarget={updateTarget}
												toggleUpdateMode={this.toggleUpdateMode}
												cancelUpdateMode={this.cancelUpdateMode}
												saveAndReload={this.saveAndReload}
												type="section"
												actualSectionId={item.id}
												template={template}
												subsection={item}
											/>
											{item.subsections.map(
												(single, subindex) => (
													<VisibilitySensor
															onChange={() =>
																this.onVisibilityChange(item, single, subindex)
															}
															key={`subsection-${subindex}-${single.id}`}
															scrollCheck
															partialVisibility="top"
															offset={{top:0}}
														>
														<React.Fragment key={single.id}>
															<div className="d-flex align-items-center justify-content-between w-100 mb-2">
																{!viewMode && (
																<DeleteButton
																	text="DELETE"
																	intent="danger"
																	minimal
																	onClick={() => this.openDeleteSubSectionModal(index, subindex, item, single)}
																/>
																)}
																<div className="d-flex justify-content-between w-100">
																	<BlankInput
																		value={single.title}
																		type="subsection"
																		readOnly={viewMode}
																		id={`subsection-${item.id}-${single.id}`}
																		onChange={e =>
																			this.updateSubsectionTitle(
																				e,
																				index,
																				subindex
																			)
																		}
																	/>
																</div>
															</div>
															{!single.fileSection && (
																<SingleEditor
																	viewMode={viewMode}
																	editVariable={
																		this.editVariable
																	}
																	makeIndicatorRed={
																		this
																			.makeIndicatorRed
																	}
																	indicator={indicator}
																	sectionIndex={index}
																	subsectionIndex={
																		subindex
																	}
																	updateNodes={
																		this.updateNodes
																	}
																	content={single.content}
																	openVariables={
																		this.openVariables
																	}
																	commonVariables={
																		commonVariables
																	}
																	templateVariables={
																		templateVariables
																	}
																	templateId={templateId}
																	reloadVariables={
																		this.reloadVariables
																	}
																	deleteVariable={
																		this.deleteVariable
																	}
																	updateMode={updateMode}
																	updateType={updateType}
																	updateTarget={
																		updateTarget
																	}
																	toggleUpdateMode={
																		this
																			.toggleUpdateMode
																	}
																	cancelUpdateMode={
																		this
																			.cancelUpdateMode
																	}
																	actualSubsectionId={
																		single.id
																	}
																	actualSectionId={
																		item.id
																	}
																	type="subsection"
																	template={template}
																	subsection={single}
																	saveAndReload={this.saveAndReload}
																/>
															)}
															{single.fileSection && (
																<FileSection
																	viewMode={viewMode}
																	makeIndicatorRed={
																		this
																			.makeIndicatorRed
																	}
																	saveAndReload={this.saveAndReload}
																	indicator={indicator}
																	sectionIndex={index}
																	subsectionIndex={
																		subindex
																	}
																	updateNodes={
																		this.updateNodes
																	}
																	subsection={single}
																	dispatch={dispatch}
																	openVariables={
																		this.openVariables
																	}
																	commonVariables={
																		commonVariables
																	}
																	templateVariables={
																		templateVariables
																	}
																	templateId={templateId}
																	reloadVariables={
																		this.reloadVariables
																	}
																	updateMode={updateMode}
																	updateType={updateType}
																	updateTarget={
																		updateTarget
																	}
																	toggleUpdateFileMode={this.toggleUpdateFileMode}
																	cancelUpdateMode={
																		this
																			.cancelUpdateMode
																	}
																	actualSubsectionId={
																		single.id
																	}
																	actualSectionId={
																		item.id
																	}
																	type="subsection"
																	template={template}
																/>
															)}

														</React.Fragment>
													</VisibilitySensor>
													))}
													{!viewMode && (
														<>
															<LinkButton
																className="pb-2"
																icon="add"
																text="Add subsection"
																intent="secondary"
																onClick={() =>
																	this.addSubsection(
																		index,
																		(item.subsections?.length - 1) || 0,
																		(item.subsections?.length > 0) ? item.subsections[item.subsections.length - 1].id : null,
																	)
																}
															/>
															<LinkButton
																className="pb-2"
																icon="add"
																text="Add document upload"
																intent="secondary"
																onClick={() =>
																	this.addDocumentUpload(
																		index,
																		(item.subsections?.length - 1) || 0,
																		(item.subsections?.length > 0) ? item.subsections[item.subsections.length - 1].id : null,
																	)
																}
															/>
														</>
													)}
											{template.sections.length - 1 === index && !viewMode && (
												<>
													<Split />
													<div className="d-flex justify-content-around">
														<LinkButton
															className="pb-2"
															icon="add"
															text="Add main section"
															minimal
															onClick={() =>
																this.addMainSection(
																	index,
																)
															}
														/>
													</div>
												</>
											)}
										</React.Fragment>
										</VisibilitySensor>
									))}

								</>
							}

							{panel === 'EventLogs' &&
								<EventLogs
									wrapperId="main"
									templateId={ parseInt(template.id) }
								/>
							}
						</TextAreaContainer>
					</div>
					<SideBar
						nodes={nodes}
						updateNodes={this.updateNodes}
						loading={templateLoading}
						scrollToSection={this.scrollToSection}
						visibleSection={visibleSection}
						visibleSubsection={visibleSubsection}
						sectionsPerPage={sectionsPerPage}
						setPage={this.setPage}
						sectionRef={this.sectionRef}
						sidebarRef={this.sidebarRef}
						viewMode={viewMode}
					/>
				</Wrapper>
			</>
		);
	}
}

AddPlan.propTypes = {
	dispatch: PropTypes.func,
	match: PropTypes.object,
	history: PropTypes.object,
	route: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(AddPlan);
