import React, { useEffect, useState, Suspense } from 'react'
import styled from 'styled-components';
import PropTypes from 'prop-types';
const EventLogItem = React.lazy(() => import('./EventLogItem'));
import { EventLogPlaceholder } from './EventLogPlaceholder';
import { EventLogNoResults } from './EventLogNoResults';

const EventLogsWrapper = styled.div`
    padding: 40px 60px;
`;

export const EventLogs = ({wrapperId, templateId}) => {

    if (!templateId) return <></>;

    const [isLoading, setIsLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(1);
    const [totalLogs, setTotalLogs] = useState(0);
    
    const getEventLogs = () => {
        const logsPerPage = 10;
        setIsLoading(true);
        const token = JSON.parse(localStorage.getItem('user')).jwt;
        fetch(`${process.env.API_URL}/plan-templates/${templateId}/logs?page=${page}&perPage=${logsPerPage}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(res => res.json())
        .then(resp => {
            if (resp.data) {
                setPage(page + 1);
                setTotalLogs(resp.data.metadata.totalRecords);
                if (resp.data.records.length > 0) {
                    resp.data.records.forEach(log => {
                        setLogs(logs => [...logs, log]);
                    });
                }
            }
            setIsLoading(false);
        });
    }

    const handleScroll = (e) => {
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = e.target;

        if (scrollTop + clientHeight + 150 > scrollHeight && !isLoading && logs.length < totalLogs ) {
            getEventLogs();
		}
    }

    useEffect(() => {
        getEventLogs();
    }, []);

    useEffect(() => {
        const wrapperElement = document.getElementById(wrapperId);
        wrapperElement.addEventListener(
            "scroll", handleScroll, { passive: true }
        );
        return () => {
            wrapperElement.removeEventListener("scroll", handleScroll);
        }
    }, [isLoading]);

    return (
        <EventLogsWrapper>
            { logs.length > 0 &&
                logs.map(item => (
                    <Suspense key={item.id} fallback={<></>}>
                        <EventLogItem 
                            key={item.id}
                            item={item}
                        />
                    </Suspense>
                ))
            }
            { logs.length === 0 && !isLoading && <EventLogNoResults /> }
            { isLoading && <EventLogPlaceholder /> }
        </EventLogsWrapper>
    )
}

EventLogs.propTypes = {
    wrapperId: PropTypes.string.isRequired,
	templateId: PropTypes.number.isRequired,
};