import React from 'react';
import { EmptyState, noResult } from 'componentsV2';
import './css/EventLog.scss';

export const EventLogEmptyState = () => {
    return (
        <div className='event-log-empty-wrapper'>
            <div className='empty-content'>
                <EmptyState
                    header="No event logs found for this template"
                    icon={noResult}
                />
            </div>
        </div>
    )
}
