import React from 'react';
import { FormModal, Input } from 'componentsV2';

export const RenameSafetyPlanModal = ({
	modalName,
	modalToShow,
	loading,
	formData,
	closeModal,
	handleChangeTitle,
	handleSubmit,
}) => {
	return (
		<FormModal
			isOpen={modalToShow === modalName}
			onClose={closeModal}
			title={'Edit plan title'}
			showCloseButton={false}
			confirmBtn={{
				title: 'Save',
				disabled: formData.title?.trim().length == 0,
				onClick: handleSubmit,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: closeModal,
			}}
			loading={loading}
			scrollContent={false}
		>
			<div className="w-100">
				<Input
					value={formData.title}
					name="title"
					label="Plan title"
					placeholder="Enter the plan title here"
					isRequired={true}
					onChange={handleChangeTitle}
				/>
			</div>
		</FormModal>
	);
};
