import React from 'react';
import { ConfirmationModal } from 'componentsV2';
import { REMOVE_MODAL } from '../../ShapeContentItemsList/ContentConstansts';

export const RemoveContentModal = ({
	                                   loading,
	                                   modalToShow,
	                                   closeModal,
	                                   modalData,
	                                   handleRemoveContent,
                                   }) => {
	return (
		<ConfirmationModal
			isOpen={modalToShow === REMOVE_MODAL}
			title={modalData.title}
			subtitle={modalData.description}
			showCloseButton={false}
			customClassName={'map-container-modals'}
			cancelBtn={{
				title: modalData.cancelBtnTitle,
				onClick: closeModal,
			}}
			confirmBtn={{
				title: modalData.confirmBtnTitle,
				onClick: handleRemoveContent,
				type: 'primaryDanger',
			}}
			loading={loading}
			btnsSize='lg'
			icon={{
				icon: 'remove',
				iconColor: 'error',
			}}
		/>
	);
};
