import React from 'react';
import { ContactsProviders } from './';
import { Contacts } from './Components';
import { ContactsModals } from './Modals';

export const ContactsV2 = ({ history, match }) => {
	return (
		<ContactsProviders history={history}>
			<ContactsModals match={match} />
			<Contacts match={match} />
		</ContactsProviders>
	);
};
