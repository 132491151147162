import React, { createContext, useState } from 'react';
import { RapidAlarmModals } from '../Modals';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import {
	ACTIVATE_REUNIFICATION_MODAL,
	END_AlARM_MODAL,
	ACTIVATE_END_ROLLCALL_MODAL,
	DOWNLOAD_PAST_AlARM_MODAL,
	ADD_STUDENT_TO_REUNIFICATION_MODAL,
	EDIT_GUARDIAN_DETAILS_MODAL,
} from '../consts';

export const RapidAlarmsContext = createContext();

export const RapidAlarmsProviders = ({
	children,
	history,
	reunificationLocation,
	setReunificationLocation,
	openedFromHomePage = false,
}) => {
	const [modalToShow, setModalToShow] = useState(null);
	const [activeAlarm, setActiveAlarm] = useState();
	const [pastAlarm, setPastAlarm] = useState();
	const [pastAlarmHistory, setPastAlarmHistory] = useState();
	const [requestType, setRequestType] = useState();
	const permissions = useSelector(state => state.route.permissions);
	const editPermissionOnRapidAlarm = checkPermission('respond_edit', permissions);
	const editPermissionOnReunification = checkPermission('reunification_edit', permissions);
	const [isRollCallStarted, setIsRollCallStarted] = useState(false);
	const [isReunificaton, setIsReunification] = useState(false);
	const [alarmId, setAlarmId] = useState(false);
	const [guardianDetails, setGuardianDetails] = useState();
	const closeModal = () => {
		setModalToShow(null);
	};

	const onEndAlarmClick = (activeAlarm, isReunificaton = false) => {
		setModalToShow(END_AlARM_MODAL);
		setActiveAlarm(activeAlarm);
		setIsReunification(isReunificaton);
	};

	const onActiveEndRollCall = (alarm, requestType) => {
		setModalToShow(ACTIVATE_END_ROLLCALL_MODAL);
		setActiveAlarm(alarm);
		setRequestType(requestType);
	};

	const onActivateReunification = (alarm, isRollCallStarted) => {
		setModalToShow(ACTIVATE_REUNIFICATION_MODAL);
		setActiveAlarm(alarm);
		setIsRollCallStarted(isRollCallStarted);
	};

	const onDownloadClick = (pastAlarm, pastAlarmHistory) => {
		setModalToShow(DOWNLOAD_PAST_AlARM_MODAL);
		setPastAlarm(pastAlarm);
		setPastAlarmHistory(pastAlarmHistory);
	};

	const onAddStudentToReunification = alarmId => {
		setModalToShow(ADD_STUDENT_TO_REUNIFICATION_MODAL);
		setAlarmId(alarmId);
	};

	const onEditGuardianDetails = (guardianDetails, alarmId) => {
		setModalToShow(EDIT_GUARDIAN_DETAILS_MODAL);
		setGuardianDetails(guardianDetails);
		setAlarmId(alarmId);
	};

	const modalFunctions = {
		modalToShow,
		setModalToShow,
		closeModal,
		editPermissionOnRapidAlarm,
		editPermissionOnReunification,
		onEndAlarmClick,
		onActiveEndRollCall,
		onActivateReunification,
		onDownloadClick,
		onAddStudentToReunification,
		onEditGuardianDetails,
		openedFromHomePage,
	};

	return (
		<RapidAlarmsContext.Provider value={modalFunctions}>
			<RapidAlarmModals
				modalToShow={modalToShow}
				closeModal={closeModal}
				activeAlarm={activeAlarm}
				pastAlarm={pastAlarm}
				pastAlarmHistory={pastAlarmHistory}
				requestType={requestType}
				history={history}
				isRollCallStarted={isRollCallStarted}
				reunificationLocation={reunificationLocation}
				setReunificationLocation={setReunificationLocation}
				isReunificaton={isReunificaton}
				alarmId={alarmId}
				guardianDetails={guardianDetails}
			/>
			<>{children}</>
		</RapidAlarmsContext.Provider>
	);
};
