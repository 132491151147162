import React from 'react';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import TasksRow from './tasksrow';

const TableWrapper = styled.div`
	display: flex;
`;

const TableTitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	margin-bottom: 0;
`;

const TableContent = styled.table`
	thead {
		tr {
			border-top: 0 !important;
			th {
				border: 0 !important;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				color: #738694;
			}
		}
	}
	tbody {
		tr {
			th {
				font-weight: normal;
				vertical-align: middle;
			}
			td {
				&:nth-child(2),
				&:nth-child(3) {
					text-transform: capitalize;
				}
				vertical-align: middle;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				color: #10161a;
			}
			&:last-child {
				border-bottom: 1px solid #dee2e6;
			}
		}
	}
`;

const Wrapper = styled.div`
	margin-left: -15px;
	margin-right: -15px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
	background: #fff;
`;

class Tasks extends React.Component {
	state = {
		tasks: [],
		loading: true,
	};

	getData = () => {
		this.setState({ loading: true });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/tasks`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				this.setState({
					tasks: resp.data,
					loading: false,
				});
			});
	};

	componentDidMount() {
		return this.getData();
	}

	render() {
		const { tasks, loading } = this.state;
		if (loading) return <LoadingSpinner />;
		return (
			<Wrapper>
				<TableWrapper>
					<TableContent
						className="table"
						style={{ backgroundColor: '#fff' }}
					>
						<thead>
							<tr>
								<td>
									<TableTitle
										style={{
											color: '#738694',
										}}
									>
										Task Title
									</TableTitle>
								</td>
								<td>
									<div className="d-flex">
										<TableTitle
											style={{
												color: '#738694',
											}}
										>
											Due date
										</TableTitle>
										<Icon
											style={{
												color: '#738694',
											}}
											icon="caret-down"
										/>
									</div>
								</td>
								<td>
									<div className="d-flex">
										<TableTitle
											style={{
												color: '#738694',
											}}
										>
											Assigned to
										</TableTitle>
										<Icon
											style={{
												color: '#738694',
											}}
											icon="caret-down"
										/>
									</div>
								</td>
								<td>
									<div className="d-flex">
										<TableTitle
											style={{
												color: '#738694',
											}}
										>
											Actions
										</TableTitle>
										<Icon
											style={{
												color: '#738694',
											}}
											icon="caret-down"
										/>
									</div>
								</td>
							</tr>
						</thead>
						<tbody>
							{tasks.map(item => (
								<TasksRow item={item} key={item.id} />
							))}
						</tbody>
					</TableContent>
				</TableWrapper>
			</Wrapper>
		);
	}
}

export { Tasks };
