/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Tab, Tabs } from '@blueprintjs/core';
import { createStructuredSelector } from 'reselect';
import { PageHeader } from 'components/PageHeader';
import { LoadingSpinner } from 'components/LoadingSpinner';
import {
	Admin,
	Dashboard,
	LogDrills,
	Statistics,
	SearchDrills,
	Analytics,
	Scenarios,

} from './panels';
import ScheduleDrills from './panels/ScheduleDrills';
import Settings from './panels/Settings';

import { checkPermission } from 'utilities/permissions';
import selectRoute from '../App/selectors';
import { setDrillsDashLastSelectedTab } from '../../routeReducer';


const TABS = [
	{
		id: 'admin',
		title: 'Admin',
		panel: Admin,
	},
	{
		id: 'dashboard',
		title: 'Dashboard',
		panel: Dashboard,
	},
	{
		id: 'scheduleDrills',
		title: 'Schedule Drills',
		panel: ScheduleDrills,
	},
	{
		id: 'logDrills',
		title: 'Log A Drill',
		panel: LogDrills,
	},
	{
		id: 'SearchDrills',
		title: 'Drill log search',
		panel: SearchDrills,
	},
	/*{
		id: 'scenarios',
		title: 'Scenarios',
		panel: Scenarios,
	},*/
	{
		id: 'statistics',
		title: 'Statistics',
		panel: Statistics,
	},
	{
		id: 'analytics',
		title: 'Analytics',
		panel: Analytics,
		viewPermission: 'drilllogs_set_reqs'
	},
	{
		id: 'settings',
		title: 'Settings',
		panel: Settings,
		viewPermission: 'drilllogs_set_reqs',
	},
];

const getFirstTab = canViewAdminPanel => TABS[canViewAdminPanel ? 0 : 1].id;

class DashboardTabs extends React.Component {
	state = {
		loading: true,
		primaryBuildingId: null,
		canViewAdminPanel: false,
		panelsToRender: {},
	};

	componentDidMount = () => {
		const {
			route: { permissions },
		} = this.props;
		const canViewAdminPanel = permissions.isSysAdmin;
		const {
			route: { drillsDashboardLastTabId },
		} = this.props;

		return this.setState({
			canViewAdminPanel,
			loading: false,
			panelsToRender: {
				[drillsDashboardLastTabId]: true,
				[getFirstTab(canViewAdminPanel)]: true,
			},
		});
	};

	renderTab = tabId => {
		const { panelsToRender } = this.state;
		const { dispatch } = this.props;

		dispatch(setDrillsDashLastSelectedTab(tabId));

		if (!panelsToRender[tabId]) {
			this.setState({
				panelsToRender: {
					...panelsToRender,
					[tabId]: true,
				},
			});
		}
	};

	getTabComponent = (id, index) => {
		const {
			history,
			route: { permissions },
		} = this.props;
		const { panelsToRender } = this.state;

		if (panelsToRender[id]) {
			const Component = TABS[index].panel;
			return <Component permissions={permissions} history={history} />;
		}

		return null;
	};

	render() {
		const { loading } = this.state;
		const {
			route: { drillsDashboardLastTabId, permissions },
		} = this.props;
		const { history } = this.props;
		const canViewAdminPanel = permissions.isSysAdmin;

		if (loading) return <LoadingSpinner />;

		return (
			<div className="container-fluid">
				<PageHeader
					title="Drill Logs"
					actionTitle="View Printable Reports"
					onActionClick={() => {
						history.push(`/newReportPage`);
					}}
				/>
				<Tabs
					id="drillsTabs"
					className="tabs"
					onChange={this.renderTab}
					defaultSelectedTabId={
						drillsDashboardLastTabId ||
						getFirstTab(canViewAdminPanel)
					}
				>
					{TABS.map((item, index) => {
						if (item.id === 'analytics') {
							return null;
						}

						if (item.id === 'admin' && !permissions.isSysAdmin) {
							return null;
						}

						if (
							item.viewPermission &&
							!checkPermission(item.viewPermission, permissions)
						) {
							return null;
						}

						return (
							<Tab
								key={item.id}
								id={item.id}
								title={item.title}
								panel={this.getTabComponent(item.id, index)}
								permissions={permissions}
								history={this.props.history}
							/>
						);
					})}
				</Tabs>
			</div>
		);
	}
}

DashboardTabs.propTypes = {
	history: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(DashboardTabs);
