import React from 'react';
import PropTypes from 'prop-types';
import './css/LoadingSpinner.scss';

export const LoadingSpinner = ({ message, size = 'medium', className = '' }) => (
	<div className={`loading-spinner ${className}`}>
		<div className={`spinner-icon size-${size}`} />
		{message && <div className="spinner-message">{message}</div>}
	</div>
);

LoadingSpinner.propTypes = {
	message: PropTypes.string,
	className: PropTypes.string,
	size: PropTypes.string,
};
