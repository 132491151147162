import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Input } from 'components/Input';
import { Tag } from '@blueprintjs/core';
import { Button } from 'components/Button';
import { SmallLoading } from 'components/SmallLoading';

const StyledTag = styled(Tag)`
	background: #faf2f3 !important;
	color: #9f1c3a !important;
	margin-left: 8px;
`;

class TextVariable extends React.Component {
	constructor(props) {
		super(props);
		const { item } = this.props;
		this.state = {
			item,
			showLoad: false,
			showButton : false
		};
	}

	setShowLoad = val => this.setState({ showLoad: val });

	updateVariable = () => {
		this.setState({ showLoad: true });
		const { item, inputValue } = this.state;
		const { templateId, setIsUpdated, setCanClose = null } = this.props;
		if (setCanClose) {
			setCanClose(false);
		}
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		if (inputValue === '') {
			if (item.value) {
				return fetch(
					`${
						process.env.API_URL
					}/district-templates/${templateId}/variablevalues/${
						item.id
					}`,
					{
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
				.then(res => res.json())
				.then(() => {
					this.setState({ showButton: false, showLoad: false });
					setIsUpdated(true);
				});
			}
			return null;
		}
		if (item.value) {
			return fetch(
				`${
					process.env.API_URL
				}/district-templates/${templateId}/variablevalues/${item.id}`,
				{
					method: 'PUT',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						variable_name: item.variable.name,
						variable_value: inputValue,
					}),
				},
			)
			.then(res => res.json())
			.then(() => {
				this.setState({ showButton: false, showLoad: false });
				setIsUpdated(true);
			});
		}
		return fetch(
			`${
				process.env.API_URL
			}/district-templates/${templateId}/variablevalues`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					variable_name: item.variable.name,
					variable_value: inputValue,
				}),
			},
		)
		.then(res => res.json())
		.then(resp => {
			const { item: oldItem } = this.props;
			oldItem.value = inputValue;
			oldItem.id = resp.data.id;
			setIsUpdated(true);
			this.setState({ item: oldItem, showButton: false, showLoad: false });
		});
	};

	contentChanged = e => {
		this.setState({ showButton: true, inputValue: e.target.value });
	};

	render() {
		const { item, showButton, showLoad } = this.state;
		return (
			<div className="mt-3">
				<div className="d-flex align-item-center justify-content-between mb-2">
					<p className="mb-0">{item.variable.content.name}</p>
					<StyledTag round>Used in {item.total} places</StyledTag>
				</div>
				<Input
					placeholder="Input Text"
					large
					defaultValue={item.value}
					onChange={e => this.contentChanged(e)}
					helpText={item.variable.content.helpText}
				/>
				{showLoad && (
					<div className="d-flex justify-content-end">
						<SmallLoading />
					</div>
				)}
				{showButton && !showLoad && (
					<div className="d-flex justify-content-end">
						<Button
							onClick={this.updateVariable}
							text="Save Changes"
							className="mt-2"
							intent="primary"
						/>
					</div>
				)}
			</div>
		);
	}
}

TextVariable.propTypes = {
	templateId: PropTypes.string,
	item: PropTypes.object,
	setCanClose: PropTypes.func,
};

export default TextVariable;
