import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notifications } from 'assets/icons';
import { initializeSocket } from 'utilities/socket';
import { Tooltip, TooltipPosition } from 'componentsV2';
import { useActiveAlarms } from 'hooks';
import { RapidAlarmService } from 'services';
import { ALARM_MODES } from 'pages/RapidAlarms/Modals/ActivateAlarmModal/consts';
import './AlarmButton.scss';

export const AlarmButton = ({ setModalToShow, permissions }) => {
	const dispatch = useDispatch();
	const [localToggleState, setLocalToggleState] = useState(false);

	const { activeAlarmList } = useActiveAlarms([], []);
	const [allowedSites, setAllowedSites] = useState([]);
	const [tooltipMessage, setTooltipMessage] = useState(null);
	const [hasMultipleSitePermission, setHasMultipleSitePermission] = useState(false);
	const [isAllowAlarmActivation, setIsAllowAlarmActivation] = useState(false);
	const [isSocketConnected, setSocketConnected] = useState(null);
	const [isActiveAlarm, setIsActiveAlarm] = useState(false);
	const [activeAlarmBuildingsIds, setActiveAlarmBuildingsIds] = useState([]);

	const getAllowedSitesList = () => {
		RapidAlarmService.getAlarmSites()
			.then(response => {
				if (response.statusCode === 200) {
					const sites = response.data;
					setHasMultipleSitePermission(sites.length > 1);
					setAllowedSites(sites);
				}
			})
			.catch(err => {})
			.finally(() => {});
	};

	const initializeAlarmSocket = () => {
		const { initializedSocket } = initializeSocket(['alarms']);
		if (!isSocketConnected) {
			initializedSocket.on('event', event => {
				const alarmBuildingId = event.data.buildingId;
				setActiveAlarmBuildingsIds(prevAlarmsBuildings => {
					if (event.name === 'alarms:created' && event.data.mode.toLowerCase() == ALARM_MODES.alarm.toLowerCase()) {
						return [...prevAlarmsBuildings, { alarmBuildingId: alarmBuildingId, alarmMode: event.data.mode }];
					} else if (event.name === 'alarms:ended') {
						return prevAlarmsBuildings.filter(alarm => alarm.alarmBuildingId !== alarmBuildingId);
					} else {
						// Return the previous state if no changes are needed
						return prevAlarmsBuildings;
					}
				});
				if (event.name === 'alarms:created' || event.name === 'alarms:ended') {
					setLocalToggleState(prevState => !prevState);
				}
			});
			setSocketConnected(true);
			return () => {
				if (initializedSocket.connected) {
					initializedSocket.disconnect();
				}
			};
		}
	};

	const checkIsAllowAlarmActivation = activeAlarmBuildingsIds => {
		setIsActiveAlarm(activeAlarmBuildingsIds.length > 0);
		const areAllActive = allowedSites.every(site => {
			const matchingAlarm = activeAlarmBuildingsIds.find(alarm => alarm.alarmBuildingId === site.team_id);
			return matchingAlarm && matchingAlarm.alarmMode?.toLowerCase() == ALARM_MODES.alarm.toLowerCase();
		});
		setIsAllowAlarmActivation(!areAllActive);
	};

	useEffect(() => {
		dispatch({
			type: 'RELOAD_FETCHED_LIST',
			payload: !localToggleState,
		});
	}, [localToggleState, dispatch]);

	useEffect(() => {
		checkIsAllowAlarmActivation(activeAlarmBuildingsIds);
	}, [activeAlarmBuildingsIds, isActiveAlarm, allowedSites]);

	useEffect(() => {
		const activeAlarmBuildingEPIds = activeAlarmList.map(obj => ({ alarmBuildingId: obj.building.id, alarmMode: obj.mode }) );
		setActiveAlarmBuildingsIds(activeAlarmBuildingEPIds);
	}, [activeAlarmList, allowedSites]);

	useEffect(() => {
		getAllowedSitesList();
		initializeAlarmSocket();
	}, []);

	useEffect(() => {
		if (isActiveAlarm) {
			if (hasMultipleSitePermission && isAllowAlarmActivation) {
				// that's mean there is an alarm active in other site, and still have sites without alarm
				setTooltipMessage(
					'Only one alarm can be active in a site. Click alarm icon to activate alarm for another site.',
				);
			} else if (!isAllowAlarmActivation) {
				// that's mean there is an active alarm in the only/all building(s) has access to
				setTooltipMessage(
					'This site has an emergency alarm in progress. Only one alarm can be activated for a site at a time.',
				);
			}
		} else {
			setTooltipMessage(null);
		}
	}, [isActiveAlarm, isAllowAlarmActivation, hasMultipleSitePermission, activeAlarmBuildingsIds]);
	if (allowedSites && allowedSites.length == 0) return null;

	return (
		<div className="alarm-btn-container">
			<div
				className={`alarm-btn ${isActiveAlarm ? 'active-alarm' : ''} ${
					!isAllowAlarmActivation ? 'alarm-activation-denied' : ''
				}`}
				onClick={
					!isActiveAlarm || isAllowAlarmActivation
						? e => {
								e.preventDefault();
								setModalToShow();
						  }
						: null
				}
			>
				<div className="alarm-btn-inner-border">
					{tooltipMessage && (
						<Tooltip tooltipContent={tooltipMessage} theme="dark">
							<div className="alarm-btn-inner-gradient">
								<img src={notifications} />
							</div>
						</Tooltip>
					)}
					{!tooltipMessage && (
						<div className="alarm-btn-inner-gradient">
							<img src={notifications} />
						</div>
					)}
				</div>
			</div>

			<span className={`alarm-btn-label ${isActiveAlarm ? 'active-alarm' : ''}`}>
				{isActiveAlarm ? 'Alarm Active' : 'Activate Alarm'}
			</span>
		</div>
	);
};
