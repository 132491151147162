import {
	LOG_TEMPLATE_RESET,
	LOG_TEMPLATE_CREATED,
	LOG_TEMPLATE_UPDATED,
	LOG_TEMPLATE_DELETED,
	LOG_TEMPLATE_ARCHIVED,
	LOG_TEMPLATE_UNARCHIVED,
	LOG_TEMPLATE_CLONED,
	LOG_TEMPLATE_PUBLISHED,
	LOG_SECTION_ADDED,
	LOG_SECTION_TITLE_UPDATED,
	LOG_SECTION_CONTENT_UPDATED,
	LOG_SECTION_DELETED,
	LOG_SUBSECTION_ADDED,
	LOG_SUBSECTION_TITLE_UPDATED,
	LOG_SUBSECTION_CONTENT_UPDATED,
	LOG_SUBSECTION_DELETED,
} from "utilities/constants";
import { formatDateForLogs } from "../utilities/dates";
import * as JsDiff from "diff";

const getInfo = (item, type) => {
	const fieldType = (type === 'old') ? 'oldValues' : 'newValues';
	const info = JSON.parse(item[fieldType]);
	return info;
}

const getSectionInfo = (item, type, field) => {
	const fieldType = (type === 'old') ? 'oldValues' : 'newValues';
	const { content } = JSON.parse(item[fieldType]);
	const sections = JSON.parse(content);
	let info = "";
	if (sections.length > 0) {
		sections.forEach(section => {
			if (section.id === item.sectionId) {
				info = section[field];
			}
		});
	}
	return info;
}
const getSubSectionInfo = (item, type, field) => {
	const fieldType = (type === 'old') ? 'oldValues' : 'newValues';
	const { content } = JSON.parse(item[fieldType]);
	const sections = JSON.parse(content);
	let info = "";
	if (sections.length > 0) {
		sections.forEach(section => {
			if (section.id === item.sectionId) {
				section.subsections.forEach(subsection => {
					if (subsection.id === item.subSectionId) {
						info = subsection[field];
					}
				});
			}
		});
	}
	return info;
}

export const cleanText = (text) => {
	// Step 1: Preserve table contents by temporarily replacing the entire table tags with placeholders
	const tablePlaceholder = [];
	text = text.replace(/(<table[\s\S]*?<\/table>)/gi, (match) => {
		tablePlaceholder.push(match);
		return `XXXXtable${tablePlaceholder.length - 1}XXXX`;
	});

	// Step 2: Replace newlines outside of the table tags with "newline"
	text = text.replace(/\n/g, "newline");

	// Step 3: Remove all HTML tags except <img>, <table>, <tr>, <td>, <th>, <thead>, <tbody>, <tfoot>
	// and ensure img tags are preserved as well
	text = text.replace(/<(?!\/?(img|table|tr|td|th|thead|tbody|tfoot)\b)[^>]+>/gi, "");

	// Step 4: Restore the original table content
	text = text.replace(/XXXXtable(\d+)XXXX/g, (match, index) => {
		return tablePlaceholder[index];
	});

	// Step 5: Replace "newline" back to newline characters
	text = text.replace(/newline/g, '\n');

	return text;
};

const renderResult = (part, index) => {
	return part.added
		? `<span class="diff-add">${part.value}</span>`
		: (part.removed
			? `<span class="diff-remove">${part.value}</span>`
			: part.value);
};

export const diffContent = (oldContent, newContent) => {
	// Preserve entire img tags by replacing them with placeholders
	const imgPlaceholderOld = [];
	oldContent = oldContent.replace(/<img[^>]*>/gi, (match) => {
		imgPlaceholderOld.push(match);
		return `XXXXimg${imgPlaceholderOld.length - 1}XXXX`;
	});

	const imgPlaceholderNew = [];
	newContent = newContent.replace(/<img[^>]*>/gi, (match) => {
		imgPlaceholderNew.push(match);
		return `XXXXimg${imgPlaceholderNew.length - 1}XXXX`;
	});

	// Perform the diff operation
	const diffWords = JsDiff.diffWords(
		oldContent,
		newContent,
		{ newlineIsToken: true }
	);

	// Restore the original img tags in the diff output
	oldContent = diffWords
		.filter(part => part.removed || !part.added)
		.map((part, index) => renderResult(part, index))
		.join('');
	oldContent = oldContent.replace(/XXXXimg(\d+)XXXX/g, (match, index) => {
		return imgPlaceholderOld[index];
	});
	oldContent = oldContent.replace(/newline/g, '<br>');

	newContent = diffWords
		.filter(part => part.added || !part.removed)
		.map((part, index) => renderResult(part, index))
		.join('');
	newContent = newContent.replace(/XXXXimg(\d+)XXXX/g, (match, index) => {
		return imgPlaceholderNew[index];
	});
	newContent = newContent.replace(/newline/g, '<br>');

	return {
		oldContent,
		newContent,
	};
};

export const useEventLogs = (item) => {

	const userName = item ? `${item.user.familyName} ${item.user.givenName}` : '';
	const dateTime = item ? formatDateForLogs(item.createdDate) : '';

	let itemName = '';
	let description = '';
	let value = '';
	let value_diff = '';
	let title_diff = '';
	let layout = ''; // text_only / strikethrough / diff_line / diff_content

	let info = {};
	let oldInfo = '';
	let newInfo = '';

	if (item) {
		switch (item.action) {
			case LOG_TEMPLATE_RESET:
				info = getInfo(item, 'old');
				description = `reset the template`;
				itemName = info.name;
				value = info.name;
				layout = 'text_only';
				break;
			case LOG_TEMPLATE_CREATED:
				info = getInfo(item, 'new');
				description = `created the template`;
				itemName = info.name;
				value = info.name;
				layout = 'text_only';
				break;

			case LOG_TEMPLATE_ARCHIVED:
				info = getInfo(item, 'old');
				description = 'archived the template';
				itemName = info.name;
				value = info.name;
				layout = 'strikethrough';
				break;

			case LOG_TEMPLATE_UNARCHIVED:
				info = getInfo(item, 'old');
				description = 'unarchived the template';
				itemName = info.name;
				value = info.name;
				layout = 'text_only';
				break;

			case LOG_TEMPLATE_UPDATED:
				oldInfo = getInfo(item, 'old');
				newInfo = getInfo(item, 'new');
				description = 'updated the template';
				itemName = oldInfo.name;
				value = oldInfo.name;
				value_diff = newInfo.name;
				layout = 'diff_line';
				break;

			case LOG_TEMPLATE_DELETED:
				info = getInfo(item, 'old');
				description = 'deleted the template';
				itemName = info.name;
				value = info.name;
				layout = 'strikethrough';
				break;

			case LOG_TEMPLATE_CLONED:
				info = getInfo(item, 'new');
				description = 'cloned the template';
				itemName = info.name;
				value = info.name;
				layout = 'text_only';
				break;

			case LOG_TEMPLATE_PUBLISHED:
				oldInfo = (item.subSectionId)
					? getSubSectionInfo(item, 'old', 'title')
					: getSectionInfo(item, 'old', 'title')
				description = (item.subSectionId)
					? 'published an update for sub section'
					: 'published an update for main section';
				itemName = oldInfo;
				value = '';
				layout = 'text_only';
				break;

			case LOG_SECTION_ADDED:
				const sectionTitle = getSectionInfo(item, 'new', 'title');
				description = 'added the main section';
				itemName = sectionTitle;
				value = sectionTitle;
				layout = 'text_only';
				break;

			case LOG_SECTION_TITLE_UPDATED:
				oldInfo = getSectionInfo(item, 'old', 'title');
				newInfo = getSectionInfo(item, 'new', 'title');
				description = 'updated the main section title';
				itemName = oldInfo;
				value = oldInfo;
				value_diff = newInfo;
				layout = 'diff_line';
				break;

			case LOG_SECTION_CONTENT_UPDATED:
				oldInfo = getSectionInfo(item, 'old', 'title');
				itemName = oldInfo;
				oldInfo = getSectionInfo(item, 'old', 'content');
				newInfo = getSectionInfo(item, 'new', 'content');
				description = 'updated the main section';
				title_diff = 'the main section ' + getSectionInfo(item, 'old', 'title');
				info = diffContent(cleanText(oldInfo), cleanText(newInfo));
				value = info.oldContent;
				value_diff = info.newContent;
				layout = 'diff_content';
				break;

			case LOG_SECTION_DELETED:
				oldInfo = getSectionInfo(item, 'old', 'title');
				description = 'deleted the main section';
				itemName = oldInfo;
				value = oldInfo;
				layout = 'strikethrough';
				break;

			case LOG_SUBSECTION_ADDED:
				newInfo = getSectionInfo(item, 'new', 'title');
				itemName = newInfo;
				newInfo = getSubSectionInfo(item, 'new', 'title');
				description = 'added a sub section within';
				value = newInfo;
				layout = 'text_only';
				break;

			case LOG_SUBSECTION_TITLE_UPDATED:
				oldInfo = getSubSectionInfo(item, 'old', 'title');
				newInfo = getSubSectionInfo(item, 'new', 'title');
				description = 'updated the sub section title';
				itemName = oldInfo;
				value = oldInfo;
				value_diff = newInfo;
				layout = 'diff_line';
				break;

			case LOG_SUBSECTION_CONTENT_UPDATED:
				oldInfo = getSubSectionInfo(item, 'old', 'title');
				itemName = oldInfo;
				oldInfo = getSubSectionInfo(item, 'old', 'content');
				newInfo = getSubSectionInfo(item, 'new', 'content');
				description = 'updated the sub section';
				title_diff = 'the sub section ' + getSubSectionInfo(item, 'old', 'title');
				info = diffContent(cleanText(oldInfo), cleanText(newInfo));
				value = info.oldContent;
				value_diff = info.newContent;
				layout = 'diff_content';
				break;

			case LOG_SUBSECTION_DELETED:
				oldInfo = getSectionInfo(item, 'old', 'title');
				itemName = oldInfo;
				oldInfo = getSubSectionInfo(item, 'old', 'title');
				description = 'deleted a sub section within';
				value = oldInfo;
				layout = 'strikethrough';
				break;
		}
	}

	return {
		userName,
		itemName,
		description,
		dateTime,
		value,
		value_diff,
		title_diff,
		layout,
	}
}  