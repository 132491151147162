import React, {useContext, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import {VariablesFieldsForm} from './';
import {section, Button, LoadingSpinner, ToasterContext, Intent} from 'componentsV2';
import {PlanService} from "services";
import './css/VariablesFields.scss';

export const VariablesFields = ({
                                  variables,
                                  templateId,
                                  loadData
                                }) => {

  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(true);
  const toaster = useContext(ToasterContext);

  const variablesReducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_VARIABLES':
        const {name, value, type, file, fileObj} = action.payload;
        const existingVariableIndex = state.variables.findIndex(variable => variable.name === name);
        if (existingVariableIndex !== -1) {
          const updatedVariables = [...state.variables];
          updatedVariables[existingVariableIndex] = {
            name: name,
            value: value,
            type: type,
            file: file,
            fileObj: fileObj
          };
          return {
            ...state,
            variables: updatedVariables,
          };
        } else {
          return {
            ...state,
            variables: [...state.variables, {
              name: name,
              value: value,
              type: type,
              file: file,
              fileObj: fileObj
            }],
          };
        }
      case 'DELETE_VARIABLES':
        const {name: variableToDelete} = action.payload;
        const filteredVariables = state.variables.filter(
          (variable) => variable.name !== variableToDelete
        );
        return {
          ...state,
          variables: filteredVariables,
        };
      default:
        return state;
    }
  };
  const [variableFormData, dispatchVariableFormData] = useReducer(variablesReducer, {
    variables: [],
  });

  const handleSubmit = () => {
    setLoading(true);
    PlanService.saveAllVariables(templateId, variableFormData)
      .then(resp => {
        if (resp.statusCode === 201) {
          toaster("You have successfully added the variable fields.",
            Intent.SUCCESS
          );
          loadData();
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (<>
      {loading && <LoadingSpinner/>}
      {!loading &&
        <div className="variables-container">
          <section className="variables-section">
            <div className="variables-section-header-text">
              Add variable fields
            </div>
          </section>
          <div className="raw variables-fields-form">
            <VariablesFieldsForm
              templateId={templateId}
              variables={variables}
              loadData={loadData}
              setLoading={setLoading}
              setDisableForm={setDisableForm}
              variableFormData={variableFormData}
              dispatchVariableFormData={dispatchVariableFormData}
            />
            <div className="col-md-12 submit-button">
              <Button
                size="md"
                text="Save"
                intent="default"
                disabled={!!disableForm}
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </div>
      }
    </>
  )
};

VariablesFields.propTypes = {
  variables: PropTypes.array.isRequired,
  template: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired
};
