import React, { useState, useEffect, useContext } from 'react';
import { PageHeader } from 'components/PageHeader';
import {
	Button,
	ActionsButton,
	Input,
	Dropdown,
	BootstrapedTable,
	EmptyState,
	noResult,
	ToasterContext,
	Intent,
	sortingStyle,
	LoadingSpinner,
	ConfirmationModal,
	FeaturedIcon,
	ToggleButton,
} from 'componentsV2';
import { emptyFolder } from 'componentsV2/EmptyState/icons';
import { SchoolYearsService, DrillDistrictRequirementsService } from 'services';
import './css/ManageDistrictDrillRequirements.scss';
import { AddDrillTypeModal } from './components/AddDrillTypeModal';
import { SetRequirementsPerBuildingModal } from './components/SetRequirementsPerBuildingModal';
import { EditRequiredFieldsModal } from './components/EditRequiredFieldsModal';
import { getStateId } from '../../Dashboard/utils';
import { isIterable } from '../../../../../utilities';
import { DeleteDrillTypeModal } from './components/DeleteDrillTypeModal';
import { EditDrillInstructionsModal } from './components/EditDrillInstructionsModal';

export const ManageDistrictDrillRequirements = () => {
	const toaster = useContext(ToasterContext);
	const breadcrumbItems = [
		{ label: 'Drills', url: '/drillsV2' },
		{ label: 'Settings', url: '/drillsV2/settings' },
		{ label: 'Drill Requirements' },
	];
	const [loading, setLoading] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [data, setData] = useState([]);
	const [modalToShow, setModalToShow] = useState(null);
	const [newDrillType, setNewDrillType] = useState(null);
	const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
	const [years, setYears] = useState(null);
	const [focusedInputId, setFocusedInputId] = useState(null);
	const [drillInstructionData, setDrillInstructionData] = useState();
	const [drillTypeData, setDrillTypeData] = useState(null);

	useEffect(() => {
		setLoading(true);
		SchoolYearsService.fetchYears()
			.then(backendYears => {
				setYears(backendYears);
				setSelectedSchoolYear(backendYears[2]);
				getDistrictRequirements(backendYears[2]);
			})
			.finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		if (focusedInputId) {
			document.getElementById(focusedInputId).focus();
			setFocusedInputId(null);
		}
	}, [data]);

	const setModalToOpen = modal => {
		if (selectedSchoolYear) {
			setModalToShow(modal);
		}
	};

	const onCloseModal = () => {
		setModalToShow(null);
	};

	const onChangeNewDrillType = item => {
		setNewDrillType(item);
	};

	const disabledRowCondition = row => row.active === false;

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'type',
			dataField: 'name',
			text: 'Type',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'required-for-district',
			dataField: 'districtRequirements',
			text: 'Drills required for district',
			formatter: (value, item) => (
				<Input
					id={item.uuid}
					disabled={!item.active}
					value={value ? value : ''}
					placeholder=""
					onChange={event =>
						editRequirement('districtRequirements', item.uuid, event.target.value)
					}
				/>
			),
		},
		{
			key: 'required-for-state',
			dataField: 'stateRequirements',
			text: 'Drills required by state',
		},
		{
			key: 'status',
			dataField: 'active',
			text: 'Status',
			formatter: (value, item) => (
				<ToggleButton
					checked={value}
					onChange={() => editRequirement('active', item.uuid, !value, item.name)}
					className="drill-reqs"
					label={value ? 'Active' : 'Inactive'}
				/>
			),
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<ActionsButton disabled={!row.active}>
					<Button
						text="Edit required log fields"
						icon="edit"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => {
							setDrillTypeData(row);
							setModalToOpen('editRequiredFields');
						}}
					/>
					<Button
						text="Set drills per building"
						icon="office"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => {
							setDrillTypeData(row);
							setModalToOpen('setRequirementsPerBuilding');
						}}
					/>
					<Button
						text="Edit instructions"
						icon="sim-card"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => handleEditInstructions(row)}
					/>
					<Button
						text="Delete"
						icon="trash"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => handleDeleteDrillModal(row)}
					/>
				</ActionsButton>
			),
		},
	];

	const handleEditInstructions = row => {
		setModalToShow('editInstructionsModal');
		setDrillInstructionData(row);
	};

	const handleDeleteDrillModal = row => {
		setDrillTypeData(row);
		setModalToOpen('deleteDrillType');
	};

	const sortDrills = data =>
		data.sort((a, b) => {
			const x = a.name.toUpperCase();
			const y = b.name.toUpperCase();
			if (x < y) {
				return -1;
			}
			if (x > y) {
				return 1;
			}
			return 0;
		});

	const handleYearSelection = selectedYear => {
		setData([]);
		setLoading(true);
		setSelectedSchoolYear(selectedYear);
		getDistrictRequirements(selectedYear);
	};

	const getDistrictRequirements = selectedYear => {
		DrillDistrictRequirementsService.getAllRequirements(selectedYear.value, getStateId())
			.then(reqs => setData(sortDrills(reqs)))
			.catch(error =>
				toaster(`There was an error retrieving the requirements: ${error}`, Intent.DANGER),
			)
			.finally(() => setLoading(false));
	};

	const editRequirement = (type, uuid, newValue, drillName) => {
		setData(prevData =>
			prevData.map(item =>
				item.uuid === uuid
					? {
							...item,
							[type]: newValue,
							districtReqsUpdated: true,
					  }
					: item,
			),
		);
		if (type === 'districtRequirements') {
			setFocusedInputId(uuid);
		}
		if (type === 'active') {
			toaster(
				`You have successfully ${
					newValue ? 'enabled' : 'disabled'
				} ${drillName}. This will process once you click the Publish button.`,
				Intent.WARNING,
				<FeaturedIcon icon="error" type="Warning" size="md" shape="circleIcon" />,
				5000,
			);
		}
	};

	const publishRequirements = async () => {
		if (isIterable(data)) {
			const promises = data.map(requirement => {
				if (requirement.districtReqsUpdated) {
					if (requirement.districtRequirement) {
						return DrillDistrictRequirementsService.updateRequirements(
							requirement.districtRequirement.id,
							requirement.districtRequirements,
							requirement.fieldUuids,
							requirement.active,
						);
					} else {
						return DrillDistrictRequirementsService.createRequirement(
							requirement.uuid,
							selectedSchoolYear.value,
							requirement.districtRequirements,
							requirement.fieldUuids,
						);
					}
				}
				return Promise.resolve(); // Do nothing if there is no changes
			});

			try {
				setModalLoading(true);
				await Promise.all(promises);
				toaster(
					'You successfully published your district drill requirements.',
					Intent.SUCCESS,
				);
			} catch (response) {
				if (response.message !== undefined) {
					// If the service throws an error
					toaster(`Error updating requirements: ${response.message}`, Intent.DANGER);
				} else {
					// If the request returns an error
					toaster(
						`Error updating requirements: ${response?.error?.description}`,
						Intent.DANGER,
					);
				}
			} finally {
				setModalLoading(false);
				setModalToShow(null);
				setFocusedInputId(null);
				handleYearSelection(selectedSchoolYear);
			}
		}
	};

	const setBuildingRequirements = buildingRequirements => {
		const promisesArray = [
			DrillDistrictRequirementsService.updateBuildingRequirements(
				drillTypeData.districtRequirement.id,
				buildingRequirements,
			),
		];
		Promise.all(promisesArray)
			.then(() => {
				toaster(
					'You have successfully updated the required drills per building.',
					Intent.SUCCESS,
				);
			})
			.catch(() => {
				toaster('An error occurred setting requirements. Please try again.', Intent.DANGER);
			})
			.finally(() => {
				setModalToShow(null);
				setDrillTypeData(null);
				handleYearSelection(selectedSchoolYear);
			});
	};

	const updateRequiredFields = updatedFields => {
		DrillDistrictRequirementsService.updateRequirements(
			drillTypeData.districtRequirement.id,
			null,
			updatedFields.map(field => field.uuid),
		)
			.then(() => {
				toaster(
					`You have successfully updated the required fields for “${
						drillTypeData?.name
					}”.`,
					Intent.SUCCESS,
				);
			})
			.catch(res => {
				toaster(`There was the following error: ${res?.error?.description}`, Intent.DANGER);
			})
			.finally(() => {
				setModalToShow(null);
				setDrillTypeData(null);
				handleYearSelection(selectedSchoolYear);
			});
	};

	const addDrillType = () => {
		setModalLoading(true);
		DrillDistrictRequirementsService.createRequirement(
			newDrillType.uuid,
			selectedSchoolYear.value,
		)
			.then(response => {
				toaster(
					`You successfully added ${newDrillType.name} as a new drill type.`,
					Intent.SUCCESS,
				);
			})
			.catch(() =>
				toaster(
					`District requirement ${
						newDrillType.name
					} already exists for this year and drill type.`,
					Intent.DANGER,
				),
			)
			.finally(() => {
				setNewDrillType(null);
				setModalLoading(false);
				setModalToShow(null);
				handleYearSelection(selectedSchoolYear);
			});
	};

	const onClickDeleteDistrictDrill = () => {
		DrillDistrictRequirementsService.deleteRequirement(drillTypeData?.districtRequirement?.id)
			.then(() => {
				toaster(
					`You have successfully deleted ${drillTypeData?.name}.`,
					Intent.NONE,
					<FeaturedIcon icon="trash" size="md" type="Gray" />,
				);
				handleYearSelection(selectedSchoolYear);
			})
			.catch(() => {
				toaster(`There was an error deleting the drill. Please try again.`, Intent.DANGER);
			})
			.finally(() => {
				setModalToShow(null);
			});
	};

	return (
		<>
			<div>
				<div className="district-requirements-page-container">
					<div className="header-container">
						<PageHeader
							title="District drill requirements"
							breadcrumbItems={breadcrumbItems}
						/>
					</div>
					<div className="dropdown-filters">
						<div className="col-md-6">
							<Dropdown
								label="School year"
								isRequired={true}
								placeholder="Select a school year"
								value={selectedSchoolYear}
								options={years}
								onChange={handleYearSelection}
							/>
						</div>
					</div>
					<div className="sections-container">
						<div className="table-section-container">
							{loading && data.length == 0 && <LoadingSpinner className="mt-3" />}
							{data.length > 0 && (
								<>
									<section className="table-section-header-container">
										<div className="right-side">
											<>
												<span className="text">
													Drill requirements
													<p>
														Marking a drill as inactive will hide that
														drill for the schools in your district.
													</p>
												</span>
											</>
										</div>
										<div className="actions">
											<>
												<Button
													size="md"
													text="New type"
													icon="plus"
													type="primaryDefault"
													intent="default"
													onClick={() => setModalToOpen('addType')}
												/>
												<Button
													size="md"
													text="Publish"
													type="tertiary"
													intent="default"
													onClick={() =>
														setModalToOpen('publishDrillModal')
													}
												/>
											</>
										</div>
									</section>
									<div className="left-side">
										{loading ? (
											<LoadingSpinner className="mt-3" />
										) : (
											<BootstrapedTable
												keyField="id"
												data={data}
												columns={columns}
												disabledRowCondition={disabledRowCondition}
												remote={false}
											/>
										)}
									</div>
								</>
							)}
							{data.length === 0 && !loading && (
								<>
									<EmptyState
										header={
											selectedSchoolYear
												? 'No district requirements found'
												: 'Please select a School Year'
										}
										icon={selectedSchoolYear ? noResult : emptyFolder}
										description={
											selectedSchoolYear
												? 'Select a different School year'
												: ''
										}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* Add new Drill type modal */}
			{modalToShow === 'addType' && (
				<AddDrillTypeModal
					isOpen
					loading={modalLoading}
					onCloseModal={onCloseModal}
					newDrillType={newDrillType}
					onModalSubmit={addDrillType}
					onChange={onChangeNewDrillType}
				/>
			)}

			{/* Edit instructions modal */}
			{modalToShow === 'editInstructionsModal' && (
				<EditDrillInstructionsModal
					isOpen
					drillInstructionData={drillInstructionData}
					onCloseModal={onCloseModal}
				/>
			)}

			{/* Publish requirements changes modal */}
			{modalToShow === 'publishDrillModal' && (
				<ConfirmationModal
					isOpen
					loading={modalLoading}
					title="Publish district requirements"
					subtitle="You are about to publish these district requirements for your district. Every school in your district will see these changes."
					showCloseButton={false}
					onClose={onCloseModal}
					cancelBtn={{ title: 'Cancel', onClick: onCloseModal }}
					confirmBtn={{
						title: 'Publish',
						onClick: publishRequirements,
						type: 'primaryDefault',
					}}
				/>
			)}

			{/* Delete Drill type modal */}
			{modalToShow === 'deleteDrillType' && (
				<DeleteDrillTypeModal
					isOpen
					titleData={drillTypeData.name}
					onClickDelete={onClickDeleteDistrictDrill}
					onCloseModal={onCloseModal}
				/>
			)}

			{/* Requirements per building modal */}
			{modalToShow === 'setRequirementsPerBuilding' && (
				<SetRequirementsPerBuildingModal
					isOpen={true}
					onModalSubmit={setBuildingRequirements}
					onCloseModal={onCloseModal}
					selectedDrillType={drillTypeData}
				/>
			)}

			{/* Edit required fields modal */}
			{modalToShow === 'editRequiredFields' && (
				<EditRequiredFieldsModal
					isOpen
					subtitle="Below are the fields available when recording a drill. Anything with a grayed out checkmark is required by the state and cannot be removed. You can checkmark additional fields you want to be required for your district."
					onCloseModal={onCloseModal}
					onActionClick={updateRequiredFields}
					selectedRequirement={drillTypeData}
				/>
			)}
		</>
	);
};
