/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import React from 'react';
import { OptionGroup } from 'componentsV2';

export const OptionGroupPattern = () => {
	const simpleOptions = ['Option 1', 'Option 2', 'Option 3'];

	const optionsObject = {
		opt1: 'Option 1',
		opt2: 'Option 2',
		opt3: 'Option 3',
	};

	const extraOptions = [
		{
			label: 'Option 1',
			value: 'opt1',
			disabled: true,
		},
		{
			label: 'Option 2',
			value: 'opt2',
		},
		{
			label: 'Option 3',
			value: 'opt3',
			large: true,
		},
	];

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: 'repeat(3, 1fr)',
				gridTemplateRows: 'repeat(3, 1fr)',
				gridGap: '2em',
			}}
		>
			<OptionGroup
				options={simpleOptions}
				groupLabel="Simple Array"
				initialSelection={['Option 2']}
				onSelect={selection => console.log('onSelect', selection)}
			/>
			<OptionGroup
				optionsObject={optionsObject}
				groupLabel="Options Object"
				initialSelection={['opt1']}
				onSelect={selection => console.log('onSelect', selection)}
			/>
			<OptionGroup
				options={extraOptions}
				groupLabel="Extra Object"
				initialSelection={['opt2']}
				onSelect={selection => console.log('onSelect', selection)}
			/>
			<OptionGroup
				options={simpleOptions}
				multiple
				groupLabel="Multi Simple"
				onSelect={selection => console.log('onSelect', selection)}
			/>
			<OptionGroup
				optionsObject={optionsObject}
				multiple
				groupLabel="Multi Options Object"
				onSelect={selection => console.log('onSelect', selection)}
			/>
			<OptionGroup
				options={extraOptions}
				initialSelection={['opt2', 'opt3']}
				multiple
				groupLabel="Multi Extra"
				onSelect={selection => console.log('onSelect', selection)}
			/>
		</div>
	);
};
