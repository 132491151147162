import React from 'react';
import { NoShapeContentState, ShapeContentItem } from './index';
import { link } from 'componentsV2';
import '../css/ShapeContent.scss';

export const CameraLinkContent = ({
	links,
	contentType,
	shapeType,
	shapeId,
	siteId,
	editPermission,
	floorplanArchived,
	isSearchedContent,
	selectedShape,
	loadContentData,
	isEditMode,
	viewPermissionOnSiteId,
	floorplan,
}) => {
	return (
		<div className="area-content-items-container">
			{links.length > 0 &&
				links.map(link => (
					<ShapeContentItem
						item={link}
						id={link.id}
						title={link.contentTitle}
						link={link.url}
						siteId={siteId}
						shapeId={shapeId}
						shapeType={shapeType}
						editPermission={editPermission}
						floorplanArchived={floorplanArchived}
						contentType={contentType}
						selectedShape={selectedShape}
						loadContentData={loadContentData}
						isEditMode={isEditMode}
						viewPermissionOnSiteId={viewPermissionOnSiteId}
						floorplan={floorplan}
					/>
				))}
			{links.length == 0 && (
				<NoShapeContentState
					icon={link}
					header={
						isSearchedContent ? 'No content found' : 'No web links currently added.'
					}
					description={
						isSearchedContent
							? 'Your search didn’t match any web links. Please try again.'
							: editPermission && !floorplanArchived && isEditMode
							? 'Click the + button to add a web link to this camera.'
							: ''
					}
				/>
			)}
		</div>
	);
};
