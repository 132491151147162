import React, { useEffect ,useState} from "react";
import { FlipchartService } from "services/FlipchartService";
import { TabPreviewList,Modal } from "componentsV2";
import '../css/TabPreview.scss';
import { CallListTab, CustomTab, SafetyPlanTab } from './PrintAction/Tabs';
import { FLIPCHART_CONTENT_TYPE } from 'utilities/constants';

export const TabPreviewModal = ({showTabPreviewModal, setShowTabPreviewModal, id, alarmId = null}) => {

    const [tabsDetails,setTabsDetails]= useState();
    const [loading,setLoading] = useState(false);


    useEffect(()=>{
        const params = { alarmId: alarmId }
        setLoading(true);
        FlipchartService.flipchartTapsDetails(id, params).then(resp =>{
            if(resp.statusCode === 200)
            {
              let tabsList = [];

              tabsList  = resp.data.map((tab,index) => {

                    let newcontent;
                    if (tab.contentTypeCode === FLIPCHART_CONTENT_TYPE.CALLLIST) {newcontent =  (<CallListTab row={tab} index={index} />);} 
                    else if (tab.contentTypeCode === FLIPCHART_CONTENT_TYPE.SAFETYPLAN) {newcontent = (<SafetyPlanTab row={tab} index={index} />);}
                    else {newcontent =  (<CustomTab row={tab} index={index} />);}

                    return {
                    id: tab.id,
                    title: tab.title,
                    content: newcontent  , 
                    textColor: `#${tab.textColor}`,
                    backgroundColor: `#${tab.backgroundColor}`,
                    }


               });
               setLoading(false);
               
               setTabsDetails(tabsList);
            }
         }).catch(err => {
          setLoading(false)
      });
    },
    []);
	return (
    
              <Modal
              isOpen={showTabPreviewModal}
              onClose={() => setShowTabPreviewModal(false)}
              loading={loading}
              title="Tab Preview"
              size='small'
              showCloseButton={true}
              customClassName={'preview-container'}
              >
                  <TabPreviewList 
                      list={tabsDetails}
                  />
              </Modal>

	);

}