import React from "react";
import { FormLabel } from 'components/Forms/shared.js';
import { Input } from 'components/Input';
import { Dropdown } from 'components/Dropdown';

const Step1 = (props) => {
    const {
        updateFormData, 
        selectedMiles, 
        setSelectedMiles, 
        validationErrors, 
        formData, 
        setErrors, 
        setDisableBtns,
        setresetDisableHandler
    } = props;
    const milesOptions = [
        { value: "5", label: "5 miles" },
        { value: "10", label: "10 miles" },
        { value: "15", label: "15 miles" },
        { value: "20", label: "20 miles" },
        { value: "25", label: "25 miles" },
    ];
    
    const disabledHandler = (e) => {
        
        if (e.target.value == '' || e.target.value.length < 5) {
            setDisableBtns(true)
        } else {
            setDisableBtns(false)
        }
    } 

    function handleKeyPress(event) {
        const regex = /^[0-9\b]+$/; // Regular expression to match only numbers
        const key = String.fromCharCode(event.which); // Get the key pressed
        if (!regex.test(key)) {
            event.preventDefault(); // Prevent the input if the key pressed is not a number
        }
        if (event.target.value.length > 5) {
            event.preventDefault();
        } 
        
        else {
            
            const errors = {};
            updateFormData('zip_code', event.target.value) 
            errors.zip_code = '';
            setErrors(errors);
            setresetDisableHandler(false)
        }
    }

    return (
        <div className="bootstrap-table-container first-responder-conratiner">
            <p>Search Filters</p>
            <FormLabel>Zip code (required)</FormLabel>
            <Input
                placeholder  = 'Zip code'
                onChange={handleKeyPress}
                onKeyPress={handleKeyPress}
                onKeyUp={disabledHandler}
                error = {!!validationErrors.zip_code}
                errorMessage = {validationErrors.zip_code}
                value={formData.zip_code}
            />
            <FormLabel>Search Radius (required)</FormLabel>
                <Dropdown
                    value={selectedMiles}
                    placeholder="Radius"
                    options={ milesOptions }
                    onChange={(e) =>
                        {
                            setSelectedMiles(e)
                            updateFormData('radius', e.value)
                        }
                    }
                />
        </div>              
    );
};
export default Step1;