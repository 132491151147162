import React from 'react';
import { Position, Popover, Tooltip as BPTooltip } from '@blueprintjs/core';
import './css/Tooltip.scss';

export const TooltipPosition = Position;

export const Tooltip = ({
	tooltipTitle = null,
	tooltipContent = null,
	theme = 'dark', // dark & light
	intent = 'none',
	position = TooltipPosition.TOP,
	disabled = false,
	children,
	hoverCloseDelay = 0, //ms
	...restProps
}) => (
	<Popover key={`popver ${tooltipTitle ? tooltipTitle : ''}`} {...restProps}>
		<div className={`Tooltip_Container ${theme}`}>
			<BPTooltip
				content={
					<>
						{!!tooltipTitle && (
							<div
								className={`Tooltip_Title ${
									!tooltipContent ? 'Tooltip_Title__No_Content' : ''
								}`}
							>
								{tooltipTitle}
							</div>
						)}
						<div className="Tooltip_Body">{tooltipContent}</div>
					</>
				}
				placement={position}
				intent={intent}
				disabled={disabled}
				hoverCloseDelay={hoverCloseDelay}
				usePortal={false}
			>
				{children}
			</BPTooltip>
		</div>
	</Popover>
);
