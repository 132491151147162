import React, { useContext } from 'react';
import { FieldCard, AlarmCountdown } from '../SharedFields';
import { CreateAlarmTypeContext } from '../../../../../../provider';

export const Countdown = () => {
	const { formData, formErrors, onChangeFormData } = useContext(CreateAlarmTypeContext);

	return (
		<FieldCard
			header="Activate countdown before alarm triggers"
			description={
				<>
					Would you like to include a 5 second countdown before the alarm is triggered?
					This gives the individual a window to cancel the alarm.{' '}
					<span className="text-danger">*</span>
				</>
			}
			style={{
				height: '100%',
			}}
		>
			<AlarmCountdown
				formData={formData}
				formErrors={formErrors}
				onChangeFormData={onChangeFormData}
			/>
		</FieldCard>
	);
};
