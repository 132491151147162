import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'componentsV2';
import {
	BindersFilters,
	BindersTable,
	EmptyStatuses,
	BinderModalsProvider,
	BinderHeader,
	BinderBulkActions,
	BINDER_EDIT_PATH,
} from './';
import useSelectedSites from 'hooks/useSelectedSites';
import { checkPermission } from 'utilities/permissions';
import { BinderService } from 'services/BinderService';
import { useSelector } from 'react-redux';
import { decodeToken } from 'utilities';
import './css/Binders.scss';

export const Binders = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [contentLoading, setContentLoading] = useState(false);
	const [bindersList, setBindersList] = useState([]);
	const [selectedBinders, setSelectedBinders] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const defaultSelectedSites = useSelectedSites();
	const [showEmptyStatus, setShowEmptyStatus] = useState(false);
	const [noDataAdded, setNoDataAdded] = useState(false);
	const [selectedTab, setSelectedTab] = useState('1');
	const [filterationData, setFilterationData] = useState({
		page: 1,
		perPage: 10,
		sortKey: 'name',
		sortDir: 'asc',
		name: '',
		includeVisibleToAll: true,
		buildingIds: [],
	});
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('vb_edit', permissions);

	useEffect(() => {
		getBindersList();
	}, [filterationData]);

	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const dist = token ? decodeToken(token) : '';
	const districtId = dist ? dist.dist : null;

	const getBindersList = () => {
		BinderService.getAll(filterationData)
			.then(response => {
				if (response.statusCode === 200) {
					const allData = response.data.map(binder => {
						const isDisabled = checkPermission(
							'vb_edit',
							permissions,
							binder.building ? binder.building.id : districtId,
						);
						return {
							...binder,
							disabled: !isDisabled,
							showTooltip: !isDisabled,
							tooltipTitle: "You don't have edit permission on this binder",
						};
					});
					setBindersList(allData);
					setTotalRows(response._metadata.totalRecords);
					const isEmpty = allData.length === 0;
					setNoDataAdded(
						isEmpty &&
							selectedTab === '1' &&
							filterationData.buildingIds.length === 0 &&
							(filterationData.name === null || filterationData.name === ''),
					);
					setShowEmptyStatus(isEmpty);
				}
			})
			.catch(error => {
				console.error('Error fetching binders list:', error);
			})
			.finally(() => {
				setSelectedBinders([]);
				setLoading(false);
				setContentLoading(false);
			});
	};

	const toggleBulkActions = () => {
		if (selectedBinders.length === 0) return false;
		return selectedBinders.some(selectedBinder => {
			return bindersList.some(binder => binder.id === selectedBinder.id);
		});
	};

	const handleAddBinderSubmit = id => {
		history.push(`/${BINDER_EDIT_PATH}/${id}`);
	};

	return (
		<div className="binders-container">
			{loading && <LoadingSpinner />}
			{!loading && (
				<BinderModalsProvider history={history} reloadList={getBindersList}>
					<BinderHeader
						editPermission={editPermission}
						noDataAdded={noDataAdded}
						onAddBinderSubmit={handleAddBinderSubmit}
						addBinderSelectedSites={filterationData.buildingIds}
					/>
					{!noDataAdded && (
						<BindersFilters
							defaultSelectedSites={defaultSelectedSites}
							setFilterationData={setFilterationData}
							filterationData={filterationData}
							setContentLoading={setContentLoading}
							setSelectedTab={setSelectedTab}
						/>
					)}
					{toggleBulkActions() && <BinderBulkActions selectedBinders={selectedBinders} />}
					{showEmptyStatus && (
						<EmptyStatuses
							filterationData={filterationData}
							noDataAdded={noDataAdded}
							editPermission={editPermission}
							onAddBinderSubmit={handleAddBinderSubmit}
						/>
					)}
					{contentLoading && <LoadingSpinner />}
					{!showEmptyStatus && !contentLoading && (
						<BindersTable
							list={bindersList}
							filterationData={filterationData}
							setFilterationData={setFilterationData}
							totalRows={totalRows}
							editPermission={editPermission}
							setSelectedRows={setSelectedBinders}
							selectedRows={selectedBinders}
							history={history}
							setLoading={setLoading}
							districtId={districtId}
						/>
					)}
				</BinderModalsProvider>
			)}
		</div>
	);
};
