import { HttpService } from 'networking/HttpService';

export const AssessTemplatesService = {
	async getTemplates(includeUnpublished = true, getCore = false, buildingState = false) {
		try {
			return await HttpService({
				method: 'GET',
				route: `/templates?includeUnpublished=${includeUnpublished}&getCore=${getCore}&buildingState=${buildingState}`,
			});
		} catch (error) {
			throw error;
		}
	},
	async deleteTemplate(templateId) {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/templates/${templateId}`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: { active: false },
			});
		} catch (error) {
			throw error;
		}
	},
	async publishUnpublishTemplate(templateId, value) {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/templates/${templateId}`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: { publish: value },
			});
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Updates the order of questions within a template section
	 * @param {number} templateId
	 * @param {number} sectionId
	 * @param {number[]} questionIds
	 */
	updateQuestionOrder: async (templateId, sectionId, questionIds) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/templates/${templateId}/sections/${sectionId}/questions`,
				body: {
					questionIds,
				},
			});
		} catch (error) {
			throw error;
		}
	},
	saveTemplate: async templateContent => {
		try {
			return await HttpService({
				method: 'POST',
				route: `/templates`,
				body: templateContent,
			});
		} catch (error) {
			throw error;
		}
	},
	editTemplate: async (templateId, templateBody) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/templates/${templateId}`,
				body: templateBody,
			});
		} catch (error) {
			throw error;
		}
	},
	importTemplate: async templateId => {
		try {
			return await HttpService({
				method: 'POST',
				route: `/templates/${templateId}/import`,
			});
		} catch (error) {
			throw error;
		}
	},
	getTemplateData: async templateId => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/templates/${templateId}`,
			});
		} catch (error) {
			throw error;
		}
	},
	copyTemplate: (templateId, name) =>
		HttpService({
			method: 'POST',
			route: `/templates/${templateId}/copy`,
			body: { name },
		}),
	submitTemplateSummary: (templateId, summary) =>
		HttpService({
			method: 'PUT',
			route: `/templates/${templateId}`,
			body: { summary: `${summary}` },
		}).catch(err => {
			throw err;
		}),
	deleteSectionSubsection: async (templateId, sectionId) => {
		try {
			return await HttpService({
				method: 'DELETE',
				route: `/templates/${templateId}/sections/${sectionId}`,
			});
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Copies a section or subsection in a template.
	 *
	 * @param {number} templateId The ID of the template.
	 * @param {number} sectionId The ID of the section/subsection to be copied.
	 * @param {string} name The new title for the copied section/subsection.
	 */
	copyTemplateSectionSubsection: (templateId, sectionId, name) =>
		HttpService({
			method: 'POST',
			route: `/templates/${templateId}/sections/${sectionId}/copy`,
			body: { name },
		}).catch(err => {
			throw err;
		}),
	async addMainSection(templateId, name, instructions) {
		try {
			return await HttpService({
				method: 'POST',
				route: `/templates/${templateId}/sections`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: { name, instructions },
			});
		} catch (error) {
			throw error;
		}
	},
	async addSubSection(templateId, name, instructions, parentId) {
		try {
			return await HttpService({
				method: 'POST',
				route: `/templates/${templateId}/sections`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: { name, instructions, parentId },
			});
		} catch (error) {
			throw error;
		}
	},
	async editSection(templateId, name, instructions, sectionId) {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/templates/${templateId}/sections/${sectionId}`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: { name, instructions },
			});
		} catch (error) {
			throw error;
		}
	},
	/**
	 * Updates a question in a template.
	 *
	 * @param {number} templateId The ID of the template.
	 * @param {number} questionId The ID of the question to be updated.
	 * @param {Object} body The body of the request.
	 * @param {number} body.sectionId The ID of the section.
	 * @param {string} body.title The title of the question.
	 * @param {string} body.properties A JSON string containing answer type and options.
	 * @param {string} body.hint A hint to help answer the question.
	 */
	updateQuestion: (templateId, questionId, body) =>
		HttpService({
			method: 'PUT',
			route: `/templates/${templateId}/questions/${questionId}`,
			body,
		}).catch(err => {
			throw err;
		}),
	createQuestion: (templateId, body) =>
		HttpService({
			method: 'POST',
			route: `/templates/${templateId}/questions`,
			body,
		}).catch(err => {
			throw err;
		}),
	deleteQuestion: (templateId, questionId) =>
		HttpService({
			method: 'DELETE',
			route: `/templates/${templateId}/questions/${questionId}`,
		}).catch(err => {
			throw err;
		}),
};
