import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popover, Position } from '@blueprintjs/core';
import { format, differenceInCalendarDays } from 'date-fns';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';
import CloseTask from './closetask';
import ViewTask from './viewtask';
import EditTask from './edittask';

const FirstColumn = styled.h6`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 0;
	color: #9f1c3a;
`;

const TableRow = styled.tr`
	opacity: ${props => (props.visible ? 0.5 : 1)};
`;

const StyledTag = styled.div`
	background: #fcebeb;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #db3737;
	border-radius: 10px;
	padding: 2px 8px;
`;

const StatusText = styled.p`
	font-family: Nunito Sans;
	font-style: italic;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #738694;
	margin-bottom: 0px;
	margin-left: 6px;
`;

const CompleteTag = styled(StyledTag)`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	border-radius: 10px;
	padding: 2px 8px;
	margin-left: 6px;
	background: ${props =>
		props.disposition === 'Complete' ? '#E8F4EF' : '#EBF1F5'} !important;
	color: ${props =>
		props.disposition === 'Complete' ? '#128C5A' : '#394B59'} !important;
`;

class TasksRow extends React.Component {
	state = {
		showMenu: false,
		showCloseTask: false,
		showViewTask: false,
	};

	/*
        disposition
        Complete
        Non complete


        no edit once closed
        no close button once closed
    */

	closeCloseTask = () => this.setState({ showCloseTask: false });

	closeViewTask = () => this.setState({ showViewTask: false });

	closeEditTask = () => this.setState({ showEditTask: false });

	getStatus = () => {
		const { item } = this.props;
		const diffInDays = differenceInCalendarDays(
			new Date(item.due_date),
			new Date(),
		);

		if (item.closed_date !== null) {
			return (
				<div className="d-flex align-items-center">
					<CompleteTag disposition={item.disposition}>
						Closed: {item.disposition}
					</CompleteTag>
				</div>
			);
		}
		if (diffInDays < 0) {
			return (
				<div className="d-flex align-items-center ml-2">
					<StyledTag>Overdue</StyledTag>
					<StatusText>
						{Math.abs(diffInDays)} days past due
					</StatusText>
				</div>
			);
		}
		return null;
	};

	openModal = isClosed => {
		if (isClosed) {
			return this.setState({ showMenu: false, showViewTask: true });
		}
		return this.setState({ showMenu: false, showEditTask: true });
	};

	deleteTask = () => {
		Swal.fire({
			title: `Are you sure you want to delete this task?`,
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Delete',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				const { item, getData } = this.props;
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				fetch(`${process.env.API_URL}/tasks/${item.id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'app/json',
						Authorization: `Bearer ${token}`,
					},
				})
					.then(res => res.json())
					.then(() => getData());
			}
		});
	};

	render() {
		const { item, getData } = this.props;
		const {
			showMenu,
			showCloseTask,
			showViewTask,
			showEditTask,
		} = this.state;
		const isClosed = item.closed_by_name !== null;
		return (
			<>
				{showCloseTask && (
					<CloseTask
						isOpen={showCloseTask}
						closeCloseTask={this.closeCloseTask}
						item={item}
						getData={getData}
					/>
				)}
				{showViewTask && (
					<ViewTask
						isOpen={showViewTask}
						closeViewTask={this.closeViewTask}
						item={item}
						getData={getData}
					/>
				)}
				{showEditTask && (
					<EditTask
						isOpen={showEditTask}
						closeEditTask={this.closeEditTask}
						item={item}
						getData={getData}
					/>
				)}
				<TableRow>
					<td>
						<FirstColumn className="d-flex ml-2">
							{item.title}
							{this.getStatus()}
						</FirstColumn>
					</td>
					<td>{format(new Date(item.due_date), 'MM/dd/yyyy')}</td>
					<td>{item.assigned_to_name}</td>
					<td>
						<Popover
							enforceFocus={false}
							isOpen={showMenu}
							position={Position.BOTTOM}
							onClose={() => this.setState({ showMenu: false })}
						>
							<Button
								icon="more"
								text=""
								onClick={() =>
									this.setState({ showMenu: true })
								}
								minimal
							/>
							<div style={{ width: '234px' }}>
								<div className="pt-2 pb-2">
									<Button
										minimal
										text={
											isClosed
												? 'View Details'
												: 'Edit Details'
										}
										className="w-100 justify-content-start"
										onClick={() => this.openModal(isClosed)}
									/>
									{!isClosed && (
										<Button
											minimal
											text="Close"
											className="w-100 justify-content-start"
											onClick={() =>
												this.setState({
													showMenu: false,
													showCloseTask: true,
												})
											}
										/>
									)}

									<Button
										minimal
										intent="danger"
										text="Delete"
										className="w-100 justify-content-start"
										onClick={this.deleteTask}
									/>
								</div>
							</div>
						</Popover>
					</td>
				</TableRow>
			</>
		);
	}
}

TasksRow.propTypes = {
	item: PropTypes.object,
	getData: PropTypes.func,
};

export default TasksRow;
