import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSelector } from 'react-redux';
import { Dropdown } from 'components/Dropdown';
import { Button } from 'components/Button';
import { DrillTypesService } from 'services/DrillTypesService';
import { DrillsAnalyticsService } from 'services/DrillsAnalyticsService';
import { BuildingsService } from 'services/BuildingsService';
import { SchoolYearDropdown } from 'components/SchoolYearDropdown';
import { SHOW_ONLY_ANALYTICS_OPTIONS } from 'utilities/drills/mockData';
import { FormLabel } from 'components/Forms/shared.js';
import {
	BY_YEAR_RESUME_DROPDOWN_OPTIONS,
	YEARLY_TRENDS_DROPDOWN_OPTIONS,
} from './shared';
import { AllDrillsAnalytics } from './AllDrillsAnalytics';
import { SpecificDrillsAnalytics } from './SpecificDrillsAnalytics';
const animatedComponents = makeAnimated();

const Wrapper = styled.div`
	padding: 0px 0px;
	height: 100%;
	.dropdown-filters {
		display: flex;
		flex: 1;
		flex-wrap: wrap;
		flex-direction: row;

		.dropdown {
			flex: 1;
			margin-right: 24px;
			margin-bottom: 16px;
			max-width: 350px;
		}

		.multi-select-container p {
			margin-top: 0px !important;
			margin-bottom: 6px;
		}

		.multi-select {
			flex: 1;
			margin-right: 24px;
			margin-bottom: 16px;
			min-width: 300px;
		}

		.filter-button-container {
			display: flex;
			flex: 1;
			align-items: center;
		}

		.export-button-container {
			display: flex;
			width: 150px;
			align-items: center;
		}

		.dropdown:last-child {
			margin-right: 0px;
		}
	}

	.totalizers-containers {
		display: flex;
		flex: 1;
		flex-wrap: wrap;
	}

	.info-container {
		display: flex;

		.overview-info-container {
			align-items: center;
			column-gap: 64px;
			display: grid;
			grid-template-columns: repeat(3, auto);
			max-height: 400px;
		}

		.recharts-wrapper {
			margin-right: 40px;
		}
	}

	.chart-container {
		margin-bottom: 25px;
	}

	.instructions-to-user {
		color: #5c7080;
		font-size: 18px;
		// margin-top: 25%;
		font-weight: bold;
		line-height: 21px;
		text-align: center;
		font-style: normal;
		font-family: Merriweather;
	}
`;

const Analytics = () => {
	const [drillTypes, setDrillTypes] = useState(null);
	const [buildings, setBuildings] = useState(null);
	const [selectedBuildings, setSelectedBuildings] = useState(null);
	const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
	const [selectedDrillType, setSelectedDrillType] = useState(null);
	const [showOnly, setShowOnly] = useState(SHOW_ONLY_ANALYTICS_OPTIONS[0]);
	const [overviewData, setOverviewData] = useState(null);
	const [yearlyTrendsData, setYearlyTrendsData] = useState(null);
	const [drillsByStatusData, setDrillsByStatusData] = useState(null);
	const [trendsShowCompletedDrills, setTrendsShowCompletedDrills] = useState(
		YEARLY_TRENDS_DROPDOWN_OPTIONS[0],
	);
	const [byYearShowCompletedDrills, setByYearShowCompletedDrills] = useState(
		BY_YEAR_RESUME_DROPDOWN_OPTIONS[0],
	);
	const drillsDashboardLastTabId = useSelector(
		state => state.route.drillsDashboardLastTabId,
	);

	useEffect(() => {
		BuildingsService.getAll(true).then(serviceBuildings =>
			setBuildings(serviceBuildings),
		);
	}, [buildings]);

	useEffect(() => {
		if (selectedSchoolYear) {
			DrillTypesService.getAll(true, selectedSchoolYear.value).then(
				serviceDrillTypes => setDrillTypes(serviceDrillTypes),
			);
		}
	}, [selectedSchoolYear]);

	// to reset form
	useEffect(() => {
		if (drillsDashboardLastTabId === 'analytics') {
			resetForm();
		}
	}, [drillsDashboardLastTabId, resetForm]);

	// form reset
	const resetForm = () => {
		setDrillTypes(null);
		setShowOnly(null);
		setBuildings(null);
		setSelectedBuildings(null);
		setSelectedSchoolYear(null);
		setSelectedDrillType(null);
		setShowOnly(SHOW_ONLY_ANALYTICS_OPTIONS[0]);
	};

	const getAnalyticsData = () => {
		if (selectedSchoolYear) {
			DrillsAnalyticsService.getOverview(
				selectedSchoolYear.value,
				selectedBuildings,
				selectedDrillType ? selectedDrillType.uuid : null,
			).then(data => setOverviewData(data));
			DrillsAnalyticsService.getYearlyTrends(
				selectedSchoolYear.value,
				selectedBuildings,
				selectedDrillType ? selectedDrillType.uuid : null,
			).then(yearlyTrends => setYearlyTrendsData(yearlyTrends));
		}
	};

	const getDrillsByStatusData = () => {
		const selectedBuildingIds = getSelectedBuildingIds();
		if (selectedSchoolYear && showOnly.value !== 'all') {
			DrillsAnalyticsService.getDrillsByStatus(
				selectedSchoolYear.value,
				showOnly.value,
				selectedDrillType ? selectedDrillType.uuid : null,
				selectedBuildingIds || null,
			).then(drillsByStatus => setDrillsByStatusData(drillsByStatus));
		}
	};

	const getSelectedBuildingIds = () => {
		const selectedBuildingIds = [];
		if (selectedBuildings) {
			selectedBuildings.forEach(element => {
				if (element.id) {
					selectedBuildingIds.push(element.id);
				}
			});
		}
		return selectedBuildingIds;
	};

	useEffect(() => {
		getAnalyticsData();
	}, [selectedBuildings, selectedSchoolYear, selectedDrillType]);

	useEffect(() => {
		getDrillsByStatusData();
	}, [showOnly, selectedSchoolYear, selectedDrillType, selectedBuildings]);

	return (
		<Wrapper>
			{/* site, school year, drill type filters */}
			<div className="dropdown-filters">
				{/* Select options */}
				<div className="multi-select-container">
					<FormLabel>School</FormLabel>
					<Select
						value={
							selectedBuildings
							// || {
							// 	value: 'all-schools',
							// 	label: 'All schools',
							// }
						}
						placeholder="Select School"
						className="multi-select"
						closeMenuOnSelect={false}
						components={animatedComponents}
						isMulti
						isLoading={buildings === null}
						options={buildings}
						onChange={selected => setSelectedBuildings(selected)}
					/>
				</div>
				{/* School year */}
				<SchoolYearDropdown
					selectedSchoolYear={selectedSchoolYear}
					onChange={item => setSelectedSchoolYear(item)}
				/>
				{/* Drill type */}
				<Dropdown
					label="Drill type"
					placeholder="Select a Drill type"
					value={selectedDrillType}
					options={drillTypes}
					onChange={item => setSelectedDrillType(item)}
				/>
				<Dropdown
					label="Show only"
					value={showOnly}
					options={SHOW_ONLY_ANALYTICS_OPTIONS}
					onChange={item => setShowOnly(item)}
				/>
				<div className="export-button-container">
					<Button
						onClick={() => {
							DrillsAnalyticsService.exportCSV(
								selectedSchoolYear.value,
								selectedBuildings,
								selectedDrillType
									? selectedDrillType.uuid
									: null,
								showOnly.value === 'all'
									? null
									: showOnly.value,
							);
						}}
						text="Export data"
						intent="tertiary"
						icon="download"
					/>
				</div>
			</div>
			{/* {!selectedSchoolYear && (
				<div className="instructions-to-user">
					Please select a School Year
				</div>
			)} */}
			{showOnly.value === 'all' ? (
				<AllDrillsAnalytics
					key={`${trendsShowCompletedDrills.value}${
						byYearShowCompletedDrills.value
					}`}
					overviewData={overviewData}
					yearlyTrendsData={yearlyTrendsData}
					selectedYear={selectedSchoolYear?.value}
					selectedBuildings={selectedBuildings}
					trendsDropdownValue={trendsShowCompletedDrills}
					byYearDropdownValue={byYearShowCompletedDrills}
					onTrendsTypeChange={value => {
						setTrendsShowCompletedDrills(value);
					}}
					onByYearTypeChange={value => {
						setByYearShowCompletedDrills(value);
					}}
				/>
			) : (
				<SpecificDrillsAnalytics
					selectedYear={selectedSchoolYear?.value}
					drillsByStatusData={drillsByStatusData}
					showOnly={showOnly}
				/>
			)}
		</Wrapper>
	);
};

export { Analytics };
