import React from 'react';
import { RadioGroup } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import './css/RadioButton.scss';

export const RadioButtonGroup = ({
	label,
	onChange,
	selectedValue,
	size, // small | medium
	bordered = false,
	required = false,
	disabled = false, // disables all options in group
	layout, // block | inline
	fullwidth = false,
	children,
	error = null,
	name = null,
	errorMessage,
}) => {
	return (
		<div className={`radio-button-group-wrapper ${fullwidth ? 'fullwidth' : ''}`}>
			{label && label.length > 0 && (
				<label className={`radio-button-group-label size-${size ? size : 'small'}`}>
					{label}
					{label &&
						label.length > 0 &&
						(required ? (
							<span className="label-required">*</span>
						) : (
							<span className="label-optional">(Optional)</span>
						))}
				</label>
			)}
			<RadioGroup
				className={`radio-button-group size-${size ? size : 'small'} layout-${
					layout ? layout : 'block'
				} ${bordered ? 'bordered' : ''} ${fullwidth ? 'fullwidth' : ''} ${
					error ? 'error' : ''
				}`}
				onChange={onChange}
				selectedValue={selectedValue}
				name={name}
				disabled={disabled}
			>
				{children}
			</RadioGroup>
			{error && <span className="text-danger mt-2">{error}</span>}
			{errorMessage && (
				<p
					className="text-danger mt-2"
					dangerouslySetInnerHTML={{ __html: errorMessage }}
				/>
			)}
		</div>
	);
};

RadioButtonGroup.propTypes = {
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	onChange: PropTypes.func.isRequired,
	selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	size: PropTypes.string,
	bordered: PropTypes.bool,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	layout: PropTypes.string,
	fullwidth: PropTypes.bool,
	error: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
	name: PropTypes.string,
	errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
