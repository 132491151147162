import React,{useContext} from "react";
import PropTypes from 'prop-types';
import {ActionsButton, Button} from "componentsV2";
import { SafetyplansContext } from "../../Providers/SafetyplansProvider";

export const TemplatesActionList = ({
    template,
    history,
    setLoading
}) => {

    const {    
        handleUpdateTemplate,
        handleCopyTemplate,
        showArchiveModal,
        showDeleteModal
    } = useContext(SafetyplansContext);

    return (
        <ActionsButton>
            <Button
                large
                text="View"
                icon="eye-open"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
                onClick={() =>
                    history.push('/core/view/' + template.id)
                }
            />
            {!template.archivedBy && (
                <>
                    <Button
                        large
                        text="Edit template"
                        icon="annotation"
                        size="md"
                        type="ghostDefault"
                        intent="default"
                        wrapperClass="displayBlock"
                        className="actionButtonElement"
                        onClick={() =>
                            history.push('/core/templates/' + template.id)
                        }
                    />
                    <Button
                        large
                        text="Edit details"
                        icon="edit"
                        size="md"
                        type="ghostDefault"
                        intent="default"
                        wrapperClass="displayBlock"
                        className="actionButtonElement"
                        onClick={() => handleUpdateTemplate(template.id)}
                    />
                    <Button
                        large
                        text="Copy"
                        icon="duplicate"
                        size="md"
                        type="ghostDefault"
                        intent="default"
                        wrapperClass="displayBlock"
                        className="actionButtonElement"
                        onClick={() => handleCopyTemplate(template.id)}
                    />
                </>
            )}
            <Button
                large
                text={template.archivedBy ? 'Unarchive' : 'Archive'}
                icon={template.archivedBy ? 'unarchive' : 'archive'}
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
                onClick={()=> showArchiveModal({
                    id: template.id,
                    title: template.name,
                    archived: template.archived,
                    source: 'list'
                })}
            />
            {!template.archivedBy && (<Button
                large
                text="Delete"
                icon="trash"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
                onClick={() => {showDeleteModal(template, 'list')}}
            />)}
        </ActionsButton>
    );
}

TemplatesActionList.propTypes = {
    drillScenario: PropTypes.object,
};