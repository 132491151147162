import React, { useState, useReducer, useEffect } from 'react';
import { CustomTabs, Animated } from 'componentsV2';
import { MapOptionCard } from '..';
import { GEOMETRY_TYPE_ICON, GEOMETRY_TYPE_ROUTE, MAP_ICON_LIST } from '../..';
import { AvailableToUsePanel, InUsePanel } from '.';
import propTypes from 'prop-types';
import { Tab } from '@blueprintjs/core';
import './css/IconList.scss';
import { FloorplanService } from 'services';
import { onDragShapeHandler } from '../Functions';
import { removeDuplicatesWithCount } from 'utilities/removeDuplicatesWithCount';

export const IconList = ({
	floorplan,
	toggleMapOption,
	editPermission,
	siteId,
	layer,
	listUpdated,
	triggerShowHide,
	optionToShow,
	mapToaster,
	transformer,
	floorplanShapes,
	setFloorplanShapes,
}) => {
	const [selectedTab, setSelectedTab] = useState('InUse');
	const floorplanArchived = !!floorplan?.archivedBy;
	const iconReducer = (iconDetails, action) => {
		iconDetails = action.payload;
		return {
			AvailableIconsList: iconDetails.AvailableIconsList,
			inUseIconsList: iconDetails.inUseIconsList,
			availableSearchKey: iconDetails.availableSearchKey,
			inUseSearchKey: iconDetails.inUseSearchKey,
			noDataAdded: iconDetails.noDataAdded,
			hideAll: iconDetails.hideAll,
		};
	};

	const initialState = {
		AvailableIconsList: [],
		inUseIconsList: [],
		noDataAdded: false,
		availableSearchKey: '',
		inUseSearchKey: '',
		hideAll: true,
	};

	const [loading, setLoading] = useState(true);
	const [iconDetails, dispatchIconDetails] = useReducer(iconReducer, initialState);

	useEffect(() => {
		getIconsList();
	}, [floorplan, listUpdated]);

	useEffect(() => {
		onShowHideAllHandler(triggerShowHide);
	}, [triggerShowHide]);

	const getIconsList = () => {
		setLoading(true);
		FloorplanService.getIconList(siteId)
			.then(res => {
				const availableToUseList = removeDuplicatesWithCount(res.data, 'title');
				const inUseList = res.data
					.filter(shape => shape.floorplanId === floorplan.id)
					.map(shape => {
						const shapesIds = shape.shapesIds.map(shapeId => parseInt(shapeId, 10));
						const isVisible = floorplanShapes.some(
							floorplanShape =>
								shapesIds.includes(floorplanShape.id) &&
								floorplanShape.visible &&
								(floorplanShape.geometryType === GEOMETRY_TYPE_ICON ||
									floorplanShape.geometryType === GEOMETRY_TYPE_ROUTE),
						);
						return {
							...shape,
							visible: isVisible,
						};
					});
				dispatchIconDetails({
					payload: {
						...iconDetails,
						inUseIconsList: inUseList,
						noDataAdded: inUseList.length === 0,
						AvailableIconsList: availableToUseList,
					},
				});
			})
			.catch(err => {
				mapToaster(err?.error?.description);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const tabs = [
		{
			title: 'In use',
			id: 'InUse',
		},
		{
			title: 'Available to use',
			id: 'AvailableToUse',
		},
	];

	const onChangeTabHandler = tab => {
		setSelectedTab(tab);
	};

	const onShowHideAllHandler = val => {
		const updatedInUseList = iconDetails.inUseIconsList.map(iconShape => {
			changeShapeIdsVisibility(iconShape, !val);
			iconShape.visible = !val;
			return iconShape;
		});
		dispatchIconDetails({
			payload: {
				...iconDetails,
				inUseIconsList: updatedInUseList,
				hideAll: val,
			},
		});
	};

	const onToggleShape = (shape, visible) => {
		changeShapeIdsVisibility(shape, visible);

		// toggle shape (show/hide) from inUseIconList array
		const updatedList = getUpdatedList(shape, visible);
		dispatchIconDetails({
			payload: {
				...iconDetails,
				inUseIconsList: updatedList,
			},
		});
	};

	const changeShapeIdsVisibility = async (shape, visible) => {
		// get ShapesId
		const shapesIds = shape.shapesIds.map(shapeId => parseInt(shapeId, 10));

		//update floorplanShapes list visibility
		updateFloorplanShapesVisibility(shapesIds, visible);

		// toggle shape (show/hide) from map layer
		layer.children
			.filter(child => {
				return shapesIds.includes(child.attrs.shape?.id);
			})
			.map(childShape => {
				if (shapesIds.includes(childShape.attrs.shape?.id)) {
					if (!visible) {
						childShape.hide();
					} else {
						childShape.show();
					}
					//detach transformer
					transformer.current.detach();
				}
			});
	};

	const updateFloorplanShapesVisibility = (shapesIds, visible) => {
		setFloorplanShapes(oldShapes =>
			oldShapes.map(floorplanShape => {
				if (
					shapesIds.includes(floorplanShape.id) &&
					(floorplanShape.geometryType === GEOMETRY_TYPE_ICON ||
						floorplanShape.geometryType === GEOMETRY_TYPE_ROUTE)
				) {
					return {
						...floorplanShape,
						visible: visible,
					};
				}
				return floorplanShape;
			}),
		);
	};

	const getUpdatedList = (shape, visible) => {
		return iconDetails.inUseIconsList.map(iconShape => {
			if (iconShape.id === shape.id) {
				iconShape.visible = visible;
			}
			return iconShape;
		});
	};

	const onDragItem = async (e, iconShape) => {
		// if its from avail to use add shapeId
		if (!editPermission) return null;
		let inListIcon = iconDetails.inUseIconsList.find(shape => shape.title == iconShape.title);
		if (!inListIcon) {
			inListIcon = iconShape;
		}
		await changeShapeIdsVisibility(inListIcon, true);
		onDragShapeHandler(e, inListIcon);
	};

	return (
		<MapOptionCard
			isOpen={optionToShow === MAP_ICON_LIST}
			title="Icons"
			onClick={() => toggleMapOption(MAP_ICON_LIST)}
			titleBorder={false}
			headerItem={
				<CustomTabs
					identifier="iconsListTabs"
					onChangeHandler={onChangeTabHandler}
					renderActiveTabPanelOnly={true}
					selectedTabId={selectedTab}
				>
					{tabs.map(tab => (
						<Tab
							id={tab.id}
							key={tab.id}
							panel={<></>}
							title={tab.title}
							className="CustomTabs__Tab"
						/>
					))}
				</CustomTabs>
			}
		>
			<Animated>
				<div className="map-icon-list-container">
					{selectedTab === 'InUse' && (
						<InUsePanel
							loading={loading}
							iconDetails={iconDetails}
							dispatchIconDetails={dispatchIconDetails}
							onToggleShape={onToggleShape}
							onDragItem={onDragItem}
							onShowHideAllHandler={onShowHideAllHandler}
							editPermission={editPermission}
							floorplanArchived={floorplanArchived}
						/>
					)}
					{selectedTab === 'AvailableToUse' && (
						<AvailableToUsePanel
							loading={loading}
							iconDetails={iconDetails}
							dispatchIconDetails={dispatchIconDetails}
							onDragItem={onDragItem}
							editPermission={editPermission}
							floorplanArchived={floorplanArchived}
						/>
					)}
				</div>
			</Animated>
		</MapOptionCard>
	);
};

IconList.propTypes = {
	floorplan: propTypes.object,
	editPermission: propTypes.bool,
	toggleMapOption: propTypes.func,
};
