import { DocumentService } from "services";
import { Intent } from "componentsV2";
import { Helper } from 'utilities/helper';
import { TYPE_360IMAGE, TYPE_DOCUMENT } from "pages/Documents";

export const RenameModalFn = (
    modalName,
    setLoading,
    documentData,
    dispatchDocumentData,
    closeModal,
    toaster,
    setModalToShow,
    getDocumentsList,
) => {
    const showRenameModal = (document) => {
        setModalToShow(modalName);
        dispatchDocumentData({
            ...documentData,
            id: document.id,
            type: document.type == TYPE_360IMAGE ? TYPE_360IMAGE : TYPE_DOCUMENT,
            oldTitle: document.title,
            title: document.type == TYPE_360IMAGE ? document.title : document.title.includes('.') ? document.title.split('.').slice(0, -1).join('.') : document.title,
            ext: document.type == TYPE_360IMAGE ? "" : document.title.includes('.') ? document.title.split('.').pop() : "",
        });
    }

    const handleChangeTitle = (e) => {
        dispatchDocumentData({ ...documentData, title: e.target.value });
    }

    const handleRenameSubmit = () => {
        if (!!documentData.title) {
            setLoading(true);
            DocumentService.renameDocumentTitle({
                id: documentData.id,
                type: documentData.type,
                title: documentData.type == TYPE_360IMAGE ? documentData.title : documentData.oldTitle.includes('.') ? `${documentData.title}.${documentData.ext}` : documentData.title,
            })
                .then(response => {
                    toaster(`You have successfully renamed "${documentData.oldTitle}"!`, Intent.SUCCESS);
                    closeModal();
                    getDocumentsList();
                })
                .catch(error => {
                    const errorMessage = error.error.fields.type || error.error.fields.title;
                    const edited = error.error.fields.edited;
                    // If the user did not change the name, display the message as valid.
                    if (edited === false) {
                        toaster(`You have successfully renamed "${documentData.oldTitle}"!`, Intent.SUCCESS);
                        closeModal();
                    } else {
                        toaster(Helper.capitalizeFirstLetter(errorMessage), Intent.DANGER);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    return {
        showRenameModal,
        handleChangeTitle,
        handleRenameSubmit,
    }
}    