import { useEffect } from 'react';

// actualDate: DateTime {ts: 1619788440000, _zone: FixedOffsetZone, loc: Locale, invalid: null, weekData: null, …}
// buildingName: "NaviGate High School"
// date: DateTime {ts: 1619788440000, _zone: FixedOffsetZone, loc: Locale, invalid: null, weekData: null, …}
// drillType: {id: 1, uuid: 'af6c63d6-da95-4f03-6487-8dae250df1eb', name: 'Emergency Evacuation Drill', schedulingInstructions: null, postingInstructions: null, …}
// id: 135
// log:
// answers: []
// drillLoggedDate: null
// logCompleted: false
// logCompletedDate: null
// logId: 95
// logStartedDate: {date: '2021-04-29 13:15:52.000000', timezone_type: 3, timezone: 'UTC'}
// user: {id: 2047485660, primaryBuildingId: 5896, accessType: 3, accountType: null, lastUpdated: '2021-02-03 12:46:42.350649', …}
// [[Prototype]]: Object
// scheduleId: 135
// scheduledDrillDate: {date: '2021-04-30 13:14:00.000000', timezone_type: 3, timezone: 'UTC'}
// status: "scheduled"
// title: "#1 - Emergency Evacuation Drill"
// type: "Emergency Evacuation Drill"
// typeId: 1
// year: 2020

// in_progress, completed, overdue, scheduled
// [true, false, true, false]
const removeNotMatchingStatus = (statusValue, data) =>
	data.filter(drill => drill.status === statusValue);

const removeUnmatchingPostedEntries = (logPosted, data) => {
	if (logPosted !== null && logPosted !== 'all' && data && data.length) {
		return data.filter(drill => {
			if (!drill.log && logPosted === 'no') {
				return true;
			}
			return (
				(logPosted === 'yes' && drill.log?.logCompleted) ||
				(logPosted === 'no' && !drill.log?.logCompleted)
			);
		});
	}
	return data;
};

const removeUnmatchingTypeIds = (drillType, data) => {
	if (drillType !== null && data && data.length) {
		return data.filter(drill => drill.typeId === drillType);
	}
	return data;
};

const removeUnmatchingDates = (dateFrom, dateTo, data, key) => {
	let filteredData = data;
	if (data && data.length && key !== null && key !== '') {
		if (dateFrom !== null) {
			filteredData = filteredData.filter(drill => drill[key] >= dateFrom);
		}
		if (dateTo !== null) {
			filteredData = filteredData.filter(drill => drill[key] <= dateTo);
		}
	}
	return filteredData;
};

// This hook was built to simplify search drill logs filter functionality
export const useFilter = (
	filter,
	selectedDrillType,
	setFilteredData,
	originalData,
) => {
	useEffect(() => {
		// skip the operation
		if (!originalData || originalData.length === 0) {
			setFilteredData(originalData);
			return;
		}

		let localFilteredData = [];
		// Drill type filtering
		if (
			selectedDrillType &&
			selectedDrillType?.value !== 'All drill types'
		) {
			localFilteredData = removeUnmatchingTypeIds(
				selectedDrillType?.value,
				originalData,
			);
		} else {
			localFilteredData = originalData;
		}
		// Dates filtering
		// Passing localFilteredData for the filters to act as a conjunction of conditions
		localFilteredData = removeUnmatchingPostedEntries(
			filter?.logPosted,
			localFilteredData,
		);

		localFilteredData = removeUnmatchingDates(
			filter?.scheduledDrillDateFrom,
			filter?.scheduledDrillDateTo,
			localFilteredData,
			'date',
		);

		localFilteredData = removeUnmatchingDates(
			filter?.actualDrillDateFrom,
			filter?.actualDrillDateTo,
			localFilteredData,
			'actualDate',
		);

		localFilteredData = removeUnmatchingDates(
			filter?.datePostedFrom,
			filter?.datePostedTo,
			localFilteredData,
			'datePosted',
		);

		const completed = filter?.statusCompleted
			? removeNotMatchingStatus('completed', localFilteredData)
			: [];

		const inProgress = filter?.statusInProgress
			? removeNotMatchingStatus('in_progress', localFilteredData)
			: [];

		const overdue = filter?.statusOverdue
			? removeNotMatchingStatus('overdue', localFilteredData)
			: [];

		const scheduled = filter?.statusScheduled
			? removeNotMatchingStatus('scheduled', localFilteredData)
			: [];

		const notScheduled = filter?.statusNotScheduled
			? removeNotMatchingStatus('not_scheduled', localFilteredData)
			: [];

		const filteredData = [
			...completed,
			...inProgress,
			...overdue,
			...scheduled,
			...notScheduled
		];
		setFilteredData(filteredData);
	}, [filter, selectedDrillType, originalData]);
};
