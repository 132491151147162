import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { Checkbox, Radio, RadioGroup } from '@blueprintjs/core';

import { Input } from 'components/Input';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { CardModal } from 'components/Modals/CardModal';
import { MoreActionsButton } from 'components/MoreActionsButton';
import { PageHeader } from 'components/PageHeader';
import { SchoolYearDropdown } from 'components/SchoolYearDropdown';
import { SitesDropdown } from 'components/SitesDropdown';
import { Table } from 'components/Table';
import { ReportService } from 'services';
import { GRADE_LEVELS } from 'utilities/drills/mockData';
import { poll } from 'utilities/debounce';

const Page = styled.div`
	padding: 8px 24px;

	.table {
		.red {
			color: #9f1c3a;
		}
	}

	.modal-card {
		width: 450px;

		.header {
			font-size: 15px;
			margin-top: 16px;
			font-weight: 700;
			line-height: 22px;
			font-style: normal;
			letter-spacing: 0.4px;
			font-family: Merriweather;
		}

		.report-modal-body {
			overflow-y: auto;
			max-height: 400px;

			.dropdown {
				margin-bottom: 16px;
			}

			.radio-cont {
				margin-bottom: 16px;
			}
		}
	}
`;

const BASE_VIEW = {
	modalToShow: null,
	selectedReport: null,
	selectedSite: null,
	selectedSchoolYear: null,
	includeScheduledDrills: false,
	printDrillsForAllSchools: false,
	reportFormHasErrors: false,

	newReportId: null,
};

const BASE_STATE = {
	...BASE_VIEW,
	loading: true,
	error: null,
	data: [],
};

class NewReportPage extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		ReportService.fetchReportsForDistrict()
			.then(reports => {
				this.setState({
					...BASE_STATE,
					loading: false,
					data: reports,
				});
			})
			.catch(() => {
				this.setState({
					...BASE_STATE,
					loading: false,
					error: 'Could not load this page. Please try refreshing',
				});

				Swal.fire({
					title: 'Oops!',
					text: 'Could not load this page. Please try refreshing',
					icon: 'error',
					toast: true,
					position: 'top',
					showConfirmButton: false,
				});
			});
	}

	setModalToShow = (modalToShow = null, selectedReport = null) =>
		this.setState({
			modalToShow,
			selectedReport,
		});

	updateReportParameter = (path, value) => {
		const { selectedReport } = this.state;
		if (selectedReport?.parameters) {
			this.setState({
				selectedReport: {
					...selectedReport,
					parameters: {
						...selectedReport.parameters,
						[path]: value,
					},
				},
			});
		}
	};

	updateGradeLevel = key => {
		this.setState(prevState => ({
			selectedReport: {
				...prevState.selectedReport,
				parameters: {
					...prevState.selectedReport.parameters,
					gradeLevels: {
						...prevState.selectedReport.parameters.gradeLevels,
						[key]: !prevState.selectedReport.parameters.gradeLevels[
							key
						],
					},
				},
			},
		}));
	};

	runReport = () => {
		const {
			selectedSite,
			selectedSchoolYear,
			includeScheduledDrills,
			printDrillsForAllSchools,
			selectedReport,
		} = this.state;
		const parameters = selectedReport?.parameters;
		const gradeLevels = parameters?.gradeLevels;

		if (parameters) {
			const isValid = Object.values(parameters).every(
				value => value !== null,
			);

			if (!isValid) {
				this.setState({
					reportFormHasErrors: true,
				});
				return;
			}
		}

		ReportService.buildReport({
			...parameters,
			...(gradeLevels &&
				Object.keys(gradeLevels).filter(key => !!gradeLevels[key])),
			sites: [selectedSite?.id],
			showScheduledDrills: includeScheduledDrills,
			printAll: printDrillsForAllSchools,
			reportKey: selectedReport?.reportKey,
			year: selectedSchoolYear.value,
		})
			.then(report => {
				this.setState({
					modalToShow: 'runningReport',
				});
				this.pollReportStatus(report?.id);
			})
			.catch(() => {
				Swal.fire({
					title: 'Oops!',
					text: 'Could not build this report. Please try again.',
					icon: 'error',
				});
			});
	};

	pollReportStatus = reportId => {
		poll({
			fn: () => ReportService.getReportStatus(reportId),
			validate: status =>
				['complete', 'error', 'deleted'].includes(status),
			interval: 2500,
		})
			.then(res => {
				if (res === 'error') throw res;
			})
			.catch(() => {
				this.setState({
					...BASE_VIEW,
				});

				Swal.fire({
					title: 'Oops!',
					text: 'Could not build this report. Please try again.',
					icon: 'error',
				});
			});
	};

	render() {
		const {
			loading,
			error,
			data,
			modalToShow,
			selectedReport,
			selectedSite,
			selectedSchoolYear,
			includeScheduledDrills,
			printDrillsForAllSchools,
			reportFormHasErrors,
		} = this.state;
		const { history } = this.props;

		if (error) return 'Something went wrong, please try refreshing';

		if (loading) {
			return <LoadingSpinner />;
		}

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Printable Reports"
					onBackBtnClick={history.goBack}
				/>

				{/* list of reports */}
				<Table
					headers={['General Reports', 'Description', 'Actions']}
					data={data}
					renderItem={item => (
						<>
							<td className="red">{item.name}</td>

							<td>{item.description || 'N/A'}</td>

							<td>
								<MoreActionsButton
									actions={[
										{
											id: 'run',
											title: 'Run report',
											icon: 'eye-open',
											handler: () =>
												this.setModalToShow(
													'configureReport',
													item,
												),
										},
									]}
								/>
							</td>
						</>
					)}
				/>

				{/* drill log report modal */}
				<CardModal
					isVisible={modalToShow === 'configureReport'}
					setIsVisible={this.setModalToShow}
					title="Drill log report"
					actionTitle="Run report"
					onActionClick={this.runReport}
				>
					<div className="report-modal-body">
						{/* header */}
						<h4 className="header">{selectedSite?.name}</h4>

						{/* sites dropdown */}
						<SitesDropdown
							selectedSite={selectedSite}
							onChange={item =>
								this.setState({ selectedSite: item })
							}
						/>

						{/* school year dropdown */}
						<SchoolYearDropdown
							selectedSchoolYear={selectedSchoolYear}
							onChange={item =>
								this.setState({ selectedSchoolYear: item })
							}
						/>

						{/* Include scheduled drills */}
						<RadioGroup
							className="radio-cont"
							label="Include scheduled drills"
							onChange={({ target }) => {
								this.setState({
									includeScheduledDrills: target.value,
								});
							}}
							selectedValue={includeScheduledDrills}
						>
							<Radio label="Yes" value="1" />
							<Radio label="No" value="0" />
						</RadioGroup>

						{/* print drills for all schools */}
						<RadioGroup
							className="radio-cont"
							label="Print drills for all schools"
							onChange={({ target }) => {
								this.setState({
									printDrillsForAllSchools: target.value,
								});
							}}
							selectedValue={printDrillsForAllSchools}
						>
							<Radio label="Yes" value="1" />
							<Radio label="No" value="0" />
						</RadioGroup>

						{/* extra parameters */}
						{selectedReport?.parameters &&
							Object.keys(selectedReport?.parameters).map(key => {
								if (key === 'gradeLevels') {
									return (
										<div>
											<h4 className="header">
												Grade levels
											</h4>

											{Object.keys(GRADE_LEVELS).map(
												gradeKey => {
													const isChecked =
														selectedReport
															?.parameters
															?.gradeLevels[
															gradeKey
														];
													return (
														<Checkbox
															label={
																GRADE_LEVELS[
																	gradeKey
																]
															}
															checked={isChecked}
															onChange={() =>
																this.updateGradeLevel(
																	gradeKey,
																)
															}
														/>
													);
												},
											)}
										</div>
									);
								}

								return (
									<Input
										label={key
											.replace(/([A-Z])/g, ' $1')
											.toLowerCase()}
										value={selectedReport?.parameters[key]}
										onChange={({ target: { value } }) =>
											this.updateReportParameter(
												key,
												value,
											)
										}
										error={
											reportFormHasErrors &&
											!selectedReport?.parameters[key]
										}
									/>
								);
							})}
					</div>
				</CardModal>

				{/* running report modal */}
				<CardModal
					isVisible={modalToShow === 'runningReport'}
					setIsVisible={this.setModalToShow}
					title="Running report"
				>
					{/* text */}
					<p className="modal-text">
						Reports may take up to several minutes based on size.
					</p>

					{/* loading spinner */}
					<LoadingSpinner />
				</CardModal>

				{/* report finished modal */}
				<CardModal
					isVisible={modalToShow === 'finishedReport'}
					setIsVisible={this.setModalToShow}
					title="Your report has finished"
				>
					{/* header */}
					<h4 className="modal-text">{selectedReport?.name}</h4>

					{/* text */}
					<p className="modal-text">
						You can download or open it using the buttons below.
					</p>

					{/* loading spinner */}
					<LoadingSpinner />
				</CardModal>
			</Page>
		);
	}
}

NewReportPage.propTypes = {
	history: PropTypes.object,
};

export default NewReportPage;
