import React, { useEffect, useState } from 'react';
import { RAPID_ICONS } from 'assets/respond';
import { STEP_TWO, STEP_FOUR, ALARM_SAFE_MODAL } from '../../consts';
import { Badge } from 'componentsV2';
import './css/Countdown.scss';

export const Countdown = ({ formData, onChangeModalData, activetAlarm }) => {
	const [seconds, setSeconds] = useState(5);
	const [isPaused, setIsPaused] = useState(false);

	useEffect(() => {
		onChangeModalData({
			modalTitle: null,
			showCancelAction: true,
			showActivateAlarmAction: true,
			secondBtnTitle: 'Go back',
			onGoBackHandler: onGoBackHandler,
			onActivateAlarmHandler: onActivateAlarmHandler,
			showHeader: false,
			onCancelHandler: handlePauseResumeCounter,
		});

		const intervalId = setInterval(() => {
			if (!isPaused) {
				setSeconds(prevSeconds => {
					if (prevSeconds > 0) {
						return prevSeconds - 1;
					}
					clearInterval(intervalId); // Stop the countdown when seconds reach 0
					onActivateAlarmHandler();
					return 0;
				});
			}
		}, 1000);

		// Cleanup the interval when the component is unmounted
		return () => clearInterval(intervalId);
	}, [isPaused]);

	const handlePauseResumeCounter = () => {
		setIsPaused(prevIsPaused => !prevIsPaused);
	};

	const onGoBackHandler = () => {
		onChangeModalData({
			activeStep: STEP_TWO,
			modalSubTitle: '',
		});
	};

	const onActivateAlarmHandler = () => {
		const selectedAlarm = formData.alarmType;

		onChangeModalData({
			onActivateAlarmHandler: activetAlarm(selectedAlarm),
		});
	};

	const secondsTitle = seconds <= 1 ? 'second' : 'seconds';
	return (
		<div className="activate-alarm-modal-count-down">
			<img src={RAPID_ICONS['hold']} height={100} width={100} />
			<p className="activate-alarm-modal-count-down-title">
				{formData.alarmType.title} alarm activating in {seconds} {secondsTitle} for
			</p>
			<div className="activate-alarm-modal-count-down-sitelist">
				{formData.selectedSites.map(selectedSite => (
					<Badge key={selectedSite.id} text={selectedSite.name} size="md" />
				))}
			</div>
		</div>
	);
};
