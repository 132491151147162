import React from "react";
import PropTypes from 'prop-types';
import { TinymceEditor, FileUpload } from 'componentsV2';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

const DocumentType = ({section}) => {

    const fileInfo = section?.valueFile?.id ? section.valueFile : section?.baseFile;

    return (
        <FileUpload
            identifier={`file-updated`}
            labelState={false}
            disabled={true}
            allowedFiles={['pdf', 'png', 'jpeg', 'jpg', 'txt', 'gif', 'doc', 'docx', 'xlsx', 'xls']}
            allowedFilesLegend={`PDF, PNG, JPEG, GIF, TXT, DOCS, XLSX or XLS (max. ${MAX_FILE_UPLOADER_SIZE}mb)`}
            maxFileSize={MAX_FILE_UPLOADER_SIZE}
            file={fileInfo}
            fileForEditing={false}
            showProgressBar={false}
            showDeleteBtn={false}
        />
    );
}

const ContentType = ({content, onEditorChange, hasEditor}) => {
    
    if(hasEditor === true) {
        return (
            <TinymceEditor
                initialValue={content}
                placeholder="Enter tab content..."
                onInit={(evt, editor) => {
                    return null;
                }}
                onChange={() => {}}
                onEditorChange={onEditorChange}
                setupProps={() =>{}}
                editorHeight="100%"
            />
        );
    }

    return (
        <div
            className="content-body-html"
            dangerouslySetInnerHTML={{
                __html: content,
            }}
        />
    )
}

DocumentType.propTypes = {
	section: PropTypes.object,
};

ContentType.propTypes = {
    hasEditor: PropTypes.bool
};

export { ContentType, DocumentType };