import React from 'react';
import { Button, Tooltip, TooltipPosition, Badge } from 'componentsV2';
import { MAP_LAYERS, MAP_SITE_INFO, MAP_AREAS, MAP_TEXT_LIST, MAP_ICON_LIST } from '../';
import './css/MapInfoButtons.scss';
import { SATELLITE_VIEW } from '../../consts';

export const MapInfoButtons = ({
	editPermission,
	optionToShow,
	toggleMapOption,
	showHideAllHandler,
	triggerShowHide,
	mapZoom,
	baseZoomLevel,
	toggleSatelliteView,
	mapViewType,
}) => {
	const MapButton = ({
		title,
		isActive,
		disabled,
		icon,
		onClick,
		className,
		type = 'tertiary',
		theme = 'light',
	}) => (
		<Tooltip tooltipTitle={title} theme={theme} position={TooltipPosition.LEFT}>
			<Button
				disabled={disabled}
				small
				icon={icon}
				iconColor={isActive ? 'primary' : ''}
				size="lg"
				type={type}
				intent="default"
				onClick={onClick}
				className={className}
			/>
		</Tooltip>
	);

	return (
		<div className="map-options-buttons">
			<MapButton
				isActive={optionToShow === MAP_SITE_INFO}
				title="Info"
				disabled={false}
				icon="info-sign"
				onClick={() => toggleMapOption(MAP_SITE_INFO)}
			/>
			<MapButton
				isActive={optionToShow === MAP_LAYERS}
				title="Layers"
				disabled={false}
				icon="layers"
				onClick={() => toggleMapOption(MAP_LAYERS)}
			/>
			<MapButton
				isActive={optionToShow === MAP_TEXT_LIST}
				title="Texts"
				disabled={false}
				icon="new-text-box"
				onClick={() => toggleMapOption(MAP_TEXT_LIST)}
			/>
			<MapButton
				isActive={optionToShow === MAP_AREAS}
				title="Areas"
				disabled={false}
				icon="draw"
				onClick={() => toggleMapOption(MAP_AREAS)}
			/>
			<MapButton
				isActive={optionToShow === MAP_ICON_LIST}
				title="Icons"
				disabled={false}
				icon="flag"
				onClick={() => toggleMapOption(MAP_ICON_LIST)}
			/>
			<div className="satellite-button">
				<MapButton
					isActive={mapViewType == SATELLITE_VIEW}
					title={
						mapViewType == SATELLITE_VIEW ? (
							<>
								Satellite is shown.
								<br /> Click to hide.{' '}
							</>
						) : (
							<>
								Satellite is hidden.
								<br /> Click to show.{' '}
							</>
						)
					}
					disabled={false}
					icon="satellite"
					onClick={() => toggleSatelliteView()}
					type={''}
					className={mapViewType == SATELLITE_VIEW ? 'Icon_ShowAll-default' : 'Icon_ShowAll-danger'}
					theme={mapViewType != SATELLITE_VIEW ? 'dark' : 'light'}
				/>
			</div>

			<div className="Icon_ShowAll">
				<MapButton
					isActive={!triggerShowHide}
					title={
						!triggerShowHide ? (
							<>
								Icons and areas are shown. <br /> Click to hide.{' '}
							</>
						) : (
							<>
								Icons and areas are hidden. <br /> Click to show.{' '}
							</>
						)
					}
					disabled={false}
					icon={!triggerShowHide ? 'eye-open' : 'eye-off'}
					onClick={() => showHideAllHandler(!triggerShowHide)}
					type=""
					className={`eye-icon ${
						!triggerShowHide ? 'Icon_ShowAll-default' : 'Icon_ShowAll-danger'
					}`}
				/>
			</div>

			<div className="zoom-percentage">
				<Badge
					type="Gray"
					size="sm"
					text={`${Math.ceil((mapZoom * 100) / baseZoomLevel)}%`}
				/>
			</div>
		</div>
	);
};
