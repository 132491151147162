import styled from 'styled-components';
import { Button } from 'components/Button';

export const FormLabel = styled.p`
	margin-top: 8px;
	margin-bottom: 2px;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: #182026;
`;

export const RequiredWraper = styled.span`
	float: right;
	font-style: italic;
	font-size: 14px;
	color: #394B59;
`;

export const EllipsisWraper = styled.span`
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width:100%;
`;

export const ErrorTextWrapper = styled.p`
	&&& {
		color: #db3737;
		font-size: 12px;
		margin-top: 2px;
		line-height: 16px;
		font-style: normal;
		font-weight: normal;
		font-family: Nunito Sans;
		margin-bottom: 0px !important;
	}
`;

export const LabelText = styled.p`
	margin-top: 8px;
	margin-bottom: 2px;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: #182026;
`;

export const HelpText = styled.p`
	margin-bottom: 0px;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #5c7080;
`;

export const InputWrapper = styled.div`
	margin-bottom: ${props => (props.noMarginBottom ? '0px' : '16px')};

	&&& {
		input {
			border: 1px solid #d8e1e8;
			font-size: 16px;
			min-height: 40px;
			border-radius: 3px;
			overflow: hidden;
			box-shadow: none;
			text-overflow: ellipsis;
			white-space: nowrap;
			box-sizing: border-box;
		}

		input::placeholder{
			color:#738694;
		}

		.error input {
			border:none;
		}

		.bp3-intent-danger > input {
			border:1px solid #db3737;
			box-shadow: none;
		}
	}
`;

export const WiderButton = styled(Button)`
	min-width: 200px;
	display: flex;
	justify-content: space-between !important;
	background: #fff !important;
	white-space: nowrap;
`;

export const BackToTopButtonWrapper = styled.div`
    position: absolute;
    right: ${props => props.customRight ? props.customRight : 15 }px;
    bottom: ${props => props.customBottom ? props.customBottom : 15 }px;
    transition: bottom .5s;
    z-index: 10;
`;

export const BackToTopButtonContent = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    span {
        margin-left: 10px;
    }
`;

export const StyledRequiredField = styled.span`
	color: #E81717;
	padding: 4px;
`;
