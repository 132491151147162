import React from 'react';
import '../css/index.scss';

const BulkContactsHeaderHandler = ({ selectedSites, selectedCallList }) => {
	return (
		<>
			<p className="bulkContactHeaderText">
				<span className="bulkContactsHeader">To</span>
				<strong> {selectedCallList.label} </strong>{' '}
				<span className="bulkContactsHeader">in</span>{' '}
				<strong>{selectedCallList.site_name}</strong>
			</p>
		</>
	);
};

export default BulkContactsHeaderHandler;
