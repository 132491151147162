import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';
import './css/FieldCard.scss';
import { LoadingSpinner, Tooltip } from 'componentsV2';

export const FieldCard = ({
	header,
	description,
	children,
	loading = false,
	tooltip = null,
	className = '',
	disabled = false,
	rightActions = null,
	...restProps
}) => {
	return (
		<div
			className={`field-card-container ${className} ${disabled ? 'disabled' : ''}`}
			{...restProps}
		>
			{loading && <LoadingSpinner />}
			{!loading && (
				<>
					<div className="field-card-container-header-container">
						<div className="field-card-container-header-container-title-container">
							<div className="field-card-container-header-container-title-container-left-side">
								<h3
									className={`field-card-container-header-container-title-container-left-side-title ${
										disabled ? 'disabled' : ''
									}`}
								>
									{header}
								</h3>
								{tooltip && (
									<Tooltip tooltipTitle={tooltip} theme="dark">
										<Icon icon="help" color="#969CA0" />
									</Tooltip>
								)}
							</div>
							<div className="field-card-container-header-container-title-container-right-side">
								{rightActions && rightActions}
							</div>
						</div>
						{description && (
							<p className="field-card-container-header-container-description">
								{description}
							</p>
						)}
					</div>
					{children}
				</>
			)}
		</div>
	);
};

FieldCard.propTypes = {
	header: PropTypes.string.isRequired || PropTypes.element.isRequired,
	description: PropTypes.string || PropTypes.element,
	tooltip: PropTypes.string || PropTypes.element,
	rightActions: PropTypes.element,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
};
