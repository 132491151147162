import React from "react";
import { Avatar } from "./";
import sampleImage from './images/anonymousUserAvatar.jpg';
import styled from 'styled-components';

const Item = styled.div`
  float:left;
  margin-right:30px;
`

const Row = styled.div`
  margin-bottom:35px;
  &::after {
    content: "";
    clear: both;
    display: block;
  }
`

export const AvatarPattern = () => {
  const name = 'Youssef Bsheer';
  const supportingText='Supporting Text';
    return (
    <>
      <Row>
        <Item>
           <Avatar
             size={'xs'}
             image={sampleImage}
           />
        </Item>
        <Item>
          <Avatar
            size={'sm'}
            image={sampleImage}
          />
        </Item>
        <Item>
          <Avatar
            size={'md'}
            image={sampleImage}
          />
        </Item>
        <Item>
          <Avatar
            size={'lg'}
            image={sampleImage}
          />
        </Item>
        <Item>
          <Avatar
            size={'xl'}
            image={sampleImage}
          />
        </Item>
        <Item>
          <Avatar
            size={'2xl'}
            image={sampleImage}
          />
        </Item>
      </Row>
      <Row>
        <Item>
          <Avatar
            size={'xs'}
            name={name}
          />
        </Item>
        <Item>
          <Avatar
            size={'sm'}
            name={name}
          />
        </Item>
        <Item>
          <Avatar
            size={'md'}
            name={name}
          />
        </Item>
        <Item>
          <Avatar
            size={'lg'}
            name={name}
          />
        </Item>
        <Item>
          <Avatar
            size={'xl'}
            name={name}
          />
        </Item>
        <Item>
          <Avatar
            size={'2xl'}
            name={name}
          />
        </Item>
      </Row>
      <Row>
        <Item>
          <Avatar
            size={'sm'}
            image={sampleImage}
            label={name}
            supportingText={supportingText}
          />
        </Item>
        <Item>
          <Avatar
            size={'md'}
            image={sampleImage}
            label={name}
            supportingText={supportingText}
          />
        </Item>
        <Item>
          <Avatar
            size={'lg'}
            image={sampleImage}
            label={name}
            supportingText={supportingText}
          />
        </Item>
        <Item>
          <Avatar
            size={'xl'}
            image={sampleImage}
            label={name}
            supportingText={supportingText}
          />
        </Item>
    </Row>
</>)
}
