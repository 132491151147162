import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner, Animated, myClasses, noResult, EmptyState } from 'componentsV2';
import { RapidAlarmService } from 'services';
import { StaffTable } from '../../';
import { STAFF_LIST } from '../consts';
import { RollCallDetailsContext } from '../../../RollCallDetails';

export const Staff = ({ filterData, setFilterData, setDisableFilter }) => {
	const [loading, setLoading] = useState(true);
	const [staffList, setStaffList] = useState([]);
	const [firstRender, setFirstRender] = useState(true);
	const [emptyStateType, setEmptyStateType] = useState({
		noStaffAdded: false,
		noResultFound: false,
	});
	const [totalStaff, setTotalStaff] = useState(0);
	const { staffUpdateEvent } = useContext(RollCallDetailsContext);

	useEffect(() => {
		// Staff status updated
		if (staffUpdateEvent && staffUpdateEvent.name === 'alarmStaff:updated') {
			getStaffList(false);
		}
	}, [staffUpdateEvent]);

	const getStaffList = (showLoading = true) => {
		if (showLoading) {
			setLoading(true);
		}
		RapidAlarmService.getStaff(filterData)
			.then(response => {
				if (response.statusCode === 200) {
					setStaffList(response.data);
					setTotalStaff(response._metadata.totalRecords);
					let noRecords = response._metadata.totalRecords === 0;
					setEmptyStateType({
						noStaffAdded: firstRender && noRecords,
						noResultFound: !firstRender && noRecords,
					});
					setDisableFilter(firstRender && noRecords);
				}
			})
			.finally(() => {
				if (showLoading) {
					setLoading(false);
				}
				setFirstRender(false);
			});
	};

	useEffect(() => {
		getStaffList();
	}, [filterData]);

	const noResultFound = emptyStateType.noResultFound;
	const noStaffAdded = emptyStateType.noStaffAdded;

	return (
		<>
			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<Animated>
					{(noStaffAdded || noResultFound) && (
						<EmptyState
							header={noResultFound ? 'No results found' : "You don't have any staff"}
							description={
								noResultFound ? (
									<>
										Your search didn't match any staff.
										<br /> Please try again.
									</>
								) : null
							}
							icon={noResultFound ? noResult : myClasses}
							showCircles={noResultFound}
							style={{
								marginTop: 100,
							}}
						/>
					)}
					{!noStaffAdded && !noResultFound && (
						<StaffTable
							staffList={staffList}
							setStaffList={setStaffList}
							totalStaff={totalStaff}
							filterData={filterData}
							setFilterData={setFilterData}
							listType={STAFF_LIST}
						/>
					)}
				</Animated>
			)}
		</>
	);
};
