import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmationModal } from 'componentsV2';

export const DeleteDrillTypeModal = ({
	isOpen,
	onCloseModal,
	titleData = '',
	onClickDelete,
	titleText = '',
	subtitleText = '',
	buttonText = '',
}) => {
	const onHandleDelete = () => {
		return onClickDelete();
	};

	return (
		<ConfirmationModal
			isOpen={isOpen}
			title={titleText || `Are you sure you want to delete "${titleData}"?`}
			subtitle={subtitleText || "Once deleted, this cannot be recovered."}
			showCloseButton={false}
			icon={{ icon: 'trash', iconColor: 'error' }}
			onClose={onCloseModal}
			cancelBtn={{
				title: 'No, go back',
				onClick: onCloseModal,
			}}
			confirmBtn={{
				title: buttonText || `Yes, delete it`,
				onClick: onHandleDelete,
				type: 'primaryDanger',
			}}
		/>
	);
};

DeleteDrillTypeModal.propTypes = {
	isOpen: PropTypes.any,
	onClickDelete: PropTypes.func,
	onCloseModal: PropTypes.any,
	titleData: PropTypes.any,
};
