import React from 'react';
import parse from 'html-react-parser';
import { rightArrow } from 'assets/icons';

export const EventLogItemAction = ({
    layout,
    value,
    value_diff,
    title_diff,
}) => {


    return (
        <div className='item-action'>
            {
                layout === 'text_only' &&
                <span className='layout-text-only'>
                    {value}
                </span>
            }
            {
                layout === 'strikethrough' &&
                <span className='layout-strikethrough'>
                    {value}
                </span>
            }
            {
                layout === 'diff_line' &&
                (
                    <div className="layout-diff-line">
                        <span>{value}</span>
                        <img src={rightArrow} alt='Diff' />
                        <span>{value_diff}</span>
                    </div>
                )
            }
            {
                layout === 'diff_content' &&
                (
                    <div className="layout-diff-content">
                        <div className="content-wrapper">
                            <div className="title-diff-content">
                                <strong>{title_diff}</strong>
                                <span> (Before)</span>
                            </div>
                            <div className="content-box">
                                <div className="content-box-inner">
                                    {parse(value)}
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="title-diff-content">
                                <strong>{title_diff}</strong>
                                <span> (Updated)</span>
                            </div>
                            <div className="content-box">
                                <div className="content-box-inner">
                                    {parse(value_diff)}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
