import { ToasterContext, FeaturedIcon } from 'componentsV2';
import React, { useContext, useState } from 'react';
import { DELETE_MAP_MODAL, MAP_LIST_PAGE, DELETE_MAP_ACTION, MAP_AREAS } from '../../../consts';
import { Intent } from '@blueprintjs/core';
import { FloorplanService } from 'services/FloorplanService';
import { deleteAreaOnMap, deleteAllTextsOnMap } from '../../../Details/components/Functions';

export const DeleteMapModalFn = (
	modalName,
	setLoading,
	closeModal,
	setModalToShow,
	dispatchModalData,
	reloadList,
	reloadDetails,
	transformer,
	setRefreshTextList,
	currentFloorplan,
	toggleMapOption,
	setRefreshAreaList,
) => {
	const toaster = useContext(ToasterContext);
	const AVAILABLE_TO_USE = 'AvailableToUse';

	const showDeleteMapModal = (maps, activePage, hasChange = false, setHasChange) => {
		let confirmationMsg = `“${maps[0].title}”`;
		let buttonTitle = `it`;
		if (maps.length > 1) {
			confirmationMsg = `${maps.length} maps`;
			buttonTitle = `them`;
		}
		dispatchModalData({
			modalTitle: `Are you sure you want to delete ${confirmationMsg}?`,
			modalSubTitle: 'Once deleted, this cannot be recovered.',
			confirmBtnTitle: `Yes, delete ${buttonTitle}`,
			onConfirmAction: () => handleDeleteMAPSubmit(maps, activePage, hasChange, setHasChange),
		});
		setModalToShow(DELETE_MAP_MODAL);
	};
	const handleDeleteMAPSubmit = (mapsToBeDeleted, activePage, hasChange, setHasChange) => {
		setLoading(true);
		let confirmationMsg = `“${mapsToBeDeleted[0].title}”`;
		if (mapsToBeDeleted.length > 1) {
			confirmationMsg = `${mapsToBeDeleted.length} maps`;
		}
		const params = {
			floorplan_ids: mapsToBeDeleted.map(map => map.id),
		};
		FloorplanService.deleteMaps(params)
			.then(() => {
				toaster(
					`You have successfully deleted ${confirmationMsg}`,
					Intent.NONE,
					<FeaturedIcon icon='trash' size='md' type='Gray' shape={'circleIcon'} />,
					2000,
					false,
					'map-view-page',
				);
				closeModal();
				setLoading(false);
				if (hasChange) {
					setHasChange(false);
				}
			})
			.catch(error => {
				const msg =
					error.error.fields.floorplan_ids ||
					`Something went wrong, please try again later`;
				toaster(`${msg}`, Intent.DANGER, null, 2000, false, 'map-view-page');
				setLoading(false);
			})
			.finally(() => {
				if (activePage === MAP_LIST_PAGE) {
					reloadList();
				} else {
					reloadDetails(DELETE_MAP_ACTION);
				}
				setModalToShow(null);
				setLoading(false);
			});
	};

	const onDeleteShapeHandler = (floorplanId, shape, layer) => {
		setLoading(true);
		FloorplanService.deleteShape(floorplanId, shape.id)
			.then(response => {
				if (response.statusCode === 200) {
					const msg = `You have successfully deleted the area “${shape.title}”`;
					toaster(msg, Intent.NONE, <FeaturedIcon icon='trash' size='md' type='Gray' shape={'circleIcon'} />, 2000, false, 'map-view-page');
					toggleMapOption(MAP_AREAS);

					deleteAreaOnMap(shape.id, layer, transformer.current);
				} else {
					toaster(
						`Something went wrong, please try again later`,
						Intent.DANGER,
						null,
						2000,
						false,
						'map-view-page',
					);
				}
			})
			.catch(err => {
				toaster(
					`Something went wrong, please try again later`,
					Intent.DANGER,
					null,
					2000,
					false,
					'map-view-page',
				);
			})
			.finally(() => {
				setRefreshAreaList(oldVal => !oldVal);
				setModalToShow(null);
				setLoading(false);
			});
	};

	const deleteTexts = (shape, siteId, tabType, layer) => {
		const body = {};
		setLoading(true);
		let toasterSuccessMsg = '';
		body.text = shape.title;

		if (tabType == AVAILABLE_TO_USE) {
			toasterSuccessMsg =
				'You have successfully deleted ' + shape.title + ' from all map layers.';
		} else {
			body.floorplanId = currentFloorplan.id;
			toasterSuccessMsg =
				'You have successfully deleted ' + shape.title + ' from this map layer.';
		}

		FloorplanService.deleteTexts(siteId, body)
			.then(() => {
				toaster(toasterSuccessMsg, Intent.NONE, <FeaturedIcon icon='trash' size='md' type='Gray' shape={'circleIcon'} />, 2000, false, 'map-view-page');
				deleteAllTextsOnMap(shape.title, layer, transformer.current);
				setRefreshTextList(oldValue => !oldValue);
				closeModal();
			})
			.catch(error => {
				const msg = `Something went wrong, please try again later`;
				toaster(`${msg}`, Intent.DANGER, null, 2000, false, 'map-view-page');
			})
			.finally(() => {
				setModalToShow(null);
				setLoading(false);
			});
	};

	return {
		showDeleteMapModal,
		deleteTexts,
		onDeleteShapeHandler,
	};
};
