export const STEP_ONE = 'alarmTypeForm';
export const STEP_TWO = 'sitesSelectForm';
export const STEP_THREE = 'countDownForm';
export const STEP_FOUR = 'activeAlarmForm';
export const ALARM_SAFE_MODAL = 'alarmSafeModal';
export const ALARM_PRACTICE_MODAL = 'alarmPracticeModal';
export const NONE_STATUS = 'none';
export const DISABLED_STATUS = 'disabled';
export const SELECTED_STATUS = 'selected';
export const ALARM_STATUS = {
	NONE_STATUS,
	DISABLED_STATUS,
	SELECTED_STATUS,
};

export const AFTER_ACTIVATION_STATUSES = {
	OKAY: 'okay',
	NOT_OKAY: 'not_okay',
	NOT_HERE: 'not_here',
};

export const ALARM_MODES = {
	drill: 'DRILL',
	training: 'TRAINING',
	alarm: 'ALARM',
};
