import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup, Dropdown, MediaPlayer, Tooltip } from 'componentsV2';
import InputWrapper from 'componentsV2/InputWrapper/InputWrapper';
import { Radio } from '@blueprintjs/core';
import { bioSound, fireSound, intruderSound, medSound, weatherSound } from 'assets/sounds';
import './css/AlarmSound.scss';

export const AlarmSound = ({
	formData,
	formErrors,
	onChangeFormData,
	disabled = false,
	tooltipTitle = null,
}) => {
	const ALARM_SOUNDS = [
		{
			label: 'Bio',
			value: 'bio',
			url: bioSound,
		},
		{
			label: 'Fire',
			value: 'fire',
			url: fireSound,
		},
		{
			label: 'Intruder',
			value: 'intruder',
			url: intruderSound,
		},
		{
			label: 'Med',
			value: 'med',
			url: medSound,
		},
		{
			label: 'Weather',
			value: 'weather',
			url: weatherSound,
		},
	];

	const onToggleSoundHandler = e => {
		const value = JSON.parse(e.target.value);
		if (!value) {
			onChangeFormData({ type: 'sound', payload: null });
		}
		onChangeFormData({ type: 'soundEnabled', payload: value });
	};

	const onSelectSoundHandler = value => {
		onChangeFormData({ type: 'sound', payload: value.value });
	};

	const { soundEnabled } = formData;
	const selectedSound = formData.sound
		? ALARM_SOUNDS.find(alarmSound => alarmSound.value === formData.sound)
		: null;
	const hasError = formErrors?.sound ? true : false;

	const InputWrapperComponent = ({ children }) =>
		tooltipTitle ? <Tooltip tooltipTitle={tooltipTitle}>{children}</Tooltip> : <>{children}</>;

	return (
		<div className="create-alarm-alarm-sound-field">
			<div className="create-alarm-alarm-sound-field-sound-enabled">
				<InputWrapperComponent>
					<RadioButtonGroup
						label={null}
						onChange={onToggleSoundHandler}
						selectedValue={soundEnabled}
						size="small"
						bordered={false}
						required
						layout="inline"
						disabled={disabled}
					>
						<Radio label="Yes" value />
						<Radio label="No" value={false} />
					</RadioButtonGroup>
				</InputWrapperComponent>
			</div>
			<div className="create-alarm-alarm-sound-field-sound-preview-container">
				<div className="create-alarm-alarm-sound-field-sound-preview-container-select-sound w-100">
					<InputWrapperComponent>
						<Dropdown
							label="Alarm sound"
							labelState={soundEnabled}
							placeholder={soundEnabled ? 'Select a sound' : 'No sound'}
							isRequired={soundEnabled}
							disabled={!soundEnabled || disabled}
							options={ALARM_SOUNDS}
							value={selectedSound}
							onChange={onSelectSoundHandler}
							error={hasError}
							errorMessage={hasError ? formErrors.sound : null}
						/>
					</InputWrapperComponent>
				</div>
				<div className="create-alarm-alarm-sound-field-sound-preview-container-preview-sound">
					<InputWrapper label="Preview Sound" labelState={false} />
					{!selectedSound && tooltipTitle ? (
						<InputWrapperComponent>
							<MediaPlayer
								mediaUrl={selectedSound ? selectedSound.url : null}
								showAudioPlayer
							/>
						</InputWrapperComponent>
					) : (
						<MediaPlayer
							mediaUrl={selectedSound ? selectedSound.url : null}
							showAudioPlayer
						/>
					)}
				</div>
			</div>
		</div>
	);
};

AlarmSound.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
	disabled: PropTypes.bool,
};
