import React from "react";
import { Button } from "../";
import { ActionsButton } from "./";

export const ActionsButtonPattern = () => {
    return (
    <>
    <div style={{ marginLeft: '164px'}}>
        <ActionsButton>
            <>
            <Button
                large
                text="View"
                icon="eye-open"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
            />
            <Button
                large
                text="Edit"
                icon="edit"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
            />
            <Button
                large
                text="Delete"
                icon="trash"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
            />
            
        </>
        </ActionsButton>
    </div>
</>)
}