import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'components/Dropdown';
import { SchoolYearsService } from 'services';
import { setLastSelectedSchoolYear } from '../../routeReducer';

const SchoolYearDropdown = ({ selectedSchoolYear = null, onChange, placeholder }) => {
	const dispatch = useDispatch();
	const previousStoredSelection = useSelector(state => state.route.lastSelectedSchoolYear);
	const [years, setYears] = useState(null);

	useEffect(() => {
		SchoolYearsService.fetchYears().then(backendYears => setYears(backendYears));
		if (previousStoredSelection) {
			onChange(previousStoredSelection);
		}
	}, []);

	return (
		<Dropdown
			label="School year"
			placeholder={placeholder ? placeholder : 'Select a school year'}
			// value={selectedSchoolYear || previousStoredSelection}
			value={selectedSchoolYear}
			options={years}
			onChange={selectedYear => {
				dispatch(setLastSelectedSchoolYear(selectedYear));
				if (onChange) {
					onChange(selectedYear);
				}
			}}
		/>
	);
};

SchoolYearDropdown.propTypes = {
	selectedSchoolYear: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};

export { SchoolYearDropdown };
