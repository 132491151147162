import React from 'react';
import { PageHeader } from 'componentsV2';

export const ViewAlarmTypeHeader = () => {
	const breadcrumbItems = [
		{
			label: 'Rapid alarm',
			url: '/rapid-alarms',
		},
		{
			label: 'Settings',
			url: '/rapid-alarms/settings',
		},
		{
			label: 'Alarm types',
			url: '/rapid-alarms/settings',
		},
		{
			label: 'View or edit alarm details',
		},
	];

	return (
		<PageHeader
			title="View or edit alarm"
			subtitle="View or edit alarm details."
			breadcrumbItems={breadcrumbItems}
		/>
	);
};
