import { SORTING_DIR } from './constants';

export const sortStringInObject = (a, b, order, keyToSort) => {
	const nameA = a[keyToSort];
	const nameB = b[keyToSort];

	if (nameA && nameB) {
		if (order === SORTING_DIR.ASC) {
			return nameA.localeCompare(nameB);
		}
		return nameB.localeCompare(nameA);
	}
	return null;
};

export const sortDatesObject = (a, b, order, keyToSort) => {
	const dateA = a[keyToSort] ? Date.parse(a[keyToSort]) : 0;
	const dateB = b[keyToSort] ? Date.parse(b[keyToSort]) : 0;
	if (order === SORTING_DIR.ASC) {
		return dateA - dateB;
	}
	return dateB - dateA;
};
