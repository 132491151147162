import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Icon } from '@blueprintjs/core';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/LoadingSpinner';
import './styles.css';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: end;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	font-weight: bold;
	color: #10161a;
	font-size: 18px;
`;

const HR = styled.hr`
	border: 0;
	margin: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const ActionButtonsWrap = styled.div`
	display: flex;
	margin-top: 0.5rem;

	.third-button-wrap {
		flex: 2 1 auto;
		padding: 0.5rem;
	}

	.action-buttons-wrap {
		flex: 2 1 auto;
		text-align: right;
		padding: 0.5rem;

		button {
			margin-left: 0.5rem;
		}
	}
`;

const NormalModal = ({
	onConfirmAction,
	onCancelAction,
	header,
	children,
	confirmBtnTitle,
	cancelBtnTitle,
	confirmBtnDisabled,
	loading,
	className,
	thirdButtonTitle,
	thirdButtonIcon,
	thirdButtonAction,
	onCloseAction,
	thirdBtnDisabled,
	showBackArrow = false,
	backArrowAction,
	showConfirmBtn = true,
	danger,
}) => {
	return (
		<Dialog
			isOpen={true}
			onClose={onCloseAction !== undefined ? onCloseAction : onCancelAction}
			className={className}
		>
			{loading && <LoadingSpinner />}
			{!loading && (
				<div className="container">
					<TitleWrap>
						<Title>
							{showBackArrow && (
								<a onClick={backArrowAction}>
									<Icon className="normal-modal-back-arrow" icon="arrow-left" />
								</a>
							)}{' '}
							{header}
						</Title>
						<Button
							minimal
							icon="cross"
							onClick={onCloseAction !== undefined ? onCloseAction : onCancelAction}
						/>
					</TitleWrap>
					<HR className="mb-2" />
					{children}
					<HR className="modal-footer-actions" />
					<ActionButtonsWrap>
						{thirdButtonTitle !== undefined && (
							<div className="third-button-wrap">
								<Button
									intent="secondary"
									icon={thirdButtonIcon}
									text={thirdButtonTitle}
									onClick={thirdButtonAction}
									disabled={thirdBtnDisabled}
									minimal={true}
									large
								/>
							</div>
						)}
						<div className="action-buttons-wrap">
							<Button
								onClick={onCancelAction}
								text={cancelBtnTitle}
								intent={danger ? 'secondaryDanger' : 'secondary'}
								large
							/>
							{showConfirmBtn && (
								<Button
									onClick={onConfirmAction}
									text={confirmBtnTitle}
									intent={danger ? 'primaryDanger' : 'primary'}
									disabled={confirmBtnDisabled}
									large
								/>
							)}
						</div>
					</ActionButtonsWrap>
				</div>
			)}
		</Dialog>
	);
};

NormalModal.propTypes = {
	onConfirmAction: PropTypes.func,
	showConfirmBtn: PropTypes.bool,
};

export default NormalModal;
