import React, { useContext, useEffect, useState } from 'react';
import { formatDate } from 'utilities/dates';
import {
	ContentSwitcher,
	ToggleButton,
	Button,
	LoadingModal,
	ToasterContext,
	Intent,
	Tooltip,
} from 'componentsV2';
import { AssessmentService } from 'services/AssessmentService';
import { format } from 'date-fns';

export const AssessmentHeader = ({ assessmentId, section, onSetIsHidden, onFilter, viewOnly }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isHidden, setIsHidden] = useState(section.isHidden);
	const toaster = useContext(ToasterContext);
	const [sectionFilter, setSectionFilter] = useState('all');

	useEffect(() => {
		// For an unknown reason, the isHidden state variable does not update when the section is switched
		setIsHidden(section.isHidden);
	}, [section]);

	const contentSwitcherTabs = [
		{ id: 'all', label: 'View All' },
		{ id: 'answered', label: 'Answered' },
		{ id: 'unanswered', label: 'Unanswered' },
	];

	const handleDownloadSection = async () => {
		setIsLoading(true);
		const blob = await AssessmentService.downloadSection(assessmentId, section.id);
		const filename = `${section.name} ${format(new Date(), 'hh-mm-ss a')}`;
		const data = new Blob([blob], { type: 'application/pdf' });
		const csvURL = window.URL.createObjectURL(data);
		const tempLink = document.createElement('a');
		tempLink.href = csvURL;
		tempLink.setAttribute('download', `${filename}.pdf`);
		tempLink.click();
		setIsLoading(false);
	};

	const handleToggleVisibility = async isHiddenValueOnToggle => {
		try {
			setIsHidden(!isHiddenValueOnToggle);
			const res = await AssessmentService.setSectionVisibility(
				assessmentId,
				section.id,
				!isHiddenValueOnToggle,
			);
			if (res.data.isHidden === !isHiddenValueOnToggle) {
				isHiddenValueOnToggle
					? toaster(`You have made visible the section "${section.name}"`, Intent.WARNING)
					: toaster(`You have hidden the section "${section.name}"`, Intent.WARNING);
				onSetIsHidden(section.id, !isHiddenValueOnToggle);
			} else throw error;
		} catch (error) {
			setIsHidden(isHiddenValueOnToggle);
			toaster(
				`There was an error setting the visibility for section "${section.name}"`,
				Intent.DANGER,
			);
		}
	};

	const handleFilter = value => {
		setSectionFilter(value);
		onFilter(section.id, value[0]);
	};

	return (
		<>
			<div className="questions-header">
				<div className="title">
					<h3>{section.name}</h3>
					<span className="last-updated">{`Last updated: ${
						section.mostRecentUpdate
							? formatDate(section.mostRecentUpdate.date)
							: 'No saves'
					}`}</span>
					<div className="switcher">
						<ContentSwitcher
							onClick={handleFilter}
							activeTab={sectionFilter}
							defaultActiveTabs={['all']}
							tabs={contentSwitcherTabs}
						/>
					</div>
				</div>
				<div className="buttons">
					<Tooltip tooltipContent="When toggled off, this section will be disabled and will not be included in the pdf download.">
						<ToggleButton
							checked={!isHidden}
							label="Visibility"
							large={false}
							onChange={() => handleToggleVisibility(isHidden)}
							disabled={viewOnly}
						/>
					</Tooltip>
					<Button
						type="primaryDefault"
						size="sm"
						icon="cloud-download"
						text="Download"
						onClick={handleDownloadSection}
						disabled={isHidden}
					/>
				</div>
			</div>
			<LoadingModal isOpen={isLoading} loading={true} />
		</>
	);
};
