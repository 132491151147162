import React from 'react';
import { PageHeader } from 'componentsV2';
import PropTypes from 'prop-types';

export const Header = ({ openedFromHomePage }) => {
	let breadcrumbItems = [
		{
			label: 'Rapid alarms',
			url: '/rapid-alarms',
		},
		{
			label: 'Alarms',
			url: '/rapid-alarms',
		},
		{
			label: 'Alarm details',
		},
	];

	if (openedFromHomePage) {
		breadcrumbItems = [
			{
				label: 'Home',
				url: '/',
			},
			{
				label: 'Alarm details',
			},
		];
	}

	return (
		<PageHeader
			title="Alarm details"
			subtitle="Detailed information on a specific active alarm."
			breadcrumbItems={breadcrumbItems}
		/>
	);
};

Header.propTypes = {
	openedFromHomePage: PropTypes.bool,
};
