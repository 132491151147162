import React from 'react';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchBar } from 'components/SearchBar';
import '../css/BindersFilters.scss';
import { ContentSwitcher } from 'componentsV2';

export const BindersFilters = ({
	defaultSelectedSites,
	setFilterationData,
	filterationData,
	setContentLoading,
	setSelectedTab,
}) => {
	const [selectedSites, setSelectedSites] = useState([]);

	const BinderFilterTabs = [
		{ id: '1', label: 'View all' },
		{ id: '2', label: 'Unarchived' },
		{ id: '3', label: 'Archived' },
	];
	const onSearchHandler = name => {
		setFilterationData(oldData => ({
			...oldData,
			name: name,
			page: 1,
		}));
	};

	const onChangeSiteHandler = sites => {
		setFilterationData(oldData => ({
			...oldData,
			buildingIds: sites.map(site => site.value),
			page: 1,
		}));
		setSelectedSites(sites);
	};

	const onClickHandler = tab => {
		if (tab && tab[0]) {
			setContentLoading(true);
			if (tab[0] == '1') {
				setFilterationData(oldData => ({
					...oldData,
					archivedOnly: false,
					page: 1,
				}));
			} else if (tab[0] == '2') {
				setFilterationData(oldData => ({
					...oldData,
					archivedOnly: 'false',
					page: 1,
				}));
			} else if (tab[0] == '3') {
				setFilterationData(oldData => ({
					...oldData,
					archivedOnly: true,
					page: 1,
				}));
			}
			setSelectedTab(tab[0]);
		}
	};

	return (
		<section className="binder-filteraion">
			<div className=" left-sideContentSwitcherPattern__Container">
				<ContentSwitcher
					onClick={onClickHandler}
					tabs={BinderFilterTabs}
					multi={false}
					defaultActiveTabs={['1']}
					preserveTab={true}
				/>
			</div>
			<div className="right-side">
				<SearchBar
					placeholder="Search by name..."
					icon="search"
					className="search-bar-text"
					value={filterationData.name}
					onSearch={onSearchHandler}
				/>
				<SitesDropdownMultiSelect
					label={null}
					value={selectedSites}
					placeholder="Select a Site"
					onChange={onChangeSiteHandler}
					visibleOptions={1}
					labelState={false}
					className="sites-mddl"
					allowSelectAll={false}
				/>
			</div>
		</section>
	);
};

BindersFilters.propTypes = {
	defaultSelectedSites: PropTypes.array,
	setFilterationData: PropTypes.func.isRequired,
	filterationData: PropTypes.object,
};
