import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dialog, Classes, Checkbox } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const HrWrapper = styled.hr`
	widht: 90%;
`;

const CreateDialog = styled(Dialog)`
	width: 300px;
`;

const DialogFooterWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 10px;
`;

const FormLabel = styled.p`
	margin-top: 8px !important;
	margin-bottom: 2px;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: #182026;
`;

class FilterModal extends React.Component {
	state = { name: '', instructions: '', loading: false };

	updateFields = (key, val) => this.setState({ [key]: val });

	render() {
		const {
			closeFilter,
			answerTypes,
			sections,
			setFilterSections,
			filterTypesArray,
			setFilterTypes,
			filterCompleted,
			setFilterCompleted,
			filterInProgress,
			setFilterInProgress,
			filterThumbsUp,
			filterThumbsDown,
			setThumbs,
			applyFilters,
		} = this.props;
		const { loading } = this.state;
		return (
			<CreateDialog
				isOpen
				onClose={closeFilter}
				title="More Filters"
				{...this.state}
			>
				<div className={Classes.DIALOG_BODY}>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<FormLabel>School</FormLabel>
							<Select
								defaultValue={null}
								className="multi-select"
								closeMenuOnSelect={false}
								components={animatedComponents}
								isMulti
								options={sections}
								onChange={selected =>
									setFilterSections(selected)
								}
							/>
							<FormLabel>Answer Type</FormLabel>
							{answerTypes.map((item, index) => (
								<Checkbox
									key={`answertype-${index}`}
									label={item.label}
									onClick={() => setFilterTypes(item)}
									checked={filterTypesArray.includes(
										item.value,
									)}
								/>
							))}
							<FormLabel>Status</FormLabel>
							<Checkbox
								label="Completed"
								onClick={setFilterCompleted}
								checked={filterCompleted}
							/>
							<Checkbox
								label="In-progress"
								onClick={setFilterInProgress}
								checked={filterInProgress}
							/>

							<FormLabel>
								Recommendation or Commendation
							</FormLabel>
							<Checkbox
								label="Thumbs up"
								onClick={() => setThumbs('up')}
								checked={filterThumbsUp}
							/>
							<Checkbox
								label="Thumbs down"
								onClick={() => setThumbs('down')}
								checked={filterThumbsDown}
							/>
							<HrWrapper />
						</>
					)}
				</div>
				<DialogFooterWrapper>
					<Button
						style={{
							marginRight: 16,
							height: 40,
							width: 68,
						}}
						onClick={closeFilter}
						text="Cancel"
						intent="none"
					/>
					<Button
						style={{
							height: 40,
							width: 68,
							background: '#9F1C3A',
						}}
						onClick={applyFilters}
						text="Apply"
						intent="danger"
					/>
				</DialogFooterWrapper>
			</CreateDialog>
		);
	}
}

FilterModal.propTypes = {
	closeFilter: PropTypes.func,
	answerTypes: PropTypes.array,
	sections: PropTypes.array,
	setFilterSections: PropTypes.func,
	filterBuildings: PropTypes.array,
	filterTypesArray: PropTypes.array,
	setFilterTypes: PropTypes.func,
	filterCompleted: PropTypes.bool,
	setFilterCompleted: PropTypes.func,
	filterInProgress: PropTypes.bool,
	filterThumbsDown: PropTypes.bool,
	filterThumbsUp: PropTypes.bool,
	setFilterInProgress: PropTypes.func,
	setThumbs: PropTypes.func,
	applyFilters: PropTypes.func,
};

export default FilterModal;
