import React from 'react';

const getFields = (content, values) => {
	const testVar = /(?<=\[\[).+?(?=\]\])/g;
	if (content) {
		const sectionVariables = content.match(testVar);
		if (sectionVariables && sectionVariables.length > 0) {
			let filledOut = 0;
			const stringValues = JSON.stringify(values);
			sectionVariables.forEach(item => {
				if (stringValues.includes(`[[${item}]]`)) {
					filledOut += 1;
				}
			});
			return (
				<>
					{filledOut} out of {sectionVariables.length} variables complete
				</>
			);
		}
	}
	return null;
};

export { getFields };
