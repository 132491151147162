import React from 'react'
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';
import { Modal, ModalFooter } from '../';
import { Button } from '../../';

export const NormalModal = ({
    isOpen = true,          // NEW prop optional
    size,                   // NEW prop optional
    title,                  // NEW prop optional
    subtitle,               // NEW prop optional
    showCloseButton = true, // NEW prop optional
    icon,                   // NEW prop optional
    iconColor,              // NEW prop optional

    showConfirmBtn = true,  // existing prop
    onConfirmAction,        // existing prop
    confirmBtnTitle,        // existing prop
    confirmBtnDisabled,     // existing prop
    confirmBtnType = "primaryDanger",   // NEW prop
    confirmBtnIcon,         // NEW prop
    confirmBtnSize = "md",  // NEW prop

    onCancelAction,         // existing prop
    cancelBtnTitle,         // existing prop
    cancelBtnType = "secondaryDefault", // NEW prop
    cancelBtnSize = "md",   // NEW prop

    thirdButtonTitle,       // existing prop
    thirdButtonIcon,        // existing prop
    thirdButtonAction,      // existing prop
    thirdBtnDisabled,       // existing prop
    thirdBtnType = "secondaryDefault", // NEW prop
    thirdBtnSize = "md",   // NEW prop

    showBackArrow = false,  // existing prop
    backArrowAction,        // existing prop

    header,                 // existing prop (used as title prop)              
    children,               // existing prop

    loading,                // existing prop
    onCloseAction,          // existing prop
    className = '',         // existing prop
}) => {
    const confirmButtonElement = (
        showConfirmBtn
            ? <Button
                text={confirmBtnTitle}
                icon={confirmBtnIcon}
                type={confirmBtnType}
                size={confirmBtnSize}
                onClick={onConfirmAction}
                disabled={confirmBtnDisabled}
            />
            : null
    );

    const cancelButtonElement = (
        cancelBtnTitle && onCancelAction
            ? <Button
                text={cancelBtnTitle}
                type={cancelBtnType}
                size={cancelBtnSize}
                onClick={onCancelAction}
            />
            : null
    );

    const thirdButtonElement = (
        thirdButtonTitle && thirdButtonAction
            ? <Button
                text={thirdButtonTitle}
                icon={thirdButtonIcon}
                type={thirdBtnType}
                size={thirdBtnSize}
                onClick={thirdButtonAction}
                disabled={thirdBtnDisabled}
            />
            : null
    );

    const headerTitle = (
        <>
            {showBackArrow && (
                <a onClick={backArrowAction}>
                    <Icon className="normal-modal-back-arrow" icon="arrow-left" />
                </a>
            )}
            {header ? header : title}
        </>
    );

    return (
        <Modal
            isOpen={isOpen}
            onClose={(onCloseAction !== undefined) ? onCloseAction : onCancelAction}
            size={size}
            title={headerTitle}
            subtitle={subtitle}
            showCloseButton={showCloseButton}
            icon={icon}
            iconColor={iconColor}
            loading={loading}
            customClassName={className}
            customFooter={
                <ModalFooter
                    confirmButton={confirmButtonElement}
                    cancelButton={cancelButtonElement}
                    leftContent={thirdButtonElement}
                />
            }
        >
            {children}
        </Modal>
    )
}

NormalModal.propTypes = {
    header: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    subtitle: PropTypes.string,
    size: PropTypes.string,
    showCloseButton: PropTypes.bool,
    icon: PropTypes.string,
    iconColor: PropTypes.string,

    showConfirmBtn: PropTypes.bool,
    onConfirmAction: PropTypes.func,
    confirmBtnTitl: PropTypes.string,
    confirmBtnDisabled: PropTypes.bool,
    confirmBtnType: PropTypes.string,
    confirmBtnIcon: PropTypes.string,
    confirmBtnSize: PropTypes.string,

    onCancelAction: PropTypes.func,
    cancelBtnTitle: PropTypes.string,
    cancelBtnType: PropTypes.string,
    cancelBtnSize: PropTypes.string,

    thirdButtonTitle: PropTypes.string,
    thirdButtonIcon: PropTypes.string,
    thirdButtonAction: PropTypes.func,
    thirdBtnDisabled: PropTypes.bool,
    thirdBtnType: PropTypes.string,
    thirdBtnSize: PropTypes.string,

    showBackArrow: PropTypes.bool,
    backArrowAction: PropTypes.func,

    children: PropTypes.element,

    loading: PropTypes.bool,
    onCloseAction: PropTypes.func,
}