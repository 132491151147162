import React from "react";
import './CardView.css';
import PropTypes from 'prop-types';

const CardView = ({
  thumbnail,
  children,
  item,
  actionButton,
  imgClass = ''
  
}) => {
  return (
        <div key={item.id} className="grid-item">
          {/* Render grid item content */}
          <div className="card-thumbnail">
            {
             <img        
             src={thumbnail} className={imgClass}
             /> }
          </div>
          <div className="item-details">
            {children}
          </div>
          <span className="fade-in-button  middle-right">
              {actionButton}
          </span>
        </div>
  );
};

CardView.propTypes = {
    thumbnail: PropTypes.string,
    children: PropTypes.any,
    item: PropTypes.object.isRequired,
};

export default CardView;
