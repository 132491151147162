import React from 'react';
import { Modal } from '../';
import { LoadingSpinner } from '../../';

import PropTypes from 'prop-types';

export const LoadingModal = ({
	isOpen = false,
	loading = false,
	onClose = () => {},
	customClassName = '',
	text = 'Downloading...',
	...props
}) => {
	return (
		<Modal
			isOpen={isOpen}
			customClassName={`LoadingModal__Container ${customClassName}`}
			onClose={onClose}
			{...props}
		>
			{loading && (
				<>
					<div className="LoadingModal__SpinnerContainer">
						<LoadingSpinner />
					</div>
					{text && <div className="LoadingModal__TextContainer">{text}</div>}
				</>
			)}
		</Modal>
	);
};

LoadingModal.propTypes = {
	text: PropTypes.string,
	isOpen: PropTypes.bool,
	loading: PropTypes.bool,
	customClassName: PropTypes.string,
	onCloseAction: PropTypes.func,
};
