import React from "react";
import { SettingsCard } from "./SettingsCard";
import styled from 'styled-components';

const Page = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const lockCompletedTemplates = () => {
}

const forceTemplateCompletion = () => {
}

const settings = [
    {
        title: 'Lock Completed Assessments',
        description: 'Completed assessments will be unchangeable',
        action: lockCompletedTemplates
    },
    {
        title: 'Force Assessment Completion',
        description: 'Exiting an assessment will automatically mark it as complete',
        action: forceTemplateCompletion
    },
]

const Settings = () => {
    return(
        <Page>
            {settings.map(setting => {
                return (
                    <SettingsCard
                        title={setting.title}
                        description={setting.description}
                        onChange={ () => setting.action() }
                    />
                )
            })}
        </Page>
    )
}

export { Settings }