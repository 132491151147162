import React, { useState } from 'react';
import { TinymceEditor, FormModal } from 'componentsV2';

export const AddSummaryModal = ({
	modalTitle,
	handleCloseModal,
	isOpen,
	summaryData,
	submitSummary,
	modalSubTitle = '',
	confirmBtnText,
}) => {
	const [summary, setSummary] = useState(summaryData || '');

	const onSubmitSummary = () => {
		return submitSummary(summary);
	};
	return (
		<FormModal
			isOpen={isOpen}
			title={modalTitle}
			subtitle={modalSubTitle}
			showCloseButton={false}
			onClose={handleCloseModal}
			cancelBtn={{ title: 'Cancel', onClick: handleCloseModal }}
			confirmBtn={{
				title: `${confirmBtnText || 'Save'} `,
				onClick: onSubmitSummary,
				type: 'primaryDefault',
				disabled: !summary,
			}}
		>
			<TinymceEditor
				label="Summary"
				isRequired
				value={summary}
				placeholder="Enter a summary"
				onChange={() => {}}
				onEditorChange={content => setSummary(content)}
				setupProps={() => {}}
			/>
		</FormModal>
	);
};
