import React from 'react';
import { useEventLogs } from 'hooks';
import { Modal, Button, Badge } from 'componentsV2';
import { EventLogItemAction } from './EventLogItemAction';

export const EventLogDiffModal = ({
  isOpen,
  onClose,
  item,
}) => {

  const {
    userName,
    itemName,
    description,
    dateTime,
    value,
    value_diff,
    title_diff,
    layout,
  } = useEventLogs(item);

  const modalTitle = (
    <div className='diff-modal-title'>
      <div>
        {`${userName} ${description} ${itemName} `}
        <Badge
          type="Gray"
          size="sm"
          text={dateTime}
          hasLeadIcon={false}
          hasCloseIcon={false}
        />
      </div>
      <Button
        disabled={false}
        text="Close"
        type="primaryDefault"
        intent="default"
        size="sm"
        onClick={() => onClose(false)}
      />
    </div>
  )

  return (

    item ?
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="large"
        scrollContent={false}
        title={modalTitle}
        showCloseButton={false}
      >
        <div className='item-details-wrapper'>
          <EventLogItemAction
            layout={layout}
            value={value}
            value_diff={value_diff}
            title_diff={title_diff}
          />
        </div>
      </Modal>
      : <></>
  )
}
