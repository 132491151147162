import React, { useContext } from 'react';
import { ConfirmationModal } from 'componentsV2';
import { CreateAlarmTypeContext } from '../provider';

export const CancelChangesModal = () => {
	const { closeModal, history, locationPath, updateFormChange, setLocationPath } = useContext(
		CreateAlarmTypeContext,
	);

	const onConfirmAction = () => {
		updateFormChange(false);
		setLocationPath('');
		return history.push(locationPath);
	};

	const onCancelAction = () => {
		setLocationPath('');
		closeModal();
	};

	return (
		<ConfirmationModal
			isOpen={true}
			title="Cancel changes?"
			subtitle="Are you sure you want to cancel your changes?"
			confirmBtn={{
				title: 'Cancel changes',
				onClick: onConfirmAction,
			}}
			cancelBtn={{
				title: 'Continue editing',
				onClick: onCancelAction,
			}}
			icon={{
				icon: 'warning-sign',
				iconColor: 'warning',
			}}
		/>
	);
};
