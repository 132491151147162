import { createSelector } from 'reselect';

const selectRouteDomain = state => state.route;

const selectRoute = () =>
	createSelector(
		selectRouteDomain,
		store => store,
	);

export default selectRoute;
