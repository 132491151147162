import React , {useContext, useReducer, useState} from 'react';
import { Button } from "components/Button";
import NormalModal from "components/Modals/NormalModal";
import { tickIconChecked, tickIconNotChecked } from "assets/icons";
import Password from "components/Password";
import { ToasterContext } from "pages/App";
import { Intent } from '@blueprintjs/core';
import './PasswordOptions/index.css';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';

const passwordHintsReducer = (passwordHints, action) => {
    switch (action.type) {
        case 'password':
            // at least has one number
            if (/\d/.test(action.payload)){
                passwordHints.oneNumber = true;
            } else {
                passwordHints.oneNumber = false;
            }

            // at least has one character
            if (/[!@#$%^&*~_-]/.test(action.payload)) {
                passwordHints.oneCharacter = true;
            } else {
                passwordHints.oneCharacter = false;
            }

            // greater than 8
            if (action.payload && action.payload.length >= 8 ) {
                passwordHints.eightCharaters = true;
            } else {
                passwordHints.eightCharaters = false;
            }

            passwordHints.passwordValue = action.payload;
        break;
        case 'passwordConfirmation':
            if(action.payload != "") {
                passwordHints.passwordConfirmationHasValue = true;
            } else {
                passwordHints.passwordConfirmationHasValue = false;
            }

            if(action.payload === passwordHints.passwordValue) {
                passwordHints.passwordMatches = true;
            } else {
                passwordHints.passwordMatches = false;
            }
        break;
        default:
            passwordHints.oneCharacter = false;
            passwordHints.oneNumber = false;
            passwordHints.eightCharaters = false;
            passwordHints.passwordValue = false;
            passwordHints.passwordMatches = true;
            passwordHints.passwordConfirmationHasValue = false;
        break;
    }

    return {
        oneCharacter: passwordHints.oneCharacter,
        oneNumber: passwordHints.oneNumber,
        eightCharaters: passwordHints.eightCharaters,
        passwordValue: passwordHints.passwordValue,
        passwordMatches: passwordHints.passwordMatches,
        passwordConfirmationHasValue: passwordHints.passwordConfirmationHasValue,
    }
}

export default function ModulePasswordOptions (
    {user, hasUserEditPermission, isMyAccount, isSuperAdmin}
) 
{
    const [modalToShow, setModalToShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passwordHints, dispatchPasswordHints] = useReducer(passwordHintsReducer, {
        oneCharacter: false,
        oneNumber: false,
        eightCharaters: false,
        passwordValue : false,
        passwordMatches: true,
        passwordConfirmationHasValue: false,
    })
    const toaster = useContext(ToasterContext);
    const [cancelationModal, setCancelationModal] = useState(false);

    //TODO userId
    const userId = user ? user.id : null;
    const url = process.env.API_URL;
	const token = JSON.parse(localStorage.getItem('user')).jwt;
    

    const ChangePasswordHandler = () => {
        setLoading(true)
        fetch(`${url}/users/${userId}/password`, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${token}`,
			},
            body: JSON.stringify({
                password: passwordHints.passwordValue,
                passwordConfirmation: passwordHints.passwordValue,
            })
		}).then(res => res.json())
		.then(resp => {
            setLoading(false)
            if(resp.statusCode == 202) {
                toaster(resp.data, Intent.SUCCESS);
            } else {
                toaster(resp.error.description, Intent.DANGER);
            }
        }).then(()=> {
            dispatchPasswordHints("");
            closeChangePassword()
        })
    }

    const closeChangePassword = () => {
        if(passwordHints.passwordValue != "") {
            setCancelationModal(true)
        } else {
            setModalToShow(false)
        }
    }

    const welcomeEmailHandler = () => {
        fetch(`${url}/users/${userId}/welcome-email`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
        .then(res => res.json())
		.then(resp => {
            if(resp.statusCode == 200) {
                toaster(resp.data, Intent.SUCCESS);
            } else {
                toaster(resp.error.description, Intent.DANGER);
            }
        })
    }

    const resetPasswordHandler = () => {
        fetch(`${url}/users/${userId}/reset-password-email`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
        .then(res => res.json())
		.then(resp => {
            if(resp.statusCode == 200) {
                toaster(resp.data, Intent.SUCCESS);
            } else {
                toaster(resp.error.description, Intent.DANGER);
            }
        })
    }

    const confirmCancelationModal = () => {
        setCancelationModal(false)
        dispatchPasswordHints("");
        setModalToShow(false)
    }

    return (
        <>
            <div className='module-password'>

                <h4>Password Options</h4>

                <h5>Change Pasword</h5>

                <p>
                    Manually set a new password for the user
                </p>

                <Button
                    onClick={() => setModalToShow('ChangePasswordModal')}
                    text="Change Password"
                    intent="secondary"
                ></Button>

                {(hasUserEditPermission || isSuperAdmin) && (
                    <>
                        <h5>Password Reset Email</h5>

                        <p>
                            An email link will be sent to the user prompting them to change their password
                        </p>
        
                        <Button
                            onClick={resetPasswordHandler}
                            text="Send Password Reset Email"
                            intent="secondary"
                        ></Button>
        
                        <h5>Welcome Email</h5>
        
                        <p>
                            A welcome email with password instructions will be sent to the user
                        </p>
        
                        <Button
                            onClick={welcomeEmailHandler}
                            text="Send Welcome Email"
                            intent="secondary"
                        ></Button>
                    </>
                )}
               
            </div>

            {modalToShow === 'ChangePasswordModal' && ( 
            <NormalModal
                setModalToShow="ChangePasswordModal"
                onConfirmAction={ChangePasswordHandler}
                onCancelAction={closeChangePassword}
                header="Change Password?"
                confirmBtnTitle="Save"
                cancelBtnTitle="Cancel"
                confirmBtnDisabled={passwordHints.eightCharaters
                    && passwordHints.oneCharacter
                    && passwordHints.oneNumber 
                    && passwordHints.passwordMatches 
                    && passwordHints.passwordConfirmationHasValue ? false : true}
                loading={loading}
            >
                <div className="password-input">
                    <Password 
                        label="New Password"
                        placeHolder="Enter your new password"
                        onChangeHandler={e => dispatchPasswordHints({'type' : 'password','payload' : e.target.value})}
                        isRequired
                    />
                    <Password 
                        label="Retype New Password"
                        placeHolder="Retype your new password"
                        onChangeHandler={e => dispatchPasswordHints({'type' : 'passwordConfirmation','payload' : e.target.value})}
                        isRequired
                        error={passwordHints.passwordMatches ? "" : "Passwords does not match"}
                        errorMessage={passwordHints.passwordMatches ? "" : "Passwords does not match"}
                    />
                </div>
                <div className="mt-3 password-hints">
                    <img src={passwordHints.eightCharaters ? tickIconChecked : tickIconNotChecked} />
                    <label>Has at least 8 characters</label>
                </div>
                <div className="password-hints">
                    <img src={passwordHints.oneCharacter ? tickIconChecked : tickIconNotChecked} />
                    <label>Uses 1 special character</label>
                </div>
                <div className="password-hints mb-4">
                    <img src={passwordHints.oneNumber ? tickIconChecked : tickIconNotChecked} />
                    <label>Uses at least 1 number</label>
                </div>
            </NormalModal>
            )}
            {cancelationModal && ( 
                <UnsavedChangesModal
                    onConfirmAction={confirmCancelationModal}
                    onCancelAction={() => setCancelationModal(false)}
                />
            )}
        </>
    );
}
