import React from 'react'
import { FileIcon, fileIconsList } from './'

export const FileIconPattern = () => {

    return (
        <>
            <h4>File Icon</h4>
            <p>Example:</p>
            <pre>{`<FileIcon icon="doc" />`}</pre>

            <div className="row">

                {
                    Object.entries(fileIconsList).map(icon => (
                        <div className="col-2">
                            <div className="card mb-2">
                                <div className="card-body d-flex" style={{
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "10px"
                                }}>
                                    <FileIcon icon={icon[0]} />
                                    <span style={{ fontSize: "12px" }}>{icon[0]}</span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}
