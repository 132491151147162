import React, { useState, useEffect, useContext } from 'react';
import { LoadingSpinner, Input, Button, Dropdown, FeaturedIcon, Intent } from 'componentsV2';
import { ToasterContext } from 'pages/App';
import { RapidAlarmIntegrationsService } from 'services';
import './Buildings.scss';

export const Buildings = ({ partnerId }) => {
	const [loading, setLoading] = useState(false);
	const [mappings, setMappings] = useState([]);
	const [zoneDropdownOptions, setZoneDropdownOptions] = useState([]);
	const [buildingsSearchValue, setBuildingsSearchValue] = useState();
	const toaster = useContext(ToasterContext);

	const mappingErrorLabel =
		'Security zone has already been mapped to another building. Please select another to proceed.';

	useEffect(() => {
		setLoading(true);
		getBuildingsData();
	}, []);

	// Render a flat list of indented heirarchical zones for the dropdown component.
	const renderDropdownZones = (zones, targetParentId = null, level = 0) => {
		let result = [];

		// Move through each matching parent ID (starts with null for top level items).
		zones.forEach(({ id, parentId, name }) => {
			if (parentId === targetParentId) {
				const subzones = getDescendents(zones, id);

				// Add a new dropdown item with indent based on the level in the hierarchy.
				result.push({
					label: `${name}${subzones.length ? ` (+${subzones.length} sub-zones)` : ''}`,
					value: id,
					indent: level,
					subzones,
				});

				// Recursively find children and increase the level.
				const children = renderDropdownZones(zones, id, level + 1);
				result = result.concat(children);
			}
		});

		return result;
	};

	// Get an array of all decendent IDs within the given dataset matching
	// the given parentId.
	const getDescendents = (data, targetParentId) => {
		let children = [];

		// Find direct children of the given parentId
		data.forEach(({ id, parentId }) => {
			if (parentId === targetParentId) {
				children.push(id);

				// Recursively find children of the current item.
				children = children.concat(getDescendents(data, id));
			}
		});

		return children;
	};

	const getBuildingsData = async () => {
		// getting buildings mappings data.
		const { data: mapData } = await RapidAlarmIntegrationsService.getBuildingMappings(
			partnerId,
		);

		// Build the managed state array around select keys from each mapping object.
		setMappings(
			mapData.map(
				({ partnerZoneId, navigateBuilding: { id: buildingId, name: buildingName } }) => ({
					partnerZoneId,
					buildingId,
					buildingName,
					hasError: false,
				}),
			),
		);

		// getting the zones data.
		const { data: zoneData } = await RapidAlarmIntegrationsService.getAlarmBuildingsZones(
			partnerId,
		);

		setZoneDropdownOptions([
			{
				value: '0',
				label: 'No mapping',
			},
			...renderDropdownZones(zoneData),
		]);

		setLoading(false);
	};

	const handleOnSaveClick = () => {
		// POST API to save the building mappings data.
		const updatedMappingData = mappings.map(({ partnerZoneId, buildingId }) => {
			const { subzones = [] } =
				zoneDropdownOptions.find(({ value }) => value === partnerZoneId) || {};
			return {
				buildingId,
				partnerZoneId,
				...(subzones?.length && { partnerSubZoneIds: subzones }),
			};
		});
		toaster(
			`Updating building mappings...`,
			Intent.WARNING,
			<FeaturedIcon icon="upload" type="Warning" size="md" shape="circleIcon" />,
			5000,
			true,
		);
		RapidAlarmIntegrationsService.updateBuildingMappings(updatedMappingData)
			.then(() => {
				toaster(
					'Successfully updated building mappings.',
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" type="Success" size="md" shape="circleIcon" />,
					10000,
					true,
				);
			})
			.catch(e => {
				console.error(e);
				toaster(
					`Failed to update 911 Cellular building mappings. Please try again or contact support.`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" size="md" shape="circleIcon" />,
					20000,
					true,
				);
			});
	};

	// Function applied to all map listing to show/hide based on search filter.
	// Allows for case insensitive filtering based on partial building name or MTID.
	const filterMap = ({ buildingName, buildingId }) =>
		buildingsSearchValue
			? `${buildingName} ${buildingId}`.toLowerCase().includes(buildingsSearchValue)
			: true;

	// Curried handleClick called when dropdown value is selected.
	const handleDropdownClick = ({ buildingId: changedBuilding }) => ({
		value: newPartnerZoneId,
	}) => {
		// Copy the current state mappings, resetting the error state which will be adjusted below.
		const newMappings = mappings.map(mapRow => ({ ...mapRow, hasError: false }));

		// Update the current map row item within mappings with the new partnerZoneId.
		newMappings.find(
			({ buildingId }) => buildingId === changedBuilding,
		).partnerZoneId = newPartnerZoneId;

		// Copy mappings to a keyed object to detect duplicates.
		const alreadyMapped = {};
		newMappings.forEach(({ buildingId, partnerZoneId }) => {
			if (partnerZoneId !== '0') {
				if (!alreadyMapped[partnerZoneId]) {
					alreadyMapped[partnerZoneId] = [];
				}
				alreadyMapped[partnerZoneId].push(buildingId);
			}
		});

		// Set the error states on specific rows if any.
		Object.values(alreadyMapped)
			.filter(buildings => buildings.length > 1)
			.forEach(buildings => {
				buildings.forEach(errorBuildingId => {
					newMappings.find(
						({ buildingId }) => buildingId === errorBuildingId,
					).hasError = true;
				});
			});

		setMappings(newMappings);
	};

	return (
		<div>
			{loading && <LoadingSpinner />}
			{!loading && (
				<div className="Buildings_Main_Container">
					<div className="title-container">
						<span className="title-text-container">Manage buildings</span>
						<span className="sub-title-container">
							Match buildings from the 911Cellular system to buildings from the
							Navigate360 system
						</span>
					</div>

					<div className="search-container">
						<div className="search">
							<Input
								placeholder="Search EMS buildings"
								onChange={({ target: { value } }) =>
									setBuildingsSearchValue(value?.toLowerCase())
								}
								className="input-search"
								icon="search"
								value={buildingsSearchValue}
							/>
						</div>
					</div>

					<div className="table-main-container">
						<table className="table sticky-table">
							<thead>
								<tr>
									<th className="table-header-container">EMS Buildings</th>
									<th className="table-header">911 Cellular Buildings</th>
								</tr>
							</thead>
							<tbody>
								{mappings.length &&
									mappings?.filter(filterMap).map(item => (
										<tr key={item.buildingId}>
											<td className="table-data-container">
												{item.buildingName}
											</td>
											<td>
												<Dropdown
													value={zoneDropdownOptions.find(
														({ value }) => value === item.partnerZoneId,
													)}
													options={zoneDropdownOptions}
													onChange={handleDropdownClick(item)}
													errorMessage={
														item.hasError ? mappingErrorLabel : ''
													}
													error={item.hasError}
													hasSearch
													hasEmptyState
													searchMessageTitle="No matching zones"
													searchMessageSubtitle="No security zone names match your search"
												/>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
			)}
			<div className="save-button-container">
				<Button
					size="md"
					text="Save"
					type="primaryDefault"
					intent="default"
					onClick={handleOnSaveClick}
					disabled={mappings.find(({ hasError }) => hasError)}
				/>
			</div>
		</div>
	);
};
