import React, { useState, useEffect, useContext } from "react";
import { Tooltip, Button } from "componentsV2";
import PropTypes from 'prop-types';
import './css/BulkActions.scss';
import { MapsContext } from "../Providers";
import { MAP_LIST_PAGE } from "../consts";

const BulkActionButton = ({
    text,
    icon,
    onClick= () => {},
    tooltipTitle = '',
    hasEditPermission
}) => {
    if(!hasEditPermission) {
        return (
            <Tooltip
                tooltipTitle={tooltipTitle}
                theme='light'
            >
                <Button
                    key={text}
                    text={text}
                    type='tertiary'
                    disabled={true}
                    size='sm'
                    icon={icon}
                    onClick={onClick}
                />
            </Tooltip>
        );
    }

    return <Button
                key={text}
                text={text}
                type='tertiary'
                size='sm'
                icon={icon}
                onClick={onClick}
            />
}

export const BulkActions = ({
    editPermission,
    selectedMaps
}) => {
    const [hasEditPermissionOnSelectedMaps, setHasEditPermissionOnSelectedMaps] = useState(false);
    const [hasArchivedBinders, setHasArchivedBinders] = useState(false);
    const [hasNotArchivedBinders, setHasNotArchivedBinders] = useState(false);

    useEffect(() => {
        setHasEditPermissionOnSelectedMaps(!selectedMaps.some(selectedMap => !selectedMap.editPermission));
        setHasArchivedBinders(selectedMaps.some(selectedMap => selectedMap.archivedBy));
        setHasNotArchivedBinders(selectedMaps.some(selectedMap => !selectedMap.archivedBy));
    }, [selectedMaps]);
	const MapCTX = useContext(MapsContext);

    return (
        <section className='maps-bulk-actions'>
            {editPermission && (
                <>
                    {hasNotArchivedBinders && (
                        <BulkActionButton
                            text='Archive'
                            icon='archive'
                            onClick={() => MapCTX.showArchiveModal(selectedMaps, MAP_LIST_PAGE)}
                            tooltipTitle = "Selected maps belongs to sites you don’t have permission on"
                            hasEditPermission={hasEditPermissionOnSelectedMaps}
                        />
                    )}
                    {hasArchivedBinders && (
                        <BulkActionButton
                            text='Unarchive'
                            icon='unarchive'
                            onClick={() => MapCTX.showUnarchiveModal(selectedMaps, MAP_LIST_PAGE)}
                            tooltipTitle="Selected maps belongs to sites you don’t have permission on"
                            hasEditPermission={hasEditPermissionOnSelectedMaps}
                        />
                    )}
                </>
            )}
			{editPermission && !hasArchivedBinders && (
                <BulkActionButton
                    text='Delete'
                    icon='trash'
                    onClick={() => MapCTX.showDeleteMapModal(selectedMaps, MAP_LIST_PAGE)}
                    tooltipTitle="Selected maps belongs to sites you don’t have permission on"
                    hasEditPermission={hasEditPermissionOnSelectedMaps}
                />
            )}
        </section>
    );
}

BulkActionButton.PropTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    tooltipTitle: PropTypes.string,
    hasEditPermission: PropTypes.bool.isRequired
}

BulkActions.propTypes = {
    editPermission: PropTypes.bool.isRequired,
    selectedMaps: PropTypes.array.isRequired,
};
