import React from 'react';
import { Button, ActionsButton } from 'componentsV2';

export const AlarmTypeActinos = ({
	history,
	alarmType,
	showDeleteModal,
	hasPermissionOnDistrict,
	isSuperAdmin,
}) => {
	const isActionsButtonDisabled =
		!isSuperAdmin &&
		!hasPermissionOnDistrict &&
		!alarmType.disabled &&
		!alarmType.preset &&
		(!alarmType.canBeDeleted || !(isSuperAdmin || hasPermissionOnDistrict));
	return (
		<ActionsButton disabled={isActionsButtonDisabled}>
			{(alarmType.disabled || alarmType.preset) && (
				<Button
					large
					text="View"
					icon="eye-open"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() =>
						history.push(`/rapid-alarms/settings/alarm-types/${alarmType.id}`)
					}
				/>
			)}
			{(isSuperAdmin || hasPermissionOnDistrict) &&
				!alarmType.disabled &&
				!alarmType.preset && (
					<Button
						large
						text="Edit"
						icon="edit"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() =>
							history.push(`/rapid-alarms/settings/alarm-types/${alarmType.id}`)
						}
					/>
				)}

			{(isSuperAdmin || hasPermissionOnDistrict) && alarmType.canBeDeleted && (
				<Button
					large
					text="Delete"
					icon="trash"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => {
						showDeleteModal(alarmType);
					}}
				/>
			)}
		</ActionsButton>
	);
};
