import React, { useState } from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import { LoadingSpinner } from 'components/LoadingSpinner';
import { formatDate } from 'utilities/dates';
import { StatusPilsner } from 'components/StatusPilsner';
import { randomColor } from 'utilities/drills/analytics';
import { ChartWrapper } from './shared';

const TableComponent = styled.table`
	background: #ffffff;
	border: 1px solid #d8e1e8;
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;

	td {
		border-color: #d8e1e8;
		border-style: solid;
		border-width: 1px 0px 1px 0px;
		font-size: 14px;
		overflow: hidden;
		padding: 10px 5px;
		word-break: normal;
	}
	th {
		border-color: #d8e1e8;
		border-style: solid;
		border-width: 1px 0px 1px 0px;
		color: #738694;
		font-size: 14px;
		font-weight: bold;
		font-family: Nunito Sans;
		overflow: hidden;
		padding: 10px 5px;
		word-break: normal;
	}
	.tg-0lax {
		text-align: left;
		vertical-align: top;
	}
`;

const SpecificDrillsAnalyticsWrapper = styled.div`
	margin-bottom: 34px;
`;

const StatusContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	justif-content: center;
	heigth: 50px;
`;

const ExpandableRow = ({ showOnly, drillData }) => {
	const [isVisible, setIsVisible] = useState(false);
	return (
		<>
			<tr key={drillData.id} id={drillData.id}>
				<td className="tg-0lax">
					{isVisible ? (
						<Icon
							onClick={() => setIsVisible(!isVisible)}
							onKeyPress={() => setIsVisible(!isVisible)}
							icon="caret-up"
						/>
					) : (
						<Icon
							onClick={() => setIsVisible(!isVisible)}
							onKeyPress={() => setIsVisible(!isVisible)}
							icon="caret-right"
						/>
					)}
					<strong>{drillData.site}</strong>
				</td>
				<td className="tg-0lax" />
				<td className="tg-0lax" />
				<td className="tg-0lax">
					<strong>{drillData.count}</strong>
				</td>
			</tr>
			{isVisible &&
				drillData?.drills?.map(drill => (
					<tr key={drill.id} id={drill.id}>
						<td className="tg-0lax" />
						<td className="tg-0lax">{drill['drill type']}</td>
						{showOnly.value === 'completed' && (
							<td className="tg-0lax">
								{formatDate(drill['actual date'])}
							</td>
						)}
						<td className="tg-0lax">
							{formatDate(drill['scheduled date'])}
						</td>
						<td className="tg-0lax" />
					</tr>
				))}
		</>
	);
};

const getStatusForShowOnly = showOnlyValue => {
	if (showOnlyValue === 'completed') {
		return 'completed';
	}
	if (showOnlyValue === 'notScheduled') {
		return 'not_scheduled';
	}
	return 'overdue';
};

const getHeaderNameForDrillType = showOnlyValue => {
	if (showOnlyValue === 'completed') {
		return 'Completed';
	}
	if (showOnlyValue === 'notScheduled') {
		return 'Not scheduled';
	}
	return 'Overdue';
};

export const SpecificDrillsAnalytics = ({
	selectedYear,
	showOnly,
	drillsByStatusData,
}) => {
	if (!drillsByStatusData) {
		return <LoadingSpinner />;
	}

	return (
		<SpecificDrillsAnalyticsWrapper>
			{drillsByStatusData?.buildingStats && drillsByStatusData?.types && (
				<div className="chart-container">
					<ChartWrapper
						title={`Drill logs in ${selectedYear}`}
						subtitle={`Drill logs in ${selectedYear}`}
					>
						<div className="responsive-chart-container">
							<ResponsiveContainer width="100%" height="100%">
								<BarChart
									width={500}
									height={300}
									data={drillsByStatusData.buildingStats}
									margin={{
										top: 20,
										right: 30,
										left: 20,
										bottom: 5,
									}}
									maxBarSize={22}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />
									<Legend />
									{/* TODO: Fix colors */}
									{drillsByStatusData?.types &&
										drillsByStatusData.types.map(type => (
											<Bar
												dataKey={type.name}
												stackId="a"
												fill={randomColor()}
											/>
										))}
								</BarChart>
							</ResponsiveContainer>
						</div>
					</ChartWrapper>
				</div>
			)}
			{showOnly.value !== 'notScheduled' && (
				<>
					<StatusContainer>
						<h4>{`${
							drillsByStatusData?.buildingDrills.length
						} drill(s) listed`}</h4>
						<StatusPilsner
							status={getStatusForShowOnly(showOnly.value)}
						/>
					</StatusContainer>
					<div>
						<TableComponent>
							<thead>
								<tr>
									<th>Site</th>
									<th>Drill type</th>
									{showOnly.value === 'completed' && (
										<th>Actual date</th>
									)}
									<th>Scheduled date</th>
									<th>
										{getHeaderNameForDrillType(
											showOnly.value,
										)}
									</th>
								</tr>
							</thead>
							<tbody>
								{drillsByStatusData?.buildingDrills?.map(
									(buildingDrill, index) => (
										<ExpandableRow
											key={index}
											drillData={buildingDrill}
											showOnly={showOnly}
										/>
									),
								)}
							</tbody>
						</TableComponent>
					</div>
				</>
			)}

			{showOnly.value === 'notScheduled' && (
				<>
					<StatusContainer>
						<h4>{`${
							drillsByStatusData?.typeStats.length
						} drill types listed`}</h4>
						<StatusPilsner status="notScheduled" />
					</StatusContainer>
					<div>
						<TableComponent>
							<thead>
								<tr>
									<th>Drill type</th>
									<th>Not Scheduled</th>
								</tr>
							</thead>
							<tbody>
								{drillsByStatusData?.typeStats?.map(
									typeStat => (
										<tr>
											<td>{typeStat.name}</td>
											<td>{typeStat.count}</td>
										</tr>
									),
								)}
							</tbody>
						</TableComponent>
					</div>
				</>
			)}
		</SpecificDrillsAnalyticsWrapper>
	);
};

SpecificDrillsAnalytics.propTypes = {
	selectedYear: PropTypes.string.isRequired,
	drillsByStatusData: PropTypes.object.isRequired,
	showOnly: PropTypes.object.isRequired,
};

ExpandableRow.propTypes = {
	drillData: PropTypes.object.isRequired,
	showOnly: PropTypes.object.isRequired,
};
