import React from 'react';
import PropTypes from 'prop-types';
import { SearchBar } from 'componentsV2';
import './css/Filteration.scss';
export const Filteration = ({ dispatchFilterationData, filterationData, disabled = false }) => {
	return (
		<section className="callList-filteraion">
			<div className="callList-filteraion-inputs-container">
				<div className="callList-filteraion-inputs-container-left-side">Contacts</div>
				<div className="callList-filteraion-inputs-container-right-side">
					<SearchBar
						placeholder="Search by name, phone, or email..."
						className="callList-search-bar"
						onSearch={combineSearch => {
							dispatchFilterationData({
								type: 'combineSearch',
								payload: combineSearch,
							});
						}}
						disabled={disabled}
						value={filterationData.combineSearch}
					/>
				</div>
			</div>
		</section>
	);
};

Filteration.propTypes = {
	filterationData: PropTypes.object,
};
