import React, { useContext, useState } from 'react';
import { ConfirmationModal, FeaturedIcon } from 'componentsV2';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { ContactService } from 'services/ContactService';

export const DeleteContactModal = ({ row, callListId, setReloadContacts, onCloseModal }) => {
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);
	const deleteContact = () => {
		setLoading(true);
		const params = {
			call_list_id: callListId,
			access_type: row.accessType,
			user_id: row.id,
		};
		ContactService.delete(params)
			.then(res => {
				if (res.statusCode === 200) {
					toaster(
						'You have successfully deleted this contact.',
						Intent.NONE,
						<FeaturedIcon icon="trash" size="md" type="Gray" />,
					);
					setLoading(false);
					onCloseModal();
					setReloadContacts(oldValue => !oldValue);
				} else {
					toaster('Something went wrong', Intent.DANGER);
					setLoading(false);
				}
			})
			.catch(err => {
				setLoading(false);
				toaster(err.error.description, Intent.DANGER);
			});
	};

	return (
		<ConfirmationModal
			loading={loading}
			title={`Are you sure you want to delete the contact “${row.person.name.givenName +
				' ' +
				row.person.name.familyName}”?`}
			isOpen={true}
			icon={{ icon: 'trash', iconColor: 'error' }}
			subtitle={
				'This contact will be removed from the call list. This action cannot be undone.'
			}
			confirmBtn={{
				title: 'Delete',
				onClick: deleteContact,
				type: 'primaryDanger',
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: () => onCloseModal(),
			}}
		/>
	);
};
