import React from "react";
import NormalModal from 'components/Modals/NormalModal';

const DeleteRespondBuildingModal = ({
    setModalToShow,
    onConfirmAction,
    site,
    isLoading,
}) => {

    return (
        <NormalModal
            onConfirmAction={() => onConfirmAction(site)}
            onCancelAction={() => setModalToShow(false)}
            header="Delete building"
            confirmBtnTitle="Delete"
            cancelBtnTitle="Cancel"
            loading={isLoading}
        >
            <div className="row mb-3">
                <div className="col-md-12">
                    <div className="form-group">
                        <p>Deleting this building will remove you from the staff count for this site, and you will no longer receive notifications</p>
                    </div>
                </div>
            </div>
        </NormalModal>
    );
}

export default DeleteRespondBuildingModal;