import React, { useState, useContext, useEffect } from 'react';
import { FormModal, Input } from 'componentsV2';
import { SiteDropdownSingleSelect } from 'components/SiteDropdownSingleSelect';
import { CallListService } from 'services/CallListService';
import { ToasterContext, Intent } from 'componentsV2';

export const AddUpdateCallListModal = ({
	handleCancel,
	sites,
	setReloadCallLists,
	selectedCallListId,
	setSelectedCallListId,
}) => {
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [formData, SetFormData] = useState({
		title: '',
		siteSelected: null,
	});
	const [error, setError] = useState({
		title: {
			isError: false,
			message: '',
		},
	});

	const loadCallListDetails = () => {
		setLoading(true);
		let params = {
			id: selectedCallListId,
		};
		CallListService.getById(params).then(resp => {
			if (resp.statusCode === 200) {
				SetFormData({
					title: resp.data.title,
					siteSelected: {
						id: resp.data.site_id,
						value: resp.data.site_id,
						label: resp.data.site,
					},
				});
				setLoading(false);
			}
		});
	};

	const onClose = () => {
		setEditMode(false);
		SetFormData([]);
		setSelectedCallListId(null);
		handleCancel();
	};

	useEffect(() => {
		if (selectedCallListId) {
			setEditMode(true);
			loadCallListDetails();
		}
	}, [selectedCallListId]);

	const handleSubmit = () => {
		let postData = {
			id: editMode ? selectedCallListId : null,
			name: formData.title,
			site_id: formData.siteSelected.value,
		};
		setLoading(true);
		CallListService.save(postData)
			.then(resp => {
				if (resp.statusCode === 200 || resp.statusCode === 201) {
					toaster(
						editMode
							? 'You have successfully edited this call list.'
							: 'You have successfully added a new call list.',
						Intent.SUCCESS,
					);
					handleCancel();
					setReloadCallLists(oldValue => !oldValue);
					setEditMode(false);
					SetFormData([]);
					setSelectedCallListId(null);
				} else {
					toaster(resp.error.description, Intent.DANGER);
				}
				setLoading(false);
			})
			.catch(error => {
				if (error.statusCode == 400) {
					const errorMessage = '*Call list with same name already exists';
					setError({
						title: {
							isError: true,
							message: errorMessage,
						},
					});
				} else {
					toaster(error.error.description, Intent.DANGER);
				}
				setLoading(false);
			});
	};

	const handleChangeTitle = e => {
		SetFormData({ ...formData, title: e.target.value });
		e.target.value.trim() === ''
			? setError({
					title: {
						isError: true,
						message: '*Title is required',
					},
			  })
			: setError({
					title: {
						isError: false,
					},
			  });
	};

	const selectSitesHandler = item => {
		SetFormData({
			...formData,
			siteSelected: {
				label: item.label,
				value: item.value,
				id: item.id,
			},
		});
	};

	return (
		<FormModal
			isOpen={true}
			onClose={onClose}
			showCloseButton={false}
			title={editMode ? 'Edit call list' : 'Add new call list'}
			loading={loading}
			scrollContent={false}
			confirmBtn={{
				title: 'Save',
				onClick: handleSubmit,
				disabled: formData.title.trim() === '' || formData.siteSelected === null,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: () => onClose(),
			}}
		>
			<div className="w-100">
				<Input
					label="Title"
					isRequired={true}
					width="100%"
					value={formData.title}
					onChange={e => handleChangeTitle(e)}
					placeholder="ex. High school list"
					error={error.title.isError}
					errorMessage={error.title.message}
				/>
			</div>

			<div className="w-100 mt-3">
				<SiteDropdownSingleSelect
					selectedSite={formData.siteSelected}
					isRequired={true}
					sitesList={sites || []}
					onChange={selectSitesHandler}
					disabled={editMode}
				/>
			</div>
		</FormModal>
	);
};
