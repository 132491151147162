import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'components/Dropdown';
import { BuildingsService } from 'services/BuildingsService';
import { setLastSelectedSite } from '../../routeReducer';

const SitesDropdown = ({ selectedSite, onChange }) => {
	const [sites, setSites] = useState(null);

	const dispatch = useDispatch();
	const previousStoredSelection = useSelector((state) => state.route.lastSelectedSite);

	useEffect(() => {
		if (previousStoredSelection) {
			onChange(previousStoredSelection);
		}
	}, []);

	useEffect(() => {
		BuildingsService.getAll(true).then((serviceBuildings) => setSites(serviceBuildings));
	}, []);

	return (
		<Dropdown
			label="Site"
			placeholder="Select a site"
			value={selectedSite || previousStoredSelection}
			options={sites}
			onChange={(selectedYear) => {
				dispatch(setLastSelectedSite(selectedYear));
				if (onChange) {
					onChange(selectedYear);
				}
			}}
		/>
	);
};

SitesDropdown.propTypes = {
	selectedSite: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};

export { SitesDropdown };
