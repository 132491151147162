import React from 'react';
import { flags } from 'utilities';
import FirstResponderUsers from './FirstResponderUsers/FirstResponderUsers';
import Users from './Users';

export const UsersCheckSite = () => {
	const {
		isOn,
		names: { SITE_IS_FIRST_RESPONDER },
	} = flags;

	if (isOn(SITE_IS_FIRST_RESPONDER)) {
		return <FirstResponderUsers />;
	}

	return <Users />;
};
