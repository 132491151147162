import React from 'react';
import { ConfirmationModal } from 'componentsV2';

export const UpdatePrimaryContactModal = ({ onCloseModal, onSubmitUpdatePrimaryContact }) => {
	return (
		<ConfirmationModal
			isOpen
			title="Update"
			subtitle="Are you sure you want to update this user to the primary contact?"
			showCloseButton={false}
			cancelBtn={{
				title: 'Cancel',
				onClick: onCloseModal,
			}}
			confirmBtn={{
				title: 'Update',
				onClick: onSubmitUpdatePrimaryContact,
				type: 'primaryDefault',
			}}
			btnsSize="lg"
		/>
	);
};
