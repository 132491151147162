import React, {useContext} from "react";
import { SafetyplansContext } from "../Providers/SafetyplansProvider";
import PropTypes from 'prop-types';
import { Button } from "componentsV2";

export const ActionButton = ({
    template
}) => {

    const {    
        showArchiveModal,
    } = useContext(SafetyplansContext);

    return (
        <Button
            text="Unarchive"
            icon="unarchive"
            size="lg"
            wrapperClass="displayBlock"
            className='breadcrumb_TitleActions'
            onClick={()=> showArchiveModal({
                id: template.id,
                title: template.name,
                archived: template.archived,
                source: 'details'
            })}
        />
    );
}

ActionButton.propTypes = {
	template: PropTypes.object,
};