import { useContext, useEffect, useReducer } from 'react';
import { Intent } from '@blueprintjs/core';
import { ToasterContext } from 'pages/App';
import { ChatService } from 'services';
import { initializeChatSocket } from 'utilities/socket';
import { formatDate, getFormattedDate } from 'utilities/dates';
import { msgReceivedSound } from 'assets/sounds';

export const useRollCallChat = alarm => {
	const LAST_MESSAGE_MAX_LENGTH = 100;
	const loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
	const isRespondTypeAdmin = loggedUser.accountType == 'administrator' ? true : false;
	const toaster = useContext(ToasterContext);

	const chatReducer = (data, action) => {
		data[action.type] = action.payload;
		return {
			...data,
		};
	};

	const [chatData, dispatchChatData] = useReducer(chatReducer, {
		loading: true,
		lastMessageReceived: null,
		pageSize: 10,

		adminMessages: [],
		adminGroupedMessages: [],
		adminLoadingMessages: false,
		adminLastKey: '',
		adminLastMessage: null,
		adminShowChatRoom: false,
		adminExpandChatRoom: false,
		adminMessageForm: '',
		adminDisableSubmit: false,
		adminNotifications: 0,
		adminCheckNotifications: false,

		everyoneMessages: [],
		everyoneGroupedMessages: [],
		everyoneLoadingMessages: false,
		everyoneLastKey: '',
		everyoneLastMessage: null,
		everyoneShowChatRoom: false,
		everyoneExpandChatRoom: false,
		everyoneMessageForm: '',
		everyoneDisableSubmit: false,
		everyoneNotifications: 0,
		everyoneCheckNotifications: false,
	});

	useEffect(() => {
		if (alarm?.id) {
			getAllMessages();
		}
	}, [alarm]);

	useEffect(() => {
		if (!chatData.loading) {
			const { chatSocket } = initializeChatSocket(alarm.id);

			chatSocket.on('connect', () => {
				console.log('Chat connected!', chatSocket);

				chatSocket.on('newMessage', message => {
					console.log(`Chat Received new message`, message);
					dispatchChatData({
						type: 'lastMessageReceived',
						payload: message,
					});
				});
			});

			return () => {
				if (chatSocket.connected) {
					chatSocket.disconnect();
				}
			};
		}
	}, [chatData.loading]);

	useEffect(() => {
		if (chatData.everyoneExpandChatRoom) {
			scrollToBottom('everyoneMessages');
			setTimeout(() => {
				dispatchChatData({
					type: 'everyoneNotifications',
					payload: 0,
				});
			}, 3000);
		}
	}, [chatData.everyoneExpandChatRoom, chatData.everyoneCheckNotifications]);

	useEffect(() => {
		if (chatData.adminExpandChatRoom) {
			scrollToBottom('adminMessages');
			setTimeout(() => {
				dispatchChatData({
					type: 'adminNotifications',
					payload: 0,
				});
			}, 3000);
		}
	}, [chatData.adminExpandChatRoom, chatData.adminCheckNotifications]);

	useEffect(() => {
		if (chatData.lastMessageReceived) {
			addMessageToRoom(chatData.lastMessageReceived);
		}
	}, [chatData.lastMessageReceived]);

	useEffect(() => {
		if (chatData.adminLoadingMessages) {
			adminGetPreviousMessages();
		}
	}, [chatData.adminLoadingMessages]);

	useEffect(() => {
		if (chatData.everyoneLoadingMessages) {
			everyoneGetPreviousMessages();
		}
	}, [chatData.everyoneLoadingMessages]);

	const scrollToBottom = elementId => {
		var element = document.getElementById(elementId);
		if (element) {
			element.scrollTop = element.scrollHeight;
		}
	};

	const limitMessageLength = message => {
		if (message && message.length > LAST_MESSAGE_MAX_LENGTH) {
			message = message.slice(0, LAST_MESSAGE_MAX_LENGTH) + '...';
		}
		return message;
	};

	const getAllMessages = () => {
		Promise.all([
			isRespondTypeAdmin
				? ChatService.getAll(alarm.id, 'admin', {
						lastKey: chatData.adminLastKey,
						pageSize: chatData.pageSize,
				  })
				: null,
			ChatService.getAll(alarm.id, 'everyone', {
				lastKey: chatData.everyoneLastKey,
				pageSize: chatData.pageSize,
			}),
		])
			.then(([adminMessages, everyoneMessages]) => {
				let messages = [];
				if (isRespondTypeAdmin && adminMessages.messages?.length > 0) {
					messages = adminMessages.messages.map(formatChatMessage).reverse();
					dispatchChatData({
						type: 'adminMessages',
						payload: messages,
					});
					dispatchChatData({
						type: 'adminGroupedMessages',
						payload: groupMessagesByDate(messages),
					});

					dispatchChatData({
						type: 'adminLastMessage',
						payload: formatChatMessage(adminMessages.messages[0]),
					});
					dispatchChatData({
						type: 'adminLastKey',
						payload: adminMessages.lastKey,
					});
				}
				if (everyoneMessages.messages?.length > 0) {
					messages = everyoneMessages.messages.map(formatChatMessage).reverse();
					dispatchChatData({
						type: 'everyoneMessages',
						payload: messages,
					});
					dispatchChatData({
						type: 'everyoneGroupedMessages',
						payload: groupMessagesByDate(messages),
					});

					dispatchChatData({
						type: 'everyoneLastMessage',
						payload: formatChatMessage(everyoneMessages.messages[0]),
					});
					dispatchChatData({
						type: 'everyoneLastKey',
						payload: everyoneMessages.lastKey,
					});
				}
			})
			.catch(error => {
				toaster(`Error getting chat messages: ${error.message}`, Intent.DANGER);
			})
			.finally(() => {
				dispatchChatData({
					type: 'loading',
					payload: false,
				});
			});
	};

	const formatChatMessage = apiMessage => {
		let message = null;
		let formattedDate = new Date(apiMessage.createdAt);
		formattedDate = formatDate(formattedDate);
		formattedDate = formattedDate.split(', ');
		if (formattedDate.length == 2) {
			message = {
				key: apiMessage.key,
				senderId: apiMessage.sender?.key,
				senderName: apiMessage.sender?.name,
				date: getFormattedDate(apiMessage.createdAt),
				time: formattedDate[1],
				message: apiMessage.message,
			};
		}
		return message;
	};

	const groupMessagesByDate = messages => {
		let groupedMessages = [];
		messages.forEach(message => {
			groupedMessages = addMessageToGroup(message, groupedMessages);
		});
		return groupedMessages;
	};

	const addMessageToGroup = (message, groupedMessages) => {
		let key =
			groupedMessages.length > 0
				? groupedMessages.findIndex(groups => groups?.date === message.date)
				: false;
		if (key === false || key < 0) {
			groupedMessages.push({
				date: message.date,
				messages: [],
			});
			key = groupedMessages.findIndex(groups => groups?.date === message.date);
		}
		if (key >= 0) {
			groupedMessages[key]?.messages.push(message);
		}
		return groupedMessages;
	};

	const addMessageToRoom = message => {
		const formattedMessage = formatChatMessage(message);
		const room = message.conversation?.key;
		if (room === 'admin' || room === 'everyone') {
			let messages = chatData[`${room}Messages`];
			messages.push(formattedMessage);
			dispatchChatData({
				type: `${room}Messages`,
				payload: messages,
			});
			dispatchChatData({
				type: `${room}GroupedMessages`,
				payload: groupMessagesByDate(messages),
			});
			dispatchChatData({
				type: `${room}LastMessage`,
				payload: formattedMessage,
			});
			dispatchChatData({
				type: `${room}CheckNotifications`,
				payload: !chatData[`${room}CheckNotifications`],
			});

			// Play a sound and add the notification
			// If the message was sent by another user
			if (loggedUser.person.personId !== formattedMessage.senderId) {
				dispatchChatData({
					type: `${room}Notifications`,
					payload: chatData[`${room}Notifications`] + 1,
				});
				const audio = new Audio(msgReceivedSound);
				audio.play();
			}
		}
	};

	// Everyone handlers
	const everyoneOpenChatRoom = () => {
		dispatchChatData({
			type: 'everyoneShowChatRoom',
			payload: true,
		});
		dispatchChatData({
			type: 'everyoneExpandChatRoom',
			payload: !chatData.everyoneExpandChatRoom,
		});
	};

	const everyoneSetShowChatRoom = value => {
		dispatchChatData({
			type: 'everyoneShowChatRoom',
			payload: value,
		});
	};

	const everyoneSetExpandChatRoom = value => {
		dispatchChatData({
			type: 'everyoneExpandChatRoom',
			payload: value,
		});
	};

	const everyoneChangeMessage = ({ target }) => {
		dispatchChatData({
			type: 'everyoneMessageForm',
			payload: target.value,
		});
	};

	const everyoneSendMessage = () => {
		dispatchChatData({
			type: 'everyoneDisableSubmit',
			payload: true,
		});
		ChatService.sendMessage(alarm.id, 'everyone', {
			message: chatData.everyoneMessageForm,
			sender: {
				key: loggedUser.person.personId,
				name: `${loggedUser.person.name.givenName} ${loggedUser.person.name.familyName}`,
			},
		})
			.then(result => {
				if (result.status === 'success') {
					dispatchChatData({
						type: 'everyoneMessageForm',
						payload: '',
					});
				} else {
					toaster(`Error sending chat message: ${error.message}`, Intent.DANGER);
				}
			})
			.catch(error => {
				toaster(`Error sending chat message: ${error.message}`, Intent.DANGER);
			})
			.finally(() => {
				dispatchChatData({
					type: 'everyoneDisableSubmit',
					payload: false,
				});
			});
	};

	const everyoneOnScrollChatRoom = () => {
		let everyoneMessages = document.getElementById('everyoneMessages');
		everyoneMessages.addEventListener('scroll', everyoneScrollHandler());
	};

	const everyoneScrollHandler = _.debounce(async () => {
		let everyoneMessages = document.getElementById('everyoneMessages');
		if (everyoneMessages.scrollTop === 0 && chatData.everyoneLastKey !== null) {
			dispatchChatData({
				type: 'everyoneLoadingMessages',
				payload: true,
			});
		}
	}, 300);

	const everyoneGetPreviousMessages = () => {
		ChatService.getAll(alarm.id, 'everyone', {
			lastKey: chatData.everyoneLastKey,
			pageSize: chatData.pageSize,
		})
			.then(everyoneMessages => {
				let messages = [];
				if (everyoneMessages.messages?.length > 0) {
					messages = everyoneMessages.messages.map(formatChatMessage).reverse();
					messages = messages.concat(chatData.everyoneMessages);
					dispatchChatData({
						type: 'everyoneMessages',
						payload: messages,
					});
					dispatchChatData({
						type: 'everyoneGroupedMessages',
						payload: groupMessagesByDate(messages),
					});
					dispatchChatData({
						type: 'everyoneLastKey',
						payload: everyoneMessages.lastKey,
					});
				}
			})
			.catch(error => {
				toaster(`Error getting chat messages: ${error.message}`, Intent.DANGER);
			})
			.finally(() => {
				dispatchChatData({
					type: 'everyoneLoadingMessages',
					payload: false,
				});
			});
	};

	// Admins handlers
	const adminOpenChatRoom = () => {
		dispatchChatData({
			type: 'adminShowChatRoom',
			payload: true,
		});
		dispatchChatData({
			type: 'adminExpandChatRoom',
			payload: !chatData.adminExpandChatRoom,
		});
	};

	const adminSetShowChatRoom = value => {
		dispatchChatData({
			type: 'adminShowChatRoom',
			payload: value,
		});
	};

	const adminChangeMessage = ({ target }) => {
		dispatchChatData({
			type: 'adminMessageForm',
			payload: target.value,
		});
	};

	const adminSetExpandChatRoom = value => {
		dispatchChatData({
			type: 'adminExpandChatRoom',
			payload: value,
		});
	};

	const adminSendMessage = () => {
		dispatchChatData({
			type: 'adminDisableSubmit',
			payload: true,
		});
		ChatService.sendMessage(alarm.id, 'admin', {
			message: chatData.adminMessageForm,
			sender: {
				key: loggedUser.person.personId,
				name: `${loggedUser.person.name.givenName} ${loggedUser.person.name.familyName}`,
			},
		})
			.then(result => {
				if (result.status === 'success') {
					dispatchChatData({
						type: 'adminMessageForm',
						payload: '',
					});
				} else {
					toaster(`Error sending chat message: ${error.message}`, Intent.DANGER);
				}
			})
			.catch(error => {
				toaster(`Error sending chat message: ${error.message}`, Intent.DANGER);
			})
			.finally(() => {
				dispatchChatData({
					type: 'adminDisableSubmit',
					payload: false,
				});
			});
	};

	const adminOnScrollChatRoom = () => {
		let adminMessages = document.getElementById('adminMessages');
		adminMessages.addEventListener('scroll', adminScrollHandler());
	};

	const adminScrollHandler = _.debounce(async () => {
		let adminMessages = document.getElementById('adminMessages');
		if (adminMessages.scrollTop === 0 && chatData.adminLastKey !== null) {
			dispatchChatData({
				type: 'adminLoadingMessages',
				payload: true,
			});
		}
	}, 300);

	const adminGetPreviousMessages = () => {
		ChatService.getAll(alarm.id, 'admin', {
			lastKey: chatData.adminLastKey,
			pageSize: chatData.pageSize,
		})
			.then(adminMessages => {
				let messages = [];
				if (isRespondTypeAdmin && adminMessages.messages?.length > 0) {
					messages = adminMessages.messages.map(formatChatMessage).reverse();
					messages = messages.concat(chatData.adminMessages);
					dispatchChatData({
						type: 'adminMessages',
						payload: messages,
					});
					dispatchChatData({
						type: 'adminGroupedMessages',
						payload: groupMessagesByDate(messages),
					});
					dispatchChatData({
						type: 'adminLastKey',
						payload: adminMessages.lastKey,
					});
				}
			})
			.catch(error => {
				toaster(`Error getting chat messages: ${error.message}`, Intent.DANGER);
			})
			.finally(() => {
				dispatchChatData({
					type: 'adminLoadingMessages',
					payload: false,
				});
			});
	};

	const isToday = date => {
		const today = new Date();
		const dateToCheck = new Date(date);
		return today.toDateString() === dateToCheck.toDateString();
	};

	return {
		chatData,
		isToday,
		loggedUser,
		isRespondTypeAdmin,
		limitMessageLength,

		everyoneOpenChatRoom,
		everyoneSetShowChatRoom,
		everyoneSetExpandChatRoom,
		everyoneChangeMessage,
		everyoneSendMessage,
		everyoneOnScrollChatRoom,

		adminOpenChatRoom,
		adminSetShowChatRoom,
		adminSetExpandChatRoom,
		adminChangeMessage,
		adminSendMessage,
		adminOnScrollChatRoom,
	};
};
