import React from 'react';
import { Badge } from 'componentsV2';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@blueprintjs/core';
import { stripTags } from 'utilities';
import './css/PlanOutline.scss';

const Subsections = props => {
	const { item, index, page, setPage, viewMode, planType } = props;

	return (
		<Droppable
			droppableId={`droppable${item.id}`}
			type={`${index}`}
			key={`subsections-${index}`}
			className='subsections-planoutline-wrapper'
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					className='answer-list'
				>
					{item.subsections.map((subsection, i) => {
						if (props.visibleSubsection?.id === subsection.id && 
							props.visibleSubsection?.title === subsection.title) {
								setPage(page);
							}
						return (
						<Draggable
							key={`${index}${i}`}
							draggableId={`${index}${i}`}
							index={i}
							isDragDisabled={viewMode}
						>
							{(p, s) => (
								<div
									ref={p.innerRef}
									{...p.draggableProps}
									style={{
										...p.draggableProps.style
									}}
									className="section-menu-item"
								>
									<div id={`planoutline-${item.id}-${subsection.id}`} className='dot-icon-wrapper'>
										{props.visibleSubsection?.id === subsection.id && 
											props.visibleSubsection?.title === subsection.title && (
											<Icon icon="dot" />
										)}
									</div>
									{!viewMode && (
										<span {...p.dragHandleProps}>
											<Icon icon="drag-handle-vertical" className='draggableIcon' />
										</span>
									)}
									<a
										className="main-section-title"
										onClick={() =>
											props.scrollToSection(
												item,
												subsection,
												'subsection',
												page
											)
										}
									>
										{stripTags(subsection.title)}
									</a>
									
									{!viewMode && !subsection.complete && planType !== 'corePlan' && (
										<Badge 
											type='Warning'
											size='sm'
											text='Incomplete' 
										/>
									)}
								</div>
							)}
						</Draggable>
						)
					})}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
};

Subsections.propTypes = {
	item: PropTypes.object,
	index: PropTypes.number,
};

export default Subsections;
