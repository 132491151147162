import { DocumentService } from "services/DocumentService";
import { useState } from "react";
import { Intent } from "componentsV2";


export const RevisionHistoryFn = (
    setLoading,
    dispatchDocumentData,
    showDownloadModal,
    setModalToShow,
    downloadDocumentHandler,
    toaster
) => {
    const [revisionHistoryList, setRevisionHisoryList] = useState([]);

    const showRevisionHistoryModal = (document) => {
        setRevisionHisoryList([])
        setLoading(true)
    
        DocumentService.getRevisionHistory(document.id).then(async response => {
            setRevisionHisoryList(response.data)
            dispatchDocumentData({
                id:document.id,
                title:document.title,
                modalTitle:"Revision history",
            });  
            
            setModalToShow('revisionHistoryModal')
        })
        .catch (err => {
            toaster(err.error?.description, Intent.DANGER)})
        .finally(() => {
            setLoading(false);
        });
      
    }

    const showDownloadRevision = (revisionDocument) => {
        
        showDownloadModal(revisionDocument, false, revisionDocument.revison_id) 
        dispatchDocumentData({
            id:revisionDocument.documentId,
            title:revisionDocument.title,
            modalTitle:"Revision history",
            onConfirmAction: () => downloadDocumentHandler(false, [revisionDocument.documentId], 1, false, revisionDocument.revison_id)

        });  
    }
 
    return {
        showRevisionHistoryModal,
        revisionHistoryList,
        showDownloadRevision
    }
}