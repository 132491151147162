import React, { useState } from 'react';
import { ToggleButton } from './';

export const ToggleButtonPattern = () => {
	const [checked, setChecked] = useState(false);

	const handleChange = () => {
		checked === true ? setChecked(false) : setChecked(true);
	};
	return (
		<>
			<ToggleButton
				checked={checked}
				label={'Toggle to change value'}
				onChange={handleChange}
			/>
			<label>{checked ? 'Checked' : 'Un checked'}</label>
		</>
	);
};
