import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StaffTypesService } from 'services/StaffTypesService';
import { DropdownMultiSelect } from '../DropdownMultiSelect';

const StaffTypesDropdown = ({ 
	label = "Staff title",
	placeholder = "Select a position",
	value = null,
	allowSelectAll,
	selectAlllabel,
	visibleOptions,
	error,
	options,
	onChange
}) => {
	const [staffTypes, setStaffTypes] = useState(null);

	useEffect(() => {
		if (options.length > 0) {
			setStaffTypes(options);
		} else {			
			StaffTypesService.getAll().then(data => {
				var staffTypes = [];
				data.forEach(element => {
					staffTypes.push({
						'value' : element.title,
						'label' : element.title
					})
				});
				setStaffTypes(staffTypes);
			});
		}
	}, []);

	return (
		<DropdownMultiSelect
			label={label}
			placeholder={placeholder}
			values={value}
			allowSelectAll={allowSelectAll}
			options={staffTypes}
			selectAllLabel={selectAlllabel}
			visibleOptions={visibleOptions}
			onChange={onChange}
			error={error}
		/>
	);
};

StaffTypesDropdown.propTypes = {
	onChange: PropTypes.func.isRequired,
};

export { StaffTypesDropdown };
