import React, { useContext } from 'react';
import { ConfirmationModal, FeaturedIcon } from 'componentsV2';
import { CreateAlarmTypeContext } from '../provider';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';

export const CancelCreationModal = () => {
	const { closeModal, history, formData, setShouldPromptSaveModal, setLocationPath } = useContext(
		CreateAlarmTypeContext,
	);

	const toaster = useContext(ToasterContext);
	const modalTitle = `Are you sure you want to cancel ${
		formData.name ? `"${formData.name}"?` : 'alarm setup?'
	}`;

	const onConfirmAction = () => {
		toaster(
			`You have successfully cancelled the alarm ${
				formData.name ? `creation for "${formData.name}"` : 'setup creation'
			}`,
			Intent.NONE,
			<FeaturedIcon icon="info-sign" type="Gray" />,
		);
		setShouldPromptSaveModal(false);
		setLocationPath('');
		return history.push('/rapid-alarms/settings');
	};

	return (
		<ConfirmationModal
			isOpen={true}
			title={modalTitle}
			subtitle={
				<>
					Are you sure you want to cancel alarm setup?
					<br />
					<br />
					This alarm will not be saved.
				</>
			}
			confirmBtn={{
				title: 'Cancel it',
				onClick: onConfirmAction,
			}}
			cancelBtn={{
				title: 'Continue setup',
				onClick: closeModal,
			}}
		/>
	);
};
