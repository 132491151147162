/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { print } from 'assets/icons';
import { DateTimePicker } from 'components/DateTimePicker';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { StatusPilsner } from 'components/StatusPilsner';

import { DrillsService } from 'services/DrillsService';
import { DrillLogService } from 'services/DrillLogService';
import { safeDateToLuxon, addMonthsToDate } from 'utilities/dates';
import { CardModal } from './CardModal';

const Wrapper = styled.div`
	width: ${props => props.width || 'initial'};

	.drill-details-header {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.pilsner {
			margin-top: 5px;
		}
	}

	.date-time-picker {
		margin-top: 32px;
	}

	.radio-error {
		color: #db3737;
		font-size: 12px;
		margin-top: 2px;
		line-height: 16px;
		font-style: normal;
		font-weight: normal;
		font-family: Nunito Sans;
		margin-bottom: 0px !important;
	}

	.title-subtitle-container {
		margin-top: 16px;

		p {
			margin-bottom: 4px;
		}
	}
`;

export class ViewDrillDetails extends React.Component {
	baseState = {
		loading: true,
		scheduledDrillDate: null,
		drillLogDate: null,
		fields: null,
		showDeleteModal: false,
	};

	state = this.baseState;

	unpostLog = () => {
		const {
			selectedDrill,
			setModalToShow,
			onUnPostDrillSuccess,
		} = this.props;
		DrillLogService.unpostDrillLog(selectedDrill?.scheduleId)
			.then(data => {
				Swal.fire(
					'Success!',
					'This drill has been logged and completed!',
					'success',
				);
				setModalToShow(false);
				if (onUnPostDrillSuccess) {
					onUnPostDrillSuccess(data);
				}
			})
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: "Could not post this drill's log. Please try again.",
				}),
			);
	};

	printDrill = () => {
		const { onPrintDrillSuccess } = this.props;
		if (onPrintDrillSuccess) {
			onPrintDrillSuccess();
		}
		window.print();
	};

	fetchFields = () => {
		const { selectedDrill, setModalToShow } = this.props;

		const handleFetchError = () => {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: "Couldn't edit drill. Please try again.",
			});
			setModalToShow(null);
		};

		DrillsService.getFormFields(selectedDrill?.scheduleId)
			.then(data => {
				if (Array.isArray(data?.fields)) {
					const scheduledDrillDate =
						data?.drill?.scheduledDrillDate || null;
					this.setState({
						...this.baseState,
						loading: false,
						fields: data?.fields,
						scheduledDrillDate: scheduledDrillDate
							? safeDateToLuxon(scheduledDrillDate)
							: null,
					});
				} else {
					handleFetchError(data);
				}
			})
			.catch(handleFetchError);
	};

	componentDidMount() {
		this.fetchFields();
	}

	render() {
		const { setModalToShow, selectedDrill } = this.props;
		const { loading, scheduledDrillDate, fields } = this.state;

		if (loading) return <LoadingSpinner />;

		return (
			<>
				<CardModal
					isVisible
					setIsVisible={setModalToShow}
					title="Drill details"
					actionTitle="Unpost log"
					onActionClick={this.unpostLog}
					secondaryActionIcon={print}
					secondaryActionTitle="Print drill"
					onSecondaryActionClick={this.printDrill}
				>
					<Wrapper width="380px">
						<div className="drill-details-header">
							<h2>{selectedDrill.drillType.name}</h2>
							<StatusPilsner status={selectedDrill?.status} />
						</div>

						{fields.map((detail, index) => (
							<TitleSubtitle
								key={index}
								title={detail.name}
								subtitle={detail.value}
							/>
						))}

						<DateTimePicker
							title="Please unpost the drill log to edit this information"
							disabled
							label="Drill scheduled date"
							initialValue={scheduledDrillDate}
							timePrecision="minute"
							onDateChangeHandler={value =>
								this.setState({
									scheduledDrillDate: value,
								})
							}
							error={!scheduledDrillDate}
							errorMessage="Please enter a value"
							datePickerProps={{
								maxDate: addMonthsToDate(),
							}}
						/>
					</Wrapper>
				</CardModal>
			</>
		);
	}
}

const TitleSubtitle = ({ title, subtitle }) => (
	<div className="title-subtitle-container">
		<p>
			<strong>{title}</strong>
		</p>
		<p>{subtitle}</p>
	</div>
);

TitleSubtitle.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
};

ViewDrillDetails.propTypes = {
	setModalToShow: PropTypes.func,
	selectedDrill: PropTypes.object,
	onUnPostDrillSuccess: PropTypes.func.isRequired,
	onPrintDrillSuccess: PropTypes.func.isRequired,
};
