import React from 'react';
import styled from 'styled-components';

const Spinner = styled.div`
	display: flex;
	justify-content: space-around;
	width: 44px;
	height: 44px;
	&:after {
		content: '';
		display: block;
		width: 26px;
		height: 26px;
		margin: 1px;
		border-radius: 50%;
		border: 3px solid #3742fa;
		border-color: #3742fa transparent #3742fa transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}
	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

const SmallLoading = () => <Spinner />;

export { SmallLoading };
