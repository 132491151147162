import React from "react";
import { warning } from "assets/icons";
import NormalModal from "./NormalModal";

const UnsavedChangesModal = ({
    onConfirmAction, 
    onCancelAction,
    message = 'The changes you have made have not been saved. Do you want to continue?',
    header = 'Unsaved changes',
    confirmBtnTitle = 'Continue',
    cancelBtnTitle = 'Cancel'
    }) => {
    return (
        <NormalModal
            setModalToShow="UnsavedChangesModal"
            onConfirmAction={onConfirmAction}
            onCancelAction={onCancelAction}
            header={header}
            confirmBtnTitle={confirmBtnTitle}
            cancelBtnTitle={cancelBtnTitle}
        >
            <div className='text-center'>
                <img src={warning} height='60' width='60' />
            </div>
            <p className='mt-2'>
                {message}
            </p>
        </NormalModal>
    );
}

export default UnsavedChangesModal;