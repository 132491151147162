import React, { useState, useEffect } from 'react';
import { EmptyState, emptyDataIcon, noResultFoundIcon } from 'componentsV2';
import { CreateBinder } from './CreateBinder';
import '../css/EmptyStatuses.scss';

export const EmptyStatuses = ({
	filterationData,
	noDataAdded,
	editPermission,
	onAddBinderSubmit,
}) => {
	const [emptyStatusContent, setEmptyStatusContent] = useState({
		title: '',
		description: '',
		icon: '',
	});

	useEffect(() => {
		if (noDataAdded === true) {
			setEmptyStatusContent({
				title: 'No binders were added yet.',
				icon: emptyDataIcon,
			});
		} else {
			setEmptyStatusContent({
				title: 'No binder found',
				description:
					"Your search didn't match any binders. Please try again or add a new binder.",
				icon: noResultFoundIcon,
			});
		}
	}, [filterationData]);

	return (
		<EmptyState
			header={emptyStatusContent.title}
			description={emptyStatusContent.description}
			icon={emptyStatusContent.icon}
			className={noDataAdded ? '' : 'empty-state-border'}
			showCircles={false}
			bodyClass={'empty-state-body'}
		>
			{editPermission && noDataAdded && (
				<CreateBinder
					onSubmitFinished={onAddBinderSubmit}
					selectedSites={filterationData.buildingIds}
				/>
			)}
		</EmptyState>
	);
};
