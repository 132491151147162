import React, { Component } from 'react';
import { Intent } from '@blueprintjs/core';
import { LoadingSpinner, Button, AlertModal, Dropdown } from 'componentsV2';
import './SafetyPlanAdminstration.scss';
import ToasterModal from '../../Shared/ToasterModal';

class SafetyPlanAdministration extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inputText: '',
			outputText: '',
			escapedContent: '',
			templates: [],
			selectOptions: [],
			selectFiltered: [],
			selectedTemplate: null,
			loading: false,
			eligibleToReset: false,
			showNotification: false,
			toasterMsg: '',
			toasterIntent: '',
			showConfirmationModal: false,
		};
	}

	changeFilter = item => {
		const { selectOptions } = this.state;
		let selectedTemplate;
		if (item.key === 0) {
			this.setState({ eligibleToReset: false });
			return;
		} else {
			selectedTemplate = selectOptions.find(one => one.key === item.key);
		}
		let parsedContent = JSON.parse(selectedTemplate.content);
		let hasDuplicateIds = this.checkForDuplicateIds(parsedContent);
		this.setState({ selectedTemplate: selectedTemplate, eligibleToReset: hasDuplicateIds });
		if (!hasDuplicateIds) {
			this.showToaster('No duplicate IDs found', Intent.DANGER);
		} else {
			this.showToaster('Duplicate IDs found', Intent.SUCCESS);
		}
	};

	checkForDuplicateIds = data => {
		const parentIdSet = new Set();
		const childIdSet = new Set();

		// Check for duplicate IDs among parent objects and children
		for (const parent of data) {
			if (parentIdSet.has(parent.id)) {
				// console.error(`Duplicate parent ID found: ${parent.id}`);
				return true;
			} else {
				parentIdSet.add(parent.id);
			}

			if (Array.isArray(parent.subsections)) {
				for (const child of parent.subsections) {
					if (childIdSet.has(child.id)) {
						// console.error(`Duplicate child ID found: ${child.id}`);
						return true;
					} else {
						childIdSet.add(child.id);
					}
				}
			}
		}
		// If you reach this point, there are no duplicates
		return false;
	};

	handleProcessClick = () => {
		this.setState({ showConfirmationModal: true });
	};

	closeConfirmationModal = () => {
		this.setState({ showConfirmationModal: false });
	};

	processUpdate = () => {
		this.setState({ loading: true });

		try {
			const token = JSON.parse(localStorage.getItem('user')).jwt;
			const { selectedTemplate } = this.state;
			// prepare the content of the selected template
			let parsedContent = JSON.parse(selectedTemplate.content);
			// remove IDs duplications
			const updatedContent = this.updateIds(parsedContent);

			// Send Request to update template content with new content
			fetch(`${process.env.API_URL}/plan-templates/${selectedTemplate.key}`, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					content: JSON.stringify(updatedContent),
				}),
			})
				.then(templateRes => templateRes.json())
				.then(templateResp => {
					if (templateResp.statusCode === 200) {
						// Template content updated, then prepare the update record
						let updateBodyRequest = {
							type: 'reset',
							updatedContent: JSON.stringify({
								content: updatedContent,
							}),
						};
						// Send Request to create a new Update request of type 'reset'
						this.createUpdateRequest(updateBodyRequest)
							.then(updateRes => updateRes.json())
							.then(updateResp => {
								if (updateResp.statusCode === 201) {
									this.showToaster(
										'You have successfully regenerated the linked safety plan IDs.',
										Intent.SUCCESS,
									);
									selectedTemplate.content = JSON.stringify(updatedContent);
									this.setState({ eligibleToReset: false });
								} else {
									this.showToaster(
										'Failed to create update request!',
										Intent.DANGER,
									);
								}
							});
					} else {
						this.showToaster('Failed to update template content!', Intent.DANGER);
					}
				})
				.finally(() => {
					this.setState({ showConfirmationModal: false, loading: false });
				});
			// const escapedContent = JSON.stringify(updatedContent, null, 2).replace(/'/g, "''");
		} catch (error) {
			console.error('Error processing data:', error);
		}
	};

	createUpdateRequest = body => {
		const { selectedTemplate } = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		return fetch(`${process.env.API_URL}/plan-templates/${selectedTemplate.key}/updates`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(body),
		});
	};

	updateIds = data => {
		let highestId = 0; // Initialize the highest ID

		const idMap = new Map(); // Map to store old and new ID pairs

		// Function to generate a unique ID
		const generateUniqueId = () => {
			highestId++; // Increment the highest ID
			return highestId;
		};

		const updateObjectIds = obj => {
			const oldId = obj.id;
			const newId = generateUniqueId();
			idMap.set(oldId, newId); // Store the mapping

			const updatedSubsections = (obj.subsections || []).map(subsection => ({
				...subsection,
				id: generateUniqueId(), // Update child IDs
			}));

			return {
				...obj,
				id: newId, // Update parent ID
				subsections: updatedSubsections,
			};
		};

		const updatedData = data.map(updateObjectIds);

		return updatedData;
	};

	showToaster = (message, intent = 'success') => {
		this.setState({
			toasterMsg: message,
			toasterIntent: intent,
			showNotification: true,
		});

		setTimeout(() => {
			this.setState({ showNotification: false });
		}, 100);
	};

	loadData = () => {
		this.setState({ loading: true });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates?all=true&includeContent=true`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode !== 200) {
					const localToken = JSON.parse(localStorage.getItem('user'));
					localToken.jwt = '';
					localStorage.setItem('user', JSON.stringify(localToken));
					return window.location.reload();
				}
				const {
					data: { templates },
				} = resp;
				const selectOptions = [];
				templates.forEach(one => {
					const temp = {
						key: one.id,
						value: one.id,
						label: one.name,
						content: one.content,
					};
					selectOptions.push(temp);
				});
				return this.setState({
					loading: false,
					templates: templates,
					selectOptions: selectOptions,
					selectFiltered: selectOptions,
				});
			});
	};

	componentDidMount() {
		this.loadData();
	}

	render() {
		const {
			selectFiltered,
			selectedTemplate,
			loading,
			eligibleToReset,
			showNotification,
			toasterMsg,
			toasterIntent,
			showConfirmationModal,
		} = this.state;
		if (loading) return <LoadingSpinner />;
		return (
			<div className="safteyplan-administration-panel">
				{showNotification && <ToasterModal msg={toasterMsg} intent={toasterIntent} />}
				<div className="title-wrapper">
					<div className="d-flex">
						<h3 className="title">Safety Plan Templates</h3>
					</div>
				</div>
				<div className="text-wrapper">
					<p>
						Select which template you want to regenerate their linked safety plan IDs.
					</p>
					<p>
						This will regenerate the IDs for all safety plans linked to the selected
						template in all districts and the districts will lose their modifications
						done on pages.
					</p>
				</div>

				<div className="actions-wrapper">
					<div className="row">
						<divl className="col-md-3">
							<Dropdown
								placeholder="Select a template"
								value={selectedTemplate}
								options={selectFiltered}
								onChange={item => this.changeFilter(item)}
								hasSearch={true}
							/>
						</divl>
						<div className="col-md-2 reset-btn">
							<Button
								text="Reset Template IDs"
								onClick={this.handleProcessClick}
								disabled={!eligibleToReset}
							/>
						</div>
					</div>
				</div>

				{showConfirmationModal && (
					<AlertModal
						isOpen={true}
						onClose={this.closeConfirmationModal}
						size="medium"
						title="Are you sure you want to regenerate IDs for this template?"
						loading={loading}
						showCloseButton={true}
						alertConfirmButton={
							<Button
								text={`Yes, regenerate`}
								type="primaryDefault"
								size="md"
								onClick={this.processUpdate}
							/>
						}
						alertCancelButton={
							<Button
								text="No, go back"
								type="tertiary"
								size="md"
								onClick={this.closeConfirmationModal}
							/>
						}
						icon="warning-sign"
						iconColor="warning"
					/>
				)}
			</div>
		);
	}
}

SafetyPlanAdministration.propTypes = {};

export default SafetyPlanAdministration;
