import React, { useState } from "react";
import { eyeIcon, eyeIconOpen } from "../../assets/icons";
import { Input } from "../Input";
import './index.css';

const Password = ({ 
    label,
    placeHolder,
    onChangeHandler,
    isRequired,
    error,
    errorMessage,
    withoutLabel,
	...props
}) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <div style={{position: 'relative'}}>
            <Input
                label={label}
                placeholder={placeHolder}
                type={showPassword ? "text" : "password"}
                onChange={onChangeHandler}
                error={error}
                errorMessage={errorMessage}
                isRequired={isRequired}
                {...props}
            />
            <img src={showPassword ? eyeIconOpen : eyeIcon} className={`password-hints-icon ${withoutLabel ? 'without-label' : ''} ${error ? 'error' : ''}`} onClick={() => setShowPassword(!showPassword)}/>
        </div>
    );
}

export default Password;