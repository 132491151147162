import alarm from './alarm.png';
import bell from './bell.png';
import bio from './bio.png';
import bomb from './bomb.png';
import clipboard from './clipboard.png';
import evacuate from './evacuate.png';
import fire from './fire.png';
import gear from './gear.png';
import general from './general.png';
import hazardous from './hazardous.png';
import hold from './hold.png';
import intruder from './intruder.png';
import lockdown from './lockdown.png';
import lockout from './lockout.png';
import medical from './medical.png';
import pawprint from './pawprint.png';
import schoolbus from './schoolbus.png';
import shelter from './shelter.png';
import violence from './violence.png';
import weather from './weather.png';

export const RAPID_ICONS = {
	alarm: alarm,
	bell: bell,
	bio: bio,
	bomb: bomb,
	clipboard: clipboard,
	evacuate: evacuate,
	fire: fire,
	gear: gear,
	general: general,
	hazardous: hazardous,
	hold: hold,
	intruder: intruder,
	lockdown: lockdown,
	lockout: lockout,
	medical: medical,
	pawprint: pawprint,
	schoolbus: schoolbus,
	shelter: shelter,
	violence: violence,
	weather: weather,
};
