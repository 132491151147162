import { useState, useEffect } from 'react';

const useDebounce = (value, delay) => {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);
			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay], // Only re-call effect if value or delay changes
	);
	return debouncedValue;
};

export { useDebounce };

export const poll = async ({ fn, validate, interval, maxAttempts }) => {
	let attempts = 0;

	const executePoll = async (resolve, reject) => {
		const result = await fn();
		attempts += 1;

		if (validate(result)) {
			return resolve(result);
		} else if (maxAttempts && attempts === maxAttempts) {
			return reject(new Error('Exceeded max attempts'));
		} else {
			setTimeout(executePoll, interval, resolve, reject);
		}
	};

	return new Promise(executePoll);
};
