import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InputGroup as BPInput, Intent } from '@blueprintjs/core';
import MaskedInput from 'react-text-mask';
import { StyledRequiredField, RequiredWraper, EllipsisWraper, ErrorTextWrapper, InputWrapper, LabelText, HelpText } from 'components/Forms/shared.js';

const StyledBPInput = styled(BPInput)`
	&&& {
		input::-ms-reveal,
		input::-ms-clear {
			display: none;
		}
		margin-left: 0px;
	}
`;

const StyledMaskedInput = styled(MaskedInput)`
	&&& {
		input::-ms-reveal,
		input::-ms-clear {
			display: none;
		}
		margin-left: 0px;
		padding:0 10px;
		width:100%;
		&:focus {
            outline: none;
        }
	}
`;

const Input = ({
	disabled,
	large,
	icon,
	onChange,
	placeholder,
	small,
	value,
	intent,
	className,
	type = 'text',
	rightelement,
	readOnly,
	inputRef,
	maxLength,
	label,
	helpText,
	onBlur,
	defaultValue,
	error,
	errorMessage,
	noMarginBottom = false,
	isRequired,
	isRequiredAsterisk,
	useEllipsisWrapper,
	mask,
	...props
}) => {
	const ElementInput = (!!mask) ? StyledMaskedInput : StyledBPInput;
	return (
	<InputWrapper className="input-wrapper" noMarginBottom={noMarginBottom}>
		{label && 
		<LabelText>
			{label}
			{isRequired && <RequiredWraper>Required</RequiredWraper>}
			{isRequiredAsterisk && <StyledRequiredField>*</StyledRequiredField> }
		</LabelText>}

		<ElementInput
			type={type}
			className={`${className} bp3-input-${intent || 'primary'} input ${
				error ? 'error' : ''
			}`}
			disabled={disabled}
			large={large}
			lefticon={icon}
			onChange={onChange}
			placeholder={placeholder}
			small={small}
			value={value}
			intent={error ? Intent.DANGER : intent}
			rightelement={rightelement}
			readOnly={readOnly}
			maxLength={maxLength}
			inputRef={inputRef}
			onBlur={onBlur}
			defaultValue={defaultValue}
			mask={mask}
			{...props}
		/>
		{helpText && <HelpText>{helpText}</HelpText>}
		{error && errorMessage 
			&& (
				(useEllipsisWrapper && <ErrorTextWrapper><EllipsisWraper>{errorMessage}</EllipsisWraper></ErrorTextWrapper>) 
				|| (!useEllipsisWrapper && <ErrorTextWrapper>{errorMessage}</ErrorTextWrapper>)
			)
		}
	</InputWrapper>
	)};

Input.propTypes = {
	disabled: PropTypes.bool,
	large: PropTypes.bool,
	icon: PropTypes.string,
	intent: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	small: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string,
	type: PropTypes.string,
	rightelement: PropTypes.any,
	readOnly: PropTypes.bool,
	maxLength: PropTypes.number,
	inputRef: PropTypes.any,
	label: PropTypes.string,
	helpText: PropTypes.string,
	onBlur: PropTypes.func,
	defaultValue: PropTypes.any,
	error: PropTypes.bool,
	useEllipsisWrapper: PropTypes.bool,
	errorMessage: PropTypes.string,
	noMarginBottom: PropTypes.bool,
	mask: PropTypes.array,
	isRequiredAsterisk: PropTypes.bool,
};

export { Input };
