import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { orderBy } from 'lodash';
import { TableRow } from './tablerow';
import { Dropdown } from '../../../../components/Dropdown';
import Swal from 'sweetalert2';

const Title = styled.h3`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
	margin-left: 10px;
`;

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	color: #394b59;
	align-items: center;
	margin-top: 40px;
`;

const Table = styled.table`
	thead {
		tr {
			border-top: 0 !important;
			th {
				border: 0 !important;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				color: #738694;
			}
		}
	}
	tbody {
		tr {
			th {
				font-weight: normal;
				vertical-align: middle;
			}
			td {
				&:nth-child(2),
				&:nth-child(3) {
					text-transform: capitalize;
				}
				vertical-align: middle;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				color: #10161a;
			}
			&:last-child {
				border-bottom: 1px solid #dee2e6;
			}
		}
	}
`;

const TableWrap = styled.div`
	margin-top: 20px;
	padding: 10px;
	background: #fff;
`;

class AdminDashboard extends React.Component {
	state = {
		loading: true,
		filter: { label: 'A-Z', value: 1 },
		templates: [],
		archived: [],
		filtered: [],
	};

	loadData = () => {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates?all=true`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode !== 200) {
					const localToken = JSON.parse(localStorage.getItem('user'));
					localToken.jwt = '';
					localStorage.setItem('user', JSON.stringify(localToken));
					return window.location.reload();
				}
				const {
					data: { archived, templates },
				} = resp;
				return this.setState({
					archived,
					templates,
					filtered: templates,
					loading: false,
				});
			});
	};

	changeSorting = item => {
		const { templates } = this.state;
		let temp;
		if (item.value === 1) {
			temp = orderBy(templates, ['name'], ['asc']);
		}
		if (item.value === 2) {
			temp = orderBy(templates, ['name'], ['desc']);
		}
		if (item.value === 3) {
			temp = orderBy(templates, ['state'], ['asc']);
		}
		if (item.value === 4) {
			temp = orderBy(templates, ['state'], ['desc']);
		}
		if (item.value === 5) {
			temp = orderBy(templates, one => one.editedDate.date, ['desc']);
		}
		if (item.value === 6) {
			temp = orderBy(templates, one => one.editedDate.date, ['asc']);
		}

		return this.setState({ filtered: temp, filter: item });
	};

	archiveTemplate = (item, value) => {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates/${item.id}/archive`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				archive: value,
			}),
		})
		.then(res => res.json())
		.then(resp => {
			if (resp.statusCode === 200) {
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				}).then(() => this.loadData());
			}
			return null;
		});
	};

	removeTemplate = item => {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates/${item.id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
		})
		.then(res => res.json())
		.then(resp => {
			if (resp.statusCode === 200) {
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				}).then(() => this.loadData());
			}
			return null;
		});
	};

	componentDidMount() {
		this.loadData();
	}

	render() {
		const { history } = this.props;
		const { templates, archived, loading, filter, filtered } = this.state;
		if (loading) return <LoadingSpinner />;

		return (
			<div>
				{filtered.length > 0 && (
					<>
						<TitleWrap>
							<div className="d-flex">
								<Icon icon="clipboard" />
								<Title>Safety Plan Templates</Title>
							</div>
							<Dropdown
								placeholder="Sort by..."
								value={filter}
								onChange={item => this.changeSorting(item)}
								options={[
									{ label: 'A-Z', value: 1 },
									{ label: 'Z-A', value: 2 },
									{ label: 'States A-Z', value: 3 },
									{ label: 'States Z-A', value: 4 },
									{ label: 'Last published', value: 5 },
									{ label: 'First published', value: 6 },
								]}
							/>
						</TitleWrap>
						<TableWrap>
							<Table className="table">
								<thead>
									<tr>
										<th scope="col">Safety Plan Name</th>
										<th scope="col">Type</th>
										<th scope="col">Site</th>
										<th scope="col">State</th>
										<th scope="col">Proprietary</th>
										<th scope="col">Last Published</th>
										<th scope="col">Actions</th>
									</tr>
								</thead>
								<tbody>
									{filtered.map(item => (
										<TableRow
											key={item.id}
											item={item}
											history={history}
											archiveTemplate={this.archiveTemplate}
											removeTemplate={this.removeTemplate}
										/>
									))}
								</tbody>
							</Table>
						</TableWrap>
					</>
				)}
				{archived && archived.length > 0 && (
					<>
						<TitleWrap>
							<div className="d-flex">
							<Icon icon="archive" />
							<Title>Archived</Title>
							</div>
						</TitleWrap>
						<TableWrap>
							<Table className="table">
								<thead>
									<tr>
										<th scope="col">Safety Plan Name</th>
										<th scope="col">Type</th>
										<th scope="col">Site</th>
										<th scope="col">State</th>
										<th scope="col">Proprietary</th>
										<th scope="col">Last Published</th>
										<th scope="col">Actions</th>
									</tr>
								</thead>
								<tbody>
									{archived && archived.map(item => (
										<TableRow
											key={item.id}
											item={item}
											history={history}
											archive
											archiveTemplate={this.archiveTemplate}
										/>
									))}
								</tbody>
							</Table>
						</TableWrap>
					</>
				)}
			</div>
		);
	}
}

AdminDashboard.propTypes = {
	history: PropTypes.object,
};

export default AdminDashboard;
