import React from 'react';
import { FormModal, Input, Dropdown } from 'componentsV2';

export const CopySafetyPlanModal = ({
	showModal,
	closeModal,
	loading,
	formData,
	handleChangeTitle,
	handleChangeSite,
	handleSubmit,
}) => {

	return (
		<FormModal
			isOpen={showModal}
			onClose={closeModal}
			title="Copy safety plan"
			subtitle={formData.oldTitle}
			loading={loading}
			confirmBtn={{
				title: "Copy plan",
				onClick: handleSubmit,
				disabled: formData.isDisabled
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: closeModal
			}}
			scrollContent={false}
		>
			<div className="w-100">
				<Input
					label="New plan title"
					isRequired={true}
					width="100%"
					value={formData.title}
					onChange={handleChangeTitle}
					placeholder="New plan name"
				/>
			</div>

			<div className="w-100 mt-3">
				<Dropdown
					label="Select site"
					placeholder="Select a site"
					value={formData.site}
					options={formData.sites}
					onChange={handleChangeSite}
					isRequired={true}
				/>
			</div>

		</FormModal>
	);
};
