import React, { useContext, useEffect, useState } from 'react';

import './css/ActiveAlarmDetails.scss';
import { Header, ReunificationDetailsCard, RollCallDetailsCard } from './Components';
import { RapidAlarmService } from 'services/RapidAlarmService';
import { ToasterContext, Intent, LoadingSpinner, AlertModal, Button } from 'componentsV2';
import { formatDate } from 'utilities/dates';
import { RapidAlarmsProviders } from 'pages/RapidAlarms';
import { initializeSocket } from 'utilities/socket';
import { AlarmSubmittedClasses, AlarmDetailsCard } from '../Shared';
import { DateTime } from 'luxon';
import { RapidAlarmsContext } from '../Providers';

import { ROLLCALL_TAB } from './Components';
import { RollCallChat } from '../RollCallDetails/components';
export const ActiveAlarmDetails = ({
	match,
	location,
	history,
	isRollCallDetails = false,
	setAlarmDetails = () => {},
	setSocketEventsListeners = () => {},
	setStudentUpdateEvent = () => {},
	setStaffUpdateEvent = () => {},
	setVisitorUpdateEvent = () => {},
	setRollCallLoading,
	setRosterUpdateEvent = () => {},
	rollCallDetailsPage,
	forceAreYouSafeOpened = false,
}) => {
	const alarmId = match.params.id ? parseInt(match.params.id) : null;
	const [alarm, setAlarm] = useState(null);
	const [loading, setLoading] = useState(true);
	const toaster = useContext(ToasterContext);
	const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
	const [isRollCallStarted, setIsRollCallStarted] = useState(false);
	const [isRollCallEnded, setIsRollCallEnded] = useState(false);
	const [isReunificationStarted, setIsReunificationStarted] = useState(false);
	const [isReunificationEnded, setIsReunificationEnded] = useState(false);
	const [userStatus, setUserStatus] = useState(false);
	const [isSocketConnected, setSocketConnected] = useState(null);
	const [reunificationLocation, setReunificationLocation] = useState(null);
	const [isAlarmEnded, setIsAlarmEnded] = useState(false);
	const [forceAreYouSafeOpen, setForceAreYouSafeOpen] = useState(false);
	const [reunificationStats, setReunificationStats] = useState(null);
	const [rollCallStats, setRollCallStats] = useState(null);
	const [submittedClasses, setSubmittedClasses] = useState([]);
	const [goButtonClicked, setGoButtonClicked] = useState(ROLLCALL_TAB);

	// check whether it opened from home page or rapid alarms list page
	let openedFromHomePage = location?.state?.openedFromHomePage;
	if (rollCallDetailsPage) {
		openedFromHomePage = useContext(RapidAlarmsContext).openedFromHomePage;
	}

	useEffect(() => {
		if (!alarm || alarm?.id != alarmId) {
			getAlarmDetails(true);
		}

		if (!isSocketConnected || alarm?.id != alarmId) {
			const { initializedSocket } = initializeSocket([
				'alarms',
				`alarms/${alarmId}/staff`,
				'reunification',
				`alarms/${alarmId}/students`,
				`alarms/${alarmId}/rosters`,
				`alarms/${alarmId}/reunification/students`,
				`alarms/${alarmId}/visitors`,
				`alarms/${alarmId}/reunification/guardians`,
			]);
			setSocketConnected(true);

			initializedSocket.on('event', event => {
				handleSocketEvents(event);
			});

			return () => {
				if (initializedSocket.connected) {
					console.log('Disconnecting socket...');
					initializedSocket.disconnect();
				}
			};
		}
	}, [alarmId]);

	useEffect(() => {
		setForceAreYouSafeOpen(forceAreYouSafeOpened);
	}, [forceAreYouSafeOpened]);

	const onChangeSocketData = value => {
		setSocketEventsListeners(oldModalData => ({
			...oldModalData,
			...value,
		}));
	};

	const updateProgressBarStats = eventData => {
		setRollCallStats(eventData.rollCallStats);
		setReunificationStats(eventData.reunificationStats);
	};

	const updateSubmittedClasses = eventData => {
		setSubmittedClasses(eventData.submittedClasses);
	};

	const handleSocketEvents = event => {
		switch (event.name) {
			case 'alarmStaff:updated':
				if (event.data.id == loggedUserData.id) {
					setUserStatus(event.data.status);
					onChangeSocketData({ userStatusUpdate: event.data.status });
				}

				updateProgressBarStats(event.data);
				setStaffUpdateEvent(event);
				break;
			case 'alarms:ended':
				if (event.data.id == alarmId) {
					setIsAlarmEnded(true);
				}
				break;
			case 'rollcall:created':
				if (event.data.id == alarmId) {
					setIsRollCallStarted(true);
					onChangeSocketData({ isRollCallStarted: true });
				}
				break;
			case 'rollcall:ended':
				if (event.data.id == alarmId) {
					setIsRollCallEnded(true);
					onChangeSocketData({ isRollCallEnded: true });
				}
				break;
			case 'reunification:created':
				if (event.data.id == alarmId) {
					setIsReunificationStarted(true);
					getAlarmDetails();
					onChangeSocketData({
						isReunificationStarted: true,
					});
				}
				break;
			case 'reunification:ended':
				if (event.data.id == alarmId) {
					setIsAlarmEnded(true);
				}
				break;
			case 'alarmStudents:updated':
				if (event.room == `alarms/${alarmId}/students`) {
					updateProgressBarStats(event.data);
					setStudentUpdateEvent(event);
					setRosterUpdateEvent(event);
				}
				break;
			case 'alarmStudents:reunificationUpdated':
				if (event.room == `alarms/${alarmId}/students`) {
					updateProgressBarStats(event.data);
					setStudentUpdateEvent(event);
				}
				break;
			case 'alarmRosters:updated':
				if (event.room == `alarms/${alarmId}/rosters`) {
					updateProgressBarStats(event.data);
					updateSubmittedClasses(event.data);
					setRosterUpdateEvent(event);
				}
				break;
			case 'alarmVisitor:updated':
				if (event.room == `alarms/${alarmId}/visitors`) {
					updateProgressBarStats(event.data);
					setVisitorUpdateEvent(event);
				}
				break;
			case 'reunificationStudents:comment':
				setStudentUpdateEvent(event);
				break;
			case 'reunificationStudents:checkIn':
			case 'reunificationStudents:reunified':
			case 'reunificationStudents:checkInManual':
				updateProgressBarStats(event.data);
				setStudentUpdateEvent(event);
				break;
			case 'reunificationGuardians:checkIn':
			case 'reunificationGuardians:undoCheckIn':
			case 'reunificationGuardians:updated':
				updateProgressBarStats(event.data);
				setStudentUpdateEvent(event);
				break;
			default:
				break;
		}
	};

	const getAlarmDetails = (showLoading = false) => {
		if (showLoading) {
			setLoading(true);
		}

		RapidAlarmService.getAlarmDetails(alarmId)
			.then(response => {
				if (response.statusCode === 200) {
					if (response.data.isAlarmEnded) {
						setIsAlarmEnded(true);
					}
					setAlarm(response.data);
					setRollCallStats(response.data.rollCallStats);
					setReunificationStats(response.data.reunificationStats);
					setSubmittedClasses(response.data.submittedClasses);
					setIsRollCallStarted(response.data.isRollCallStarted);
					setIsRollCallEnded(response.data.isRollCallEnded);
					setIsReunificationStarted(response.data.isReunificationStarted);
					setIsReunificationEnded(response.data.isReunificationEnded);
					setUserStatus(response.data.loggedUserStatus);
					setAlarmDetails(response.data);
					onChangeSocketData({
						isRollCallStarted: response.data.isRollCallStarted,
						isRollCallEnded: response.data.isRollCallEnded,
						isReunificationStarted: response.data.isReunificationStarted,
						isReunificationEnded: response.data.isReunificationEnded,
						userStatusUpdate: response.data.loggedUserStatus,
					});
					setReunificationLocation(response.data.reunificationLocation);
				}
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				if (isRollCallDetails) {
					setRollCallLoading(false);
				}
			});
	};

	return (
		<div className="rapid-alarm-details-container">
			{!isRollCallDetails && <Header openedFromHomePage={openedFromHomePage} />}
			{loading && <LoadingSpinner />}
			{!loading && alarm && (
				<>
					<RapidAlarmsProviders
						history={history}
						reunificationLocation={reunificationLocation}
						setReunificationLocation={setReunificationLocation}
						openedFromHomePage={openedFromHomePage}
					>
						<AlarmDetailsCard
							alarm={alarm}
							loggedUser={loggedUserData}
							isRollCallStarted={isRollCallStarted}
							isRollCallEnded={isRollCallEnded}
							isReunificationStarted={isReunificationStarted}
							userStatus={userStatus}
							endAlarmBtn={true}
							isRollCallDetails={isRollCallDetails}
							history={history}
							toaster={toaster}
							forceAreYouSafeOpen={forceAreYouSafeOpen}
							goButtonClicked={goButtonClicked}
						/>

						{!isRollCallDetails && (
							<RollCallDetailsCard
								alarm={alarm}
								loggedUser={loggedUserData}
								isRollCallStarted={isRollCallStarted}
								isRollCallEnded={isRollCallEnded}
								isReunificationStarted={isReunificationStarted}
								history={history}
								userStatus={userStatus}
								setForceAreYouSafeOpen={setForceAreYouSafeOpen}
								rollCallStats={rollCallStats}
								setGoButtonClicked={setGoButtonClicked}
							/>
						)}

						{!isRollCallDetails && (
							<ReunificationDetailsCard
								alarm={alarm}
								loggedUser={loggedUserData}
								isReunificationStarted={isReunificationStarted}
								isRollCallStarted={isRollCallStarted}
								reunificationLocation={reunificationLocation}
								history={history}
								reunificationStats={reunificationStats}
								userStatus={userStatus}
								setForceAreYouSafeOpen={setForceAreYouSafeOpen}
								isRollCallEnded={isRollCallEnded}
								setGoButtonClicked={setGoButtonClicked}
							/>
						)}

						{!isRollCallDetails && loggedUserData.accountType == 'administrator' && (
							<div className="submitted-classes-container">
								<AlarmSubmittedClasses
									submittedClasses={submittedClasses}
									history={history}
									alarmId={alarm.id}
								/>
							</div>
						)}
					</RapidAlarmsProviders>

					{isAlarmEnded && (
						<AlertModal
							isOpen={true}
							size="medium"
							title="Alarm ended"
							subtitle={`The ${alarm.alarmType.title} initiated on ${formatDate(
								alarm.dateStarted?.date,
								DateTime.DATE_HUGE,
							)} has ended`}
							showCloseButton={false}
							alertConfirmButton={
								<Button
									text={'Ok'}
									type="primaryDefault"
									size="md"
									onClick={() => history.push('/')}
								/>
							}
							icon="info-sign"
						/>
					)}
				</>
			)}
			<RollCallChat alarm={alarm} />
		</div>
	);
};
