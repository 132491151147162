import React from 'react';
import { Intent } from '@blueprintjs/core';
import { FloorplanService } from 'services';
import { FeaturedIcon } from 'componentsV2';
import { REMOVE_MODAL } from '../../ShapeContentItemsList/ContentConstansts';

export const RemoveContentModalFn = (
	modalName,
	setLoading,
	closeModal,
	toaster,
	setModalToShow,
	dispatchModalData,
	item,
	itemType,
	shapeType,
	shapeId,
	siteId,
	loadContentData,
) => {
	const showRemoveContentModal = () => {
		dispatchModalData({
			type: 'Remove',
			title: 'Are you sure you want to remove this content?',
			description: `This will remove the content from this map ${shapeType}.`,
			confirmBtnTitle: 'Yes, remove it',
			cancelBtnTitle: 'No, go back',
		});
		setModalToShow(REMOVE_MODAL);
	};

	const handleRemoveContent = () => {
		setLoading(true);
		FloorplanService.removeShapeContent({
			siteId: siteId,
			shapeId: shapeId,
			itemId: item.id,
			type: itemType,
		})
			.then(response => {
				toaster(
					`You have successfully removed this content from this map ${shapeType}.`,
					Intent.NONE,
					<FeaturedIcon icon={'trash'} type={'Gray'} size={'md'} shape={'circleIcon'} />,
					2000,
					false,
					'map-view-page',
				);
				closeModal();
			})
			.catch(error => {
				const msg =
					error.error.fields.floorplan_ids ||
					`Something went wrong, please try again later`;
				toaster(`${msg}`, Intent.DANGER, null, 2000, false, 'map-view-page');
				setLoading(false);
			})
			.finally(() => {
				closeModal();
				loadContentData();
				setLoading(false);
			});
	};

	return {
		showRemoveContentModal,
		handleRemoveContent,
	};
};
