import React, { useEffect, useState } from "react";
import NormalModal from "components/Modals/NormalModal";
import { BuildingsService } from "services/BuildingsService";
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';

const AddRespondBuildingModal = ({
    setModalToShow,
    setShowUnsavedChangesModal,
    onConfirmAction,
    isLoading,
    values,
    userId
}) => {

    const [sitesList, setSitesList] = useState([]);
    const [selectedSites, setSelectedSites] = useState([]);
    const [error, setError] = useState(false);
    const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);

    useEffect(() => {
        BuildingsService.getUserAssignedBuildings(userId).then(response => {
            var sites = [];
            response.forEach(site => {
                if(values.some(buildingAlert => {
                    return buildingAlert.id === site.id;
                })) {
                    site.isDisabled = true;
                }
                sites.push(site)
            });
            setSitesList(sites)
        });
    }, [])

    const onConfirmActionHandler = () => {
        if(selectedSites.length == 0) {
            setError("Site is required.")
            return;
        } else { 
            setError(null);
        }
        
        var sitesIds = [];
        selectedSites.map(selectedSite =>{
            if(selectedSite.value != "*"){
                sitesIds.push(selectedSite.id);
            }
        })

        onConfirmAction(selectedSites);

        // setIsLoading(true);
    }

    const onCancelActionHandler = () => {
        if(selectedSites.length == 0) { 
            setModalToShow(false);
        } else {
            setShowUnsavedChangesModal(true);
        }
    }
    
    const selectSitesHandler = items => {
        if(items.length == 0) {
            setError("Site is required.")
            setConfirmBtnDisabled(true);
        } else { 
            setError(null);
            setConfirmBtnDisabled(false);
        }
        setSelectedSites(items)
    }

    return (
        <NormalModal
            onConfirmAction={onConfirmActionHandler}
            onCancelAction={onCancelActionHandler}
            header="Add Building"
            confirmBtnTitle="Confirm"
            cancelBtnTitle="Cancel"
            confirmBtnDisabled={confirmBtnDisabled}
            loading={isLoading}
        >
            <div className="row mb-3">
                <div className="col-md-12">
                    <div className="form-group">
                        <SitesDropdownMultiSelect
                            isRequired={true}
                            value={selectedSites}
                            visibleOptions={1}
                            allowSelectAll={true}
                            options={sitesList}
                            onChange={selectSitesHandler}
                            error={error}
                        />
                    </div>
                </div>
            </div>
        </NormalModal>
    );
}

export default AddRespondBuildingModal;