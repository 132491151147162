import React, {useState, useEffect, useReducer, useContext} from "react";
import { AlertModal, Button } from "componentsV2";
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { TemplateService } from 'services';


export const PublishModal = ({
    modalName,
    modalToShow,
    closeModal,
    sectionData,
    template
}) => {
	const [loading, setLoading] = useState(true);
	const toaster = useContext(ToasterContext);

    const modalDetailsReducer = (modalDetails, action) => {
        modalDetails = action.payload;
        return {
            title: modalDetails.title,
            subtitle: modalDetails.subtitle,
            alertConfirmButton: modalDetails.alertConfirmButton,
            alertCancelButton:modalDetails.alertCancelButton,
            hasError:modalDetails.hasError,
        }
    }

    const initialState = {
        title: ' ',
        subtitle: ' ',
        alertConfirmButton: null,
        alertCancelButton:null,
        hasError:false
    };

    const [modalDetails, dispatchModalDetails] = useReducer(modalDetailsReducer, initialState);

    useEffect(() => {
        validate()
    }, []);

    const validate = () => {
        if (sectionData.is_section === true) {
            OpenValidationSuccess()
        } else {
            checkMainSectionValidation()
        }
    }

    const openFileValidationError = () => {
        dispatchModalDetails({ payload:
            {
                ...modalDetails,
                title: sectionData.subSectionItem.title + " is missing a file upload.",
                subtitle:"Please attach a file to continue.",
                alertConfirmButton:<Button
                    text={`OK`}
                    type="primaryDefault"
                    size="md"
                    onClick={closeModal}
                />,
                hasError:true,
                alertCancelButton:null
            }
        });
        setLoading(false);
    }

    const OpenValidationSuccess = () => {
        dispatchModalDetails({ payload:
            {
                ...modalDetails,
                title: 'Are you sure you want to create an update for "'+ (sectionData.is_section ? sectionData.item.title : sectionData.subSectionItem.title) + '"?',
                subtitle:"",
                hasError:false,
                alertConfirmButton:<Button
                    text={`Yes, publish`}
                    type="primaryDefault"
                    size="md"
                    onClick={publishUpdates}
                />,
                alertCancelButton:<Button
                text="No, go back"
                type="tertiary"
                size="md"
                onClick={closeModal}
            />
            }
        });
        setLoading(false);
    }

    const checkMainSectionValidation = () => {
        TemplateService.checkPublishValidation(parseInt(template.id), sectionData.item.id)
        .then(resp =>{
            if(resp.statusCode === 200){
                if (!resp.data.published) {
                    dispatchModalDetails({ payload:
                        {
                            ...modalDetails,
                            title: "This subsection cannot be published until its main section has been published.",
                            subtitle:"Publish the main section in order to publish the subsection.",
                            alertConfirmButton:<Button
                                text={`OK`}
                                type="primaryDefault"
                                size="md"
                                onClick={closeModal}
                            />,
                            hasError:true,
                            alertCancelButton:null
                        }
                    });
                } else {
                    if (sectionData.subSectionItem.hasOwnProperty('fileSection')) {

                        if (sectionData.subSectionItem.reupload && !sectionData.subSectionItem.baseFile.id) {
                            openFileValidationError()
                        } else {
                            OpenValidationSuccess()
                        }

                    } else {
                        OpenValidationSuccess()
                    }
                }
            }
        }) .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }


    const publishUpdates = () => {
        setLoading(true);
        let updatedContent = null
        const templateBody =  JSON.stringify({
            headers: JSON.stringify(template.headers),
            content: JSON.stringify(
                template.sections,
            ),
            name: template.name,
            state: template.state,
        })

        const actualSectionId = sectionData.item.id;
        const actualSubsectionId = sectionData.subSectionItem?.id;
        const sectionIndex = sectionData.section_index;
        const subsectionIndex = sectionData.subsection_index;

        const tempcontent = (sectionData.is_section ? sectionData.item.content : sectionData.subSectionItem.content)
        let updatedTitle = "";

        if (actualSubsectionId) {

            template.sections[sectionIndex].subsections[subsectionIndex].content = tempcontent;
            updatedTitle = template.sections[sectionIndex].subsections[subsectionIndex].title;

        } else {

            template.sections[sectionIndex].content = tempcontent;
            updatedTitle = template.sections[sectionIndex].title;

        }
        if (!sectionData.is_section && sectionData.subSectionItem.hasOwnProperty('fileSection')) {

            //sectionData.subSectionItem.reupload && !sectionData.subSectionItem.baseFile.id
            if (sectionData.subSectionItem.reupload) {

                updatedContent = {
                    actualSectionId,
                    actualSubsectionId,
                    content: '',
                    is_document: true,
                    reupload: true,
                    index: actualSubsectionId ? subsectionIndex : sectionIndex
                };

                if (!!sectionData.subSectionItem.baseFile?.name) {
                    updatedContent['baseFile'] = sectionData.subSectionItem.baseFile;
                }
            } else {

                updatedContent = {
                    actualSectionId,
                    actualSubsectionId,
                    content: '',
                    is_document: true,
                    index: actualSubsectionId ? subsectionIndex : sectionIndex
                };
            }

        } else {
            updatedContent = {
                actualSectionId,
                actualSubsectionId,
                content: tempcontent,
                title: updatedTitle,
                index: actualSubsectionId ? subsectionIndex : sectionIndex
            };
        }

        const updateBody = {
            type: 'update',
            updatedContent: JSON.stringify(updatedContent),
            actualSectionId : sectionData.item.id,
            actualSubsectionId: actualSubsectionId,
        }
        TemplateService.publishUpdates( parseInt(template.id, 10), updateBody).then(resp => {

            if (resp.statusCode === 201) {

                TemplateService.updatePlanTemplate(templateBody, parseInt(template.id, 10)).then(resp =>{
                    toaster('You have successfully published "' + (sectionData.is_section ? sectionData.item.title : sectionData.subSectionItem.title) + '".', Intent.SUCCESS)
                }).catch(err => {
                    toaster("Failed To Publish!", Intent.DANGER);
                })
                .finally(() => {
                    setLoading(false);
                    closeModal();
                });

            } else {
                toaster("Failed To Publish!", Intent.DANGER);
            }
        }).catch(err => {
            toaster("Failed To Publish!", Intent.DANGER);
        })
    }
    return (
        <AlertModal
            isOpen={modalToShow === modalName}
            onClose={closeModal}
            loading={loading}
            size='medium'
            title={modalDetails.title}
            subtitle={modalDetails.subtitle}
            showCloseButton={false}
            alertConfirmButton={modalDetails.alertConfirmButton}
            alertCancelButton={modalDetails.alertCancelButton}
            icon= { modalDetails.hasError ? 'warning-sign' : null }
            iconColor= { modalDetails.hasError ? 'warning' : null }
        />
    );
}
