import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { decodeToken } from 'utilities';
import { checkPermission } from 'utilities/permissions';
import selectRoute from './selectors';
import { PanelCard } from '../shared/PanelCard';

const Page = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

class Settings extends React.Component {
	navigateToSubPage = path => {
		const { history } = this.props;
		history.push(path);
	};

	render() {
		const {
			route: { permissions },
		} = this.props;

		const localToken = JSON.parse(localStorage.getItem('user'));
		const { dist } = decodeToken(localToken.jwt);

		const TABS = [
			{
				districtAdminLevel: true,
				title: 'Manage District Drill Requirements',
				description:
					'Set which drills and how many of each you would like to require for your district.',
				path: '/manageDistrictDrillRequirements',
			},
			{
				title: 'Public Viewable Settings',
				description:
					"Choose what drills are publicly viewable and create a link to place on your schools' website",
				path: '/publicViewableSettings',
			},
			{
				title: 'Set First Responder Email Notifications',
				description:
					'Choose who is notified when drills are re-scheduled, scheduled, and/or completed',
				path: '/emailPreferences',
			},
			{
				title: 'Emergency Management Office Email Recipients',
				description: 'Add Emergency Management Office Email Recipients',
				path: '/officeEmailRecipients',
			},
		];

		return (
			<Page>
				{TABS.map(item => {
					// check if they have permission drilllogs_set_reqs for district-level building
					if (
						item.districtAdminLevel &&
						!checkPermission(
							'drilllogs_set_reqs',
							permissions,
							dist,
						)
					) {
						return null;
					}
					return (
						<PanelCard
							title={item.title}
							description={item.description}
							onClick={() => this.navigateToSubPage(item.path)}
						/>
					);
				})}
			</Page>
		);
	}
}

Settings.propTypes = {
	history: PropTypes.object,
	route: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(Settings);
