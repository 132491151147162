import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem} from '@blueprintjs/core';
import {LoadingSpinner} from 'components/LoadingSpinner';
import './index.scss';
import TemplateDetails from '../AddTemplate/templatedetails';

class CloneTemplate extends React.Component {
  state = {
    template: {
      name: '',
      type: null,
      state: null,
      site: null,
      isProprietary: false,
    },
    selectedOptions: [],
    building: {key:null},
    clients: [],
    loading: true,
    history,
  };

  getData = () => {
    const {
      match: {
        params: {id, type},
      },
    } = this.props;
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    let url = `${process.env.API_URL}/district-templates/${id}`;
    if (type === 'core') {
      url = `${process.env.API_URL}/plan-templates/${id}`;
    }
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.json())
      .then(resp => {
        if (resp.statusCode === 200) {
          if (type !== 'core') { //plan
            fetch(
              `${
                process.env.API_URL
              }/users/myself?includeUserBuildings=true`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              },
            )
              .then(rs => rs.json())
              .then(rsp => {
                const selectedOptions = [];
                let selectedBuilding = {};
                rsp.data.buildings.forEach(one => {
                  const temp = {
                    key: one.id,
                    value: one.name,
                  };
                  selectedOptions.push(temp);
                });
                const {template} = this.state;
                if (type !== 'plan') { //core
                  template.name = resp.data.template.name;
                  template.state = resp.data.template.state;
                  template.headers = JSON.parse(resp.data.template.headers);
                  template.content = JSON.parse(
                    resp.data.template.content,
                  );
                } else {
                  template.name = resp.data.name;
                  template.headers = JSON.parse(resp.data.headers);
                  template.content = JSON.parse(
                    resp.data.content,
                  );
                  if (selectedOptions.length == 1) {
                    selectedBuilding = selectedOptions[0];
                  }
                }
                template.data = {id};
                this.setState({
                  loading: false,
                  template,
                  selectedOptions,
                  building: selectedBuilding,
                  type: type
                });
              });
          } else {  //core Template
            fetch(`${process.env.API_URL}/clients`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            })
              .then(rss => rss.json())
              .then(rspp => {
                const temp = rspp.data;
                temp.map(item => {
                  if (!item.name) return null;
                  const tmp = item;
                  tmp.searchString = `${
                    item.id
                  } ${item.name.toLowerCase()}`;
                  return tmp;
                });
                const {template} = this.state;
                if (type !== 'plan') {
                  template.name = resp.data.template.name;
                  template.state = resp.data.template.state;
                  template.headers = JSON.parse(resp.data.template.headers);
                  template.content = JSON.parse(
                    resp.data.template.content,
                  );
                  template.isProprietary =
                    resp.data.template.isProprietary;
                  const districts =
                    resp.data.template.districts || [];
                  let formattedDistricts = [];
                  if (
                    districts.length > 0 &&
                    template.isProprietary
                  ) {
                    // check this condition
                    formattedDistricts = districts.map(
                      district => ({
                        id: district.districtId,
                        name: district.name,
                      }),
                    );
                  }
                  template.districts = formattedDistricts;
                }
                template.data = {id};
                return this.setState({
                  template,
                  clients: temp,
                  districts: template.isProprietary
                    ? template.districts
                    : [],
                  loading: false,
                  building: null,
                  type: type
                });
              });
          }
        }
        return null;
      });
  };

  updateFields = (key, value) => {
    const {template} = this.state;
    template[key] = value;
    return this.setState({template});
  };
  makeBuildings = item => (
    <MenuItem
      key={`building-${item.key}`}
      onClick={() => this.setState({building: item})}
      text={item.value}
    />
  );

  handleChange = () => {
    const {
      template: {isProprietary},
    } = this.state;
    return this.updateFields('isProprietary', !isProprietary);
  };

  setClient = val => this.setState({districts: val});

  componentDidMount() {
    return this.getData();
  }

  render() {
    const {
      template,
      template: {name, state, isProprietary, districts},
      loading,
      selectedOptions,
      clients,
      building,
      type
    } = this.state;

    const {history} = this.props;
    if (loading) return <LoadingSpinner/>;
    return (
      <div className="add-template-wrapper">
        <TemplateDetails
          template={template}
          updateFields={this.updateFields}
          history={history}
          clients={clients}
          districts={districts}
          setClient={this.setClient}
          title="Create New Template"
          submitText="Clone Plan"
          action="clone"
          selectedOptions={selectedOptions}
          building={building}
          makeBuildings={this.makeBuildings}
          type={type}
        />
      </div>
    );
  }
}

CloneTemplate.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default CloneTemplate;
