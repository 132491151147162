import React, { useEffect, useReducer, useRef, useState } from 'react';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { NoResultFound } from 'components/NoResultFound';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { SearchBar } from 'components/SearchBar';
import { NoResultFoundIcon, noContactsAdded } from 'assets/icons';
import { ContactService } from 'services/ContactService';
import './css/index.scss';
import { Icon } from '@blueprintjs/core';
import { checkPermission } from 'utilities/permissions';
import { NavLink } from 'react-router-dom';
import ExistingContactsModal from './Components/ExistingContactsModal';
import { CallListService } from 'services/CallListService';
import { useDispatch, useSelector } from 'react-redux';
import BulkContactsModal from './Components/BulkContactsModal';
import AddContactButton from './Components/AddContactButton';
import Table from './Components/Table';
import ExternalContactModal from './Components/ExternalContactModal';
import DropdownWithFilterComponent from 'components/DropdownWithFilterComponent';
import GridExistingContactTable from './Components/GridExistingContactTable';
import SortByDropdown from 'components/SortByDropdown';
import { PageHeader } from 'components/PageHeader';
import { SORTING_DIR } from 'utilities/constants';

const filterationDataReducer = (filterationData, action) => {
	switch (action.type) {
		case 'selectedSites':
			filterationData.selectedSites = action.payload;
			filterationData.page = 1;
			break;
		case 'combineSearch':
			filterationData.combineSearch = action.payload;
			filterationData.page = 1;
			filterationData.perPage = filterationData.perPage;
			break;
		default:
			filterationData[action.type] = action.payload;
			break;
	}
	return {
		page: filterationData.page,
		perPage: filterationData.perPage,
		selectedSites: filterationData.selectedSites,
		combineSearch: filterationData.combineSearch,
		sortKey: filterationData.sortKey,
		sortDir: filterationData.sortDir,
	};
};

const Contacts = ({ match, history, location }) => {
	const [loading, setLoading] = useState(true);
	const [modalToShow, setModalToShow] = useState(false);
	const [contactsList, setContactsList] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [callList, setCallList] = useState([]);
	const [callListsForFilter, setCallListFilter] = useState([]);
	const [selectedCallList, setSelectedCallList] = useState(null);
	const [selectedContactCallList, setSelectedContactCallList] = useState(null);
	const [emptyContacts, setEmptyContacts] = useState(false);
	const [editPermission, setEditPermission] = useState(false);
	const [userEditPermission, setUserEditPermission] = useState(false);
	const [userViewPermission, setUserViewPermission] = useState(false);
	const [permissionSet, updatePermissionSet] = useState(false);
	const [lodinggrid, setLoadingGrid] = useState(false);
	const [contactGridList, setContactGridList] = useState([]);
	const [contactGridTotalRecords, setGridContactTotalRecord] = useState(0);
	const pageNumber = useRef(1);
	const endOfList = useRef(false);
	const [noContactsFound, setNoContactsFound] = useState(false);

	const prevoulscallListViewType = useSelector(state => state.route.callListViewType);

	const [filterationData, dispatchFilterationData] = useReducer(filterationDataReducer, {
		page: 1,
		perPage: prevoulscallListViewType === 'grid' ? 30 : 10,
		selectedSites: [],
		combineSearch: '',
		sortKey: 'name',
		sortDir: SORTING_DIR.ASC,
	});

	const [callListId, setCallListId] = useState(+match.params.id);

	const previousSitesSelection = useSelector(state => state.route.callListSites);

	const [listView, setListView] = useState(prevoulscallListViewType === 'grid' ? false : true);
	const dispatch = useDispatch();

	const fetchContacts = () => {
		if (!listView && contactGridTotalRecords == 0) {
			setLoadingGrid(true);
		}

		ContactService.getAll(callListId, filterationData)
			.then(resp => {
				if (resp.statusCode === 200) {
					if (resp._metadata.totalRecords === 0) {
						//empty response
						if (filterationData.combineSearch) {
							setNoContactsFound(true);
						} else {
							setNoContactsFound(false);
							setEmptyContacts(true);
						}
					} else {
						setNoContactsFound(false);
						setEmptyContacts(false);
					}

					if (listView === true) {
						setTotalRows(resp._metadata.totalRecords);
						setContactsList(resp.data);
					} else {
						if (contactGridTotalRecords == 0) {
							setGridContactTotalRecord(resp._metadata.totalRecords);
							setLoadingGrid(false);
						}

						if (resp.data.length === 0) {
							endOfList.current = true;
							pageNumber.current = 1;
						} else {
							endOfList.current = false;
						}

						if (resp._metadata.currentPage === 1) {
							setContactGridList(resp.data);
							pageNumber.current = 2;
						} else {
							setContactGridList(oldGrid => [...oldGrid, ...resp.data]);
						}
					}
				}
				setLoading(false);
			})
			.catch(() => setLoading(false));
	};

	useEffect(async () => {
		var sites = [];
		const formData = location?.state;
		if (previousSitesSelection !== undefined && formData?.formData?.callListName == undefined) {
			dispatchFilterationData({
				type: 'selectedSites',
				payload: previousSitesSelection,
			});
			await previousSitesSelection.map(site => {
				sites.push(site.value);
			});
		} else {
			await CallListService.getById({ id: callListId }).then(resp => {
				if (resp.statusCode === 200) {
					dispatchFilterationData({
						type: 'selectedSites',
						payload: [
							{
								label: resp.data.site,
								value: resp.data.site_id,
								id: resp.data.site_id,
							},
						],
					});
					sites = [resp.data.site_id];
				}
			});
		}
		CallListService.getAll({
			selectedBuilders: sites,
		})
			.then(resp => {
				if (resp.statusCode === 200) {
					var callLists = [];
					var callListsForFilter = [];
					resp.data.map(item => {
						if (item.id === callListId) {
							setSelectedContactCallList({
								label: `${item.title}`,
								value: item.id,
								site_id: item.site_id,
								site_name: item.site,
							});
							setSelectedCallList({
								label: `${item.title} (${item.site})`,
								value: item.id,
								site_id: item.site_id,
								site_name: item.site,
							});
						}
						callLists.push({
							label: `${item.title} (${item.site})`,
							value: item.id,
							site_id: item.site_id,
						});
						callListsForFilter.push({
							value: `${item.title} (${item.site})`,
							key: item.id,
							site_id: item.site_id,
						});
					});
					setCallList(callLists);
					setCallListFilter(callListsForFilter);
				}
			})
			.then(() => {
				fetchContacts();
			});
	}, [callListId]);

	useEffect(() => {
		fetchContacts();
	}, [filterationData]);

	const onCloseModal = () => {
		setLoading(false);
		setModalToShow(false);
	};

	const onCallListChange = value => {
		const callListChangedObject = {
			label: value.value,
			value: value.key,
			site_id: value.site_id,
		};
		setCallListId(callListChangedObject.value);
		setSelectedCallList(callListChangedObject);
	};

	const loggedUserPermissions = useSelector(state => state.route.permissions);

	const setUserPermissionsPerSite = siteId => {
		setEditPermission(checkPermission('call_list_edit', loggedUserPermissions, siteId));
		setUserEditPermission(checkPermission('user_edit', loggedUserPermissions, siteId));
		setUserViewPermission(checkPermission('user_view', loggedUserPermissions, siteId));
		updatePermissionSet(true);
	};

	useEffect(() => {
		if (selectedCallList?.site_id) {
			setUserPermissionsPerSite(selectedCallList?.site_id);
		}
	}, [selectedCallList]);

	const handlePerPageChange = perPage => {
		dispatchFilterationData({
			type: 'perPage',
			payload: perPage,
		});
	};

	const handlePageChange = () => {
		dispatchFilterationData({
			type: 'page',
			payload: 1,
		});
	};
	const onListViewClickHandler = () => {
		setListView(true);
		dispatch({ type: 'SET_CALL_LIST_VIEW_TYPE', payload: 'list' });
		handlePerPageChange(10);
		if (listView === false) {
			dispatchFilterationData({
				type: 'page',
				payload: 1,
			});
		}
	};

	const onGridViewClickHandler = () => {
		setListView(false);
		dispatch({ type: 'SET_CALL_LIST_VIEW_TYPE', payload: 'grid' });
		handlePerPageChange(30);
	};

	const goBackToCallLists = () => {
		history.push('/call-list');
	};

	const sortList = [
		{
			label: 'Ascending',
			value: SORTING_DIR.ASC,
			type: 'sortDir',
			icon: 'arrow-down',
		},
		{
			label: 'Descending',
			value: SORTING_DIR.DESC,
			type: 'sortDir',
			icon: 'arrow-up',
			addBreakLine: true,
		},
		{
			label: 'Name',
			value: 'name',
			type: 'sortKey',
			icon: 'tick',
		},
		{
			label: 'Staff Title',
			value: 'staffTitle',
			type: 'sortKey',
			icon: 'tick',
		},
		{
			label: 'Role Type',
			value: 'roles',
			type: 'sortKey',
			icon: 'tick',
		},
	];

	const onSortItemHandler = item => {
		switch (item.type) {
			case 'sortKey':
				dispatchFilterationData({ type: 'sortKey', payload: item.value });
				break;
			case 'sortDir':
				dispatchFilterationData({ type: 'sortDir', payload: item.value });
				break;
		}
		dispatchFilterationData({ type: 'page', payload: 1 });
	};

	return (
		<div className="container-fluid contacts-wrapper">
			{modalToShow === 'ExternalContactModal' && (
				<ExternalContactModal
					onCloseModal={onCloseModal}
					setModalToShow={setModalToShow}
					updateDataCallBack={fetchContacts}
					selectedSites={filterationData.selectedSites}
					selectedCallList={selectedContactCallList}
					mode="add"
				/>
			)}
			{modalToShow === 'CallListBulkContactsModal' && (
				<BulkContactsModal
					onCloseModal={onCloseModal}
					setModalToShow={setModalToShow}
					contactsList={filterationData}
					selectedSites={filterationData.selectedSites}
					callListId={match.params.id}
					selectedCallList={selectedContactCallList}
					fetchContacts={fetchContacts}
				/>
			)}
			{modalToShow === 'CallListExistingContactsModal' && (
				<ExistingContactsModal
					onCloseModal={onCloseModal}
					setModalToShow={setModalToShow}
					contactsList={contactsList}
					selectedSites={filterationData.selectedSites}
					callListId={match.params.id}
					selectedCallList={selectedContactCallList}
					fetchMainContacts={fetchContacts}
				/>
			)}
			<div className="row">
				<div className="col-12">
					<PageHeader title="Contacts" onBackBtnClick={goBackToCallLists} />
				</div>
				{loading && <LoadingSpinner />}
				{!loading && (
					<>
						<div className="col-md-5 d-flex">
							<div className="sites-ddl-container">
								<SitesDropdownMultiSelect
									labelState={false}
									value={filterationData.selectedSites}
									onChange={sites =>
										dispatchFilterationData({
											type: 'selectedSites',
											payload: sites,
										})
									}
									allowSelectAll={false}
									disabled
									visibleOptions={1}
									className="sites-dropdown"
								/>
							</div>
							<div className="annotation-icon-container">
								<NavLink exact to="/call-list" className="annotation-icon">
									<Icon icon="annotation" className="annotation-icon" />
								</NavLink>
							</div>
						</div>
						<div className="col-md-2" />
						<div className="col-md-5 search-bar-input">
							<SearchBar
								placeholder="Search contacts"
								onSearch={combineSearch => {
									dispatchFilterationData({
										type: 'combineSearch',
										payload: combineSearch,
									});
									if (!listView) {
										if (combineSearch != '') {
											handlePerPageChange(contactGridTotalRecords);
										} else {
											handlePerPageChange(30);
										}
										handlePageChange();
									}
								}}
								value={filterationData.combineSearch}
							/>
						</div>
						<div className="col-12 row-break-space CallList_Select__Container">
							<DropdownWithFilterComponent
								label="Call List"
								items={callListsForFilter}
								selectedItem={selectedCallList}
								onChange={onCallListChange}
								placeholder="Please select a site"
								popoverClassName="CallList_Select__Popover"
							/>
							<div
								className={
									'sort-actions-row ' +
									(listView ? 'justify-content-end' : 'justify-content-between')
								}
							>
								{!listView && (
									<SortByDropdown
										sortList={sortList}
										sortKey={filterationData.sortKey}
										sortDir={filterationData.sortDir}
										onSelectItemHandler={onSortItemHandler}
									/>
								)}
								{editPermission && !emptyContacts && (
									<div className="actions-container">
										<AddContactButton setModalToShow={setModalToShow} />
									</div>
								)}
							</div>
						</div>
						<div className="col-md-12 row-break-space">
							<hr className="page-hr" />
						</div>
						{emptyContacts && (
							<div className="col-md-12">
								<NoResultFound
									header="No contacts have been added yet"
									icon={noContactsAdded}
								>
									{editPermission && (
										<AddContactButton setModalToShow={setModalToShow} />
									)}
								</NoResultFound>
							</div>
						)}
						{!emptyContacts && (
							<>
								{noContactsFound === true ? (
									<div className="col-md-12">
										<NoResultFound
											header="No contacts were found"
											icon={NoResultFoundIcon}
										/>
									</div>
								) : selectedCallList?.site_id && permissionSet ? (
									listView === true ? (
										<div className="col-md-12">
											<Table
												contactsList={contactsList}
												filterationData={filterationData}
												dispatchFilterationData={dispatchFilterationData}
												totalRows={totalRows}
												selectedCallList={selectedCallList}
												callListId={callListId}
												updateDataCallBack={fetchContacts}
												userEditPermission={userEditPermission}
												userViewPermission={userViewPermission}
												callListEditPermission={editPermission}
											/>
										</div>
									) : (
										<GridExistingContactTable
											filterationContactData={filterationData}
											dispatchFilterationContactData={dispatchFilterationData}
											listView={listView}
											callListId={callListId}
											selectedCallList={selectedCallList}
											setCallListId={setCallListId}
											userEditPermission={userEditPermission}
											userViewPermission={userViewPermission}
											callListEditPermission={editPermission}
											updateDataCallBack={fetchContacts}
											setLoadingGrid={setLoadingGrid}
											lodinggrid={lodinggrid}
											contactGridList={contactGridList}
											endOfList={endOfList}
											pageNumber={pageNumber}
										/>
									)
								) : null}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Contacts;
