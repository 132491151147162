import React from 'react';
import { Button, Tooltip } from 'componentsV2';

export const VariableItem = ({
    item,
    type,
    handleInsertVariable,
    handleEditVariable = () => { },
    confirmDeleteVariable = () => { },
}) => {

    const itemContent = JSON.parse(item.content);

    return (
        <div className='variable-item-wrapper'>
            <div className="variable-item-content">
                <div className="variable-item-title">
                    {itemContent.name}
                </div>
                <div className="variable-item-description">
                    {type == "common" ? itemContent.type : itemContent.typeAnnotation}
                </div>
            </div>
            <div className="variable-item-actions">
                <Button
                    text="Insert"
                    type="primaryDefault"
                    intent="default"
                    size="sm"
                    onClick={() => handleInsertVariable(item)}
                />

                {type == "custom" && (
                    <>
                        <Tooltip
                            tooltipTitle="Edit"
                            theme={'light'}
                        >
                            <Button
                                className="btn-border-primary"
                                type="tertiary"
                                intent="default"
                                size="sm"
                                minimal
                                rightIcon="edit"
                                onClick={() => handleEditVariable(item)}
                            />
                        </Tooltip>

                        <Tooltip
                            tooltipTitle="Delete"
                            theme={'light'}
                        >
                            <Button
                                type="tertiary"
                                intent="default"
                                size="sm"
                                minimal
                                rightIcon="trash"
                                onClick={() => confirmDeleteVariable(item)}
                            />
                        </Tooltip>
                    </>
                )}
            </div>
        </div>
    )
}
