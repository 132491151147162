import React, { useContext, useState } from 'react';
import ActionsButton from 'components/ActionsButton';
import { Icon, Intent } from '@blueprintjs/core';
import { USER_ACCESS_TYPES } from 'utilities/constants';
import { Button } from 'components/Button';
import { NavLink } from 'react-router-dom';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { ContactService } from 'services/ContactService';
import { ToasterContext } from 'pages/App';
import ExternalContactModal from './ExternalContactModal';
import PropTypes from 'prop-types';

const ContactsListActionsButtons = ({
	userEditPermission,
	userViewPermission,
	callListEditPermission,
	row,
	updateDataCallBack,
	callListId,
	filterationData,
}) => {
	const toaster = useContext(ToasterContext);
	//view permissions per contact/user
	const contactHasViewPermission =
		row.accessType === USER_ACCESS_TYPES.OUTSIDE_USER && !callListEditPermission;
	const userHasViewPermission =
		row.accessType !== USER_ACCESS_TYPES.OUTSIDE_USER &&
		!userEditPermission &&
		userViewPermission;
	//edit permissions per contact/user
	const contactHasEditPermission =
		row.accessType === USER_ACCESS_TYPES.OUTSIDE_USER && callListEditPermission;
	const userHasEditPermission =
		row.accessType !== USER_ACCESS_TYPES.OUTSIDE_USER && userEditPermission;

	const [mode, setMode] = useState('view');
	const [contactID, setContactID] = useState(null);

	const handleViewEditClick = (mode, contactID) => {
		setContactID(contactID);
		setMode(mode);
		setModalToShow('ExternalContactModal');
	};
	const [modalToShow, setModalToShow] = useState(false);

	const onCloseModal = () => {
		setModalLoading(false);
		setModalToShow(false);
	};

	const [modalLoading, setModalLoading] = useState(false);

	const deleteContact = () => {
		setModalLoading(true);
		const params = {
			call_list_id: callListId,
			access_type: row.accessType,
			user_id: contactID,
		};
		ContactService.delete(params)
			.then(res => {
				if (res.statusCode === 200) {
					toaster('Contact successfully deleted', Intent.SUCCESS);
					setModalLoading(false);
					setModalToShow(false);
					updateDataCallBack();
				} else {
					toaster('Something went wrong', Intent.DANGER);
					setModalLoading(false);
				}
			})
			.catch(err => {
				setModalLoading(false);
				toaster(err.error.description, Intent.DANGER);
			});
	};

	return (
		<>
			{modalToShow === 'ExternalContactModal' && (
				<ExternalContactModal
					onCloseModal={onCloseModal}
					setModalToShow={setModalToShow}
					updateDataCallBack={updateDataCallBack}
					mode={mode}
					contactID={contactID}
					selectedSites={filterationData.selectedSites}
				/>
			)}
			{modalToShow === 'deleteContact' && (
				<ConfirmationModal
					title="Delete contact"
					text={[
						<>
							<p>
								Deleting this contact will remove them from the call list. This
								action can not be undone.
							</p>
						</>,
					]}
					confirmBtnTxt="Delete"
					closeModal={() => setModalToShow(false)}
					submitModel={deleteContact}
					loading={modalLoading}
					danger
				/>
			)}
			<ActionsButton>
				{(contactHasViewPermission || userHasViewPermission) &&
					(row.accessType == USER_ACCESS_TYPES.OUTSIDE_USER ? (
						<NavLink
							to="#"
							className="bp3-button bp3-minimal w-100 justify-content-start"
						>
							<Icon icon="eye-open" minimal />
							<span
								className="bp3-button-text"
								onClick={() => handleViewEditClick('view', row.id)}
							>
								View
							</span>
						</NavLink>
					) : (
						<NavLink
							exact
							to={{
								pathname: `/users/view/${row.id}`,
								state: { callList: callListId },
							}}
							className="bp3-button bp3-minimal w-100 justify-content-start"
						>
							<Icon icon="eye-open" minimal />
							<span className="bp3-button-text">View</span>
						</NavLink>
					))}
				{(contactHasEditPermission || userHasEditPermission) && (
					<>
						{row.accessType == USER_ACCESS_TYPES.OUTSIDE_USER ? (
							<Button
								icon="edit"
								minimal
								text="Edit"
								className="w-100 justify-content-start"
								onClick={() => handleViewEditClick('edit', row.id)}
							/>
						) : (
							<NavLink
								exact
								to={{
									pathname: `/users/view/${row.id}`,
									state: { callList: callListId },
								}}
								className="bp3-button bp3-minimal w-100 justify-content-start"
							>
								<Icon icon="edit" minimal />
								<span className="bp3-button-text">Edit</span>
							</NavLink>
						)}

						<hr />
						<Button
							icon="trash"
							intent="Danger"
							minimal
							text="Delete"
							className="w-100 justify-content-start"
							onClick={() => {
								setContactID(row.id);
								setModalToShow('deleteContact');
							}}
						/>
					</>
				)}
			</ActionsButton>
		</>
	);
};
ContactsListActionsButtons.propTypes = {
	userEditPermission: PropTypes.bool,
	userViewPermission: PropTypes.bool,
	row: PropTypes.object.isRequired,
	updateDataCallBack: PropTypes.func.isRequired,
	callListId: PropTypes.number,
};

export default ContactsListActionsButtons;
