import React from 'react';
import { PropTypes } from 'prop-types';
import { ActionsButton, Button } from 'componentsV2';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';

export const TableActions = ({ user, openModal }) => {
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('user_edit', permissions, user.site_id);

	return (
		<ActionsButton>
			{editPermission && (
				<Button
					text="Edit"
					icon="edit"
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => {
						openModal('NewUserResponderModal', {
							isEdit: true,
							user: {
								id: user.id,
								firstName: user.person.firstName,
								lastName: user.person.lastName,
								email: user.person.emailAddress,
								mobilePhone: user.person.phoneNumber,
								title: user.person.title,
								password: '',
								confirmPassword: '',
							},
						});
					}}
				/>
			)}
			{editPermission && user.person.status === 1 ? (
				<Button
					text="Disable"
					icon="disable"
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => {
						openModal('EnableDisableUser', {
							userId: user.id,
							title: 'Disable user',
							subtitle: 'Are you sure you want to disable this user?',
							confirmTitle: 'Yes, disable',
							icon: 'disable',
							iconColor: 'warning',
						});
					}}
				/>
			) : (
				<Button
					text="Enable"
					icon="power"
					type="ghostDefault"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => {
						openModal('EnableDisableUser', {
							userId: user.id,
							title: 'Enable user',
							subtitle: 'Are you sure you want to enable this user?',
							confirmTitle: 'Yes, enable',
							icon: 'power',
							iconColor: 'primary',
						});
					}}
				/>
			)}
		</ActionsButton>
	);
};

TableActions.propTypes = {
	user: PropTypes.object,
	openModal: PropTypes.func,
};
