import React from 'react';
import PropTypes from 'prop-types';
import AddPlan from './AddPlan';
import TemplateDetails from './templatedetails';
import './css/index.scss';

class AddTemplate extends React.Component {
	state = {
		step: 1,
		template: {
			name: '',
			type: null,
			state: null,
			site: null,
			isProprietary: false,
		},
		clients: [],
		districts: [],
	};

	updateFields = (key, value) => {
		const { template } = this.state;
		template[key] = value;
		return this.setState({ template });
	};

	getData = () => {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/clients`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				const temp = resp.data;
				temp.map(item => {
					if (!item.name) return null;
					const tmp = item;
					tmp.searchString = `${item.id} ${item.name.toLowerCase()}`;
					return tmp;
				});
				this.setState({ clients: temp });
			});
	};

	setClient = val => {
		this.setState({ districts: val });
	};

	componentDidMount() {
		this.getData();
	}

	render() {
		const { history } = this.props;
		const { step, template, clients, districts } = this.state;
		if (step === 1) {
			return (
				<div className="add-template-wrapper">
					<TemplateDetails
						template={template}
						updateFields={this.updateFields}
						history={history}
						clients={clients}
						setClient={this.setClient}
						districts={districts}
						title="Create New Template"
						submitText="Create plan"
            type="core"
					/>
				</div>
			);
		}
		return <AddPlan />;
	}
}

AddTemplate.propTypes = {
	history: PropTypes.object,
};

export default AddTemplate;
