import React, { useEffect, useState } from 'react';
import { Modal, ModalFooter, Button } from 'componentsV2';
import './css/SubmitClassModal.scss';

export const SubmitClassModal = ({
	setShowSubmitClassModal,
	selectedClassElement,
	markAllAsAccounted = false,
	submitClassOnConfirmHandler,
	modalLoading,
	onCloseModal,
}) => {
	const initialState = {
		title: ' ',
	};
	const [modalDetails, setModalDetails] = useState(initialState);

	useEffect(() => {
		let modalTitle = '';

		if (markAllAsAccounted) {
			modalTitle = `Are you sure you want to  “Mark all as accounted for”?`;
		} else {
			modalTitle = `Are you sure you want to submit “${selectedClassElement?.courseName}”?`;
		}
		setModalDetails({
			...modalDetails,
			title: modalTitle,
		});
	}, []);
	const getFooter = () => {
		const confirmButtonElement = (
			<Button
				text={'Confirm'}
				intent="default"
				onClick={() => {
					submitClassOnConfirmHandler(markAllAsAccounted, selectedClassElement?.id);
				}}
			/>
		);

		const cancelButtonElement = (
			<Button text="Cancel" type="tertiary" intent="default" onClick={onCloseModal} />
		);

		return (
			<ModalFooter confirmButton={confirmButtonElement} cancelButton={cancelButtonElement} />
		);
	};
	return (
		<Modal
			isOpen={true}
			onClose={() => setShowSubmitClassModal(false)}
			title={modalDetails.title}
			size="medium"
			showCloseButton={false}
			customFooter={getFooter()}
			loading={modalLoading}
		>
			<div className="SubmitClass__Wrapper">
				{!markAllAsAccounted && (
					<div>
						<p className="submitClassText supportingText">
							By clicking "Confirm", you will submit the statuses for all students in
							this class.
						</p>
						<p className="submitClassText supportingText">Please ensure:</p>
						<ul>
							<li>All student statuses are accurately reviewed and updated.</li>
							<li>
								This submission will be recorded as the official status report for
								this class.
							</li>
							<li>
								You cannot reverse a submission, however, you're free to make
								changes and submit again later.
							</li>
						</ul>
						<p className="submitClassText confirmText">
							Are you ready to submit all student statuses?
						</p>
					</div>
				)}

				{markAllAsAccounted && (
					<div>
						<p className="submitClassText supportingText">
							You are about to mark all students as accounted for. This action will
							override the current status of ALL students and change them to
							"Accounted For".
						</p>
						<p className="submitClassText supportingText">Please note:</p>
						<ul>
							<li>This action cannot be undone.</li>
							<li>
								You can modify the status for individual students after this change.
							</li>
						</ul>
						<p className="submitClassText confirmText">
							Are you sure you want to continue?
						</p>
					</div>
				)}
			</div>
		</Modal>
	);
};
