import { FloorplanService } from 'services';
import { useState, useContext } from 'react';
import { Intent } from '@blueprintjs/core';
import { ToasterContext } from 'pages/App';

export const ViewUpdateNoteModalFn = (setLoading, closeModal, setNote, reloadContent) => {
	const [noteSaveBtnDisabled, setNoteSaveBtnDisabled] = useState(true);
	const toaster = useContext(ToasterContext);
	const [oldNote, setOldNote] = useState({});
	const [newNote, setNewNote] = useState(null);

	const noteUpdateChangeHandler = (e, oldNote) => {
		let text = e.target.value;

		setNote(text);
		setNewNote(text);
		setOldNote(oldNote);

		if (text.trim() == '' || text == oldNote.note) {
			setNoteSaveBtnDisabled(true);
		} else {
			setNoteSaveBtnDisabled(false);
		}
	};

	const updateNoteHandler = () => {
		setLoading(true);

		FloorplanService.UpdateShapeNote(oldNote.id, { note: newNote })
			.then(res => {
				if (res.statusCode == 200) {
					toaster(
						'You have successfully edited a note.',
						Intent.SUCCESS,
						null,
						2000,
						false,
						'map-view-page',
					);
					closeModal();
					reloadContent();
				}
			})
			.catch(error => {
				toaster(
					'Something went wrong, please try again.',
					Intent.DANGER,
					null,
					2000,
					false,
					'map-view-page',
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		noteSaveBtnDisabled,
		noteUpdateChangeHandler,
		updateNoteHandler,
	};
};
