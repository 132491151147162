import React from 'react';
import { FilterMenu, FilterBar } from 'componentsV2';

export const StatusFilterMenu = ({
	setFilterationData,
	filterationData,
	statusOptions,
	gradesList,
	disabled = false,
}) => {
	const onChangeHandler = (filterName, value) => {
		const filterValues = filterationData[filterName];
		const indexToRemove = filterValues.findIndex(filterValue => filterValue === value);

		if (indexToRemove > -1) {
			filterValues.splice(indexToRemove, 1);
		} else {
			filterValues.push(value);
		}

		setFilterationData(oldData => ({
			...oldData,
			[filterName]: filterValues,
			page: 1,
		}));
	};

	const items = [
		{
			title: 'Grades',
			name: 'grades',
			onchange: onChangeHandler,
			multipleSelect: true,
			options: gradesList,
		},
		{
			title: 'Status',
			name: 'statusSelected',
			onchange: onChangeHandler,
			multipleSelect: true,
			options: statusOptions,
		},
	];

	const getFilterValue = filterName => {
		return filterationData[filterName];
	};

	return <FilterMenu items={items} getFilterValue={getFilterValue} disabled={disabled} />;
};

export const StatusFilterBar = ({ filterationData, setFilterationData, statusOptions }) => {
	const statusValues = statusOptions.filter(statusOption =>
		filterationData.statusSelected.includes(statusOption.value),
	);

	const items = [
		{
			title: 'Grades',
			name: 'grades',
			values: filterationData.grades,
		},
		{
			title: 'Status',
			name: 'statusSelected',
			values: statusValues.map(statusValue => statusValue.label),
		},
	];

	const onRemoveFilterBar = filterName => {
		setFilterationData(oldData => ({
			...oldData,
			[filterName]: [],
		}));
	};

	const showFilterBar =
		!!filterationData.statusSelected.length || !!filterationData.grades.length;

	return <FilterBar items={items} removeFilter={onRemoveFilterBar} isShown={showFilterBar} />;
};
