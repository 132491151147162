import React from 'react';
import './Question.scss';
import { Badge, Button } from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import { QUESTION_ANSWERS_TYPES, QUESTION_ANSWERS_LABELS } from '../../consts.js';

export const Question = ({ sectionId, question, questionNumber, isViewOnly, onOpenModal }) => {
	const propertiesData = JSON.parse(question.properties);

	const getTypeDataLabel = propertiesData => {
		let typeLabel = '';
		switch (propertiesData.type) {
			case QUESTION_ANSWERS_TYPES.YN:
				typeLabel = propertiesData.options
					?.join('|')
					.replaceAll('/', '')
					.replaceAll('|', '/');
				break;
			case QUESTION_ANSWERS_TYPES.AREA:
				typeLabel = QUESTION_ANSWERS_LABELS.AREA;
				break;
			case QUESTION_ANSWERS_TYPES.SINGLE_SELECT:
				typeLabel = QUESTION_ANSWERS_LABELS.SINGLE_SELECT;
				break;
			case QUESTION_ANSWERS_TYPES.MULTIPLE_SELECT:
				typeLabel = QUESTION_ANSWERS_LABELS.MULTIPLE_SELECT;
				break;
		}
		return typeLabel;
	};

	return (
		<>
			<div className="template-section-question">
				<div className="question-header">
					<Icon icon="drag-handle-vertical" />
					<Badge type="Primary" size="sm" text={`Question ${questionNumber}`} />
				</div>
				<div className="body">
					<div className="info">
						<div className="title">{question.title}</div>
						<div className="property">Hint: {question.hint}</div>
						<div className="property">Type: {getTypeDataLabel(propertiesData)}</div>
					</div>
					{!isViewOnly && (
						<div className="controls">
							<Button
								type="secondaryDefault"
								icon="edit"
								onClick={() =>
									onOpenModal('edit_question', { sectionId, question })
								}
							/>
							<Button
								type="tertiary"
								icon="trash"
								onClick={() =>
									onOpenModal('delete_question', {
										sectionId,
										questionId: question.id,
									})
								}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
