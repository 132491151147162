import React from "react";
import { FeaturedIcon } from 'componentsV2';
import './css/EmptySection.scss';

export const EmptySection = ({
    title,
    description,
	icon,
	type
}) => (
	<div className={`empty-section-container ${type}`}>
		<FeaturedIcon icon={icon} size="lg" type={type} className="icon" />
		<div className="description-container">
			<p className="title">{title}</p>
			<span className="description">{description}</span>
		</div>
	</div>
)
