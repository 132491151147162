import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner, Animated, myClasses, noResult, EmptyState } from 'componentsV2';
import { RapidAlarmService } from 'services';
import { StudentsTable } from '../MyClasses';
import { STUDENTS_LIST } from '../consts';
import { RollCallDetailsContext } from '../../../RollCallDetails';
import { RollCallAndReunificationContext } from '../../RollCallAndReunification';

export const Students = ({ filterData, setFilterData, setDisableFilter }) => {
	const [loading, setLoading] = useState(true);
	const [studentsList, setStudentsList] = useState([]);
	const [firstRender, setFirstRender] = useState(true);
	const [emptyStateType, setEmptyStateType] = useState({
		noStudentsAdded: false,
		noResultFound: false,
	});
	const [totalStudents, setTotalStudents] = useState(0);
	const { studentUpdateEvent } = useContext(RollCallDetailsContext);
	const { requestId } = useContext(RollCallAndReunificationContext);

	useEffect(() => {
		//student status updated
		if (studentUpdateEvent) {
			switch (studentUpdateEvent.name) {
				case 'reunificationStudents:checkIn':
				case 'reunificationStudents:checkInManual':
					getStudentsList(false);
					break;
				case 'alarmStudents:updated':
					if (
						studentUpdateEvent.data?.requestId !== requestId ||
						filterData.statusSelected.length > 0
					) {
						getStudentsList(false);
					}
					break;
			}
		}
	}, [studentUpdateEvent]);

	const getStudentsList = (showLoading = true) => {
		if (showLoading) {
			setLoading(true);
		}
		RapidAlarmService.getStudentsByClass(filterData)
			.then(response => {
				if (response.statusCode === 200) {
					setStudentsList(response.data);
					setTotalStudents(response._metadata.totalRecords);
					let noRecords = response._metadata.totalRecords === 0;
					setEmptyStateType({
						noStudentsAdded: firstRender && noRecords,
						noResultFound: !firstRender && noRecords,
					});
					setDisableFilter(firstRender && noRecords);
				}
			})
			.finally(() => {
				if (showLoading) {
					setLoading(false);
				}
				setFirstRender(false);
			});
	};

	useEffect(() => {
		getStudentsList();
	}, [filterData]);

	const noResultFound = emptyStateType.noResultFound;
	const noStudentsAdded = emptyStateType.noStudentsAdded;

	return (
		<>
			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<Animated>
					{(noStudentsAdded || noResultFound) && (
						<EmptyState
							header={
								noResultFound ? 'No results found' : "You don't have any students"
							}
							description={
								noResultFound ? (
									<>
										Your search didn't match any student.
										<br /> Please try again.
									</>
								) : (
									undefined
								)
							}
							icon={noResultFound ? noResult : myClasses}
							showCircles={noResultFound}
							style={{
								marginTop: 100,
							}}
						/>
					)}
					{!noStudentsAdded && !noResultFound && (
						<StudentsTable
							studentsList={studentsList}
							setStudentsList={setStudentsList}
							totalStudents={totalStudents}
							filterData={filterData}
							setFilterData={setFilterData}
							listType={STUDENTS_LIST}
						/>
					)}
				</Animated>
			)}
		</>
	);
};
