import React, { useEffect, useState, useContext, useReducer } from 'react';
import { RAPID_ICONS } from 'assets/respond';
import { Badge, StepsBar, Button, Intent } from 'componentsV2';
import { AlarmContentItem } from './AlarmContentItem';
import { getFormattedDurationInHMS } from 'utilities/dates';
import { NavLink } from 'react-router-dom';
import { TabPreviewModal } from '../../Flipchart/components';
import { Icon } from '@blueprintjs/core';
import { ALARM_MODES_LABEL, ALARM_STATUS } from '../consts';
import { RapidAlarmsContext } from '../Providers';
import './css/AlarmDetailsCard.scss';
import { AlarmFormModal } from '../Modals/AlarmFormModal';
import { AFTER_ACTIVATION_STATUSES } from '../Modals/ActivateAlarmModal/consts';
import { RapidAlarmService } from 'services';

export const AlarmDetailsCard = ({
	history,
	alarm,
	loggedUser,
	isRollCallStarted,
	isRollCallEnded,
	isReunificationStarted,
	userStatus,
	endAlarmBtn = false,
	isRollCallDetails,
	toaster,
	forceAreYouSafeOpen,
	goButtonClicked,
}) => {
	const { onEndAlarmClick, openedFromHomePage } = useContext(
		RapidAlarmsContext,
	);
	const isRespondTypeAdmin = loggedUser.accountType == 'administrator' ? true : false;
	const [duration, setDuration] = useState('0h 0m 0s');

	const initialState = {
		showCancelAction: false,
		loading: false,
		onConfirmHandler: () => {},
		alertMsg: '',
		showAreYouSafe: true,
		ShowNeedAssistant: false,
		ShowAreYouSafeModal: false,
	};

	const modalDataReducer = (modalData, action) => {
		modalData = action.payload;
		return {
			showCancelAction: modalData.showCancelAction,
			loading: modalData.loading,
			onConfirmHandler: modalData.onConfirmHandler,
			alertMsg: modalData.alertMsg,
			showAreYouSafe: modalData.showAreYouSafe,
			ShowNeedAssistant: modalData.ShowNeedAssistant,
			ShowAreYouSafeModal: modalData.ShowAreYouSafeModal,
		};
	};

	const [modalData, dispatchModalData] = useReducer(modalDataReducer, initialState);

	const [formData, setFormData] = useState({
		value: '',
		selectedOption: null,
	});
	const [showTabPreviewModal, setShowTabPreviewModal] = useState(false);
	const [steps, setSteps] = useState([]);

	const showAreYouSafeModalHandler = () => {
		onChangeModalData({
			ShowAreYouSafeModal: true,
		});
	};

	const onChangeModalData = value => {
		dispatchModalData({
			payload: {
				...modalData,
				...value,
			},
		});
	};
	const createPersonAlarmsNote = () => {
		onChangeModalData({
			loading: true,
		});
		RapidAlarmService.createStaffNote(formData.value, [alarm.id])
			.then(response => {
				if (response.statusCode === 201) {
					goToRollCallReunificationDetails();
					toaster(
						'Your status has been saved and updated in Roll Call. If you provided a note, it will be saved to your Roll Call history.',
						Intent.WARNING,
					);
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				onChangeModalData({
					loading: false,
					ShowNeedAssistant: false,
					showAreYouSafe: true,
					ShowAreYouSafeModal: false,
				});
			});
	};
	const onConfirmSafeModalHandler = () => {
		onChangeModalData({
			loading: true,
		});
		RapidAlarmService.updateStaffStatus(formData.selectedOption, [alarm.id])
			.then(response => {
				if (response.statusCode === 200) {
					if (
						modalData.showAreYouSafe &&
						formData.selectedOption == AFTER_ACTIVATION_STATUSES.NOT_OKAY
					) {
						onChangeModalData({
							ShowNeedAssistant: true,
							showAreYouSafe: false,
							loading: false,
						});
					} else if (modalData.ShowNeedAssistant) {
						createPersonAlarmsNote();
					} else {
						onChangeModalData({
							ShowAreYouSafeModal: false,
							loading: false,
						});

						toaster(
							'Your status has been saved and updated in Roll Call.',
							Intent.SUCCESS,
						);
						if (forceAreYouSafeOpen) {
							goToRollCallReunificationDetails();
						}
					}
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			});
	};
	useEffect(() => {
		let updatedSteps = [
			{
				title: 'Alarm activated',
				passed: true,
			},
		];
		if (isRollCallStarted) {
			updatedSteps.push({
				title: 'Roll Call',
				passed: true,
			});
		} else if (!isRollCallStarted && !isReunificationStarted) {
			updatedSteps.push({
				title: 'Roll Call',
				passed: false,
			});
		}

		updatedSteps.push({
			title: 'Reunification',
			passed: isReunificationStarted,
		});

		setSteps(updatedSteps);

		if (!alarm.dateStarted) {
			return;
		}

		let durationInterval = setInterval(() => {
			setDuration(getFormattedDurationInHMS(alarm.dateStarted));
		}, 1000);

		return () => clearInterval(durationInterval);
	}, [isRollCallStarted, isReunificationStarted]);

	const goToAlarmDetailsHandler = () => {
		history.push({
			pathname: `/active-alarms/${alarm.id}`,
			state: { openedFromHomePage },
		});
	};

	const goToRollCallReunificationDetails = () => {
		history.push({
			pathname: `/active-alarms/roll-call-reunification/${alarm.id}`,
			state: { activeTab: goButtonClicked, openedFromHomePage },
		});
	};

	return (
		<>
			<div className="alarm-card-container">
				<div className="alarm-title-container">
					<img src={RAPID_ICONS[alarm.alarmType.icon]} />
					<div className="alarm-title-and-subtitle">
						<div className="alarm-title-and-type">
							<h2>{alarm.alarmType.title}</h2>
							<Badge
								key="past-alarm-type"
								type="Primary"
								size="md"
								text={ALARM_MODES_LABEL[alarm.mode]}
							/>
						</div>
						<span className="alarm-subtitle">{alarm.building.name}</span>
					</div>

					<div className="alarm-right-action-btn">
						{isRespondTypeAdmin && endAlarmBtn && !isRollCallDetails && (
							<Button
								large
								text="End alarm"
								type="primaryDanger"
								intent="default"
								onClick={() => {
									onEndAlarmClick(alarm);
								}}
							/>
						)}
						{isRollCallDetails && (
							<Button
								large
								text="Go to alarm details"
								type="secondaryDefault"
								intent="default"
								rightIcon="arrow-top-right"
								onClick={goToAlarmDetailsHandler}
							/>
						)}
					</div>
				</div>

				<div className="row">
					<div className="col-md-6">
						<div className="alarm-details-contents">
							<AlarmContentItem icon="time" label="Alarm duration" value={duration} />

							<AlarmContentItem
								icon="box"
								label="Flipchart"
								value={
									alarm.flipchart ? (
										<NavLink
											to="#"
											onClick={() => setShowTabPreviewModal(true)}
										>
											{alarm.flipchart.title}
										</NavLink>
									) : (
										<span className="gray-400-color">
											No flipchart included
										</span>
									)
								}
							/>

							<AlarmContentItem
								icon="flag"
								label="Your status"
								value={
									isRollCallStarted ? (
										<>
											{userStatus ? (
												ALARM_STATUS[userStatus]
											) : (
												<span>Status not specified</span>
											)}
											&nbsp;
											{!isRollCallEnded && (
												<NavLink
													to="#"
													onClick={showAreYouSafeModalHandler}
												>
													<br /> Change Status <Icon icon="arrow-right" />
												</NavLink>
											)}
										</>
									) : (
										<span className="gray-400-color">
											Roll Call not activated
										</span>
									)
								}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<StepsBar orientation="horizontal" steps={steps} />
					</div>
				</div>
			</div>
			{(modalData.ShowAreYouSafeModal || forceAreYouSafeOpen) && (
				<AlarmFormModal
					hasCallout={false}
					setFormData={setFormData}
					formData={formData}
					modalData={modalData}
					onChangeModalData={onChangeModalData}
					onClose={() => {}}
					response=""
					confirmSafeAction={onConfirmSafeModalHandler}
					showCancelBtn={false}
					loading={modalData.loading}
					preSelectedValue={userStatus}
				/>
			)}
			{showTabPreviewModal && (
				<TabPreviewModal
					showTabPreviewModal={showTabPreviewModal}
					setShowTabPreviewModal={setShowTabPreviewModal}
					id={alarm.flipchart.id}
					alarmId={alarm.id}
				/>
			)}
		</>
	);
};
