import React, { useState, useEffect, useContext } from 'react';
import {
	CardContainer,
	ToggleButton,
	Button,
	LoadingSpinner,
	ToasterContext,
	Intent,
	FeaturedIcon,
} from 'componentsV2';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import './Integrations.scss';
import { ConnectionModal } from './components/ConnectionModal';
import { RapidAlarmIntegrationsService } from 'services';

export const Integrations = ({ history }) => {
	const [openModal, setOpenModal] = useState(null);
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(null);
	const [partnerId, setPartnerId] = useState(null);
	const permissions = useSelector(state => state.route.permissions);
	const editPremission = checkPermission('respond_edit', permissions);
	const isSuperAdmin = permissions.isSysAdmin;
	const toaster = useContext(ToasterContext);

	const handleCloseModal = () => {
		setOpenModal(null);
		setChecked(!checked);
	};

	useEffect(() => {
		setLoading(true);
		getAlarmPartners();
	}, []);

	const getAlarmPartners = async () => {
		await RapidAlarmIntegrationsService.getAlarmPartners()
			.then(res => {
				setPartnerId(res?.data[0].id);
				setChecked(res?.data[0].isCurrent);
				setLoading(false);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const onConfirmationConnection = () => {
		RapidAlarmIntegrationsService.cellularConnection(partnerId, checked)
			.then(res => {
				toaster(
					`You have successfully ${
						checked ? 'enabled' : 'disabled'
					} the 911Cellular connection.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
				);
			})
			.catch(err => {
				toaster(
					`There was an error establishing the connection. Please try again.`,
					Intent.DANGERS,
					<FeaturedIcon icon="error" type="Error" />,
				);
				handleCloseModal();
				console.log(err);
			})
			.finally(() => {
				setOpenModal(null);
			});
	};
	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && (
				<CardContainer>
					<div className="Settings_integration_Main_Container">
						<h3 className="body-md-bold">Integration Settings</h3>
						<div className="integration-settings-container">
							{(editPremission || isSuperAdmin) && (
								<>
									<div className="toggle-button-container">
										<ToggleButton
											checked={checked}
											label="911Cellular"
											large={false}
											onChange={() => {
												setChecked(!checked);
												setOpenModal('connection_modal');
											}}
										/>
									</div>
									<Button
										text="Edit"
										type="ghostPrimary"
										size="md"
										onClick={() => {
											history.push({
												pathname: `/rapid-alarms/integrations/edit`,
												state: { data: partnerId },
											});
										}}
									/>
									<Button
										type="secondaryDefault"
										icon="changes"
										text="Test Connection"
										size="md"
										onClick={() => {
											history.push({
												pathname: `/rapid-alarms/integrations/test-connection`,
												state: { data: partnerId },
											});
										}}
									/>
								</>
							)}
						</div>
					</div>
					{openModal === 'connection_modal' && (
						<ConnectionModal
							isOpen
							onCloseModal={handleCloseModal}
							onClickConnection={onConfirmationConnection}
							checked={checked}
						/>
					)}
				</CardContainer>
			)}
		</>
	);
};
