import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';
import { Dialog , Icon } from '@blueprintjs/core';	
import { Callout } from 'components/Callout';
import { DocumentBody, HtmlBody , AlertType } from './components/ContentBody';
import './css/index.scss';
import TemplateSection from './components/TemplateSection';

class UpdateModal extends React.Component {
	constructor() {
		super();
		this.state = {
			pages: null,
			currentPage: null,
			loading: true,
			updates: [],
			currentUpdateId: null,
			updateType: null,
			templateUpdated: false,
		};

		this.editorRef = React.createRef();
	}

	getCurrentTarget = currentUpdate => {
		const { template } = this.props;
		if (currentUpdate.actualSubsectionId) {
			const temp = template.sections.filter(
				item => item.id === currentUpdate.actualSectionId,
			);
			if (temp[0]) {
				const retVal = temp[0].subsections.filter(
					item => item.id === currentUpdate.actualSubsectionId,
				);
				if(retVal.length == 0 && currentUpdate.actualSubsectionId) {
					// targetSSNotExistAndSExist: Targetting Sub Section Not Exist, but the Section Exist
					return { title: temp[0].title, targetSSNotExistAndSExist: true };
				}
				return retVal[0];

			} else {
				// targetSSNotExistAndSNotExist: Targetting Sub Section Not Exist, and the Section Not Exist
				return { title: '', targetSSNotExistAndSNotExist: true };
			}
			
		}
		const retVal2 = template.sections.filter(
			item => (item.id === currentUpdate.actualSectionId)
		);

		return retVal2[0];
	};

	sortData = () => {
		const { template, isDistrictUpdate } = this.props;
		const totalUpdates = isDistrictUpdate ? template.localUpdates : template.updates;

		if (totalUpdates.length === 0) return null;

		let getCurrentUpdate = totalUpdates.find(totalUpdate => totalUpdate.type === 'reset')
		if(!getCurrentUpdate){
			getCurrentUpdate = totalUpdates[0];
		}

		const currentUpdate = JSON.parse(getCurrentUpdate.updatedContent);
		const updateType = getCurrentUpdate.type;
		const currentUpdateId = JSON.parse(getCurrentUpdate.id);
		const currentTarget = this.getCurrentTarget(currentUpdate);

		return this.setState({
			updates: totalUpdates,
			pages: totalUpdates.length,
			currentPage: 1,
			currentUpdate,
			currentTarget,
			currentUpdateId,
			updateType,
			loading: false,
		});
	};

	getWidth = () => {
		const { pages, currentPage } = this.state;
		if (pages === 1) return 100;
		return (currentPage / pages) * 100;
	};

	getRightTitle = (currentUpdate) => {
		const { template } = this.props;
		if (currentUpdate?.actualSubsectionId) {
			const temp = template.sections.filter(
				item => item.id === currentUpdate?.actualSectionId,
			);
			if (temp.length != 0) {
				const retVal = temp[0].subsections.filter(
					item => item.id === currentUpdate?.actualSubsectionId,
				);
	
				return (
					<>
						<h3>{temp[0]?.title}</h3>
						<h5>{currentUpdate?.title}</h5>
					</>
				);
			} else {
				return <h3>{currentUpdate?.title}</h3>;
			}
		} else {
			return <h3>{currentUpdate?.title}</h3>
		}
	};

	getLeftTitle = (currentTarget, currentUpdate) => {
		const { template } = this.props;
		// update a subsection
		if (currentUpdate?.actualSubsectionId) {
			// get the section
			const temp = template.sections.filter(
				item => item.id === currentUpdate?.actualSectionId,
			);
			if (temp.length > 0) {
				const retVal = temp[0].subsections.filter(
					item => item.id === currentUpdate?.actualSubsectionId,
				);
	
				return (
					<>
						<h3>{temp[0]?.title}</h3>
						{retVal[0] && <h5>{retVal[0].title}</h5>}
					</>
				);
			} else {
				return <h3>{currentTarget?.title}</h3>
			}
		} else {
			return <h3>{currentTarget?.title}</h3>
		}
	};

	deleteSectionOrSubSection = (targetSection) => {
		const { template } = this.props;
		const { currentUpdate } = this.state;
		if( targetSection ) {
			if (currentUpdate.actualSubsectionId) { // Delete a subSection
				const targetSubsection = targetSection.subsections.find(subSection => subSection.id === currentUpdate.actualSubsectionId);
				const subSectionIndex = targetSection.subsections.indexOf(targetSubsection);
				if( subSectionIndex > -1) {
					targetSection.subsections.splice(subSectionIndex, 1);
				}
			} else { // Delete a section 
				const sectionIndex = template.sections.indexOf(targetSection);
				if( sectionIndex > -1) {
					template.sections.splice(sectionIndex, 1);
				}
			}
		}
		
		this.updateTemplateSectionId(template, true).then(() => {
			this.updateTemplateContent(template)
		});
	}

	useUpdated = () => {
		Swal.fire('Applying');
		Swal.showLoading();

		const { template, isDistrictUpdate } = this.props; // template here is the plan not the original template
		const { currentUpdate: oldUpdate, updateType } = this.state;		

		if(updateType === 'reset'){
			template.sections = oldUpdate.content;
			this.updateTemplateSectionId(template, true).then(() => {
				this.updateTemplateContent(template);
			});
			return;
		}

		// getting the section
		const targetSection = template.sections.filter(
			item => item.id === oldUpdate.actualSectionId,
		)[0];
		if(updateType === 'delete' ){ //delete a section/sub-section
			this.deleteSectionOrSubSection(targetSection)
			return;
		}
		let actualIndex = template.sections.indexOf(targetSection);
		let adjustedIndex, splicedElement;
		// checking first for actualSubsectionId, to check if update related to subsections not section
		if (oldUpdate.actualSubsectionId) {
			
			// section found
			if (targetSection !== undefined) {
				// getting the subsection
				var targetSubsection = targetSection.subsections.filter(
					item => item.id === oldUpdate.actualSubsectionId,
				);
				if(targetSubsection[0]) {
					targetSubsection = targetSubsection[0]
				}
				const actualSubIndex = targetSection.subsections.indexOf(targetSubsection); // find the index of subsection
				if (actualSubIndex == -1) { //NEW Sub SECTION WILL BE ADDED 
					const totalUpdates = isDistrictUpdate ? template.localUpdates : template.updates
					totalUpdates.forEach(templateUpdate => {
						const newUpdate = {};
						const content = JSON.parse(templateUpdate.updatedContent)
						if (oldUpdate.actualSectionId == content.actualSectionId && oldUpdate.actualSubsectionId == content.actualSubsectionId) {

							newUpdate.id = content.actualSubsectionId;

							// newUpdate.id =  lastInsertedId;
							newUpdate.title = content.title;
							newUpdate.content = content.content;
							if (oldUpdate.is_document) {
								newUpdate.content = "";
								newUpdate.fileSection = true;
								if(oldUpdate.reupload) {
									newUpdate.reupload = true;
									newUpdate.baseFile = (!!oldUpdate.baseFile?.name) ? oldUpdate.baseFile : {"coreTemplate":null,"name":"","size":"","id":null};;
									newUpdate.valueFile = {"name":"","size":"","id":null}
								} else {
									newUpdate.reupload = false;
									newUpdate.baseFile = {"coreTemplate":null,"name":"","size":"","id":null};
									newUpdate.valueFile = {"name":"","size":"","id":null}
								}
							}
							if (newUpdate) {
								template.sections[actualIndex].subsections.push(newUpdate);	
								if(oldUpdate.index !== '') {
									// in case the new index is greater than the length of subsections, get the minimum
									adjustedIndex = Math.min(oldUpdate.index, template.sections[actualIndex].subsections.length -1);
									splicedElement = template.sections[actualIndex].subsections.splice(template.sections[actualIndex].subsections.length - 1, 1)[0];
									template.sections[actualIndex].subsections.splice(adjustedIndex, 0, splicedElement);
								}
							}
						}
					 });
				} else {
					targetSubsection.content = oldUpdate.content;
					targetSubsection.title = oldUpdate.title;
					if (oldUpdate.is_document) {
						targetSubsection.content = "";
						targetSubsection.fileSection = true;
						if(oldUpdate.reupload) {
							targetSubsection.reupload = true;
							targetSubsection.baseFile = (!!oldUpdate.baseFile?.name) ? oldUpdate.baseFile : {"coreTemplate":null,"name":"","size":"","id":null};
							targetSubsection.valueFile = {"name":"","size":"","id":null}
						} else {
							targetSubsection.reupload = false;
							targetSubsection.baseFile = {"coreTemplate":null,"name":"","size":"","id":null};
							targetSubsection.valueFile = {"name":"","size":"","id":null}
						}
					}
					template.sections[actualIndex].subsections[actualSubIndex] = targetSubsection;
					if(oldUpdate.index !== '') {
						// in case the new index is greater than the length of subsections, get the minimum
						adjustedIndex = Math.min(oldUpdate.index, template.sections[actualIndex].subsections.length - 1);
						splicedElement = template.sections[actualIndex].subsections.splice(actualSubIndex, 1)[0];
						template.sections[actualIndex].subsections.splice(adjustedIndex, 0, splicedElement);
					}
				}
			}
			
		
		} else { // updating a section
			if (targetSection == undefined) { //new main section will be added
				const newSection = {}
				newSection.id = oldUpdate.actualSectionId;
				newSection.content = oldUpdate.content;
				newSection.title = oldUpdate.title;
				
				newSection.subsections = []
				template.sections.push(newSection)
				actualIndex = template.sections.length - 1;
			} else {
				template.sections[actualIndex].content = oldUpdate.content;
				template.sections[actualIndex].title = oldUpdate.title;
			}
			if(oldUpdate.index !== '') {
				// in case the new index is greater than the length of sections, get the minimum
				adjustedIndex = Math.min(oldUpdate.index, template.sections.length - 1);
				splicedElement = template.sections.splice(actualIndex, 1)[0];
				template.sections.splice(adjustedIndex, 0, splicedElement);
			}
			
		}
		this.updateTemplateSectionId(template, true).then(() => {
			this.updateTemplateContent(template)
		});
	};

	getToken = () => {
		return JSON.parse(localStorage.getItem('user')).jwt;
	}

	updateTemplateSectionId = (template, accepted) => {
		const { currentUpdateId } = this.state;
		const { isDistrictUpdate } = this.props;

		return fetch(`${process.env.API_URL}/district-templates/${parseInt(template.id, 10)}/updates/${currentUpdateId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${this.getToken()}`,
			},
			body: JSON.stringify({
				accepted: accepted,
				isDistrictLocalUpdate : isDistrictUpdate?isDistrictUpdate:false
			}),
		})
	}

	setTemplateUpdated = value => this.setState({ templateUpdated: value });

	updateTemplateContent = template => {
		const { reloadTemplate , toggleDistrictUpdateModal} = this.props;
		const {
			pages,
			currentPage,
			updates,
			updateType
		} = this.state;

		return fetch(`${process.env.API_URL}/district-templates/${parseInt(template.id, 10)}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${this.getToken()}`,
				},
				body: JSON.stringify({
					headers: JSON.stringify(template.headers),
					content: JSON.stringify(template.sections),
				}),
			},
		).then(res => res.json())
		.then(rsp => {
			if (rsp.statusCode === 200) {
				this.setTemplateUpdated(true)
				if (pages === currentPage || updateType === 'reset') {
					Swal.close();
					return Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(reloadTemplate);
				}
				const nextUpdate = updates[currentPage];
				const currentUpdate = JSON.parse(nextUpdate.updatedContent);
				const nextUpdateId = nextUpdate.id;
				const nextUpdateType = nextUpdate.type;
				const currentTarget = this.getCurrentTarget(currentUpdate);
				Swal.close();
				return Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				}).then(() =>
					this.setState({
						currentPage: currentPage + 1,
						currentUpdate,
						currentTarget,
						updateType: nextUpdateType,
						currentUpdateId: nextUpdateId,
					}),
				);
			}
			return null;
		});
	}

	keepExisting = () => {
		Swal.fire('Applying');
		Swal.showLoading();

		const { template } = this.props;
		const { currentUpdate: oldUpdate } = this.state;

		if (oldUpdate.actualSubsectionId) { // update subsection
			const temp = template.sections.filter(
				item => item.id === oldUpdate.actualSectionId,
			)[0];
			// check if section found
			if(temp != undefined) {
				var temp2 = temp.subsections.filter(
					item => item.id === oldUpdate.actualSubsectionId,
				);
				if(temp2[0]) {
					temp2 = temp2[0]
				}
				const actualIndex = template.sections.indexOf(temp);
				const actualSubIndex = temp.subsections.indexOf(temp2);
				temp2.content = this.editorRef.current ? this.editorRef.current.getContent() : ''
				template.sections[actualIndex].subsections[actualSubIndex] = temp2;
			}
		} else { // update section
			const temp = template.sections.filter(
				item => item.id === oldUpdate.actualSectionId,
			)[0];
			if (temp != undefined) { //new main section
				const actualIndex = template.sections.indexOf(temp);
				template.sections[
					actualIndex
				].content = this.editorRef.current ?this.editorRef.current.getContent() : ''
			}		
		}

		this.updateTemplateSectionId(template, false).then(() => {
			this.updateTemplateContent(template)
		});
	};

	componentDidMount() {
		this.sortData();
	}

	closeModal = () => {
		const { reloadTemplate, closeUpdateModal } = this.props
		if(this.state.templateUpdated === true) {
			reloadTemplate();
		}
		closeUpdateModal();
	}

	render() {
		const { isOpen, template } = this.props;
		const { currentUpdate, currentTarget, loading, currentPage, pages, updateType } = this.state;

		if (!isOpen || loading) return null;
		return (
			<div className='update-modal-wrapper'>
				<Dialog isOpen={true} className='modal-content-body'>
					<div className="modal-header">
						<h3 className="modal-title">Template Updates</h3>
						<Icon icon='cross' className='cross-icon'  onClick={this.closeModal}/>
					</div>
				<div className='progress-bar'>
					<div className='bar' style={{width: this.getWidth()+"%"}} />
				</div>
				<div className="mt-3">
					<div className="row">
						{updateType !== 'reset' && (
						<div className="col-md-6">
							<TemplateSection
								name={template.name}
								templateTitle={this.getLeftTitle(currentTarget, currentUpdate)}
								isPrimary={true}
								btnTitle="Keep existing version"
								onClickHandler={this.keepExisting}
							>
							<>
							{currentTarget && !currentTarget.targetSSNotExistAndSExist && !currentTarget.targetSSNotExistAndSNotExist ? 
									<>
									{currentTarget.fileSection ? 
										<DocumentBody
											template={currentTarget}
										/> 
										:
										<HtmlBody
											content={currentTarget.content}
											hasEditor={true}
											editorRef={this.editorRef}
										/>
									}
									</>
								:
								<Callout type={ currentTarget?.targetSSNotExistAndSNotExist ? "warning" : "info" } wrapperStyles={{marginTop: 0}}>
									{ 
										currentTarget?.targetSSNotExistAndSNotExist 
											? 'New sub-section added to the template. However, this sub-section is linked to a section that does not exist in your safety plan. Therefore, you cannot use the updated version' 
											: (updateType === 'delete' 
													? `This ${currentTarget == undefined ? 'section' : 'sub section'} does not exist yet in your safety plan`
													:'New section added to the template. If you wish to include it in this plan click on "Use updated version", otherwise click on "Keep existing version"'
												)
									}
								</Callout>
							}
							</>
							</TemplateSection>
						</div>
						)}
						<div className={updateType === 'reset' ? 'col-md-12' : 'col-md-6'}>
							<TemplateSection
								name={template.name}
								templateTitle={this.getRightTitle(currentUpdate)}
								btnTitle="Use updated version"
								onClickHandler={this.useUpdated}
								isButtonDisabled={!!currentTarget?.targetSSNotExistAndSNotExist || (updateType === 'delete' && (currentTarget == undefined || currentTarget?.targetSSNotExistAndSExist || currentTarget?.targetSSNotExistAndSNotExist))}
								isDeleteUpdate={updateType === 'delete' ? true : false}
							>
								<>
								{updateType === 'reset' ? (
									<AlertType
										type="warning"
									>
										The system found some anomalies in the safety plan and is forcing an update. Choosing “Use updated version” will return the safety plan to its initial state and all the changes you have done on the safety plan will be lost. Any variables added will not be affected. The system will automatically create a copy of your current safety plan and you can view it in the archived section.
									</AlertType>
								) : (
									<>
									{currentUpdate.is_document || currentUpdate.fileSection ?
										<DocumentBody
											template={currentUpdate}
										/> 
										:
										<HtmlBody
											content={currentUpdate.content}
											updateType={updateType}
										/>
									}
									</>
								)}
								</>
							</TemplateSection>
						</div>
						<div className="col-12">
							<div className="d-flex w-100 justify-content-between mt-3">
								<p>
									Page {currentPage} of {pages}
								</p>
								<Button
									intent="secondary"
									text="Close without updating"
									onClick={this.closeModal}
								/>
							</div>
						</div>
					</div>
					</div>
				</Dialog>
			</div>
		);
	}
}

UpdateModal.propTypes = {
	isOpen: PropTypes.bool,
	template: PropTypes.object,
	reloadTemplate: PropTypes.func,
	closeUpdateModal: PropTypes.func,
};

export default UpdateModal;