import {useContext, useReducer} from "react";

export const InsertVariablesModalFn = (
  modalName,
  setLoading,
  safetyPlanData,
  dispatchSafetyPlanData,
  closeModal,
  setModalToShow,
) => {

  const showInsertVariablesModal = (template,
                                    variables,
                                    section,
                                    subSection,
                                    sectionType) => {

    let content = null;
    let sectionTitle = null;
    if (sectionType === 'subsection') {
      sectionTitle = subSection.title;
      content = subSection.content;
    }
    if (sectionType === 'section') {
      sectionTitle = section.title;
      content = section.content;
    }
    const sectionVariables = getContentVariables(sectionTitle, content, variables);
    dispatchSafetyPlanData({
      ...safetyPlanData,
      id: template.id,
      variables: sectionVariables,
    });
    setModalToShow(modalName);
  }
  const getContentVariables = (sectionTitle, content, variables) => {
    if (content) {
      const testVar = /(?<=\[\[).+?(?=\]\])/g;
      const sectionVariables = content.match(testVar);
      const varArr = [];
      const uniqueSectionVariables = [...new Set(sectionVariables)];
      if (uniqueSectionVariables && uniqueSectionVariables.length > 0) {
        let sectionReferences = [sectionTitle];
        uniqueSectionVariables.forEach(sectionVariable => {
          variables.forEach(commonVariable => {
            if (commonVariable.name === `[[${sectionVariable}]]`) {
              const temp = {
                ...commonVariable,
                imageInfo: commonVariable.value === 'object' ? commonVariable.value : null,
                variable: {
                  ...commonVariable,
                  content: commonVariable.parsedContent,
                },
                sectionPlaces: commonVariable.places,
                sectionReferences: sectionReferences
              };

              let typeRemap = {};
              if (temp.variable.content.type === 'Open text field') {
                typeRemap = {
                  id: 1,
                  value: 'Text',
                };
              }

              temp.variable.content.type = {
                ...typeRemap,
              };

              let oldVarArr = varArr.find(oneVar => oneVar.variable.id === temp.variable.id);
              if (oldVarArr) {
                oldVarArr.total = ++oldVarArr.total;
              } else {
                varArr.push(temp);
              }
            }
          });
        });
      }
      return varArr;
      setLoading(false);
    }
    return [];
  };

  return {
    showInsertVariablesModal,
  };
}
