import React, { useEffect, useState, useContext } from 'react';
import { Input, LoadingSpinner, ToggleButton, InputColor, Button, Intent } from 'componentsV2';
import { MapOptionCard, ShapeContent } from '../';
import { MAP_AREAS, GEOMETRY_TYPE_POLYGON } from '../../consts';
import { FloorplanService } from 'services';
import { ToasterContext } from 'pages/App';
import { UnsavedChangesModal } from '../../modals';
import { addShapeActions } from '../../CanvasDrawFn';
import {
	editArea,
	addArea,
	cancleDrawArea,
	changeAreaColor,
	getAreaNewPosition,
	stopEventListener,
	calculateLabelCenter,
	drawAreaGroup,
	drawAreaLabel,
} from '../../Draw';
import './css/AreaAddEdit.scss';

export const AreaAddEdit = ({
	toggleMapOption,
	layer,
	stage,
	floorplan,
	selectedArea = null,
	legendListWithDescription,
	map,
	baseZoomLevel,
	editPermissionOnSiteId,
	selectedShape,
	setOptionToShow,
	setAreaHasChange,
	areahasChange,
	setSelectedArea,
	setSelectedShape,
	setRefreshAreaList,
	isEditMode,
	setFloorplanShapes,
	showModal,
	viewPermissionOnSiteId,
}) => {
	const [loading, setLoading] = useState(false);
	const [color, setColor] = useState();
	const [toggleButtonChecked, setToggleButtonChecked] = useState(false);
	const [title, setTitle] = useState();
	const toaster = useContext(ToasterContext);
	const [disabled, setDisabled] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [selectedAreaId, setSelectedAreaId] = useState();
	const [areaToEdit, setAreaToEdit] = useState();
	const [EditAreaVectorMode, setEditAreaVectorMode] = useState(false);
	const [editVictorButtonIntent, setEditVectorButtonIntetn] = useState('none');
	const [showUnsavedModal, setShowUnsavedModal] = useState(false);
	const [oldSelectedArea, setOldSelectedArea] = useState(null);
	const [startAddAreaFlag, setStartAddAreaFlag] = useState(false);
	const [editModeChangedStatus, setEditModeChangedStatus] = useState(isEditMode);
	const floorplanArchived = !!floorplan?.archivedBy;
	const description = !editMode
		? 'Create at least three vector dots to create an area. Select Save when done or Cancel to abandon changes.'
		: 'Select Save when done editing or Cancel to abandon changes.';

	const legendList = legendListWithDescription.map(item => {
		return {
			id: item.id,
			value: '#' + item.hex,
			class: 'color-primary',
			showToolTip: true,
			tooltipTitle: item.description,
		};
	});

	useEffect(() => {
		if (selectedArea && legendList) {
			loadAreaInfo();
			setEditMode(true);
		}
	}, [selectedArea, isEditMode]);

	useEffect(() => {
		setEditModeChangedStatus(isEditMode);
		editPermissionOnSiteId && isEditMode
			? (document.getElementsByClassName('map-options-buttons')[0].style.display = 'none')
			: (document.getElementsByClassName('map-options-buttons')[0].style.display = 'flex');
	}, [isEditMode]);

	useEffect(() => {
		let newArea = layer.find(node => node.name() === 'poly')[0]; // check if we trired to draw new area and we didn't save it
		if (!editMode && selectedArea === null && !startAddAreaFlag && !newArea) {
			setStartAddAreaFlag(true);
			addArea(
				stage,
				map.current,
				legendList[0].value,
				baseZoomLevel,
				legendList[0].value,
				setAreaHasChange,
				title,
			);
		}
	}, []);

	const getselectedColor = color => {
		let selectedColor = legendList.filter(item => item.value === color)[0];
		setColor(selectedColor);
		if (!editMode && selectedArea === null) {
			const currentArea = layer.find(node => node.name() === 'poly')[0];
			changeAreaColor(currentArea, color);
		} else {
			if (areaToEdit) {
				if (areaToEdit.attrs.fill !== color) {
					areaToEdit.attrs.fill = color;
					layer.draw();
					setAreaHasChange(true);
				}
			}
		}
	};

	const stageCleaning = () => {
		stopEventListener('click', stage, 'addNewPointInstance');
		stopEventListener('mousedown', stage, 'handleAddNewArea');
		setEditVectorButtonIntetn('none');
		setEditAreaVectorMode(false);
		let anchors = layer.find(node => node.name() === 'anchor');
		anchors.map(anchor => {
			anchor.destroy();
		});
		document.getElementById('draw-cursor-container').classList.remove('draw-cursor');
	};

	const resetUpdates = area => {
		setAreaHasChange(false);
		let selectedColor = legendList.filter(
			item => item.value === '#' + area.attrs.shape.color,
		)[0];
		area.attrs.title = area.attrs.shape.title;
		area.fill(selectedColor.value);
		area.stroke(selectedColor.value);
		area.points(JSON.parse(area.attrs.shape.positions));
		area.x(null);
		area.y(null);
		if (area.attrs.shape?.showLabel) {
			let labelBorder = layer.find(node => node.id() === `labelBorder-${area.attrs.id}`)[0];
			let labelGroup = layer.find(node => node.id() === `labelGroup-${area.attrs.id}`)[0];

			let LabelCenter = calculateLabelCenter(area, labelBorder);
			labelGroup.x(LabelCenter[0]);
			labelGroup.y(LabelCenter[1]);
		}

		area.attrs.showLabel = area.attrs.shape.showLabel;
		area.draggable(false);
	};
	const handleCancle = () => {
		toggleMapOption(MAP_AREAS);
		setStartAddAreaFlag(false);
		const currentArea = layer.find(node => node.name() === 'poly')[0];
		cancleDrawArea(stage, currentArea);
		setRefreshAreaList(oldValue => !oldValue);
		stageCleaning();
		if (editMode) {
			resetUpdates(areaToEdit);
		}
		document.getElementsByClassName('map-options-buttons')[0].style.display = 'flex';
	};

	const handleTitleChange = event => {
		if (areaToEdit) {
			areaToEdit.attrs.title = event.target.value;
		} else {
			const NewArea = layer.find(node => node.name() === 'poly')[0];
			NewArea.attrs.title = event.target.value;
		}
		setTitle(event.target.value);
		setAreaHasChange(true);
		setHasError(false);
		if (event.target.value !== '') {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};

	const addDragEvent = Area => {
		Area.on('dragend', e => {
			setAreaHasChange(true);

			let points = Area.attrs.points;
			if (Area.attrs?.x && Area.attrs?.y) {
				points = getAreaNewPosition(Area.attrs.points, Area.attrs.x, Area.attrs.y);
			}

			Area.attrs.points = points;
			Area.x(null);
			Area.y(null);
			if (Area.attrs.shape?.showLabel) {
				let labelBorder = layer.find(
					node => node.id() === `labelBorder-${Area.attrs.id}`,
				)[0];
				let labelGroup = layer.find(node => node.id() === `labelGroup-${Area.attrs.id}`)[0];
				let LabelCenter = calculateLabelCenter(Area, labelBorder);
				labelGroup.x(LabelCenter[0]);
				labelGroup.y(LabelCenter[1]);
			}
		});
	};
	const addNewArea = () => {
		let newArea = layer.find(node => node.name() === 'poly')[0];
		let numberOfPoints =
			newArea && newArea?.attrs?.points ? newArea.attrs.points.length / 2 : null;
		if (newArea != null && numberOfPoints > 2) {
			const areaData = {
				type: 'area',
				title: newArea.attrs?.title ? newArea.attrs?.title : title,
				is_shape_draggable: false,
				points: newArea.attrs.points,
				color_id: color.id,
				coord_lat_long: JSON.stringify(newArea.attrs.coordLatLong),
				show_label: toggleButtonChecked === true ? 1 : 0,
			};
			setLoading(true);
			FloorplanService.addShape(floorplan.id, areaData)
				.then(resp => {
					if (resp.statusCode === 201) {
						toaster(
							'You have successfully added "' +
								title +
								'" as a new area. You can now add content to this area. ',
							Intent.SUCCESS,
							null,
							2000,
							false,
							'map-view-page',
						);
						setEditMode(true);

						//clean the layer
						stageCleaning();
						newArea.attrs.id = resp.data.id;
						newArea.attrs.geometryType = GEOMETRY_TYPE_POLYGON;
						newArea.attrs.name = title;
						newArea.attrs.title = title;
						newArea.attrs.stroke = '#B3D8DD';
						newArea.attrs.strokeWidth = 9;
						newArea.draggable(true);
						let shape = {
							id: resp.data.id,
							positions: JSON.stringify(newArea.attrs.points),
							colorId: color.id,
							color: color.value.split('#')[1],
							showLabel: !!toggleButtonChecked,
							title: title,
							visible: true,
							geometryType: GEOMETRY_TYPE_POLYGON,
						};
						newArea.attrs.shape = shape;

						setSelectedAreaId(resp.data.id);
						let AreaToEdit = layer.find(
							node => parseInt(node.id()) === resp.data.id,
						)[0];
						setAreaToEdit(AreaToEdit);
						setAreaHasChange(false);
						setStartAddAreaFlag(false);
						setSelectedShape(shape);
						// update floorplan shapes
						setFloorplanShapes(oldShapes => [...oldShapes, shape]);

						const areaGroup = drawAreaGroup(shape);
						areaGroup.add(newArea);
						if (toggleButtonChecked) {
							const areaLabel = drawAreaLabel(shape, newArea);
							areaGroup.add(areaLabel);
						}
						addShapeActions(newArea, 'area', null, showModal, null);
						layer.add(areaGroup);

						// Remove the last element
						layer.children.pop();

						// Adding the new area after the first element of the layer array which is the floorplan image
						layer.children.splice(1, 0, areaGroup);
						addDragEvent(newArea);
					}
				})
				.catch(error => {
					setHasError(true);
					console.error(error);
				})
				.finally(() => {
					setLoading(false);
					setRefreshAreaList(oldValue => !oldValue);
				});
		} else {
			toaster(
				'You should draw at least three dots with the vector pen to save your area',
				Intent.DANGER,
				null,
				2000,
				false,
				'map-view-page',
			);
		}
	};

	const editExistingArea = () => {
		let newPoints = [];
		if (areaToEdit.attrs?.x && areaToEdit.attrs?.y) {
			//  means positon of the area changed
			newPoints = getAreaNewPosition(
				areaToEdit.attrs.points,
				areaToEdit.attrs.x,
				areaToEdit.attrs.y,
			);
		}
		const areaData = {
			id: areaToEdit.attrs.id,
			type: 'area',
			title: title,
			is_shape_draggable: false,
			points: newPoints.length > 0 ? newPoints : areaToEdit.attrs.points,
			color_id: color.id,
			coord_lat_long: JSON.stringify(areaToEdit.attrs.coordLatLong),
			show_label: toggleButtonChecked === true ? 1 : 0,
			visible: true,
			geometryType: GEOMETRY_TYPE_POLYGON,
		};
		setLoading(true);
		updateArea(areaToEdit.attrs.id, areaData, false);
		let labelGroup = layer.find(node => node.id() === `labelGroup-${areaToEdit.attrs.id}`)[0];
		let areaGroup = layer.find(node => node.id() === `areaGroup-${areaToEdit.attrs.id}`)[0];
		if (!labelGroup) {
			labelGroup = drawAreaLabel(areaData, areaToEdit);
			areaGroup.add(labelGroup);
		}
		if (labelGroup) {
			const textShape = labelGroup.children.find(child => child instanceof Konva.Text);
			if (textShape) {
				textShape.text(title);
			}
			const labelBorder = labelGroup.children.find(child => child instanceof Konva.Rect);
			if (labelBorder) {
				labelBorder.width(textShape.width());
				const labelCenter = calculateLabelCenter(areaToEdit, labelBorder);
				labelGroup.x(labelCenter[0]);
				labelGroup.y(labelCenter[1]);
			}
		}
		if (toggleButtonChecked) {
			labelGroup.show();
		} else {
			labelGroup.hide();
		}
		areaToEdit.stroke(areaToEdit.fill());
		areaToEdit.strokeWidth(3);
		//////updating shape without using reload
		areaToEdit.attrs.title = title;
		areaToEdit.attrs.shape.title = title;
		areaToEdit.attrs.shape.colorId = color.id;
		areaToEdit.attrs.shape.color = color.value.split('#')[1];
		areaToEdit.attrs.shape.showLabel = toggleButtonChecked;
		areaToEdit.attrs.shape.positions = JSON.stringify(areaToEdit.attrs.points);
		areaToEdit.draggable(false);
	};

	const updateArea = (areaId, areaData, isUnsavedChangedModal) => {
		FloorplanService.updateShape(floorplan.id, areaId, areaData)
			.then(resp => {
				if (resp.statusCode === 200) {
					toaster(
						'You have successfully edited this area.',
						Intent.SUCCESS,
						null,
						2000,
						false,
						'map-view-page',
					);
					if (!isUnsavedChangedModal) {
						stageCleaning();
						toggleMapOption(MAP_AREAS);
					}
					setAreaHasChange(false);
				}
			})
			.catch(error => {
				setHasError(true);
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
				setRefreshAreaList(oldValue => !oldValue);
			});
	};

	const handleAreaSave = () => {
		!editMode ? addNewArea() : editExistingArea();
		// show map icons
		document.getElementsByClassName('map-options-buttons')[0].style.display = 'flex';
	};

	const handleToggleChange = () => {
		setAreaHasChange(true);
		toggleButtonChecked === true ? setToggleButtonChecked(false) : setToggleButtonChecked(true);
		if (areaToEdit) {
			areaToEdit.attrs.showLabel = toggleButtonChecked === true ? false : true;
		}
	};

	const loadAreaInfo = () => {
		let selectedColor = legendList.filter(item => item.id === selectedArea.colorId)[0];
		let AreaToEdit = layer.find(node => parseInt(node.id()) === selectedArea.id)[0];
		AreaToEdit.stroke('#B3D8DD');
		AreaToEdit.strokeWidth(9);
		if ((areaToEdit && areaToEdit !== AreaToEdit) || startAddAreaFlag) {
			// means the selected area to be edit is changed so we have to stop draging on old one
			if (areahasChange) {
				setShowUnsavedModal(true);
				areaToEdit ? setOldSelectedArea(areaToEdit) : null;
			}
			if (areaToEdit) {
				areaToEdit.draggable(false);
				areaToEdit.stroke(areaToEdit.fill());
				areaToEdit.strokeWidth(3);
			}
		}
		AreaToEdit.draggable(
			editPermissionOnSiteId && !floorplanArchived && isEditMode ? true : false,
		);
		addDragEvent(AreaToEdit);
		setTitle(selectedArea.title);
		setToggleButtonChecked(selectedArea.showLabel);
		setColor(selectedColor);
		setSelectedAreaId(selectedArea.id);
		setAreaToEdit(AreaToEdit);
		setDisabled(false);
	};

	const editAreaVictor = () => {
		if (!EditAreaVectorMode) {
			setEditVectorButtonIntetn('secondaryDefault');
			areaToEdit.draggable(false);
			// setAreaHasChange(true);
			editArea(stage, areaToEdit, map.current, baseZoomLevel, setAreaHasChange);
			setEditAreaVectorMode(true);
			document.getElementById('draw-cursor-container').classList.add('draw-cursor');
		} else if (EditAreaVectorMode) {
			setEditVectorButtonIntetn('none');
			areaToEdit.draggable(true);
			let anchors = layer.find(node => node.name() === 'anchor');
			anchors.map(anchor => {
				anchor.destroy();
			});
			stopEventListener('click', stage, 'addNewPointInstance');
			setEditAreaVectorMode(false);
			document.getElementById('draw-cursor-container').classList.remove('draw-cursor');
		}
	};

	const handleUnsavedChanges = () => {
		if (oldSelectedArea) {
			// get old data
			let selectedColor = legendList.filter(
				item => item.value === oldSelectedArea.attrs.fill,
			)[0];
			let newPoints = [];
			if (oldSelectedArea.attrs?.x && oldSelectedArea.attrs?.y) {
				//  means positon of the area changed
				newPoints = getAreaNewPosition(
					oldSelectedArea.attrs.points,
					oldSelectedArea.attrs.x,
					oldSelectedArea.attrs.y,
				);
			}
			let points = newPoints.length > 0 ? newPoints : oldSelectedArea.attrs.points;

			let areaData = {
				id: parseInt(oldSelectedArea.attrs.id),
				type: 'area',
				title: oldSelectedArea.attrs.title,
				is_shape_draggable: false,
				points: points,
				color_id: selectedColor.id,
				coord_lat_long: JSON.stringify(oldSelectedArea.attrs.shape.coord_latlng),
				show_label: oldSelectedArea.attrs?.shape?.showLabel,
				visible: true,
				geometryType: GEOMETRY_TYPE_POLYGON,
			};

			// save it to database
			updateArea(oldSelectedArea.attrs.id, areaData, true);

			// update on shape attrs
			let updatedArea = layer.find(
				node => parseInt(node.id()) === parseInt(oldSelectedArea.attrs.id),
			)[0];
			updatedArea.attrs.title = oldSelectedArea.attrs.title;
			updatedArea.attrs.shape.title = oldSelectedArea.attrs.title;
			updatedArea.attrs.shape.colorId = selectedColor.id;
			updatedArea.attrs.shape.color = selectedColor.value.split('#')[1];
			updatedArea.attrs.shape.showLabel = oldSelectedArea.attrs?.shape?.showLabel;
			updatedArea.attrs.shape.positions = JSON.stringify(oldSelectedArea.attrs.points);
			updatedArea.draggable(false);

			let labelGroup = layer.find(
				node => node.id() === `labelGroup-${updatedArea.attrs.id}`,
			)[0];
			let areaGroup = layer.find(
				node => node.id() === `areaGroup-${updatedArea.attrs.id}`,
			)[0];
			if (!labelGroup) {
				labelGroup = drawAreaLabel(areaData, updatedArea);
				areaGroup.add(labelGroup);
			}
			if (labelGroup) {
				const textShape = labelGroup.children.find(child => child instanceof Konva.Text);
				if (textShape) {
					textShape.text(oldSelectedArea.attrs.title);
				}
				const labelBorder = labelGroup.children.find(child => child instanceof Konva.Rect);
				if (labelBorder) {
					labelBorder.width(textShape.width());
					const labelCenter = calculateLabelCenter(updatedArea, labelBorder);
					labelGroup.x(labelCenter[0]);
					labelGroup.y(labelCenter[1]);
				}
			}

			if (updatedArea.attrs.shape.showLabel) {
				labelGroup.show();
			} else {
				labelGroup.hide();
			}

			stopEventListener('click', stage, 'addNewPointInstance');
			stopEventListener('mousedown', stage, 'handleAddNewArea');
			let anchors = layer.find(node => node.name() === 'anchor');
			anchors.map(anchor => {
				anchor.destroy();
			});
			document.getElementById('draw-cursor-container').classList.remove('draw-cursor');

			setShowUnsavedModal(false);
		} else {
			let newArea = layer.find(node => node.name() === 'poly')[0];
			let selectedColor = legendList.filter(item => item.value === newArea.attrs.fill)[0];

			addNewArea();

			setColor(selectedColor);
			setTitle(newArea.attrs?.title);
			setEditMode(false);
			setShowUnsavedModal(false);
			setSelectedArea(null);
		}
	};

	const disacardUnsavedCahnges = () => {
		setAreaHasChange(false);
		if (oldSelectedArea !== null) {
			resetUpdates(oldSelectedArea);
		} else {
			const currentArea = layer.find(node => node.name() === 'poly')[0];
			cancleDrawArea(stage, currentArea);
		}

		setShowUnsavedModal(false);
		areaToEdit.draggable(isEditMode ? true : false);
		stageCleaning();
	};
	return (
		<>
			<MapOptionCard
				isOpen={true}
				title={!editMode ? 'Add new area' : title}
				onClick={() => {
					const currentArea = layer.find(node => node.name() === 'poly')[0];
					cancleDrawArea(stage, currentArea);
					setRefreshAreaList(oldValue => !oldValue);
					stageCleaning();
					if (editMode) {
						resetUpdates(areaToEdit);
					}
					setOptionToShow(null);
					document.getElementsByClassName('map-options-buttons')[0].style.display =
						'flex';
				}}
				headerItem={''}
				showLeftArrow={true}
				handleArrowClicked={() => handleCancle()}
			>
				<>
					{loading && <LoadingSpinner className="mt-4" />}
					{!loading && (
						<div className="area-add-edit-container">
							{editPermissionOnSiteId && !floorplanArchived && isEditMode && (
								<>
									{editMode && (
										<div className="edit-area-button-style">
											<Button
												text="Edit area vector shape"
												type={editVictorButtonIntent}
												intent={editVictorButtonIntent}
												size="sm"
												onClick={editAreaVictor}
											/>
										</div>
									)}

									<p className="area-add-edit-description">{description}</p>
									<Input
										label={'Area title'}
										placeholder={'Add area title'}
										hasHintText={true}
										hintText={'ex. Gym'}
										isRequired={true}
										onChange={handleTitleChange}
										error={hasError}
										value={title}
										errorMessage={'*Area with same name already exists'}
									/>
									<ToggleButton
										checked={toggleButtonChecked}
										label={'Show area title'}
										onChange={handleToggleChange}
									/>
									<div className="legend-color-menu">
										<InputColor
											label="Select color"
											name="input-color3"
											isRequired={true}
											value={color ? color.value : legendList[0].value}
											setValue={getselectedColor}
											presetColors={legendList}
											showColorPicker={false}
											showToolTipe={true}
										/>
									</div>
									<div className="button-div-styles">
										<Button
											text="Cancel"
											type="secondaryDefault"
											intent="default"
											size="lg"
											onClick={handleCancle}
										/>
										<Button
											text="Save"
											type="primaryDefault"
											intent="default"
											size="lg"
											onClick={handleAreaSave}
											disabled={disabled}
										/>
									</div>
								</>
							)}
							{!editMode && (
								<div className="content-div-container">
									<div className="content-div-header">Content</div>
									<p className="content-div-body">
										Content will be available to upload once this area is added.
									</p>
								</div>
							)}
							{editMode && (
								<ShapeContent
									floorplan={floorplan}
									editPermission={editPermissionOnSiteId}
									siteId={floorplan.building.id}
									selectedShape={selectedShape}
									shapeId={selectedAreaId ? selectedAreaId : null}
									isEditMode={editModeChangedStatus}
									viewPermissionOnSiteId={viewPermissionOnSiteId}
								/>
							)}
						</div>
					)}
				</>
			</MapOptionCard>
			{showUnsavedModal && (
				<UnsavedChangesModal
					saveChangesHandler={handleUnsavedChanges}
					discardChangesHandler={disacardUnsavedCahnges}
					shapeType={'area'}
				/>
			)}
		</>
	);
};
