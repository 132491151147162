import React from "react";
import { Icon } from "@blueprintjs/core";
import { SORTING_DIR } from "../";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ContentSwitcher } from "../../";
import '../css/Pagination.scss';

export const sortingStyle = (order, column, extraIconName = '') => {
	if (order === SORTING_DIR.DESC) {
		return (
			<>	
				{extraIconName && (<>  <Icon size={12} className="headers-icon" icon={extraIconName} /> </>)} 
				<Icon size={12} className="headers-icon" icon="arrow-down" />
			</>
		)
	}
	return (
		<>	
			{extraIconName && (<>  <Icon size={12} className="headers-icon" icon={extraIconName} /> </>)} 
			<Icon size={12} className="headers-icon" icon="arrow-up" />
		</>
	)
};

const paginationTotalRenderer = (
    from,
    to,
    size
) => {
    return (
        <span className="react-bootstrap-table-pagination-total">
            { to } of { size } items
        </span>
    )
}

// should be updated when the dropdown component is ready
const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
}) => (
    <>
    
    <button id="pageDropDown" type="button" 
        className="btn btn-default btn-secondary dropdown-toggle" 
        data-toggle="dropdown" aria-expanded="false"
        dataToggle="dropdown"
        >
        Show {currSizePerPage} 
    
    </button>
    {options.map(option => (
        <li
            key={ option.text }
            role="presentation"
            className="dropdown-item"
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ option.page }
                onMouseDown={ (e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                } }
                style={ { color: 'red' } }
            >
            { option.text }
            </a>
        </li>
        ))}
    </>
);

const pageListRenderer = ({
    pages,
    onPageChange,
}) => {
    const tabs = pages.map(page => {
        let label, skipId, leftIcon = '', rightIcon = '';
      
        if (page.page === '<') {
            label = 'Previous';
            leftIcon = <Icon icon='chevron-left' className="arrow-icon" />;
            skipId = true;
        } else if (page.page === '>') {
            label = 'Next';
            rightIcon = <Icon icon='chevron-right' className="arrow-icon" />;
            skipId = true;
        } else {
            label = page.title;
            skipId = false;
        }
      
        return {
            id: page.page,
            label,
            isDisabled: page.disabled,
            isActive: page.active, // not used in the ContentSwitcher, it's used for defaultActiveTabs only
            skipId,
            leftIcon,
            rightIcon
        };
    });

    const defaultActiveTabs = pages.filter(page => page.active == true).map(page => page.page);;

    const onClickHandler = (page) => {
        if(page && page[0]) {
            onPageChange(page[0])
        }
    }
  
    return (
        <div className="pagination-pages-list">
            <ContentSwitcher
                tabs={tabs}
                onClick={onClickHandler}
                preserveTab={true}
                defaultActiveTabs={defaultActiveTabs}
                useInput={true}
            />
        </div>
    );
};
 
const perPageList = (sizePerPageList) => {
    var perPageList = [];
    sizePerPageList.map(sizePerPage => {
        perPageList.push({
            text: `${sizePerPage} Rows`,
            value: sizePerPage
        })
    })
    return perPageList;
}
 
export const PaginationDetails = (
    sizePerPageList,
    totalRows,
    tableRef,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    remote
) => {
    const onPageChange = (page, perPage) => {
        // to make the page scroll into top after change table page
        tableRef?.current?.scrollIntoView();
        if(remote == true) {
            setRowsPerPage(perPage);
            setCurrentPage(page);
        }
    }

    return paginationFactory({
        page: currentPage,
        sizePerPage: rowsPerPage,
        totalSize: totalRows,
        onSizePerPageChange: (perPage, page) => onPageChange(page, perPage),
        sizePerPageList: perPageList(sizePerPageList),
        showTotal: true,
        withFirstAndLast: false,
        alwaysShowAllBtns: true,
        hidePageListOnlyOnePage: true,
        pageListRenderer,
        paginationTotalRenderer,
        onPageChange,
    });
};