import React, { useState, useContext, useEffect } from 'react';
import NormalModal from 'components/Modals/NormalModal';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { CallListService } from 'services/CallListService';
import atachmentImg from 'images/attachment.svg';
import MultiStepHeaderModal from 'components/Modals/MultiStepHeaderModal';
import BulkContactsStepsHandler from './BulkContactsStepsHandler';
import BulkContactsHeaderHandler from './BulkContactsHeaderHandler';

const BulkContactsModal = props => {
	const { onCloseModal, selectedCallList, selectedSites, fetchContacts } = props;
	const [confirmBtnTitle, setConfirmBtnTitle] = useState('Execute');
	const [disableBtns, setDisableBtns] = useState(true);
	const [changesOccuredFlag, setChangesOccuredFlag] = useState(0);
	const [showConfirmPopModal, setShowConfirmPopModal] = useState(false);
	const [barsColors, setbarsColors] = useState(['#007E8F', '#D8E1E8']);
	const [barsTitles, setBarsTitles] = useState('Step 1 - Import Excel file');
	const [currentStep, setCurrentStep] = useState(1);
	const [loading, setLoading] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [formData, setFormData] = useState();
	const [validationResData, setValidationRes] = useState();
	const [validContacts, setValidContacts] = useState([]);
	const context = useContext(ToasterContext);
	const [uploadText, setUploadText] = useState('Upload File');
	const [xslContentClass, setxslContentClass] = useState('');
	const [xslContent, setXslContent] = useState('');
	const [xslImg, setXslImg] = useState(atachmentImg);
	const [backBtn, setBackBtn] = useState(false);
	const [uploadLabel, setUploadLabel] = useState('Import a file');

	const back = () => {
		setUploadLabel('Import a file');
		setBackBtn(false);
		setDisableBtns(false);
		setCurrentStep(currentStep - 1);
	};
	const closeConfirmPopUpModal = () => {
		setShowConfirmPopModal(false);
	};

	const next = () => {
		setLoading(true);
		setUploadLabel('Loading...');
		CallListService.validateBulkContactsFile(formData)
			.then(resp => {
				validate(resp);
			})
			.catch(err => {
				if (err.statusCode == 400) {
					validate(err);
				} else {
					context('Something went wrong!', Intent.DANGER);
					setLoading(false);
					setCurrentStep(1);
				}
			});
	};

	const handleSubmit = event => {
		setModalLoading(true);
		const callListId = selectedCallList.value;

		CallListService.bulkAddContacts(validContacts, callListId)
			.then(resp => {
				if (resp.statusCode == 201) {
					context('Contacts successfully created', Intent.SUCCESS);
				} else {
					context('Contacts cannot be created', Intent.DANGER);
				}

				onCloseModal();
				fetchContacts();
				setModalLoading(false);
			})
			.catch(() => {
				context('Something went wrong!', Intent.DANGER);
				setModalLoading(false);
				onCloseModal();
			});
	};

	useEffect(() => {
		switch (currentStep) {
			case 1:
				setConfirmBtnTitle('Execute');
				setbarsColors(['#007E8F', '#D8E1E8']);
				setBarsTitles('Step 1 - Import Excel file');
				break;
			case 2:
				setConfirmBtnTitle('Import');
				setbarsColors(['#007E8F', '#007E8F']);
				setBarsTitles('Step 2 - File Validation');
				break;
		}
	}, [currentStep]);
	const confirmAction = () => {
		switch (currentStep) {
			case 1:
				return next();
			case 2:
				setBackBtn(true);
				return handleSubmit();
		}
	};

	function validate(resp) {
		if (
			resp.data.invalid_headers == undefined &&
			resp.data.invalid_contacts_count == 0 &&
			resp.data.valid_contacts_count == 0
		) {
			context('Excel file is empty!', Intent.DANGER);
			setCurrentStep(1);
		} else {
			setValidationRes(resp.data);
			if (resp.data.valid_contacts_count != undefined && resp.data.valid_contacts_count > 0) {
				setValidContacts(resp.data.valid_contacts);
				setDisableBtns(false);
			} else {
				setDisableBtns(true);
			}
			setBackBtn(true);
			setCurrentStep(currentStep + 1);
		}
		setLoading(false);
		setUploadLabel('Import a file');
	}

	const cancelBtnClickHandler = () => {
		if (changesOccuredFlag == 1) {
			setShowConfirmPopModal(true);
		} else {
			onCloseModal();
		}
	};
	return (
		<>
			<NormalModal
				setModalToShow="CallListBulkContactsModal"
				onConfirmAction={confirmAction}
				onCancelAction={cancelBtnClickHandler}
				header="Add Multiple Contacts"
				confirmBtnTitle={confirmBtnTitle}
				cancelBtnTitle="Cancel"
				confirmBtnDisabled={disableBtns}
				showBackArrow={backBtn}
				backArrowAction={back}
				onCloseAction={cancelBtnClickHandler}
				loading={modalLoading}
			>
				<BulkContactsHeaderHandler
					selectedCallList={selectedCallList}
					selectedSites={selectedSites}
				/>
				<MultiStepHeaderModal bars={barsColors} headerTitle={barsTitles} />
				<BulkContactsStepsHandler
					next={next}
					currentStep={currentStep}
					setDisableBtns={setDisableBtns}
					back={back}
					setChangesOccuredFlag={setChangesOccuredFlag}
					loading={loading}
					setLoading={setLoading}
					setFormData={setFormData}
					validationResData={validationResData}
					uploadText={uploadText}
					setUploadText={setUploadText}
					xslContentClass={xslContentClass}
					setxslContentClass={setxslContentClass}
					setXslContent={setXslContent}
					xslImg={xslImg}
					setXslImg={setXslImg}
					xslContent={xslContent}
					uploadLabel={uploadLabel}
					setUploadLabel={setUploadLabel}
				/>
			</NormalModal>
			{showConfirmPopModal && (
				<UnsavedChangesModal
					onConfirmAction={onCloseModal}
					onCancelAction={closeConfirmPopUpModal}
					confirmBtnTitle="Continue"
					backBtnTitle="Cancel"
					header="Unsaved changes"
					message="The changes you have made have not been saved. Do you want to continue?"
				/>
			)}
		</>
	);
};

export default BulkContactsModal;
