import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Tooltip } from 'componentsV2';
import InputWrapper from 'componentsV2/InputWrapper/InputWrapper';
import { PartnersService } from 'services';
import './css/AlarmType911List.scss';

export const AlarmType911List = ({
	formData,
	formErrors,
	onChangeFormData,
	disabled = false,
	tooltipTitle = null,
}) => {
	const [partnerAlarmTypes, setPartnerAlarmTypes] = useState([]);
	const isDisabled = formData.contact911 == null || formData.contact911 == false || disabled;
	const tooltipProps = isDisabled
		? {
				tooltipTitle: tooltipTitle
					? tooltipTitle
					: 'Since this alarm is set to not be configured with your 911Cellular configuration, there is no need to select an alarm to match to 911Cellular.',
				theme: 'dark',
		  }
		: null;

	useEffect(() => {
		let alarmTypeOptionsList = [];

		PartnersService.getAllPartnersAlarmTypes().then(resp => {
			if (resp.statusCode === 200) {
				resp.data.forEach(alarmType => {
					alarmTypeOptionsList.push({
						label: alarmType.name,
						value: alarmType.id,
						alarmType: alarmType.alarmType,
					});
				});
				setPartnerAlarmTypes(alarmTypeOptionsList);
			}
		});
	}, []);

	const disabledOptions = partnerAlarmTypes
		.filter(
			partnerAlarmType =>
				partnerAlarmType.alarmType &&
				(!formData.id || formData.id !== partnerAlarmType.alarmType.id),
		)
		.map(partnerAlarmType => partnerAlarmType.value);

	const onSelectAlarmTypeHandler = value => {
		onChangeFormData({ type: 'alarmTypes911', payload: value });
	};

	const WrapperComponent = isDisabled ? Tooltip : React.Fragment;

	return (
		<div className="row alarmType911_Wrapper">
			<div className="col-md-12">
				<InputWrapper
					label="Select 911Cellular Alarm"
					isRequired={true}
					labelState={true}
					hasHintText={true}
					hintText="If an alarm has already been mapped in 911Cellular it will be grayed out and not selectable."
				>
					<WrapperComponent {...tooltipProps}>
						<Dropdown
							hasSearch={true}
							labelState={true}
							placeholder={isDisabled ? 'No mapping' : 'Select alarm'}
							isRequired={true}
							disabled={isDisabled ? true : false}
							options={partnerAlarmTypes}
							value={formData.alarmTypes911}
							onChange={onSelectAlarmTypeHandler}
							error={formErrors?.alarmTypes911 ? true : false}
							errorMessage={
								formErrors?.alarmTypes911 ? formErrors.alarmTypes911 : null
							}
							disabledOptions={disabledOptions}
							className="w-100"
						/>
					</WrapperComponent>
				</InputWrapper>
			</div>
		</div>
	);
};

AlarmType911List.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
	disabled: PropTypes.bool,
};
