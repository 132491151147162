import React, { useState } from "react";
import { TabPreview } from "./components";
import PropTypes from 'prop-types';
import './css/TabPreviewList.scss';

export const TabPreviewList = ({
    list = []
}) => {
    const [openTabId, setOpenTabId] = useState(false);

    return (
        <div className="tab-preview-list-container">
            {list.map((item, index) => (
                <>
                    {openTabId === false && (
                        <TabPreview 
                            key={index}
                            id={item.id}
                            title={item.title}
                            supportText={item?.supportText}
                            content={item?.content}
                            icon={item?.icon}
                            textColor={item?.textColor}
                            backgroundColor={item.backgroundColor}
                            isOpen={false}
                            setIsOpen={setOpenTabId}
                        />
                    )}
                    {openTabId === item.id && (
                        <TabPreview
                            key={index}
                            id={item.id}
                            title={item.title}
                            supportText={item?.supportText}
                            content={item?.content}
                            icon={item?.icon}
                            textColor={item?.textColor}
                            backgroundColor={item.backgroundColor}
                            isOpen={true}
                            setIsOpen={setOpenTabId}
                        />
                    )}
                </>
            ))}
        </div>
    )
}

TabPreviewList.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            supportText: PropTypes.string,
            content: PropTypes.string,
            icon: PropTypes.string,
            textColor: PropTypes.string,
            backgroundColor: PropTypes.string,
        })
    ).isRequired,
}