/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dialog, Classes, MenuItem, Icon } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import Swal from 'sweetalert2';
import { Select } from '@blueprintjs/select';
import { format } from 'date-fns';

const CreateDialog = styled(Dialog)`
	width: 550px !important;
	font-family: 'Nunito Sans';
`;

const DialogFooterWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 10px;
`;

const DropdownWrap = styled.div`
	padding: 10px;
	width: 100%;
	background: #ffffff;
	border: 1px solid #d8e1e8;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
	min-width: 315px;
`;

const SelectWrap = styled(Select)`
	display: block;
	width: 100%;
	.bp3-popover-target {
		display: block;
		width: 100%;
	}
`;

class ExportSectionModal extends React.Component {
	constructor() {
		super();
		this.state = {
			name: '',
			loading: false,
			selectedSection: null,
			selectedSectionArray: [],
			selectedItem: null,
		};
	}

	handleExportClick = () => {
		Swal.fire('Exporting');
		Swal.showLoading();
		const { assessmentId } = this.props;
		const name = 'All PDF';
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { selectedSectionArray } = this.state;
		const sectionIds = selectedSectionArray.map(item => item.id);
		fetch(`${process.env.API_URL}/assessments/${assessmentId}/export`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({ sectionIds: [...sectionIds] }),
		})
			.then(res => res.blob())
			.then(resp => {
				Swal.close();
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				}).then(() => {
					const filename = `${name} ${format(
						new Date(),
						'hh-mm-ss a',
					)}`;
					const data = new Blob([resp], {
						type: 'application/pdf',
					});
					const csvURL = window.URL.createObjectURL(data);
					const tempLink = document.createElement('a');
					tempLink.href = csvURL;
					tempLink.setAttribute('download', `${filename}.pdf`);
					tempLink.click();
				});
			});
	};

	importSection = item => (
		<MenuItem
			key={item.id}
			text={item.name}
			onClick={() => {
				const { selectedSectionArray } = this.state;
				const hasItem = selectedSectionArray.some(
					sectionItem => sectionItem.id === item.id,
				);
				if (!hasItem) {
					this.setState({
						selectedSectionArray: [
							...this.state.selectedSectionArray,
							item,
						],
					});
				}
			}}
		/>
	);

	render() {
		const { closeExportSection, sections, name } = this.props;
		const { selectedSection, selectedSectionArray, loading } = this.state;
		return (
			<CreateDialog
				isOpen
				onClose={closeExportSection}
				title="Export Sections"
				style={{
					color: '#10161a',
					lineHeight: '21px',
					fontFamily: 'Merriweather',
					fontStyle: 'normal',
					fontSize: '18px',
				}}
				{...this.state}
			>
				<h4
					style={{
						color: '#10161a',
						fontFamily: 'Merriweather',
						fontStyle: 'normal',
						fontSize: '18px',
						marginLeft: 20,
						marginTop: 20,
						fontWeight: 700,
					}}
				>
					{name}
				</h4>
				<div
					style={{
						marginTop: 10,
					}}
					className={Classes.DIALOG_BODY}
				>
					<h4
						style={{
							color: '#10161a',
							lineHeight: '21px',
							fontFamily: 'Nunito Sans',
							fontStyle: 'normal',
							fontSize: '16px',
						}}
					>
						Selected Section(s)
					</h4>

					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							{selectedSectionArray &&
								selectedSectionArray.map((item, idx) => (
									<>
										<div>
											<ul key={idx}>
												<li
													style={{
														color: '#10161a',
														fontFamily:
															'Nunito Sans',
														fontSize: '16px',
													}}
												>
													{item.name}
												</li>
											</ul>
										</div>
									</>
								))}
							<SelectWrap
								items={sections}
								itemRenderer={this.importSection}
								className="w-100"
								filterable={false}
							>
								<DropdownWrap>
									<div
										style={{
											color: '#738694',
											fontFamily: 'Nunito Sans',
											fontStyle: 'normal',
											fontSize: '16px',
										}}
									>
										{selectedSection
											? selectedSection.name
											: 'Select by title of section'}
									</div>
									<Icon icon="caret-down" />
								</DropdownWrap>
							</SelectWrap>
						</>
					)}
				</div>
				<DialogFooterWrapper>
					<Button
						style={{
							marginRight: 16,
							width: 'auto',
							fontFamily: 'Nunito Sans',
						}}
						onClick={closeExportSection}
						text="Cancel"
						intent="none"
					/>
					<Button
						style={{
							height: 40,
							width: 'auto',
							background: '#9F1C3A',
							fontFamily: 'Nunito Sans',
						}}
						onClick={this.handleExportClick}
						text="Export Selected Sections"
						intent="danger"
					/>
				</DialogFooterWrapper>
			</CreateDialog>
		);
	}
}

ExportSectionModal.propTypes = {
	sections: PropTypes.any,
	closeExportSection: PropTypes.func,
	assessmentId: PropTypes.any,
	name: PropTypes.any,
};

export default ExportSectionModal;
