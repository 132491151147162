import React, { useContext, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
	Dropdown,
	FormModal,
	Input,
	TextArea,
	RadioButtonGroup,
	FeaturedIcon,
	ToasterContext,
	Intent,
} from 'componentsV2';
import '../styles.scss';
import { StatesService, SchoolYearsService, AssessTemplatesService } from 'services';
import { Radio } from '@blueprintjs/core';
import { isValidEmail, isNotEmpty } from '../../../../../utilities/validations';

export const EditTemplateDetailsModal = ({
	onClose,
	templateData,
	refreshTemplatesData,
	isCoreTemplate,
}) => {
	const [yearsOptions, setYearsOptions] = useState(null);
	const [stateOptions, setStateOptions] = useState(null);
	const [stateValue, setStateValue] = useState(null);
	const [schoolYearValue, setSchoolYearValue] = useState(null);
	const toaster = useContext(ToasterContext);
	const [templateDataDetails, setTemplateDataDetails] = useState({
		name: templateData?.name || '',
		description: templateData?.summary || '',
		emails: templateData?.emails || '',
		isProprietary: `${templateData?.isProprietary}` || null,
		notes: templateData?.notes || '',
		active: templateData?.active || null,
		published: templateData?.published || null,
	});
	const [validationErrors, setValidationErrors] = useState({
		name: { hasError: false, errorMsg: '' },
		emails: { hasError: false, errorMsg: '' },
		isProprietary: { hasError: false, errorMsg: '' },
		stateValue: { hasError: false, errorMsg: '' },
		schoolYearValue: { hasError: false, errorMsg: '' },
	});

	useEffect(() => {
		getStates();
		getYears();
	}, []);

	const getStates = () => {
		StatesService.getAll(true).then(states => {
			// All states label and value is not returned in the getAll(), therefore adding this label in the states array
			states.unshift({
				id: 0,
				abbreviation: 'All',
				label: 'All States',
				name: 'All States',
				value: 'all states',
			});
			let currentState = states.find(
				state =>
					state.abbreviation.toLowerCase() === templateData?.state.toLowerCase() ||
					state.name.toLowerCase() === templateData?.state.toLowerCase(),
			);
			setStateValue(currentState);
			setStateOptions(states);
		});
	};

	const getYears = () => {
		SchoolYearsService.fetchYears(true).then(years => {
			let currentYear = years.find(year => year.value === templateData?.year);
			// Only 5 year values are returned in the fetch call. Therefore adding the template year into this array.
			if (!currentYear) {
				let templateDataYear = {
					value: templateData?.year,
					label: `${templateData?.year} - ${templateData?.year + 1}`,
				};
				years.unshift(templateDataYear);
				setSchoolYearValue(templateDataYear);
			} else {
				setSchoolYearValue(currentYear);
			}
			setYearsOptions(years);
		});
	};

	const handleInputChange = event => {
		const { name, value } = event.target;
		setTemplateDataDetails({
			...templateDataDetails,
			[name]: value,
		});

		if (name !== 'description') {
			let inputValidation = {
				[name]: { hasError: false, errorMsg: '' },
			};
			setValidationErrors({ ...validationErrors, ...inputValidation });
		}
	};

	const emailValidation = () => {
		if (templateDataDetails.emails.length === 0) return true;
		let emails = templateDataDetails.emails.split(',').map(email => email.trim());
		for (let email of emails) {
			if (!isValidEmail(email)) {
				return false;
			}
		}

		return true;
	};

	const checkModalValidation = () => {
		let errors = {};

		if (!isNotEmpty(templateDataDetails.name)) {
			errors.name = { hasError: true, errorMsg: 'Title is required' };
		}
		if (!stateValue) {
			errors.stateValue = { hasError: true, errorMsg: 'Please select valid state' };
		}
		if (!schoolYearValue) {
			errors.schoolYearValue = { hasError: true, errorMsg: 'Please select valid year' };
		}
		if (!isNotEmpty(templateDataDetails.isProprietary)) {
			errors.isProprietary = { hasError: true, errorMsg: 'Selection is required' };
		}

		if (!emailValidation()) {
			errors.emails = { hasError: true, errorMsg: 'Please enter valid email' };
		}
		return errors;
	};

	const submitTemplateDetails = () => {
		let errors = checkModalValidation();
		setValidationErrors({ ...validationErrors, ...errors });
		if (Object.keys(errors).length === 0) {
			let templateDetails = {
				name: templateDataDetails.name,
				publish: templateDataDetails.published,
				state: stateValue.abbreviation,
				year: schoolYearValue.value,
				notes: templateDataDetails.notes,
				active: templateDataDetails.active,
				emails: templateDataDetails.emails,
				proprietary:
					templateDataDetails.isProprietary === null
						? null
						: templateDataDetails.isProprietary === 'true',
			};
			AssessTemplatesService.editTemplate(templateData?.id, templateDetails)
				.then(() => {
					refreshTemplatesData();
					toaster(
						`You have successfully edited the template details.`,
						Intent.SUCCESS,
						<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
					);
				})
				.catch(error => {
					console.log('error', error);
					toaster(
						`There was an error editing the template details. Please try again!`,
						Intent.DANGER,
						<FeaturedIcon icon="error" type="Error" />,
					);
				})
				.finally(() => {
					onClose();
				});
		}
	};

	return (
		<>
			<FormModal
				isOpen
				onClose={onClose}
				showCloseButton
				title="Edit template details"
				confirmBtn={{
					title: 'Update',
					onClick: submitTemplateDetails,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: () => onClose(),
				}}
			>
				<div className="w-100">
					<Input
						label="Title"
						hasHintText={true}
						hintText="ex. Playground Safety Assessment "
						isRequired={true}
						width="100%"
						name="name"
						value={templateDataDetails.name}
						error={validationErrors.name.hasError}
						errorMessage={validationErrors.name.errorMsg}
						onChange={handleInputChange}
						placeholder="Enter title"
					/>
				</div>
				<div className="edit-template-modal">
					<div className="w-100">
						<Dropdown
							label="State"
							options={stateOptions}
							isRequired
							optionLabel="abbreviation"
							optionValue="name"
							value={stateValue}
							onChange={item => {
								setStateValue(item);
								setValidationErrors({
									...validationErrors,
									stateValue: { hasError: false, errorMsg: '' },
								});
							}}
							error={validationErrors.stateValue.hasError}
							errorMessage={validationErrors.stateValue.errorMsg}
						/>
					</div>
					<div className="w-100">
						<Dropdown
							label="School Year"
							options={yearsOptions}
							isRequired
							value={schoolYearValue}
							onChange={item => {
								setSchoolYearValue(item);
								setValidationErrors({
									...validationErrors,
									schoolYearValue: { hasError: false, errorMsg: '' },
								});
							}}
							error={validationErrors.schoolYearValue.hasError}
							errorMessage={validationErrors.schoolYearValue.errorMsg}
						/>
					</div>
				</div>
				{isCoreTemplate && (
					<RadioButtonGroup
						label="Proprietary template"
						selectedValue={templateDataDetails.isProprietary}
						onChange={handleInputChange}
						name="isProprietary"
						error={validationErrors.isProprietary.hasError}
						errorMessage={validationErrors.isProprietary.errorMsg}
						size="medium"
						bordered
						required
						layout="inline"
						fullwidth
					>
						<Radio label="Yes" value="true" />
						<Radio label="No" value="false" />
					</RadioButtonGroup>
				)}
				<div className="w-100 mt-1">
					<Input
						label="Add recipients for notification upon assessment completion"
						width="100%"
						hasHintText
						hintText="Enter as comma separated list."
						placeholder="Enter recipients"
						value={templateDataDetails.emails}
						onChange={handleInputChange}
						name="emails"
						error={validationErrors.emails.hasError}
						errorMessage={validationErrors.emails.errorMsg}
					/>
				</div>
				<div className="w-100 mt-1">
					<TextArea
						label="Description"
						value={templateDataDetails.notes}
						onChange={handleInputChange}
						name="notes"
						placeholder="Enter description"
					/>
				</div>
			</FormModal>
		</>
	);
};

EditTemplateDetailsModal.propTypes = {
	onClose: PropTypes.func,
	templateData: PropTypes.object,
	refreshTemplatesData: PropTypes.func,
	isCoreTemplate: PropTypes.bool,
};
