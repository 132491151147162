import htmlparser2 from 'htmlparser2';

const stripTags = htmlInput => {
	let content = '';
	const parser = new htmlparser2.Parser({
		ontext(text) {
			content = text;
		},
	});
	parser.write(htmlInput);
	parser.end();
	return content;
};

export { stripTags };
