import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '@blueprintjs/core';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';

class TextAreaQuestion extends React.Component {
	constructor() {
		super();
		this.state = {};
		this.debounceAnswer = debounce(this.addAnswer, 1000);
	}

	addAnswer = e => {
		Swal.fire('Saving');
		Swal.showLoading();
		const { templateId, questionId } = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${
				process.env.API_URL
			}/assessments/${templateId}/questions/${questionId}/answers`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					response: {
						textarea: e.target.value,
					},
				}),
			},
		)
			.then(res => res.json())
			.then(() => {
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				});
			});
	};

	render() {
		const { item } = this.props;
		return (
			<TextArea
				defaultValue={
					item.answer && item.answer.response
						? item.answer &&
						  JSON.parse(item.answer.response).textarea
						: ''
				}
				className="w-100"
				growVertically
				large
				onChange={this.debounceAnswer}
			/>
		);
	}
}

TextAreaQuestion.propTypes = {
	questionId: PropTypes.number,
	templateId: PropTypes.any,
	item: PropTypes.object,
};

export default TextAreaQuestion;
