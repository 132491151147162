import React, { useState } from 'react';
import { FormModal, Input } from 'componentsV2';

export const CopyTemplateSectionModal = ({
	sectionModalTitle,
	handleCloseModal,
	isOpen,
	loading,
	submitSection,
	inputHintText = '',
	confirmBtnText,
	inputLabelText,
	inputLabelPlaceholderText,
}) => {
	const [titleValue, setTitleValue] = useState('');

	return (
		<FormModal
			isOpen={isOpen}
			loading={loading}
			loadingMessage="Copying..."
			title={sectionModalTitle}
			showCloseButton={false}
			onClose={handleCloseModal}
			scrollContent={false}
			cancelBtn={{ title: 'Cancel', onClick: handleCloseModal }}
			confirmBtn={{
				title: `${confirmBtnText} `,
				onClick: () => submitSection(titleValue),
				type: 'primaryDefault',
				disabled: !titleValue,
			}}
		>
			<div className="w-100 mt-1">
				<Input
					label={inputLabelText}
					width="100%"
					isRequired
					hasHintText={inputHintText.length > 0}
					hintText={inputHintText}
					placeholder={inputLabelPlaceholderText}
					value={titleValue}
					onChange={e => setTitleValue(e.target.value)}
					name="title"
				/>
			</div>
		</FormModal>
	);
};
