import React from 'react';
import PropTypes from 'prop-types';
import { checkMark } from 'assets/icons';
import './Autosave.scss';

const Autosave = ({ autoSaveValue }) => {
	const isDate = autoSaveValue instanceof Date;
	const date = autoSaveValue.toLocaleString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});

	return (
		<div className="auto-save">
			{isDate ? (
				<>
					<img alt="" src={checkMark} />
					Auto saved &#8226;&#160;
					{date}
				</>
			) : (
				'Not saved'
			)}
		</div>
	);
};

export { Autosave };

Autosave.propTypes = {
	autoSaveValue: PropTypes.oneOf([PropTypes.instanceOf(Date), PropTypes.string]),
};
