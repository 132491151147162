import React, { useState, useEffect, useContext } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Intent } from '@blueprintjs/core';
import { NavLink } from 'react-router-dom';
import { ToasterContext } from 'pages/App';
import { UsersService } from 'services/UsersService';
import NewUserModel from './NewUserMode';
import BulkUsersModal from './BulkUsersModal';
import UserFilters from './UserFilters';
import { NoResultFound } from 'components/NoResultFound';
import { NoResultFoundIcon, addUser } from 'assets/icons';
import './css/index.scss';
import { RolesService } from 'services/RolesService';
import SiteColumn from './SiteColumn';
import AddUserButton from './AddUserButton';
import UserManagement from '..';
import { BuildingsService } from 'services/BuildingsService';
import { checkPermission, filterSitesPerPermissionCode } from 'utilities/permissions';
import { BootstrapedTable, LoadingSpinner, sortingStyle } from 'componentsV2';
import { useDispatch, useSelector } from 'react-redux';
import { SORTING_DIR } from 'utilities/constants';
import useSelectedSites from 'hooks/useSelectedSites';
import { formatDate } from 'utilities/dates';
import { setUserManagementFiltersData } from '../../../routeReducer';

const Users = () => {
	const url = process.env.API_URL;
	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const useManagementFiltersDataValue = useSelector(
		state => state.route.userManagementFiltersData,
	);
	const [users, setUsers] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [modalToShow, setModalToShow] = useState(false);
	const [sitesHaveNoUsers, setSitesHaveNoUsers] = useState(false);
	const [siteList, setSitesList] = useState([]);
	const [filteredSitesList, setFilteredSitesList] = useState({
		data: [],
		loaded: false,
	});
	const [rolesList, setRolesList] = useState([]);
	const [sortKey, setSortKey] = useState('name');
	const [sortDir, setSortDir] = useState(SORTING_DIR.ASC);
	const [includeDisabledUsers, setIncludeDisabledUsers] = useState(
		useManagementFiltersDataValue.disabledUsersCheck,
	);
	const [includeDisabledSites, setIncludeDisabledSites] = useState(false);
	const [includeNeverLoggedUsers, setIncludeNeverLoggedUsers] = useState(false);
	const [searchUsers, setSearchUsers] = useState(useManagementFiltersDataValue.searchUsersValue);
	const [emailLogin, setEmailLogin] = useState('');
	const [showTagInput, setShowTagInput] = useState(false);
	const [searchBarTags, setSearchBarTags] = useState([]);
	const [staffTitle, setStaffTitle] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const toaster = useContext(ToasterContext);
	const loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
	const [selectedSites, setSelectedSites] = useState(
		useManagementFiltersDataValue.selectedSitesValue,
	);
	const [selectedRoles, setSelectedRoles] = useState(
		useManagementFiltersDataValue.selectedRolesValue,
	);
	const permissions = useSelector(state => state.route.permissions);
	let userEditPermission = checkPermission('user_edit', permissions);
	const dispatch = useDispatch();

	useEffect(() => {
		getSites();
		getRolesList();
	}, []);

	useEffect(() => {
		checkSelectedSitesHaveUsers();
	}, [selectedSites]);

	useEffect(() => {
		setLoading(true);
		fetchUsers()
			.then(() => setLoading(false))
			.catch(() => setLoading(false));
	}, [
		selectedSites,
		searchUsers,
		selectedRoles,
		includeDisabledUsers,
		emailLogin,
		staffTitle,
		includeNeverLoggedUsers,
		currentPage,
		perPage,
	]);
	useEffect(() => {
		fetchUsers()
			.then(() => setLoading(false))
			.catch(() => setLoading(false));
	}, [sortKey, sortDir]);

	const submitNewUser = event => {
		fetch(`${url}/users/add`, {
			method: 'POST',
			headers: {
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				first_name: event.target.f_name.value,
				last_name: event.target.l_name.value,
				email: event.target.email.value,
				site_id: event.site_id,
				role_id: event.role_id,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					const userId = resp.data.id;
					sessionStorage.setItem(
						'flashSuccessMsg',
						'Successfully created a new user. Explore the settings side panel to customize this account further.',
					);
					window.location.replace(
						`${location.protocol}//${location.host}/users/view/${userId}`,
					);
				} else {
					toaster('Some thing went Wrong ,user has not been created.', Intent.DANGER);
				}
				setModalToShow(false);
			});
	};

	const getSites = () => {
		BuildingsService.getAll(false, true).then(serviceBuildings => {
			setSitesList(serviceBuildings);
			const filteredSites = filterSitesPerPermissionCode(
				'user_edit',
				permissions,
				serviceBuildings,
			);
			const filteredObject = { data: filteredSites, loaded: true };
			setFilteredSitesList(filteredObject);
		});
	};

	const getRolesList = () => {
		RolesService.search().then(res => {
			var rolesList = [];
			res?.data.map(role => {
				if (role.id != 3) {
					//exclude first responder role from list
					rolesList.push({
						label: role.title,
						value: role.id,
					});
				}
			});
			setRolesList(rolesList);
		});
	};

	const checkSelectedSitesHaveUsers = () => {
		var selectedSitesValues = [];
		selectedSites?.map(selectedSite => {
			if (selectedSite.value != '*') {
				selectedSitesValues.push(selectedSite.value);
			}
		});
		UsersService.search({
			page: 1,
			perPage: 1,
			sites: selectedSitesValues,
			includeDisabled: true,
		})
			.then(response => {
				if (response._metadata.totalRecords == 0) {
					setSitesHaveNoUsers(true);
				} else {
					setSitesHaveNoUsers(false);
				}
			})
			.catch(err => setSitesHaveNoUsers(false));
	};

	const fetchUsers = async () => {
		// get users if sites have users
		if (!sitesHaveNoUsers) {
			var selectedSitesValues = [];
			selectedSites?.map(selectedSite => {
				if (selectedSite.value != '*') {
					selectedSitesValues.push(selectedSite.value);
				}
			});

			var selectedRolesValues = [];
			const selectAllRolesIncluded = selectedRoles?.some(selectedRole => {
				return selectedRole.value == '*';
			});
			if (!selectAllRolesIncluded) {
				selectedRoles?.map(selectedRole => {
					if (selectedRole.value != '*') {
						selectedRolesValues.push(selectedRole.value);
					}
				});
			}

			var staffTitleValues = [];
			const selectAllStaffIncluded = staffTitle?.some(selectedRole => {
				return selectedRole.value == '*';
			});
			if (!selectAllStaffIncluded) {
				staffTitle?.map(staff => {
					if (staff.value != '*') {
						staffTitleValues.push(staff.value);
					}
				});
			}
			await UsersService.search({
				page: currentPage,
				perPage: perPage,
				sortKey: sortKey,
				sortDir: sortDir,
				sites: selectedSitesValues,
				roles: selectedRolesValues,
				combineSearch: searchUsers,
				email: emailLogin,
				includeNeverLoggedUsers: includeNeverLoggedUsers,
				staffTitles: staffTitleValues,
				includeDisabled: includeDisabledUsers,
			})
				.then(response => {
					setUsers(response.data);
					setTotalRows(response._metadata.totalRecords);
				})
				.catch(() => console.log('error'));
		}
	};

	const userViewLink = rowId => {
		if (rowId == loggedUser.id) {
			return '/users/myprofile';
		}
		return `/users/view/${rowId}`;
	};

	const columns = [
		{ dataField: 'uniqid', text: 'ID', hidden: true },
		{
			dataField: 'person.fullName',
			formatter: (value, row) => (
				<NavLink
					className={row.person.status === 1 ? 'active' : 'user-disabled'}
					to={userViewLink(row.id)}
				>
					{row.person.status === 2 ? (
						<div style={{ display: '-webkit-box' }}>
							<p> {value}</p>
							<span className="user-disabled-box">Disabled</span>
						</div>
					) : (
						<>{value}</>
					)}
				</NavLink>
			),
			text: 'Name',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(sortKey === 'name' ? sortDir : order, column),
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'person.title',
			text: 'Staff Title',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(sortKey === 'title' ? sortDir : order, column),
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'primaryRole.title',
			text: 'Role Type',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(sortKey === 'role' ? sortDir : order, column),
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'primaryBuilding.name',
			text: 'Site',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(sortKey === 'site' ? sortDir : order, column),
			formatter: (value, row) => <SiteColumn value={value} row={row} />,
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'login',
			text: 'Login Info',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(sortKey === 'login' ? sortDir : order, column),
			headerStyle: { width: '16%' },
		},
		{
			dataField: 'lastLogin',
			text: 'Last Login',
			sort: true,
			formatter: (value, row) => (value && value != 'N/A' ? formatDate(value) : 'N/A'),
			sortCaret: (order, column) =>
				sortingStyle(sortKey === 'lastLogin' ? sortDir : order, column),
			headerStyle: { width: '16%' },
			formatter: (cell, row) => {
				if (cell !== 'N/A') {
					return formatDate(cell);
				}

				return cell;
			},
		},
	];

	const selectRow = {
		mode: 'checkbox',
		clickToSelect: false,
		onSelect: (row, isSelect, rowIndex, e) => {
			if (isSelect) {
				setSelectedRows([...selectedRows, row]);
			} else {
				setSelectedRows(
					selectedRows.filter(selectedRow => {
						return selectedRow.id != row.id;
					}),
				);
			}
		},
		onSelectAll: (isSelect, rows, e) => {
			if (isSelect) {
				setSelectedRows(users);
			} else {
				setSelectedRows([]);
			}
		},
	};

	// this function has to be identified
	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'name';

			switch (sortField) {
				case 'person.fullName':
					sortColumn = 'name';
					break;
				case 'person.title':
					sortColumn = 'title';
					break;
				case 'primaryRole.title':
					sortColumn = 'role';
					break;
				case 'primaryBuilding.name':
					sortColumn = 'site';
					break;
				case 'login':
					sortColumn = 'login';
					break;
				case 'lastLogin':
					sortColumn = 'lastLogin';
					break;
				default:
					sortColumn = 'name';
					break;
			}
			setSortKey(sortColumn);
			setSortDir(sortOrder);
		}
	};

	const advancedSearchHandler = data => {
		setModalToShow(false);
		setSelectedRoles(data.roles);
		onSelectSitesHandler(data.sites);
		setIncludeDisabledUsers(data.includeDisabledUsers);
		setStaffTitle(data.staffTitle);
		setIncludeDisabledSites(data.includeDisabledSites);
		setIncludeNeverLoggedUsers(data.includeNeverLoggedUsers);
		setSearchUsers(data.userName);
		setEmailLogin(data.emailLogin);
		setCurrentPage(1);
		var tags = [
			{
				type: 'includeDisabledSites',
				key: 'includeDisabledSites',
				label: data.includeDisabledSites ? 'Include disabled sites' : false,
			},
			{
				type: 'neverLoggedUsers',
				key: 'neverLoggedUsers',
				label: data.includeNeverLoggedUsers ? 'Never logged users' : false,
			},
			{
				type: 'searchUsers',
				key: 'searchUsers',
				label: data.userName != '' ? data.userName : false,
			},
			{
				type: 'emailLogin',
				key: 'emailLogin',
				label: data.emailLogin != '' ? data.emailLogin : false,
			},
		];
		if (data.staffTitle.length > 0) {
			data.staffTitle.map(staffTitle => {
				if (staffTitle.value != '*') {
					tags.push({
						type: 'staffTitle',
						key: `staffTitle-${staffTitle.value}`,
						label: staffTitle.value,
					});
				}
			});
		}
		const tagsFiltered = tags.filter(tag => {
			return tag.label;
		});
		if (tagsFiltered.length > 0) {
			setShowTagInput(true);
			setSearchBarTags(tagsFiltered);
		} else {
			setShowTagInput(false);
		}
	};

	const advancedSearchCancelBtnHandler = () => {
		setModalToShow(false);
	};

	const onSearchUserHandler = search => {
		setSearchUsers(search);
		dispatch(setUserManagementFiltersData({ searchUsersValue: search }));

		if (search != '') {
			setCurrentPage(1);
		} else {
			setShowTagInput(false);
		}
	};

	const onDeleteTag = deletedTag => {
		switch (deletedTag.type) {
			case 'staffTitle':
				setStaffTitle(
					staffTitle.filter(staff => {
						return staff.label !== deletedTag.label;
					}),
				);
				break;
			case 'includeDisabledSites':
				setIncludeDisabledSites(false);
				break;
			case 'neverLoggedUsers':
				setIncludeNeverLoggedUsers(false);
				break;
			case 'searchUsers':
				setSearchUsers('');
				break;
			case 'emailLogin':
				setEmailLogin('');
				break;
			default:
				setSearchBarTags([]);
				break;
		}
		const newTags = searchBarTags.filter(searchBarTag => {
			return searchBarTag !== deletedTag;
		});
		setSearchBarTags(newTags);
		if (newTags.length == 0) {
			setShowTagInput(false);
		}
	};

	const clearSearchBarHandler = () => {
		setStaffTitle([]);
		setIncludeDisabledSites(false);
		setIncludeNeverLoggedUsers(false);
		setSearchUsers('');
		setEmailLogin('');
		setShowTagInput(false);
		setSearchBarTags([]);
	};

	const onCloseModal = () => {
		setLoading(false);
		setModalToShow(false);
	};

	const onSelectRolesHandler = roles => {
		dispatch(setUserManagementFiltersData({ selectedRolesValue: roles }));
		setSelectedRoles(roles);
		if (currentPage > 1) {
			setCurrentPage(1);
		}
	};

	const onDisableUsersHander = val => {
		setIncludeDisabledUsers(val);
		dispatch(setUserManagementFiltersData({ disabledUsersCheck: val }));
	};

	const onSelectSitesHandler = sites => {
		setSelectedSites(sites);
		dispatch({
			type: 'SET_SELECTED_SITES',
			payload: sites,
		});
		dispatch(setUserManagementFiltersData({ selectedSitesValue: sites }));
		if (currentPage > 1) {
			setCurrentPage(1);
		}
	};

	return (
		<UserManagement defaultSelectedTab="users">
			{modalToShow === 'NewUserModal' && (
				<NewUserModel
					sites={filteredSitesList.data}
					isOpen
					closeModal={() => setModalToShow(false)}
					formdata={submitNewUser}
					selectedSites={selectedSites}
				/>
			)}
			{modalToShow === 'BulkUsersModal' && (
				<BulkUsersModal
					isOpen
					closeModal={() => setModalToShow(false)}
					onCloseModal={onCloseModal}
					fetchUsers={fetchUsers}
				/>
			)}
			<UserFilters
				OnSelectSites={onSelectSitesHandler}
				OnSelectRoles={onSelectRolesHandler}
				OnSearchUsers={onSearchUserHandler}
				onDisableUsersHander={onDisableUsersHander}
				OnIncludeDisabled={setIncludeDisabledUsers}
				onDeleteTag={onDeleteTag}
				onClearSearchBar={clearSearchBarHandler}
				advancedSearchHandler={advancedSearchHandler}
				advancedSearchCancelBtnHandler={advancedSearchCancelBtnHandler}
				rolesList={rolesList}
				siteList={siteList}
				defaultSelectedSites={selectedSites}
				defaultSelectedRoles={selectedRoles}
				defaultIncludeDisabledUsers={includeDisabledUsers}
				defaultIncludeDisabledSites={includeDisabledSites}
				defaultIncludeNeverLoggedUsers={includeNeverLoggedUsers}
				defaultStaffTitle={staffTitle}
				defaultUserName={searchUsers}
				defaultEmailLogin={emailLogin}
				selectedRows={selectedRows}
				searchBarTags={searchBarTags}
				userEditPermission={userEditPermission}
				showAddUserBtn={sitesHaveNoUsers ? false : filteredSitesList.loaded ? true : false}
				showTagInput={showTagInput}
				setModalToShow={setModalToShow}
				modalToShow={modalToShow}
				fetchUsers={fetchUsers}
				onCloseModal={onCloseModal}
				setSelectedRows={setSelectedRows}
			/>
			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<>
					{sitesHaveNoUsers ? (
						<NoResultFound header="No users have been added yet" icon={addUser}>
							{userEditPermission && filteredSitesList.loaded && (
								<AddUserButton setModalToShow={setModalToShow} />
							)}
						</NoResultFound>
					) : (
						<>
							{users.length == 0 ? (
								<NoResultFound
									header="No users were found"
									icon={NoResultFoundIcon}
								>
									<p style={{ fontFamily: 'Nunito Sans' }}>
										{' '}
										Try changing the search criteria or add a new user. <br />
									</p>
								</NoResultFound>
							) : (
								<BootstrapedTable
									keyField="uniqid"
									data={users}
									columns={columns}
									bordered={false}
									hasCheckbox={userEditPermission}
									onSelectRows={setSelectedRows}
									selectedRows={selectedRows}
									className="mt-3"
									selectRow={userEditPermission ? selectRow : undefined}
									onTableChange={onTableChange}
									currentPage={currentPage}
									rowsPerPage={perPage}
									setCurrentPage={value => setCurrentPage(value)}
									setRowsPerPage={value => setPerPage(value)}
									totalRows={totalRows}
								/>
							)}
						</>
					)}
				</>
			)}
		</UserManagement>
	);
};

export default Users;
