import React from "react";
import '../css/BackgroundCircles.scss';

export const BackgroundCircles = ({
    children
}) => (
    <div className="background-circles-wrapper">
        <span className="circle-1">
            <span className="circle-2">
                <span className="circle-3">
                    <span className="circle-4">
                        {children}
                    </span>
                </span>
            </span>
        </span>
    </div>
);