import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Dialog } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import Swal from 'sweetalert2';
import { differenceInCalendarDays } from 'date-fns';
import AddTask from './addtask';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

const StyledTag = styled.div`
	background: #fcebeb;
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #db3737;
	border-radius: 10px;
	padding: 2px 8px;
`;

const StatusText = styled.p`
	font-family: Nunito Sans;
	font-style: italic;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #738694;
	margin-bottom: 0px;
	margin-left: 6px;
`;

const CompleteTag = styled(StyledTag)`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	border-radius: 10px;
	padding: 2px 8px;
	margin-left: 6px;
	background: ${props =>
		props.disposition === 'Complete' ? '#E8F4EF' : '#EBF1F5'} !important;
	color: ${props =>
		props.disposition === 'Complete' ? '#128C5A' : '#394B59'} !important;
`;

const EmptyState = styled.p`
	color: #5c7080;
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	text-align: center;
`;

class TasksModal extends React.Component {
	constructor() {
		super();
		this.state = { loading: true, showAddModal: false };
		this.fileInput = React.createRef();
	}

	getData = () => {
		this.setState({ loading: true, showAddModal: false });
		const { templateId, questionId } = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${process.env.API_URL}/assessments/${templateId}/questions/${questionId}/tasks`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${token}`,
				},
			},
		)
			.then(res => res.json())
			.then(resp => {
				this.setState({ loading: false, tasks: resp.data });
			});
	};

	deleteFile = id => {
		Swal.fire('Removing');
		Swal.showLoading();
		const { templateId } = this.props;

		const token = JSON.parse(localStorage.getItem('user')).jwt;

		fetch(`${process.env.API_URL}/assessments/${templateId}/files/${id}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => this.getData());
				}
			});
	};

	fileChange = e => {
		Swal.fire('Uploading');
		Swal.showLoading();
		const { templateId, questionId } = this.props;

		const token = JSON.parse(localStorage.getItem('user')).jwt;

		const fd = new FormData();
		fd.append('file', e.target.files[0]);
		fd.append('questionId', questionId);

		fetch(
			`${process.env.API_URL}/assessments/${templateId}/files?questionId=${questionId}`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: fd,
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => this.getData());
				} else if (resp.statusCode === 500) {
					closeAddModal();
					Swal.fire({
						title: 'Oops!',
						icon: 'error',
						text: 'Please answer the question to create a task.',
						showConfirmButton: true,
					});
				}
			});
	};

	getStatus = one => {
		const diffInDays = differenceInCalendarDays(
			new Date(one.due_date),
			new Date(),
		);

		if (one.closed_date !== null) {
			return (
				<div className="d-flex align-items-center">
					<CompleteTag disposition={one.disposition}>
						Closed: {one.disposition}
					</CompleteTag>
				</div>
			);
		}
		if (diffInDays < 0) {
			return (
				<div className="d-flex align-items-center ml-2">
					<StyledTag>Overdue</StyledTag>
					<StatusText>
						{Math.abs(diffInDays)} days past due
					</StatusText>
				</div>
			);
		}
		return null;
	};

	openAddModal = () => this.setState({ showAddModal: true });

	closeAddModal = () => this.setState({ showAddModal: false });

	componentDidMount() {
		this.getData();
	}

	render() {
		const {
			isOpen,
			closeTasksModal,
			templateId,
			questionId,
			item,
		} = this.props;
		const { loading, tasks, showAddModal } = this.state;
		return (
			<>
				{showAddModal && (
					<AddTask
						isOpen={showAddModal}
						closeAddModal={this.closeAddModal}
						getData={this.getData}
						templateId={templateId}
						questionId={questionId}
						item={item}
					/>
				)}
				<Dialog isOpen={isOpen} onClose={closeTasksModal}>
					<div>
						<TitleWrap>
							<Title>Tasks</Title>
							<Button
								text=""
								minimal
								icon="cross"
								onClick={closeTasksModal}
							/>
						</TitleWrap>
						<hr />
						{loading && <LoadingSpinner />}
						{!loading && (
							<>
								<div className="px-2">
									{tasks.length === 0 && (
										<EmptyState>No tasks</EmptyState>
									)}
									{tasks.map(one => (
										<div
											className="d-flex align-items-center mb-3"
											key={one.id}
										>
											<p className="mb-0">{one.title}</p>
											{this.getStatus(one)}
										</div>
									))}
								</div>
								<hr />
								<div className="d-flex justify-content-end mt-2 px-2">
									<Button
										text="Close"
										large
										intent="secondary"
										onClick={closeTasksModal}
									/>
									<Button
										text="Add Task"
										className="ml-2"
										large
										intent="primary"
										onClick={this.openAddModal}
									/>
								</div>
							</>
						)}
					</div>
				</Dialog>
			</>
		);
	}
}

TasksModal.propTypes = {
	isOpen: PropTypes.bool,
	templateId: PropTypes.number,
	questionId: PropTypes.number,
	closeTasksModal: PropTypes.func,
	item: PropTypes.object,
};

export default TasksModal;
