/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { VariableValue } from 'components/VariableValue';
import { Button } from 'components/Button';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

class VariableDialog extends React.Component {
	state = {
		variableValues: [],
		variablesIncluded: [],
		loading: true,
		canClose: true,
		isUpdated: false
	};


	getVariables = () => {
		const {
			content,
			commonVariables,
			templateVariables,
			variableValues,
		} = this.props;
		if (content) {
			const testVar = /(?<=\[\[).+?(?=\]\])/g;
			const varArr = [];
			const sectionVariables = content.match(testVar);

			if (sectionVariables && sectionVariables.length > 0) {
				sectionVariables.forEach(sectionVariable => {
					commonVariables.forEach(commonVariable => {
						if (commonVariable.name === `[[${sectionVariable}]]`) {
							let value = '';
							let id;
							let imageInfo = null;
							variableValues.forEach(val => {
								if (val.variableName === `[[${sectionVariable}]]`) {
									value = val.variableValue;
									id = val.id;
									imageInfo = val?.imageInfo ? val.imageInfo : null;
								}
							});
							const temp = {
								id,
								type: 'common',
								value,
								imageInfo,
								variable: {
									...commonVariable,
									content: JSON.parse(commonVariable.content),
								},
								occurs: [
									...content.matchAll(
										new RegExp(`[[${sectionVariable}]]`, 'gi'),
									),
								].map(a => a.index).length,
								total: commonVariable.total,
							};

							let typeRemap = {};
							if (temp.variable.content.type === 'Open text field') {
								typeRemap = {
									id: 1,
									value: 'Text',
								};
							}

							temp.variable.content.type = {
								...typeRemap,
							};
							
							let oldVarArr = varArr.find(oneVar => oneVar.variable.id === temp.variable.id);
							if(oldVarArr){
								oldVarArr.total = ++oldVarArr.total;
							} else {
								varArr.push(temp);
							}
						}
					});

					templateVariables.forEach(templateVariable => {
						if (templateVariable.name === `[[${sectionVariable}]]`) {
							let value = '';
							let id;
							let imageInfo = null
							variableValues.forEach(val => {
								if (val.variableName === `[[${sectionVariable}]]`) {
									value = val.variableValue;
									id = val.id;
									imageInfo = val?.imageInfo ? val.imageInfo : null;
								}
							});
							const temp = {
								id,
								type: 'template',
								value,
								imageInfo,
								variable: {
									...templateVariable,
									content: JSON.parse(templateVariable.content),
								},
								occurs: [
									...content.matchAll(
										new RegExp(`[[${sectionVariable}]]`, 'gi'),
									),
								].map(a => a.index).length,
								total: 1,
							};
							
							let oldVarArr = varArr.find(oneVar => oneVar.variable.id === temp.variable.id);
							if(oldVarArr){
								oldVarArr.total = ++oldVarArr.total;
							} else {
								varArr.push(temp);
							}
						}
					});
				});
				return this.setState({
					loading: false,
					variablesIncluded: varArr,
				});
			}
			return this.setState({ loading: false });
		}
		return null;
	};

	setCanClose = val => this.setState({ canClose: val });

	setIsUpdated = val => {
		this.setState({ isUpdated: val });
	}

	componentDidUpdate(prevProps) {
		const { isOpen } = this.props;
		if (prevProps.isOpen !== isOpen) {
			this.getVariables();
		}
	}

	shouldClose = () => {
		const { loadData } = this.props;
		const { canClose } = this.state;
		if (canClose) return loadData();
		return null;
	};

	closeModal = () => {
		const {
			closeDialog,
			closeWithoutReload
		} = this.props;
		const { isUpdated } = this.state;
		
		return !isUpdated ? closeWithoutReload : closeDialog;
	};

	render() {
		const {
			isOpen,
			commonVariables,
			templateVariables,
			templateId,
			loadData
		} = this.props;
		const { loading, variablesIncluded } = this.state;
		return (
			<>
				<Dialog isOpen={isOpen} onClose={this.shouldClose}>
					{loading && <LoadingSpinner />}
					{!loading && variablesIncluded.length > 0 && (
						<>
							<div>
								<TitleWrap>
									<Title>Add Values</Title>
								</TitleWrap>
								<hr />
								{variablesIncluded.map((item, index) => (
									
									<VariableValue
										item={item}
										key={index}
										commonVariables={commonVariables}
										templateVariables={templateVariables}
										loadData={loadData}
										templateId={templateId}
										setCanClose={this.setCanClose}
										setIsUpdated={this.setIsUpdated}
									/>
								))}
							</div>
						</>
					)}
					{!loading && variablesIncluded.length === 0 && (
						<>
							<div>
								<TitleWrap>
									<Title>Add Values</Title>
								</TitleWrap>
								<hr />
								<div className="mx-2">
									<p>No variables in this section</p>
								</div>
							</div>
						</>
					)}
					<div className="mx-3 mt-3 d-flex justify-content-end">
						<Button
							onClick={this.closeModal()}
							text="Close"
							intent="secondary"
						/>
					</div>
				</Dialog>
			</>
		);
	}
}
VariableDialog.propTypes = {
	isOpen: PropTypes.bool,
	loadData: PropTypes.func,
	commonVariables: PropTypes.array,
	templateVariables: PropTypes.array,
	templateId: PropTypes.string,
	variableValues: PropTypes.array,
	content: PropTypes.string,
	closeDialog: PropTypes.func,
};

export default VariableDialog;
