import React from "react";
import { PUBLISH_STATUS, MODIFIED_STATUS, ARCHIVED_STATUS, DRAFT_STATUS } from './statuses';
import { DropdownMultiSelect } from "componentsV2";
import { useState } from "react";

export const StatusList = ({
    setFilterationData
}) => {
    const [statusesValues, setstatusesValues] = useState([])
    
    const statusesOptions = [
        {
            label: PUBLISH_STATUS.charAt(0).toUpperCase() + PUBLISH_STATUS.slice(1),
            value: PUBLISH_STATUS
        },
        {
            label: MODIFIED_STATUS.charAt(0).toUpperCase() + MODIFIED_STATUS.slice(1),
            value: MODIFIED_STATUS
        },
        {
            label: DRAFT_STATUS.charAt(0).toUpperCase() + DRAFT_STATUS.slice(1),
            value: DRAFT_STATUS
        },
        {
            label: ARCHIVED_STATUS.charAt(0).toUpperCase() + ARCHIVED_STATUS.slice(1),
            value: ARCHIVED_STATUS
        }
    ];

    const onChangeStatusHandler = statuses => {
        setstatusesValues(statuses)
        setFilterationData(oldData => ({
            ...oldData,
            statuses: statuses.map(status => status.value)
        }));
    }

    return (  
        <DropdownMultiSelect
            label={null}
            values={statusesValues}
            options={statusesOptions}
            allowSelectAll={false}
            placeholder="Filter by status"
            className="status-mddl"
            visibleOptions={1}
            onChange={onChangeStatusHandler}
        />
    );
}