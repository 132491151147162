import React from 'react';
import '@babel/polyfill';
import attachmentFile from '!file-loader?name=[name].[ext]!../../../../../assets/files/contactsBulkTemplate.xlsx';
import { Icon } from '@blueprintjs/core';
import { FileUpload, FeaturedIcon, Callout } from 'componentsV2';

const Step1 = ({ setDisableBtns, setFormData, resetInitialState }) => {
	const handleFileChange = file => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = e => {
			const { result } = e.target;
			setFormData(result);
		};
	};

	const fileUploadProgress = fileUploadProgress => {
		if (fileUploadProgress) {
			setDisableBtns(false);
		} else {
			setDisableBtns(true);
		}
	};

	const grayIcon = (
		<FeaturedIcon icon={'document'} type={'Gray'} size={'md'} shape={'circleIcon'} />
	);

	return (
		<>
			<div className="step-1">
				<Callout
					icon={grayIcon}
					intent="gray"
					text={
						<>
							<div className="card-text-column">
								<h5 className="card-title">Contacts template</h5>
								<p className="card-text">
									Please fill out the template file with any Outside Contacts you
									would like to import into call lists. <strong>DO NOT</strong>{' '}
									add, rename, or delete columns, or perform any similar forms of
									modifications to the document.
								</p>
								<a
									className="card-action"
									href={attachmentFile}
									download="multiple-contacts-template.xlsx"
								>
									<Icon icon="cloud-download" /> Download
								</a>
							</div>
						</>
					}
				/>

				<FileUpload
					labelState={false}
					allowedFiles={['xlsx']}
					allowedFilesLegend="only xlsx format is accepted"
					defaultErrorMessage="Upload failed, please try again"
					label={'Upload template'}
					onFileInputChange={handleFileChange}
					setProgressCompleted={fileUploadProgress}
					onReplaceAction={resetInitialState}
					onDeleteFile={resetInitialState}
				/>
			</div>
		</>
	);
};
export default Step1;
