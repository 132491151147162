import React, {useEffect, useState, useContext} from "react";
import '../../css/Flipchart.scss';
import { FlipchartService } from "services/FlipchartService";
import { Tabs } from "./Tabs/Tabs.jsx";
import './css/print.scss';
import { ToasterContext } from "../../../App";
import { Intent } from '@blueprintjs/core';

export const Print = (props) => {
    const [flipchartData, setFlipChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
	const toaster = useContext(ToasterContext);
        
    useEffect(async() => {  
        await loadFlipchartData()
      

    }, []);

    useEffect(async() => {  
        flipchartData.length !== 0 ? 
        setTimeout(() => {
            handlePrintClick()
        }, 1000) : ''
    }, [flipchartData]);

    const handlePrintClick = event => {
        window.print();
    };

    const loadFlipchartData = async () => {
        try {
            setIsLoading(true);
            const id = props.match.params.id;
            const response = await FlipchartService.getTabsByFlipchartId(id);
            setFlipChartData(response.data)
            setIsLoading(false);  
            
        } catch (err) {
            toaster(err.error?.description, Intent.DANGER, "", 3000)
			setIsLoading(false);

        }
    }

	return (
        <Tabs
            isLoading={isLoading}
            flipchartData={flipchartData}
            history={props.history}
            handlePrintClick={handlePrintClick}

        /> 
	);
}
