import React, { useState } from "react";
import { Icon } from "@blueprintjs/core";
import Dropdown from 'react-bootstrap/Dropdown';
import './index.scss';

const DropdownButton = ({
    text,
    lefticon,
    content,
    disabled,
    children,
    variant="primary",
    ...props
}) => {

    return (
        <Dropdown>
            <Dropdown.Toggle disabled={disabled} {...props} variant={variant} id="dropdown-basic">
                {lefticon && <Icon icon={lefticon} className="mr-2" />}
                <span className="ddbtn-text">{text}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropdownButton;
