import React, { useContext, useState, useRef } from 'react';
import { AlertModal, Button, Input, Intent, ToasterContext } from 'componentsV2';
import { RapidAlarmService } from 'services';
import { useSelector, useDispatch } from 'react-redux';
import './css/EndAlarmModal.scss';

export const EndAlarmModal = ({ activeAlarmId, handleCancel, history, isReunificaton }) => {
	const toaster = useContext(ToasterContext);
	const dispatch = useDispatch();
	const toggleState = useSelector(state => state.route.toggleState);
	let loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));

	const [hasError, setHasError] = useState(false);
	const [pins, setPins] = useState(['', '', '', '']);
	const [disabled, setdisableButton] = useState(true);
	const inputRefs = [useRef(), useRef(), useRef(), useRef()];
	const [loading, setLoading] = useState(false);

	const handleEndAlarm = () => {
		const pinNumber = pins.join('');
		let pincode =
			loggedUser?.respondAlarmPin && loggedUser?.respondAlarmPin !== ''
				? (pincode = loggedUser?.respondAlarmPin)
				: null;
		setLoading(true);
		RapidAlarmService.endAlarm(activeAlarmId, pinNumber, isReunificaton)
			.then(response => {
				if (response.statusCode === 200) {
					if (isReunificaton) {
						toaster(
							'You have successfully ended Reunification and the alarm.',
							Intent.SUCCESS,
						);
					} else {
						toaster('You have successfully ended the alarm.', Intent.SUCCESS);
					}

					dispatch({
						type: 'RELOAD_FETCHED_LIST',
						payload: !toggleState,
					});
					handleCancel();
					history.push(`/`);
				}
			})
			.catch(error => {
				setHasError(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChange = (index, value, event) => {
		setHasError(false);
		if (/^\d*$/.test(value) && value.length <= 1) {
			const newPins = [...pins];
			newPins[index] = value;
			setPins(newPins);

			if (value !== '' && index < 3 && event.keyCode !== 8) {
				inputRefs[index + 1].current.focus();
			}

			if (value === '' && index > 0 && event.keyCode === 8) {
				inputRefs[index - 1].current.focus();
			}

			newPins.filter(pin => pin === '').length > 0
				? setdisableButton(true)
				: setdisableButton(false);
		}
	};

	const subtitle = (
		<div className="endalarm-subtitle">
			<p>
				{isReunificaton
					? 'Please note that ending the reunification process will also end the current alarm. This action is irreversible.'
					: 'Ending this alarm will also end both Roll Call and Reunification. This action is irreversible.'}
			</p>
			{loggedUser?.respondAlarmPin && loggedUser?.respondAlarmPin !== '' && (
				<>
					<span className="pin-text">
						Please enter your 4-digit alarm PIN to confirm ending{' '}
						{isReunificaton ? 'Reunification' : 'this alarm'}:
					</span>
					<div className="pin-digits">
						{pins.map((pin, index) => (
							<Input
								placeholder={''}
								maxLength={1}
								error={hasError}
								inputRef={inputRefs[index]}
								value={pin}
								onChange={e => handleChange(index, e.target.value, e)}
								onKeyDown={e => handleChange(index, pins[index], e)}
							/>
						))}
					</div>
					{hasError && (
						<div>
							<span className="pin-error">Invalid alarm PIN provided.</span>
						</div>
					)}
				</>
			)}
		</div>
	);

	return (
		<AlertModal
			isOpen={true}
			loading={loading}
			size="medium"
			title={isReunificaton ? 'End Reunification?' : 'End alarm?'}
			subtitle={subtitle}
			showCloseButton={false}
			alertConfirmButton={
				<Button
					text={isReunificaton ? 'End Reunification & Alarm' : 'End alarm'}
					type="primaryDanger"
					size="md"
					onClick={handleEndAlarm}
					disabled={
						loggedUser?.respondAlarmPin && loggedUser?.respondAlarmPin !== ''
							? disabled
							: false
					}
				/>
			}
			alertCancelButton={
				<Button text="Cancel" type="tertiary" size="md" onClick={handleCancel} />
			}
			icon="warning-sign"
			iconColor="warning"
		/>
	);
};
