import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';
import { debounce, isEmpty } from 'lodash';
import { Input } from 'componentsV2';
import './css/SearchBar.scss';

export const SearchBar = ({
	label,
	placeholder,
	onSearch,
	style,
	onFocus,
	value = '',
	className = '',
	icon = 'search',
	...restProps
}) => {
	const [searchValue, setSearchValue] = useState(value);

	useEffect(() => {
		setSearchValue(value);
	}, [value]);

	const clearSearch = () => {
		setSearchValue('');
		debouncedOnChange('');
	};

	const onSearchHandler = value => {
		onSearch(value);
	};

	const debouncedOnChange = useCallback(debounce(onSearchHandler, 500), []);

	const handleChange = e => {
		const value = e.target.value;
		setSearchValue(value);
		debouncedOnChange(value);
	};

	const rightElement = (
		<>
			{!isEmpty(searchValue) && (
				<Icon size={18} className="cross-icon" icon="cross" onClick={clearSearch} />
			)}
		</>
	);

	return (
		<Input
			placeholder={placeholder || 'Search'}
			onFocus={onFocus}
			label={label}
			className={'SearchBar_Wrapper ' + className}
			icon={icon}
			onChange={handleChange}
			value={searchValue}
			rightElement={rightElement}
			style={style}
			{...restProps}
		/>
	);
};

SearchBar.propTypes = {
	placeholder: PropTypes.string,
	onSearch: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	style: PropTypes.object,
	value: PropTypes.string,
};
