import React from 'react';
import { TemplateService } from 'services';
import { Intent, FeaturedIcon } from 'componentsV2';

export const ToogleArchiveTemplateModalFn = (
	modalName,
	setLoading,
	formData,
	dispatchSafetyPlanData,
	closeModal,
	toaster,
	setModalToShow,
	reloadData,
    history
) => {
	const showArchiveModal = safetyPlan => {
		setModalToShow(modalName);
		dispatchSafetyPlanData({
			id: safetyPlan.id,
            title: safetyPlan.title,
            archived: safetyPlan.archived,
            source: safetyPlan.source
		});
	};

	const handleArchiveSubmit = () => {
        setLoading(true);
        TemplateService.toogleArchive(formData.id, !formData.archived)
            .then(response => {
                if(formData.archived) {
                    toaster(
                        `You have successfully unarchived "${
                            formData.title
                        }".`,
                        Intent.SUCCESS
                    );
                } else {
                    toaster(
                        `You have successfully archived "${
                            formData.title
                        }".`,
                        Intent.NONE,
                        <FeaturedIcon icon={'archive'} type={'Gray'} />
                    );
                }
                
                if(formData.source == 'list') {
                    reloadData();
                } else {
                    if(formData.archived) {
                        history.push(`/core/templates/${formData.id}`);
                    } else {
                        history.push(`/core/view/${formData.id}`);
                    }
                }
                closeModal();
                
            })
            .catch(error => {
                toaster(
                    'Error while archiving the template',
                    Intent.DANGER,
                );
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
	};

	return {
		showArchiveModal,
		handleArchiveSubmit,
	};
};
