export const BINDER_EDIT_PATH = 'binders/edit';
export const BINDER_VIEW_PATH = 'binders/view';

export const IMAGES_TYPES = ['jpg', 'jpeg', 'gif', 'bmp', 'tif', 'png'];

export const HAS_OLD_SP_TP_CONTENT_EDIT_USER =
	'The safety plan in this binder is outdated. Please delete the old safety plan and attach a new safety plan so the binder can be downloaded.';
export const HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER =
	'The safety plan in this binder is outdated. Please contact your administrator to delete the old safety plan and attach a new safety plan so the binder can be downloaded.';
export const HAS_OLD_SP_TP_COPY_CONTENT_EDIT_USER =
	'The safety plan in this binder is outdated. Please delete the old safety plan and attach a new safety plan so the binder can be copied.';
