import React from 'react';
import { Icon } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import './css/ShowHideAllIcon.scss';

export const ShowHideAllIcon = ({ showAll, onClick }) => (
	<div className="show-hide-all-icon-container">
		<a className="showHideLink" onClick={onClick}>
			<Icon icon={showAll ? 'eye-on' : 'eye-off'} className="eye-icon" onClick={onClick} />
			{showAll ? ' Show all' : ' Hide all'}
		</a>
	</div>
);

ShowHideAllIcon.propTypes = {
	showAll: PropTypes.bool,
	onClick: PropTypes.func,
};
