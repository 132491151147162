import {
	INTERNAL_SITE_IDS,
	DELAWARE_SITE_IDS,
	TN_SITE_IDS,
	SITE_TEMPLATE_BASE,
	SITE_FIRST_RESPONDER,
	EMS_HOST_ENVS,
	SITE_MAINTENANCE_MODE,
} from './constants';

export const names = {
	SITE_IS_INTERNAL: 'SITE_IS_INTERNAL',
	SITE_IS_DELAWARE: 'SITE_IS_DELAWARE',
	SITE_IS_TN: 'SITE_IS_TN',
	SITE_IS_TEMPLATE_CORE: 'SITE_IS_TEMPLATE_CORE',
	SITE_IS_FIRST_RESPONDER: 'SITE_IS_FIRST_RESPONDER',
	ENV_NOT_PROD: 'ENV_NOT_PROD',
	ENV_DEV_ONLY: 'ENV_DEV_ONLY',
	INTERNAL_NOT_PROD: 'INTERNAL_NOT_PROD',
	SITE_IS_IN_MAINTENANCE: 'SITE_IS_IN_MAINTENANCE',
};

const districtInList = (site, list) => {
	// If we have a parent ID, that's the only district ID.
	if (parseInt(site?.parentId, 10)) {
		return list.includes(parseInt(site?.parentId, 10));
	}

	// Without a parent ID, the ID is the district.
	return list.includes(parseInt(site?.id, 10));
};

export const isOn = name => {
	let flagState = false;
	const site = JSON.parse(localStorage.getItem('currentDistrict'));
	const params = new URLSearchParams(window.location.search);

	switch (name) {
		case names.SITE_IS_INTERNAL:
			flagState = districtInList(site, INTERNAL_SITE_IDS);
			break;

		case names.SITE_IS_DELAWARE:
			flagState = districtInList(site, DELAWARE_SITE_IDS);
			break;

		case names.SITE_IS_TN:
			// EIT-4175: Breaks users that can only see a mtid same TN building ID.
			// flagState = districtInList(site, TN_SITE_IDS);
			flagState = false;
			break;

		case names.SITE_IS_TEMPLATE_CORE:
			flagState = districtInList(site, [SITE_TEMPLATE_BASE]);
			break;

		case names.SITE_IS_FIRST_RESPONDER:
			flagState = districtInList(site, [SITE_FIRST_RESPONDER]);
			break;

		case names.ENV_NOT_PROD:
			flagState = !['uat', 'prod'].includes(EMS_HOST_ENVS[window.location.host]);
			break;

		case names.ENV_DEV_ONLY:
			flagState =
				['dev'].includes(EMS_HOST_ENVS[window.location.host]) ||
				window.location.host.includes('localhost');
			break;

		case names.INTERNAL_NOT_PROD:
			flagState = isOn(names.SITE_IS_INTERNAL) && isOn(names.ENV_NOT_PROD);
			break;

		case names.SITE_IS_IN_MAINTENANCE:
			flagState = params.has('rebuild-internal-allow') ? false : SITE_MAINTENANCE_MODE;
			break;

		default:
			break;
	}

	return flagState;
};
