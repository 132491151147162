import './css/ManageStateDrillRequirements.scss';
import React, { useState, useEffect, useContext } from 'react';
import { PageHeader } from 'components/PageHeader';
import { StatesService, SchoolYearsService, DrillStateRequirementsService } from 'services';
import { flags } from 'utilities';
import {
	Dropdown,
	EmptyState,
	emptyFolder,
	noResult,
	LoadingSpinner,
	Button,
	BootstrapedTable,
	Input,
	ActionsButton,
	ToasterContext,
	sortingStyle,
	Intent,
	FeaturedIcon,
} from 'componentsV2';
import { getCurrentSchoolYear } from 'utilities/dates';
import { EditRequiredFieldsModal } from '../Settings/StateRequirements/components/EditRequiredFieldsModal';
import { AddDrillTypeModal } from '../Settings/StateRequirements/components/AddDrillTypeModal';
import { DeleteDrillTypeModal } from '../Settings/StateRequirements/components/DeleteDrillTypeModal';
import { getStateId } from '../Dashboard/utils';

export const ManageStateDrillRequirements = () => {
	const [states, setStates] = useState([]);
	const [years, setYears] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedState, setSelectedState] = useState(null);
	const [selectedSchoolYear, setSelectedSchoolYear] = useState({
		label: `${getCurrentSchoolYear()[0]} - ${getCurrentSchoolYear()[1]}`,
		value: getCurrentSchoolYear()[0],
	});
	const [requirements, setRequirements] = useState([]);
	const [focusedInputId, setFocusedInputId] = useState(null);
	const [modalLoading, setModalLoading] = useState(false);
	const [modalToShow, setModalToShow] = useState(null);
	const [newDrillType, setNewDrillType] = useState(null);
	const [drillTypeData, setDrillTypeData] = useState(null);

	const breadcrumbItems = [
		{ label: 'Drills', url: '/drillsV2' },
		{ label: 'Admin', url: '/drillsV2/admin' },
		{ label: 'State requirements' },
	];

	const {
		isOn,
		names: { SITE_IS_TEMPLATE_CORE },
	} = flags;
	const isCoreSite = isOn(SITE_IS_TEMPLATE_CORE);

	const setModalToOpen = modal => {
		if (selectedSchoolYear && selectedState) {
			setModalToShow(modal);
		}
	};

	const onCloseModal = () => {
		setModalToShow(null);
	};

	const onChangeNewDrillType = item => {
		setNewDrillType(item);
	};

	const addDrillType = () => {
		setModalLoading(true);
		DrillStateRequirementsService.addStateRequirement(
			0,
			newDrillType.uuid,
			selectedState.id,
			selectedSchoolYear.value,
		)
			.then(() => {
				toaster(
					`You successfully added ${newDrillType.name} as a new drill type.`,
					Intent.SUCCESS,
				);
			})
			.catch(() =>
				toaster(
					`State requirement ${
						newDrillType.name
					} already exists for this state, year, and drill type.`,
					Intent.DANGER,
				),
			)
			.finally(() => {
				setNewDrillType(null);
				setModalLoading(false);
				setModalToShow(null);
				getRequirements(selectedState.id, selectedSchoolYear.value);
			});
	};

	const updateRequiredFields = updatedFields => {
		DrillStateRequirementsService.updateRequiredFieldsOrder(
			drillTypeData.id,
			updatedFields.map(field => field.uuid),
		)
			.then(() => {
				toaster(
					`You have successfully updated the required fields for "${
						drillTypeData?.drillType?.name
					}".`,
					Intent.SUCCESS,
				);
			})
			.catch(res => {
				toaster(`There was the following error: ${res?.error?.description}`, Intent.DANGER);
			})
			.finally(() => {
				setModalLoading(false);
				setModalToShow(null);
				setDrillTypeData(null);
				getRequirements(selectedState.id, selectedSchoolYear.value);
			});
	};

	const getDropdownOptions = async () => {
		setLoading(true);
		await StatesService.getAll(isCoreSite).then(allStates => {
			setStates(allStates);
			const selectState = allStates.find(state => state.id === getStateId());
			setSelectedState(selectState);
			getRequirements(selectState.id, selectedSchoolYear.value);
		});
		await SchoolYearsService.fetchYears().then(backendYears => {
			setYears(backendYears);
		});
		setLoading(false);
	};

	useEffect(() => {
		getDropdownOptions();
	}, []);

	const getRequirements = async (state, year) => {
		setLoading(true);
		await DrillStateRequirementsService.fetchRequirements(year, state).then(requirementsData =>
			setRequirements(
				requirementsData.map(req => ({
					...req,
					type: req.drillType.name,
				})),
			),
		);
		setLoading(false);
	};

	const handleParameterSelect = async (parameter, value) => {
		if (parameter === 'state') {
			setSelectedState(value);
			if (selectedSchoolYear) getRequirements(value.id, selectedSchoolYear.value);
		}
		if (parameter === 'year') {
			setSelectedSchoolYear(value);
			if (selectedState) getRequirements(selectedState.id, value.value);
		}
	};

	const toaster = useContext(ToasterContext);

	const handleUpdate = async () => {
		setLoading(true);
		try {
			const updatePromises = requirements.map(req => {
				if (req.updated) {
					return DrillStateRequirementsService.updateNumberOfRequirements(
						req.id,
						req.requirements,
					).catch(() => {
						if (req.requirements === '0') {
							const message = `Value cannot be 0 for ${req.drillType.name}`;
							throw new Error(message);
						}
					});
				}
				return Promise.resolve(); // For requirements that don't need updating
			});

			await Promise.all(updatePromises);

			toaster('You have successfully updated your state drill requirements.', Intent.SUCCESS);
		} catch (error) {
			toaster(`Error updating state drill requirements: ${error.message}`, Intent.DANGER);
		} finally {
			setFocusedInputId(null);
			await getRequirements(selectedState.id, selectedSchoolYear.value);
			setLoading(false);
		}
	};

	const PageHeaderButtons = () => {
		const hasEmptyData = requirements.find(
			requirement => requirement.requirements == null || requirement.requirements == '',
		);

		return (
			<>
				<Button
					icon="plus"
					text="New drill type"
					type="primaryDefault"
					onClick={() => setModalToOpen('addType')}
				/>
				<Button
					icon="refresh"
					text="Update"
					type="secondaryDefault"
					onClick={handleUpdate}
					disabled={hasEmptyData}
				/>
			</>
		);
	};

	const columns = [
		{
			dataField: 'type',
			text: 'Type',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			sortFunc: (a, b, order) => {
				if (order === 'desc') return a.localeCompare(b);
				return b.localeCompare(a);
			},
		},
		{
			dataField: 'requirements',
			text: 'Drills required by state',
			formatter: (value, row) => (
				<Input
					value={value}
					placeholder=""
					onChange={e => editRequirement(row.id, e.target.value)}
					id={row.id}
				/>
			),
		},
		{
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<ActionsButton>
					<Button
						text="Edit required log fields"
						icon="edit"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => {
							setDrillTypeData(row);
							setModalToOpen('editRequiredFields');
						}}
					/>
					<Button
						text="Delete"
						icon="trash"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => {
							setDrillTypeData(row);
							setModalToOpen('deleteDrillType');
						}}
					/>
				</ActionsButton>
			),
		},
	];

	const editRequirement = (id, newValue) => {
		setRequirements(reqs =>
			reqs.map(req =>
				req.id === id
					? {
							...req,
							requirements: newValue,
							updated: true,
					  }
					: req,
			),
		);
		setFocusedInputId(`${id}`);
	};

	// don't lose focus on input after change
	useEffect(() => {
		if (focusedInputId) {
			document.getElementById(focusedInputId).focus();
			setFocusedInputId(null);
		}
	}, [requirements]);

	const onClickDeleteStateDrill = () => {
		DrillStateRequirementsService.deleteRequirement(drillTypeData?.id)
			.then(() => {
				toaster(
					`You have successfully deleted ${drillTypeData?.type}.`,
					Intent.NONE,
					<FeaturedIcon icon="trash" size="md" type="Gray" />,
				);
				getRequirements(selectedState?.id, selectedSchoolYear?.value);
			})
			.catch(() => {
				toaster(`There was an error deleting the drill. Please try again.`, Intent.DANGER);
			})
			.finally(() => {
				setModalToShow(null);
			});
	};

	return (
		<>
			<PageHeader
				{...{ breadcrumbItems }}
				title="State requirements"
				customItem={selectedState && selectedSchoolYear && PageHeaderButtons}
			/>
			<div className="parameter-selection">
				<Dropdown
					label="State"
					isRequired
					placeholder="Select a state"
					value={selectedState}
					onChange={value => handleParameterSelect('state', value)}
					options={states}
				/>
				<Dropdown
					label="School year"
					isRequired
					placeholder="Select a school year"
					value={selectedSchoolYear}
					onChange={value => handleParameterSelect('year', value)}
					options={years}
				/>
			</div>
			{loading && <LoadingSpinner />}
			{!loading && requirements.length > 0 && (
				<BootstrapedTable
					keyField="id"
					data={requirements}
					columns={columns}
					remote={false}
				/>
			)}
			{!loading && requirements.length === 0 && (
				<>
					<EmptyState
						header={
							selectedState
								? selectedSchoolYear
									? 'No state requirements found'
									: 'Please select a school year'
								: 'Please select a state'
						}
						icon={
							selectedState
								? selectedSchoolYear
									? noResult
									: emptyFolder
								: emptyFolder
						}
					/>
				</>
			)}

			{/* Add new Drill type modal */}
			{modalToShow === 'addType' && (
				<AddDrillTypeModal
					isOpen
					loading={modalLoading}
					onCloseModal={onCloseModal}
					newDrillType={newDrillType}
					onModalSubmit={addDrillType}
					onChange={onChangeNewDrillType}
				/>
			)}

			{/* Delete Drill type modal */}
			{modalToShow === 'deleteDrillType' && (
				<DeleteDrillTypeModal
					isOpen
					titleData={drillTypeData?.type}
					onClickDelete={onClickDeleteStateDrill}
					onCloseModal={onCloseModal}
				/>
			)}

			{/* Edit required fields modal */}
			{modalToShow === 'editRequiredFields' && (
				<EditRequiredFieldsModal
					isOpen
					subtitle="Below are the fields available when recording a drill."
					onCloseModal={onCloseModal}
					onActionClick={updateRequiredFields}
					selectedRequirement={drillTypeData}
				/>
			)}
		</>
	);
};
