import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dialog, Classes } from '@blueprintjs/core';
import { LabelText } from 'components/Forms/shared.js';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Editor } from '@tinymce/tinymce-react';
import Swal from 'sweetalert2';

const CreateDialog = styled(Dialog)`
	width: 650px !important;
`;

const DialogFooterWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 10px;
`;

class AddSummaryModal extends React.Component {
	constructor() {
		super();
		this.state = { name: '', loading: false, year: '' };
		this.editorRef = React.createRef();
	}

	updateFields = (key, val) => this.setState({ [key]: val });

	addSection = () => {
		Swal.fire('Saving');
		Swal.showLoading();
		const { assessmentId, getData } = this.props;
		const summary = this.editorRef.current.getContent();
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/assessments/${assessmentId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				summary,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => getData());
				}
				return null;
			});
	};

	render() {
		const { closeAddSummary, name, value, year } = this.props;
		const { loading } = this.state;
		return (
			<CreateDialog
				isOpen
				onClose={closeAddSummary}
				// title="Add summary"
				title="Add summary"
				style={{ fontFamily: 'Merriweather' }}
				{...this.state}
			>
				<div className={Classes.DIALOG_BODY}>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<LabelText
								style={{
									fontFamily: 'Merriweather',
									marginBottom: 10,
									fontSize: 16,
									fontWeight: 600,
								}}
								className="mt-2"
							>
								{year}-{year + 1}:{name}
							</LabelText>
							<Editor
								style={{ height: 100, width: 200 }}
								onInit={(event, editor) => {
									this.editorRef.current = editor;
								}}
								init={{
									menubar: false,
									statusbar: false,
									branding: false,
									resize: false,
									height: 315,
									plugins: [
										'advlist autolink lists link image charmap print preview anchor',
										'searchreplace visualblocks code fullscreen',
										'insertdatetime media table paste code table ',
									],
									toolbar:
										'undo redo formatselect bold italic alignleft aligncenter alignright justify bullist numlist ',
									setup: editor => {
										editor.on(
											'Paste Change input Undo Redo',
											this.handleDebounce,
										);
									},
								}}
								initialValue={value}
							/>
							{/* <Callout intent="primary" className="mb-2">
                                Directions will help users understand more
                                information on how to answer this section. For
                                example, “Directions: Please assess the
                                following areas and answer each question by
                                selecting: YES, NO, N/A (Not Applicable), or
                                N/AS (Not Assessed) from the drop-down box.”
                            </Callout> */}
							{/* <Input
                                placeholder="Main Section Title"
                                label="Section"
                                large
                                onChange={e =>
                                    this.updateFields('name', e.target.value)
                                }
                                value={name}
                            />
                            <LabelText className="mt-2">Directions</LabelText>
                            <TextArea
                                className="bp3-fill .modifier"
                                growVertically={false}
                                intent={Intent.NONE}
                                placeholder="Enter directions"
                                onChange={e =>
                                    this.updateFields(
                                        'instructions',
                                        e.target.value,
                                    )
                                }
                                value={instructions}
                            />
                            <HrWrapper /> */}
						</>
					)}
				</div>
				<div>
					<hr
						style={{ height: 30, marginTop: 0, marginBottom: -10 }}
					/>
				</div>
				<DialogFooterWrapper>
					<Button
						style={{
							marginRight: 16,
							height: 40,
							width: 68,
							fontFamily: 'Nunito Sans',
							fontSize: '16px',
						}}
						onClick={closeAddSummary}
						text="Cancel"
						intent="none"
					/>
					<Button
						style={{
							height: 40,
							width: 68,
							background: '#9F1C3A',
							fontFamily: 'Nunito Sans',
							fontSize: '16px',
						}}
						onClick={this.addSection}
						text="Save"
						intent="danger"
					/>
				</DialogFooterWrapper>
			</CreateDialog>
		);
	}
}

AddSummaryModal.propTypes = {
	editTemplate: PropTypes.object,
	updateTemplatesArray: PropTypes.func,
	closeAddSummary: PropTypes.func,
	assessmentId: PropTypes.any,
	getData: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.string,
	year: PropTypes.any,
};

export default AddSummaryModal;
