import React from 'react';
import { PropTypes } from 'prop-types';
import { ActionsButton, Button } from 'componentsV2';

export const TemplatesTableActions = ({
	row,
	isCoreSite,
	onShowModal,
	editPermission,
	isSysAdmin,
	history,
}) => {
	const go = (route, row) => {
		history.push({ pathname: route, state: { data: row, isCoreSite } });
	};
	return (
		<ActionsButton>
			<Button
				type="ghostDefault"
				size="lg"
				text={isCoreSite ? 'Edit' : row?.isCoreTemplate ? 'View' : 'Edit'}
				icon={isCoreSite ? 'edit' : row?.isCoreTemplate ? 'eye-open' : 'edit'}
				onClick={() => {
					go(`/assessV2/assess/template/${row.id}`, row);
				}}
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
			/>
			<Button
				type="ghostDefault"
				size="lg"
				text="Copy"
				icon="duplicate"
				onClick={() =>
					editPermission && onShowModal('copy_template', { id: row.id, name: row.name })
				}
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
			/>
			{(editPermission || isCoreSite) && (<Button
				type="ghostDefault"
				size="lg"
				text={row?.published ? 'Unpublish' : 'Publish'}
				icon={row?.published ? 'th-disconnect' : 'book'}
				onClick={() => onShowModal('publish_unpublish_template', {
					published: row.published,
					id: row.id,
					name: row.name,
				})}
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
			/>)}
			<Button
				type="ghostDefault"
				size="lg"
				text={isCoreSite ? 'Delete' : row?.isCoreTemplate ? 'Remove' : 'Delete'}
				icon={isCoreSite ? 'trash' : row?.isCoreTemplate ? 'remove' : 'trash'}
				onClick={() =>
					row?.isCoreTemplate && !isCoreSite && editPermission
						? onShowModal('remove_template', {
							id: row.id,
							name: row.name,
							isCoreTemplate: row?.isCoreTemplate || false
						})
						: editPermission && isSysAdmin && isCoreSite
						? onShowModal('delete_template', {
								id: row.id,
								name: row.name,
						  })
						: editPermission &&
						  !isCoreSite &&
						  onShowModal('delete_template', {
								id: row.id,
								name: row.name,
						  })
				}
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
			/>
		</ActionsButton>
	);
};

TemplatesTableActions.propTypes = {
	row: PropTypes.object,
	isCoreSite: PropTypes.bool,
	onShowModal: PropTypes.func,
	editPermission: PropTypes.bool,
	isSysAdmin: PropTypes.bool,
	history: PropTypes.object,
};
