/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { plus } from 'assets/icons';
import { Callout } from 'components/Callout';
import { Input } from 'components/Input';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { AddDrillTypeModal } from 'components/Modals/AddDrillTypeModal';
import {
	EditPostingInstructionsModal,
	EditSchedulingInstructionsModal,
} from 'components/Modals/InstructionsModal';
import { EditRequiredFieldsModal } from 'components/Modals/EditRequiredFieldsModal';
import { PublishRequirementsModal } from 'components/Modals/PublishRequirementsModal';
import { SchoolYearDropdown } from 'components/SchoolYearDropdown';
import { StatesDropdown } from 'components/StatesDropdown';
import { MoreActionsButton } from 'components/MoreActionsButton';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { DrillStateRequirementsService } from 'services';

const AddDrillType = styled.div`
	width: 150px;
	height: 40px;
	display: flex;
	color: #9f1c3a;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	font-style: normal;
	text-align: center;
	align-items: center;
	flex-direction: row;
	letter-spacing: 0px;
	font-family: Nunito Sans;
	justify-content: space-evenly;
	cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const Page = styled.div`
	padding: 8px 24px;

	.dropdown-filters {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.dropdown {
			margin-right: 24px;
			margin-bottom: 16px;
			width: calc(33% - 24px);
		}
	}

	.instructions-to-user {
		color: #5c7080;
		font-size: 18px;
		margin-top: 25%;
		font-weight: bold;
		line-height: 21px;
		text-align: center;
		font-style: normal;
		font-family: Merriweather;
	}

	table {
		margin-top: 16px;

		.required-drills-count {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: center;

			.input-wrapper {
				margin-bottom: 0px;
			}

			input {
				width: 32px;
				margin-right: 4px;
			}
		}
	}

	.modal-card {
		.dropdown {
			margin: 16px 0;
		}
	}
`;

class ManageStateRequirements extends React.Component {
	state = {
		tableLoading: false,
		requirements: null,
		selectedYear: null,
		selectedState: null,
		selectedRequirement: null,
		newDrillType: null,
		modalToShow: null,
	};

	setModalToShow = (modal, selectedRequirement = null) => {
		const { selectedYear, selectedState } = this.state;
		if (selectedYear && selectedState) {
			this.setState({
				modalToShow: modal,
				selectedRequirement,
			});
		}
	};

	// manages the dropdown
	selectYear = item => {
		// resets the 'state' and 'requirements' because different 'years' have different 'states' and 'requirements'
		this.setState({
			selectedYear: item,
			selectedState: null,
			requirements: null,
		});
	};

	selectState = item => {
		// resets the 'requirements table' because different 'states' have different 'requirements'
		this.setState(
			{
				selectedState: item,
				requirements: null,
				tableLoading: true,
			},
			() => {
				const { selectedYear, selectedState } = this.state;
				DrillStateRequirementsService.fetchRequirements(
					selectedYear?.value,
					selectedState?.id,
				)
					.then(requirements =>
						this.setState({
							requirements,
							tableLoading: false,
						}),
					)
					.catch(() =>
						this.setState({
							requirements: null,
							tableLoading: false,
						}),
					);
			},
		);
	};

	// manage requirements table
	editStateRequirement = (id, someValue) => {
		// create a useable number
		const requiredDrillsCount = parseInt(someValue, 10);

		// update the status and count in the table/state
		const updatedStateRequirement = {
			status: 'Not published',
			requirements: requiredDrillsCount,
		};
		const updateTable = () => {
			this.setState(prevState => ({
				requirements: prevState.requirements.map(item => {
					if (id === item.id) {
						return {
							...item,
							...updatedStateRequirement,
						};
					}

					return item;
				}),
			}));
		};

		// update the requirement
		updateTable();

		// send the request for positive integers
		if (Number.isInteger(requiredDrillsCount) || requiredDrillsCount >= 0) {
			DrillStateRequirementsService.updateNumberOfRequirements(
				id,
				requiredDrillsCount,
			)
				.then(res => {
					// update to published if we passed
					if (res.statusCode === 200) {
						updatedStateRequirement.status = 'Published';
						updateTable();

						Swal.fire({
							title: 'Success!',
							text: 'This requirement has been updated',
							icon: 'success',
							toast: true,
							position: 'top',
							timer: 3000,
							showConfirmButton: false,
						});
					}
				})
				.catch(() =>
					Swal.fire({
						title: 'Error',
						text:
							'Could not save this requirement. Please try again.',
						icon: 'error',
						toast: true,
						position: 'top',
						timer: 3000,
						showConfirmButton: false,
					}),
				);
		}
	};

	editRequiredFields = requirement => {
		this.setState({
			selectedRequirement: requirement,
			modalToShow: 'editRequiredFields',
		});
	};

	saveRequiredFields = (newFields = []) => {
		const {
			selectedRequirement: { id },
		} = this.state;
		const fieldUuids = newFields.map(({ uuid }) => uuid);
		DrillStateRequirementsService.updateRequiredFieldsOrder(id, fieldUuids)
			.then(() =>
				Swal.fire(
					'Success!',
					'The required fields have been saved!',
					'success',
				),
			)
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text:
						'Could not update the required fields. Please try again.',
				}),
			);
	};

	publishRequirements = () => {
		// handle network stuff
		this.setModalToShow(null);
	};

	removeFromTable = requirementId => {
		const { requirements } = this.state;
		DrillStateRequirementsService.deleteRequirement(requirementId)
			.then(() => {
				Swal.fire({
					title: 'Success!',
					text: 'This drill type has been removed',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});

				this.setState({
					requirements: requirements.filter(
						({ id }) => id !== requirementId,
					),
				});
			})
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Could not delete at this time. Please try again.',
				}),
			);
	};

	// manage the header
	addDrillType = () => {
		const { newDrillType, selectedState, selectedYear } = this.state;
		DrillStateRequirementsService.addStateRequirement(
			1,
			newDrillType.uuid,
			selectedState.id,
			selectedYear.value,
		)
			.then(newItem => {
				Swal.fire({
					title: 'Success!',
					text: 'This drill type has been added',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});

				this.setState(prevState => ({
					modalToShow: null,
					newDrillType: null,
					requirements: prevState?.requirements?.concat({
						...newItem,
						status: 'Not published',
					}),
				}));
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text:
						'Could not add a new drill type at this time. Please try again.',
				});
			});
	};

	setNewDrillType = item => {
		this.setState({
			newDrillType: item,
		});
	};

	render() {
		const {
			tableLoading,
			requirements,
			selectedYear,
			selectedState,
			selectedRequirement,
			newDrillType,
			modalToShow,
		} = this.state;
		const { history } = this.props;
		const canPublish = selectedYear && selectedState;

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Manage State Requirements"
					onBackBtnClick={history.goBack}
					customItem={() => (
						<AddDrillType
							disabled={!canPublish}
							onClick={() => this.setModalToShow('addDrillType')}
						>
							<img src={plus} alt="" />
							Add Drill Type
						</AddDrillType>
					)}
				/>

				{/* school, state, and drill type selectors */}
				<div className="dropdown-filters">
					{/* School year */}
					<SchoolYearDropdown
						selectedSchoolYear={selectedYear}
						onChange={item => this.selectYear(item)}
					/>

					{selectedYear && (
						/* States */
						<StatesDropdown
							selectedState={selectedState}
							onChange={item => this.selectState(item)}
						/>
					)}
				</div>

				{selectedYear && selectedState && (
					/* information for to the user about the requirements for the selected state */
					<Callout type="info">
						These are the drill requirements for the state of
						{` ${selectedState?.label}`}. These get published to
						District Admins which they will see as the state’s drill
						requirements.
					</Callout>
				)}

				{/* loading spinner for the table */}
				{tableLoading && <LoadingSpinner />}

				{selectedYear && selectedState && (
					/* edit scheduling and posting instructions */
					<Table
						headers={[
							'Drill type',
							`Drills required for ${selectedState?.label}`,
							'Status',
							'Actions',
						]}
						data={requirements}
						renderItem={item => (
							<>
								<td>{item.drillType.name}</td>
								<td>
									<div className="required-drills-count">
										<Input
											defaultValue={item.requirements}
											onChange={({ target: { value } }) =>
												this.editStateRequirement(
													item.id,
													value,
												)
											}
										/>
										drills
									</div>
								</td>
								<td>{item.status}</td>
								<td>
									<MoreActionsButton
										actions={[
											{
												title: 'Edit required fields',
												id: 'requiredFields',
												handler: () =>
													this.editRequiredFields(
														item,
													),
											},
											{
												title:
													'Edit scheduling instructions',
												id: 'scheduling',
												handler: () =>
													history.push(
														'/manageDrillInstructions',
													),
											},
											{
												title:
													'Edit posting instructions',
												id: 'posting',
												handler: () =>
													history.push(
														'/manageDrillInstructions',
													),
											},
											{
												title: 'Remove from list',
												id: 'remove',
												handler: () =>
													this.removeFromTable(
														item.id,
													),
												isDestructive: true,
											},
										]}
									/>
								</td>
							</>
						)}
					/>
				)}

				{(!selectedYear || !selectedState) && (
					/* inform the user to pick from the drop down */
					<div className="instructions-to-user">
						{!selectedYear
							? 'Please select a School Year'
							: !selectedState &&
							  'Please select a State to continue'}
					</div>
				)}

				{modalToShow === 'publish' && (
					<PublishRequirementsModal
						setModalToShow={this.setModalToShow}
						onActionClick={this.publishRequirements}
						selectedState={selectedState}
						variant="state"
					/>
				)}

				{modalToShow === 'addDrillType' && (
					<AddDrillTypeModal
						setModalToShow={this.setModalToShow}
						onActionClick={this.addDrillType}
						newDrillType={newDrillType}
						onChange={this.setNewDrillType}
						excludeDrillTypes={requirements?.map(
							({ drillType }) => drillType?.uuid,
						)}
					/>
				)}

				{modalToShow === 'editRequiredFields' && (
					// delete or re-order required fields
					<EditRequiredFieldsModal
						setModalToShow={this.setModalToShow}
						onActionClick={this.saveRequiredFields}
						label={selectedRequirement?.drillType?.name}
						selectedRequirement={selectedRequirement}
					/>
				)}

				{modalToShow === 'editSchedulingInstructions' && (
					<EditSchedulingInstructionsModal
						setModalToShow={this.setModalToShow}
						header={selectedRequirement?.drillType?.name}
						onActionClick={() => this.setModalToShow(null)}
					/>
				)}

				{modalToShow === 'editPostingInstructions' && (
					<EditPostingInstructionsModal
						setModalToShow={this.setModalToShow}
						header={selectedRequirement?.drillType?.name}
						onActionClick={() => this.setModalToShow(null)}
					/>
				)}
			</Page>
		);
	}
}

export default ManageStateRequirements;

ManageStateRequirements.propTypes = {
	history: PropTypes.object,
};
