import React, { useContext } from 'react';
import { Button, Tooltip } from 'componentsV2';
import { BROWSE_ICONS_MODAL } from '../../../../../../consts';
import { CreateAlarmTypeContext } from '../../../../../../provider';
import {
	AlarmContact911,
	AlarmCountdown,
	AlarmGeneric,
	AlarmName,
	AlarmPriority,
	AlarmSelectedIcon,
	AlarmSound,
	AlarmType911List,
	FieldCard,
	FlipchartDropDown,
	MarkAllAccountedRadioGroup,
	RollcallRadioGroup,
} from '../SharedFields';
import InputWrapper from 'componentsV2/InputWrapper/InputWrapper';
import PropTypes from 'prop-types';
import './css/Preview.scss';

export const Preview = ({ header, description, rightActions = null }) => {
	const { formData, openModal, formErrors, onChangeFormData } = useContext(
		CreateAlarmTypeContext,
	);

	const flipchartToolTipMessage =
		'Choose the Flipchart that staff and administrators should reference during this type of emergency. Only published Flipcharts set as “Visible to All” will appear in this list.';

	const onShowAlertModal = () => {
		openModal(BROWSE_ICONS_MODAL);
	};

	const inUpdateMode = formData.id > 0;
	const isAlarmTypePreset = formData.preset;
	const isAlarmTypeActive = formData.hasActiveAlarm;
	const contact911 = formData.contact911 ? formData.contact911 : false;

	// disabled tooltip if the alarm type is active
	const disabledTooltip = isAlarmTypeActive
		? 'This alarm is currently active and cannot be edited. Please wait until the alarm concludes or deactivate it before making changes.'
		: null;

	// generic alarm is enabled when utilize 911Cellular integration is enabled and "Yes" selected and (no other alarm type is set as generic or in edit mode)
	const isIntegrationActiveAndYesSelected = formData._911Active && contact911;
	const canChangeGeneric = !formData.anotherAlarmIsGeneric || formData.isGeneric;
	const genericAlarmEnabled =
		!isAlarmTypeActive && isIntegrationActiveAndYesSelected && canChangeGeneric;

	const genericFieldTooltip = disabledTooltip
		? disabledTooltip
		: !canChangeGeneric
		? 'Only one alarm type can be set as generic.'
		: !isIntegrationActiveAndYesSelected
		? 'This section is utilized when 911Cellular is configured with alarms.'
		: null;

	const fieldIsDisabled = isAlarmTypePreset || isAlarmTypeActive;
	const contact911FieldisDisabled = formData._911Active ? isAlarmTypeActive : fieldIsDisabled;

	const AlarmPriorityField = () => (
		<AlarmPriority
			formData={formData}
			formErrors={formErrors}
			onChangeFormData={onChangeFormData}
			disabled={isAlarmTypeActive}
		/>
	);

	const AlarmIconButton = () => (
		<Button
			type="ghostPrimary"
			onClick={onShowAlertModal}
			text="Browse icons"
			wrapperClass={formErrors?.icon ? 'error' : ''}
			disabled={fieldIsDisabled}
		/>
	);

	const AlarmCountdownField = () => (
		<AlarmCountdown
			formData={formData}
			formErrors={formErrors}
			onChangeFormData={onChangeFormData}
			disabled={fieldIsDisabled}
		/>
	);

	const AlarmContact911Field = () => (
		<AlarmContact911
			formData={formData}
			formErrors={formErrors}
			onChangeFormData={onChangeFormData}
			disabled={contact911FieldisDisabled}
		/>
	);

	const AlarmGenericField = () => (
		<AlarmGeneric
			formData={formData}
			formErrors={formErrors}
			onChangeFormData={onChangeFormData}
			disabled={!genericAlarmEnabled}
		/>
	);

	const RollcallRadioGroupField = () => (
		<RollcallRadioGroup
			formData={formData}
			formErrors={formErrors}
			onChangeFormData={onChangeFormData}
			disabled={fieldIsDisabled}
		/>
	);

	const MarkAllAccountedRadioGroupField = () => (
		<MarkAllAccountedRadioGroup
			formData={formData}
			formErrors={formErrors}
			onChangeFormData={onChangeFormData}
			disabled={fieldIsDisabled}
		/>
	);

	return (
		<div className="create-alarm-type-preview-section-container">
			<FieldCard
				header={header}
				description={description}
				rightActions={rightActions}
				className={`create-alarm-type-preview-section-container-header ${
					inUpdateMode ? 'update-mode' : ''
				}`}
			/>
			<section className="create-alarm-type-preview-section-container-body">
				<FieldCard
					header="Set as priority alarm"
					tooltip="Setting an alarm as priority will ensure it appears at the top of the alarm activation list."
				>
					{disabledTooltip ? (
						<Tooltip tooltipTitle={disabledTooltip}>
							<AlarmPriorityField />
						</Tooltip>
					) : (
						<AlarmPriorityField />
					)}
				</FieldCard>
				<FieldCard header="Alarm name and icon">
					<div className="create-alarm-type-preview-section-container-body-alarm-name-container">
						<div className="create-alarm-type-preview-section-container-body-alarm-name-container-name">
							{disabledTooltip ? (
								<Tooltip tooltipTitle={disabledTooltip}>
									<AlarmName
										formData={formData}
										formErrors={formErrors}
										onChangeFormData={onChangeFormData}
										disabled={fieldIsDisabled}
									/>
								</Tooltip>
							) : (
								<AlarmName
									formData={formData}
									formErrors={formErrors}
									onChangeFormData={onChangeFormData}
									disabled={fieldIsDisabled}
								/>
							)}
						</div>
						<div className="create-alarm-type-preview-section-container-body-alarm-name-container-icon-container">
							<InputWrapper label="Alarm icon" isRequired>
								{formData.icon && (
									<AlarmSelectedIcon
										className="create-alarm-type-preview-section-container-body-alarm-name-container-icon-container-icon"
										icon={formData.icon}
									/>
								)}
							</InputWrapper>
							<div className="create-alarm-type-preview-section-container-body-alarm-name-container-icon-container-button">
								<InputWrapper
									error={formErrors?.icon}
									errorMessage={formErrors.icon}
								>
									{disabledTooltip ? (
										<Tooltip tooltipTitle={disabledTooltip}>
											<AlarmIconButton />
										</Tooltip>
									) : (
										<AlarmIconButton />
									)}
								</InputWrapper>
							</div>
						</div>
					</div>
				</FieldCard>
				<FieldCard
					header={
						<>
							Sound alarm on device when alarm is activated{' '}
							<span className="text-danger">*</span>
						</>
					}
					tooltip="Should users hear a sound on their devices when this alarm is activated?"
				>
					<AlarmSound
						formData={formData}
						formErrors={formErrors}
						onChangeFormData={onChangeFormData}
						disabled={fieldIsDisabled}
						tooltipTitle={disabledTooltip ? disabledTooltip : null}
					/>
				</FieldCard>
				<FieldCard
					header={
						<>
							Activate countdown before alarm triggers{' '}
							<span className="text-danger">*</span>
						</>
					}
					tooltip={
						disabledTooltip ? (
							disabledTooltip
						) : (
							<>
								Would you like to include a 5 second countdown before the alarm is
								triggered? This gives the individual a window to cancel the alarm.
							</>
						)
					}
				>
					{disabledTooltip ? (
						<Tooltip tooltipTitle={disabledTooltip}>
							<AlarmCountdownField />
						</Tooltip>
					) : (
						<AlarmCountdownField />
					)}
				</FieldCard>
				{formData._911Active && (
					<FieldCard className="create-alarm-type-preview-section-container-body-911cellular-container">
						<FieldCard
							header={
								<>
									Utilize 911Cellular integration{' '}
									<span className="text-danger">*</span>
								</>
							}
							tooltip="Do you want to use this alarm with your 911Cellular configuration?"
							className="create-alarm-type-preview-section-container-body-911cellular-container-contact no-padding no-border"
						>
							{isAlarmTypeActive ? (
								<Tooltip tooltipTitle={disabledTooltip}>
									<AlarmContact911Field />
								</Tooltip>
							) : (
								<AlarmContact911Field />
							)}
						</FieldCard>
						<FieldCard
							header={
								<>
									Choose 911Cellular alarm <span className="text-danger">*</span>
								</>
							}
							tooltip="This alarm name will be what dispatchers see at the 911 call center when this alarm is activated."
							className="create-alarm-type-preview-section-container-body-911cellular-container-list no-padding no-border"
						>
							<AlarmType911List
								formData={formData}
								formErrors={formErrors}
								onChangeFormData={onChangeFormData}
								disabled={contact911FieldisDisabled}
								tooltipTitle={contact911FieldisDisabled ? disabledTooltip : null}
							/>
						</FieldCard>
					</FieldCard>
				)}
				{!formData._911Active && (
					<FieldCard
						header={
							<>
								Enable 911 prompt on alarm activation{' '}
								<span className="text-danger">*</span>
							</>
						}
						tooltip={
							<>
								Would you like a prompt to call 911 when this alarm goes off?
								<br />
								<br />
								This serves as a reminder for the individual activating the alarm to
								reach out to emergency services if they haven't done so.
							</>
						}
					>
						{disabledTooltip ? (
							<Tooltip tooltipTitle={disabledTooltip}>
								<AlarmContact911Field />
							</Tooltip>
						) : (
							<AlarmContact911Field />
						)}
					</FieldCard>
				)}
				<FieldCard
					header="Set alarm as generic"
					tooltip="Setting an alarm as generic will designate this specific alarm as the default option in case no specific alarm type is chosen."
				>
					{genericFieldTooltip ? (
						<Tooltip tooltipTitle={genericFieldTooltip}>
							<AlarmGenericField />
						</Tooltip>
					) : (
						<AlarmGenericField />
					)}
				</FieldCard>
				<FieldCard
					header={
						<>
							Flipchart to include <span className="text-danger">*</span>
						</>
					}
					tooltip={flipchartToolTipMessage}
					className="create-alarm-type-preview-section-container-body-flipchart-container"
				>
					{disabledTooltip ? (
						<Tooltip tooltipTitle={disabledTooltip} className="w-50">
							<FlipchartDropDown
								formData={formData}
								formErrors={formErrors}
								onChangeFormData={onChangeFormData}
								disabled={fieldIsDisabled}
								className="w-100"
							/>
						</Tooltip>
					) : (
						<FlipchartDropDown
							formData={formData}
							formErrors={formErrors}
							onChangeFormData={onChangeFormData}
							disabled={fieldIsDisabled}
							className="w-50"
						/>
					)}
				</FieldCard>
				<FieldCard
					header={
						<>
							Roll Call <span className="text-danger">*</span>
						</>
					}
					tooltip="Would you like a roll call to be taken of students, staff, and visitors when this alarm is activated?"
				>
					{disabledTooltip ? (
						<Tooltip tooltipTitle={disabledTooltip}>
							<RollcallRadioGroupField />
						</Tooltip>
					) : (
						<RollcallRadioGroupField />
					)}
					<FieldCard
						header={
							<>
								Mark all as accounted for option{' '}
								<span className="text-danger">*</span>
							</>
						}
						tooltip='Would you prefer enabling the option "Mark all as accounted for" for users during Roll Call to account for their entire class simultaneously? Selecting "No" means users must account for each student individually.'
						className="no-border no-padding "
					>
						{disabledTooltip ? (
							<Tooltip tooltipTitle={disabledTooltip}>
								<MarkAllAccountedRadioGroupField />
							</Tooltip>
						) : (
							<MarkAllAccountedRadioGroupField />
						)}
					</FieldCard>
				</FieldCard>
			</section>
		</div>
	);
};

Preview.propTypes = {
	header: PropTypes.string.isRequired || PropTypes.element.isRequired,
	description: PropTypes.string || PropTypes.element,
	rightActions: PropTypes.element,
};
