import React from 'react';
import './css/CardContainer.scss';
import PropTypes from 'prop-types';
import { Icon, IconSize } from '@blueprintjs/core';

export const CardContainer = ({
	title,
	children,
	rightAction,
	subtitle,
	bodyCustomClass = '',
	cardCustomClass = '',
	icon = '',
	...restProps
}) => (
	<div className={`card card-container ${cardCustomClass}`} {...restProps}>
		{icon && <Icon {...{ icon, size: IconSize.STANDARD }} />}
		{(title || rightAction || subtitle) && (
			<div className="card-container-header">
				<div className="title">
					{title}
					<div className="subtitle">{subtitle}</div>
				</div>
				<div className="right-side">{rightAction}</div>
			</div>
		)}
		<div className={`card-container-body ${bodyCustomClass}`}>{children}</div>
	</div>
);

CardContainer.propTypes = {
	title: PropTypes.string,
	children: PropTypes.any,
	rightAction: PropTypes.any,
	subtitle: PropTypes.any,
	bodyCustomClass: PropTypes.string,
	cardCustomClass: PropTypes.string,
	icon: PropTypes.string,
};
