import React from 'react';
import { logoNavigateSwitch, logoExpired, callout } from 'assets/icons';
import './css/PasswordExpired.scss';

export default function SetPasswordExpired() {
	return (
		<div className="expired-container">
			<div className="expired-wrapper">
				<div className="expired-box">
					<img alt="Navigate360 Logo" src={logoNavigateSwitch} />
					<img alt="logo expired" src={logoExpired} />

					<div className="expired-link-message">
						<h6>Oops!</h6> <p>It seems like the link has expired</p>
					</div>

					<img alt="Callout" src={callout} />
				</div>
			</div>
		</div>
	);
}
