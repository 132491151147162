import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'components/Button';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import RolesDropDown from './RolesDropdown';
import SitesDropDown from './SitesDropdown';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from 'react-redux';
import { BuildingsService } from 'services/BuildingsService';
import { LoadingSpinner } from 'components/LoadingSpinner';

const buildTree = (hierarchyFlatList, sitesDetails) => {
	const map = {};
	const roots = [];

	// Create a map of details for easy lookup
	const detailsMap = {};
	sitesDetails.forEach(({ id, name }) => {
		detailsMap[id] = name;
	});

	// Initialize a map of items with id, name, and children
	hierarchyFlatList.forEach(item => {
		map[item.id] = { ...item, name: detailsMap[item.id], children: [] };
	});

	// Add children to parents
	hierarchyFlatList.forEach(item => {
		if (item.parent !== null) {
			map[item.parent]?.children
				? map[item.parent]?.children?.push(map[item.id])
				: roots.push(map[item.id]);
		} else {
			roots.push(map[item.id]);
		}
	});

	return roots;
};

const assignIndentation = (node, indent, result) => {
	result.push({ id: node.id, name: node.name, indent });
	node.children.forEach(child => assignIndentation(child, indent + 1, result));
};

// This function returns a list of items that can be passed into the sites dropdown list.
// It assigns an indentation value based on the sites hierarchy for the current district.
const formatSitesListWithIndent = (sitesHierarchy, sitesDetails) => {
	// Create a flat list of hierarchy objects
	const hierarchyFlatList = Object.values(sitesHierarchy);

	// Build a tree structure so we can use recursion to assign indentation levels
	const tree = buildTree(hierarchyFlatList, sitesDetails);

	// Traverse the tree and assign indentation levels
	const result = [];
	tree.forEach(root => assignIndentation(root, 0, result));

	return result;
};

export default function SitePermissions(props) {
	const [sites, setSites] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingSites, setLoadingSites] = useState(true);
	const [assignedRoles, setAssignedRoles] = useState([]);
	const [disableSubmit, setDisableSubmit] = useState(true);
	const [roleDropDowns, setRoleDropDowns] = useState([]);
	const [assignedRolesOriginal, setAssignedRolesOriginal] = useState([]);
	const [confirmToShow, setConfirmToShow] = useState(null);
	const [assignedIdRemove, setAssignedIdRemove] = useState(null);
	const [assignedIndexRemove, setAssignedIndexRemove] = useState(null);
	const [idsToRemove, setIdsToRemove] = useState([]);
	const [canEditBuilding, setCanEditBuilding] = useState([]);
	const [disabledSites, setDisabledSites] = useState(null);
	const toaster = useContext(ToasterContext);
	const [isReadOnlyForm, setIsReadOnlyForm] = useState(false);
	const permissions = useSelector(state => state.route.permissions);
	const [buildingHasAlerts, setBuildingHasAlerts] = useState([]);
	const [buildingHasAlertMessage, setBuildingHasAlertMessage] = useState(false);
	const [isError, setIsError] = useState(false);

	const url = process.env.API_URL; //const url = "http://localhost:8086";
	const token = JSON.parse(localStorage.getItem('user')).jwt;

	const getSites = (readOnly = false) => {
		BuildingsService.getAll(false)
			.then(buildings => {
				// Set building alerts
				let hasBuildingAlerts = [];
				buildings.forEach(building => {
					hasBuildingAlerts[building.id] = props?.user?.buildingAlerts.some(
						buildingAlert => {
							return buildingAlert.id === building.id;
						},
					);
				});
				setBuildingHasAlerts(hasBuildingAlerts);

				// Format sites list for dropdown, including any indentation levels based on district hierarchy
				const sitesWithIndent = formatSitesListWithIndent(
					permissions.buildingHierarchy,
					buildings,
				);
				setSites(sitesWithIndent);

				if (!readOnly) {
					// after having sites
					getUserDetails(readOnly, buildings);
				}
			})
			.catch(error => {
				toaster('Network error', Intent.DANGER);
				setIsError(true);
				console.log(error);
			})
			.finally(() => setLoadingSites(false));
	};

	useEffect(() => {
		if (!props.isSuperAdmin && !props.hasUserEditPermission) {
			setIsReadOnlyForm(true);
			getUserDetails(true, {});
		} else {
			getSites();
		}
	}, []);

	const getUserDetails = (readOnly = false, mySites = []) => {
		fetch(`${url}/users/${props.user.id}/accessdetails`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				setAssignedRoles(resp.data);
				setAssignedRolesOriginal(resp.data);

				let roleDdowns = [];
				let selectedSites = [];
				let canEditSiteUser = [];
				resp.data.map((item, inx) => {
					let canEditSite = checkPermission('user_edit', permissions, item.building.id);
					canEditSiteUser[item.building.id] = canEditSite;
					roleDdowns[inx] = (
						<RolesDropDown
							dataIndex={inx}
							id={`roles-dd-${inx}`}
							siteId={item.building.id}
							value={item.role.id}
							assignedRoles={resp.data}
							onSelectItem={updateRoleAssignedRole}
							isReadOnly={readOnly || !canEditSite}
							savedName={item.role.title}
						/>
					);
					selectedSites.push(item.building.id);
				});

				setCanEditBuilding(canEditSiteUser);
				setRoleDropDowns(roleDdowns);
				setDisabledSites(selectedSites);
				setLoading(false);
				setLoadingSites(false);
			});
	};

	const appendAssignedRole = () => {
		let rowAppend = {
			id: null,
			building: { id: null, active: true },
			role: { id: null },
		};

		// append roledropdown
		let newRolesDdowns = [...roleDropDowns];
		let index = assignedRoles.length;
		newRolesDdowns[index] = (
			<RolesDropDown
				dataIndex={index}
				id={`roles-dd-${index}`}
				siteId={null}
				value={null}
				assignedRoles={assignedRoles}
				onSelectItem={updateRoleAssignedRole}
			/>
		);
		setRoleDropDowns(newRolesDdowns);
		setAssignedRoles([...assignedRoles, rowAppend]);
		setDisableSubmit(false);
		props.setTabHasChange(true);
	};

	const removeAssignedRole = (item, index) => {
		setAssignedIndexRemove(index);
		setConfirmToShow('removeAssignedRole');

		if (item.id != null) {
			setAssignedIdRemove(item.id);
		}
		setBuildingHasAlertMessage(buildingHasAlerts[item.building.id]);
	};

	const cancelRemoveAssignedRole = () => {
		setConfirmToShow(null);
		setAssignedIndexRemove(null);
		setAssignedIdRemove(null);
		setBuildingHasAlertMessage(false);
	};

	const removeSavedAssignedRole = () => {
		props.setTabHasChange(true);
		setDisableSubmit(false);
		setConfirmToShow(null);
		setLoading(true);

		let newAssignedRoles = [...assignedRoles];
		newAssignedRoles.splice(assignedIndexRemove, 1);
		setAssignedRoles(newAssignedRoles);

		let newIdsToRemove = [...idsToRemove];
		if (assignedIdRemove != null) {
			newIdsToRemove.push(assignedIdRemove);
			setIdsToRemove(newIdsToRemove);
		}

		//empty to reload
		setRoleDropDowns([]);
		let roleDdowns = [];
		let selectedSites = [];
		setTimeout(() => {
			newAssignedRoles.map((item, inx) => {
				roleDdowns[inx] = (
					<RolesDropDown
						dataIndex={inx}
						id={`roles-dd-${inx}`}
						siteId={item.building.id}
						value={item.role.id}
						assignedRoles={newAssignedRoles}
						onSelectItem={updateRoleAssignedRole}
						isReadOnly={!canEditBuilding[item.building.id]}
						savedName={item.role.title}
					/>
				);
				selectedSites.push(item.building.id);
			});
			setRoleDropDowns(roleDdowns);
			setDisabledSites(selectedSites);
			setLoading(false);
		}, 10);
	};

	const updateBuildingAssignedRole = (index, value, oldValue) => {
		let newAssignedRoles = JSON.parse(JSON.stringify(assignedRoles));
		let roleIdValue = null;

		if (assignedRolesOriginal[index] === undefined) {
			setDisableSubmit(false);
			props.setTabHasChange(true);
		} else {
			if (value == assignedRolesOriginal[index].building.id) {
				//return to original values
				roleIdValue = assignedRolesOriginal[index].role.id;
				// if you only have one assigned-role
				if (assignedRolesOriginal.length == 1) {
					setDisableSubmit(true);
					props.setTabHasChange(false);
				}
			} else {
				setDisableSubmit(false);
				props.setTabHasChange(true);
			}
		}

		newAssignedRoles[index]['building'] = { id: value, active: true };
		newAssignedRoles[index]['role'] = { id: roleIdValue };
		setAssignedRoles(newAssignedRoles);

		let canEditSiteUser = [];
		newAssignedRoles.map((assignedRole, ainx) => {
			let canEditSite = checkPermission(
				'user_edit',
				permissions,
				assignedRole['building']['id'],
			);
			canEditSiteUser[assignedRole['building']['id']] = canEditSite;
		});
		setCanEditBuilding(canEditSiteUser);

		// //empty slot on dropowns
		let newRolesDdowns = [...roleDropDowns];
		newRolesDdowns[index] = '';
		setRoleDropDowns(newRolesDdowns);
		//re-fill slot on dropowns
		setTimeout(() => {
			let newRolesDdowns = [...roleDropDowns];
			newRolesDdowns[index] = (
				<RolesDropDown
					dataIndex={index}
					id={`roles-ddn-${index}`}
					siteId={value}
					value={newAssignedRoles[index].role.id}
					onSelectItem={updateRoleAssignedRole}
					assignedRoles={newAssignedRoles}
				/>
			);
			setRoleDropDowns(newRolesDdowns);
		}, 10);
	};

	const updateRoleAssignedRole = (asRolesOriginalCBack, index, value, oldValue) => {
		let newAssignedRoles = JSON.parse(JSON.stringify(asRolesOriginalCBack));
		newAssignedRoles[index]['role'] = { id: value };
		if (newAssignedRoles[index].role.id != asRolesOriginalCBack[index].role.id) {
			setDisableSubmit(false);
			props.setTabHasChange(true);
		} else {
			setDisableSubmit(true);
			props.setTabHasChange(false);
		}

		setAssignedRoles(newAssignedRoles);
		//empty to reload
		setRoleDropDowns([]);
		//re-fill other slots on dropdowns
		setTimeout(() => {
			let newRolesDdowns = [...roleDropDowns];
			let canEditSiteUser = [];
			newAssignedRoles.map((assignedRole, ainx) => {
				let canEditSite = checkPermission(
					'user_edit',
					permissions,
					assignedRole['building']['id'],
				);
				canEditSiteUser[assignedRole['building']['id']] = canEditSite;
				newRolesDdowns[ainx] = (
					<RolesDropDown
						dataIndex={ainx}
						id={`roles-ddn-${ainx}`}
						siteId={assignedRole['building']['id']}
						value={assignedRole['role']['id']}
						onSelectItem={updateRoleAssignedRole}
						assignedRoles={newAssignedRoles}
						isReadOnly={!canEditSite}
						savedName={assignedRole['role']['title']}
					/>
				);
			});
			setCanEditBuilding(canEditSiteUser);
			setRoleDropDowns(newRolesDdowns);
		}, 10);
	};

	const submitAssignedRoles = () => {
		let validSite = true;
		let validRole = true;
		for (let i = 0; i < assignedRoles.length; i++) {
			let item = assignedRoles[i];
			if (item.building.id == null) {
				validSite = false;
				break;
			} else if (item.role.id == null) {
				validRole = false;
				break;
			}
		}

		if (!validSite) {
			toaster('All Sites have to be selected.', Intent.DANGER);
			return false;
		}

		if (!validRole) {
			toaster('All Roles have to be selected.', Intent.DANGER);
			return false;
		}

		setLoading(true);
		setDisableSubmit(true);
		props.setUserupdated(false);
		props.setTabHasChange(false);

		let bodyData = {
			user_id: props.user.id,
			assigned_roles: assignedRoles,
		};
		if (idsToRemove.length > 0) {
			bodyData.assigned_roles_deleted = idsToRemove;
		}
		fetch(`${url}/roles/assigned/save`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(bodyData),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					toaster('Site permissions successfully updated', Intent.SUCCESS);
				}
				if (resp.statusCode == 400) {
					toaster(resp.error.description, Intent.DANGER);
				}
				props.setUserupdated(true);
			});
	};

	const confirmationModalMessage = () => {
		let message = `Are you sure you want to remove this site and role?`;

		if (buildingHasAlertMessage) {
			message = `${message} Removing it will remove it from the building alerts as well.`;
		}

		return [<p> {message} </p>];
	};

	return (
		<>
			{isError ? (
				<p>Network error: Unable to display site permissions.</p>
			) : (
				<div className="module-site-permissions">
					<div className="row">
						<div className="col-md-12">
							<h4>Site Permissions</h4>
						</div>
						<div className="col-md-12">
							<p>
								<strong>Assign Roles</strong>
								<br />
								<br />
								Assign a user to multiple sites with an appropriate role for each
								site.
							</p>
						</div>
						{loading ? (
							<div className="col-md-12">
								<LoadingSpinner />
							</div>
						) : (
							assignedRoles.map(
								(item, index) =>
									item?.building?.active === true && (
										<>
											<div className="ddown-account-wrapper col-md-12 col-lg-6">
												<SitesDropDown
													dataIndex={index}
													id={`site-dd-${index}`}
													itemsList={sites}
													value={item.building.id}
													onSelectItem={updateBuildingAssignedRole}
													// disabledOptions={disabledSites}
													disabledSites={disabledSites}
													setDisabledSites={setDisabledSites}
													isReadOnly={
														isReadOnlyForm ||
														(!canEditBuilding[item.building.id] &&
															item.id != null)
													}
													savedName={item.building.name}
												/>
											</div>
											<div className="ddown-account-wrapper primary-role-dd col-md-12 col-lg-6">
												{roleDropDowns[index]}
												{((index != 0 &&
													!isReadOnlyForm &&
													canEditBuilding[item.building.id]) ||
													item.id == null) && (
													<Button
														type="button"
														intent="tertiary"
														icon="remove"
														className="remove-assigned-btn"
														onClick={e =>
															removeAssignedRole(item, index)
														}
														disabled={isReadOnlyForm}
													/>
												)}
											</div>
											<div className="col-md-12 mt-3">
												<hr />
											</div>
										</>
									),
							)
						)}
						{!isReadOnlyForm && (
							<>
								<div className="col-md-12 mt-1">
									<Button
										type="button"
										icon="add"
										text="Add new"
										minimal={true}
										onClick={appendAssignedRole}
										className="no-hover-backgound"
										style={{ marginLeft: '-7px' }}
										intent="ghostPrimary"
									/>
								</div>
								<div className="col-md-12 mt-3">
									<Button
										type="submit"
										intent="primary"
										text="Save"
										large
										onClick={submitAssignedRoles}
										disabled={disableSubmit}
									/>
								</div>
							</>
						)}
					</div>
					{confirmToShow === 'removeAssignedRole' && (
						<ConfirmationModal
							title="Remove Site and Role?"
							text={confirmationModalMessage()}
							confirmBtnTxt="Confirm"
							closeModal={cancelRemoveAssignedRole}
							submitModel={removeSavedAssignedRole}
							loading={loading}
						/>
					)}
				</div>
			)}
		</>
	);
}
