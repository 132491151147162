/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Callout } from 'components/Callout';
import { Checkbox } from '@blueprintjs/core';
import { throttle } from 'lodash';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { UsersService } from 'services/UsersService';
import { CardModal } from './CardModal';

const Wrapper = styled.div`
	width: 400px;

	.subtitle {
		font-family: Merriweather;
		font-size: 15px;
		font-style: normal;
		font-weight: 700;
		line-height: 22px;
		margin-top: 16px;
	}

	.callout {
		margin: 0 !important;
		margin-top: 16px !important;
	}

	.search {
		width: 400px;
		height: 42px;
		padding: 10px;
		margin-top: 8px;
		border-radius: 3px;
		border: 1px solid #d8e1e8;
	}

	.users-list {
		z-index: 10;
		width: 400px;
		margin-top: 5px;
		overflow-y: auto;
		min-height: 300px;
		max-height: 300px;
		border-radius: 3px;
		background-color: white;
		border: 1px solid #d8e1e8;

		.user {
			display: flex;
			padding: 10px;

			.info {
				padding-left: 6px;

				.name {
					font-family: Nunito Sans;
					font-size: 16px;
					line-height: 22px;
				}

				.email {
					font-family: Nunito Sans;
					font-size: 12px;
					line-height: 16px;
					color: #5c7080;
				}
			}

			.checkbox-wrapper {
				display: flex;
				align-items: center;
			}
		}
	}

	.notfound {
		height: 85px;
		display: flex;
		color: #5c7080;
		font-size: 16px;
		line-height: 22px;
		text-align: center;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
`;

export class AddUsersModal extends React.Component {
	baseState = {
		value: null,
		searchStarted: false,
		dataFetched: false,
		users: [],
		selectedUsers: {},
		query: null,
	};

	state = this.baseState;

	async searchUsers(query) {
		this.setState({ searchStarted: true, query });

		try {
			const users = await UsersService.search({
				combineSearch: query,
				page: 0,
				perPage: 7,
			});

			this.setState({ users, dataFetched: true });
		} catch (error) {}
	}

	throttledUserSearch = throttle(this.searchUsers, 800);

	onChange = ({ target }) => {
		if (!target.value || target.value.trim() === '') {
			this.setState({ users: [], query: null });
			return;
		}

		this.throttledUserSearch(target.value);
	};

	toggleUserSelect = user => {
		const { selectedUsers } = this.state;

		if (selectedUsers[user.id]) {
			delete selectedUsers[user.id];
		} else {
			selectedUsers[user.id] = 1;
		}

		this.setState(selectedUsers);
	};

	returnUsers = () => {
		const { onUsersAdd } = this.props;
		const { selectedUsers } = this.state;

		const selectedUserIds = Object.keys(selectedUsers);
		onUsersAdd(selectedUserIds);
	};

	render() {
		const {
			searchStarted,
			dataFetched,
			users,
			query,
			selectedUsers,
		} = this.state;
		const { setModalToShow, subtitle, tip } = this.props;

		const showNotFound = dataFetched && users.length < 1;

		return (
			<CardModal
				className="add-users-modal"
				isVisible
				setIsVisible={setModalToShow}
				title="Add Users"
				actionTitle="Add Selected users"
				onActionClick={this.returnUsers}
			>
				<Wrapper>
					{subtitle && <div className="subtitle">{subtitle}</div>}

					{tip && <Callout type="info">{tip}</Callout>}

					<input
						className="search"
						placeholder="Search by first, last name, or email"
						onChange={this.onChange}
					/>

					{query && (
						<div className="users-list">
							{searchStarted && !dataFetched && (
								<LoadingSpinner />
							)}

							{showNotFound && (
								<div className="notfound">No users found.</div>
							)}

							{users &&
								query &&
								users.map(user => (
									<div key={user.id} className="user">
										<div className="checkbox-wrapper">
											<Checkbox
												checked={selectedUsers[user.id]}
												onChange={() =>
													this.toggleUserSelect(user)
												}
											/>
										</div>
										<div className="info">
											<div className="name">
												{user?.person?.firstName}
											</div>
											<div className="email">
												{user?.person?.emailAddress}
											</div>
										</div>
									</div>
								))}
						</div>
					)}
				</Wrapper>
			</CardModal>
		);
	}
}

AddUsersModal.propTypes = {
	setModalToShow: PropTypes.func,
	onUsersAdd: PropTypes.func,
	subtitle: PropTypes.string,
	tip: PropTypes.string,
};
