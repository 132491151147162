import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { LinkButton } from 'components/LinkButton';
import { LoadingSpinner } from 'components/LoadingSpinner';

class UserVariable extends React.Component {
	state = {
		helpText: '',
		choices: [
			{
				id: 1,
				name: 'Choice 1',
			},
		],
	};

	createVar = () => {
		const { templateId, refreshVariables, toggleLoading } = this.props;
		toggleLoading(true);
		const { helpText, choices } = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates/${templateId}/variables`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				name: '[[USER]]',
				content: JSON.stringify({
					helpText,
					name: 'User',
					typeAnnotation: 'User',
					type: {
						id: 5,
						value: 'User',
					},
					choices,
				}),
			}),
		})
			.then(res => res.json())
			.then(() => refreshVariables());
	};

	addChoice = () => {
		const { choices } = this.state;
		const temp = {
			id: choices.length + 1,
			name: `Choice ${choices.length + 1}`,
		};
		choices.push(temp);
		return this.setState({ choices });
	};

	updateChoice = (val, index) => {
		const { choices } = this.state;
		choices[index].name = val;
		return this.setState({ choices });
	};

	render() {
		const { toggleCreateDialog, loading } = this.props;
		const { helpText, choices } = this.state;
		if (loading) return <LoadingSpinner />;
		return (
			<div className="mt-3">
				<Input
					placeholder="Help text"
					label="Example help text"
					large
					value={helpText}
					onChange={e => this.setState({ helpText: e.target.value })}
					helpText="Help text will appear below the variable input for the end user"
				/>
				<div className="mt-3">
					{choices.map((item, index) => (
						<div
							key={item.id}
							className="d-flex align-items-center mb-2"
						>
							<p className="mb-0 mr-2">{item.id}</p>
							<Input
								value={item.name}
								onChange={e =>
									this.updateChoice(e.target.value, index)
								}
							/>
						</div>
					))}
					<LinkButton
						className="mt-2"
						icon="add"
						text="Add another choice"
						intent="secondary"
						onClick={this.addChoice}
					/>
				</div>
				<div className="d-flex justify-content-end mt-2">
					<Button
						text="Cancel"
						large
						intent="secondary"
						onClick={() => toggleCreateDialog(false)}
					/>
					<Button
						className="ml-2"
						text="Create"
						large
						intent="primary"
						onClick={this.createVar}
					/>
				</div>
			</div>
		);
	}
}

UserVariable.propTypes = {
	templateId: PropTypes.string,
	refreshVariables: PropTypes.func,
	toggleLoading: PropTypes.func,
	toggleCreateDialog: PropTypes.func,
	loading: PropTypes.bool,
};

export default UserVariable;
