import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SingleQuestion from './SingleQuestion';

const Wrapper = styled.div``;

const Title = styled.h2`
	font-family: Merriweather;
	font-style: normal;
	font-weight: normal;
	font-size: 28px;
	line-height: 32px;
	color: #10161a;
	margin-bottom: 0;
`;

const Directions = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #10161a;
`;

class Subsection extends React.Component {
	state = {};

	render() {
		const { item, templateId } = this.props;
		return (
			<Wrapper>
				<div className="d-flex align-items-center justify-content-between">
					<Title>{item.name}</Title>
				</div>
				<Directions>
					<strong>Directions:</strong> {item.instructions}
				</Directions>
				<div>
					{item.questions.map((one, index) => (
						<SingleQuestion
							item={one}
							key={`section-question-${index}`}
							templateId={templateId}
							index={index}
						/>
					))}
				</div>
			</Wrapper>
		);
	}
}

Subsection.propTypes = {
	item: PropTypes.object,
	templateId: PropTypes.any,
};

export default Subsection;
