import React, { useContext } from 'react';
import { ContactsContext } from '../Providers';
import {
	DeleteContactModal,
	ExternalContactModal,
	BulkContactsModal,
	ExistingContactsModal,
} from './';

export const ContactsModals = ({ match }) => {
	const {
		filterationData,
		reloadContacts,
		modalToShow,
		setModalToShow,
		onCloseModal,
		selectedContactCallList,
		selectedContact,
		setReloadContacts,
		contactsList,
		setTotalRows,
		totalRows,
		mode,
		contactID,
	} = useContext(ContactsContext);
	return (
		<>
			{modalToShow === 'ExternalContactModal' && (
				<ExternalContactModal
					onCloseModal={onCloseModal}
					setModalToShow={setModalToShow}
					selectedSites={filterationData.selectedSites}
					selectedCallList={selectedContactCallList}
					mode={mode ? mode : 'add'}
					contactID={contactID}
				/>
			)}

			{modalToShow === 'CallListExistingContactsModal' && (
				<ExistingContactsModal
					onCloseModal={onCloseModal}
					selectedSites={filterationData.selectedSites}
					selectedCallList={selectedContactCallList}
					setReloadContacts={setReloadContacts}
				/>
			)}

			{modalToShow === 'deleteContact' && (
				<DeleteContactModal
					onCloseModal={onCloseModal}
					row={selectedContact}
					callListId={match.params.id}
					setReloadContacts={setReloadContacts}
				/>
			)}

			{modalToShow === 'bulkExternalContacts' && (
				<BulkContactsModal
					onCloseModal={onCloseModal}
					selectedCallList={selectedContactCallList}
					setReloadContacts={setReloadContacts}
				/>
			)}
		</>
	);
};
