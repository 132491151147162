import React, { useContext } from 'react';
import { AlertModal, Button } from 'componentsV2';
import { CreateAlarmTypeContext } from '../provider';

export const MissingRequiredFieldsModal = () => {
	const { closeModal } = useContext(CreateAlarmTypeContext);

	return (
		<AlertModal
			isOpen={true}
			size="medium"
			icon="info-sign"
			iconColor="error"
			title="Missing required information"
			subtitle="Please fill in all the required fields before continuing."
			showCloseButton={true}
			onClose={closeModal}
			alertConfirmButton={
				<Button text="OK" type="primaryDanger" size="md" onClick={closeModal} />
			}
		/>
	);
};
