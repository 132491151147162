import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import Item from './item';

const DragAndDrop = ({ items = [], component, cacheItems }) => {
	if (!component) {
		throw Error(
			'You need to define a "component" before using "DragAndDrop"',
		);
	}

	if (!cacheItems) {
		throw Error(
			'You need to define "cacheItems" before using "DragAndDrop"',
		);
	}

	const [objects, setObjects] = useState([]);

	useEffect(() => {
		setObjects(items);
		cacheItems(items);
	}, [items]);

	const moveItem = (dragIndex, hoverIndex) => {
		const draggedObj = objects[dragIndex];
		const temp = update(objects, {
			$splice: [[dragIndex, 1], [hoverIndex, 0, draggedObj]],
		});

		setObjects(temp);
		cacheItems(temp);
	};

	const removeItem = id => {
		const temp = objects.filter(item => item.id !== id);
		setObjects(temp);
		cacheItems(temp);
	};

	return (
		<DndProvider backend={HTML5Backend}>
			<div>
				{objects.map((obj, i) => (
					<Item
						key={obj.id}
						id={obj.id}
						index={i}
						moveItem={moveItem}
					>
						{component(obj, removeItem)}
					</Item>
				))}
			</div>
		</DndProvider>
	);
};

DragAndDrop.propTypes = {
	items: PropTypes.array.isRequired,
	component: PropTypes.func.isRequired,
	cacheItems: PropTypes.func.isRequired,
};

export { DragAndDrop };
