import React, { useState, createContext, useReducer, useContext } from "react";
import {AddUpdateCopyTemplateModal} from '../Create/AddUpdateCopyTemplateModal';
import {
    ToogleArchiveTemplateModal,
    ToogleArchiveTemplateModalFn,
    DeleteTemplateModal,
    DeleteTemplateModalFn,
} from '../Details/modals';
import { ToasterContext } from 'pages/App';

export const SafetyplansContext = createContext();

export const SafetyplansProvider = ({
    children,
    reloadData,
    history
}) => {
    const [showCreateUpdateCopyTemplateModal,setShowCreateUpdateCopyTemplateModal] = useState(false);
    const [templateId,setTemplateId] = useState();
    const [isCopy,setIsCopy] = useState(false);
    const [modalToShow, setModalToShow] = useState(null);
	const [loading, setLoading] = useState(false);
    const ARCHIVE_MODAL = 'ArchiveModal';
	const DELETE_MODAL = 'DeleteModal';
    const toaster = useContext(ToasterContext);

    const templateDataReducer = (templateData, action) => {
		return {
			id: action.id,
			title: action.title,
			archived: action.archived,
            modalTitle: action.modalTitle,
            confirmBtnTitle: action.confirmBtnTitle,
            source: action.source
		};
	};

    const closeModal = () => {
		setModalToShow(null);
	};

	const [templateData, dispatchTemplateData] = useReducer(
		templateDataReducer,
		{
			id: null,
			title: '',
			archived: null,
            modalTitle: '',
            confirmBtnTitle: '',
            source: ''
		},
	);

    const handleCreateNewTemplate = () => {
        setTemplateId(null);
        setShowCreateUpdateCopyTemplateModal(true);
    }

    const handleUpdateTemplate = (templateId) => {
        setTemplateId(templateId);
        setIsCopy(false);
        setShowCreateUpdateCopyTemplateModal(true);
    }
    const handleCopyTemplate = (templateId) => {
        setTemplateId(templateId);
        setIsCopy(true);
        setShowCreateUpdateCopyTemplateModal(true);
    }

    const {
		showArchiveModal,
		handleArchiveSubmit,
	} = ToogleArchiveTemplateModalFn(
		ARCHIVE_MODAL,
		setLoading,
		templateData,
		dispatchTemplateData,
		closeModal,
		toaster,
		setModalToShow,
		reloadData,
        history
	);
    
    const {
		showDeleteModal,
        handleDeleteSubmit
	} = DeleteTemplateModalFn(
		DELETE_MODAL,
		setLoading,
		templateData,
		dispatchTemplateData,
		closeModal,
		toaster,
		setModalToShow,
        history,
		reloadData,
	);

    const modalFunctions = {
        // use modal functions here
        handleCreateNewTemplate,
        handleUpdateTemplate,
        handleCopyTemplate,
        showArchiveModal,
        showDeleteModal
    }

    return (
        <SafetyplansContext.Provider value={modalFunctions}>
            <AddUpdateCopyTemplateModal 
                showCreateUpdateCopyTemplateModal = {showCreateUpdateCopyTemplateModal}
                setShowCreateUpdateCopyTemplateModal = {setShowCreateUpdateCopyTemplateModal}
                editTemplateId = {templateId}
                reloadData = {reloadData}
                history = {history}
                isCopy = {isCopy}
            />

            <ToogleArchiveTemplateModal
				modalName={ARCHIVE_MODAL}
				modalToShow={modalToShow}
				loading={loading}
				formData={templateData}
				closeModal={closeModal}
				handleSubmit={handleArchiveSubmit}
			/>

            <DeleteTemplateModal
                modalName={DELETE_MODAL}
                modalToShow={modalToShow}
                loading={loading}
            	templateData={templateData}
                closeModal={closeModal}
                handleDeleteSubmit={handleDeleteSubmit}
            />

            {children}
        </SafetyplansContext.Provider>
    );
}