import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DownloadBaseFile from './downloadbasefile';
import {FormModal, FileUpload} from "componentsV2";
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

export const Reupload = ({
	closeUploadModal,
	sectionIndex,
	subsectionIndex,
	baseFile,
	uploadFile,
	loading
}) => {
	const [step, setStep] = useState(1);
	const [uploadedFile, setUploadedFile] = useState('');
	const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);
	const [showProgressBar, setShowProgressBar] = useState(false);

	
	const changeStep = (step) => {
		setStep(step)
	};

	const onFileInputChange = async(file) => {
		setShowProgressBar(true)
		setUploadedFile(file)

		if (file && file.name) {
			setConfirmBtnDisabled(false)
			setTimeout(() => {
				setShowProgressBar(false)
			}, 4000)

		} else {
			setConfirmBtnDisabled(true)
		}
	}

	const disableConfirmBtn = () => {
		setConfirmBtnDisabled(true)
	}

	
	const upload = () => {
		uploadFile(uploadedFile, sectionIndex, subsectionIndex)
	}

	const onReplaceAction = () => {
		disableConfirmBtn()	
	}

	return (<>
		{
			step === 1 ? 
				<DownloadBaseFile
					baseFile={baseFile}
					changeStep={() => changeStep(2)}
					closeUploadModal={closeUploadModal}
				/>
			:
			<FormModal
				isOpen={true}
				onClose={() => {}}
				title={'Step 2 of 2: Upload your document'}
				subtitle="Upload your signed document for School Emergency Operations Plan."
				showCloseButton={false}
				footer={true}
				loading= {loading}
				scrollContent={false}
				size='medium'
				showBackArrow={true}
				backArrowAction={() => changeStep(1)}
				confirmBtn={{
					title: `Save and Continue`,
					onClick: upload,
					type:"primaryDefault",
					size:"md",
					disabled:confirmBtnDisabled
				}}
				cancelBtn={{
					title:'Cancel',
					type:"tertiary",
					size:"md",
					onClick:closeUploadModal
				}}
			
			>   
				<div className='col-md-12'>
					<FileUpload
						identifier={`fileKey`}
						labelState = {false}
						allowedFiles = {['pdf', 'png', 'jpeg', 'jpg', 'txt', 'gif', 'doc', 'docx', 'xlsx', 'xls']}
						allowedFilesLegend = {`PDF, PNG, JPEG, GIF, TXT, DOCS, XLSX or XLS (max. ${MAX_FILE_UPLOADER_SIZE}mb)`}
						defaultErrorMessage = 'Upload failed, please try again'
						maxFileSize={MAX_FILE_UPLOADER_SIZE}
						file=""
						fileForEditing={true}
						showProgressBar={showProgressBar}
						onFileInputChange = {onFileInputChange}
						onDeleteFile={disableConfirmBtn}
						onReplaceAction={onReplaceAction}
						showDeleteBtn={true}
						downloadText = 'Download'
						showDownloadAction={false}
						showReplaceAction={true}
						onDownloadClickHandler={() => {}}
						disabled={false}
					/>
				
				</div>
			</FormModal>
		}	
	</>)
}


Reupload.propTypes = {
	valueFile: PropTypes.object,
	baseFile: PropTypes.object,
	closeUploadModal: PropTypes.func,
	templateId: PropTypes.string,
	dispatch: PropTypes.func,
	sectionIndex: PropTypes.number,
	subsectionIndex: PropTypes.number,
};

export default Reupload;
