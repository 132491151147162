import React, { useEffect, useState, useContext } from 'react';
import { LoadingSpinner, Button } from 'componentsV2';
import { Filtration, EmptyStatuses, PlansTable } from './components';
import { PlanService } from 'services/PlanService';
import useSelectedSites from 'hooks/useSelectedSites';
import { DistrictTemplateContext, DistrictTemplateProvider } from '../Providers';
import './css/DistrictPlans.scss';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';

export const DistrictPlans = () => {
	const [loading, setLoading] = useState(true);
	const [tableLoading, setTableLoading] = useState(false);
	const [plans, setPlans] = useState([]);
	const [noDataAdded, setNoDataAdded] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [showEmptyStatus, setShowEmptyStatus] = useState(false);
	const defaultSelectedSites = useSelectedSites();

	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('safety_plans_edit', permissions);

	const [filterationData, setFilterationData] = useState({
		isNotDropdown: true,
		name: null,
		buildingIds: [],
	});

	const { setSelectedSites, handleCreateNewSafetyPlan, history } = useContext(
		DistrictTemplateContext,
	);

	useEffect(() => {
		getPlansList();
		setSelectedSites(filterationData.buildingIds);
	}, [filterationData]);

	const getPlansList = async () => {
		PlanService.getAll(filterationData)
			.then(async response => {
				if (response.statusCode === 200) {
					setPlans(response.data);
					var isEmpty = response.data.length === 0;
					setNoDataAdded(isFirstRender && isEmpty);
					setShowEmptyStatus(isEmpty);
				}
			})
			.catch(error => {
				console.error('Error fetching Templates list:', error);
			})
			.finally(() => {
				setLoading(false);
				setTableLoading(false);
				setIsFirstRender(false);
			});
	};

	const getPlansListWithLoading = () => {
		setTableLoading(true);
		getPlansList();
	};

	const createBtn = editPermission ? (
		<Button
			icon="plus"
			text="New safety plan"
			className="actionButtonElement"
			onClick={handleCreateNewSafetyPlan}
		/>
	) : null;

	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && (
				<>
					<DistrictTemplateProvider history={history} loadData={getPlansListWithLoading}>
						<div className="templates-container">
							{!noDataAdded && (
								<>
									<section className="templates-section-header-container">
										<div className="left-side">
											<span className="text">Safety plans</span>
										</div>
										<div className="right-side">{createBtn}</div>
									</section>

									<section className="templates-section-filter-container">
										<Filtration
											filterationData={filterationData}
											setFilterationData={setFilterationData}
											defaultSelectedSites={defaultSelectedSites}
										/>
									</section>
								</>
							)}
							{showEmptyStatus && (
								<EmptyStatuses
									filterationData={filterationData}
									noDataAdded={noDataAdded}
									createBtn={createBtn}
								/>
							)}
							{!showEmptyStatus && (
								<>
									{tableLoading && <LoadingSpinner />}
									{!tableLoading && <PlansTable list={plans} history={history} />}
								</>
							)}
						</div>
					</DistrictTemplateProvider>
				</>
			)}
		</>
	);
};
