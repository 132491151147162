import React, { useState, useReducer, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { FeaturedIcon, FormModal, Input } from 'componentsV2';
import { Helper, changedData } from 'utilities/helper';
import { UserService } from 'services';
import { isNotEmpty, isObjNotEmpty, isValidEmail, isValidPhone } from 'utilities/validations';
import { PHONE_NUMBER_MASK } from 'utilities/constants';
import './NewUserResponderModal.scss';

export const NewUserResponderModal = ({
	closeModal,
	buildingId,
	addUser,
	isEdit,
	editUser,
	editPassword,
	user = {},
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		mobilePhone: '',
		title: '',
		password: '',
		confirmPassword: '',
	});

	const [validationErrors, setErrors] = useState({});
	const [hasChange, setHasChange] = useState(false);
	const [emailHasChange, setEmailHasChange] = useState(false);

	const { id, ...originalData } = user;

	const validate = async () => {
		const errors = {};

		if (!isNotEmpty(formData.firstName)) {
			errors.firstName = '*First name is required.';
		}

		if (!isNotEmpty(formData.lastName)) {
			errors.lastName = '*Last name is required.';
		}

		if (!isNotEmpty(formData.mobilePhone)) {
			errors.mobilePhone = '*Phone number is required.';
		} else if (!isValidPhone(formData.mobilePhone)) {
			errors.mobilePhone = '*Phone number is not valid.';
		}

		if (!isNotEmpty(formData.email)) {
			errors.email = '*Email is required.';
		} else if (!isValidEmail(formData.email)) {
			errors.email = '*Email is not valid.';
		} else if (emailHasChange && originalData.email !== formData.email) {
			const emailExists = await UserService.checkEmailExists(formData.email);

			if (emailExists) {
				errors.email = '*Email already exists.';
			}
		}

		if (isNotEmpty(formData.password)) {
			const missingCharacters = '*Not enough characters.';
			const missingNumber = '*Missing number.';
			const missingSpecialCharacter = '*Missing special character.';
			const passwordMatches = '*The password you entered does not match.';

			if (!isNotEmpty(formData.confirmPassword)) {
				errors.confirmPassword = '*Confirm password is required.';
			} else {
				if (!passwordHints.eightCharacters) {
					errors.password = missingCharacters;
				} else if (!passwordHints.oneNumber) {
					errors.password = missingNumber;
				} else if (!passwordHints.oneCharacter) {
					errors.password = missingSpecialCharacter;
				} else if (!passwordHints.passwordMatches) {
					errors.password = passwordMatches;
				}

				if (isNotEmpty(formData.confirmPassword)) {
					if (!passwordHints.eightCharacters) {
						errors.confirmPassword = missingCharacters;
					} else if (!passwordHints.oneNumber) {
						errors.confirmPassword = missingNumber;
					} else if (!passwordHints.oneCharacter) {
						errors.confirmPassword = missingSpecialCharacter;
					} else if (!passwordHints.passwordMatches) {
						errors.confirmPassword = passwordMatches;
					}
				}
			}
		}

		return errors;
	};

	const handleSubmit = () => {
		validate().then(errors => {
			if (Object.keys(errors).length === 0) {
				const role_id = 1;

				if (isEdit) {
					const updateData = changedData(originalData, formData);
					const updateKeys = Object.keys(updateData);
					const name = {
						firstName: formData.firstName,
						lastName: formData.lastName,
					};

					// Adds mobilePhone back because the backend won't save the user if there isn't a phone number
					if (updateKeys.length > 0 && !updateKeys.includes('mobilePhone')) {
						updateData.mobilePhone = formData.mobilePhone;
					}

					// This turned into a mess. Updating the password is a separate route.
					if (
						// Updates if the only changes are to the password
						updateKeys.length === 2 &&
						updateKeys.includes('password') &&
						updateKeys.includes('confirmPassword')
					) {
						editPassword(
							user.id,
							{
								password: formData.password,
								passwordConfirmation: formData.confirmPassword,
							},
							name,
							true,
						);
					} else if (
						// Updates user data and password if user data update is successful.
						isNotEmpty(formData.password) &&
						isNotEmpty(formData.confirmPassword)
					) {
						editUser(user.id, updateData, name, true);
					} else {
						// Updates only the user data
						editUser(user.id, updateData, name);
					}
				} else {
					addUser({
						site_id: buildingId,
						role_id,
						first_name: formData.firstName,
						last_name: formData.lastName,
						email: formData.email,
						phoneNumber: formData.mobilePhone,
						title: formData.title,
						password: formData.password,
						confirmPassword: formData.confirmPassword,
					});
				}
				closeModal();
			} else {
				setErrors(errors);
				const firstErrorField = Object.keys(errors)[0];
				const inputElement = document.getElementsByName(firstErrorField)[0];
				if (inputElement) {
					inputElement.focus();
				}
			}
		});
	};

	const passwordHintsReducer = (passwordHints, action) => {
		switch (action.type) {
			case 'password':
				// at least has one number
				if (/\d/.test(action.payload)) {
					passwordHints.oneNumber = true;
				} else {
					passwordHints.oneNumber = false;
				}

				// at least has one special character
				if (/[!@#$%^&*~_-]/.test(action.payload)) {
					passwordHints.oneCharacter = true;
				} else {
					passwordHints.oneCharacter = false;
				}

				// greater than 8
				if (action.payload && action.payload.length >= 8) {
					passwordHints.eightCharacters = true;
				} else {
					passwordHints.eightCharacters = false;
				}

				if (action.payload === passwordHints.passwordValue) {
					passwordHints.passwordMatches = true;
				} else if (
					action.payload !== passwordHints.passwordValue &&
					action.confirmPassword !== undefined
				) {
					passwordHints.passwordMatches = false;
				}

				if (action.payload === action.confirmPassword) {
					passwordHints.passwordMatches = true;
				}

				passwordHints.passwordValue = action.payload;
				break;
			case 'confirmPassword':
				if (action.payload !== '') {
					passwordHints.confirmPasswordHasValue = true;
				} else {
					passwordHints.confirmPasswordHasValue = false;
				}

				if (action.payload === passwordHints.passwordValue) {
					passwordHints.passwordMatches = true;
				} else {
					passwordHints.passwordMatches = false;
				}
				break;
			default:
				passwordHints.oneCharacter = false;
				passwordHints.oneNumber = false;
				passwordHints.eightCharacters = false;
				passwordHints.passwordValue = false;
				passwordHints.passwordMatches = true;
				passwordHints.confirmPasswordHasValue = false;
				break;
		}

		return {
			oneCharacter: passwordHints.oneCharacter,
			oneNumber: passwordHints.oneNumber,
			eightCharacters: passwordHints.eightCharacters,
			passwordValue: passwordHints.passwordValue,
			passwordMatches: passwordHints.passwordMatches,
			confirmPasswordHasValue: passwordHints.confirmPasswordHasValue,
		};
	};

	const [passwordHints, dispatchPasswordHints] = useReducer(passwordHintsReducer, {
		oneCharacter: false,
		oneNumber: false,
		eightCharacters: false,
		passwordValue: false,
		passwordMatches: true,
		confirmPasswordHasValue: false,
	});

	useEffect(() => {
		if (isEdit) {
			setFormData(originalData);
		}
	}, []);

	useEffect(() => {
		if (isEdit) {
			const updateData = changedData(originalData, formData);
			if (!isObjNotEmpty(updateData)) {
				setHasChange(false);
			}
		}
	}, [formData]);

	return (
		<FormModal
			isOpen
			onClose={closeModal}
			showCloseButton
			title={isEdit ? 'Edit user' : 'Add new user'}
			confirmBtn={{
				title: isEdit ? 'Update' : 'Add',
				onClick: () => handleSubmit(),
				disabled: !hasChange,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: () => closeModal(),
			}}
		>
			<div className="new-responder-user-modal">
				<div className="side-by-side">
					<Input
						label="First name"
						placeholder="First name"
						isRequired
						error={!!validationErrors.firstName}
						errorMessage={validationErrors.firstName}
						value={formData.firstName}
						onChange={e => {
							validationErrors.firstName = '';
							setFormData({ ...formData, firstName: e.target.value });
							if (isNotEmpty(e.target.value)) {
								setHasChange(true);
							} else {
								setHasChange(false);
							}
						}}
					/>
					<Input
						label="Last name"
						placeholder="Last name"
						isRequired
						error={!!validationErrors.lastName}
						errorMessage={validationErrors.lastName}
						value={formData.lastName}
						onChange={e => {
							validationErrors.lastName = '';
							setFormData({ ...formData, lastName: e.target.value });
							if (isNotEmpty(e.target.value)) {
								setHasChange(true);
							} else {
								setHasChange(false);
							}
						}}
					/>
				</div>
				<div className="side-by-side">
					<Input
						label="Phone number"
						placeholder="Phone number"
						value={formData.mobilePhone}
						isRequired
						mask={PHONE_NUMBER_MASK}
						error={!!validationErrors.mobilePhone}
						errorMessage={validationErrors.mobilePhone}
						onChange={e => {
							validationErrors.mobilePhone = '';
							setFormData({
								...formData,
								mobilePhone: Helper.formatPhoneToDigits(e.target.value),
							});
							if (isNotEmpty(e.target.value)) {
								setHasChange(true);
							} else {
								setHasChange(false);
							}
						}}
					/>
					<Input
						label="Title"
						placeholder="Title"
						value={formData.title}
						onChange={e => {
							setFormData({ ...formData, title: e.target.value });
							if (isNotEmpty(e.target.value)) {
								setHasChange(true);
							} else {
								setHasChange(false);
							}
						}}
					/>
				</div>
				<Input
					label="Email"
					placeholder="Email"
					isRequired
					value={formData.email}
					error={!!validationErrors.email}
					errorMessage={validationErrors.email}
					onChange={e => {
						validationErrors.email = '';
						setFormData({ ...formData, email: e.target.value });
						setEmailHasChange(true);
						if (isNotEmpty(e.target.value)) {
							setHasChange(true);
						} else {
							setHasChange(false);
						}
					}}
				/>
				<div className="side-by-side">
					<Input
						label="Password"
						placeholder="Password"
						value={formData.password}
						error={!!validationErrors.password}
						errorMessage={validationErrors.password}
						onChange={e => {
							validationErrors.password = '';
							setFormData({ ...formData, password: e.target.value });
							dispatchPasswordHints({
								type: 'password',
								payload: e.target.value,
								confirmPassword: formData.confirmPassword,
							});
							setHasChange(true);
						}}
						type={showPassword ? 'text' : 'password'}
						autoComplete="new-password"
						rightElement={
							<span
								className="show-password"
								onClick={() => setShowPassword(!showPassword)}
							>
								<FeaturedIcon icon={showPassword ? 'eye-open' : 'eye-off'} />
							</span>
						}
					/>
					<Input
						label="Confirm password"
						placeholder="Confirm password"
						value={formData.confirmPassword}
						isRequired={formData.password !== ''}
						error={!!validationErrors.confirmPassword}
						errorMessage={validationErrors.confirmPassword}
						onChange={e => {
							validationErrors.confirmPassword = '';
							setFormData({ ...formData, confirmPassword: e.target.value });
							dispatchPasswordHints({
								type: 'confirmPassword',
								payload: e.target.value,
							});
							setHasChange(true);
						}}
						type={showConfirmPassword ? 'text' : 'password'}
						autoComplete="new-password"
						rightElement={
							<span
								className="show-password"
								onClick={() => setShowConfirmPassword(!showConfirmPassword)}
							>
								<FeaturedIcon icon={showConfirmPassword ? 'eye-open' : 'eye-off'} />
							</span>
						}
					/>
				</div>
				{formData.password !== '' && (
					<div>
						<div className="password-hints">
							<FeaturedIcon
								icon={passwordHints.eightCharacters ? 'tick' : 'full-circle'}
								size="sm"
							/>
							<label>Has at least 8 characters</label>
						</div>
						<div className="password-hints">
							<FeaturedIcon
								icon={passwordHints.oneCharacter ? 'tick' : 'full-circle'}
								size="sm"
							/>
							<label>Uses at least 1 special character</label>
						</div>
						<div className="password-hints">
							<FeaturedIcon
								icon={passwordHints.oneNumber ? 'tick' : 'full-circle'}
								size="sm"
							/>
							<label>Uses at least 1 number</label>
						</div>
					</div>
				)}
				{isEdit ? (
					<p>
						If no password is set, the user can create their own password during the
						login process.
					</p>
				) : (
					<p>
						A welcome email will be sent to this user upon adding. If no password is
						preset, the user can create their own password during the login process.
					</p>
				)}
			</div>
		</FormModal>
	);
};

NewUserResponderModal.propTypes = {
	closeModal: PropTypes.func,
	buildingId: PropTypes.number,
	addUser: PropTypes.func,
	isEdit: PropTypes.bool.isRequired,
	editUser: PropTypes.func,
	editPassword: PropTypes.func,
	user: PropTypes.object,
};
