import React, { useContext } from 'react';
import { Button, ActionsButton, Tooltip, TooltipPosition } from 'componentsV2';
import PropTypes from 'prop-types';
import '../css/BinderDetailsActions.scss';
import { BinderModalsContext } from '../../BinderModalsProvider';
import {
	HAS_OLD_SP_TP_CONTENT_EDIT_USER,
	HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER,
	HAS_OLD_SP_TP_COPY_CONTENT_EDIT_USER,
} from '../../consts.js';

export const BinderDetailsActions = ({ binder, viewMode }) => {
	if (!binder) return null;

	const { downloadBinder, showCopyModal, showArchiveModal, showDeleteModal } = useContext(
		BinderModalsContext,
	);

	const downloadButton = (
		<Button
			key="Print"
			text="Download"
			icon="download"
			size="lg"
			type="tertiary"
			className={`${binder.hasOldSP ? ' customDownloadDisabled' : ''}`}
			disabled={binder.hasOldSP}
			onClick={() => downloadBinder(binder)}
		/>
	);

	const copyBtn = (
		<Button
			key="Copy"
			text="Copy"
			icon="duplicate"
			size="lg"
			type="ghostDefault"
			intent="default"
			wrapperClass="displayBlock"
			className={`actionButtonElement ${binder.hasOldSP ? ' customCopyDisabled' : ''}`}
			disabled={binder.hasOldSP}
			onClick={() => {
				showCopyModal(binder);
			}}
		/>
	);

	return (
		<div className="binder-details-actions-container">
			{binder.hasEditAccess && binder.archivedByUser && (
				<Button
					key="Unarchive"
					text="Unarchive"
					size="lg"
					type="primaryDefault"
					onClick={() => showArchiveModal(binder)}
				/>
			)}
			{binder.archivedByUser === null &&
			binder.binderContents.length > 0 &&
			binder.hasOldSP ? (
				<Tooltip
					tooltipContent={
						<p className="sp_tp_text">
							{binder.hasEditAccess
								? HAS_OLD_SP_TP_CONTENT_EDIT_USER
								: HAS_OLD_SP_TP_CONTENT_VIEWONLY_USER}
						</p>
					}
					theme="light"
					position={TooltipPosition.LEFT}
				>
					{downloadButton}
				</Tooltip>
			) : (
				downloadButton
			)}

			{!viewMode && (
				<ActionsButton
					customClass="binderDetails_ActionsList"
					elementSelector={<Button type="tertiary" size="lg" icon="more" />}
				>
					{binder.archivedByUser === null && (
						<Button
							key="Archive"
							text="Archive"
							icon="archive"
							size="lg"
							type="ghostDefault"
							intent="default"
							wrapperClass="displayBlock"
							className="actionButtonElement"
							onClick={() => showArchiveModal(binder)}
						/>
					)}
					{binder.hasOldSP ? (
						<Tooltip
							className="binderDetailsPopover_wrapper"
							tooltipContent={
								<p className="sp_tp_text">{HAS_OLD_SP_TP_COPY_CONTENT_EDIT_USER}</p>
							}
							theme="light"
							position={TooltipPosition.LEFT}
						>
							{copyBtn}
						</Tooltip>
					) : (
						copyBtn
					)}
					<Button
						key="Delete"
						text="Delete"
						icon="trash"
						size="lg"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
						onClick={() => showDeleteModal(binder)}
					/>
				</ActionsButton>
			)}
		</div>
	);
};

BinderDetailsActions.propTypes = {
	binder: PropTypes.object,
	viewMode: PropTypes.bool,
};
