import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from 'componentsV2';
import {
	ALARM_STATUS,
	STEP_ONE,
	SELECTED_STATUS,
	DISABLED_STATUS,
	NONE_STATUS,
	STEP_THREE,
	ALARM_MODES,
} from '../../consts';
import { RapidAlarmService } from 'services';
import { SelectSiteHeader, SelectSiteList } from './';
import { ToasterContext } from 'pages/App';
import { ALARM_MODE } from '../../../../consts';
import { Intent } from '@blueprintjs/core';
import useSelectedSites from 'hooks/useSelectedSites';
import './css/StepTwoSelectSite.scss';

export const StepTwoSelectSite = ({
	formData,
	onChangeModalData,
	onChangeHandler,
	activetAlarm,
	hasCallout,
	mode,
}) => {
	const [sitesList, setSitesList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [buildingResponse, setBuildingResponse] = useState(null);
	const toaster = useContext(ToasterContext);
	const defaultSelectedSites = useSelectedSites();

	useEffect(() => {
		onChangeModalData({
			modalTitle: `Select sites to notify of "${formData.alarmType.title}" alarm`,
			showCancelAction: true,
			showActivateAlarmAction: true,
			secondBtnTitle: 'Go back',
			showHeader: true,
			onGoBackHandler: onGoBackHandler,	
			onActivateAlarmHandler: onActivateAlarmHandler,
		});
		getSitesList();
	}, []);

	useEffect(() => {
		// if i have only 1 site i should redirect to next step
		if (buildingResponse && buildingResponse.data.length === 1 && formData.selectedSites.length > 0) {
			onActivateAlarmHandler();
		}
		onChangeModalData({
			onActivateAlarmHandler: onActivateAlarmHandler,
		});
	}, [formData.selectedSites]);

	const onActivateAlarmHandler = () => {
		const selectedAlarm = formData.alarmType;
		if (selectedAlarm) {
			if (selectedAlarm.countDown) {
				onChangeModalData({
					activeStep: STEP_THREE,
					modalSubTitle: '',
				});
			} else {
				onChangeModalData({
					onActivateAlarmHandler: activetAlarm(selectedAlarm),
				});
			}
		}
	};

	const getSitesList = () => {
		RapidAlarmService.getAlarmSites()
			.then(response => {
				if (response.statusCode === 200) {
					const list = response.data.map(site => ({
						...site,
						status: getAlarmStatus(site),
					}));
					setBuildingResponse(response);

					setSitesList(list);
					if (formData.selectSitesFirstLoad) {
						const defaultPrimarySiteId = defaultSelectedSites[0]
							? defaultSelectedSites[0].value
							: null;
						const getPrimarySite = response.data.find(
							site => site.team_id == defaultPrimarySiteId,
						);

						// if primary site has no active alarm with emergency mode
						if (getPrimarySite && !hasEmergencyAlarm(getPrimarySite)) {
							const mappedSelectedSites = defaultSelectedSites.map(
								defaultSelectedSite => ({
									id: defaultSelectedSite.value,
									name: defaultSelectedSite.label,
								}),
							);
							onChangeHandler({
								selectedSites: mappedSelectedSites,
							});
							onChangeModalData({
								activateAlarmDisabled: false,
							});
						}
						onChangeHandler({
							selectSitesFirstLoad: false,
						});
					}
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		updateSitesList();
	}, [formData]);

	const updateSitesList = () => {
		setSitesList(oldList =>
			oldList.map(site => ({
				...site,
				status: getAlarmStatus(site),
			})),
		);
		onChangeModalData({
			activateAlarmDisabled: formData.selectedSites.length === 0,
		});
	};

	const hasEmergencyAlarm = site => {
		if (mode == ALARM_MODES.drill || mode == ALARM_MODES.training) {
			//Practice
			return (
				site.id &&
				(site.mode === ALARM_MODE ||
					site.mode === ALARM_MODES.drill?.toLowerCase() ||
					ALARM_MODES.training?.toLowerCase())
			);
		}
		return site.id && site.mode === ALARM_MODE;
	};

	const getAlarmStatus = site => {
		// if site has active alarm & alarm mode is emergency mode so it should be disabled
		if (hasEmergencyAlarm(site)) {
			return ALARM_STATUS.DISABLED_STATUS;
		}
		// check whether site is selected or not
		return formData.selectedSites.some(selectedSite => selectedSite.id === site.team_id)
			? ALARM_STATUS.SELECTED_STATUS
			: ALARM_STATUS.NONE_STATUS;
	};

	const onGoBackHandler = () => {
		onChangeModalData({
			activeStep: STEP_ONE,
		});
	};

	const onSelectAllHandler = selectAll => {
		if (selectAll) {
			const formDataSites = sitesList
				.filter(site => site.status !== DISABLED_STATUS)
				.map(site => ({
					id: site.team_id,
					name: site.team_name,
				}));
			onChangeHandler({
				selectedSites: formDataSites,
			});
		} else {
			onChangeHandler({
				selectedSites: [],
			});
		}
	};

	const onSelectSiteHandler = site => {
		if (site.status === DISABLED_STATUS) {
			return;
		}

		// update formData
		const selectedSites = formData.selectedSites;
		const selectedIndex = selectedSites.findIndex(
			selectedSite => selectedSite.id === site.team_id,
		);

		// it was select then unselect it
		if (selectedIndex > -1) {
			selectedSites.splice(selectedIndex, 1);
		} else {
			// add it to selected array
			selectedSites.push({
				id: site.team_id,
				name: site.team_name,
			});
		}
		onChangeHandler({ selectedSites });
	};

	if (loading) return <LoadingSpinner />;
	return (
		<div className={`${hasCallout ? ' hasCalloutWrapper' : 'w-100'}`}>
			<div className="activate-alarm-modal-select-site">
				<SelectSiteHeader sitesList={sitesList} onSelectAll={onSelectAllHandler} />
				<SelectSiteList sitesList={sitesList} onSelect={onSelectSiteHandler} mode={mode} />
			</div>
		</div>
	);
};
