export const drillsSettingsCards = [
	{
		icon: 'office',
		title: 'District drill requirements',
		subTitle:
			'Set which drills and how many of each you would like to require for your district.',
		linkText: 'Manage',
		linkUri: '/drillsV2/settings/manageDistrictDrillRequirements',
	},
	// {
	// 	icon: 'eye-open',
	// 	title: 'Public viewable',
	// 	subTitle:
	// 		'Choose what drills are publicly viewable and create a link to place on your schools website.',
	// 	linkText: 'Edit',
	// },
	{
		icon: 'user',
		title: 'First responder email notifications',
		subTitle:
			'Choose who is notified when drills are re-scheduled, scheduled, and/or completed.',
		linkText: 'Edit',
		linkUri: '/drillsV2/settings/manageFirstResponderNotifications',
	},
	// {
	// 	icon: 'envelope',
	// 	title: 'Office email recipients',
	// 	subTitle:
	// 		'Recipients on this list will receive messages via email from anyone who submits a question when filling out a drill report.',
	// 	linkText: 'Edit',
	// },
	{
		icon: 'document',
		title: 'Drill reports',
		subTitle:
			'Customize Exportable drill form fields and create reports for your organization.',
		linkText: 'Download',
	},
];

export const breadcrumbItems = [
	{
		label: 'Drills',
		url: '/drillsV2',
	},
	{
		label: 'Settings',
	},
];
