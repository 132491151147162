import React from 'react';
import { FormModal, RadioButtonGroup } from 'componentsV2';
import { Radio } from '@blueprintjs/core';

export const DownloadModal = ({
	modalToShow,
	loading,
	documentData,
	closeModal,
	selectedDownloadOption,
	handleDownloadOption,
}) => {
	return (
		<FormModal
			isOpen={modalToShow === 'downloadDocumentModal'}
			onClose={closeModal}
			title={documentData.modalTitle}
			showCloseButton={true}
			confirmBtn={{
				title: 'Download',
				onClick: documentData.onConfirmAction,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: closeModal,
			}}
			loading={loading}
			scrollContent={false}
			size="small"
		>
			<RadioButtonGroup
				label={null}
				onChange={handleDownloadOption}
				selectedValue={selectedDownloadOption}
				size="small"
				bordered={false}
				required={true}
				layout="block"
				fullwidth={true}
			>
				<Radio label="Source file extension" value="1" />
				<Radio label="PDF" value="2" />
			</RadioButtonGroup>
		</FormModal>
	);
};
