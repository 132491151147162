import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Radio, RadioGroup } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';

import { FormLabel } from 'components/Forms/shared.js';
import { DateTimePicker } from 'components/DateTimePicker';
import { CardModal } from 'components/Modals/CardModal';
import { addMonthsToDate } from 'utilities/dates';

const CardModalStyled = styled(CardModal)`
	.modal-card {
		.modal-input-label {
			color: #10161a;
			font-size: 16px;
			margin-top: 16px;
			line-height: 22px;
			font-style: normal;
			font-weight: normal;
			font-family: Nunito Sans;
		}

		.modal-input {
			width: 350px;
			height: 42px;
			font-size: 16px;
			line-height: 22px;
			font-style: normal;
			padding-left: 10px;
			border-radius: 3px;
			font-weight: normal;
			margin-bottom: 16px;
			font-family: Nunito Sans;
			border: 1px solid #d8e1e8;
		}

		.modal-check-box-wrapper {
			margin-bottom: 15px;
			div {
				margin-right: 40px;

				label {
					text-transform: capitalize;
					margin: 0 0 3.5px 5px;
				}
			}
		}
	}
`;

const CheckboxesContainer = styled.div`
	.checkbox-row {
		display: flex;
		flex: 1;
		flex-direction: row;
	}

	.checkbox-group-container {
		display: flex;
		flex: 1;
		flex-direction: row;

		p {
			margin-left: 8px;
		}
	}

	&&& .bp3-control input:checked ~ .bp3-control-indicator {
		background-color: white;
	}
`;

const MoreFiltersModal = ({
	isVisible,
	setIsVisible,
	actualFilter,
	onApply,
}) => {
	const [filter, setFilter] = useState(actualFilter);

	// Helper function to edit the filter
	const editFilter = (fieldName, newValue) => {
		setFilter(prevState => ({
			...prevState,
			[fieldName]: newValue,
		}));
	};

	// Helper function to edit the filter
	const switchStatusValue = fieldName => {
		setFilter(prevState => ({
			...prevState,
			[fieldName]: !prevState[fieldName],
		}));
	};

	return (
		<CardModalStyled
			isVisible={isVisible}
			setIsVisible={setIsVisible}
			title="More filters"
			actionTitle="Apply"
			onActionClick={() => onApply && onApply(filter)}
		>
			<div className="form-group">
				{/* Actual drill date group */}
				<FormLabel>Actual drill date</FormLabel>
				<DateTimePicker
					initialValue={filter?.actualDrillDateFrom}
					onDateChangeHandler={date =>
						editFilter('actualDrillDateFrom', date)
					}
					timePrecision="minute"
					datePickerProps={{
						maxDate: addMonthsToDate(),
					}}
				/>
				<DateTimePicker
					initialValue={filter?.actualDrillDateTo}
					onDateChangeHandler={date =>
						editFilter('actualDrillDateTo', date)
					}
					timePrecision="minute"
					datePickerProps={{
						maxDate: addMonthsToDate(),
					}}
				/>
			</div>
			<div className="form-group">
				{/* Scheduled drill date group */}
				<FormLabel>Scheduled drill date</FormLabel>
				<DateTimePicker
					initialValue={filter?.scheduledDrillDateFrom}
					onDateChangeHandler={date =>
						editFilter('scheduledDrillDateFrom', date)
					}
					timePrecision="minute"
					datePickerProps={{
						maxDate: addMonthsToDate(),
					}}
				/>
				<DateTimePicker
					initialValue={filter?.scheduledDrillDateTo}
					onDateChangeHandler={date =>
						editFilter('scheduledDrillDateTo', date)
					}
					timePrecision="minute"
					datePickerProps={{
						maxDate: addMonthsToDate(),
					}}
				/>
			</div>
			<div className="form-group">
				{/* Date posted group */}
				<FormLabel>Date posted</FormLabel>
				<DateTimePicker
					initialValue={filter?.datePostedFrom}
					onDateChangeHandler={date =>
						editFilter('datePostedFrom', date)
					}
					timePrecision="minute"
					datePickerProps={{
						maxDate: addMonthsToDate(),
					}}
				/>
				<DateTimePicker
					initialValue={filter?.datePostedTo}
					onDateChangeHandler={date =>
						editFilter('datePostedTo', date)
					}
					timePrecision="minute"
					datePickerProps={{
						maxDate: addMonthsToDate(),
					}}
				/>
			</div>
			<div className="form-group">
				{/* Log posted */}
				<RadioGroup
					label="Log is posted"
					onChange={({ target }) =>
						editFilter('logPosted', target.value)
					}
					selectedValue={filter?.logPosted}
				>
					<Radio label="All" value="all" />
					<Radio label="Yes" value="yes" />
					<Radio label="No" value="no" />
				</RadioGroup>
			</div>
			<CheckboxesContainer>
				<FormLabel>Status</FormLabel>
				<div className="checkbox-row">
					<div className="checkbox-group-container">
						<label>
							<input
								style={{
									marginRight: 5,
									height: 15,
									width: 15,
								}}
								type="checkbox"
								checked={filter?.statusCompleted}
								onChange={() =>
									switchStatusValue('statusCompleted')
								}
							/>
							Completed
						</label>
					</div>
					<div className="checkbox-group-container">
						<label>
							<input
								style={{
									marginRight: 5,
									height: 15,
									width: 15,
								}}
								type="checkbox"
								checked={filter?.statusInProgress}
								onChange={() =>
									switchStatusValue('statusInProgress')
								}
							/>
							In progress
						</label>
					</div>
				</div>
				<div className="checkbox-row">
					<div className="checkbox-group-container">
						<label>
							<input
								style={{
									marginRight: 5,
									height: 15,
									width: 15,
								}}
								type="checkbox"
								checked={filter?.statusScheduled}
								onChange={() =>
									switchStatusValue('statusScheduled')
								}
							/>
							Scheduled
						</label>
					</div>
					<div className="checkbox-group-container">
						<label>
							<input
								style={{
									marginRight: 5,
									height: 15,
									width: 15,
								}}
								type="checkbox"
								checked={filter?.statusOverdue}
								onChange={() =>
									switchStatusValue('statusOverdue')
								}
							/>
							Overdue
						</label>
					</div>
				</div>
				<div className="checkbox-row">
					<div className="checkbox-group-container">
						<label>
							<input
								style={{
									marginRight: 5,
									height: 15,
									width: 15,
								}}
								type="checkbox"
								checked={filter?.statusNotScheduled}
								onChange={() =>
									switchStatusValue('statusNotScheduled')
								}
							/>
							Not scheduled
						</label>
					</div>
				</div>
			</CheckboxesContainer>
		</CardModalStyled>
	);
};

MoreFiltersModal.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	setIsVisible: PropTypes.func.isRequired,
	actualFilter: PropTypes.object.isRequired,
	onApply: PropTypes.func.isRequired,
};

export { MoreFiltersModal };
