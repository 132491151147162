import React, { useContext } from 'react';
import { PageHeader, Button } from 'componentsV2';
import { CHECKIN_STEPS } from './Reunification/Guardians/GuardiansCheckin/consts';
import { REUNIFICATION_STUDENTS_LIST, REUNIFICATION_GUARDIANS_LIST } from './Reunification/consts';
import { REUNIFICATION_TAB } from './consts';
import { RapidAlarmsContext } from '../../Providers';
import PropTypes from 'prop-types';

export const Header = ({ alarmId, reunificationActiveTab, activeTabId, onChangeModalToShow }) => {
	const { openedFromHomePage, onAddStudentToReunification } = useContext(RapidAlarmsContext);
	let breadcrumbItems = [
		{
			label: 'Alarms',
			url: '/rapid-alarms',
		},
		{
			label: 'Alarm details',
			url: `/active-alarms/${alarmId}`,
		},
		{
			label: 'Roll Call & Reunification',
		},
	];

	if (openedFromHomePage) {
		breadcrumbItems = [
			{
				label: 'Home',
				url: '/',
			},
			{
				label: 'Alarm details',
				url: {
					pathname: `/active-alarms/${alarmId}`,
					state: { openedFromHomePage },
				},
			},
			{
				label: 'Roll Call & Reunification',
			},
		];
	}

	const customItem = () => {
		return (
			<>
				{reunificationActiveTab === REUNIFICATION_STUDENTS_LIST &&
					activeTabId === REUNIFICATION_TAB && (
						<Button
							text="Add student to Reunification"
							size="md"
							onClick={() => {
								onAddStudentToReunification(alarmId);
							}}
						/>
					)}
				{reunificationActiveTab === REUNIFICATION_GUARDIANS_LIST &&
					activeTabId === REUNIFICATION_TAB && (
						<Button
							size="md"
							text={'Guardian check-in'}
							intent="default"
							onClick={() => onChangeModalToShow(CHECKIN_STEPS.GUARDIANS_LIST_STEP)}
							className="CrumbButtonActions"
						/>
					)}
			</>
		);
	};

	return (
		<PageHeader
			title="Roll Call & Reunification"
			subtitle={
				<>
					Roll Call assists in tracking and marking the attendance of individuals,
					ensuring that everyone's presence is recorded accurately.
					<br /> Reunification assists with the tracking of students as they reunite with
					their guardians.
				</>
			}
			breadcrumbItems={breadcrumbItems}
			customItem={customItem}
		/>
	);
};

Header.propTypes = {
	alarmId: PropTypes.number.isRequired,
	reunificationActiveTab: PropTypes.string,
	activeTabId: PropTypes.string,
};
