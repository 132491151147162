import React from 'react';
import { fileIconsList } from 'componentsV2';
import { ShapeContentItem, NoShapeContentState } from './';
import '../css/ShapeContent.scss';

export const ImagesContent = ({
	images,
	contentType,
	shapeType,
	shapeId,
	siteId,
	editPermission,
	isEditMode,
	floorplanArchived,
	isSearchedContent,
	loadContentData,
	viewPermissionOnSiteId,
	floorplan,
}) => {
	return (
		<div className="area-content-items-container">
			{images.length > 0 ? (
				images.map(image => (
					<ShapeContentItem
						item={image}
						id={image.uniqId}
						ext={image.ext}
						thumbnail={image.thumbnail}
						contentTitle={image.contentTitle}
						createdBy={image.createdBy}
						siteId={siteId}
						shapeId={shapeId}
						shapeType={shapeType}
						editPermission={editPermission}
						floorplanArchived={floorplanArchived}
						createdDate={image.createdDate}
						contentType={contentType}
						loadContentData={loadContentData}
						isEditMode={isEditMode}
						viewPermissionOnSiteId={viewPermissionOnSiteId}
						floorplan={floorplan}
					/>
				))
			) : (
				<NoShapeContentState
					icon={fileIconsList['map']}
					header={isSearchedContent ? 'No content found' : 'No images currently added.'}
					description={
						isSearchedContent
							? `Your search didn’t match any ${contentType}s. Please try again.`
							: editPermission && !floorplanArchived && isEditMode
							? 'Click the + button to add an image.'
							: ''
					}
				/>
			)}
		</div>
	);
};
