export const rollCallValidation = formData => {
	let isValid = true;
	const errors = {};
	if (formData.hasRollCall === null) {
		isValid = false;
		errors.hasRollCall = 'Roll Call is required';
	}
	if (formData.markAllAccounted === null) {
		isValid = false;
		errors.markAllAccounted = 'Mark all as acounted is required';
	}

	return {
		isValid,
		errors,
	};
};
