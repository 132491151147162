import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmationModal } from 'componentsV2';

export const ConnectionModal = ({ isOpen, onCloseModal, checked, onClickConnection }) => {
	const onHandleConnection = () => {
		return onClickConnection();
	};

	return (
		<ConfirmationModal
			isOpen={isOpen}
			title={checked ? 'Enable connection' : 'Disable connection'}
			subtitle={
				checked
					? 'Enabling will initiate your 911Cellular integration. Are you sure you want to enable this integration connection?'
					: 'Mapping has already been defined for 911Cellular. Are you sure you want to disable this integration connection?'
			}
			showCloseButton={false}
			icon={{
				icon: checked ? 'power' : 'warning-sign',
				iconColor: checked ? 'primary' : 'warning',
			}}
			onClose={onCloseModal}
			cancelBtn={{
				title: 'Cancel',
				onClick: onCloseModal,
			}}
			confirmBtn={{
				title: checked ? 'Enable connection' : 'Disable connection',
				onClick: onHandleConnection,
				type: 'primaryDefault',
			}}
		/>
	);
};

ConnectionModal.propTypes = {
	isOpen: PropTypes.any,
	onClickConnection: PropTypes.func,
	onCloseModal: PropTypes.any,
	titleData: PropTypes.any,
};
