import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { PlanService, BuildingsService, TemplateService } from 'services';
import { FormModal, Intent, FeaturedIcon, Dropdown, Input } from 'componentsV2';
import { filterSitesPerPermissionCode } from 'utilities/permissions';
import { ToasterContext } from 'pages/App';
import { DISTRICT_LIST_PATH } from '../consts';
export const CreateModal = ({ showModal, setShowModal, selectedSites = [], history }) => {
	const permissions = useSelector(state => state.route.permissions);

	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [sites, setSites] = useState([]);
	const [templates, setTemplates] = useState([]);
	const [formData, setFormData] = useState({
		site: '',
		template: '',
		title: '',
	});

	useEffect(() => {
		let disabled = true;
		if (
			formData.site &&
			formData.template &&
			formData.title &&
			formData.title.trim().length > 0
		) {
			disabled = false;
		}
		setIsDisabled(disabled);
	}, [formData]);

	useEffect(() => {
		getSites();
		getTemplates();
	}, []);

	const getSites = () => {
		setLoading(true);
		BuildingsService.getAll(false, false)
			.then(serviceBuildings => {
				let sites = filterSitesPerPermissionCode(
					'safety_plans_edit',
					permissions,
					serviceBuildings,
				);

				const enabledSites = sites.filter(site => site.safetyPlanEnabled == true);

				setSites(enabledSites);
				setDefaultSelectedSite(enabledSites);
			})
			.then(() => {
				setLoading(false);
			});
	};

	const setDefaultSelectedSite = sites => {
		if (selectedSites && selectedSites.length == 1) {
			let selectedSite = sites.find(site => site.id === selectedSites[0]);

			if (selectedSite) {
				handleChangeSite(selectedSite);
				return;
			}
		}

		if (selectedSites && selectedSites.length > 1) {
			let loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
			let primarySite = sites.find(site => site.id === loggedUser.primaryBuilding.id);

			if (primarySite) {
				handleChangeSite(primarySite);
				return;
			}
		}
	};

	const getTemplates = () => {
		setLoading(true);
		TemplateService.ListTemplates({ includeContent: false, archived: false })
			.then(response => {
				if (response.statusCode === 200) {
					setTemplates(response.data.templates);
				}
			})
			.catch(error => {
				console.error('Error fetching Templates list:', error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleSubmit = () => {
		const data = {
			buildingId: formData.site.id,
			coreId: formData.template.id,
			title: formData.title,
		};

		setLoading(true);
		PlanService.add(data)
			.then(resp => {
				if (resp.statusCode === 201) {
					toaster(
						`You have successfully added the "${formData.title}" as a new safety plan`,
						Intent.SUCCESS,
						<FeaturedIcon
							icon={'tick'}
							type={'Success'}
							size={'md'}
							shape={'circleIcon'}
						/>,
					);
					handleCloseModal();
					history.push(`${DISTRICT_LIST_PATH}/${resp.data.id}`);
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChangeSite = option => {
		setFormData({
			...formData,
			site: option,
		});
	};

	const canUpdateTitle = () => {
		let result = false;
		if (formData.title.trim().length == 0 || formData.title == formData.template.name) {
			result = true;
		}
		return result;
	};

	const handleChangeTemplate = option => {
		setFormData({
			...formData,
			template: option,
			title: canUpdateTitle() ? option.name : formData.title,
		});
	};

	const handleChangeTitle = ({ target }) => {
		setFormData({
			...formData,
			title: target.value,
		});
	};

	return (
		<FormModal
			isOpen={showModal}
			onClose={() => handleCloseModal()}
			title="Create a new safety plan"
			loading={loading}
			confirmBtn={{
				title: 'Save',
				onClick: handleSubmit,
				disabled: isDisabled,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: handleCloseModal,
			}}
			scrollContent={false}
		>
			<div className="w-100">
				<Dropdown
					label="Select site"
					placeholder="Select a site"
					value={formData.site}
					options={sites}
					onChange={handleChangeSite}
					isRequired={true}
				/>
			</div>

			<div className="w-100 mt-3">
				<Dropdown
					label="Select template"
					placeholder="Select a template"
					value={formData.template}
					options={templates}
					optionLabel="name"
					optionValue="id"
					onChange={handleChangeTemplate}
					isRequired={true}
				/>
			</div>

			<div className="w-100 mt-3">
				<Input
					label="Plan title"
					isRequired={true}
					width="100%"
					value={formData.title}
					onChange={handleChangeTitle}
					placeholder="Plan name"
				/>
			</div>
		</FormModal>
	);
};
