import React from 'react';
import PropTypes from 'prop-types';
import { Callout as BPCallOut } from '@blueprintjs/core';
import './css/Callout.scss';

export const Callout = ({ intent = 'success', icon = icon, text, className = '' }) => (
	<div className="Callout_Wrapper">
		<BPCallOut className={className} intent={intent} icon={icon}>
			<span className="bb3-content-text">{text}</span>
		</BPCallOut>
	</div>
);
