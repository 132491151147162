import React from 'react';
import { Badge } from 'componentsV2';

export const ChatRoomItem = ({ onClick, title, description, notifications }) => {
	return (
		<div onClick={onClick} className="chat-room-item">
			<div className="chat-room-item-info">
				<div className="chat-room-item-title">{title}</div>
				<div className="chat-room-item-description">{description}</div>
			</div>
			{notifications > 0 && <Badge type="Primary" size="sm" text={notifications} />}
		</div>
	);
};
