import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Button } from "componentsV2";
import { AddUpdateBinderModal } from "../../..";

export const EditBinder = ({
    binder,
    onSubmitFinished,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return (
        <>
            {modalIsOpen && (
                <AddUpdateBinderModal
                    binder={binder}
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    onSubmitFinished={onSubmitFinished}
                />
            )}

            <Button
                size="md"
                text='Edit'
                type="ghostPrimary"
                intent="default"
                onClick={() => setModalIsOpen(true)}
            />
        </>
    );
}

EditBinder.propTypes = {
    onSubmitFinished: PropTypes.func.isRequired,
    selectedSites: PropTypes.array,
};
