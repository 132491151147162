import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PanelCard } from '../shared/PanelCard';

const Page = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const Admin = ({ history }) => {
	const TABS = React.useMemo(
		() => [
			{
				title: 'Manage State Requirements',
				description:
					'Manage state drill log requirements and guidance notes for a school year. Provide instructions on a per-state and per-drill type basis.',
				path: '/manageStateRequirements',
			},
			{
				title: 'Manage Drill Log Fields',
				description:
					'Add custom fields to drills and allow organizations to record custom data',
				path: '/manageDrillLogFields',
			},
			{
				title: 'Manage Drill Types',
				description: 'Provide unique drill types to an organization',
				path: '/manageDrillTypes',
			},
			{
				title: 'Manage Drill Instructions',
				description:
					'Provide instructions on a per-state and per-drill type basis',
				path: '/manageDrillInstructions',
			},
			{
				title: 'New Report Page',
				description:
					'Customize  Exportable  drill  form  fields  and  create  reports  for  organization',
				path: '/newReportPage',
			},
		],
		[],
	);

	const navigateToSubPage = path => {
		history.push(path);
	};

	return (
		<Page>
			{TABS.map(item => (
				<PanelCard
					key={item.title}
					title={item.title}
					description={item.description}
					onClick={() => navigateToSubPage(item.path)}
				/>
			))}
		</Page>
	);
};

export { Admin };

Admin.propTypes = {
	history: PropTypes.object,
};
