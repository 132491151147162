import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'componentsV2';
import { FlipchartService } from 'services';

export const FlipchartDropDown = ({
	formData,
	formErrors,
	onChangeFormData,
	disabled = false,
	...restProps
}) => {
	const [flipchartList, setFlipchartList] = useState([]);
	const [loading, setLoading] = useState(!formData.flipchartValue);

	useEffect(() => {
		let flipchartListData = [];

		FlipchartService.getAll({ visibleToAllOnly: 1 }).then(resp => {
			if (resp.statusCode === 200) {
				flipchartListData.push({
					label: 'None',
					value: -1,
				});
				resp.data.forEach(flipchart => {
					flipchartListData.push({
						label: flipchart.title,
						value: flipchart.id,
					});
				});
				setFlipchartList(flipchartListData);
				setLoading(false);
			}
		});
	}, []);

	const onSelectFlipchartHandler = value => {
		onChangeFormData({ type: 'flipchartValue', payload: value });
	};

	return (
		<Dropdown
			labelState={true}
			placeholder="Select flipchart"
			isRequired={true}
			label="Select flipchart"
			options={flipchartList}
			value={formData.flipchartValue}
			onChange={onSelectFlipchartHandler}
			error={formErrors?.flipchartValue ? true : false}
			errorMessage={formErrors?.flipchartValue ? formErrors.flipchartValue : null}
			loading={loading}
			disabled={disabled}
			{...restProps}
		/>
	);
};

FlipchartDropDown.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
	disabled: PropTypes.bool,
};
