import React, { useEffect, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { Animated, Badge } from 'componentsV2';

export const ChatRoom = ({
	title,
	notifications = 0,
	optionToClose = true,
	startMinimized = false,
	showChatRoom = true,
	setShowChatRoom = () => {},
	expand = false,
	setExpand = () => {},
	children,
}) => {
	const [minimized, setMinimized] = useState(startMinimized);

	const toggleMinimizeAction = () => {
		setMinimized(value => !value);
		setExpand(minimized);
	};

	useEffect(() => {
		if (showChatRoom && !startMinimized) {
			setMinimized(false);
		}
	}, [showChatRoom]);

	useEffect(() => {
		if (expand && !startMinimized) {
			setMinimized(!expand);
		}
	}, [expand]);

	return (
		showChatRoom && (
			<Animated>
				<div className={`chatroom-container ${minimized ? 'minimized' : ''}`}>
					<div onClick={toggleMinimizeAction} className="chatroom-header">
						<div className="chatroom-title">
							{title}
							{notifications > 0 && (
								<Badge type="Primary" size="sm" text={notifications} />
							)}
						</div>
						<div className="chatroom-actions">
							{optionToClose && (
								<a onClick={() => setShowChatRoom(false)}>
									<Icon icon="cross" />
								</a>
							)}
							<a>
								{minimized ? (
									<Icon icon="arrow-top-right" />
								) : (
									<Icon icon="arrow-bottom-right" />
								)}
							</a>
						</div>
					</div>
					<div className={`chatroom-body ${minimized ? 'slide-down' : 'slide-up'} `}>
						<div className="chatroom-body-content">{children}</div>
					</div>
				</div>
			</Animated>
		)
	);
};
