import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Dialog, Radio, RadioGroup } from '@blueprintjs/core';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
	height: 30px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	color: #10161a;
	margin-top: 15px;
`;

const FilterLabel = styled.label`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	margin-left: 10px;
`;

const FilterModal = ({ isOpen, closeAttachModal, handleFilterValues, filters }) => {
	let initialFilters = {}
	if (Object.keys(filters).length !== 0) {
		initialFilters = filters
	} else {
		initialFilters = {
			answer: 'all',
			rating: 'all',
			filesCount: 'all'
		}
	}
	const [filter, setFilter] = useState(initialFilters);

	const handleFilterClick = () => {
		handleFilterValues(filter);
		closeAttachModal();
	};

	const editFilter = (fieldName, newValue) => {
		setFilter(prevState => ({
			...prevState,
			[fieldName]: newValue,
		}));
	};
	const questionsArray = [
		{
			label: 'All',
			value: 'all',
		},
		{
			label: 'Answered questions',
			value: 'answeredQuestions',
		},
		{
			label: 'Unanswered questions',
			value: 'unAnsweredQuestions',
		},
	];
	const likedUnlikedArray = [
		{
			label: 'All',
			value: 'all',
		},
		{
			label: 'Liked questions',
			value: 'likedQuestions',
		},
		{
			label: 'Disliked questions',
			value: 'unlikedQuestions',
		},
	];
	const attachCommentsArray = [
		{
			label: 'All',
			value: 'all',
		},
		{
			label: 'Attachments Included',
			value: 'attachmentsIncluded',
		},
		{
			label: 'Comments Included',
			value: 'commentsIncluded',
		},
	];
	return (
		<Dialog isOpen={isOpen} onClose={closeAttachModal}>
			<div>
				<TitleWrap>
					<Title>Filter Questions</Title>
					<Button
						text=""
						minimal
						icon="cross"
						onClick={closeAttachModal}
					/>
				</TitleWrap>
				<hr />
				<FilterLabel>Show answered or unanswered questions</FilterLabel>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginLeft: '10px',
					}}
				>
					<RadioGroup
						onChange={e => editFilter('answer', e.currentTarget.value)}
						selectedValue={filter.answer}
					>
					{questionsArray.map(item => (
						<Radio
							style={{ fontFamily: 'Nunito Sans' }}
							label={item.label}
							value={item.value}
						/>
					))}
					</RadioGroup>
				</div>

				<FilterLabel style={{ marginTop: '8px' }}>
					Show liked or disliked questions
				</FilterLabel>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginLeft: '10px',
					}}
				>
					<RadioGroup
						onChange={e => editFilter('rating', e.currentTarget.value)}
						selectedValue={filter.rating}
					>
					{likedUnlikedArray.map(item => (
						<Radio
							style={{ fontFamily: 'Nunito Sans' }}
							label={item.label}
							value={item.value}
						/>
					))}
					</RadioGroup>
				</div>

				<FilterLabel style={{ marginTop: '8px' }}>
					Has attachments or comments included
				</FilterLabel>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginLeft: '10px',
					}}
				>
					<RadioGroup
						onChange={e => editFilter('filesCount', e.currentTarget.value)}
						selectedValue={filter.filesCount}
					>
					{attachCommentsArray.map(item => (
						<Radio
							style={{ fontFamily: 'Nunito Sans' }}
							label={item.label}
							value={item.value}
						/>
					))}
					</RadioGroup>
				</div>

				<hr />
				<div className="d-flex justify-content-end mt-2 px-2">
					<Button
						text="Cancel"
						large
						intent="secondary"
						onClick={closeAttachModal}
					/>
					<Button
						className="ml-2"
						text="Apply"
						large
						intent="primary"
						onClick={handleFilterClick}
					/>
				</div>
			</div>
		</Dialog>
	);
};

FilterModal.propTypes = {
	isOpen: PropTypes.bool,
	closeAttachModal: PropTypes.func,
	handleFilterValues: PropTypes.func,
};

export default FilterModal;
