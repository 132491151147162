import React, { useState } from 'react';
import { USER_ACCESS_TYPES } from 'utilities/constants';

export const RolesColumn = ({ row }) => {
	if (row.accessType === USER_ACCESS_TYPES.OUTSIDE_USER) {
		return 'External';
	} else {
		return 'Internal';
	}
};
