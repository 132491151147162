import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';

const Wrapper = styled.div`
	height: 100vh;
	box-shadow: inset 1px 0px 0px #d0d9e0;
	width: ${props => (props.expand ? '300px' : '55px')};
	position: fixed;
	right: 0;
	transition: all 0.3s ease;
	padding: 15px 0 0 20px;
	background-color: #f5f8fa;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	margin-bottom: 0;
	color: #000;
	opacity: ${props => (props.expand ? '1' : '0')};
	transition: all 0.3s ease;
`;

// const ListHeading = styled.p`
// 	position: static;
// 	width: 244px;
// 	height: 20px;
// 	left: 0px;
// 	top: 0px;
// 	font-family: Nunito Sans;
// 	font-style: normal;
// 	font-weight: bold;
// 	font-size: 14px;
// 	line-height: 20px;
// 	/* identical to box height, or 143% */
// 	display: flex;
// 	align-items: center;
// `;

const ToggleButton = styled.span`
	font-family: Open Sans;
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 25px;
	color: #5c7080;
`;

const ItemTitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #d9822b;
	opacity: ${props => (props.expand ? '1' : '0')};
`;

class Sidebar extends React.Component {
	state = {};

	scrollTitle = (e, id) => {
		e.preventDefault();
		const el = document.getElementById(`section-${id}`);
		el.scrollIntoView({ behavior: 'smooth' });
	};

	render() {
		const { selectedSection, expand, setExpand } = this.props;
		return (
			<Wrapper expand={expand}>
				<div className="d-flex align-items-start mt-2">
					<ToggleButton
						onClick={() => setExpand(!expand)}
						secondary="true"
						className="mr-2"
					>
						{expand ? (
							<Icon
								style={{
									color: '#5c7080',
								}}
								icon="menu-open"
							/>
						) : (
							<Icon
								style={{
									color: '#5c7080',
								}}
								icon="menu-closed"
							/>
						)}
					</ToggleButton>
					<Title style={{ marginTop: 5 }} expand={expand}>
						Section outline
					</Title>
				</div>
				<div className="mt-3">
					{selectedSection.sections.map(item => (
						<React.Fragment key={`title-${item.id}`}>
							<ItemTitle expand={expand}>{item.name}</ItemTitle>
							{item.sections?.map(one => (
								<ItemTitle
									expand={expand}
									className="ml-3"
									key={`subtitle-${item.id}`}
								>
									{one.name}
								</ItemTitle>
							))}
						</React.Fragment>
					))}
				</div>
			</Wrapper>
		);
	}
}

Sidebar.propTypes = {
	selectedSection: PropTypes.object,
	expand: PropTypes.bool,
	setExpand: PropTypes.func,
};

export default Sidebar;
