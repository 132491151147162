import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Popover, Position, Icon } from '@blueprintjs/core';
import Swal from 'sweetalert2';
import AddQuestionModal from './AddQuestionModal';
import EditQuestionModal from './EditQuestionModal';
import AddSubsectionModal from './addsubsectionmodal';

const Wrapper = styled.div`
	margin-top: 24px;
`;

const Title = styled.h2`
	font-family: Merriweather;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 32px;
	color: #10161a;
	margin-bottom: 0;
`;

const Directions = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #10161a;
`;

const QuestionWrap = styled.div`
	border: 1px solid #d8e1e8;
	padding: 24px;
	margin-bottom: 12px;
`;

const QuestionText = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	color: #10161a;
	margin-bottom: 0;
`;

const QuestionTextWrap = styled.div`
	margin-top: -2px;
	margin-left: 12px;
`;

class Subsection extends React.Component {
	state = {
		showMenu: false,
		showAddQuestion: false,
		showQuestionMenu: false,
		showEditQuestion: false,
		editQuestion: null,
		showQuestionId: null,
	};

	closeAddQuestion = () => this.setState({ showAddQuestion: false });

	closeEditQuestion = () =>
		this.setState({ showEditQuestion: false, editQuestion: null });

	deleteSection = () => {
		Swal.fire({
			title: 'Are you sure you want to delete the section?',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Delete',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				const { templateId, item, getData } = this.props;
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				return fetch(
					`${process.env.API_URL}/templates/${templateId}/sections/${
						item.id
					}`,
					{
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
					.then(res => res.json())
					.then(resp => {
						if (resp.statusCode === 200) {
							return getData();
						}
						return null;
					});
			}
			return null;
		});
	};

	deleteQuestion = item => {
		Swal.fire({
			title: 'Are you sure you want to delete this question?',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Delete',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				const { templateId, getData } = this.props;
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				return fetch(
					`${process.env.API_URL}/templates/${templateId}/questions/${
						item.id
					}`,
					{
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
					.then(res => res.json())
					.then(resp => {
						if (resp.statusCode === 200) {
							return getData();
						}
						return null;
					});
			}
			return null;
		});
	};

	closeAddSubsection = () => this.setState({ showAddSubsection: false });

	render() {
		const { item, openCopy, openEdit, getData, templateId } = this.props;
		const {
			showMenu,
			showAddQuestion,
			showQuestionMenu,
			showEditQuestion,
			editQuestion,
			showAddSubsection,
			showQuestionId,
		} = this.state;
		return (
			<Wrapper>
				{showAddQuestion && (
					<AddQuestionModal
						isOpen={showAddQuestion}
						closeAddQuestion={this.closeAddQuestion}
						templateId={templateId}
						getData={getData}
						sectionId={item.id}
					/>
				)}
				{showEditQuestion && (
					<EditQuestionModal
						isOpen={showEditQuestion}
						closeEditQuestion={this.closeEditQuestion}
						item={editQuestion}
						templateId={templateId}
						getData={getData}
						sectionId={item.id}
					/>
				)}
				{showAddSubsection && (
					<AddSubsectionModal
						closeAdd={this.closeAddSubsection}
						templateId={templateId}
						sectionId={item.id}
						getData={getData}
					/>
				)}
				<div className="d-flex align-items-center justify-content-between">
					<Title>{item.name}</Title>
					<Popover
						enforceFocus={false}
						isOpen={showMenu}
						position={Position.BOTTOM}
						onClose={() => this.setState({ showMenu: false })}
					>
						<Button
							rightIcon="caret-down"
							intent="danger"
							text="View Options"
							onClick={() => this.setState({ showMenu: true })}
							minimal
						/>
						<div style={{ width: '234px' }}>
							<div className="pt-2 pb-2">
								<Button
									minimal
									text="Add question"
									className="w-100 justify-content-start"
									onClick={() =>
										this.setState({ showAddQuestion: true })
									}
								/>
								<Button
									minimal
									text="Edit section"
									className="w-100 justify-content-start"
									onClick={() => openEdit(item)}
								/>
								<Button
									minimal
									text="Copy section"
									className="w-100 justify-content-start"
									onClick={() => openCopy(item.id)}
								/>
								<Button
									intent="danger"
									minimal
									text="Delete section"
									onClick={this.deleteSection}
									className="w-100 justify-content-start"
								/>
							</div>
						</div>
					</Popover>
				</div>
				<Directions>
					<strong>Directions:</strong> {item.instructions}
				</Directions>
				<div>
					<Title style={{ fontSize: '18px' }}>
						Main Section Questions
					</Title>
					{item.questions.map((one, index) => (
						<QuestionWrap key={`question-${index}`}>
							<div className="d-flex justify-content-between">
								<div className="d-flex">
									<Icon icon="drag-handle-vertical" />
									<QuestionTextWrap>
										<QuestionText>
											{index + 1}. {one.title}
										</QuestionText>
										<p className="mt-2">Hint: {one.hint}</p>
										<p>
											Type:{' '}
											{
												JSON.parse(one.properties)
													.answerType?.value
											}
										</p>
									</QuestionTextWrap>
								</div>
								<div>
									<Button
										rightIcon="edit"
										intent="danger"
										text=""
										onClick={() =>
											this.setState({
												showEditQuestion: true,
												editQuestion: one,
											})
										}
										minimal
									/>
									<Popover
										enforceFocus={false}
										isOpen={
											showQuestionMenu &&
											one.id === showQuestionId
										}
										position={Position.BOTTOM}
										onClose={() =>
											this.setState({
												showQuestionMenu: false,
												showQuestionId: null,
											})
										}
									>
										<Button
											rightIcon="more"
											intent="danger"
											text=""
											onClick={() =>
												this.setState({
													showQuestionMenu: true,
													showQuestionId: one.id,
												})
											}
											minimal
										/>
										<div style={{ width: '234px' }}>
											<div className="pt-2 pb-2">
												<Button
													minimal
													text="Delete question"
													className="w-100 justify-content-start"
													onClick={() =>
														this.deleteQuestion(one)
													}
												/>
											</div>
										</div>
									</Popover>
								</div>
							</div>
							<div />
						</QuestionWrap>
					))}
				</div>
				<div className="d-flex">
					<Button
						icon="plus"
						outline
						minimal
						text="Add a sub section"
						className="justify-content-start"
						onClick={() =>
							this.setState({ showAddSubsection: true })
						}
					/>
					<Button
						icon="plus"
						outline
						minimal
						text="Add a question"
						className="justify-content-start"
						onClick={() => this.setState({ showAddQuestion: true })}
					/>
				</div>
			</Wrapper>
		);
	}
}

Subsection.propTypes = {
	item: PropTypes.object,
	getData: PropTypes.func,
	templateId: PropTypes.any,
	openCopy: PropTypes.func,
	openEdit: PropTypes.func,
};

export default Subsection;
