import React, { useEffect, useState } from 'react';
import {
    LoadingSpinner,
    EmptyState,
    noResult,
} from 'componentsV2';
import { AlarmSubmittedClassRow } from './AlarmSubmittedClassRow';

export const AlarmSubmittedClasses = ({
    loading,
    pastAlarmHistory,
}) => {
    const [submittedClasses, setSubmittedClasses] = useState([]);

    useEffect(() => {
        if (!loading) {
            setSubmittedClasses((pastAlarmHistory && pastAlarmHistory.submittedClasses) ? pastAlarmHistory.submittedClasses.filter(submittedClass => submittedClass.id > 0) : []);
        }
    }, [loading]);

    return (
        <div className="alarm-card-container w-60">
            {loading && <LoadingSpinner />}
            {!loading && (
                <>
                    <div className="alarm-card-header">
                        <div className="alarm-card-title">Submitted classes</div>
                    </div>
                    <div className={`alarm-submitted-classes-container ${submittedClasses.length === 0 ? "alarm-submitted-classes-empty" : ""} `}>
                        {submittedClasses.length === 0 ? (
                            <EmptyState
                                header="No classes to show right now"
                                icon={noResult}
                                showCircles={false}
                            />
                        ) : (
                            <>
                                <div className="alarm-submitted-classes-header">
                                    <div className="alarm-submitted-classes-column1">
                                        Class
                                    </div>
                                    <div className="alarm-submitted-classes-column2">
                                        Submitted by
                                    </div>
                                </div>
                                <div className="alarm-submitted-classes-body">
                                    {submittedClasses.map((submittedClass, key) => (
                                        <AlarmSubmittedClassRow
                                            key={`submittedClass-${key}`}
                                            submittedClass={submittedClass}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}
