import { HttpService, buildRoute } from 'networking/HttpService';

export const DrillTypeInstructionsService = {
	async getWithParams(state, year, drillTypeUUID) {
		return HttpService({
			method: 'GET',
			route: buildRoute(
				'/drills/type-instructions?state=:state&year=:year&drillTypeUUID=:drillTypeUUID',
				{
					state,
					year,
					drillTypeUUID,
				},
			),
		});
	},
	async save(
		state,
		year,
		drillTypeUUID,
		schedulingInstructions,
		postingInstructions,
	) {
		return HttpService({
			method: 'POST',
			route: '/drills/type-instructions',
			body: {
				state,
				year,
				drillTypeUUID,
				...(schedulingInstructions && { schedulingInstructions }),
				...(postingInstructions && { postingInstructions }),
			},
		});
	},
};
