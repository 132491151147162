import React from 'react';
import PropTypes from 'prop-types';

export const ModalFooter = ({
  footer,
  leftContent,
  confirmButton,
  cancelButton,
}) => {
  return (
    <div className='alert-modal-footer-wrapper'>
      {footer ? footer :
        <>
          {leftContent}
          <div className="footer-main-actions">
            {cancelButton}
            {confirmButton}
          </div>
        </>
      }
    </div>
  )
};

ModalFooter.propTypes = {
  footer: PropTypes.element,
  leftContent: PropTypes.element,
  confirmButton: PropTypes.element,
  cancelButton: PropTypes.element,
}