import { useSelector } from 'react-redux';

const useSelectedSites = () => {
	const oldSelectedSites = useSelector(state => state.route.selectedSites);

	if (oldSelectedSites !== undefined && Array.isArray(oldSelectedSites)) {
		return oldSelectedSites;
	}
	const loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));

	return [
		{
			label: loggedUser?.primaryBuilding?.name,
			value: loggedUser?.primaryBuilding?.id,
		},
	];
};

export default useSelectedSites;
