import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog } from '@blueprintjs/core';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { LoadingSpinner } from 'components/LoadingSpinner';
import Swal from 'sweetalert2';
import CreateVariables from './CreateVariables';
import EditVariables from './EditVariables';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

const InputWrap = styled.div`
	padding: 0 10px 0 10px;
`;

const SectionTitleWrap = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 10px 0;
	background: ${props => (props.color === 'none' ? '#fff' : '#faedd3')};
`;

const SectionTitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: #202b33;
	margin-bottom: 0;
`;

const VariableWrap = styled.div`
	display: flex;
	justify-content: space-between;
	background: ${props => (props.color === 'none' ? '#fff' : '#fffbf3')};
	border-top: 1px solid #d8e1e8;
	padding: 5px 7px;
`;
const VariableInfoWrap = styled.div`
	display: flex;
	align-items: center;
`;

const VariableName = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: #182026;
	margin-bottom: 0;
`;

const VariableType = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #182026;
	margin-bottom: 0;
	margin-left: 10px;
`;

const ButtonsWrap = styled.div`
	display: flex;
`;

class VariableDialog extends React.Component {
	constructor(props) {
		super(props);
		const { commonVariables, templateVariables } = this.props;
		this.state = {
			loading: false,
			createVarDialog: false,
			editVarDialog: false,
			editVarContent: {
				type: {
					id: null,
				},
			},
			editVarId: null,
			commonVariables,
			templateVariables,
			allCommonVariables: null,
			allTemplateVariables: null,
			stringToSearch: ''
		};
	}

	findVar = e => 
	{
		const { allCommonVariables, allTemplateVariables, stringToSearch } = this.state;
		const { value } = e.target;

		this.setState({ stringToSearch : value });

		if (value != "") {
			const filteredCommonVariables = allCommonVariables.filter(function (item) {
				return JSON.parse(item.content).name.toLowerCase().includes(value.toLowerCase())
			});

			const filteredTemplateVariables = allTemplateVariables.filter(function (item) {
				return JSON.parse(item.content).name.toLowerCase().includes(value.toLowerCase())
			});

			this.setState({
				templateVariables: filteredTemplateVariables,
				commonVariables: filteredCommonVariables
			});
		} else {
			this.setState({
				templateVariables: allTemplateVariables,
				commonVariables: allCommonVariables
			});
		}
	};

	toggleCreateDialog = (val, openVars = false) => {
		const { toggleDialog } = this.props;
		if (openVars) toggleDialog(true);
		return this.setState({ createVarDialog: val });
	};

	toggleEditDialog = () =>
		this.setState({
			editVarDialog: false,
			editVarContent: { type: { id: null } },
		});

	refreshVariables = () => {
		const { reloadVariables, reopenDialog } = this.props;
		reloadVariables();
		this.setState({ createVarDialog: false });
		reopenDialog();
	};

	checkDelete = item => {
		const { deleteVariable } = this.props;
		Swal.fire({
			title: 'Delete the variable?',
			text: 'This action can not be undone!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Delete',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				return deleteVariable(item);
			}
			return Swal.close();
		});
	};

	updateRealVars = () => {
		this.setState({ loading: true });
		const { templateId } = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		Promise.all([
			fetch(`${process.env.API_URL}/plan-templates/${templateId}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
			fetch(`${process.env.API_URL}/common-variables`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			}),
		])
		.then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
		.then(([resp1, resp2]) => {
			if (resp1.statusCode !== 200 || resp2.statusCode !== 200) {
				const localToken = JSON.parse(localStorage.getItem('user'));
				localToken.jwt = '';
				localStorage.setItem('user', JSON.stringify(localToken));
				return window.location.reload();
			}

			if (resp1.data.variables) {
				this.setState({ 
					allTemplateVariables: resp1.data.variables,
					templateVariables: resp1.data.variables 
				});
			}
			this.setState({
				allCommonVariables: resp2.data,
				commonVariables: resp2.data,
				loading: false,
			});
		});
	};

	componentDidUpdate(prevProps, prevState) {
		const { createVarDialog } = this.state;
		const { isOpen } = this.props;
		if (prevState.createVarDialog === true && createVarDialog === false) {
			this.updateRealVars();
		}
		//ON OPEN update vars
		if (!prevProps.isOpen && isOpen) {
			this.updateRealVars();
		}	
	}

	render() {
		const {
			isOpen,
			toggleDialog,
			insertVariable,
			templateId,
			editVariable,
		} = this.props;
		const {
			createVarDialog,
			editVarDialog,
			editVarContent,
			editVarId,
			commonVariables,
			templateVariables,
			loading,
			stringToSearch
		} = this.state;
		return (
			<>
				<Dialog isOpen={isOpen} onClose={() => toggleDialog(false)}>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<div>
								<TitleWrap>
									<Title>Insert Variable</Title>
									<Button
										text="Add Custom Variable"
										minimal
										icon="add"
										onClick={() => {
											toggleDialog(false);
											return this.setState({
												createVarDialog: true,
											});
										}}
									/>
								</TitleWrap>
								<hr />
							</div>
							<div>
								<InputWrap>
									<Input
										placeholder="Search Variables"
										large
										className="mb-2"
										onChange={e => this.findVar(e)}
									/>
								</InputWrap>
								<SectionTitleWrap>
									<SectionTitle>
										Common Variables
									</SectionTitle>
								</SectionTitleWrap>	
								{commonVariables.map((item, index) => (
									<VariableWrap key={index}>
										<VariableInfoWrap>
											<VariableName>
												{JSON.parse(item.content).name}
											</VariableName>
											<VariableType>
												{JSON.parse(item.content).type}
											</VariableType>
										</VariableInfoWrap>
										<Button
											text="Insert"
											onClick={() => insertVariable(item)}
										/>
									</VariableWrap>
								))}

								{(commonVariables.length == 0 && stringToSearch != "")  && (
									<SectionTitleWrap color="none">
										No Common Variables found with '{stringToSearch}'
									</SectionTitleWrap>
								)}

							</div>
							<div>
								<SectionTitleWrap color="none">
									<SectionTitle>
										Custom Variables
									</SectionTitle>
								</SectionTitleWrap>
								
								{templateVariables.map(
									(item, index) => {
										if (item.active) {
											return (
												<VariableWrap
													color="none"
													key={index}
												>
													<VariableInfoWrap>
														<VariableName>
															{
																JSON.parse(
																	item.content,
																).name
															}
														</VariableName>
														<VariableType>
															{
																JSON.parse(
																	item.content,
																)
																	.typeAnnotation
															}
														</VariableType>
													</VariableInfoWrap>
													<ButtonsWrap>
														<Button
															text="Insert"
															onClick={() =>
																insertVariable(
																	item,
																)
															}
														/>

														<Button
															minimal
															icon="edit"
															className="ml-2"
															onClick={() =>
																this.setState(
																	{
																		editVarDialog: true,
																		editVarContent: JSON.parse(
																			item.content,
																		),
																		editVarId:
																			item.id,
																	},
																)
															}
														/>
														<Button
															minimal
															icon="trash"
															className="ml-2"
															onClick={() =>
																this.checkDelete(
																	item,
																)
															}
														/>
													</ButtonsWrap>
												</VariableWrap>
											);
										}
										return null;
									},
								)}

								{(templateVariables.length == 0 && stringToSearch != "")  && (
									<SectionTitleWrap color="none">
										No Custom Variables found with '{stringToSearch}'
									</SectionTitleWrap>
								)}
								
							</div>
						</>
					)}
				</Dialog>
				<CreateVariables
					createVarDialog={createVarDialog}
					toggleCreateDialog={this.toggleCreateDialog}
					templateId={templateId}
					refreshVariables={this.refreshVariables}
				/>
				<EditVariables
					createVarDialog={editVarDialog}
					toggleEditDialog={this.toggleEditDialog}
					templateId={templateId}
					refreshVariables={this.refreshVariables}
					editVarContent={editVarContent}
					editVariable={editVariable}
					editVarId={editVarId}
				/>
			</>
		);
	}
}
VariableDialog.propTypes = {
	isOpen: PropTypes.bool,
	toggleDialog: PropTypes.func,
	insertVariable: PropTypes.func,
	commonVariables: PropTypes.array,
	templateVariables: PropTypes.array,
	templateId: PropTypes.string,
	reloadVariables: PropTypes.func,
	reopenDialog: PropTypes.func,
	editVariable: PropTypes.func,
	deleteVariable: PropTypes.func,
};

export default VariableDialog;
