const scrollToSidebarSection = (subSectionIndex, sideBarRef, sectionRef) => {
	if (sectionRef.current) {
		let offsetTop = sectionRef.current.offsetTop - 100;
		if (subSectionIndex !== null) {
			// subsection
			offsetTop += subSectionIndex * 10;
		}
		sideBarRef.current.scrollTo({
			behavior: 'smooth',
			block: 'start',
			top: offsetTop,
		});
	}
};

const getVariableType = (item, templateVariables, commonVariables) => {

	//search in template variables
	const getTemplateVariable = templateVariables.find(templateVariable => templateVariable.name === item.variableName);
	if(getTemplateVariable) {
		if( getTemplateVariable.content && JSON.parse(getTemplateVariable.content).typeAnnotation == 'Image') {
			return 'image';
		}
		return 'text';
	}

	//search in common variables
	const getCommonVariable = commonVariables.find(commonVariable => commonVariable.name === item.variableName);

	if(getCommonVariable) {
		if( getCommonVariable.content && JSON.parse(getCommonVariable.content).typeAnnotation == 'Image') {
			return 'image';
		}
		return 'text';
	}
}

export { scrollToSidebarSection };
export { getVariableType };
