import React from 'react';
import { Badge } from 'componentsV2';

export const TableStatusCell = ({ status }) => {
	const colorMap = {
		'In Progress': 'Primary',
		Completed: 'Success',
	};

	if (status === 'Completed' || status === 'In Progress') {
		return (
			<Badge
				text={status === 'In Progress' ? 'Incomplete' : status}
				type={colorMap[status]}
				size="lg"
			/>
		);
	} else {
		return <span>N/A</span>;
	}
};
