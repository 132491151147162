import { HttpService } from 'networking/HttpService';

export const FloorplanService = {
	getAll: async (params = {}) => {
		let query = '';
		if (params.title) {
			query += `&title=${params.title}`;
		}
		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}
		if (params.includeArchived) {
			query += `&includeArchived=${params.includeArchived}`;
		}
		if (params.includeVisibleToAll) {
			query += `&includeVisibleToAll=${params.includeVisibleToAll}`;
		}
		if (params.buildingIds && params.buildingIds.length > 0) {
			params.buildingIds.forEach(buildingId => {
				query += `&buildingIds[]=${buildingId}`;
			});
		}

		return await HttpService({
			method: 'GET',
			route: `/floorplans?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	getFloorplansBySiteId: async params => {
		let query = '';
		if (params.page) {
			query += `page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.floorplanId) {
			query += `&floorplan_id=${params.floorplanId}`;
		}
		if (params.title) {
			query += `&title=${params.title}`;
		}
		if (params.binderId) {
			query += `&binderId=${params.binderId}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/buildings/${params.siteId}/floorplans?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	getAllLegends: async () => {
		return await HttpService({
			method: 'GET',
			route: `/floorplans/color-legends`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	editLegends: async colorLegends => {
		return await HttpService({
			method: 'PUT',
			route: `/floorplans/color-legends`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				colorLegends: colorLegends,
			},
		}).catch(err => {
			throw err;
		});
	},

	getSiteInfo: async params => {
		return await HttpService({
			method: 'GET',
			route: `/floorplans/${params.siteId}/get_site_info`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	createMap: async body => {
		return await HttpService({
			route: `/floorplans/add`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	reOrder: async (buildingId, body) => {
		return await HttpService({
			route: `/floorplans/${buildingId}/reorder`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	updateMap: async (id, body) => {
		return await HttpService({
			route: `/floorplans/${id}`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	getMapInfo: async id => {
		return await HttpService({
			method: 'GET',
			route: `/floorplans/${id}`,
		}).catch(err => {
			throw err;
		});
	},

	bulkToggleArchive: async body => {
		return await HttpService({
			route: `/floorplans/bulk-toggle-archived`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},
	deleteMaps: async (params = {}) => {
		return await HttpService({
			route: `/floorplans`,
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			body: params,
		}).catch(err => {
			throw err;
		});
	},

	getShapes: async (params = {}) => {
		let query = '';
		if (params.mapId) {
			query += `&map_id=${params.mapId}`;
		}
		if (params.geometryType) {
			query += `&geometry_type=${params.geometryType}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/floorplans/${params.siteId}/shapes?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	},

	bulkShapesUpdate: async (id, body) => {
		return await HttpService({
			route: `/floorplans/${id}/shapes/bulk-updates`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	archiveAllMaps: async body => {
		return await HttpService({
			route: `/floorplans/bulk-toggle-archived`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	getSiteMaps: async (siteId, params = null) => {
		let query = '';
		if (params) {
			if (params.sortKey) {
				query += `sortKey=${params.sortKey}`;
			}
			if (params.sortDir) {
				query += `&sortDir=${params.sortDir}`;
			}
			if (params.includeArchived) {
				query += `&includeArchived=${params.includeArchived}`;
			}
		}

		return await HttpService({
			method: 'GET',
			route: `/buildings/${siteId}/maps?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	getIconList: async (siteId, floorplanId = null) => {
		let route = `/floorplans/${siteId}/icons`;
		if (floorplanId) {
			route += `?floorplanId=` + parseInt(floorplanId);
		}
		return await HttpService({
			route: route,
			method: 'GET',
		}).catch(err => {
			throw err;
		});
	},

	addShape: async (floorplanId, body) => {
		return await HttpService({
			route: `/floorplans/${floorplanId}/add-shape`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	deleteShape: async (floorplanId, shapeId) => {
		return await HttpService({
			route: `/floorplans/${floorplanId}/shape/${shapeId}`,
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	saveBulkActions: async (floorplanId, shapeData, floorplanImg) => {
		let route = `/floorplans/${floorplanId}/shapes/bulk-updates`;
		return await HttpService({
			route: route,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				shapes: shapeData,
				floorplan: floorplanImg,
			},
		}).catch(err => {
			throw err;
		});
	},

	updateShape: async (floorplanId, shapeId, body) => {
		return await HttpService({
			route: `/floorplans/${floorplanId}/shape/${shapeId}`,
			method: 'put',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},
	getAreaContentDetails: async params => {
		let query = '';
		if (params.site_id) {
			query += `&site_id=${params.site_id}`;
		}
		if (params.floorplan_id) {
			query += `&floorplan_id=${params.floorplan_id}`;
		}
		if (params.shape_id) {
			query += `&shape_id=${params.shape_id}`;
		}
		return await HttpService({
			route: `/floorplans/area-attachments-details?${query}`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},
	copyShape: async (floorplanId, shapeId) => {
		return await HttpService({
			route: `/floorplans/${floorplanId}/shape/${shapeId}/copy`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	AddShapeNote: async body => {
		return await HttpService({
			route: `/floorplans/notes`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	AttachContentToArea: async (siteId, shapeId, body) => {
		return await HttpService({
			route: `/floorplans/${siteId}/shapes/${shapeId}/attach-object`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				attachments: body,
			},
		}).catch(err => {
			throw err;
		});
	},

	AttachWeblinkToArea: async (floorplanId, shapeId, body) => {
		return await HttpService({
			route: `/floorplans/${floorplanId}/shape/${shapeId}/camera-link`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},
	UpdateShapeNote: async (noteId, body) => {
		return await HttpService({
			route: `/floorplans/notes/${noteId}`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	deleteTexts: async (siteId, body) => {
		return await HttpService({
			route: `/floorplans/${siteId}/texts`,
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	DeleteShapeNote: async noteId => {
		return await HttpService({
			route: `/floorplans/notes/${noteId}`,
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},
	removeShapeContent: async (params = {}) => {
		return await HttpService({
			route: `/floorplans/${params.siteId}/shapes/${params.shapeId}/file`,
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				type: params.type,
				fileId: params.itemId,
			},
		}).catch(err => {
			throw err;
		});
	},
	UpdateShapeCameraLink: async (floorplanId, shapeId, cameraLinkId, body) => {
		return await HttpService({
			route: `/floorplans/${floorplanId}/shape/${shapeId}/camera-link/${cameraLinkId}`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},
	DeleteShapeCameraLink: async (floorplanId, shapeId, cameraLinkId) => {
		return await HttpService({
			route: `/floorplans/${floorplanId}/shape/${shapeId}/camera-link/${cameraLinkId}`,
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},
	getAvailableSites: async () => {
		return await HttpService({
			method: 'GET',
			route: `/floorplans/available-sites`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	renameTexts: async (siteId, body) => {
		return await HttpService({
			route: `/floorplans/${siteId}/text`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body,
		}).catch(err => {
			throw err;
		});
	},
	downloadMap: async (siteId, floorplanId, type) => {
		return await HttpService({
			route: `/floorplans/${siteId}/${floorplanId}/map-preview?type=${type}`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			}
		}).catch(err => {
			throw err;
		});
	},

	checkMapContainSp: async (buildingsIds) => {
		let query = ''
		if (buildingsIds && buildingsIds.length > 0) {
			buildingsIds.forEach(buildingId => {
				query += `&buildingIds[]=${buildingId}`;
			});
		}
		return await HttpService({
			method: 'GET',
			route: `/floorplans/check-old-sp?${query}`,
		}).catch(err => {
			throw err;
		});
	},
};
