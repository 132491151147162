import React, { useContext } from 'react';
import { CANCEL_CREATION_MODAL } from '../../../../consts';
import { CreateAlarmTypeContext } from '../../../../provider';
import { Button } from 'componentsV2';
import PropTypes from 'prop-types';
import './css/FooterActions.scss';

export const FooterActions = ({
	previousButtonTitle,
	onClickPreviousAction,
	nextButtonTitle,
	onClickNextAction,
	nextButtonDisabled = false,
}) => {
	const { openModal } = useContext(CreateAlarmTypeContext);

	const onCancelAction = () => {
		openModal(CANCEL_CREATION_MODAL);
	};

	return (
		<div className="create-alarm-type-body-card-content-footer-actions">
			<div className="create-alarm-type-body-card-content-footer-actions-left-side">
				<Button text="Cancel" type="tertiary" onClick={onCancelAction} />
			</div>
			<div className="create-alarm-type-body-card-content-footer-actions-right-side">
				{previousButtonTitle && (
					<Button
						text={previousButtonTitle}
						onClick={onClickPreviousAction}
						type="secondaryDefault"
						icon="chevron-left"
					/>
				)}
				<Button
					text={nextButtonTitle}
					onClick={onClickNextAction}
					type="primaryDefault"
					rightIcon="chevron-right"
					disabled={nextButtonDisabled}
				/>
			</div>
		</div>
	);
};

FooterActions.propTypes = {
	previousButtonTitle: PropTypes.string,
	nextButtonTitle: PropTypes.string,
	onClickPreviousAction: PropTypes.func,
	onClickNextAction: PropTypes.func,
	nextButtonDisabled: PropTypes.bool,
};
