import React, { useEffect, useState, useContext } from 'react';
import { FeaturedIcon, ToasterContext, Intent, ConfirmationModal } from 'componentsV2';
import './Template.scss';
import { Section } from './Section';
import { TemplateSectionModal } from './TemplateSectionModal';
import { TemplateQuestionModal } from './TemplateQuestionModal';
import { CopyTemplateSectionModal } from './CopyTemplateSectionModal';
import { AssessTemplatesService } from '../../../../../services/AssessTemplatesService';

// The assessment template is represented as a tree where the sections are branches and the questions are leaves.
// This model allows the sections to be recursively rendered, as the structure is fluid.
export const Template = ({ templateData, getTemplatesData, isViewOnly }) => {
	const [template, setTemplate] = useState(templateData.templateSections);
	const [openModal, setOpenModal] = useState('');
	const [modalProps, setModalProps] = useState({});
	const toaster = useContext(ToasterContext);
	const [modalLoading, setModalLoading] = useState(false);

	const handleCloseModal = () => {
		setOpenModal('');
		setModalProps({});
	};

	const handleOpenModal = (modalToShow, modalProps) => {
		setOpenModal(modalToShow);
		if (modalProps) setModalProps(modalProps);
	};

	const onSubmitDeleteSectionSubsection = () => {
		setModalLoading(true);
		AssessTemplatesService.deleteSectionSubsection(templateData.id, modalProps?.sectionId)
			.then(() => {
				getTemplatesData();
				toaster(
					`You have successfully deleted this ${
						modalProps?.isSection ? 'section' : 'subsection'
					}.`,
					Intent.NONE,
					<FeaturedIcon icon="trash" size="md" type="Gray" shape="circleIcon" />,
				);
			})
			.catch(err => {
				console.log('err', err);
				toaster(
					`There was an error deleting the ${
						modalProps?.isSection ? 'section' : 'subsection'
					}. Please try again!`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				handleCloseModal();
				setModalLoading(false);
			});
	};

	const onSubmitCopySectionSubsection = data => {
		setModalLoading(true);
		AssessTemplatesService.copyTemplateSectionSubsection(
			templateData.id,
			modalProps?.sectionId,
			data,
		)
			.then(() => {
				getTemplatesData();
				toaster(
					`You have successfully copied this ${
						modalProps?.isSection ? 'section' : 'subsection'
					}.`,
					Intent.SUCCESS,
				);
			})
			.catch(err => {
				console.log('err', err);
				toaster(
					`There was an error copying the ${
						modalProps?.isSection ? 'section' : 'subsection'
					}. Please try again!`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				handleCloseModal();
				setModalLoading(false);
			});
	};

	const onSubmitSubsection = data => {
		AssessTemplatesService.addSubSection(
			templateData.id,
			data.title,
			data.instructions,
			modalProps.id,
		)
			.then(() => {
				getTemplatesData();
				toaster(
					`You successfully added this subsection.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
				);
			})
			.catch(err => {
				console.log('err', err);
				toaster(
					`There was an error adding sub section. Please try again!`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				handleCloseModal();
			});
	};

	const onSubmitEditSection = data => {
		AssessTemplatesService.editSection(
			templateData.id,
			data.title,
			data.instructions,
			modalProps.id,
		)
			.then(() => {
				getTemplatesData();
				toaster(
					`${
						modalProps.depth > 0
							? 'You have successfully edited this subsection.'
							: 'You have successfully edited this section.'
					}`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
				);
			})
			.catch(err => {
				console.log('err', err);
				toaster(
					`${
						modalProps.depth > 0
							? 'There was an error editing this subsection. Please try again.'
							: 'There was an error editing this section. Please try again.'
					}`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			})
			.finally(() => {
				handleCloseModal();
			});
	};

	const editQuestion = (sections, sectionId, questionId, updatedQuestion) => {
		return sections.map(section => {
			// If this section matches sectionId, update questions
			if (section.id === sectionId) {
				const updatedQuestions = section.questions.map(question => {
					return question.id === questionId ? updatedQuestion : question;
				});
				return { ...section, questions: updatedQuestions };
			}
			// If this section has sub-sections, recursively try them
			if (section.sections) {
				return {
					...section,
					sections: editQuestion(
						section.sections,
						sectionId,
						questionId,
						updatedQuestion,
					),
				};
			}
			// If no matches, return unchanged section
			return section;
		});
	};

	const handleEditQuestion = async (sectionId, questionId, updateQuestionBody) => {
		try {
			// persist updated question
			const updateQuestionResponse = await AssessTemplatesService.updateQuestion(
				templateData.id,
				questionId,
				updateQuestionBody,
			);
			// update template
			const updatedTemplate = editQuestion(
				template,
				sectionId,
				questionId,
				updateQuestionResponse.data,
			);
			setTemplate(updatedTemplate);
			// show success toaster
			toaster(
				'You have successfully edited this question.',
				Intent.SUCCESS,
				<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
			);
		} catch (error) {
			toaster(
				'There was an error editing this question',
				Intent.DANGER,
				<FeaturedIcon icon="error" type="Error" />,
			);
			throw error;
		}
	};

	const addQuestion = (sections, sectionId, newQuestion) => {
		return sections.map(section => {
			// if section id matches, add new question
			if (section.id === sectionId) {
				return { ...section, questions: [...section.questions, newQuestion] };
			}
			// if section has sub-sections, recursively try them
			if (section.sections) {
				return {
					...section,
					sections: addQuestion(section.sections, sectionId, newQuestion),
				};
			}
			// if no matches, return unchanged section
			return section;
		});
	};

	const handleAddQuestion = async (sectionId, questionBody) => {
		try {
			// persist new question
			const createQuestionResponse = await AssessTemplatesService.createQuestion(
				templateData.id,
				questionBody,
			);
			// update template
			const updatedTemplate = addQuestion(template, sectionId, createQuestionResponse.data);
			setTemplate(updatedTemplate);
			// show success toaster
			toaster(
				'You have successfully added this question.',
				Intent.SUCCESS,
				<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
			);
		} catch (error) {
			toaster(
				'There was an error adding this question',
				Intent.DANGER,
				<FeaturedIcon icon="error" type="Error" />,
			);
			throw error;
		}
	};

	const deleteQuestion = (sections, sectionId, questionId) => {
		return sections.map(section => {
			// if section id matches, remove question with id = questionId
			if (section.id === sectionId) {
				const updatedQuestions = section.questions.filter(
					question => question.id !== questionId,
				);
				return { ...section, questions: updatedQuestions };
			}
			// if section has sub-sections, recursively try them
			if (section.sections) {
				return {
					...section,
					sections: deleteQuestion(section.sections, sectionId, questionId),
				};
			}
			// if no matches, return unchanged section
			return section;
		});
	};

	const handleDeleteQuestion = async (sectionId, questionId) => {
		try {
			// persist deletion
			const deleteQuestionResponse = await AssessTemplatesService.deleteQuestion(
				templateData.id,
				questionId,
			);
			// update template
			const updatedTemplate = deleteQuestion(template, sectionId, questionId);
			setTemplate(updatedTemplate);
			handleCloseModal();
			// show success toaster
			toaster(
				'You have successfully deleted this question.',
				Intent.SUCCESS,
				<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
			);
		} catch (error) {
			handleCloseModal();
			toaster(
				'There was an error deleting this question',
				Intent.DANGER,
				<FeaturedIcon icon="error" type="Error" />,
			);
			throw error;
		}
	};

	useEffect(() => console.log(template), [template]);

	return (
		<div className="view-edit-template-sections">
			{/* Main sections */}
			{template.map(mainSection => (
				<Section
					key={mainSection.id}
					section={mainSection}
					depth={0}
					isViewOnly={isViewOnly}
					onOpenModal={handleOpenModal}
					templateId={templateData.id}
				/>
			))}
			{openModal === 'add_subsection' && (
				<TemplateSectionModal
					isOpen
					inputLabelText="Subsection Title"
					inputLabelPlaceholderText="Add subsection title"
					textAreaLabel="Directions"
					textAreaPlaceholderText="Enter directions"
					sectionModalTitle="Add a subsection"
					confirmBtnText="Add"
					sectionSubTitle={templateData?.name}
					submitSection={onSubmitSubsection}
					handleCloseModal={handleCloseModal}
				/>
			)}
			{openModal === 'edit_section' && (
				<TemplateSectionModal
					isOpen
					modalProps={modalProps}
					inputLabelText={
						modalProps.depth > 0 ? 'Edit subsection title' : 'Edit section title'
					}
					inputLabelPlaceholderText={
						modalProps.depth > 0 ? 'Add subsection title' : 'Add section title'
					}
					textAreaLabel="Directions"
					textAreaPlaceholderText="Enter directions"
					sectionModalTitle={modalProps.depth > 0 ? 'Edit subsection' : 'Edit section'}
					confirmBtnText="Update"
					sectionSubTitle={templateData?.name}
					submitSection={onSubmitEditSection}
					handleCloseModal={handleCloseModal}
				/>
			)}
			{openModal === 'delete_section_subsection' && (
				<ConfirmationModal
					isOpen
					loading={modalLoading}
					title={`Are you sure you want to delete this ${
						modalProps?.isSection ? 'section' : 'subsection'
					}?`}
					subtitle="Once deleted, this cannot be recovered."
					showCloseButton={false}
					onClose={handleCloseModal}
					cancelBtn={{
						title: 'No, go back',
						onClick: handleCloseModal,
					}}
					confirmBtn={{
						title: 'Yes, delete it',
						onClick: onSubmitDeleteSectionSubsection,
						type: 'primaryDanger',
					}}
				/>
			)}
			{openModal === 'edit_question' && (
				<TemplateQuestionModal
					type="edit"
					sectionId={modalProps.sectionId}
					question={modalProps.question}
					onEdit={(sectionId, questionId, updatedQuestion) =>
						handleEditQuestion(sectionId, questionId, updatedQuestion)
					}
					onClose={handleCloseModal}
				/>
			)}
			{openModal === 'add_question' && (
				<TemplateQuestionModal
					type="add"
					sectionId={modalProps.sectionId}
					onAdd={(sectionId, newQuestion) => handleAddQuestion(sectionId, newQuestion)}
					onClose={handleCloseModal}
				/>
			)}
			{openModal === 'copy_section_subsection' && (
				<CopyTemplateSectionModal
					isOpen
					loading={modalLoading}
					inputLabelPlaceholderText={`Enter a New ${
						modalProps.isSection ? 'section' : 'subsection'
					} title`}
					inputLabelText={modalProps.isSection ? 'Section title' : 'Subsection title'}
					sectionModalTitle={modalProps.isSection ? 'Copy section' : 'Copy subsection'}
					confirmBtnText={modalProps.isSection ? 'Copy section' : 'Copy subsection'}
					inputHintText={`Give your copied ${
						modalProps.isSection ? 'section' : 'subsection'
					} a new name. Please refrain from using a duplicate ${
						modalProps.isSection ? 'section' : 'subsection'
					} name.`}
					submitSection={onSubmitCopySectionSubsection}
					handleCloseModal={handleCloseModal}
				/>
			)}
			{openModal === 'delete_question' && (
				<ConfirmationModal
					isOpen
					title="Are you sure you want to delete this question?"
					subtitle="Once deleted, this cannot be recovered."
					icon={{ icon: 'trash', iconColor: 'error' }}
					cancelBtn={{
						title: 'Cancel',
						onClick: handleCloseModal,
					}}
					confirmBtn={{
						title: 'Delete it',
						onClick: () =>
							handleDeleteQuestion(modalProps.sectionId, modalProps.questionId),
						type: 'primaryDanger',
					}}
					onClose={handleCloseModal}
				/>
			)}
		</div>
	);
};
