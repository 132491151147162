import { HttpService } from 'networking/HttpService';

export const TemplateService = {
	publishUpdates: async (templateId, Updatedbody) => {
		return await HttpService({
			method: 'POST',
			headers: {
				Accept: 'application/json, text/plain, */*',
			},
			route: `/plan-templates/${templateId}/updates`,
			body: Updatedbody,
		}).catch(err => {
			throw err;
		});
	},

	async uploadFile(file, templateId) {
		try {
			const formData = new FormData();
			formData.append('file', file);
			const url = process.env.API_URL;
			const token = JSON.parse(localStorage.getItem('user')).jwt;
			const response = await fetch(`${url}/plan-templates/${templateId}/upload`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});
			return response.json();
		} catch (error) {
			throw error;
		}
	},

	async deleteFile(fileId, templateId) {
		try {
			return await HttpService({
				method: 'DELETE',
				route: `/plan-templates/${templateId}/files/${fileId}`,
			});
		} catch (error) {
			throw error;
		}
	},

	async ListTemplates(params = {}) {
		let query = '';
		if (params.page) {
			query += `page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.archive) {
			query += `&archive=${params.archive}`;
		}
		if (params.name) {
			query += `&name=${params.name}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}
		if (params.includeContent !== undefined) {
			query += `&includeContent=${params.includeContent}`;
		}
		if (params.archived !== undefined) {
			query += `&archive=${params.archived}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/plan-templates?${query}`,
		}).catch(err => {
			throw err;
		});
	},

	async toogleArchive(id, value) {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/plan-templates/${id}/archive`,
				body: {
					archive: value,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Get the event logs associated with the template
	 * @param {object} params
	 */
	async getEventLogs(params = {}) {
		let query = '';
		if (params.page) {
			query += `page=${params.page}`;
		}
		if (params.perPage) {
			query += query != '' ? '&' : '';
			query += `perPage=${params.perPage}`;
		}

		try {
			return await HttpService({
				method: 'GET',
				route: `/plan-templates/${params.templateId}/logs?${query}`,
			});
		} catch (error) {
			throw error;
		}
	},

	async deleteTemplate(templateId) {
		try {
			return await HttpService({
				method: 'DELETE',
				route: `/plan-templates/${templateId}`,
			});
		} catch (error) {
			throw error;
		}
	},

	async getCommonVariables() {
		try {
			return await HttpService({
				method: 'GET',
				route: `/common-variables`,
			});
		} catch (error) {
			throw error;
		}
	},

	async deleteCustomVariable(templateId, variableId) {
		try {
			return await HttpService({
				method: 'DELETE',
				route: `/plan-templates/${templateId}/variables/${variableId}`,
			});
		} catch (error) {
			throw error;
		}
	},

	async addCustomVariable(templateId, body = {}) {
		try {
			return await HttpService({
				method: 'POST',
				route: `/plan-templates/${templateId}/variables`,
				body,
			});
		} catch (error) {
			throw error;
		}
	},

	async updateCustomVariable(templateId, variableId, body = {}) {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/plan-templates/${templateId}/variables/${variableId}`,
				body,
			});
		} catch (error) {
			throw error;
		}
	},

	getPlanTemplateById: async planId =>
		await HttpService({
			method: 'GET',
			route: `/plan-templates/${planId}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		}),
	savePlanTemplate: async templateContent =>
		await HttpService({
			method: 'POST',
			route: `/plan-templates`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: templateContent,
		}).catch(err => {
			throw err;
		}),
	updatePlanTemplate: async (templateContent, planId) =>
		await HttpService({
			method: 'PUT',
			route: `/plan-templates/${planId}`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: templateContent,
		}).catch(err => {
			throw err;
		}),
	copyPlanTemplate: async (templateContent, planId) =>
		await HttpService({
			method: 'POST',
			route: `/plan-templates/${planId}/clone`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: templateContent,
		}).catch(err => {
			throw err;
		}),
	checkPublishValidation: async (templateId, sectionId) =>
		await HttpService({
			method: 'GET',
			route: `/plan-templates/${parseInt(
				templateId,
				10,
			)}/check-published-section?sectionId=${sectionId}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		}),

	downloadFile: async (baseFile, baseFileId) =>
		await HttpService({
			method: 'GET',
			route: `/plan-templates/${baseFile}/files/${baseFileId}`,
		}).catch(err => {
			throw err;
		}),
};
