import { HttpService } from 'networking/HttpService';

export const AssessmentService = {
	getAssessmentsBySiteId: async params => {
		let query = '';
		if (params.page) {
			query += `page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.title) {
			query += `&name=${params.title}`;
		}
		if (params.binderId) {
			query += `&binderId=${params.binderId}`;
		}

		return HttpService({
			method: 'GET',
			route: `/buildings/${params.siteId}/assessments?${query}`,
		}).catch(err => {
			throw err;
		});
	},
	initAssessments: () =>
		HttpService({
			method: 'GET',
			route: `/assessments-init`,
		}).catch(err => {
			throw err;
		}),

	getAssessmentSections: (assessmentId, includeHidden = true) =>
		HttpService({
			method: 'GET',
			route: `/assessments/${assessmentId}?includeHiddenSections=${includeHidden}`,
		}).catch(err => {
			throw err;
		}),

	getQuestions: (assessmentId, sectionId) =>
		HttpService({
			method: 'GET',
			route: `/assessments/${assessmentId}/sections/${sectionId}?includeAnswerAttachment=true`,
		}).catch(err => {
			throw err;
		}),

	downloadSection: (assessmentId, sectionId) =>
		HttpService({
			method: 'POST',
			route: `/assessments/${assessmentId}/export`,
			body: { sectionIds: [sectionId] },
		}).catch(err => {
			throw err;
		}),

	submitAssessSummary: (assessmentId, summary) =>
		HttpService({
			method: 'PUT',
			route: `/assessments/${assessmentId}`,
			body: { summary: `${summary}` },
		}).catch(err => {
			throw err;
		}),
	getAssessAttachmentsData: assessmentId =>
		HttpService({
			method: 'GET',
			route: `/assessments/${assessmentId}/files`,
		}).catch(err => {
			throw err;
		}),

	uploadAssessmentAttachment: async (assessmentId, fileData) => {
		try {
			const assessFileData = new FormData();

			assessFileData.append('file', fileData);
			const url = process.env.API_URL;
			const token = JSON.parse(localStorage.getItem('user')).jwt;
			const response = await fetch(`${url}/assessments/${assessmentId}/files`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: assessFileData,
			});

			return response.json();
		} catch (error) {
			throw error;
		}
	},

	getQuestionAttachments: (assessmentId, questionId) =>
		HttpService({
			method: 'GET',
			route: `/assessments/${assessmentId}/files?questionId=${questionId}`,
		}).catch(err => {
			throw err;
		}),

	uploadQuestionAttachment: async (assessmentId, questionId, questionFileData) => {
		try {
			const assessQuestionFileData = new FormData();

			assessQuestionFileData.append('file', questionFileData);
			const url = process.env.API_URL;
			const token = JSON.parse(localStorage.getItem('user')).jwt;
			const response = await fetch(
				`${url}/assessments/${assessmentId}/files?questionId=${questionId}`,
				{
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: assessQuestionFileData,
				},
			);

			return response.json();
		} catch (error) {
			throw error;
		}
	},

	deleteAttachment: (assessmentId, fileId) =>
		HttpService({
			method: 'DELETE',
			route: `/assessments/${assessmentId}/files/${fileId}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		}),

	setSectionVisibility: (assessmentId, sectionId, isHidden) =>
		HttpService({
			method: 'PUT',
			route: `/assessments/${assessmentId}/sections/${sectionId}`,
			body: { isHidden },
		}).catch(err => {
			throw err;
		}),
	downloadAssessment: (assessmentId, data) =>
		HttpService({
			method: 'POST',
			route: `/assessments/${assessmentId}/export`,
			body: { sectionIds: data },
		}).catch(err => {
			throw err;
		}),

	saveQuestionAnswer: (assessmentId, questionId, response) =>
		HttpService({
			method: 'POST',
			route: `/assessments/${assessmentId}/questions/${questionId}/answers`,
			body: { response },
		}),

	saveAnswerComment: (assessmentId, questionId, comment) =>
		HttpService({
			method: 'POST',
			route: `/assessments/${assessmentId}/questions/${questionId}/answers`,
			body: { comment },
		}),

	markCompleteIncomplete: async (assessmentId, value) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/assessments/${assessmentId}`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: { isCompleted: value },
			});
		} catch (error) {
			throw error;
		}
	},
	getSettings: async districtId => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/clients/${districtId}`,
			});
		} catch (error) {
			throw error;
		}
	},
	/**
	 * This is the settings object definition expected when updating settings
	 * @param {Object} settings
	 * @param {boolean} settings.assessIsLocked
	 * @param {boolean} settings.assessForceCompletion
	 */
	setSettings: (districtId, settings) =>
		HttpService({
			method: 'PUT',
			route: `/clients/${districtId}`,
			body: settings,
		}),
};
