/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@blueprintjs/core';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
	CustomTabs,
	LoadingSpinner,
	EmptyState,
	emptyFiles,
	LoadingModal,
	ConfirmationModal,
} from 'componentsV2';
import Swal from 'sweetalert2';
import InfiniteScroll from 'react-infinite-scroll-component';
import VisibilitySensor from 'react-visibility-sensor';
import selectRoute from './selectors';
import { parseDataModel, decodeToken, validateTemplateSections } from 'utilities';
import { LinkButton } from 'components/LinkButton';
import { BackToTopButton } from 'components/BackToTopButton';
import {
	DeleteSectionModal,
	UploadModal,
	ViewUpdatesModal,
	CompletePlanModal,
	MarkSectionComplete,
	ResetModal,
	AttachFilesModal,
	PublishModal,
} from './modals';
import { UploadImage } from '../../Shared/modals';
import { PlanService } from 'services/PlanService';
import { getVariableType } from 'utilities/SafetyPlan/helpers';
import { DistrictTemplateHeader, SectionItems } from './components';
import './css/DistrictTemplateDetails.scss';
import { DISTRICT_LIST_PATH } from '../consts';
import { DistrictTemplateProvider } from '../Providers';
import { SectionSubSectionContainer, scrollToSidebarSection } from '../../Shared';
import { VariablesFields } from './components/VariablesFields';
import PlanOutline from '../../Shared/PlanOutline/PlanOutline';
import { countOfSpecialTextInString } from 'utilities/searchInString';
import { parseContent } from './modals/DownloadSafetyPlanModal/utilities';
import ToasterModal from '../../Shared/ToasterModal.js';
import { DateTime } from 'luxon';
import { formatDate } from 'utilities/dates';
// Important: To maintain the 1-1 relationship between pages and sections. Keep this value at 1
const sectionsPerPage = 2;

class DistrictTemplateDetails extends React.Component {
	state = {
		content: '',
		loading: true,
		commonVariables: [],
		panel: 'Text editor',
		exportLoading: false,
		uploadModal: false,
		uploadSection: null,
		uploadSubsection: null,
		uploadBaseFile: null,
		uploadValueFile: null,
		yPosition: 0,
		page: 0,
		variableValues: [],
		showBackToTopButton: true,
		removeSubsectionReference: true,
		hasPermissions: false,
		modalToShow: false,
		isSectionScrollActive: false,
		viewMode: false,
		templateOldName: '',
		templateOldContent: {},
		templateSavedBeforeUnmount: false,
		variableImagesIDs: [],
		emptyStateMsg: null,
		visibleSection: null,
		visibleSubsection: null,
		sectionData: {},
		modalLoading: false,
		showToaster: false,
		toasterMsg: '',
		toasterIntent: '',
		activeEditor: null,
		fileValidationTitle: null,
	};
	constructor() {
		super();
		this.sidebarRef = React.createRef();
		this.mainWrapperRef = React.createRef();
	}
	loadData = async (firstLoad = null) => {
		this.setState({
			loading: true,
			emptyStateMsg: null,
			panel: 'Text editor',
		});

		const {
			match: {
				params: { id },
			},
			location,
			dispatch,
		} = this.props;

		dispatch({
			type: 'CLEAR_TEMPLATE_DATA',
		});

		Promise.all([PlanService.getTemplate(id), PlanService.getVariableValues(id)])
			.then(async ([resp1, resp2]) => {
				const templateResponse = resp1.data;

				const { history } = this.props;

				let viewMode = this.state.viewMode;

				if (
					(!templateResponse.hasEditPermission ||
						templateResponse.status == 'archived') &&
					!viewMode
				) {
					history.push('/safety-plans/view/' + templateResponse.id);
				}

				const templateUpdates = templateResponse.allUpdates;
				const hasResetUpdate =
					!viewMode &&
					templateUpdates.some(templateUpdate => templateUpdate.type === 'reset');
				const lastUpdated = templateResponse?.editedDate || templateResponse?.createdDate;
				let template = {
					id,
					is_template: false,
					name: templateResponse.name,
					lastUpdated: lastUpdated,
					lastUpdatedFormatted: formatDate(lastUpdated, DateTime.TIME_WITH_SHORT_OFFSET),
					buildingId: templateResponse.buildingId,
					createdFromDistrict: templateResponse.createdFromDistrict,
					hasEditPermission: templateResponse.hasEditPermission,
					sections: [
						{
							id: 1,
							title: 'Section 1 title',
							subsections: [
								{
									id: 11,
									title: 'Subsection 1 title',
									content: '<p>Subsection 1 content</p>',
								},
							],
						},
					],
					variableValues: resp2.data,
					bonusFiles: templateResponse.bonusFiles
						? JSON.parse(templateResponse.bonusFiles)
						: [],
					allUpdates: templateResponse.allUpdates,
					archivedByUser: templateResponse.archivedByUser,
					archivedDate: templateResponse.archivedDate,
					isCompleted: templateResponse.isCompleted,
					acceptingBulkUpdate: templateResponse.acceptingBulkUpdate,
				};
				if (templateResponse.headers) {
					template.headers = JSON.parse(templateResponse.headers);
				}
				if (templateResponse.content) {
					if (firstLoad) {
						// Only on the First Load, set the Initial Information
						// of the template in the state, needed to log changes
						this.setState({
							templateOldName: templateResponse.name,
							templateOldContent: templateResponse.content,
						});
					}
					template.sections =
						JSON.parse(templateResponse.content)?.sections ||
						JSON.parse(templateResponse.content);
					template = parseDataModel(template);
				}

				const variableValues = [];
				const variableImagesIDs = [];
				await resp2.data.forEach(async variableValue => {
					const item = variableValue;
					const variableType = getVariableType(
						variableValue,
						templateResponse.templateVariables,
						templateResponse.commonVariables,
					);
					item.type = variableType;

					if (variableType === 'image') {
						if (this.isInt(item.variableValue)) {
							variableImagesIDs.push(parseInt(item.variableValue));
						}
					}
					variableValues.push(item);
				});
				this.setState({
					variableImagesIDs: variableImagesIDs,
				});
				let imagesValues = [];
				await this.fetchImages(variableImagesIDs).then(res => {
					imagesValues = res;
				});
				for (const imagesValue of imagesValues) {
					variableValues.forEach(variableValue => {
						if (variableValue.variableValue == imagesValue.id) {
							variableValue.imageInfo = imagesValue;
						}
					});
				}
				this.setState({ variableValues: variableValues });
				const tempCommon = templateResponse.commonVariables;
				tempCommon.forEach(item => {
					let total = 0;
					let places = 0;
					let references = [];
					let value = null;
					variableValues.forEach(varVal => {
						if (item.name === varVal.variableName) {
							value = varVal.imageInfo ? varVal.imageInfo : varVal.variableValue;
						}
					});
					template.sections.forEach(one => {
						if (one.content.includes(item.name)) {
							total += 1;
							places += countOfSpecialTextInString(one.content, item.name);
							references.push(one.title);
						}
						one.subsections.forEach(single => {
							if (single.content.includes(item.name)) {
								total += 1;
								places += countOfSpecialTextInString(single.content, item.name);
								references.push(single.title);
							}
						});
					});
					item.total = total;
					item.references = references;
					item.places = places;
					item.value = value;
				});

				const tempTemplate = templateResponse.templateVariables;
				tempTemplate.forEach(item => {
					let total = 0;
					let places = 0;
					let references = [];
					let value = null;
					variableValues.forEach(varVal => {
						if (item.name === varVal.variableName) {
							value = varVal.imageInfo ? varVal.imageInfo : varVal.variableValue;
						}
					});
					template.sections.forEach(one => {
						if (one.content.includes(item.name)) {
							total += 1;
							places += countOfSpecialTextInString(one.content, item.name);
							references.push(one.title);
						}
						one.subsections.forEach(single => {
							if (single.content.includes(item.name)) {
								total += 1;
								places += countOfSpecialTextInString(single.content, item.name);
								references.push(single.title);
							}
						});
					});
					item.total = total;
					item.references = references;
					item.places = places;
					item.value = value;
				});
				this.setState({
					commonVariables: templateResponse.commonVariables,
					templateVariables: templateResponse.templateVariables,
					allVariables: templateResponse.commonVariables
						.map(variable => ({
							...variable,
							parsedContent: JSON.parse(variable.content),
						}))
						.concat(
							templateResponse.templateVariables.map(variable => ({
								...variable,
								parsedContent: JSON.parse(variable.content),
							})),
						),
				});
				const filteredSections = JSON.parse(JSON.stringify(template));
				if (viewMode) {
					filteredSections.sections.length =
						template.sections.length > sectionsPerPage
							? parseInt(sectionsPerPage + 10)
							: template.sections.length;
				} else {
					filteredSections.sections.length =
						template.sections.length > sectionsPerPage
							? sectionsPerPage
							: template.sections.length;
				}

				//set updateModal to true if the updates has reset update
				if (hasResetUpdate === true && !viewMode) {
					this.openModal('ResetModal');
				} else if (
					!viewMode &&
					templateUpdates.length > 0 &&
					location.state?.openPendingUpdates == true
				) {
					this.openModal('ViewUpdatesModal');
				}

				//set first section as visibile section

				const firstSection = template?.sections[0];
				this.onSectionChange(firstSection);
				this.setState({
					loading: false,
				});

				return dispatch({
					type: 'LOAD_TEMPLATE',
					payload: template,
					filtered: filteredSections,
				});
			})
			.catch((err1, err2) => {
				this.setState({
					emptyStateMsg: err1?.error?.description,
					loading: false,
				});
			});
	};

	openModal = modalName => this.setState({ modalToShow: modalName });

	isInt(value) {
		return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
	}

	saveAndReload = () => {
		const {
			route: {
				template: { sections, headers },
			},
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { nodes } = this.state;
		fetch(`${process.env.API_URL}/district-templates/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				headers: JSON.stringify(headers),
				content: JSON.stringify(sections),
			}),
		})
			.then(res => res.json())
			.then(resp => {
				Swal.close();
				this.loadData();
			});
	};

	saveWithoutReload = () => {
		const {
			route: {
				template: { sections, headers },
			},
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { nodes } = this.state;
		fetch(`${process.env.API_URL}/district-templates/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				headers: JSON.stringify(headers),
				content: JSON.stringify(sections),
			}),
		})
			.then(res => res.json())
			.then(resp => {
				Swal.close();
			});
	};

	openAllowFileValidationnModal = errorObj => {
		this.setState({
			modalToShow: 'AllowFileValidationError',
			fileValidationTitle:
				errorObj.messageHTML.replace(/<[^>]*>/g, '') +
				' Are you sure you want to close and lose your changes?',
			errorObj: errorObj,
		});
	};

	saveTemplate = enableValidation => {
		const { viewMode } = this.state;
		const { history } = this.props;

		if (viewMode) {
			return;
		}

		const { uploadModal } = this.state;
		if (uploadModal) {
			return this.closeUploadModal();
		}
		const {
			route: {
				template: { sections, is_template, name, headers },
			},
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		let errorObj = validateTemplateSections(sections, is_template, null, name);
		if (errorObj.issue && enableValidation) {
			this.openAllowFileValidationnModal(errorObj);
		} else {
			fetch(`${process.env.API_URL}/district-templates/${id}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					headers: JSON.stringify(headers),
					content: JSON.stringify(sections),
					name: name,
					oldName: this.state.templateOldName,
					oldContent: this.state.templateOldContent,
					log: 1,
				}),
			})
				.then(res => res.json())
				.then(resp => {
					if (enableValidation) {
						this.setState({ templateSavedBeforeUnmount: true });
						return history.push(DISTRICT_LIST_PATH);
					}
				});
		}
	};

	updateNodes = (sectionIndex, subsectionIndex, content) => {
		const { dispatch } = this.props;
		if (subsectionIndex === null) {
			return dispatch({
				type: 'UPDATE_SECTION_CONTENT',
				payload: content,
				sectionIndex,
			});
		}
		return dispatch({
			type: 'UPDATE_SUBSECTION',
			payload: content,
			sectionIndex,
			subsectionIndex,
		});
	};

	updateSectionTitle = (e, sectionIndex) => {
		if (e.target.value.length === 0) {
			e.target.parentElement.style.background = '#faf2f3';
			e.target.style.background = '#faf2f3';
		} else {
			e.target.parentElement.style.background = '';
			e.target.style.background = '';
		}
		const { dispatch } = this.props;
		return dispatch({
			type: 'UPDATE_SECTION_TITLE',
			payload: e.target.value,
			sectionIndex,
		});
	};

	updateSubsectionTitle = (e, sectionIndex, subsectionIndex) => {
		if (e.target.value.length === 0) {
			e.target.parentElement.style.background = '#faf2f3';
			e.target.style.background = '#faf2f3';
		} else {
			e.target.parentElement.style.background = '';
			e.target.style.background = '';
		}
		const { dispatch } = this.props;
		return dispatch({
			type: 'UPDATE_SUBSECTION_TITLE',
			payload: e.target.value,
			sectionIndex,
			subsectionIndex,
		});
	};

	updateTemplateName = e => {
		if (e.target.value.length === 0) {
			e.target.style.background = '#faf2f3';
		} else {
			e.target.style.background = '';
		}
		const { dispatch } = this.props;
		return dispatch({
			type: 'UPDATE_TEMPLATE_NAME',
			payload: e.target.value,
		});
	};

	addSubsection = (sectionIndex, subsectionIndex, subsectionId, source = '') => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'ADD_SUBSECTION',
			sectionIndex,
			subsectionIndex,
			subsectionId,
			source,
			removeReference: this.state.removeSubsectionReference,
		});
	};

	addMainSection = (sectionIndex, source) => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'ADD_SECTION',
			sectionIndex,
			source,
		});
	};

	templateTabsChanges = async val => {
		const targetPanel = val == 0 ? 'Text editor' : 'Variable fields';
		if (targetPanel === 'Text editor' && this.state.panel !== 'Text editor') {
			//scroll to active visible section/subsection
			const visibleSection = this.state.visibleSection;
			const visibleSubsection = this.state.visibleSubsection;
			await this.scrollToSection(
				visibleSection,
				visibleSubsection,
				visibleSubsection == null ? 'section' : 'subsection',
			);
		}
		this.setState({ panel: targetPanel });
	};

	isStringUrl = str => {
		const pattern = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i',
		); // fragment locator
		return !!pattern.test(str);
	};

	fetchImages = async fileIds => {
		const dataArray = [];
		const {
			match: {
				params: { id: templateId },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		for (const fileId of fileIds) {
			await fetch(`${process.env.API_URL}/district-templates/${templateId}/files/${fileId}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
				.then(res => res.json())
				.then(response => {
					if (response.statusCode === 200) {
						dataArray.push(response.data);
					}
				})
				.catch(err => {
					//
				});
		}
		return dataArray;
	};

	openDeleteSectionModal = (sectionIndex, sectionDetails) => {
		this.setState({
			modalToShow: 'DeleteSectionModal',
			deletedSectionIndex: sectionIndex,
			deletedSubsectionIndex: null,
			deletedSection: {
				actualSectionId: sectionDetails.id,
				...sectionDetails,
			},
		});
	};

	openDeleteSubSectionModal = (
		sectionIndex,
		subsectionIndex,
		sectionDetails,
		subSectionDetails,
	) => {
		this.setState({
			modalToShow: 'DeleteSubSectionModal',
			deletedSectionIndex: sectionIndex,
			deletedSubsectionIndex: subsectionIndex,
			deletedSection: {
				actualSectionId: sectionDetails.id,
				actualSubsectionId: subSectionDetails.id,
				...subSectionDetails,
			},
		});
	};

	closeModal = () => {
		this.setState({
			modalToShow: false,
		});
		const element = document.getElementById(this.state.errorObj?.elementId);
		if (element) {
			element.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}

		// this.saveAndReload();
	};

	closeErrorModal = () => {
		this.setState({
			modalToShow: false,
		});
		const element = document.getElementById(this.state.errorObj.elementId);
		if (element) {
			element.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	};

	openUploadModal = (reupload, baseFile, valueFile, sectionIndex, subsectionIndex) => {
		return this.setState({
			uploadModal: true,
			uploadSection: sectionIndex,
			uploadSubsection: subsectionIndex,
			uploadReupload: reupload,
			uploadBaseFile: baseFile,
			uploadValueFile: valueFile,
			showBackToTopButton: false,
		});
	};

	uploadFile = (files, sectionIndex, subsectionIndex) => {
		this.setState({ modalLoading: true });
		const {
			match: {
				params: { id: templateId },
			},
			dispatch,
		} = this.props;

		const token = JSON.parse(localStorage.getItem('user')).jwt;

		const fd = new FormData();
		fd.append('file', files);

		fetch(`${process.env.API_URL}/district-templates/${templateId}/upload`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: fd,
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					const valueFile = {
						size: resp.data.size,
						id: resp.data.id,
						name: resp.data.fileName,
					};
					this.setState({
						uploadValueFile: valueFile,
					});
					dispatch({
						type: 'ADD_VALUE_FILE',
						valueFile,
						sectionIndex: sectionIndex,
						subsectionIndex,
					});
					this.closeUploadModal();
					this.setState({
						modalLoading: false,
						toasterMsg: 'You have successfully uploaded this file.',
						toasterIntent: 'success',
						showToaster: true,
					});
				} else if (resp.statusCode == 400) {
					this.setState({
						modalLoading: false,
						toasterMsg: 'Faild to upload this file.',
						toasterIntent: 'danger',
						showToaster: true,
					});
					this.closeUploadModal();
				}
				return null;
			});
	};

	shouldComponentUpdate(nextProps, nextState) {
		const {
			route: { permissions },
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { dist } = token ? decodeToken(token) : '';
		const hasPermission = permissions.buildingPermissions?.[dist]?.['safety_plans_edit'];
		if (hasPermission !== undefined && this.state.hasPermissions != hasPermission) {
			this.setState({
				hasPermissions: hasPermission,
			});
		}

		return true;
	}

	closeUploadModal = () =>
		this.setState({
			uploadModal: false,
			uploadSection: null,
			uploadSubsection: null,
			uploadBaseFile: null,
			uploadValueFile: null,
			showBackToTopButton: true,
			showToaster: false,
		});

	markFileSubsectionComplete = (sectionIndex, subsectionIndex, payload, activeSection) => {
		this.setState({
			modalToShow: 'markSectionComplete',
			markCompleteSectionIndex: sectionIndex,
			markCompleteSubsectionIndex: subsectionIndex,
			payload: payload,
			sectionSubsectionInfo: activeSection,
		});
	};

	uploadImage = (activeSection, sectionIndex, subsectionIndex) => {
		this.setState({
			modalToShow: 'uploadImageToSection',
			sectionSubsectionInfo: activeSection,
			uploadImageSectionIndex: sectionIndex,
			uploadImageSubsectionIndex: subsectionIndex,
		});
	};

	scrollToSection = (section, subSection, type) => {
		let sectionId = `${type}-${section.id}`;
		if (subSection !== null) {
			sectionId += `-${subSection.id}`;
		}
		this.setState({
			isSectionScrollActive: false,
		});
		if (subSection) {
			this.onSubSectionChange(section, subSection);
		} else {
			this.onSectionChange(section);
		}
		let element = document.getElementById(sectionId);
		if (element) {
			this.scrollToElement(element);
		} else {
			// The element is not loaded yet, so, load the page that includes the element
			this.loadNewSections(section.id).then(sectionsLoaded => {
				if (sectionsLoaded) {
					let newElement = document.getElementById(sectionId);
					if (newElement) {
						this.scrollToElement(newElement);
					}
				}
			});
		}
	};

	loadNewSections = targetSectionId => {
		return new Promise(async resolve => {
			const {
				route: { template, filtered },
				dispatch,
			} = this.props;

			const targetSectionIndex = await template.sections.findIndex(
				section => section.id === targetSectionId,
			);
			const lastCurrentIndex = filtered.sections.length - 1;
			const newSections = template.sections.slice(
				lastCurrentIndex + 1,
				targetSectionIndex + 1,
			);

			filtered.sections.push(...newSections);

			dispatch({
				type: 'NEXT_SECTION',
				payload: filtered,
			});
			resolve(true);
		});
	};

	scrollToElement = element => {
		element.scrollIntoView();
	};

	getAutosave = template => {
		if (template.lastUpdatedFormatted) {
			return template.lastUpdatedFormatted;
		}

		return 'N/A';
	};

	onSectionChange = item => {
		this.setState({ visibleSubsection: null, visibleSection: item });
	};

	onSubSectionChange = (section, subSection) => {
		this.setState({ visibleSection: section, visibleSubsection: subSection });
	};

	onVisibilityChange = (section, subSection = null) => {
		if (this.state.isSectionScrollActive === true) {
			if (subSection === null) {
				this.onSectionChange(section);
			} else {
				this.onSubSectionChange(section, subSection);
			}
			scrollToSidebarSection(section.id, subSection?.id, this.sidebarRef);
		}
	};

	currentValues = {};

	getCurrentValuesFromState = () => {
		this.currentValues.visibleSection = this.state.visibleSection;
		this.currentValues.visibleSubsection = this.state.visibleSubsection;
		this.currentValues.page = this.state.page;
	};

	setCurrentValuesToState = () => {
		this.setState({
			visibleSection: this.currentValues.visibleSection,
			visibleSubsection: this.currentValues.visibleSubsection,
			page: this.currentValues.page,
		});
	};

	nextPage = () => {
		this.getCurrentValuesFromState();
		new Promise(resolve => {
			const {
				route: { template, filtered },
				dispatch,
			} = this.props;

			var lastSection = null;
			if (filtered.sections.length > 0) {
				lastSection = filtered.sections[filtered.sections.length - 1];
			}

			var nextSectionIndexToLoad = 0;
			if (lastSection) {
				template.sections.map((section, index) => {
					if (section.id === lastSection.id) {
						if (template.sections[index + 1] != undefined) {
							nextSectionIndexToLoad = index + 1;
						}
					}
				});
			}

			if (nextSectionIndexToLoad > 0) {
				const temp = template.sections[nextSectionIndexToLoad];
				filtered.sections.push(temp);
			}

			resolve(
				dispatch({
					type: 'NEXT_SECTION',
					payload: filtered,
				}),
			);
		}).then(async dispatchResolve => {
			await scrollToSidebarSection(
				this.currentValues.visibleSection.id,
				this.currentValues.visibleSubsection?.id,
				this.sidebarRef,
			);
			this.setCurrentValuesToState();
			return dispatchResolve;
		});
	};

	isLastSection = sectionId => {
		const {
			route: { template },
		} = this.props;

		const lastSection = template.sections[template.sections.length - 1];
		return lastSection.id === sectionId;
	};

	previousPage = () => {
		this.getCurrentValuesFromState();
		new Promise(resolve => {
			const {
				route: { template, filtered },
				dispatch,
			} = this.props;

			var firstSection = null;
			if (filtered.sections.length > 0) {
				firstSection = filtered.sections[0];
			}

			var previousSectionIndexToLoad = -1;
			if (firstSection) {
				template.sections.map((section, index) => {
					if (section.id === firstSection.id) {
						if (template.sections[index - 1] != undefined) {
							previousSectionIndexToLoad = index - 1;
						}
					}
				});
			}

			if (previousSectionIndexToLoad >= 0) {
				const temp = template.sections[previousSectionIndexToLoad];
				filtered.sections = [temp, ...filtered.sections];
			}

			return resolve(
				dispatch({
					type: 'NEXT_SECTION',
					payload: filtered,
				}),
			);
		}).then(dispatchResolve => {
			this.setCurrentValuesToState();
			return dispatchResolve;
		});
	};

	setPage = page => {
		this.setState({ page: page });
	};

	handleMainScroll = e => {
		if (this.state.yPosition > e.target.scrollTop) {
			if (e.target.scrollTop < 300) {
				this.previousPage();
			}
		}
		this.setState({
			yPosition: e.target.scrollTop,
		});
	};

	handleBackToTop = () => {
		// Get the first section
		const {
			route: { template },
		} = this.props;
		const firstSection = template.sections[0];
		// set firstSection as visible section
		this.onSectionChange(firstSection);
		this.setState({
			isSectionScrollActive: false,
		});
		// Wait 300ms to load the content, then scroll to the top of the page
		// setTimeout(() => {
		this.mainWrapperRef.current.scrollTo({
			top: 0,
		});
		this.sidebarRef.current.scrollTo({
			top: 0,
		});
		window.scrollTo(0, 0);
		// }, 300);
	};

	onWheelHandler = () => {
		if (this.state.isSectionScrollActive === false) {
			this.setState({
				isSectionScrollActive: true,
			});
		}
	};

	componentDidMount = async () => {
		const { viewMode } = this.props;

		if (viewMode === true) {
			this.setState({
				viewMode: true,
			});
		} else {
			this.setState({
				viewMode: false,
			});
		}

		return await this.loadData(true);
	};

	fileValidationnConfirmationModal = () => {
		const { history } = this.props;
		return history.push(DISTRICT_LIST_PATH);
	};

	componentWillUnmount() {
		const {
			route: { templateLoading },
		} = this.props;
		const templateSaved = this.state.templateSavedBeforeUnmount;
		const loading = this.state.loading;
		if (!templateSaved && !loading && !templateLoading) {
			this.saveTemplate(false);
		}
	}

	onDeleteAndPublishSection = async () => {
		const { deletedSection } = this.state;
		const {
			match: {
				params: { id: templateId },
			},
		} = this.props;
		const updateBody = {
			type: 'delete',
			updatedContent: JSON.stringify(deletedSection),
			actualSectionId: deletedSection.actualSectionId,
			actualSubsectionId: deletedSection.actualSubsectionId,
		};
		await PlanService.publishUpdates(templateId, updateBody).then(response => {
			if (response.statusCode === 201) {
			} else if (response.statusCode === 400) {
				// fireSwal('error', response.error.description)
			}
		});
	};

	openPublishModal = (isSection, sectionItem, subSectionItem, sectionIndex, subsectionIndex) => {
		const section = {
			item: sectionItem,
			subSectionItem: subSectionItem,
			section_index: sectionIndex,
			subsection_index: subsectionIndex,
			is_section: isSection,
		};
		this.setState({
			modalToShow: 'ShowDistrictUpdateModal',
			sectionData: section,
		});
	};

	sectionCustomItem = (
		sectionType,
		sectionIndex,
		section,
		sectionSubIndex = null,
		subSection = null,
	) => {
		const { viewMode, variableValues, allVariables } = this.state;
		const {
			route: { template },
		} = this.props;

		return (
			<SectionItems
				section={section}
				subSection={subSection}
				variables={allVariables}
				template={template}
				viewMode={viewMode}
				variableValues={variableValues}
				openDeleteSectionModal={this.openDeleteSectionModal}
				openDeleteSubSectionModal={this.openDeleteSubSectionModal}
				openModal={this.openModal}
				sectionIndex={sectionIndex}
				sectionSubIndex={sectionSubIndex}
				sectionType={sectionType}
				markFileSubsectionComplete={this.markFileSubsectionComplete}
				uploadImage={this.uploadImage}
				openPublishUpdateModal={this.openPublishModal}
			/>
		);
	};

	setActiveEditor = activeEditor => {
		this.setState({ activeEditor });
	};

	render() {
		const {
			allVariables,
			loading,
			variableValues,
			panel,
			exportLoading,
			uploadModal,
			uploadBaseFile,
			uploadValueFile,
			uploadSection,
			uploadSubsection,
			uploadReupload,
			viewMode,
			variableImagesIDs,
			visibleSection,
			visibleSubsection,
			sectionData,
			modalLoading,
			showToaster,
			toasterMsg,
			toasterIntent,
			fileValidationTitle,
		} = this.state;

		const {
			nodes,
			modalToShow,
			deletedSectionIndex,
			deletedSubsectionIndex,
			deletedSection,
		} = this.state;
		const {
			markCompleteSectionIndex,
			markCompleteSubsectionIndex,
			payload,
			sectionSubsectionInfo,
			uploadImageSectionIndex,
			uploadImageSubsectionIndex,
		} = this.state;
		const {
			route: { template, filtered, indicator, templateLoading },
			match: {
				params: { id: templateId },
			},
			dispatch,
		} = this.props;
		if (this.state.emptyStateMsg) {
			return <EmptyState header={this.state.emptyStateMsg} icon={emptyFiles} />;
		}
		if (templateLoading || loading) return <LoadingSpinner className="mt-3" />;

		const availableTabs = [
			{
				title: 'Text editor',
				index: 0,
			},
			{
				title: 'Variable fields',
				index: 1,
			},
		];

		const { history } = this.props;
		const templateUpdates = template.allUpdates.filter(
			templateUpdate => templateUpdate.type !== 'reset',
		);
		const hasUpdates = !viewMode && templateUpdates.length > 0;
		const cancelBtn = { title: 'No, go back', onClick: this.closeErrorModal };
		const confirmBtn = {
			title: 'Yes, continue',
			onClick: this.fileValidationnConfirmationModal,
		};
		const warningIcon = { icon: 'warning-sign', iconColor: 'warning' };
		return (
			<DistrictTemplateProvider history={history} loadData={this.loadData}>
				{showToaster && <ToasterModal msg={toasterMsg} intent={toasterIntent} />}
				{modalToShow === 'AllowFileValidationError' && (
					<ConfirmationModal
						isOpen={true}
						title={fileValidationTitle}
						subtitle=""
						showCloseButton={false}
						cancelBtn={cancelBtn}
						confirmBtn={confirmBtn}
						btnsSize="lg"
						icon={warningIcon}
					/>
				)}
				<div className="district-template-details-container">
					<div className="container-header">
						<DistrictTemplateHeader
							template={template}
							saveTemplate={this.saveTemplate}
							viewMode={viewMode}
							templateUpdates={templateUpdates}
							openModal={this.openModal}
							variableImagesIDs={variableImagesIDs}
							variableValues={variableValues}
							indicator={indicator}
							history={history}
						/>
						{!viewMode && (
							<CustomTabs
								renderActiveTabPanelOnly={true}
								identifier="addPlanTabs"
								onChangeHandler={e => {
									this.templateTabsChanges(e);
								}}
							>
								{availableTabs.map(availableTab => (
									<Tab
										id={availableTab.index}
										key={availableTab.index}
										panel={null}
										title={availableTab.title}
										className="CustomTabs__Tab"
									/>
								))}
							</CustomTabs>
						)}
					</div>
					<div className={`container-body ${hasUpdates ? 'has-update-card' : ''}`}>
						{panel === 'Text editor' && (
							<section className="text-editor-tab-wrapper">
								{modalToShow === 'DeleteSectionModal' && (
									<DeleteSectionModal
										isOpen={true}
										type="section"
										deletedSection={deletedSection}
										sectionIndex={deletedSectionIndex}
										subsectionIndex={deletedSubsectionIndex}
										closeDeleteModal={this.closeModal}
										onDeleteAndPublishSection={this.onDeleteAndPublishSection}
										scrollToSection={this.scrollToSection}
										template={template}
									/>
								)}
								{modalToShow === 'DeleteSubSectionModal' && (
									<DeleteSectionModal
										isOpen={true}
										type="sub-section"
										deletedSection={deletedSection}
										sectionIndex={deletedSectionIndex}
										subsectionIndex={deletedSubsectionIndex}
										closeDeleteModal={this.closeModal}
										onDeleteAndPublishSection={this.onDeleteAndPublishSection}
										scrollToSection={this.scrollToSection}
										template={template}
									/>
								)}
								<LoadingModal isOpen={exportLoading} loading={true} />
								{modalToShow === 'bonusFilesModal' && (
									<AttachFilesModal
										isOpen={true}
										template={template}
										onClose={this.closeModal}
										dispatch={dispatch}
										loadData={this.loadData}
									/>
								)}
								{modalToShow === 'completeModal' && (
									<CompletePlanModal
										isOpen={true}
										template={template}
										closeCompleteModal={this.closeModal}
										loadData={this.loadData}
									/>
								)}
								<UploadModal
									isOpen={uploadModal}
									closeUploadModal={this.closeUploadModal}
									baseFile={uploadBaseFile}
									valueFile={uploadValueFile}
									sectionIndex={uploadSection}
									subsectionIndex={uploadSubsection}
									reupload={uploadReupload}
									dispatch={dispatch}
									templateId={templateId}
									uploadFile={this.uploadFile}
									loading={modalLoading}
								/>
								{modalToShow === 'ViewUpdatesModal' && (
									<ViewUpdatesModal
										template={template}
										reloadTemplate={this.loadData}
										closeModal={this.closeModal}
									/>
								)}
								{modalToShow === 'uploadImageToSection' && (
									<UploadImage
										isOpen={true}
										closeModal={this.closeModal}
										activeSection={sectionSubsectionInfo}
										sectionIndex={uploadImageSectionIndex}
										subsectionIndex={uploadImageSubsectionIndex}
										updateNodes={this.updateNodes}
									/>
								)}
								{modalToShow === 'ResetModal' && (
									<ResetModal
										isOpen={true}
										template={template}
										reloadTemplate={this.loadData}
										closeResetModal={this.closeModal}
									/>
								)}
								{modalToShow === 'markSectionComplete' && (
									<MarkSectionComplete
										isOpen={true}
										template={template}
										isComplete={payload}
										activeSection={sectionSubsectionInfo}
										sectionIndex={markCompleteSectionIndex}
										subsectionIndex={markCompleteSubsectionIndex}
										dispatch={dispatch}
										closeModal={this.closeModal}
									/>
								)}
								{modalToShow === 'ShowDistrictUpdateModal' && (
									<PublishModal
										isOpen={true}
										title={`Are you sure you want to create an update for ""?`}
										subtitle=""
										showCloseButton={false}
										cancelBtn={{
											title: 'No, go back',
											onClick: this.closeModal,
										}}
										confirmBtn={{
											title: 'Yes, publish',
											onClick: this.publishUpdate,
										}}
										loading={loading}
										btnsSize="lg"
										sectionData={sectionData}
										template={template}
										closeModal={this.closeModal}
									/>
								)}
								<div
									className="sections-container"
									ref={this.mainWrapperRef}
									id="main"
									onScroll={this.handleMainScroll}
									onWheelCapture={this.onWheelHandler}
								>
									<div id="top-element" />
									<BackToTopButton
										onClick={this.handleBackToTop}
										containerId="main"
										topElementId="top-element"
										elementOnRightId="sidebar-wrapper"
										show={this.state.showBackToTopButton}
									/>
									<InfiniteScroll
										dataLength={filtered.sections.length}
										next={this.nextPage}
										hasMore={
											filtered.sections.length < template.sections.length
										}
										scrollableTarget="main"
									>
										{filtered.sections.map((item, index) => (
											<VisibilitySensor
												onChange={() => this.onVisibilityChange(item)}
												key={`section-${item.id}-${index}`}
												scrollCheck
												partialVisibility="top"
												offset={{ top: index > 0 ? 380 : 0 }}
											>
												<div key={index} className="section-wrapper">
													<div className="frame_wrapper">
														<SectionSubSectionContainer
															item={item}
															type="section"
															id={`section-${item.id}`}
															onTitleChange={e =>
																this.updateSectionTitle(e, index)
															}
															sectionCustomItem={this.sectionCustomItem(
																'section',
																index,
																item,
																null,
																null,
															)}
															viewMode={viewMode}
															sectionIndex={index}
															subsectionIndex={null}
															actualSectionId={item.id}
															actualSubsectionId={null}
															subsection={null}
															updateNodes={this.updateNodes}
															content={parseContent(
																item.content,
																false,
																variableValues,
															)}
															templateId={templateId}
															variableValues={variableValues}
															modalToShow={modalToShow}
															saveAndReload={this.saveAndReload}
															openUploadModal={this.openUploadModal}
															template={template}
															dispatch={dispatch}
															page={this.state.page}
															contentType="districtPlan"
															indicator={indicator}
															setActiveEditor={this.setActiveEditor}
														/>
													</div>
													{item.subsections.map((single, subindex) => (
														<VisibilitySensor
															onChange={() =>
																this.onVisibilityChange(
																	item,
																	single,
																)
															}
															key={`subsection-${item.id}-${
																single.id
															}-${subindex}`}
															scrollCheck
															partialVisibility="top"
															offset={{ top: 380 }}
														>
															<div
																className="subsection-wrapper frame_wrapper"
																key={index + '-' + single}
															>
																<SectionSubSectionContainer
																	item={single}
																	type="subsection"
																	readOnly={viewMode}
																	id={`subsection-${item.id}-${
																		single.id
																	}`}
																	onTitleChange={e =>
																		this.updateSubsectionTitle(
																			e,
																			index,
																			subindex,
																		)
																	}
																	saveTemplate={this.saveTemplate}
																	sectionCustomItem={this.sectionCustomItem(
																		'subsection',
																		index,
																		item,
																		subindex,
																		single,
																	)}
																	viewMode={viewMode}
																	variableValues={variableValues}
																	sectionIndex={index}
																	subsectionIndex={subindex}
																	actualSectionId={item.id}
																	actualSubsectionId={single.id}
																	updateNodes={this.updateNodes}
																	content={parseContent(
																		single.content,
																		false,
																		variableValues,
																	)}
																	templateId={templateId}
																	modalToShow={modalToShow}
																	openUploadModal={
																		this.openUploadModal
																	}
																	template={template}
																	subsection={single}
																	saveAndReload={
																		this.saveAndReload
																	}
																	dispatch={dispatch}
																	contentType="districtPlan"
																	indicator={indicator}
																	setActiveEditor={
																		this.setActiveEditor
																	}
																/>
															</div>
														</VisibilitySensor>
													))}
													{!viewMode && (
														<>
															<LinkButton
																className="pb-2"
																icon="add"
																text="Add subsection"
																intent="secondary"
																onClick={() =>
																	this.addSubsection(
																		index,
																		item.subsections?.length -
																			1 || 0,
																		item.subsections?.length > 0
																			? item.subsections[
																					item.subsections
																						.length - 1
																			  ].id
																			: null,
																		this.state.hasPermissions
																			? 'plan'
																			: 'site',
																	)
																}
															/>
															{this.isLastSection(item.id) && (
																<LinkButton
																	className="pb-2"
																	icon="add"
																	text="Add main section"
																	intent="secondary"
																	onClick={() =>
																		this.addMainSection(
																			index,
																			this.state
																				.hasPermissions
																				? 'plan'
																				: 'site',
																		)
																	}
																/>
															)}
														</>
													)}
												</div>
											</VisibilitySensor>
										))}
									</InfiniteScroll>
								</div>
								<div className="sidebar-container">
									<PlanOutline
										loading={templateLoading}
										variableValues={variableValues}
										loadData={this.loadData}
										scrollToSection={this.scrollToSection}
										visibleSection={visibleSection}
										visibleSubsection={visibleSubsection}
										sectionsPerPage={sectionsPerPage}
										setPage={this.setPage}
										sidebarRef={this.sidebarRef}
										viewMode={viewMode}
									/>
								</div>
							</section>
						)}
						{panel === 'Variable fields' && (
							<section className="variables-tab-container">
								<VariablesFields
									variables={allVariables}
									templateId={template.id}
									loadData={this.loadData}
								/>
							</section>
						)}
					</div>
				</div>
			</DistrictTemplateProvider>
		);
	}
}

DistrictTemplateDetails.propTypes = {
	match: PropTypes.object,
	route: PropTypes.object,
	history: PropTypes.object,
	dispatch: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(DistrictTemplateDetails);
