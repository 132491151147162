import React from 'react';
import { Checkbox as BPCheckbox } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import './css/Checkbox.scss';

export const Checkbox = ({
	label,
	onChange,
	checked = false,
	disabled,
	size, // small | medium | large
	bordered = false,
	color = 'primary', // primary | success | error | gray | orange
	...props
}) => {
	const availableColors = ['primary', 'success', 'error', 'gray', 'orange'];
	const checkboxColor = availableColors.includes(color) ? color : 'primary';
	const checkboxClass = `checkbox-item size-${size ? size : 'small'} ${
		bordered ? 'bordered' : ''
	} ${!label ? 'no-content' : ''} color-${checkboxColor}`;

	return (
		<BPCheckbox
			className={checkboxClass}
			label={label}
			onChange={onChange}
			checked={checked}
			disabled={disabled}
			{...props}
		/>
	);
};

Checkbox.propTypes = {
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	size: PropTypes.string,
	bordered: PropTypes.bool,
};
