/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import { CardModal } from './CardModal';

export const PublishRequirementsModal = ({
	setModalToShow,
	onActionClick,
	selectedState = {},
	variant = 'state',
}) => (
	<CardModal
		isVisible
		setIsVisible={setModalToShow}
		title="Publish district requirements"
		actionTitle="Publish"
		onActionClick={onActionClick}
	>
		{variant === 'state' ? (
			<p className="modal-text">
				You are about to publish these state requirements for the state
				of {selectedState?.label}. Every district in{' '}
				{selectedState?.label} will see these changes.
			</p>
		) : (
			<p className="modal-text">
				You are about to publish these district requirements for your
				district. Every school in your district will see these changes.
			</p>
		)}
	</CardModal>
);

PublishRequirementsModal.propTypes = {
	setModalToShow: PropTypes.func,
	onActionClick: PropTypes.func,
	selectedState: PropTypes.object,
	variant: PropTypes.oneOf(['state', 'district', null]),
};
