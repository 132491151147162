import React, { useContext } from 'react';
import { Button, ActionsButton } from 'componentsV2';
import PropTypes from 'prop-types';
import { MapsContext } from '../../../Providers';
import { MAP_DETAILS_PAGE } from '../../../consts';

export const ActionsList = ({ floorplan, editPermission, isEditMode, setHasChange, hasChange }) => {
	const MapCTX = useContext(MapsContext);

	return (
		<ActionsButton popoverClassName="map-layers-actions-list">
			<Button
				text="Map Info"
				icon="info-sign"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => MapCTX.getMapDetails(floorplan.id, MAP_DETAILS_PAGE)}
			/>
			{editPermission && !floorplan.dateArchived && isEditMode && (
				<Button
					text="Edit"
					icon="edit"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => MapCTX.showCreateMapModal(true, floorplan)}
				/>
			)}
			{editPermission && !floorplan.dateArchived && (
				<Button
					text="Archive"
					icon="archive"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => MapCTX.showArchiveModal([floorplan], MAP_DETAILS_PAGE)}
				/>
			)}
			{editPermission && floorplan.dateArchived && (
				<Button
					text="Unarchive"
					icon="unarchive"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() => MapCTX.showUnarchiveModal([floorplan], MAP_DETAILS_PAGE)}
				/>
			)}
			<Button
				text="Download"
				icon="cloud-download"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => MapCTX.showDownloadMapModal(floorplan)}
			/>
			{editPermission && !floorplan.dateArchived && isEditMode && (
				<Button
					text="Delete"
					icon="trash"
					size="md"
					type="ghostDefault"
					intent="default"
					wrapperClass="displayBlock"
					className="actionButtonElement"
					onClick={() =>
						MapCTX.showDeleteMapModal(
							[floorplan],
							MAP_DETAILS_PAGE,
							hasChange,
							setHasChange,
						)
					}
				/>
			)}
		</ActionsButton>
	);
};

ActionsList.propTypes = {
	floorplan: PropTypes.object,
};
