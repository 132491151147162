import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
	Button,
	Dialog,
	Classes,
	Icon,
	TextArea,
	Intent,
	RadioGroup,
	Radio,
	MenuItem,
	Tooltip,
} from '@blueprintjs/core';
import { Input } from 'components/Input';
import { LabelText } from 'components/Forms/shared.js';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Select } from '@blueprintjs/select';
import { allStates } from './statelist';
import { allYears } from './yearlist';

const SelectWrap = styled(Select)`
	display: block;
	width: 100%;
	.bp3-popover-target {
		display: block;
		width: 100%;
	}
`;

const HrWrapper = styled.hr`
	widht: 90%;
`;

const CreateDialog = styled(Dialog)`
	width: 300px;
`;

const DropdownWrap = styled.div`
	padding: 10px;
	width: 100%;
	background: #ffffff;
	border: 1px solid #d8e1e8;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
`;

const ProprietaryTextWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const DialogFooterWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 10px;
`;

class EditAssessment extends React.Component {
	constructor(props) {
		super(props);
		const {
			editTemplate: {
				name,
				state,
				year,
				notes,
				isProprietary,
				id,
				createdDate,
			},
		} = this.props;
		this.state = {
			name,
			state,
			year,
			notes,
			proprietary: isProprietary ? isProprietary.toString() : 'false',
			loading: false,
			id,
			createdDate,
		};
	}

	makeStates = item => (
		<MenuItem
			key={item}
			onClick={() => this.updateFields('state', item)}
			text={item}
		/>
	);

	makeYears = item => (
		<MenuItem
			key={item}
			onClick={() => this.updateFields('year', item)}
			text={item}
		/>
	);

	updateFields = (key, val) => this.setState({ [key]: val });

	updateTemplate = () => {
		this.setState({ loading: true });
		const {
			editTemplate: { index },
			updateTemplatesArray,
		} = this.props;
		const {
			name,
			state,
			year,
			notes,
			proprietary,
			id,
			createdDate,
		} = this.state;
		const temp = {
			id,
			createdDate,
			name,
			state,
			year,
			notes,
			isProprietary: proprietary,
		};
		return setTimeout(() => updateTemplatesArray(temp, index), 1000);
	};

	text = (
		<div
			style={{
				height: 40,
				width: 250,
				lineHeight: 1.5,
				fontFamily: 'Nunito Sans',
				fontSize: 12,
				alignContent: 'center',
			}}
		>
			{
				'Marking as proprietary will block clients \n from being able to edit this template'
			}
		</div>
	);

	render() {
		const { closeEdit, isSysAdmin } = this.props;
		const { name, state, year, notes, proprietary, loading } = this.state;
		return (
			<CreateDialog
				isOpen
				onClose={closeEdit}
				title="Edit Template"
				{...this.state}
			>
				<div className={Classes.DIALOG_BODY}>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<Input
								placeholder="Enter template name"
								label="Name"
								large
								onChange={e =>
									this.updateFields('name', e.target.value)
								}
								value={name}
							/>
							<LabelText className="mt-2">State</LabelText>
							<SelectWrap
								items={allStates}
								itemRenderer={this.makeStates}
								className="w-100"
								filterable={false}
							>
								<DropdownWrap>
									<div>{state || 'Select'}</div>
									<Icon icon="caret-down" />
								</DropdownWrap>
							</SelectWrap>
							<LabelText className="mt-2">Year</LabelText>
							<SelectWrap
								items={allYears}
								itemRenderer={this.makeYears}
								className="w-100"
								filterable={false}
							>
								<DropdownWrap>
									<div>{year || 'Select'}</div>
									<Icon icon="caret-down" />
								</DropdownWrap>
							</SelectWrap>
							<LabelText className="mt-2">Notes</LabelText>
							<TextArea
								className="bp3-fill .modifier"
								growVertically={false}
								intent={Intent.NONE}
								placeholder="Enter template notes"
								onChange={e =>
									this.updateFields('notes', e.target.value)
								}
								value={notes}
							/>
							{isSysAdmin && (
								<ProprietaryTextWrapper className="row m-0">
									<LabelText className="mt-2">
										Proprietary template
									</LabelText>
									<div>
										<Tooltip
											className="mt-2"
											content={this.text}
											position="top"
										>
											<Icon
												style={{
													color: '#137CBD',
													marginLeft: 4,
												}}
												icon="help"
											/>
										</Tooltip>
									</div>
								</ProprietaryTextWrapper>
							)}
							{isSysAdmin && (
								<div className="row m-0 ">
									<RadioGroup
										className="row m-0"
										onChange={e =>
											this.updateFields(
												'proprietary',
												e.target.value,
											)
										}
										selectedValue={proprietary}
									>
										<Radio
											style={{ marginRight: 40 }}
											label="Yes"
											value="true"
										/>
										<Radio label="No" value="false" />
									</RadioGroup>
								</div>
							)}

							<HrWrapper />
						</>
					)}
				</div>
				<DialogFooterWrapper>
					<Button
						style={{
							marginRight: 16,
							height: 40,
							width: 68,
						}}
						onClick={closeEdit}
						text="Cancel"
						intent="none"
					/>
					<Button
						style={{
							height: 40,
							width: 68,
							background: '#9F1C3A',
						}}
						onClick={this.updateTemplate}
						text="Update"
						intent="danger"
					/>
				</DialogFooterWrapper>
			</CreateDialog>
		);
	}
}

EditAssessment.propTypes = {
	editTemplate: PropTypes.object,
	updateTemplatesArray: PropTypes.func,
	closeEdit: PropTypes.func,
	isSysAdmin: PropTypes.bool,
};

export default EditAssessment;
