import React, { useState } from "react";
import { Icon } from "@blueprintjs/core";
import PropTypes from 'prop-types';
import { SectionLabel } from "../../";
import '../css/TabPreview.scss';

export const TabPreview = ({
    id,
    title,
    supportText,
    content,
    icon,
    textColor,
    backgroundColor,
    isOpen, 
    setIsOpen
}) => {

    const [isViewed, setIsViewed] = useState(false);

    const onClickHandler = value => {
        setIsOpen(value)
        setIsViewed(true);
    }
    return (
        <div className="tab-preview-container"
            key={id}
            style={{
                color: (textColor ? textColor: undefined),
                backgroundColor: (backgroundColor ? backgroundColor : undefined)   
            }}
        >
            <div className={`tab-header ${isOpen === false ? 'opened' : 'closed'} ${isViewed ? 'viewed' : ''}`}  onClick={() => onClickHandler(id)}>
                <div className="left-side">    
                    {icon && (
                        <div className="left-icon">
                            <img src={icon} className="icon" />
                        </div>
                    )}
                    <div className="text-container">
                        <p className="title"
                            style={{
                                color: (textColor ? textColor: undefined)
                            }}
                        >
                            {title}
                        </p>
                        {supportText && (
                            <span className="support-text"
                                style={{
                                    color: (textColor ? textColor: undefined)
                                }}
                            >
                                {supportText}
                            </span>   
                        )}
                    </div>
                </div>
                <div className="right-side">
                    <Icon icon='chevron-right' 
                        style={{
                            color: (textColor ? textColor: undefined)
                        }}
                    />
                </div>
            </div>
            <div className={`tab-content ${isOpen === true ? 'opened' : ''}`}>
                <SectionLabel
                    title={title}
                    showLeftArrow={true}
                    onClickBackBtn={() => onClickHandler(false)}
                    textColor={textColor}
                    backgroundColor={backgroundColor}
                />
                <section className="section-content">
                    {content}
                </section>
            </div>
        </div>
    )
}

TabPreview.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    supportText: PropTypes.string,
    content: PropTypes.string,
    icon: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
}