import React from "react";
import ActionsButton from "components/ActionsButton";
import { Button } from "components/Button";
import './style.css';
import Swal from "sweetalert2";

const PlanActionButton = ({
    item,
    loadDataCallback,
    editPlanCallback,
    clonePlanCallback,
    viewTemplate
}) => {

    const deletePlan = () => {
		Swal.fire({
			title: 'Delete the template?',
			text: 'This action can not be undone!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Delete',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				const token = JSON.parse(localStorage.getItem('user')).jwt;

				fetch(`${process.env.API_URL}/district-templates/${item.id}`, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
					.then(res => res.json())
					.then(resp => {
						if (resp.statusCode === 200) {
							Swal.close();
							loadDataCallback();
						}
					});
			}
			return Swal.close();
		});
	};

    const archivePlan = () => {
		Swal.fire({
			title: 'Archive plan?',
			text: "Archived plans can't be edited, or attached to binders or flipcharts. Archived plans do not have their fields updated from other places in the system, Are you sure you want to archive this plan?",
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Confirm',
			reverseButtons: true,
            showCloseButton: true,
		}).then(result => {
			if (result.value) {
				const token = JSON.parse(localStorage.getItem('user')).jwt;

				fetch(`${process.env.API_URL}/district-templates/${item.id}/toggle-archive`, {
					method: 'PUT',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
                .then(res => res.json())
                .then(resp => {
                    if (resp.statusCode === 200) {
                        Swal.close();
                        loadDataCallback();
                    }
                });
			}
			return Swal.close();
		});
	};

    return (
        <ActionsButton
            customClass="width-200"
        >
            <Button
                icon="eye-open"
                minimal
                text="View Plan"
                className="w-100 justify-content-start"
                onClick={() => viewTemplate(item.id)}
            />
            { item.hasEditPermissionOnPlanPerSite && (
                <>
                    <Button
                        icon="edit"
                        minimal
                        text="Edit Plan"
                        className="w-100 justify-content-start"
                        onClick={()=> editPlanCallback(item)}
                    />
                    <Button
                        icon="duplicate"
                        minimal
                        text="Clone Plan"
                        className="w-100 justify-content-start"
                        onClick={() => clonePlanCallback(item)}
                    />
                    <Button
                        icon="archive"
                        minimal
                        text="Archive Plan"
                        className="w-100 justify-content-start"
                        onClick={archivePlan}
                    />
                    <hr />
                    <Button
                        icon="trash"
                        intent="Danger"
                        minimal
                        text="Delete Plan"
                        className="w-100 justify-content-start"
                        onClick={deletePlan}
                    />
                </>
            )}
            
        </ActionsButton>
    )
}

export default PlanActionButton;