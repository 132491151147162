import { HttpService } from 'networking/HttpService';
import { DrillDistrictRequirementsService, BuildingsService } from 'services';
import { formatDrills, getStateId } from '../pages/DrillsV2/components/Dashboard/utils';

/**
 * @param {Object} params
 * @param {number} params.year
 * @param {number} [params.buildingId]
 * @param {string} [params.typeId]
 */
export const getDrills = async params => {
	try {
		// Saved drills
		const query = new URLSearchParams(params).toString();
		const savedDrillsResponse = await HttpService({
			method: 'GET',
			route: `/drills?${query}`,
		});
		const savedDrills = savedDrillsResponse.data;

		// Drill requirements
		const allRequirements = await DrillDistrictRequirementsService.getAllRequirements(
			params.year,
			getStateId(),
		);
		const requirementCounts = new Map(
			allRequirements.map(req => [
				req.uuid,
				Math.max(
					req.stateRequirements ? req.stateRequirements : 0,
					req.districtRequirements ? req.districtRequirements : 0,
				),
			]),
		);

		// Format returned drills (saved + unscheduled for each building)
		let allDrills = [];
		const buildingsData = await BuildingsService.getAll(false);
		for (const building of buildingsData) {
			// Get building's saved drills
			const buildingSavedDrills = savedDrills.filter(
				savedDrill => savedDrill.buildingName === building.name,
			);
			const buildingSavedDrillCounts = getSavedDrillCounts(buildingSavedDrills);
			// Generate unscheduled drills from requirements
			let unscheduledDrills = [];
			const currentBuildingRequirements = getRequirementSavedDifference(
				requirementCounts,
				buildingSavedDrillCounts,
			);
			for (const [uuid, count] of currentBuildingRequirements) {
				const requirementInfo = allRequirements.find(req => req.uuid === uuid);
				const unscheduledDrillOfUuid = {
					type: requirementInfo.type,
					site: building.name,
					scheduledDate: null,
					status: 'N/A',
					drillTypeUuid: requirementInfo.uuid,
				};
				const unscheduledDrillsOfUuid = new Array(count >= 0 ? count : 0).fill(
					unscheduledDrillOfUuid,
				);

				unscheduledDrills.push(unscheduledDrillsOfUuid);
			}

			const buildingDrills = [unscheduledDrills, formatDrills(buildingSavedDrills)];
			allDrills.push(buildingDrills.flat(3));
		}

		const indexedDrills = allDrills.flat(2).map((drill, index) => ({
			id: index,
			...drill,
		}));
		return indexedDrills;
	} catch (error) {
		throw error;
	}
};

import { SCHOOL_YEAR_CUTOFF_MONTH, SCHOOL_YEAR_CUTOFF_DAY } from 'utilities/constants';

/**
 * This function is now depricated. All drills are now being returned by school year from the backend.
 * @param {[number, number]} schoolYear
 * @returns {array} Array of drills for provided school year
 */
export const getDrillsBySchoolYear = async schoolYear => {
	const [startYear, endYear] = schoolYear;
	const drillsResponseStartYear = await HttpService({
		method: 'GET',
		route: `/drills?year=${startYear}`,
	});
	const drillsResponseEndYear = await HttpService({
		method: 'GET',
		route: `/drills?year=${endYear}`,
	});

	const startYearCutoffDate = new Date(
		startYear,
		SCHOOL_YEAR_CUTOFF_MONTH - 1,
		SCHOOL_YEAR_CUTOFF_DAY,
	);
	const endYearCutoffDate = new Date(
		endYear,
		SCHOOL_YEAR_CUTOFF_MONTH - 1,
		SCHOOL_YEAR_CUTOFF_DAY,
	);

	const startYearDrills = drillsResponseStartYear.data.filter(
		drill => new Date(drill.scheduledDrillDate.date) > startYearCutoffDate,
	);
	const endYearDrills = drillsResponseEndYear.data.filter(
		drill => new Date(drill.scheduledDrillDate.date) < endYearCutoffDate,
	);

	const schoolYearDrills = [...endYearDrills, ...startYearDrills];
	return formatDrills(schoolYearDrills);
};

// Takes an array of drills and returns a map of drill type uuids to counts
const getSavedDrillCounts = savedDrills => {
	const counts = new Map();
	for (const drill of savedDrills) {
		if (counts.has(drill.drillType.uuid)) {
			// Increment count if the uuid is already in the map
			counts.set(drill.drillType.uuid, counts.get(drill.drillType.uuid) + 1);
		} else {
			// Initialize count for this uuid
			counts.set(drill.drillType.uuid, 1);
		}
	}
	return counts;
};

// Takes a map of requirement uuids to counts and saved drill uuid to counts and returns the difference in a new map
const getRequirementSavedDifference = (requirementCounts, savedDrillCounts) => {
	const differences = new Map();

	for (const [uuid, count] of requirementCounts.entries()) {
		// Get count, defaulting to 0 if not found
		const savedDrillCount = savedDrillCounts.get(uuid) || 0;

		differences.set(uuid, count - savedDrillCount);
	}

	return differences;
};
