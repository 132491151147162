import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownMultiSelect } from '../DropdownMultiSelect';
import { RolesService } from '../../services/RolesService';

const RolesDropdown = ({ 
	value = [],
	onChange,
	visibleOptions = null,
	error = null,
	selectAlllabel = "All",
	allowSelectAll = true,
	options = [],
}) => {
	const [roles, setRoles] = useState([]);

	useLayoutEffect(() => {
		if(options.length > 0) {
			setRoles(options);
		} else {
			RolesService.search().then((roles) => {
				var rolesList = []
				roles?.data.map(role => {
					rolesList.push({
						label: role.title,
						value: role.id
					})
				})
				setRoles(rolesList);
			})
		}
	}, [])
	
	return (
		<DropdownMultiSelect
			label="Role Type"
			placeholder="Select a Role"
			values={value}
			allowSelectAll={allowSelectAll}
			options={roles}
			selectAllLabel={selectAlllabel}
			visibleOptions={visibleOptions}
			onChange={onChange}
			error={error}
		/>
	);
};

RolesDropdown.propTypes = {
	selectedRoles: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};

export { RolesDropdown };
