import React from "react";
import './index.scss';
import PropTypes from 'prop-types';
import { Select } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import { FormLabel, RequiredWraper, WiderButton } from 'components/Forms/shared.js';

const DropdownWithFilterComponent = ({
    label,
    items,
    selectedItem,
    onChange,
    placeholder,
    popoverClassName,
    hideArrow = true,
    isRequired = false,
    itemRender,
    itemPredicate
}) => {

    const defaultItemRender = (item, { handleClick }) => (
        <MenuItem key={item.key} text={item.value} onClick={handleClick} />
    );
    
    const defaultItemPredicate = (query, item) => (
        item.value.indexOf(query) > -1
    )

    return (
        <>
            <div className="DropdownWithFilter__Container">
                { label && (
                    <FormLabel>
                        { label }
                        { isRequired && (<RequiredWraper>Required</RequiredWraper>) }
                    </FormLabel>
                )}
                <Select
                    className="DropdownWithFilter__Select"
                    items={items}
                    itemRenderer={itemRender || defaultItemRender}  // Use passed itemRender or default
                    itemPredicate={itemPredicate || defaultItemPredicate}  // Use passed itemPredicate or default
                    onItemSelect={onChange}
                    popoverProps={{ popoverClassName: popoverClassName, minimal: hideArrow }}
                >
                    <WiderButton
                            style={{ width: '100%' }}
                            rightIcon="caret-down"
                            text={ (selectedItem && selectedItem.label) || placeholder}
                        />
                </Select>
            </div>
        </>
    )
}

DropdownWithFilterComponent.propTypes = {
    label: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
            // and this object can have any other properties
        })
    ).isRequired,
    selectedItem: PropTypes.shape({
        key: PropTypes.any.isRequired,
        label: PropTypes.any.isRequired,
        // and this object can have any other properties
    }),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    popoverClassName: PropTypes.string,
    isRequired: PropTypes.bool,
    itemRender: PropTypes.func,
    itemPredicate: PropTypes.func,
    hideArrow: PropTypes.bool
};
export default DropdownWithFilterComponent;