import React, { useEffect, useState, useContext } from "react";
import { FormModal, Dropdown } from 'componentsV2';
import { BuildingsService, BinderService } from 'services';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from "react-redux";
import { decodeToken } from 'utilities';
import { ToasterContext } from 'pages/App';
import { Intent } from "@blueprintjs/core";
import PropTypes from 'prop-types';

export const AttachToBinder = ({
    closeModal,
    documents
}) => {
    const [modalData, setModalData] = useState({
        modalTitle: '',
        modalLoading: true,
        binderLoading: true,
    })
    
    const [inputErrors, setInputErrors] = useState({
        siteList: false,
        bindersList: false
    })

    const [bindersList, setBindersList] = useState([]);
    const [selectedBinder, setSelectedBinder] = useState(null);
    
    const [sitesList, setSitesList] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null);

    const toaster = useContext(ToasterContext);

    const permissions = useSelector( state => state.route.permissions );
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    const dist = token ? decodeToken(token) : '';
    const districtId = dist ? dist.dist : null;
    const editPermissionOnDistrict = checkPermission('document_edit', permissions, districtId);

    const selectedBuildingId = documents[0]?.buildingId;

    useEffect(async () => {
        if(document.length === 0) {
            return;
        }
        
        if(documents.length === 1) { // single document
            updateModalData({ modalTitle: `Attach "${documents[0].title}" to a binder` })
        } else {
            updateModalData({ modalTitle: `Attach ${documents.length} files to a binder` })
        }

        fetchData().then(() => {
            updateModalData({ modalLoading: false })
        })

    }, [documents])

    const fetchData = async () => {
        updateModalData({ modalLoading: true })
        const buildingsList = await getBuildings();	
        setSitesList(buildingsList);

        let selectedBuilding = (null);
        if (selectedBuildingId) {
            selectedBuilding = buildingsList.find(building => building.id === selectedBuildingId) 
        } else {
            selectedBuilding = null;
        }
        
        await getBinders(selectedBuilding?.id)
        setSelectedSite(selectedBuilding)
        setSelectedBinder(null);
    }

    const getBuildings = () => {

        return BuildingsService.getAll(false).then(serviceBuildings => {
            if(editPermissionOnDistrict) {
                return [
                    {
                        id: 0,
                        name: 'Visible to all'    
                    },
                    ...serviceBuildings
                ];
            }
            return serviceBuildings;
		})
    }

    const getBinders = async(buildingId) => {
        updateModalData({ binderLoading: true })
        let params = {}

        if(buildingId) {
            params = {
                ...params,
                buildingIds: [buildingId]
            }
        }

        BinderService.getAll(params).then(response => {
            if(response.statusCode === 200) {
                setBindersList(response.data)
            }
            updateModalData({ binderLoading: false })
        })
    }

    const updateModalData = updatedValues => {
        setModalData(oldValues => ({
            ...oldValues,
            ...updatedValues
        }));
    }

    const onChangeSiteHandler = value => {
        getBinders(value?.id);
        setSelectedBinder(null);
        setSelectedSite(value);
    }

    const onChangeBinderHandler = value => {
        setSelectedBinder(value);
    }

    const handleConfirmAction = () => {
        updateModalData({ modalLoading: true });

        const contents = documents.map(document => ({
            content_type: 'document',
            content_id: document.id
        }))

        BinderService.attachContentToBinder(contents, selectedBinder.id).then(response => {
            if(response.statusCode === 201) {
                toaster(`You have successfully attached the files to "${selectedBinder.name}"`, Intent.SUCCESS)
                closeModal()
            } else {
                toaster(response.data.message, Intent.DANGER)
            }
        }).catch(err => {
            if(err.error?.fields?.content_id) {
                if(documents.length === 1) {
                    setInputErrors(oldErrors => (
                        {
                            ...oldErrors,
                            bindersList: `File is already attached to "${selectedBinder.name}" binder.`
                        }
                    ))
                } else {
                    const message = err.error.fields.content_id
                    
                    /* Removing every thing from the message execpt the files name */
                    const copiedSubstring = message.slice(message.indexOf(': ') + 2);
                    const endOfTheMessage = ". ";
                    const filesString = copiedSubstring.replace(endOfTheMessage, '');

                    /** Converting files to array to show only the selected  files */
                    const filesArray = filesString.split(', ');
                    const commonFiles = filesArray.filter(title => documents.some(item => item.title === title));
                    const convertedFileString = commonFiles.join(', ');
                    const newMessage = 'The following file(s) are already attached to '+selectedBinder.name +': '+convertedFileString+'.';


                    setInputErrors(oldErrors => (
                        {
                            ...oldErrors,
                            bindersList: newMessage
                        }
                    ))
                }
            } else {
                toaster(err.error.description, Intent.DANGER);
            }
        }).finally(() => {
            updateModalData({ modalLoading: false });
        });
    }

	return (
        <FormModal
            isOpen={true}
            onClose={closeModal}
            title={modalData.modalTitle}
            showCloseButton={true}
            confirmBtn={{
                title: `Add ${ documents.length > 1 ? 'them' : 'it'}`,
                onClick: handleConfirmAction,
                disabled: !selectedBinder
            }}
            cancelBtn={{
                title: 'Cancel',
                onClick: closeModal,
            }}
            loading={modalData.modalLoading}
            size="medium"
            scrollContent={false}
        >
            <div className="w-100">
                <Dropdown
                    label='Site visibility'
                    labelState={false}
                    value={selectedSite}
                    options={sitesList}
                    onChange={onChangeSiteHandler}
                    disabled={selectedBuildingId}
                    optionLabel="name"
                    optionValue="id"
                />
            </div>
            <div className="w-100">
                <Dropdown
                    label={`Binder to put ${ documents.length > 1 ? 'them' : 'it'} in`}
                    labelState={false}
                    value={selectedBinder}
                    options={bindersList}
                    loading={modalData.binderLoading}
                    onChange={onChangeBinderHandler}
                    optionLabel="name"
                    optionValue='id'
                    error={inputErrors.bindersList}
                    errorMessage={inputErrors.bindersList}
                />
            </div>
        </FormModal>
    );
}

AttachToBinder.propTypes = {
    closeModal: PropTypes.func.isRequired,
    documents: PropTypes.array,
}
