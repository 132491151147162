import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MoreActionsButton } from 'components/MoreActionsButton';
import { StatusPilsner } from 'components/StatusPilsner';
import { Table } from 'components/Table';
import { STATUS_MAP } from 'utilities/drills/mockData';

const Wrapper = styled.div`
	table {
		.red {
			color: #9f1c3a;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			font-style: normal;
			margin-right: 10px;
			letter-spacing: 0px;
			font-family: Nunito Sans;
		}

		.grey {
			color: #738694;
			font-size: 12px;
			font-weight: 400;
			line-height: 15px;
			font-style: italic;
			letter-spacing: 0px;
			font-family: Nunito Sans;
		}

		.drill-statuses {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.pilsner {
				max-width: initial;
				margin-left: 0;
				margin-right: 8px;
				margin-bottom: 8px;
			}
		}
	}
`;

export const StatsByBuilding = ({ history, data = null }) => {
	const viewSchool = school => {
		history.push({
			pathname: `/viewSchoolStats/${school.id}`,
			state: {
				buildingId: school.id,
				buildingName: school.name,
			},
		});
	};

	const convertStatus = status => STATUS_MAP[status];

	return (
		<Wrapper>
			<Table
				headers={['School', 'Drill Statuses', 'Actions']}
				data={data}
				renderItem={item => (
					<>
						<td>
							<span className="red">{item.name}</span>
							<span className="grey">
								{/* eslint-disable */}
								{[item.completed, item.required].includes(0)
									? '0'
									: parseInt(
											(item.completed / item.required) * 100,
											10,
									  )}
								% complete
								{/* eslint-enable */}
							</span>
						</td>

						<td className="drill-statuses">
							{Object.keys(STATUS_MAP).map(status => {
								if (item[status] > 0) {
									return (
										<StatusPilsner
											status={convertStatus(status)}
											extraText={` - ${item[status]}`}
										/>
									);
								}
								return null;
							})}
						</td>

						<td>
							<MoreActionsButton
								actions={[
									{
										id: 'view',
										icon: 'eye-open',
										title: 'View school',
										handler: () => viewSchool(item),
									},
								]}
							/>
						</td>
					</>
				)}
			/>
		</Wrapper>
	);
};

StatsByBuilding.propTypes = {
	history: PropTypes.object,
	data: PropTypes.array,
};

export const StatsByMonth = ({ history, data = null }) => {
	const viewMonth = monthName => {
		history.push({
			pathname: `/viewMonthStats/${monthName}`,
			state: {
				monthName,
			},
		});
	};

	const convertStatus = status => STATUS_MAP[status];

	return (
		<Wrapper>
			{Object.keys(data).map(item => (
				<Table
					headers={[item, 'Drill Statuses', 'Actions']}
					data={Object.keys(data[item])}
					renderItem={subItem => (
						<>
							<td>
								<span className="red">{subItem}</span>
							</td>

							<td className="drill-statuses">
								{Object.keys(STATUS_MAP).map(status => {
									if (data[item][subItem][status] > 0) {
										return (
											<StatusPilsner
												status={convertStatus(status)}
												extraText={` - ${
													data[item][subItem][status]
												}`}
											/>
										);
									}
									return null;
								})}
							</td>

							<td>
								<MoreActionsButton
									actions={[
										{
											id: 'view',
											icon: 'eye-open',
											title: 'View month',
											handler: () => viewMonth(subItem),
										},
									]}
								/>
							</td>
						</>
					)}
				/>
			))}
		</Wrapper>
	);
};

StatsByMonth.propTypes = {
	history: PropTypes.object,
	data: PropTypes.array,
};