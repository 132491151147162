import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Popover, Position } from '@blueprintjs/core';

import { Button } from 'components/Button';
import { formatDate } from 'utilities/dates';
import Swal from 'sweetalert2';

const TableRow = ({ 
	item, 
	archive = false, 
	history, 
	archiveTemplate,
	removeTemplate,
}) => {
	const [menu, setMenu] = useState(false);

	const editTemplate = () => history.push(`editplan/${item.id}`);

	const viewTemplate = () => history.push(`viewplan/${item.id}`);

	const editDetails = () => history.push(`editdetails/${item.id}`);

	const cloneTemplate = () => history.push(`clone/${item.id}/core`);

	const archiveTemplateDialog = () => {
		setMenu(false);
		Swal.fire({
			title: 'Archive template?',
			text: 'Are you sure you want to archive this template?',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-warning',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Archive',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				archiveTemplate(item, true);
			}
			return Swal.close();
		});
	};

	const restoreTemplateDialog = () => {
		setMenu(false);
		Swal.fire({
			title: 'Restore template?',
			text: 'Are you sure you want to restore this template?',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-warning',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Restore',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				archiveTemplate(item, false);
			}
			return Swal.close();
		});
	};

	const removeTemplateDialog = () => {
		setMenu(false);
		Swal.fire({
			title: 'Remove template?',
			text: 'Are you sure you want to remove this template?',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Remove',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				removeTemplate(item);
			}
			return Swal.close();
		});
	};

	return (
		<tr key={item.id}>
			<th scope="row">{item.name}</th>
			<td>{item.type}</td>
			<td>{item.site}</td>
			<td>{item.state}</td>
			<td>{item.isProprietary ? 'Yes' : 'No'}</td>
			<td>
				{formatDate(item.createdDate.date, DateTime.DATETIME_SHORT)}
			</td>
			<td>
				<Popover
					enforceFocus={false}
					isOpen={menu}
					position={Position.BOTTOM}
					onClose={() => setMenu(false)}
				>
					<Button
						icon="more"
						text=""
						onClick={() => setMenu(true)}
						minimal
					/>
					<div style={{ width: '234px' }}>
						<div className="pt-2 pb-2">
							{!archive && (
								<>
									<Button
										minimal
										text="Edit template"
										className="w-100 justify-content-start"
										onClick={editTemplate}
									/>
									<Button
										minimal
										text="Edit details"
										className="w-100 justify-content-start"
										onClick={editDetails}
									/>
									<Button
										minimal
										text="Clone"
										className="w-100 justify-content-start"
										onClick={cloneTemplate}
									/>
									<Button
										intent="danger"
										minimal
										text="Archive"
										className="w-100 justify-content-start"
										onClick={archiveTemplateDialog}
									/>
									<Button
										intent="danger"
										minimal
										text="Remove"
										className="w-100 justify-content-start"
										onClick={removeTemplateDialog}
									/>
								</>
							)}
							{archive && (
								<>
									<Button
										minimal
										text="View"
										className="w-100 justify-content-start"
										onClick={viewTemplate}
									/>
									<Button
										minimal
										text="Restore"
										className="w-100 justify-content-start"
										onClick={restoreTemplateDialog}
									/>
								</>
							)}
						</div>
					</div>
				</Popover>
			</td>
		</tr>
	);
};

TableRow.propTypes = {
	item: PropTypes.object,
	history: PropTypes.object,
	archive: PropTypes.bool,
	archiveTemplate: PropTypes.func,
	removeTemplate: PropTypes.func,
};

export { TableRow };
