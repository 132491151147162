import React, { useState } from 'react';
import { FormModal, Input, TextArea } from 'componentsV2';

export const TemplateSectionModal = ({
	sectionModalTitle,
	handleCloseModal,
	isOpen,
	submitSection,
	sectionSubTitle = '',
	confirmBtnText,
	inputLabelText,
	inputLabelPlaceholderText,
	textAreaLabel,
	textAreaPlaceholderText,
	modalProps,
}) => {
	const [sectionData, setSectionData] = useState({
		title: modalProps?.name || '',
		instructions: modalProps?.instructions || '',
	});

	const handleChange = event => {
		const { name, value } = event.target;
		setSectionData({
			...sectionData,
			[name]: value,
		});
	};

	const onSubmitSection = () => {
		return submitSection(sectionData);
	};
	return (
		<FormModal
			isOpen={isOpen}
			title={sectionModalTitle}
			subtitle={sectionSubTitle}
			showCloseButton={false}
			onClose={handleCloseModal}
			scrollContent={false}
			cancelBtn={{ title: 'Cancel', onClick: handleCloseModal }}
			confirmBtn={{
				title: `${confirmBtnText} `,
				onClick: onSubmitSection,
				type: 'primaryDefault',
				disabled: !sectionData.title,
			}}
		>
			<div className="w-100 mt-1">
				<Input
					label={inputLabelText}
					width="100%"
					isRequired
					placeholder={inputLabelPlaceholderText}
					value={sectionData.title}
					onChange={handleChange}
					name="title"
				/>
			</div>
			<div className="w-100 mt-3">
				<TextArea
					label={textAreaLabel}
					value={sectionData.instructions}
					onChange={handleChange}
					name="instructions"
					placeholder={textAreaPlaceholderText}
					hasHintText={true}
					hintText="Directions will help users understand more 
                    information on how to answer this section. For example, 
                    “Directions: Please assess the following areas and answer 
                    each question by selecting: YES, NO, N/A (Not Applicable),
                    or N/AS (Not Assessed) from the drop-down box.”"
				/>
			</div>
		</FormModal>
	);
};
