import React, { createContext, useEffect, useState } from 'react';
import { ACTIVE_ALARMS_TYPE, PAST_ALARMS_TYPE, RapidAlarmsProviders } from '../';
import { Header, ActiveAlarms, PastAlarms } from './components';
import { ActivateAlarmModal } from '../Modals';
import useSelectedSites from 'hooks/useSelectedSites';
import { RapidAlarmService } from 'services';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import { PageHeader, EmptyState, emptyFiles } from 'componentsV2';
import './css/RapidAlarms.scss';

export const AlarmsListContext = createContext();

export const RapidAlarms = ({ history }) => {
	const params = new URLSearchParams(window.location.search);
	const paramAlarmType = params.get('type');
	const [alarmType, setAlarmType] = useState(ACTIVE_ALARMS_TYPE);
	const defaultSelectedSites = useSelectedSites();
	const [alarmSites, setAlarmSites] = useState([]);
	const filterationInitialState = {
		search: '',
		sites: alarmType === PAST_ALARMS_TYPE ? defaultSelectedSites : [],
		startDate: null,
		endDate: null,
		alarmTypes: [],
		alarmModes: [],
		triggeredCellular: false,
	};
	const [showPractice, setShowPracticeModal] = useState(false);
	const permissions = useSelector(state => state.route.permissions);
	const viewPermission = checkPermission('respond_view', permissions) || checkPermission('reunification_view', permissions);

	const exportedData = {
		alarmType,
		setAlarmType,
		filterationInitialState,
		alarmSites,
	};

	const showPracticeModal = (status = true) => {
		setShowPracticeModal(status);
	};

	useEffect(() => {
		if (!viewPermission) {
			return;
		}

		const getActiveAlarmSites = () => {
			RapidAlarmService.getAlarmSites()
				.then(res => {
					if (res.statusCode === 200) {
						const mappedSitesList = res.data.map(site => ({
							id: site.team_id,
							label: site.team_name,
							value: site.team_id,
						}));
						setAlarmSites(mappedSitesList);
					}
				})
				.catch(err => console.error(err));
		};

		getActiveAlarmSites();
	}, []);

	useEffect(() => {
		if (paramAlarmType === 'past') {
			setAlarmType(PAST_ALARMS_TYPE);
		}
	}, [paramAlarmType]);

	if (!viewPermission) {
		return (
			<>
				<PageHeader title="Rapid Alarm" />
				<EmptyState
					icon={emptyFiles}
					className="mt-3"
					header="You are not allowed to view Rapid alarms."
				/>
			</>
		);
	}
	return (
		<AlarmsListContext.Provider value={exportedData}>
			<div className="rapid-alarms-container">
				<RapidAlarmsProviders history={history}>
					<Header showPracticeModal={showPracticeModal} />
					{alarmType === ACTIVE_ALARMS_TYPE && (
						<ActiveAlarms
							filterationInitialState={filterationInitialState}
							history={history}
						/>
					)}
					{alarmType === PAST_ALARMS_TYPE && (
						<PastAlarms
							filterationInitialState={filterationInitialState}
							history={history}
						/>
					)}

					{showPractice && (
						<ActivateAlarmModal
							activeStep="alarmPracticeModal"
							history={history}
							hasCallout={true}
							showPracticeModal={showPracticeModal}
						/>
					)}
				</RapidAlarmsProviders>
			</div>
		</AlarmsListContext.Provider>
	);
};
