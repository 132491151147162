import React from 'react';
import './css/ExistingContactsTable.scss';
import { SearchBar } from 'components/SearchBar';
import xslIconImg from'images/contacts-icon.png';
import BulkContactsHeaderHandler from "./BulkContactsHeaderHandler";
import { Callout, Icon } from '@blueprintjs/core';
import BootstrapedTable from "components/BootstrapedTable";
import { NoResultFound } from 'components/NoResultFound';
import { NoResultFoundIcon } from 'assets/icons';
import { LoadingSpinner } from 'components/LoadingSpinner';
import ContactTooltip from './ContactTooltip';
import { SORTING_DIR } from 'utilities/constants';

const ExistingContactsTable = ({
    contactsList,
    totalRows,
    selectRow,
    selectedRows,
    selectedCallList,
    selectedSites,
    deleteTableRows,
    newSelectedIds,
    dispatchFilterationData,
    filterationData,
    loading
}) => {
    
    const columns = [
        {
            key: 'id', 
            dataField: 'id', 
            text: 'ID', 
            hidden: true
        },
        {
            key: 'familyName', 
            dataField: 'familyName',
            text: 'Name',
            formatter: (value, row) => {
                const rowData =  (row.familyName ? row.familyName  : '')  + (row.familyName != " " && row.familyName != "" && row.givenName !=" " && row.givenName !=""   ? ', ' : '')  + (row.givenName ? row.givenName : '');
                return (row.isLinkedToCallList) ? <ContactTooltip contact={rowData}/>: rowData
                
            },
            classes:'table-users-rows-font',
            sort: true,
            sortCaret: (order, column) => sortingStyle(order, column),
        },
        {
            key: 'title', 
            dataField: 'title',
            text: 'Title',
            classes:'table-users-rows-font',
            sort: true,
            formatter: (value, row) => (row.isLinkedToCallList) ? 
            <ContactTooltip contact={row.title}/>
           : row.title,
            sortCaret: (order, column) => sortingStyle(order, column),
        },
        {
            key: 'accessTypeName', 
            dataField: 'accessTypeName',
            text: 'Account Type',

            classes:'table-users-rows-font',
            sort: true,
            formatter: (value, row) => (row.isLinkedToCallList) ? 
            <ContactTooltip contact={row.accessTypeName}/>
           : row.accessTypeName,
            sortCaret: (order, column) => sortingStyle(order, column),
        }
    ];

    const sortingStyle = (order, column) => {
        if (order === SORTING_DIR.DESC) {
            return <Icon icon="caret-down" />;
        }
        return <Icon icon="caret-up" />;
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
        if (type === 'sort') {
            let sortColumn = sortField;
            switch (sortField) {
                case 'familyName':
                    sortColumn = 'name';
                break;
                case 'title':
                    sortColumn = 'title';
                break;
                case 'accessTypeName':
                    sortColumn = 'accessType';
                break;
            }
            dispatchFilterationData({ type: 'sortKey', payload: sortColumn });
            dispatchFilterationData({ type: 'sortDir', payload: sortOrder });
        }
    };
    return (
        <>
            <div className='contactsContainerWrapper'>
                <div className="row">
                    <div className="col-md-7 vertical-row">
                        <BulkContactsHeaderHandler
                            selectedCallList={selectedCallList}
                            selectedSites={selectedSites}
                        >
                        </BulkContactsHeaderHandler>
                        <p className="search-title">Search</p>
                    
                        <SearchBar
                            placeholder="Search by full name or email" 
                            onSearch={combineSearch =>
                                dispatchFilterationData({
                                    type: 'combineSearch',
                                    payload: combineSearch,
                                })
                            }
                            value={
                                filterationData.combineSearch
                            }
                        />
                        <br/>
                        <p className = "users-count-text">{totalRows} users found</p>
                        {loading && <LoadingSpinner />}
                        {!loading && (

                                <div className='custom-borderd'>
                                    {contactsList.length == 0 ? (
                                        <NoResultFound 
                                            header='No contacts were found'
                                            icon={NoResultFoundIcon}
                                            >
                                        <p> Change search criteria. or go back and create <br/>a new external contact <br /></p>
                                        </NoResultFound>
                                        ) : (
                                        <BootstrapedTable
                                            keyField="id"
                                            data={contactsList}
                                            columns={columns}
                                            bordered={false}
                                            defaultSorted={[{
                                                dataField: 'familyName',
                                                order: SORTING_DIR.ASC, 
                                            }]}
                                            onTableChange = {onTableChange}
                                            selectRow={selectRow}
                                            currentPage={filterationData.page}
                                            rowsPerPage={filterationData.perPage}
                                            setCurrentPage={value =>
                                                dispatchFilterationData({ type: 'page', payload: value })
                                            }
                                            setRowsPerPage={value =>
                                                dispatchFilterationData({ type: 'perPage', payload: value })
                                            }
                                            totalRows={totalRows}
                                            rowClasses= {(row) => {
                                                if (row.isLinkedToCallList) {
                                                    return 'disabled-row';
                                                } else if (newSelectedIds.includes(row.id)) {
                                                    return 'highlighted-row';
                                                }
                                            }}
                                            sizePerPageList={[10, 25, 50, 100, 200]}
                                            showTotal={false}
                                        />
                                    
                                    )}
                                </div>
                        )}
                    </div>

                    <div className="col-md-5">
                        <p className="added-contact-title"><strong>Contacts to add</strong></p>
                        {(selectedRows.length == 0 ? <>
                            <div className="bulk-contact-container bootstrap-table-container">
                                <div className="buttons-holder">
                                    <img className = 'attachment-img'  src={xslIconImg}  alt=""/>
                                    <p> <strong>No contacts added yet.</strong> </p>
                                    <p> Selected contacts will apear here. </p>
                                </div> 
                            </div> </> :  
                            <>
                                <Callout className="attachment-note" intent="primary">
                                    { <strong> {selectedRows.length} contacts to be added</strong>}
                                </Callout> <div  className="bootstrap-table-container custom-scroll">
                                <table className="selectedRowsTable table sticky-table">
                                    <thead>
                                    <tr >
                                        <th className='existing-contact-table-header'>Name</th>
                                        <th className='existing-contact-table-header'>Title</th>
                                        <th className='existing-contact-table-header'>Account Type</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>{
                                        selectedRows.map((row, index)=>{
                                            return(
                                                <tr key={`${index}${row.id}`}>
                                                    <td><strong>{
                                                        (row.familyName ? row.familyName  : '')  + (row.familyName != " " && row.familyName != "" && row.givenName !=" " && row.givenName !=""   ? ', ' : '')
                                                        +(row.givenName ? row.givenName : '')	} </strong>
                                                    </td>
                                                    <td> <strong>{row.title} </strong></td>
                                                    <td><strong> {row.accessTypeName}</strong></td>
                                                    <td><Icon className="custom-icon" onClick={()=>(deleteTableRows(index))} icon="remove" /></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody> 
                                </table>
                        </div></>
                        )}
                
                    </div> 
                
                </div>
            </div>
        </>
    );
};

export default ExistingContactsTable
