import React from 'react';

export const ChatMessagesContainer = ({
	id = 'chat-messages-container',
	onScroll = () => {},
	children,
}) => {
	return (
		<div id={id} onScroll={onScroll} className="chat-messages-container">
			{children}
		</div>
	);
};
