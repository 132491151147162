import React, { useContext } from 'react';
import {
	ReunificationFilteration,
	ReunificationGuardians,
	ReunificationProvider,
	ReunificationStudents,
} from './';
import { REUNIFICATION_GUARDIANS_LIST, REUNIFICATION_STUDENTS_LIST } from './consts';
import { RollCallDetailsContext } from '../..';
import { RollCallReunificationModals } from '../Modals';

export const Reunification = () => {
	const { reunificationActiveTab, setReunificationActiveTab } = useContext(
		RollCallDetailsContext,
	);

	return (
		<div className="reunification-tab-container">
			<ReunificationProvider>
				<RollCallReunificationModals contentToShow={reunificationActiveTab} />
				<ReunificationFilteration
					reunificationActiveTab={reunificationActiveTab}
					setReunificationActiveTab={setReunificationActiveTab}
				/>
				{reunificationActiveTab === REUNIFICATION_STUDENTS_LIST && (
					<ReunificationStudents />
				)}
				{reunificationActiveTab === REUNIFICATION_GUARDIANS_LIST && (
					<ReunificationGuardians />
				)}
			</ReunificationProvider>
		</div>
	);
};
