import React , {useState, useRef} from "react";
import xslIconImg from'images/xsl-img.png';
import '@babel/polyfill';
import attachmentFile from '!file-loader?name=[name].[ext]!../../../../assets/files/contactsBulkTemplate.xlsx';
import { Icon, Spinner } from '@blueprintjs/core';
import { Button } from 'components/Button';
import uploadErrorIcon from'images/upload-error.png';
import { Callout } from '@blueprintjs/core';
const Step1 = ({setDisableBtns, 
    setChangesOccuredFlag,
    loading, 
    setLoading, 
    setFormData, 
    setUploadText, 
    uploadText ,
    xslContentClass,
    setxslContentClass,
    setXslContent,
    xslImg,
    setXslImg,
    xslContent,
    setUploadLabel,
    uploadLabel
    }) => {
    const hiddenFileInput = useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();

    };

    const fileTypes = ["xls", "xlsx", "application/vnd.ms-excel", "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const [uploadContainerClass, setUploadContainerClass] = useState('uploading-container-success');

    const handleFileChange = (event) => {
        setLoading(true)
        setUploadLabel('Loading...')
        event.preventDefault()
        const file = event.target.files[0];
        setChangesOccuredFlag(1)
        hiddenFileInput.current.value = "";

        const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                const { result } = e.target;
                setTimeout(() => {
                    setLoading(false)
                    setUploadLabel('Import a file')
                    if (result) {
                        if (!fileTypes.includes(file.type)) {
                            setDisableBtns(true)
                            setXslImg(uploadErrorIcon);
                            setxslContentClass('font-upload')
                            setXslContent('Error uploading file');
                            setUploadText('Upload Excel file')
                            setUploadContainerClass('uploading-container-error')
                        } else {
                            setxslContentClass('')
                            setXslImg(xslIconImg);
                            setXslContent(file.name);
                            setUploadText('Change file')
                            setUploadContainerClass('uploading-container-success')
                            setFormData(result)
                            setDisableBtns(false)
                        
                        }
                    }
                }, 800);
            }
             
      };

    return (
        <>
            <div className="bulk-user-container bootstrap-table-container">
                <Callout className="attachment-note" intent="primary">
                    <p style={{marginBottom:0}}><b>Uploaded file should match the Excel template provided by Navigate360: </b>
                    <a href={attachmentFile} download='multiple-contacts-template.xlsx' className='bulk-actions-anchor'>Download Template</a></p>
                </Callout>
                <p className = 'font-bold attachment-import-font'>{uploadLabel}</p>
                <div className={uploadContainerClass}>
                    <div className="buttons-holder">
                        {loading && <Spinner intent ='primary'/>}
                        {!loading && (
                        <>
                            <img className = 'attachment-img'  src={xslImg}  alt=""/>
                            <p className={xslContentClass}>{xslContent}</p>
                            <Button  onClick={handleClick}>  
                                <Icon className='bulk-actions-upload-icon' icon="cloud-upload"/>  
                                <span className='bulk-actions-upload-text'>{uploadText}</span>
                            </Button>
                        </>  
                        )}  
                    </div> 
                </div>   
                <input
                    type="file"
                    accept=".xls,.xlsx"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{display: 'none'}}
                    id='attachInput'
                />         
            </div> 
        </>
    );
};
export default Step1;