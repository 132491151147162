import React from 'react';
import PropTypes from 'prop-types';
import { SiteDropdownSingleSelect } from 'components/SiteDropdownSingleSelect';
import { FormModal, Input, FileUpload, Checkbox } from 'componentsV2';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

export const CreateUpdateMap = ({
	isEdit,
	loading,
	updateFormData,
	createMap,
	updateMap,
	replaceAction,
	deleteFile,
	isDisabled,
	showProgressBar,
	selectedSite,
	closeModal,
	formData,
	validationErrors,
	setHasError,
	isVisibleToAll,
	AddedFromView,
}) => {
	const handleOnSubmit = () => {
		isEdit ? updateMap() : createMap(AddedFromView);
	};
	return (
		<>
			<FormModal
				isOpen={true}
				onClose={() => closeModal()}
				title={isEdit ? 'Edit map image' : 'Add a new image'}
				size="medium"
				loading={loading}
				confirmBtn={{
					title: isEdit ? 'Update' : 'Save',
					onClick: handleOnSubmit,
					disabled: isDisabled,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: closeModal,
				}}
				customClassName={'edit-legend-container map-container-modals'}
				scrollContent={false}
				subtitle={
					isEdit
						? ''
						: 'Images will be added as a layer on the map for the selected site.'
				}
			>
				<div className="w-100">
					<Input
						label="Title"
						name="title"
						isRequired={true}
						width="100%"
						value={formData?.title}
						onChange={e => updateFormData(e, 'title')}
						placeholder="ex. High school - first floor"
						error={!!validationErrors?.title}
						errorMessage={validationErrors?.title}
					/>
				</div>
				{!isEdit && (
					<div className="w-100 mt-3">
						<SiteDropdownSingleSelect
							label={`Site visibility`}
							toAllLabel=""
							selectedSite={selectedSite}
							onChange={e => updateFormData(e, 'site')}
							permission={'maps_edit'}
							isRequired={true}
							error={validationErrors?.site_id}
							errorMessage={validationErrors?.site_id}
							disabled={AddedFromView}
						/>
					</div>
				)}

				<div className="w-100 mt-3">
					<FileUpload
						identifier={`mapKey`}
						allowedFiles={['png']}
						allowedFilesLegend={`PNG (max. ${MAX_FILE_UPLOADER_SIZE}mb)`}
						defaultErrorMessage="Upload failed, please try again"
						maxFileSize={MAX_FILE_UPLOADER_SIZE}
						file={formData?.oldImage}
						label="Map"
						fileForEditing={true}
						showProgressBar={showProgressBar}
						onFileInputChange={e => updateFormData(e, 'img')}
						onDeleteFile={deleteFile}
						showDeleteBtn={true}
						downloadText="Download"
						onReplaceAction={replaceAction}
						showDownloadAction={false}
						showReplaceAction={true}
						onDownloadClickHandler={() => {}}
						disabled={false}
						setHasError={setHasError}
						required={true}
						error={validationErrors?.map_image}
						errorMessage={validationErrors?.map_image}
					/>
				</div>

				<div className="w-100 mt-3">
					<Checkbox
						label={<div>Visible to all sites</div>}
						onChange={() => updateFormData(oldValue => !oldValue, 'checkbox')}
						checked={isVisibleToAll}
						size="small"
					/>
				</div>
			</FormModal>
		</>
	);
};
CreateUpdateMap.propTypes = {
	defaultSelectedSites: PropTypes.array,
	selectedSites: PropTypes.array,
	getMapsList: PropTypes.func,
};
