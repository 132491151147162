import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popover, Position, Switch, Tag } from '@blueprintjs/core';
import { format } from 'date-fns';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';

const PilsnerText = styled.div`
	font-family: Nunito Sans;
	font-style: italic;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #738694;
	margin-left: 10px;
`;

const FirstColumn = styled.h6`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 0;
	color: #9f1c3a;
`;

const StyledTag = styled(Tag)`
	height: 22px;
	background-color: ${props => props.background} !important;
	color: ${props => props.text} !important;
`;

const TableRow = styled.tr`
	opacity: ${props => (props.visible ? 0.5 : 1)};
`;

const SectionsRow = ({ item, assessmentId, history, getData, editAssess }) => {
	const [menu, setMenu] = useState(false);

	const switchVisibility = () => {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		Swal.fire('Saving');
		Swal.showLoading();
		fetch(
			`${process.env.API_URL}/assessments/${assessmentId}/sections/${
				item.id
			}`,
			{
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					isHidden: !item.isHidden,
				}),
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => getData());
				}
			});
	};

	const getTag = isCompleted => {
		let background = '#EBF1F5';
		let text = '#394B59';
		if (isCompleted) {
			background = '#E8F4EF';
			text = '#137CBD';
		}
		return (
			<StyledTag background={background} text={text} round minimal>
				{isCompleted ? 'Completed' : 'In Progress'}
			</StyledTag>
		);
	};

	const exportSection = () => {
		Swal.fire('Exporting');
		Swal.showLoading();

		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/assessments/${assessmentId}/export`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				sectionIds: [item.id],
			}),
		})
			.then(res => res.blob())
			.then(resp => {
				Swal.close();
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				}).then(() => {
					const filename = `${item.name} ${format(
						new Date(),
						'hh-mm-ss a',
					)}`;
					const data = new Blob([resp], {
						type: 'application/pdf',
					});
					const csvURL = window.URL.createObjectURL(data);
					const tempLink = document.createElement('a');
					tempLink.href = csvURL;
					tempLink.setAttribute('download', `${filename}.pdf`);
					tempLink.click();
				});

				// this.setState({ exportLoading: false });
			});
	};

	return (
		<TableRow visible={item.isHidden}>
			<td>
				<FirstColumn>{item.name}</FirstColumn>
			</td>
			<td>
				<div className="d-flex">
					{getTag(item.isCompleted)}

					<PilsnerText style={{ marginTop: 4 }}>
						{item.answerCount} out of {item.questionCount} questions
						answered
					</PilsnerText>
				</div>
			</td>
			<td>
				{item.mostRecentUpdate
					? format(new Date(item.mostRecentUpdate.date), 'MM/dd/yyyy')
					: 'N/A'}
			</td>
			<td>
				<Switch
					checked={!item.isHidden}
					label="Visible"
					className="mb-0"
					disabled={!editAssess}
					onChange={switchVisibility}
				/>
			</td>
			<td>
				<Popover
					enforceFocus={false}
					isOpen={menu}
					position={Position.BOTTOM}
					onClose={() => setMenu(false)}
				>
					<Button
						icon="more"
						text=""
						onClick={() => setMenu(true)}
						minimal
					/>
					<div style={{ width: '234px' }}>
						<div className="pt-2 pb-2">
							<Button
								minimal
								text="View Section"
								className="w-100 justify-content-start"
								onClick={() =>
									history.push(
										`/assessment/${assessmentId}/sections/${
											item.id
										}`,
									)
								}
							/>
							<Button
								minimal
								text="Export section to PDF"
								className="w-100 justify-content-start"
								onClick={exportSection}
							/>
						</div>
					</div>
				</Popover>
			</td>
		</TableRow>
	);
};

SectionsRow.propTypes = {
	item: PropTypes.object,
	assessmentId: PropTypes.string,
	history: PropTypes.object,
	getData: PropTypes.func,
	editAssess: PropTypes.bool,
};

export { SectionsRow };
