import React, { useContext } from 'react';
import { PageHeader } from 'components/PageHeader';
import { Button } from 'componentsV2';
import { RapidAlarmsContext } from '../../Providers';
import { NavLink } from 'react-router-dom';

export const Header = ({ showPracticeModal }) => {
	const { editPermissionOnRapidAlarm, editPermissionOnReunification } = useContext(
		RapidAlarmsContext,
	);

	const subTitle =
		editPermissionOnReunification || editPermissionOnRapidAlarm
			? 'Monitor active and past alarms, conduct drills and training sessions, and set up and manage different alarm types.'
			: 'Active alarm overview and details';

	const RightBtns = () => {
		return (
			<div className="binder-details-actions-container">
				<Button
					key="Practice"
					text="Practice"
					size="lg"
					type="secondaryDefault"
					onClick={() => showPracticeModal()}
				/>

				{editPermissionOnRapidAlarm && (
					<NavLink
						exact
						to={{
							pathname: '/rapid-alarms/settings',
						}}
					>
						<Button
							key="Settings"
							text="Settings"
							icon="cog"
							size="lg"
							type="tertiary"
						/>
					</NavLink>
				)}
			</div>
		);
	};
	return <PageHeader title="Rapid Alarm" subtitle={subTitle} customItem={RightBtns} />;
};
