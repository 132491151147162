import React, { useEffect, useState } from 'react';
import { PageHeader } from 'components/PageHeader';
import { CustomTabs, LoadingSpinner } from 'componentsV2';
import { Tab } from '@blueprintjs/core';
import { Binders, Documents } from './';

export const BindersDocuments = ({ history }) => {
	const [loading, setLoading] = useState(false);

	const tabs = [
		{
			title: 'Binders',
			panel: <Binders history={history} />,
			index: 0,
		},
		{
			title: 'Documents',
			panel: <Documents history={history} />,
			index: 1,
		},
	];

	return (
		<div className="documents-binders-container">
			<PageHeader
				title="Binders & Documents"
				subtitle="Easily create, store, and organize safety information"
			/>
			{loading && <LoadingSpinner />}
			{!loading && (
				<CustomTabs renderActiveTabPanelOnly={true}>
					{tabs.map(tab => (
						<Tab
							id={tab.index}
							key={tab.index}
							title={tab.title}
							disabled={tab.disabled}
							panel={tab.panel}
							fill={true}
							className="CustomTabs__Tab"
						/>
					))}
				</CustomTabs>
			)}
		</div>
	);
};
