import { useEffect, useState } from "react";
import { DocumentService } from "services";
import { Intent } from "componentsV2";
import { Helper } from 'utilities/helper';
import { TYPE_360IMAGE, TYPE_DOCUMENT } from "pages/Documents";

export const WhereItsUsedModalFn = (
    modalName,
    setLoading,
    documentData,
    dispatchDocumentData,
    setModalToShow,
    toaster,
) => {

    const [locationsLoaded, setLocationsLoaded] = useState(false);

    useEffect(() => {
        if (!!documentData.id && !locationsLoaded) {
            setDocumentLocations();
        }
    }, [documentData.id, locationsLoaded]);

    const showWhereItsUsedmodal = (document) => {
        setModalToShow(modalName);
        setLocationsLoaded(false);
        setLoading(true);
        const type = document.type == TYPE_360IMAGE ? TYPE_360IMAGE : TYPE_DOCUMENT;
        dispatchDocumentData({
            ...documentData,
            id: document.id,
            type,
            title: document.title,
        });
    }

    const setDocumentLocations = () => {
        const type = documentData.type == TYPE_360IMAGE ? TYPE_360IMAGE : TYPE_DOCUMENT;
        DocumentService.getDocumentLocations({
            id: documentData.id,
            type
        })
            .then(response => {
                const { data } = response;
                dispatchDocumentData({
                    ...documentData,
                    locations: data,
                });
                setLocationsLoaded(true);
            })
            .catch(error => {
                const errorMessage = error.error.fields?.type || error.error.fields?.title;
                if (errorMessage) {
                    toaster(Helper.capitalizeFirstLetter(errorMessage), Intent.DANGER);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return {
        showWhereItsUsedmodal,
    }
}    