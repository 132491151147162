import React, { useState, useEffect } from 'react';
import { SearchBar } from 'components/SearchBar';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { DropdownMultiSelect } from 'componentsV2';
import { BuildingsService } from 'services';
import { filterSitesPerPermissionCode } from 'utilities/permissions';
import { useSelector } from 'react-redux';

export const Filtration = ({ defaultSelectedSites, setFilterationData, filtrationData }) => {
	const [selectedSites, setSelectedSites] = useState([]);
	const [selectedStatuses, setSelectedStatuses] = useState([]);
	const [nameValue, setNameValue] = useState(filtrationData?.name || '');
	const [sitesData, setSitesData] = useState([]);
	const permissions = useSelector(state => state.route.permissions);

	useEffect(() => {
		getSites();
	}, []);

	const getSites = () => {
		BuildingsService.getAll(false, false).then(serviceBuildings => {
			let sites = filterSitesPerPermissionCode(
				'safety_plans_edit',
				permissions,
				serviceBuildings,
			);
			const enabledSites = sites.filter(site => site.safetyPlanEnabled == true);
			setSitesData(enabledSites);
		});
	};

	const onSearchHandler = name => {
		setNameValue(name);
		setFilterationData(oldData => ({
			...oldData,
			name: name,
		}));
	};

	const onChangeSiteHandler = sites => {
		setFilterationData(oldData => ({
			...oldData,
			buildingIds: sites.map(site => site.value),
		}));

		setSelectedSites(sites);
	};

	const statuses = [
		{ value: 'inCompleted', label: 'Incomplete' },
		{ value: 'isCompleted', label: 'Completed' },
		{ value: 'archived', label: 'Archived' },
	];

	const onChangeStatusHandler = statuses => {
		let selectedStatus = {
			inCompleted: 0,
			isCompleted: 0,
			archived: 0,
		};

		statuses.map(status => {
			selectedStatus[status.value] = 1;
		});

		setFilterationData(oldData => ({
			...oldData,
			...selectedStatus,
		}));

		setSelectedStatuses(statuses);
	};

	return (
		<section className="filtration-container plans-filter-container">
			<div className="left-side">
				<SearchBar
					placeholder="Search by title..."
					icon="search"
					className="search-bar-text"
					onSearch={onSearchHandler}
					value={nameValue}
				/>
			</div>
			<div className="right-side">
				{sitesData.length > 0 && (
					<SitesDropdownMultiSelect
						label={null}
						value={selectedSites}
						placeholder="Select a Site"
						onChange={onChangeSiteHandler}
						visibleOptions={1}
						labelState={false}
						className="sites-mddl"
						allowSelectAll={false}
						sitesList={sitesData}
					/>
				)}
				<DropdownMultiSelect
					placeholder="Filter by status"
					values={selectedStatuses}
					options={statuses}
					onChange={onChangeStatusHandler}
					visibleOptions={1}
				/>
			</div>
		</section>
	);
};
