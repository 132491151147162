import React from 'react';
import { Modal, Button, ModalFooter, TextArea } from 'componentsV2';

import '../css/MapContentsList.scss';
import { Icon } from '@blueprintjs/core';

export const ViewUpdateNoteModal = ({
	note,
	oldNote,
	loading,
	closeModal,
	noteUpdateChangeHandler,
	updateNoteHandler,
	noteSaveBtnDisabled,
	selectedShape,
	mode,
}) => {
	const getFooter = () => {
		const confirmButtonElement = (
			<Button
				text={mode == 'view' ? 'Done' : 'Save'}
				intent="default"
				disabled={mode == 'view' ? false : noteSaveBtnDisabled}
				onClick={mode == 'view' ? closeModal : updateNoteHandler}
			/>
		);

		const cancelButtonElement = (
			<Button text="Cancel" type="tertiary" intent="default" onClick={closeModal} />
		);

		if (mode == 'view') {
			return <ModalFooter confirmButton={confirmButtonElement} />;
		}

		return (
			<ModalFooter confirmButton={confirmButtonElement} cancelButton={cancelButtonElement} />
		);
	};

	return (
		<Modal
			isOpen={true}
			onClose={() => closeModal()}
			title={(mode == 'view' ? 'View' : 'Edit') + ' content for ' + selectedShape.title}
			size="small"
			loading={loading}
			customFooter={getFooter()}
			showCloseButton={false}
			customClassName={'edit-legend-container map-container-modals'}
		>
			<div className="mapContent_wrapper">
				<div className="headlineWrapper">
					<a onClick={closeModal}>
						<Icon className="normal-modal-back-arrow" icon="arrow-left" />
					</a>
					<span>{mode == 'view' ? 'View' : 'Edit'} note</span>
				</div>
				<TextArea
					label="Note"
					isRequired={mode == 'view' ? false : true}
					labelState={mode == 'view' ? false : true}
					onChange={e => noteUpdateChangeHandler(e, oldNote)}
					value={note}
					disabled={mode == 'view' ? true : false}
				/>
			</div>
		</Modal>
	);
};
ViewUpdateNoteModal.propTypes = {};
