/**
 * @file Drills v2 Administration page.
 */
import React from 'react';
import { PageHeader, CardContainer, LinkArrow } from 'componentsV2';

import './css/Administration.scss';

export const Administration = ({ history }) => {
	const breadcrumbItems = [{ label: 'Drills', url: '/drillsV2' }, { label: 'Admin' }];
	const go = path => () => {
		history.push(path);
	};

	const cards = [
		{
			title: 'State requirements',
			icon: 'office',
			key: 'requirements',
			subtitle:
				'Manage state drill log requirements and guidance notes for a school year. Provide instructions on a per-state and per-drill type basis.',
			children: <LinkArrow onClick={go('/drillsV2/admin/requirements')}>Manage</LinkArrow>,
		},
		{
			title: 'Drill log fields',
			icon: 'multi-select',
			key: 'fields',
			subtitle: 'Add custom fields to drills and allow organizations to record custom data.',
			children: <LinkArrow onClick={go('/drillsV2/admin/fields')}>Manage</LinkArrow>,
		},
		{
			title: 'Drill types',
			icon: 'envelope',
			key: 'types',
			subtitle: 'Provide unique drill types to an organization.',
			children: <LinkArrow onClick={go('/drillsV2/admin/types')}>Manage</LinkArrow>,
		},
	];
	return (
		<div className="drills-admin">
			<PageHeader {...{ breadcrumbItems }} title="Administration" />
			<div className="cards">
				{cards.map(props => (
					<CardContainer {...props} />
				))}
			</div>
		</div>
	);
};

export default Administration;
