import React from 'react';
import { Modal, ModalFooter } from '../';
import { Button } from '../../';

import PropTypes from 'prop-types';

export const FormModal = ({
	confirmBtn = {
		title: 'Save',
		type: null,
		onClick: () => {},
		disabled: false,
	},
	cancelBtn = {
		title: 'Cancel',
		type: null,
		onClick: () => {},
	},
	loading = false,
	leftFooter = null,
	...props
}) => {
	const cancelButton = (
		<Button
			text={cancelBtn.title}
			type={cancelBtn.type || 'tertiary'}
			size="lg"
			onClick={cancelBtn.onClick}
		/>
	);

	const confirmButton = (
		<Button
			text={confirmBtn.title}
			type={confirmBtn.type || 'primaryDefault'}
			size="lg"
			onClick={confirmBtn.onClick}
			disabled={confirmBtn.disabled}
			className={confirmBtn?.className}
		/>
	);

	const customFooter = (
		<>
			{!loading && (
				<ModalFooter
					confirmButton={confirmButton}
					cancelButton={cancelButton}
					leftContent={leftFooter}
				/>
			)}
		</>
	);

	return <Modal customFooter={customFooter} loading={loading} {...props} />;
};

FormModal.propTypes = {
	confirmBtn: PropTypes.object,
	cancelBtn: PropTypes.object,
	loading: PropTypes.bool,
};
