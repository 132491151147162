import React, { useContext } from 'react';
import { Button } from 'componentsV2';
import { MapsContext } from '../MapsProvider';

export const CreateMapBtn = () => {
	const { showCreateMapModal } = useContext(MapsContext);
	return (
		<Button
			size="md"
			text="New image"
			icon="plus"
			intent="default"
			onClick={() => showCreateMapModal(false, null)}
		/>
	);
};
