import emptyDocument from './emptyDocument.svg';
import emptyFiles from './emptyFiles.svg';
import emptyFolder from './emptyFolder.svg';
import fonts from './fonts.svg';
import maps from './maps.svg';
import noResult from './noResult.svg';
import childrenGroup from './childrenGroup.svg';
import safetyplan from './safetyplan.svg';
import heartbroken from './heartbroken.svg';
import link from './link.svg';
import illustrationalarm from './illustrationalarm.svg';
import assessEmptyState from './assessEmptyState.svg';
import messagesEmptyState from './messages.svg';
import myClasses from './myClasses.svg';
import noResultFoundIcon from './noResultFoundIcon.svg';
import emptyDataIcon from './emptyDataIcon.svg';

export {
	emptyDocument,
	emptyFiles,
	emptyFolder,
	fonts,
	maps,
	noResult,
	childrenGroup,
	heartbroken,
	safetyplan,
	link,
	illustrationalarm,
	assessEmptyState,
	messagesEmptyState,
	myClasses,
	noResultFoundIcon,
	emptyDataIcon,
};
