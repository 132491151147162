import React from 'react';
import { GuardianDetails } from './Shared';
import { GuardianWithStudentsTable } from './';
import { Button, EmptyState, myClasses } from 'componentsV2';
import { CHECKIN_STEPS } from '../consts';

import './css/GuardianWithStudentsStep.scss';

export const GuardianWithStudentsStep = ({
	formData,
	selectedStudents,
	onChangeModalDetails,
	setSelectedStudents,
	currentSelectedStudent,
	setCurrentSelectedStudent,
	selectedGuardian,
	addAsNew,
	manualCheckIn,
}) => {
	const goToStudentsList = () => {
		setCurrentSelectedStudent(null);
		onChangeModalDetails({ activeStep: CHECKIN_STEPS.ADD_STUDENTS_STEP });
	};
	return (
		<div className="GuardianWithStudsents__Wrapper">
			<GuardianDetails
				formData={formData}
				selectedGuardian={selectedGuardian}
				addAsNew={addAsNew}
			/>
			<div className="GuardianWithStudsents__Content">
				{selectedStudents.length == 0 && (
					<EmptyState
						header="No students found for this guardian"
						icon={myClasses}
						showCircles={false}
					/>
				)}
				{selectedStudents.length != 0 && (
					<GuardianWithStudentsTable
						selectedStudents={selectedStudents}
						setSelectedStudents={setSelectedStudents}
						goToStudentsList={goToStudentsList}
						currentSelectedStudent={currentSelectedStudent}
						setCurrentSelectedStudent={setCurrentSelectedStudent}
						manualCheckIn={manualCheckIn}
					/>
				)}
				{manualCheckIn ? (
					<Button
						icon={'plus'}
						text="Add another student"
						type="ghostPrimary"
						size="md"
						onClick={goToStudentsList}
					/>
				) : (
					<br />
				)}
			</div>
		</div>
	);
};
