import React, { useEffect, useState } from 'react';
import { PageHeader } from 'components/PageHeader';
import { BinderService } from 'services/BinderService';
import {
	BINDER_EDIT_PATH,
	BINDER_VIEW_PATH,
	BinderModalsProvider,
	BinderDetailsActions,
	BinderContent,
	DetailsSection,
	PrintSection,
} from '..';
import { LoadingSpinner, EmptyState, emptyFiles } from 'componentsV2';
import './css/BinderDetails.scss';

export const BinderDetails = ({ match, viewMode, history }) => {
	const [loading, setLoading] = useState(true);
	const [binderDetails, setBinderDetails] = useState(null);
	const [filteredContent, setFilteredContent] = useState(false);
	const [filterationData, setFilterationData] = useState({
		name: '',
		types: [],
	});
	const id = match.params.id;
	const [errorMessage, setErrorMessage] = useState(false);

	useEffect(() => {
		setLoading(true);
	}, []);

	useEffect(() => {
		getBinderDetails(id);
	}, [filterationData]);

	const reloadBinderDetails = id => {
		setLoading(true);
		getBinderDetails(id);
	};

	const getBinderDetails = id => {
		BinderService.getBinderDetails(id, filterationData)
			.then(response => {
				if (response.statusCode === 200) {
					const binder = response.data;
					setBinderDetails(binder);
					if (binder.hasEditAccess) {
						const isArchived = binder.archivedByUser;
						if (isArchived && !viewMode) {
							history.push(`/${BINDER_VIEW_PATH}/${id}`);
						} else if (!isArchived && viewMode) {
							history.push(`/${BINDER_EDIT_PATH}/${id}`);
						}
					} else if (!binder.hasEditAccess && !viewMode) {
						history.push(`/${BINDER_VIEW_PATH}/${id}`);
					}
                    setFilteredContent(filterationData.name || (filterationData.types && filterationData.types.length > 0));

                }
			})
			.catch(error => {
				setErrorMessage(error.error.description);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const breadcrumbItems = [
		{
			label: 'Binders & Documents',
			url: '/binders',
		},
		{
			label: 'Binder details',
		},
	];

	const headerCustomItem = () => {
		return <BinderDetailsActions binder={binderDetails} viewMode={viewMode} />;
	};

	return (
		<BinderModalsProvider history={history} reloadBinderDetails={reloadBinderDetails}>
			<div className="binderDetails_wrapper">
				<PageHeader
					title={binderDetails?.name}
					breadcrumbItems={breadcrumbItems}
					customItem={headerCustomItem}
				/>
				{loading && <LoadingSpinner />}
				{!loading && !binderDetails && (
					<EmptyState header={errorMessage} icon={emptyFiles} />
				)}
				{!loading && binderDetails && (
					<>
						<div className="binder-details-container">
							<div className="content-container">
								<BinderContent
									binderDetails={binderDetails}
									filterationData={filterationData}
									setFilterationData={setFilterationData}
									filteredContent={filteredContent}
									viewMode={viewMode}
									reloadBinderDetails={reloadBinderDetails}
								/>
							</div>
							<div className="detail-container">
								<DetailsSection
									viewMode={viewMode}
									binder={binderDetails}
									onEditSubmit={reloadBinderDetails}
								/>
								<PrintSection binder={binderDetails} viewMode={viewMode} />
							</div>
						</div>
					</>
				)}
			</div>
		</BinderModalsProvider>
	);
};
