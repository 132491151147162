import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LoadingSpinner, PageHeader } from 'componentsV2';
import { CallListService } from 'services/CallListService';
import {
	CallListTable,
	CallListFilteration,
	CallListCreateBtn,
	CallListEmptyState,
} from './Components';
import { Intent } from '@blueprintjs/core';
import { ToasterContext } from 'pages/App';
import { CallListContext } from './Providers';

export const CallList = props => {
	const [loading, setLoading] = useState(true);
	const [callList, setCallList] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [emptyCallList, setEmptyCallList] = useState(false);
	const toaster = useContext(ToasterContext);
	const { filterationData, reloadCallLists } = useContext(
		CallListContext,
	);

	const fetchCallList = useCallback(() => {
		// get call lists data
		const params = {
			page: filterationData.page,
			perPage: filterationData.perPage,
			title: filterationData.title,
			sortKey: filterationData.sortKey,
			sortDir: filterationData.sortDir,
			selectedBuilders: filterationData.selectedSites.map(site => site.value),
		};
		CallListService.getAll(params)
			.then(resp => {
				if (resp.statusCode === 200) {
					setCallList(resp.data);
					setTotalRows(resp._metadata.totalRecords);
					setEmptyCallList(isFirstRender && resp._metadata.totalRecords === 0);
				}
			})
			.catch(err => {
				toaster(err.error.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				setIsFirstRender(false);
			});
	}, [filterationData, isFirstRender]);

	useEffect(() => {
		fetchCallList();
	}, [filterationData, reloadCallLists]);

	return (
		<div className="callListContainer">
			<PageHeader
				title="Call Lists"
				subtitle="Create call lists to ensure staff know who to call in the event of an emergency or if a question arises."
				customItem={() => !emptyCallList && <CallListCreateBtn />}
			/>
			{loading && <LoadingSpinner />}
			{!loading && (
				<>
					{emptyCallList && (
						<CallListEmptyState
							header="No call list were added yet"
							description="Add a contact list to your Emergency Management Solution."
							emptyDataState
						>
							<CallListCreateBtn />
						</CallListEmptyState>
					)}
					{!emptyCallList && (
						<>
							<CallListFilteration />
							{callList.length === 0 ? (
								<CallListEmptyState
									header="No call list were found"
									description="Your search didn’t match any call lists. Please try again or add a new call list."
								/>
							) : (
								<CallListTable
									callList={callList}
									totalRows={totalRows}
									history={props.history}
								/>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};
