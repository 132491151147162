import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from '@blueprintjs/core';
import Swal from 'sweetalert2';

class RadioQuestion extends React.Component {
	constructor(props) {
		super(props);
		const { item } = this.props;
		let sValue = null;
		if (item.answer && item.answer.response) {
			sValue = JSON.parse(item.answer.response)[
				Object.keys(JSON.parse(item.answer.response))[0]
			];
		}
		this.state = {
			selectedValue: sValue,
		};
	}

	handleChange = e => {
		this.setState({ selectedValue: e.target.value });
		Swal.fire('Saving');
		Swal.showLoading();
		const { templateId, questionId } = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${
				process.env.API_URL
			}/assessments/${templateId}/questions/${questionId}/answers`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					response: {
						yn: e.target.value,
					},
				}),
			},
		)
			.then(res => res.json())
			.then(() => {
				window.location.reload();
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				});
			});
	};

	render() {
		const { options } = this.props;
		const { selectedValue } = this.state;
		return (
			<RadioGroup
				label=""
				onChange={this.handleChange}
				selectedValue={selectedValue}
			>
				{options.map((item, index) => (
					<Radio
						label={item}
						value={item}
						key={`radioOption-${index}`}
					/>
				))}
			</RadioGroup>
		);
	}
}

RadioQuestion.propTypes = {
	templateId: PropTypes.any,
	questionId: PropTypes.any,
	options: PropTypes.any,
	item: PropTypes.object,
};

export default RadioQuestion;
