import React from "react";
import { Icon } from '@blueprintjs/core';
import { Button } from 'components/Button';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import { allowedExtensions } from 'utilities';
import { Callout } from 'components/Callout';

const ContentBody = ({children, ...props}) => (
    <div className="content-wrap" {...props}>
        {children}
    </div>  
);

const DocumentBody = ({template}) => {

    const fileInfo = template?.valueFile?.id ? template.valueFile : template?.baseFile;
    const reUpload = template?.reupload;

    return (
        <ContentBody style={{textAlign: 'center'}}>
            {fileInfo?.name ?
                <div className="big-icon">
                    <Icon icon="document" />
                    <p className="file-name">
                        {fileInfo.name}
                    </p>
                    <p className="file-size">
                        {(fileInfo.size / 1000000).toFixed(2)} MB
                    </p>
                </div>
                :
                <>
                {!reUpload && (
                    
                    <div className="big-icon light">
                        <Icon icon="document" />
                        <p>Drag and drop a file here, or{' '} <a href="#" >browse</a></p>
                        <p>{allowedExtensions}</p>
                    </div>
                )}
                {reUpload && (
                    <>
                    <p>Upload your document</p><p>{allowedExtensions}</p>
                    <Button
                        intent="primary"
                        rightIcon="arrow-top-right"
                        text="Upload Document"
                        onClick={() => {}}
                    />
                    </>
                )}
                </>
            }
        </ContentBody>
    );
}

const HtmlBody = ({content, editorRef, hasEditor}) => {
    
    if(hasEditor === true) {
        return (
            <Editor
                ref={editorRef}
                initialValue={content}
                onInit={(evt, editor) => {
                    editorRef.current = editor;
                    return null;
                }}
                init={{
                    height: '60vh',
                    menubar: false,
                    statusbar: false,
                    branding: false,
                    resize: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code table code',
                    ],
                    toolbar:
                        'undo redo formatselect bold italic alignleft aligncenter alignright justify bullist numlist ' +
                        'table variableMenu updateMode code'
                }}
            />
        );
    }

    return (
        <ContentBody>
            <div
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
        </ContentBody>
    )
}

const AlertType = ({type, children}) => (
    <Callout type={type} wrapperStyles={{marginTop: 0}}>
        {children}
    </Callout>    
);

DocumentBody.propTypes = {
	template: PropTypes.object,
};

HtmlBody.propTypes = {
    hasEditor: PropTypes.bool
};

AlertType.prototype = {
    type: PropTypes.string.isRequired
}

export { HtmlBody, DocumentBody, AlertType };