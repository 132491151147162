import { HttpService } from 'networking/HttpService';
import { formatDate } from 'utilities/dates';
import { deserializeDrills } from 'utilities/drills/deserializers';

const validateDrillId = drillId => {
	if (!Number.isInteger(drillId) || drillId < 0) {
		throw new Error(`'drillId' needs to be a positive integer/number, not a ${typeof drillId}`);
	}
};
const validateScenarioId = scenarioId => {
	if (!Number.isInteger(scenarioId) || scenarioId < 0) {
		throw new Error(
			`'scenarioId' needs to be a positive integer/number, not a ${typeof scenarioId}`,
		);
	}
};

const getBuildingId = () => {
	const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
	return loggedUserData.primaryBuilding.id;
};

export const DrillLogService = {
	fetchDrillLogFields: () =>
		HttpService({
			method: 'GET',
			route: `/drills/fields?buildingId=${getBuildingId()}`,
		}).then(res => {
			if (Array.isArray(res?.data)) {
				return res?.data?.map(item => {
					if (item.type === 'yesno') {
						return {
							...item,
							type: 'Yes/No',
						};
					}

					return item;
				});
			}

			throw res;
		}),
	createDrillLogField: (fieldName = null, fieldType = null, publicViewable = null) => {
		if (fieldName === null || fieldType === null || publicViewable === null) {
			throw new Error(
				"'fieldName', 'fieldType', and/or 'publicViewable', cannot be null. Please define the parameters",
			);
		}

		return HttpService({
			method: 'POST',
			route: '/drills/fields',
			body: {
				fieldName,
				fieldType,
				publicViewable,
			},
		}).then(res => {
			if (typeof res?.data === 'object') {
				const item = res?.data;
				if (item.type === 'yesno') {
					item.type = 'Yes/No';
				}

				return item;
			}

			throw res;
		});
	},
	createDrillLog: scheduleId => {
		validateDrillId(scheduleId);
		return HttpService({
			method: 'POST',
			route: `/drills/${scheduleId}/logs`,
		}).then(res => {
			if (res?.data?.logId) {
				return res?.data;
			}

			throw res;
		});
	},
	postDrillLog: (scheduleId, scheduledDrillDate, logDrillDate, properties) => {
		validateDrillId(scheduleId);
		if (![scheduledDrillDate, logDrillDate].every(item => !!item)) {
			throw new Error(`You have incorrect parameters for this request`);
		}

		if (properties !== null && ![properties].every(item => !!item)) {
			throw new Error('You have incorrect properties for this request');
		}

		return HttpService({
			method: 'POST',
			route: `/drills/${scheduleId}/post`,
			body: {
				scheduledDrillDate,
				logDrillDate,
				properties,
			},
		}).then(res => {
			if (res?.data?.status?.toLowerCase() === 'completed') {
				return deserializeDrills([res.data])[0];
			}

			throw res;
		});
	},
	addDrillLogToCalendar: (drill = null, year = null, buildingId = null) => {
		if (typeof drill !== 'object' || !Number.isInteger(year) || !Number.isInteger(buildingId)) {
			throw Error(
				"Invalid parameters: 'drill' object, 'year' positive integer, and 'buildingId' positive integer",
			);
		}

		return HttpService({
			method: 'POST',
			route: '/drill-calendar-files',
			body: {
				drillTypeUUID: drill?.typeUuid,
				year,
				buildingId,
			},
		}).then(blob => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `Navigate360 Drills: ${drill?.type} ${formatDate(
				drill?.scheduledDrillDate,
			)}`;
			document.body.appendChild(a);
			a.click();
			a.remove();
		});
	},
	addAllDrillLogToCalendar: (drill = null, year = null, buildingId = null) => {
		if (typeof drill !== 'object' || !Number.isInteger(year) || !Number.isInteger(buildingId)) {
			throw Error(
				"Invalid parameters: 'drill' object, 'year' positive integer, and 'buildingId' positive integer",
			);
		}
		return HttpService({
			method: 'POST',
			route: '/drill-calendar-files',
			body: {
				year,
				buildingId,
			},
		}).then(blob => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'Navigate360 Drills';
			document.body.appendChild(a);
			a.click();
			a.remove();
		});
	},
	unpostDrillLog: scheduleId => {
		validateDrillId(scheduleId);
		return HttpService({
			method: 'PUT',
			route: `/drills/${scheduleId}/unpost`,
		});
	},
	removeDrillLog: scheduleId => {
		validateDrillId(scheduleId);
		return HttpService({
			method: 'DELETE',
			route: `/drills/${scheduleId}`,
		});
	},
	removeDrillLogScenario: scenarioId => {
		validateScenarioId(scenarioId);
		return HttpService({
			method: 'DELETE',
			route: `/drills/scenario/${scenarioId}`,
		});
	},
	getPublicDrillLogs: (year, districtId, buildingId) =>
		HttpService({
			method: 'GET',
			route: `/public/districts/${districtId}/buildings/${buildingId}/drills?year=${year}`,
		}).then(res => {
			if (
				Object.hasOwn(res.data, 'districtName') &&
				Object.hasOwn(res.data, 'buildingName') &&
				Array.isArray(res?.data?.drills)
			) {
				return res.data;
			}

			throw new Error('failed request');
		}),
};
