import React, { useState, useEffect } from 'react';
import { EmptyState, emptyDocument, noResult, MapIcon, videoWrapIcon } from 'componentsV2';
import { CONTENT_TYPE } from './consts';
export const EmptyStatuses = ({ filterationData, noDataAdded, type }) => {
	const [emptyStatusContent, setEmptyStatusContent] = useState({
		title: '',
		description: '',
		icon: '',
	});

	useEffect(() => {
		let title = '';
		let description = '';
		let icon = '';

		switch (type) {
			case CONTENT_TYPE.DOCUMENTS:
				icon = emptyDocument;
				break;
			case CONTENT_TYPE.IMAGE360:
			case CONTENT_TYPE.IMAGES:
				icon = MapIcon;
				break;
			case CONTENT_TYPE.VIDEOS:
				icon = videoWrapIcon;
				break;
		}
		if (noDataAdded === true) {
			title = `No ${type} added`;
			description = `No ${type} have been added yet. Upload a new file to add ${type} to this area.`;
			icon = icon;
		} else {
			title = `No ${type} found`;
			description = `Your search didn’t match any ${type}. Please try again`;
			icon = noResult;
		}
		setEmptyStatusContent({
			title: title,
			description: description,
			icon: icon,
		});
	}, [filterationData]);

	return (
		<EmptyState
			header={emptyStatusContent.title}
			description={emptyStatusContent.description}
			icon={emptyStatusContent.icon}
		/>
	);
};
