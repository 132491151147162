import React from 'react';
import { FeaturedIcon, CardContainer } from 'componentsV2';

export const AlarmIsActiveBanner = () => (
	<CardContainer cardCustomClass="view-alarm-type-container-header">
		<FeaturedIcon type="Primary" icon="info-sign" />
		This alarm is currently active and cannot be edited. Please wait until the alarm concludes
		or deactivate it before making changes.
	</CardContainer>
);
