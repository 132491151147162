import React, { useContext, useReducer, useRef, useEffect } from 'react';
import NormalModal from "components/Modals/NormalModal";
import { Input } from "components/Input";
import { SiteDropdownSingleSelect } from "components/SiteDropdownSingleSelect";
import "./css/NewCallListModal.scss";
import UnsavedChangesModal from "components/Modals/UnsavedChangesModal";
import { ToasterContext } from "pages/App";
import { Intent } from '@blueprintjs/core';
import { FormLabel, RequiredWraper } from 'components/Forms/shared.js';
import { CallListService } from "services/CallListService";
import { Helper } from 'utilities/helper';

const formDataReducer = (formData, action) => {
    switch (action.type) {
        default:
            formData[action.type] = action.payload;
        break;
    }
    return {
        callListName: formData.callListName,
        callListNameEdit: formData.callListNameEdit,
        selectedSite: formData.selectedSite
    }
}

const booleansReducer = (booleans, action) => {
    switch (action.type) {
        default:
            booleans[action.type] = action.payload;
        break;
    }
    return {
        loading: booleans.loading,
        showUnsavedChangesModal: booleans.showUnsavedChangesModal,
        hasChanged: booleans.hasChanged
    }
}

const errorsReducer = (errors, action) => {
    switch (action.type) {
        default:
            errors[action.type] = action.payload;
        break;
    }
    return {
        listNameError: errors.listNameError,
        siteError: errors.siteError
    }
}

const NewCallListModal = (props) => {
    const sites = props.sites;
    const onCloseModal = props.onCloseModal;
    const selectedSites = props.selectedSites;
    const selectedSiteOnLoad = Helper.getSelectedSiteOnLoad(selectedSites, sites);
    const isMounted = useRef(false);
    const history = props.history;

    useEffect(() => {
        isMounted.current = true;
        if(!!props.id) {
            dispatchBoolean({ type: 'loading', payload: true });
            const params = { id: props.id };
            CallListService.getById(params).then(resp => {
                if(resp.statusCode === 200) {
                    dispatchFormData({ type: 'callListName', payload: resp.data.title });
                    dispatchFormData({ type: 'callListNameEdit', payload: resp.data.title });
                    const callListSite = {
                        id: resp.data.site_id,
                        value: resp.data.site_id,
                        label: resp.data.site
                    };
                    dispatchFormData({ type: 'selectedSite', payload: callListSite });
                }
                dispatchBoolean({ type: 'loading', payload: false });
            }).catch(() => {
                dispatchBoolean({ type: 'loading', payload: false });
            })
        }
    
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [errors, dispatchError] = useReducer(errorsReducer, {
        listNameError: {
            isError: false,
            message: ''
        },
        siteError: {
            sError: false,
            message: ''
        }
    });
    const [booleans, dispatchBoolean] = useReducer(booleansReducer, {
        loading: false,
        showUnsavedChangesModal: false,
        hasChanged: false
    });
    const [formData, dispatchFormData] = useReducer(formDataReducer, {
        callListName: '',
        callListNameEdit: '',
        selectedSite: selectedSiteOnLoad
    });
    
    const confirmBtnDisabled = formData.callListName == '' || formData.selectedSite.value == '';
    const toaster = useContext(ToasterContext);

    const cancelBtnClickHandler = () => {
        if(formData.selectedSite.value == selectedSiteOnLoad.value 
            && (formData.callListName == '' || formData.callListName == formData.callListNameEdit) 
            && !booleans.hasChanged) { 
            onCloseModal();
        } else {
            dispatchBoolean({ type: 'showUnsavedChangesModal', payload: true });
        }
    }

    const onConfirmActionHandler = () => {
        dispatchBoolean({ type: 'loading', payload: true });
        
        const postData = {
            name: formData.callListName,
            site_id: formData.selectedSite.id,
            id: props.id || null
        };
        CallListService.save(postData).then(resp => {
            if(!isMounted.current) {
                return;
            }

            if (resp.statusCode === 200 || resp.statusCode === 201) {
                if (! props.id) {
                    toaster("New call list successfully created", Intent.SUCCESS);
                    history.push('/contacts/'+resp.data.id,  { formData });
                    return;
                }

                dispatchFormData({ type: 'selectedSite', payload: [] });
                dispatchFormData({ type: 'callListName', payload: '' });
                toaster("Call list successfully modified", Intent.SUCCESS);
                props.setModalToShow(false);
                props.updateDataCallBack();
                onCloseModal();
            } else {
                toaster(resp.error.description, Intent.DANGER);
            }

            dispatchBoolean({ type: 'loading', payload: false });
        }).catch((error) => {
            if(!isMounted.current) {
                return;
            }
            if(error.statusCode == 400) {
                const errorMessage = '*Call list with same name already exists';
                dispatchError({ type: 'listNameError', payload: { isError: true, message: errorMessage }} );
            } else {
                toaster(error.error.description, Intent.DANGER);
            }
            dispatchBoolean({ type: 'loading', payload: false }) 
        });
    }

    const selectSitesHandler = item => {
        dispatchFormData({ type: 'selectedSite', payload: item });
        dispatchBoolean({ type : 'hasChanged', payload: true });
        if(item.value == '') {
            dispatchError({ type: 'siteError', payload: { isError: true, message: '*Site is required.' }} );      
        } else {
            dispatchError({ type: 'siteError', payload: { isError: false, message: '' }} );      
        }
    }

    const handleInputChange = (e) => {
        dispatchFormData({ type: 'callListName', payload: e.target.value });
        if(!e.target.value) {
            dispatchError({ type: 'listNameError', payload: { isError: true, message: '*Name is required.' }} );      
        } else {
            dispatchError({ type: 'listNameError', payload: { isError: false, message: '' }} );      
        }
    }

    return (
        <>
            {booleans.showUnsavedChangesModal && ( 
                <UnsavedChangesModal
                    onConfirmAction={() => onCloseModal()}
                    onCancelAction={() => dispatchBoolean({ type: 'showUnsavedChangesModal', payload: false }) }
                />
            )}
            <NormalModal
                onConfirmAction={onConfirmActionHandler}
                onCancelAction={cancelBtnClickHandler}
                header={props.id ? "Edit Call List" : "Create Call List"}
                confirmBtnTitle={props.id ? "Save" : "Create"}
                cancelBtnTitle="Cancel"
                confirmBtnDisabled={confirmBtnDisabled}
                loading={booleans.loading}
            >
                <div className="row create-call-list-container">
                    <div className="col-md-12">
                        <div className="form-group">
                            <FormLabel>
                                Call List Name 
                                <RequiredWraper>Required</RequiredWraper>
                            </FormLabel>
                            <Input
                                placeholder="Enter a call list name"
                                onChange={(e) =>{
                                    handleInputChange(e)
                                    }
                                }
                                value={formData.callListName}
                                error={errors.listNameError.isError}
                                errorMessage={errors.listNameError.message}
                                useEllipsisWrapper={true}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <SiteDropdownSingleSelect 
                                selectedSite={formData.selectedSite}
                                isRequired={true}
                                sitesList={sites || []}
                                disabled={!!props.id}
                                onChange={selectSitesHandler}
                                error={errors.siteError.message}
                            />
                        </div>
                    </div>
                </div>
            </NormalModal>
        </>
	);
}

export default NewCallListModal ;