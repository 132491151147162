import React from "react";
import { Breadcrumb } from "./";


export const BreadcrumbPattern = () => {

    const breadcrumbItems = [
        { icon: 'home', url: '/' },
        { label: 'Category', url: '/category' },
        { label: 'Subcategory', url: '/category/subcategory' },
        { label: 'Current Page' },
      ];

   return (
        <Breadcrumb items={breadcrumbItems} />
   );   
}