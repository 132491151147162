import { HttpService } from 'networking/HttpService';

export const SafetyPlanService = {
	getPlan: async id => {
		const { data } = await HttpService({
			method: 'GET',
			route: `/district-templates/${id}`,
		});

		// Parse strings into objects.
		const content = JSON.parse(data.content);
		data?.templateVariables.forEach(({ content }, index) => {
			data.templateVariables[index].content = JSON.parse(content);
		});

		data.bonusFiles = JSON.parse(data.bonusFiles);

		return { ...data, content };
	},
	getToc: async id =>
		HttpService({
			method: 'GET',
			route: `/district-templates/${id}/toc`,
		}),
	getVariableValues: id =>
		HttpService({
			method: 'GET',
			route: `/district-templates/${id}/variablevalues`,
		}),
	getFiles: (id, fileIds = []) =>
		HttpService({
			method: 'POST',
			route: `/district-templates/${id}/files`,
			body: { fileIds },
		}),
	getFilesAsync: (id, fileIds = []) =>
		Promise.all(fileIds.map(fileId => SafetyPlanService.getFiles(id, [fileId]))),
	getExport: (id, sectionId = null, subsectionId = null) => {
		const query = new URLSearchParams({});

		if (sectionId) query.append('sectionId', sectionId);
		if (subsectionId) query.append('subsectionId', subsectionId);

		return HttpService({
			method: 'POST',
			route: `/district-templates/${id}/export${query.size ? `?${query}` : ''}`,
		});
	},
};
