import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormModal, Input } from 'componentsV2';

export const DrillTypeAddModal = ({ onSubmitFinished, modalIsOpen, setModalIsOpen }) => {
	const [formData, setFormData] = useState({
		disableSubmit: true,
		name: '',
	});

	const checkSave = () => {
		setFormData(prevData => {
			const { name } = prevData;
			const disableSubmit = !name;
			return { ...prevData, disableSubmit };
		});
	};

	const fieldChange = name => event => {
		// Default to direct value.
		let data = event;

		// Target input element?
		if (event?.target) {
			const { target } = event;

			// Checkbox or direct value?
			if (Object.hasOwn(target, 'checked')) {
				data = target.checked;
			} else {
				data = event.target.value;
			}
		}

		setFormData(prevData => ({ ...prevData, [name]: data }));
		checkSave();
	};

	return (
		<FormModal
			isOpen={modalIsOpen}
			onClose={setModalIsOpen}
			showCloseButton
			title="Create new drill type"
			scrollContent={false}
			confirmBtn={{
				title: 'Save',
				onClick: () => {
					setModalIsOpen(false);
					onSubmitFinished(formData);
				},
				disabled: formData.disableSubmit,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: () => setModalIsOpen(false),
			}}
		>
			<>
				<div className="w-100">
					<Input
						label="Name"
						isRequired
						width="100%"
						value={formData.name}
						onChange={fieldChange('name')}
						placeholder="Enter the name of the type..."
					/>
				</div>
			</>
		</FormModal>
	);
};

DrillTypeAddModal.propTypes = {
	onSubmitFinished: PropTypes.func.isRequired,
	modalIsOpen: PropTypes.bool.isRequired,
	setModalIsOpen: PropTypes.func.isRequired,
};
