import React, { useState, useEffect, useContext } from 'react';
import {
	EmptyState,
	emptyFolder,
	noResult,
	Button,
	emptyDataIcon,
	noResultFoundIcon,
} from 'componentsV2';
import { SafetyplansContext } from '../../Providers/SafetyplansProvider';
import './css/EmptyStatuses.scss';

export const EmptyStatuses = ({ filterationData, noDataAdded, createBtn }) => {
	const { handleCreateNewTemplate } = useContext(SafetyplansContext);

	const [emptyStatusContent, setEmptyStatusContent] = useState({
		title: '',
		description: '',
		icon: '',
	});

	useEffect(() => {
		if (noDataAdded === true) {
			setEmptyStatusContent({
				title: 'No templates were added yet',
				description: 'Add a template to your Emergency Management Suite.',
				icon: emptyDataIcon,
			});
		} else {
			setEmptyStatusContent({
				title: 'No safety plan templates found.',
				description:
					'Your search didn’t match any template. Please try again or add a new template.',
				icon: noResultFoundIcon,
			});
		}
	}, [filterationData]);

	return (
		<EmptyState
			header={emptyStatusContent.title}
			description={emptyStatusContent.description}
			icon={emptyStatusContent.icon}
			showCircles={false}
			className={noDataAdded ? '' : 'empty-state-border'}
			bodyClass={'empty-state-body'}
		>
			{noDataAdded && (
				<Button
					icon="plus"
					text="New template"
					className="actionButtonElement"
					onClick={() => handleCreateNewTemplate()}
				/>
			)}
		</EmptyState>
	);
};
