import { DocumentService } from 'services/DocumentService';
import { useState } from 'react';

import { Intent } from 'componentsV2';
import { useSelector } from 'react-redux';
import { decodeToken } from 'utilities';

export const DownloadModalFn = (
	modalToShow,
	loading,
	setLoading,
	documentData,
	dispatchDocumentData,
	closeModal,
	selectedDownloadOption,
	toaster,
	setSelectedDownloadOption,
	setModalToShow,
) => {
	const DOWNLOAD_MODAL = 'downloadDocumentModal';
	const [ids, setIds] = useState([]);
	const [_360Ids, set360Ids] = useState([]);

	const [isMultiple, setIsMultiple] = useState(false);

	const downloadDocumentHandler = (
		isMultiple,
		ids,
		selectedVal,
		documentDetails,
		closeDownloadModal = true,
		_360Ids,
		revisionId = null,
		floorplanId = null,
	) => {
		let asPdf = selectedVal == '1' ? false : true;
		var title = '';
		var timeInsec = Math.round(Date.now() / 1000);

		//handle downloaded file name
		if (!isMultiple || ids.length == 1 || _360Ids.length == 1) {
			title = asPdf
				? documentDetails.title.substring(0, documentDetails.title.lastIndexOf('.')) +
				  '.pdf'
				: documentDetails.title
				? documentDetails.title
				: documentDetails.filename;
		} else {
			const token = JSON.parse(localStorage.getItem('user')).jwt;
			const dist = token ? decodeToken(token) : '';
			const districtId = dist ? dist.dist : null;

			title = districtId + '_' + timeInsec + '.zip';
		}

		setLoading(true);
		DocumentService.download(ids, _360Ids, asPdf, revisionId, floorplanId)
			.then(blob => {
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				const ext = ids.length > 1 ? 'zip' : asPdf ? 'pdf' : documentData.ext;
				link.download = title;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch(err => {
				toaster(err.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				if (closeDownloadModal) {
					closeModal();
				}
			});
	};
	const showDownloadModal = (
		document,
		loadingModal = true,
		revisionId = null,
		floorplanId = null,
	) => {
		setIsMultiple(false);
		set360Ids([]);
		setIds([]);
		setSelectedDownloadOption('1');

		let _360Id = [];
		let id = [];
		if (document.type == '360image') {
			_360Id = [document.id];
			set360Ids([document.id]);
		} else {
			id = [document.id];
			setIds([document.id]);
		}
		dispatchDocumentData({
			id: document.id,
			title: document.title ? document.title : document.filename,
			modalTitle: 'Download 1 file',
			ext: document.extension,
			onConfirmAction: () =>
				downloadDocumentHandler(
					false,
					id,
					1,
					document,
					loadingModal,
					_360Id,
					null,
					floorplanId,
				),
		});

		loadingModal && setModalToShow('automaticDocumentModal');
		downloadDocumentHandler(
			false,
			id,
			1,
			document,
			loadingModal,
			_360Id,
			revisionId,
			floorplanId,
		);

		//USE setModalToShow(DOWNLOAD_MODAL) if you need to give the user an option to download it as PDF
	};

	const handleDownloadOption = ({ target }) => {
		setSelectedDownloadOption(target.value);
		dispatchDocumentData({
			...documentData,
			onConfirmAction: () =>
				downloadDocumentHandler(isMultiple, ids, target.value, documentData, true, []),
		});
	};

	const showMultipleDownloadModal = selectedDocuments => {
		setIsMultiple(true);
		setSelectedDownloadOption('1');
		let automaticDownload = false;
		let AllPdfFiles = true;
		const selectedIds = [];
		const selected360Ids = [];

		selectedDocuments.forEach(item => {
			if (item.extension != 'pdf') {
				AllPdfFiles = false;
			}
			if (item.type != 'Images' && item.type != 'Documents') {
				automaticDownload = true;
			}
			if (item.type == '360image') {
				selected360Ids.push(item.id);
			} else {
				selectedIds.push(item.id);
			}
		});

		if (AllPdfFiles) {
			automaticDownload = true;
		}
		set360Ids(selected360Ids);
		setIds(selectedIds);

		setModalToShow('automaticDocumentModal');
		downloadDocumentHandler(true, selectedIds, 1, documentData, true, selected360Ids);
	};

	return {
		showDownloadModal,
		handleDownloadOption,
		showMultipleDownloadModal,
		setModalToShow,
	};
};
