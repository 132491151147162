import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { stringToVarName } from 'utilities';
import { LoadingSpinner } from 'components/LoadingSpinner';

class EmailVariable extends React.Component {
	state = {
		value: '',
		helpText: '',
	};

	createVar = () => {
		const { templateId, refreshVariables, toggleLoading } = this.props;
		toggleLoading(true);
		const { value, helpText } = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates/${templateId}/variables`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				name: stringToVarName(value),
				content: JSON.stringify({
					helpText,
					name: value,
					typeAnnotation: 'Email',
					type: {
						id: 4,
						value: 'Email Address',
					},
				}),
			}),
		})
			.then(res => res.json())
			.then(() => refreshVariables());
	};

	render() {
		const { toggleCreateDialog, loading } = this.props;
		const { value, helpText } = this.state;
		if (loading) return <LoadingSpinner />;
		return (
			<div className="mt-3">
				<Input
					label="Email Address Variable"
					large
					value={value}
					onChange={e => this.setState({ value: e.target.value })}
					helpText={`Actual variable value: ${stringToVarName(
						value,
					)}`}
				/>
				<div className="mt-2">
					<Input
						placeholder="Help text"
						label="Example help text"
						large
						value={helpText}
						onChange={e =>
							this.setState({ helpText: e.target.value })
						}
						helpText="Help text will appear below the variable input for the end user"
					/>
				</div>
				<div className="d-flex justify-content-end mt-2">
					<Button
						text="Cancel"
						large
						intent="secondary"
						onClick={() => toggleCreateDialog(false)}
					/>
					<Button
						className="ml-2"
						text="Create"
						large
						intent="primary"
						onClick={this.createVar}
					/>
				</div>
			</div>
		);
	}
}

EmailVariable.propTypes = {
	templateId: PropTypes.string,
	refreshVariables: PropTypes.func,
	toggleLoading: PropTypes.func,
	toggleCreateDialog: PropTypes.func,
	loading: PropTypes.bool,
};

export default EmailVariable;
