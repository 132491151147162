import React from 'react';
import { Menu, MenuItem, Radio } from '@blueprintjs/core';
import { RadioButtonGroup, ActionsButton, Button, CheckboxGroup, Checkbox } from 'componentsV2';
import { FilterMenuItem, FilterMenuOption } from './components';
import './css/FilterMenu.scss';

export const FilterMenu = ({ items = [], getFilterValue = () => {}, disabled = false }) => {
	const OPTIONS_TO_SHOW = 2;

	const getItemSubtitle = item => {
		let subtitle = '';
		if (item.subtitle && item.subtitle.length > 0) {
			subtitle = item.subtitle;
		} else {
			if (item.options && item.options.length > 0) {
				let optionLabels = [];
				item.options.slice(0, OPTIONS_TO_SHOW).forEach(option => {
					optionLabels.push(option.label);
				});
				if (item.options.length > OPTIONS_TO_SHOW) {
					optionLabels.push(`+${item.options.length - OPTIONS_TO_SHOW}`);
				}
				subtitle = optionLabels.join(', ');
			}
		}
		return subtitle;
	};

	return (
		<ActionsButton
			elementSelector={
				<Button
					type="tertiary"
					size="md"
					text="Filters"
					icon="horizontal-bar-chart-desc"
					intent="default"
					disabled={disabled}
				/>
			}
			closeOnClick={false}
			disabled={disabled}
		>
			<div className="filter-menu-items-wrapper">
				<Menu key="menu">
					{items.map((item, index) =>
						item.options && item.options.length > 0 ? (
							<MenuItem
								key={index}
								text={
									<FilterMenuItem
										title={item.title}
										subtitle={getItemSubtitle(item)}
										hasChildren={item.options?.length}
									/>
								}
							>
								{item.multipleSelect ? (
									<CheckboxGroup className={'filter-menu-multi-select'}>
										{item.options.map((option, optionIndex) => {
											let values = getFilterValue(item.name);
											return (
												<Checkbox
													key={`${item.name}-${optionIndex}`}
													label={option.label}
													onChange={() =>
														item.onchange(
															item.name,
															option.value,
															'multiple',
														)
													}
													checked={
														values
															? values.includes(option.value)
															: false
													}
												/>
											);
										})}
									</CheckboxGroup>
								) : (
									<RadioButtonGroup
										onChange={e => item.onchange(item.name, e.target.value)}
										selectedValue={getFilterValue(item.name)}
									>
										{item.options.map((option, optionIndex) => (
											<Radio
												key={`${item.name}-${optionIndex}`}
												label={option.label}
												value={option.value}
											/>
										))}
									</RadioButtonGroup>
								)}
							</MenuItem>
						) : (
							<FilterMenuOption
								key={index}
								title={item.title}
								content={item.content}
							/>
						),
					)}
				</Menu>
			</div>
		</ActionsButton>
	);
};
