import React, { useContext } from 'react';
import { ActionsButton, Button } from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import { USER_ACCESS_TYPES } from 'utilities/constants';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ContactsContext } from '../Providers';
import { CALLLIST_ROUTES } from 'utilities/constants';

const ContactsListActionsButtons = ({
	userEditPermission,
	userViewPermission,
	callListEditPermission,
	row,
	callListId,
}) => {
	//view permissions per contact/user
	const contactHasViewPermission =
		row.accessType === USER_ACCESS_TYPES.OUTSIDE_USER && !callListEditPermission;
	const userHasViewPermission =
		row.accessType !== USER_ACCESS_TYPES.OUTSIDE_USER &&
		!userEditPermission &&
		userViewPermission;

	//edit permissions per contact/user
	const contactHasEditPermission =
		row.accessType === USER_ACCESS_TYPES.OUTSIDE_USER && callListEditPermission;
	const userHasEditPermission =
		row.accessType !== USER_ACCESS_TYPES.OUTSIDE_USER && userEditPermission;

	const { setModalToShow, setSelectedContact, setContactID, handleViewEditClick } = useContext(
		ContactsContext,
	);

	return (
		<>
			<ActionsButton>
				{(contactHasViewPermission || userHasViewPermission) &&
					(row.accessType == USER_ACCESS_TYPES.OUTSIDE_USER ? (
						<NavLink
							className="bp3-button bp3-minimal w-100 justify-content-start"
							to="#"
						>
							<Icon icon="eye-open" minimal />
							<span
								className="bp3-button-text"
								onClick={() => handleViewEditClick('view', row.id)}
							>
								View
							</span>
						</NavLink>
					) : (
						<NavLink
							exact
							className="bp3-button bp3-minimal w-100 justify-content-start"
							to={{
								pathname: `/users/view/${row.id}`,
								state: { callList: callListId, route: CALLLIST_ROUTES.CONTACTSV2 },
							}}
						>
							<Icon icon="eye-open" minimal />
							<span className="bp3-button-text">View</span>
						</NavLink>
					))}
				{(contactHasEditPermission || userHasEditPermission) && (
					<>
						{row.accessType == USER_ACCESS_TYPES.OUTSIDE_USER ? (
							<Button
								icon="edit"
								onClick={() => handleViewEditClick('edit', row.id)}
								medium
								text="Edit"
								size="md"
								type="ghostDefault"
								intent="default"
								wrapperClass="displayBlock"
								className="actionButtonElement"
							/>
						) : (
							<NavLink
								exact
								to={{
									pathname: `/users/view/${row.id}`,
									state: {
										callList: callListId,
										route: CALLLIST_ROUTES.CONTACTSV2,
									},
								}}
								className="bp3-button bp3-minimal w-100 justify-content-start"
							>
								<Icon icon="edit" minimal />
								<span className="bp3-button-text">Edit</span>
							</NavLink>
						)}

						<Button
							icon="trash"
							text="Delete"
							size="md"
							type="ghostDefault"
							intent="default"
							wrapperClass="displayBlock"
							className="actionButtonElement"
							onClick={() => {
								setSelectedContact(row);
								setContactID(row.id);
								setModalToShow('deleteContact');
							}}
						/>
					</>
				)}
			</ActionsButton>
		</>
	);
};
ContactsListActionsButtons.propTypes = {
	userEditPermission: PropTypes.bool,
	userViewPermission: PropTypes.bool,
	row: PropTypes.object.isRequired,
	callListId: PropTypes.number,
};

export default ContactsListActionsButtons;
