import { HttpService } from 'networking/HttpService';

const GENERIC_ERROR = new Error('An error occurred, please try again');

export const ScenariosCategoriesService = {
  async getAll() {
      return await HttpService({
        method: 'GET',
        route: `/drills/scenario/categories`,
        headers: {
          'Content-Type': 'application/json',
        },
      }).catch(err => {
        throw err;
      });
    },
};
