import React from "react";
import { Button } from "componentsV2";

export const CreateBtn = ({
    onClick
}) => (
    <Button
        size="lg"
        text="New flipchart"
        icon="plus"
        intent="default"
        onClick={onClick}
    />
)