import React from 'react';
import styled from 'styled-components';

const Spinner = styled.div`
	display: block;
	width: 64px;
	height: 64px;
	margin: 0 auto;
	margin-top: 40px;
	&:after {
		content: '';
		display: block;
		width: 46px;
		height: 46px;
		margin: 1px;
		border-radius: 50%;
		border: 3px solid #664aa0;
		border-color: #664aa0 transparent #664aa0 transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}
	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
const Container = styled.div`
	padding: 30px;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100vh;
`;

const AppUpdateModal = () => (
	<Wrapper>
		<div className="container">
			<div className="row justify-content-around">
				<div className="col-6">
					<Container>
						<h4 className="text-center pt-3">
							Just a moment, application is updating
						</h4>
						<Spinner />
					</Container>
				</div>
			</div>
		</div>
	</Wrapper>
);

export { AppUpdateModal };
