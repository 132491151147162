import React from 'react';
import PropTypes from 'prop-types';
import '../css/Color.scss';
import { Icon } from '@blueprintjs/core';
import { Tooltip } from '../../';

export const Color = ({
	colorClass,
	isSelected = false,
	onClick,
	children,
	size = 'md',
	customStyle = {},
	singleColor = false,
	showToolTipe = false,
	tooltipTitle = '',
	...restProps
}) => {
	const WrapperComponent = ({ children }) => (
		<>
			{showToolTipe ? (
				<Tooltip tooltipTitle={tooltipTitle} theme="light">
					{children}
				</Tooltip>
			) : (
				<>{children}</>
			)}
		</>
	);

	return (
		<WrapperComponent>
			<span
				className={
					'color-wrapper ' +
					colorClass +
					' ' +
					size +
					' ' +
					(isSelected ? 'selected' : '')
				}
				onClick={onClick}
				style={customStyle}
				{...restProps}
			>
				{isSelected && !singleColor && (
					<Icon icon="tick" className="icon-tick" size={12.8} />
				)}
				{children}
			</span>
		</WrapperComponent>
	);
};

Color.propTypes = {
	colorClass: PropTypes.string,
	isSelected: PropTypes.bool,
	showToolTipe: PropTypes.bool,
	tooltipTitle: PropTypes.string,
	onClick: PropTypes.func,
	size: PropTypes.string,
	customStyle: PropTypes.object,
	singleColor: PropTypes.bool,
};
