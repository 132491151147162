import React from 'react';
import { ActionsButton, Button } from 'componentsV2';

export const SectionActions = ({ section, depth, onOpenModal }) => {
	return (
		<ActionsButton>
			<Button
				text="Add question"
				icon="add"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => onOpenModal('add_question', { sectionId: section.id })}
			/>
			<Button
				text={depth > 0 ? 'Edit subsection' : 'Edit section'}
				icon="edit"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() => {
					onOpenModal('edit_section', {
						id: section?.id,
						name: section?.name,
						instructions: section?.instructions,
						depth: depth,
					});
				}}
			/>
			<Button
				text={depth > 0 ? 'Copy subsection' : 'Copy section'}
				icon="duplicate"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() =>
					onOpenModal('copy_section_subsection', {
						sectionId: section.id,
						isSection: depth === 0,
					})
				}
			/>
			<Button
				text={depth > 0 ? 'Delete subsection' : 'Delete section'}
				icon="trash"
				size="md"
				type="ghostDefault"
				intent="default"
				wrapperClass="displayBlock"
				className="actionButtonElement"
				onClick={() =>
					onOpenModal('delete_section_subsection', {
						sectionId: section.id,
						isSection: depth === 0,
					})
				}
			/>
		</ActionsButton>
	);
};
