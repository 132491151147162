import React, { useContext } from 'react';
import { Button } from 'componentsV2';
import { MapsContext } from '../../Providers';

export const MapViewHeaderButtons = ({
    floorplan,
    printMap
}) => {

    const MapCTX = useContext(MapsContext);

    return (
        <>
        <Button
            small
            icon="cloud-download"
            type={'tertiary'}
            onClick={() => MapCTX.showDownloadMapModal(floorplan)}
        />
        <Button
            small
            icon="print"
            type={'tertiary'}
            onClick={printMap}
        />
        </>
    )
}