import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import {Icon} from "@blueprintjs/core";
import './css/Accordion.scss';

const AccordionItem = ({title, content, isOpenContent}) => {
  const [isOpen, setIsOpen] = useState(isOpenContent);
  const [isManuallyOpen, setIsManuallyOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isOpenContent);
  }, [isOpenContent]);
  const toggleAccordion = () => {
    setIsManuallyOpen(!isManuallyOpen);
  };

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={toggleAccordion} >
        <div className="title"> {title} </div>
        <div className="icon-container">
          <div className="right-side">
            {(isOpen || isManuallyOpen) && <Icon icon="chevron-up" className="non-border"/>}
            {!(isOpen || isManuallyOpen) && <Icon icon="chevron-down" className="non-border"/>}
          </div>
        </div>
      </div>
      {(isOpen || isManuallyOpen)&& <div className="accordion-content">{content}</div>}
    </div>
  );
};

export const Accordion = ({items}) => {
  return (
    <div className="accordion-container">
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} isOpenContent={item.isOpenContent}/>
      ))}
    </div>
  );
};

Accordion.prototype = {
  items: PropTypes.array
};
