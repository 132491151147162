import { FloorplanService } from "services/FloorplanService";
import { useEffect, useState } from "react";
import { Intent } from "componentsV2";


export const MapInfoFn = (
    closeModal,
    toaster,
    setLoading,
    setModalToShow
) => {
    const [mapDetails, setMapDetails] = useState(null);


    const getMapDetails = (mapId) => {
        setMapDetails(null)
        setLoading(true)
        setModalToShow("MAP_INFO")
        FloorplanService.getMapInfo(mapId).then(res => {
            setMapDetails(res.data.floorplan)
        }).catch(res => {
            toaster(res.error?.description, Intent.DANGER);
            closeModal()
        }).finally(() => {
            setLoading(false)
        })
    
    }

    return {
        getMapDetails,
        mapDetails
    }
}