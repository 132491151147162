import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Toaster, Position, Icon, Intent, Tag } from '@blueprintjs/core';
import { Button } from '../Button';
import { SmallLoading } from 'components/SmallLoading';
import './css/ImagesVariable.scss';

const StyledTag = styled(Tag)`
	background: #faf2f3 !important;
	color: #9f1c3a !important;
	margin-left: 8px;
`;

class ImagesVariable extends React.Component {
	constructor(props) {
		super(props);
		const { item } = this.props;
		this.state = {
			item,
			imageName: null,
			showLoad: false,
			showButton : false,
			imageFile: null,
			hasImage: item.imageInfo?.id ? true : false,
		};
		this.updateVariable()
	}

	setShowLoad = val => this.setState({ showLoad: val });

	failedToUpload = () => {
		this.setState({
			showLoad: false,
			showButton: true,
		})
		this.showToaster('Failed to upload', Intent.DANGER);
	}

	showToaster = (message, intent = 'success') => {
		const toastParams = {
			message,
			intent,
			timeout: 3000,
		};
		this.toaster.show(toastParams);
	};

	updateImageValue = async () => {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { imageFile, item } = this.state;
		const { templateId } = this.props;

		const formDataBody = new FormData();
		formDataBody.append('file', imageFile);
		return await fetch( // upload image to template attachments
			`${process.env.API_URL}/district-templates/${templateId}/upload`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataBody
			},
		)
		.then(res => res.json())
		.then(response => {
			if(response.statusCode === 201) {
				if(item.id == undefined) { // CREATE NEW VARIABLE VALUE
					var url = `${process.env.API_URL}/district-templates/${templateId}/variablevalues`
					var method = 'POST';
				} else { // UPDATE AN EXISTING VARIABLE VALUE
					var url = `${process.env.API_URL}/district-templates/${templateId}/variablevalues/${item.id}`
					var method = 'PUT';
				}

				fetch(url, { // update variable value by image url 
						method: method,
						headers: {
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({
							variable_name: item.variable.name,
							variable_value: `${response.data.id}`,
						}),
					},
				)
				.then(res => res.json())
				.then(updateVarResponse => {
					if(updateVarResponse.statusCode === 201) {
						const { item: oldItem, setIsUpdated } = this.props;
						oldItem.value = updateVarResponse.data.variableValue;
						oldItem.id = updateVarResponse.data.id;
						this.setState({
							item: oldItem,
							showLoad: false,
							showButton: false,
						})
						this.showToaster('Image uploaded successfully');
						setIsUpdated(true);
					} else {
						this.failedToUpload()
					}
				})
				.catch((err) => {
					this.failedToUpload()
				})
			} else {
				this.failedToUpload()
			}
		})
		.catch(() => {
			this.failedToUpload()
		})
	}

	updateVariable = async (e) =>  {
		this.setState({
			showButton: false,
			showLoad: true,
		})
		const { imageFile } = this.state;
		if (imageFile) {
			return await this.updateImageValue()
		}
	};

	deleteVariable = async () => {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		const { item } = this.state;
		const { templateId } = this.props;
		
		if (item.id) {
			this.setState({
				showLoad: true
			})
			if(item.imageInfo?.id) {
				// Delete image from database
				await fetch(`${process.env.API_URL}/district-templates/${templateId}/files/${item.imageInfo.id}`, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
					}
				})
			}
			return await fetch(`${process.env.API_URL}/district-templates/${templateId}/variablevalues/${item.id}`, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then(res => res.json())
			.then(response => {
				if(response.statusCode === 200) {
					const { item: oldItem, setIsUpdated } = this.props;
					oldItem.id = null;
					oldItem.value = null;
					this.setState({
						item: oldItem,
						hasImage: false,
					})
					setIsUpdated(true);
					this.showToaster('Image deleted successfully');
				} else {
					this.showToaster(response.error.description, Intent.DANGER);
				}
				this.setState({
					showLoad: false
				})
			}).catch(err=> {
				this.setState({
					showLoad: false
				})
				this.showToaster(err.error.description, Intent.DANGER);
			})
		}
		return null;
	}

	imageChangeHandler = e => {
		const file = e.target.files[0];
		if (file?.name) {
			this.setState({
				imageFile: file,
				showButton: true
			})
		}
	}

	render() {
		const { item, imageFile, showLoad, showButton, hasImage } = this.state;
		let imageLabel = imageFile ? imageFile.name : (item.imageInfo?.fileName && hasImage ? item.imageInfo.fileName : "Choose File");

		return (
			<div className="mt-3 image-variable-wrapper">
				<Toaster
						ref={ref => {
							this.toaster = ref;
						}}
						position={Position.TOP_RIGHT}
					/>
				<div className="d-flex align-item-center justify-content-between mb-2">
					<p className="mb-0">{item.variable.content.name}</p>
					<StyledTag round>Used in {item.total} places</StyledTag>
				</div>
			 	<div className={hasImage ? "input-group has-image mb-3" : "input-group mb-3"}>
					<input
						type="file"
						className="custom-file-input"
						id="uploadFile"
						onChange={this.imageChangeHandler}
					/>
					<div className='choose-file-wrap'>
						<label htmlFor="uploadFile" className='custom-file-label'>
							{imageLabel}
						</label>
						{hasImage && (
							<div className='delete-btn-icon'>
								<Icon icon='trash' onClick={this.deleteVariable} />							
							</div>
						)}
					</div>
				</div>
				{showLoad && (
					<div className="d-flex justify-content-end">
						<SmallLoading />
					</div>
				)}
				{showButton && !showLoad && (
					<div className="d-flex justify-content-end">
						<Button
							onClick={this.updateVariable}
							text="Save Changes"
							className="btn btn-small"
							intent="primary"
						/>
					</div>
				)}
			</div>
		);
	}
}

ImagesVariable.propTypes = {
	templateId: PropTypes.string,
	item: PropTypes.object,
};

export default ImagesVariable;
