import React from "react";
import { useState } from "react";
import PropTypes from 'prop-types';
import { SearchBar } from "components/SearchBar";
import '../css/BindersContentFilters.scss';
import {TypesList} from "./TypesList";


export const BinderContentFilteration = ({
            filterationData,
            setFilterationData,
}) => {

    const [nameValue, setNameValue] = useState(filterationData?.name || '')
    const onSearchHandler = name => {
        setFilterationData(oldData => ({
            ...oldData,
            name: name
        }));
    }

	return (
        <section className="binder-content-filteration-container">
            <div className="left-side">
                <SearchBar
                    placeholder="Search by name..."
                    icon="search"
                    className="search-bar-text"
                    onSearch={onSearchHandler}
                    value={nameValue}
                />
            </div>
            <div className="right-side">
                <TypesList
                    filterationData={filterationData}
                    setFilterationData={setFilterationData}
                />
            </div>
        </section>
	);
}

BinderContentFilteration.propTypes = {
    setFilterationData: PropTypes.func.isRequired
};