import React, { useEffect, useReducer, useState, useContext } from 'react';
import {
	logoNavigateSwitch,
	logoNavigateDark,
	eyeIcon,
	eyeIconOpen,
	tickIconChecked,
	tickIconNotChecked,
} from 'assets/icons';
import { Button, Input, ToasterContext } from 'componentsV2';
import { Intent } from '@blueprintjs/core';
import { PropTypes } from 'prop-types';
import './css/SetPassword.scss';

const SetPassword = props => {
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
	const [linkExpired, setLinkExpired] = useState(true);
	const [loading, setLoading] = useState(false);
	const [url, setUrl] = useState('');

	const toaster = useContext(ToasterContext);

	const passwordHintsReducer = (passwordHints, action) => {
		switch (action.type) {
			case 'password':
				// at least has one number
				if (/\d/.test(action.payload)) {
					passwordHints.oneNumber = true;
				} else {
					passwordHints.oneNumber = false;
				}

				// at least has one special character
				if (/[!@#$%^&*~_-]/.test(action.payload)) {
					passwordHints.oneCharacter = true;
				} else {
					passwordHints.oneCharacter = false;
				}

				// greater than 8
				if (action.payload && action.payload.length >= 8) {
					passwordHints.eightCharaters = true;
				} else {
					passwordHints.eightCharaters = false;
				}

				if (action.payload === passwordHints.passwordValue) {
					passwordHints.passwordMatches = true;
				} else if (
					action.payload !== passwordHints.passwordValue &&
					action.passwordConfirmation !== undefined
				) {
					passwordHints.passwordMatches = false;
				}

				if (action.payload === action.passwordConfirmation) {
					passwordHints.passwordMatches = true;
				}

				passwordHints.passwordValue = action.payload;
				break;
			case 'passwordConfirmation':
				if (action.payload != '') {
					passwordHints.passwordConfirmationHasValue = true;
				} else {
					passwordHints.passwordConfirmationHasValue = false;
				}

				if (action.payload === passwordHints.passwordValue) {
					passwordHints.passwordMatches = true;
				} else {
					passwordHints.passwordMatches = false;
				}
				break;
			default:
				passwordHints.oneCharacter = false;
				passwordHints.oneNumber = false;
				passwordHints.eightCharaters = false;
				passwordHints.passwordValue = false;
				passwordHints.passwordMatches = true;
				passwordHints.passwordConfirmationHasValue = false;
				break;
		}

		return {
			oneCharacter: passwordHints.oneCharacter,
			oneNumber: passwordHints.oneNumber,
			eightCharaters: passwordHints.eightCharaters,
			passwordValue: passwordHints.passwordValue,
			passwordMatches: passwordHints.passwordMatches,
			passwordConfirmationHasValue: passwordHints.passwordConfirmationHasValue,
		};
	};

	const [passwordHints, dispatchPasswordHints] = useReducer(passwordHintsReducer, {
		oneCharacter: false,
		oneNumber: false,
		eightCharaters: false,
		passwordValue: false,
		passwordMatches: true,
		passwordConfirmationHasValue: false,
	});

	useEffect(() => {
		setUrl(process.env.API_URL);

		const parseJwt = () =>
			JSON.parse(Buffer.from(props.match.params.token.split('.')[1], 'base64').toString());

		const decodedJwt = parseJwt(props.match.params.token);

		const { email } = props.match.params;
		const expirationUnixDatetime = decodedJwt.exp;
		const currentUnixDatetime = Math.floor(new Date().getTime() / 1000);

		if (currentUnixDatetime > expirationUnixDatetime) {
			if (decodedJwt.et === 'reset-password-v3') {
				window.location.replace(`/reset-password-expired/${email}`);
			} else if (decodedJwt.et === 'ems-welcome-v2') {
				window.location.replace(`/set-password-expired`);
			}
		} else {
			setLinkExpired(false);
		}
	}, []);

	const submitPasswordUpdate = async () => {
		setLoading(true);
		const res = await fetch(`${url}/reset-password`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${props.match.params.token}`,
			},
			body: JSON.stringify({
				password,
				repeatNewPassword: passwordConfirmation,
				token: props.match.params.token,
			}),
		});
		const resp = await res.json();
		if (resp.statusCode === 200) {
			setLoading(false);
			sessionStorage.setItem(
				'flashSuccessMsg',
				'Your password has been updated. Please sign in again',
			);
			window.location.replace(`${location.protocol}//${location.host}/login`);
		} else if (resp.statusCode === 400) {
			setLoading(false);
			toaster(resp.error.description, Intent.DANGER);
		}
	};

	return (
		<>
			{!linkExpired && (
				<div className="new-password-container">
					<div className="new-password-wrapper">
						<img alt="Navigate360 Logo" src={logoNavigateSwitch} />
						<div className="new-password-box">
							<div className="new-password-title">Update Password</div>

							<div className="password-input-container">
								<Input
									type="password"
									name="password"
									label="New Password"
									placeholder="Enter your new password"
									value={password}
									onChange={e => {
										setPassword(e.target.value);
										dispatchPasswordHints({
											type: 'password',
											payload: e.target.value,
											passwordConfirmation,
										});
									}}
									isRequired
									width="100%"
								/>
							</div>

							<div className="password-input-container">
								<Input
									type="password"
									name="passwordConfirmation"
									label="Retype New Password"
									placeholder="Re-enter your new password"
									value={passwordConfirmation}
									onChange={e => {
										setPasswordConfirmation(e.target.value);
										dispatchPasswordHints({
											type: 'passwordConfirmation',
											payload: e.target.value,
										});
									}}
									isRequired
									error={!passwordHints.passwordMatches}
									errorMessage="Passwords do not match"
									width="100%"
								/>
							</div>
						</div>
						<div className="password-hints-box">
							<div className="password-hints">
								<img
									src={
										passwordHints.eightCharaters
											? tickIconChecked
											: tickIconNotChecked
									}
									alt="Character length confirmation"
								/>
								<label>Has at least 8 characters</label>
							</div>
							<div className="password-hints">
								<img
									src={
										passwordHints.oneCharacter
											? tickIconChecked
											: tickIconNotChecked
									}
									alt="Special character confirmation"
								/>
								<label>Uses at least 1 special character</label>
							</div>
							<div className="password-hints">
								<img
									src={
										passwordHints.oneNumber
											? tickIconChecked
											: tickIconNotChecked
									}
									alt="Number confirmation"
								/>
								<label>Uses at least 1 number</label>
							</div>
							<Button
								text="Update"
								large
								wrapperClass="w-100 mt-3"
								disabled={
									!(
										passwordHints.eightCharaters &&
										passwordHints.oneCharacter &&
										passwordHints.oneNumber &&
										passwordHints.passwordMatches &&
										passwordHints.passwordConfirmationHasValue
									)
								}
								onClick={submitPasswordUpdate}
								loading={loading}
							/>
						</div>
					</div>
					<div className="new-password-footer">
						<img alt="Navigate360 Logo" src={logoNavigateDark} />
					</div>
				</div>
			)}
		</>
	);
};

SetPassword.propTypes = {
	token: PropTypes.string,
	match: PropTypes.object,
};

export default SetPassword;
