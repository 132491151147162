import React from 'react'
import styled from 'styled-components';
import { FileUpload } from './'

const Row = styled.div`margin-bottom:30px;`;

export const FileUploadPattern = () => {
  return (
    <div className = "column">
        <Row>
          <div className = "col-md-12">
            <h5>Normal State (Required):</h5>
          </div>
          <div className = "col-md-12">        
            <FileUpload
                disabled = {false}
                required = {true}
                allowedFiles = {['png', 'jpeg', 'jpg', 'svg', 'gif', 'mp3', 'mp4']}
                allowedFilesLegend = 'SVG, PNG, JPG or GIF (max. 800x400px) or MP3/MP4'
                defaultErrorMessage = 'Upload failed, please try again'
                label = 'Upload your audio file'
                hintText = 'This is an optional hint text to help user.'
            />
          </div>
        </Row>
        <Row>
          <div className = "col-md-12">
            <h5>Normal State (Required):</h5>
          </div>
          <div className = "col-md-12">        
            <FileUpload
                identifier='file-input-1'
                disabled = {false}
                required = {true}
                allowedFiles = {['png', 'jpeg', 'jpg', 'svg', 'gif', 'mp3', 'mp4']}
                allowedFilesLegend = 'SVG, PNG, JPG or GIF (max. 800x400px) or MP3/MP4'
                defaultErrorMessage = 'Upload failed, please try again'
                label = 'Upload your video file'
                hintText = 'This is an optional hint text to help user.'
            />
          </div>
        </Row>        
        <Row>
          <div className = "col-md-12">
            <h5>Normal State (Optional):</h5>
          </div>
          <div className = "col-md-12">        
            <FileUpload
                identifier = 'file-input-2'
                disabled = {false}
                allowedFiles = {['svg', 'png', 'gif']}
                defaultErrorMessage = 'This is an error message.'
                label = 'Upload an image'
                hintText = 'Help User Message Hint.'
                allowedFilesLegend = 'SVG, PNG or GIF only supported'
            />
          </div>
        </Row>
        <Row>
          <div className = "col-md-12">
            <h5>Error State (Try a PDF or DOC File!):</h5>
          </div>
          <div className = "col-md-12">        
            <FileUpload
                identifier = 'file-input-3'
                disabled = {false}
                required = {true}
                allowedFiles = {['svg', 'gif']}
                defaultErrorMessage = 'This is an error message.'
                label = 'Upload a SVG or GIF image'
                hintText = 'This uploader only supports SVG or GIF images!'
                allowedFilesLegend = 'SVG or GIF only supported (640x320px)'
            />
          </div>
        </Row>
        <Row>
          <div className = "col-md-12">
            <h5>Disabled State:</h5>
          </div>
          <div className = "col-md-12">        
            <FileUpload
                identifier = 'file-input-4'
                disabled = {true}
                allowedFilesLegend = 'No files are allowed right now'
                defaultErrorMessage = 'This is an error message.'
                label = 'Input Label text'
                hintText = 'This File Uploader is disabled.'
            />
          </div>
        </Row>
    </div>
  )
}
