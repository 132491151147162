import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalFooter } from './';

export const AlertModal = ({
	isOpen,
	onClose,
	loading,
	size,
	title,
	subtitle,
	respectSubtitleHtml,
	showCloseButton,
	icon,
	iconColor,
	footer,
	alertLeftContent,
	alertCancelButton,
	alertConfirmButton,
	customClassName,
}) => {
	const customFooter =
		alertLeftContent || alertCancelButton || alertConfirmButton ? (
			<ModalFooter
				leftContent={alertLeftContent}
				cancelButton={alertCancelButton}
				confirmButton={alertConfirmButton}
			/>
		) : null;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={size}
			title={title && title.length > 0 ? title : 'Title is required for Alert Modal'}
			subtitle={subtitle}
			respectSubtitleHtml={respectSubtitleHtml}
			showCloseButton={showCloseButton}
			icon={icon}
			iconColor={iconColor}
			footer={footer}
			customFooter={customFooter}
			loading={loading}
			customClassName={customClassName}
		/>
	);
};

AlertModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	size: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	respectSubtitleHtml: PropTypes.bool,
	showCloseButton: PropTypes.bool,
	icon: PropTypes.string,
	iconColor: PropTypes.string,
	footer: PropTypes.element,
	alertLeftContent: PropTypes.element,
	alertCancelButton: PropTypes.element,
	alertConfirmButton: PropTypes.element,
	customClassName: PropTypes.string,
};
