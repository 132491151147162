import React from "react";
import {TYPES_DATA} from './types';
import { DropdownMultiSelect } from "componentsV2";
import { useState } from "react";

export const TypesList = ({
    filterationData,
    setFilterationData
}) => {

    const [typesValues, setTypesValues] = useState(() => {
        return (filterationData?.types || []).map((type) =>
            TYPES_DATA.find((typeObj) => typeObj.value === type) || type
        );
    });

    const onChangeTypesHandler = types => {
        setTypesValues(types)
        setFilterationData(oldData => ({
            ...oldData,
            types: types.map(type => type.value)
        }));
    }
    return (  
        <DropdownMultiSelect
            label={null}
            values={typesValues}
            options={TYPES_DATA}
            allowSelectAll={false}
            placeholder="Filter by type"
            className="status-mddl"
            visibleOptions={1}
            onChange={onChangeTypesHandler}
        />
    );
}