import React from 'react'
import PropTypes from 'prop-types';
import { useBinderForm } from 'hooks';
import {
  FormModal,
  Input,
  InputColor,
  DefaultColors,
  TextArea,
  Dropdown,
} from 'componentsV2';

export const AddUpdateBinderModal = ({
  onSubmitFinished,
  modalIsOpen,
  setModalIsOpen,
  binder,
  selectedSites,
}) => {

  const presetColors = DefaultColors.filter((defaultColor, index) => index <= 11);

  const {
    formData,
    dispatchFormData,
    handleChangeName,
    handleChangeSite,
    handleChangeDescription,
    handleSubmit
  } = useBinderForm(
    binder,
    setModalIsOpen,
    selectedSites,
    onSubmitFinished);

  return (
    <FormModal
      isOpen={modalIsOpen}
      onClose={setModalIsOpen}
      showCloseButton={true}
      title={binder ? `Edit binder details` : "Add new binder"}
      loading={formData.modalLoading}
      scrollContent={false}
      confirmBtn={{
        title: binder ? "Update" : "Save",
        onClick: handleSubmit,
        disabled: formData.disableSubmit
      }}
      cancelBtn={{
        title: "Cancel",
        onClick: () => setModalIsOpen(false)
      }}
    >
      <>
        <div className="w-100">
          <Input
            label="Name"
            isRequired={true}
            width="100%"
            value={formData.name}
            onChange={handleChangeName}
            placeholder="ex. Safety plan binder"
          />
        </div>

        <div className="w-100 mt-3">
          <Dropdown
            label="Site Visibility"
            value={formData.siteSelected}
            options={formData.siteOptions}
            onChange={handleChangeSite}
            isRequired={true}
            error={!!formData.siteValidationError}
            errorMessage={formData.siteValidationError}
          />
        </div>

        <div className="w-100 mt-3">
          <InputColor
            label="Color"
            name="text-color"
            value={formData.color}
            setValue={(color) => dispatchFormData({ 'type': 'color', 'payload': color })}
            presetColors={presetColors}
            showColorPicker={false}
            isRequired={true}
          />
        </div>

        <div className="w-100 mt-3">
          <TextArea
            label="Description"
            placeholder="Enter a description for the binder…"
            isRequired={false}
            value={formData.description}
            width={"100%"}
            onChange={handleChangeDescription}
          />
        </div>

      </>
    </FormModal>
  )
}

AddUpdateBinderModal.propTypes = {
  onSubmitFinished: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
  binder: PropTypes.object,
};