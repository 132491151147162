import React, { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { Dropdown, FileIcon } from '../';
import { Checkbox, CheckboxGroup } from './';

export const CheckboxPattern = () => {
	// State of the checkbox
	const [checkbox1Checked, setCheckbox1Checked] = useState(false);
	const [checkbox2Checked, setCheckbox2Checked] = useState(false);
	const [checkbox3Checked, setCheckbox3Checked] = useState(false);
	const [checkbox4Checked, setCheckbox4Checked] = useState(true);
	const [checkbox5Checked, setCheckbox5Checked] = useState(false);
	const [checkbox6Checked, setCheckbox6Checked] = useState(false);

	// State of the options
	const [optionSize, setOptionSize] = useState(null);
	const [optionBorder, setOptionBorder] = useState(null);
	const [optionLayout, setOptionLayout] = useState(null);
	const [optionShowLabel, setOptionShowLabel] = useState(null);
	const [optionIcon, setOptionIcon] = useState(null);
	const [optionFileIcon, setOptionFileIcon] = useState(null);
	const [optionRequired, setOptionRequired] = useState(null);
	const [optionDisabled, setOptionDisabled] = useState(null);
	const [optionColor, setOptionColor] = useState(null);

	/** Handle Options */
	const handleChangeColor = item => {
		setOptionColor(item);
	};
	const handleChangeSize = item => {
		setOptionSize(item);
	};
	const handleChangeBorder = item => {
		setOptionBorder(item);
	};
	const handleChangeLayout = item => {
		setOptionLayout(item);
	};
	const handleChangeShowLabel = item => {
		setOptionShowLabel(item);
	};
	const handleChangeIcon = item => {
		setOptionIcon(item);
	};
	const handleChangeFileIcon = item => {
		setOptionFileIcon(item);
	};
	const handleChangeRequired = item => {
		setOptionRequired(item);
	};
	const handleChangeDisabled = item => {
		setOptionDisabled(item);
	};

	const customLabel = (
		<div className="checkbox-icon">
			<div>
				Checkbox #1
				<p>This is a supporting text</p>
			</div>
			{optionIcon && optionIcon.value.length > 0 && (
				<Icon icon={optionIcon.value} className="modal-icon" />
			)}
		</div>
	);

	const fileIconLabel = (
		<div className="checkbox-icon">
			<div>
				Checkbox #5 - File icon
				<p>Select a File Icon to be shown at the right</p>
			</div>
			{optionFileIcon && optionFileIcon.value.length > 0 && (
				<FileIcon icon={optionFileIcon.value} />
			)}
		</div>
	);

	const customLabelDisabled = (
		<div>
			Checkbox #2 - Disabled
			<p>This is a supporting text</p>
		</div>
	);

	const handleChange = ({ target }) => {
		setSelectedValue(target.value);
		console.log(target.value);
	};

	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className="card">
						<div className="card-body">
							<h4 className="mb-2">Checkbox Options</h4>

							<div className="row">
								<div className="col-md-3">
									<Dropdown
										className="mt-3"
										label="Size (all)"
										placeholder="Select size"
										value={optionSize}
										options={[
											{
												value: 'small',
												label: 'Small',
											},
											{
												value: 'medium',
												label: 'Medium',
											},
											{
												value: 'large',
												label: 'Large',
											},
										]}
										onChange={handleChangeSize}
									/>

									<div className="mt-3" />
									<Dropdown
										label="Show Border (all)"
										placeholder="Select an option"
										value={optionBorder}
										options={[
											{
												value: true,
												label: 'Yes',
											},
											{
												value: false,
												label: 'No',
											},
										]}
										onChange={handleChangeBorder}
									/>
								</div>

								<div className="col-md-3">
									<Dropdown
										label="Layout (only for group)"
										placeholder="Select an option"
										value={optionLayout}
										options={[
											{
												value: 'block',
												label: 'Block',
											},
											{
												value: 'inline',
												label: 'Inline',
											},
										]}
										onChange={handleChangeLayout}
									/>

									<div className="mt-3" />
									<Dropdown
										label="Disabled (all)"
										placeholder="Select an option"
										value={optionDisabled}
										options={[
											{
												value: true,
												label: 'Yes',
											},
											{
												value: false,
												label: 'No',
											},
										]}
										onChange={handleChangeDisabled}
									/>
								</div>

								<div className="col-md-3">
									<Dropdown
										label="Required (only for group)"
										placeholder="Select an option"
										value={optionRequired}
										options={[
											{
												value: true,
												label: 'Yes',
											},
											{
												value: false,
												label: 'No',
											},
										]}
										onChange={handleChangeRequired}
									/>

									<div className="mt-3" />
									<Dropdown
										label="Show Label (only for group)"
										placeholder="Select an option"
										value={optionShowLabel}
										options={[
											{
												value: true,
												label: 'Yes',
											},
											{
												value: false,
												label: 'No',
											},
										]}
										onChange={handleChangeShowLabel}
									/>
								</div>

								<div className="col-md-3">
									<Dropdown
										label="Icon (only checkbox #1)"
										placeholder="Select an option"
										value={optionIcon}
										options={[
											{
												value: '',
												label: 'Hide Icon',
											},
											{
												value: 'circle',
												label: 'Circle',
											},
											{
												value: 'warning-sign',
												label: 'warning-sign',
											},
											{
												value: 'help',
												label: 'help',
											},
											{
												value: 'tick-circle',
												label: 'tick-circle',
											},
											{
												value: 'lightbulb',
												label: 'lightbulb',
											},
										]}
										onChange={handleChangeIcon}
									/>

									<div className="mt-3" />
									<Dropdown
										label="File Icon (only checkbox #5)"
										placeholder="Select an option"
										value={optionFileIcon}
										options={[
											{
												value: '',
												label: 'Hide Icon',
											},
											{
												value: 'pdf',
												label: 'pdf',
											},
											{
												value: 'docx',
												label: 'docx',
											},
											{
												value: 'xls',
												label: 'xls',
											},
											{
												value: 'pptx',
												label: 'pptx',
											},
											{
												value: 'csv',
												label: 'csv',
											},
											{
												value: 'txt',
												label: 'txt',
											},
											{
												value: 'document',
												label: 'document',
											},
											{
												value: 'map',
												label: 'map',
											},
											{
												value: 'call_list',
												label: 'call_list',
											},
											{
												value: 'safety_plan',
												label: 'safety_plan',
											},
											{
												value: 'assessment',
												label: 'assessment',
											},
										]}
										onChange={handleChangeFileIcon}
									/>
								</div>
								<div className="col-md-3">
									<Dropdown
										label="Colors"
										placeholder="Select color"
										value={optionColor}
										options={[
											{
												value: 'primary',
												label: 'Primary',
											},
											{
												value: 'success',
												label: 'Success',
											},
											{
												value: 'error',
												label: 'Error',
											},
											{
												value: 'gray',
												label: 'Gray',
											},
											{
												value: 'orange',
												label: 'Orange',
											},
										]}
										onChange={handleChangeColor}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-9 mb-3">
					<div className="card">
						<div className="card-body">
							<CheckboxGroup
								label={
									optionShowLabel && optionShowLabel.value
										? 'Checkbox group title'
										: ''
								}
								size={optionSize && optionSize.value} // small | medium | large
								layout={optionLayout && optionLayout.value} // block | inline
								required={optionRequired && optionRequired.value}
							>
								<Checkbox
									label={customLabel}
									onChange={() => setCheckbox1Checked(oldValue => !oldValue)}
									checked={checkbox1Checked}
									size={optionSize && optionSize.value} // small | medium
									bordered={optionBorder && optionBorder.value}
									fileIcon="pdf"
									disabled={optionDisabled && optionDisabled.value}
									color={optionColor && optionColor.value}
								/>

								<Checkbox
									label={customLabelDisabled}
									onChange={() => setCheckbox2Checked(oldValue => !oldValue)}
									checked={checkbox2Checked}
									disabled={true}
									size={optionSize && optionSize.value} // small | medium
									bordered={optionBorder && optionBorder.value}
									color={optionColor && optionColor.value}
								/>

								<Checkbox
									onChange={() => setCheckbox3Checked(oldValue => !oldValue)}
									checked={checkbox3Checked}
									size={optionSize && optionSize.value} // small | medium
									bordered={optionBorder && optionBorder.value}
									disabled={optionDisabled && optionDisabled.value}
									color={optionColor && optionColor.value}
								/>

								<Checkbox
									label="Checkbox #4 - Indeterminate"
									onChange={() => setCheckbox4Checked(oldValue => !oldValue)}
									checked={checkbox4Checked}
									size={optionSize && optionSize.value} // small | medium
									indeterminate={true}
									disabled={optionDisabled && optionDisabled.value}
									bordered={optionBorder && optionBorder.value}
									color={optionColor && optionColor.value}
								/>
								<Checkbox
									label={fileIconLabel}
									onChange={() => setCheckbox5Checked(oldValue => !oldValue)}
									checked={checkbox5Checked}
									size={optionSize && optionSize.value} // small | medium
									bordered={optionBorder && optionBorder.value}
									disabled={optionDisabled && optionDisabled.value}
									color={optionColor && optionColor.value}
								/>
							</CheckboxGroup>
						</div>
					</div>
				</div>

				<div className="col-md-3">
					<div className="card">
						<div className="card-body">
							<Checkbox
								label={
									<div>
										Checkbox #6
										<p>Without group</p>
									</div>
								}
								onChange={() => setCheckbox6Checked(oldValue => !oldValue)}
								checked={checkbox6Checked}
								size={optionSize && optionSize.value} // small | medium
								bordered={optionBorder && optionBorder.value}
								disabled={optionDisabled && optionDisabled.value}
								color={optionColor && optionColor.value}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
