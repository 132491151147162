import React, { useState, useEffect, useContext } from 'react';
import './Settings.scss';
import { CardContainer, ToggleButton, ToasterContext, Intent, FeaturedIcon } from 'componentsV2';
import { AssessmentService } from 'services';
import { useSelector } from 'react-redux';

export const Settings = () => {
	const [settings, setSettings] = useState({});

	const toaster = useContext(ToasterContext);

	const districtId = useSelector(state => state.route.districtId);
	const getSettingsData = async () => {
		const res = await AssessmentService.getSettings(districtId);
		setSettings({
			assessIsLocked: res.data.assessIsLocked,
			assessForceCompletion: res.data.assessForceCompletion,
		});
	};

	useEffect(() => {
		getSettingsData();
	}, []);

	const toggleSetting = async setting => {
		try {
			const setSettingsRes = await AssessmentService.setSettings(districtId, {
				[setting]: !settings[setting],
			});

			setSettings({
				...settings,
				[setting]: setSettingsRes.data[setting],
			});

			const successMessages = {
				assessIsLocked: `Completed assessments are now ${
					setSettingsRes.data[setting] ? 'locked' : 'unlocked'
				}`,
				assessForceCompletion: `Assessment completion is now ${
					setSettingsRes.data[setting] ? 'forced' : 'unforced'
				}`,
			};

			toaster(
				successMessages[setting],
				Intent.SUCCESS,
				<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
			);
		} catch (error) {
			toaster(
				'Error updating setting',
				Intent.DANGER,
				<FeaturedIcon icon="error" type="Error" />,
			);
		}
	};

	return (
		<div className="assess-settings">
			<CardContainer title="Lock Completed Assessments">
				<p>Assessments marked as complete cannot be edited.</p>
				<ToggleButton
					checked={settings.assessIsLocked}
					onChange={() => toggleSetting('assessIsLocked')}
				/>
			</CardContainer>
			<CardContainer title="Force Assessment Completion">
				<p>An assessment can only be marked as complete once all questions are answered.</p>
				<ToggleButton
					checked={settings.assessForceCompletion}
					onChange={() => toggleSetting('assessForceCompletion')}
				/>
			</CardContainer>
		</div>
	);
};
