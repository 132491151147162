import React, { useContext } from 'react';
import { ProgressBar, Button } from 'componentsV2';
import { RapidAlarmsContext } from '../../Providers';
import { ACTIVATE_ROLL_CALL, END_ROLL_CALL } from 'pages/RapidAlarms/consts';
import { ROLLCALL_TAB } from '../../RollCallDetails/components';

export const RollCallDetailsCard = ({
	alarm,
	loggedUser,
	isRollCallStarted,
	isRollCallEnded,
	history,
	isReunificationStarted,
	userStatus,
	setForceAreYouSafeOpen,
	rollCallStats,
	setGoButtonClicked,
}) => {
	const { onActiveEndRollCall, openedFromHomePage } = useContext(RapidAlarmsContext);
	const redirectRollCallHandler = () => {
		if (!userStatus) {
			setForceAreYouSafeOpen(true);
			setGoButtonClicked(ROLLCALL_TAB);
		} else {
			history.push({
				pathname: `/active-alarms/roll-call-reunification/${alarm.id}`,
				state: { openedFromHomePage },
			});
		}
	};

	return (
		<div className="alarm-card-container">
			<div className="alarm-card-info">
				{loggedUser.accountType == 'administrator' ? (
					<>
						<div className="row">
							<div className="col-md-6">
								<div className="alarm-card-title">Roll Call details</div>
							</div>
							<div className="col-md-6 text-right">
								<div className="alarm-card-buttons">
									{isRollCallStarted ? (
										<span className="mr-3">
											<Button
												large
												text="End Roll Call"
												type="secondaryDanger"
												intent="default"
												disabled={isRollCallEnded}
												onClick={() => {
													onActiveEndRollCall(alarm, END_ROLL_CALL);
												}}
											/>
										</span>
									) : (
										<span className="mr-3">
											<Button
												large
												text="Activate Roll Call"
												disabled={isReunificationStarted}
												onClick={() => {
													onActiveEndRollCall(alarm, ACTIVATE_ROLL_CALL);
												}}
											/>
										</span>
									)}

									<Button
										large
										text="See Roll Call details"
										type="secondaryDefault"
										intent="default"
										rightIcon="arrow-top-right"
										disabled={
											!isRollCallStarted || isRollCallEnded ? true : false
										}
										onClick={redirectRollCallHandler}
									/>
								</div>
							</div>
						</div>
						<div className="alarm-progress-bars-container">
							<ProgressBar
								label="Students with a Roll Call status"
								value={isRollCallStarted ? rollCallStats.studentsAccountedFor : 0}
								total={
									isRollCallStarted ? rollCallStats.studentsTotalExpectedCount : 0
								}
								displayValueInPercentage={false}
								disabled={!isRollCallStarted}
							/>

							<ProgressBar
								label="Staff with a Roll Call status"
								value={isRollCallStarted ? rollCallStats.staffAccountedFor : 0}
								total={
									isRollCallStarted ? rollCallStats.staffTotalExpectedCount : 0
								}
								displayValueInPercentage={false}
								disabled={!isRollCallStarted}
							/>
							{alarm.vmAccess && (
								<ProgressBar
									label="Visitors with a Roll Call status"
									value={
										isRollCallStarted ? rollCallStats.visitorsAccountedFor : 0
									}
									total={isRollCallStarted ? rollCallStats.visitorsTotalCount : 0}
									displayValueInPercentage={false}
									disabled={!isRollCallStarted}
								/>
							)}
						</div>
					</>
				) : (
					<div className="row">
						<div className="col-md-6">
							<div className="alarm-card-title">Roll Call</div>
							{isRollCallStarted && !isRollCallEnded && (
								<p>
									Roll Call has been activated. Please go to Roll Call and account
									for yourself.
								</p>
							)}
							{!isRollCallStarted && (
								<p>
									Roll Call has not been activated. Wait for further instruction.
								</p>
							)}
							{isRollCallEnded && (
								<p>Roll Call has ended. Wait for further instruction.</p>
							)}
						</div>
						<div className="col-md-6 text-right">
							<div className="alarm-card-buttons">
								<Button
									large
									text="Go to Roll Call"
									type="secondaryDefault"
									intent="default"
									rightIcon="arrow-top-right"
									disabled={!isRollCallStarted || isRollCallEnded ? true : false}
									onClick={redirectRollCallHandler}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
