import React, { useState, useContext, useReducer, useEffect } from 'react';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { ExistingContactsTable } from './ExistingContactsTable';
import { ContactService } from 'services/ContactService';
import { SORTING_DIR } from 'utilities/constants';
import { Modal, Button, ModalFooter } from 'componentsV2';
import './css/ExistingContactsModal.scss';

export const ExistingContactsModal = ({
	onCloseModal,
	selectedCallList,
	selectedSites,
	setReloadContacts,
}) => {
	const [loading, setLoading] = useState(false);
	const context = useContext(ToasterContext);
	const [selectedRows, setSelectedRows] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [allContactsList, setAllContactsList] = useState([]);
	const [firstRender, setFirstRender] = useState(true);

	const callListId = selectedCallList.value;
	const [preAssignedContacts, setPreAssignedContacts] = useState([]);
	const disableBtns = selectedRows.length == 0;
	const newSelectedIds = selectedRows.map(row => row.id);
	const filterationDataReducer = (filterationData, action) => {
		switch (action.type) {
			case 'combineSearch':
				filterationData[action.type] = action.payload;
				filterationData.page = 1;
				break;
			case 'sortDir':
			case 'sortDir':
				filterationData[action.type] = action.payload;
				filterationData.perPage = filterationData.perPage * filterationData.page;
				filterationData.page = 1;
				break;
			default:
				filterationData[action.type] = action.payload;
				break;
		}
		return {
			page: filterationData.page,
			perPage: filterationData.perPage,
			selectedSites: filterationData.selectedSites,
			combineSearch: filterationData.combineSearch,
			sortKey: filterationData.sortKey,
			sortDir: filterationData.sortDir,
		};
	};

	const [filterationData, dispatchFilterationData] = useReducer(filterationDataReducer, {
		page: 1,
		perPage: 10,
		selectedSites: [],
		combineSearch: '',
		sortKey: 'name',
		sortDir: SORTING_DIR.ASC,
	});

	const fetchContacts = () => {
		ContactService.getAllExistingContacts(callListId, filterationData).then(resp => {
			if (resp.statusCode === 200) {
				setLoading(false);
				let distArray = [];
				if (filterationData.page == 1) {
					setAllContactsList(resp.data);
				} else {
					setAllContactsList(oldContent => [...oldContent, ...resp.data]);
					distArray = [...preAssignedContacts];
				}

				resp.data.forEach(item =>
					item.isLinkedToCallList ? distArray.push(item.id) : null,
				);
				setPreAssignedContacts(distArray);
				setTotalRows(resp._metadata.totalRecords);
				setFirstRender(false);
			}
		});
	};

	const deleteTableRows = (index, id) => {
		const rows = [...selectedRows];
		const filteredRows = rows.filter((item, key) => !(key == index && item.id == id));
		setSelectedRows(filteredRows);
		const distArray = [...preAssignedContacts];
		filteredRows.forEach(item => distArray.push(item.id));
	};

	const handleSubmit = event => {
		setLoading(true);
		ContactService.addExistingContacts(selectedRows, selectedCallList.value)
			.then(response => {
				setLoading(false);
				context('You have successfully attached new contact(s).', Intent.SUCCESS);
				setReloadContacts(oldValue => !oldValue);
				onCloseModal();
			})
			.catch(error => {
				setLoading(false);
				if (error.statusCode == 400) {
					context('Failed to create some contact(s).', Intent.DANGER);
				}
			});
	};

	useEffect(() => {
		//check if selected sites has Contacts
		setLoading(firstRender);
		fetchContacts();
	}, [filterationData, callListId]);

	const selectRow = {
		mode: 'checkbox',
		classes: 'selected-row',

		clickToSelect: true,
		onSelect: (row, isSelect) => {
			if (isSelect) {
				setSelectedRows([...selectedRows, row]);
				const distArray = [];
				[...selectedRows, row].forEach(item => distArray.push(item.id));
			} else {
				setSelectedRows(
					selectedRows.filter(selectedRow => {
						return selectedRow.id != row.id;
					}),
				);
			}
		},

		onSelectAll: (isSelect, rows, e) => {
			if (isSelect) {
				const distArray = [];

				rows.forEach(row => {
					[...selectedRows, row].forEach(item => distArray.push(item.id));
					selectedRows.push(row);
				});
				setSelectedRows([...selectedRows]);
			} else {
				setSelectedRows([]);
			}
		},
		nonSelectable: preAssignedContacts,
		selected: [...preAssignedContacts, ...newSelectedIds],
	};
	const getFooter = () => {
		const confirmButtonElement = !loading && (
			<Button text="Add" intent="default" disabled={disableBtns} onClick={handleSubmit} />
		);
		const cancelButtonElement = (
			<Button text="Cancel" type="tertiary" intent="default" onClick={onCloseModal} />
		);

		return (
			<ModalFooter confirmButton={confirmButtonElement} cancelButton={cancelButtonElement} />
		);
	};
	return (
		<Modal
			title="Attach EMS contacts"
			isOpen={true}
			onConfirmAction={handleSubmit}
			customFooter={getFooter()}
			customClassName="ExsitingContactModal-Wrapper"
			size="large"
			loading={loading}
			onClose={() => {}}
		>
			<ExistingContactsTable
				contactsList={allContactsList}
				selectRow={selectRow}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				selectedCallList={selectedCallList}
				selectedSites={selectedSites}
				deleteTableRows={deleteTableRows}
				dispatchFilterationData={dispatchFilterationData}
				filterationData={filterationData}
				totalRows={totalRows}
			/>
		</Modal>
	);
};
