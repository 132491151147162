import React, { useState, useEffect }  from "react";
import { formatDate } from "utilities/dates";
import { CallListService } from "services/CallListService";
import { Icon } from "@blueprintjs/core";
import { LoadingSpinner } from 'components/LoadingSpinner';
import { ActionsButton, BootstrapedTable, Button, TableCell, sortingStyle } from "../";
import { generateAvatar } from "pages/App/AvatarInitials";

export const BootstrapedTablePattern = () => {

    const [loading, setLoading] = useState(true);
    const [callList, setCallList] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [filterationData, setFilterationData] = useState({
        page: 1,
        perPage: 10,
        sortKey: 'title',
        sortDir: 'asc',
    })

    useEffect(() => {

        CallListService.getAll(filterationData).then(resp => {
            if(resp.statusCode === 200) {
                setTotalRows(resp._metadata.totalRecords)
                setCallList(resp.data);
            }
            setLoading(false);
        }).catch(() => setLoading(false));
    }, [filterationData])

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID', 
			hidden: true 
		},
		{
			key: 'title',
			dataField: 'title',
			text: 'Name',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column),
            formatter: (value, row) => {
				return (
                    <TableCell
                        text={value}
                        supportText="support text" 
                        leftIcon = {<Icon icon='help' />} 
                        rightIcon = {<Icon icon='volume-down' />}
                        firstRow = {true}
                    />
                )
			}
		},
		{
			key: 'site',
			dataField: 'site',
			text: 'Site',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column, 'help'),
        },
		{
			key: 'date',
			dataField: 'dateModified.date',
			text: 'Last Modified',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column, 'check'),
            formatter: (value, row) =>  formatDate(value)
		},
		{
			key: 'numberOfContracts',
			dataField: 'numberOfContracts',
			text: 'Number Of Contacts',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
        },
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<ActionsButton>
					<Button
						larg
						text="View"
						icon="eye-open"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
					/>
				</ActionsButton>
			)
		},
	];

	const columns2 = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID', 
			hidden: true 
		},
		{	
			key: 'title',
			dataField: 'title',
			text: 'Name',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column, 'info-sign'),
            formatter: (value, row) => {
				return (
                    <TableCell
                        text={value}
                        supportText="support text" 
                        leftIcon = {<img src={generateAvatar(value.slice(0, 2).toUpperCase())} alt="Sherif Hesham" />} 
                        firstRow = {true}
                    />
                )
			}
		},
		{
			key: 'site',
			dataField: 'site',
			text: 'Site',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column, 'help'),
        },
		{
			key: 'date',
			dataField: 'dateModified.date',
			text: 'Last Modified',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column, 'check'),
            formatter: (value, row) =>  formatDate(value)
		},
		{
			key: 'numberOfContracts',
			dataField: 'numberOfContracts',
			text: 'Number Of Contacts',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
        },
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<ActionsButton>
					<Button
						large
						text="View"
						icon="eye-open"
						size="md"
						type="ghostDefault"
						intent="default"
						wrapperClass="displayBlock"
						className="actionButtonElement"
					/>
				</ActionsButton>
			)
		},
	];
	
	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'title';
			switch (sortField) {
				case 'buildings[0].name':
					sortColumn = 'buildingName';
				break;
				case 'dateModified.date':
					sortColumn = 'lastModified';
				break;
				case 'numberOfContracts':
					sortColumn = 'contracts';
				break;
				default:
					sortColumn = sortField;
				break;
			}
			setFilterationData({...filterationData, 'sortKey': sortColumn, 'sortDir' : sortOrder});
		}
	};

    const setRowsPerPage = value => {
        setFilterationData(oldData => ({...oldData, 'perPage': value }));
    }

    const setCurrentPage = value => {
        setFilterationData(oldData => ({...oldData, 'page': value }));
    }

	const disabledRowCondition = (row) => {
		return row.title === 'abc';
	};

	return (
        <>
            {loading && <LoadingSpinner />}
            {!loading && (
				<div className="row">
					<div className="col-md-12">
						<h3>Example 1:</h3>
					</div>
					<div className="col-md-12">
						<BootstrapedTable
							keyField="id"
							data={callList}
							columns={columns}
							onTableChange={onTableChange}
							currentPage={filterationData.page}
							rowsPerPage={filterationData.perPage}
							setCurrentPage={setCurrentPage}
							setRowsPerPage={setRowsPerPage}
							totalRows={totalRows}
							disabledRowCondition={disabledRowCondition}
						/>
					</div>
					<div className="col-md-12 mt-3">
						<h3>Example 2 with checkboxes:</h3>
					</div>
					<div className="col-md-12">
						<BootstrapedTable
							keyField="id"
							hasCheckbox={true}
							data={callList}
							columns={columns2}
							onTableChange={onTableChange}
							currentPage={filterationData.page}
							rowsPerPage={filterationData.perPage}
							setCurrentPage={setCurrentPage}
							setRowsPerPage={setRowsPerPage}
							totalRows={totalRows}
							disabledRowCondition={disabledRowCondition}
						/>
					</div>
				</div>
            )}
        </>
	);
}