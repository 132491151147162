import React from 'react';
import { useMapEvents } from 'react-leaflet';

export const ZoomEvents = ({ mapRef, setMapZoom, stage, updateStageData, updateStagePosition }) => {
	const handleZoom = () => {
		const zoom = mapRef.current.getZoom();
		setMapZoom(zoom);
		updateStageData();
	};

	const handleDrag = e => {
		updateStagePosition();
		stage.batchDraw();
	};

	const MapZoomEvents = () => {
		useMapEvents({
			zoom: handleZoom,
			move: handleDrag,
		});

		return null;
	};

	return <MapZoomEvents />;
};
