import React from 'react';
import MoreCard from 'components/MoreCard';
import { NavLink } from 'react-router-dom';
import { PhoneWithExtension } from 'utilities/PhoneWithExtension';
import { Helper } from 'utilities/helper';
import { BootstrapedTable, sortingStyle } from 'componentsV2';

const FirstRespondersTable = ({ list, filterationData, dispatchFilterationData, totalRows }) => {
	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Name',
			formatter: (value, row) => (
				<NavLink to={`first-responders/view/${row.id}`}>{value}</NavLink>
			),
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(
					filterationData.sortKey === column.dataField ? filterationData.sortDir : order,
					column,
				),
			headerStyle: { width: '16%' },
		},
		{
			key: 'destinationBuildingId',
			dataField: 'buildingId',
			text: 'Site',
			formatter: (value, row) => (
				<MoreCard
					items={row.allowed_sites}
					visibleOptions={1}
					id={row.id}
					borderIncluded={false}
				/>
			),
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(
					filterationData.sortKey === column.dataField ? filterationData.sortDir : order,
					column,
				),
			headerStyle: { width: '16%' },
		},
		{
			key: 'primaryContactName',
			dataField: 'primaryContactName',
			text: 'Primary Contact Name',
			formatter: (value, row) =>
				(row.primary_contact ? row.primary_contact.first_name : '') +
				' ' +
				(row.primary_contact ? row.primary_contact.family_name : ''),
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(
					filterationData.sortKey === column.dataField ? filterationData.sortDir : order,
					column,
				),
			headerStyle: { width: '16%' },
		},
		{
			key: 'primaryContactNumber',
			dataField: 'work_number',
			text: 'Primary Contact Number',
			formatter: (value, row) => (
				<PhoneWithExtension
					phone={Helper.formatDigitsToPhone(row.primary_contact?.work_number)}
					extension={row.primary_contact?.work_ext_number}
				/>
			),
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(
					filterationData.sortKey === column.dataField ? filterationData.sortDir : order,
					column,
				),
			headerStyle: { width: '16%' },
		},
		{
			key: 'loginInfo',
			dataField: 'email',
			text: 'Login Info',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(
					filterationData.sortKey === column.dataField ? filterationData.sortDir : order,
					column,
				),
			headerStyle: { width: '16%' },
		},
		{
			key: 'lastLogin',
			dataField: 'formatted_last_login_date',
			text: 'Last Login',
			classes: 'bold-500',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(
					filterationData.sortKey === column.dataField ? filterationData.sortDir : order,
					column,
				),
			headerStyle: { width: '16%' },
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'name';
			switch (sortField) {
				case 'destinationUserId':
					sortColumn = 'destinationUserId';
					break;
				case 'dateCreated.date':
					sortColumn = 'dateCreated';
					break;
				default:
					sortColumn = sortField;
					break;
			}
			dispatchFilterationData({ type: 'sortKey', payload: sortColumn });
			dispatchFilterationData({ type: 'sortDir', payload: sortOrder });
		}
	};
	return (
		<BootstrapedTable
			keyField="id"
			data={list}
			columns={columns}
			bordered={false}
			className="mt-3"
			onTableChange={onTableChange}
			currentPage={filterationData.page}
			rowsPerPage={filterationData.perPage}
			setCurrentPage={value => dispatchFilterationData({ type: 'page', payload: value })}
			setRowsPerPage={value => dispatchFilterationData({ type: 'perPage', payload: value })}
			totalRows={totalRows}
		/>
	);
};

export default FirstRespondersTable;
