import React, { useState, useEffect, useRef } from 'react';
import './css/DropdownMultiSelect.scss';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import InputWrapper, { ElementWrapper } from '../InputWrapper/InputWrapper';
import PropTypes from 'prop-types';

const animatedComponents = makeAnimated();

const Option = ({
	getStyles,
	Icon,
	isDisabled,
	isFocused,
	isSelected,
	children,
	innerProps,
	...rest
}) => {
	const [isActive, setIsActive] = useState(false);
	const onMouseDown = () => setIsActive(true);
	const onMouseUp = () => setIsActive(false);
	const onMouseLeave = () => setIsActive(false);

	// styles
	let bg = 'transparent';
	let color = 'inherit';
	let cursor = 'default';

	if (isFocused) bg = '#E6F2F4';
	if (isSelected) bg = '#E6F2F4';
	if (isActive) bg = '#B2D4FF';
	if (isDisabled) {
		color = '#BEBEBE';
		cursor = 'not-allowed';
	}
	const style = {
		alignItems: 'center',
		backgroundColor: bg,
		color,
		display: 'flex',
		cursor,
	};

	let disabledStyle = {};
	if (isDisabled) {
		disabledStyle = {
			cursor: 'not-allowed',
		};
	}

	// prop assignment
	const props = {
		...innerProps,
		onMouseDown,
		onMouseUp,
		onMouseLeave,
		style,
	};

	return (
		<components.Option
			{...rest}
			isDisabled={isDisabled}
			isFocused={isFocused}
			isSelected={isSelected}
			getStyles={getStyles}
			innerProps={props}
		>
			<div
				style={disabledStyle}
				className="ddl-multiselect-custom d-flex justify-content-start"
			>
				<input
					type="checkbox"
					checked={isSelected}
					style={disabledStyle}
					onChange={() => {}}
				/>
				<p style={disabledStyle}>{rest.label}</p>
			</div>
		</components.Option>
	);
};

const CustomValueContainer = ({ children, ...props }) => {
	const selectedOptions = props.getValue();
	const numSelected = selectedOptions.length;
	const { visibleOptions } = props.selectProps;
	const { showHiddenOptions } = props.selectProps;
	const { setShowHiddenOptions } = props.selectProps;
	if (numSelected == 0) {
		setShowHiddenOptions(false);
	}

	const handleClick = () => {
		setShowHiddenOptions(currentValue => !currentValue);
	};

	if (selectedOptions.some(val => val.value === '*')) {
		children = [[children[0][0]], children[1]];
	} else if (visibleOptions && numSelected > visibleOptions) {
		if (!showHiddenOptions) {
			children = [
				children[0].slice(0, visibleOptions),
				<div key="more" className="more-options" onClick={handleClick}>
					<div className="ddl-multiselect-value-container">
						+{numSelected - visibleOptions}
					</div>
				</div>,
				children[children.length - 1],
			];
		} else {
			children = [
				...children[0],
				<div key="more" className="ddl-multiselect-value-container" onClick={handleClick}>
					<div className="x-more-hidden-options-count">Show less</div>
				</div>,
			];
		}
	}
	return (
		<components.ValueContainer
			{...props}
			style={{ overFlowY: 'auto' }}
			className="ddl-multiselect-value-container"
		>
			{children}
		</components.ValueContainer>
	);
};

const MultiValue = props => {
	return (
		<components.MultiValue {...props}>
			<span>{props.data.label}</span>
		</components.MultiValue>
	);
};

export const DropdownMultiSelect = ({
	label = '',
	placeholder = 'Select an option...',
	labelState = true,
	isRequired,
	values,
	options = [],
	onChange,
	visibleOptions = null,
	disabled = false,
	allowSelectAll = false,
	selectAllChecked = false,
	defaultValue,
	error = null,
	showRequiredText = false,
	width = null,
	isClearable = true,
	selectAllOption,
	className = '',
}) => {
	const [showHiddenOptions, setShowHiddenOptions] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const selectRef = useRef();

	if (allowSelectAll && !selectAllOption) {
		selectAllOption = {
			value: '*',
			label: 'Select All',
		};
	}

	const onBlurHandler = () => {
		setShowHiddenOptions(false);
	};

	const handleKeyDown = e => {
		const inputValue = e.target.value;
		if (e.key === 'Backspace' && !isClearable && !inputValue) {
			e.preventDefault();
		}
	};

	const customStyles = {
		menu: base => ({
			...base,
			zIndex: 9999,
		}),
	};

	const customClases = {
		placeholder: () => 'placeholder',
		input: () => 'input',
		control: state => {
			return state.isFocused ? 'control-selected' : 'control';
		},
		options: () => 'options',
		singleValue: () => 'singleValue',
		multiValue: () => 'multiValue',
		multiValueRemove: () => 'multiValueRemove',
		indicatorSeparator: () => 'indicatorSeparator',
		indicatorsContainer: () => 'indicatorsContainer',
		option: () => 'option',
		menuList: () => 'menuList',
	};

	if (!isClearable) {
		customStyles.multiValueRemove = (styles, { data }) => ({
			...styles,
			display: 'none',
		});
	}

	useEffect(() => {
		if (menuIsOpen) {
			const handleClickOutside = e => {
				if (selectRef.current && !selectRef.current.contains(e.target)) {
					setMenuIsOpen(false);
				}
			};

			// Bind the event listener
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}
	}, [menuIsOpen]);

	const onChangeHandler = (selected, event) => {
		if (selected !== null && selected.length > 0) {
			if (event.action == 'select-option') {
				setShowHiddenOptions(false);
			}
			if (
				selected[selected.length - 1].value === selectAllOption.value &&
				event.action == 'select-option'
			) {
				setSelectedOptions([selectAllOption, ...options]);
				return onChange([selectAllOption, ...options], event);
			}
			let result = [];
			if (selected.length === options.length) {
				if (
					selected.filter(selectItem => selectItem.value === selectAllOption.value)
						.length > 0
				) {
					result = selected.filter(option => option.value !== selectAllOption.value);
				} else if (event.action === 'select-option') {
					result = [selectAllOption, ...options];
				}
				setSelectedOptions(result);
				return onChange(result, event);
			}
			setSelectedOptions(selected);
			return onChange(selected, event);
		}
		setSelectedOptions([]);
		return onChange([], event);
	};

	const getClassStatus = () => {
		const activeStatus = menuIsOpen ? '-active' : '';
		const errorStatus = error ? '-is-error' : '';
		return `${activeStatus}${errorStatus}`;
	};

	return (
		<div className={`ddl-multiselect-wrapper ${className}`} ref={selectRef}>
			<InputWrapper label={label} labelState={labelState} isRequired={isRequired}>
				<ElementWrapper width={width}>
					<Select
						defaultValue={defaultValue}
						value={values}
						className={`multi-select${getClassStatus()}`}
						placeholder={placeholder}
						options={allowSelectAll ? [selectAllOption, ...options] : options}
						isClearable={isClearable}
						isMulti
						menuIsOpen={menuIsOpen}
						onMenuOpen={() => setMenuIsOpen(true)}
						onMenuClose={() => setMenuIsOpen(false)}
						visibleOptions={visibleOptions}
						showHiddenOptions={showHiddenOptions}
						setShowHiddenOptions={setShowHiddenOptions}
						isDisabled={disabled}
						selectAllLabel={selectAllOption?.label}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						components={{
							Option,
							MultiValue,
							ValueContainer: CustomValueContainer,
							animatedComponents,
						}}
						onChange={allowSelectAll ? onChangeHandler : onChange}
						onBlur={onBlurHandler}
						classNames={customClases}
						styles={customStyles}
						selectAllChecked={selectAllChecked}
						onKeyDown={e => handleKeyDown(e)}
					/>
				</ElementWrapper>
			</InputWrapper>
		</div>
	);
};

DropdownMultiSelect.propTypes = {
	labelState: PropTypes.bool,
};
