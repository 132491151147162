import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {AlertModal, Button } from 'componentsV2';
import { ToasterContext } from 'pages/App';
import { Intent} from "componentsV2";
import { PlanService } from 'services';

const ResetModal = ({
	template,
	reloadTemplate,
	closeResetModal
}) => {
	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(true);


	const toaster = useContext(ToasterContext);
	const currentUpdate = template.allUpdates.filter((templateUpdate) => { return templateUpdate.type === 'reset'; })
	const currentUpdateContent = currentUpdate.length > 0 ? JSON.parse(currentUpdate[0].updatedContent) : null;

	const resetUpdates = () => {
		setLoading(true)
		template.sections = currentUpdateContent.content;
		updateTemplateSectionId(template, true)
		return;
	}

	const updateTemplateSectionId = (template, accepted) => {
		const currentUpdateId = currentUpdate[0].id;
		const body = JSON.stringify({
			accepted: accepted,
			isDistrictLocalUpdate: false,
		})
    	PlanService.updateTemplateUpdate(parseInt(template.id), currentUpdateId, body).then(response => {
			updateTemplateContent(template)
		});
	};

	const updateTemplateContent = async(template) => {
		const totalUpdates =  template.allUpdates;

		if (totalUpdates.length === 0) return null;

		let getCurrentUpdate = totalUpdates.find(totalUpdate => totalUpdate.type === 'reset')
		if(!getCurrentUpdate){
			getCurrentUpdate = totalUpdates[0];
		}

		const currentUpdate = JSON.parse(getCurrentUpdate.updatedContent);
		const body =  {
			headers: JSON.stringify(template.headers),
			content: JSON.stringify(currentUpdate.content),
		}
    PlanService.updatePlan(parseInt(template.id),body)
		.then(rsp => {
			if (rsp.statusCode === 200) {
				setLoading(true)
				toaster("Your safety plan has been successfully returned to its original state.", Intent.SUCCESS);
			}
		}).catch (err => {
			toaster(err.error?.description, Intent.DANGER)
		}).finally(() => {
			setLoading(false)
			closeResetModal()
			reloadTemplate()
		})

	}

	return (
		<div className="update-modal-wrapper">
			<AlertModal
				isOpen={isOpen}
				size='medium'
				icon="warning-sign"
				iconColor="warning"
				title={template.name}
				onClose={() => {}}
				subtitle={<>This Template has been updated. Your safety plan will return to its original state, however variable fields will be maintained. <br/><br/> Your current safety plan will automatically be archived.</>}
				showCloseButton={false}
				loading={loading}
				alertConfirmButton={
					<Button
						text={"Continue"}
						type="primaryDefault"
						size="md"
						onClick={resetUpdates}
					/>
				}
			/>

		</div>
	);


}

ResetModal.propTypes = {
	template: PropTypes.object,
	reloadTemplate: PropTypes.func,
};

export { ResetModal };
