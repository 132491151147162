import React, { useState, useEffect, useReducer, useContext } from 'react';
import { Modal, ModalFooter, Button, ConfirmationModal, ToasterMessage } from 'componentsV2';
import { CHECKIN_STEPS } from './consts';
import {
	ManualFormStep,
	GuardiansListStep,
	ExistingGuardiansStep,
	AddStudentsStep,
	GuardianWithStudentsStep,
	SelectedStudentsStep,
} from './CheckinSteps';
import './css/GuardianCheckin.scss';
import { ReunificationService } from 'services';
import { isValidEmail, isValidPhone, isNotEmpty } from 'utilities/validations';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { useReunficationStudents } from '../../Students/hooks';
import { useSelector, useDispatch } from 'react-redux';

export const GuardianCheckin = ({ isManual = false, activeStep, onChangeModalToShow, alarmId }) => {
	const toaster = useContext(ToasterContext);
	const modalDetailsReducer = (modalDetails, action) => {
		modalDetails = action.payload;
		return {
			activeStep: modalDetails.activeStep,
			title: modalDetails.title,
			subtitle: modalDetails.subtitle,
			onConfirmHandler: modalDetails.onConfirmHandler,
			backArrowAction: modalDetails.backArrowAction,
			showCancelBtn: modalDetails.showCancelBtn,
			showConfirmBtn: modalDetails.showConfirmBtn,
			showThirdBtn: modalDetails.showThirdBtn,
			showBackBtn: modalDetails.showBackBtn,
			disabled: modalDetails.disabled,
			confirmBtnTitle: modalDetails.confirmBtnTitle,
			cancelBtnTitle: modalDetails.cancelBtnTitle,
			loading: modalDetails.loading,
			onCancelHandler: modalDetails.onCancelHandler,
			selectedGuardian: modalDetails.selectedGuardian,
		};
	};
	const [validationErrors, setErrors] = useState({});
	const [guardiansResponse, setGuardiansResponse] = useState([]);
	const [studentsResponse, setStudentsResponse] = useState([]);
	const [selectedStudents, setSelectedStudents] = useState([]);

	const [currentSelectedStudent, setCurrentSelectedStudent] = useState(null);
	const [selectedRelation, setSelectedRelation] = useState(null);
	const [selectedGuardian, setSelectedGuardian] = useState(null);
	const [addAsNew, setAddAsNew] = useState(true);
	const [totalGuardians, setTotalGuardians] = useState(0);
	const [guadianLoading, setGuardianLoading] = useState(false);
	const [manualCheckIn, setManualCheckIn] = useState(isManual);
	const [isUseSelected, setIsUseSelected] = useState(false);
	const [isEmptyGuardianData, setIsEmptyData] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [filterationData, setFilterationData] = useState({
		page: 1,
		perPage: 10,
		sortKey: 'studentName',
		sortDir: 'asc',
		studentName: '',
	});

	const [guardianFilterationData, setGuardianFilterationData] = useState({
		guardianName: '',
		searchKey: '',
		guardianPhone: '',
		includeExistingContacts: true,
		page: 1,
		perPage: 10,
		sortKey: 'guardianName',
		sortDir: 'asc',
	});

	const onChangeModalDetails = value => {
		dispatchModalDetails({
			payload: {
				...modalDetails,
				...value,
			},
		});
	};
	const dispatch = useDispatch();
	const toggleState = useSelector(state => state.route.toggleState);

	const initialState = {
		disabled: true,
		subtitle: ' ',
		activeStep: activeStep,
		onConfirmHandler: () => {},
		loading: false,
		selectedGuardian: null,
	};
	const [modalDetails, dispatchModalDetails] = useReducer(modalDetailsReducer, initialState);

	const [formData, setFormData] = useState({
		name: '',
		email: '',
		mobilePhone: '',
	});

	const closeModalAction = () => {
		onChangeModalToShow('');
	};

	const changeStep = step => {
		onChangeModalDetails({ activeStep: step });
	};

	const { loading, studentsList, totalStudents, isEmptyData } = useReunficationStudents(
		alarmId,
		filterationData,
	);

	const submitCheckIn = () => {
		onChangeModalDetails({ loading: true });
		let requestStudents = [];
		selectedStudents.forEach(item => {
			requestStudents.push({
				id: item.id,
				relation: item.relation,
				note: '',
			});
		});

		let requestBody = {
			name: selectedGuardian ? selectedGuardian.name : formData.name,
			note: '',
			guardianStudents: requestStudents,
		};
		if (selectedGuardian && selectedGuardian.email) {
			requestBody.email = selectedGuardian.email;
		} else if (formData.email) {
			requestBody.email = formData.email;
		}
		if (selectedGuardian && selectedGuardian.phone) {
			requestBody.phone = selectedGuardian.phone;
		} else if (formData.mobilePhone) {
			requestBody.phone = formData.mobilePhone;
		}
		if (!addAsNew) {
			if (selectedGuardian.personId) {
				requestBody.personId = selectedGuardian.personId;
			} else {
				requestBody.guardianId = selectedGuardian.id;
			}
		}
		ReunificationService.submitGuardianCheckin(alarmId, requestBody)
			.then(response => {
				if (response.statusCode === 201) {
					const message = (
						<ToasterMessage
							text={'Guardian Checked-In'}
							supportText={
								'The guardian has successfully been checked-in. Proceed with reunification'
							}
						/>
					);
					toaster(message, Intent.SUCCESS);
				}
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				onChangeModalDetails({ loading: false });
				dispatch({
					type: 'RELOAD_FETCHED_LIST',
					payload: !toggleState,
				});
				closeModalAction();
			});
	};

	const getGuardiansList = (formData, getAll = false) => {
		let params = {};

		if (getAll) {
			setManualCheckIn(false);
			guardianFilterationData.page == 1 && setGuardianLoading(true);
			params = guardianFilterationData;
		} else {
			setManualCheckIn(true);
			params.guardianName = formData.name;
		}
		ReunificationService.getGuardiansList(alarmId, params)
			.then(response => {
				if (response.statusCode === 200) {
					setIsEmptyData(isFirstRender && response.data.length === 0);

					if (getAll) {
						let appendData = guardianFilterationData.page == 1 ? false : true;

						if (appendData) {
							setGuardiansResponse(oldContent => [
								...oldContent,
								...response.data.records,
							]);
						} else {
							setGuardiansResponse(response.data.records);
						}
						setTotalGuardians(response.data.metadata.totalRecords);
					} else {
						if (response.data.records) {
							if (response.data.records.length == 0) {
								changeStep(CHECKIN_STEPS.ADD_STUDENTS_STEP);
							} else {
								setGuardiansResponse(response.data.records);
								if (response.data.records.length == 1) {
									setSelectedGuardian(response.data.records[0]);
								}
								changeStep(CHECKIN_STEPS.EXISTING_GUARDIANS_STEP);
							}
						} else {
							onChangeModalDetails({ loading: false });
							setGuardiansResponse([]);
							changeStep(CHECKIN_STEPS.ADD_STUDENTS_STEP);
						}
					}
				}
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setGuardianLoading(false);
				setIsFirstRender(false);
			});
	};

	const resetSelectedStudent = () => {
		setSelectedRelation(null);
	};

	const goToAddedStudentsList = () => {
		const result = [currentSelectedStudent, ...selectedStudents];
		setSelectedStudents(result);
		setSelectedRelation(null);
		changeStep(CHECKIN_STEPS.GUARDIAN_WITH_STUDENTS_STEP);
	};

	useEffect(() => {
		let appendData = filterationData.page == 1 ? false : true;
		if (!currentSelectedStudent) {
			onChangeModalDetails({ disabled: true });
		} else {
			onChangeModalDetails({ disabled: false });
		}
		if (appendData) {
			setStudentsResponse(oldContent => [...oldContent, ...studentsList]);
		} else {
			setStudentsResponse(studentsList);
		}
	}, [studentsList]);

	useEffect(() => {
		getGuardiansList(formData, true);
	}, [guardianFilterationData]);

	const hasValidateError = formData => {
		let hasValidationError = false;
		let errors = {};
		if (!isNotEmpty(formData.name)) {
			hasValidationError = true;
			errors.name = "*Name can't be empty";
		} else {
			errors.name = '';
		}

		if (formData.mobilePhone && !isValidPhone(formData.mobilePhone)) {
			hasValidationError = true;
			errors.mobilePhone = '*Mobile is not valid';
		} else {
			errors.mobilePhone = '';
		}
		if (formData.email && !isValidEmail(formData.email)) {
			hasValidationError = true;
			errors.email = '*Email is not valid';
		} else {
			errors.email = '';
		}
		setErrors(errors);
		return hasValidationError;
	};

	const setGuardianStudents = guardian => {
		let studentsList = [];

		guardian.students.forEach(item => {
			studentsList.push(item);
		});
		setSelectedStudents(studentsList);
	};

	useEffect(() => {
		let title = '',
			subtitle = '',
			showConfirmBtn = true,
			showCancelBtn = true,
			showThirdBtn = false,
			showBackBtn = true,
			confirmBtnTitle = '',
			cancelBtnTitle = '',
			onConfirmHandler = () => {},
			onCancelHandler = () => {},
			backArrowAction = () => {},
			loading = false,
			disabled = true;

		if (modalDetails.activeStep === CHECKIN_STEPS.GUARDIANS_LIST_STEP) {
			title = 'Check-in guardian';
			subtitle = 'Search guardians by name or associated phone number.';
			showThirdBtn = false;
			showBackBtn = false;
			showConfirmBtn = false;
			showCancelBtn = false;
		} else if (modalDetails.activeStep === CHECKIN_STEPS.MANUAL_FORM_STEP) {
			title = 'Check-in guardian manually';
			onConfirmHandler = () => {
				setSelectedGuardian(null);
				setGuardiansResponse([]);
				setCurrentSelectedStudent(null);
				setSelectedStudents([]);
				getGuardiansList(formData);
			};
			backArrowAction = () => {
				getGuardiansList(formData, true);
				changeStep(CHECKIN_STEPS.GUARDIANS_LIST_STEP);
			};
			confirmBtnTitle = 'Continue';
			cancelBtnTitle = 'Cancel';
			onCancelHandler = () => closeModalAction();
			if (formData.name) {
				disabled = false;
			}
		} else if (modalDetails.activeStep === CHECKIN_STEPS.EXISTING_GUARDIANS_STEP) {
			title = 'Guardian might already exist';
			subtitle = 'This guardian already exists on the list';
			backArrowAction = () => changeStep(CHECKIN_STEPS.MANUAL_FORM_STEP);
			onConfirmHandler = () => {
				setAddAsNew(false);
				setManualCheckIn(false);
				setIsUseSelected(true);
				setGuardianStudents(selectedGuardian);
				changeStep(CHECKIN_STEPS.GUARDIAN_WITH_STUDENTS_STEP);
			};
			confirmBtnTitle = 'Use selected';
			cancelBtnTitle = 'Add as new';
			onCancelHandler = () => {
				setAddAsNew(true);
				changeStep(CHECKIN_STEPS.ADD_STUDENTS_STEP);
			};
			if (guardiansResponse.length == 1) {
				if (guardiansResponse[0]['checkIn']) {
					disabled =
						guardiansResponse[0]['checkIn']['status'] == 'checkedIn' ? true : false;
				} else {
					disabled = false;
				}
			} else if (selectedGuardian) {
				disabled = false;
			}
		} else if (modalDetails.activeStep === CHECKIN_STEPS.ADD_STUDENTS_STEP) {
			title = 'Check-in guardian manually';
			backArrowAction = () => {
				resetSelectedStudent();
				changeStep(
					guardiansResponse.length == 0
						? CHECKIN_STEPS.MANUAL_FORM_STEP
						: CHECKIN_STEPS.EXISTING_GUARDIANS_STEP,
				);
			};
			onConfirmHandler = () => changeStep(CHECKIN_STEPS.SELECTED_STUDENTS_STEP);
			confirmBtnTitle = selectedStudents.length > 0 ? 'Add student' : 'Continue';
			cancelBtnTitle = 'Cancel';
			onCancelHandler = () => {
				closeModalAction();
			};
			showBackBtn = true;
			disabled = currentSelectedStudent ? false : true;
			setFilterationData(filterationData);
		} else if (modalDetails.activeStep === CHECKIN_STEPS.SELECTED_STUDENTS_STEP) {
			title = 'Check-in guardian manually';
			backArrowAction = () => {
				resetSelectedStudent();
				changeStep(CHECKIN_STEPS.ADD_STUDENTS_STEP);
			};
			onConfirmHandler = () => goToAddedStudentsList();
			confirmBtnTitle = 'Add student';
			cancelBtnTitle = 'Cancel';
			onCancelHandler = () => closeModalAction();
			showBackBtn = true;
			disabled = true;
		} else if (modalDetails.activeStep === CHECKIN_STEPS.GUARDIAN_WITH_STUDENTS_STEP) {
			title = `Check-in guardian ${manualCheckIn ? ' manually' : ''}`;
			confirmBtnTitle = 'Check-in Guardian';
			cancelBtnTitle = 'Cancel';
			onCancelHandler = () => closeModalAction();
			showBackBtn = true;
			onConfirmHandler = () => changeStep(CHECKIN_STEPS.CONFIRM_GUARDIAN_CHECKIN_STEP);
			backArrowAction = () => {
				setCurrentSelectedStudent(null);
				changeStep(
					manualCheckIn
						? CHECKIN_STEPS.ADD_STUDENTS_STEP
						: isUseSelected
						? CHECKIN_STEPS.EXISTING_GUARDIANS_STEP
						: CHECKIN_STEPS.GUARDIANS_LIST_STEP,
				);
			};
			//showThirdBtn = manualCheckIn ? false : true;
			showThirdBtn = false;
			disabled = selectedStudents.length == 0 ? true : false;
		}
		onChangeModalDetails({
			title: title,
			subtitle: subtitle,
			showConfirmBtn: showConfirmBtn,
			showCancelBtn: showCancelBtn,
			showThirdBtn: showThirdBtn,
			showBackBtn: showBackBtn,
			onConfirmHandler: onConfirmHandler,
			backArrowAction: backArrowAction,
			confirmBtnTitle: confirmBtnTitle,
			cancelBtnTitle: cancelBtnTitle,
			onCancelHandler: onCancelHandler,
			disabled: disabled,
			loading: loading,
		});

		getFooter();
	}, [modalDetails.activeStep, formData]);

	const getFooter = () => {
		const thirdButtonElement = modalDetails.showThirdBtn && (
			<Button
				text="Manually Check-in"
				type="ghostPrimary"
				className="NoPaddingBtn"
				onClick={() => {
					changeStep(CHECKIN_STEPS.MANUAL_FORM_STEP);
				}}
			/>
		);

		const confirmButtonElement = modalDetails.showConfirmBtn && (
			<Button
				text={modalDetails.confirmBtnTitle}
				intent="default"
				disabled={modalDetails.disabled}
				onClick={modalDetails.onConfirmHandler}
			/>
		);
		const cancelButtonElement = modalDetails.showCancelBtn && (
			<Button
				text={modalDetails.cancelBtnTitle}
				type="tertiary"
				intent="default"
				onClick={modalDetails.onCancelHandler}
			/>
		);

		return (
			<ModalFooter
				leftContent={thirdButtonElement}
				confirmButton={confirmButtonElement}
				cancelButton={cancelButtonElement}
			/>
		);
	};

	return (
		<Modal
			isOpen={true}
			onClose={closeModalAction}
			title={modalDetails.title}
			subtitle={modalDetails.subtitle}
			size="medium"
			showCloseButton={true}
			showBackArrow={modalDetails.showBackBtn}
			backArrowAction={modalDetails.backArrowAction}
			scrollContent={false}
			customBodyClass={`${
				modalDetails.activeStep == CHECKIN_STEPS.ADD_STUDENTS_STEP ||
				modalDetails.activeStep == CHECKIN_STEPS.SELECTED_STUDENTS_STEP ||
				modalDetails.activeStep == CHECKIN_STEPS.GUARDIAN_WITH_STUDENTS_STEP ||
				modalDetails.activeStep == CHECKIN_STEPS.MANUAL_FORM_STEP
					? ' CustomBorderedContent'
					: ''
			}`}
			customClassName="guardian-checkin-modal-container"
			loading={modalDetails.loading}
			customFooter={getFooter()}
		>
			{modalDetails.activeStep === CHECKIN_STEPS.MANUAL_FORM_STEP && (
				<ManualFormStep
					formData={formData}
					setFormData={setFormData}
					validationErrors={validationErrors}
					getGuardiansList={getGuardiansList}
					onChangeModalDetails={onChangeModalDetails}
					hasValidateError={hasValidateError}
				/>
			)}
			{modalDetails.activeStep === CHECKIN_STEPS.GUARDIANS_LIST_STEP && (
				<GuardiansListStep
					guardiansResponse={guardiansResponse}
					selectedGuardian={selectedGuardian}
					setSelectedGuardian={setSelectedGuardian}
					guardianFilterationData={guardianFilterationData}
					setGuardianFilterationData={setGuardianFilterationData}
					totalGuardians={totalGuardians}
					guadianLoading={guadianLoading}
					setSelectedStudents={setSelectedStudents}
					setAddAsNew={setAddAsNew}
					onChangeModalDetails={onChangeModalDetails}
					isEmptyData={isEmptyData}
					setIsUseSelected={setIsUseSelected}
				/>
			)}
			{modalDetails.activeStep === CHECKIN_STEPS.EXISTING_GUARDIANS_STEP &&
				guardiansResponse.length > 0 && (
					<ExistingGuardiansStep
						guardiansResponse={guardiansResponse}
						onChangeModalDetails={onChangeModalDetails}
						setSelectedGuardian={setSelectedGuardian}
						selectedGuardian={selectedGuardian}
						setAddAsNew={setAddAsNew}
						setManualCheckIn={setManualCheckIn}
						changeStep={changeStep}
						setGuardianStudents={setGuardianStudents}
						setIsUseSelected={setIsUseSelected}
					/>
				)}
			{modalDetails.activeStep === CHECKIN_STEPS.ADD_STUDENTS_STEP && (
				<AddStudentsStep
					formData={formData}
					studentsResponse={studentsResponse}
					onChangeModalDetails={onChangeModalDetails}
					filterationData={filterationData}
					setFilterationData={setFilterationData}
					totalStudents={totalStudents}
					setSelectedStudents={setSelectedStudents}
					selectedStudents={selectedStudents}
					setCurrentSelectedStudent={setCurrentSelectedStudent}
					currentSelectedStudent={currentSelectedStudent}
					isEmptyData={isEmptyData}
					selectedGuardian={selectedGuardian}
					addAsNew={addAsNew}
				/>
			)}
			{modalDetails.activeStep === CHECKIN_STEPS.GUARDIAN_WITH_STUDENTS_STEP && (
				<GuardianWithStudentsStep
					formData={formData}
					onChangeModalDetails={onChangeModalDetails}
					selectedStudents={selectedStudents}
					changeStep={changeStep}
					setSelectedStudents={setSelectedStudents}
					currentSelectedStudent={currentSelectedStudent}
					setCurrentSelectedStudent={setCurrentSelectedStudent}
					selectedGuardian={selectedGuardian}
					addAsNew={addAsNew}
					manualCheckIn={manualCheckIn}
				/>
			)}
			{modalDetails.activeStep === CHECKIN_STEPS.SELECTED_STUDENTS_STEP && (
				<SelectedStudentsStep
					currentSelectedStudent={currentSelectedStudent}
					formData={formData}
					onChangeModalDetails={onChangeModalDetails}
					changeStep={changeStep}
					setCurrentSelectedStudent={setCurrentSelectedStudent}
					selectedRelation={selectedRelation}
					setSelectedRelation={setSelectedRelation}
					selectedStudents={selectedStudents}
					selectedGuardian={selectedGuardian}
					addAsNew={addAsNew}
				/>
			)}
			<ConfirmationModal
				isOpen={modalDetails.activeStep === CHECKIN_STEPS.CONFIRM_GUARDIAN_CHECKIN_STEP}
				title="Are you sure you want to check-in this guardian?"
				subtitle={
					manualCheckIn
						? 'Once checked-in, the guardian will be added to the list and can be reunified with the students associated with them.'
						: 'Once checked-in they cannot be edited but only removed from the list.'
				}
				showCloseButton={false}
				cancelBtn={{
					title: 'Cancel',
					onClick: () => changeStep(CHECKIN_STEPS.GUARDIAN_WITH_STUDENTS_STEP),
				}}
				loading={modalDetails.loading}
				confirmBtn={{
					title: 'Check-in guardian',
					onClick: () => {
						submitCheckIn();
					},
					type: 'primaryDefault',
				}}
			/>
		</Modal>
	);
};
