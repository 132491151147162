import React, { useContext } from 'react';
import { ActionsButton, Button } from 'componentsV2';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from 'react-redux';
import { CallListContext } from '../Providers';
import { CALLLIST_ROUTES } from 'utilities/constants';

const ActionListButton = ({ text, icon, onClick }) => (
	<Button
		key={text}
		text={text}
		icon={icon}
		size="md"
		type="ghostDefault"
		wrapperClass="displayBlock"
		className="actionButtonElement"
		onClick={onClick}
	/>
);

export const CallListsActionsButton = ({ row, history }) => {
	const { editCallList, deleteCallList } = useContext(CallListContext);
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('call_list_edit', permissions, row.site_id);

	return (
		<ActionsButton>
			<ActionListButton
				text="View"
				icon="eye-open"
				onClick={() =>
					history.push({
						pathname: `${CALLLIST_ROUTES.CONTACTSV2 + row.id}`,
						state: { contact: row.id },
					})
				}
			/>
			{editPermission && (
				<>
					<ActionListButton
						text="Edit"
						icon="edit"
						onClick={() => editCallList(row.id)}
					/>
					<ActionListButton
						text="Delete"
						icon="trash"
						onClick={() => deleteCallList(row)}
					/>
				</>
			)}
		</ActionsButton>
	);
};
