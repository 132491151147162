import React from "react";
import { NavLink } from 'react-router-dom';
import ActionsButton from "components/ActionsButton";
import { Icon } from '@blueprintjs/core';
import { Button } from "components/Button";
import { checkPermission } from 'utilities/permissions';
import { useSelector } from "react-redux";

const CallListsActionsButton = ({
    id,
    row,
    setModalToShow,
    loadEditModal,
    setCallListID,
}) => {
    const permissions = useSelector( state => state.route.permissions );
    const editPermission = checkPermission('call_list_edit', permissions, row.site_id);

    const onDeleteActionHandler = () => {
        setCallListID(row.id)
        setModalToShow('deleteCallList');
    }

    return (
        <ActionsButton>
            <NavLink
                    exact
                    to={{
                        pathname:`/contacts/${row.id}`,
                        state: {'contact': row.id}  
                    }}
                    className="bp3-button bp3-minimal w-100 justify-content-start"
                >
                <Icon icon='eye-open'
                    minimal 
                />
                <span className="bp3-button-text">View Contacts</span>
            </NavLink>
            {editPermission && (
                <>
                <Button
                    icon="edit"
                    minimal
                    text="Edit"
                    className="w-100 justify-content-start"
                    onClick={()=> loadEditModal(row.id)}
                />
                <hr />
                <Button
                    icon="trash"
                    intent="Danger"
                    minimal
                    text="Delete"
                    className="w-100 justify-content-start"
                    onClick={onDeleteActionHandler}
                />
            </>
            )}
        </ActionsButton>
    )
}

export default CallListsActionsButton;