import React from 'react';
import Step1 from "./BulkUsersMultiStep/MultiStep1";
import Step2 from "./BulkUsersMultiStep/MultiStep2";

const BulkUsersStepsHandler = ({
	currentStep,
    next,
    setDisableBtns,
    back,
    setChangesOccuredFlag,
    loading,
    setLoading,
    setFormData,
    validationResData,
    uploadText,
    setUploadText,
    xslContentClass,
    setxslContentClass,
    setXslContent,
    xslImg,
    setXslImg,
    xslContent,
    uploadLabel,
    setUploadLabel


}) => {
    switch (currentStep) {
		case 1:
            return <Step1 
                next={next} 
                setDisableBtns={setDisableBtns}
                setChangesOccuredFlag={setChangesOccuredFlag}
                loading={loading}
                setLoading={setLoading}
                setFormData={setFormData}
                uploadText={uploadText}
                setUploadText={setUploadText}
                xslContentClass={xslContentClass}
                setxslContentClass={setxslContentClass}
                setXslContent={setXslContent}
                xslImg={xslImg}
                setXslImg={setXslImg}
                xslContent={xslContent}
                uploadLabel={uploadLabel}
				setUploadLabel={setUploadLabel}
            />
        case 2 :
            return <Step2
                next={next}
                back={back}
                setDisableBtns={setDisableBtns}
                loading={loading}
                setLoading={setLoading}
                validationResData={validationResData}
                
            />
    }
};

export default BulkUsersStepsHandler
