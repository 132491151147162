import React from 'react';
import './css/Tag.scss';
import { Icon } from '@blueprintjs/core';

export const Tag = ({ 
	type = 'default', 
	text = '', 
	size = 'sm', 
	hasCloseIcon = false, 
	onClick = null,
	clickable = true,
}) => {
	if(type == 'disabled' || !clickable) {
		onClick = null;
	}
	return (
		<div className="Tag__wrapper">
			<div  className={`tag ${type} ${size} ${!clickable ? "not-clickable" : ""}`}>
				<span>{text}</span>
				{ hasCloseIcon && (
					<Icon className="tab-icon tag-close" icon="cross" onClick={onClick}/>
				)}
			</div>
		</div>
	);
};
