import { ToasterContext, FeaturedIcon } from 'componentsV2';
import React, { useContext, useState } from 'react';
import { DELETE_NOTE_MODAL } from '../../../consts';
import { Intent } from '@blueprintjs/core';
import { FloorplanService } from 'services/FloorplanService';

export const DeleteNoteModalFn = (setLoading, closeModal, setModalToShow, reloadContent) => {
	const toaster = useContext(ToasterContext);
	const [note, setNote] = useState(null);
	const [selectedNoteShape, setSelectedNoteShape] = useState(null);

	const showDeleteNoteModal = (note, selectedShape) => {
		setNote(note);
		setSelectedNoteShape(selectedShape);
		setModalToShow(DELETE_NOTE_MODAL);
	};

	const deleteNodeHandler = () => {
		setLoading(true);

		FloorplanService.DeleteShapeNote(note.id)
			.then(() => {
				toaster(
					`You have successfully deleted this note from this map area.`,
					Intent.NONE,
					<FeaturedIcon icon='trash' size='md' type='Gray' shape={'circleIcon'} />,
					2000,
					false,
					'map-view-page',
				);
				closeModal();
				reloadContent();
			})
			.catch(error => {
				console.log(error);
				toaster(
					`Something went wrong, please try again later`,
					Intent.DANGER,
					null,
					2000,
					false,
					'map-view-page',
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		showDeleteNoteModal,
		deleteNodeHandler,
		selectedNoteShape,
	};
};
