import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { checkMark } from 'assets/icons';

const Wrapper = styled.div`
	height: 20px;
	display: flex;
	color: #5c7080;
	font-size: 12px;
	margin-top: 3px;
	font-weight: 600;
	padding: 3px 8px;
	margin-left: 25px;
	font-style: normal;
	align-items: center;
	border-radius: 10px;
	flex-direction: row;
	font-family: Nunito Sans;
	background-color: #ebf1f5;

	img {
		width: 7px;
		height: 5px;
		margin-right: 9px;
	}
`;

const Autosave = ({ autoSaveValue }) => {
	const isDate = autoSaveValue instanceof Date;
	const date = autoSaveValue.toLocaleString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});

	return (
		<Wrapper>
			{isDate ? (
				<>
					<img alt="" src={checkMark} />
					Auto saved &#8226;&#160;
					{date}
				</>
			) : (
				'Not saved'
			)}
		</Wrapper>
	);
};

export { Autosave };

Autosave.propTypes = {
	autoSaveValue: PropTypes.oneOf([
		PropTypes.instanceOf(Date),
		PropTypes.string,
	]),
};
