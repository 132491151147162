import React, { useContext, useState } from 'react';
import { ConfirmationModal, ModalFooter, Button } from 'componentsV2';
import { CreateAlarmTypeContext } from '../provider';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { RapidAlarmService } from 'services/RapidAlarmService';
import { CANCEL_CREATION_MODAL } from '../consts';

export const FinalizeAlarmModal = () => {
	const {
		openModal,
		closeModal,
		history,
		formData,
		getCreateAlarmParams,
		setShouldPromptSaveModal,
		setLocationPath,
	} = useContext(CreateAlarmTypeContext);
	const [loading, setLoading] = useState(false);
	const toaster = useContext(ToasterContext);

	const onConfirmAction = () => {
		setLoading(true);
		setShouldPromptSaveModal(false);
		setLocationPath('');
		const params = getCreateAlarmParams();
		RapidAlarmService.createAlarmType(params)
			.then(response => {
				if (response.statusCode === 201) {
					toaster(`You have successfully finalized "${formData.name}"`, Intent.SUCCESS);
					return history.push('/rapid-alarms/settings');
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				closeModal();
				setLoading(false);
			});
	};

	return (
		<ConfirmationModal
			isOpen={true}
			loading={loading}
			title={`Are you sure you want to finalize "${formData.name}"?`}
			subtitle="Once finalized it will appear in your alarm types as enabled. You can disabled it in edit mode."
			customFooter={
				<ModalFooter
					leftContent={
						<Button
							text="Cancel"
							type="ghostDefault"
							onClick={() => openModal(CANCEL_CREATION_MODAL)}
						/>
					}
					confirmButton={<Button text="Finalize it" onClick={onConfirmAction} />}
					cancelButton={<Button text="Go back" type="tertiary" onClick={closeModal} />}
				/>
			}
		/>
	);
};
