import React from 'react';
import { CardContainer, FeaturedIcon } from 'componentsV2';
import './css/PlanUpdates.scss';
import { Icon } from '@blueprintjs/core';

export const PlanUpdates = ({ title, onClick }) => (
	<CardContainer
		cardCustomClass="plan-update-container"
		bodyCustomClass="plan-update-body"
	>
		<FeaturedIcon icon="error" size="lg" type="Warning" className="icon" />
		<div className="description">
			<p className="title">{title}</p>
			<p className="clickable-link" onClick={onClick}>
				View update <Icon icon="arrow-right" size={12} />
			</p>
		</div>
	</CardContainer>
);
