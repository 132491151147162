import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'componentsV2';
import { Header } from './components';
import { RapidAlarmsProviders } from '../';
import './css/RapidAlarmDetails.scss';

export const RapidAlarmDetails = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getRapidAlaram = () => {
			//api calling
			setLoading(false);
		};

		getRapidAlaram();
	}, []);

	return (
		<div className="rapid-alarm-details-container">
			{loading && <LoadingSpinner />}
			{!loading && (
				<RapidAlarmsProviders>
					<Header />
				</RapidAlarmsProviders>
			)}
		</div>
	);
};
