import React from 'react';
import PropTypes from 'prop-types';
import { backArrow } from 'assets/icons';
import './index.scss';

const BackButton = ({ goBack }) => (
	<div className='back-btn' onClick={goBack}>
		<img alt="" src={backArrow} />
	</div>
);

export { BackButton };

BackButton.propTypes = {
	goBack: PropTypes.func,
};
