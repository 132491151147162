import React, { createContext, useRef, useState } from 'react';
import { Toaster, Position } from '@blueprintjs/core';
import './css/ToasterNotification.scss';
import { FeaturedIcon } from '../';

const ToasterContext = createContext();

const Intent = {
	NONE: 'none',
	PRIMARY: 'primary',
	SUCCESS: 'success',
	WARNING: 'warning',
	DANGER: 'danger',
};

const ToasterNotification = ({ children }) => {
	const toasterRef = useRef();
	const [toastPosition, setToastPosition] = useState(Position.TOP_RIGHT);
  
	const changeToastPosition = newPosition => {
		setToastPosition(newPosition);
  };

	const showToaster = (
		message,
		intent,
		icon = null,
		timeout = 5000,
		hasCloseButton = false,
		customClassName = '',
		position = null,
	) => {
		const toastParams = {
			message,
			className: `Toaster__wrapper ${intent} ${
				hasCloseButton === false ? 'no-close-button' : ''
			} ${customClassName}`,
			icon,
			timeout,
		};
		// Set default icon for Success
		if (intent == Intent.SUCCESS && !icon) {
			toastParams.icon = <FeaturedIcon icon="tick" type="Success" />;
		}
		// Set default icon for Error
		if (intent == Intent.DANGER && !icon) {
			toastParams.icon = <FeaturedIcon icon="error" type="Error" />;
		}
    // Set default icon for Warning
    if (intent == Intent.WARNING && !icon) {
			toastParams.icon = <FeaturedIcon icon="error" type="Warning" shape={'circleIcon'} />;
		}

		if (position) {
			changeToastPosition(position);
		}
		toasterRef.current.show(toastParams);
	};

	return (
		<ToasterContext.Provider value={showToaster}>
			<Toaster ref={toasterRef} position={toastPosition} className="Toaster__container" />
			{children}
		</ToasterContext.Provider>
	);
};

export { ToasterNotification, ToasterContext, Intent };
