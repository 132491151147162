import { Intent } from "componentsV2";
import { useState } from "react";
import { TemplateService } from "services/TemplateService";

export const DeleteTemplateModalFn = (
    modalName,
	setLoading,
	templateData,
    dispatchTemplateData,
	closeModal,
	toaster,
	setModalToShow,
	history,
    reloadData
) => {

    const [source, setSource] = useState(null);

    const showDeleteModal = (template, source = null) => {
        setModalToShow(modalName);
        dispatchTemplateData({
            ...templateData,
            id: template.id,
            title: `“${template.name}”`,
            modalTitle: `Are you sure you want to delete “${template.name}”?`,
            confirmBtnTitle: `Yes, delete it`,
        });

        setSource(source);
    }

    const handleDeleteSubmit = () => {
        setLoading(true);
        TemplateService.deleteTemplate(templateData.id)
            .then(() => {
                toaster(`You have successfully deleted ${templateData.title}`, Intent.SUCCESS);
                closeModal();
                if (source == 'list') {
                    reloadData();
                } else {
                    history.push('/safety-plans');
                }
            })
            .catch((error) => { 
                toaster('Something went wrong, please try again later', Intent.DANGER);
             })
            .finally(() => { setLoading(false); });
    }

    return {
        showDeleteModal,
        handleDeleteSubmit
    }
}    