import React, { useState, useEffect, useContext, useReducer } from 'react';
import { Button } from 'components/Button';
import { MasqueradeService } from 'services/MasqueradeService';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import { UserService, USER_STATUS } from '../../../../services/UserService';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal';

export default function ModuleAccount(props) {
	const [btnEnabled, setBtnEnabled] = useState(false);
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [btnEnableSwitch, setBtnEnableSwitch] = useState('switchon');
	const [btnDisableSwitch, setBtnDisableSwitch] = useState('switchoff');
	const [modalToShow, setModalToShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const [statusText, setStatusText] = useState('');
	const [isSuperAdmin, setIsSuperAdmin] = useState(JSON.parse(props.isSuperAdmin));
	const [isMyAccount, setIsMyAccount] = useState(JSON.parse(props.isMyAccount));
	const [isMasquerading, setIsMasquerading] = useState(MasqueradeService.isMasquerading());

	const toaster = useContext(ToasterContext);
	const dispatch = useDispatch();

	const userParamsReducer = (userParams, action) => {
		switch (action.type) {
			default:
				userParams[action.type] = action.payload;
				break;
		}
		return {
			user: userParams.user,
			userStatus: userParams.userStatus,
			canMasquerade: userParams.canMasquerade,
			canDelete: userParams.canDelete,
		};
	};

	const [userParams, dispatchUserParams] = useReducer(userParamsReducer, {
		user: props.user,
		userStatus: props.userStatus,
		canMasquerade: false,
		canDelete: false,
	});

	useEffect(() => {
		const canMasquerade =
			!isMasquerading &&
			!isMyAccount &&
			isSuperAdmin &&
			props.userStatus == USER_STATUS.ENABLED;
		const canDelete = !isMasquerading && !isMyAccount && isSuperAdmin;
		dispatchUserParams({ type: 'canMasquerade', payload: canMasquerade });
		dispatchUserParams({ type: 'canDelete', payload: canDelete });
	}, [props, isSuperAdmin, isMyAccount, isMasquerading]);

	useEffect(() => {
		updateBtnsState();
		if (!props.hasUserEditPermission && !props.isSuperAdmin) {
			setBtnEnabled(true);
			setBtnDisabled(true);
		}
	}, [userParams.userStatus]);

	const updateBtnsState = () => {
		if (userParams.userStatus == USER_STATUS.ENABLED) {
			setBtnEnabled(true);
			setBtnDisabled(false);
			setBtnEnableSwitch('switchon');
			setBtnDisableSwitch('switchoff');
			setStatusText('This account is active. The user can login to their account.');
		} else {
			setBtnEnabled(false);
			setBtnDisabled(true);
			setBtnEnableSwitch('switchoff');
			setBtnDisableSwitch('switchon');
			setStatusText('This account is inactive. The user cannot login to their account.');
		}
	};

	const disabledBtnClicked = () => {
		setModalToShow('disableUser');
	};

	const enabledBtnClicked = () => {
		setModalToShow('enableUser');
	};

	const deleteBtnClicked = () => {
		setModalToShow('deleteUserModal');
	};

	const toggleUserStatus = () => {
		setLoading(true);
		Promise.all([UserService.toggleStatus(userParams.user.id)])
			.then(([response]) => {
				dispatchUserParams({
					type: 'userStatus',
					payload: response.person.status,
				});
				setModalToShow(false);
				setLoading(false);
				props.updateUserStatus(response.person.status);

				if (response.person.status == 1) {
					toaster('User successfully enabled', Intent.SUCCESS);
				} else {
					toaster('User successfully disabled', Intent.SUCCESS);
				}
			})
			.catch(error => {
				setLoading(false);
				console.log(error);
			});
	};

	const startMasquerade = () => {
		const params = {
			target_user_id: userParams.user.id,
		};
		setLoading(true);
		MasqueradeService.startMasqueradingSession(params)
			.then(response => {
				if (response.statusCode === 200) {
					// get token
					const { accessToken } = response.data;
					return UserService.getUserById(userParams.user.id)
						.then(resp => {
							if (resp.statusCode === 200) {
								// get info for current user
								const masqueradingUserToken = JSON.parse(
									localStorage.getItem('user'),
								).jwt;
								const masqueradingUserData = JSON.parse(
									localStorage.getItem('loggedUserData'),
								);
								// store current user token & info in localStorage
								dispatch({
									type: 'SET_MASQUERADING_USER_TOKEN',
									payload: masqueradingUserToken,
								});
								dispatch({
									type: 'SET_MASQUERADING_USER_DATA',
									payload: masqueradingUserData,
								});
								// overwrite current user info by masquerading user info
								dispatch({
									type: 'SET_USER',
									payload: accessToken,
								});
								// localStorage.setItem('user', JSON.stringify({ jwt: accessToken }));
								dispatch({
									type: 'SET_USER_DATA',
									payload: resp.data,
								});
								location.reload();
							}
						})
						.catch(err => {
							toaster(err.error.description, Intent.DANGER);
						});
				}
				toaster('Masquerading failed', Intent.DANGER);

				setLoading(false);
			})
			.catch(err => {
				setLoading(false);
				toaster(err.error.description, Intent.DANGER);
			});
	};

	const handleMasqueradeClick = () => {
		setModalToShow('masqueradingUserModal');
	};

	const deleteUser = () => {
		setLoading(true);
		setModalToShow(false);

		UserService.deleteUser(userParams.user.id)
			.then(() => {
				setLoading(false);
				window.location.replace(`${location.protocol}//${location.host}/user-management`);
				sessionStorage.setItem('flashSuccessMsg', 'User successfully deleted.');
			})
			.catch(error => {
				setLoading(false);
				console.log(error);
				toaster("This user can't be deleted", Intent.DANGER);
			});
	};

	return (
		<>
			<div className="module-account">
				<h4>Account Controls</h4>
				<h5>Account Access</h5>
				<Button
					text="Enabled"
					intent={btnEnableSwitch}
					disabled={btnEnabled}
					onClick={enabledBtnClicked}
				/>
				&nbsp;
				<Button
					text="Disabled"
					intent={btnDisableSwitch}
					disabled={btnDisabled}
					onClick={disabledBtnClicked}
				/>
				<p className="">{statusText}</p>
				{userParams.canMasquerade && (
					<>
						<h5>Masquerade</h5>
						<p>
							Use masquerade mode to view the user's account from their perspective.
						</p>
						<Button
							text="Masquerade"
							intent="secondary"
							onClick={handleMasqueradeClick}
						/>
					</>
				)}
				{userParams.canDelete && (
					<>
						<h5>Delete Account</h5>
						<p>
							Delete to remove the user’s account from the system. This action cannot
							be undone.
						</p>
						<Button text="Delete" intent="secondary" onClick={deleteBtnClicked} />
					</>
				)}
			</div>

			{modalToShow === 'disableUser' && (
				<ConfirmationModal
					title="Disable User?"
					text={[<p>Are you sure you want to disable this user?</p>]}
					confirmBtnTxt="Disable"
					closeModal={() => setModalToShow(false)}
					submitModel={toggleUserStatus}
					loading={loading}
				/>
			)}

			{modalToShow === 'enableUser' && (
				<ConfirmationModal
					title="Enable User?"
					text={[<p>Are you sure you want to enable this user?</p>]}
					confirmBtnTxt="Enable"
					closeModal={() => setModalToShow(false)}
					submitModel={toggleUserStatus}
					loading={loading}
				/>
			)}

			{modalToShow === 'deleteUserModal' && (
				<ConfirmationModal
					title="Delete User Account"
					text={[
						<>
							<p>
								Deleting this user will remove their account from the system. This
								action can not be undone.
							</p>
							<p>
								Consider changing the user's account status to{' '}
								<span style={{ color: '#9F1C3A' }}>disabled</span> instead.
							</p>
						</>,
					]}
					confirmBtnTxt="Delete"
					closeModal={() => setModalToShow(false)}
					submitModel={deleteUser}
					loading={loading}
					danger
				/>
			)}

			{modalToShow === 'masqueradingUserModal' && (
				<ConfirmationModal
					title="Masquerade as User"
					text={[
						<>
							<p>Are you sure you want to masquerade as this user?</p>
						</>,
					]}
					confirmBtnTxt="Yes"
					closeModal={() => setModalToShow(false)}
					submitModel={startMasquerade}
					loading={loading}
				/>
			)}
		</>
	);
}
