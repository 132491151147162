import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dialog, Classes, Callout } from '@blueprintjs/core';
import { Input } from 'components/Input';
import { LoadingSpinner } from 'components/LoadingSpinner';

const HrWrapper = styled.hr`
	widht: 90%;
`;

const CreateDialog = styled(Dialog)`
	width: 300px;
`;

const DialogFooterWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 10px;
`;

class CopySectionModal extends React.Component {
	state = { name: '', loading: false };

	updateFields = (key, val) => this.setState({ [key]: val });

	copySection = () => {
		const { templateId, getData, copySectionId } = this.props;
		const { name } = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${
				process.env.API_URL
			}/templates/${templateId}/sections/${copySectionId}/copy`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					name,
				}),
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					return getData();
				}
				return null;
			});
	};

	render() {
		const { closeCopy } = this.props;
		const { name, loading } = this.state;
		return (
			<CreateDialog
				isOpen
				onClose={closeCopy}
				title="Edit Template"
				{...this.state}
			>
				<div className={Classes.DIALOG_BODY}>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<Callout intent="primary" className="mb-2">
								Give your copied section a new name. Please
								refrain from using a duplicate section name.
							</Callout>
							<Input
								placeholder="Enter a new section name"
								label="Section"
								large
								onChange={e =>
									this.updateFields('name', e.target.value)
								}
								value={name}
							/>
							<HrWrapper />
						</>
					)}
				</div>
				<DialogFooterWrapper>
					<Button
						style={{
							marginRight: 16,
							height: 40,
							width: 68,
						}}
						onClick={closeCopy}
						text="Cancel"
						intent="none"
					/>
					<Button
						style={{
							height: 40,
							width: 68,
							background: '#9F1C3A',
						}}
						onClick={this.copySection}
						text="Copy"
						intent="danger"
					/>
				</DialogFooterWrapper>
			</CreateDialog>
		);
	}
}

CopySectionModal.propTypes = {
	editTemplate: PropTypes.object,
	updateTemplatesArray: PropTypes.func,
	closeCopy: PropTypes.func,
	templateId: PropTypes.any,
	copySectionId: PropTypes.any,
	getData: PropTypes.func,
};

export default CopySectionModal;
