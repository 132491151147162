const isStringUrl = str => {
	const pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i',
	); // fragment locator
	return !!pattern.test(str);
};

const removeVariables = (content, variableValues) => {
	let tempContent = content;
	variableValues.forEach(item => {
		if (isStringUrl(item.variableValue)) {
			tempContent = tempContent.replaceAll(
				`<div><img src="${
					item.variableValue
				}" style="width: auto; height: 300px"/></div>`,
				`<span class="variable-highlight">${item.variableName}</span>`,
			);
		}
		tempContent = tempContent.replaceAll(
			`<span class="variable-value-highlight" contenteditable="false">${
				item.variableValue
			}</span>`,
			`<span class="variable-highlight">${item.variableName}</span>`,
		);
	});
	return tempContent;
};

export { removeVariables };
