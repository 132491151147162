import React, {useState} from "react";
import {SearchBar} from "components/SearchBar";
import {ContentSwitcher} from "componentsV2";

export const Filtration = ({
                               filtrationData,
                               setFiltrationData,
                           }) => {

    const [nameValue, setNameValue] = useState(filtrationData?.name || '')

    const TemplatesFilterTabs = [
        {id: '1', label: 'View all'},
        {id: '2', label: 'Archived'},
        {id: '3', label: 'Unarchived'},
    ];

    const onSearchHandler = name => {
        setNameValue(name);
        setFiltrationData(oldData => ({
            ...oldData,
            name: name,
            page: 1
        }));
    }
    
    const onClickHandler = (tab) => {
        if (tab && tab[0]) {
            let newData = {};
            const page = 1;
            switch (tab[0]) {
                case '1':
                    newData = {archive: null, page: page};
                    break;
                case '2':
                    newData = {archive: true, page: page};
                    break;
                case '3':
                    newData = {archive: 'false', page: page};
                    break;
                default:
                    break;
            }
            setFiltrationData(oldData => ({...oldData, ...newData}));
        }
    };

    return (
        <section className="filtration-container">
            <div className="left-side">
                <ContentSwitcher onClick={onClickHandler} tabs={TemplatesFilterTabs} multi={false}
                                 defaultActiveTabs={['1']} preserveTab={true}/>
            </div>
            <div className="right-side">
                <SearchBar
                    placeholder="Search by title..."
                    icon="search"
                    className="search-bar-text"
                    onSearch={onSearchHandler}
                    value={nameValue}
                />
            </div>
        </section>
    );
}