import { FloorplanService } from 'services';
import { useState, useContext } from 'react';
import { Intent } from '@blueprintjs/core';
import { ToasterContext } from 'pages/App';
import { floorplanDetails } from '../../../Details/CanvasDrawFn';

export const ViewUpdateCameraLinkModalFn = (
	setLoading,
	closeModal,
	setCameraLink,
	reloadContent,
) => {
	const [cameraLinkSaveBtnDisabled, setCameraLinkSaveBtnDisabled] = useState(true);
	const toaster = useContext(ToasterContext);
	const [oldCameraLink, setOldCameraLink] = useState({});
	const [newCameraLink, setNewCameraLink] = useState(null);
	const [selectedShape, setSelectedShape] = useState({});
	const [cameraLinkHasError, setCameraLinkHasError] = useState(false);
	const [cameraLinkErrorMsg, setCameraLinkErrorMsg] = useState(null);

	const cameraLinkUpdateChangeHandler = (e, oldCameraLink, selectedShape) => {
		let text = e.target.value;

		setCameraLink(text);
		setNewCameraLink(text);
		setOldCameraLink(oldCameraLink);
		setSelectedShape(selectedShape);

		if (text.trim() == '' || text == oldCameraLink.url) {
			setCameraLinkSaveBtnDisabled(true);
		} else {
			setCameraLinkSaveBtnDisabled(false);
		}
	};

	const updateCameraLinkHandler = () => {
		setLoading(true);
		setCameraLinkHasError(false);
		setCameraLinkErrorMsg(null);

		FloorplanService.UpdateShapeCameraLink(
			floorplanDetails.id,
			selectedShape.id,
			oldCameraLink.id,
			{
				web_address: newCameraLink,
			},
		)
			.then(res => {
				if (res.statusCode == 200) {
					toaster(
						`You have successfully updated the camera web link for ${
							selectedShape.title
						}`,
						Intent.SUCCESS,
						null,
						2000,
						false,
						'map-view-page',
					);
					closeModal();
					reloadContent();
				}
			})
			.catch(error => {
				if (error.statusCode == 400 && error.error?.fields?.web_address) {
					setCameraLinkHasError(true);
					setCameraLinkErrorMsg('Web address must be a valid url');
				} else {
					toaster(
						'Something went wrong, please try again.',
						Intent.DANGER,
						null,
						2000,
						false,
						'map-view-page',
					);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		cameraLinkSaveBtnDisabled,
		cameraLinkUpdateChangeHandler,
		updateCameraLinkHandler,
		cameraLinkHasError,
		cameraLinkErrorMsg,
	};
};
