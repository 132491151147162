import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const EmailTimer = ({ seconds, handleTimerEnd, message }) => {
	const [countdown, setCountdown] = useState(seconds);

	useEffect(() => {
		const interval = setInterval(() => {
			if (countdown > 0) {
				setCountdown(countdown - 1);
			}
			if (countdown === 1) {
				clearInterval(interval);
				handleTimerEnd();
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [countdown]);

	return (
		<>
			{message}
			{countdown > 0 ? (
				<span class="timer"> 0:{countdown}</span>
			) : (
				<span class="timer"> 0:0</span>
			)}
		</>
	);
};

EmailTimer.propTypes = {
	seconds: PropTypes.number.isRequired,
	handleTimerEnd: PropTypes.func.isRequired,
	message: PropTypes.string.isRequired,
};

export default EmailTimer;
