import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Icon } from '@blueprintjs/core';
import { flags } from 'utilities';
import { LoadingSpinner } from 'components/LoadingSpinner';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import { checkPermissionByCodeAndSites, checkPermission } from 'utilities/permissions';
import { UserService } from 'services/UserService';
import { PageHeader } from 'components/PageHeader';
import { USER_ACCESS_TYPES, CALLLIST_ROUTES } from 'utilities/constants';
import AvatarInitials from '../../AvatarInitials';
import ModuleAccount from './ModuleAccount';
import ModuleDetails from './ModuleDetails';
import ModulePasswordOptions from './ModulePasswordOptions';
import ModuleRespondSettings from './ModuleRespondSettings';
import SitePermissions from './SitePermissions';
import UploadProfilePhoto from './UploadProfilePhoto';
import './account-manager.scss';
import SystemSync from './SystemSync';
import ActivityModule from './ActivityModule';

export default function AccountManager(props) {
	const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
	const isMyAccount =
		props.match.path.includes('myprofile') ||
		parseInt(props.match.params.id) === loggedUserData.id;
	const userId = isMyAccount ? loggedUserData.id : props.match.params.id;
	const [user, setUser] = useState({});
	const [userModule, setUserModule] = useState('manageAccount');
	const [avatarImg, setAvatarImg] = useState('');
	const [profileImage, setProfileImage] = useState(null);
	const [personName, setPersonName] = useState([]);
	const [personStatus, setPersonStatus] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [Userupdated, setUserupdated] = useState(true);
	const [tabHasChange, setTabHasChange] = useState(false);
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	const [newModule, setNewModule] = useState();
	const [showImageModal, setShowImageModal] = useState(false);
	const [userProfileId, setUserProfileId] = useState(null);
	const [userInitials, setUserInitials] = useState(null);
	const permissions = useSelector((state) => state.route.permissions);
	const [hasUserEditPermission, setHasUserEditPermission] = useState(false);
	const isSuperAdmin = permissions.isSysAdmin;
	const { history } = props;
	const prevPage = useMemo(() => props.location.state, []);
	const dispatch = useDispatch();
	const updateLocalStorageKey = useSelector((state) => state.route.updateLocalStorageKey);

	const {
		isOn,
		names: { SITE_IS_FIRST_RESPONDER },
	} = flags;

	useEffect(() => {
		if (isOn(SITE_IS_FIRST_RESPONDER) && !isSuperAdmin) {
			history.push('/user-management');
		}

		return () => {
			setUser({});
			setUserModule('manageAccount');
			setAvatarImg('');
			setProfileImage(null);
			setPersonName([]);
			setPersonStatus(0);
			setIsLoading(true);
			setUserupdated(false);
			setTabHasChange(false);
			setShowUnsavedChangesModal(false);
			setNewModule(null);
			setShowImageModal(false);
			setUserProfileId(null);
			setUserInitials(null);
			setHasUserEditPermission(false);
		};
	}, []);

	const hasUserViewPermission = checkPermission('user_view', permissions);

	useEffect(() => {
		getUserDetails(userId);
	}, [Userupdated === true]);

	const getUserDetails = (userId) => {
		setIsLoading(true);

		UserService.getUserById(userId, '?includeUserBuildings=true').then((resp) => {
			if (!isMyAccount && resp.data.accessType === USER_ACCESS_TYPES['NAVIGATE_ADMIN']) {
				return history.push('/');
			}
			const persName = resp.data.person.name;
			const initials = persName.givenName.charAt(0) + persName.familyName.charAt(0);
			setUserProfileId(resp.data.id);
			setUser(resp.data);
			setPersonName(persName);
			setPersonStatus(resp.data.person.status);
			setUserInitials(initials);
			setProfileImage(resp.data.personPhoto);
			setUserupdated(false);
			const object_site_ids = resp.data.buildings.map((object) => object.id);
			const hasEditPermission = checkPermissionByCodeAndSites(
				'user_edit',
				permissions,
				object_site_ids,
				'some',
			);
			setHasUserEditPermission(hasEditPermission);

			let avatarImg = '';

			if (resp.data.personPhotoUrl != null && resp.data.personPhotoUrl != '') {
				avatarImg = <img src={resp.data.personPhotoUrl} className="profile-image" />;
			} else {
				avatarImg = <AvatarInitials initials={initials} imageMode="profile" />;
			}

			setAvatarImg(avatarImg);
			setIsLoading(false);
			let userData = JSON.parse(localStorage.getItem('loggedUserData'));

			if (userData.id == resp.data.id) {
				userData.personPhoto = resp.data.personPhoto;
				userData.personPhotoUrl = resp.data.personPhotoUrl;
				userData.person.name.givenName = resp.data.person.name.givenName;
				userData.person.name.familyName = resp.data.person.name.familyName;

				localStorage.setItem('loggedUserData', JSON.stringify(userData));

				dispatch({
					type: 'UPDATE_LOCAL_STORAGE',
					payload: !updateLocalStorageKey,
				});
			}
		});
	};

	const handleLinkClick = (newModule) => {
		if (userModule !== newModule && tabHasChange) {
			setNewModule(newModule);
			setShowUnsavedChangesModal(true);
		} else {
			setUserModule(newModule);
		}
	};

	const handleConfirmAction = (newModule) => {
		if (newModule == 'gobacktousers') {
			history.push('/user-management');
		} else {
			setShowUnsavedChangesModal(false);
			setTabHasChange(false);
			setUserModule(newModule);
		}
	};

	const goBackToUsers = () => {
		if (!hasUserViewPermission) {
			history.push('/');
			return;
		}
		if (prevPage && prevPage?.route == CALLLIST_ROUTES.CONTACTSV2) {
			history.push(CALLLIST_ROUTES.CONTACTSV2 + prevPage?.callList);
		} else if (prevPage && prevPage?.callList) {
			history.push(CALLLIST_ROUTES.CONTACTSV1 + prevPage?.callList);
		} else if (tabHasChange) {
			setNewModule('gobacktousers');
			setShowUnsavedChangesModal(true);
			return false;
		} else {
			history.push('/user-management');
		}
	};

	return (
		<>
			{showImageModal && !isLoading && (
				<UploadProfilePhoto
					setShowImageModal={setShowImageModal}
					userId={userProfileId}
					avatarImage={avatarImg}
					profileImage={profileImage}
					userInitials={userInitials}
					setUserupdated={setUserupdated}
				/>
			)}
			<div className="account-manager-general">
				<div className="row">
					<div className="col-12">
						<div className=" container-fluid">
							<PageHeader
								title={isMyAccount ? 'My Account' : 'User Account'}
								onBackBtnClick={goBackToUsers}
							/>
						</div>
						{isLoading && (
							<div className="main-info-wrapper_Loading">
								<LoadingSpinner />
							</div>
						)}
						{!isLoading && (
							<div className="user-main-info-wrapper">
								{hasUserEditPermission || isMyAccount ? (
									<div
										className="avatar-wrapper editable"
										onClick={() => setShowImageModal(true)}
									>
										{avatarImg}
										<Icon icon="camera" iconSize={24} />
									</div>
								) : (
									<div className="avatar-wrapper">{avatarImg}</div>
								)}

								<div className="user-info-wrapper">
									<div className="user-title">
										<strong>
											{personName.givenName} {personName.familyName}
											{personStatus == 2 && (
												<Icon
													icon="disable"
													size={24}
													style={{
														color: '#777',
														verticalAlign: 'baseline',
													}}
												/>
											)}
										</strong>
									</div>

									<div className="user-details">
										<div className="user-detail-box">
											<span className="user-detail-subtitle">
												Staff Title
											</span>
											<p className="user-detail-value">{personName.title}</p>
										</div>

										<div className="user-detail-box">
											<span className="user-detail-subtitle">
												Global User ID
											</span>
											<p className="user-detail-value">{user.globalUserId}</p>
										</div>

										<div className="user-detail-box">
											<span className="user-detail-subtitle">User ID</span>
											<p className="user-detail-value">{userId}</p>
										</div>

										<div className="user-detail-box">
											<span className="user-detail-subtitle">
												Primary Site
											</span>
											<p className="user-detail-value">
												{user.primaryBuilding.name}
											</p>
										</div>

										<div className="user-detail-box">
											<span className="user-detail-subtitle">
												Primary Role
											</span>
											<p className="user-detail-value">
												{user.primaryRoleId ? user.primaryRole.title : '-'}
											</p>
										</div>

										<div className="user-detail-box">
											<span className="user-detail-subtitle">
												Account Status
											</span>

											{personStatus == 1 && (
												<div className="user-info-pill">
													<span className="text-pill-light">Enabled</span>
												</div>
											)}

											{personStatus == 2 && (
												<div className="user-info-pill">
													{/*
													<span className='text-pill-dark'>
														Disabled 
														&nbsp;&bull;&nbsp;
													</span>
													*/}
													<span className="text-pill-light">
														Disabled
													</span>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						)}
						{!isLoading && (
							<div className="user-actions-wrapper">
								<div className="user-menu-wrapper">
									<div
										className={
											userModule == 'manageAccount'
												? 'menu-btn-action is-active'
												: 'menu-btn-action'
										}
									>
										<a
											href="#"
											title="Manage Account"
											onClick={() => handleLinkClick('manageAccount')}
										>
											<Icon icon="tick" className="span-icon" iconSize={14} />
											<span className="text-icon">Manage Account</span>
										</a>
									</div>
									<div
										className={
											userModule == 'userDetails'
												? 'menu-btn-action is-active'
												: 'menu-btn-action'
										}
									>
										<a
											href="#"
											title="User Details"
											onClick={() => handleLinkClick('userDetails')}
										>
											<Icon
												icon="person"
												className="span-icon"
												iconSize={14}
											/>
											<span className="text-icon">User Details</span>
										</a>
									</div>
									{hasUserEditPermission && (
										<div
											className={
												userModule == 'passwordOptions'
													? 'menu-btn-action is-active'
													: 'menu-btn-action'
											}
										>
											<a
												href="#"
												title="Password Options"
												onClick={() => handleLinkClick('passwordOptions')}
											>
												<Icon
													icon="eye-off"
													className="span-icon"
													iconSize={14}
												/>
												<span className="text-icon">Password Options</span>
											</a>
										</div>
									)}
									<div
										className={
											userModule == 'respondSettings'
												? 'menu-btn-action is-active'
												: 'menu-btn-action'
										}
									>
										<a
											href="#"
											title="Respond Settings"
											onClick={() => handleLinkClick('respondSettings')}
										>
											<Icon
												icon="info-sign"
												className="span-icon"
												iconSize={14}
											/>
											<span className="text-icon">Rapid Alarm Settings</span>
										</a>
									</div>

									{/* <div
										className={
											userModule == 'notifications'
												? 'menu-btn-action is-active'
												: 'menu-btn-action'
										}
									>
										<a
											href="#"
											title="Notifications"
											onClick={() => handleLinkClick('notifications')}
										>
											<Icon
												icon="notifications"
												className="span-icon"
												iconSize={14}
											/>
											<span className="text-icon">Notifications</span>
										</a>
									</div> */}

									{isSuperAdmin && (
										<div
											className={
												userModule == 'systemSync'
													? 'menu-btn-action is-active'
													: 'menu-btn-action'
											}
										>
											<a
												href="#"
												title="System Sync"
												onClick={() => handleLinkClick('systemSync')}
											>
												<Icon
													icon="git-pull"
													className="span-icon"
													iconSize={14}
												/>
												<span className="text-icon">System Sync</span>
											</a>
										</div>
									)}
									<div
										className={
											userModule == 'recentActivity'
												? 'menu-btn-action is-active'
												: 'menu-btn-action'
										}
									>
										<a
											href="#"
											title="Recent Activity"
											onClick={() => handleLinkClick('recentActivity')}
										>
											<Icon
												icon="history"
												className="span-icon"
												iconSize={14}
											/>
											<span className="text-icon">Recent Activity</span>
										</a>
									</div>
									{(!isMyAccount || !permissions.isSysAdmin) && (
										<div
											className={
												userModule == 'sitePermissions'
													? 'menu-btn-action is-active'
													: 'menu-btn-action'
											}
										>
											<a
												href="#"
												title="Site Permissions"
												onClick={() => handleLinkClick('sitePermissions')}
											>
												<Icon
													icon="lock"
													className="span-icon"
													iconSize={14}
												/>
												<span className="text-icon">Site Permissions</span>
											</a>
										</div>
									)}
								</div>

								<div className="user-module-wrapper">
									{userModule === 'manageAccount' && (
										<ModuleAccount
											user={user}
											updateUserStatus={setPersonStatus}
											userStatus={personStatus}
											isMyAccount={isMyAccount}
											isSuperAdmin={isSuperAdmin}
											hasUserEditPermission={hasUserEditPermission}
										/>
									)}

									{userModule === 'userDetails' && (
										<ModuleDetails
											userId={userId}
											user={user}
											setTabHasChange={setTabHasChange}
											setUserupdated={setUserupdated}
											isMyAccount={isMyAccount}
											permissions={permissions}
											isSuperAdmin={isSuperAdmin}
											hasUserEditPermission={hasUserEditPermission}
										/>
									)}

									{userModule === 'passwordOptions' && (
										<ModulePasswordOptions
											user={user}
											isMyAccount={isMyAccount}
											isSuperAdmin={isSuperAdmin}
											hasUserEditPermission={hasUserEditPermission}
										/>
									)}

									{userModule === 'respondSettings' && (
										<ModuleRespondSettings
											user={user}
											setUserupdated={setUserupdated}
											setTabHasChange={setTabHasChange}
											isLoading={isLoading}
											setIsLoading={setIsLoading}
											isSuperAdmin={isSuperAdmin}
											isMyAccount={isMyAccount}
											hasUserEditPermission={hasUserEditPermission}
										/>
									)}

									{/* {userModule === 'notifications' && (
										<ModuleNotifications
											user={user}
											setUserupdated={setUserupdated}
											setTabHasChange={setTabHasChange}
											isSuperAdmin={isSuperAdmin}
											hasUserEditPermission={hasUserEditPermission}
											isMyAccount={isMyAccount}
										/>
									)} */}

									{userModule === 'systemSync' && (
										<SystemSync
											user={user}
											isSuperAdmin={isSuperAdmin}
											hasUserEditPermission={hasUserEditPermission}
											isMyAccount={isMyAccount}
											setTabHasChange={setTabHasChange}
											setUserupdated={setUserupdated}
										/>
									)}

									{userModule === 'recentActivity' && (
										<ActivityModule user={user} />
									)}

									{userModule === 'sitePermissions' && (
										<SitePermissions
											user={user}
											setUserupdated={setUserupdated}
											setTabHasChange={setTabHasChange}
											isSuperAdmin={isSuperAdmin}
											hasUserEditPermission={hasUserEditPermission}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{showUnsavedChangesModal && (
				<UnsavedChangesModal
					onConfirmAction={() => handleConfirmAction(newModule)}
					onCancelAction={() => {
						setShowUnsavedChangesModal(false);
					}}
				/>
			)}
		</>
	);
}

AccountManager.propTypes = {
	history: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object,
};
