import React from "react";
import { Button } from "../";
import { Tooltip } from "./";
import './css/TooltipPattern.scss';

export const TooltipPattern = () => {
    return (
    <div className="TooltipPattern_Container">
        <Tooltip
            tooltipTitle={'Tooltip title'} // bold text
            tooltipContent={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
            theme={'dark'}
        >
            <Button
                text="position top - theme dark"
                type="primaryDefault"
                size="md"
            />
        </Tooltip>
        <Tooltip
            tooltipTitle={'Tooltip title'}
            tooltipContent={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
            theme={'light'}
        >
            <Button
                text="position top - theme light"
                type="primaryDefault"
                size="md"
            />
        </Tooltip>

        <Tooltip
            tooltipContent={'I am a disabled tooltip text'}
            disabled={true}
        >
            <Button
                text="Disabled Tooltop"
                type="primaryDefault"
                size="md"
            />
        </Tooltip>
        </div>
    )
}