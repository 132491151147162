import Swal from 'sweetalert2';

const fireSwal = (icon, message) => {  
    Swal.fire({
        title: message,
        icon: icon,
        timer: 1000,
        showConfirmButton: false,
    })
}

export { fireSwal };