import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import { debounce } from 'debounce';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';
import VariableDialog from './variabledialog';
import ImageDialog from '../../EditTemplate/imagedialog';
import {validateTemplateSubsection, validateTemplateSections} from "utilities";

const ButtonWrap = styled.div`
	position: absolute;
	right: 0;
	top: -40px;
`;
const EditorWrap = styled.div`
	position: relative;
`;

class SingleEditor extends React.Component {
	constructor() {
		super();
		this.state = {
			isOpen: false,
			actualCursor: null,
			isImagesOpen: false
		};
		this.editorRef = React.createRef();
		this.debounceUpdate = debounce(this.updateSubsection, 400);
	}

	insertVariable = val =>
	{
		this.editorRef.current.selection.moveToBookmark(this.state.actualCursor);

		this.editorRef.current.selection.setContent(
			`<span class="variable-highlight">${val.name}</span> `,
		);

		this.setState({ isOpen: false });
		this.updateSubsection();
	};

	openMenu = () => this.setState({ isOpen: true , actualCursor : this.editorRef.current.selection.getBookmark()});

	openImages = () => this.setState({ isImagesOpen: true });

	closeImages = () => this.setState({ isImagesOpen: false });

	insertImage = val => {
		let tempcontent = this.editorRef.current.getContent();

		tempcontent += val;
		this.editorRef.current.setContent(tempcontent);
		return Swal.fire({
			title: 'Done!',
			icon: 'success',
			timer: 1000,
			showConfirmButton: false,
		}).then(() => {
			this.setState({ isImagesOpen: false });
			return this.updateSubsection();
		});
	};

	updateSubsection = () => {
		const { updateMode, saveAndReload } = this.props;
		if (updateMode) return null;
		const { updateNodes, sectionIndex, subsectionIndex } = this.props;
		const tempcontent = this.editorRef.current.getContent();

		updateNodes(sectionIndex, subsectionIndex, tempcontent);
		saveAndReload();
	};

	publishUpdate = () => {
		const {
			templateId,
			sectionIndex,
			subsectionIndex,
			actualSectionId,
			actualSubsectionId,
			template,
			subsection,
      type
		} = this.props;
		let errorObj = null;
		if (type === 'section') {
			errorObj = validateTemplateSections(
				[subsection],
				true,
				'publish',
				template.name,
			);
		} else {
			errorObj = validateTemplateSubsection(
				subsection,
				actualSectionId,
				true,
				null,
				template.name,
			);
		}
		if (errorObj.issue) {
			Swal.fire({
				html: errorObj.messageHTML,
				icon: 'warning',
				showCancelButton: false,
				confirmButtonText: 'ok',
				reverseButtons: true,
				returnFocus: false,
				onAfterClose() {
          const element = document.getElementById('subsection-' + actualSectionId + '-' + actualSubsectionId);
          if (!!element) {
            element.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }
        }
      }).then(result => {
        if (result.value) {
          return Swal.close();
        }
      });
    } else {
      Swal.fire({
        title: 'Publish update?',
        text: 'You are about to publish an update for this section',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'bp3-button bp3-intent-danger',
          cancelButton: 'bp3-button',
        },
        confirmButtonText: 'Publish',
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          Swal.fire('Publishing');
          Swal.showLoading();
          const token = JSON.parse(localStorage.getItem('user')).jwt;
          const tempcontent = this.editorRef.current.getContent();
          let updatedTitle = "";
          if (actualSubsectionId) {
            template.sections[sectionIndex].subsections[subsectionIndex].content = tempcontent;
            updatedTitle = template.sections[sectionIndex].subsections[subsectionIndex].title;
          } else {
            template.sections[sectionIndex].content = tempcontent;
            updatedTitle = template.sections[sectionIndex].title;
          }
          const updatedContent = {
            actualSectionId,
            actualSubsectionId,
            content: tempcontent,
            title: updatedTitle,
            index: actualSubsectionId ? subsectionIndex : sectionIndex
          };
          return fetch(
            `${
              process.env.API_URL
            }/plan-templates/${templateId}/updates`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                type: 'update',
                updatedContent: JSON.stringify(updatedContent),
                actualSectionId,
                actualSubsectionId,
              }),
            },
          )
            .then(res => res.json())
            .then(resp => {
              if (resp.statusCode === 201) {
                fetch(
                  `${
                    process.env.API_URL
                  }/plan-templates/${parseInt(template.id, 10)}`,
                  {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'app/json',
                      Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
						headers: JSON.stringify(template.headers),
						content: JSON.stringify(
							template.sections,
						),
						name: template.name,
                      	state: template.state,
                    }),
                  },
                )
                  .then(res => res.json())
                  .then(rsp => {
                    if (rsp.statusCode === 200) {
                      Swal.close();
                      Swal.fire({
                        title: 'Done!',
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false,
                      }).then(() => window.location.reload());
                    }
                  });
              } else if(resp.statusCode == 400) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: resp.error?.description || 'Could not publish update. Please try again.',
				});
			  }
              return null;
            });
        }
        return Swal.close();
      });
    }
	};

	toggleMode = () => {
		const {
			type,
			sectionIndex,
			subsectionIndex,
			toggleUpdateMode,
			actualSectionId,
			actualSubsectionId,
		} = this.props;
		let indexVal = sectionIndex;
		if (type === 'subsection') {
			indexVal = sectionIndex + '-' + subsectionIndex;
		}
		return toggleUpdateMode(
			true,
			indexVal,
			type,
			actualSectionId,
			actualSubsectionId,
		);
	};

	shouldComponentUpdate(nextProps, nextState) {
		const { isOpen, isImagesOpen } = this.state;
		if (nextState.isOpen !== isOpen) {
			return true;
		}
		if (nextState.isImagesOpen !== isImagesOpen) {
			return true;
		}
		const { updateMode, sectionIndex, subsectionIndex } = this.props;
		if (updateMode !== nextProps.updateMode) {
			return true;
		}
		if (
			nextProps.sectionIndex === sectionIndex &&
			nextProps.subsectionIndex === subsectionIndex
		) {
			return false;
		}

		return true;
	}

	getIndex = () => {
		const { type, sectionIndex, subsectionIndex } = this.props;
		let indexVal = sectionIndex;
		if (type === 'subsection') {
			indexVal = sectionIndex + '-' + subsectionIndex;
		}
		return indexVal;
	};

	render() {
		const {
			content,
			subsectionIndex,
			makeIndicatorRed,
			indicator,
			commonVariables,
			templateVariables,
			templateId,
			reloadVariables,
			editVariable,
			deleteVariable,
			updateMode,
			updateType,
			updateTarget,
			type,
			cancelUpdateMode,
			viewMode = false,
		} = this.props;
		const { isOpen , isImagesOpen} = this.state;
		const showEditor =
			(updateMode &&
				updateType === type &&
				updateTarget === this.getIndex()) ||
			!updateMode;
		const showButtons =
			updateMode &&
			updateType === type &&
			updateTarget === this.getIndex();
		return (
			<>
				<VariableDialog
					isOpen={isOpen}
					toggleDialog={() => this.setState({ isOpen: false })}
					insertVariable={this.insertVariable}
					commonVariables={commonVariables}
					templateVariables={templateVariables}
					templateId={templateId}
					reloadVariables={reloadVariables}
					reopenDialog={() => this.setState({ isOpen: true })}
					editVariable={editVariable}
					deleteVariable={deleteVariable}
				/>
				<ImageDialog
					isOpen={isImagesOpen}
					insertImage={this.insertImage}
					closeImages={this.closeImages}
				/>
				<EditorWrap className="mb-3">
					{showEditor && !viewMode ? (
						<>
							{showButtons && (
								<ButtonWrap>
									<Button
										text="Cancel"
										onClick={cancelUpdateMode}
									/>
									<Button
										text="Publish"
										className="ml-2"
										onClick={this.publishUpdate}
									/>
								</ButtonWrap>
							)}
							<Editor
								key={subsectionIndex}
								ref={this.editorRef}
								onInit={(evt, editor) => {
									this.editorRef.current = editor;
									return null;
								}}
								onEditorChange={() => {
									if (indicator) {
										return makeIndicatorRed();
									}
									return null;
								}}
								initialValue={content}
								init={{
									height: '90vh',
									menubar: false,
									statusbar: false,
									branding: false,
									resize: false,
									plugins: [
										'advlist autolink lists link image charmap print preview anchor',
										'searchreplace visualblocks code fullscreen',
										'insertdatetime media table paste code table code',
									],
									toolbar:
										'undo redo formatselect bold italic underline fontselect fontsizeselect forecolor backcolor cut copy ' +
										'alignleft aligncenter alignright alignjustify bullist numlist ' +
										'table imageModal variableMenu exportSection completeSection updateMode',
									content_style:
										'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }' +
										'.variable-highlight{background: rgba(19, 124, 189, 0.2)}',
									setup: editor => {
										editor.ui.registry.addButton(
											'variableMenu',
											{
												text: 'Create Variable',
												onAction: this.openMenu,
											},
										);
										editor.ui.registry.addButton(
											'updateMode',
											{
												text: 'Create an Update',
												onAction: this.toggleMode,
											},
										);
										editor.on(
											'Paste Change input Undo Redo',
											this.debounceUpdate,
										);
										editor.ui.registry.addButton('imageModal', {
											text: 'Add Image',
											onAction: this.openImages,
										});
									},
								}}
							/>
						</>
					) : (
						<div
							dangerouslySetInnerHTML={{
								__html: content,
							}}
						/>
					)}
				</EditorWrap>
			</>
		);
	}
}

SingleEditor.propTypes = {
	content: PropTypes.any,
	updateNodes: PropTypes.func,
	sectionIndex: PropTypes.number,
	subsectionIndex: PropTypes.number,
	makeIndicatorRed: PropTypes.func,
	indicator: PropTypes.bool,
	commonVariables: PropTypes.array,
	templateVariables: PropTypes.array,
	templateId: PropTypes.string,
	reloadVariables: PropTypes.func,
	editVariable: PropTypes.func,
	deleteVariable: PropTypes.func,
	toggleUpdateMode: PropTypes.func,
	type: PropTypes.string,
	updateMode: PropTypes.bool,
	updateType: PropTypes.string,
	updateTarget: PropTypes.number,
	cancelUpdateMode: PropTypes.func,
	actualSectionId: PropTypes.number,
	actualSubsectionId: PropTypes.number,
	template: PropTypes.object,
    subsection: PropTypes.object
};

export { SingleEditor };
