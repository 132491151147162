import React from "react";
import {Modal, BootstrapedTable, Badge, Button} from "componentsV2";
import '../css/revisionHistoryTable.scss';
import {RevisionTableTitle} from './RevisionTableTitle';
import { formatDate } from "utilities/dates";
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

export const RevisionHistory = ({
    modalToShow,
    loading,
    documentData,
    closeModal,
    revisionHistoryList,
    showDownloadRevision
}) => {

    const columns = [
        
        {
            key: 'filename',
            dataField: 'documentTitle',
            text: 'Title',
            sort: false,
            headerAttrs: {
                hidden: true
            },
            formatter: (value, row, key) => (<RevisionTableTitle
                rowHeader="File name"
                rowName={row.filename}
                rowKey={key}
            />)
        },
        {
            key: 'dateEntered',
            dataField: 'dateEntered',
            text: 'Uploaded Date',
            sort: false,
            headerAttrs: {
                hidden: true
            },
            formatter: (value, row, key) => (<RevisionTableTitle
                rowHeader="Uploaded date"
                rowName={formatDate(row.dateEntered?.date, DateTime.fromISO) + ', ' + 
                    (row.enteredBy?.firstName ? row.enteredBy?.firstName  : '')  + 
                    (row.enteredBy?.lastName != " " && row.enteredBy?.lastName != ""  ? ' ' + row.enteredBy?.lastName : '')
                }
                rowKey={key}
            />)
        }, 
        {
            key: 'fileSize',
            dataField: 'fileSize',
            text: 'Size',
            sort: false,
            headerAttrs: {
                hidden: true
            },
            formatter: (value, row, key) => (<RevisionTableTitle
                rowHeader="Size"
                rowName={row.fileSize}
                rowKey={key}
            />)
        },  

        {
            key: 'archivedBy',
            dataField: 'archivedBy',
            text: '',
            sort: false,
            headerAttrs: {
                hidden: true
            },
            formatter: (value, row, key) => ( 
                <div className="ActionWrapper">
                    {
                        row.archivedBy ?   
                        <Button
                            key={key + "action"}
                            disabled={false}
                            minimal
                            text=""
                            type="tertiary"
                            intent="default" 
                            rightIcon="cloud-download"
                            size="md"
                            onClick = {() => showDownloadRevision({
                                ...row,
                                revison_id:row.id,
                                id:row.documentId,
                                title: row.filename,
                                extension:row.documentTitle.split('.').pop()
                            })}
                        />:
                        <Badge
                            key={`sm-Success`}
                            type="Success"
                            size="sm"
                            text="Active"
                        />
                    }
            </div>
            )
        },  
        
    
        
    ];

    return ( 
        <Modal
            isOpen={modalToShow === 'revisionHistoryModal'}
            onClose={closeModal}
            title={documentData.modalTitle}
            showCloseButton={true}
            footer={false}
            loading= {loading}
            scrollContent={false}
        >                                
            <div className="RevisionHistory_Wrapper">
                <BootstrapedTable
                    keyField="id"
                    data={revisionHistoryList}
                    columns={columns}
                    showPagination={false}
                    bordered={false}
                />
            </div>
        </Modal>
    );
}

RevisionHistory.propTypes = {
    showDownloadRevision: PropTypes.func,
    modalToShow: PropTypes.string,
    revisionHistoryList: PropTypes.array,
    documentData: PropTypes.object,
    loading: PropTypes.bool
};
