import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { TextArea } from 'components/TextArea';
import { Dialog } from '@blueprintjs/core';
import { Input } from 'components/Input';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';
import { DateInput } from '@blueprintjs/datetime';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

const SearchWrap = styled.div`
	position: relative;
`;

const DropWrap = styled.div`
	position: absolute;
	top: 80px;
	z-index: 50;
	width: 97%;
`;

const DropItem = styled.button`
	border: none;
	padding: 10px;
	cursor: pointer;
	background: #fff;
	width: 100%;
	text-align: left;
	&:hover {
		background: #ebf1f5;
	}
`;

const DateLabel = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: #182026;
`;

const StyledInput = styled(DateInput)`
	width: 100%;
	.bp3-popover-wrapper {
		width: 100% !important;
	}
	.bp3-popover-target {
		width: 100% !important;
	}
	.bp3-input-group {
		width: 100% !important;
	}
	input {
		width: 100% !important;
		border: 1px solid #d8e1e8;
		font-size: 16px;
		min-height: 40px;
		border-radius: 3px;
		overflow: hidden;
		box-shadow: none;
	}
`;

class AddTask extends React.Component {
	constructor() {
		super();
		this.state = {
			title: '',
			description: '',
			assignedTo: {},
			userSearch: [],
			dueDate: new Date(),
		};
		this.debounceSearch = debounce(this.searchForUser, 500);
		this.inputRef = React.createRef();
	}

	searchForUser = e => {
		const {
			target: { value },
		} = e;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/users?combineSearch=${value}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				this.setState({ userSearch: resp.data });
			});
	};

	assignUser = item => {
		this.inputRef.current.value = `${item.person.firstName} ${item.person.lastName}`;
		this.setState({
			userSearch: [],
			assignedTo: {
				id: item.id,
				name: `${item.person.firstName} ${item.person.lastName}`,
			},
		});
	};

	handleDateChange = val => this.setState({ dueDate: val });

	updateTask = () => {
		Swal.fire('Saving');
		Swal.showLoading();

		const { questionId, templateId, getData, closeAddModal } = this.props;
		const { title, description, assignedTo, dueDate } = this.state;

		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${process.env.API_URL}/assessments/${templateId}/questions/${questionId}/tasks`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					title,
					description,
					dueDate,
					assignedTo: assignedTo.id,
				}),
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					return Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => getData());
				} else if (resp.statusCode === 500) {
					closeAddModal();
					Swal.fire({
						title: 'Oops!',
						icon: 'error',
						text: 'Please answer the question before creating a task.',
						showConfirmButton: true
					});
				}
				return null;
			});
	};

	render() {
		const { isOpen, closeAddModal } = this.props;
		const {
			title,
			description,
			assignedTo,
			userSearch,
			dueDate,
		} = this.state;
		return (
			<Dialog isOpen={isOpen} onClose={closeAddModal}>
				<div>
					<TitleWrap>
						<Title>Add Task</Title>
						<Button
							text=""
							minimal
							icon="cross"
							onClick={closeAddModal}
						/>
					</TitleWrap>
					<hr />
					<div className="px-2">
						<Input
							label="Title"
							placeholder="Enter Title"
							large
							width="100%"
							className="mb-2"
							value={title}
							onChange={e =>
								this.setState({
									title: e.target.value,
								})
							}
						/>
					</div>
					<div className="px-2">
						<TextArea
							label="Description"
							placeholder="Enter Title"
							large
							width="100%"
							className="mb-2"
							value={description}
							onChange={e =>
								this.setState({
									description: e.target.value,
								})
							}
						/>
					</div>
					<SearchWrap className="d-flex flex-column px-2">
						<Input
							inputRef={this.inputRef}
							label="Assigned to"
							placeholder="Search"
							large
							width="100%"
							className="mb-2"
							defaultValue={assignedTo.name}
							onChange={this.debounceSearch}
						/>
						<DropWrap>
							{userSearch.map(one => (
								<DropItem
									key={one.id}
									onClick={() => this.assignUser(one)}
								>
									{one.person.firstName} {one.person.lastName}
								</DropItem>
							))}
						</DropWrap>
					</SearchWrap>
					<div className="px-2 w-100">
						<DateLabel>Due date</DateLabel>
						<StyledInput
							formatDate={date => `${format(date, 'MM/dd/yyyy')}`}
							parseDate={str => new Date(str)}
							className="w-100"
							onChange={this.handleDateChange}
							value={new Date(dueDate)}
						/>
					</div>
					<hr />
					<div className="d-flex justify-content-end mt-2 px-2">
						<Button
							text="Cancel"
							large
							intent="secondary"
							onClick={closeAddModal}
						/>
						<Button
							className="ml-2"
							text="Save"
							large
							intent="primary"
							onClick={this.updateTask}
						/>
					</div>
				</div>
			</Dialog>
		);
	}
}

AddTask.propTypes = {
	isOpen: PropTypes.bool,
	questionId: PropTypes.number,
	templateId: PropTypes.any,
	getData: PropTypes.func,
	closeAddModal: PropTypes.func,
};

export default AddTask;
