import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { circleInfo, warningYellow } from 'assets/icons';

const OPTIONS = {
	info: {
		icon: circleInfo,
		backgroundColor: '#E8F2F9',
	},
	warning: {
		icon: warningYellow,
		backgroundColor: '#FCF3EA',
	},
};

const StyledImage = styled.img`
	margin-top: -2px;
	margin-right: 8px;
	width: 16px;
	height: 16px;
`;

const Wrapper = styled.div`
	margin-top: 8px;
	padding: 13px 10px;
	border-radius: 3px;
	background-color: ${props => OPTIONS[(props?.type)]?.backgroundColor};
	font-family : var(--font-family);
`;

const Callout = ({ type = 'info', children, wrapperStyles = {} }) => {
	const icon = OPTIONS[type]?.icon;
	const alt = type[0]?.toUpperCase() + type?.substring(1);
	return (
		<Wrapper className="callout" style={wrapperStyles} type={type}>
			<StyledImage alt={alt} src={icon} />
			{children}
		</Wrapper>
	);
};

Callout.propTypes = {
	type: PropTypes.string,
	children: PropTypes.any,
	wrapperStyles: PropTypes.object,
};

export { Callout };
