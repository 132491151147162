import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dropdown } from 'components/Dropdown';
import { Icon } from '@blueprintjs/core';

export const WhiteCard = styled.div`
	background: white;
	border: 1px solid #d8e1e8;
	border-radius: 5px;
	height: 100%;
	margin-bottom: 24px;
	margin-right: ${props => (props?.marginRight ? props?.marginRight : '0')};
	padding: 24px;
	display: flex;
	flex: 1;
	flex-direction: column;

	.responsive-chart-container {
		height: 500px;
	}

	.title-container {
		align-items: center;
		display: flex;
		flex: 1;
		justify-content: space-between;
		margin-bottom: 32px;
		margin-right: 27px;

		.title-filter {
			display: flex;
			align-items: center;
		}

		p {
			margin-bottom: 0;
			font-family: Nunito Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			color: #738694;
		}
		h4 {
			margin-bottom: 0;
			font-family: Merriweather;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 21px;
			letter-spacing: 0.3px;
			color: #10161a;
		}
	}
`;

export const Totalizer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	p {
		color: ${props => props?.textColor};
	}
	.title {
		font-size: 16px;
	}
	.total {
		font-size: 36px;
	}
`;

export const OverviewInfo = styled.div`
	.header-container {
		display: flex;
		align-items: center;

		.header {
			color: ${props => props?.color};
			margin-right: 16px;
			font-family: 'Merriweather';
		}
	}
`;

export const OverviewInfoComponent = ({ icon, number, text, color }) => (
	<OverviewInfo color={color}>
		<div className="header-container">
			<Icon className="header" icon={icon} />
			<h1 className="header">{number}</h1>
		</div>
		<p>{text}</p>
	</OverviewInfo>
);

export const WhiteCardComponent = ({ children, width, marginRight }) => (
	<WhiteCard width={width} marginRight={marginRight}>
		{children}
	</WhiteCard>
);

export const TotalizerComponent = ({ title, total, subtitle, textColor }) => (
	<Totalizer textColor={textColor}>
		<p className="title">{title}</p>
		<p className="total">{total}</p>
		<p>{subtitle}</p>
	</Totalizer>
);

export const YEARLY_TRENDS_DROPDOWN_OPTIONS = [
	{
		key: `${Math.random()}`,
		value: 'completed',
		label: 'Completed on Time',
	},
	{
		key: `${Math.random()}`,
		value: 'late',
		label: 'Late or Missed',
	},
];

export const BY_YEAR_RESUME_DROPDOWN_OPTIONS = [
	{
		key: `${Math.random()}`,
		value: 'completed',
		label: 'Completed',
	},
	{
		key: `${Math.random()}`,
		value: 'overdue',
		label: 'Overdue',
	},
	{
		key: `${Math.random()}`,
		value: 'notScheduled',
		label: 'Not scheduled',
	},
];

export const ChartWrapper = ({
	chartId = '',
	children,
	title,
	subtitle,
	showFilter = false,
	dropdownValue,
	onDropdownChange,
}) => (
	<WhiteCardComponent>
		<div className="title-container">
			<div className="title-filter">
				<h4>{title}</h4>
				<p className="ml-2">
					<small>{subtitle}</small>
				</p>
			</div>
			{showFilter && (
				<div className="title-filter">
					<p className="mr-2">Show only:</p>
					<Dropdown
						value={dropdownValue}
						onChange={onDropdownChange}
						options={
							chartId === 'yearly-trends'
								? YEARLY_TRENDS_DROPDOWN_OPTIONS
								: BY_YEAR_RESUME_DROPDOWN_OPTIONS
						}
					/>
				</div>
			)}
		</div>
		<div>{children}</div>
	</WhiteCardComponent>
);

WhiteCardComponent.propTypes = {
	children: PropTypes.object,
	marginRight: PropTypes.string,
	width: PropTypes.string,
};

TotalizerComponent.propTypes = {
	subtitle: PropTypes.string,
	title: PropTypes.string,
	textColor: PropTypes.string,
	total: PropTypes.string,
};

ChartWrapper.propTypes = {
	chartId: PropTypes.string,
	children: PropTypes.object,
	title: PropTypes.string,
	showFilter: PropTypes.bool,
	subtitle: PropTypes.string,
	dropdownValue: PropTypes.string,
	onDropdownChange: PropTypes.func,
};

OverviewInfoComponent.propTypes = {
	icon: PropTypes.string,
	number: PropTypes.string,
	text: PropTypes.string,
	color: PropTypes.string,
};
