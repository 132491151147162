export const MAP_SITE_INFO = 'siteInfo';
export const MAP_LAYERS = 'layers';
export const UNSAVED_CHANGES_MODAL = 'unsavedChangesModal';
export const DELETE_SHAPE_MODAL = 'deleteShapeModal';
export const MAP_AREAS = 'areas';
export const MAP_AREA_ADD = 'areasAdd';
export const MAP_AREA_Edit = 'areasEdit';
export const MAP_TEXT_LIST = 'mapTextList';
export const GEOMETRY_TYPE_TEXT = 'text';
export const GEOMETRY_TYPE_POLYGON = 'area';
export const GEOMETRY_TYPE_ICON = 'icon';
export const GEOMETRY_TYPE_LINE = 'line';
export const GEOMETRY_TYPE_ROUTE = 'route';
export const GEOMETRY_TYPE_ICONS_GROUP = 'iconsGroup';
export const MAP_ICON_LIST = 'mapIconList';
export const ICON_TYPE_OTHERS = 1;
export const ICON_TYPE_ROUTE = 2;
export const ICON_TYPE_CAMERA = 3;
export const MAP_SHAPE_CONTENT = 'mapShapeContentList';
export const MAP_ICON_EDIT = 'mapIconEdit';
export const DELETE_MAP_ACTION = 'delete';
export const MAP_LOADER_MODAL = 'mapLoaderModal';
export const MAP_LEGEND = 'legend';
