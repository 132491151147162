import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownMultiSelect } from '../DropdownMultiSelect/DropdownMultiSelect.jsx';

const DropdownMultiSelectPattern = () => {
	const options = [
		{ value: '1', label: 'Jimmy' },
		{ value: '2', label: 'Laura' },
		{ value: '3', label: 'Tommy' },
		{ value: '4', label: 'Jane' },
		{ value: '5', label: 'Mike' },
	];

	const [selectedOptions, setSelectedOptions] = useState([]);

	const selectAllOption = {
		value: '*',
		label: 'All Options',
	};

	const onChange = (selected, event) => {
		console.log('selected ', selected);
		console.log('event', event);
		console.log('event action', event.action);

		if (event.action === 'clear') {
			setSelectedOptions([]);
		}

		if (event.action === 'remove-value') {
			if (event.removedValue.value === '*') {
				setSelectedOptions([]);
			} else {
				setSelectedOptions(selected);
			}
		}

		if (event.option.value === '*') {
			if (event.action === 'select-option') {
				setSelectedOptions([selectAllOption, ...options]);
			}
			if (event.action === 'deselect-option') {
				console.log('quint');
				setSelectedOptions([]);
			}
		} else {
			console.log('pri', event.action);
			if (
				event.action === 'select-option' &&
				selected.length === options.length
			) {
				setSelectedOptions([selectAllOption, ...options]);
			} else if (
				event.action === 'deselect-option' &&
				selected.length === options.length
			) {
				console.log('ter leng', selected.length);
				setSelectedOptions(selected);
				setSelectedOptions(current => current.filter(opt => opt.value != '*'));

			} else {
				setSelectedOptions(selected);
			}
		}
	};

	return (
		<div className='DropDownMultiSelect__wrapper'>
			<DropdownMultiSelect
				placeholder="Select one or more options"
				values={selectedOptions}
				options={options}
				onChange={onChange}
				visibleOptions={1}
				isRequired={false}
				disabled={false}
				error={false}
				label={''}
				allowSelectAll={true}
				showRequiredText={false}
				isClearable={true}
				selectAllOption={selectAllOption}
				onDataLoad={() => {}} // output for list of sites in case they are loaded from endpoint
			/>
			<DropdownMultiSelect
				placeholder="Select one or more options"
				values={selectedOptions}
				options={options}
				onChange={onChange}
				visibleOptions={1}
				isRequired={false}
				disabled={false}
				error={true}
				label={'with error condition'}
				allowSelectAll={true}
				showRequiredText={false}
				isClearable={true}
				selectAllOption={selectAllOption}
				onDataLoad={() => {}} // output for list of sites in case they are loaded from endpoint
			/>
			<DropdownMultiSelect
				placeholder="Select one or more options"
				values={selectedOptions}
				options={options}
				onChange={onChange}
				visibleOptions={1}
				isRequired={true}
				disabled={false}
				error={false}
				label={'this is a required input'}
				allowSelectAll={true}
				showRequiredText={false}
				isClearable={true}
				selectAllOption={selectAllOption}
				onDataLoad={() => {}} // output for list of sites in case they are loaded from endpoint
			/>
		</div>
	);
};

DropdownMultiSelectPattern.propTypes = {
	selectedSite: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};

export { DropdownMultiSelectPattern };
