import React from 'react';

export const FilterMenuOption = ({
    title,
    subtitle = "",
    content = null,
}) => {
    return (
        <div className='filter-menu-item filter-menu-option'>
            <div className='menu-item-content'>
                <div className='menu-item-title'>{title}</div>
                {subtitle.length > 0 && (
                    <div className='menu-item-subtitle'>{subtitle}</div>
                )}
                {content && (
                    <div className='menu-item-content'>{content}</div>
                )}
            </div>
        </div>
    )
}
