import React, { useState, useEffect } from 'react';
import { Input, FormModal, LoadingSpinner, EmptyState } from 'componentsV2';
import { noResult } from 'componentsV2/EmptyState/icons';
import { BuildingsService } from 'services';
import '../css/SetRequirementsPerBuildingModal.scss';

export const SetRequirementsPerBuildingModal = ({
	isOpen,
	onModalSubmit,
	onCloseModal,
	selectedDrillType = null,
}) => {
	const [mainRequirement, setMainRequirement] = useState(0);
	const [buildingRequirements, setBuildingRequirements] = useState(
		selectedDrillType?.districtRequirement?.buildingRequirements,
	);
	const [modalLoading, setModalLoading] = useState(false);

	/* 
    Here we are validating the building requirements (If any) against the buildings pulled from the BE
    And creating a uniform data structure in order to have unified attributes because the building requirements
    comming from the grid has one structure, and the buildings from the BE has another
    So if the building requirements from the grid are not present, the structure to display the buildings
    is going to be different and it is confussing to handle changes in an unified way and avoid bugs
  */
	useEffect(() => {
		setMainRequirement(selectedDrillType.districtRequirements || 0);
		setModalLoading(true);
		BuildingsService.getAll()
			.then(backendBuildings => {
				setBuildingRequirements(prevBuildings => {
					const uniqueBuildings = backendBuildings.filter(backendBuilding => {
						const found = prevBuildings.findIndex(
							existingBuildingReq =>
								existingBuildingReq.building.id === backendBuilding.id,
						);
						return found === -1;
					});

					const organizedBuildings = uniqueBuildings.map(backendBuilding => {
						const existingBuildingReq = prevBuildings.find(
							existingBuildingReq =>
								existingBuildingReq.building.id === backendBuilding.id,
						);

						return {
							requirement: existingBuildingReq
								? existingBuildingReq.requirement
								: selectedDrillType.districtRequirements || 0,
							building: {
								id: backendBuilding.id,
								name: backendBuilding.name,
							},
						};
					});

					return [...prevBuildings, ...organizedBuildings];
				});
			})
			.finally(() => setModalLoading(false));
	}, []);

	const updateRequirement = (buildingId, value) => {
		const tempValue = value.length === 0 ? parseInt(0, 10) : parseInt(value, 10);

		const temp = JSON.parse(JSON.stringify(buildingRequirements));
		temp.forEach(item => {
			if (item.building.id === buildingId) {
				item.requirement = tempValue;
				return item;
			}
			return item;
		});

		setBuildingRequirements(temp);
	};

	return (
		<>
			<FormModal
				size="medium"
				title={`Set drills per building for “${selectedDrillType?.name}”`}
				subtitle="Here you can set the district requirement for each building individually."
				isOpen={isOpen}
				onClose={onCloseModal}
				showCloseButton={false}
				confirmBtn={{
					title: 'Save',
					onClick: () => {
						setModalLoading(true);
						onModalSubmit(buildingRequirements);
					},
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: onCloseModal,
				}}
				scrollContent={false}
				customBodyClass="add-users-content"
			>
				<div className="building-requirements-building-list w-100">
					{modalLoading ? (
						<LoadingSpinner className="mt-3" />
					) : buildingRequirements.length === 0 ? (
						<EmptyState header={'There are no building requirements'} icon={noResult} />
					) : (
						buildingRequirements.map(item => (
							<div key={item.building.id} className="building-item w-100">
								<div className="item-label w-100">{item.building.name}</div>
								<div className="item-input w-100">
									<Input
										id={item.building.id}
										key={item.building.id}
										value={item.requirement || mainRequirement}
										placeholder=""
										onChange={evt =>
											updateRequirement(item.building.id, evt.target.value)
										}
									/>
								</div>
							</div>
						))
					)}
				</div>
			</FormModal>
		</>
	);
};
