import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce } from 'debounce';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';
import VariableDialog from '../../Core/Details/variabledialog';
import { removeVariables, validateTemplateSubsection, validateTemplateSections} from "utilities";
import {TinymceEditor} from 'componentsV2';
import './css/SectionSubSectionContainer.scss';

const ButtonWrap = styled.div`
	position: absolute;
	right: 0;
	top: -40px;
`;
const EditorWrap = styled.div`
	position: relative;
	width:100%;
	margin:0px;
`;

class SingleEditor extends React.Component {
	constructor(props) {
		super();
		this.state = {
			isOpen: false,
		};
		this.editorRef = React.createRef();
		props.linkEditorsToSections(this.editorRef, props.sectionIndex, props.subsectionIndex)
		this.debounceUpdate = debounce(this.updateSubsection, 0);
	}

	updateSubsection = () => {
		const { saveTemplate } = this.props;
		const { updateNodes, sectionIndex, subsectionIndex, variableValues, contentType } = this.props;
		const tempcontent = this.editorRef.current.getContent();

		if(contentType === 'districtPlan') {
			const cleancontent = removeVariables(tempcontent, variableValues);
			return updateNodes(sectionIndex, subsectionIndex, cleancontent);
		} else {
			return updateNodes(sectionIndex, subsectionIndex, tempcontent);
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		const { modalToShow, updateEditor } = this.props;
		if (typeof updateEditor !== "undefined" && !updateEditor) 
			return false;
		if(modalToShow !== nextProps.modalToShow) {
			return true;
		}
		const { isOpen } = this.state;
		if (nextState.isOpen !== isOpen) {
			return true;
		}
		const {  sectionIndex, subsectionIndex } = this.props;

		if (
			nextProps.sectionIndex === sectionIndex &&
			nextProps.subsectionIndex === subsectionIndex
		) {
			// if (nextProps.indicator != this.props.indicator) {
			// 	return true;
			// }
			return false;
		}
		return true;
	}

	getIndex = () => {
		const { type, sectionIndex, subsectionIndex } = this.props;
		let indexVal = sectionIndex;
		if (type === 'subsection') {
			indexVal = sectionIndex + '-' + subsectionIndex;
		}
		return indexVal;
	};

	editorSetupFunc = (editor, error) => {
		editor.on(
			'Paste Change input Undo Redo',
			this.debounceUpdate,
		);
	  }

	render() {
		const {
			content,
			subsectionIndex,
			viewMode = false,
			contentType,
			type,
			setActiveEditor
		} = this.props;
		const { isOpen } = this.state;
			
		return (
			<>
				<EditorWrap className="mb-3 editorWapper">
					{!viewMode ? (
						<>
							<TinymceEditor
								placeholder={type=='subsection' ? "Enter subsection content..." : "Enter section content..."}
								key={subsectionIndex}
								ref={this.editorRef}
								onFocus={() => {
									setActiveEditor(this.editorRef)
								}}
								onInit={(evt, editor) => {
									this.editorRef.current = editor;
									return null;
								}}
								onEditorChange={() => {
									return null;
								}}
								onChange={() => {}}
								setupProps={this.editorSetupFunc}
								initialValue={content}
								editorHeight='696'
								devTool={contentType == "corePlan" ? "code" : ""}
							/>
						</>
					) : (
						<div
						    className='DisabledContainer'
							dangerouslySetInnerHTML={{
								__html: content,
							}}
						/>
					)}
				</EditorWrap>
			</>
		);
	}
}

SingleEditor.propTypes = {
	content: PropTypes.any,
	updateNodes: PropTypes.func,
	sectionIndex: PropTypes.number,
	subsectionIndex: PropTypes.number,
	commonVariables: PropTypes.array,
	templateVariables: PropTypes.array,
	templateId: PropTypes.string,
	reloadVariables: PropTypes.func,
	editVariable: PropTypes.func,
	deleteVariable: PropTypes.func,
	type: PropTypes.string,
	actualSectionId: PropTypes.number,
	actualSubsectionId: PropTypes.number,
	template: PropTypes.object,
    subsection: PropTypes.object
};

export { SingleEditor };
