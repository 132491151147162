import React, { useState, useEffect } from 'react';
import {
	EmptyState,
	emptyDataIcon,
	noResultFoundIcon,
} from 'componentsV2';
import { PAST_ALARMS_TYPE } from '../../consts';
import '../css/EmptyStatuses.scss';

export const EmptyStatuses = ({ noDataAdded, alarmType }) => {
	const [emptyStatusContent, setEmptyStatusContent] = useState({
		title: '',
		description: '',
		icon: '',
	});

	useEffect(() => {
		let title = '';
		let icon = null;
		let Description = '';
		if (noDataAdded === true) {
			title = `No ` + (alarmType === PAST_ALARMS_TYPE ? `Past` : `Active`) + ` Alarms`;
			icon = emptyDataIcon;
			Description = () => (
				<>
					There are currently no
					{alarmType === PAST_ALARMS_TYPE ? ' past ' : ' active '}
					alarms <br /> at any locations.
				</>
			);
		} else {
			title = `No ` + (alarmType === PAST_ALARMS_TYPE ? 'past' : 'active') + ` alarms found`;
			icon = noResultFoundIcon;
			Description = () => (
				<>
					Your search didn't match any
					{alarmType === PAST_ALARMS_TYPE ? ' past ' : ' active '}
					alarms.
				</>
			);
		}
		setEmptyStatusContent({
			title: title,
			icon: icon,
			description: <Description />,
		});
	}, [alarmType, noDataAdded]);

	return (
		<EmptyState
			header={emptyStatusContent.title}
			description={emptyStatusContent.description}
			icon={emptyStatusContent.icon}
			showCircles={false}
			className={noDataAdded ? '' : 'empty-state-border'}
			bodyClass={'empty-state-body'}
		/>
	);
};
