import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Button } from "componentsV2";
import { AddUpdateBinderModal } from "./AddUpdateBinderModal";

export const CreateBinder = ({
    onSubmitFinished,
    selectedSites,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return (
        <>

            {modalIsOpen && (
                <AddUpdateBinderModal
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    onSubmitFinished={onSubmitFinished}
                    selectedSites={selectedSites}
                />
            )}

            <Button
                size="md"
                text="New binder"
                icon="plus"
                intent="default"
                onClick={() => setModalIsOpen(true)}
            />
        </>
    );
}

CreateBinder.propTypes = {
    onSubmitFinished: PropTypes.func.isRequired,
    selectedSites: PropTypes.array,
};
