import React, { useEffect, useState, useContext, useCallback } from 'react';
import { LoadingSpinner, TableCell, CardContainer } from 'componentsV2';
import { RapidAlarmService } from 'services';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { RAPID_ICONS } from 'assets/respond';
import '../css/StepOneAlarmType.scss';
import { STEP_TWO } from '../consts';

export const StepOneAlarmType = ({
	selectedAlarm,
	onChangeHandler,
	onCancelModalHandler,
	onChangeModalData,
	hasCallout,
}) => {
	const [loading, setLoading] = useState(false);
	const [alarmTypes, setAlarmTypes] = useState([]);
	const toaster = useContext(ToasterContext);

	useEffect(() => {
		onChangeModalData({
			modalTitle: 'Select alarm type',
			showCancelAction: false,
			showActivateAlarmAction: false,
			secondBtnTitle: 'Cancel',
			onGoBackHandler: onCancelModalHandler,
			showHeader: true,
		});
		getAlarmTypes();
	}, []);

	const getAlarmTypes = useCallback(() => {
		setLoading(true);
		const params = {
			sortKey: 'setAsPriority',
			sortDir: 'desc',
			disabled: 'false',
		};
		RapidAlarmService.getAlarmTypes(params)
			.then(response => {
				if (response.statusCode === 200) {
					setAlarmTypes(response.data);
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const getCardClassName = alarmType => {
		return `activate-alarm-modal-alarm-types-card ${
			selectedAlarm?.id === alarmType.id ? 'selected' : ''
		}
			${alarmType.setAsPriority ? 'full-width' : 'half-width'}
		`;
	};

	const onClickHandler = alarmType => {
		onChangeHandler({ alarmType });
		onChangeModalData({
			activeStep: STEP_TWO,
			modalSubTitle: '',
		});
	};

	if (loading) return <LoadingSpinner />;
	return (
		<div
			className={`activate-alarm-modal-alarm-types ${hasCallout ? ' hasCalloutWrapper' : ''}`}
		>
			{alarmTypes.map(alarmType => (
				<CardContainer
					key={alarmType.id}
					cardCustomClass={getCardClassName(alarmType)}
					onClick={() => onClickHandler(alarmType)}
				>
					<TableCell
						text={alarmType.title}
						leftIcon={<img src={RAPID_ICONS[alarmType.icon]} />}
					/>
				</CardContainer>
			))}
		</div>
	);
};
