import React, { useState, useEffect } from 'react';
import { DrillTypesService } from 'services/DrillTypesService';
import { Dropdown, FormModal, LoadingSpinner } from 'componentsV2';

export const AddDrillTypeModal = ({
  isOpen,
  loading = false,
  onModalSubmit,
  onCloseModal,
  newDrillType = null,
  onChange,
}) => {
  const [drillTypes, setDrillTypes] = useState(null);

  useEffect(() => {
    DrillTypesService.getAll(false).then((serviceDrillTypes) =>
      setDrillTypes(serviceDrillTypes),
    );
  }, []);

  return (
    <>
      <FormModal
        title='Add new drill type'
        size='small'
        isOpen={isOpen}
        onClose={onCloseModal}
        showCloseButton={false}
        confirmBtn={{
          title: 'Add it',
          onClick: onModalSubmit,
          disabled: !newDrillType,
        }}
        cancelBtn={{
          title: 'Cancel',
          onClick: onCloseModal,
        }}
        scrollContent={false}
      >
        <div className='w-100'>
          {loading ? (
            <LoadingSpinner className='mt-3' />
          ) : (
            <Dropdown
              isRequired={true}
              placeholder='Select a drill type'
              label='Type'
              value={newDrillType}
              onChange={onChange}
              options={drillTypes}
            />
          )}
        </div>
      </FormModal>
    </>
  );
};
