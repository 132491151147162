import React, { useEffect, useState } from 'react';
import { FloorplanService } from 'services';
import { formatDate } from 'utilities/dates';
import { Avatar, Tooltip, LoadingSpinner } from 'componentsV2';
import questionIcon from "./assets/questionIcon.svg";
import "./css/SiteInfo.scss";
import "pages/Flipchart/css/DetailsCardContainer.scss"
import { MapOptionCard } from './components/MapOptionCard';
import { MAP_SITE_INFO } from './consts';
import { UnarchiveAllModal } from './modals/UnarchiveAllModal';

export const SiteInfo = ({
  siteId,
  toggleMapOption,
  closeModal,
  modalToShow,
	setModalToShow,
  loadMapDetails
}) => {

  const [loading, setLoading] = useState(false);
  const [siteInfo, setSiteInfo] = useState(null);
  const [location, setLocation] = useState("");

  useEffect(() => {
    if (!siteInfo) {
      setLoading(true);
      FloorplanService.getSiteInfo({ siteId: siteId })
        .then(response => {
          if (response.statusCode === 200) {
            setSiteInfo(response.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (siteInfo) {
      let loc = '';
      if (siteInfo.address) {
        loc = loc + siteInfo.address;
      }
      if (siteInfo.city) {
        loc = loc + ', ' + siteInfo.city;
      }
      if (siteInfo.state) {
        loc = loc + ', ' + siteInfo.state;
      }
      if (siteInfo.zip) {
        loc = loc + ' ' + siteInfo.zip;
      }
      if (siteInfo.country) {
        loc = loc + ', ' + siteInfo.country;
      }
      setLocation(loc);
    }
  }, [siteInfo]);

  return (       
        <MapOptionCard
          isOpen={true}
          title="Site info"
          className="site-info-wrapper"
          onClick={() => toggleMapOption(MAP_SITE_INFO)}
        >
            {loading || !siteInfo
              ? <LoadingSpinner />
              :
              <div className="details-content">
                <UnarchiveAllModal
                    modalToShow={modalToShow}
                    setModalToShow={setModalToShow}
                    closeModal={closeModal}
                    siteInfo={siteInfo}
                    loadMapDetails={loadMapDetails}
                />
                {siteInfo.name && (
                  <div className="details-row">
                    <div className="left-side">
                      <div className="label">Site name</div>
                      <div className="content">
                        <div className="text">{siteInfo.name}</div>
                      </div>
                    </div>
                  </div>
                )}

                {location.length > 0 && (
                  <div className="details-row">
                    <div className="left-side">
                      <div className="label">
                        <div className="location-title">
                          Location
                          <Tooltip
                            tooltipTitle="A map will be generated using this location. Location can be modified in the Site Information section."
                            theme={'light'}
                          >
                            <img src={questionIcon} alt="Location info" />
                          </Tooltip>
                        </div>
                      </div>
                      <div className="content">
                        <div className="text">{location}</div>
                      </div>
                    </div>
                  </div>
                )}

                {(siteInfo.lat || siteInfo.long) && (
                  <div className="details-row">
                    <div className="left-side">
                      <div className="label">Location coordinates</div>
                      <div className="content">
                        <div className="text">
                          {siteInfo.lat && (
                            <>
                              Latitude {siteInfo.lat}
                              <br />
                            </>
                          )}
                          {siteInfo.long && (
                            <>
                              Longitude {siteInfo.long}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {siteInfo.createdBy && (
                  <div className="details-row">
                    <div className="left-side">
                      <div className="label">Created by</div>
                      <div className="content">
                        <Avatar
                          size="md"
                          name={siteInfo.createdBy.img_link ? null : `${siteInfo.createdBy.first_name} ${siteInfo.createdBy.last_name}`}
                          label={`${siteInfo.createdBy.first_name} ${siteInfo.createdBy.last_name}`}
                          supportingText={siteInfo.createdBy.title}
                          image={siteInfo.createdBy.img_link ? siteInfo.createdBy.img_link : null}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {siteInfo.dateCreated && siteInfo.dateCreated.date && (
                  <div className="details-row">
                    <div className="left-side">
                      <div className="label">Date created</div>
                      <div className="content">
                        <div className="text">{formatDate(siteInfo.dateCreated.date).toLowerCase()}</div>
                      </div>
                    </div>
                  </div>
                )}

                {siteInfo.modifiedBy && (
                  <div className="details-row">
                    <div className="left-side">
                      <div className="label">Last modified by</div>
                      <div className="content">
                        <Avatar
                          size="md"
                          name={siteInfo.modifiedBy.img_link ? null : `${siteInfo.modifiedBy.first_name} ${siteInfo.modifiedBy.last_name}`}
                          label={`${siteInfo.modifiedBy.first_name} ${siteInfo.modifiedBy.last_name}`}
                          supportingText={siteInfo.modifiedBy.title}
                          image={siteInfo.modifiedBy.img_link ? siteInfo.modifiedBy.img_link : null}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {siteInfo.dateModified && siteInfo.dateModified.date && (
                  <div className="details-row">
                    <div className="left-side">
                      <div className="label">Date last modified</div>
                      <div className="content">
                        <div className="text">{formatDate(siteInfo.dateModified.date).toLowerCase()}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
    </MapOptionCard>
  )
}
