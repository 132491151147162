import React, { useState, useEffect, useContext } from 'react';
import { EmptyState, emptyDataIcon, noResultFoundIcon, Button } from 'componentsV2';
import { DocumentModalsContext } from './DocumentModalsProvider';
import './css/EmptyStatuses.scss';

export const EmptyStatuses = ({ filterationData, noDataAdded, editPermission }) => {
	const { showUploadModal } = useContext(DocumentModalsContext);

	const [emptyStatusContent, setEmptyStatusContent] = useState({
		title: '',
		description: '',
		icon: '',
	});

	useEffect(() => {
		if (noDataAdded === true) {
			setEmptyStatusContent({
				title: 'No files were uploaded yet',
				description: 'Add a file to your Emergency Management Suite',
				icon: emptyDataIcon,
			});
		} else {
			setEmptyStatusContent({
				title: 'No file found',
				description:
					'Your search didn’t match any file. Please try again or upload a new file',
				icon: noResultFoundIcon,
			});
		}
	}, [filterationData]);

	return (
		<EmptyState
			header={emptyStatusContent.title}
			description={emptyStatusContent.description}
			icon={emptyStatusContent.icon}
			className={noDataAdded ? '' : 'empty-state-border'}
			showCircles={false}
			bodyClass={'empty-state-body'}
		>
			{editPermission && noDataAdded && (
				<Button
					size="lg"
					text="Upload file"
					icon="cloud-upload"
					intent="default"
					onClick={() => showUploadModal()}
				/>
			)}
		</EmptyState>
	);
};
