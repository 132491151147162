import React, { useEffect, useState } from "react";
import { generateAvatar } from "../../App/AvatarInitials";
import { Card, Position, Popover } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { PhoneWithExtension } from 'utilities/PhoneWithExtension';
import { Helper } from 'utilities/helper';
import './css/ContactCard.scss';
import { PHONE_TYPES } from 'utilities/constants';

const ContactCard = ({ contact }) => {
    const [isOpen, setIsOpen] = useState(false);
    const mobileNumber = contact.person.phones.find(phone => phone.type == PHONE_TYPES.MOBILE)
    const homeNumber = contact.person.phones.find(phone => phone.type == PHONE_TYPES.HOME)
    const workNumber = contact.person.phones.find(phone => phone.type == PHONE_TYPES.WORK)
    const [avatarImg, setAvatarImg] = useState('');

    const name = contact.person.name.givenName.charAt(0) + contact.person.name.familyName.charAt(0);
    const avatarInitials = generateAvatar(name)

    useEffect(() => {
        if(contact.img_link) {
            setAvatarImg(
                <img src={contact.img_link} className="contact-profile-img" alt={contact.person.name.givenName} />
            );
        } else {
            setAvatarImg(
                <img src={avatarInitials} className="contact-profile-img" alt={contact.person.name.givenName} />
            );
        }
    }, [contact])

    return (
        <div className="contact-card-parent">
            <p
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
            >
                {contact.person.name.givenName} {contact.person.name.familyName}
            </p>
            {isOpen && (
                <Popover
                    isOpen={isOpen}
                    position={Position.RIGHT}
                    enforceFocus={false}
                    className='contact-card-popover'
                    modifiers={{
                        arrow: { 
                            enabled:false,
                        }
                    }}
                >
                    <i className="card-left-arrow"></i>
                    <Card className='contact-card-card'>
                        <div className="row">
                            <div className="col-md-6 text-center"> 
                                {avatarImg}
                            </div>
                            <div className="col-md-6"> 
                                <h3 className="contact-card-title">
                                    {contact.person.name.givenName} {contact.person.name.familyName}
                                </h3>
                                <div className="contact-card-label">
                                    <label className="title">Title</label>
                                    <p className="value">{contact.person.name.title}</p>
                                </div>
                                <div className="contact-card-label">
                                    <label className="title">Mobile</label>
                                    <p className="value">{Helper.formatDigitsToPhone(mobileNumber?.phoneNumber)}</p>
                                </div>
                                <div className="contact-card-label">
                                    <label className="title">Work phone</label>
                                    <p className="value">
                                        <PhoneWithExtension
                                            phone={Helper.formatDigitsToPhone(workNumber?.phoneNumber)} 
                                            extension={workNumber?.extension}
                                        />
                                    </p>
                                </div>
                                <div className="contact-card-label">
                                    <label className="title">Home phone</label>
                                    <p className="value">{Helper.formatDigitsToPhone(homeNumber?.phoneNumber)}</p>
                                </div>
                                <div className="contact-card-label">
                                    <label className="title">Email</label>
                                    <div className="value">{contact.person.email.emailAddress}</div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Popover>
            )}
         </div>
    );
}

ContactCard.PropTypes = {
    contact: PropTypes.object.isRequired
}

export default ContactCard;