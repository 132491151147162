import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';
import { Callout } from 'componentsV2';

export const ModalHeader = ({
	onClose,
	title,
	subtitle,
	respectSubtitleHtml = false,
	showCloseButton,
	icon,
	iconColor,
	customHeaderClass,
	showBackArrow = false,
	backArrowAction,
	hasCallout = false,
	calloutData,
}) => {
	let showHeader = title || subtitle || icon || showCloseButton;
	let showHeaderTexts = title || subtitle;

	if (!showHeader) return null;
	return (
		<div className={`modal-header-wrapper ${customHeaderClass}`}>
			<div className="modal-header-content">
				{hasCallout && (
					<Callout
						intent={calloutData.intent}
						icon={calloutData.icon}
						text={calloutData.text}
						className={calloutData?.type == 'primary' ? 'Callout_Primary' : ''}
					/>
				)}
				{icon && (
					<div className={`modal-icon icon-${iconColor ? iconColor : 'primary'}`}>
						<Icon icon={icon} />
					</div>
				)}

				{showHeaderTexts && (
					<div className="modal-header-texts">
						{title && (
							<div className="modal-title">
								{showBackArrow && (
									<a onClick={backArrowAction}>
										<Icon
											className="normal-modal-back-arrow"
											icon="arrow-left"
										/>
									</a>
								)}
								{title}
							</div>
						)}
						{subtitle && respectSubtitleHtml ? (
							<div
								className="modal-subtitle"
								dangerouslySetInnerHTML={{ __html: subtitle }}
							/>
						) : subtitle ? (
							<div className="modal-subtitle">{subtitle}</div>
						) : (
							''
						)}
					</div>
				)}
			</div>

			{showCloseButton && (
				<a onClick={() => onClose(false)} className="modal-close-button">
					<Icon icon="cross" />
				</a>
			)}
		</div>
	);
};

ModalHeader.propTypes = {
	onClose: PropTypes.func.isRequired,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	showCloseButton: PropTypes.bool,
	icon: PropTypes.string,
	iconColor: PropTypes.string,
};
