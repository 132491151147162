import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownSingleSelect } from './Dropdown.jsx';

export const DropdownPattern = () => {
	const options = [
		{ value: '1', label: 'Jimmy' },
		{ value: '2', label: 'Laura' },
		{ value: '3', label: 'Tommy' },
		{ value: '4', label: 'Jane' },
		{ value: '5', label: 'Mike' },
	];

	const [selectedOptions, setSelectedOptions] = useState();

	const onChange = selected => {
		setSelectedOptions(selected);
	};

	return (
		<div className="DropDownPattern__wrapper">
			<Dropdown
				label="Select one option"
				value={selectedOptions}
				options={options}
				onChange={onChange}
				isRequired={false}
				disabled={true}
				error={false}
			/>
			<Dropdown
				label="we have an error here:"
				value={selectedOptions}
				options={options}
				onChange={onChange}
				isRequired={false}
				disabled={false}
				error={true}
			/>
			<Dropdown
				label="Has empty search"
				value={selectedOptions}
				options={options}
				onChange={onChange}
				hasSearch
				hasEmptyState
				searchMessageTitle="No results"
				searchMessageSubtitle="No items match your search"
			/>
		</div>
	);
};
