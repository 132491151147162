import { HttpService } from 'networking/HttpService';
import { deserializeSites } from 'utilities/drills/deserializers';

export const StaffTypesService = {

	async getAll(includeSelectAll = false) {
		try {
			const response = await HttpService({
				method: 'GET',
				route: `/staff-types`,
			});
			const staffTypes = deserializeSites(response.data);
			if (includeSelectAll) {
				return [
					{ value: 'all', label: 'All Staff types' },
					...staffTypes,
				];
			}
			return staffTypes;
		} catch (error) {
			throw error;
		}
	},
	
};
