import React, { useContext } from 'react';
import {
	BASIC_INFO_SECTION,
	CONTACT_911_SECTION,
	COUNTDOWN_SECTION,
	FLIPCHART_SECTION,
	ROLLCALL_SECTION,
	PREVIEW_SECTION,
} from '../../../../consts';
import { BasicInfo, Contact911, Countdown, Flipchart, Preview, RollCall } from './components';
import { FooterActions } from './FooterActions';
import PropTypes from 'prop-types';
import './css/CardContent.scss';
import { CreateAlarmTypeContext } from '../../../../provider';

export const CardContent = ({ selectedSection, onChange, onSubmit }) => {
	const { allRequiredFieldsAreFilled } = useContext(CreateAlarmTypeContext);
	if (!selectedSection) return null;

	const isFinalizeBtnDisabled =
		selectedSection.name === PREVIEW_SECTION && !allRequiredFieldsAreFilled();
	return (
		<section className="create-alarm-type-body-card-content">
			<div className="create-alarm-type-body-card-content-fields">
				{selectedSection.name === BASIC_INFO_SECTION && <BasicInfo />}
				{selectedSection.name === CONTACT_911_SECTION && <Contact911 />}
				{selectedSection.name === COUNTDOWN_SECTION && <Countdown />}
				{selectedSection.name === FLIPCHART_SECTION && <Flipchart />}
				{selectedSection.name === ROLLCALL_SECTION && <RollCall />}
				{selectedSection.name === PREVIEW_SECTION && (
					<Preview
						header="Preview this alarm"
						description="You can make edits within this page or you can go back to each section via the side navigation menu to make any necessary changes."
					/>
				)}
			</div>
			<FooterActions
				previousButtonTitle={
					selectedSection.previous ? `Previous: ${selectedSection.previous}` : null
				}
				nextButtonTitle={
					selectedSection.name === PREVIEW_SECTION
						? 'Finalize'
						: `Next: ${selectedSection.next}`
				}
				nextButtonDisabled={isFinalizeBtnDisabled}
				onClickPreviousAction={() => onChange(selectedSection.previous)}
				onClickNextAction={() =>
					selectedSection.name === PREVIEW_SECTION
						? onSubmit()
						: onChange(selectedSection.next)
				}
			/>
		</section>
	);
};

CardContent.propTypes = {
	selectedSection: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};
