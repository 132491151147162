import React, { useContext, useState } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import EnableModal from './enablemodal';
import DisableModal from './disablemodal';
import { DeleteRoleModal } from './DeleteRoleModal';
import { ToasterContext } from '../../App';
import { RolesService } from 'services/RolesService';
import './css/ActionsColumn.scss';
import ActionsButton from 'components/ActionsButton';
import AddEditRoleModal from './AddEditRoleModal';

const ActionsColumn = ({ row, updateDataCallBack, sitesLoadedData }) => {
	const [rowId, setRowId] = useState(null);
	const [roleFormType, setRoleFormType] = useState('');
	const [roleFormTitle, setRoleFormTitle] = useState('');
	const [modalRoleId, setModalRoleId] = useState(null);
	const [modalToShow, setModalToShow] = useState(null);
	const [siteName, setsiteName] = useState('');
	const [roleStatus, setroleStatus] = useState(null);
	const [roleId, setroleId] = useState(null);
	const toaster = useContext(ToasterContext);
	const [modalIsLoading, setModalIsLoading] = useState(false);

	const showRoleModal = (modal, role = null) => {
		if (role != null) {
			setRoleFormType('edit');
			setRoleFormTitle('Edit');
			setModalRoleId(role.id);
		} else {
			setRoleFormType('add');
			setRoleFormTitle('Add');
			setModalRoleId(null);
		}

		setModalToShow(modal);
	};

	const handleEnableModelData = role => {
		setModalToShow('EnableRoleModal');
		addSiteName(role);
		setroleStatus(1);
		setroleId(role.id);
	};

	const handleDisableModelData = role => {
		setModalToShow('DisableRoleModal');
		addSiteName(role);
		setroleStatus(0);
		setroleId(role.id);
	};

	const submitModel = () => {
		setModalIsLoading(true);
		RolesService.toggleStatus(roleId, roleStatus)
			.then(resp => {
				if (resp.error) {
					setWarningTitle('Unable to disable role');
					setWarningMessage(resp.error.description);
					setShowGeneralWarning(true);
				} else if (resp.data.message) {
					toaster(resp.data.message, Intent.SUCCESS);
				} else {
					toaster('updated successfully', Intent.SUCCESS);
				}
				setModalIsLoading(false);
				setModalToShow(false);
			})
			.then(() => {
				updateDataCallBack();
			})
			.catch(resp => {
				setModalIsLoading(false);
				setModalToShow(false);
				toaster(resp.error.description, Intent.DANGER);
			});
	};

	const showDeleteRoleMoal = role => {
		setroleId(role.id);
		addSiteName(role);
		setModalToShow('DeleteRoleModal');
	};

	const DeleteRoleHandler = () => {
		setModalIsLoading(true);
		RolesService.deleteRole(roleId)
			.then(resp => {
				if (resp.statusCode === 200) {
					toaster('Role successfully deleted!', Intent.SUCCESS);
				}
			})
			.then(() => {
				setModalToShow(false);
				setModalIsLoading(false);
				updateDataCallBack();
			})
			.catch(resp => {
				if (resp.statusCode === 400) {
					toaster(resp.error.description, Intent.DANGER);
					setModalIsLoading(false);
				} else if (resp.error) {
					toaster(resp.error.description, Intent.DANGER);
				}
			});
	};

	const addSiteName = role => {
		if (role.allSites == true) {
			setsiteName('All Sites');
		} else if (role.sites.length > 0) {
			let sites = '';

			role.sites.forEach(site => {
				sites += site.name + ', ';
			});

			// remove last ', '
			sites = sites.slice(0, -2);

			setsiteName(sites);
		}
	};

	return (
		<>
			<ActionsButton
				disabled={row.isDefault == true || row.canEdit == false ? true : false}
				isOpen={rowId == row.id ? true : false}
			>
				<>
					{row.isEnabled == true && (
						<>
							<Button
								icon="annotation"
								minimal
								text="Edit"
								className="w-100 justify-content-start actions-popover-btn"
								onClick={() => {
									showRoleModal('roleForm', row);
									setRowId(false);
								}}
							/>
							<Button
								icon="disable"
								minimal
								text="Disable"
								className="w-100 justify-content-start actions-popover-btn"
								onClick={() => {
									handleDisableModelData(row);
									setRowId(false);
								}}
							/>
						</>
					)}
					{row.isEnabled === false && (
						<Button
							icon="power"
							minimal
							text="Enable"
							className="w-100 justify-content-start actions-popover-btn"
							onClick={() => {
								handleEnableModelData(row);
								setRowId(false);
							}}
						/>
					)}
					<hr />
					<Button
						icon="trash"
						intent="Danger"
						minimal
						text="Delete"
						className="w-100 justify-content-start actions-popover-btn"
						onClick={() => {
							showDeleteRoleMoal(row);
							setRowId(false);
						}}
					/>
				</>
			</ActionsButton>

			{modalToShow === 'EnableRoleModal' && (
				<EnableModal
					id={roleId}
					siteName={siteName}
					isOpen={true}
					closeModal={() => setModalToShow(false)}
					submitModel={submitModel}
					loading={modalIsLoading}
				/>
			)}
			{modalToShow === 'DisableRoleModal' && (
				<DisableModal
					id={roleId}
					siteName={siteName}
					isOpen={true}
					closeModal={() => setModalToShow(false)}
					submitModel={submitModel}
					loading={modalIsLoading}
				/>
			)}
			{modalToShow === 'DeleteRoleModal' && (
				<DeleteRoleModal
					setModalToShow={setModalToShow}
					onActionClick={DeleteRoleHandler}
					header="Delete Role"
					Sites={siteName}
					loading={modalIsLoading}
				/>
			)}
			{modalToShow === 'roleForm' && (
				<AddEditRoleModal
					formType={roleFormType}
					formTitle={roleFormTitle}
					setModalToShow={setModalToShow}
					closeModal={() => setModalToShow(false)}
					roleId={modalRoleId}
					updateDataCallBack={updateDataCallBack}
					sitesLoadedData={sitesLoadedData}
				/>
			)}
		</>
	);
};

export default ActionsColumn;
