import { isOn, names } from '../../utilities/flags';
import SafetyPlanTemplatesDashboard from '../SafetyPlansV2/SafetyPlanTemplatesDashboard';

/**
 * Apply specific business logic to filter final routes
 * for permission based access prevention.
 *
 * @param {Array} routes
 *   Array of routes to filter.
 */
export const filterRoutes = routes => {
	let out = [...routes];

	// EIT-4104: Lockout non-paying TN sites from anything other than user management and safety plans.
	if (isOn(names.SITE_IS_TN)) {
		out = out.filter(
			({ link, parentPath, protected: isProtected = true }) =>
				parentPath === '/user-management' ||
				link.match('(/safety-plans*)|(/user-management*)') ||
				isProtected === false,
		);

		// Safety plans as homepage.
		out.push({
			link: '/',
			component: SafetyPlanTemplatesDashboard,
			parentPath: '/safety-plans',
		});
	}
	return out;
};
