import React from "react";
import { MediaPlayer } from "./MediaPlayer.jsx";

export const MediaPlayerPattern = () => {
    return (
        <div className="MediaPlayerPattern__wrapper">
            <MediaPlayer mediaUrl={'https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4'} />
            <MediaPlayer mediaUrl={'https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/Yodel_Sound_Effect.mp3'} />
        </div>
    )
}
