import React from 'react';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchBar } from 'components/SearchBar';
import { DropdownMultiSelect } from 'componentsV2';

export const DocumentsFilters = ({ defaultSelectedSites, setFilterationData, filterationData }) => {
	const [selectedSites, setSelectedSites] = useState([]);
	const [selectedTypes, setSelectedTypes] = useState([]);

	const onSearchHandler = title => {
		setFilterationData(oldData => ({
			...oldData,
			title: title,
			page: 1,
		}));
	};

	const onChangeSiteHandler = sites => {
		setFilterationData(oldData => ({
			...oldData,
			page: 1,
			buildingIds: sites.map(site => site.value),
		}));

		setSelectedSites(sites);
	};

	const types = [
		{ value: 'Documents', label: 'Document' },
		{ value: 'Images', label: 'Image' },
		{ value: 'Video', label: 'Video' },
		{ value: 'Audio', label: 'Audio' },
		{ value: '360image', label: '360 Image' },
	];

	const onChangeTypesHandler = types => {
		setFilterationData(oldData => ({
			...oldData,
			page: 1,
			types: types.map(type => type.value),
		}));

		setSelectedTypes(types);
	};

	return (
		<section className="filteration-container">
			<div className="left-side">
				<SearchBar
					placeholder="Search by name..."
					icon="search"
					className="search-bar-text"
					value={filterationData.title}
					onSearch={onSearchHandler}
				/>
			</div>
			<div className="right-side">
				<SitesDropdownMultiSelect
					label={null}
					value={selectedSites}
					placeholder="Select a Site"
					onChange={onChangeSiteHandler}
					visibleOptions={1}
					labelState={false}
					className="sites-mddl"
					allowSelectAll={false}
				/>
				<DropdownMultiSelect
					placeholder="Filter by type"
					values={selectedTypes}
					options={types}
					onChange={onChangeTypesHandler}
					visibleOptions={1}
				/>
			</div>
		</section>
	);
};

DocumentsFilters.propTypes = {
	defaultSelectedSites: PropTypes.array,
	setFilterationData: PropTypes.func.isRequired,
	filterationData: PropTypes.object,
};
