import Konva from 'konva';

export const drawLabel = (title, parentShape, paddingTop = 0) => {
	const labelGroup = new Konva.Group({
		draggable: false,
		listening: false,
		scalable: false,
	});

	const labelBorder = new Konva.Rect({
		stroke: '#B3D8DD',
		strokeWidth: 1,
		fill: '#E6F2F4',
		height: 25,
		cornerRadius: 32,
	});

	const labelText = new Konva.Text({
		x: labelBorder.x(),
		y: labelBorder.y(),
		text: title,
		fontSize: 12,
		fontFamily: 'Nunito Sans',
		fontStyle: 'normal',
		fill: '#007E8F',
		padding: 7,
		textAlign: 'center',
		justifyContent: 'center',
		verticalAlign: 'middle',
	});
	labelBorder.width(labelText.width() + 2);

	labelGroup.add(labelBorder);
	labelGroup.add(labelText);

	// Center the Label
	const xPos = parentShape.height() / 2 + parentShape.x() - labelBorder.width() / 2;
	const yPos = parentShape.y() - paddingTop;
	labelGroup.x(xPos);
	labelGroup.y(yPos);

	return labelGroup;
};
