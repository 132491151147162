import React from 'react'
import { Card } from '@blueprintjs/core'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { rightArrow } from 'assets/icons';
import { useEventLogs } from 'hooks/useEventLogs';

export const EventLogItemWrapper = styled(Card)`
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-bottom: 16px;

    border-radius: 3px;
    border: 1px solid #E1E8ED;
    background: #FFF;
    box-shadow: none !important;
`;

const EventLogDescription = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    span {
        color: #293742;
        font-family: Nunito Sans;
        font-size: 14px;
        line-height: 20px;
        strong {
            font-weight: 700;
        }
    }
    @media screen and (max-width: 991px) {
        flex-direction: column;
        .dot {
            display: none;
        }
    }
`;

const EventLogAction = styled.span`
    color: #293742;
    font-family: Nunito Sans;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
`;

const StrikethroughSpan = styled.span`
    text-decoration: line-through;
    color: #5C7080;
`;

const EvenLogDateTime = styled.span`
    color: #293742;
`;

const DiffWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 32px;
    span:first-child {
        color: var(--grayscale-gray-1, #5C7080);
        font-style: normal;
        font-weight: 400;
    }
    @media screen and (max-width: 991px) {
        flex-direction: column;
        gap: 15px;
        img {
            transform: rotate(90deg);
        }
    }
    span.diff-add {
        background-color: #E8F2F9;
    }
    span.diff-remove {
        background-color: #FCF3EA;
    }
`;

const ContentBox = styled.div`
    width: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    max-height: 110px;
    overflow-y: auto;
    @media screen and (max-width: 991px) {
        width: 100%;
    }
`;

const EventLogItem = ({ item }) => {

    const {
        userName,
        itemName,
        description,
        dateTime,
        value,
        value_diff,
        layout,
    } = useEventLogs(item);

    if (description.length === 0) return <></>;

    return (
        <EventLogItemWrapper>
            <EventLogDescription>
                <span>
                    <strong>{ userName }</strong>
                    { ` ${description} ` }
                    <strong>{ itemName }</strong>
                </span>
                <span className='dot'>•</span>
                <EvenLogDateTime>{ dateTime }</EvenLogDateTime>
            </EventLogDescription>
            <EventLogAction>
                { layout === 'text_only' && 
                    value 
                }
                { layout === 'strikethrough' && 
                    <StrikethroughSpan>{ value }</StrikethroughSpan>
                }
                { layout === 'diff_line' && 
                    (
                        <DiffWrapper>
                            <span>{ value }</span>
                            <img src={ rightArrow } alt='Diff' />
                            <span>{ value_diff }</span>

                        </DiffWrapper>
                    )
                }
                { layout === 'diff_content' && 
                    (
                        <DiffWrapper>
                            <ContentBox>{ parse(value) }</ContentBox>
                            <img src={ rightArrow } alt='Diff' />
                            <ContentBox>{ parse(value_diff) }</ContentBox>
                        </DiffWrapper>
                    )
                }
            </EventLogAction>
        </EventLogItemWrapper>
    )
}

EventLogItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default EventLogItem;