import React from 'react';
import { CHECKIN_STEPS } from '../consts';
import { SearchBar } from 'components/SearchBar';
import { useStudentStatus } from '../../../Students/hooks';
import './css/GuardiansListStep.scss';
import { LoadingSpinner, noResult, EmptyState } from 'componentsV2';
import { Helper } from 'utilities/helper';

export const GuardiansListStep = ({
	guardiansResponse,
	selectedGuardian,
	setSelectedGuardian,
	guardianFilterationData,
	setGuardianFilterationData,
	totalGuardians,
	guadianLoading,
	setSelectedStudents,
	setAddAsNew,
	onChangeModalDetails,
	isEmptyData,
	setIsUseSelected,
}) => {
	const onSearchListHandler = name => {
		setGuardianFilterationData(oldData => ({
			...oldData,
			guardianName: name,
			page: 1,
		}));
	};
	const handleChange = target => {
		let studentsList = [];
		let currentSelected = guardiansResponse.find(item =>
			target.id ? item.id == target.id : item.personId == target.personId,
		);
		currentSelected.students.forEach(item => {
			studentsList.push(item);
		});
		setAddAsNew(false);
		setIsUseSelected(false);
		setSelectedGuardian(currentSelected);
		setSelectedStudents(studentsList);
		onChangeModalDetails({
			activeStep: CHECKIN_STEPS.GUARDIAN_WITH_STUDENTS_STEP,
		});
	};

	const onScrollGuardiansList = () => {
		let myGuardiansList = document.getElementById('my-guardian-list');
		myGuardiansList.addEventListener('scroll', debouncedScrollHandler());
	};

	const debouncedScrollHandler = _.debounce(async () => {
		let myGuardiansList = document.getElementById('my-guardian-list');

		if (
			Math.round(myGuardiansList.offsetHeight) + Math.round(myGuardiansList.scrollTop) + 2 >=
			myGuardiansList.scrollHeight
		) {
			if (guardiansResponse.length < totalGuardians) {
				const newData = {
					...guardianFilterationData,
					page: guardianFilterationData.page + 1,
				};
				setGuardianFilterationData(newData);
			}
		}
	}, 300);
	return (
		<div className="GuardiansTable__Wrapper">
			<div>
				<SearchBar
					placeholder="Search guardian by name or phone number..."
					icon="search"
					className="search-bar-text"
					value={guardianFilterationData.guardianName}
					onSearch={onSearchListHandler}
				/>
			</div>
			<br />

			{guadianLoading && <LoadingSpinner />}
			{!guadianLoading && (
				<>
					{guardiansResponse.length == 0 && !isEmptyData && (
						<EmptyState
							header="No results found"
							description="Your search didn’t match any Guardian.
							Please try again."
							className="GuardianList_emptyState"
							icon={noResult}
							showCircles={false}
						/>
					)}
					{guardiansResponse.length > 0 && (
						<div
							id="my-guardian-list"
							onScroll={onScrollGuardiansList}
							className="GuardianContent_Wrapper"
						>
							<div className="row">
								<div className="col-md-12">
									{guardiansResponse.map((item, index) => {
										let customDisable = false;
										let hasStudentCheckedIn = false;
										if (item.checkedIn) {
											item.checkIn = { status: 'checkedIn' };
										}
										hasStudentCheckedIn = item.students.some(student => {
											return student.guardianCheckIn.length > 0;
										});

										customDisable = item.checkedIn || hasStudentCheckedIn;

										return useStudentStatus(
											item.name,
											Helper.formatDigitsToPhone(item.phone),
											item,
											() => handleChange(guardiansResponse[index]),
											selectedGuardian,
											false,
											() => {},
											[],
											customDisable,
										);
									})}
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};
