import React, { useEffect, useState, useContext } from 'react';
import { Button, AlertModal, FeaturedIcon, ToasterContext } from 'componentsV2';
import { FloorplanService } from 'services/FloorplanService';
import { Intent } from '@blueprintjs/core';
import {
	GEOMETRY_TYPE_ICON,
	GEOMETRY_TYPE_ROUTE,
	GEOMETRY_TYPE_TEXT,
	GEOMETRY_TYPE_POLYGON,
} from '../consts';
import { deleteShapeOnMap, deleteAreaOnMap } from '../components/Functions';
import {
	MAP_AREA_Add,
	MAP_AREA_Edit,
	MAP_AREA_ADD_EDIT,
	MAP_AREAS,
	MAP_SHAPE_CONTENT,
} from '../../consts';

export const DeleteShapeModal = ({
	onClose,
	floorplanId,
	shape,
	layer,
	transformer,
	mapToaster,
	setRefreshList,
	setRefreshAreaList,
	toggleMapOption,
	siteId,
	loadMapDetails,
	setIconUpdated,
	setAreaHasChange,
	setSelectedArea,
}) => {
	const [loading, setLoading] = useState(false);
	const [modalData, setModalData] = useState({
		title: '',
		subTitle: '',
		onConfirmAction: () => {},
		icon: 'trash',
		confirmTitle: '',
	});

	const successMsg =
		GEOMETRY_TYPE_ICON || GEOMETRY_TYPE_ROUTE ? 'You have successfully removed this icon.' : '';
	const toaster = useContext(ToasterContext);

	useEffect(() => {
		switch (shape.geometryType) {
			case GEOMETRY_TYPE_ICON:
				updateModalDataIcon();
				break;
			case GEOMETRY_TYPE_ROUTE:
				updateModalDataIcon();
				break;
			case GEOMETRY_TYPE_TEXT:
				updateModalDataText();
				break;
			case GEOMETRY_TYPE_POLYGON:
				updateModalDataArea();
				break;
		}
	}, [shape]);

	const updateModalDataIcon = () => {
		setModalData({
			title: 'Are you sure you want to remove this icon?',
			subTitle:
				'This will remove this icon and all its information from this map layer. Once removed, the information cannot be recovered.',
			onConfirmAction: onDeleteShapeHandler,
			icon: 'remove',
			confirmTitle: 'Yes, remove it',
		});
	};

	const updateModalDataText = () => {
		setModalData({
			title: 'Are you sure you want to delete "' + shape.text + '"?',
			subTitle: 'Once deleted, this cannot be recovered.',
			onConfirmAction: onDeleteTextHandler,
			icon: 'trash',
			confirmTitle: 'Yes, delete it',
		});
	};

	const updateModalDataArea = () => {
		setModalData({
			title: 'Are you sure you want to delete this area?',
			subTitle:
				'This will remove this area and all its content from this map layer. Once removed, the information cannot be recovered.',
			onConfirmAction: onDeleteAreaHandler,
			icon: 'trash',
			confirmTitle: 'Yes, delete it',
		});
	};

	const onDeleteShapeHandler = () => {
		setLoading(true);
		FloorplanService.deleteShape(floorplanId, shape.id)
			.then(response => {
				if (response.statusCode === 200) {
					setRefreshList(oldValue => !oldValue);
					deleteShapeOnMap(shape.id, layer, transformer);
					document.getElementsByClassName('map-options-buttons')[0].style.display =
						'flex';
					if (
						shape.geometryType === GEOMETRY_TYPE_ICON ||
						shape.geometryType === GEOMETRY_TYPE_ROUTE
					) {
						setIconUpdated(false);
					} else if (shape.geometryType === GEOMETRY_TYPE_POLYGON) {
						setAreaHasChange(false);
					}
					toaster(
							successMsg,
							Intent.NONE,
							<FeaturedIcon icon='remove' size='md' type='Gray' shape={'circleIcon'} />,
							2000,
							false,
							'map-view-page',
					);
					toggleMapOption(null);
				} else {
					mapToaster('Something went wrong!', Intent.DANGER);
				}
			})
			.catch(err => {
				mapToaster(err.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				onClose();
			});
	};

	const onDeleteTextHandler = () => {
		setLoading(true);

		const body = {
			shapeId: parseInt(shape.id),
		};
		const toasterSuccessMsg = 'You have successfully deleted "' + shape.text + '"';

		FloorplanService.deleteTexts(siteId, body)
			.then(response => {
				toaster(toasterSuccessMsg, Intent.NONE, <FeaturedIcon icon='trash' size='md' type='Gray' shape={'circleIcon'} />, 2000, false, 'map-view-page')
				if (response.statusCode === 200) {
					setRefreshList(oldValue => !oldValue);
					deleteShapeOnMap(shape.id, layer, transformer);
					toggleMapOption(null);
				} else {
					mapToaster('Something went wrong!', Intent.DANGER);
				}
			})
			.catch(error => {
				const msg = `Something went wrong, please try again later`;
				mapToaster(`${msg}`, Intent.DANGER, null, 2000, false, 'map-view-page');
			})
			.finally(() => {
				setLoading(false);
				onClose();
			});
	};

	const onDeleteAreaHandler = () => {
		setLoading(true);
		FloorplanService.deleteShape(floorplanId, shape.id)
			.then(response => {
				if (response.statusCode === 200) {
					const msg = `You have successfully deleted the area “${shape.title}”`;
					mapToaster(msg, Intent.SUCCESS, null, 2000, false, 'map-view-page');
					toggleMapOption(MAP_AREA_ADD_EDIT);
					deleteAreaOnMap(shape.id, layer, transformer);
					setRefreshAreaList(oldValue => !oldValue);

					document.getElementsByClassName('map-options-buttons')[0].style.display =
						'flex';
					setSelectedArea(null);
				} else {
					mapToaster(
						`Something went wrong, please try again later`,
						Intent.DANGER,
						null,
						2000,
						false,
						'map-view-page',
					);
				}
			})
			.catch(err => {
				mapToaster(
					`Something went wrong, please try again later`,
					Intent.DANGER,
					null,
					2000,
					false,
					'map-view-page',
				);
			})
			.finally(() => {
				setLoading(false);
				onClose();
			});
	};

	return (
		<AlertModal
			isOpen={true}
			onClose={onClose}
			loading={loading}
			size="medium"
			title={modalData.title}
			customClassName="map-container-modals"
			subtitle={modalData.subTitle}
			showCloseButton={false}
			icon={modalData.icon}
			iconColor="error"
			alertConfirmButton={
				<Button
					text={modalData.confirmTitle}
					type="primaryDanger"
					size="md"
					onClick={modalData.onConfirmAction}
				/>
			}
			alertCancelButton={
				<Button text="No, go back" type="tertiary" size="md" onClick={onClose} />
			}
		/>
	);
};
