import React from "react";
import { BootstrapedTable, sortingStyle } from "componentsV2";
import { formatDate } from "utilities/dates";
import { DocumentTitleWithIcon } from "./DocumentTitleWithIcon";
import { ActionsList } from "./ActionsList";

export const DocumentsTable = ({
	list,
	totalRows,
	filterationData,
	setFilterationData,
	editPermission,
	setSelectedRows,
	selectedRows,
	history
}) => {

	const columns = [
		{
			key: 'uniqid',
			dataField: 'uniqid',
			text: 'uniqid', 
			hidden: true 
		},
		{
			key: 'title',
			dataField: 'title',
			text: 'Title',
			sort: true,
			classes: 'first-column document-title',
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (
				<DocumentTitleWithIcon row={row} />
			)
		},
		{
			key: 'type',
			dataField: 'labelType',
			text: 'Type',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'buildingName',
			dataField: 'buildingName',
			text: 'Site Visibility',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (value == null || row?.buildingId == 0) ? "Visible to all" : value
		},
		{
			key: 'createDate',
			dataField: 'createDate',
			text: 'Created',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, row) => (value?.date ? formatDate(value.date) : 'N/A'),
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
			<ActionsList 
				document={row}
				history={history}
			/>)
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'title';
			switch (sortField) {
				case 'labelType':
					sortColumn = 'type';
				break;
				case 'buildingName':
					sortColumn = 'building';
				break;
				case 'createDate':
					sortColumn = 'createDate';
				break;
				default:
					sortColumn = sortField;
				break;
			}
			setFilterationData({...filterationData, 'sortKey': sortColumn, 'sortDir' : sortOrder});
		}
	};

    const setRowsPerPage = value => {
        setFilterationData(oldData => ({...oldData, 'perPage': value }));
    }

    const setCurrentPage = value => {
        setFilterationData(oldData => ({...oldData, 'page': value }));
    }
	
	return (
		<BootstrapedTable
			keyField="uniqid"
			data={list}
			columns={columns}
			totalRows={totalRows}
			onTableChange={onTableChange}
			currentPage={filterationData.page}
			rowsPerPage={filterationData.perPage}
			setCurrentPage={setCurrentPage}
			setRowsPerPage={setRowsPerPage}
			onSelectRows={setSelectedRows}
			selectedRows={selectedRows}
			hasCheckbox={editPermission}
		/>
	);
}