import React from 'react';
import { CreateAlarmTypeHeader, CreateAlarmTypeBody } from './components';
import { CreateAlarmTypeModals } from './modals';
import { CreateAlarmProvider } from './provider';
import './css/CreateAlarmType.scss';

export const CreateAlarmType = ({ history }) => {
	return (
		<div className="create-alarm-type-container">
			<CreateAlarmProvider history={history} hasPermissionOnDistrict={true}>
				<CreateAlarmTypeHeader />
				<CreateAlarmTypeBody />
				<CreateAlarmTypeModals />
			</CreateAlarmProvider>
		</div>
	);
};
