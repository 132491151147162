/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { dragSelector, remove, plus } from 'assets/icons';
import { Dropdown } from 'components/Dropdown';
import { DragAndDrop } from 'components/DragAndDrop';
import { Callout } from 'components/Callout';
import { LoadingSpinner } from 'components/LoadingSpinner';
import {
	DrillDistrictRequirementsService,
	DrillStateRequirementsService,
} from 'services';
import { CardModal } from './CardModal';
import { DrillLogService } from 'services';

const Wrapper = styled.div`
	width: ${props => props.width || 'initial'};
	height: ${props => props.height || 'initial'};

	.empty-fields {
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dropdown {
		margin: 16px 0;
		min-width: 315px;
	}

	.required-field {
		width: 340px;
		display: flex;
		color: #10161a;
		padding: 5px 0;
		font-size: 16px;
		min-height: 45px;
		line-height: 22px;
		font-style: normal;
		font-weight: normal;
		align-items: center;
		flex-direction: row;
		font-family: Nunito Sans;
		background-color: #ffffff;
		border-bottom: 1px solid #f5f8fa;

		.drag-selector {
			margin-left: 7.5px;
			margin-right: 24px;
		}

		.field-text {
			margin-right: 10px;
		}

		.dropdown {
			margin-right: 10px !important;
		}

		.delete-requirement {
			margin-left: auto;
			z-index: 200;
			cursor: pointer;
		}
	}

	.add-another-field {
		width: 170px;
		height: 32px;
		margin: 8px 0;
		cursor: pointer;
		justify-content: space-around;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #9f1c3a;
		font-size: 16px;
		line-height: 22px;
		text-align: center;
		font-style: normal;
		font-weight: normal;
		font-family: Nunito Sans;
	}
`;

export const EditRequiredFieldsModal = ({
	setModalToShow,
	onActionClick,
	label = '',
	selectedRequirement,
}) => {
	const [loading, setLoading] = useState(true);
	const [addNewField, setAddNewField] = useState(false);
	const [fields, setFields] = useState([]);
	const [fieldOptions, setFieldOptions] = useState([]);

	const cacheItems = newlyOrderedItems => {
		setFields(newlyOrderedItems);
	};

	const handleActionBtn = () => {
		if (fields.length) {
			onActionClick(fields);
		} else {
			setModalToShow(null);
		}
	};

	const filterOutMatchingElements = (mainArray, elementsToFilterOut) => {
		const uuidsToRemove = elementsToFilterOut.map(obj => obj.uuid)
		return mainArray.filter(item => !uuidsToRemove.includes(item.uuid));
	}
	  
	// remove field from options if added
	useEffect(() => {
		const opts = filterOutMatchingElements(fieldOptions, fields)
		setFieldOptions(opts)
	}, [fields])

	useEffect(async () => {
		const funcToCall = selectedRequirement?.districtRequirement?.id
			? DrillDistrictRequirementsService.fetchRequiredFields
			: DrillStateRequirementsService.fetchRequiredFields;
		
		const drillLogFields = await DrillLogService.fetchDrillLogFields()

		funcToCall(
			selectedRequirement?.districtRequirement?.id ||
				selectedRequirement?.id,
		)
			.then(backendFields => {
				const opts = filterOutMatchingElements(drillLogFields, backendFields)
				setFieldOptions(opts)
				setFields(backendFields);
				setLoading(false);
			})
			.catch(() => {
				setModalToShow(null);
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text:
						'Could not get requirements at this time. Please try again.',
				});
			});
	}, []);

	if (loading) {
		return (
			<CardModal
				isVisible
				setIsVisible={setModalToShow}
				title="Change required fields"
			>
				<Wrapper width="340px">
					<LoadingSpinner />
				</Wrapper>
			</CardModal>
		);
	}

	return (
		<CardModal
			isVisible
			setIsVisible={setModalToShow}
			title="Change required fields"
			actionTitle={fields?.length ? 'Save' : 'Exit'}
			onActionClick={handleActionBtn}
		>
			<Wrapper
				width="380px"
				height="380px"
				style={{ overflow: 'scroll', overflowX: 'hidden' }}
			>
				{/* drill type */}
				<h2>{label}</h2>

				{/* instructions and information */}
				<Callout
					className="d-flex mb-2"
					wrapperStyles={{ display: 'flex', alignItems: 'center' }}
				>
					<div>
						Below are the fields available when recording a drill.
						Anything with a grayed out checkmark is required by the
						state and cannot be removed. You can checkmark
						additional fields you want to be required for your
						district.
					</div>
				</Callout>

				{/* empty message */}
				{!fields.length && (
					<div className="empty-fields">
						There are no fields to edit
					</div>
				)}

				{/* re-order the fields */}
				{!!fields.length && (
					<DragAndDrop
						cacheItems={cacheItems}
						items={fields}
						component={(item, removeItem) => (
							<div className="required-field">
								<img
									alt=""
									className="drag-selector"
									src={dragSelector}
								/>
								<div className="field-text">{item.name}</div>
								<img
									alt=""
									className="delete-requirement"
									onClick={() => removeItem(item.id)}
									src={remove}
								/>
							</div>
						)}
					/>
				)}

				{/* dropdown to select a field */}
				{addNewField && (
					<div className="required-field">
						<Dropdown
							value={null}
							optionLabel="name"
							optionValue="id"
							options={fieldOptions}
							onChange={option => {
								setAddNewField(false);
								setFields(
									fields.concat({
										...option,
										uuid: option.uuid,
										id: option.id,
									}),
								);
							}}
						/>
						<img
							alt=""
							className="delete-requirement"
							onClick={() => setAddNewField(false)}
							src={remove}
						/>
					</div>
				)}

				{/* button to add another field */}
				{!!fields.length && (
					<div
						className="add-another-field"
						onClick={() => setAddNewField(true)}
					>
						<img alt="" className="" src={plus} />
						<div>Add another field</div>
					</div>
				)}
			</Wrapper>
		</CardModal>
	);
};

EditRequiredFieldsModal.propTypes = {
	setModalToShow: PropTypes.func,
	onActionClick: PropTypes.func,
	label: PropTypes.string,
	selectedRequirement: PropTypes.object.isRequired,
};