import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner, Animated, myClasses, noResult, EmptyState } from 'componentsV2';
import { RapidAlarmService } from 'services';
import { VisitorsTable } from '../..';
import { VISITORS_LIST } from '../consts';
import { RollCallDetailsContext } from '../../..';

export const Visitors = ({ filterData, setFilterData, setDisableFilter }) => {
	const { visitorUpdateEvent } = useContext(RollCallDetailsContext);
	const [loading, setLoading] = useState(true);
	const [visitorsList, setVisitorsList] = useState([]);
	const [firstRender, setFirstRender] = useState(true);
	const [emptyStateType, setEmptyStateType] = useState({
		noVisitorsAdded: false,
		noResultFound: false,
	});
	const [totalVisitors, setTotalVisitors] = useState(0);

	useEffect(() => {
		// Visitor status updated
		if (visitorUpdateEvent && visitorUpdateEvent.name === 'alarmVisitor:updated') {
			getVisitorsList(false);
		}
	}, [visitorUpdateEvent]);

	const getVisitorsList = (showLoading = true) => {
		if (showLoading) {
			setLoading(true);
		}
		RapidAlarmService.getVisitors(filterData)
			.then(response => {
				if (response.statusCode === 200) {
					setVisitorsList(response.data);
					setTotalVisitors(response._metadata.totalRecords);
					let noRecords = response._metadata.totalRecords === 0;
					setEmptyStateType({
						noVisitorsAdded: firstRender && noRecords,
						noResultFound: !firstRender && noRecords,
					});
					setDisableFilter(firstRender && noRecords);
				}
			})
			.finally(() => {
				if (showLoading) {
					setLoading(false);
				}
				setFirstRender(false);
			});
	};

	useEffect(() => {
		getVisitorsList();
	}, [filterData]);

	const noResultFound = emptyStateType.noResultFound;
	const noVisitorsAdded = emptyStateType.noVisitorsAdded;

	return (
		<>
			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<Animated>
					{(noVisitorsAdded || noResultFound) && (
						<EmptyState
							header={
								noResultFound ? 'No results found' : "You don't have any visitors"
							}
							description={
								noResultFound ? (
									<>
										Your search didn't match any visitors.
										<br /> Please try again.
									</>
								) : null
							}
							icon={noResultFound ? noResult : myClasses}
							showCircles={noResultFound}
							style={{
								marginTop: 100,
							}}
						/>
					)}
					{!noVisitorsAdded && !noResultFound && (
						<VisitorsTable
							visitorsList={visitorsList}
							setVisitorsList={setVisitorsList}
							totalVisitors={totalVisitors}
							filterData={filterData}
							setFilterData={setFilterData}
							listType={VISITORS_LIST}
						/>
					)}
				</Animated>
			)}
		</>
	);
};
