import React, { useState, useContext } from "react";
import PropTypes from 'prop-types';
import { ActionsButton, Button } from "componentsV2";
import { BinderModalsContext } from "../../BinderModalsProvider";
import { DOCUMENT_TYPE } from "./types";

export const BinderContentActionsList = ({
    binderContent,
    history,
    binderId
}) => {


    const {    
        renameDocument,
        handleReplaceContent,
        showDeleteContentModal
    } = useContext(BinderModalsContext);
    
    return (
        <ActionsButton>
            { binderContent.type === DOCUMENT_TYPE && (
                <Button
                    large
                    text="Rename"
                    icon="style"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    key="Rename"
                    onClick={() => renameDocument(binderContent, binderId)}
                />
            )}
            {binderContent.type === DOCUMENT_TYPE && (
                <Button
                    large
                    text="Replace"
                    icon="reset"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    key="Replace"
                    onClick={() => handleReplaceContent(binderContent, binderId)}
                />)}
            <Button
                large
                text="Delete"
                icon="trash"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
                key="Delete"
                onClick={() => showDeleteContentModal(binderContent, binderId)}

            />
        </ActionsButton>
    );
}

BinderContentActionsList.propTypes = {
    binderContent: PropTypes.any,
    binderId: PropTypes.number
};