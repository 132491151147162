import React, { useContext, useState } from 'react';
import { RapidAlarmService } from 'services';
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { FormModal, Input } from 'componentsV2';
import './css/ReunificationModal.scss';

export const ActivateReunificationModal = ({
	activeAlarmId,
	handleCancel,
	isRollCallStarted,
	reunificationLocation,
	setReunificationLocation,
}) => {
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);
	const [location, setLocation] = useState(null);

	const activateReunification = () => {
		setLoading(true);

		RapidAlarmService.activateReunification(activeAlarmId, location)
			.then(resp => {
				if (resp.statusCode === 200) {
					setReunificationLocation(location);
					toaster(' You have successfully activated Reunification.', Intent.SUCCESS);
				}
			})
			.catch(err => {
				console.log(err);
				toaster(err?.error?.fields.id, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				handleCancel();
			});
	};

	return (
		<FormModal
			isOpen={true}
			loading={loading}
			size="medium"
			title={'Activate Reunification?'}
			showCloseButton={false}
			confirmBtn={{
				title: 'Activate Reunification',
				onClick: activateReunification,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: handleCancel,
			}}
			icon="warning-sign"
			iconColor="warning"
			customClassName="reunification-modal"
		>
			<p>
				{isRollCallStarted
					? 'Enable Reunification to track and manage the reunion of students with their guardians at a Reunification location. Those taking Roll Call can transfer students to the student reunification list.'
					: ' Enable Reunification to track and manage the reunion of students with their guardians at a Reunification location.'}
			</p>

			<Input
				value={reunificationLocation}
				onChange={e => setLocation(e.target.value)}
				name="reunification_location"
				label={'Reunification location'}
				placeholder={'Type in location for Reunification'}
				isRequired={false}
			/>
		</FormModal>
	);
};
