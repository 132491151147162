import React from 'react';
import { CustomTabs, PageHeader } from 'componentsV2';
import { Tab } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utilities/permissions';
import { flags } from 'utilities';
import { ListAssessments, ListTemplates, Settings } from './pages';

export const AssessV2 = ({ history, tabId }) => {
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('site_assess_edit', permissions);
	const {
		isOn,
		names: { SITE_IS_TEMPLATE_CORE, ENV_NOT_PROD },
	} = flags;
	const tabs = [
		{
			id: 'index',
			title: 'Assessments',
			panel: <ListAssessments history={history} />,
		},
		{
			id: 'templates',
			title: 'Templates',
			panel: <ListTemplates history={history} />,
		},
		{
			id: 'settings',
			title: 'Settings',
			panel: <Settings />,
		},
	];

	const routeTab = tabId => {
		if (tabId === 'index') history.push('/assessV2');
		else history.push(`/assessV2/${tabId}`);
	};

	const canViewTab = tab => {
		// No templates tab without edit permission.
		if (tab.id === 'templates' && !editPermission) {
			return false;
		}

		// No settings tab without Super Admin permission (or we're on prod right now).
		if (tab.id === 'settings' && !permissions.isSysAdmin) {
			return false;
		}
		return true;
	};

	return (
		<>
			<PageHeader title="Assess" />
			{isOn(SITE_IS_TEMPLATE_CORE) ? (
				<CustomTabs id="assessTabs" onChangeHandler={routeTab} selectedTabId="templates">
					<Tab
						id="templates"
						title="Templates"
						panel={<ListTemplates history={history} />}
					/>
				</CustomTabs>
			) : (
				<CustomTabs id="assessTabs" onChangeHandler={routeTab} selectedTabId={tabId}>
					{tabs.filter(canViewTab).map(tab => (
						<Tab id={tab.id} key={tab.id} title={tab.title} panel={tab.panel} />
					))}
				</CustomTabs>
			)}
		</>
	);
};
