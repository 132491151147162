import React, { useState, useEffect } from 'react';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { BootstrapedTable, Badge, Dropdown, TableCell, sortingStyle } from 'componentsV2';
import { SchoolYearsService, DrillTypesService, DrillsStatsService } from 'services';
import { getCurrentSchoolYear } from 'utilities/dates';

import './Statistics.scss';

export const Statistics = () => {
	const [loading, setLoading] = useState(true);
	const [buildings, setBuildings] = useState([]);
	const [totalRows, setTotalRows] = useState(0);

	const [monthOptions, setMonthOptions] = useState([]);
	const [drillTypeOptions, setDrillTypeOptions] = useState([]);
	const [yearOptions, setYearOptions] = useState([]);

	const statusOptions = [
		{ value: 'all', label: 'Any Status' },
		{ value: 'completed', label: 'Completed' },
		{ value: 'scheduled', label: 'Scheduled' },
		{ value: 'inProgress', label: 'In Progress' },
		{ value: 'overdue', label: 'Overdue' },
		{ value: 'notScheduled', label: 'Not scheduled' },
	];

	const [filterationData, setFilterationData] = useState({
		year: getCurrentSchoolYear()[0],
		drillType: 'All drill types',
		month: 'All months',
		status: 'all',
	});

	// Set filteration data to the object.
	const setFilter = (key, value) => setFilterationData({ ...filterationData, [key]: value });

	// Get filter value object based on key.
	const getFilterValue = (key, options) =>
		options.find(({ value }) => value === filterationData[key]);

	// Filter rows based on status > 0.
	const filterForStatus = (rows, status) =>
		status === 'all' ? rows : rows.filter(row => row[status] > 0);

	// Load table data initial and keyed to filtration param change.
	useEffect(() => {
		setLoading(true);
		const { year, drillType, month, status } = filterationData;
		DrillsStatsService.fetchBuildingStats(year, drillType, month)
			.then(({ buildingStats }) => {
				const rows = filterForStatus(buildingStats, status);
				setBuildings(rows);
				setTotalRows(rows.length);
			})
			.finally(() => setLoading(false));
	}, [filterationData]);

	// Initially get data for dropdowns.
	useEffect(() => {
		SchoolYearsService.fetchYears().then(setYearOptions);
		SchoolYearsService.getMonths(true, true).then(setMonthOptions);
		DrillTypesService.getAll(true).then(rawOptions => {
			const options = rawOptions.map(({ value, label, uuid }) => ({
				value: uuid || value,
				label,
			}));
			setDrillTypeOptions(options);
		});
	}, []);

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'name',
			text: 'Site',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column),
			formatter: (value, { notScheduled, scheduled, overdue, inProgress, completed }) => {
				const total = notScheduled + scheduled + overdue + inProgress + completed;
				const percent = total > 0 ? Math.round((completed / total) * 100) : 0;
				return <TableCell text={value} supportText={`${percent}% completed`} firstRow />;
			},
		},
		{
			key: 'status',
			text: 'Status',
			formatter: (value, { inProgress, completed, overdue, scheduled, notScheduled }) => (
				<>
					{!scheduled && !notScheduled && !completed && !inProgress && !overdue && (
						<Badge text="No Drills" type="Gray" size="md" />
					)}
					{scheduled > 0 && (
						<Badge text={`Scheduled (${scheduled})`} type="Primary" size="md" />
					)}
					{notScheduled > 0 && (
						<Badge text={`Not Scheduled (${notScheduled})`} type="Indigo" size="md" />
					)}
					{completed > 0 && (
						<Badge text={`Completed (${completed})`} type="Success" size="md" />
					)}
					{inProgress > 0 && (
						<Badge text={`In Progress (${inProgress})`} type="Warning" size="md" />
					)}
					{overdue > 0 && <Badge text={`Overdue (${overdue})`} type="Error" size="md" />}
				</>
			),
		},
	];

	return (
		<div className="drill-statistics">
			<div className="filter-bar-wrapper">
				<Dropdown
					label="Year"
					value={getFilterValue('year', yearOptions)}
					onChange={({ value }) => setFilter('year', value)}
					options={yearOptions}
					width="100%"
					isRequired
				/>
				<Dropdown
					label="Drill Type"
					value={getFilterValue('drillType', drillTypeOptions)}
					onChange={({ value }) => setFilter('drillType', value)}
					options={drillTypeOptions}
					width="100%"
					isRequired
				/>
				<Dropdown
					label="Month"
					value={getFilterValue('month', monthOptions)}
					onChange={({ value }) => setFilter('month', value)}
					options={monthOptions}
					width="100%"
					isRequired
				/>
				{/* Disabling this until we can rethink on how to handle it.
				<Dropdown
					label="Status"
					value={getFilterValue('status', statusOptions)}
					onChange={({ value }) => setFilter('status', value)}
					options={statusOptions}
					width="100%"
					isRequired
				/> */}
			</div>
			{loading && <LoadingSpinner />}
			{!loading && (
				<BootstrapedTable
					keyField="id"
					data={buildings}
					columns={columns}
					currentPage={filterationData.page}
					rowsPerPage={filterationData.perPage}
					totalRows={totalRows}
					remote={false}
					sizePerPageList={[5, 10, 25, 50]}
				/>
			)}
		</div>
	);
};
