import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { PageHeader } from 'components/PageHeader';
import {
	Button,
	Dropdown,
	BootstrapedTable,
	EmptyState,
	ToasterContext,
	Intent,
	sortingStyle,
	FeaturedIcon,
	LoadingSpinner,
	ConfirmationModal,
} from 'componentsV2';
import { emptyFolder } from 'componentsV2/EmptyState/icons';
import './css/ManageReminders.scss';
import { DrillEmailRemindersService } from 'services/DrillEmailRemindersService';
import { BuildingsService } from '../../../../services/BuildingsService';
import { AddUsersModal } from './components/AddUsersModal';

export const ManageReminders = () => {
	const toaster = useContext(ToasterContext);
	const breadcrumbItems = [
		{ label: 'Drills', url: '/drillsV2' },
		{ label: 'Dashboard', url: '/drillsV2' },
		{ label: 'Reminders' },
	];
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [buildings, setBuildings] = useState([]);
	const [userID, setUserID] = useState(null);
	const [user, setUser] = useState([]);
	const [modalToShow, setModalToShow] = useState(null);
	const [showAddModal, setShowAddModal] = useState(false);
	const [currentBuilding, setCurrentBuilding] = useState({});

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'name',
			dataField: 'user.person.fullName',
			text: 'Name',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) => sortingStyle(order, column),
		},
		{
			key: 'title',
			dataField: 'user.person.title',
			text: 'Title',
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: id => (
				<FeaturedIcon
					icon="trash"
					type="Default"
					size="md"
					shape="circleIcon"
					onClick={() => {
						showDeleteUserModal(id);
					}}
				/>
			),
		},
	];

	useEffect(() => {
		getInitialData();
	}, []);

	const getInitialData = async () => {
		// Get buildings, pick the first and load data for that.
		const buildingList = await BuildingsService.getAll();
		setBuildings(buildingList);
		handleBuildingChange(buildingList[0]);
		setLoading(false);
	};

	const handleBuildingChange = async selectedItem => {
		setCurrentBuilding(selectedItem);
		setLoading(true);
		setData(await DrillEmailRemindersService.fetch(selectedItem.value));
		setLoading(false);
	};

	const addUsers = async userIds => {
		setModalToShow(null);

		DrillEmailRemindersService.add(currentBuilding.value, userIds)
			.then(addedReminders => {
				toaster(
					'You have successfully added new user(s) to receive reminders.',
					Intent.SUCCESS,
				);
				setData([...data, ...addedReminders]);
			})
			.catch(() => toaster('There was an error adding user(s).', Intent.DANGER));
	};

	const deleteUser = async () => {
		setModalToShow(null);

		DrillEmailRemindersService.remove(userID)
			.then(() => {
				toaster(`You have successfully removed "${user.person.fullName}"`, Intent.SUCCESS);
				const newData = data.filter(filterUser => filterUser.id !== userID);
				setData(newData);
			})
			.catch(() => {
				toaster('There was an error removing user.', Intent.DANGER);
			});
	};

	const showAddUsersModal = () => {
		setShowAddModal(true);
		setModalToShow('addUsers');
	};

	const showDeleteUserModal = id => {
		const deleteUser = data.find(user => {
			return user.id === id;
		});
		setUserID(id);
		setUser(deleteUser.user);
		setModalToShow('deleteUserModal');
	};

	const onCloseModal = () => {
		setUserID(null);
		setUser([]);
		setShowAddModal(false);
		setModalToShow('');
	};

	return (
		<>
			<div>
				<div className="reminders-page-container">
					<div className="header-container">
						<PageHeader title="Manage reminders" breadcrumbItems={breadcrumbItems} />
					</div>
					<div className="sections-container">
						<div className="table-section-container">
							{data.length > 0 && (
								<>
									<section className="table-section-header-container">
										<div className="right-side">
											<>
												<span className="text">
													Users receiving reminders
												</span>
												<Button
													size="md"
													text="New user"
													icon="plus"
													type="primaryDefault"
													intent="default"
													onClick={showAddUsersModal}
												/>
											</>
										</div>
									</section>
									<div className="left-side">
										{loading ? (
											<LoadingSpinner className="mt-3" />
										) : (
											<BootstrapedTable
												keyField="id"
												data={data}
												columns={columns}
												remote={false}
												showPagination={false}
											/>
										)}
									</div>
								</>
							)}
							{data.length === 0 && (
								<>
									<EmptyState
										header="No users have been added"
										icon={emptyFolder}
									>
										<Button
											text="New user"
											size="lg"
											icon="plus"
											type="primaryDefault"
											intent="default"
											onClick={showAddUsersModal}
										/>
									</EmptyState>
								</>
							)}
						</div>
						<div className="building-container">
							<div className="building-info">
								<Dropdown
									label="Site"
									onChange={handleBuildingChange}
									options={buildings}
									isRequired
									value={currentBuilding}
								/>
							</div>
							<div className="info">
								<FeaturedIcon
									icon="info-sign"
									type="Gray"
									size="md"
									shape="circleIcon"
								/>
								<div>
									Email reminders are sent three days before and one day after.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modalToShow === 'addUsers' && (
				<AddUsersModal
					isOpen={showAddModal}
					setModalToShow={modalToShow}
					onCloseModal={onCloseModal}
					onUsersAdd={addUsers}
					subtitle={`You will only see users that are not already receiving reminders for ${
						currentBuilding.label
					}`}
					title="Add new user"
				/>
			)}

			{modalToShow === 'deleteUserModal' && (
				<ConfirmationModal
					isOpen={true}
					title={`Are you sure you want to delete "${user.person.fullName}"?`}
					subtitle={'Once deleted, this cannot be recovered.'}
					showCloseButton={false}
					icon={{ icon: 'trash', iconColor: 'error' }}
					onClose={onCloseModal}
					cancelBtn={{ title: 'No, go back', onClick: onCloseModal }}
					confirmBtn={{
						title: 'Yes, delete it',
						onClick: deleteUser,
						type: 'primaryDanger',
					}}
				/>
			)}
		</>
	);
};
