import React from 'react';
import { Icon } from '@blueprintjs/core';
import './css/Breadcrumb.scss';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

export const Breadcrumb = ({ items }) => {
	return (
		<nav aria-label="Breadcrumb" className="breadcrumb-container">
			<ol className="ds-breadcrumb">
				{items.map((item, index) => (
					<>
						{index > 0 && <Icon icon="slash" className="icon-slash" />}
						<li
							key={index}
							className={`ds-breadcrumb-item ${
								index === items.length - 1 ? 'active' : ''
							}`}
						>
							{index === items.length - 1 ? (
								<span className="ds-current-page">{item.label}</span>
							) : item.icon ? (
								<NavLink
									className="breadcrumb-href"
									exact
									to={item.url}
									onClick={item.onClick ? item.onClick : null}
								>
									<Icon
										icon={item.icon}
										className={`icon-icon ${
											items.length === 0 ? 'active-icon' : ''
										}`}
									/>
								</NavLink>
							) : (
								<NavLink
									className="breadcrumb-href"
									exact
									to={item.url}
									onClick={item.onClick ? item.onClick : null}
								>
									{item.label}
								</NavLink>
							)}
						</li>
					</>
				))}
			</ol>
		</nav>
	);
};

Breadcrumb.propTypes = {
	items: PropTypes.array.isRequired,
};
