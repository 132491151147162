import React from 'react';
import './NavCard.scss';
import { Icon } from '@blueprintjs/core';

export const NavCard = ({
	name,
	subtext,
	rightElement,
	selected,
	completed,
	onClick,
	showIcon = true,
	disabled = false,
}) => {
	return (
		<div
			className={`nav-card ${disabled ? ' nav-card-disabled' : ''}  ${
				selected ? ' nav-card-selected' : ''
			} ${(completed && !selected) || disabled ? 'nav-card-completed' : ''}`}
			onClick={() => (!disabled ? onClick(name) : {})}
		>
			<div className="grid-container">
				<div className="grid-item-1">
					{showIcon && (
						<>
							{selected && (
								<Icon
									className="circle-icon-selected"
									icon="full-circle"
									size={12}
								/>
							)}
							{!selected && (
								<Icon
									className={completed ? 'completed-icon' : 'circle-icon'}
									icon={completed ? 'tick' : 'circle'}
									size={completed ? 16 : 12}
								/>
							)}
						</>
					)}
				</div>
				<div className="grid-item-2">
					<div className="space-between">
						<span className="name">{name}</span>
						{rightElement}
					</div>
				</div>
				<div className="grid-item-3">
					{subtext && <span className="subtext">{subtext}</span>}
				</div>
			</div>
		</div>
	);
};
