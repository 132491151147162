import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, Checkbox, FormGroup } from '@blueprintjs/core';

/**
 * OptionGroup: When you don't want a dropdown, but need to pick from a list!
 *
 * Accepts:
 *   options:
 *    Array of objects with the following keys:
 *      label, value, disabled: All props expanded into element.
 *    Array of flat strings to pick from (value will be same as label)
 *      Ex: ['Option 1', 'Option 2', 'Option 3']
 */
export const OptionGroup = ({
	options = [],
	optionsObject = {},
	multiple = false,
	caseInsensitive = false,
	groupLabel = '',
	onChange = () => {},
	onSelect = () => {},
	initialSelection = [],
	disabled = false,
}) => {
	const [selectedValue, setSelectedValue] = useState(initialSelection);
	let optionList = [...options];

	// Convert flat array of labels to key/value object array.
	if (optionList?.length && typeof optionList[0] === 'string') {
		optionList = optionList.map(label => ({ value: label, label }));
	}

	// If simple options object passed with no option array, use that.
	if (!options?.length && Object.keys(optionsObject).length) {
		optionList = Object.entries(optionsObject).map(([value, label]) => ({ label, value }));
	}

	// Force all value keys to be lowercase.
	optionList = optionList.map(option => ({ ...option, value: option.value.toLowerCase() }));

	// Radiogroup change handler.
	const onGroupChange = e => {
		const newSelected = [e.target.value];
		setSelectedValue(newSelected);
		onSelect(newSelected);
		onChange(e);
	};

	// Individual checkbox change handler.
	const onCheckboxChange = e => {
		const set = new Set(selectedValue);

		if (!e.target.checked) {
			set.delete(e.target.value);
		} else {
			set.add(e.target.value);
		}
		const newSelected = Array.from(set);
		setSelectedValue(newSelected);
		onSelect(newSelected);
		onChange(e);
	};

	// Optionally case insensitive selection check.
	const checkSelected = value => {
		if (!initialSelection.length) return false;

		if (caseInsensitive) {
			// The string produced here may contain characters such as (), {}, etc.
			// These screw up the RegExp, so escape them: https://stackoverflow.com/a/9310752
			const escaped = initialSelection.join('|').replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
			const insensitiveCheck = new RegExp(escaped, 'i');
			return insensitiveCheck.test(value);
		}
		return initialSelection.includes(value);
	};

	return (
		<FormGroup label={groupLabel}>
			{multiple ? (
				optionList.map((props, index) => (
					<Checkbox
						{...{
							...props,
							key: index,
							onChange: onCheckboxChange,
							defaultChecked: checkSelected(props?.value),
							disabled,
						}}
					/>
				))
			) : (
				<RadioGroup onChange={onGroupChange} selectedValue={selectedValue[0]}>
					{optionList.map((props, index) => (
						<Radio {...{ ...props, key: index, disabled }} />
					))}
				</RadioGroup>
			)}
		</FormGroup>
	);
};

OptionGroup.propTypes = {
	options: PropTypes.array,
	optionsObject: PropTypes.object,
	multiple: PropTypes.bool,
	caseInsensitive: PropTypes.bool,
	groupLabel: PropTypes.string,
	onChange: PropTypes.func,
	initialSelection: PropTypes.array,
	onSelect: PropTypes.func,
	disabled: PropTypes.bool,
};
