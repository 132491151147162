import React from 'react';
import {
	Modal,
	TabPreview,
	Button,
	SearchBar,
	DropdownMultiSelect,
	CheckboxGroup,
	Checkbox,
	LoadingSpinner,
	ModalFooter,
	TextArea,
	Input,
} from 'componentsV2';

import { CONTENT_TYPE } from './consts';

import { Icon } from '@blueprintjs/core';
import '../css/MapContentsList.scss';
import { EmptyStatuses } from './EmptyStatuses';

export const MapContentsList = ({
	loading,
	closeModal,
	modalData,
	openNextModal,
	stepModal,
	types,
	fileIconLabel,
	type,
	options,
	searchValue,
	searchReselt,
	contentList,
	docIdSelected,
	selectDocId,
	handleSearch,
	handleScroll,
	disabled,
	changeStep,
	debounceLoading,
	onChangeTypesHandler,
	typesValues,
	onNoteChangeHandler,
	saveBtnHandler,
	onWebAddressChangeHandler,
	showEmptyStatus,
	noDataAdded,
	filterationData,
	validationErrors,
	showUploadContentModal,
}) => {
	const getFooter = () => {
		const thirdButtonElement = stepModal == 2 && type != CONTENT_TYPE.ADD_WEB_LINK && (
			<Button
				icon="cloud-upload"
				text="Upload new file"
				minimal={true}
				fill={false}
				type="ghostDefault"
				onClick={() => showUploadContentModal(type, docIdSelected)}
			/>
		);
		const confirmButtonElement = stepModal == 2 && (
			<Button text={'Save'} intent="default" disabled={disabled} onClick={saveBtnHandler} />
		);
		const cancelButtonElement = (
			<Button text="Cancel" type="tertiary" intent="default" onClick={closeModal} />
		);

		if (type == CONTENT_TYPE.NOTE) {
			return (
				<ModalFooter
					confirmButton={confirmButtonElement}
					cancelButton={cancelButtonElement}
				/>
			);
		} else {
			return (
				<ModalFooter
					confirmButton={confirmButtonElement}
					cancelButton={cancelButtonElement}
					leftContent={thirdButtonElement}
				/>
			);
		}
	};

	const getLabelText = (type, docIdSelectedLength) => {
		const typeLabels = {
			[CONTENT_TYPE.NOTE]: 'Add a note',
			[CONTENT_TYPE.ADD_WEB_LINK]: 'Add web link',
			[CONTENT_TYPE.DOCUMENTS]: 'Select documents / binders',
		};

		const defaultLabel = `Select ${type}`;
		const countLabel = docIdSelectedLength > 0 ? ` (${docIdSelectedLength})` : '';

		return typeLabels[type] ? `${typeLabels[type]}${countLabel}`:`${defaultLabel}${countLabel}`;
	};

	return (
		<Modal
			isOpen={true}
			onClose={() => closeModal()}
			title={modalData.modalTitle}
			size="small"
			loading={loading}
			customFooter={getFooter()}
			showCloseButton={false}
			onScrollHandller={handleScroll}
			customClassName={'edit-legend-container map-container-modals'}
		>
			<div className="mapContent_wrapper">
				{stepModal == 1 && (
					<div className="tabListWrapper">
						{types.map((item, index) => (
							<TabPreview
								key={index}
								id={item.id}
								title={item.title}
								icon={item?.icon}
								isOpen={false}
								setIsOpen={() => openNextModal(item.title)}
							/>
						))}
					</div>
				)}
				{stepModal == 2 && (
					<div
						className={
							type !== CONTENT_TYPE.NOTE && type !== CONTENT_TYPE.ADD_WEB_LINK
								? 'contentListWrapper'
								: ''
						}
					>
						<div className="headlineWrapper">
							<a
								onClick={() => {
									changeStep(1);
								}}
							>
								<Icon className="normal-modal-back-arrow" icon="arrow-left" />
							</a>
							<span>
								{getLabelText(type, docIdSelected.length)}
							</span>
						</div>
						{type == CONTENT_TYPE.NOTE && (
							<TextArea
								label="Note"
								placeholder={'Enter you note...'}
								isRequired={true}
								onChange={onNoteChangeHandler}
							/>
						)}
						{type == CONTENT_TYPE.ADD_WEB_LINK && (
							<Input
								label="Web address"
								placeholder="Web address"
								isRequired={true}
								onChange={onWebAddressChangeHandler}
								error={!!validationErrors?.web_address}
								errorMessage={validationErrors?.web_address}
							/>
						)}
						{type != CONTENT_TYPE.NOTE && type != CONTENT_TYPE.ADD_WEB_LINK && (
							<div className="contentlist__Container">
								<div className="filteration_wrapper">
									<div className="leftElement">
										<SearchBar
											placeholder="Search by name..."
											icon="search"
											className="search-bar-text"
											value={searchValue}
											onSearch={e => handleSearch(e)}
										/>
									</div>
									<div className="rightElement">
										<DropdownMultiSelect
											label={null}
											values={typesValues}
											options={options}
											allowSelectAll={false}
											placeholder="Filter by type"
											visibleOptions={1}
											className="dropdown_types"
											onChange={onChangeTypesHandler}
										/>
									</div>
								</div>
								{showEmptyStatus && (
									<div className="emptyStateWrapper">
										<EmptyStatuses
											filterationData={filterationData}
											noDataAdded={noDataAdded}
											type={type}
										/>
									</div>
								)}
								<CheckboxGroup size={'medium'} layout={'block'} required={false}>
									{searchValue
										? searchReselt.length > 0 &&
										  searchReselt.map(item => (
												<Checkbox
													label={fileIconLabel(item)}
													onChange={() => selectDocId(item)}
													checked={docIdSelected.find(
														selected => selected.id == item.id,
													) ? true : false}
													key={`checkbox-${item.id}`}
													size={'small'}
													bordered={true}
													value={item.id}
												/>
										  ))
										: contentList.map(item => (
												<Checkbox
													label={fileIconLabel(item)}
													onChange={() => selectDocId(item)}
													checked={docIdSelected.find(
														selected => selected.id == item.id,
													)}
													key={`checkbox-${item.id}`}
													size={'small'} // small | medium
													bordered={true}
													value={item.id}
												/>
										  ))}
									{debounceLoading && <LoadingSpinner />}
								</CheckboxGroup>
							</div>
						)}
					</div>
				)}
			</div>
		</Modal>
	);
};
MapContentsList.propTypes = {};
