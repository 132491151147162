import { FloorplanService } from 'services/FloorplanService';
import L from 'leaflet';
import { Intent } from '@blueprintjs/core';
import { GEOMETRY_TYPE_TEXT } from '../consts';
import { layer, floorplanDetails, editPermission, addShapeActions } from '../CanvasDrawFn';
import { getMousePosition } from '../components/Functions';

/** * method to rename text ** */
export const renameText = (setHasChange, showModal, shape, addToBulkSave) => {
	shape.setAttr('draggable', false);

	const transformer = layer.getStage().findOne('Transformer');
	shape.hide();
	transformer.hide();

	const textPosition = shape.absolutePosition();

	const areaPosition = {
		x: textPosition.x,
		y: textPosition.y + 60,
	};

	const textarea = document.createElement('textarea');

	textarea.value = shape.text();
	textarea.style.position = 'absolute';
	textarea.style.top = `${areaPosition.y}px`;
	textarea.style.left = `${areaPosition.x}px`;
	textarea.style.width = `${shape.width() - shape.padding() * 2}px`;
	textarea.style.height = `${shape.height() - shape.padding() * 2 + 5}px`;
	textarea.style.fontSize = `${shape.fontSize()}px`;
	textarea.style.border = 'none';
	textarea.style.padding = '0px';
	textarea.style.margin = '0px';
	textarea.style.overflow = 'hidden';
	textarea.style.background = 'none';
	textarea.style.outline = 'none';
	textarea.style.resize = 'none';
	textarea.style.lineHeight = shape.lineHeight();
	textarea.style.fontFamily = shape.fontFamily();
	textarea.style.transformOrigin = 'left top';
	textarea.style.textAlign = shape.align();
	textarea.style.color = shape.fill();
	textarea.style.zIndex = '1000';

	document.body.appendChild(textarea);
	let transform = '';
	let px = 0;

	const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
	if (isFirefox) {
		px += 2 + Math.round(shape.fontSize() / 20);
	}
	transform += `translateY(-${px}px)`;

	textarea.style.transform = transform;
	textarea.style.height = 'auto';
	textarea.style.height = `${textarea.scrollHeight + 3}px`;
	textarea.focus();

	function removeTextarea() {
		textarea.value = '';
		textarea.parentNode.removeChild(textarea);
		window.removeEventListener('click', handleOutsideClick);
		shape.show();
		transformer.show();
		transformer.forceUpdate();
		shape.setAttr('draggable', true);
	}

	function setTextareaWidth(newWidth) {
		if (!newWidth) {
			newWidth = shape.placeholder.length * shape.fontSize();
		}
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
		if (isSafari || isFirefox) {
			newWidth = Math.ceil(newWidth);
		}

		const isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
		if (isEdge) {
			newWidth += 1;
		}
		textarea.style.width = `${newWidth}px`;
	}

	let enterOrEscapeFlage = false;
	let show_modal = false;
	textarea.addEventListener('keydown', function(e) {
		const newText = textarea.value.trim();

		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			enterOrEscapeFlage = true;
			if (newText === '') {
				showModal(shape, 'EmptyText');
			} else {
				shape.text(textarea.value);
				removeTextarea();
				setHasChange(true);
				addToBulkSave(shape.attrs.id, 'text', 'Rename');
			}
		}

		if (e.key === 'Escape') {
			enterOrEscapeFlage = true;
			if (newText === '') {
				showModal(shape, 'EmptyText');
			} else {
				removeTextarea();
				setHasChange(true);
				addToBulkSave(shape.attrs.id, 'text', 'Rename');
			}
		}
	});

	textarea.addEventListener('keydown', function(e) {
		const scale = shape.getAbsoluteScale().x;
		setTextareaWidth(shape.width() * scale);
		textarea.style.height = 'auto';
		textarea.style.height = `${textarea.scrollHeight + shape.fontSize()}px`;
	});

	function handleOutsideClick(e) {
		const newText = textarea.value.trim();
		if (e.target !== textarea && enterOrEscapeFlage != true) {
			if (newText === '' && !show_modal) {
				showModal(shape, 'EmptyText');
				show_modal = true;
			} else if (newText !== '') {
				shape.text(textarea.value);
				removeTextarea();
				setHasChange(true);
				addToBulkSave(shape.attrs.id, 'text', 'Rename');
			} else {
				textarea.focus();
			}
		}
	}
	setTimeout(() => {
		window.addEventListener('click', handleOutsideClick);
	});
};

/** * method to draw text ** */
export const drawText = (setHasChange, showModal, shape, addToBulkSave) => {
	const floorplanArchived = !!floorplanDetails?.archivedBy;
	const text = new Konva.Text({
		id: `${shape.id}`,
		x: shape.shape_position_x,
		y: shape.shape_position_y,
		rotation: shape.angle ? shape.angle : null,
		scaleX: shape.shape_scale_x ? shape.shape_scale_x : null,
		scaleY: shape.shape_scale_y ? shape.shape_scale_y : null,
		text: shape.title,
		fontSize: 16,
		fill: 'black',
		align: 'center',
		padding: 8,
		draggable: editPermission && !floorplanArchived,
		listening: editPermission && !floorplanArchived,
		scalable: editPermission && !floorplanArchived,
		visible: shape.visible,
	});

	if (editPermission) {
		addShapeActions(text, 'text', setHasChange, showModal, addToBulkSave);
	}

	layer.add(text);

	return text;
};

export const enableTextDrawing = (
	mapRef,
	stage,
	resetFreeDrawing,
	baseZoomLevel,
	setRefreshTextList,
	toaster,
	setHasChange,
	showModal,
	addToBulkSave,
	loadMapDetails,
) => {
	var textNode = null;
	var firstDraw = 0;
	var textarea = null;

	stage.on('mousedown touchstart', function(e) {
		if (firstDraw > 0) {
			stage.container().style.cursor = 'default';
			return;
		}

		const mouse = stage.getPointerPosition();
		const mapMouse = getMousePosition(stage, mapRef, baseZoomLevel, floorplanDetails);
		textNode = drawText(
			setHasChange,
			showModal,
			{
				shape_position_x: mapMouse.x,
				shape_position_y: mapMouse.y,
				title: '',
			},
			addToBulkSave,
		);

		// create textarea and style it
		textarea = document.createElement('textarea');
		document.body.appendChild(textarea);

		// apply some styles to match text on canvas as close as possible
		textarea.value = '';
		textarea.style.position = 'absolute';
		textarea.style.top = `${mouse.y + 60}px`;
		textarea.style.left = `${mouse.x}px`;
		textarea.style.minWidth = '150px';
		textarea.style.height = `${textNode.height() - textNode.padding() * 2 + 5}px`;
		textarea.style.fontSize = `${textNode.fontSize()}px`;
		textarea.style.border = '1px dashed #3f3f3f';
		textarea.style.padding = '2px';
		textarea.style.margin = '0px';
		textarea.style.overflow = 'hidden';
		textarea.style.background = 'none';
		textarea.style.outline = 'none';
		textarea.style.resize = 'none';
		textarea.style.lineHeight = textNode.lineHeight();
		textarea.style.fontFamily = textNode.fontFamily();
		textarea.style.transformOrigin = 'left top';
		textarea.style.textAlign = 'left';
		textarea.style.color = textNode.fill();
		textarea.style.zIndex = '1000';
		textarea.focus();

		textarea.addEventListener('keydown', function(e) {
			// hide on enter
			// but don't hide on shift + enter
			if (e.keyCode === 13 && !e.shiftKey) {
				// save new text
				saveNewText();
			}

			// on esc do not set value back to node
			if (e.keyCode === 27) {
				textarea.remove();
				textNode.hide();
				textNode = textarea = null;
				return;
			}
		});

		firstDraw++;
	});

	function saveNewText() {
		if (textarea) {
			textNode.text(textarea.value);
			textarea.remove();
			textarea = null;
		}

		textNode.show();

		resetFreeDrawing();

		var textAttrs = textNode.getAttrs();

		if (textAttrs.text == '') {
			return;
		}

		var shape = {
			type: GEOMETRY_TYPE_TEXT,
			title: textAttrs.text,
			position_x: textAttrs.x,
			position_y: textAttrs.y,
			is_shape_draggable: textAttrs.draggable,
			fontsize: textAttrs.fontSize,
			fill: textAttrs.fill,
			coord_lat_long: JSON.stringify(
				mapRef.layerPointToLatLng(L.point(textAttrs.x, textAttrs.y)),
			),
		};

		FloorplanService.addShape(floorplanDetails.id, shape)
			.then(response => {
				setRefreshTextList(true);
				toaster(response.data.message, Intent.SUCCESS, null, 2000, false, 'map-view-page');
				textNode.setAttr('id', response.data.id);
			})
			.finally(() => {
				setRefreshTextList(false);
				loadMapDetails();
			});
	}

	var firstClick = 0;

	function handleOutsideClick(e) {
		let newText = textarea ? textarea.value.trim() : '';
		var addBtnClicked = e.target.closest('.map-add-btn');

		if (textarea) {
			textarea.focus();
		}

		if (e.target !== textarea && firstClick > 0 && !addBtnClicked) {
			if (newText === '' || e.keyCode === 27) {
				if (textarea != null) {
					textarea.remove();
					textarea = null;
				}
			} else if (newText !== '') {
				saveNewText();
			}
		}

		if (e.target !== textarea && !addBtnClicked) {
			resetFreeDrawing();
		}

		firstClick++;
	}

	setTimeout(() => {
		window.addEventListener('click', handleOutsideClick);
	});
};
