/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch } from '@blueprintjs/core';
import Swal from 'sweetalert2';

import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { Input } from 'components/Input';
import { Callout } from 'components/Callout';
import { plus } from 'assets/icons';
import { AddDrillTypeModal } from 'components/Modals/AddDrillTypeModal';
import { EditRequiredFieldsModal } from 'components/Modals/EditRequiredFieldsModal';
import { PublishRequirementsModal } from 'components/Modals/PublishRequirementsModal';
import { SchoolYearDropdown } from 'components/SchoolYearDropdown';
import { StatesDropdown } from 'components/StatesDropdown';
import { DrillDistrictRequirementsService } from 'services/DrillDistrictRequirementsService';
import { SetRequirementsPerBuildingModal } from 'components/Modals/SetRequirementsPerBuildingModal';
import { MoreActionsButton } from '../../../components/MoreActionsButton';
import { getManageDistrictRequirementsActions } from '../../../utilities/drills/utils';
import { isIterable } from '../../../utilities';

const AddDrillType = styled.div`
	width: 150px;
	height: 40px;
	display: flex;
	color: #9f1c3a;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	font-style: normal;
	text-align: center;
	align-items: center;
	flex-direction: row;
	letter-spacing: 0px;
	font-family: Nunito Sans;
	justify-content: space-evenly;
	cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const Page = styled.div`
	padding: 8px 24px;

	.dropdown-filters {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.dropdown {
			margin-right: 24px;
			margin-bottom: 16px;
			width: calc(33% - 24px);
		}
	}

	.instructions-to-user {
		color: #5c7080;
		font-size: 18px;
		margin-top: 25%;
		font-weight: bold;
		line-height: 21px;
		text-align: center;
		font-style: normal;
		font-family: Merriweather;
	}

	.requirements-body {
		table {
			margin-top: 16px;

			.required-drills-count {
				display: flex;
				align-items: center;
				flex-direction: row;
				justify-content: center;

				.input-wrapper {
					margin-bottom: 0px;
				}

				input {
					width: 48px;
					margin-right: 4px;
				}
			}

			.more-actions-btn img {
				cursor: pointer;
				padding: 5px 10px;
			}

			.more-actions-modal {
				right: 13%;
				z-index: 100;
				padding-top: 10px;
				border-radius: 6px;
				position: absolute;
				background-color: white;
				box-shadow: 0px 4px 24px rgb(32 23 50 / 25%);

				p {
					color: #10161a;
					font-size: 14px;
					padding: 2px 10px;
					line-height: 20px;
					font-style: normal;
					font-weight: normal;
					font-family: Nunito Sans;

					&:hover {
						background-color: #f8f9fa;
					}

					&:last-child {
						color: red;
					}
				}

				.arrow {
					right: 0;
					width: 0;
					top: -5px;
					height: 0;
					position: absolute;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
					border-bottom: 8px solid white;
				}
			}
		}
	}

	.modal-card {
		.dropdown {
			margin: 16px 0;
		}
	}
`;

class ManageDistrictDrillRequirements extends React.Component {
	state = {
		isLoading: false,
		requirements: [],
		drillTypesToExclude: [],
		selectedSchoolYear: null,
		selectedState: null,
		newDrillType: null,
		modalToShow: null,
		selectedDrillType: null,
	};

	sortDrills = data =>
		data.sort((a, b) => {
			const x = a.name.toUpperCase();
			const y = b.name.toUpperCase();
			if (x < y) {
				return -1;
			}
			if (x > y) {
				return 1;
			}
			return 0;
		});

	fetchData = (year, stateId) => {
		DrillDistrictRequirementsService.getAllRequirements(year, stateId).then(
			reqs => {
				this.setState({
					requirements: this.sortDrills(reqs),
					drillTypesToExclude: reqs.map(req => req.uuid),
				});
			},
		);
	};

	componentDidMount() {
		const { selectedSchoolYear, selectedState } = this.state;
		if (selectedSchoolYear && selectedState) {
			this.fetchData(selectedSchoolYear.value, selectedState.id);
		}
	}

	componentDidUpdate(_, prevState) {
		const { selectedSchoolYear, selectedState } = this.state;
		// Just fetch data if all the fields required fields were filled
		// or any of the fields already filled change
		if (
			selectedState &&
			selectedSchoolYear &&
			(selectedState.value !== prevState.selectedState?.value ||
				selectedSchoolYear.value !==
					prevState.selectedSchoolYear?.value)
		) {
			this.fetchData(selectedSchoolYear.value, selectedState.id);
		}
	}

	setModalToShow = modal => {
		const { selectedSchoolYear, selectedState } = this.state;
		if (selectedSchoolYear && selectedState) {
			this.setState({
				modalToShow: modal,
			});
		}
	};

	handleActionModalClick = (task, selectedItem) => {
		const { selectedSchoolYear, selectedState } = this.state;
		if (task === 'delete') {
			DrillDistrictRequirementsService.deleteRequirement(
				selectedItem,
			).then(() => {
				this.fetchData(selectedSchoolYear.value, selectedState.id);
				Swal.fire({
					title: 'Success!',
					text: 'Drill Type Deleted',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
			});
		} else {
			this.setState({
				modalToShow: task,
				selectedDrillType: selectedItem,
			});
		}
	};

	// manages the dropdown
	selectYear = item => {
		// resets the 'year' and 'drill type' because different 'years' have different 'states'
		this.setState({
			selectedSchoolYear: item,
			selectedState: null,
		});
	};

	selectState = item => {
		// resets the 'drill type' because different 'states' have different 'drill types'
		this.setState({
			selectedState: item,
		});
	};

	// manage requirements table
	editRequirement = (type, uuid, newValue) => {
		this.setState(prevState => ({
			requirements: prevState.requirements.map(item => {
				if (item.uuid === uuid) {
					return {
						...item,
						[type]: newValue,
						// To avoid unnecesary network requests
						stateReqsUpdated: type === 'stateRequirements',
						districtReqsUpdated: type !== 'stateRequirements',
					};
				}

				return item;
			}),
		}));
	};

	publishRequirements = () => {
		const { requirements, selectedSchoolYear } = this.state;
		if (isIterable(requirements)) {
			// eslint-disable-next-line no-restricted-syntax
			for (const requirement of requirements) {
				if (requirement.stateReqsUpdated) {
					// TODO call state requirements with the ones that changed
				}
				if (requirement.districtReqsUpdated) {
					// if there is a requirement set then we are updating else we need to create the requirement
					if (requirement.districtRequirement) {
						DrillDistrictRequirementsService.updateRequirements(
							requirement.districtRequirement.id,
							requirement.districtRequirements,
							requirement.fieldUuids,
							requirement.active,
						).then(() => {
							Swal.fire({
								title: 'Success!',
								text: 'Requirements were updated',
								icon: 'success',
								toast: true,
								position: 'top',
								timer: 3000,
								showConfirmButton: false,
							});
						});
					} else {
						DrillDistrictRequirementsService.createRequirement(
							requirement.uuid,
							selectedSchoolYear.value,
							requirement.districtRequirements,
							requirement.fieldUuids,
						).then(() => {
							Swal.fire({
								title: 'Success!',
								text: 'Requirements were updated',
								icon: 'success',
								toast: true,
								position: 'top',
								timer: 3000,
								showConfirmButton: false,
							});
						});
					}
				}
			}
		}
		this.setModalToShow(null, null);
	};

	addDrillType = () => {
		const { selectedSchoolYear, selectedState, newDrillType } = this.state;
		// Call the service with the selected drill type UUID
		DrillDistrictRequirementsService.createRequirement(
			newDrillType.uuid,
			selectedSchoolYear.value,
		);
		// Refresh the requirements
		this.setState({
			modalToShow: null,
			newDrillType: null,
			// todo: this.state.newDrillType
		});
		setTimeout(() => {
			this.fetchData(selectedSchoolYear.value, selectedState.id);
		}, 500);
	};

	setNewDrillType = item => {
		this.setState({
			newDrillType: item,
		});
	};

	updateRequiredFields = updatedFields => {
		const { selectedDrillType } = this.state;
		DrillDistrictRequirementsService.updateRequirements(
			selectedDrillType.districtRequirement.id,
			null,
			updatedFields.map(field => field.uuid),
		).then(() => {
			Swal.fire({
				title: 'Success!',
				text: 'Required fields updated',
				icon: 'success',
				toast: true,
				position: 'top',
				timer: 3000,
				showConfirmButton: false,
			});
		});
		this.setState({
			modalToShow: null,
			selectedDrillType: null,
		});
	};

	setBuildingRequirements = (mainReqChanged, value, buildingRequirements) => {
		const {
			selectedSchoolYear,
			selectedState,
			selectedDrillType,
		} = this.state;

		const promisesArray = [
			DrillDistrictRequirementsService.updateBuildingRequirements(
				selectedDrillType.districtRequirement.id,
				buildingRequirements,
			),
		];
		if (mainReqChanged) {
			promisesArray.push(
				DrillDistrictRequirementsService.updateRequirements(
					selectedDrillType.districtRequirement.id,
					value,
				),
			);
		}
		Promise.all(promisesArray)
			.then(() => {
				Swal.fire({
					title: 'Success!',
					text: 'Building requirements were updated',
					icon: 'success',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
				this.fetchData(selectedSchoolYear.value, selectedState.id);
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text:
						'An error occurred setting requirements. Please try again.',
				});
			})
			.finally(() => {
				this.setState({
					modalToShow: null,
					selectedDrillType: null,
				});
			});
	};

	render() {
		const {
			isLoading,
			requirements,
			drillTypesToExclude,
			selectedSchoolYear,
			selectedState,
			newDrillType,
			modalToShow,
			selectedDrillType,
		} = this.state;
		const { history } = this.props;
		const canPublish = selectedSchoolYear && selectedState;

		if (isLoading) {
			return null;
		}

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Manage District Drill Requirements"
					onBackBtnClick={history.goBack}
					actionDisabled={!canPublish}
					actionTitle="Publish"
					onActionClick={() => this.setModalToShow('publish', null)}
					customItem={() => (
						<AddDrillType
							disabled={!canPublish}
							onClick={() => this.setModalToShow('addDrillType')}
						>
							<img src={plus} alt="" />
							Add Drill Type
						</AddDrillType>
					)}
				/>

				{/* school, state, and drill type selectors */}
				<div className="dropdown-filters">
					{/* School year */}
					<SchoolYearDropdown
						selectedSchoolYear={selectedSchoolYear}
						onChange={item => this.selectYear(item)}
					/>

					{selectedSchoolYear && (
						/* States */
						<StatesDropdown
							selectedState={selectedState}
							onChange={item => this.selectState(item)}
						/>
					)}
				</div>

				{selectedSchoolYear && selectedState ? (
					/* edit scheduling and posting instructions */
					<div className="requirements-body">
						<Callout type="info">
							Marking a drill as inactive will hide that drill for
							the schools in your district. If you need to edit
							state requirements values please
							<a href="/manageStateRequirements"> click here. </a>
						</Callout>
						<Table
							headers={[
								'Drill type',
								'Drills required for district',
								`Drills required by ${selectedState?.label}`,
								'State',
								'Actions',
							]}
							body={() =>
								requirements?.map(item => (
									<tr
										key={item.uuid}
										style={{
											opacity: item.active ? 1 : 0.5,
										}}
									>
										<td>{item.type}</td>
										<td>
											<div className="required-drills-count">
												<Input
													className
													value={
														item.districtRequirements
													}
													onChange={({
														target: { value },
													}) =>
														this.editRequirement(
															'districtRequirements',
															item.uuid,
															value,
														)
													}
												/>
												drills
											</div>
										</td>
										<td>
											<div className="required-drills-count">
												<Input
													disabled
													className
													value={
														item.stateRequirements
													}
													onChange={({
														target: { value },
													}) =>
														this.editRequirement(
															'stateRequirements',
															item.uuid,
															value,
														)
													}
												/>
												drills
											</div>
										</td>
										<td>
											<Switch
												checked={item.active}
												onChange={() =>
													this.editRequirement(
														'active',
														item.uuid,
														!item.active,
													)
												}
												label={
													item.active
														? 'Active'
														: 'Inactive'
												}
											/>
										</td>
										<td>
											<MoreActionsButton
												actions={getManageDistrictRequirementsActions(
													() =>
														this.handleActionModalClick(
															'editRequiredFields',
															item,
														),
													() =>
														history.push(
															'/manageDrillInstructions',
														),
													() =>
														history.push(
															'/manageDrillInstructions',
														),
													() =>
														this.handleActionModalClick(
															'delete',
															item
																.districtRequirement
																.id,
														),
													() =>
														this.handleActionModalClick(
															'setRequirementsPerBuilding',
															item,
														),
												)}
											/>
										</td>
									</tr>
								))
							}
						/>
					</div>
				) : (
					/* inform the user to pick from the drop down */
					<div className="instructions-to-user">
						{!selectedSchoolYear
							? 'Please select a School Year'
							: !selectedState &&
							  'Please select a State to continue'}
					</div>
				)}

				{modalToShow === 'publish' && (
					<PublishRequirementsModal
						setModalToShow={this.setModalToShow}
						onActionClick={this.publishRequirements}
						selectedState={selectedState}
						variant="district"
					/>
				)}

				{modalToShow === 'addDrillType' && (
					<AddDrillTypeModal
						setModalToShow={this.setModalToShow}
						onActionClick={this.addDrillType}
						newDrillType={newDrillType}
						onChange={this.setNewDrillType}
						excludeDrillTypes={drillTypesToExclude}
					/>
				)}

				{modalToShow === 'editRequiredFields' && (
					<EditRequiredFieldsModal
						key={`${Math.random()}`}
						setModalToShow={this.setModalToShow}
						onActionClick={this.updateRequiredFields}
						label={selectedDrillType?.name}
						selectedRequirement={selectedDrillType}
					/>
				)}

				{modalToShow === 'setRequirementsPerBuilding' && (
					<SetRequirementsPerBuildingModal
						key={`${Math.random()}`}
						setModalToShow={this.setModalToShow}
						onActionClick={this.setBuildingRequirements}
						label={selectedDrillType?.name}
						requiredFields={selectedDrillType.fieldUuids}
						selectedDrillType={selectedDrillType}
					/>
				)}
			</Page>
		);
	}
}

export default ManageDistrictDrillRequirements;

ManageDistrictDrillRequirements.propTypes = {
	history: PropTypes.object,
};
