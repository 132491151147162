import React,{useContext, useEffect, useState} from "react";
import {ToasterContext} from 'pages/App';
import { FormModal, Intent, FeaturedIcon } from "componentsV2";
import './css/CompletePlanModal.scss';
import {PlanService} from "services";

export const CompletePlanModal = ({
    isOpen,
    closeCompleteModal,
    template,
    loadData

}) => {

    const toaster = useContext(ToasterContext);
    const [loading,setLoading] = useState(false);
    const [incompleteSections,setIncompleteSections] = useState();

    const handleCompletePlan = () => {

		setLoading(true);
        let isCompleted =  (template.isCompleted) ? false : true
        PlanService.markSafetyplanAsCompleted(parseInt(template.id),isCompleted)
        .then(resp =>{
            if(resp.statusCode === 200){
                toaster('You have successfully marked "' +template.name+ '" as ' + ((template.isCompleted) ? 'incomplete' : 'complete'), Intent.SUCCESS,<FeaturedIcon icon={'tick'} type={'Success'} size={'md'} shape={'circleIcon'}/>);
                setLoading(false);
                closeCompleteModal();
                loadData();
            }
        })
	};


    const checkSections = () => {
		const temp = [];
		template.sections.forEach(item => {
			if (!item.complete) {
				temp.push(item.title);
			}
			if (item.subsections) {
				item.subsections.forEach(one => {
					if (!one.complete) {
						temp.push(one.title);
					}
				});
			}
		});
        setIncompleteSections(temp)
	};

    useEffect(()=>{
        checkSections();
    },[])

    return (
        <FormModal
            isOpen={isOpen}
            loading = {loading}
            onClose={closeCompleteModal}
            size='small'
            customClassName = {'complete-modal-container'}
            icon = {'warning-sign'}
            iconColor = {'warning'}
            title={'Are you sure you would like to mark "'+ template.name +'" as '+((template.isCompleted) ? 'incomplete' : 'complete') +'?'}
            subtitle = {(incompleteSections && incompleteSections.length > 0 && !template.isCompleted) ? 'The following sections and subsections are incomplete.' :''}
            showCloseButton={false}
            confirmBtn={{
                title: ("Yes, mark as "+ ((template.isCompleted) ? 'incomplete' : 'complete')),
                onClick: handleCompletePlan,
                disabled: false
            }}
            cancelBtn={{
            title:'No, go back',
            onClick: closeCompleteModal
            }}
        >
            {(incompleteSections && incompleteSections.length > 0 && !template.isCompleted) && (
                <>
                {incompleteSections.map(
                    (item, index) => (
                            <label>{item}</label>
                    ),
                )}
                </>
            )}
        </FormModal>
    );

}
