import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@blueprintjs/core';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { LoadingSpinner } from 'components/LoadingSpinner';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;
const DialogWrap = styled.div`
	padding-left: 10px;
	padding-right: 10px;
`;

export const DeleteRoleModal = ({ setModalToShow, onActionClick, Sites, loading }) => {
	return (
		<Dialog isOpen={true} onClose={() => setModalToShow(false)}>
			{loading && <LoadingSpinner />}
			{!loading && (
				<DialogWrap>
					<TitleWrap>
						<Title>Delete Role?</Title>
						<Button
							text=""
							minimal
							icon="cross"
							onClick={() => setModalToShow(false)}
						/>
					</TitleWrap>
					<hr />
					<p>Are you sure you want to delete this role for {Sites} ?</p>
					<hr />
					<div className="d-flex justify-content-end mt-2 px-2">
						<Button
							text="Cancel"
							large
							intent="secondaryDanger"
							onClick={() => setModalToShow(false)}
						/>
						<Button
							className="ml-2"
							text="Delete"
							large
							intent="primaryDanger"
							onClick={onActionClick}
						/>
					</div>
				</DialogWrap>
			)}
		</Dialog>
	);
};

DeleteRoleModal.propTypes = {
	setModalToShow: PropTypes.func,
	onActionClick: PropTypes.func,
};
