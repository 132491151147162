import React, { useState, useContext, useEffect } from 'react';
import { DrillsService } from 'services';
import PropTypes from 'prop-types';
import {
	Dropdown,
	FormModal,
	ToasterContext,
	DateTimePicker,
	Intent,
	FeaturedIcon,
} from '../../../../componentsV2';
import { DrillDistrictRequirementsService } from 'services';
import { getStateId } from '../Dashboard/utils';
import { sortDrills } from '../../../../utilities';

export const ScheduleDrillModal = ({
	scheduleModalOpen,
	setScheduleModalOpen,
	scheduleSiteData,
	scheduleYearsData,
	reloadFetchDrills,
	history,
}) => {
	const [siteScheduledValue, setSiteScheduledValue] = useState(null);
	const [selectScheduledYear, setSelectScheduledYear] = useState(null);
	const [selectScheduledType, setSelectScheduledType] = useState(null);
	const [selectScheduledDate, setSelectScheduledDate] = useState(null);
	const [drilltypes, setDrillTypes] = useState([]);
	const [siteDropdownLabel, setSiteDropdownLabel] = useState('Select a drill type');
	const [isSiteDropdownDisabled, setIsSiteDropdownDisabled] = useState(false);

	useEffect(() => {
		if (selectScheduledYear) {
			fetchSitesData();
		}
	}, [selectScheduledYear]);

	const fetchSitesData = async () => {
		await DrillDistrictRequirementsService.getAllRequirements(
			selectScheduledYear.value,
			getStateId(),
		)
			.then(reqs => {
				const reqsArray = [];
				reqs.map(req => {
					req?.districtRequirement?.active &&
						reqsArray.push({
							id: req.id,
							name: req.name,
							label: req.name,
							uuid: req.uuid,
							value: req.id,
						});
				});
				setIsSiteDropdownDisabled(false);
				setDrillTypes(sortDrills(reqsArray));
				if (reqsArray?.length < 1) {
					setSiteDropdownLabel(
						'No district requirements have been set for the selected school year.',
					);
					setIsSiteDropdownDisabled(true);
				}
			})
			.catch(error =>
				toaster('There was an error retrieving the drill types: ', Intent.DANGER),
			);
	};

	// Site
	const handleSiteChange = item => {
		setSiteScheduledValue(item);
	};

	// School Year
	const handleYearchange = item => {
		setSelectScheduledYear(item);
	};

	// Type
	const handleTypeChange = item => {
		setSelectScheduledType(item);
	};

	// Date Time
	const handleDateChange = item => {
		setSelectScheduledDate(item);
	};

	const toaster = useContext(ToasterContext);

	const onHandleSaveDrill = () => {
		if (
			siteScheduledValue &&
			selectScheduledYear &&
			selectScheduledType &&
			selectScheduledDate
		) {
			DrillsService.scheduleAndLogADrill(
				siteScheduledValue.value,
				selectScheduledYear.value,
				selectScheduledType.uuid,
				selectScheduledDate,
			)
				.then(({ data: { scheduleId, year } }) => {
					if (scheduleId) {
						setScheduleModalOpen(false);
						toaster(
							`You have successfully logged "${selectScheduledType.label}".`,
							Intent.SUCCESS,
							<FeaturedIcon icon={'tick'} type={'Success'} />,
						);
						history.push(`/drillsV2/log/${year}/${scheduleId}`);
					}
				})
				.catch(err => {
					console.log('Error logging drill', err);
					toaster(
						'Error logging new drill',
						Intent.DANGER,
						<FeaturedIcon icon={'error'} type={'Error'} />,
					);
				});
		} else {
			toaster(
				'Error logging new drill',
				Intent.DANGER,
				<FeaturedIcon icon={'error'} type={'Error'} />,
			);
		}
	};
	const isNotEmpty = value => !!value;
	const [isDisabled, setIsDisabled] = useState(true);
	useEffect(() => {
		setIsDisabled(
			!(
				isNotEmpty(siteScheduledValue) &&
				isNotEmpty(selectScheduledYear) &&
				isNotEmpty(selectScheduledType) &&
				isNotEmpty(selectScheduledDate)
			),
		);
	}, [siteScheduledValue, selectScheduledYear, selectScheduledType, selectScheduledDate]);

	return (
		<>
			<FormModal
				isOpen={scheduleModalOpen}
				onClose={setScheduleModalOpen}
				showCloseButton
				title="Schedule and log a drill"
				scrollContent={false}
				confirmBtn={{
					title: 'Save',
					onClick: () => onHandleSaveDrill(),
					disabled: isDisabled,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: () => setScheduleModalOpen(false),
				}}
			>
				<div className="w-100">
					<Dropdown
						label="Site"
						options={scheduleSiteData}
						isRequired
						value={siteScheduledValue}
						onChange={item => {
							handleSiteChange(item);
						}}
					/>
				</div>
				<div className="w-100 mt-3">
					<Dropdown
						label="School Year"
						options={scheduleYearsData}
						isRequired
						value={selectScheduledYear}
						onChange={item => {
							handleYearchange(item);
						}}
					/>
				</div>
				<div className="w-100 mt-3">
					<Dropdown
						label="Type"
						options={drilltypes}
						disabled={isSiteDropdownDisabled}
						isRequired
						placeholder={siteDropdownLabel}
						value={selectScheduledType}
						onChange={item => {
							handleTypeChange(item);
						}}
					/>
				</div>
				<div className="w-100 mt-3">
					<DateTimePicker
						labelRequired={true}
						initialValue={selectScheduledDate}
						label="What date and time was this drill scheduled for?"
						timePrecision="minute"
						onChange={value => handleDateChange(value)}
					/>
				</div>
			</FormModal>
		</>
	);
};

ScheduleDrillModal.propTypes = {
	scheduleModalOpen: PropTypes.any,
	setScheduleModalOpen: PropTypes.any,
	scheduleDrillTypesData: PropTypes.any,
	scheduleSiteData: PropTypes.any,
	scheduleYearsData: PropTypes.any,
	reloadFetchDrills: PropTypes.func,
};
