import React, {useState, useContext, useRef, useEffect} from 'react'; 
import NormalModal from 'components/Modals/NormalModal';
import { ToasterContext } from "pages/App";
import { Intent } from '@blueprintjs/core';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import AvatarInitials from '../../AvatarInitials';

export default function UploadProfilePhoto (props)
{
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cancelationModal, setCancelationModal] = useState(false);
    const [removePhoto, setRemovePhoto] = useState(false);
    const inputUploadFile = useRef(null);
    const imageMimeType = /image\/(png|jpg|jpeg)/i;
    const toaster = useContext(ToasterContext);
    
    const url = process.env.API_URL;
    const token = JSON.parse(localStorage.getItem('user')).jwt;

    function onFileChange(event) 
    {
        const file = event.target.files[0];
        
        if (!file.type.match(imageMimeType)) {
            //alert("Image mime type is not valid");
            toaster("Image mime type is not valid.", Intent.DANGER);
            return;
        } else {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                const { result } = e.target;
                if (result) {
                    setFileDataURL(result);
                }
            }

        }
    };

    function uploadImageFile () 
    {
        //props.setUserupdated(false);
        setLoading(true);
        //fetch(`${url}/users/uploadimage`, {
        fetch(`${url}/awsimages/upload`, {
                method : 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId : props.userId,
                fileType : selectedFile.type,
                file : fileDataURL 
            })
        })
        .then(res => res.json())
        .then(resp => {  
            if (resp.data.uploadAws != "") {
                toaster("Photo succesfully uploaded.", Intent.SUCCESS);
            } else {
                toaster("Photo could NOT be uploaded.", Intent.DANGER);
            }
            
            setLoading(false);
            props.setUserupdated(true);
            props.setShowImageModal(false);
        });
    };

    function deleteProfileImage()
    {
        //props.setUserupdated(false);
        setLoading(true);
        fetch(`${url}/awsimages/remove/${props.profileImage}/${props.userId}`, {
            method : 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(resp => {
            setLoading(false);
            setRemovePhoto(false);
            props.setUserupdated(true);
            props.setShowImageModal(false);

            if (resp.data.is_deleted == true) {
                toaster("Photo succesfully removed.", Intent.SUCCESS);
            } else {
                toaster("Photo does NOT exist!", Intent.DANGER);
            }
        });
    }

    function confirmClickTitle()
    {
        if (selectedFile) {
            return "Save Changes";
        } else {
            if (props.profileImage != null && props.profileImage != '') {
                return removePhoto ? "Save Changes" : "Upload new photo";
            } else {
                return "Upload photo";
            }            
        }
    }

    function cancelClickTitle()
    {
        if (props.profileImage != null && props.profileImage != '') {
            return removePhoto ? "Cancel" : "Remove Photo";
        } else {
            return "Cancel";
        }
    }

    function confirmClickAction()
    {
        if (selectedFile) {
            uploadImageFile();
        } else {
            if (removePhoto) {
                deleteProfileImage();
            } else {
                inputUploadFile.current.click();
            }
        }
    }

    function cancelClickAction(e)
    {
        if (selectedFile != null) {
            setCancelationModal(true);
            return false;
        } 
         
        if (e.target.className == 'bp3-button-text') {
            if (props.profileImage != null && props.profileImage != '') {
                if (removePhoto) {
                    props.setShowImageModal(false);
                } else {
                    setRemovePhoto(true);
                }
            } else {
                props.setShowImageModal(false);
            }
        } else {
            props.setShowImageModal(false);
        }
    }

    const confirmCancelationModal = () => {
        setCancelationModal(false);
        props.setShowImageModal(false);
    };

    return (
        <>
            <NormalModal
                onConfirmAction={confirmClickAction}
                onCancelAction={(e) => cancelClickAction(e)}
                confirmBtnTitle={confirmClickTitle()}
                cancelBtnTitle={cancelClickTitle()}
                header="User photo"
                loading={loading}
                className="upload-image-modal"
            >
                <div className='image-upload-wrap'>
                    {(selectedFile) ? (
                        <img src={fileDataURL} alt="preview"/>
                    ): (
                        (removePhoto) ? (
                            <AvatarInitials initials={props.userInitials} imageMode="profile"></AvatarInitials>
                        ) : (
                            props.avatarImage
                        )
                    )}
                    <div className='image-upload-wrap_Note'>*Professional photos only</div>
               </div>
               <input 
                    id="profile-file" 
                    type="file" 
                    accept='.png, .jpg, .jpeg'
                    ref={inputUploadFile} 
                    style={{display: 'none'}}
                    onChange={(e) => onFileChange(e)} 
                />
                
            </NormalModal>
            {cancelationModal && ( 
                <UnsavedChangesModal
                    onConfirmAction={confirmCancelationModal}
                    onCancelAction={() => setCancelationModal(false)}
                />
            )}
        </>
    );
}