import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@blueprintjs/core';
import { stripTags } from 'utilities';

const ItemTitle = styled.a``;

const IconWrap = styled.div`
	position: absolute;
	left: -10px;
	top: 3px;
`;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: '4px 0 4px 12px',
	margin: '0 0 6px 0',
	textAlign: 'left',
	position: 'relative',

	// change background colour if dragging

	// styles we need to apply on draggables
	...draggableStyle,
});

const Subsections = props => {
	const { item, index, page, setPage, viewMode } = props;

	return (
		<Droppable
			droppableId={`droppable${item.id}`}
			type={`${index}`}
			key={`subsections-${index}`}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					className='answer-list'
				>
					{item.subsections.map((subsection, i) => {
						if (props.visibleSubsection?.id === subsection.id && 
							props.visibleSubsection?.title === subsection.title) {
								setPage(page);
							}
						return (
						<Draggable
							key={`${index}${i}`}
							draggableId={`${index}${i}`}
							index={i}
							isDragDisabled={viewMode}
						>
							{(p, s) => (
								<div
									ref={p.innerRef}
									{...p.draggableProps}
									style={getItemStyle(
										s.isDragging,
										p.draggableProps.style,
									)}
									className="draggable-wrapper"
								>
									<span {...p.dragHandleProps}>
										<Icon
											icon="drag-handle-vertical"
											style={{
												float: 'left',
											}}
										/>
									</span>
									<ItemTitle
										className="ml-2 subsection-link"
										onClick={() =>
											props.scrollToSection(
												item,
												subsection,
												'subsection',
												page
											)
										}
									>
										{stripTags(subsection.title)}
									</ItemTitle>
									{props.visibleSubsection?.id === subsection.id && 
										props.visibleSubsection?.title === subsection.title && (
										<IconWrap>
											<Icon icon="dot" color="#D9822B" />
										</IconWrap>
									)}
								</div>
							)}
						</Draggable>
						)
					})}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
};

Subsections.propTypes = {
	item: PropTypes.object,
	index: PropTypes.number,
};

export default Subsections;
