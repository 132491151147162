import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormLabel, RequiredWraper } from 'components/Forms/shared.js';
import { Input } from 'components/Input';
import { Dropdown } from 'components/Dropdown';
import { Dialog } from '@blueprintjs/core';
import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/LoadingSpinner';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import { SiteDropdownSingleSelect } from 'components/SiteDropdownSingleSelect';
import { Helper } from 'utilities/helper';
import { UserService } from 'services';
import { isNotEmpty } from '../../../utilities/validations';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

const DialogWrap = styled.div`
	padding-left: 10px;
	padding-right: 10px;
`;

const NoteWrap = styled.div`
	color: red;
	padding-top: 10px;
	padding-bottom: 13px;
`;

const NewUserModel = ({ isOpen, closeModal, sites, formdata, selectedSites }) => {
	const selectedSiteOnLoad = Helper.getSelectedSiteOnLoad(selectedSites, sites);
	const [selectedSite, setselectedSite] = useState(selectedSiteOnLoad);
	const [selectedRole, setselectedRole] = useState();

	const [formData, setFormData] = useState({
		f_name: '',
		l_name: '',
		email: '',
		selectedSite: '',
		selectedRole: '',
	});

	const [validationErrors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [hasChange, setHasChange] = useState(false);
	const [cancelWithChanges, setCancleWithChanges] = useState(false);
	const [siteRoleList, setSiteRolesList] = useState('');

	useEffect(() => {
		if (selectedSite) {
			getSiteRoles(selectedSite.value);
		}
	}, [selectedSite]);

	const url = process.env.API_URL;
	const token = JSON.parse(localStorage.getItem('user')).jwt;

	const getSiteRoles = event => {
		fetch(`${url}/roles?sites[0]=${event}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				const roles = [
					resp
						? resp.data
								.map(item => {
									if (item.title === 'First Responder') {
										return null; // Skip this item
									}
									return { label: item.title, value: item.id };
								})
								.filter(item => item !== null) // Filter out skipped items
						: [],
				];
				setSiteRolesList(roles[0]);
			});
	};

	const checkEmailExist = async email => {
		const emailExists = await UserService.checkEmailExists(email);
		return emailExists;
	};

	const validate = async () => {
		const errors = {};

		if (!isNotEmpty(formData.f_name)) {
			errors.f_name = '*First name is required.';
		}

		if (!isNotEmpty(formData.l_name)) {
			errors.l_name = '*Last name is required.';
		}

		if (!selectedRole || !siteRoleList || siteRoleList.length === 0) {
			errors.selectedRole = 'Please select a role.';
		}

		if (!isNotEmpty(formData.email)) {
			errors.email = '*Email is required.';
		}

		if (!isNotEmpty(formData.email)) {
			errors.email = '*Email is required.';
		} else {
			const emailExists = await checkEmailExist(formData.email);

			if (emailExists) {
				errors.email =
					'*Email already exists. Verify user is not in the system or enter a different email.';
			}
		}

		return errors;
	};

	const handleRoleChange = value => {
		if (siteRoleList.length === 0) {
			setselectedRole('');
		} else {
			setselectedRole(value);
		}
	};

	const handleSubmit = event => {
		event.preventDefault();
		// window.location.href = 'users/view/200000000';
		// toaster("Successfully created a new user. Explore the settings side panel to customize this account further.", Intent.SUCCESS);
		setLoading(true); // set loading to true
		validate().then(errors => {
			setLoading(false); // set loading to false after validating
			if (Object.keys(errors).length === 0) {
				const site_id = selectedSite.value;
				const role_id = selectedRole.value;
				const new_data = { ...event, site_id: site_id, role_id: role_id };

				formdata(new_data);
				closeModal();
			} else {
				setErrors(errors);
				const firstErrorField = Object.keys(errors)[0];
				const inputElement = document.getElementsByName(firstErrorField)[0];
				if (inputElement) {
					inputElement.focus();
				}
			}
		});
	};

	const selectSitesHandler = site => {
		setselectedSite(site);
		setHasChange(true);
	};

	const handleCancelClick = () => {
		if (hasChange) {
			setCancleWithChanges(true);
		} else {
			closeModal();
		}
	};

	const allRequiredFieldsAreFilled = () => {
		return (
			formData.f_name !== '' &&
			formData.l_name !== '' &&
			formData.email !== '' &&
			selectedSite &&
			selectedRole
		);
	};

	const saveBtnEnabled = hasChange && allRequiredFieldsAreFilled();

	return (
		<>
			{
				<Dialog isOpen={isOpen} onClose={handleCancelClick}>
					<DialogWrap>
						<TitleWrap>
							<Title>Add New User</Title>
							<Button text="" minimal icon="cross" onClick={handleCancelClick} />
						</TitleWrap>
						<hr />
						{loading && <LoadingSpinner />}
						{!loading && (
							<form onSubmit={handleSubmit} method="POST">
								<FormLabel>
									First Name <RequiredWraper>Required</RequiredWraper>
								</FormLabel>
								<Input
									placeholder="First Name"
									name="f_name"
									error={!!validationErrors.f_name}
									errorMessage={validationErrors.f_name}
									value={formData.f_name}
									onChange={e => {
										validationErrors.f_name = '';
										setFormData({ ...formData, f_name: e.target.value });
										setHasChange(true);
									}}
								/>
								<FormLabel>
									Last Name <RequiredWraper>Required</RequiredWraper>
								</FormLabel>
								<Input
									placeholder="Last Name"
									name="l_name"
									error={!!validationErrors.l_name}
									errorMessage={validationErrors.l_name}
									value={formData.l_name}
									onChange={e => {
										validationErrors.l_name = '';
										setFormData({ ...formData, l_name: e.target.value });
										setHasChange(true);
									}}
								/>
								<FormLabel>
									Email <RequiredWraper>Required</RequiredWraper>
								</FormLabel>
								<Input
									type="email"
									placeholder="email@email.com"
									name="email"
									value={formData.email}
									error={!!validationErrors.email}
									errorMessage={validationErrors.email}
									onChange={e => {
										validationErrors.email = '';
										setFormData({ ...formData, email: e.target.value });
										setHasChange(true);
									}}
								/>
								<SiteDropdownSingleSelect
									label="Primary Site"
									selectedSite={selectedSite}
									isRequired
									sitesList={sites || []}
									onChange={selectSitesHandler}
								/>
								<FormLabel>
									Primary Role <RequiredWraper>Required</RequiredWraper>
								</FormLabel>
								<Dropdown
									value={
										siteRoleList.length === 0
											? { label: 'Select option', value: '' }
											: selectedRole
									}
									options={siteRoleList || []}
									//onChange={newValue => setselectedRole(newValue)}
									onChange={e => {
										handleRoleChange(e);
										setHasChange(true);
									}}
									error={!!validationErrors.selectedRole}
									errorMessage={validationErrors.selectedRole}
									disabled={!siteRoleList || siteRoleList.length === 0}
								/>
								<NoteWrap>
									<p>
										<span>
											A Welcome Email will be sent to this user upon adding.
										</span>
									</p>
								</NoteWrap>
								<hr />
								<div className="d-flex justify-content-end mt-2 px-2">
									<Button
										type="button"
										text="Cancel"
										large
										intent="secondary"
										onClick={handleCancelClick}
									/>
									<Button
										disabled={!saveBtnEnabled}
										type="submit"
										intent="primary"
										text="Add"
										className="ml-2"
										large
									/>
								</div>
							</form>
						)}
					</DialogWrap>
				</Dialog>
			}
			{cancelWithChanges && (
				<UnsavedChangesModal
					onConfirmAction={closeModal}
					onCancelAction={() => setCancleWithChanges(false)}
				/>
			)}
		</>
	);
};

export default NewUserModel;
