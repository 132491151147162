import React, { useContext, useState } from 'react';
import {
	LoadingSpinner,
	BootstrapedTable,
	TableCell,
	Badge,
	ConfirmationModal,
} from 'componentsV2';
import { RAPID_ICONS } from 'assets/respond';
import PropTypes from 'prop-types';
import { Icon, Intent } from '@blueprintjs/core';
import { AlarmTypeActinos } from './AlarmTypeActinos';
import { RapidAlarmService } from 'services/RapidAlarmService';
import { ToasterContext } from 'pages/App';

export const AlarmTypesTable = ({
	loading,
	list,
	filterationData,
	setFilterationData,
	totalRows,
	reloadList,
	history,
	hasPermissionOnDistrict,
	isSuperAdmin,
}) => {
	const [modalToShow, setModalToShow] = useState(null);
	const [alarmType, setAlarmType] = useState({});
	const [modalLoading, setModalLoading] = useState(false);
	const toaster = useContext(ToasterContext);
	const DELETE_MODAL = 'deleteModal';
	const TOGGLE_PRIORITY_MODAL = 'priorityModal';

	const showPriorityModal = alarmType => {
		if (alarmType.disabled) return;

		setAlarmType(alarmType);
		setModalToShow(TOGGLE_PRIORITY_MODAL);
	};

	const showDeleteModal = alarmType => {
		if (alarmType.preset) return;

		setAlarmType(alarmType);
		setModalToShow(DELETE_MODAL);
	};

	const cancelBtn = {
		title: 'Cancel',
		onClick: () => closeModal(),
	};
	const confirmPriorityBtn = {
		title: alarmType.setAsPriority ? 'Set as non-priority' : 'Set as priority',
		onClick: () => toggleAlarmTypePriority(),
	};
	const confirmDeleteBtn = {
		title: 'Delete it',
		onClick: () => deleteAlarmType(),
		type: 'primaryDanger',
	};
	const deleteIcon = { icon: 'trash', iconColor: 'error' };

	const closeModal = () => {
		setModalToShow(null);
	};

	const toggleAlarmTypePriority = () => {
		setModalLoading(true);

		RapidAlarmService.toggleAlarmTypePriority(alarmType.id)
			.then(response => {
				toaster(
					alarmType.setAsPriority
						? 'You have successfully set this alarm as non-priority.'
						: 'You have successfully set this alarm as priority.',
					Intent.SUCCESS,
				);
				reloadList();
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setModalLoading(false);
				closeModal();
			});
	};

	const deleteAlarmType = () => {
		setModalLoading(true);

		RapidAlarmService.deleteAlarmType(alarmType.id)
			.then(response => {
				toaster(`You have successfully deleted "${alarmType.title}"`, Intent.SUCCESS);
				reloadList();
			})
			.catch(error => {
				toaster(error?.error?.description || error?.description, Intent.DANGER);
			})
			.finally(() => {
				setModalLoading(false);
				closeModal();
			});
	};

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'title',
			dataField: 'title',
			text: 'Name',
			formatter: (value, row) => {
				let supportText = '';

				if (row.isGeneric && row.preset) {
					supportText = (
						<>
							Marked as generic* <br /> Preset
						</>
					);
				} else if (row.isGeneric) {
					supportText = 'Marked as generic*';
				} else if (row.preset) {
					supportText = 'Preset';
				}

				return (
					<TableCell
						text={value}
						supportText={supportText}
						leftIcon={<img src={RAPID_ICONS[row.icon]} />}
					/>
				);
			},
		},
		{
			key: 'activateRollCall',
			dataField: 'activateRollCall',
			text: 'Includes Roll Call',
			classes: 'text-center',
			headerClasses: 'text-center',
			formatter: (value, row) => (
				<Icon
					icon="tick-circle"
					className={value ? 'success-500-color' : 'gray-200-color'}
				/>
			),
		},
		{
			key: 'disabled',
			dataField: 'disabled',
			text: 'Status',
			classes: 'text-center',
			headerClasses: 'text-center',
			formatter: (value, row) => {
				let type = 'Success';

				if (value == true) {
					type = 'Gray';
				}

				return (
					<>
						<Badge type={type} size="md" text={value ? 'Disabled' : 'Enabled'} />
					</>
				);
			},
		},
		{
			key: 'setAsPriority',
			dataField: 'setAsPriority',
			text: 'Priority alarm',
			classes: 'text-center',
			headerClasses: 'text-center',
			formatter: (value, row) => (
				<Icon
					icon="notifications"
					className={value ? 'error-600-color' : 'gray-200-color'}
					onClick={() => showPriorityModal(row)}
				/>
			),
		},
		{
			key: 'actions',
			dataField: 'id',
			text: '',
			formatter: (value, row) => (
				<AlarmTypeActinos
					alarmType={row}
					history={history}
					showDeleteModal={showDeleteModal}
					hasPermissionOnDistrict={hasPermissionOnDistrict}
					isSuperAdmin={isSuperAdmin}
				/>
			),
		},
	];

	const disabledRowCondition = row => {
		return row.disabled === true;
	};

	const setRowsPerPage = value => {
		setFilterationData(oldData => ({ ...oldData, perPage: value }));
	};

	const setCurrentPage = value => {
		setFilterationData(oldData => ({ ...oldData, page: value }));
	};

	if (loading) return <LoadingSpinner className="mt-3" />;
	return (
		<>
			<BootstrapedTable
				keyField="id"
				data={list}
				columns={columns}
				disabledRowCondition={disabledRowCondition}
				totalRows={totalRows}
				currentPage={filterationData.page}
				rowsPerPage={filterationData.perPage}
				setCurrentPage={setCurrentPage}
				setRowsPerPage={setRowsPerPage}
			/>

			<ConfirmationModal
				isOpen={modalToShow === TOGGLE_PRIORITY_MODAL}
				title={
					alarmType.setAsPriority ? 'Set as non-priority alarm?' : 'Set priority alarm?'
				}
				subtitle={
					alarmType.setAsPriority
						? 'Are you sure you want to set this as a non-priority alarm?'
						: 'Are you sure you want to set this as a priority alarm?'
				}
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmPriorityBtn}
				loading={modalLoading}
				btnsSize="lg"
			/>

			<ConfirmationModal
				isOpen={modalToShow === DELETE_MODAL}
				title={`Are you sure you want to delete "${alarmType.title}"?`}
				subtitle={`Once deleted, this cannot be recovered.`}
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmDeleteBtn}
				loading={modalLoading}
				icon={deleteIcon}
			/>
		</>
	);
};

AlarmTypesTable.propTypes = {
	list: PropTypes.array.isRequired,
};
