import React from 'react';

export const ChatMessage = ({ text, time, author, showAuthor = true }) => {
	return (
		<div
			className={`chat-message ${!showAuthor ? 'no-author' : ''} ${
				author === 'me' ? 'my-message' : ''
			}`}
		>
			{showAuthor && (
				<div className="chat-message-author">{author === 'me' ? 'You' : author}</div>
			)}
			<div className="chat-message-block">
				<div className="chat-message-text">{text}</div>
				<div className="chat-message-time">{time}</div>
			</div>
		</div>
	);
};
