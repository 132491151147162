/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import {
	PieChart,
	Pie,
	Sector,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	LineChart,
	Line,
} from 'recharts';
import PropTypes from 'prop-types';

import { LoadingSpinner } from 'components/LoadingSpinner';
import {
	OverviewInfoComponent,
	WhiteCardComponent,
	TotalizerComponent,
	ChartWrapper,
} from 'pages/Drills/panels/Analytics/shared.js';
import { randomColor } from 'utilities/drills/analytics';

const Colors = ['#6FDC8C', '#4589FF', '#08BDBA'];

const getByYearResumeDataValue = (byYearStats, byYearDropdownValue) => {
	if (byYearDropdownValue === 'completed') {
		return byYearStats.completed;
	}
	if (byYearDropdownValue === 'overdue') {
		return byYearStats.overdue;
	}
	if (byYearDropdownValue === 'notScheduled') {
		return byYearStats.notScheduled;
	}
	throw Error(
		'byYearDropdownValue flag has an incorrect value. Please double check.',
	);
};

const getTrendsDataValue = (yearlyTrendsData, trendsShowCompletedDrills) => {
	if (trendsShowCompletedDrills === 'completed') {
		return yearlyTrendsData.completed;
	}
	if (trendsShowCompletedDrills === 'late') {
		return yearlyTrendsData.late;
	}
	throw Error(
		'trendsShowCompletedDrills flag has an incorrect value. Please double check.',
	);
};

export const AllDrillsAnalytics = ({
	yearlyTrendsData,
	overviewData,
	selectedYear,
	selectedBuildings,
	trendsDropdownValue,
	byYearDropdownValue,
	onTrendsTypeChange,
	onByYearTypeChange,
}) => {
	const [drillsCompletedChartData, setDrillsCompletedChartData] = useState(
		overviewData,
	);

	useEffect(() => {
		if (overviewData) {
			setDrillsCompletedChartData([
				{
					name: `${
						overviewData.totalCompletedPercentage
					}% drills completed for ${selectedYear}`,
					rest: `% drills completed for ${selectedYear}`,
					value: overviewData.totalCompletedPercentage,
				},
				{
					name: '',
					value: Math.round(
						100 - overviewData.totalCompletedPercentage,
					),
					rest: '',
				},
			]);
		}
	}, [overviewData]);

	const renderActiveShape = props => {
		const {
			cx,
			cy,
			innerRadius,
			outerRadius,
			startAngle,
			endAngle,
			payload,
		} = props;

		return (
			<g>
				<text
					x={cx}
					y={cy}
					dy={8}
					style={{ fontSize: 36 }}
					width={87}
					textAnchor="middle"
					verticalAnchor="start"
					fill="#128C5A"
				>
					{`${payload.value}%`}
				</text>
				<text
					x={cx}
					y={cy}
					dy={20}
					style={{ width: '35px', fontSize: 12 }}
					scaleToFit
					textAnchor="middle"
					verticalAnchor="end"
					fill="#128C5A"
				>
					{payload.rest}
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					startAngle={startAngle}
					endAngle={endAngle}
					fill="#11E38C"
				/>
			</g>
		);
	};

	if (!overviewData) {
		// return <LoadingSpinner />;
		return (
			<div className="instructions-to-user">
				Please select a School and School Year
			</div>
		);
	}

	const {
		totalRequired,
		totalOverdue,
		totalNotScheduled,
		totalCompleted,
		mostOverdueType,
		mostNotScheduledType,
		mostCompletedType,
		mostOverdueBuilding,
		mostNotScheduledBuilding,
		mostCompletedBuilding,
	} = overviewData;

	return (
		<>
			<div className="totalizers-containers">
				<WhiteCardComponent width="272px" marginRight="24px">
					<TotalizerComponent
						title="Total drills required"
						total={totalRequired}
						subtitle="drills"
						textColor="#10161A"
					/>
				</WhiteCardComponent>
				<WhiteCardComponent width="272px" marginRight="24px">
					<TotalizerComponent
						title="Total overdue drills"
						total={totalOverdue}
						subtitle="drills"
						textColor="#DB3737"
					/>
				</WhiteCardComponent>
				<WhiteCardComponent width="272px" marginRight="24px">
					<TotalizerComponent
						title="Total drills not scheduled yet"
						total={totalNotScheduled}
						subtitle="drills"
						textColor="#D9822B"
					/>
				</WhiteCardComponent>
				<WhiteCardComponent width="272px">
					<TotalizerComponent
						title="Total drills completed"
						total={totalCompleted}
						subtitle="drills"
						textColor="#128C5A"
					/>
				</WhiteCardComponent>
			</div>
			{drillsCompletedChartData && (
				<div>
					<ChartWrapper
						title="Drill logs overview"
						subtitle={`for ${selectedYear}`}
					>
						<div className="info-container">
							<ResponsiveContainer height={400} width={400}>
								<PieChart>
									<Pie
										cx="42%"
										cy="50%"
										activeIndex={0}
										activeShape={renderActiveShape}
										data={drillsCompletedChartData}
										innerRadius={120}
										outerRadius="80%"
										fill="#EBF1F5"
										dataKey="value"
									/>
								</PieChart>
							</ResponsiveContainer>
							<div className="overview-info-container">
								{mostOverdueType.name && (
									<OverviewInfoComponent
										icon="flame"
										number={mostOverdueType.count}
										text={`${
											mostOverdueType.name
										} are the most overdue drill type`}
										color="#DB3737"
									/>
								)}
								{mostNotScheduledType.name && (
									<OverviewInfoComponent
										icon="lock"
										number={mostNotScheduledType.count}
										text={`${
											mostNotScheduledType.name
										} are the most not scheduled yet drill type`}
										color="#C8D341"
									/>
								)}
								{mostCompletedType.name && (
									<OverviewInfoComponent
										icon="shield"
										number={mostCompletedType.count}
										text={`${
											mostCompletedType.name
										} are the most completed drill type`}
										color="#128C5A"
									/>
								)}
								{mostOverdueBuilding.name && (
									<OverviewInfoComponent
										icon="office"
										number={mostOverdueBuilding.count}
										text={`${
											mostOverdueBuilding.name
										} has the most overdue drills`}
										color="#DB3737"
									/>
								)}
								{mostNotScheduledBuilding.name && (
									<OverviewInfoComponent
										icon="office"
										number={mostNotScheduledBuilding.count}
										text={`${
											mostNotScheduledBuilding.name
										} has the most drills not scheduled yet`}
										color="#C8D341"
									/>
								)}
								{mostCompletedBuilding.name && (
									<OverviewInfoComponent
										icon="office"
										number={mostCompletedBuilding.count}
										text={`${
											mostCompletedBuilding.name
										} has the most completed drills`}
										color="#128C5A"
									/>
								)}
							</div>
						</div>
					</ChartWrapper>
				</div>
			)}
			{overviewData.byYearStats && (
				<div className="chart-container" key={byYearDropdownValue}>
					<ChartWrapper
						chartId="by-year-resume"
						title={`Drill logs in ${selectedYear}`}
						subtitle={`Drill logs in ${selectedYear}`}
						showFilter
						dropdownValue={byYearDropdownValue}
						onDropdownChange={onByYearTypeChange}
					>
						<div className="responsive-chart-container">
							<ResponsiveContainer width="100%" height="100%">
								<BarChart
									maxBarSize={22}
									width={500}
									height={280}
									data={getByYearResumeDataValue(
										overviewData.byYearStats,
										byYearDropdownValue.value,
									)}
									margin={{
										top: 10,
										right: 30,
										left: 0,
										bottom: 50,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />
									<Legend />
									{overviewData.byYearStats.typesAdded.map(
										type => (
											<Bar
												dataKey={type.name}
												stackId="a"
												fill={randomColor()}
											/>
										),
									)}
								</BarChart>
							</ResponsiveContainer>
						</div>
					</ChartWrapper>
				</div>
			)}
			{yearlyTrendsData && (
				<div className="chart-container" key={trendsDropdownValue}>
					<ChartWrapper
						chartId="yearly-trends"
						title="Drills yearly trends"
						subtitle={
							(selectedBuildings &&
								selectedBuildings
									.map(building => building.name)
									.toString()) ||
							'All schools'
						}
						showFilter
						dropdownValue={trendsDropdownValue}
						onDropdownChange={onTrendsTypeChange}
					>
						<div className="responsive-chart-container">
							<ResponsiveContainer width="100%" height="100%">
								<LineChart
									width={500}
									height={300}
									data={getTrendsDataValue(
										yearlyTrendsData,
										trendsDropdownValue.value,
									)}
									margin={{
										top: 5,
										right: 30,
										left: 0,
										bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />
									<Legend />
									{yearlyTrendsData?.years?.map(
										(year, index) => (
											<Line
												type="basis"
												dataKey={year}
												stroke={Colors[index]}
												activeDot={{ r: 8 }}
											/>
										),
									)}
								</LineChart>
							</ResponsiveContainer>
						</div>
					</ChartWrapper>
				</div>
			)}
			<div className="chart-container">
				<ChartWrapper
					title="Required drills by type"
					subtitle={`for ${selectedYear}`}
				>
					<div className="responsive-chart-container">
						<ResponsiveContainer width="100%" height="100%">
							<BarChart
								maxBarSize={22}
								width={500}
								height={300}
								data={overviewData.byTypeStats}
								margin={{
									top: 20,
									right: 30,
									left: 0,
									bottom: 10,
								}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Legend />
								<Bar
									dataKey="Completed"
									stackId="a"
									fill="#11E38C"
								/>
								<Bar
									dataKey="Not scheduled yet"
									stackId="a"
									fill="#DBE84A"
								/>
								<Bar
									dataKey="Overdue"
									stackId="a"
									fill="#EF6C6C"
								/>
							</BarChart>
						</ResponsiveContainer>
					</div>
				</ChartWrapper>
			</div>
		</>
	);
};

AllDrillsAnalytics.propTypes = {
	yearlyTrendsData: PropTypes.object,
	overviewData: PropTypes.object,
	selectedYear: PropTypes.string,
	selectedBuildings: PropTypes.array,
	trendsDropdownValue: PropTypes.string,
	byYearDropdownValue: PropTypes.string,
	onTrendsTypeChange: PropTypes.func,
	onByYearTypeChange: PropTypes.func,
};
