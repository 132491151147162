import React, { useContext, useState, useEffect } from 'react';
import { CardContainer, FeaturedIcon, ConfirmationModal, Intent } from 'componentsV2';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';
import { FloorplanService } from 'services';
import { ToasterContext } from 'pages/App';
import { useGetSiteFloorplans } from 'hooks';

export const UnarchiveAllModal = ({
	modalToShow,
	closeModal,
	setModalToShow,
	siteInfo,
	loadMapDetails,
}) => {
	const [loading, setLoading] = useState(false);
	const [showArchivedCard, setShowArchivedCard] = useState(false);
	const { siteFloorplans } = useGetSiteFloorplans(siteInfo.id, {"includeArchived":1});

	useEffect(() => {
		const isAllMapArchivedFlag = siteFloorplans.length > 0 && siteFloorplans.every(map => map.dateArchived != null);
		setShowArchivedCard(isAllMapArchivedFlag)

	}, [siteFloorplans]);

	const closeModdal = async () => {
		setLoading(true);
		const requestBody = {
			archived: false,
			site_id: siteInfo.id,
		};
		await FloorplanService.archiveAllMaps(requestBody)
			.then(response => {
				if (response.statusCode === 200) {
					toaster(
						`You have successfully unarchived all maps for "` + siteInfo?.name + `"`,
						Intent.SUCCESS,
						null,
						2000,
						false,
						'map-view-page',
					);
				}
			})
			.catch(err => {
				toaster(err.error?.description, Intent.DANGER, null, 2000, false, 'map-view-page');
			})
			.finally(() => {
				loadMapDetails();
				setShowArchivedCard(false);
				setLoading(false);
				closeModal();
			});
	};
	const toaster = useContext(ToasterContext);
	const confirmUnarchiveBtn = { title: 'Yes, unarchive them', onClick: closeModdal };
	const cancelBtn = { title: 'No, go back', onClick: closeModal };

	return (
		<>
			<ConfirmationModal
				isOpen={modalToShow === 'UNARCHIVE_ALL'}
				title={`Are you sure you want to unarchive all maps for "` + siteInfo?.name + `"?`}
				subtitle="You can archive them at a later time."
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmUnarchiveBtn}
				loading={loading}
				btnsSize="lg"
				customClassName={' map-container-modals'}
			/>
			{showArchivedCard && (
				<CardContainer
					cardCustomClass="archive-all-container"
					bodyCustomClass="archive-all-body"
				>
					<FeaturedIcon
						icon="error"
						size="md"
						type="Gray"
						className="icon"
						shape={'circleIcon'}
					/>
					<div className="description">
						<p className="title">All maps are currently archived.</p>
						<p
							className="clickable-link"
							onClick={() => setModalToShow('UNARCHIVE_ALL')}
						>
							Unarchive all <Icon icon="arrow-right" size={12} />
						</p>
					</div>
				</CardContainer>
			)}
		</>
	);
};

UnarchiveAllModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
};
