/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@blueprintjs/core';
import { stripTags } from 'utilities';

const ItemTitle = styled.span`
	display: block;
	padding-left: 10px;
	text-align: left;
	cursor: pointer;
`;

const IconWrap = styled.div`
	position: absolute;
	left: -10px;
	top: 3px;
`;

const getColor = filledOut => {
	if (filledOut === 'true') {
		return '#128C5A';
	}
	if (filledOut === 'false') {
		return '#D9822B';
	}
	return '#000000';
};

const ColoredTitle = styled.span`
	color: ${props => getColor(props.filledOut)};
`;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: '4px 0 4px 12px',
	margin: '0 0 6px 0',
	textAlign: 'left',
	position: 'relative',

	// change background colour if dragging

	// styles we need to apply on draggables
	...draggableStyle,
});

// const getAnswerListStyle = isDraggingOver => ({
// 	padding: 4,
// 	width: 250,
// 	marginLeft: 5,
// });

const getAnswerListStyle = () => ({
	width: 250,
	marginLeft: 5,
});

const Subsections = props => {
	const getTitle = item => (
		<ColoredTitle
			filledOut={item.complete ? item.complete.toString() : 'false'}
		>
			{stripTags(item.title)}
		</ColoredTitle>
	);

	const { item, index, page, setPage, activeSection, viewMode } = props;

	return (
		<Droppable
			droppableId={`droppable${item.id}`}
			type={`${index}`}
			key={`subsections-${index}`}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					style={getAnswerListStyle(snapshot.isDraggingOver)}
				>
					{item.subsections.map((subsection, i) => {
						if (activeSection?.subSectionId === subsection.id ) {
								setPage(page);
							}
						return (
							<Draggable
								key={`${index}${i}`}
								draggableId={`${index}${i}`}
								index={i}
								isDragDisabled={viewMode}
							>
								{(p, s) => (
									<div
										ref={p.innerRef}
										{...p.draggableProps}
										style={getItemStyle(
											s.isDragging,
											p.draggableProps.style,
										)}
									>
										<span {...p.dragHandleProps}>
											<Icon
												icon="drag-handle-vertical"
												style={{
													float: 'left',
												}}
											/>
										</span>
										<ItemTitle
											className="ml-2"
											onClick={() => props.scrollToSection(item, subsection, 'subsection', page)}
										>
											{getTitle(subsection)}
										</ItemTitle>
										{activeSection?.sectionId === item.id && activeSection?.subSectionId === subsection.id && (
											<IconWrap>
												<Icon icon="dot" color="#D9822B" />
											</IconWrap>
										)}
									</div>
								)}
							</Draggable>
						)
					})}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
};

Subsections.propTypes = {
	item: PropTypes.object,
	activeSection: PropTypes.object,
	index: PropTypes.number,
	scrollToSection: PropTypes.func,
	page: PropTypes.number,
	setPage: PropTypes.func,
};

export default Subsections;
