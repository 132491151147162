import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tab } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { LinkButton } from 'components/LinkButton';
import Swal from 'sweetalert2';
import selectRoute from './selectors';
import { parseDataModel, stringToVarName, validateTemplateSections } from 'utilities';
import { UploadImage } from '../../Shared/modals';
import PlanOutline from '../../Shared/PlanOutline/PlanOutline';
import { EventLogs } from 'components/EventLogs';
import { BackToTopButton } from 'components/BackToTopButton';
import VisibilitySensor from 'react-visibility-sensor';
import DeleteSectionModal from './SafetyPlan/Modals/DeleteSectionModal';
import { TemplateService } from 'services';
import { fireSwal } from 'utilities/fireSwal';
import { BreadCrumbHeader, EventLog, SectionSubSectionContainer, scrollToSidebarSection } from "../../Shared";
import { Button, Badge, ConfirmationModal, CustomTabs, FeaturedIcon } from "componentsV2";
import { SafetyplansProvider } from '../Providers/SafetyplansProvider';
import './css/index.scss';
import { ActionsList } from "./ActionsList";
import { SectionActionsList } from "./SectionActionsList";
import { CreateVariableModal, PublishModal } from "./modals";
import { format } from 'date-fns';
import { ActionButton } from "./ActionButton";

const Split = styled.hr``;
const sectionsPerPage = 1;
class CoreTemplateDetails extends React.Component {
	state = {
		nodes: [],
		loading: true,
		commonVariables: [],
		panel: 'contextualeditor',
		visibleSection: null,
		visibleSubsection: null,
		currentPage: 0,
		yPosition: 0,
		page: 0,
		modalToShow: false,
		viewMode: false,
		templateOldName: '',
		templateOldContent: {},
		templateSavedBeforeUnmount: false,
		isSectionScrollActive: true,
		startScrolling: false,
		fileValidationTitle: null,
		errorObj: null,
		isAutoSaved: false,
		sectionData: {},
		activeSection: null,
		activeSectionIndex: null,
		activeSubSectionIndex: null,
		activeEditor: null,
		linkedEditorsToSection: []
	};

	constructor() {
		super();
		this.sidebarRef = React.createRef();
		this.mainWrapperRef = React.createRef();
	}

	loadData = (firstLoad) => {
		const {
			match: {
				params: { id },
			},
			dispatch,
			history,
			route: { districtId },
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;

		if (firstLoad) {
			dispatch({
				type: 'CLEAR_TEMPLATE_DATA',
			});
		}

		Promise.all([
			fetch(`${process.env.API_URL}/plan-templates/${id}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}),
			fetch(`${process.env.API_URL}/common-variables`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			}),
		])
			.then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
			.then(([resp1, resp2]) => {
				let template = {
					id,
					is_template: true,
					name: resp1.data.template.name,
					lastUpdated: resp1.data.template.editedDate.date,
					dateWithTimezone: resp1.data.template.dateWithTimezone,
					state: resp1.data.template.state,
					archived: resp1.data.template.archived,
					archivedBy: resp1.data.template.archiver,
					archivedDate: resp1.data.template.archivedDate,

					sections: [
						{
							id: 1,
							title: 'Section 1 title',
							subsections: [
								{
									id: 1,
									title: 'Subsection 1 title',
									content: '<p>Subsection 1 content</p>',
								},
							],
						},
					],
				};
				if (resp1.data.template.headers) {
					template.headers = JSON.parse(resp1.data.template.headers);
				}
				if (resp1.data.template.content) {
					if (firstLoad) {
						// Only on the First Load, set the Initial Information
						// of the template in the state, needed to log changes
						this.setState({
							templateOldName: resp1.data.template.name,
							templateOldContent: resp1.data.template.content
						});
					}
					template.sections = JSON.parse(resp1.data.template.content);
					if (template.sections[0]) {
						this.setState({
							visibleSection: template.sections[0]
						})
					}
					template = parseDataModel(template);
				}
				if (resp1.data.variables) {
					this.setState({ templateVariables: resp1.data.variables });
				}
				if (template.archived && !this.state.viewMode) {
					history.push('/core/view/'+id);
				}
				const singleSection = JSON.parse(JSON.stringify(template));
				singleSection.sections.length = 1;
				this.setState({ commonVariables: resp2.data, loading: false });
				return dispatch({
					type: 'LOAD_TEMPLATE',
					payload: template,
					filtered: singleSection,
				});
			});
	};
	openPublishModal = (isSection, sectionItem, subSectionItem,sectionIndex, subsectionIndex) => {
		const section = {
			'item' : sectionItem,
			'subSectionItem':subSectionItem,
			'section_index':sectionIndex,
			'subsection_index':subsectionIndex,
			'is_section':isSection
		}
		this.setState({
			modalToShow: 'ShowCoreUpdateModal',
			sectionData: section
		})

	}


	saveTemplate = async (enableValidation, setAutoSave = false) => {
		if (setAutoSave) {
			this.setState({ isAutoSaved: false });
		}
		const {
			route: {
				template: { sections, is_template, name, headers },
			},
			match: {
				params: { id },
			},
		} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		let errorObj = validateTemplateSections(sections, true, null, name);
		if (errorObj.issue && enableValidation) {
			this.openAllowFileValidationnModal(errorObj)
		} else {
			fetch(`${process.env.API_URL}/plan-templates/${id}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'app/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					headers: JSON.stringify(headers),
					content: JSON.stringify(sections),
					name: name,
					oldName: this.state.templateOldName,
					oldContent: this.state.templateOldContent,
					log: 1,
				}),
			})
				.then(res => res.json())
				.then(() => {
					if (enableValidation) {
						this.setState({ templateSavedBeforeUnmount: true });
						const { history } = this.props;
						return history.push('/safety-plans');
					}
					if (setAutoSave) {
						this.setState({ isAutoSaved: true });
					}
				});
		}
	};

	saveAndReload = () => {
		const {
			route: {
				template: { sections, headers },
			},
			match: {
				params: { id },
			},
		} = this.props;
		this.setState({ isAutoSaved: false });

		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/plan-templates/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				headers: JSON.stringify(headers),
				content: JSON.stringify(sections),
			}),
		})
			.then(res => res.json())
			.then(() => {
				this.setState({ isAutoSaved: true });
				this.loadData();
			});
	};

	handleNodeClick = e => {
		window.location.hash = e.id;
	};

	updateNodes = (
		sectionIndex,
		subsectionIndex,
		content,
	) => {
		const { dispatch } = this.props;
		if (subsectionIndex === null) {
			dispatch({
				type: 'UPDATE_SECTION_CONTENT',
				payload: content,
				sectionIndex,
			});
			return null;
		}
		dispatch({
			type: 'UPDATE_SUBSECTION',
			payload: content,
			sectionIndex,
			subsectionIndex,
		});
		return null;
	};

	updateSectionTitle = async (e, sectionIndex) => {
		const { dispatch } = this.props;
		dispatch({
			type: 'UPDATE_SECTION_TITLE',
			payload: e.target.value,
			sectionIndex,
		});

	};

	updateSubsectionTitle = (e, sectionIndex, subsectionIndex) => {
		const { dispatch } = this.props;
		dispatch({
			type: 'UPDATE_SUBSECTION_TITLE',
			payload: e.target.value,
			sectionIndex,
			subsectionIndex,
		});
	};

	updateTemplateName = e => {
		if (e.target.value.length === 0) {
			e.target.style.background = '#faf2f3';
		} else {
			e.target.style.background = '';
		}
		const { dispatch } = this.props;
		return dispatch({
			type: 'UPDATE_TEMPLATE_NAME',
			payload: e.target.value,
		});
	};

	makeIndicatorRed = () => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'INDICATOR_RED',
		});
	};

	addSubsection = (sectionIndex, subsectionIndex, subsectionId) => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'ADD_SUBSECTION',
			sectionIndex,
			subsectionIndex,
			removeReference: true,
		});
	};

	addDocumentUpload = (sectionIndex, subsectionIndex) => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'ADD_DOCUMENT_UPLOAD',
			sectionIndex,
			subsectionIndex,
		});
	};

	addMainSection = sectionIndex => {
		const { dispatch } = this.props;
		return dispatch({
			type: 'ADD_SECTION',
			sectionIndex,
		});
	};

	closeModal = () => {
		this.setState({
			modalToShow: false
		})
	}

	openDeleteSectionModal = (sectionIndex, sectionDetails) => {
		this.setState({
			modalToShow: 'DeleteSectionModal',
			deletedSectionIndex: sectionIndex,
			deletedSubsectionIndex: null,
			deletedSection: {
				actualSectionId: sectionDetails.id,
				...sectionDetails,
			},
		})
	}

	openDeleteSubSectionModal = (sectionIndex, subsectionIndex, sectionDetails, subSectionDetails) => {
		this.setState({
			modalToShow: 'DeleteSubSectionModal',
			deletedSectionIndex: sectionIndex,
			deletedSubsectionIndex: subsectionIndex,
			deletedSection: {
				actualSectionId: sectionDetails.id,
				actualSubsectionId: subSectionDetails.id,
				...subSectionDetails,
			},
		})
	}

	openCreateVariableAction = (activeSection, activeSectionIndex, activeSubSectionIndex) => {
		const key = "editor_" + activeSectionIndex + "_" + activeSubSectionIndex;
		const activeEditor = this.state.linkedEditorsToSection[key];
		this.setActiveEditor(activeEditor);
		this.setState({
			modalToShow: 'CreateVariableModal',
			activeSection,
			activeSectionIndex,
			activeSubSectionIndex,
		})
	}
	
	openAddImageModal = (activeSection, activeSectionIndex, activeSubSectionIndex) => {
		const key = "editor_" + activeSectionIndex + "_" + activeSubSectionIndex;
		const activeEditor = this.state.linkedEditorsToSection[key];
		this.setActiveEditor(activeEditor);
		this.setState({
			modalToShow: 'uploadImageToSection',
			activeSection,
			activeSectionIndex,
			activeSubSectionIndex,
		})
	};

	openAllowFileValidationnModal = (errorObj) => {
		this.setState({
			modalToShow: 'AllowFileValidationError',
			fileValidationTitle: errorObj.messageHTML.replace(/<[^>]*>/g, '') + ' Are you sure you want to close and lose your changes?',
			errorObj: errorObj
		})
	}

	fileValidationnConfirmationModal = () => {
		const element = document.getElementById(this.state.errorObj.elementId);
		if (element) {
			element.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
		const { history } = this.props;
		return history.push('/safety-plans');
	}

	checkScroll = () => {
		const {
			route: { scrollTarget, scrollType, shouldScroll, template },
			dispatch,
		} = this.props;
		if (!shouldScroll) return null;
		document
			.getElementById(`${scrollType}-${scrollTarget}`)
			.scrollIntoView({ behavior: 'smooth' });
		return dispatch({ type: 'UNSET_SCROLL_MODE' });
	};

	onSectionChange = (item) => {
		this.setState({ visibleSubsection: null, visibleSection: item });
	};

	onSubSectionChange = (section, subSection) => {
		this.setState({ visibleSection: section, visibleSubsection: subSection });
	};

	onVisibilityChange = (section, subSection = null) => {
		if (this.state.startScrolling === true && this.state.isSectionScrollActive === true) {
			if (subSection === null) {
				this.onSectionChange(section)
			} else {
				this.onSubSectionChange(section, subSection)
			}
			scrollToSidebarSection(section.id, subSection?.id, this.sidebarRef)
		}
	}

	currentValues = {};

	getCurrentValuesFromState = () => {
		this.currentValues.visibleSection = this.state.visibleSection;
		this.currentValues.visibleSubsection = this.state.visibleSubsection;
		this.currentValues.page = this.state.page;
	}

	setCurrentValuesToState = () => {
		this.setState({
			visibleSection: this.currentValues.visibleSection,
			visibleSubsection: this.currentValues.visibleSubsection,
			page: this.currentValues.page
		});
	}

	nextPage = () => {
		this.getCurrentValuesFromState();
		new Promise((resolve) => {
			const {
				route: { template, filtered },
				dispatch,
			} = this.props;

			var lastSection = null;
			if (filtered.sections.length > 0) {
				lastSection = filtered.sections[filtered.sections.length - 1];
			}

			var nextSectionIndexToLoad = 0;
			if (lastSection) {
				template.sections.map((section, index) => {
					if (section.id === lastSection.id) {
						if (template.sections[index + 1] != undefined) {
							nextSectionIndexToLoad = index + 1;
						}
					}
				})
			}

			if (nextSectionIndexToLoad > 0) {
				const temp = template.sections[nextSectionIndexToLoad];
				filtered.sections.push(temp);
			}

			resolve(dispatch({
				type: 'NEXT_SECTION',
				payload: filtered,
			}));
		}).then((dispatchResolve) => {
			this.setCurrentValuesToState();
			return dispatchResolve;
		});
	};

	isLastSection = (sectionId) => {
		const {
			route: { template }
		} = this.props;

		const lastSection = template.sections[template.sections.length - 1];
		return lastSection.id === sectionId;
	}

	previousPage = () => {
		this.getCurrentValuesFromState();
		new Promise((resolve) => {
			const {
				route: { template, filtered },
				dispatch,
			} = this.props;

			var firstSection = null;
			if (filtered.sections.length > 0) {
				firstSection = filtered.sections[0];
			}

			var previousSectionIndexToLoad = -1;
			if (firstSection) {
				template.sections.map((section, index) => {
					if (section.id === firstSection.id) {
						if (template.sections[index - 1] != undefined) {
							previousSectionIndexToLoad = index - 1;
						}
					}
				})
			}

			if (previousSectionIndexToLoad >= 0) {
				const temp = template.sections[previousSectionIndexToLoad];
				filtered.sections = [
					temp,
					...filtered.sections,
				];
			}

			return resolve(dispatch({
				type: 'NEXT_SECTION',
				payload: filtered,
			}));

		}).then((dispatchResolve) => {
			this.setCurrentValuesToState();
			return dispatchResolve;
		});
	}

	showPage = (page) => {
		return new Promise((resolvePage) => {
			new Promise((resolve) => {
				const {
					route: { template, filtered },
					dispatch,
				} = this.props;

				filtered.sections = template.sections.filter(function (section, index) {
					return (index >= (page * sectionsPerPage - 1) && index < page * sectionsPerPage + sectionsPerPage)
						? section
						: false;
				});

				this.setPage(page);

				resolve(dispatch({
					type: 'NEXT_SECTION',
					payload: filtered,
				}));
			}).then(() => {
				resolvePage(true);
			});
		})
	}

	setPage = (page) => {
		this.setState({ page: page });
	}
	headerCustomItem = () => {
		const { viewMode } = this.state;

		const {
			history,
			route: { template}
		} = this.props;

		return <>
			{
				!viewMode && <>
					<Button
						size="lg"
						text={"View"}
						intent="default"
						icon="eye-open"
						className='breadcrumb_TitleActions'
						onClick={() => {history.push('/core/view/'+template.id)}}
					/>
					<ActionsList
						history={history}
						elementSelector={<Button type="tertiary" size="lg" icon="more" />}
						template={template}
					/>
				</>
			}
			{
				viewMode && !template.archived && <Button
					size="lg"
					text="Edit"
					intent="default"
					icon="edit"
					className='breadcrumb_TitleActions'
					onClick={() => {history.push('/core/templates/'+template.id)}}
				/>
			}
			{
				viewMode && template.archived &&
				<ActionButton
					template={template}

				/>
			}
		</>
	}

	sectionCustomItem = (sectionIndex, item) => {
		const { viewMode } = this.state;
		const {
			route: { template },
		} = this.props;
		return <>
			{ !viewMode &&
				<>
					<Button
						size="sm"
						text={"Create an update"}
						intent="default"
						className='CreateUpdateButton'
						onClick={() => this.openPublishModal(true, item,null, sectionIndex)}
					/>
					<SectionActionsList
						deleteAction={() => this.openDeleteSectionModal(sectionIndex, item)}
						createVariableAction={() => this.openCreateVariableAction(item, sectionIndex, null)}
						addImageAction={() => this.openAddImageModal(item, sectionIndex, null)}
						elementSelector={<Button type="tertiary" size="sm" icon="more" />}
						sectionType = {'section'}
						template = {template}
					/>
				</>
			}
		</>
	}

	updateAutoSave = (autoSaved) => {
		this.setState({ isAutoSaved: autoSaved });
	}


	subSectionCustomItem = (index, subindex, item, single, viewMode) => {
		const {
			route: { template },
		} = this.props;

		return <>
		{ !viewMode &&
			<>
				<Button
					size="sm"
					text={"Create an update"}
					intent="default"
					className='CreateUpdateButton'
					onClick={() => this.openPublishModal(false, item, single, index, subindex)}
				/>
				{
					single.fileSection ?
					<FeaturedIcon icon="trash" size={"sm"} shape={'roundedIcon'} type="Default" onClick={() => this.openDeleteSubSectionModal(index, subindex, item, single)} />
					:

					<SectionActionsList
						elementSelector={!item.fileSection && <Button type="tertiary" size="sm" icon="more" />}
						viewMode={viewMode}
						deleteAction={() => this.openDeleteSubSectionModal(index, subindex, item, single)}
						createVariableAction={() => this.openCreateVariableAction(single, index, subindex)}
						addImageAction={() => this.openAddImageModal(single, index, subindex)}
						sectionType = {'subsection'}
						template = {template}
					/>
				}

			</>
			}
		</>

	}


	breadCrumbCustomItem = () => {
		const {
			history,
			route: { template, indicator }
		} = this.props;
		const { isAutoSaved, viewMode } = this.state;
		return <>
			{!viewMode &&
				<>
					<Button
						size="sm"
						text={"Save and exit"}
						intent="default"
						onClick={() => this.saveTemplate(true)}
					/>

					<Badge
						hasLeadIcon={indicator ? true : false}
						leadIcon="tick"
						key={`lg-Gray`}
						type='Gray'
						size='lg'
						text={indicator ? (`Auto Saved: ` + template.dateWithTimezone) : ('Not Saved')}
					/>
				</>
			}

			{viewMode &&
				<>
					<Button
						size="sm"
						text={"Exit"}
						intent="default"
						onClick={() => history.push('/safety-plans')}
					/>
					<Badge
						hasLeadIcon={true}
						leadIcon={template.archived ? "archive" : "tick"}
						key={`lg-Gray`}
						type='Gray'
						size='lg'
						text={template.archived ? `Archived by `+ template.archivedBy?.name +' on ' + (template.archivedDate ? format(new Date(template.archivedDate.date), 'MM/dd/yyyy'):"") : `Last saved: ` + format(new Date(template.lastUpdated), 'MM/dd/yyyy')}
					/>
				</>
			}
		</>
	}

	handleMainScroll = (e) => {
		if (this.state.yPosition > e.target.scrollTop) {
			if (e.target.scrollTop < 300) {
				this.previousPage();
			}
		}
		this.setState({ yPosition: e.target.scrollTop });
		if (this.state.startScrolling === false) {
			this.setState({
				startScrolling: true
			});
		}
	};

	handleBackToTop = () => {
		// Get the first section
		const {
			route: { template },
		} = this.props;
		const firstSection = template.sections[0];
		this.onSectionChange(firstSection)
		this.setState({
			isSectionScrollActive: false,
		})

		this.mainWrapperRef.current.scrollTo({
			top: 0
		})
		this.sidebarRef.current.scrollTo({
			top: 0
		})
		window.scrollTo(0, 0);
	}

	scrollToSection = (section, subSection, type, page) => {
		this.setPage(page);
		let sectionId = `${type}-${section.id}`;
		if (subSection !== null) {
			sectionId += `-${subSection.id}`;
		}
		let element = document.getElementById(sectionId);
		this.setState({
			isSectionScrollActive: false
		})
		if (element) {
			this.scrollToElement(element);
			if (type === 'subsection') {
				this.onSubSectionChange(section, subSection)
			} else {
				this.onSectionChange(section)
			}
		} else {
			// The element is not loaded yet, so, load the page that includes the element
			this.showPage(page).then((pageLoaded) => {
				if (pageLoaded) {
					let newElement = document.getElementById(sectionId);
					if (newElement) {
						this.scrollToElement(newElement);
						if (type === 'subsection') {
							this.onSubSectionChange(section, subSection)
						} else {
							this.onSectionChange(section)
						}
					}
				}
			});
		}
	};

	scrollToElement = (element) => {
		element.scrollIntoView({
			block: 'start',
			behavior: 'smooth',
		});
	}

	componentDidUpdate() {
		this.checkScroll();
	}

	templateTabsChanges = async (val) => {
		const targetPanel = val == 0 ? 'contextualeditor' : 'EventLogs'
		if(targetPanel === 'contextualeditor' && this.state.panel !== 'contextualeditor') {
			//scroll to active visible section/subsection
			const visibleSection = this.state.visibleSection;
			const visibleSubsection = this.state.visibleSubsection;
			await this.scrollToSection(visibleSection, visibleSubsection, visibleSubsection == null ? 'section' : 'subsection', this.state.page)
		}
		this.setState({ panel: targetPanel })
	};

	componentDidMount() {
		const { viewMode } = this.props;
		if (viewMode === true) {
			this.setState({
				viewMode: true
			});
		}

		return this.loadData(true);
	}

	toggleSectionScroll = value => {
		this.setState({
			isSectionScrollActive: value
		})
	}

	onDeleteAndPublishSection = async () => {
		const { deletedSection } = this.state;
		const {
			match: {
				params: { id: templateId },
			},
		} = this.props;
    const updateBody = {
      type: 'delete',
      updatedContent: JSON.stringify(deletedSection),
      actualSectionId : deletedSection.actualSectionId,
      actualSubsectionId: deletedSection.actualSubsectionId,
    }
		await TemplateService.publishUpdates(templateId, updateBody)
			.then(response => {
				if (response.statusCode === 201) {
				} else {
					fireSwal('error', response.error.description)
				}
			})
	}

	onWheelHandler = () => {
		if (this.state.isSectionScrollActive === false) {
			this.toggleSectionScroll(true)
		}
	}

	componentWillUnmount() {
		const {
			route: { templateLoading },
		} = this.props;
		const templateSaved = this.state.templateSavedBeforeUnmount;
		const loading = this.state.loading;
		if (!templateSaved && !loading && !templateLoading) {
			this.saveTemplate(false);
		}
	}

	linkEditorsToSections = (activeEditor, sectionIndex, subsectionIndex) => {
		const key = "editor_" + sectionIndex + "_" + subsectionIndex;
		const tempLinkedEditorsToSection = this.state.linkedEditorsToSection
		tempLinkedEditorsToSection[key] = activeEditor;
		this.setState({ linkedEditorsToSection: tempLinkedEditorsToSection})
	}

	setActiveEditor = activeEditor => {
		this.setState({activeEditor})
	}

	render() {
		const {
			loading,
			panel,
			visibleSection,
			visibleSubsection,
			sectionData,
			activeEditor
		} = this.state;
		const { nodes, modalToShow, deletedSectionIndex, deletedSubsectionIndex, viewMode, fileValidationTitle,deletedSection } = this.state;
		const {
			route: { template, indicator, templateLoading },
			match: {
				params: { id: templateId },
			},
			history: {
				location: { pathname },
			},
			dispatch,
		} = this.props;

		if (templateLoading || loading) return <LoadingSpinner />;

		const breadcrumbItems = [
			{
				label: 'Safety plan templates',
				url: '/safety-plans',
				onClick: () => this.saveTemplate(true)
			},
			{
				label: viewMode ? 'Safety plan template viewer' : 'Safety plan template editor'
			}
		];
		const cancelBtn = { title: 'No, go back', onClick: this.closeModal };
		const confirmBtn = { title: 'Yes, continue', onClick: this.fileValidationnConfirmationModal };
		const warningIcon = { icon: 'warning-sign', iconColor: 'warning' }
		const confirmPublishBtn = { title: 'Yes, publish', onClick: this.publishUpdate };

		const tabs = [
			{
				id: "contextualeditor",
				title: "Text editor",
				panel: null,
				index: 0
			},
			{
				id: "EventLogs",
				title: "Event log",
				panel: null,
				index: 1
			}
		];
		const { history } = this.props;
		return (
			<>
				<SafetyplansProvider
					reloadData={this.loadData}
					history={history}
				>
					{modalToShow === 'AllowFileValidationError' && (
						<ConfirmationModal
							isOpen={true}
							title={fileValidationTitle}
							subtitle=''
							showCloseButton={false}
							cancelBtn={cancelBtn}
							confirmBtn={confirmBtn}
							btnsSize="lg"
							icon={warningIcon}
						/>
					)}
					{modalToShow  === 'ShowCoreUpdateModal' && (
						<PublishModal
							isOpen={true}
							title={`Are you sure you want to create an update for ""?`}
							subtitle=''
							showCloseButton={false}
							cancelBtn={cancelBtn}
							confirmBtn={confirmPublishBtn}
							loading={loading}
							btnsSize="lg"
							sectionData={sectionData}
							template={template}
							closeModal={this.closeModal}

						/>
					)}

					{modalToShow === 'DeleteSectionModal' && (
						<DeleteSectionModal
						    isOpen={true}
							type='section'
							deletedSection = {deletedSection}
							sectionIndex={deletedSectionIndex}
							subsectionIndex={deletedSubsectionIndex}
							closeDeleteModal={this.closeModal}
							onDeleteAndPublishSection={this.onDeleteAndPublishSection}
							scrollToSection={this.scrollToSection}
							templateSections={template.sections}
						/>
					)}
					{modalToShow === 'DeleteSubSectionModal' && (
						<DeleteSectionModal
							isOpen={true}
							type='sub-section'
							deletedSection ={deletedSection}
							sectionIndex={deletedSectionIndex}
							subsectionIndex={deletedSubsectionIndex}
							closeDeleteModal={this.closeModal}
							onDeleteAndPublishSection={this.onDeleteAndPublishSection}
							scrollToSection={this.scrollToSection}
							templateSections={template.sections}
						/>
					)}

					{modalToShow === 'CreateVariableModal' && (
						<CreateVariableModal
							isOpen={true}
							onClose={this.closeModal}
							template={template}
							activeSection={this.state.activeSection}
							sectionIndex={this.state.activeSectionIndex}
							subsectionIndex={this.state.activeSubSectionIndex}
							updateNodes={this.updateNodes}
							reloadData={this.loadData}
							activeEditor={activeEditor}
						/>
					)}
					{modalToShow === 'uploadImageToSection' && (
						<UploadImage
							isOpen={true}
							closeModal={this.closeModal}
							activeSection={this.state.activeSection}
							sectionIndex={this.state.activeSectionIndex}
							subsectionIndex={this.state.activeSubSectionIndex}
							updateNodes = {this.updateNodes}
							activeEditor={activeEditor}
						/>
					)}

					<div className='template_layout_Wrapper'>
						<div className="stickyHeader_Container">
							<BreadCrumbHeader
								title={template.name}
								breadcrumbItems={breadcrumbItems}
								customItem={this.headerCustomItem}
								breadCrumbCustomItem={this.breadCrumbCustomItem}
							/>
						</div>
						<div className="template_bodyContainer">
							<CustomTabs
								renderActiveTabPanelOnly={true}
								identifier="addPlanTabs"
								onChangeHandler={(e) => { this.templateTabsChanges(e) }}
							>
								{tabs.map((tab) => (
									<Tab
										id={tab.index}
										key={tab.index}
										title={tab.title}
										panel={null}
										className="CustomTabs__Tab"
									/>
								))}
							</CustomTabs>

							{panel === 'contextualeditor' && (
								<div className='main_Container'>
									<div
										id='main'
										style={{
											width: '100%',
											height: '100%',
											overflow: 'scroll',
										}}
										ref={this.mainWrapperRef}
										onScroll={this.handleMainScroll}
										onWheelCapture={this.onWheelHandler}
									>
										<div id="top-element"></div>
										<BackToTopButton
											onClick={this.handleBackToTop}
											containerId="main"
											topElementId="top-element"
											elementOnRightId="sidebar-wrapper"
											show={this.state.showBackToTopButton}
										/>

										<div panel={panel} className="textarea_Container">
											{panel === 'contextualeditor' &&
												<>
													{template.sections.map((item, index) => (
														<VisibilitySensor
															onChange={() =>
																this.onVisibilityChange(item)
															}
															key={`section-${item.id}`}
															scrollCheck
															partialVisibility="top"
															offset={{top: index > 0 ? 380 : 0 }}
														>
															<React.Fragment key={item.id}>
																<div className='frame_wrapper'>
																	<SectionSubSectionContainer
																		item={item}
																		type="section"
																		id={`section-${item.id}`}
																		readOnly={viewMode}
																		onTitleChange={e =>
																			this.updateSectionTitle(
																				e,
																				index,
																			)
																		}
																		sectionCustomItem={this.sectionCustomItem(index, item,template.sections.length)}
																		viewMode={viewMode}
																		makeIndicatorRed={this.makeIndicatorRed}
																		indicator={indicator}
																		sectionIndex={index}
																		subsectionIndex={null}
																		updateNodes={this.updateNodes}
																		content={item.content}
																		templateId={templateId}
																		saveAndReload={this.saveAndReload}
																		actualSectionId={item.id}
																		template={template}
																		subsection={item}
																		dispatch={dispatch}
																		setActiveEditor={this.setActiveEditor}
																		linkEditorsToSections={this.linkEditorsToSections}
																		page={this.state.page}
																		contentType="corePlan"
																		updateAutoSave={this.updateAutoSave}
																		saveTemplate={this.saveTemplate}
																		modalToShow={modalToShow}
																		updateEditor={false}
																	/>

																</div>
																{item.subsections.map(
																	(single, subindex) => (
																		<VisibilitySensor
																			onChange={() =>
																				this.onVisibilityChange(item, single)
																			}
																			key={`subsection-${subindex}-${single.id}`}
																			scrollCheck
																			partialVisibility="top"
																			offset={{top:380}}
																		>
																			<React.Fragment key={single.id}>
																				<div className='frame_wrapper'>

																					<SectionSubSectionContainer
																						item={single}
																						type="subsection"
																						readOnly={viewMode}
																						id={`subsection-${item.id}-${single.id}`}
																						onTitleChange={e =>
																							this.updateSubsectionTitle(
																								e,
																								index,
																								subindex
																							)
																						}
																						setActiveEditor={this.setActiveEditor}
																						linkEditorsToSections={this.linkEditorsToSections}
																						saveTemplate={this.saveTemplate}
																						sectionCustomItem={this.subSectionCustomItem(index, subindex, item, single, viewMode)}
																						viewMode={viewMode}
																						makeIndicatorRed={this.makeIndicatorRed}
																						indicator={indicator}
																						sectionIndex={index}
																						subsectionIndex={subindex}
																						updateNodes={this.updateNodes}
																						content={single.content}
																						templateId={templateId}
																						actualSubsectionId={single.id}
																						actualSectionId={item.id}
																						template={template}
																						subsection={single}
																						saveAndReload={this.saveAndReload}
																						dispatch={dispatch}
																						contentType="corePlan"
																						updateAutoSave={this.updateAutoSave}
																						modalToShow={modalToShow}
																						updateEditor={false}
																					/>


																					{/* {!single.fileSection && (
																			<></>
																		)} */}
																					{/* {single.fileSection && (
																			<FileSection
																				viewMode={viewMode}
																				makeIndicatorRed={
																					this
																						.makeIndicatorRed
																				}
																				saveAndReload={this.saveAndReload}
																				indicator={indicator}
																				sectionIndex={index}
																				subsectionIndex={
																					subindex
																				}
																				updateNodes={
																					this.updateNodes
																				}
																				subsection={single}
																				dispatch={dispatch}
																				templateId={templateId}
																				actualSubsectionId={
																					single.id
																				}
																				actualSectionId={
																					item.id
																				}
																				page={this.state.page}
																				type="corePlan"
																				template={template}
																			/>
																		)} */}
																				</div>
																			</React.Fragment>
																		</VisibilitySensor>
																	))}
																{!viewMode && (
																	<>
																		<LinkButton
																			className="pb-2"
																			icon="add"
																			text="Add subsection"
																			intent="secondary"
																			onClick={() =>
																				this.addSubsection(
																					index,
																					(item.subsections?.length - 1) || 0,
																					(item.subsections?.length > 0) ? item.subsections[item.subsections.length - 1].id : null,
																				)
																			}
																		/>
																		<LinkButton
																			className="pb-2"
																			icon="add"
																			text="Add document upload"
																			intent="secondary"
																			onClick={() =>
																				this.addDocumentUpload(
																					index,
																					(item.subsections?.length - 1) || 0,
																					(item.subsections?.length > 0) ? item.subsections[item.subsections.length - 1].id : null,
																				)
																			}
																		/>
																		<br />
																	</>

																)}
																{template.sections.length - 1 === index && !viewMode && (
																	<>
																		<Split />
																		<div className="d-flex justify-content-around">
																			<LinkButton
																				className="pb-2"
																				icon="add"
																				text="Add main section"
																				minimal
																				onClick={() =>
																					this.addMainSection(
																						index,
																					)
																				}
																			/>
																		</div>
																	</>
																)}
															</React.Fragment>
														</VisibilitySensor>
													))}

												</>
											}
										</div>
									</div>
									<div id='sidebar-wrapper' className='addplan-sidebar-container'>
										<PlanOutline
											nodes={nodes}
											updateNodes={this.updateNodes}
											loading={templateLoading}
											scrollToSection={this.scrollToSection}
											visibleSection={visibleSection}
											visibleSubsection={visibleSubsection}
											sectionsPerPage={sectionsPerPage}
											setPage={this.setPage}
											sidebarRef={this.sidebarRef}
											viewMode={viewMode}
											planType="corePlan"
										/>
									</div>
								</div>
							)}

							{panel === 'EventLogs' && (
								<EventLog templateId={templateId} />
							)}
						</div>
					</div>
				</SafetyplansProvider>
			</>
		);
	}
}

CoreTemplateDetails.propTypes = {
	dispatch: PropTypes.func,
	match: PropTypes.object,
	history: PropTypes.object,
	route: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(CoreTemplateDetails);
