import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon } from '@blueprintjs/core';
import Swal from 'sweetalert2';
import { SectionsRow } from './sectionsrow';
import AttachModal from './attachmodal';

const TableWrapper = styled.div`
	display: flex;
`;

const TableTitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	margin-bottom: 0;
`;

const TableContent = styled.table`
	thead {
		tr {
			border-top: 0 !important;
			th {
				border: 0 !important;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				color: #738694;
			}
		}
	}
	tbody {
		tr {
			vertical-align: middle;
			th {
				font-weight: normal;
				vertical-align: middle;
			}
			td {
				&:nth-child(2),
				&:nth-child(3) {
					text-transform: capitalize;
				}
				vertical-align: middle;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				color: #10161a;
			}
			&:last-child {
				border-bottom: 1px solid #dee2e6;
			}
		}
	}
`;

const Callout = styled.div`
	display: flex;
	align-items: center;
	background: #e8f2f9;
	height: 42px;
	width: 99%;
	margin-bottom: 16px;
`;

const Wrapper = styled.div`
	margin-left: -15px;
	margin-right: -15px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
	background: #fff;
`;

class SectionsTable extends React.Component {
	state = {};

	state = {
		showAttachModal: false,
		showFile: [],
	};

	setFileValue = item => {
		const { showFile } = this.state;
		this.setState({
			showFile: [...showFile, item],
		});
	};

	closeAttachModal = () => this.setState({ showAttachModal: false });

	setDeleteFile = id => {
		Swal.fire('Removing');
		Swal.showLoading();
		const { assessmentId } = this.props;
		const { showFile } = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;

		fetch(
			`${process.env.API_URL}/assessments/${assessmentId}/files/${id}`,
			{
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					});
					const temp = showFile.filter(item => item.id !== id);
					this.setState({
						showFile: temp,
					});
				}
			});
	};

	render() {
		const {
			sections,
			assessmentId,
			history,
			getData,
			assessmentSummary,
			name,
			year,
			editAssess,
		} = this.props;
		const { showAttachModal, showFile } = this.state;

		return (
			<Wrapper>
				<Callout className=".bg-primary">
					<Icon
						style={{
							marginRight: 10,
							marginLeft: 10,
							color: '#137CBD',
						}}
						icon="info-sign"
					/>
					You are currently creating an assessment with the {name} for
					the {year} - {year + 1} school year. Sections tab shows all
					the current sections. Tasks tab shows the number of
					currently opened or closed tasks.
				</Callout>
				{showAttachModal && (
					<AttachModal
						isOpen
						fileName={showFile}
						setFileValue={this.setFileValue}
						closeAttachModal={this.closeAttachModal}
						id={assessmentId}
					/>
				)}
				{assessmentSummary && (
					<div className="d-flex">
						<strong className="mr-2">Summary:</strong>
						<div
							dangerouslySetInnerHTML={{
								__html: assessmentSummary,
							}}
						/>
					</div>
				)}
				<div style={{ display: 'flex' }}>
					{showFile.length > 0 &&
						showFile.map(item => (
							<div style={{ marginBottom: 10, marginLeft: 5 }}>
								<Button
									style={{
										borderRadius: 15,
										fontSize: 10,
										color: '#394B59',
										marginRight: 1,
										width: 'auto',
										boxShadow: 'none',
									}}
									rightIcon="small-cross"
									onClick={() => this.setDeleteFile(item.id)}
								>
									{item.fileName}
								</Button>
							</div>
						))}
					<div style={{ marginBottom: 10, marginLeft: 5 }}>
						<Button
							style={{
								borderRadius: 15,
								fontSize: 12,
								width: 'auto',
								background: 'transparent',
							}}
							icon="small-plus"
							onClick={() =>
								this.setState({
									showAttachModal: true,
								})
							}
						>
							Add attachment
						</Button>
					</div>
				</div>
				<TableWrapper>
					<TableContent
						className="table"
						style={{ backgroundColor: '#fff' }}
					>
						<thead>
							<tr>
								<td>
									<TableTitle
										style={{
											color: '#738694',
										}}
									>
										Sections
									</TableTitle>
								</td>
								<td>
									<div className="d-flex">
										<TableTitle
											style={{
												color: '#738694',
											}}
										>
											Progress
										</TableTitle>
										<Icon
											style={{
												color: '#738694',
											}}
											icon="caret-down"
										/>
									</div>
								</td>
								<td>
									<div className="d-flex">
										<TableTitle
											style={{
												color: '#738694',
											}}
										>
											Last updated
										</TableTitle>
										<Icon
											style={{
												color: '#738694',
											}}
											icon="caret-down"
										/>
									</div>
								</td>
								<td>
									<div className="d-flex">
										<TableTitle
											style={{
												color: '#738694',
											}}
										>
											Status
										</TableTitle>
										<Icon
											style={{
												color: '#738694',
											}}
											icon="caret-down"
										/>
									</div>
								</td>
								<td>
									<TableTitle
										style={{
											color: '#738694',
										}}
									>
										Actions
									</TableTitle>
								</td>
							</tr>
						</thead>
						<tbody>
							{sections.map(item => (
								<SectionsRow
									item={item}
									key={item.id}
									assessmentId={assessmentId}
									history={history}
									getData={getData}
									editAssess={editAssess}
								/>
							))}
						</tbody>
					</TableContent>
				</TableWrapper>
			</Wrapper>
		);
	}
}

SectionsTable.propTypes = {
	sections: PropTypes.any,
	assessmentId: PropTypes.string,
	history: PropTypes.object,
	getData: PropTypes.func,
	assessmentSummary: PropTypes.string,
	name: PropTypes.string,
	year: PropTypes.number,
	editAssess: PropTypes.bool,
};

export default SectionsTable;
