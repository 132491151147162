import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';

const Checkbox = ({
	label,
	onChange,
	checked = false,
	...props
}) => {
	const [isChecked, setIsChecked] = useState(checked);

	return (		
		<div className='label-form'>
			<label>
				<input type="checkbox" className='checkbox-input' onChange={e => {
						setIsChecked(!isChecked)
						onChange(e.target.checked)
					}} checked={isChecked} />
				<span>{label}</span>
			</label>
		</div>
	);
}

Checkbox.propTypes = {
	onChange: PropTypes.func,
	label: PropTypes.string,
	checked: PropTypes.bool,
};

export { Checkbox };
