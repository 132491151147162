import React, { useState, useEffect, useContext } from 'react';
import NormalModal from 'components/Modals/NormalModal';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import { FirstRespondersService } from 'services/FirstRespondersService';
import MultiStepHeaderModal from 'components/Modals/MultiStepHeaderModal';
import { Intent } from '@blueprintjs/core';
import FirstResponderStepsHandler from './FirstResponderStepsHandler';
import { ToasterContext } from '../../../App';
import { isValidEmail } from 'utilities/validations';

const AddFirstResponderModal = ({
	loggedUser,
	onCloseModal,
	sitesFRList,
	selectedUserSites,
	fetchFirstResponders,
}) => {
	const [validationErrors, setErrors] = useState({});
	const [resetDisableHandler, setresetDisableHandler] = useState(true);
	const [changesOccuredFlag, setChangesOccuredFlag] = useState(0);
	const [showConfirmPopModal, setShowConfirmPopModal] = useState(false);
	const [agencies, setAgencies] = useState([]);
	const [currentStep, setCurrentStep] = useState(1);
	const [confirmBtnTitle, setConfirmBtnTitle] = useState('Next');
	const [disableBtns, setDisableBtns] = useState(false);
	const [barsColors, setbarsColors] = useState(['#007E8F', '#D8E1E8', '#D8E1E8']);
	const [barsTitles, setBarsTitles] = useState('Step 1 - Agency Search');
	const [zipCode, setZipCode] = useState('');
	const [loading, setLoading] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [allowedEditSites, setAllowedEditSites] = useState([]);
	const [allowedSites, setAllowedSites] = useState([]);
	const [defaultSelectedAllowedSites, setDefaultSelectedAllowedSites] = useState([]);
	const [backBtn, setBackBtn] = useState(false);
	const context = useContext(ToasterContext);
	const [formData, setFormData] = useState({
		zip_code: zipCode,
		radius: 5,
		agency: '',
		first_name: '',
		last_name: '',
		email: '',
		mobile_phone: '',
		work_phone: '',
		home_phone: '',
		extension: '',
		lat: null,
		lon: null,
		address: '',
		google_ref: '',
	});

	const [defaultAccessedSites, setDefaultAccessedSites] = useState(selectedUserSites);
	const [existResponderAccessedSites, setExistResponderAccessedSites] = useState([]);
	const [firstResponderData, setFirstResponderData] = useState([]);

	useEffect(() => {
		const allowedEditSites = [];
		const filterSites = [];
		const filteredSites = sitesFRList.filter(site => loggedUser.primaryBuilding.id == site.id);

		sitesFRList.forEach(data => {
			allowedEditSites.push(data.label);
		});
		setAllowedEditSites(allowedEditSites);
		selectedUserSites.forEach(data => {
			if (allowedEditSites.includes(data.label)) {
				filterSites.push(data);
			}
		});
		setDefaultAccessedSites(filterSites);
		setDefaultSelectedAllowedSites(filterSites);
		setExistResponderAccessedSites(filterSites);

		if (filteredSites.length !== 0) {
			setZipCode(parseInt(filteredSites[0]['zip']));
			setFormData({
				...formData,
				['zip_code']: filteredSites[0]['zip'],
			});
		} else {
			setDisableBtns(true);
		}
	}, []);

	const [highlightedIndex, setHighlightedIndex] = useState(-1);
	const [selectedMiles, setSelectedMiles] = useState({ value: '5', label: '5 miles' });
	const [selectedIndex, setSelectedIndex] = useState('');
	const [resetTitle, setResetTitle] = useState('Reset');
	const [responderByEmailExist, setResponderByEmailExist] = useState(false);

	const closeConfirmPopUpModal = () => {
		setShowConfirmPopModal(false);
	};

	const resetForm = () => {
		switch (currentStep) {
			case 1:
				setFormData({
					zip_code: zipCode,
				});
				setSelectedMiles({ value: '5', label: '5 miles' });
				setDisableBtns(false);
				break;
			case 2:
				setFormData({
					...formData,
					agency: '',
					team_id: '',
				});
				setDisableBtns(true);
				setSelectedIndex('');
				setHighlightedIndex(-1);
				break;
			case 3:
				setFormData({
					...formData,
					first_name: '',
					last_name: '',
					sites_ids: [],
					email: '',
					mobile_phone: '',
					work_phone: '',
					home_phone: '',
					extension: '',
					agency: '',
				});
				break;
		}

		setErrors({});
		setresetDisableHandler(true);
	};

	const onSelectSitesHandler = sites => {
		setDefaultAccessedSites(sites);
		if (sites.length == 0) {
			setDisableBtns(true);
		} else {
			setDisableBtns(false);
		}
	};

	const existMailSitesHandler = sites => {
		setExistResponderAccessedSites(sites);
		if (sites.length == 0) {
			setDisableBtns(true);
		} else {
			setDisableBtns(false);
		}
	};

	const getAgencies = async () => {
		setLoading(true);
		FirstRespondersService.fetchAgencies(formData.zip_code, selectedMiles)
			.then(resp => {
				var response = resp.data;
				var agenciesList = [];
				var allowedSitesObj = '';
				for (var key in response) {
					var allowedSites = [];
					for (var ckey in response[key]['allowed_sites']) {
						allowedSitesObj = {
							label: response[key]['allowed_sites'][ckey],
							name: response[key]['allowed_sites'][ckey],
							value: parseInt(ckey),
							id: ckey,
						};

						allowedSites.push(allowedSitesObj);
					}

					response[key]['allowed_sites_array'] = allowedSites;
					agenciesList.push(response[key]);
				}
				setAgencies(agenciesList);
				setLoading(false);
			})
			.catch(() => {
				context('Failed Fetching Agencies!', Intent.DANGER);
				setLoading(false);
			});

		next();
	};

	const validate = async () => {
		const errors = {};

		if (!formData.first_name.trim()) {
			errors.firs_name = '*First name is required.';
		}
		if (!formData.last_name.trim()) {
			errors.last_name = '*Last name is required.';
		}
		if (
			formData.mobile_phone &&
			formData.mobile_phone.length > 0 &&
			formData.mobile_phone.length < 10
		) {
			errors.mobilePhone = '*Mobile phone is not valid.';
		}

		if (
			formData.work_phone &&
			formData.work_phone.length > 0 &&
			formData.work_phone.length < 10
		) {
			errors.workPhone = '*Work phone is not valid.';
		}

		if (
			(!formData.work_phone && formData.extension) ||
			(formData.work_phone &&
				formData.work_phone.trim().length <= 0 &&
				formData.work_phone &&
				formData.work_phone.length > 0)
		) {
			errors.workPhone = '*Work phone number is required when extension is filled.';
		}

		if (
			formData.home_phone &&
			formData.home_phone.length > 0 &&
			formData.home_phone.length < 10
		) {
			errors.homePhone = '*Home phone is not valid.';
		}

		if (!formData.email.trim()) {
			errors.email = '*Email is required.';
		} else if (!isValidEmail(formData.email)) {
			errors.email = '*Email is not valid.';
		}
		return errors;
	};

	const handleSubmit = () => {
		validate().then(errors => {
			if (Object.keys(errors).length === 0) {
				if (firstResponderData.length != 0) {
					const newFormData = {
						first_name: firstResponderData['primary_given_name'],
						last_name: firstResponderData['primary_last_name'],
						email: formData['email'],
						work_phone: firstResponderData['work_number'],
						mobile_phone: firstResponderData['mobile_number'],
						home_phone: firstResponderData['home_number'],
						google_ref: firstResponderData['team_identifier'],
						extension: firstResponderData['work_ext_number'],
						lat: firstResponderData['lat'],
						lon: firstResponderData['lan'],
						state: firstResponderData['team_state'],
						city: firstResponderData['team_city'],
						address: firstResponderData['team_address'],
						team_id: firstResponderData['team_id'],
						agency: firstResponderData['team_name'],
					};
					setDefaultAccessedSites(existResponderAccessedSites);
					submitHandler(newFormData, existResponderAccessedSites);
				} else {
					if (formData['team_id'] != '') {
						if (formData.is_new) {
							//First responder exist but not assigned to any site
							if (formData['pre_email'] == formData['email']) {
								submitHandler(formData, defaultAccessedSites);
							} else {
								// User changed the email
								emailCheckHandler();
							}
						} else {
							submitHandler(formData, defaultAccessedSites);
						}
					} else {
						emailCheckHandler();
					}
				}
			} else {
				const err = {};

				if ('workPhone' in errors) {
					err.msg = 'Work phone is not valid';
				} else if ('email' in errors) {
					err.msg = '*Email is required.';
				} else if ('firs_name' in errors) {
					err.msg = '*First Name is required';
				} else if ('last_name' in errors) {
					err.msg = '*Last Name is required';
				}

				context(err.msg, Intent.DANGER);
				setErrors(errors);
			}
		});
	};

	const emailCheckHandler = () => {
		setLoading(true);

		FirstRespondersService.getByEmail(formData).then(resp => {
			if (resp.data.length != 0) {
				var allowedSitesObj = '';
				setResponderByEmailExist(true);
				setFirstResponderData(resp.data);
				var allowedSites = [];
				var response = resp.data;
				for (var ckey in response['linked_sites_data']) {
					allowedSitesObj = {
						label: response['linked_sites_data'][ckey],
						name: response['linked_sites_data'][ckey],
						value: parseInt(ckey),
						id: ckey,
					};

					allowedSites.push(allowedSitesObj);
				}
				setExistResponderAccessedSites(allowedSites);
				setConfirmBtnTitle('Add');
			} else {
				setFirstResponderData([]);
				setResponderByEmailExist(false);
				submitHandler(formData, defaultAccessedSites);
			}

			setLoading(false);
		});
	};

	const submitHandler = (formData, defaultAccessedSites) => {
		setModalLoading(true);

		FirstRespondersService.addResponder(formData, defaultAccessedSites)
			.then(resp => {
				if (resp.statusCode === 201 || resp.statusCode === 200) {
					if (formData.team_id != '') {
						context('First responder successfully updated.', Intent.SUCCESS);
					} else {
						context('Successfully created a new first responder.', Intent.SUCCESS);
					}
				}

				onCloseModal();
				fetchFirstResponders();
				setModalLoading(false);
			})
			.catch(err => {
				const errors = {};
				errors.msg = 'Something went wrong!';
				if (err.error.hasOwnProperty('fields')) {
					if ('contact_first_name' in err.error.fields) {
						errors.first_name = 'First Name is required';
						errors.msg = 'First Name is required';
					}
					if ('contact_last_name' in err.error.fields) {
						errors.last_name = 'Last Name is required';
						errors.msg = 'Last Name is required';
					}
					if ('contact_email' in err.error.fields) {
						errors.msg = 'Email already exists';
						errors.email =
							'*Email already exists. Verify user is not in the system or enter a different email.';
					}

					if ('contact_mobile_phone' in err.error.fields) {
						errors.msg = 'Mobile phone is not valid';
						errors.mobilePhone = '*Mobile phone is not valid.';
					}

					if ('contact_work_phone' in err.error.fields) {
						errors.msg = 'Work phone is not valid';
						errors.workPhone = '*Work phone is not valid.';
					}

					if ('contact_home_phone' in err.error.fields) {
						errors.msg = 'Home phone is not valid';
						errors.homePhone = '*Home phone is not valid.';
					}
					if ('contact_work_phone_ext' in err.error.fields) {
						errors.msg = 'Work phone extension is required.';
					}
				}

				setErrors(errors);
				setModalLoading(false);
				context(errors.msg, Intent.DANGER);
			});
	};

	const cancelBtnClickHandler = () => {
		if (changesOccuredFlag == 1) {
			setShowConfirmPopModal(true);
		} else {
			onCloseModal();
		}
	};

	const updateFormData = (key, value, index) => {
		setFormData({
			...formData,
			[key]: value,
		});

		setHighlightedIndex(index);
		setChangesOccuredFlag(1);
		setDisableBtns(false);
		if (currentStep == 1) {
			if (formData.zip_code == '' || formData.zip_code.length < 5) {
				setDisableBtns(true);
			}
		}
		if (currentStep == 3) {
			if (
				formData.first_name == '' ||
				formData.last_name == '' ||
				formData.email == '' ||
				defaultAccessedSites.length == 0
			) {
				setDisableBtns(true);
			}
			if (
				formData.first_name == '' &&
				formData.last_name == '' &&
				formData.email == '' &&
				formData.mobile_phone &&
				formData.work_phone &&
				formData.home_phone &&
				formData.extension
			) {
				setresetDisableHandler(true);
			} else {
				setresetDisableHandler(false);
			}
		}

		if (currentStep == 2) {
			if (index === undefined && key == 'agency') {
				setDisableBtns(true);
			} else {
				const filterSites = [];
				const isNew = {};
				setSelectedIndex(index);
				setDisableBtns(false);

				if (agencies[index]['pre_assigned']) {
					if (agencies[index]['allowed_sites'].length != 0) {
						agencies[index]['allowed_sites_array'].forEach(data => {
							if (sitesFRList.some(val => val.id == data.id)) {
								filterSites.push(data);
							}
						});
						isNew.current = false;
						setDefaultAccessedSites(filterSites);
					} else {
						setDefaultAccessedSites(defaultSelectedAllowedSites);
						isNew.current = true;
					}

					setDisableBtns(false);
					setFormData({
						...formData,
						first_name: agencies[index]['contact_first_name'],
						last_name: agencies[index]['contact_last_name'],
						email: agencies[index]['contact_email'],
						work_phone: agencies[index]['work_number'],
						mobile_phone: agencies[index]['mobile_number'],
						home_phone: agencies[index]['home_number'],
						google_ref: agencies[index]['google_reference'],
						extension: agencies[index]['work_ext_number'],
						lat: agencies[index]['lat'],
						lon: agencies[index]['lan'],
						state: agencies[index]['team_state'],
						city: agencies[index]['team_city'],
						address: agencies[index]['composite_address'],
						team_id: agencies[index]['team_id'],
						agency: agencies[index]['name'],
						is_new: isNew.current,
						pre_email: agencies[index]['contact_email'],
					});
				} else {
					setDefaultAccessedSites(defaultSelectedAllowedSites);

					setFormData({
						...formData,
						first_name: '',
						last_name: '',
						email: '',
						work_phone: '',
						mobile_phone: '',
						home_phone: '',
						extension: '',
						lat: agencies[index]['lat'],
						lon: agencies[index]['lan'],
						google_ref: agencies[index]['google_reference'],
						state: agencies[index]['team_state'],
						city: agencies[index]['team_city'],
						address: agencies[index]['composite_address'],
						team_id: '',
						agency: agencies[index]['name'],
					});
				}
			}
		}
	};
	const next = () => {
		setCurrentStep(currentStep + 1);
	};
	const back = () => {
		setErrors({});
		if (firstResponderData.length != 0) {
			setFirstResponderData([]);
			setResponderByEmailExist(false);
			setCurrentStep(2);
			setConfirmBtnTitle('Save');
			setDisableBtns(false);
		} else {
			setCurrentStep(currentStep - 1);
			setDisableBtns(false);
		}
	};

	const backToLatest = () => {
		setResponderByEmailExist(false);
		setFirstResponderData([]);
	};

	const confirmAction = () => {
		switch (currentStep) {
			case 1:
				return getAgencies(['#007E8F', '#D8E1E8', '#D8E1E8']);
			case 2:
				return next();
			case 3:
				return handleSubmit();
		}
	};
	const cancelAction = () => {
		switch (currentStep) {
			case 1:
				return cancelBtnClickHandler();
			default:
				return back();
		}
	};
	useEffect(() => {
		switch (currentStep) {
			case 1:
				if (formData.zip_code == '') {
				} else {
					setDisableBtns(false);
				}
				setBackBtn(false);
				setConfirmBtnTitle('Search');
				setbarsColors(['#007E8F', '#D8E1E8', '#D8E1E8']);
				setBarsTitles('Step 1 - Agency Search');
				setResetTitle('Reset');
				break;
			case 2:
				if (selectedIndex === undefined || selectedIndex == '') {
					setDisableBtns(true);
				} else {
					setDisableBtns(false);
				}
				setBackBtn(true);
				setConfirmBtnTitle('Select');
				setbarsColors(['#007E8F', '#007E8F', '#D8E1E8']);
				setBarsTitles('Step 2 - Select Agency');
				setResetTitle(undefined);
				break;
			case 3:
				if (
					formData.first_name == '' ||
					formData.last_name == '' ||
					formData.email == '' ||
					defaultAccessedSites.length == 0
				) {
					setDisableBtns(true);
				} else {
					setDisableBtns(false);
				}

				if (formData.team_id != '') {
					setResetTitle(undefined);
					if (defaultAccessedSites.length > 0) {
						setDisableBtns(false);
					}
				} else {
					setResetTitle('Reset');
				}
				setBackBtn(true);
				setConfirmBtnTitle('Save');
				setbarsColors(['#007E8F', '#007E8F', '#007E8F']);
				setBarsTitles('Step 3 - Site and Contact Information');
		}
	}, [currentStep]);

	return (
		<>
			{showConfirmPopModal && (
				<UnsavedChangesModal
					onConfirmAction={onCloseModal}
					onCancelAction={closeConfirmPopUpModal}
					confirmBtnTitle="Continue"
					backBtnTitle="Cancel"
					header="Unsaved changes"
					message="The changes you have made have not been saved. Do you want to continue?"
				/>
			)}
			<NormalModal
				setModalToShow="FirstResponderModal"
				onConfirmAction={confirmAction}
				onCancelAction={cancelBtnClickHandler}
				header="Add First Responder Agency"
				confirmBtnTitle={confirmBtnTitle}
				cancelBtnTitle="Cancel"
				confirmBtnDisabled={disableBtns}
				thirdButtonTitle={resetTitle}
				thirdButtonIcon="reset"
				thirdButtonAction={resetForm}
				thirdBtnDisabled={resetDisableHandler}
				showBackArrow={backBtn}
				backArrowAction={back}
				onCloseAction={cancelBtnClickHandler}
				loading={modalLoading}
			>
				<MultiStepHeaderModal bars={barsColors} headerTitle={barsTitles} />
				<FirstResponderStepsHandler
					data={formData}
					next={next}
					updateFormData={updateFormData}
					selectedMiles={selectedMiles}
					validationErrors={validationErrors}
					setSelectedMiles={setSelectedMiles}
					formData={formData}
					setErrors={setErrors}
					currentStep={currentStep}
					setDisableBtns={setDisableBtns}
					highlightedIndex={highlightedIndex}
					selectedIndex={selectedIndex}
					agencies={agencies}
					loading={loading}
					back={back}
					allowedSites={allowedSites}
					onSelectSitesHandler={onSelectSitesHandler}
					selectedSites={defaultAccessedSites}
					sitesFRList={sitesFRList}
					selectedUserSites={defaultAccessedSites}
					responderByEmailExist={responderByEmailExist}
					firstResponderData={firstResponderData}
					backToLatest={backToLatest}
					existMailSitesHandler={existMailSitesHandler}
					existResponderAccessedSites={existResponderAccessedSites}
					setresetDisableHandler={setresetDisableHandler}
				/>
			</NormalModal>
		</>
	);
};

export default AddFirstResponderModal;
