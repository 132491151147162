import React, { useContext } from 'react';
import {
	CANCEL_CREATION_MODAL,
	MISSING_REQUIRED_FIELDS_MODAL,
	BROWSE_ICONS_MODAL,
	FINALIZE_ALARM_MODAL,
	SAVE_AS_DISABLED_MODAL,
	LOADING_MODAL,
	CANCEL_CHANGES_MODAL,
	ENABLE_DISABLE_ALARM_MODAL,
} from '../consts';
import { CreateAlarmTypeContext } from '../provider';
import {
	CancelCreationModal,
	MissingRequiredFieldsModal,
	BrowseIconsModal,
	FinalizeAlarmModal,
	SaveAsDisabledModal,
	CancelChangesModal,
	EnableDisableAlarmModal,
} from './';
import { LoadingModal } from 'componentsV2';

export const CreateAlarmTypeModals = () => {
	const { modalToShow } = useContext(CreateAlarmTypeContext);

	return (
		<>
			{modalToShow === CANCEL_CREATION_MODAL && <CancelCreationModal />}
			{modalToShow === MISSING_REQUIRED_FIELDS_MODAL && <MissingRequiredFieldsModal />}
			{modalToShow === BROWSE_ICONS_MODAL && <BrowseIconsModal />}
			{modalToShow === FINALIZE_ALARM_MODAL && <FinalizeAlarmModal />}
			{modalToShow === SAVE_AS_DISABLED_MODAL && <SaveAsDisabledModal />}
			{modalToShow === LOADING_MODAL && <LoadingModal isOpen loading text="Saving..." />}
			{modalToShow === CANCEL_CHANGES_MODAL && <CancelChangesModal />}
			{modalToShow === ENABLE_DISABLE_ALARM_MODAL && <EnableDisableAlarmModal />}
		</>
	);
};
