import React, { useContext } from 'react';
import { DocumentModalsContext } from 'pages/Documents';

export const ShapeContentImageLink = ({item}) => {
    
    const DocumentContext = useContext(DocumentModalsContext);

    return (
        <a onClick={() => DocumentContext.showViewImageModal(item)}>
            {item.contentTitle}
        </a>
    )
}
