import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'componentsV2';
import { MapElementCard, TabPanel } from '../SidebarComponents';
import { NoIconFoundState } from './';

export const AvailableToUsePanel = ({ loading, iconDetails, dispatchIconDetails, onDragItem, editPermission, floorplanArchived }) => {
	const [availableToUseList, setAvailableToUseList] = useState(iconDetails.AvailableIconsList);

	useEffect(() => {
		onSearchHandler(iconDetails.availableSearchKey);
	}, []);

	const onSearchHandler = search => {
		setAvailableToUseList(
			iconDetails.AvailableIconsList.filter(iconItem =>
				iconItem.title?.toLowerCase().includes(search.toLowerCase()),
			),
		);
		dispatchIconDetails({
			payload: {
				...iconDetails,
				availableSearchKey: search,
			},
		});
	};

	const getSubTitle = iconShape => {
		const inUse = iconDetails.inUseIconsList.some(function(item) {
			return item.id === iconShape.id;
		});

		if (inUse) {
			return 'In use in this map layer';
		}
		return '';
	};
	if (loading) return <LoadingSpinner />;
	return (
		<TabPanel
			description="The icons listed below are icons used for all maps. Drag an icon from the list to add to this map layer. Right click on an icon to edit.	
					Once added to this map layer, the icon will appear under the “In use” tab."
			onSearchHandler={onSearchHandler}
			onAddClickHandler={() => {}}
			addBtnTitle=""
			hasSearch={true}
			showAddBtn={false}
			searchValue={iconDetails.availableSearchKey}
		>
			{availableToUseList.length === 0 && <NoIconFoundState />}
			{!!availableToUseList.length && (
				<>
					{availableToUseList.map((iconShape, index) => (
						<MapElementCard
							key={iconShape.id + '-' + index}
							title={`${iconShape.title}`}
							subTitle={getSubTitle(iconShape)}
							isSelected={false}
							isHidden={true}
							showEyeIcon={false}
							icon={<img className="inUseImg" src={iconShape.iconURL} />}
							onDrag={e => onDragItem(e, iconShape)}
							editPermission={editPermission}
							floorplanArchived={floorplanArchived}
						/>
					))}
				</>
			)}
		</TabPanel>
	);
};
