import React from "react";
import './css/PhoneWithExtension.scss';

export const PhoneWithExtension = ({ phone, extension }) => {
    return (
    <div className="PhoneWithExtension__Container">
        <div className="Phone__Container">{phone}</div>
        { extension && (
            <div className="Extension__Container">{extension}</div>
        )}
    </div>
)};