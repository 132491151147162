import React from "react";
import PropTypes from 'prop-types';
import { ActionsButton, Button } from "componentsV2";
import { ARCHIVED_STATUS, DRAFT_STATUS, MODIFIED_STATUS, PUBLISH_STATUS } from "./statuses";

export const ActionsList = ({
    flipchart,
    history,
    loadTabPreviewModal,
    sourcePage = null,
    showCopyModal,
    showArchiveModal,
    showDeleteModal,
    showPublishModal,
    elementSelector = null
}) => (
        <ActionsButton elementSelector={elementSelector}>
            {(sourcePage !== "details") && flipchart.status !== ARCHIVED_STATUS && flipchart.tabCount > 0 && (
                <Button
                    large
                    text="Preview"
                    icon="eye-open"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={()=> loadTabPreviewModal(flipchart.id)}
                />
            )}
            {flipchart.viewPermission && sourcePage !== "details" &&  flipchart.status === ARCHIVED_STATUS && (
                <Button
                    large
                    text="View"
                    icon="eye-open"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={()=> history.push("/flipcharts/view/"+flipchart.id)}
                />
            )}
            {flipchart.editPermission &&  (sourcePage !== "details") && flipchart.status !== ARCHIVED_STATUS && (
                <Button
                    large
                    text="Edit"
                    icon="edit"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={()=> history.push("/flipcharts/"+flipchart.id)}
                />
            )}
            {flipchart.editPermission && (
                <Button
                    large
                    text={flipchart.status === ARCHIVED_STATUS ? 'Unarchive' : 'Archive'}
                    icon="archive"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => {showArchiveModal(flipchart)}}

                />
            )}
            {flipchart.editPermission && (sourcePage !== "details") &&  [MODIFIED_STATUS, DRAFT_STATUS].includes(flipchart.status) && (
                <Button
                    large
                    text={flipchart.status === MODIFIED_STATUS ? "Publish changes" : "Publish"}
                    icon="tick"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => {showPublishModal(flipchart)}}
                />
            )}
            {flipchart.status !== ARCHIVED_STATUS && (
                <Button
                    large
                    text="Print"
                    icon="print"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={()=> window.open("/flipcharts/"+flipchart.id+"/print", '_blank')}
                />
            )}
            {flipchart.editPermission && [PUBLISH_STATUS, MODIFIED_STATUS, DRAFT_STATUS].includes(flipchart.status) && (
                <Button
                    large
                    text="Copy"
                    icon="duplicate"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => {showCopyModal(flipchart)}}
                />
            )}
            {flipchart.editPermission && flipchart.status !== ARCHIVED_STATUS && (
                <Button
                    large
                    text="Delete"
                    icon="trash"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={e => showDeleteModal(flipchart)}
                />
            )}
        </ActionsButton>
    );
ActionsList.propTypes = {
    flipchart: PropTypes.object,
};