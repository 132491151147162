import React, {useState, useEffect} from 'react';

export default function TestPermission({match})
{
    const userId = match.params.userId;
    const districtId = match.params.districtId;
    const permission = match.params.permission;
    const [userPermissions, setUserPermissions] = useState([]);
    const [districtPermissions, setDistrictPermissions] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);
    const [allDistricts, setAllDistricts] = useState([]);
    //const [districtId, setDistrictId] = useState(5235);
    const [allowedText, setAllowedText] = useState(" .. checking.. ");
    
    const url = process.env.API_URL;
    //const url = "http://localhost:8086";
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    
    useEffect(() => {
        console.log('mounted!');
        //testing user permissions
        console.log('userID : ' + userId);
        console.log('districtId : ' + districtId);
        console.log('permission : ' + permission);
        getUserPermisions(userId);
        //200001281        
    },[]);

    const getUserPermisions = (userId) => 
    {
        console.log('user permissions');
        
        fetch(`${url}/users/${userId}/permissions`, {
            method : 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(resp => {

            //console.log(resp.data);

            let permissions = resp.data.permissions;
            let districts = Object.keys(permissions);
            setAllDistricts(districts);
            
            //permissions = Object.create(permissions);
            
            console.log(permissions);

            console.log(districts);

            let allPerms = [];
            districts.map(district => {
                allPerms[district] = Object.keys(permissions[district]);
            });
            console.log(allPerms);

            setAllPermissions(allPerms);

            //console.log('allperms');
            
            //console.log(allPermissions);

            //console.log('perms: ' . permissions.length);

            if (districts.length > 0) {

                if (districts.includes(districtId)) {
                    
                    console.log('has permission in district');
    
                    //let permisArray = Object.keys(permissions[districtId]);
                    let districtPermissions = permissions[districtId];
                    let districtPermissionsList = Object.keys(permissions[districtId]);
                    //let permitsCount = districtPermissions.length;

                    console.log('District ' + districtId + ' permissions');

                    console.log(districtPermissions);

                    setDistrictPermissions(districtPermissionsList);

                    //console.log(permitsCount);

                    console.log(districtPermissions[permission]);

                    if ( districtPermissions[permission] !== undefined ) {
                        //console.log('permission GRANTED by RoleID: ' + districtPermissions[permission]);
                        setAllowedText('Permission GRANTED by RoleID: ' + districtPermissions[permission]);
                    } else {
                        //setAllowedText(' DOES NOT have access to ');   
                        //console.log('permission DENIED');
                        if (permission != null) {
                            setAllowedText('Permission DENIED to action');
                        } else {
                            setAllowedText('No action selected');
                        }
                        
                    }
    
                } else {

                    if (districtId != null) {
                        setAllowedText('User has NOT permission in district');
                    } else {
                        setAllowedText('No district selected');
                    }
                    //console.log('has NOT permission in district');
                    
                
                }
            } else {
                //console.log('User has no district/permission/role');
                setAllowedText('User has no district/permission/role');
            }

        });
    };

    const humanize = (str) => 
    {
        var i, frags = str.split('_');
        for (i=0; i<frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    };

    return (
        <>
        <h2>User Permission Tests</h2>

        <h4>
            User ID <code>{userId}</code>
        </h4>

        {(districtId) && (
            <>
            <h4>
                District ID <code>{districtId}</code>
            </h4>

            <h3>User permissions in district <code>{districtId}</code></h3>
            <ul>
                {districtPermissions.map(item => {
                    return <li><code>{item}</code></li>
                })}
            </ul>
            </>
        )}
        
        {(permission) && (
            <h4>
                Permission Name <code>{permission}</code>
            </h4>
        )}

        <h3>
            Results: <span> {allowedText } </span>
        </h3>

        
        {(!districtId && !permission) && (
            <>
            <h3>ALL User permissions BY district</h3>
            <ul>
                {allPermissions.map((permissions, district) => {
                    console.log(district);
                    console.log(permissions);
                    return <li>
                        <strong>{district}</strong>
                        <ul>
                            {permissions.map(permission => {
                                return <li><code>{permission}</code></li>
                            })}
                        </ul>
                    </li>
                })}
            </ul>
            </>
        )}
    
        </> 
    )
}

    /*
    {
    "document_setup": 1,
    "document_documents": 1,
    "document_delete": 1,
    "document_view": 1,
    "document_edit": 1,
    "document_view_original": 1,
    "document_creator": 1,
    "call_list_edit": 1,
    "call_list_view": 1,
    "call_list_delete": 1,
    "maps_view": 1,
    "maps_edit": 1,
    "maps_delete": 1,
    "vb_edit": 1,
    "vb_view": 1,
    "vb_delete": 1,
    "vb_view_pdf": 1,
    "flipcharts_view": 1,
    "flipcharts_edit": 1,
    "flipcharts_publish": 1,
    "drilllogs_view": 1,
    "drilllogs_schedule": 1,
    "drilllogs_log": 1,
    "drilllogs_post": 1,
    "drilllogs_unpost": 1,
    "drilllogs_delete": 1,
    "drilllogs_set_reqs": 1,
    "safety_plans_view": 1,
    "safety_plans_delete": 1,
    "safety_plans_edit": 1,
    "response_response": 1,
    "user_edit": 1,
    "user_view": 1,
    "setup_site": 1,
    "setup_progress": 1,
    "setup_organization": 1,
    "mobile_app_flipcharts": 1,
    "site_info_view": 1,
    "site_info_edit": 1,
    "prepare_createplans": 1,
    "prepare_virtualbinder": 1,
    "prepare_resources": 1,
    "prepare_operationalteams": 1,
    "prepare_incidentcommand": 1,
    "prepare_call": 1,
    "prepare_contacts": 1,
    "prepare_notification": 1,
    "prepare_alert": 1,
    "prepare_site": 1,
    "prepare_tools": 1,
    "roles_view": 1,
    "roles_edit": 1,
    "roles_delete": 1,
    "respond_view": 1,
    "respond_edit": 1,
    "drill_scenario_view": 1,
    "drill_scenario_edit": 1,
    "reunification_view": 1,
    "reunification_edit": 1,
    "site_assess_view": 1,
    "site_assess_edit": 1,
    "manage_custom_workflows": 1
}
    
    
    */
