import React, { useState, useEffect } from 'react';
import { LoadingSpinner } from 'componentsV2';
import {
	DocumentsFilters,
	DocumentsTable,
	EmptyStatuses,
	DocumentHeader,
	DocumentModalsProvider,
	BulkActions,
} from './components';
import './css/Documents.scss';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from 'react-redux';
import { DocumentService } from 'services/DocumentService';
import useSelectedSites from 'hooks/useSelectedSites';

export const Documents = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const permissions = useSelector(state => state.route.permissions);
	const editPermission = checkPermission('document_edit', permissions);
	const [totalRows, setTotalRows] = useState(0);
	const defaultSelectedSites = useSelectedSites();
	const [showEmptyStatus, setShowEmptyStatus] = useState(false);
	const [noDataAdded, setNoDataAdded] = useState(false);
	const [documentsList, setDocumentsList] = useState([]);
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const [filterationData, setFilterationData] = useState({
		page: 1,
		perPage: 10,
		sortKey: 'title',
		sortDir: 'asc',
		title: null,
		types: [],
		buildingIds: [],
	});

	useEffect(() => {
		getDocumentsList();
	}, [filterationData]);

	const getDocumentsList = () => {
		DocumentService.getAll(filterationData)
			.then(response => {
				if (response.statusCode === 200) {
					setDocumentsList(response.data);
					setTotalRows(response._metadata.totalRecords);
					var isEmpty = response.data.length === 0;
					setNoDataAdded(
						isEmpty &&
							filterationData.types.length === 0 &&
							filterationData.buildingIds.length === 0 &&
							(filterationData.title === null || filterationData.title === ''),
					);
					setShowEmptyStatus(isEmpty);
				}
			})
			.catch(error => {
				console.error('Error fetching documents list:', error);
			})
			.finally(() => {
				setSelectedDocuments([]);
				setLoading(false);
			});
	};

	const toggleBulkActions = () => {
		if (selectedDocuments.length === 0) return false;

		return selectedDocuments.some(selectedDocument => {
			return documentsList.some(document => document.uniqid === selectedDocument.uniqid);
		});
	};

	return (
		<div className="documents-container">
			{loading && <LoadingSpinner />}
			{!loading && (
				<DocumentModalsProvider
					history={history}
					getDocumentsList={getDocumentsList}
					setTbLoading={setLoading}
					selectedSites={filterationData.buildingIds}
				>
					<DocumentHeader editPermission={editPermission} noDataAdded={noDataAdded} />
					{!noDataAdded && (
						<DocumentsFilters
							defaultSelectedSites={defaultSelectedSites}
							setFilterationData={setFilterationData}
							filterationData={filterationData}
						/>
					)}
					{toggleBulkActions() && <BulkActions selectedDocuments={selectedDocuments} />}
					{showEmptyStatus && (
						<EmptyStatuses
							filterationData={filterationData}
							noDataAdded={noDataAdded}
							editPermission={editPermission}
						/>
					)}
					{!showEmptyStatus && (
						<DocumentsTable
							history={history}
							list={documentsList}
							filterationData={filterationData}
							setFilterationData={setFilterationData}
							totalRows={totalRows}
							editPermission={editPermission}
							setSelectedRows={setSelectedDocuments}
							selectedRows={selectedDocuments}
						/>
					)}
				</DocumentModalsProvider>
			)}
		</div>
	);
};
