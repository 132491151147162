/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { CardModal } from 'components/Modals/CardModal';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Input } from 'components/Input';
import { MoreActionsButton } from 'components/MoreActionsButton';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { TextArea } from 'components/TextArea';
import { DrillsSettingsService } from 'services';

const Page = styled.div`
	.modal-card {
		.link-label {
			font-size: 14px;
			margin-top: 16px;
			font-weight: 700;
			line-height: 20px;
			font-style: normal;
			margin-bottom: 2px;
			letter-spacing: 0px;
			font-family: Nunito Sans;
		}

		a {
			color: #106ba3;
		}

		.text-area {
			width: 400px;
		}
	}
`;

const BASE_VIEW = {
	showEmailModal: false,
	receiverLink: null,
	receiverEmail: null,
	receiverComments: null,
};

const BASE_STATE = {
	...BASE_VIEW,
	loading: true,
	schools: null,
};

export default class PublicViewableSettings extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		DrillsSettingsService.fetchPublicViewableSchools()
			.then(schools => {
				this.setState({ ...BASE_STATE, schools, loading: false });
			})
			.catch(() => {
				this.setState({
					...BASE_STATE,
					loading: false,
				});
				Swal.fire({
					title: 'Oops!',
					text: 'Could not load this page. Please try refreshing',
					icon: 'error',
					toast: true,
					position: 'top',
					showConfirmButton: false,
				});
			});
	}

	makePreviewLink = school =>
		`${window.location.protocol}//${
			window.location.host
		}/publicDrillLogs/district/${school.districtId}/building/${school.id}`;

	copyLink = school => {
		const input = document.createElement('textarea');
		input.innerHTML = this.makePreviewLink(school);
		document.body.appendChild(input);
		input.select();
		const result = document.execCommand('copy');
		document.body.removeChild(input);
		return result;
	};

	previewSchool = school => {
		window.open(this.makePreviewLink(school), '_blank');
	};

	manageEmailModal = (showEmailModal = true, school) => {
		this.setState({
			...BASE_VIEW,
			showEmailModal,
			...(school && {
				selectedSchool: school,
				receiverLink: this.makePreviewLink(school),
			}),
		});
	};

	sendEmail = () => {
		const { selectedSchool, receiverEmail, receiverComments } = this.state;
		DrillsSettingsService.emailLinkToRecipient(
			selectedSchool.id,
			receiverEmail,
			receiverComments,
		)
			.then(() => {
				this.manageEmailModal(false);
				Swal.fire({
					icon: 'success',
					title: 'Success!',
					text: 'Your email has been sent!',
				});
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Your email could not be delivered. Please try again',
				});
			});
	};

	sortData = data =>
		data.sort((a, b) => {
			const x = a.name.toUpperCase();
			const y = b.name.toUpperCase();
			if (x < y) {
				return -1;
			}
			if (x > y) {
				return 1;
			}
			return 0;
		});

	checkItem = item => {
		DrillsSettingsService.enablePublicViewable(item.id, !item.isPublic)
			.then(schools => {
				this.setState({ ...BASE_STATE, schools, loading: false });
			})
			.catch(() => {
				this.setState({
					...BASE_STATE,
					loading: false,
				});
				Swal.fire({
					title: 'Oops!',
					text: 'Could not load this page. Please try refreshing',
					icon: 'error',
					toast: true,
					position: 'top',
					showConfirmButton: false,
				});
			});
	};

	render() {
		const {
			loading,
			error,
			schools,
			showEmailModal,
			receiverLink,
			receiverEmail,
			receiverComments,
		} = this.state;

		const { history } = this.props;

		if (loading) return <LoadingSpinner />;

		if (error) return error;

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Public Viewable Settings"
					onBackBtnClick={history.goBack}
				/>

				{schools && (
					/* overview table */
					<Table
						headers={['Public viewable', 'School name', 'Actions']}
						data={this.sortData(schools)}
						renderItem={item => (
							<>
								<td>
									<input
										type="checkbox"
										// disabled
										checked={item.isPublic}
										onClick={() => this.checkItem(item)}
									/>
								</td>

								<td>{item.name}</td>

								<td>
									{item.isPublic && (
										<MoreActionsButton
											actions={[
												{
													id: 'copy',
													title: 'Copy link',
													handler: () =>
														this.copyLink(item),
												},
												{
													id: 'email',
													title: 'Email link',
													handler: () =>
														this.manageEmailModal(
															true,
															item,
														),
												},
												{
													id: 'preview',
													title: 'Preview',
													handler: () =>
														this.previewSchool(
															item,
														),
												},
											]}
										/>
									)}
								</td>
							</>
						)}
					/>
				)}

				{/* email to */}
				<CardModal
					isVisible={showEmailModal}
					setIsVisible={this.manageEmailModal}
					title="Email public link"
					actionTitle="Send email"
					onActionClick={this.sendEmail}
					actionBtnDisabled={!receiverEmail || !receiverComments}
				>
					<p className="link-label">Link</p>
					<a>{receiverLink}</a>

					<Input
						label="Receiver email"
						placeholder="Enter email address"
						value={receiverEmail}
						onChange={({ target: { value } }) =>
							this.setState({ receiverEmail: value })
						}
					/>

					<TextArea
						label="Comments"
						placeholder="Enter any comments"
						value={receiverComments}
						onChange={({ target: { value } }) =>
							this.setState({
								receiverComments: value,
							})
						}
					/>
				</CardModal>
			</Page>
		);
	}
}

PublicViewableSettings.propTypes = {
	history: PropTypes.object,
};
