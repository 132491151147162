/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
	Tab,
	Tabs,
	Button,
	Dialog,
	Classes,
	Icon,
	TextArea,
	Intent,
	RadioGroup,
	Radio,
	MenuItem,
} from '@blueprintjs/core';
import { Input } from 'components/Input';
import { LabelText } from 'components/Forms/shared.js';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Select } from '@blueprintjs/select';
import { HttpService } from 'networking/HttpService';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import selectRoute from './selectors';
import Templates, { Tasks, Analytics, Settings } from './panels';
import Dashboard from './panels/dashboard';
import { allStates } from './statelist';
import { allYears } from './yearlist';
import EditAssessment from './editeassessment';
import CopyAssessment from './copyAssessment';
import NewAssess from './newassessment';
import { checkPermission } from '../../utilities/permissions';
import ImportAssess from './importassessment';
import { PageHeader } from '../../components/PageHeader';

const SelectWrap = styled(Select)`
	display: block;
	width: 100%;
	.bp3-popover-target {
		display: block;
		width: 100%;
	}
`;

const HeaderButton = styled(Button)`
	.bp3-intent-danger {
		outline: #f00;
	}
	.bp3-outlined {
	}
`;


const CreateDialog = styled(Dialog)`
	width: 300px;
`;

const DropdownWrap = styled.div`
	padding: 10px;
	width: 100%;
	background: #ffffff;
	border: 1px solid #d8e1e8;
	border-radius: 3px;
	display: flex;
	justify-content: space-between;
`;

const ProprietaryTextWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const DialogFooterWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 10px;
`;

class AssessDashboard extends React.Component {
	state = {
		primaryBuildingId: null,
		loading: true,
		canEscapeKeyClose: true,
		canOutsideClickClose: true,
		enforceFocus: true,
		isOpen: false,
		radioChange: 'one',
		proprietary: 'true',
		templates: [],
		editTemplate: {},
		isEditOpen: false,
		isCopyOpen: false,
		// eslint-disable-next-line react/destructuring-assignment
		panel: this.props.history.location.state
			? // eslint-disable-next-line react/destructuring-assignment
			  this.props.history.location.state.currentPanel
			: 'dashboard',
		isNewAssessOpen: false,
		assessments: [],
		exportTarget: null,
		exportBuildings: [],
		exportYear: null,
		isImportTemplateOpen: false,
		importSuccessful: false,
		importButton: true,
	};

	changeBuilding = item => {
		this.setState({ loading: true });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/buildings/${item.id}/assessments`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		})
			.then(rsp => rsp.json())
			.then(rs => {
				localStorage.setItem('building', JSON.stringify(item));
				this.setState({
					selectedBuilding: item,
					assessments: rs.data,
					loading: false,
				});
			});
	};

	getData = () => {
		const {
			route: { districtId },
		} = this.props;
		this.setState({ loading: true });
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		Promise.all([
			fetch(
				`${process.env.API_URL}/templates?includeUnpublished=${true}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			),
			fetch(
				`${process.env.API_URL}/users/myself?includeUserBuildings=true`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			),
		])
			.then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
			.then(([resp1, resp2]) => {
				const { templates } = resp1.data
				// const { buildings, changeBuilding } = this.props;

				const [targetBuilding] = resp2.data.buildings.filter(
					item => item.id === resp2.data.primaryBuildingId,
				);

				fetch(
					`${process.env.API_URL}/buildings/${
						targetBuilding.id
					}/assessments`,
					{
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${token}`,
						},
					},
				)
					.then(rsp => rsp.json())
					.then(rs => {
						this.setState({
							loading: false,
							templates,
							isOpen: false,
							buildings: resp2.data.buildings,
							selectedBuilding: targetBuilding,
							assessments: rs.data,
						});

						if (districtId === 6880) {
							this.setState({
								panel: 'templates',
							});
						}
					});
			});
	};

	checkUser = () =>
		HttpService({
			method: 'GET',
			route: '/users/myself',
		}).then(res => {
			if (res.statusCode === 200) {
				return {
					primaryBuildingId: res.data.primaryBuildingId,
					loading: false,
				};
			}
			return {};
		});

	componentDidMount = () => {
		this.checkUser().then(newState => this.getData(newState));
	};

	updateFields = (key, value) => this.setState({ [key]: value });

	exportCSV = () => {
		const { buildings } = this.state;
		Swal.fire('Exporting');
		Swal.showLoading();
		const { exportTarget, exportBuildings, exportYear } = this.state;
		const tempBuildings = [];
		exportBuildings.forEach(item => tempBuildings.push(item.id));

		exportBuildings.forEach(one => {
			if (one.value === 'all-schools') {
				// empty the buildings
				tempBuildings.length = 0;
				return buildings.forEach(site => {
					if (site.id) {
						tempBuildings.push(site.id);
					}
				});
			}

			return tempBuildings.push(one.id);
		});
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${process.env.API_URL}/templates/${
				exportTarget.id
			}/analytics-export`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: exportTarget.id,
					buildings: tempBuildings,
					year: exportYear.value,
				}),
			},
		)
			.then(res => res.blob())
			.then(resp => {
				Swal.close();
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				}).then(() => {
					const filename = `${exportTarget.name} ${format(
						new Date(),
						'hh-mm-ss a',
					)}`;
					const data = new Blob([resp], {
						type: 'application/csv',
					});
					const csvURL = window.URL.createObjectURL(data);
					const tempLink = document.createElement('a');
					tempLink.href = csvURL;
					tempLink.setAttribute('download', `${filename}.csv`);
					tempLink.click();
				});
			});
	};

	handleImportOpen = () => this.setState({ isImportTemplateOpen: true });

	importTemplate = () => {
		this.setState({ importButton: false });
		this.setState({ importSuccessful: true });
		setTimeout(
			() => {
				this.setState({
					importSuccessful: false,
					importButton: true,
				})
				this.getData()
			},
			3000,
		);
	};

	handleOpen = () => {
		const {
			route: { districtId },
		} = this.props;
		const { panel } = this.state;

		//	Make sure we are only opening this create template whenever in 6880
		if (districtId === 6880) {
			return this.setState({ isOpen: true });
		}

		if (panel === 'analytics') {
			return this.exportCSV();
		}
		if (panel === 'templates') {
			return this.setState({ isOpen: true });
		}
		if (panel === 'dashboard') {
			return this.setState({ isNewAssessOpen: true });
		}
		return this.setState({
			isNewAssessOpen: true,
		});
	};

	handleClose = () => this.setState({ isOpen: false });

	handleRadioChange = () =>
		this.setState({
			// eslint-disable-next-line no-constant-condition
			// eslint-disable-next-line no-nested-ternary
			radioChange: 'one' ? 'two' : false && 'two' ? 'one' : false,
		});

	makeStates = item => (
		<MenuItem
			key={item}
			onClick={() => this.updateFields('state', item)}
			text={item}
		/>
	);

	makeYears = item => (
		<MenuItem
			key={item}
			onClick={() => this.updateFields('year', item)}
			text={item}
		/>
	);

	createTemplate = () => {
		const {
			name,
			year,
			notes,
			state,
			proprietary,
			primaryBuildingId,
		} = this.state;
		this.setState({ loading: true });
		const isTrueSet = proprietary.toLowerCase() === 'true';
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/templates`, {
			method: 'POST',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				name,
				year,
				notes,
				state,
				proprietary: isTrueSet,
				buildingId: primaryBuildingId,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					return this.getData();
				}
				return null;
			});
	};

	updateTemplatesArray = (item, index) => {
		const { templates } = this.state;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/templates/${item.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				state: item.state,
				year: item.year.length === 0 ? null : parseInt(item.year, 10),
				notes: item.notes? item.notes : '',
				proprietary: (item.isProprietary === 'true'),
				name: item.name,
				active: true,
				summary: item.summary? item.summary : null,
				publish: true
			  }),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 2000,
						showConfirmButton: false,
					});
					templates[index] = item;
					return this.setState({
						templates,
						isEditOpen: false,
						isCopyOpen: false,
						editTemplate: {},
					});
				}else{
					Swal.fire({
						title: 'Oops!',
						icon: 'error',
						timer: 3000,
						showConfirmButton: false,
					});
					return this.setState({
						isEditOpen: false,
						isCopyOpen: false,
						editTemplate: {},
					});
				}
			});
			return null;
	};

	removeTemplate = item => {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/templates/${item.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				active: false,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => this.getData());
				}
				return null;
			});
	};

	setEdit = (item, index) => {
		const temp = { ...item };
		temp.index = index;
		return this.setState({ editTemplate: temp, isEditOpen: true });
	};

	closeEdit = () => {
		this.setState({ editTemplate: {}, isEditOpen: false });
	};

	setCopy = (item, index) => {
		const temp = { ...item };
		temp.index = index;
		this.setState({ editTemplate: temp, isCopyOpen: true });
	};

	closeCopyTemplate = () => {
		this.setState({ editTemplate: {}, isCopyOpen: false });
	};

	closeNewAssess = () => this.setState({ isNewAssessOpen: false });

	closeImportAssess = () => this.setState({ isImportTemplateOpen: false });

	showButton = () => {
		const { exportTarget, exportBuildings, exportYear, panel } = this.state;
		if (panel === 'tasks' || panel === 'settings') return false;
		if (
			panel === 'analytics' &&
			(!exportTarget || !exportYear || exportBuildings.length === 0)
		)
			return false;
		return true;
	};

	onTabChange = async val => {
		this.setState({ panel: val });
		if (val !== 'templates') {
			// eslint-disable-next-line react/destructuring-assignment
			this.props.history.location.state = { currentPanel: 'dashboard' };
		}
	};

	render() {
		const {
			history,
			route: { permissions },
			route: { districtId },
		} = this.props;
		const {
			loading,
			state,
			year,
			notes,
			proprietary,
			templates,
			editTemplate,
			isEditOpen,
			isCopyOpen,
			selectedBuilding,
			buildings,
			assessments,
			panel,
			isNewAssessOpen,
			isImportTemplateOpen,
			importSuccessful,
			importButton,
			primaryBuildingId,
		} = this.state;

		const showButton = this.showButton();

		return (
			<div className="container-fluid">
				<div className="row ">
					<div className="col-12">
						<PageHeader
							title="Assess"
							customItem={() => {
								return showButton ? 
									<HeaderButton
										style={{
											color: '#9F1C3A',
											height: 40,
											marginTop: 5,
										}}
										onClick={this.handleOpen}
									>
										{districtId === 6880 ||
										panel === 'templates'
											? 'Create new templates'
											: panel === 'dashboard'
											? 'Start new assessment'
											: panel === 'analytics'
											? 'Export all to CSV'
											: null}
									</HeaderButton>
								: ""
							}}
						/>
					</div>
					<div className="col-4 d-flex row justify-content-end">
						<div className=" d-flex justify-content-end mr-10">
							<div className="d-flex row">
								{panel === 'templates' && importSuccessful && (
									<p
										style={{
											color: '#394B59',
											height: 20,
											marginTop: 15,
											marginRight: 25,
											backgroundColor: '#EBF1F5',
											borderRadius: 10,
											fontFamily: 'Nunito Sans',
										}}
									>
										<Icon icon="small-tick" />
										Template Successfully Imported!
									</p>
								)}
							</div>
							{districtId !== 6880 &&
								importButton &&
								panel === 'templates' && (
									<Button
										className="bp3-minimal"
										style={{
											color: '#9F1C3A',
											height: 40,
											marginTop: 5,
											marginRight: 20,
											background: 'transparent',
										}}
										onClick={this.handleImportOpen}
									>
										<Icon
											icon="import"
											style={{
												color: '#9F1C3A',
												margin: 1,
											}}
										/>
										{panel === 'templates' && 'Import'}
									</Button>
								)}
						</div>
						<div className="d-flex justify-content-end">
							
						</div>
					</div>
					{isCopyOpen && (
						<CopyAssessment
							getData={this.getData}
							primaryBuildingId={primaryBuildingId}
							copyTemplate={editTemplate}
							closeEdit={this.closeCopyTemplate}
						/>
					)}

					{isEditOpen && (
						<EditAssessment
							editTemplate={editTemplate}
							updateTemplatesArray={this.updateTemplatesArray}
							closeEdit={this.closeEdit}
							isSysAdmin={permissions.isSysAdmin}
						/>
					)}

					{isImportTemplateOpen && (
						<ImportAssess
							templates={templates}
							closeImportAssess={this.closeImportAssess}
							importTemplateCallback={this.importTemplate}
						/>
					)}

					{isNewAssessOpen && (
						<NewAssess
							closeNewAssess={this.closeNewAssess}
							templates={templates}
							selectedBuilding={selectedBuilding}
							history={history}
						/>
					)}

					<CreateDialog
						onClose={this.handleClose}
						title="Create new template"
						{...this.state}
					>
						<div className={Classes.DIALOG_BODY}>
							<Input
								placeholder="Enter template name"
								label="Name"
								large
								onChange={e =>
									this.updateFields('name', e.target.value)
								}
							/>
							<LabelText className="mt-2">State</LabelText>
							<SelectWrap
								items={allStates}
								itemRenderer={this.makeStates}
								className="w-100"
								filterable={false}
							>
								<DropdownWrap>
									<div>{state || 'Select'}</div>
									<Icon icon="caret-down" />
								</DropdownWrap>
							</SelectWrap>
							<LabelText className="mt-2">Year</LabelText>
							<SelectWrap
								items={allYears}
								itemRenderer={this.makeYears}
								className="w-100"
								filterable={false}
							>
								<DropdownWrap>
									<div>{year || 'Select'}</div>
									<Icon icon="caret-down" />
								</DropdownWrap>
							</SelectWrap>
							<LabelText className="mt-2">Notes</LabelText>
							<TextArea
								className="bp3-fill .modifier"
								growVertically={false}
								intent={Intent.NONE}
								placeholder="Enter template notes"
								onChange={e =>
									this.updateFields('notes', e.target.value)
								}
								value={notes}
							/>
							{permissions.isSysAdmin && (
								<ProprietaryTextWrapper className="row m-0">
									<LabelText className="mt-2">
										Proprietary template
									</LabelText>
									<Icon
										style={{
											color: '#137CBD',
											marginLeft: 4,
										}}
										icon="help"
									/>
								</ProprietaryTextWrapper>
							)}
							{permissions.isSysAdmin && (
								<div className="row m-0 ">
									<RadioGroup
										className="row m-0"
										onChange={e =>
											this.updateFields(
												'proprietary',
												e.target.value,
											)
										}
										selectedValue={proprietary}
									>
										<Radio
											style={{ marginRight: 40 }}
											label="Yes"
											value="true"
										/>
										<Radio label="No" value="false" />
									</RadioGroup>
								</div>
							)}

						</div>
						<DialogFooterWrapper>
							<Button
								style={{
									marginRight: 16,
									height: 40,
									width: 68,
								}}
								onClick={this.handleClose}
								text="Cancel"
								intent="none"
							/>
							<Button
								style={{
									height: 40,
									width: 68,
									background: '#9F1C3A',
								}}
								onClick={this.createTemplate}
								text="Create"
								intent="danger"
							/>
						</DialogFooterWrapper>
					</CreateDialog>
				</div>
				{loading && <LoadingSpinner />}
				{!loading && (
					<Tabs
						id="assessTabs"
						defaultSelectedTabId={
							districtId !== 6880 ? panel : 'templates'
						}
						onChange={val => this.onTabChange(val)}
					>
						{districtId !== 6880 && (
							<Tab
								id="dashboard"
								title="Dashboard"
								panel={
									<Dashboard
										history={history}
										templates={templates}
										setEdit={this.setEdit}
										removeTemplate={this.removeTemplate}
										buildings={buildings}
										selectedBuilding={selectedBuilding}
										assessments={assessments}
										changeBuilding={this.changeBuilding}
									/>
								}
							/>
						)}
						{districtId !== 6880 && false && (
							<Tab
								id="analytics"
								title="Analytics"
								panel={
									<Analytics
										history={history}
										templates={templates}
										setEdit={this.setEdit}
										removeTemplate={this.removeTemplate}
										updateFields={this.updateFields}
									/>
								}
							/>
						)}
						{checkPermission(
							'site_assess_edit',
							permissions,
							districtId,
						) && (
							<Tab
								id="templates"
								title="Templates"
								panel={
									<Templates
										history={history}
										permissions={permissions}
										templates={templates}
										setCopy={this.setCopy}
										setEdit={this.setEdit}
										removeTemplate={this.removeTemplate}
										districtId={districtId}
									/>
								}
							/>
						)}
						{districtId !== 6880 && (
							<Tab
								id="tasks"
								title="Tasks"
								panel={<Tasks history={history} />}
							/>
						)}
						{districtId !== 6880 && checkPermission('site_assess_edit', permissions, districtId) && (
							<Tab
								id="settings"
								title="Settings"
								panel={<Settings history={history} />}
							/>
						)}
					</Tabs>
				)}
			</div>
		);
	}
}

AssessDashboard.propTypes = {
	history: PropTypes.object,
	route: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(withConnect)(AssessDashboard);