import React from 'react';
import { EventLogItemWrapper } from './EventLogItem';

export const EventLogNoResults = () => {

    return (
        <EventLogItemWrapper>
            <h6>No event logs found for this template.</h6> 
        </EventLogItemWrapper>
    )
}