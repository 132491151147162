import React, { useEffect, useState, useContext, useReducer } from 'react';
import { PageHeader } from 'components/PageHeader';
import { ToasterContext } from 'pages/App';
import {
	Button,
	Badge,
	EmptyState,
	ConfirmationModal,
	Intent,
	FeaturedIcon,
	LoadingSpinner,
} from 'componentsV2';
import { emptyFiles } from 'componentsV2/EmptyState/icons';
import {
	ActionsList,
	Tabs,
	DetailsCardContainer,
	PublishHistoryCardContainer,
	AddUpdateFlipchartModal,
	AddUpdateTabModal,
	TabPreviewModal,
} from './components';
import { FlipchartService } from 'services/FlipchartService';
import './css/FlipchartDetails.scss';
import {
	ARCHIVED_STATUS,
	MODIFIED_STATUS,
	DRAFT_STATUS,
	PUBLISH_STATUS,
} from './components/statuses';

export const FlipchartDetails = props => {
	const breadcrumbItems = [
		{ label: 'Flipcharts', url: '/flipcharts' },
		{ label: 'Flipchart details' },
	];
	const [loading, setLoading] = useState(true);
	const [flipchart, setFlipchart] = useState([]);
	const [selectedTaps, setSelectedTaps] = useState([]);
	const [selectedSingleTapTitle, setselectedSingleTapTitle] = useState([]);
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [showTabPreviewModal, setShowTabPreviewModal] = useState(false);
	const [flipchartId, setFlipchartId] = useState();
	const [modalTabIsOpen, setModalTabIsOpen] = useState(false);
	const [singleDeleteTabFlag, setSingleDeleteTabFlag] = useState(false);
	const { history, viewMode } = props;
	const id = props.match.params.id;
	const toaster = useContext(ToasterContext);
	const UPDATE_MODAL = 'AddUpdateFlipchartModal';
	const [notFoundState, setNotFoundState] = useState(false);

	const reloadFlipchartDetails = () => {
		setLoading(true);
		getFlipchartDetails(id);
	};

	useEffect(() => {
		getFlipchartDetails(id);
	}, []);

	const getFlipchartDetails = async id => {
		setLoading(true);
		await FlipchartService.getFlipchart(id)
			.then(response => {
				if (response.statusCode === 200) {
					const flipchartRes = response.data;
					if (flipchartRes.status === ARCHIVED_STATUS) {
						history.push('/flipcharts/view/' + id);
					}
					const editPermission = flipchartRes.hasEditAccess;
					if (editPermission) {
						// if the flipchart is not last updated one this means it's old version flipchart.
						if (!flipchartRes.isLastOne) {
							setNotFoundState(true);
							setLoading(false);
							return;
						}
						if (viewMode && flipchartRes.status != ARCHIVED_STATUS) {
							history.push('/flipcharts/' + flipchartRes.id);
						}
						flipchartRes.editPermission = editPermission;
						flipchartRes.viewPermission = true;
						setFlipchart(flipchartRes);
						setLoading(false);
					} else {
						toaster("You don't have access to this flipchart!", Intent.DANGER);
						history.push('/flipcharts');
					}
				} else {
					toaster('Invalid Flipchart Data', Intent.DANGER);
					history.push('/flipcharts');
				}
			})
			.catch(err => {
				history.push('/flipcharts');
			});
	};

	const [isCopyModalOpen, setCopyModalOpen] = useState(false);
	const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [isDeleteTabModalOpen, setDeleteTabModalOpen] = useState(false);
	const [modalToShow, setModalToShow] = useState(null);
	const PUBLISH_MODAL = 'publishModal';

	const closeModal = () => {
		setCopyModalOpen(false);
		setArchiveModalOpen(false);
		setDeleteModalOpen(false);
		setDeleteTabModalOpen(false);
		setModalToShow(false);
		if (singleDeleteTabFlag === true) {
			setSelectedTaps([]);
			setSingleDeleteTabFlag(false);
		}
	};

	const flipchartDataReducer = (filpchartData, action) => {
		return {
			id: action.id,
			title: action.title,
			isArchived: action.isArchived,
		};
	};

	const [filpchartData, dispatchFlipchartnData] = useReducer(flipchartDataReducer, {
		id: null,
		title: '',
		isArchived: false,
	});

	const handlConfirmCopyAction = async () => {
		try {
			setModalLoading(true);
			const response = await FlipchartService.publishFlipChart(filpchartData.id, 'copy');
			setModalLoading(false);
			setLoading(false);
			closeModal();
			history.push('/flipcharts/' + response.data.id);
			getFlipchartDetails(response.data.id);
		} catch (error) {
			setModalLoading(false);
			toaster(error.error?.description, Intent.DANGER);
		}
	};

	const handleArchiveAction = async () => {
		try {
			setModalLoading(true);
			const archiveFlipchart = filpchartData.isArchived ? false : true; //toggle status
			await FlipchartService.archiveFlipChart(filpchartData.id, archiveFlipchart);
			setLoading(false);
			setModalLoading(false);
			closeModal();
			getFlipchartDetails(filpchartData.id);
			toaster(
				'You have successfully ' +
					(filpchartData.isArchived ? 'unarchived ' : 'archived ') +
					`"` +
					filpchartData.title +
					'"!',
				Intent.NONE,
				<FeaturedIcon icon={'database'} type={'Gray'} size={'md'} shape={'circleIcon'} />,
			);

			filpchartData.isArchived
				? history.push('/flipcharts/' + filpchartData.id)
				: history.push('/flipcharts/view/' + filpchartData.id);
		} catch (error) {
			setModalLoading(false);
			closeModal();
			toaster(error.error?.description, Intent.DANGER);
		}
	};

	const handleDeleteAction = async () => {
		try {
			setModalLoading(true);
			await FlipchartService.delete(filpchartData.id);
			history.push('/flipcharts');
			toaster(
				'You have successfully deleted "' + filpchartData.title + '"!',
				Intent.NONE,
				<FeaturedIcon icon="trash" type="Gray" />,
			);
		} catch (error) {
			toaster(error.error?.description, Intent.DANGER);
			closeModal();
			setModalLoading(false);
		}
	};

	const handleMultipleSingleDeleteTab = async () => {
		setModalLoading(true);
		await FlipchartService.deleteFlipchartTab(flipchart.id, selectedTaps)
			.then(response => {
				if (response.statusCode === 200) {
					closeModal();
					setModalLoading(false);
					setSingleDeleteTabFlag(false);
					let message =
						'You have successfully deleted ' +
						(selectedTaps.length > 1
							? selectedTaps.length + ' tabs'
							: 'the tab "' + selectedSingleTapTitle + '"');
					toaster(
						message,
						Intent.NONE,
						<FeaturedIcon
							icon={'trash'}
							type={'Gray'}
							size={'md'}
							shape={'circleIcon'}
						/>,
					);
					reloadFlipchartDetails();
				}
			})
			.catch(err => {
				// toaster(err.error?.description, Intent.DANGER);
				closeModal();
				setModalLoading(false);
			});
	};

	const handleConfirmPublishAction = async () => {
		try {
			// validate if flipchart has tabs
			if (flipchart.tabCount === 0) {
				toaster(
					`You must create a tab before you can publish the flipchart "${
						flipchart.title
					}"`,
					Intent.DANGER,
				);
				closeModal();
				return;
			}
			setModalLoading(true);
			const response = await FlipchartService.publishFlipChart(flipchart.id, 'publish');
			setModalLoading(false);
			closeModal();
			if (response.statusCode === 201) {
				toaster(`You have successfully published "${flipchart.title}"`, Intent.SUCCESS);
				history.push('/flipcharts/' + response.data.id);
				getFlipchartDetails(response.data.id);
			} else {
				toaster(response?.error?.description, Intent.DANGER);
			}
		} catch (error) {
			toaster(error.error?.description, Intent.DANGER);
			closeModal();
			setModalLoading(false);
		}
	};

	const showCopyModal = row => {
		dispatchFlipchartnData({
			id: row.id,
			title: row.title,
		});

		setCopyModalOpen(true);
	};

	const showArchiveModal = row => {
		dispatchFlipchartnData({
			id: row.id,
			title: row.title,
			isArchived: row.archivedBy ? true : false,
		});
		setArchiveModalOpen(true);
	};

	const showDeleteModal = row => {
		dispatchFlipchartnData({
			id: row.id,
			title: row.title,
		});
		setDeleteModalOpen(true);
	};
	const loadTabPreviewModal = id => {
		setFlipchartId(id);
		setShowTabPreviewModal(true);
	};

	const showPublishModal = flipchart => {
		dispatchFlipchartnData(flipchart);
		setModalToShow(PUBLISH_MODAL);
	};

	const showUpdateModal = () => {
		setModalToShow(UPDATE_MODAL);
	};

	const cancelBtn = { title: 'No, go back', onClick: closeModal };
	const confirmCopyBtn = { title: 'Yes, copy it', onClick: handlConfirmCopyAction };
	const confirmArchiveBtn = {
		title: 'Yes, ' + (filpchartData.isArchived ? 'unarchive' : 'archive') + ' it',
		onClick: handleArchiveAction,
	};
	const confirmDeleteBtn = {
		title: 'Yes, delete it',
		onClick: handleDeleteAction,
		type: 'primaryDanger',
	};
	const confirmDeleteTabBtn = {
		title: selectedTaps.length > 1 ? 'Yes, delete them' : 'Yes, delete it',
		onClick: handleMultipleSingleDeleteTab,
		type: 'primaryDanger',
	};
	const confirmPublishBtn = {
		title: 'Yes, publish it',
		onClick: handleConfirmPublishAction,
	};
	const deleteIcon = { icon: 'trash', iconColor: 'error' };

	const getTabsSelected = tabsIds => {
		if (tabsIds.length > 0) {
			if (tabsIds.length === 1) {
				let title = flipchart.tabs.find(tab => tab.id === tabsIds[0]).title;
				setselectedSingleTapTitle(title);
			}
			setShowDeleteButton(true);
			setSelectedTaps(tabsIds);
		} else {
			setSelectedTaps([]);
		}
	};

	const PageHeaderCustomItems = (
		<div className="page-header-custom-items">
			{[MODIFIED_STATUS, DRAFT_STATUS].includes(flipchart.status) && (
				<>
					<Button
						size="lg"
						text={flipchart.status == DRAFT_STATUS ? 'Publish' : 'Publish changes'}
						intent="default"
						onClick={() => showPublishModal(flipchart)}
					/>{' '}
				</>
			)}
			{[MODIFIED_STATUS, DRAFT_STATUS, PUBLISH_STATUS].includes(flipchart.status) && (
				<>
					<div className="action-list">
						<ActionsList
							flipchart={flipchart}
							history={history}
							sourcePage={'details'}
							showCopyModal={showCopyModal}
							showArchiveModal={showArchiveModal}
							showDeleteModal={showDeleteModal}
							elementSelector={<Button type="tertiary" size="lg" icon="more" />}
						/>
					</div>
				</>
			)}
			{[ARCHIVED_STATUS].includes(flipchart.status) && (
				<>
					<Button
						size="lg"
						text={'Unarchive'}
						intent="default"
						onClick={() => {
							showArchiveModal(flipchart);
						}}
					/>
				</>
			)}
		</div>
	);

	return (
		<div>
			<ConfirmationModal
				isOpen={isCopyModalOpen}
				title={
					`Are you sure you would like to make a copy of "` + filpchartData.title + `"?`
				}
				subtitle=""
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmCopyBtn}
				loading={modalLoading}
				btnsSize="lg"
			/>
			<ConfirmationModal
				isOpen={isArchiveModalOpen}
				title={
					`Are you sure you would like to ` +
					(filpchartData.isArchived ? 'unarchive ' : 'archive ') +
					`"` +
					filpchartData.title +
					`"?`
				}
				showCloseButton={false}
				cancelBtn={cancelBtn}
				subtitle={
					`You can ` +
					(filpchartData.isArchived ? 'archive ' : 'unarchive ') +
					`it at a later time.`
				}
				confirmBtn={confirmArchiveBtn}
				loading={modalLoading}
			/>
			<ConfirmationModal
				isOpen={isDeleteModalOpen}
				title={`Are you sure you want to delete "${filpchartData.title}"?`}
				subtitle={`Once deleted, this cannot be recovered.`}
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmDeleteBtn}
				loading={modalLoading}
				icon={deleteIcon}
			/>
			<ConfirmationModal
				isOpen={modalToShow === PUBLISH_MODAL}
				title={`Are you sure you would like to publish "${filpchartData.title}"?`}
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmPublishBtn}
				loading={modalLoading}
			/>
			<ConfirmationModal
				isOpen={isDeleteTabModalOpen}
				title={
					'Are you sure you want to delete ' +
					(selectedTaps.length > 1
						? selectedTaps.length + ' tabs'
						: 'the tab "' + selectedSingleTapTitle + '"')
				}
				subtitle={`Once deleted, this cannot be recovered.`}
				showCloseButton={false}
				cancelBtn={cancelBtn}
				confirmBtn={confirmDeleteTabBtn}
				loading={modalLoading}
				icon={deleteIcon}
			/>

			{modalTabIsOpen && (
				<AddUpdateTabModal
					flipchart={flipchart}
					onSubmitFinished={getFlipchartDetails}
					modalIsOpen={modalTabIsOpen}
					setModalIsOpen={setModalTabIsOpen}
				/>
			)}

			{modalToShow == UPDATE_MODAL && (
				<AddUpdateFlipchartModal
					isOpen={modalToShow == UPDATE_MODAL}
					onCloseModal={closeModal}
					flipchart={flipchart}
					mode="edit"
					reloadFlipchart={getFlipchartDetails}
				/>
			)}

			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<div className="details-page-container">
					<div className="header-container">
						<PageHeader
							title={flipchart.title}
							subtitle={
								viewMode && flipchart.status == ARCHIVED_STATUS
									? 'Archived by ' +
									  flipchart.archiver?.name +
									  ' on ' +
									  flipchart.dateArchivedFormatted
									: null
							}
							breadcrumbItems={breadcrumbItems}
							customItem={() => PageHeaderCustomItems}
						/>
					</div>
					{notFoundState && (
						<EmptyState header="Flipchart doesn't exist" icon={emptyFiles} />
					)}
					{!notFoundState && (
						<div className="sections-container">
							<div className="tabs-section-container">
								{flipchart.tabs.length > 0 && (
									<>
										<section className="tabs-section-header-container">
											<div className="left-side">
												<>
													<span className="text">Tabs</span>
													<Badge
														key="sm-Gray"
														type="Gray"
														size="sm"
														text={flipchart.tabs.length}
													/>
												</>
											</div>
											{!viewMode && flipchart.status != ARCHIVED_STATUS && (
												<div className="right-side">
													<>
														<Button
															size="md"
															text={'New Tab'}
															icon="plus"
															type="secondaryDefault"
															intent="default"
															onClick={() => setModalTabIsOpen(true)}
														/>
														<Button
															size="md"
															text={'Preview'}
															icon="eye-open"
															type="tertiary"
															intent="default"
															onClick={() =>
																loadTabPreviewModal(flipchart.id)
															}
														/>
													</>
												</div>
											)}
										</section>
										{showDeleteButton &&
										selectedTaps.length > 0 &&
										singleDeleteTabFlag != true ? (
											<div className="delete-multiple">
												<Button
													icon="trash"
													size="md"
													text={'Delete ' + selectedTaps.length + ' tabs'}
													intent="default"
													type="tertiary"
													onClick={() => setDeleteTabModalOpen(true)}
												/>
											</div>
										) : (
											''
										)}

										<div className="tabs-section-content-container">
											<Tabs
												list={flipchart.tabs || []}
												flipchartId={flipchart.id}
												isArchived={
													viewMode && flipchart.status === ARCHIVED_STATUS
												}
												history={history}
												getTabsSelected={getTabsSelected}
												setDeleteTabModalOpen={setDeleteTabModalOpen}
												setSingleDeleteTabFlag={setSingleDeleteTabFlag}
												reloadFlipchartDetails={reloadFlipchartDetails}
												buildingId={flipchart.buildingId}
												flipchart={flipchart}
											/>
										</div>
									</>
								)}
								{flipchart.tabs.length == 0 && (
									<>
										{viewMode && flipchart.status == ARCHIVED_STATUS && (
											<>
												<EmptyState
													header={'No tabs were found'}
													// description={"Tabs add context to your flipchart so you can preview it with your school."}
													icon={emptyFiles}
												/>
											</>
										)}
										{!viewMode && flipchart.status != ARCHIVED_STATUS && (
											<EmptyState
												header={'Add your first tab'}
												description={
													'Tabs add context to your flipchart so you can preview it with your school.'
												}
												icon={emptyFiles}
											>
												<Button
													text="New tab"
													size="lg"
													icon="plus"
													type="secondaryDefault"
													intent="default"
													onClick={() => setModalTabIsOpen(true)}
												/>
											</EmptyState>
										)}
									</>
								)}
								{showTabPreviewModal && (
									<TabPreviewModal
										showTabPreviewModal={showTabPreviewModal}
										setShowTabPreviewModal={setShowTabPreviewModal}
										id={flipchartId}
									/>
								)}
							</div>
							<div className="cards-sections-container">
								<DetailsCardContainer
									viewMode={viewMode}
									flipchartCurrentlyPublished={flipchart || null}
									showUpdateModal={showUpdateModal}
								/>
								<PublishHistoryCardContainer
									flipchartHistory={flipchart.history}
									loadTabPreviewModal={loadTabPreviewModal}
								/>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
