import React, { useEffect, useState, useRef, useContext } from 'react';
import { FormModal, SearchBar, Badge, noResult, NavCard, Intent, FeaturedIcon } from 'componentsV2';
import { ReunificationService } from 'services';
import { ToasterContext } from 'pages/App';
import { ReunificationStudentsEmptyState } from '../../RollCallDetails/components/Reunification/Students/components/ReunificationStudentsEmptyState';
import './css/AddStudentToReunificationModal.scss';

export const AddStudentToReunificationModal = ({ handleCancel, alarmId }) => {
	const [studentsList, setStudentsList] = useState([]);
	const [currentSelectedStudent, setCurrentSelectedStudent] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [totalStudents, setTotalStudents] = useState();
	const toaster = useContext(ToasterContext);
	const [submitDisabled, setsubmitDisabled] = useState(true);
	const filterationData = useRef({
		page: 1,
		perPage: 10,
		studentName: '',
		sortKey: 'studentName',
		sortDir: 'asc',
	});

	const HandleSave = () => {
		let body = {
			students: [
				{
					id: currentSelectedStudent.id,
					status: 'checkedIn',
				},
			],
			isManual: true,
		};
		ReunificationService.checkStudentIn(alarmId, body).then(response => {
			if (response.statusCode === 201) {
				handleCancel();
				let studentName =
					currentSelectedStudent.firstName +
					' ' +
					currentSelectedStudent.middleInitial?.substring(0, 1) +
					'. ' +
					currentSelectedStudent.lastName;
				toaster(
					'You have successfully checked-in to reunification student ' +
						studentName +
						'.',
					Intent.SUCCESS,
					<FeaturedIcon
						icon={'tick'}
						type={'Success'}
						size={'md'}
						shape={'circleIcon'}
					/>,
				);
			}
		});
	};

	const getStudentsList = (appendData = false) => {
		ReunificationService.getStudentsList(alarmId, filterationData.current)
			.then(response => {
				if (response.statusCode === 200) {
					if (appendData) {
						setStudentsList(oldContent => [...oldContent, ...response.data]);
					} else {
						if (response.data.length > 0) {
							setStudentsList(response.data);
						}
					}
					setTotalStudents(response._metadata.totalRecords);
				}
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			});
	};

	const rightContent = item => {
		let type = '';
		let text = '';
		if (item.reunified) {
			type = 'Primary';
			text = 'Reunified';
		} else {
			if (item.checkIn) {
				switch (item.checkIn.status) {
					case 'checkedIn':
						type = 'Success';
						text = 'Checked-in';
						break;
					case 'absent':
						type = 'Gray';
						text = 'Absent';
						break;
					case 'transferred':
						type = 'Gray';
						text = 'Transferred to reunification';
						break;
					default:
						type = 'Primary';
						text = item.checkIn.status;
				}
			} else {
				type = 'Warning';
				text = 'Not Checked-in';
			}
		}

		return (
			<span className="badgesWrapper">
				<Badge type={type} size="md" text={text} />
				{item.guardianCheckIn?.length > 0 && (
					<span className="AssociatedWrapper">
						<Badge
							type="Gray"
							size="md"
							text={'Associated with ' + item.guardianCheckIn[0]['name']}
						/>
					</span>
				)}
			</span>
		);
	};

	const handleChangeSelection = target => {
		let currentSelected = studentsList.find(item => item.id == target.id);
		setCurrentSelectedStudent(currentSelected);
		setsubmitDisabled(false);
	};

	useEffect(() => {
		getStudentsList();
	}, []);

	const handleSearch = name => {
		filterationData.current.studentName = name;
		filterationData.current.page = 1;
		setSearchValue(name);
		getStudentsList();
	};

	const debouncedScrollHandler = _.debounce(async () => {
		let modal = document.getElementsByClassName('modal-content-wrapper');
		if (modal.length > 0) {
			// Check if any element was found with the specified class
			modal = modal[0];
			if (window.innerHeight + modal.scrollTop >= modal.scrollHeight) {
				filterationData.current.page += 1;
				getStudentsList(true);
			}
		}
	}, 900); // Set the debounce delay to 500 milliseconds

	const handleScroll = () => {
		let modal = document.getElementsByClassName('modal-content-wrapper');
		if (modal.length > 0) {
			// Check if any element was found with the specified class
			modal = modal[0];

			modal.addEventListener('scroll', debouncedScrollHandler());
		}
	};

	return (
		<>
			<FormModal
				isOpen={true}
				onClose={handleCancel}
				showCloseButton
				customClassName={'add-student-manual-modal'}
				size="medium"
				title="Add student to Reunification"
				subtitle="If a student is not on the Reunification list, you can manually add them by searching the student directory. "
				scrollContent={true}
				confirmBtn={{
					title: 'Check-in',
					onClick: HandleSave,
					disabled: submitDisabled,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: () => handleCancel(),
				}}
				onScrollHandller={handleScroll}
			>
				<div className="add-student-to-reunification-container">
					<div className="students-search">
						<SearchBar
							placeholder={'Search by student’s name'}
							icon="search"
							className={'input-search'}
							value={searchValue}
							onSearch={e => handleSearch(e)}
						/>
					</div>

					{totalStudents === 0 ? (
						<ReunificationStudentsEmptyState
							header="No result found"
							description={
								<>
									Your search didn’t match any student.
									<br /> Please try again.
								</>
							}
							icon={noResult}
							showCircles={false}
						/>
					) : (
						<>
							{studentsList.map(item => {
								return (
									<div className="NavWrapper">
										<NavCard
											name={
												item.firstName +
												' ' +
												item.middleInitial?.substring(0, 1) +
												'. ' +
												item.lastName
											}
											key={item.id}
											subtext={`Grade:  ${item.grade}`}
											selected={item.id === currentSelectedStudent?.id}
											onClick={() => handleChangeSelection(item)}
											rightElement={rightContent(item)}
											showIcon={false}
											disabled={
												item.checkIn?.status === 'checkedIn' ||
												item.checkIn?.status === 'transferred' ||
												item.reunified
											}
										/>
									</div>
								);
							})}
						</>
					)}
				</div>
			</FormModal>
		</>
	);
};
