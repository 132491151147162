import React from 'react';
import PropTypes from 'prop-types';
import { EmptyState } from 'componentsV2';

export const ReunificationGuardiansEmptyState = ({
	header,
	description,
	icon,
	showCircles = true,
}) => {
	return (
		<EmptyState
			header={header}
			description={description}
			className="mt-2"
			bodyClass={showCircles ? 'h-auto' : 'position-relative h-auto'}
			icon={icon}
			showCircles={showCircles}
		/>
	);
};

ReunificationGuardiansEmptyState.propTypes = {
	header: PropTypes.string.isRequired,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	icon: PropTypes.element.isRequired,
	showCircles: PropTypes.bool,
};
