import React, { useCallback, useEffect, useState } from 'react';
import { RapidAlarmService } from 'services';
import { LoadingSpinner } from 'componentsV2';
import { MyClassesContent } from './';

export const MyClasses = ({
	alarm,
	initialData,
	filterData,
	setFilterData,
	setDisableFilter,
	rosterUpdateEvent,
	selectedRoster = null,
}) => {
	const [loading, setLoading] = useState(true);
	const [loadingClasses, setLoadingClasses] = useState(false);
	const [classesList, setClassesList] = useState([]);
	const [totalClasses, setTotalClasses] = useState(0);
	const [classSelected, setClassSelected] = useState(null);
	const [customListClass, setCustomListClass] = useState(null);
	const loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
	const isLoggedUserAdmin = loggedUser.accountType == 'administrator';

	const [myClassesParams, setMyClassesParams] = useState({
		page: 1,
		perPage: 20,
		alarmId: alarm.id,
		includeAllClasses: isLoggedUserAdmin,
		rosterId: selectedRoster?.rostersid,
	});

	const getClasses = (params, appendData = true) => {
		setLoadingClasses(true);
		RapidAlarmService.getRollCallMyClasses(params)
			.then(response => {
				if (response.statusCode === 200 && response.data) {
					const newContent = isLoggedUserAdmin
						? [...response.data.allRosters.records, ...response.data.myRosters.records]
						: response.data.myRosters.records;

					const getCustomListClass = newContent.find(
						classItem => classItem.isCustom && classItem.user?.id === loggedUser.id,
					);

					if (getCustomListClass) {
						setCustomListClass(getCustomListClass);
					}

					if (
						selectedRoster &&
						selectedRoster.is_custom == true &&
						selectedRoster.created_by == loggedUser.id
					) {
						setClassSelected(getCustomListClass);
					} else {
						getSelectedClass(newContent);
					}

					const updatedClassesList = newContent.filter(
						classItem =>
							!classItem.isCustom ||
							(classItem.isCustom && classItem.user?.id !== loggedUser.id),
					);

					if (appendData) {
						setClassesList(oldContent => [...oldContent, ...updatedClassesList]);
					} else {
						setClassesList(updatedClassesList);
					}

					setTotalClasses(
						isLoggedUserAdmin
							? parseInt(response.data.allRosters.metadata.totalRecords) +
									parseInt(response.data.myRosters.metadata.totalRecords)
							: response.data.myRosters.metadata.totalRecords,
					);
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
				setLoadingClasses(false);
			});
	};

	const getSelectedClass = useCallback(
		classesList => {
			if (!classSelected && classesList.length > 0) {
				const indexFound = classesList.findIndex(classItem => {
					return (
						!classItem.isCustom ||
						(classItem.isCustom && classItem.user?.id !== loggedUser.id)
					);
				});
				if (indexFound > -1) {
					setClassSelected(classesList[indexFound]);
				} else {
					setClassSelected(classesList[0]);
				}
			}
		},
		[classSelected],
	);

	const onScrollClassList = () => {
		let myClassesList = document.getElementById('my-classes-list');
		myClassesList.addEventListener('scroll', debouncedScrollHandler());
	};

	const debouncedScrollHandler = _.debounce(async () => {
		let myClassesList = document.getElementById('my-classes-list');

		if (
			Math.round(myClassesList.offsetHeight) + Math.round(myClassesList.scrollTop) >=
			myClassesList.scrollHeight
		) {
			if (classesList.length < totalClasses) {
				setMyClassesParams(oldData => ({
					...oldData,
					page: oldData.page + 1,
				}));
			}
		}
	}, 300);

	useEffect(() => {
		const params = {
			page: 1,
			perPage: myClassesParams.page * 20,
			alarmId: alarm.id,
			includeAllClasses: isLoggedUserAdmin,
			rosterId: selectedRoster?.rostersid,
		};
		getClasses(params, false);
	}, [rosterUpdateEvent]);

	useEffect(() => {
		if (myClassesParams.page > 1) {
			getClasses(myClassesParams);
		}
	}, [myClassesParams.page]);

	useEffect(() => {
		setDisableFilter(!classSelected);
	}, [classSelected]);

	return (
		<>
			{loading && <LoadingSpinner className="mt-3" />}
			{!loading && (
				<MyClassesContent
					loadingClasses={loadingClasses}
					classesList={classesList}
					customListClass={customListClass}
					classSelected={classSelected}
					setClassSelected={setClassSelected}
					filterData={filterData}
					setFilterData={setFilterData}
					initialData={initialData}
					onScrollClassList={onScrollClassList}
					alarm={alarm}
					rosterUpdateEvent={rosterUpdateEvent}
					setDisableFilter={setDisableFilter}
				/>
			)}
		</>
	);
};
