import { getAuthToken, isSuccessfulRequest } from './HttpService';

export const HttpChatService = async request => {
	const token = await getAuthToken();
	const apiUrl = process.env.API_URL_CHAT;

	const config = {
		method: request.method,
		headers: {
			accept: '*/*',
			'content-type': 'application/json',
		},
	};

	// add the token if it is available
	if (token && !request.headers?.Authorization) {
		config.headers = {
			...config.headers,
			authorization: `Bearer ${token}`,
		};
	}

	// allow devs to override headers
	if (request.headers) {
		config.headers = {
			...config.headers,
			...request.headers,
		};
	}

	// addd and build request body
	if (request.body) {
		config.body = JSON.stringify(request.body);
	}

	// return request promise
	try {
		const response = await fetch(apiUrl + request.route, config);

		if (isSuccessfulRequest(response?.status)) {
			// parse the response into json, successful response to your '.then()' block
			if (response.headers.get('content-type')?.includes('application/json')) {
				return response.json();
			}
			return response.blob();
		}

		// parse the error, to fail the request
		const error = await response.json();
		throw error;
	} catch (err) {
		// throw or send the error to your ".catch()" or "} catch {" blocks
		if (err.statusCode == 401) {
			const localToken = JSON.parse(localStorage.getItem('user'));
			localToken.jwt = '';
			localStorage.setItem('user', JSON.stringify(localToken));
			return (window.location.href = '/login');
		}
		throw err;
	}
};
