import React from "react";
import {CardContainer, Badge, Button, Color} from "componentsV2";
import "../css/DetailsCardContainer.scss"
import {
    MODIFIED_STATUS,
    DRAFT_STATUS,
    ARCHIVED_STATUS,
    PUBLISH_STATUS,
    STATUS_LABELS,
} from "./statuses";

export const DetailsCardContainer = ({
    flipchartCurrentlyPublished = {},
    viewMode=false,
    showUpdateModal
}) => {

    const getType = (status) => {
        switch (status) {
            case DRAFT_STATUS:
                return 'Primary';
            case MODIFIED_STATUS:
                return 'Warning';
            case PUBLISH_STATUS:
                return 'Success';
            case ARCHIVED_STATUS :
                return 'Gray';
        }
    }

    const DetailsCardSideItems = <Button
        size="md"
        text={'Edit'}
        type="ghostPrimary"
        intent="default"
        onClick={() => showUpdateModal()}
    />

    return (<CardContainer
        title="Details"
        rightAction={viewMode ? null : DetailsCardSideItems} >
        <div className="details-card-container">
            {flipchartCurrentlyPublished && (<div className="details-content">
                <div className="details-row">
                    <div className="left-side">
                        <div className="label">Site</div>
                        <div className="content">
                            <div className="text">{flipchartCurrentlyPublished.building ? flipchartCurrentlyPublished.building : 'Visible to all'}</div>
                        </div>

                    </div>
                </div>
                <div className="details-row">
                    <div className="left-side">
                        <div className="label">Status</div>
                        <div className="content">

                            <Badge
                                key="status"
                                type={getType(flipchartCurrentlyPublished.status)}
                                size="md"
                                text={STATUS_LABELS.find((status) => status.value === flipchartCurrentlyPublished.status).label}
                            />
                        </div>

                    </div>
                    <div className="right-side">
                        <div className="label">Created by</div>
                        <div className="content">
                            <div className="text">{flipchartCurrentlyPublished.creator.name}</div>
                        </div>
                    </div>
                </div>
                <div className="details-row">
                    <div className="left-side">
                        <div className="label">Date created</div>
                        <div className="content">
                            <div
                                className="text">{flipchartCurrentlyPublished.dateEnteredFormatted}</div>
                        </div>
                    </div>
                    <div className="right-side">
                        <div className="label">Last published</div>
                        <div className="content">
                            <div
                                className="text">{flipchartCurrentlyPublished.datePublishedFormatted}</div>
                        </div>
                    </div>
                </div>
                <div className="details-row">
                    <div className="left-side">
                        <div className="label">Theme type</div>
                        <div className="content">
                            <div className="text">{flipchartCurrentlyPublished.theme}</div>
                        </div>
                    </div>
                   
                    {['stripes', 'gradient', 'solid'].includes(flipchartCurrentlyPublished.theme) &&
                        <div className="right-side">
                            <div className="label">Colors</div>
                            <div className="content">
                                <div className="color">
                                    <Color
                                        key={"primaryColor"}
                                    colorClass={"primary-color"}
                                    customStyle={{
                                        backgroundColor: '#' + flipchartCurrentlyPublished.primaryColor,
                                        border: `2px solid #D9DCDD`,
                                    }}
                                    isSelected={true}
                                    size="sm"
                                />
                                    {['stripes'].includes(flipchartCurrentlyPublished.theme) &&
                                <Color
                                    key={"secondaryColor"}
                                    colorClass={"secondary-color"}
                                    customStyle={{
                                        backgroundColor: '#' + flipchartCurrentlyPublished.secondaryColor,
                                        border: `2px solid #D9DCDD`,
                                    }}
                                    isSelected={true}
                                    size="sm"
                                />
                                    }
                            </div>
                        </div>
                      
                    </div>
                    
                    }
                </div>
                <div className="details-row">
                    <div className="left-side">
                        <div className="label">Description</div>
                        <div className="content">
                            <div className="text">{flipchartCurrentlyPublished.description ? flipchartCurrentlyPublished.description : 'N/A' }</div>
                        </div>

                    </div>
                </div>
            </div>)}
        </div>
    </CardContainer>);
}