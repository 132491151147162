import React, { createContext, useState, useReducer, useContext } from "react";
import { ToasterContext } from 'pages/App';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import { TYPE_360IMAGE , TYPE_IMAGE } from './consts';
import {
    DownloadModal,
    DownloadModalFn,
    RenameModal,
    RenameModalFn,
    MoveModal,
    MoveModalFn,
    WhereItsUsedModal,
    WhereItsUsedModalFn,
    RevisionHistory,
    RevisionHistoryFn,
    DocumentPdfFn,
    AttachToBinder,
    DeleteModal,
    DeleteModalFn,
    UploadDocumentModal,
    UploadDocumentFn,
} from './ProviderComponents';
import { Modal, LoadingModal } from 'componentsV2';

export const DocumentModalsContext = createContext();

export const DocumentModalsProvider = ({
    children,
    history,
    getDocumentsList,
    setTbLoading,
    selectedSites
}) => {
    const [modalToShow, setModalToShow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedDownloadOption, setSelectedDownloadOption] = useState("1");
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const toaster = useContext(ToasterContext);

    const RENAME_MODAL = 'renameDocumentModal';
    const WHERE_ITS_USED_MODAL = 'whereItsUsedModal';
    const ATTACH_TO_BINDER_MODAL = 'attachToBinderModal';
    const DELETE_MODAL = 'deleteModal';
    const MOVE_MODAL = 'moveDocumentModal';
    const VIEW_IMAGE_MODAL = 'viewImageModal';

    const documentDataReducer = (documentData, action) => {

        return {
            id: action.id,
            title: action.title,
            oldTitle: action.oldTitle,
            type: action.type,
            modalTitle: action.modalTitle,
            modalSubTitle: action.modalSubTitle,
            confirmBtnTitle: action.confirmBtnTitle,
            onConfirmAction: action.onConfirmAction,
            ext: action.ext,
            locations: action.locations,
            bulkIds: action.bulkIds,
            displayTitle: action.displayTitle,
            panoramasIds: action.panoramasIds,
            ids: action.ids,
            siteId: action.siteId,
            siteName: action.siteName,
            fileUrl: action.fileUrl,
            thumbnail: action.thumbnail,
            mimetype: action.mimetype,
            base64: action.base64,
        }
    }

    const [documentData, dispatchDocumentData] = useReducer(documentDataReducer, {
        id: null,
        title: '',
        oldTitle: '',
        type: '',
        modalTitle: '',
        modalSubTitle: '',
        confirmBtnTitle: '',
        ext: '',
        locations: [],
        bulkIds: {},
        displayTitle: '',
        ids: [],
        panoramasIds: [],
        siteId: null,
        siteName: '',
        onConfirmAction: () => {
        },
        fileUrl: null,
        thumbnail: null,
        mimetype: null,
        base64: null
    });
    const closeModal = () => {
        setModalToShow(null);
    }

    const {
        showDownloadModal,
        downloadDocumentHandler,
        showMultipleDownloadModal,
        handleDownloadOption,
    } = DownloadModalFn(
        modalToShow,
        loading,
        setLoading,
        documentData,
        dispatchDocumentData,
        closeModal,
        selectedDownloadOption,
        toaster,
        setSelectedDownloadOption,
        setModalToShow
    );

    const {
        showRenameModal,
        handleChangeTitle,
        handleRenameSubmit,
    } = RenameModalFn(
        RENAME_MODAL,
        setLoading,
        documentData,
        dispatchDocumentData,
        closeModal,
        toaster,
        setModalToShow,
        getDocumentsList,
    );
    const {
        showMoveModal,
        handleChangeSite,
        handleMoveSubmit,
        selectedSite,
        selectedDocumentsToMove,
        inputErrors
    } = MoveModalFn(
        MOVE_MODAL,
        setLoading,
        documentData,
        dispatchDocumentData,
        closeModal,
        toaster,
        setModalToShow,
        getDocumentsList,
    );

    const {
        showWhereItsUsedmodal,
    } = WhereItsUsedModalFn(
        WHERE_ITS_USED_MODAL,
        setLoading,
        documentData,
        dispatchDocumentData,
        setModalToShow,
        toaster,
    );

    const {
        showRevisionHistoryModal,
        revisionHistoryList,
        showDownloadRevision
    } = RevisionHistoryFn(
        setLoading,
        dispatchDocumentData,
        showDownloadModal,
        setModalToShow,
        downloadDocumentHandler,
        toaster
    )

    const {
        OpenDocumentAsPdf,
    } = DocumentPdfFn(
        setTbLoading,
        toaster
    )

    const {
        showUploadModal,
        documentDetails
    } = UploadDocumentFn(
        setModalToShow,
        documentData,
        dispatchDocumentData
    )

    const {
        showDeleteModal,
        showMultipleDeleteModal,
        handleDeleteSubmit,
        handleMultipleDeleteSubmit,
    } = DeleteModalFn(
        DELETE_MODAL,
        setLoading,
        documentData,
        dispatchDocumentData,
        setModalToShow,
        closeModal,
        getDocumentsList,
        toaster,
    );

    const showAttachToBinderModal = (documents) =>{
        setSelectedDocuments(documents);
        setModalToShow(ATTACH_TO_BINDER_MODAL);
    }

    const showViewImageModal = async (document) => {
        if(document.type == TYPE_IMAGE) {
            dispatchDocumentData({
                ...documentData,
                type: document.type,
                thumbnail: document.thumbnail,
                fileUrl: document.fileUrl,
            }); 
        } else if(document.type == TYPE_360IMAGE) {
            dispatchDocumentData({
                ...documentData,
                type: document.type,
                mimetype: document.mimetype,
                base64: document.base64
            }); 
        }
        setModalToShow(VIEW_IMAGE_MODAL);
    }

    const modalFunctions = {
        showDownloadModal,
        showMultipleDownloadModal,
        showRenameModal,
        showWhereItsUsedmodal,
        showRevisionHistoryModal,
        OpenDocumentAsPdf,
        showAttachToBinderModal,
        showUploadModal,
        showMultipleDeleteModal,
        showDeleteModal,
        showMoveModal,
        showViewImageModal
    }

    return (
        <DocumentModalsContext.Provider value={modalFunctions}>
            <LoadingModal
                isOpen={modalToShow === 'automaticDocumentModal'}
                loading={loading}
                onClose={closeModal}
                customClassName={'map-container-modals'}
            />
            <Modal
                isOpen={modalToShow === VIEW_IMAGE_MODAL}
                onClose={closeModal}
                customClassName={'map-container-modals'}
                customBodyClass={'body-no-padding-top'}
                customHeaderClass={'header-no-border'}
                showCloseButton={true}
                size={documentData.type == TYPE_360IMAGE ? 'large' : 'auto'}
            >
                { documentData.fileUrl ? <img src={documentData.fileUrl} width={"100%"} />  : null }
                { documentData.base64 ? <ReactPhotoSphereViewer src={`data:${documentData.mimetype};base64,${documentData.base64}`} height={'100vh'} width={"100%"}></ReactPhotoSphereViewer> : null }
            </Modal>
            <DownloadModal
                modalToShow={modalToShow}
                loading={loading}
                setLoading={setLoading}
                documentData={documentData}
                dispatchDocumentData={dispatchDocumentData}
                closeModal={closeModal}
                toaster={toaster}
                selectedDownloadOption={selectedDownloadOption}
                setSelectedDownloadOption={setSelectedDownloadOption}
                setModalToShow={setModalToShow}
                handleDownloadOption={handleDownloadOption}
            />

            <RenameModal
                modalName={RENAME_MODAL}
                modalToShow={modalToShow}
                loading={loading}
                documentData={documentData}
                closeModal={closeModal}
                handleChangeTitle={handleChangeTitle}
                handleSubmit={handleRenameSubmit}
            />

            <WhereItsUsedModal
                modalName={WHERE_ITS_USED_MODAL}
                modalToShow={modalToShow}
                loading={loading}
                documentData={documentData}
                closeModal={closeModal}
                history={history}
            />

            <RevisionHistory
                modalToShow={modalToShow}
                loading={loading}
                documentData={documentData}
                closeModal={closeModal}
                revisionHistoryList={revisionHistoryList}
                showDownloadRevision={showDownloadRevision}
            />
            {modalToShow === ATTACH_TO_BINDER_MODAL && (
                <AttachToBinder
                    documents={selectedDocuments}
                    closeModal={closeModal}
                />
            )}

            <DeleteModal
                modalName={DELETE_MODAL}
                modalToShow={modalToShow}
                loading={loading}
                documentData={documentData}
                closeModal={closeModal}
                handleDeleteSubmit={handleDeleteSubmit}
                handleMultipleDeleteSubmit={handleMultipleDeleteSubmit}
            />

            <MoveModal
                modalName={MOVE_MODAL}
                modalToShow={modalToShow}
                loading={loading}
                documentData={documentData}
                documents={selectedDocumentsToMove}
                closeModal={closeModal}
                handleChangeSite={handleChangeSite}
                handleSubmit={handleMoveSubmit}
                selectedSite={selectedSite}
                inputErrors={inputErrors}
            />


            {modalToShow === 'uploadModal' && (
                <UploadDocumentModal
                    showUploadModal={modalToShow === 'uploadModal'}
                    selectedSites={selectedSites}
                    closeModal={closeModal}
                    documentDetails={documentDetails}
                    reloadList={getDocumentsList}
                />
            )}

            {children}
        </DocumentModalsContext.Provider>
    );
}