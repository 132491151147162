export const QUESTION_ANSWERS_TYPES = {
	YN: 'yn',
	AREA: 'area',
	SINGLE_SELECT: 'select',
	MULTIPLE_SELECT: 'multiple',
};

export const QUESTION_ANSWERS_LABELS = {
	YN: '',
	AREA: 'Text area',
	SINGLE_SELECT: 'Custom select (single answer)',
	MULTIPLE_SELECT: 'Multiple select (multiple answers)',
};
