import React from 'react';
import { BootstrapedTable, sortingStyle, TableCell, LoadingSpinner } from 'componentsV2';
import { formatDate } from 'utilities/dates';
import { checkIsNavigateEmail } from 'utilities';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ActionsList, MAPS_VIEW_PATH, OldSafetyPlanTooltip } from '..';
import { Icon } from '@blueprintjs/core';

export const Table = ({
	list,
	totalRows,
	filterationData,
	setFilterationData,
	editPermission,
	setSelectedRows,
	selectedRows,
	history,
	loading,
	setLoading,
	hasEditPermissionOnBinders,
	isSysAdmin,
}) => {
	const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
	const userLoggedEmail = loggedUserData.person.email?.emailAddress;
	const isNavigateEmail = userLoggedEmail ? checkIsNavigateEmail(userLoggedEmail) : false;
	const getRedirectionPath = map => {
		if (map.editPermission && !map.archivedBy) {
			return `/${MAPS_VIEW_PATH}/${map.building?.id}/${map.id}`;
		}
		return `/${MAPS_VIEW_PATH}/${map.building?.id}/${map.id}`;
	};

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'disabled',
			dataField: 'disabled',
			hidden: true,
		},
		{
			key: 'title',
			dataField: 'title',
			text: 'Name',
			sort: true,
			classes: 'first-column',
			sortCaret: (order, column) =>
				sortingStyle(
					filterationData.sortKey === 'title' ? filterationData.sortDir : order,
					column,
				),
			formatter: (value, row) => {
				const hasEditPermissionOnBinder = hasEditPermissionOnBinders(row);
				return (
					<TableCell
						text={
							<NavLink
								exact
								to={{
									pathname: getRedirectionPath(row),
								}}
								target="_blank"
							>
								{value}
							</NavLink>
						}
						supportText={row.archivedBy ? 'Archived' : ''}
						rightIcon={
							row.hasOldSP && (
								<OldSafetyPlanTooltip
									message={
										hasEditPermissionOnBinder
											? `This map contains an area or icon with a binder with an outdated safety plan.
											Please go to Binders and update the outdated plans in order to download binders
											from maps.`
											: `This map contains an area or icon with a binder with an outdated safety plan.
											Please contact your administrator to update these plans in the Binders section before downloading binders from the maps.`
									}
									editPermissionOnBinders={hasEditPermissionOnBinder}
								>
									<Icon icon="warning-sign" intent="warning" />
								</OldSafetyPlanTooltip>
							)
						}
					/>
				);
			},
		},
		{
			key: 'building',
			dataField: 'building',
			text: 'Site',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(
					filterationData.sortKey === 'site' ? filterationData.sortDir : order,
					column,
				),
			formatter: (value, row) => (
				<TableCell
					text={value?.name}
					supportText={row.isVisibleToAll ? 'Visible to all' : ''}
					fontSize="sm"
				/>
			),
		},
		{
			key: 'dateModified',
			dataField: 'dateModified',
			text: 'Last Modified',
			sort: true,
			sortCaret: (order, column) =>
				sortingStyle(
					filterationData.sortKey === 'dateModified' ? filterationData.sortDir : order,
					column,
				),
			formatter: (value, row) => (value?.date ? formatDate(value.date) : 'N/A'),
		},
		{
			key: 'actions',
			dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
				<ActionsList
					map={row}
					history={history}
					setLoading={setLoading}
					isNavigateEmail={isNavigateEmail}
					isSysAdmin={isSysAdmin}
				/>
			),
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'name';
			switch (sortField) {
				case 'building':
					sortColumn = 'site';
					break;
				default:
					sortColumn = sortField;
					break;
			}
			setFilterationData(oldData => ({
				...oldData,
				sortKey: sortColumn,
				sortDir:
					sortOrder == oldData.sortDir
						? oldData.sortDir == 'asc'
							? 'desc'
							: 'asc'
						: sortOrder,
			}));
		}
	};

	const setRowsPerPage = value => {
		setFilterationData(oldData => ({ ...oldData, perPage: value }));
	};

	const setCurrentPage = value => {
		setFilterationData(oldData => ({ ...oldData, page: value }));
	};

	return (
		<>
			{loading && (
				<div className="mt-3">
					<LoadingSpinner />
				</div>
			)}
			{!loading && (
				<BootstrapedTable
					keyField="id"
					data={list}
					columns={columns}
					totalRows={totalRows}
					onTableChange={onTableChange}
					currentPage={filterationData.page}
					rowsPerPage={filterationData.perPage}
					setCurrentPage={setCurrentPage}
					setRowsPerPage={setRowsPerPage}
					onSelectRows={setSelectedRows}
					selectedRows={selectedRows}
					hasCheckbox={editPermission}
				/>
			)}
		</>
	);
};

Table.propTypes = {
	list: PropTypes.array.isRequired,
	editPermission: PropTypes.bool.isRequired,
	totalRows: PropTypes.number,
	filterationData: PropTypes.array,
	setFilterationData: PropTypes.func,
	setSelectedRows: PropTypes.func,
	selectedRows: PropTypes.array,
	loading: PropTypes.bool,
	setLoading: PropTypes.func,
};
