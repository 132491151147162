import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './css/AlarmIcon.scss';
import InputWrapper from 'componentsV2/InputWrapper/InputWrapper';
import { RAPID_ICONS } from 'assets/respond';
import { AlarmSelectedIcon } from './';

export const AlarmIcon = ({ formData, formErrors, onChangeFormData }) => {
	const [iconsList, setIconsList] = useState([]);

	useEffect(() => {
		mapRapidIcons();
	}, []);

	const mapRapidIcons = () => {
		const mappedIcons = [];
		for (const [key, value] of Object.entries(RAPID_ICONS)) {
			mappedIcons.push({
				name: key,
				link: value,
			});
		}
		setIconsList(mappedIcons);
	};

	const onSelectIcon = icon => {
		onChangeFormData({ type: 'icon', payload: icon });
	};

	const hasError = formErrors?.icon ? true : false;

	return (
		<div className="create-alarm-alarm-icon-field">
			<div className="create-alarm-alarm-icon-field-selected-icon">
				{formData.icon && (
					<>
						<AlarmSelectedIcon
							icon={formData.icon}
							className="create-alarm-alarm-icon-field-selected-icon-icon"
						/>
						<p className="create-alarm-alarm-icon-field-selected-icon-text">
							Current icon
						</p>
					</>
				)}
				{!formData.icon && (
					<p className="create-alarm-alarm-icon-field-selected-icon-text">
						Please select an icon
					</p>
				)}
			</div>
			<div className="create-alarm-alarm-icon-field-icons-container">
				<InputWrapper label="Icons" labelState={false} />
				<div
					className={`create-alarm-alarm-icon-field-icons-container-list ${
						hasError ? 'error' : ''
					}`}
				>
					{iconsList.map(icon => (
						<img
							className={`create-alarm-alarm-icon-field-icons-container-list-icon ${
								formData.icon === icon.name ? 'selected' : ''
							}`}
							key={icon.name}
							name={icon.name}
							title={icon.name}
							src={icon.link}
							onClick={() => onSelectIcon(icon.name)}
						/>
					))}
				</div>
				{hasError && (
					<p className="create-alarm-alarm-icon-field-icons-container-list-error-text">
						{formErrors.icon}
					</p>
				)}
			</div>
		</div>
	);
};

AlarmIcon.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
};
