import React from 'react';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { StatusList } from './StatusList';
import { SearchBar } from 'components/SearchBar';
import '../css/Filteration.scss';

export const Filteration = ({ defaultSelectedSites, setFilterationData, editPermission }) => {
	const [selectedSites, setSelectedSites] = useState([]);

	const onSearchHandler = title => {
		setFilterationData(oldData => ({
			...oldData,
			title: title,
		}));
	};

	const onChangeSiteHandler = sites => {
		setFilterationData(oldData => ({
			...oldData,
			buildingIds: sites.map(site => site.value),
		}));
		setSelectedSites(sites);
	};

	return (
		<section className="filteration-container">
			<div className="left-side">
				<SearchBar
					placeholder="Search Flipcharts by name..."
					icon="search"
					className="search-bar-text"
					onSearch={onSearchHandler}
				/>
			</div>
			<div className="right-side">
				<SitesDropdownMultiSelect
					label={null}
					value={selectedSites}
					placeholder="Select a Site"
					onChange={onChangeSiteHandler}
					visibleOptions={1}
					labelState={false}
					className="sites-mddl"
					allowSelectAll={false}
				/>
				{editPermission && <StatusList setFilterationData={setFilterationData} />}
			</div>
		</section>
	);
};

Filteration.propTypes = {
	defaultSelectedSites: PropTypes.array,
	setFilterationData: PropTypes.func.isRequired,
};
