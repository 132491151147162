import React from 'react';
import './css/GuardianDetails.scss';
import { Helper } from 'utilities/helper';
import { Tooltip, TooltipPosition } from 'componentsV2';

const GuardianItem = ({ title, value }) => (
	<div className="AddStudsents__Item">
		<label className="GuardianLabel">{title}</label>
		<div className="GuardianValue_Wrapper">
			{value && (
				<Tooltip tooltipContent={value} theme="dark" position={TooltipPosition.BOTTOM}>
					<p className="GuardianValue">{Helper.formatDigitsToPhone(value)}</p>
				</Tooltip>
			)}
			{!value && <p className="GuardianValue"> N/A</p>}
		</div>
	</div>
);
export const GuardianDetails = ({ formData, selectedGuardian = null, addAsNew }) => {
	const applyFormData = addAsNew || !selectedGuardian;

	return (
		<div className="topSection_Wrapper">
			<div className="AddStudsents__contents">
				<GuardianItem
					title="Guardian"
					value={!applyFormData ? selectedGuardian.name : formData.name}
				/>
				<GuardianItem
					title="Mobile number"
					value={!applyFormData ? selectedGuardian.phone : formData.mobilePhone}
				/>
				<GuardianItem
					title="Email address"
					value={!applyFormData ? selectedGuardian.email : formData.email}
				/>
			</div>
		</div>
	);
};
