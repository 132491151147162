import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog } from '@blueprintjs/core';
import { cancel } from 'assets/icons';
import { Button } from 'components/Button';

const Container = styled.div`
	h2 {
		color: #10161a;
		font-size: 15px;
		margin-top: 16px;
		font-weight: bold;
		line-height: 22px;
		font-style: normal;
		letter-spacing: 0.4px;
		font-family: Merriweather;
	}

	.modal-card {		
		width: 100%;
		padding: 16px;
		border-radius: 3px;

		.modal-card-header {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;

			.modal-card-title {
				color: #10161a;
				font-size: 18px;
				font-weight: bold;
				line-height: 21px;
				font-style: normal;
				font-family: Merriweather;
			}

			img {
				padding: 10px;
				cursor: pointer;
				border-radius: 5px;
				margin-top: -7.5px;
				margin-right: -10px;

				&:hover {
					background-color: #e1e8ed;
				}
			}
		}

		.modal-card-underline {
			width: 100%;
			height: 1px;
			background: #d8e1e8;
		}

		.action-btn-cont {
			display: flex;
			margin-top: 16px;
			flex-direction: row;

			.modal-card-cancel {
				margin-left: auto;
				margin-right: 16px;
			}
		}

		.modal-text {
			width: 460px;
			margin: 20px 0;
			font-size: 14px;
			line-height: 20px;
			font-style: normal;
			font-weight: normal;
			font-family: Nunito Sans;
		}
	}

	@media print {
		.action-btn-cont {
			display: none !important;
		}
	}
`;

class CardModal extends React.PureComponent {
	render() {
		const {
			isVisible,
			setIsVisible,
			title = '',
			secondaryActionIcon,
			secondaryActionTitle,
			onSecondaryActionClick,
			onSecondaryActionDisabled,
			actionTitle,
			onActionClick,
			actionBtnDisabled = false,
			children,
			className,
			style,
		} = this.props;

		if (isVisible) {
			return (
			<Dialog isOpen style={{ paddingBottom: 0 }}>
				<Container>
					<div
						role="presentation"
						onClick={() => setIsVisible(false)}
					/>

					<div className={`modal-card ${className}`} style={style}>
						{title && (
							<>
								<div className="modal-card-header">
									<h4 className="modal-card-title">
										{title}
									</h4>

									<img
										role="presentation"
										src={cancel}
										alt="cancel"
										onClick={() => setIsVisible(false)}
									/>
								</div>

								<div className="modal-card-underline" />
							</>
						)}

						{children}

						{actionTitle && onActionClick && (
							<>
								<div className="modal-card-underline mt-2 mb-2 " />
								<div className="action-btn-cont">
									{onSecondaryActionClick && (
										<>
											{secondaryActionIcon && (
												<img
													src={secondaryActionIcon}
													alt=""
												/>
											)}

											<Button
												intent="tertiary"
												text={secondaryActionTitle}
												onClick={onSecondaryActionClick}
												disabled={
													onSecondaryActionDisabled
												}
											/>
										</>
									)}

									<Button
										className="modal-card-cancel"
										intent="secondary"
										text="Cancel"
										onClick={() => setIsVisible(false)}
									/>

									<Button
										intent="primary"
										text={actionTitle}
										onClick={onActionClick}
										disabled={actionBtnDisabled}
									/>
								</div>
							</>
						)}
					</div>
				</Container>
			</Dialog>
			);
		}

		return null;
	}
}

CardModal.propTypes = {
	isVisible: PropTypes.bool,
	setIsVisible: PropTypes.func.isRequired,
	title: PropTypes.string,
	actionTitle: PropTypes.string,
	onActionClick: PropTypes.func,
	actionBtnDisabled: PropTypes.bool,
	secondaryActionIcon: PropTypes.any,
	secondaryActionTitle: PropTypes.string,
	onSecondaryActionClick: PropTypes.func,
	onSecondaryActionDisabled: PropTypes.bool,
	children: PropTypes.any.isRequired,
	className: PropTypes.string,
};

export { CardModal };
