import React, { useState } from 'react';
import { InputColor } from './InputColor';
import { DefaultColors } from './components';
import './css/InputColorPattern.scss';

export const InputColorPattern = () => {
	const allPresetColors = DefaultColors;
	const somePresetColors1 = DefaultColors.filter((defaultColor, index) => index <= 4);
	const somePresetColors2 = DefaultColors.filter((defaultColor, index) => index >= 4);

	const [color, setColor] = useState(false);

	return (
		<div className="input-color-pattern-wrapper">
			<div className="row">
				<div className="col-md-8 mt-2">
					<div className="col-md-12 mt-2">
						<InputColor
							label="all preset colors"
							name="input-color1"
							isRequired={false}
							presetColors={allPresetColors}
							setValue={setColor}
						/>
					</div>
					<div className="col-md-12 mt-5">
						<InputColor
							label="all preset colors (with selected color)"
							name="input-color1"
							isRequired={false}
							presetColors={allPresetColors}
							value="#cdb1b1"
							setValue={setColor}
						/>
					</div>
					<div className="col-md-12 mt-5">
						<InputColor
							label="some colors 1"
							name="input-color2"
							isRequired={true}
							value="#FF8310"
							setValue={setColor}
							presetColors={somePresetColors1}
						/>
					</div>
					<div className="col-md-12 mt-5">
						<InputColor
							label="some colors 2"
							name="input-color3"
							isRequired={true}
							value="#D444F1"
							setValue={setColor}
							presetColors={somePresetColors2}
						/>
					</div>
					<div className="col-md-12 mt-5">
						<InputColor
							label="Single Color"
							name="input-color4"
							isRequired={true}
							value="#D444F1"
							setValue={setColor}
						/>
					</div>
				</div>
				<div className="col-md-4 mt-2">
					<div className="card">
						<div className="card-body">
							<h4 className="mb-0">Available preset colors to use</h4>
							<span className="d-block text-secondary mb-3 font-italic">
								predefined class + value for each preset color
							</span>
							<div className="preset-colors-group">
								{DefaultColors.map((defaultColor, index) => (
									<pre className="color-info" key={index}>
										<p>
											class: {defaultColor.class} <br />
											value: {defaultColor.value}
										</p>
									</pre>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
