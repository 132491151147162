import React from 'react';
import './css/EmptyState.scss';
import PropTypes from 'prop-types';
import { BackgroundCircles } from './components';

export const EmptyState = ({
	icon,
	header,
	description,
	className = '',
	bodyClass = '',
	children,
	showCircles = true,
	...restProps
}) => {
	const WrapperComponent = showCircles ? BackgroundCircles : React.Fragment;

	return (
		<div className={`empty-state-wrapper ${className}`} {...restProps}>
			<WrapperComponent>
				<div
					className={`empty-state-body ${bodyClass} ${
						!showCircles ? 'without-circles' : ''
					}`}
				>
					<div className="header-icon">
						<img src={icon} className="icon" />
					</div>
					<div className="body-container">
						<h3 className="title">{header}</h3>
						<span className="description">{description}</span>
						<div className="footer">{children}</div>
					</div>
				</div>
			</WrapperComponent>
		</div>
	);
};

EmptyState.propTypes = {
	icon: PropTypes.string.isRequired,
	header: PropTypes.string.isRequired,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	className: PropTypes.string,
	bodyClass: PropTypes.string,
};
