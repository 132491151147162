import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Icon} from '@blueprintjs/core';

const StyledBP = styled.button`
    border-radius: 3px;
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: #9f1c3a;
    padding: 8px;

    .button-txt {
        margin-left: 8px;
    }

    &:hover {
        background: rgba(159, 28, 58, 0.1);
    }

    ${({secondary}) => {
        if (secondary === 'true') {
            return `
				color: #5c7080;
                &:hover {
                    background: rgba(92, 112, 128, 0.1);
                }
			`;
        }
        return null;
    }};
    ${({primary}) => {   //check default
        if (primary === 'true') {
            return `
				color: #0A5E92;
                &:hover {
                    background: white;
                }
			`;
        }
        return null;
    }};
`;

const LinkButton = ({
                        icon,
                        onClick,
                        intent,
                        text,
                        large,
                        minimal,
                        active,
                        disabled,
                        rightIcon,
                        className,
                        type,
                        loading,
                    }) => (
    <StyledBP
        secondary={intent === 'secondary' ? 'true' : 'false'}
        tertiary={intent === 'tertiary' ? 'true' : 'false'}
        primary={intent === 'primary' ? 'true' : 'false'}
        type={type}
        className={className}
        icon={icon}
        onClick={onClick}
        intent={intent}
        text={text}
        large={large}
        minimal={minimal}
        active={active}
        disabled={disabled}
        rightIcon={rightIcon}
        loading={loading}
    >
        <Icon icon={icon}/>
        <span className="button-txt">{text}</span>
    </StyledBP>
);

LinkButton.propTypes = {
    icon: PropTypes.string,
    rightIcon: PropTypes.string,
    intent: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
    large: PropTypes.bool,
    minimal: PropTypes.bool,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.string,
    loading: PropTypes.bool,
};

export {LinkButton};
