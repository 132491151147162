import React, { useEffect, useState, useContext } from 'react';
import { RapidAlarmService } from 'services';
import { LoadingSpinner, ToasterContext, Intent } from 'componentsV2';
import {
	Header,
	AlarmDetailsCard,
	RollCallDetailsCard,
	ReunificationDetailsCard,
	AlarmChatHistory,
	AlarmSubmittedClasses,
} from './components';
import './css/PastAlarmDetails.scss';
import { RapidAlarmsProviders } from '../Providers';

export const PastAlarmDetails = ({ match }) => {
	const pastAlarmId = match.params.id ? parseInt(match.params.id) : null;
	const [pastAlarm, setPastAlarm] = useState(null);
	const [loading, setLoading] = useState(true);
	const [pastAlarmHistory, setPastAlarmHistory] = useState(null);
	const [loadingHistory, setLoadingHistory] = useState(true);
	const toaster = useContext(ToasterContext);

	const getPastAlarm = () => {
		RapidAlarmService.getPastAlarm(pastAlarmId)
			.then(response => {
				if (response.statusCode === 200) {
					setPastAlarm(response.data);
				}
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getPastAlarmHistory = () => {
		RapidAlarmService.getPastAlarmHistory(pastAlarm.alarmId)
			.then(response => {
				if (response.statusCode === 200) {
					if (response.data.length > 0) {
						setPastAlarmHistory(response.data[0]);
					}
				}
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoadingHistory(false);
			});
	};

	useEffect(() => {
		getPastAlarm();
	}, []);

	useEffect(() => {
		if (pastAlarm) {
			getPastAlarmHistory();
		}
	}, [pastAlarm]);

	return (
		<div className="rapid-alarm-details-container">
			{loading && loadingHistory && <LoadingSpinner />}
			{!loading && !loadingHistory && (
				<>
					<RapidAlarmsProviders>
						<Header pastAlarm={pastAlarm} pastAlarmHistory={pastAlarmHistory} />
						<AlarmDetailsCard pastAlarm={pastAlarm} />
						<RollCallDetailsCard rollCall={pastAlarm.rollCall} />
						<ReunificationDetailsCard reunification={pastAlarm.reunification} />

						<div className="chat-and-submitted-classes-container">
							<AlarmChatHistory
								loading={loadingHistory}
								pastAlarmHistory={pastAlarmHistory}
							/>
							<AlarmSubmittedClasses
								loading={loadingHistory}
								pastAlarmHistory={pastAlarmHistory}
							/>
						</div>
					</RapidAlarmsProviders>
				</>
			)}
		</div>
	);
};
