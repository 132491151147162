import React from "react";
import { useSelector } from "react-redux";
import { decodeToken } from 'utilities';
import { checkPermission } from 'utilities/permissions';
import { Modal, CardContainer, LinkArrow, Tooltip } from "componentsV2";
import { BINDER_EDIT_PATH, BINDER_VIEW_PATH } from "pages/Binders";
import { TYPE_360IMAGE, TYPE_DOCUMENT } from "pages/Documents";
import { MAPS_VIEW_PATH } from "pages/Maps/components";
import './css/WhereItsUsedModal.scss';

export const WhereItsUsedModal = ({
    modalName,
    modalToShow,
    loading,
    documentData,
    closeModal,
    history,
}) => {
    const permissions = useSelector(state => state.route.permissions);
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    const dist = token ? decodeToken(token) : '';
    const districtId = dist ? dist.dist : null;

    const editPermission = checkPermission('vb_edit', permissions, districtId);
    const bindersPath = editPermission ? BINDER_EDIT_PATH : BINDER_VIEW_PATH;

    const MapLink = ({floorplan}) => {
        const linkEnable = checkPermission('maps_view', permissions, floorplan.buildingId);
        const buildingId = floorplan.buildingId ? floorplan.buildingId : floorplan.building ? floorplan.building.id : null;
        return linkEnable ? (
            <LinkArrow 
                key={floorplan.id}
                onClick={() => window.open(`/${MAPS_VIEW_PATH}/${buildingId}/${floorplan.id}`)}
            >
                {floorplan.title}
            </LinkArrow>
        ) : (
            <Tooltip 
                tooltipTitle="This map is linked to a site you don't have permission on" 
                theme="light"
            >
                <LinkArrow 
                    key={floorplan.id}
                    disableLink={true}
                    onClick={(e) => {e.target.click()}}
                >
                    {floorplan.title}
                </LinkArrow>
            </Tooltip>
        )
    }

    const Locations = ({ type, locations }) => {
        return (
            <div className="where-is-used-wrapper">
                {type == TYPE_DOCUMENT && locations.floorPlans.length > 0 && (
                    <CardContainer title="Maps">
                        <div className="locations-links">
                            {locations.floorPlans.map(({floorplan}) => (
                                <MapLink floorplan={floorplan} />
                            ))}
                        </div>
                    </CardContainer>
                )}

                {type == TYPE_DOCUMENT && locations.virtualBinders.length > 0 && (
                    <CardContainer title="Binders">
                        <div className="locations-links">
                            {locations.virtualBinders.map(binder => (
                                <LinkArrow
                                    key={binder.id}
                                    onClick={() => history.push(`${bindersPath}/${binder.id}`)}
                                >
                                    {binder.name}
                                </LinkArrow>
                            ))}
                        </div>
                    </CardContainer>
                )}

                {type == TYPE_360IMAGE && (
                    <CardContainer title="Maps">
                        <div className="locations-links">
                            {locations.map(floorplan => (
                                <MapLink floorplan={floorplan} />
                            ))}
                        </div>
                    </CardContainer>
                )}
            </div>
        )
    }

    const hasLocations = (
        documentData.type == TYPE_DOCUMENT &&
        (documentData.locations?.floorPlans?.length > 0 ||
            documentData.locations?.virtualBinders?.length > 0)) ||
        (documentData.type == TYPE_360IMAGE &&
            documentData.locations?.length > 0);

    return (
        hasLocations ?
            <Modal
                isOpen={modalToShow === modalName}
                onClose={closeModal}
                title={`Where “${documentData.title}” it's used`}
                showCloseButton={true}
                loading={loading}
                scrollContent={true}
            >
                <div className="w-100">
                    <Locations
                        type={documentData.type}
                        locations={documentData.locations}
                    />
                </div>
            </Modal>
            :
            <Modal
                isOpen={modalToShow === modalName}
                onClose={closeModal}
                title={`Where “${documentData.title}” it's used`}
                subtitle="Currently this document is not attached to any maps or binders."
                showCloseButton={true}
                loading={loading}
                scrollContent={true}
                customHeaderClass="border-bottom-none"
            />
    );
}