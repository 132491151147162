import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'componentsV2';
import { MapElementCard, TabPanel } from '../SidebarComponents';
import { TextListActionsList } from './TextListActionsList';
import { removeDuplicatesWithCount } from 'utilities/removeDuplicatesWithCount';
import { NoTextAddedState, NoTextFoundState } from './';
import { onDragShapeHandler } from '../Functions';
import { floorplanDetails } from '../../CanvasDrawFn';

export const AvailableToUsePanel = ({
	floorplanId,
	editPermission,
	list,
	addNewTextHandler,
	addBtnDisabled,
	siteId,
	layer,
	isEditMode,
}) => {
	const [textList, setTextList] = useState([]);
	const [noDataAdded, setNoDataAdded] = useState(list.length === 0);
	const [loading, setLoading] = useState(true);
	const floorplanArchived = !!floorplanDetails?.archivedBy;

	useEffect(() => {
		const removeDuplicated = removeDuplicatesWithCount(list, 'title');
		setTextList(removeDuplicated);
		setNoDataAdded(removeDuplicated.length === 0);
		setLoading(false);
	}, [list]);

	const onSearchHandler = search => {
		setTextList(
			textList.filter(textItem =>
				textItem.title?.toLowerCase().includes(search.toLowerCase()),
			),
		);
	};

	const getSubTitle = textShape => {
		const usedInThisMap = list.find(
			shapeListItem =>
				shapeListItem.floorPlanId === floorplanId &&
				shapeListItem.title === textShape.title,
		);
		if (usedInThisMap) {
			return 'In use in this map layer';
		}
		return '';
	};

	if (loading) return <LoadingSpinner />;
	return (
		<>
			{noDataAdded && (
				<NoTextAddedState
					editPermission={editPermission}
					floorplanArchived={floorplanArchived}
					addNewTextHandler={addNewTextHandler}
					addBtnDisabled={addBtnDisabled}
					isEditMode={isEditMode}
				/>
			)}
			{!noDataAdded && (
				<TabPanel
					description="The texts listed below are texts used in other map layers.
                            If you don’t see a text you’d like to use, select the + button to add a new text.
                            Drag a text from the list to add to this map layer.
                            Once added to this map layer, the text will appear under the “In use” tab."
					onSearchHandler={onSearchHandler}
					onAddClickHandler={addNewTextHandler}
					addBtnTitle="Add Text"
					hasSearch={true}
					showAddBtn={editPermission && !floorplanArchived && isEditMode}
					addBtnDisabled={addBtnDisabled}
				>
					{textList.length === 0 && <NoTextFoundState />}
					{!!textList.length && (
						<>
							{textList.map(textShape => (
								<MapElementCard
									key={textShape.id}
									title={textShape.title}
									subTitle={getSubTitle(textShape)}
									isSelected={false}
									isHidden={true}
									showEyeIcon={false}
									onDrag={e => onDragShapeHandler(e, textShape)}
									rightElement={
										editPermission && (
											<TextListActionsList
												textShape={textShape}
												editPermission={editPermission}
												floorplanArchived={floorplanArchived}
												tabType="AvailableToUse"
												siteId={siteId}
												layer={layer}
												isEditMode={isEditMode}
											/>
										)
									}
									editPermission={editPermission}
									floorplanArchived={floorplanArchived}
								/>
							))}
						</>
					)}
				</TabPanel>
			)}
		</>
	);
};
