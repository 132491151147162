import React from 'react';
import { SearchBar } from 'components/SearchBar';
import './css/StudentsFilters.scss';

export const StudentsFilters = ({ setFilterationData, filterationData }) => {
	const onSearchHandler = name => {
		setFilterationData(oldData => ({
			...oldData,
			studentName: name,
			page: 1,
		}));
	};

	return (
		<div className="Filteration_Wrapper">
			<div>
				<SearchBar
					placeholder="Search by name..."
					icon="search"
					className="search-bar-text"
					value={filterationData.studentName}
					onSearch={onSearchHandler}
				/>
			</div>
		</div>
	);
};

StudentsFilters.propTypes = {};
