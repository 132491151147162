export const ACTIVE_ALARMS_TYPE = 'activeAlarmsType';
export const PAST_ALARMS_TYPE = 'pastAlarmsType';
export const ADVANCED_FILTER_MODAL = 'advancedFilterModal';
export const ALARM_MODE = 'alarm';
export const ACTIVATE_ROLL_CALL = 'Active';
export const END_ROLL_CALL = 'End';
export const DRILL_MODE = 'drill';
export const TRAINING_MODE = 'training';
export const TRIGGER_ALARM_TYPES = {
	NineOneOneCellular: '911 Cellular',
	EMS: 'EMS',
	VMS: 'VMS',
	UNKNOWN: 'Unknown',
};
export const PAST_ALARMS_PER_PAGE = 10;
export const PAST_ALARMS_TO_SHOW_IN_HOME = 3;
export const ALARM_STATUS = {
	okay: 'Safe',
	not_okay: 'Needs assistance',
	not_here: 'Not on campus',
};
export const ALARM_MODES_LABEL = {
	alarm: 'Emergency mode',
	drill: 'Drill mode',
	training: 'Training mode',
};

export const ROLL_CALL_TAB_MY_CLASSES = 'myClasses';
export const ROLL_CALL_TAB_STUDENTS = 'students';
export const ROLL_CALL_TAB_STAFF = 'staff';
export const ROLL_CALL_TAB_VISITORS = 'visitors';

export const ALARM_STUDENT_STATUS_ACCOUNTED = 'accounted';
export const ALARM_STUDENT_STATUS_NEED_ASSISTANCE = 'needs assistance';
export const ALARM_STUDENT_STATUS_ABSENT = 'absent';
export const ALARM_STUDENT_STATUS_MISSING = 'missing';
export const ALARM_STUDENT_STATUS_EXCEPTION = 'exception';
export const ALARM_STUDENT_STATUS_UNACCOUNTED = 'unaccounted';
export const ALARM_STUDENT_STATUS_TRANSFERED = 'transferred';
export const ALARM_STUDENT_STATUS_TRANSFER_TO_REUNIFY = 'transfer to reunify';
export const ALARM_STUDENT_CUSTOM_ROASTER_CLASS = 'Custom Roster';

export const ALARM_STUDENT_STATUS_OPTIONS = {
	[ALARM_STUDENT_STATUS_ACCOUNTED]: 'Accounted for',
	[ALARM_STUDENT_STATUS_NEED_ASSISTANCE]: ALARM_STATUS.not_okay,
	[ALARM_STUDENT_STATUS_ABSENT]: ALARM_STATUS.not_here,
	[ALARM_STUDENT_STATUS_EXCEPTION]: 'Exception',
	[ALARM_STUDENT_STATUS_UNACCOUNTED]: 'No status',
	[ALARM_STUDENT_STATUS_TRANSFER_TO_REUNIFY]: 'Transfer to reunify',
};
export const ACTIVATE_REUNIFICATION_MODAL = 'ActivateReunificationModal';
export const END_AlARM_MODAL = 'EndAlarm';
export const ACTIVATE_END_ROLLCALL_MODAL = 'ActiveEndRollCall';
export const DOWNLOAD_PAST_AlARM_MODAL = 'DownloadPastAlarm';
export const ADD_STUDENT_TO_REUNIFICATION_MODAL = 'AddStudentToReunification';
export const EDIT_GUARDIAN_DETAILS_MODAL = 'EditGuardianDetails';
export const ADD_ANOTHER_STUDENT = 'AddAnotherStudent';
export const DEFULT_ADD_ANOTHER_STUDENT_MODAL = 'DefultAddAnotherStudentModal';
export const DELETE_GUARDIAN_ASSOCIATION = 'DeleteGardianAssociation';

export const ALARM_STAFF_STATUS_ACCOUNTED = 'okay';
export const ALARM_STAFF_STATUS_NEED_ASSISTANCE = 'not_okay';
export const ALARM_STAFF_STATUS_ABSENT = 'not_here';
export const ALARM_STAFF_STATUS_EXCEPTION = 'exception';
export const ALARM_STAFF_STATUS_UNACCOUNTED = 'unaccounted';

export const STATUS_REUNIFICATION_CANCELED = 'transferred canceled';
export const STATUS_REUNIFICATION_CHECKIN = 'reunification check-in';
export const STATUS_REUNIFICATION_CHECKIN_CANCELED = 'reunification check-in canceled';
export const STATUS_REUNIFICATION_REUNIFIED = 'reunified';
export const STATUS_REUNIFICATION_REUNIFIED_CANCELED = 'undo reunify';

export const ALARM_STAFF_STATUS_OPTIONS = {
	[ALARM_STAFF_STATUS_ACCOUNTED]: 'Accounted for',
	[ALARM_STAFF_STATUS_NEED_ASSISTANCE]: ALARM_STATUS.not_okay,
	[ALARM_STAFF_STATUS_ABSENT]: ALARM_STATUS.not_here,
	[ALARM_STAFF_STATUS_EXCEPTION]: 'Exception',
	[ALARM_STAFF_STATUS_UNACCOUNTED]: 'No status',
};

export const ALARM_VISITORS_STATUS_OPTIONS = {
	[ALARM_STUDENT_STATUS_ACCOUNTED]: 'Accounted for',
	[ALARM_STUDENT_STATUS_NEED_ASSISTANCE]: ALARM_STATUS.not_okay,
	[ALARM_STUDENT_STATUS_ABSENT]: ALARM_STATUS.not_here,
	[ALARM_STUDENT_STATUS_EXCEPTION]: 'Exception',
	[ALARM_STUDENT_STATUS_UNACCOUNTED]: 'No status',
};
