import React from 'react';
import { PageHeader } from 'componentsV2';

export const CreateAlarmTypeHeader = () => {
	const breadcrumbItems = [
		{
			label: 'Rapid alarm',
			url: '/rapid-alarms',
		},
		{
			label: 'Settings',
			url: '/rapid-alarms/settings',
		},
		{
			label: 'Alarm types',
			url: '/rapid-alarms/settings',
		},
		{
			label: 'Create new alarm type',
		},
	];

	return (
		<PageHeader
			title="Create new alarm type"
			subtitle="Use the wizard to create a new alarm type for your district"
			breadcrumbItems={breadcrumbItems}
		/>
	);
};
