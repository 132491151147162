import React from "react";
import parse from 'html-react-parser';

export const SafetyPlanTab = ({
    row,
    index
}) => {

    return (
        <div key={index} className="card-body">{parse(row.content || '')}</div>
    )
}