import React, { useState, useEffect, useContext } from "react";
import {FileUpload, FormModal, Intent, Dropdown, Checkbox } from "componentsV2";
import { DocumentService } from "services/DocumentService";
import {ToasterContext} from 'pages/App';
import { useSelector } from "react-redux";
import { filterSitesPerPermissionCode, checkPermissionOnDistrict } from "utilities/permissions";
import { BuildingsService } from 'services/BuildingsService';    
import { isEmpty } from "lodash";
import { PanoramaService } from "services/PanoramaService";
import '../css/uploadDocument.scss';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';

export const UploadDocumentModal = ({
    showUploadModal,
    closeModal,
    selectedSites,
    documentDetails = null,
    reloadList
}) => {
    const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const toaster = useContext(ToasterContext);
    const [selectedSite, setSelectedSite] = useState({});
    const [sites, setSites] = useState([]);
    const permissions = useSelector(state => state.route.permissions);
    const hasPermissionOnDistrict = checkPermissionOnDistrict('document_edit');
    const [fileUploadCompeleted, setFileUploadCompleted] = useState(false);
    const isPanorama = documentDetails && documentDetails.type == '360image' ? true : false;
    const [panoramChecked, setPanoramChecked] = useState(isPanorama ? true : false);
    const [modalTitle, setModalTitle] = useState('Upload new file');
    const [fileError, setFileError] = useState(null);

    useEffect(() => {
        if (documentDetails) {
            setModalTitle('Replace file');
            const site = {
                label: documentDetails.buildingId ? documentDetails.buildingName : 'Visible to all',
                value: documentDetails.buildingId ? documentDetails.buildingId : null
            }

            setSites([site]);
            setSelectedSite(site);
        } else {
            getSitesData(selectedSites);
        }
    }, []);

    useEffect(() => {
        validateInputs();
    }, [selectedSite, selectedFile, fileUploadCompeleted]);

    const getSitesData = () => {
        setLoading(true);

        BuildingsService.getAll(false, false).then(serviceBuildings => {
            let sites = filterSitesPerPermissionCode('document_edit', permissions, serviceBuildings);
            
            if (hasPermissionOnDistrict) {
                sites.unshift({label: 'Visible to all', value: null});
            }

            setSites(sites);
            setDefaultSelectedSite(sites);
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const setDefaultSelectedSite = (sites) => {
        let selectedSite = null;

        if (selectedSites.length == 1) {
            selectedSite = sites.find(site => site.id === selectedSites[0]);
        }

        if (selectedSite) {
            setSelectedSite(selectedSite);
            return;
        }
        
        let loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
        let primarySite = sites.find(site => site.id === loggedUser.primaryBuilding.id);
        
        if (primarySite) {
            setSelectedSite(primarySite);
            return;
        }
    }

    const onSiteChange = (selected) => {
		setSelectedSite(selected);
	};

    const handleProgressCompleted = (isProgressCompleted) => {
        if (isProgressCompleted) {
            setFileUploadCompleted(true)
        } else {
            setFileUploadCompleted(false)
        }
    }

    const onFileInputChange = (e) => {
        setSelectedFile(e);
        setFileError(null);
    }

    const onCloseModal = () => {
        closeModal(false);
        setConfirmBtnDisabled(true);
    }

    const validateInputs = () => {
        if (
            Object.keys(selectedSite).length < 1 ||
            !fileUploadCompeleted ||
            ! selectedFile || isEmpty(selectedFile.name)
        ) {
            setConfirmBtnDisabled(true);
        } else {
            setConfirmBtnDisabled(false);
        }
    }
    
    const formSubmitHandler = () => {    
        setLoading(true);
        const params = {buildingId: selectedSite.value}
        var successMsg = 'You have successfully uploaded a new  file!';
        
        if (documentDetails) {
            successMsg = 'You have successfully replaced '+ documentDetails.title + ' with a new file!';
        }

        if (panoramChecked) {
            if (documentDetails) {
                params.panoramaId = documentDetails.id;
            }

            PanoramaService.upload(params, selectedFile)
            .then(response => {
                if (response.statusCode == 201) {
                    toaster(successMsg, Intent.SUCCESS)
                    onCloseModal();
                    reloadList();
                } else if (response.statusCode == 400) {
                    if (response.error.fields.file) {
                        setFileError(response.error.fields.file);
                    }
                }
            })
            .catch(error => {
                toaster('Something went wrong pls try again', Intent.DANGER);
            })
            .finally(() => {
                setLoading(false);
            });
        } else {
            if (documentDetails) {
                params.documentId = documentDetails.id;    
            }

            DocumentService.uploadDocument(params, selectedFile)
            .then(response => {
                if (response.statusCode == 200) {
                    toaster(successMsg, Intent.SUCCESS)
                    onCloseModal();
                    reloadList();
                } else if (response.statusCode == 400) {
                    if (response.error.fields.file) {
                        setFileError(response.error.fields.file);
                    }
                }
            })
            .catch(error => {
                toaster('Something went wrong pls try again', Intent.DANGER);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }

    return (
        <>
        {showUploadModal && (
            <FormModal
                isOpen={showUploadModal}
                onClose={onCloseModal}
                showCloseButton={true}
                title={modalTitle}
                loading={loading}
                scrollContent={false}
                confirmBtn={{
                    title: "Save",
                    onClick: formSubmitHandler,
                    disabled: confirmBtnDisabled
                }}
                cancelBtn={{
                    title: "Cancel",
                    onClick: onCloseModal
                }}
                customBodyClass='document-upload-modal'
            >
                <Dropdown
                    label="Site visibility"
                    value={selectedSite}
                    options={sites}
                    onChange={onSiteChange}
                    isRequired={true}
                    disabled={documentDetails ? true : false}
                    placeholder='Select a site'
                />
                
                <FileUpload
                    required = {true}
                    allowAllTypes = {true}
                    allowedFilesLegend = ''
                    label = 'File'
                    labelState = {false}
                    onFileInputChange = {onFileInputChange}
                    setProgressCompleted = {handleProgressCompleted}
                    allowedFiles = { panoramChecked ? ['jpeg', 'jpg', 'png', 'gif'] : []}
                    error={fileError ? true : false}
                    defaultErrorMessage = {fileError}
                    file={fileError ? selectedFile : null}
                    maxFileSize={MAX_FILE_UPLOADER_SIZE}
                />
                
                <Checkbox
                    label={"This is a 360 image"}
                    onChange={() => setPanoramChecked(oldValue => !oldValue)}
                    checked={panoramChecked}
                    disabled={documentDetails ? true : false}
                />
            </FormModal>
        )}
        </>
    )
}