import React, { useState, useEffect, useContext } from 'react';
import {
	CardContainer,
	Input,
	Tooltip,
	TooltipPosition,
	Badge,
	LoadingSpinner,
	ToasterContext,
	Intent,
	FeaturedIcon,
} from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import './Credentials.scss';
import { RapidAlarmIntegrationsService } from 'services';
import { isNotEmpty } from 'utilities/validations';

export const InputLabel = ({ text, isRequired, tooltipText }) => {
	return (
		<div className="input-label-container">
			<span className="authentication-label">{text}</span>
			{isRequired && <span className="required-label">*</span>}
			{tooltipText && (
				<>
					<Tooltip
						tooltipTitle={tooltipText}
						theme="dark"
						position={TooltipPosition.RIGHT}
					>
						<Icon className="icon-contianer" icon="help" size="12" />
					</Tooltip>
				</>
			)}
		</div>
	);
};

export const Credentials = ({ partnerId }) => {
	const [credentials, setCredentials] = useState({
		incomingPartnerId: '',
		incomingSecret: '',
		outgoingBaseUrl: '',
		outgoingSecret: '',
	});
	const [loading, setLoading] = useState(false);
	const toaster = useContext(ToasterContext);
	const [validationErrors, setValidationErrors] = useState({
		incomingPartnerId: { hasError: false, errorMsg: '' },
		incomingSecret: { hasError: false, errorMsg: '' },
		outgoingBaseUrl: { hasError: false, errorMsg: '' },
		outgoingSecret: { hasError: false, errorMsg: '' },
	});

	useEffect(() => {
		setLoading(true);
		getCredentialsData();
	}, []);

	const checkUrlPattern = string => {
		const urlChecker = /^https?:\/\/(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)?(?:\.[a-zA-Z]{2,})?)(?:\/\S*)?$/;
		return urlChecker.test(string);
	};

	const handleValidationErrors = () => {
		const errors = {};

		if (!isNotEmpty(credentials.incomingPartnerId)) {
			errors.incomingPartnerId = {
				hasError: true,
				errorMsg: 'Partner Id is required',
			};
		}

		if (!isNotEmpty(credentials.incomingSecret)) {
			errors.incomingSecret = {
				hasError: true,
				errorMsg: 'Incoming Secret Id is required',
			};
		}
		if (!checkUrlPattern(credentials.outgoingBaseUrl)) {
			errors.outgoingBaseUrl = { hasError: true, errorMsg: 'Enter Valid URL.' };
		}
		if (!isNotEmpty(credentials.outgoingSecret)) {
			errors.outgoingSecret = {
				hasError: true,
				errorMsg: ' Outgoing Secret Id is required',
			};
		}
		setValidationErrors({ ...validationErrors, ...errors });
		return errors;
	};

	const handleChangeCredentialsValue = e => {
		const { name } = e.target;
		const { value } = e.target;
		setCredentials({
			...credentials,
			[name]: value,
		});
		let validations = {};

		const inputValidation = {
			[e.target.name]: { hasError: false, errorMsg: '' },
		};

		validations = { ...inputValidation };

		setValidationErrors({ ...validationErrors, ...validations });
	};

	const handleSubmitCredentials = e => {
		e.preventDefault();
		const errors = handleValidationErrors();
		if (Object.keys(errors).length === 0) {
			setLoading(true);
			RapidAlarmIntegrationsService.submitCredentials(partnerId, credentials)
				.then(() => {
					setLoading(false);
					toaster(
						`You have successfully updated the credentials`,
						Intent.SUCCESS,
						<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
					);
				})
				.catch(err => {
					toaster(
						`There was an error saving the credentials. Please try again.`,
						Intent.DANGER,
					);
					console.log(err);
				});
		} else {
			console.log('Validation Errors:', validationErrors);
		}
	};

	const getCredentialsData = async () => {
		await RapidAlarmIntegrationsService.getCredentials(partnerId)
			.then(res => {
				if (res?.data) {
					setLoading(false);
					setCredentials({
						incomingPartnerId: res?.data?.incomingPartnerId,
						incomingSecret: res?.data?.incomingSecret,
						outgoingBaseUrl: res?.data?.outgoingBaseUrl,
						outgoingSecret: res?.data?.outgoingSecret,
					});
				}
			})
			.catch(err => {
				console.log(err);
			});
	};
	return (
		<div className="credentials-main-container">
			{loading && <LoadingSpinner />}
			{!loading && (
				<CardContainer>
					<span className="form-title-container">
						Manage 911Cellular Partner Credentials
					</span>

					<form className="form-container" onSubmit={handleSubmitCredentials}>
						<span className="settings-label-container">Incoming Settings</span>
						<div className="form-input-container">
							<InputLabel
								text="Partner ID"
								isRequired
								tooltipText="The Partner ID is provided by Navigate to identify the partner."
							/>
							<Input
								placeholder="Enter Partner ID"
								isRequired
								value={credentials.incomingPartnerId}
								name="incomingPartnerId"
								error={validationErrors.incomingPartnerId.hasError}
								errorMessage={validationErrors.incomingPartnerId.errorMsg}
								onChange={handleChangeCredentialsValue}
							/>
						</div>
						<div className="form-input-container">
							<InputLabel
								text="Secret"
								isRequired
								tooltipText="Copy and paste the Secret created in the 911Cellular portal under the Navigate360 integration."
							/>
							<Input
								placeholder="Enter Secret ID"
								isRequired
								value={credentials.incomingSecret}
								name="incomingSecret"
								error={validationErrors.incomingSecret.hasError}
								errorMessage={validationErrors.incomingSecret.errorMsg}
								onChange={handleChangeCredentialsValue}
							/>
						</div>
						<span className="settings-label-container">Outgoing Settings</span>
						<div className="form-input-container">
							<InputLabel
								text="Base URL"
								isRequired
								tooltipText="The Base URL is used by the partner for all outgoing APIs."
							/>
							<Input
								placeholder="Enter Base URL"
								isRequired
								value={credentials.outgoingBaseUrl}
								name="outgoingBaseUrl"
								error={validationErrors.outgoingBaseUrl.hasError}
								errorMessage={validationErrors.outgoingBaseUrl.errorMsg}
								onChange={handleChangeCredentialsValue}
							/>
						</div>
						<div className="form-input-container">
							<InputLabel
								text="Secret"
								isRequired
								tooltipText="The Token is provided to the partner to authenticate a request."
							/>
							<Input
								placeholder="Enter Secret token"
								isRequired
								value={credentials.outgoingSecret}
								name="outgoingSecret"
								error={validationErrors.outgoingSecret.hasError}
								errorMessage={validationErrors.outgoingSecret.errorMsg}
								onChange={handleChangeCredentialsValue}
							/>
						</div>
						<div>
							<button className="save-button-container" type="submit">
								Save
							</button>
						</div>
					</form>
				</CardContainer>
			)}
		</div>
	);
};
