import React, { useContext, useState } from 'react';
import { ConfirmationModal } from 'componentsV2';
import { CreateAlarmTypeContext } from '../provider';
import { ToasterContext } from 'pages/App';
import { RapidAlarmService } from '../../../../../services/RapidAlarmService';
import { Intent } from '@blueprintjs/core';

export const EnableDisableAlarmModal = () => {
	const { formData, closeModal, onChangeFormData } = useContext(CreateAlarmTypeContext);
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);
	const isDisabled = formData.disabled;

	const onConfirmAction = () => {
		setLoading(true);
		RapidAlarmService.toggleEnableDisableAlarmType(formData.id, !isDisabled)
			.then(response => {
				if (response.statusCode === 200) {
					toaster(
						`You have successfully ${isDisabled ? 'enabled' : 'disabled'} "${
							formData.name
						}".`,
						Intent.SUCCESS,
					);
					onChangeFormData({ type: 'disabled', payload: !isDisabled });
				} else {
					toaster(
						`Error while ${isDisabled ? 'enabling' : 'disabling'} alarm type`,
						Intent.DANGER,
					);
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	const title = `Are you sure you want to ${isDisabled ? 'enable' : 'disable'} "${
		formData.name
	}"?`;

	const subTitle = `${
		isDisabled
			? 'Once enabled, this alarm will become operational and can be activated.'
			: 'You can enable it at a later time'
	}`;

	const confirmBtnTitle = `${isDisabled ? 'Enable' : 'Disable'} it`;

	return (
		<ConfirmationModal
			isOpen={true}
			loading={loading}
			title={title}
			subtitle={subTitle}
			confirmBtn={{
				title: confirmBtnTitle,
				onClick: onConfirmAction,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: closeModal,
			}}
		/>
	);
};
