import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import TasksRow from './tasksrow';

const TableWrapper = styled.div`
	display: flex;
`;

const TableTitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	margin-bottom: 0;
`;

const TableContent = styled.table`
	thead {
		tr {
			border-top: 0 !important;
			th {
				border: 0 !important;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				color: #738694;
			}
		}
	}
	tbody {
		tr {
			th {
				font-weight: normal;
				vertical-align: middle;
			}
			td {
				&:nth-child(2),
				&:nth-child(3) {
					text-transform: capitalize;
				}
				vertical-align: middle;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				color: #10161a;
			}
			&:last-child {
				border-bottom: 1px solid #dee2e6;
			}
		}
	}
`;

const Wrapper = styled.div`
	margin-left: -15px;
	margin-right: -15px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
	background: #fff;
`;

class Tasks extends React.Component {
	state = {};

	render() {
		const { tasks, assessmentId, getData, history } = this.props;
		return (
			<Wrapper>
				<TableWrapper>
					<TableContent
						className="table"
						style={{ backgroundColor: '#fff' }}
					>
						<thead>
							<tr>
								<td>
									<TableTitle
										style={{
											color: '#738694',
										}}
									>
										Task Title
									</TableTitle>
								</td>
								<td>
									<div className="d-flex">
										<TableTitle
											style={{
												color: '#738694',
											}}
										>
											Due date
										</TableTitle>
										<Icon
											style={{
												color: '#738694',
											}}
											icon="caret-down"
										/>
									</div>
								</td>
								<td>
									<div className="d-flex">
										<TableTitle
											style={{
												color: '#738694',
											}}
										>
											Assigned to
										</TableTitle>
										<Icon
											style={{
												color: '#738694',
											}}
											icon="caret-down"
										/>
									</div>
								</td>
								<td>
									<div className="d-flex">
										<TableTitle
											style={{
												color: '#738694',
											}}
										>
											Actions
										</TableTitle>
										<Icon
											style={{
												color: '#738694',
											}}
											icon="caret-down"
										/>
									</div>
								</td>
							</tr>
						</thead>
						<tbody>
							{tasks.map(item => (
								<TasksRow
									item={item}
									key={item.id}
									assessmentId={assessmentId}
									history={history}
									getData={getData}
								/>
							))}
						</tbody>
					</TableContent>
				</TableWrapper>
			</Wrapper>
		);
	}
}

Tasks.propTypes = {
	tasks: PropTypes.any,
	assessmentId: PropTypes.string,
	history: PropTypes.object,
	getData: PropTypes.func,
};

export default Tasks;
