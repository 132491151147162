import React, { useState, createContext, useReducer, useContext } from 'react';
import {
	ARCHIVE_MAP_MODAL,
	MAP_LIST_PAGE,
	DELETE_MAP_MODAL,
	RENAME_MAP_MODAL,
	CREATE_MAP_MODAL,
	MAP_CONTENTS_MODAL,
	VIEW_UPDATE_NOTE_MODAL,
	DELETE_NOTE_MODAL,
	VIEW_UPDATE_CAMERA_LINK_MODAL,
	DELETE_CAMERA_LINK_MODAL,
	UPLOAD_CONTENT,
	DOWNLOAD_MAP,
} from '../consts';
import { ArchiveMapModal } from './';
import { FloorplanService } from 'services/FloorplanService';
import { ToasterContext } from 'componentsV2';
import { Intent } from '@blueprintjs/core';
import {
	MapInfo,
	MapInfoFn,
	CreateUpdateMap,
	CreateUpdateMapFn,
	MapContentsList,
	MapContentsListFn,
	ViewUpdateNoteModal,
	ViewUpdateNoteModalFn,
	DeleteNoteModal,
	DeleteNoteModalFn,
	ViewUpdateCameraLinkModal,
	ViewUpdateCameraLinkModalFn,
	DeleteCameraLinkModalFn,
	DeleteCameraLinkModal,
	UploadContent,
	UploadContentFn,
	DownloadMap,
	DownloadMapFn,
} from './ProviderComponents';
import { DeleteMapModal, DeleteMapModalFn, RenameMapModal, RenameMapModalFn } from '../List/modals';
import { UNSAVED_CHANGES_MODAL } from '../Details';

export const MapsContext = createContext();

const modalDataReducer = (modalData, action) => ({
	modalTitle: action.modalTitle,
	modalSubTitle: action.modalSubTitle,
	confirmBtnTitle: action.confirmBtnTitle,
	onConfirmAction: action.onConfirmAction,
	isEdit: action.isEdit,
	shape: action.shape,
	siteId: action.siteId,
	floorplanId: action.floorplanId,
	iconLinks: action.iconLinks,
	loadContentData: action.loadContentData,
	uploader: action.uploader,
	contentType: action.contentType,
	onCancelAction: action.onCancelAction,
});

export const MapsProvider = ({
	reloadList,
	reloadDetails,
	children,
	history,
	defaultSelectedSites,
	selectedSites,
	setSelectedSites,
	toggleMapOption,
	loadMapDetails,
	reloadContent,
	transformer,
	setRefreshTextList,
	currentFloorplan,
	setCurrentFloorplan,
	setIsEditMode,
	mapHasChanges,
	setRefreshAreaList,
	setMapViewModalToShow,
	setNextModalToShow,
	layer,
	editPermissionOnSiteId,
}) => {
	const [modalToShow, setModalToShow] = useState(null);
	const [loading, setLoading] = useState(false);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [floorpanDetails, setFloorPlanDetails] = useState(null);
	const [AddedFromView, setAddedFromView] = useState(false);
	const [note, setNote] = useState(null);
	const [oldNote, setOldNote] = useState({});
	const [selectedShape, setSelectedShape] = useState(null);
	const [mode, setMode] = useState('view');
	const [cameraLink, setCameraLink] = useState(null);
	const [oldCameraLink, setOldCameraLink] = useState({});
	const AVAILABLE_TO_USE = 'AvailableToUse';

	const [modalData, dispatchModalData] = useReducer(modalDataReducer, {
		modalTitle: '',
		modalSubTitle: '',
		confirmBtnTitle: '',
		onConfirmAction: () => {},
		isEdit: false,
		shape: null,
		siteId: null,
		floorplanId: null,
		iconLinks: [],
		loadContentData: () => {},
		uploader: {},
		contentType: '',
		onCancelAction: () => {},
	});
	const toaster = useContext(ToasterContext);

	const closeModal = () => {
		setModalToShow(null);
	};

	const showCreateMapModal = (isEdit, details, addedFromView) => {
		setFloorPlanDetails(details);
		setAddedFromView(addedFromView);
		dispatchModalData({
			isEdit: isEdit,
		});
		if (mapHasChanges) {
			setNextModalToShow(CREATE_MAP_MODAL);
			setMapViewModalToShow(UNSAVED_CHANGES_MODAL);
		} else {
			setModalToShow(CREATE_MAP_MODAL);
		}
	};

	const showAddContentsModal = (
		selectedShape,
		siteId,
		floorplanId,
		filteredLinks,
		loadContentData,
	) => {
		let IconTitle = selectedShape?.geometryType == 'icon' ? 'icon' : 'area';
		dispatchModalData({
			modalTitle: `Add content to "` + selectedShape?.title + `" ` + IconTitle,
			shape: selectedShape,
			siteId: siteId,
			floorplanId: floorplanId,
			iconLinks: filteredLinks,
			loadContentData: loadContentData,
		});

		setModalToShow(MAP_CONTENTS_MODAL);
	};

	const showTextDeleteModal = (textShape, tabType, siteId, layer) => {
		let subtitle =
			tabType == AVAILABLE_TO_USE ? (
				<span>
					Deleting these texts will delete all of them from this map layer. Once deleted,
					this cannot be recovered.
					<br />
					To delete a single text, right click on the text in this map layer.
				</span>
			) : (
				'Deleting this text will delete it from this map layer. Once deleted, this cannot be recovered.'
			);

		let confirmTitle = tabType == AVAILABLE_TO_USE ? 'Yes, delete them' : 'Yes, delete it';
		setModalToShow(DELETE_MAP_MODAL);
		dispatchModalData({
			modalTitle: `Are you sure you want to delete “${textShape.title}”?`,
			modalSubTitle: subtitle,
			confirmBtnTitle: confirmTitle,
			onConfirmAction: () => deleteTexts(textShape, siteId, tabType, layer),
			layer: layer,
		});
	};

	const showAreaDeleteModal = (shape, layer) => {
		dispatchModalData({
			modalTitle: `Are you sure you want to delete “${shape.title}”?`,
			modalSubTitle:
				'This will remove this area and all its content from this map layer. Once removed, the information cannot be recovered.',
			confirmBtnTitle: 'Yes, delete it',
			onConfirmAction: () => onDeleteShapeHandler(currentFloorplan.id, shape, layer),
		});
		setModalToShow(DELETE_MAP_MODAL);
	};

	const showArchiveModal = (maps, activePage) => {
		if (maps.length === 1) {
			//single map
			dispatchModalData({
				modalTitle: `Are you sure you want to archive “${maps[0].title}”`,
				modalSubTitle: 'You can unarchive it at a later time.',
				confirmBtnTitle: 'Yes, archive it',
				onConfirmAction: () => archiveMapsAction(maps, true, activePage),
			});
		} else {
			// multiple maps
			dispatchModalData({
				modalTitle: `Are you sure you want to archive ${maps.length} maps?`,
				modalSubTitle: 'You can unarchive them at a later time.',
				confirmBtnTitle: 'Yes, archive them',
				onConfirmAction: () => archiveMapsAction(maps, true, activePage),
			});
		}
		if (mapHasChanges) {
			setNextModalToShow(ARCHIVE_MAP_MODAL);
			setMapViewModalToShow(UNSAVED_CHANGES_MODAL);
		} else {
			setModalToShow(ARCHIVE_MAP_MODAL);
		}
	};

	const showUnarchiveModal = (maps, activePage) => {
		if (maps.length === 1) {
			//single map
			dispatchModalData({
				modalTitle: `Are you sure you want to unarchive “${maps[0].title}”?`,
				modalSubTitle: 'You can archive it at a later time.',
				confirmBtnTitle: 'Yes, unarchive it',
				onConfirmAction: () => archiveMapsAction(maps, false, activePage),
			});
		} else {
			// multiple maps
			dispatchModalData({
				modalTitle: `Are you sure you want to unarchive ${maps.length} maps?`,
				modalSubTitle: 'You can archive them at a later time.',
				confirmBtnTitle: 'Yes, unarchive them',
				onConfirmAction: () => archiveMapsAction(maps, false, activePage),
			});
		}
		setModalToShow(ARCHIVE_MAP_MODAL);
	};

	const archiveCurrentFloorplan = (maps, archived) => {
		if (currentFloorplan && maps.some(map => map.id === currentFloorplan.id)) {
			let user = JSON.parse(localStorage.getItem('loggedUserData'));
			setCurrentFloorplan({
				...currentFloorplan,
				archivedBy: archived
					? {
							id: user.id,
							globalUserId: user.globalUserId,
							person: {
								personId: user.person.personId,
								status: user.person.status,
								type: user.person.type,
								firstName: user.person.name.givenName,
								lastName: user.person.name.familyName,
								fullName: `${user.person.name.familyName}, ${
									user.person.name.givenName
								}`,
								emailAddress: user.person.email?.emailAddress,
							},
					  }
					: null,
				dateArchived: archived ? Date.now() : null,
			});
			if (archived) {
				setIsEditMode(false);
			}
		}
	};

	const archiveMapsAction = (maps, archived, activePage) => {
		archiveCurrentFloorplan(maps, archived);
		setLoading(true);
		const body = {
			ids: maps.map(map => map.id),
			archived,
		};
		FloorplanService.bulkToggleArchive(body)
			.then(response => {
				if (response.statusCode === 200) {
					let msg = '';
					if (archived === true) {
						msg = `You have successfully archived ${
							maps.length === 1 ? `"${maps[0].title}"` : `${maps.length} maps`
						}`;
					} else {
						msg = `You have successfully unarchived ${
							maps.length === 1 ? `"${maps[0].title}"` : `${maps.length} maps`
						}`;
					}
					toaster(msg, Intent.SUCCESS, null, 3000, false, 'map-view-page');
				} else {
					toaster(
						'Something went wrong!',
						Intent.DANGER,
						null,
						3000,
						false,
						'map-view-page',
					);
				}
			})
			.catch(err => {
				toaster(err.error?.description, Intent.DANGER, null, 3000, false, 'map-view-page');
			})
			.finally(() => {
				if (activePage === MAP_LIST_PAGE) {
					//list page
					reloadList();
				} else {
					//details page
					reloadDetails();
				}
				setLoading(false);
				setModalToShow(null);
			});
	};

	const showViewUpdateNoteModal = (note, selectedShape, mode = 'view') => {
		setNote(note.note);
		setOldNote(note);
		setModalToShow(VIEW_UPDATE_NOTE_MODAL);
		setSelectedShape(selectedShape);
		setMode(mode);
	};

	const showViewUpdateCameraLinkModal = (cameraLink, selectedShape, mode = 'view') => {
		setCameraLink(cameraLink.url);
		setOldCameraLink(cameraLink);
		setModalToShow(VIEW_UPDATE_CAMERA_LINK_MODAL);
		setSelectedShape(selectedShape);
		setMode(mode);
	};

	const { getMapDetails, mapDetails } = MapInfoFn(
		closeModal,
		toaster,
		setLoading,
		setModalToShow,
	);

	const {
		updateFormData,
		createMap,
		updateMap,
		replaceAction,
		deleteFile,
		isDisabled,
		showProgressBar,
		selectedSite,
		validationErrors,
		formData,
		setHasError,
		isVisibleToAll,
		closeAddUpdateModal,
	} = CreateUpdateMapFn(
		closeModal,
		toaster,
		setLoading,
		setModalToShow,
		modalToShow,
		modalData.isEdit,
		history,
		reloadList,
		loadMapDetails,
		floorpanDetails,
		defaultSelectedSites,
		selectedSites,
		setSelectedSites,
		toggleMapOption,
		AddedFromView,
		setFloorPlanDetails,
		layer,
		editPermissionOnSiteId,
		currentFloorplan,
	);

	const { showDeleteMapModal, deleteTexts, onDeleteShapeHandler } = DeleteMapModalFn(
		DELETE_MAP_MODAL,
		setLoading,
		closeModal,
		setModalToShow,
		dispatchModalData,
		reloadList,
		reloadDetails,
		transformer,
		setRefreshTextList,
		currentFloorplan,
		toggleMapOption,
		setRefreshAreaList,
	);

	const {
		showTextRenameModal,
		textToRename,
		handleChangeText,
		disableRenameForm,
	} = RenameMapModalFn(
		RENAME_MAP_MODAL,
		setModalToShow,
		modalData,
		dispatchModalData,
		setRefreshTextList,
		setLoading,
		closeModal,
		currentFloorplan,
	);

	const [docIdSelected, setDocIdSelected] = useState([]);

	const {
		openNextModal,
		stepModal,
		types,
		fileIconLabel,
		type,
		options,
		searchValue,
		searchReselt,
		contentList,
		selectDocId,
		handleSearch,
		handleScroll,
		disabled,
		changeStep,
		debounceLoading,
		onChangeTypesHandler,
		typesValues,
		onNoteChangeHandler,
		saveBtnHandler,
		onWebAddressChangeHandler,
		showEmptyStatus,
		noDataAdded,
		filterationData,
		validationMapErrors,
	} = MapContentsListFn(setLoading, modalData, closeModal, docIdSelected, setDocIdSelected);

	const {
		noteSaveBtnDisabled,
		noteUpdateChangeHandler,
		updateNoteHandler,
	} = ViewUpdateNoteModalFn(setLoading, closeModal, setNote, reloadContent);

	const { showDeleteNoteModal, deleteNodeHandler, selectedNoteShape } = DeleteNoteModalFn(
		setLoading,
		closeModal,
		setModalToShow,
		reloadContent,
	);

	const {
		cameraLinkSaveBtnDisabled,
		cameraLinkUpdateChangeHandler,
		updateCameraLinkHandler,
		cameraLinkHasError,
		cameraLinkErrorMsg,
	} = ViewUpdateCameraLinkModalFn(setLoading, closeModal, setCameraLink, reloadContent);

	const { showDeleteCameraLinkModal, deleteCameraLinkHandler } = DeleteCameraLinkModalFn(
		setLoading,
		closeModal,
		setModalToShow,
		reloadContent,
	);

	const { showUploadContentModal, handleUploadContent } = UploadContentFn(
		UPLOAD_CONTENT,
		setLoading,
		closeModal,
		toaster,
		setModalToShow,
		modalData,
		dispatchModalData,
		reloadContent,
		openNextModal,
	);

	const { showDownloadMapModal, handleDownloadMap } = DownloadMapFn(
		DOWNLOAD_MAP,
		setLoading,
		setDownloadLoading,
		closeModal,
		toaster,
		setModalToShow,
		modalData,
		dispatchModalData,
	);
	const modalFunctions = {
		// use modal functions here
		showArchiveModal,
		showUnarchiveModal,
		closeModal,
		getMapDetails,
		showDeleteMapModal,
		showCreateMapModal,
		showAddContentsModal,
		showViewUpdateNoteModal,
		showTextDeleteModal,
		showTextRenameModal,
		showDeleteNoteModal,
		showViewUpdateCameraLinkModal,
		showDeleteCameraLinkModal,
		showAreaDeleteModal,
		showUploadContentModal,
		showDownloadMapModal,
		setModalToShow,
	};

	return (
		<MapsContext.Provider value={modalFunctions}>
			{modalToShow === ARCHIVE_MAP_MODAL && (
				<ArchiveMapModal
					loading={loading}
					title={modalData.modalTitle}
					subtitle={modalData.modalSubTitle}
					confirmBtnTitle={modalData.confirmBtnTitle}
					onConfirmAction={modalData.onConfirmAction}
					closeModal={closeModal}
				/>
			)}
			{modalToShow == 'MAP_INFO' && (
				<MapInfo mapDetails={mapDetails} loading={loading} closeModal={closeModal} />
			)}
			{modalToShow == 'DeleteMapModal' && (
				<DeleteMapModal
					modalName={DELETE_MAP_MODAL}
					modalToShow={modalToShow}
					loading={loading}
					modalData={modalData}
					closeModal={closeModal}
				/>
			)}
			{modalToShow == RENAME_MAP_MODAL && (
				<RenameMapModal
					loading={loading}
					modalData={modalData}
					textToRename={textToRename}
					handleChangeText={handleChangeText}
					disableRenameForm={disableRenameForm}
					closeModal={closeModal}
				/>
			)}
			{modalToShow === CREATE_MAP_MODAL && (
				<CreateUpdateMap
					loading={loading}
					updateFormData={updateFormData}
					createMap={createMap}
					updateMap={updateMap}
					replaceAction={replaceAction}
					deleteFile={deleteFile}
					isDisabled={isDisabled}
					showProgressBar={showProgressBar}
					selectedSite={selectedSite}
					closeModal={closeAddUpdateModal}
					validationErrors={validationErrors}
					formData={formData}
					setHasError={setHasError}
					setSelectedSites={setSelectedSites}
					isVisibleToAll={isVisibleToAll}
					isEdit={modalData.isEdit}
					AddedFromView={AddedFromView}
				/>
			)}
			{modalToShow == MAP_CONTENTS_MODAL && (
				<MapContentsList
					loading={loading}
					closeModal={closeModal}
					modalData={modalData}
					openNextModal={openNextModal}
					stepModal={stepModal}
					types={types}
					type={type}
					fileIconLabel={fileIconLabel}
					options={options}
					searchValue={searchValue}
					searchReselt={searchReselt}
					contentList={contentList}
					docIdSelected={docIdSelected}
					selectDocId={selectDocId}
					handleSearch={handleSearch}
					handleScroll={handleScroll}
					disabled={disabled}
					changeStep={changeStep}
					debounceLoading={debounceLoading}
					onChangeTypesHandler={onChangeTypesHandler}
					typesValues={typesValues}
					onNoteChangeHandler={onNoteChangeHandler}
					saveBtnHandler={saveBtnHandler}
					onWebAddressChangeHandler={onWebAddressChangeHandler}
					showEmptyStatus={showEmptyStatus}
					noDataAdded={noDataAdded}
					filterationData={filterationData}
					validationErrors={validationMapErrors}
					showUploadContentModal={showUploadContentModal}
				/>
			)}

			{modalToShow == VIEW_UPDATE_NOTE_MODAL && (
				<ViewUpdateNoteModal
					note={note}
					oldNote={oldNote}
					loading={loading}
					closeModal={closeModal}
					noteUpdateChangeHandler={noteUpdateChangeHandler}
					updateNoteHandler={updateNoteHandler}
					noteSaveBtnDisabled={noteSaveBtnDisabled}
					selectedShape={selectedShape}
					mode={mode}
				/>
			)}

			{modalToShow == DELETE_NOTE_MODAL && (
				<DeleteNoteModal
					loading={loading}
					closeModal={closeModal}
					deleteNodeHandler={deleteNodeHandler}
					selectedShape={selectedNoteShape}
				/>
			)}

			{modalToShow == VIEW_UPDATE_CAMERA_LINK_MODAL && (
				<ViewUpdateCameraLinkModal
					cameraLink={cameraLink}
					oldCameraLink={oldCameraLink}
					loading={loading}
					closeModal={closeModal}
					cameraLinkUpdateChangeHandler={cameraLinkUpdateChangeHandler}
					updateCameraLinkHandler={updateCameraLinkHandler}
					cameraLinkSaveBtnDisabled={cameraLinkSaveBtnDisabled}
					selectedShape={selectedShape}
					mode={mode}
					cameraLinkHasError={cameraLinkHasError}
					cameraLinkErrorMsg={cameraLinkErrorMsg}
				/>
			)}

			{modalToShow == DELETE_CAMERA_LINK_MODAL && (
				<DeleteCameraLinkModal
					loading={loading}
					closeModal={closeModal}
					deleteCameraLinkHandler={deleteCameraLinkHandler}
				/>
			)}

			{modalToShow == UPLOAD_CONTENT && (
				<UploadContent
					closeModal={closeModal}
					loading={loading}
					modalToShow={modalToShow}
					modalData={modalData}
					handleUploadContent={handleUploadContent}
				/>
			)}
			{modalToShow == DOWNLOAD_MAP && (
				<DownloadMap
					closeModal={closeModal}
					loading={loading}
					downloadLoading={downloadLoading}
					modalToShow={modalToShow}
					modalData={modalData}
					handleDownloadMap={handleDownloadMap}
				/>
			)}
			{children}
		</MapsContext.Provider>
	);
};
