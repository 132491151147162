import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Button } from 'components/Button';
import { Title } from 'components/Title';
import { LinkButton } from 'components/LinkButton';
import { Checkbox, Position, Toaster, Intent } from '@blueprintjs/core';
import Swal from 'sweetalert2';

const ContentWrapper = styled.div`
	 {
		padding: 32px;
		border: 1px solid #d8e1e8;
		background-color: white;
		flex-direction: column;
		display: flex;
		gap: 12px;
	}
`;
const PlansBox = styled.div`
	 {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		align-self: stretch;
		width: 50%;
		gap: 8px;
		padding: 10px;
	}
`;
const CheckboxWrapper = styled.div`
	 {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		align-self: stretch;
		gap: 8px;
		padding: 10px;
		border-radius: 3px;
		border: 1px solid var(--grayscale-light-gray-2, #d8e1e8);
		background: #fff;

		.settings-checkbox {
			margin-bottom: 0px !important;
		}
	}
`;
const LinksWrapper = styled.div`
	 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;
		gap: 12px;
	}
`;

class Settings extends React.Component {
	state = {
		loading: true,
		plans: [],
		selectedCount: 0,
		modalMessageVariables: '',
	};

	handleCheckAll = () => {
		const { plans } = this.state;
		const updatedplans = plans.map(checkbox => ({
			...checkbox,
			isChecked: true,
		}));
		this.setState({
			plans: updatedplans,
			selectedCount: updatedplans.length,
		});
	};

	handleToogleCheck = (event, plan) => {
		const { selectedCount } = this.state;
		plan.isChecked = event.target.checked;
		if (event.target.checked) {
			this.setState({ selectedCount: selectedCount + 1 });
		} else {
			this.setState({ selectedCount: selectedCount - 1 });
		}
	};

	handleUncheckAll = () => {
		const { plans } = this.state;
		const updatedplans = plans.map(checkbox => ({
			...checkbox,
			isChecked: false,
		}));
		this.setState({ plans: updatedplans, selectedCount: 0 });
	};

	preSubmit = () => {
		const { plans, totalCount } = this.state;
		const submittedPlans = plans.filter(plan => plan.isChecked);
		let message = 'the selected plans';
		if (submittedPlans.length === 1) {
			message = 'this plan';
		}
		if (submittedPlans.length === totalCount) {
			message = 'all the school plans';
		}
		Swal.fire({
			title: 'Mark plans as incomplete?',
			icon: 'warning',
			html: `This will change the status of ${message} in your district to not completed. This can’t be undone.`,
			showCancelButton: true,
			confirmButtonText: 'Confirm',
			reverseButtons: true,
			returnFocus: false,
			customClass: {
				confirmButton: 'bp3-button ems-primary-button',
				cancelButton: 'bp3-button',
			},
		}).then(result => {
			if (result.dismiss === 'cancel') {
				return Swal.close();
			}
			this.submit();
		});
	};

	submit = () => {
		const { plans, totalCount } = this.state;
		const submittedPlans = plans
			.filter(plan => plan.isChecked)
			.map(plan => plan.id);
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${process.env.API_URL}/district-templates/bulk-toggle-complete`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					isCompleted: false,
					plans: submittedPlans,
				}),
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					let message = 'Plans';
					if (submittedPlans.length === 1) {
						message = 'Plan';
					}
					if (submittedPlans.length === totalCount) {
						message = 'All plans';
					}
					this.showToaster(
						`${message} marked as incomplete.`,
						Intent.SUCCESS,
					);
					this.loadData();
					return null;
				}
			});
	};

	showToaster = (message, intent = 'success') => {
		const toastParams = {
			message,
			intent,
			timeout: 3000,
		};
		this.toaster.show(toastParams);
	};

	loadData = () => {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${
				process.env.API_URL
			}/district-templates?includeContent=false&isCompleted=true`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode !== 200) {
					const localToken = JSON.parse(localStorage.getItem('user'));
					localToken.jwt = '';
					localStorage.setItem('user', JSON.stringify(localToken));
					return window.location.reload();
				}
				let plans = [];
				plans = resp.data;
				plans.map(plan => ({
					id: plan.id,
					isChecked: false,
					name: plan.name,
				}));
				return this.setState({
					plans,
					selectedCouont: 0,
					totalCount: plans.length,
					loading: false,
				});
			});
	};

	componentDidMount() {
		this.loadData();
	}

	render() {
		const { history } = this.props;
		const { plans, loading, selectedCount, totalCount } = this.state;
		if (loading) return <LoadingSpinner />;
		return (
			<div>
				<Title>Available Safety Plan Templates</Title>
				<div className="row">
					<div className="col-md-10">
						<div className="row">
							<div className="col-md-8">
								Select which safety plan templates to mark as
								incomplete.
								<br />
								This will set all completed plans made from
								these templates to incomplete.
							</div>
							<div className="col-md-4">
								<Button
									className="float-right"
									text="Mark as incomplete"
									intent="primary"
									onClick={this.preSubmit}
									disabled={selectedCount === 0}
								/>
							</div>
						</div>
						<br />
						<div>
							<ContentWrapper>
								<div>
									<LinksWrapper className="float-right">
										<LinkButton
											text="Check all"
											intent="primary"
											disabled={
												selectedCount === totalCount
											}
											onClick={this.handleCheckAll}
										/>
										<LinkButton
											text="Uncheck all"
											intent="primary"
											disabled={selectedCount === 0}
											onClick={this.handleUncheckAll}
										/>
									</LinksWrapper>
								</div>
								<br />
								<div>
									<PlansBox>
										{plans.map(plan => (
											<CheckboxWrapper>
												<Checkbox
													className="settings-checkbox"
													checked={plan.isChecked}
													onChange={() =>
														this.handleToogleCheck(
															event,
															plan,
														)
													}
												>
													{plan.name}
												</Checkbox>
											</CheckboxWrapper>
										))}
									</PlansBox>
								</div>
							</ContentWrapper>
						</div>
					</div>
				</div>
				<Toaster
					ref={ref => {
						this.toaster = ref;
					}}
					position={Position.TOP_RIGHT}
				/>
			</div>
		);
	}
}

Settings.propTypes = {
	history: PropTypes.object,
};

export default Settings;
