import React, { useContext } from 'react';
import { FieldCard, RollcallRadioGroup, MarkAllAccountedRadioGroup } from '../SharedFields';
import { CreateAlarmTypeContext } from '../../../../../../provider';
import '../SharedFields/css/FieldCard.scss';
import './css/RollCall.scss';

export const RollCall = () => {
	const { formData, formErrors, onChangeFormData } = useContext(CreateAlarmTypeContext);

	return (
		<FieldCard
			header="Roll Call"
			description={
				<>
					Would you like a roll call to be taken of students, staff, and visitors when
					this alarm is activated?
					<span className="text-danger"> *</span>
				</>
			}
			loading={false}
			style={{
				height: '100%',
			}}
		>
			<RollcallRadioGroup
				formData={formData}
				formErrors={formErrors}
				onChangeFormData={onChangeFormData}
			/>
			<div className="markAllAccounted_Wrapper">
				<FieldCard
					className="no-border no-padding"
					header="Mark all as accounted for option"
					description={
						<>
							Would you prefer enabling the option "Mark all as accounted for" for
							users during Roll Call to account for their entire class simultaneously?
							Selecting "No" means users must account for each student individually.
							<span className="text-danger">*</span>
						</>
					}
					loading={false}
				>
					<MarkAllAccountedRadioGroup
						formData={formData}
						formErrors={formErrors}
						onChangeFormData={onChangeFormData}
					/>
				</FieldCard>
			</div>
		</FieldCard>
	);
};
