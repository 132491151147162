import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
	Popover as BPPopover,
	Menu as BPMenu,
	Position as BPPosition,
	Button as BPButton,
} from '@blueprintjs/core';

const StyledBPButton = styled(BPButton)`
	background-color: white !important;
	box-shadow: none !important;
`;

const getMenuItemsFromActions = actions =>
	actions.map(action => {
		if (action?.isVisible !== false) {
			return (
				<BPMenu.Item
					key={action.id}
					icon={action.icon || null}
					onClick={action.handler}
					text={action.title}
					className={action.isDestructive ? 'bp3-intent-danger' : ''}
				/>
			);
		}
	});

const MoreActionsButton = ({ actions }) => (
	<BPPopover
		className="more-actions-button"
		content={<BPMenu>{getMenuItemsFromActions(actions)}</BPMenu>}
		position={BPPosition.BOTTOM}
	>
		<StyledBPButton icon="more" />
	</BPPopover>
);

MoreActionsButton.propTypes = {
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			handler: PropTypes.func.isRequired,
			icon: PropTypes.string,
			title: PropTypes.string.isRequired,
			isDestructive: PropTypes.bool,
		}),
	),
};

export { MoreActionsButton };
