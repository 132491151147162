import React from "react";
import DropdownButton from "components/DropdownButton";
import Dropdown from 'react-bootstrap/Dropdown';
import { Icon } from '@blueprintjs/core';
import './index.scss';
import PropTypes from 'prop-types';

const DropdownComponent = ({
    text,
    items,
    onSelectItem,
    ...restProps
}) => {

    return (
        <div className="dropdown-sorting-wrapper" {...restProps}>
            <DropdownButton
                variant='white'
                text={text}
            >
                {items.map(item => (
                    <>
                    <Dropdown.Item key={item.value} onClick={() => onSelectItem(item)}>
                        {item.showIcon ? <Icon icon={item.icon} intent='primary' className='item-icon-selected' /> : <span className="item-icon" /> }
                        <span className={item.isSelected ? 'item-text active' : 'item-text'}>{item.label}</span>
                    </Dropdown.Item>
                    {item.addBreakLine === true && (
                        <hr className="page-hr"></hr>
                    )}
                    </>
                ))} 
            </DropdownButton>
        </div>
    )
}

DropdownComponent.propTypes = {
    text: PropTypes.any.isRequired,
    items: PropTypes.array.isRequired,
    onSelectItem: PropTypes.func.isRequired,
}

export default DropdownComponent;