import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
	Button,
	CardContainer,
	Dropdown,
	LoadingSpinner,
	PageHeader,
	EmptyState,
	ToasterContext,
	Intent,
	LoadingModal,
} from 'componentsV2';
import { RespondService } from 'services';
import { UserService } from 'services';
import { DrillsTable } from './DrillsTable';
import { noResult } from '../../componentsV2/EmptyState/icons';
import './styles.scss';

export const FirstResponder = () => {
	const [districtSiteOptions, setDistrictSiteOptions] = useState(null);
	const [districtSiteSelectedValue, setDistrictSiteSelectedValue] = useState(null);
	const [siteOptions, setSiteOptions] = useState(null);
	const [siteSelectedValue, setSiteSelectedValue] = useState(null);
	const [drillsLoading, setDrillsLoading] = useState(false);
	const [drills, setDrills] = useState([]);
	const [loadingModalIsOpen, setOpenLoadingModal] = useState(false);
	const toaster = useContext(ToasterContext);
	const dispatch = useDispatch();

	const getDrills = (districtId, buildingId) => {
		setDrillsLoading(true);
		RespondService.getBuildingDrills(districtId, buildingId)
			.then(resp => {
				setDrills(resp.data);
			})
			.finally(() => setDrillsLoading(false));
	};

	const getBuildings = districtId => {
		RespondService.getBuildings(districtId).then(resp => {
			setSiteOptions(resp.data.map(site => ({ label: site.name, value: site.id })));
		});
	};

	const formatDistrictOptions = data =>
		data.map(district => ({
			label: district.title,
			value: district.mt_id,
			masqueradeUserId: district.pa_id,
		}));

	const accessSite = () => {
		setOpenLoadingModal(true);
		RespondService.startResponderMasqueradingSession(
			districtSiteSelectedValue?.value,
			districtSiteSelectedValue?.masqueradeUserId,
		)
			.then(response => {
				if (response.statusCode === 200) {
					// get token
					const { accessToken } = response.data;
					return UserService.fetchMyInfo(accessToken)
						.then(({ data }) => {
							// get info for current user
							const masqueradingUserToken = JSON.parse(localStorage.getItem('user'))
								.jwt;
							const masqueradingUserData = JSON.parse(
								localStorage.getItem('loggedUserData'),
							);
							// store current user token & info in localStorage
							dispatch({
								type: 'SET_MASQUERADING_USER_TOKEN',
								payload: masqueradingUserToken,
							});
							dispatch({
								type: 'SET_MASQUERADING_USER_DATA',
								payload: masqueradingUserData,
							});
							// overwrite current user info by masquerading user info
							dispatch({
								type: 'SET_USER',
								payload: accessToken,
							});
							dispatch({
								type: 'SET_USER_DATA',
								payload: data,
							});
							dispatch({
								type: 'SET_CURRENT_DISTRICT',
								payload: data.primaryBuilding,
							});
							location.reload();
						})
						.catch(err => {
							setOpenLoadingModal(false);
							toaster(err.error.description, Intent.DANGER);
						});
				}
				toaster('Masquerading failed', Intent.DANGER);
			})
			.catch(err => {
				toaster(err.error.description, Intent.DANGER);
			})
			.finally(() => {
				setOpenLoadingModal(false);
			});
	};

	useEffect(() => {
		RespondService.getDistricts().then(resp => {
			setDistrictSiteOptions(formatDistrictOptions(resp.data));
		});
	}, []);

	return (
		<div className="first-responder-dashboard">
			<PageHeader title="First Responder Portal" />
			<CardContainer
				title="Site Selection"
				subtitle="Select a site to access its Emergency Management Solution"
			>
				<hr />
				<Dropdown
					placeholder="Select a district"
					options={districtSiteOptions}
					value={districtSiteSelectedValue}
					onChange={item => {
						setDistrictSiteSelectedValue(item);
						getBuildings(item.value);
					}}
					hasSearch
					hasEmptyState
					searchMessageTitle="No districts found"
					searchMessageSubtitle="Your search didn't match any districts."
				/>
				<Dropdown
					placeholder="Select a site"
					disabled={!districtSiteSelectedValue}
					options={siteOptions}
					value={siteSelectedValue}
					onChange={item => {
						setSiteSelectedValue(item);
						getDrills(districtSiteSelectedValue.value, item.value);
					}}
					hasSearch
					hasEmptyState
					searchMessageTitle="No sites found"
					searchMessageSubtitle="Your search didn't match any sites."
				/>
				<Button
					text="Access site"
					rightIcon="arrow-top-right"
					size="md"
					onClick={accessSite}
					disabled={!siteSelectedValue}
				/>
			</CardContainer>
			<div className="drills-wrapper">
				<div className="first-responder-dashboard-header">
					<h4 className="title">Drills list</h4>
					<div className="subtitle">
						View scheduled and completed drills for the district selected above.
					</div>
				</div>
				<div className="first-responder-dashboard-body">
					{drillsLoading && <LoadingSpinner />}
					{drills.length === 0 && !drillsLoading ? (
						<EmptyState
							header={
								!siteSelectedValue
									? 'Select a site to show the drills list.'
									: 'No drills were added yet.'
							}
							icon={noResult}
							showCircles={false}
						/>
					) : (
						!drillsLoading && <DrillsTable drills={drills} />
					)}
				</div>
			</div>
			<LoadingModal
				isOpen={loadingModalIsOpen}
				loading={true}
				text={`Login you into ${siteSelectedValue?.label}...`}
				onClose={() => {}}
			/>
		</div>
	);
};
