import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'components/Dropdown';

export default function SitesDropDown({
	id = '',
	itemsList = {},
	onSelectItem = null,
	value = null,
	dataIndex = null,
	disabledOptions = {},
	setDisabledSites,
	disabledSites,
	isReadOnly = false,
	savedName = '',
}) {
	const [selectedItemValue, setSelectedItemValue] = useState(value);
	const [selectedItem, setSelectedItem] = useState(null);
	// const [disabledSites, setDisabledSites] = useState(disabledOptions);

	const selectItem = event => {
		if (disabledSites.length > 0 && disabledSites.indexOf(event.value) > -1) {
			return false;
		}
		let disabSites = [...disabledSites, event.value];

		if (selectedItem.value !== 0 && selectedItem.value !== event.value) {
			disabSites = disabSites.filter(site => site !== selectedItem.value);
		}

		setDisabledSites(disabSites);

		setSelectedItemValue(event.value);
		setSelectedItem(event);
		// callback optional function
		if (onSelectItem != null) {
			onSelectItem(dataIndex, event.value, value);
		}
	};

	const firstOption = { label: 'Select Site', value: 0 };

	const options = () => {
		let allOption = [];

		if (itemsList.length > 0) {
			itemsList.map((item, k) =>
				allOption.push({
					label: item.name,
					value: item.id,
          indent: item?.indent
				}),
			);
		}

		return allOption;
	};

	const getSelectedOption = () => {
		let selectedItm = null;
		if (selectedItemValue != null) {
			let allOpts = options();
			let kFound = null;
			allOpts.map((itm, k) => {
				if (itm.value == selectedItemValue) {
					kFound = k;
					return;
				}
			});
			selectedItm = allOpts[kFound];
		} else {
			selectedItm = firstOption;
		}
		setSelectedItem(selectedItm);
	};

	const getFieldLabel = () => {
		return dataIndex == 0 ? 'Primary Site' : 'Site';
	};

	useEffect(() => {
		if (isReadOnly) {
			setSelectedItem({
				label: savedName,
				value: value,
			});
		} else {
			getSelectedOption();
		}
	}, []);

	return (
		<Dropdown
			label={getFieldLabel()}
			value={selectedItem ? selectedItem : firstOption}
			options={options()}
			onChange={e => selectItem(e, selectedItem)}
			id={id}
			disabledOptions={disabledSites}
			disabled={isReadOnly}
		/>
	);
}
