import React from 'react';
import { Icon } from '@blueprintjs/core';
import { Tooltip } from 'componentsV2';

export const AlarmSubmittedClassRow = ({ submittedClass }) => {
	const submittedByName = name => {
		let nameArray = name.split(', ');
		if (nameArray.length == 2) {
			name = `${nameArray[1]} ${nameArray[0]}`;
		}
		return name;
	};

	return (
		<div className="alarm-submitted-classes-row">
			<div className="alarm-submitted-classes-column1">
				<div className="alarm-class-info-container">
					<div className="alarm-class-icon">
						{submittedClass.unaccounted_students > 0 ? (
							<Tooltip
								tooltipTitle={
									<>
										Some students in this class
										<br />
										do not have a status submitted
									</>
								}
							>
								<Icon icon="error" className="icon" color="#E81717" size={16} />
							</Tooltip>
						) : (
							<Icon icon="tick-circle" className="icon" color="#5DD400" size={16} />
						)}
					</div>
					<div className="alarm-class-title-container">
						<span className="alarm-class-title">{submittedClass.course_name}</span>
						<span className="alarm-class-grade">
							{submittedClass.grade && <>Grade: {submittedClass.grade}</>}
						</span>
					</div>
				</div>
			</div>
			<div className="alarm-submitted-classes-column2">
				{submittedClass.user_name && (
					<span className="alarm-class-submitted-by">
						{submittedByName(submittedClass.user_name)}
					</span>
				)}
			</div>
		</div>
	);
};
