import React, { useEffect, useState } from 'react';
import { TemplateSection } from './TemplateSection';
import './css/SectionsComparison.scss';
import PropTypes from 'prop-types';
import { ContentType, DocumentType } from './ContentBody';
import { EmptySection } from './EmptySection';
import { Icon } from '@blueprintjs/core';
import { diffContent, cleanText } from 'hooks/useEventLogs';

export const SectionsComparison = ({
    currentUpdate,
    useUpdated,
    keepExisting,
    template,
    onEditorChange,
    onCloseModal
}) => {

    const [rightSection, setRightSection] = useState({
        title: null,
        description: null,
        icon: null,
        type: null,
        isButtonDisabled: false
    });
    const [leftSection, setLeftSection] = useState({
        title: null,
        description: null,
        icon: null,
        type: null,
        isButtonDisabled: false
    });
    const [sectionsContent, setSectionsContent] = useState({
        oldContent: "",
        newContent: ""
    });

    const { targetUpdate, updatedContent, sectionType, type } = currentUpdate;
    const isTargetUpdateDocument = targetUpdate?.fileSection ? targetUpdate.fileSection : false; 
    const isUpdatedContentDocument = updatedContent?.is_document ? updatedContent?.is_document : false;

    useEffect(() => {
        if(type === 'delete') { // delete section/subsection 
            deleteSectionOrSubsection();
        } else {
            if(targetUpdate == null || targetUpdate == undefined) { // add section/subsection
                addSectionOrSubsection()
            } else { // update existing section/subsection
                updateSectionOrSubsection()
            }
        }
        const oldContent = targetUpdate?.content ? targetUpdate.content : "";
        const newContent = updatedContent?.content ? updatedContent.content : "";
        if( newContent != "" ) {
            const parsedContents = diffContent(cleanText(oldContent), cleanText(newContent))
            setSectionsContent({
                oldContent: parsedContents.oldContent,
                newContent: parsedContents.newContent
            })
        } else {
            setSectionsContent({
                oldContent: oldContent,
                newContent: newContent
            })
        }
    }, [currentUpdate])

    const addSectionOrSubsection = () => {
        if(sectionType === 'subsection') {
            const parentSection = template.sections.some(section => section.id === updatedContent?.actualSectionId);
            if(parentSection) { // linked to parent section
                setLeftSection({
                    title: 'New subsection added to template',
                    description: 'New subsection added to the template. If you wish to include it in this plan click on "Use updated version", otherwise click on "Keep existing version',
                    icon: 'error',
                    type: 'Primary',
                    isButtonDisabled: false,
                })
                restoreRightSection()
            } else { // not linked to parent section
                setLeftSection({
                    title: 'Subsection linked to a deleted section',
                    description: 'New subsection added to the template. However, this subsection is linked to a main section that does not exist in your safety plan. Therefore you cannot use the updated version.',
                    icon: 'warning-sign',
                    type: 'Warning',
                    isButtonDisabled: false,
                })
                setRightSection({
                    title: null,
                    description: null,
                    icon: null,
                    type: null,
                    isButtonDisabled: true,
                });
            }
        } else { // section added
            setLeftSection({
                title: 'New section added to template',
                description: 'New section added to the template. If you wish to include it in this plan click on "Use updated version", otherwise click on "Keep existing version"',
                icon: 'error',
                type: 'Primary',
                isButtonDisabled: false,
            })
            restoreRightSection()
        }
    }

    const deleteSectionOrSubsection = () => {
        if(sectionType === 'subsection') { // sub section deleted
            if(targetUpdate == null || targetUpdate == undefined) {
                setRightSection({
                    title: 'Subsection deleted from the template',
                    description: 'Subsection was deleted from the template. If you wish to delete it from this plan click on "Use updated version", otherwise click on "Keep existing version"',
                    icon: 'error',
                    type: 'Error',
                    isButtonDisabled: true,
                })
                setLeftSection({
                    title: 'Subsection linked to a deleted section',
                    description: 'Subsection deleted from the template. However, this subsection is linked to a deleted subsection that does not exist in your safety plan. Therefore you cannot use the updated version.',
                    icon: 'warning-sign',
                    type: 'Warning',
                    isButtonDisabled: false,
                })
            } else {
                //get subsection parent section
                const parentSection = template.sections.some(section => section.id === updatedContent?.actualSectionId);
                
                if(!parentSection) { // not linked to parent section
                    setRightSection({
                        title: 'Subsection deleted from the template',
                        description: 'Subsection was deleted from the template. If you wish to delete it from this plan click on "Use updated version", otherwise click on "Keep existing version"',
                        icon: 'error',
                        type: 'Error',
                        isButtonDisabled: true,
                    })
                    setLeftSection({
                        title: 'Subsection linked to a deleted section',
                        description: 'Subsection deleted from the template. However, this subsection is linked to a main section that does not exist in your safety plan. Therefore you cannot use the updated version.',
                        icon: 'warning-sign',
                        type: 'Warning',
                        isButtonDisabled: false,
                    })
                } else {
                    setRightSection({
                        title: 'Section deleted from the template',
                        description: 'Section was deleted from the template. If you wish to delete it from this plan click on "Use updated version", otherwise click on "Keep existing version"',
                        icon: 'error',
                        type: 'Error',
                        isButtonDisabled: false,
                    })
                    restoreLeftSection();
                }
            }
        
        } else { // section deleted
            if(targetUpdate == null || targetUpdate == undefined) {
                setRightSection({
                    title: 'Section deleted from the template',
                    description: 'Section was deleted from the template. If you wish to delete it from this plan click on "Use updated version", otherwise click on "Keep existing version"',
                    icon: 'error',
                    type: 'Error',
                    isButtonDisabled: true,
                })
                setLeftSection({
                    title: 'Section linked to a deleted section',
                    description: 'Section was deleted from the template. However, this section is linked to a deleted section that does not exist in your safety plan. Therefore you cannot use the updated version.',
                    icon: 'warning-sign',
                    type: 'Warning',
                    isButtonDisabled: false,
                })
            } else {
                setRightSection({
                    title: 'Section deleted from the template',
                    description: 'Section was deleted from the template. If you wish to delete it from this plan click on "Use updated version", otherwise click on "Keep existing version"',
                    icon: 'error',
                    type: 'Error',
                    isButtonDisabled: false,
                })
                restoreLeftSection();
            }
        }
    }

    const restoreRightSection = () => {
        setRightSection({
            title: null,
            description: null,
            icon: null,
            type: null,
            isButtonDisabled: false,
        });
    }

    const restoreLeftSection = () => {
        setLeftSection({
            title: null,
            description: null,
            icon: null,
            type: null,
            isButtonDisabled: false,
        });
    }

    const updateSectionOrSubsection = () => {
        restoreRightSection()
        restoreLeftSection()
    }

	return (
        <section className="sections-comparison-wrapper">
            <section className='sections-header'>
                <div className='left-side'>
                    <h3>Updates</h3>
                    <span>{template.name}</span>
                </div>
                <Icon icon='cross' className='right-side' onClick={onCloseModal} />
            </section>
            <section className='sections-comparison'>
                <TemplateSection
                    btnTitle="Keep existing version"
                    isButtonDisabled={leftSection.isButtonDisabled}
                    btnType="tertiary"
                    onClickHandler={keepExisting}
                    title={targetUpdate?.title}
                    subTitle='(Current)'
                >
                    {leftSection.title !== null && (
                        <EmptySection 
                            title={leftSection.title}
                            description={leftSection.description}
                            icon={leftSection.icon}
                            type={leftSection.type}
                        />
                    )}
                    {leftSection.title === null && (
                        <>
                        {isTargetUpdateDocument && (
                            <DocumentType 
                                section={targetUpdate}
                            />
                        )}
                        {!isTargetUpdateDocument && (
                            <ContentType 
                                hasEditor={true}
                                content={sectionsContent.oldContent}
                                onEditorChange={onEditorChange}
                            />
                        )}
                        </>
                    )}
                </TemplateSection>
                <TemplateSection
                    btnTitle="Use updated version"
                    isButtonDisabled={rightSection.isButtonDisabled}
                    btnType="primaryDefault"
                    onClickHandler={useUpdated}
                    title={updatedContent?.title}
                    subTitle='(Updated)'
                >
                {rightSection.title !== null && (
                    <EmptySection 
                        title={rightSection.title}
                        description={rightSection.description}
                        icon={rightSection.icon}
                        type={rightSection.type}
                    />
                )}
                {rightSection.title === null && (
                    <>
                        {isUpdatedContentDocument && (
                            <DocumentType 
                                section={updatedContent}
                            />
                        )}
                        {!isUpdatedContentDocument && (    
                            <ContentType 
                                hasEditor={false}
                                content={sectionsContent.newContent}
                            />
                        )}
                    </>
                )}
                </TemplateSection>
            </section>
        </section>
	);
};

SectionsComparison.propTypes = {
    currentUpdate: PropTypes.object,
    useUpdated: PropTypes.func.isRequired,
    keepExisting: PropTypes.func.isRequired,
    template: PropTypes.object
}