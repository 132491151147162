import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'componentsV2';
import { MapElementCard, ShowHideAllIcon, TabPanel } from '../SidebarComponents';
import { TextListActionsList } from './TextListActionsList';
import { removeDuplicatesWithCount } from 'utilities/removeDuplicatesWithCount';
import { NoTextAddedState, NoTextFoundState } from './';
import { onDragShapeHandler } from '../Functions';
import { GEOMETRY_TYPE_TEXT } from '../../consts';

export const InUsePanel = ({
	floorplanId,
	editPermission,
	floorplanArchived,
	list,
	addNewTextHandler,
	addBtnDisabled,
	triggerShowHide,
	siteId,
	layer,
	floorplanShapes,
	setFloorplanShapes,
	isEditMode,
}) => {
	const [textList, setTextList] = useState([]);
	const [noDataAdded, setNoDataAdded] = useState(false);
	const [loading, setLoading] = useState(true);
	const [showAll, setShowAll] = useState(true);
	const transformer = layer.getStage().findOne('Transformer');

	useEffect(() => {
		const filteredTexts = list.filter(shape => shape.floorPlanId === floorplanId);
		const removeDuplicated = removeDuplicatesWithCount(filteredTexts, 'title');
		const filteredList = removeDuplicated.map(text => {
			const isVisible = floorplanShapes.some(
				floorplanShape =>
					floorplanShape.id === text.id &&
					floorplanShape.geometryType === GEOMETRY_TYPE_TEXT &&
					floorplanShape.visible === true,
			);

			return {
				...text,
				visible: isVisible,
			};
		});
		setTextList(filteredList);
		setNoDataAdded(removeDuplicated.length === 0);
		setLoading(false);
	}, [list, floorplanId]);

	const onSearchHandler = search => {
		setTextList(
			textList.filter(textItem =>
				textItem.title?.toLowerCase().includes(search.toLowerCase()),
			),
		);
	};

	useEffect(() => {
		showHideAllHandler(triggerShowHide);
	}, [triggerShowHide]);

	useEffect(() => {
		setShowAll(false);
	}, []);

	const updateFloorplanShapesVisibility = (title, visible) => {
		// set area shape matched with passed it to visible/invisible
		setFloorplanShapes(oldShapes =>
			oldShapes.map(floorplanShape => {
				if (
					floorplanShape.geometryType === GEOMETRY_TYPE_TEXT &&
					floorplanShape.title === title
				) {
					return {
						...floorplanShape,
						visible: !visible,
					};
				}
				return floorplanShape;
			}),
		);
	};

	const showHideAllHandler = val => {
		textList.forEach(textShape => {
			showHideItemHandler(textShape, val);
		});

		setShowAll(val);
	};

	const showHideItemHandler = (textShape, value) => {
		updateFloorplanShapesVisibility(textShape.title, value);
		let filteredTextList = textList.map(text => {
			if (text.id == textShape.id) {
				text.visible = !value;
			}
			return text;
		});

		setTextList(filteredTextList);

		layer.children.filter(child => {
			if (child.getAttrs().text == textShape.title) {
				if (value == true) {
					child.hide();
					transformer.nodes([]);
				} else {
					child.show();
				}
			}
		});
	};

	if (loading) return <LoadingSpinner />;
	return (
		<>
			{noDataAdded && (
				<NoTextAddedState
					editPermission={editPermission}
					floorplanArchived={floorplanArchived}
					addNewTextHandler={addNewTextHandler}
					addBtnDisabled={addBtnDisabled}
					isEditMode={isEditMode}
				/>
			)}
			{!noDataAdded && (
				<TabPanel
					description="The texts listed below are texts already in use for this map layer.
												If you don’t see a text you’d like to use, select the + button to add a new text.
												Drag a text from the list to add to this map layer. Right click to edit the text."
					onSearchHandler={onSearchHandler}
					onAddClickHandler={addNewTextHandler}
					addBtnTitle="Add Text"
					hasSearch={true}
					showAddBtn={editPermission && !floorplanArchived && isEditMode}
					addBtnDisabled={addBtnDisabled}
					editPermission={editPermission}
				>
					{textList.length === 0 && <NoTextFoundState />}
					{!!textList.length && (
						<>
							<ShowHideAllIcon
								showAll={showAll}
								onClick={() => showHideAllHandler(!showAll)}
							/>
							{textList.map(textShape => (
								<MapElementCard
									key={textShape.id}
									title={`${textShape.title} [${textShape.counter}]`}
									isSelected={textShape.visible}
									isHidden={!textShape.visible}
									onDrag={e => onDragShapeHandler(e, textShape)}
									onShowHideClick={() =>
										showHideItemHandler(textShape, textShape.visible)
									}
									rightElement={
										editPermission && (
											<TextListActionsList
												textShape={textShape}
												editPermission={editPermission}
												floorplanArchived={floorplanArchived}
												tabType="inUse"
												siteId={siteId}
												layer={layer}
												isEditMode={isEditMode}
											/>
										)
									}
									editPermission={editPermission}
									floorplanArchived={floorplanArchived}
								/>
							))}
						</>
					)}
				</TabPanel>
			)}
		</>
	);
};
