/* eslint-disable consistent-return */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { Button } from 'components/Button';
import { DateTimePicker } from 'components/DateTimePicker';
import { Dropdown } from 'components/Dropdown';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { CardModal } from 'components/Modals/CardModal';
import { CopyDrillScheduleModal } from 'components/Modals/CopyDrillScheduleModal';
import { MoreActionsButton } from 'components/MoreActionsButton';
import { StatusPilsner } from 'components/StatusPilsner';
import { Table } from 'components/Table';
import {
	BuildingsService,
	SchoolYearsService,
	DrillTypesService,
	DrillsService,
	DrillLogService,
	DrillDistrictRequirementsService,
} from 'services';
import { safeDateToLuxon, formatDate, addMonthsToDate } from 'utilities/dates';
import { InstructionsBlock } from 'components/InstructionsBlock';
import { checkPermission } from 'utilities/permissions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import selectRoute from './selectors';

const Page = styled.div`
	min-height: 100vh;

	.dropdown-filters {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.dropdown {
			margin-right: 24px;
			margin-bottom: 16px;
			width: calc(33% - 24px);
		}
	}

	.table {
		.dropdown {
			min-width: initial;
		}

		.date-time-picker {
			min-width: initial;
		}
	}

	.drill-actions-row {
		display: flex;
		margin-top: 24px;
		margin-bottom: 48px;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
	}

	.table-header {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		h4 {
			color: #10161a;
			font-size: 18px;
			font-weight: 700;
			line-height: 21px;
			font-style: normal;
			letter-spacing: 0.3px;
			font-family: Merriweather;
		}

		.drill-buttons-row {
			display: flex;
			align-items: end;
			flex-direction: row;
		}
	}
`;

const BASE_VIEW = {
	selectedSite: null,
	selectedSchoolYear: null,
	selectedDrillType: null,
	selectedDrill: null,
	scheduledDrills: [],
	updatedScheduledDrills: [],
	unscheduledDrills: [],
	invalidDrillsError: false,
	showCopyDrillsModal: false,
	canEditDrills: false,
};

const BASE_STATE = {
	...BASE_VIEW,
	loading: true,
	sites: null,
	schoolYears: null,
	drillTypes: null,
	requirements: null,
};

class ScheduleDrills extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		Promise.all([
			BuildingsService.getAll(),
			SchoolYearsService.fetchYears(),
		])
			.then(([sites, schoolYears]) => {
				this.setState({
					...BASE_STATE,
					sites,
					schoolYears,
					loading: false,
				});
			})
			.catch(() => {
				this.setState({
					...BASE_STATE,
					loading: false,
					error: 'Could not load this page, please try refreshing',
				});
			});
	}

	componentDidUpdate(_, prevState) {
		const { selectedSchoolYear } = this.state;
		if (
			selectedSchoolYear &&
			prevState.selectedSchoolYear?.value !== selectedSchoolYear?.value
		) {
			DrillTypesService.getAll(true, selectedSchoolYear?.value).then(
				drillTypes =>
					this.setState({
						drillTypes,
						loading: false,
					}),
			);
		}
	}

	setDrillType = selectedDrillType => {
		// updated selected drill type
		const { selectedSite, selectedSchoolYear } = this.state;
		this.setState({ selectedDrillType });

		// fetch corresponding drills and requirements
		Promise.all([
			DrillsService.getWithParams(
				selectedSite?.id,
				selectedSchoolYear?.value,
				selectedDrillType?.uuid || null,
			),
			DrillDistrictRequirementsService.getAllRequirementsByBuilding(
				selectedSite?.id,
				selectedSchoolYear?.value,
			),
		])
			.then(([scheduledDrills, requirements]) => {
				this.setState({ requirements });

				if (Array.isArray(scheduledDrills)) {
					const unscheduledDrills = this.calculateUnscheduledDrills(
						scheduledDrills,
					);

					this.setState({
						scheduledDrills,
						unscheduledDrills,
					});
				}
			})
			.catch(err => {
				console.error('error', err.message);
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text:
						'Could not fetch any drills this time. Please try again.',
				});
				this.setState({
					scheduledDrills: [],
				});
			});
	};

	addDrillToCalendar = item => {
		const { selectedSchoolYear, selectedSite } = this.state;
		DrillLogService.addDrillLogToCalendar(
			item,
			selectedSchoolYear?.value,
			selectedSite?.value,
		).catch(() => {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Could not add to calendar. Please try again.',
			});
		});
	};

	addAllToCalendar = () => {
		const {
			scheduledDrills,
			selectedSchoolYear,
			selectedSite,
		} = this.state;
		const scheduledDrillsAdded = scheduledDrills.filter(
			drillStatus => drillStatus.status === 'scheduled',
		);
		DrillLogService.addAllDrillLogToCalendar(
			scheduledDrillsAdded,
			selectedSchoolYear?.value,
			selectedSite?.value,
		).catch(() => {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Could not add to calendar. Please try again.',
			});
		});
	};

	setShowDeleteModal = (showDeleteModal = false, selectedDrill = null) =>
		this.setState({ showDeleteModal, selectedDrill });

	// specific drill functions
	addAnotherDrill = () => {
		this.setState(prevState => ({
			unscheduledDrills: prevState?.unscheduledDrills.concat({
				id: (Math.random() + 1).toString(36).substring(2),
				date: null,
				drillType: null,
				unscheduled: true,
			}),
		}));
	};

	updateUnscheduledDrill = (id, path, value) => {
		this.setState(prevState => ({
			unscheduledDrills: prevState?.unscheduledDrills.map(item => ({
				...item,
				...(item.id === id ? { [path]: value } : {}),
			})),
		}));
	};

	editScheduledDrill = (index, scheduleId, date) => {
		const { updatedScheduledDrills } = this.state;
		updatedScheduledDrills[index] = {
			scheduleId,
			date,
		};
		this.setState({ updatedScheduledDrills });
	};

	deleteDrill = () => {
		const { selectedDrill } = this.state;

		// remove unscheduled from state
		if (selectedDrill?.unscheduled) {
			return this.setState(prevState => ({
				unscheduledDrills: prevState?.unscheduledDrills.filter(
					({ id }) => id !== selectedDrill?.id,
				),
				selectedDrill: null,
				showDeleteModal: false,
			}));
		}

		// remove scheduled from state and DB
		DrillsService.deleteScheduledDrill(selectedDrill?.scheduleId)
			.then(() => {
				Swal.fire({
					icon: 'success',
					title: 'Success!',
					text: 'The drill has been deleted',
				});

				this.setState(prevState => ({
					scheduledDrills: prevState?.scheduledDrills.filter(
						({ scheduleId }) =>
							scheduleId !== selectedDrill?.scheduleId,
					),
					selectedDrill: null,
					showDeleteModal: false,
				}));
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Could not delete this drill. Please try again',
				});
			});
	};

	saveSchedule = () => {
		const {
			selectedSite,
			selectedSchoolYear,
			scheduledDrills,
			updatedScheduledDrills,
			unscheduledDrills,
			selectedDrillType,
		} = this.state;

		const addUnscheduledDrills = unscheduledDrills.filter(
			item => item.drillType !== null && item.date !== null,
		);

		// update and/or create drills
		DrillsService.updateAndCreateDrills(
			selectedSite?.id,
			selectedSchoolYear?.value,
			updatedScheduledDrills?.filter(
				({ scheduleId }) => !!scheduleId,
			),
			addUnscheduledDrills,
		)
		.then(({ newDrills, updatedDrills }) => {
			Swal.fire({
				icon: 'success',
				title: 'Your changes have been saved!',
				text:
					'Some of your new drills may not appear in your table because you can only see drills which have the currently selected drill type',
			}).then(() => {
				this.setDrillType();
			});

			// filter drills by selected drill type
			const drillsToAdd = newDrills?.filter(
				({ drillType }) =>
					selectedDrillType.value === 'All drill types' ||
					drillType?.uuid === selectedDrillType.uuid,
			);

			const setScheduledDrills = scheduledDrills
				?.map((item, index) => {
					// replace the drill with the new one from the backend
					if (updatedScheduledDrills[index]) {
						return updatedDrills?.find(
							({ scheduleId }) =>
								scheduleId === item.scheduleId,
						);
					}

					return item;
				})
				.concat(drillsToAdd);

			const setUnscheduledDrills = this.calculateUnscheduledDrills(
				setScheduledDrills,
			);

			this.setState({
				invalidDrillsError: false,
				scheduledDrills: setScheduledDrills,
				updatedScheduledDrills: [],
				unscheduledDrills: setUnscheduledDrills,
			});
		})
		.catch(() => {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Could not save the schedule. Please try again.',
			});
		});
	};

	selectSite = site => {
		const {
			route: { permissions },
		} = this.props;

		const canScheduleDrill = checkPermission(
			'drilllogs_schedule',
			permissions,
			site.id,
		);

		this.setState({
			...BASE_VIEW,
			selectedSite: site,
			canEditDrills: canScheduleDrill,
		});
	};

	calculateTypeCounts = drills => {
		const typeCounts = {};

		drills.forEach(drill => {
			if (!typeCounts[(drill.drillType?.uuid)]) {
				typeCounts[(drill.drillType?.uuid)] = 0;
			}

			typeCounts[(drill.drillType?.uuid)] += 1;
		});

		return typeCounts;
	};

	calculateUnscheduledDrills = drills => {
		const typeCounts = this.calculateTypeCounts(drills);
		const unscheduledDrills = [];
		const { requirements, selectedDrillType } = this.state;

		requirements.forEach(requirement => {
			if (
				selectedDrillType?.uuid &&
				selectedDrillType?.uuid !== requirement.uuid
			) {
				return;
			}

			let count = requirement.requirement;
			if (typeCounts[requirement.uuid]) {
				count -= typeCounts[requirement.uuid];
			}

			if (count > 0) {
				for (let i = 0; i < count; i += 1) {
					unscheduledDrills.push({
						id: (Math.random() + 1).toString(36).substring(2),
						date: null,
						drillType: {
							label: requirement.name,
							value: requirement.uuid,
						},
						unscheduled: true,
					});
				}
			}
		});

		return unscheduledDrills;
	};

	render() {
		const {
			loading,
			error,
			sites,
			schoolYears,
			drillTypes,
			selectedSite,
			selectedSchoolYear,
			selectedDrillType,
			selectedDrill,
			scheduledDrills,
			unscheduledDrills,
			showDeleteModal,
			invalidDrillsError,
			showCopyDrillsModal,
			canEditDrills,
		} = this.state;

		const { history } = this.props;

		if (loading) return <LoadingSpinner />;

		if (error) return error;

		return (
			<Page>
				{/* site, school year, drill type filters */}
				<div className="dropdown-filters">
					{/* Site */}
					<Dropdown
						label="Site"
						placeholder="Select a site"
						value={selectedSite}
						options={sites}
						onChange={this.selectSite}
					/>

					{selectedSite && (
						/* School year */
						<Dropdown
							label="School year"
							placeholder="Select a school year"
							value={selectedSchoolYear}
							options={schoolYears}
							onChange={item =>
								this.setState({
									selectedSchoolYear: item,
									selectedDrillType: null,
								})
							}
						/>
					)}

					{selectedSite && selectedSchoolYear && (
						/* Drill type */
						<Dropdown
							label="Drill type"
							placeholder="Select a Drill type"
							value={selectedDrillType}
							options={drillTypes}
							onChange={this.setDrillType}
						/>
					)}
				</div>

				{selectedSite && selectedSchoolYear && selectedDrillType && (
					/* instructions block */
					<InstructionsBlock
						contentRows={[
							'One fire drill each month that has 10 or more school days (including summer school), including one drill withing 10 days of the beginning of classes.',
							'One announced drill (during the first two weeks of new school year)',
							'One obstructed drill each semester',
							'One drill with special circumstances (scheduled during lunch, class change time, accountability/reunification issues, functional needs, etc.)',
							'Test evacuation procedures not usually addresses during fire drills (Check with your local fire department about receiving fire drill credit for this drill)',
							<p>
								Source: <span>Drill Recommendations</span>
							</p>,
						]}
					/>
				)}

				{selectedSite && selectedSchoolYear && selectedDrillType && (
					/* table header and table buttons */
					<div className="table-header">
						{/* table header */}
						<h4>Schedule drills</h4>

						{/* schedule drills, manage reminders, add to calendar, copy drill schedule */}
						<div className="drill-buttons-row">
							{canEditDrills && (
								<Button
									onClick={() => {
										history.push({
											pathname: `/manageDrillEmailReminders/${
												selectedSite.id
											}`,
											state: {
												buildingName: selectedSite.name,
											},
										});
									}}
									text="Manage reminders"
									intent="tertiary"
									icon="notifications"
								/>
							)}

							<Button
								onClick={this.addAllToCalendar}
								text="Add all to calendar"
								intent="tertiary"
								icon="calendar"
							/>

							{canEditDrills && (
								<Button
									onClick={() =>
										this.setState({
											showCopyDrillsModal: true,
										})
									}
									text="Copy drill schedule"
									intent="tertiary"
									icon="duplicate"
								/>
							)}
						</div>
					</div>
				)}

				{selectedSite && selectedSchoolYear && selectedDrillType && (
					/* scheduled drills table */
					<Table
						headers={[
							`Scheduled ${selectedDrillType?.label}`,
							'Status',
							'Date',
							'Actions',
						]}
						data={scheduledDrills}
						renderItem={(item, index) => (
							<>
								<td>{item?.drillType?.name}</td>

								<td>
									<StatusPilsner status={item.status} />
								</td>

								<td>
									{item.status === 'completed' ||
									!canEditDrills ? (
										formatDate(item?.scheduledDrillDate)
									) : (
										<DateTimePicker
											inputNoMarginBottom
											initialValue={safeDateToLuxon(
												item?.scheduledDrillDate,
											)}
											timePrecision="minute"
											onDateChangeHandler={value =>
												this.editScheduledDrill(
													index,
													item.scheduleId,
													value,
												)
											}
											error={
												invalidDrillsError && !item.date
											}
											datePickerProps={{
												maxDate: addMonthsToDate(),
											}}
										/>
									)}
								</td>

								<td>
									{canEditDrills && (
										<MoreActionsButton
											actions={[
												{
													id: 'calendar',
													icon: 'calendar',
													title:
														'Add drill to calendar',
													handler: () =>
														this.addDrillToCalendar(
															item,
														),
												},
												{
													id: 'delete',
													icon: 'delete',
													title: 'Delete drill log',
													isDestructive: true,
													isVisible:
														item.status !==
														'completed',
													handler: () =>
														this.setShowDeleteModal(
															true,
															item,
														),
												},
											]}
										/>
									)}
								</td>
							</>
						)}
					/>
				)}

				{selectedSite && selectedSchoolYear && selectedDrillType && (
					/* unscheduled drills table */
					<Table
						headers={['Unscheduled drills', 'Date', 'Actions']}
						data={unscheduledDrills}
						renderItem={item => (
							<>
								<td>
									<Dropdown
										disabled={item.status === 'completed'}
										value={item.drillType}
										options={drillTypes.slice(1)} // remove 'all drill types'
										onChange={value =>
											this.updateUnscheduledDrill(
												item.id,
												'drillType',
												{
													label: value.label,
													value: value.uuid,
												},
											)
										}
										error={invalidDrillsError && !item.date}
										errorMessage="Please enter a value"
									/>
								</td>

								<td>
									<DateTimePicker
										inputNoMarginBottom
										onDateChangeHandler={value =>
											this.updateUnscheduledDrill(
												item.id,
												'date',
												value,
											)
										}
										timePrecision="minute"
										error={invalidDrillsError && !item.date}
										datePickerProps={{
											maxDate: addMonthsToDate(),
										}}
									/>
								</td>

								<td>
									<MoreActionsButton
										actions={[
											{
												id: 'delete',
												icon: 'delete',
												title: 'Delete drill log',
												isDestructive: true,
												handler: () =>
													this.setShowDeleteModal(
														true,
														item,
													),
											},
										]}
									/>
								</td>
							</>
						)}
					/>
				)}

				{selectedSite &&
					selectedSchoolYear &&
					selectedDrillType &&
					canEditDrills && (
						/* add another drills, save schedule */
						<div className="drill-actions-row">
							<Button
								icon="plus"
								intent="secondary"
								text="Add another drill"
								onClick={this.addAnotherDrill}
							/>

							<Button
								text="Save schedule"
								intent="primary"
								onClick={this.saveSchedule}
							/>
						</div>
					)}

				{/* delete drill and its log modal */}
				<CardModal
					isVisible={showDeleteModal}
					setIsVisible={this.setShowDeleteModal}
					title={`Drill ${selectedDrill?.drillType?.name}`}
					actionTitle="Delete drill log"
					onActionClick={this.deleteDrill}
				>
					<p className="modal-text">
						Are you sure you want to delete this drill log? This
						will also delete the scheduled drill.
					</p>
				</CardModal>

				{showCopyDrillsModal && (
					/* copy drills schedule modal */
					<CopyDrillScheduleModal
						setModalToShow={show =>
							this.setState({ showCopyDrillsModal: show })
						}
						selectedSite={selectedSite}
						selectedSchoolYear={selectedSchoolYear}
					/>
				)}
			</Page>
		);
	}
}

ScheduleDrills.propTypes = {
	history: PropTypes.object,
	route: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(ScheduleDrills);
