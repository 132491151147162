import React from "react"
import { NoResultFound } from 'components/NoResultFound';
import { NoResultFoundIcon } from 'assets/icons';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Icon } from '@blueprintjs/core';

const Step2 = (props) => {
  const { updateFormData, highlightedIndex , selectedIndex, agencies, loading} = props;
    return (
        <>
        {loading && <LoadingSpinner />}
        {!loading && (
            <>
                {agencies.length == 0 ? (
                <NoResultFound
                    header='No agencies were found'
                    icon={NoResultFoundIcon}
                >
                <p style={{fontFamily: 'Nunito Sans'}}> Change search criteria
                </p>
                </NoResultFound>
                ) : (
                    <>
                    <p className="font-bold">Select an agency</p>
                    <div className="bootstrap-table-container custom-table custom-scroll first-responder-conratiner">
                        {
                            
                            agencies.map((row, index) => (
                                <a key={index} onClick={(e) =>{
                                    updateFormData('agency', row['name'], index)
                                }}>
                                    
                                    <div className={index === highlightedIndex || selectedIndex === index ? (row['pre_assigned'] ? 'highlighted first-responder-agency-container' : 'highlighted-new first-responder-agency-container') : 'unhighlighted first-responder-agency-container'}>
                                        <div style={{marginTop:'10px'}}>
                                            <span className ={index === highlightedIndex || selectedIndex === index ? (row['pre_assigned'] && row['allowed_sites'].length != 0 ? 'font-main-title first-responder-agency-text' : 'font-main-title-new first-responder-agency-text') : 'font-main-title first-responder-agency-text'}>{row['name']}</span><br></br>
                                            <span className ={index === highlightedIndex || selectedIndex === index ? (row['pre_assigned'] && row['allowed_sites'].length != 0 ? 'font-sub-title first-responder-agency-text' : 'font-sub-title-new first-responder-agency-text') : 'font-sub-title first-responder-agency-text'} >{row['composite_address']}</span><br></br>
                                            <span className ={index === highlightedIndex || selectedIndex === index ? (row['pre_assigned'] && row['allowed_sites'].length != 0 ? 'font-small first-responder-agency-text' : 'font-small-new first-responder-agency-text') : 'font-small first-responder-agency-text'}>{Math.round(row['distance_mi'])} Miles</span><br></br>
                                            
                                            <div style = {{'paddingTop':'5px'}} className ="font-small font-bold">{(row['pre_assigned'] && row['allowed_sites'].length != 0) ?  <> <p className ="TextOverFlow"><Icon icon="small-tick"/> Already using Navigate 360 
                                            {row['allowed_sites_array'].length != 0 ? <> at 
                                                {
                                                    row['allowed_sites_array'].map((row, ind) => (
                                                        (ind != 0 ? ', ' : ' ') + row['label']
                                                    ))
                                                }</> : ''
                                            }
                                                </p></> : ''}  
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            ))
                        }
                    </div>
                    </>
            
                )}
            </>
        )}
        </>
  );
};
export default Step2;