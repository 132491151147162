import React, { useState } from "react";
import {  Position, Popover, Tooltip } from '@blueprintjs/core';

const ContactTooltip = ({ contact }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="contact-card-parent">
            <Popover  position={Position.RIGHT}
                enforceFocus={false}
                className=''
                modifiers={{
                    arcontact: { 
                        enabled:false,
                    }
                }}>
            
                <Tooltip 
                    className="input-tooltip"
                    content={
                        <>
                            <p className='tooltip-text'> User already in this call list.</p>
                        </> 
                    }   
                    position='right'
                >
                    <span>{contact}</span>
                </Tooltip>
            </Popover>
        </div>
    );
}

export default ContactTooltip;