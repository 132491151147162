import React, { useState, useEffect, useContext } from 'react';
import NormalModal from '../../../components/Modals/NormalModal';
import MultiStepHeaderModal from '../../../components/Modals/MultiStepHeaderModal';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import BulkUsersStepsHandler from './BulkUsersStepsHandler';
import { UsersService } from 'services/UsersService';
import { ToasterContext } from '../../App';
import { Intent } from '@blueprintjs/core';
import atachmentImg from 'images/attachment.svg';

const BulkUsersModal = ({ onCloseModal, fetchUsers }) => {
	const [confirmBtnTitle, setConfirmBtnTitle] = useState('Execute');
	const [disableBtns, setDisableBtns] = useState(true);
	const [changesOccuredFlag, setChangesOccuredFlag] = useState(0);
	const [showConfirmPopModal, setShowConfirmPopModal] = useState(false);
	const [barsColors, setbarsColors] = useState(['#9F1C3A', '#D8E1E8']);
	const [barsTitles, setBarsTitles] = useState('Step 1 - Import Excel file');
	const [currentStep, setCurrentStep] = useState(1);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState();
	const [validationResData, setValidationRes] = useState();
	const [validUsers, setValidUsers] = useState([]);
	const context = useContext(ToasterContext);
	const [uploadText, setUploadText] = useState('Upload File');
	const [xslContentClass, setxslContentClass] = useState('');
	const [xslContent, setXslContent] = useState('');
	const [xslImg, setXslImg] = useState(atachmentImg);
	const [backBtn, setBackBtn] = useState(false);
	const [uploadLabel, setUploadLabel] = useState('Import a file');

	const closeConfirmPopUpModal = () => {
		setShowConfirmPopModal(false);
	};

	const handleSubmit = event => {
		UsersService.bulkAddUsers(validUsers)
			.then(resp => {
				if (resp.statusCode == 201) {
					context('Users successfully created', Intent.SUCCESS);
					fetchUsers();
				} else {
					context('Users cannot be created', Intent.DANGER);
				}
				onCloseModal();
			})
			.catch(() => {
				context('Something went wrong!', Intent.DANGER);
				setLoading(false);
				onCloseModal();
			});
	};

	const cancelBtnClickHandler = () => {
		if (changesOccuredFlag == 1) {
			setShowConfirmPopModal(true);
		} else {
			onCloseModal();
		}
	};

	const next = () => {
		setLoading(true);
		setUploadLabel('Loading...');
		UsersService.validateBulkUsersFile(formData)
			.then(resp => {
				if (
					resp.data.invalid_headers == undefined &&
					resp.data.invalid_users_count == 0 &&
					resp.data.valid_users_count == 0
				) {
					context('Excel file is empty!', Intent.DANGER);
					setCurrentStep(1);
				} else {
					setValidationRes(resp.data);
					if (
						resp.data.valid_users_count != undefined &&
						resp.data.valid_users_count > 0
					) {
						setValidUsers(resp.data.valid_users);
						setDisableBtns(false);
					} else {
						setDisableBtns(true);
					}
					setBackBtn(true);
					setCurrentStep(currentStep + 1);
				}
				setLoading(false);
				setUploadLabel('Import a file');
			})
			.catch(() => {
				context('Something went wrong!', Intent.DANGER);
				setLoading(false);
				setCurrentStep(1);
			});
	};
	const back = () => {
		setUploadLabel('Import a file');
		setBackBtn(false);
		setDisableBtns(false);
		setCurrentStep(currentStep - 1);
	};
	const confirmAction = () => {
		switch (currentStep) {
			case 1:
				return next();
			case 2:
				setBackBtn(true);
				return handleSubmit();
		}
	};

	useEffect(() => {
		switch (currentStep) {
			case 1:
				setConfirmBtnTitle('Execute');
				setbarsColors(['#007E8F', '#D8E1E8']);
				setBarsTitles('Step 1 - Import Excel file');
				break;
			case 2:
				setConfirmBtnTitle('Import');
				setbarsColors(['#007E8F', '#007E8F']);
				setBarsTitles('Step 2 - File Validation');
				break;
		}
	}, [currentStep]);
	return (
		<>
			<NormalModal
				setModalToShow="BulkUsersModal"
				onConfirmAction={confirmAction}
				onCancelAction={cancelBtnClickHandler}
				header="Add Multiple Users"
				confirmBtnTitle={confirmBtnTitle}
				cancelBtnTitle="Cancel"
				confirmBtnDisabled={disableBtns}
				showBackArrow={backBtn}
				backArrowAction={back}
				onCloseAction={cancelBtnClickHandler}
			>
				<MultiStepHeaderModal bars={barsColors} headerTitle={barsTitles} />
				<BulkUsersStepsHandler
					next={next}
					currentStep={currentStep}
					setDisableBtns={setDisableBtns}
					back={back}
					setChangesOccuredFlag={setChangesOccuredFlag}
					loading={loading}
					setLoading={setLoading}
					setFormData={setFormData}
					validationResData={validationResData}
					uploadText={uploadText}
					setUploadText={setUploadText}
					xslContentClass={xslContentClass}
					setxslContentClass={setxslContentClass}
					setXslContent={setXslContent}
					xslImg={xslImg}
					setXslImg={setXslImg}
					xslContent={xslContent}
					uploadLabel={uploadLabel}
					setUploadLabel={setUploadLabel}
				/>
			</NormalModal>
			{showConfirmPopModal && (
				<UnsavedChangesModal
					onConfirmAction={onCloseModal}
					onCancelAction={closeConfirmPopUpModal}
					confirmBtnTitle="Continue"
					backBtnTitle="Cancel"
					header="Unsaved changes"
					message="The changes you have made have not been saved. Do you want to continue?"
				/>
			)}
		</>
	);
};

export default BulkUsersModal;
