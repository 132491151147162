import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce, isEmpty } from 'lodash';
import { Input } from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import './css/index.scss';

const SearchBar = ({
	label,
	placeholder,
	onSearch,
	style,
	onFocus,
	value = "",
	className = "",
	icon = 'search'
}) => {
	const [searchValue, setSearchValue] = useState(value);

	const clearSearch = () => {
		setSearchValue("");
		onSearch("")
	}

	const onSearchHandler = (value) => {
		onSearch(value);
	}

	const debouncedOnChange = useCallback(debounce(onSearchHandler, 500), []);

	const handleChange = (e) => {
		const value = e.target.value;
		setSearchValue(value);
		debouncedOnChange(value);
	};
	
	const rightElement = (
		<>
			{!isEmpty(searchValue) &&
				<Icon size={18} className='cross-icon' icon="cross" onClick={clearSearch} />
			}
		</>
	)

	return	(
		<Input
			placeholder={placeholder || 'Search'} 
			onFocus={onFocus}
			label={label}
			className={"SearchBar_Wrapper " + className}
			icon={icon} 
			onChange={handleChange}
			value={searchValue}
			rightElement={rightElement}
			style={style}
		/>	
	)
};

export { SearchBar };

SearchBar.propTypes = {
	placeholder: PropTypes.string,
	onSearch: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	style: PropTypes.object,
	value: PropTypes.string
};
