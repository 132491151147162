import React, { useState, useRef, useEffect } from 'react';
import { HexColorPicker } from "react-colorful";
import { Input } from '../../';
import { Color } from './Color';
import PropTypes from 'prop-types';
import '../css/ColorPicker.scss';

export const ColorPicker = ({
    selectedColor = '#ffffff',
    setSelectedColor,
    isSelected,
    singleColor = false,
}) => {
    const hexColor = selectedColor === null ? '#ffffff' : selectedColor;
    const [RgbColor, setRgbColor] = useState({
        'R' : 255,
        'G' : 255,
        'B' : 255,
    });
    const colorPickerRef = useRef();
    const [toggleColorPicker, setToggleColorPicker] = useState(false);

    const handleColorChange = (newColor) => {
        hexToRgb(newColor);
        setSelectedColor(newColor);
    };

    const hexToRgb = (hex) => {
        // Remove the "#" character if present
        hex = hex.replace('#', '');
      
        // Extract the red, green, and blue components
        const red = parseInt(hex.substring(0, 2), 16);
        const green = parseInt(hex.substring(2, 4), 16);
        const blue = parseInt(hex.substring(4, 6), 16);

        setRgbColor({
            'R': red,
            'G': green,
            'B': blue
        })
    }

    useEffect(() => {
        const handleClickOutside = event => {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setToggleColorPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [colorPickerRef]);


    return (
            <Color
                key='color-picker' 
                colorClass={`color-mix ${toggleColorPicker === false && singleColor ? "color-border-none" : ""}`}
                isSelected={isSelected}
                onClick={() => setToggleColorPicker(true)}
                style={{
                    borderColor: isSelected ? singleColor ? `rgba(255, 255, 255, 0.8)` : hexColor : undefined,
                    background: isSelected && singleColor ? hexColor : "",
                }}
                singleColor={singleColor}
            >
                {toggleColorPicker && (
                    <div className='color-picker-wrapper'  ref={colorPickerRef}>
                        <HexColorPicker 
                            color={hexColor} 
                            onChange={handleColorChange}
                        />
                        <section className='input-section'>
                            <Input
                                label="Hex"
                                type="text"
                                value={hexColor}
                                className="hex-input"
                                onChange={e => handleColorChange(e.target.value)}
                                maxLength={7}
                                labelState={false}
                            />
                            <Input
                                label="R"
                                value={RgbColor?.R}
                                type="number"
                                maxLength={3}
                                readOnly={true}
                                labelState={false}
                                width={'55px'}
                            />
                            <Input
                                label="G"
                                value={RgbColor?.G}
                                type="number"
                                maxLength={3}
                                readOnly={true}
                                labelState={false}
                                width={'55px'}
                            />
                            <Input
                                label="B"
                                value={RgbColor?.B}
                                type="number"
                                maxLength={3}
                                readOnly={true}
                                labelState={false}
                                width={'55px'}
                            />
                        </section>
                    </div>
                )}
            </Color>
    );
};

ColorPicker.propTypes = {	
	selectedColor: PropTypes.string,
	setSelectedColor: PropTypes.func,
    isSelected: PropTypes.bool.isRequired,
    singleColor: PropTypes.bool,
}