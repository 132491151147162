import React from 'react';
import { RadioButtonGroup } from 'componentsV2';
import { Radio } from '@blueprintjs/core';
import PropTypes from 'prop-types';

export const AlarmContact911 = ({ formData, formErrors, onChangeFormData, disabled = false }) => {
	const onToggle911Handler = e => {
		const value = JSON.parse(e.target.value);
		onChangeFormData({ type: 'contact911', payload: value });
		if (!value) {
			onChangeFormData({ type: 'alarmTypes911', payload: null });
		}
	};
	const selectedValue = formData.contact911 !== null ? formData.contact911 : null;

	return (
		<RadioButtonGroup
			label={null}
			onChange={onToggle911Handler}
			selectedValue={selectedValue}
			size="small"
			bordered={false}
			required={true}
			layout="block"
			error={formErrors?.contact911}
			disabled={disabled}
		>
			<Radio
				label={`Yes${!formData._911Active ? ', prompt to call 911' : ''}`}
				value={true}
			/>
			<Radio
				label={`No${!formData._911Active ? ", don't prompt to call 911" : ''}`}
				value={false}
			/>
		</RadioButtonGroup>
	);
};

AlarmContact911.propTypes = {
	formData: PropTypes.object,
	formErrors: PropTypes.object,
	onChangeFormData: PropTypes.func,
	disabled: PropTypes.bool,
};
