import { HttpService } from 'networking/HttpService';

const GENERIC_ERROR = new Error('An error occurred, please try again');

export const DrillsScenariosService = {
	async createDrillScenario(
		title,
		category,
		building = null,
		notes = null,
		content = null,
		file = null,
		fileType = null,
		fileName = null,
		fileSize = null,
	) {
		try {
			return await HttpService({
				method: 'POST',
				route: `/drills/scenario/create`,
				body: {
					scenario_title: title,
					scenario_team_id: building,
					scenario_drill_scenarios_categories_id: category,
					scenario_notes: notes,
					scenario_content: content,
					file,
					fileType,
					fileName,
					fileSize,
				},
			});
		} catch (error) {
			throw GENERIC_ERROR;
		}
	},

	async updateDrillScenario(
		scenarioId,
		title,
		category,
		building = null,
		notes = null,
		content = null,
		file = null,
		fileType = null,
		fileName = null,
		fileSize = null,
	) {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/drills/scenario/edit/${scenarioId}`,
				body: {
					scenario_title: title,
					scenario_team_id: building,
					scenario_drill_scenarios_categories_id: category,
					scenario_notes: notes,
					scenario_content: content,
					file,
					fileType,
					fileName,
					fileSize,
				},
			});
		} catch (error) {
			throw GENERIC_ERROR;
		}
	},

	async getById(id) {
		return await HttpService({
			method: 'GET',
			route: `/drills/scenario/${id}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	async getAll(filterationData) {
		// Separate array values because URLSearchParams.toString() doesn't handle arrays
		const { category, buildingIds, site_id, ...otherFilters } = filterationData;

		let objString = `?${new URLSearchParams(otherFilters).toString()}`;

		// Format array query params
		if (category.value === 'all') objString += '&category=all';
		else objString += category.value;

		if (buildingIds) objString += buildingIds;
		else objString += '&site_id=all';

		const route = `/drills/scenario${objString}`;
		const response = await HttpService({
			method: 'GET',
			route,
		});

		return response;
	},
};
