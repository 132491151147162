import { HttpService } from 'networking/HttpService';

export const RapidAlarmService = {
	getActiveAlarms: async (params = {}) => {
		let query = '';
		if (params.search) {
			query += `&search=${params.search}`;
		}
		if (params.buildingIds && params.buildingIds.length > 0) {
			params.buildingIds.forEach(buildingId => {
				query += `&buildingIds[]=${buildingId}`;
			});
		}
		if (params.startDate) {
			query += `&startDate=${params.startDate}`;
		}
		if (params.endDate) {
			query += `&endDate=${params.endDate}`;
		}
		if (params.alarmModes && params.alarmModes.length > 0) {
			params.alarmModes.forEach(alarmMode => {
				query += `&modes[]=${alarmMode}`;
			});
		}
		if (params.alarmTypes && params.alarmTypes.length > 0) {
			params.alarmTypes.forEach(alarmType => {
				query += `&typeIds[]=${alarmType}`;
			});
		}
		if (params.triggeredBy911) {
			query += `&triggeredBy911=${params.triggeredBy911}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/alarms?${query}`,
		}).catch(err => {
			throw err;
		});
	},

	getAllPastAlarm: async (params = {}) => {
		let query = '';
		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}
		if (params.search) {
			query += `&title=${params.search}`;
		}
		if (params.buildingIds && params.buildingIds.length > 0) {
			params.buildingIds.forEach(buildingId => {
				query += `&buildingIds[]=${buildingId}`;
			});
		}
		if (params.startDate) {
			query += `&startDate=${params.startDate}`;
		}
		if (params.endDate) {
			query += `&endDate=${params.endDate}`;
		}
		if (params.modes && params.modes.length > 0) {
			params.modes.forEach(alarmMode => {
				query += `&modes[]=${alarmMode}`;
			});
		}
		if (params.alarmTypesIds && params.alarmTypesIds.length > 0) {
			params.alarmTypesIds.forEach(alarmTypeId => {
				query += `&alarmTypesIds[]=${alarmTypeId}`;
			});
		}
		if (params.is_911Cellular) {
			query += `&is_911Cellular=${params.is_911Cellular}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/alarms/past?${query}`,
		}).catch(err => {
			throw err;
		});
	},

	getAlarmTypes: async (params = {}) => {
		let query = '';

		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}
		if (params.disabled) {
			query += `&disabled=${params.disabled}`;
		}
		if (params.isGeneric) {
			query += `&isGeneric=${params.isGeneric}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/alarms/types?${query}`,
		}).catch(err => {
			throw err;
		});
	},

	getAlarmSites: async (params = {}) => {
		return await HttpService({
			method: 'GET',
			route: `/respond/buildings`,
		}).catch(err => {
			throw err;
		});
	},

	activateAlarm: async body => {
		return await HttpService({
			method: 'POST',
			route: `/alarms/add-bulk`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	createStaffNote: async (note, alarmsIds) => {
		let loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
		return await HttpService({
			method: 'POST',
			route: `/persons/${loggedUser.person.personId}/bulk-notes`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				alarmIds: alarmsIds,
				text: note,
			},
		}).catch(err => {
			throw err;
		});
	},

	updateStaffStatus: async (status, alarmsIds, staffId = 0) => {
		let id = staffId;
		if (!staffId) {
			let loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
			id = loggedUser.id;
		}
		return await HttpService({
			method: 'PUT',
			route: `/alarms/bulk-staff-status/${id}`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				status: status,
				alarmIds: alarmsIds,
			},
		}).catch(err => {
			throw err;
		});
	},

	getPastAlarm: async alarmId => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/past/${alarmId}`,
			});
		} catch (error) {
			throw error;
		}
	},

	downloadPastAlarm: async (id, options) => {
		let query = '';
		if (options.with_rollCall) {
			query += `&with_rollCall=${options.with_rollCall}`;
		}
		if (options.with_reunification) {
			query += `&with_reunification=${options.with_reunification}`;
		}
		if (options.with_chatHistory) {
			query += `&with_chatHistory=${options.with_chatHistory}`;
		}
		if (options.with_submittedClasses) {
			query += `&with_submittedClasses=${options.with_submittedClasses}`;
		}

		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/past/${id}/download?${query}`,
			});
		} catch (error) {
			throw error;
		}
	},

	getPastAlarmHistory: async alarmId => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/past/${alarmId}/history`,
			});
		} catch (error) {
			throw error;
		}
	},

	toggleAlarmTypePriority: async typeId => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/alarms/types/${typeId}/toggle-priority`,
			});
		} catch (error) {
			throw error;
		}
	},

	deleteAlarmType: async typeId => {
		try {
			return await HttpService({
				method: 'DELETE',
				route: `/alarms/types/${typeId}`,
			});
		} catch (error) {
			throw error;
		}
	},
	activateRollCall: async activeAlarmId => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/alarms/${activeAlarmId}/roll-call/activate`,
			});
		} catch (error) {
			throw error;
		}
	},
	endRollCall: async activeAlarmId => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/alarms/${activeAlarmId}/roll-call/end`,
			});
		} catch (error) {
			throw error;
		}
	},

	createAlarmType: async body => {
		return await HttpService({
			route: `/alarms/types/add-alarm-type`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	getAlarmTypeDetails: async alarmTypeId => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/types/${alarmTypeId}/view`,
			});
		} catch (error) {
			throw error;
		}
	},

	updateAlarmType: async (alarmTypeId, body) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/alarms/types/${alarmTypeId}/edit`,
				body: body,
			});
		} catch (error) {
			throw error;
		}
	},

	toggleEnableDisableAlarmType: async (alarmTypeId, disabled) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/alarms/types/${alarmTypeId}/enable-disable`,
				body: {
					disabled,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	getAlarmDetails: async alarmId => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/${alarmId}`,
			});
		} catch (error) {
			throw error;
		}
	},
	endAlarm: async (alarmId, pinCode, isReunification = false) => {
		let body;
		if (pinCode !== null) {
			body = {
				user_pin: pinCode,
			};
		} else {
			body = {};
		}

		if (isReunification) {
			body.is_reunification = isReunification;
		}

		try {
			return await HttpService({
				method: 'PUT',
				route: `/alarms/${alarmId}/end`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: body,
			});
		} catch (error) {
			throw error;
		}
	},
	getRollCallMyClasses: async params => {
		let query = '?includeCustom=1';
		query += `&includeAllClasses=${params.includeAllClasses ? '1' : '0'}`;
		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.rosterId) {
			query += `&rosterId=${params.rosterId}`;
		}
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/${params.alarmId}/users/myself/rosters${query}`,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			throw error;
		}
	},
	getStudentsByClass: async (params = {}) => {
		let query = `?alarmId=${params.alarmId}`;
		if (params.classId) {
			query += `&rosterId=${params.classId}`;
		}
		if (params.name && params.name.length > 0) {
			query += `&studentName=${params.name}`;
		}
		if (params.statusSelected && params.statusSelected.length > 0) {
			params.statusSelected.forEach(status => {
				query += `&respondStatuses[]=${status}`;
			});
		}
		if (params.grades && params.grades.length > 0) {
			params.grades.forEach(grade => {
				query += `&grades[]=${grade}`;
			});
		}
		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/students${query}`,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			throw error;
		}
	},

	getStudentsGrades: async () => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/grades`,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			throw error;
		}
	},

	activateReunification: async (alarmId, location) => {
		let body = location ? { reunification_location: location } : {};

		return await HttpService({
			route: `/alarms/${alarmId}/reunification/activate`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	updateStudentsStatus: async (alarmId, body) => {
		return await HttpService({
			route: `/alarms/${alarmId}/students/status`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	transferToReunifyStudent: async (alarmId, studentId, transferStatus) => {
		return await HttpService({
			route: `/alarms/reunification/transfer`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				alarm_id: alarmId,
				student_id: studentId,
				transfer_to_reunification: transferStatus,
			},
		}).catch(err => {
			throw err;
		});
	},

	submitClass: async (requestBody, classId, alarmId) => {
		return await HttpService({
			route: `/alarms/${alarmId}/rosters/${classId}/submit`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: requestBody,
		}).catch(err => {
			throw err;
		});
	},

	studentAddNote: async (studentId, body) => {
		return await HttpService({
			route: `/students/${studentId}/notes`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	getStudentHistory: async (alarmId, studentId) => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/${alarmId}/students/${studentId}/status-history`,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			throw error;
		}
	},

	getStaffHistory: async (alarmId, staffId) => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/${alarmId}/staff/${staffId}/status-history`,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			throw error;
		}
	},

	getVisitorHistory: async (alarmId, visitorId) => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/${alarmId}/visitors/${visitorId}/status-history`,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			throw error;
		}
	},

	personAddNote: async (personId, body) => {
		return await HttpService({
			route: `/persons/${personId}/notes`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	visitorAddNote: async (alarmId, visitorId, body) => {
		return await HttpService({
			route: `/alarms/${alarmId}/visitors/${visitorId}/notes`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		}).catch(err => {
			throw err;
		});
	},

	getStaff: async (params = {}) => {
		let query = `?`;
		if (params.name && params.name.length > 0) {
			query += `&staffName=${params.name}`;
		}
		if (params.statusSelected && params.statusSelected.length > 0) {
			params.statusSelected.forEach(({ value }) => {
				query += `&statuses[]=${value}`;
			});
		}
		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/${params.alarmId}/non-teachers${query}`,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			throw error;
		}
	},

	getVisitors: async (params = {}) => {
		let query = `?`;
		if (params.name && params.name.length > 0) {
			query += `&visitorName=${params.name}`;
		}
		if (params.statusSelected && params.statusSelected.length > 0) {
			params.statusSelected.forEach(({ value }) => {
				query += `&respondStatuses[]=${value}`;
			});
		}
		if (params.page) {
			query += `&page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}
		try {
			return await HttpService({
				method: 'GET',
				route: `/alarms/${params.alarmId}/visitors${query}`,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			throw error;
		}
	},

	updateVisitorStatus: async (alarmId, visitorId, status) => {
		return await HttpService({
			route: `/alarms/${alarmId}/visitors/${visitorId}`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				status,
			},
		}).catch(err => {
			throw err;
		});
	},
};
