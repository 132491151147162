/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
	Button,
	Icon,
	Callout,
	Menu,
	MenuItem,
	Popover,
	Position,
} from '@blueprintjs/core';
import { Title } from 'components/Title';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { backArrow } from 'assets/icons';
import Swal from 'sweetalert2';
import { HttpService } from 'networking/HttpService';
import Sidebar from './Sidebar';
import Section from './Section';
import AddSectionModal from './addsectionmodal';
import CopySectionModal from './copysectionmodal';
import EditSectionModal from './editsectionmodal';
import AddSummaryModal from './addsummarymodal';
import EditDetails from './editDetails';

const HeaderButton = styled(Button)`
	.bp3-intent-danger {
		outline: #f00;
	}
	.bp3-outlined {
	}
`;


const PageBody = styled.div`
	flex-wrap: wrap;
`;

const BackBtn = styled.div`
	width: 5%;
	display: flex;
	color: #5c7080;
	cursor: pointer;
	font-size: 14px;
	transition: 0.1s;
	margin-left: 11px;
	line-height: 20px;
	font-style: normal;
	border-radius: 8px;
	font-weight: normal;
	align-items: center;
	font-family: Nunito Sans;
	:hover {
		background-color: white;
	}

	img {
		margin-right: 9px;
	}
`;

const Subtitle = styled.div`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
`;

const AddSectionText = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 15%;
`;

const AddSectionLink = styled.button`
	border: none;
	background: none;
	margin: 0;
	color: #9f1c3a;
	border-radius: 6px;
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	text-decoration: underline;
`;

const AddSectonSubtext = styled.div`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	letter-spacing: 0.3px;
	color: #5c7080;
	margin-top: 2px;
`;

const Wrapper = styled.div`
	flex-direction: row;
	display: flex;
`;

const ButtonMenu = ({ openAdd, openSummary, deleteTemplate, openEditDetail }) => (
	<Menu>
		<MenuItem text="Add a section" onClick={openAdd} />
		<MenuItem text="Add summary" onClick={openSummary} />
		<MenuItem text="Edit details" onClick={openEditDetail} />
		<MenuItem
			style={{ color: '#DB3737' }}
			text="Delete template"
			onClick={deleteTemplate}
		/>
	</Menu>
);

const MenuButton = ({ openAdd, openSummary, deleteTemplate, openEditDetail }) => (
	<Popover
		content={
			<ButtonMenu
				openAdd={openAdd}
				openSummary={openSummary}
				deleteTemplate={deleteTemplate}
				openEditDetail={openEditDetail}
			/>
		}
		position={Position.BOTTOM}
	>
		<Button
			icon={
				<Icon
					style={{
						color: '#9F1C3A',
					}}
					icon="cog"
				/>
			}
			minimal
			style={{
				marginTop: 3,
				marginRight: 34,
				fontWeight: 300,
				fontSize: 15,
				color: '#9F1C3A',
				backfaceVisibility: 'transparent',
			}}
			text="Options"
		/>
	</Popover>
);

class EditAssessTemplate extends React.Component {
	state = {
		primaryBuildingId: null,
		loading: true,
		name: '',
		sections: [],
		addSectionModal: false,
		editAssessment: false,
		copySectionModal: false,
		copySectionId: null,
		editSectionModal: false,
		editSectionTarget: null,
		addSummaryModal: false,
		notes: '',
	};

	getData = () => {
		const {
			match: {
				params: { id },
			},
		} = this.props;
		this.setState({
			loading: true,
			addSectionModal: false,
			editAssessment: false,
			copySectionModal: false,
			copySectionId: null,
			editSectionModal: false,
			editSectionTarget: null,
			addSummaryModal: false,
		});
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/templates/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					const {
						data: {
							state,
							name,
							templateSections,
							published,
							summary,
							year,
						},
					} = resp;
					return this.setState({
						name,
						state,
						sections: templateSections,
						templateId: id,
						loading: false,
						published,
						summary,
						year,
					});
				}
				return null;
			});
	};

	checkUser = () => {
		HttpService({
			method: 'GET',
			route: '/users/myself',
		}).then(res => {
			if (res.statusCode === 200) {
				return this.setState({
					primaryBuildingId: res.data.primaryBuildingId,
					loading: false,
				});
			}
			return null;
		});
	};

	componentDidMount = () => {
		this.checkUser();
		this.getData();
	};

	handleRadioChange = () =>
		this.setState({
			// eslint-disable-next-line no-constant-condition
			// eslint-disable-next-line no-nested-ternary
			radioChange: 'one' ? 'two' : false && 'two' ? 'one' : false,
		});

	goBack = () => {
		// eslint-disable-next-line react/destructuring-assignment
		this.props.history.location.state = {currentPanel:'templates'}
		this.props.history.goBack();
	};

	addSections = () => {
		const { sections } = this.state;
		let lastId;
		if (sections.length > 0) {
			lastId = sections[sections.length - 1].id + 1;
		} else {
			lastId = 1;
		}
		sections.push({
			id: lastId,
			title: 'Section title',
			directions: 'Lorem ipsum dolor sit amet,',
			questions: [],
		});
		return this.setState({ sections });
	};

	addQuestionToSection = (question, index) => {
		const { sections } = this.state;
		sections[index].questions.push(question);
		return this.setState({ sections });
	};

	updateSectionQuestion = (question, index, questionIndex) => {
		const { sections } = this.state;
		sections[index].questions[questionIndex] = question;
		return this.setState({ sections });
	};

	deleteQuestion = (sectionIndex, index) => {
		const { sections } = this.state;
		sections[sectionIndex].questions.splice(index, 1);
		return this.setState({ sections });
	};

	closeAdd = () => this.setState({ addSectionModal: false });

	openAdd = () => this.setState({ addSectionModal: true });

	openEditDetail = () => this.setState({ editAssessment : true })

	closeEditDetail = () => this.setState({ editAssessment : false})

	openCopy = id =>
		this.setState({ copySectionModal: true, copySectionId: id });

	closeCopy = () =>
		this.setState({ copySectionModal: false, copySectionId: null });

	openEdit = item =>
		this.setState({ editSectionModal: true, editSectionTarget: item });

	closeEdit = () =>
		this.setState({ editSectionModal: false, editSectionTarget: null });

	closeSummary = () => this.setState({ addSummaryModal: false });

	openSummary = () => this.setState({ addSummaryModal: true });

	publishTemplate = () => {
		const { published } = this.state;
		Swal.fire({
			title: `Are you sure you want to ${
				published ? 'Unpulsih' : 'Publish'
			} this template?`,
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: `${published ? 'Unpublish' : 'Publish'}`,
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				const { templateId } = this.state;
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				return fetch(`${process.env.API_URL}/templates/${templateId}`, {
					method: 'PUT',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						publish: !published,
					}),
				})
					.then(res => res.json())
					.then(resp => {
						if (resp.statusCode === 200) {
							return this.getData();
						}
						return null;
					});
			}
			return Swal.close();
		});
	};

	deleteTemplate = () => {
		Swal.fire({
			title: 'Remove template?',
			// title: 'Delete the template?',
			text: 'Are you sure you want to remove this template?',
			// text: 'This action can not be undone',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Remove',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				const { history } = this.props;
				const { templateId } = this.state;
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				fetch(`${process.env.API_URL}/templates/${templateId}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'app/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						active: false,
					}),
				})
					.then(res => res.json())
					.then(resp => {
						if (resp.statusCode === 200) {
							Swal.fire({
								title: 'Done!',
								icon: 'success',
								timer: 1000,
								showConfirmButton: false,
							}).then(() => history.replace('/assess'));
						}
						return null;
					});
			}
			return Swal.close();
		});
	};

	// Edit Details 
	onSubmitEditDetail = (data) => {
		const {
			match: {
				params: { id },
			},
		} = this.props;
		const { name, state, year, notes } = data;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(`${process.env.API_URL}/templates/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				name,
				state,
				year,
				notes,
			}),
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					Swal.fire({
						title: 'Done!',
						icon: 'success',
						timer: 1000,
						showConfirmButton: false,
					}).then(() => this.getData());
				}
				return this.setState({
					editAssessment: false,
					editTemplate: {},
				});
			});
		
	};

	render() {
		const {
			loading,
			sections,
			addSectionModal,
			editAssessment,
			templateId,
			copySectionModal,
			copySectionId,
			editSectionModal,
			editSectionTarget,
			addSummaryModal,
			published,
			name,
			state,
			summary,
			year,
			notes,
		} = this.state;

		const editTemplate = {
				name: name, state: state, year: year, 
				notes: notes, 
			}
		if (loading) return <LoadingSpinner />;
		return (
			<Wrapper>
				{addSectionModal && (
					<AddSectionModal
						closeAdd={this.closeAdd}
						getData={this.getData}
						templateId={templateId}
					/>
				)}

				{editAssessment && (
					<EditDetails
						closeEdit={this.closeEditDetail}
						onSubmitEditDetail={this.onSubmitEditDetail}
						editTemplate={editTemplate}
					/>
				)}

				{copySectionModal && (
					<CopySectionModal
						closeCopy={this.closeCopy}
						getData={this.getData}
						templateId={templateId}
						copySectionId={copySectionId}
					/>
				)}

				{editSectionModal && (
					<EditSectionModal
						closeEdit={this.closeEdit}
						getData={this.getData}
						templateId={templateId}
						item={editSectionTarget}
					/>
				)}

				{addSummaryModal && (
					<AddSummaryModal
						closeSummary={this.closeSummary}
						getData={this.getData}
						templateId={templateId}
						name={name}
						summary={summary}
					/>
				)}
				<div className="container-fluid">
					<div className="row">
						<div className="col-9">
							<div>
								<BackBtn onClick={this.goBack} className="mt-2">
									<img src={backArrow} alt="back arrow" />
									Back
								</BackBtn>
								<div className="d-flex justify-content-between">
									<div>
										<Title className="mt-2">{name}</Title>
										<Subtitle className="mt-2">
											{state}
										</Subtitle>
									</div>
									<div>
										<MenuButton
											openAdd={this.openAdd}
											openSummary={this.openSummary}
											deleteTemplate={this.deleteTemplate}
											openEditDetail={this.openEditDetail}
										/>
										<HeaderButton
											style={{
												color: '#9F1C3A',
												height: 40,
												marginTop: 5,
											}}
											onClick={this.publishTemplate}
										>
											{published
												? 'Unpublish'
												: 'Publish'}
										</HeaderButton>
									</div>
								</div>
								<hr />
							</div>
							<PageBody>
								{(sections.length === 0 && (
									<Callout intent="primary">
										This is a 2021 template. When starting a
										new blank template go into options and
										add a section.
									</Callout>
								)) || (
									<Callout intent="primary">
										Note that these are core templates.
										Please be careful when editing or
										deleting.
									</Callout>
								)}

								{sections.length === 0 && (
									<AddSectionText>
										<AddSectionLink
											onClick={() =>
												this.setState({
													addSectionModal: true,
												})
											}
										>
											Add a main section
										</AddSectionLink>
										<AddSectonSubtext>
											to begin creating your template
										</AddSectonSubtext>
									</AddSectionText>
								)}
								{sections.length > 0 &&
									sections.map(item => (
										<Section
											item={item}
											key={item.id}
											templateId={templateId}
											getData={this.getData}
											openCopy={this.openCopy}
											openEdit={this.openEdit}
										/>
									))}
							</PageBody>
						</div>
						<div className="col-3">
							<Sidebar sections={sections} />
						</div>
					</div>
				</div>
			</Wrapper>
		);
	}
}

EditAssessTemplate.propTypes = {
	history: PropTypes.object,
	match: PropTypes.object,
};

ButtonMenu.propTypes = {
	openAdd: PropTypes.func,
	openSummary: PropTypes.func,
	openEditDetail: PropTypes.func,
};

MenuButton.propTypes = {
	openAdd: PropTypes.func,
	openSummary: PropTypes.func,
	openEditDetail: PropTypes.func,
};

export default EditAssessTemplate;
