import React from "react";
import { StepsBar } from ".";

export const StepsBarPattern = () => {

  const steps = [
    {
      title: "Alarm activated", // required
      passed: true, // required (green or gray)
      active: false, // optional -> only one step must be active at a time...
      subtitle: "This is a supporting text", // optional
      icon: "symbol-circle", // optional -> symbol-circle by default
    },
    {
      title: "Roll Call",
      passed: true,
      icon: "person",
    },
    {
      title: "Roll Call ACTIVE",
      passed: true,
      active: true,
      icon: "tick",
    },
    {
      title: "Reunification",
      passed: false
    },
    {
      title: "Jessica Addison on October 10, 2023 11:02 AM",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      passed: true
    },
  ];

  return (
    <>
      {/* HORIZONTAL */}
      <div className="d-block w-100 mt-5">

        <h6 className="w-100 text-center mb-4">Horizontal (icons with circles)</h6>
        <StepsBar
          orientation="horizontal" // required
          steps={steps} // required
        />

      </div>

      <div className="d-block w-100 mt-5">

        <h6 className="w-100 text-center mb-4">Horizontal (icons without circles)</h6>
        <StepsBar
          orientation="horizontal" // required
          steps={steps} // required
          iconCircle={false} // optional -> true by default
        />

      </div>

      {/* VERTICAL */}
      <div className="d-flex w-100 p-5" style={{
        flexDirection: "row"
      }}>

        <div>
          <h6 className="w-100 mb-4">Vertical (icons with circles)</h6>
          <StepsBar
            orientation="vertical" // required
            steps={steps} // required
          />
        </div>

        <div>
          <h6 className="w-100 mb-4">Vertical (icons without circles)</h6>
          <StepsBar
            orientation="vertical"
            steps={steps}
            iconCircle={false} // optional -> true by default
          />
        </div>

        <div>
          <h6 className="w-100 mb-4">Vertical (Type Notes)</h6>
          <StepsBar
            orientation="vertical"
            steps={steps}
            iconCircle={false} // optional -> true by default
            stepBarType="notes" // optional -> only needed for type notes
          />
        </div>

      </div>
    </>
  )
}
