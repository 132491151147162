import React, { useState } from "react";
import { Tag } from '@blueprintjs/core';

const MoreCard = ({
    id,
    visibleOptions,
    items,
    borderIncluded = true,
    showText = null
}) => {
    const [showSitesCard, setShowSitesCard] = useState(false);

    document.addEventListener('click', function(e){
        if ( !document.getElementById('tag-'+id)?.contains(e.target)){
            // Clicked outside the box
            setShowSitesCard(false)
        }
    });

    const itemsToShow = (visibleOptions ? items?.slice(0, visibleOptions) : items) || [];
    const remainingCount = items?.length - visibleOptions;
    const remainingItems = items?.slice(visibleOptions) || [];

    return (
        <div className="d-flex MoreCard__Container">
            { !!showText ? showText : (
                <>
                    <div>
                        { itemsToShow.join(', ') }
                    </div>
                    { remainingCount > 0 && (
                    <div className="position-relative">
                        <Tag 
                            key={id}
                            className="tags-extra"
                            onClick={() => setShowSitesCard(!showSitesCard)}
                            id={'tag-'+id}
                            style={{
                                marginLeft:5
                            }}
                            > +{remainingCount} more 
                        </Tag>
                        {showSitesCard && 
                            <div className="roles-type-card">
                                { remainingItems.map((site, key) => {
                                    return (
                                        <div 
                                            className="roles-type-card-item"
                                            style={{border: (borderIncluded ? '' : 'none') }} 
                                            key={key}
                                        >
                                            <p>{site}</p>
                                        </div>
                                    )		
                                })}
                            </div>
                        }
                    </div>
                    )}
                </>
            )}
        </div>
    );
}

export default MoreCard;