import React, { useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import { PaginationDetails } from './components';
import { Checkbox } from '..';
import { Tooltip } from 'componentsV2';
import './css/BootstrapedTable.scss';

export const BootstrapedTable = ({
	keyField,
	data,
	columns,
	defaultSorted = [],
	hasCheckbox = false,
	onTableChange = () => {},
	onSelectRows = () => {},
	selectedRows = [],
	currentPage,
	setCurrentPage = () => {},
	rowsPerPage,
	setRowsPerPage = () => {},
	totalRows,
	remote = true,
	sizePerPageList = [10, 25, 50, 100, 200],
	disabledRowCondition = row => {},
	showPagination = true,
	cellEdit,
	borderNone, // this is for standalone tables (no header) that need top corner radii - see ManageStateDrillRequirements
	className = '',
	customSelectRow = null,
	showTooltipOnDisabledCheckboxes = false,
}) => {
	const tableRef = useRef(null);

	const onSelectAllHandler = isSelect => {
		if (isSelect) {
			// Select all rows
			const filteredRows = data.filter((item, index) => {
				return item.disabled ? null : item;
			});
			onSelectRows(filteredRows);
		} else {
			// Deselect all rows
			onSelectRows([]);
		}
	};

	const onSelectRow = (row, isSelect, rowIndex, e) => {
		if (isSelect) {
			if (row.disabled) {
				return false;
			}
			const updateSelectedRows = [...selectedRows, row];
			onSelectRows(updateSelectedRows);
		} else {
			const updateSelectedRows = selectedRows.filter(selectedRow => {
				return selectedRow[keyField] != row[keyField];
			});
			onSelectRows(updateSelectedRows);
		}
	};

	const selectionRenderer = ({ rowIndex, ...rest }) => {
		const row = data[rowIndex];
		const isDisabled = row?.disabled ? true : false;
		const CheckboxWrapper = ({ children }) => (
			<>
				{showTooltipOnDisabledCheckboxes && row?.showTooltip ? (
					<Tooltip tooltipTitle={row?.tooltipTitle} theme="light">
						{children}
					</Tooltip>
				) : (
					<>{children}</>
				)}
			</>
		);

		return (
			<CheckboxWrapper>
				<CustomCheckbox
					checked={rest.checked}
					indeterminate={rest.indeterminate}
					disabled={isDisabled}
					onChange={() => {}}
				/>
			</CheckboxWrapper>
		);
	};

	const CustomCheckbox = ({ checked, indeterminate, disabled, onChange }) => (
		<Checkbox
			onChange={onChange}
			checked={checked}
			indeterminate={indeterminate}
			disabled={disabled}
			size="md"
		/>
	);

	const selectRow = {
		mode: 'checkbox',
		clickToSelect: false,
		classes: 'selected-row',
		selected: selectedRows.map(row => row[keyField]),
		onSelect: onSelectRow,
		onSelectAll: onSelectAllHandler,
		selectionRenderer: selectionRenderer,
		selectionHeaderRenderer: selectionRenderer,
		...customSelectRow,
	};

	const rowClasses = (row, rowIndex) => {
		const classes = '';
		if (disabledRowCondition(row)) {
			return classes + ' disabled';
		}
		return classes;
	};

	const paginationData = showPagination
		? PaginationDetails(
				sizePerPageList,
				totalRows,
				tableRef,
				currentPage,
				setCurrentPage,
				rowsPerPage,
				setRowsPerPage,
				remote,
		  )
		: null;
	const columnsCount = columns.filter(column => column.hidden !== true).length;

	return (
		<div
			className={`tableContainerWrapperV2 ${borderNone && 'borderNone'} ${className}`}
			ref={tableRef}
		>
			<BootstrapTable
				bootstrap4
				classes={`bootstrap-custom-table columns-count-${columnsCount}`}
				remote={remote}
				keyField={keyField}
				data={data}
				columns={columns}
				bordered={false}
				defaultSorted={defaultSorted}
				selectRow={hasCheckbox ? selectRow : undefined}
				onTableChange={onTableChange}
				pagination={paginationData}
				rowClasses={rowClasses}
				cellEdit={cellEdit}
			/>
		</div>
	);
};

BootstrapedTable.propTypes = {
	keyField: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	defaultSorted: PropTypes.array,
	hasCheckbox: PropTypes.bool,
	onTableChange: PropTypes.func,
	onSelectRows: PropTypes.func,
	selectedRows: PropTypes.array,
	currentPage: PropTypes.number,
	setCurrentPage: PropTypes.func,
	rowsPerPage: PropTypes.number,
	setRowsPerPage: PropTypes.func,
	totalRows: PropTypes.number,
	remote: PropTypes.bool,
	sizePerPageList: PropTypes.array,
	disabledRowCondition: PropTypes.func,
	className: PropTypes.string,
	showTooltipOnDisabledCheckboxes: PropTypes.bool,
};
