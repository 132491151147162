/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { findIndex } from 'lodash';

import { checkMark } from 'assets/icons';
import { Input } from 'components/Input';
import { Pilsner } from 'components/Pilsner';
import { Callout } from 'components/Callout';
import { CardModal } from './CardModal';
import { BuildingsService } from '../../services/BuildingsService';

const Wrapper = styled.div`
	width: ${props => props.width || 'initial'};
	max-height: 500px;

	.dropdown {
		margin: 16px 0;
		min-width: 315px;
	}

	.required-field {
		width: 340px;
		display: flex;
		color: #10161a;
		padding: 5px 0;
		font-size: 16px;
		min-height: 45px;
		line-height: 22px;
		font-style: normal;
		font-weight: normal;
		align-items: center;
		flex-direction: row;
		font-family: Nunito Sans;
		background-color: #ffffff;
		border-bottom: 1px solid #f5f8fa;

		.drag-selector {
			margin-left: 7.5px;
			margin-right: 24px;
		}

		.field-text {
			margin-right: 10px;
		}

		.dropdown {
			margin-right: 10px !important;
		}

		.delete-requirement {
			margin-left: auto;
			z-index: 200;
			cursor: pointer;
		}
	}

	.apply-requirements {
		align-items: center;
		color: #9f1c3a;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		font-family: Nunito Sans;
		font-size: 16px;
		font-style: normal;
		font-weight: normal;
		justify-content: space-around;
		line-height: 22px;
		text-align: center;
		width: 90%;
	}

	.apply-requirements-container {
		border: 1px solid rgba(16, 22, 26, 0.2);
		display: flex;
		justify-content: center;
		margin: 16px 0;
		padding: 9px;
	}

	.apply-requirements-icon {
		width: 15px;
	}

	.pilsener-container {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 16px;
	}

	.pilsener {
		margin-left: 10px;
	}

	.input-with-text-container {
		display: flex;
		align-items: center;
		margin-top: 16px;
		margin-bottom: -16px;
	}

	.input-with-text {
		width: 40px;
		margin-right: 16px;
	}

	.text {
		margin-bottom: 16px;
		margin-top: 0;
	}
`;

const BuildingsContainer = styled.div`
	width: ${props => props.width || 'initial'};
	overflow-y: auto;
	max-height: 300px;
`;

export const SetRequirementsPerBuildingModal = ({
	setModalToShow,
	onActionClick,
	label = '',
	selectedDrillType,
}) => {
	const [requirementsChangeApplied, setRequirementsChangeApplied] = useState(
		false,
	);
	const [mainRequirement, setMainRequirement] = useState(
		selectedDrillType.districtRequirements || 0,
	);
	const [buildingRequirements, setBuildingRequirements] = useState(
		selectedDrillType?.districtRequirement?.buildingRequirements,
	);
	const [itemRequirement, setItemRequirement] = useState(0);

	// Build a list with all the buildings - minus the ones that have requirements set
	useEffect(() => {
		BuildingsService.getAll().then(backendBuildings => {
			setBuildingRequirements([
				...buildingRequirements,
				...backendBuildings.filter(backendBuilding => {
					const found = findIndex(
						buildingRequirements,
						existingBuildingReq =>
							existingBuildingReq.building.id ===
							backendBuilding.id,
					);
					return found === -1;
				}),
			]);
		});
	}, []);

	const copyRequirementToBuildings = value => {
		setBuildingRequirements(
			buildingRequirements.map(buildReq => ({
				...buildReq,
				requirement: value,
			})),
		);
		setRequirementsChangeApplied(true);
		setTimeout(() => {
			setRequirementsChangeApplied(false);
		}, 3000);
	};

	const updateRequirement = (buildingId, value) => {
		const tempValue =
			value.length === 0 ? parseInt(0, 10) : parseInt(value, 10);

		setItemRequirement(value);
		const temp = JSON.parse(JSON.stringify(buildingRequirements));
		temp.forEach(item => {
			if (item.building.id === buildingId) {
				item.requirement = tempValue;
				return item;
			}
			return item;
		});

		setBuildingRequirements(temp);
	};

	const updateDistrictRequirement = value => {
		setMainRequirement(value);
	};

	return (
		<CardModal
			isVisible
			setIsVisible={setModalToShow}
			title="Set drills per building"
			actionTitle="Save"
			// onActionClick calls with a boolean, the mainRequirement new value, and the buildin requirements updated
			onActionClick={() =>
				onActionClick(
					// If values are distinct, then the number changed
					mainRequirement !== selectedDrillType.districtRequirements,
					mainRequirement,
					buildingRequirements,
				)
			}
		>
			<Wrapper width="340px">
				<InputWithText
					inputValue={mainRequirement}
					text={label}
					title
					onChange={evt =>
						updateDistrictRequirement(evt.target.value)
					}
				/>

				<div className="apply-requirements-container">
					<div
						className="apply-requirements"
						onClick={() =>
							copyRequirementToBuildings(mainRequirement)
						}
					>
						{requirementsChangeApplied && (
							<img
								alt=""
								className="apply-requirements-icon"
								src={checkMark}
							/>
						)}
						<div>Apply requirement to all buildings</div>
					</div>
				</div>
				{requirementsChangeApplied && (
					<div className="pilsener-container">
						<Pilsner
							className="pilsener"
							icon={checkMark}
							backgroundColor="#EBF1F5"
							text="Drills per building updated"
							textStyles={{ marginBottom: 0 }}
							wrapperStyles={{
								width: '180px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						/>
					</div>
				)}
				<BuildingsContainer>
					<Callout
						className="d-flex mb-2"
						wrapperStyles={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<div>
							Here you can set the district requirement for each
							building individually. Click Apply requirement to
							all buildings above to set all buildings to the same
							requirement.
						</div>
					</Callout>
					{buildingRequirements && buildingRequirements.length ? (
						buildingRequirements.map(item => (
							<div>
								<InputWithText
									inputValue={
										item.requirement || itemRequirement
									}
									text={item.building?.name || item.name}
									onChange={evt =>
										updateRequirement(
											item.building.id,
											evt.target.value,
										)
									}
								/>
							</div>
						))
					) : (
						<div>There are no building requirements</div>
					)}
				</BuildingsContainer>
			</Wrapper>
		</CardModal>
	);
};

const InputWithText = ({ inputValue, text, title = false, onChange }) => (
	<div className="input-with-text-container">
		<Input
			className="input-with-text"
			value={inputValue}
			onChange={onChange}
		/>
		{title ? (
			<h2 className="text">{text}</h2>
		) : (
			<span className="text">{text}</span>
		)}
	</div>
);

InputWithText.propTypes = {
	inputValue: PropTypes.string,
	text: PropTypes.string,
	title: PropTypes.bool,
	onChange: PropTypes.func,
};

SetRequirementsPerBuildingModal.propTypes = {
	setModalToShow: PropTypes.func,
	onActionClick: PropTypes.func,
	label: PropTypes.string,
	selectedDrillType: PropTypes.object,
};
