import React from "react";
import {TinymceEditor}  from './TinymceEditor.jsx';

const TinymceEditorPattern = ({
	label = 'Test Label',
	placeholder = 'Enter a value',
	value = null,
	disabled = false,
	error = true,
	errorMessage = null,
	hasHintText=false,
	hintText=null,
	isRequired = false,
    onChange = null,
    labelState = true
}) => {
    const variableAction = () => {

    }
    const setupFunc = (editor, error) => {
        editor.ui.registry.addButton('variableMenu', {
            text: 'Insert Variable',
            onAction: variableAction,
        });

    }
    return (
		<>
		<TinymceEditor
            label="Default Optional"
            placeholder={placeholder}
            isRequired={isRequired}
            error={false}
            errorMessage={errorMessage}
            hasHintText={hasHintText}
            hintText = {hintText}
            disabled = {disabled}
            value={value}
            setupProps={setupFunc}
            onChange={() => {}}
            labelState={labelState}
            editorWidth={600}
            editorHeight={300}
            onInit={(event, editor) => {
                //this.editorRef.current = editor;
            }}
           
            initialValue={value}
        />
        <br/>
        <TinymceEditor
            label="Default Required Label"
            placeholder={placeholder}
            isRequired={true}
            error={false}
            errorMessage={errorMessage}
            hasHintText={hasHintText}
            hintText = {hintText}
            disabled = {disabled}
            value={value}
            setupProps={setupFunc}
            onChange={() => {}}
            labelState={labelState}
            editorWidth={600}
            editorHeight={300}
            onInit={(event, editor) => {
                //this.editorRef.current = editor;
            }}
           
            initialValue={value}
        />
        <br/>
        <TinymceEditor
            label="Default error"
            placeholder={placeholder}
            isRequired={true}
            error={true}
            errorMessage= "This is error msg"
            hasHintText={false}
            hintText = {hasHintText}
            disabled = {disabled}
            value={value}
            setupProps={setupFunc}
            onChange={() => {}}
            labelState={false}
            editorWidth={600}
            editorHeight={300}
            onInit={(event, editor) => {
                //this.editorRef.current = editor;
            }}
           
            initialValue={value}
        />
        <TinymceEditor
            label="Disabled"
            placeholder={placeholder}
            isRequired={true}
            error={false}
            errorMessage={errorMessage}
            hasHintText={hasHintText}
            hintText = {hintText}
            disabled = {true}
            value={value}
            setupProps={setupFunc}
            onChange={() => {}}
            labelState={false}
            editorWidth={400}
            editorHeight={200}
            width={600}
            initialValue={value}
        />
        <br/>
		</>
	);

}
export default TinymceEditorPattern;