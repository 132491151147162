import React, { useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import './index.scss';

const BootstrapedTable = ({
	keyField,
	data,
	columns,
	bordered,
	defaultSorted = [],
	rowStyle,
	selectRow,
	onTableChange = () => {},
	currentPage,
	setCurrentPage,
	rowsPerPage,
	setRowsPerPage,
	totalRows,
	sizePerPageList,
	remote = true,
	showTotal = false,
	rowClasses,
	headerClasses
}) => {
	const tableRef = useRef(null);
	
	const onPageChange = () => {
		// to make the page scroll into top after change table page
        tableRef?.current?.scrollIntoView();
	}

	const perPageList = () => {
		var perPageList = [];
		sizePerPageList.map(sizePerPage => {
			perPageList.push({
				text: `${sizePerPage} Rows`,
				value: sizePerPage
			})
		})
		return perPageList;
	}

	const sizePerPageOptionRenderer = ({
		text,
		page,
		onSizePerPageChange
	}) => {
		return (
			<li
				key={ text }
				role="presentation"
				className="dropdown-item"
			>
				<a
					href="#"
					tabIndex="-1"
					role="menuitem"
					data-page={ page }
					onMouseDown={e => {
						e.preventDefault();
						onSizePerPageChange(page);
					}}
				>
				{ text }
				</a>
			</li>
		)
	}
	
	var paginationOptions = {
		showTotal: showTotal,
		sizePerPageList: perPageList(),
		withFirstAndLast: true,
		showTotal: true,
		onPageChange: page => onPageChange(),
		sizePerPageOptionRenderer: sizePerPageOptionRenderer,
	};

	if(remote) {
		paginationOptions = {
			showTotal: showTotal,
			sizePerPageList: perPageList(),
			withFirstAndLast: true,
			showTotal: true,
			sizePerPageOptionRenderer: sizePerPageOptionRenderer,
			page: currentPage,
			sizePerPage: rowsPerPage,
			totalSize: totalRows,
			onSizePerPageChange: (perPage, page) => {
				setRowsPerPage(perPage);
				setCurrentPage(page);
			},
			onPageChange: (page, perPage) => {
				onPageChange();
				setRowsPerPage(perPage);
				setCurrentPage(page);
			},
		};
	}

	return (
		<div className='tableContainerWrapper' ref={tableRef}>
			<BootstrapTable
				bootstrap4
				remote={remote}
				keyField={keyField}
				data={data}
				columns={columns}
				bordered={bordered}
				defaultSorted={defaultSorted}
				rowStyle={rowStyle}
				selectRow={selectRow}
				onTableChange={onTableChange}
				pagination={paginationFactory(paginationOptions)}
				headerClasses={headerClasses}
				rowClasses={rowClasses}

			/>
		</div>
	);
}

export default BootstrapedTable;