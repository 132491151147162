import { useState } from 'react';
import { DocumentService } from 'services';
import { Intent } from 'componentsV2';
import { Helper } from 'utilities/helper';

export const MoveModalFn = (
	modalName,
	setLoading,
	documentData,
	dispatchDocumentData,
	closeModal,
	toaster,
	setModalToShow,
	getDocumentsList,
) => {
	const [selectedSite, setSelectedSite] = useState(null);
	const [selectedDocumentsToMove, setSelectedDocumentsToMove] = useState([]);

	const [inputErrors, setInputErrors] = useState({
		errorMessage: false
	})
	const showMoveModal = documents => {
		setSelectedSite(null);
		setInputErrors(oldErrors => (
			{
				...oldErrors,
				errorMessage: false
			}
		))
		setSelectedDocumentsToMove(documents);
		setModalToShow(modalName);
		dispatchDocumentData({
			...documentData,
			ids: documents
				.filter(item => item.type !== '360image')
				.map(item => item.id),
			panoramasIds: documents
				.filter(item => item.type === '360image')
				.map(item => item.id),
			displayTitle:
				documents.length === 1
					? documents[0].title
					: `${documents.length} files selected`,
		});
	};
	const handleChangeSite = site => {
		setSelectedSite(site);
		setInputErrors(oldErrors => (
			{
				...oldErrors,
				errorMessage: false
			}
		))
		dispatchDocumentData({
			...documentData,
			siteId: site.id,
			siteName: site.name,
		});
	};
	const handleMoveSubmit = () => {
		setLoading(true);
		DocumentService.moveDocument({
			siteId: documentData.siteId,
			ids: documentData.ids,
			panoramasIds: documentData.panoramasIds
		})
			.then(response => {
				const sitenameMessage = `"${documentData.siteName ? documentData.siteName : 'Visible to All Sites'}"`;
				const successMessage = `You have successfully moved the file to ${documentData.siteName ? sitenameMessage : 'be ' + sitenameMessage}`;
				toaster(
					successMessage,
					Intent.SUCCESS,
				);
				closeModal();
				getDocumentsList();
			})
			.catch(error => {
				const sameSiteError = error.error.fields.same_site || '';
				const siteIdError = error.error.fields.site_id || '';
				const documentsIdsError =
					error.error.fields.documents_ids || '';
				const panoramasIdsError =
					error.error.fields.panoramas_ids || '';

				let errorMessage = Helper.capitalizeFirstLetter(
					`${sameSiteError} ${siteIdError} ${documentsIdsError} ${panoramasIdsError}`.trim(),
				);
				setInputErrors(oldErrors => (
					{
						...oldErrors,
						errorMessage: errorMessage
					}
				))
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		showMoveModal,
		handleChangeSite,
		handleMoveSubmit,
		selectedSite,
		selectedDocumentsToMove,
		inputErrors
	};
};
