import React, { useEffect, useState, useContext } from 'react'
import { TemplateService } from 'services';
import { stringToVarName } from 'utilities';
import { ToasterContext } from 'pages/App';
import {
    Modal,
    ModalFooter,
    Button,
    Dropdown,
    Input,
    Animated,
    Intent,
    FeaturedIcon,
} from 'componentsV2';

export const AddUpdateCustomVariable = ({
    showAddModal,
    setShowAddModal,
    template,
    reloadVariables,
    item,
    reloadData,
    onCloseMainModal,
}) => {
    const [loading, setLoading] = useState(false);
    const [variableType, setVariableType] = useState(null);
    const [variablePlaceholder, setVariablePlaceholder] = useState("");
    const [variableName, setVariableName] = useState("");
    const [variableHelpText, setVariableHelpText] = useState("");
    const [variableImageWidth, setVariableImageWidth] = useState("");
    const [variableImageHeight, setVariableImageHeight] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(true);
    const toaster = useContext(ToasterContext);

    const variableTypes = [
        { value: 1, label: 'Text', placeholder: 'Name of text variable', typeAnnotation: 'Open text field' },
        { value: 2, label: 'Image', placeholder: 'Name of image variable', typeAnnotation: 'Image' },
        { value: 3, label: 'Phone Number', placeholder: 'Phone number variable', typeAnnotation: 'Phone' },
        { value: 4, label: 'Email Address', placeholder: 'Email address variable', typeAnnotation: 'Email' },
    ];

    const handleChangeVariableType = (item) => {
        setVariableType(item);
        setVariablePlaceholder(item.placeholder);
        setVariableName("");
        setVariableHelpText("");
        setVariableImageWidth("");
        setVariableImageHeight("");
    }

    const handleChangeVariableName = ({ target }) => {
        setVariableName(target.value);
    }

    const handleChangeVariableHelpText = ({ target }) => {
        setVariableHelpText(target.value);
    }

    const handleChangeVariableImageWidth = ({ target }) => {
        setVariableImageWidth(target.value);
    }

    const handleChangeVariableImageHeight = ({ target }) => {
        setVariableImageHeight(target.value);
    }

    const getVariableBody = () => {
        return {
            name: stringToVarName(variableName),
            content: JSON.stringify({
                helpText: variableHelpText,
                name: variableName,
                typeAnnotation: variableType.typeAnnotation,
                type: {
                    id: variableType.value,
                    value: variableType.label,
                },
                width: variableImageWidth ? variableImageWidth : 0,
                height: variableImageHeight ? variableImageHeight : 0,
            }),
        }
    }

    const handleSubmitAdd = () => {
        setLoading(true);
        TemplateService.addCustomVariable(template.id, getVariableBody())
            .then(resp => {
                if (resp.statusCode === 201) {
                    toaster(`You have successfully created the variable "${variableName}"`,
                        Intent.SUCCESS,
                        <FeaturedIcon
                            icon={'tick'}
                            type={'Success'}
                            size={'md'}
                            shape={'circleIcon'}
                        />,
                    );
                    setShowAddModal(false);
                    reloadVariables();
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSubmitEdit = () => {
        if (item.name == stringToVarName(variableName)) {
            editCustomVariable();
        } else {
            updateTemplate();
        }
    }

    const updateTemplate = () => {
        setLoading(true);

        template.sections.forEach(section => {
            if (section.content) {
                if (section.content.includes(item.name)) {
                    section.content = section.content.replaceAll(
                        item.name,
                        stringToVarName(variableName),
                    );
                }
                section.subsections.forEach(subsection => {
                    if (subsection.content.includes(item.name)) {
                        subsection.content = subsection.content.replaceAll(
                            item.name,
                            stringToVarName(variableName),
                        );
                    }
                });
            }
        });

        TemplateService.updatePlanTemplate({ content: JSON.stringify(template.sections) }, template.id)
            .then(resp => {
                if (resp.statusCode === 200) {
                    editCustomVariable();
                }
            });
    }

    const editCustomVariable = () => {
        setLoading(true);
        TemplateService.updateCustomVariable(template.id, item.id, getVariableBody())
            .then(resp => {
                if (resp.statusCode === 201) {
                    toaster(`You have successfully updated the variable "${variableName}"`,
                        Intent.SUCCESS,
                        <FeaturedIcon
                            icon={'tick'}
                            type={'Success'}
                            size={'md'}
                            shape={'circleIcon'}
                        />,
                    );
                    setShowAddModal(false);
                    reloadData(true);
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
                onCloseMainModal(false);
            });
    }

    useEffect(() => {
        setDisableSubmit(true);
        if (variableName.trim().length > 0) {
            if (variableType.label == 'Image') {
                if (variableImageWidth > 0 && variableImageHeight > 0) {
                    setDisableSubmit(false);
                }
            } else {
                setDisableSubmit(false);
            }
        }
    }, [variableName, variableImageWidth, variableImageHeight]);

    useEffect(() => {
        resetForm();
        if (item) {
            setFormToEdit(item);
        }
    }, [showAddModal, item]);

    const resetForm = () => {
        setVariableType(null);
        setVariableName("");
        setVariableHelpText("");
        setVariableImageWidth("");
        setVariableImageHeight("");
    }

    const setFormToEdit = (item) => {
        let content = JSON.parse(item.content);
        if (content && content.type && content.type.id > 0) {
            let varType = variableTypes.filter(v => v.value == content.type.id);
            if (varType.length == 1) {
                setVariableType(varType[0]);
                setVariablePlaceholder(varType[0].placeholder);
                setVariableName(content.name);
                setVariableHelpText(content.helpText);
                setVariableImageWidth(content.width ? content.width : 0);
                setVariableImageHeight(content.height ? content.height : 0);
            }
        }
    }

    const confirmButtonStep1 =
        <Button
            text="Cancel"
            type="primaryDefault"
            intent="default"
            onClick={() => setShowAddModal(false)}
        />;

    const confirmButtonStep2 =
        <Button
            text={item ? "Save" : "Create"}
            type="primaryDefault"
            intent="default"
            disabled={disableSubmit}
            onClick={item ? handleSubmitEdit : handleSubmitAdd}
        />;

    const cancelButton =
        <Button
            text="Cancel"
            type="tertiary"
            intent="default"
            onClick={() => setShowAddModal(false)}
        />;

    return (
        <>
            <Modal
                isOpen={showAddModal}
                onClose={setShowAddModal}
                loading={loading}
                title={item ? "Edit variable" : "Create variable"}
                showCloseButton={false}
                scrollContent={false}
                customFooter={
                    <ModalFooter
                        confirmButton={variableType ? confirmButtonStep2 : confirmButtonStep1}
                        cancelButton={variableType ? cancelButton : <></>}
                    />
                }
            >
                <>
                    <div className={`w-100 mb-3 ${item ? "d-none" : ""}`}>
                        <Dropdown
                            label="Variable type"
                            placeholder="Select variable"
                            value={variableType}
                            options={variableTypes}
                            onChange={item ? () => { } : handleChangeVariableType}
                            isRequired={true}
                        />
                    </div>

                    {variableType && (
                        <Animated>
                            <div className="w-100">
                                <Input
                                    label={variablePlaceholder}
                                    placeholder={variablePlaceholder}
                                    isRequired={true}
                                    width="100%"
                                    value={variableName}
                                    onChange={handleChangeVariableName}
                                    hasHintText={true}
                                    hintText={`Actual variable value: ${stringToVarName(variableName)}`}
                                />
                            </div>

                            <div className="w-100 mt-3">
                                <Input
                                    label="Example help text"
                                    placeholder="Help text"
                                    isRequired={false}
                                    width="100%"
                                    value={variableHelpText}
                                    onChange={handleChangeVariableHelpText}
                                    hasHintText={true}
                                    hintText="Help text will appear below the variable input for the end user."
                                />
                            </div>

                            {variableType.label == 'Image' && (
                                <Animated>
                                    <div className="d-flex w-100 mt-3" style={{
                                        gap: "16px"
                                    }}>
                                        <div className="w-50">
                                            <Input
                                                label="Width in pixels"
                                                placeholder="Width"
                                                isRequired={true}
                                                width="100%"
                                                value={variableImageWidth}
                                                onChange={handleChangeVariableImageWidth}
                                            />
                                        </div>
                                        <div className="w-50">
                                            <Input
                                                label="Height in pixels"
                                                placeholder="Height"
                                                isRequired={true}
                                                width="100%"
                                                value={variableImageHeight}
                                                onChange={handleChangeVariableImageHeight}
                                            />
                                        </div>
                                    </div>
                                </Animated>
                            )}
                        </Animated>
                    )}
                </>
            </Modal>
        </>
    )
}
