import React, { useContext, useEffect, useState } from 'react';
import { ContentSwitcher, SearchBar, DropdownMultiSelect } from 'componentsV2';
import {
	REUNIFICATION_GUARDIANS_LIST,
	REUNIFICATION_STUDENTS_LIST,
	REUNIFICATION_STUDENT_STATUS_CHECKEDIN,
	REUNIFICATION_STUDENT_STATUS_REUNIFIED,
	REUNIFICATION_STUDENT_STATUS_NOSTATUS,
	STATUS_PARTIALLY_REUNIFIED,
	STATUS_REUNIFICATION_COMPLETE,
	STATUS_NO_STUDENTS_REUNIFIED,
} from '../consts';
import PropTypes from 'prop-types';
import './css/ReunificationFilteration.scss';
import { ReunificationContext } from '../Provider';
import { RollCallDetailsContext } from '../../../RollCallDetails';
import { StatusFilterBar, StatusFilterMenu } from '../../RollCall/Students/components';
import { useStudentsGrades } from '../Students/hooks/useStudentsGrades';

export const ReunificationFilteration = ({ reunificationActiveTab, setReunificationActiveTab }) => {
	const {
		initialFilterationData,
		filterationData,
		setFilterationData,
		filterationDisabled,
		initialGuardianFilterationData,
		guardianFilterationData,
		setGuardianFilterationData,
		gaurdianFilterationDisabled,
	} = useContext(ReunificationContext);

	const ReunificationSwitchTab = [
		{ id: REUNIFICATION_STUDENTS_LIST, label: 'Students' },
		{ id: REUNIFICATION_GUARDIANS_LIST, label: 'Guardians' },
	];
	const { gradesList } = useStudentsGrades();

	const statusList = [
		{
			label: 'Checked-in',
			value: REUNIFICATION_STUDENT_STATUS_CHECKEDIN,
		},
		{
			label: 'Reunified',
			value: REUNIFICATION_STUDENT_STATUS_REUNIFIED,
		},
		{
			label: 'No status',
			value: REUNIFICATION_STUDENT_STATUS_NOSTATUS,
		},
	];

	const guardianStatusList = [
		{
			label: STATUS_PARTIALLY_REUNIFIED,
			value: STATUS_PARTIALLY_REUNIFIED,
		},
		{
			label: STATUS_REUNIFICATION_COMPLETE,
			value: STATUS_REUNIFICATION_COMPLETE,
		},
		{
			label: STATUS_NO_STUDENTS_REUNIFIED,
			value: STATUS_NO_STUDENTS_REUNIFIED,
		},
	];

	const onSwitchTabHandler = tab => {
		setReunificationActiveTab(tab[0]);
		setFilterationData(initialFilterationData);
		setGuardianFilterationData(initialGuardianFilterationData);
	};

	// no_status
	const onSearchHandler = search => {
		setFilterationData(oldData => ({
			...oldData,
			studentName: search,
			page: 1,
		}));
	};

	const onGuardianChangeStatusHandler = statusList => {
		setGuardianFilterationData(oldData => ({
			...oldData,
			statuses: statusList,
			page: 1,
		}));
	};

	const onGuardianSearchHandler = search => {
		setGuardianFilterationData(oldData => ({
			...oldData,
			guardianName: search,
			page: 1,
		}));
	};

	return (
		<section className="reunification-tab-filteration-container">
			<div className="reunification-tab-filteration-container-left-side">
				<ContentSwitcher
					onClick={onSwitchTabHandler}
					tabs={ReunificationSwitchTab}
					defaultActiveTabs={[reunificationActiveTab]}
					preserveTab={true}
				/>
			</div>
			<div className="reunification-tab-filteration-container-right-side">
				{reunificationActiveTab === REUNIFICATION_GUARDIANS_LIST ? (
					<>
						<SearchBar
							placeholder={'Search by guardian name...'}
							icon="search"
							className="reunification-tab-filteration-container-right-side-search-bar"
							value={guardianFilterationData.guardianName}
							onSearch={onGuardianSearchHandler}
							disabled={gaurdianFilterationDisabled}
							key="guardian-seach"
						/>
						<DropdownMultiSelect
							label={null}
							values={guardianFilterationData.statuses}
							options={guardianStatusList}
							allowSelectAll={false}
							placeholder="Filter by status"
							className="status-mddl"
							visibleOptions={2}
							onChange={onGuardianChangeStatusHandler}
							width="400px"
							disabled={gaurdianFilterationDisabled}
						/>
					</>
				) : (
					<>
						<SearchBar
							placeholder={'Search by student name...'}
							icon="search"
							className="reunification-tab-filteration-container-right-side-search-bar"
							value={filterationData.studentName}
							onSearch={onSearchHandler}
							disabled={filterationDisabled}
							key="student-search"
						/>
						<StatusFilterMenu
							filterationData={filterationData}
							setFilterationData={setFilterationData}
							statusOptions={statusList}
							gradesList={gradesList}
							disabled={filterationDisabled}
						/>
					</>
				)}
			</div>
			<div className="w-100">
				<StatusFilterBar
					filterationData={filterationData}
					setFilterationData={setFilterationData}
					statusOptions={statusList}
				/>
			</div>
		</section>
	);
};

ReunificationFilteration.propTypes = {
	setActiveTab: PropTypes.func.isRequired,
};
