export const contact911Validation = formData => {
	let isValid = true;
	const errors = {};
	if (formData.contact911 === null) {
		isValid = false;
		errors.contact911 = 'Contact 911 is required';
	}

	if (formData._911Active) {
		if (formData.contact911 == true && formData.alarmTypes911 === null) {
			isValid = false;
			errors.alarmTypes911 = 'Alarm type 911 is required';
		}
	}

	return {
		isValid,
		errors,
	};
};
