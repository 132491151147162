import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog, Callout } from '@blueprintjs/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 0 10px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
`;

const Text = styled.p`
	font-family: Nunito Sans;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-decoration: underline;
	color: #182026;
	margin-left: 4px;
`;

class CompletePlanModal extends React.Component {
	state = {
		loading: false,
		incompleteSections: [],
	};

	checkSections = () => {
		const { template } = this.props;
		const temp = [];
		template.sections.forEach(item => {
			if (!item.complete) {
				temp.push(item.title);
			}
			if (item.subsections) {
				item.subsections.forEach(one => {
					if (!one.complete) {
						temp.push(one.title);
					}
				});
			}
		});

		this.setState({ incompleteSections: temp });
	};

	completePlan = () => {
		this.setState({ loading: true });
		const { template, closeCompleteModal } = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		fetch(
			`${process.env.API_URL}/district-templates/${parseInt(
				template.id,
				10,
			)}`,
			{
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					isCompleted: true,
				}),
			},
		)
			.then(res => res.json())
			.then(() =>
				Swal.fire({
					title: 'Done!',
					icon: 'success',
					timer: 1000,
					showConfirmButton: false,
				}).then(() => {
					this.setState({ loading: false });
					return closeCompleteModal();
				}),
			);
	};

	componentDidMount() {
		return this.checkSections();
	}

	render() {
		const { isOpen, closeCompleteModal } = this.props;
		const { loading, incompleteSections } = this.state;
		return (
			<>
				<Dialog isOpen={isOpen} onClose={closeCompleteModal}>
					{loading && <LoadingSpinner />}
					{!loading && (
						<>
							<div>
								<TitleWrap>
									<Title>Mark safety plan as complete</Title>
								</TitleWrap>
								<hr />
							</div>
							<div className="mx-2">
								{incompleteSections.length > 0 && (
									<>
										<Callout intent="warning">
											The following sections and sub
											sections are incomplete.
										</Callout>
										<ul className="list-unstyled mt-3">
											{incompleteSections.map(
												(item, index) => (
													<li key={index}>
														<Text>{item}</Text>
													</li>
												),
											)}
										</ul>
									</>
								)}
								{incompleteSections.length === 0 && (
									<Callout intent="warning">
										Are you sure you want to complete the
										plan?
									</Callout>
								)}
							</div>
							<div className="mx-3 mt-3 d-flex justify-content-end">
								<Button
									text="Cancel"
									intent="secondary"
									large
									onClick={closeCompleteModal}
								/>
								<Button
									text="Complete"
									intent="primary"
									large
									className="ml-2"
									onClick={this.completePlan}
								/>
							</div>
						</>
					)}
				</Dialog>
			</>
		);
	}
}

CompletePlanModal.propTypes = {
	isOpen: PropTypes.bool,
	template: PropTypes.object,
	closeCompleteModal: PropTypes.func,
};

export default CompletePlanModal;
