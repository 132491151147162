import { useCallback, useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import { RapidAlarmService } from 'services';
import { safeDateToLuxon } from 'componentsV2';
import { ToasterContext } from 'pages/App';

export const useActiveAlarms = (filterationInitialState = [], selectedSites = []) => {
	const [filterationData, setFilterationData] = useState({
		...filterationInitialState,
		sites: selectedSites,
	});

	const [activeAlarmList, setActiveAlarmList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [noDataAdded, setNoDataAdded] = useState(false);
	const [showEmptyStatus, setShowEmptyStatus] = useState(false);
	const toaster = useContext(ToasterContext);
	const toggleState = useSelector(state => state.route.toggleState);

	useEffect(() => {
		getActiveAlarm();
	}, [filterationData, toggleState]);

	const getActiveAlarm = useCallback(() => {
		setLoading(true);
		const startDate = filterationData.startDate
			? safeDateToLuxon(filterationData.startDate).toFormat('M/d/yyyy')
			: null;
		const endDate = filterationData.endDate
			? safeDateToLuxon(filterationData.endDate).toFormat('M/d/yyyy')
			: null;

		const params = {
			search: filterationData.search ? filterationData.search : '',
			buildingIds: filterationData.sites ? filterationData.sites.map(site => site.value) : [],
			startDate: startDate,
			endDate: endDate,
			alarmTypes: filterationData.alarmTypes
				? filterationData.alarmTypes.map(alarmType => alarmType.value)
				: [],
			alarmModes: filterationData.alarmModes
				? filterationData.alarmModes.map(alarmMode => alarmMode.value)
				: [],
			triggeredBy911: !!filterationData.triggeredCellular,
		};
		RapidAlarmService.getActiveAlarms(params)
			.then(response => {
				if (response.statusCode === 200) {
					setActiveAlarmList(response.data);
					const isEmpty = response.data.length === 0;
					setShowEmptyStatus(isEmpty);
					setNoDataAdded(isFirstRender && isEmpty);
				}
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setIsFirstRender(false);
				setLoading(false);
			});
	}, [filterationData]);

	return {
		showEmptyStatus,
		noDataAdded,
		filterationData,
		setFilterationData,
		loading,
		activeAlarmList,
	};
};
