import React, { useState, useEffect, useContext } from 'react';
import { RapidAlarmService } from 'services';
import { Animated, LoadingSpinner, EmptyState, myClasses, noResult, Intent } from 'componentsV2';
import { SidebarListItem, StudentsTable, StudentsFooterOptions, SubmitClassModal } from './';
import { ToasterContext } from 'pages/App';
import { RollCallDetailsContext } from '../../../RollCallDetails';
import { RollCallAndReunificationContext } from '../..';

export const MyClassesContent = ({
	classesList,
	loadingClasses,
	classSelected,
	setClassSelected,
	customListClass,
	filterData,
	setFilterData,
	initialData,
	onScrollClassList,
	alarm,
	rosterUpdateEvent,
	setDisableFilter,
}) => {
	const [loadingStudents, setLoadingStudents] = useState(true);
	const [studentsList, setStudentsList] = useState([]);
	const [totalStudents, setTotalStudents] = useState(0);
	const [showSubmitClassModal, setShowSubmitClassModal] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [markAllAccounted, setMarkAllAccounted] = useState(false);
	const { studentUpdateEvent } = useContext(RollCallDetailsContext);
	const loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
	const customClassSelected =
		classSelected?.isCustom && classSelected?.user?.id === loggedUser.id;
	const toaster = useContext(ToasterContext);
	const { requestId } = useContext(RollCallAndReunificationContext);

	useEffect(() => {
		//student status updated
		if (studentUpdateEvent) {
			switch (studentUpdateEvent.name) {
				case 'reunificationStudents:checkIn':
				case 'reunificationStudents:checkInManual':
					getStudentsByClass(false);
					break;
				case 'alarmStudents:updated':
					if (
						studentUpdateEvent.data?.requestId !== requestId ||
						filterData.statusSelected.length > 0
					) {
						getStudentsByClass(false);
					}
					break;
			}
		}
	}, [studentUpdateEvent]);

	useEffect(() => {
		let filterApplied = filterData.name.length > 0 || filterData.statusSelected.length > 0;
		setDisableFilter(!filterApplied && !studentsList.length);
	}, [studentsList]);

	useEffect(() => {
		if (classSelected) {
			getStudentsByClass();
		}
	}, [classSelected, filterData]);

	useEffect(() => {
		if (classSelected && rosterUpdateEvent?.data.id == classSelected.id) {
			getStudentsByClass(false);
		}
	}, [rosterUpdateEvent]);

	const getStudentsByClass = (showLoading = true) => {
		setLoadingStudents(showLoading);
		const params = {
			...filterData,
			classId: classSelected.id,
			statusSelected: filterData.statusSelected.map(statusSelected => statusSelected.value),
		};

		RapidAlarmService.getStudentsByClass(params)
			.then(response => {
				if (response.statusCode === 200 && response.data) {
					setStudentsList(response.data);
					setTotalStudents(response._metadata.totalRecords);
				}
			})
			.catch(error => {
				setStudentsList([]);
			})
			.finally(() => {
				setLoadingStudents(false);
			});
	};

	const handleListItemClick = clickedClass => {
		if (classSelected && clickedClass.id !== classSelected.id) {
			setDisableFilter(true);
			setClassSelected(clickedClass);
			setFilterData(initialData);
			if (classId === 0) {
				setStudentsList([]);
				setTotalStudents(0);
			}
		}
	};

	const emptyStateInfo = {
		header: customClassSelected ? 'No students in custom list' : 'No results found',
		description: customClassSelected ? (
			<>
				Custom list will include any students you
				<br />
				have accounted for that don't belong to your class.
			</>
		) : (
			<>
				Your search didn't match any student. <br />
				Please try again.
			</>
		),
		icon: customClassSelected ? myClasses : noResult,
	};

	const submitClassOnConfirmHandler = (isMarkAllAsAccounted, classId) => {
		let requestBody;
		if (isMarkAllAsAccounted) {
			requestBody = {
				mark_all_as_accounted: true,
			};
		} else {
			requestBody = {
				submitRosterOnly: true,
			};
		}
		setModalLoading(true);
		RapidAlarmService.submitClass(requestBody, classId, alarm.id)
			.then(response => {
				if (response.statusCode === 200 && response.data) {
					const msg = isMarkAllAsAccounted
						? `You have successfully marked all students as “Accounted for”`
						: `You have successfully submitted “${classSelected?.courseName}”.`;
					toaster(msg, Intent.SUCCESS);
				}
			})
			.catch(err => {
				toaster(err?.error?.description, Intent.DANGER);
			})
			.finally(() => {
				onCloseModal();
			});
	};

	const submitClassHandler = (isMarkAllASAccountedModal = false) => {
		setMarkAllAccounted(isMarkAllASAccountedModal);
		setShowSubmitClassModal(true);
	};

	const onCloseModal = () => {
		setModalLoading(false);
		setShowSubmitClassModal(false);
	};

	return (
		<Animated>
			<div className="roll-call-my-classes-container">
				{classesList.length > 0 || customListClass ? (
					<>
						<div className="sidebar-container">
							<div className="sidebar-title">Select a class</div>
							<div className="sidebar-list">
								<div
									id="my-classes-list"
									className="sidebar-list-classes"
									onScroll={onScrollClassList}
								>
									{classesList.map((myClass, key) => (
										<SidebarListItem
											key={key}
											selected={myClass.id == classSelected.id}
											onClick={handleListItemClick}
											myClass={myClass}
										/>
									))}
								</div>
								<div className="sidebar-list-custom">
									<SidebarListItem
										key="custom"
										selected={customClassSelected}
										onClick={handleListItemClick}
										myClass={customListClass}
										tooltip="Custom list will include any students you have accounted for that
										don't belong to your class."
										custom={true}
									/>
								</div>
							</div>
						</div>
						<div className="students-table-container">
							{loadingStudents && <LoadingSpinner className="mt-3" />}
							{!loadingStudents && (
								<Animated>
									{studentsList.length > 0 ? (
										<>
											<StudentsTable
												studentsList={studentsList}
												setStudentsList={setStudentsList}
												totalStudents={totalStudents}
												filterData={filterData}
												setFilterData={setFilterData}
												borderNone={true}
												rosterId={classSelected.id}
											/>
											<StudentsFooterOptions
												isMarkAllAsAccountedAllowed={
													alarm.alarmType.markAllAsAccounted
												}
												submitClassHandler={submitClassHandler}
											/>
											{showSubmitClassModal && (
												<SubmitClassModal
													selectedClassElement={classSelected}
													setShowSubmitClassModal={
														setShowSubmitClassModal
													}
													submitClassOnConfirmHandler={
														submitClassOnConfirmHandler
													}
													modalLoading={modalLoading}
													onCloseModal={onCloseModal}
													markAllAsAccounted={markAllAccounted}
												/>
											)}
										</>
									) : (
										<div className="no-results-container">
											<EmptyState
												header={emptyStateInfo.header}
												description={emptyStateInfo.description}
												icon={emptyStateInfo.icon}
												showCircles={false}
											/>
										</div>
									)}
								</Animated>
							)}
						</div>
					</>
				) : (
					<>
						{!loadingClasses && (
							<EmptyState
								header="You don't have any classes"
								icon={myClasses}
								showCircles={false}
							/>
						)}
					</>
				)}
			</div>
		</Animated>
	);
};
