import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CardModal } from 'components/Modals/CardModal';
import { Input } from 'components/Input';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { DrillTypesService } from 'services/DrillTypesService';

const Page = styled.div`
	padding: 8px 24px;

	.input {
		width: 350px;
	}
`;

class ManageDrillTypes extends React.Component {
	state = {
		data: [],
		isLoading: false,
		showCreationModal: false,
		newDrillType: '',
	};

	componentDidMount() {
		this.setState({ isLoading: true });
		this.fetchData();
	}

	fetchData = () => {
		DrillTypesService.getAll(false).then(drillTypes => {
			this.setState({ data: drillTypes, isLoading: false });
		});
	};

	setCreationModal = show => {
		this.setState({
			showCreationModal: show,
		});
	};

	createDrillType = () => {
		const { newDrillType } = this.state;
		DrillTypesService.createDrillType(newDrillType).then(() => {
			this.fetchData();
			this.setState({
				showCreationModal: false,
				newDrillType: '',
			});
		});
	};

	render() {
		const { isLoading, data, showCreationModal, newDrillType } = this.state;
		const { history } = this.props;

		if (isLoading) {
			return null;
		}

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Manage Drill Types"
					onBackBtnClick={history.goBack}
					actionTitle="Create New Drill Type"
					onActionClick={() => this.setCreationModal(true)}
				/>

				{/* drills table */}
				<Table
					headers={['Drill type', 'UUID']}
					numberOfItemsToDisplay={15}
					data={data}
					renderItem={item => (
						<>
							<td>{item.label}</td>
							<td>{item.uuid}</td>
						</>
					)}
				/>

				{/* create drill type modal */}
				<CardModal
					isVisible={showCreationModal}
					setIsVisible={this.setCreationModal}
					title="Create New Drill Type"
					actionTitle="Create"
					onActionClick={this.createDrillType}
				>
					<Input
						label="Drill Type Name"
						placeholder="Enter name"
						value={newDrillType}
						onChange={({ target }) =>
							this.setState({ newDrillType: target.value })
						}
					/>
				</CardModal>
			</Page>
		);
	}
}

ManageDrillTypes.propTypes = {
	history: PropTypes.object,
};

export default ManageDrillTypes;
