import React, { useState } from 'react';
import {
	assessEmptyState,
	childrenGroup,
	emptyDocument,
	emptyFiles,
	emptyFolder,
	fonts,
	heartbroken,
	illustrationalarm,
	link,
	maps,
	messagesEmptyState,
	noResult,
	safetyplan,
	myClasses,
} from './icons';
import { Dropdown, InputWrapper, Checkbox, Input, Button, EmptyState } from 'componentsV2';
import './css/EmptyState.scss';

export const EmptyStatePattern = () => {
	const [title, setTitle] = useState('Empty state title');
	const [description, setDescription] = useState(
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	);
	const [icon, setIcon] = useState({
		value: emptyDocument,
		label: 'emptyDocument',
	});
	const [showSecondBtn, setShowSecondBtn] = useState(true);
	const [showFirstBtn, setShowFirstBtn] = useState(true);

	const predefinedIcons = [
		{
			value: assessEmptyState,
			label: 'assessEmptyState',
		},
		{
			value: emptyDocument,
			label: 'emptyDocument',
		},
		{
			value: emptyFiles,
			label: 'emptyFiles',
		},
		{
			value: emptyFolder,
			label: 'emptyFolder',
		},
		{
			value: fonts,
			label: 'fonts',
		},
		{
			value: maps,
			label: 'maps',
		},
		{
			value: noResult,
			label: 'noResult',
		},
		{
			value: childrenGroup,
			label: 'childrenGroup',
		},
		{
			value: heartbroken,
			label: 'heartbroken',
		},
		{
			value: illustrationalarm,
			label: 'illustrationalarm',
		},
		{
			value: link,
			label: 'link',
		},
		{
			value: messagesEmptyState,
			label: 'messagesEmptyState',
		},
		{
			value: safetyplan,
			label: 'safetyplan',
		},
		{
			value: myClasses,
			label: 'myClasses',
		},
	];
	return (
		<div className="row">
			<div className="col-md-12">
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-md-6">
								{/* TODO Replace old components (input, dropdown & checkbox) */}
								<InputWrapper label="Title" labelState={true} isRequired={true}>
									<Input
										large
										placeholder="Title ..."
										value={title}
										onChange={e => setTitle(e.target.value)}
									/>
								</InputWrapper>
							</div>
							<div className="col-md-6">
								<InputWrapper label="Description" labelState={true}>
									<Input
										large
										placeholder="Description ..."
										onChange={e => setDescription(e.target.value)}
										value={description}
									/>
								</InputWrapper>
							</div>
							<div className="col-md-6">
								<Dropdown
									className="mt-3"
									label="Icon"
									placeholder="Select icon"
									value={icon}
									options={predefinedIcons}
									onChange={value => setIcon(value)}
								/>
							</div>
							<div className="col-md-3 mt-4">
								<Checkbox
									label="Show cancel btn"
									onChange={() => setShowFirstBtn(oldValue => !oldValue)}
									checked={showFirstBtn}
								/>
							</div>
							<div className="col-md-3 mt-4">
								<Checkbox
									label="Show confirm btn"
									onChange={() => setShowSecondBtn(oldValue => !oldValue)}
									checked={showSecondBtn}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-12">
				<EmptyState header={title} description={description} icon={icon?.value}>
					<>
						{showFirstBtn && (
							<Button
								type="secondaryDefault"
								text="cancel action"
								size="lg"
								onClick={() => {}}
							/>
						)}
						{showSecondBtn && (
							<Button
								type="primaryDefault"
								text="confirm action"
								size="lg"
								onClick={() => {}}
							/>
						)}
					</>
				</EmptyState>
			</div>
		</div>
	);
};
