import { useCallback, useContext, useEffect, useState } from 'react';
import { ReunificationService } from 'services';
import { RollCallDetailsContext } from '../../../../RollCallDetails';

export const useReunficationStudents = (alarmId, filterationData) => {
	const [loading, setLoading] = useState(true);
	const [studentsList, setStudentsList] = useState([]);
	const [totalStudents, setTotalStudents] = useState(0);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [isEmptyData, setIsEmptyData] = useState(false);
	const [error, setError] = useState(null);

	const { studentUpdateEvent, resetStudentUpdateEvent } = useContext(RollCallDetailsContext);

	useEffect(() => {
		//student status updated event
		if (studentUpdateEvent) {
			switch (studentUpdateEvent.name) {
				case 'reunificationStudents:checkIn':
				case 'reunificationStudents:reunified':
				case 'reunificationStudents:checkInManual':
					getStudentsList(false);
					break;
			}
			resetStudentUpdateEvent();
		}
	}, [studentUpdateEvent]);

	useEffect(() => {
		getStudentsList();
	}, [filterationData]);

	const getStudentsList = useCallback(
		(showLoading = true) => {
			if (showLoading) {
				setLoading(true);
			}
			ReunificationService.getStudentsList(alarmId, filterationData)
				.then(response => {
					if (response.statusCode === 200) {
						setStudentsList(response.data);
						setTotalStudents(response._metadata.totalRecords);
						setIsEmptyData(isFirstRender && response.data.length === 0);
					}
				})
				.catch(error => {
					setStudentsList([]);
					setError(error?.error?.description);
				})
				.finally(() => {
					if (showLoading) {
						setLoading(false);
					}
					setIsFirstRender(false);
				});
		},
		[alarmId, filterationData],
	);

	return {
		loading,
		setStudentsList,
		studentsList,
		totalStudents,
		isEmptyData,
		error,
		getStudentsList,
	};
};
