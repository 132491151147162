import React from "react";
import { Button } from 'componentsV2/Button';
import { Icon } from '@blueprintjs/core';

export const TabHeader = ({
    row,
    index,
    handlePrintClick

}) => {

    return (
        <div key = {'HeaderKey'+index } style={{background: '#'+row.backgroundColor}} className="card-header tabHeader">
            <div  className="row">
                <div className="col-md-10">             
                    <div className = "card-title" style={{color: '#'+row.textColor}}> 
                        <span className="cardHeaderTitle">{row.title} </span>
                    </div>
                </div>
                {index == 0 ?
                <div style={{width: '100%'}} className="col-md-2 printBtn">
                    <Button
                        onClick={() => handlePrintClick()}
                        type="secondaryDefault"
                        text="Print"
                        size="sm"
                        width="100%"
                        id="print_button"
                    />
                </div> : ""}

            </div>
        </div>
   )
}