import React, { useState } from 'react';
import { GuardianDetails, AddStudentHeader } from './Shared';
import './css/SelectedStudentsStep.scss';
import { useStudentStatus } from '../../../Students/hooks';
import { GUARDIAN_RELATIONS, CHECKIN_STEPS } from '../consts';
import { Dropdown } from 'componentsV2';

export const SelectedStudentsStep = ({
	currentSelectedStudent,
	formData,
	onChangeModalDetails,
	changeStep,
	setCurrentSelectedStudent,
	selectedRelation,
	setSelectedRelation,
	selectedStudents,
	selectedGuardian,
	addAsNew,
}) => {
	const studentName =
		currentSelectedStudent.firstName +
		' ' +
		(currentSelectedStudent.middleInitial != null
			? currentSelectedStudent.middleInitial.substring(0, 1) + '. '
			: '') +
		currentSelectedStudent.lastName;

	const onChangeHandler = selectedValue => {
		let currentStudent = currentSelectedStudent;
		currentStudent.relation = selectedValue.value;
		setCurrentSelectedStudent(currentStudent);
		setSelectedRelation(selectedValue);
		onChangeModalDetails({ disabled: false });
	};

	const onRemoveAction = () => {
		setCurrentSelectedStudent(null);
		changeStep(CHECKIN_STEPS.ADD_STUDENTS_STEP);
	};

	return (
		<div className="selectedStudentWrapper">
			<GuardianDetails
				formData={formData}
				selectedGuardian={selectedGuardian}
				addAsNew={addAsNew}
			/>
			<div className="SelectStudent_content">
				<AddStudentHeader title="Selected student" subtitle="" />
				{useStudentStatus(
					studentName,
					`Grade:  ${currentSelectedStudent.grade}`,
					currentSelectedStudent,
					() => {},
					currentSelectedStudent,
					true,
					onRemoveAction,
					selectedStudents,
				)}
				<Dropdown
					label={`Select the relation of the guardian to ${studentName}`}
					value={selectedRelation}
					options={GUARDIAN_RELATIONS}
					onChange={onChangeHandler}
					isRequired={true}
					placeholder="Select relation"
					className="GuardianRelation_dropDown"
				/>
			</div>
		</div>
	);
};
