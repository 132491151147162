import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, Position } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';

import { Button } from 'components/Button';
import { formatDate } from 'utilities/dates';

const TableRow = ({
	item,
	index,
	setEdit,
	setCopy,
	removeTemplate,
	history,
	permissions,
	districtId,
}) => {
	const [menu, setMenu] = useState(false);
	const editTemplate = () => history.push(`/assess/editTemplate/${item.id}`);

	const editDetails = () => {
		setMenu(false);
		return setEdit(item, index);
	};

	const cloneTemplate = () => {
		setMenu(false);
		return setCopy(item, index);
	};

	const removeTemplateDialog = () => {
		setMenu(false);
		Swal.fire({
			title: 'Remove template?',
			// title: 'Delete the template?',
			text: 'Are you sure you want to remove this template?',
			// text: 'This action can not be undone',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Remove',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				removeTemplate(item);
			}
			return Swal.close();
		});
	};

	const cantEdit =
		item.isCoreTemplate && districtId !== 6880 && !permissions.isSysAdmin;

	return (
		<tr key={item.id}>
			<th scope="row">{item.year}</th>
			{item.isCoreTemplate === true ? (
				<td style={{ display: 'flex' }}>
					{item.name}
					<p
						style={{
							marginLeft: 10,
							fontFamily: 'Nunito Sans',
							fontStyle: 'italic',
							color: '#738694',
						}}
					>
						Navigate360
					</p>
				</td>
			) : (
				<td>{item.name}</td>
			)}
			<td>{item.state}</td>
			<td>{item.createdDate?.date}</td>
			<td>{item.published ? 'Published' : 'Not published'}</td>
			<td>
				<Popover
					enforceFocus={false}
					isOpen={menu}
					position={Position.BOTTOM}
					onClose={() => setMenu(false)}
				>
					<Button
						icon="more"
						text=""
						onClick={() => setMenu(true)}
						minimal
					/>
					<div style={{ width: '234px' }}>
						<div className="pt-2 pb-2">
							{cantEdit ? (
								''
							) : (
								<Button
									minimal
									text="Edit template"
									className="w-100 justify-content-start"
									onClick={editTemplate}
								/>
							)}
							{cantEdit ? (
								''
							) : (
								<Button
									minimal
									text="Edit details"
									className="w-100 justify-content-start"
									onClick={editDetails}
								/>
							)}
							<Button
								minimal
								text="Copy"
								className="w-100 justify-content-start"
								onClick={cloneTemplate}
							/>
							<Button
								intent="danger"
								minimal
								text="Remove"
								onClick={removeTemplateDialog}
								className="w-100 justify-content-start"
							/>
						</div>
					</div>
				</Popover>
			</td>
		</tr>
	);
};

TableRow.propTypes = {
	item: PropTypes.object,
	index: PropTypes.number,
	setEdit: PropTypes.func,
	setCopy: PropTypes.func,
	removeTemplate: PropTypes.func,
	history: PropTypes.object,
	permissions: PropTypes.object,
	districtId: PropTypes.number,
};

export { TableRow };
