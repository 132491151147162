import { HttpChatService } from 'networking/HttpChatService';

export const ChatService = {
	getAll: async (alarmId, room, params = {}) => {
		let query = '';
		const roomCode = room === 'admin' ? '01' : '03';
		if (params.lastKey) {
			query += `&lastKey=${params.lastKey}`;
		}
		if (params.pageSize) {
			query += `&size=${params.pageSize}`;
		}

		return await HttpChatService({
			method: 'GET',
			route: `/messages/${alarmId}/${roomCode}/paged?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	sendMessage: async (alarmId, room, body = {}) => {
		const roomCode = room === 'admin' ? '01' : '03';
		return await HttpChatService({
			route: `/messages/${alarmId}/${roomCode}`,
			method: 'POST',
			body,
		}).catch(err => {
			throw err;
		});
	},
};
