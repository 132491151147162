import React, { useEffect, useState, useContext } from 'react';
import { LoadingSpinner, Button } from 'componentsV2';
import { Filtration, TemplatesTable, EmptyStatuses } from './components';
import { TemplateService } from 'services/TemplateService';
import './css/SafetyPlanTemplates.scss';
import { SafetyplansContext } from '../Providers/SafetyplansProvider';
import { SafetyplansProvider } from '../Providers/SafetyplansProvider';

export const SafetyPlanTemplates = ({ history }) => {
	const { handleCreateNewTemplate } = useContext(SafetyplansContext);

	const [loading, setLoading] = useState(true);
	const [templates, setTemplates] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [noDataAdded, setNoDataAdded] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [showEmptyStatus, setShowEmptyStatus] = useState(false);

	const [filtrationData, setFiltrationData] = useState({
		name: '',
		sortKey: 'name',
		sortDir: 'asc',
		page: 1,
		perPage: 10,
	});

	useEffect(() => {
		getTemplatesList();
	}, [filtrationData]);

	const getTemplatesList = async () => {
		TemplateService.ListTemplates(filtrationData)
			.then(async response => {
				if (response.statusCode === 200) {
					setTemplates(response.data.templates);
					setTotalRows(response._metadata.totalRecords);
					var isEmpty = response.data.templates.length === 0;
					setNoDataAdded(isFirstRender && isEmpty);
					setShowEmptyStatus(isEmpty);
				}
			})
			.catch(error => {
				console.error('Error fetching Templates list:', error);
			})
			.finally(() => {
				setLoading(false);
				setIsFirstRender(false);
			});
	};

	const createBtn = (
		<Button
			icon="plus"
			text="New template"
			className="actionButtonElement"
			onClick={() => handleCreateNewTemplate()}
		/>
	);

	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && (
				<>
					<SafetyplansProvider reloadData={getTemplatesList} history={history}>
						<div className="templates-container">
							{!noDataAdded && (
								<>
									<section className="templates-section-header-container">
										<div className="left-side">
											<span className="text">Safety plan templates</span>
										</div>
										<div className="right-side">{createBtn}</div>
									</section>

									<section className="templates-section-filter-container">
										<Filtration
											filtrationData={filtrationData}
											setFiltrationData={setFiltrationData}
											setLoading={setLoading}
											getTemplatesList={getTemplatesList}
										/>
									</section>
								</>
							)}
							{showEmptyStatus && (
								<EmptyStatuses
									filterationData={filtrationData}
									noDataAdded={noDataAdded}
									createBtn={createBtn}
								/>
							)}
							{!showEmptyStatus && (
								<TemplatesTable
									list={templates}
									filtrationData={filtrationData}
									setFiltrationData={setFiltrationData}
									totalRows={totalRows}
									history={history}
									setLoading={setLoading}
								/>
							)}
						</div>
					</SafetyplansProvider>
				</>
			)}
		</>
	);
};
