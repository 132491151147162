import React, { useContext, useState } from 'react';
import { Button, Modal, Intent } from 'componentsV2';
import './css/CopyAreaConfirmationModal.scss';
import { copyArea, drawAreaGroup, drawAreaLabel } from '../Draw/Area';
import { FloorplanService } from 'services';
import { ToasterContext } from 'pages/App';
import { GEOMETRY_TYPE_POLYGON, MAP_AREA_Edit } from '../../Details/consts';
import { addShapeActions } from '../CanvasDrawFn';

export const CopyAreaConfirmationModal = ({
	isOpen,
	handleClose,
	selectedShape,
	floorplanDetails,
	toggleMapOption,
	setSelectedArea,
	setSelectedShape,
	setFloorplanShapes,
	layer,
	showModal,
}) => {
	const toaster = useContext(ToasterContext);
	const [loading, setLoading] = useState(false);

	const footer = (
		<div className="button-div-styles">
			<Button
				text={'No, go back'}
				type="tertiary"
				intent="default"
				onClick={() => {
					handleClose();
				}}
			/>
			<Button
				text={'Yes, copy it'}
				intent="default"
				onClick={() => {
					handlecopy();
				}}
			/>
		</div>
	);

	const handlecopy = () => {
		if (selectedShape) {
			copyArea(null, selectedShape, true);
			setLoading(true);
			FloorplanService.copyShape(floorplanDetails.id, selectedShape.attrs.id)
				.then(resp => {
					if (resp.statusCode === 201) {
						toaster(
							'You have successfully copied this area.',
							Intent.SUCCESS,
							null,
							2000,
							false,
							'map-view-page',
						);
						let shape = {
							id: resp.data.id,
							title: resp.data.title,
							color: selectedShape.attrs.shape.color,
							colorId: selectedShape.attrs.shape.colorId,
							showLabel: selectedShape.attrs.shape.showLabel ? true : false,
							positions: JSON.stringify(selectedShape.attrs.points),
							geometryType: GEOMETRY_TYPE_POLYGON,
							visible: true,
						};
						let newArea = layer.find(node => node.name() === 'copyPoly')[0];
						newArea.attrs.id = `${resp.data.id}`;
						newArea.attrs.title = resp.data.title;
						newArea.attrs.name = resp.data.title;
						newArea.attrs.geometryType = GEOMETRY_TYPE_POLYGON;
						newArea.attrs.shape = shape;
						newArea.attrs.stroke = '#B3D8DD';
						newArea.attrs.strokeWidth = 9;
						newArea.attrs.showLabel = selectedShape.attrs.shape.showLabel
							? true
							: false;
						newArea.draggable(true);

						// add shape actions to area
						const areaGroup = drawAreaGroup(shape);
						areaGroup.add(newArea);
						if (shape.showLabel) {
							const areaLabel = drawAreaLabel(shape, newArea);
							areaGroup.add(areaLabel);
						}
						addShapeActions(newArea, 'area', null, showModal, null);
						layer.add(areaGroup);

						layer.draw();
						setSelectedArea(shape);
						setSelectedShape(shape);
						handleClose();
						toggleMapOption(MAP_AREA_Edit);
						// add copied area to floorplanShapes state
						setFloorplanShapes(oldShapes => [...oldShapes, shape]);
					}
				})
				.catch(error => {
					console.error(error);
				})
				.finally(() => {});
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			loading={loading}
			title={'Are you sure you want to copy this area?'}
			subtitle={'The name and shape will be copied, Content will need to be added.'}
			customClassName="map-container-modals"
			footer={footer}
		/>
	);
};
