import React, { useState, useEffect, useRef } from 'react';
import { FloorplanService } from 'services';
import { Button, Modal, Tooltip } from 'componentsV2';
import { PrintMapHeader, PrintMapFooter } from './components';
import './css/PrintMap.scss';

export const PrintMap = ({
	floorplan,
	setCurrentFloorplan,
	printedCanvas,
	mapCanvas,
	legends,
	floorplanShapes,
	xToken = null,
	onClose,
}) => {
	const [floorplanUpdated, setFloorplanUpdated] = useState(null);
	const [floorplanDetails, setFloorplanDetails] = useState(null);
	const [prinMapLoading, setPrinMapLoading] = useState(false);
	const printStage = useRef();
	const printLayer = useRef();
	const containerRef = useRef();

	useEffect(() => {
		intializeStageAndLayer();
		getFloorplanDetails();
	}, []);

	useEffect(() => {
		if (floorplanDetails && floorplanUpdated) {
			setTimeout(printFloorplan, 2000);
		}
	}, [floorplanDetails, floorplanUpdated]);

	const intializeStageAndLayer = () => {
		console.log({ mapCanvas, printedCanvas });
		printStage.current = new Konva.Stage({
			container: containerRef.current,
			width: mapCanvas.width,
			height: mapCanvas.height,
			x: 0,
			y: 0,
			position: 'relative',
			top: 0,
			left: 0,
		});
		printLayer.current = new Konva.Layer();
		printStage.current.add(printLayer.current);

		const mapToPrint = new Konva.Image({
			image: mapCanvas,
			x: 0,
			y: 0,
		});
		printLayer.current.add(mapToPrint);
		const imageToPrint = new Konva.Image({
			image: printedCanvas,
			x: 0,
			y: 0,
		});
		printLayer.current.add(imageToPrint);
		printLayer.current.draw();
	};

	const getFloorplanDetails = () => {
		setFloorplanUpdated(null);
		setFloorplanDetails(null);
		setPrinMapLoading(true);
		Promise.all([
			FloorplanService.getMapInfo(floorplan.id),
			FloorplanService.getFloorplansBySiteId({
				siteId: floorplan.building.id,
				sortKey: 'order',
				sortDir: 'ASC',
				floorplanId: floorplan.id,
			}),
		])
			.then(([mapInfoResponse, floorplanResponse]) => {
				setFloorplanDetails(mapInfoResponse.data.floorplan);
				const floorplanToUpdate = floorplanResponse.data.find(
					floorplanFound => floorplanFound.id === floorplan.id,
				);
				setFloorplanUpdated(floorplanToUpdate);
				setCurrentFloorplan(floorplanToUpdate);
			})
			.catch(() => {
				if (mapInfoResponse) {
					console.log(mapInfoResponse?.error?.description);
				}
				if (floorplanResponse) {
					console.log(floorplanResponse?.error?.description);
				}
			})
			.finally(() => {
				setPrinMapLoading(false);
			});
	};

	const printFloorplan = () => {
		if (!xToken) {
			window.print();
		}
	};
	return (
		<>
			<div className="print-map">
				<div className="map-title">
					Site Maps
					{!xToken && (
						<Tooltip tooltipTitle="Close page">
							<Button
								icon="cross"
								size="md"
								type="tertiary"
								intent="default"
								onClick={onClose}
								wrapperClass="no-print"
							/>
						</Tooltip>
					)}
				</div>
				<div className="print-map-container">
					{floorplanUpdated && floorplanDetails && (
						<PrintMapHeader
							floorplan={floorplanUpdated}
							floorplanDetails={floorplanDetails}
						/>
					)}
					<div ref={containerRef} className="map-container-canvas-shapes" />
					<PrintMapFooter
						floorplan={floorplan}
						legends={legends}
						floorplanShapes={floorplanShapes}
					/>
				</div>
			</div>

			{prinMapLoading && (
				<Modal
					isOpen={true}
					onClose={() => {}}
					loading={true}
					showCloseButton={false}
					customClassName="map-container-modals"
				/>
			)}
		</>
	);
};
