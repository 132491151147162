import React, { useEffect, useContext, useState } from 'react';
import { Intent } from '@blueprintjs/core';
import { FloorplanService } from 'services';
import { ToasterContext } from 'componentsV2';

export const RenameMapModalFn = (
	modalName,
	setModalToShow,
	modalData,
	dispatchModalData,
	setRefreshTextList,
	setLoading,
	closeModal,
	currentFloorplan,
) => {
	const toaster = useContext(ToasterContext);
	const AVAILABLE_TO_USE = 'AvailableToUse';
	const [initialTextValue, setInitialTextValue] = useState("");
	const [textToRename, setTextToRename] = useState("");
	const [disableRenameForm, setDisableRenameForm] = useState(true);
	const [tabType, setTabType] = useState("");
	const [siteId, setSiteId] = useState(0);
	const [layer, setLayer] = useState(0);

	useEffect(() => {
		let disableForm = true;
		if (textToRename.length > 0 && textToRename !== initialTextValue) {
			disableForm = false;
			dispatchModalData({
				...modalData,
				onConfirmAction: () => renameTexts(),
			});
		}
		setDisableRenameForm(disableForm);
	}, [textToRename]);

	const showTextRenameModal = (textShape, tabType, siteId, layer) => {
		setInitialTextValue(textShape.title);
		setTextToRename(textShape.title);
		setTabType(tabType);
		setSiteId(siteId);
		setLayer(layer);

		let subtitle =
			tabType == AVAILABLE_TO_USE ? (
				<span>
					Renaming this text will affect all map layers.
					<br /><br />
					To rename this text to affect only this map layer, rename it from the In use tab.
				</span>
			) : (
				<span>
					Renaming this text will affect just this map layer.
					<br /><br />
					To rename this text to affect all map layers, rename it from the side panel under the Available to use tab. 
				</span>
			);

		setModalToShow(modalName);
		dispatchModalData({
			modalTitle: "Rename text",
			modalSubTitle: subtitle,
			confirmBtnTitle: "Update",
		});
	};

	const handleChangeText = ({target}) => {
		setTextToRename(target.value);
	}

	const renameTexts = () => {
		const body = {};
		setLoading(true);
		let toasterSuccessMsg = '';
		body.oldText = initialTextValue;
		body.newText = textToRename;

		if (tabType == AVAILABLE_TO_USE) {
			toasterSuccessMsg =
				'You have successfully renamed this text for all map layers.';
		} else {
			body.floorplanId = currentFloorplan.id;
			toasterSuccessMsg =
				'You have successfully renamed this text for this map layer.';
		}

		FloorplanService.renameTexts(siteId, body)
			.then(() => {
				toaster(toasterSuccessMsg, Intent.SUCCESS, null, 2000, false, 'map-view-page');
				setRefreshTextList(oldValue => !oldValue);
				renameTextsOnLayer();
				closeModal();
			})
			.catch(error => {
				const msg = `Something went wrong, please try again later`;
				toaster(`${msg}`, Intent.DANGER, null, 2000, false, 'map-view-page');
			})
			.finally(() => {
				setDisableRenameForm(true);
				setModalToShow(null);
				setLoading(false);
			});
	};

	const renameTextsOnLayer = () => {
		layer.children.map(shape => {
			if (shape instanceof Konva.Text && shape.text() == initialTextValue) {
				shape.text(textToRename);
			}
		});
	}

	return {
		showTextRenameModal,
		textToRename,
		handleChangeText,
		disableRenameForm,
	};
};
