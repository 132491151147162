import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { warning, clock, circleCheckMark, calendar } from 'assets/icons';

const OPTIONS = {
	scheduled: {
		width: 83,
		icon: calendar,
		color: '#137CBD',
		background: '#E8F2F9',
		title: 'Scheduled',
	},
	overdue: {
		width: 73,
		icon: warning,
		color: '#DB3737',
		background: '#FCEBEB',
		title: 'Overdue',
	},
	in_progress: {
		width: 89,
		icon: clock,
		color: '#137CBD',
		background: '#E8F2F9',
		title: 'In progress',
	},
	completed: {
		width: 85,
		icon: circleCheckMark,
		color: '#128C5A',
		background: '#E8F4EF',
		title: 'Completed',
	},
	not_scheduled: {
		width: 107,
		icon: warning,
		color: '#D9822B',
		background: '#FCF3EA',
		title: 'Not scheduled',
	},
};

const Wrapper = styled.div`
	height: 21px;
	display: flex;
	justify-content: center;
	align-content: center;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	padding: 3px 6px;
	font-style: normal;
	border-radius: 10px;
	letter-spacing: 0px;
	font-family: Nunito Sans;
	color: ${props => OPTIONS[(props?.status)]?.color};
	max-width: ${props => OPTIONS[(props?.status)]?.width}px;
	background-color: ${props => OPTIONS[(props?.status)]?.background};
	margin-left: 8px;

	img {
		margin-top: -3px;
		margin-right: 4px;
		width: 12px;
		height: 12px;
	}

	p {
		margin-bottom: 0px;
	}
`;

const StatusPilsner = ({ status, extraText = '' }) => {
	const formattedStatus = status?.toLowerCase();
	const icon = OPTIONS[formattedStatus]?.icon;
	const title = OPTIONS[formattedStatus]?.title + extraText;

	if (!icon || !title) {
		return null;
	}

	return (
		<Wrapper className="pilsner" status={formattedStatus}>
			<div>
				<img src={icon} alt="" />
			</div>
			<p>{title}</p>
		</Wrapper>
	);
};

StatusPilsner.propTypes = {
	status: PropTypes.oneOf([
		'overdue',
		'scheduled',
		'in_progress',
		'completed',
	]),
};

export { StatusPilsner };
