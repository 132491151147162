import React, { useState } from "react";
import { Button, Popover, Position } from '@blueprintjs/core';
import './index.css';

const ActionsButton = ({
    disabled = false, 
    isOpened = false,
    children,
    customClass 
}) => {
    const [isOpen, setIsOpen] = useState(isOpened);

    return (
        <Popover
            className="actions-popvoer actions-btn-popover-overlay"
            disabled={disabled}
            enforceFocus={false}
            visible={true}
            minimal={false}
            isOpen={isOpen}
            position={Position.BOTTOM}
            onClose={() => setIsOpen(false)}
        >
            <Button
                icon="more"
                disabled={disabled}
                onClick={() => setIsOpen(true)}
                minimal
            />
                <div className={"actions-btn-popover " + customClass}>
                    {children}
                </div>
        </Popover>
    )
}

export default ActionsButton;