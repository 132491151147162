import React, { useContext, useState } from 'react';
import NormalModal from '../../../components/Modals/NormalModal';
import { ToasterContext } from '../../App';
import { Intent } from '@blueprintjs/core';
import { UsersService } from 'services/UsersService';
import { Button } from '../../../components/Button';
import ChangesConfirmationModal from './ChangesConfirmationModal';
import BootstrapTable from 'react-bootstrap-table-next';
import TableRows from './TableRows';
import './css/BulkEmailModal.scss';

const BulkEmailModal = ({ onCloseModal, selectedRows, fetchUsers, setSelectedRows }) => {
	const [btnWelcomeEmail, setBtnWelcomeEmail] = useState('switchon');
	const [btnResetPassword, setBtnResetPassword] = useState();
	const [actionText, setActionText] = useState('Welcome Email List');
	const [welcomeFlag, setWlcomeFlag] = useState(1);
	const context = useContext(ToasterContext);
	const [data, setData] = useState(selectedRows);
	const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
	const [changesOccuredFlag, setChangesOccuredFlag] = useState(0);
	const [showConfirmPopModal, setShowConfirmPopModal] = useState(0);
	const [rowsData, setRowsData] = useState(selectedRows);
	const [loading, setLoading] = useState(false);

	const SendBtnClickHandler = () => {
		setLoading(true);
		const usersIds = [];
		rowsData.map(selectedRow => {
			usersIds.push(selectedRow.id);
		});
		if (welcomeFlag == 1) {
			UsersService.bulkWelcomeEmailUsers(usersIds)
				.then(resp => {
					if (resp.statusCode == 200) {
						context(resp.data, Intent.SUCCESS);
						fetchUsers();
						setSelectedRows([]);
						setRowsData(rows);
					} else {
						context(resp.data, Intent.DANGER);
					}
					onCloseModal();
				})
				.catch(() => {
					onCloseModal();
				});
		} else {
			UsersService.bulkResetPasswordUsers(usersIds)
				.then(resp => {
					if (resp.statusCode == 200) {
						context(resp.data, Intent.SUCCESS);
						fetchUsers();
						setSelectedRows([]);
						setRowsData(rows);
					} else {
						context(resp.data, Intent.DANGER);
					}
					onCloseModal();
				})
				.catch(() => {
					onCloseModal();
				});
		}
	};

	const cancelBtnClickHandler = () => {
		if (changesOccuredFlag == 1) {
			setShowConfirmPopModal(1);
		} else {
			fetchUsers();
			setSelectedRows([]);
			onCloseModal();
		}
	};

	const welcomeEmailBtnClicked = () => {
		setBtnWelcomeEmail('switchon');
		setBtnResetPassword('switchoff');
		setActionText('Welcome Email List');
		setWlcomeFlag(1);
	};

	const resetPasswordBtnClicked = () => {
		setBtnWelcomeEmail('switchoff');
		setBtnResetPassword('switchon');
		setActionText('Password Reset Email List');
		setWlcomeFlag(0);
		setChangesOccuredFlag(1);
	};

	const closeConfirmPopUpModal = () => {
		setShowConfirmPopModal(0);
	};

	const deleteTableRows = index => {
		const rows = [...rowsData];
		rows.splice(index, 1);
		setRowsData(rows);
		setSelectedRows(rows);
		if (rows.length == 0) {
			setSendBtnDisabled(true);
		}
		setChangesOccuredFlag(1);
	};

	const handleChange = (index, evnt) => {
		const { name, value } = evnt.target;
		const rowsInput = [...rowsData];
		rowsInput[index][name] = value;
		setRowsData(rowsInput);
	};

	const removeRow = row => {
		const newData = data.filter(item => item.id !== row.id);
		setData(newData);
		if (newData.length == 0) {
			setSendBtnDisabled(true);
		}
		setChangesOccuredFlag(1);
	};

	const columns = [
		{ dataField: 'uniqid', text: 'ID', hidden: true },

		{
			dataField: 'person.fullName',
			text: 'Name',
			sort: false,
		},

		{
			dataField: 'login',
			text: 'Email',
			sort: false,
		},

		{
			dataField: '',
			text: 'Remove',
			formatter: (cell, row) => (
				<Button minimal={true} onClick={() => removeRow(row)} icon="cross" />
			),
		},
	];

	return (
		<>
			<NormalModal
				setModalToShow="EnableUsersModal"
				onConfirmAction={SendBtnClickHandler}
				onCancelAction={cancelBtnClickHandler}
				header="Send Bulk Email"
				confirmBtnTitle="Send"
				cancelBtnTitle="Cancel"
				confirmBtnDisabled={sendBtnDisabled}
				loading={loading}
				className={'email-modal'}
			>
				<div className="email-subtitle">Email Type</div>
				<div className="row mb-3">
					<div className="col-md-12">
						<div className="form-group">
							<div className="action-buttons-wrap">
								<Button
									text="Welcome Email"
									intent={btnWelcomeEmail}
									onClick={welcomeEmailBtnClicked}
								/>
								&nbsp;
								<Button
									text="Password Reset Email"
									intent={btnResetPassword}
									onClick={resetPasswordBtnClicked}
								/>
							</div>
							<div
								style={{
									fontSize: '14px',
									lineHeight: '20px',
									color: '#000',
									fontWeight: 400,
									marginTop: 3,
									fontFamily: 'Nunito Sans',
								}}
							>
								Select which type of email you want to send.
							</div>
						</div>
						<p
							style={{
								fontSize: '14px',
								lineHeight: '20px',
								color: '#000',
								fontWeight: 700,
								fontFamily: 'Nunito Sans',
							}}
						>
							{actionText}
						</p>
					</div>
				</div>
				<div className="bootstrap-table-container border-radius custom-table custom-scroll">
					<table className="table sticky-table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th>Remove</th>
							</tr>
						</thead>
						<tbody>
							<TableRows
								rowsData={rowsData}
								deleteTableRows={deleteTableRows}
								handleChange={handleChange}
							/>
						</tbody>
					</table>
				</div>
			</NormalModal>
			{showConfirmPopModal && (
				<ChangesConfirmationModal
					onConfirmAction={onCloseModal}
					onCancelAction={closeConfirmPopUpModal}
					confirmBtnTitle="Yes"
					backBtnTitle="No"
					headerMessage="Unsaved changes"
					bodyMessage="Are you sure you want to close this page and lose all your changes?"
				/>
			)}
		</>
	);
};

export default BulkEmailModal;
