/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import { dislikeFill, likeFill } from 'assets/icons';

const StyledButton = styled.button`
	background: none;
	border: none;
	text-align: left;
	width: 100%;
`;

const StyledTr = styled.tr`
	display: ${props => (props.showNested ? 'table-row' : 'none')};
	td {
		padding: 8px 0;
	}
	border-bottom: 1px solid #dee2e6;
`;

const NestedTr = styled(StyledTr)`
	td {
		&:first-child {
			padding-left: 28px;
		}
	}
`;

const convertType = one => {
	if (one.type === 'select') {
		return 'Select';
	}
	if (one.type === 'textarea' || one.type === 'area') {
		return 'Text Area';
	}
	if (one.type === 'multiple') {
		return 'Multiple Select';
	}
	if (one.type === 'yn') {
		const temp = JSON.parse(one.options);
		return temp.toString();
	}

	return null;
};

const TableRow = ({ item }) => {
	const [showNested, setShowNested] = useState(false);

	return (
		<>
			<StyledTr showNested>
				<td>
					<StyledButton onClick={() => setShowNested(!showNested)}>
						{item.answers.length > 0 && (
							<Icon
								icon={showNested ? 'caret-down' : 'caret-right'}
							/>
						)}
						{item.title}
					</StyledButton>
				</td>
				<td>
					<strong>{convertType(item)}</strong>
				</td>
				<td />
			</StyledTr>
			{item.answers.map((one, index) => (
				<NestedTr
					key={`analytics-answer-${index}`}
					showNested={showNested}
				>
					<td>{one.name}</td>
					<td className="pl-2">{one.value}</td>
					<td>
						{one.rating && (
							<img
								src={
									one.rating === 'up' ? likeFill : dislikeFill
								}
								alt="answer rating"
							/>
						)}
					</td>
				</NestedTr>
			))}
		</>
	);
};
/* 

if select answer is SELECT
			if textarea answer is TEXT AREA
			if type multiple answer is multiple select 
			if yn answer show options

			*/
TableRow.propTypes = {
	item: PropTypes.object,
};

export { TableRow };
