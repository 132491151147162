import React from 'react';
import { Badge, NavCard } from 'componentsV2';
import { Icon } from '@blueprintjs/core';
import './css/useStudentStatus.scss';
import { CheckinGuardianStatus } from '../../Guardians/GuardiansCheckin/CheckinSteps/Shared';

export const useStudentStatus = (
	title,
	subtitle,
	item,
	handleChange,
	currentSelectedStudent,
	hasRemoveIcon = false,
	onRemoveAction = () => {},
	selectedStudents = [],
	customDisabled = null,
) => {
	const rightElement = () => {
		return (
			<span className="badgesWrapper">
				<CheckinGuardianStatus item={item} disabled={customDisabled} />
				{item.guardianCheckIn?.length > 0 && (
					<span className="AssociatedWrapper">
						<Badge
							type="Gray"
							size="md"
							text={`Associated with ${item.guardianCheckIn[0].name}`}
						/>
					</span>
				)}
				{hasRemoveIcon && (
					<Icon size={16} className="cross-icon" icon="cross" onClick={onRemoveAction} />
				)}
			</span>
		);
	};

	return (
		<div className="NavWrapper">
			<NavCard
				{...{
					name: subtitle ? title : <div className="mt-2">{title}</div>,
					key: item.id,
					subtext: subtitle,
					selected: item.id
						? item.id === currentSelectedStudent?.id
						: item.personId === currentSelectedStudent?.personId,
					onClick: () => handleChange(),
					rightElement: subtitle ? (
						rightElement()
					) : (
						<div className="mb-2"> {rightElement()}</div>
					),
					showIcon: false,
					disabled:
						item.reunified ||
						selectedStudents?.find(student => student.id == item.id) ||
						customDisabled,
				}}
			/>
		</div>
	);
};
