import React, { useState, createContext, useReducer, useContext } from 'react';
import { ToasterContext } from 'pages/App';
import { RemoveContentModal, RemoveContentModalFn } from '../modals';
import { REMOVE_MODAL } from '../ShapeContentItemsList/ContentConstansts';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from 'react-redux';

export const ShapeContentContext = createContext();

export const ShapeContentProvider = ({
	children,
	history,
	item,
	itemType,
	shapeType,
	shapeId,
	siteId,
	loadContentData,
}) => {
	const [modalToShow, setModalToShow] = useState(null);
	const [loading, setLoading] = useState(false);
	const toaster = useContext(ToasterContext);

	const modalDataReducer = (state, action) => {
		switch (action.type) {
			case 'Remove':
				return {
					title: action.title,
					description: action.description,
					confirmBtnTitle: action.confirmBtnTitle,
					cancelBtnTitle: action.cancelBtnTitle,
				};
			default:
				return state;
		}
	};

	const [modalData, dispatchModalData] = useReducer(modalDataReducer, {
		title: '',
		description: '',
		confirmBtnTitle: '',
		cancelBtnTitle: '',
	});

	const closeModal = () => {
		setModalToShow(null);
	};

	const { showRemoveContentModal, handleRemoveContent } = RemoveContentModalFn(
		REMOVE_MODAL,
		setLoading,
		closeModal,
		toaster,
		setModalToShow,
		dispatchModalData,
		item,
		itemType,
		shapeType,
		shapeId,
		siteId,
		loadContentData,
	);

	const modalFunctions = {
		// use modal functions here
		showRemoveContentModal,
		handleRemoveContent,
	};

	return (
		<ShapeContentContext.Provider value={modalFunctions}>
			{children}
			<RemoveContentModal
				modalToShow={modalToShow}
				loading={loading}
				closeModal={closeModal}
				handleRemoveContent={handleRemoveContent}
				modalData={modalData}
			/>
		</ShapeContentContext.Provider>
	);
};
