export { log } from './logger';
export { tinyToObject } from './tinyToObject';
export { stripTags } from './stripTags';
export { stringToVarName } from './stringtovarname';
export {
	parseDataModel,
	validateTemplateSections,
	validateTemplateSubsection,
} from './parsedatamodel';
export { getFields } from './getfields';
export { removeVariables } from './removevariables';
export { decodeToken } from './decode';
export * as flags from './flags';

export const isIterable = obj => {
	// checks for null and undefined
	if (!obj) {
		return false;
	}
	return typeof obj[Symbol.iterator] === 'function';
};

export const allowedExtensions =
	'File formats supported: (pdf, png, jpg, jpeg, gif, txt, docs, xlsx, xls) Max 10MB file size.';

export const isInt = value =>
	!isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));

export const checkIsNavigateEmail = email => {
	// Extract the domain from the email
	const domain = email.split('@')[1];
	// Check if the domain matches navigate360.com
	return domain === 'navigate360.com';
};

// This is a sorting function that will sort items in ascending order. Used in drills to show the drill types in a sorted manner.
// Used in the modals for drills while adding and scheduling new drills.
export const sortDrills = data =>
	data.sort((a, b) => {
		const x = a.name.toUpperCase();
		const y = b.name.toUpperCase();
		if (x < y) {
			return -1;
		}
		if (x > y) {
			return 1;
		}
		return 0;
	});
