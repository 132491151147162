import { HttpService } from 'networking/HttpService';

export const BinderService = {
	getAll: async (params = {}) => {
		let query = '';
		if (params.page) {
			query += `page=${params.page}`;
		}
		if (params.perPage) {
			query += `&perPage=${params.perPage}`;
		}
		if (params.sortKey) {
			query += `&sortKey=${params.sortKey}`;
		}
		if (params.sortDir) {
			query += `&sortDir=${params.sortDir}`;
		}
		if (params.archivedOnly) {
			query += `&archivedOnly=${params.archivedOnly}`;
		}
		if (params.name) {
			query += `&name=${params.name}`;
		}
		if (params.buildingIds && params.buildingIds.length > 0) {
			params.buildingIds.forEach(buildingId => {
				query += `&buildingIds[]=${buildingId}`;
			});
		}
		if (params.includeVisibleToAll) {
			query += `&includeVisibleToAll=${params.includeVisibleToAll}`;
		}

		return await HttpService({
			method: 'GET',
			route: `/binders?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	attachContentToBinder: async (contents, binderId) => {
		return await HttpService({
			method: 'POST',
			route: `/binders/${binderId}/attach-multiple-contents`,
			body: {
				contents: contents,
			},
		});
	},
	getBinderDetails: async (id, params) => {
		let query = '';
		if (params.name) {
			query += `content_name=${params.name}`;
		}
		if (params.types && params.types.length > 0) {
			params.types.forEach(type => {
				query += `&content_type[]=${type}`;
			});
		}
		return await HttpService({
			method: 'GET',
			route: `/binders/${id}?${query}`,
			headers: {
				'Content-Type': 'application/json',
			},
		}).catch(err => {
			throw err;
		});
	},

	download: async (id, floorplanId = null) => {
		try {
			return await HttpService({
				method: 'GET',
				route: `/binders/${id}/pdf${floorplanId ? `?map_id=${floorplanId}` : ''}`,
			});
		} catch (error) {
			throw error;
		}
	},

	add: async params => {
		try {
			return await HttpService({
				method: 'POST',
				route: `/binders`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: params,
			});
		} catch (error) {
			throw error;
		}
	},

	update: async (id, params) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/binders/${id}`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: params,
			});
		} catch (error) {
			throw error;
		}
	},

	delete: async bindersIds => {
		try {
			return await HttpService({
				method: 'DELETE',
				route: `/binders`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: {
					binders_ids: bindersIds,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	copy: async id => {
		try {
			return await HttpService({
				method: 'POST',
				route: `/binders/${id}/copy`,
			});
		} catch (error) {
			throw error;
		}
	},
	archive: async (bindersIds, archive) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/binders/toggle-archive`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: {
					binders_ids: bindersIds,
					archive: archive,
				},
			});
		} catch (error) {
			throw error;
		}
	},

	renameContent: async params => {
		return await HttpService({
			method: 'PUT',
			route: `/documents/${params.id}/rename`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				type: params.type,
				title: params.title,
			},
		}).catch(err => {
			throw err;
		});
	},

	updatePrintOptions: async (id, params) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/binders/${id}/print-options`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: params,
			});
		} catch (error) {
			throw error;
		}
	},

	deleteContent: async (binderId, contentIds) => {
		return await HttpService({
			method: 'DELETE',
			route: `/binders/${binderId}/contents`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				ids: contentIds,
			},
		}).catch(err => {
			throw err;
		});
	},
	reorderContent: async (id, params) => {
		try {
			return await HttpService({
				method: 'PUT',
				route: `/binders/${id}/contents-reorder`,
				headers: {
					'Content-Type': 'application/json',
				},
				body: params,
			});
		} catch (error) {
			throw error;
		}
	},
};
