import React,{useState,useContext, useEffect} from "react";
import { FormModal,Button ,FeaturedIcon,Intent,ColorPicker,Input } from "componentsV2";
import {FloorplanService} from 'services/FloorplanService';
import {ToasterContext} from 'pages/App';
import "./css/EditLegendsModal.scss";

export const EditLegendsModal = ({
    showEditLegendsModal,
    setShowEditLegendsModal ,
    legendList,
    LegendHasUpdate
}) => {

    const [loading, setLoading] = useState(false);
    const [disableSave,setDisableSave] = useState(true);
    const [newList,setNewList] = useState(legendList);
    const toaster = useContext(ToasterContext);

    const leftFooter = (

            <Button
                disabled={false}
                large
                text={'Reset changes'}
                icon="reset"
                type={'ghostDefault'}
                size="lg"
                intent="default"
                onClick={()=>handleReset()}
                className="reset-button"
            />
    )

    useEffect(()=> {
        setNewList(legendList);
    },[legendList])

    const handleSave = (e) => {
        e.preventDefault();
        setLoading(true)
         FloorplanService.editLegends(newList)
         .then(resp => {
            if(resp.statusCode === 200)
            {
                toaster('You have successfully updated your legend ', Intent.SUCCESS,<FeaturedIcon icon={'tick'} type={'Success'} size={'md'} shape={'circleIcon'}/>, 2000, false, 'map-view-page')
                handleCloseModal();
                LegendHasUpdate();
            }
         })
         .catch(error => {
            console.error('Error updating Legends:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleCloseModal =() => {
        setShowEditLegendsModal(false);
        setDisableSave(true);
    }

    const handleReset = () => {

        setNewList(legendList);
        setDisableSave(true);
    }


    
    const replaceItem = (id, value,type) => {

        const updatedItems = newList.map(item => {
          if (item.id === id) {

            if(type === 'color') {

                return { ...item, hex: value }
            } else {
                return { ...item, description: value};
            }
          }
          return item;
        });
    
        setNewList(updatedItems);
      };

    const handleLegendChange = (event,legendId,type) => {
            event = (type ==='color') ? event.slice(1) : event.target.value; 
            setDisableSave(false);
            replaceItem(legendId,event,type);
    }

    return (
        <>
        {legendList && (
            <FormModal
            isOpen={showEditLegendsModal}
            onClose={() => handleCloseModal()}
            title='Edit legends'
            size='small'
            loading={loading}
            leftFooter = {leftFooter}
            confirmBtn={{
                title: 'Update',
                onClick: handleSave,
                disabled: disableSave
            }}
            cancelBtn={{
             title:'Cancel',
             onClick: handleCloseModal
            }}
            customClassName = {'edit-legend-container map-container-modals'}
            subtitle = {'Any changes apply to all of your Navigate360 floorplans.'}
            scrollContent={false}
            > 
            {newList && (
                
                newList.map(item => (
                    <div className = "legend-row" key={item.id}>
                        <ColorPicker
                            key={`color-picker-${item.id}`}
                            selectedColor={'#'+item.hex}
                            setSelectedColor={(e) => handleLegendChange(e,item.id,'color')}
                            isSelected={true}
                            singleColor={true}
                        />
                        <Input 
                            value = {(item.description) ? item.description : ''}
                            placeholder = {'Enter a name'}
                            large
                            width = {'100%'}
                            onChange = {(e) => handleLegendChange(e,item.id,'description')}
                        />
                    </div>

                    
                ))
            )}
            </FormModal>
    )}
   </>

    );


}