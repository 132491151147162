import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { validateTemplateSubsection } from 'utilities';
import { FileUpload, LoadingSpinner, Checkbox, FeaturedIcon } from 'componentsV2';
import { PlanService } from 'services/PlanService';
import { TemplateService } from 'services/TemplateService';
import './css/FileSection.scss';
import { MAX_FILE_UPLOADER_SIZE } from 'utilities/constants';


class FileSection extends React.Component {
	state = {
		showButtons:false,
		inputFile: null,
		loading:true,
		showProgressBar: false,
		isChecked : (this.props.subsection.baseFile.id ? true : false),
	};

	parseFileData = (file, type) => {
		
		return {
			id: file.id,
			name: file.name,
			size: file.size,
			type: this.getMimiType(file?.name.substr(file.name?.lastIndexOf('.') + 1)),
			filetype: type

		}
	}

	getMimiType = (ext) => {
		const images = ['png', 'jpeg', 'jpg']
		if (images.includes(ext)) {
			return 'image/'+ ext
		}
		return null
	}

	componentDidMount = async () => {
		const {
			subsection: { id, reupload, baseFile, valueFile },
			type,
			sectionIndex,
			subsectionIndex,
			dispatch
		} = this.props;
		let parsedFile = null;
		if (type === 'corePlan') {

			if (reupload && !baseFile.name) { //TO FIX DATA ISSUE
				let allowReupload = false;
				dispatch({
					type: 'FLIP_REUPLOAD',
					sectionIndex,
					subsectionIndex,
					allowReupload
				});
			}
		}
		
		if(valueFile.id) {
			parsedFile = await this.parseFileData(valueFile, 'valueFile');
		} else if (baseFile.id) {
			
			parsedFile = await this.parseFileData(baseFile, 'baseFile');
		}

		this.setState({
			inputFile: parsedFile,
			loading: false
		})	
	}

	deleteFile = deletedFile => {
		const {
			templateId,
			dispatch,
			sectionIndex,
			subsectionIndex,
			type,
			updateAutoSave
		} = this.props;
	
		if(type === 'districtPlan') {
			PlanService.deleteFile(deletedFile.id, templateId).then(async (resp) => {
				await dispatch({
					type: 'DELETE_VALUE_FILE',
					sectionIndex,
					subsectionIndex,
				});
			});
		} else {
			const {
				subsection: { baseFile },
				saveTemplate
			} = this.props;

			if(baseFile.id){
				TemplateService.deleteFile(baseFile.id, templateId).then(async (response) => {
					if (response.statusCode === 200) {
						await dispatch({
							type: 'DELETE_FILE',
							sectionIndex,
							subsectionIndex,
						});
						await saveTemplate(false, true);
						this.setState({
							inputFile: null,
							isChecked: false
						})	
					}
				})
			}
		}

	};
	publishUpdate = () => {
		const {
		templateId,
		sectionIndex,
		subsectionIndex,
		actualSectionId,
		actualSubsectionId,
		template,
		subsection,
		subsection: { reupload, baseFile },
		} = this.props;
		// should always be true -- publish is for templates only
		const errorObj = validateTemplateSubsection(
		subsection,
		actualSectionId,
		true,
		null,
		template.name,
		);
		if (
		!errorObj.issue ||
		(!reupload && errorObj.issue === 'missingUpload')
		) {

			Swal.fire({
				title: 'Publish update?',
				text: 'You are about to publish an update for this section',
				icon: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'bp3-button bp3-intent-danger',
					cancelButton: 'bp3-button',
				},
				confirmButtonText: 'Publish',
				reverseButtons: true,
			}).then(result => {
				if (result.value) {
					Swal.fire('Publishing');
					Swal.showLoading();
					const token = JSON.parse(localStorage.getItem('user')).jwt;
					const tempcontent = '';
					let subsectionTitle;
					if (actualSubsectionId) {
					template.sections[sectionIndex].subsections[subsectionIndex].content = tempcontent;
					subsectionTitle = template.sections[sectionIndex].subsections[subsectionIndex].title;
					} else {
					template.sections[sectionIndex].content = tempcontent;
					subsectionTitle = template.sections[sectionIndex].title;
					}
					let updatedContent;
					if(reupload) {
						updatedContent = {
							actualSectionId,
							actualSubsectionId,
							content: '',
							is_document:true,
							reupload: true
						};
						if(!!baseFile?.name) {
							updatedContent['baseFile'] = baseFile;
						}
					} else {
						updatedContent = {
							actualSectionId,
							actualSubsectionId,
							content: '',
							is_document:true,
							isClient:1
						};
					}
					updatedContent.index = actualSubsectionId ? subsectionIndex : sectionIndex;
					updatedContent.title = subsectionTitle;
					return fetch(
						`${
							process.env.API_URL
						}/district-templates/${templateId}/updates`,
						{
							method: 'POST',
							headers: {
								Authorization: `Bearer ${token}`,
							},
							body: JSON.stringify({
								type: 'update',
								updatedContent: JSON.stringify(updatedContent),
								actualSectionId,
								actualSubsectionId,
							}),
						},
					)
						.then(res => res.json())
						.then(resp => {
							if (resp.statusCode === 201) {
								fetch(
									`${
										process.env.API_URL
									}/district-templates/${parseInt(template.id, 10)}`,
									{
										method: 'PUT',
										headers: {
											'Content-Type': 'app/json',
											Authorization: `Bearer ${token}`,
										},
										body: JSON.stringify({
											content: JSON.stringify(
												template.sections,
											),
											name: template.name,
											state: template.state,
										}),
									},
								)
									.then(res => res.json())
									.then(rsp => {
										if (rsp.statusCode === 200) {
											Swal.close();
											Swal.fire({
												title: 'Done!',
												icon: 'success',
												timer: 1000,
												showConfirmButton: false,
											}).then(() => window.location.reload());
										}
									});
							}
							return null;
						});
				}
				return Swal.close();
			});
		} else {
		Swal.fire({
			html: errorObj.messageHTML,
			icon: 'warning',
			showCancelButton: false,
			confirmButtonText: 'ok',
			reverseButtons: true,
			returnFocus: false,
			onAfterClose() {
			const element = document.getElementById('subsection-' + actualSectionId + '-' + actualSubsectionId);
			if (!!element) {
				element.scrollIntoView({
				block: 'start',
				behavior: 'smooth',
				});
			}
			}
		}).then(result => {
			if (result.value) {
			return Swal.close();
			}
		});
		}
	};


	onFileInputChange = async(file) => {
		
		const {
			template,
			dispatch,
			sectionIndex,
			subsectionIndex,
			type,
			saveAndReload,
			saveTemplate,
			updateAutoSave
		} = this.props;
		this.setState({
			showProgressBar: true
		});

		if(file.name) {
			if(type === 'districtPlan') {
				PlanService.uploadFile(file, template.id).then(async (resp) => {
					if (resp.statusCode === 201) {
						const fileResponse = {
							...resp.data,
							name: resp.data.fileName,
							type: resp.data.contentType
						}
						const valueFile = this.parseFileData(fileResponse)
						this.setState({
							inputFile: valueFile,
							showProgressBar: false
						});
						await dispatch({
							type: 'ADD_VALUE_FILE',
							valueFile,
							sectionIndex,
							subsectionIndex,
						});
					} else  {
						this.setState({
							uploadFileErrors: resp
						})
					}
				}).catch(error => {
					this.setState({
						uploadFileErrors: error
					})
				});

			} else if (type === 'corePlan') {
				updateAutoSave(false)
				TemplateService.uploadFile(file, template.id).then(resp => {
					if (resp.statusCode === 201) {
						const fileResponse = {
							...resp.data,
							name: resp.data.fileName,
							type: resp.data.contentType,
							coreTemplate: template.id,
						}
						const baseFile = this.parseFileData(fileResponse)
						this.setState({
							inputFile: baseFile,
							showProgressBar: false
						});
						dispatch({
							type: 'ADD_FILE',
							baseFile,
							sectionIndex,
							subsectionIndex,
						});
						saveTemplate(false, true)
					} else  {
						this.setState({
							uploadFileErrors: resp
						})
					}
				}).catch(error => {
					this.setState({
						uploadFileErrors: error
					})
				});
			}
		}
	}

	
	flipReupload = () => {
		const { 
			subsection: { reupload, baseFile },
			saveTemplate,
			dispatch, sectionIndex, subsectionIndex, updateAutoSave } = 
			this.props;
		
		const {isChecked } = this.state;
		let allowReupload = false;
		if (!isChecked) { //Check It
			this.setState({isChecked: true})	
			allowReupload = true;
			updateAutoSave(false)
			saveTemplate(false, true);
		} else {
			updateAutoSave(false)
			if (baseFile.id) {
				this.deleteFile()
			}
			allowReupload = false;
			this.setState({isChecked: false})
		}
		dispatch({
			type: 'FLIP_REUPLOAD',
			sectionIndex,
			subsectionIndex,
			allowReupload
		});
	};


	render() {
		const BigIcon = styled.div`
			color: #5c7080;
			text-align: center;
			p {
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 22px;
				color: #394b59;
			}
		`;

		const {
			subsection: { reupload, baseFile, valueFile },
			openUploadModal,
			sectionIndex,
			subsectionIndex,
			viewMode,
			type,
		} = this.props;
	

		const { inputFile, loading, showProgressBar, isChecked } = this.state;

		if (valueFile.id) {
			let parsedFile = this.parseFileData(valueFile, 'valueFile');
			if (inputFile && (inputFile.id != valueFile.id)) {
				this.setState({
					inputFile: parsedFile,
				})	
			}
		} else if (baseFile.id) {
			let parsedFile = this.parseFileData(baseFile, 'baseFile');
			if (inputFile && (inputFile.id != baseFile.id)) {
				this.setState({
					inputFile: parsedFile,
					showProgressBar:false
				})	
			}
		}

		if(loading) return <LoadingSpinner />
		return (
			<div className='file-section-container'>
				{type === 'corePlan' && (
					<div className='reupload_section'>
						{!viewMode && <Checkbox 
							label="Allow document download & re-upload"
							onChange={this.flipReupload}
							checked={isChecked}
						/>}
						{!isChecked && (
								<BigIcon>
									<FeaturedIcon icon="document"  shape={'roundedIcon'} type="Default"/>
									<p className='UserUploadHint'>Users will upload their document here</p>
								</BigIcon>
							)}
					</div>
				)}
				<div className='file_section'>
					{(type === 'districtPlan' ? true : isChecked) && (
						<FileUpload
							identifier={`file-${sectionIndex}-${subsectionIndex}`}
							labelState = {false}
							allowedFiles = {['pdf', 'png', 'jpeg', 'jpg', 'txt', 'gif', 'doc', 'docx', 'xlsx', 'xls']}
							allowedFilesLegend = {`PDF, PNG, JPEG, GIF, TXT, DOCS, XLSX or XLS (max. ${MAX_FILE_UPLOADER_SIZE}mb)`}
							defaultErrorMessage = 'Upload failed, please try again'
							maxFileSize={MAX_FILE_UPLOADER_SIZE}
							file={inputFile}
							fileForEditing={true}
							showProgressBar={showProgressBar}
							onFileInputChange = {this.onFileInputChange}
							onDeleteFile={this.deleteFile}
							showDeleteBtn={!viewMode && (!isChecked || type === 'corePlan' || inputFile.filetype === 'valueFile')}
							downloadText = 'Download and re-upload signed document'
							showDownloadAction={!viewMode && isChecked && type === 'districtPlan' && inputFile.filetype == 'baseFile'}
							showReplaceAction={!viewMode && (!isChecked || type === 'corePlan' || inputFile.filetype === 'valueFile')}
							onDownloadClickHandler={() => openUploadModal(true, baseFile, valueFile, sectionIndex,subsectionIndex)}
							disabled={viewMode ? true :  false}
						/>
					)}
				</div>
			</div>
		);
	}
}

FileSection.propTypes = {
	subsection: PropTypes.object,
	openUploadModal: PropTypes.func,
	sectionIndex: PropTypes.number,
	subsectionIndex: PropTypes.number,
	templateId: PropTypes.string,
	dispatch: PropTypes.func,
};

export { FileSection };
