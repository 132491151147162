import React, { useState, createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { BinderService } from 'services/BinderService';
import { ToasterContext } from 'pages/App';
import { Intent, Modal, Input, FormModal, ConfirmationModal, LoadingModal } from 'componentsV2';
import { AttachContentModal } from './AttachContentModal';
import { ReplaceContentModal } from './BinderDetails/components/ReplaceContentModal';
import { BINDER_EDIT_PATH } from './';
import { UploadNewFileModal } from './BinderDetails/components/UploadNewFileModal';
import { Helper } from 'utilities/helper';
import { DownloadModal, DownloadModalFn } from '../../Documents/components/ProviderComponents';

export const BinderModalsContext = createContext();

export const BinderModalsProvider = ({ children, history, reloadBinderDetails, reloadList }) => {
	const [modalToShow, setModalToShow] = useState(null);
	const [loading, setLoading] = useState(false);
	const [oldBinderContentTitle, setOldBinderContentTitle] = useState(null);
	const [binderId, setBinderId] = useState(null);
	const [showTabPreview, setShowTabPreview] = useState(false);
	const [binderDetails, setBinderDetails] = useState();
	const [showReplaceModal, setShowReplaceModal] = useState(false);
	const [selectedContentToReplace, setSelectedContentToReplace] = useState();
	const [showUploadModal, setShowUploadModal] = useState(false);
	// Documents
	const [selectedDownloadOption, setSelectedDownloadOption] = useState('1');

	const formDataReducer = (formData, action) => {
		formData = action.payload;
		return {
			title: formData.title,
			id: formData.id, // contentId in the binderContentTable
			type: formData.type.toLowerCase(),
			VBContentId: formData.VBContentId, // id in the binderContentTable
		};
	};
	const initialState = {
		title: null,
		id: null,
		type: null,
		VBContentId: null,
	};
	const [binderContent, dispatchFormData] = useReducer(formDataReducer, initialState);
	const ARCHIVE_MODAL = 'archiveModal';
	const DELETE_MODAL = 'deleteModal';

	const closeModal = () => {
		setModalToShow(null);
	};
	const binderDataReducer = (binderData, action) => {
		return {
			id: action.id,
			name: action.name,
			modalTitle: action.modalTitle,
			modalSubTitle: action.modalSubTitle,
			confirmBtnTitle: action.confirmBtnTitle,
			onConfirmAction: action.onConfirmAction,
		};
	};

	const [binderData, dispatchBinderData] = useReducer(binderDataReducer, {
		id: null,
		name: '',
		modalTitle: '',
		modalSubTitle: '',
		confirmBtnTitle: '',
		onConfirmAction: () => {},
	});

	const documentDataReducer = (documentData, action) => {
		return {
			id: action.id,
			title: action.title,
			modalTitle: action.modalTitle,
			modalSubTitle: action.modalSubTitle,
			confirmBtnTitle: action.confirmBtnTitle,
			onConfirmAction: action.onConfirmAction,
			ext: action.ext,
		};
	};

	const [documentData, dispatchDocumentData] = useReducer(documentDataReducer, {
		id: null,
		title: '',
		modalTitle: '',
		modalSubTitle: '',
		confirmBtnTitle: '',
		ext: '',
		onConfirmAction: () => {},
	});

	const confirmArchiveModal = async binder => {
		setLoading(true);
		await BinderService.archive([binder.id], !binder.archivedByUser ? true : false)
			.then(response => {
				if (response.statusCode === 200) {
					toaster(
						`You have successfully ${
							binder.archivedByUser ? 'unarchived' : 'archived'
						} "${binder.name}"!`,
						Intent.SUCCESS,
					);
					if (reloadList) {
						reloadList();
					}
					if (reloadBinderDetails) {
						reloadBinderDetails(binder.id);
					}
				}
			})
			.catch(err => {
				toaster(err.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	const confirmArchiveMultiModal = async (binders, isArchiveAction) => {
		setLoading(true);

		let itemsSelected =
			binders.length == 1 ? `"${binders[0].name}"` : `${binders.length} binders`;

		await BinderService.archive(binders.map(binder => binder.id), isArchiveAction)
			.then(response => {
				if (response.statusCode === 200) {
					toaster(
						`You have successfully ${
							isArchiveAction ? 'archived' : 'unarchived'
						} ${itemsSelected}!`,
						Intent.SUCCESS,
					);
					reloadList();
				}
			})
			.catch(err => {
				toaster(err.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	const confirmDeleteModal = async binder => {
		setLoading(true);
		await BinderService.delete([binder.id])
			.then(response => {
				if (response.statusCode === 200) {
					toaster(`You have successfully deleted "${binder.name}"!`, Intent.SUCCESS);
					if (reloadList) {
						reloadList();
					}
					if (reloadBinderDetails) {
						history.push(`/binders`);
					}
				}
			})
			.catch(err => {
				toaster(err.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	const confirmDeleteMultiModal = async binders => {
		setLoading(true);

		let itemsSelected =
			binders.length == 1 ? `"${binders[0].name}"` : `${binders.length} binders`;

		await BinderService.delete(binders.map(binder => binder.id))
			.then(response => {
				if (response.statusCode === 200) {
					toaster(`You have successfully deleted ${itemsSelected}!`, Intent.SUCCESS);
					reloadList();
				}
			})
			.catch(err => {
				toaster(err.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	const handlConfirmCopyAction = async () => {
		setLoading(true);
		BinderService.copy(binderData.id)
			.then(response => {
				if (response.statusCode === 200) {
					toaster(
						'You have successfully copied "' + binderData.name + '"!',
						Intent.SUCCESS,
					);
					const copiedBinderId = response.data.id;
					if (reloadBinderDetails) {
						reloadBinderDetails(copiedBinderId);
					}
					history.push(`/${BINDER_EDIT_PATH}/${copiedBinderId}`);
				}
			})
			.catch(err => {
				toaster(err.error?.description, Intent.DANGER);
			})
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	const handlDeleteContentAction = async (
		isMultipleDeleteContent,
		binderId,
		ids,
		selectedContentTitle,
	) => {
		setLoading(true);
		await BinderService.deleteContent(binderId, ids)
			.then(response => {
				const successMsg = isMultipleDeleteContent
					? 'You have successfully deleted ' +
					  (ids.length > 1
							? ids.length + ' contents from the binder!'
							: '"' + selectedContentTitle + '"!')
					: 'You have successfully deleted "' + selectedContentTitle + '"!';
				toaster(successMsg, Intent.SUCCESS, '', 3000);
				reloadBinderDetails(binderId);
			})
			.catch(err => {
				if (err.error?.fields) {
					toaster(err.error.fields?.content_team_id, Intent.DANGER, '', 3000);
				} else {
					toaster(err.error?.description, Intent.DANGER, '', 3000);
				}
			})
			.finally(() => {
				setLoading(false);
				closeModal();
			});
	};

	const toaster = useContext(ToasterContext);
	const COPY_MODAL = 'copyModal';
	const DELETE_CONTENT_MODAL = 'deleteContentModal';
	const cancelBtn = { title: 'No, go back', onClick: closeModal };
	const confirmCopyBtn = { title: 'Yes, copy it', onClick: handlConfirmCopyAction };
	const deleteIcon = { icon: 'trash', iconColor: 'error' };

	const downloadBinder = (binder, floorplanId = null) => {
		setLoading(true);
		setModalToShow('downloadBinderModal');
		BinderService.download(binder.id, floorplanId)
			.then(response => {
				var a = document.createElement('a');
				a.href = 'data:application/pdf;base64,' + response.data; //File Base64 Goes here
				a.download = binder.name + '.pdf';
				a.click();
				a.remove();
			})
			.catch(err => {
				setModalToShow('');
				setLoading(false);
				toaster(err.error?.description, Intent.DANGER, '', 3000);
			})
			.finally(() => {
				setModalToShow('');
				closeModal();
				setLoading(false);
			});
	};

	const showCopyModal = row => {
		setModalToShow(COPY_MODAL);
		dispatchBinderData({
			id: row.id,
			name: row.name,
		});
	};

	const showArchiveModal = binder => {
		setModalToShow(ARCHIVE_MODAL);
		const isArchived = binder.archivedByUser;
		dispatchBinderData({
			modalTitle: `Are you sure you want to ${isArchived ? 'unarchive' : 'archive'} "${
				binder.name
			}"?`,
			modalSubTitle: `You can ${isArchived ? 'archive' : 'unarchive'} it at a later time.`,
			confirmBtnTitle: `Yes, ${isArchived ? 'unarchive' : 'archive'} it`,
			onConfirmAction: () => confirmArchiveModal(binder),
		});
	};

	const showArchiveMultipleModal = (binders, isArchiveAction) => {
		setModalToShow(ARCHIVE_MODAL);
		let itemsSelected =
			binders.length == 1 ? `"${binders[0].name}"` : `${binders.length} binders`;

		let buttonElements = binders.length == 1 ? `it` : `them`;

		dispatchBinderData({
			modalTitle: `Are you sure you want to ${
				isArchiveAction ? 'archive' : 'unarchive'
			} ${itemsSelected}?`,
			modalSubTitle: `You can ${
				isArchiveAction ? 'unarchive' : 'archive'
			} ${buttonElements} at a later time.`,
			confirmBtnTitle: `Yes, ${isArchiveAction ? 'archive' : 'unarchive'} ${buttonElements}`,
			onConfirmAction: () => confirmArchiveMultiModal(binders, isArchiveAction),
		});
	};

	const showDeleteModal = binder => {
		setModalToShow(DELETE_MODAL);
		dispatchBinderData({
			modalTitle: `Are you sure you want to delete "${binder.name}"?`,
			modalSubTitle: `Once deleted, this cannot be recovered.`,
			confirmBtnTitle: `Yes, delete it`,
			onConfirmAction: () => confirmDeleteModal(binder),
		});
	};

	const showDeleteMultipleModal = binders => {
		setModalToShow(DELETE_MODAL);
		let itemsSelected =
			binders.length == 1 ? `"${binders[0].name}"` : `${binders.length} binders`;

		let buttonElements = binders.length == 1 ? `it` : `them`;

		dispatchBinderData({
			modalTitle: `Are you sure you want to delete ${itemsSelected}?`,
			modalSubTitle: `Once deleted, this cannot be recovered.`,
			confirmBtnTitle: `Yes, delete ${buttonElements}`,
			onConfirmAction: () => confirmDeleteMultiModal(binders),
		});
	};

	const showDeleteContentModal = (binderContent, binderId) => {
		dispatchFormData({
			payload: {
				...binderContent,
				title: binderContent.title,
				VBContentId: binderContent.id,
			},
		});
		dispatchBinderData({
			modalTitle: 'Are you sure you want to delete "' + binderContent.title + '"?',
			confirmBtnTitle: 'Yes, delete it',
			onConfirmAction: () =>
				handlDeleteContentAction(false, binderId, [binderContent.id], binderContent.title),
		});
		setModalToShow(DELETE_CONTENT_MODAL);
	};

	const showMultipleDeleteContentModal = (binderContentsIds, binderId, binderData) => {
		const selectedContent = binderData.filter(
			item => item.VBContentId === binderContentsIds[0],
		);
		const binderContentTitle =
			selectedContent.length != 0 ? selectedContent[0]['fileName'] : '';
		dispatchBinderData({
			modalTitle:
				'Are you sure you want to delete ' +
				(binderContentsIds.length > 1
					? binderContentsIds.length + ' contents?'
					: '"' + binderContentTitle + '"?'),
			confirmBtnTitle: 'Yes, delete ' + (binderContentsIds.length > 1 ? 'them' : 'it'),
			onConfirmAction: () =>
				handlDeleteContentAction(true, binderId, binderContentsIds, binderContentTitle),
		});
		setModalToShow(DELETE_CONTENT_MODAL);
	};

	const renameDocument = (data, binderId) => {
		dispatchFormData({ payload: { ...data, title: data.title, id: data.contentId } });
		setOldBinderContentTitle(data.title);
		setBinderId(binderId);
		setModalToShow('renameDocumentModal');
	};
	const handleReplaceContent = (data, binderId) => {
		setShowReplaceModal(true);
		setBinderId(binderId);
		setSelectedContentToReplace(data);
	};

	const handleAttachContentModal = binderDetails => {
		setShowTabPreview(true);
		setBinderDetails(binderDetails);
	};
	const handleUploadNewFile = binderDetails => {
		setShowUploadModal(true);
		setBinderDetails(binderDetails);
	};

	const setBinderContentTitle = e => {
		dispatchFormData({ payload: { ...binderContent, title: e.target.value } });
	};

	const renameFormSubmitHandler = () => {
		if (!!binderContent.title) {
			setLoading(true);
			BinderService.renameContent(binderContent)
				.then(response => {
					toaster(
						`You have successfully renamed "${oldBinderContentTitle}"!`,
						Intent.SUCCESS,
					);
					closeModal();
					reloadBinderDetails(binderId);
				})
				.catch(error => {
					if(error.error.type == 'INSUFFICIENT_PRIVILEGES'){
						toaster("You don't have permission to rename this document. Please contact your administrator.", Intent.DANGER);
						closeModal();
					}
					const errorMessage = error.error.fields.type || error.error.fields.title;
					toaster(Helper.capitalizeFirstLetter(errorMessage), Intent.DANGER);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const { showDownloadModal, handleDownloadOption } = DownloadModalFn(
		modalToShow,
		loading,
		setLoading,
		documentData,
		dispatchDocumentData,
		closeModal,
		selectedDownloadOption,
		toaster,
		setSelectedDownloadOption,
		setModalToShow,
	);

	const modalFunctions = {
		// use modal functions here
		downloadBinder,
		renameDocument,
		showCopyModal,
		handleAttachContentModal,
		handleReplaceContent,
		showDeleteContentModal,
		showArchiveModal,
		showArchiveMultipleModal,
		showDeleteModal,
		showDeleteMultipleModal,
		handleUploadNewFile,
		showMultipleDeleteContentModal,
		showDownloadModal,
	};

	return (
		<BinderModalsContext.Provider value={modalFunctions}>
			<>
				{showTabPreview && (
					<AttachContentModal
						Binder={binderDetails}
						showTabPreview={showTabPreview}
						setShowTabPreview={setShowTabPreview}
						reloadBinderDetails={reloadBinderDetails}
					/>
				)}
				<ReplaceContentModal
					selectedContentToReplace={selectedContentToReplace}
					showReplaceModal={showReplaceModal}
					setShowReplaceModal={setShowReplaceModal}
					binderId={binderId}
					reloadBinderDetails={reloadBinderDetails}
				/>

				<UploadNewFileModal
					showUploadModal={showUploadModal}
					setShowUploadModal={setShowUploadModal}
					binderDetails={binderDetails}
					reloadBinderDetails={reloadBinderDetails}
				/>
				<LoadingModal
					isOpen={modalToShow === 'automaticDocumentModal'}
					loading={loading}
					onClose={closeModal}
					customClassName={'map-container-modals'}
				/>
				<LoadingModal
					isOpen={modalToShow === 'downloadBinderModal'}
					loading={loading}
					onClose={closeModal}
					customClassName={'map-container-modals'}
				/>

				<ConfirmationModal
					isOpen={modalToShow === COPY_MODAL}
					title={
						`Are you sure you would like to make a copy of "` + binderData.name + `"?`
					}
					subtitle=""
					showCloseButton={false}
					cancelBtn={cancelBtn}
					confirmBtn={confirmCopyBtn}
					loading={loading}
					btnsSize="lg"
				/>

				<DownloadModal
					modalToShow={modalToShow}
					loading={loading}
					setLoading={setLoading}
					documentData={documentData}
					dispatchDocumentData={dispatchDocumentData}
					closeModal={closeModal}
					toaster={toaster}
					selectedDownloadOption={selectedDownloadOption}
					setSelectedDownloadOption={setSelectedDownloadOption}
					setModalToShow={setModalToShow}
					handleDownloadOption={handleDownloadOption}
				/>
				<ConfirmationModal
					isOpen={modalToShow === DELETE_CONTENT_MODAL}
					title={binderData.modalTitle}
					subtitle="Once deleted, this cannot be recovered."
					showCloseButton={false}
					cancelBtn={cancelBtn}
					confirmBtn={{
						title: binderData.confirmBtnTitle,
						onClick: binderData.onConfirmAction,
						type: 'primaryDanger',
					}}
					loading={loading}
					btnsSize="lg"
					icon={deleteIcon}
				/>
				<ConfirmationModal
					isOpen={modalToShow === ARCHIVE_MODAL}
					title={binderData.modalTitle}
					subtitle={binderData.modalSubTitle}
					showCloseButton={false}
					cancelBtn={cancelBtn}
					confirmBtn={{
						title: binderData.confirmBtnTitle,
						onClick: binderData.onConfirmAction,
					}}
					loading={loading}
					btnsSize="lg"
				/>
				<ConfirmationModal
					isOpen={modalToShow === DELETE_MODAL}
					title={binderData.modalTitle}
					subtitle={binderData.modalSubTitle}
					showCloseButton={false}
					cancelBtn={cancelBtn}
					confirmBtn={{
						title: binderData.confirmBtnTitle,
						type: 'primaryDanger',
						onClick: binderData.onConfirmAction,
					}}
					loading={loading}
					btnsSize="lg"
					icon={{
						icon: 'trash',
						iconColor: 'error',
					}}
				/>
				{binderContent.id && (
					<FormModal
						isOpen={modalToShow === 'renameDocumentModal'}
						onClose={closeModal}
						title={'Rename document'}
						showCloseButton={true}
						confirmBtn={{
							title: 'Update',
							disabled: !binderContent.title,
							onClick: renameFormSubmitHandler,
						}}
						cancelBtn={{
							title: 'Cancel',
							onClick: closeModal,
						}}
						loading={loading}
						scrollContent={false}
					>
						<div className="col-12 mb-3">
							<Input
								value={binderContent.title}
								name="fileName"
								label={'Name'}
								placeholder={'Enter your filename here'}
								isRequired={true}
								onChange={setBinderContentTitle}
							/>
						</div>
					</FormModal>
				)}

				{children}
			</>
		</BinderModalsContext.Provider>
	);
};

BinderModalsProvider.propTypes = {
	reloadList: PropTypes.func,
	reloadBinderDetails: PropTypes.func,
};
