import React from 'react';
import DropdownButton from 'components/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Icon } from '@blueprintjs/core';
import './css/AddUserButton.scss';

const AddUserButton = ({ setModalToShow, ...props }) => {
	return (
		<DropdownButton
			className="AddUserButton__Dropdown"
			variant="primary"
			text="Add User "
			lefticon="plus"
			style={{
				backgroundColor: '#007E8F',
			}}
			{...props}
		>
			<>
				<Dropdown.Item
					className="mt-2 w-100 justify-content-start"
					onClick={() => setModalToShow('NewUserModal')}
				>
					<Icon icon="person" className="dropdown-item-icon" /> New user
				</Dropdown.Item>
				<Dropdown.Item
					className="mt-2 w-100 justify-content-start"
					onClick={() => setModalToShow('BulkUsersModal')}
				>
					<Icon icon="people" className="dropdown-item-icon" /> Multiple users
				</Dropdown.Item>
			</>
		</DropdownButton>
	);
};

export default AddUserButton;
