import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { orderBy } from 'lodash';
import { Icon } from '@blueprintjs/core';
import { TableRow } from './tablerow';
import { Sorter } from '../../../components/Sorter';

const TitleWrap = styled.div`
	display: flex;
	color: #394b59;
	align-items: center;
	margin-left: -3px;
	margin-bottom: 16px;
`;

const Title = styled.h3`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
	margin-bottom: 0;
	margin-left: 10px;
`;

const Callout = styled.div`
	display: flex;
	align-items: center;
	background: #fcf3ea;
	height: 42px;
	width: 99%;
	margin-bottom: 16px;
`;

const TableWrapper = styled.div`
	width: 110%;
`;

const Page = styled.div`
	flex-wrap: wrap;
`;

const TableTitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	margin-bottom: 0;
`;

const TableContent = styled.table`
	thead {
		tr {
			border-top: 0 !important;
			th {
				border: 0 !important;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
				color: #738694;
			}
		}
	}
	tbody {
		tr {
			th {
				font-weight: normal;
				vertical-align: middle;
			}
			td {
				&:nth-child(2),
				&:nth-child(3) {
					text-transform: capitalize;
				}
				vertical-align: middle;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				color: #10161a;
			}
			&:last-child {
				border-bottom: 1px solid #dee2e6;
			}
		}
	}
`;

class Templates extends React.Component {
	state = {
		data: [],
		filtered: [],
		sortBy: { state: 'down', name: 'down' },
	};

	getDirection = key => {
		const { sortBy } = this.state;
		if (key in sortBy) {
			return sortBy[key];
		}
		return 'down';
	};

	sortTable = key => {
		const { sortBy, data } = this.state;
		let tempDir;
		if (key in sortBy) {
			const sortDirection = sortBy[key];

			if (sortDirection === 'up') tempDir = 'down';
			if (sortDirection === 'down') tempDir = 'up';
		} else {
			tempDir = 'down';
		}

		const arrWithValues = data.filter(item => item[key] !== null);
		const diff = data.filter(item => !arrWithValues.includes(item));

		let temp = orderBy(
			arrWithValues,
			[key],
			[tempDir === 'up' ? 'asc' : 'desc'],
		);
		temp = [...temp, ...diff];

		return this.setState({
			filtered: temp,
			sortBy: { ...sortBy, [key]: tempDir },
		});
	};

	componentDidMount() {
		const { templates } = this.props;
		this.setState({ data: templates, filtered: templates });
	}

	render() {
		const {
			setEdit,
			setCopy,
			removeTemplate,
			history,
			districtId,
			permissions,
		} = this.props;
		const { filtered } = this.state;

		return (
			<Page>
				{districtId === 6880 && (
					<TitleWrap>
						<Icon icon="torch" />
						<Title>Core templates</Title>
					</TitleWrap>
				)}
				{districtId === 6880 && (
					<Callout className=".bg-warning">
						<Icon
							style={{
								marginRight: 10,
								marginLeft: 10,
								color: '#D9822B',
							}}
							icon="warning-sign"
						/>
						Note that these are core templates. Please be careful
						when editing or deleting.
					</Callout>
				)}
				<TableWrapper>
					<TableContent
						className="table"
						style={{ backgroundColor: '#fff', width: '90%' }}
					>
						<thead>
							<tr>
								<td>
									<TableTitle
										style={{ color: '#738694', width: 60 }}
									>
										Year
									</TableTitle>
								</td>
								<td>
									<Sorter
										direction={this.getDirection('name')}
										onSortClick={() =>
											this.sortTable('name')
										}
									>
										Template
									</Sorter>
								</td>
								<td>
									<Sorter
										direction={this.getDirection('state')}
										onSortClick={() =>
											this.sortTable('state')
										}
									>
										State
									</Sorter>
								</td>
								<td>
									<TableTitle style={{ color: '#738694' }}>
										Creation Date
									</TableTitle>
								</td>

								<td>
									<TableTitle style={{ color: '#738694' }}>
										Status
									</TableTitle>
								</td>

								<td>
									<TableTitle style={{ color: '#738694' }}>
										Actions
									</TableTitle>
								</td>
							</tr>
						</thead>
						<tbody>
							{filtered.map((item, index) => (
								<TableRow
									key={index}
									index={index}
									item={item}
									setCopy={setCopy}
									setEdit={setEdit}
									removeTemplate={removeTemplate}
									history={history}
									districtId={districtId}
									permissions={permissions}
								/>
							))}
						</tbody>
					</TableContent>
				</TableWrapper>
			</Page>
		);
	}
}

export default Templates;

Templates.propTypes = {
	templates: PropTypes.array,
	setEdit: PropTypes.func,
	setCopy: PropTypes.func,
	removeTemplate: PropTypes.func,
	history: PropTypes.object,
	permissions: PropTypes.object,
	districtId: PropTypes.number,
};
