import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Input,
	BootstrapedTable,
	sortingStyle,
	Checkbox,
	TableCell,
	ToasterContext,
	Tooltip,
} from 'componentsV2';
import { ReunificationStudentActionsList } from './ReunificationStudentActionsList';
import './css/ReunificationStudentsTable.scss';
import { ReunificationService } from 'services';
import { RollCallDetailsContext } from '../../../..';
import { debounce } from 'debounce';
import { Intent } from '@blueprintjs/core';
import { REUNIFICATION_STUDENT_STATUS_CHECKEDIN } from '../../consts';
import { RollCallAndReunificationContext } from '../../../RollCallAndReunification';
import { REUNIFY_STUDENT_MODAL } from '../../../Modals/consts';

export const ReunificationStudentsTable = ({
	studentsList,
	setStudentsList,
	totalStudents,
	filterData,
	setFilterData,
}) => {
	const { alarmId, studentUpdateEvent, resetStudentUpdateEvent } = useContext(
		RollCallDetailsContext,
	);
	const { setModalToShow, setSelectedStudent } = useContext(RollCallAndReunificationContext);
	const toaster = useContext(ToasterContext);

	useEffect(() => {
		//student status updated event
		if (studentUpdateEvent) {
			const eventData = studentUpdateEvent.data;
			switch (studentUpdateEvent.name) {
				case 'reunificationStudents:comment':
					updateStudentComment(eventData.studentId, eventData.note);
					break;
			}
			resetStudentUpdateEvent();
		}
	}, [studentUpdateEvent]);

	const checkInStudentHandler = (student) => {
		// update current student status
		const newStatus =
			student.checkIn?.status === REUNIFICATION_STUDENT_STATUS_CHECKEDIN
				? null
				: REUNIFICATION_STUDENT_STATUS_CHECKEDIN;
		updateStudentCheckInStatus(student.id, newStatus);
		// send new student status to api
		const students = [
			{
				id: student.id,
				status: newStatus,
			},
		];
		ReunificationService.updateStudentStatus(alarmId, students).catch((err) => {
			toaster(err?.error.description, Intent.DANGER);
		});
	};

	const showReunifyStudentModal = (student) => {
		setSelectedStudent(student);
		setModalToShow(REUNIFY_STUDENT_MODAL);
	};

	const updateCommentHandler = (student, comment) => {
		const body = {
			studentId: student.id,
			comment: comment ? comment : null,
		};
		ReunificationService.updateStudentComment(alarmId, body).catch((err) => {
			toaster(err?.error.description, Intent.DANGER);
		});
	};

	const changeCommentHandler = (student, value) => {
		updateStudentComment(student.id, value);
		debouncedUpdateComment(student, value);
	};

	const debouncedUpdateComment = useCallback(debounce(updateCommentHandler, 1000), []);

	const updateStudentComment = (studentId, value) => {
		setStudentsList((studentsList) =>
			studentsList.map((studentItem) => {
				if (studentItem.id === studentId) {
					return {
						...studentItem,
						checkIn: {
							...studentItem.checkIn,
							note: value,
						},
					};
				}
				return studentItem;
			}),
		);
	};

	const updateStudentCheckInStatus = (studentId, status) => {
		setStudentsList((studentsList) =>
			studentsList.map((studentItem) => {
				if (studentItem.id === studentId) {
					return {
						...studentItem,
						checkIn: {
							...studentItem.checkIn,
							status: status,
						},
					};
				}
				return studentItem;
			}),
		);
	};

	const isStudentCheckedIn = (student) => {
		return student.checkIn && student.checkIn.status === REUNIFICATION_STUDENT_STATUS_CHECKEDIN;
	};

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'student',
			dataField: 'student',
			text: "Student's name",
			sort: true,
			sortCaret: (order, column) => sortingStyle(filterData.sortDir, column),
			formatter: (value, row) => (
				<TableCell
					text={`${row.firstName} ${
						row.middleInitial?.length > 0 ? row.middleInitial.slice(0, 1) + '.' : ''
					} ${row.lastName}`}
					supportText={<>Grade: {row.grade}</>}
				/>
			),
		},
		{
			key: 'checkIn',
			dataField: 'checkIn',
			text: 'Checked-in',
			headerStyle: {
				textAlign: 'center',
			},
			style: {
				width: '100px',
			},
			formatter: (value, row) => {
				const isDisabled = row.reunified && row.guardianCheckIn[0] ? true : false;
				const tooltipTitle = isDisabled
					? 'This student is marked as reunified with their guardian. Their check-in status can’t be edited'
					: undefined;
				return (
					<StudentStatusCheckbox
						onChange={() => checkInStudentHandler(row)}
						checked={isStudentCheckedIn(row)}
						color="success"
						disabled={isDisabled}
						tooltipTitle={tooltipTitle}
					/>
				);
			},
		},
		{
			key: 'reunified',
			dataField: 'reunified',
			text: 'Reunified',
			headerStyle: {
				textAlign: 'center',
			},
			style: {
				width: '100px',
			},
			formatter: (value, row) => {
				const isCheckedIn = isStudentCheckedIn(row);
				const isDisabled = isCheckedIn && row.guardianCheckIn[0] ? false : true;
				const tooltipTitle = isDisabled
					? !isCheckedIn
						? 'This student can’t be reunified because they aren’t checked-in yet'
						: 'This student can’t be reunified because they are not associated with any checked-in guardian yet'
					: undefined;
				return (
					<StudentStatusCheckbox
						onChange={() => showReunifyStudentModal(row)}
						checked={value ? true : false}
						color="primary"
						disabled={isDisabled}
						tooltipTitle={tooltipTitle}
					/>
				);
			},
		},
		{
			key: 'guardianCheckIn',
			dataField: 'guardianCheckIn',
			text: 'Checked-in guardian',
			style: {
				whiteSpace: 'nowrap',
			},
			headerStyle: {
				whiteSpace: 'nowrap',
			},
			formatter: (value, row) => (
				<>{row.guardianCheckIn[0] ? row.guardianCheckIn[0].name : ''}</>
			),
		},
		{
			key: 'comments',
			dataField: 'comments',
			text: 'Comments',
			style: {
				minWidth: '160px',
				paddingLeft: '10px',
				paddingRight: '10px',
			},
			formatter: (value, row) => (
				<Input
					type="text"
					value={row.checkIn && row.checkIn.note ? row.checkIn.note : ''}
					onChange={(e) => changeCommentHandler(row, e.target.value)}
					placeholder="Add a comment"
				/>
			),
		},
		{
			key: 'actions',
			dataField: 'actions',
			text: 'Actions',
			style: {
				width: '100px',
			},
			formatter: (value, row) => <ReunificationStudentActionsList student={row} />,
		},
	];

	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			if (sortField == 'student') {
				setFilterData((oldData) => ({
					...oldData,
					sortKey: 'studentName',
					sortDir: oldData.sortDir == 'desc' ? 'asc' : 'desc',
				}));
			}
		}
	};

	const setCurrentPage = (value) => {
		setFilterData((oldData) => ({ ...oldData, page: value }));
	};

	const setRowsPerPage = (value) => {
		setFilterData((oldData) => ({ ...oldData, perPage: value }));
	};

	return (
		<BootstrapedTable
			keyField="id"
			key="reunificationStudentsTable"
			className="reunification-students-table-list-container"
			data={studentsList}
			columns={columns}
			totalRows={totalStudents}
			currentPage={filterData.page}
			rowsPerPage={filterData.perPage}
			setCurrentPage={setCurrentPage}
			setRowsPerPage={setRowsPerPage}
			onTableChange={onTableChange}
		/>
	);
};

const StudentStatusCheckbox = ({ disabled, tooltipTitle = '', color, onChange, checked }) => (
	<div className="reunification-students-table-list-container-student-status">
		{disabled && (
			<Tooltip tooltipTitle={tooltipTitle}>
				<div className="reunification-students-table-list-container-student-status-disabled">
					<Checkbox
						onChange={onChange}
						checked={checked}
						size="large"
						color={color}
						disabled={disabled}
					/>
				</div>
			</Tooltip>
		)}
		{!disabled && (
			<Checkbox
				onChange={onChange}
				checked={checked}
				size="large"
				color={color}
				disabled={disabled}
			/>
		)}
	</div>
);

StudentStatusCheckbox.propTypes = {
	disabled: PropTypes.bool.isRequired,
	checked: PropTypes.bool.isRequired,
	tooltipTitle: PropTypes.string,
	color: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

ReunificationStudentsTable.propTypes = {
	studentsList: PropTypes.array.isRequired,
	setStudentsList: PropTypes.func.isRequired,
	totalStudents: PropTypes.number.isRequired,
	filterData: PropTypes.array.isRequired,
	setFilterData: PropTypes.string.isRequired,
};
