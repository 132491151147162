import socketio from 'socket.io-client';

export function initializeSocket(roomsToSubscribe) {
	const token = JSON.parse(localStorage.getItem('user')).jwt;

	const initializedSocket = socketio.connect(process.env.SOCKET_URL + '/EMS', {
		auth: {
			token: token,
		},
		transports: ['websocket'],
	});

	console.log('socket connecting...', initializedSocket);

	initializedSocket.on('connect', () => {
		console.log('connected!');
		initializedSocket.emit('subscribe', roomsToSubscribe);
	});

	initializedSocket.on('connect_error', err => {
		console.log('connect_error', err);
	});

	// Return the socket instance
	return {
		initializedSocket,
	};
}

export function initializeChatSocket(alarmId) {
	const currentDistrict = JSON.parse(localStorage.getItem('currentDistrict'));
	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const districtId = !!currentDistrict?.parentId ? currentDistrict.parentId : currentDistrict?.id;

	const chatSocket = socketio(
		`${process.env.SOCKET_URL_CHAT}/${districtId}-${alarmId}`,
		{
			auth: {
				token: token,
			},
			path: '/chat',
			jsonp: false,
			transports: ['websocket', 'polling'],
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 5000,
			reconnectionAttempts: 10,
		},
	);

	chatSocket.on('connect_error', err => {
		console.log('Chat connect error', err);
	});

	chatSocket.on('disconnect', reason => {
		console.log('Chat socket disconnected');
		if (reason === 'io server disconnect') {
			// the disconnection was initiated by the server, reconnect manually
			chatSocket.connect();
		}
	});

	// Return the chat socket instance
	return {
		chatSocket,
	};
}
