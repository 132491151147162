import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, Tag } from '@blueprintjs/core';
import { Button } from 'components/Button';

const StyledTag = styled(Tag)`
	background: #faf2f3 !important;
	color: #9f1c3a !important;
	margin-left: 8px;
`;

class MultipleVariable extends React.Component {
	constructor(props) {
		super(props);
		const { item } = this.props;
		this.state = {
			item,
			selected: null,
			showButton: false,
		};
	}

	updateVariable = () => {
		const { item, selected } = this.state;
		const { templateId } = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		if (item.value) {
			return fetch(
				`${
					process.env.API_URL
				}/district-templates/${templateId}/variablevalues/${item.id}`,
				{
					method: 'PUT',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						variable_name: item.variable.name,
						variable_value: selected,
					}),
				},
			)
				.then(res => res.json())
				.then(() => this.setState({ showButton: false }));
		}
		return fetch(
			`${
				process.env.API_URL
			}/district-templates/${templateId}/variablevalues`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					variable_name: item.variable.name,
					variable_value: selected,
				}),
			},
		)
			.then(res => res.json())
			.then(resp => {
				const { item: oldItem } = this.props;
				oldItem.value = selected;
				oldItem.id = resp.data.id;
				return this.setState({ item: oldItem, showButton: false });
			});
	};

	changeRadio = e =>
		this.setState({ selected: e.target.value, showButton: true });

	render() {
		const { item } = this.props;
		const { selected, showButton } = this.state;
		return (
			<div className="mt-3">
				<div className="d-flex align-item-center justify-content-between mb-2">
					<p className="mb-0">{item.variable.content.name}</p>
					<StyledTag round>Used in {item.total} places</StyledTag>
				</div>
				<RadioGroup
					name="group"
					onChange={e => this.changeRadio(e)}
					selectedValue={selected}
				>
					{item.variable.content.choices.map(one => (
						<Radio key={one.id} label={one.name} value={one.name} />
					))}
				</RadioGroup>
				{showButton && (
					<div className="d-flex justify-content-end">
						<Button
							onClick={this.updateVariable}
							text="Save Changes"
							intent="primary"
						/>
					</div>
				)}
			</div>
		);
	}
}

MultipleVariable.propTypes = {
	templateId: PropTypes.string,
	item: PropTypes.object,
};

export default MultipleVariable;
