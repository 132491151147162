import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { Checkbox } from '@blueprintjs/core';

import { Button } from 'components/Button';
import { Callout } from 'components/Callout';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { CardModal } from 'components/Modals/CardModal';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';
import { DrillEmailRemindersService } from 'services';

const Page = styled.div`
	padding: 8px 24px;

	.header-buttons {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: flex-end;
	}

	.table-header {
		color: #10161a;
		font-size: 18px;
		margin-top: 40px;
		font-weight: 700;
		line-height: 21px;
		font-style: normal;
		letter-spacing: 0.3px;
		font-family: Merriweather;
	}

	table {
		margin-top: 16px;

		.select-responders {
			padding-left: 0px;
		}
	}

	.modal-card {
		width: 400px;

		.responders-header {
			color: #10161a;
			font-size: 15px;
			margin-top: 16px;
			font-weight: 700;
			line-height: 22px;
			font-style: normal;
			letter-spacing: 0.4px;
			font-family: Merriweather;
		}

		.responders-list {
			margin: 16px 0;
			overflow-y: auto;
			max-height: 225px;

			.responders-item {
				height: 45px;
				display: flex;
				padding: 0 10px;
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				font-style: normal;
				letter-spacing: 0px;
				flex-direction: row;
				align-items: center;
				font-family: Nunito Sans;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);

				.bp3-control {
					margin-bottom: 0px;
				}
			}
		}
	}
`;

const BASE_VIEW = {
	selectedSite: null,
	showFirstResponderModal: false,
	listOfResponders: [],
};

const BASE_STATE = {
	...BASE_VIEW,
	loading: true,
	error: null,
	data: [],
};

export default class EmailPreferences extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		DrillEmailRemindersService.fetchEmailSettings()
			.then(settings => {
				this.setState({
					...BASE_STATE,
					loading: false,
					data: settings,
				});
			})
			.catch(() => {
				this.setState({
					...BASE_STATE,
					loading: false,
					error: 'Could not load this page. Please try refreshing',
				});

				Swal.fire({
					title: 'Oops!',
					text: 'Could not load this page. Please try refreshing',
					icon: 'error',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
			});
	}

	editTableItem = (id, path) => {
		this.setState(prevState => ({
			data: prevState?.data?.map(item => ({
				...item,
				...(item.id === id && {
					drillEmailSettings: {
						...item.drillEmailSettings,
						[path]: !item.drillEmailSettings[path],
					},
				}),
			})),
		}));
	};

	manageModal = (showFirstResponderModal = false, selectedSite = null) => {
		if (showFirstResponderModal) {
			return this.fetchResponders(selectedSite);
		}
		this.setState({
			...BASE_VIEW,
			showFirstResponderModal,
		});
	};

	fetchResponders = selectedSite => {
		DrillEmailRemindersService.fetchResponders(selectedSite?.id)
			.then(listOfResponders => {
				this.setState({
					...BASE_VIEW,
					selectedSite,
					listOfResponders: listOfResponders?.map(item => ({
						...item,
						isSelected: !!selectedSite?.drillEmailSettings?.emailRecipients?.find(
							id => parseInt(id, 10) === item.id,
						),
					})),
					showFirstResponderModal: true,
				});
			})
			.catch(() => {
				Swal.fire({
					title: 'Oops!',
					text: 'Could not fetch the responders. Please try again',
					icon: 'error',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
			});
	};

	toggleResponder = id => {
		this.setState(prevState => ({
			listOfResponders: prevState?.listOfResponders?.map(item => ({
				...item,
				...(item.id === id && {
					isSelected: !item.isSelected,
				}),
			})),
		}));
	};

	addResponders = () => {
		const { selectedSite, listOfResponders } = this.state;

		DrillEmailRemindersService.updateDrillEmailSettings([
			{
				buildingId: selectedSite?.id,
				drillEmailSettings: {
					...selectedSite.drillEmailSettings,
					emailRecipients: listOfResponders
						.filter(({ isSelected }) => isSelected)
						.map(({ id }) => id)
						.concat(
							selectedSite.drillEmailSettings.emailRecipients,
						),
				},
			},
		])
			.then(newResponders => {
				this.setState({
					...BASE_VIEW,
					data: newResponders,
				});
				Swal.fire({
					title: 'Success!',
					text: 'Your changes have been saved!',
					icon: 'success',
				});
			})
			.catch(() => {
				Swal.fire({
					title: 'Oops!',
					text: 'Could update the responders. Please try again',
					icon: 'error',
				});
			});
	};

	savePageSettings = () => {
		const { data } = this.state;

		DrillEmailRemindersService.updateDrillEmailSettings(
			data.map(item => ({
				buildingId: item.id,
				drillEmailSettings: item.drillEmailSettings,
			})),
		)
			.then(newResponders => {
				this.setState({
					...BASE_VIEW,
					data: newResponders,
				});
				Swal.fire({
					title: 'Success!',
					text: 'Your changes have been saved!',
					icon: 'success',
				});
			})
			.catch(() => {
				Swal.fire({
					title: 'Oops!',
					text: 'Could update the responders. Please try again',
					icon: 'error',
				});
			});
	};

	sortData = data =>
		data.sort((a, b) => {
			const x = a.name.toUpperCase();
			const y = b.name.toUpperCase();
			if (x < y) {
				return -1;
			}
			if (x > y) {
				return 1;
			}
			return 0;
		});

	render() {
		const {
			loading,
			error,
			data,
			selectedSite,
			showFirstResponderModal,
			listOfResponders,
		} = this.state;

		const { history } = this.props;

		if (error) {
			return 'Something went wrong, please refresh';
		}

		if (loading) {
			return <LoadingSpinner />;
		}

		return (
			<Page className="container-fluid mt-2">
				{/* header and underline */}
				<PageHeader
					title="Email Preferences"
					onBackBtnClick={history.goBack}
					actionTitle="Save"
					onActionClick={this.savePageSettings}
					actionBtnDisabled={!data?.length}
				/>

				{/* instructions callout */}
				<Callout type="info">
					If school is not listed, confirm that Drill Logs are enabled
				</Callout>

				{/* table header */}
				<h4 className="table-header">
					First Responder Email Notifications
				</h4>

				{/* drills table */}
				<Table
					headers={[
						'Use district list',
						'School name',
						'Drill re-scheduled',
						'Drill scheduled',
						'Drill completed',
						'Drill reminder',
						'Recipients',
					]}
					data={this.sortData(data)}
					renderItem={item => (
						<>
							<td>
								<Checkbox
									checked={
										item?.drillEmailSettings
											?.useDistrictRecipientList
									}
									onChange={() =>
										this.editTableItem(
											item.id,
											'useDistrictRecipientList',
										)
									}
								/>
							</td>

							<td>{item.name}</td>

							<td>
								<Checkbox
									checked={
										item?.drillEmailSettings?.rescheduled
									}
									onChange={() =>
										this.editTableItem(
											item.id,
											'rescheduled',
										)
									}
								/>
							</td>

							<td>
								<Checkbox
									checked={
										item?.drillEmailSettings?.scheduled
									}
									onChange={() =>
										this.editTableItem(item.id, 'scheduled')
									}
								/>
							</td>

							<td>
								<Checkbox
									checked={
										item?.drillEmailSettings?.completed
									}
									onChange={() =>
										this.editTableItem(item.id, 'completed')
									}
								/>
							</td>

							<td>
								<Checkbox
									checked={item?.drillEmailSettings?.reminder}
									onChange={() =>
										this.editTableItem(item.id, 'reminder')
									}
								/>
							</td>

							<td className="select-responders">
								{item?.drillEmailSettings?.emailRecipients && (
									<Button
										intent="primary"
										text="Select responders"
										onClick={() =>
											this.manageModal(true, item)
										}
									/>
								)}
							</td>
						</>
					)}
				/>

				{/* add a new recipient */}
				<CardModal
					isVisible={showFirstResponderModal}
					setIsVisible={this.manageModal}
					title="Select first responders"
					actionTitle="Save"
					onActionClick={this.addResponders}
				>
					{/* header */}
					<h4 className="responders-header">{selectedSite?.name}</h4>

					{/* info */}
					<Callout type="info">
						If school is not listed, confirm that Drill Logs are
						enabled
					</Callout>

					{/* list first responders */}
					<div className="responders-list">
						{listOfResponders?.map(item => (
							<div className="responders-item">
								<Checkbox
									checked={item.isSelected}
									label={item.name}
									onChange={() =>
										this.toggleResponder(item.id)
									}
								/>
							</div>
						))}
					</div>
				</CardModal>
			</Page>
		);
	}
}

EmailPreferences.propTypes = {
	history: PropTypes.object,
};
