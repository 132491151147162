import React, { useEffect, useState } from "react";
import DropdownComponent from "components/DropdownComponent";
import PropTypes from 'prop-types';

const SortByDropdown = ({
    sortList = [],
    sortKey,
    sortDir,
    onSelectItemHandler,
    ...restProps
}) => {
    
    const [selectedLabel, setSelectedLabel] = useState("")
    const [sortItems, setSortItems] = useState(sortList);

    useEffect(() => {
        setSortItems(sortItems => sortItems.map(sortItem => {
                if(sortItem.type === 'sortKey' && sortKey === sortItem.value) {
                    setSelectedLabel(sortItem.label)
                }
                return {
                    ...sortItem,
                    isSelected: sortKey === sortItem.value || sortDir === sortItem.value,
                    showIcon: sortItem.type === 'sortKey' && sortKey === sortItem.value || sortItem.type === 'sortDir',
                }
            })
        )
    }, [sortKey, sortDir])
    

    return (
        <DropdownComponent 
            text={<>Sorted By: <strong>{selectedLabel}</strong></>}
            items={sortItems}
            onSelectItem={onSelectItemHandler}
            {...restProps}
        />
    )
}

SortByDropdown.propTypes = {
    sortKey: PropTypes.string.isRequired,
    sortDir: PropTypes.string.isRequired,
    sortList: PropTypes.array.isRequired,
    onSelectItemHandler: PropTypes.func.isRequired,
}

export default SortByDropdown;