import React, { useEffect, useContext, useState } from 'react';
import { ContactService } from 'services/ContactService';
import { checkPermission } from 'utilities/permissions';
import { CallListService } from 'services/CallListService';
import { useSelector } from 'react-redux';
import AddContactButton from './AddContactButton';
import { ContactsTable, Filteration, Header } from '.';
import { EmptyState, noResultFoundIcon, emptyDataIcon, LoadingSpinner } from 'componentsV2';
import './css/Contacts.scss';
import { ContactsContext } from '../Providers';

export const Contacts = ({ match, history, location }) => {
	const {
		filterationData,
		dispatchFilterationData,
		reloadContacts,
		modalToShow,
		setModalToShow,
		selectedContactCallList,
		setSelectedContactCallList,
		contactsList,
		setContactsList,
		onCloseModal,
	} = useContext(ContactsContext);
	const [loading, setLoading] = useState(true);
	const [totalRows, setTotalRows] = useState(0);
	const [selectedCallList, setSelectedCallList] = useState(null);
	const [emptyContacts, setEmptyContacts] = useState(false);
	const [editPermission, setEditPermission] = useState(false);
	const [userEditPermission, setUserEditPermission] = useState(false);
	const [userViewPermission, setUserViewPermission] = useState(false);
	const [permissionSet, updatePermissionSet] = useState(false);

	const callListId = +match.params.id;

	const previousSitesSelection = useSelector(state => state.route.callListSites);

	const fetchContacts = () => {
		ContactService.getAll(callListId, filterationData)
			.then(resp => {
				if (resp.statusCode === 200) {
					if (resp._metadata.totalRecords === 0) {
						//empty response
						if (filterationData.combineSearch) {
						} else {
							setEmptyContacts(true);
						}
					} else {
						setEmptyContacts(false);
					}

					setTotalRows(resp._metadata.totalRecords);
					setContactsList(resp.data);
				}
				setLoading(false);
			})
			.catch(() => setLoading(false));
	};

	useEffect(async () => {
		var sites = [];
		const formData = location?.state;
		if (previousSitesSelection !== undefined && formData?.formData?.callListName == undefined) {
			dispatchFilterationData({
				type: 'selectedSites',
				payload: previousSitesSelection,
			});
			await previousSitesSelection.map(site => {
				sites.push(site.value);
			});
		} else {
			await CallListService.getById({ id: callListId }).then(resp => {
				if (resp.statusCode === 200) {
					dispatchFilterationData({
						type: 'selectedSites',
						payload: [
							{
								label: resp.data.site,
								value: resp.data.site_id,
								id: resp.data.site_id,
							},
						],
					});
					sites = [resp.data.site_id];
				}
			});
		}
		CallListService.getAll({
			selectedBuilders: sites,
		})
			.then(resp => {
				if (resp.statusCode === 200) {
					var callLists = [];
					var callListsForFilter = [];
					resp.data.map(item => {
						if (item.id === callListId) {
							setSelectedContactCallList({
								label: `${item.title}`,
								value: item.id,
								site_id: item.site_id,
							});
							setSelectedCallList({
								label: `${item.title}`,
								value: item.id,
								site_id: item.site_id,
							});
						}
						callLists.push({
							label: `${item.title} (${item.site})`,
							value: item.id,
							site_id: item.site_id,
						});
						callListsForFilter.push({
							value: `${item.title} (${item.site})`,
							key: item.id,
							site_id: item.site_id,
						});
					});
				}
			})
			.then(() => {
				fetchContacts();
			});
	}, [callListId]);

	useEffect(() => {
		fetchContacts();
	}, [filterationData, reloadContacts]);

	const loggedUserPermissions = useSelector(state => state.route.permissions);

	const setUserPermissionsPerSite = siteId => {
		setEditPermission(checkPermission('call_list_edit', loggedUserPermissions, siteId));
		setUserEditPermission(checkPermission('user_edit', loggedUserPermissions, siteId));
		setUserViewPermission(checkPermission('user_view', loggedUserPermissions, siteId));
		updatePermissionSet(true);
	};

	useEffect(() => {
		if (selectedCallList?.site_id) {
			setUserPermissionsPerSite(selectedCallList?.site_id);
		}
	}, [selectedCallList]);

	const headerCustomItem = () => {
		return (
			editPermission && !emptyContacts && <AddContactButton setModalToShow={setModalToShow} />
		);
	};

	return (
		<div className="callList-container">
			{modalToShow === 'CallListBulkContactsModal' && (
				<BulkContactsModal
					onCloseModal={onCloseModal}
					setModalToShow={setModalToShow}
					contactsList={filterationData}
					selectedSites={filterationData.selectedSites}
					callListId={match.params.id}
					selectedCallList={selectedContactCallList}
					fetchContacts={fetchContacts}
				/>
			)}
			
			<div className="row">
				<Header title={selectedCallList?.label} customItem={headerCustomItem} />
				{loading && <LoadingSpinner />}
				{!loading && (
					<>
						{!emptyContacts && (
							<Filteration
								filterationData={filterationData}
								dispatchFilterationData={dispatchFilterationData}
							/>
						)}

						{emptyContacts && (
							<div className="col-md-12 noContactsEmptyState">
								<EmptyState
									icon={emptyDataIcon}
									className="mt-3"
									showCircles={false}
									header="No contacts were added yet"
									description="Add contacts to your call list."
								>
									{editPermission && (
										<AddContactButton setModalToShow={setModalToShow} />
									)}
								</EmptyState>
							</div>
						)}
						{!emptyContacts && (
							<>
								{contactsList.length === 0 ? (
									<div className="col-md-12 noContactsEmptyState empty-contacts-container">
										<EmptyState
											icon={noResultFoundIcon}
											className="mt-3"
											showCircles={false}
											header="No contact found"
											description="Your search didn't match any contact. Please try again or add a new contact."
										/>
									</div>
								) : selectedCallList?.site_id && permissionSet ? (
									<ContactsTable
										contactsList={contactsList}
										filterationData={filterationData}
										dispatchFilterationData={dispatchFilterationData}
										totalRows={totalRows}
										selectedCallList={selectedCallList}
										callListId={callListId}
										updateDataCallBack={fetchContacts}
										userEditPermission={userEditPermission}
										userViewPermission={userViewPermission}
										callListEditPermission={editPermission}
									/>
								) : null}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};
