import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AssessmentService } from 'services';
import { NavCard, LoadingSpinner, Badge, ScrollTopButton } from 'componentsV2';
import { AssessmentQuestionSection } from './AssessmentQuestionSection';
import { AssessmentHeader } from './AssessmentHeader';

export const AssessmentBody = ({
	assessmentId,
	getSectionsData,
	viewOnly = false,
	setUpdatedSectionsList = () => {},
	handleUpdateAnswerCount = () => {},
}) => {
	const [sections, setSections] = useState([]);
	const [section, setSection] = useState({});
	const [questions, setQuestions] = useState({});
	const [areQuestionsLoading, setAreQuestionsLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [questionsFilter, setQuestionsFilter] = useState('all');

	const selectSection = selectedSection => {
		setSection(selectedSection);
		setAreQuestionsLoading(true);
		AssessmentService.getQuestions(assessmentId, selectedSection?.id).then(res => {
			// Convert from empty array to expected empty object.
			const qs = Array.isArray(res.data[0]) && res.data[0].length === 0 ? {} : res.data[0];
			setQuestions(qs);
			setAreQuestionsLoading(false);
		});
	};

	useEffect(() => {
		setLoading(true);
		if (getSectionsData?.data?.sections) {
			setSections(getSectionsData.data.sections);
			selectSection(getSectionsData.data.sections[0]);
			setLoading(false);
		}
	}, [getSectionsData]);

	const handleSetIsHidden = (sectionId, isHidden) => {
		const updatedSections = sections.map(section => ({
			...section,
			isHidden: section.id === sectionId ? isHidden : section.isHidden,
		}));
		setSections(updatedSections);
		setUpdatedSectionsList(updatedSections);
	};

	const handleQuestionsFiltration = (sectionId, filterValue) => {
		setAreQuestionsLoading(true);
		AssessmentService.getQuestions(assessmentId, sectionId).then(res => {
			// Convert from empty array to expected empty object.
			const qs = Array.isArray(res.data[0]) && res.data[0].length === 0 ? {} : res.data[0];
			setQuestions(qs);
			setAreQuestionsLoading(false);
		});
		setQuestionsFilter(filterValue);
	};

	const updateQuestionsAnsweredCount = async () => {
		const {
			data: { sections: updatedSections },
		} = await AssessmentService.getAssessmentSections(assessmentId);
		setSections(updatedSections);
		handleUpdateAnswerCount(updatedSections);
	};

	return (
		<>
			<h3 className="section-title">Sections</h3>
			{loading && <LoadingSpinner />}
			{!loading && sections.length === 0 && (
				<h3 className="section-title">There are no sections.</h3>
			)}
			{!loading && sections.length !== 0 && (
				<div className="assessment-body">
					<div className="section-cards">
						{sections.map(
							({ name, answerCount: a, questionCount: q, id, isHidden }, index) => (
								<NavCard
									{...{
										name,
										key: id,
										subtext: `${a}/${q} questions answered`,
										selected: id === section.id,
										onClick: () => selectSection(sections[index]),
										rightElement: isHidden ? (
											<Badge text="Hidden" size="md" />
										) : null,
									}}
								/>
							),
						)}
					</div>
					<div className="questions">
						{Object.keys(section).length === 0 && <LoadingSpinner />}
						{Object.keys(section).length !== 0 && (
							<>
								<AssessmentHeader
									{...{ assessmentId, section, viewOnly }}
									onSetIsHidden={handleSetIsHidden}
									onFilter={handleQuestionsFiltration}
								/>
								{areQuestionsLoading ? (
									<LoadingSpinner />
								) : (
									<AssessmentQuestionSection
										section={questions}
										assessmentId={assessmentId}
										viewOnly={viewOnly}
										questionsFilter={questionsFilter}
										updateQuestionsAnsweredCount={updateQuestionsAnsweredCount}
									>
										<ScrollTopButton />
									</AssessmentQuestionSection>
								)}
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

AssessmentBody.propTypes = {
	assessmentId: PropTypes.number.isRequired,
	viewOnly: PropTypes.bool,
	getSectionsData: PropTypes.object,
	setUpdatedSectionsList: PropTypes.func,
	handleUpdateAnswerCount: PropTypes.func,
};
