import React, { useState } from "react";
import { Icon } from "@blueprintjs/core";
import { Modal, CardContainer, MediaPlayer } from "componentsV2";
import './css/HowToUseModal.scss'

const facilitatorTrainingVideos = [
    {
        title: 'Crisis stress & the human mind (8:02)',
        link: 'https://d3hwpifpj2ott1.cloudfront.net/ScenarioVideos/Training-FacilitatorVideo1.mp4'
    },
    {
        title: 'How to use scenarios (7:42)',
        link: 'https://d3hwpifpj2ott1.cloudfront.net/ScenarioVideos/Training-FacilitatorVideo2.mp4'
    },
    {
        title: 'A tool for staff development (8:51)',
        link: 'https://d3hwpifpj2ott1.cloudfront.net/ScenarioVideos/Training-FacilitatorVideo3.mp4'
    },
]

const staffDevelopmentVideo = {
    title: 'Staff Development Video (9:47)',
    link: 'https://d3hwpifpj2ott1.cloudfront.net/ScenarioVideos/Training-Staff_Development.mp4'
}

export const HowToUseModal = ({ onCloseModal }) => {
    const [activeVideo, setActiveVideo] = useState(facilitatorTrainingVideos[0].link)

    return (
        <Modal
            isOpen={true}
            onClose={onCloseModal}
            title="Using scenarios effectively"
            showCloseButton={true}
        >
            <div className="content">
                <CardContainer
                    title="Facilitator Training Videos"
                    subtitle="We recommend this video series for anyone presenting scenarios to staff."
                >
                    <div className="links-container">
                        {facilitatorTrainingVideos.map(video => (
                            <a href="#" className="link" onClick={() => setActiveVideo(video.link)}>
                                <span>{video.title}</span>
                                <Icon icon="play" size={12} />
                            </a>

                        ))}
                    </div>
                </CardContainer>
                <CardContainer
                    title="Staff Development Video"
                    subtitle="Show this video to your staff prior to using the scenarios to help them understand the intent of the exercise."
                    cardCustomClass="max-content"
                >
                    <a href="#" className="link" onClick={() => setActiveVideo(staffDevelopmentVideo.link)}>
                        <span className="link-text">{staffDevelopmentVideo.title}</span>
                        <Icon icon="play" size={12} />
                    </a>
                </CardContainer>
            </div>
            <div className="video-container">
                <MediaPlayer
                    mediaUrl={activeVideo}
                />
            </div>
        </Modal>
    )
}