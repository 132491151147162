import React, { useState, useContext } from 'react';
import { FormModal, Input, ToasterContext, Intent, FeaturedIcon } from 'componentsV2';
import './css/AddPrimaryContactModal.scss';
import { FirstRespondersService } from '../../../../services/FirstRespondersService';

export const AddPrimaryContactModal = ({
	loading,
	onCloseModal,
	firstResponder,
	loadFirstResponder,
	responderId,
}) => {
	const toaster = useContext(ToasterContext);

	const [contactInformation, setcontactInformation] = useState({
		firstName: '',
		lastName: '',
		email: '',
	});

	const handleChangeContactInformation = e => {
		const { name } = e.target;
		const { value } = e.target;
		setcontactInformation({
			...contactInformation,
			[name]: value,
		});
	};

	const onSaveContactInformation = e => {
		e.preventDefault();
		FirstRespondersService.addPrimaryContact(
			responderId,
			firstResponder.data.allowed_sites_ids,
			contactInformation,
		)
			.then(resp => {
				if (resp.statusCode === 201) {
					toaster(
						`You have successfully added the primary contact for ${
							firstResponder.data.name
						} agency.`,
						Intent.SUCCESS,
						<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
					);
					loadFirstResponder(responderId);
				}
			})
			.catch(error => {
				toaster(
					`${error.error.description}`,
					Intent.DANGER,
					<FeaturedIcon icon="error" size="md" type="Error" shape="circleIcon" />,
				);
				console.log('error', error);
			})
			.finally(() => {
				onCloseModal();
			});
	};

	return (
		<>
			<FormModal
				isOpen
				onClose={onCloseModal}
				title={'Add new primary contact'}
				showCloseButton={false}
				size={'small'}
				confirmBtn={{
					title: 'Add',
					disabled:
						!contactInformation.firstName ||
						!contactInformation.lastName ||
						!contactInformation.email,
					onClick: onSaveContactInformation,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: onCloseModal,
				}}
				loading={loading}
				scrollContent={false}
			>
				<div className="w-100 mt-1">
					<Input
						name="firstName"
						label="First name"
						placeholder="First name"
						isRequired={true}
						onChange={handleChangeContactInformation}
					/>
				</div>
				<div className="w-100 mt-3">
					<Input
						name="lastName"
						label="Last name"
						placeholder="Last name"
						isRequired={true}
						onChange={handleChangeContactInformation}
					/>
				</div>
				<div className="w-100 mt-3">
					<Input
						label="Email"
						name="email"
						placeholder="Email"
						isRequired={true}
						onChange={handleChangeContactInformation}
					/>
				</div>
				<div className="w-100 mt-3">
					<Input
						type="number"
						label="Phone number"
						name="phone"
						placeholder="Phone number..."
						isRequired={true}
						onChange={handleChangeContactInformation}
					/>
				</div>
			</FormModal>
		</>
	);
};
