import { useEffect, useState } from 'react';
import { FloorplanService } from 'services/FloorplanService';

export const useGetSiteFloorplans = (siteId, params = null) => {
	const [siteFloorplans, setSiteFloorplans] = useState([]);

	useEffect(() => {
		getSiteFloorPlan(params);
	}, []);

	const getSiteFloorPlan = async (params) => {
		FloorplanService.getSiteMaps(siteId, params).then(res => {
			setSiteFloorplans(res.data);
		});
	};

	return {
		siteFloorplans,
	};
};
