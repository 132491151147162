import React from "react";
import { AlertModal, Button } from "componentsV2";

export const ToogleArchiveTemplateModal = ({
    modalName,
    modalToShow,
    loading,
    formData,
    closeModal,
    handleSubmit
}) => {
    const archived = formData?.archived;
    return (
        <AlertModal
            isOpen={modalToShow === modalName}
            onClose={closeModal}
            loading={loading}
            size='medium'
            title={`Are you sure you want to ${ archived ? 'unarchive' : 'archive'} "${formData?.title}"?`}
            subtitle={ archived ? "You can archive it at a later time." : "You can unarchive it at a later time."}
            showCloseButton={false}
            alertConfirmButton={
                <Button
                    text={`Yes, ${ archived ? 'unarchive' : 'archive'} it`}
                    type="primaryDefault"
                    size="md"
                    onClick={handleSubmit}
                />
            }
            alertCancelButton={
                <Button
                    text="No, go back"
                    type="tertiary"
                    size="md"
                    onClick={closeModal}
                />
            }
            icon= { archived ? null : 'warning-sign'}
            iconColor= { archived ? null : 'warning' }
        />
    );
}