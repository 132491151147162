import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import './styles.css';
import './css/CustomScenarioModal.scss';
import { DrillsScenariosService } from '../../services/DrillsScenariosService';
import {
	Modal,
	ModalFooter,
	LoadingSpinner,
	Button,
	Input,
	Dropdown,
	TextArea,
	FeaturedIcon,
	Intent,
	FileUpload,
	FormModal,
} from 'componentsV2';
import { ToasterContext } from 'pages/App';

const Container = styled.div`
	padding: 0 !important;
`;

const Row = styled.div`
  display:flex;
  margin-bottom: 8px;
}
`;
const AddEditCustomScenarioModal = ({
	onCloseModal,
	editing,
	scenarioId,
	updateDataCallBack,
	buildings,
	categories,
}) => {
	const [emptyFieldsError, setEmptyFieldsError] = useState(false);
	const [loading, setLoading] = useState(true);
	const [inputs, setInputs] = useState({});
	const [dropdownLabels, setDropdownLabels] = useState({});
	const [isActiveSubmit, setIsActiveSubmit] = useState(false);
	const [scenarioInfo, setScenarioInfo] = useState([{}]);
	const [scenarioMediaFile, setScenrioMediaFile] = useState({
		fileDbName: null,
		fileName: null,
		fileSize: null,
	});
	const toaster = useContext(ToasterContext);

	const handleChange = (event, fieldName = null, type = null) => {
		const name = fieldName ? fieldName : event.target.name;
		const value = type == 'dropdown' ? event.value : event.target.value;
		setInputs(values => ({ ...values, [name]: value }));
		if (type == 'dropdown')
			setDropdownLabels(values => ({ ...values, [name]: event }));
	};

	const handleFileInput = (file, base64Data) => {
		setInputs(values => ({
			...values,
			['file']: base64Data !== null ? base64Data : '',
			['fileName']: file?.name,
			['fileSize']: file?.size,
			['fileType']: file?.type,
		}));
	};

	const getFileTypeFromStringName = fileName => {
		if (!fileName) return;
		const ext = fileName.split('.')[1];
		switch (ext) {
			case 'jpg':
			case 'gif':
			case 'png':
			case 'svg':
			case 'jpeg':
				return 'image/' + ext;
			case 'mp3':
			case 'wav':
				return 'audio/' + ext;
			case 'mp4':
			case 'avi':
				return 'video/' + ext;
			default:
				return 'document/' + ext;
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			if (editing) {
				const scenarioData = await DrillsScenariosService.getById(
					scenarioId,
				);
				setScenarioInfo(scenarioData.data);
				setInputs(values => ({
					...values,
					['title']: scenarioData.data.title,
				}));
				setInputs(values => ({
					...values,
					['category']: scenarioData.data.category?.id,
				}));

				const building = getBuildingDropdownValue(
					buildings,
					scenarioData.data.site,
				);
				setInputs(values => ({
					...values,
					['building']: building?.value,
				}));
				setDropdownLabels(values => ({
					...values,
					['building']: building,
				}));

				setInputs(values => ({
					...values,
					['category']: scenarioData.data.category.id,
				}));
				setDropdownLabels(values => ({
					...values,
					['category']: {
						label: scenarioData.data?.category?.title,
						value: scenarioData.data?.category?.id,
					},
				}));

				setInputs(values => ({
					...values,
					['content']: scenarioData.data.content,
				}));
				setInputs(values => ({
					...values,
					['notes']: scenarioData.data.notes,
				}));
				switch (true) {
					case scenarioData.data.audioFile &&
						scenarioData.data.audioFile.length > 0:
						setScenrioMediaFile({
							fileDbName: scenarioData.data.audioFile.substring(
								scenarioData.data.audioFile.lastIndexOf('/') +
									1,
							),
							fileName: scenarioData.data.fileName,
							fileSize: scenarioData.data.fileSize,
						});
						break;
					case scenarioData.data.videoFile &&
						scenarioData.data.videoFile.length > 0:
						setScenrioMediaFile({
							fileDbName: scenarioData.data.videoFile.substring(
								scenarioData.data.videoFile.lastIndexOf('/') +
									1,
							),
							fileName: scenarioData.data.fileName,
							fileSize: scenarioData.data.fileSize,
						});
						break;
				}
			} else {
				setDropdownLabels(values => ({
					...values,
					['building']: buildings.slice(0, 1)[0],
				}));
				setInputs(values => ({
					...values,
					['building']: buildings.slice(0, 1)[0].value,
				}));
			}
		};
		fetchData().then(() => {
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		if (inputs.title && inputs.category) {
			setIsActiveSubmit(true);
		} else {
			setIsActiveSubmit(false);
		}
	}, [inputs]);

	const getBuildingDropdownValue = (buildings, site) => {
		return buildings.filter(
			option => option.label.toLowerCase() === site.toLowerCase(),
		)[0];
	};

	const onConfirmCreate = async () => {
		try {
			setEmptyFieldsError(true);
			setLoading(true);
			await DrillsScenariosService.createDrillScenario(
				inputs.title,
				inputs.category,
				inputs.building,
				inputs.notes,
				inputs.content,
				inputs.file,
				inputs.fileType,
				inputs.fileName,
				inputs.fileSize,
			);
			toaster(
				`You have successfully added the Custom Scenario "${
					inputs.title
				}".`,
				Intent.SUCCESS,
				<FeaturedIcon
					icon={'tick'}
					type={'Success'}
					size={'md'}
					shape={'circleIcon'}
				/>,
			);
		} catch (error) {
			toaster(
				`Scenario "${inputs.title}" not successfully added`,
				Intent.DANGER,
				<FeaturedIcon
					icon={'error'}
					type={'Error'}
					size={'md'}
					shape={'circleIcon'}
				/>,
			);
		} finally {
			setLoading(false);
			onCloseModal();
			updateDataCallBack();
		}
	};

	const onConfirmUpdate = async () => {
		try {
			setEmptyFieldsError(true);
			setLoading(true);
			await DrillsScenariosService.updateDrillScenario(
				scenarioId,
				inputs.title,
				inputs.category,
				inputs.building,
				inputs.notes,
				inputs.content,
				inputs.file,
				inputs.fileType,
				inputs.fileName,
				inputs.fileSize,
			);
			toaster(
				`You have successfully updated "${
					inputs.title
				}" custom Scenario.`,
				Intent.SUCCESS,
				<FeaturedIcon
					icon={'tick'}
					type={'Success'}
					size={'md'}
					shape={'circleIcon'}
				/>,
			);
		} catch (error) {
			toaster(
				`Scenario "${inputs.title}" not successfully updated`,
				Intent.DANGER,
				<FeaturedIcon
					icon={'error'}
					type={'Error'}
					size={'md'}
					shape={'circleIcon'}
				/>,
			);
		} finally {
			setLoading(false);
			onCloseModal();
			updateDataCallBack();
		}
	};

	return (
		<FormModal
			isOpen={true}
			onClose={onCloseModal}
			title={editing ? 'Edit Custom Scenario' : 'Add Custom Scenario'}
			showCloseButton={true}
			confirmBtn={{
				title: editing ? 'Update' : 'Save',
				onClick: editing ? onConfirmUpdate : onConfirmCreate,
				disabled: !isActiveSubmit,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: onCloseModal,
			}}
			loading={loading}
		>
			<Container className="container scenario-modal-container">
				<Row>
					<div className="col-12">
						<Input
							defaultValue={editing ? scenarioInfo.title : ''}
							label={'Title'}
							noMarginBottom={true}
							placeholder={'ex. Student not breathing'}
							isRequired
							onChange={e => handleChange(e, 'title')}
							error={emptyFieldsError && !inputs.title}
						/>
					</div>
				</Row>
				<Row style={{ marginBottom: 10 }}>
					<div className="col-6">
						<Dropdown
							label="Category"
							name="category"
							placeholder={'Select the scenario category'}
							options={categories}
							value={dropdownLabels.category}
							isRequired
							onChange={e =>
								handleChange(e, 'category', 'dropdown')
							}
							error={emptyFieldsError && !inputs.category}
						/>
					</div>
					<div className="col-6">
						<Dropdown
							label="Site Visibility"
							name="building"
							placeholder={'District Name'}
							value={dropdownLabels.building}
							options={buildings}
							isRequired
							onChange={e =>
								handleChange(e, 'building', 'dropdown')
							}
						/>
					</div>
				</Row>
				<Row>
					<div className="col-12">
						<FileUpload
							disabled={false}
							allowedFiles={['avi', 'wav', 'mp3', 'mp4']}
							allowedFilesLegend="MP3, MP4, AVI, or WAV (max. 5mb)"
							label="Media file"
							file={
								scenarioMediaFile.fileDbName
									? {
											name:
												scenarioMediaFile.fileName ||
												scenarioMediaFile.fileDbName,
											type: getFileTypeFromStringName(
												scenarioMediaFile.fileDbName,
											),
											size: scenarioMediaFile.fileSize,
									  }
									: null
							}
							fileForEditing={
								scenarioMediaFile.fileDbName ? true : false
							}
							onFileInputChange={handleFileInput}
						/>
					</div>
				</Row>
				<Row>
					<div className="col-12">
						<TextArea
							label="Scenario Description"
							placeholder="Enter the scenario description..."
							value={inputs.content}
							onChange={e => handleChange(e, 'content')}
						/>
					</div>
				</Row>
				<Row>
					<div className="col-12">
						<TextArea
							label="Discussion points"
							placeholder="Enter the scenario discussion points..."
							value={inputs.notes}
							onChange={e => handleChange(e, 'notes')}
						/>
					</div>
				</Row>
			</Container>
		</FormModal>
	);
};

export default AddEditCustomScenarioModal;
