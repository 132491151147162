import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Dialog } from '@blueprintjs/core';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';
import { allowedExtensions } from 'utilities';

const BigIcon = styled.div`
	color: #5c7080;
	text-align: center;
	padding: 30px 0;
	svg {
		width: 48px;
		height: auto;
		margin-bottom: 10px;
	}
	p {
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		color: #394b59;
	}
`;

const LightIcon = styled(BigIcon)`
	color: #d8e1e8;
`;

const Section = styled.section`
	border: 1px dashed #d8e1e8;
`;

class BonusFilesModal extends React.Component {
	state = {
		loading: false,
	};

	deleteFile = item => {
		Swal.fire({
			title: 'Are you sure you want to delete this file?',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Delete',
			reverseButtons: true,
		}).then(result => {
			if (result.value) {
				Swal.close();
				this.setState({ loading: true });
				const {
					template: { id: templateId },
					dispatch,
				} = this.props;
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				
				fetch(`${process.env.API_URL}/district-templates/${templateId}/files/${item.id}`, {
					method: 'DELETE',
					headers: {
						Authorization: `Bearer ${token}`,
					}
				})
				.then(res => res.json())
				.then(() => {
					dispatch({
						type: 'REMOVE_BONUS_FILE',
						payload: item,
					});
					this.saveAndEnableClose();
				});
			}
			return null;
		});
	};

	uploadFile = files => {
		this.setState({ loading: true });
		const {
			template: { id },
			dispatch,
		} = this.props;

		const token = JSON.parse(localStorage.getItem('user')).jwt;

		const fd = new FormData();
		fd.append('file', files[0]);

		fetch(`${process.env.API_URL}/district-templates/${id}/upload`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: fd,
		})
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 201) {
					const valueFile = {
						size: resp.data.size,
						id: resp.data.id,
						name: resp.data.fileName,
					};
					dispatch({
						type: 'ADD_BONUS_FILES',
						valueFile,
					});
					this.saveAndEnableClose();
				} else if (resp.statusCode == 400) {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: resp.data,
					});
				}
				this.setState({ loading: false });
				return null;
			});
	};

	saveAndEnableClose = () => 
	{
		const { template} = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;	
		fetch(`${process.env.API_URL}/district-templates/${template.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'app/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				bonusFiles : JSON.stringify(template.bonusFiles)
			}),
		})
		.then(res => res.json())
		.then(() => {
			this.setState({ loading: false });
		});
	};

	render() {
		const { isOpen, onClose } = this.props;
		const { loading } = this.state;

		const {
			template: { bonusFiles },
		} = this.props;
		return (
			<Dialog 
				isOpen={isOpen} 
				onClose={onClose} 
				title="Attach files"
				isCloseButtonShown={!loading}
				canOutsideClickClose={!loading}
			>
				{bonusFiles.map(item => (
					<div key={`file-${item.id}`}>
						<div className="p-3 d-flex justify-content-between align-items-center">
							<p className="mb-0">
								{item.name} {(item.size / 1000000).toFixed(2)}{' '}
								MB
							</p>
							<Button
								icon="cross"
								minimal
								onClick={() => this.deleteFile(item)}
							/>
						</div>
					</div>
				))}
				{loading && <LoadingSpinner />}
				{!loading && (
					<>
						<Dropzone onDrop={file => this.uploadFile(file)}>
							{({ getRootProps, getInputProps }) => (
								<Section className="container">
									<div
										{...getRootProps({
											className: 'dropzone',
										})}
									>
										<input {...getInputProps()} />
										<LightIcon>
											<Icon icon="document" />
											<p>
												Drag and drop a file here, or{' '}
												<a
													href="#"
													onClick={e =>
														e.preventDefault()
													}
												>
													browse
												</a>
											</p>
											<p>{allowedExtensions}</p>
										</LightIcon>
									</div>
								</Section>
							)}
						</Dropzone>
					</>
				)}
			</Dialog>
		);
	}
}

BonusFilesModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	template: PropTypes.object,
	match: PropTypes.object,
	dispatch: PropTypes.func,
	bonusFiles: PropTypes.array,
};

export default BonusFilesModal;
