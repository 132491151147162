import React from "react";
import { Input } from "./";
import styled from 'styled-components';
import { PHONE_NUMBER_MASK } from './components/constants';

const Row = styled.div`
	margin-bottom:30px;
  margin-right: -15px;
  margin-left: -15px;
`;
export const InputPattern = () => {
  const label = 'Input label';
  const placeholder = 'Input Placeholder...';
  const errorMessage = 'This is an error message.';
  const hint = 'This is a hint text to help user.';
  return (
    <>
      <Row>
        <div className="col-md-12">
          <h5>Normal State:</h5>
        </div>
        <div className="col-md-12">
          <Input label={label} placeholder={placeholder} helpText={hint} isRequired={true} />
        </div>
      </Row>
      <Row>
        <div className="col-md-12">
          <h5>Normal State (Optional)</h5>
        </div>
        <div className="col-md-12">
          <Input label={label} placeholder={placeholder} helpText={hint} />
        </div>
      </Row>
      <Row>
        <div className="col-md-12">
          <h5>Normal State with Icon</h5>
        </div>
        <div className="col-md-12">
          <Input label={label} placeholder={placeholder} helpText={hint} icon={"user"} />
        </div>
      </Row>
      <Row>
        <div className="col-md-12 mt-3">
          <h5>Disabled State:</h5>
        </div>
        <div className="col-md-12">
          <Input label={label} placeholder={placeholder} disabled={true} value={'Input text'} helpText={hint} />
        </div>
      </Row>
      <Row>
        <div className="col-md-12 mt-3">
          <h5>Error State:</h5>
        </div>
        <div className="col-md-12">
          <Input label={label} placeholder={placeholder} error={true} errorMessage={errorMessage} />
        </div>
      </Row>
      <Row>
        <div className="col-md-12 mt-3">
          <h5>Input Mask:</h5>
        </div>
        <div className="col-md-12">
          <Input label={label} placeholder={placeholder} mask={ PHONE_NUMBER_MASK } />
        </div>
      </Row>
    </>
  );

}
