import React from 'react';
import { UnsavedChangesModal } from './UnsavedChangesModal';
import { UNSAVED_CHANGES_MODAL } from '../consts';
import PropTypes from 'prop-types';

export const MapViewModals = ({
	modalToShow,
	closeModal,
	saveChangesHandler,
	discardChangesHandler,
	nextModalToShow,
	setNextModalToShow,
}) => {
	return (
		<>
			{modalToShow === UNSAVED_CHANGES_MODAL && (
				<UnsavedChangesModal
					discardChangesHandler={discardChangesHandler}
					saveChangesHandler={saveChangesHandler}
					nextModalToShow={nextModalToShow}
					setNextModalToShow={setNextModalToShow}
				/>
			)}
		</>
	);
};

MapViewModals.propTypes = {
	modalToShow: PropTypes.bool,
	closeModal: PropTypes.func.isRequired,
	saveChangesHandler: PropTypes.func,
};
