import React from 'react';
import { Radio } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useFlipchartTabForm } from 'hooks';
import {
	FormModal,
	Input,
	InputColor,
	DefaultColors,
	RadioButtonGroup,
	TinymceEditor,
	LoadingSpinner,
	Dropdown,
	Animated,
} from 'componentsV2';
import { FLIPCHART_CONTENT_TYPE } from 'utilities/constants';

export const AddUpdateTabModal = ({
	flipchart,
	onSubmitFinished,
	modalIsOpen,
	setModalIsOpen,
	tab,
	tabFlipchartId,
	tabBuildingId,
}) => {
	const {
		formData,
		dispatchFormData,
		handleChangeTitle,
		handleChangeSubtitle,
		handleChangeContentType,
		handleChangeSafetyPlan,
		handleChangeSafetyPlanSection,
		handleSubmit,
	} = useFlipchartTabForm(
		flipchart,
		tab,
		tabFlipchartId,
		tabBuildingId,
		setModalIsOpen,
		onSubmitFinished,
	);

	const presetColors = DefaultColors.filter((defaultColor, index) => index <= 5);

	const editorSetupFunc = (editor, error) => {
		editor.ui.registry.addButton('variableMenu', {
			text: 'Insert Variable',
			onAction: () => {},
		});
	};

	return (
		<FormModal
			isOpen={modalIsOpen}
			onClose={setModalIsOpen}
			showCloseButton
			title={tab ? `Edit tab ${formData.title}` : 'Add new tab'}
			loading={formData.modalLoading}
			scrollContent={false}
			confirmBtn={{
				title: tab ? 'Update' : 'Save',
				onClick: handleSubmit,
				disabled: formData.disableSubmit,
			}}
			cancelBtn={{
				title: 'Cancel',
				onClick: () => setModalIsOpen(false),
			}}
		>
			<>
				<div className="w-100">
					<Input
						label="Title"
						placeholder="ex. Emergency Communication"
						isRequired
						width="100%"
						value={formData.title}
						onChange={handleChangeTitle}
						error={formData.fieldsErrors.title}
						errorMessage={formData.fieldsErrors.title}
					/>
				</div>

				<div className="w-100 mt-3">
					<Input
						label="Subtitle"
						placeholder="ex. Fire"
						isRequired={false}
						width="100%"
						value={formData.subtitle}
						onChange={handleChangeSubtitle}
					/>
				</div>

				<div className="d-flex w-100 mt-3">
					<div className="w-50">
						<InputColor
							label="Text color"
							name="text-color"
							value={formData.textColor}
							setValue={color =>
								dispatchFormData({ type: 'textColor', payload: color })
							}
							presetColors={presetColors}
							isRequired
						/>
					</div>
					<div className="w-50">
						<InputColor
							label="Background color"
							name="background-color"
							value={formData.backgroundColor}
							setValue={color =>
								dispatchFormData({ type: 'backgroundColor', payload: color })
							}
							presetColors={presetColors}
							isRequired
						/>
					</div>
				</div>

				<div className="w-100 mt-3">
					<RadioButtonGroup
						label="Content type"
						onChange={handleChangeContentType}
						selectedValue={formData.contentType}
						size="small"
						bordered
						required
						layout="inline"
						fullwidth
					>
						<Radio label="Custom" value="custom" />
						<Radio label="Call list" value="call_list" />
						<Radio label="Safety plan section" value="safety_plan" />
					</RadioButtonGroup>
				</div>

				<div className="w-100 mt-3">
					{formData.contentType == FLIPCHART_CONTENT_TYPE.CUSTOM && (
						<Animated>
							<TinymceEditor
								label="Content"
								placeholder="Enter tab content..."
								isRequired
								error={false}
								onChange={() => {}}
								value={formData.customContentValue}
								setupProps={editorSetupFunc}
								onEditorChange={content =>
									dispatchFormData({
										type: 'customContentValue',
										payload: content,
									})
								}
								labelState
								editorWidth="100%"
								editorHeight={250}
								onInit={(event, editor) => {}}
								initialValue={formData.initialCustomContent}
							/>
						</Animated>
					)}

					{formData.contentType == FLIPCHART_CONTENT_TYPE.CALLLIST &&
						(formData.callListLoading ? (
							<LoadingSpinner size="small" />
						) : (
							<Animated>
								<Dropdown
									label="Select call list"
									value={formData.callListSelected}
									options={formData.callListOptions}
									onChange={option =>
										dispatchFormData({
											type: 'callListSelected',
											payload: option,
										})
									}
									isRequired
									error={false}
								/>
								{formData.callListInitId && !formData.callListSelected && (
									<div className="mt-2 text-danger">
										The attached call list has been deleted.
									</div>
								)}
							</Animated>
						))}

					{formData.contentType == FLIPCHART_CONTENT_TYPE.SAFETYPLAN && (
						<>
							{formData.safetyPlanLoading ? (
								<LoadingSpinner size="small" />
							) : (
								<>
									<Animated>
										<Dropdown
											label="Select safety plan"
											value={formData.safetyPlanSelected}
											options={formData.safetyPlanOptions}
											onChange={handleChangeSafetyPlan}
											isRequired
											optionLabel="name"
											optionValue="id"
											error={false}
										/>
									</Animated>
									{formData.safetyPlanInitId && !formData.safetyPlanSelected && (
										<div className="mt-2 text-danger">
											The attached safety plan has been deleted.
										</div>
									)}
								</>
							)}

							{formData.safetyPlanSelected &&
								(formData.safetyPlanSectionLoading ? (
									<LoadingSpinner size="small" />
								) : (
									<>
										<Animated>
											<Dropdown
												label="Select plan section"
												value={formData.safetyPlanSectionSelected}
												options={formData.safetyPlanSectionOptions}
												onChange={handleChangeSafetyPlanSection}
												isRequired
												disabled={!formData.safetyPlanSectionOptions}
												error={false}
											/>
										</Animated>
										{formData.safetyPlanSectionSelected &&
											formData.safetyPlanSubsectionOptions &&
											(formData.safetyPlanSubsectionOptions.length > 0 ? (
												<Animated>
													<Dropdown
														label="Select plan subsection"
														value={
															formData.safetyPlanSubsectionSelected
														}
														options={
															formData.safetyPlanSubsectionOptions
														}
														onChange={option =>
															dispatchFormData({
																type:
																	'safetyPlanSubsectionSelected',
																payload: option,
															})
														}
														isRequired={false}
														disabled={
															!formData.safetyPlanSubsectionOptions
														}
														error={false}
													/>
												</Animated>
											) : (
												<div className="mt-2">
													This section does not contain subsections.
												</div>
											))}
									</>
								))}
						</>
					)}
				</div>
			</>
		</FormModal>
	);
};

AddUpdateTabModal.propTypes = {
	flipchart: PropTypes.object,
	onSubmitFinished: PropTypes.func,
	modalIsOpen: PropTypes.bool,
	setModalIsOpen: PropTypes.func,
	tab: PropTypes.object,
	tabFlipchartId: PropTypes.number,
	tabBuildingId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
};
