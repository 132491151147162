import React from 'react';
import { Tabs } from '@blueprintjs/core';
import './css/CustomTabs.scss';

export const CustomTabs = ({ onChangeHandler, identifier, children, renderActiveTabPanelOnly = false, selectedTabId }) => {
    return (
        <>
            <Tabs
                id={identifier}
                className="custom-tabs"
                onChange={ typeof onChangeHandler === 'function' ? onChangeHandler : null }
                renderActiveTabPanelOnly={renderActiveTabPanelOnly}
                selectedTabId={selectedTabId}
            >
                {children}
            </Tabs>
        </>
    );
};
