export const MAPS_EDIT_PATH = 'maps/edit';
export const MAPS_VIEW_PATH = 'maps/view';
export const ARCHIVE_MAP_MODAL = 'archiveMapModal';
export const MAP_LIST_PAGE = 'mapListPage';
export const MAP_DETAILS_PAGE = 'mapDetailsPage';
export const DELETE_MAP_MODAL = 'DeleteMapModal';
export const RENAME_MAP_MODAL = 'RenameMapModal';
export const CREATE_MAP_MODAL = 'createMapModal';
export const MAP_CONTENTS_MODAL = 'mapContentsList';
export const DELETE_MAP_ACTION = 'delete';
export const VIEW_UPDATE_NOTE_MODAL = 'viewUpdateNoteModal';
export const DELETE_NOTE_MODAL = 'deleteNoteModal';
export const VIEW_UPDATE_CAMERA_LINK_MODAL = 'viewUpdateCameraLinkModal';
export const DELETE_CAMERA_LINK_MODAL = 'deleteCameraLinkModal';
export const UPLOAD_CONTENT = 'uploadContent';
export const DOWNLOAD_MAP = 'downloadMap';
export const SATELLITE_VIEW = 'satelliteView';
