import Konva from 'konva';
import { layer, editPermission, floorplanDetails } from '../CanvasDrawFn';
import { GEOMETRY_TYPE_ROUTE } from '../consts';
import { shapeContextMenu } from './Actions/shapeContextMenu';
import { drawLabel } from './Label';

export const drawRoute = shape => {
	const floorplanArchived = !!floorplanDetails?.archivedBy;
	const shapeIcon = shape.icon;
	if (shapeIcon) {
		// create konva group
		const RouteGroup = new Konva.Group({
			shape,
			x: shape.shape_position_x,
			y: shape.shape_position_y,
			visible: shape.visible,
			listening: editPermission && !floorplanArchived,
			draggable: editPermission && !floorplanArchived,
			scalable: false,
		});

		var routeImg = new Image();
		routeImg.src = shapeIcon.iconURL;

		const routeIcon = new Konva.Image({
			shape,
			draggable: false,
			scalable: true,
			scaleX: shape.shape_scale_x ? shape.shape_scale_x : null,
			scaleY: shape.shape_scale_y ? shape.shape_scale_y : null,
			listening: editPermission && !floorplanArchived,
			type: GEOMETRY_TYPE_ROUTE,
			image: routeImg,
			rotation: shape.angle ? shape.angle : 0,
		});

		const labelOffsetY = 40; // Offset between routeIcon and routeLabel

		// draw label
		if (shape.showLabel) {
			const routeLabel = drawLabel(shape.title, routeIcon, labelOffsetY);
			RouteGroup.add(routeLabel);
		}

		RouteGroup.add(routeIcon);

		if (editPermission) {
			shapeContextMenu(RouteGroup);
		}

		layer.add(RouteGroup);
		return RouteGroup;
	}
	return null;
};
