import React, { useEffect, useState } from 'react';
import {
	SearchBar,
	LoadingSpinner,
	EmptyState,
	Button,
	Animated,
	emptyDocument,
	noResult,
	Tooltip,
} from 'componentsV2';
import { MapOptionCard } from '../';
import { GEOMETRY_TYPE_POLYGON, MAP_AREA_ADD, MAP_AREA_Edit, MAP_AREAS } from '../../consts';
import { AreaItem } from './AreaItem';
import './css/Areas.scss';
import { FloorplanService } from 'services';

export const AreasList = ({
	legends,
	floorplan,
	toggleMapOption,
	editPermission,
	viewPermissionOnSiteId,
	setSelectedArea,
	setSelectedShape,
	optionToShow,
	triggerShowHide,
	layer,
	siteId,
	refreshAeraList,
	floorplanShapes,
	setFloorplanShapes,
	isEditMode,
	setAreaToCopy,
	setModalToShow,
}) => {
	const [loading, setLoading] = useState(true);
	const [areas, setAreas] = useState([]);
	const [noAreasAdded, setNoAreasAdded] = useState(false);
	const [firstLoad, setFirstLoad] = useState(0);
	const floorplanArchived = !!floorplan?.archivedBy;

	useEffect(() => {
		getAreaList();
	}, [floorplan, refreshAeraList]);

	const getAreaList = () => {
		setLoading(true);
		FloorplanService.getShapes({
			siteId,
			geometryType: '3',
			mapId: floorplan.id,
		})
			.then(response => {
				if (response.statusCode === 200) {
					setAreas(response.data);
					setNoAreasAdded(response.data.length === 0);
				}
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateVisiblityOnMap = (floorplanShape, visible) => {
		let areaShape =
			layer?.children.length > 0
				? layer.find(konvaShape => konvaShape.id() === `areaGroup-${floorplanShape.id}`)[0]
				: null;
		if (!areaShape) {
			areaShape = layer.children.find(
				shape =>
					shape.attrs.shape &&
					shape.attrs.shape.id === floorplanShape.id &&
					shape.attrs.shape.geometryType === GEOMETRY_TYPE_POLYGON,
			);
		}

		if (areaShape) {
			visible ? areaShape.show() : areaShape.hide();
		}
	};

	const updateFloorplanShapesVisibility = (visible, id = null) => {
		if (id === null) {
			// set all area shapes to visible/invisible
			setFloorplanShapes(oldShapes =>
				oldShapes.map(floorplanShape => {
					if (floorplanShape.geometryType === GEOMETRY_TYPE_POLYGON) {
						updateVisiblityOnMap(floorplanShape, visible);
						return {
							...floorplanShape,
							visible: visible,
						};
					}
					return floorplanShape;
				}),
			);
		} else {
			// set area shape matched with passed it to visible/invisible
			setFloorplanShapes(oldShapes =>
				oldShapes.map(floorplanShape => {
					if (
						floorplanShape.geometryType === GEOMETRY_TYPE_POLYGON &&
						floorplanShape.id === id
					) {
						updateVisiblityOnMap(floorplanShape, visible);
						return {
							...floorplanShape,
							visible: visible,
						};
					}
					return floorplanShape;
				}),
			);
		}
	};

	const handleShowArea = (id, value) => {
		updateFloorplanShapesVisibility(value, id);
	};

	const handleShowAllAreas = value => {
		updateFloorplanShapesVisibility(value);
	};

	const handleShowAll = value => {
		handleShowAllAreas(value);
	};

	const onSearchHandler = searchValue => {
		setAreas(
			areas.filter(area => area.title.toLowerCase().includes(searchValue.toLowerCase())),
		);
	};

	const handleViewShapeContent = areaSelected => {
		let selectedArea = areas.filter(area => area.id === areaSelected.id)[0];
		setSelectedArea(selectedArea);
		setSelectedShape(selectedArea);
		toggleMapOption(MAP_AREA_Edit);
	};

	const handleEditArea = areaId => {
		let selectedArea = areas.filter(area => area.id === areaId)[0];
		setSelectedArea(selectedArea);
		setSelectedShape(selectedArea);
		toggleMapOption(MAP_AREA_Edit);
	};

	const handleCopyArea = areaId => {
		const areaToCopy = layer.find(node => parseInt(node.id()) === areaId)[0];
		setAreaToCopy(areaToCopy);
		setModalToShow('CopyArea');
	};

	useEffect(() => {
		if (firstLoad > 0) {
			updateFloorplanShapesVisibility(!triggerShowHide);
		}
		setFirstLoad(firstLoad + 1);
	}, [triggerShowHide]);

	const getAreaVisibility = area => {
		const isVisible = floorplanShapes.some(
			floorplanShape =>
				floorplanShape.geometryType === GEOMETRY_TYPE_POLYGON &&
				floorplanShape.id === area.id &&
				floorplanShape.visible === true,
		);
		return isVisible;
	};

	const allShapesAreVisible =
		floorplanShapes.filter(
			floorplanShape =>
				floorplanShape.geometryType === GEOMETRY_TYPE_POLYGON && floorplanShape.visible,
		).length ===
		floorplanShapes.filter(
			floorplanShape => floorplanShape.geometryType === GEOMETRY_TYPE_POLYGON,
		).length;

	return (
		<>
			{
				<MapOptionCard
					isOpen={optionToShow === MAP_AREAS}
					title="Areas"
					onClick={() => toggleMapOption(MAP_AREAS)}
					loading={loading}
					headerItem={
						!noAreasAdded && (
							<div className="map-areas-container-search">
								<SearchBar
									placeholder="Search by name..."
									icon="search"
									onSearch={onSearchHandler}
								/>
								{editPermission && !floorplanArchived && isEditMode && (
									<Tooltip tooltipTitle={'Add Area'} theme="light">
										<Button
											small
											icon="plus"
											iconColor="primary"
											size="md"
											type="tertiary"
											intent="default"
											onClick={() => {
												toggleMapOption(MAP_AREA_ADD);
											}}
										/>
									</Tooltip>
								)}
							</div>
						)
					}
				>
					{loading && <LoadingSpinner className="mt-4" />}
					{!loading && (
						<div className="map-areas-container">
							{!noAreasAdded && (
								<>
									{areas.length > 0 ? (
										<div className="map-areas-container-list">
											<Button
												text={allShapesAreVisible ? 'Hide all' : 'Show all'}
												small
												className="btn-hide-all-areas"
												icon={allShapesAreVisible ? 'eye-off' : 'eye-open'}
												iconColor="tertiary"
												size="md"
												type="ghostDefault"
												intent="default"
												onClick={() => handleShowAll(!allShapesAreVisible)}
											/>

											{areas.map((area, key) => (
												<Animated key={key}>
													<AreaItem
														key={key}
														item={area}
														editPermission={editPermission}
														isEditMode={isEditMode}
														viewPermissionOnSiteId={
															viewPermissionOnSiteId
														}
														legends={legends}
														floorplan={floorplan}
														handleShowArea={handleShowArea}
														handleEditArea={handleEditArea}
														handleCopyArea={handleCopyArea}
														handleViewShapeContent={
															handleViewShapeContent
														}
														visible={getAreaVisibility(area)}
														layer={layer}
													/>
												</Animated>
											))}
										</div>
									) : (
										<Animated>
											<EmptyState
												header="No areas found"
												description={
													<>
														Your search didn't match any areas. <br />
														Please try again.
													</>
												}
												icon={noResult}
												className="mt-3"
											/>
										</Animated>
									)}
								</>
							)}
							{noAreasAdded && (
								<Animated>
									<EmptyState
										header="No areas have been added yet."
										description={
											editPermission && !floorplanArchived && isEditMode
												? 'Start drawing areas over the map'
												: ''
										}
										className="mt-5"
										icon={emptyDocument}
									>
										{editPermission && !floorplanArchived && isEditMode && (
											<Button
												text="Add area"
												size="lg"
												icon="plus"
												type="primaryDefault"
												intent="default"
												onClick={() => {
													toggleMapOption(MAP_AREA_ADD);
												}}
											/>
										)}
									</EmptyState>
								</Animated>
							)}
						</div>
					)}
				</MapOptionCard>
			}
		</>
	);
};
