import React from "react";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { checkPermission } from 'utilities/permissions';
import { ActionsButton, Button } from "componentsV2";

export const ActionsList = ({
    drillScenario,
    showEnableModal,
    loadEditModal,
    loadDeleteModal,
    history,
    showMarkAsUsedModal
}) => {
    const enabledText = drillScenario.enabled ? 'Disable' : 'Enable';
    const permissions = useSelector(state => state.route.permissions);
    const editPermission = checkPermission('drill_scenario_edit', permissions, drillScenario.site_id);

    return (
        <ActionsButton>
            <Button
                large
                text="View"
                icon="eye-open"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
                onClick={() =>
                    history.push(`/drills/scenarios/${drillScenario.id}`)
                }
            />
            <Button
                large
                text="Mark as used"
                icon="tick"
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
                onClick={() => showMarkAsUsedModal(drillScenario)}
            />
            {editPermission && drillScenario.static_id === null && (
                <Button
                    large
                    text="Edit"
                    icon="edit"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => loadEditModal(drillScenario.id)}
                />
            )}
            <Button
                large
                text={enabledText}
                icon={drillScenario.enabled ? 'disable' : 'tick'}
                size="md"
                type="ghostDefault"
                intent="default"
                wrapperClass="displayBlock"
                className="actionButtonElement"
                onClick={() => { showEnableModal(drillScenario) }}
            />
            {editPermission && drillScenario.static_id === null && (
                <Button
                    large
                    text="Delete"
                    icon="trash"
                    size="md"
                    type="ghostDefault"
                    intent="default"
                    wrapperClass="displayBlock"
                    className="actionButtonElement"
                    onClick={() => loadDeleteModal(drillScenario)}
                />
            )}
        </ActionsButton>
    );
}

ActionsList.propTypes = {
    drillScenario: PropTypes.object,
};