import React, { useState } from 'react';
import { Tab } from '@blueprintjs/core';
import { CustomTabs } from './';

const tabExamples = [
    { title: 'Tab No. 1', panel: <div>Some lovely content for Tab #1</div>, index: 0, disabled: false },
    { title: 'Tab No. 2', panel: <div>Other beatiful content for Tab #2</div>, index: 1, disabled: false },
    { title: 'Tab No. 3', panel: <div>Yet another wonderful content for Tab #3</div>, index: 2, disabled: false },
    { title: 'Tab No. 4', panel: <div>Content for disabled Tab</div>, index: 3, disabled: true }
];

const panelLoader = (index) => {
    return tabExamples[index].panel || {};
};

export const CustomTabsPattern = () => {  
    const [messageUpdated, setMessageUpdated] = useState(tabExamples[0].title + ' selected');

    const functionToRunOnTabChange = (item) => {
        setMessageUpdated(tabExamples[item].title + ' was clicked!');
    };

    return (<div>
        <label style={{fontSize:'1.2em'}}><u>Tabs with <code>onChange()</code> handler provided as <i>prop</i></u>:</label>
        <div style={{padding: '10px 0px'}}><span class='bp3-tag bp3-intent-primary bp3-large'>{messageUpdated}</span></div>
        <CustomTabs id="customTabsPattern" onChangeHandler={functionToRunOnTabChange}>
            {tabExamples.map((tab) => (
                <Tab id={tab.index} key={tab.index} panel={panelLoader(tab.index)} title={tab.title} disabled={tab.disabled} className='CustomTabs__Tab' />
            ))}                               
        </CustomTabs>
        <div style={{padding:'120px 0px'}}><hr></hr></div>
        <label style={{paddingBottom:'20px', fontSize:'1.2em'}}><u>Tabs without <code>onChange()</code> handler provided as <i>prop</i></u>:</label>
        <CustomTabs id="customTabsPatternReturns">
            {tabExamples.map((tab) => (
                <Tab id={tab.index} key={tab.index} panel={panelLoader(tab.index)} title={tab.title} disabled={tab.disabled} className='CustomTabs__Tab' />
            ))}                               
        </CustomTabs>
    </div>);
};
