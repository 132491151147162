/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { StatusPilsner } from 'components/StatusPilsner';
import {
	SchoolYearsService,
	DrillTypesService,
	DrillsStatsService,
} from 'services';
import { STATUS_MAP } from 'utilities/drills/mockData';
import { StatsByBuilding, StatsByMonth } from '../shared/Tables';

const Page = styled.div`
	min-height: 100vh;

	.dropdown-filters {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.dropdown {
			margin-right: 24px;
			margin-bottom: 16px;
			width: calc(33% - 24px);
		}
	}

	.table-description {
		display: flex;
		margin: 16px 0;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.td-left {
			display: flex;
			max-width: 50%;
			align-items: center;
			flex-direction: row;

			h4 {
				margin: 0;
				color: #10161a;
				font-size: 18px;
				font-weight: 700;
				line-height: 21px;
				font-style: normal;
				letter-spacing: 0.3px;
				font-family: Merriweather;
			}

			span {
				color: #9f1c3a;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				font-style: normal;
				letter-spacing: 0px;
				font-family: Nunito Sans;
			}
		}

		.td-right {
			display: flex;
			max-width: 50%;
			flex-wrap: wrap;
			flex-direction: row;

			.pilsner {
				max-width: initial;
				margin-left: 0;
				margin-right: 8px;
				margin-bottom: 4px;
			}
		}
	}
`;

const drillStatuses = [
	{ label: 'All drill statuses', value: 'all' },
	{ label: 'Completed', value: 'completed' },
	{ label: 'In progress', value: 'inProgress' },
	{ label: 'Scheduled', value: 'scheduled' },
	{ label: 'Overdue', value: 'overdue' },
	{ label: 'Not scheduled', value: 'notScheduled' },
]

const BASE_VIEW = {
	selectedSchoolYear: null,
	selectedDrillType: null,
	selectedMonth: null,
	selectedDrillStatus: null,

	displayByBuilding: true,
	tableLoading: false,
	stats: null,
	overviewStats: null,
};

const BASE_STATE = {
	...BASE_VIEW,
	loading: true,
	schoolYears: null,
	drillTypes: null,
	months: null,
	drillStatuses
};

export class Statistics extends React.Component {
	state = { ...BASE_STATE };

	componentDidMount() {
		Promise.all([
			SchoolYearsService.fetchYears(),
			SchoolYearsService.getMonths(true, true),
			// get drill status types
		])
			.then(([schoolYears, months]) => {
				this.setState({
					...BASE_STATE,
					schoolYears,
					months,
					loading: false,
				});
			})
			.catch(() => {
				this.setState({
					...BASE_STATE,
					loading: false,
					error: 'Could not load this page, please try refreshing',
				});
			});
	}

	componentDidUpdate(_, prevState) {
		const { selectedSchoolYear } = this.state;
		if (
			selectedSchoolYear &&
			prevState.selectedSchoolYear?.value !== selectedSchoolYear?.value
		) {
			DrillTypesService.getAll(true, selectedSchoolYear?.value).then(
				drillTypes =>
					this.setState({
						drillTypes,
						loading: false,
					}),
			);
		}
	}

	sortBuildingStats = data =>
		data.sort((a, b) => {
			const x = a.name.toUpperCase();
			const y = b.name.toUpperCase();
			if (x < y) {
				return -1;
			}
			if (x > y) {
				return 1;
			}
			return 0;
		});

	setDrillStatus = selectedDrillStatus => {
		this.setState(
			{ selectedDrillStatus, stats: null, tableLoading: true },
			this.fetchData,
		);
	};

	fetchAndToggle = () => {
		this.setState(
			({ displayByBuilding }) => ({
				tableLoading: true,
				displayByBuilding: !displayByBuilding,
			}),
			this.fetchData,
		);
	};

	fetchData = () => {
		const { displayByBuilding } = this.state;
		return displayByBuilding
			? this.fetchDataByBuilding()
			: this.fetchDataByMonth();
	};

	fetchDataByBuilding = () => {
		const {
			selectedSchoolYear,
			selectedDrillType,
			selectedMonth,
			selectedDrillStatus
		} = this.state;

		DrillsStatsService.fetchBuildingStats(
			selectedSchoolYear.value,
			selectedDrillType.uuid,
			selectedMonth.value,
		)
			.then(data => {
				data = this.filterByStatus(data, selectedDrillStatus)
				this.setState({
					tableLoading: false,
					stats: data.buildingStats
						? this.sortBuildingStats(data.buildingStats)
						: null,
					overviewStats: data.overview,
				});
			})
			.catch(() => {
				this.setState({
					tableLoading: false,
				});
				Swal.fire({
					title: 'Oops!',
					text: 'Could not get any statistics. Please try again',
					icon: 'error',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
			});
	};

	fetchDataByMonth = () => {
		const { selectedSchoolYear, selectedDrillType } = this.state;

		DrillsStatsService.fetchMonthlyStats(
			selectedSchoolYear.value,
			selectedDrillType.uuid,
		)
			.then(data => {
				this.setState({
					tableLoading: false,
					stats: data.monthlyStats,
					overviewStats: data.overview,
				});
			})
			.catch(() => {
				this.setState({
					tableLoading: false,
				});
				Swal.fire({
					title: 'Oops!',
					text: 'Could not get any statistics. Please try again',
					icon: 'error',
					toast: true,
					position: 'top',
					timer: 3000,
					showConfirmButton: false,
				});
			});
	};

	viewSchool = school => {
		const { history } = this.props;
		history.push({
			pathname: `/viewSchoolStats/${school.id}`,
			state: {
				buildingId: school.id,
				buildingName: school.name,
			},
		});
	};

	convertStatus = status => STATUS_MAP[status];

	filterByStatus = (data, status) => {
		if (status.value === 'all')
			return data
		
		data.buildingStats.map(building => {
			for (const key in STATUS_MAP) {
				if (key !== status.value)
					building[key] = 0
			}
		})
		for (const key in data.overview) {
			if (key !== status.value)
				data.overview[key] = 0
		}
		return data
	}

	render() {
		const {
			loading,
			error,
			schoolYears,
			drillTypes,
			months,
			selectedSchoolYear,
			selectedDrillType,
			selectedMonth,
			selectedDrillStatus,
			displayByBuilding,
			overviewStats,
			stats,
			tableLoading,
		} = this.state;

		const { history } = this.props;

		if (loading) return <LoadingSpinner />;

		if (error) return error;

		return (
			<Page className="">
				{/* drop downs */}
				<div className="dropdown-filters">
					{/* School year */}
					<Dropdown
						label="School year"
						placeholder="Select a school year"
						value={selectedSchoolYear}
						options={schoolYears}
						onChange={item =>
							this.setState({
								selectedSchoolYear: item,
								selectedDrillType: null,
								selectedMonth: null,
							})
						}
					/>

					{selectedSchoolYear && (
						/* Drill type */
						<Dropdown
							label="Drill type"
							placeholder="Select a Drill type"
							value={selectedDrillType}
							options={drillTypes}
							onChange={item =>
								this.setState({
									selectedDrillType: item,
									stats: null,
									selectedMonth: null,
								})
							}
						/>
					)}

					{selectedSchoolYear && selectedDrillType && (
						/* Month */
						<Dropdown
							label="Month"
							placeholder="Select a month"
							value={selectedMonth}
							options={months}
							onChange={item => this.setState({
								selectedMonth: item,
								stats: null, 
								selectedDrillStatus: null
							})}
						/>
					)}

					{selectedSchoolYear && selectedDrillType && selectedMonth && (
						/* Drill status */
						<Dropdown
							label="Drill status"
							placeholder="Select a drill status"
							value={selectedDrillStatus}
							options={drillStatuses}
							onChange={item => this.setDrillStatus(item)}
						/>
					)}
				</div>

				{stats && (
					/* table description */
					<div className="table-description">
						<div className="td-left">
							<h4>Drill statistics</h4>

							<Button
								text={
									displayByBuilding ? 'By school' : 'By month'
								}
								intent="tertiary"
								rightIcon={
									displayByBuilding
										? 'caret-down'
										: 'caret-up'
								}
								onClick={this.fetchAndToggle}
							/>
						</div>
						<div className="td-right">
							<span>Statistics Overview:</span>
							{Object.keys(STATUS_MAP).map(status => {
								if (overviewStats[status] > 0) {
									return (
										<StatusPilsner
											status={this.convertStatus(status)}
											extraText={` - ${
												overviewStats[status]
											}`}
										/>
									);
								}
							})}
						</div>
					</div>
				)}

				{tableLoading && (
					/* loading spinner while re-fetching data */
					<div style={{ marginTop: 20 }}>
						<LoadingSpinner />
					</div>
				)}

				{!tableLoading && stats && displayByBuilding && (
					<StatsByBuilding history={history} data={stats} />
				)}

				{!tableLoading && stats && !displayByBuilding && (
					<StatsByMonth history={history} data={stats} />
				)}
			</Page>
		);
	}
}

Statistics.propTypes = {
	history: PropTypes.object,
};
