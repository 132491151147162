import React from 'react';

export const PrintMapHeaderItem = ({
  label,
  value,
}) => {
  return (
    <div className="map-header-content-item">
        <div className="map-header-content-item-label">
            {label}
        </div>
        <div className="map-header-content-item-value">
            {value}
        </div>
    </div>
  )
}
