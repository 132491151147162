export const columns = [
	{
		key: 'adminStatisticsTableId',
		dataField: 'id',
		text: 'ID',
		hidden: true,
	},
	{
		key: 'team_name',
		dataField: 'team_name',
		text: 'Site',
		headerStyle: { width: '20%' },
	},
	{
		key: 'total_logins',
		dataField: 'total_logins',
		classes: 'text-center',
		text: 'Logins (All Time)',
	},
	{
		key: 'total_logins_30',
		dataField: 'total_logins_30',
		classes: 'text-center',
		text: 'Web Login (Last 30 days)',
	},
	{
		key: 'app_logins',
		dataField: 'app_logins',
		classes: 'text-center',
		text: 'App Logins (All Time)',
	},
	{
		key: 'total_users',
		dataField: 'total_users',
		classes: 'text-center',
		text: 'Users',
	},
	{
		key: 'old_call_lists',
		dataField: 'old_call_lists',
		classes: 'text-center',
		text: 'Call Lists',
	},
	{
		key: 'total_documents',
		dataField: 'total_documents',
		classes: 'text-center',
		text: 'Documents',
	},
	{
		key: 'total_images',
		dataField: 'total_images',
		classes: 'text-center',
		text: 'Images',
	},
	{
		key: 'total_floorplans',
		dataField: 'total_floorplans',
		classes: 'text-center',
		text: 'Maps',
	},
	{
		key: 'total_360s',
		dataField: 'total_360s',
		classes: 'text-center',
		text: '360s',
	},
	{
		key: 'total_cameras',
		dataField: 'total_cameras',
		classes: 'text-center',
		text: 'Camera Links',
	},
	{
		key: 'total_binders',
		dataField: 'total_binders',
		classes: 'text-center',
		text: 'Binders',
	},
	{
		key: 'total_flipcharts',
		dataField: 'total_flipcharts',
		classes: 'text-center',
		text: 'All Flipcharts',
	},
	{
		key: 'published_flipcharts',
		dataField: 'published_flipcharts',
		classes: 'text-center',
		text: 'Published Flipcharts',
	},
	{
		key: 'total_safety_plans',
		dataField: 'total_safety_plans',
		classes: 'text-center',
		text: 'Safety Plans',
	},
	{
		key: 'total_safety_plans_complete',
		dataField: 'total_safety_plans_complete',
		classes: 'text-center',
		text: 'Completed Safety Plans',
	},
	{
		key: 'total_respond_drills',
		dataField: 'total_respond_drills',
		classes: 'text-center',
		text: 'Respond Drills',
	},
	{
		key: 'total_scheduled_drills',
		dataField: 'total_scheduled_drills',
		classes: 'text-center',
		text: 'Scheduled Drills',
	},
	{
		key: 'total_completed_drills',
		dataField: 'total_completed_drills',
		classes: 'text-center',
		text: 'Completed Drills',
	},
	{
		key: 'total_completed_assessments',
		dataField: 'total_completed_assessments',
		classes: 'text-center',
		text: 'Completed Assessments',
	},
	{
		key: 'total_assessments',
		dataField: 'total_assessments',
		classes: 'text-center',
		text: 'Total Assessments',
	},
];
