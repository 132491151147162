import { DocumentService } from "services";
import { Intent } from "componentsV2";
import { Helper } from 'utilities/helper';
import { TYPE_360IMAGE, TYPE_DOCUMENT } from "pages/Documents";
import { useState } from "react";

export const UploadDocumentFn = (
    setModalToShow,
    documentData,
    dispatchDocumentData
) => {
    const [documentDetails, setDocumentDetails] = useState(null);

    const showUploadModal = (document = null) => {
        setModalToShow('uploadModal');
        setDocumentDetails(document);
        
        if (document) {
            dispatchDocumentData({
                ...documentData,
                id: document.id,
                type: document.type == TYPE_360IMAGE ? TYPE_360IMAGE : TYPE_DOCUMENT,
                oldTitle: document.title,
                title: document.type == TYPE_360IMAGE ? document.title : document.title.split('.').slice(0, -1).join('.'),
                ext: document.type == TYPE_360IMAGE ? "" : document.title.split('.').pop(),
            });
        }
        
    }

    return {
        showUploadModal,
        documentDetails
    }
}    