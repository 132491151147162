import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Dialog } from '@blueprintjs/core';
import { Button } from 'components/Button';
import Swal from 'sweetalert2';

const Progress = styled.div`
    position: absolute;
    top:0;
    background: #CED9E0;
    height: 4px;
`;

const Bar = styled.div`
	height: 100%;
  width: ${props => props.width}%;
	background: #128c5a;
	margin-top: -1px;
`;

const Title = styled.h4`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	color: #394b59;
	margin-top: 36px;
`;

const Subtitle = styled.p`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #394b59;
	margin-bottom: 25px;
`;

const SmallTitle = styled.p`
	font-family: Merriweather;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #394b59;
`;

const FileWrap = styled.div`
	box-shadow: 0px 0px 0px 1px rgba(16, 22, 26, 0.15);
	border-radius: 3px;
	padding: 16px;
`;

const BigIcon = styled(Icon)`
	color: #5c7080;
	svg {
		width: 32px;
		height: auto;
	}
`;

class DownloadBaseFile extends React.Component {
	state = {};

	downloadFile = () => {
		Swal.fire('Downloading');
		Swal.showLoading();
		const { baseFile } = this.props;
		const token = JSON.parse(localStorage.getItem('user')).jwt;
		return fetch(
			`${process.env.API_URL}/plan-templates/${
				baseFile.coreTemplate
			}/files/${baseFile.id}`,
			{
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		)
			.then(res => res.json())
			.then(resp => {
				if (resp.statusCode === 200) {
					const tempLink = document.createElement('a');
					tempLink.href = resp.data.url;
					tempLink.setAttribute('download', `${baseFile?.name}`);
					tempLink.click();
					setTimeout(() => Swal.close(), 1000);
				}
			});
	};
  getWidth = () => {
    const { valueFile } = this.props;
    return valueFile?.name ? `100%` : `0`;
  };
	render() {
		const { baseFile, changeStep } = this.props;
		return (
			<div>
        <Dialog isOpen={true} style={{width:"100%", height:"70%"}}>
				<Progress>
					<Bar width={this.getWidth()}/>
				</Progress>
				<div className="container">
					<div className="row justify-content-around mt-3">
						<div className="col-6">
							<Title>
								Step 1 of 2: Approval and implementation
								signature
							</Title>
							<Subtitle>
								Download and Sign the Approval and
								Implementation Form. Print, sign, scan, and then
								upload for it to appear in your School Emergency
								Operations Plan.
							</Subtitle>
							<SmallTitle>
								Download inline signature sheet
							</SmallTitle>
							<div className="row">
								<div className="col-8">
									<FileWrap>
										<div className="d-flex align-items-center">
											<BigIcon icon="document" />
											<div className="ml-2">
												<Title className="mt-0 mb-0">
													{baseFile?.name}
												</Title>
                        <Subtitle className="mt-0 mb-0">
                          {baseFile?.name ? (
                            <>({(baseFile.size / 1000000).toFixed(2)} MB) </>
                          ): ''}
                        </Subtitle>
											</div>
										</div>
										<hr />
										<Button
											intent="primary"
											text="Download"
											onClick={this.downloadFile}
										/>
									</FileWrap>
								</div>
							</div>
							<div className="d-flex justify-content-end mt-3">
								<Button
									text="Next"
									intent="primary"
									onClick={changeStep}
								/>
							</div>
						</div>
					</div>
				</div>
        </Dialog>
			</div>
		);
	}
}

DownloadBaseFile.propTypes = {
	baseFile: PropTypes.object,
	changeStep: PropTypes.func,
};

export default DownloadBaseFile;
