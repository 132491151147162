import React, { useState, useEffect } from 'react';
import { EmptyState, emptyDataIcon, noResultFoundIcon } from 'componentsV2';
import './css/EmptyStatuses.scss';
import { CreateMapBtn } from '../Providers/ProviderComponents';

export const EmptyStatuses = ({ filterationData, noDataAdded, editPermission }) => {
	const [emptyStatusContent, setEmptyStatusContent] = useState({
		title: '',
		description: '',
		icon: '',
	});

	useEffect(() => {
		if (noDataAdded === true) {
			setEmptyStatusContent({
				title: 'No maps were added yet.',
				description: 'Add image(s) to a site map in your Emergency Management Suite',
				icon: emptyDataIcon,
			});
		} else {
			setEmptyStatusContent({
				title: 'No maps found.',
				description:
					"Your search didn't match any maps. Please try again or add a new map.",
				icon: noResultFoundIcon,
			});
		}
	}, [filterationData]);

	return (
		<EmptyState
			header={emptyStatusContent.title}
			description={emptyStatusContent.description}
			icon={emptyStatusContent.icon}
			className={noDataAdded ? '' : 'empty-state-border'}
			showCircles={false}
			bodyClass={'empty-state-body'}
		>
			{editPermission && noDataAdded && <CreateMapBtn />}
		</EmptyState>
	);
};
