import React, { useContext } from 'react';
import { PageHeader, Button } from 'componentsV2';
import { RapidAlarmsContext } from '../../Providers';

export const Header = ({ pastAlarm, pastAlarmHistory }) => {
	const { onDownloadClick } = useContext(RapidAlarmsContext);

	const breadcrumbItems = [
		{
			label: 'Rapid alarm',
			url: '/rapid-alarms',
		},
		{
			label: 'Past alarms',
			url: '/rapid-alarms?type=past',
		},
		{
			label: 'Past alarm details',
		},
	];

	return (
		<PageHeader
			title="Past alarm details"
			subtitle="Detailed information on a specific past alarm."
			breadcrumbItems={breadcrumbItems}
			customItem={() => (
				<Button
					key="past-alarm-download"
					text="Download"
					icon="cloud-download"
					size="lg"
					type="primaryDefault"
					onClick={() => {
						onDownloadClick(pastAlarm, pastAlarmHistory);
					}}
				/>
			)}
		/>
	);
};
