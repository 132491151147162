import { FloorplanService } from 'services/FloorplanService';

export const addShape = (floorplanId, shape, map) => {
	const shapeData = shapeParams(shape, map);

	return FloorplanService.addShape(floorplanId, shapeData);
};

const shapeParams = (shape, map) => {
	return {
		type: shape.geometryType,
		title: shape.title,
		is_shape_draggable: shape.draggable,
		position_x: shape.x,
		position_y: shape.y,
		coord_lat_long: JSON.stringify(map.layerPointToLatLng(L.point(shape.x, shape.y))),
		icon_id: shape.icon_id,
		show_label: shape.showLabel,
		// fill: '',
		// fontsize: '',
		// points: '',
		// color_id: '',
		// offset_x: '',
		// offset_y: '',
		// angle: '',
		// shape_scale_x: '',
		// shape_scale_y: '',
		// shape_skew_x: '',
		// shape_skew_y: '',
	};
};
