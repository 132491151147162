import React, { useState } from 'react';
import { usePastAlarms } from 'hooks';
import { PastAlarmsTable } from './';
import { Filteration, EmptyStatuses } from '../';
import { PAST_ALARMS_PER_PAGE, PAST_ALARMS_TYPE } from '../../../consts';

export const PastAlarms = ({ filterationInitialState, history }) => {
	const [selectedSites, setSelectedSites] = useState([]);
	const {
		showEmptyStatus,
		filterationData,
		setFilterationData,
		loading,
		setLoading,
		pastAlarmList,
		totalRows,
		noDataAdded,
	} = usePastAlarms(PAST_ALARMS_PER_PAGE, false, filterationInitialState, selectedSites);

	return (
		<>
			<Filteration
				filterationData={filterationData}
				setFilterationData={setFilterationData}
				onChange={() => setLoading(true)}
				disabled={noDataAdded}
				setSelectedSites={setSelectedSites}
			/>
			{!showEmptyStatus && (
				<PastAlarmsTable
					list={pastAlarmList}
					loading={loading}
					totalRows={totalRows}
					filterationData={filterationData}
					setFilterationData={setFilterationData}
					history={history}
				/>
			)}
			{showEmptyStatus && (
				<EmptyStatuses alarmType={PAST_ALARMS_TYPE} noDataAdded={noDataAdded} />
			)}
		</>
	);
};
