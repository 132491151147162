import React from 'react';
import { fileIconsList } from 'componentsV2';
import { ShapeContentItem, NoShapeContentState } from './';
import '../css/ShapeContent.scss';

export const VideosContent = ({
	videos,
	contentType,
	shapeType,
	shapeId,
	siteId,
	editPermission,
	floorplanArchived,
	isSearchedContent,
	loadContentData,
	isEditMode,
	viewPermissionOnSiteId,
	floorplan,
}) => {
	return (
		<div className="area-content-items-container">
			{videos.length > 0 ? (
				videos.map(video => (
					<ShapeContentItem
						item={video}
						id={video.uniqId}
						ext={video.ext || 'play'}
						mimetype={video.mimeType}
						thumbnail={video.thumbnail}
						contentTitle={video.contentTitle}
						createdBy={video.createdBy}
						siteId={siteId}
						shapeId={shapeId}
						shapeType={shapeType}
						editPermission={editPermission}
						floorplanArchived={floorplanArchived}
						createdDate={video.createdDate}
						contentType={contentType}
						loadContentData={loadContentData}
						isEditMode={isEditMode}
						viewPermissionOnSiteId={viewPermissionOnSiteId}
						floorplan={floorplan}
					/>
				))
			) : (
				<NoShapeContentState
					icon={fileIconsList['play']}
					header={isSearchedContent ? 'No content found' : 'No videos currently added.'}
					description={
						isSearchedContent
							? `Your search didn’t match any ${contentType}s. Please try again.`
							: editPermission && !floorplanArchived && isEditMode
							? 'Click the + button to add a video.'
							: ''
					}
				/>
			)}
		</div>
	);
};
