import React, {useState} from "react";
import {Button}  from './';
import { Dropdown } from "../";

const rows = [
    { label: "primaryDefault", value: 0 },
    { label: "primaryDanger", value: 1 },
    { label: "secondaryDefault", value: 2 },
    { label: "secondaryDanger", value: 3 },
    { label: "tertiary", value: 4 },
    { label: "ghostPrimary", value: 5 } , 
    { label: "ghostSecondary", value: 6 } , 
    { label: "ghostDefault", value: 7 }  

]
export const ButtonPattern = () => {
    const closeModal = () => {

    }

    //const rows = ["primaryDefault", "primaryDanger", "secondaryDefault", "secondaryDanger", "tertiary", "ghostPrimary", "ghostSecondary", "ghostDefault"];
    const rowsData = []
    for (let i = 0; i < rows.length; i++) {
        rowsData.push(
        	<>
            <Button
                key={i + '0'} 
                disabled={false}
                minimal
                text=""
                type={rows[i]['label']}
                intent="default" //should be changed according to the type
                rightIcon="circle"
                size="sm"
                onClick={closeModal}
            /><span> </span> 
            <Button
                key={i + '1'} 
                disabled={false}
                minimal
                text= {'sm / ' +rows[i]['label']}
                icon="circle"
                type={rows[i]['label']}
                intent="default"
                rightIcon="circle"
                size="sm"
                onClick={closeModal}
            />	
            <span> </span> 
            <Button
                key={i + '2'} 
                disabled={true}
                minimal
                text= {'sm / ' +rows[i]['label'] + ' / disabled'}
                icon="circle"
                type={rows[i]['label']}
                intent="default"
                size="sm"
                onClick={closeModal}
            />	

            <br/><br/>
            <Button
                key={i + '3'} 
                disabled={false}
                minimal
                text=""
                type={rows[i]['label']}
                intent="default"
                rightIcon="circle"
                size="md"
                onClick={closeModal}
            />	
             <span> </span> 
            <Button
                key={i + '4'} 
                disabled={false}
                large
                text={'md / ' +rows[i]['label']}
                icon="circle"
                size="md"
                type={rows[i]['label']}
                intent="default"
                rightIcon="circle"
                onClick={closeModal}
            />	
            <span> </span> 
            <Button
                key={i + '5'} 
                disabled={true}
                large
                type={rows[i]['label']}
                size="md"
                text={'md / ' +rows[i]['label'] + ' / disabled'}
                icon="circle"
                intent="default"
                onClick={closeModal}
            />	
            <br/><br/>

            <Button
                key={i + '6'} 
                disabled={false}
                minimal
                text=""
                type={rows[i]['label']}
                intent="default"
                rightIcon="circle"
                size="lg"
                onClick={closeModal}
            />	
            <span> </span> 
            
            <Button
                key={i + '7'} 
                disabled={false}
                large
                text={'lg / ' +rows[i]['label']}
                icon="circle"
                type={rows[i]['label']}
                size="lg"
                intent="default"
                rightIcon="circle"
                onClick={closeModal}
            />	
            <span> </span> 

            <Button
                key={i + '8'} 
                disabled={true}
                large
                size="lg"
                text={'lg / ' +rows[i]['label'] + ' / disabled'}
                icon="circle"
                type={rows[i]['label']}
                intent="default"
                onClick={closeModal}
            />	
            <br/><br/>

            <Button
                key={i + '9'} 
                disabled={false}
                minimal
                text=""
                type={rows[i]['label']}
                intent="default"
                rightIcon="circle"
                size="xl"
                onClick={closeModal}
            />	
            <span> </span> 
            
            <Button
                key={i + '10'} 
                disabled={false}
                xlarge
                text={'xl / ' +rows[i]['label']}
                icon="circle"
                type={rows[i]['label']}
                size="xl"
                intent="default"
                rightIcon="circle"
                onClick={closeModal}
            />	
            <span> </span>             
            <Button
                key={i + '11'} 
                disabled={true}
                xxlarge
                type={rows[i]['label']}
                size="xl"
                text={'xl / ' +rows[i]['label'] + ' / disabled'}
                icon="circle"
                intent="default"
                onClick={closeModal}
            />	
            <br/><br/>

            <Button
                key={i + '12'} 
                disabled={false}
                minimal
                text=""
                type={rows[i]['label']}
                intent="default"
                rightIcon="circle"
                size="xxl"
                onClick={closeModal}
            />	
            <span> </span> 
            <Button
                key={i + '13'} 
                disabled={false}
                large
                text={'xxl / ' +rows[i]['label']}
                icon="circle"
                type={rows[i]['label']}
                size="xxl"
                intent="default"
                rightIcon="circle"
                onClick={closeModal}
            />
            <span> </span> 
            <Button
                key={i + '14'} 
                disabled={true}
                xxlarge
                size="xxl"
                text={'xxl / ' +rows[i]['label'] + ' / disabled'}
                icon="circle"
                type={rows[i]['label']}
                intent="default"
                onClick={closeModal}
            />
           
            <br/><br/>
		</>
        );
    }

    const [optionval, setOption] = useState(rowsData[0])
    const [optionlabel, setOptionLabel] = useState(rows[0])

    const handleOptionChange = (e) => {
        console.log(e)
      setOption(rowsData[e.value])
      setOptionLabel(e)
    }
    return (
        <>
         <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h4 className='mb-2'>Button Options</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <Dropdown
                                        className="mt-3"
                                        label="Button Type"
                                        placeholder="Select Button Type"
                                        value={optionlabel}
                                        options={rows}
                                        onChange={handleOptionChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="ButtonPattern_wrapper">
                {optionval}
                <br />
            </div>
        </>
	);

}
