import React, { useState } from 'react';
import { Button , EmptyState, FileUpload, Dropdown, Accordion } from '../';
import { maps } from '../EmptyState/icons';

export const AccordionPattern = () => {
	const [selectedOptions, setSelectedOptions] = useState();
	const [isOpenContent, setIsOpenContent] = useState(false);

	const options = [{ value: true, label: 'True' }, { value: false, label: 'False' }];
	const onChange = selected => {
		setSelectedOptions(selected);
		setIsOpenContent(selected.value)
	};
	return (
		<>
			<div className="row">
				<div className="col-md-6">
					<Dropdown
						className="mt-3"
						label="set isOpenContent manually - to keep the items expanded"
						placeholder="Select isOpenContent manually"
						value={selectedOptions}
						options={options}
						onChange={onChange}
					/>
				</div>
			</div>
			<div className="row">
				<Accordion
					items={[
						{
							id: 0,
							title: 'title 1 - Text example',
							isOpenContent: isOpenContent,
							content: (
								<>
									Amidst the vibrant cityscape, a coffee shop buzzes with energy.
									Patrons sip on rich espresso, engaging in lively conversations.
									The aroma of freshly baked pastries fills the air, creating a
									cozy, welcoming ambiance
								</>
							),
						},
						{
							id: 1,
							title: 'title 2 - file uploader example',
							isOpenContent: isOpenContent,
							content: (
								<>
									<FileUpload
										disabled={false}
										required={true}
										allowedFiles={[
											'png',
											'jpeg',
											'jpg',
											'svg',
											'gif',
											'mp3',
											'mp4',
										]}
										allowedFilesLegend="SVG, PNG, JPG or GIF (max. 800x400px) or MP3/MP4"
										defaultErrorMessage="Upload failed, please try again"
										label="Upload your audio file"
										hintText="This is an optional hint text to help user."
									/>
								</>
							),
						},
						{
							id: 2,
							title: 'title 3 - EmptyState Example',
							isOpenContent: isOpenContent,
							content: (
								<>
									<EmptyState
										header="EmptyState Example"
										description="EmptyState Example for Accordion"
										icon={maps}
									>
										<>
											<Button
												type="secondaryDefault"
												text="cancel action"
												size="lg"
												onClick={() => {}}
											/>
											<Button
												type="primaryDefault"
												text="confirm action"
												size="lg"
												onClick={() => {}}
											/>
										</>
									</EmptyState>
								</>
							),
						},
					]}
				/>
			</div>
		</>
	);
};
