import React from 'react';
import { PrintMapHeaderItem } from './PrintMapHeaderItem';

export const PrintMapHeader = ({
    floorplan,
    floorplanDetails,
}) => {
  return (
    <div className="map-header-container">
        <div className="map-header-title-container">
            <div className="map-header-title">
                Map Title
            </div>
            <div className="map-header-title-label">
                {floorplan.title}
            </div>
        </div>
        <div className="map-header-content-container">
            <PrintMapHeaderItem 
                label="Site name"
                value={floorplan.building.name}
            />
            <PrintMapHeaderItem 
                label="Site location"
                value={
                    <span>
                        {floorplan.building.address}
                        <br />
                        {floorplan.building.city}, {floorplan.building.state} {floorplan.building.zip}, {floorplan.building.country}
                    </span>
                }
            />
            <PrintMapHeaderItem 
                label="Last modified by and date"
                value={
                    <span>
                        {`${floorplanDetails?.modifiedBy?.first_name} ${floorplanDetails?.modifiedBy?.last_name}`}
                        <br />
                        {floorplanDetails?.formattedDateCreated}
                    </span>
                }
            />
            <PrintMapHeaderItem 
                label="Created by and date"
                value={
                    <span>
                        {`${floorplanDetails?.createdBy?.first_name} ${floorplanDetails?.createdBy?.last_name}`}
                        <br />
                        {floorplanDetails?.formattedDateCreated}
                    </span>
                }
            />
            <PrintMapHeaderItem 
                label="Office phone"
                value={floorplanDetails?.building?.officePhone}
            />
            <PrintMapHeaderItem 
                label="Emergency phone"
                value={floorplanDetails?.building?.emergencyPhone}
            />
        </div>
    </div>
  )
}
