import React from 'react';
import { ProgressBar, NavCard } from 'componentsV2';
import PropTypes from 'prop-types';
import './css/SetupGuide.scss';

export const SetupGuide = ({ sectionsList, activeSection, onChange }) => {
	const completedCount = sectionsList.filter(cardItem => cardItem.completed).length;
	const progressBarLabel = (
		<>
			Setup guide <br /> ({completedCount} out of {sectionsList.length} tasks completed)
		</>
	);

	return (
		<section className="create-alarm-type-body-setup-guide">
			<ProgressBar
				label={progressBarLabel}
				value={completedCount}
				total={sectionsList.length}
			/>
			<div className="create-alarm-type-body-setup-guide-cards-list">
				{sectionsList.map(section => (
					<NavCard
						key={section.name}
						name={section.name}
						selected={section.name === activeSection}
						completed={section.completed}
						onClick={() => onChange(section.name)}
					/>
				))}
			</div>
		</section>
	);
};

SetupGuide.propTypes = {
	sectionsList: PropTypes.array.isRequired,
	activeSection: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};
