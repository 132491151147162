import React, { useState, useEffect } from 'react';
import { Dropdown } from 'components/Dropdown';

export default function RolesDropDown (
    {
        id = "",
        siteId = null,
        onSelectItem = null,
        value = null,
        dataIndex = null,
        assignedRoles = [],
        isReadOnly = false,
        savedName = ''
    }
) {
    const [selectedItemValue,setSelectedItemValue] = useState(value);
    const [selectedItem,setSelectedItem] = useState({'label':'None', 'value':null});
    const [isDisabled,setIsDisabled] = useState(true);
    const [roles,setRoles] = useState([]);
    const [disabledRoles,setDisabledRoles] = useState([]);
    const url = process.env.API_URL; //const url = "http://localhost:8086";
    const token = JSON.parse(localStorage.getItem('user')).jwt;
    
    const selectItem = (event) => 
    {
        if (disabledRoles.length > 0 && disabledRoles.indexOf(event.value) > -1 ){
            return false;
        }

        setSelectedItemValue(event.value);
        setSelectedItem(event);
        
        if (onSelectItem != null) {
            onSelectItem(assignedRoles, dataIndex, event.value, value);
        }
    }

    const firstLabel = () => {
        if (siteId == null) {
            return 'You have to select Site';
        }

        if (isDisabled) {
            return 'Loading Roles...';
        }
       
        return 'Select Role';
    };

    const firstOption = { 
        label: firstLabel(), 
        value: 0 
    };

    const setOptions = (rolesResp) => 
    {
        const allOptions = [];

        if (rolesResp.length > 0) {
            rolesResp.map(item => {
                //hide first responder
                if (item.id != 3) {
                    allOptions.push({
                        label: item.title,
                        value: item.id
                    });
                }                
            });
        }
        
        return allOptions;
    };

    const getSelectedOption = (roleOptions) => 
    {
        let selectedItm = null; 
        if ( value != null ) {
            let allOpts = roleOptions;
            let kFound = null;
            allOpts.map((itm,k) => {
                if (itm.value == value) {
                    kFound = k;
                    return;
                }
            });
            selectedItm = allOpts[kFound]; 
        } else {
            firstOption.label = 'Select Role';
            selectedItm = firstOption;
        }
        setSelectedItem(selectedItm);
        setSelectedItemValue(selectedItm.value);
    };

    const getRoles = () => 
    {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('sites[]', siteId);
        let path = urlSearchParams.toString();
       
        fetch(`${url}/roles?${path}`, { 
            method: 'GET',
            headers: {
               Authorization: `Bearer ${token}`,
            },
        })
        .then(res => res.json())
        .then(resp => {
            setIsDisabled(false);
            let roleOptions = setOptions(resp.data);
            setRoles(roleOptions);
            getSelectedOption(roleOptions);
            //disable options
            let disabledRolesIds = [];
            assignedRoles.map((assignedRole) => {
                if (assignedRole.building.id == siteId && assignedRole.role.id != null) {
                    disabledRolesIds.push(assignedRole.role.id);
                }
            });
            setDisabledRoles(disabledRolesIds)
        })
    };

    const getFieldLabel = () => 
    {
        return dataIndex==0 ? 'Primary Role' : 'Role';
    };

    const getSelectedValue = () => 
    {
        return (selectedItem?.value!=null) ? selectedItem : firstOption;
    };

    useEffect(() => {      
        if (siteId != null) {
            if (isReadOnly) { 
                setSelectedItem({
                    'label' : savedName,
                    'value' : value
                });
                setIsDisabled(true);
            } else {
                getRoles();
            }            
        }
    },[]);

    return (
        <Dropdown 
            label={getFieldLabel()}
            value={getSelectedValue()}
            options={!isDisabled ? roles : []}
            onChange={(e) => selectItem(e)}
            disabled={isDisabled}
            disabledOptions={disabledRoles}
            id={id}
        />            
    );
}
