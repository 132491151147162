import React, { useState, useContext } from 'react';
import { Intent } from '@blueprintjs/core';
import { ToasterContext } from 'pages/App';
import {
	ChatContainer,
	ChatForm,
	ChatMessage,
	ChatMessageDate,
	ChatMessagesContainer,
	ChatRoom,
	ChatRoomItem,
} from './components';

export const ChatPattern = () => {
	const toaster = useContext(ToasterContext);

	// Everyone states
	const [showEveryoneChatRoom, setShowEveryoneChatRoom] = useState(false);
	const [expandEveryoneChatRoom, setExpandEveryoneChatRoom] = useState(false);
	const [everyoneMessageForm, setEveryoneMessageForm] = useState('');
	const [everyoneNotifications, setEveryoneNotifications] = useState(1);

	// Admins States
	const [showAdminsChatRoom, setShowAdminsChatRoom] = useState(false);
	const [expandAdminsChatRoom, setExpandAdminsChatRoom] = useState(false);
	const [adminsMessageForm, setAdminsMessageForm] = useState('');
	const [adminsNotifications, setAdminsNotifications] = useState(2);

	// Everyone handlers
	const handleOpenEveryoneChatRoom = () => {
		setShowEveryoneChatRoom(true);
		setExpandEveryoneChatRoom(value => !value);
		setEveryoneNotifications(0);
	};

	const handleChangeMessageEveryone = ({ target }) => {
		setEveryoneMessageForm(target.value);
	};

	const handleSendMessageEveryone = () => {
		toaster('Message to Everyone: ' + everyoneMessageForm, Intent.SUCCESS);
		setEveryoneMessageForm('');
	};

	// Admins handlers
	const handleOpenAdminsChatRoom = () => {
		setShowAdminsChatRoom(true);
		setExpandAdminsChatRoom(value => !value);
		setAdminsNotifications(0);
	};

	const handleChangeMessageAdmins = ({ target }) => {
		setAdminsMessageForm(target.value);
	};

	const handleSendMessageAdmins = () => {
		toaster('Message to Admins: ' + adminsMessageForm, Intent.WARNING);
		setAdminsMessageForm('');
	};

	return (
		<>
			<div className="container-fluid px-0 tab-section mt-3 mb-5">
				<div className="row px-3 my-3">
					<div className="col-md-12">
						<div className="mb-2">Chat Structure</div>
						<pre>
							{`<ChatContainer>
	<ChatRoom
		title="Chat"
		notifications={3}
		optionToClose={false}
		startMinimized={true}
		showChatRoom={true}
		setShowChatRoom={() => {}},
		expand={false}
	>
		<ChatRoomItem
			onClick={() => {}}
			title="Everyone"
			description="Chris: Thank you!"
			notifications={1}
		/>
		<ChatRoomItem />
	</ChatRoom>

	<ChatRoom>
		<ChatMessagesContainer>
			<ChatMessageDate date="Today" />
			<ChatMessage
				author="Johnny Smith"
				text="I want somebody to notify the security officer to get prepared."
				time="4:22 AM"
			/>
			<ChatMessage
				showAuthor={false}
				text="Please it's urgent!!"
				time="4:23 AM"
			/>
			<ChatMessage author="me" text="Okay, will notify them." time="4:30 AM" />
			<ChatMessage />
			<ChatMessage />
			<ChatForm
				message=""
				handleChangeMessage={() => {}}
				handleSendMessage={() => {}}
			/>
		</ChatMessagesContainer>
	</ChatRoom>
</ChatContainer>`}
						</pre>
					</div>
				</div>
			</div>
			<ChatContainer>
				<ChatRoom
					title="Chat"
					notifications={everyoneNotifications + adminsNotifications}
					optionToClose={false}
					startMinimized={true}
				>
					<ChatRoomItem
						onClick={handleOpenEveryoneChatRoom}
						title="Everyone"
						description="Chris: Thank you!"
						notifications={everyoneNotifications}
					/>
					<ChatRoomItem
						onClick={handleOpenAdminsChatRoom}
						title="Admins"
						description="You: Lorem ipsum dolor sit amet, consectet..."
						notifications={adminsNotifications}
					/>
				</ChatRoom>

				<ChatRoom
					title="Everyone"
					notifications="1"
					showChatRoom={showEveryoneChatRoom}
					setShowChatRoom={setShowEveryoneChatRoom}
					expand={expandEveryoneChatRoom}
				>
					<ChatMessagesContainer>
						<ChatMessageDate date="Today" />
						<ChatMessage
							author="Johnny Smith"
							text="I want somebody to notify the security officer to get prepared."
							time="4:22 AM"
						/>
						<ChatMessage
							showAuthor={false}
							text="Please it's urgent!!"
							time="4:23 AM"
						/>
						<ChatMessage author="me" text="Okay, will notify them." time="4:30 AM" />
						<ChatMessage
							author="Johnny Smith"
							text="Thank you! please let them know i am waiting"
							time="4:40 AM"
						/>
					</ChatMessagesContainer>
					<ChatForm
						message={everyoneMessageForm}
						handleChangeMessage={handleChangeMessageEveryone}
						handleSendMessage={handleSendMessageEveryone}
					/>
				</ChatRoom>

				<ChatRoom
					title="Admins"
					notifications="2"
					showChatRoom={showAdminsChatRoom}
					setShowChatRoom={setShowAdminsChatRoom}
					expand={expandAdminsChatRoom}
				>
					<ChatMessagesContainer>
						<ChatMessageDate date="Yesterday" />
						<ChatMessage
							author="Will Smith"
							text="I want somebody to notify the security officer to get prepared."
							time="4:22 AM"
						/>
						<ChatMessage author="me" text="Okay, will notify them." time="4:30 AM" />
						<ChatMessage
							author="Will Smith"
							text="Please it's urgent!!"
							time="4:33 AM"
						/>
						<ChatMessage
							author="me"
							text="Sorry, let me notify them again..."
							time="4:35 AM"
						/>
						<ChatMessage
							showAuthor={false}
							author="me"
							text="The security officer was notified."
							time="4:37 AM"
						/>
						<ChatMessage
							author="Will Smith"
							text="Thanks for your help!"
							time="4:40 AM"
						/>
					</ChatMessagesContainer>
					<ChatForm
						message={adminsMessageForm}
						handleChangeMessage={handleChangeMessageAdmins}
						handleSendMessage={handleSendMessageAdmins}
					/>
				</ChatRoom>
			</ChatContainer>
		</>
	);
};
