import React from 'react';
import { PageHeader } from 'components/PageHeader';

export const Header = () => {
	return (
		<PageHeader
			title="Edit Rapid Alarm"
			subtitle="Monitor active and past alarms, conduct drills and training sessions, and set up and manage different alarm types."
			customItem={() => {}}
		/>
	);
};
