import React from "react";
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { Icon } from '@blueprintjs/core';

const BuildingAlert = ({ 
    building,
    onDeleteAction, 
    isReadOnly = false
}) => {
    return (
        <div className="col-md-12 d-flex justify-content-start">
            <Input
                type='text'
                label="Site"
                name = 'mobilePhone'
                className="respond-settings-site-name"
				value={building?.name}
                onChange={() => {}}
                readOnly={isReadOnly}
            />                  
            <Icon icon="info-sign" className='respond-settings-site-info' htmlTitle="View Building Details" color='#5C7080' />
            <div className="respond-settings-delete-btn">
                <Button
                    type="button"
                    icon='remove'
                    minimal={true}
                    intent ='DANGER'
                    className='no-hover-backgound' 
                    onClick={onDeleteAction}
                    disabled={isReadOnly}
                />              
            </div>                      
        </div> 
    );
}

export default BuildingAlert;