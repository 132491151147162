import React, {useState, useEffect, useReducer, useContext} from "react";
import { AlertModal, Button } from "componentsV2";
import { ToasterContext } from 'pages/App';
import { Intent } from '@blueprintjs/core';
import { PlanService } from "services";

export const PublishModal = ({
    modalName,
    modalToShow,
    closeModal,
    sectionData,
    template
}) => {
	const [loading, setLoading] = useState(true);
	const toaster = useContext(ToasterContext);

    const modalDetailsReducer = (modalDetails, action) => {
        modalDetails = action.payload;
        return {
            title: modalDetails.title,
            subtitle: modalDetails.subtitle,
            alertConfirmButton: modalDetails.alertConfirmButton,
            alertCancelButton:modalDetails.alertCancelButton,
            hasError:modalDetails.hasError,
        }
    }

    const initialState = {
        title: ' ',
        subtitle: ' ',
        alertConfirmButton: null,
        alertCancelButton:null,
        hasError:false
    };

    const [modalDetails, dispatchModalDetails] = useReducer(modalDetailsReducer, initialState);

    useEffect(() => {
        validate()
    }, []);

    const validate = () => {
        if (sectionData.is_section === true) {
            OpenValidationSuccess()
        } else {
            checkMainSectionValidation()
        }
    }

    const openFileValidationError = () => {
        dispatchModalDetails({ payload:
            {
                ...modalDetails,
                title: sectionData.item.title + " is missing a file upload.",
                subtitle:"Please attach a file to continue.",
                alertConfirmButton:<Button
                    text={`OK`}
                    type="primaryDefault"
                    size="md"
                    onClick={closeModal}
                />,
                hasError:true,
                alertCancelButton:null
            }
        });
        setLoading(false);
    }

    const OpenValidationSuccess = () => {
        dispatchModalDetails({ payload:
            {
                ...modalDetails,
                title: 'Are you sure you would like to create an update for "'+ (sectionData.is_section ? sectionData.item.title : sectionData.subSectionItem.title) + '"?',
                subtitle:"",
                hasError:false,
                alertConfirmButton:<Button
                    text={`Yes, publish`}
                    type="primaryDefault"
                    size="md"
                    onClick={publishUpdates}
                />,
                alertCancelButton:<Button
                text="No, go back"
                type="tertiary"
                size="md"
                onClick={closeModal}
            />
            }
        });
        setLoading(false);
    }

    const checkMainSectionValidation = () => {
        PlanService.checkPublishValidation(parseInt(template.id), sectionData.item.id)
        .then(resp =>{
            if(resp.statusCode === 200){
                if (!resp.data.published) {
                    dispatchModalDetails({ payload:
                        {
                            ...modalDetails,
                            title: "This subsection cannot be published until its main section has been published.",
                            subtitle:"Publish the main section in order to publish the subsection.",
                            alertConfirmButton:<Button
                                text={`OK`}
                                type="primaryDefault"
                                size="md"
                                onClick={closeModal}
                            />,
                            hasError:true,
                            alertCancelButton:null
                        }
                    });
                } else {
                    if (sectionData.subSectionItem.hasOwnProperty('fileSection')) {

                        if (sectionData.subSectionItem.reupload && !sectionData.subSectionItem.baseFile.id) {
                            openFileValidationError()
                        } else {
                            OpenValidationSuccess()
                        }

                    } else {
                        OpenValidationSuccess()
                    }
                }
            }
        }) .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const publishUpdates = () => {

        setLoading(true);

        let updatedContent = {}

        const templateBody =  JSON.stringify({
            headers: JSON.stringify(template.headers),
            content: JSON.stringify(
                template.sections,
            ),
            name: template.name,
            state: template.state,
        })

        const actualSectionId = sectionData.item.id;
        const actualSubsectionId = sectionData.subSectionItem ? sectionData.subSectionItem.id : null;
        const sectionIndex = sectionData.section_index;
        const subsectionIndex = sectionData.subsection_index;

        const tempcontent = (sectionData.is_section ? sectionData.item.content : sectionData.subSectionItem.content)
        let updatedTitle = "";
		let isClient = 0;

        if (actualSubsectionId) {
            template.sections[sectionIndex].subsections[subsectionIndex].content = tempcontent;
            updatedTitle = template.sections[sectionIndex].subsections[subsectionIndex].title;
        } else {
            template.sections[sectionIndex].content = tempcontent;
            updatedTitle = template.sections[sectionIndex].title;
            isClient = template.sections[sectionIndex].isClient ? template.sections[sectionIndex].isClient : false;
        }

        if (!sectionData.is_section && sectionData.subSectionItem.hasOwnProperty('fileSection')) {
            updatedContent.is_document = true;
            tempcontent = '';

            if (sectionData.subSectionItem.reupload) {
                updatedContent.reupload = true;

                if (!!sectionData.subSectionItem.baseFile?.name) {
                    updatedContent['baseFile'] = sectionData.subSectionItem.baseFile;
                }
            } else {
                isClient = 1;
            }
        }

        updatedContent.actualSectionId = actualSectionId;
        updatedContent.actualSubsectionId = actualSubsectionId;
        updatedContent.content = tempcontent;
        updatedContent.title = updatedTitle;
        updatedContent.index = actualSubsectionId? subsectionIndex : sectionIndex;
        updatedContent.isClient = isClient;

        const updateBody = {
            type: 'update',
            updatedContent: JSON.stringify(updatedContent),
            actualSectionId : sectionData.item.id,
            actualSubsectionId: actualSubsectionId,
        }

        PlanService.publishUpdates(template.id, updateBody).then(resp => {
            if (resp.statusCode === 201) {
                if(resp.data.length > 0) {
                    PlanService.updatePlan(template.id, templateBody).then(resp =>{
                        toaster('You have successfully published "' + (sectionData.is_section ? sectionData.item.title : sectionData.subSectionItem.title) + '".', Intent.SUCCESS)
                    }).catch(err => {
                        toaster("Failed To Publish!", Intent.DANGER);
                    })
                    .finally(() => {
                        setLoading(false);
                        closeModal();
                    });
                } else {
                    toaster("Updates cannot be published since there are no safety plans to update", Intent.NONE);
                    setLoading(false);
                    closeModal();
                }

            } else {
                toaster("Failed To Publish!", Intent.DANGER);
            }
        }).catch(err => {
            toaster("Failed To Publish!", Intent.DANGER);
        })
    }

    return (
        <AlertModal
            isOpen={modalToShow === modalName}
            onClose={closeModal}
            loading={loading}
            size='medium'
            title={modalDetails.title}
            subtitle={modalDetails.subtitle}
            showCloseButton={false}
            alertConfirmButton={modalDetails.alertConfirmButton}
            alertCancelButton={modalDetails.alertCancelButton}
            icon= { modalDetails.hasError ? 'warning-sign' : null }
            iconColor= { modalDetails.hasError ? 'warning' : null }
        />
    );
}
