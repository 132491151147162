import React, { useContext } from 'react';
import { AlertModal, Button } from 'componentsV2';
import PropTypes from 'prop-types';
import { MapsContext } from '../../Providers';

export const UnsavedChangesModal = ({
	saveChangesHandler,
	discardChangesHandler,
	shapeType = '',
	nextModalToShow,
	setNextModalToShow,
}) => {
	const { setModalToShow } = useContext(MapsContext);

	const onDiscardClick = () => {
		const res = discardChangesHandler();
		if (res) {
			checkNextModalToOpen();
		}
	};

	const onSaveClick = () => {
		const res = saveChangesHandler();
		if (res) {
			checkNextModalToOpen();
		}
	};

	const checkNextModalToOpen = () => {
		if (nextModalToShow) {
			setModalToShow(nextModalToShow);
			setNextModalToShow(null);
		}
	};

	return (
		<AlertModal
			isOpen={true}
			loading={false}
			size="medium"
			title={'This ' + (shapeType ? shapeType : 'map') + ' has unsaved changes'}
			subtitle="The changes you have made have not been saved. Do you want to continue?"
			customClassName="map-container-modals"
			showCloseButton={false}
			alertConfirmButton={
				<Button text={'Save'} type="primaryDefault" size="md" onClick={onSaveClick} />
			}
			alertCancelButton={
				<Button text="Discard changes" type="tertiary" size="md" onClick={onDiscardClick} />
			}
			icon="info-sign"
			iconColor="warning"
		/>
	);
};

UnsavedChangesModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	saveChangesHandler: PropTypes.func.isRequired,
};
