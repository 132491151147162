import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { TinymceEditor, FormModal, ToasterContext, Intent, FeaturedIcon } from 'componentsV2';
import { DrillTypeInstructionsService } from 'services';

export const EditDrillInstructionsModal = ({ onCloseModal, isOpen, drillInstructionData }) => {
	const [instructionsValue, setInstructionsValue] = useState('');
	const toaster = useContext(ToasterContext);
	const userData = localStorage.getItem('loggedUserData');
	const loggedUserData = JSON.parse(userData);
	const drillState = loggedUserData.primaryBuilding.state;

	useEffect(() => {
		getDrillInstructions();
	}, []);

	const getDrillInstructions = () => {
		DrillTypeInstructionsService.getWithParams(
			drillState,
			drillInstructionData?.districtRequirement?.year,
			drillInstructionData?.uuid,
		).then(res => {
			setInstructionsValue(res?.data?.schedulingInstructions);
		});
	};
	const onSubmitInstructions = () => {
		DrillTypeInstructionsService.save(
			drillState,
			drillInstructionData?.districtRequirement?.year,
			drillInstructionData?.uuid,
			instructionsValue,
			null,
		)
			.then(() => {
				toaster(
					`You have successfully updated the instructions for “${
						drillInstructionData.name
					}”.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" size="md" type="Success" shape="circleIcon" />,
				);
			})
			.catch(() => {
				toaster(
					`There was an error adding the instructions. Please try again.`,
					Intent.DANGER,
				);
			})
			.finally(() => {
				onCloseModal();
			});
	};

	return (
		<FormModal
			isOpen={isOpen}
			title={`Manage "${drillInstructionData?.name}" Instructions`}
			showCloseButton={false}
			onClose={onCloseModal}
			cancelBtn={{ title: 'Cancel', onClick: onCloseModal }}
			confirmBtn={{
				title: 'Save',
				onClick: onSubmitInstructions,
				type: 'primaryDefault',
				disabled: !instructionsValue,
			}}
		>
			<TinymceEditor
				value={instructionsValue}
				placeholder="Enter Instructions..."
				onChange={() => {}}
				onEditorChange={content => setInstructionsValue(content)}
				setupProps={() => {}}
			/>
		</FormModal>
	);
};
EditDrillInstructionsModal.propTypes = {
	onCloseModal: PropTypes.func,
	isOpen: PropTypes.any,
	drillInstructionData: PropTypes.any,
};
