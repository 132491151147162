import React, { useEffect, useState } from "react";
import BootstrapedTable from "components/BootstrapedTable";
import { Icon, Button } from '@blueprintjs/core';
import '../css/ArchivedTemplatesTable.scss';
import ActionsDropdown from "components/ActionsDropdown";
import { formatDate } from "utilities/dates";
import { LoadingSpinner } from 'components/LoadingSpinner';
import Swal from 'sweetalert2';
import { fireSwal } from "utilities/fireSwal";

const ArchivedTemplatesTable = ({
	showToaster,
	reloadPlans,
	history,
	selectedSite
}) => {
    const [archivedTemplates, setArchivedTemplates] = useState([]);
    const [pageDetails, setPageDetails] = useState({
		page: 1,
		perPage: 10,
        sortKey: null,
        sortDir: null,
	});
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
	const [reloadData, setReloadData] = useState(false);

	const token = JSON.parse(localStorage.getItem('user')).jwt;

    useEffect(() => {
        let filters = `page=${pageDetails.page}&perPage=${pageDetails.perPage}`;
        
        if(pageDetails.sortKey && pageDetails.sortDir) {
            filters += `&sortKey=${pageDetails.sortKey}&sortDir=${pageDetails.sortDir}`
        }

		if(selectedSite && selectedSite > 0) {
            filters += `&siteId=${selectedSite}`;
		}

        fetch(`${process.env.API_URL}/district-templates/archived?${filters}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
    			},
            }).then(res => res.json())
            .then(response => {
                setLoading(false);
                if(response.statusCode === 200) {
                    setArchivedTemplates(response.data.records);
                    setTotalRecords(response.data.metadata.totalRecords);
                }
            })
            .catch(err => {
                setLoading(false);
            })
    }, [pageDetails, reloadData, selectedSite]);

	const unarchivTemplateHandler = (id) => {
		Swal.fire({
			title: 'Unarchive plan?',
			text: "Are you sure you want to unarchive this plan?",
			showCancelButton: true,
			customClass: {
				confirmButton: 'bp3-button bp3-intent-danger',
				cancelButton: 'bp3-button',
			},
			confirmButtonText: 'Confirm',
			reverseButtons: true,
            showCloseButton: true,
		}).then(result => {
			if (result.value) {
				Swal.fire('Updating');
				onConfirmActionHandler(id).then(res => res.json())
                .then(resp => {
					Swal.close();
                    if (resp.statusCode === 200) {
						setReloadData(oldValue => !oldValue)
						reloadPlans();
                    } else {
						fireSwal('error', 'Something went wrong!');
					}
                }).catch(err => {
					fireSwal('error', 'Something went wrong!')
					Swal.close();
				});
			}
			return Swal.close();
		});
	}
	
    const onConfirmActionHandler = templateId => {
		const token = JSON.parse(localStorage.getItem('user')).jwt;
        return fetch(
            `${process.env.API_URL}/district-templates/${templateId}/toggle-archive`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        )
    }
    
	const viewTemplateHandler = (id) => {
		history.push(`/view/${id}`);
	}

    const ActionsList = ({
		id
	}) => (
        <>
            <Button
                minimal
                icon="eye-open"
                text="View"
                title="View"
                className="w-100 justify-content-start"
                onClick={() => viewTemplateHandler(id)}
            />
            <Button
                minimal
                icon="unarchive"
                text="Unarchive"
                title="Unarchive"
                className="w-100 justify-content-start"
                onClick={() => unarchivTemplateHandler(id)}
            />
        </>
    )

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true 
		},
		{	
			key: 'name',
			dataField: 'name',
			text: 'Safety Plan Name',
			classes:'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%'},
		},
		{
			key: 'buildingName',
			dataField: 'buildingName',
			text: 'Site',
			classes:'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%'},
		},
		{
			key: 'archivedBy',
            dataField: 'archivedBy',
			text: 'Archived By',
			classes:'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%'},
			formatter: (value, item ) => {
				return item.familyName+', '+item.givenName
			}
		},
		{
			key: 'archivedDate.date',
			dataField: 'archivedDate.date',
			text: 'Archived Date',
			classes:'bold-500',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			headerStyle: { width: '20%'},
			formatter: (value) => {
				return formatDate(value);
			}
		},
		{
			key: 'actions',
            dataField: 'id',
			text: 'Actions',
			formatter: (value, row) => (
                <ActionsDropdown 
                    actionsList={
						<ActionsList 
							id={value}
						/>
					}
                />
			)
		}
	];

	const sortingStyle = (order, column) => {
		if (order === 'desc') {
			return <Icon icon="caret-down" />
		}
		return <Icon icon="caret-up" />
	};

	// change row color with disabled callList
	const rowStyle = (row, rowIndex) => {
		return {
            color: '#777'
        };
	};
	
	const onTableChange = (type, { sortField, sortOrder }) => {
		if (type === 'sort') {
			let sortColumn = 'name';
			switch (sortField) {
				case 'name':
                    sortColumn = 'planName';
				break;
				case 'buildingName':
					sortColumn = 'siteName';
				break;
				case 'archivedDate.date':
					sortColumn = 'archivedDate';
                break;
				default:
					sortColumn = sortField;
				break;
			}
            
            setPageDetails(pageDetails => ({
                ...pageDetails,
                sortKey: sortColumn,
                sortDir: sortOrder
            }))
		}
	};

    const onCurrentPageChange = currentPage => {
        setPageDetails(pageDetails => ({
            ...pageDetails,
            page: currentPage,
        }))
    }

    const onPerPageChange = perPage => {
        setPageDetails(pageDetails => ({
            ...pageDetails,
            perPage
        }))
    }

    if(archivedTemplates.length === 0) {
        return (
            <div className="no-archived-templates">
                No archived templates found
            </div>
        )
    }

    if (loading) return <LoadingSpinner />;
    
	return (
		<BootstrapedTable
			keyField="id"
			data={archivedTemplates}
			columns={columns}
			bordered={false}
			rowStyle={rowStyle}
            showTotal={true}
			onTableChange={onTableChange}
			currentPage={pageDetails.page}
			rowsPerPage={pageDetails.perRow}
			setCurrentPage={onCurrentPageChange}
			setRowsPerPage={onPerPageChange}
			totalRows={totalRecords}
			sizePerPageList={[10, 25, 50, 100, 200]}
		/>
	);
}

export default ArchivedTemplatesTable;