export const SITES = [
	{
		label: 'Navigate Middle School',
		value: '5898',
	},
	{
		label: 'Navigate Elementary School',
		value: '5896',
	},
];

export const SCHOOL_YEARS = [
	{
		label: '2018-2019',
		value: '2018',
	},
	{
		label: '2019-2020',
		value: '2019',
	},
	{
		label: '2020-2021',
		value: '2020',
	},
	{
		label: '2021-2022',
		value: '2021',
	},
	{
		label: '2022-2023',
		value: '2022',
	},
	{
		label: '2023-2024',
		value: '2023',
	},
];

export const STATES = [
	{
		label: 'Ohio',
		value: 'Ohio',
	},
	{
		label: 'Alaska',
		value: 'Alaska',
	},
	{
		label: 'Arizona',
		value: 'Arizona',
	},
	{
		label: 'Arkansas',
		value: 'Arkansas',
	},
];

export const DRILL_TYPES = [
	{
		label: 'All drill types',
		value: 'All drill types',
	},
	{
		label: 'Blizzard',
		value: 'Blizzard',
	},
	{
		label: 'Fire',
		value: 'Fire',
	},
	{
		label: 'Storm',
		value: 'Storm',
	},
	{
		label: 'Tornado',
		value: 'Tornado',
	},
];

export const DRILL_TYPES_WITHOUT_ALL = [
	{
		label: 'Blizzard',
		value: 'Blizzard',
		uuid: 'af6c63d6-da95-4f03-6487-8dae250df1eb',
	},
	{
		label: 'Fire',
		value: 'Fire',
		uuid: 'af6c63d6-da95-4f03-6487-8dae250df1eb',
	},
	{
		label: 'Storm',
		value: 'Storm',
		uuid: 'af6c63d6-da95-4f03-6487-8dae250df1eb',
	},
	{
		label: 'Tornado',
		value: 'Tornado',
		uuid: 'af6c63d6-da95-4f03-6487-8dae250df1eb',
	},
];

export const REQUIREMENTS = [
	{
		uuid: '1234',
		type: 'Blizzard',
		requirements: 1,
		status: 'Published',
	},
	{
		uuid: '2345',
		type: 'Fire',
		requirements: 2,
		status: 'Published',
	},
	{
		uuid: '3456',
		type: 'Storm',
		requirements: 3,
		status: 'Published',
	},
	{
		uuid: '4567',
		type: 'Tornado',
		requirements: 4,
		status: 'Published',
	},
];

export const MOCK_DRILLS = [
	{
		id: '1312132',
		title: 'Drill #1',
		type: 'Fire',
		date: '08/25/2021 09:00am',
		uuid: '0b071c54-8158-4cab-0043-3f3ff725258c',
		status: 'scheduled',
	},
	{
		id: '3644523',
		title: 'Drill #2',
		type: 'Fire',
		date: '08/30/2021 3:00pm',
		uuid: 'b480c6e9-5faa-0cb9-c32b-174448ddb26f',
		status: 'overdue',
	},
	{
		id: '1231321213',
		title: 'Drill #3',
		type: 'Fire',
		date: '04/30/2021 10:00am',
		uuid: '132123312-6944-68c6-907d-295340ebd239',
		status: 'in_progress',
	},
	{
		id: '345543542',
		title: 'Drill #4',
		type: 'Tornado',
		date: '08/30/2021 08:00am',
		uuid: '355424-6944-68c6-3423a-295340ebd239',
		status: 'overdue',
	},
	{
		id: '46745475447',
		title: 'Drill #5',
		type: 'Fire',
		date: '08/30/2021 08:00am',
		uuid: '474675-6944-68c6-907d-295340ebd239',
		status: 'in_progress',
	},
	{
		id: '34543354',
		title: 'Drill #6',
		type: 'Blizzard',
		date: '08/24/2021 08:00am',
		uuid: '51a26210-54fb-69c0-b060-337f4a38c104',
		status: 'completed',
	},
	{
		id: '45563462',
		title: 'Drill #7',
		type: 'Fire',
		date: '10/01/2021 08:00am',
		uuid: 'ab493437-c2ee-428e-ad78-57e2f1cab2dd',
		status: 'completed',
	},
];

export const BASIC_DRILLS = [
	{
		name: 'Severe weather drill',
		uuid: '0b071c54 - 8158 - 4cab - 0043 - 3f3ff725258c',
		site: 'Starzville - SACC',
		timestamp: '03/16/2021 08:00am',
		dueIn: 'due in 3 days',
	},
	{
		name: 'Emergency evacuation drill',
		uuid: 'b480c6e9 - 5faa - 0cb9 - c32b - 174448ddb26f',
		site: 'Freiheit Elementary',
		timestamp: '03/17/2021 08:00am',
		dueIn: 'due in 4 days',
	},
	{
		name: 'Fire drill',
		uuid: '33d57753 - 6944 - 68c6 - 907d - 295340ebd239',
		site: 'Freiheit Elementary',
		timestamp: '03/17/2021 08:00am',
		dueIn: 'due in 5 days',
	},
	{
		name: 'Severe weather drill',
		uuid: '51a26210 - 54fb - 69c0 - b060 - 337f4a38c104',
		site: 'Starzville - SACC',
		timestamp: '03/17/2021 08:00am',
		dueIn: 'due in 5 days',
	},
	{
		name: 'Emergency evacuation drill',
		uuid: 'ab493437 - c2ee - 428e - ad78 - 57e2f1cab2dd',
		site: 'Freiheit Elementary',
		timestamp: '03/17/2021 08:00am',
		dueIn: 'due in 4 days',
	},
];

export const FIELD_OPTIONS = [
	{ label: 'Text', value: 'text' },
	{ label: 'Text Multi-Line', value: 'textarea' },
	{ label: 'Number', value: 'number' },
	{ label: 'Date', value: 'date' },
	{ label: 'Date/Time', value: 'datetime' },
	{ label: 'Yes/No', value: 'yesno' },
	{ label: 'Satisfactory/Needs Improvement', value: 'satisfactory' },
];

export const STATUS_MAP = {
	completed: 'completed',
	required: 'required',
	overdue: 'overdue',
	inProgress: 'in_progress',
	scheduled: 'scheduled',
	notScheduled: 'not_scheduled',
};

export const GRADE_LEVELS = {
	GL_SP_ED: 'Special Education',
	GL_PK: 'Pre-school',
	GL_K: 'Kindergarten',
	GL_1: 'Grade 1',
	GL_2: 'Grade 2',
	GL_3: 'Grade 3',
	GL_4: 'Grade 4',
	GL_5: 'Grade 5',
	GL_6: 'Grade 6',
	GL_7: 'Grade 7',
	GL_8: 'Grade 8',
	GL_9: 'Grade 9',
	GL_10: 'Grade 10',
	GL_11: 'Grade 11',
	GL_12: 'Grade 12',
};

export const GRADE_LEVELS_VALUES = {
	GL_SP_ED: false,
	GL_PK: false,
	GL_K: false,
	GL_1: false,
	GL_2: false,
	GL_3: false,
	GL_4: false,
	GL_5: false,
	GL_6: false,
	GL_7: false,
	GL_8: false,
	GL_9: false,
	GL_10: false,
	GL_11: false,
	GL_12: false,
};

export const SHOW_ONLY_ANALYTICS_OPTIONS = [
	{ label: 'All drill statuses', value: 'all' },
	{ label: 'Drills not scheduled yet', value: 'notScheduled' },
	{ label: 'Overdue drill', value: 'overdue' },
	{ label: 'Completed drills', value: 'completed' },
];
