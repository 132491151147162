import React, { useState, useEffect, useContext } from 'react';
import { LoadingSpinner } from 'components/LoadingSpinner';
import {
	BootstrapedTable,
	Button,
	Intent,
	FeaturedIcon,
	sortingStyle,
	PageHeader,
} from 'componentsV2';
import { DrillTypesService } from 'services';
import { ToasterContext } from 'pages/App';
import { DrillTypeAddModal } from './DrillTypeAddModal';

export const DrillTypes = () => {
	const [loading, setLoading] = useState(true);
	const [totalRows, setTotalRows] = useState(0);
	const [fields, setFields] = useState([]);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const toaster = useContext(ToasterContext);

	const breadcrumbItems = [
		{ label: 'Drills', url: '/drillsV2' },
		{ label: 'Admin', url: '/drillsV2/admin' },
		{ label: 'Drill types' },
	];

	// Callback for create drill log field modal.
	const createDrillType = ({ name }) => {
		toaster(
			`Creating drill type "${name}"...`,
			Intent.WARNING,
			<FeaturedIcon icon="upload" type="Warning" size="md" shape="circleIcon" />,
			5000,
			true,
		);
		DrillTypesService.createDrillType(name)
			.then(() => {
				toaster(
					`You have successfully created "${name}" as a new drill type.`,
					Intent.SUCCESS,
					<FeaturedIcon icon="tick" type="Success" size="md" shape="circleIcon" />,
					10000,
					true,
				);
				getData();
			})
			.catch(() => {
				toaster(
					`Failed to create "${name}" as a new drill type. Please try again or contact support.`,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" size="md" shape="circleIcon" />,
					20000,
					true,
				);
			});
	};

	// Helper to get all field data.
	const getData = () => {
		setLoading(true);
		DrillTypesService.getAll()
			.then(rows => {
				setFields(rows);
				setTotalRows(rows.length);
			})
			.finally(() => setLoading(false));
	};

	// Load table data initial.
	useEffect(getData, []);

	const columns = [
		{
			key: 'name',
			dataField: 'name',
			text: 'Name',
			sort: true,
			sortCaret: (order, column) => sortingStyle(order, column),
			classes: 'first-column',
			headerStyle: () => ({ width: '50%' }),
		},
		{
			key: 'uuid',
			dataField: 'uuid',
			text: 'UUID',
		},
	];

	return (
		<div className="drill-types">
			<PageHeader {...{ breadcrumbItems }} title="Drill types" />
			{modalIsOpen && (
				<DrillTypeAddModal
					modalIsOpen={modalIsOpen}
					setModalIsOpen={setModalIsOpen}
					onSubmitFinished={createDrillType}
				/>
			)}
			{loading && <LoadingSpinner />}
			{!loading && (
				<div>
					<div className="Dashboard-Header">
						<h4 className="title">Types</h4>
						<Button
							size="md"
							text="New type"
							icon="plus"
							intent="default"
							onClick={() => setModalIsOpen(true)}
						/>
					</div>
					<BootstrapedTable
						keyField="id"
						data={fields}
						columns={columns}
						totalRows={totalRows}
						defaultSorted={[{ dataField: 'name', order: 'asc' }]}
						remote={false}
						sizePerPageList={[10, 25, 50]}
					/>
				</div>
			)}
		</div>
	);
};
