import React, { useState, useContext, useEffect } from 'react';
import {
	Modal,
	Input,
	Badge,
	Intent,
	Button,
	BootstrapedTable,
	TableCell,
	Tooltip,
} from 'componentsV2';
import { ToasterContext } from 'pages/App';
import { Icon } from '@blueprintjs/core';
import './css/EditGuardianDetailsModal.scss';
import { isValidEmail, isValidPhone, isNotEmpty } from 'utilities/validations';
import { PHONE_NUMBER_MASK, PHONE_NUMBER_PLACEHOLDER } from 'utilities/constants';
import { Helper } from 'utilities/helper';
import { ReunificationService } from 'services/ReunificationService';
import {
	ADD_ANOTHER_STUDENT,
	DEFULT_ADD_ANOTHER_STUDENT_MODAL,
	DELETE_GUARDIAN_ASSOCIATION,
} from '../../consts';
import { AddAnotherStudentModal } from './AddAnotherStudentModal';
import { DeleteStudentGuardianAssociationModal } from './DeleteStudentGuardianAssociationModal';

export const EditGuardianDetailsModal = ({ handleCancel, guardianDetails, alarmId }) => {
	const toaster = useContext(ToasterContext);
	const [validationErrors, setErrors] = useState({});
	const [formData, setFormData] = useState({
		name: guardianDetails.name,
		mobilePhone: guardianDetails.phone,
		email: guardianDetails.email,
	});
	const [isDisabled, setIsDisabled] = useState(true);
	const [modalToShow, setModalToshow] = useState(DEFULT_ADD_ANOTHER_STUDENT_MODAL);
	const [studentsList, setStudentsList] = useState(guardianDetails?.students);
	const [currentSelectedStudent, setCurrentSelectedStudent] = useState();
	const Handleupdate = () => {
		if (!hasValidateError()) {
			let body = {
				name: formData.name,
			};
			if (formData.email) {
				body.email = formData.email;
			}
			if (formData.phone) {
				body.phone = formData.mobilePhone;
			}
			ReunificationService.updateGuardianDetails(alarmId, guardianDetails.id, body)
				.then(response => {
					if (response.statusCode === 200) {
						toaster(
							'You have successfully edited ' +
								guardianDetails.name +
								' information.',
							Intent.SUCCESS,
						);
						handleCancel();
					}
				})
				.catch(error => {
					toaster(error?.error?.description, Intent.DANGER);
				});
		}
	};

	const onRemoveAction = item => {
		setCurrentSelectedStudent(item.student);
		setModalToshow(DELETE_GUARDIAN_ASSOCIATION);
	};

	const hasValidateError = () => {
		let hasValidationError = false;
		let errors = {};
		if (!isNotEmpty(formData.name)) {
			hasValidationError = true;
			errors.name = "*Name can't be empty";
		} else {
			errors.name = '';
		}

		if (formData.mobilePhone && !isValidPhone(formData.mobilePhone)) {
			hasValidationError = true;
			errors.mobilePhone = '*Mobile is not valid';
		} else {
			errors.mobilePhone = '';
		}
		if (formData.email && !isValidEmail(formData.email)) {
			hasValidationError = true;
			errors.email = '*Email is not valid';
		} else {
			errors.email = '';
		}
		setErrors(errors);
		return hasValidationError;
	};

	const rightContent = item => {
		let type = '';
		let text = '';
		if (item.reunified) {
			type = 'Primary';
			text = 'Reunified';
		} else {
			if (item.checkIn) {
				switch (item.checkIn.status) {
					case 'checkedIn':
						type = 'Success';
						text = 'Checked-in';
						break;
					case 'absent':
						type = 'Gray';
						text = 'Absent';
						break;
					case 'transferred':
						type = 'Gray';
						text = 'Transferred to reunification';
						break;
					default:
						type = 'Primary';
						text = item.checkIn.status;
				}
			} else {
				type = 'Warning';
				text = 'Not Checked-in';
			}
		}

		return (
			<span className="badgesWrapper">
				<Badge type={type} size="md" text={text} />
			</span>
		);
	};

	const handleAddAnotherStudent = () => {
		setModalToshow(ADD_ANOTHER_STUDENT);
	};

	const columns = [
		{
			key: 'id',
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			key: 'student',
			dataField: 'student',
			text: "Student's name",
			sort: false,
			formatter: (value, row) => (
				<TableCell
					text={`${row.student.firstName} ${
						row.student.middleInitial?.length > 0
							? row.student.middleInitial.slice(0, 1) + '.'
							: ''
					} ${row.student.lastName}`}
					supportText={
						<>
							Grade: {row.student.grade}
							<br />
							{rightContent(row.student)}
						</>
					}
				/>
			),
		},
		{
			key: 'relation',
			dataField: 'relation',
			text: 'Relationship to student',
		},

		{
			key: 'actions',
			text: 'Actions',
			dataField: 'id',

			formatter: (value, row) => (
				<>
					{row.student.reunified ? (
						<Tooltip tooltipTitle={'Reunified students can’t be deleted'}>
							<Icon size={16} className="trash_icon-disabled" icon="trash" />
						</Tooltip>
					) : (
						<Icon
							size={16}
							className="trash_icon"
							icon="trash"
							onClick={() => onRemoveAction(row)}
						/>
					)}
				</>
			),
		},
	];

	const refreshGuardian = () => {
		ReunificationService.getGuardianDetails(alarmId, guardianDetails.id)
			.then(response => {
				if (response.statusCode === 200) {
					if (response.data.students.length > 0) {
						setStudentsList(response.data.students);
					} else {
						setModalToshow(ADD_ANOTHER_STUDENT);
					}
				}
			})
			.catch(error => {
				toaster(error?.error?.description, Intent.DANGER);
			});
	};
	useEffect(() => {
		refreshGuardian();
	}, [modalToShow]);
	return (
		<>
			{modalToShow === DEFULT_ADD_ANOTHER_STUDENT_MODAL && (
				<Modal
					isOpen={true}
					onClose={handleCancel}
					showCloseButton
					customClassName={'edit-student-guardian-modal'}
					size="medium"
					title="Edit guardian details"
					scrollContent={true}
				>
					<div className="edit-guardian-Wrapper">
						<div className="edit-student-title row">Guardian information</div>
						<div className="row">
							<div className="col-md-12 inputCol">
								<Input
									placeholder="Enter full name"
									name="name"
									label={'Full Name'}
									isRequired={true}
									error={!!validationErrors.name}
									errorMessage={validationErrors.name}
									labelState={true}
									value={formData.name}
									onChange={e => {
										validationErrors.name = '';
										setFormData({ ...formData, name: e.target.value });
										e.target.value != '' &&
										e.target.value != guardianDetails.name
											? setIsDisabled(false)
											: setIsDisabled(true);
									}}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 inputCol">
								<Input
									label={'Mobile Phone'}
									isRequired={false}
									placeholder="(000) 000-0000"
									name="mobilePhone"
									error={!!validationErrors.mobilePhone}
									errorMessage={validationErrors.mobilePhone}
									labelState={false}
									value={formData.mobilePhone}
									mask={PHONE_NUMBER_MASK}
									onChange={e => {
										const input = Helper.formatPhoneToDigits(e.target.value);
										if (isValidPhone(input)) {
											validationErrors.mobilePhone = '';
										}
										setFormData({ ...formData, mobilePhone: input });
										input != '' ? setIsDisabled(false) : setIsDisabled(true);
									}}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 inputCol">
								<Input
									name="email"
									label="Email"
									value={formData.email}
									error={!!validationErrors.email}
									errorMessage={validationErrors.email}
									isRequired={false}
									labelState={false}
									placeholder="Enter email address"
									onChange={e => {
										if (isValidEmail(e.target.value)) {
											validationErrors.email = '';
										}
										setFormData({ ...formData, email: e.target.value });
										e.target.value != ''
											? setIsDisabled(false)
											: setIsDisabled(true);
									}}
								/>
							</div>
						</div>
						<div className="row">
							<div className="button-div-styles">
								<Button
									text={'Cancel'}
									type="tertiary"
									intent="default"
									onClick={() => handleCancel()}
								/>
								<Button
									text={'Update'}
									intent="default"
									disabled={isDisabled}
									onClick={() => {
										Handleupdate();
									}}
								/>
							</div>
						</div>
						<div className="row list-title">Students</div>
						<div className="students-table-list">
							<BootstrapedTable
								keyField="id"
								className="guardian-students-table-list"
								data={studentsList ? studentsList : guardianDetails?.students}
								columns={columns}
								borderNone={true}
								showPagination={false}
							/>
							<Button
								icon={'plus'}
								text="Add another student"
								type="ghostPrimary"
								size="md"
								onClick={() => {
									handleAddAnotherStudent();
								}}
							/>
						</div>
					</div>
				</Modal>
			)}
			{modalToShow === ADD_ANOTHER_STUDENT && (
				<AddAnotherStudentModal
					handleCancel={handleCancel}
					alarmId={alarmId}
					setModalToshow={setModalToshow}
					guardianDetails={guardianDetails}
				/>
			)}
			{modalToShow === DELETE_GUARDIAN_ASSOCIATION && (
				<DeleteStudentGuardianAssociationModal
					setModalToshow={setModalToshow}
					currentSelectedStudent={currentSelectedStudent}
					alarmId={alarmId}
					guardianId={guardianDetails.id}
				/>
			)}
		</>
	);
};
