export const DRAFT_STATUS = 'draft';
export const PUBLISH_STATUS = 'published';
export const MODIFIED_STATUS = 'modified';
export const ARCHIVED_STATUS = 'archived';
export const STATUS_LABELS = [
    {
        label: 'Published',
        value: PUBLISH_STATUS
    },
    {
        label: 'Modified',
        value: MODIFIED_STATUS
    },
    {
        label: 'Draft',
        value: DRAFT_STATUS
    },
    {
        label: 'Archived',
        value: ARCHIVED_STATUS
    }
];