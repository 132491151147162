import React from 'react';
import { TableCell, Checkbox } from 'componentsV2';
import './css/SelectSiteList.scss';
import { DISABLED_STATUS, SELECTED_STATUS, ALARM_MODES } from '../../consts';

export const SelectSiteList = ({ sitesList, onSelect, mode }) => {
	return (
		<div className="activate-alarm-modal-select-site-list">
			{sitesList.map(site => (
				<div
					className={`activate-alarm-modal-select-site-list-item ${site.status}`}
					key={site.team_id}
					onClick={() => onSelect(site)}
				>
					<TableCell
						text={site.team_name}
						supportText={
							site.status === DISABLED_STATUS
								? `This site has ${
										site.mode == ALARM_MODES.alarm.toLowerCase()
											? ' an emergency'
											: ' a ' + site.mode
								  } alarm in progress. Only one alarm can be activated for a site at a time.`
								: ''
						}
						leftIcon={
							<Checkbox
								onChange={e => onSelect(site)}
								checked={site.status === SELECTED_STATUS}
								disabled={site.status === DISABLED_STATUS}
							/>
						}
					/>
				</div>
			))}
		</div>
	);
};
